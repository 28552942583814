define("alliance-business-suite/templates/components/tree-table-text-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WFm6tNJ1",
    "block": "{\"symbols\":[],\"statements\":[[1,[35,0,[\"value\"]]],[2,\" \"]],\"hasEval\":false,\"upvars\":[\"cell\"]}",
    "moduleName": "alliance-business-suite/templates/components/tree-table-text-cell.hbs"
  });

  _exports.default = _default;
});