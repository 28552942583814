define("alliance-business-suite/components/form-ui/time-span-epoch", ["exports", "alliance-business-suite/mixins/init-property-names"], function (_exports, _initPropertyNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function rollHours(value) {
    // If we add hours to a time that rolls it over to the next day, reset the value
    return value >= 24 ? value - 24 : value;
  }

  var _default = Ember.Component.extend(_initPropertyNames.default, {
    tagName: 'div',
    startTime: Ember.computed('startDate', function () {
      let date = moment(this.startDate, "X");
      let hours = date.hour() || 0;
      let minutes = date.minute() || 0;
      return {
        hours,
        minutes
      };
    }),
    endTime: Ember.computed('endDate', function () {
      let date = moment(this.endDate, "X");
      let hours = date.hour() || 0;
      let minutes = date.minute() || 0;
      return {
        hours,
        minutes
      };
    }),
    actions: {
      updateStartTime(value) {
        // Update property with existing date and new time
        let currentDate = moment(this.startDate, "X").hours(value.hours).minutes(value.minutes).seconds(0).format("X");
        this.update(this.object, this.properties.startDate.key, currentDate);
        this.update(this.object, this.properties.endDate.key, moment(currentDate, "X").add(this.durationHours, 'hours').format("X"));
      },

      updateDuration(object, propertyName, value) {
        // Add duration to startDate
        let endDate = moment(this.startDate, "X").add(value, 'hours').format("X");
        this.update(object, this.properties.endDate.key, endDate);
        this.update(object, propertyName, value);
      }

    },
    overnight: Ember.computed('startTime.{hours,minutes}', 'durationHours', function () {
      let startHours = this.startTime.hours;
      let startMinutes = this.startTime.minutes;
      let totalTime = startHours + startMinutes / 60 + Number(this.durationHours);
      console.log('totalTime', totalTime);

      if (totalTime >= 24) {
        return true;
      } else {
        return false;
      }
    })
  });

  _exports.default = _default;
});