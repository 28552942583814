define("alliance-business-suite/components/machines/stats/s-bars", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['statistics-breakdown-stats-graph js-toggle-breakdown-stats'],
    hasGreen: Ember.computed('numbers', function () {
      return Ember.get(this, 'numbers.green') ? true : false;
    }),
    hasGray: Ember.computed('numbers', function () {
      return Ember.get(this, 'numbers.gray') ? true : false;
    }),
    hasRed: Ember.computed('numbers', function () {
      return Ember.get(this, 'numbers.red') ? true : false;
    }),
    hasOrange: Ember.computed('numbers', function () {
      return Ember.get(this, 'numbers.orange') ? true : false;
    }),
    hasYellow: Ember.computed('numbers', function () {
      return Ember.get(this, 'numbers.yellow') ? true : false;
    })
  });

  _exports.default = _default;
});