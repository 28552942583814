define("alliance-business-suite/components/summary/reports/s-firelinc", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "alliance-business-suite/utils/report-tables-util"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _reportTablesUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Summary::Reports::DefaultReportShim
    @height={{@height}}
    @skipNumberFormatting={{true}}
    @translatedColumns={{this.translatedColumns}}
    @rowsWithChildren={{this.rowsWithChildren}} />
  
  */
  {
    "id": "k0guXMuf",
    "block": "{\"symbols\":[\"@height\"],\"statements\":[[8,\"summary/reports/default-report-shim\",[],[[\"@height\",\"@skipNumberFormatting\",\"@translatedColumns\",\"@rowsWithChildren\"],[[32,1],true,[32,0,[\"translatedColumns\"]],[32,0,[\"rowsWithChildren\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "alliance-business-suite/components/summary/reports/s-firelinc.hbs"
  });

  let SummaryReportsSFirelincComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, (_class = class SummaryReportsSFirelincComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor3, this);
    }

    get translatedColumns() {
      return [{
        name: 'item',
        width: 180,
        valuePath: 'name'
      }, {
        name: 'location',
        width: 200,
        valuePath: 'location'
      }, {
        name: 'operator',
        valuePath: 'operator'
      }, {
        name: 'attendee',
        valuePath: 'attendee'
      }, {
        name: 'cycle',
        valuePath: 'cycle'
      }, {
        name: 'option_CycleComplete',
        valuePath: 'cycleComplete'
      }, {
        name: 'scan_date',
        valuePath: 'scanDate'
      }, {
        name: 'cycle_start_time',
        valuePath: 'cycleStart'
      }, {
        name: 'cycle_end_time',
        valuePath: 'cycleEnd'
      }, {
        name: 'max_temp',
        valuePath: 'maxTemp'
      }, {
        name: 'components.orders.notes',
        valuePath: 'notes'
      }];
    }

    get rowsWithChildren() {
      return this.args.model.get('items'); // let items = this.args.model.get('items');
      //
      // let itemArray = [];
      //
      // items.forEach((item) => {
      //   itemArray.push({...item})
      // });
      //
      // return itemArray;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SummaryReportsSFirelincComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SummaryReportsSFirelincComponent);
});