define("alliance-business-suite/templates/components/machines/programs/pricing/midas-wx-modal-mod1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PmCaPrAL",
    "block": "{\"symbols\":[\"ui\"],\"statements\":[[8,\"form-ui\",[],[[\"@object\",\"@machineProgram\",\"@formSettings\",\"@selectOptions\",\"@formDisplay\"],[[32,0,[\"object\"]],[32,0,[\"machineProgram\"]],[32,0,[\"formSettings\"]],[32,0,[\"selectOptions\"]],\"compact\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n      \"],[8,[32,1,[\"columns\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,1,[\"column\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n          \"],[8,[32,1,[\"section\"]],[],[[\"@title\"],[[30,[36,0],[\"modifier1\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n            \"],[8,[32,1,[\"text\"]],[],[[\"@params\"],[[30,[36,1],null,[[\"propertyName\",\"label\"],[\"Messages.Modifier1Name\",\"modifier_name\"]]]]],null],[2,\"\\n\\n            \"],[8,[32,1,[\"textarea\"]],[],[[\"@params\"],[[30,[36,1],null,[[\"propertyName\",\"label\"],[\"Messages.Modifier1Description\",\"modifier_description\"]]]]],null],[2,\"\\n\\n          \"]],\"parameters\":[]}]]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\\n      \"],[11,\"button\"],[24,0,\"button--ok\"],[24,4,\"button\"],[4,[38,3],[[32,0],[35,2]],null],[12],[1,[30,[36,0],[\"OK\"],null]],[13],[2,\"\\n\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"hash\",\"hideModal\",\"action\"]}",
    "moduleName": "alliance-business-suite/templates/components/machines/programs/pricing/midas-wx-modal-mod1.hbs"
  });

  _exports.default = _default;
});