define("alliance-business-suite/components/employees/time-clock-punch-list", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    activePunch: null,
    sortedList: Ember.computed('punches', function () {
      let {
        punches
      } = this;
      let sortedList = punches.sort((a, b) => {
        let punchA = (0, _moment.default)(a.punchInDate);
        let punchB = (0, _moment.default)(b.punchInDate);

        if (punchA.isAfter(punchB)) {
          return 1;
        } else if (punchB.isAfter(punchA)) {
          return -1;
        }

        return 0;
      });
      return sortedList;
    }),
    actions: {
      refreshData() {
        let isOldRefresh = true;
        this.refreshData(isOldRefresh);
      },

      showEditModal(data) {
        this.set('activePunch', data);
        this.toggleProperty('showEditModal');
      }

    }
  });

  _exports.default = _default;
});