define("alliance-business-suite/routes/crm/campaigns/create", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "alliance-business-suite/routes/_secureRoute"], function (_exports, _defineProperty2, _applyDecoratedDescriptor2, _secureRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  let CrmCampaignsCreateRoute = (_dec = Ember._action, (_class = class CrmCampaignsCreateRoute extends _secureRoute.default {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "allowAccess", 'CRM_CREATE_CAMPAIGN');
    }

    // old holdover from class
    // init() {
    //   this._super(...arguments);
    //
    //   $('.overflow-container').scrollTop(0);
    // },
    model() {
      return this.store.createRecord('campaign', {
        filter: '{"Params": []}'
      });
    }

    willTransition(transition) {
      let abandonMessage = 'You have unsaved changes, continue and lose changes?';

      if (this.controller.get('hasUnsavedChanges') && !window.confirm(abandonMessage)) {
        transition.abort();
      } else {
        this.controller.set('hasUnsavedChanges', false);
        localStorage.removeItem('newCampaignFilters');
        localStorage.removeItem('newCampaignLocations');

        if (this.context && !this.context.get('id')) {
          this.context.rollbackAttributes();
        }

        return true;
      }
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class.prototype, "willTransition", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "willTransition"), _class.prototype)), _class));
  _exports.default = CrmCampaignsCreateRoute;
});