define("alliance-business-suite/templates/components/machines/programs/water-usage/acas-wx-flw", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ESJxWgP1",
    "block": "{\"symbols\":[\"ui\"],\"statements\":[[8,\"form-ui\",[],[[\"@object\",\"@model\",\"@formDisplay\",\"@machineSettings\",\"@decimals\",\"@currencySymbol\",\"@selectOptions\",\"@currencySymbolPosition\",\"@currencyDivideBy\",\"@thousandsSymbol\",\"@decimalSymbol\",\"@isCelsius\",\"@coinValue\"],[[32,0,[\"machineProgram\",\"settings\"]],[32,0,[\"machineProgram\"]],\"compact\",[32,0,[\"machineProgram\",\"machineType\",\"machineSettings\"]],[32,0,[\"machineProgram\",\"settings\",\"Program\",\"CoinAndPulse\",\"DecimalPointPosition\"]],[32,0,[\"globalSettings\",\"currencySymbol\"]],[32,0,[\"selectOptions\"]],[32,0,[\"globalSettings\",\"currencySymbolPosition\"]],[32,0,[\"globalSettings\",\"divideBy\"]],[32,0,[\"globalSettings\",\"thousandsSymbol\"]],[32,0,[\"globalSettings\",\"decimalSymbol\"]],[32,0,[\"globalSettings\",\"isCelsius\"]],[32,0,[\"globalSettings\",\"coinValue\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[8,[32,1,[\"columns\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"column\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n        \"],[8,[32,1,[\"checkbox\"]],[],[[\"@object\",\"@params\"],[[32,0],[30,[36,0],null,[[\"propertyName\",\"label\"],[\"convertToLiters\",\"show_water_use_in_liters\"]]]]],null],[2,\"\\n\\n        \"],[8,\"high-charts\",[],[[\"@content\",\"@chartOptions\",\"@theme\"],[[32,0,[\"chartDataLight\"]],[32,0,[\"lightChartOptions\"]],[32,0,[\"theme\"]]]],null],[2,\"\\n\\n        \"],[8,\"high-charts\",[],[[\"@content\",\"@chartOptions\",\"@theme\"],[[32,0,[\"chartDataMedium\"]],[32,0,[\"mediumChartOptions\"]],[32,0,[\"theme\"]]]],null],[2,\"\\n\\n        \"],[8,\"high-charts\",[],[[\"@content\",\"@chartOptions\",\"@theme\"],[[32,0,[\"chartDataHeavy\"]],[32,0,[\"heavyChartOptions\"]],[32,0,[\"theme\"]]]],null],[2,\"\\n\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"hash\"]}",
    "moduleName": "alliance-business-suite/templates/components/machines/programs/water-usage/acas-wx-flw.hbs"
  });

  _exports.default = _default;
});