define("alliance-business-suite/models/gateway", ["exports", "ember-data", "alliance-business-suite/models/mixins/timestamped"], function (_exports, _emberData, _timestamped) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend(_timestamped.default, {
    guid: attr('string'),
    isActive: attr('boolean'),
    raspberryPiType: attr('string'),
    url: attr() // brushLabel: computed('id', 'raspberryPiNotes', () => {
    //   // eslint-disable-next-line no-console
    //   console.error(
    //     'PLEASE send this error to Devin or Ed if you are seeing it!\n',
    //     'gateway brushLabel computed invoked, returning:',
    //     this.raspberryPiNotes ? 'this.get(raspberryPiNotes)' : 'GATEWAY #${this.get(id)}',
    //     '\nwhich is:',
    //     this.raspberryPiNotes || `GATEWAY #${this.id}`
    //   );
    //   return this.raspberryPiNotes || `GATEWAY #${this.id}`;
    // })

  });

  _exports.default = _default;
});