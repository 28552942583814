define("alliance-business-suite/components/machines/programs/special-vend/primus-mc10-wx", ["exports", "alliance-business-suite/helpers/find-by-dropdown-with-custom", "alliance-business-suite/mixins/machines/programs/mc10-options"], function (_exports, _findByDropdownWithCustom, _mc10Options) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_mc10Options.default, {
    didReceiveAttrs() {
      // Check if Coin1PriceSeparator property exists
      let newPropertyExists = Ember.get(this, 'machineProgram.settings.Program.Display.Coin1PriceSeparator') !== undefined; // Check if NumericSeparator property exists

      let oldPropertyExists = Ember.get(this, 'machineProgram.settings.Program.Display.NumericSeparator') !== undefined;

      if (!newPropertyExists && oldPropertyExists) {
        // Convert the old property to the new properties, then remove the old property
        set(this, 'machineProgram.settings.Program.Display.Coin1PriceSeparator', Bits.getLowNibble(this.machineProgram.settings.Program.Display.NumericSeparator));
        set(this, 'machineProgram.settings.Program.Display.Coin2PriceSeparator', Bits.getHighNibble(this.machineProgram.settings.Program.Display.NumericSeparator));
        set(this, 'machineProgram.settings.Program.Display.NumericSeparator', undefined);
      }

      this._super(...arguments);
    },

    showModal: false,
    decimalPlaces: Ember.computed.alias('machineProgram.settings.Program.CoinAndPulse.DecimalPointPosition'),
    numberFormatting: Ember.computed('machineProgram.settings.Program.Display.Coin1PriceSeparator', function () {
      let value = Ember.get(this, 'machineProgram.settings.Program.Display.Coin1PriceSeparator');
      let option = Ember.get(this, 'selectOptions.NumericSeparator').findBy('val', value);

      if (option === undefined) {
        console.log('NumericSeparator is not valid for this machine. Value is', value);
        return {
          decimals: null,
          divideBy: 1,
          decimalSymbol: null,
          thousandsSymbol: null
        };
      } else {
        return {
          decimals: option.decimalPlaces,
          divideBy: option.divideBy,
          decimalSymbol: option.decimalSymbol,
          thousandsSymbol: option.decimalSymbol == '.' ? ',' : '.'
        };
      }
    }),
    currencySymbol: Ember.computed('machineProgram.settings.Program.Price.Misc.Currency1.Symbol', function () {
      let selectedSymbol = Ember.get(this, 'machineProgram.settings.Program.Price.Misc.Currency1.Symbol');
      let options = Ember.get(this, 'selectOptions.CurrencyType');
      let option = (0, _findByDropdownWithCustom.findByDropDown)([options, "val", selectedSymbol]);
      return option.abbr;
    }),
    currencySymbolPosition: Ember.computed('machineProgram.settings.Program.Price.Misc.Currency1.PlaceAfter', function () {
      return Ember.get(this, 'machineProgram.settings.Program.Price.Misc.Currency1.PlaceAfter') === true ? "after" : "before";
    }),
    formConfig: Ember.computed(function () {
      return {
        columns: [{
          sections: [{
            title: "special_vend",
            controls: [{
              type: "special-vend-container",
              componentName: "machines/programs/special-vend/modal-primus-mc10-wx",
              properties: {
                Friday: "SpecialVend.Items.${index}.DOWEn.Friday",
                Monday: "SpecialVend.Items.${index}.DOWEn.Monday",
                Sunday: "SpecialVend.Items.${index}.DOWEn.Sunday",
                Enabled: "SpecialVend.Items.${index}.DOWEn.Enabled",
                Tuesday: "SpecialVend.Items.${index}.DOWEn.Tuesday",
                Saturday: "SpecialVend.Items.${index}.DOWEn.Saturday",
                Thursday: "SpecialVend.Items.${index}.DOWEn.Thursday",
                Wednesday: "SpecialVend.Items.${index}.DOWEn.Wednesday",
                EndDate: "SpecialVend.Items.${index}.EndDate",
                EndYear: "SpecialVend.Items.${index}.EndYear",
                Duration: "SpecialVend.Items.${index}.Duration",
                EndMonth: "SpecialVend.Items.${index}.EndMonth",
                StartDate: "SpecialVend.Items.${index}.StartDate",
                StartHour: "SpecialVend.Items.${index}.StartHour",
                StartYear: "SpecialVend.Items.${index}.StartYear",
                StartMonth: "SpecialVend.Items.${index}.StartMonth",
                CyclePrices: "SpecialVend.Items.${index}.CyclePrices",
                StartMinute: "SpecialVend.Items.${index}.StartMinute",
                HotAdder: "SpecialVend.Items.${index}.ModifierPrices.HotAdder",
                Mod1Adder: "SpecialVend.Items.${index}.ModifierPrices.Mod1Adder",
                Mod2Adder: "SpecialVend.Items.${index}.ModifierPrices.Mod2Adder",
                WarmAdder: "SpecialVend.Items.${index}.ModifierPrices.WarmAdder",
                OzoneAdder: "SpecialVend.Items.${index}.ModifierPrices.OzoneAdder",
                Temp15CAdder: "SpecialVend.Items.${index}.ModifierPrices.Temp15CAdder",
                Temp30CAdder: "SpecialVend.Items.${index}.ModifierPrices.Temp30CAdder",
                Temp40CAdder: "SpecialVend.Items.${index}.ModifierPrices.Temp40CAdder",
                Temp50CAdder: "SpecialVend.Items.${index}.ModifierPrices.Temp50CAdder",
                Temp60CAdder: "SpecialVend.Items.${index}.ModifierPrices.Temp60CAdder",
                Temp90CAdder: "SpecialVend.Items.${index}.ModifierPrices.Temp90CAdder",
                ExtraTimeAdder: "SpecialVend.Items.${index}.ModifierPrices.ExtraTimeAdder",
                WarmRinseAdder: "SpecialVend.Items.${index}.ModifierPrices.WarmRinseAdder",
                SpeedCycleAdder: "SpecialVend.Items.${index}.ModifierPrices.SpeedCycleAdder",
                HeavyBundleAdder: "SpecialVend.Items.${index}.ModifierPrices.HeavyBundleAdder",
                MediumBundleAdder: "SpecialVend.Items.${index}.ModifierPrices.MediumBundleAdder",
                SupplyInjectionAdder: "SpecialVend.Items.${index}.ModifierPrices.SupplyInjectionAdder",
                IncreasedSpinSpeedAdder: "SpecialVend.Items.${index}.ModifierPrices.IncreasedSpinSpeedAdder",
                IncreasedWaterLevelAdder: "SpecialVend.Items.${index}.ModifierPrices.IncreasedWaterLevelAdder",
                Mod1: "SpecialVend.Items.${index}.DefaultModifiers.Mod1",
                Mod2: "SpecialVend.Items.${index}.DefaultModifiers.Mod2",
                Ozone: "SpecialVend.Items.${index}.DefaultModifiers.Ozone",
                ExtraTime: "SpecialVend.Items.${index}.DefaultModifiers.ExtraTime",
                SpeedCycle: "SpecialVend.Items.${index}.DefaultModifiers.SpeedCycle",
                HeavyBundle: "SpecialVend.Items.${index}.DefaultModifiers.HeavyBundle",
                DispenseSoap: "SpecialVend.Items.${index}.DefaultModifiers.DispenseSoap",
                MediumBundle: "SpecialVend.Items.${index}.DefaultModifiers.MediumBundle",
                WarmFinalRinse: "SpecialVend.Items.${index}.DefaultModifiers.WarmFinalRinse",
                IncreasedSpinSpeed: "SpecialVend.Items.${index}.DefaultModifiers.IncreasedSpinSpeed",
                IncreasedWaterLevel: "SpecialVend.Items.${index}.DefaultModifiers.IncreasedWaterLevel",
                ExtractSpeedLimit: "SpecialVend.Items.${index}.ExtractSpeedLimit",
                HeavyMod1: "SpecialVend.Items.${index}.HeavyBundleOptions.Mod1",
                HeavyMod2: "SpecialVend.Items.${index}.HeavyBundleOptions.Mod2",
                HeavyExtraTime: "SpecialVend.Items.${index}.HeavyBundleOptions.ExtraTime",
                HeavySpinSpeed: "SpecialVend.Items.${index}.HeavyBundleOptions.SpinSpeed",
                HeavyWarmRinse: "SpecialVend.Items.${index}.HeavyBundleOptions.WarmRinse",
                HeavyWaterLevel: "SpecialVend.Items.${index}.HeavyBundleOptions.WaterLevel",
                MediumMod1: "SpecialVend.Items.${index}.MediumBundleOptions.Mod1",
                MediumMod2: "SpecialVend.Items.${index}.MediumBundleOptions.Mod2",
                MediumExtraTime: "SpecialVend.Items.${index}.MediumBundleOptions.ExtraTime",
                MediumSpinSpeed: "SpecialVend.Items.${index}.MediumBundleOptions.SpinSpeed",
                MediumWarmRinse: "SpecialVend.Items.${index}.MediumBundleOptions.WarmRinse",
                MediumWaterLevel: "SpecialVend.Items.${index}.MediumBundleOptions.WaterLevel",
                OptionsMod1: "SpecialVend.Items.${index}.ModsAdditionalOptsEnabled.Mod1",
                OptionsMod2: "SpecialVend.Items.${index}.ModsAdditionalOptsEnabled.Mod2",
                OptionsOzone: "SpecialVend.Items.${index}.ModsAdditionalOptsEnabled.Ozone",
                OptionsExtraTime: "SpecialVend.Items.${index}.ModsAdditionalOptsEnabled.ExtraTime",
                OptionsSpeedCycle: "SpecialVend.Items.${index}.ModsAdditionalOptsEnabled.SpeedCycle",
                OptionsHeavyBundle: "SpecialVend.Items.${index}.ModsAdditionalOptsEnabled.HeavyBundle",
                OptionsDispenseSoap: "SpecialVend.Items.${index}.ModsAdditionalOptsEnabled.DispenseSoap",
                OptionsMediumBundle: "SpecialVend.Items.${index}.ModsAdditionalOptsEnabled.MediumBundle",
                OptionsWarmFinalRinse: "SpecialVend.Items.${index}.ModsAdditionalOptsEnabled.WarmFinalRinse",
                OptionsIncreasedSpinSpeed: "SpecialVend.Items.${index}.ModsAdditionalOptsEnabled.IncreasedSpinSpeed",
                OptionsIncreasedWaterLevel: "SpecialVend.Items.${index}.ModsAdditionalOptsEnabled.IncreasedWaterLevel",
                WashAgitateMinutesAdder: "SpecialVend.Items.${index}.WashAgitateMinutesAdder",
                WashAgitateMinuteSubtracter: "SpecialVend.Items.${index}.WashAgitateMinuteSubtracter"
              },
              uniqueIndex: [0, 1, 2, 3, 4, 5, 6, 7]
            }]
          }]
        }]
      };
    })
  });

  _exports.default = _default;
});