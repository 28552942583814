define("alliance-business-suite/templates/components/form-ui/steps/internal-external-supply", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yuJRGeGG",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[8,\"form-ui\",[],[[\"@object\",\"@machineProgram\",\"@formSettings\",\"@selectOptions\"],[[32,0,[\"object\"]],[32,0,[\"machineProgram\"]],[32,0,[\"formSettings\"]],[32,0,[\"selectOptions\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[10,\"h2\"],[12],[1,[30,[36,0],[\"internal_supply\"],null]],[13],[2,\"\\n\\n  \"],[8,[32,1,[\"checkbox\"]],[],[[\"@params\"],[[30,[36,1],null,[[\"propertyName\",\"labelKey\"],[[32,0,[\"properties\",\"Compartment1\",\"key\"]],\"compartment_1\"]]]]],null],[2,\"\\n  \"],[8,[32,1,[\"checkbox\"]],[],[[\"@params\"],[[30,[36,1],null,[[\"propertyName\",\"labelKey\"],[[32,0,[\"properties\",\"Compartment2\",\"key\"]],\"compartment_2\"]]]]],null],[2,\"\\n  \"],[8,[32,1,[\"checkbox\"]],[],[[\"@params\"],[[30,[36,1],null,[[\"propertyName\",\"labelKey\"],[[32,0,[\"properties\",\"Compartment3\",\"key\"]],\"compartment_3\"]]]]],null],[2,\"\\n  \"],[8,[32,1,[\"checkbox\"]],[],[[\"@params\"],[[30,[36,1],null,[[\"propertyName\",\"labelKey\"],[[32,0,[\"properties\",\"Compartment4\",\"key\"]],\"compartment_4\"]]]]],null],[2,\"\\n\\n  \"],[10,\"h2\"],[12],[1,[30,[36,0],[\"external_supply\"],null]],[13],[2,\"\\n\\n  \"],[8,[32,1,[\"select\"]],[],[[\"@params\"],[[30,[36,1],null,[[\"propertyName\",\"options\"],[[32,0,[\"properties\",\"ShutoffCondition\",\"key\"]],\"SupplyCondition\"]]]]],null],[2,\"\\n\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"hash\"]}",
    "moduleName": "alliance-business-suite/templates/components/form-ui/steps/internal-external-supply.hbs"
  });

  _exports.default = _default;
});