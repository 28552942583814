define("alliance-business-suite/templates/components/form-ui/steps/active-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "llOnSD/W",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[8,\"form-ui\",[],[[\"@object\",\"@formSettings\",\"@selectOptions\"],[[32,0,[\"object\"]],[32,0,[\"formSettings\"]],[32,0,[\"selectOptions\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[8,[32,1,[\"select\"]],[],[[\"@params\"],[[30,[36,0],null,[[\"propertyName\",\"label\",\"options\"],[[32,0,[\"properties\",\"ActiveState\",\"key\"]],\"ActiveState\",\"ActiveState\"]]]]],null],[2,\"\\n\\n  \"],[8,[32,1,[\"select\"]],[],[[\"@params\"],[[30,[36,0],null,[[\"propertyName\",\"label\",\"options\"],[[32,0,[\"properties\",\"Label\",\"key\"]],\"Label\",\"StepLabel\"]]]]],null],[2,\"\\n\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"hash\"]}",
    "moduleName": "alliance-business-suite/templates/components/form-ui/steps/active-label.hbs"
  });

  _exports.default = _default;
});