define("alliance-business-suite/components/summary/reports/s-location-and-revenue", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "alliance-business-suite/utils/currency-format-util"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _currencyFormatUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  let SummaryReportsSLocationAndRevenueComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, (_class = class SummaryReportsSLocationAndRevenueComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "brush", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor4, this);
      (0, _defineProperty2.default)(this, "washerTableHeader", [{
        text: "models.revenue-details.machine-type"
      }, {
        text: "models.revenue-details.cycles"
      }, {
        text: "models.revenue-details.turns-per-day"
      }, {
        text: "models.revenue-details.coin-vault-opening"
      }, {
        text: "models.revenue-details.modifier-revenue"
      }, {
        text: "models.revenue-details.modifier-percent"
      }, {
        text: "models.revenue-details.total-revenue"
      }]);
      (0, _defineProperty2.default)(this, "dryerTableHeader", [{
        text: "models.revenue-details.machine-type"
      }, {
        text: "models.revenue-details.coin-vault-opening"
      }, {
        text: "models.revenue-details.total-revenue"
      }]);
      (0, _defineProperty2.default)(this, "comboTableHeader", [{
        text: "models.revenue-details.machine-type"
      }, // {
      //   text: "models.revenue-details.cycles",
      // },
      {
        text: "models.revenue-details.turns-per-day"
      }, {
        text: "models.revenue-details.coin-vault-opening"
      }, {
        text: "models.revenue-details.modifier-revenue"
      }, {
        text: "models.revenue-details.modifier-percent"
      }, {
        text: "models.revenue-details.total-revenue"
      }]);
    }

    get hasCombos() {
      let data = this.args.model.get("overview");
      let combos = data.filter(d => {
        return d.id === "Combos";
      });
      return combos[0]?.turns > 0;
    }

    get revenueOverviewData() {
      let data = this.args.model.get("overview");
      let totals = {};
      let machineData = data.filter(machine => {
        if (machine.id === "Goods" || machine.id === "Wash Dry Fold") {
          machine.type = "retail";
        }

        if (machine.id !== "Totals") {
          return true;
        } else {
          totals = machine;
        }
      });
      let overviewData = {
        machines: machineData,
        totals
      };
      return overviewData;
    } // computed('revenueOverviewData',


    get revOverviewChart() {
      let labels = [this.intl.t("dryers"), this.intl.t("washers")];
      let datasets = [{
        label: this.intl.t("total_revenue"),
        data: [80, 20]
      }];
      return {
        labels,
        datasets
      };
    }

    get revenueSummaryData() {
      // Adding this for the Washer section, totals row.
      let output = {
        totals: {}
      };
      output.totals.washerTotals = this.args.model.get("overview").findBy("id", "Washers");
      return output;
    }

    get revenueCombosSummaryData() {
      // Adding this for the Washer section, totals row.
      let output = {
        totals: {}
      };
      output.totals.comboTotals = this.args.model.get("overview").findBy("id", "Combos");
      return output;
    }

    get revenueDetailsData() {
      let data = this.args.model.get("details");
      let totals = {
        washerTotals: {
          count: 0,
          turns: 0,
          averageTurns: 0,
          coinVaultOpeningYes: 0,
          coinVaultOpeningNo: 0,
          modifierRevenue: 0,
          revenue: 0
        },
        dryerTotals: {
          count: 0,
          coinVaultOpeningYes: 0,
          coinVaultOpeningNo: 0,
          revenue: 0
        },
        comboTotals: {
          count: 0,
          turns: 0,
          averageTurns: 0,
          coinVaultOpeningYes: 0,
          coinVaultOpeningNo: 0,
          modifierRevenue: 0,
          revenue: 0
        }
      };
      data.forEach(machine => {
        if (machine.isWasher && !machine.isDryer) {
          // not sure why we are doing totals here when we are getting washer/dryer totals from the back already ~ap
          totals.washerTotals.count += machine.currentMachineCount;
          totals.washerTotals.turns += machine.turns;
          totals.washerTotals.averageTurns += machine.averageTurns;

          if (machine.coinVaultOpening) {
            totals.washerTotals.coinVaultOpeningYes += 1;
          } else {
            totals.washerTotals.coinVaultOpeningNo += 1;
          }

          totals.washerTotals.modifierRevenue += machine.modifierRevenue;
          totals.washerTotals.revenue += machine.revenue;
        }

        if (machine.isDryer && !machine.isWasher) {
          totals.dryerTotals.count += machine.currentMachineCount;

          if (machine.coinVaultOpening) {
            totals.dryerTotals.coinVaultOpeningYes += 1;
          } else {
            totals.dryerTotals.coinVaultOpeningNo += 1;
          }

          totals.dryerTotals.revenue += machine.revenue;
        } // combo


        if (machine.isWasher && machine.isDryer) {
          // not sure why we are doing totals here when we are getting washer/dryer totals from the back already ~ap
          totals.comboTotals.count += machine.currentMachineCount;
          totals.comboTotals.turns += machine.turns;
          totals.comboTotals.averageTurns += machine.averageTurns;

          if (machine.coinVaultOpening) {
            totals.comboTotals.coinVaultOpeningYes += 1;
          } else {
            totals.comboTotals.coinVaultOpeningNo += 1;
          }

          totals.comboTotals.modifierRevenue += machine.modifierRevenue;
          totals.comboTotals.revenue += machine.revenue;
        }
      });
      let detailsData = {
        machines: data,
        totals
      };
      return detailsData;
    } // computed('revenueOverviewData.machines', 'revenueDetailsData.machines',


    get cyclesChartData() {
      let overview = this.revenueOverviewData.machines;
      let details = this.revenueDetailsData.machines;
      let washerLabels = [];
      let washerCapacityCycles = [];
      let washerTotalCycles = 0;
      let washerRevenue = [];
      let dryerLabels = [];
      let dryerCapacityCycles = [];
      let dryerTotalCycles = 0;
      let dryerRevenue = [];
      let comboLabels = [];
      let comboCapacityCycles = [];
      let comboTotalCycles = 0;
      let comboRevenue = [];
      details.filter(function (machine) {
        if (machine.isWasher && !machine.isDryer) {
          washerLabels.push(machine.machineType);
          washerCapacityCycles.push(machine.turns);
          washerRevenue.push(machine.revenue);
        }

        if (!machine.isWasher && machine.isDryer) {
          dryerLabels.push(machine.machineType);
          dryerCapacityCycles.push(machine.turns);
          dryerRevenue.push(machine.revenue);
        }

        if (machine.isWasher && machine.isDryer) {
          comboLabels.push(machine.machineType);
          comboCapacityCycles.push(machine.turns);
          comboRevenue.push(machine.revenue);
        }
      });
      overview.filter(machine => {
        if (machine.id == "Washers") {
          washerTotalCycles = machine.turns;
        }

        if (machine.id == "Dryers") {
          dryerTotalCycles = machine.turns;
        }

        if (machine.id == "Combos") {
          comboTotalCycles = machine.turns;
        }
      }); // TODO(alexpaluzzi): need to break this up

      return {
        washerLabels,
        washerCapacityCycles,
        washerTotalCycles,
        washerRevenue,
        dryerLabels,
        dryerCapacityCycles,
        dryerTotalCycles,
        dryerRevenue,
        comboLabels,
        comboCapacityCycles,
        comboTotalCycles,
        comboRevenue
      };
    } // Overview


    get chartRevCyclesOptions() {
      return {
        chart: {
          type: "column"
        },
        colors: ["#49a2e6", "#f48180"],
        title: {
          text: this.intl.t("cycles")
        },
        xAxis: {
          categories: [""],
          crosshair: true
        },
        yAxis: {
          min: 0,
          title: {
            text: this.intl.t("cycles")
          }
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0
          }
        }
      };
    } // 'cyclesChartData.{dryerTotalCycles,washerTotalCycles}'


    get chartRevCyclesContent() {
      let data = [{
        name: this.intl.t("washers"),
        data: [this.cyclesChartData.washerTotalCycles],
        dataLabels: {
          enabled: true
        }
      }, {
        name: this.intl.t("dryers"),
        data: [this.cyclesChartData.dryerTotalCycles],
        dataLabels: {
          allowOverlap: true,
          enabled: true
        }
      }];

      if (this.cyclesChartData.comboTotalCycles) {
        data.push({
          name: this.intl.t("combos"),
          data: [this.cyclesChartData.comboTotalCycles],
          dataLabels: {
            allowOverlap: true,
            enabled: true
          }
        });
      }

      console.log("this.cyclesChartData", this.cyclesChartData);
      return data;
    }

    get chartRevOverviewOptions() {
      let self = this;
      return {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: "pie"
        },
        colors: ["#49a2e6", "#f48180", "#a1e989", "#ef9d4a"],
        title: {
          text: this.intl.t("revenue")
        },
        tooltip: {
          pointFormatter() {
            return `${this.name}: <b>${_currencyFormatUtil.formatCurrencyString.call(self, this.y)}</b>`;
          }

        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              allowOverlap: true,
              enabled: true,

              formatter() {
                return _currencyFormatUtil.formatCurrencyString.call(self, this.y);
              }

            },
            showInLegend: false
          }
        },
        responsive: {
          rules: [{
            condition: {
              maxWidth: 500
            },
            chartOptions: {}
          }]
        }
      };
    }

    get chartRevOverviewContent() {
      let overview = this.args.model.get("overview");
      let revOverview = [];
      overview.forEach(function (machine) {
        if (machine.id !== "Totals") {
          revOverview.push({
            name: machine.id,
            y: machine.revenue && machine.revenue >= 0 ? machine.revenue : 0
          });
        }
      });
      return [{
        data: revOverview
      }];
    }

    get washerData() {
      let data = this.args.model.get("details");
      let washers = data.filter(function (machine) {
        if (!machine.isDryer && machine.isWasher) {
          return true;
        }
      });
      return washers;
    }

    get dryerData() {
      let data = this.args.model.get("details");
      let washers = data.filter(function (machine) {
        if (machine.isDryer && !machine.isWasher) {
          return true;
        }
      });
      return washers;
    }

    get comboData() {
      let data = this.args.model.get("details");
      let washers = data.filter(function (machine) {
        if (machine.isDryer && machine.isWasher) {
          return true;
        }
      });
      return washers;
    }

    get washerTotalsData() {
      let data = this.args.model.get("overview");

      for (let i = 0; i < data.length; i++) {
        let total = data[i];

        if (total.id === "Washers") {
          return total;
        }
      }

      return {};
    }

    get washerTableData() {
      let data = this.args.model.get("details");
      return data.filter(d => {
        if (d.isWasher && !d.isDryer) {
          return d;
        }
      });
    }

    get dryerTableData() {
      let data = this.args.model.get("details");
      return data.filter(d => {
        if (d.isDryer && !d.isWasher) {
          return d;
        }
      });
    }

    get comboTableData() {
      let data = this.args.model.get("details");
      return data.filter(d => {
        if (d.isDryer && d.isWasher) {
          console.log("d", d);
          return d;
        }
      });
    } // Washer Breakdown
    // Overview


    get chartWasherBreakdownCyclesOptions() {
      return {
        chart: {
          type: "column"
        },
        title: {
          text: this.intl.t("cycles")
        },
        // subtitle: {
        //     text: 'Source: WorldClimate.com'
        // },
        xAxis: {
          categories: [""],
          crosshair: true
        },
        yAxis: {
          min: 0,
          title: {
            text: this.intl.t("cycles")
          }
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0
          }
        }
      };
    }

    get chartWasherBreakdownCyclesContent() {
      let cycleData = [];
      this.washerData.forEach(function (machine) {
        cycleData.push({
          name: machine.machineType,
          data: [machine.turns],
          dataLabels: {
            allowOverlap: true,
            enabled: true
          }
        });
      });
      return cycleData;
    } // Washers


    get chartWasherBreakdownTotalRevOptions() {
      let self = this;
      return {
        chart: {
          type: "column"
        },
        title: {
          text: this.intl.t("revenue")
        },
        tooltip: {
          pointFormatter() {
            return `${this.series.name}: <b>${_currencyFormatUtil.formatCurrencyString.call(self, this.y)}</b>`;
          }

        },
        xAxis: {
          categories: [""],
          crosshair: true
        },
        yAxis: {
          min: 0,
          title: {
            text: "Revenue"
          },
          labels: {
            formatter() {
              return _currencyFormatUtil.formatCurrencyString.call(self, this.value);
            }

          }
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0
          }
        }
      };
    }

    get chartWasherBreakdownTotalRevContent() {
      let totalRevs = [];
      let self = this;
      this.washerData.forEach(function (machine) {
        totalRevs.push({
          name: machine.machineType,
          data: [machine.revenue],
          dataLabels: {
            allowOverlap: true,
            enabled: true,

            formatter() {
              return _currencyFormatUtil.formatCurrencyString.call(self, machine.revenue);
            }

          }
        });
      });
      return totalRevs;
    }

    get chartWasherBreakdownTurnsOptions() {
      return {
        chart: {
          type: "column"
        },
        title: {
          text: this.intl.t("turns_per_day")
        },
        // subtitle: {
        //     text: 'Source: WorldClimate.com'
        // },
        xAxis: {
          categories: [""],
          crosshair: true
        },
        yAxis: {
          min: 0,
          title: {
            text: this.intl.t("turns_per_day")
          }
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0
          }
        }
      };
    }

    get chartWasherBreakdownTurnsContent() {
      let turns = [];
      this.washerData.forEach(function (machine) {
        turns.push({
          name: machine.machineType,
          data: [machine.averageTurns],
          dataLabels: {
            allowOverlap: true,
            enabled: true
          }
        });
      });
      return turns;
    } // Dryers


    get chartDryerBreakdownTotalRevOptions() {
      let self = this;
      return {
        chart: {
          type: "column"
        },
        title: {
          text: this.intl.t("revenue")
        },
        tooltip: {
          pointFormatter() {
            return `${this.series.name}: <b>${_currencyFormatUtil.formatCurrencyString.call(self, this.y)}</b>`;
          }

        },
        xAxis: {
          categories: [""],
          crosshair: true
        },
        yAxis: {
          min: 0,
          title: {
            text: this.intl.t("revenue")
          },
          labels: {
            formatter() {
              return _currencyFormatUtil.formatCurrencyString.call(self, this.value);
            }

          }
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0
          }
        }
      };
    }

    get chartDryerBreakdownTotalRevContent() {
      let totalRevs = [];
      let self = this;
      this.dryerData.forEach(function (machine) {
        totalRevs.push({
          name: machine.machineType,
          data: [machine.revenue],
          dataLabels: {
            allowOverlap: true,
            enabled: true,

            formatter() {
              return _currencyFormatUtil.formatCurrencyString.call(self, machine.revenue);
            }

          }
        });
      });
      return totalRevs;
    } // Combos


    get chartComboBreakdownTotalRevOptions() {
      let self = this;
      return {
        chart: {
          type: "column"
        },
        title: {
          text: this.intl.t("revenue")
        },
        tooltip: {
          pointFormatter() {
            return `${this.series.name}: <b>${_currencyFormatUtil.formatCurrencyString.call(self, this.y)}</b>`;
          }

        },
        xAxis: {
          categories: [""],
          crosshair: true
        },
        yAxis: {
          min: 0,
          title: {
            text: this.intl.t("revenue")
          },
          labels: {
            formatter() {
              return _currencyFormatUtil.formatCurrencyString.call(self, this.value);
            }

          }
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0
          }
        }
      };
    }

    get chartComboBreakdownTotalRevContent() {
      let totalRevs = [];
      let self = this;
      this.comboData.forEach(function (machine) {
        totalRevs.push({
          name: machine.machineType,
          data: [machine.revenue],
          dataLabels: {
            allowOverlap: true,
            enabled: true,

            formatter() {
              return _currencyFormatUtil.formatCurrencyString.call(self, machine.revenue);
            }

          }
        });
      });
      return totalRevs;
    } // actions: {
    //   /**
    //    * Show the properties of the model.
    //    *
    //    * @public
    //    */
    //   show(model) {
    //     this.brush.transitionTo('machine', model, 'show');
    //   }
    // }


  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "brush", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SummaryReportsSLocationAndRevenueComponent;
});