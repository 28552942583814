define("alliance-business-suite/components/form-ui/speed-select", ["exports", "alliance-business-suite/utils/speed-utilities", "alliance-business-suite/mixins/init-property-names"], function (_exports, _speedUtilities, _initPropertyNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_initPropertyNames.default, {
    speedSettings: Ember.computed('showG', 'machineProgram._showG', 'basketDiameter', 'machineSettings.extract', function () {
      // Returns the min/max/presets
      let settings = {};
      settings = this.machineSettings.extract;
      Ember.set(settings, 'basketDiameter', this.basketDiameter);

      if (this.basketDiameter === 0) {
        Ember.set(settings, 'showG', true); // If no basket size, show G only.
      } else {
        Ember.set(settings, 'showG', this?.machineProgram?._showG ?? this.showG ?? false);
      }

      return settings;
    }),
    hasDistribution: Ember.computed('params.display', function () {
      let display = this.params.display;
      return display === 'distribution';
    }),
    hasRotationDelay: Ember.computed('object.AlwaysRotate', function () {
      return !Ember.isEmpty(this.object.AlwaysRotate);
    }),
    optionList: Ember.computed('params.display', function () {
      return 'SpinSpeedExtract';
    }),
    displayType: Ember.computed('params.display', function () {
      return this.params.display;
    }),
    basketDiameter: Ember.computed.alias('machineSettings.basketDiameter'),
    selectedSpeed: Ember.computed('object.Speed', {
      get() {
        return this.object.Speed;
      },

      set(key, value) {}

    }),
    showG: Ember.computed('formSettings.showG', 'basketDiameter', function () {
      // If basketDiameter is 0, only show g-force.
      let basket = this.basketDiameter;
      let showG = this.formSettings.showG; // Without basket size, show G

      if (basket == 0) {
        return true;
      }

      return this.formSettings?.showG ?? this.params?.machineProgram?._showG ?? true;
    }),
    showPresets: Ember.computed('basketDiameter', function () {
      // If basketDiameter is 0, no presets
      return this.basketDiameter !== 0;
    })
  });

  _exports.default = _default;
});