define("alliance-business-suite/templates/components/guests/g-transactions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rywtm8TZ",
    "block": "{\"symbols\":[\"o\"],\"statements\":[[6,[37,6],[[35,5]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"ui-loading-throb\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"c-transaction\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"icon-img\"],[12],[2,\"\\n        \"],[10,\"img\"],[14,\"src\",\"/assets/images/icon-funds-deposit.svg\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"t-content\"],[12],[2,\"\\n        \"],[10,\"h3\"],[14,0,\"pull-left\"],[12],[1,[30,[36,0],[[32,1,[\"serviceType\",\"lookupKey\"]]],null]],[13],[2,\"\\n        \"],[10,\"h3\"],[14,0,\"pull-right amount\"],[12],[2,\"\\n          \"],[8,\"currency-options\",[],[[\"@value\",\"@isInput\"],[[32,1,[\"totalCost\"]],false]],null],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"p\"],[12],[2,\"\\n          \"],[10,\"small\"],[12],[2,\"\\n            \"],[1,[32,1,[\"orderNumber\"]]],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"p\"],[14,0,\"date\"],[12],[2,\"\\n          \"],[1,[30,[36,1],[[32,1,[\"orderDate\"]]],null]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[2,\"    \"],[10,\"h2\"],[14,0,\"no-data\"],[12],[1,[30,[36,0],[\"no_orders\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"utc-format\",\"orders\",\"-track-array\",\"each\",\"isLoading\",\"if\"]}",
    "moduleName": "alliance-business-suite/templates/components/guests/g-transactions.hbs"
  });

  _exports.default = _default;
});