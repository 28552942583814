define("alliance-business-suite/helpers/cycles-name", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // TODO: Add translations
  // export default Helper.helper(function([index, collection, ...rest]) {
  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),

    /*
      Using this helper should look like this:
        cycles-name index cyclesArray
         cyclesArray is an array of cycle names as strings
    */
    compute(_ref) {
      let [index, collection, ...rest] = _ref;
      let t = this.intl;

      if (collection && index != null) {
        let name = collection[index];
        return t.t(name.underscore());
      } else {
        return t.t('unknown');
      }
    }

  });

  _exports.default = _default;
});