define("alliance-business-suite/controllers/machines/programs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notifications: Ember.inject.service('notifications'),

    toggleProgramModal() {
      this.toggleProperty('showProgramModal');
    },

    actions: {
      toggleModal(model) {
        Ember.set(this, 'selectedMachineProgram', model);
        this.toggleProperty('showProgramModal');
      }

    }
  });

  _exports.default = _default;
});