define("alliance-business-suite/serializers/gateway", ["exports", "lodash", "alliance-business-suite/serializers/application"], function (_exports, _lodash, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    /**
     * Whether `includes` from the JSON API response should be pushed to the
     * store.
     *
     * @type {boolean}
     * @protected
     */
    allowIncludes: false,

    /**
     * Hacks a JSON API data object returned within a JSON API document to
     * conform to what is required to make the endpoint work properly with Ember
     * Data and within this application.
     *
     * @param {object} data A data object from a JSON API document
     * @protected
     */
    normalizeDataHack(data) {
      // Convert `raspberryPiType` into a string value
      if (_lodash.default.get(data, 'attributes.raspberryPiType.id') != null) {
        data.attributes.raspberryPiType = data.attributes.raspberryPiType.id;
      }
    }

  });

  _exports.default = _default;
});