define("alliance-business-suite/components/payment/modal-upgrade-form", ["exports", "alliance-business-suite/mixins/account-payment", "alliance-business-suite/config/environment"], function (_exports, _accountPayment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_accountPayment.default, {
    session: Ember.inject.service(),
    actions: {
      cancel() {
        let session = this.session;
        session.invalidate();
      },

      close() {
        this.close();
      }

    }
  });

  _exports.default = _default;
});