define("alliance-business-suite/components/rewards/modal-machine-type", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'programMachine.machineTypeLookupKey': [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    'programMachine.runFromDate': [(0, _emberCpValidations.validator)('presence', {
      dependentKeys: ['model.programMachine.dateRange'],
      presence: true,
      disabled: Ember.computed('model.programMachine.dateRange', function () {
        return this.model.get('programMachine.dateRange') === 'FOREVER' ? true : false;
      })
    })],
    'isSaving': [(0, _emberCpValidations.validator)('presence', {
      presence: false,
      disabled: Ember.computed('model.isSaving', function () {
        return this.get('isSaving') === true ? true : false;
      })
    })],
    'programMachine.pointsPerUnitUsage': [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      // must allow string, cannot get it to work otherwise (use input type="number" to prevent strings instead)
      gt: 0,
      lte: 100
    })]
  });
  const SCHEDULE_DATE_OPTIONS = ['FOREVER', 'CUSTOM'];

  var _default = Ember.Component.extend(Validations, {
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    access: Ember.inject.service(),
    brand: Ember.inject.service(),
    notifications: Ember.inject.service(),
    machineDateOptions: Ember.computed(function () {
      return SCHEDULE_DATE_OPTIONS;
    }),
    machineTypes: Ember.computed('programMachine.machineTypeLookupKey', function () {
      let programMachines = this.get('programMachine.rewardsProgram.rewardsProgramMachineTypes');
      let machines = this.store.peekAll('machineType').sortBy('name');
      let filteredMachines = [];
      machines.forEach(machine => {
        Ember.set(machine, 'disabled', false); // for Huebsch brand, remove machines such as 'SY240H', 'SY280H', etc

        if (this.brand.name === 'HUEBSCH' && machine.name.indexOf('SY') != -1) {
          return; // exit loop, don't push
        }

        filteredMachines.push(machine);
      });

      if (programMachines) {
        programMachines.forEach(function (programMachine) {
          filteredMachines.forEach(function (machine) {
            if (programMachine.get('machineTypeLookupKey') == machine.get('id')) {
              Ember.set(machine, 'disabled', true);
            }
          });
        });
      }

      return filteredMachines;
    }),
    minFromDate: Ember.computed(function () {
      return moment().toDate();
    }),
    minToDate: Ember.computed('programMachine.runFromDate', function () {
      let runFromDate = this.get('programMachine.runFromDate') || moment();
      return moment(runFromDate).add(1, 'days').toDate();
    }),
    selectedProgramMachine: Ember.computed('programMachine.machineTypeLookupKey', function () {
      let programMachine = this.programMachine;

      if (programMachine.get('machineTypeLookupKey')) {
        return this.store.peekRecord('machineType', programMachine.get('machineTypeLookupKey'));
      }
    }),
    actions: {
      exit() {
        if (this.isSaving) {
          return;
        }

        this.programMachine.rollbackAttributes();
        this.close();
      },

      setDate(model, property, value) {
        let date = value;

        if (moment(date).isValid()) {
          date = moment(value).format('YYYY-MM-DDTHH:mm:ss');
        }

        model.set(property, date);
      },

      setMachineType(machineType) {
        let programMachine = this.programMachine;
        programMachine.set('machineTypeLookupKey', machineType.get('id'));
      },

      saveMachine(programMachine) {
        this.set('isSaving', true);
        programMachine.save({
          adapterOptions: {
            programId: programMachine.get('rewardsProgram.id')
          }
        }).then(() => {
          this.set('isSaving', false);
          this.close();
          this.notifications.info(this.intl.t('machine_type_reward_saved'), {
            autoClear: true,
            clearDuration: 2000
          });
        });
      },

      deleteMachine(programMachine) {
        this.set('isSaving', true);
        programMachine.destroyRecord({
          adapterOptions: {
            programId: programMachine.get('rewardsProgram.id')
          }
        }).then(() => {
          this.set('isSaving', false);
          this.close();
          this.notifications.error(this.intl.t('machine_type_reward_deleted'), {
            autoClear: true,
            clearDuration: 2000
          });
        });
      }

    }
  });

  _exports.default = _default;
});