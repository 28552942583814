define("alliance-business-suite/components/machines/traceability/sidebar/trace-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    
  <li class="wash-group-drawer {{if (eq @option @selectedDetailType) 'active' 'inactive'}}">
    <button
      class="btn-toggle {{if (eq @option @selectedDetailType) 'active' 'inactive'}}"
      {{on "click" (fn @updateSelectedTraceItem @option)}}>
  
      <span class="icon">
        {{#if (eq @option "general")}}
          <i class="fa fa-cog" aria-hidden="true"></i>
        {{/if}}
  
        {{#if (eq @option "steps")}}
          <i class="fa fa-list" aria-hidden="true"></i>
        {{/if}}
  
        {{#if (eq @option "data")}}
          <i class="fa fa-area-chart" aria-hidden="true"></i>
        {{/if}}
  
        {{#if (eq @option "errors")}}
          <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
        {{/if}}
      </span>
  
      <span class="info">
        {{@option}}
      </span>
    </button>
  </li>
  
  */
  {
    "id": "7wGKujfz",
    "block": "{\"symbols\":[\"@selectedDetailType\",\"@option\",\"@updateSelectedTraceItem\"],\"statements\":[[2,\"\\n\"],[10,\"li\"],[15,0,[31,[\"wash-group-drawer \",[30,[36,1],[[30,[36,0],[[32,2],[32,1]],null],\"active\",\"inactive\"],null]]]],[12],[2,\"\\n  \"],[11,\"button\"],[16,0,[31,[\"btn-toggle \",[30,[36,1],[[30,[36,0],[[32,2],[32,1]],null],\"active\",\"inactive\"],null]]]],[4,[38,3],[\"click\",[30,[36,2],[[32,3],[32,2]],null]],null],[12],[2,\"\\n\\n    \"],[10,\"span\"],[14,0,\"icon\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[32,2],\"general\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"i\"],[14,0,\"fa fa-cog\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[32,2],\"steps\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"i\"],[14,0,\"fa fa-list\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[32,2],\"data\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"i\"],[14,0,\"fa fa-area-chart\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[32,2],\"errors\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"i\"],[14,0,\"fa fa-exclamation-circle\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\\n    \"],[10,\"span\"],[14,0,\"info\"],[12],[2,\"\\n      \"],[1,[32,2]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\",\"fn\",\"on\"]}",
    "moduleName": "alliance-business-suite/components/machines/traceability/sidebar/trace-details.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});