define("alliance-business-suite/models/security-role-functional-area", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    hasMany,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    lookupKey: attr(),
    description: attr(),
    securityFunctionalAreaGroups: hasMany('securityFunctionalAreaGroup'),
    securityFunctionalAreas: hasMany('securityFunctionalArea'),
    securityRole: belongsTo('security-role')
  });

  _exports.default = _default;
});