define("alliance-business-suite/helpers/tm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.getLocaleKeys = getLocaleKeys;
  _exports.getModelLocaleKey = getModelLocaleKey;

  function getModelName(model) {
    // only use old code if model.constructor is defined
    if (model.constructor) {
      return model.constructor.modelName || model.modelName || model.type && model.type.modelName;
    } else {
      // else just do the same thing without the model.constructor reference
      return model.modelName || model.type && model.type.modelName;
    }
  }
  /**
   * Returns the locale key used to create a translation for the `field` or
   * `value` of the field on the `model`.
   *
   * @param  {DS.Model|string} model An instance or name of a model
   * @param  {string}          field The name of a field on the model
   * @param  {string}          value An optional value
   *
   * @return {string} The translated label for the field on the model.
   *
   * @public
   */


  function getModelLocaleKey(model, field, value) {
    (true && !(model != null) && Ember.assert('The model parameter cannot be null or undefined', model != null));
    (true && !(field != null) && Ember.assert('The field parameter cannot be null or undefined', field != null));
    let modelName = typeof model === 'string' ? model : getModelName(model);

    if (modelName == null) {
      // Check if it is a promise and handle specifically
      if (model.isFulfilled && model.content) {
        modelName = getModelName(model.content);
      } else if (model.isPending) {
        throw new Error('The model must have a "modelName" property; it is an' + ' unfulfilled promise');
      }
    }

    if (modelName == null) {
      // console.log(
      //   'modelName', modelName,
      //   '\nmodel.constructor?', model.constructor ? 'true' : 'false'
      // );
      // if (model.consctructor) {
      //   console.log(
      //     'model.constructor.modelName', model.constructor.modelName,
      //     '\nmodel.modelName', model.modelName,
      //     '\nresult', model.constructor.modelName || model.modelName
      //   );
      // } else {
      //   console.log(
      //     'model.type', model.type,
      //     '\nmodel.type.modelName',
      //       (model.type ? model.type.modelName : 'model.type is undefined! refusing to touch .modelName'),
      //     '\nmodel.type && model.type.modelName', model.type && model.type.modelName
      //   );
      // }
      // console.log(
      //   'model', model,
      //   '\nfield', field,
      //   '\nvalue', value
      // );
      modelName = 'NO_MODEL_NAME'; // eslint-disable-next-line

      console.warn('The model does not have a "modelName" property, using "NO_MODEL_NAME"'); // this console.error could include a LOT of info that
      // is logged right above this code
    }

    field = Ember.String.dasherize(field);
    modelName = Ember.String.dasherize(modelName); // TODO: Think about making this format configurable [twl 30.Mar.17]
    // eslint-disable-next-line prefer-template

    return `models.${modelName}.${field}${typeof value !== 'undefined' ? '.' + value : ''}`;
  }
  /**
   * Returns the locale keys for the `variant` of the `field` on the `model`,
   * allowing for overrides using the `intlPrefix`. The keys returned include:
   *
   *  - The variant of the field under the `intlPrefix`
   *  - The field under the `intlPrefix`
   *  - The variant of the field under the model path
   *  - The field under the model path
   *
   * Invalid keys are not included (e.g. if `variant` is undefined, the variant
   * keys will not be returned).
   *
   * @param {string}   intlPrefix The prefix to use to generate locale keys
   * @param {DS.Model} model      The instance of the model the field belongs to
   * @param {string}   field      The field to translate
   * @param {string}   variant    The variant to provide, e.g. `aria-label`
   *
   * @return {Array<string>} An array of locale keys
   * @public
   */


  function getLocaleKeys(intlPrefix, model, field, variant) {
    let fieldKey = getModelLocaleKey(model, field);
    let keys = [];

    if (intlPrefix) {
      if (variant) {
        keys.push(`${intlPrefix}.${field}.${variant}`);
      }

      keys.push(`${intlPrefix}.${field}`);
    }

    if (variant) {
      keys.push(`${fieldKey}.${variant}`);
    }

    keys.push(fieldKey);
    return keys;
  }
  /**
   * Returns the translated label for a field on a model.
   *
   * @public
   */


  var _default = Ember.Helper.extend({
    /**
     * The service used to do the translation.
     *
     * @protected
     */
    intl: Ember.inject.service(),

    /**
     * Computes the translated label for the field on the model.
     *
     * @param  {DS.Model|string} model An instance or name of a model
     * @param  {string}          field The name of a field on the model
     * @param  {string}          value An optional value
     * @param  {string}          defaultValue The value to use if `value` is null
     *                                        or undefined
     *
     * @return {string} The translated label for the field on the model.
     *
     * @public
     */
    compute(_ref, _ref2) {
      let [model, field, value] = _ref;
      let {
        defaultValue
      } = _ref2;

      if (defaultValue && value == null) {
        value = defaultValue;
      }

      return Ember.get(this, 'intl').t(getModelLocaleKey(model, field, value));
    },

    /**
     * Recompute whenever the locale changes.
     *
     * @private
     */
    _recomputeOnLocaleChange: Ember.observer('intl.locale', function () {
      this.recompute();
    })
  });

  _exports.default = _default;
});