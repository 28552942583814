define("alliance-business-suite/helpers/cycles-step-name", ["exports", "alliance-business-suite/utils/cycles-values"], function (_exports, _cyclesValues) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // TODO: Add translations
  var _default = Ember.Helper.helper(function (_ref) {
    let [value, ...rest] = _ref;
    return _cyclesValues.STEP_TYPES[value];
  });

  _exports.default = _default;
});