define("alliance-business-suite/helpers/plural", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (_ref) {
    let [number, single, plural] = _ref;

    if (number === 1) {
      return single;
    } else {
      return plural;
    }
  });

  _exports.default = _default;
});