define("alliance-business-suite/utils/fetch-localizations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fetchLocalizations = fetchLocalizations;

  async function fetchLocalizations(locale) {
    let fetchLocale = locale ? locale : this.intl.locale[0];

    if (fetchLocale.indexOf('_')) {
      fetchLocale = fetchLocale.toLowerCase().replace('_', '-');
    } // protects against loading en-us twice during initilization due to race conditions


    if (fetchLocale == 'en-us' && this.intl.exists('all', 'en-us')) {
      // the second language in the array is the fallback language
      this.intl.setLocale([fetchLocale, 'en-us']);
      return;
    }

    let url = fetchLocale;

    if (fetchLocale === 'th') {
      url = 'th-merged-2';
    }

    const translations = this.ajax.request(`https://alliancelabs-assets-production.s3.amazonaws.com/localizations/${url}.json`, {
      method: 'GET'
    }).then(response => {
      return response;
    });
    this.intl.addTranslations(fetchLocale, await translations); // the second language in the array is the fallback language

    this.intl.setLocale([fetchLocale, 'en-us']); // because of lazy loading languages, we need to fetch en-us as our fallback
    // this means intl orgs will do two network calls for localizations

    if (fetchLocale != 'th') {
      if (fetchLocale != 'en-us' && !this.intl.exists('all', 'en-us')) {
        const fallback = this.ajax.request(`https://alliancelabs-assets-production.s3.amazonaws.com/localizations/en-us.json`, {
          method: 'GET'
        }).then(response => {
          return response;
        });
        this.intl.addTranslations('en-us', await fallback);
      }
    }

    return;
  }
});