define("alliance-business-suite/components/machines/programs/special-vend/modal-midas-wx", ["exports", "alliance-business-suite/utils/number-to-hour", "alliance-business-suite/utils/format-currency", "alliance-business-suite/mixins/init-property-names", "alliance-business-suite/mixins/modals", "alliance-business-suite/mixins/machines/programs/midas-property-names", "alliance-business-suite/utils/array-utils"], function (_exports, _numberToHour, _formatCurrency, _initPropertyNames, _modals, _midasPropertyNames, _arrayUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function formatTime(minutes, seconds) {
    return `${minutes}:${seconds.toString().padStart(2, 0)}`;
  }

  var _default = Ember.Component.extend(_initPropertyNames.default, _modals.default, _midasPropertyNames.default, {
    intl: Ember.inject.service(),
    // The observers are a mess because CyclePrices.@each does not work. Will be refactored when we move to Octane.
    summaryText: Ember.computed('CyclePrices.{0,1,2,3,4,5,6,7,8,9,10,11,12,13,14}', 'CyclePrices2.{0,1,2,3}', 'Duration', 'Enabled', 'EndDate', 'EndMonth', 'EndYear', 'Friday', 'Monday', 'Saturday', 'StartDate', 'StartHour', 'StartMinute', 'StartMonth', 'StartYear', 'Sunday', 'Thursday', 'Tuesday', 'Wednesday', 'formSettings', 'object.Cycles', 'selectOptions.CyclesWx', function () {
      let output = '';
      let intl = this.intl;

      if (!this.Enabled || this.Enabled === false) {
        return 'Off';
      } // When: Days (dates) (hours)
      // Every Day; Weekends; Weekdays; Mon-Wed, Fri


      let daysText = "";

      if (this.Saturday && this.Sunday && this.Monday && this.Tuesday && this.Wednesday && this.Thursday && this.Friday) {
        daysText = 'Every Day, ';
      } else {
        // Weekends
        if (this.Saturday && this.Sunday) {
          daysText += ' Weekends, ';
        } else if (this.Saturday) {
          daysText += 'Sat, ';
        } else if (this.Sunday) {
          daysText += 'Sun, ';
        } // Weekdays


        if (this.Monday && this.Tuesday && this.Wednesday && this.Thursday && this.Friday) {
          daysText += 'Weekdays, ';
        } else {
          if (this.Monday) {
            daysText += 'Mon, ';
          }

          if (this.Tuesday) {
            daysText += 'Tue, ';
          }

          if (this.Wednesday) {
            daysText += 'Wed, ';
          }

          if (this.Thursday) {
            daysText += 'Thu, ';
          }

          if (this.Friday) {
            daysText += 'Fri, ';
          }
        }

        if (daysText) {
          output += daysText;
        } else {
          output += `${intl.t("no_days_selected")}, `;
        } // Dates - this has some logic. If the day, month, or year has a value of 0, date limit is not enabled. If start = 1/1/2000 and end = 12/31/2099, also has no limit.


        if (this.StartMonth == 0 || this.StartDate == 0 || this.EndMonth == 0 || this.EndDate == 0 || this.StartMonth == 1 && this.StartDate == 1 && this.StartYear == 0 && this.EndMonth == 12 && this.EndDate == 31 && this.EndYear == 99) {// No date limits
        } else {
          output += `(${this.StartMonth}/${this.StartDate}/${this.StartYear}&ndash;${this.EndMonth}/${this.EndDate}/${this.EndYear})  `;
        } // Hours - this seems to have some logic. If duration is 0, not enabled.


        if (this.Duration > 0) {
          // We'll need to figure out the end time
          let startHour = (0, _numberToHour.default)(this.StartHour);
          let endHour = (0, _numberToHour.default)(Number(this.StartHour) + Number(this.Duration));
          output += `(${startHour}&ndash;${endHour}), `;
        }

        if (output.length > 0) {
          output = `<strong>${output}</strong>`;
        }
      } // Show Actions
      // Change prices (Normal $0.00, Synthetics $0.00, Delicates/Bulky $0.00, Colors $0.00, Whites $0.00, Mix $0.00, Woolens $0.00, Delicate $0.00, Bedding $0.00,)


      let cycles = this.selectOptions.CyclesWx;
      let actionOutput = '';
      let enabledCycles = cycles.filter(item => {
        let cycle = this.object.Cycles.objectAt(item.index);
        return cycle ? Ember.get(cycle, "Enabled") : false;
      });
      let allCycles = (0, _arrayUtils.arrayCollection)(this.CyclePrices, this.CyclePrices2);
      enabledCycles.forEach(item => {
        let name = this.object.Cycles[item.index].Name;
        let price = (0, _formatCurrency.default)(allCycles[item.index], this.formSettings);
        actionOutput += `, ${name} ${price}`;
      });
      actionOutput = actionOutput.substring(2);

      if (actionOutput) {
        output += `<br>${this.intl.t('change_prices')} (${actionOutput})`;
      }

      return Ember.String.htmlSafe(output);
    }),
    defaultModifier: Ember.computed('DefaultReversing', 'DefaultAntiWrinkle', function () {
      let reversing = this.DefaultReversing;
      let antiWrinkle = this.DefaultAntiWrinkle;
      if (!reversing && !antiWrinkle) return 1;
      if (!reversing && antiWrinkle) return 2;
      if (reversing && !antiWrinkle) return 3;
      if (reversing && antiWrinkle) return 4;
      return null;
    }),
    actions: {
      updateValues(obj, prop, value) {
        let object = this.object;
        Ember.set(this, 'defaultModifier', value);

        switch (value) {
          case 1:
            // None
            // Do nothing
            this.update(object, this.properties.DefaultReversing.key, false);
            this.update(object, this.properties.DefaultAntiWrinkle.key, false);
            break;

          case 2:
            // Anti-Wrinkle
            this.update(object, this.properties.DefaultReversing.key, false);
            this.update(object, this.properties.DefaultAntiWrinkle.key, true);
            this.update(object, this.properties.AntiWrinkle.key, true);
            break;

          case 3:
            // Reversing
            this.update(object, this.properties.DefaultReversing.key, true);
            this.update(object, this.properties.DefaultAntiWrinkle.key, false);
            this.update(object, this.properties.Reversing.key, true);
            break;

          case 4:
            // Both
            this.update(object, this.properties.DefaultReversing.key, true);
            this.update(object, this.properties.DefaultAntiWrinkle.key, true);
            this.update(object, this.properties.AntiWrinkle.key, true);
            this.update(object, this.properties.Reversing.key, true);
            break;
        }
      }

    },
    // Need to observe any properties used for enabled, etc.
    formConfig: Ember.computed('bundle1Name', 'bundle2Name', 'index', 'indexValue', 'isCelsius', 'machineProgram.settings.Program.Price.ModifierOptions.CycleModifierName_WX', 'machineSettings', 'object.Cycles', 'object.Program.Price.ModifierOptions.CycleModifierName_WX', 'selectOptions.CyclesWx', function () {
      let index = this.indexValue;
      return {
        columns: [{
          sections: [{
            title: "Enabled",
            controls: [{
              type: "checkbox",
              propertyName: "SpecialVend.Items.${index}.DOWEn.Enabled",
              index: index
            }]
          }, {
            title: "Dates",
            controls: [{
              type: "limit-dates",
              properties: {
                startYear: "SpecialVend.Items.${index}.StartYear",
                startMonth: "SpecialVend.Items.${index}.StartMonth",
                startDate: "SpecialVend.Items.${index}.StartDate",
                endYear: "SpecialVend.Items.${index}.EndYear",
                endMonth: "SpecialVend.Items.${index}.EndMonth",
                endDate: "SpecialVend.Items.${index}.EndDate"
              }
            }]
          }, {
            title: "Times",
            controls: [{
              type: "time-span",
              properties: {
                startHour: "SpecialVend.Items.${index}.StartHour",
                startMinute: "SpecialVend.Items.${index}.StartMinute",
                durationHours: "SpecialVend.Items.${index}.Duration"
              },
              hour12: true,
              index: index
            }]
          }, {
            title: "Days",
            controls: [{
              type: "checkbox",
              propertyName: "SpecialVend.Items.${index}.DOWEn.Monday"
            }, {
              type: "checkbox",
              propertyName: "SpecialVend.Items.${index}.DOWEn.Tuesday"
            }, {
              type: "checkbox",
              propertyName: "SpecialVend.Items.${index}.DOWEn.Wednesday"
            }, {
              type: "checkbox",
              propertyName: "SpecialVend.Items.${index}.DOWEn.Thursday"
            }, {
              type: "checkbox",
              propertyName: "SpecialVend.Items.${index}.DOWEn.Friday"
            }, {
              type: "checkbox",
              propertyName: "SpecialVend.Items.${index}.DOWEn.Saturday"
            }, {
              type: "checkbox",
              propertyName: "SpecialVend.Items.${index}.DOWEn.Sunday"
            }]
          }, {
            title: "base_price",
            controls: [{
              type: "base-price-multi-vend",
              arrayNames: ["SpecialVend.Items.${index}.CyclePrices_WX", "SpecialVend.ExpandedPrices.${index}.CyclePrices_WX"],
              propertyName: "SpecialVend.Items.${index}.CyclePrices_WX",
              names: this.machineProgram.settings.Cycles.mapBy('Name'),
              cycleEnabled: this.machineProgram.settings.Cycles.mapBy('Enabled')
            }]
          }]
        }, {
          sections: [{
            title: "Bundle",
            controls: [{
              type: "default-bundle",
              label: "Bundle",
              properties: {
                defaultMedium: 'SpecialVend.Items.${index}.DefaultModifiers_WX.MediumBundle_WX',
                defaultHeavy: 'SpecialVend.Items.${index}.DefaultModifiers_WX.HeavyBundle_WX',
                optionMedium: 'SpecialVend.Items.${index}.ModsAdditionalOptsEnabled_WX.MediumBundle_WX',
                optionHeavy: 'SpecialVend.Items.${index}.ModsAdditionalOptsEnabled_WX.HeavyBundle_WX'
              }
            }]
          }, {
            title: "bundles",
            controls: [{
              type: "checkbox-value-combo",
              properties: {
                checkbox1: {
                  key: "SpecialVend.Items.${index}.ModsAdditionalOptsEnabled_WX.MediumBundle_WX",
                  label: this.bundle1Name,
                  disabled: "SpecialVend.Items.${index}.DefaultModifiers_WX.MediumBundle_WX"
                },
                input1: {
                  type: "currency",
                  label: null,
                  key: "SpecialVend.Items.${index}.ModifierPrices_WX.MediumBundleAdder_WX",
                  min: 0,
                  max: 65535
                },
                button1: {
                  type: "icon",
                  icon: "cog",
                  tooltip: "tooltip_additional_settings",
                  label: this.bundle1Name,
                  modal: "special-vend/midas-wx-modal-medium-bundle",
                  modalTitle: `${this.bundle1Name} ${this.intl.t('bundle')}`,
                  index: index
                }
              }
            }, {
              type: "checkbox-value-combo",
              properties: {
                checkbox1: {
                  key: "SpecialVend.Items.${index}.ModsAdditionalOptsEnabled_WX.HeavyBundle_WX",
                  label: this.bundle2Name,
                  disabled: "SpecialVend.Items.${index}.DefaultModifiers_WX.HeavyBundle_WX"
                },
                input1: {
                  type: "currency",
                  label: null,
                  key: "SpecialVend.Items.${index}.ModifierPrices_WX.HeavyBundleAdder_WX",
                  min: 0,
                  max: 65535
                },
                button1: {
                  type: "icon",
                  icon: "cog",
                  tooltip: "tooltip_additional_settings",
                  label: this.bundle2Name,
                  modal: "special-vend/midas-wx-modal-heavy-bundle",
                  modalTitle: `${this.bundle2Name} ${this.intl.t('bundle')}`,
                  index: index
                }
              }
            }]
          }, {
            title: "modifiers",
            controls: [{
              type: "checkbox-value-combo",
              properties: {
                checkbox1: {
                  key: "SpecialVend.Items.${index}.ModsAdditionalOptsEnabled_WX.DispenseSoap_WX",
                  label: "dispense_soap"
                },
                input1: {
                  type: "currency",
                  label: null,
                  key: "SpecialVend.Items.${index}.ModifierPrices_WX.SupplyInjectionAdder_WX",
                  min: 0,
                  max: 65535
                },
                checkbox2: {
                  label: "Include",
                  key: "SpecialVend.Items.${index}.DefaultModifiers_WX.DispenseSoap_WX",
                  tooltip: "tooltip_include_with_default"
                }
              }
            }, {
              type: "checkbox-value-combo",
              properties: {
                checkbox1: {
                  key: "SpecialVend.Items.${index}.ModsAdditionalOptsEnabled_WX.Ozone_WX",
                  label: "ozone"
                },
                input1: {
                  type: "currency",
                  label: null,
                  key: "SpecialVend.Items.${index}.ModifierPrices_WX.OzoneAdder_WX",
                  min: 0,
                  max: 65535
                },
                checkbox2: {
                  label: "Include",
                  key: "SpecialVend.Items.${index}.DefaultModifiers_WX.Ozone_WX",
                  tooltip: "tooltip_include_with_default"
                }
              }
            }, {
              type: "checkbox-value-combo",
              properties: {
                checkbox1: {
                  key: "SpecialVend.Items.${index}.ModsAdditionalOptsEnabled_WX.Mod1_WX",
                  label: this.modifier1Name
                },
                input1: {
                  type: "currency",
                  label: null,
                  key: "SpecialVend.Items.${index}.ModifierPrices_WX.Mod1Adder_WX",
                  min: 0,
                  max: 65535
                },
                checkbox2: {
                  label: "Include",
                  key: "SpecialVend.Items.${index}.DefaultModifiers_WX.Mod1_WX",
                  tooltip: "tooltip_include_with_default"
                }
              }
            }, {
              type: "checkbox-value-combo",
              properties: {
                checkbox1: {
                  key: "SpecialVend.Items.${index}.ModsAdditionalOptsEnabled_WX.Mod2_WX",
                  label: this.modifier2Name
                },
                input1: {
                  type: "currency",
                  label: null,
                  key: "SpecialVend.Items.${index}.ModifierPrices_WX.Mod2Adder_WX",
                  min: 0,
                  max: 65535
                },
                checkbox2: {
                  label: "Include",
                  key: "SpecialVend.Items.${index}.DefaultModifiers_WX.Mod2_WX",
                  tooltip: "tooltip_include_with_default"
                }
              }
            }, {
              type: "checkbox-value-combo",
              properties: {
                checkbox1: {
                  key: "SpecialVend.Items.${index}.ModsAdditionalOptsEnabled_WX.IncreasedWaterLevel_WX",
                  label: "increase_water_level"
                },
                input1: {
                  type: "currency",
                  label: null,
                  key: "SpecialVend.Items.${index}.ModifierPrices_WX.IncreasedWaterLevelAdder_WX",
                  min: 0,
                  max: 65535
                },
                checkbox2: {
                  label: "Include",
                  key: "SpecialVend.Items.${index}.DefaultModifiers_WX.IncreasedWaterLevel_WX",
                  tooltip: "tooltip_include_with_default"
                }
              }
            }, {
              type: "checkbox-value-combo",
              properties: {
                checkbox1: {
                  key: "SpecialVend.Items.${index}.ModsAdditionalOptsEnabled_WX.ExtraTime_WX",
                  label: "extra_time"
                },
                input1: {
                  type: "currency",
                  label: null,
                  key: "SpecialVend.Items.${index}.ModifierPrices_WX.ExtraTimeAdder_WX",
                  min: 0,
                  max: 65535
                },
                checkbox2: {
                  label: "Include",
                  key: "SpecialVend.Items.${index}.DefaultModifiers_WX.ExtraTime_WX",
                  tooltip: "tooltip_include_with_default"
                }
              }
            }, {
              type: "checkbox-value-combo",
              properties: {
                checkbox1: {
                  key: "SpecialVend.Items.${index}.ModsAdditionalOptsEnabled_WX.WarmFinalRinse_WX",
                  label: "warm_rinse"
                },
                input1: {
                  type: "currency",
                  label: null,
                  key: "SpecialVend.Items.${index}.ModifierPrices_WX.WarmRinseAdder_WX",
                  min: 0,
                  max: 65535
                },
                checkbox2: {
                  label: "Include",
                  key: "SpecialVend.Items.${index}.DefaultModifiers_WX.WarmFinalRinse_WX",
                  tooltip: "tooltip_include_with_default"
                }
              }
            }, {
              type: "checkbox-value-combo",
              properties: {
                checkbox1: {
                  key: "SpecialVend.Items.${index}.ModsAdditionalOptsEnabled_WX.IncreasedSpinSpeed_WX",
                  label: "increase_spin_speed"
                },
                input1: {
                  type: "currency",
                  label: null,
                  key: "SpecialVend.Items.${index}.ModifierPrices_WX.IncreasedSpinSpeedAdder_WX",
                  min: 0,
                  max: 65535
                },
                checkbox2: {
                  label: "Include",
                  key: "SpecialVend.Items.${index}.DefaultModifiers_WX.IncreasedSpinSpeed_WX",
                  tooltip: "tooltip_include_with_default"
                }
              }
            }, {
              type: "checkbox-value-combo",
              properties: {
                checkbox1: {
                  key: "SpecialVend.Items.${index}.ModsAdditionalOptsEnabled_WX.SpeedCycle_WX",
                  label: "speed_cycle"
                },
                input1: {
                  type: "currency",
                  label: null,
                  key: "SpecialVend.Items.${index}.ModifierPrices_WX.SpeedCycleAdder_WX",
                  min: 0,
                  max: 65535
                },
                checkbox2: {
                  label: "Include",
                  key: "SpecialVend.Items.${index}.DefaultModifiers_WX.SpeedCycle_WX",
                  tooltip: "tooltip_include_with_default"
                }
              }
            }]
          }, {
            title: "temperatures",
            controls: [{
              type: "currency",
              propertyName: "SpecialVend.Items.${index}.ModifierPrices_WX.WarmAdder_WX",
              label: "warm",
              min: 0,
              max: 65535
            }, {
              type: "currency",
              propertyName: "SpecialVend.Items.${index}.ModifierPrices_WX.HotAdder_WX",
              label: "hot",
              min: 0,
              max: 65535
            }, {
              type: "currency",
              propertyName: "SpecialVend.Items.${index}.ModifierPrices_WX.Temp15CAdder_WX",
              label: this.isCelsius ? "Temp15C" : "Temp59F",
              min: 0,
              max: 65535
            }, {
              type: "currency",
              propertyName: "SpecialVend.Items.${index}.ModifierPrices_WX.Temp30CAdder_WX",
              label: this.isCelsius ? "Temp30C" : "Temp86F",
              min: 0,
              max: 65535
            }, {
              type: "currency",
              propertyName: "SpecialVend.Items.${index}.ModifierPrices_WX.Temp40CAdder_WX",
              label: this.isCelsius ? "Temp40C" : "Temp104F",
              min: 0,
              max: 65535
            }, {
              type: "currency",
              propertyName: "SpecialVend.Items.${index}.ModifierPrices_WX.Temp50CAdder_WX",
              label: this.isCelsius ? "Temp50C" : "Temp122F",
              min: 0,
              max: 65535
            }, {
              type: "currency",
              propertyName: "SpecialVend.Items.${index}.ModifierPrices_WX.Temp60CAdder_WX",
              label: this.isCelsius ? "Temp60C" : "Temp140F",
              min: 0,
              max: 65535
            }, {
              type: "currency",
              propertyName: "SpecialVend.Items.${index}.ModifierPrices_WX.Temp90CAdder_WX",
              label: this.isCelsius ? "Temp90C" : "Temp194F",
              min: 0,
              max: 65535
            }]
          }, {
            title: "agitate_time",
            controls: [{
              type: "positive-negative-number",
              properties: {
                positive: "SpecialVend.Items.${index}.WashAgitateMinutesAdder_WX",
                negative: "SpecialVend.Items.${index}.WashAgitateMinuteSubtracter_WX"
              },
              label: "vend_agitate_time_mod",
              min: -10,
              max: 10,
              labelAfter: "minutes"
            }]
          }, {
            title: "speed",
            controls: [{
              type: "speed-select",
              properties: {
                Speed: "SpecialVend.Items.${index}.ExtractSpeedLimit_WX"
              },
              labelkey: "extract_speed",
              machineSettings: this.machineSettings
            }]
          }]
        }]
      };
    })
  });

  _exports.default = _default;
});