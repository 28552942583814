define("alliance-business-suite/components/summary/reports/s-peak-times-by-hour", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SummaryReportsSPeakTimesByHourComponent extends _component.default {
    get translatedColumns() {
      let roomData = this.args.model.rooms;
      let columnData = [{
        name: '',
        valuePath: 'hour',
        width: 100,
        isFixed: 'left'
      }];
      roomData.forEach(r => {
        columnData.push({
          name: r.name,
          valuePath: `room_${r.id}`,
          width: 180
        });
      });
      return columnData;
    }

    get rowsWithChildren() {
      let rowData = [];
      let allHours = ['12 am', '1 am', '2 am', '3 am', '4 am', '5 am', '6 am', '7 am', '8 am', '9 am', '10 am', '11 am', '12 pm', '1 pm', '2 pm', '3 pm', '4 pm', '5 pm', '6 pm', '7 pm', '8 pm', '9 pm', '10 pm', '11 pm'];
      let roomHoursResults = this.args.model.rooms.map(r => {
        let roomResults = [];
        r.hours.forEach(h => {
          roomResults.push(h.pctRoomForRoomHours);
        });
        return {
          roomId: `room_${r.id}`,
          hours: roomResults
        };
      });
      allHours.forEach((h, index) => {
        let hoursRow = {
          hour: allHours[index],
          hourIndex: index,
          width: 180
        };
        rowData.push(hoursRow);
      });
      return rowData.map(row => {
        let newRow = {
          hour: row.hour,
          width: row.width,
          hourIndex: row.hourIndex
        };
        roomHoursResults.forEach(room => {
          newRow[`${room.roomId}`] = room.hours[row.hourIndex].toFixed(2) + '%';
        });
        return newRow;
      });
    }

  }

  _exports.default = SummaryReportsSPeakTimesByHourComponent;
});