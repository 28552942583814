define("alliance-business-suite/controllers/machines/programs/index", ["exports", "alliance-business-suite/utils/machine-programming-presets", "alliance-business-suite/components/models-table/themes/insights", "blueimp-md5"], function (_exports, _machineProgrammingPresets, _insights, _blueimpMd) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    // eslint-disable-line ember/no-classic-classes
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    access: Ember.inject.service(),
    brand: Ember.inject.service(),
    router: Ember.inject.service(),
    machinePrograms: Ember.inject.service(),
    machines: Ember.inject.service(),
    isSaving: false,
    sorting: null,
    tableTheme: _insights.default.create(),
    showComponentFooter: false,
    showColumnsDropdown: false,
    useFilteringByColumns: false,
    showGlobalFilter: false,
    useNumericPagination: false,
    doFilteringByHiddenColumns: false,
    filteringIgnoreCase: false,
    multipleColumnsSorting: false,
    showPageSize: false,
    collapseNumPaginationForPagesCount: 1,
    pageSize: 50,
    generations: Ember.computed('model.machineAuditGroupType.[]', 'brand.name', function () {
      let brand = _machineProgrammingPresets.machinePresets.findBy('brand', this.brand.name);

      let auditGroupType = Ember.get(this, 'model.machineAuditGroupType');
      let currentGens = auditGroupType.mapBy('id'); // Only return the generations that are listed in machineAuditGroupType

      return brand.generations.filter(item => {
        return currentGens.includes(item.id);
      });
    }),
    machineAuditTypes: Ember.computed('model.machineAuditType.[]', 'access.isOpl', 'brand.name', function () {
      let orgType = this.access.isOpl ? 'isOpl' : 'isVended';
      return Ember.get(this, 'model.machineAuditType').filterBy(orgType).mapBy('id');
    }),
    // init() {
    //   this._super(...arguments);
    //   get(this, 'machineProgramList');
    // },
    machineProgramList: Ember.computed('model.machineProgram.{length,[],isLoaded}', 'brand.name', function () {
      let programs = this.model.machineProgram; // demo: remove programs with certain strings in the name

      if (this.access.isDemo) {
        programs = programs.filter(p => {
          const bannedStrings = ['touch', 'midas', 'york', 'dalton', 'sq', 'quantum'];
          return bannedStrings.every(k => {
            return p.name.toLowerCase().indexOf(k) == -1;
          });
        });
      }

      return programs;
    }),
    brandAbbrev: Ember.computed('brand.abbrev', function () {
      return this.brand.abbrev.toUpperCase();
    }),
    columns: Ember.computed('brand.name', function () {
      let userId = Ember.get(this, 'access.accessUser.id');
      let user = Ember.get(this, 'store').peekRecord('employee', userId);
      let columns = [{
        propertyName: 'name',
        title: this.intl.t('name')
      }, {
        propertyName: 'machineControlName',
        title: this.intl.t('control')
      }, {
        propertyName: 'machineType.machineGroupingName',
        title: this.intl.t('type')
      }, {
        propertyName: 'createdAt',
        title: this.intl.t('created'),
        customDateFormat: `${user.get('dateFormat')}`,
        component: 'table/table-cell-date',
        customMachineProgramListing: true
      }, {
        // This is the cell that contains action buttons
        propertyName: 'id',
        title: '',
        disableSorting: true,
        component: 'table/table-cell-machine-program-options',
        action: this.downloadMachineProgram.bind(this)
      }]; // if demo remove the "control" from the table

      if (this.access.isDemo) {
        columns.splice(1, 1);
      }

      return columns;
    }),

    downloadMachineProgram(downloadUrl) {
      this.set('downloadUrl', downloadUrl);
      setTimeout(() => {
        document.body.style.cursor = 'default';
        document.getElementById('downloadLink_machineProgram').click();
      }, 250);
    },

    actions: {
      // eslint-disable-line ember/no-actions-hash
      pauseMe() {
        debugger; // eslint-disable-line
      },

      deleteTestPrograms() {
        this.machinePrograms.deleteTestPrograms();
      },

      deleteUnnamedPrograms() {
        this.machinePrograms.deleteUnnamedPrograms();
      },

      createProgram(generation, auditType, machineTypeId) {
        // TODO: Create a new machine program in the store, but do not persist to backend
        let orgId = Ember.get(this, 'access.accessOrganization.id');
        let orgType = Ember.get(this, 'store').peekRecord('organization', orgId);
        let machineType = Ember.get(this, 'store').peekRecord('machineType', machineTypeId);
        let machineAuditGroupType = Ember.get(this, 'store').peekRecord('machineAuditGroupType', generation);
        let machineAuditType = Ember.get(this, 'store').peekRecord('machineAuditType', auditType);
        let name = `${this.intl.t('new_program_created_on')} ${moment().format('YYYY-MM-DD')}`;
        Ember.set(this, 'isSaving', true);
        let program = this.store.createRecord('machine-program', {
          name,
          organization: orgType,
          machineType,
          machineAuditGroupType,
          machineAuditType
        });
        program.save().then(data => {
          Ember.set(this, 'isSaving', false);

          if (this.settings?.settingBytes.Message) {
            alert(this.intl.t('program_transcoding_error'));
            return;
          }

          this.transitionToRoute('machines.programs.manage', data); // Pass in object to avoid another model reload
        });
      },

      importProgram() {
        document.getElementById('import-file-upload').click();
      },

      import(file) {
        let acceptedFileTypes = ['json']; // Upload

        if (acceptedFileTypes.indexOf(file.extension) === -1) {
          alert(`${this.intl.t('only_file_types')} ${this.acceptedFileTypes.join(', ')}`);
          return false;
        }

        try {
          file.readAsText().then(fileContents => {
            // Import/replace
            fileContents = JSON.parse(fileContents); // Good checksum?

            const {
              name,
              machineAuditType,
              machineAuditGroupType,
              machineType,
              settings
            } = fileContents;
            const checksum = (0, _blueimpMd.default)(machineAuditType + machineAuditGroupType + machineType + JSON.stringify(settings));

            if (fileContents.checksum !== checksum) {
              alert("The file has been modified since it was exported. Unable to continue.");
              return;
            }

            let orgId = Ember.get(this, 'access.accessOrganization.id');
            let orgType = Ember.get(this, 'store').peekRecord('organization', orgId);
            let newMachineType = Ember.get(this, 'store').peekRecord('machineType', machineType);
            let newMachineAuditGroupType = Ember.get(this, 'store').peekRecord('machineAuditGroupType', machineAuditGroupType);
            let newMachineAuditType = Ember.get(this, 'store').peekRecord('machineAuditType', machineAuditType);
            let importName = `${this.intl.t('imported')}: ${name}`;
            let program = this.store.createRecord('machine-program', {
              name: importName,
              organization: orgType,
              machineType: newMachineType,
              machineAuditGroupType: newMachineAuditGroupType,
              machineAuditType: newMachineAuditType
            });
            program.settings = settings;
            program.save().then(data => {
              this.transitionToRoute('machines.programs.manage', data); // Pass in object to avoid another model reload
            });
          });
        } catch (err) {
          alert("There was an error reading the file.");
        }
      },

      tableUpdate(object) {
        // Save sorting info to session
        if (object.sort && object.sort.length > 0) {
          let [columnName, direction] = object.sort[0].split(':'); // We only support single column sorting

          this.columns.forEach(column => {
            if (column.propertyName === columnName) {
              column.sortDirection = direction;
              column.sortPrecedence = ['none', 'asc', 'desc'].indexOf(direction) || 1;
            } else {
              column.sortDirection = null;
              column.sortPrecedence = null;
            }
          });
        }

        Ember.set(this, 'sorting', object.sort);
      }

    }
  });

  _exports.default = _default;
});