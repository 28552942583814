define("alliance-business-suite/components/input-currency", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // eslint-disable-line
  var _default = Ember.Component.extend({
    isInput: false,
    isDisabled: false,
    value: 0,
    useOverride: false,
    access: Ember.inject.service(),
    currencyFormat: Ember.inject.service('currency-format'),

    processKeyboard(e, value) {
      e.preventDefault();
      e.stopPropagation(); // Get the value
      // let input = document.getElementById(this.inputId);
      // let currentNumber = this.value; //this.number;
      // let input_val = `${currentNumber}` || input.value;
      // let clean = Number(input_val.replace(/\D/g, "").replace(/^0+/, ""));
      // this.value = this.value + value;
      // let newValue = clean + value;

      this.send('increment', value); // this.send('setUnmaskedCurrency');
    },

    didInsertElement() {
      this._super(...arguments);

      let mousetrap = new Mousetrap(this.element);
      Ember.set(this, 'mousetrap', mousetrap);
      mousetrap.bind(['up'], (e, combo) => {
        this.processKeyboard(e, 1);
      });
      mousetrap.bind(['down'], (e, combo) => {
        this.processKeyboard(e, -1);
      });
      mousetrap.bind(['mod+up'], (e, combo) => {
        this.processKeyboard(e, 10);
      });
      mousetrap.bind(['mod+down'], (e, combo) => {
        this.processKeyboard(e, -10);
      });
      mousetrap.bind(['shift+up'], (e, combo) => {
        this.processKeyboard(e, 100);
      });
      mousetrap.bind(['shift+down'], (e, combo) => {
        this.processKeyboard(e, -100);
      }); // mousetrap.bind(['esc'], (e, combo) => {
      //  this.processKeyboard(e, null)
      // });

      this.send('setUnmaskedCurrency');
    },

    willDestroyElement() {
      this._super(...arguments);

      let mousetrap = this.mousetrap; // Clean up keyboard shortcuts

      mousetrap.unbind(['up']);
      mousetrap.unbind(['down']);
      mousetrap.unbind(['mod+up']);
      mousetrap.unbind(['mod+down']);
      mousetrap.unbind(['shift+up']);
      mousetrap.unbind(['shift+down']); // mousetrap.unbind(['esc']);
    },

    numberHasChanged: Ember.observer('number', function () {
      // eslint-disable-line
      this.send('maskCurrencyInput');
    }),
    // rawValueChange: observer('rawValue', function() { // eslint-disable-line
    //   console.log('rawValue is now', this.rawValue);
    // }),
    inputId: Ember.computed('elementId', function () {
      return `inputCurrency_${this.elementId}`;
    }),
    actions: {
      increment(value) {
        let input = document.getElementById(this.inputId);
        let currentNumber = this.value; //this.number;

        let input_val = `${currentNumber}` || input.value;

        if (input_val === "") {
          console.log('NO input value');
          return;
        } // sets caret position


        let original_len = input_val.length;
        let caret_pos = input.selectionStart;
        input_val = Number(input_val) + value;

        if (this.currencyFormat.getDecimals === false || this.currencyFormat.getDecimal === undefined || this.currencyFormat.isThaiBypass) {
          //Formats Number without decimal places based on "currencyDecimalPlaces" property being set to 0...Example: ¥1,0000
          input_val = this.currencyFormat.formatNumberWithoutDecimals(input_val, this.currencyFormat.getGroup);
        } else {
          //Formats Number with decimal places based on "currencyDecimalPlaces" property being set to 2... Example: $1,235.25
          input_val = this.currencyFormat.formatNumberWithDecimals(input_val, this.currencyFormat.getGroup, this.currencyFormat.getDecimal);
        } //Get formatted currency symbol position based on standard Intl NumberFormat


        if (this.currencyFormat.getSymbol === "RIGHT") {
          input_val = input_val + `${this.currencyFormat.getSymbol}`;
        } else {
          input_val = `${this.currencyFormat.getSymbol}` + input_val;
        } //Send final formatted currency value to the input = $2.50


        input.value = input_val; // put caret back in the right position

        let updated_len = input_val.length;
        caret_pos = updated_len - original_len + caret_pos;
        input.setSelectionRange(caret_pos, caret_pos);
      },

      // Sets Masked input value
      maskCurrencyInput() {
        let input = document.getElementById(this.inputId);
        let currentNumber = this.value; //this.number;

        let input_val = `${currentNumber}` || input.value;

        if (input_val === "") {
          console.log('NO input value');
          return;
        } // sets caret position


        let original_len = input_val.length;
        let caret_pos = input.selectionStart;

        if (this.currencyFormat.getDecimals === false || this.currencyFormat.getDecimal === undefined || this.currencyFormat.isThaiBypass) {
          //Formats Number without decimal places based on "currencyDecimalPlaces" property being set to 0...Example: ¥1,0000
          input_val = this.currencyFormat.formatNumberWithoutDecimals(input_val, this.currencyFormat.getGroup);
        } else {
          //Formats Number with decimal places based on "currencyDecimalPlaces" property being set to 2... Example: $1,235.25
          input_val = this.currencyFormat.formatNumberWithDecimals(input_val, this.currencyFormat.getGroup, this.currencyFormat.getDecimal);
        } //Get formatted currency symbol position based on standard Intl NumberFormat


        if (this.currencyFormat.getSymbol === "RIGHT") {
          input_val = input_val + `${this.currencyFormat.getSymbol}`;
        } else {
          input_val = `${this.currencyFormat.getSymbol}` + input_val;
        } //Send final formatted currency value to the input = $2.50


        input.value = input_val; // put caret back in the right position

        let updated_len = input_val.length;
        caret_pos = updated_len - original_len + caret_pos;
        input.setSelectionRange(caret_pos, caret_pos);
      },

      // Sets unmasked input value for API
      setUnmaskedCurrency() {
        let input = document.getElementById(this.inputId);
        let currentNumber = this.value; // let input_val = `${currentNumber}` || input.value;

        let input_val = input.value || `${currentNumber}`;

        if (Number(input_val.replace(/\D/g, "").replace(/^0+/, "")) >= 0) {
          this.set('value', Number(input_val.replace(/\D/g, "").replace(/^0+/, ""))); // this.set('rawValue', Number(input_val.replace(/\D/g, "").replace(/^0+/, "")));

          this.send('maskCurrencyInput'); // format the currency input
        }
      }

    }
  });

  _exports.default = _default;
});