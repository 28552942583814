define("alliance-business-suite/components/brushes/ui/list-item", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="model-view">
    <div class="model-field">
      {{#if hasBlock}}
        {{yield (hash
          label=(component "brushes/ui/list-item-label" label=@label tooltip=@tooltip)
          value=(component "brushes/ui/list-item-value" value=@value)
        )}}
      {{else}}
        <Brushes::Ui::ListItemLabel @label={{@label}} />
        <Brushes::Ui::ListItemValue @value={{@value}} />
      {{/if}}
    </div>
  </div>
  
  */
  {
    "id": "zKQZxIqZ",
    "block": "{\"symbols\":[\"@label\",\"@value\",\"@tooltip\",\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"model-view\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"model-field\"],[12],[2,\"\\n\"],[6,[37,2],[[27,[32,4]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[18,4,[[30,[36,1],null,[[\"label\",\"value\"],[[30,[36,0],[\"brushes/ui/list-item-label\"],[[\"label\",\"tooltip\"],[[32,1],[32,3]]]],[30,[36,0],[\"brushes/ui/list-item-value\"],[[\"value\"],[[32,2]]]]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[8,\"brushes/ui/list-item-label\",[],[[\"@label\"],[[32,1]]],null],[2,\"\\n      \"],[8,\"brushes/ui/list-item-value\",[],[[\"@value\"],[[32,2]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\",\"if\"]}",
    "moduleName": "alliance-business-suite/components/brushes/ui/list-item.hbs"
  });

  class BrushesUiListItemComponent extends _component.default {}

  _exports.default = BrushesUiListItemComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, BrushesUiListItemComponent);
});