define("alliance-business-suite/components/brushes/ui/list-item-value", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <span class="model-field__value">
    {{#if hasBlock}}
      {{yield}}
    {{else}}
      {{@value}}
    {{/if}}
  </span>
  
  */
  {
    "id": "ATZ6bbH7",
    "block": "{\"symbols\":[\"@value\",\"&default\"],\"statements\":[[10,\"span\"],[14,0,\"model-field__value\"],[12],[2,\"\\n\"],[6,[37,0],[[27,[32,2]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,2,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[32,1]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "alliance-business-suite/components/brushes/ui/list-item-value.hbs"
  });

  class BrushesUiListItemValueComponent extends _component.default {}

  _exports.default = BrushesUiListItemValueComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, BrushesUiListItemValueComponent);
});