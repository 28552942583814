define("alliance-business-suite/templates/components/form-ui/default-modifier", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NR4NE+ps",
    "block": "{\"symbols\":[],\"statements\":[[8,\"form-ui/select\",[],[[\"@object\",\"@update\",\"@formSettings\",\"@params\"],[[32,0],[30,[36,0],[[32,0],\"updateValues\"],null],[34,1],[30,[36,3],null,[[\"propertyName\",\"options\"],[\"defaultModifier\",[35,2,[\"DefaultModifiers\"]]]]]]],null],[2,\"\\n\\n\"],[8,\"form-ui/dev-tools\",[],[[\"@outputs\"],[[30,[36,3],null,[[\"reversingProp\",\"antiWrinkleProp\",\"reversingValue\",\"antiWrinkleValue\"],[[35,6,[\"reversing\",\"key\"]],[35,6,[\"antiWrinkle\",\"key\"]],[35,5],[35,4]]]]]],null]],\"hasEval\":false,\"upvars\":[\"action\",\"formSettings\",\"selectOptions\",\"hash\",\"antiWrinkle\",\"reversing\",\"properties\"]}",
    "moduleName": "alliance-business-suite/templates/components/form-ui/default-modifier.hbs"
  });

  _exports.default = _default;
});