define("alliance-business-suite/utils/machine-programming-find-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.findOptions = void 0;

  const findOptionsByAttribute = (options, machineAttributes) => {
    const attributes = machineAttributes._machineAttributes ?? machineAttributes;
    let found = options.find(dataItem => {
      let isFound = false;
      let machineAttributeProperty;
      if (dataItem.machineAttribute) machineAttributeProperty = dataItem.machineAttribute;
      if (dataItem.machineAttributes) machineAttributeProperty = dataItem.machineAttributes;
      if (dataItem.auditType) machineAttributeProperty = dataItem.auditType;
      if (!machineAttributeProperty) return false;

      for (var key of machineAttributeProperty) {
        if (attributes.includes(key)) {
          isFound = true;
        }
      }

      return isFound;
    }); // If no match is found, return the first object with default property of true.

    if (found === undefined) {
      found = options.find(item => item.default && item.default === true);
    } // If nothing is found, return an error message.


    if (found === undefined) {
      console.error("Error finding options.");
    } // If we found options, but it has no items or options property, return an error message.


    if (found.items === undefined && found.options === undefined) {
      console.error("Items property not found for options.");
    } else {
      return found.options || found.items;
    }
  };

  const findOptions = (options, attributes) => {
    if (attributes !== undefined) {
      return findOptionsByAttribute(options, attributes);
    } else {
      return Ember.computed('options', 'machineProgram._machineAttributes', function () {
        return findOptionsByAttribute(options, this.machineProgram._machineAttributes);
      });
    }
  };

  _exports.findOptions = findOptions;
});