define("alliance-business-suite/templates/components/page-ui/page-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RpfUgLHY",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"section--container-v2\"],[12],[2,\"\\n  \"],[10,\"header\"],[14,0,\"ui-route-header\"],[12],[2,\"\\n\\n\"],[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"h2\"],[12],[2,\"\\n        \"],[1,[34,1]],[2,\"\\n\"],[6,[37,2],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"span\"],[14,0,\"ui-route-header__subtitle\"],[12],[2,\"\\n            \"],[1,[34,0]],[2,\"\\n          \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[2,\"    \"],[18,1,[[30,[36,4],null,[[\"controls\"],[[30,[36,3],[\"page-ui/page-controls\"],null]]]]]],[2,\"\\n\\n  \"],[13],[2,\"\\n\\n\"],[2,\"  \"],[18,1,[[30,[36,4],null,[[\"content\",\"isLoading\"],[[30,[36,3],[\"page-ui/page-content\"],null],[35,5]]]]]],[2,\"\\n\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"subTitle\",\"title\",\"if\",\"component\",\"hash\",\"isLoading\"]}",
    "moduleName": "alliance-business-suite/templates/components/page-ui/page-container.hbs"
  });

  _exports.default = _default;
});