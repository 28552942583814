define("alliance-business-suite/utils/seconds-to-hms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = function _default(totalSeconds, intl) {
    let showAllParts = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    let hours = parseInt(totalSeconds / 3600);
    let totalMinutes = parseInt(totalSeconds / 60);
    let minutes = totalMinutes % 60;
    let seconds = totalSeconds % 60;
    let abbrevs = {
      h: 'h',
      m: 'm',
      s: 's'
    };

    if (intl) {
      abbrevs.h = intl.t('duration_hours_single_letter'), abbrevs.m = intl.t('duration_minutes_single_letter'), abbrevs.s = intl.t('duration_seconds_single_letter');
    }

    if (showAllParts) {
      return `${hours}h ${minutes}m ${seconds}s`;
    } else {
      let output = '';

      if (hours > 0) {
        output += `${hours}${abbrevs.h} `;
      }

      if (minutes > 0) {
        output += `${minutes}${abbrevs.m} `;
      }

      output += ` ${seconds}${abbrevs.s}`;
      return output;
    }
  };

  _exports.default = _default;
});