define("alliance-business-suite/templates/components/employees/roles/permission-tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sMKlcOgm",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"i\"],[14,0,\"fa fa-info-circle\"],[12],[13],[2,\"\\n  \"],[8,\"ember-tooltip\",[],[[\"@side\",\"@event\"],[\"top\",\"click\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[34,0]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"tooltipText\",\"hasTooltip\",\"if\"]}",
    "moduleName": "alliance-business-suite/templates/components/employees/roles/permission-tooltip.hbs"
  });

  _exports.default = _default;
});