define("alliance-business-suite/templates/components/ui/modal-progress-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bD9CrC+e",
    "block": "{\"symbols\":[\"@currentAmount\"],\"statements\":[[8,\"modal-dialog\",[],[[\"@animatable\",\"@targetAttachment\",\"@translucentOverlay\",\"@overlayClassNames\"],[true,\"none\",true,\"ui-modal-dialog__overlay ui-modal-dialog__progress\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[11,\"div\"],[24,0,\"progress-pie-chart\"],[4,[38,0],[[32,0,[\"initialLoad\"]]],null],[4,[38,1],[[32,0,[\"updateProgressBar\"]],[32,1]],null],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"ppc-progress\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"ppc-progress-fill\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"ppc-percents\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"pcc-percents-wrapper\"],[12],[2,\"\\n        \"],[10,\"span\"],[12],[1,[32,0,[\"savingProgress\"]]],[2,\"%\"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"did-update\"]}",
    "moduleName": "alliance-business-suite/templates/components/ui/modal-progress-bar.hbs"
  });

  _exports.default = _default;
});