define("alliance-business-suite/components/ui/section-collapse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * Manages the state of the dialog collapse
     *
     * @type Boolean
     * @public
     */
    isCollapsed: true,

    /**
     * The "block" portion of the class name for this component in the
     * Block-Element-Modifier (BEM) naming convention.
     *
     * @type {Array<String>}
     * @public
     */
    componentClassName: undefined,

    /**
     * The dynamic class names for this component that are derived from component
     * properties.
     *
     * @type {Array<String>}
     * @public
     */
    classNameBindings: ['componentClassName', 'isCollapsed'],
    classNames: ['section-collapse'],
    actions: {
      toggleCollapse() {
        this.toggleProperty('isCollapsed');
      }

    }
  });

  _exports.default = _default;
});