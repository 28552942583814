define("alliance-business-suite/templates/components/table/table-cell-localize-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nxAzjmFV",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[35,1,[\"prependBrand\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,0],[\"app_name\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"b\",\"column\",\"if\"]}",
    "moduleName": "alliance-business-suite/templates/components/table/table-cell-localize-text.hbs"
  });

  _exports.default = _default;
});