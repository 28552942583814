define("alliance-business-suite/components/summary/reports/s-pos-status-revenue", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "alliance-business-suite/utils/currency-format-util", "alliance-business-suite/utils/report-tables-util"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _currencyFormatUtil, _reportTablesUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  let SummaryReportsSPosStatusRevenueComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, (_class = class SummaryReportsSPosStatusRevenueComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor3, this);
    }

    get translatedColumns() {
      return [{
        name: 'location',
        valuePath: 'name',
        width: 300,
        isFixed: 'left'
      }, {
        name: 'transaction_date',
        valuePath: 'transactionDate',
        width: 200
      }, {
        name: 'description',
        valuePath: 'description',
        width: 180
      }, {
        name: 'Quantity',
        valuePath: 'quantity',
        width: 180
      }, {
        name: 'Weight',
        valuePath: 'weight',
        width: 180
      }, {
        name: 'payment_lookup_key',
        valuePath: 'orderPaymentTypeLookupKey',
        width: 180
      }, {
        name: 'price',
        valuePath: 'price',
        width: 180
      }, {
        name: 'total',
        valuePath: 'totalPrice',
        width: 180
      }, {
        name: 'amount_received',
        valuePath: 'saleMoneyReceived',
        width: 180
      }, {
        name: 'amount_outstanding',
        valuePath: 'saleAmountOutstanding',
        width: 180
      }];
    }

    get rowsWithChildren() {
      let {
        model
      } = this.args;
      let geoBoundaries = model.geoBoundaries;
      let geoData = [];
      let rowData = [{
        name: model.organizationName,
        children: geoData
      }];
      geoBoundaries.forEach(geoBoundary => {
        let roomSectionDataArray = [];
        geoData.push({
          name: geoBoundary.name,
          children: roomSectionDataArray
        });
        geoBoundary.rooms.forEach(room => {
          let salesDataArray = [];
          roomSectionDataArray.push({
            name: room.name || 'N/A',
            children: salesDataArray
          });
          room.sales.forEach(sale => {
            let orderItemData = [];
            salesDataArray.push({
              name: `Sale: ${sale.id}`,
              transactionDate: moment(sale.transactionDate).format(`${_reportTablesUtil.formatDateTimeString.call(this)}`),
              totalPrice: _currencyFormatUtil.formatCurrencyString.call(this, sale.saleTotalAmount || 0),
              saleMoneyReceived: _currencyFormatUtil.formatCurrencyString.call(this, sale.saleMoneyReceived || 0),
              saleAmountOutstanding: _currencyFormatUtil.formatCurrencyString.call(this, sale.saleAmountOutstanding || 0),
              isCollapsed: true,
              children: orderItemData
            });
            sale.orders.forEach(order => {
              let lineItems = [];
              orderItemData.push({
                name: `Order: ${order.orderNumber}`,
                saleMoneyReceived: _currencyFormatUtil.formatCurrencyString.call(this, order.orderMoneyReceived || 0),
                orderPaymentTypeLookupKey: this.intl.t(order.orderPaymentTypeLookupKey || 0),
                isCollapsed: true,
                children: lineItems
              });
              order.settings.forEach(setting => {
                lineItems.push({
                  price: _currencyFormatUtil.formatCurrencyString.call(this, setting.price || 0),
                  description: setting.description || 'N/A',
                  weight: setting.weight || 'N/A'
                });
              });
              order.lineItems.forEach(lineItem => {
                lineItems.push({
                  price: _currencyFormatUtil.formatCurrencyString.call(this, lineItem.price || 0),
                  totalPrice: _currencyFormatUtil.formatCurrencyString.call(this, lineItem.orderLineItemTotal || 0),
                  weight: lineItem.weight || 'N/A',
                  description: lineItem.description || 'N/A'
                });
              });
            });
            sale.goodsLineItems.forEach(goodItem => {
              orderItemData.push({
                name: 'Goods',
                isCollapsed: true,
                children: [{
                  price: _currencyFormatUtil.formatCurrencyString.call(this, goodItem.unitPrice || 0),
                  totalPrice: _currencyFormatUtil.formatCurrencyString.call(this, goodItem.subtotal || 0),
                  isRefunded: _currencyFormatUtil.formatCurrencyString.call(this, goodItem.isRefunded || 0),
                  quantity: goodItem.quantity || 'N/A',
                  weight: goodItem.weight || 'N/A',
                  description: goodItem.description || 'N/A'
                }]
              });
            });
          });
        });
      });
      return rowData;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SummaryReportsSPosStatusRevenueComponent;
});