define("alliance-business-suite/components/summary/reports/s-employee-sold", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "alliance-business-suite/utils/currency-format-util", "alliance-business-suite/utils/report-tables-util"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _currencyFormatUtil, _reportTablesUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  let SummaryReportsSEmployeeSoldComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, (_class = class SummaryReportsSEmployeeSoldComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor3, this);
    }

    get translatedColumns() {
      return [{
        name: '',
        valuePath: 'name',
        isFixed: 'left'
      }, {
        name: 'email',
        valuePath: 'email'
      }, {
        name: 'date',
        valuePath: 'date'
      }, {
        name: 'quantity',
        valuePath: 'quantity'
      }, {
        name: 'subtotal',
        valuePath: 'subTotal'
      }];
    }

    get rowsWithChildren() {
      let rooms = this.args.model.rooms;
      let roomData = [];
      rooms.forEach(room => {
        let roomSectionDataArray = [];
        roomData.push({
          name: room.name,
          children: roomSectionDataArray
        });
        room.sales.forEach(sale => {
          let salesDataArray = [];
          roomSectionDataArray.push({
            date: moment(sale.date).format(`${_reportTablesUtil.formatDateTimeString.call(this)}`),
            name: `${sale.firstName} ${sale.lastName}`,
            email: sale.email,
            subTotal: _currencyFormatUtil.formatCurrencyString.call(this, sale.subTotal || 0),
            quantity: 0,
            isCollapsed: true,
            children: salesDataArray
          });
          sale.items.forEach(item => {
            let saleItemsData = [];
            roomSectionDataArray[roomSectionDataArray.length - 1].quantity += item.quantity;
            salesDataArray.push({
              id: item.id,
              name: item.description,
              quantity: item.quantity,
              subTotal: _currencyFormatUtil.formatCurrencyString.call(this, item.total || 0)
            });
          });
        });
      });
      return roomData;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SummaryReportsSEmployeeSoldComponent;
});