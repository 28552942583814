define("alliance-business-suite/components/ui/t-moment-duration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const LOCALIZATION_TIME_RANGE = {
    'P0Y0M7DT0H0M0S': 'duration_7_days',
    'P0Y0M1DT0H0M0S': 'duration_previous_day',
    'P0Y1M0DT0H0M0S': 'duration_previous_month',
    'P0Y3M0DT0H0M0S': 'duration_3_months'
  };
  const LOCALIZATION_FREQUENCY = {
    'P0Y0M0DT0H0M0S': 'one_time_only',
    'P0Y0M1DT0H0M0S': 'duration_a_day',
    'P0Y0M7DT0H0M0S': 'duration_7_days',
    'P0Y0M14DT0H0M0S': 'duration_14_days',
    'P0Y1M0DT0H0M0S': 'duration_1_month',
    'P0Y3M0DT0H0M0S': 'duration_3_months',
    'P1Y0M0DT0H0M0S': 'duration_1_year'
  };
  let ComponentClass = Ember.Component.extend({
    intl: Ember.inject.service(),
    tagName: '',
    // moment: service(),
    translationString: Ember.computed('interval', function () {
      let interval = this.interval;
      let type = this.type;
      let key = '';

      if (type == 'time_range') {
        key = LOCALIZATION_TIME_RANGE[interval];
      }

      if (type == 'frequency') {
        key = LOCALIZATION_FREQUENCY[interval];
      }

      return this.intl.t(key);
    }) // formattedString: computed(function() {
    //   let duration = moment.duration(get(this, 'interval'));
    //   let dData = duration._data;
    //
    //   let fString = '';
    //
    //   if (dData.days == 1) {
    //     fString = 'previous_day'
    //   }
    //
    //   if (dData.months == 1) {
    //     fString = 'previous_month'
    //   }
    //
    //   if (dData.years == 1) {
    //     fString = 'previous_year'
    //   }
    //
    //   return fString
    // })

  });
  ComponentClass.reopenClass({
    positionalParams: ['interval', 'type']
  });
  var _default = ComponentClass;
  _exports.default = _default;
});