define("alliance-business-suite/components/machines/programs/cycles/primus-mc10-wx", ["exports", "ember-copy", "jquery", "alliance-business-suite/mixins/machines/programs/mc10-options", "alliance-business-suite/utils/is-number"], function (_exports, _emberCopy, _jquery, _mc10Options, _isNumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Removed CycleGlobal mixin!
  var _default = Ember.Component.extend(_mc10Options.default, {
    //#region Variables
    notifications: Ember.inject.service(),
    intl: Ember.inject.service(),
    clipboard: {
      type: null,
      contents: null,
      title: null
    },
    maxCycles: 15,
    showCycleInfo: false,
    maxSteps: 40,
    comboCycleNames: ["cycle_wash_dry_normal", "cycle_wash_dry_small", "cycle_wash_only", "cycle_dry_only", "cycle_rinse_machine"],
    isCombo: Ember.computed.alias('machineProgram.machineType.isCombo'),

    //#endregion
    //#region Events
    didInsertElement() {
      this._super(...arguments);

      this.send('selectCycle');
      Ember.set(this, 'defaultMachineType', Ember.get(this, 'cycleList.firstObject.MachineType'));
      Ember.set(this, 'hasCycleNumbers', Ember.get(this, 'cycleList.firstObject.Number') ? true : false);
    },

    //#endregion
    //#region Default values
    // TODO: Need to set the MachineType value
    defaultCycle: Ember.computed(function () {
      if (this.machineProgram.hasMachineAttribute("MC10_COMBO")) {
        return {
          "MachineType": this.defaultMachineType,
          "Number": 0,
          "Enabled": true,
          "ProgrammableCycleTime": 0,
          "ProgrammableCycleTimeEnabled": false,
          "DefaultTemp": 1,
          "Steps": []
        };
      } else {
        return {
          "MachineType": this.defaultMachineType,
          "Number": 0,
          "Enabled": true,
          "ProgrammableCycleTime": 0,
          "ProgrammableCycleTimeEnabled": false,
          "DisinfectTimeMinutes": 0,
          "DisinfectTemperature": 0,
          "CycleName": this.intl.t("new_cycle"),
          "TempOptions": {
            "Cold": true,
            "Warm": true,
            "Hot": true,
            "NoHeat": false,
            "t15C": false,
            "t30C": false,
            "t40C": false,
            "t50C": false,
            "t60C": false,
            "t90C": false
          },
          "DefaultTemp": 1,
          "Steps": []
        };
      }
    }),
    defaultStep: Ember.computed(function () {
      if (this.machineProgram.hasMachineAttribute("MC10_COMBO")) {
        return {
          "ActiveState": 1,
          "Type": 13,
          "WashDuration": 210,
          "WaterLevelType": 1,
          "WaterLevel": 0,
          "FlushOut": false,
          "TemperatureType": 4,
          "Temperature": 104,
          "WashRotateTime": 18,
          "WashPauseTime": 4,
          "WashSpeed": 46,
          "Inlets": {
            "Inlet1": true,
            "Inlet2": false
          },
          "DrainValveForCooldown": 0,
          "Detergents": {
            "D1": true,
            "D2": false,
            "D3": false,
            "D4": false
          },
          "DetergentTimes": [30, 0, 0, 0],
          "DrainType": 5,
          "DrainValve": 1,
          "DrainDuration": 60,
          "DrainRotateTime": 18,
          "DrainPauseTime": 4,
          "DrainSpeed": 46
        };
      } else {
        return {
          "ActiveState": 1,
          "Type": 13,
          "WashDuration": 210,
          "WaterLevelType": 1,
          "WaterLevel": 0,
          "FlushOut": false,
          "AllowTempOverride": true,
          "TemperatureType": 4,
          "Temperature": 104,
          "WashRotateTime": 18,
          "WashPauseTime": 4,
          "WashSpeed": 46,
          "Inlets": {
            "Inlet1": true,
            "Inlet2": false,
            "Inlet3": false,
            "Inlet4": false,
            "Inlet5": true,
            "Inlet6": false,
            "Inlet7": false,
            "Inlet8": false
          },
          "DrainValveForCooldownOrTumble": 0,
          "Detergents": {
            "D1": true,
            "D2": false,
            "D3": false,
            "D4": false,
            "D5": false,
            "D6": false,
            "D7": false,
            "D8": false,
            "D9": false,
            "D10": false,
            "D11": false,
            "D12": false,
            "D13": false,
            "D14": false,
            "D15": false,
            "D16": false
          },
          "DetergentTimes": [30, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          "DrainType": 5,
          "DrainValve": 1,
          "DrainDuration": 60,
          "DrainRotateTime": 18,
          "DrainPauseTime": 4,
          "DrainSpeed": 46
        };
      }
    }),
    //#endregion
    //#region Computed properties
    // TODO: Make sure the cycle array is sorted based on Number.
    cycleList: Ember.computed.sort('model.settings.Cycles', function (a, b) {
      if (a.Number > b.Number) {
        return 1;
      } else if (a.Number < b.Number) {
        return -1;
      } else {
        return 0;
      }
    }),
    stepList: Ember.computed('selectedCycle', 'selectedCycle.[]', function () {
      // Steps can be in an unnamed array, or an array named `Steps`
      if (Ember.get(this, 'selectedCycle.Steps')) {
        return Ember.get(this, 'selectedCycle.Steps');
      } else {
        return this.selectedCycle || [];
      }
    }),

    //#endregion
    //#region Methods
    resetCycleNumbers() {
      if (this.hasCycleNumbers) {
        this.cycleList.forEach((cycle, index) => {
          // TODO: Check if cycle.Number is zero-based
          Ember.set(cycle, 'Number', index + 1);
        });
      }
    },

    swapArrayItems(array, index, direction) {
      // Used to re-order array items
      let secondIndex = direction === 'up' ? index - 1 : index + 1;
      let firstItem = [array[index]];
      let secondItem = [array[secondIndex]];

      if (direction == "up" && index > 0 || direction == 'down' && index < array.get('length') - 1) {
        array.replace(index, 1, secondItem).replace(secondIndex, 1, firstItem);
      }

      return array;
    },

    //#endregion
    //#region Actions
    actions: {
      closeModal() {
        Ember.set(this, 'showCycleInfo', false);
      },

      editCycleInfo(cycle) {
        Ember.set(this, 'showCycleInfo', true);
      },

      moveCycle(cycle, direction) {
        let array = this.cycleList;
        let index = array.indexOf(cycle);
        this.swapArrayItems(array, index, direction);
      },

      moveStep(step, direction) {
        let array = this.stepList;
        let index = array.indexOf(step);
        this.swapArrayItems(array, index, direction);
      },

      addCycle() {
        // New for MC10
        let maxCycles = this.maxCycles;
        let list = this.cycleList;

        if (list.length < maxCycles) {
          let newItem = (0, _emberCopy.copy)(this.defaultCycle);
          let index = list.length;
          list.insertAt(index, newItem);
          this.send('selectCycle', index);
          this.resetCycleNumbers();
        } else {
          this.notifications.error('Unable to add a new cycle because this machine only supports 15 cycles. Please delete a cycle and try again.', {
            autoClear: false,
            clearDuration: 1000,
            cssClasses: 'quick-notification'
          });
        }
      },

      deleteCycle(cycle) {
        // New for MC10
        let cycleList = this.cycleList;

        if (cycleList.length === 0) {
          return;
        }

        let description = name || 'Cycle';

        if (confirm(`Delete selected ${description}?`)) {
          let index = cycleList.indexOf(cycle);
          cycleList.removeAt(index); // Now we need to set the next step that is active.

          let selected;
          let newIndex = cycleList.length > index ? index : index - 1;
          this.resetCycleNumbers();
          this.send('selectCycle', newIndex);
        }
      },

      selectCycle(value, init) {
        // Value can be the cycle object or the index of the cycle
        let index, cycle;

        if (value === undefined) {
          value = 0;
        }

        if (isNaN(value)) {
          // we have an object
          cycle = value;
          index = this.cycleList.indexOf(value);
        } else {
          // we have a number
          index = value;
          cycle = this.cycleList.objectAt(value);
        }

        Ember.set(this, 'selectedCycle', cycle);
        Ember.set(this, 'selectedCycleIndex', Number(index));
        localStorage.setItem('cycle', index || 0);

        if (!init) {
          // Select first step
          this.send('selectStep');
        }
      },

      selectStep(step) {
        if (!step) {
          step = Ember.get(this, 'stepList.firstObject');
        } else if ((0, _isNumber.isNumber)(step)) {
          step = this.stepList.objectAt(Number(step));
        }

        let index = this.stepList.length > 0 ? this.stepList.indexOf(step) : 0;
        Ember.set(this, 'selectedStep', step);
        (0, _jquery.default)(step).focus();
        localStorage.setItem('step', index || 0);
      },

      // These are from the mixin
      deleteStep(step) {
        let stepList = this.stepList;

        if (stepList.length === 0) {
          return;
        }

        let id = Ember.get(step, 'Type');
        let selectOptions = this.selectOptions;
        let description = name || 'Current step';

        if (confirm(`Delete selected ${description}?`)) {
          let index = stepList.indexOf(step);
          stepList.removeAt(index); // Now we need to set the next step that is active.

          let selected;
          let newIndex = stepList.length > index ? index : index - 1;

          if (newIndex >= 0) {
            selected = stepList.objectAt(newIndex);
            Ember.set(this, 'selectedStep', selected);
            Ember.set(this, 'selectedStepIndex', newIndex);
            (0, _jquery.default)(selected).focus();
          }
        }
      },

      addStep(index) {
        // TODO: Be able to add steps within the list of steps, instead of always at the bottom.
        let newItem = (0, _emberCopy.copy)(this.defaultStep);
        let list = this.stepList; // Only allow maxSteps

        if (list.length >= this.maxSteps) {
          this.notifications.error(this.intl.t("max_steps_reached", {
            maxSteps: this.maxSteps
          }), {
            autoClear: false,
            cssClasses: 'quick-notification'
          });
          return false;
        } // Click action can pass in proxy object as index


        index = (0, _isNumber.isNumber)(index) ? index : list.length || 0;
        list.insertAt(index, newItem);
        this.send('selectStep', index);
      },

      copyCycle(cycle) {
        Ember.set(this, 'clipboard.type', 'cycle');
        Ember.set(this, 'clipboard.contents', cycle);
        Ember.set(this, 'clipboard.title', 'Name will be here');
        this.notifications.success('Cycle copied', {
          autoClear: true,
          clearDuration: 1000,
          cssClasses: 'quick-notification'
        });
        return false;
      },

      copyStep(step) {
        Ember.set(this, 'clipboard.type', 'step');
        Ember.set(this, 'clipboard.contents', (0, _emberCopy.copy)(step)); // step is an object

        this.notifications.success('Step copied', {
          autoClear: true,
          clearDuration: 1000,
          cssClasses: 'quick-notification'
        });
        return false;
      },

      pasteCycle(cycle) {
        // This machine can paste additional cycles, up to 15 total.
        let maxCycles = this.maxCycles;
        let cycles = this.cycleList;

        if (cycles.length < maxCycles) {
          let type = Ember.get(this, 'clipboard.type');

          if (type !== 'cycle') {
            alert('No cycle is currently in your clipboard.');
            return false;
          }

          let clipboard = (0, _emberCopy.copy)(Ember.get(this, 'clipboard.contents'));
          let index = cycles.indexOf(cycle);
          cycles.insertAt(index, clipboard);
          this.send('selectCycle', index);
          this.resetCycleNumbers();
          this.notifications.success('Cycle pasted', {
            autoClear: true,
            clearDuration: 1000,
            cssClasses: 'quick-notification'
          });
        } else {
          this.notifications.error('Unable to paste because this machine only supports 15 cycles. Please delete a cycle and try again.', {
            autoClear: false,
            clearDuration: 1000,
            cssClasses: 'quick-notification'
          });
        }

        return false;
      },

      pasteStep(step) {
        // We're going to paste the new step above the selected on
        let type = Ember.get(this, 'clipboard.type');

        if (type !== 'step') {
          alert('No step is currently in your clipboard.');
          return false;
        }

        let clipboard = (0, _emberCopy.copy)(Ember.get(this, 'clipboard.contents'));
        let steps = this.stepList;
        let index = steps.indexOf(step); // Copy the Label and ActiveState

        let label, activeState;

        if (step) {
          label = Ember.get(step, 'Label');
          activeState = Ember.get(step, 'ActiveState');
        } else {
          // Defaults
          label = 1;
          activeState = 8;
        }

        Ember.set(clipboard, 'Label', label);
        Ember.set(clipboard, 'ActiveState', activeState);
        steps.insertAt(index, clipboard);
        this.send('selectStep', index);
        let notifications = this.notifications;
        this.notifications.success('Step pasted', {
          autoClear: true,
          clearDuration: 1000,
          cssClasses: 'quick-notification'
        });
        return false;
      }

    } //#endregion

  });

  _exports.default = _default;
});