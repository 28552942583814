define("alliance-business-suite/templates/components/table/machines/table-cell-machine-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ymcdd/GQ",
    "block": "{\"symbols\":[\"@record\"],\"statements\":[[1,[32,0,[\"displayMachineTypeName\"]]],[2,\"\\n\\n\"],[6,[37,2],[[32,1,[\"serialNumber\",\"length\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"br\"],[12],[13],[2,\"\\n  \"],[10,\"small\"],[14,5,\"opacity: .5\"],[12],[2,\"\\n    S/N: \"],[1,[32,1,[\"serialNumber\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,2],[[32,0,[\"devtools\",\"trayEnabled\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"br\"],[12],[13],[2,\"\\n  \"],[10,\"small\"],[14,5,\"opacity: .85\"],[12],[2,\"\\n    \"],[10,\"strong\"],[12],[1,[32,1,[\"machineAuditType\",\"id\"]]],[13],[2,\"\\n    \"],[10,\"span\"],[14,5,\"opacity: .75\"],[12],[1,[32,1,[\"machineControlName\"]]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,2],[[30,[36,3],[[32,1,[\"id\"]],[32,0,[\"machines\",\"fetchInProgressMachineList\"]]],null]],[[\"use\"],[\"toDown\"]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"br\"],[12],[13],[2,\"\\n  \"],[10,\"small\"],[14,5,\"opacity: .85\"],[12],[2,\"\\n    \"],[1,[34,0]],[2,\" \"],[1,[30,[36,1],[\"fetching_program\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"spinner-icon\",\"t\",\"if\",\"contains\"]}",
    "moduleName": "alliance-business-suite/templates/components/table/machines/table-cell-machine-type.hbs"
  });

  _exports.default = _default;
});