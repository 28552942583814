define("alliance-business-suite/components/ui/context-menu-2", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    showMenu: false,

    click() {
      alert('click'); // Show the menu

      Ember.set(this, 'showMenu', true); // Position the menu under the element clicked on

      let $el = (0, _jquery.default)(this.element); // Position the wormhole element

      (0, _jquery.default)('#context-menu-wormhole').css('top', $el.offset().top + $el.height());
      (0, _jquery.default)('#context-menu-wormhole').css('left', $el.offset().left);
      return false; // Do not bubble
    },

    focusOut() {
      alert('blur');
    },

    willDestroyElement() {
      alert('willdestroy');
      Ember.set(this, 'showMenu', false);
    },

    didInsertElementS() {
      this._super(...arguments);

      (0, _jquery.default)('body').on('click', '.dropdown-button, .dropdown-trigger', function (e) {
        e.stopPropagation(); // Close anything already displayed

        if ((0, _jquery.default)(this).parents('.ui-dropdown').hasClass('show-menu')) {
          (0, _jquery.default)('.show-menu').removeClass('show-menu');
          return;
        }

        (0, _jquery.default)('.show-menu').removeClass('show-menu');
        let $button, $menu, $parent;
        $button = (0, _jquery.default)(this);
        $menu = $button.siblings('.dropdown-menu');
        $parent = $button.parents('.ui-dropdown');
        $menu.toggleClass('show-menu');
        $parent.toggleClass('show-menu'); // TODO: Is this needed, since clicking the document will close the menu?

        $menu.children('li').click(function () {
          (0, _jquery.default)('.show-menu').removeClass('show-menu');
        });
      });
      (0, _jquery.default)(document).click(function () {
        (0, _jquery.default)('.show-menu').removeClass('show-menu');
      });
      Mousetrap.bind(['esc'], function () {
        (0, _jquery.default)('.show-menu').removeClass('show-menu');
      });
    }

  });

  _exports.default = _default;
});