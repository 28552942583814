define("alliance-business-suite/components/machines/programs/cycles/mgd-opl", ["exports", "alliance-business-suite/mixins/machines/programs/cycles/cycle-global-mgd-opl", "alliance-business-suite/utils/speed-utilities", "alliance-business-suite/mixins/machines/programs/mgd-options", "alliance-business-suite/mixins/machines/programs/cycles/cycle-times"], function (_exports, _cycleGlobalMgdOpl, _speedUtilities, _mgdOptions, _cycleTimes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import WaterUsage from 'alliance-business-suite/mixins/machines/programs/cycles/water-usage-flw';
  // export default Component.extend(SelectOptions, PropertyNames, CycleGlobal, {
  var _default = Ember.Component.extend(_mgdOptions.default, _cycleTimes.default, _cycleGlobalMgdOpl.default, {
    cycleSettings: Ember.computed('model', function () {
      return {
        machine: 'mgd-m1-flw',
        isCelsius: 'Program.Misc.CelsiusEnabled',
        // All options used in the UI are contained in this `options` object.
        options: {
          ActiveState: [{
            val: 0,
            label: 'Disabled'
          }, {
            val: 1,
            label: 'Mod1'
          }, {
            val: 2,
            label: 'Mod2'
          }, {
            val: 8,
            label: 'Enabled'
          }],
          Label: [{
            val: 0,
            label: 'Pre Wash'
          }, {
            val: 1,
            label: 'Wash'
          }, {
            val: 2,
            label: 'Rinse 1'
          }, {
            val: 3,
            label: 'Rinse 2'
          }, {
            val: 4,
            label: 'Rinse 3'
          }, {
            val: 5,
            label: 'Rinse 4'
          }, {
            val: 6,
            label: 'Spin'
          } // { val: 7, label: 'Bleach' }
          ],
          AudioPattern: [{
            val: 1,
            label: 'Solid High'
          }, {
            val: 2,
            label: 'Solid Medium'
          }, {
            val: 3,
            label: 'Solid Low'
          }, {
            val: 4,
            label: 'High Beep'
          }, {
            val: 5,
            label: 'Medium Beep'
          }, {
            val: 6,
            label: 'Low Beep'
          }, {
            val: 7,
            label: 'High Double Quick Beep'
          }, {
            val: 8,
            label: 'Medium Double Quick Beep'
          }, {
            val: 9,
            label: 'Low Double Quick Beep'
          }, {
            val: 10,
            label: 'High SOS Beep'
          }, {
            val: 11,
            label: 'Medium SOS Beep'
          }, {
            val: 12,
            label: 'Low SOS Beep'
          }, {
            val: 13,
            label: 'Increasing Tone'
          }, {
            val: 14,
            label: 'Sinusoidal Tone'
          }, {
            val: 15,
            label: 'Melody 1'
          }, {
            val: 16,
            label: 'Melody 2'
          }, {
            val: 17,
            label: 'Melody 3'
          }, {
            val: 18,
            label: 'Melody 4'
          }, {
            val: 19,
            label: 'Melody 5'
          }, {
            val: 20,
            label: 'Melody 6'
          }],
          SoakHeat: [{
            val: 0,
            label: 'No heat'
          }, {
            val: 1,
            label: 'Heat to target'
          }, {
            val: 2,
            label: 'Maintain heat'
          }],
          AgitateHeat: [{
            val: 0,
            label: 'No heat'
          }, {
            val: 1,
            label: 'Heat to target'
          }, {
            val: 2,
            label: 'Maintain heat'
          }],
          AgitateHeatRate: [{
            val: 0,
            label: 'No rate restriction',
            presetValue: 60
          }, // presetValue is 6.0...after dividing by 10
          {
            val: 60,
            label: 'Increment by time',
            isCustom: true
          } // value becomes the custom setting...saved as 10x the displayed value
          ],
          AgitateSpeed: [// Windows has Distribution=3  WAS AgitateSpeed and SoakSpeed
          {
            val: 1,
            label: `Low (${Ember.get(this, 'useG') ? `${Ember.get(this, 'machineSpeeds.agitate.presets')[0]}G` : `${(0, _speedUtilities.convertGtoRPM)(Ember.get(this, 'basketDiameter'), Ember.get(this, 'machineSpeeds.agitate.presets')[0], 10, 50)}RPM`})`,
            g: Ember.get(this, 'machineSpeeds.agitate.presets')[0],
            rpm: (0, _speedUtilities.convertGtoRPM)(Ember.get(this, 'basketDiameter'), Ember.get(this, 'machineSpeeds.agitate.presets')[0], 10, 50)
          }, {
            val: 2,
            label: `Regular (${Ember.get(this, 'useG') ? `${Ember.get(this, 'machineSpeeds.agitate.presets')[1]}G` : `${(0, _speedUtilities.convertGtoRPM)(Ember.get(this, 'basketDiameter'), Ember.get(this, 'machineSpeeds.agitate.presets')[1], 10, 50)}RPM`})`,
            g: Ember.get(this, 'machineSpeeds.agitate.presets')[1],
            rpm: (0, _speedUtilities.convertGtoRPM)(Ember.get(this, 'basketDiameter'), Ember.get(this, 'machineSpeeds.agitate.presets')[1], 10, 50)
          }, {
            val: 255,
            label: 'Custom',
            isCustom: true
          }, {
            val: 0,
            label: 'Disabled',
            g: 0,
            rpm: 0
          }],
          DrainSpeed: [// Windows has Distribution=3  WAS AgitateSpeed and SoakSpeed
          {
            val: 1,
            label: `Low (${Ember.get(this, 'useG') ? `${Ember.get(this, 'machineSpeeds.drain.presets')[0]}G` : `${(0, _speedUtilities.convertGtoRPM)(Ember.get(this, 'basketDiameter'), Ember.get(this, 'machineSpeeds.drain.presets')[0], 10, 50)}RPM`})`,
            g: Ember.get(this, 'machineSpeeds.drain.presets')[0],
            rpm: (0, _speedUtilities.convertGtoRPM)(Ember.get(this, 'basketDiameter'), Ember.get(this, 'machineSpeeds.drain.presets')[0], 10, 50)
          }, {
            val: 2,
            label: `Regular (${Ember.get(this, 'useG') ? `${Ember.get(this, 'machineSpeeds.drain.presets')[1]}G` : `${(0, _speedUtilities.convertGtoRPM)(Ember.get(this, 'basketDiameter'), Ember.get(this, 'machineSpeeds.drain.presets')[1], 10, 50)}RPM`})`,
            g: Ember.get(this, 'machineSpeeds.drain.presets')[1],
            rpm: (0, _speedUtilities.convertGtoRPM)(Ember.get(this, 'basketDiameter'), Ember.get(this, 'machineSpeeds.drain.presets')[1], 10, 50)
          }, {
            val: 3,
            label: `Distribute (${Ember.get(this, 'useG') ? `${Ember.get(this, 'machineSpeeds.drain.presets')[2]}G` : `${(0, _speedUtilities.convertGtoRPM)(Ember.get(this, 'basketDiameter'), Ember.get(this, 'machineSpeeds.drain.presets')[2], 15, 90)}RPM`})`,
            g: Ember.get(this, 'machineSpeeds.drain.presets')[2],
            rpm: (0, _speedUtilities.convertGtoRPM)(Ember.get(this, 'basketDiameter'), Ember.get(this, 'machineSpeeds.drain.presets')[2], 10, 50)
          }, {
            val: 255,
            label: 'Custom',
            isCustom: true
          }, {
            val: 0,
            label: 'Disabled',
            g: 0,
            rpm: 0
          }],
          ExtractSpeed: [{
            val: 1,
            label: `Very low (${Ember.get(this, 'useG') ? `${Ember.get(this, 'machineSpeeds.extract.presets')[0]}G` : `${(0, _speedUtilities.convertGtoRPM)(Ember.get(this, 'basketDiameter'), Ember.get(this, 'machineSpeeds.extract.presets')[0])}RPM`})`,
            g: Ember.get(this, 'machineSpeeds.extract.presets')[0],
            rpm: (0, _speedUtilities.convertGtoRPM)(Ember.get(this, 'basketDiameter'), Ember.get(this, 'machineSpeeds.extract.presets')[0])
          }, {
            val: 2,
            label: `Low (${Ember.get(this, 'useG') ? `${Ember.get(this, 'machineSpeeds.extract.presets')[1]}G` : `${(0, _speedUtilities.convertGtoRPM)(Ember.get(this, 'basketDiameter'), Ember.get(this, 'machineSpeeds.extract.presets')[1])}RPM`})`,
            g: Ember.get(this, 'machineSpeeds.extract.presets')[1],
            rpm: (0, _speedUtilities.convertGtoRPM)(Ember.get(this, 'basketDiameter'), Ember.get(this, 'machineSpeeds.extract.presets')[1])
          }, {
            val: 3,
            label: `Medium (${Ember.get(this, 'useG') ? `${Ember.get(this, 'machineSpeeds.extract.presets')[2]}G` : `${(0, _speedUtilities.convertGtoRPM)(Ember.get(this, 'basketDiameter'), Ember.get(this, 'machineSpeeds.extract.presets')[2])}RPM`})`,
            g: Ember.get(this, 'machineSpeeds.extract.presets')[2],
            rpm: (0, _speedUtilities.convertGtoRPM)(Ember.get(this, 'basketDiameter'), Ember.get(this, 'machineSpeeds.extract.presets')[2])
          }, {
            val: 4,
            label: `High (${Ember.get(this, 'useG') ? `${Ember.get(this, 'machineSpeeds.extract.presets')[3]}G` : `${(0, _speedUtilities.convertGtoRPM)(Ember.get(this, 'basketDiameter'), Ember.get(this, 'machineSpeeds.extract.presets')[3])}RPM`})`,
            g: Ember.get(this, 'machineSpeeds.extract.presets')[3],
            rpm: (0, _speedUtilities.convertGtoRPM)(Ember.get(this, 'basketDiameter'), Ember.get(this, 'machineSpeeds.extract.presets')[3])
          }, {
            val: 5,
            label: `Very high (${Ember.get(this, 'useG') ? `${Ember.get(this, 'machineSpeeds.extract.presets')[4]}G` : `${(0, _speedUtilities.convertGtoRPM)(Ember.get(this, 'basketDiameter'), Ember.get(this, 'machineSpeeds.extract.presets')[4])}RPM`})`,
            g: Ember.get(this, 'machineSpeeds.extract.presets')[4],
            rpm: (0, _speedUtilities.convertGtoRPM)(Ember.get(this, 'basketDiameter'), Ember.get(this, 'machineSpeeds.extract.presets')[4])
          }, {
            val: 6,
            label: `Ultra high (${Ember.get(this, 'useG') ? `${Ember.get(this, 'machineSpeeds.extract.presets')[5]}G` : `${(0, _speedUtilities.convertGtoRPM)(Ember.get(this, 'basketDiameter'), Ember.get(this, 'machineSpeeds.extract.presets')[5])}RPM`})`,
            g: Ember.get(this, 'machineSpeeds.extract.presets')[5],
            rpm: (0, _speedUtilities.convertGtoRPM)(Ember.get(this, 'basketDiameter'), Ember.get(this, 'machineSpeeds.extract.presets')[5])
          }, {
            val: 7,
            label: 'Custom',
            isCustom: true
          } // Yes, custom is NOT 255 for extract
          ],
          FillStepHeat: [{
            val: 0,
            label: 'No heat',
            values: [false, false]
          }, {
            val: 1,
            label: 'Heat to target in agitate step',
            values: [true, false]
          }, {
            val: 2,
            label: 'Maintain heat in agitate step',
            values: [false, true]
          }],
          SupplyWaterValves: [{
            val: 1,
            label: 'Hot',
            setting: [true, false]
          }, {
            val: 2,
            label: 'Warm',
            setting: [true, true]
          }, {
            val: 3,
            label: 'Cold',
            setting: [false, true]
          }],
          WaterValvesTemperature: [{
            val: 0,
            label: 'Hot'
          }, {
            val: 1,
            label: 'Warm'
          }, {
            val: 2,
            label: 'Cold'
          }],
          WaterValvesTemperatureWithControlled: [{
            val: 0,
            label: 'Hot'
          }, {
            val: 1,
            label: 'Warm'
          }, {
            val: 2,
            label: 'Cold'
          }, {
            val: 3,
            label: 'Controlled'
          }],
          SupplyExternalSupplyOptions: [{
            val: 0,
            label: 'Until supply time expires'
          }, {
            val: 1,
            label: 'Until target water level'
          }],
          FillWaterLevel: [{
            val: 126,
            label: `High (${Ember.get(this, 'model.settings.Program.Cycle.HighWaterLevel')})`,
            presetValue: Ember.get(this, 'model.settings.Program.Cycle.HighWaterLevel')
          }, {
            val: 125,
            label: `Medium (${Ember.get(this, 'model.settings.Program.Cycle.MediumWaterLevel')})`,
            presetValue: Ember.get(this, 'model.settings.Program.Cycle.MediumWaterLevel')
          }, {
            val: 124,
            label: `Low (${Ember.get(this, 'model.settings.Program.Cycle.LowWaterLevel')})`,
            presetValue: Ember.get(this, 'model.settings.Program.Cycle.LowWaterLevel')
          }, {
            val: 0,
            label: 'Custom',
            isCustom: true
          }],
          FillGlobalTemperature: [{
            val: 2,
            label: `Global hot temperature (${Ember.get(this, '_HotWaterTemperature')}°${Ember.get(this, 'temperatureScale')})`,
            presetValue: Ember.get(this, '_HotWaterTemperature')
          }, {
            val: 1,
            label: `Global warm temperature (${Ember.get(this, '_WarmWaterTemperature')}°${Ember.get(this, 'temperatureScale')})`,
            presetValue: Ember.get(this, '_WarmWaterTemperature')
          }, {
            val: 0,
            label: `Global cold temperature (${Ember.get(this, '_ColdWaterTemperature')}°${Ember.get(this, 'temperatureScale')})`,
            presetValue: Ember.get(this, '_ColdWaterTemperature')
          }, {
            val: 3,
            label: 'Custom',
            isCustom: true
          } // use custom value, stored in F
          ],
          Temperature: [{
            val: 0,
            label: 'Cold'
          }, {
            val: 1,
            label: 'Warm'
          }, {
            val: 2,
            label: 'Hot'
          }, {
            val: 3,
            label: 'Custom',
            isCustom: true
          }],
          CooldownTemperature: [{
            val: 0,
            label: `Global cool down (${Ember.get(this, 'globalCooldownTemp')}°${Ember.get(this, 'temperatureScale')})`,
            presetValue: Ember.get(this, 'globalCooldownTemp')
          }, {
            val: Ember.get(this, 'globalCooldownTemp'),
            label: 'Custom',
            isCustom: true
          }],
          CoolDownType: [{
            val: 0,
            label: 'No rate restriction'
          }, {
            val: 1,
            label: 'Decrement by time',
            isCustom: true
          }],
          ShutOffCondition: [{
            val: false,
            label: 'Until supply time expoires'
          }, {
            val: true,
            label: 'Until target water level'
          }],
          HeaterOptionsHeat: [{
            val: 0,
            label: 'No heat'
          }, {
            val: 1,
            label: 'Heat to target'
          }, {
            val: 2,
            label: 'Maintain heat'
          }],
          HeaterOptionsRate: [{
            val: 0,
            label: 'No tate restrictions'
          }, {
            val: 1,
            label: 'Increments by time...'
          }],
          GlobalMessage: [{
            val: 0,
            label: 'Load'
          }, {
            val: 1,
            label: 'Chemical'
          }, {
            val: 2,
            label: 'Soap'
          }, {
            val: 3,
            label: 'Bleach'
          }, {
            val: 4,
            label: 'Softener'
          }, {
            val: 5,
            label: 'Sour'
          }, {
            val: 6,
            label: 'Starch'
          }]
        },
        // All default property values for each step are here, and used for creating new steps in a cycle.
        stepMetaData: [{
          Type: 1,
          name: 'ReuseFill',
          enabled: true
        }, {
          Type: 2,
          name: 'Fill',
          enabled: true
        }, {
          Type: 3,
          name: 'Supply',
          enabled: true
        }, {
          Type: 4,
          name: 'Agitate',
          enabled: true
        }, {
          Type: 5,
          name: 'Soak',
          enabled: true
        }, {
          Type: 6,
          name: 'Cooldown',
          enabled: true
        }, {
          Type: 7,
          name: 'Drain',
          enabled: true
        }, {
          Type: 8,
          name: 'SprayRinseExtract',
          enabled: true
        }, {
          Type: 9,
          name: 'DrainAndExtract',
          enabled: true
        }, {
          Type: 10,
          name: 'AudioSignal',
          enabled: true
        }, {
          Type: 11,
          name: 'Hold',
          enabled: true
        }, {
          Type: 12,
          name: 'WashSpray',
          enabled: true
        }],
        steps: [{
          Type: 1,
          Label: 1,
          Minutes: 5,
          Seconds: 0,
          FlushOut: false,
          PauseTime: 4,
          RotateTime: 18,
          ActiveState: 8,
          CustomSpeed: 2,
          Temperature: 0,
          NonReversing: false,
          ReuseFillAOn: false,
          ReuseFillBOn: false,
          AuxFillEnabled: false,
          HotFillEnabled: false,
          ColdFillEnabled: true,
          CustomWaterLevel: 124,
          FillValveEnabled: false,
          AuxOutput1Enabled: false,
          AuxOutput2Enabled: false,
          AuxOutput3Enabled: false,
          AuxOutput4Enabled: false,
          AuxOutput5Enabled: false,
          AuxOutput6Enabled: false,
          SprayValveEnabled: false,
          ColdHardFillEnabled: false,
          FillTubValveEnabled: false,
          TempControlledFillEnabled: false
        }, {
          Type: 2,
          Temp: 0,
          Label: 1,
          Minutes: 0,
          Seconds: 30,
          FlushOut: false,
          PauseTime: 4,
          RotateTime: 18,
          ActiveState: 8,
          CustomSpeed: 2,
          NonReversing: false,
          AuxFillEnabled: false,
          HotFillEnabled: false,
          ColdFillEnabled: true,
          CustomWaterLevel: 124,
          FillValveEnabled: true,
          AuxOutput1Enabled: false,
          AuxOutput2Enabled: false,
          AuxOutput3Enabled: false,
          AuxOutput4Enabled: false,
          AuxOutput5Enabled: false,
          AuxOutput6Enabled: false,
          SprayValveEnabled: true,
          ColdHardFillEnabled: false,
          FillTubValveEnabled: true,
          TempControlledFillEnabled: false
        }, {
          Type: 3,
          Label: 1,
          Minutes: 0,
          Seconds: 30,
          ActiveState: 8,
          CustomSpeed: 1,
          totalSeconds: 0,
          AuxFillEnabled: false,
          HotFillEnabled: false,
          ColdFillEnabled: true,
          FillValveEnabled: true,
          ShutoffCondition: 0,
          AuxSupply1Enabled: false,
          AuxSupply2Enabled: false,
          AuxSupply3Enabled: false,
          AuxSupply4Enabled: false,
          AuxSupply5Enabled: false,
          AuxSupply6Enabled: false,
          AuxSupply7Enabled: false,
          AuxSupply8Enabled: false,
          SprayValveEnabled: false,
          SupplyDelaySeconds: 0,
          ColdHardFillEnabled: false,
          Compartment1Enabled: true,
          Compartment2Enabled: false,
          Compartment3Enabled: false,
          Compartment4Enabled: false,
          FillTubValveEnabled: false,
          JrAuxOutput1Enabled: false,
          JrAuxOutput2Enabled: false,
          JrAuxOutput3Enabled: false,
          JrAuxOutput4Enabled: false,
          JrAuxOutput5Enabled: false,
          JrAuxOutput6Enabled: false
        }, {
          Type: 4,
          Label: 1,
          Minutes: 1,
          Seconds: 0,
          HeatRate: 0,
          ChemFlush: false,
          PauseTime: 4,
          HeatOption: 0,
          MainDrain1: false,
          MainDrain2: false,
          RotateTime: 18,
          ActiveState: 8,
          CustomSpeed: 2,
          NonReversing: false,
          PulseDrainOn: false,
          RefillEnabled: true,
          ExternalDrainOn: false,
          AuxSupply1Enabled: false,
          AuxSupply2Enabled: false,
          AuxSupply3Enabled: false,
          AuxSupply4Enabled: false,
          AuxSupply5Enabled: false,
          AuxSupply6Enabled: false,
          AuxSupply7Enabled: false,
          AuxSupply8Enabled: false,
          ResueDrainPumpAOn: false,
          ResueDrainPumpBOn: false,
          JrAuxOutput1Enabled: false,
          JrAuxOutput2Enabled: false,
          JrAuxOutput3Enabled: false,
          JrAuxOutput4Enabled: false,
          JrAuxOutput5Enabled: false,
          JrAuxOutput6Enabled: false
        }, {
          Type: 5,
          Hours: 0,
          Label: 1,
          Minutes: 1,
          HeatRate: 0,
          ChemFlush: false,
          PauseTime: 255,
          HeatOption: 0,
          MainDrain1: false,
          MainDrain2: false,
          RotateTime: 4,
          ActiveState: 8,
          CustomSpeed: 2,
          NonReversing: false,
          PulseDrainOn: false,
          RefillEnabled: true,
          ExternalDrainOn: false,
          AuxSupply1Enabled: false,
          AuxSupply2Enabled: false,
          AuxSupply3Enabled: false,
          AuxSupply4Enabled: false,
          AuxSupply5Enabled: false,
          AuxSupply6Enabled: false,
          AuxSupply7Enabled: false,
          AuxSupply8Enabled: false,
          ResueDrainPumpAOn: false,
          ResueDrainPumpBOn: false,
          JrAuxOutput1Enabled: false,
          JrAuxOutput2Enabled: false,
          JrAuxOutput3Enabled: false,
          JrAuxOutput4Enabled: false,
          JrAuxOutput5Enabled: false,
          JrAuxOutput6Enabled: false
        }, {
          Type: 6,
          Temp: 0,
          Label: 1,
          PauseTime: 27,
          MainDrain1: false,
          MainDrain2: false,
          RotateTime: 3,
          ActiveState: 8,
          CustomSpeed: 2,
          CoolDownRate: 0,
          NonReversing: false,
          PulseDrainOn: false,
          IsAntiWrinkle: false,
          AuxFillEnabled: false,
          HotFillEnabled: false,
          IsPlumbingCode: true,
          ColdFillEnabled: true,
          FillValveEnabled: true,
          AuxSupply1Enabled: false,
          AuxSupply2Enabled: false,
          AuxSupply3Enabled: false,
          AuxSupply4Enabled: false,
          AuxSupply5Enabled: false,
          AuxSupply6Enabled: false,
          AuxSupply7Enabled: false,
          AuxSupply8Enabled: false,
          SprayValveEnabled: true,
          ColdHardFillEnabled: false,
          FillTubValveEnabled: true,
          JrAuxOutput1Enabled: false,
          JrAuxOutput2Enabled: false,
          JrAuxOutput3Enabled: false,
          JrAuxOutput4Enabled: false,
          JrAuxOutput5Enabled: false,
          JrAuxOutput6Enabled: false
        }, {
          Type: 7,
          Label: 1,
          PauseTime: 4,
          MainDrain1: true,
          MainDrain2: false,
          RotateTime: 18,
          ActiveState: 8,
          CustomSpeed: 2,
          PulseDrainOn: false,
          ExternalDrainOn: false,
          AuxSupply1Enabled: false,
          AuxSupply2Enabled: false,
          AuxSupply3Enabled: false,
          AuxSupply4Enabled: false,
          AuxSupply5Enabled: false,
          AuxSupply6Enabled: false,
          AuxSupply7Enabled: false,
          AuxSupply8Enabled: false,
          ResueDrainPumpAOn: false,
          ResueDrainPumpBOn: false,
          NoReversingEnabled: false,
          JrAuxOutput1Enabled: false,
          JrAuxOutput2Enabled: false,
          JrAuxOutput3Enabled: false,
          JrAuxOutput4Enabled: false,
          JrAuxOutput5Enabled: false,
          JrAuxOutput6Enabled: false,
          RotateEntireStepEnabled: false
        }, {
          Type: 8,
          Label: 1,
          Minutes: 2,
          Seconds: 0,
          MainDrain1: true,
          MainDrain2: true,
          ActiveState: 8,
          CustomSpeed: 1,
          ValveTimeOn: 30,
          ValveTimeOff: 30,
          OPTIsprayTime: 0,
          ExternalDrainOn: false,
          HotSprayEnabled: false,
          ColdSprayEnabled: true,
          AuxSupply1Enabled: false,
          AuxSupply2Enabled: false,
          AuxSupply3Enabled: false,
          AuxSupply4Enabled: false,
          AuxSupply5Enabled: false,
          AuxSupply6Enabled: false,
          AuxSupply7Enabled: false,
          AuxSupply8Enabled: false,
          ResueDrainPumpAOn: false,
          ResueDrainPumpBOn: false,
          ValvePulseEnabled: false,
          JrAuxOutput1Enabled: false,
          JrAuxOutput2Enabled: false,
          JrAuxOutput3Enabled: false,
          JrAuxOutput4Enabled: false,
          JrAuxOutput5Enabled: false,
          JrAuxOutput6Enabled: false
        }, {
          Type: 9,
          Label: 1,
          Minutes: 0,
          Seconds: 30,
          MainDrain1: true,
          MainDrain2: true,
          ActiveState: 8,
          CustomSpeed: 2,
          PulseDrainOn: false,
          ExternalDrainOn: false,
          ChemFlushSeconds: 30,
          AuxSupply1Enabled: false,
          AuxSupply2Enabled: false,
          AuxSupply3Enabled: false,
          AuxSupply4Enabled: false,
          AuxSupply5Enabled: false,
          AuxSupply6Enabled: false,
          AuxSupply7Enabled: false,
          AuxSupply8Enabled: false,
          ResueDrainPumpAOn: false,
          ResueDrainPumpBOn: false,
          JrAuxOutput1Enabled: false,
          JrAuxOutput2Enabled: false,
          JrAuxOutput3Enabled: false,
          JrAuxOutput4Enabled: false,
          JrAuxOutput5Enabled: false,
          JrAuxOutput6Enabled: false
        }, {
          Type: 10,
          Label: 1,
          Minutes: 0,
          Seconds: 5,
          ActiveState: 8,
          CustomSpeed: 1,
          ExternalDrainOn: false,
          AudioPatternType: 1,
          AuxSupply1Enabled: false,
          AuxSupply2Enabled: false,
          AuxSupply3Enabled: false,
          AuxSupply4Enabled: false,
          AuxSupply5Enabled: false,
          AuxSupply6Enabled: false,
          AuxSupply7Enabled: false,
          AuxSupply8Enabled: false,
          ResueDrainPumpAOn: false,
          ResueDrainPumpBOn: false,
          JrAuxOutput1Enabled: false,
          JrAuxOutput2Enabled: false,
          JrAuxOutput3Enabled: false,
          JrAuxOutput4Enabled: false,
          JrAuxOutput5Enabled: false,
          JrAuxOutput6Enabled: false
        }, {
          Type: 11,
          Label: 1,
          MainDrain1: true,
          MainDrain2: true,
          ActiveState: 8,
          CustomSpeed: 1,
          AudioMinutes: 0,
          AudioSeconds: 10,
          PulseDrainOn: false,
          GlobalMessage: 0,
          DrainAndUnlock: true,
          ExternalDrainOn: false,
          AudioPatternType: 1,
          AutoResumeMinutes: 0,
          AuxSupply1Enabled: false,
          AuxSupply2Enabled: false,
          AuxSupply3Enabled: false,
          AuxSupply4Enabled: false,
          AuxSupply5Enabled: false,
          AuxSupply6Enabled: false,
          AuxSupply7Enabled: false,
          AuxSupply8Enabled: false,
          ResueDrainPumpAOn: false,
          ResueDrainPumpBOn: false,
          JrAuxOutput1Enabled: false,
          JrAuxOutput2Enabled: false,
          JrAuxOutput3Enabled: false,
          JrAuxOutput4Enabled: false,
          JrAuxOutput5Enabled: false,
          JrAuxOutput6Enabled: false
        }, {
          Type: 12,
          Label: 1,
          Minutes: 2,
          Seconds: 0,
          PauseTime: 4,
          MainDrain1: true,
          MainDrain2: true,
          RotateTime: 18,
          ActiveState: 8,
          CustomSpeed: 2,
          ValveTimeOn: 30,
          NonReversing: false,
          ValveTimeOff: 30,
          HotSprayEnabled: false,
          ColdSprayEnabled: true,
          AuxSupply1Enabled: false,
          AuxSupply2Enabled: false,
          AuxSupply3Enabled: false,
          AuxSupply4Enabled: false,
          AuxSupply5Enabled: false,
          AuxSupply6Enabled: false,
          AuxSupply7Enabled: false,
          AuxSupply8Enabled: false,
          ValvePulseEnabled: false,
          JrAuxOutput1Enabled: false,
          JrAuxOutput2Enabled: false,
          JrAuxOutput3Enabled: false,
          JrAuxOutput4Enabled: false,
          JrAuxOutput5Enabled: false,
          JrAuxOutput6Enabled: false
        }]
      };
    }),
    actions: {
      closeModal() {
        Ember.set(this, 'showCycleInfo', false);
      }

    }
  });

  _exports.default = _default;
});