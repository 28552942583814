define("alliance-business-suite/components/form-ui/steps/audio-signal", ["exports", "alliance-business-suite/mixins/init-property-names"], function (_exports, _initPropertyNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_initPropertyNames.default, {
    optionsAudioPattern: Ember.computed("machineProgram._machineAuditType", "object.Type", function () {
      switch (true) {
        case this?.machineProgram?._machineAuditType === "MGD_M7" && this.object.Type === 10:
          // Audio Signal
          return [// { val: 0, label: 'NoTone' },
          {
            val: 1,
            label: 'SolidHigh'
          }, {
            val: 2,
            label: 'SolidMedium'
          }, {
            val: 3,
            label: 'SolidLow'
          }, {
            val: 4,
            label: 'HighBeep'
          }, {
            val: 5,
            label: 'MediumBeep'
          }, {
            val: 6,
            label: 'LowBeep'
          }, {
            val: 7,
            label: 'HighDoubleQuickBeep'
          }, {
            val: 8,
            label: 'MediumDoubleQuickBeep'
          }, {
            val: 9,
            label: 'LowDoubleQuickBeep'
          }, {
            val: 10,
            label: 'HighSOSBeep'
          }, {
            val: 11,
            label: 'MediumSOSBeep'
          }, {
            val: 12,
            label: 'LowSOSBeep'
          }, {
            val: 13,
            label: 'IncreasingTone'
          }, {
            val: 14,
            label: 'SinusoidalTone'
          }, {
            val: 15,
            label: 'Melody1'
          }, {
            val: 16,
            label: 'Melody2'
          }, {
            val: 17,
            label: 'Melody3'
          }, {
            val: 18,
            label: 'Melody4'
          }, {
            val: 19,
            label: 'Melody5'
          }, {
            val: 20,
            label: 'Melody6'
          }];

        case this?.machineProgram?._machineAuditType === "MGD_M7" && this.object.Type === 11:
          // Hold
          return [{
            val: 0,
            label: 'NoTone'
          }, {
            val: 1,
            label: 'SolidHigh'
          }, {
            val: 2,
            label: 'SolidMedium'
          }, {
            val: 3,
            label: 'SolidLow'
          }, {
            val: 4,
            label: 'HighBeep'
          }, {
            val: 5,
            label: 'MediumBeep'
          }, {
            val: 6,
            label: 'LowBeep'
          }, {
            val: 7,
            label: 'HighDoubleQuickBeep'
          }, {
            val: 8,
            label: 'MediumDoubleQuickBeep'
          }, {
            val: 9,
            label: 'LowDoubleQuickBeep'
          }, {
            val: 10,
            label: 'HighSOSBeep'
          }, {
            val: 11,
            label: 'MediumSOSBeep'
          }, {
            val: 12,
            label: 'LowSOSBeep'
          }, {
            val: 13,
            label: 'IncreasingTone'
          }, {
            val: 14,
            label: 'SinusoidalTone'
          }, {
            val: 15,
            label: 'Melody1'
          }, {
            val: 16,
            label: 'Melody2'
          }, {
            val: 17,
            label: 'Melody3'
          }, {
            val: 18,
            label: 'Melody4'
          }, {
            val: 19,
            label: 'Melody5'
          }, {
            val: 20,
            label: 'Melody6'
          }];

        default:
          // If no matching criteria, fall back to AudioPattern
          return "AudioPattern";
      }
    })
  });

  _exports.default = _default;
});