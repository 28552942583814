define("alliance-business-suite/components/machines/programs/cycles/midas-wx", ["exports", "ember-copy", "alliance-business-suite/utils/is-number", "jquery", "alliance-business-suite/mixins/machines/programs/midas-property-names", "alliance-business-suite/mixins/machines/programs/midas-options", "alliance-business-suite/helpers/find-by-dropdown-with-custom"], function (_exports, _emberCopy, _isNumber, _jquery, _midasPropertyNames, _midasOptions, _findByDropdownWithCustom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Removed CycleGlobal mixin!
  var _default = Ember.Component.extend(_midasOptions.default, _midasPropertyNames.default, {
    isDependentSupplyStep(step, index, enumerable, activeStates) {
      // Check to see if supply step is following a fill step or following a supply that follows a fill step
      if (step.Type == 3) {
        while (index > 0) {
          index--;
          let prevStep = enumerable[index];
          let prevActiveState = (0, _findByDropdownWithCustom.findByDropDown)([this.selectOptions['ActiveState'], "val", prevStep.ActiveState]).label;
          let prevType = (0, _findByDropdownWithCustom.findByDropDown)([this.selectOptions['StepType'], "val", prevStep.Type]).label;

          if (activeStates.includes(prevActiveState)) {
            if (["Fill", "ReuseFill"].includes(prevType)) {
              // Fill or Reuse Fill type
              return true;
            } else if (prevType !== "Supply") {
              // not Supply step
              return false;
            }
          }
        }
      }

      return false;
    },

    calculateStepTime(step, enumerable) {
      // Return array for LIGHT, MEDIUM, HEAVY, AND SPEED_CYCLE
      let stepName = (0, _findByDropdownWithCustom.findByDropDown)([this.selectOptions['StepType'], "val", step.Type]).label.toLowerCase();
      let activeState = (0, _findByDropdownWithCustom.findByDropDown)([this.selectOptions['ActiveState'], "val", step.ActiveState]).label;
      let stepLabel = (0, _findByDropdownWithCustom.findByDropDown)([this.selectOptions['StepLabel'], "val", step.Label]).label.toLowerCase();
      let mods = this.mods;
      let stepIndex = enumerable.indexOf(step);
      let times = [0, 0, 0, 0]; // Loop through each mods element

      mods.forEach((mod, index) => {
        let totalSeconds = 0;
        let hours,
            minutes,
            seconds = 0; // Only add up active steps

        if (mod.activeStates.includes(activeState)) {
          switch (stepName) {
            case 'agitate':
              if (stepLabel == 'wash1' && activeState !== 'InactiveMod3') {
                // InactiveMod3 = Off with Speed Cycle
                totalSeconds += mod.additionalWashMinutes * 60;
              }

              if (stepLabel == 'rinse1' && activeState !== 'InactiveMod3') {
                totalSeconds += mod.additionalRinseMinutes * 60;
              }

            // fallthrough

            case 'fill':
            case 'reusefill':
            case 'drainextract':
            case 'audiosignal':
            case 'hold':
            case 'soak':
              hours = step.Hours || 0;
              minutes = step.Minutes || 0;
              seconds = step.Seconds || 0;
              totalSeconds += Number(Big(hours).times(3600).plus(Big(minutes).times(60)).plus(seconds));
              break;

            case 'drain':
              totalSeconds += 30;
              break;

            case 'supply':
              // This one's tricky...
              if (this.isDependentSupplyStep(step, stepIndex, enumerable, mod.activeStates)) {// Time is part of fill step
              } else {
                minutes = step.Minutes || 0;
                seconds = step.Seconds || 0;
                totalSeconds += Number(Big(minutes).times(60).plus(seconds).plus(step.SupplyDelay));
              }

              break;
          }
        }

        times[index] = totalSeconds;
      });
      return times;
    },

    applyTime() {
      this.cycleList.forEach((cycle, index) => {
        Ember.get(cycle, 'Steps').forEach((step, index, enumerable) => {
          Ember.set(step, "_totalSeconds", undefined); // This is needed to force updates to existing items

          Ember.defineProperty(step, "_totalSeconds", Ember.computed('[]', 'this.[]', 'Hours', 'Minutes', 'Seconds', 'Label', 'ActiveState', 'SupplyDelay', () => {
            return this.calculateStepTime(step, enumerable);
          }));
        }); // Now add up the steps for cycles

        Ember.defineProperty(cycle, "_totalSeconds", Ember.computed('Steps.@each._totalSeconds', () => {
          return cycle.Steps.reduce((val, item) => {
            return [Big(val[0]).plus(item._totalSeconds[0]), Big(val[1]).plus(item._totalSeconds[1]), Big(val[2]).plus(item._totalSeconds[2]), Big(val[3]).plus(item._totalSeconds[3])];
          }, [0, 0, 0, 0]);
        }));
      });
    },

    init() {
      this._super(...arguments);

      this.applyTime();
    },

    // NEW STUFF HERE
    //#region Variables
    notifications: Ember.inject.service(),
    intl: Ember.inject.service(),
    clipboard: {
      type: null,
      contents: null,
      title: null
    },
    maxCycles: 15,
    showCycleInfo: false,

    //#endregion
    //#region Events
    didInsertElement() {
      let self = this;

      this._super(...arguments);

      this.send('selectCycle');
      Ember.set(this, 'defaultMachineType', Ember.get(this, 'cycleList.firstObject.MachineType'));
      Ember.set(this, 'hasCycleNumbers', Ember.get(this, 'cycleList.firstObject.Number') ? true : false);
    },

    //#endregion
    //#region Default values
    // TODO: Need to set the MachineType value
    defaultCycle: Ember.computed(function () {
      return {
        "MachineType": this.defaultMachineType,
        "Number": 0,
        "Enabled": true,
        "ProgrammableCycleTime": 60,
        "DisinfectTimeMinutes": 0,
        "DisinfectTemperature": 0,
        "CycleName": this.intl.t("new_cycle"),
        "TempOptions": {
          "Cold": true,
          "Warm": true,
          "Hot": true,
          "NoHeat": false,
          "t15C": false,
          "t30C": false,
          "t40C": false,
          "t50C": false,
          "t60C": false,
          "t90C": false
        },
        "DefaultTemp": 1,
        "Steps": []
      };
    }),
    defaultStep: Ember.computed(function () {
      return {
        "ActiveState": 1,
        "Label": 0,
        "Type": 13,
        "WashDuration": 210,
        "WaterLevelType": 1,
        "WaterLevel": 0,
        "FlushOut": false,
        "AllowTempOverride": true,
        "TemperatureType": 4,
        "Temperature": 104,
        "WashRotateTime": 18,
        "WashPauseTime": 4,
        "WashSpeed": 46,
        "Inlets": {
          "Inlet1": true,
          "Inlet2": false,
          "Inlet3": false,
          "Inlet4": false,
          "Inlet5": true,
          "Inlet6": false,
          "Inlet7": false,
          "Inlet8": false
        },
        "DrainValveForCooldownOrTumble": 0,
        "Detergents": {
          "D1": true,
          "D2": false,
          "D3": false,
          "D4": false,
          "D5": false,
          "D6": false,
          "D7": false,
          "D8": false,
          "D9": false,
          "D10": false,
          "D11": false,
          "D12": false,
          "D13": false,
          "D14": false,
          "D15": false,
          "D16": false
        },
        "DetergentTimes": [30, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        "DrainType": 5,
        "DrainValve": 1,
        "DrainDuration": 60,
        "DrainRotateTime": 18,
        "DrainPauseTime": 4,
        "DrainSpeed": 46
      };
    }),
    //#endregion
    //#region Computed properties
    // TODO: Make sure the cycle array is sorted based on Number.
    cycleList: Ember.computed.alias('model.settings.Cycles'),
    stepList: Ember.computed('selectedCycle', 'selectedCycle.[]', function () {
      // Steps can be in an unnamed array, or an array named `Steps`
      if (Ember.get(this, 'selectedCycle.Steps')) {
        return Ember.get(this, 'selectedCycle.Steps');
      } else {
        return this.selectedCycle || [];
      }
    }),
    isDefaultCycle: Ember.computed('model', function () {
      return Ember.get(this, 'model.settings.Program.Misc.CycleRibonConfig_WX.0');
    }),

    //#endregion
    //#region Methods
    resetCycleNumbers() {
      if (this.hasCycleNumbers) {
        this.cycleList.forEach((cycle, index) => {
          // TODO: Check if cycle.Number is zero-based
          Ember.set(cycle, 'Number', index + 1);
        });
      }
    },

    swapArrayItems(array, index, direction) {
      // Used to re-order array items
      let secondIndex = direction === 'up' ? index - 1 : index + 1;
      let firstItem = [array[index]];
      let secondItem = [array[secondIndex]];

      if (direction == "up" && index > 0 || direction == 'down' && index < array.get('length') - 1) {
        array.replace(index, 1, secondItem).replace(secondIndex, 1, firstItem);
      }

      return array;
    },

    //#endregion
    //#region Actions
    actions: {
      closeModal() {
        Ember.set(this, 'showCycleInfo', false);
      },

      updateCyleOrder(cycle) {
        let orderArray = Ember.get(this, 'model.settings.Program.Misc.CycleRibonConfig_WX');

        if (orderArray.length < 15) {
          let index = orderArray.indexOf(cycle.Number);

          if (index > -1) {
            orderArray.splice(index, 1);
          } else {
            orderArray.push(cycle.Number);
          }
        }

        Ember.set(this, 'model.settings.Program.Misc.CycleRibonConfig_WX', orderArray);
      },

      editCycleInfo(cycle) {
        Ember.set(this, 'showCycleInfo', true);
      },

      moveCycle(cycle, direction) {
        let array = this.cycleList;
        let index = array.indexOf(cycle);
        this.swapArrayItems(array, index, direction);
      },

      moveStep(step, direction) {
        let array = this.stepList;
        let index = array.indexOf(step);
        this.swapArrayItems(array, index, direction);
        this.applyTime(); // Doesn't work
      },

      addCycle() {
        // New for MC10
        let maxCycles = this.maxCycles;
        let list = this.cycleList;

        if (list.length < maxCycles) {
          let newItem = (0, _emberCopy.copy)(this.defaultCycle);
          let index = list.length;
          list.insertAt(index, newItem);
          this.send('selectCycle', index);
          this.resetCycleNumbers();
        } else {
          this.notifications.error('Unable to add a new cycle because this machine only supports 15 cycles. Please delete a cycle and try again.', {
            autoClear: false,
            clearDuration: 1000,
            cssClasses: 'quick-notification'
          });
        }
      },

      deleteCycle(cycle) {
        // New for MC10
        let cycleList = this.cycleList;

        if (cycleList.length === 0) {
          return;
        }

        let description = name || 'Cycle';

        if (confirm(`Delete selected ${description}?`)) {
          let index = cycleList.indexOf(cycle);
          cycleList.removeAt(index); // Now we need to set the next step that is active.

          let selected;
          let newIndex = cycleList.length > index ? index : index - 1;
          this.resetCycleNumbers();
          this.send('selectCycle', newIndex); // if (newIndex >= 0) {
          //   selected =  cycleList.objectAt(newIndex);
          //   set(this, 'selectedCycle', selected);
          //   set(this, 'selectedCycleIndex', newIndex);
          //   $(selected).focus();
          // }
        }
      },

      selectCycle(value, init) {
        if (event && event.target.tagName === "INPUT") {
          return true;
        } // Value can be the cycle object or the index of the cycle


        let index, cycle;

        if (value === undefined) {
          value = 0;
        }

        if (isNaN(value)) {
          // we have an object
          cycle = value;
          index = this.cycleList.indexOf(value);
        } else {
          // we have a number
          index = value;
          cycle = this.cycleList.objectAt(value);
        }

        Ember.set(this, 'selectedCycle', cycle);
        Ember.set(this, 'selectedCycleIndex', Number(index));
        localStorage.setItem('cycle', index || 0);

        if (!init) {
          // Select first step
          this.send('selectStep');
        }
      },

      selectStep(step) {
        if (!step) {
          step = Ember.get(this, 'stepList.firstObject');
        } else if ((0, _isNumber.isNumber)(step)) {
          step = this.stepList.objectAt(Number(step));
        }

        let index = this.stepList.length > 0 ? this.stepList.indexOf(step) : 0;
        Ember.set(this, 'selectedStep', step);
        (0, _jquery.default)(step).focus();
        localStorage.setItem('step', index || 0);
      },

      // These are from the mixin
      deleteStep(step) {
        let stepList = this.stepList;

        if (stepList.length === 0) {
          return;
        }

        let id = Ember.get(step, 'Type');
        let selectOptions = this.selectOptions;
        let description = name || 'Current step';

        if (confirm(`Delete selected ${description}?`)) {
          let index = stepList.indexOf(step);
          stepList.removeAt(index); // Now we need to set the next step that is active.

          let selected;
          let newIndex = stepList.length > index ? index : index - 1;

          if (newIndex >= 0) {
            selected = stepList.objectAt(newIndex);
            Ember.set(this, 'selectedStep', selected);
            Ember.set(this, 'selectedStepIndex', newIndex);
            (0, _jquery.default)(selected).focus();
          }
        }
      },

      addStep(stepType) {
        // New steps are added under the currently selected step, and
        // have the same ActiveState and Label as the selected step.
        let newItem = (0, _emberCopy.copy)(this.defaultSteps.findBy('Type', stepType), true);
        let list = this.stepList;
        let currentStep = this.selectedStep;
        let currentIndex = list.indexOf(currentStep) + 1;

        if (newItem) {
          if (currentStep) {
            newItem.ActiveState = Ember.get(currentStep, 'ActiveState');
            newItem.Label = Ember.get(currentStep, 'Label');
          }

          newItem._totalSeconds = [0, 0, 0, 0];
          list.insertAt(currentIndex, newItem);
        }

        this.send('selectStep', currentIndex);
      },

      copyCycle(cycle) {
        Ember.set(this, 'clipboard.type', 'cycle');
        Ember.set(this, 'clipboard.contents', cycle);
        Ember.set(this, 'clipboard.title', 'Name will be here');
        this.notifications.success('Cycle copied', {
          autoClear: true,
          clearDuration: 1000,
          cssClasses: 'quick-notification'
        });
        return false;
      },

      copyStep(step) {
        Ember.set(this, 'clipboard.type', 'step');
        Ember.set(this, 'clipboard.contents', (0, _emberCopy.copy)(step, true)); // step is an object

        this.notifications.success('Step copied', {
          autoClear: true,
          clearDuration: 3000,
          cssClasses: 'quick-notification'
        });
        return false;
      },

      pasteCycleMC10(cycle) {},

      pasteCycle(cycle) {
        let type = Ember.get(this, 'clipboard.type');

        if (type !== 'cycle') {
          alert('No cycle is currently in your clipboard.');
          return false;
        }

        let clipboard = (0, _emberCopy.copy)(Ember.get(this, 'clipboard.contents'), true);
        let cycles = this.cycleList;
        let index = cycles.indexOf(cycle); // We need to preserve the Name and Number of the existing cycle

        let cycleName = cycles.objectAt(index).Name;
        let cycleNumber = cycles.objectAt(index).Number; // cycles.replace(index, 1, clipboard);

        cycles.removeAt(index);
        cycles.insertAt(index, clipboard); // // cycles.objectAt(index).pushObjects(clipboard);
        // cycles.objectAt(index).pushObjects(clipboard);

        let pastedCycle = cycles.objectAt(index);
        Ember.set(pastedCycle, 'Name', cycleName);
        Ember.set(pastedCycle, 'Number', cycleNumber); // this.send('calculateTime');

        this.send('selectCycle', index); // Add CP for time calculation

        this.applyTime();
        let notifications = this.notifications;
        this.notifications.success('Cycle pasted', {
          autoClear: true,
          clearDuration: 1000,
          cssClasses: 'quick-notification'
        });
        return false;
      },

      pasteStep(step) {
        // We're going to paste the new step above the selected on
        let type = Ember.get(this, 'clipboard.type');

        if (type !== 'step') {
          alert('No step is currently in your clipboard.');
          return false;
        }

        let clipboard = (0, _emberCopy.copy)(Ember.get(this, 'clipboard.contents'), true);
        let steps = this.stepList;
        let index = steps.indexOf(step); // Copy the Label and ActiveState

        let label, activeState;

        if (step) {
          label = Ember.get(step, 'Label');
          activeState = Ember.get(step, 'ActiveState');
        } else {
          // Defaults
          label = 1;
          activeState = 8;
        }

        Ember.set(clipboard, 'Label', label);
        Ember.set(clipboard, 'ActiveState', activeState);
        steps.insertAt(index, clipboard);
        this.send('selectStep', index); // Add CP for time calculation

        this.applyTime(); // let notifications = this.notifications;

        this.notifications.success('Step pasted', {
          autoClear: true,
          clearDuration: 3000,
          cssClasses: 'quick-notification'
        });
        return false;
      }

    },
    mediumMods: Ember.computed.alias('model.settings.Program.Price.ModifierOptions.MediumBundleMods_WX'),
    heavyMods: Ember.computed.alias('model.settings.Program.Price.ModifierOptions.HeavyBundleMods_WX'),
    // Get mods for this machine. TODO: See if we can DRY this up a little.
    mods: Ember.computed('mediumMods.@each', 'heavyMods.@each', function () {
      let mediumMods = this.mediumMods;
      let heavyMods = this.heavyMods;
      let mediumExtraTime = Ember.get(mediumMods, 'Options.ExtraTime');
      let mediumWashTime = mediumExtraTime ? Ember.get(mediumMods, 'ExtraWashAgitateTime') : 0;
      let mediumRinseTime = mediumExtraTime ? Ember.get(mediumMods, 'ExtraRinseAgitateTime') : 0;
      let mediumMod1 = Ember.get(mediumMods, 'Options.Mod1');
      let mediumMod2 = Ember.get(mediumMods, 'Options.Mod2');
      let heavyExtraTime = Ember.get(heavyMods, 'Options.ExtraTime');
      let heavyWashTime = heavyExtraTime ? Ember.get(heavyMods, 'ExtraWashAgitateTime') : 0;
      let heavyRinseTime = heavyExtraTime ? Ember.get(heavyMods, 'ExtraRinseAgitateTime') : 0;
      let heavyMod1 = Ember.get(heavyMods, 'Options.Mod1');
      let heavyMod2 = Ember.get(heavyMods, 'Options.Mod2');
      let allMods = [{
        // No Modifiers
        name: "Light",
        mod1: false,
        mod2: false,
        additionalWashMinutes: 0,
        additionalRinseMinutes: 0,
        activeStates: ["AlwaysEnabled", "InactiveMod4", "InactiveMod3"]
      }, {
        // Mod 1
        name: "Medium",
        mod1: Ember.get(mediumMods, 'Options.Mod1'),
        mod2: Ember.get(mediumMods, 'Options.Mod2'),
        additionalWashMinutes: mediumWashTime,
        additionalRinseMinutes: mediumRinseTime,
        activeStates: ['AlwaysEnabled', "InactiveMod4", "InactiveMod3"]
      }, {
        // Mod 2
        name: "Heavy",
        mod1: Ember.get(heavyMods, 'Options.Mod1'),
        mod2: Ember.get(heavyMods, 'Options.Mod2'),
        additionalWashMinutes: heavyWashTime,
        additionalRinseMinutes: heavyRinseTime,
        activeStates: ['AlwaysEnabled', "InactiveMod4", "InactiveMod3"]
      }, {
        // SpeedCycle
        name: "SpeedCycle",
        mod1: false,
        mod2: false,
        additionalWashMinutes: 0,
        additionalRinseMinutes: 0,
        activeStates: ["AlwaysEnabled"]
      }];

      if (mediumMod1) {
        allMods[1].activeStates.push('ActiveMod1');
      }

      if (mediumMod2) {
        allMods[1].activeStates.push('ActiveMod2');
      }

      if (heavyMod1) {
        allMods[2].activeStates.push('ActiveMod1');
      }

      if (heavyMod2) {
        allMods[2].activeStates.push('ActiveMod2');
      }

      return allMods;
    }),
    //#endregion
    stepNames: Ember.computed(function () {
      return this.selectOptions['StepType'].filterBy('active', true);
    }),
    defaultSteps: [{
      "Type": 1,
      "Label": 1,
      "Speed": 2,
      "Valves": {
        "AuxFill": false,
        "HotFill": false,
        "ColdFill": true,
        "FillValveSet": false,
        "SprayValveSet": false,
        "ExtraFillInlet": false,
        "FillTubValveSet": false,
        "TempControlledFill": false
      },
      "Minutes": 5,
      "Seconds": 0,
      "PauseSec": 4,
      "RotateSec": 18,
      "AuxOutputs": {
        "I": false,
        "J": false,
        "K": false,
        "L": false,
        "M": false,
        "N": false
      },
      "ReuseFillA": false,
      "ReuseFillB": false,
      "WaterLevel": 124,
      "ActiveState": 8,
      "Temperature": 0,
      "FlushoutEnabled": false,
      "ReversingDisabled": false
    }, {
      "Type": 2,
      "Label": 1,
      "Speed": 2,
      "Valves": {
        "AuxFill": false,
        "HotFill": false,
        "ColdFill": true,
        "FillValveSet": true,
        "SprayValveSet": true,
        "ExtraFillInlet": false,
        "FillTubValveSet": true,
        "TempControlledFill": false
      },
      "Minutes": 0,
      "Seconds": 30,
      "PauseSec": 4,
      "RotateSec": 18,
      "AuxOutputs": {
        "I": false,
        "J": false,
        "K": false,
        "L": false,
        "M": false,
        "N": false
      },
      "WaterLevel": 124,
      "ActiveState": 8,
      "Temperature": 0,
      "FlushoutEnabled": false,
      "AllowTempOverride": true,
      "ReversingDisabled": false
    }, {
      "Type": 3,
      "Label": 1,
      "Valves": {
        "AuxFill": false,
        "HotFill": false,
        "ColdFill": true,
        "FillValveSet": true,
        "SprayValveSet": false,
        "ExtraFillInlet": false,
        "FillTubValveSet": false,
        "TempControlledFill": false
      },
      "Minutes": 0,
      "Seconds": 30,
      "AuxOutputs": {
        "I": false,
        "J": false,
        "K": false,
        "L": false,
        "M": false,
        "N": false
      },
      "AuxSupplyA": false,
      "AuxSupplyB": false,
      "AuxSupplyC": false,
      "AuxSupplyD": false,
      "AuxSupplyE": false,
      "AuxSupplyF": false,
      "AuxSupplyG": false,
      "AuxSupplyH": false,
      "ActiveState": 8,
      "SupplyDelay": 0,
      "Compartment1": true,
      "Compartment2": false,
      "Compartment3": false,
      "Compartment4": false,
      "GlobalSupplyTime": 0,
      "ShutoffCondition": 0
    }, {
      "Type": 4,
      "Label": 1,
      "Speed": 2,
      "Minutes": 1,
      "Seconds": 0,
      "HeatRate": 0,
      "PauseSec": 4,
      "RotateSec": 18,
      "AuxOutputs": {
        "I": false,
        "J": false,
        "K": false,
        "L": false,
        "M": false,
        "N": false
      },
      "AuxSupplyA": false,
      "AuxSupplyB": false,
      "AuxSupplyC": false,
      "AuxSupplyD": false,
      "AuxSupplyE": false,
      "AuxSupplyF": false,
      "AuxSupplyG": false,
      "AuxSupplyH": false,
      "HeatOption": 0,
      "ReuseFillA": false,
      "ReuseFillB": false,
      "ActiveState": 8,
      "DrainOptions": {
        "Refill": true,
        "MainDrain1": false,
        "MainDrain2": false,
        "PulseDrain": false,
        "PHChemFlush": false
      },
      "ExternalDrain": false,
      "ReversingDisabled": false
    }, {
      "Type": 5,
      "Hours": 0,
      "Label": 1,
      "Speed": 2,
      "Minutes": 1,
      "HeatRate": 0,
      "PauseSec": 255,
      "RotateSec": 4,
      "AuxOutputs": {
        "I": false,
        "J": false,
        "K": false,
        "L": false,
        "M": false,
        "N": false
      },
      "AuxSupplyA": false,
      "AuxSupplyB": false,
      "AuxSupplyC": false,
      "AuxSupplyD": false,
      "AuxSupplyE": false,
      "AuxSupplyF": false,
      "AuxSupplyG": false,
      "AuxSupplyH": false,
      "HeatOption": 0,
      "ReuseFillA": false,
      "ReuseFillB": false,
      "ActiveState": 8,
      "DrainOptions": {
        "Refill": true,
        "MainDrain1": false,
        "MainDrain2": false,
        "PulseDrain": false,
        "PHChemFlush": false
      },
      "ExternalDrain": false,
      "ReversingDisabled": false
    }, {
      "Type": 6,
      "Label": 1,
      "Speed": 2,
      "Valves": {
        "AuxFill": false,
        "HotFill": false,
        "ColdFill": true,
        "FillValveSet": true,
        "SprayValveSet": true,
        "ExtraFillInlet": false,
        "FillTubValveSet": true,
        "TempControlledFill": false
      },
      "CoolRate": 0,
      "PauseSec": 18,
      "RotateSec": 6,
      "AuxOutputs": {
        "I": false,
        "J": false,
        "K": false,
        "L": false,
        "M": false,
        "N": false
      },
      "AuxSupplyA": false,
      "AuxSupplyB": false,
      "AuxSupplyC": false,
      "AuxSupplyD": false,
      "AuxSupplyE": false,
      "AuxSupplyF": false,
      "AuxSupplyG": false,
      "AuxSupplyH": false,
      "ActiveState": 8,
      "AntiWrinkle": false,
      "Temperature": 0,
      "ReversingDisabled": false
    }, {
      "Type": 7,
      "Label": 1,
      "Speed": 2,
      "PauseSec": 4,
      "RotateSec": 18,
      "AuxOutputs": {
        "I": false,
        "J": false,
        "K": false,
        "L": false,
        "M": false,
        "N": false
      },
      "AuxSupplyA": false,
      "AuxSupplyB": false,
      "AuxSupplyC": false,
      "AuxSupplyD": false,
      "AuxSupplyE": false,
      "AuxSupplyF": false,
      "AuxSupplyG": false,
      "AuxSupplyH": false,
      "ReuseFillA": false,
      "ReuseFillB": false,
      "ActiveState": 8,
      "AlwaysRotate": true,
      "DrainOptions": {
        "Refill": false,
        "MainDrain1": true,
        "MainDrain2": true,
        "PulseDrain": false,
        "PHChemFlush": false
      },
      "ExternalDrain": false,
      "ReversingDisabled": false
    }, {
      "Type": 9,
      "Label": 1,
      "Minutes": 0,
      "Seconds": 30,
      "AuxOutputs": {
        "I": false,
        "J": false,
        "K": false,
        "L": false,
        "M": false,
        "N": false
      },
      "AuxSupplyA": false,
      "AuxSupplyB": false,
      "AuxSupplyC": false,
      "AuxSupplyD": false,
      "AuxSupplyE": false,
      "AuxSupplyF": false,
      "AuxSupplyG": false,
      "AuxSupplyH": false,
      "MainDrain1": true,
      "MainDrain2": true,
      "PulseDrain": false,
      "ReuseFillA": false,
      "ReuseFillB": false,
      "ActiveState": 8,
      "CustomSpeed": 2,
      "ExternalDrain": false
    }, {
      "Type": 10,
      "Label": 1,
      "Minutes": 0,
      "Pattern": 1,
      "Seconds": 5,
      "AuxOutputs": {
        "I": false,
        "J": false,
        "K": false,
        "L": false,
        "M": false,
        "N": false
      },
      "AuxSupplyA": false,
      "AuxSupplyB": false,
      "AuxSupplyC": false,
      "AuxSupplyD": false,
      "AuxSupplyE": false,
      "AuxSupplyF": false,
      "AuxSupplyG": false,
      "AuxSupplyH": false,
      "ActiveState": 8
    }, {
      "Type": 11,
      "Label": 1,
      "Message": 0,
      "Minutes": 0,
      "Pattern": 1,
      "Seconds": 10,
      "AuxOutputs": {
        "I": false,
        "J": false,
        "K": false,
        "L": false,
        "M": false,
        "N": false
      },
      "AuxSupplyA": false,
      "AuxSupplyB": false,
      "AuxSupplyC": false,
      "AuxSupplyD": false,
      "AuxSupplyE": false,
      "AuxSupplyF": false,
      "AuxSupplyG": false,
      "AuxSupplyH": false,
      "ReuseFillA": false,
      "ReuseFillB": false,
      "UnlockDoor": true,
      "ActiveState": 8,
      "DrainOptions": {
        "Refill": false,
        "MainDrain1": true,
        "MainDrain2": true,
        "PulseDrain": false,
        "PHChemFlush": false
      },
      "ExternalDrain": false,
      "AutoResumeMinutes": 0
    }]
  });

  _exports.default = _default;
});