define("alliance-business-suite/templates/components/ui-loading-throb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xLhemjgv",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[35,3]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[[30,[36,1],[[35,0],\"center\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"content-container container--placeholder\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,5,\"margin:30px auto;\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"spinner\"],[12],[2,\"\\n          \"],[10,\"div\"],[14,0,\"rect1 theme-color-background\"],[12],[13],[2,\"\\n          \"],[10,\"div\"],[14,0,\"rect2 theme-color-background\"],[12],[13],[2,\"\\n          \"],[10,\"div\"],[14,0,\"rect3 theme-color-background\"],[12],[13],[2,\"\\n          \"],[10,\"div\"],[14,0,\"rect4 theme-color-background\"],[12],[13],[2,\"\\n          \"],[10,\"div\"],[14,0,\"rect5 theme-color-background\"],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"spinner\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"rect1 theme-color-background\"],[12],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"rect2 theme-color-background\"],[12],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"rect3 theme-color-background\"],[12],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"rect4 theme-color-background\"],[12],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"rect5 theme-color-background\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"position\",\"eq\",\"if\",\"display\"]}",
    "moduleName": "alliance-business-suite/templates/components/ui-loading-throb.hbs"
  });

  _exports.default = _default;
});