define("alliance-business-suite/components/summary/reports/s-employee-sales-goal", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "alliance-business-suite/utils/currency-format-util", "alliance-business-suite/utils/report-tables-util"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _currencyFormatUtil, _reportTablesUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  let SummaryReportsSEmployeeSalesGoalComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, (_class = class SummaryReportsSEmployeeSalesGoalComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor3, this);
    }

    get translatedColumns() {
      return [{
        name: '',
        valuePath: 'name',
        isFixed: 'left'
      }, {
        name: 'email',
        valuePath: 'email'
      }, {
        name: 'date',
        valuePath: 'date'
      }, {
        name: 'total_sales',
        valuePath: 'totalSales'
      }, {
        name: 'daily_goal',
        valuePath: 'dailyGoal'
      }, {
        name: 'completion',
        valuePath: 'percentCompletion'
      }];
    }

    get rowsWithChildren() {
      let rooms = this.args.model.rooms;
      let roomData = [];
      rooms.forEach(room => {
        let roomSectionDataArray = [];
        roomData.push({
          name: room.roomName,
          children: roomSectionDataArray
        });
        room.employees.forEach(employee => {
          let employeeData = [];
          roomSectionDataArray.push({
            name: `${employee.firstName} ${employee.lastName}`,
            email: employee.email,
            isCollapsed: false,
            children: employeeData
          });
          employee.dates.forEach(salesDate => {
            let goal = '-';
            let percentCompletion = '-';

            if (salesDate.roleGoal != null) {
              goal = _currencyFormatUtil.formatCurrencyString.call(this, salesDate.roleGoal || 0);

              if (salesDate.totalSales > 0) {
                percentCompletion = (salesDate.totalSales / salesDate.roleGoal * 100).toFixed(2) + '%';
              } else {
                percentCompletion = '0%';
              }
            }

            if (salesDate.individualGoal != null) {
              goal = _currencyFormatUtil.formatCurrencyString.call(this, salesDate.individualGoal || 0);

              if (salesDate.totalSales > 0) {
                percentCompletion = (salesDate.totalSales / salesDate.individualGoal * 100).toFixed(2) + '%';
              } else {
                percentCompletion = '0%';
              }
            }

            employeeData.push({
              date: moment(salesDate.date).format(`${_reportTablesUtil.formatDateTimeString.call(this)}`),
              totalSales: _currencyFormatUtil.formatCurrencyString.call(this, salesDate.totalSales || 0),
              dailyGoal: goal,
              percentCompletion: percentCompletion
            });
          });
        });
      });
      return roomData;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SummaryReportsSEmployeeSalesGoalComponent;
});