define("alliance-business-suite/templates/components/tables/table-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SSrhCqbN",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[2,\"\\n\"],[10,\"table\"],[14,0,\"table table-striped table-bordered table-condensed v2-table\"],[12],[2,\"\\n\\n\"],[6,[37,5],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,1,[[30,[36,4],null,[[\"header\",\"body\",\"footer\"],[[30,[36,3],[\"tables/table-header\"],null],[30,[36,3],[\"tables/table-body\"],null],[30,[36,3],[\"tables/table-footer\"],null]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"tables/table-header\",[],[[\"@headerOptions\"],[[34,0]]],null],[2,\"\\n\\n    \"],[8,\"tables/table-body\",[],[[\"@model\",\"@modelName\",\"@headerOptions\",\"@clickable\"],[[34,1],[34,2],[34,0],true]],null],[2,\"\\n\\n  \"],[8,\"tables/table-footer\",[],[[],[]],null],[2,\"\\n\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"headerOptions\",\"model\",\"modelName\",\"component\",\"hash\",\"if\"]}",
    "moduleName": "alliance-business-suite/templates/components/tables/table-container.hbs"
  });

  _exports.default = _default;
});