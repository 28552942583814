define("alliance-business-suite/components/brushes/async-brush", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  const PendingResponse = Ember.Object.extend(Ember.PromiseProxyMixin); // This component grabs the proxy and passes it into the yielded component once its finished loading
  // https://tritarget.org/#Asynchronous%20Computed%20Properties%20in%20Ember

  let BrushesAsyncBrushComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._action, _dec7 = Ember.computed('response', 'response.isFulfilled'), _dec8 = Ember.computed('response.isPending'), (_class = class BrushesAsyncBrushComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "brush", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "response", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "isCacheData", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "isLoading", _descriptor5, this);
    }

    initSetup() {
      let promise = this.brush.model;
      let response = null; // do not run unless there is a valid id

      if (!promise.get('id')) {
        this.isLoading = true;
        return;
      } else {
        this.isLoading = false;
      } // reset property


      this.isCacheData = false;

      try {
        let responseObject = PendingResponse.create({
          promise
        });
        responseObject.catch(() => {});
        response = responseObject;
      } catch (error) {
        this.isCacheData = true;
        response = promise;
      }

      this.response = response;
    } // data loaded async and those from the data store are diff, this determines the struc to pass down


    get loadedModel() {
      let {
        modelType
      } = this.args;

      if (!this.response) {
        return;
      }

      if (!this.response.isFulfilled) {
        if (modelType != 'employee' && modelType != 'room' && modelType != 'machine') {
          return;
        }
      } // do not run unless there is a valid id


      let promise = this.brush.model;

      if (!promise.get('id')) {
        // this.isLoading = true;
        return;
      } else {// this.isLoading = false
      } // employee are in the DS actually "users"


      if (modelType == 'employee') {
        modelType = 'user';
      } // async data


      if (this.response.content) {
        return this.store.peekRecord(modelType, this.response.content.id);
      } else {
        try {
          let peekRecord = this.store.peekRecord(modelType, this.response.id);

          if (!peekRecord) {
            return this.store.findRecord(modelType, this.response.id);
          }

          return peekRecord;
        } catch (error) {}
      }
    }

    get state() {
      if (!this.response) {
        return;
      }

      let {
        isPending,
        isSettled,
        isRejected
      } = this.response;
      let stateObj = {
        isPending,
        isSettled,
        isRejected
      };

      if (this.isCacheData) {
        stateObj.isPending = false;
        stateObj.isSettled = true;
        stateObj.isRejected = false;
      }

      return stateObj;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "brush", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "response", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isCacheData", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isLoading", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "initSetup", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "initSetup"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "loadedModel", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "loadedModel"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "state", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "state"), _class.prototype)), _class));
  _exports.default = BrushesAsyncBrushComponent;
});