define("alliance-business-suite/components/rooms/r-view-overview", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class RoomsRViewOverviewComponent extends _component.default {
    get markers() {
      let {
        lat,
        lng
      } = this.args.model;
      let markers = [];
      markers.addObject({
        lat,
        // Required
        lng,
        // Required
        clickable: false
      });
      return markers;
    }

  }

  _exports.default = RoomsRViewOverviewComponent;
});