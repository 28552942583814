define("alliance-business-suite/templates/components/currency-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EAZBurdQ",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,15],[[35,14]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"standard-currency-input-format\",[],[[\"@number\",\"@class\",\"@disabled\",\"@inputId\",\"@currencySymbol\",\"@thousandsSeparator\",\"@decimalSeparator\",\"@decimalPlaces\",\"@symbolPosition\",\"@handleChange\",\"@localeCode\"],[[34,2],[34,4],[34,5],[34,6],[34,7],[34,8],[34,9],[34,10],[34,11],[34,12],[34,13]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[30,[36,3],[[35,2]],[[\"useOverride\",\"isDollars\"],[[35,1],[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"isDollars\",\"useOverride\",\"value\",\"format-currency\",\"class\",\"isDisabled\",\"inputId\",\"currencySymbol\",\"thousandsSeparator\",\"decimalSeparator\",\"decimalPlaces\",\"symbolPosition\",\"handleChange\",\"localeCode\",\"isInput\",\"if\"]}",
    "moduleName": "alliance-business-suite/templates/components/currency-options.hbs"
  });

  _exports.default = _default;
});