define("alliance-business-suite/components/ui/section-panel-element", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * The "block" portion of the class name for this component in the
     * Block-Element-Modifier (BEM) naming convention.
     *
     * @type {Array<String>}
     * @public
     */
    componentClassName: undefined,

    /**
     * The dynamic class names for this component that are derived from component
     * properties.
     *
     * @type {Array<String>}
     * @public
     */
    classNameBindings: ['componentClassName'],
    // TODO: What was this used for?
    attributeBindings: ['style'],
    style: Ember.computed(function () {
      return this.minHeight ? Ember.String.htmlSafe(`min-height: ${this.minHeight}`) : Ember.String.htmlSafe('');
    })
  });

  _exports.default = _default;
});