define("alliance-business-suite/components/wash-alert/wa-ads", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    intl: Ember.inject.service(),
    brand: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.set('activeAd', this.get('ads.firstObject'));
    },

    isAd: Ember.computed('activeAd.advertisementTypeLookupKey', function () {
      return this.get('activeAd.advertisementTypeLookupKey') == 'WASH_ALERT' ? false : true;
    }),
    adTypes: [{
      id: 'ad',
      subtitle: 'ad_subtitle'
    }, {
      id: 'wa',
      subtitle: 'ad_wa_subtitle'
    }],
    currentAdType: Ember.computed('activeAd.advertisementTypeLookupKey', function () {
      if (this.get('activeAd.advertisementTypeLookupKey') == 'WASH_ALERT') {
        return this.adTypes[1];
      } else {
        return this.adTypes[0];
      }
    }),
    tvPublicUrl: Ember.computed(function () {
      return `https://wa.${this.brand.topLevelDomain}.com/${this.session.data.orgId}?room=${this.room.get('id')}&mode=tv`;
    }),
    actions: {
      transitionToWashAlertEditor() {
        let hasChanged = this.hasChanged;

        if (hasChanged) {
          if (confirm(this.intl.t('unsaved_changes_confirm_exit'))) {
            this.router.transitionTo('locations.wash-alert', {
              queryParams: {
                roomId: this.room.get('id')
              }
            });
          }
        } else {
          this.router.transitionTo('locations.wash-alert', {
            queryParams: {
              roomId: this.room.get('id')
            }
          });
        }
      },

      changeDuration() {
        this.set('hasChanged', true);
      },

      changeActiveAd(ad) {
        this.set('activeAd', ad);
      },

      setType(val) {
        this.set('activeAd.advertisementTypeLookupKey', val.id == 'wa' ? 'WASH_ALERT' : 'ADVERTISEMENT');
      },

      addAd(type) {
        let newAd = {
          advertisementTypeLookupKey: type,
          duration: 30,
          background: null,
          imageUrl: null,
          file: null
        };
        let ads = this.ads;
        ads.addObject(newAd);
        this.set('activeAd', newAd);
        this.set('hasChanged', true);
      },

      removeAd(ad) {
        let deletedAds = this.deletedAds;
        deletedAds.addObject(ad);
        let ads = this.ads;
        ads.removeObject(ad);
        let lastAd = this.get('ads.firstObject');
        this.set('activeAd', lastAd);
        this.set('hasChanged', true);
      }

    }
  });

  _exports.default = _default;
});