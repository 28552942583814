define("alliance-business-suite/utils/reports-util", ["exports", "alliance-business-suite/models/report-type", "alliance-business-suite/config/environment"], function (_exports, _reportType, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.coerceToNearest15 = coerceToNearest15;
  _exports.generateReportOptions = generateReportOptions;
  _exports.getMonthList = getMonthList;
  _exports.getYearList = getYearList;
  _exports.legacyDownload = legacyDownload;
  _exports.legacyReportSubmit = legacyReportSubmit;
  _exports.setTimeInput = setTimeInput;
  _exports.templarDownload = templarDownload;
  _exports.templarReportSubmit = templarReportSubmit;

  // returns a list of all the months
  // used by the months drop down in s-reports
  function getMonthList() {
    return Array.apply(0, Array(12)).map(function (_, i) {
      return {
        number: i + 1,
        name: moment().month(i).format("MMMM")
      };
    });
  } // returns a list of current year and 4 years before that
  // used by the years drop down in s-reports


  function getYearList(currentYear) {
    let years = [];
    let minOffset = 0,
        maxOffset = 4; // this is the range of years shown in dropdown

    for (let i = minOffset; i <= maxOffset; i++) {
      let year = currentYear - i;
      years.push({
        value: year,
        text: `${year}`
      });
    }

    return years;
  } // in the time component, sets the time from 11:08 -> 11:15


  function coerceToNearest15(hour, minutes) {
    let momentObj = moment().startOf("day");
    let coeff = 1000 * 60 * 15;
    let date = momentObj.add(minutes, "minutes").toDate();
    let rounded = new Date(Math.round(date.getTime() / coeff) * coeff);
    let resultHour = moment(rounded).format("H");
    let resultMinutes = moment(rounded).format("mm");
    hour += parseInt(resultHour);

    if (hour < 10) {
      hour = "0" + hour;
    }

    minutes = resultMinutes;
    return `${hour}:${minutes}`;
  }

  function generateReportOptions(usePeekAll) {
    // Return an array of objects for power-select grouping: `{ groupName: 'Smalls', options: ['one', 'two', 'three'] },`
    let groupedReportList = [];
    let isRoute = !this.access.get("isLaundromatOrg");
    let reports = usePeekAll ? this.store.peekAll("report-type") : this.args.reports; // Get the list of group names, then filter out the reports for each group

    let groupList = [..._reportType.GROUPS]; // Create "Favorites" category of reports

    let reportFavorites = [];
    reports = reports.map(r => {
      if (r.isFavorite) {
        // create a copy of the favorited so we can disabled the original
        let temp = this.store.peekRecord("report-type", r.id); // push shallow copy of favorite report

        reportFavorites.push({ ...temp,
          id: r.id,
          translatedName: temp.translatedName,
          disabled: false
        });
        r.disabled = true; // disable original option
      } else {
        r.disabled = false;
      }

      return r;
    });

    if (Ember.isNone(groupList.findBy("id", "OTHER_GROUP"))) {
      groupList.pushObject({
        id: "OTHER_GROUP",
        reports: []
      });
    } // add category if there are any favorites and fill it with those favorite reports


    if (reportFavorites && reportFavorites.length > 0) {
      groupList.unshift({
        id: "FAVORITE_REPORTS",
        reports: reportFavorites
      });
    }

    console.log("~~ TEMP 3 ~~~");
    const nfcDisconnectedReports = ["FUNDS_DEPOSIT", "INCOME_STATEMENT", "MANUAL_BALANCE_CHANGE", "REFUNDS", "REWARDS_EARNED", "REWARDS_SPENT", "VALUE_STATION", "NEW_USERS", "VALUE_CENTER_COLLECTIONS", "MACHINE_REMOTE_START_TRACKING", "MONTHLY_RECON", "PEAK_TIMES", "STORE_OVERVIEW", "DAILY_OVERVIEW", "EMPLOYEE_LOGIN", "MACHINE_ACTIVITY", "PEAK_TIMES_BY_HOUR"];

    if (this.access.isDisconnected) {
      reports = reports.filter(r => {
        return nfcDisconnectedReports.indexOf(r.id) !== -1;
      });
    }

    groupList.forEach((group, idx) => {
      let reportList = reports.filter(report => {
        // CAMPUS_CARD_RECON report is only for Route
        if (!isRoute && report.id == "CAMPUS_CARD_RECON") {
          return false;
        } // if disabled, its already in the "Favorites" category so remove it


        if (report.disabled) {
          return false;
        }

        return report.isActive && report.groupName == group.id;
      }); // if any favorites, add that category and fill it

      if (reportFavorites.length > 0 && group.id === "FAVORITE_REPORTS") {
        reportList = reportFavorites;
      }

      if (reportList) {
        groupedReportList.pushObject({
          id: group.id,
          groupName: this.intl.t(group.id),
          options: reportList.sortBy("translatedName")
        });
      }
    }); // remove groups if there are no reports within it

    return groupedReportList.filter(g => {
      if (g.options.length > 0 || g.id === "FAVORITE_REPORTS") {
        return g;
      }
    });
  }

  function setTimeInput(value) {
    console.log("calling setTimeInput", value);
    let result = "00:00";

    if (value) {
      if (typeof value !== "string") {
        value = moment(`${value.hours}:${value.minutes}`, "HH:mm:ss").format("HH:mm");
      }

      let timeArray = value.split(" ");
      let hour = parseInt(timeArray[0].split(":")[0]);
      let minutes = timeArray[0].split(":")[1];
      result = value; // round to the nearest 15th minute

      if (minutes % 15 != 0) {
        result = coerceToNearest15(hour, minutes);
      }
    } else {
      return "00:00";
    }

    return result;
  } // new template reporting system uses a POST vs a GET, and different body than legacy


  async function templarReportSubmit() {
    let results;
    let {
      activeReport,
      startTime,
      endTime
    } = this;
    let params = {
      timezone: moment.tz.guess()
    };

    if (typeof startTime !== "string") {
      startTime = moment(`${startTime.hours}:${startTime.minutes}`, "HH:mm:ss").format("HH:mm");
    }

    if (typeof endTime !== "string") {
      endTime = moment(`${endTime.hours}:${endTime.minutes}`, "HH:mm:ss").format("HH:mm");
    }

    if (activeReport.filterOrganizationId) {
      params.organizationId = this.session.get("data.orgId");
    }

    if (activeReport.filterStartDate) {
      params.startTime = moment(this.args.startDate).format("YYYY-MM-DD");
    }

    if (activeReport.filterEndDate) {
      params.endTime = moment(this.args.endDate).format("YYYY-MM-DD");
    }

    if (activeReport.filterStartTime) {
      params.startTime += " " + startTime;
    }

    if (activeReport.filterEndTime) {
      params.endTime += " " + endTime;
    }

    if (activeReport.filterYear) {
      params.year = this.selectedYear.value;
    }

    if (activeReport.filterMonth) {
      params.month = this.selectedMonth.number;
    }

    if (activeReport.filterFundsRootId) {
      params.fundsRootId = this.selectFundsRoot.id;
    }

    if (activeReport.filterMachineId && this.selectedMachine) {
      params.machineId = this.selectedMachine.get("id");
    }

    if (activeReport.filterRoomId) {
      let roomSelections = this.selectedRooms;

      if (roomSelections.length > 0) {
        let rooms = [];
        roomSelections.forEach(selection => {
          rooms.push(selection.id);
        });
        params.roomIds = rooms;
      } else {
        this.results = null;
        return;
      }
    }

    if (activeReport.filterSingleRoomId) {
      params.roomIds = this.selectedRoom.id;
    }

    this.isLoading = true;
    this.results = null;
    this.errorMessage = null;

    try {
      results = await this.ajax.request(`/reports/v2/${activeReport.id}`, {
        method: "POST",
        data: params
      });
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      this.errorMessage = error.errors ? error.errors[0].title : error.message;
    }

    this.results = results;
  }

  async function legacyReportSubmit() {
    let {
      activeReport,
      startTime,
      endTime
    } = this;
    let params = {};
    let results = null;

    if (typeof startTime !== "string") {
      startTime = moment(`${startTime.hours}:${startTime.minutes}`, "HH:mm:ss").format("HH:mm");
    }

    if (typeof endTime !== "string") {
      endTime = moment(`${endTime.hours}:${endTime.minutes}`, "HH:mm:ss").format("HH:mm");
    }

    params.id = activeReport.id; // Workaround due to inconsistency between activeReport type and data endpoint URL

    if (params.id === "AUDIT_AVERAGE_VEND") {
      params.id = "AUDIT_AVERAGE_VENDING";
    }

    if (params.id === "AUDIT_SPECIAL_VEND") {
      params.id = "AUDIT_SPECIAL_VENDING";
    }

    if (activeReport.filterOrganizationId) {
      params.organizationId = this.session.get("data.orgId");
    }

    if (activeReport.filterStartDate) {
      params.startDate = moment(this.args.startDate).format("YYYY-MM-DD");
    }

    if (activeReport.filterEndDate) {
      params.endDate = moment(this.args.endDate).format("YYYY-MM-DD");
    }

    if (activeReport.filterStartTime) {
      params.startTime = startTime;
    }

    if (activeReport.filterEndTime) {
      params.endTime = endTime;
    }

    if (activeReport.filterYear) {
      params.year = this.selectedYear.value;
    }

    if (activeReport.filterMonth) {
      params.month = this.selectedMonth.number;
    }

    if (activeReport.filterTimeZone) {
      params.timeZone = moment.tz.guess();
    }

    if (activeReport.filterGroupId) {
      params.groupId = this.selectedMembershipGroup?.id;
    }

    if (activeReport.filterOperator) {
      console.log("TODO! missing"); // params.filterOperator = moment.tz.guess();
    }

    if (activeReport.filterFundsRootId) {
      params.fundsRootId = this.selectedFundsRoot.id;
    }

    if (activeReport.filterMachineId && this.selectedMachine) {
      params.machineId = this.selectedMachine.get("id");
    }

    if (activeReport.filterRoomId) {
      let roomSelections = this.selectedRooms;

      if (roomSelections.length > 0) {
        let rooms = [];
        roomSelections.forEach(selection => {
          rooms.push(selection.id);
        });
        params.roomIds = rooms.join();
      } else {
        this.results = null;
        return;
      }
    }

    if (activeReport.id === "MACHINE_DATA") {
      params.organizationId = this.session.data.orgId;
      params.roomId = this.store.peekRecord("machine", this.selectedMachine.get("id")).room.get("id");
    }

    if (activeReport.filterSingleRoomId) {
      params.roomIds = this.selectedRoom.id;
    }

    this.isLoading = true;
    this.results = null;
    this.errorMessage = null; // because we do not have unique IDs (report type is an ID, ember returns the ID)
    // we check if that report type exists and we unload it from the ember store

    let existingRecord = this.store.peekRecord("report", params.id);

    if (existingRecord) {
      existingRecord.unloadRecord();
    }

    try {
      if (this.access.showExperimentalFirelincReport && activeReport.id === "FIRELINC") {
        console.error("1 firelinc stub", params);
        results = await this.ajax.request(`https://alliancelabs-assets-production.s3.us-east-1.amazonaws.com/demo/demo-firelinc.json`, {
          method: "GET"
        }).then(response => {
          this.store.push(response);
          return this.store.peekRecord("report", "FIRELINC");
        }); // } else if (this.access.showExperimentalFirelincReport && activeReport.id === 'MAINTENANCE_SUMMARY') {
        //   console.error('2 firelinc stub', params)
        //
        //   results = await this.ajax.request(`https://alliancelabs-assets-production.s3.us-east-1.amazonaws.com/demo/demo-maintenance.json`, {
        //     method: 'GET'
        //   }).then((response) => {
        //     this.store.push(response)
        //     return this.store.peekRecord('report', 'MAINTENANCE_SUMMARY');
        //   })
      } else {
        results = await this.store.queryRecord("report", {
          filter: params
        });
      }

      this.isLoading = false;
      results.params = params;
    } catch (error) {
      console.log("error!", error);
      this.isLoading = false;
      this.errorMessage = error.errors ? error.errors[0].title : error.message;
    }

    this.results = results;
  }

  function legacyDownload() {
    let {
      activeReport,
      startTime,
      endTime,
      selectedMachine
    } = this;
    let startDate = moment(this.args.startDate).format("YYYY-MM-DD");
    let endDate = moment(this.args.endDate).format("YYYY-MM-DD");
    let url = `${_environment.default.APP.ALLIANCE_API_URL}/reports/${activeReport.id}/download?`;

    if (typeof startTime !== "string") {
      startTime = moment(`${startTime.hours}:${startTime.minutes}`, "HH:mm:ss").format("HH:mm");
    }

    if (typeof endTime !== "string") {
      endTime = moment(`${endTime.hours}:${endTime.minutes}`, "HH:mm:ss").format("HH:mm");
    }

    if (activeReport.filterStartDate) {
      url += `&startDate=${startDate}`;
    }

    if (activeReport.filterEndDate) {
      url += `&endDate=${endDate}`;
    }

    if (activeReport.filterStartTime) {
      url += `&startTime=${startTime}`;
    }

    if (activeReport.filterEndTime) {
      url += `&endTime=${endTime}`;
    }

    if (activeReport.filterYear) {
      url += `&year=${this.selectedYear.value}`;
    }

    if (activeReport.filterMonth) {
      url += `&month=${this.selectedMonth.number}`;
    }

    if (activeReport.filterOrganizationId) {
      url += `&organizationId=${this.session.data.orgId}`;
    }

    if (activeReport.filterMachineId) {
      if (activeReport.filterOrganizationId) {
        url += `&machineId=${this.selectedMachine.get("id")}`;
      } else {
        url += `&machineId=${this.selectedMachine.get("id")}&organizationId=${this.session.data.orgId}`;
      }
    }

    if (activeReport.filterFundsRootId) {
      url += `&fundsRootId=${this.selectedFundsRoot.id}`;
    }

    if (activeReport.filterGroupId) {
      url += `&groupId=${this.selectedMembershipGroup?.id}`;
    }

    if (activeReport.filterRoomId) {
      let roomArray = [];
      this.selectedRooms.forEach(room => {
        roomArray.push(room.id);
      });
      url += "&rooms=" + roomArray.join(","); // eslint-disable-line prefer-template
    }

    if (activeReport.filterSingleRoomId) {
      url += `&rooms=${this.selectedRoom.id}`;
    }

    if (activeReport.filterTimeZone) {
      url += `&timeZone=${moment.tz.guess()}`;
    }

    if (activeReport.id === "MACHINE_DATA") {
      // url += `&organizationId=${this.session.data.orgId}`;
      url += `&roomId=${this.store.peekRecord("machine", this.selectedMachine.get("id")).room.get("id")}`;
    } // this.downloadReady = false;


    this.processingDownload = true;
    this.downloadUrl = null;
    this.ajax.request(url).then(result => {
      this.downloadUrl = result;
      setTimeout(() => {
        this.processingDownload = false;
        document.getElementById("downloadLink").click();
      }, 300);
    });
  }

  function templarDownload() {
    console.log("templarDownload", templarDownload);
    let {
      activeReport,
      startTime,
      endTime,
      selectedMachine
    } = this;
    let url = `${_environment.default.APP.ALLIANCE_API_URL}/reports/v2/${activeReport.id}/download`;
    let params = {
      timezone: moment.tz.guess(),
      locale: this.intl.locale[0]
    };

    if (typeof startTime !== "string") {
      startTime = moment(`${startTime.hours}:${startTime.minutes}`, "HH:mm:ss").format("HH:mm");
    }

    if (typeof endTime !== "string") {
      endTime = moment(`${endTime.hours}:${endTime.minutes}`, "HH:mm:ss").format("HH:mm");
    }

    if (activeReport.filterStartDate) {
      params.startTime = moment(this.args.startDate).format("YYYY-MM-DD");
    }

    if (activeReport.filterEndDate) {
      params.endTime = moment(this.args.endDate).format("YYYY-MM-DD");
    }

    if (activeReport.filterStartTime) {
      params.startTime += " " + startTime;
    }

    if (activeReport.filterEndTime) {
      params.endTime += " " + endTime;
    }

    if (activeReport.filterYear) {
      params.year = this.selectedYear.value;
    }

    if (activeReport.filterMonth) {
      params.month = this.selectedMonth.number;
    }

    if (activeReport.filterOrganizationId) {
      params.organizationId = this.session.data.orgId;
    }

    if (activeReport.filterMachineId && this.selectedMachine) {
      params.machineId = this.selectedMachine.get("id");
    }

    if (activeReport.filterRoomId) {
      let roomArray = [];
      this.selectedRooms.forEach(room => {
        roomArray.push(room.id);
      });
      params.roomIds = roomArray.join();
    } // this.downloadReady = false;


    this.processingDownload = true;
    this.downloadUrl = null;
    this.ajax.request(url, {
      data: params,
      method: "POST"
    }).then(result => {
      this.downloadUrl = result;
      setTimeout(() => {
        this.processingDownload = false;
        document.getElementById("downloadLink").click();
      }, 300);
    }).catch(() => {
      this.processingDownload = false;
      this.notifications.error(this.intl.t("error"), {
        clearDuration: 5000,
        autoClear: true
      });
    });
  }
});