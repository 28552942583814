define("alliance-business-suite/templates/employees/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "I2CODBG+",
    "block": "{\"symbols\":[],\"statements\":[[8,\"employees/e-edit-form\",[],[[\"@model\",\"@isCreate\",\"@securityRoles\"],[[34,0,[\"newEmployee\"]],true,[34,1,[\"securityRoles\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"parentModel\"]}",
    "moduleName": "alliance-business-suite/templates/employees/create.hbs"
  });

  _exports.default = _default;
});