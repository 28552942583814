define("alliance-business-suite/components/form-ui/steps/spray-valves", ["exports", "alliance-business-suite/mixins/init-property-names", "alliance-business-suite/helpers/find-by-dropdown-with-custom"], function (_exports, _initPropertyNames, _findByDropdownWithCustom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_initPropertyNames.default, {
    optionsOptiSpray: [{
      val: 0,
      label: 'Custom'
    }, {
      val: 1,
      label: 'Towels'
    }, {
      val: 2,
      label: 'Sheets'
    }, {
      val: 3,
      label: 'General'
    }],
    optionsSprayValves: [{
      label: "Cold",
      val: 0,
      hot: false,
      cold: true
    }, {
      label: "Warm",
      val: 1,
      hot: true,
      cold: true
    }, {
      label: "Hot",
      val: 2,
      hot: true,
      cold: false
    }],
    sprayValves: Ember.computed('HotSpray', 'ColdSpray', {
      get() {
        // Get options
        let options = this.optionsSprayValves; // Find a match...

        let selected = options.find(item => {
          return item.hot == this.HotSpray && item.cold == this.ColdSpray;
        });
        return Ember.get(selected, "val");
      },

      set(key, value) {
        // We do nothing.
        return value;
      }

    }),
    actions: {
      updateSprayValves(object, propertyName, value) {
        // Find the correct values
        let {
          cold,
          hot
        } = (0, _findByDropdownWithCustom.findByDropDown)([this.optionsSprayValves, "val", value]);
        this.update(this.object, this.properties.HotSpray.key, hot);
        this.update(this.object, this.properties.ColdSpray.key, cold);
      }

    }
  });

  _exports.default = _default;
});