define("alliance-business-suite/models/machine-type", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    hasMany
  } = _emberData.default;
  const machineKeyNames = [{
    id: 0,
    name: "TopLoadWasher",
    ordinal: 1
  }, {
    id: 10,
    name: "FrontLoadWasher",
    ordinal: 1
  }, {
    id: 20,
    name: "Dryer",
    ordinal: 1
  }, {
    id: 21,
    name: "DryerSingle",
    ordinal: 2
  }, {
    id: 22,
    name: "DryerStack",
    ordinal: 3
  }, {
    id: 23,
    name: "DryerStackL",
    ordinal: 4
  }, {
    id: 24,
    name: "DryerStackU",
    ordinal: 5
  }, {
    id: 30,
    name: "Tumbler",
    ordinal: 1
  }, {
    id: 31,
    name: "Tumbler25",
    ordinal: 2
  }, {
    id: 32,
    name: "Tumbler30",
    ordinal: 3
  }, {
    id: 33,
    name: "TumblerT30",
    ordinal: 4
  }, {
    id: 34,
    name: "TumblerT30L",
    ordinal: 5
  }, {
    id: 35,
    name: "TumblerT30U",
    ordinal: 6
  }, {
    id: 36,
    name: "Tumbler35",
    ordinal: 7
  }, {
    id: 37,
    name: "TumblerT45",
    ordinal: 8
  }, {
    id: 38,
    name: "TumblerT45L",
    ordinal: 9
  }, {
    id: 39,
    name: "TumblerT45U",
    ordinal: 10
  }, {
    id: 40,
    name: "Tumbler50",
    ordinal: 11
  }, {
    id: 51,
    name: "TumblerT50",
    ordinal: 12
  }, {
    id: 52,
    name: "TumblerT50L",
    ordinal: 13
  }, {
    id: 53,
    name: "TumblerT50U",
    ordinal: 14
  }, {
    id: 41,
    name: "Tumbler55",
    ordinal: 15
  }, {
    id: 47,
    name: "Tumbler60",
    ordinal: 16
  }, {
    id: 42,
    name: "Tumbler75",
    ordinal: 17
  }, {
    id: 46,
    name: "TumblerF75",
    ordinal: 18
  }, {
    id: 48,
    name: "Tumbler80",
    ordinal: 19
  }, {
    id: 49,
    name: "TumblerF80",
    ordinal: 20
  }, {
    id: 50,
    name: "Tumbler100",
    ordinal: 21
  }, {
    id: 43,
    name: "Tumbler120",
    ordinal: 22
  }, {
    id: 44,
    name: "Tumbler170",
    ordinal: 23
  }, {
    id: 45,
    name: "Tumbler200",
    ordinal: 24
  }, {
    id: 100,
    name: "WasherExtractor_C",
    ordinal: 1
  }, {
    id: 150,
    name: "WasherExtractor_T",
    ordinal: 2
  }, {
    id: 101,
    name: "WasherExtractor_C20",
    ordinal: 3
  }, {
    id: 102,
    name: "WasherExtractor_C25",
    ordinal: 4
  }, {
    id: 103,
    name: "WasherExtractor_C30",
    ordinal: 5
  }, {
    id: 151,
    name: "WasherExtractor_T30L",
    ordinal: 6
  }, {
    id: 104,
    name: "WasherExtractor_C40",
    ordinal: 7
  }, {
    id: 152,
    name: "WasherExtractor_T50L",
    ordinal: 8
  }, {
    id: 105,
    name: "WasherExtractor_C60",
    ordinal: 9
  }, {
    id: 106,
    name: "WasherExtractor_C80",
    ordinal: 10
  }, {
    id: 107,
    name: "WasherExtractor_C100",
    ordinal: 11
  }, {
    id: 108,
    name: "WasherExtractor_C125",
    ordinal: 12
  }, {
    id: 109,
    name: "WasherExtractor_CF250",
    ordinal: 13
  }, {
    id: 120,
    name: "WasherExtractor_P",
    ordinal: 14
  }, {
    id: 121,
    name: "WasherExtractor_P35",
    ordinal: 15
  }, {
    id: 122,
    name: "WasherExtractor_P40",
    ordinal: 16
  }, {
    id: 123,
    name: "WasherExtractor_P60",
    ordinal: 17
  }, {
    id: 124,
    name: "WasherExtractor_P80",
    ordinal: 18
  }, {
    id: 125,
    name: "WasherExtractor_P100",
    ordinal: 19
  }, {
    id: 126,
    name: "WasherExtractor_P125",
    ordinal: 20
  }, {
    id: 127,
    name: "WasherExtractor_P150",
    ordinal: 21
  }, {
    id: 128,
    name: "WasherExtractor_P45",
    ordinal: 22
  }, {
    id: 129,
    name: "WasherExtractor_P65",
    ordinal: 23
  }, {
    id: 130,
    name: "WasherExtractor_P85",
    ordinal: 24
  }, {
    id: 131,
    name: "WasherExtractor_P105",
    ordinal: 25
  }, {
    id: 132,
    name: "WasherExtractor_P130",
    ordinal: 26
  }, {
    id: 133,
    name: "WasherExtractor_P160",
    ordinal: 27
  }, {
    id: 134,
    name: "WasherExtractor_P200",
    ordinal: 28
  }, {
    id: 200,
    name: "Other",
    ordinal: 29
  }, {
    id: 242,
    name: "SY65H",
    ordinal: 1
  }, {
    id: 243,
    name: "SY80H",
    ordinal: 2
  }, {
    id: 244,
    name: "SY105H",
    ordinal: 3
  }, {
    id: 245,
    name: "SY135H",
    ordinal: 4
  }, {
    id: 246,
    name: "SY180H",
    ordinal: 5
  }, {
    id: 247,
    name: "SY240H",
    ordinal: 6
  }, {
    id: 248,
    name: "SY280H",
    ordinal: 7
  }, // Larger MC10 OPL machines
  {
    id: 258,
    name: "SY350",
    ordinal: 8
  }, {
    id: 259,
    name: "IY350",
    ordinal: 8
  }, {
    id: 260,
    name: "SY450",
    ordinal: 9
  }, {
    id: 261,
    name: "IY450",
    ordinal: 9
  }, {
    id: 262,
    name: "SY600",
    ordinal: 10
  }, {
    id: 263,
    name: "IY600",
    ordinal: 10
  }, {
    id: 255,
    name: "SY800",
    ordinal: 11
  }, {
    id: 256,
    name: "SY1000",
    ordinal: 12
  }, {
    id: 257,
    name: "SY1200",
    ordinal: 13
  }, // MC5
  {
    id: 270,
    name: "T9",
    ordinal: 0
  }, {
    id: 281,
    name: "TX9",
    ordinal: 1
  }, {
    id: 271,
    name: "T11",
    ordinal: 2
  }, {
    id: 272,
    name: "T13",
    ordinal: 3
  }, {
    id: 273,
    name: "T16",
    ordinal: 4
  }, {
    id: 274,
    name: "T24",
    ordinal: 5
  }, {
    id: 275,
    name: "T35",
    ordinal: 6
  }, {
    id: 277,
    name: "T9HP",
    ordinal: 7
  }, {
    id: 276,
    name: "TX9HP",
    ordinal: 8
  }, {
    id: 278,
    name: "T11HP",
    ordinal: 9
  }, {
    id: 279,
    name: "T13HP",
    ordinal: 10
  }, {
    id: 280,
    name: "T16HP",
    ordinal: 11
  }, // Combo
  {
    id: 342,
    name: "CZCOMBO65",
    ordinal: 1
  }, {
    id: 343,
    name: "CZCOMBO80",
    ordinal: 2
  }, {
    id: 344,
    name: "CZCOMBO105",
    ordinal: 3
  }, {
    id: 345,
    name: "CZCOMBO135",
    ordinal: 4
  }, {
    id: 346,
    name: "CZCOMBO180",
    ordinal: 5
  }, {
    id: 347,
    name: "CZCOMBO240",
    ordinal: 6
  }, {
    id: 348,
    name: "CZCOMBO280",
    ordinal: 7
  }, // external japan org use case
  {
    id: 900,
    name: 'Generic_Combo',
    ordinal: 900
  }, {
    id: 902,
    name: 'Combo12Yamamoto',
    ordinal: 902
  }, {
    id: 903,
    name: 'Combo17Yamamoto',
    ordinal: 903
  }, {
    id: 904,
    name: 'Combo27Yamamoto',
    ordinal: 904
  }, {
    id: 905,
    name: 'Combo35Yamamoto',
    ordinal: 905
  }];
  const machineTypeSettings = [{
    machineType: 0,
    machineName: 'Topload Washer',
    abbrev: 'TopLoadWasher',
    basketDiameter: 0,
    agitate: {
      min: 0,
      max: 0,
      presets: []
    },
    drain: {
      min: 0,
      max: 0,
      presets: []
    },
    extract: {
      min: 0,
      max: 0,
      presets: []
    },
    sprayRinseExtract: {
      min: 3,
      max: 100,
      presets: []
    },
    cycleTimes: {
      wash: {
        standard: 495,
        tall: 525
      },
      rinseFill: 430,
      rinseSpray: {
        standard: 185,
        tall: 205
      },
      extraRinse: 430
    }
  }, {
    machineType: 10,
    machineName: 'Frontload Washer',
    abbrev: 'FrontLoadWasher',
    basketDiameter: 21.52,
    agitate: {
      min: 0.03,
      max: 0.76,
      presets: [.51, .77]
    },
    drain: {
      min: 0.06,
      max: 2.48,
      presets: [.51, .77, 2.48]
    },
    extract: {
      min: 37.4,
      max: 440,
      presets: [49, 76, 129, 195, 305, 440]
    },
    sprayRinseExtract: {
      min: 3,
      max: 100,
      presets: []
    }
  }, {
    machineType: 100,
    machineName: 'W/X Cabinet Generic',
    abbrev: 'WasherExtractor_C',
    basketDiameter: 0,
    agitate: {
      min: 0.03,
      max: 1.27,
      presets: [0.4, 0.78]
    },
    drain: {
      min: 0.07,
      max: 2.5,
      presets: [0.4, 0.78, 2.5]
    },
    extract: {
      min: 3,
      max: 200,
      presets: [27, 80, 100, 125, 150, 200]
    },
    sprayRinseExtract: {
      min: 3,
      max: 100,
      presets: []
    }
  }, {
    machineType: 101,
    machineName: 'W/X Cab Hard 20#',
    abbrev: 'WasherExtractor_C20',
    basketDiameter: 21,
    agitate: {
      min: 0.03,
      max: 0.75,
      presets: [0.4, 0.78]
    },
    drain: {
      min: 0.07,
      max: 2.5,
      presets: [0.4, 0.78, 2.5]
    },
    extract: {
      min: 2.5,
      max: 200,
      presets: [27, 80, 100, 125, 150, 200]
    },
    sprayRinseExtract: {
      min: 3,
      max: 100,
      presets: []
    },
    c4: {
      vSpeed: [27, 80, 100, 125, 150, 200],
      fSpeed: [60, 80, 100, 100, 100, 100]
    },
    waterUse: {
      slope1: 0.1337,
      intercept1: 8.2388,
      slope2: 0.1369,
      intercept2: 4.1294,
      heat: 0.51
    }
  }, {
    machineType: 102,
    machineName: 'W/X Cab Hard 25#',
    abbrev: 'WasherExtractor_C25',
    basketDiameter: 0,
    agitate: {
      min: 0,
      max: 0,
      presets: []
    },
    drain: {
      min: 0,
      max: 0,
      presets: []
    },
    extract: {
      min: 0,
      max: 0,
      presets: []
    },
    sprayRinseExtract: {
      min: 3,
      max: 100,
      presets: []
    }
  }, {
    machineType: 103,
    machineName: 'W/X Cab Hard 30#',
    abbrev: 'WasherExtractor_C30',
    basketDiameter: 24,
    agitate: {
      min: 0.04,
      max: 0.86,
      presets: [0.4, 0.78]
    },
    drain: {
      min: 0.08,
      max: 2.5,
      presets: [0.4, 0.78, 2.5]
    },
    extract: {
      min: 2.5,
      max: 200,
      presets: [27, 80, 100, 125, 150, 200]
    },
    sprayRinseExtract: {
      min: 3,
      max: 100,
      presets: []
    },
    c4: {
      vSpeed: [27, 80, 100, 125, 150, 200],
      fSpeed: [60, 80, 100, 100, 100, 100]
    },
    waterUse: {
      slope1: 0.165,
      intercept1: 10.105,
      slope2: 0.1815,
      intercept2: 4.2756,
      heat: 0.46
    }
  }, {
    machineType: 104,
    machineName: 'W/X Cab Hard 40#',
    abbrev: 'WasherExtractor_C40',
    basketDiameter: 26.3,
    agitate: {
      min: 0.4,
      max: 0.94,
      presets: [0.4, 0.78]
    },
    drain: {
      min: 0.09,
      max: 2.5,
      presets: [0.4, 0.78, 2.5]
    },
    extract: {
      min: 2.5,
      max: 200,
      presets: [27, 80, 100, 125, 150, 200]
    },
    sprayRinseExtract: {
      min: 3,
      max: 100,
      presets: []
    },
    c4: {
      vSpeed: [27, 80, 100, 125, 150, 200],
      fSpeed: [60, 80, 100, 100, 100, 100]
    },
    waterUse: {
      slope1: 0.0533,
      intercept1: 15.56,
      slope2: 0.1246,
      intercept2: 6.5663,
      heat: 0.61
    }
  }, {
    machineType: 105,
    machineName: 'W/X Cab Hard 60#',
    abbrev: 'WasherExtractor_C60',
    basketDiameter: 30,
    agitate: {
      min: 0.05,
      max: 1.07,
      presets: [0.4, 0.78]
    },
    drain: {
      min: 0.1,
      max: 2.5,
      presets: [0.4, 0.78, 2.5]
    },
    extract: {
      min: 2.5,
      max: 200,
      presets: [27, 80, 100, 125, 150, 200]
    },
    sprayRinseExtract: {
      min: 3,
      max: 100,
      presets: []
    },
    c4: {
      vSpeed: [27, 80, 100, 125, 150, 200],
      fSpeed: [60, 80, 100, 100, 100, 100]
    },
    waterUse: {
      slope1: 0.2287,
      intercept1: 24.324,
      slope2: 0.1988,
      intercept2: 12.014,
      heat: 0.95
    }
  }, {
    machineType: 106,
    machineName: 'W/X Cab Hard 80#',
    abbrev: 'WasherExtractor_C80',
    basketDiameter: 36,
    agitate: {
      min: 0.06,
      max: 1.27,
      presets: [0.4, 0.78]
    },
    drain: {
      min: .12,
      max: 2.5,
      presets: [0.4, 0.78, 2.5]
    },
    extract: {
      min: 2.5,
      max: 200,
      presets: [27, 80, 100, 125, 150, 200]
    },
    sprayRinseExtract: {
      min: 3,
      max: 100,
      presets: []
    },
    c4: {
      vSpeed: [27, 80, 100, 125, 150, 200],
      fSpeed: [60, 80, 100, 100, 100, 100]
    },
    waterUse: {
      slope1: 0.5329,
      intercept1: 30.321,
      slope2: 0.4969,
      intercept2: 12.074,
      heat: 1.16
    }
  }, {
    machineType: 107,
    machineName: 'W/X Cab Hard 100#',
    abbrev: 'WasherExtractor_C100',
    basketDiameter: 36,
    agitate: {
      min: 0.06,
      max: 1.27,
      presets: [0.4, 0.78]
    },
    drain: {
      min: 0.12,
      max: 2.5,
      presets: [0.4, 0.78, 2.5]
    },
    extract: {
      min: 2.5,
      max: 200,
      presets: [27, 80, 100, 125, 150, 200]
    },
    sprayRinseExtract: {
      min: 3,
      max: 100,
      presets: []
    },
    c4: {
      vSpeed: [27, 80, 100, 125, 150, 165],
      fSpeed: [27, 80, 100, 100, 100, 100]
    },
    waterUse: {
      slope1: 0.6967,
      intercept1: 35.94,
      slope2: 0.6083,
      intercept2: 13.91,
      heat: 1.43
    }
  }, {
    machineType: 108,
    machineName: 'W/X Cab Hard 125#',
    abbrev: 'WasherExtractor_C125',
    basketDiameter: 0,
    agitate: {
      min: 0,
      max: 0,
      presets: []
    },
    drain: {
      min: 0,
      max: 0,
      presets: []
    },
    extract: {
      min: 0,
      max: 0,
      presets: []
    },
    sprayRinseExtract: {
      min: 3,
      max: 100,
      presets: []
    },
    usesSupplyTime: true
  }, {
    machineType: 109,
    machineName: 'W/X Cab Hard 125#',
    abbrev: 'WasherExtractor_CF250',
    basketDiameter: 0,
    agitate: {
      min: 0,
      max: 0,
      presets: []
    },
    drain: {
      min: 0,
      max: 0,
      presets: []
    },
    extract: {
      min: 0,
      max: 0,
      presets: []
    },
    sprayRinseExtract: {
      min: 3,
      max: 100,
      presets: []
    }
  }, // TODO: THESE WERE COPY/PASTE AND ARE NOT CORRECT!!!
  // TODO: 120 settings need to be applied to other sizes for MGD OPL WX
  {
    machineType: 120,
    machineName: 'W/X',
    abbrev: 'WasherExtractor_P',
    basketDiameter: 0,
    agitate: {
      min: 0.03,
      max: 0.77,
      presets: []
    },
    drain: {
      min: 0.06,
      max: 2.48,
      presets: []
    },
    extract: {
      min: 0.3,
      max: 0.77,
      presets: []
    },
    drainExtract: {
      min: 2,
      max: 500,
      presets: []
    },
    sprayRinseExtract: {
      min: 2,
      max: 100,
      presets: []
    }
  }, {
    machineType: 128,
    machineName: 'W/X',
    abbrev: 'WasherExtractor_P45',
    basketDiameter: 0,
    agitate: {
      min: 0.03,
      max: 0.77,
      presets: []
    },
    drain: {
      min: 0.06,
      max: 2.48,
      presets: []
    },
    extract: {
      min: 0.3,
      max: 0.77,
      presets: []
    },
    drainExtract: {
      min: 2,
      max: 500,
      presets: []
    },
    sprayRinseExtract: {
      min: 2,
      max: 100,
      presets: []
    }
  }, {
    machineType: 129,
    machineName: 'W/X',
    abbrev: 'WasherExtractor_P65',
    basketDiameter: 0,
    agitate: {
      min: 0.03,
      max: 0.77,
      presets: []
    },
    drain: {
      min: 0.06,
      max: 2.48,
      presets: []
    },
    extract: {
      min: 0.3,
      max: 0.77,
      presets: []
    },
    drainExtract: {
      min: 2,
      max: 500,
      presets: []
    },
    sprayRinseExtract: {
      min: 2,
      max: 100,
      presets: []
    }
  }, {
    machineType: 130,
    machineName: 'W/X',
    abbrev: 'WasherExtractor_P85',
    basketDiameter: 0,
    agitate: {
      min: 0.03,
      max: 0.77,
      presets: []
    },
    drain: {
      min: 0.06,
      max: 2.48,
      presets: []
    },
    extract: {
      min: 0.3,
      max: 0.77,
      presets: []
    },
    drainExtract: {
      min: 2,
      max: 500,
      presets: []
    },
    sprayRinseExtract: {
      min: 2,
      max: 100,
      presets: []
    }
  }, {
    machineType: 131,
    machineName: 'W/X',
    abbrev: 'WasherExtractor_P105',
    basketDiameter: 0,
    agitate: {
      min: 0.03,
      max: 0.77,
      presets: []
    },
    drain: {
      min: 0.06,
      max: 2.48,
      presets: []
    },
    extract: {
      min: 0.3,
      max: 0.77,
      presets: []
    },
    drainExtract: {
      min: 2,
      max: 500,
      presets: []
    },
    sprayRinseExtract: {
      min: 2,
      max: 100,
      presets: []
    }
  }, {
    machineType: 132,
    machineName: 'W/X',
    abbrev: 'WasherExtractor_P130',
    basketDiameter: 0,
    agitate: {
      min: 0.03,
      max: 0.77,
      presets: []
    },
    drain: {
      min: 0.06,
      max: 2.48,
      presets: []
    },
    extract: {
      min: 0.3,
      max: 0.77,
      presets: []
    },
    drainExtract: {
      min: 2,
      max: 500,
      presets: []
    },
    sprayRinseExtract: {
      min: 2,
      max: 100,
      presets: []
    }
  }, {
    machineType: 133,
    machineName: 'W/X',
    abbrev: 'WasherExtractor_P160',
    basketDiameter: 0,
    agitate: {
      min: 0.03,
      max: 0.77,
      presets: []
    },
    drain: {
      min: 0.06,
      max: 2.48,
      presets: []
    },
    extract: {
      min: 0.3,
      max: 0.77,
      presets: []
    },
    drainExtract: {
      min: 2,
      max: 500,
      presets: []
    },
    sprayRinseExtract: {
      min: 2,
      max: 100,
      presets: []
    }
  }, {
    machineType: 134,
    machineName: 'W/X',
    abbrev: 'WasherExtractor_P200',
    basketDiameter: 0,
    agitate: {
      min: 0.03,
      max: 0.77,
      presets: []
    },
    drain: {
      min: 0.06,
      max: 2.48,
      presets: []
    },
    extract: {
      min: 0.3,
      max: 0.77,
      presets: []
    },
    drainExtract: {
      min: 2,
      max: 500,
      presets: []
    },
    sprayRinseExtract: {
      min: 2,
      max: 100,
      presets: []
    }
  }, {
    machineType: 150,
    machineName: 'W/X Stack Generic',
    abbrev: 'WasherExtractor_T',
    basketDiameter: 0,
    agitate: {
      min: 0.03,
      max: 1.27,
      presets: [0.4, 0.78]
    },
    drain: {
      min: 0.07,
      max: 2.5,
      presets: [0.4, 0.78, 2.5]
    },
    extract: {
      min: 3,
      max: 200,
      presets: [27, 80, 100, 125, 150, 200]
    },
    sprayRinseExtract: {
      min: 3,
      max: 100,
      presets: []
    }
  }, {
    machineType: 151,
    machineName: 'W/X Stack 30# Lower',
    abbrev: 'WasherExtractor_T30L',
    basketDiameter: 24,
    agitate: {
      min: 0.04,
      max: 0.86,
      presets: [0.4, 0.78]
    },
    drain: {
      min: 0.08,
      max: 2.5,
      presets: [0.4, 0.78, 2.5]
    },
    extract: {
      min: 2.5,
      max: 200,
      presets: [27, 80, 100, 125, 150, 200]
    },
    sprayRinseExtract: {
      min: 3,
      max: 100,
      presets: []
    },
    c4: {
      vSpeed: [27, 80, 100, 125, 150, 200],
      fSpeed: [60, 80, 100, 100, 100, 100]
    },
    waterUse: {
      slope1: 0.165,
      intercept1: 10.105,
      slope2: 0.1815,
      intercept2: 4.2756,
      heat: 0.46
    }
  }, {
    machineType: 152,
    machineName: 'W/X Stack 50# Lower',
    abbrev: 'WasherExtractor_T50L',
    basketDiameter: 26.3,
    agitate: {
      min: 0.4,
      max: 0.94,
      presets: [0.4, 0.78]
    },
    drain: {
      min: 0.09,
      max: 2.5,
      presets: [0.4, 0.78, 2.5]
    },
    extract: {
      min: 2.5,
      max: 200,
      presets: [27, 80, 100, 125, 150, 200]
    },
    sprayRinseExtract: {
      min: 3,
      max: 100,
      presets: []
    },
    c4: {
      vSpeed: [27, 80, 100, 125, 150, 200],
      fSpeed: [60, 80, 100, 100, 100, 100]
    },
    waterUse: {
      slope1: 0.0533,
      intercept1: 15.56,
      slope2: 0.1246,
      intercept2: 6.5663,
      heat: 0.61
    }
  }, // MC10

  /*
    Run this query to get limits, then convert to JSON using https://jsfiddle.net/tycahill/m3h8obd0/
    (The list of machine_type_name values was provided by CZ.)
   -- Data
    SELECT
      m.machine_type_id as machineTypeId,
      m.machine_type_name as machineTypeName,
      wl.min_prog_level_mm / 10 as 'waterMin',
      wl.max_prog_level_mm / 10 as 'waterMax',
      ms.drum_wash_RPM_min as 'washRpmMin',
      ms.drum_wash_RPM_max as 'washRpmMax',
      ms.drum_spin_RPM_min as 'spinRpmMin',
      ms.drum_spin_RPM_max as 'spinRpmMax'
    FROM
      'machine_water_level_limit' as wl,
      'machine_type' as mt,
      'machines' as m,
      'machine_speed_limit' as ms
    WHERE wl.id = mt.machine_water_level_limit_id
      AND ms.id = mt.machine_speed_limit_id
      AND mt.id = m.machine_type_id
      AND m.machine_type_id in ('42', '43', '44', '45', '46', '47', '48', '55', '56', '57', '58', '59', '60', '61', '62', '63')
      AND m.brand_id in ('1', '3', '4', '5', '6')
   */
  {
    "machineType": 242,
    "machineName": "SY65H",
    abbrev: "SY65H",
    "waterMin": 9,
    "waterMax": 29,
    "washRpmMin": 10,
    "washRpmMax": 60,
    "spinRpmMin": 150,
    "spinRpmMax": 1165
  }, {
    "machineType": 243,
    "machineName": "SY80H",
    abbrev: "SY80H",
    "waterMin": 9,
    "waterMax": 29,
    "washRpmMin": 10,
    "washRpmMax": 60,
    "spinRpmMin": 150,
    "spinRpmMax": 1165
  }, {
    "machineType": 244,
    "machineName": "SY105H",
    abbrev: "SY105H",
    "waterMin": 9,
    "waterMax": 30,
    "washRpmMin": 10,
    "washRpmMax": 60,
    "spinRpmMin": 150,
    "spinRpmMax": 1075
  }, {
    "machineType": 245,
    "machineName": "SY135H",
    abbrev: "SY135H",
    "waterMin": 9,
    "waterMax": 30,
    "washRpmMin": 10,
    "washRpmMax": 60,
    "spinRpmMin": 150,
    "spinRpmMax": 1075
  }, {
    "machineType": 246,
    "machineName": "SY180H",
    abbrev: "SY180H",
    "waterMin": 7,
    "waterMax": 30,
    "washRpmMin": 10,
    "washRpmMax": 60,
    "spinRpmMin": 150,
    "spinRpmMax": 980
  }, {
    "machineType": 247,
    "machineName": "SY240H",
    abbrev: "SY240H",
    "waterMin": 7,
    "waterMax": 30,
    "washRpmMin": 10,
    "washRpmMax": 60,
    "spinRpmMin": 150,
    "spinRpmMax": 980
  }, {
    "machineType": 248,
    "machineName": "SY280H",
    abbrev: "SY280H",
    "waterMin": 7,
    "waterMax": 30,
    "washRpmMin": 10,
    "washRpmMax": 55,
    "spinRpmMin": 150,
    "spinRpmMax": 915
  }, // MC10_COMBO
  {
    "machineType": 342,
    "machineName": "SY65H",
    abbrev: "SY65H",
    "waterMin": 9,
    "waterMax": 29,
    "washRpmMin": 10,
    "washRpmMax": 60,
    "spinRpmMin": 150,
    "spinRpmMax": 1165
  }, {
    "machineType": 343,
    "machineName": "SY80H",
    abbrev: "SY80H",
    "waterMin": 9,
    "waterMax": 29,
    "washRpmMin": 10,
    "washRpmMax": 60,
    "spinRpmMin": 150,
    "spinRpmMax": 1165
  }, {
    "machineType": 344,
    "machineName": "SY105H",
    abbrev: "SY105H",
    "waterMin": 9,
    "waterMax": 30,
    "washRpmMin": 10,
    "washRpmMax": 60,
    "spinRpmMin": 150,
    "spinRpmMax": 1075
  }, {
    "machineType": 345,
    "machineName": "SY135H",
    abbrev: "SY135H",
    "waterMin": 9,
    "waterMax": 30,
    "washRpmMin": 10,
    "washRpmMax": 60,
    "spinRpmMin": 150,
    "spinRpmMax": 1075
  }, {
    "machineType": 346,
    "machineName": "SY180H",
    abbrev: "SY180H",
    "waterMin": 7,
    "waterMax": 30,
    "washRpmMin": 10,
    "washRpmMax": 60,
    "spinRpmMin": 150,
    "spinRpmMax": 980
  }, {
    "machineType": 347,
    "machineName": "SY240H",
    abbrev: "SY240H",
    "waterMin": 7,
    "waterMax": 30,
    "washRpmMin": 10,
    "washRpmMax": 60,
    "spinRpmMin": 150,
    "spinRpmMax": 980
  }, {
    "machineType": 348,
    "machineName": "SY280H",
    abbrev: "SY280H",
    "waterMin": 7,
    "waterMax": 30,
    "washRpmMin": 10,
    "washRpmMax": 55,
    "spinRpmMin": 150,
    "spinRpmMax": 915
  }, {
    "machineType": 255,
    "machineName": "SY800",
    abbrev: "SY800",
    "waterMin": 27,
    "waterMax": 72,
    "washRpmMin": 10,
    "washRpmMax": 45,
    "spinRpmMin": 75,
    "spinRpmMax": 750
  }, {
    "machineType": 256,
    "machineName": "SY1000",
    abbrev: "SY1000",
    "waterMin": 30,
    "waterMax": 70,
    "washRpmMin": 10,
    "washRpmMax": 45,
    "spinRpmMin": 75,
    "spinRpmMax": 722
  }, {
    "machineType": 257,
    "machineName": "SY1200",
    abbrev: "SY1200",
    "waterMin": 30,
    "waterMax": 70,
    "washRpmMin": 10,
    "washRpmMax": 45,
    "spinRpmMin": 75,
    "spinRpmMax": 695
  }, {
    "machineType": 258,
    "machineName": "SY350",
    abbrev: "SY350",
    "waterMin": 34,
    "waterMax": 80,
    "washRpmMin": 10,
    "washRpmMax": 45,
    "spinRpmMin": 75,
    "spinRpmMax": 839
  }, {
    "machineType": 259,
    "machineName": "IY350",
    abbrev: "IY350",
    "waterMin": 34,
    "waterMax": 80,
    "washRpmMin": 10,
    "washRpmMax": 45,
    "spinRpmMin": 75,
    "spinRpmMax": 839
  }, {
    "machineType": 260,
    "machineName": "SY450",
    abbrev: "SY450",
    "waterMin": 34,
    "waterMax": 80,
    "washRpmMin": 10,
    "washRpmMax": 45,
    "spinRpmMin": 75,
    "spinRpmMax": 839
  }, {
    "machineType": 261,
    "machineName": "IY450",
    abbrev: "IY450",
    "waterMin": 34,
    "waterMax": 80,
    "washRpmMin": 10,
    "washRpmMax": 45,
    "spinRpmMin": 75,
    "spinRpmMax": 839
  }, {
    "machineType": 262,
    "machineName": "SY600",
    abbrev: "SY600",
    "waterMin": 28,
    "waterMax": 80,
    "washRpmMin": 10,
    "washRpmMax": 42,
    "spinRpmMin": 75,
    "spinRpmMax": 788
  }, {
    "machineType": 263,
    "machineName": "IY600",
    abbrev: "IY600",
    "waterMin": 28,
    "waterMax": 80,
    "washRpmMin": 10,
    "washRpmMax": 42,
    "spinRpmMin": 75,
    "spinRpmMax": 788
  }];
  const dryerIds = [20, 21, 22, 23, 24];
  const tumblerIds = [30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 281, 270, 271, 272, 273, 274, 275, 276, 277, 278, 279, 280 // MC5
  ];
  const extractorIds = [100, 101, 102, 103, 104, 105, 106, 107, 108, 120, 121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 151, 152, 242, 243, 244, 245, 246, 247, 248, // MC10 machines
  255, 256, 257, 258, 259, 260, 261, 262, 263 // Mid/Large MC10 OPL machines
  ];
  const toploadIds = [0];
  const frontloadIds = [10];
  const comboIds = [342, 343, 344, 345, 346, 347, 348, // ALS
  900, 902, 903, 904, 905 // Yamamoto
  ]; // external japan use case

  const genericWasherAndDryer = [900];
  const mc5BrandNameLookup = [{
    id: 281,
    label: 'TX9',
    brand: 'PR'
  }, {
    id: 270,
    label: 'T9',
    brand: 'PR'
  }, {
    id: 271,
    label: 'T11',
    brand: 'PR'
  }, {
    id: 272,
    label: 'T13',
    brand: 'PR'
  }, {
    id: 273,
    label: 'T16',
    brand: 'PR'
  }, {
    id: 274,
    label: 'T24',
    brand: 'PR'
  }, {
    id: 275,
    label: 'T35',
    brand: 'PR'
  }, {
    id: 276,
    label: 'TX9HP',
    brand: 'PR'
  }, {
    id: 277,
    label: 'T9HP',
    brand: 'PR'
  }, {
    id: 278,
    label: 'T11HP',
    brand: 'PR'
  }, {
    id: 279,
    label: 'T13HP',
    brand: 'PR'
  }, {
    id: 280,
    label: 'T16HP',
    brand: 'PR'
  }, {
    id: 281,
    label: 'IDR195',
    brand: 'IP'
  }, {
    id: 270,
    label: 'IDR190',
    brand: 'IP'
  }, {
    id: 271,
    label: 'IDR250',
    brand: 'IP'
  }, {
    id: 272,
    label: 'IDR285',
    brand: 'IP'
  }, {
    id: 273,
    label: 'IDR345',
    brand: 'IP'
  }, {
    id: 274,
    label: 'IDR530',
    brand: 'IP'
  }, {
    id: 275,
    label: 'IDR680',
    brand: 'IP'
  }, {
    id: 276,
    label: 'IHP195',
    brand: 'IP'
  }, {
    id: 277,
    label: 'IHP190',
    brand: 'IP'
  }, {
    id: 278,
    label: 'IHP250',
    brand: 'IP'
  }, {
    id: 279,
    label: 'IHP285',
    brand: 'IP'
  }, {
    id: 280,
    label: 'IHP345',
    brand: 'IP'
  }, {
    id: 281,
    label: 'SDR195',
    brand: 'SQ'
  }, {
    id: 270,
    label: 'SDR190',
    brand: 'SQ'
  }, {
    id: 271,
    label: 'SDR250',
    brand: 'SQ'
  }, {
    id: 272,
    label: 'SDR285',
    brand: 'SQ'
  }, {
    id: 273,
    label: 'SDR345',
    brand: 'SQ'
  }, {
    id: 274,
    label: 'SDR530',
    brand: 'SQ'
  }, {
    id: 275,
    label: 'SDR680',
    brand: 'SQ'
  }, {
    id: 276,
    label: 'SHP195',
    brand: 'SQ'
  }, {
    id: 277,
    label: 'SHP190',
    brand: 'SQ'
  }, {
    id: 278,
    label: 'SHP250',
    brand: 'SQ'
  }, {
    id: 279,
    label: 'SHP285',
    brand: 'SQ'
  }, {
    id: 280,
    label: 'SHP345',
    brand: 'SQ'
  }, {
    id: 281,
    label: 'UDR195',
    brand: 'UM'
  }, {
    id: 270,
    label: 'UDR190',
    brand: 'UM'
  }, {
    id: 271,
    label: 'UDR250',
    brand: 'UM'
  }, {
    id: 272,
    label: 'UDR285',
    brand: 'UM'
  }, {
    id: 273,
    label: 'UDR345',
    brand: 'UM'
  }, {
    id: 274,
    label: 'UDR530',
    brand: 'UM'
  }, {
    id: 275,
    label: 'UDR680',
    brand: 'UM'
  }, {
    id: 276,
    label: 'UHP195',
    brand: 'UM'
  }, {
    id: 277,
    label: 'UHP190',
    brand: 'UM'
  }, {
    id: 278,
    label: 'UHP250',
    brand: 'UM'
  }, {
    id: 279,
    label: 'UHP285',
    brand: 'UM'
  }, {
    id: 280,
    label: 'UHP345',
    brand: 'UM'
  }, {
    id: 281,
    label: 'LSX195',
    brand: 'GENERIC'
  }, {
    id: 270,
    label: 'LS195',
    brand: 'GENERIC'
  }, {
    id: 271,
    label: 'LS250',
    brand: 'GENERIC'
  }, {
    id: 272,
    label: 'LS300',
    brand: 'GENERIC'
  }, {
    id: 273,
    label: 'LS350',
    brand: 'GENERIC'
  }, {
    id: 274,
    label: 'LS530',
    brand: 'GENERIC'
  }, {
    id: 275,
    label: 'LS680',
    brand: 'GENERIC'
  }, {
    id: 276,
    label: 'LSX195HP',
    brand: 'GENERIC'
  }, {
    id: 277,
    label: 'LS195',
    brand: 'GENERIC'
  }, {
    id: 278,
    label: 'LS250HP',
    brand: 'GENERIC'
  }, {
    id: 279,
    label: 'LS300HP',
    brand: 'GENERIC'
  }, {
    id: 280,
    label: 'LS350HP',
    brand: 'GENERIC'
  }];
  const mc10BrandNameLookup = [{
    id: 242,
    label: 'FX65H',
    brand: 'PR'
  }, {
    id: 242,
    label: 'UY65H',
    brand: 'UM'
  }, {
    id: 242,
    label: 'SY65H',
    brand: 'SQ'
  }, {
    id: 242,
    label: 'IY65H',
    brand: 'IP'
  }, {
    id: 242,
    label: 'HY65H',
    brand: 'HB'
  }, {
    id: 243,
    label: 'FX80H',
    brand: 'PR'
  }, {
    id: 243,
    label: 'UY80H',
    brand: 'UM'
  }, {
    id: 243,
    label: 'SY80H',
    brand: 'SQ'
  }, {
    id: 243,
    label: 'IY80H',
    brand: 'IP'
  }, {
    id: 243,
    label: 'HY80H',
    brand: 'HB'
  }, {
    id: 244,
    label: 'FX105H',
    brand: 'PR'
  }, {
    id: 244,
    label: 'UY105H',
    brand: 'UM'
  }, {
    id: 244,
    label: 'SY105H',
    brand: 'SQ'
  }, {
    id: 244,
    label: 'IY105H',
    brand: 'IP'
  }, {
    id: 244,
    label: 'HY105H',
    brand: 'HB'
  }, {
    id: 245,
    label: 'FX135H',
    brand: 'PR'
  }, {
    id: 245,
    label: 'UY135H',
    brand: 'UM'
  }, {
    id: 245,
    label: 'SY135H',
    brand: 'SQ'
  }, {
    id: 245,
    label: 'IY135H',
    brand: 'IP'
  }, {
    id: 245,
    label: 'HY135H',
    brand: 'HB'
  }, {
    id: 246,
    label: 'FX180H',
    brand: 'PR'
  }, {
    id: 246,
    label: 'UY180H',
    brand: 'UM'
  }, {
    id: 246,
    label: 'SY180H',
    brand: 'SQ'
  }, {
    id: 246,
    label: 'IY180H',
    brand: 'IP'
  }, {
    id: 246,
    label: 'HY180H',
    brand: 'HB'
  }, {
    id: 247,
    label: 'FX240H',
    brand: 'PR'
  }, {
    id: 247,
    label: 'UY240H',
    brand: 'UM'
  }, {
    id: 247,
    label: 'SY240H',
    brand: 'SQ'
  }, {
    id: 247,
    label: 'IY240H',
    brand: 'IP'
  }, {
    id: 247,
    label: 'HY240H',
    brand: 'HB'
  }, {
    id: 248,
    label: 'FX280H',
    brand: 'PR'
  }, {
    id: 248,
    label: 'UY280H',
    brand: 'UM'
  }, {
    id: 248,
    label: 'SY280H',
    brand: 'SQ'
  }, {
    id: 248,
    label: 'IY280H',
    brand: 'IP'
  }, {
    id: 248,
    label: 'HY280H',
    brand: 'HB'
  }, {
    id: 255,
    label: 'FS800',
    brand: 'PR'
  }, {
    id: 255,
    label: 'UY800',
    brand: 'UM'
  }, {
    id: 255,
    label: 'SY800',
    brand: 'SQ'
  }, {
    id: 255,
    label: 'IY800',
    brand: 'IP'
  }, {
    id: 255,
    label: 'HY800',
    brand: 'HB'
  }, {
    id: 256,
    label: 'FS1000',
    brand: 'PR'
  }, {
    id: 256,
    label: 'UY1000',
    brand: 'UM'
  }, {
    id: 256,
    label: 'SY1000',
    brand: 'SQ'
  }, {
    id: 256,
    label: 'IY1000',
    brand: 'IP'
  }, {
    id: 256,
    label: 'HY1000',
    brand: 'HB'
  }, {
    id: 257,
    label: 'FS1200',
    brand: 'PR'
  }, {
    id: 257,
    label: 'UY1200',
    brand: 'UM'
  }, {
    id: 257,
    label: 'SY1200',
    brand: 'SQ'
  }, {
    id: 257,
    label: 'IY1200',
    brand: 'IP'
  }, {
    id: 257,
    label: 'HY1200',
    brand: 'HB'
  }, {
    id: 258,
    label: 'FX350',
    brand: 'PR'
  }, {
    id: 258,
    label: 'UY350',
    brand: 'UM'
  }, {
    id: 258,
    label: 'SY350',
    brand: 'SQ'
  }, {
    id: 258,
    label: 'HY350',
    brand: 'HB'
  }, {
    id: 259,
    label: 'IY350',
    brand: 'IP'
  }, {
    id: 260,
    label: 'FX450',
    brand: 'PR'
  }, {
    id: 260,
    label: 'UY450',
    brand: 'UM'
  }, {
    id: 260,
    label: 'SY450',
    brand: 'SQ'
  }, {
    id: 260,
    label: 'HY450',
    brand: 'HB'
  }, {
    id: 261,
    label: 'IY450',
    brand: 'IP'
  }, {
    id: 262,
    label: 'FX600',
    brand: 'PR'
  }, {
    id: 262,
    label: 'UY600',
    brand: 'UM'
  }, {
    id: 262,
    label: 'SY600',
    brand: 'SQ'
  }, {
    id: 262,
    label: 'HY600',
    brand: 'HB'
  }, {
    id: 263,
    label: 'IY600',
    brand: 'IP'
  }];

  var _default = Model.extend({
    access: Ember.inject.service(),
    intl: Ember.inject.service(),
    brand: Ember.inject.service(),
    name: attr('string'),
    typeName: attr('string'),
    machines: hasMany('machines'),
    isDryer: attr('boolean'),
    isWasher: attr('boolean'),
    isCombo: Ember.computed('isDryer', 'isWasher', 'id', function () {
      // Is this a washer and a dryer?
      if (this.isDryer && this.isWasher) {
        return true;
      } // Is this a known combo based on machine type?


      if ([comboIds].includes(this.id)) {
        return true;
      } // Is this a konwn combo based on machineAuditType?
      // TODO: We don't know the audit type in this model.


      return false;
    }),
    key: Ember.computed('id', function () {
      // This is the enumeration used to identify the machine type and size, such as `WasherExtractor_C40` for a "Washer Extractor 40# Cabinet"
      return machineKeyNames.findBy('id', Number(this.id)) ? machineKeyNames.findBy('id', Number(this.id)).name : "undefined";
    }),
    ordinal: Ember.computed('id', function () {
      // This is the enumeration used to identify the machine type and size, such as `WasherExtractor_C40` for a "Washer Extractor 40# Cabinet"
      return machineKeyNames.findBy('id', Number(this.id)).ordinal;
    }),
    machineSettings: Ember.computed('id', function () {
      let machineType = Number(Ember.get(this, 'id'));
      return machineTypeSettings.findBy('machineType', machineType);
    }),
    machineSpeeds: Ember.computed.alias('machineSettings'),
    basketDiameter: Ember.computed.alias('machineSettings.basketDiameter'),
    cycleTimes: Ember.computed.alias('machineSettings.cycleTimes'),
    equipmentType: Ember.computed.alias('generalMachineType.equipmentType'),
    equipmentTypeAbbreviation: Ember.computed('id', function () {
      return this.isWasher ? 'W' : 'D';
    }),
    generalMachineType: Ember.computed('id', function () {
      let id = parseInt(Ember.get(this, 'id'));

      if (dryerIds.includes(id)) {
        return {
          name: 'dryer',
          code: 'DRY',
          default: 20,
          grouping: 'dryer',
          compatibleMachines: dryerIds,
          equipmentType: 'dryer'
        };
      }

      if (tumblerIds.includes(id)) {
        return {
          name: 'tumbler',
          code: 'TUM',
          default: 30,
          grouping: 'tumbler',
          compatibleMachines: tumblerIds,
          equipmentType: 'dryer'
        };
      }

      if (toploadIds.includes(id)) {
        return {
          name: 'topload',
          code: 'TLW',
          default: 0,
          grouping: 'top-load-washer',
          compatibleMachines: toploadIds,
          equipmentType: 'washer'
        };
      }

      if (frontloadIds.includes(id)) {
        return {
          name: 'frontload',
          code: 'FLW',
          default: 10,
          grouping: 'front-load-washer',
          compatibleMachines: frontloadIds,
          equipmentType: 'washer'
        };
      }

      if (extractorIds.includes(id)) {
        return {
          name: 'extractor',
          code: 'WX',
          default: 100,
          grouping: 'washer-extractor',
          compatibleMachines: extractorIds,
          equipmentType: 'washer'
        };
      }

      if (comboIds.includes(id)) {
        return {
          name: 'combo',
          code: 'CBO',
          default: 345,
          grouping: 'combo',
          compatibleMachines: comboIds,
          equipmentType: 'combo'
        };
      }

      return {
        name: "",
        code: "",
        default: null,
        grouping: 'error',
        compatibleMachines: null,
        equipmentType: 'unknown'
      };
    }),
    machineTypeKey: Ember.computed('id', function () {
      // Returns machine type description for international or domestic machines, based on the access.isInternational
      if (this.access.isInternational) {
        return `machineType.intl.${this.key}`;
      } else {
        return `machineType.us.${this.key}`;
      }
    }),
    machineNameFormatted: Ember.computed('id', function () {
      // Returns machine type description for international or domestic machines, based on the weightLookupKey
      // MC10 OPL machines display different size labels based on the brand, so we need to catch this and update to the correct label
      let mc10 = [242, 243, 244, 245, 246, 247, 248, 255, 256, 257, 258, 259, 260, 261, 262, 263];
      let mc5 = [281, 270, 271, 272, 273, 274, 275, 276, 277, 278, 279, 280];

      if (mc10.includes(Number(this.id))) {
        // { id: 242, label: 'UY65H', brand: 'UM' },
        let brandName = mc10BrandNameLookup.find(item => {
          return item.id === Number(this.id) && this.brand.abbrev === item.brand.toLowerCase();
        });
        return brandName ? brandName.label : 'unknown';
      } else if (mc5.includes(Number(this.id))) {
        let brandName = mc5BrandNameLookup.find(item => {
          return item.id === Number(this.id) && this.brand.abbrev === item.brand.toLowerCase();
        });
        return brandName ? brandName.label : 'unknown';
      } else {
        if (this.access.org.weightLookupKey === 'KILOGRAM') {
          return this.intl.t(`machineType.intl.${this.key}`);
        } else {
          return this.intl.t(`machineType.us.${this.key}`);
        }
      }
    }),
    machineTypeName: Ember.computed('typeName', 'name', function () {
      // Handle combo machines differently. TODO: Make this less hacky.
      let useKeyTranslated = [342, 343, 344, 345, 346, 347, 348, 900, 902, 903, 904, 905];

      if (useKeyTranslated.includes(Number(this.id))) {
        return this.intl.t(this.key);
      } // Bug: typeName sometimes returns kg machine name instead of lbs. Caught this on Galaxy Touch 20# in Machine Size drop-down


      if (this.name || this.typeName) {
        return this.name || this.typeName;
      } else {
        return this.key;
      }
    }),
    machineGrouping: Ember.computed.alias('generalMachineType.grouping'),
    machineGroupingName: Ember.computed('generalMachineType.code', function () {
      const code = this.generalMachineType.code;
      return code ? this.intl.t(code) : '';
    }),
    compatibleMachines: Ember.computed.alias('generalMachineType.compatibleMachines')
  });

  _exports.default = _default;
});