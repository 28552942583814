define("alliance-business-suite/components/dashboard/charts/opl/efficiency", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="opl-reports-container" {{did-insert this.initialLoad}}>
    {{#if this.hasEfficiencyChart}}
      <div class="top-row-boxes">
        {{#each this.shiftEfficiency as |barChart|}}
          <div class="productivity-bar-box {{this.cssEfficiencyCharts}} {{if barChart.disabled 'disabled-shift' ''}}">
            <h1>
              {{#if barChart.disabled}}
                ---
  
                <br>
                <small>No data</small>
              {{else}}
                {{barChart.header}}
  
                <br>
                <small>employees per ton</small>
              {{/if}}
            </h1>
  
            <h2>{{t barChart.text}}</h2>
          </div>
        {{/each}}
      </div>
  
      {{#if this.chartResults}}
        <HighCharts
          @chartOptions={{this.chartOptions}}
          @content={{this.chartData}} />
      {{/if}}
    {{else}}
      <Ui::PlaceholderMessage
        @image="/assets/images/warning.svg"
        @title="No data for this range. Please confirm shifts are defined." />
    {{/if}}
  </div>
  
  */
  {
    "id": "QMnnlV77",
    "block": "{\"symbols\":[\"barChart\"],\"statements\":[[11,\"div\"],[24,0,\"opl-reports-container\"],[4,[38,4],[[32,0,[\"initialLoad\"]]],null],[12],[2,\"\\n\"],[6,[37,0],[[32,0,[\"hasEfficiencyChart\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"top-row-boxes\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,0,[\"shiftEfficiency\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[15,0,[31,[\"productivity-bar-box \",[32,0,[\"cssEfficiencyCharts\"]],\" \",[30,[36,0],[[32,1,[\"disabled\"]],\"disabled-shift\",\"\"],null]]]],[12],[2,\"\\n          \"],[10,\"h1\"],[12],[2,\"\\n\"],[6,[37,0],[[32,1,[\"disabled\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"              ---\\n\\n              \"],[10,\"br\"],[12],[13],[2,\"\\n              \"],[10,\"small\"],[12],[2,\"No data\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"              \"],[1,[32,1,[\"header\"]]],[2,\"\\n\\n              \"],[10,\"br\"],[12],[13],[2,\"\\n              \"],[10,\"small\"],[12],[2,\"employees per ton\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"          \"],[13],[2,\"\\n\\n          \"],[10,\"h2\"],[12],[1,[30,[36,1],[[32,1,[\"text\"]]],null]],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n\\n\"],[6,[37,0],[[32,0,[\"chartResults\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"high-charts\",[],[[\"@chartOptions\",\"@content\"],[[32,0,[\"chartOptions\"]],[32,0,[\"chartData\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"ui/placeholder-message\",[],[[\"@image\",\"@title\"],[\"/assets/images/warning.svg\",\"No data for this range. Please confirm shifts are defined.\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"t\",\"-track-array\",\"each\",\"did-insert\"]}",
    "moduleName": "alliance-business-suite/components/dashboard/charts/opl/efficiency.hbs"
  });

  let DashboardChartsOplEfficiencyComponent = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._action, (_class = class DashboardChartsOplEfficiencyComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "utilizationGoal", _descriptor2, this);
    }

    initialLoad() {
      this.args.isLoaded();
    }

    get activeFilter() {
      switch (this.args.filter) {
        case 'washers':
          return {
            bar: 'washersTotal',
            chart: 'washersTotal'
          };

        case 'dryers':
          return {
            bar: 'dryersTotal',
            chart: 'dryersTotal'
          };

        default:
          return {
            bar: 'allTotal',
            chart: 'allTotal'
          };
      }
    }

    get chartResults() {
      let oplRawResults = this.args.results;
      let val = null;
      oplRawResults.some(arr => {
        if (arr.type == 'efficiency_daily') {
          val = arr.data;
          return true;
        }
      });

      if (!val) {
        return;
      }

      let dateAxis = [];
      let shift_1 = [];
      let shift_2 = [];
      let shift_3 = [];
      val.forEach(date => {
        dateAxis.push(date.date);
        shift_1.push(date.shift_1);
        shift_2.push(date.shift_2);
        shift_3.push(date.shift_3);
      });
      return {
        dateAxis,
        shift_1,
        shift_2,
        shift_3
      };
    }

    get shiftEfficiency() {
      let oplRawResults = this.args.results;
      let val = [];
      oplRawResults.some(arr => {
        if (arr.type == 'shift_efficiency_breakdown') {
          val = arr.data;
          return true;
        }
      });
      return val.map(d => {
        return {
          text: d.id,
          header: parseFloat(d.stats[this.activeFilter.bar]).toFixed(2),
          disabled: d.stats[this.activeFilter.bar] === null
        };
      });
    } // show top column stats only if there is a shift


    get hasEfficiencyChart() {
      return this.shiftEfficiency.filter(c => {
        return c.text.indexOf('shift_') != -1;
      }).length > 0;
    }

    get cssEfficiencyCharts() {
      switch (this.shiftEfficiency.length) {
        case 4:
          return 'size-25';

        case 3:
          return 'size-33';

        case 2:
          return 'size-50';

        default:
          return '';
      }
    }

    get chartOptions() {
      return {
        chart: {
          zoomType: 'xy'
        },
        title: {
          text: null // removes title

        },
        subtitle: {
          text: null // removes subtitle

        },
        xAxis: {
          categories: this.chartResults.dateAxis,
          crosshair: true
        },
        yAxis: {
          // Primary yAxis (Utilization)
          labels: {
            format: '{value} per ton',
            style: {
              color: Highcharts.getOptions().colors[1]
            }
          },
          title: {
            text: '',
            style: {
              color: Highcharts.getOptions().colors[1]
            }
          }
        },
        tooltip: {
          shared: true,
          valueSuffix: ' per ton'
        },
        legend: {
          enabled: true // layout: 'vertical',
          // align: 'left',
          // x: 120,
          // verticalAlign: 'top',
          // y: 100,
          // floating: true,
          // backgroundColor:
          //   Highcharts.defaultOptions.legend.backgroundColor || // theme
          //   'rgba(255,255,255,0.25)'

        },
        plotOptions: {
          column: {
            // stacking: 'normal',
            dataLabels: {
              enabled: true
            }
          },
          area: {
            marker: {
              enabled: false
            },
            fillColor: {
              linearGradient: [0, 0, 0, 300],
              stops: [[0, "rgba(241,241,241,0.35)"], [1, Highcharts.color("#f13343").setOpacity(0).get('rgba')]]
            }
          }
        }
      };
    }

    get chartData() {
      // let alertsData = [0, 3, 0, 0, 1, 5, 2, 0, 0, 1, 1, 0];
      // let utilizationData = [22.0, 22.3, 21.7, 21.9, 22.3, 21.5, 21.4, 21.2, 21.6, 21.4, 21.3, 0];
      //
      // let utilizationGoalData = utilizationData.map((d) => {
      //   return this.utilizationGoal;
      // })
      return [{
        name: this.intl.t('shift_1'),
        type: 'area',
        fillOpacity: 0.125,
        color: '#f1a344',
        lineWidth: 5,
        data: this.chartResults.shift_1,
        tooltip: {
          valueSuffix: ' per ton'
        }
      }, {
        name: this.intl.t('shift_2'),
        type: 'area',
        fillOpacity: 0.125,
        color: '#8676ff',
        lineWidth: 5,
        data: this.chartResults.shift_2,
        tooltip: {
          valueSuffix: ' per ton'
        }
      }, {
        name: this.intl.t('shift_3'),
        type: 'area',
        fillOpacity: 0.125,
        color: '#7314f8',
        lineWidth: 5,
        data: this.chartResults.shift_3,
        tooltip: {
          valueSuffix: ' per ton'
        }
      }];
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "utilizationGoal", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 21;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "initialLoad", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "initialLoad"), _class.prototype)), _class));
  _exports.default = DashboardChartsOplEfficiencyComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, DashboardChartsOplEfficiencyComponent);
});