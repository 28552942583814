define("alliance-business-suite/routes/summary/alerts/manage", ["exports", "@babel/runtime/helpers/esm/defineProperty", "alliance-business-suite/routes/_secureRoute"], function (_exports, _defineProperty2, _secureRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SummaryAlertsManageRoute extends _secureRoute.default {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "allowAccess", 'SUMMARY_ALERTS_VIEW_PAGE');
    }

    async model(params) {
      let reportSubscription = this.store.peekRecord('subscription', params.alert_id);
      let selectedRooms = [];
      let errorCount = null;
      reportSubscription.get('subscriptionRoomConditions').forEach(function (roomSubscription) {
        selectedRooms.push(roomSubscription.get('room'));
      });
      reportSubscription.get('subscriptionConditions').forEach(function (subscriptionCondition) {
        if (subscriptionCondition.get('subscriptionConditionType.lookupKey') == 'ERROR_COUNT_ABOVE') {
          errorCount = subscriptionCondition.get('intValue');
        }
      });
      return Ember.RSVP.hash({
        rooms: this.store.peekAll('room'),
        subscriptionTemplates: this.store.peekAll('subscriptionTemplate'),
        reportSubscription,
        selectedRooms,
        errorCount
      });
    }

    setupController(controller, model) {
      super.setupController(controller, model);
      controller.machineAuditErrorSubTemplates = model.machineAuditErrorSubTemplates;
      controller.machineErrorSubTemplates = model.subscriptionTemplates;
    }

  }

  _exports.default = SummaryAlertsManageRoute;
});