define("alliance-business-suite/components/machines/programs/cycles/cycle-info-primus-mc10-wx", ["exports", "alliance-business-suite/mixins/machines/programs/mc10-options", "jquery"], function (_exports, _mc10Options, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_mc10Options.default, {
    didInsertElement() {
      this._super(...arguments);

      (0, _jquery.default)(this.element).focus();
      Mousetrap.bind(["esc", "enter"], (e, combo) => {
        e.preventDefault();
        e.stopPropagation();
        Ember.get(this, "close")();
      });
    },

    destroy() {
      this._super(...arguments); // Clean up keyboard shortcuts


      Mousetrap.unbind(["esc", "enter"]);
    },

    icons: Ember.computed(function () {
      return [{
        val: 0,
        label: "Unknown"
      }, {
        val: 1,
        label: "Normal",
        image: "/assets/images/machine-programming/mc10/cycle-icons/Normal.png"
      }, {
        val: 2,
        label: "Synthetics",
        image: "/assets/images/machine-programming/mc10/cycle-icons/Synthetics.png"
      }, {
        val: 3,
        label: "Delicate",
        image: "/assets/images/machine-programming/mc10/cycle-icons/Delicates.png"
      }, {
        val: 4,
        label: "Colors",
        image: "/assets/images/machine-programming/mc10/cycle-icons/Colors.png"
      }, {
        val: 5,
        label: "Whites",
        image: "/assets/images/machine-programming/mc10/cycle-icons/Whites.png"
      }, {
        val: 6,
        label: "Mix",
        image: "/assets/images/machine-programming/mc10/cycle-icons/Mix.png"
      }, {
        val: 7,
        label: "Woolens",
        image: "/assets/images/machine-programming/mc10/cycle-icons/Woolens.png"
      }, {
        val: 8,
        label: "Bedding",
        image: "/assets/images/machine-programming/mc10/cycle-icons/Bedding.png"
      }, {
        val: 9,
        label: "HotWash",
        image: "/assets/images/machine-programming/mc10/cycle-icons/HotWash.png"
      }, {
        val: 10,
        label: "WarmWash",
        image: "/assets/images/machine-programming/mc10/cycle-icons/WarmWash.png"
      }, {
        val: 11,
        label: "Cotton",
        image: "/assets/images/machine-programming/mc10/cycle-icons/Cotton.png"
      }, {
        val: 12,
        label: "HighSpeedSpin",
        image: "/assets/images/machine-programming/mc10/cycle-icons/HighSpeedSpin.png"
      }, {
        val: 13,
        label: "PreClean",
        image: "/assets/images/machine-programming/mc10/cycle-icons/PreClean.png"
      }, {
        val: 14,
        label: "CustomCycle",
        image: "/assets/images/machine-programming/mc10/cycle-icons/CustomCycle.png"
      }];
    }),
    actions: {
      selectDefaultTemperature(value) {
        // Update this property and check the same item in allowed temperatures.
        Ember.set(this, "model.DefaultTemp", value.val);
        Ember.set(this, `model.TempOptions.${value.key}`, true);
      }

    }
  });

  _exports.default = _default;
});