define("alliance-business-suite/utils/crm-schedule-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SCHEDULE_TYPE = _exports.ON_OPTIONS = _exports.FREQUENCY_OPTIONS = _exports.END_OPTIONS = _exports.DAY_OPTIONS = void 0;
  const SCHEDULE_TYPE = [{
    id: 'oneOff',
    text: 'one_time_only',
    value: true
  }, {
    id: 'recurring',
    text: 'recurring',
    value: false
  }];
  _exports.SCHEDULE_TYPE = SCHEDULE_TYPE;
  const FREQUENCY_OPTIONS = [{
    id: 'daily',
    text: 'day(s)',
    value: 'days'
  }, {
    id: 'weekly',
    text: 'week(s)',
    value: 'weeks'
  }, {
    id: 'monthly',
    text: 'month(s)',
    value: 'months'
  }];
  _exports.FREQUENCY_OPTIONS = FREQUENCY_OPTIONS;
  const END_OPTIONS = [{
    id: 'never',
    text: 'never'
  }, {
    id: 'by',
    text: 'by_date'
  }];
  _exports.END_OPTIONS = END_OPTIONS;
  const ON_OPTIONS = [{
    id: 'day',
    text: 'Day'
  }, {
    id: '',
    text: '--------',
    disabled: true
  }, {
    id: 'first',
    text: 'First'
  }, {
    id: 'second',
    text: 'Second'
  }, {
    id: 'third',
    text: 'Third'
  }, {
    id: 'fourth',
    text: 'Forth'
  }, {
    id: 'last',
    text: 'Last'
  }];
  _exports.ON_OPTIONS = ON_OPTIONS;
  const DAY_OPTIONS = [{
    id: 'day',
    text: 'Day'
  }, {
    id: 'weekday',
    text: 'Weekday'
  }, {
    id: 'weekend',
    text: 'Weekend Day'
  }, {
    id: '',
    text: '-------',
    disabled: true
  }, {
    id: 'sunday',
    text: 'Sunday'
  }, {
    id: 'monday',
    text: 'Monday'
  }, {
    id: 'tuesday',
    text: 'Tuesday'
  }, {
    id: 'wednesday',
    text: 'Wednesday'
  }, {
    id: 'thursday',
    text: 'Thursday'
  }, {
    id: 'friday',
    text: 'Friday'
  }, {
    id: 'saturday',
    text: 'Saturday'
  }];
  _exports.DAY_OPTIONS = DAY_OPTIONS;
});