define("alliance-business-suite/templates/retail/inventory", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XCGAPyXD",
    "block": "{\"symbols\":[],\"statements\":[[8,\"inventory/inventory-section\",[],[[\"@items\",\"@taxes\",\"@inventory\",\"@isLocation\"],[[34,0,[\"items\"]],[34,0,[\"taxes\"]],[34,0,[\"inventory\"]],true]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "moduleName": "alliance-business-suite/templates/retail/inventory.hbs"
  });

  _exports.default = _default;
});