define("alliance-business-suite/models/payment-method", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    cardType: attr('string'),
    lastFour: attr('string'),
    expMonth: attr('string'),
    expYear: attr('string'),
    isDefault: attr('boolean'),
    paymentNonce: attr('string'),
    userAccount: belongsTo('account'),
    organization: belongsTo('organization'),
    // These aren't returning any attributes in the `includes` and don't have
    // their own endpoints yet, so ignore these for now. [twl 4.May.17]
    //
    //   paymentType: belongsTo('paymentType'),
    //   paymentMethodType: belongsTo('paymentMethodType'),
    createdBy: belongsTo('user', {
      inverse: null
    }),
    updatedBy: belongsTo('user', {
      inverse: null
    })
  });

  _exports.default = _default;
});