define("alliance-business-suite/templates/summary/subscriptions/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "43gK2bYA",
    "block": "{\"symbols\":[],\"statements\":[[8,\"summary/subscriptions/s-edit-form\",[],[[\"@queryReportId\",\"@subscriptionTemplatesModel\",\"@rooms\",\"@clearQueryParam\"],[[32,0,[\"reportId\"]],[32,0,[\"model\",\"subscriptionTemplates\"]],[32,0,[\"model\",\"rooms\"]],[32,0,[\"clearQueryParam\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "alliance-business-suite/templates/summary/subscriptions/create.hbs"
  });

  _exports.default = _default;
});