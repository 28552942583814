define("alliance-business-suite/templates/components/customers/customers-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fmJ8AZfT",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"section--container-v2 margin-bottom-20\"],[12],[2,\"\\n  \"],[10,\"header\"],[14,0,\"ui-route-header\"],[12],[2,\"\\n    \"],[10,\"h2\"],[12],[2,\"\\n      \"],[1,[30,[36,1],[\"customers\"],null]],[2,\"\\n      \"],[10,\"span\"],[14,0,\"ui-route-header__subtitle\"],[12],[2,\"\\n        \"],[1,[30,[36,1],[\"customers_subtitle\"],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"controls controls--right\"],[12],[2,\"\\n\"],[6,[37,2],[[32,0,[\"canExport\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[11,\"button\"],[24,0,\"secondary\"],[4,[38,0],[[32,0],\"toggleDownloadModal\"],null],[12],[2,\"\\n          \"],[10,\"i\"],[14,0,\"fa fa-download\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n\\n          \"],[8,\"ember-tooltip\",[],[[\"@side\"],[\"left\"]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[30,[36,1],[\"download_customers_list_tooltip\"],null]],[2,\"\\n          \"]],\"parameters\":[]}]]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[6,[37,2],[[32,0,[\"viewDownloadOptions\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"customers/modal-download-customers-list\",[],[[\"@close\"],[[32,0,[\"toggleDownloadModal\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,\"customers/c-list-table\",[],[[],[]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"t\",\"if\"]}",
    "moduleName": "alliance-business-suite/templates/components/customers/customers-section.hbs"
  });

  _exports.default = _default;
});