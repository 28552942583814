define("alliance-business-suite/adapters/community-group", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "alliance-business-suite/adapters/application", "alliance-business-suite/config/environment"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  let CommunityGroupAdapter = (_dec = Ember.inject.service, (_class = class CommunityGroupAdapter extends _application.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "session", _descriptor, this);
      (0, _defineProperty2.default)(this, "host", _environment.default.APP.ALLIANCE_API_URL);
    }

    buildURL(modelName, id, snapshot, requestType, query) {
      let organizationId = this.session.get("data.orgId");

      switch (requestType) {
        case "findAll":
          return this.host + `/organizations/${organizationId}/groups`;

        case "createRecord":
          return this.host + `/organizations/${organizationId}/groups`;

        case "updateRecord":
          return this.host + `/organizations/${organizationId}/groups/${id}`;

        case "findRecord":
          return this.host + `/organizations/${organizationId}/groups/${id}`;

        case "deleteRecord":
          return this.host + `/organizations/${organizationId}/groups/${id}`;
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = CommunityGroupAdapter;
});