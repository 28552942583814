define("alliance-business-suite/templates/components/ui/form-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ctUAndFr",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"form-field overflow-float\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"checkbox-field\"],[12],[2,\"\\n    \"],[10,\"label\"],[15,\"for\",[31,[[34,0]]]],[14,0,\"form-field--label inline-checkbox no-select\"],[12],[2,\"\\n      \"],[8,\"input\",[[24,0,\"form-field--control\"]],[[\"@type\",\"@id\",\"@checked\"],[\"checkbox\",[34,0],[34,1]]],null],[2,\"\\n      \"],[1,[34,2]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"elementName\",\"checked\",\"labelText\"]}",
    "moduleName": "alliance-business-suite/templates/components/ui/form-checkbox.hbs"
  });

  _exports.default = _default;
});