define("alliance-business-suite/components/input/language-select", ["exports", "alliance-business-suite/utils/locale", "alliance-business-suite/utils/fetch-localizations"], function (_exports, _locale, _fetchLocalizations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    moment: Ember.inject.service(),
    store: Ember.inject.service(),
    access: Ember.inject.service(),
    classNames: ['language-select'],

    didInsertElement() {
      this._super(...arguments);

      let userId = this.access.user ? this.access.user.get('id') : null;

      if (userId) {
        this.setCurrentLocale(this);
      } else {
        let windowLocale = `${window.navigator.language}`;
        let browserLanguageMapped = _locale.browserLanguageMap[windowLocale.replace(/\-.*$/g, '')] || "en-us";
        let locale = localStorage.language ? `${localStorage.language}` : browserLanguageMapped;
        localStorage.setItem('language', locale);
        this.moment.setLocale(_locale.momentLocaleMap[locale]);
        console.log('select calling fetch localization....'); // fetchLocalizations.call(this, locale);
      }
    },

    newLocales: Ember.computed('currentLocale', function () {
      return [{
        id: 'cs-cz',
        name: 'Ceština',
        local: 'language-select.language.cs-cz'
      }, {
        id: 'de',
        name: 'Deutsch',
        local: 'language-select.language.de'
      }, {
        id: 'en-us',
        name: 'English',
        local: 'language-select.language.en-us'
      }, {
        id: 'es',
        name: 'Spanish',
        local: 'language-select.language.es'
      }, {
        id: 'fr',
        name: 'Français',
        local: 'language-select.language.fr'
      }, {
        id: 'id',
        name: 'Bahasa Indonesia',
        local: 'language-select.language.id'
      }, {
        id: 'ita',
        name: 'Italiano',
        local: 'language-select.language.ita'
      }, {
        id: 'jpn',
        name: '日本語',
        local: 'language-select.language.jpn'
      }, {
        id: 'ko',
        name: '한국어',
        local: 'language-select.language.ko'
      }, {
        id: 'ms',
        name: 'Bahasa Melayu',
        local: 'language-select.language.ms'
      }, {
        id: 'nl-nl',
        name: 'Nederlands',
        local: 'language-select.language.nl-nl'
      }, {
        id: 'pt',
        name: 'Português',
        local: 'language-select.language.pt'
      }, {
        id: 'ru',
        name: 'Pусский',
        local: 'language-select.language.ru'
      }, {
        id: 'th',
        name: 'ภาษาไทย',
        local: 'language-select.language.th'
      }, {
        id: 'zh-cn',
        name: '简体中文',
        local: 'language-select.language.zh-cn'
      }];
    }),

    setCurrentLocale(context) {
      let userId = context.access.user.get('id');
      let user = Ember.get(context, 'store').peekRecord('employee', userId);
      let userLocale = localStorage.language === user.get('locale') ? user.get('locale') : localStorage.language;
      let locale = this.intl.locale[0];
      locale = userLocale ? userLocale : locale;
      context.send('updateLocale', locale); // console.log('setCurrentLocale calling...')
      // fetchLocalizations.call(this, locale);
    },

    currentLocale: Ember.computed('access.user.id', 'intl.locale', 'newLocales', {
      get() {
        let userId = this.access.user ? this.access.user.get('id') : null;
        let windowLanguage = `${window.navigator.language}`;
        let browserLanguageMapped = _locale.browserLanguageMap[windowLanguage.replace(/\-.*$/g, '')] || "en-us";
        let windowLocale = [localStorage.language ? `${localStorage.language}` : browserLanguageMapped];
        let locale = userId ? this.intl.locale : windowLocale;
        return this.newLocales.findBy('id', locale[0]);
      },

      set(key, value) {
        let userId = this.access.user ? this.access.user.get('id') : null;
        localStorage.setItem('language', value.id);
        this.moment.setLocale(_locale.momentLocaleMap[value.id]);

        if (userId) {
          let user = this.store.peekRecord('employee', userId);
          Ember.set(user, 'locale', this.newLocales.findBy('id', value.id).id);
          this.send('saveLocale');
        }

        _fetchLocalizations.fetchLocalizations.call(this, value.id);

        return value;
      }

    }),
    actions: {
      updateLocale(locale) {
        let userId = this.access.user.get('id');
        let user = this.store.peekRecord('employee', userId);
        Ember.set(user, 'locale', locale);
      },

      saveLocale() {
        let userId = this.access.user.get('id');
        let user = this.store.peekRecord('employee', userId);
        user.save().then(response => {
          location.reload();
        });
      }

    }
  });

  _exports.default = _default;
});