define("alliance-business-suite/utils/ember-data", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.nestedSet = nestedSet;
  _exports.toPlainDataObject = toPlainDataObject;
  const {
    Model
  } = _emberData.default;
  /**
   * Converts a Model instance into a plain data object. To avoid triggering lazy
   * relationships, only attributes are converted.
   *
   * TODO: Add a second parameter that allows an array of relationships paths to
   *       convert to be passed in. These are processed in a nested fashion.
   *       [twl 25.May.17]
   *
   * @param {DS.Model} data An Ember Data record
   *
   * @return {object} A plain data object
   * @public
   */

  function toPlainDataObject(data) {
    if (data == null) {
      return data;
    }

    (true && !('The data parameter must be a model instance') && Ember.assert(data instanceof Model, 'The data parameter must be a model instance'));
    let result = {};
    Ember.get(data.constructor, 'attributes').forEach((_descriptor, name) => {
      result[name] = Ember.get(data, name);
    });
    return result;
  }

  var _default = {
    toPlainDataObject
  };
  /**
   * Sets the value on the property at the path underneath the object, creating
   * the intermediate objects if they don't already exist.
   *
   * @public
   */

  _exports.default = _default;

  function nestedSet(object, property, value) {
    let components = property.split('.');

    do {
      let component = components.shift();

      if (components.length > 0) {
        if (!isNaN(component)) {
          component = parseInt(component);

          for (let i = 0; i <= component; i++) {
            if (object[i] == null) {
              object[i] = {};
            }
          }

          object = object[component];
        } else {
          if (Ember.get(object, component) == null) {
            if (isNaN(components[0])) {
              Ember.set(object, component, {});
            } else {
              Ember.set(object, component, []);
            }
          }

          object = Ember.get(object, component);
        }
      } else {
        Ember.set(object, component, value);
      }
    } while (components.length > 0);

    return value;
  }
});