define("alliance-business-suite/controllers/crm/campaigns/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    access: Ember.inject.service(),
    ajax: Ember.inject.service(),

    init() {
      this._super(...arguments);
    },

    actions: {
      createCampaign() {
        let _this = this;

        let rooms = [];
        let deliveryMethodMessages = [];
        let newCampaign = this.model;
        let deliveryMessages;
        let deliveryMethodLookupKey = newCampaign.get('deliveryMethodLookupKeys');
        let name = newCampaign.get('name');
        let campaignTypeLookupKey = newCampaign.get('campaignTypeLookupKey');
        let filter = newCampaign.get('filter');
        let organizationId = this.get('access.accessOrganization.id');
        let promo = this.promo;
        let shouldAlert = false;

        if (!newCampaign.get('name')) {
          alert('Please enter a name for this campaign');
          return;
        } else if (!newCampaign.get('rooms.length')) {
          alert('Please select a location for this campaign');
          return;
        } else if (newCampaign.get('campaignTypeLookupKey') == 'REENGAGEMENT' && (!newCampaign.get('filter') || !JSON.parse(newCampaign.get('filter')).Params.length)) {
          alert('Please select a filter for this campaign');
          return;
        } else if (newCampaign.get('campaignTypeLookupKey') == 'REENGAGEMENT' && deliveryMethodLookupKey.length === 0) {
          alert('Please select a message for this campaign');
          return;
        } else if (JSON.parse(filter).Params.length) {
          let filterParams = JSON.parse(filter).Params;
          filterParams.forEach(function (filterParam) {
            if (filterParam.Name === "LAST_VISIT_END_DATE") {
              if (filterParam.Value === "Invalid date") {
                shouldAlert = true;
              }
            }

            if (filterParam.Name === "LAST_VISIT_START_DATE") {
              if (filterParam.Value === "Invalid date") {
                shouldAlert = true;
              }
            }

            if (filterParam.Name === "LAST_VISIT_INTERVAL") {
              if (filterParam.Value === "custom") {
                shouldAlert = true;
              }
            }
          });

          if (shouldAlert) {
            alert('Please select a valid date');
            return;
          }
        } else if (newCampaign.get('campaignTypeLookupKey') == 'REENGAGEMENT' && deliveryMethodLookupKey.length > 0) {
          this.set('isEmpty', false);
          deliveryMethodLookupKey.forEach(MethodLookupKey => {
            if (MethodLookupKey === 'SMS' && newCampaign.get('deliveryMethodBodyTextSMS') === undefined) {
              this.set('isEmpty', true);
            }

            if (MethodLookupKey === 'EMAIL_HTML' && newCampaign.get('deliveryMethodBodyTextHtml') === undefined) {
              this.set('isEmpty', true);
            }

            if (MethodLookupKey === 'IN_APP_NOTIFICATION' && newCampaign.get('deliveryMethodBodyTextInApp') === undefined) {
              this.set('isEmpty', true);
            }

            if (MethodLookupKey === 'EMAIL_TEXT' && newCampaign.get('deliveryMethodBodyTextPlain') === undefined) {
              this.set('isEmpty', true);
            }
          });

          if (this.isEmpty === true) {
            alert('Please fill out a message for this campaign');
            return;
          }
        }

        this.set('isWorking', true);

        if (newCampaign.get('rooms.length')) {
          deliveryMessages = newCampaign.get('rooms');
          deliveryMessages.forEach(function (deliveryMessage) {
            rooms.push({
              'id': deliveryMessage.get('id')
            });
          });
        }

        if (campaignTypeLookupKey === 'REENGAGEMENT') {
          deliveryMethodLookupKey.forEach(MethodLookupKey => {
            if (MethodLookupKey === "SMS") {
              deliveryMethodMessages.push({
                "id": 1,
                "deliveryMethodTypeLookupKey": MethodLookupKey,
                "deliveryMethodBodyText": `${newCampaign.get('deliveryMethodBodyTextSMS')}`
              });
            }

            if (MethodLookupKey === "EMAIL_HTML") {
              deliveryMethodMessages.push({
                "id": 2,
                "deliveryMethodTypeLookupKey": MethodLookupKey,
                "deliveryMethodBodyText": `${newCampaign.get('deliveryMethodBodyTextHtml')}`,
                "deliveryMethodFromName": `${newCampaign.get('deliveryMethodFromNameHtml')}`,
                "deliveryMethodFromEmail": `${newCampaign.get('deliveryMethodFromEmailHtml')}`,
                "deliveryMethodReplyEmail": `${newCampaign.get('deliveryMethodReplyEmailHtml')}`,
                "deliveryMethodSubjectText": `${newCampaign.get('deliveryMethodSubjectTextHtml')}`
              });
            }

            if (MethodLookupKey === "IN_APP_NOTIFICATION") {
              deliveryMethodMessages.push({
                "id": 3,
                "deliveryMethodTypeLookupKey": MethodLookupKey,
                "deliveryMethodBodyText": `${newCampaign.get('deliveryMethodBodyTextInApp')}`
              });
            }

            if (MethodLookupKey === "EMAIL_TEXT") {
              deliveryMethodMessages.push({
                "id": 4,
                "deliveryMethodTypeLookupKey": MethodLookupKey,
                "deliveryMethodBodyText": `${newCampaign.get('deliveryMethodBodyTextPlain')}`,
                "deliveryMethodFromName": `${newCampaign.get('deliveryMethodFromNameText')}`,
                "deliveryMethodFromEmail": `${newCampaign.get('deliveryMethodFromEmailText')}`,
                "deliveryMethodReplyEmail": `${newCampaign.get('deliveryMethodReplyEmailText')}`,
                "deliveryMethodSubjectText": `${newCampaign.get('deliveryMethodSubjectTextPlain')}`
              });
            }

            if (MethodLookupKey === "NONE") {
              deliveryMethodMessages.push({
                "id": 5,
                "deliveryMethodTypeLookupKey": MethodLookupKey
              });
            }
          });
        } else {
          deliveryMethodLookupKey = ["NONE"];
          deliveryMethodMessages = {
            "id": 5,
            "deliveryMethodTypeLookupKey": "NONE"
          };
        }

        this.ajax.request(`/organizations/${organizationId}/campaigns`, {
          data: {
            name,
            rooms,
            filter,
            deliveryMethodLookupKey,
            deliveryMethodMessages,
            campaignTypeLookupKey,
            raw: true
          },
          method: 'POST'
        }).then(response => {
          if (promo) {
            promo.save({
              adapterOptions: {
                campaignId: response.id
              }
            }).then(function () {
              _this.set('hasUnsavedChanges', false);

              _this.get('router').transitionTo('crm.campaigns');

              _this.set('isWorking', false);
            }, function () {
              alert('Unable to create campaign promo!');

              _this.set('hasUnsavedChanges', false);

              _this.get('router').transitionTo('crm.campaigns');

              _this.set('isWorking', false);
            });
          } else {
            _this.set('hasUnsavedChanges', false);

            _this.get('router').transitionTo('crm.campaigns');

            _this.set('isWorking', false);
          }
        }, function () {
          _this.set('isWorking', false);
        });
      },

      hasUnsavedChanges(value) {
        this.set('hasUnsavedChanges', value);
      }

    }
  });

  _exports.default = _default;
});