define("alliance-business-suite/mixins/machines/programs/midas-property-names", ["exports", "alliance-business-suite/helpers/find-by-dropdown-with-custom"], function (_exports, _findByDropdownWithCustom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    // eslint-disable-line
    intl: Ember.inject.service(),
    _settings: Ember.computed.or('object.settings', 'machineProgram.settings', 'object'),
    bundle1Name: Ember.computed('_settings.{Messages.Bundle1Name,Program.Price.ModifierOptions.CycleModifierName_WX}', function () {
      // Deluxe/Ultra = 0, Medium/Heavy = 1, Custom/Custom = 2. These values are also in CycleNames in midas-options.js.
      let names = [this.intl.t('deluxe'), this.intl.t('medium'), this._settings.Messages.Bundle1Name];
      return names[this._settings.Program.Price.ModifierOptions.CycleModifierName_WX];
    }),
    bundle2Name: Ember.computed('_settings.{Messages.Bundle2Name,Program.Price.ModifierOptions.CycleModifierName_WX}', function () {
      // Deluxe/Ultra = 0, Medium/Heavy = 1, Custom/Custom = 2. These values are also in CycleNames in midas-options.js.
      let names = [this.intl.t('ultra'), this.intl.t('heavy'), this._settings.Messages.Bundle2Name];
      return names[this._settings.Program.Price.ModifierOptions.CycleModifierName_WX];
    }),
    modifier1Name: Ember.computed('_settings.Messages.Modifier1Name', function () {
      return this._settings.Messages.Modifier1Name || this.intl.t('prewash');
    }),
    modifier2Name: Ember.computed('_settings.Messages.Modifier2Name', function () {
      return this._settings.Messages.Modifier2Name || this.intl.t('extra_rinse');
    }),
    watchForUpdates: 1,

    didInsertElement() {
      this._super(...arguments);

      this.alignUiElements();
    },

    // numberFormatting: computed('machineProgram.settings.Program.Display.NumericSeparator', function() {
    globalSettings: Ember.computed('coinValue', 'currencySymbol', 'currencySymbolPosition', 'isCelsius', 'machineProgram.settings.Program.Display.NumericSeparator', 'selectOptions.NumericSeparator', 'showG', function () {
      let value = this.machineProgram.settings.Program.Display.NumericSeparator;
      let option = this.selectOptions.NumericSeparator.findBy('val', value);
      let thousandsSymbol;
      let decimalSymbol = option && option.decimalSymbol ? option.decimalSymbol : ".";
      if (decimalSymbol == "") thousandsSymbol = "";
      if (decimalSymbol == ".") thousandsSymbol = ",";
      if (decimalSymbol == ",") thousandsSymbol = ".";
      let currencySymbol = this.currencySymbol; //get(this, 'currencySymbol'),

      let currencySymbolPosition = this.currencySymbolPosition; //get(this, 'currencySymbolPosition'),

      let decimals = option && option.decimalPlaces ? option.decimalPlaces : 0;
      let divideBy = option && option.divideBy ? option.divideBy : 1;
      let output = {
        decimals,
        divideBy,
        decimalSymbol,
        thousandsSymbol,
        currencySymbol,
        currencySymbolPosition,
        isCelsius: this.isCelsius,
        coinValue: this.coinValue,
        showG: this.showG
      };
      return output;
    }),
    showG: Ember.computed.alias('machineProgram.settings.Program.Display.DisplayExtractSpeedInGForce_WX'),
    // isCelsius: computed('machineProgram.settings.Program.Misc.MiscConfig.CelsiusEnabled', function() {
    //   return this.machineProgram.settings.Program.Misc.MiscConfig.CelsiusEnabled;
    // }),
    isCelsius: Ember.computed.reads('machineProgram.settings.Program.Misc.MiscConfig.CelsiusEnabled'),
    currencySymbol: Ember.computed('machineProgram.settings.Program.Price.Misc.Currency1.Symbol', 'selectOptions.CurrencyType', function () {
      let option = (0, _findByDropdownWithCustom.findByDropDown)([this.selectOptions.CurrencyType, "val", this.machineProgram.settings.Program.Price.Misc.Currency1.Symbol]);
      return option && option.abbr ? option.abbr : "";
    }),
    coinValue: Ember.computed.alias('machineProgram.settings.Program.CoinPulse.Coin1Config.Value'),
    currencySymbolPosition: Ember.computed('machineProgram.settings.Program.Price.Misc.Currency1.PlaceAfter', function () {
      return this.machineProgram.settings.Program.Price.Misc.Currency1.PlaceAfter === true ? "after" : "before";
    }),
    propertyNames: Ember.computed(function () {
      return {
        steps: {},
        temperatureOverride: {
          WashCold: 'Program.Cycle.WashColdTempOverride_WX',
          WashWarm: 'Program.Cycle.WashWarmTempOverride_WX',
          WashHot: 'Program.Cycle.WashHotTempOverride_WX',
          WashNoHeat: 'Program.Cycle.WashNoHeatTempOverride_WX',
          Wash15C: 'Program.Cycle.Wash15CTempOverride_WX',
          Wash30C: 'Program.Cycle.Wash30CTempOverride_WX',
          Wash40C: 'Program.Cycle.Wash40CTempOverride_WX',
          Wash50C: 'Program.Cycle.Wash50CTempOverride_WX',
          Wash60C: 'Program.Cycle.Wash60CTempOverride_WX',
          Wash90C: 'Program.Cycle.Wash90CTempOverride_WX',
          PrewashCold: 'Program.Cycle.PrewashColdTempOverride_WX',
          PrewashWarm: 'Program.Cycle.PrewashWarmTempOverride_WX',
          PrewashHot: 'Program.Cycle.PrewashHotTempOverride_WX',
          PrewashNoHeat: 'Program.Cycle.PrewashNoHeatTempOverride_WX',
          Prewash15C: 'Program.Cycle.Prewash15CTempOverride_WX',
          Prewash30C: 'Program.Cycle.Prewash30CTempOverride_WX',
          Prewash40C: 'Program.Cycle.Prewash40CTempOverride_WX',
          Prewash50C: 'Program.Cycle.Prewash50CTempOverride_WX',
          Prewash60C: 'Program.Cycle.Prewash60CTempOverride_WX',
          Prewash90C: 'Program.Cycle.Prewash90CTempOverride_WX'
        },
        specialVend_TUM: {
          Enabled: 'SpecialVend.Items.${index}.DOWEn.Enabled',
          Friday: 'SpecialVend.Items.${index}.DOWEn.Friday',
          Monday: 'SpecialVend.Items.${index}.DOWEn.Monday',
          Sunday: 'SpecialVend.Items.${index}.DOWEn.Sunday',
          Tuesday: 'SpecialVend.Items.${index}.DOWEn.Tuesday',
          Saturday: 'SpecialVend.Items.${index}.DOWEn.Saturday',
          Thursday: 'SpecialVend.Items.${index}.DOWEn.Thursday',
          Wednesday: 'SpecialVend.Items.${index}.DOWEn.Wednesday',
          EndYear: 'SpecialVend.Items.${index}.EndYear',
          EndMonth: 'SpecialVend.Items.${index}.EndMonth',
          EndDate: 'SpecialVend.Items.${index}.EndDate',
          StartYear: 'SpecialVend.Items.${index}.StartYear',
          StartMonth: 'SpecialVend.Items.${index}.StartMonth',
          StartDate: 'SpecialVend.Items.${index}.StartDate',
          StartHour: 'SpecialVend.Items.${index}.StartHour',
          StartMinute: 'SpecialVend.Items.${index}.StartMinute',
          Duration: 'SpecialVend.Items.${index}.Duration',
          DefaultAntiWrinkle: 'SpecialVend.Items.${index}.DefaultModifiers_TUM.AntiWrinkle_TUM',
          DefaultReversing: 'SpecialVend.Items.${index}.DefaultModifiers_TUM.Reversing_TUM',
          AntiWrinkle: 'SpecialVend.Items.${index}.ModsAdditionalOptsEnabled_TUM.AntiWrinkle_TUM',
          Reversing: 'SpecialVend.Items.${index}.ModsAdditionalOptsEnabled_TUM.Reversing_TUM',
          HeatPrice: 'SpecialVend.Items.${index}.HeatPrice_TUM',
          NoHeatPrice: 'SpecialVend.Items.${index}.NoHeatPrice_TUM',
          PaymentSystemTopOffPrice: 'SpecialVend.Items.${index}.PaymentSystemTopOffPrice_TUM',
          RemoteTopOffPrice: 'SpecialVend.Items.${index}.RemoteTopOffPrice_TUM',
          AntiWrinkleAdder: 'SpecialVend.Items.${index}.ModifierPrices_TUM.AntiWrinkleAdder_TUM',
          ReversingAdder: 'SpecialVend.Items.${index}.ModifierPrices_TUM.ReversingAdder_TUM',
          HeatMinutes: 'SpecialVend.Items.${index}.HeatMinutes_TUM',
          HeatSeconds: 'SpecialVend.Items.${index}.HeatSeconds_TUM',
          NoHeatMinutes: 'SpecialVend.Items.${index}.NoHeatMinutes_TUM',
          NoHeatSeconds: 'SpecialVend.Items.${index}.NoHeatSeconds_TUM',
          Coin1TopOffMinutes: 'SpecialVend.Items.${index}.Coin1TopOffMinutes_TUM',
          Coin1TopOffSeconds: 'SpecialVend.Items.${index}.Coin1TopOffSeconds_TUM',
          Coin2TopOffMinutes: 'SpecialVend.Items.${index}.Coin2TopOffMinutes_TUM',
          Coin2TopOffSeconds: 'SpecialVend.Items.${index}.Coin2TopOffSeconds_TUM',
          PaymentSystemTopOffMinutes: 'SpecialVend.Items.${index}.PaymentSystemTopOffMinutes_TUM',
          PaymentSystemTopOffSeconds: 'SpecialVend.Items.${index}.PaymentSystemTopOffSeconds_TUM',
          RemoteTopOffMinutes: 'SpecialVend.Items.${index}.RemoteTopOffMinutes_TUM',
          RemoteTopOffSeconds: 'SpecialVend.Items.${index}.RemoteTopOffSeconds_TUM'
        },
        specialVend_WX: {
          Enabled: 'SpecialVend.Items.${index}.DOWEn.Enabled',
          Friday: 'SpecialVend.Items.${index}.DOWEn.Friday',
          Monday: 'SpecialVend.Items.${index}.DOWEn.Monday',
          Sunday: 'SpecialVend.Items.${index}.DOWEn.Sunday',
          Tuesday: 'SpecialVend.Items.${index}.DOWEn.Tuesday',
          Saturday: 'SpecialVend.Items.${index}.DOWEn.Saturday',
          Thursday: 'SpecialVend.Items.${index}.DOWEn.Thursday',
          Wednesday: 'SpecialVend.Items.${index}.DOWEn.Wednesday',
          EndYear: 'SpecialVend.Items.${index}.EndYear',
          EndMonth: 'SpecialVend.Items.${index}.EndMonth',
          EndDate: 'SpecialVend.Items.${index}.EndDate',
          StartYear: 'SpecialVend.Items.${index}.StartYear',
          StartMonth: 'SpecialVend.Items.${index}.StartMonth',
          StartDate: 'SpecialVend.Items.${index}.StartDate',
          StartHour: 'SpecialVend.Items.${index}.StartHour',
          StartMinute: 'SpecialVend.Items.${index}.StartMinute',
          Duration: 'SpecialVend.Items.${index}.Duration',
          CyclePrices: 'SpecialVend.Items.${index}.CyclePrices_WX',
          CyclePrices2: 'SpecialVend.ExpandedPrices.${index}.CyclePrices_WX',
          HotAdder: 'SpecialVend.Items.${index}.ModifierPrices_WX.HotAdder_WX',
          Mod1Adder: 'SpecialVend.Items.${index}.ModifierPrices_WX.Mod1Adder_WX',
          Mod2Adder: 'SpecialVend.Items.${index}.ModifierPrices_WX.Mod2Adder_WX',
          WarmAdder: 'SpecialVend.Items.${index}.ModifierPrices_WX.WarmAdder_WX',
          OzoneAdder: 'SpecialVend.Items.${index}.ModifierPrices_WX.OzoneAdder_WX',
          Temp15CAdder: 'SpecialVend.Items.${index}.ModifierPrices_WX.Temp15CAdder_WX',
          Temp30CAdder: 'SpecialVend.Items.${index}.ModifierPrices_WX.Temp30CAdder_WX',
          Temp40CAdder: 'SpecialVend.Items.${index}.ModifierPrices_WX.Temp40CAdder_WX',
          Temp50CAdder: 'SpecialVend.Items.${index}.ModifierPrices_WX.Temp50CAdder_WX',
          Temp60CAdder: 'SpecialVend.Items.${index}.ModifierPrices_WX.Temp60CAdder_WX',
          Temp90CAdder: 'SpecialVend.Items.${index}.ModifierPrices_WX.Temp90CAdder_WX',
          ExtraTimeAdder: 'SpecialVend.Items.${index}.ModifierPrices_WX.ExtraTimeAdder_WX',
          WarmRinseAdder: 'SpecialVend.Items.${index}.ModifierPrices_WX.WarmRinseAdder_WX',
          SpeedCycleAdder: 'SpecialVend.Items.${index}.ModifierPrices_WX.SpeedCycleAdder_WX',
          HeavyBundleAdder: 'SpecialVend.Items.${index}.ModifierPrices_WX.HeavyBundleAdder_WX',
          MediumBundleAdder: 'SpecialVend.Items.${index}.ModifierPrices_WX.MediumBundleAdder_WX',
          SupplyInjectionAdder: 'SpecialVend.Items.${index}.ModifierPrices_WX.SupplyInjectionAdder_WX',
          IncreasedSpinSpeedAdder: 'SpecialVend.Items.${index}.ModifierPrices_WX.IncreasedSpinSpeedAdder_WX',
          IncreasedWaterLevelAdder: 'SpecialVend.Items.${index}.ModifierPrices_WX.IncreasedWaterLevelAdder_WX',
          DefaultMod1: 'SpecialVend.Items.${index}.DefaultModifiers_WX.Mod1_WX',
          DefaultMod2: 'SpecialVend.Items.${index}.DefaultModifiers_WX.Mod2_WX',
          DefaultOzone: 'SpecialVend.Items.${index}.DefaultModifiers_WX.Ozone_WX',
          DefaultExtraTime: 'SpecialVend.Items.${index}.DefaultModifiers_WX.ExtraTime_WX',
          DefaultSpeedCycle: 'SpecialVend.Items.${index}.DefaultModifiers_WX.SpeedCycle_WX',
          DefaultHeavyBundle: 'SpecialVend.Items.${index}.DefaultModifiers_WX.HeavyBundle_WX',
          DefaultDispenseSoap: 'SpecialVend.Items.${index}.DefaultModifiers_WX.DispenseSoap_WX',
          DefaultMediumBundle: 'SpecialVend.Items.${index}.DefaultModifiers_WX.MediumBundle_WX',
          DefaultWarmFinalRinse: 'SpecialVend.Items.${index}.DefaultModifiers_WX.WarmFinalRinse_WX',
          DefaultIncreasedSpinSpeed: 'SpecialVend.Items.${index}.DefaultModifiers_WX.IncreasedSpinSpeed_WX',
          DefaultIncreasedWaterLevel: 'SpecialVend.Items.${index}.DefaultModifiers_WX.IncreasedWaterLevel_WX',
          ExtractSpeedLimit: 'SpecialVend.Items.${index}.ExtractSpeedLimit_WX',
          HeavyMod1: 'SpecialVend.Items.${index}.HeavyBundleOptions_WX.Mod1',
          HeavyMod2: 'SpecialVend.Items.${index}.HeavyBundleOptions_WX.Mod2',
          HeavyExtraTime: 'SpecialVend.Items.${index}.HeavyBundleOptions_WX.ExtraTime',
          HeavySpinSpeed: 'SpecialVend.Items.${index}.HeavyBundleOptions_WX.SpinSpeed',
          HeavyWarmRinse: 'SpecialVend.Items.${index}.HeavyBundleOptions_WX.WarmRinse',
          HeavyWaterLevel: 'SpecialVend.Items.${index}.HeavyBundleOptions_WX.WaterLevel',
          MediumMod1: 'SpecialVend.Items.${index}.MediumBundleOptions_WX.Mod1',
          MediumMod2: 'SpecialVend.Items.${index}.MediumBundleOptions_WX.Mod2',
          MediumExtraTime: 'SpecialVend.Items.${index}.MediumBundleOptions_WX.ExtraTime',
          MediumSpinSpeed: 'SpecialVend.Items.${index}.MediumBundleOptions_WX.SpinSpeed',
          MediumWarmRinse: 'SpecialVend.Items.${index}.MediumBundleOptions_WX.WarmRinse',
          MediumWaterLevel: 'SpecialVend.Items.${index}.MediumBundleOptions_WX.WaterLevel',
          OptionsMod1: 'SpecialVend.Items.${index}.ModsAdditionalOptsEnabled_WX.Mod1_WX',
          OptionsMod2: 'SpecialVend.Items.${index}.ModsAdditionalOptsEnabled_WX.Mod2_WX',
          OptionsOzone: 'SpecialVend.Items.${index}.ModsAdditionalOptsEnabled_WX.Ozone_WX',
          OptionsExtraTime: 'SpecialVend.Items.${index}.ModsAdditionalOptsEnabled_WX.ExtraTime_WX',
          OptionsSpeedCycle: 'SpecialVend.Items.${index}.ModsAdditionalOptsEnabled_WX.SpeedCycle_WX',
          OptionsHeavyBundle: 'SpecialVend.Items.${index}.ModsAdditionalOptsEnabled_WX.HeavyBundle_WX',
          OptionsDispenseSoap: 'SpecialVend.Items.${index}.ModsAdditionalOptsEnabled_WX.DispenseSoap_WX',
          OptionsMediumBundle: 'SpecialVend.Items.${index}.ModsAdditionalOptsEnabled_WX.MediumBundle_WX',
          OptionsWarmFinalRinse: 'SpecialVend.Items.${index}.ModsAdditionalOptsEnabled_WX.WarmFinalRinse_WX',
          OptionsIncreasedSpinSpeed: 'SpecialVend.Items.${index}.ModsAdditionalOptsEnabled_WX.IncreasedSpinSpeed_WX',
          OptionsIncreasedWaterLevel: 'SpecialVend.Items.${index}.ModsAdditionalOptsEnabled_WX.IncreasedWaterLevel_WX',
          WashAgitateMinuteSubtracter: 'SpecialVend.Items.${index}.WashAgitateMinuteSubtracter_WX',
          WashAgitateMinutesAdder: 'SpecialVend.Items.${index}.WashAgitateMinutesAdder_WX'
        },
        daylightSaving: {
          enabled: "Program.Misc.DaylightSavings.Enabled",
          startMonth: "Program.Misc.DaylightSavings.StartMonth",
          startWeek: "Program.Misc.DaylightSavings.StartWOM",
          startDay: "Program.Misc.DaylightSavings.StartDOW",
          startHour: "Program.Misc.DaylightSavings.StartHour",
          endMonth: "Program.Misc.DaylightSavings.EndMonth",
          endWeek: "Program.Misc.DaylightSavings.EndWOM",
          endDay: "Program.Misc.DaylightSavings.EndDOW",
          endHour: "Program.Misc.DaylightSavings.EndHour"
        },
        autoFlush: {
          Enabled: "Program.Cycle.AutoFlushDOW_WX.Enabled",
          Sunday: "Program.Cycle.AutoFlushDOW_WX.Sunday",
          Monday: "Program.Cycle.AutoFlushDOW_WX.Monday",
          Tuesday: "Program.Cycle.AutoFlushDOW_WX.Tuesday",
          Wednesday: "Program.Cycle.AutoFlushDOW_WX.Wednesday",
          Thursday: "Program.Cycle.AutoFlushDOW_WX.Thursday",
          Friday: "Program.Cycle.AutoFlushDOW_WX.Friday",
          Saturday: "Program.Cycle.AutoFlushDOW_WX.Saturday",
          NumberCycles: "Program.Cycle.AutoFlushCyclesBetween_WX",
          StartHour: "Program.Cycle.AutoFlushHour_WX",
          Duration: "Program.Cycle.AutoFlushDurationSeconds_WX"
        },
        leakDetection: {
          Enabled: "Program.Cycle.InCycleLeakDetectDOW_WX.Enabled",
          Sunday: "Program.Cycle.InCycleLeakDetectDOW_WX.Sunday",
          Monday: "Program.Cycle.InCycleLeakDetectDOW_WX.Monday",
          Tuesday: "Program.Cycle.InCycleLeakDetectDOW_WX.Tuesday",
          Wednesday: "Program.Cycle.InCycleLeakDetectDOW_WX.Wednesday",
          Thursday: "Program.Cycle.InCycleLeakDetectDOW_WX.Thursday",
          Friday: "Program.Cycle.InCycleLeakDetectDOW_WX.Friday",
          Saturday: "Program.Cycle.InCycleLeakDetectDOW_WX.Saturday",
          NumberCycles: "Program.Cycle.CyclesBetweenLeakDetect_WX",
          Display: "Program.Cycle.InCycleLeakDetectEnabled_WX"
        },
        luckyCycle: {
          Friday: "Program.Misc.LuckyCycle.DOWEn.Friday",
          Monday: "Program.Misc.LuckyCycle.DOWEn.Monday",
          Sunday: "Program.Misc.LuckyCycle.DOWEn.Sunday",
          Enabled: "Program.Misc.LuckyCycle.DOWEn.Enabled",
          Tuesday: "Program.Misc.LuckyCycle.DOWEn.Tuesday",
          Saturday: "Program.Misc.LuckyCycle.DOWEn.Saturday",
          Thursday: "Program.Misc.LuckyCycle.DOWEn.Thursday",
          Wednesday: "Program.Misc.LuckyCycle.DOWEn.Wednesday",
          EndYear: "Program.Misc.LuckyCycle.EndYear",
          EndMonth: "Program.Misc.LuckyCycle.EndMonth",
          EndDate: "Program.Misc.LuckyCycle.EndDate",
          StartYear: "Program.Misc.LuckyCycle.StartYear",
          StartMonth: "Program.Misc.LuckyCycle.StartMonth",
          StartDate: "Program.Misc.LuckyCycle.StartDate",
          StartHour: "Program.Misc.LuckyCycle.StartHour",
          StartMinute: "Program.Misc.LuckyCycle.StartMinute",
          Duration: "Program.Misc.LuckyCycle.Duration",
          CyclesBetween: "Program.Misc.LuckyCycle.CyclesBetween",
          // DisplayEnabled: "Program.Misc.LuckyCycle.DisplayEnabled",
          DisplayEnabled: "Program.Misc.LuckyCycle.DisplayAfterInput" // TODO: Make sure this is correct

        },
        luckyCycleWX: {
          Friday: "Program.Misc.LuckyCycle.DOWEn.Friday",
          Monday: "Program.Misc.LuckyCycle.DOWEn.Monday",
          Sunday: "Program.Misc.LuckyCycle.DOWEn.Sunday",
          Enabled: "Program.Misc.LuckyCycle.DOWEn.Enabled",
          Tuesday: "Program.Misc.LuckyCycle.DOWEn.Tuesday",
          Saturday: "Program.Misc.LuckyCycle.DOWEn.Saturday",
          Thursday: "Program.Misc.LuckyCycle.DOWEn.Thursday",
          Wednesday: "Program.Misc.LuckyCycle.DOWEn.Wednesday",
          EndYear: "Program.Misc.LuckyCycle.EndYear",
          EndMonth: "Program.Misc.LuckyCycle.EndMonth",
          EndDate: "Program.Misc.LuckyCycle.EndDate",
          StartYear: "Program.Misc.LuckyCycle.StartYear",
          StartMonth: "Program.Misc.LuckyCycle.StartMonth",
          StartDate: "Program.Misc.LuckyCycle.StartDate",
          StartHour: "Program.Misc.LuckyCycle.StartHour",
          StartMinute: "Program.Misc.LuckyCycle.StartMinute",
          Duration: "Program.Misc.LuckyCycle.Duration",
          CyclesBetween: "Program.Misc.LuckyCycle.CyclesBetween",
          DisplayEnabled: "Program.Misc.LuckyCycle.DisplayAfterInput"
        },
        lowPower: {
          Enabled: 'Program.Misc.LowPowerShutdown.${index}.DOWEn.Enabled',
          Friday: 'Program.Misc.LowPowerShutdown.${index}.DOWEn.Friday',
          Monday: 'Program.Misc.LowPowerShutdown.${index}.DOWEn.Monday',
          Sunday: 'Program.Misc.LowPowerShutdown.${index}.DOWEn.Sunday',
          Tuesday: 'Program.Misc.LowPowerShutdown.${index}.DOWEn.Tuesday',
          Saturday: 'Program.Misc.LowPowerShutdown.${index}.DOWEn.Saturday',
          Thursday: 'Program.Misc.LowPowerShutdown.${index}.DOWEn.Thursday',
          Wednesday: 'Program.Misc.LowPowerShutdown.${index}.DOWEn.Wednesday',
          EndYear: 'Program.Misc.LowPowerShutdown.${index}.EndYear',
          EndMonth: 'Program.Misc.LowPowerShutdown.${index}.EndMonth',
          EndDate: 'Program.Misc.LowPowerShutdown.${index}.EndDate',
          StartYear: 'Program.Misc.LowPowerShutdown.${index}.StartYear',
          StartMonth: 'Program.Misc.LowPowerShutdown.${index}.StartMonth',
          StartDate: 'Program.Misc.LowPowerShutdown.${index}.StartDate',
          StartHour: 'Program.Misc.LowPowerShutdown.${index}.StartHour',
          StartMinute: 'Program.Misc.LowPowerShutdown.${index}.StartMinute',
          Duration: 'Program.Misc.LowPowerShutdown.${index}.Duration',
          LowPowerEnabled: 'Program.Misc.LowPowerShutdown.${index}.LowPowerEnabled',
          AutoShutdownEnabled: 'Program.Misc.LowPowerShutdown.${index}.AutoShutdownEnabled'
        },
        worldDrop: {
          WorldDrop1CoinsAccepted: "Program.CoinPulse.WorldDrop1CoinsAccepted",
          WorldDropValues: "Program.CoinPulse.WorldDropValues"
        },
        coinDrop: {
          SingleDrop: 'Program.Price.Misc.PaymentMethods.SingleDrop',
          DualDrop: 'Program.Price.Misc.PaymentMethods.DualDrop',
          WorldDrop: 'Program.Price.Misc.PaymentMethods.WorldDrop'
        },
        defaultModifier: {
          reversing: 'Program.Price.ModifierOptions.DefaultModifiers.Reversing_TUM',
          antiWrinkle: 'Program.Price.ModifierOptions.DefaultModifiers.AntiWrinkle_TUM'
        },
        defaultBundle: {
          defaultMedium: 'Program.Price.ModifierOptions.DefaultModifiers.MediumBundle_WX',
          defaultHeavy: 'Program.Price.ModifierOptions.DefaultModifiers.HeavyBundle_WX',
          optionMedium: 'Program.Price.ModifierOptions.ModsAdditionalOptsEnabled.MediumBundle_WX',
          optionHeavy: 'Program.Price.ModifierOptions.ModsAdditionalOptsEnabled.HeavyBundle_WX'
        },
        coinStartPulses: {
          StartPulseMode: "Program.CoinPulse.StartPulseMode",
          StartPulseMinWidth: "Program.CoinPulse.StartPulseMinWidth",
          StartPulseMaxWidth: "Program.CoinPulse.StartPulseMaxWidth",
          StartPulseMinTimeBetween: "Program.CoinPulse.StartPulseMinTimeBetween",
          StartPulseValue: "Program.CoinPulse.StartPulseValue",
          CoinPulseWidthMin: "Program.CoinPulse.CoinPulseWidthMin",
          CoinPulseWidthMax: "Program.CoinPulse.CoinPulseWidthMax",
          CoinPulseMinTimeBetween: "Program.CoinPulse.CoinPulseMinTimeBetween"
        },
        powerFailReset: {
          PowerFailResetEnabled: "Program.Misc.MiscConfig.PowerFailResetEnabled",
          PowerFailResetTime: "Program.Misc.MiscConfig.PowerFailResetTime"
        },
        topOff: {
          TopoffEnableType: "Program.Cycle.TopoffEnableType_TUM",
          RemoteTopoffPrice: "Program.Price.Misc.RemoteTopoffPrice_TUM",
          PSTopoffPrice: "Program.Price.Misc.PSTopoffPrice_TUM",
          TopoffTimeAdjustEnabled: "Program.Cycle.TopoffTimeAdjustEnabled_TUM"
        },
        breakinAlarm: {
          Passkey0: 'Program.Misc.Breakin.Passkey.0',
          Passkey1: 'Program.Misc.Breakin.Passkey.1',
          Passkey2: 'Program.Misc.Breakin.Passkey.2',
          Passkey3: 'Program.Misc.Breakin.Passkey.3',
          BreakInAlarmEnabled: 'Program.Misc.Breakin.BreakInAlarmEnabled',
          ServiceDoorOpenAlarmEnabled: 'Program.Misc.Breakin.ServiceDoorOpenAlarmEnabled',
          ServiceDoorShutDownEnabled: 'Program.Misc.Breakin.ServiceDoorShutDownEnabled',
          CoinVaultOpenAlarmEnabled: 'Program.Misc.Breakin.CoinVaultOpenAlarmEnabled',
          CoinVaultShutDownEnabled: 'Program.Misc.Breakin.CoinVaultShutDownEnabled'
        },
        manualMode: {
          Passkey0: 'Program.Misc.SystemMenu.ManualAccessPasskey.0',
          Passkey1: 'Program.Misc.SystemMenu.ManualAccessPasskey.1',
          Passkey2: 'Program.Misc.SystemMenu.ManualAccessPasskey.2',
          Passkey3: 'Program.Misc.SystemMenu.ManualAccessPasskey.3',
          ManualDiagnosticsEnabled: 'Program.Misc.SystemMenu.ManualDiagnosticsEnabled',
          ManualProgrammingEnabled: 'Program.Misc.SystemMenu.ManualProgrammingEnabled',
          ManualRapidAdvanceEnabled: 'Program.Misc.SystemMenu.ManualRapidAdvanceEnabled'
        },
        userLanguages: [// Keep these languages in sync with midas-options::Language
        "Program.Display.EnabledLanguages.Czech", "Program.Display.EnabledLanguages.Dutch", "Program.Display.EnabledLanguages.Greek", "Program.Display.EnabledLanguages.Danish", "Program.Display.EnabledLanguages.French", "Program.Display.EnabledLanguages.German", "Program.Display.EnabledLanguages.Korean", "Program.Display.EnabledLanguages.Polish", "Program.Display.EnabledLanguages.Slovak", "Program.Display.EnabledLanguages.English", "Program.Display.EnabledLanguages.Finnish", "Program.Display.EnabledLanguages.Italian", "Program.Display.EnabledLanguages.Latvian", "Program.Display.EnabledLanguages.Russian", "Program.Display.EnabledLanguages.Slovene", "Program.Display.EnabledLanguages.Swedish", "Program.Display.EnabledLanguages.Turkish", "Program.Display.EnabledLanguages.Croatian", "Program.Display.EnabledLanguages.Japanese", "Program.Display.EnabledLanguages.Romanian", "Program.Display.EnabledLanguages.Bulgarian", "Program.Display.EnabledLanguages.Hungarian", "Program.Display.EnabledLanguages.Norwegian", "Program.Display.EnabledLanguages.Lithuanian", "Program.Display.EnabledLanguages.SpanishEuropean", "Program.Display.EnabledLanguages.SpanishColumbian", "Program.Display.EnabledLanguages.ChineseSimplified", "Program.Display.EnabledLanguages.ChineseTraditional", "Program.Display.EnabledLanguages.PortugueseEuropean", "Program.Display.EnabledLanguages.PortugueseBrazilian", "Program.Display.EnabledLanguagesExtra.Indonesian", "Program.Display.EnabledLanguagesExtra.Malay", "Program.Display.EnabledLanguagesExtra.Thai", "Program.Display.EnabledLanguagesExtra.Vietnamese"]
      };
    }),
    actions: {
      refreshFormConfig() {
        this.notifyPropertyChange('watchForUpdates');
      }

    },
    alignUiWatcher: Ember.observer('formConfig', function () {
      // eslint-disable-line
      // If the formConfig gets updated, we'll need to re-align the UI elements
      this.alignUiElements();
    }),

    alignUiElements() {
      // Find all .checkbox-value-combo parents, then set width of first checkbox label elements
      let parents = [];
      let els = this.element.getElementsByClassName('checkbox-value-combo');

      for (let el of els) {
        parents.addObject(el.parentNode);
      }

      parents.forEach(container => {
        // Get each child with class and save biggest width
        let width = 0;
        let children = container.getElementsByClassName('checkbox-value-combo');

        for (let child of children) {
          let label = child.querySelector('label');

          if (label) {
            width = label.offsetWidth > width ? label.offsetWidth : width;
          }
        }

        for (let child of children) {
          let label = child.querySelector('label');

          if (label) {
            label.style.width = width + 10 + 'px';
          }
        }
      });
    }

  });

  _exports.default = _default;
});