define("alliance-business-suite/services/ajax", ["exports", "ember-ajax/services/ajax", "alliance-business-suite/config/environment"], function (_exports, _ajax, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ajax.default.extend({
    access: Ember.inject.service(),
    session: Ember.inject.service(),
    host: _environment.default.APP.ALLIANCE_API_URL,
    headers: Ember.computed('access.accessToken', 'session.data', {
      get() {
        let headers = {};
        let authToken = Ember.get(this, 'access.accessToken');
        let organizationId = Ember.get(this, 'session.data.orgId');
        headers.app = _environment.default.APP.APP_IDENTIFIER;
        headers['x-api-key'] = _environment.default.APP.ALLIANCE_API_KEY;
        headers['alliancels-organization-id'] = organizationId;
        headers['Content-Type'] = 'application/json'; // let resetPasswordToken = get(this, 'access.resetPasswordToken');
        // if (resetPasswordToken) {
        //   headers['alliancels-auth-token'] = resetPasswordToken;
        // }
        // eslint-disable-next-line

        if (authToken) {
          headers['alliancels-auth-token'] = authToken;
        }

        return headers;
      }

    })
  });

  _exports.default = _default;
});