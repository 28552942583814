define("alliance-business-suite/components/machines/programs/cycles/acas-wx-flw", ["exports", "alliance-business-suite/mixins/machines/programs/cycles/water-usage", "alliance-business-suite/mixins/machines/programs/cycles/cycle-global", "alliance-business-suite/mixins/machines/programs/acas-options", "alliance-business-suite/mixins/machines/programs/cycles/cycle-times"], function (_exports, _waterUsage, _cycleGlobal, _acasOptions, _cycleTimes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_acasOptions.default, _cycleGlobal.default, _cycleTimes.default, _waterUsage.default, {
    intl: Ember.inject.service(),
    machineAuditType: Ember.computed.alias("machineProgram._machineAuditType"),
    mods: Ember.computed('machineProgram', 'machineAuditType', 'Key1ModifierOptionsPrewash', 'Key1ModifierOptionsExtraRinse', 'Key2ModifierOptionsPrewash', 'Key2ModifierOptionsExtraRinse', 'Key1ModifierOptionsExtraWashStepsAgitateTime', 'CycleModifierKey1AddedExtraWashAgitateTime', 'Key1ModifierOptionsExtraRinseStepsAgitateTime', 'CycleModifierKey1AddedExtraRinseAgitateTime', 'Key2ModifierOptionsExtraWashStepsAgitateTime', 'CycleModifierKey2AddedExtraWashAgitateTime', 'Key2ModifierOptionsExtraRinseStepsAgitateTime', 'CycleModifierKey2AddedExtraRinseAgitateTime', function () {
      // THIS GETS KINDA CRAZY!
      // let mod11 = get(this, 'machineProgram.settings.Program.Price.Key1ModifierOptionsPrewash');
      // let mod12 = get(this, 'machineProgram.settings.Program.Price.Key1ModifierOptionsExtraRinse')
      // let mod21 = get(this, 'machineProgram.settings.Program.Price.Key2ModifierOptionsPrewash');
      // let mod22 = get(this, 'machineProgram.settings.Program.Price.Key2ModifierOptionsExtraRinse')
      // Key1ModifierOptionsExtraWashStepsAgitateTime: false,
      // Key1ModifierOptionsExtraRinseStepsAgitateTime: false,
      // Key2ModifierOptionsExtraWashStepsAgitateTime: false,
      // Key2ModifierOptionsExtraRinseStepsAgitateTime: false,
      // CycleModifierKey1AddedExtraWashAgitateTime: 2,
      // CycleModifierKey1AddedExtraRinseAgitateTime: 2,
      // CycleModifierKey2AddedExtraWashAgitateTime: 4,
      // CycleModifierKey2AddedExtraRinseAgitateTime: 4,

      /*
        Soil levels can have additional time added to their Wash and Rinse steps in the Pricing tab. We need to
        see if these extras are enabled, and if so, add that time using the following rules.
         Additional Wash - Time is added to all Agitate steps with a Label of Wash.
        Additional Rinse - Time is added to all Agitate steps with a Label of Rinse 1.
      */
      if (this.machineProgram == undefined) {
        return;
      }

      let allMods;

      if (this.machineProgram.hasMachineAttribute("MGD_M1_FLW")) {
        allMods = [{
          // No Modifiers - Light
          mod1: false,
          mod2: false,
          additionalWashMinutes: 0,
          additionalRinseMinutes: 0
        }, {
          // Mod 1 - Medium
          mod1: this.machineProgram.settings.Program.Price.ModifierKey1Options.PrewashEnabled,
          mod2: this.machineProgram.settings.Program.Price.ModifierKey1Options.ExtraRinseEnabled,
          additionalWashMinutes: this.machineProgram.settings.Program.Price.ModifierKey1AddedWashTime ? this.machineProgram.settings.Program.Price.ModifierKey1AddedWashTime : 0,
          additionalRinseMinutes: this.machineProgram.settings.Program.Price.ModifierKey1AddedRinseTime ? this.machineProgram.settings.Program.Price.ModifierKey1AddedRinseTime : 0
        }, {
          // Mod 2 - Heavy
          mod1: this.machineProgram.settings.Program.Price.ModifierKey2Options.PrewashEnabled,
          mod2: this.machineProgram.settings.Program.Price.ModifierKey2Options.ExtraRinseEnabled,
          additionalWashMinutes: this.machineProgram.settings.Program.Price.ModifierKey2AddedWashTime ? this.machineProgram.settings.Program.Price.ModifierKey2AddedWashTime : 0,
          additionalRinseMinutes: this.machineProgram.settings.Program.Price.ModifierKey2AddedRinseTime ? this.machineProgram.settings.Program.Price.ModifierKey2AddedRinseTime : 0
        }, {
          // Speed Cycle matches No Modifiers
          mod1: false,
          mod2: false,
          additionalWashMinutes: 0,
          additionalRinseMinutes: 0
        }];
      } else {
        allMods = [{
          // No Modifiers
          mod1: false,
          mod2: false,
          additionalWashMinutes: 0,
          additionalRinseMinutes: 0
        }, {
          // Mod 1
          mod1: this.Key1ModifierOptionsPrewash,
          mod2: this.Key1ModifierOptionsExtraRinse,
          additionalWashMinutes: this.Key1ModifierOptionsExtraWashStepsAgitateTime ? this.CycleModifierKey1AddedExtraWashAgitateTime : 0,
          additionalRinseMinutes: this.Key1ModifierOptionsExtraRinseStepsAgitateTime ? this.CycleModifierKey1AddedExtraRinseAgitateTime : 0
        }, {
          // Mod 2
          mod1: this.Key2ModifierOptionsPrewash,
          mod2: this.Key2ModifierOptionsExtraRinse,
          additionalWashMinutes: this.Key2ModifierOptionsExtraWashStepsAgitateTime ? this.CycleModifierKey2AddedExtraWashAgitateTime : 0,
          additionalRinseMinutes: this.Key2ModifierOptionsExtraRinseStepsAgitateTime ? this.CycleModifierKey2AddedExtraRinseAgitateTime : 0
        }];

        if (['ACAS_A2', 'ACAS_A22'].includes(this.machineAuditType)) {
          // Galaxy+ WX needs Mod 1 + Mod 2
          allMods[3] = {
            mod1: allMods[1].mod1 || allMods[2].mod1,
            mod2: allMods[1].mod2 || allMods[2].mod2,
            additionalWashMinutes: allMods[1].additionalWashMinutes + allMods[2].additionalWashMinutes,
            additionalRinseMinutes: allMods[1].additionalRinseMinutes + allMods[2].additionalRinseMinutes
          };
        }
      }

      return allMods;
    }),
    selectedStepName: Ember.computed('selectedStep.Type', function () {
      if (this.selectedStep && this.selectedStep.Type) {
        return this.intl.t(["Unknown", "ReuseFill", "Fill", "Supply", "Agitate", "Soak", "CoolDown", "Drain", "Unknown", "DrainExtract", "AudioSignal", "Hold"][this.selectedStep.Type]);
      } else {
        return this.intl.t("Unknown");
      }
    }),
    // #region Settings and Configs
    cycles: Ember.computed('cycleCount', 'machineAuditType', 'machineProgram._machineCode', function () {
      switch (true) {
        case this.machineAuditType == 'ACAS_A22':
          return ['normal_90', 'normal_60', 'normal_40', 'perm_press_90', 'perm_press_60', 'gentle_30', 'perm_press_50'];

        case ['ACAS_A2'].includes(this.machineAuditType):
          return ['normal_hot', 'normal_warm', 'normal_cold', 'blankets_cold', 'delicate_warm', 'delicate_cold'];

        case this.cycleCount === 6 && this.machineProgram._machineCode == "ACAS_A3_FLW":
          // The FLW has different cycles for A3
          return ['normal_90', 'normal_60', 'normal_40', 'perm_press_60', 'delicate_30', 'delicate_no_heat'];

        case this.cycleCount === 6:
          return ['normal_hot', 'normal_warm', 'normal_cold', 'delicate_hot', 'delicate_warm', 'delicate_cold'];

        default:
          return ['normal_hot', 'normal_warm', 'normal_cold', 'permanent_press_hot', 'permanent_press_warm', 'permanent_press_cold', 'delicate_hot', 'delicate_warm', 'delicate_cold'];
      }
    }),
    stepMetaData: [{
      Type: 1,
      Name: 'Reuse Fill Step',
      enabled: true
    }, {
      Type: 2,
      Name: 'Fill Step',
      enabled: true
    }, {
      Type: 3,
      Name: 'Supply Step',
      enabled: true
    }, {
      Type: 4,
      Name: 'Agitate Step',
      enabled: true
    }, {
      Type: 5,
      Name: 'Soak Step',
      enabled: true
    }, {
      Type: 6,
      Name: 'Cooldown Step',
      enabled: true
    }, {
      Type: 7,
      Name: 'Drain Step',
      enabled: true
    }, {
      Type: 8,
      Name: 'Spray Rinse Extract Drain Step',
      enabled: false
    }, {
      Type: 9,
      Name: 'Extract Step',
      enabled: true
    }, {
      Type: 10,
      Name: 'Audio Signal Step',
      enabled: true
    }, {
      Type: 11,
      Name: 'Hold Step',
      enabled: true
    }, {
      Type: 12,
      Name: 'Wash Spray Step',
      enabled: true
    }],
    steps: Ember.computed('machineProgram._machineTypeCode', function () {
      // These property names must match the ones used by the machine, which can change based on machine audit type
      const DEFAULT_PROPERTIES = [{
        Type: 1,
        Label: 1,
        Speed: 2,
        Minutes: 5,
        Seconds: 0,
        FlushOut: false,
        HotFillOn: false,
        PauseTime: 15,
        ColdFillOn: true,
        RotateTime: 5,
        WaterLevel: 124,
        ActiveState: 8,
        CustomSpeed: 2,
        Temperature: 0,
        NonReversing: false,
        AuxiliaryFill: false,
        ColdHardFillOn: false,
        FillValveSetOn: false,
        SprayValveSetOn: false,
        CustomWaterLevel: 124,
        CustomTemperature: 0,
        FillTubValveSetOn: false,
        TemperatureControlledFill: false
      }, {
        Type: 2,
        Label: 1,
        Speed: 2,
        Minutes: 2,
        Seconds: 0,
        Flushout: false,
        AuxFillOn: false,
        HotFillOn: false,
        PauseTime: 15,
        SmartWave: false,
        ColdFillOn: true,
        RotateTime: 5,
        WaterLevel: 124,
        ActiveState: 8,
        CustomSpeed: 2,
        Temperature: 0,
        FillValveSet: true,
        NonReversing: false,
        ColdHardFillOn: false,
        SprayValveSetOn: true,
        CustomWaterLevel: 124,
        CustomTemperature: 0,
        FillTubValveSetOn: true,
        TemperatureControlledFill: false
      }, {
        Type: 3,
        Label: 1,
        Minutes: 0,
        Seconds: 30,
        AuxFillOn: false,
        HotFillOn: false,
        ColdFillOn: true,
        ActiveState: 8,
        JrAuxOutput1: false,
        JrAuxOutput2: false,
        JrAuxOutput3: false,
        JrAuxOutput4: false,
        SprayValveSet: true,
        ColdHardFillOn: false,
        Compartment1On: true,
        Compartment2On: false,
        Compartment3On: false,
        Compartment4On: false,
        FillValveSetOn: true,
        ShutoffCondition: 0,
        FillTubValveSetOn: true,
        SupplyDelaySeconds: 0
      }, {
        Type: 4,
        Label: 1,
        Speed: 2,
        Minutes: 1,
        Seconds: 0,
        HeatRate: 0,
        RefillOn: true,
        PauseTime: 4,
        HeatOption: 0,
        RotateTime: 18,
        ActiveState: 8,
        CustomSpeed: 2,
        NoReversing: false,
        MainDrain1On: false,
        MainDrain2On: false,
        PulseDrainOn: false,
        JrAuxOutput1On: false,
        JrAuxOutput2On: false,
        JrAuxOutput3On: false,
        JrAuxOutput4On: false
      }, {
        Type: 5,
        Hours: 0,
        Label: 1,
        Speed: 2,
        Minutes: 1,
        HeatRate: 0,
        RefillOn: true,
        PauseTime: 56,
        HeatOption: 0,
        RotateTime: 4,
        ActiveState: 8,
        CustomSpeed: 2,
        MainDrain1On: false,
        MainDrain2On: false,
        NonReversing: false,
        PulseDrainOn: false,
        JrAuxOutput1On: false,
        JrAuxOutput2On: false,
        JrAuxOutput3On: false,
        JrAuxOutput4On: false
      }, {
        Type: 6,
        Label: 1,
        Speed: 2,
        AuxFillOn: false,
        PauseTime: 18,
        RotateTime: 6,
        ActiveState: 8,
        CustomSpeed: 2,
        Temperature: 0,
        ColdHardFill: false,
        CoolDownRate: 0,
        CoolDownType: 0,
        MainDrain1On: true,
        MainDrain2On: false,
        NonReversing: false,
        PulseDrainOn: false,
        SprayValveSet: true,
        FillValveSetOn: true,
        HotFillValveOn: false,
        JrAuxOutput1On: false,
        JrAuxOutput2On: false,
        JrAuxOutput3On: false,
        JrAuxOutput4On: false,
        ColdFillValveOn: true,
        FillTubValveSet: true
      }, {
        Type: 7,
        Label: 1,
        Speed: 2,
        PauseTime: 4,
        RotateTime: 18,
        ActiveState: 8,
        CustomSpeed: 2,
        NoReversing: true,
        MainDrain1On: true,
        MainDrain2On: true,
        JrAuxOutput1On: false,
        JrAuxOutput2On: false,
        JrAuxOutput3On: false,
        JrAuxOutput4On: false,
        PulseDrainEnabled: false,
        RotateEntireStepEnabled: false
      }, {
        Type: 9,
        Label: 1,
        Speed: 2,
        Minutes: 0,
        Seconds: 30,
        ActiveState: 8,
        CustomSpeed: 2,
        MainDrain1On: true,
        MainDrain2On: true,
        ChemFlushTime: 0,
        JrAuxOutput1On: false,
        JrAuxOutput2On: false,
        JrAuxOutput3On: false,
        JrAuxOutput4On: false,
        PulseDrainEnabled: false
      }, {
        Type: 10,
        Label: 1,
        Minutes: 0,
        Seconds: 5,
        ActiveState: 8,
        AudioPattern: 1,
        JrAuxOutput1On: false,
        JrAuxOutput2On: false,
        JrAuxOutput3On: false,
        JrAuxOutput4On: false
      }, {
        Type: 11,
        Label: 1,
        Minutes: 0,
        Seconds: 10,
        MainDrain1: true,
        MainDrain2: true,
        ActiveState: 8,
        AudioPattern: 1,
        GlobalMessage: 0,
        DrainAndUnlock: true,
        JrAuxOutput1On: false,
        JrAuxOutput2On: false,
        JrAuxOutput3On: false,
        JrAuxOutput4On: false,
        AutoResumeMinutes: 0,
        PulseDrainEnabled: false
      }];
      const MGD_PROPERTIES = [{
        "Type": 1,
        "Label": 1,
        "Minutes": 5,
        "Seconds": 0,
        "FlushOut": false,
        "PauseTime": 15,
        "RotateTime": 5,
        "ActiveState": 8,
        "CustomSpeed": 2,
        "Temperature": 0,
        "NonReversing": false,
        "ReuseFillAOn": false,
        "ReuseFillBOn": false,
        "AuxFillEnabled": false,
        "HotFillEnabled": false,
        "ColdFillEnabled": true,
        "CustomWaterLevel": 124,
        "FillValveEnabled": false,
        "AuxOutput1Enabled": false,
        "AuxOutput2Enabled": false,
        "AuxOutput3Enabled": false,
        "AuxOutput4Enabled": false,
        "AuxOutput5Enabled": false,
        "AuxOutput6Enabled": false,
        "SprayValveEnabled": false,
        "ColdHardFillEnabled": false,
        "FillTubValveEnabled": false,
        "TempControlledFillEnabled": false
      }, {
        "Temp": 0,
        "Type": 2,
        "Label": 1,
        "Minutes": 2,
        "Seconds": 0,
        "FlushOut": false,
        "PauseTime": 15,
        "RotateTime": 5,
        "ActiveState": 8,
        "CustomSpeed": 2,
        "NonReversing": false,
        "AuxFillEnabled": false,
        "HotFillEnabled": false,
        "ColdFillEnabled": true,
        "CustomWaterLevel": 124,
        "FillValveEnabled": true,
        "AuxOutput1Enabled": false,
        "AuxOutput2Enabled": false,
        "AuxOutput3Enabled": false,
        "AuxOutput4Enabled": false,
        "AuxOutput5Enabled": false,
        "AuxOutput6Enabled": false,
        "SprayValveEnabled": false,
        "ColdHardFillEnabled": false,
        "FillTubValveEnabled": true,
        "TempControlledFillEnabled": false
      }, {
        "Type": 3,
        "Label": 1,
        "Minutes": 0,
        "Seconds": 30,
        "ActiveState": 8,
        "CustomSpeed": 1,
        "AuxFillEnabled": false,
        "HotFillEnabled": false,
        "ColdFillEnabled": true,
        "FillValveEnabled": true,
        "ShutoffCondition": 0,
        "AuxSupply1Enabled": false,
        "AuxSupply2Enabled": false,
        "AuxSupply3Enabled": false,
        "AuxSupply4Enabled": false,
        "AuxSupply5Enabled": false,
        "AuxSupply6Enabled": false,
        "AuxSupply7Enabled": false,
        "AuxSupply8Enabled": false,
        "SprayValveEnabled": false,
        "SupplyDelaySeconds": 0,
        "ColdHardFillEnabled": false,
        "Compartment1Enabled": true,
        "Compartment2Enabled": false,
        "Compartment3Enabled": false,
        "Compartment4Enabled": false,
        "FillTubValveEnabled": false,
        "JrAuxOutput1Enabled": false,
        "JrAuxOutput2Enabled": false,
        "JrAuxOutput3Enabled": false,
        "JrAuxOutput4Enabled": false,
        "JrAuxOutput5Enabled": false,
        "JrAuxOutput6Enabled": false
      }, {
        "Type": 4,
        "Label": 1,
        "Minutes": 1,
        "Seconds": 0,
        "HeatRate": 0,
        "ChemFlush": false,
        "PauseTime": 4,
        "HeatOption": 0,
        "MainDrain1": false,
        "MainDrain2": false,
        "RotateTime": 18,
        "ActiveState": 8,
        "CustomSpeed": 2,
        "NonReversing": false,
        "PulseDrainOn": false,
        "RefillEnabled": true,
        "ExternalDrainOn": false,
        "AuxSupply1Enabled": false,
        "AuxSupply2Enabled": false,
        "AuxSupply3Enabled": false,
        "AuxSupply4Enabled": false,
        "AuxSupply5Enabled": false,
        "AuxSupply6Enabled": false,
        "AuxSupply7Enabled": false,
        "AuxSupply8Enabled": false,
        "ResueDrainPumpAOn": false,
        "ResueDrainPumpBOn": false,
        "JrAuxOutput1Enabled": false,
        "JrAuxOutput2Enabled": false,
        "JrAuxOutput3Enabled": false,
        "JrAuxOutput4Enabled": false,
        "JrAuxOutput5Enabled": false,
        "JrAuxOutput6Enabled": false
      }, {
        "Type": 5,
        "Hours": 0,
        "Label": 1,
        "Minutes": 1,
        "HeatRate": 0,
        "ChemFlush": false,
        "PauseTime": 56,
        "HeatOption": 0,
        "MainDrain1": false,
        "MainDrain2": false,
        "RotateTime": 4,
        "ActiveState": 8,
        "CustomSpeed": 2,
        "NonReversing": false,
        "PulseDrainOn": false,
        "RefillEnabled": true,
        "ExternalDrainOn": false,
        "AuxSupply1Enabled": false,
        "AuxSupply2Enabled": false,
        "AuxSupply3Enabled": false,
        "AuxSupply4Enabled": false,
        "AuxSupply5Enabled": false,
        "AuxSupply6Enabled": false,
        "AuxSupply7Enabled": false,
        "AuxSupply8Enabled": false,
        "ResueDrainPumpAOn": false,
        "ResueDrainPumpBOn": false,
        "JrAuxOutput1Enabled": false,
        "JrAuxOutput2Enabled": false,
        "JrAuxOutput3Enabled": false,
        "JrAuxOutput4Enabled": false,
        "JrAuxOutput5Enabled": false,
        "JrAuxOutput6Enabled": false
      }, {
        "Temp": 0,
        "Type": 6,
        "Label": 1,
        "PauseTime": 18,
        "MainDrain1": false,
        "MainDrain2": false,
        "RotateTime": 6,
        "ActiveState": 8,
        "CustomSpeed": 2,
        "CoolDownRate": 0,
        "NonReversing": false,
        "PulseDrainOn": false,
        "IsAntiWrinkle": false,
        "AuxFillEnabled": false,
        "HotFillEnabled": false,
        "IsPlumbingCode": true,
        "ColdFillEnabled": true,
        "FillValveEnabled": true,
        "AuxSupply1Enabled": false,
        "AuxSupply2Enabled": false,
        "AuxSupply3Enabled": false,
        "AuxSupply4Enabled": false,
        "AuxSupply5Enabled": false,
        "AuxSupply6Enabled": false,
        "AuxSupply7Enabled": false,
        "AuxSupply8Enabled": false,
        "SprayValveEnabled": false,
        "ColdHardFillEnabled": false,
        "FillTubValveEnabled": true,
        "JrAuxOutput1Enabled": false,
        "JrAuxOutput2Enabled": false,
        "JrAuxOutput3Enabled": false,
        "JrAuxOutput4Enabled": false,
        "JrAuxOutput5Enabled": false,
        "JrAuxOutput6Enabled": false
      }, {
        "Type": 7,
        "Label": 1,
        "PauseTime": 4,
        "MainDrain1": true,
        "MainDrain2": false,
        "RotateTime": 18,
        "ActiveState": 8,
        "CustomSpeed": 2,
        "PulseDrainOn": false,
        "ExternalDrainOn": false,
        "AuxSupply1Enabled": false,
        "AuxSupply2Enabled": false,
        "AuxSupply3Enabled": false,
        "AuxSupply4Enabled": false,
        "AuxSupply5Enabled": false,
        "AuxSupply6Enabled": false,
        "AuxSupply7Enabled": false,
        "AuxSupply8Enabled": false,
        "ResueDrainPumpAOn": false,
        "ResueDrainPumpBOn": false,
        "NoReversingEnabled": false,
        "JrAuxOutput1Enabled": false,
        "JrAuxOutput2Enabled": false,
        "JrAuxOutput3Enabled": false,
        "JrAuxOutput4Enabled": false,
        "JrAuxOutput5Enabled": false,
        "JrAuxOutput6Enabled": false,
        "RotateEntireStepEnabled": false
      }, {
        "Type": 9,
        "Label": 1,
        "Minutes": 0,
        "Seconds": 30,
        "MainDrain1": true,
        "MainDrain2": false,
        "ActiveState": 8,
        "CustomSpeed": 2,
        "PulseDrainOn": false,
        "ExternalDrainOn": false,
        "ChemFlushSeconds": 30,
        "AuxSupply1Enabled": false,
        "AuxSupply2Enabled": false,
        "AuxSupply3Enabled": false,
        "AuxSupply4Enabled": false,
        "AuxSupply5Enabled": false,
        "AuxSupply6Enabled": false,
        "AuxSupply7Enabled": false,
        "AuxSupply8Enabled": false,
        "ResueDrainPumpAOn": false,
        "ResueDrainPumpBOn": false,
        "JrAuxOutput1Enabled": false,
        "JrAuxOutput2Enabled": false,
        "JrAuxOutput3Enabled": false,
        "JrAuxOutput4Enabled": false,
        "JrAuxOutput5Enabled": false,
        "JrAuxOutput6Enabled": false
      }, {
        "Type": 10,
        "Label": 1,
        "Minutes": 0,
        "Seconds": 5,
        "ActiveState": 8,
        "CustomSpeed": 1,
        "ExternalDrainOn": false,
        "AudioPatternType": 1,
        "AuxSupply1Enabled": false,
        "AuxSupply2Enabled": false,
        "AuxSupply3Enabled": false,
        "AuxSupply4Enabled": false,
        "AuxSupply5Enabled": false,
        "AuxSupply6Enabled": false,
        "AuxSupply7Enabled": false,
        "AuxSupply8Enabled": false,
        "ResueDrainPumpAOn": false,
        "ResueDrainPumpBOn": false,
        "JrAuxOutput1Enabled": false,
        "JrAuxOutput2Enabled": false,
        "JrAuxOutput3Enabled": false,
        "JrAuxOutput4Enabled": false,
        "JrAuxOutput5Enabled": false,
        "JrAuxOutput6Enabled": false
      }, {
        "Type": 11,
        "Label": 1,
        "MainDrain1": true,
        "MainDrain2": false,
        "ActiveState": 8,
        "CustomSpeed": 1,
        "AudioMinutes": 0,
        "AudioSeconds": 10,
        "PulseDrainOn": false,
        "GlobalMessage": 0,
        "DrainAndUnlock": true,
        "ExternalDrainOn": false,
        "AudioPatternType": 1,
        "AutoResumeMinutes": 0,
        "AuxSupply1Enabled": false,
        "AuxSupply2Enabled": false,
        "AuxSupply3Enabled": false,
        "AuxSupply4Enabled": false,
        "AuxSupply5Enabled": false,
        "AuxSupply6Enabled": false,
        "AuxSupply7Enabled": false,
        "AuxSupply8Enabled": false,
        "ResueDrainPumpAOn": false,
        "ResueDrainPumpBOn": false,
        "JrAuxOutput1Enabled": false,
        "JrAuxOutput2Enabled": false,
        "JrAuxOutput3Enabled": false,
        "JrAuxOutput4Enabled": false,
        "JrAuxOutput5Enabled": false,
        "JrAuxOutput6Enabled": false
      }];

      switch (true) {
        case this.machineProgram.hasMachineAttribute("MGD_M1"):
          return MGD_PROPERTIES;

        default:
          let defaultProperties = DEFAULT_PROPERTIES; // Pause/Rotate times in ReuseFill step are different for WX

          if (this.machineProgram._machineTypeCode === "WX") {
            defaultProperties[0].PauseTime = 4;
            defaultProperties[0].RotateTime = 18;
          }

          return defaultProperties;
      }
    })
  });

  _exports.default = _default;
});