define("alliance-business-suite/components/ui/modal-create-user-note", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  let UiModalCreateUserNoteComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember.computed('note.noteText'), _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, (_class = class UiModalCreateUserNoteComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "ajax", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "note", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "isLoading", _descriptor6, this);
    }

    get canSubmit() {
      let {
        note
      } = this;

      if (note) {
        let noteText = note.noteText || 0;

        if (noteText.length || noteText.length > 0) {
          return true;
        } else {
          return false;
        }
      }

      return false;
    }

    initialLoad() {
      let user = this.store.peekRecord('user', this.access.accessUser.id);
      let note = this.store.createRecord('user-note', {
        user
      }); // note.noteText = ""

      this.note = note;
    }

    close() {
      this.note.rollbackAttributes();

      if (!this.isLoading) {
        this.args.close();
      }
    }

    apply() {
      this.isLoading = true;
      this.note.save({
        adapterOptions: {
          customerId: this.args.customer.id
        }
      }).then(() => {
        this.isLoading = false;
        this.args.close();
      }, () => {
        this.isLoading = false;
      });
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "note", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isLoading", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "canSubmit", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "canSubmit"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "initialLoad", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "initialLoad"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "close", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "apply", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "apply"), _class.prototype)), _class));
  _exports.default = UiModalCreateUserNoteComponent;
});