define("alliance-business-suite/templates/components/form-ui/checkbox-extended-tumble", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CMmbk2Wx",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,0],[[32,0,[\"showComponent\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[31,[\"checkbox-field \",[30,[36,0],[[32,0,[\"noClass\"]],\"\",\"form-field overflow-float\"],null]]]],[12],[2,\"\\n    \"],[10,\"label\"],[15,0,[31,[\"form-field--label inline-checkbox form-ui-element \",[30,[36,0],[[35,1],\"disabled\"],null]]]],[12],[2,\"\\n      \"],[8,\"input\",[[24,0,\"form-field--control\"]],[[\"@type\",\"@checked\",\"@disabled\",\"@click\"],[\"checkbox\",[34,2],[34,1],[30,[36,3],[[32,0],\"confirmExtendedTumbleWarning\"],null]]],null],[2,\"\\n      \"],[1,[34,4]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"dev-tools hidden-formui-component\"],[12],[2,\"CHECKBOX-EXTENDED-TUMBLE component hidden\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[8,\"form-ui/dev-tools\",[],[[\"@outputs\"],[[30,[36,8],null,[[\"labelAutoTranslated\",\"propertyName\",\"isDisabled\",\"invert\",\"rawValue\",\"value\"],[[35,7],[35,6,[\"propertyName\"]],[35,1],[35,6,[\"invert\"]],[35,5],[35,2]]]]]],null]],\"hasEval\":false,\"upvars\":[\"if\",\"isDisabled\",\"value\",\"action\",\"label\",\"rawValue\",\"params\",\"labelAutoTranslated\",\"hash\"]}",
    "moduleName": "alliance-business-suite/templates/components/form-ui/checkbox-extended-tumble.hbs"
  });

  _exports.default = _default;
});