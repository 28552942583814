define("alliance-business-suite/utils/custom-hold-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unicodeToBase64 = _exports.base64ToUnicode = void 0;

  /**
   *
   * This file contains functions for converting the Base64 encoded Custom Hold Message to a usable string.
   *
   * The API gives us a UTF-32LE encoded string which is then Base64 encoded for transit. To work with that
   * data in our project, we convert it to Unicode for local use and then convert the Unicode back to a
   * UTF-32LE encoded string (which is then Base64 encoded) when sending it back to the API.
   *
   * Although this code works, it may not be the most efficient way to do this and refactoring may be
   * beneficial.
   *
   */
  function utf8ToUtf32(f, e, a) {
    if (e === void 0 && (e = "be"), e != "be" && e != "le") throw new Error("byteOrder must be 'be' (for big endian) or 'le' (for little endian)");
    a === void 0 && (a = !1);

    for (var o = [], r = 0; r < f.length;) {
      var n = f[r];

      if ((n & bin("11110000")) == bin("11110000")) {
        var c = f[r],
            l = f[r + 1],
            i = f[r + 2],
            t = f[r + 3],
            v = (c & bin("00000111")) << 18 | (l & bin("00111111")) << 12 | (i & bin("00111111")) << 6 | t & bin("00111111");
        r += 4;
      } else if ((n & bin("11100000")) == bin("11100000")) {
        var l = f[r],
            i = f[r + 1],
            t = f[r + 2],
            v = (l & bin("00001111")) << 12 | (i & bin("00111111")) << 6 | t & bin("00111111");
        r += 3;
      } else if ((n & bin("11000000")) == bin("11000000")) {
        var i = f[r],
            t = f[r + 1],
            v = (i & bin("00011111")) << 6 | t & bin("00111111");
        r += 2;
      } else if ((n & 128) == 0) {
        var v = n;
        r += 1;
      } else throw new Error("Unrecognized byte at position " + (r + 1));

      o.push(v);
    }

    for (var u = [], r = 0; r < o.length; r++) {
      var v = o[r],
          h = codePointToUtf32(v, e);
      u.push(h);
    }

    return a && (e == "be" ? u.unshift([0, 0, 254, 255]) : u.unshift([255, 254, 0, 0])), u;
  }

  function bin(f) {
    return parseInt(f, 2);
  }

  function codePointToUtf32(f, e) {
    var a = (f & 4278190080) >> 24,
        o = (f & 16711680) >> 16,
        r = (f & 65280) >> 8,
        n = f & 255;
    if (e == "be") return [a, o, r, n];
    if (e == "le") return [n, r, o, a];
    throw new Error("Unrecognized byte order request for conversion.");
  }

  function decodeBase64(base64string) {
    try {
      return window.atob(base64string);
    } catch (error) {
      console.log('Error decoding base64:' + error);
      return "";
    }
  }

  const toByteArray = function (string) {
    let order = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "be";
    let length = string.length;
    let array = new Uint32Array(new ArrayBuffer(length * 4));

    for (let x = 0; x < string.length; x++) {
      let arrayPosition = x;
      array[arrayPosition] = string.codePointAt(x);
    }

    if (order == "le") {
      let leArray = new Uint32Array(new ArrayBuffer(length * 4));

      for (let i = 0; i < leArray.length; i += 4) {
        leArray[i] = array[i + 3];
        leArray[i + 1] = array[i + 2];
        leArray[i + 2] = array[i + 1];
        leArray[i + 3] = array[i];
      }

      return leArray;
    }

    return array;
  };

  const byteArrayToDecimal = function (array) {
    let order = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "be";

    if (order === 'be') {
      return array[0] * 16777216 + array[1] * 65536 + array[2] * 256 + array[3];
    }

    if (order === 'le') {
      return array[3] * 16777216 + array[2] * 65536 + array[1] * 256 + array[0];
    }

    return "Endian error";
  };

  function getString(bytes, type) {
    let str = '';

    for (let i = 0; i < bytes.length; i++) {
      // Skip null values
      if (bytes[i] !== 0) {
        str = str + String.fromCharCode(bytes[i]);
      }
    }

    return str;
  }

  function getDecimalByteArray(bytes) {
    let newArray = [];

    for (let i = 0; i < bytes.length; i += 4) {
      newArray.push(byteArrayToDecimal([bytes[i], bytes[i + 1], bytes[i + 2], bytes[i + 3]]));
    }

    return newArray;
  }

  function utf32ToUnicode(bytes) {
    let str = '';

    for (let i = 0; i < bytes.length; i++) {
      str = str + String.fromCharCode(bytes[i][0]);
      str = str + String.fromCharCode(bytes[i][1]);
      str = str + String.fromCharCode(bytes[i][2]);
      str = str + String.fromCharCode(bytes[i][3]);
    }

    return str;
  }

  const base64ToUnicode = string => {
    let str = decodeBase64(string); // decode from base64

    let correctBytes = toByteArray(str, 'le'); // we have byte array from the string

    let correctString = getDecimalByteArray(correctBytes); // we have byte array from the string

    let cleanStr = getString(correctString); // we decode the byte array to text

    return cleanStr;
  };

  _exports.base64ToUnicode = base64ToUnicode;

  const unicodeToBase64 = (string, len) => {
    let returnString = len !== undefined ? string.padEnd(len, String.fromCodePoint(0)) : string;
    let returnBytes = toByteArray(returnString);
    let utf32array = utf8ToUtf32(returnBytes, "le");
    let return32string = utf32ToUnicode(utf32array); //

    let encodedCorrectly = btoa(return32string);
    return encodedCorrectly;
  };

  _exports.unicodeToBase64 = unicodeToBase64;
});