define("alliance-business-suite/components/machines/programs/cycles/steps-midas-opl-tum", ["exports", "alliance-business-suite/mixins/machines/programs/mc10-options"], function (_exports, _mc10Options) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    multistepEnabled: true,
    //#region Options
    optionsStepType: [{
      val: 0,
      label: "time"
    }, {
      val: 1,
      label: "auto"
    }, {
      val: 2,
      label: "moisture_dry"
    }],
    optionsMaterial: [{
      val: 0,
      label: "cotton"
    }, {
      val: 1,
      label: "blend"
    }, {
      val: 2,
      label: "bedding"
    }, {
      val: 3,
      label: "delicate"
    }, {
      val: 4,
      label: "synthetics"
    }, {
      val: 5,
      label: "wool"
    }],
    optionsRotateTime: [{
      val: 30,
      label: "30"
    }, {
      val: 45,
      label: "45"
    }, {
      val: 60,
      label: "60"
    }, {
      val: 90,
      label: "90"
    }, {
      val: 120,
      label: "120"
    }, {
      val: 240,
      label: "240"
    }, {
      val: 540,
      label: "540"
    }],
    optionsStopTime: [{
      val: 6,
      label: "6"
    }, {
      val: 7,
      label: "7"
    }, {
      val: 8,
      label: "8"
    }, {
      val: 9,
      label: "9"
    }, {
      val: 10,
      label: "10"
    }],
    stepTemperature: [{
      val: 0,
      label: "very_low",
      global: "120F"
    }, {
      val: 1,
      label: "low",
      global: "140F"
    }, {
      val: 2,
      label: "medium",
      global: "160F"
    }, {
      val: 3,
      label: "high",
      global: "190F"
    }, {
      val: 100,
      label: "100"
    }, {
      val: 105,
      label: "105"
    }, {
      val: 110,
      label: "110"
    }, {
      val: 115,
      label: "115"
    }, {
      val: 120,
      label: "120"
    }, {
      val: 125,
      label: "125"
    }, {
      val: 130,
      label: "130"
    }, {
      val: 135,
      label: "135"
    }, {
      val: 140,
      label: "140"
    }, {
      val: 145,
      label: "145"
    }, {
      val: 150,
      label: "150"
    }, {
      val: 155,
      label: "155"
    }, {
      val: 160,
      label: "160"
    }, {
      val: 165,
      label: "165"
    }, {
      val: 170,
      label: "170"
    }, {
      val: 175,
      label: "175"
    }, {
      val: 180,
      label: "180"
    }, {
      val: 185,
      label: "185"
    }, {
      val: 190,
      label: "190"
    }],
    stepMoisture: [{
      val: 0,
      label: "0%"
    }, {
      val: 1,
      label: "1%"
    }, {
      val: 3,
      label: "3%"
    }, {
      val: 5,
      label: "5%"
    }, {
      val: 7,
      label: "7%"
    }, {
      val: 10,
      label: "10%"
    }, {
      val: 15,
      label: "15%"
    }, {
      val: 20,
      label: "20%"
    }, {
      val: 25,
      label: "25%"
    }, {
      val: 30,
      label: "30%"
    }, {
      val: 35,
      label: "35%"
    }, {
      val: 40,
      label: "40%"
    }],
    optionsCooldownTemperature: [{
      val: 70,
      label: "70"
    }, {
      val: 75,
      label: "75"
    }, {
      val: 80,
      label: "80"
    }, {
      val: 85,
      label: "85"
    }, {
      val: 90,
      label: "90"
    }, {
      val: 95,
      label: "95"
    }, {
      val: 100,
      label: "100"
    }, {
      val: 105,
      label: "105"
    }, {
      val: 110,
      label: "110"
    }] //#endregion

  });

  _exports.default = _default;
});