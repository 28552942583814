define("alliance-business-suite/components/ui/context-menu-separator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'li',
    classNames: ['separator']
  });

  _exports.default = _default;
});