define("alliance-business-suite/components/summary/reports/s-value-center-collections", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "numeral", "alliance-business-suite/utils/currency-format-util", "alliance-business-suite/utils/report-tables-util"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _numeral, _currencyFormatUtil, _reportTablesUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  let SummaryReportsSValueCenterCollectionsComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, (_class = class SummaryReportsSValueCenterCollectionsComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor3, this);
    }

    get translatedColumns() {
      let {
        model
      } = this.args;
      let denominations = model.uniqueDenominations;
      let currencyDenomination = this.access.currencyDenomination;
      let currencyDivisor = model.currencyDivisor;
      let dynamicColumns = [{
        name: this.intl.t('organization_room_vcm'),
        valuePath: 'organizationName',
        width: 339,
        isFixed: 'left'
      }, {
        name: this.intl.t('Date'),
        valuePath: 'emptyDate',
        width: 186
      }, {
        name: this.intl.t('userName'),
        valuePath: 'userName',
        width: 309
      }, {
        name: this.intl.t('firstName'),
        valuePath: 'firstName',
        width: 225
      }, {
        name: this.intl.t('lastName'),
        valuePath: 'lastName',
        width: 225
      }, {
        name: this.intl.t('number_of_bills'),
        valuePath: 'billCount',
        width: 225
      }, {
        name: this.intl.t('models.account.amount'),
        valuePath: 'currencyAmount',
        width: 225
      }];
      denominations.forEach(denomination => {
        dynamicColumns.push({
          name: `${currencyDenomination}${_reportTablesUtil.formatDenominations.call(this, denomination / currencyDivisor || 0)} ${this.intl.t('count')}`,
          valuePath: `${denomination}`,
          width: 175
        });
      });
      return dynamicColumns;
    }

    get rowsWithChildren() {
      let {
        model
      } = this.args;
      let currencyDenomination = this.access.currencyDenomination; // let orgData = get(this, 'model.data');

      let currencyDivisor = model.currencyDivisor;
      let rowData = [];
      let valueCenterArray = [];
      let orgBillTotal = model.billCountTotal || 0;

      let orgCurrencyAmountTotal = _reportTablesUtil.formatDenominations.call(this, model.currencyAmountTotal / currencyDivisor || 0);

      rowData.push({
        organizationName: model.organizationName,
        billCount: orgBillTotal,
        currencyAmount: `${currencyDenomination}${orgCurrencyAmountTotal}`,
        children: valueCenterArray
      });
      model.vcmRooms.forEach(room => {
        let vcmArray = [];
        let roomBillCountTotal = room.billCountTotal || 0;

        let roomCurrencyTotal = _reportTablesUtil.formatDenominations.call(this, room.currencyAmountTotal / currencyDivisor || 0);

        valueCenterArray.push({
          organizationName: room.name,
          billCount: roomBillCountTotal,
          currencyAmount: `${currencyDenomination}${roomCurrencyTotal}`,
          children: vcmArray
        });
        room.vcms.forEach(vcm => {
          let collectionArray = [];
          let vcmBillCountTotal = vcm.billCountTotal || 0;

          let vcmCurrencyTotal = _reportTablesUtil.formatDenominations.call(this, vcm.currencyAmountTotal / currencyDivisor || 0);

          let formattedName = vcm.isPaymentCenter ? `PC: ${vcm.id}` : `VCM: ${vcm.id}`;
          vcmArray.push({
            organizationName: formattedName,
            billCount: vcmBillCountTotal,
            currencyAmount: `${currencyDenomination}${vcmCurrencyTotal}`,
            children: collectionArray
          });
          vcm.collections.forEach(collection => {
            let emptied = collection.emptyDate;

            if (collection.emptyDate !== 'Current') {
              emptied = moment(collection.emptyDate).format(`${_reportTablesUtil.formatDateTimeString.call(this)}`);
            }

            let collectionsCurrency = _reportTablesUtil.formatDenominations.call(this, collection.currencyAmount / currencyDivisor || 0);

            if (collection.sortOrder === '1_VALUE') {
              emptied = collection.emptyDate;
            }

            let setObj = {
              emptyDate: emptied,
              userName: collection.userName,
              firstName: collection.firstName,
              lastName: collection.lastName,
              billCount: collection.billCount,
              currencyAmount: `${currencyDenomination}${collectionsCurrency}`
            };
            collection.denominations.forEach(denom => {
              if (denom.denomination) {
                setObj[denom.denomination] = denom.count;
              }
            });
            collectionArray.push(setObj);
          });
        });
      });
      return rowData;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SummaryReportsSValueCenterCollectionsComponent;
});