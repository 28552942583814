define("alliance-business-suite/components/form-ui/positive-negative-number", ["exports", "alliance-business-suite/mixins/init-property-names", "alliance-business-suite/utils/fix-range"], function (_exports, _initPropertyNames, _fixRange) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_initPropertyNames.default, {
    numberValue: Ember.computed('positive', 'negative', {
      get() {
        let positive = this.positive;
        let negative = this.negative;

        if (positive > 0) {
          return positive;
        } else if (negative > 0) {
          return negative * -1;
        } else {
          return 0;
        }
      },

      set(key, value) {
        let object = this.object; // let propertyNames = get(this, 'propertyNames');

        let properties = this.properties;
        let min = Ember.get(this, 'params.min');
        let max = Ember.get(this, 'params.max');

        if (!Ember.isNone(min) && !Ember.isNone(max)) {
          value = (0, _fixRange.fixRange)(value, min, max);
        }

        if (value > 0) {
          this.update(object, properties.positive.key, value);
          this.update(object, properties.negative.key, 0);
        } else if (value < 0) {
          this.update(object, properties.positive.key, 0);
          this.update(object, properties.negative.key, Math.abs(value));
        } else {
          this.update(object, properties.positive.key, 0);
          this.update(object, properties.negative.key, 0);
        }

        return value;
      }

    })
  });

  _exports.default = _default;
});