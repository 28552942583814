define("alliance-business-suite/components/accounts/a-refund-button", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3;

  let AccountsARefundButtonComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._action, (_class = class AccountsARefundButtonComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "isDialogOpen", _descriptor3, this);
    }

    get isRefundable() {
      let refundUserAccountTransactionLog = this.args.item.refundUserAccountTransactionLog;
      let isMachinePayment = this.args.item.userAccountTransactionTypeLookupKey;

      if (isMachinePayment === 'MACHINE_PAYMENT' && !refundUserAccountTransactionLog) {
        return true;
      }

      return false;
    }

    toggleRefundModal() {
      if (this.access.isDemo) {
        alert('Demo mode restricted.');
        return;
      }

      this.isDialogOpen = !this.isDialogOpen;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isDialogOpen", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "toggleRefundModal", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "toggleRefundModal"), _class.prototype)), _class));
  _exports.default = AccountsARefundButtonComponent;
});