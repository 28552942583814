define("alliance-business-suite/components/scrub/brush-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const componentClassName = 'scrub-brush-item';
  let ComponentClass = Ember.Component.extend({
    /**
     * The static class names for this component.
     *
     * @type {Array<String>}
     * @public
     */
    classNames: [componentClassName],

    /**
     * The dynamic class names for this component that are derived from component
     * properties.
     *
     * @type {Array<String>}
     * @public
     */
    classNameBindings: [`active:${componentClassName}--active`],

    /**
     * The service used to do the translation.
     *
     * @protected
     */
    intl: Ember.inject.service(),

    /**
     * The title for this brush item.
     *
     * @type {String}
     * @public
     */
    title: Ember.computed('item.scrub', 'item.model.{isPending,brushLabel}', {
      get() {
        let scrub = Ember.get(this, 'item.scrub');
        let intl = this.intl;
        let isPending = Ember.get(this, 'item.model.isPending');

        if (scrub == null || scrub === 'default') {
          return 'default-brush';
        } else if (scrub === 'search') {
          return intl.t('components.scrub.brush-item.title.search');
        } else if (isPending) {
          return intl.t('components.scrub.brush-item.title.pending');
        } else {
          return Ember.get(this, 'item.model.brushLabel');
        }
      }

    }),

    /**
     * The subtitle for this brush item.
     *
     * @type {String}
     * @public
     */
    subtitle: Ember.computed('item.{scrub,state}', 'item.model.{isPending,brushSublabel}', {
      get() {
        let scrub = Ember.get(this, 'item.scrub');
        let isPending = Ember.get(this, 'item.model.isPending');

        if (scrub == null || scrub === 'default' || isPending) {
          return undefined;
        } else if (scrub === 'search') {
          return Ember.get(this, 'item.state');
        } else {
          let sublabel = Ember.get(this, 'item.model.brushSublabel');
          return sublabel ? `${sublabel}` : scrub;
        }
      }

    })
  });
  ComponentClass.reopenClass({
    positionalParams: ['item']
  });
  var _default = ComponentClass;
  _exports.default = _default;
});