define("alliance-business-suite/components/marketing/marketing-signage-section", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;

  let MarketingMarketingSignageSectionComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, (_class = class MarketingMarketingSignageSectionComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "ajax", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "brand", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "session", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "selectedMarketingLocation", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "bindInstance", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "height", _descriptor8, this);
    }

    // iframe for international locations (though not huebsch)
    get showIFrame() {
      return this.access.isInternational && this.brand.slug !== "huebsch";
    } // iframe for international locations (though not huebsch)


    get brandSpecificUrl() {
      let returnUrl = '';
      let brandSlug = this.brand.slug ? this.brand.slug : 'sq';

      switch (brandSlug) {
        case 'sq':
          returnUrl = 'https://alliancelaundrysystems.widencollective.com/portals/vtme9qax/SpeedQueenInsightsforSQLS';
          break;

        case 'ipso':
          returnUrl = 'https://alliancelaundrysystems.widencollective.com/portals/jfzicfzz/IPSOCONNECT';
          break;

        case 'primus':
          returnUrl = 'https://alliancelaundrysystems.widencollective.com/portals/os9souc6/PRIMUSi-Trace';
          break;
      }

      return returnUrl;
    }

    get locations() {
      const billingLocations = this.access.get('billingLocations');
      let locationsWithNames = [];

      if (billingLocations) {
        billingLocations.forEach(loc => {
          if (loc.id) {
            const room = this.store.peekRecord('room', loc.id);

            if (room) {
              locationsWithNames.push(room);
            }
          }
        });
      }

      return locationsWithNames;
    }

    initialLoad() {
      this.selectedMarketingLocation = this.locations[0].get('id');

      if (this.showIFrame) {
        // To remove event listener after willDestroyElement which where added using bind() you just have to store listener.bind(this) in a variable.
        // Because bind() is creating a new reference each time its called.
        this.bindInstance = this.setHeight.bind(this);
        window.addEventListener("resize", this.bindInstance, {
          passive: true
        });
      }
    }

    willDestroyComponent() {
      window.removeEventListener("resize", this.bindInstance);
      window.removeEventListener("scroll", this.bindInstance);
    }

    selectedLocation(e) {
      this.selectedMarketingLocation = e.target.value;
    }

    openPaymentFormWithLoc() {
      let locId = this.selectedMarketingLocation;

      if (this.access.isDemo) {
        alert('Demo mode restricted');
        return;
      }

      this.ajax.request(`/marketing/login?roomId=${locId}`, {
        method: 'POST'
      }).then(response => {
        window.open(response.url);
      });
    } // calculates the dynamic height


    setHeight() {
      let bottomOffset = 25;
      let windowHeight = window.innerHeight;
      let headerHeight = document.querySelector('.system-header').offsetHeight;
      let sectionPaddingTop = 100;
      let sectionHeader = document.querySelector('.ui-route-header').offsetHeight;
      let locationsExpiringSoon = this.access.locationsExpiringSoon;
      let subscriptionWarningBanner = 0;

      if (locationsExpiringSoon) {
        if (locationsExpiringSoon.length > 0) {
          subscriptionWarningBanner = 43;
        }
      }

      let final = windowHeight - headerHeight - sectionPaddingTop - sectionHeader - subscriptionWarningBanner - bottomOffset;
      this.height = final;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "brand", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "session", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedMarketingLocation", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "bindInstance", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "height", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "initialLoad", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "initialLoad"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "willDestroyComponent", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "willDestroyComponent"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedLocation", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "selectedLocation"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "openPaymentFormWithLoc", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "openPaymentFormWithLoc"), _class.prototype)), _class));
  _exports.default = MarketingMarketingSignageSectionComponent;
});