define("alliance-business-suite/templates/components/form-ui/limit-extract-speed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dJE1QejC",
    "block": "{\"symbols\":[],\"statements\":[[2,\"  \"],[10,\"span\"],[12],[2,\"\\n    \"],[10,\"label\"],[14,0,\"form-field--label inline-checkbox form-ui-element\"],[12],[2,\"\\n      \"],[10,\"input\"],[14,0,\"form-field--control\"],[15,\"disabled\",[34,4]],[15,\"checked\",[34,5]],[15,\"onclick\",[30,[36,6],[[32,0],\"checkboxClicked\"],null]],[14,4,\"checkbox\"],[12],[13],[1,[30,[36,7],[[35,3,[\"label\"]]],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"span\"],[14,0,\"inline\"],[12],[2,\"\\n\\n\"],[6,[37,10],[[30,[36,9],[[35,0],[35,8]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[8,\"input\",[[16,\"placeholder\",[34,1]],[24,0,\"form-field--control ember-text-field\"],[16,\"disabled\",true],[16,\"step\",[34,2]],[16,\"min\",[34,3,[\"min\"]]],[16,\"max\",[34,3,[\"max\"]]]],[[\"@type\",\"@value\",\"@limit\"],[\"number\",\"\",true]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[8,\"input\",[[16,\"placeholder\",[34,1]],[24,0,\"form-field--control ember-text-field\"],[16,\"step\",[34,2]],[16,\"min\",[34,3,[\"min\"]]],[16,\"max\",[34,3,[\"max\"]]]],[[\"@type\",\"@value\",\"@limit\"],[\"number\",[34,0],true]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\\n\"],[8,\"form-ui/dev-tools\",[],[[\"@outputs\"],[[30,[36,13],null,[[\"propertyName\",\"value\",\"rawValue\",\"onValue\",\"offValue\",\"checkboxValue\"],[[35,3,[\"propertyName\"]],[35,0],[35,12],[35,11],[35,8],[35,5]]]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"value\",\"placeholder\",\"step\",\"params\",\"isDisabled\",\"checkboxValue\",\"action\",\"t\",\"offValue\",\"eq\",\"if\",\"onValue\",\"rawValue\",\"hash\"]}",
    "moduleName": "alliance-business-suite/templates/components/form-ui/limit-extract-speed.hbs"
  });

  _exports.default = _default;
});