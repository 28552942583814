define("alliance-business-suite/components/locations/rewards/r-edit-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const componentClassName = 'rewards-program-edit-form'; // Do we even want these rules turned on at all?

  /* eslint-disable key-spacing, comma-spacing */

  var _default = Ember.Component.extend({
    /**
     * The service used to access the store.
     *
     * @protected
     */
    store: Ember.inject.service(),
    access: Ember.inject.service(),
    ajax: Ember.inject.service(),
    notifications: Ember.inject.service(),
    intl: Ember.inject.service(),
    componentClassName,
    classNames: [componentClassName],
    selectedTreeNodes: Ember.A(),
    gbWithRewardsPrograms: [],
    isInitLoading: true,
    showSaveConfirmModal: false,
    checkboxOptions: {
      'three_state': false,
      'cascade': 'down+undetermined',
      'load_checked_fix': true
    },
    selectedGeoboundaries: Ember.computed('selectedTreeNodes', {
      get() {
        let data = [];
        let selectedTreeNodes = Ember.get(this, 'selectedTreeNodes');

        if (selectedTreeNodes) {
          selectedTreeNodes.forEach(node => {
            let {
              hasFundsRestriction,
              geoBoundaryId
            } = node.original;

            if (hasFundsRestriction) {
              data.push(geoBoundaryId);
            }
          });
        }

        return data.map(id => parseInt(id));
      },

      set(propName, setValue) {
        // console.log('force set selectedGeoboundaries hack', setValue);
        return setValue;
      }

    }),
    actions: {
      // /rewardsPrograms/{rewardsProgramId}/geoBoundaries
      showSaveConfirmModal() {
        this.toggleProperty('showSaveConfirmModal');
      },

      sendRewardsProgram() {
        if (this.access.isDemo) {
          alert('Demo mode restricted.');
          return;
        }

        this.set('showSaveConfirmModal', false);
        let selectedGeoboundaries = Ember.get(this, 'selectedGeoboundaries'); // console.log('selectedGeoboundaries (jsa)', selectedGeoboundaries);

        let preselectedNodes = Ember.get(this, 'gbWithRewardsPrograms').map(id => parseInt(id)); // console.log('preselectedNodes (jsa)', preselectedNodes);
        // find nodes that were preselected, but no longer present in selectedGeoboundaries

        let removedNodes = preselectedNodes.filter(id => !selectedGeoboundaries.includes(id)); // console.log('removedNodes (jsa)', removedNodes);

        let rewardId = Ember.get(this, 'rewardId');
        Ember.set(this, 'isLoading', true);
        let patchDone = false;
        let deletionDone = false;

        if (selectedGeoboundaries.length > 0) {
          this.ajax.request(`/rewardsPrograms/${rewardId}/geoBoundaries`, {
            method: 'PATCH',
            data: {
              geoBoundaries: selectedGeoboundaries
            }
          }).then(() => {
            patchDone = true;

            if (patchDone && deletionDone) {
              Ember.get(this, 'notifications').info(this.intl.t('program_successfully_sent'), {
                autoClear: true,
                clearDuration: 2000
              });
            }
          }).catch((error
          /* , jqXHR */
          ) => {// console.log('error', error);
            // get(this, 'notifications').error(this.get('intl').t('error_send_program'), {
            //   autoClear: true,
            //   clearDuration: 6000
            // });
          });
        } else {
          patchDone = true;
        }

        if (removedNodes.length > 0) {
          this.ajax.request(`/rewardsPrograms/${rewardId}/geoBoundaries`, {
            method: 'DELETE',
            data: {
              geoBoundaries: removedNodes
            }
          }).then(() => {
            deletionDone = true;

            if (patchDone && deletionDone) {
              Ember.get(this, 'notifications').info(this.intl.t('program_successfully_sent'), {
                autoClear: true,
                clearDuration: 2000
              });
            }
          }).catch((error
          /* , jqXHR */
          ) => {
            console.warn('error', error); // get(this, 'notifications').error(this.get('intl').t('error_send_program'), {
            //   autoClear: true,
            //   clearDuration: 6000
            // });
          });
        } else {
          deletionDone = true;
        }
      }

    }
  });

  _exports.default = _default;
});