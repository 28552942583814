define("alliance-business-suite/templates/components/form-ui/slow-drain-detection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Wwz6/CQo",
    "block": "{\"symbols\":[\"ui\"],\"statements\":[[6,[37,1],[[32,0,[\"showComponent\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"form-ui\",[],[[\"@object\",\"@formDisplay\",\"@formSettings\",\"@selectOptions\"],[[32,0,[\"object\"]],\"compact\",[32,0,[\"formSettings\"]],[32,0,[\"selectOptions\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n    \"],[8,[32,1,[\"checkbox\"]],[],[[\"@params\"],[[30,[36,0],null,[[\"propertyName\",\"label\"],[[32,0,[\"properties\",\"Enabled\",\"key\"]],\"enable_slow_drain_detection\"]]]]],null],[2,\"\\n    \"],[8,[32,1,[\"number\"]],[],[[\"@params\"],[[30,[36,0],null,[[\"propertyName\",\"label\",\"min\",\"max\",\"labelAfter\",\"enabled\"],[[32,0,[\"properties\",\"AddedTime\",\"key\"]],\"added_time_for_detection\",1,255,\"sec\",[32,0,[\"Enabled\"]]]]]]],null],[2,\"\\n\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"hash\",\"if\"]}",
    "moduleName": "alliance-business-suite/templates/components/form-ui/slow-drain-detection.hbs"
  });

  _exports.default = _default;
});