define("alliance-business-suite/templates/components/wash-alert/wa-listing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aPgUKwM1",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,5],[[35,2,[\"machineNumber\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"li\"],[15,0,[31,[\"wa-listing \",[30,[36,5],[[35,4],\"active\"],null]]]],[15,\"title\",[31,[\"Id: \",[34,2,[\"id\"]]]]],[12],[2,\"\\n    \"],[10,\"div\"],[12],[2,\"\\n\"],[6,[37,5],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[11,\"div\"],[16,0,[31,[\"m-icon \",[30,[36,5],[[35,4],\"red\"],null]]]],[4,[38,3],[[32,0],\"removeMachine\",[35,2],[35,1]],null],[12],[2,\"\\n          \"],[10,\"i\"],[14,0,\"fa fa-check-circle\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[11,\"div\"],[24,0,\"m-icon\"],[4,[38,3],[[32,0],\"addMachine\",[35,2],[35,1]],null],[12],[2,\"\\n          \"],[10,\"i\"],[14,0,\"fa fa-minus-circle\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n      \"],[10,\"span\"],[14,0,\"wa-listing--info\"],[12],[2,\"\\n\"],[6,[37,5],[[35,2,[\"machineType\",\"isWasher\"]]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[1,[30,[36,0],[\"washer\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,5],[[35,2,[\"machineType\",\"isDryer\"]]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[1,[30,[36,0],[\"dryer\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n        \"],[1,[30,[36,7],null,[[\"machineName\",\"machineNumber\"],[[35,2,[\"machineName\"]],[35,6]]]]],[2,\"\\n\\n        \"],[10,\"span\"],[14,0,\"subtitle\"],[12],[2,\"\\n          \"],[1,[35,2,[\"machineType\",\"machineTypeName\"]]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"status\",\"model\",\"action\",\"isActive\",\"if\",\"machineNumber\",\"machine-display-name\"]}",
    "moduleName": "alliance-business-suite/templates/components/wash-alert/wa-listing.hbs"
  });

  _exports.default = _default;
});