define("alliance-business-suite/components/application-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    access: Ember.inject.service(),
    machinePrograms: Ember.inject.service(),
    classNameBindings: ['access.isLoadingBrand:theme--loading', 'brandTheme'],
    accessRules: {
      'scrubbrush': {
        deny: ['PERSON_REPORT_VIEWER']
      }
    },
    // TODO: Check that this works correctly!
    brandTheme: Ember.computed('brand.name', function () {
      return `theme--${this.brand.slug}`;
    }),
    actions: {
      toggleProgramsModal() {
        this.machinePrograms.showProgressComponent();
      }

    }
  });

  _exports.default = _default;
});