define("alliance-business-suite/components/locations/rewards/r-create-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const componentClassName = 'rewards-program-edit-form'; // Do we even want these rules turned on at all?

  /* eslint-disable key-spacing, comma-spacing */

  var _default = Ember.Component.extend({
    /**
     * The service used to access the store.
     *
     * @protected
     */
    store: Ember.inject.service(),
    access: Ember.inject.service(),

    init() {
      this._super(); // Flag this as a new machine program, so we're able to redirect to edit mode after saving this new record


      Ember.set(this, 'isNewProgram', true);
    },

    componentClassName,
    classNames: ['edit-form', componentClassName],

    didReceiveAttrs() {
      this._super(...arguments);
    }

  });

  _exports.default = _default;
});