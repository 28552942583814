define("alliance-business-suite/components/form-ui/checkbox-extended-tumble", ["exports", "alliance-business-suite/mixins/form-ui", "jquery"], function (_exports, _formUi, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // TODO: Consider merging this with the existing checkbox component, or extending from it.
  var _default = Ember.Component.extend(_formUi.default, {
    // tagName: 'div',
    // classNames: ['checkbox-field', 'form-field', 'overflow-float'],
    noClass: false,

    serializeValue(rawValue) {
      return Ember.get(this, 'params.invert') ? !rawValue : !!rawValue;
    },

    deserializeValue(value) {
      return Ember.get(this, 'params.invert') ? !value : value;
    },

    actions: {
      confirmExtendedTumbleWarning(e) {
        let t = this.intl;
        let el = (0, _jquery.default)(e.target);
        let isChecked = el.prop('checked'); // Current state after being clicked on

        let warningMessage = t.t('enable_extended_tumble_warning');

        if (!isChecked && !confirm(warningMessage)) {
          e.preventDefault();
        }
      }

    }
  });

  _exports.default = _default;
});