define("alliance-business-suite/components/crm/campaign-approval-banner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    access: Ember.inject.service(),
    intl: Ember.inject.service(),
    notifications: Ember.inject.service(),
    isLoading: false,
    actions: {
      approveCampaign(campaign) {
        if (this.access.isDemo) {
          alert('Demo mode restricted.');
          return;
        }

        let isApproved = !campaign.isApproved;
        let confirmKey = 'confirm_campaign_activation';
        Ember.set(this, 'isLoading', true);

        if (!isApproved) {
          confirmKey = 'confirm_campaign_deactivation';
        } // if (confirm(`${this.intl.t('download_customers_list_confirmation')} ${email}?`)) {


        if (!confirm(`${this.intl.t(confirmKey)}`)) {
          return;
        }

        this.ajax.request(`/campaigns/${campaign.get('id')}/approval`, {
          method: 'PATCH',
          data: {
            isApproved
          }
        }).then(() => {
          Ember.set(this, 'isLoading', false);
          campaign.set('isApproved', isApproved);
          this.notifications.success(this.intl.t('success'), {
            clearDuration: 5000,
            autoClear: true
          });
        }, () => {
          alert('Failed to change approval status');
        });
      }

    }
  });

  _exports.default = _default;
});