define("alliance-business-suite/templates/components/can-access", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YXX66MmP",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,1],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,1],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[15,0,[31,[\"can-access \",[30,[36,1],[[35,0],\"can-access-true\",\"can-access-false\"],null]]]],[12],[2,\"\\n      \"],[10,\"span\"],[14,0,\"can-access sfa\"],[12],[1,[34,2]],[13],[2,\"\\n      \"],[18,1,null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"canAccess\",\"if\",\"sfa\",\"isDebug\"]}",
    "moduleName": "alliance-business-suite/templates/components/can-access.hbs"
  });

  _exports.default = _default;
});