define("alliance-business-suite/components/machines/traceability/trace-refresh-controls", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="detail-section-controls" {{did-insert @toggleHydrate}}>
    <p>
      {{t "trace_in_progress"}}
  
      {{#if @hydrateActive}}
        {{t "trace_auto_poll_every_minute"}}
      {{else}}
        {{t "trace_auto_poll_paused"}}
      {{/if}}
    </p>
  
    <div>
      {{#if @hydrateInprogress}}
        <span class="hydrate-in-progress">
          <i class="fa fa-refresh fa-spin fa-fw"></i>
        </span>
      {{/if}}
  
      <button
        class="button button--secondary button--small btn-flex-small"
        {{on "click" @toggleHydrate}}>
          {{#if @hydrateActive}}
            {{t "pause_updates"}}
          {{else}}
            {{t "resume_updates"}}
          {{/if}}
      </button>
    </div>
  </div>
  
  */
  {
    "id": "d5dmXsrO",
    "block": "{\"symbols\":[\"@toggleHydrate\",\"@hydrateActive\",\"@hydrateInprogress\"],\"statements\":[[11,\"div\"],[24,0,\"detail-section-controls\"],[4,[38,1],[[32,1]],null],[12],[2,\"\\n  \"],[10,\"p\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"trace_in_progress\"],null]],[2,\"\\n\\n\"],[6,[37,2],[[32,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,0],[\"trace_auto_poll_every_minute\"],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[30,[36,0],[\"trace_auto_poll_paused\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[12],[2,\"\\n\"],[6,[37,2],[[32,3]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"span\"],[14,0,\"hydrate-in-progress\"],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"fa fa-refresh fa-spin fa-fw\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[11,\"button\"],[24,0,\"button button--secondary button--small btn-flex-small\"],[4,[38,3],[\"click\",[32,1]],null],[12],[2,\"\\n\"],[6,[37,2],[[32,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"          \"],[1,[30,[36,0],[\"pause_updates\"],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"          \"],[1,[30,[36,0],[\"resume_updates\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"did-insert\",\"if\",\"on\"]}",
    "moduleName": "alliance-business-suite/components/machines/traceability/trace-refresh-controls.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});