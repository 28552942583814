define("alliance-business-suite/templates/employees/roles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "z8K7gNgu",
    "block": "{\"symbols\":[],\"statements\":[[8,\"employees/roles/role-container\",[],[[\"@model\",\"@sRole\",\"@createRole\",\"@deleteRole\",\"@selectedRole\"],[[34,0],[34,1],[30,[36,2],[[32,0],\"createRole\"],null],[30,[36,2],[[32,0],\"deleteRole\"],null],[34,3]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"sRole\",\"action\",\"selectedRole\"]}",
    "moduleName": "alliance-business-suite/templates/employees/roles.hbs"
  });

  _exports.default = _default;
});