define("alliance-business-suite/templates/components/ui/lcd-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aPakTLN4",
    "block": "{\"symbols\":[],\"statements\":[[8,\"input\",[],[[\"@type\",\"@value\",\"@key-up\",\"@disabled\"],[\"text\",[34,0],[30,[36,1],[[32,0],\"validateInput\"],null],[34,2]]],null],[2,\"\\n​\\n\"],[10,\"div\"],[14,0,\"dev-tools\"],[12],[2,\"\\n  \"],[10,\"ul\"],[14,0,\"pills\"],[12],[2,\"\\n    \"],[10,\"li\"],[12],[2,\"Value: \"],[1,[34,3]],[13],[2,\"\\n    \"],[10,\"li\"],[12],[2,\"isDisabled: \"],[1,[34,2]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"displayValue\",\"action\",\"isDisabled\",\"property\"]}",
    "moduleName": "alliance-business-suite/templates/components/ui/lcd-field.hbs"
  });

  _exports.default = _default;
});