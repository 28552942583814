define("alliance-business-suite/helpers/find-options", ["exports", "alliance-business-suite/utils/machine-programming-find-options"], function (_exports, _machineProgrammingFindOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    // Returns the `items` array in the first object of the `data` array with a matching `machineAttribute`. The order of objects in the data array is important, since the FIRST match is returned.
    compute(_ref, hash) {
      let [arrayOptions, arrayMachineAttributes, ...rest] = _ref;
      const options = hash.options ?? arrayOptions;
      const machineAttributes = hash.machineAttributes ?? arrayMachineAttributes;
      return (0, _machineProgrammingFindOptions.default)(options, machineAttributes);
    }

  });

  _exports.default = _default;
});