define("alliance-business-suite/components/marketing/campaigns/preview-email", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    emailBodyType: 'html',

    init() {
      this._super(...arguments);

      if (this.messageType === "EMAIL_HTML") {
        let htmlEmailContent = this.get('campaign.deliveryMethodBodyTextHtml');
        setTimeout(function () {
          (0, _jquery.default)('#html-email-preview-iframe').contents().find('html').html(htmlEmailContent);
        }, 100);
      }

      if (this.messageType === "EMAIL_TEXT") {
        let emailContent = this.get('campaign.deliveryMethodBodyTextPlain');

        if (emailContent) {
          emailContent = emailContent.replace(/&/g, '&amp;');
          emailContent = emailContent.replace(/</g, '&lt;');
          emailContent = emailContent.replace(/>/g, '&gt;');
          emailContent = emailContent.replace(/\n/g, '<br/>');
        }

        setTimeout(function () {
          (0, _jquery.default)('#email-preview-iframe').contents().find('html').html(emailContent);
        }, 100);
      }
    },

    htmlMessageBody: Ember.computed(function () {
      let campaign = this.campaign;
      let text = '';
      text = campaign.get('deliveryMethodBodyTextHtml');
      return text;
    }),
    textMessageBody: Ember.computed(function () {
      let campaign = this.campaign;
      let text = '';
      text = campaign.get('deliveryMethodBodyTextPlain');
      return text;
    }),
    textEmailBodyParser: Ember.observer('textMessageBody', function () {
      let textMessageBody = this.textMessageBody;
      let campaign = this.campaign;
      campaign.set('deliveryMethodBodyTextPlain', textMessageBody);

      if (textMessageBody) {
        textMessageBody = textMessageBody.replace(/&/g, '&amp;');
        textMessageBody = textMessageBody.replace(/</g, '&lt;');
        textMessageBody = textMessageBody.replace(/>/g, '&gt;');
        textMessageBody = textMessageBody.replace(/\n/g, '<br/>');
      }

      setTimeout(function () {
        (0, _jquery.default)('#email-preview-iframe').contents().find('html').html(textMessageBody);
      }, 100);
    }),
    htmlEmailBodyParser: Ember.observer('htmlMessageBody', function () {
      let htmlMessageBody = this.htmlMessageBody;
      let campaign = this.campaign;
      campaign.set('deliveryMethodBodyTextHtml', htmlMessageBody);
      setTimeout(function () {
        (0, _jquery.default)('#html-email-preview-iframe').contents().find('html').html(htmlMessageBody);
      }, 100);
    }),
    actions: {
      openEmailPreviewWindow() {
        let emailContent = this.htmlMessageBody;

        if (emailContent) {
          let previewWindow = window.open(null, 'Email Preview', 'titlebar=yes,toolbar=no,status=no,location=no');
          previewWindow.document.write(emailContent);
        }
      },

      changeEmailType(type) {
        this.set('emailBodyType', type);
        this.notifyPropertyChange('messageBody');
      }

    }
  });

  _exports.default = _default;
});