define("alliance-business-suite/components/summary/s-report-size-container", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3;

  let SummarySReportSizeContainerComponent = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, (_class = class SummarySReportSizeContainerComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "bindInstance", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "height", _descriptor3, this);
    }

    initialLoad() {
      this.setHeight(); // To remove event listener after willDestroyElement which where added using bind() you just have to store listener.bind(this) in a variable.
      // Because bind() is creating a new reference each time its called.

      this.bindInstance = this.setHeight.bind(this);
      window.addEventListener("resize", this.bindInstance, {
        passive: true
      });
      setTimeout(() => {
        this.createScrollEvent();
      }, 2000);
    } // due to the occlusion for the table, need to trigger a window resize event on scroll


    createScrollEvent(e) {
      const el = document.querySelector("#report-size-container .ember-table-overflow");

      if (el) {
        el.addEventListener('scroll', e => {
          window.dispatchEvent(new Event('resize'));
        });
      }
    }

    willDestroyComponent() {
      window.removeEventListener("resize", this.bindInstance);
      window.removeEventListener("scroll", this.bindInstance);
    } // calculates the dynamic height


    setHeight() {
      let bottomOffset = 25;
      let windowHeight = window.innerHeight;
      let headerHeight = document.querySelector('.system-header').offsetHeight;
      let subMenuHeight = document.querySelector('.sub-menu').offsetHeight;
      let sectionPaddingTop = 20;
      let sectionHeader = document.querySelector('.ui-route-header').offsetHeight;
      let subControlsHeight = document.querySelector('.sub-controls-container').offsetHeight;
      let locationsExpiringSoon = this.access.locationsExpiringSoon;
      let subscriptionWarningBanner = 0;

      if (locationsExpiringSoon) {
        if (locationsExpiringSoon.length > 0) {
          subscriptionWarningBanner = 43;
        }
      }

      let final = windowHeight - headerHeight - subMenuHeight - sectionPaddingTop - sectionHeader - subControlsHeight - subscriptionWarningBanner - bottomOffset;
      this.height = final;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "bindInstance", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "height", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "initialLoad", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "initialLoad"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "willDestroyComponent", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "willDestroyComponent"), _class.prototype)), _class));
  _exports.default = SummarySReportSizeContainerComponent;
});