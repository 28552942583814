define("alliance-business-suite/routes/notifications", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "alliance-business-suite/routes/_secureRoute"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _secureRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  let NotificationsRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = class NotificationsRoute extends _secureRoute.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor2, this);
    }

    setupController(controller, model) {
      super.setupController(controller, model);
      controller.user = this.access.get('user');
      controller.subnavTabs = this.access.subnavTabs('profile');
    }

    beforeModel(transition) {
      if (!this.access.isOwnerOperator) {
        this.transitionTo('profile');
      }
    }

    model() {
      return this.store.query('alert', {
        isActive: true
      }).then(data => {
        return data.filter(item => {
          const body = item.notification?.body;
          return body && body.length > 0;
        }).sortBy("createdAt").reverse();
      });
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = NotificationsRoute;
});