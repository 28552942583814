define("alliance-business-suite/components/summary/reports/s-audit-operation", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "alliance-business-suite/utils/report-tables-util"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _reportTablesUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  let SummaryReportsSAuditOperationComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, (_class = class SummaryReportsSAuditOperationComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "brand", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor4, this);
    }

    get translatedColumns() {
      return [{
        name: 'location_machine',
        valuePath: 'name',
        width: 225,
        isFixed: 'left'
      }, {
        name: 'date_range',
        valuePath: 'dateRange',
        width: 200
      }, {
        name: 'total_cycles',
        valuePath: 'totalNumberOfMachineCycles',
        width: 125
      }, {
        name: 'rapid_advance',
        valuePath: 'totalNumberOfRapidAdvanceCycles',
        width: 125
      }, {
        name: 'total_hours',
        valuePath: 'totalOperationHours',
        width: 125
      }];
    }

    get rowsWithChildren() {
      let geoBoundaries = this.args.model.get('geoBoundaries');
      let rowData = [];
      geoBoundaries.forEach(geoBoundary => {
        let rowArray = [];
        let nameArray = [];
        let totalCoinVendedSum = 0;
        let totalPulseVendSum = 0;
        let totalCardVendSum = 0;
        let totalCoinVended = [];
        let totalPulseVend = [];
        let totalCardVend = [];
        rowData.push({
          name: _reportTablesUtil.localizeString.call(this, geoBoundary.name, 'Location', 'location'),
          children: nameArray,
          totalNumberOfMachineCycles: totalCoinVended,
          totalNumberOfRapidAdvanceCycles: totalPulseVend,
          totalOperationHours: totalCardVend
        });
        geoBoundary.rooms.forEach(room => {
          let to1talCoinVendedSum = 0;
          let to1talPulseVendSum = 0;
          let to1talCardVendSum = 0;
          let to1talCoinVended = [];
          let to1talPulseVend = [];
          let to1talCardVend = [];
          nameArray.push({
            name: room.name,
            children: rowArray,
            totalNumberOfMachineCycles: to1talCoinVended,
            totalNumberOfRapidAdvanceCycles: to1talPulseVend,
            totalOperationHours: to1talCardVend
          });
          room.machineAuditGroupTypes.forEach(machineAuditType => {
            machineAuditType.machineTypes.forEach(machineType => {
              let machineArray = [];
              let machineTypeName = `${machineAuditType.name} - ${machineType.name}`;
              let to2talCoinVendedSum = 0;
              let to2talPulseVendSum = 0;
              let to2talCardVendSum = 0;
              let to2talCoinVended = [];
              let to2talPulseVend = [];
              let to2talCardVend = [];
              rowArray.push({
                name: machineTypeName,
                machineTypeName: machineType.name,
                children: machineArray,
                totalNumberOfMachineCycles: to2talCoinVended,
                totalNumberOfRapidAdvanceCycles: to2talPulseVend,
                totalOperationHours: to2talCardVend
              });
              machineType.machines.forEach(machine => {
                let machineCycleArray = [];
                let machineVendArray = [];
                let machineMediumArray = [];

                if (this.brand.slug == 'primus') {
                  // fix the branding issue alliance-tasks/issues/2291
                  rowArray[rowArray.length - 1].name = `${machine.machineAuditType.name} - ${rowArray[rowArray.length - 1].machineTypeName}`;
                }

                if (machine.totalNumberOfMachineCycles !== 0) {
                  machineCycleArray.push(machine.totalNumberOfMachineCycles);
                  totalCoinVendedSum = (0, _reportTablesUtil.sumArray)(machineCycleArray, totalCoinVendedSum);
                  to1talCoinVendedSum = (0, _reportTablesUtil.sumArray)(machineCycleArray, to1talCoinVendedSum);
                  to2talCoinVendedSum = (0, _reportTablesUtil.sumArray)(machineCycleArray, to2talCoinVendedSum);
                }

                if (machine.totalNumberOfRapidAdvanceCycles !== 0) {
                  machineVendArray.push(machine.totalNumberOfRapidAdvanceCycles);
                  totalPulseVendSum = (0, _reportTablesUtil.sumArray)(machineVendArray, totalPulseVendSum);
                  to1talPulseVendSum = (0, _reportTablesUtil.sumArray)(machineVendArray, to1talPulseVendSum);
                  to2talPulseVendSum = (0, _reportTablesUtil.sumArray)(machineVendArray, to2talPulseVendSum);
                }

                if (machine.totalOperationHours !== 0) {
                  machineMediumArray.push(machine.totalOperationHours);
                  totalCardVendSum = (0, _reportTablesUtil.sumArray)(machineMediumArray, totalCardVendSum);
                  to1talCardVendSum = (0, _reportTablesUtil.sumArray)(machineMediumArray, to1talCardVendSum);
                  to2talCardVendSum = (0, _reportTablesUtil.sumArray)(machineMediumArray, to2talCardVendSum);
                }

                let machineName = _reportTablesUtil.machineNameDisplay.call(this, machine.machineName, machine.machineNumber);

                let machineCycles = machine.totalNumberOfMachineCycles || 0;
                let rapidAdvance = machine.totalNumberOfRapidAdvanceCycles || 0;
                let operationHours = machine.totalOperationHours || 0;
                let dateRange = "";

                if (machine.minFirstReceivedAt && machine.maxFirstReceivedAt) {
                  dateRange = `${moment(machine.minFirstReceivedAt).format(_reportTablesUtil.formatDateTimeString.call(this))} - ${moment(machine.maxFirstReceivedAt).format(_reportTablesUtil.formatDateTimeString.call(this))}`;
                }

                machineArray.push({
                  name: machineName,
                  dateRange: dateRange,
                  totalNumberOfMachineCycles: machineCycles,
                  totalNumberOfRapidAdvanceCycles: rapidAdvance,
                  totalOperationHours: operationHours
                });
              });
              to2talCoinVended.push(to2talCoinVendedSum || 0);
              to2talPulseVend.push(to2talPulseVendSum || 0);
              to2talCardVend.push(to2talCardVendSum || 0);
            });
          });
          to1talCoinVended.push(to1talCoinVendedSum || 0);
          to1talPulseVend.push(to1talPulseVendSum || 0);
          to1talCardVend.push(to1talCardVendSum || 0);
        });
        totalCoinVended.push(totalCoinVendedSum || 0);
        totalPulseVend.push(totalPulseVendSum || 0);
        totalCardVend.push(totalCardVendSum || 0);
      });
      return rowData;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "brand", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SummaryReportsSAuditOperationComponent;
});