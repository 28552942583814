define("alliance-business-suite/models/system-alert-type", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;
  const ALERT_NAMES = {
    PARTIAL_COLLECT_SUCCESSFUL: 'Partial Collect Successful',
    FULL_COLLECT_FAILED: 'Full Collect Failed',
    INITIALIZING_NETWORK_CONTROLLER: 'Initializing Network Controller',
    NETWORK_CONTROLLER_INITIALIZATION_SUCCESSFUL: 'Network Controller Initialization Successful',
    ERROR_CONNECTING_TO_NODE: 'Error Connecting To Node',
    CONNECTION_RESTORED: 'Connection Restored',
    FULL_COLLECT_SUCCESSFUL: 'Full Collect Successful',
    PARTIAL_COLLECT_FAILED: 'Partial Collect Failed',
    COLLECTION_STARTED: 'Collection Started',
    COLLECTION_FINISHED: 'Collection Finished',
    NODE_TIME_NOT_SYNCHRONIZED: 'Node Time Not Synchronized',
    NODE_TIME_SYNCHRONIZED: 'Node Time Synchronized',
    NETWORK_CONTROLLER_INITIALIZATION_FAILED: 'Network Controller Initialization Failed'
  };

  var _default = Model.extend({
    name: attr(''),
    formattedName: Ember.computed('id', {
      get() {
        return ALERT_NAMES[this.id];
      }

    }).readOnly()
  });

  _exports.default = _default;
});