define("alliance-business-suite/routes/index", ["exports", "alliance-business-suite/routes/_secureRoute"], function (_exports, _secureRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* The only purpose of this file is for ESA to properly route to login. */
  class IndexRoute extends _secureRoute.default {
    beforeModel(transition) {
      super.beforeModel(...arguments); // call the SecureRoutes default beforeModel

      if (transition.from.name == 'login.index') {
        this.transitionTo('start');
      }
    }

  }

  _exports.default = IndexRoute;
});