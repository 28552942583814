define("alliance-business-suite/initializers/devtools", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'devtools',

    initialize(app) {
      app.inject('controller', 'devtools', 'service:devtools');
      app.inject('component', 'devtools', 'service:devtools');
      app.inject('helper', 'devtools', 'service:devtools');
      app.inject('route', 'devtools', 'service:devtools');
      app.inject('template', 'devtools', 'service:devtools');
    }

  };
  _exports.default = _default;
});