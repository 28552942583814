define("alliance-business-suite/components/form-ui/coin-start-pulses", ["exports", "alliance-business-suite/mixins/init-property-names"], function (_exports, _initPropertyNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_initPropertyNames.default, {
    /*
      There are 3 ways coin start pulses can work.
       1) A single `StartPulseMode` property sets the mode to off, single, or multiple. Midas works
        this way, and the values are 0, 128, or 192.
       2) Two boolean values can determine the mode. ACAS works this way.
       3) A boolean and a number can determine the mode. Titanium works this way.
    */
    init() {
      this._super(...arguments);

      if (Ember.isNone(this.params.options)) {
        alert("Error: Missing options for coin-start-pulses.");
      }
    },

    alignElements() {
      let width = 0;
      Ember.run.schedule('afterRender', () => {
        $('.align-together-right').each(function () {
          $(this).css('width', 'auto');
        });
        $('.align-together-right').each(function () {
          let thisWidth = $(this).outerWidth();

          if (thisWidth > width) {
            width = thisWidth;
          }
        });
        $('.align-together-right').each(function () {
          $(this).css('width', width + 20);
        });
      });
    },

    machineModeType: Ember.computed('StartPulseMode', 'StartPulseEnabled', function () {
      // Determine the properties based on type detection.
      switch (true) {
        case Ember.typeOf(this.StartPulseEnabled) === "boolean" && Ember.typeOf(this.StartPulseMode) === "boolean":
          return 1;
        // ACAS

        case Ember.typeOf(this.StartPulseEnabled) === "undefined" && Ember.typeOf(this.StartPulseMode) === "number":
          return 2;
        // Midas

        case Ember.typeOf(this.StartPulseEnabled) === "boolean" && Ember.typeOf(this.StartPulseMode) === "number":
          return 3;
        // Titanium

        default:
          console.error('Unable to determine mode type for coin-start-pulses.');
          return undefined;
      }
    }),
    mode: Ember.computed('machineModeType', 'StartPulseMode', 'params.options', 'currentMode', function () {
      // return get(this.params.options.findBy('val', this.currentMode), 'key'); // off, single, multiple
      return Ember.get(this.params.options.findBy('val', this.currentMode), 'key'); // off, single, multiple
    }),
    currentMode: Ember.computed('machineModeType', 'StartPulseMode', 'StartPulseEnabled', {
      get(key) {
        let returnValue;

        switch (this.machineModeType) {
          case 1:
            // ACAS
            if (this.StartPulseEnabled === false && this.StartPulseMode === false) {
              returnValue = 0;
            }

            if (this.StartPulseEnabled === true && this.StartPulseMode === false) {
              returnValue = 1;
            }

            if (this.StartPulseEnabled === true && this.StartPulseMode === true) {
              returnValue = 2;
            }

            break;

          case 2:
            // MIDAS
            if (this.StartPulseEnabled !== undefined) {
              console.error('The coin-start-pulses component should not have StartPulseEnabled for this machine.');
            }

            returnValue = this.StartPulseMode;
            break;

          case 3:
            // TITANIUM
            if (this.StartPulseEnabled === true && this.StartPulseMode === 1) {
              returnValue = 2;
            } else if (this.StartPulseEnabled === true && this.StartPulseMode === 0) {
              returnValue = 1;
            } else {
              returnValue = 0;
            }

        }

        return returnValue;
      },

      set(key, value) {
        switch (this.machineModeType) {
          case 1:
            // ACAS...need to set two different booleans
            switch (value) {
              case 0:
                // None
                this.update(this.object, this.properties.StartPulseEnabled.key, false);
                this.update(this.object, this.properties.StartPulseMode.key, false);
                break;

              case 1:
                // Single
                this.update(this.object, this.properties.StartPulseEnabled.key, true);
                this.update(this.object, this.properties.StartPulseMode.key, false);
                break;

              case 2:
                // Multiple
                this.update(this.object, this.properties.StartPulseEnabled.key, true);
                this.update(this.object, this.properties.StartPulseMode.key, true);
                break;
            }

            break;

          case 2:
            // MIDAS...need to set a number, such as 0, 128, or 192
            this.update(this.object, this.properties.StartPulseMode.key, value);
            break;

          case 3:
            // TITAN...need to set a boolean and a number
            switch (value) {
              case 0:
                // None
                this.update(this.object, this.properties.StartPulseEnabled.key, false);
                this.update(this.object, this.properties.StartPulseMode.key, 0);
                break;

              case 1:
                // Single
                this.update(this.object, this.properties.StartPulseEnabled.key, true);
                this.update(this.object, this.properties.StartPulseMode.key, 0);
                break;

              case 2:
                // Multiple
                this.update(this.object, this.properties.StartPulseEnabled.key, true);
                this.update(this.object, this.properties.StartPulseMode.key, 1);
                break;
            }

            break;
        }

        return value;
      }

    }),
    actions: {
      changeSelected(value) {
        Ember.set(this, 'currentMode', value.val);
        console.log('changed');
        this.alignElements();
      }

    }
  });

  _exports.default = _default;
});