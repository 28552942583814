define("alliance-business-suite/components/form-ui/temperature-rate", ["exports", "alliance-business-suite/mixins/form-ui", "alliance-business-suite/utils/convert-temperature"], function (_exports, _formUi, _convertTemperature) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_formUi.default, {
    tagName: 'div',
    classNames: ['form-field', 'overflow-float', 'number-field', 'text-field', 'temperature-rate-field'],
    // These need to be gone if displayValue is custom
    intl: Ember.inject.service(),
    isCelsius: Ember.computed.alias('formSettings.isCelsius'),
    // isTemperatureRate: alias('params.isTemperatureRate'),
    step: Ember.computed('isCelsius', function () {
      return this.isCelsius ? 0.1 : 0.5;
    }),
    divideBy: 10,
    min: Ember.computed.alias('params.min'),
    max: Ember.computed.alias('params.max'),

    // Convert value and min/max to Celsius if needed
    serializeValue(rawValue) {
      // to FE
      if (rawValue === undefined) return; // 1) Divide my 10 to get the real value

      rawValue = Big(rawValue).div(10).round(1); // 2) Check limits

      if (rawValue < this.min) {
        rawValue = this.min;
      }

      if (rawValue > this.max) {
        rawValue = this.max;
      } // 3) Convert to Celsius, if necessary


      if (this.isCelsius) {
        rawValue = (0, _convertTemperature.toCRate)(rawValue);
      }

      return rawValue;
    },

    deserializeValue(value) {
      // to API
      // 1) Convert to F if needed
      if (this.isCelsius) {
        value = (0, _convertTemperature.toFRate)(value);
      } // 2) Check limits - use params.{min,max} because they are F values


      let min = this.min;
      let max = this.max;

      if (value < min) {
        value = min;
      }

      if (value > max) {
        value = max;
      } // 3) Multiply by 10 to make it an integer


      value = Big(value).times(10).round(0);
      return value;
    },

    didRender() {
      this._super(...arguments); // Make sure we're within bounds/limits

    },

    value: Ember.computed('rawValue', 'preset', 'formSettings.[]', {
      // We need to update the values if there is a change to divideBy decimal placement.
      get(key) {
        let rawValue = this.rawValue;
        let serializeValue = this.serializeValue(rawValue);
        return serializeValue;
      },

      set(key, value) {
        // Use speedChange action on change event to update data
        console.log('ERR: Setting value in temperature-rate is not supported.');
        return value;
      }

    }),
    actions: {
      valueChange(event) {
        // Add deserialization here - convert value to what the API expects
        let deserializeValue = this.deserializeValue(event.target.value);
        this.update(this.object, this.params.propertyName, deserializeValue);
        this.notifyPropertyChange('value');
        return this.serializeValue(deserializeValue);
      }

    }
  });

  _exports.default = _default;
});