define("alliance-business-suite/ember-gestures/recognizers/vertical-swipe", ["exports", "ember-gestures/recognizers/vertical-swipe"], function (_exports, _verticalSwipe) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _verticalSwipe.default;
    }
  });
});