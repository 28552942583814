define("alliance-business-suite/components/machines/modal-fetch-program", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let ComponentClass = Ember.Component.extend({
    classNames: ['machine-view'],
    ajax: Ember.inject.service(),
    access: Ember.inject.service(),
    intl: Ember.inject.service(),
    notifications: Ember.inject.service(),
    firebaseCommands: Ember.inject.service('firebase-commands'),
    machines: Ember.inject.service(),
    canSubmit: Ember.computed('name', 'description', function () {
      let name = Ember.get(this, 'name');
      let description = Ember.get(this, 'description');

      if (name) {
        return true;
      }
    }),

    didInsertElement() {
      this._super(...arguments); // I think we need a setTimeout because the modal is animated?


      setTimeout(function () {
        (0, _jquery.default)('[autofocus]').focus();
      }, 500);
      Mousetrap.bind(['esc'], (e, combo) => {
        e.preventDefault();
        e.stopPropagation();
        this.closeModal();
      });
      Mousetrap.bind(['enter'], (e, combo) => {
        e.preventDefault();
        e.stopPropagation();

        if (this['can-access'].canAccess("MACHINES_COMMAND_GET_PROGRAM")) {
          // Submit
          if (!this.isLoading && this.canSubmit) {
            this.send("fetchProgram");
          }
        } else {
          // Close
          this.closeModal();
        }
      });
    },

    destroy() {
      this._super(...arguments); // Clean up keyboard shortcuts


      Mousetrap.unbind(['esc']);
      Mousetrap.unbind(['enter']);
    },

    closeModal() {
      if (!Ember.get(this, 'isLoading')) {
        this.close();
      }
    },

    actions: {
      close() {
        this.closeModal();
      },

      fetchProgram() {
        let firebaseCommands = Ember.get(this, 'firebaseCommands');
        let notifications = Ember.get(this, 'notifications');
        let organization = Ember.get(this, 'access.accessOrganization.id');
        let intl = Ember.get(this, 'intl');
        let name = Ember.get(this, 'name');
        let description = Ember.get(this, 'description') || "";
        let machineId = Ember.get(this, 'machineId'); // Here we update things in the machines service

        let statusId = this.machines.addStatus("fetch", {
          name,
          description,
          machineId,
          organization,
          status: "init"
        });
        Ember.set(this, 'isLoading', true);
        this.ajax.request(`/machines/${machineId}/fetchProgram?raw=true`, {
          data: {
            params: {
              name,
              description,
              organization
            }
          },
          method: 'POST'
        }).then(response => {
          let fetchInProgress = intl.t('fetch_program_in_progress');
          Ember.set(this, 'isLoading', false);
          firebaseCommands.get('fetchProgramCommand')(firebaseCommands, machineId, response.id, notifications, intl, {
            programName: name,
            statusId
          });
          notifications.info(fetchInProgress, {
            autoClear: true,
            clearDuration: 5000
          });
          this.close();
        });
      }

    }
  });
  ComponentClass.reopenClass({
    positionalParams: ['model']
  });
  var _default = ComponentClass;
  _exports.default = _default;
});