define("alliance-business-suite/templates/components/table/table-cell-external-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "i5ZXoGat",
    "block": "{\"symbols\":[],\"statements\":[[11,\"a\"],[24,\"rel\",\"noopener\"],[4,[38,0],[\"click\",[32,0,[\"openExternalUrl\"]]],null],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-external-link\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"on\"]}",
    "moduleName": "alliance-business-suite/templates/components/table/table-cell-external-url.hbs"
  });

  _exports.default = _default;
});