define("alliance-business-suite/templates/components/form-ui/limit-dates-epoch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ysMwEqom",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"checkbox-field form-field overflow-float\"],[12],[2,\"\\n  \"],[10,\"label\"],[14,0,\"form-field--label inline-checkbox form-ui-element\"],[12],[2,\"\\n    \"],[8,\"input\",[[24,0,\"form-field--control\"]],[[\"@type\",\"@checked\"],[\"checkbox\",[32,0,[\"limitEnabled\"]]]],null],[2,\"\\n    \"],[1,[30,[36,1],[\"limit_dates\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,3],[[32,0,[\"limitEnabled\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"form-ui/date\",[],[[\"@object\",\"@update\",\"@params\"],[[32,0,[\"object\"]],[30,[36,0],[[32,0],\"updateStartDate\"],null],[30,[36,2],null,[[\"label\",\"propertyName\"],[[30,[36,1],[\"start_date\"],null],[32,0,[\"properties\",\"startDate\",\"key\"]]]]]]],null],[2,\"\\n  \"],[8,\"form-ui/date\",[],[[\"@object\",\"@update\",\"@params\"],[[32,0,[\"object\"]],[30,[36,0],[[32,0],\"updateEndDate\"],null],[30,[36,2],null,[[\"label\",\"propertyName\"],[[30,[36,1],[\"end_date\"],null],[32,0,[\"properties\",\"endDate\",\"key\"]]]]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[8,\"form-ui/dev-tools\",[],[[\"@outputs\"],[[30,[36,2],null,[[\"propertyName\",\"value\",\"rawValue\",\"startDate\",\"endDate\",\"limitEnabled\"],[[32,0,[\"params\",\"propertyName\"]],[32,0,[\"value\"]],[32,0,[\"rawValue\"]],[32,0,[\"startDate\"]],[32,0,[\"endDate\"]],[32,0,[\"limitEnabled\"]]]]]]],null]],\"hasEval\":false,\"upvars\":[\"action\",\"t\",\"hash\",\"liquid-if\"]}",
    "moduleName": "alliance-business-suite/templates/components/form-ui/limit-dates-epoch.hbs"
  });

  _exports.default = _default;
});