define("alliance-business-suite/components/ui/modal-welcome", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "jquery", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _jquery, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  let UiModalWelcomeComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._action, _dec7 = Ember._action, (_class = class UiModalWelcomeComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "brand", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "session", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "setInterval", _descriptor5, this);
    }

    initialLoad() {
      this.colorRandomSvg();
    }

    willDestroyModal() {
      clearInterval(this.setInterval);
      (0, _jquery.default)('.welcome-bg').fadeOut();
    }

    colorRandomSvg() {
      let washingMachines = (0, _jquery.default)('#washing-machine g');
      let machinesToChange = washingMachines;
      let i = 0;
      let activeColor = this.brand.lookupKey('brand-active-color');
      let timeOut = setInterval(() => {
        let randomMachine = Math.floor(Math.random() * machinesToChange.length);
        (0, _jquery.default)(machinesToChange[randomMachine]).css({
          fill: activeColor,
          transition: '2.0s'
        });
        (0, _jquery.default)(machinesToChange[randomMachine]).delay(3000).queue(function (next) {
          (0, _jquery.default)(this).css({
            fill: 'white',
            transition: '5.0s'
          });
          next();
        });
        i += 1;
        machinesToChange.splice(randomMachine, 1);

        if (i == 300 || machinesToChange.length == 0) {
          clearInterval(timeOut);
        }
      }, 500);
      this.setInterval = timeOut;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "brand", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "session", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setInterval", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "initialLoad", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "initialLoad"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "willDestroyModal", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "willDestroyModal"), _class.prototype)), _class));
  _exports.default = UiModalWelcomeComponent;
});