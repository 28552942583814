define("alliance-business-suite/templates/components/standard-time-format-string", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "du223/C6",
    "block": "{\"symbols\":[],\"statements\":[[8,\"time-field\",[],[[\"@value\",\"@on-change\",\"@hour12\"],[[34,0],[30,[36,1],[[32,0],\"updateTime\"],null],[34,2]]],null]],\"hasEval\":false,\"upvars\":[\"timeString\",\"action\",\"userDateTimeFormat\"]}",
    "moduleName": "alliance-business-suite/templates/components/standard-time-format-string.hbs"
  });

  _exports.default = _default;
});