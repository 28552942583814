define("alliance-business-suite/components/machines/programs/special-vend/midas-wx", ["exports", "alliance-business-suite/mixins/machines/programs/midas-options", "alliance-business-suite/mixins/machines/programs/midas-property-names", "alliance-business-suite/helpers/find-by-dropdown-with-custom"], function (_exports, _midasOptions, _midasPropertyNames, _findByDropdownWithCustom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_midasOptions.default, _midasPropertyNames.default, {
    numberFormatting: Ember.computed('machineProgram.settings.Program.Display.NumericSeparator', 'selectOptions.NumericSeparator', function () {
      let value = this.machineProgram.settings.Program.Display.NumericSeparator;
      let option = this.selectOptions.NumericSeparator.findBy('val', value);
      let thousandsSymbol;
      if (option.decimalSymbol == "") thousandsSymbol = "";
      if (option.decimalSymbol == ".") thousandsSymbol = ",";
      if (option.decimalSymbol == ",") thousandsSymbol = ".";
      let output = {
        decimals: option.decimalPlaces,
        divideBy: option.divideBy,
        decimalSymbol: option.decimalSymbol,
        thousandsSymbol
      };
      return output;
    }),
    currencySymbol: Ember.computed('machineProgram.settings.Program.Price.Misc.Currency1.Symbol', 'selectOptions.CurrencyType', function () {
      let selectedSymbol = this.machineProgram.settings.Program.Price.Misc.Currency1.Symbol;
      let options = this.selectOptions.CurrencyType;
      let option = (0, _findByDropdownWithCustom.findByDropDown)([options, "val", selectedSymbol]);
      return option.abbr;
    }),
    currencySymbolPosition: Ember.computed('machineProgram.settings.Program.Price.Misc.Currency1.PlaceAfter', function () {
      return this.machineProgram.settings.Program.Price.Misc.Currency1.PlaceAfter === true ? "after" : "before";
    }),
    formConfig: Ember.computed("propertyNames.specialVend_WX", function () {
      return {
        columns: [{
          sections: [{
            title: "special_vend",
            controls: [{
              type: "special-vend-container",
              machineProgram: this.attrs.machineProgram,
              componentName: "machines/programs/special-vend/modal-midas-wx",
              properties: Ember.get(this, 'propertyNames.specialVend_WX'),
              uniqueIndex: [0, 1, 2, 3, 4, 5, 6, 7]
            }]
          }]
        }]
      };
    })
  });

  _exports.default = _default;
});