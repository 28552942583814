define("alliance-business-suite/components/marketing/campaigns/campaign-edit-form", ["exports", "jquery", "alliance-business-suite/mixins/crm"], function (_exports, _jquery, _crm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CAMPAIGN_TYPES = ['AQUISITION', 'REENGAGEMENT'];
  const FILTERS = [{
    id: 'DEADBEAT_USERS',
    text: 'deadbeat_users',
    description: 'deadbeat_users.description'
  }, {
    id: 'LAST_ACTIVE',
    text: 'last_visit',
    description: 'last_visit.description'
  }, {
    id: 'SERVICE_TYPE',
    text: 'usage_type',
    description: 'usage_type.description'
  }, {
    id: 'PREFERRED_DAY',
    text: 'preferred_day_of_week',
    description: 'preferred_day_of_week.description'
  }, {
    id: 'MACHINE_TYPE',
    text: 'machine_type',
    description: 'machine_type.description'
  }, {
    id: 'WASH_DRY_FOLD',
    text: 'avg_visit_spend_wash_dry_fold',
    description: 'avg_visit_spend_wash_dry_fold.description'
  }, {
    id: 'GOODS',
    text: 'avg_visit_spend_goods',
    description: 'avg_visit_spend_goods.description'
  }, {
    id: 'MACHINE',
    text: 'avg_visit_spend_self_service',
    description: 'avg_visit_spend_self_service.description'
  }, {
    id: 'LIFETIME_SPEND',
    text: 'lifetime_spend',
    description: 'lifetime_spend.description'
  }];

  var _default = Ember.Component.extend(_crm.default, {
    ajax: Ember.inject.service(),
    access: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    selectedFilters: [],
    filterComponents: [],
    checkboxOptions: {
      'three_state': false,
      'cascade': 'down+undetermined',
      'load_checked_fix': true
    },

    init() {
      this._super(...arguments);

      let self = this;
      let selectedFilters, rooms;
      let roomIds = []; // Reset to empty

      this.set('selectedFilters', []);
      this.set('filterComponents', []);
      let filterComponents = this.filterComponents;

      if (this.get('campaign.rooms.length')) {
        rooms = this.get('campaign.rooms');
        rooms.forEach(function (room) {
          roomIds.push(room.get('id'));
        });
        this.set('preSelectedGeos', roomIds);
      } else if (localStorage.getItem('newCampaignLocations')) {
        roomIds = JSON.parse(localStorage.getItem('newCampaignLocations'));
        rooms = [];
        roomIds.forEach(function (roomId) {
          let room = self.get('store').peekRecord('room', roomId);

          if (room) {
            rooms.push(room);
          }
        });
        this.set('campaign.rooms', rooms);
        this.set('preSelectedGeos', roomIds);
        this.setCrmPreviewProperties({
          type: "rooms",
          content: roomIds
        });
      }

      if (this.get('campaign.filter') && JSON.parse(this.get('campaign.filter')).Params.length) {
        selectedFilters = JSON.parse(this.get('campaign.filter')).Params;
      } else if (localStorage.getItem('newCampaignFilters')) {
        let newCampaignFilters = JSON.parse(localStorage.getItem('newCampaignFilters')); // select which campaign type based on passed filters

        if (newCampaignFilters.Params && newCampaignFilters.Params.length) {
          selectedFilters = newCampaignFilters.Params;
          this.set('campaign.campaignTypeLookupKey', 'REENGAGEMENT');
        } else {
          this.set('campaign.campaignTypeLookupKey', 'AQUISITION');
        }
      }

      if (selectedFilters) {
        let filters = {
          size: 0
        };
        selectedFilters.forEach(function (filter) {
          if (filter.Name == 'LAST_VISIT_INTERVAL' || filter.Name == 'LAST_VISIT_START_DATE' || filter.Name == 'LAST_VISIT_END_DATE' || filter.Name == 'LAST_VISIT_QUALIFIER') {
            if (filters.LAST_ACTIVE) {
              filters.LAST_ACTIVE.options.push({
                name: filter.Name,
                value: filter.Value
              });
            } else {
              filters.LAST_ACTIVE = {
                'filter': FILTERS[1],
                'options': [{
                  name: filter.Name,
                  value: filter.Value
                }]
              };
              filters.size++;
              filterComponents.push({
                component: 'marketing/campaigns/campaign-filter'
              });
            }
          } else if (filter.Name == 'SERVICE_TYPE') {
            if (filters[filter.Name]) {
              if (filters.SERVICE_TYPE.options.indexOf(filter.Value) === -1) {
                filters.SERVICE_TYPE.options.push(filter.Value);
              }
            } else {
              filters.SERVICE_TYPE = {
                'filter': FILTERS[2],
                'options': [filter.Value]
              };
              filters.size++;
              filterComponents.push({
                component: 'marketing/campaigns/campaign-filter'
              });
            }
          } else if (filter.Name == 'DAY_OF_WEEK') {
            if (filters[filter.Name]) {
              if (filters.DAY_OF_WEEK.options.indexOf(filter.Value) === -1) {
                filters.DAY_OF_WEEK.options.push(filter.Value);
              }
            } else {
              filters.DAY_OF_WEEK = {
                'filter': FILTERS[3],
                'options': [filter.Value]
              };
              filters.size++;
              filterComponents.push({
                component: 'marketing/campaigns/campaign-filter'
              });
            }
          } else if (filter.Name == 'MACHINE_TYPE') {
            if (filters[filter.Name]) {
              if (filters.MACHINE_TYPE.options.indexOf(filter.Value) === -1) {
                filters.MACHINE_TYPE.options.push(filter.Value);
              }
            } else {
              filters.MACHINE_TYPE = {
                'filter': FILTERS[4],
                'options': [filter.Value]
              };
              filters.size++;
              filterComponents.push({
                component: 'marketing/campaigns/campaign-filter'
              });
            }
          } else if (filter.Name == 'AVG_VEND_TYPE') {
            let min;
            let max;
            selectedFilters.forEach(selectedFilter => {
              if (selectedFilter.Name == filter.Name && selectedFilter.Value == filter.Value) {
                min = false;
                max = false;
              } else if (selectedFilter.Name == 'AVG_VEND_MIN' && !min) {
                min = selectedFilter.Value;
              } else if (selectedFilter.Name == 'AVG_VEND_MAX' && !max) {
                max = selectedFilter.Value;
              }
            });
            FILTERS.forEach(FILTER => {
              if (FILTER.id == filter.Value) {
                filters[filter.Value] = {
                  'filter': FILTER,
                  'options': {
                    'AVG_VEND_MIN': min,
                    'AVG_VEND_MAX': max
                  }
                };
              }
            });
            filters.size++;
            filterComponents.push({
              component: 'marketing/campaigns/campaign-filter'
            });
          } else if (filter.Name == 'LIFETIME_SPEND_MIN') {
            let min = selectedFilters[selectedFilters.findIndex(f => {
              return f.Name == 'LIFETIME_SPEND_MIN';
            })].Value;
            let max = selectedFilters[selectedFilters.findIndex(f => {
              return f.Name == 'LIFETIME_SPEND_MAX';
            })].Value;
            FILTERS.forEach(FILTER => {
              if (FILTER.id == 'LIFETIME_SPEND') {
                filters['LIFETIME_SPEND'] = {
                  'filter': FILTER,
                  'options': {
                    'LIFETIME_SPEND_MIN': min,
                    'LIFETIME_SPEND_MAX': max
                  }
                };
              }
            });
            filters.size++;
            filterComponents.push({
              component: 'marketing/campaigns/campaign-filter'
            });
          } else if (filter.Name == 'DEADBEAT_USERS') {
            filters.DEADBEAT_USERS = {
              'filter': FILTERS[0],
              'options': [filter.Value]
            };
            filters.size++;
            filterComponents.push({
              component: 'marketing/campaigns/campaign-filter'
            });
          }
        });
        this.set('applySelectedFilters', filters); // set the filters to the crm mixin

        this.setCrmPreviewProperties({
          type: 'filters',
          content: selectedFilters
        });
      } else {
        filterComponents.push({
          component: 'marketing/campaigns/campaign-filter'
        });
      }
    },

    filters: Ember.computed(function () {
      let filterOptions = FILTERS;

      if (this.get('access.isRetail') === false) {
        filterOptions = filterOptions.filter(item => item.id !== 'WASH_DRY_FOLD' && item.id !== 'GOODS');
      }

      return JSON.parse(JSON.stringify(filterOptions));
    }),
    campaignTypeOptions: Ember.computed(function () {
      return CAMPAIGN_TYPES;
    }),
    organization: Ember.computed(function () {
      let organizationId = Ember.get(this, 'access.accessOrganization.id');
      return this.store.peekRecord('organization', organizationId);
    }),
    hasChanged: Ember.observer('campaign.name', 'campaign.campaignTypeLookupKey', function () {
      if (this.get('campaign.hasDirtyAttributes')) {
        this.hasUnsavedChanges(true);
      }
    }),
    preSelectedGeoBoundaryIds: Ember.computed('preSelectedGeos', function () {
      return this.preSelectedGeos;
    }),
    selectedGeoBoundaryIds: Ember.computed('selectedLocations', 'preSelectedGeos', function () {
      let self = this;
      let roomIds = [];
      let rooms = [];
      let selectedLocations = this.selectedLocations;
      let preSelectedGeos = this.preSelectedGeos;

      if (selectedLocations) {
        selectedLocations.forEach(function (location) {
          if (location.original && location.original.roomId) {
            roomIds.push(location.original.roomId);
            rooms.push(self.get('store').peekRecord('room', location.original.roomId));
          }
        });
        this.set('campaign.rooms', rooms);
        this.hasUnsavedChanges(true);
      } else if (preSelectedGeos) {
        roomIds = preSelectedGeos;
      }

      return roomIds;
    }),

    updateSelectedFilters(update, triggerTrackingCountCall) {
      // console.log('updateSelectedFilters...')
      // using classic "set" to update
      this.set('selectedFilters', update); // using classic "set" to update

      if (update.length === 1 && update[0].filter.id === 'DEADBEAT_USERS') {
        this.set('filterComponents', this.filterComponents.filter((filter, idx) => {
          return idx == update[0].index;
        }));
        this.filterChange(update);
      }

      if (triggerTrackingCountCall) {
        this.setCrmPreviewProperties({
          type: "filters",
          content: update
        });
      }
    },

    // this is defined twice? is this deprecated?
    // _filterChange() {
    //   console.error('filterChange!!')
    //   let filters = this.get('selectedFilters');
    //   let self = this;
    //   let payload = { 'Params': [] };
    //   let part1 = false;
    //   let part2 = false;
    //
    //   filters.forEach(function(filter) {
    //     if (filter.options) {
    //       // Needed a way to disable analytics button.
    //       filter.options.forEach(function(option) {
    //         if (option.Name === "LAST_VISIT_INTERVAL") {
    //           if (option.Value === "custom") {
    //             part1 = true;
    //             part2 = true;
    //             self.filterButtonDisabled = true;
    //           }
    //           else {
    //             self.filterButtonDisabled = false;
    //           }
    //         }
    //
    //         if (option.Name === "LAST_VISIT_START_DATE" ) {
    //           if (!moment(option.Value).isValid()) {
    //             part1 = true;
    //           }
    //           else {
    //             part1 = false;
    //           }
    //         }
    //
    //         if (option.Name === "LAST_VISIT_END_DATE" ) {
    //           if (!moment(option.Value).isValid()) {
    //             part2 = true;
    //           }
    //           else {
    //             part2 = false;
    //           }
    //         }
    //
    //         if (part1 === false && part2 === false) {
    //           self.filterButtonDisabled = false;
    //         }
    //         else {
    //           self.filterButtonDisabled = true;
    //         }
    //
    //         payload.Params.push(option);
    //       });
    //     }
    //   });
    //
    //   if (this.filter !== JSON.stringify(payload)) {
    //     this.filter = JSON.stringify(payload);
    //   }
    // },
    filterChange(overrideSelectedFilters) {
      // console.log('2 filterChange...')
      const self = this.context ? this.context : this; // horrible, octane refactor needed

      let filters = overrideSelectedFilters ?? this.selectedFilters;
      let payload = {
        'Params': []
      }; // let machineTypesPushed = []; // fix an issue where the params for machine type would be duplicated
      // fix for DEADBEAT_USERS persisting for the trackingCount call
      // removes it if it somehow sticks around

      if (filters.length > 1 && filters[0].filter.id === 'DEADBEAT_USERS') {
        filters = filters.filter(option => {
          return option.filter.id !== 'DEADBEAT_USERS';
        });
      } // console.log('>>> filters', filters)


      filters.forEach(function (filter) {
        if (filter.options) {
          filter.options.forEach(function (option) {
            payload.Params.push(option); // if (option.Name !== "MACHINE_TYPE") {
            //   payload.Params.push(option);
            // } else if (option.Name === "MACHINE_TYPE" && machineTypesPushed.indexOf(option.Value) === -1) {
            //   payload.Params.push(option);
            //   machineTypesPushed.push(option.Value)
            // }
          });
        }
      });

      if (this.get('campaign.filter') !== JSON.stringify(payload) && (!this.get('applySelectedFilters.size') || this.get('applySelectedFilters.size') <= 0)) {
        this.set('campaign.filter', JSON.stringify(payload));
        self.hasUnsavedChanges(true);
      }
    },

    addFilter() {
      let filterComponents = this.get('filterComponents');
      filterComponents.addObject({
        component: 'marketing/campaigns/campaign-filter'
      });
      this.context.filterChange();
    },

    removeFilter(index) {
      let filterComponents = this.get('filterComponents');
      filterComponents.removeObject(filterComponents[index]);
      this.context.filterChange();
    },

    actions: {
      toggleLocationModal() {
        this.toggleProperty('showLocationModal'); // only call when modal is closed

        if (!this.showLocationModal) {
          this.setCrmPreviewProperties({
            type: 'rooms',
            content: this.campaign.rooms
          });
        }
      },

      selectCampaignType(type) {
        if (!this.get('campaign.id')) {
          this.set('campaign.campaignTypeLookupKey', type);
        }
      }

    }
  });

  _exports.default = _default;
});