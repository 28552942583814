define("alliance-business-suite/utils/pad", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(value, count) {
    if (!count) {
      count = 2;
    }

    return value.toString().padStart(count, 0);
  }
});