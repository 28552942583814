define("alliance-business-suite/components/accounts/a-payment-method-list-block", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  let AccountsAPaymentMethodListBlockComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._action, _dec8 = Ember._action, (_class = class AccountsAPaymentMethodListBlockComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "ajax", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "isLoading", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "paymentMethods", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "showRefundModal", _descriptor6, this);
    }

    initialLoad() {
      let userId = this.args.userId;
      let organizationId = this.access.accessOrganization.get('id');
      this.isLoading = true;
      this.ajax.request(`/users/${userId}/accounts?organizationId=${organizationId}&raw=true`, {
        method: 'GET'
      }).then(response => {
        if (!response || !response[0] || !response[0].id) {
          this.paymentMethods = [];
          this.isLoading = false;
          return;
        }

        this.ajax.request(`/accounts/${response[0].id}/paymentMethods`, {
          method: 'GET'
        }).then(response => {
          if (!this.isDestroyed) {
            this.paymentMethods = response.data.map(obj => {
              let retVal = obj.attributes;
              retVal.modelName = 'payment-method';
              return retVal;
            });
            this.isLoading = false;
          }
        });
      }); // return this._super(...arguments);
    }

    get canRefund() {
      let {
        store,
        access
      } = this;
      let organizationId = access.get('accessOrganization.id');
      let org = store.peekRecord('organization', organizationId);

      if (org.get('paymentGateway.lookupKey') == 'FIRSTPAY') {
        return true;
      }

      return false;
    }

    toggleRefundModal() {
      this.showRefundModal = !this.showRefundModal;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isLoading", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "paymentMethods", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "showRefundModal", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "initialLoad", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "initialLoad"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "toggleRefundModal", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "toggleRefundModal"), _class.prototype)), _class));
  _exports.default = AccountsAPaymentMethodListBlockComponent;
});