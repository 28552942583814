define("alliance-business-suite/templates/components/form-ui/led-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DDdSibWJ",
    "block": "{\"symbols\":[],\"statements\":[[8,\"input\",[[24,0,\"led7\"],[16,\"disabled\",[34,2]]],[[\"@type\",\"@value\",\"@key-up\"],[\"text\",[34,0],[30,[36,1],[[32,0],\"validateInput\"],null]]],null],[2,\"\\n\\n\"],[8,\"form-ui/dev-tools\",[],[[\"@outputs\"],[[30,[36,7],null,[[\"type\",\"propertyName\",\"value\",\"rawValue\"],[[35,6],[35,5,[\"propertyName\"]],[35,4],[35,3]]]]]],null]],\"hasEval\":false,\"upvars\":[\"displayValue\",\"action\",\"isDisabled\",\"rawValue\",\"value\",\"params\",\"type\",\"hash\"]}",
    "moduleName": "alliance-business-suite/templates/components/form-ui/led-text.hbs"
  });

  _exports.default = _default;
});