define("alliance-business-suite/components/machines/m-list-grid", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class MachinesMListGridComponent extends _component.default {
    get sortedMachines() {
      return this.args.model.sort((a, b) => {
        return a.sortableMachineName - b.sortableMachineName;
      });
    }

  }

  _exports.default = MachinesMListGridComponent;
});