define("alliance-business-suite/components/ui/modal-refund", ["exports", "alliance-business-suite/mixins/account-balance"], function (_exports, _accountBalance) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_accountBalance.default, {
    ajax: Ember.inject.service(),
    access: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),

    didInsertElement() {
      this._super(...arguments);

      this.fetchFundsRoots();
    },

    canSubmit: Ember.computed('refundAmount', 'selectedFundsRoot', 'selectedPaymentMethod', 'isLoading', function () {
      let {
        refundAmount,
        selectedPaymentMethod,
        selectedFundsRoot,
        isLoading,
        currencySubunit
      } = this;

      if (!isLoading && !isNaN(refundAmount) && refundAmount > 0 && selectedPaymentMethod && selectedFundsRoot) {
        return true;
      }
    }),

    amountCanBeRefunded(amount) {
      let {
        currencySubunit,
        currentBalance
      } = this;

      if (amount > currentBalance * currencySubunit) {
        this.notifications.error(this.intl.t('refund_amount_too_large'), {
          clearDuration: 5000,
          autoClear: true
        });
        return;
      } else {
        return true;
      }
    },

    actions: {
      close() {
        if (!Ember.get(this, 'isLoading')) {
          this.close();
        }
      },

      apply() {
        let {
          refundAmount,
          selectedPaymentMethod,
          selectedFundsRoot,
          currencySubunit,
          currentBalance,
          accountId
        } = this;

        if (!confirm(`${this.intl.t('confirm_refund')}`)) {
          return;
        }

        let amount = refundAmount * currencySubunit;
        let geoBoundaryId = selectedFundsRoot.get('id');
        let paymentNonce = selectedPaymentMethod.paymentNonce;

        if (!this.amountCanBeRefunded(amount)) {
          return;
        }

        this.set('isLoading', true);
        this.ajax.request(`/accounts/${accountId}/refund`, {
          method: 'POST',
          data: {
            amount,
            geoBoundaryId,
            paymentNonce
          }
        }).then(response => {
          this.notifications.success(this.intl.t('refund_transfer_success'), {
            clearDuration: 5000,
            autoClear: true
          });
          this.close();
        }).catch(e => {
          this.notifications.success(this.intl.t('refund_error'), {
            clearDuration: 5000,
            autoClear: true
          });
          this.set('isLoading', false);
        });
      }

    }
  });

  _exports.default = _default;
});