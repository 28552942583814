define("alliance-business-suite/models/order-setting-type", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    hasMany,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    intl: Ember.inject.service(),
    modelType: 'order-setting-type',
    description: attr('string'),
    systemDefault: attr('boolean', {
      defaultValue: false
    }),
    type: Ember.computed({
      get() {
        return this.intl.t('order_setting_type');
      }

    }).readOnly()
  });

  _exports.default = _default;
});