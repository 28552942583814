define("alliance-business-suite/adapters/order-setting-type", ["exports", "alliance-business-suite/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    session: Ember.inject.service(),

    buildURL(modelName, id, snapshot, requestType, query) {
      let url = this._super('', null, snapshot, requestType, query);

      let organizationId = Ember.get(this, 'session.data.orgId');
      let orderSettingId = Ember.get(snapshot, 'adapterOptions.serviceId');

      switch (requestType) {
        case 'findAll':
          // url += `/rooms/${roomId}/orderSettings`;
          url += `/organizations/${organizationId}/orderSettingTypes`;
          return url;

        case 'createRecord':
          url += `/organizations/${organizationId}/orderSettingTypes`;
          return url;

        case 'deleteRecord':
          url += `/organizations/${organizationId}/orderSettingTypes/${orderSettingId}`;
          return url;

        case 'updateRecord':
          url += `/organizations/${organizationId}/orderSettingTypes`;
          return url;
      }

      return url;
    }

  });

  _exports.default = _default;
});