define("alliance-business-suite/components/rooms/modal-sign-up-bonus", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11;

  let RoomsModalSignUpBonusComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember.computed('args.room'), _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, (_class = class RoomsModalSignUpBonusComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "ajax", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "notifications", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "isActiveBonus", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "initialAmount", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "hasLoadedRedemptions", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "timesRedeemed", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "isLoading", _descriptor10, this);
      (0, _initializerDefineProperty2.default)(this, "selectedSignUpBonusTrigger", _descriptor11, this);
      (0, _defineProperty2.default)(this, "bonusRedemptionOptions", [{
        id: 'ACCT_CREATE',
        text: 'redemption-on-verified-user'
      }, {
        id: 'CHARGE',
        text: 'redemption-on-charge'
      }]);
    }

    // get selectedSignUpBonusTrigger() {
    //   const currentTrigger = this.args.room.get('signupBonusTrigger');
    //
    //   if (!currentTrigger || currentTrigger === 'ACCT_CREATE') {
    //     return this.bonusRedemptionOptions[0]
    //   } else {
    //     this.bonusRedemptionOptions[1]
    //   }
    // }
    // set selectedSignUpBonusTrigger(val) {
    //   console.log('SETTING!', val)
    //   return val
    // }
    get currencySymbol() {
      return this.access.currencyDenomination;
    }

    get currencyDecimals() {
      if (this.access.currencySubunit === 1) {
        return 0;
      } else {
        return 2;
      }
    }

    get bonusAmount() {
      let amount = this.args.room.signupBonusAmount;

      if (amount) {
        return amount;
      }

      return 0;
    }

    set bonusAmount(value) {
      return value;
    }

    fetchRedemptions() {
      let room = this.args.room;
      this.hasLoadedRedemptions = false;
      this.ajax.request(`/rooms/${room.id}/bonus?raw=true`, {
        method: 'GET'
      }).then(response => {
        this.timesRedeemed = response.timesRedeemed;
        this.selectedSignUpBonusTrigger = !response.triggerEvent || response.triggerEvent === 'ACCT_CREATE' ? this.bonusRedemptionOptions[0] : this.bonusRedemptionOptions[1];
        this.hasLoadedRedemptions = true;
      });
    }

    initialSetup() {
      let amount = this.bonusAmount;
      this.initialAmount = amount;

      if (amount) {
        this.isActiveBonus = true;
      }

      this.fetchRedemptions();
    }

    close() {
      this.args.room.rollbackAttributes();
      this.args.close();
    }

    removeBonus() {
      if (!confirm(`${this.intl.t('confirm_remove_bonus')}`)) {
        return;
      }

      this.bonusAmount = 0;
      this.save(true);
    }

    submit() {
      let amount = this.bonusAmount;

      if (this.initialAmount != amount && amount != 0) {
        if (!confirm(`${this.intl.t('confirm_bonus_change_will_reset')}`)) {
          return;
        }
      }

      this.save();
    }

    updateBonusRedemptionType(val) {
      const idx = val.id === 'ACCT_CREATE' ? 0 : 1;
      this.selectedSignUpBonusTrigger = this.bonusRedemptionOptions[idx];
    }

    save(isRemoval) {
      if (this.access.isDemo) {
        alert('Demo mode restricted.');
        return;
      }

      let amount = this.bonusAmount;
      let model = this.args.room;

      if (model.maxRedemptions <= 0) {
        model.maxRedemptions = null;
      }

      model.signupBonusTrigger = this.selectedSignUpBonusTrigger.id;
      model.signupBonusAmount = parseInt(amount);

      if (isRemoval) {
        model.signupBonusAmount = null;
      }

      this.isLoading = true;
      model.save().then(() => {
        this.isLoading = false;
        this.notifications.info(this.intl.t('room_successfully_updated'), {
          autoClear: true,
          clearDuration: 2000
        });
        this.args.close();
      }).catch(e => {
        this.isLoading = false;
        this.notifications.error(this.intl.t('save_failed'));
      });
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "notifications", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isActiveBonus", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "initialAmount", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "hasLoadedRedemptions", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "timesRedeemed", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isLoading", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedSignUpBonusTrigger", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "bonusAmount", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "bonusAmount"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "initialSetup", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "initialSetup"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "close", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "removeBonus", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "removeBonus"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "submit", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "submit"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "updateBonusRedemptionType", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "updateBonusRedemptionType"), _class.prototype)), _class));
  _exports.default = RoomsModalSignUpBonusComponent;
});