define("alliance-business-suite/components/ui/location-tree", ["exports", "alliance-business-suite/utils/demo-mode"], function (_exports, _demoMode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    classNames: ['location-tree-container'],
    store: Ember.inject.service(),
    brush: Ember.inject.service(),
    machines: Ember.inject.service(),
    access: Ember.inject.service(),
    // selectedTreeNodes: A(),
    jstreeActionReceiver: null,
    jstreeSelectedNodes: Ember.A(),
    searchTerm: '',
    sortedSelectedNodes: Ember.computed.sort('jstreeSelectedNodes', function (a, b) {
      if (a.text > b.text) {
        return 1;
      } else if (a.text < b.text) {
        return -1;
      } else {
        return 0;
      }
    }),
    formattedData: Ember.computed('model', 'filterMachineType', 'flattenChildren', 'compactView', 'machineProgramTypeName', function () {
      var _this = this;

      /**
       * The following params may be passed in to this component:
       *     checkboxOptions
       *     compactView
       *     disableAllNodes
       *     disableRooms
       *     disableTransitionLinks
       *     enableAllFundsRootCheckboxes - Used by Rewards to display enabled checkboxes for all funds root geoBoundaries.
       *     eventDidBecomeReady
       *     filterByMachineAuditGroupType
       *     filterMachineType
       *     flattenChildren
       *     fundsRestrictionOnlyCheckbox
       *     fundsRootsAsLinks
       *     hasCheckboxSupport
       *     hideDisabledRoomCheckbox
       *     isExpanded
       *     loadModalEdit
       *     machineProgramTypeName
       *     machineProgramTypeId
       *     model - geoBoundary data.
       *     preSelectedNodes
       *     roomsAsLinks
       *     selectedTreeNodes - Tree nodes to initially display as selected.
       *     showMachines - Show machine data in the treeview.
       *     checkboxOnlyRooms - only rooms are checkbox (used in CRM)
       */
      let buildNode = (parentNode, dataArr) => {
        parentNode.get('geoBoundaries').forEach(node => {
          let nodeObject = {
            geoBoundaryId: node.get('id'),
            text: `${node.get('description')}`,
            icon: 'geoboundary-icon',
            hasFundsRestriction: false,
            isFundsRootClickable: false,
            children: [],
            state: {
              opened: this.isExpanded,
              disabled: this.disableAllNodes,
              selected: null
            },
            a_attr: {}
          }; // select the checkboxes for those that should be on loading

          if (this.preSelectedNodes) {
            this.preSelectedNodes.forEach(id => {
              if (node.get('id') == id) {
                nodeObject.state.selected = true;
              }
            });
          } // turn on checkbox support only for the highlevel funds root


          if (this.fundsRestrictionOnlyCheckbox) {
            if (!node.get('fundsRestriction')) {
              nodeObject.state.disabled = true;
            } else {
              nodeObject.icon = 'funds-root';
              nodeObject.hasFundsRestriction = true;
            }
          }

          if (this.fundsRootsAsLinks) {
            if (node.get('fundsRestriction')) {
              nodeObject.isFundsRootClickable = true; // isFundsRootClickable means this is a funds root and we want a hyperlink

              nodeObject.a_attr['data-edit-text'] = 'true'; // Note this is a string
            }
          }

          if (this.checkboxOnlyRooms) {
            nodeObject.state.disabled = true;
          }

          let rooms = node.get('rooms');

          if (!Ember.isEmpty(rooms)) {
            rooms.forEach(room => {
              let roomObject = {
                icon: 'room-icon',
                text: `${room.get('roomName')}`,
                isRoom: true,
                roomId: room.get('id'),
                children: [],
                a_attr: {
                  class: null,
                  'data-isLink': this.roomsAsLinks ? 'true' : null
                },
                state: {
                  opened: this.isExpanded,
                  disabled: this.disableAllNodes
                }
              };

              if (this.access.isDemo) {
                _demoMode.updateRoomNames.call(this);

                _demoMode.updateGeoNames.call(this);

                if (roomObject.text === 'Speed Queen Laundry - Little York') {
                  roomObject.text = 'Demo Lancester Ave';
                }
              }

              if (this.fundsRestrictionOnlyCheckbox || this.disableRooms) {
                roomObject.state.disabled = true;

                if (this.hideDisabledRoomCheckbox) {
                  roomObject.a_attr.class = 'hide-checkbox';
                }
              } // modified off of "machine-display-name" helper, used to modify the names in machine program send


              let machineDisplayNameFormatter = function (machineName) {
                let machineNumber = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '000';
                let isDryer = arguments.length > 2 ? arguments[2] : undefined;
                let machineType = arguments.length > 3 ? arguments[3] : undefined;
                let displayValue = machineNumber.replace(/^[0|\D]*/, '');
                let typeName = machineType.get('typeName');
                let machineTypeId = machineType.get('id');

                if (machineName && machineName.length < 8) {
                  displayValue = machineName;
                }

                if (machineTypeId != 900) {
                  if (isDryer === true || isDryer === "dryer") {
                    displayValue = `${_this.intl.t('dryer')} ${displayValue}`;
                  } else {
                    displayValue = `${_this.intl.t('washer')} ${displayValue}`;
                  }
                } else {
                  displayValue = `${_this.intl.t('machine')} ${displayValue}`; // machineTypeId 900 is a generic combo
                }

                if (typeName) {
                  displayValue += ` <small class="muted">${typeName}</small>`;
                }

                if (machineTypeId !== _this.machineProgramTypeId) {
                  displayValue += ` <span style="color:orange">${_this.intl.t('size_mis_match')}</span>`;
                }

                return displayValue;
              };

              let machines = room.get('machines'); // if preSelectedNodes = 'rooms', then mark as selected those room nodes

              if (this.preSelectedNodes && this.preSelectedNodesType == 'room') {
                let preSelectedNodes = this.preSelectedNodes;

                if (preSelectedNodes.indexOf(room.get('id')) != -1) {
                  roomObject.state.selected = true;
                }
              }

              if (this.showMachines && !Ember.isEmpty(machines)) {
                // console.groupCollapsed('>>> machines'); // eslint-disable-line no-console
                machines.forEach(machine => {
                  // console.log('>>> machine', machine); // eslint-disable-line no-console
                  let {
                    machineName,
                    machineNumber,
                    isDryer,
                    machineType
                  } = machine;
                  let machineObject = {
                    icon: 'machine-icon',
                    text: machineDisplayNameFormatter(machineName, machineNumber, isDryer, machineType),
                    machineNumber: machineNumber,
                    ordinal: isDryer ? 1 : 0,
                    isMachine: true,
                    machineId: machine.get('id')
                  }; // Check if we need to filter the results by machine type

                  if (this.filterMachineType) {
                    let machineId = parseInt(machineType.get('id')); // if it does not match the program filter, it should not be added...make sure that the machine matches the machine audit type (ex QUANTUM_GOLD)

                    if (machine.get('machineAuditGroupType.id') == this.filterByMachineAuditGroupType) {
                      if (this.filterMachineType.indexOf(machineId) != -1) {
                        roomObject.children.push(machineObject);
                      }
                    }
                  } else {
                    roomObject.children.push(machineObject);
                  }
                }); // console.groupEnd('>>> machines'); // eslint-disable-line no-console
              } // if filtering by machine, and there are no children, don't show the node at all


              if (this.filterMachineType) {
                if (roomObject.children.length > 0) {
                  nodeObject.children.push(roomObject);
                }
              } else {
                nodeObject.children.push(roomObject);
              } // show only rooms that are CRM enabled


              if (this.isCrmOnly && !room.get('isCrm')) {
                roomObject.state.disabled = true;
              }
            });
          } // Now repeat the same thing recursively


          buildNode(node, nodeObject.children); // Now that we're gone down that branch, if filtering by machine and there are no children, don't show the node

          if (this.filterMachineType) {
            if (nodeObject.children.length > 0) {
              dataArr.push(nodeObject);
            }
          } else {
            dataArr.push(nodeObject);
          }
        });
      }; // Start at the top and crawl through the geoBoundary structure. Store the results in `data`


      let data = [];
      buildNode(this.model, data);

      if (this.flattenChildren) {
        // Remove nodes without children
        let dataFlat = [];
        data.forEach(dataNode => {
          if (dataNode.children.length > 0) {
            dataNode.children.forEach(child => {
              dataFlat.push(child);
            });
          }
        });
        data = dataFlat;
      }

      let alphabetizeWholeTree = tree => {
        if (!Ember.isArray(tree)) {
          console.error('location-tree attempted to sort an object that is not enumerable'); // eslint-disable-line no-console

          return tree;
        } // a special sort is needed for machines


        if (tree[0] && tree[0].isMachine) {
          return sortMachines(tree);
        }

        return tree.sort((a, b) => {
          a = a.text.toLowerCase();
          b = b.text.toLowerCase();
          return a === b ? 0 : a > b ? 1 : -1;
        }).map(node => {
          if (node.children) {
            node.children = alphabetizeWholeTree(node.children);
          }

          return node;
        });
      }; // we need a special sort for machines because machines can have custom names
      // which makes sorting can create weird results ex 1, 10, 11, 12, 2, 20, 3, etc.


      let sortMachines = tree => {
        return tree.sort(function (a, b) {
          if (a.machineNumber > b.machineNumber) return 1;
          if (a.machineNumber < b.machineNumber) return -1;
          return 0;
        });
      }; // Collapse tree nodes when the geoBoundary has only 1 room in it, to make the UI simpler


      let collapseSingles = tree => {
        tree.forEach((node, index) => {
          if (node.children.length == 1 && node.children[0].isRoom && !node.isFundsRootClickable) {
            tree[index] = node.children[0];
          } else if (node.children.length > 0) {
            collapseSingles(node.children);
          }
        });
      };

      if (this.compactView && !this.enableAllFundsRootCheckboxes) {
        collapseSingles(data);
      }

      return alphabetizeWholeTree(data);
    }),
    emptyTree: Ember.computed('formattedData', function () {
      return Ember.get(this, 'formattedData.length') == 0;
    }),
    plugins: Ember.computed('hasCheckboxSupport', function () {
      if (this.hasCheckboxSupport) {
        return 'checkbox, wholerow, types';
      } else {
        return 'wholerow, types';
      }
    }),
    themes: {
      'name': 'default',
      'responsive': true
    },
    checkboxOptions: {
      'keep_selected_style': false
    },
    searchOptions: {
      'show_only_matches': true
    },
    typesOptions: {
      'room': {
        'icon': 'icon-room'
      },
      'geoBoundary': {
        'icon': 'icon-geoboundary'
      }
    },
    actions: {
      didChange(e) {
        if (this.callback) {
          this.callback(e);
        } // 'load_checked_fix' because checkbox plugin causes issues with checking only what preSelectedNodes says to check


        if (e.action === 'ready' && this.checkboxOptions && this.checkboxOptions.load_checked_fix) {
          let items = e.instance._model.data;

          for (let key in items) {
            if (items[key].original && items[key].original.geoBoundaryId && !items[key].original.state.selected && items[key].state.selected) {
              this.jstreeActionReceiver.send('deselectNode', items[key]);
            }
          }
        }
      },

      didLoad() {
        if (this.eventDidBecomeReady) {
          this.eventDidBecomeReady();
        }
      },

      didCheckNode() {
        if (this.eventDidCheckNode) {
          this.eventDidCheckNode();
        }
      },

      didSelectNode(e
      /* , data*/
      ) {
        if (this.eventDidSelectNode) {
          this.eventDidSelectNode();
        }

        let {
          isRoom,
          roomId,
          isMachine,
          machineId,
          isFundsRootClickable,
          geoBoundaryId
        } = e.original;
        let hasCheckboxSupport = this.hasCheckboxSupport;

        if (isFundsRootClickable) {
          // let fundsRoot = get(this, 'store').findRecord('geoBoundary', geoBoundaryId);
          // get(this, 'loadModalEdit')(e);
          // get(this, 'brush').transitionTo('geoBoundary', geoBoundary, 'show');
          this.loadModalEdit(e);
        }

        if (!this.disableTransitionLinks) {
          if (isRoom) {
            // let roomId = e.original.roomId;
            let room = this.store.peekRecord('room', roomId);
            this.brush.transitionTo('room', room, 'show');
          }

          if (isMachine && !hasCheckboxSupport) {
            let machine = this.store.peekRecord('machine', machineId);
            this.brush.transitionTo('machine', machine, 'show');
          }
        }
      },

      handleTreeDidBecomeReady() {
        this.set('treeReady', true);
      },

      toggleCompact() {
        this.toggleProperty('compactView');
      }

    }
  });

  _exports.default = _default;
});