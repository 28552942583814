define("alliance-business-suite/components/summary/reports/s-rewards-earned", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "alliance-business-suite/utils/currency-format-util", "alliance-business-suite/utils/report-tables-util"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _currencyFormatUtil, _reportTablesUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  let SummaryReportsSRewardsEarnedComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, (_class = class SummaryReportsSRewardsEarnedComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor3, this);
    }

    get translatedColumns() {
      let geoBoundaries = this.args.model.get('geoBoundaries');
      let promotionGeoBoundaries = this.args.model.get('promotionGeoBoundaries');
      let columns = [{
        name: 'empty_string',
        valuePath: 'name',
        width: 250,
        isFixed: 'left',
        subcolumns: [{
          name: 'report_location_month_week_day',
          valuePath: 'name',
          width: 250,
          isFixed: 'left'
        }]
      }, {
        name: 'empty_string',
        textAlign: 'center',
        subcolumns: [{
          name: 'Date',
          valuePath: 'transactionDate',
          width: 150
        }, {
          name: 'userName',
          valuePath: 'userName',
          width: 175
        }]
      }];

      if (geoBoundaries) {
        columns.push({
          name: 'rewards_earned_report',
          textAlign: 'center',
          subcolumns: [{
            name: 'points_converted',
            valuePath: 'pointsConverted',
            width: 125
          }, {
            name: 'currency_redeemed',
            valuePath: 'currencyRedeemed',
            width: 125
          }]
        });
      }

      if (promotionGeoBoundaries) {
        columns.push({
          name: 'promotions_earned_report',
          textAlign: 'center',
          subcolumns: [{
            name: 'rewards_type',
            valuePath: 'rewardsType',
            width: 125
          }, {
            name: 'promo_code',
            valuePath: 'promotionCode',
            width: 125
          }, {
            name: 'rewards_credit_earned',
            valuePath: 'rewardsCreditEarned',
            width: 125
          }]
        });
      }

      return columns;
    }

    get rowsWithChildren() {
      let {
        model
      } = this.args;
      let geoBoundaries = model.geoBoundaries;
      let promotionGeoBoundaries = model.promotionGeoBoundaries;
      let rowData = [];
      let rewardsCurrencyEarnedTotalSum = 0;
      let rewardsCurrencyEarnedTotals = [];
      let rewardsPointsEarnedTotalSum = 0;
      let rewardsPointsEarnedTotals = [];
      let rewardsGeo = [];

      if (geoBoundaries) {
        rewardsGeo.push({
          name: this.intl.t('rewards_earned'),
          pointsConverted: rewardsPointsEarnedTotals,
          currencyRedeemed: rewardsCurrencyEarnedTotals,
          children: rowData
        });
        geoBoundaries.forEach(geoBoundary => {
          let nameArray = [];
          let totalAmountDepositedSum = 0;
          let pointsConvertedSum = 0;
          let totalAmountDeposited = [];
          let totalPointsConverted = [];
          rowData.push({
            name: _reportTablesUtil.localizeString.call(this, geoBoundary.name, 'Location', 'location'),
            children: nameArray,
            pointsConverted: totalPointsConverted,
            currencyRedeemed: totalAmountDeposited
          });
          geoBoundary.rooms.forEach(room => {
            let totalAmountDepositedSum1 = 0;
            let pointsConvertedSum1 = 0;
            let totalAmountDeposited1 = [];
            let totalPointsConverted1 = [];
            let monthArray = [];
            nameArray.push({
              name: room.name,
              children: monthArray,
              pointsConverted: totalPointsConverted1,
              currencyRedeemed: totalAmountDeposited1
            });
            room.months.forEach(month => {
              let weekArray = [];
              let totalAmountDepositedSum2 = 0;
              let totalAmountDeposited2 = [];
              let pointsConvertedSum2 = 0;
              let totalPointsConverted2 = [];
              monthArray.push({
                name: month.name,
                children: weekArray,
                pointsConverted: totalPointsConverted2,
                currencyRedeemed: totalAmountDeposited2
              });
              month.weeks.forEach(week => {
                let dayArray = [];
                let totalAmountDepositedSum3 = 0;
                let totalAmountDeposited3 = [];
                let pointsConvertedSum3 = 0;
                let totalPointsConverted3 = [];
                weekArray.push({
                  name: week.name,
                  children: dayArray,
                  pointsConverted: totalPointsConverted3,
                  currencyRedeemed: totalAmountDeposited3
                });
                week.days.forEach(day => {
                  let stationArray = [];
                  let totalAmountDepositedSum4 = 0;
                  let totalAmountDeposited4 = [];
                  let pointsConvertedSum4 = 0;
                  let totalPointsConverted4 = [];
                  dayArray.push({
                    name: day.name,
                    children: stationArray,
                    pointsConverted: totalPointsConverted4,
                    currencyRedeemed: totalAmountDeposited4
                  });
                  day.rewardsEarned.forEach(reward => {
                    let machineVendDArray = [];
                    let machineVendEArray = [];
                    let transDate = "";

                    if (reward.transactionDate) {
                      transDate = moment(reward.transactionDate).format(`${_reportTablesUtil.formatDateTimeString.call(this)}`);
                    }

                    if (reward.currencyRedeemed !== 0) {
                      machineVendDArray.push(reward.currencyRedeemed);
                      totalAmountDepositedSum = (0, _reportTablesUtil.sumArray)(machineVendDArray, totalAmountDepositedSum);
                      totalAmountDepositedSum1 = (0, _reportTablesUtil.sumArray)(machineVendDArray, totalAmountDepositedSum1);
                      totalAmountDepositedSum2 = (0, _reportTablesUtil.sumArray)(machineVendDArray, totalAmountDepositedSum2);
                      totalAmountDepositedSum3 = (0, _reportTablesUtil.sumArray)(machineVendDArray, totalAmountDepositedSum3);
                      totalAmountDepositedSum4 = (0, _reportTablesUtil.sumArray)(machineVendDArray, totalAmountDepositedSum4);
                      rewardsCurrencyEarnedTotalSum = (0, _reportTablesUtil.sumArray)(machineVendDArray, rewardsCurrencyEarnedTotalSum);
                    }

                    if (reward.pointsConverted !== 0) {
                      machineVendEArray.push(reward.pointsConverted);
                      pointsConvertedSum = (0, _reportTablesUtil.sumArray)(machineVendEArray, pointsConvertedSum);
                      pointsConvertedSum1 = (0, _reportTablesUtil.sumArray)(machineVendEArray, pointsConvertedSum1);
                      pointsConvertedSum2 = (0, _reportTablesUtil.sumArray)(machineVendEArray, pointsConvertedSum2);
                      pointsConvertedSum3 = (0, _reportTablesUtil.sumArray)(machineVendEArray, pointsConvertedSum3);
                      pointsConvertedSum4 = (0, _reportTablesUtil.sumArray)(machineVendEArray, pointsConvertedSum4);
                      rewardsPointsEarnedTotalSum = (0, _reportTablesUtil.sumArray)(machineVendEArray, rewardsPointsEarnedTotalSum);
                    }

                    let redeemedCurrency = _currencyFormatUtil.formatCurrencyString.call(this, reward.currencyRedeemed || 0);

                    let points = reward.pointsConverted || 0;
                    stationArray.push({
                      userName: reward.userName,
                      transactionDate: transDate,
                      pointsConverted: points,
                      currencyRedeemed: redeemedCurrency
                    });
                  });
                  totalPointsConverted4.push(pointsConvertedSum4 || 0);
                  totalAmountDeposited4.push(_currencyFormatUtil.formatCurrencyString.call(this, totalAmountDepositedSum4 || 0));
                });
                totalPointsConverted3.push(pointsConvertedSum3 || 0);
                totalAmountDeposited3.push(_currencyFormatUtil.formatCurrencyString.call(this, totalAmountDepositedSum3 || 0));
              });
              totalPointsConverted2.push(pointsConvertedSum2 || 0);
              totalAmountDeposited2.push(_currencyFormatUtil.formatCurrencyString.call(this, totalAmountDepositedSum2 || 0));
            });
            totalPointsConverted1.push(pointsConvertedSum1 || 0);
            totalAmountDeposited1.push(_currencyFormatUtil.formatCurrencyString.call(this, totalAmountDepositedSum1 || 0));
          });
          totalPointsConverted.push(pointsConvertedSum || 0);
          totalAmountDeposited.push(_currencyFormatUtil.formatCurrencyString.call(this, totalAmountDepositedSum || 0));
        });
        rewardsPointsEarnedTotals.push(rewardsPointsEarnedTotalSum || 0);
        rewardsCurrencyEarnedTotals.push(_currencyFormatUtil.formatCurrencyString.call(this, rewardsCurrencyEarnedTotalSum || 0));
      }

      if (promotionGeoBoundaries) {
        let promotionsEarnedData = this.getPromotionsObject();
        rewardsGeo.push(promotionsEarnedData);
      }

      return rewardsGeo;
    }

    getPromotionsObject() {
      let {
        model
      } = this.args;
      let geoBoundaries = model.promotionGeoBoundaries;
      let rowData = [];
      let promoCreditEarnedTotalSum = 0;
      let promoCreditEarnedTotals = [];
      let promoObject = {
        name: this.intl.t('promotions_earned'),
        rewardsCreditEarned: promoCreditEarnedTotals,
        children: rowData
      };
      geoBoundaries.forEach(geoBoundary => {
        let monthArray = [];
        let rewardsCreditEarnedSum = 0;
        let rewardsCreditTotal = [];
        rowData.push({
          name: _reportTablesUtil.localizeString.call(this, geoBoundary.name, 'Location', 'location'),
          rewardsCreditEarned: rewardsCreditTotal,
          children: monthArray
        });
        geoBoundary.months.forEach(month => {
          let weekArray = [];
          let rewardsCreditEarnedSum1 = 0;
          let rewardsCreditTotal1 = [];
          monthArray.push({
            name: month.name,
            rewardsCreditEarned: rewardsCreditTotal1,
            children: weekArray
          });
          month.weeks.forEach(week => {
            let dayArray = [];
            let rewardsCreditEarnedSum2 = 0;
            let rewardsCreditTotal2 = [];
            weekArray.push({
              name: week.name,
              rewardsCreditEarned: rewardsCreditTotal2,
              children: dayArray
            });
            week.days.forEach(day => {
              let promoArray = [];
              let rewardsCreditEarnedSum3 = 0;
              let rewardsCreditTotal3 = [];
              dayArray.push({
                name: day.name,
                rewardsCreditEarned: rewardsCreditTotal3,
                children: promoArray
              });
              day.rewardsEarned.forEach(reward => {
                let transDate = "";
                let rewardsCreditEarnedArray = [];

                if (reward.transactionDate) {
                  transDate = moment(reward.transactionDate).format(`${_reportTablesUtil.formatDateTimeString.call(this)}`);
                }

                if (reward.rewardsCreditEarned !== 0) {
                  rewardsCreditEarnedArray.push(reward.rewardsCreditEarned);
                  rewardsCreditEarnedSum = (0, _reportTablesUtil.sumArray)(rewardsCreditEarnedArray, rewardsCreditEarnedSum);
                  rewardsCreditEarnedSum1 = (0, _reportTablesUtil.sumArray)(rewardsCreditEarnedArray, rewardsCreditEarnedSum1);
                  rewardsCreditEarnedSum2 = (0, _reportTablesUtil.sumArray)(rewardsCreditEarnedArray, rewardsCreditEarnedSum2);
                  rewardsCreditEarnedSum3 = (0, _reportTablesUtil.sumArray)(rewardsCreditEarnedArray, rewardsCreditEarnedSum3);
                  promoCreditEarnedTotalSum = (0, _reportTablesUtil.sumArray)(rewardsCreditEarnedArray, promoCreditEarnedTotalSum);
                }

                promoArray.push({
                  userName: reward.userName,
                  transactionDate: transDate,
                  rewardsType: this.intl.t(`${reward.rewardsType}`),
                  promotionCode: reward.promotionCode,
                  rewardsCreditEarned: _currencyFormatUtil.formatCurrencyString.call(this, reward.rewardsCreditEarned || 0)
                });
              });
              rewardsCreditTotal3.push(_currencyFormatUtil.formatCurrencyString.call(this, rewardsCreditEarnedSum3 || 0));
            });
            rewardsCreditTotal2.push(_currencyFormatUtil.formatCurrencyString.call(this, rewardsCreditEarnedSum2 || 0));
          });
          rewardsCreditTotal1.push(_currencyFormatUtil.formatCurrencyString.call(this, rewardsCreditEarnedSum1 || 0));
        });
        rewardsCreditTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, rewardsCreditEarnedSum || 0));
      });
      promoCreditEarnedTotals.push(_currencyFormatUtil.formatCurrencyString.call(this, promoCreditEarnedTotalSum || 0));
      return promoObject;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SummaryReportsSRewardsEarnedComponent;
});