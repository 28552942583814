define("alliance-business-suite/validations/messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // app/validations/messages.js
  var _default = {
    inclusion: 'validation.inclusion',
    exclusion: 'validation.exclusion',
    invalid: 'validation.invalid',
    confirmation: 'validation.confirmation',
    accepted: 'validation.accepted',
    empty: 'validation.empty',
    blank: 'validation.blank',
    present: 'validation.present',
    collection: 'validation.collection',
    singular: 'validation.singular',
    tooLong: 'validation.tooLong',
    tooShort: 'validation.tooShort',
    between: 'validation.between',
    before: 'validation.before',
    onOrBefore: 'validation.onOrBefore',
    after: 'validation.after',
    onOrAfter: 'validation.onOrAfter',
    wrongDateFormat: 'validation.wrongDateFormat',
    wrongLength: 'validation.wrongLength',
    notANumber: 'validation.notANumber',
    notAnInteger: 'validation.notAnInteger',
    greaterThan: 'validation.greaterThan',
    greaterThanOrEqualTo: 'validation.greaterThanOrEqualTo',
    equalTo: 'validation.equalTo',
    lessThan: 'validation.lessThan',
    lessThanOrEqualTo: 'validation.lessThanOrEqualTo',
    otherThan: 'validation.otherThan',
    odd: 'validation.odd',
    even: 'validation.even',
    positive: 'validation.positive',
    multipleOf: 'validation.multipleOf',
    date: 'validation.date',
    email: 'validation.email',
    phone: 'validation.phone',
    url: 'validation.url'
  };
  _exports.default = _default;
});