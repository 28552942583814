define("alliance-business-suite/components/form-ui/steps/cooldown-options", ["exports", "alliance-business-suite/mixins/init-property-names"], function (_exports, _initPropertyNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_initPropertyNames.default, {
    rateLabelKey: Ember.computed('formSettings.isCelsius', function () {
      return Ember.get(this, 'formSettings.isCelsius') ? "c_per_minute" : "f_per_minute";
    }),
    hasCoolDownType: Ember.computed('CoolDownType', function () {
      return this.CoolDownType !== undefined;
    })
  });

  _exports.default = _default;
});