define("alliance-business-suite/templates/components/machines/programs/water-guardian/midas-wx", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dmFv3kj7",
    "block": "{\"symbols\":[\"ui\"],\"statements\":[[8,\"form-ui\",[],[[\"@object\",\"@model\",\"@formDisplay\",\"@selectOptions\",\"@currencySymbol\",\"@currencySymbolPosition\",\"@decimals\",\"@currencyDivideBy\",\"@thousandsSymbol\",\"@decimalSymbol\",\"@isCelsius\",\"@coinValue\"],[[34,0,[\"settings\"]],[34,0],\"compact\",[34,1],[34,2,[\"currencySymbol\"]],[34,2,[\"currencySymbolPosition\"]],[34,2,[\"decimals\"]],[34,2,[\"divideBy\"]],[34,2,[\"thousandsSymbol\"]],[34,2,[\"decimalSymbol\"]],[34,2,[\"isCelsius\"]],[34,2,[\"coinValue\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[8,[32,1,[\"render-form\"]],[],[[\"@formConfig\"],[[32,0,[\"formConfig\"]]]],null],[2,\"\\n\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"machineProgram\",\"selectOptions\",\"globalSettings\"]}",
    "moduleName": "alliance-business-suite/templates/components/machines/programs/water-guardian/midas-wx.hbs"
  });

  _exports.default = _default;
});