define("alliance-business-suite/templates/components/machines/m-current-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0+QHYSxi",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[\"m-current-user \",[30,[36,3],[[35,0],\"has-user\"],null]]]],[12],[2,\"\\n\"],[6,[37,3],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"i\"],[14,0,\"fa fa-circle-o-notch fa-spin\"],[14,5,\"opacity: .3\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[35,0]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,1],[\"app_user\"],null]],[2,\"\\n\\n\"],[6,[37,3],[[30,[36,2],[[32,0,[\"access\",\"isDemo\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"ember-tooltip\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[1,[34,0]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"i\"],[14,\"aria-hidden\",\"true\"],[14,0,\"fa fa-ban\"],[14,5,\"opacity: .3\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"currentUser\",\"t\",\"not\",\"if\",\"isLoading\"]}",
    "moduleName": "alliance-business-suite/templates/components/machines/m-current-user.hbs"
  });

  _exports.default = _default;
});