define("alliance-business-suite/components/can-access-all", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    canAccessAll: Ember.computed('params.[]', function () {
      let area = this.params[0];
      let access = this['can-access'];
      return access.canAccessAll(area);
    })
  }).reopenClass({
    positionalParams: 'params'
  });

  _exports.default = _default;
});