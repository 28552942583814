define("alliance-business-suite/components/form-ui/duration-field/states/unfocused", ["exports", "alliance-business-suite/components/form-ui/duration-field/utils/state"], function (_exports, _state) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _state.default.extend({
    focusIn(manager) {
      if (this.get("manager.showHours")) {
        manager.transitionTo("focused.hours");
      } else {
        manager.transitionTo("focused.minutes");
      }
    },

    // it's possible to receive events after losing focus, due to runloop shenanigans
    // just swallow them as no-ops
    refocus() {},

    focusOut() {},

    up() {},

    down() {},

    left() {},

    right() {},

    key() {}

  });

  _exports.default = _default;
});