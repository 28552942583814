define("alliance-business-suite/adapters/machine", ["exports", "alliance-business-suite/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForQuery(query) {
      if (query.from === 'organization') {
        let url = `${this.host}/organizations/${query.id}/machines`;
        delete query.from;
        delete query.id;
        return url;
      }

      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});