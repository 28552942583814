define("alliance-business-suite/components/inventory/item-form", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'itemDescription': [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    'itemCatalogName': [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    'selectedItemCategory': [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })]
  });
  const IMAGE_PLACEHOLDERS = ['placeholder-item-blue.png', 'placeholder-item-forest.png', 'placeholder-item-gray.png', 'placeholder-item-green.png', 'placeholder-item-orange.png', 'placeholder-item-peach.png', 'placeholder-item-pink.png', 'placeholder-item-purple.png', 'placeholder-item-red.png', 'placeholder-item-turqouise.png'];

  var _default = Ember.Component.extend(Validations, {
    access: Ember.inject.service(),
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    notifications: Ember.inject.service(),
    classNames: [],
    itemTypes: ['LAUNDRY_ITEM', 'RETAIL_ITEM', 'GARMENT'],
    uploadFile: null,
    acceptedFileTypes: ['png', 'jpeg'],
    sortedCategories: Ember.computed('categories', function () {
      return this.categories.sortBy('name');
    }),
    selectedItemType: Ember.computed(function () {
      return this.itemTypes.get(0);
    }),
    selectedItemCategory: Ember.computed(function () {
      return this.get('item.itemCategory');
    }),
    itemDescription: Ember.computed('item', function () {
      return this.get('item.description');
    }),
    itemCatalogName: Ember.computed('item', function () {
      return this.get('item.catalogName');
    }),
    itemControlNumber: Ember.computed('item', function () {
      return this.get('item.controlNumber');
    }),
    previewUrl: Ember.computed('item', function () {
      return this.get('item.imageUrl');
    }),
    organizationName: Ember.computed(function () {
      return this.store.peekRecord('organization', this.get('access.accessOrganization.id')).get('organizationName');
    }),

    async uploadImage(file) {
      return await this.ajax.request(`/inventory/assetUrl?fileName=${file.get('blob.name')}`, {
        method: 'GET'
      }).then(response => {
        this.set('imageKey', `inventory/${response.fileName}`); // needs to use reponse url

        return file.uploadBinary(response.signedUrl, {
          method: 'PUT'
        });
      });
    },

    actions: {
      close() {
        this.close();
      },

      uploadPreview(file) {
        if (this.acceptedFileTypes.indexOf(file.extension) === -1) {
          alert(`${this.intl.t('only_file_types')} ${this.acceptedFileTypes.join(', ')}`);
          return false;
        }

        let blobUrl = window.URL.createObjectURL(file.blob);

        if (blobUrl) {
          this.set('uploadFile', file);
          this.set('previewUrl', blobUrl);
        }
      },

      async addItem() {
        let user = this.store.peekRecord('user', this.get('access.accessUser.id'));
        let organization = this.store.peekRecord('organization', Ember.get(this, 'session.data.orgId'));
        let file = this.uploadFile;
        this.set('isSaving', true);
        this.set('isProcessing', true);

        if (file) {
          await this.uploadImage(file);
        } else {
          let img = IMAGE_PLACEHOLDERS[Math.floor(Math.random() * 10)];
          this.set('imageKey', `pos/${img}`);
        }

        let newItem = this.store.createRecord('item');
        newItem.set('organization', organization);
        newItem.set('description', this.itemDescription);
        newItem.set('catalogName', this.itemCatalogName);
        newItem.set('controlNumber', this.itemControlNumber);
        newItem.set('itemCategory', this.selectedItemCategory);
        newItem.set('imageKey', this.imageKey);
        newItem.set('itemTypeLookupKey', this.selectedItemType);
        newItem.set('createdBy', user);
        newItem.save().then(() => {
          this.set('isSaving', false);
          this.set('isProcessing', false);
          this.send('close');
        }, () => {
          // failed
          this.set('isSaving', false);
          this.set('isProcessing', false);
        });
      },

      async saveItem() {
        let item = this.item;
        let file = this.uploadFile;
        this.set('isSaving', true);
        this.set('isProcessing', true);

        if (file) {
          await this.uploadImage(file);
        }

        item.set('description', this.itemDescription);
        item.set('catalogName', this.itemCatalogName);
        item.set('controlNumber', this.itemControlNumber);
        item.set('itemCategory', this.selectedItemCategory);
        item.set('imageKey', this.imageKey);
        item.set('itemTypeLookupKey', this.selectedItemType);
        item.save().then(() => {
          this.set('isSaving', false);
          this.set('isProcessing', false);
          this.send('close');
        }, () => {
          // failed
          this.set('isSaving', false);
          this.set('isProcessing', false);
        });
      },

      async destroyItem(item) {
        if (confirm(this.intl.t('delete_product_confirmation'))) {
          item.destroyRecord().then(() => {
            this.notifications.success(this.intl.t('item_deleted'), {
              autoClear: true,
              clearDuration: 4000
            });
            this.send('close');
            this.router.transitionTo('retail.list');
          }, () => {
            // failed
            this.set('isSaving', false);
            this.set('isProcessing', false);
          });
        }
      }

    }
  });

  _exports.default = _default;
});