define("alliance-business-suite/components/machines/machines-list-table", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  let MachinesMachinesListTableComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, (_class = class MachinesMachinesListTableComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "session", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "devtools", _descriptor4, this);
    }

    get columns() {
      let sortByProperty = 'networkNode';
      const orgId = this.access.get('accessOrganization.id'); // gateways stop reporting networkNode numbers higher than 250
      // so we need to rely on a special sorting for rooms with more than 250 machines
      // this sort is based on a new prop computed off the machine names

      if (this.args.room && this.args.room.get('machines').length > 250) {
        sortByProperty = 'sortableMachineName';
      }

      let tableColumns = [{
        title: 'id',
        propertyName: 'id',
        mayBeHidden: true,
        isHidden: !this.devtools.get('trayEnabled')
      }, {
        sortPrecedence: 1,
        sortDirection: 'asc',
        propertyName: sortByProperty,
        title: this.intl.t('machine'),
        component: 'table/machines/table-cell-machine-name-pill'
      }, {
        title: this.intl.t('type'),
        component: 'table/machines/table-cell-machine-type'
      }, {
        title: this.intl.t('status'),
        propertyName: 'machineCurrentStatus.statusId',
        component: 'table/machines/table-cell-machine-status'
      }, {
        title: this.intl.t('price'),
        propertyName: 'machineCurrentStatus.remainingVend',
        component: 'table/machines/table-cell-machine-price',
        isHidden: this.access.get('isOpl') // }, {
        //   title: this.intl.t('current_user'),
        //   component: 'table/machines/table-cell-machine-current-user'

      }]; // currently only showing it for this org due to concerns on taxing the system
      // we can bring it back for all after consulting BE

      if (orgId === '196902') {
        tableColumns.push({
          title: this.intl.t('current_user'),
          component: 'table/machines/table-cell-machine-current-user'
        });
      }

      return tableColumns;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "devtools", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = MachinesMachinesListTableComponent;
});