define("alliance-business-suite/templates/components/search-results", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "85sxlMAW",
    "block": "{\"symbols\":[\"panel\",\"result\",\"@total\",\"&default\",\"@title\",\"@results\"],\"statements\":[[6,[37,6],[[32,6,[\"length\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"ui/section-panel\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"header\"]],[],[[\"@title\",\"@subtitle\"],[[30,[36,0],[[32,5]],null],[30,[36,1],[[30,[36,0],[\"showing\"],null],\" \",[32,6,[\"length\"]],\" \",[30,[36,0],[\"of\"],null],\" \",[32,3],\" \",[30,[36,0],[\"results\"],null]],null]]],null],[2,\"\\n\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[30,[36,2],[\"createdAt\",\"lastName\",[32,6]],null]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[18,4,[[32,2]]],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[32,6,[\"length\"]],[32,3]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"ui/cell-data\",[],[[\"@click\"],[[32,0,[\"loadAll\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"small\"],[12],[2,\"\\n          \"],[1,[30,[36,0],[\"load_all\"],null]],[2,\" \"],[1,[32,3]],[2,\" \"],[1,[30,[36,0],[\"results\"],null]],[2,\"\\n        \"],[13],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"concat\",\"sort-by\",\"-track-array\",\"each\",\"lt\",\"if\"]}",
    "moduleName": "alliance-business-suite/templates/components/search-results.hbs"
  });

  _exports.default = _default;
});