define("alliance-business-suite/templates/components/machines/programs/pricing/midas-ezopl-m3-tum", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "htw8gfEB",
    "block": "{\"symbols\":[\"ui\"],\"statements\":[[8,\"form-ui\",[],[[\"@object\",\"@model\",\"@machineProgram\",\"@formDisplay\",\"@selectOptions\",\"@limitWidth\",\"@currencySymbol\",\"@currencySymbolPosition\",\"@decimals\",\"@currencyDivideBy\",\"@thousandsSymbol\",\"@decimalSymbol\",\"@isCelsius\"],[[32,0,[\"machineProgram\",\"settings\"]],[32,0,[\"machineProgram\"]],[32,0,[\"machineProgram\"]],\"compact\",[32,0,[\"selectOptions\"]],true,[32,0,[\"globalSettings\",\"currencySymbol\"]],[32,0,[\"globalSettings\",\"currencySymbolPosition\"]],[32,0,[\"globalSettings\",\"decimals\"]],[32,0,[\"globalSettings\",\"divideBy\"]],[32,0,[\"globalSettings\",\"thousandsSymbol\"]],[32,0,[\"globalSettings\",\"decimalSymbol\"]],[32,0,[\"globalSettings\",\"isCelsius\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[8,[32,1,[\"render-form\"]],[],[[\"@formConfig\"],[[32,0,[\"formConfig\"]]]],null],[2,\"\\n\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "alliance-business-suite/templates/components/machines/programs/pricing/midas-ezopl-m3-tum.hbs"
  });

  _exports.default = _default;
});