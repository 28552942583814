define("alliance-business-suite/routes/_secureRoute", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  let SecureRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, (_class = class SecureRoute extends Ember.Route {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "brush", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "canAccess", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "session", _descriptor4, this);
    }

    afterModel(model, transition) {
      // super(...arguments);
      if (this.hideBrush) {
        this.brush.hide();
      }

      if (this.showBrush) {
        this.brush.show();
      }

      if (this.disableBrush) {
        this.brush.disable();
      } else {
        this.brush.enable();
      }
    }

    beforeModel(transition) {
      this.session.requireAuthentication(transition, 'login');

      this._canAccessGate(transition);
    }

    _canAccessGate(transition) {
      if (transition.targetName !== 'not-found') {
        let notFoundController = this.controllerFor('not-found');
        notFoundController.previousTransition = transition;
      }

      let securityDebugging = false;
      let bodyElem = document.body; // Redirect if landing on top-level tab

      let findTargetRoute = this.subnavTabs;
      let url = transition.intent.url || transition.intent.name;
      let len = 0; // fix for strange behavior when coming from the machine brush (aka queryParam source = sidebar)
      // to view "machine activity report"

      if (transition.targetName === 'summary.reports' && transition.intent.queryParams && transition.intent.queryParams.source && transition.intent.queryParams.source === 'sidebar') {
        url = 'summary.reports/foo';
      }

      if (url) {
        len = url.split('/').reduce((val, item) => {
          return item.length !== 0 ? val + 1 : val;
        }, 0);
      }

      if (findTargetRoute && len == 1) {
        for (let x = 0; x < findTargetRoute.length; x++) {
          let p = findTargetRoute[x];

          if (this.canAccess.canAccessAny(p.allowAccess)) {
            this.transitionTo(p.link);
            break;
          }
        }
      }

      if (this.allowAccess) {
        // we have something to check
        if (!this.canAccess.canAccessAny(this.allowAccess)) {
          bodyElem.classList.remove('route-access-true');
          bodyElem.classList.add('route-access-false');

          if (securityDebugging === true) {
            console.log('DEBUG ON: BLOCKED ACCESS TO', transition.targetName); // eslint-disable-line no-console

            bodyElem.classList.add('route-blocked');
          } else {
            console.log('BLOCKED ACCESS TO', transition.targetName); // eslint-disable-line no-console

            return this.transitionTo('not-found');
          }
        } else {
          // can access route
          bodyElem.classList.add('route-access-true');
          bodyElem.classList.remove('route-blocked');
          bodyElem.classList.remove('route-access-false');
        }
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "brush", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "canAccess", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "session", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SecureRoute;
});