define("alliance-business-suite/adapters/application", ["exports", "alliance-business-suite/config/environment", "ember-data", "ember-inflector"], function (_exports, _environment, _emberData, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    JSONAPIAdapter,
    ServerError
  } = _emberData.default;

  var _default = JSONAPIAdapter.extend({
    access: Ember.inject.service(),
    session: Ember.inject.service(),
    host: _environment.default.APP.ALLIANCE_API_URL,
    headers: Ember.computed('session.data.orgId', 'session.data.authenticated.token', function () {
      let organizationId = Ember.get(this, 'session.data.orgId');
      let {
        token
      } = this.get('session.data.authenticated');
      return {
        'x-api-key': _environment.default.APP.ALLIANCE_API_KEY,
        'content-type': 'application/json',
        'app': _environment.default.APP.APP_IDENTIFIER,
        'alliancels-organization-id': organizationId,
        'alliancels-auth-token': token
      };
    }),

    pathForType(modelName) {
      return (0, _emberInflector.pluralize)(Ember.String.camelize(modelName));
    }

  });

  _exports.default = _default;
});