define("alliance-business-suite/components/summary/reports/s-coin-vault", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "alliance-business-suite/utils/currency-format-util", "alliance-business-suite/utils/report-tables-util"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _currencyFormatUtil, _reportTablesUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  let SummaryReportsSCoinVaultComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, (_class = class SummaryReportsSCoinVaultComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "brand", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor4, this);
    }

    get translatedColumns() {
      return [{
        name: 'location_machine',
        valuePath: 'name',
        width: 280,
        isFixed: 'left'
      }, {
        name: 'total_coins',
        valuePath: 'totalCoins',
        width: 90
      }, {
        name: 'vault_size',
        valuePath: 'vaultSize',
        width: 90
      }, {
        name: 'percent_capacity',
        valuePath: 'percentCapacity',
        width: 110
      }, {
        name: 'total_value',
        valuePath: 'totalValue',
        width: 80
      }, {
        name: 'last_emptied',
        valuePath: 'lastEmptied',
        width: 125
      }, {
        name: 'last_emptied_value',
        valuePath: 'lastEmptiedValue',
        width: 125
      }];
    }

    get rowsWithChildren() {
      let geoBoundaries = this.args.model.get('geoBoundaries');
      let rowData = [];
      geoBoundaries.forEach(geoBoundary => {
        let rowArray = [];
        let nameArray = [];
        let totalCoinValueSum = 0;
        let totalCoinVaultSum = 0;
        let totalCoinsSum = 0;
        let totalCoinValue = [];
        let totalCoins = [];
        let totalCoinVaultCapacity = [];
        let totalLastEmptiedValue = [];
        let totalLastEmptiedValueSum = 0;
        rowData.push({
          name: _reportTablesUtil.localizeString.call(this, geoBoundary.name, 'Location', 'location'),
          children: nameArray,
          totalCoins: totalCoins,
          totalValue: totalCoinValue,
          lastEmptiedValue: totalLastEmptiedValue,
          // new
          percentCapacity: totalCoinVaultCapacity
        });
        geoBoundary.rooms.forEach(room => {
          let totalCoinValueSum1 = 0;
          let totalCoinsSum1 = 0;
          let totalCoinVaultSum1 = 0;
          let totalCoinVaultCapacity1 = [];
          let totalCoinValue1 = [];
          let totalCoins1 = [];
          let totalLastEmptiedValue1 = [];
          let totalLastEmptiedValue1Sum = 0;
          nameArray.push({
            name: room.name,
            children: rowArray,
            totalCoins: totalCoins1,
            totalValue: totalCoinValue1,
            percentCapacity: totalCoinVaultCapacity1,
            lastEmptiedValue: totalLastEmptiedValue1 // new

          });
          room.machineAuditGroupTypes.forEach(machineAuditType => {
            machineAuditType.machineTypes.forEach(machineType => {
              let machineArray = [];
              let totalCoinValueSum2 = 0;
              let totalCoinsSum2 = 0;
              let totalCoinVaultSum2 = 0;
              let totalCoinVaultCapacity2 = [];
              let totalCoinValue2 = [];
              let totalCoins2 = [];
              let machineTypeName = `${machineAuditType.name} - ${machineType.name}`;
              let totalLastEmptiedValue2 = [];
              let totalLastEmptiedValue2Sum = 0;
              rowArray.push({
                name: machineTypeName,
                machineTypeName: machineType.name,
                children: machineArray,
                totalCoins: totalCoins2,
                totalValue: totalCoinValue2,
                percentCapacity: totalCoinVaultCapacity2,
                lastEmptiedValue: totalLastEmptiedValue2 // new

              });
              machineType.machines.forEach(machine => {
                let machineChildren = [];
                let machineTotalCoinsArray = [];
                let machineTotalCoinsValueArray = [];
                let machineTotalCoinVaultArray = [];

                let machineName = _reportTablesUtil.machineNameDisplay.call(this, machine.machineName, machine.machineNumber);

                let totalCoinsValue = machine.totalCoins || 0;
                let vaultSizeValue = machine.vaultSize || 0;
                let capacityPercentage = `${(machine.percentCapacity * 100 || 0).toFixed(2)} %`;

                let totalValueFormatted = _currencyFormatUtil.formatCurrencyString.call(this, machine.totalValue || 0);

                let machineTotalLastEmptiedArray = [];

                if (this.brand.slug == 'primus') {
                  // fix the branding issue alliance-tasks/issues/2291
                  rowArray[rowArray.length - 1].name = `${machine.machineAuditType.name} - ${rowArray[rowArray.length - 1].machineTypeName}`;
                }

                if (machine.totalCoins !== 0) {
                  machineTotalCoinsArray.push(machine.totalCoins);
                  totalCoinsSum = (0, _reportTablesUtil.sumArray)(machineTotalCoinsArray, totalCoinsSum);
                  totalCoinsSum1 = (0, _reportTablesUtil.sumArray)(machineTotalCoinsArray, totalCoinsSum1);
                  totalCoinsSum2 = (0, _reportTablesUtil.sumArray)(machineTotalCoinsArray, totalCoinsSum2);
                }

                if (machine.totalValue !== 0) {
                  machineTotalCoinsValueArray.push(machine.totalValue);
                  totalCoinValueSum = (0, _reportTablesUtil.sumArray)(machineTotalCoinsValueArray, totalCoinValueSum);
                  totalCoinValueSum1 = (0, _reportTablesUtil.sumArray)(machineTotalCoinsValueArray, totalCoinValueSum1);
                  totalCoinValueSum2 = (0, _reportTablesUtil.sumArray)(machineTotalCoinsValueArray, totalCoinValueSum2);
                }

                if (machine.vaultSize !== 0) {
                  machineTotalCoinVaultArray.push(machine.vaultSize);
                  totalCoinVaultSum = (0, _reportTablesUtil.sumArray)(machineTotalCoinVaultArray, totalCoinVaultSum);
                  totalCoinVaultSum1 = (0, _reportTablesUtil.sumArray)(machineTotalCoinVaultArray, totalCoinVaultSum1);
                  totalCoinVaultSum2 = (0, _reportTablesUtil.sumArray)(machineTotalCoinVaultArray, totalCoinVaultSum2);
                }

                machineArray.push({
                  name: machineName,
                  children: machineChildren,
                  totalCoins: totalCoinsValue,
                  vaultSize: vaultSizeValue,
                  percentCapacity: capacityPercentage,
                  totalValue: totalValueFormatted
                });
                machine.coinVaultEmptiedDetails.forEach(emptyDetail => {
                  if (emptyDetail.emptiedValue !== 0) {
                    machineTotalLastEmptiedArray.push(emptyDetail.emptiedValue ? emptyDetail.emptiedValue : 0);
                    totalLastEmptiedValueSum = (0, _reportTablesUtil.sumArray)(machineTotalLastEmptiedArray, totalLastEmptiedValueSum, true);
                    totalLastEmptiedValue1Sum = (0, _reportTablesUtil.sumArray)(machineTotalLastEmptiedArray, totalLastEmptiedValue1Sum, true);
                    totalLastEmptiedValue2Sum = (0, _reportTablesUtil.sumArray)(machineTotalLastEmptiedArray, totalLastEmptiedValue2Sum, true); // due to a bug we need to use the last idx ("true")
                  }

                  machineChildren.push({
                    name: `Date`,
                    lastEmptied: emptyDetail.emptiedAt ? `${moment(emptyDetail.emptiedAt).format(`${_reportTablesUtil.formatDateTimeString.call(this)}`)}` : '---',
                    lastEmptiedValue: emptyDetail.emptiedAt ? _currencyFormatUtil.formatCurrencyString.call(this, emptyDetail.emptiedValue || 0) : '---'
                  });
                });
              });
              totalCoins2.push(totalCoinsSum2);
              totalCoinValue2.push(_currencyFormatUtil.formatCurrencyString.call(this, totalCoinValueSum2 || 0));
              totalCoinVaultCapacity2.push(`${(totalCoinsSum2 / totalCoinVaultSum2 * 100 || 0).toFixed(2)} %`);
              totalLastEmptiedValue2.push(_currencyFormatUtil.formatCurrencyString.call(this, totalLastEmptiedValue2Sum || 0));
            });
          });
          totalCoins1.push(totalCoinsSum1);
          totalCoinValue1.push(_currencyFormatUtil.formatCurrencyString.call(this, totalCoinValueSum1 || 0));
          totalCoinVaultCapacity1.push(`${(totalCoinsSum1 / totalCoinVaultSum1 * 100 || 0).toFixed(2)} %`);
          totalLastEmptiedValue1.push(_currencyFormatUtil.formatCurrencyString.call(this, totalLastEmptiedValue1Sum || 0));
        });
        totalCoins.push(totalCoinsSum);
        totalCoinValue.push(_currencyFormatUtil.formatCurrencyString.call(this, totalCoinValueSum || 0));
        totalCoinVaultCapacity.push(`${(totalCoinsSum / totalCoinVaultSum * 100 || 0).toFixed(2)} %`);
        totalLastEmptiedValue.push(_currencyFormatUtil.formatCurrencyString.call(this, totalLastEmptiedValueSum || 0));
      });
      return rowData;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "brand", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SummaryReportsSCoinVaultComponent;
});