define("alliance-business-suite/components/inventory/inventory-form", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'selectedRooms': [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    'selectedItem': [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    'quantity': [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    'price': [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })]
  });

  var _default = Ember.Component.extend(Validations, {
    access: Ember.inject.service(),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    notifications: Ember.inject.service(),
    currencySymbol: Ember.computed(function () {
      return Ember.get(this, 'access.currencyDenomination');
    }),
    currencyDecimals: Ember.computed(function () {
      if (this.get('access.currencySubunit') === 1) {
        return 0;
      } else {
        return 2;
      }
    }),
    taxOptions: Ember.computed('taxes', {
      get() {
        return this.taxes;
      }

    }),
    saveDisabled: Ember.computed(function () {
      let canUpdateAmount = this['can-access'].canAccess('INVENTORY_UPDATE_AMOUNT');
      let canUpdatePrice = this['can-access'].canAccess('INVENTORY_UPDATE_PRICE');

      if (!canUpdatePrice && !canUpdateAmount) {
        return true;
      }
    }).readOnly(),
    items: Ember.computed(function () {
      let self = this;
      let organizationId = Ember.get(this, 'access.accessOrganization.id');
      this.ajax.request(`/organizations/${organizationId}/items`, {
        method: 'GET'
      }).then(response => {
        Ember.get(self, 'store').pushPayload(response);
      });
      return this.store.peekAll('item');
    }),
    quantity: Ember.computed(function () {
      return this.get('inventory.quantity');
    }),
    price: Ember.computed(function () {
      return this.get('inventory.price');
    }),
    selectedTaxes: Ember.computed('inventory.taxRates', {
      get() {
        return this.get('inventory.taxRates');
      }

    }),
    item: Ember.computed(function () {
      return this.get('inventory.item');
    }),
    posVisibilty: Ember.computed(function () {
      return [{
        text: 'hiddenPOS',
        value: true,
        icon: 'fa-eye-slash'
      }, {
        text: 'showPOS',
        value: false,
        icon: 'fa-eye'
      }];
    }).readOnly(),
    selectedVisibility: Ember.computed('inventory.hiddenPOS', {
      get() {
        let hiddenPOS = this.get('inventory.hiddenPOS');

        if (hiddenPOS) {
          return this.posVisibilty.get(0);
        } else {
          return this.posVisibilty.get(1);
        }
      }

    }),
    actions: {
      close() {
        this.close();
      },

      addInventory() {
        let user = this.store.peekRecord('user', this.get('access.accessUser.id'));
        let selectedRooms = this.selectedRooms;
        let roomCount = selectedRooms.length;
        let price = this.price;
        let taxOption = this.taxOption;
        let taxes = this.selectedTaxes || [];
        let self = this;
        price = Math.round(price);
        this.set('isSaving', true);
        this.set('isProcessing', true);
        selectedRooms.forEach(selectedRoom => {
          let newInventory = this.store.createRecord('inventory');
          newInventory.set('room', selectedRoom);
          newInventory.set('item', this.selectedItem);
          newInventory.set('quantity', this.quantity);
          newInventory.set('price', price);
          newInventory.set('taxRates', taxes);
          newInventory.set('createdBy', user);
          newInventory.set('hiddenPOS', self.selectedVisibility.value);
          newInventory.save({
            adapterOptions: {
              roomId: selectedRoom.get('id')
            }
          }).then(() => {
            roomCount--;

            if (roomCount === 0) {
              this.set('isSaving', false);
              this.set('isProcessing', false);
              this.send('close');
            }
          }, () => {
            this.set('isSaving', false);
            this.set('isProcessing', false);
          });
        });
      },

      saveInventory() {
        let inventory = this.inventory;
        let price = this.price;
        let taxOption = this.taxOption;
        let selectedTaxes = this.selectedTaxes;
        let taxes = [];
        let self = this;
        price = Math.round(price); // strangely needed to prevent Ember from blanking out selectedTaxes when assigning to inventory.taxRates

        if (selectedTaxes) {
          selectedTaxes.forEach(tax => {
            taxes.push(tax);
          });
        }

        inventory.set('quantity', this.quantity);
        inventory.set('price', price);
        inventory.set('taxRates', taxes);
        inventory.set('hiddenPOS', self.selectedVisibility.value);
        this.set('isSaving', true);
        this.set('isProcessing', true);
        inventory.save({
          adapterOptions: {
            roomId: this.get('inventory.room.id')
          }
        }).then(() => {
          this.set('isSaving', false);
          this.set('isProcessing', false);
          this.send('close');
        }, () => {
          this.set('isSaving', false);
          this.set('isProcessing', false);
        });
      }

    }
  });

  _exports.default = _default;
});