define("alliance-business-suite/components/models-table/themes/ember-bootstrap-v3/row-filtering-cell", ["exports", "ember-models-table/components/models-table/themes/ember-bootstrap-v3/row-filtering-cell"], function (_exports, _rowFilteringCell) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _rowFilteringCell.default;
    }
  });
});