define("alliance-business-suite/controllers/employees/roles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['sRole'],
    notifications: Ember.inject.service(),
    intl: Ember.inject.service(),
    selectedRole: null,
    actions: {
      deleteRole(role) {
        if (Ember.get(this, 'can-access').canAccess('EMPLOYEES_MANAGE_SECURITY_ROLES')) {
          if (confirm(Ember.get(this, 'intl').t('delete_this_role'))) {
            let notifications = Ember.get(this, 'notifications');
            role.deleteRecord();
            role.save().then(role => {
              Ember.set(this, 'isSavingRole', false);
              notifications.success(Ember.get(this, 'intl').t('role_deleted'), {
                clearDuration: 5000,
                autoClear: true
              });
            }).catch(err => {
              Ember.set(this, 'isSavingRole', false); // TODO: intl

              console.log('Error deleting role.', err); // eslint-disable-line no-console

              let message = Ember.get(this, 'intl').t('users_exist_in_role');
              notifications.error(message, {
                clearDuration: 5000,
                autoClear: true
              });
            });
          }
        }
      },

      createRole(newRole) {
        if (Ember.get(this, 'can-access').canAccess('EMPLOYEES_MANAGE_SECURITY_ROLES')) {
          let {
            name,
            copyRoleId
          } = newRole;
          Ember.set(this, 'isSavingRole', true);
          let notifications = Ember.get(this, 'notifications');
          let copyRole = Ember.get(this, 'model.roles').findBy('id', copyRoleId);
          this.store.createRecord('securityRole', {
            name,
            isSystemDefault: false,
            securityFunctionalAreas: Ember.get(copyRole, 'securityFunctionalAreas')
          }).save().then(role => {
            Ember.set(this, 'isSavingRole', false);
            notifications.success(Ember.get(this, 'intl').t('role_created'), {
              clearDuration: 5000,
              autoClear: true
            });
          }).catch(err => {
            Ember.set(this, 'isSavingRole', false); // TODO: intl

            console.log('Error creating role.', err); // eslint-disable-line no-console

            let message = Ember.get(this, 'intl').t('error_saving_role');
            notifications.error(message, {
              clearDuration: 5000,
              autoClear: true
            });
          });
        }
      }

    }
  });

  _exports.default = _default;
});