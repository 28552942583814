define("alliance-business-suite/helpers/primus-step-summary", ["exports", "alliance-business-suite/utils/seconds-to-hms"], function (_exports, _secondsToHms) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),

    compute(_ref, _ref2) {
      let [value, ...rest] = _ref;
      let {
        washDuration,
        drainType,
        drainDuration,
        drainSpeed,
        isCombo = false,
        isDryStep = false
      } = _ref2;

      /*
      Duration: 1m 30s
      Drain: 30s, 10RMP
      */
      let output = '';
      output += `Duration: ${(0, _secondsToHms.default)(washDuration, this.intl)}`; // TODO: add proper time labels

      if (!(isCombo && isDryStep)) {
        if (drainType != 'NoDrain') {
          // Ignore everything if draintype is NoDrain
          output += '<br>';
          output += `${drainType}: ${(0, _secondsToHms.default)(drainDuration, this.intl)}`;

          if (drainType != 'StaticDrain') {
            output += `, ${drainSpeed}${this.intl.t("RPM")}`;
          }
        }
      }

      return Ember.String.htmlSafe(output);
    }

  });

  _exports.default = _default;
});