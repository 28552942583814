define("alliance-business-suite/helpers/b", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    onBrandChange: Ember.observer('brand.name', function () {
      this.recompute();
    }),

    compute(_ref, hash) {
      let [key, replaceText, ...rest] = _ref;
      return this.brand.b(key, replaceText, hash.htmlSafe || false);
    }

  });

  _exports.default = _default;
});