define("alliance-business-suite/models/mixins/timestamped", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Ember.Mixin.create({
    createdAt: attr('date'),
    updatedAt: attr('date'),
    createdBy: belongsTo('user', {
      inverse: null
    }),
    updatedBy: belongsTo('user', {
      inverse: null
    })
  });

  _exports.default = _default;
});