define("alliance-business-suite/utils/tryCalling", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.tryCalling = void 0;

  const tryCalling = (fn, params) => {
    try {
      return fn(...params);
    } catch (e) {
      // eslint-disable-next-line
      console.log(new Error('Call failed, returning undefined'));
      return undefined;
    }
  };

  _exports.tryCalling = tryCalling;
});