define("alliance-business-suite/components/machines/modal-start-machine", ["exports", "ember-cp-validations", "alliance-business-suite/components/mixins/machine-controls"], function (_exports, _emberCpValidations, _machineControls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const START_OPTIONS = [{
    name: 'WDF order'
  }, {
    name: 'Customer service'
  }, {
    name: 'Payment system charged twice'
  }];

  var _default = Ember.Component.extend(_machineControls.default, {
    ajax: Ember.inject.service(),
    access: Ember.inject.service(),
    notifications: Ember.inject.service(),
    intl: Ember.inject.service(),
    startOptions: null,
    isLoading: true,
    note: null,

    didInsertElement() {
      this._super(...arguments);

      this.getStartReasons();
    },

    isOther: Ember.computed('selectedStartOption', function () {
      let selected = this.selectedStartOption;

      if (selected && selected.lookupKey === 'OTHER') {
        return true;
      }
    }),

    getStartReasons() {
      this.ajax.request(`/machines/startReasons?raw=true`, {
        method: 'GET'
      }).then(response => {
        this.setProperties({
          isLoading: false,
          startOptions: response
        });
      });
    },

    actions: {
      close() {
        if (!this.isLoading) {
          this.close();
        }
      },

      submit() {
        let {
          note,
          selectedStartOption,
          isOther,
          machineId,
          machine
        } = this;
        let uri = `/machines/${machineId}/start`;

        if (!selectedStartOption) {
          this.notifications.error(this.intl.t('select_start_reason'));
          return;
        }

        this.set('isSaving', true); // clear note is option is not "other"

        if (!isOther) {
          note = null;
        }

        let options = {
          type: 'startReason',
          typeLookupKey: selectedStartOption.lookupKey,
          notes: note
        };
        this.processCommand(uri, machine, options);
      }

    }
  });

  _exports.default = _default;
});