define("alliance-business-suite/utils/cycles-values", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.STEP_TYPES_ENUM = _exports.STEP_TYPES = _exports.STEP_LABELS_ENUM = _exports.STEP_LABELS_ABBREV = _exports.STEP_LABELS = _exports.CYCLE_LABELS = _exports.ACTIVE_STATES = void 0;

  /*
  
  These values are used throughout the Cycles tab for calculations, etc.
  
  */
  const ACTIVE_STATES = {
    enabled: 8,
    mod1: 1,
    mod2: 2,
    mod3: 3,
    speedCycle: 3,
    disabled: 0
  };
  _exports.ACTIVE_STATES = ACTIVE_STATES;
  const STEP_LABELS_ENUM = {
    PreWash: 0,
    Wash: 1,
    Rinse1: 2,
    Rinse2: 3,
    Rinse3: 4,
    Rinse4: 5,
    Spin: 6,
    Bleach: 7
  };
  _exports.STEP_LABELS_ENUM = STEP_LABELS_ENUM;
  const STEP_TYPES_ENUM = {
    'InvalidOrEmpty': 0,
    'ReuseFill': 1,
    // WX only
    'Fill': 2,
    'Supply': 3,
    'Agitate': 4,
    'Soak': 5,
    'Cooldown': 6,
    'Drain': 7,
    'SprayRinseExtract': 8,
    // Added for MGD_OPL
    'DrainAndExtract': 9,
    'AudioSignal': 10,
    'Hold': 11,
    'WashSpray': 12 // WX only

  };
  _exports.STEP_TYPES_ENUM = STEP_TYPES_ENUM;
  const CYCLE_LABELS = ['Normal Hot', 'Normal Warm', 'Normal Cold', 'Permanent Press Hot', 'Permanent Press Warm', 'Permanent Press Cold', 'Delicate Hot', 'Delicate Warm', 'Delicate Cold'];
  _exports.CYCLE_LABELS = CYCLE_LABELS;
  const STEP_LABELS = ['PreWash', 'Wash', 'Rinse 1', 'Rinse 2', 'Rinse 3', 'Rinse 4', 'Spin', 'Bleach'];
  _exports.STEP_LABELS = STEP_LABELS;
  const STEP_LABELS_ABBREV = ['P', 'W', 'R1', 'R2', 'R3', 'R4', 'S', 'B'];
  _exports.STEP_LABELS_ABBREV = STEP_LABELS_ABBREV;
  const STEP_TYPES = ['Not Used', 'Reuse Fill', 'Fill', 'Supply', 'Agitate', 'Soak', 'Cooldown', 'Drain', 'Spray Rinse Extract', 'Drain and Extract', 'Audio Signal', 'Hold', 'Wash Spray'];
  _exports.STEP_TYPES = STEP_TYPES;
});