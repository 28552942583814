define("alliance-business-suite/helpers/total-step-time", ["exports", "alliance-business-suite/utils/total-step-time"], function (_exports, _totalStepTime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute(_ref
    /* , hash*/
    ) {
      let [step] = _ref;
      return (0, _totalStepTime.totalStepTime)(step);
    }

  });

  _exports.default = _default;
});