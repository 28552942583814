define("alliance-business-suite/components/marketing/campaigns/campaign-filter", ["exports", "alliance-business-suite/mixins/report-tables", "alliance-business-suite/mixins/crm"], function (_exports, _reportTables, _crm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const LAST_ACTIVE_OPTIONS = [{
    id: '1 day',
    qualifier: 'WITHIN_RANGE',
    text: 'last_24_hours'
  }, {
    id: '7 days',
    qualifier: 'WITHIN_RANGE',
    text: 'last_7_days'
  }, {
    id: '1 month',
    qualifier: 'WITHIN_RANGE',
    text: 'last_month'
  }, {
    id: '1 week',
    qualifier: 'OUTSIDE_RANGE',
    text: 'more_than_1_week'
  }, {
    id: '1 month',
    qualifier: 'OUTSIDE_RANGE',
    text: 'more_than_1_month'
  }, {
    id: 'custom',
    qualifier: 'WITHIN_RANGE',
    text: 'custom_date'
  }];
  const USAGE_TYPE_OPTIONS = [{
    id: 'WASH_DRY_FOLD',
    text: 'wash_dry_fold'
  }, {
    id: 'GOODS',
    text: 'goods_sold'
  }, {
    id: 'MACHINE',
    text: 'SELF_SERVICE'
  }];
  const DAYS_OF_WEEK = [{
    id: '0',
    text: 'sunday'
  }, {
    id: '1',
    text: 'monday'
  }, {
    id: '2',
    text: 'tuesday'
  }, {
    id: '3',
    text: 'wednesday'
  }, {
    id: '4',
    text: 'thursday'
  }, {
    id: '5',
    text: 'friday'
  }, {
    id: '6',
    text: 'saturday'
  }];
  const DATE_FILTER_TYPE_OPTIONS = [{
    id: 'within',
    text: 'Within'
  }, {
    id: 'between',
    text: 'Between'
  }];

  var _default = Ember.Component.extend(_crm.default, _reportTables.default, {
    ajax: Ember.inject.service(),
    access: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    classNames: ['filter-option'],
    classNameBindings: ['isOptionsOpen:filter-options--active'],

    didInsertElement() {
      this._super(...arguments);

      let MACHINE_FILTER_TYPE_OPTIONS = this.store.peekAll('machine-type');
      this.set('MACHINE_FILTER_TYPE_OPTIONS', MACHINE_FILTER_TYPE_OPTIONS);

      if (this.applySelectedFilters) {
        let filters = this.applySelectedFilters;

        if (filters) {
          if (filters.LAST_ACTIVE) {
            this.set('selectedFilter', filters.LAST_ACTIVE.filter);

            for (let i = 0; i < filters.LAST_ACTIVE.options.length; i++) {
              for (let x = 0; x < LAST_ACTIVE_OPTIONS.length; x++) {
                if (filters.LAST_ACTIVE.options[i + 1] && filters.LAST_ACTIVE.options[i + 1].name == 'LAST_VISIT_QUALIFIER') {
                  if (filters.LAST_ACTIVE.options[i].value == LAST_ACTIVE_OPTIONS[x].id && filters.LAST_ACTIVE.options[i + 1].value == LAST_ACTIVE_OPTIONS[x].qualifier) {
                    this.set('selectedDateFilter', LAST_ACTIVE_OPTIONS[x]);
                    this.send('applyLastSeenWithinRange');
                  }
                } else if (filters.LAST_ACTIVE.options[i - 1] && filters.LAST_ACTIVE.options[i - 1].name == 'LAST_VISIT_QUALIFIER') {
                  if (filters.LAST_ACTIVE.options[i].value == LAST_ACTIVE_OPTIONS[x].id && filters.LAST_ACTIVE.options[i - 1].value == LAST_ACTIVE_OPTIONS[x].qualifier) {
                    this.set('selectedDateFilter', LAST_ACTIVE_OPTIONS[x]);
                    this.send('applyLastSeenWithinRange');
                  }
                } else if (filters.LAST_ACTIVE.options[i].value == LAST_ACTIVE_OPTIONS[x].id) {
                  this.set('selectedDateFilter', LAST_ACTIVE_OPTIONS[x]);
                  this.send('applyLastSeenWithinRange');
                }
              }

              if (filters.LAST_ACTIVE.options[i].name == 'LAST_VISIT_START_DATE') {
                this.set('selectedDateFilter', LAST_ACTIVE_OPTIONS[5]);
                this.set('rangeDateMin', moment(filters.LAST_ACTIVE.options[i].value).format('llll'));
                this.send('applyLastSeenDateRange');
              } else if (filters.LAST_ACTIVE.options[i].name == 'LAST_VISIT_END_DATE') {
                this.set('selectedDateFilter', LAST_ACTIVE_OPTIONS[5]);
                this.set('rangeDateMax', moment(filters.LAST_ACTIVE.options[i].value).format('llll'));
                this.send('applyLastSeenDateRange');
              }
            }

            delete filters.LAST_ACTIVE;
            filters.size--;
          } else if (filters.SERVICE_TYPE) {
            let options = [];

            for (let i = 0; i < filters.SERVICE_TYPE.options.length; i++) {
              for (let x = 0; x < USAGE_TYPE_OPTIONS.length; x++) {
                if (filters.SERVICE_TYPE.options[i] == USAGE_TYPE_OPTIONS[x].id) {
                  options.push(USAGE_TYPE_OPTIONS[x]);
                }
              }
            }

            this.set('selectedFilter', filters.SERVICE_TYPE.filter);
            this.set('usageTypeSelected', options);
            this.send('applyUsageTypes', options);
            delete filters.SERVICE_TYPE;
            filters.size--;
          } else if (filters.MACHINE_TYPE) {
            let options = [];

            for (let i = 0; i < filters.MACHINE_TYPE.options.length; i++) {
              let machineType = this.store.peekRecord('machineType', filters.MACHINE_TYPE.options[i]);

              if (machineType) {
                options.push({
                  id: machineType.id,
                  machineNameFormatted: machineType.machineNameFormatted
                });
              }
            }

            this.set('selectedFilter', filters.MACHINE_TYPE.filter);
            this.set('machineTypeSelected', options);
            this.send('applyMachineTypes', options);
            delete filters.MACHINE_TYPE;
            filters.size--;
          } else if (filters.DAY_OF_WEEK) {
            let options = [];

            for (let i = 0; i < filters.DAY_OF_WEEK.options.length; i++) {
              for (let x = 0; x < DAYS_OF_WEEK.length; x++) {
                if (filters.DAY_OF_WEEK.options[i] == DAYS_OF_WEEK[x].id) {
                  options.push(DAYS_OF_WEEK[x]);
                }
              }
            }

            this.set('selectedFilter', filters.DAY_OF_WEEK.filter);
            this.set('preferredDaysSelected', options);
            this.send('applyPrefferedDays', options);
            delete filters.DAY_OF_WEEK;
            filters.size--;
          } else if (filters.WASH_DRY_FOLD) {
            this.set('selectedFilter', filters.WASH_DRY_FOLD.filter);
            let min = filters.WASH_DRY_FOLD.options.AVG_VEND_MIN;
            let max = filters.WASH_DRY_FOLD.options.AVG_VEND_MAX;
            this.set('minWashSpend', min);
            this.set('maxWashSpend', max);
            this.send('applyAvgWashSpend');
            filters.size--;
            delete filters.WASH_DRY_FOLD;
          } else if (filters.GOODS) {
            this.set('selectedFilter', filters.GOODS.filter);
            let min = filters.GOODS.options.AVG_VEND_MIN;
            let max = filters.GOODS.options.AVG_VEND_MAX;
            this.set('minGoodsSpend', min);
            this.set('maxGoodsSpend', max);
            this.send('applyAvgGoodsSpend');
            filters.size--;
            delete filters.GOODS;
          } else if (filters.MACHINE) {
            this.set('selectedFilter', filters.MACHINE.filter);
            let min = filters.MACHINE.options.AVG_VEND_MIN;
            let max = filters.MACHINE.options.AVG_VEND_MAX;
            this.set('minSelfSpend', min);
            this.set('maxSelfSpend', max);
            this.send('applyAvgSelfSpend');
            filters.size--;
            delete filters.MACHINE;
          } else if (filters.LIFETIME_SPEND) {
            this.set('selectedFilter', filters.LIFETIME_SPEND.filter);
            let min = filters.LIFETIME_SPEND.options.LIFETIME_SPEND_MIN;
            let max = filters.LIFETIME_SPEND.options.LIFETIME_SPEND_MAX;
            this.set('minLifetimeSpend', min);
            this.set('maxLifetimeSpend', max);
            this.send('applyLifetimeSpend');
            filters.size--;
            delete filters.LIFETIME_SPEND;
          } else if (filters.DEADBEAT_USERS) {
            this.set('selectedFilter', filters.DEADBEAT_USERS.filter);
            filters.size--;
            delete filters.DEADBEAT_USERS;
          }

          this.filterChange();
        }
      }

      this.updateFilterUI();
    },

    willDestroyElement() {
      this._super(...arguments);

      if (this.selectedFilter) {
        this.selectedFilter.disabled = false;
      }
    },

    dateFilterTypes: Ember.computed(function () {
      return DATE_FILTER_TYPE_OPTIONS;
    }),
    lastActiveOptions: Ember.computed(function () {
      return LAST_ACTIVE_OPTIONS;
    }),
    usageTypeOptions: Ember.computed(function () {
      return USAGE_TYPE_OPTIONS;
    }),
    preferredDaysOptions: Ember.computed(function () {
      return DAYS_OF_WEEK;
    }),
    machineTypeOptions: Ember.computed(function () {
      const unsupportedMachineNameFormatted = ['machineType.intl.undefined', 'unknown'];
      return this.MACHINE_FILTER_TYPE_OPTIONS.filter(m => {
        return unsupportedMachineNameFormatted.indexOf(m.get('machineNameFormatted')) == -1;
      }).map(m => {
        return this.store.peekRecord('machineType', m.id);
      });
    }),
    previouslySelectedFilter: null,
    lastActiveDateFilterType: Ember.computed(function () {
      return DATE_FILTER_TYPE_OPTIONS[0];
    }),
    currencySymbol: Ember.computed(function () {
      return Ember.get(this, 'access.currencyDenomination');
    }),
    currencyDecimals: Ember.computed(function () {
      if (this.get('access.currencySubunit') === 1) {
        return 0;
      } else {
        return 2;
      }
    }),

    // rangeDateMin: computed(() => {
    //   return moment().subtract(30, 'days').toDate();
    // }),
    //
    // rangeDateMax: computed(() => {
    //   return moment().toDate()
    // }),
    //
    // startDateMin: computed(() => {
    //   return moment('2018-01-01').toDate();
    // }),
    //
    // startDateMax: computed(() => {
    //   return moment().toDate()
    // }),
    //
    // endDateMax: computed(() => {
    //   return moment().toDate()
    // }),
    // some filters (zombie users) cannot be combined with other filters
    checkExclusionaryFilters() {
      if (this.selectedFilter?.id !== 'DEADBEAT_USERS') {
        this.set('disabledAddFilter', false);
      } else {
        // remove + button
        this.set('disabledAddFilter', true); // remove all existing filters, add this filter

        const disableAllFilters = this.filters;
        disableAllFilters.forEach(function (filter) {
          Ember.set(filter, 'disabled', filter.id === 'DEADBEAT_USERS');
        });
        this.set('filters', disableAllFilters);
        this.updateSelectedFilters([{
          filter: this.selectedFilter,
          options: [{
            'Name': 'DEADBEAT_USERS',
            'Value': 'true'
          }],
          index: this.index
        }]);
      }
    },

    // used to be test2 observer
    updateFilterUI() {
      let previouslySelectedFilter = this.previouslySelectedFilter;
      let selectedFilter = this.selectedFilter;
      let filters = this.filters;
      this.checkExclusionaryFilters();

      if (previouslySelectedFilter) {
        filters.forEach(function (filter) {
          if (filter.id == previouslySelectedFilter.id) {
            Ember.set(filter, 'disabled', false);
          }
        }); // clear previous params

        if (selectedFilter.id !== previouslySelectedFilter.id) {
          let selectedFilters = this.selectedFilters;
          let newSelectedFilters = selectedFilters.filter(f => {
            return f.filter.id != previouslySelectedFilter.id;
          });
          this.set('selectedFilters', newSelectedFilters);
        }
      }

      this.set('previouslySelectedFilter', selectedFilter); // restore UI values

      if (this.get('selectedFilter.id') === 'LAST_ACTIVE' && this.selectedDateFilter) {
        this.send('applyLastSeenWithinRange');

        if (this.get('selectedDateFilter.id') === 'custom' && (this.rangeDateMin || this.rangeDateMax)) {
          this.send('applyLastSeenDateRange');
        }
      } else if (this.get('selectedFilter.id') === 'SERVICE_TYPE' && this.usageTypeSelected) {
        this.send('applyUsageTypes', this.usageTypeSelected);
      } else if (this.get('selectedFilter.id') === 'PREFERRED_DAY' && this.preferredDaysSelected) {
        this.send('applyPrefferedDays', this.preferredDaysSelected);
      } else if (this.get('selectedFilter.id') === 'WASH_DRY_FOLD' && (this.minWashSpend || this.maxWashSpend)) {
        this.send('applyAvgWashSpend');
      } else if (this.get('selectedFilter.id') === 'GOODS' && (this.minGoodsSpend || this.maxGoodsSpend)) {
        this.send('applyAvgGoodsSpend');
      } else if (this.get('selectedFilter.id') === 'MACHINE' && (this.minSelfSpend || this.maxSelfSpend)) {
        this.send('applyAvgSelfSpend');
      } else if (this.get('selectedFilter.id') === 'MACHINE_TYPE' && this.machineTypeSelected) {
        this.send('applyMachineTypes', this.machineTypeSelected);
      } else if (this.get('selectedFilter.id') === 'LIFETIME_SPEND' && (this.minLifetimeSpend || this.maxLifetimeSpend)) {
        this.send('applyLifetimeSpend');
      } // default filter params


      if (this.get('selectedFilter.id') === 'LAST_ACTIVE' && !this.selectedDateFilter) {
        this.set('selectedDateFilter', LAST_ACTIVE_OPTIONS[4]);
        this.send('applyLastSeenWithinRange');
      } else if (this.get('selectedFilter.id') === 'WASH_DRY_FOLD' && !this.minWashSpend && !this.maxWashSpend) {
        this.set('minWashSpend', 100);
        this.set('maxWashSpend', 3000);
        this.send('applyAvgWashSpend');
      } else if (this.get('selectedFilter.id') === 'GOODS' && !this.minGoodsSpend && !this.maxGoodsSpend) {
        this.set('minGoodsSpend', 100);
        this.set('maxGoodsSpend', 3000);
        this.send('applyAvgGoodsSpend');
      } else if (this.get('selectedFilter.id') === 'MACHINE' && !this.minSelfSpend && !this.maxSelfSpend) {
        this.set('minSelfSpend', 100);
        this.set('maxSelfSpend', 3000);
        this.send('applyAvgSelfSpend');
      } else if (this.get('selectedFilter.id') === 'LIFETIME_SPEND' && !this.minLifetimeSpend && !this.maxLifetimeSpend) {
        this.set('minLifetimeSpend', 100);
        this.set('maxLifetimeSpend', 5000);
        this.send('applyLifetimeSpend');
      }

      filters.forEach(function (filter) {
        if (filter.id == selectedFilter?.id) {
          Ember.set(filter, 'disabled', true);
        }
      }); // manually update params for these filters
      // its because these do not have defaults

      if (this.selectedFilter?.id === 'SERVICE_TYPE' || this.selectedFilter?.id === 'PREFERRED_DAY' || this.selectedFilter?.id === 'MACHINE_TYPE') {
        this.updateParam();
      } // calling crm tracking count manually


      if (this.showCrmTrackingPreview) {
        this.setCrmPreviewProperties({
          type: "filters",
          content: this.selectedFilters
        });
      }

      this.set('filters', filters);
    },

    updateParam() {
      let filters = this.selectedFilters;
      let payload = {
        'Params': []
      };
      let machineTypesPushed = []; // fix an issue where the params for machine type would be duplicated
      // fix for DEADBEAT_USERS persisting for the trackingCount call
      // removes it if it somehow sticks around

      if (filters.length > 1 && filters[0].filter.id === 'DEADBEAT_USERS') {
        filters = filters.filter(option => {
          return option.filter.id !== 'DEADBEAT_USERS';
        });
      } // console.log('updateParam?')


      filters.forEach(function (filter) {
        if (filter.options) {
          filter.options.forEach(function (option) {
            payload.Params.push(option); //   if (option.Name !== "MACHINE_TYPE") {
            //     payload.Params.push(option);
            //   } else if (option.Name === "MACHINE_TYPE" && machineTypesPushed.indexOf(option.Value) === -1) {
            //     payload.Params.push(option);
            //     machineTypesPushed.push(option.Value)
            //   }
            // });
          });
        }
      });

      if (this.get('campaign') && this.get('campaign.filter') !== JSON.stringify(payload) && (!this.get('applySelectedFilters.size') || this.get('applySelectedFilters.size') <= 0)) {
        this.set('campaign.filter', JSON.stringify(payload)); // this.hasUnsavedChanges(true);
      }
    },

    test: Ember.observer('applyFilter', function () {
      if (this.applyFilter == true) {
        let self = this;
        self.send('applyFilter');
        setTimeout(function () {
          self.set('applyFilter', false);
        }, 200);
      }
    }),

    // this needs to be its own function so debounce works
    callCrmTrackingApi() {
      this.setCrmPreviewProperties({
        type: "filters",
        content: this.selectedFilters
      });
    },

    actions: {
      handleCurrencyChange(val) {
        // SELF SERVICE
        if (val.inputId === 'campaign-filter-min-self-spend') {
          this.set('minSelfSpend', val.val);
          this.send('applyAvgSelfSpend');
        }

        if (val.inputId === 'campaign-filter-max-self-spend') {
          this.set('maxSelfSpend', val.val);
          this.send('applyAvgSelfSpend');
        } // GOODS


        if (val.inputId === 'campaign-filter-min-goods-spend') {
          this.set('minGoodsSpend', val.val);
          this.send('applyAvgGoodsSpend');
        }

        if (val.inputId === 'campaign-filter-max-goods-spend') {
          this.set('maxGoodsSpend', val.val);
          this.send('applyAvgGoodsSpend');
        } // WDF


        if (val.inputId === 'campaign-filter-min-wash-spend') {
          this.set('minWashSpend', val.val);
          this.send('applyAvgWashSpend');
        }

        if (val.inputId === 'campaign-filter-max-wash-spend') {
          this.set('maxWashSpend', val.val);
          this.send('applyAvgWashSpend');
        } // LIFETIME_SPEND


        if (val.inputId === 'campaign-filter-min-lifetime-spend') {
          this.set('minLifetimeSpend', val.val);
          this.send('applyLifetimeSpend');
        }

        if (val.inputId === 'campaign-filter-max-lifetime-spend') {
          this.set('maxLifetimeSpend', val.val);
          this.send('applyLifetimeSpend');
        }

        if (this.showCrmTrackingPreview) {
          Ember.run.debounce(this, this.callCrmTrackingApi, 750);
        }
      },

      updateSelectedDateFilter(val) {
        this.set('selectedDateFilter', val);

        if (val.id == 'custom') {
          Ember.set(this, 'customDateRange', true);
          Ember.set(this, 'lastActiveDateFilterType', DATE_FILTER_TYPE_OPTIONS[1]);
        } else {
          Ember.set(this, 'customDateRange', false);
          Ember.set(this, 'lastActiveDateFilterType', DATE_FILTER_TYPE_OPTIONS[0]);
        }

        this.send('applyLastSeenWithinRange');

        if (this.showCrmTrackingPreview) {
          this.setCrmPreviewProperties({
            type: "filters",
            content: this.selectedFilters
          });
        }
      },

      updateSelectedFilter(val) {
        if (val.id === 'DEADBEAT_USERS') {
          if (confirm("You cannot combine this filter type with any others. Do you wish to continue?")) {
            this.set('selectedFilter', val);
          } else {
            return;
          }
        } else {
          this.set('selectedFilter', val);
        }

        this.updateFilterUI();
      },

      newFilter() {
        this.addFilter();
      },

      removeFilter() {
        let selectedFilter = this.selectedFilter;
        let selectedFilters = this.selectedFilters;
        let filters = this.filters;
        let index = this.index;

        if (selectedFilter) {
          filters.forEach(function (filter) {
            if (filter.id == selectedFilter.id) {
              filter.disabled = false;
            }
          });
          let filtersToDelete = [];
          selectedFilters.forEach(filter => {
            if (filter.filter.id == selectedFilter.id) {
              filtersToDelete.push(filter);
            }
          });
          filtersToDelete.forEach(filter => {
            selectedFilters.removeObject(filter);
          });
        }

        this.removeFilter(index); // calling crm tracking count manually

        if (this.showCrmTrackingPreview) {
          this.setCrmPreviewProperties({
            type: "filters",
            content: this.selectedFilters
          });
        }
      },

      setDateMin(value) {
        Ember.set(this, 'rangeDateMin', value);
        this.send('applyLastSeenDateRange');
      },

      setDateMax(value) {
        Ember.set(this, 'rangeDateMax', value);
        this.send('applyLastSeenDateRange');
      },

      applyAvgWashSpend() {
        let self = this;
        let avgSpendMin = this.minWashSpend;
        let avgSpendMax = this.maxWashSpend;
        let selectedFilters = this.selectedFilters;
        let filter;
        selectedFilters.forEach(function (selectedFilter) {
          if (selectedFilter.filter.id == self.get('selectedFilter.id')) {
            filter = selectedFilter;
          }
        });

        if (filter) {
          filter.options = [{
            Name: 'AVG_VEND_TYPE',
            Value: 'WASH_DRY_FOLD'
          }, {
            Name: 'AVG_VEND_MIN',
            Value: avgSpendMin
          }, {
            Name: 'AVG_VEND_MAX',
            Value: avgSpendMax
          }];
        } else {
          selectedFilters.push({
            filter: this.selectedFilter,
            options: [{
              Name: 'AVG_VEND_TYPE',
              Value: 'WASH_DRY_FOLD'
            }, {
              Name: 'AVG_VEND_MIN',
              Value: avgSpendMin
            }, {
              Name: 'AVG_VEND_MAX',
              Value: avgSpendMax
            }]
          });
        } // pre-octane refactor
        // set(this, 'selectedFilters', selectedFilters);


        this.updateSelectedFilters(selectedFilters);
        self.filterChange();
      },

      applyAvgGoodsSpend() {
        let self = this;
        let avgSpendMin = this.minGoodsSpend;
        let avgSpendMax = this.maxGoodsSpend;
        let selectedFilters = this.selectedFilters;
        let filter;
        selectedFilters.forEach(function (selectedFilter) {
          if (selectedFilter.filter.id == self.get('selectedFilter.id')) {
            filter = selectedFilter;
          }
        });

        if (filter) {
          filter.options = [{
            Name: 'AVG_VEND_TYPE',
            Value: 'GOODS'
          }, {
            Name: 'AVG_VEND_MIN',
            Value: avgSpendMin
          }, {
            Name: 'AVG_VEND_MAX',
            Value: avgSpendMax
          }];
        } else {
          selectedFilters.push({
            filter: this.selectedFilter,
            options: [{
              Name: 'AVG_VEND_TYPE',
              Value: 'GOODS'
            }, {
              Name: 'AVG_VEND_MIN',
              Value: avgSpendMin
            }, {
              Name: 'AVG_VEND_MAX',
              Value: avgSpendMax
            }]
          });
        } // pre-octane refactor
        // set(this, 'selectedFilters', selectedFilters);


        this.updateSelectedFilters(selectedFilters);
        self.filterChange();
      },

      applyAvgSelfSpend() {
        let self = this;
        let avgSpendMin = this.minSelfSpend;
        let avgSpendMax = this.maxSelfSpend;
        let selectedFilters = this.selectedFilters;
        let filter;
        selectedFilters.forEach(function (selectedFilter) {
          if (selectedFilter.filter.id == self.get('selectedFilter.id')) {
            filter = selectedFilter;
          }
        });

        if (filter) {
          filter.options = [{
            Name: 'AVG_VEND_TYPE',
            Value: 'MACHINE'
          }, {
            Name: 'AVG_VEND_MIN',
            Value: avgSpendMin
          }, {
            Name: 'AVG_VEND_MAX',
            Value: avgSpendMax
          }];
        } else {
          selectedFilters.push({
            filter: this.selectedFilter,
            options: [{
              Name: 'AVG_VEND_TYPE',
              Value: 'MACHINE'
            }, {
              Name: 'AVG_VEND_MIN',
              Value: avgSpendMin
            }, {
              Name: 'AVG_VEND_MAX',
              Value: avgSpendMax
            }]
          });
        } // pre-octane refactor
        // set(this, 'selectedFilters', selectedFilters);


        this.updateSelectedFilters(selectedFilters);
        self.filterChange();
      },

      applyUsageTypes(value) {
        let self = this;
        let usageTypes = value;
        let selectedFilters = this.selectedFilters;
        let filter;
        selectedFilters.forEach(function (selectedFilter) {
          if (selectedFilter.filter.id == self.get('selectedFilter.id')) {
            filter = selectedFilter;
          }
        });

        if (filter) {
          filter.options = [];
          usageTypes.forEach(function (usageType) {
            filter.options.push({
              Name: 'SERVICE_TYPE',
              Value: usageType.id
            });
          });
        } else {
          usageTypes.forEach(function (usageType) {
            selectedFilters.push({
              filter: self.get('selectedFilter'),
              options: [{
                Name: 'SERVICE_TYPE',
                Value: usageType.id
              }]
            });
          });
        }

        Ember.set(this, 'usageTypeSelected', value); // pre-octane refactor
        // set(this, 'selectedFilters', selectedFilters);

        this.updateSelectedFilters(selectedFilters, true);
        self.filterChange();
      },

      applyMachineTypes(value) {
        // console.log('applyMachineTypes...', value)
        let self = this;
        let _value = value;
        let machineTypes = value;
        let selectedFilters = this.selectedFilters;
        let filter;
        const updatedSelectedFiltersId = value.map(v => {
          return v.id;
        }); // initialize correctly the dropdown with pre-selected options

        if (this.get('machineTypeSelected') && value) {
          _value = value.map(m => {
            return this.store.peekRecord('machineType', m.id);
          });
        }

        selectedFilters.forEach(function (selectedFilter) {
          if (selectedFilter.filter.id == self.get('selectedFilter.id')) {
            filter = selectedFilter;
          }
        });

        if (filter) {
          filter.options = [];
          machineTypes.forEach(function (machine) {
            filter.options.push({
              Name: 'MACHINE_TYPE',
              Value: machine.id
            });
          });
        } else {
          machineTypes.forEach(function (machine) {
            selectedFilters.push({
              filter: self.get('selectedFilter'),
              options: [{
                Name: 'MACHINE_TYPE',
                Value: machine.id
              }]
            });
          });
        } // const newFilters = selectedFilters.filter((f) => {
        //   if (f.filter.id === 'MACHINE_TYPE') {
        //     if (updatedSelectedFiltersId.indexOf(f.options.id) !== -1) {
        //
        //     }
        //   } else {
        //     return f
        //   }
        //   console.log('      ', f)
        //   return
        // })
        //
        // console.log('!!! updatedSelectedFiltersId', updatedSelectedFiltersId)
        // console.log('!!! newFilters', newFilters)


        Ember.set(this, 'machineTypeSelected', _value); // pre-octane refactor
        // set(this, 'selectedFilters', selectedFilters);

        this.updateSelectedFilters(selectedFilters, true); // self.filterChange(selectedFilters);

        self.filterChange();
      },

      applyPrefferedDays(value) {
        let self = this;
        let prefferedDays = value;
        let selectedFilters = this.selectedFilters;
        let filter;
        selectedFilters.forEach(function (selectedFilter) {
          if (selectedFilter.filter.id == self.get('selectedFilter.id')) {
            filter = selectedFilter;
          }
        });

        if (filter) {
          filter.options = [];
          prefferedDays.forEach(function (prefferedDay) {
            filter.options.push({
              Name: 'DAY_OF_WEEK',
              Value: prefferedDay.id
            });
          });
        } else {
          prefferedDays.forEach(function (prefferedDay) {
            selectedFilters.push({
              filter: self.get('selectedFilter'),
              options: [{
                Name: 'DAY_OF_WEEK',
                Value: prefferedDay.id
              }]
            });
          });
        } // Filter by "or"
        // preferredDaysSelected.forEach(function(preferredDaySelected) {
        //   let tempResults = results.filter(function(el) {
        //     return el.dateName.indexOf(preferredDaySelected.text) > -1;
        //   });
        //   newResults = newResults.concat(tempResults);
        // });


        Ember.set(this, 'preferredDaysSelected', value); // pre-octane refactor
        // set(this, 'selectedFilters', selectedFilters);

        this.updateSelectedFilters(selectedFilters, true);
        self.filterChange();
      },

      applyLastSeenDateRange() {
        let self = this;
        let selectedFilters = this.selectedFilters;
        let lastActive = this.selectedDateFilter;
        let rangeDateMin = Ember.get(this, 'rangeDateMin');
        let rangeDateMax = Ember.get(this, 'rangeDateMax');
        let filter; // rangeDateMin = moment(rangeDateMin, "ddd, MMM D, YYYY h:mm a")

        selectedFilters.forEach(function (selectedFilter) {
          if (selectedFilter.filter.id == self.get('selectedFilter.id')) {
            filter = selectedFilter;
          }
        });

        if (filter) {
          filter.options = [{
            Name: 'LAST_VISIT_START_DATE',
            Value: moment(rangeDateMin).format('YYYY-MM-DD')
          }, {
            Name: 'LAST_VISIT_END_DATE',
            Value: moment(rangeDateMax).format('YYYY-MM-DD')
          }, {
            Name: 'LAST_VISIT_QUALIFIER',
            Value: lastActive.qualifier
          }];
        } else {
          selectedFilters.push({
            filter: this.selectedFilter,
            options: [{
              Name: 'LAST_VISIT_START_DATE',
              Value: moment(rangeDateMin).format('YYYY-MM-DD')
            }, {
              Name: 'LAST_VISIT_END_DATE',
              Value: moment(rangeDateMax).format('YYYY-MM-DD')
            }, {
              Name: 'LAST_VISIT_QUALIFIER',
              Value: lastActive.qualifier
            }]
          });
        } // pre-octane refactor
        // set(this, 'selectedFilters', selectedFilters);


        this.updateSelectedFilters(selectedFilters);
        self.filterChange();

        if (this.showCrmTrackingPreview) {
          this.setCrmPreviewProperties({
            type: "filters",
            content: this.selectedFilters
          });
        }
      },

      applyLastSeenWithinRange() {
        let self = this;
        let userId = this.get('access.accessUser.id');
        let user = this.store.peekRecord('employee', userId);
        let selectedFilters = this.selectedFilters;
        let lastActive = this.selectedDateFilter;
        let lastActiveDateFilterType = Ember.get(this, 'lastActiveDateFilterType');
        let filter;
        selectedFilters.forEach(function (selectedFilter) {
          if (selectedFilter.filter.id == self.get('selectedFilter.id')) {
            filter = selectedFilter;
          }
        });

        if (filter) {
          filter.options = [{
            Name: 'LAST_VISIT_INTERVAL',
            Value: lastActive.id
          }, {
            Name: 'LAST_VISIT_QUALIFIER',
            Value: lastActive.qualifier
          }, {
            Name: 'TIME_ZONE',
            Value: user.get('timeZone')
          }];
        } else {
          selectedFilters.push({
            filter: this.selectedFilter,
            options: [{
              Name: 'LAST_VISIT_INTERVAL',
              Value: lastActive.id
            }, {
              Name: 'LAST_VISIT_QUALIFIER',
              Value: lastActive.qualifier
            }, {
              Name: 'TIME_ZONE',
              Value: user.get('timeZone')
            }]
          });
        } // pre-octane refactor
        // set(this, 'selectedFilters', selectedFilters);


        this.updateSelectedFilters(selectedFilters);
        self.filterChange();
      },

      applyLifetimeSpend() {
        let self = this;
        let lifetimeSpendMin = this.minLifetimeSpend;
        let lifetimeSpendMax = this.maxLifetimeSpend;
        let selectedFilters = this.selectedFilters;
        let filter;
        selectedFilters.forEach(function (selectedFilter) {
          if (selectedFilter.filter.id == self.get('selectedFilter.id')) {
            filter = selectedFilter;
          }
        });

        if (filter) {
          filter.options = [{
            //   Name: 'AVG_VEND_TYPE',
            //   Value: 'GOODS'
            // }, {
            Name: 'LIFETIME_SPEND_MIN',
            Value: lifetimeSpendMin
          }, {
            Name: 'LIFETIME_SPEND_MAX',
            Value: lifetimeSpendMax
          }];
        } else {
          selectedFilters.push({
            filter: this.selectedFilter,
            options: [{
              //   Name: 'AVG_VEND_TYPE',
              //   Value: 'GOODS'
              // }, {
              Name: 'LIFETIME_SPEND_MIN',
              Value: lifetimeSpendMin
            }, {
              Name: 'LIFETIME_SPEND_MAX',
              Value: lifetimeSpendMax
            }]
          });
        } // pre-octane refactor
        // set(this, 'selectedFilters', selectedFilters);


        this.updateSelectedFilters(selectedFilters);
        self.filterChange();
      }

    }
  });

  _exports.default = _default;
});