define("alliance-business-suite/components/guests/g-transactions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    access: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    cache: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.getTransactions();
    },

    refreshButtonDisabled: 'disabled',
    orders: [],
    refreshButtonEnabled: Ember.observer('isLoading', function () {
      let loading = this.isLoading;

      if (loading) {
        this.set('refreshButtonDisabled', 'disabled');
      } else {
        this.set('refreshButtonDisabled', '');
      }
    }),

    getTransactions() {
      let guestId = Ember.get(this, 'guestId');
      let self = this;
      this.set('isLoading', true);
      this.ajax.request(`/orders/?guestId=${guestId}&raw=true`, {
        method: 'GET'
      }).then(response => {
        self.set('orders', response.orders);
        self.set('isLoading', false);
      });
    },

    actions: {
      refresh() {
        this.getTransactions();
      }

    }
  });

  _exports.default = _default;
});