define("alliance-business-suite/components/guests/g-modal", ["exports", "jquery", "ember-cp-validations"], function (_exports, _jquery, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'firstName': [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    'lastName': [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    'phoneOrEmail': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      dependentKeys: ['model.emailAddress', 'model.telecomInternationalCode'],
      disabled: Ember.computed('model.{emailAddress,telecomInternationalCode}', function () {
        return this.model.get('emailAddress') || this.model.get('telecomInternationalCode') ? true : false;
      })
    })]
  });

  var _default = Ember.Component.extend(Validations, {
    brush: Ember.inject.service(),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),

    clearSearch() {
      let searchInput = (0, _jquery.default)('#scrub_search');
      searchInput.focus().val('').trigger({
        type: 'keydown',
        which: 20
      });
      this.brush.transitionTo('default', null);
    },

    firstName: Ember.computed(function () {
      return this.get('guest.firstName');
    }),
    lastName: Ember.computed(function () {
      return this.get('guest.lastName');
    }),
    emailAddress: Ember.computed(function () {
      return this.get('guest.emailAddress');
    }),
    phoneNumber: Ember.computed({
      get() {
        let telecomInternationalCode = this.get('guest.telecomInternationalCode') || '';
        let telecomAreaCode = this.get('guest.telecomAreaCode') || '';
        let telecomContactNumber = this.get('guest.telecomContactNumber') || '';

        if (telecomInternationalCode && telecomContactNumber) {
          return `+${telecomInternationalCode}${telecomAreaCode}${telecomContactNumber}`;
        }

        return;
      }

    }),
    telecomInternationalCode: Ember.computed({
      get() {
        return this.get('guest.telecomInternationalCode');
      }

    }),
    telecomAreaCode: Ember.computed({
      get() {
        return this.get('guest.telecomAreaCode');
      }

    }),
    telecomContactNumber: Ember.computed({
      get() {
        return this.get('guest.telecomContactNumber');
      }

    }),
    actions: {
      close() {
        this.close();
      },

      toggleShowConflictModal() {
        this.toggleProperty('showConflictModal');
      },

      phoneInput(phone, meta) {
        let phoneNumber, telecomAreaCode, telecomContactNumber;

        if (!phone || !meta.isValidNumber) {
          this.set('telecomInternationalCode', '');
          this.set('telecomAreaCode', '');
          this.set('telecomContactNumber', '');
          return false;
        }

        phoneNumber = intlTelInputUtils.formatNumber(phone, meta.selectedCountryData.iso2, 2);

        if (meta.selectedCountryData.iso2 == 'us') {
          telecomAreaCode = phone.substr(meta.selectedCountryData.dialCode.length + 1, 3);
          telecomContactNumber = phone.substr(meta.selectedCountryData.dialCode.length + 4, phone.length);
        } else {
          telecomContactNumber = phone.substr(meta.selectedCountryData.dialCode.length + 1, phone.length);
        }

        this.set('phoneNumber', phoneNumber);
        this.set('telecomInternationalCode', meta.selectedCountryData.dialCode);
        this.set('telecomAreaCode', telecomAreaCode);
        this.set('telecomContactNumber', telecomContactNumber);
      },

      delete() {
        this.set('isProcessing', true);
        this.set('isDeleting', true);
        this.guest.destroyRecord().then(() => {
          this.clearSearch();
          this.set('isProcessing', false);
          this.set('isDeleting', false);
        }, () => {
          this.guest.rollbackAttributes();
          this.set('isProcessing', false);
          this.set('isDeleting', false);
        });
      },

      save() {
        let guest = this.guest;
        guest.set('firstName', this.firstName);
        guest.set('lastName', this.lastName);
        guest.set('emailAddress', this.emailAddress);
        guest.set('telecomInternationalCode', this.telecomInternationalCode);
        guest.set('telecomAreaCode', this.telecomAreaCode);
        guest.set('telecomContactNumber', this.telecomContactNumber);
        this.set('changes', guest.changedAttributes());
        this.set('isProcessing', true);
        this.set('isSaving', true);
        guest.save().then(result => {
          if (result.conflictGuestId) {
            this.toggleProperty('showConflictModal');
            this.set('isProcessing', false);
            this.set('isSaving', false);
          } else {
            this.close();
            this.set('isProcessing', false);
            this.set('isSaving', false);
          }
        }, result => {
          guest.rollbackAttributes();
          this.set('isProcessing', false);
          this.set('isSaving', false);
        });
      }

    }
  });

  _exports.default = _default;
});