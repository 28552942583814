define("alliance-business-suite/helpers/options-intl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Translates option text/label if found in translations.js file.
   */
  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),

    compute(_ref, args) {
      let [value, ...rest] = _ref;
      let intl = this.intl;
      let key = `option_${value}`;

      if (intl.exists(key)) {
        return intl.t(key);
      } else {
        if (intl.exists(value)) {
          return intl.t(value);
        } else {
          return Ember.String.htmlSafe(value);
        }
      }
    }

  });

  _exports.default = _default;
});