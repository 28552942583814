define("alliance-business-suite/components/machines/programs/cycles/midas-tum", ["exports", "alliance-business-suite/mixins/machines/programs/midas-options"], function (_exports, _midasOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Used by Midas and MGD_M1_DRY
   */
  var _default = Ember.Component.extend(_midasOptions.default, {
    formConfig: Ember.computed(function () {
      return {
        columns: [{
          sections: [{
            title: "cycle_times",
            controls: [{
              type: "duration",
              label: "with_heat",
              properties: {
                minutes: ["Program.Cycle.HeatTimeMinutes_TUM", "Program.Cycle.HeatCycleMinutes"],
                seconds: ["Program.Cycle.HeatTimeSeconds_TUM", "Program.Cycle.HeatCycleSeconds"]
              },
              min: 60,
              max: 5940
            }, {
              type: "duration",
              label: "without_heat",
              properties: {
                minutes: ["Program.Cycle.NoHeatTimeMinutes_TUM", "Program.Cycle.NoHeatCycleMinutes"],
                seconds: ["Program.Cycle.NoHeatTimeSeconds_TUM", "Program.Cycle.NoHeatCycleSeconds"]
              },
              min: 60,
              max: 5940
            }]
          }]
        }, {
          sections: [{
            title: "cooldown_times",
            controls: [{
              type: "number",
              propertyName: ["Program.Cycle.HighTempCooldownMinutes_TUM", "Program.Cycle.HighCoolDownMinutes"],
              label: "high",
              min: 1,
              max: 15,
              labelAfter: "minutes"
            }, {
              type: "number",
              propertyName: ["Program.Cycle.MediumTempCooldownMinutes_TUM", "Program.Cycle.MedCoolDownMinutes"],
              label: "medium",
              min: 1,
              max: 15,
              labelAfter: "minutes"
            }, {
              type: "number",
              propertyName: ["Program.Cycle.LowTempCooldownMinutes_TUM", "Program.Cycle.LowCoolDownMinutes"],
              label: "low",
              min: 1,
              max: 15,
              labelAfter: "minutes"
            }, {
              type: "number",
              propertyName: ["Program.Cycle.DelicatesTempCooldownMinutes_TUM", "Program.Cycle.DelicateCoolDownMinutes"],
              label: "delicate",
              min: 1,
              max: 15,
              labelAfter: "minutes"
            }]
          }, {
            title: "top_off",
            controls: [{
              type: "duration",
              label: "coin_drop_1_top_off_time",
              properties: {
                minutes: ["Program.CoinPulse.Coin1Config.TopoffMinutes_TUM", "Program.CoinPulse.Coin1TopoffTimeMinutes"],
                seconds: ["Program.CoinPulse.Coin1Config.TopoffSeconds_TUM", "Program.CoinPulse.Coin1TopoffTimeSeconds"]
              },
              min: 10,
              max: 5940
            }, {
              type: "duration",
              label: "coin_drop_2_top_off_time",
              properties: {
                minutes: ["Program.CoinPulse.Coin2Config.TopoffMinutes_TUM", "Program.CoinPulse.Coin2TopoffTimeMinutes"],
                seconds: ["Program.CoinPulse.Coin2Config.TopoffSeconds_TUM", "Program.CoinPulse.Coin2TopoffTimeSeconds"]
              },
              min: 10,
              max: 5940
            }, {
              type: "duration",
              label: "payment_system_topoff_time",
              properties: {
                minutes: ["Program.Price.Misc.PSTopoffMinutes_TUM", "Program.CoinPulse.PaymentSystemTopoffMinutes"],
                seconds: ["Program.Price.Misc.PSTopoffSeconds_TUM", "Program.CoinPulse.PaymentSystemTopoffSeconds"]
              },
              min: 10,
              max: 5940
            }, {
              type: "duration",
              label: "remote_top_off_time",
              properties: {
                minutes: "Program.Price.Misc.RemoteTopoffMinutes_TUM",
                seconds: "Program.Price.Misc.RemoteTopoffSeconds_TUM"
              },
              min: 0,
              max: 5940,
              notMachine: "MGD_M1_DRY"
            }]
          }, {
            title: "bonus_time_per_coin",
            ifMachine: "MGD_M1_DRY",
            controls: [{
              type: "duration",
              label: "coin_drop_1_bonus_time",
              properties: {
                minutes: "Program.CoinPulse.Coin1BonusTimeMinutes",
                seconds: "Program.CoinPulse.Coin1BonusTimeSeconds"
              },
              min: 0,
              max: 3600
            }, {
              type: "duration",
              label: "coin_drop_2_bonus_time",
              properties: {
                minutes: "Program.CoinPulse.Coin2BonusTimeMinutes",
                seconds: "Program.CoinPulse.Coin2BonusTimeSeconds"
              },
              min: 0,
              max: 3600
            }]
          }]
        }]
      };
    })
  });

  _exports.default = _default;
});