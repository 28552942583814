define("alliance-business-suite/models/machine-audit", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    vendAuditData: attr(),
    totalOperationMinutes: attr(),
    totalNumberOfRemoteVendCycles: attr(),
    totalNumberOfRapidAdvanceCycles: attr(),
    totalNumberOfOplModeCycles: attr(),
    totalNumberOfMachineCycles: attr(),
    totalNumberOfLuckyCycles: attr(),
    totalNumberOfDropOffCycles: attr(),
    switchAuditData: attr(),
    shortedThermistorErrorAuditCounter: attr(),
    rotationSensorErrorAuditCounter: attr(),
    programmingParameters: attr(),
    powerFailureAuditData: attr(),
    originalPassedBytes: attr(),
    openThermistorErrorAuditCounter: attr(),
    lastEightErrorsQueuePointer: attr(),
    cycleHistoryQueuePointer: attr(),
    cycleHistoryQueue: attr(),
    createdAt: attr(),
    receivedAt: attr(),
    lastEightErrorsQueue: attr(),
    irNetworkAuditData: attr(),
    configStatus: attr(),
    cardRelatedAuditData: attr(),
    machine: belongsTo('machine')
  });

  _exports.default = _default;
});