define("alliance-business-suite/components/machines/programs/pricing/midas-ezopl-m3-tum", ["exports", "alliance-business-suite/mixins/machines/programs/midas-options", "alliance-business-suite/mixins/machines/programs/midas-property-names"], function (_exports, _midasOptions, _midasPropertyNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_midasOptions.default, _midasPropertyNames.default, {
    // Need to observe any properties used for enabled, etc.
    formConfig: Ember.computed('propertyNames.{coinDrop,luckyCycle,topOff,worldDrop,defaultModifier}', function () {
      return {
        columns: [{
          sections: [{
            title: "base_price",
            controls: [{
              type: "currency",
              propertyName: "Program.Price.CyclePrices.HeatCycle_TUM",
              min: 0,
              max: 65535
            }, {
              type: "currency",
              propertyName: "Program.Price.CyclePrices.NoHeatCycle_TUM",
              min: 0,
              max: 65535
            }]
          }, {
            title: "defaults",
            controls: [{
              type: "select",
              propertyName: "Program.Cycle.DefaultCycle_TUM",
              options: "DefaultCycles"
            }, {
              type: "default-modifier",
              label: "DefaultModifier",
              properties: this.propertyNames.defaultModifier
            }, {
              type: "select",
              propertyName: "Program.Display.NumericSeparator",
              options: "NumericSeparator"
            }, {
              type: "select",
              propertyName: "Program.Price.Misc.Currency1.Symbol",
              options: "CurrencyType"
            }, {
              type: "select",
              propertyName: "Program.Price.Misc.Currency1.PlaceAfter",
              options: "SymbolPlacement"
            }]
          }, {
            title: "coin_drops",
            controls: [// Enable coin 1
            {
              type: "checkbox-number-combo",
              numberType: "currency",
              properties: {
                checkbox: {
                  key: "Program.CoinPulse.Coin1Enabled",
                  label: "Coin1Enabled"
                },
                number: {
                  key: "Program.CoinPulse.Coin1Config.Value",
                  label: "value"
                }
              },
              min: 0,
              max: 65535
            }, // Enable coin 2
            {
              type: "checkbox-number-combo",
              numberType: "currency",
              properties: {
                checkbox: {
                  key: "Program.CoinPulse.Coin2Enabled",
                  label: "Coin2Enabled"
                },
                number: {
                  key: "Program.CoinPulse.Coin2Config.Value",
                  label: "value"
                }
              },
              min: 0,
              max: 65535
            }, // World drop settings
            {
              type: "world-drop",
              properties: this.propertyNames.worldDrop
            }]
          }, {
            title: "payment_method",
            controls: [{
              type: "coin-drop",
              properties: this.propertyNames.coinDrop
            }, // Card
            {
              type: "checkbox",
              propertyName: "Program.Price.Misc.PaymentMethods.Card"
            }, // Bill
            {
              type: "checkbox",
              propertyName: "Program.Price.Misc.PaymentMethods.Bill"
            }, // Phone
            {
              type: "checkbox",
              propertyName: "Program.Price.Misc.PaymentMethods.Mobile"
            }, // Kiosk
            {
              type: "checkbox",
              propertyName: "Program.Price.Misc.PaymentMethods.Kiosk"
            }]
          }]
        }, {
          sections: [{
            title: "lucky_cycle",
            controls: [{
              type: "lucky-cycle",
              properties: this.propertyNames.luckyCycle
            }]
          }, {
            title: "top_off",
            controls: [{
              type: "top-off",
              properties: this.propertyNames.topOff,
              topoffEnabledValue: 1
            }]
          }, {
            title: "anti_wrinkle",
            controls: [// Enable
            {
              type: "checkbox",
              propertyName: "Program.Cycle.AntiWrinkleEnabled_TUM"
            }, // Prompt user
            {
              type: "checkbox",
              propertyName: "Program.Price.ModifierOptions.ModsAdditionalOptsEnabled.AntiWrinkle_TUM",
              labelKey: "prompt_user"
            }, // Additional price
            {
              type: "currency",
              propertyName: "Program.Price.ModifierPrices.AntiWrinkleAdder_TUM",
              min: 0,
              max: 65535
            }, // Time min.
            {
              type: "number",
              propertyName: "Program.Cycle.AntiWrinkleDuration_TUM",
              labelAfter: "minutes"
            }]
          }, {
            title: "reversing",
            controls: [// Enable
            {
              type: "checkbox",
              propertyName: "Program.Cycle.ReversingEnabled_TUM"
            }, // Prompt user
            {
              type: "checkbox",
              propertyName: "Program.Price.ModifierOptions.ModsAdditionalOptsEnabled.Reversing_TUM",
              labelKey: "prompt_user"
            }, // Additional price
            {
              type: "currency",
              propertyName: "Program.Price.ModifierPrices.ReversingAdder_TUM",
              min: 0,
              max: 65535
            }, // Rotate time sec.
            {
              type: "select",
              propertyName: "Program.Cycle.ReversingRotateTime_TUM",
              options: "ReversingRotateTime"
            }, // Pause time sec.
            {
              type: "select",
              propertyName: "Program.Cycle.ReversingStopTime_TUM",
              options: "ReversingStopTime"
            }]
          }]
        }]
      };
    })
  });

  _exports.default = _default;
});