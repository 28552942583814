define("alliance-business-suite/components/machines/programs/combo-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    classNames: ['btn-group', 'settings'],
    classNameBindings: ['open'],
    icon: 'fa-pencel',
    open: false,
    position: 'right',
    actions: {
      manage() {
        Ember.set(this, 'open', false);
        this.manage();
      },

      send() {
        Ember.set(this, 'open', false);
        this.sendProgram();
      },

      download() {
        Ember.set(this, 'open', false);
        this.download();
      },

      export() {
        Ember.set(this, 'open', false);
        this.export();
      },

      delete() {
        Ember.set(this, 'open', false);
        this['delete']();
      }

    }
  });

  _exports.default = _default;
});