define("alliance-business-suite/components/form-ui/temp", ["exports", "alliance-business-suite/mixins/form-ui", "alliance-business-suite/utils/is-number"], function (_exports, _formUi, _isNumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_formUi.default, {
    tagName: 'div',
    classNames: ['form-field', 'overflow-float', 'number-field', 'text-field'],
    // These need to be gone if displayValue is custom
    intl: Ember.inject.service(),

    // TODO: Serialization and deserialization may not work correctly with divideBy values. This logic may need to move to the change() event.
    serializeValue(rawValue) {
      // Override in component, like a getter
      // if (get(this, 'type') === 'number' && isNumber(rawValue)) {
      //   let divideBy = get(this, 'params.divideBy') || 1;
      //   return rawValue / divideBy;
      // } else {
      //   return rawValue;
      // }
      return rawValue + '˚C';
    },

    deserializeValue(value) {
      // Override in component, like a setter
      // if (get(this, 'type') === 'number' && isNumber(value)) {
      //   // Deal with numbers that need the decimal place shifted
      //   let divideBy = get(this, 'params.divideBy') || 1;
      //   value *= divideBy;
      // }
      value = value.replace(/˚C/, '');
      return value;
    }

  });

  _exports.default = _default;
});