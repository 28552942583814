define("alliance-business-suite/serializers/account-refund", ["exports", "alliance-business-suite/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    /**
     * Whether `includes` from the JSON API response should be pushed to the
     * store.
     *
     * @type {boolean}
     * @protected
     */
    allowIncludes: false,

    /**
     * Customizes how a record is serialized into a JSON API payload to be sent
     * to the server.
     *
     * @param {record} record The data object to be sent to the server
     * @param {object} options Options for how to serialize the record
     *
     * @returns {object} A JSON API document
     *
     * @protected
     */
    serialize(record, options) {
      let json = this._super(record, options);

      json.data.type = 'refunds';
      delete json.data.relationships;
      return json;
    }

  });

  _exports.default = _default;
});