define("alliance-business-suite/models/room", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    hasMany,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    access: Ember.inject.service(),
    // geo: belongsTo('organization'),
    roomName: attr('string'),
    breadcrumbDisplay: attr('string'),
    breadcrumbId: attr('string'),
    isActive: attr('boolean'),
    createdAt: attr('date'),
    createdBy: attr('string'),
    updatedAt: attr('date'),
    updatedBy: attr('string'),
    geomName: attr('string'),
    latitude: attr(),
    longitude: attr(),
    timeZoneName: attr('string'),
    internationalTimeZoneName: attr('string'),
    internationalTimeZoneAbbreviation: attr('string'),
    hasCoin: attr('boolean'),
    canSms: attr('boolean'),
    hasWashAlert: attr('boolean'),
    washAlertTheme: attr('string'),
    hasWashAlertRoomName: attr('boolean'),
    hasWashAlertClock: attr('boolean'),
    hasWashAlertLegend: attr('boolean'),
    washAlertCanvasResize: attr('boolean'),
    taxRate: attr('string'),
    washAlertImageUrl: attr('string'),
    serviceEmail: attr('string'),
    machines: hasMany('machines'),
    machineAuditGroupTypes: hasMany('machineAuditGroupTypes'),
    gateways: hasMany('gateways'),
    servicePhoneNumber: attr('string'),
    isRetail: attr('boolean'),
    isCrm: attr('boolean'),
    isTrade: attr('boolean'),
    locationPin: attr('string'),
    signupBonusAmount: attr('number'),
    signupBonusTrigger: attr('string'),
    // expirationDateUtc: attr('string'),
    maxRedemptions: attr('number'),
    shiftBlocks: hasMany('shiftBlock'),
    inventory: hasMany('inventory'),
    reorderGuidelines: hasMany('reorder-guideline'),
    cycleCount: belongsTo('cycle-count'),
    vcms: hasMany('vcm'),
    // social media urls
    socialMediaFacebook: attr('string'),
    socialMediaInstagram: attr('string'),
    socialMediaYelp: attr('string'),
    socialMediaGoogle: attr('string'),
    receiptMarkupJson: belongsTo('receipt-markup-json', {
      async: true
    }),

    /* Computed Properties */
    lat: Ember.computed.alias('latitude'),
    lng: Ember.computed.alias('longitude'),
    brushLabel: Ember.computed.alias('roomName').readOnly(),
    brushSublabel: Ember.computed({
      get() {
        return 'Location';
      }

    }).readOnly(),
    googleMapsUrl: Ember.computed('latitude', 'longitude', {
      get() {
        return `http://maps.google.com/?q=${this.latitude},${this.longitude}`;
      }

    }).readOnly(),
    machinesLoaded: Ember.computed('machines', 'machines.{isLoaded,isFulfilled}', {
      get() {
        let machines = this.machines;
        let isLoaded = Ember.get(machines, 'isLoaded');
        let isFulfilled = Ember.get(machines, 'isFulfilled');

        if (isLoaded !== undefined) {
          return isLoaded;
        } else if (isFulfilled !== undefined) {
          return isFulfilled;
        } else {
          return machines && Ember.get(machines, 'length') > -1;
        }
      }

    }),
    formattedBreadcrumbDisplay: Ember.computed('breadcrumbDisplay', 'roomName', function () {
      let breadcrumbDisplay = this.breadcrumbDisplay;

      if (breadcrumbDisplay) {
        // special case for room named "24/7 Coin Laundry"
        if (this.id == 3050) {
          return "24/7 Coin Laundry";
        }

        let str = breadcrumbDisplay.substring(breadcrumbDisplay.lastIndexOf('/') + 1);
        return str;
      }
    })
  });

  _exports.default = _default;
});