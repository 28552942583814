define("alliance-business-suite/components/form-ui/steps/cooldown-type", ["exports", "alliance-business-suite/mixins/init-property-names", "alliance-business-suite/helpers/find-by-dropdown-with-custom"], function (_exports, _initPropertyNames, _findByDropdownWithCustom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_initPropertyNames.default, {
    optionsKeyValue: Ember.computed('optionsKey', 'formSettings.selectOptions', function () {
      return this.selectOptions["CooldownType"];
    }),
    cooldownType: Ember.computed('AntiWrinkle', 'PlumbingCode', {
      get() {
        // Get options
        let options = this.optionsKeyValue; // Find a match...

        let selected = options.find(item => {
          return item.antiWrinkle == this.AntiWrinkle && item.plumbingCode == this.PlumbingCode;
        });
        return Ember.get(selected, "val");
      },

      set(key, value) {
        // We do nothing.
        return value;
      }

    }),
    actions: {
      updateValue(object, propertyName, value) {
        // Find the correct values
        let {
          antiWrinkle,
          plumbingCode
        } = (0, _findByDropdownWithCustom.findByDropDown)([this.optionsKeyValue, "val", value]);
        this.update(this.object, this.properties.AntiWrinkle.key, antiWrinkle);
        this.update(this.object, this.properties.PlumbingCode.key, plumbingCode);
      }

    }
  });

  _exports.default = _default;
});