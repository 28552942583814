define("alliance-business-suite/mixins/crm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    cache: Ember.inject.service(),

    setCrmPreviewProperties(obj) {
      let payload = {
        'Params': []
      };
      let {
        cache
      } = this;

      if (obj.type == "filters") {
        let filters = obj.content;
        filters.forEach(function (filter) {
          let f = null; // massage the data if coming from local storage or not

          if (filter.options) {
            f = filter.options;
          } else {
            f = [filter];
          }

          f.forEach(function (option) {
            payload.Params.push(option);
          });
        });
        cache.set('paramFilters', JSON.stringify(payload));
      } // convert the selected room ids to {123, 456}


      if (obj.type == "rooms") {
        let rooms = obj.content;
        let selectedRoomIds = [];
        rooms.forEach(room => {
          // massage the data if coming from local storage or not
          if (room.id) {
            selectedRoomIds.push(room.id);
          } else {
            selectedRoomIds.push(room);
          }
        });
        cache.set('paramSelectedRoomIds', `{${selectedRoomIds.join()}}`);
      }

      if (obj.type == 'deliveryType') {
        cache.set('paramDeliveryMethod', obj.content);
      }

      Ember.run.debounce(this, this.fetchPreviewCall, 2000);
    },

    fetchPreviewCall() {
      let self = this;
      let {
        cache
      } = this;
      let {
        paramSelectedRoomIds,
        paramFilters,
        paramDeliveryMethod
      } = cache;
      let organizationId = this.session.get('data.orgId');
      let roomIds = paramSelectedRoomIds ? paramSelectedRoomIds : '{}';
      let deliveryMethodLookupKey = paramDeliveryMethod ? paramDeliveryMethod : 'NONE';

      if (!paramSelectedRoomIds) {
        cache.set('showCrmPreviewBanner', false);
        return;
      }

      let dataOptions = {
        roomIds: roomIds,
        deliveryMethodLookupKey: `${deliveryMethodLookupKey}`,
        raw: true,
        params: paramFilters ? paramFilters : JSON.stringify({
          'Params': []
        })
      };
      cache.set('loadingCrmPreviewBanner', true);
      this.ajax.request(`/organizations/${organizationId}/trackingCounts`, {
        method: 'GET',
        data: dataOptions
      }).then(function (results) {
        if (!self.isDestroyed) {
          self.set('isLoading', false);
        }

        if (results.eligibleUserCount >= 0) {
          cache.setProperties({
            crmPreviewMatching: results.eligibleUserCount,
            crmPreviewTotal: results.filteredUserCount,
            showCrmPreviewBanner: true,
            loadingCrmPreviewBanner: false
          });
        }
      }, function () {
        self.set('isLoading', false);
        cache.setProperties({
          crmPreviewMatching: null,
          crmPreviewTotal: null,
          showCrmPreviewBanner: false,
          loadingCrmPreviewBanner: false
        });
      });
    }

  });

  _exports.default = _default;
});