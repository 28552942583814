define("alliance-business-suite/templates/components/table/table-cell-button-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aEiVC5tY",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[35,0,[\"customRouteTo\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"link-to\",[[24,0,\"button button--secondary button--small\"]],[[\"@route\",\"@model\",\"@tagName\"],[[34,0,[\"customRouteTo\"]],[30,[36,5],[[35,4],[35,0,[\"propertyName\"]]],null],\"button\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-cog\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[35,0,[\"buttonTitle\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[11,\"button\"],[16,0,[31,[[34,0,[\"buttonClass\"]]]]],[4,[38,1],[[32,0],\"go\"],null],[12],[2,\"\\n    \"],[1,[35,0,[\"buttonTitle\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[35,0,[\"buttonContent\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"button\"],[16,0,[31,[[34,0,[\"buttonClass\"]]]]],[4,[38,1],[[32,0],\"go\"],null],[12],[2,\"\\n    \"],[2,[34,2]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"column\",\"action\",\"buttonContent\",\"if\",\"record\",\"get\"]}",
    "moduleName": "alliance-business-suite/templates/components/table/table-cell-button-options.hbs"
  });

  _exports.default = _default;
});