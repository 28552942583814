define("alliance-business-suite/templates/components/input/language-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8UwW2ylN",
    "block": "{\"symbols\":[\"lang\"],\"statements\":[[8,\"power-select\",[],[[\"@options\",\"@selected\",\"@searchEnabled\",\"@onChange\"],[[34,1],[34,2],false,[30,[36,4],[[32,0],[30,[36,3],[[35,2]],null]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[35,2],[32,1,[\"local\"]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,0],[[32,1,[\"local\"]]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[30,[36,0],[[32,1,[\"local\"]]],null]],[2,\" (\"],[1,[32,1,[\"name\"]]],[2,\")\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"newLocales\",\"currentLocale\",\"mut\",\"action\",\"eq\",\"if\"]}",
    "moduleName": "alliance-business-suite/templates/components/input/language-select.hbs"
  });

  _exports.default = _default;
});