define("alliance-business-suite/components/summary/reports/s-lifetime-operation", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "alliance-business-suite/utils/currency-format-util", "alliance-business-suite/utils/report-tables-util"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _currencyFormatUtil, _reportTablesUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  let SummaryReportsSLifetimeOperationComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, (_class = class SummaryReportsSLifetimeOperationComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "brand", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor4, this);
    }

    get translatedColumns() {
      return [{
        name: 'location_machine',
        valuePath: 'name',
        width: 180,
        isFixed: 'left'
      }, {
        name: 'machine_clock',
        valuePath: 'machineTime',
        width: 180
      }, {
        name: 'total_cycles',
        valuePath: 'totalCycles',
        width: 180
      }, {
        name: 'rapid_advance',
        valuePath: 'rapidAdvanceCycles',
        width: 180
      }, {
        name: 'operation_hours',
        valuePath: 'operationHours',
        width: 180
      }];
    }

    get rowsWithChildren() {
      let geoBoundaries = this.args.model.get('geoBoundaries');
      let rowData = [];
      geoBoundaries.forEach(geoBoundary => {
        let nameArray = [];
        let cycleSum = 0;
        let medModSum = 0;
        let heavModSum = 0;
        let totalRoomCycles = [];
        let totalMedMod = [];
        let totalheavMod = [];
        rowData.push({
          name: _reportTablesUtil.localizeString.call(this, geoBoundary.name, 'Location', 'location'),
          children: nameArray,
          totalCycles: totalRoomCycles,
          rapidAdvanceCycles: totalMedMod,
          operationHours: totalheavMod
        });
        geoBoundary.rooms.forEach(room => {
          let roomArray = [];
          let cy1cleSum = 0;
          let me1dModSum = 0;
          let he1avModSum = 0;
          let to1talRoomCycles = [];
          let to1talMedMod = [];
          let to1talheavMod = [];
          nameArray.push({
            name: room.name,
            children: roomArray,
            totalCycles: to1talRoomCycles,
            rapidAdvanceCycles: to1talMedMod,
            operationHours: to1talheavMod
          });
          room.machineAuditGroupTypes.forEach(machineAuditType => {
            machineAuditType.machineTypes.forEach(machineType => {
              let machineArray = [];
              let machineTypeName = `${machineAuditType.name} - ${machineType.name}`;
              let cy2cleSum = 0;
              let me2dModSum = 0;
              let he2avModSum = 0;
              let to2talRoomCycles = [];
              let to2talMedMod = [];
              let to2talheavMod = [];
              roomArray.push({
                name: machineTypeName,
                machineTypeName: machineType.name,
                children: machineArray,
                totalCycles: to2talRoomCycles,
                rapidAdvanceCycles: to2talMedMod,
                operationHours: to2talheavMod
              });
              machineType.machines.forEach(machine => {
                let machineCycleArray = [];
                let medModArray = [];
                let heavModArray = [];

                if (this.brand.slug == 'primus') {
                  // fix the branding issue alliance-tasks/issues/2291
                  roomArray[roomArray.length - 1].name = `${machine.machineAuditType.name} - ${roomArray[roomArray.length - 1].machineTypeName}`;
                }

                if (machine.totalCycles !== 0) {
                  machineCycleArray.push(machine.totalCycles);
                  cycleSum = (0, _reportTablesUtil.totalRows)(machineCycleArray, cycleSum);
                  cy1cleSum = (0, _reportTablesUtil.totalRows)(machineCycleArray, cy1cleSum);
                  cy2cleSum = (0, _reportTablesUtil.totalRows)(machineCycleArray, cy2cleSum);
                }

                if (machine.rapidAdvanceCycles !== 0) {
                  medModArray.push(machine.rapidAdvanceCycles);
                  medModSum = (0, _reportTablesUtil.totalRows)(medModArray, medModSum);
                  me1dModSum = (0, _reportTablesUtil.totalRows)(medModArray, me1dModSum);
                  me2dModSum = (0, _reportTablesUtil.totalRows)(medModArray, me2dModSum);
                }

                if (machine.operationHours !== 0) {
                  heavModArray.push(machine.operationHours);
                  heavModSum = (0, _reportTablesUtil.totalRows)(heavModArray, heavModSum);
                  he1avModSum = (0, _reportTablesUtil.totalRows)(heavModArray, heavModSum);
                  he2avModSum = (0, _reportTablesUtil.totalRows)(heavModArray, heavModSum);
                }

                let machineName = _reportTablesUtil.machineNameDisplay.call(this, machine.machineName, machine.machineNumber);

                let totalCycle = machine.totalCycles || 0;
                let rapidAdvanceCycle = machine.rapidAdvanceCycles || 0;
                let operationHour = machine.operationHours || 0;
                let machineTime = moment(machine.machineTime).format(`${_reportTablesUtil.formatDateTimeString.call(this)}`);
                machineArray.push({
                  name: machineName,
                  machineTime: machineTime,
                  totalCycles: totalCycle,
                  rapidAdvanceCycles: rapidAdvanceCycle,
                  operationHours: operationHour
                });
              });
              to2talRoomCycles.push(cy2cleSum || 0);
              to2talMedMod.push(me2dModSum || 0);
              to2talheavMod.push(he2avModSum || 0);
            });
          });
          to1talRoomCycles.push(cy1cleSum || 0);
          to1talMedMod.push(me1dModSum || 0);
          to1talheavMod.push(he1avModSum || 0);
        });
        totalRoomCycles.push(cycleSum || 0);
        totalMedMod.push(medModSum || 0);
        totalheavMod.push(heavModSum || 0);
      });
      return rowData;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "brand", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SummaryReportsSLifetimeOperationComponent;
});