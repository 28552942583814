define("alliance-business-suite/components/inventory/inventory-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    access: Ember.inject.service(),
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    classNames: ['inventory-section'],
    currentView: 'canView',
    isLoading: false,
    rooms: Ember.computed(function () {
      let allRooms = this.store.peekAll('room').sortBy('formattedBreadcrumbDisplay');
      return allRooms.filter(room => {
        if (room.get('isRetail')) {
          return true;
        }
      });
    }),
    selectedRoom: Ember.computed('rooms', function () {
      if (this.rooms.length === 1) {
        this.send('changeRoom', this.rooms.firstObject);
        return this.rooms.firstObject;
      }
    }),
    selectedLocationType: Ember.computed('selectedRoom', function () {
      let selectedRoom = this.selectedRoom;
      return selectedRoom.get('constructor.modelName');
    }),
    inventoryFiltered: Ember.computed('inventory.[]', 'selectedRoom', 'items.[]', function () {
      let selectedRoom = this.selectedRoom; // if modelType is org, show all the items in the org
      // if (selectedRoom.get('constructor.modelName') == 'organization') {
      //   return this.get('items');
      // }

      if (selectedRoom) {
        return this.store.peekAll('inventory').filterBy('room.id', selectedRoom.get('id'));
      } else {
        return [];
      }
    }),
    actions: {
      changeRoom(room) {
        this.set('isLoading', true);
        this.store.findAll('inventory', {
          reload: true,
          adapterOptions: {
            roomId: room.get('id')
          }
        }).then(inventory => {
          this.set('selectedRoom', room);
          this.set('isLoading', false);
        }, () => {
          this.set('isLoading', false);
        });
      },

      showCreateInventoryModal() {
        this.set('selectedRooms', [this.selectedRoom]);
        this.toggleProperty('showCreateInventoryModal');
      }

    }
  });

  _exports.default = _default;
});