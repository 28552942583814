define("alliance-business-suite/models/security-functional-area", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    hasMany,
    belongsTo
  } = _emberData.default;
  const allCharts = ['SUMMARY_CHARTS_VIEW_DAILY_REVENUE_CHART', 'SUMMARY_CHARTS_VIEW_LOCATION_REVENUE_CHART', 'SUMMARY_CHARTS_VIEW_WASHER_REVENUE_CHART', 'SUMMARY_CHARTS_VIEW_DRYER_REVENUE_CHART', 'SUMMARY_CHARTS_VIEW_TURNS_CHART', 'SUMMARY_CHARTS_VIEW_DAILY_REVENUE_CHART', 'SUMMARY_CHARTS_VIEW_TURNS_PER_DAY_CHART', 'SUMMARY_CHARTS_VIEW_REVENUE_BY_MACHINE_SIZES_CHART', 'SUMMARY_CHARTS_VIEW_TURNS_PER_DAY_BREAKDOWN_CHART'];
  const routeCharts = ['SUMMARY_CHARTS_VIEW_LOCATION_REVENUE_CHART', 'SUMMARY_CHARTS_VIEW_WASHER_REVENUE_CHART', 'SUMMARY_CHARTS_VIEW_DRYER_REVENUE_CHART' // 'SUMMARY_CHARTS_VIEW_TURNS_CHART'
  ];

  var _default = Model.extend({
    lookupKey: attr(),
    parentId: attr(),
    isRouteChart: Ember.computed('lookupKey', function () {
      // Routes have additional reports that are not displayed for Laundromats
      let key = this.lookupKey;

      if (allCharts.includes(key)) {
        return routeCharts.includes(key);
      }
    })
  });

  _exports.default = _default;
});