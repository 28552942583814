define("alliance-business-suite/templates/components/standard-currency-input-format", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Y03+Kwxv",
    "block": "{\"symbols\":[],\"statements\":[[10,\"input\"],[14,\"data-type\",\"currency\"],[14,3,\"currency-field\"],[15,1,[34,0]],[14,\"pattern\",\"^\\\\$\\\\d{1,3}(,\\\\d{3})*(\\\\.\\\\d+)?$\"],[15,\"disabled\",[34,1]],[15,0,[34,2]],[15,2,[34,3]],[15,\"onkeyup\",[30,[36,4],[[32,0],\"maskCurrencyInput\"],null]],[15,\"onkeyup\",[30,[36,4],[[32,0],\"setUnmaskedCurrency\"],null]],[14,4,\"currency\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"inputId\",\"disabled\",\"class\",\"currencyDisplay\",\"action\"]}",
    "moduleName": "alliance-business-suite/templates/components/standard-currency-input-format.hbs"
  });

  _exports.default = _default;
});