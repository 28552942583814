define("alliance-business-suite/templates/components/summary/reports/s-value-center-collections", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Xz64kIpW",
    "block": "{\"symbols\":[\"@height\"],\"statements\":[[8,\"summary/reports/default-report-shim\",[],[[\"@skipTranslatingHeaders\",\"@height\",\"@translatedColumns\",\"@rowsWithChildren\"],[true,[32,1],[32,0,[\"translatedColumns\"]],[32,0,[\"rowsWithChildren\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "alliance-business-suite/templates/components/summary/reports/s-value-center-collections.hbs"
  });

  _exports.default = _default;
});