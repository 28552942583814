define("alliance-business-suite/templates/components/form-ui/power-fail-reset", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZN0bJomC",
    "block": "{\"symbols\":[\"ui\"],\"statements\":[[2,\" \"],[8,\"form-ui\",[],[[\"@object\",\"@formSettings\",\"@formDisplay\"],[[32,0,[\"object\"]],[32,0,[\"formSettings\"]],\"compact\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[8,[32,1,[\"checkbox\"]],[],[[\"@params\"],[[30,[36,0],null,[[\"propertyName\",\"label\"],[[32,0,[\"properties\",\"PowerFailResetEnabled\",\"key\"]],\"enable_power_fail_reset\"]]]]],null],[2,\"\\n\\n  \"],[8,[32,1,[\"number\"]],[],[[\"@params\"],[[30,[36,0],null,[[\"propertyName\",\"label\",\"enabled\",\"labelAfter\",\"min\",\"max\"],[[32,0,[\"properties\",\"PowerFailResetTime\",\"key\"]],\"timeout\",[30,[36,1],[[32,0,[\"object\"]],[32,0,[\"properties\",\"PowerFailResetEnabled\",\"key\"]]],null],\"minutes\",0,120]]]]],null],[2,\"\\n\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"hash\",\"get\"]}",
    "moduleName": "alliance-business-suite/templates/components/form-ui/power-fail-reset.hbs"
  });

  _exports.default = _default;
});