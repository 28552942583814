define("alliance-business-suite/models/sub-account", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    name: attr('string'),
    balance: attr('number'),
    rewardsPoint: attr('number'),
    createdAt: attr('date'),
    updatedAt: attr('date'),
    // Being returned as an object attribute with `id` and `lookupKey` properties
    // but no entity exists for this yet. [twl 3.May.17]
    //
    //   paymentGateway: belongsTo?
    // Not being returned from the API yet. [twl 3.May.17]
    //
    //   createdAt: attr('date'),
    //   updatedAt: attr('date'),
    // Not sure what this entity looks like yet. [twl 3.May.17]
    //
    //   rewardsGroup: belongsTo('rewardsGroup')
    geoBoundary: belongsTo('geoBoundary'),
    userAccount: belongsTo('account'),
    createdBy: belongsTo('user', {
      inverse: null
    }),
    updatedBy: belongsTo('user', {
      inverse: null
    })
  });

  _exports.default = _default;
});