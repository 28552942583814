define("alliance-business-suite/templates/components/brushes/machine-brush", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AE7sxXVX",
    "block": "{\"symbols\":[\"brush\"],\"statements\":[[8,\"brushes/async-brush\",[],[[\"@modelType\"],[\"machine\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"machines/m-view\",[],[[\"@model\"],[[32,1,[\"loadedModel\"]]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "alliance-business-suite/templates/components/brushes/machine-brush.hbs"
  });

  _exports.default = _default;
});