define("alliance-business-suite/mixins/campaign-mixin", ["exports", "alliance-business-suite/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const FILTERS = [{
    id: 'LAST_ACTIVE',
    text: 'last_visit'
  }, {
    id: 'SERVICE_TYPE',
    text: 'usage_type'
  }, {
    id: 'PREFERRED_DAY',
    text: 'preferred_day_of_week'
  }, {
    id: 'WASH_DRY_FOLD',
    text: 'avg_visit_spend_wash_dry_fold'
  }, {
    id: 'GOODS',
    text: 'avg_visit_spend_goods'
  }, {
    id: 'MACHINE',
    text: 'avg_visit_spend_self_service'
  }];
  const LAST_ACTIVE_OPTIONS = [{
    id: '1 day',
    qualifier: 'WITHIN_RANGE',
    text: 'last_24_hours'
  }, {
    id: '7 days',
    qualifier: 'WITHIN_RANGE',
    text: 'last_7_days'
  }, {
    id: '1 month',
    qualifier: 'WITHIN_RANGE',
    text: 'last_month'
  }, {
    id: '1 week',
    qualifier: 'OUTSIDE_RANGE',
    text: 'more_than_1_week'
  }, {
    id: '1 month',
    qualifier: 'OUTSIDE_RANGE',
    text: 'more_than_1_month'
  }, {
    id: 'custom',
    qualifier: 'WITHIN_RANGE',
    text: 'custom_date'
  }];

  var _default = Ember.Mixin.create({
    access: Ember.inject.service(),
    ajax: Ember.inject.service(),
    paramsFilter: Ember.computed('applySelectedFilters', function () {
      let applySelectedFilters = this.applySelectedFilters;
      let result = [];

      for (let prop in applySelectedFilters) {
        if (prop != 'size') {
          if (prop == 'LAST_ACTIVE') {
            let obj = {};
            obj.id = prop;
            obj.options = [];
            let lastActiveOption = {};
            applySelectedFilters[prop].options.forEach(f => {
              if (f.name == 'LAST_VISIT_INTERVAL') {
                lastActiveOption.interval = f.value;
              } else if (f.name == 'LAST_VISIT_QUALIFIER') {
                lastActiveOption.qualifier = f.value;
              } else if (f.name == 'LAST_VISIT_START_DATE' || f.name == 'LAST_VISIT_END_DATE') {
                let option = {};
                option.name = f.name;
                option.value = f.value;
                obj.options.push(option);
              }

              if (lastActiveOption.interval && lastActiveOption.qualifier) {
                LAST_ACTIVE_OPTIONS.forEach(o => {
                  if (lastActiveOption.interval === o.id && lastActiveOption.qualifier === o.qualifier) {
                    let option = {};
                    option.name = 'LAST_VISIT_INTERVAL';
                    option.value = o.text;
                    obj.options.push(option);
                  }
                });
              }
            });
            result.push(obj);
          }

          if (prop == 'SERVICE_TYPE') {
            let obj = {};
            obj.id = prop;
            obj.options = [];
            applySelectedFilters[prop].options.forEach(f => {
              obj.options.push(f);
            });
            result.push(obj);
          }

          if (prop == 'DAY_OF_WEEK') {
            let obj = {};
            obj.id = applySelectedFilters[prop].filter.id;
            obj.options = [];
            applySelectedFilters[prop].options.forEach(f => {
              obj.options.push(f);
            });
            result.push(obj);
          }

          if (applySelectedFilters[prop].type == 'AVG_VEND_TYPE') {
            let obj = {};
            obj.id = `AVG_${applySelectedFilters[prop].filter.id}_SPEND`;
            obj.type = 'AVG_VEND_TYPE';
            obj.min = applySelectedFilters[prop].options.AVG_VEND_MIN;
            obj.max = applySelectedFilters[prop].options.AVG_VEND_MAX;
            result.push(obj);
          }
        }
      }

      return result;
    })
  });

  _exports.default = _default;
});