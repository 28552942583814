define("alliance-business-suite/components/table/table-cell-machine-program-options", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "alliance-business-suite/config/environment", "blueimp-md5", "alliance-business-suite/utils/download", "moment"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _environment, _blueimpMd, _download, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  let TableTableCellMachineProgramOptionsComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember._tracked, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, (_class = class TableTableCellMachineProgramOptionsComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "ajax", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "router", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "devtools", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "isCommandProcessing", _descriptor7, this);
    }

    get programId() {
      return this.args.record.get('id');
    }

    downloadMachineProgram() {
      document.body.style.cursor = 'wait';
      const url = `${_environment.default.APP.ALLIANCE_API_URL}/machinePrograms/${this.programId}/download`;
      this.isCommandProcessing = true;
      this.ajax.request(url).then(result => {
        this.args.column.get('action')(result); // send to the parent the download url
      }, result => {
        this.isCommandProcessing = false;
      });
    }

    manageItem() {
      this.router.transitionTo("machines.programs.manage", this.programId);
    }

    sendItem() {
      if (this.access.isDemo) {
        alert('Demo mode restricted.');
        return;
      }

      this.router.transitionTo("machines.programs.send", this.programId);
    }

    exportItem() {
      // this.router.transitionTo("machines.programs.export", this.programId);
      // Let's try to download this
      this.store.findRecord('machine-program', this.programId, {
        reload: true
      }).then(machineProgram => {
        const {
          name,
          settings
        } = machineProgram;
        const machineAuditType = Ember.get(machineProgram, "machineAuditType.id");
        const machineAuditGroupType = Ember.get(machineProgram, "machineAuditGroupType.id");
        const machineType = Ember.get(machineProgram, "machineType.id");
        const checksum = (0, _blueimpMd.default)(machineAuditType + machineAuditGroupType + machineType + JSON.stringify(settings));
        let fileContents = JSON.stringify({
          name,
          machineAuditType,
          machineAuditGroupType,
          machineType,
          dateExported: (0, _moment.default)().format(),
          settings,
          checksum
        });
        (0, _download.default)(fileContents, `${name.trim()}.json`);
      });
    }

    downloadItem() {
      if (this.access.isDemo) {
        alert('Demo mode restricted.');
        return;
      }

      this.downloadMachineProgram();
    }

    deleteItem() {
      if (this.access.isDemo) {
        alert('Demo mode restricted.');
        return;
      }

      try {
        if (confirm('Do you really want to delete this program?')) {
          this.args.record.destroyRecord();
        }
      } catch (e) {
        alert('Error deleting.');
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "devtools", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isCommandProcessing", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "manageItem", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "manageItem"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "sendItem", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "sendItem"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "exportItem", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "exportItem"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "downloadItem", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "downloadItem"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "deleteItem", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "deleteItem"), _class.prototype)), _class));
  _exports.default = TableTableCellMachineProgramOptionsComponent;
});