define("alliance-business-suite/templates/locations/wash-alert/ads", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7LgjyZjm",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"section--container-v2\"],[12],[2,\"\\n  \"],[10,\"header\"],[14,0,\"ui-route-header\"],[12],[2,\"\\n    \"],[10,\"h2\"],[12],[2,\"\\n      \"],[1,[35,4,[\"room\",\"roomName\"]]],[2,\"\\n      \"],[10,\"span\"],[14,0,\"ui-route-header__subtitle\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"ads_subtitle\"],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"controls controls--right\"],[12],[2,\"\\n\"],[6,[37,3],[[35,5]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"button\"],[14,0,\"primary\"],[14,\"disabled\",\"disabled\"],[12],[10,\"i\"],[14,0,\"fa fa-circle-o-notch fa-spin\"],[14,\"aria-hidden\",\"true\"],[12],[13],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[35,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[11,\"button\"],[24,0,\"primary\"],[4,[38,1],[[32,0],\"save\"],null],[12],[1,[30,[36,0],[\"save\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[10,\"button\"],[14,0,\"primary\"],[14,\"disabled\",\"disabled\"],[12],[1,[30,[36,0],[\"save\"],null]],[13],[2,\"\\n      \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[8,\"wash-alert/wa-ads\",[],[[\"@room\",\"@ads\",\"@hasChanged\",\"@deletedAds\"],[[34,4,[\"room\"]],[34,4,[\"ads\"]],[34,2],[34,6]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"action\",\"hasChanged\",\"if\",\"model\",\"isSaving\",\"deletedAds\"]}",
    "moduleName": "alliance-business-suite/templates/locations/wash-alert/ads.hbs"
  });

  _exports.default = _default;
});