define("alliance-business-suite/templates/components/machines/programs/input-money-int", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/6NJfXPg",
    "block": "{\"symbols\":[],\"statements\":[[10,\"label\"],[15,0,[31,[[34,8]]]],[12],[1,[34,9]],[2,\"\\n\"],[6,[37,11],[[30,[36,10],[[35,1],0],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"input\",[[24,0,\"form-field--control\"]],[[\"@type\",\"@value\",\"@min\",\"@max\",\"@allowDecimal\",\"@limit\",\"@disabled\",\"@step\"],[\"number\",[34,0],[34,3],[34,4],[34,5],[34,6],[34,2],[34,7]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"input-money\",[],[[\"@number\",\"@precision\",\"@class\",\"@disabled\"],[[34,0],[34,1],\"form-field--control\",[34,2]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"dev-tools\"],[12],[2,\"\\n  \"],[10,\"ul\"],[14,0,\"pills\"],[12],[2,\"\\n    \"],[10,\"li\"],[12],[2,\"decimalPlaces: \"],[1,[34,1]],[13],[2,\"\\n    \"],[10,\"li\"],[12],[2,\"property: \"],[1,[34,12]],[13],[2,\"\\n    \"],[10,\"li\"],[12],[2,\"displayValue: \"],[1,[34,0]],[13],[2,\"\\n    \"],[10,\"li\"],[12],[2,\"allowDecimal: \"],[1,[34,5]],[13],[2,\"\\n    \"],[10,\"li\"],[12],[2,\"limit: \"],[1,[34,6]],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"displayValue\",\"decimalPlaces\",\"disableIf\",\"min\",\"max\",\"allowDecimal\",\"limit\",\"step\",\"labelClass\",\"label\",\"eq\",\"if\",\"property\"]}",
    "moduleName": "alliance-business-suite/templates/components/machines/programs/input-money-int.hbs"
  });

  _exports.default = _default;
});