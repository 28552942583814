define("alliance-business-suite/serializers/alert", ["exports", "@ember-data/serializer/json"], function (_exports, _json) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AlertSerializer extends _json.default {// The Alerts endpoint is not JSON:API
  }

  _exports.default = AlertSerializer;
});