define("alliance-business-suite/components/form-ui/break-in-alarm", ["exports", "alliance-business-suite/mixins/init-property-names", "alliance-business-suite/utils/machine-programming-find-options"], function (_exports, _initPropertyNames, _machineProgrammingFindOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_initPropertyNames.default, {
    passcodeSelect: Ember.computed('machineProgram._machineCode', function () {
      // switch (this?.machineProgram?._machineCode) {
      switch (this?.machineProgram?._machineAuditType) {
        // case "MGD_M1_DRY":
        // case "MGD_M1_FLW":
        // case "CENTURION_C4_TUM":
        // case "CENTURION_C3_TUM":
        // case "CENTURION_C4_WX":
        // case "ACAS_A4_TUM":
        case "MGD_M1":
        case "CENTURION_C4":
        case "CENTURION_C4_ALS_DRIVE":
        case "CENTURION_C3":
        case "CENTURION_C3_ALS_DRIVE":
        case "ACAS_A44":
        case "ACAS_A4":
        case "ACAS_A3":
        case "TITAN_T4":
        case "MGD_M1":
          return true;

        default:
          // support machines before machineProgram was added
          return false;
      }
    }),
    optionsPasskey: (0, _machineProgrammingFindOptions.findOptions)([{
      machineAttributes: ["ACAS_A3"],
      options: [{
        val: 1,
        label: 'a_abbrev'
      }, {
        val: 2,
        label: 'b_abbrev'
      }, {
        val: 3,
        label: 'c_abbrev'
      }, {
        val: 5,
        label: 'normal_90'
      }, {
        val: 6,
        label: 'normal_60'
      }, {
        val: 7,
        label: 'normal_40'
      }, {
        val: 9,
        label: 'perm_press_60'
      }, {
        val: 10,
        label: 'delicate_30'
      }, {
        val: 11,
        label: 'delicate_no_heat'
      }, {
        val: 12,
        label: 'start'
      }]
    }, {
      auditType: ["TITAN_DRY", "TITAN_TUM"],
      options: [{
        val: 4,
        label: 'high_temp'
      }, {
        val: 5,
        label: 'low_temp'
      }, {
        val: 6,
        label: 'no_heat'
      }, {
        val: 7,
        label: 'medium_temp'
      }, {
        val: 8,
        label: 'delicates'
      }, {
        val: 9,
        label: 'start'
      }]
    }, {
      machineAttributes: ["CENTURION_C4_TUM"],
      options: [{
        val: 1,
        label: 'high_temp'
      }, {
        val: 2,
        label: 'low_temp'
      }, {
        val: 3,
        label: 'no_heat'
      }, {
        val: 5,
        label: 'delicates'
      }, {
        val: 6,
        label: 'start'
      }]
    }, {
      machineAttributes: ["ACAS_A4", "TITAN_T4", "CENTURION"],
      options: [{
        val: 1,
        label: 'light'
      }, {
        val: 2,
        label: 'medium'
      }, {
        val: 3,
        label: 'heavy'
      }, {
        val: 5,
        label: 'normal'
      }, {
        val: 6,
        label: 'perm_press'
      }, {
        val: 7,
        label: 'delicates_bulky'
      }, {
        val: 9,
        label: 'hot'
      }, {
        val: 10,
        label: 'warm'
      }, {
        val: 11,
        label: 'cold'
      }, {
        val: 12,
        label: 'start'
      }]
    }, {
      machineAttributes: ["ACAS_A44"],
      options: [{
        val: 1,
        label: 'regular'
      }, {
        val: 2,
        label: 'deluxe'
      }, {
        val: 3,
        label: 'ultra'
      }, {
        val: 5,
        label: 'normal'
      }, {
        val: 7,
        label: 'delicate'
      }, {
        val: 9,
        label: 'hot'
      }, {
        val: 10,
        label: 'warm'
      }, {
        val: 11,
        label: 'cold'
      }, {
        val: 12,
        label: 'start'
      }]
    }, {
      machineAttributes: ["TITAN", "MGD_M1_DRY"],
      options: [{
        val: 1,
        label: "button_temp"
      }, {
        val: 2,
        label: "button_down_arrow"
      }, {
        val: 3,
        label: "button_up_arrow"
      }, {
        val: 4,
        label: "button_start"
      }, {
        val: 10,
        label: "button_language"
      }, {
        val: 11,
        label: "button_help"
      }]
    }, {
      machineAttributes: ["TUM"],
      options: [[{
        val: 4,
        label: 'high_temp'
      }, {
        val: 5,
        label: 'low_temp'
      }, {
        val: 6,
        label: 'no_heat'
      }, {
        val: 7,
        label: 'medium_temp'
      }, {
        val: 8,
        label: 'delicates'
      }, {
        val: 9,
        label: 'start'
      }]]
    }, {
      machineAttributes: ["WX", "MGD_M1_FLW"],
      options: [{
        val: 1,
        label: "button_temp"
      }, {
        val: 2,
        label: "button_cycle"
      }, {
        val: 3,
        label: "button_soil"
      }, {
        val: 4,
        label: "button_start"
      }, {
        val: 9,
        label: "button_speed_wash"
      }, {
        val: 10,
        label: "button_language"
      }, {
        val: 11,
        label: "button_help"
      }]
    }])
  });

  _exports.default = _default;
});