define("alliance-business-suite/mixins/machines/programs/validate-options", ["exports", "alliance-business-suite/utils/show-features"], function (_exports, _showFeatures) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    // eslint-disable-line
    validateOptions(optionsConfig) {
      console.time("Processing conditional options array");
      let options = {};
      optionsConfig.forEach(configItem => {
        let processItem = true; // Check if entire element is conditional

        if (configItem.notMachine !== undefined || configItem.ifMachine !== undefined) {
          processItem = (0, _showFeatures.showMachineFeature)({
            ifMachine: configItem.ifMachine,
            notMachine: configItem.notMachine,
            machineProgram: this.machineProgram
          });
        } // Continue if we should process this item


        if (processItem) {
          // Should we check for conditional option objects?
          if (configItem.conditional !== true) {
            options[configItem.name] = configItem.options;
          } else {
            let conditionalOptions = []; // Loop through each item in the options array to see if we keep it

            configItem.options.forEach(optionItem => {
              if (optionItem.notMachine === undefined && optionItem.ifMachine === undefined) {
                conditionalOptions.push(optionItem);
              } else {
                let keepItem = (0, _showFeatures.showMachineFeature)({
                  ifMachine: optionItem.ifMachine,
                  notMachine: optionItem.notMachine,
                  machineProgram: this.machineProgram
                });

                if (keepItem) {
                  conditionalOptions.push(optionItem);
                }
              }
            });
            options[configItem.name] = conditionalOptions;
          }
        }
      });
      console.timeEnd("Processing conditional options array");
      return options;
    },

    selectOptions: Ember.computed('optionsConfig.[]', 'basketDiameter', function () {
      // may need to update if the machine size changes?
      return this.validateOptions(this.optionsConfig);
    })
  });

  _exports.default = _default;
});