define("alliance-business-suite/components/form-ui/number", ["exports", "alliance-business-suite/mixins/form-ui", "alliance-business-suite/utils/is-number"], function (_exports, _formUi, _isNumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_formUi.default, {
    tagName: 'div',
    classNames: ['form-field', 'overflow-float', 'number-field', 'text-field'],
    // These need to be gone if displayValue is custom
    classNameBindings: ['params.zebra::no-zebra'],
    intl: Ember.inject.service(),

    /*
      We need to handle some numbers that get multiplied by 10 or 100 to save as
      integers in the backend.
       Pricing works similar to this, but also forces decimals in display. Use the
      currency component for pricing/money values.
       Temperatures must always be stored as F, so any C values must be converted
      and stored as an integer. Use the temp component for temperatures.
    */
    // TODO: Serialization and deserialization may not work correctly with divideBy values. This logic may need to move to the change() event.
    serializeValue(rawValue) {
      // Override in component, like a getter
      if (Ember.get(this, 'type') === 'number' && (0, _isNumber.isNumber)(rawValue)) {
        let divideBy = Ember.get(this, 'params.divideBy') || 1;
        return rawValue / divideBy;
      } else {
        return rawValue;
      }
    },

    deserializeValue(value) {
      // Override in component, like a setter
      if (Ember.get(this, 'type') === 'number' && (0, _isNumber.isNumber)(value)) {
        // Deal with numbers that need the decimal place shifted
        let divideBy = Ember.get(this, 'params.divideBy') || 1;
        value *= divideBy;
      }

      return value;
    },

    noLimit: Ember.computed('params.noLimitValue', function () {
      return this.params.noLimitValue !== undefined;
    }),
    noLimitChecked: Ember.computed('value', 'params.noLimitValue', function () {
      return this.value === this.params.noLimitValue;
    }),
    noLimitLabel: Ember.computed('params.noLimitLabel', function () {
      return this.params.noLimitLabel || "no-limit";
    }),

    change() {
      // Enforce any step values
      let stepValue = Ember.get(this, 'params.step');
      let value = Ember.get(this, 'value');

      if (Ember.get(this, 'type') === 'number' && (0, _isNumber.isNumber)(value) && stepValue) {
        value = new Big(value);
        let output = value.div(stepValue).round(0).times(stepValue);

        if (value != output) {
          Ember.set(this, 'value', output.toFixed(1)); // This is carryover from older code. Not sure why .toFixed() is here.
        }
      }
    },

    actions: {
      updateNoLimit(object, propertyName, value, e) {
        if (value) {
          Ember.set(this, 'value', this.params.noLimitValue);
        } else {
          Ember.set(this, 'value', this.params.min);
        }
      }

    }
  });

  _exports.default = _default;
});