define("alliance-business-suite/utils/machine-programming-presets", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.brandPresets = brandPresets;
  _exports.hasPreset = hasPreset;
  _exports.machinePresets = void 0;

  /*
    This file is used for...
  
     1) Populating the list of machine preset names. Presets are based on
        generation + auditType + machineType.
     2) Defining the sizeless machine type to use when creating new programs.
     3) Mapping general machine types to the filenames used within the app,
        so multiple audit types can mapped to a single component. For example,
        acas-a44-wx and acas-a3-wx machines use the acas-a4-wx components,
        which will dynamically render the appropriate content (6 cycles vs. 9).
  
      {
        machineAuditType: 'CENTURION_C3', - required for creating new programs
        defaultMachineType: 100, - - required for creating new programs
        presetName: 'preset_wx', - display name for creating new program,
      }
  
      More notes on setting things up...
        {
          id: 'TITAN', // This must match a machine-audit-group-type being returned from the BE
          generationName: 'titan',
          active: true,
          machines: [
            {
              machineAuditType: 'TITAN_T1', // This must be listed in the `tabs` const.
              defaultMachineType: 0,
              presetName: 'preset_tlw',
              active: true
            }
          ]
        }
  
  */
  const machinePresets = [{
    brand: 'PRIMUS',
    // The brand (obviously)
    generations: [{
      id: 'MIDAS',
      // I think this matches the machineAuditGroupType
      generationName: 'midas',
      // The name is used for intl?
      active: true,
      machines: [{
        machineAuditType: 'MIDAS_EZOPL_M5',
        // what the api gives us
        defaultMachineType: 30,
        // the machine type/size
        presetName: 'preset_tum',
        // the label displayed in the list of presets: t(generation.id) + t(presetName)
        active: true
      }, {
        machineAuditType: 'MIDAS_VEND_M5',
        defaultMachineType: 30,
        presetName: 'preset_tum',
        active: true
      }]
    }, {
      id: 'MC10',
      // vs. PRIMUS_MC10
      generationName: 'mc10',
      active: true,
      machines: [{
        machineAuditType: 'MC10_M5',
        defaultMachineType: 242,
        presetName: 'preset_wx',
        active: true
      }, {
        machineAuditType: 'MC10_EZOPL_M5',
        defaultMachineType: 242,
        presetName: 'preset_wx',
        active: true
      }, {
        machineAuditType: 'MC10_OPL_5',
        defaultMachineType: 242,
        presetName: 'preset_wx',
        active: true
      }]
    }, {
      id: 'GALAXY_600+',
      // TODO: Doesn't seem accurate that Galaxy stuff is showing up here.
      generationName: 'galaxy-plus-g600',
      active: true,
      machines: [{
        machineAuditType: 'ACAS_A2',
        // Xtronic machines
        defaultMachineType: 100,
        presetName: 'preset_wx',
        active: false
      }, {
        machineAuditType: 'ACAS_A2',
        defaultMachineType: 30,
        presetName: 'preset_tum',
        active: true
      }]
    }, {
      id: 'MC5',
      generationName: 'mc5',
      active: true,
      // EC are Easy Control, FC are Full Control
      machines: [{
        machineAuditType: 'MC5_EC_Primus',
        defaultMachineType: 270,
        presetName: 'preset_tum',
        active: true
      }, {
        machineAuditType: 'MC5_FC_Primus',
        defaultMachineType: 270,
        presetName: 'preset_tum',
        active: false
      }]
    }]
  }, {
    brand: 'IPSO',
    generations: [{
      id: 'QUANTUM_GOLD+',
      generationName: 'quantum-gold-plus',
      active: true,
      machines: [{
        machineAuditType: 'ACAS_A2',
        defaultMachineType: 100,
        presetName: 'preset_wx',
        // This is a 6-cycle Intl machine, but Quantum software leaves that out of the description
        active: true
      }, {
        machineAuditType: 'ACAS_A3',
        defaultMachineType: 10,
        presetName: 'preset_flw',
        // This is a 6-cycle Intl machine, but Quantum software leaves that out of the description
        active: true
      }, {
        machineAuditType: 'ACAS_A4',
        defaultMachineType: 20,
        presetName: 'preset_dry',
        active: true
      }, {
        machineAuditType: 'ACAS_A2',
        defaultMachineType: 30,
        presetName: 'preset_tum',
        // This is a 6-cycle Intl machine, but Quantum software leaves that out of the description
        active: true
      }]
    }, {
      id: 'MIDAS',
      // I think this matches the machineAuditGroupType
      generationName: 'midas',
      // The name is used for intl?
      active: true,
      machines: [{
        machineAuditType: 'MIDAS_EZOPL_M3',
        // what the api gives us
        defaultMachineType: 30,
        // the machine type/size
        presetName: 'preset_tum',
        // the label displayed in the list of presets: t(generation.id) + t(presetName)
        active: true
      }, {
        machineAuditType: 'MIDAS_VEND_M3',
        defaultMachineType: 30,
        presetName: 'preset_tum',
        active: true
      }]
    }, {
      id: 'MC10',
      // vs. PRIMUS_MC10
      generationName: 'mc10',
      active: true,
      machines: [{
        machineAuditType: 'PRIMUS_MC10',
        defaultMachineType: 242,
        presetName: 'preset_wx',
        active: true
      }, {
        machineAuditType: 'PRIMUS_EZOPL_MC10',
        defaultMachineType: 242,
        presetName: 'preset_wx',
        active: true
      }, {
        machineAuditType: 'MC10_OPL_3',
        defaultMachineType: 242,
        presetName: 'preset_wx',
        active: true
      }]
    }, {
      id: 'MC5',
      generationName: 'mc5',
      active: true,
      // EC are Easy Control, FC are Full Control
      machines: [{
        machineAuditType: 'MC5_EC_IPSO',
        defaultMachineType: 270,
        presetName: 'preset_tum',
        active: true
      }, {
        machineAuditType: 'MC5_FC_IPSO',
        defaultMachineType: 270,
        presetName: 'preset_tum',
        active: false
      }]
    }]
  }, {
    brand: 'HUEBSCH',
    generations: [{
      id: 'GALAXY_600',
      generationName: 'galaxy-g600',
      active: true,
      machines: [{
        machineAuditType: 'CENTURION_C3',
        defaultMachineType: 100,
        presetName: 'preset_wx',
        active: true
      }, {
        machineAuditType: 'CENTURION_C3_ALS_DRIVE',
        defaultMachineType: 100,
        presetName: 'preset_wx_als_drive',
        active: true
      }, {
        machineAuditType: 'CENTURION_C3',
        defaultMachineType: 30,
        presetName: 'preset_tum',
        active: true
      }]
    }, {
      id: 'GALAXY_600+',
      generationName: 'galaxy-plus-g600',
      active: true,
      machines: [{
        machineAuditType: 'ACAS_A2',
        defaultMachineType: 100,
        presetName: 'preset_wx',
        active: true
      }, {
        // TODO: Test this
        machineAuditType: 'ACAS_A22',
        defaultMachineType: 100,
        presetName: 'preset_wx',
        active: true
      }, {
        machineAuditType: 'ACAS_A2',
        defaultMachineType: 10,
        presetName: 'preset_flw',
        active: true
      }, {
        machineAuditType: 'ACAS_A1',
        defaultMachineType: 0,
        presetName: 'preset_tlw',
        active: true
      }, {
        machineAuditType: 'ACAS_A2',
        defaultMachineType: 30,
        presetName: 'preset_tum',
        active: true
      }]
    }, {
      id: 'TITAN',
      generationName: 'titan',
      active: true,
      machines: [{
        machineAuditType: 'TITAN_T1',
        defaultMachineType: 0,
        presetName: 'preset_tlw',
        active: true
      }, {
        machineAuditType: 'TITAN_T2',
        defaultMachineType: 10,
        presetName: 'preset_flw',
        active: true
      }]
    }, {
      id: 'MIDAS',
      // vs. MIDAS_VEND_M1
      generationName: 'midas',
      active: true,
      machines: [{
        machineAuditType: 'MIDAS_VEND_M2',
        defaultMachineType: 100,
        presetName: 'preset_wx',
        active: true
      }, {
        machineAuditType: 'MIDAS_VEND_M2',
        defaultMachineType: 30,
        presetName: 'preset_tum',
        active: true
      }]
    }, {
      id: 'MC10',
      generationName: 'mc10',
      active: true,
      machines: [{
        machineAuditType: 'PRIMUS_MC10',
        defaultMachineType: 242,
        presetName: 'preset_wx',
        active: true
      }]
    }]
  }, {
    brand: 'SPEED_QUEEN',
    active: true,
    generations: [{
      id: 'MC5',
      generationName: 'mc5',
      active: true,
      // EC are Easy Control, FC are Full Control
      machines: [{
        machineAuditType: 'MC5_EC_SQ',
        defaultMachineType: 270,
        presetName: 'preset_tum',
        active: true
      }, {
        machineAuditType: 'MC5_FC_SQ',
        defaultMachineType: 270,
        presetName: 'preset_tum',
        active: true
      }]
    }, {
      id: 'QUANTUM_GOLD',
      generationName: 'quantum-gold',
      machines: [{
        machineAuditType: 'CENTURION_C4',
        defaultMachineType: 100,
        presetName: 'preset_wx',
        active: true
      }, {
        machineAuditType: 'CENTURION_C4_ALS_DRIVE',
        defaultMachineType: 100,
        presetName: 'preset_wx_als_drive',
        active: true
      }, {
        machineAuditType: 'CENTURION_C4',
        defaultMachineType: 30,
        presetName: 'preset_tum',
        active: true
      }]
    }, {
      id: 'QUANTUM_GOLD+',
      generationName: 'quantum-gold-plus',
      active: true,
      machines: [{
        machineAuditType: 'ACAS_A4',
        defaultMachineType: 100,
        presetName: 'preset_wx_9_cycle',
        active: true
      }, {
        machineAuditType: 'ACAS_A4',
        defaultMachineType: 10,
        presetName: 'preset_flw',
        active: true
      }, {
        machineAuditType: 'ACAS_A3',
        defaultMachineType: 10,
        presetName: 'preset_flw_6_cycle_intl',
        active: true
      }, {
        machineAuditType: 'ACAS_A4',
        defaultMachineType: 0,
        presetName: 'preset_tlw',
        active: true
      }, {
        machineAuditType: 'ACAS_A4',
        defaultMachineType: 30,
        presetName: 'preset_tum',
        active: true
      }, {
        machineAuditType: 'ACAS_A4',
        defaultMachineType: 20,
        presetName: 'preset_dry',
        active: true
      }, {
        machineAuditType: 'ACAS_A44',
        // TODO: should be a44
        defaultMachineType: 100,
        presetName: 'preset_wx_6_cycle',
        active: true
      }, {
        machineAuditType: 'ACAS_A3',
        defaultMachineType: 100,
        presetName: 'preset_wx_6_cycle_intl',
        active: true
      }]
    }, {
      id: 'QUANTUM_PLATINUM',
      generationName: 'quantum-platinum',
      active: true,
      machines: [{
        machineAuditType: 'MGD_M1',
        defaultMachineType: 10,
        presetName: 'preset_flw',
        active: true
      }, {
        machineAuditType: 'MGD_M1',
        defaultMachineType: 20,
        presetName: 'preset_dry',
        active: true
      }]
    }, {
      id: 'MC10',
      // vs. PRIMUS_MC10
      generationName: 'mc10',
      active: true,
      machines: [{
        machineAuditType: 'PRIMUS_MC10',
        defaultMachineType: 242,
        presetName: 'preset_wx',
        active: true
      }, {
        machineAuditType: 'MC10_COMBO_M1',
        defaultMachineType: 345,
        presetName: 'preset_combo',
        active: true
      }, {
        machineAuditType: 'MC10',
        defaultMachineType: 242,
        presetName: 'preset_wx',
        active: true
      }, {
        // TODO: Verify this should be here.
        machineAuditType: 'MC10_OPL_1',
        defaultMachineType: 242,
        presetName: 'preset_wx',
        active: true
      }]
    }, {
      id: 'MIDAS',
      // vs. MIDAS_VEND_M1
      generationName: 'midas',
      active: true,
      machines: [{
        machineAuditType: 'MIDAS_VEND_M1',
        defaultMachineType: 100,
        presetName: 'preset_wx',
        active: true
      }, {
        machineAuditType: 'MIDAS_VEND_M1',
        defaultMachineType: 30,
        presetName: 'preset_tum',
        active: true
      }, {
        machineAuditType: 'MIDAS_OPL_1',
        defaultMachineType: 30,
        presetName: 'preset_tum',
        active: false
      }, {
        machineAuditType: 'MIDAS_OPL_2',
        defaultMachineType: 30,
        presetName: 'preset_tum',
        active: false
      }, {
        machineAuditType: 'MIDAS_OPL_5',
        defaultMachineType: 30,
        presetName: 'preset_tum',
        active: false
      }]
    }, {
      id: 'TITAN',
      generationName: 'titan',
      active: true,
      machines: [{
        machineAuditType: 'TITAN_T4',
        defaultMachineType: 0,
        presetName: 'preset_tlw',
        active: true
      }, {
        machineAuditType: 'TITAN_T4',
        defaultMachineType: 10,
        presetName: 'preset_flw',
        active: true
      }, {
        machineAuditType: 'TITAN_T4',
        defaultMachineType: 20,
        presetName: 'preset_dry',
        active: true
      }]
    }, {
      id: 'MGD_OPL',
      // Used for developoment only
      generationName: 'mgd_opl',
      active: false,
      machines: [{
        machineAuditType: 'MGD_M7',
        defaultMachineType: 120,
        presetName: 'preset_wx',
        active: false
      }, {
        machineAuditType: 'MGD_M7',
        defaultMachineType: 30,
        presetName: 'preset_tum',
        active: false
      }]
    }]
  }, {
    brand: 'UNIMAC',
    active: true,
    generations: [{
      id: 'MGD_OPL',
      generationName: 'mgd_opl',
      active: true,
      machines: [{
        machineAuditType: 'MGD_M7',
        defaultMachineType: 120,
        presetName: 'preset_wx',
        active: true
      }, {
        machineAuditType: 'MGD_M7',
        defaultMachineType: 30,
        presetName: 'preset_tum',
        active: true
      }]
    }, {
      id: 'MC10',
      generationName: 'mc10',
      active: true,
      machines: [{
        machineAuditType: 'MC10_OPL_4',
        defaultMachineType: 242,
        presetName: 'preset_wx',
        active: true
      }]
    }, {
      id: 'MC5',
      generationName: 'mc5',
      active: true,
      // EC are Easy Control, FC are Full Control
      machines: [{
        machineAuditType: 'MC5_EC_UniMac',
        defaultMachineType: 270,
        presetName: 'preset_tum',
        active: true
      }, {
        machineAuditType: 'MC5_FC_UniMac',
        defaultMachineType: 270,
        presetName: 'preset_tum',
        active: false
      }]
    }]
  }];
  _exports.machinePresets = machinePresets;

  function brandPresets(brandName) {
    // Get an array of machineAuditType this brand has presets for
    let brand = machinePresets.findBy('brand', brandName);
    let machineAuditTypes = [];
    brand.generations.forEach(generation => {
      generation.machines.forEach(machine => {
        if (machine.active === true) {
          machineAuditTypes.push(machine.machineAuditType);
        }
      });
    });
    return machineAuditTypes;
  }

  function hasPreset(machineAuditType, brandName) {
    let presets = brandPresets(brandName);
    return presets.includes(machineAuditType);
  }
});