define("alliance-business-suite/components/machines/programs/advanced/acas-tum-dry", ["exports", "alliance-business-suite/utils/machine-programming-find-options"], function (_exports, _machineProgrammingFindOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    machineAuditType: Ember.computed.alias('machineProgram.machineAuditType.id'),
    lowPowerStartIndex: Ember.computed('machineAuditType', function () {
      switch (this.machineAuditType) {
        case "CENTURION_C4":
          return "A";

        default:
          return 0;
      }
    }),
    lowPowerCount: Ember.computed('machineAuditType', function () {
      switch (this.machineAuditType) {
        case "ACAS_A2":
        case "TITAN_T1":
          return 2;

        default:
          return 4;
      }
    }),
    optionsAvailability: (0, _machineProgrammingFindOptions.findOptions)([{
      machineAttributes: ["CENTURION_C3"],
      options: [{
        val: 0,
        label: 'in_ready_mode'
      }, {
        val: 1,
        label: 'until_start_mode'
      }, {
        val: 2,
        label: 'in_ready_mode_EOC'
      }, {
        val: 3,
        label: 'until_start_mode_EOC'
      }, {
        val: 4,
        label: 'inready_start_mode'
      }, {
        val: 5,
        label: 'until_run_mode'
      }, {
        val: 6,
        label: 'in_ready_start_mode_EOC'
      }, {
        val: 7,
        label: 'until_run_mode_EOC'
      }]
    }, {
      default: true,
      options: [{
        val: 0,
        label: "availablity_available"
      }, {
        val: 1,
        label: "availablity_available_with_start"
      }, {
        val: 2,
        label: "availablity_available_with_end_of_cycle"
      }, {
        val: 3,
        label: "availablity_available_with_start_and_end_of_cycle"
      }, {
        val: 4,
        label: "availablity_vend_is_not_satisfied"
      }, {
        val: 5,
        label: "availablity_vend_is_not_satisfied_with_end_of_cycle"
      }, {
        val: 6,
        label: "availablity_available_with_vend"
      }, {
        val: 7,
        label: "availablity_available_with_vend_and_end_of_cycle"
      }, {
        val: 8,
        label: "availablity_machine_is_in_use"
      }, {
        val: 9,
        label: "availablity_cycle_is_complete"
      }, {
        val: 10,
        label: "availablity_lucky_cycle"
      }, {
        val: 11,
        label: "availablity_break_in_alarm"
      }, {
        val: 12,
        label: "availablity_end_of_cycle_is_entered"
      }, {
        val: 13,
        label: "availablity_motor_running"
      }]
    }]),
    options: {
      startPulseMode: [{
        val: 0,
        label: 'Off',
        key: 'off'
      }, {
        val: 1,
        label: 'SinglePulse',
        key: 'single'
      }, {
        val: 2,
        label: 'MultiplePulses',
        key: 'multiple'
      }],
      temperatureUnits: [{
        val: true,
        label: 'Celsius'
      }, {
        val: false,
        label: 'Fahrenheit'
      }],
      temperatureUnitsInt: [{
        val: 1,
        label: 'Celsius'
      }, {
        val: 0,
        label: 'Fahrenheit'
      }]
    },
    optionsTemperatureUnits: Ember.computed('machineProgram._machineGeneration', function () {
      if (this.machineProgram._machineGeneration === "TITAN") {
        return [{
          val: 1,
          label: 'Celsius'
        }, {
          val: 0,
          label: 'Fahrenheit'
        }];
      } else {
        return [{
          val: true,
          label: 'Celsius'
        }, {
          val: false,
          label: 'Fahrenheit'
        }];
      }
    }),
    optionsTemperatureValues: Ember.computed('machineProgram._machineTypeCode', function () {
      if (["CENTURION_C4", "CENTURION_C3"].includes(this.machineAuditType)) {
        return [{
          val: 13,
          F: 100,
          C: 37
        }, {
          val: 14,
          F: 105,
          C: 40
        }, {
          val: 15,
          F: 110,
          C: 43
        }, {
          val: 16,
          F: 115,
          C: 46
        }, {
          val: 17,
          F: 120,
          C: 48
        }, {
          val: 18,
          F: 125,
          C: 51
        }, {
          val: 19,
          F: 130,
          C: 54
        }, {
          val: 20,
          F: 135,
          C: 57
        }, {
          val: 21,
          F: 140,
          C: 60
        }, {
          val: 22,
          F: 145,
          C: 62
        }, {
          val: 23,
          F: 150,
          C: 65
        }, {
          val: 24,
          F: 155,
          C: 68
        }, {
          val: 25,
          F: 160,
          C: 71
        }, {
          val: 26,
          F: 165,
          C: 73
        }, {
          val: 27,
          F: 170,
          C: 76
        }, {
          val: 28,
          F: 175,
          C: 79
        }, {
          val: 29,
          F: 180,
          C: 82
        }, {
          val: 30,
          F: 185,
          C: 85
        }, {
          val: 31,
          F: 190,
          C: 87
        }];
      }

      if (this.machineProgram._machineTypeCode === "TUM") {
        // TUM has higher heat
        return [{
          val: 100,
          F: 100,
          C: 38
        }, {
          val: 105,
          F: 105,
          C: 41
        }, {
          val: 110,
          F: 110,
          C: 43
        }, {
          val: 115,
          F: 115,
          C: 46
        }, {
          val: 120,
          F: 120,
          C: 49
        }, {
          val: 125,
          F: 125,
          C: 52
        }, {
          val: 130,
          F: 130,
          C: 54
        }, {
          val: 135,
          F: 135,
          C: 57
        }, {
          val: 140,
          F: 140,
          C: 60
        }, {
          val: 145,
          F: 145,
          C: 63
        }, {
          val: 150,
          F: 150,
          C: 66
        }, {
          val: 155,
          F: 155,
          C: 68
        }, {
          val: 160,
          F: 160,
          C: 71
        }, {
          val: 165,
          F: 165,
          C: 74
        }, {
          val: 170,
          F: 170,
          C: 77
        }, {
          val: 175,
          F: 175,
          C: 79
        }, {
          val: 180,
          F: 180,
          C: 82
        }, {
          val: 185,
          F: 185,
          C: 85
        }, {
          val: 190,
          F: 190,
          C: 88
        } // { val: 195, F: 195, C: 91 }
        ];
      } else {
        // DRY
        return [{
          val: 100,
          F: 100,
          C: 38
        }, {
          val: 105,
          F: 105,
          C: 41
        }, {
          val: 110,
          F: 110,
          C: 43
        }, {
          val: 115,
          F: 115,
          C: 46
        }, {
          val: 120,
          F: 120,
          C: 49
        }, {
          val: 125,
          F: 125,
          C: 52
        }, {
          val: 130,
          F: 130,
          C: 54
        }, {
          val: 135,
          F: 135,
          C: 57
        }, {
          val: 140,
          F: 140,
          C: 60
        }, {
          val: 145,
          F: 145,
          C: 63
        }, {
          val: 150,
          F: 150,
          C: 66
        }, {
          val: 155,
          F: 155,
          C: 68
        }, {
          val: 160,
          F: 160,
          C: 71
        }];
      }
    })
  });

  _exports.default = _default;
});