define("alliance-business-suite/components/form-ui/date", ["exports", "alliance-business-suite/mixins/form-ui", "moment"], function (_exports, _formUi, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_formUi.default, {
    tagName: 'div',
    classNames: ['form-field', 'overflow-float'],
    intl: Ember.inject.service(),

    init() {
      this._super(...arguments); // The `value` can be a full date, or year, month, day values.


      Ember.defineProperty(this, 'fullDate', Ember.computed.reads(`object.${this.params.propertyName}`));
      Ember.defineProperty(this, 'year', Ember.computed.reads(`object.${this.params.yearPropertyName}`));
      Ember.defineProperty(this, 'month', Ember.computed.reads(`object.${this.params.monthPropertyName}`));
      Ember.defineProperty(this, 'date', Ember.computed.reads(`object.${this.params.datePropertyName}`));
    },

    format: Ember.computed('params.format', function () {
      return this.params.format || 'MM/DD/YYYY';
    }),
    value: Ember.computed('fullDate', 'year', 'month', 'date', function () {
      // fullDate is populated if a single date value is passed in for propertyName
      if (this.fullDate) {
        return (0, _moment.default)(this.fullDate, "X").format();
      } else {
        // Put together the pieces... month-- to fix 0 vs. 1 base
        let year = Number(this.year);

        if (year < 100) {
          year += 2000;
        }

        return (0, _moment.default)({
          year,
          month: this.month - 1,
          date: this.date
        }).format();
      }
    }),
    isUndefinedProperty: Ember.computed('fullDate', 'year', 'month', 'date', function () {
      // Overrides global mixin function
      if (this.fullDate !== undefined) {
        return false;
      } else if (this.year !== undefined && this.month !== undefined && this.date !== undefined) {
        return false;
      } else {
        console.error('Reference to date properties are undefined.'); // eslint-disable-line no-console

        return true;
      }
    }),
    yearDigits: Ember.computed('params.yearDigits', function () {
      return this.params.yearDigits || 2; // We should always update with 2 digits
    }),
    actions: {
      doChange(value) {
        let date = (0, _moment.default)(value);

        if (this.params.propertyName) {
          // this.update(this.object, this.params.propertyName, date.format()); // TODO: Check if this was ever used in the past
          this.update(this.object, this.params.propertyName, Number(date.format('X'))); // Return UNIX timestamp value

          console.log('updated', this.params.propertyName, Number(date.format('X')));
        }

        if (this.params.yearPropertyName) {
          if (this.yearDigits == 2) {
            this.update(this.object, this.params.yearPropertyName, Number(date.format("YY")));
          } else {
            this.update(this.object, this.params.yearPropertyName, date.year());
          }
        }

        if (this.params.monthPropertyName) {
          this.update(this.object, this.params.monthPropertyName, date.month() + 1); // Pass back Jan=1
        }

        if (this.params.datePropertyName) {
          this.update(this.object, this.params.datePropertyName, date.date());
        }
      }

    }
  });

  _exports.default = _default;
});