define("alliance-business-suite/templates/components/input-placeholder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "h/1i/iCx",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,3],[[30,[36,2],[[35,1,[\"text-field\"]],\"expected `form.text-field` to be a contextual component but found a string. Did you mean `(component form.text-field)`? ('alliance-business-suite/templates/components/input-placeholder.hbs' @ L2:C2) \"],null],[35,0]],[[\"disabled\"],[true]]]]],\"hasEval\":false,\"upvars\":[\"field\",\"form\",\"-assert-implicit-component-helper-argument\",\"component\"]}",
    "moduleName": "alliance-business-suite/templates/components/input-placeholder.hbs"
  });

  _exports.default = _default;
});