define("alliance-business-suite/mixins/machines/programs/acas-property-names", ["exports", "alliance-business-suite/helpers/find-by-dropdown-with-custom"], function (_exports, _findByDropdownWithCustom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    // numberFormatting: computed('machineProgram.settings.Program.Display.NumericSeparator', function() {
    globalSettings: Ember.computed('showG', 'decimals', 'coinValue', 'isCelsius', function () {
      return {
        decimals: this.decimals,
        divideBy: 1,
        // This may be wrong
        decimalSymbol: '.',
        thousandsSymbol: ',',
        currencySymbol: '$',
        currencySymbolPosition: 'before',
        isCelsius: this.isCelsius,
        coinValue: this.coinValue,
        showG: this.showG
      };
    }),
    decimals: Ember.computed.alias('machineProgram.settings.Program.CoinAndPulse.DecimalPointPosition'),
    showRpm: Ember.computed.alias('machineProgram.settings.Program.Cycle.DisplaySpeedUnits'),
    showG: Ember.computed.not('showRpm'),
    isCelsius: Ember.computed.alias('machineProgram.settings.Program.Miscellaneous.CelsiusEnabled'),
    coinValue: Ember.computed.alias('machineProgram.settings.Program.CoinAndPulse.Coin1Value'),
    propertyNames: Ember.computed(function () {
      return {
        specialVend_TUM: {
          Enabled: 'SpecialVend.${index}.Enabled',
          Friday: 'SpecialVend.${index}.DOWFridayEnabled',
          Monday: 'SpecialVend.${index}.DOWMondayEnabled',
          Sunday: 'SpecialVend.${index}.DOWSundayEnabled',
          Tuesday: 'SpecialVend.${index}.DOWTuesdayEnabled',
          Saturday: 'SpecialVend.${index}.DOWSaturdayEnabled',
          Thursday: 'SpecialVend.${index}.DOWThursdayEnabled',
          Wednesday: 'SpecialVend.${index}.DOWWednesdayEnabled',
          EndYear: 'SpecialVend.${index}.EndYear',
          EndMonth: 'SpecialVend.${index}.EndMonth',
          EndDate: 'SpecialVend.${index}.EndDate',
          StartYear: 'SpecialVend.${index}.StartYear',
          StartMonth: 'SpecialVend.${index}.StartMonth',
          StartDate: 'SpecialVend.${index}.StartDate',
          StartHour: 'SpecialVend.${index}.StartHour',
          StartMinute: 'SpecialVend.${index}.StartMinute',
          Duration: 'SpecialVend.${index}.LengthInHours',
          StartDateTime: 'SpecialVend.${index}.StartDateTime',
          Coin1TopOffMinutes: 'SpecialVend.${index}.Coin1TopoffMinutes',
          Coin1TopOffSeconds: 'SpecialVend.${index}.Coin1TopoffSeconds',
          Coin2TopOffMinutes: 'SpecialVend.${index}.Coin2TopoffMinutes',
          Coin2TopOffSeconds: 'SpecialVend.${index}.Coin2TopoffSeconds',
          HeatPrice: 'SpecialVend.${index}.HeatPrice',
          NoHeatPrice: 'SpecialVend.${index}.NoHeatPrice',
          HeatMinutes: 'SpecialVend.${index}.HeatMinutes',
          HeatSeconds: 'SpecialVend.${index}.HeatSeconds',
          NoHeatMinutes: 'SpecialVend.${index}.NoHeatMinutes',
          NoHeatSeconds: 'SpecialVend.${index}.NoHeatSeconds',
          CycleModifierDefaultValue: 'SpecialVend.${index}.CycleModifierDefaultValue',
          PaymentSystemTopOffPrice: 'SpecialVend.${index}.PaymentSystemAndNVVTopoffPrice',
          // CycleModifierReversingPriceAdder: 'SpecialVend.${index}.CycleModifierReversingPriceAdder',
          PaymentSystemTopOffMinutes: 'SpecialVend.${index}.PaymentSystemAndNVVTopoffMinutes',
          PaymentSystemTopOffSeconds: 'SpecialVend.${index}.PaymentSystemAndNVVTopoffSeconds'
        }
      };
    })
  });

  _exports.default = _default;
});