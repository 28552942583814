define("alliance-business-suite/components/table/sales-goals/table-cell-role-goal", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.hasRoleGoal}}
    <CurrencyOptions @value={{@record.roleGoal}} @isInput={{false}} />
  {{else}}
    <i aria-hidden="true" class="fa fa-ban" style="opacity: .25"></i>
  {{/if}}
  
  */
  {
    "id": "ktsbFixI",
    "block": "{\"symbols\":[\"@record\"],\"statements\":[[6,[37,0],[[32,0,[\"hasRoleGoal\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"currency-options\",[],[[\"@value\",\"@isInput\"],[[32,1,[\"roleGoal\"]],false]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"i\"],[14,\"aria-hidden\",\"true\"],[14,0,\"fa fa-ban\"],[14,5,\"opacity: .25\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "alliance-business-suite/components/table/sales-goals/table-cell-role-goal.hbs"
  });

  class TableSalesGoalsTableCellRoleGoalComponent extends _component.default {
    // we need a special check as "null" we treat differently than "0"
    get hasRoleGoal() {
      if (this.args.record.roleGoal === 0 || this.args.record.roleGoal) {
        return true;
      }
    }

  }

  _exports.default = TableSalesGoalsTableCellRoleGoalComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TableSalesGoalsTableCellRoleGoalComponent);
});