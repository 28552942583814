define("alliance-business-suite/models/delivery-method-message", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    hasMany,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    campaign: belongsTo('campaign'),
    deliveryMethodFromName: attr('string'),
    deliveryMethodFromEmail: attr('string'),
    deliveryMethodReplyEmail: attr('string'),
    deliveryMethodSubjectText: attr('string'),
    deliveryMethodBodyText: attr('string'),
    deliveryMethodTypeLookupKey: attr('string')
  });

  _exports.default = _default;
});