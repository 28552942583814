define("alliance-business-suite/components/inventory/reorder-guideline-form", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'reorderBelowLevel': [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    'phoneOrEmail': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      dependentKeys: ['model.email', 'model.phone'],
      disabled: Ember.computed('model.{email,phone}', function () {
        return this.model.get('email') || this.model.get('phone') ? true : false;
      })
    })]
  });

  var _default = Ember.Component.extend(Validations, {
    access: Ember.inject.service(),
    store: Ember.inject.service(),
    reorderBelowLevel: Ember.computed(function () {
      let reorderBelowLevel = this.get('guideline.reorderBelowLevel');
      return reorderBelowLevel;
    }),
    editDisabled: Ember.computed('guideline.room', function () {
      if (this.get('guideline.room.id')) {
        // this is an inventory alert
        return !this['can-access'].canAccess('INVENTORY_MANAGE_ALERT');
      } else {
        // this is an item alert
        return !this['can-access'].canAccess('ITEM_MANAGE_ALERT');
      }
    }).readOnly(),
    phone: Ember.computed(function () {
      let phone = this.get('guideline.phone');
      return phone;
    }),
    email: Ember.computed(function () {
      let email = this.get('guideline.email');
      return email;
    }),
    actions: {
      close() {
        this.close();
      },

      addGuideline() {
        let itemId = this.get('item.id');
        let reorderBelowLevel = this.reorderBelowLevel;
        let email = this.email;
        let phone = this.phone;
        let user = this.store.peekRecord('user', this.get('access.accessUser.id'));
        let guideline = this.store.createRecord('reorder-guideline', {
          reorderBelowLevel,
          email,
          phone,
          user
        });

        if (this.get('inventory.room.id')) {
          guideline.set('room', this.get('inventory.room'));
        }

        this.set('isSaving', true);
        this.set('isProcessing', true);
        guideline.save({
          adapterOptions: {
            itemId
          }
        }).then(() => {
          this.set('isSaving', false);
          this.set('isProcessing', false);
          this.send('close');
        }, () => {
          this.set('isSaving', false);
          this.set('isProcessing', false);
        });
      },

      saveGuideline() {
        let guideline = this.store.peekRecord('reorder-guideline', this.get('guideline.id'));
        let reorderBelowLevel = this.reorderBelowLevel;
        let email = this.email;
        let phone = this.phone;
        guideline.setProperties({
          reorderBelowLevel,
          email,
          phone
        });
        this.set('isSaving', true);
        this.set('isProcessing', true);
        guideline.save().then(() => {
          this.set('isSaving', false);
          this.set('isProcessing', false);
          this.send('close');
        }, () => {
          this.set('isSaving', false);
          this.set('isProcessing', false);
        });
      },

      deleteGuideline() {
        let guideline = this.store.peekRecord('reorder-guideline', this.get('guideline.id'));
        this.set('isDeleting', true);
        this.set('isProcessing', true);
        guideline.deleteRecord();
        guideline.save().then(() => {
          this.set('isDeleting', false);
          this.set('isProcessing', false);
          this.send('close');
        }, () => {
          this.set('isDeleting', false);
          this.set('isProcessing', false);
        });
      }

    }
  });

  _exports.default = _default;
});