define("alliance-business-suite/services/devtools", ["exports", "alliance-business-suite/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const brandOptions = [{
    text: 'Speed Queen',
    id: 'SPEED_QUEEN'
  }, {
    text: 'Huebsch',
    id: 'HUEBSCH'
  }, {
    text: 'IPSO',
    id: 'IPSO'
  }, {
    text: 'Primus',
    id: 'PRIMUS'
  }, {
    text: 'UniMac',
    id: 'UNIMAC'
  }];

  var _default = Ember.Service.extend({
    // eslint-disable-line ember/no-classic-classes
    display: false,
    trayEnabled: false,
    missingIntl: [],
    brandOptions,
    brand: Ember.inject.service(),
    canAccess: Ember.inject.service('can-access'),

    init() {
      this._super(...arguments);

      let productionWarning = _environment.default.configEnvironment == 'production' && window.location.hostname == 'localhost';
      Ember.set(this, 'productionWarning', productionWarning);
      let trayEnabled = Ember.get(this, 'trayEnabled');
      let devToolsEnabled = Ember.get(this, 'devToolsEnabled');
      let securityToolsEnabled = Ember.get(this, 'securityToolsEnabled');
      let devBrand = localStorage.getItem('devBrand') || 'IPSO';

      if (trayEnabled) {
        this.addClassName('dev-tools-tray');
      }

      if (trayEnabled && devToolsEnabled) {
        this.addClassName('show-dev-tools');
      }

      if (trayEnabled && securityToolsEnabled) {
        this.addClassName('show-security-tools');
      }

      if (trayEnabled && devBrand) {
        this.changeSelectedBrand(brandOptions.findBy('id', devBrand));
      }
    },

    selectedBrand: Ember.computed('brand.slug', 'brandOptions', function () {
      const slugs = ['sq', 'huebsch', 'ipso', 'primus', 'unimac'];
      return this.brandOptions[slugs.indexOf(this.brand.slug)];
    }),

    changeSelectedBrand(option) {
      this.brand.loadBrand(option.id);
      localStorage.setItem('devBrand', option.id);
    },

    addClassName(name) {
      document.body.className += ` ${name}`;
    },

    removeClassName(name) {
      let pattern = new RegExp("(^|\\s)" + name + "\\b", 'g');
      document.body.className = document.body.className.replace(pattern, '');
    },

    toggleClassName(name) {
      let pattern = new RegExp("(^|\\s)" + name + "\\b", 'g');

      if (document.body.className.search(pattern) >= 0) {
        this.removeClassName(name);
      } else {
        this.addClassName(name);
      }
    },

    speak(filename) {
      if (this.trayEnabled && this.audioFeedback) {
        var snd = new Audio(`/assets/audio/${filename}`);
        snd.play();
      }
    },

    addIntlKey(key) {
      Ember.run.scheduleOnce('afterRender', () => {
        Ember.get(this, 'missingIntl').addObject(key);
      });
    },

    missingIntlCount: Ember.computed('missingIntl.[]', {
      get() {
        return this.missingIntl.length;
      }

    }),

    listMissingIntl() {
      // This will output a nice list.
      let list = Ember.get(this, 'missingIntl');

      if (list.length <= 0) {
        alert("No missing translations found.");
      } else {
        let output = list.reduce((output, item) => {
          return `${output}\n"${item}": "${item}",`;
        }, "");
        console.log("Missing translations collected:\n", output); // eslint-disable-line no-console

        alert(`${list.length} missing translations logged to console.`);
      }
    },

    trayEnabled: Ember.computed({
      get() {
        return localStorage.getItem('devTray') == 'ON';
      },

      set(key, value) {
        // Value must be ON or OFF
        localStorage.setItem('devTray', value);
        return value == 'ON';
      }

    }),
    devToolsEnabled: Ember.computed({
      get() {
        return localStorage.getItem('devTools') == 'ON';
      },

      set(key, value) {
        // Value must be ON or OFF
        localStorage.setItem('devTools', value);
        return value == 'ON';
      }

    }),
    securityToolsEnabled: Ember.computed({
      get() {
        return localStorage.getItem('securityTools') == 'ON';
      },

      set(key, value) {
        // Value must be ON or OFF
        localStorage.setItem('securityTools', value);
        return value == 'ON';
      }

    }),
    testMachineId: Ember.computed({
      get() {
        return localStorage.getItem('testMachineId') || '';
      },

      set(key, value) {
        localStorage.setItem('testMachineId', value);
        return value;
      }

    }),
    autoSendTest: Ember.computed({
      get() {
        return localStorage.getItem('autoSendTest') == "true" ? true : false;
      },

      set(key, value) {
        localStorage.setItem('autoSendTest', value);
        return value;
      }

    }),
    audioFeedback: Ember.computed({
      get() {
        return localStorage.getItem('audioFeedback') == "true" ? true : false;
      },

      set(key, value) {
        localStorage.setItem('audioFeedback', value);
        return value;
      }

    }),

    toggleDevToolTray() {
      if (this.trayEnabled) {
        Ember.set(this, 'trayEnabled', 'OFF');
        Ember.set(this, 'devToolsEnabled', 'OFF');
        Ember.set(this, 'securityToolsEnabled', 'OFF');
        this.removeClassName('dev-tools-enabled');
        this.removeClassName('dev-tools-tray');
        this.removeClassName('show-dev-tools');
        this.removeClassName('show-security-tools');
      } else {
        Ember.set(this, 'trayEnabled', 'ON');
        this.addClassName('dev-tools-tray');
      }

      this.notifyPropertyChange('trayEnabled');
      Ember.run.scheduleOnce('afterRender', this, function () {
        window.dispatchEvent(new Event('resize'));
      });
    },

    toggleDevTools() {
      if (this.devToolsEnabled) {
        Ember.set(this, 'devToolsEnabled', 'OFF');
        this.removeClassName('show-dev-tools');
      } else {
        Ember.set(this, 'devToolsEnabled', 'ON');
        this.addClassName('show-dev-tools');
      }

      this.notifyPropertyChange('devToolsEnabled');
    },

    toggleSecurityTools() {
      let currentStatus = this.canAccess.securityDebugging;
      this.toggleProperty('canAccess.securityDebugging');
      this.toggleClassName('show-security-tools');
      Ember.set(this, 'securityToolsEnabled', currentStatus ? 'OFF' : 'ON');
      this.notifyPropertyChange('securityToolsEnabled');
    }

  });

  _exports.default = _default;
});