define("alliance-business-suite/templates/components/brushes/async-brush", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hgSnx7XM",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[11,\"div\"],[4,[38,5],[[32,0,[\"initSetup\"]]],null],[4,[38,7],[[32,0,[\"initSetup\"]],[35,6,[\"model\",\"id\"]]],null],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,8],[[32,0,[\"isLoading\"]]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,4],[[35,3,[\"isSettled\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,4],[[35,3,[\"isRejected\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"data-error\"],[12],[2,\"\\n          \"],[10,\"h2\"],[12],[1,[30,[36,2],[\"error\"],null]],[13],[2,\"\\n          \"],[10,\"p\"],[12],[1,[30,[36,2],[\"brush_error_subtitle\"],null]],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[18,1,[[30,[36,1],null,[[\"loadedModel\"],[[35,0]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"loadedModel\",\"hash\",\"t\",\"state\",\"if\",\"did-insert\",\"brush\",\"did-update\",\"not\"]}",
    "moduleName": "alliance-business-suite/templates/components/brushes/async-brush.hbs"
  });

  _exports.default = _default;
});