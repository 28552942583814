define("alliance-business-suite/helpers/step-state-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
    "Icons" for steps are just one or two characters in a square box that are returned from the i18n service.
    The ActiveState value gets passed in, along with the collection of possible values for the Active State
    (typically, an array from selectOptions is used).
  
    1) Find the item in the array with a value matching the ActiveState value.
    2) Find the translated "icon" text to use.
    3) Find the translated "label" text to use.
    4) Return the HTML needed to render the "icon".
  
    The tranlations are prepended with "icon" and "option", like this:
  
      'icon_Disabled': 'X',
      'icon_ActiveMod1': '1',
      'icon_ActiveMod2': '2',
      ...
      'option_Disabled': 'Disabled',
      'option_ActiveMod1': 'Active Mod1',
      'option_ActiveMod2': 'Active Mod2',
      ...
  */
  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),

    compute(_ref, args) {
      let [value, array, ...rest] = _ref;
      let option = array.findBy('val', value); // Find the option for this value

      let label = option ? Ember.get(option, 'label') : 'unknown'; // Get the label text for this option so we can check i18n

      let intl = this.intl; // Find the translations

      let icon = `icon_${label}`;
      let text = `option_${label}`;

      if (intl.exists(icon) && intl.exists(text)) {
        let tIcon = intl.t(icon);
        let tText = intl.t(text);
        return Ember.String.htmlSafe(`<span class="step-label-abbrev" title="${tText}">${tIcon}</span>`);
      } else {
        return;
      }
    }

  });

  _exports.default = _default;
});