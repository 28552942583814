define("alliance-business-suite/templates/components/brushes/default-brush", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lEKdbMIz",
    "block": "{\"symbols\":[],\"statements\":[[10,\"img\"],[14,0,\"scrubbrush-watermark\"],[15,\"src\",[31,[[30,[36,0],[\"brand-image-path\",\"sidebar-watermark.png\"],null]]]],[12],[13]],\"hasEval\":false,\"upvars\":[\"b\"]}",
    "moduleName": "alliance-business-suite/templates/components/brushes/default-brush.hbs"
  });

  _exports.default = _default;
});