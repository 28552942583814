define("alliance-business-suite/components/ui/modal-reward-points-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    access: Ember.inject.service(),
    intl: Ember.inject.service(),
    notifications: Ember.inject.service(),
    netBalanceClass: '',
    userAccountId: null,
    isCredit: true,
    updatedRewardsPointsTotal: Ember.computed('currentRewardsPoints', function () {
      return this.currentRewardsPoints;
    }),
    balanceDifference: Ember.observer('updatedRewardsPointsTotal', function () {
      let {
        currentRewardsPoints,
        updatedRewardsPointsTotal
      } = this;

      if (updatedRewardsPointsTotal < 0 || isNaN(updatedRewardsPointsTotal)) {
        console.log('MAKING ZERO');
        updatedRewardsPointsTotal = 0;
      }

      let diff = updatedRewardsPointsTotal - currentRewardsPoints;

      if (diff < 0) {
        Ember.set(this, 'isCredit', false);
      } else {
        Ember.set(this, 'isCredit', true);
      }

      diff = diff.toFixed(2);
      Ember.set(this, 'proposedBalanceDifference', diff);
      return;
    }),
    actions: {
      close() {
        if (!Ember.get(this, 'isLoading')) {
          this.close();
        }
      },

      updateBalance() {
        let {
          userAccountId,
          fundsRootId,
          proposedBalanceDifference,
          isCredit
        } = this;
        let isPromotionalCredit = false;
        let confirmText = this.intl.t('confirm_rewards_points_adjustment');

        if (proposedBalanceDifference < 0) {
          confirmText = `${confirmText} (${proposedBalanceDifference})`;
        } else {
          confirmText = `${confirmText} ${proposedBalanceDifference}`;
        }

        if (!confirm(confirmText)) {
          return;
        }

        Ember.set(this, 'isLoading', true);
        this.ajax.request(`/accounts/${userAccountId}/updateBalance`, {
          method: 'POST',
          data: JSON.stringify({
            amount: Math.abs(proposedBalanceDifference),
            geoBoundary: {
              id: fundsRootId
            },
            isCredit,
            isRewardPoints: true
          })
        }).then(response => {
          let responseText = response.responseText;

          if (responseText == 'POINTS_NOT_APPLIED_NO_ACTIVE_REWARDS_PROGRAM') {
            this.notifications.error(this.intl.t(responseText), {
              clearDuration: 5000,
              autoClear: true
            });
            Ember.set(this, 'isLoading', false);
            return;
          }

          if (responseText == 'REWARDS_POINTS_CONVERTED_TO_REWARDS_CREDIT') {
            this.notifications.warning(this.intl.t(responseText), {
              clearDuration: 15000,
              autoClear: true
            });
          } else {
            this.notifications.success(this.intl.t('success'), {
              clearDuration: 5000,
              autoClear: true
            });
          }

          Ember.set(this, 'isLoading', false);
          this.refresh();
          this.close();
          return;
        });
      }

    }
  });

  _exports.default = _default;
});