define("alliance-business-suite/controllers/machines/programs/test", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  JSON.flatten = function (data) {
    var result = {};

    function recurse(cur, prop) {
      if (Object(cur) !== cur) {
        result[prop] = cur;
      } else if (Array.isArray(cur)) {
        for (var i = 0, l = cur.length; i < l; i++) recurse(cur[i], prop + "[" + i + "]");

        if (l == 0) result[prop] = [];
      } else {
        var isEmpty = true;

        for (var p in cur) {
          isEmpty = false;
          recurse(cur[p], prop ? prop + "." + p : p);
        }

        if (isEmpty && prop) result[prop] = {};
      }
    }

    recurse(data, "");
    return result;
  };

  var _default = Ember.Controller.extend({
    machines: Ember.inject.service(),
    devtools: Ember.inject.service(),

    init() {
      this._super(...arguments);

      Ember.set(this, 'differenceFound', false);
      Ember.set(this, 'comparison', []);
    },

    firstProgram: Ember.computed('programList.[]', function () {
      return this.programList.findBy("name", this.machines.testProgramNames.firstObject);
    }),
    secondProgram: Ember.computed('programList.[]', function () {
      return this.programList.findBy("name", this.machines.testProgramNames.lastObject);
    }),
    differenceFound: false,
    running: false,
    programList: Ember.computed('model.[]', function () {
      console.log('programList', this.model);
      return this.model || [];
    }),
    actions: {
      comparePrograms() {
        Ember.set(this, 'running', true);
        Ember.RSVP.hash({
          a: this.store.findRecord('machine-program', this.firstProgram.id, {
            reload: true
          }),
          b: this.store.findRecord('machine-program', this.secondProgram.id, {
            reload: true
          })
        }).then(results => {
          let output = this.machines.compareObjects(results.a.settings, results.b.settings);
          Ember.set(this, 'comparison', output);
          Ember.set(this, 'running', false);
        });
      }

    }
  });

  _exports.default = _default;
});