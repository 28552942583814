define("alliance-business-suite/models/rewards-program-schedule-day", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    pointsMultiplier: attr('number', {
      defaultValue: 1
    }),
    dayLookupKey: attr('string'),
    runFromDate: attr('string', {
      defaultValue: null
    }),
    runToDate: attr('string', {
      defaultValue: null
    }),
    dayStartTime: attr('string', {
      defaultValue: '00:00:00'
    }),
    dayEndTime: attr('string', {
      defaultValue: '23:59:00'
    }),
    rewardsProgram: belongsTo('rewards-program'),
    dateRange: Ember.computed('runFromDate', {
      get() {
        let runFromDate = this.runFromDate;
        return runFromDate == null ? 'FOREVER' : 'CUSTOM';
      },

      set(key, value) {
        if (value === 'FOREVER') {
          this.set('runFromDate', null);
          this.set('runToDate', null);
        }

        return value;
      }

    }),
    timeRange: Ember.computed('dayStartTime', {
      get() {
        let dayStartTime = this.dayStartTime;
        let dayEndTime = this.dayEndTime;
        return dayStartTime === '00:00:00' && dayEndTime === '23:59:00' ? 'ALL_DAY' : 'CUSTOM';
      },

      set(key, value) {
        this.set('dayStartTime', '00:00:00');
        this.set('dayEndTime', '23:59:00');
        return value;
      }

    })
  });

  _exports.default = _default;
});