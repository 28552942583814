define("alliance-business-suite/templates/components/table/machines/table-cell-machine-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c1YzajSs",
    "block": "{\"symbols\":[\"@record\"],\"statements\":[[10,\"div\"],[14,0,\"text-align-left\"],[12],[2,\"\\n  \"],[8,\"machines/m-status-pill\",[],[[\"@model\",\"@status\",\"@statusLevel\",\"@lastUpdated\",\"@timeRemaining\",\"@isAvailable\",\"@createdAt\",\"@tooltipSide\",\"@showUpdatedLabel\"],[[32,1],[32,1,[\"machineCurrentStatus\",\"statusId\"]],[32,1,[\"statusLevel\"]],[32,1,[\"machineCurrentStatus\",\"updatedAt\"]],[32,1,[\"machineCurrentStatus\",\"remainingSeconds\"]],[32,1,[\"machineCurrentStatus\",\"isAvailable\"]],[32,1,[\"machineCurrentStatus\",\"createdAt\"]],\"top\",true]],null],[2,\"\\n\\n\"],[6,[37,2],[[32,0,[\"showLastStatus\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"small\"],[14,5,\"opacity: .5\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[[32,1,[\"machineCurrentStatus\",\"createdAt\"]]],[[\"displayTimeZone\",\"showMissingPlaceholder\"],[true,true]]]],[2,\"\\n\\n        \"],[8,\"ember-tooltip\",[],[[\"@event\",\"@_tooltipVariantClass\",\"@side\"],[\"hover\",\"ember-tooltip brush-tooltip\",\"bottom-start\"]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[30,[36,1],[\"models.machine-current-status.created-at\"],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"utc-format\",\"t\",\"if\"]}",
    "moduleName": "alliance-business-suite/templates/components/table/machines/table-cell-machine-status.hbs"
  });

  _exports.default = _default;
});