define("alliance-business-suite/templates/components/summary/reports/s-retail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cYC5OMKI",
    "block": "{\"symbols\":[\"@height\"],\"statements\":[[8,\"summary/reports/default-report-shim\",[],[[\"@height\",\"@translatedColumns\",\"@rowsWithChildren\"],[[32,1],[32,0,[\"translatedColumns\"]],[32,0,[\"rowsWithChildren\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "alliance-business-suite/templates/components/summary/reports/s-retail.hbs"
  });

  _exports.default = _default;
});