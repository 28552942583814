define("alliance-business-suite/helpers/alphabetize-objects", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),

    compute(_ref
    /* , hash*/
    ) {
      let [list] = _ref;
      let intl = Ember.get(this, 'intl');

      let addTranslatedKey = item => {
        // adds translatedKey. We do all translations at once, instead of on
        // each sort comparison, which would be many many more than O(n) calls here.
        Ember.set(item, 'translatedKey', intl.t(Ember.get(item, 'messageLocalizationKey')));
      };

      if (list.modelName) {
        // eslint-disable no-console
        console.log(new Error('alphabetize would have failed, changing data struc'));
        list = list.map(item => {
          return item;
        });
      }

      try {
        if (list.content && list.content.content && list.forEach) {
          // call from listcriptions/s-edit-form
          list.forEach(addTranslatedKey);
          list.content.set('content', list.content.content.sort((a, b) => Ember.get(a, '_record.translatedKey') < Ember.get(b, '_record.translatedKey') ? -1 : 1));
        } else if (list[0] && list.forEach) {
          // should be calling form alerts/s-edit-form
          list.forEach(addTranslatedKey);
          return list.sort((a, b) => a.translatedKey < b.translatedKey ? -1 : 1);
        } else if (Ember.get(list, 'content') && Ember.get(list, 'content')[0] && Ember.get(list, 'content')[0].__data && Ember.get(list, 'content')[0].__data.roomName) {
          Ember.set(list, 'content', Ember.get(list, 'content').sort((a, b) => a.__data.roomName < b.__data.roomName ? -1 : 1));
        } else {
          // eslint-disable no-console
          // window.list = list;
          // window.intl = intl;
          // console.log('list is global:', list);
          // console.log('list.content', list.get('content'));
          // eslint-disable-next-line no-console
          console.error(new Error('didnt hit any use case, returning un-sorted list'));
        }
      } catch (e) {
        throw new Error(`alphabetize-objects is setup only for sorting certain
                collections\n ${e}`);
      }

      return list;
    }

  });

  _exports.default = _default;
});