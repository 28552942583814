define("alliance-business-suite/components/form-ui/speed", ["exports", "alliance-business-suite/mixins/form-ui", "alliance-business-suite/utils/speed-utilities", "alliance-business-suite/utils/fix-range"], function (_exports, _formUi, _speedUtilities, _fixRange) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_formUi.default, {
    tagName: 'div',
    classNames: ['form-field', 'overflow-float', 'number-field', 'text-field'],
    // These need to be gone if displayValue is custom
    intl: Ember.inject.service(),
    showG: Ember.computed('params.{speedSettings.showG,machineProgram._showG}', function () {
      let value = this.params?.speedSettings?.showG ?? this.params?.machineProgram?._showG ?? true;

      if (typeof value != "boolean") {
        value = value.toString() == "0" ? false : true;
      }

      return value;
    }),
    basketDiameter: Ember.computed.alias('params.speedSettings.basketDiameter'),
    min: Ember.computed('params.speedSettings.min', function () {
      return this.params.speedSettings.min * 100;
    }),
    max: Ember.computed('params.speedSettings.max', function () {
      return this.params.speedSettings.max * 100;
    }),
    speedUnit: Ember.computed('showG', function () {
      return this.showG ? "G" : "RPM";
    }),
    step: Ember.computed('showG', function () {
      return this.showG ? 0.01 : 1;
    }),

    // TODO: Serialization and deserialization may not work correctly with divideBy values. This logic may need to move to the change() event.
    serializeValue(rawValue) {
      // Override in component, like a getter
      if (!Ember.isNone(rawValue)) {
        let gValue = new Big(rawValue).div(100); // Check limits using g-force values

        let g = (0, _fixRange.fixRange)(gValue.toFixed(), this.params.min, this.params.max); // If this property can be an enum or custom value, we may need to force the value within bounds and then force an update

        if (!gValue.eq(g)) {
          Ember.set(this, "value", g);
        }

        if (!this.showG) {
          let rpmValue = (0, _speedUtilities.toRPM)(this.basketDiameter, g);
          return rpmValue.toFixed();
        } else {
          return g;
        }
      }
    },

    deserializeValue(value) {
      let decimals = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
      let gValue = Number(value);

      let _gValue = Number(value); // 1) Does this need conversion to g-force?


      if (!this.showG) {
        gValue = (0, _speedUtilities.toG)(this.basketDiameter, value).toFixed(2);
        _gValue = (0, _speedUtilities.toG)(this.basketDiameter, value, undefined, 4).toFixed(4);
      } // 2) Check min/max limits


      gValue = (0, _fixRange.fixRange)(gValue, this.params.min, this.params.max); // 3) G-force gets multipled by 100 to become integer

      if (decimals === 2) {
        gValue = Big(gValue).times(100).toFixed(0);
      } else {
        gValue = Big(_gValue).times(100).toFixed(2);
      }

      return gValue;
    },

    value: Ember.computed('rawValue', 'preset', 'formSettings.[]', {
      // We need to update the values if there is a change to divideBy decimal placement.
      get(key) {
        // If _rawValue exists, we need to use that for more precision
        return this._rawValue ? this.serializeValue(this._rawValue) : this.serializeValue(this.rawValue);
      },

      set(key, value) {
        // Use speedChange action on change event to update data
        // If the value changes here, we need to update the bound property
        // TODO: Figure out what is changing the value here! Should be using speedChange to do this.
        this.update(this.object, this.params.propertyName, this.deserializeValue(value));
        return value;
      }

    }),
    actions: {
      valueChange(event) {
        // Add deserialization here - convert value to what the API expects
        let deserializeValue = this.deserializeValue(event.target.value);
        this.update(this.object, this.params.propertyName, deserializeValue); // Decimal precision is a problem going from G to RPM, so we need to track the value separately

        Ember.set(this, '_rawValue', this.deserializeValue(event.target.value, 4));
        this.notifyPropertyChange('value');
        return this.serializeValue(deserializeValue);
      }

    }
  });

  _exports.default = _default;
});