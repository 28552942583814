define("alliance-business-suite/routes/machines/programs/manage", ["exports", "@babel/runtime/helpers/esm/defineProperty", "alliance-business-suite/routes/_secureRoute"], function (_exports, _defineProperty2, _secureRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class MachinesProgramsManageRoute extends _secureRoute.default {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "allowAccess", 'MACHINES_PROGRAMS_VIEW');
      (0, _defineProperty2.default)(this, "disableBrush", true);
    }

    model(params) {
      return this.store.findRecord('machine-program', params.machine_program_id, {
        reload: true
      });
    }

    afterModel(model, transition) {
      super.afterModel(...arguments); // call the SecureRoutes default afterModel
      // Remove old properties that were added as part of water usage calculations.

      let validMachines = ['ACAS_A4_WX', 'ACAS_A4_FLW', 'ACAS_A44_WX', 'ACAS_A3_WX', 'ACAS_A3_FLW', 'ACAS_A2_FLW', 'ACAS_A2_WX'];

      if (model.settings && model.settings.Cycles && validMachines.includes(model._machineCode)) {
        console.time("Remove deprecated properties");
        let deprecatedProperies = ['__water_heavy_cold', '__water_heavy_hot', '__water_heavy_total', '__water_light_cold', '__water_light_hot', '__water_light_total', '__water_medium_cold', '__water_medium_hot', '__water_medium_total']; // Old properties are in settings.Cycle or settings.Cycle

        let cycles = model.settings.Cycles.CycleSteps || model.settings.Cycles;
        let count = 0;

        const loopNestedObj = obj => {
          Object.keys(obj).forEach(key => {
            if (obj[key] && typeof obj[key] === "object") {
              loopNestedObj(obj[key]); // recurse
            } else {
              if (deprecatedProperies.includes(key)) {
                count++;
                Ember.set(obj, key, undefined);
              }
            }
          });
        };

        loopNestedObj(cycles);
        console.timeEnd("Remove deprecated properties");
        console.log('Removed', count, 'deprecated properties.');
      } // Deprecation: Prices are no longer stored in the VendPriceArray. This will move them to the right place.


      if (model._machineGeneration === "ACAS" && model._machineTypeCode === "TUM") {
        if (model.settings.Program.Price.VendPriceArray) {
          Ember.set(model, "settings.Program.Price.HeatPrice", model.settings.Program.Price.VendPriceArray[0]);
          Ember.set(model, "settings.Program.Price.NoHeatPrice", model.settings.Program.Price.VendPriceArray[1]);
          delete model.settings.Program.Price.VendPriceArray;
          console.warn('DEPRECATION: VendPriceArray is no longer used. Prices have been moved to HeatPrice and NoHeatPrice.');
        }
      }
    }

  }

  _exports.default = MachinesProgramsManageRoute;
});