define("alliance-business-suite/components/ui/card-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Conditionally renders a single card. If the `selected` parameter matches the
   * name of this card, the block expression is rendered; otherwise nothing is
   * rendered.
   *
   * # Positional Parameters
   *
   * name
   * : The name of this card.
   *
   * # Named Parameters
   *
   * selected
   * : The name of the active card.
   *
   * @public
   */
  let ComponentClass = Ember.Component.extend({
    /**
     * The tag name used for this component. Since this is a controller component
     * that only yields, this is an empty string. This prevents a DOM element from
     * being created, instead rendering the template for this component directly
     * underneath its parent.
     *
     * @type {string}
     * @protected
     */
    tagName: '',

    /**
     * The currently selected card.
     *
     * @type {string}
     * @public
     */
    selected: undefined,

    /**
     * Returns true if this card is currently selected; false otherwise.
     *
     * @type {boolean}
     * @public
     */
    isSelected: Ember.computed('name', 'selected', function () {
      return this.name === this.selected;
    })
  });
  ComponentClass.reopenClass({
    positionalParams: ['name']
  });
  var _default = ComponentClass;
  _exports.default = _default;
});