define("alliance-business-suite/components/machines/programs/pricing/midas-wx", ["exports", "alliance-business-suite/mixins/machines/programs/midas-options", "alliance-business-suite/mixins/machines/programs/midas-property-names"], function (_exports, _midasOptions, _midasPropertyNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_midasOptions.default, _midasPropertyNames.default, {
    intl: Ember.inject.service(),
    cycleNames: Ember.computed(function () {
      let names = [];
      this.machineProgram.settings.Cycles.forEach(cycle => {
        names.push({
          val: cycle.Number,
          label: cycle.Name
        });
      });
      return names;
    }),
    // Need to observe any properties used for enabled, etc.
    formConfig: Ember.computed('watchForUpdates', 'bundle1Name', 'bundle2Name', 'isCelsius', 'machineProgram.settings.{Cycles,Program.Price.ModifierOptions.CycleModifierName_WX}', 'propertyNames.{coinDrop,defaultBundle,luckyCycleWX,worldDrop}', 'selectOptions.CyclesWx', function () {
      return {
        columns: [{
          sections: [{
            title: "base_price",
            controls: [{
              type: "base-price-multi-vend",
              arrayNames: ["Program.Price.CyclePrices.CyclePrices_WX", "Program.ExpandedProg.CyclePrices_WX"],
              propertyName: "Program.Price.CyclePrices.CyclePrices_WX",
              names: this.machineProgram.settings.Cycles.mapBy('Name'),
              cycleEnabled: this.machineProgram.settings.Cycles.mapBy('Enabled')
            }]
          }, {
            title: "defaults",
            controls: [{
              type: "checkbox-value-combo",
              properties: {
                select1: {
                  key: "Program.Misc.CycleRibonConfig_WX.0",
                  label: "default_cycle",
                  class: "cycle_display_selector",
                  options: this.cycleNames,
                  disabled: true
                },
                button1: {
                  type: "icon",
                  icon: "cog",
                  tooltip: "edit_cycle_order",
                  modal: "pricing/midas-wx-modal-cycle-order"
                }
              }
            }, {
              type: "default-bundle",
              label: "bundle",
              properties: this.propertyNames.defaultBundle
            }, {
              type: "select",
              propertyName: "Program.Display.NumericSeparator",
              options: "NumericSeparator"
            }, {
              type: "select",
              propertyName: "Program.Price.Misc.Currency1.Symbol",
              options: "CurrencyType"
            }, {
              type: "select",
              propertyName: "Program.Price.Misc.Currency1.PlaceAfter",
              options: "SymbolPlacement"
            }]
          }, {
            title: "coin_drops",
            controls: [// Enable coin 1
            {
              type: "checkbox-number-combo",
              numberType: "currency",
              properties: {
                checkbox: {
                  key: "Program.CoinPulse.Coin1Enabled",
                  label: "Coin1Enabled"
                },
                number: {
                  key: "Program.CoinPulse.Coin1Config.Value",
                  label: "value"
                }
              },
              min: 0,
              max: 65535
            }, // Enable coin 2
            {
              type: "checkbox-number-combo",
              numberType: "currency",
              properties: {
                checkbox: {
                  key: "Program.CoinPulse.Coin2Enabled",
                  label: "Coin2Enabled"
                },
                number: {
                  key: "Program.CoinPulse.Coin2Config.Value",
                  label: "value"
                }
              },
              min: 0,
              max: 65535
            }]
          }, {
            title: "world_drop_settings",
            controls: [// World drop settings
            {
              type: "world-drop",
              properties: this.propertyNames.worldDrop
            }]
          }, {
            title: "payment_method",
            controls: [{
              type: "coin-drop",
              properties: this.propertyNames.coinDrop
            }, // Card
            {
              type: "checkbox",
              propertyName: "Program.Price.Misc.PaymentMethods.Card"
            }, // Bill
            {
              type: "checkbox",
              propertyName: "Program.Price.Misc.PaymentMethods.Bill"
            }, // Phone
            {
              type: "checkbox",
              propertyName: "Program.Price.Misc.PaymentMethods.Mobile"
            }, // Kiosk
            {
              type: "checkbox",
              propertyName: "Program.Price.Misc.PaymentMethods.Kiosk"
            }]
          }]
        }, {
          sections: [{
            title: "lucky_cycle",
            controls: [{
              type: "lucky-cycle",
              properties: this.propertyNames.luckyCycleWX,
              title: "Lucky Cycle"
            }]
          }, {
            title: "bundles",
            controls: [{
              type: "checkbox-value-combo",
              properties: {
                checkbox1: {
                  key: "Program.Price.ModifierOptions.ModsAdditionalOptsEnabled.MediumBundle_WX",
                  label: this.bundle1Name,
                  disabled: "Program.Price.ModifierOptions.DefaultModifiers.MediumBundle_WX"
                },
                input1: {
                  type: "currency",
                  label: null,
                  key: "Program.Price.ModifierPrices.MediumBundleAdder_WX",
                  min: 0,
                  max: 65535
                },
                button1: {
                  type: "icon",
                  icon: "cog",
                  tooltip: "tooltip_additional_settings",
                  label: this.bundle1Name,
                  modal: "pricing/midas-wx-modal-medium-bundle",
                  modalTitle: `${this.bundle1Name} ${this.intl.t('bundle')}`
                }
              }
            }, {
              type: "checkbox-value-combo",
              properties: {
                checkbox1: {
                  key: "Program.Price.ModifierOptions.ModsAdditionalOptsEnabled.HeavyBundle_WX",
                  label: this.bundle2Name,
                  disabled: "Program.Price.ModifierOptions.DefaultModifiers.HeavyBundle_WX"
                },
                input1: {
                  type: "currency",
                  label: null,
                  key: "Program.Price.ModifierPrices.HeavyBundleAdder_WX",
                  min: 0,
                  max: 65535
                },
                button1: {
                  type: "icon",
                  icon: "cog",
                  tooltip: "tooltip_additional_settings",
                  label: this.bundle2Name,
                  modal: "pricing/midas-wx-modal-heavy-bundle",
                  modalTitle: `${this.bundle2Name} ${this.intl.t('bundle')}`
                }
              }
            }]
          }, {
            title: "modifiers",
            controls: [{
              type: "checkbox-value-combo",
              properties: {
                checkbox1: {
                  key: "Program.Price.ModifierOptions.ModsAdditionalOptsEnabled.DispenseSoap_WX",
                  label: "dispense_soap"
                },
                input1: {
                  type: "currency",
                  label: null,
                  key: "Program.Price.ModifierPrices.SupplyInjectionAdder_WX",
                  min: 0,
                  max: 65535
                },
                checkbox2: {
                  label: "include",
                  key: "Program.Price.ModifierOptions.DefaultModifiers.DispenseSoap_WX",
                  tooltip: "tooltip_include_with_default"
                }
              }
            }, {
              type: "checkbox-value-combo",
              properties: {
                checkbox1: {
                  key: "Program.Price.ModifierOptions.ModsAdditionalOptsEnabled.Ozone_WX",
                  label: "ozone"
                },
                input1: {
                  type: "currency",
                  label: null,
                  key: "Program.Price.ModifierPrices.OzoneAdder_WX",
                  min: 0,
                  max: 65535
                },
                checkbox2: {
                  label: "include",
                  key: "Program.Price.ModifierOptions.DefaultModifiers.Ozone_WX",
                  tooltip: "tooltip_include_with_default"
                }
              }
            }, {
              type: "checkbox-value-combo",
              properties: {
                checkbox1: {
                  key: "Program.Price.ModifierOptions.ModsAdditionalOptsEnabled.Mod1_WX",
                  label: this.modifier1Name
                },
                button1: {
                  notifyPropertyChange: true,
                  type: "icon",
                  icon: "cog",
                  tooltip: "tooltip_additional_settings",
                  modal: "pricing/midas-wx-modal-mod1"
                },
                input1: {
                  type: "currency",
                  label: null,
                  key: "Program.Price.ModifierPrices.Mod1Adder_WX",
                  min: 0,
                  max: 65535
                },
                checkbox2: {
                  label: "include",
                  key: "Program.Price.ModifierOptions.DefaultModifiers.Mod1_WX",
                  tooltip: "tooltip_include_with_default"
                }
              }
            }, {
              type: "checkbox-value-combo",
              properties: {
                checkbox1: {
                  key: "Program.Price.ModifierOptions.ModsAdditionalOptsEnabled.Mod2_WX",
                  label: this.modifier2Name
                },
                button1: {
                  notifyPropertyChange: true,
                  type: "icon",
                  icon: "cog",
                  tooltip: "tooltip_additional_settings",
                  modal: "pricing/midas-wx-modal-mod2"
                },
                input1: {
                  type: "currency",
                  label: null,
                  key: "Program.Price.ModifierPrices.Mod2Adder_WX",
                  min: 0,
                  max: 65535
                },
                checkbox2: {
                  label: "include",
                  key: "Program.Price.ModifierOptions.DefaultModifiers.Mod2_WX",
                  tooltip: "tooltip_include_with_default"
                }
              }
            }, {
              type: "checkbox-value-combo",
              properties: {
                checkbox1: {
                  key: "Program.Price.ModifierOptions.ModsAdditionalOptsEnabled.IncreasedWaterLevel_WX",
                  label: "increase_water_level"
                },
                input1: {
                  type: "currency",
                  label: null,
                  key: "Program.Price.ModifierPrices.IncreasedWaterLevelAdder_WX",
                  min: 0,
                  max: 65535
                },
                button1: {
                  type: "icon",
                  icon: "cog",
                  tooltip: "tooltip_additional_settings",
                  modal: "pricing/midas-wx-modal-increase-water-level"
                },
                checkbox2: {
                  label: "include",
                  key: "Program.Price.ModifierOptions.DefaultModifiers.IncreasedWaterLevel_WX",
                  tooltip: "tooltip_include_with_default"
                }
              }
            }, {
              type: "checkbox-value-combo",
              properties: {
                checkbox1: {
                  key: "Program.Price.ModifierOptions.ModsAdditionalOptsEnabled.ExtraTime_WX",
                  label: "extra_time"
                },
                input1: {
                  type: "currency",
                  label: null,
                  key: "Program.Price.ModifierPrices.ExtraTimeAdder_WX",
                  min: 0,
                  max: 65535
                },
                button1: {
                  type: "icon",
                  icon: "cog",
                  tooltip: "tooltip_additional_settings",
                  modal: "pricing/midas-wx-modal-extra-time"
                },
                checkbox2: {
                  label: "include",
                  key: "Program.Price.ModifierOptions.DefaultModifiers.ExtraTime_WX",
                  tooltip: "tooltip_include_with_default"
                }
              }
            }, {
              type: "checkbox-value-combo",
              properties: {
                checkbox1: {
                  key: "Program.Price.ModifierOptions.ModsAdditionalOptsEnabled.WarmFinalRinse_WX",
                  label: "warm_rinse"
                },
                input1: {
                  type: "currency",
                  label: null,
                  key: "Program.Price.ModifierPrices.WarmRinseAdder_WX",
                  min: 0,
                  max: 65535
                },
                checkbox2: {
                  label: "include",
                  key: "Program.Price.ModifierOptions.DefaultModifiers.WarmFinalRinse_WX",
                  tooltip: "tooltip_include_with_default"
                }
              }
            }, {
              type: "checkbox-value-combo",
              properties: {
                checkbox1: {
                  key: "Program.Price.ModifierOptions.ModsAdditionalOptsEnabled.IncreasedSpinSpeed_WX",
                  label: "increase_spin_speed"
                },
                input1: {
                  type: "currency",
                  label: null,
                  key: "Program.Price.ModifierPrices.IncreasedSpinSpeedAdder_WX",
                  min: 0,
                  max: 65535
                },
                button1: {
                  type: "icon",
                  icon: "cog",
                  tooltip: "tooltip_additional_settings",
                  modal: "pricing/midas-wx-modal-increase-spin-speed"
                },
                checkbox2: {
                  label: "include",
                  key: "Program.Price.ModifierOptions.DefaultModifiers.IncreasedSpinSpeed_WX",
                  tooltip: "tooltip_include_with_default"
                }
              }
            }, {
              type: "checkbox-value-combo",
              properties: {
                checkbox1: {
                  key: "Program.Price.ModifierOptions.ModsAdditionalOptsEnabled.SpeedCycle_WX",
                  label: "speed_cycle"
                },
                input1: {
                  type: "new-currency",
                  label: null,
                  key: "Program.Price.ModifierPrices.SpeedCycleAdder_WX",
                  min: -32768,
                  max: 32768
                },
                checkbox2: {
                  label: "include",
                  key: "Program.Price.ModifierOptions.DefaultModifiers.SpeedCycle_WX",
                  tooltip: "tooltip_include_with_default"
                }
              }
            }]
          }, {
            title: "temperatures",
            controls: [{
              type: "currency",
              propertyName: "Program.Price.ModifierPrices.WarmAdder_WX",
              label: "warm",
              min: 0,
              max: 65535
            }, {
              type: "currency",
              propertyName: "Program.Price.ModifierPrices.HotAdder_WX",
              label: "hot",
              min: 0,
              max: 65535
            }, {
              type: "currency",
              propertyName: "Program.Price.ModifierPrices.Temp15CAdder_WX",
              label: this.isCelsius ? "Temp15C" : "Temp59F",
              min: 0,
              max: 65535
            }, {
              type: "currency",
              propertyName: "Program.Price.ModifierPrices.Temp30CAdder_WX",
              label: this.isCelsius ? "Temp30C" : "Temp86F",
              min: 0,
              max: 65535
            }, {
              type: "currency",
              propertyName: "Program.Price.ModifierPrices.Temp40CAdder_WX",
              label: this.isCelsius ? "Temp40C" : "Temp104F",
              min: 0,
              max: 65535
            }, {
              type: "currency",
              propertyName: "Program.Price.ModifierPrices.Temp50CAdder_WX",
              label: this.isCelsius ? "Temp50C" : "Temp122F",
              min: 0,
              max: 65535
            }, {
              type: "currency",
              propertyName: "Program.Price.ModifierPrices.Temp60CAdder_WX",
              label: this.isCelsius ? "Temp60C" : "Temp140F",
              min: 0,
              max: 65535
            }, {
              type: "currency",
              propertyName: "Program.Price.ModifierPrices.Temp90CAdder_WX",
              label: this.isCelsius ? "Temp90C" : "Temp194F",
              min: 0,
              max: 65535
            }]
          }]
        }]
      };
    })
  });

  _exports.default = _default;
});