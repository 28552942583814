define("alliance-business-suite/components/machines/programs/banners/mgd-opl", ["exports", "alliance-business-suite/mixins/machines/programs/midas-opl-options", "alliance-business-suite/mixins/machines/programs/midas-opl-property-names"], function (_exports, _midasOplOptions, _midasOplPropertyNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_midasOplOptions.default, _midasOplPropertyNames.default, {});

  _exports.default = _default;
});