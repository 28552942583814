define("alliance-business-suite/components/ui/tab-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Renders a list of tabs.
   *
   * # Positional Parameters
   *
   * tabs
   * : An array of tab labels.
   *
   * # Named Parameters
   *
   * selected
   * : The label for the actively selected tab.
   *
   * onselected
   * : An action to call when a new tab is selected. Passed the `tab` as the
   *   first parameter.
   *
   *
   * @type {[type]}
   * @public
   */
  let ComponentClass = Ember.Component.extend({
    /**
     * The static class names for this component.
     *
     * @type {Array<String>}
     * @public
     */
    classNames: ['ui-tab-list'],

    /**
     * The tag name used for this component; an empty string prevents a DOM
     * element from being created, instead rendering the template for this
     * component directly underneath its parent.
     *
     * @protected
     */
    tagName: 'ul',

    /**
     * An array of tabs to render.
     *
     * @type {Array<string>}
     * @public
     */
    tabs: undefined,

    /**
     * The currently selected tab.
     *
     * @type {string}
     * @public
     */
    selected: undefined,

    /**
     * The tab that is currently active. Defaults to the first tab if no other
     * tab is selected.
     *
     * @protected
     */
    activeTab: Ember.computed('tabs', 'selected', function () {
      let tabs = Ember.get(this, 'tabs');
      let selected = Ember.get(this, 'selected');
      return selected != null ? selected : tabs && tabs.length > 0 ? tabs[0] : undefined;
    }),

    /**
     * The actions for this component.
     *
     * @public
     */
    actions: {
      /**
       * Sets the selected tab and executes the `onselect` action passed into
       * this component.
       *
       * @public
       */
      select(tab) {
        this.set('selected', tab);

        if (this.onselect) {
          this.onselect(tab);
        }
      }

    }
  });
  ComponentClass.reopenClass({
    positionalParams: ['tabs']
  });
  var _default = ComponentClass;
  _exports.default = _default;
});