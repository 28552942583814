define("alliance-business-suite/templates/components/ui/section-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GG3mpPOO",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,5],null,[[\"header\",\"contents\",\"group\"],[[30,[36,2],[\"ui/section-panel-header\"],[[\"type\"],[[35,4]]]],[30,[36,2],[\"ui/section-panel-element\"],[[\"minHeight\",\"componentClassName\"],[[35,3],[30,[36,1],[[35,0],\"__contents\"],null]]]],[30,[36,2],[\"ui/section-panel-element\"],[[\"componentClassName\"],[[30,[36,1],[[35,0],\"__group\"],null]]]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"componentClassName\",\"concat\",\"component\",\"minHeight\",\"type\",\"hash\"]}",
    "moduleName": "alliance-business-suite/templates/components/ui/section-panel.hbs"
  });

  _exports.default = _default;
});