define("alliance-business-suite/routes/locations/rewards/create", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "alliance-business-suite/routes/_secureRoute"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _secureRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  let LocationsRewardsCreateRoute = (_dec = Ember.inject.service, (_class = class LocationsRewardsCreateRoute extends _secureRoute.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _defineProperty2.default)(this, "allowAccess", 'LOCATIONS_MANAGE_REWARDS_PROGRAM');
    }

    model() {
      return Ember.RSVP.hash({
        rewardsProgram: this.store.createRecord('rewards-program')
      });
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = LocationsRewardsCreateRoute;
});