define("alliance-business-suite/templates/notifications", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jON9Nuk2",
    "block": "{\"symbols\":[\"notification\"],\"statements\":[[8,\"header-sub-nav\",[],[[\"@subnavTabs\"],[[32,0,[\"subnavTabs\"]]]],null],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"overflow-container\"],[12],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"section--container-v2 margin-bottom-20 listing-container-alt\"],[12],[2,\"\\n    \"],[10,\"header\"],[14,0,\"ui-route-header\"],[12],[2,\"\\n      \"],[10,\"h2\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"notifications\"],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[8,\"ui/section-panel\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,0,[\"model\"]]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"model-field form-field\"],[12],[2,\"\\n          \"],[10,\"label\"],[14,0,\"model-field__label\"],[12],[2,\"\\n            \"],[1,[30,[36,1],[[32,1,[\"createdAt\"]]],[[\"useLocalTimezone\",\"includeDate\"],[true,true]]]],[2,\" - \"],[10,\"strong\"],[12],[1,[32,1,[\"header\"]]],[13],[2,\"\\n          \"],[13],[2,\"\\n          \"],[10,\"span\"],[14,0,\"model-field__value\"],[12],[2,\"\\n            \"],[1,[32,1,[\"notification\",\"body\"]]],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[2,\"        \"],[10,\"p\"],[14,0,\"not-found\"],[12],[2,\"\\n          \"],[1,[30,[36,0],[\"no_notifications\"],null]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"f\",\"-track-array\",\"each\"]}",
    "moduleName": "alliance-business-suite/templates/notifications.hbs"
  });

  _exports.default = _default;
});