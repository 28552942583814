define("alliance-business-suite/components/machines/m-status-pill", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "firebase/app", "firebase/database"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _app, _database) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  // const componentClassName = 'machine-status-pill';
  let MachinesMStatusPillComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._action, _dec7 = Ember._action, (_class = class MachinesMStatusPillComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "firebaseRef", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "firebaseListener", _descriptor5, this);
    }

    initialLoad() {
      this.fetchFbStatus();
    }

    willDestroyPillComponent() {
      // it is possible for the user to be viewing two machine pills instances for the same machine
      // ex: on machine's list AND with an open machine brush on a machine
      // in that case, we do not want to kill the firebase subscription because the list is still active
      if (!this.args.isBrush) {
        this.firebaseRef.off('value', this.firebaseListener);
      }
    } // create the firebase subscription when the status pill is shown


    fetchFbStatus() {
      let {
        store
      } = this;
      let {
        model
      } = this.args;

      if (model) {
        let machineId = model.get('id');

        let machineCurrentStatusRef = _app.default.database().ref('machineCurrentStatuses/' + machineId);

        this.firebaseRef = machineCurrentStatusRef; // firebase updates

        let firebaseListener = machineCurrentStatusRef.on('value', function (snapshot) {
          let currentStatusObj = snapshot.val();
          let peekMachine = store.peekRecord('machineCurrentStatus', machineId);
          let canUpdate = true; // premature optimization?
          // if (peekMachine && peekMachine.createdAt == currentStatusObj.createdAt) {
          //   console.log('skip!')
          //   canUpdate = false
          // }
          // console.log('EXPENSIVE!', currentStatusObj.statusId, currentStatusObj);

          if (canUpdate && currentStatusObj) {
            store.push({
              data: {
                id: machineId,
                type: 'machine-current-status',
                attributes: {
                  statusId: currentStatusObj.statusId,
                  linkQualityIndicator: currentStatusObj.linkQualityIndicator,
                  remainingSeconds: currentStatusObj.remainingSeconds,
                  remainingVend: currentStatusObj.remainingVend,
                  createdAt: currentStatusObj.createdAt,
                  displayStatus: currentStatusObj.displayStatus
                },
                relationships: {
                  machine: {
                    data: {
                      type: "machine",
                      id: machineId
                    }
                  }
                }
              }
            });
          }
        });
        this.firebaseListener = firebaseListener;
      }
    } // alignClassName: computed('align', {
    //   get() {
    //     return `${componentClassName}--${this.align || 'left'}`;
    //   }
    // }),
    // @computed('status')


    get formattedStatus() {
      let {
        status
      } = this.args;

      if (!status) {
        return 'LOADING';
      }

      if (status == 'UNKNOWN') {
        return 'ERROR';
      }

      return this.intl.t(`models.machine-current-status.status-id.name.${status || 'UNKNOWN'}`);
    }

    get machineStatusTooltip() {
      return `machine_status_tooltip_${this.args.status.toLowerCase()}`;
    }

    get showTimer() {
      return this.args.statusLevel === 'in-use';
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "firebaseRef", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "firebaseListener", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "initialLoad", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "initialLoad"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "willDestroyPillComponent", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "willDestroyPillComponent"), _class.prototype)), _class));
  _exports.default = MachinesMStatusPillComponent;
});