define("alliance-business-suite/components/machines/programs/cycles/midas-ezopl-m3-tum", ["exports", "alliance-business-suite/mixins/machines/programs/midas-options"], function (_exports, _midasOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_midasOptions.default, {
    formConfig: Ember.computed(function () {
      return {
        columns: [{
          sections: [{
            title: "cycle_times",
            controls: [{
              type: "duration",
              label: "with_heat",
              properties: {
                minutes: "Program.Cycle.HeatTimeMinutes_TUM",
                seconds: "Program.Cycle.HeatTimeSeconds_TUM"
              },
              min: 60,
              max: 5940
            }, {
              type: "duration",
              label: "without_heat",
              properties: {
                minutes: "Program.Cycle.NoHeatTimeMinutes_TUM",
                seconds: "Program.Cycle.NoHeatTimeSeconds_TUM"
              },
              min: 60,
              max: 5940
            }]
          }]
        }, {
          sections: [{
            title: "cooldown_times",
            controls: [{
              type: "number",
              propertyName: "Program.Cycle.HighTempCooldownMinutes_TUM",
              min: 1,
              max: 15,
              labelAfter: "minutes"
            }, {
              type: "number",
              propertyName: "Program.Cycle.MediumTempCooldownMinutes_TUM",
              min: 1,
              max: 15,
              labelAfter: "minutes"
            }, {
              type: "number",
              propertyName: "Program.Cycle.LowTempCooldownMinutes_TUM",
              min: 1,
              max: 15,
              labelAfter: "minutes"
            }, {
              type: "number",
              propertyName: "Program.Cycle.DelicatesTempCooldownMinutes_TUM",
              min: 1,
              max: 15,
              labelAfter: "minutes"
            }]
          }, {
            disable: true,
            title: "top_off",
            controls: [{
              type: "duration",
              label: "coin_drop_1_top_off_time",
              properties: {
                minutes: "Program.CoinPulse.Coin1Config.TopoffMinutes_TUM",
                seconds: "Program.CoinPulse.Coin1Config.TopoffSeconds_TUM"
              },
              min: 10,
              max: 5940
            }, {
              type: "duration",
              label: "coin_drop_2_top_off_time",
              properties: {
                minutes: "Program.CoinPulse.Coin2Config.TopoffMinutes_TUM",
                seconds: "Program.CoinPulse.Coin2Config.TopoffSeconds_TUM"
              },
              min: 10,
              max: 5940
            }, {
              type: "duration",
              label: "payment_system_topoff_time",
              properties: {
                minutes: "Program.Price.Misc.PSTopoffMinutes_TUM",
                seconds: "Program.Price.Misc.PSTopoffSeconds_TUM"
              },
              min: 10,
              max: 5940
            }, {
              type: "duration",
              label: "remote_top_off_time",
              properties: {
                minutes: "Program.Price.Misc.RemoteTopoffMinutes_TUM",
                seconds: "Program.Price.Misc.RemoteTopoffSeconds_TUM"
              },
              min: 0,
              max: 5940
            }]
          }]
        }]
      };
    })
  });

  _exports.default = _default;
});