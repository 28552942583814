define("alliance-business-suite/helpers/troubleshoot-component-name", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (_ref) {
    let [value, ...rest] = _ref;
    let compName = value.toString().split(':');
    let output = compName.length > 2 ? compName[1] : 'unknown component name';
    return `<div class="dev-tools"><ul class="pills"><li>Component file: <strong>${output}</strong></li></ul></div>`;
  });

  _exports.default = _default;
});