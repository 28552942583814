define("alliance-business-suite/components/customers/membership-groups-section", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="section--container-v2 margin-bottom-20">
    <header class="ui-route-header">
      <h2>
        {{t "membership_groups"}}
        <span class="ui-route-header__subtitle">
          {{t "membership_groups_subtitle"}}
        </span>
      </h2>
  
      <div class="controls controls--right">
        {{#can-access "CUSTOMERS_COMMUNITY_GROUPS_MANAGE"}}
          <button
            class="primary"
            {{on "click" (fn this.toggleEditModal 'create')}}>
              {{fa-icon "plus"}}
          </button>
        {{/can-access}}
      </div>
    </header>
  
    <Customers::Groups::GroupListTable
      @groups={{@model}}
      @toggleModal={{this.toggleEditModal}}
    />
  </div>
  
  
  {{#if this.showEditModal}}
    <Customers::ModalGroupMembership
      @selectedGroup={{this.selectedGroup}}
      @close={{this.toggleEditModal}}
    />
  {{/if}}
  */
  {
    "id": "nNp7PZKe",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[10,\"div\"],[14,0,\"section--container-v2 margin-bottom-20\"],[12],[2,\"\\n  \"],[10,\"header\"],[14,0,\"ui-route-header\"],[12],[2,\"\\n    \"],[10,\"h2\"],[12],[2,\"\\n      \"],[1,[30,[36,2],[\"membership_groups\"],null]],[2,\"\\n      \"],[10,\"span\"],[14,0,\"ui-route-header__subtitle\"],[12],[2,\"\\n        \"],[1,[30,[36,2],[\"membership_groups_subtitle\"],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"controls controls--right\"],[12],[2,\"\\n\"],[6,[37,3],[\"CUSTOMERS_COMMUNITY_GROUPS_MANAGE\"],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[11,\"button\"],[24,0,\"primary\"],[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"toggleEditModal\"]],\"create\"],null]],null],[12],[2,\"\\n            \"],[10,\"i\"],[14,0,\"fa fa-plus\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[8,\"customers/groups/group-list-table\",[],[[\"@groups\",\"@toggleModal\"],[[32,1],[32,0,[\"toggleEditModal\"]]]],null],[2,\"\\n\"],[13],[2,\"\\n\\n\\n\"],[6,[37,4],[[32,0,[\"showEditModal\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"customers/modal-group-membership\",[],[[\"@selectedGroup\",\"@close\"],[[32,0,[\"selectedGroup\"]],[32,0,[\"toggleEditModal\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"t\",\"can-access\",\"if\"]}",
    "moduleName": "alliance-business-suite/components/customers/membership-groups-section.hbs"
  });

  let CustomersMembershipGroupsSectionComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._action, (_class = class CustomersMembershipGroupsSectionComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "canAccess", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "showEditModal", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "selectedGroup", _descriptor4, this);
    }

    toggleEditModal(id) {
      this.selectedGroup = id != "create" && id ? this.store.peekRecord("community-group", id) : null;
      this.showEditModal = !this.showEditModal;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "canAccess", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "showEditModal", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedGroup", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "toggleEditModal", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "toggleEditModal"), _class.prototype)), _class));
  _exports.default = CustomersMembershipGroupsSectionComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, CustomersMembershipGroupsSectionComponent);
});