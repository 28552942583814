define("alliance-business-suite/utils/fix-range", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fixValueToOptions = _exports.fixRangeWithDetails = _exports.fixRange = void 0;

  const isNone = val => {
    return val === undefined || val === null;
  };

  const fixRange = (val, min, max) => {
    // Make sure we have a value to work with
    if (isNone(val)) {
      return val;
    } // Check limits


    if (!isNone(max) && Number(val) > Number(max)) {
      val = max;
    } else if (!isNone(min) && Number(val) < Number(min)) {
      val = min;
    } else {// WE DON'T CARE
    }

    return val;
  };

  _exports.fixRange = fixRange;

  const fixRangeWithDetails = (val, min, max) => {
    let result = {
      withinRange: true,
      value: val
    }; // Make sure we have a value to work with

    if (val === undefined || val === null) {
      return result;
    } // Check limits


    if (max && Number(val) > Number(max)) {
      result.value = max;
      result.withinRange = false;
    } else if (min && Number(val) < Number(min)) {
      result.value = min;
      result.withinRange = false;
    } else {// WE DON'T CARE
    }

    return result;
  };
  /**
    Checks the value provided against all values in the options array. If the value is found
    in the array, it is returned. If the value is not found, the closest value in the array
    is returned.
  
    Warning: If the value is within the range of values in the array, but does not match a
    value in the array, it will be returned unchanged. For example, if the range of values
    is [10, 15, 20] and the value 12 is provided, 12 will be returned.
  
    @param {Array} options - The options to limit the value with
    @param {number} value - The value we want limited to range of options
    @return {number} - The "fixed" value that is within the range option values
  
    @example
      let options = [
        { val: 10 },
        { val: 15 },
        { val: 20 }
      ];
      fixValueToOptions(options, 5); // returns 10
      fixValueToOptions(options, 100); // returns 20
      fixValueToOptions(options, 15); // returns 15
  
  */


  _exports.fixRangeWithDetails = fixRangeWithDetails;

  const fixValueToOptions = (options, value) => {
    let val = Number(value); // Make sure the value is one of the options values

    let optionValues = options.map(option => {
      return option.val;
    }); // Is the number found in the range?

    if (optionValues.includes(val)) {
      return val;
    } // Check for value being outside min/max


    let min = Math.min.apply(null, optionValues);
    let max = Math.max.apply(null, optionValues);
    let valueInRange = fixRange(val, min, max);

    if (valueInRange !== val) {
      return valueInRange;
    } // Now find the closest value in the range


    optionValues.sort((a, b) => a - b);
    let index = 0;

    while (index < optionValues.length) {
      let indexValue = Number(optionValues[index]);

      if (indexValue > val) {
        let maxValue = indexValue;
        let minValue = Number(optionValues[index - 1]);

        if (maxValue - val < val - minValue) {
          return maxValue;
        } else {
          return minValue;
        }
      }

      index = index + 1;
    }
  };

  _exports.fixValueToOptions = fixValueToOptions;
});