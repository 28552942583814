define("alliance-business-suite/components/form-ui/temperature", ["exports", "alliance-business-suite/mixins/form-ui", "alliance-business-suite/utils/is-number", "alliance-business-suite/utils/convert-temperature", "alliance-business-suite/utils/fix-range"], function (_exports, _formUi, _isNumber, _convertTemperature, _fixRange) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_formUi.default, {
    tagName: 'div',
    classNames: ['form-field', 'overflow-float', 'number-field', 'text-field', 'temperature-field'],
    // These need to be gone if displayValue is custom
    intl: Ember.inject.service(),
    isCelsius: Ember.computed.or('machineProgram._isCelsius', 'formSettings.isCelsius'),
    // Moving toward celsius setting in the machineProgram model
    temperatureUnit: Ember.computed('isCelsius', function () {
      return this.isCelsius ? Ember.String.htmlSafe(' &deg;C') : Ember.String.htmlSafe(' &deg;F'); // TODO: Do we need intl here?
    }),
    isTemperatureRate: Ember.computed.alias('params.isTemperatureRate'),

    didReceiveAttrs() {
      this._super(...arguments); // We need to check for changes to min/max values, which can change based on MC10 step type


      if (this.params.min !== undefined || this.params.max !== undefined) {
        if (this.params.min !== this._min || this.params.max !== this._max) {
          this._min = this.params.min;
          this._max = this.params.max;
          this.forceValueWithinLimits();
        }
      }
    },

    // Convert value and min/max to Celsius if needed
    serializeValue(rawValue) {
      if (this.isCelsius) {
        return (0, _convertTemperature.toC)(rawValue);
      }

      return rawValue;
    },

    deserializeValue(value) {
      if (this.isCelsius) {
        return (0, _convertTemperature.toF)(value);
      }

      return value;
    },

    // Override form-ui's value property to convert to Celsius if needed
    minValue: Ember.computed('params.min', 'isCelsius', function () {
      if (this.isCelsius) {
        return (0, _convertTemperature.toC)(this.params.min);
      } else {
        return this.params.min;
      }
    }),
    maxValue: Ember.computed('params.max', 'isCelsius', function () {
      if (this.isCelsius) {
        return (0, _convertTemperature.toC)(this.params.max);
      } else {
        return this.params.max;
      }
    }),

    forceValueWithinLimits() {
      if (this.params.min !== undefined && this.params.max !== undefined) {
        let output = (0, _fixRange.fixRange)(this.value, this.min, this.max);

        if (output !== this.value) {
          Ember.set(this, 'value', output);
        }
      }
    },

    min: Ember.computed('isCelsius', 'params.min', 'isTemperatureRate', function () {
      let value = this.params.min;

      if (value && this.isCelsius) {
        return (0, _convertTemperature.toC)(value);
      }

      return value;
    }),
    max: Ember.computed('isCelsius', 'params.max', 'isTemperatureRate', function () {
      let value = this.params.max;

      if (value && this.isCelsius) {
        return (0, _convertTemperature.toC)(value);
      }

      return value;
    }),

    change() {
      // Enforce any step values
      let stepValue = this.params.step;
      let value = this.value;

      if (this.type === 'number' && (0, _isNumber.isNumber)(value) && stepValue) {
        value = new Big(value);
        let output = value.div(stepValue).round(0).times(stepValue);

        if (value != output) {
          Ember.set(this, 'value', output.toFixed(1)); // This is carryover from older code. Not sure why .toFixed() is here. 2021-07-05: seems to make Big return a number instead of a string?
        }
      } else {
        // The value is always a string, and needs to have limits applied
        this.forceValueWithinLimits();
      }
    }

  });

  _exports.default = _default;
});