define("alliance-business-suite/adapters/access-user", ["exports", "alliance-business-suite/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    /**
     * The path to use in place of the pluralized model name to retrieve data for
     * this adapter.
     *
     * @type {string}
     * @public
     */
    typePath: 'users'
  });

  _exports.default = _default;
});