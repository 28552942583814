define("alliance-business-suite/components/form-ui/low-power", ["exports", "alliance-business-suite/utils/number-to-hour", "alliance-business-suite/mixins/init-property-names", "alliance-business-suite/mixins/modals", "alliance-business-suite/utils/bits", "alliance-business-suite/mixins/show-component"], function (_exports, _numberToHour, _initPropertyNames, _modals, _bits, _showComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_showComponent.default, _initPropertyNames.default, _modals.default, {
    intl: Ember.inject.service(),

    /*
    *  Titanium machines store options in a bitfield in the LowPowerShutdownOptions property.
    *  Centurion machines do not have AutoShutdownEnabled. Either low power is enabled or shutdown is enabled.
    *  All other machines have two properties to enable low power and shutdown independently.
    */
    optionsBitfield: Ember.computed('LowPowerShutdownOptions', function () {
      return this.LowPowerShutdownOptions !== undefined;
    }),
    optionsSingleField: Ember.computed('optionsBitfield', 'AutoShutdownEnabled', function () {
      return !this.optionsBitfield && this.AutoShutdownEnabled == undefined;
    }),
    optionsTwoFields: Ember.computed('LowPowerEnabled', 'AutoShutdownEnabled', function () {
      return this.LowPowerEnabled !== undefined && this.AutoShutdownEnabled !== undefined;
    }),
    optionsSetting: Ember.computed('LowPowerEnabled', 'AutoShutdownEnabled', 'LowPowerShutdownOptions', function () {
      let lowPowerEnabled;
      let shutdownEnabled;

      if (this.LowPowerShutdownOptions !== undefined) {
        // Titanium
        lowPowerEnabled = this.LowPowerShutdownOptions === 1 || this.LowPowerShutdownOptions === 3;
        shutdownEnabled = this.LowPowerShutdownOptions === 2 || this.LowPowerShutdownOptions === 3;
      } else if (this.LowPowerShutdownOptions == undefined && this.AutoShutdownEnabled === undefined) {
        // Centurion
        lowPowerEnabled = this.LowPowerEnabled;
        shutdownEnabled = !this.LowPowerEnabled;
      } else {
        lowPowerEnabled = this.LowPowerEnabled;
        shutdownEnabled = this.AutoShutdownEnabled;
      }

      if (lowPowerEnabled && shutdownEnabled) {
        return `${this.intl.t('low_power_and_auto_shutdown_on')} - `;
      } else if (lowPowerEnabled) {
        return `${this.intl.t('low_power_on')} - `;
      } else if (shutdownEnabled) {
        return `${this.intl.t('auto_shutdown_on')} - `;
      } else {
        return `${this.intl.t('on')} - `; // Enabled, but no options set
      }
    }),
    summaryText: Ember.computed('Enabled', 'LowPowerEnabled', 'AutoShutdownEnabled', 'LowPowerShutdownOptions', 'DayOfWeek', 'Saturday', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'StartMonth', 'StartDate', 'StartYear', 'EndMonth', 'EndDate', 'EndYear', 'StartHour', 'StartMinute', 'Duration', function () {
      let output = '';

      if (!this.Enabled || this.Enabled === false) {
        return this.intl.t('Off');
      }

      output += this.optionsSetting; // Need to map days from individual properties or bitfield

      let day = {
        Sunday: this.Sunday || _bits.default.get(this.DayOfWeek, 1),
        Monday: this.Monday || _bits.default.get(this.DayOfWeek, 2),
        Tuesday: this.Tuesday || _bits.default.get(this.DayOfWeek, 3),
        Wednesday: this.Wednesday || _bits.default.get(this.DayOfWeek, 4),
        Thursday: this.Thursday || _bits.default.get(this.DayOfWeek, 5),
        Friday: this.Friday || _bits.default.get(this.DayOfWeek, 6),
        Saturday: this.Saturday || _bits.default.get(this.DayOfWeek, 7)
      }; // When: Days (dates) (hours)
      // Every Day; Weekends; Weekdays; Mon-Wed, Fri

      if (day.Saturday && day.Sunday && day.Monday && day.Tuesday && day.Wednesday && day.Thursday && day.Friday) {
        output += 'Every Day, ';
      } else {
        // Weekends
        if (day.Saturday && day.Sunday) {
          output += ` ${this.intl.t('weekends')}, `;
        } else if (day.Saturday) {
          output += `${this.intl.t('sat')}, `;
        } else if (day.Sunday) {
          output += `${this.intl.t('sun')}, `;
        } // Weekdays


        if (day.Monday && day.Tuesday && day.Wednesday && day.Thursday && day.Friday) {
          output += `${this.intl.t('weekdays')}, `;
        } else {
          if (day.Monday) {
            output += `${this.intl.t('mon')}, `;
          }

          if (day.Tuesday) {
            output += `${this.intl.t('tue')}, `;
          }

          if (day.Wednesday) {
            output += `${this.intl.t('wed')}, `;
          }

          if (day.Thursday) {
            output += `${this.intl.t('thu')}, `;
          }

          if (day.Friday) {
            output += `${this.intl.t('fri')}, `;
          }
        } // Dates - this has some logic. If the day, month, or year has a value of 0, date limit is not enabled. If start = 1/1/2000 and end = 12/31/2099, also has no limit.


        if (this.StartMonth == 0 || this.StartDate == 0 || this.EndMonth == 0 || this.EndDate == 0 || this.StartMonth == 1 && this.StartDate == 1 && this.StartYear == 0 && this.EndMonth == 12 && this.EndDate == 31 && this.EndYear == 99) {// No date limits
        } else {
          // StartMonth and EndMonth are 1-based
          // TODO: Localize the date
          output += `(${this.StartMonth}/${this.StartDate}/${this.StartYear}&ndash;${this.EndMonth}/${this.EndDate}/${this.EndYear})  `;
        } // Hours - this seems to have some logic. If duration is 0, not enabled.


        if (this.Duration > 0) {
          // We'll need to figure out the end time
          let startHour = (0, _numberToHour.default)(this.StartHour);
          let endHour = (0, _numberToHour.default)(Number(this.StartHour) + Number(this.Duration));
          output += `(${startHour}&ndash;${endHour}), `;
        }
      }

      return output.length > 2 ? Ember.String.htmlSafe(output.substr(0, output.length - 2)) : output;
    })
  });

  _exports.default = _default;
});