define("alliance-business-suite/adapters/report", ["exports", "alliance-business-suite/adapters/application", "alliance-business-suite/utils/demo-mode"], function (_exports, _application, _demoMode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    access: Ember.inject.service(),

    /**
     * Builds the URL for a request.
     *
     * @public
     */
    // TODO - Work with team to use proper end point
    buildURL(modelName, id, snapshot, requestType, query) {
      let url = this._super(modelName, id, snapshot, requestType, query);

      switch (requestType) {
        case 'queryRecord':
          url += `/${query.filter.id}`;
          delete query.filter.id;
          break;
      }

      return url;
    },

    handleResponse(status, headers, payload, requestData) {
      if (this.access.isDemo) {
        console.log('DEMO');
        return (0, _demoMode.fudgeReports)(payload);
      }

      return payload;
    }

  });

  _exports.default = _default;
});