define("alliance-business-suite/utils/options-temperature-range", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
    Takes a starting temperature, ending temperature, and step value and returns a range of temperatures
    formatted for use as options: [{ val: 100 }]
  
    @param {number} startTemp - The starting temperature
    @param {number} endTemp - The ending temperature
    @param {number} step - The increment to use for the range of values
    @return {Array} - An array of option objects
  
    @example
  
      optionsTemperatureRange(100, 110, 5); // [{ val: 100 }, { val: 105 }, { val: 110 }]
  
  */
  var _default = (startTemp, endTemp, step) => {
    let options = [];

    for (let i = startTemp; i <= endTemp; i += step) {
      options.push({
        val: i
      });
    }

    return options;
  };

  _exports.default = _default;
});