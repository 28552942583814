define("alliance-business-suite/components/form-ui/world-drop", ["exports", "alliance-business-suite/mixins/init-property-names"], function (_exports, _initPropertyNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MGD_TOKEN = 0;
  const MIDAS_TOKEN = -1;
  /**
   * Midas: Token = -1
   *        Blank = 0
   *        Coin >=1
   *
   * MGD:   Token = 0
   *        Coin >= 1
   *        Blank is not an option
   */

  var _default = Ember.Component.extend(_initPropertyNames.default, {
    drop5: Ember.computed('machineProgram._machineGeneration', function () {
      // Values differ by audit type. Midas = -1, Platinum = 1.
      return Ember.get(this, 'machineProgram._machineGeneration') === "MGD" ? MGD_TOKEN : MIDAS_TOKEN;
    }),
    DropTypeToken: Ember.computed('machineProgram._machineGeneration', function () {
      let val = Ember.get(this, 'machineProgram._machineGeneration') === "MGD" ? MGD_TOKEN : MIDAS_TOKEN;
      return [{
        label: "Token",
        val
      }];
    })
  });

  _exports.default = _default;
});