define("alliance-business-suite/components/ui/date/week-picker", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="inputless-shim {{if @disabled 'disabled'}}" {{on "click" this.togglePikaday}}>
    <span>
      {{moment-format @startDate this.dateFormat}}
       -
      {{moment-format @endDate this.dateFormat}}
    </span>
  
    <label class="date-picker">
      <Ui::DateFormat
        @value={{@startDate}}
        @options={{hash pickWholeWeek=true showDaysInNextAndPreviousMonths=true}}
        @onSelection={{this.update}}
        @inputless={{true}}
        @showPika={{this.showPikaday}}
        @toggleInputless={{this.togglePikaday}}
        @maxDate={{this.maxDate}}
        @overrideFirstDay={{this.firstDay}}
      />
    </label>
  </div>
  
  */
  {
    "id": "hi2CthNv",
    "block": "{\"symbols\":[\"@disabled\",\"@startDate\",\"@endDate\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"inputless-shim \",[30,[36,0],[[32,1],\"disabled\"],null]]]],[4,[38,1],[\"click\",[32,0,[\"togglePikaday\"]]],null],[12],[2,\"\\n  \"],[10,\"span\"],[12],[2,\"\\n    \"],[1,[30,[36,2],[[32,2],[32,0,[\"dateFormat\"]]],null]],[2,\"\\n     -\\n    \"],[1,[30,[36,2],[[32,3],[32,0,[\"dateFormat\"]]],null]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"label\"],[14,0,\"date-picker\"],[12],[2,\"\\n    \"],[8,\"ui/date-format\",[],[[\"@value\",\"@options\",\"@onSelection\",\"@inputless\",\"@showPika\",\"@toggleInputless\",\"@maxDate\",\"@overrideFirstDay\"],[[32,2],[30,[36,3],null,[[\"pickWholeWeek\",\"showDaysInNextAndPreviousMonths\"],[true,true]]],[32,0,[\"update\"]],true,[32,0,[\"showPikaday\"]],[32,0,[\"togglePikaday\"]],[32,0,[\"maxDate\"]],[32,0,[\"firstDay\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"on\",\"moment-format\",\"hash\"]}",
    "moduleName": "alliance-business-suite/components/ui/date/week-picker.hbs"
  });

  let UiDateWeekPickerComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, (_class = class UiDateWeekPickerComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "showPikaday", _descriptor3, this);
    }

    // toggle the inputless date picker into view
    get dateFormat() {
      const userId = this.access.accessUser.get('id');
      return this.store.peekRecord('employee', userId).dateFormat;
    }

    get firstDay() {
      return this.access.accessOrganization.get('isoStartDayOfWeek');
    }

    get maxDate() {
      if (this.args.maxDate) {
        return new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 6);
      }
    }

    togglePikaday() {
      if (!this.args.isLoading) {
        this.showPikaday = !this.showPikaday;
      }
    }

    update(val) {
      this.findStartOfWeek(val);
    } // isoStartDayOfWeek
    // find the start of the week depending on where they clicked on the calendar


    findStartOfWeek(val) {
      const firstDayOfWeek = this.access.accessOrganization.get('isoStartDayOfWeek'); // organization can set their start of week (monday, eg)

      let newVal = new Date(val);
      let isoWeekOfSelected = newVal.getDay(); // fyi iso: monday = 1... sunday = 7, uses 1 index
      // only if they do not pick the start of week do we coerce it

      if (isoWeekOfSelected != firstDayOfWeek) {
        let diff = firstDayOfWeek - isoWeekOfSelected;

        if (diff > 0) {
          diff = 7 - diff;
        } else {
          diff = diff * -1;
        }

        newVal = new Date(newVal.getTime() - 1000 * 60 * 60 * 24 * diff); // vanilla js subtraction of dates
      }

      this.args.onUpdate(newVal);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "showPikaday", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "togglePikaday", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "togglePikaday"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "update", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "update"), _class.prototype)), _class));
  _exports.default = UiDateWeekPickerComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, UiDateWeekPickerComponent);
});