define("alliance-business-suite/components/rooms/room-tab-social-media", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),

    didInsertElement() {
      this._super(...arguments);
    }

  });

  _exports.default = _default;
});