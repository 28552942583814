define("alliance-business-suite/components/machines/programs/cycles/steps-midas-wx", ["exports", "alliance-business-suite/mixins/machines/programs/mc10-options"], function (_exports, _mc10Options) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    stepControls: Ember.computed('selectedStep.Type', 'selectedStep', function () {
      let stepId = Ember.get(this, 'selectedStep.Type');
      let allStepSettings = Ember.get(this, 'allStepSettings');
      let thisStepSettings = allStepSettings.findBy("id", stepId); // return thisStep.controls;

      return thisStepSettings;
    }),
    stepChange: Ember.observer('selectedStep', function () {
      // Scroll to top when step changes
      // TODO: Make then event-based so it's not fragile.
      document.getElementById('split3').scrollTop = 0;
    }),
    allStepSettings: [{
      id: 1,
      stepName: "ReuseFill",
      controls: ["active-label", "step-time", "flush-out", "water-level", "water-valves", "heater-options-temperature", // WaterValvesControlled, WaterValves
      "speed", "aux-output", "reuse"],
      validations: {
        stepMinutes: [0, 255],
        stepSeconds: [0, 59]
      },
      properties: {
        Type: "Type",
        Label: "Label",
        Speed: "Speed",
        AuxFill: "Valves.AuxFill",
        HotFill: "Valves.HotFill",
        ColdFill: "Valves.ColdFill",
        FillValveSet: "Valves.FillValveSet",
        SprayValveSet: "Valves.SprayValveSet",
        ExtraFillInlet: "Valves.ExtraFillInlet",
        FillTubValveSet: "Valves.FillTubValveSet",
        TempControlledFill: "Valves.TempControlledFill",
        Minutes: "Minutes",
        Seconds: "Seconds",
        PauseSec: "PauseSec",
        RotateSec: "RotateSec",
        AuxOutputsI: "AuxOutputs.I",
        AuxOutputsJ: "AuxOutputs.J",
        AuxOutputsK: "AuxOutputs.K",
        AuxOutputsL: "AuxOutputs.L",
        AuxOutputsM: "AuxOutputs.M",
        AuxOutputsN: "AuxOutputs.N",
        ReuseFillA: "ReuseFillA",
        ReuseFillB: "ReuseFillB",
        WaterLevel: "WaterLevel",
        ActiveState: "ActiveState",
        Temperature: "Temperature",
        FlushoutEnabled: "FlushoutEnabled",
        ReversingDisabled: "ReversingDisabled"
      }
    }, {
      id: 2,
      stepName: "Fill",
      controls: ["active-label", "step-time", "flush-out", "water-level", "water-valves", "heater-options-temperature", "speed", "aux-output"],
      validations: {
        stepHours: [0, 255],
        stepMinutes: [0, 255],
        stepSeconds: [0, 59]
      },
      properties: {
        Type: "Type",
        Label: "Label",
        Speed: "Speed",
        AuxFill: "Valves.AuxFill",
        HotFill: "Valves.HotFill",
        ColdFill: "Valves.ColdFill",
        FillValveSet: "Valves.FillValveSet",
        SprayValveSet: "Valves.SprayValveSet",
        ExtraFillInlet: "Valves.ExtraFillInlet",
        FillTubValveSet: "Valves.FillTubValveSet",
        TempControlledFill: "Valves.TempControlledFill",
        Minutes: "Minutes",
        Seconds: "Seconds",
        PauseSec: "PauseSec",
        RotateSec: "RotateSec",
        AuxOutputsI: "AuxOutputs.I",
        AuxOutputsJ: "AuxOutputs.J",
        AuxOutputsK: "AuxOutputs.K",
        AuxOutputsL: "AuxOutputs.L",
        AuxOutputsM: "AuxOutputs.M",
        AuxOutputsN: "AuxOutputs.N",
        WaterLevel: "WaterLevel",
        ActiveState: "ActiveState",
        Temperature: "Temperature",
        FlushoutEnabled: "FlushoutEnabled",
        AllowTempOverride: "AllowTempOverride",
        ReversingDisabled: "ReversingDisabled"
      }
    }, {
      id: 3,
      stepName: "Supply",
      controls: ["active-label", "step-time", "delay-time", "water-valves", "internal-external-supply", "aux-supply", "aux-output"],
      validations: {
        stepMinutes: [0, 255],
        stepSeconds: [0, 59]
      },
      properties: {
        Type: "Type",
        Label: "Label",
        AuxFill: "Valves.AuxFill",
        HotFill: "Valves.HotFill",
        ColdFill: "Valves.ColdFill",
        FillValveSet: "Valves.FillValveSet",
        SprayValveSet: "Valves.SprayValveSet",
        ExtraFillInlet: "Valves.ExtraFillInlet",
        FillTubValveSet: "Valves.FillTubValveSet",
        TempControlledFill: "Valves.TempControlledFill",
        Minutes: "Minutes",
        Seconds: "Seconds",
        AuxOutputsI: "AuxOutputs.I",
        AuxOutputsJ: "AuxOutputs.J",
        AuxOutputsK: "AuxOutputs.K",
        AuxOutputsL: "AuxOutputs.L",
        AuxOutputsM: "AuxOutputs.M",
        AuxOutputsN: "AuxOutputs.N",
        AuxSupplyA: "AuxSupplyA",
        AuxSupplyB: "AuxSupplyB",
        AuxSupplyC: "AuxSupplyC",
        AuxSupplyD: "AuxSupplyD",
        AuxSupplyE: "AuxSupplyE",
        AuxSupplyF: "AuxSupplyF",
        AuxSupplyG: "AuxSupplyG",
        AuxSupplyH: "AuxSupplyH",
        ActiveState: "ActiveState",
        SupplyDelay: "SupplyDelay",
        Compartment1: "Compartment1",
        Compartment2: "Compartment2",
        Compartment3: "Compartment3",
        Compartment4: "Compartment4",
        GlobalSupplyTime: "GlobalSupplyTime",
        ShutoffCondition: "ShutoffCondition"
      }
    }, {
      id: 4,
      stepName: "Agitate",
      controls: ["active-label", "step-time", "heater-options-rate", "speed", "drain-refill", "aux-supply", "aux-output", "reuse"],
      validations: {
        stepMinutes: [0, 255],
        stepSeconds: [0, 59]
      },
      properties: {
        Type: "Type",
        Label: "Label",
        Speed: "Speed",
        Minutes: "Minutes",
        Seconds: "Seconds",
        HeatRate: "HeatRate",
        PauseSec: "PauseSec",
        RotateSec: "RotateSec",
        AuxOutputsI: "AuxOutputs.I",
        AuxOutputsJ: "AuxOutputs.J",
        AuxOutputsK: "AuxOutputs.K",
        AuxOutputsL: "AuxOutputs.L",
        AuxOutputsM: "AuxOutputs.M",
        AuxOutputsN: "AuxOutputs.N",
        AuxSupplyA: "AuxSupplyA",
        AuxSupplyB: "AuxSupplyB",
        AuxSupplyC: "AuxSupplyC",
        AuxSupplyD: "AuxSupplyD",
        AuxSupplyE: "AuxSupplyE",
        AuxSupplyF: "AuxSupplyF",
        AuxSupplyG: "AuxSupplyG",
        AuxSupplyH: "AuxSupplyH",
        HeatOption: "HeatOption",
        ReuseFillA: "ReuseFillA",
        ReuseFillB: "ReuseFillB",
        ActiveState: "ActiveState",
        Refill: "DrainOptions.Refill",
        MainDrain1: "DrainOptions.MainDrain1",
        MainDrain2: "DrainOptions.MainDrain2",
        PulseDrain: "DrainOptions.PulseDrain",
        PHChemFlush: "DrainOptions.PHChemFlush",
        ExternalDrain: "ExternalDrain",
        ReversingDisabled: "ReversingDisabled"
      }
    }, {
      id: 5,
      stepName: "Soak",
      controls: ["active-label", "step-time", "heater-options-rate", "speed", "drain-refill", "aux-supply", "aux-output", "reuse"],
      validations: {
        stepHours: [0, 255],
        stepMinutes: [0, 59]
      },
      properties: {
        Type: "Type",
        Hours: "Hours",
        Label: "Label",
        Speed: "Speed",
        Minutes: "Minutes",
        HeatRate: "HeatRate",
        PauseSec: "PauseSec",
        RotateSec: "RotateSec",
        AuxOutputsI: "AuxOutputs.I",
        AuxOutputsJ: "AuxOutputs.J",
        AuxOutputsK: "AuxOutputs.K",
        AuxOutputsL: "AuxOutputs.L",
        AuxOutputsM: "AuxOutputs.M",
        AuxOutputsN: "AuxOutputs.N",
        AuxSupplyA: "AuxSupplyA",
        AuxSupplyB: "AuxSupplyB",
        AuxSupplyC: "AuxSupplyC",
        AuxSupplyD: "AuxSupplyD",
        AuxSupplyE: "AuxSupplyE",
        AuxSupplyF: "AuxSupplyF",
        AuxSupplyG: "AuxSupplyG",
        AuxSupplyH: "AuxSupplyH",
        HeatOption: "HeatOption",
        ReuseFillA: "ReuseFillA",
        ReuseFillB: "ReuseFillB",
        ActiveState: "ActiveState",
        Refill: "DrainOptions.Refill",
        MainDrain1: "DrainOptions.MainDrain1",
        MainDrain2: "DrainOptions.MainDrain2",
        PulseDrain: "DrainOptions.PulseDrain",
        PHChemFlush: "DrainOptions.PHChemFlush",
        ExternalDrain: "ExternalDrain",
        ReversingDisabled: "ReversingDisabled"
      }
    }, {
      id: 6,
      stepName: "Cooldown",
      controls: ["active-label", "cooldown-options", "water-valves", "speed", "aux-supply", "aux-output"],
      validations: {},
      properties: {
        Type: "Type",
        Label: "Label",
        Speed: "Speed",
        AuxFill: "Valves.AuxFill",
        HotFill: "Valves.HotFill",
        ColdFill: "Valves.ColdFill",
        FillValveSet: "Valves.FillValveSet",
        SprayValveSet: "Valves.SprayValveSet",
        ExtraFillInlet: "Valves.ExtraFillInlet",
        FillTubValveSet: "Valves.FillTubValveSet",
        TempControlledFill: "Valves.TempControlledFill",
        CoolRate: "CoolRate",
        PauseSec: "PauseSec",
        RotateSec: "RotateSec",
        AuxOutputsI: "AuxOutputs.I",
        AuxOutputsJ: "AuxOutputs.J",
        AuxOutputsK: "AuxOutputs.K",
        AuxOutputsL: "AuxOutputs.L",
        AuxOutputsM: "AuxOutputs.M",
        AuxOutputsN: "AuxOutputs.N",
        AuxSupplyA: "AuxSupplyA",
        AuxSupplyB: "AuxSupplyB",
        AuxSupplyC: "AuxSupplyC",
        AuxSupplyD: "AuxSupplyD",
        AuxSupplyE: "AuxSupplyE",
        AuxSupplyF: "AuxSupplyF",
        AuxSupplyG: "AuxSupplyG",
        AuxSupplyH: "AuxSupplyH",
        ActiveState: "ActiveState",
        AntiWrinkle: "AntiWrinkle",
        Temperature: "Temperature",
        ReversingDisabled: "ReversingDisabled"
      }
    }, {
      id: 7,
      stepName: "Drain",
      controls: ["active-label", {
        type: "speed",
        display: "distribution"
      }, "drain", "reuse", "aux-supply", "aux-output"],
      validations: {
        rotateTime: [3, 255],
        pauseTime: [3, 255],
        requireDrain: true
      },
      properties: {
        Type: "Type",
        Label: "Label",
        Speed: "Speed",
        PauseSec: "PauseSec",
        RotateSec: "RotateSec",
        AuxOutputsI: "AuxOutputs.I",
        AuxOutputsJ: "AuxOutputs.J",
        AuxOutputsK: "AuxOutputs.K",
        AuxOutputsL: "AuxOutputs.L",
        AuxOutputsM: "AuxOutputs.M",
        AuxOutputsN: "AuxOutputs.N",
        AuxSupplyA: "AuxSupplyA",
        AuxSupplyB: "AuxSupplyB",
        AuxSupplyC: "AuxSupplyC",
        AuxSupplyD: "AuxSupplyD",
        AuxSupplyE: "AuxSupplyE",
        AuxSupplyF: "AuxSupplyF",
        AuxSupplyG: "AuxSupplyG",
        AuxSupplyH: "AuxSupplyH",
        ReuseFillA: "ReuseFillA",
        ReuseFillB: "ReuseFillB",
        ActiveState: "ActiveState",
        AlwaysRotate: "AlwaysRotate",
        Refill: "DrainOptions.Refill",
        MainDrain1: "DrainOptions.MainDrain1",
        MainDrain2: "DrainOptions.MainDrain2",
        PulseDrain: "DrainOptions.PulseDrain",
        PHChemFlush: "DrainOptions.PHChemFlush",
        ExternalDrain: "ExternalDrain",
        ReversingDisabled: "ReversingDisabled"
      }
    }, {
      id: 9,
      stepName: "DrainExtract",
      controls: ["active-label", "step-time", "speed", "drain", "reuse", "aux-supply", "aux-output"],
      validations: {
        stepMinutes: [0, 9],
        stepSeconds: [0, 59],
        requireDrain: true
      },
      properties: {
        Type: "Type",
        Label: "Label",
        Minutes: "Minutes",
        Seconds: "Seconds",
        AuxOutputsI: "AuxOutputs.I",
        AuxOutputsJ: "AuxOutputs.J",
        AuxOutputsK: "AuxOutputs.K",
        AuxOutputsL: "AuxOutputs.L",
        AuxOutputsM: "AuxOutputs.M",
        AuxOutputsN: "AuxOutputs.N",
        AuxSupplyA: "AuxSupplyA",
        AuxSupplyB: "AuxSupplyB",
        AuxSupplyC: "AuxSupplyC",
        AuxSupplyD: "AuxSupplyD",
        AuxSupplyE: "AuxSupplyE",
        AuxSupplyF: "AuxSupplyF",
        AuxSupplyG: "AuxSupplyG",
        AuxSupplyH: "AuxSupplyH",
        MainDrain1: "MainDrain1",
        MainDrain2: "MainDrain2",
        PulseDrain: "PulseDrain",
        ReuseFillA: "ReuseFillA",
        ReuseFillB: "ReuseFillB",
        ActiveState: "ActiveState",
        Speed: "CustomSpeed",
        ExternalDrain: "ExternalDrain"
      }
    }, {
      id: 10,
      stepName: "AudioSignal",
      controls: ["active-label", "audio-signal", "step-time", "aux-supply", "aux-output"],
      validations: {
        stepMinutes: [0, 2],
        stepSeconds: [0, 59]
      },
      properties: {
        Type: "Type",
        Label: "Label",
        Minutes: "Minutes",
        Pattern: "Pattern",
        Seconds: "Seconds",
        AuxOutputsI: "AuxOutputs.I",
        AuxOutputsJ: "AuxOutputs.J",
        AuxOutputsK: "AuxOutputs.K",
        AuxOutputsL: "AuxOutputs.L",
        AuxOutputsM: "AuxOutputs.M",
        AuxOutputsN: "AuxOutputs.N",
        AuxSupplyA: "AuxSupplyA",
        AuxSupplyB: "AuxSupplyB",
        AuxSupplyC: "AuxSupplyC",
        AuxSupplyD: "AuxSupplyD",
        AuxSupplyE: "AuxSupplyE",
        AuxSupplyF: "AuxSupplyF",
        AuxSupplyG: "AuxSupplyG",
        AuxSupplyH: "AuxSupplyH",
        ActiveState: "ActiveState"
      }
    }, {
      id: 11,
      stepName: "Hold",
      controls: ["active-label", "audio-signal", "step-time", "drain", "reuse", "aux-supply", "aux-output", "door"],
      validations: {
        stepMinutes: [0, 2],
        stepSeconds: [0, 59]
      },
      properties: {
        Type: "Type",
        Label: "Label",
        X_Message: "Message",
        Minutes: "Minutes",
        Pattern: "Pattern",
        Seconds: "Seconds",
        AuxOutputsI: "AuxOutputs.I",
        AuxOutputsJ: "AuxOutputs.J",
        AuxOutputsK: "AuxOutputs.K",
        AuxOutputsL: "AuxOutputs.L",
        AuxOutputsM: "AuxOutputs.M",
        AuxOutputsN: "AuxOutputs.N",
        AuxSupplyA: "AuxSupplyA",
        AuxSupplyB: "AuxSupplyB",
        AuxSupplyC: "AuxSupplyC",
        AuxSupplyD: "AuxSupplyD",
        AuxSupplyE: "AuxSupplyE",
        AuxSupplyF: "AuxSupplyF",
        AuxSupplyG: "AuxSupplyG",
        AuxSupplyH: "AuxSupplyH",
        ReuseFillA: "ReuseFillA",
        ReuseFillB: "ReuseFillB",
        UnlockDoor: "UnlockDoor",
        ActiveState: "ActiveState",
        Refill: "DrainOptions.Refill",
        MainDrain1: "DrainOptions.MainDrain1",
        MainDrain2: "DrainOptions.MainDrain2",
        PulseDrain: "DrainOptions.PulseDrain",
        PHChemFlush: "DrainOptions.PHChemFlush",
        ExternalDrain: "ExternalDrain",
        AutoResumeMinutes: "AutoResumeMinutes"
      }
    }]
  });

  _exports.default = _default;
});