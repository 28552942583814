define("alliance-business-suite/models/inventory", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;

  var _default = Model.extend({
    quantity: attr('number'),
    price: attr('number'),
    room: belongsTo('room'),
    item: belongsTo('item'),
    cycleCount: belongsTo('cycleCount'),
    taxRates: hasMany('taxRate'),
    hiddenPOS: attr('boolean'),
    createdBy: belongsTo('user', {
      inverse: null
    }),
    priceFormatted: Ember.computed('price', {
      get() {
        return parseFloat(this.price, 10).toFixed(2);
      }

    }),
    inventoryGuideline: Ember.computed('item.reorderGuidelines.[]', function () {
      let room = this.room;
      let item = this.item;
      let itemGuidelines = item.get('reorderGuidelines');
      let inventoryGuideline;

      if (itemGuidelines) {
        itemGuidelines.forEach(function (itemGuideline) {
          if (itemGuideline.get('room.id') === room.get('id')) {
            inventoryGuideline = itemGuideline;
          }
        });
      }

      return inventoryGuideline;
    })
  });

  _exports.default = _default;
});