define("alliance-business-suite/templates/components/ui/section-panel-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Il5lpSEu",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[15,0,[31,[[34,0],\"__title listing-container__title\"]]],[12],[2,\"\\n\"],[6,[37,4],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"checkbox-container\"],[12],[2,\"\\n    \"],[10,\"input\"],[14,\"checked\",\"checked\"],[14,4,\"checkbox\"],[12],[13],[2,\"\\n      \"],[8,\"input\",[],[[\"@type\",\"@checked\"],[\"checkbox\",[32,0,[\"checkbox\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n    \"],[1,[34,2]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[34,2]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,4],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[15,0,[31,[[34,0],\"__subtitle subtitle\"]]],[12],[1,[34,1]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\"],[6,[37,4],[[27,[32,1]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[31,[[34,0],\"__controls listing-container__controls\"]]],[12],[2,\"\\n    \"],[18,1,null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"componentClassName\",\"subtitle\",\"title\",\"displayCheckbox\",\"if\"]}",
    "moduleName": "alliance-business-suite/templates/components/ui/section-panel-header.hbs"
  });

  _exports.default = _default;
});