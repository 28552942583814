define("alliance-business-suite/components/customers/c-transaction-item", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3;

  let CustomersCTransactionItemComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._action, (_class = class CustomersCTransactionItemComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor3, this);
      (0, _defineProperty2.default)(this, "updatedBy", null);
    }

    // @computed('item.userAccountTransactionTypeLookupKey')
    get typeCssClass() {
      return `TRANSACTION_${this.args.item.userAccountTransactionTypeLookupKey}`;
    } // @computed('item.userAccountTransactionTypeLookupKey')


    get isRefundable() {
      let type = this.args.item.userAccountTransactionTypeLookupKey;

      if (type == 'MACHINE_PAYMENT' || type == 'MACHINE_PAYMENT_REFUND') {
        return true;
      }

      return false;
    } // @computed('item.depositRewardsEarned')


    get hasVCMDepositRewards() {
      let item = this.args.item;

      if (!item.depositRewardsEarned || item.depositRewardsEarned == '0.00' || item.depositRewardsEarned == '0') {
        return false;
      }

      return true;
    } // @computed('item.transactionDescription')


    get machineInfo() {
      let item = this.args.item;
      let descriptions = item.transactionDescription;
      let machine = descriptions[2].text;
      let machineNumber = descriptions[3] ? descriptions[3].text : null;
      let machineType = '';
      let transactionType = item.userAccountTransactionTypeLookupKey;

      if (!machineNumber) {
        return 'Disconnected Machine';
      }

      if (transactionType == 'MACHINE_PAYMENT' || transactionType == 'ATRIUM_MACHINE_PAYMENT') {
        machine = descriptions[1].text;
        machineNumber = item.machineName;
      }

      if (machine.includes('Tumbler') || machine.includes('Dryer')) {
        machineType = this.intl.t('dryer');
      } else {
        machineType = this.intl.t('washer');
      }

      let machineNumberFormatted = machineNumber; // if machine has default name, use default formatting

      if (machineNumber && machineNumber.includes('Machine Node -')) {
        machineNumber = descriptions[2].text;
        machineNumber = `${machineNumber.substring(0, machineNumber.indexOf('-'))}`;
        machineNumberFormatted = `#${machineNumber.replace(/^[0|\D]*/, '')}`;
      }

      return `${machineType} ${machineNumberFormatted}`;
    } // @computed('item.transactionDescription')


    get locationInfo() {
      let item = this.args.item;
      let descriptions = item.transactionDescription;
      let transactionType = item.userAccountTransactionTypeLookupKey;
      let loc;

      if (transactionType == 'MACHINE_PAYMENT_DISCONNECTED' || transactionType == 'MACHINE_PAYMENT') {
        loc = descriptions[0].text;
      } else {
        loc = descriptions[1].text;
      }

      if (this.access.isDemo) {
        console.log('Demo override');
        return 'Demo Lancaster Ave';
      }

      return loc.substring(0, loc.indexOf(':'));
    } // @computed('item.transactionDescription')


    get secondaryInfo() {
      let item = this.args.item;
      let type = item.userAccountTransactionTypeLookupKey;
      let descriptions = item.transactionDescription;
      let result;

      if (this.access.isDemo) {
        console.log('Demo override');
        return 'Demo Lancaster Ave';
      }

      if (type == 'ORGANIZATION_BALANCE_ADJUSTMENT') {
        // let user = this.get('store').peekRecord('user', item.updatedBy);
        result = descriptions[0].text;
      }

      if (type == 'DEPOSIT_FUNDS') {
        let loc = descriptions[0].text;

        if (loc.indexOf(':') > 0) {
          result = loc.substring(0, loc.indexOf(':'));
        } else {
          result = loc;
        }
      }

      if (type === 'REWARDS_CREDIT_PROMO_REDEEMED' || type === 'REWARDS_POINTS_PROMO_REDEEMED') {
        const loc = descriptions[2]?.text;
        result = loc ? `${this.intl.t('code')}: ${loc.substring(loc.indexOf('|') + 1)}` : null;
      }

      return result;
    }

    refresh() {
      this.args.refresh();
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "refresh", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "refresh"), _class.prototype)), _class));
  _exports.default = CustomersCTransactionItemComponent;
});