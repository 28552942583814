define("alliance-business-suite/templates/components/form-ui/language-selection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BCBfzbbH",
    "block": "{\"symbols\":[\"ui\",\"lang\"],\"statements\":[[6,[37,0],[[32,0,[\"showComponent\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"form-ui\",[],[[\"@object\",\"@machineProgram\",\"@formSettings\",\"@formDisplay\"],[[32,0,[\"object\"]],[32,0,[\"machineProgram\"]],[32,0,[\"formSettings\"]],\"compact\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,0,[\"languages\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[15,0,[31,[\"checkbox-field \",[30,[36,0],[[32,0,[\"noClass\"]],\"\",\"form-field overflow-float\"],null]]]],[12],[2,\"\\n        \"],[10,\"label\"],[14,0,\"form-field--label inline-checkbox form-ui-element\"],[12],[2,\"\\n          \"],[10,\"input\"],[14,0,\"form-field--control\"],[15,\"checked\",[32,2,[\"selected\"]]],[15,\"onclick\",[30,[36,1],[[32,0],\"checkboxClicked\",[32,2]],null]],[14,4,\"checkbox\"],[12],[13],[2,\"\\n          \"],[1,[30,[36,2],[[32,2,[\"label\"]]],null]],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"\\n    \"],[8,\"form-ui/dev-tools\",[],[[\"@outputs\"],[[30,[36,5],null,[[\"propertyName\",\"value\",\"rawValue\"],[[32,0,[\"params\",\"propertyName\"]],[32,0,[\"value\"]],[32,0,[\"rawValue\"]]]]]]],null],[2,\"\\n\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"action\",\"t\",\"-track-array\",\"each\",\"hash\"]}",
    "moduleName": "alliance-business-suite/templates/components/form-ui/language-selection.hbs"
  });

  _exports.default = _default;
});