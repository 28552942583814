define("alliance-business-suite/components/summary/reports/s-campus-card-recon", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "alliance-business-suite/utils/currency-format-util", "alliance-business-suite/utils/report-tables-util"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _currencyFormatUtil, _reportTablesUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  let SummaryReportsSCampusCardReconComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, (_class = class SummaryReportsSCampusCardReconComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor3, this);
    }

    get orgAccountNumber() {
      const organizationId = this.access.accessOrganization.get('id');
      return this.store.peekRecord('organization', organizationId).get('accountNumber');
    }

    get translatedColumns() {
      let options = [{
        name: 'location',
        valuePath: 'name',
        width: 100,
        isFixed: 'left'
      }, {
        name: 'amount',
        valuePath: 'amount',
        width: 100
      }]; // if has account number, then put it in index 2

      if (this.orgAccountNumber) {
        let a = options.slice(0, 1);
        let b = options.slice(1);
        a.push({
          name: 'organization_account_number',
          valuePath: 'orgAccountNumber'
        });
        options = [...a, ...b];
      }

      return options;
    }

    get rowsWithChildren() {
      let geoBoundaries = this.args.model.get('geoBoundaries');
      let rowData = [];
      geoBoundaries.forEach(geoBoundary => {
        let rowArray = [];
        let nameArray = [];
        let totalCardAmountSum = 0;
        let totalCardAmount = [];
        rowData.push({
          name: _reportTablesUtil.localizeString.call(this, geoBoundary.name, 'Location', 'location'),
          amount: totalCardAmount,
          children: nameArray,
          orgAccountNumber: this.orgAccountNumber
        });
        geoBoundary.rooms.forEach(room => {
          let roomCardTotalArray = [];

          if (room.amount !== 0) {
            roomCardTotalArray.push(room.amount);
            totalCardAmountSum = (0, _reportTablesUtil.sumArray)(roomCardTotalArray, totalCardAmountSum);
          }

          nameArray.push({
            name: room.name,
            amount: _currencyFormatUtil.formatCurrencyString.call(this, room.amount || 0),
            orgAccountNumber: this.orgAccountNumber,
            children: rowArray
          });
        });
        totalCardAmount.push(_currencyFormatUtil.formatCurrencyString.call(this, totalCardAmountSum || 0));
      });
      return rowData;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SummaryReportsSCampusCardReconComponent;
});