define("alliance-business-suite/components/machines/programs/cycles/steps-acas-tlw", ["exports", "alliance-business-suite/mixins/machines/programs/cycles/step-global"], function (_exports, _stepGlobal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_stepGlobal.default, {
    // Alias properties for cooldownWaterValvesTemperature CP
    HotFillValveOn: Ember.computed.alias('model.HotFillValveOn'),
    ColdFillValveOn: Ember.computed.alias('model.ColdFillValveOn'),
    // Alias properties for waterValvesTempeature CP
    HotFillOn: Ember.computed.alias('model.HotFillOn'),
    ColdFillOn: Ember.computed.alias('model.ColdFillOn'),
    TemperatureControlledFill: Ember.computed.alias('model.TemperatureControlledFill')
  });

  _exports.default = _default;
});