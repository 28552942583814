define("alliance-business-suite/components/form-ui/steps/drain-refill", ["exports", "alliance-business-suite/mixins/init-property-names"], function (_exports, _initPropertyNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_initPropertyNames.default, {
    showDrain2: Ember.computed('MainDrain2', 'machineProgram._machineCode', function () {
      // This used to be handled in the template with this:
      //    {{#if (not (is-empty this.MainDrain2))}}
      if (this.MainDrain2 === undefined) {
        return false;
      }

      if (["ACAS_A3_FLW", "ACAS_A4_FLW", "MGD_M1_FLW", "ACAS_A2_FLW"].includes(this.machineProgram._machineCode)) {
        return false;
      }

      return true;
    })
  });

  _exports.default = _default;
});