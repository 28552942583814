define("alliance-business-suite/components/machines/programs/cycles/ui/cycles-water-use", ["exports", "alliance-business-suite/utils/cycles-values"], function (_exports, _cyclesValues) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const toL = value => {
    return (Number(value) * 3.78541).toFixed(2);
  };

  const FILL = 2;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    isStep: Ember.computed('stepIndex', function () {
      return this.stepIndex !== undefined;
    }),
    enabledStates: Ember.computed('modStates', function () {
      let states = this.modStates ? this.modStates.slice() : [];
      states.push(_cyclesValues.ACTIVE_STATES.enabled);
      states.push(_cyclesValues.ACTIVE_STATES.speedCycle);
      return states;
    }),
    show: Ember.computed('isStep', 'enabled', 'enabledStates', 'activeState', 'stepType', function () {
      // Check if this is a cycle that is enabled
      if (!this.isStep && this.enabled) {
        return true;
      } // Check if this is a step that is enabled


      if (this.enabled && this.stepType === FILL) {
        if (this.activeState === _cyclesValues.ACTIVE_STATES.speedCycle && this.mod === 's') {
          return false;
        }

        return this.enabledStates.includes(this.activeState);
      } else {
        return false;
      }
    }),
    hot: Ember.computed('isStep', 'data', 'cycleIndex', 'stepIndex', 'mod', 'useLiters', function () {
      if (this.isStep) {
        let value = this.data[this.cycleIndex].step[this.stepIndex][this.mod][1];
        return this.useLiters ? toL(value) : value;
      } else {
        let value = this.data[this.cycleIndex][this.mod][1];
        return this.useLiters ? toL(value) : value;
      }
    }),
    total: Ember.computed('isStep', 'data', 'cycleIndex', 'stepIndex', 'mod', 'useLiters', function () {
      if (this.isStep) {
        let value = this.data[this.cycleIndex].step[this.stepIndex][this.mod][0];
        return this.useLiters ? toL(value) : value;
      } else {
        let value = this.data[this.cycleIndex][this.mod][0];
        return this.useLiters ? toL(value) : value;
      }
    }),
    units: Ember.computed('useLiters', function () {
      let t = this.intl;
      return this.useLiters ? t.t('l') : t.t('g');
    })
  });

  _exports.default = _default;
});