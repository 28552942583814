define("alliance-business-suite/components/summary/reports/s-employee-schedule-stats", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "alliance-business-suite/utils/currency-format-util", "alliance-business-suite/utils/report-tables-util"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _currencyFormatUtil, _reportTablesUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  let SummaryReportsSEmployeeScheduleStatsComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, (_class = class SummaryReportsSEmployeeScheduleStatsComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor3, this);
    }

    get translatedColumns() {
      return [{
        name: '',
        valuePath: 'name',
        isFixed: 'left'
      }, // { name: 'fullName', valuePath: 'fullName', width: 200},
      // { name: 'fullName', valuePath: 'fullName' },
      {
        name: 'email',
        valuePath: 'email'
      }, {
        name: 'date',
        valuePath: 'date'
      }, {
        name: 'total_sales',
        valuePath: 'totalSales'
      }, {
        name: 'scheduled_hours',
        valuePath: 'hoursWorked'
      }, {
        name: 'sales_per_hour',
        valuePath: 'salesPerHour'
      }];
    }

    get rowsWithChildren() {
      let rooms = this.args.model.rooms;
      let roomData = [];
      rooms.forEach(room => {
        let roomSectionDataArray = [];
        roomData.push({
          name: room.roomName,
          children: roomSectionDataArray
        });
        room.employees.forEach(employee => {
          let employeeData = [];
          roomSectionDataArray.push({
            name: `${employee.firstName} ${employee.lastName}`,
            email: employee.email,
            // isCollapsed: true,
            children: employeeData
          });
          employee.dates.forEach(salesDate => {
            let salesPerHour = 0;

            if (salesDate.hoursWorked > 0) {
              salesPerHour = salesDate.totalSales / salesDate.hoursWorked;
            }

            employeeData.push({
              date: moment(salesDate.date).format(`${_reportTablesUtil.formatDateTimeString.call(this)}`),
              totalSales: _currencyFormatUtil.formatCurrencyString.call(this, salesDate.totalSales || 0),
              hoursWorked: salesDate.hoursWorked,
              salesPerHour: _currencyFormatUtil.formatCurrencyString.call(this, salesPerHour || 0)
            });
          });
        });
      });
      return roomData;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SummaryReportsSEmployeeScheduleStatsComponent;
});