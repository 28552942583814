define("alliance-business-suite/components/table/table-cell-intl-loop", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "moment"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  let TableTableCellIntlLoopComponent = (_dec = Ember.inject.service, (_class = class TableTableCellIntlLoopComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor, this);
    }

    get isEmpty() {
      return this.splitString[0] === '';
    }

    get indexLength() {
      let splitString = this.splitString;
      return splitString.length - 1;
    }

    get splitString() {
      let record = this.args.record;
      let key = this.args.column.get('propertyName');
      let result = record[key];

      if (!result) {
        result = [];
      }

      return result;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = TableTableCellIntlLoopComponent;
});