define("alliance-business-suite/initializers/input-limits", ["exports", "alliance-business-suite/utils/is-number"], function (_exports, _isNumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let alreadyRun = false;
  var _default = {
    name: 'input-limits',

    initialize() {
      if (alreadyRun) {
        return;
      } else {
        alreadyRun = true;
      }

      Ember.TextField.reopen({
        focusOut() {
          // This was added because clicking on an ember-power-select does something strange
          // with the change() event and the value isn't updated correctly.
          this._super(...arguments);

          this.change();
        },

        change() {
          this._super(...arguments);

          try {
            let {
              limit,
              type,
              min,
              max,
              value,
              allowDecimal,
              allowDecimals
            } = this;

            if (allowDecimals !== undefined) {
              throw new Error('pass in allowDecimal NOT allowDecimals!');
            }

            if (allowDecimal === false || allowDecimal === 'false') {
              value = Math.trunc(value);
              Ember.set(this, 'value', value);
            }

            if (limit && type.toLowerCase() === 'number') {
              if ((0, _isNumber.isNumber)(min) && Number(value) < Number(min)) {
                Ember.set(this, 'value', min);
              }

              if ((0, _isNumber.isNumber)(max) && Number(value) > Number(max)) {
                Ember.set(this, 'value', max);
              }
            }
          } catch (error) {// fail silently
          }

          return true;
        }

      });
    }

  };
  _exports.default = _default;
});