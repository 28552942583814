define("alliance-business-suite/components/machines/programs/config/midas-opl-tum", ["exports", "alliance-business-suite/mixins/machines/programs/midas-opl-options", "alliance-business-suite/mixins/machines/programs/midas-opl-property-names"], function (_exports, _midasOplOptions, _midasOplPropertyNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_midasOplOptions.default, _midasOplPropertyNames.default, {
    formConfig: Ember.computed(function () {
      // eslint-disable-line
      return {
        columns: [{
          sections: [{
            title: "machine_settings",
            controls: [{
              type: "daylight-saving",
              properties: this.propertyNames.daylightSaving,
              hours12: true
            }, {
              disable: true,
              type: "checkbox",
              propertyName: "Program.Display.VendPriceDisplayOverrideEnabled"
            }, {
              type: "checkbox",
              propertyName: "Program.Misc.Comms.IREnabled"
            }, {
              disable: true,
              type: "checkbox",
              propertyName: "Program.Misc.Machine.PaymentSystemDIP"
            }, {
              type: "checkbox",
              propertyName: "Program.Misc.Comms.USBEnabled"
            }, {
              type: "checkbox",
              propertyName: "Program.Cycle.AntiWrinkleEnabled_TUM",
              label: "enable_antiwrinkle"
            }, // {
            //   type: "checkbox",
            //   propertyName: "Program.Misc.Comms.WifiEnabled"
            // },
            {
              type: "checkbox",
              propertyName: "Program.Misc.MiscConfig.FactoryTestCycleEnabled"
            }, {
              type: "checkbox-extended-tumble",
              propertyName: 'Program.Cycle.ExtendedTumbleEnabled_TUM'
            }, {
              type: "checkbox",
              propertyName: "Program.Misc.MiscConfig.ErrorClearingEnabled",
              label: "error_clearing"
            }]
          }, {
            disable: true,
            title: "break_in_alarm",
            controls: [{
              type: "break-in-alarm",
              properties: this.propertyNames.breakinAlarm
            }]
          }, {
            title: "manual_mode",
            controls: [{
              type: "manual-mode",
              properties: this.propertyNames.manualMode
            }]
          }]
        }, {
          sections: [{
            title: "display_settings",
            controls: [{
              type: "checkbox",
              propertyName: "Program.Display.DisplayPowerSave",
              label: "enable_display_power_save"
            }, // TODO: Confirm this is the correct property
            {
              type: "checkbox",
              propertyName: "Program.Display.FlameIndicator",
              label: "enable_heat_indicator"
            }, {
              type: "checkbox",
              propertyName: "Program.Display.DisplayTimeSinceCyleComplete"
            }, {
              type: "checkbox",
              propertyName: "Program.Display.DisplayMoistureError_TUM",
              label: "display_moisture_level"
            }, // {
            //   type: "checkbox",
            //   propertyName: "Program.Display.DisplayTimeMinutesOnly_TUM",
            //   inverted: true
            // },
            // {
            //   type: "checkbox",
            //   propertyName: "Program.Display.DisplayApproachScreen"
            // },
            {
              type: "checkbox",
              propertyName: "Program.Display.DisplayLimitCycles_TUM"
            }, // {
            //   type: "user-languages",
            //   languages: this.propertyNames.userLanguages
            // },
            // TODO: These three are missing
            {
              type: "number",
              propertyName: "Program.Display.Backlight.Active",
              labelKey: "backlight_user_interaction",
              min: 0,
              max: 255
            }, {
              type: "number",
              propertyName: "Program.Display.Backlight.InactiveCriticalMode",
              min: 0,
              max: 255
            }, {
              type: "number",
              propertyName: "Program.Display.Backlight.InactiveNonCriticalMode",
              min: 0,
              max: 255
            }, // TODO: Finish this select
            {
              type: "select",
              propertyName: "Program.Misc.DaylightSavings.DateOrder",
              label: "display_cell_configuration",
              options: "CellConfiguration"
            }, {
              type: "select",
              propertyName: "Program.Misc.DaylightSavings.DateOrder",
              options: "DateOrder"
            }, {
              type: "select",
              propertyName: "Program.Misc.MiscConfig.DefaultLanguage",
              options: "Language"
            } // {
            //   type: "text",
            //   propertyName: "Program.Misc.MiscConfig.HelpNumber",
            //   length: 20
            // },
            // {
            //   disable: true,
            //   type: "number",
            //   propertyName: "Program.Price.Misc.KioskNumber",
            //   min: 0,
            //   max: 255
            // },
            ]
          }, {
            title: "programmable_errors",
            controls: [// {
            //   disable: true,
            //   type: "checkbox",
            //   propertyName: "Program.Misc.Errors.CoinErrorEnabled"
            // },
            // {
            //   disable: true,
            //   type: "checkbox",
            //   propertyName: "Program.Misc.Errors.CoinErrorPenaltyEnabled"
            // },
            // {
            //   disable: true,
            //   type: "checkbox",
            //   propertyName: "Program.Misc.Errors.CoinDropVendHeaderErrorEnabled",
            //   inverted: true
            // },
            {
              type: "checkbox",
              propertyName: "Program.Display.DisplayFalseFlameError_TUM"
            }, {
              type: "checkbox",
              propertyName: "Program.Display.DisplayMoistureError_TUM",
              label: "display_moisture_errors"
            }]
          }, {
            title: "audio_signals",
            controls: [// {
            //   type: "checkbox",
            //   propertyName: "Program.Misc.MiscConfig.EndOfCycleAudioEnabled",
            //   label: "EndOfCycleAudioEnabled"
            // },
            {
              type: "checkbox-value-combo",
              properties: {
                checkbox1: {
                  key: "Program.Misc.MiscConfig.EndOfCycleAudioEnabled",
                  label: "EndOfCycleAudioEnabled"
                },
                input1: {
                  key: "Program.Cycle.EndOfCycleAudioDuration",
                  type: "number",
                  label: "duration",
                  min: 1,
                  max: 120,
                  labelAfter: "min"
                }
              }
            }, {
              type: "checkbox-value-combo",
              properties: {
                checkbox1: {
                  key: "Program.Cycle.EndOfCycleExternalSignalConfig",
                  label: "enable_end_of_cycle_external_signal"
                },
                input1: {
                  key: "Program.Cycle.EndOfCycleExternalSignalDuration",
                  type: "number",
                  label: "duration",
                  min: 1,
                  max: 120,
                  labelAfter: "min"
                }
              }
            }, // {
            //   type: "checkbox",
            //   propertyName: "Program.Cycle.EndOfCycleExternalSignalConfig",
            //   label: "enable_end_of_cycle_external_signal"
            // },
            // {
            //   disable: true,
            //   type: "checkbox",
            //   propertyName: "Program.Misc.MiscConfig.CoinCardAudioEnabled"
            // },
            // {
            //   disable: true,
            //   type: "checkbox",
            //   propertyName: "Program.Misc.MiscConfig.NetworkVendAudioEnabled",
            //   inverted: true
            // },
            {
              type: "checkbox",
              propertyName: "Program.Misc.MiscConfig.KeyPressAudioEnabled"
            }, // {
            //   type: "checkbox",
            //   propertyName: "Program.Misc.MiscConfig.StartModeAudioEnabled"
            // },
            {
              type: "checkbox",
              propertyName: "Program.Cycle.AntiWrinkleAudioEnabled_TUM"
            }, {
              type: "checkbox",
              propertyName: "Program.Cycle.ExtendedTumbleAudioEnabled_TUM"
            }]
          }]
        }]
      };
    })
  });

  _exports.default = _default;
});