define("alliance-business-suite/components/summary/reports/s-value-station", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "alliance-business-suite/utils/currency-format-util", "alliance-business-suite/utils/report-tables-util"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _currencyFormatUtil, _reportTablesUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  let SummaryReportsSValueStationComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, (_class = class SummaryReportsSValueStationComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor3, this);
    }

    get translatedColumns() {
      return [{
        name: 'Name',
        valuePath: 'name',
        width: 218,
        isFixed: 'left'
      }, {
        name: 'amount_deposited',
        valuePath: 'amountDeposited',
        width: 128
      }, {
        name: 'station_id',
        valuePath: 'stationId',
        width: 136
      }, {
        name: 'Date',
        valuePath: 'transactionDate',
        width: 180
      }];
    }

    get rowsWithChildren() {
      let {
        model
      } = this.args;
      let geoBoundaries = model.geoBoundaries;
      let rowData = [];
      geoBoundaries.forEach(geoBoundary => {
        let monthArray = [];
        let nameArray = [];
        let totalAmountDepositedSum = 0;
        let totalAmountDeposited = [];
        rowData.push({
          name: _reportTablesUtil.localizeString.call(this, geoBoundary.name, 'Location', 'location'),
          children: nameArray,
          amountDeposited: totalAmountDeposited
        });
        geoBoundary.rooms.forEach(room => {
          let totalAmountDepositedSum1 = 0;
          let totalAmountDeposited1 = [];
          nameArray.push({
            name: room.name,
            children: monthArray,
            amountDeposited: totalAmountDeposited1
          });
          room.months.forEach(month => {
            let totalAmountDepositedSum2 = 0;
            let totalAmountDeposited2 = [];
            let weekArray = [];
            monthArray.push({
              name: month.name,
              children: weekArray,
              amountDeposited: totalAmountDeposited2
            });
            month.weeks.forEach(week => {
              let totalAmountDepositedSum3 = 0;
              let totalAmountDeposited3 = [];
              let dayArray = [];
              weekArray.push({
                name: week.name,
                children: dayArray,
                amountDeposited: totalAmountDeposited3
              });
              week.days.forEach(day => {
                let totalAmountDepositedSum4 = 0;
                let totalAmountDeposited4 = [];
                let stationArray = [];
                dayArray.push({
                  name: day.name,
                  children: stationArray,
                  amountDeposited: totalAmountDeposited4
                });
                day.valueStation.forEach(station => {
                  let machineVendDArray = [];
                  let transactionDate = "";

                  if (station.transactionDate) {
                    transactionDate = moment(station.transactionDate).format(`${_reportTablesUtil.formatDateTimeString.call(this)}`);
                  }

                  if (station.amountDeposited !== 0) {
                    machineVendDArray.push(station.amountDeposited);
                    totalAmountDepositedSum = (0, _reportTablesUtil.sumArray)(machineVendDArray, totalAmountDepositedSum);
                    totalAmountDepositedSum1 = (0, _reportTablesUtil.sumArray)(machineVendDArray, totalAmountDepositedSum1);
                    totalAmountDepositedSum2 = (0, _reportTablesUtil.sumArray)(machineVendDArray, totalAmountDepositedSum2);
                    totalAmountDepositedSum3 = (0, _reportTablesUtil.sumArray)(machineVendDArray, totalAmountDepositedSum3);
                    totalAmountDepositedSum4 = (0, _reportTablesUtil.sumArray)(machineVendDArray, totalAmountDepositedSum4);
                  }

                  let depositAmount = _currencyFormatUtil.formatCurrencyString.call(this, station.amountDeposited || 0);

                  stationArray.push({
                    name: station.userName,
                    amountDeposited: depositAmount,
                    stationId: station.stationId,
                    transactionDate: transactionDate
                  });
                });
                totalAmountDeposited4.push(_currencyFormatUtil.formatCurrencyString.call(this, totalAmountDepositedSum4 || 0));
              });
              totalAmountDeposited3.push(_currencyFormatUtil.formatCurrencyString.call(this, totalAmountDepositedSum3 || 0));
            });
            totalAmountDeposited2.push(_currencyFormatUtil.formatCurrencyString.call(this, totalAmountDepositedSum2 || 0));
          });
          totalAmountDeposited1.push(_currencyFormatUtil.formatCurrencyString.call(this, totalAmountDepositedSum1 || 0));
        });
        totalAmountDeposited.push(_currencyFormatUtil.formatCurrencyString.call(this, totalAmountDepositedSum || 0));
      });
      return rowData;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SummaryReportsSValueStationComponent;
});