define("alliance-business-suite/templates/components/scrub/brush-history", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gcDm/F0h",
    "block": "{\"symbols\":[\"brushItem\",\"index\",\"item\"],\"statements\":[[10,\"div\"],[14,0,\"brush-history-container\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"scrub-brush-header\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"scrub-brush-header__main\"],[12],[2,\"\\n\\n\"],[2,\"\\n      \"],[10,\"div\"],[14,0,\"scrub-brush-header__details scrub-brush-item\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"scrub-brush-header__title\"],[12],[2,\"\\n          \"],[1,[30,[36,6],[\"recent_history\"],null]],[2,\"\\n          \"],[11,\"button\"],[24,0,\"button button--secondary button--small pull-right\"],[4,[38,2],[[32,0],\"clearItems\"],null],[12],[2,\"\\n            \"],[1,[30,[36,6],[\"components.scrub.brush-history.actions.clear\"],null]],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"div\"],[14,0,\"scrub-brush-header__subtitle\"],[12],[1,[30,[36,6],[\"type_to_search\"],null]],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"scrub-brush-history\"],[12],[2,\"\\n\"],[6,[37,8],[[30,[36,7],[[30,[36,7],[[35,3,[\"history\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,0],[[32,1,[\"data\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,5],[[32,1]],[[\"active\",\"click\"],[[30,[36,4],[[32,2],[35,3,[\"historyIndex\"]]],null],[30,[36,2],[[32,0],[35,1],[32,2]],null]]],[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[32,3,[\"title\"]]],[6,[37,0],[[32,3,[\"subtitle\"]]],null,[[\"default\"],[{\"statements\":[[2,\": \"],[1,[32,3,[\"subtitle\"]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[3]}]]]],\"parameters\":[]}]]]],\"parameters\":[1,2]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"gotoItem\",\"action\",\"brush\",\"eq\",\"scrub/brush-item\",\"t\",\"-track-array\",\"each\"]}",
    "moduleName": "alliance-business-suite/templates/components/scrub/brush-history.hbs"
  });

  _exports.default = _default;
});