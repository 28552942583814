define("alliance-business-suite/highcharts-configs/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(defaultOptions) {
    defaultOptions.lang = {
      thousandsSep: ',',
      rangeSelectorZoom: 'Zoom:' // rangeSelectorZoom: `${window.appThis.get('intl').t('zoom')}:`

    };
    return defaultOptions;
  }
});