define("alliance-business-suite/components/machines/programs/pricing/acas-wx-flw", ["exports", "alliance-business-suite/mixins/machines/programs/acas-options"], function (_exports, _acasOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_acasOptions.default, {
    machineAuditType: Ember.computed.alias('machineProgram._machineAuditType'),
    optionsDisplaySettings: [{
      val: 0,
      label: '0',
      precision: 0
    }, {
      val: 2,
      label: '0.00',
      precision: 2
    }, {
      val: 3,
      label: '0.000',
      precision: 3
    }]
  });

  _exports.default = _default;
});