define("alliance-business-suite/templates/components/ui/input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WVPfH84g",
    "block": "{\"symbols\":[\"@label\",\"@disabled\",\"@placeholder\",\"@propertyName\",\"@changeset\",\"@type\"],\"statements\":[[10,\"label\"],[12],[2,\"\\n\"],[6,[37,1],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,0],[[32,1]],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[8,\"input\",[[16,\"disabled\",[32,2]],[16,\"placeholder\",[32,3]],[16,4,[32,6]],[4,[38,3],[\"input\",[32,0,[\"validateInput\"]]],null]],[[\"@value\"],[[30,[36,2],[[32,5],[32,4]],null]]],null],[2,\"\\n\\n  \"],[8,\"validation/message\",[],[[\"@changeset\",\"@propertyName\"],[[32,5],[32,4]]],null],[2,\"\\n\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"if\",\"get\",\"on\"]}",
    "moduleName": "alliance-business-suite/templates/components/ui/input.hbs"
  });

  _exports.default = _default;
});