define("alliance-business-suite/components/ui/shopping-cart/cart-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['cart-section'],
    tagName: 'section',
    isCompleted: false,
    actions: {
      setSection(param) {
        if (!this.isDisabled) {
          this.set('isCompleted', 'true');
          this.setSection(param);
        }
      }

    }
  });

  _exports.default = _default;
});