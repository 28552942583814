define("alliance-business-suite/services/phone-input", ["exports", "ember-phone-input/services/phone-input"], function (_exports, _phoneInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _phoneInput.default;
    }
  });
});