define("alliance-business-suite/authenticators/token", ["exports", "alliance-business-suite/config/environment", "ember-simple-auth-token/authenticators/token", "alliance-business-suite/utils/org-setup-util"], function (_exports, _environment, _token, _orgSetupUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * An authenticator for `ember-simple-auth` which uses tokens for authorization.
   *
   * Besides handling initial authentication and authorization, this authenticator
   * handles updating the authorization token when the session variables change so
   * that a new authorization is required (e.g. when switching into a new user
   * role).
   *
   * @public
   */
  var _default = _token.default.extend({
    ajax: Ember.inject.service(),
    access: Ember.inject.service(),
    session: Ember.inject.service(),

    /**
     * Initialize this authenticator.
     *
     * @protected
     */
    // init() {
    //   this._super(...arguments);
    //   console.log('removed invalidationSucceeded for ESA update, needed?')
    //   // this.session.on('invalidationSucceeded', this.invalidate.bind(this));
    // },
    // Authenticates using the `identification`, returning the authenticated data
    // to store in the session if successful or throwing an error if not.
    async authenticate(credentials) {
      let response = await this.makeRequest({
        email: credentials.identification,
        password: credentials.password
      });

      if (!response.payload.data || response.payload.data.id == null) {
        throw new Error('No user data returned from the login endpoint');
      }

      let userBrand, algoliaKey;

      _orgSetupUtil.buildUserOrgs.call(this, response.payload.included, response.payload.data.attributes.isDemoUser);

      _orgSetupUtil.buildUserInfo.call(this, credentials, response.payload);

      _orgSetupUtil.loopIncluded.call(this, credentials, response.payload.included);

      this.session.set('data.hasAcceptedTOS', response.payload.data.attributes.hasAcceptedTos);
      this.session.set('data.authToken', response.response.meta.authToken);
      this.session.set('data.appVersion', _environment.default.version);
      this.session.set('data.hasPassedOrgSelect', false);
      this.session.set('data.isDemoUser', response.payload.data.attributes.isDemoUser); // demo-mode

      return {
        token: response.jqXHR.getResponseHeader('alliancels-auth-token'),
        userId: response.payload.data.id
      };
    },

    makeRequest(data) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      // `serverTokenEndpoint` and `headers` are injected via the configuration
      let url = options.url || this.serverTokenEndpoint;
      let requestOptions = Object.assign({
        data: data != null ? JSON.stringify(data) : undefined,
        method: 'POST',
        headers: Object.assign({
          'Content-Type': 'application/json',
          'app': _environment.default.APP.APP_IDENTIFIER
        }, this.headers)
      }, options);
      return this.ajax.raw(url, requestOptions);
    },

    // custom logic run on invalidation/logging out
    invalidate(data) {
      document.body.className += ' hide-all';
      this.session.store.clear();

      for (const [key, value] of Object.entries(this.session.data)) {
        if (key != 'authenticated') {
          delete this.session.data[key];
        }
      }

      return Ember.RSVP.resolve();
    }

  });

  _exports.default = _default;
});