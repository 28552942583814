define("alliance-business-suite/components/machines/programs/variables", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    // Arrays of machineType.id values for different machine size "groups".
    // sizeGroups are arrays of different sizes that may change property values.
    MC10_OPL: {
      smallMachineSize: [242, 243, 244, 245, 246, 247, 248],
      midLargeMachineSize: [255, 256, 257, 258, 259, 260, 261, 262, 263],

      get sizeGroups() {
        return [[...this.smallMachineSize], [...this.midLargeMachineSize]];
      }

    },
    MGD_OPL: {
      sizeGroups: [[40, 42, 46], [43, 44, 45], [30, 31, 32, 33, 34, 35], [36, 37, 38, 39], [41]]
    },
    MC5_EC: {
      sizeGroups: [[270, 281, 271, 272, 273, 277, 276, 278, 279, 280], [274, 275]]
    }
  };
  _exports.default = _default;
});