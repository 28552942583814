define("alliance-business-suite/components/form-ui/select", ["exports", "alliance-business-suite/mixins/form-ui", "alliance-business-suite/helpers/find-by-dropdown-with-custom", "alliance-business-suite/utils/convert-temperature", "jquery"], function (_exports, _formUi, _findByDropdownWithCustom, _convertTemperature, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_formUi.default, {
    tagName: 'div',
    intl: Ember.inject.service(),
    customValue: null,
    showInvalidValueMessage: Ember.computed('params.showInvalidValueMessage', function () {
      return this.params.showInvalidValueMessage !== undefined ? this.params.showInvalidValueMessage : true;
    }),

    //#region Events
    didReceiveAttrs() {
      // was init
      this._super(...arguments);

      if (this.showComponent === false) {
        return;
      }

      let val = this.value;
      let selected; // We must have options to continue.

      try {
        selected = this.options.findBy('val', val) ?? this.options.findBy('isCustom');
      } catch (err) {// We didn't have an Ember object to work with
      }

      if (selected) {
        Ember.set(this, 'selectedOption', selected);
      }
    },

    valueChanged: false,
    //#endregion
    //#region Computed properties
    customPropertyName: Ember.computed.or('params.customPropertyName', 'params.propertyName'),
    isTemperature: Ember.computed.equal('params.customType', 'temperature'),
    isTemperatureRate: Ember.computed.equal('params.customType', 'temperature-rate'),
    isSpeed: Ember.computed.equal('params.customType', 'speed'),
    speedSettings: Ember.computed.alias('params.speedSettings'),
    // TODO: Should this use _machineSettings instead?
    showG: Ember.computed('params.{speedSettings.showG,machineProgram._showG}', function () {
      return this.params?.machineProgram?._showG ?? this.params?.speedSettings?.showG ?? true;
    }),

    serializePresetValue(value) {
      // Serialize to display value
      if (this.isTemperature && this.formSettings.isCelsius) {
        return (0, _convertTemperature.toC)(value);
      }

      return value;
    },

    deserializePresetValue(value) {// Not used
    },

    yieldHash: Ember.computed('value', 'options', 'lastPresetValue', function () {
      /*
      Always yield the current option that is selected, BUT...
      The presetValue is the last valid preset that was selected. isCustom has no presetValue.
      */
      let selectedOption = (0, _findByDropdownWithCustom.findByDropDown)([this.options, "val", this.value]); // This is the actual option selected. Do not mutate!
      // If custom is selected, send presetValue of last preset.

      let myreturn = {
        val: selectedOption.val,
        label: selectedOption.label,
        isCustom: selectedOption.isCustom,
        presetValue: selectedOption.presetValue,
        lastPresetValue: this.lastPresetValue
      };
      return myreturn;
    }),
    isCustomSelected: Ember.computed('value', 'options', function () {
      let selectedOption = (0, _findByDropdownWithCustom.findByDropDown)([this.options, "val", this.value]);
      return selectedOption.isCustom;
    }),
    options: Ember.computed('params.sortLocalized', 'sortedOptions', 'rawOptions', function () {
      if (this.params.sortLocalized === true) {
        return this.sortedOptions;
      } else {
        return this.rawOptions;
      }
    }),
    sortedOptions: Ember.computed.sort('rawOptions', function (a, b) {
      let localLanguage1 = a.label;
      let localLanguage2 = b.label;

      if (this.intl.t(localLanguage1) > this.intl.t(localLanguage2)) {
        return 1;
      } else if (this.intl.t(localLanguage1) < this.intl.t(localLanguage2)) {
        return -1;
      } else {
        return 0;
      }
    }),
    rawOptions: Ember.computed('params.{options,sortLocalized}', 'formSettings.selectOptions', 'propertyName', function () {
      // overrides options CP in mixin
      let options = this.params.options; // What options we're going to display

      let returnOptions;

      if (options && typeof options !== "string") {
        // An object was passed in
        returnOptions = options || {
          val: null,
          label: 'Invalid options'
        };
      } else {
        // Find options and return them.
        if (options) {
          // A string was passed into 'options'
          if (this.formSettings && this.formSettings.selectOptions) {
            returnOptions = Ember.get(this, `formSettings.selectOptions.${options}`) || {
              val: null,
              label: 'Data not found.'
            };
          } else {
            returnOptions = Ember.get(this, `selectOptions.${options}`) || {
              val: null,
              label: 'Data not found.'
            };
          }
        } else {
          // Nothing passed in, so fall back to propertyName
          let propertyName = this.propertyName;
          let key = propertyName.split('.').slice(-1)[0];

          if (this.formSettings && this.formSettings.selectOptions) {
            returnOptions = Ember.get(this, `formSettings.selectOptions.${key}`) || {
              val: null,
              label: 'Data not found.'
            };
          } else {
            returnOptions = Ember.get(this, `selectOptions.${key}`) || {
              val: null,
              label: 'Data not found.'
            };
          }
        }
      } // Make sure the current value is one of the options, and choose the first option if it's not


      if (!this.sharedCustomProperty) {
        let values = returnOptions.map(option => {
          return option.val;
        });

        if (!values.includes(this.value)) {
          if (this.showInvalidValueMessage) {
            Ember.set(this, 'valueChanged', true);
          }

          this.update(this.object, this.propertyName, values[0]); // Set to first option
        }
      }

      return returnOptions;
    }),
    sharedCustomProperty: Ember.computed('params.{hasCustom,customPropertyName,sharedCustomProperty}', function () {
      // If hasCustom is true but we have no customPropertyName, then one property is used
      // for both and sharedCustomProperty is true.
      // If sharedCustomProperty is passed in, that value will replace this function.
      if (this.params.sharedCustomProperty !== undefined) {
        return this.params.sharedCustomProperty;
      } else {
        return this.params.hasCustom && this.params.customPropertyName === undefined;
      }
    }),
    //#endregion
    //#region Actions
    actions: {
      blockBubbles(select, event) {
        if (event.target && event.target.tagName === 'INPUT') {
          event.stopPropagation();
          return false; // This is required to stop the bubbles
        }
      },

      doChange(val) {
        Ember.set(this, 'selectedOption', val);

        if (this.params.onChange) {
          this.params.onChange(val);
        } else {
          // If 'custom' is chosen and sharedCustomProperty is true, we need to set the value to the last preset value.
          let isCustom = val.isCustom;
          let sharedCustomProperty = this.sharedCustomProperty;
          let lastPresetValue = this.yieldHash.lastPresetValue || this.yieldHash.presetValue;
          let value = val.val;

          if (isCustom && sharedCustomProperty) {
            // We need to update the value to be the last presetValue
            // If this is a sharedCustomProperty, the lastPresetValue might be undefined. If so, we'll use the current value.
            let customValue = lastPresetValue ?? value ?? this.params.min ?? 0; // Oh gosh, this is ugly, but needs to be here for backwards-compatibility. :-( G-force presets need to be multipled by 100.

            if (this.isSpeed) {
              customValue *= 100;
            }

            Ember.set(this, 'value', customValue); // TODO: get this to pre-fill the custom value based on preset values
            // } else if (isCustom && !sharedCustomProperty) {
            //   let customValue = lastPresetValue ?? value ?? this.params.min ?? 0;
            //   if (this.isSpeed) {
            //     customValue *= 100;
            //   }
            //   set(this, 'value', value);
            //   set(this.object, this.customPropertyName, customValue);
          } else if (isCustom) {
            Ember.set(this, 'value', value);
          } else {
            // not custom
            Ember.set(this, 'value', value); // Only update if we have a presetValue to use

            if (!Ember.isNone(val.presetValue)) {
              Ember.set(this, 'lastPresetValue', val.presetValue);
            }
          }
        } // Hook for doing things after changing the value


        if (this.params.afterChange) {
          this.params.afterChange(val);
        } // If "custom" was just selected, try to focus on an input. setTimeout required to catch the element in the DOM.


        Ember.run.schedule('afterRender', () => {
          setTimeout(() => {
            (0, _jquery.default)(this.element).find('input').focus();
          }, 100);
        });
      }

    } //#endregion

  });

  _exports.default = _default;
});