define("alliance-business-suite/utils/locale", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.momentLocaleMap = _exports.momentLocaleFormatMap = _exports.default = _exports.browserLanguageMap = void 0;
  _exports.processLocale = processLocale;

  /**
   * Processes a locale, resolving any processing directives. A processing
   * directive is an object containing one of the following properties:
   *
   * alias
   * : A path to another locale key to use for this translation.
   *
   * @param {object} locale An object mapping locale keys to translations or
   *                        processing directives.
   *
   * @return {object<string,string>} An object mapping locale keys to
   *                                 translations
   * @public
   */
  function processLocale(locale) {
    for (let key in locale) {
      let {
        alias
      } = locale[key];

      if (alias) {
        let value = locale[alias];

        if (value == null) {
          value = Ember.get(locale, alias);

          if (value == null) {
            // eslint-disable-next-line no-console
            console.warn(`Could not find the alias '${alias}' for locale key '${key}'`);
          }
        }

        locale[key] = value;
      }
    }

    return locale;
  }

  var _default = {
    processLocale
  };
  _exports.default = _default;
  const momentLocaleMap = {
    'cs-cz': 'cs',
    'de': 'de',
    'en-us': 'en',
    'es': 'es',
    'es-co': 'es-co',
    'fr': 'fr',
    'id': 'id',
    'ita': 'it',
    'jpn': 'ja',
    'ko': 'ko',
    'ms': 'ms-my',
    'nl-nl': 'nl',
    'pt': 'pt',
    'pt-br': 'pt',
    'ru': 'ru',
    'th': 'th',
    'zh-cn': 'zh-cn'
  };
  _exports.momentLocaleMap = momentLocaleMap;
  const momentLocaleFormatMap = {
    'cs-cz': 'LL',
    'de': 'LL',
    'en-us': 'MMM D, YYYY',
    'es': 'LL',
    'es-co': 'LL',
    'fr': 'LL',
    'id': 'LL',
    'ita': 'LL',
    'jpn': 'LL',
    'ko': 'LL',
    'nl-nl': 'LL',
    'pt': 'LL',
    'ru': 'LL',
    'th': 'LL',
    'zh-cn': 'LL'
  };
  _exports.momentLocaleFormatMap = momentLocaleFormatMap;
  const browserLanguageMap = {
    'cs': 'cs-cz',
    'de': 'de',
    'en': 'en-us',
    'es': 'es',
    'fr': 'fr',
    'id': 'id',
    'ita': 'ita',
    'ja': 'jpn',
    'jpn': 'ja',
    'ko': 'ko',
    'nl': 'nl-nl',
    'pt': 'pt',
    'pt-br': 'pt',
    'ru': 'ru',
    'th': 'th',
    'zh': 'zh-cn'
  };
  _exports.browserLanguageMap = browserLanguageMap;
});