define("alliance-business-suite/components/machines/programs/pricing/midas-wx-modal-increase-spin-speed", ["exports", "alliance-business-suite/mixins/init-property-names"], function (_exports, _initPropertyNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_initPropertyNames.default, {
    speedSettings: Ember.computed('showG', 'basketDiameter', 'machineSettings.extract', function () {
      let settings = this.machineSettings.extract;
      Ember.set(settings, 'basketDiameter', this.basketDiameter);
      Ember.set(settings, 'showG', this.showG);
      return settings;
    }),
    optionList: "SpinSpeedExtract",
    basketDiameter: Ember.computed.alias('machineSettings.basketDiameter'),
    selectedSpeed: Ember.computed('object.Speed', {
      get() {
        return this.object.Speed;
      },

      set(key, value) {
        return value;
      }

    }),
    showG: Ember.computed('basketDiameter', 'formSettings.showG', function () {
      // Without basket size, show G
      if (this.basketDiameter == 0) {
        return true;
      }

      return Ember.get(this, 'formSettings.showG');
    }),
    showPresets: Ember.computed('basketDiameter', function () {
      // If basketDiameter is 0, no presets
      return this.basketDiameter !== 0;
    })
  });

  _exports.default = _default;
});