define("alliance-business-suite/components/rewards/rewards-list-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    classNames: ['inventory-list-table'],
    tagName: '',
    showComponentFooter: false,
    showColumnsDropdown: false,
    useFilteringByColumns: false,
    showGlobalFilter: false,
    useNumericPagination: false,
    doFilteringByHiddenColumns: false,
    filteringIgnoreCase: false,
    multipleColumnsSorting: false,
    showPageSize: false,
    collapseNumPaginationForPagesCount: 1,
    pageSize: 100,
    columns: Ember.computed(function () {
      return [{
        propertyName: 'name',
        title: this.intl.t('name')
      }, {
        propertyName: 'hasDepositRewards',
        title: this.intl.t('deposit_rewards'),
        component: 'table/table-cell-boolean-checklist'
      }, {
        propertyName: 'hasScheduleDays',
        title: this.intl.t('timed_rewards'),
        component: 'table/table-cell-boolean-checklist'
      }, {
        propertyName: 'hasProgramMachineTypes',
        title: this.intl.t('machine_types'),
        component: 'table/table-cell-boolean-checklist'
      }, {
        component: 'table/table-cell-button-options',
        action: this.goToManage,
        params: ['record.id'],
        buttonClass: 'button button--secondary button--small',
        buttonContent: '<i class="fa fa-cog" aria-hidden="true"></i>',
        disableSorting: true
      }];
    }),

    goToManage(rewardId) {
      this.router.transitionTo('locations.rewards.manage', rewardId);
    }

  });

  _exports.default = _default;
});