define("alliance-business-suite/components/summary/reports/s-audit-average-vend", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "alliance-business-suite/utils/currency-format-util", "alliance-business-suite/utils/report-tables-util"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _currencyFormatUtil, _reportTablesUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  let SummaryReportsSAuditAverageVendComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, (_class = class SummaryReportsSAuditAverageVendComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "brand", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor4, this);
    }

    get translatedColumns() {
      return [{
        name: 'location_machine',
        valuePath: 'name',
        width: 215,
        isFixed: 'left'
      }, {
        name: 'date_range',
        valuePath: 'dateRange',
        width: 190
      }, {
        name: 'total_cycles',
        valuePath: 'totalNumberOfMachineCycles',
        width: 125
      }, {
        name: 'total_vended',
        valuePath: 'calcTotalVend',
        width: 125
      }, {
        name: 'vended_per_day',
        valuePath: 'vendPerDay',
        width: 125
      }];
    }

    get rowsWithChildren() {
      let {
        model
      } = this.args;
      let geoBoundaries = model.geoBoundaries;
      let rowData = [];
      geoBoundaries.forEach(geoBoundary => {
        let rowArray = [];
        let nameArray = [];
        let cycleSum = 0;
        let medModSum = 0;
        let heavModSum = 0;
        let totalRoomCycles = [];
        let totalMedMod = [];
        let totalheavMod = [];
        rowData.push({
          name: _reportTablesUtil.localizeString.call(this, geoBoundary.name, 'Location', 'location'),
          children: nameArray,
          totalNumberOfMachineCycles: totalRoomCycles,
          calcTotalVend: totalMedMod,
          vendPerDay: totalheavMod
        });
        geoBoundary.rooms.forEach(room => {
          let cycleSum1 = 0;
          let medModSum1 = 0;
          let heavModSum1 = 0;
          let totalRoomCycles1 = [];
          let totalMedMod1 = [];
          let totalheavMod1 = [];
          nameArray.push({
            name: room.name,
            children: rowArray,
            totalNumberOfMachineCycles: totalRoomCycles1,
            calcTotalVend: totalMedMod1,
            vendPerDay: totalheavMod1
          });
          room.machineAuditGroupTypes.forEach(machineAuditType => {
            machineAuditType.machineTypes.forEach(machineType => {
              let machineArray = [];
              let machineTypeName = `${machineAuditType.name} - ${machineType.name}`;
              let cycleSum2 = 0;
              let medModSum2 = 0;
              let heavModSum2 = 0;
              let totalRoomCycles2 = [];
              let totalMedMod2 = [];
              let totalheavMod2 = [];
              rowArray.push({
                name: machineTypeName,
                machineTypeName: machineType.name,
                children: machineArray,
                totalNumberOfMachineCycles: totalRoomCycles2,
                calcTotalVend: totalMedMod2,
                vendPerDay: totalheavMod2
              });
              machineType.machines.forEach(machine => {
                let machineCycleArray = [];
                let machineVendArray = [];
                let machineMediumArray = [];

                if (machine.totalNumberOfMachineCycles !== 0) {
                  machineCycleArray.push(machine.totalNumberOfMachineCycles);
                  cycleSum = (0, _reportTablesUtil.sumArray)(machineCycleArray, cycleSum);
                  cycleSum1 = (0, _reportTablesUtil.sumArray)(machineCycleArray, cycleSum1);
                  cycleSum2 = (0, _reportTablesUtil.sumArray)(machineCycleArray, cycleSum2);
                }

                if (machine.vendPerDay !== 0) {
                  machineVendArray.push(Math.round(machine.vendPerDay));
                  medModSum = (0, _reportTablesUtil.sumArray)(machineVendArray, medModSum);
                  medModSum1 = (0, _reportTablesUtil.sumArray)(machineVendArray, medModSum1);
                  medModSum2 = (0, _reportTablesUtil.sumArray)(machineVendArray, medModSum2);
                }

                if (machine.calcTotalVend !== 0) {
                  machineMediumArray.push(machine.calcTotalVend);
                  heavModSum = (0, _reportTablesUtil.sumArray)(machineMediumArray, heavModSum);
                  heavModSum1 = (0, _reportTablesUtil.sumArray)(machineMediumArray, heavModSum1);
                  heavModSum2 = (0, _reportTablesUtil.sumArray)(machineMediumArray, heavModSum2);
                }

                let machineName = _reportTablesUtil.machineNameDisplay.call(this, machine.machineName, machine.machineNumber);

                let totalCycles = machine.totalNumberOfMachineCycles || 0;

                let vendedPerDay = _currencyFormatUtil.formatCurrencyString.call(this, Math.round(machine.vendPerDay) || 0);

                let totalVend = _currencyFormatUtil.formatCurrencyString.call(this, machine.calcTotalVend || 0);

                let dateRange = "";

                if (this.brand.slug == 'primus') {
                  // fix the branding issue alliance-tasks/issues/2291
                  rowArray[rowArray.length - 1].name = `${machine.machineAuditType.name} - ${rowArray[rowArray.length - 1].machineTypeName}`;
                }

                if (machine.minFirstReceivedAt && machine.maxFirstReceivedAt) {
                  dateRange = `${moment(machine.minFirstReceivedAt).format(_reportTablesUtil.formatDateTimeString.call(this))} - ${moment(machine.maxFirstReceivedAt).format(_reportTablesUtil.formatDateTimeString.call(this))}`;
                }

                machineArray.push({
                  name: machineName,
                  dateRange: dateRange,
                  totalNumberOfMachineCycles: totalCycles,
                  calcTotalVend: totalVend,
                  vendPerDay: vendedPerDay
                });
              });
              totalRoomCycles2.push(cycleSum2 || 0);
              totalMedMod2.push(_currencyFormatUtil.formatCurrencyString.call(this, heavModSum2 || 0));
              totalheavMod2.push(_currencyFormatUtil.formatCurrencyString.call(this, medModSum2 || 0));
            });
          });
          totalRoomCycles1.push(cycleSum1 || 0);
          totalMedMod1.push(_currencyFormatUtil.formatCurrencyString.call(this, heavModSum1 || 0));
          totalheavMod1.push(_currencyFormatUtil.formatCurrencyString.call(this, medModSum1 || 0));
        });
        totalRoomCycles.push(cycleSum || 0);
        totalMedMod.push(_currencyFormatUtil.formatCurrencyString.call(this, heavModSum || 0));
        totalheavMod.push(_currencyFormatUtil.formatCurrencyString.call(this, medModSum || 0));
      });
      return rowData;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "brand", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SummaryReportsSAuditAverageVendComponent;
});