define("alliance-business-suite/utils/demo-mode", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fudgeReports = fudgeReports;
  _exports.updateGeoNames = updateGeoNames;
  _exports.updateOrganizationName = updateOrganizationName;
  _exports.updateRoomNames = updateRoomNames;
  const ROOM_NAMES = ['Arthur Blvd', 'Bertha Ave', 'Critobal St', 'Dolly Blvd', 'Lancaster Ave', 'Fay St', 'Gonzalo Blvd', 'Hanna Ave'];
  const MAP_COORDINATES = [{
    lat: '34.0154341',
    lng: '-118.4975649'
  }, {
    lat: '30.3279383',
    lng: '-98.8884499'
  }, {
    lat: '29.9670893',
    lng: '-90.146264'
  }, {
    lat: '25.7669682',
    lng: '-80.1934346'
  }, {
    lat: '36.0595046',
    lng: '-86.8674748'
  }, {
    lat: '33.8857354',
    lng: '-84.4745805'
  }, {
    lat: '43.8495743',
    lng: '-88.8324799'
  }, {
    lat: '43.8495743',
    lng: '-88.8324799'
  }];

  function updateOrganizationName() {
    const org = this.store.peekRecord('organization', this.access.get('accessOrganization.id'));
    org.set('organizationName', 'ALS Demo Mode Org');
  }

  function updateRoomNames() {
    const rooms = this.store.peekAll('room');
    rooms.forEach((r, idx) => {
      r.set('roomName', `Demo ${ROOM_NAMES[idx]}`);
      r.set('breadcrumbDisplay', `Speed Queen Account/Speed Queen Laundry - ${ROOM_NAMES[idx]} Location/Demo ${ROOM_NAMES[idx]}`);
      r.set('geomName', '221 Shephard St, Ripon, WI 54971');
      r.set('lat', MAP_COORDINATES[idx].lat);
      r.set('lng', MAP_COORDINATES[idx].lng);
    });
  }

  function updateGeoNames() {
    const geos = this.store.peekAll('geo-boundary');
    geos.forEach((g, idx) => {
      const desc = g.description;
      const breadcrumbDisplay = g.breadcrumbDisplay;

      if (desc === 'Speed Queen Little York' || 'Speed Queen Laundry - Little York') {
        g.set('description', 'Demo Lancaster Ave');
      }

      if (desc === 'Speed Queen Laundry - Bellfort Location') {
        g.set('description', 'Demo Authur Blvd');
      }

      if (desc === 'Speed Queen Laundry - Candler Location') {
        g.set('description', 'Demo Critobal St');
      }

      if (desc === 'Speed Queen Laundry - Dalton Location') {
        g.set('description', 'Demo Dolly Blvd');
      }

      if (desc === 'Speed Queen Laundry - Spencer Location') {
        g.set('description', 'Demo Bertha Ave');
      }

      if (desc === 'Speed Queen Laundry - Varsity Village Location') {
        g.set('description', 'Demo Gonzalo Ave');
      }

      if (desc === 'Speed Queen Laundry Headland - Location') {
        g.set('description', 'Demo Fay St');
      }

      if (breadcrumbDisplay === 'Speed Queen Account/Speed Queen Little York/Speed Queen Laundry - Little York/Speed Queen Laundry - Little York') {
        g.set('breadcrumbDisplay', 'Speed Queen Account/Speed Queen Lancaster/Speed Queen Laundry - Lancaster/Speed Queen Laundry - Lancaster');
      }
    });
  }

  function fudgeReports(payload) {
    let stringPayload = JSON.stringify(payload);
    stringPayload = stringPayload.replaceAll('Bellfort', 'Bertha Ave');
    stringPayload = stringPayload.replaceAll('Little York', 'Demo Lancaster Ave');
    stringPayload = stringPayload.replaceAll('Speed Queen Laundry - Little York', 'Demo Lancaster Ave');
    stringPayload = stringPayload.replaceAll('Belfort', 'Bertha Ave');
    stringPayload = stringPayload.replaceAll('Candler', 'Cristobal St');
    stringPayload = stringPayload.replaceAll('Dalton', 'Dolly Blvd');
    stringPayload = stringPayload.replaceAll('Headland', 'Fay St');
    stringPayload = stringPayload.replaceAll('Spencer', 'Gonzalo Blvd');
    stringPayload = stringPayload.replaceAll('Varsity Village', 'Hanna Ave');
    return JSON.parse(stringPayload);
  }
});