define("alliance-business-suite/components/form-ui/checkbox", ["exports", "alliance-business-suite/mixins/form-ui", "alliance-business-suite/utils/bits"], function (_exports, _formUi, _bits) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const checkboxField = Ember.Component.extend(_formUi.default, {
    noClass: false,

    init() {
      this._super(...arguments);

      if (this.params.classNames === null) {
        Ember.set(this, 'noClass', true);
      }

      Ember.set(this, 'dataTypeDetected', Ember.typeOf(Ember.get(this, `object.${this.propertyName}`)));
    },

    dataType: Ember.computed('params.dataType', function () {
      return this.params.dataType || this.dataTypeDetected || 'boolean';
    }),
    isBitField: Ember.computed('params.bitIndex', 'propertyName', function () {
      /*
        We know this is a bitfield if the following are true:
        1) The bitIndex param is set.
        2) The propertyName value is a number (not boolean).
      */
      let isNumber = Ember.typeOf(Ember.get(this, `object.${this.propertyName}`)) == 'number';
      let hasBitIndex = this.params.bitIndex !== undefined;
      return isNumber && hasBitIndex;
    }),

    serializeValue(rawValue) {
      // Convert the raw value from the API to something the UI displays
      if (this.isBitField) {
        Ember.set(this, "_rawValue", rawValue); // rawValue = decimalBitIndexBoolean(rawValue, this.params.bitIndex)

        rawValue = _bits.default.get(rawValue, this.params.bitIndex);
      } // Invert, if needed. FE needs boolean values.


      return this.params.invert ? !rawValue : !!rawValue;
    },

    deserializeValue(value) {
      // Make the value what the API expects to receive
      // First see if we need to invert this value
      let val = this.params.invert ? !value : value; // Special handling for bit fields

      if (this.isBitField) {
        value = value == true ? 1 : 0;
        return _bits.default.set(this.rawValue, this.params.bitIndex, value); // value = updateBitField(this._rawValue, this.params.bitIndex, value)
      } // Next, convert the data type if necessary


      switch (this.dataType) {
        case 'integer':
        case 'number':
          return val ? 1 : 0;

        default:
          return val;
      }
    },

    value: Ember.computed('rawValue', function () {
      // We need to update the values if there is a change to divideBy decimal placement.
      let rawValue = this.rawValue;
      let serializeValue = this.serializeValue(rawValue);
      return serializeValue;
    }),
    actions: {
      checkboxClicked(e) {
        let value = e.target.checked;
        this.update(this.object, this.propertyName, this.deserializeValue(value), e);
      }

    }
  });
  var _default = checkboxField;
  _exports.default = _default;
});