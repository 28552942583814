define("alliance-business-suite/utils/iso-date", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isoDate = isoDate;

  function isoDate(dateParts, opts) {
    // Send in an object with optional year, month, date, hours, minutes, seconds
    // Returns ISO-8601 datetime string, defaulting to 1900-01-01T12:00:00.
    let {
      year = 1900,
      month = 0,
      date = 1,
      hours = 0,
      minutes = 0,
      seconds = 0,
      startMonthValue = 0,
      addHours = 0
    } = dateParts;
    let isoDate = new _moment.default({
      year,
      month,
      date,
      hours,
      minutes,
      seconds
    });
    isoDate.add(addHours, 'hours');
    return isoDate.format(_moment.default.HTML5_FMT.DATETIME_LOCAL_SECONDS);
  }
});