define("alliance-business-suite/templates/components/form-ui/steps/water-level-overflow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "K0rM+3yp",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[8,\"form-ui\",[],[[\"@object\",\"@formSettings\",\"@selectOptions\"],[[32,0,[\"object\"]],[32,0,[\"formSettings\"]],[32,0,[\"selectOptions\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[10,\"h2\"],[12],[1,[30,[36,2],[\"water_level\"],null]],[13],[2,\"\\n\\n  \"],[8,\"form-ui/dev-tools\",[],[[\"@outputs\"],[[30,[36,0],null,[[\"WaterLevel\",\"CustomWaterLevel\",\"hasCustom\"],[[32,0,[\"WaterLevel\"]],[32,0,[\"CustomWaterLevel\"]],[32,0,[\"hasCustom\"]]]]]]],null],[2,\"\\n\\n\"],[6,[37,3],[[32,0,[\"hasCustomProperty\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,[32,1,[\"select\"]],[],[[\"@params\"],[[30,[36,0],null,[[\"propertyName\",\"label\",\"options\",\"hasCustom\",\"showPresets\",\"min\",\"max\",\"sharedCustomProperty\",\"customPropertyName\",\"afterChange\"],[[32,0,[\"properties\",\"WaterLevel\",\"key\"]],\"WaterLevel\",\"WaterLevelOverflow\",true,true,1,30,false,[32,0,[\"properties\",\"CustomWaterLevel\",\"key\"]],[30,[36,1],[[32,0],\"updateCustomProperty\"],null]]]]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,[32,1,[\"select\"]],[],[[\"@params\"],[[30,[36,0],null,[[\"propertyName\",\"label\",\"options\",\"hasCustom\",\"sharedCustomProperty\",\"showPresets\",\"min\",\"max\"],[[32,0,[\"properties\",\"WaterLevel\",\"key\"]],\"WaterLevel\",\"WaterLevelOverflow\",true,true,true,1,30]]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"hash\",\"action\",\"t\",\"if\"]}",
    "moduleName": "alliance-business-suite/templates/components/form-ui/steps/water-level-overflow.hbs"
  });

  _exports.default = _default;
});