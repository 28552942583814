define("alliance-business-suite/components/wash-alert/wa-canvas", ["exports", "jquery", "fabric", "alliance-business-suite/config/environment"], function (_exports, _jquery, _fabric, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function getMachineTypeAbbrev(machineType) {
    let id = Number(machineType.get('id')); // First we try to find the type based on the machine type id
    // Taken from machine-types model

    const dryerIds = [20, 21, 22, 23, 24];
    const dryerStackIds = [22, 23, 24];
    const tumblerIds = [30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51];
    const tumblerStackIds = [33, 34, 35, 37, 38, 39, 51, 52, 53];
    const extractorIds = [100, 101, 102, 103, 104, 105, 106, 107, 108, 120, 121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 151, 152, 242, 243, 244, 245, 246, 247, 248, // MC10 machines
    255, 256, 257, 258, 259, 260, 261, 262, 263 // Mid/Large MC10 OPL machines
    ];
    const toploadIds = [0];
    const frontloadIds = [10];

    if (dryerStackIds.includes(id)) {
      return 'dry-stack';
    }

    if (dryerIds.includes(id)) {
      return 'dry';
    }

    if (tumblerStackIds.includes(id)) {
      return 'tum-stack';
    }

    if (tumblerIds.includes(id)) {
      return 'tum';
    }

    if (extractorIds.includes(id)) {
      return 'wx';
    }

    if (toploadIds.includes(id)) {
      return 'tlw';
    }

    if (frontloadIds.includes(id)) {
      return 'flw';
    } // If the ID is not found, we try to find the type based on the machine typeName


    const typeName = (machineType.get('typeName') || "").toLowerCase();

    if (typeName.includes('dryer')) {
      return 'dry';
    }

    if (typeName.includes('tumbler')) {
      return 'tum';
    }

    if (typeName.includes('extractor')) {
      return 'wx';
    }

    if (typeName.includes('frontload')) {
      return 'flw';
    }

    if (typeName.includes('topload')) {
      return 'tlw';
    } // And finally we'll fall back to isDryer/isWasher


    if (machineType.get('isDryer')) {
      return 'tum';
    }

    if (machineType.get('isWasher')) {
      return 'wx';
    } // And if none of that works...


    console.warn('Could not find machine type abbreviation for a machine');
    return 'unknown';
  }

  function hasWindow(modelNumber) {
    // Dryer has a window if the 8th character of model is W
    return modelNumber && modelNumber.length > 7 && modelNumber[7] === 'W' ? true : false;
  }

  function hasRearControls(modelNumber) {
    // FLW and DRY have rear controls if the 6th character of model is R
    return modelNumber && modelNumber.length > 5 && modelNumber[5] === 'R' ? true : false;
  }

  var _default = Ember.Component.extend({
    classNames: ['wash-alert-container'],
    classNameBindings: ['activeRoomHasWashAlert::wash-alert-disabled'],
    access: Ember.inject.service(),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    notifications: Ember.inject.service(),
    intl: Ember.inject.service(),
    canvas: null,
    grid: 20,
    gridWidth: 20,
    gridHeight: 20,
    gridRows: 40,
    gridColumns: 40,
    canvasSize: [800, 800],
    machinesOnGrid: [],
    backgroundObj: null,
    tempComboResolution: [],
    bgColor: null,
    snapping: true,
    theme: 'default',
    themeConfig: {},
    uploadImages: [],
    s3ImageUrl: "",
    isShowingNewItemTooltip: false,
    isBackgroundEditable: true,
    hasBackgroundImage: false,
    showBackgroundUpload: false,
    isLoading: false,
    isSaving: false,
    isModified: false,
    hasGrid: false,
    maxImageCount: 5,
    imageCount: 0,
    maxImageSize: 1048576,
    // 1MB
    // has the first render of the layout with all machine been done
    hasLoadedInitialBoard: false,
    selectedItem: null,
    backgroundHtmlAttribute: Ember.computed('themeConfig.background', function () {
      let cssBackground = this?.themeConfig.background;

      if (cssBackground) {
        return `background: ${cssBackground}`;
      } else {
        return '';
      }
    }),
    // dev only
    machineTypeIcon: null,
    comboFilterBy: null,
    fonts: ['Arial', 'Verdana', 'Georgia', 'Courier', 'Comic Sans MS', 'Impact'],
    backgroundControls: {
      bl: false,
      mt: false,
      tl: false,
      tr: false
    },
    imageControls: {
      bl: true,
      br: true,
      mt: true,
      mb: true,
      ml: true,
      mr: true,
      tl: true,
      tr: true,
      mtr: true
    },
    textControls: {
      bl: false,
      mt: false,
      tl: false,
      tr: false
    },
    machineControls: {
      bl: false,
      mt: false,
      mb: false,
      ml: false,
      mr: false,
      tl: false,
      tr: false
    },
    groupControls: {
      bl: false,
      br: false,
      mt: false,
      mb: false,
      ml: false,
      mr: false,
      tl: false,
      tr: false,
      mtr: false
    },
    machineToSize: {
      '20lbs': {
        size: 'small',
        scaleX: 2,
        scaleY: 2
      },
      '20 lbs': {
        size: 'small',
        scaleX: 2,
        scaleY: 2
      },
      '25lbs': {
        size: 'small',
        scaleX: 2,
        scaleY: 2
      },
      '25 lbs': {
        size: 'small',
        scaleX: 2,
        scaleY: 2
      },
      '30lbs': {
        size: 'medium',
        scaleX: 3,
        scaleY: 3
      },
      '30 lbs': {
        size: 'medium',
        scaleX: 3,
        scaleY: 3
      },
      '40lbs': {
        size: 'medium',
        scaleX: 3,
        scaleY: 3
      },
      '40 lbs': {
        size: 'medium',
        scaleX: 3,
        scaleY: 3
      },
      '45lbs': {
        size: 'medium',
        scaleX: 3,
        scaleY: 3
      },
      '45 lbs': {
        size: 'medium',
        scaleX: 3,
        scaleY: 3
      },
      '60lbs': {
        size: 'large',
        scaleX: 4,
        scaleY: 4
      },
      '60 lbs': {
        size: 'large',
        scaleX: 4,
        scaleY: 4
      },
      '75lbs': {
        size: 'large',
        scaleX: 4,
        scaleY: 4
      },
      '75 lbs': {
        size: 'large',
        scaleX: 4,
        scaleY: 4
      },
      '80lbs': {
        size: 'large',
        scaleX: 4,
        scaleY: 4
      },
      '80 lbs': {
        size: 'large',
        scaleX: 4,
        scaleY: 4
      }
    },
    canAddImage: Ember.computed('imageCount', 'maxImageCount', function () {
      return this.imageCount < this.maxImageCount;
    }),
    // sort and filter machines
    filteredMachines: Ember.computed('model', 'filterByMachineNumber', function () {
      let model = this.model;
      let keyword = this.filterByMachineNumber;
      let data = model;

      if (keyword) {
        let results = [];
        model.forEach(machine => {
          let str = machine.get('machineNumber');

          if (str.indexOf(keyword) >= 0) {
            results.push(machine);
          }
        });
        data = results;
      }

      return data.sort((a, b) => {
        return a.get('machineNumber') - b.get('machineNumber');
      });
    }),

    didInsertElement() {
      this._super(...arguments); // creates the fabric js canvas


      let canvas = new _fabric.fabric.Canvas('c', {
        selection: true,
        stateful: true,
        preserveObjectStacking: true,
        imageSmoothingEnabled: true
      });
      canvas.setWidth(this.canvasSize[0]);
      canvas.setHeight(this.canvasSize[1]);
      Ember.set(this, 'canvas', canvas);
      this.snapToGrid();
      this.snapOnScale();
      this.objSelected();
      this.objModified();
      this.mouseEvents();

      if (!this.isDestroyed) {
        Ember.set(this, 'machinesOnGrid', []);
        Ember.set(this, 'isModified', false);
        this.send('load');
      }
    },

    movementKeys: ["left", "shift+left", "right", "shift+right", "up", "shift+up", "down", "shift+down"],
    resizeKeys: ["mod+left", "mod+right", "mod+up", "mod+down", "shift+mod+left", "shift+mod+right", "shift+mod+up", "shift+mod+down"],

    init() {
      var _this2 = this;

      this._super(...arguments);

      Mousetrap.bind(this.movementKeys, function (e, combo) {
        let context = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _this2;
        e.preventDefault();

        _this2.moveItem(context, combo);
      });
      Mousetrap.bind(this.resizeKeys, function (e, combo) {
        let context = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _this2;
        e.preventDefault();

        _this2.resizeItem(context, combo);
      });
    },

    destroy() {
      this._super(...arguments);

      Mousetrap.unbind(this.movementKeys);
      Mousetrap.unbind(this.resizeKeys);
    },

    moveItem(context, direction) {
      if (!context.selectedItem) return;
      let item = context.selectedItem;
      let {
        left,
        top,
        width,
        height
      } = item;
      let pixels = direction.includes('shift') ? 10 : 1;
      direction = direction.split('+').pop();
      let itemWidth = width * item.scaleX;
      let itemHeight = height * item.scaleY;
      let minTopPosition = 0;
      let maxTopPosition = this.canvasSize[1] - itemHeight;
      let minLeftPosition = 0;
      let maxLeftPosition = this.canvasSize[0] - itemWidth; // Position the item within canvas

      switch (direction) {
        case 'left':
          item.left = left - pixels < minLeftPosition ? minLeftPosition : left - pixels;
          break;

        case 'right':
          item.left = left + pixels > maxLeftPosition ? maxLeftPosition : left + pixels;
          break;

        case 'up':
          item.top = top - pixels < minTopPosition ? minTopPosition : top - pixels;
          break;

        case 'down':
          item.top = top + pixels > maxTopPosition ? maxTopPosition : top + pixels;
          break;
      }

      context.canvas.renderAll();
      context.send('hasChanged', true);
    },

    resizeItem(context, direction) {
      // TODO: It's possible to enlarge an item so it moves outside the canvas boundary.
      if (!context.selectedItem) return;
      let item = context.selectedItem;
      let {
        width,
        height
      } = item;
      let currentScale = item.scaleX;
      let scaleWidth = currentScale * width;
      let scaleHeight = currentScale * height;
      let scaleFactor;
      let pixels = direction.includes('shift') ? 10 : 1;
      direction = direction.split('+').pop(); // last part of the string

      switch (direction) {
        case 'left':
          // smaller
          scaleFactor = (scaleWidth - pixels) / scaleWidth;
          break;

        case 'down':
          // smaller
          scaleFactor = (scaleHeight - pixels) / scaleHeight;
          break;

        case 'right':
          // bigger
          scaleFactor = (scaleWidth + pixels) / scaleWidth;
          break;

        case 'up':
          // bigger
          scaleFactor = (scaleHeight + pixels) / scaleHeight;
          break;
      }

      let newScale = currentScale * scaleFactor; // Make sure we stay in the limits

      if (newScale > item.maxScaleX) newScale = item.maxScaleX;
      if (newScale < item.minScaleX) newScale = item.minScaleX;
      item.scaleX = newScale;
      item.scaleY = newScale;
      context.canvas.renderAll();
      context.send('hasChanged', true);
    },

    activeRoomHasWashAlert: Ember.computed('room.{@each,hasWashAlert}', function () {
      return this.get('room.hasWashAlert');
    }),
    // Location changed
    locationChange: Ember.observer('room', function () {
      let canvas = Ember.get(this, 'canvas');
      canvas.clear();
      Ember.set(this, 'machinesOnGrid', []);
      Ember.set(this, 'isModified', false);
      this.send('load');
    }),

    // creates the 10x10 grid we will be using for snapping and size purposes
    createGrid(canvasSize) {
      let grid = this.grid;
      const gridColor = "#ccc";
      let gridArray = [];
      let [canvasWidth, canvasHeight] = canvasSize; // Horizontal lines

      for (let i = 0; i < canvasHeight / grid; i++) {
        const top = i * grid;
        const bottom = i * grid;
        const left = 0;
        const right = canvasWidth;
        gridArray.push(new _fabric.fabric.Line([left, top, right, bottom], {
          isGrid: true,
          stroke: gridColor
        }));
      } // Vertical lines


      for (let i = 0; i < canvasWidth / grid; i++) {
        const top = 0;
        const bottom = canvasHeight;
        const left = i * grid;
        const right = i * grid;
        gridArray.push(new _fabric.fabric.Line([left, top, right, bottom], {
          isGrid: true,
          stroke: gridColor
        }));
      }

      let group = new _fabric.fabric.Group(gridArray);
      group.set({
        id: 'grid',
        isGrid: true,
        selectable: false,
        hoverCursor: 'default'
      });
      this.canvas.add(group);
      group.sendToBack();

      if (!this.isDestroyed) {
        this.set('hasGrid', true);
      } // if has layout, move forward one


      if (this.hasBackgroundImage) {
        group.bringForward();
      }
    },

    createBackground(url, left, top, scaleX, scaleY, angle) {
      let self = this;
      let canvas = Ember.get(this, 'canvas');

      _fabric.fabric.Image.fromURL(url, function (img) {
        let oImg = img.set({
          id: 'layout',
          left,
          top,
          scaleX,
          scaleY,
          angle,
          ignoreOnHover: true,
          selectable: false,
          zIndex: 0
        });
        canvas.add(oImg);
        oImg.sendToBack();
        let rect = new _fabric.fabric.Rect({
          id: 'gridLayer',
          left,
          top,
          scaleX,
          scaleY,
          angle,
          width: oImg.width,
          height: oImg.height,
          fill: 'transparent',
          ignoreOnHover: true,
          zIndex: 0
        });
        canvas.add(rect);
        canvas.sendToBack(rect);
        rect.bringForward();

        if (self.get('hasGrid')) {
          rect.bringForward();
        }

        canvas.renderAll();
        Ember.set(self, 'hasBackgroundImage', true);
        Ember.set(self, 'showBackgroundUpload', false);
        Ember.set(self, 'isBackgroundEditable', true); // set it true because we are always toggling it off

        self.send('toggleLockBackground');
      });
    },

    imagePath(filename) {
      return `${this.s3ImageUrl}/rooms/${filename}`;
    },

    createImage(filename, left, top, scaleX, scaleY, angle, zIndex) {
      let selectable = arguments.length > 7 && arguments[7] !== undefined ? arguments[7] : true;
      let ignoreOnHover = arguments.length > 8 && arguments[8] !== undefined ? arguments[8] : false;
      let canvas = this.canvas;
      let path = this.imagePath(filename);

      _fabric.fabric.Image.fromURL(path, imageObject => {
        let options = {
          id: 'image',
          left,
          top,
          scaleX: scaleX ? scaleX : 1,
          scaleY: scaleY ? scaleY : 1,
          angle,
          ignoreOnHover,
          selectable,
          zIndex,
          filename,
          transparentCorners: false
        };
        imageObject.set(options);
        canvas.add(imageObject);
        canvas.moveTo(imageObject, zIndex);
        Ember.set(this, "imageCount", this.imageCount + 1); // this.sortCanvas();

        Ember.run.debounce(this, this.debouncedSortCanvas, 750);
      });
    },

    createText(t, s, w, x, y, sX, sY, a, f, bc, ff, fw, fs, u) {
      let zIndex = arguments.length > 14 && arguments[14] !== undefined ? arguments[14] : 1000;
      let self = this;
      let canvas = Ember.get(this, 'canvas');
      let gridWidth = Ember.get(this, 'gridWidth');
      let left = x || 0;
      let top = y || 0;
      let scaleX = sX || 3;
      let scaleY = sY || 3;
      let angle = a || 0;
      let text = t || 'text';
      let width = w || null;
      let fill = f || '#000000';
      let backgroundColor = bc || null;
      let fontFamily = ff || 'Arial';
      let fontWeight = fw || '';
      let fontStyle = fs || '';
      let underline = u || '';
      let fontSize = s || 10;
      let limitScale = true; // Create the text canvas object

      text = new _fabric.fabric.Textbox(text, {
        id: 'text',
        limitScale,
        fontSize,
        fontFamily,
        textAlign: 'left',
        fill,
        backgroundColor,
        fontWeight,
        fontStyle,
        underline,
        top,
        left,
        scaleX,
        scaleY,
        angle,
        width,
        statefullCache: true,
        transparentCorners: false,
        padding: 6,
        zIndex
      });
      text.setControlsVisibility(this.textControls); // finally adds the new group to the board

      canvas.add(text);
      canvas.moveTo(text, zIndex); // this.sortCanvas();

      Ember.run.debounce(this, this.debouncedSortCanvas, 750);
    },

    createMachine(machine, machineStatus, x, y, sX, sY, a, z) {
      // Add machine icons to the canvas.
      if (!machine) {
        return false;
      }

      let color;
      let gridWidth = Ember.get(this, 'gridWidth');
      let canvas = Ember.get(this, 'canvas');
      let machineId = machine.get('id');
      let machineType = machine.get('machineType');
      let isDryer = machine.get('machineType.isDryer');
      let isWasher = machine.get('machineType.isWasher');
      let machineTypeName = machine.get('machineType.machineTypeName');
      let machineName = machine.get('machineName');
      let machineNumber = `#${machine.get('machineNumber').replace(/^0+/, '')}`;
      const modelNumber = machine.get('modelNumber');
      let machineIcon = getMachineTypeAbbrev(machineType);
      let svgIcon;

      if (hasRearControls(modelNumber)) {
        machineIcon += "-back";
      }

      if (hasWindow(modelNumber)) {
        machineIcon += "-window";
      } // Machine icons can be unique to the machine audit type


      let machineAuditType = machine.get('machineAuditType.id') || '';

      if (machineAuditType && Object.prototype.hasOwnProperty.call(this.themeConfig, machineAuditType)) {
        // Icons specific to the machine audit type
        svgIcon = `themes/${this.theme}/${this.themeConfig[machineAuditType][machineIcon]}`;
      } else {
        svgIcon = `themes/${this.theme}/${this.themeConfig.icons[machineIcon]}`;
      }

      let left = x == null ? gridWidth * 1 + 1 : x;
      let top = y == null ? gridWidth * 1 + 1 : y;
      let scaleX = sX || 3;
      let scaleY = sY || 3;
      let angle = a || 0;
      let zIndex = z || null;
      let minScaleX = 1;
      let maxScaleX = 8;
      let minScaleY = 1;
      let maxScaleY = 8;
      let limitScale = true;

      if (machine) {
        color = this.statusColor(machineStatus);
      } else {
        color = this.statusColor();
      } // machine size adjustment


      if (!sX || !sY) {
        for (let size in this.machineToSize) {
          let typeName = machine.get('machineType.typeName') || '';

          if (typeName.toLowerCase().indexOf(size) !== -1) {
            scaleX = this.machineToSize[size].scaleX;
            scaleY = this.machineToSize[size].scaleY;
          }
        }
      }

      function getIconText() {
        let iconText = machineNumber.replace(/^0+/, "");
        iconText = machineNumber.replace(/^#/, "");

        if (machineName.length < 8) {
          iconText = machineName;
        }

        return iconText;
      } // adds the icon of machine to the board


      _fabric.fabric.loadSVGFromURL(`/assets/images/wash-alert/${svgIcon}.svg`, (objects, options) => {
        let group;
        let textInSVG = false;

        let obj = _fabric.fabric.util.groupSVGElements(objects, options);

        obj.set({
          left,
          top,
          width: gridWidth,
          height: gridWidth,
          fill: 'rgb(127, 140, 141)',
          originX: 'left',
          originY: 'top',
          centeredRotation: false,
          lockScalingX: false,
          lockScalingY: false,
          lockRotation: true,
          hasControls: true,
          cornerSize: 8,
          hasBorders: false,
          padding: 0,
          scaleX: 1,
          scaleY: 1,
          zIndex
        }); // sets the color of the svg object to the right status color

        function updateColors(obj) {
          if (obj.fill == '#ccc' || obj.id == 'fill-#ccc') {
            obj.set({
              fill: color[0]
            });
          } else if (obj.fill == '#aaa' || obj.id == 'fill-#aaa') {
            obj.set({
              fill: color[1]
            });
          }

          if (obj.stroke == '#ccc' || obj.id == 'stroke-#ccc') {
            obj.set({
              stroke: color[0]
            });
          } else if (obj.stroke == '#aaa' || obj.id == 'stroke-#aaa') {
            obj.set({
              stroke: color[1]
            });
          }
        }

        updateColors(obj); // also check sub shapes to the right status color

        for (let x in obj._objects) {
          updateColors(obj._objects[x]);

          if (obj._objects[x].text == '123' || obj._objects[x].text === '--') {
            // '123' was used in the past, but resembles real data if it gets displayed. Now using '--' as the placeholder.
            textInSVG = true;
            obj._objects[x].text = getIconText();
          }
        }

        if (!textInSVG) {
          // Create the text canvas object for machine ID
          textInSVG = true; // Text positioning is stored in the theme.json file

          const textConfig = this.themeConfig.text;
          const textAlign = textConfig?.["text-align"] || "center";
          const fontFamily = textConfig?.["font-family"] || "Arial";
          const shadow = textConfig?.["shadow"] || "rgba(0,0,0,1) 0px 0px 1px";
          const fill = textConfig?.["fill"] || "white";
          const fontSize = textConfig?.["font-size"] || 3;
          let text = new _fabric.fabric.Textbox(getIconText(), {
            // backgroundColor: "red", // To see the text position in the group
            fontSize,
            textAlign,
            fill,
            fontFamily,
            shadow
          });
          const verticalAlign = textConfig?.["vertical-align"] || "top"; // Padding values are used to move the text down/up from the top/bottom borders of the icon

          const paddingTop = textConfig?.["padding-top"] || 0;
          const paddingBottom = textConfig?.["padding-bottom"] || 0; // Padding values are used to reduce the text width to center it on 3-D icons

          const paddingRight = textConfig?.["padding-right"] || 0;
          const paddingLeft = textConfig?.["padding-left"] || 0; // Not usually used

          let textTop, textLeft;

          if (verticalAlign === "top") {
            textTop = top + paddingTop;
          } else {
            textTop = top + gridWidth - paddingBottom;
          } // If the left is adjusted, we also need to reduce the width or the group (text + icon) will be offset incorrectly


          let textWidth = gridWidth - paddingRight - paddingLeft;
          text.set({
            top: textTop,
            left,
            width: textWidth,
            scaleX: 1,
            scaleY: 1
          }); // group the object and the text together
          // the order of operations matters

          group = new _fabric.fabric.Group([obj, text]);
        } else {
          group = new _fabric.fabric.Group([obj]);
        } // set the properties of the new group


        group.set({
          id: 'machine',
          lockRotation: false,
          lockScalingFlip: true,
          isMachineGroup: true,
          isNew: x == null ? true : false,
          hasMoved: false,
          width: gridWidth,
          height: gridWidth,
          machineStatus,
          machineTypeName,
          machineName,
          machineType,
          machineNumber,
          machineId,
          isDryer,
          isWasher,
          color,
          angle,
          left,
          top,
          scaleX: 1,
          scaleY: 1,
          minScaleX,
          minScaleY,
          maxScaleX,
          maxScaleY,
          limitScale,
          transparentCorners: false,
          padding: 8,
          zIndex
        });
        let scaleWidth = Math.round(scaleX) * gridWidth - 1;
        let scaleHeight = Math.round(scaleX) * gridWidth - 1;
        group.scaleToWidth(scaleWidth, true);
        group.scaleToHeight(scaleHeight, true);
        group.setControlsVisibility(this.machineControls); // finally adds the new group to the board

        canvas.add(group);
        canvas.moveTo(group, zIndex); // make sure added machines don't overlap

        if (x == null) {
          let clearPosition = false;

          while (!clearPosition) {
            clearPosition = true;
            canvas.getObjects().forEach(obj => {
              if (obj.id == 'machine') {
                if (obj === group) return;

                while (group.intersectsWithObject(obj, true, true)) {
                  if (group.top + gridWidth * group.scaleY > canvas.height) {
                    group.set({
                      top: gridWidth * 1 + 1,
                      left: group.left + gridWidth
                    });
                  } else {
                    group.set({
                      top: group.top + gridWidth
                    });
                  }

                  clearPosition = false;
                }
              }
            });
          }

          group.setCoords();
        }

        if (!zIndex) {
          group.bringToFront();
        }

        Ember.run.debounce(this, this.debouncedSortCanvas, 750);
      });
    },

    debouncedSortCanvas() {
      this.sortCanvas();
    },

    sortCanvas() {
      let canvas = this.canvas;
      let objectsToSort = []; // Only sorting objects with zIndex

      canvas.forEachObject(obj => {
        if (obj.zIndex) {
          objectsToSort.push(obj);
        }
      }); // Doing the sort

      objectsToSort.sort((a, b) => {
        if (a.zIndex < b.zIndex) {
          return -1;
        } else if (a.zIndex > b.zIndex) {
          return 1; // } else if (a.zIndex === b.zIndex) {
          //   console.log('ID')
          //   if (b.machineId > a.machineId) {
          //     console.log('!1')
          //     return 1
          //   } else {
          //     console.log('!2')
          //     return -1
          //   }
        }

        return 0;
      }); // Setting the new zIndex

      objectsToSort.forEach((obj, index) => {
        obj.moveTo(obj.zIndex);
      });
      canvas.requestRenderAll();
    },

    snapToGrid() {
      let canvas = this.canvas;
      let grid = this.grid;
      let self = this;
      const snappingItems = ['machine', 'image', 'text']; // all objects (minus the layout) will be subject to the grid snapping when moved

      canvas.on('object:moving', function (options) {
        const snap = Ember.get(self, 'snapping');
        let selectedItem = options.target ?? options.selected[0];

        if (snappingItems.includes(selectedItem.id)) {
          if (snap) {
            const gridLeftPosition = parseInt(Math.round(selectedItem.left / grid) * grid);
            const gridTopPosition = parseInt(Math.round(selectedItem.top / grid) * grid);
            let left, top;

            if (selectedItem.angle >= 270) {
              left = gridLeftPosition + 1;
              top = gridTopPosition;
            } else if (selectedItem.angle >= 180) {
              left = gridLeftPosition;
              top = gridTopPosition;
            } else if (selectedItem.angle > 0) {
              left = gridLeftPosition;
              top = gridTopPosition + 1;
            } else {
              left = gridLeftPosition + 1;
              top = gridTopPosition + 1;
            }

            left = parseInt(left.toFixed(0));
            top = parseInt(top.toFixed(0));
            selectedItem.set({
              left,
              top,
              hasMoved: true
            });
          }
        } else if (selectedItem.id === 'gridLayer') {
          // TODO: What does this do?
          canvas.forEachObject(obj => {
            if (obj && obj.id === 'layout') {
              obj.left = selectedItem.left;
              obj.top = selectedItem.top;
              obj.setCoords();
            }
          });
        } else if (selectedItem.id != 'layout') {
          selectedItem.set({
            left: parseInt(Math.round(selectedItem.left / grid) * grid),
            top: parseInt(Math.round(selectedItem.top / grid) * grid),
            hasMoved: true
          });
        }

        self.send('hasChanged', true);
      });
      canvas.on('object:rotating', function (options) {
        // rotate in 5* increments
        let angle = parseInt(Math.round(options.target.angle / 5) * 5);

        if (angle > 359 || angle < 0) {
          angle = 0;
        }

        options.target.rotate(angle);

        if (options.target.id === 'gridLayer') {
          canvas.forEachObject(obj => {
            if (obj && obj.id === 'layout') {
              obj.rotate(angle);
            }
          });
        }

        self.send('hasChanged', true);
      });
    },

    // on resize, makes sure the object scales cleanly on the grid
    // this does not apply to the "layout image"
    snapOnScale() {
      let canvas = Ember.get(this, 'canvas');
      let self = this;
      canvas.on('object:scaling', function (options) {
        let snap = Ember.get(self, 'snapping');
        let {
          limitScale,
          scaleX,
          scaleY,
          minScaleX,
          minScaleY,
          maxScaleX,
          maxScaleY,
          width,
          height,
          left,
          top,
          id
        } = options.target;

        if (limitScale) {
          if (minScaleX) {
            scaleX = scaleX > minScaleX ? scaleX : minScaleX;
          }

          if (minScaleY) {
            scaleY = scaleY > minScaleY ? scaleY : minScaleY;
          }

          if (maxScaleX) {
            scaleX = scaleX < maxScaleX ? scaleX : maxScaleX;
          }

          if (maxScaleY) {
            scaleY = scaleY < maxScaleY ? scaleY : maxScaleY;
          }

          if (id === 'machine') {
            if (snap) {
              let scaleWidth = Math.round(scaleX) * width - 1;
              let scaleHeight = Math.round(scaleX) * height - 1;
              options.target.scaleToWidth(scaleWidth, true);
              options.target.scaleToHeight(scaleHeight, true);
              options.target.set({
                left: Math.round(options.target.left),
                top: Math.round(options.target.top)
              });
            }
          } else {
            options.target.set({
              'scaleX': Math.round(scaleX),
              'scaleY': Math.round(scaleY)
            });
          }
        } else if (id === 'gridLayer') {
          canvas.forEachObject(obj => {
            if (obj && obj.id === 'layout') {
              obj.set({
                scaleX,
                scaleY,
                width,
                height,
                left,
                top
              });
            }
          });
        }

        self.send('hasChanged', true);
      });
    },

    // remove the floating ui tooltip created on new machine elements place on board
    // populates the item-info area with data of the selected machine
    objSelected() {
      let canvas = Ember.get(this, 'canvas');
      let self = this;
      canvas.on('selection:created', options => {
        let selectedItem = options.target ?? options.selected[0];

        if (selectedItem.id === 'layout' || selectedItem.id === 'gridLayer') {
          return;
        }

        Ember.set(self, 'editBackground', false);
        let hasSelectedBg = false;
        let selectedObjects = [];
        Ember.set(self, 'selectedItem', selectedItem); // If this is a highlighted group

        if (!selectedItem.id) {
          for (let i = selectedItem._objects.length - 1; i >= 0; i--) {
            if (selectedItem._objects[i].id === 'layout' || selectedItem._objects[i].id === 'gridLayer') {
              hasSelectedBg = true;
            } else {
              selectedObjects.push(selectedItem._objects[i]);
            }
          }

          if (hasSelectedBg && selectedObjects.length) {
            canvas.discardActiveObject();
            let selection = new _fabric.fabric.ActiveSelection(selectedObjects);
            canvas.setActiveObject(selection);
          } else if (hasSelectedBg) {
            canvas.discardActiveObject();
            canvas.requestRenderAll();
            return;
          }

          selectedItem.setControlsVisibility(self.groupControls);
          selectedItem.transparentCorners = false;
          selectedItem.padding = 6;
          canvas.requestRenderAll();
        }
      });
      canvas.on('selection:updated', options => {
        Ember.set(self, 'editBackground', false);
        let selectedItem = options.target ?? options.selected[0];

        if (selectedItem.id === 'layout' || selectedItem.id === 'gridLayer') {
          return;
        }

        let hasSelectedBg = false;
        let selectedObjects = [];
        Ember.set(self, 'selectedItem', selectedItem); // If this is a highlighted group

        if (!selectedItem.id) {
          for (let i = selectedItem._objects.length - 1; i >= 0; i--) {
            const hasBg = ['layout', 'gridLayer'];

            if (hasBg.includes(selectedItem._objects[i].id)) {
              hasSelectedBg = true;
            } else {
              selectedObjects.push(selectedItem._objects[i]);
            }
          }

          if (hasSelectedBg && selectedObjects.length) {
            canvas.discardActiveObject();
            let selection = new _fabric.fabric.ActiveSelection(selectedObjects);
            canvas.setActiveObject(selection);
          } else if (hasSelectedBg) {
            canvas.discardActiveObject();
            canvas.requestRenderAll();
            return;
          }

          selectedItem.setControlsVisibility(self.groupControls);
          selectedItem.transparentCorners = false;
          selectedItem.padding = 6;
          canvas.requestRenderAll();
        }
      });
      canvas.on('selection:cleared', options => {
        Ember.set(self, 'selectedItem', null);
        Ember.set(self, 'editBackground', false);
      });
    },

    mouseEvents() {
      let canvas = Ember.get(this, 'canvas'); // canvas.on('mouse:over', (e) => {
      //   // if there is already an active element, that take precedence over a mouse hover
      //   if (canvas.getActiveObject()) {
      //     return;
      //   }
      //   let obj = e.target;
      //   if (obj && !obj.isGrid && !obj.ignoreOnHover) {
      //     set(this, 'selectedItem', obj);
      //   }
      // });
      // canvas.on('mouse:out', (/* e */) => {
      //   // if there is already an active element, that take precedence over a mouse hover
      //   if (canvas.getActiveObject()) {
      //     return;
      //   }
      //   set(this, 'selectedItem', null);
      // });
    },

    // keep objects within the canvas boundary
    // if the object is outside of it, return the object back to the original position before mouse move
    objModified() {
      let canvas = Ember.get(this, 'canvas');
      let self = this;
      canvas.on('object:modified', e => {
        let obj = e.target;

        if (obj.id === 'layout' || obj.id === 'gridLayer') {
          return;
        }

        let bounds = obj.getBoundingRect(); // height, left, top, width;

        let objLeft = bounds.left;
        let objTop = bounds.top;
        let objRight = bounds.left + bounds.width;
        let objBottom = bounds.top + bounds.height;

        if (objLeft < 0 || objTop < 0 || objRight > canvas.width || objBottom > canvas.height) {
          // Keep objects within the canvas boundary
          if (objTop < 0) {
            obj.top = obj.top - objTop;
          }

          if (objLeft < 0) {
            obj.left = obj.left - objLeft;
          }

          if (objRight > canvas.width) {
            let diff = canvas.width - objRight;
            obj.left = obj.left + diff;
          }

          if (objBottom > canvas.height) {
            let diff = canvas.height - objBottom;
            obj.top = obj.top + diff;
          }

          obj.setCoords();
        }
      });
      canvas.on('text:changed', e => {
        self.send('hasChanged', true);
      });
    },

    statusColor(statusType) {
      // Return status colors defined in theme.json -or- defaultColors -or- fallbackColors
      const status = statusType.toUpperCase();
      const fallbackColors = ["#c6cccf", "#999d9e"];
      const defaultColors = [{
        label: "wa_available",
        status: ["AVAILABLE", "READY_TO_START"],
        colors: ["#61c26d", "#359742"]
      }, {
        label: "wa_in_use",
        status: ["IN_USE", "LUCKY_CYCLE", "RESERVED"],
        colors: ["#f5b255", "#c98729"]
      }, {
        label: "wa_error",
        status: ["UNAVAILABLE", "ERROR", "UNKNOWN", "DOOR_OPEN"],
        colors: ["#D0011B", "#a20014"]
      }, {
        label: "wa_complete",
        status: ["COMPLETE"],
        colors: ["#4a9cdf", "#2671b4"]
      }, {
        label: "wa_offline",
        status: ["DEFAULT"],
        colors: ["#c6cccf", "#999d9e"]
      }];
      const colors = this.themeConfig?.status || defaultColors;

      function findStatus(status, colors) {
        return colors.find(item => {
          return item.status.includes(status);
        });
      }

      return findStatus(status, colors)?.colors || findStatus("DEFAULT", colors)?.colors || fallbackColors;
    },

    // given an id, update the color of the machine to reflect its new status
    updateMachineStatus(machine, newStatus) {
      let canvas = Ember.get(this, 'canvas');
      let machineId = machine.get('id');
      let machinesOnGrid = Ember.get(this, 'machinesOnGrid'); // if the machine is currently on the board it needs to be updated
      // there is no way to reach specific obj so we have to loop

      if (machinesOnGrid.indexOf(machineId) != -1) {
        canvas.getObjects().forEach(obj => {
          // if the object has a machineId, and it matches the machine Id
          if (obj.machineId && obj.machineId == machineId) {
            let status = newStatus.toLowerCase();
            let newColor = this.statusColor(status); // nested deeply in the group

            Ember.set(obj, 'color', newColor);
            Ember.set(obj, 'machineStatus', status);

            obj._objects[0]._objects[2].set({
              fill: newColor[0]
            }); // machine


            obj._objects[0]._objects[0].set({
              fill: newColor[1]
            }); // center circle


            obj._objects[0]._objects[3].set({
              fill: newColor[0]
            }); // center wash
            // re-render the whole canvas to update all colors


            canvas.renderAll();
          }
        });
      }
    },

    async uploadImage(file) {
      let _this = this;

      return await this.ajax.request(`${_environment.default.APP.ALLIANCE_API_URL}/rooms/assetUrl?fileName=${file.name}`, {
        method: 'GET'
      }).then(function (response) {
        _this.set('customImageKey', `rooms/${response.fileName}`); // needs to use reponse url


        return _this.get('ajax').request(response.signedUrl, {
          method: 'PUT',
          data: file,
          dataType: 'text',
          contentType: false,
          processData: false
        });
      });
    },

    actions: {
      showBgColor() {
        (0, _jquery.default)('#bgColor').click();
      },

      changeBgColor(e) {
        let canvas = this.canvas;
        this.set('bgColor', e.target.value);
        this.set('canvas.backgroundColor', e.target.value); // this.$('#bgColorIndicator')[0].style.color = e.target.value;

        canvas.renderAll();
        this.send('hasChanged', true);
      },

      addMachine(machine, machineStatus) {
        let selectedMachine;
        let machineId = machine.get('id');
        let machines = Ember.get(this, 'model');
        let machinesOnGrid = Ember.get(this, 'machinesOnGrid'); // add a machine if not already on the board

        if (machinesOnGrid.indexOf(machineId) == -1) {
          let statusId = 'READY_TO_START';
          machinesOnGrid.pushObject(machineId);
          machines.forEach(function (machine) {
            if (machine.get('id') == machineId) {
              selectedMachine = machine;
            }
          });
          this.createMachine(selectedMachine, statusId);
        }

        this.send('hasChanged', true);
      },

      // outputs out all info of all objects on the board
      async save() {
        if (this.access.isDemo) {
          alert('Demo mode restricted.');
          return;
        }

        let canvas = Ember.get(this, 'canvas');

        let _this = this;

        let roomId = Ember.get(this, 'room.id');
        let roomPayload = {
          roomLabelMeta: []
        };
        let machinePayload = {
          machines: []
        };
        let bgColor = this.bgColor;
        let machineModels = Ember.get(this, 'model');
        let machinesOnGrid = Ember.get(this, 'machinesOnGrid');
        let saveFailed = false;
        let roomSaveFinished = false;
        let machineSaveFinished = false;
        let file = Ember.get(this, 'file');
        let hasGrid = Ember.get(this, 'hasGrid');
        let background = {
          washAlertAngle: null,
          washAlertXAxis: null,
          washAlertYAxis: null,
          washAlertScaleX: null,
          washAlertScaleY: null
        };
        let textCount = 0;
        Ember.set(this, 'isSaving', true); // Deselect required to properly save multi-selected items (strange bug)

        Ember.set(this, 'selectedItem', null);
        canvas.discardActiveObject();
        canvas.renderAll(); // Get machines that are not displayed

        machineModels.forEach(machine => {
          if (machinesOnGrid.indexOf(machine.id) == -1) {
            machinePayload.machines.push({
              id: machine.id,
              washAlertIsVisible: false
            });
          }
        }); // Get machines that ARE displayed

        canvas.forEachObject(function (obj) {
          if (obj.id == 'machine') {
            machinePayload.machines.push({
              id: obj.machineId,
              washAlertXAxis: Math.round(obj.left),
              washAlertYAxis: Math.round(obj.top),
              washAlertScaleX: Math.round(obj.scaleX),
              washAlertScaleY: Math.round(obj.scaleY),
              washAlertAngle: Math.round(obj.angle),
              washAlertIsVisible: true,
              washAlertZIndex: canvas.getObjects().indexOf(obj)
            });
          }
        }); // Get text items

        let labelResults = [];
        canvas.forEachObject(async function (obj) {
          if (obj.id == 'text') {
            labelResults.push({
              id: ++textCount,
              labelText: obj.text,
              width: parseInt(obj.width),
              labelX: parseInt(obj.left),
              labelY: parseInt(obj.top),
              labelScaleX: parseInt(obj.scaleX),
              labelScaleY: parseInt(obj.scaleY),
              labelAngle: parseInt(obj.angle),
              labelFontSize: parseInt(obj.fontSize),
              labelColor: obj.fill,
              labelBgColor: obj.backgroundColor,
              labelFont: obj.fontFamily,
              labelWeight: obj.fontWeight,
              labelStyle: obj.fontStyle,
              labelUnderline: obj.underline,
              washAlertZIndex: canvas.getObjects().indexOf(obj),
              zIndex: canvas.getObjects().indexOf(obj)
            });
          }
        }); // Get image items - requires a Promise

        function getImageResults() {
          return new Promise(resolved => {
            let imageResults = [];
            let promises = [];
            canvas.forEachObject(function (obj) {
              if (obj.id == 'image') {
                if (obj.src !== undefined) {
                  // Need to upload image
                  let file = obj.src;

                  let request = _this.ajax.request(`${_environment.default.APP.ALLIANCE_API_URL}/rooms/assetUrl?fileName=${file.name}`, {
                    method: 'GET'
                  }).then(response => {
                    // Upload file to AWS
                    _this.get('ajax').request(response.signedUrl, {
                      method: 'PUT',
                      data: file,
                      dataType: 'text',
                      contentType: false,
                      processData: false
                    }); // Save data for api


                    imageResults.push({
                      id: ++textCount,
                      type: 'image',
                      filename: response.fileName,
                      positionX: parseInt(obj.left),
                      positionY: parseInt(obj.top),
                      scaleX: parseFloat(obj.scaleX.toFixed(2)),
                      scaleY: parseFloat(obj.scaleY.toFixed(2)),
                      angle: parseInt(obj.angle),
                      zIndex: canvas.getObjects().indexOf(obj)
                    });
                  });

                  promises.push(request);
                } else {
                  // already have an uploaded image
                  imageResults.push({
                    id: ++textCount,
                    type: 'image',
                    filename: obj.filename,
                    positionX: parseInt(obj.left),
                    positionY: parseInt(obj.top),
                    scaleX: parseFloat(obj.scaleX.toFixed(2)),
                    scaleY: parseFloat(obj.scaleY.toFixed(2)),
                    angle: parseInt(obj.angle),
                    zIndex: canvas.getObjects().indexOf(obj)
                  });
                }
              }
            });
            Promise.all(promises).then(() => {
              resolved(imageResults);
            });
          });
        } // Get background item


        canvas.forEachObject(async function (obj) {
          if (obj.id == 'layout') {
            background = {
              washAlertAngle: Math.round(obj.angle),
              washAlertXAxis: Math.round(obj.left),
              washAlertYAxis: Math.round(obj.top),
              washAlertScaleX: parseFloat(obj.scaleX.toFixed(2)),
              washAlertScaleY: parseFloat(obj.scaleY.toFixed(2)),
              washAlertZIndex: canvas.getObjects().indexOf(obj)
            };
          }
        }); // Save machines to room

        this.ajax.request('/machines/batchUpdate', {
          method: 'PATCH',
          data: machinePayload
        }).then(() => {
          if (roomSaveFinished) {
            _this.send('hasChanged', false);

            Ember.set(_this, 'isSaving', false);
            Ember.get(_this, 'notifications').info(_this.get('intl').t('layout_saved'), {
              autoClear: true,
              clearDuration: 2000
            });
          } else {
            machineSaveFinished = true;
          }
        }, () => {
          if (saveFailed === false) {
            Ember.set(_this, 'isSaving', false);
            Ember.get(_this, 'notifications').error(_this.get('intl').t('save_failed'));
            saveFailed = true;
          }
        });
        getImageResults().then(async imageResults => {
          // BACKGROUND
          if (file) {
            await this.uploadImage(file);
          }

          roomPayload.id = roomId;
          roomPayload.hasWashAlert = true;
          roomPayload.backgroundColor = bgColor;
          roomPayload.washAlertGridlinesOnBg = hasGrid;
          roomPayload.washAlertAngle = background.washAlertAngle;
          roomPayload.washAlertXAxis = background.washAlertXAxis;
          roomPayload.washAlertYAxis = background.washAlertYAxis;
          roomPayload.washAlertScaleX = background.washAlertScaleX;
          roomPayload.washAlertScaleY = background.washAlertScaleY;
          roomPayload.customImageKey = Ember.get(this, 'customImageKey');
          roomPayload.roomLabelMeta = [...labelResults, ...imageResults];
          this.ajax.request(`/rooms/${roomId}`, {
            method: 'PATCH',
            data: roomPayload
          }).then(() => {
            if (machineSaveFinished) {
              _this.send('hasChanged', false);

              Ember.set(_this, 'isSaving', false);
              Ember.get(_this, 'notifications').info(_this.get('intl').t('layout_saved'), {
                autoClear: true,
                clearDuration: 2000
              });
            } else {
              roomSaveFinished = true;
            }
          }, () => {
            if (saveFailed === false) {
              Ember.set(_this, 'isSaving', false);
              Ember.get(_this, 'notifications').error(_this.get('intl').t('save_failed'));
              saveFailed = true;
            }
          });
        });
      },

      load() {
        let canvas = Ember.get(this, 'canvas');
        let roomId = Ember.get(this, 'room.id');
        let machinesOnGrid = [];
        let machineModels = Ember.get(this, 'model');
        let self = this;
        Ember.set(this, 'isLoading', true);
        Ember.set(this, 'loadingFailed', false); // Clear canvas

        canvas.clear();
        Ember.set(this, 'machinesOnGrid', []);
        Ember.set(this, 'uploadImages', []);
        Ember.set(this, 'imageCount', 0);
        Ember.set(this, 'selectedItem', null);
        this.ajax.request(`/washAlert/rooms/${roomId}`, {
          method: 'GET'
        }).then(results => {
          Ember.set(this, 's3ImageUrl', results.s3ImagePath); // Load layout

          if (results.roomCustomImageUrl) {
            self.createBackground(results.roomCustomImageUrl, results.washAlertXAxis, results.washAlertYAxis, results.washAlertScaleX, results.washAlertScaleY, results.washAlertAngle);
          } else if (!self.isDestroyed) {
            Ember.set(self, 'hasBackgroundImage', false);
            Ember.set(self, 'showBackgroundUpload', true);
            Ember.set(self, 'file', null);
          }

          if (results.washAlertGridlinesOnBg) {
            self.createGrid(this.canvasSize);
          }

          if (results.backgroundColor) {
            Ember.set(self, 'bgColor', results.backgroundColor);
            canvas.set('backgroundColor', results.backgroundColor);
          } // load theme


          if (!self.isDestroyed) {
            self.send('loadTheme', results.washAlertTheme);
          } // Render machine icons


          if (results.machines) {
            results.machines.forEach(function (machine) {
              let selectedMachine, selectedStatus;
              let {
                washAlertXAxis,
                washAlertYAxis,
                washAlertScaleX,
                washAlertScaleY,
                washAlertAngle,
                washAlertZIndex
              } = machine;
              machineModels.forEach(function (model) {
                if (model.get('id') == machine.id && machine.washAlertIsVisible) {
                  selectedMachine = model;
                  selectedStatus = 'ready_to_start';
                }
              });

              if (selectedMachine) {
                self.createMachine(selectedMachine, selectedStatus, washAlertXAxis, washAlertYAxis, washAlertScaleX, washAlertScaleY, washAlertAngle, washAlertZIndex);
                machinesOnGrid.pushObject(machine.id);
              }
            });

            if (!self.isDestroyed) {
              Ember.set(self, 'machinesOnGrid', machinesOnGrid);
            }
          } // Render text and images


          if (results.roomLabelMeta) {
            results.roomLabelMeta.forEach(function (item) {
              if (item.filename) {
                const {
                  filename,
                  positionX,
                  positionY,
                  scaleX,
                  scaleY,
                  angle,
                  zIndex
                } = item;
                self.createImage(filename, positionX, positionY, scaleX, scaleY, angle, zIndex);
              } else {
                let txt = item;
                self.createText(txt.labelText, txt.labelFontSize, txt.width, txt.labelX, txt.labelY, txt.labelScaleX, txt.labelScaleY, txt.labelAngle, txt.labelColor, txt.labelBgColor, txt.labelFont, txt.labelWeight, txt.labelStyle, txt.labelUnderline, txt.washAlertZIndex);
              }
            });
          } // Render logo


          Ember.set(self, 'logoUrl', results.logoUrl);
          canvas.renderAll();
          Ember.set(self, 'isLoading', false);
          self.send('hasChanged', false);
          Ember.get(self, 'notifications').info(self.get('intl').t('layout_loaded'), {
            autoClear: true,
            clearDuration: 2000
          }); // Zindex will be screwed up unless we reset everything after all the things are rendered.
          // this.sortCanvas();

          Ember.run.debounce(this, this.debouncedSortCanvas, 750);
        }, () => {
          Ember.set(self, 'isLoading', false);
          Ember.set(self, 'loadingFailed', true);
        });
      },

      loadTheme(theme) {
        theme = theme || 'theme1';
        let config = {}; // can't use ember ajax because it targets api site

        _jquery.default.ajax({
          async: false,
          dataType: 'JSON',
          url: `/assets/images/wash-alert/themes/${theme}/theme.json`
        }).done(json => {
          config = json;
        });

        this.set('theme', theme);
        this.set('themeConfig', config);
      },

      addText() {
        this.createText();
        this.send('hasChanged', true);
      },

      addImage(e) {
        if (!this.canAddImage) {
          this.notifications.error(this.intl.t('max_images_reached'), {
            autoClear: true,
            clearDuration: 2000
          });
          return;
        }

        let self = this;
        let canvas = this.canvas;
        let [file] = e.target.files;
        let reader = new FileReader();

        if (file.size > this.maxImageSize) {
          this.notifications.error(this.intl.t('image_too_large'), {
            autoClear: true,
            clearDuration: 2000
          });
          return;
        }

        reader.onload = f => {
          let data = f.target.result;

          _fabric.fabric.Image.fromURL(data, img => {
            let image = img.set({
              id: 'image',
              type: 'image',
              src: file,
              filename: file.name,
              left: 0,
              top: 0,
              angle: 0,
              selectable: true,
              transparentCorners: false
            }).scale(0.5);
            image.setControlsVisibility(self.imageControls);
            canvas.add(image);
            canvas.renderAll();
            Ember.set(this, 'imageCount', this.imageCount + 1);
            this.uploadImages.pushObject({
              localFile: file
            }); // Clear the input field so the image can be uploaded again
            // TODO: Is there a cleaner, less fragile way to do this?

            document.getElementById('chooseImageFile').value = null;

            if (!this.canAddImage) {
              this.notifications.info(this.intl.t('max_images_reached'), {
                autoClear: true,
                clearDuration: 2000
              });
            }
          });
        };

        reader.readAsDataURL(file);
        this.send('hasChanged', true);
      },

      hasChanged(changed) {
        this.hasChanged(changed);
        Ember.set(this, 'isModified', changed);
        this.notifyPropertyChange('isModified');
      },

      toggleAdvanced() {
        this.toggleProperty('viewAdvanced');
      },

      // lock the layout from being edited
      toggleLockBackground() {
        // let canvas = get(this, 'canvas');
        this.canvas.forEachObject(obj => {
          if (obj.id === 'gridLayer') {
            this.toggleProperty('isBackgroundEditable');
            obj.selectable = Ember.get(this, 'isBackgroundEditable');

            if (obj.selectable) {
              obj.hoverCursor = null;
            } else {
              obj.hoverCursor = 'default';
            }

            this.canvas.discardActiveObject();
            this.canvas.renderAll();
          }
        });
      },

      // receives and initiates a change on a machine
      machineStatusChange(machine, newStatus) {
        this.updateMachineStatus(machine, newStatus);
      },

      changeBackground(color) {
        Ember.set(this, 'selectedItem.backgroundColor', color);
        this.canvas.renderAll();
        this.send('hasChanged', true);
      },

      changeFill(color) {
        Ember.set(this, 'selectedItem.fill', color);
        this.canvas.renderAll();
        this.send('hasChanged', true);
      },

      changeFont(fontFamily) {
        Ember.set(this, 'selectedItem.fontFamily', fontFamily);
        this.canvas.renderAll();
        this.send('hasChanged', true);
      },

      toggleItalic() {
        Ember.set(this, 'selectedItem.fontStyle', this.selectedItem.fontStyle === 'italic' ? '' : 'italic');
        this.canvas.renderAll();
        this.send('hasChanged', true);
      },

      toggleBold() {
        Ember.set(this, 'selectedItem.fontWeight', this.selectedItem.fontWeight === 'bold' ? '' : 'bold');
        this.canvas.renderAll();
        this.send('hasChanged', true);
      },

      toggleUnderline() {
        Ember.set(this, 'selectedItem.underline', this.selectedItem.underline == 'underline' ? '' : 'underline');
        this.canvas.renderAll();
        this.send('hasChanged', true);
      },

      removeItem(item) {
        // Images need to be removed from upload queue and update image count
        if (this.selectedItem.id === 'image') {
          if (this.selectedItem.src) {
            this.uploadImages.forEach((item, index) => {
              if (item.localFile === this.selectedItem.src) {
                this.uploadImages.splice(index, 1);
                this.notifyPropertyChange('uploadImages');
              }
            });
          }

          Ember.set(this, 'imageCount', this.imageCount - 1);
        } // Machines need to be removed from the machine list


        if (this.selectedItem.id === 'machine') {
          if (this.selectedItem.machineId) {
            this.machinesOnGrid.removeObject(this.selectedItem.machineId);
          }
        } // Any groups (just machines right now) need all their children removed


        if (this.selectedItem._objects) {
          this.selectedItem._objects.forEach(item => {
            this.canvas.remove(item);
          });
        } // Remove the item and update the canvas


        this.canvas.remove(this.selectedItem);
        this.canvas.discardActiveObject();
        this.canvas.renderAll();
        Ember.set(this, 'selectedItem', null);
        this.send('hasChanged', true);
      },

      removeMachine(machine) {
        let selectedMachine;

        if (machine) {
          this.canvas.forEachObject(obj => {
            if (obj && !obj.isGrid) {
              if (obj.machineId == machine.get('id')) {
                selectedMachine = obj;
              }

              if (obj.dismissOnSelect) {
                this.canvas.remove(obj);
                Ember.set(this, 'isShowingNewItemTooltip', false);
              }
            }
          });
        } else {
          selectedMachine = this.selectedItem;
        }

        this.canvas.remove(selectedMachine);
        this.machinesOnGrid.removeObject(selectedMachine.machineId);
        Ember.set(this, 'selectedItem', null);
        this.send('hasChanged', true);
      },

      reloadMachines() {
        let machineModels = this.model;
        let canvas = Ember.get(this, 'canvas');
        let machines = [];
        canvas.forEachObject(obj => {
          if (obj && obj.id == 'machine') {
            machines.push(obj);
          }
        });
        machines.forEach(machine => {
          let selectedMachine;
          canvas.remove(machine);
          machineModels.forEach(function (model) {
            if (model.get('id') == machine.machineId) {
              selectedMachine = model;
            }
          });
          this.createMachine(selectedMachine, 'ready_to_start', machine.left, machine.top, machine.scaleX, machine.scaleY, machine.angle, machine.washAlertZIndex || machine.zIndex);
        });
        this.sortCanvas();
      },

      removeBackground() {
        let canvas = Ember.get(this, 'canvas');
        canvas.forEachObject(obj => {
          if (obj && obj.id === 'layout') {
            canvas.remove(obj);
          }
        });
        canvas.forEachObject(obj => {
          if (obj && obj.id === 'gridLayer') {
            canvas.remove(obj);
          }
        });
        (0, _jquery.default)('#chooseBackgroundFile').val('');
        this.setProperties({
          'showBackgroundUpload': true,
          'hasBackgroundImage': false,
          'file': null,
          'customImageKey': ''
        });
        this.send('hasChanged', true);
      },

      removeBgColor() {
        Ember.set(this, 'bgColor', '');
        this.canvas.set('backgroundColor', null);
        this.canvas.renderAll();
        this.send('hasChanged', true);
      },

      toggleSnapping() {
        this.toggleProperty('snapping');
      },

      toggleGrid() {
        let hadGrid = false;
        this.canvas.forEachObject(obj => {
          if (obj && obj.id === 'grid') {
            this.canvas.remove(obj);
            hadGrid = true;
            this.set('hasGrid', false);
          }
        });

        if (!hadGrid) {
          this.createGrid(this.canvasSize);
        }

        this.send('hasChanged', true);
      },

      move(direction) {
        this.moveItem(this, direction);
      },

      resize(direction) {
        this.resizeItem(this, direction);
      }

    }
  });

  _exports.default = _default;
});