define("alliance-business-suite/templates/components/customers/c-notes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6Sg+Tr6P",
    "block": "{\"symbols\":[\"n\",\"@customer\"],\"statements\":[[11,\"div\"],[24,0,\"model-view\"],[4,[38,3],[[32,0,[\"fetchCustomerNotes\"]]],null],[12],[2,\"\\n\"],[6,[37,4],[\"CUSTOMERS_ORDERS_CREATE_NOTE\"],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"model-field\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"model-field__label\"],[12],[1,[30,[36,1],[\"add_note\"],null]],[13],[2,\"\\n    \"],[10,\"span\"],[14,0,\"model-field__value\"],[12],[2,\"\\n      \"],[11,\"span\"],[24,0,\"action\"],[4,[38,2],[\"click\",[32,0,[\"toggleCreateNote\"]]],null],[12],[2,\"\\n        \"],[1,[30,[36,1],[\"create_note\"],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[32,0,[\"filteredNotes\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"model-field\"],[12],[2,\"\\n      \"],[10,\"span\"],[14,0,\"model-field__label\"],[12],[2,\"\\n        \"],[1,[32,1,[\"firstName\"]]],[2,\" \"],[1,[32,1,[\"lastName\"]]],[2,\"\\n        \"],[10,\"span\"],[14,0,\"pull-right\"],[12],[2,\"\\n          \"],[1,[30,[36,0],[[32,1,[\"noteDate\"]]],null]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"span\"],[14,0,\"model-field__value\"],[12],[1,[32,1,[\"noteText\"]]],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\\n\"],[6,[37,7],[[32,0,[\"viewCreateNoteModal\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"ui/modal-create-user-note\",[],[[\"@customer\",\"@close\"],[[32,2],[32,0,[\"toggleCreateNote\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"utc-format\",\"t\",\"on\",\"did-insert\",\"can-access\",\"-track-array\",\"each\",\"if\"]}",
    "moduleName": "alliance-business-suite/templates/components/customers/c-notes.hbs"
  });

  _exports.default = _default;
});