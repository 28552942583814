define("alliance-business-suite/templates/components/form-ui/duration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "00LkNyTQ",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[32,0,[\"showComponent\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[10,\"div\"],[14,0,\"text-field duration-field\"],[12],[2,\"\\n  \"],[10,\"label\"],[14,0,\"form-field--label form-ui-element\"],[12],[2,\"\\n    \"],[1,[32,0,[\"label\"]]],[2,\"\\n    \"],[8,\"form-ui/duration-field/duration-field\",[],[[\"@value\",\"@display\",\"@on-change\",\"@disabled\",\"@class\",\"@max\"],[[32,0,[\"value\"]],[32,0,[\"display\"]],[30,[36,1],[[30,[36,0],[[32,0,[\"value\"]]],null]],null],[32,0,[\"isDisabled\"]],\"form-field--control\",[32,0,[\"params\",\"max\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"dev-tools hidden-formui-component\"],[12],[2,\"DURATION component hidden\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[8,\"form-ui/dev-tools\",[],[[\"@outputs\"],[[30,[36,3],null,[[\"type\",\"hoursPropertyName\",\"minutesPropertyName\",\"secondsPropertyName\",\"hours\",\"minutes\",\"seconds\",\"min\",\"max\"],[[32,0,[\"type\"]],[32,0,[\"properties\",\"hours\",\"key\"]],[32,0,[\"properties\",\"minutes\",\"key\"]],[32,0,[\"properties\",\"seconds\",\"key\"]],[32,0,[\"hours\"]],[32,0,[\"minutes\"]],[32,0,[\"seconds\"]],[32,0,[\"params\",\"min\"]],[32,0,[\"params\",\"max\"]]]]]]],null]],\"hasEval\":false,\"upvars\":[\"mut\",\"fn\",\"if\",\"hash\"]}",
    "moduleName": "alliance-business-suite/templates/components/form-ui/duration.hbs"
  });

  _exports.default = _default;
});