define("alliance-business-suite/templates/components/summary/reports/s-campus-card-recon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0ex1Q8ag",
    "block": "{\"symbols\":[\"@height\"],\"statements\":[[10,\"div\"],[14,0,\"campus-card-recon\"],[12],[2,\"\\n  \"],[8,\"summary/reports/default-report-shim\",[],[[\"@height\",\"@translatedColumns\",\"@rowsWithChildren\"],[[32,1],[32,0,[\"translatedColumns\"]],[32,0,[\"rowsWithChildren\"]]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "alliance-business-suite/templates/components/summary/reports/s-campus-card-recon.hbs"
  });

  _exports.default = _default;
});