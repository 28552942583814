define("alliance-business-suite/controllers/support", ["exports", "alliance-business-suite/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['section']
  });

  _exports.default = _default;
});