define("alliance-business-suite/templates/components/ui/column-block", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "T/Q/OkWF",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,0],[[32,0,[\"showComponent\"]]],null,[[\"default\"],[{\"statements\":[[10,\"div\"],[14,0,\"split-half\"],[12],[2,\"\\n  \"],[18,1,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "alliance-business-suite/templates/components/ui/column-block.hbs"
  });

  _exports.default = _default;
});