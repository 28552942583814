define("alliance-business-suite/templates/retail/categories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ruDrDYRZ",
    "block": "{\"symbols\":[],\"statements\":[[8,\"products/categories-section\",[],[[\"@model\",\"@items\",\"@categories\",\"@inventory\"],[[34,0],[34,0,[\"items\"]],[34,0,[\"categories\"]],[34,0,[\"inventory\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "moduleName": "alliance-business-suite/templates/retail/categories.hbs"
  });

  _exports.default = _default;
});