define("alliance-business-suite/components/customers/c-stats", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;

  let CustomersCStatsComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember.computed('cache.loadedGeoIncludedAll'), _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, (_class = class CustomersCStatsComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "ajax", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "cache", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "selectedLocation", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "customerStats", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "isLoading", _descriptor8, this);
    }

    get geosLoaded() {
      if (this.cache.loadedGeoIncludedAll) {
        return true;
      }

      return;
    }

    get rooms() {
      let allRooms = this.store.peekAll('room').sortBy('formattedBreadcrumbDisplay');
      return allRooms.filter(room => {
        if (room.get('isCrm')) {
          return true;
        }
      });
    }

    initialLoad() {
      let {
        rooms
      } = this;
      this.selectedLocation = rooms[0];
      this.getStats();
    }

    getStats() {
      this.isLoading = true;
      this.ajax.request(`/users/${this.args.customer.id}/campaignTrackingPreview?roomIds={${this.selectedLocation.id}}&raw=true`, {
        method: 'GET'
      }).then(response => {
        this.isLoading = false;
        this.customerStats = response;
      });
    }

    setSelectedLocation(val) {
      this.selectedLocation = val;
      this.getStats();
    }

    refresh() {
      this.getStats();
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "cache", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedLocation", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "customerStats", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isLoading", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "geosLoaded", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "geosLoaded"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "initialLoad", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "initialLoad"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setSelectedLocation", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "setSelectedLocation"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "refresh", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "refresh"), _class.prototype)), _class));
  _exports.default = CustomersCStatsComponent;
});