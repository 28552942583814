define("alliance-business-suite/templates/components/machines/programs/cycles/ui/cycles-water-use", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HIFbBw9K",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[32,0,[\"show\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[14,0,\"label-water-hot\"],[12],[1,[32,0,[\"total\"]]],[2,\" \"],[1,[32,0,[\"units\"]]],[2,\" / \"],[1,[32,0,[\"hot\"]]],[2,\" \"],[1,[30,[36,0],[\"hot\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"if\"]}",
    "moduleName": "alliance-business-suite/templates/components/machines/programs/cycles/ui/cycles-water-use.hbs"
  });

  _exports.default = _default;
});