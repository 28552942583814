define("alliance-business-suite/templates/components/ui/location-tree", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7YHh+GYU",
    "block": "{\"symbols\":[],\"statements\":[[8,\"ember-jstree\",[],[[\"@data\",\"@actionReceiver\",\"@eventDidCheckNode\",\"@eventDidSelectNode\",\"@eventDidBecomeReady\",\"@eventDidRedraw\",\"@eventDidChange\",\"@typesOptions\",\"@checkboxOptions\",\"@preSelectedNodes\",\"@plugins\",\"@selectedNodes\",\"@callback\",\"@filterMachineType\"],[[34,1],[34,2],[30,[36,3],[[32,0],\"didCheckNode\"],null],[30,[36,3],[[32,0],\"didSelectNode\"],null],[30,[36,3],[[32,0],\"didLoad\"],null],[30,[36,3],[[32,0],\"didLoad\"],null],[30,[36,3],[[32,0],\"didChange\"],null],[34,4],[34,5],[34,6],[34,7],[34,8],[34,9],[34,10]]],null],[2,\"\\n\\n\"],[6,[37,12],[[35,11]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"content-container container--placeholder\"],[12],[2,\"\\n    \"],[10,\"img\"],[14,0,\"scrubbrush-watermark\"],[14,\"src\",\"/assets/images/warning.svg\"],[12],[13],[2,\"\\n    \"],[10,\"h3\"],[14,0,\"scrubbrush-watermark\"],[12],[1,[30,[36,0],[\"location_tree_empty\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"formattedData\",\"jstreeActionReceiver\",\"action\",\"typesOptions\",\"checkboxOptions\",\"preSelectedNodes\",\"plugins\",\"selectedTreeNodes\",\"callback\",\"filterMachineType\",\"emptyTree\",\"if\"]}",
    "moduleName": "alliance-business-suite/templates/components/ui/location-tree.hbs"
  });

  _exports.default = _default;
});