define("alliance-business-suite/components/summary/subscriptions/s-edit-form", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "alliance-business-suite/config/environment", "alliance-business-suite/utils/subscription-methods-utils"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _environment, _subscriptionMethodsUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21;

  const LOCATION_OPTIONS = ["ALL_LOCATIONS", "CUSTOM_LOCATIONS"];
  let SummarySubscriptionsSEditFormComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember.inject.service, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember._tracked, _dec18 = Ember._tracked, _dec19 = Ember._tracked, _dec20 = Ember._tracked, _dec21 = Ember._action, _dec22 = Ember._action, _dec23 = Ember.computed("activeReport.subscriptionConditionTemplates[]", "isRoomRequired"), _dec24 = Ember._tracked, _dec25 = Ember._action, _dec26 = Ember._action, _dec27 = Ember._action, _dec28 = Ember._action, (_class = class SummarySubscriptionsSEditFormComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "ajax", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "canAccess", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "notifications", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "router", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "session", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "subscriptionConditions", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "requiredFields", _descriptor10, this);
      (0, _initializerDefineProperty2.default)(this, "email", _descriptor11, this);
      (0, _initializerDefineProperty2.default)(this, "activeReport", _descriptor12, this);
      (0, _initializerDefineProperty2.default)(this, "selectedReportInterval", _descriptor13, this);
      (0, _initializerDefineProperty2.default)(this, "componentSelectedFrequency", _descriptor14, this);
      (0, _initializerDefineProperty2.default)(this, "componentSelectedRooms", _descriptor15, this);
      (0, _initializerDefineProperty2.default)(this, "componentSelectedSendAtTime", _descriptor16, this);
      (0, _initializerDefineProperty2.default)(this, "componentSelectedSendOnDay", _descriptor17, this);
      (0, _initializerDefineProperty2.default)(this, "isSaving", _descriptor18, this);
      (0, _initializerDefineProperty2.default)(this, "isDeleting", _descriptor19, this);
      (0, _initializerDefineProperty2.default)(this, "isLoading", _descriptor20, this);
      (0, _initializerDefineProperty2.default)(this, "selectedMachine", _descriptor21, this);
    }

    get dayOfWeeks() {
      return _subscriptionMethodsUtils.DAYS_OF_WEEK;
    }

    get editingDisabled() {
      if (!this.args.isEditing) {
        return false;
      }

      return !this.canAccess.canAccess("SUMMARY_SUBSCRIPTION_EDIT_SUBSCRIPTION");
    } // computed('selectedFrequency',


    get disableDayOfWeek() {
      if (this.componentSelectedFrequency && this.componentSelectedFrequency.get("messageLocalizationKey") == "FREQUENCY_DAILY") {
        return true;
      }

      return false;
    } // computed('subscriptionTemplatesModel.[]',


    get subscriptionTemplates() {
      return this.args.subscriptionTemplatesModel.filterBy("reportLookupKey");
    }

    initialLoad() {
      this.buildInitialActiveReport();
      this.buildInitialReportedInterval();
    }

    willDestroySReports() {
      if (this.args.clearQueryParam) {
        this.args.clearQueryParam();
      }
    }

    buildInitialActiveReport() {
      let key = ""; // if there is a query param of queryReportId, default to that

      if (this.args.queryReportId) {
        key = this.args.queryReportId;
      }

      if (this.args.report && this.args.report.get("reportLookupKey")) {
        key = this.args.report.get("reportLookupKey");
      }

      let activeReport = this.args.subscriptionTemplatesModel.filterBy("reportLookupKey", key);

      if (activeReport.length == 0) {
        this.activeReport = activeReport;
      } else {
        this.activeReport = activeReport.get("firstObject");
      }
    } // TEMPORARY for demos august 11 2019


    get isTempOrg() {
      let organizationId = this.session.get("data.orgId");

      if (organizationId == "623" || organizationId == "81620" || organizationId == "30865") {
        return true;
      }

      return false;
    } // TEMPORARY for demos august 11 2019


    get locationOptions() {
      if (this.isTempOrg) {
        return ["CUSTOM_LOCATIONS"];
      }

      return LOCATION_OPTIONS;
    } // TEMPORARY for demos august 11 2019


    get selectedLocationOption() {
      let {
        isTempOrg,
        componentSelectedRooms
      } = this;
      let selectedLocationOption;

      if (this.activeReport.get("subscriptionConditionTemplates")) {
        this.activeReport.get("subscriptionConditionTemplates").forEach(subscriptionCondition => {
          if (subscriptionCondition.get("subscriptionConditionType.lookupKey") === "ROOM_ID") {
            if (componentSelectedRooms && componentSelectedRooms.get("firstObject.id")) {
              selectedLocationOption = LOCATION_OPTIONS[1];
            } else {
              selectedLocationOption = LOCATION_OPTIONS[0];
            }
          }
        });
      }

      return selectedLocationOption;
    }

    set selectedLocationOption(value) {
      return value;
    }

    buildInitialReportedInterval() {
      let selectedReportInterval = null;

      if (this.args.report && this.args.report.get("subscriptionConditions")) {
        this.args.report.get("subscriptionConditions").forEach(subscriptionCondition => {
          let subscriptionConditions = this.store.peekAll("subscriptionConditionTemplateValue");
          subscriptionConditions.forEach(record => {
            if (record.get("value") == subscriptionCondition.get("dateValue")) {
              selectedReportInterval = record;
            }
          });
        });
      }

      this.selectedReportInterval = selectedReportInterval;
    }

    getReportOrg(subscriptionConditions) {
      let condition = {};
      this.activeReport.get("subscriptionConditionTemplates").forEach(conditionTemplate => {
        if (conditionTemplate.get("subscriptionConditionType.lookupKey") == "ORGANIZATION_ID") {
          condition.intValue = this.access.get("accessOrganization.id");
          condition.subscriptionConditionTypeId = conditionTemplate.get("subscriptionConditionType.id");
          subscriptionConditions.push(condition);
        }
      });
      return subscriptionConditions;
    }

    getReportInterval(subscriptionConditions) {
      let condition = {};

      if (this.selectedReportInterval) {
        this.activeReport.get("subscriptionConditionTemplates").forEach(conditionTemplate => {
          if (conditionTemplate.get("subscriptionConditionType.lookupKey") == "REPORT_INTERVAL") {
            condition.dateValue = this.selectedReportInterval.get("value");
            condition.subscriptionConditionTypeId = conditionTemplate.get("subscriptionConditionType.id");
            subscriptionConditions.push(condition);
          }
        });
      }

      return subscriptionConditions;
    }

    getReportRooms(subscriptionConditions) {
      if (this.selectedLocationOption === "ALL_LOCATIONS") {
        this.activeReport.get("subscriptionConditionTemplates").forEach(conditionTemplate => {
          let condition = {};

          if (conditionTemplate.get("subscriptionConditionType.lookupKey") == "ROOM_ID") {
            condition.intValue = null;
            condition.subscriptionConditionTypeId = conditionTemplate.get("subscriptionConditionType.id");
            subscriptionConditions.push(condition);
          }
        });
      } else if (this.componentSelectedRooms) {
        this.componentSelectedRooms.forEach(room => {
          let condition = {};
          this.activeReport.get("subscriptionConditionTemplates").forEach(conditionTemplate => {
            if (conditionTemplate.get("subscriptionConditionType.lookupKey") == "ROOM_ID") {
              condition.intValue = room.get("id");
              condition.subscriptionConditionTypeId = conditionTemplate.get("subscriptionConditionType.id");
              subscriptionConditions.push(condition);
            }
          });
        });
      }

      return subscriptionConditions;
    }

    get timeOptions() {
      let locale = "en"; // or whatever you want...

      let hours = [];
      moment.locale(locale); // optional - can remove if you are only dealing with one locale

      for (let hour = 0; hour < 24; hour++) {
        let hourObj = {};
        let hour30Obj = {};
        hourObj.display = moment({
          hour
        }).format("h:mm A");
        hourObj.value = moment({
          hour
        }).format("HH:mm");
        hours.push(hourObj);
        hour30Obj.display = moment({
          hour,
          minute: 30
        }).format("h:mm A");
        hour30Obj.value = moment({
          hour,
          minute: 30
        }).format("HH:mm");
        hours.push(hour30Obj);
      }

      return hours;
    }

    get machineIdOptions() {
      return this.store.peekAll("machine");
    }

    async deleteReportSubscription(subscription) {
      let self = this;
      let subscriptionId = subscription.get("id");
      this.isLoading = true;
      this.isDeleting = true;
      await this.ajax.request(`/subscriptions/${subscriptionId}`, {
        method: "DELETE"
      }).then(response => {
        // remove Ember data store
        subscription.deleteRecord();
        subscription.unloadRecord();
        this.notifications.info(this.intl.t("deleted_exclamation"), {
          autoClear: true,
          clearDuration: 6000
        });
        this.router.transitionTo("summary.subscriptions");
        this.isLoading = false;
        this.isDeleting = false;
        return response;
      }).catch((error
      /* , jqXHR */
      ) => {
        this.notifications.error(this.intl.t("please_try_again"), {
          autoClear: true,
          clearDuration: 6000
        });
        this.isLoading = false;
        this.isDeleting = false;
        return error;
      });
    }

    async createReportSubscription(subscription) {
      this.isLoading = true;
      this.isSaving = true;
      await this.ajax.request(`/subscriptionTemplates/${this.activeReport.get("id")}/subscriptions`, {
        method: "POST",
        data: JSON.stringify(subscription)
      }).then(response => {
        this.notifications.info(this.intl.t("success"), {
          autoClear: true,
          clearDuration: 6000
        });
        this.store.pushPayload(response);
        this.router.transitionTo("summary.subscriptions");
        this.isLoading = false;
        this.isSaving = false;
        return response;
      }).catch((error
      /* , jqXHR */
      ) => {
        this.notifications.error(this.intl.t("please_try_again"), {
          autoClear: true,
          clearDuration: 6000
        });
        this.isLoading = false;
        this.isSaving = false;
        return error;
      });
    }

    async saveReportSubscription(subscription) {
      let subscriptionId = this.args.report.get("id");
      this.isLoading = true;
      this.isSaving = true;
      await this.ajax.request(`/subscriptions/${subscriptionId}`, {
        method: "PATCH",
        data: JSON.stringify(subscription)
      }).then(response => {
        this.notifications.info(this.intl.t("success"), {
          autoClear: true,
          clearDuration: 6000
        });
        this.store.pushPayload(response);
        this.router.transitionTo("summary.subscriptions");
        this.isLoading = false;
        this.isSaving = false;
        return response;
      }).catch((error
      /* , jqXHR */
      ) => {
        this.notifications.error(this.intl.t("please_try_again"), {
          autoClear: true,
          clearDuration: 6000
        });
        this.isLoading = false;
        this.isSaving = false;
        return error;
      });
    }

    validateFields() {
      let valid = true;

      if (!this.email) {
        valid = false;
      }

      if (this.activeReport) {
        if (!this.componentSelectedSendAtTime || !this.componentSelectedFrequency) {
          valid = false;
        }

        if (this.componentSelectedFrequency.get("messageLocalizationKey") !== "FREQUENCY_DAILY" && !this.componentSelectedSendOnDay) {
          valid = false;
        } // Conditional fields


        this.activeReport.get("subscriptionConditionTemplates").forEach(condition => {
          if (condition.get("subscriptionConditionType.lookupKey") == "ROOM_ID") {
            if (this.selectedLocationOption === "CUSTOM_LOCATIONS" && (!this.componentSelectedRooms || this.componentSelectedRooms.length <= 0)) {
              valid = false;
            }
          } else if (condition.get("subscriptionConditionType.lookupKey") == "REPORT_INTERVAL") {
            if (!this.selectedReportInterval) {
              valid = false;
            }
          }
        });
      }

      return valid;
    }

    cancel() {
      this.router.transitionTo("summary.subscriptions");
    }

    delete(subscription) {
      this.deleteReportSubscription(subscription);
    }

    create() {
      // first make sure all fields have been filled in
      if (!this.validateFields()) {
        this.notifications.error(this.intl.t("please_fill_out_the_entire_form"), {
          autoClear: true,
          clearDuration: 5000
        });
        return false;
      }

      let userId = this.access.get("accessUser.id");
      let newSubscription = {};
      newSubscription.subscriptionEventTypeId = this.activeReport.get("subscriptionEventType.id");

      try {
        newSubscription.userId = userId;
        newSubscription.brandLookupKey = _environment.default.brand;
        newSubscription.timeZone = moment.tz.guess();
        newSubscription.frequencyId = this.componentSelectedFrequency.get("id");
        newSubscription.sendAtTime = this.componentSelectedSendAtTime.value; // if frequency is daily then we dont send on day

        newSubscription.sendOnDay = this.componentSelectedFrequency.get("id") == 1 ? null : this.componentSelectedSendOnDay.id;
        newSubscription.subscriptionConditions = [];
        newSubscription.subscriptionConditions = this.getReportOrg(newSubscription.subscriptionConditions);
        newSubscription.subscriptionConditions = this.getReportInterval(newSubscription.subscriptionConditions);
        newSubscription.subscriptionConditions = this.getReportRooms(newSubscription.subscriptionConditions);
        newSubscription.email = this.email;
        this.createReportSubscription(newSubscription);
      } catch (error) {
        this.notifications.error(this.intl.t("please_fill_out_the_entire_form"), {
          autoClear: true,
          clearDuration: 5000
        });
      }
    }

    save() {
      // first make sure all fields have been filled in
      if (!this.validateFields()) {
        this.notifications.error(this.intl.t("please_fill_out_the_entire_form"), {
          autoClear: true,
          clearDuration: 5000
        });
        return false;
      }

      let userId = this.access.get("accessUser.id");
      let newSubscription = {};
      newSubscription.subscriptionEventTypeId = this.activeReport.get("subscriptionEventType.id");

      try {
        newSubscription.userId = userId;
        newSubscription.brandLookupKey = _environment.default.brand;
        newSubscription.timeZone = moment.tz.guess();
        newSubscription.frequencyId = this.componentSelectedFrequency.get("id");
        newSubscription.sendAtTime = this.componentSelectedSendAtTime.value;
        newSubscription.sendOnDay = this.componentSelectedFrequency.get("id") == 1 ? null : this.componentSelectedSendOnDay.id;
        newSubscription.subscriptionConditions = [];
        newSubscription.subscriptionConditions = this.getReportOrg(newSubscription.subscriptionConditions);
        newSubscription.subscriptionConditions = this.getReportInterval(newSubscription.subscriptionConditions);
        newSubscription.subscriptionConditions = this.getReportRooms(newSubscription.subscriptionConditions);
        newSubscription.email = this.email;
        this.saveReportSubscription(newSubscription);
      } catch (error) {
        this.notifications.error(this.intl.t("please_fill_out_the_entire_form"), {
          autoClear: true,
          clearDuration: 5000
        }); // console.error(error);
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "canAccess", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "notifications", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "router", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "session", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "subscriptionConditions", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "requiredFields", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor11 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "email", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.activeEmail || this.session.get("data.accessPrimaryEmail");
    }
  }), _descriptor12 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "activeReport", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedReportInterval", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "componentSelectedFrequency", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.selectedFrequency;
    }
  }), _descriptor15 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "componentSelectedRooms", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.selectedRooms;
    }
  }), _descriptor16 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "componentSelectedSendAtTime", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.selectedSendAtTime;
    }
  }), _descriptor17 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "componentSelectedSendOnDay", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.selectedSendOnDay;
    }
  }), _descriptor18 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isSaving", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isDeleting", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isLoading", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "initialLoad", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "initialLoad"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "willDestroySReports", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "willDestroySReports"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedLocationOption", [_dec23], Object.getOwnPropertyDescriptor(_class.prototype, "selectedLocationOption"), _class.prototype), _descriptor21 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedMachine", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "cancel", [_dec25], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "delete", [_dec26], Object.getOwnPropertyDescriptor(_class.prototype, "delete"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "create", [_dec27], Object.getOwnPropertyDescriptor(_class.prototype, "create"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "save", [_dec28], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype)), _class));
  _exports.default = SummarySubscriptionsSEditFormComponent;
});