define("alliance-business-suite/models/subscription-condition-template-value", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    messageLocalizationKey: attr('string'),
    value: attr('string'),
    isDefault: attr('boolean') // lookupKey: attr('string')
    // defaultValue: attr('string'),
    // minValue: attr('string'),
    // maxValue: attr('string'),
    // messageLocalizationKey: attr('string'),
    // subscriptionConditionTemplateValues: hasMany('subscription-condition-template-values')

  });

  _exports.default = _default;
});