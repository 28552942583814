define("alliance-business-suite/components/scrub/brush-header", ["exports", "jquery", "alliance-business-suite/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const componentClass = 'scrub-brush-header';

  var _default = Ember.Component.extend({
    access: Ember.inject.service(),

    /**
     * The "block" component class name for this component in the
     * Block-Element-Modifier (BEM) naming convention.
     *
     * @type {Array<String>}
     * @public
     */
    componentClass,

    /**
     * The static class names for this component.
     *
     * @type {Array<String>}
     * @public
     */
    classNames: [componentClass],

    /**
     * Handles clicks that happen anywhere on the page after the history has been
     * opened.
     *
     * @protected
     */
    handleClick() {
      Ember.set(this, 'showHistory', false);
      (0, _jquery.default)(document).off('click.brush-header');
    },

    /**
     * The actions for this component.
     *
     * @public
     */
    actions: {
      /**
       * Moves one entry back in the brush history.
       *
       * @public
       */
      back() {
        Ember.get(this, 'brush').back();
      },

      /**
       * Moves one entry forward in the brush history.
       *
       * @public
       */
      forward() {
        Ember.get(this, 'brush').forward();
      },

      /**
       * Toggles the open status of the history.
       *
       * @public
       */
      toggleHistory(e) {
        this.toggleProperty('showHistory');

        if (Ember.get(this, 'showHistory')) {
          (0, _jquery.default)(document).on('click.brush-header', this.handleClick.bind(this)); // Don't allow the click event to bubble; otherwise the click handler
          // we just added will handle the event and then immediately close the
          // history

          e.stopPropagation();
        }
      }

    }
  });

  _exports.default = _default;
});