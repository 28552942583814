define("alliance-business-suite/components/welcome/welcome-tos", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;

  let WelcomeWelcomeTosComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, (_class = class WelcomeWelcomeTosComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "session", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "router", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "ajax", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "notifications", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "isLoading", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "agreement", _descriptor8, this);
    }

    cancel() {
      this.session.invalidate();
    }

    accept() {
      this.isLoading = true;
      this.ajax.request(`/users/${this.session.get('data.userId')}/acceptTOS`, {
        method: 'POST'
      }).then(response => {
        this.session.set('data.hasAcceptedTOS', true);
        this.args.updateCurrentView();
        return response;
      }).catch(() => {
        this.notifications.error(this.intl.t('please_try_again'), {
          autoClear: true,
          clearDuration: 6000
        });
        return;
      }).finally(() => {
        this.isLoading = false;
      });
    }

    returnToStart() {
      this.router.transitionTo('start');
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "ajax", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "notifications", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isLoading", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "agreement", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "cancel", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "accept", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "accept"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "returnToStart", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "returnToStart"), _class.prototype)), _class));
  _exports.default = WelcomeWelcomeTosComponent;
});