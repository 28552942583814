define("alliance-business-suite/components/machines/programs/cycles/midas-opl-tum", ["exports", "ember-copy", "alliance-business-suite/utils/is-number", "jquery", "alliance-business-suite/mixins/machines/programs/midas-property-names", "alliance-business-suite/mixins/machines/programs/midas-options", "alliance-business-suite/helpers/find-by-dropdown-with-custom"], function (_exports, _emberCopy, _isNumber, _jquery, _midasPropertyNames, _midasOptions, _findByDropdownWithCustom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Removed CycleGlobal mixin!
  var _default = Ember.Component.extend(_midasOptions.default, _midasPropertyNames.default, {
    isDependentSupplyStep(step, index, enumerable, activeStates) {
      // Check to see if supply step is following a fill step or following a supply that follows a fill step
      if (step.Type == 3) {
        while (index > 0) {
          index--;
          let prevStep = enumerable[index];
          let prevActiveState = (0, _findByDropdownWithCustom.findByDropDown)([this.selectOptions['ActiveState'], "val", prevStep.ActiveState]).label;
          let prevType = (0, _findByDropdownWithCustom.findByDropDown)([this.selectOptions['StepType'], "val", prevStep.Type]).label;

          if (activeStates.includes(prevActiveState)) {
            if (["Fill", "ReuseFill"].includes(prevType)) {
              // Fill or Reuse Fill type
              return true;
            } else if (prevType !== "Supply") {
              // not Supply step
              return false;
            }
          }
        }
      }

      return false;
    },

    calculateStepTime(step, enumerable) {
      console.time('calculateStepTime'); // Return array for LIGHT, MEDIUM, HEAVY, AND SPEED_CYCLE

      let stepName = (0, _findByDropdownWithCustom.findByDropDown)([this.selectOptions['StepType'], "val", step.Type]).label.toLowerCase();
      let activeState = (0, _findByDropdownWithCustom.findByDropDown)([this.selectOptions['ActiveState'], "val", step.ActiveState]).label;
      let stepLabel = (0, _findByDropdownWithCustom.findByDropDown)([this.selectOptions['StepLabel'], "val", step.Label]).label.toLowerCase();
      let mods = this.mods;
      let stepIndex = enumerable.indexOf(step);
      let times = [0, 0, 0, 0]; // Loop through each mods element

      mods.forEach((mod, index) => {
        let totalSeconds = 0;
        let hours,
            minutes,
            seconds = 0; // Only add up active steps

        if (mod.activeStates.includes(activeState)) {
          switch (stepName) {
            case 'agitate':
              if (stepLabel == 'wash1' && activeState !== 'InactiveMod3') {
                // InactiveMod3 = Off with Speed Cycle
                totalSeconds += mod.additionalWashMinutes * 60;
              }

              if (stepLabel == 'rinse1' && activeState !== 'InactiveMod3') {
                totalSeconds += mod.additionalRinseMinutes * 60;
              }

            // fallthrough

            case 'fill':
            case 'reusefill':
            case 'drainextract':
            case 'audiosignal':
            case 'hold':
            case 'soak':
              hours = step.Hours || 0;
              minutes = step.Minutes || 0;
              seconds = step.Seconds || 0;
              totalSeconds += Number(Big(hours).times(3600).plus(Big(minutes).times(60)).plus(seconds));
              break;

            case 'drain':
              totalSeconds += 30;
              break;

            case 'supply':
              // This one's tricky...
              if (this.isDependentSupplyStep(step, stepIndex, enumerable, mod.activeStates)) {// Time is part of fill step
              } else {
                minutes = step.Minutes || 0;
                seconds = step.Seconds || 0;
                totalSeconds += Number(Big(minutes).times(60).plus(seconds).plus(step.SupplyDelay));
              }

              break;
          }
        }

        times[index] = totalSeconds;
      });
      console.timeEnd('calculateStepTime');
      return times;
    },

    applyTime() {
      console.time('applyTime');
      this.cycleList.forEach((cycle, index) => {
        Ember.get(cycle, 'Steps').forEach((step, index, enumerable) => {
          Ember.set(step, "_totalSeconds", undefined); // This is needed to force updates to existing items

          Ember.defineProperty(step, "_totalSeconds", Ember.computed('[]', 'this.[]', 'Hours', 'Minutes', 'Seconds', 'Label', 'ActiveState', 'SupplyDelay', () => {
            return this.calculateStepTime(step, enumerable);
          }));
        }); // Now add up the steps for cycles

        Ember.defineProperty(cycle, "_totalSeconds", Ember.computed('Steps.@each._totalSeconds', () => {
          return cycle.Steps.reduce((val, item) => {
            return [Big(val[0]).plus(item._totalSeconds[0]), Big(val[1]).plus(item._totalSeconds[1]), Big(val[2]).plus(item._totalSeconds[2]), Big(val[3]).plus(item._totalSeconds[3])];
          }, [0, 0, 0, 0]);
        }));
      });
      console.timeEnd('applyTime');
    },

    // init() {
    //   this._super(...arguments);
    //   // this.applyTime();
    // },
    // NEW STUFF HERE
    //#region Variables
    notifications: Ember.inject.service(),
    intl: Ember.inject.service(),
    clipboard: {
      type: null,
      contents: null,
      title: null
    },
    maxCycles: 15,

    // showCycleInfo: false,
    //#endregion
    //#region Events
    didInsertElement() {
      let self = this;

      this._super(...arguments);

      this.send('selectCycle');
      Ember.set(this, 'defaultMachineType', Ember.get(this, 'cycleList.firstObject.MachineType'));
      Ember.set(this, 'hasCycleNumbers', Ember.get(this, 'cycleList.firstObject.Number') ? true : false); // TODO: How do we know if this is for cycles or steps?
      // Catch Ctrl+Up and Ctrl+Down keyboard events to move the selected step position in the list.
      // Mousetrap.bind(['mod+up', 'mod+down'], function(e, combo) {
      //   e.preventDefault();
      //   e.stopPropagation();
      //   let cycle = self.get('selectedCycle');
      //   let stepIndex = self.get('selectedStepIndex');
      //   let totalSteps = cycle.get('length');
      //   if (combo === 'mod+up') {
      //     if (stepIndex > 0) {
      //       self.swapArrayItems(cycle, stepIndex, stepIndex - 1);
      //       self.set('selectedStepIndex', stepIndex - 1);
      //     }
      //   } else {
      //     if (stepIndex < totalSteps - 1) {
      //       self.swapArrayItems(cycle, stepIndex, stepIndex + 1);
      //       self.set('selectedStepIndex', stepIndex + 1);
      //     }
      //   }
      // });
    },

    //#endregion
    //#region Computed properties
    // TODO: Make sure the cycle array is sorted based on Number.
    cycleList: Ember.computed.alias('model.settings.Cycles'),
    isDefaultCycle: Ember.computed('model', function () {
      return Ember.get(this, 'model.settings.Program.Misc.CycleRibonConfig_WX.0');
    }),

    //#endregion
    //#region Methods
    resetCycleNumbers() {
      console.time('resetCycleNumbers');

      if (this.hasCycleNumbers) {
        this.cycleList.forEach((cycle, index) => {
          // TODO: Check if cycle.Number is zero-based
          Ember.set(cycle, 'Number', index + 1);
        });
      }

      console.timeEnd('resetCycleNumbers');
    },

    swapArrayItems(array, index, direction) {
      // Used to re-order array items
      console.time('swapArrayItems');
      let secondIndex = direction === 'up' ? index - 1 : index + 1;
      let firstItem = [array[index]];
      let secondItem = [array[secondIndex]];

      if (direction == "up" && index > 0 || direction == 'down' && index < array.get('length') - 1) {
        array.replace(index, 1, secondItem).replace(secondIndex, 1, firstItem);
      }

      console.timeEnd('swapArrayItems');
      return array;
    },

    //#endregion
    //#region Actions
    actions: {
      // closeModal() {
      //   set(this, 'showCycleInfo', false)
      // },
      updateCyleOrder(cycle) {
        console.time('updateCyleOrder');
        let orderArray = Ember.get(this, 'model.settings.Program.Misc.CycleRibonConfig_WX');

        if (orderArray.length < 15) {
          let index = orderArray.indexOf(cycle.Number);

          if (index > -1) {
            orderArray.splice(index, 1);
          } else {
            orderArray.push(cycle.Number);
          }
        }

        Ember.set(this, 'model.settings.Program.Misc.CycleRibonConfig_WX', orderArray);
        console.timeEnd('updateCyleOrder');
      },

      // editCycleInfo(cycle) {
      //   set(this, 'showCycleInfo', true);
      // },
      moveCycle(cycle, direction) {
        console.time('moveCycle');
        let array = this.cycleList;
        let index = array.indexOf(cycle);
        this.swapArrayItems(array, index, direction);
        console.timeEnd('moveCycle');
      },

      addCycle() {
        // New for MC10
        let maxCycles = this.maxCycles;
        let list = this.cycleList;

        if (list.length < maxCycles) {
          let newItem = (0, _emberCopy.copy)(this.defaultCycle);
          let index = list.length;
          list.insertAt(index, newItem);
          this.send('selectCycle', index);
          this.resetCycleNumbers();
        } else {
          this.notifications.error('Unable to add a new cycle because this machine only supports 15 cycles. Please delete a cycle and try again.', {
            autoClear: false,
            clearDuration: 1000,
            cssClasses: 'quick-notification'
          });
        }
      },

      deleteCycle(cycle) {
        // New for MC10
        let cycleList = this.cycleList;

        if (cycleList.length === 0) {
          return;
        }

        let description = name || 'Cycle';

        if (confirm(`Delete selected ${description}?`)) {
          let index = cycleList.indexOf(cycle);
          cycleList.removeAt(index); // Now we need to set the next step that is active.

          let selected;
          let newIndex = cycleList.length > index ? index : index - 1;
          this.resetCycleNumbers();
          this.send('selectCycle', newIndex); // if (newIndex >= 0) {
          //   selected =  cycleList.objectAt(newIndex);
          //   set(this, 'selectedCycle', selected);
          //   set(this, 'selectedCycleIndex', newIndex);
          //   $(selected).focus();
          // }
        }
      },

      selectCycle(value, init) {
        // debugger; // eslint-disable-line
        console.time('selectCycle');

        if (event && event.target.tagName === "INPUT") {
          return true;
        } // Value can be the cycle object or the index of the cycle


        let index, cycle;

        if (value === undefined) {
          value = 0;
        }

        if (isNaN(value)) {
          // we have an object
          cycle = value;
          index = this.cycleList.indexOf(value);
        } else {
          // we have a number
          index = value;
          cycle = this.cycleList.objectAt(value);
        }

        Ember.set(this, 'selectedCycle', cycle);
        Ember.set(this, 'selectedCycleIndex', Number(index));
        localStorage.setItem('cycle', index || 0);
        console.timeEnd('selectCycle');
      },

      copyCycle(cycle) {
        console.time('copyCycle');
        Ember.set(this, 'clipboard.type', 'cycle');
        Ember.set(this, 'clipboard.contents', cycle);
        Ember.set(this, 'clipboard.title', 'Name will be here');
        this.notifications.success('Cycle copied', {
          autoClear: true,
          clearDuration: 1000,
          cssClasses: 'quick-notification'
        });
        console.timeEnd('copyCycle');
        return false;
      },

      pasteCycle(cycle) {
        console.time('pasteCycle');
        let type = Ember.get(this, 'clipboard.type');

        if (type !== 'cycle') {
          alert('No cycle is currently in your clipboard.');
          return false;
        }

        let clipboard = (0, _emberCopy.copy)(Ember.get(this, 'clipboard.contents'), true);
        let cycles = this.cycleList;
        let index = cycles.indexOf(cycle); // We need to preserve the Name and Number of the existing cycle

        let cycleName = cycles.objectAt(index).Name;
        let cycleNumber = cycles.objectAt(index).Number; // cycles.replace(index, 1, clipboard);

        cycles.removeAt(index);
        cycles.insertAt(index, clipboard); // // cycles.objectAt(index).pushObjects(clipboard);
        // cycles.objectAt(index).pushObjects(clipboard);

        let pastedCycle = cycles.objectAt(index);
        Ember.set(pastedCycle, 'Name', cycleName);
        Ember.set(pastedCycle, 'Number', cycleNumber); // this.send('calculateTime');

        this.send('selectCycle', index); // Add CP for time calculation

        this.applyTime();
        let notifications = this.notifications;
        this.notifications.success('Cycle pasted', {
          autoClear: true,
          clearDuration: 1000,
          cssClasses: 'quick-notification'
        });
        console.timeEnd('pasteCycle');
        return false;
      }

    } // mediumMods: alias('model.settings.Program.Price.ModifierOptions.MediumBundleMods_WX'),
    // heavyMods: alias('model.settings.Program.Price.ModifierOptions.HeavyBundleMods_WX'),
    // Get mods for this machine. TODO: See if we can DRY this up a little.
    // mods: computed('mediumMods.@each', 'heavyMods.@each', function() {
    //   let mediumMods = this.mediumMods;
    //   let heavyMods = this.heavyMods;
    //   let mediumExtraTime = get(mediumMods, 'Options.ExtraTime');
    //   let mediumWashTime =  mediumExtraTime ? get(mediumMods, 'ExtraWashAgitateTime') : 0;
    //   let mediumRinseTime =  mediumExtraTime ? get(mediumMods, 'ExtraRinseAgitateTime') : 0;
    //   let mediumMod1 = get(mediumMods, 'Options.Mod1');
    //   let mediumMod2 = get(mediumMods, 'Options.Mod2');
    //   let heavyExtraTime = get(heavyMods, 'Options.ExtraTime');
    //   let heavyWashTime =  heavyExtraTime ? get(heavyMods, 'ExtraWashAgitateTime') : 0;
    //   let heavyRinseTime =  heavyExtraTime ? get(heavyMods, 'ExtraRinseAgitateTime') : 0;
    //   let heavyMod1 = get(heavyMods, 'Options.Mod1');
    //   let heavyMod2 = get(heavyMods, 'Options.Mod2');
    //   let allMods = [
    //     {
    //       // No Modifiers
    //       name: "Light",
    //       mod1: false,
    //       mod2: false,
    //       additionalWashMinutes: 0,
    //       additionalRinseMinutes: 0,
    //       activeStates: ["AlwaysEnabled", "InactiveMod4", "InactiveMod3"]
    //     },
    //     {
    //       // Mod 1
    //       name: "Medium",
    //       mod1: get(mediumMods, 'Options.Mod1'),
    //       mod2: get(mediumMods, 'Options.Mod2'),
    //       additionalWashMinutes: mediumWashTime,
    //       additionalRinseMinutes: mediumRinseTime,
    //       activeStates: ['AlwaysEnabled', "InactiveMod4", "InactiveMod3"]
    //     },
    //     {
    //       // Mod 2
    //       name: "Heavy",
    //       mod1: get(heavyMods, 'Options.Mod1'),
    //       mod2: get(heavyMods, 'Options.Mod2'),
    //       additionalWashMinutes: heavyWashTime,
    //       additionalRinseMinutes: heavyRinseTime,
    //       activeStates: ['AlwaysEnabled', "InactiveMod4", "InactiveMod3"]
    //     },
    //     {
    //       // SpeedCycle
    //       name: "SpeedCycle",
    //       mod1: false,
    //       mod2: false,
    //       additionalWashMinutes: 0,
    //       additionalRinseMinutes: 0,
    //       activeStates: ["AlwaysEnabled"]
    //     }
    //   ];
    //   if (mediumMod1) {
    //     allMods[1].activeStates.push('ActiveMod1')
    //   }
    //   if (mediumMod2) {
    //     allMods[1].activeStates.push('ActiveMod2')
    //   }
    //   if (heavyMod1) {
    //     allMods[2].activeStates.push('ActiveMod1')
    //   }
    //   if (heavyMod2) {
    //     allMods[2].activeStates.push('ActiveMod2')
    //   }
    //   return allMods;
    // }),
    //#endregion

  });

  _exports.default = _default;
});