define("alliance-business-suite/routes/retail/categories", ["exports", "@babel/runtime/helpers/esm/defineProperty", "alliance-business-suite/routes/_secureRoute"], function (_exports, _defineProperty2, _secureRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class RetailCategoriesRoute extends _secureRoute.default {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "allowAccess", 'ITEM_EDIT');
    }

    model(params) {
      return Ember.RSVP.hash({
        items: this.store.findAll('item'),
        inventory: this.store.peekAll('inventory'),
        categories: this.store.findAll('item-category')
      });
    }

  }

  _exports.default = RetailCategoriesRoute;
});