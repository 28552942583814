define("alliance-business-suite/utils/machine-programming-custom-properties", ["exports", "alliance-business-suite/utils/fix-range"], function (_exports, _fixRange) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.updateCustomPropertyValues = _exports.initCustomPropertyValues = _exports.hasCustomProperty = void 0;

  // Returns true if:
  //  - the machine has a valid "custom" property
  //  - options is an array of options
  //  - propertyName and customPropertyName are the names of the properties as returned from the API
  const hasCustomProperty = args => {
    return Ember.computed(function () {
      const model = Ember.get(this, args.model);
      const options = Ember.get(this, args.options);
      const propertyName = Ember.get(this, args.propertyName);
      const customPropertyName = Ember.get(this, args.customPropertyName);
      if (model === undefined) throw new Error("Macro 'hasCustomProperty' requires a `model`.");
      if (options === undefined) throw new Error("Macro 'hasCustomProperty' requires `options`.");
      if (propertyName === undefined) throw new Error("Macro 'hasCustomProperty' requires `propertyName`."); // if (customPropertyName === undefined) throw new Error("Macro 'hasCustomProperty' requires `customPropertyName`.");

      if (customPropertyName === undefined) {
        return false;
      }

      const hasCustomOption = options.findBy('isCustom', true) !== undefined;
      const hasCustomProperty = `model.${customPropertyName}` !== undefined;
      const hasDifferentNames = propertyName != customPropertyName;
      return hasCustomOption && hasCustomProperty && hasDifferentNames;
    });
  };

  _exports.hasCustomProperty = hasCustomProperty;

  const initCustomPropertyValues = params => {
    const {
      context,
      model,
      options,
      propertyName,
      customPropertyName,
      limits
    } = params;
    const val = Ember.get(model, propertyName);
    const customVal = Ember.get(model, customPropertyName);
    let currentSelected = options.findBy('val', val); // Check if value is in selectOptions. If not, set to custom. (This should never happen unless we have bad data.)

    if (currentSelected === undefined) {
      currentSelected = options.findBy('isCustom', true);
      Ember.set(model, propertyName, currentSelected.val); // Set value to custom enum value
    }

    if (currentSelected && !currentSelected.isCustom) {
      // Not custom selected
      Ember.set(model, customPropertyName, currentSelected.val); // set custom value to match non-custom value

      Ember.set(context, 'lastPresetValue', currentSelected.presetValue ?? 0);
    }

    if (currentSelected && currentSelected.isCustom) {
      // Custom selected
      let min = null,
          max = null;

      if (limits !== undefined) {
        min = limits[0];
        max = limits[1];
      }

      Ember.set(model, customPropertyName, (0, _fixRange.fixRange)(customVal, min, max)); // set custom value to match non-custom valueInRange
    }
  };

  _exports.initCustomPropertyValues = initCustomPropertyValues;

  const updateCustomPropertyValues = params => {
    const {
      context,
      hasCustomProperty,
      model,
      selection,
      propertyName,
      customPropertyName
    } = params;
    let lastPresetValue = params.lastPresetValue;

    if (hasCustomProperty) {
      if (selection.isCustom) {
        // Set the customPropertyName to the lastPresetValue
        Ember.set(model, customPropertyName, lastPresetValue);
      } else {
        // Keep customPropertyName value matching the propertyName value
        Ember.set(context, 'lastPresetValue', selection.presetValue ?? 0);
        Ember.set(model, customPropertyName, selection.val);
      }
    }
  };

  _exports.updateCustomPropertyValues = updateCustomPropertyValues;
});