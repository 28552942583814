define("alliance-business-suite/services/access-nav-config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    title: "summary",
    link: "summary",
    allowAccess: "SUMMARY_VIEW_PAGE",
    parentLink: "summary",
    // isMobileOnly: true
    subnavTabs: [{
      title: "charts",
      link: "summary.charts",
      allowAccess: "SUMMARY_CHARTS_VIEW_PAGE",
      removeIf: ["nfc-disconnected"]
    }, {
      title: "reports",
      link: "summary.reports",
      allowAccess: "SUMMARY_REPORTS_VIEW_PAGE"
    }, {
      title: "subscriptions",
      link: "summary.subscriptions",
      allowAccess: "SUMMARY_SUBSCRIPTION_VIEW_PAGE",
      removeIf: ["nfc-disconnected"]
    }, {
      title: "alerts",
      link: "summary.alerts",
      allowAccess: "SUMMARY_ALERTS_VIEW_PAGE",
      removeIf: ["nfc-disconnected"]
    }]
  }, {
    title: "locations",
    link: "locations",
    allowAccess: "LOCATIONS_VIEW_PAGE",
    parentLink: "locations",
    subnavTabs: [{
      title: "list",
      link: "locations.list",
      allowAccess: "LOCATIONS_LIST_VIEW_PAGE"
    }, {
      title: "map",
      link: "locations.map",
      allowAccess: "LOCATIONS_MAP_VIEW_PAGE",
      removeIf: ["opl"]
    }, {
      title: "rewards",
      link: "locations.rewards",
      allowAccess: "LOCATIONS_REWARDS_VIEW_PAGE",
      removeIf: ["opl"]
    }, {
      title: "wash_alert",
      link: "locations.wash-alert",
      allowAccess: "LOCATIONS_WASH_ALERT_VIEW",
      allowBrandSlug: ["sq", "unimac"],
      // removeIf: ['opl'],
      whitelistOrgOverride: ["1110", "258338", "155942", "327233", "404286", "967952"]
    }, {
      title: "configure",
      link: "locations.configure",
      // allowAccess: 'LOCATIONS_LIST_VIEW_PAGE'
      allowAccess: "LOCATIONS_CONFIGURE_VIEW_PAGE"
    }]
  }, {
    title: "machines",
    link: "machines",
    allowAccess: "MACHINES_VIEW_PAGE",
    parentLink: "machines",
    subnavTabs: [{
      title: "list",
      link: "machines.list",
      allowAccess: "MACHINES_VIEW_PAGE"
    }, {
      title: "programs",
      link: "machines.programs",
      allowAccess: "MACHINES_PROGRAMS_VIEW"
    }, {
      title: "traceability",
      link: "machines.traceability",
      allowAccess: "MACHINES_PROGRAMS_VIEW_TRACEABILITY" // removeIf: ['brand-unimac']

    }]
  }, {
    title: "customers",
    // TODO: Is this gone?
    link: "customers",
    allowAccess: "CUSTOMERS_VIEW_PAGE",
    parentLink: "customers",
    removeIf: ["opl"],
    subnavTabs: [{
      title: "list",
      link: "customers.list",
      // Was index before custom security roles
      allowAccess: "CUSTOMERS_LIST_VIEW_PAGE"
    }, {
      title: "orders",
      link: "customers.orders",
      allowAccess: "CUSTOMERS_ORDERS_VIEW_PAGE",
      removeIf: ["opl", "trade", "not-retail", "is-demo"]
    }, {
      title: "membership_groups",
      link: "customers.memberships",
      allowAccess: "CUSTOMERS_COMMUNITY_GROUPS_VIEW",
      removeIf: ["opl"]
    }]
  }, {
    title: "employees",
    link: "employees",
    allowAccess: "EMPLOYEES_VIEW_PAGE",
    parentLink: "employees",
    subnavTabs: [{
      title: "list",
      link: "employees.list",
      allowAccess: "EMPLOYEES_LIST_VIEW_PAGE"
    }, {
      title: "security_roles",
      link: "employees.roles",
      allowAccess: "EMPLOYEES_SECURITY_ROLES_VIEW_PAGE"
    }, {
      title: "scheduling",
      link: "employees.schedules",
      allowAccess: "EMPLOYEES_SCHEDULE_VIEW_PAGE",
      removeIf: ["trade"]
    }, {
      title: "time-clock",
      link: "employees.time-clock",
      allowAccess: "TIME_CLOCK_VIEW_PAGE",
      removeIf: ["opl", "trade", "not-retail"]
    }, {
      title: "sales_goals",
      link: "employees.sales-goals",
      allowAccess: "EMPLOYEES_VIEW_PAGE",
      // TODO: Update this
      removeIf: ["not-retail", "opl"] // TODO: Confirm this

    }]
  }, {
    title: "crm",
    link: "crm.analytics",
    allowAccess: "CRM_VIEW_PAGE",
    parentLink: "crm",
    removeIf: ["not-crm", "opl"],
    subnavTabs: [{
      title: "analytics",
      link: "crm.analytics",
      allowAccess: "CRM_ANALYTICS_VIEW_PAGE"
    }, {
      title: "campaigns",
      link: "crm.campaigns",
      allowAccess: "CRM_CAMPAIGNS_VIEW_PAGE"
    }]
  }, {
    title: "retail",
    link: "retail",
    allowAccess: "RETAIL_CONTROL_VIEW_PAGE",
    parentLink: "retail",
    removeIf: ["not-retail", "opl", "is-demo"],
    subnavTabs: [{
      title: "products",
      link: "retail.list",
      allowAccess: "PRODUCTS_LIST_VIEW_PAGE"
    }, {
      title: "inventory",
      link: "retail.inventory",
      allowAccess: "PRODUCTS_LIST_VIEW_PAGE" // TODO: Is this correct?

    }, {
      title: "service_types",
      link: "retail.services-list",
      allowAccess: "SERVICES_VIEW_PAGE"
    }, {
      title: "services",
      link: "retail.services",
      allowAccess: "SERVICES_VIEW_PAGE"
    }, {
      title: "advanced",
      link: "retail.advanced",
      allowAccess: "RETAIL_POS_MANAGE_OPTIONS"
    }, {
      title: "taxes",
      link: "retail.taxes",
      allowAccess: "SERVICES_VIEW_PAGE"
    }]
  }, {
    title: "profile",
    link: "profile",
    allowAccess: "BILLING_VIEW",
    parentLink: "profile",
    isMobileOnly: true,
    subnavTabs: [{
      title: "account",
      link: "profile.index",
      allowAccess: "SUMMARY_CHARTS_VIEW_PAGE"
    }, {
      title: "billing",
      link: "profile.billing",
      allowAccess: "BILLING_VIEW",
      removeIf: ["international"]
    }]
  }];
  _exports.default = _default;
});