define("alliance-business-suite/templates/components/table/table-cell-intl-loop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SrknB38x",
    "block": "{\"symbols\":[\"s\",\"index\"],\"statements\":[[6,[37,5],[[32,0,[\"isEmpty\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"i\"],[14,\"aria-hidden\",\"true\"],[14,0,\"fa fa-ban\"],[14,5,\"opacity: .25\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,0,[\"splitString\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,0],[[32,1]],null]],[1,[30,[36,2],[[30,[36,1],[[32,2],[32,0,[\"indexLength\"]]],null],\",\"],null]],[2,\"\\n\"]],\"parameters\":[1,2]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"eq\",\"unless\",\"-track-array\",\"each\",\"if\"]}",
    "moduleName": "alliance-business-suite/templates/components/table/table-cell-intl-loop.hbs"
  });

  _exports.default = _default;
});