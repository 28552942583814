define("alliance-business-suite/templates/components/marketing/campaign-list-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Rgn2IQ0E",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,4],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"content-container container--placeholder\"],[12],[2,\"\\n    \"],[8,\"ui-loading-throb\",[],[[],[]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"content-container container--placeholder\"],[12],[2,\"\\n      \"],[10,\"h3\"],[12],[1,[30,[36,0],[\"no_campaigns_created\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"tables/table-interactive\",[],[[\"@isClickable\",\"@isLeftAlignLast\",\"@data\",\"@columns\",\"@displayDataChangedAction\"],[true,false,[32,0,[\"campaigns\"]],[32,0,[\"columns\"]],[32,0,[\"transitionToCampaignManage\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"campaigns\",\"unless\",\"isLoading\",\"liquid-if\"]}",
    "moduleName": "alliance-business-suite/templates/components/marketing/campaign-list-table.hbs"
  });

  _exports.default = _default;
});