define("alliance-business-suite/components/employees/schedule-block-bar", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@glimmer/component"], function (_exports, _applyDecoratedDescriptor2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  let EmployeesScheduleBlockBarComponent = (_dec = Ember._action, (_class = class EmployeesScheduleBlockBarComponent extends _component.default {
    get employeeName() {
      return this.args.employee || "Select employee";
    }

    updateTime(times) {
      let {
        year,
        monthNumber,
        dayMoment
      } = this.args.dateConfig;

      if (times.newStartTime) {
        this.args.updateItem('localStartDatetime', `${year}-${monthNumber}-${dayMoment}T${times.newStartTime}`);
      }

      if (times.newEndTime) {
        this.args.updateItem('localEndDatetime', `${year}-${monthNumber}-${dayMoment}T${times.newEndTime}`);
      }
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class.prototype, "updateTime", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "updateTime"), _class.prototype)), _class));
  _exports.default = EmployeesScheduleBlockBarComponent;
});