define("alliance-business-suite/helpers/cycle-times-tlw", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // export default Helper.helper(function([index, collection, ...rest]) {
  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),

    compute(_ref) {
      let [programTime, addedTime, sprayEnabled = false, ...rest] = _ref;
      let message = ""; // If the programTime is 0, we show 0 for the added time. Otherwise, show the addedTime.

      if (programTime > 0) {
        message = `${this.intl.t("abbrev_minute")} + ${addedTime} ${this.intl.t("abbrev_minute")}`;
      } else {
        message = `${this.intl.t("abbrev_minute")} + 0 ${this.intl.t("abbrev_minute")}`;
      } // If sprayEnabled, then add "or 4 min*" to the message.


      if (sprayEnabled) {
        message = `${message} ${this.intl.t("or")} 4 ${this.intl.t("abbrev_minute")}<sup>*</sup>`;
      }

      return Ember.String.htmlSafe(message);
    }

  });

  _exports.default = _default;
});