define("alliance-business-suite/serializers/report-subscription", ["exports", "alliance-business-suite/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    access: Ember.inject.service(),

    normalizeDataHack(data) {
      let rooms = Ember.get(data, 'relationships.rooms.data'); // if (!rooms) {
      //   console.log('NO ROOMS??')
      //   return
      // }

      let roomIds = rooms.map(room => {
        return room.id;
      });
      data.attributes.roomIds = roomIds;
    },

    serializeIntoHash(data, type, snapshot
    /* , options */
    ) {
      let userId = Ember.get(this, 'access.accessUser.id');
      let organizationId = Ember.get(this, 'access.accessOrganization.id');
      Object.assign(data, snapshot.record.getProperties('reportKey', 'frequency', 'timeframe'));
      data.rooms = snapshot.record.get('roomIds');
      data.user = {
        id: userId
      };
      data.organization = {
        id: organizationId
      };
    }

  });

  _exports.default = _default;
});