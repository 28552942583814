define("alliance-business-suite/components/form-ui/duration-field/utils/mod", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = mod;

  // wrapping mod
  function mod(n, m) {
    return (n % m + m) % m;
  }
});