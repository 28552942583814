define("alliance-business-suite/templates/summary/subscriptions/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HB5UQHjb",
    "block": "{\"symbols\":[],\"statements\":[[8,\"modal-dialog\",[],[[\"@animatable\",\"@targetAttachment\",\"@translucentOverlay\",\"@overlayClassNames\",\"@containerClassNames\"],[true,\"none\",true,\"ui-modal-dialog__overlay loading-modal\",[30,[36,1],[\"ui-modal-dialog\",[35,0]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[8,\"ui-loading-spinner\",[],[[],[]],null],[2,\"\\n\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"classNames\",\"array\"]}",
    "moduleName": "alliance-business-suite/templates/summary/subscriptions/loading.hbs"
  });

  _exports.default = _default;
});