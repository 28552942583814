define("alliance-business-suite/components/table/table-cell-cycle-count", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    access: Ember.inject.service(),
    store: Ember.inject.service(),
    tooltip: null,
    warningClass: '',
    lastCycleCount: Ember.computed('record.cycleCount.createdAt', function () {
      let createdAt = this.get('record.cycleCount.createdAt');
      return createdAt;
    }),
    userDateFormat: Ember.computed(function () {
      let userId = Ember.get(this, 'access.accessUser.id');
      let user = Ember.get(this, 'store').peekRecord('employee', userId);
      return user.get('dateFormat');
    }),
    hasWarning: Ember.computed('record.cycleCount.createdAt', function () {
      let createdAt = this.get('record.cycleCount.createdAt');
      let hasWarning = false;
      let a = (0, _moment.default)(createdAt);
      let b = (0, _moment.default)();
      let diffInMonths = b.diff(a, 'weeks');

      if (!createdAt) {
        this.set('tooltip', this.intl.t('no_previous_cycle_counts'));
      }

      if (diffInMonths > 0) {
        hasWarning = true;
        this.set('tooltip', this.intl.t('cycle_count_over_month_old'));
        this.set('warningClass', 'warning--orange');
      }

      if (diffInMonths >= 3) {
        hasWarning = true;
        this.set('tooltip', this.intl.t('cycle_count_over_three_months_old'));
        this.set('warningClass', 'warning--red');
      }

      return hasWarning;
    })
  });

  _exports.default = _default;
});