define("alliance-business-suite/components/form-ui/default-bundle", ["exports", "alliance-business-suite/mixins/init-property-names"], function (_exports, _initPropertyNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_initPropertyNames.default, {
    defaultBundle: Ember.computed('defaultMedium', 'defaultHeavy', function () {
      let defaultMedium = Ember.get(this, 'defaultMedium');
      let defaultHeavy = Ember.get(this, 'defaultHeavy');
      if (!defaultMedium && !defaultHeavy) return 1;
      if (defaultMedium && !defaultHeavy) return 2;
      if (!defaultMedium && defaultHeavy) return 3;
    }),
    actions: {
      updateValues(obj, prop, value) {
        let object = Ember.get(this, 'object');

        switch (value) {
          case 1:
            // None
            // Do nothing
            Ember.get(this, 'update')(object, Ember.get(this, 'properties.defaultMedium.key'), false);
            Ember.get(this, 'update')(object, Ember.get(this, 'properties.defaultHeavy.key'), false);
            break;

          case 2:
            // Medium
            Ember.get(this, 'update')(object, Ember.get(this, 'properties.optionMedium.key'), true);
            Ember.get(this, 'update')(object, Ember.get(this, 'properties.defaultMedium.key'), true);
            Ember.get(this, 'update')(object, Ember.get(this, 'properties.defaultHeavy.key'), false);
            break;

          case 3:
            // Heavy
            Ember.get(this, 'update')(object, Ember.get(this, 'properties.optionHeavy.key'), true);
            Ember.get(this, 'update')(object, Ember.get(this, 'properties.defaultMedium.key'), false);
            Ember.get(this, 'update')(object, Ember.get(this, 'properties.defaultHeavy.key'), true);
            break;
        }
      }

    }
  });

  _exports.default = _default;
});