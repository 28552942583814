define("alliance-business-suite/components/alert/alert", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#liquid-if this.showAlert}}
  <div class="alert-preview alert-{{this.alertTypeClass}}">
  
    {{fa-icon this.alertIconName class="alert-icon"}}
  
    <div class="alert-container">
        <div>
          <p class="alert-header">{{this.header}}</p>
          <p class="alert-text">{{this.body}}</p>
        </div>
        <div class="alert-button-container">
          {{#if this.hasButton}}
            {{#if this.isNotification}}
              <LinkTo @route="notifications" class="alert-button" @tagName="a">{{this.linkText}}</LinkTo>
            {{else}}
              <a href="{{@data.link.url}}" target="_blank" rel="noopener noreferrer" class="alert-button">{{this.linkText}}</a>
            {{/if}}
          {{/if}}
        </div>
    </div>
      <a class="alert-close" {{on "click" this.hideAlert}}>{{fa-icon "times" class="alert-icon-close"}}</a>
  </div>
  {{/liquid-if}}
  */
  {
    "id": "p6ambbTH",
    "block": "{\"symbols\":[\"@data\"],\"statements\":[[6,[37,2],[[32,0,[\"showAlert\"]]],null,[[\"default\"],[{\"statements\":[[10,\"div\"],[15,0,[31,[\"alert-preview alert-\",[32,0,[\"alertTypeClass\"]]]]],[12],[2,\"\\n\\n  \"],[10,\"i\"],[15,0,[31,[\"fa fa-\",[32,0,[\"alertIconName\"]],\" alert-icon\"]]],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"alert-container\"],[12],[2,\"\\n      \"],[10,\"div\"],[12],[2,\"\\n        \"],[10,\"p\"],[14,0,\"alert-header\"],[12],[1,[32,0,[\"header\"]]],[13],[2,\"\\n        \"],[10,\"p\"],[14,0,\"alert-text\"],[12],[1,[32,0,[\"body\"]]],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"alert-button-container\"],[12],[2,\"\\n\"],[6,[37,0],[[32,0,[\"hasButton\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,0],[[32,0,[\"isNotification\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"            \"],[8,\"link-to\",[[24,0,\"alert-button\"]],[[\"@route\",\"@tagName\"],[\"notifications\",\"a\"]],[[\"default\"],[{\"statements\":[[1,[32,0,[\"linkText\"]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"            \"],[10,\"a\"],[15,6,[31,[[32,1,[\"link\",\"url\"]]]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[14,0,\"alert-button\"],[12],[1,[32,0,[\"linkText\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n  \"],[13],[2,\"\\n    \"],[11,\"a\"],[24,0,\"alert-close\"],[4,[38,1],[\"click\",[32,0,[\"hideAlert\"]]],null],[12],[10,\"i\"],[14,0,\"fa fa-times alert-icon-close\"],[14,\"aria-hidden\",\"true\"],[12],[13],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"on\",\"liquid-if\"]}",
    "moduleName": "alliance-business-suite/components/alert/alert.hbs"
  });

  function isEmpty(value) {
    return value == undefined || value == null || value.length == 0;
  }

  let UiAlertsAlertComponent = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, (_class = class UiAlertsAlertComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "hasNotification", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "data", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "showAlert", _descriptor3, this);
      (0, _defineProperty2.default)(this, "optionsLevel", [{
        value: "GENERAL",
        name: "general",
        text: "GENERAL",
        icon: "info-circle",
        color: "#4b85fa",
        background: "#c7def2"
      }, {
        value: "WARNING",
        name: "warning",
        text: "WARNING",
        icon: "exclamation-triangle",
        color: "#e7b800",
        background: "#eff1c1"
      }, {
        value: "URGENT",
        name: "urgent",
        text: "URGENT",
        icon: "times-circle",
        color: "#e62502",
        background: "#efc5c5"
      }]);
      (0, _defineProperty2.default)(this, "optionsActive", [{
        value: true,
        text: "Active"
      }, {
        value: false,
        text: "Deactivated"
      }]);
    }

    get alertType() {
      return this.optionsLevel.findBy("value", this.data?.level);
    }

    get alertTypeClass() {
      return this.alertType?.name;
    }

    get alertIconName() {
      return this.alertType?.icon;
    }

    get header() {
      return this.data?.header;
    }

    get body() {
      return this.data?.body;
    }

    get hasButton() {
      return !isEmpty(this.data?.link.text);
    }

    get linkText() {
      return this.data?.link.text;
    }

    get isNotification() {
      return this.hasButton && !isEmpty(this.data?.notification.body);
    }

    hideAlert(e) {
      if (e.metaKey) {
        if (confirm("Close all alerts?")) {
          const collection = document.getElementsByClassName("alert-close");

          for (let i = 0; i < collection.length; i++) {
            collection[i].click();
          }
        }
      } else {
        this.showAlert = false;
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "hasNotification", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.data?.link?.url?.length && this.model.link.url.length > 0 ? false : true;
    }
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "data", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.data;
    }
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "showAlert", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "hideAlert", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "hideAlert"), _class.prototype)), _class));
  _exports.default = UiAlertsAlertComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, UiAlertsAlertComponent);
});