define("alliance-business-suite/themes/default-theme", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    colors: ['#559fed', '#e2effb', 'rgba(228, 239, 250, .3)'],
    chart: {
      backgroundColor: {
        linearGradient: {
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1
        },
        stops: [[0, '#fafcfe'], [1, '#fafcfe']]
      },
      style: {
        fontFamily: '\'Lato\', sans-serif'
      },
      plotBorderColor: '#606063'
    },
    title: {
      style: {
        color: '#4f6071',
        textTransform: 'uppercase',
        fontSize: '20px'
      }
    },
    subtitle: {
      style: {
        color: '#E0E0E3',
        textTransform: 'uppercase'
      }
    },
    xAxis: {
      gridLineColor: '#707073',
      labels: {
        style: {
          color: '#354052'
        }
      },
      crosshair: {
        color: '#dee5ef',
        zIndex: 2,
        width: 4
      },
      lineColor: '',
      minorGridLineColor: '#505053',
      tickColor: '',
      title: {
        style: {
          color: '#9FA9BA'
        }
      }
    },
    yAxis: {
      gridLineColor: '',
      labels: {
        style: {
          color: '#354052'
        }
      },
      lineColor: '#707073',
      minorGridLineColor: '#505053',
      tickColor: '#707073',
      tickWidth: 1,
      title: {
        style: {
          color: '#9FA9BA'
        }
      }
    },
    tooltip: {
      backgroundColor: '',
      style: {
        color: '#F0F0F0'
      }
    },
    plotOptions: {
      series: {
        dataLabels: {
          color: '#000000'
        },
        marker: {
          fillColor: 'white',
          lineColor: '#4592eb',
          width: 5,
          radius: 4,
          zIndex: 11
        }
      },
      boxplot: {
        fillColor: '#505053'
      },
      candlestick: {
        lineColor: 'white'
      },
      errorbar: {
        color: 'white'
      }
    },
    legend: {
      itemStyle: {
        color: '#E0E0E3'
      },
      itemHoverStyle: {
        color: '#FFF'
      },
      itemHiddenStyle: {
        color: '#606063'
      }
    },
    credits: {
      style: {
        color: '#666'
      }
    },
    labels: {
      style: {
        color: '#707073'
      }
    },
    drilldown: {
      activeAxisLabelStyle: {
        // color: '#F0F0F3'
        color: '#354052',
        textDecoration: 'none'
      },
      activeDataLabelStyle: {
        // color: '#F0F0F3'
        color: '#354052',
        textDecoration: 'none'
      }
    },
    //  navigation: {
    //     buttonOptions: {
    //        symbolStroke: '#DDDDDD',
    //        theme: {
    //           fill: '#505053'
    //        }
    //     }
    //  },
    // scroll charts
    rangeSelector: {
      buttonTheme: {
        fill: '',
        stroke: '#000000',
        style: {
          color: '#b6c0cb'
        },
        states: {
          hover: {
            fill: '#707073',
            stroke: '#000000',
            style: {
              color: '4491ea'
            }
          },
          select: {
            fill: '#000003',
            stroke: '#000000',
            style: {
              color: '#4491ea'
            }
          }
        }
      },
      inputBoxBorderColor: 'transparent',
      inputStyle: {
        backgroundColor: 'white',
        color: 'silver'
      },
      labelStyle: {
        color: 'silver'
      }
    },
    navigator: {
      handles: {
        backgroundColor: '#666',
        borderColor: '#AAA'
      },
      outlineColor: '#CCC',
      maskFill: 'rgba(255,255,255,0.1)',
      series: {
        color: '#7798BF',
        lineColor: '#A6C7ED'
      },
      xAxis: {
        gridLineColor: '#505053'
      }
    },
    // removes 'Zoom from the top right buttons'
    lang: {
      rangeSelectorZoom: ''
    },
    scrollbar: {
      barBackgroundColor: '#808083',
      barBorderColor: '#808083',
      buttonArrowColor: '#CCC',
      buttonBackgroundColor: '#606063',
      buttonBorderColor: '#606063',
      rifleColor: '#FFF',
      trackBackgroundColor: '#404043',
      trackBorderColor: '#404043'
    },
    // special colors for some of the
    legendBackgroundColor: 'rgba(0, 0, 0, 0.5)',
    background2: '#505053',
    dataLabelsColor: '#B0B0B3',
    textColor: '#C0C0C0',
    contrastTextColor: '#F0F0F3',
    maskColor: 'rgba(255,255,255,0.3)'
  };
  _exports.default = _default;
});