define("alliance-business-suite/serializers/machine-program", ["exports", "lodash", "alliance-business-suite/serializers/application"], function (_exports, _lodash, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function tryNumber(s) {
    // We're only working with Strings
    if (typeof s !== "string") {
      return s;
    } // If this is a zero-length string, we're done.


    if (s === "") {
      return s;
    }

    let value = _lodash.default.toNumber(s);

    return isNaN(value) ? s : value;
  }

  var _default = _application.default.extend({
    /**
     * Hacks a JSON API include object returned within a JSON API document to
     * confirm to what is required to make the endpoint work property with Ember
     * Data and within this application.
     *
     * @param  {object} include An included data object from a JSON API document
     *
     * @return {object} The data object to use for this include when passing it
     *                  to the store; or undefined if this include should be
     *                  ignored.
     *
     * @protected
     */
    normalizeInclude(include) {
      return ['role-types', 'roles', 'user-role-types', 'user-roles'].includes(include.type) ? undefined : include;
    },

    serialize() {
      let json = this._super(...arguments); // if (json && json.data && json.data.attributes && json.data.attributes.settings) {


      if (_lodash.default.has(json, 'data.attributes.settings')) {
        /**
         * The `traverse` function will check every property in the `settings` object
         * and convert any strings that are numeric values into numbers. Why? Because
         * `input` elements will return numeric values as strings, and we don't want
         * to send those to the API as strings.
         *
         * THIS MAY MUTATE DATA BEFORE IT'S SENT TO THE API!
         */
        let traverse = (key, parent) => {
          let value = parent[key]; // not value === json.data.attributes.settings
          // TODO: Remove this section after 2020-08-01. It is a temporary fix for bad data in the Messages object.
          // Messages only contains strings. If a zero is found, change it to a zero-length string.

          let propsToFind = ['ApproachMessage', 'Bundle1Description', 'Bundle1Name', 'Bundle2Description', 'Bundle2Name', 'Modifier1Description', 'Modifier1Name', 'Modifier2Description', 'Modifier2Name'];

          if (moment().isBefore('2020-08-01') && propsToFind.includes(key)) {
            console.log('Checking for zeros', value);

            if (value == 0) {
              Ember.set(parent, key, "");
            }
          } else // End of section to be removed after 2020-08-01
            if (typeof value !== 'object') {
              value = tryNumber(value);

              if (value === "") {
                console.log('Found zero-length string in key %s. No changes made.', key);
              }

              if (value !== parent[key]) {
                if (["customMessage1", "customMessage2"].includes(key)) {
                  console.log("Skipping", key);
                } else {
                  console.log('converting', key, 'of', parent[key], 'to', value);
                  Ember.set(parent, key, value);
                }
              }
            } else {
              // `value` is an object/array
              if (key === 'VendPrices') {
                value = value.map(val => {
                  return tryNumber(val);
                });
                console.log('converting', key, 'of', parent[key], 'to', value);
                Ember.set(parent, key, value);
              } else {
                // Recursively check values
                Object.entries(value).forEach(_ref => {
                  let [key, keysValue] = _ref;

                  if (keysValue != null && key != null) {
                    traverse(key, value);
                  }
                });
              }
            }
        };

        console.groupCollapsed('serializer conversions');
        traverse('settings', json.data.attributes);
        console.groupEnd('serializer conversions');
      }

      return json;
    }

  });

  _exports.default = _default;
});