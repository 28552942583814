define("alliance-business-suite/components/machines/programs/cycles/steps-mgd-opl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    updateTimeObserver: Ember.observer('model.{Minutes,Seconds,ActiveState,Label}', function () {
      // Yes, observers are evil but it makes sense to use one here.
      Ember.run.once(this, 'updateTime');
    }),
    machineAuditType: Ember.computed.alias('machineProgram._machineAuditType'),
    // Alias properties for cooldownWaterValvesTemperature CP
    HotFillValveOn: Ember.computed.alias('model.HotFillValveOn'),
    ColdFillValveOn: Ember.computed.alias('model.ColdFillValveOn'),
    // Alias properties for waterValvesTempeature CP
    HotFillOn: Ember.computed.alias('model.HotFillEnabled'),
    ColdFillOn: Ember.computed.alias('model.ColdFillEnabled'),
    TemperatureControlledFill: Ember.computed.alias('model.TempControlledFillEnabled'),
    allProperties: {
      Type: "Type",
      Label: "Label",
      // Speed: "SpeedType",
      // CustomSpeed: "CustomSpeed",
      Speed: "CustomSpeed",
      AuxFill: "AuxFillEnabled",
      HotFill: "HotFillEnabled",
      ColdFill: "ColdFillEnabled",
      FillValveSet: "FillValveEnabled",
      SprayValveSet: "SprayValveEnabled",
      ExtraFillInlet: null,
      TempControlledFill: "TempControlledFillEnabled",
      Minutes: "Minutes",
      Seconds: "Seconds",
      PauseSec: "PauseTime",
      RotateSec: "RotateTime",
      __FillTubValveSet: "FillTubValveEnabled",
      // Not currently used
      CoolRate: "CoolDownRate",
      WaterLevel: "CustomWaterLevel",
      // WaterLevel: "WaterLevel",
      // CustomWaterLevel: "CustomWaterLevel",
      ActiveState: "ActiveState",
      Temperature: "Temperature",
      FlushoutEnabled: "FlushOut",
      AllowTempOverride: "ColdHardFillEnabled",
      ReversingDisabled: "NonReversing",
      AuxSupplyA: "AuxSupply1Enabled",
      AuxSupplyB: "AuxSupply2Enabled",
      AuxSupplyC: "AuxSupply3Enabled",
      AuxSupplyD: "AuxSupply4Enabled",
      AuxSupplyE: "AuxSupply5Enabled",
      AuxSupplyF: "AuxSupply6Enabled",
      AuxSupplyG: "AuxSupply7Enabled",
      AuxSupplyH: "AuxSupply8Enabled",
      AuxOutputsI: "AuxOutput1Enabled",
      AuxOutputsJ: "AuxOutput2Enabled",
      AuxOutputsK: "AuxOutput3Enabled",
      AuxOutputsL: "AuxOutput4Enabled",
      AuxOutputsM: "AuxOutput5Enabled",
      AuxOutputsN: "AuxOutput6Enabled",
      JrAuxOutputsI: "JrAuxOutput1Enabled",
      JrAuxOutputsJ: "JrAuxOutput2Enabled",
      JrAuxOutputsK: "JrAuxOutput3Enabled",
      JrAuxOutputsL: "JrAuxOutput4Enabled",
      JrAuxOutputsM: "JrAuxOutput5Enabled",
      JrAuxOutputsN: "JrAuxOutput6Enabled",
      HeatOption: "HeatOption",
      HeatRate: "HeatRate",
      SupplyDelay: "SupplyDelaySeconds",
      ShutoffCondition: "ShutoffCondition",
      ReuseFillA: "ResueDrainPumpAOn",
      ReuseFillB: "ResueDrainPumpBOn",
      ExternalDrain: "ExternalDrainOn",
      Refill: "RefillEnabled",
      MainDrain1: "MainDrain1",
      MainDrain2: "MainDrain2",
      PulseDrain: "PulseDrainOn",
      PHChemFlush: "ChemFlushSeconds",
      ChemFlush: "ChemFlush",
      Compartment1: "Compartment1Enabled",
      Compartment2: "Compartment2Enabled",
      Compartment3: "Compartment3Enabled",
      Compartment4: "Compartment4Enabled",
      AlwaysRotate: "RotateEntireStepEnabled",
      SprayValveTime: "ValveTimeOn",
      PauseValveTime: "ValveTimeOff",
      OptiSprayTime: "OPTIsprayTime",
      HotSpray: "HotSprayEnabled",
      ColdSpray: "ColdSprayEnabled",
      Pattern: "AudioPatternType",
      UnlockDoor: "DrainAndUnlock"
    },
    stepControls: Ember.computed('selectedStep.Type', 'selectedStep', function () {
      let stepId = Ember.get(this, 'selectedStep.Type');
      let allStepSettings = Ember.get(this, 'allStepSettings');
      let thisStepSettings = allStepSettings.findBy("id", stepId); // return thisStep.controls;

      return thisStepSettings;
    }),
    stepChange: Ember.observer('selectedStep', function () {
      // Scroll to top when step changes
      // TODO: Make then event-based so it's not fragile.
      document.getElementById('split3').scrollTop = 0;
    }),
    allStepSettings: Ember.computed('object.Type', function () {
      let output = [{
        id: 1,
        stepName: "ReuseFill",
        controls: ["active-label", "step-time", "water-level-overflow", "water-valves", "heater-options-temperature", "speed", "aux-output", "reuse"],
        validations: {
          stepMinutes: [0, 255],
          stepSeconds: [0, 59]
        },
        properties: { ...this.allProperties,
          ...{
            ReuseFillA: "ReuseFillAOn",
            ReuseFillB: "ReuseFillBOn",
            Temperature: "Temperature"
          }
        }
      }, {
        id: 2,
        stepName: "Fill",
        controls: ["active-label", "step-time", "water-level-overflow", "water-valves", "heater-options-temperature", "speed", "aux-output"],
        validations: {
          stepHours: [0, 255],
          stepMinutes: [0, 255],
          stepSeconds: [0, 59]
        },
        properties: { ...this.allProperties,
          ...{
            Temperature: "Temp"
          }
        }
      }, {
        id: 3,
        stepName: "Supply",
        controls: ["active-label", "step-time", "delay-time", "water-valves", "internal-external-supply", "aux-supply", "aux-output-jr"],
        validations: {
          stepMinutes: [0, 255],
          stepSeconds: [0, 59]
        },
        properties: this.allProperties
      }, {
        id: 4,
        stepName: "Agitate",
        controls: ["active-label", "step-time", "heater-options-rate", "speed", "drain-refill", "aux-supply", "aux-output-jr", "reuse"],
        validations: {
          stepMinutes: [0, 255],
          stepSeconds: [0, 59]
        },
        properties: this.allProperties
      }, {
        id: 5,
        stepName: "Soak",
        controls: ["active-label", "step-time", "heater-options-rate", "speed", "drain-refill", "aux-supply", "aux-output-jr"],
        validations: {
          stepHours: [0, 255],
          stepMinutes: [0, 59]
        },
        properties: { ...this.allProperties,
          ...{
            HeatOption: "HeatOption",
            HeatRate: "HeatRate",
            ChemFlush: null
          }
        }
      }, {
        id: 6,
        stepName: "Cooldown",
        controls: ["active-label", "cooldown-type", "cooldown-options", "water-valves", "speed", "aux-supply", "aux-output-jr"],
        validations: {},
        properties: { ...this.allProperties,
          ...{
            CoolRate: "CoolDownRate",
            AntiWrinkle: "IsAntiWrinkle",
            PlumbingCode: "IsPlumbingCode",
            Temperature: "Temp"
          }
        }
      }, {
        id: 7,
        stepName: "Drain",
        controls: ["active-label", {
          type: "speed",
          display: "distribution"
        }, "drain", "reuse", "aux-supply", "aux-output-jr"],
        validations: {
          rotateTime: [3, 255],
          pauseTime: [3, 255],
          requireDrain: true
        },
        properties: { ...this.allProperties,
          ...{
            ReversingDisabled: "NoReversingEnabled"
          }
        }
      }, {
        id: 8,
        stepName: "Spray Rinse Extract",
        controls: ["active-label", "step-time", "speed", "drain", "spray-valves", "aux-supply", "aux-output-jr", "reuse"],
        validations: {
          stepMinutes: [0, 9],
          stepSeconds: [0, 59],
          rotateTime: [3, 255],
          pauseTime: [3, 255],
          requireDrain: true
        },
        properties: this.allProperties
      }, {
        id: 9,
        stepName: "DrainExtract",
        controls: ["active-label", "step-time", "speed", "drain", "reuse", "aux-supply", "aux-output-jr"],
        validations: {
          stepMinutes: [0, 9],
          stepSeconds: [0, 59],
          requireDrain: true
        },
        properties: this.allProperties
      }, {
        id: 10,
        stepName: "AudioSignal",
        controls: ["active-label", "audio-signal", "step-time", "aux-supply", "aux-output-jr"],
        validations: {
          stepMinutes: [0, 2],
          stepSeconds: [0, 59]
        },
        properties: this.allProperties
      }, {
        id: 11,
        stepName: "Hold",
        controls: ["active-label", "audio-signal", "step-time", "drain", "reuse", "aux-supply", "aux-output", "door"],
        validations: {
          stepMinutes: [0, 2],
          stepSeconds: [0, 59]
        },
        properties: { ...this.allProperties,
          ...{
            AuxOutputsI: "JrAuxOutput1Enabled",
            AuxOutputsJ: "JrAuxOutput2Enabled",
            AuxOutputsK: "JrAuxOutput3Enabled",
            AuxOutputsL: "JrAuxOutput4Enabled",
            AuxOutputsM: "JrAuxOutput5Enabled",
            AuxOutputsN: "JrAuxOutput6Enabled"
          }
        }
      }, {
        id: 12,
        stepName: "Wash Spray",
        controls: ["active-label", "step-time", "speed", "drain-refill", "spray-valves", "aux-supply", "aux-output-jr"],
        validations: {
          stepMinutes: [0, 9],
          stepSeconds: [0, 59]
        },
        properties: this.allProperties
      }];
      return output;
    })
  });

  _exports.default = _default;
});