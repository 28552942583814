define("alliance-business-suite/components/products/org-services-list-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // session: service(),
    // store: service(),
    // access: service(),
    intl: Ember.inject.service(),
    showComponentFooter: false,
    showColumnsDropdown: false,
    useFilteringByColumns: false,
    showGlobalFilter: false,
    useNumericPagination: false,
    doFilteringByHiddenColumns: false,
    filteringIgnoreCase: false,
    multipleColumnsSorting: false,
    showPageSize: false,
    pageSize: 100,
    collapseNumPaginationForPagesCount: 1,
    columns: Ember.computed(function () {
      return [{
        propertyName: 'description',
        title: `${this.intl.t('name')}`
      }, {
        propertyName: 'type',
        title: `${this.intl.t('type')}`
      }, {
        propertyName: 'systemDefault',
        title: '',
        component: 'table/table-cell-button-lock-edit',
        params: ['record'],
        action: this.toggleEditModal,
        buttonClass: 'button button--secondary button--small',
        buttonContent: '<i class="fa fa-cog" aria-hidden="true"></i>'
      }];
    })
  });

  _exports.default = _default;
});