define("alliance-business-suite/mixins/machines/programs/config/led", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    vendPriceDisplayOverrideText: Ember.computed({
      get(key) {
        let dec = [];
        [1, 2, 3, 4, 5, 6].forEach(index => {
          dec.push(Ember.get(this, `model.settings.Program.Miscellaneous.VendPriceDisplayOverrideDigit${index}`) || 0);
        });
        return dec;
      },

      set(key, value) {
        [1, 2, 3, 4, 5, 6].forEach((item, index) => {
          Ember.set(this, `model.settings.Program.Miscellaneous.VendPriceDisplayOverrideDigit${item}`, value[index]);
        });
      }

    })
  });

  _exports.default = _default;
});