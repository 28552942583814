define("alliance-business-suite/components/inventory/item-list-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    access: Ember.inject.service(),
    router: Ember.inject.service(),
    intl: Ember.inject.service(),
    classNames: ['inventory-list-table'],
    intlPrefix: 'components.crm',
    tagName: '',
    columns: Ember.computed(function () {
      return [{
        propertyName: 'imageUrl',
        title: '',
        component: 'table/table-cell-image',
        className: 'align-center',
        disableSorting: true
      }, {
        propertyName: 'description',
        title: this.intl.t('name'),
        subtitle: 'catalogName',
        component: 'table/table-cell-subtitle'
      }, {
        propertyName: 'itemCategory.name',
        title: this.intl.t('category') // subtitle: 'catalogName',
        // component: 'table/table-cell-subtitle'

      }, {
        propertyName: 'totalInventory',
        title: this.intl.t('total_inventory'),
        component: 'table/table-cell-quantity-amount'
      }, {
        propertyName: 'totalInventory',
        alertThreshold: 'itemGuideline.reorderBelowLevel',
        title: this.intl.t('inventory_alert'),
        component: 'table/table-cell-guideline-alert',
        isLocation: this.isLocation
      }];
    }),

    transitionToItemDetail(record, context) {
      let recordId = record.selectedItems[0].get('id');
      context.router.transitionTo('retail.manage', recordId);
    }

  });

  _exports.default = _default;
});