define("alliance-business-suite/utils/computed-find-options", ["exports", "alliance-business-suite/utils/show-features"], function (_exports, _showFeatures) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.findOptions = void 0;

  const findOptionsByAttribute = (options, machineProgram) => {
    // We must have a machineProgram to use the _machineAttributes property.
    if (machineProgram === undefined) {
      console.error("MachineProgram is required for find-options.");
      return;
    }

    let items; // If we have an array without an `options` property, we can assume it is an array of option items

    if (options[0].options === undefined) {
      items = options;
    } else {
      let found = options.find(optionsItem => {
        return (0, _showFeatures.showMachineFeature)({
          ifMachine: optionsItem.ifMachine,
          notMachine: optionsItem.notMachine,
          machineProgram: machineProgram
        });
      });
      items = found?.options;
    } // If no match is found, return the first object with default property of true.


    if (items === undefined) {
      let found = options.find(item => item.default && item.default === true);
      items = found?.options;
    } // If nothing is found, return an error message.


    if (items === undefined) {
      console.error("Error finding option items.");
      return;
    } // Check each item to be returned, checking for conditionals


    return items.filter(optionItem => {
      return (0, _showFeatures.showMachineFeature)({
        ifMachine: optionItem.ifMachine,
        notMachine: optionItem.notMachine,
        machineProgram
      });
    });
  };

  const findOptions = options => {
    return Ember.computed('options', 'machineProgram._machineAttributes.[]', function () {
      return findOptionsByAttribute(options, this.machineProgram);
    });
  };

  _exports.findOptions = findOptions;
});