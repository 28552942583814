define("alliance-business-suite/components/dashboard/charts/daily-revenue/daily-revenue-simple", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "alliance-business-suite/utils/currency-format-util", "alliance-business-suite/utils/summary-util"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _currencyFormatUtil, _summaryUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <HighCharts
    @mode={{mode}}
    @content={{chartData}}
    @chartOptions={{chartOptions}}
    @theme={{@theme}} as |chart|>
    <Charts::ChartWithKpiYield
      @hasKPI={{hasKPI}}
      @kpi={{temp_kpi}}
      @groupRoomKPI={{temp_groupRoomKPI}}
      @chart={{chart}} />
  </HighCharts>
  
  */
  {
    "id": "FIOy4eyq",
    "block": "{\"symbols\":[\"chart\",\"@theme\"],\"statements\":[[8,\"high-charts\",[],[[\"@mode\",\"@content\",\"@chartOptions\",\"@theme\"],[[34,0],[34,1],[34,2],[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"charts/chart-with-kpi-yield\",[],[[\"@hasKPI\",\"@kpi\",\"@groupRoomKPI\",\"@chart\"],[[34,3],[34,4],[34,5],[32,1]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"mode\",\"chartData\",\"chartOptions\",\"hasKPI\",\"temp_kpi\",\"temp_groupRoomKPI\"]}",
    "moduleName": "alliance-business-suite/components/dashboard/charts/daily-revenue/daily-revenue-simple.hbs"
  });

  let DashboardChartsDailyRevenueDailyRevenueSimpleComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, (_class = class DashboardChartsDailyRevenueDailyRevenueSimpleComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "canAccess", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor3, this);
      (0, _defineProperty2.default)(this, "mode", 'StockChart');
      (0, _initializerDefineProperty2.default)(this, "dailyRevenueData", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "chartTotal", _descriptor5, this);
      (0, _defineProperty2.default)(this, "highestValue", 0);
      (0, _defineProperty2.default)(this, "skipNextTooltipFormatCall", false);
    }

    // these two "temp" are because we havent converted ChartsWithKpiYield to octane components yet
    // when they are converted we will be able to just pass in @revKpi
    get temp_kpi() {
      return this.args.revKPI;
    } // these two "temp" are because we havent converted ChartsWithKpiYield to octane components yet
    // when they are converted we will be able to just pass in @groupRevKPI


    get temp_groupRoomKPI() {
      return this.args.groupRevKPI;
    }

    get hasKPI() {
      if (this.canAccess.canAccess('SUMMARY_CHARTS_VIEW_DAILY_REVENUE_KPI')) {
        return true;
      }
    }

    generateDailyRevenueData(sourceKey) {
      let zone = moment.tz.guess();
      let currentLocale = _summaryUtil.momentLocaleMap[this.intl.locale[0]];
      let monthsShort = moment.localeData(currentLocale).monthsShort();

      let monthsLong = moment.localeData(currentLocale)._months;

      let weekDays = moment.localeData(currentLocale)._weekdays;

      Highcharts.setOptions({
        time: {
          useUTC: false // getTimezoneOffset(timestamp) {
          //   let timezoneOffset = -moment.tz(timestamp, zone).utcOffset();
          //   return timezoneOffset;
          // }

        },
        lang: {
          months: monthsLong,
          weekdays: weekDays,
          shortMonths: monthsShort
        }
      });
      let daily = [];
      let {
        totals
      } = this.args.results;

      if (!totals) {
        return [];
      }

      totals.forEach(day => {
        let value = day.revenue / this.access.currencySubunit;

        if (value > this.highestValue) {
          this.highestValue = value;
        }

        daily.push([moment(day.reportDate).valueOf(), value]);
      });
      return daily;
    } // computed dailyRevenueOptions.[]', 'revKPI'


    get chartOptions() {
      let self = this;
      let revKPI = this.args.revKPI;
      return {
        chart: {
          marginTop: 10,
          spacingRight: 0,
          spacingLeft: 0,
          events: {
            load: e => {
              this.args.isLoaded();
            },
            render: e => {
              Ember.run.later(() => {
                if (!this.isDestroyed) {
                  e.target.reflow();
                }
              }, 220); // about how long it takes for the liquid animation to finish
            }
          }
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          series: {
            pointStart: Date.UTC(2010, 0, 1)
          }
        },
        rangeSelector: {
          inputEnabled: false,
          buttonPosition: {
            y: 300
          },
          selected: 10,
          buttons: [{
            type: 'week',
            count: 1,
            text: '1w'
          }, {
            type: 'month',
            count: 1,
            text: '1m'
          }, {
            type: 'month',
            count: 3,
            text: '3m'
          }, {
            type: 'month',
            count: 6,
            text: '6m'
          }, {
            type: 'ytd',
            text: 'YTD'
          }, {
            type: 'year',
            count: 1,
            text: '1y'
          }, {
            type: 'all',
            text: 'All'
          }]
        },
        navigator: {
          enabled: true,
          margin: 50,
          xAxis: {
            showLastLabel: true,
            endOnTick: true,
            dateTimeLabelFormats: {
              second: '%Y-%m-%d<br/>%H:%M:%S',
              minute: '%Y-%m-%d<br/>%H:%M',
              hour: '%Y-%m-%d<br/>%H:%M',
              day: '%b %e',
              week: '%Y<br/>%m-%d',
              month: '%Y-%m',
              year: '%Y'
            }
          }
        },
        scrollbar: {
          enabled: true
        },
        title: {// text: 'Daily Revenue'
        },
        xAxis: {
          showLastLabel: true,
          endOnTick: true,
          type: 'datetime',
          dateTimeLabelFormats: {
            second: '%Y-%m-%d<br/>%H:%M:%S',
            minute: '%Y-%m-%d<br/>%H:%M',
            hour: '%Y-%m-%d<br/>%H:%M',
            day: '%b %e',
            week: '%Y<br/>%m-%d',
            month: '%Y-%m',
            year: '%Y'
          },
          title: {
            text: this.intl.t('day')
          },
          events: {
            afterSetExtremes: e => {
              if (e.target.series && e.target.series[0]) {
                let total = 0;
                e.target.series[0].processedYData.forEach(data => {
                  total += data;
                }); // Because ember complains about multiple changes per render which is false...

                Ember.run.later(() => {
                  if (!this.isDestroyed) {
                    this.chartTotal = total;
                  }
                }, 50);
              }
            }
          }
        },
        yAxis: {
          max: this.highestValue,
          floor: 0,
          opposite: false,
          showLastLabel: true,
          title: {
            text: this.intl.t('revenue')
          },
          allowDecimals: false,
          plotLines: [{
            value: revKPI,
            color: 'red',
            dashStyle: 'shortdash',
            width: 2,
            zIndex: 10,
            label: {
              text: this.intl.t('org_kpi')
            }
          }],
          labels: {
            formatter() {
              return _currencyFormatUtil.formatCurrencyString.call(self, this.value, false, true);
            } // format: 'xxx +${value:,.0f}'


          }
        },
        tooltip: {
          // not using arrow function, because I need the this value this is called with from highcharts
          // this function gets called everytime you hover over something and highcharts needs to generate a tooltip.
          // it gets called for the regular plot line but also for a kpi line if it's present.
          // If it gets called 2 times consecutively, the second call will be ignored as this is the call
          // for the point on the kpi line.
          pointFormatter() {
            // we skip every other tooltip call, formatting the first (the days revenue) and
            // skipping the second, unless the second call doesn't immediately happen,
            // in which case the next call is not going to be for formatting the kpi
            if (self.skipNextTooltipFormatCall) {
              return '';
            } else {
              self.skipNextTooltipFormatCall = true; // again, a tad hacky but it works well

              setTimeout(() => {
                self.skipNextTooltipFormatCall = false;
              }, 1);
              return `<b>${_currencyFormatUtil.formatCurrencyString.call(self, this.y, false, true)}</b>`;
            }
          }

        }
      };
    }

    get chartData() {
      if (!this.args.results) {
        return undefined;
        /* since changes to chartData make ember-highcharts re-render the chart...
         * which I don't know if we'll need at all now that we dynamically render the chart..
         * we might want to track the last returned data and return that
         * instead of undefined, so the data stays the same.
         * real question is: do we even need this chartData fn anymore??
         */
        // let currentDailyRevData = this.get('currentDailyRevData');
        // this.set('results', currentDailyRevData); // maybe this will help with the hoverSeries bug??
        // return currentDailyRevData;
      }

      let name = this.intl.t('revenue');
      let data = this.generateDailyRevenueData();
      let dailyRevData = [{
        name,
        data,
        type: 'area',
        threshold: null,
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1
          },
          stops: [[0, '#e2effb'], [1, 'rgba(228, 239, 250, .3)']]
        }
      }];
      return dailyRevData;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "canAccess", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dailyRevenueData", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "chartTotal", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  })), _class));
  _exports.default = DashboardChartsDailyRevenueDailyRevenueSimpleComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, DashboardChartsDailyRevenueDailyRevenueSimpleComponent);
});