define("alliance-business-suite/controllers/locations/rewards/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    // validations,
    brush: Ember.inject.service(),
    router: Ember.inject.service(),
    canCreate: Ember.computed('model.rewardsProgram.name', function () {
      if (Ember.get(this, 'model.rewardsProgram.name')) {
        return false;
      }

      return 'disabled';
    }),
    actions: {
      createRecord(model) {
        Ember.set(this, 'isLoading', true);
        model.save().then(() => {
          Ember.set(this, 'isLoading', false);
          this.transitionToRoute('locations.rewards.manage', model);
        });
      },

      destroyRecord(model) {
        model.destroyRecord().then(() => {
          this.transitionToRoute('locations.rewards');
        });
      }

    }
  });

  _exports.default = _default;
});