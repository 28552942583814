define("alliance-business-suite/helpers/machines/cycles/label-abbrev", ["exports", "alliance-business-suite/utils/cycles-values"], function (_exports, _cyclesValues) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // TODO: Add translations
  var _default = Ember.Helper.helper(function (_ref) {
    let [value, ...rest] = _ref;
    return `<span class="step-label-abbrev">${_cyclesValues.STEP_LABELS_ABBREV_ENUM[value]}</span>`;
  });

  _exports.default = _default;
});