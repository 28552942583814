define("alliance-business-suite/components/summary/reports/s-capacity-utilization-detail", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "alliance-business-suite/utils/report-tables-util"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _reportTablesUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  let SummaryReportsSCapacityUtilizationDetailComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, (_class = class SummaryReportsSCapacityUtilizationDetailComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "brand", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor4, this);
    }

    get translatedColumns() {
      return [{
        name: "machine",
        valuePath: "serialNumber",
        isFixed: "left"
      }, {
        name: "description",
        valuePath: "description"
      }, {
        name: "cycle_name",
        valuePath: "cycleName"
      }, {
        name: "total_cycles",
        valuePath: "totalCycleCount"
      }, {
        name: "total_cycle_times",
        valuePath: "cycleLengthBreakdown"
      }];
    }

    get rowsWithChildren() {
      let {
        model
      } = this.args;
      let cycleTotals = model.cycleTotals;
      let rowData = [];
      cycleTotals.forEach(total => {
        let machineCycles = [];
        rowData.push({
          serialNumber: total.serialNumber,
          description: total.description,
          children: machineCycles,
          isCollapsed: false,
          totalCycleCount: total.totalCycleCount,
          cycleLengthBreakdown: total.cycleLengthBreakdown
        });
        total.cycleTypes.forEach(cycle => {
          machineCycles.push({
            cycleName: cycle.cycleName,
            totalCycleCount: cycle.cycleCount
          });
        });
      });
      console.log("rowData", rowData);
      return rowData;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "brand", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SummaryReportsSCapacityUtilizationDetailComponent;
});