define("alliance-business-suite/components/tables/table-body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    brush: Ember.inject.service(),
    tagName: '',
    actions: {
      sendToBrush(data) {
        let {
          modelName
        } = this;
        let id = data.id;
        this.brush.transitionTo(modelName, {
          id,
          type: modelName
        });
      }

    }
  });

  _exports.default = _default;
});