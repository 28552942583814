define("alliance-business-suite/routes/route-not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class RouteNotFoundRoute extends Ember.Route {
    beforeModel() {
      this.transitionTo('not-found');
    }

  }

  _exports.default = RouteNotFoundRoute;
});