define("alliance-business-suite/templates/components/welcome/welcome-shim", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fWNdsdRe",
    "block": "{\"symbols\":[\"@viewOnly\"],\"statements\":[[8,\"modal-dialog\",[],[[\"@animatable\",\"@targetAttachment\",\"@translucentOverlay\",\"@clickOutsideToClose\",\"@overlayClassNames\",\"@containerClassNames\"],[true,\"none\",true,false,\"ui-modal-dialog__overlay modal-tos-container\",[30,[36,1],[\"ui-modal-dialog modal-tos\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[10,\"header\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"ui-modal-dialog__title\"],[12],[2,\"\\n      \"],[1,[30,[36,2],[\"app_name\"],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[6,[37,4],[[30,[36,3],[[32,0,[\"currentView\"]],\"terms-of-service\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"welcome/welcome-tos\",[],[[\"@viewOnly\",\"@updateCurrentView\"],[[32,1],[30,[36,0],[[32,0,[\"updateCurrentView\"]],\"terms-of-service\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"welcome/welcome-owner-app-promo\",[],[[\"@updateCurrentView\"],[[30,[36,0],[[32,0,[\"updateCurrentView\"]],\"owner-app-promo\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"array\",\"b\",\"eq\",\"if\"]}",
    "moduleName": "alliance-business-suite/templates/components/welcome/welcome-shim.hbs"
  });

  _exports.default = _default;
});