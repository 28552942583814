define("alliance-business-suite/components/form-ui/duration-field/utils/codes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.KEY_CODES = void 0;
  _exports.isNumberCode = isNumberCode;
  _exports.keyCodeToNumber = keyCodeToNumber;
  const NUM_KEYS_START = 48;
  const NUM_KEYS_END = 57;
  const NUM_PAD_START = 96;
  const NUM_PAD_END = 105;
  const KEY_CODES = {
    UP: 38,
    DOWN: 40,
    LEFT: 37,
    RIGHT: 39,
    TAB: 9
  };
  _exports.KEY_CODES = KEY_CODES;

  function isNumberCode(code) {
    return code >= NUM_KEYS_START && code <= NUM_KEYS_END || code >= NUM_PAD_START && code <= NUM_PAD_END;
  }

  function keyCodeToNumber(code) {
    if (code >= NUM_KEYS_START && code <= NUM_KEYS_END) {
      return code - NUM_KEYS_START;
    } else if (code >= NUM_PAD_START && code <= NUM_PAD_END) {
      return code - NUM_PAD_START;
    } else {
      return null;
    }
  }
});