define("alliance-business-suite/templates/locations/configure", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YhwUsiEV",
    "block": "{\"symbols\":[],\"statements\":[[8,\"locations/configure/configure-locations-section\",[],[[],[]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "alliance-business-suite/templates/locations/configure.hbs"
  });

  _exports.default = _default;
});