define("alliance-business-suite/services/access", ["exports", "alliance-business-suite/config/environment", "alliance-business-suite/mixins/account-payment", "alliance-business-suite/services/access-nav-config", "moment", "ember-copy"], function (_exports, _environment, _accountPayment, _accessNavConfig, _moment, _emberCopy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A service for managing access control within the application. Coordinates
   * with the session for authentication and authorization. Ensures the
   * application is using the right token to make requests to the API and that
   * data is cleared properly whenever the access changes.
   *
   * # Events
   *
   * accessChanged
   * : Sent after the access for the current session has changed an the new access
   *   has been loaded.
   *
   * @public
   */
  var _default = Ember.Service.extend(Ember.Evented, _accountPayment.default, {
    session: Ember.inject.service(),
    brush: Ember.inject.service(),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    machinePrograms: Ember.inject.service(),
    brand: Ember.inject.service(),
    showExperimentalFeatures: false,
    showRetailFeatures: false,
    whiteListPayment: false,
    // hasPassedOrgSelect: false,
    isLoadingBrand: true,
    algoliaKey: "",
    paymentLocations: null,
    locationsExpiringSoon: null,

    shiftCurrency(value) {
      // This is just a simple helper to convert currency values
      return value / Ember.get(this, "currencySubunit");
    },

    isLaundromatOrg: Ember.computed("session.data.isLaundromatOrg", "accessOrganization", function () {
      let type = this.get("accessOrganization.userRole.roleType.id");

      if (type == "ORGANIZATION_FRANCHISE_OWNER" || type == "ORGANIZATION_OPL") {
        return true;
      }

      return false;
    }),
    isOwnerOperator: Ember.computed("access.accessUser", function () {
      const employee = this.store.peekRecord("employee", this.accessUser.get("id"));
      const securityRoles = employee.get("securityRoles");
      const securityRole = securityRoles.get("firstObject");
      return securityRole.get("name") === "OWNER_OPERATOR";
    }),
    isHuebsch: Ember.computed(function () {
      // TODO: Deprecated.
      console.log("Deprecation: isHuebsch has been moved to the brand service."); // eslint-disable-line no-console

      return _environment.default.brand == "HUEBSCH" ? true : false;
    }),
    org: Ember.computed("session.data.orgId", function () {
      if (this.get("session.data.orgId")) {
        return this.store.peekRecord("organization", this.get("session.data.orgId"));
      }
    }),
    isCrm: Ember.computed("org", function () {
      return this.org ? this.org.isCrm : false;
    }),
    isRetail: Ember.computed("org", function () {
      return this.org ? this.org.isRetail : false;
    }),
    isTrade: Ember.computed("org", function () {
      return this.org ? this.org.isTrade : false;
    }),
    isInternational: Ember.computed("countryCode", function () {
      return this.countryCode == "US" ? false : true;
    }),
    isNotCrm: Ember.computed.not("isCrm"),
    isNotRetail: Ember.computed.not("isRetail"),
    isNotTrade: Ember.computed.not("isTrade"),
    forceOrgType: null,
    isOplOrg: Ember.computed("org", "session.data.orgId", function () {
      return this.store.peekAll("role-type").get("firstObject.id") === "ORGANIZATION_OPL";
    }),
    isOpl: Ember.computed("org", "session.data.orgId", "forceOrgType", function () {
      // if (this.session.data.orgId == '2946') {
      //   console.error('HARDOCDED OPL', this.session.data.orgId, '.>>2946')
      //   return true
      // }
      if (this.forceOrgType === "OPL") {
        return true;
      } else if (this.forceOrgType === "VENDED") {
        return false;
      } else {
        return this.store.peekAll("role-type").get("firstObject.id") === "ORGANIZATION_OPL";
      }
    }),
    isDemo: Ember.computed(function () {
      return this.accessOrganization && this.session.data.isDemoUser && this.accessOrganization.get("id") && this.accessOrganization.get("id") === "1084";
    }),
    showExperimentalFirelincReport: Ember.computed(function () {
      if (this.store.peekAll("role-type").get("firstObject.id") === "ORGANIZATION_OPL") {
        console.error("showExperimentalFirelincReport true!");
        return this.accessOrganization.get("id") && (this.accessOrganization.get("id") === "492936" || this.accessOrganization.get("id") === "623");
      }
    }),
    isDisconnected: false,
    isVended: Ember.computed.not("isOpl"),

    calendarStartDay() {
      // Returns integer indicating the day that starts a week. Sunday: 0
      let user = Ember.get(this, "store").peekRecord("employee", this.accessUser.id);
      let dayList = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
      let day = user.calendarStartDay || "sunday";
      return dayList.indexOf(day);
    },

    currencyDenomination: Ember.computed("session.data.currencySymbol", function () {
      return this.get("session.data.currencySymbol");
    }),
    currencySubunit: Ember.computed("session.data.currencyDivisor", function () {
      let hungarianOrgs = [342447, 450318, 464868, 1177975]; // override hungary divisor

      if (hungarianOrgs.indexOf(this.accessOrganization.get("id")) != -1) {
        return "1";
      } else {
        let session = Ember.get(this, "session");
        return session.get("data.currencyDivisor");
      }
    }),
    currencyCode: Ember.computed("session.data.iso4217Code", function () {
      let session = Ember.get(this, "session");
      return session.get("data.iso4217Code");
    }),
    // determines if a country separates thousands with "1,000,000" or "1.000.000" or "1 000 000" (country specific)
    currencyThousandsSeparator: Ember.computed("session.data.currencyThousandsSeparator", function () {
      let session = Ember.get(this, "session");
      return session.get("data.currencyThousandsSeparator");
    }),
    // determines if a country separates thousands with "1,000,000" or "1.000.000" or "1 000 000" (country specific)
    currencyDecimalSeparator: Ember.computed("session.data.currencyDecimalSeparator", function () {
      let session = Ember.get(this, "session");
      return session.get("data.currencyDecimalSeparator");
    }),
    currencyFormatStringWithoutThousands: Ember.computed("currencySubunit", function () {
      let cs = this.currencySubunit;

      if (cs === 1) {
        return "0";
      } else {
        return "0.00";
      }
    }),
    currencyFormatString: Ember.computed("currencySubunit", function () {
      let cs = this.currencySubunit;

      if (cs === 1) {
        return "0,0";
      } else {
        return "0,0.00";
      }
    }),
    // for a colombia org, the prices are using a decimal different than the country's so we need
    // specific logic to override that
    currencyDecimalOverride: Ember.computed("session.data.currencyDecimalOverride", function () {
      let session = Ember.get(this, "session");
      return session.get("data.currencyDecimalOverride");
    }),
    currencyDecimalPlaces: Ember.computed("session.data.currencyDecimalPlaces", function () {
      let session = Ember.get(this, "session");
      return session.get("data.currencyDecimalPlaces");
    }),
    countryCode: Ember.computed("access.accessOrganization.country", function () {
      return this.get("access.accessOrganization.country.isoCountryCode");
    }).readOnly(),
    hasViewedChangelog: Ember.computed(function () {
      let cookie = document.cookie;
      let cookieIdx = cookie.search("hasViewedALSChangelog");

      if (cookieIdx >= 0) {
        return true;
      }
    }),
    expiredPaymentLocations: Ember.computed("paymentLocations", function () {
      let roomsRaw = this.paymentLocations; // only show rooms that are expired

      let roomsFiltered = roomsRaw.filter(r => {
        if (!this.isExpired(r)) {
          return r;
        }
      });
      return roomsFiltered;
    }),
    locationsExpiringSoonIds: Ember.computed("locationsExpiringSoon.[]", function () {
      let {
        locationsExpiringSoon
      } = this;
      let result = [];
      locationsExpiringSoon.forEach(loc => {
        result.push(loc.id);
      });
      return result;
    }),

    /**
     * Initialize this service.
     *
     * @protected
     */
    // init() {
    //   this._super(...arguments);
    //   let sessionStore = getOwner(this).lookup('session-store:application');
    //
    //   // sessionStore.addSessionObserver('token', this, this._tokenChanged);
    // },

    /**
     * Loads the access properties for this service. Await the results of this
     * method before performing operations that need access objects.
     *
     * @public
     */
    async load(transition) {
      let employee = await this._findEmployee();
      let organization = await this._findOrganization();
      Ember.set(this, "accessUser", employee);
      Ember.set(this, "accessOrganization", organization);

      if (organization.id == "408714" || organization.id == "413201") {
        if (transition.targetName = "summary.charts") {
          this.router.transitionTo("start");
        }

        this.set("isDisconnected", true);
      }
    },

    // ---------------------------------------------------------------------------
    // Privileged Properties
    //
    // Use these only for accessing models which determine the current
    // authorization. Use the normal properties for access data about those models
    // that doesn't involve authorization.
    // ---------------------------------------------------------------------------

    /**
     * Returns the active token that represents the current authorization to use
     * for API calls. Defaults to the token set during authentication, but may be
     * updated when the active user role changes.
     *
     * @type {string}
     * @public
     */
    accessToken: Ember.computed("session.data.{token,authenticated.token}", {
      get() {
        let token = Ember.get(this, "session.data.token") || Ember.get(this, "session.data.authenticated.token"); // HACK: This is used to remove a hack in the `token` authenticator that
        //       is used for testing when changing user roles. Remove this once
        //       different tokens are being returned by the API. [twl 17.May.17]

        if (token && token.indexOf("!#!") !== -1) {
          token = token.substring(0, token.indexOf("!#!"));
        }

        return token;
      }

    }).readOnly(),

    /**
     * The authenticated privileged user object for this application. To change,
     * the user must invalidate, then revalidate their session (i.e. log out and
     * back in).
     *
     * @type {DS.Model}
     * @public
     */
    accessUser: undefined,
    securityRoles: Ember.computed(function () {
      let roles = this.store.peekAll("user").get("firstObject.securityRoles");
      return roles;
    }),

    /**
     * All of the privileged roles assigned to a user, across all organizations
     * this user belongs to.
     *
     * @type {Array<DS.Model>}
     * @public
     */
    accessUserRoles: undefined,

    /**
     * The privileged organization object that access is currently based on.
     *
     * @type {Array<DS.Model>}
     * @public
     */
    accessOrganization: undefined,
    // ---------------------------------------------------------------------------
    // Normal Properties
    // ---------------------------------------------------------------------------

    /**
     * The unprivileged user object for the authenticated user. This can be used
     * to access all of the relationships for the user, but has no access to
     * privileged objects.
     *
     * @type {DS.Model}
     * @public
     */
    user: Ember.computed("accessUser.id", {
      get() {
        // console.error('this should never be called: May26160956')
        let id = Ember.get(this, "accessUser.id");
        return id != null ? Ember.get(this, "store").findRecord("user", id) : undefined;
      }

    }).readOnly(),

    /**
     * The organization that access is currently based on.
     *
     * @type {Array<DS.Model>}
     * @public
     */
    organization: Ember.computed("userRole.organization", function () {
      try {
        return this.userRole.get("organization");
      } catch {
        this.session.invalidate();
      } // async get() {
      //   return await asyncGet(this, 'userRole.organization');
      // }

    }).readOnly(),

    // retrieve user to put it into ember data
    async _findEmployee() {
      let id = Ember.get(this, "session.data.authenticated.userId");
      return await Ember.get(this, "store").findRecord("employee", id);
    },

    async _findOrganization() {
      let {
        access,
        session,
        store
      } = this;
      let id = session.get("data.orgId"); // return await store.findRecord('organization', session.get('data.orgId'), {reload: true});

      return await store.queryRecord("organization", {
        organization: id,
        geoBoundaries: true,
        include: "all"
      });
    },

    navLinks: Ember.computed("forceOrgType", "isNotCrm", "isNotRetail", "isTrade", "isOpl", "isInternational", "isDisconnected", {
      // Return an array of navigation links (top and subnav tabs) that should be displayed
      // based on the current organization's attributes (such as isRetail, isOpl, etc.)
      get() {
        let links = (0, _emberCopy.copy)(Ember.A(_accessNavConfig.default), true);

        let shouldRemoveItem = linkObj => {
          // These are the organization attributes we're filtering out
          let orgAttributes = [{
            name: "opl",
            value: this.isOpl
          }, {
            name: "vended",
            value: !this.isOpl
          }, {
            name: "not-crm",
            value: this.isNotCrm
          }, {
            name: "not-retail",
            value: this.isNotRetail
          }, {
            name: "trade",
            value: this.isTrade
          }, {
            name: "international",
            value: this.isInternational
          }, {
            name: "nfc-disconnected",
            value: this.isDisconnected
          }, {
            name: "is-demo",
            value: this.isDemo
          }, {
            name: "brand-unimac",
            value: this.brand.name === "UNIMAC"
          }];
          return orgAttributes.any(setting => {
            return setting.value && // the attribute must be true for the current organization
            linkObj.removeIf && // the removeIf array must exist
            linkObj.removeIf.includes(setting.name); // the removeIf array must include this attribute name
          });
        };

        return links.filter(topLink => {
          // Deal with top-level tabs
          if (shouldRemoveItem(topLink)) return false; // Deal with sub-tabs

          topLink.subnavTabs = topLink.subnavTabs.reject(subTab => {
            // Do not include items that should be removed
            return shouldRemoveItem(subTab);
          });
          return true;
        });
      }

    }),

    subnavTabs(title) {
      let routeDetails = this.headerLinks.findBy("title", title);
      const orgId = this.accessOrganization ? this.accessOrganization.get("id") : this.session.data.orgId;

      if (routeDetails && routeDetails.subnavTabs) {
        return routeDetails.subnavTabs.map(link => {
          // add the override property if it exists
          if (link.whitelistOrgOverride && link.whitelistOrgOverride.includes(orgId)) {
            link.override = true;
          }

          return link;
        });
      } else {
        return "";
      }
    },

    headerLinks: Ember.computed("navLinks.[]", {
      get() {
        let a = this.navLinks;
        let filtered = a.filter(link => {
          if (!Ember.get(this, "session.data.algoliaKey")) {
            if (link.link != "customers") {
              return true;
            }
          } else {
            return true;
          }
        });
        return filtered;
      }

    }),
    // some alerts are not for OPL orgs
    oplHiddenAlerts: Ember.computed(function () {
      return [{
        reportLookupKey: "SUBSCRIPTION_TEMPLATE_TOTAL_CARD_READER_PROGRAMMING_ERRORS_MACHINE_ERROR",
        text: "Card reader programming"
      }, {
        reportLookupKey: "SUBSCRIPTION_TEMPLATE_COIN_ONE_MACHINE_ERROR",
        text: "Coin one error"
      }, {
        reportLookupKey: "SUBSCRIPTION_TEMPLATE_COIN_TWO_MACHINE_ERROR",
        text: "Coin two error"
      }, {
        reportLookupKey: "SUBSCRIPTION_TEMPLATE_COIN_VAULT_FULL_ERRORS_MACHINE_ERROR",
        text: "Coin vault full"
      }, {
        reportLookupKey: "SUBSCRIPTION_TEMPLATE_TOTAL_NUMBER_OF_COIN_VAULT_OPENINGS_ERRORS_MACHINE_ERROR",
        text: "Coin vault openings"
      }, {
        reportLookupKey: "SUBSCRIPTION_TEMPLATE_TOTAL_NUMBER_OF_LUCKY_CYCLES_ERRORS_MACHINE_ERROR",
        text: "Lucky cycles"
      }, {
        reportLookupKey: "SUBSCRIPTION_TEMPLATE_TOTAL_NUMBER_OF_CARD_READER_DOWNLOADS_ERRORS_MACHINE_ERROR",
        text: "Number of card reader downloads"
      }, {
        reportLookupKey: "SUBSCRIPTION_TEMPLATE_TOTAL_NUMBER_OF_REMOTE_VEND_CYCLES_ERRORS_MACHINE_ERROR",
        text: "Remote vend cycles"
      }];
    }),
    isEnglish: Ember.computed(function () {
      return this.intl.locales[0] == "en-us";
    }),
    showTutorial: Ember.computed(function () {
      return this.intl.locales[0] == "en-us" && !this.isOpl;
    }),
    weightUnit: Ember.computed(function () {
      if (this.org.weightLookupKey == "KILOGRAM") {
        return "kg";
      } else {
        return "lb";
      }
    }),
    // Handle time zone conversions
    userTimeZone: Ember.computed("accessUser.timeZone", function () {
      return this.accessUser.timeZone; // As returned from the API
    }),
    userTimeZoneAbbr: Ember.computed("accessUser.timeZone", function () {
      return _moment.default.tz(Date(), this.userTimeZone).zoneAbbr();
    }),
    userUtcOffset: Ember.computed("accessUser.timeZone", function () {
      return (0, _moment.default)().tz(this.userTimeZone).utcOffset(); // offset in minutes
    }),
    userDateTimeFormat: Ember.computed("accessUser.{dateFormat,timeFormat}", function () {
      // The user can only choose between 12-hour and 24-hour time formats, so we will
      // assume that 12-hour time should display AM/PM.
      const timeFormat = this.accessUser.timeFormat == "hh:mm" ? "h:mm a" : "HH:mm";
      return `${this.accessUser.dateFormat} ${timeFormat}`;
    }),

    toUserDateTime(utcDateTime) {
      let {
        type
      } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : options;

      // TODO: Should we return a Moment with timezone info, or RFC date?
      if (type === "moment") {
        return _moment.default.utc(utcDateTime).tz(this.userTimeZone); // RFC2822/ISO date
      } else {
        return _moment.default.utc(utcDateTime).tz(this.userTimeZone).format(); // RFC2822/ISO date
      }
    },

    toUtcDateTime(userDateTime) {
      let {
        type
      } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : options;

      if (type === "moment") {
        return _moment.default.tz(userDateTime, this.userTimeZone).utc(); // RFC2822/ISO date
      } else {
        return _moment.default.tz(userDateTime, this.userTimeZone).utc().format(); // RFC2822/ISO date
      }
    },

    toggleOplMode() {
      let newType;

      switch (this.forceOrgType) {
        case "VENDED":
          newType = "OPL";
          break;

        case "OPL":
          newType = null;
          break;

        default:
          newType = "VENDED";
          break;
      }

      this.set("forceOrgType", newType);
      localStorage.setItem("forceOrgType", newType);
    }

  });

  _exports.default = _default;
});