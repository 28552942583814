define("alliance-business-suite/components/machines/programs/cycles/centurion", ["exports", "alliance-business-suite/mixins/machines/programs/cycles/cycle-global-gold"], function (_exports, _cycleGlobalGold) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_cycleGlobalGold.default, {
    // #region Settings and Configs
    speeds: Ember.computed('model.{settings._MotorDriveType,machineSize}', function () {
      // Speeds are determined by machine size and motor drive
      let motorDrive = Ember.get(this, 'model.settings._MotorDriveType');
      let machineType = Ember.get(this, 'model.machineType.id');

      if (machineType == 100 || motorDrive === 'TWO_SPEED') {
        return [{
          val: 1,
          label: 'Very low'
        }, {
          val: 2,
          label: 'Low'
        }, {
          val: 3,
          label: 'Medium'
        }, {
          val: 4,
          label: 'High'
        }, {
          val: 5,
          label: 'Very high'
        }, {
          val: 6,
          label: 'Ultra high'
        }];
      } else {
        let speedPresets = motorDrive === 'V_SPEED' ? Ember.get(this, 'machineSettings.c4.vSpeed') : Ember.get(this, 'machineSettings.c4.fSpeed');
        return [{
          val: 1,
          label: `Very low (${speedPresets[0]}G)`
        }, {
          val: 2,
          label: `Low (${speedPresets[1]}G)`
        }, {
          val: 3,
          label: `Medium (${speedPresets[2]}G)`
        }, {
          val: 4,
          label: `High (${speedPresets[3]}G)`
        }, {
          val: 5,
          label: `Very high (${speedPresets[4]}G)`
        }, {
          val: 6,
          label: `Ultra high (${speedPresets[5]}G)`
        }];
      }
    }),
    cycleSettings: Ember.computed('model', 'model.{settings._MotorDriveType,machineSize}', function () {
      return {
        machine: 'quantum-gold-plus-frontload',
        isCelsius: 'Program.Miscellaneous.CelsiusEnabled',
        // All options used in the UI are contained in this `options` object.
        options: {
          MotorDriveType: [// V and F speed seem to be the same
          {
            val: 'TWO_SPEED',
            label: '2 Speed'
          }, // use custom=true to set as default
          {
            val: 'V_SPEED',
            label: 'V Speed'
          }, {
            val: 'F_SPEED',
            label: 'F Speed'
          }],
          SegmentStatus: [{
            val: 0,
            label: 'Off'
          }, {
            val: 2,
            label: 'Wash'
          }, {
            val: 3,
            label: 'Rinse'
          }],
          SpeedPresets: this.speeds,
          FillStepHeat: [{
            val: 1,
            label: 'No heat',
            values: [false, false]
          }, {
            val: 2,
            label: 'Heat to target in agitate step',
            values: [true, false]
          }, {
            val: 3,
            label: 'Maintain heat in agitate step',
            values: [false, true]
          }],
          FillWaterLevel: [// Updated for Gold WX
          {
            val: 2,
            label: `High (${Ember.get(this, 'machineProgram.settings.Program.WaterLevelHigh')})`,
            presetValue: Ember.get(this, 'machineProgram.settings.Program.WaterLevelHigh')
          }, {
            val: 1,
            label: `Medium (${Ember.get(this, 'machineProgram.settings.Program.WaterLevelMedium')})`,
            presetValue: Ember.get(this, 'machineProgram.settings.Program.WaterLevelMedium')
          }, {
            val: 0,
            label: `Low (${Ember.get(this, 'machineProgram.settings.Program.WaterLevelLow')})`,
            presetValue: Ember.get(this, 'machineProgram.settings.Program.WaterLevelLow')
          }, {
            val: 128,
            label: 'Custom',
            isCustom: true
          }],
          FillGlobalTemperature: [{
            val: 2,
            label: `Global hot temperature (${Ember.get(this, 'machineProgram.settings.Program.WaterTemperatureHot')}°${this.temperatureScale})`,
            presetValue: Ember.get(this, 'machineProgram.settings.Program.WaterTemperatureHot')
          }, {
            val: 1,
            label: `Global warm temperature (${Ember.get(this, 'machineProgram.settings.Program.WaterTemperatureWarm')}°${this.temperatureScale})`,
            presetValue: Ember.get(this, 'machineProgram.settings.Program.WaterTemperatureWarm')
          }, {
            val: 0,
            label: `Global cold temperature (${Ember.get(this, 'machineProgram.settings.Program.WaterTemperatureCold')}°${this.temperatureScale})`,
            presetValue: Ember.get(this, 'machineProgram.settings.Program.WaterTemperatureCold')
          }],
          RotatePauseAgitate: [{
            val: 0,
            label: '18 / 3 / 18'
          }, {
            val: 1,
            label: '3 / 12 / 3'
          }]
        },
        // All cycles for this machine are listed here in the order the API gives them to us
        cycles: ['Normal Hot', 'Normal Warm', 'Normal Cold', 'Permanent Press Hot', 'Permanent Press Warm', 'Permanent Press Cold', 'Delicate Hot', 'Delicate Warm', 'Delicate Cold']
      };
    })
  });

  _exports.default = _default;
});