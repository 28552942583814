define("alliance-business-suite/templates/components/form-ui/tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9cM3JWzQ",
    "block": "{\"symbols\":[],\"statements\":[[8,\"ember-tooltip\",[],[[\"@delay\",\"@tooltipClass\",\"@event\",\"@side\",\"@text\"],[[30,[36,1],[[35,0],400],null],\"ember-tooltip\",[30,[36,1],[[35,2],\"hover\"],null],[30,[36,1],[[35,3],\"right\"],null],[30,[36,5],[[35,4]],null]]],null]],\"hasEval\":false,\"upvars\":[\"delay\",\"or\",\"event\",\"side\",\"tooltip\",\"t\"]}",
    "moduleName": "alliance-business-suite/templates/components/form-ui/tooltip.hbs"
  });

  _exports.default = _default;
});