define("alliance-business-suite/templates/components/badge-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sRVmZrHn",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[2,\"\\n\"],[6,[37,1],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"i\"],[15,0,[31,[\"fa fa-\",[34,0]]]],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"name\",\"if\"]}",
    "moduleName": "alliance-business-suite/templates/components/badge-icon.hbs"
  });

  _exports.default = _default;
});