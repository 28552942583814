define("alliance-business-suite/templates/components/form-ui/user-languages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DdAN5QWQ",
    "block": "{\"symbols\":[\"ui\",\"lang\"],\"statements\":[[8,\"form-ui\",[],[[\"@object\",\"@formDisplay\",\"@formSettings\",\"@selectOptions\"],[[32,0,[\"object\"]],\"compact\",[32,0,[\"formSettings\"]],[32,0,[\"selectOptions\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[10,\"label\"],[14,0,\"form-ui-element\"],[12],[1,[30,[36,1],[\"user_selectable_language\"],null]],[13],[2,\"\\n  \"],[10,\"div\"],[14,5,\"overflow-y:scroll; height:10em; border: 1px solid #CED0DA;\"],[12],[2,\"\\n\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,0,[\"sortedLanguages\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,[32,1,[\"checkbox\"]],[],[[\"@params\"],[[30,[36,0],null,[[\"propertyName\"],[[32,2]]]]]],null],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"  \"],[13],[2,\"\\n\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"hash\",\"t\",\"-track-array\",\"each\"]}",
    "moduleName": "alliance-business-suite/templates/components/form-ui/user-languages.hbs"
  });

  _exports.default = _default;
});