define("alliance-business-suite/components/form-ui/duration-field/utils/state", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.state = state;

  function state(klass) {
    let attrs = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return Ember.computed({
      get() {
        let manager = this.manager || this;
        let parent = this;
        return klass.create(Object.assign(attrs, {
          manager,
          parent
        }));
      }

    });
  }

  var _default = Ember.Object.extend({
    send(name) {
      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      if (this[name]) {
        this[name].apply(this, [this.manager, ...args]);
      } else if (this.parent) {
        this.parent.send(name, ...args);
      } else {
        throw new Error(`Unhandled event ${name}`);
      }
    }

  });

  _exports.default = _default;
});