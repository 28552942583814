define("alliance-business-suite/helpers/time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (params) {
    return moment(params[0]).format('hh:mm');
  });

  _exports.default = _default;
});