define("alliance-business-suite/templates/components/form-ui/chips", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rAjKj+Cp",
    "block": "{\"symbols\":[\"option\",\"&default\"],\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"form-field select-field\"],[12],[2,\"\\n    \"],[10,\"label\"],[12],[1,[34,4]],[13],[2,\"\\n      \"],[10,\"ul\"],[14,0,\"tyui-select\"],[12],[2,\"\\n\"],[6,[37,7],[[30,[36,6],[[30,[36,6],[[35,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,3],[[30,[36,2],[[32,1,[\"val\"]],[35,1]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"            \"],[11,\"li\"],[24,0,\"selected\"],[4,[38,0],[[32,0],\"doChange\",[32,1]],null],[12],[10,\"span\"],[14,0,\"selected-status\"],[12],[2,\"✓\"],[13],[1,[32,1,[\"label\"]]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"            \"],[11,\"li\"],[4,[38,0],[[32,0],\"doChange\",[32,1]],null],[12],[1,[32,1,[\"label\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"      \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[18,2,[[35,8]]]],\"hasEval\":false,\"upvars\":[\"action\",\"value\",\"eq\",\"if\",\"label\",\"options\",\"-track-array\",\"each\",\"yieldHash\"]}",
    "moduleName": "alliance-business-suite/templates/components/form-ui/chips.hbs"
  });

  _exports.default = _default;
});