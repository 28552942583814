define("alliance-business-suite/utils/speed-utilities", ["exports", "alliance-business-suite/utils/fix-range"], function (_exports, _fixRange) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.toRPM = _exports.toG = _exports.convertRPMtoG = _exports.convertGtoRPM = void 0;

  /* globals Big */
  // Note that values come in multiplied by 100 to be submitted as integers
  const ROUND_DOWN = 0;
  const ROUND_HALF_UP = 1;
  const ROUND_HALF_EVEN = 2;
  const ROUND_UP = 3;

  const convertGtoRPM = (diameter, gForce, min, max, roundUp) => {
    if (gForce === undefined) {
      // eslint-disable-next-line
      console.warn('gForce is undefined, convertGtoRPM is returning undefined');
      return undefined;
    }

    let g = new Big(gForce);

    if (diameter && diameter > 0) {
      let rpm = g.times(70472).div(diameter).sqrt().toFixed(0);

      if (min && max) {
        return (0, _fixRange.fixRange)(rpm, min, max);
      } else {
        return rpm;
      }
    }
  };

  _exports.convertGtoRPM = convertGtoRPM;

  const convertRPMtoG = function (diameter, rpm, min, max, roundUp) {
    let decimals = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : 2;

    if (rpm === undefined) {
      // eslint-disable-next-line
      console.warn('rpm is undefined, convertRPMtoG is returning undefined');
      return undefined;
    }

    let r = new Big(rpm);

    if (diameter && diameter > 0) {
      let g = r.pow(2).times(diameter).div(70472);

      if (min && max) {
        return new Big((0, _fixRange.fixRange)(g, min, max)).toFixed(decimals);
      } else {
        return g;
      }
    }
  }; // The following was added for the form-ui codebase. There are minor changes from the code above.
  // Defaults are based on WX, which rounds UP. FLW rounds DOWN.


  _exports.convertRPMtoG = convertRPMtoG;

  const toRPM = function () {
    let diameter = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
    let gForce = arguments.length > 1 ? arguments[1] : undefined;
    let rounding = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : ROUND_DOWN;

    if (gForce === undefined) {
      // eslint-disable-next-line
      console.warn('gForce is undefined, toRPM is returning undefined');
      return undefined;
    }

    let g = new Big(gForce);

    if (!Ember.isEmpty(diameter) && diameter > 0) {
      return g.times(70472).div(diameter).sqrt().round(0, rounding);
    }
  };

  _exports.toRPM = toRPM;

  const toG = function () {
    let diameter = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
    let rpm = arguments.length > 1 ? arguments[1] : undefined;
    let rounding = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : ROUND_UP;
    let decimals = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 2;

    if (rpm === undefined) {
      // eslint-disable-next-line
      console.warn('rpm is undefined, toG is returning undefined');
      return undefined;
    }

    let r = new Big(rpm);

    if (diameter && diameter > 0) {
      let newVal = r.pow(2).times(diameter).div(70472).round(decimals, rounding);
      console.log('*** toG.2', r.pow(2).times(diameter).div(70472).round(2, rounding).toFixed(4));
      console.log('*** toG.4', r.pow(2).times(diameter).div(70472).round(4, rounding).toFixed(4));
      return newVal;
    }
  };

  _exports.toG = toG;
});