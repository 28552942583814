define("alliance-business-suite/templates/components/form-ui/steps/message-resume", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dg5rJ4RA",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[8,\"form-ui\",[],[[\"@object\",\"@formSettings\",\"@selectOptions\"],[[34,0],[34,1],[34,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[8,[32,1,[\"select\"]],[],[[\"@params\"],[[30,[36,3],null,[[\"propertyName\",\"options\"],[\"HoldMessage\",\"HoldMessage\"]]]]],null],[2,\"\\n\\n  \"],[8,[32,1,[\"number\"]],[],[[\"@params\"],[[30,[36,3],null,[[\"propertyName\"],[\"AutoResumeMinutes\"]]]]],null],[2,\"\\n\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"object\",\"formSettings\",\"selectOptions\",\"hash\"]}",
    "moduleName": "alliance-business-suite/templates/components/form-ui/steps/message-resume.hbs"
  });

  _exports.default = _default;
});