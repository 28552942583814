define("alliance-business-suite/models/organization", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    hasMany,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    name: attr('string'),
    weightLookupKey: attr('string'),
    isActive: attr('string'),
    isAtrium: attr('boolean'),
    isIncludedLaundry: attr('boolean'),
    isMeteredLaundry: attr('boolean'),
    isRetail: attr('boolean'),
    isTrade: attr('boolean'),
    isCrm: attr('boolean'),
    stats: attr(),
    brand: attr('string'),
    hasCvvVerification: attr('boolean'),
    hasAddressVerification: attr('boolean'),
    organizationAlgoliaSecuredApiKey: attr('string'),
    isoStartDayOfWeek: attr(''),
    accountNumber: attr(''),
    organizationName: attr('string'),
    // TODO: Because `employees` is currently extended from `user`, the
    //       `employee` model has both an `organization` and an
    //       `organizations` relationship. Figure out a way to resolve
    //       this. -- Same goes for `customers`. [twl 2.May.17]
    employees: hasMany({
      inverse: 'organization'
    }),
    customers: hasMany({
      inverse: 'organization'
    }),
    roles: hasMany(),
    securityRoles: hasMany('security-role'),
    userRole: belongsTo('user-role'),
    paymentGateway: belongsTo('payment-gateway'),
    brandUser: belongsTo(),
    currency: belongsTo(),
    country: belongsTo(),
    machinePrograms: hasMany('machine-program', {
      inverse: 'organization'
    }),
    createdAt: attr('date'),
    // createdBy: belongsTo('user', {
    //   inverse: null
    // }),
    updatedAt: attr('date'),
    // updatedBy: belongsTo('user', {
    //   inverse: null
    // })
    geoBoundaries: hasMany('geo-boundary'),
    systemAlertSubscriptions: hasMany(),
    atriumBalanceName: attr('string'),
    meteredBalanceName: attr('string'),
    isRewardsEditable: Ember.computed('isAtrium', 'isMeteredLaundry', function () {
      let isAtrium = this.isAtrium;
      let isMeteredLaundry = this.isMeteredLaundry;

      if (isAtrium || isMeteredLaundry) {
        return false;
      }

      return true;
    })
  });

  _exports.default = _default;
});