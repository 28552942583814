define("alliance-business-suite/templates/components/table/table-cell-guideline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "n/9A2qCj",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[30,[36,10],[[35,8],0],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"order-progress\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"progress-bar\"],[12],[2,\"\\n      \"],[10,\"span\"],[15,0,[31,[[34,4]]]],[15,5,[30,[36,6],[[35,5],\"0.0%\",\"width:\"],null]],[12],[13],[2,\"\\n      \"],[8,\"ember-tooltip\",[],[[\"@event\",\"@class\"],[\"hover\",[30,[36,7],[\"\",[35,4]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,1],[\"inventory_at\"],null]],[2,\" \"],[1,[34,8]],[2,\"%, \"],[1,[30,[36,1],[\"alert_trigger_set\"],null]],[2,\" \"],[1,[34,9]],[2,\" \"],[1,[30,[36,1],[\"threshold\"],null]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[11,\"a\"],[4,[38,0],[[32,0],\"go\"],null],[12],[1,[30,[36,1],[\"edit_reorder_guidline\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"a\"],[4,[38,0],[[32,0],\"go\"],null],[12],[1,[30,[36,1],[\"add_reorder_guidline\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"t\",\"hasInventory\",\"if\",\"cssColor\",\"progressBar\",\"safe-string\",\"concat\",\"progressPercent\",\"alertThreshold\",\"gte\"]}",
    "moduleName": "alliance-business-suite/templates/components/table/table-cell-guideline.hbs"
  });

  _exports.default = _default;
});