define("alliance-business-suite/templates/components/machines/programs/combo-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9f8xeJCA",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[35,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[11,\"button\"],[24,0,\"btn glow\"],[4,[38,1],[[32,0],\"manage\"],null],[12],[10,\"i\"],[14,0,\"fa fa-pencil\"],[15,\"title\",[30,[36,0],[\"edit\"],null]],[12],[13],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"button\"],[14,0,\"btn glow\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,5,\"font-weight:bold\"],[12],[2,\"–\"],[13],[2,\"\\n    \"],[8,\"ember-tooltip\",[],[[\"@_tooltipVariantClass\"],[\"ember-tooltip tooltip-long no-wrap\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"machine_no_edit\"],null]]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[11,\"button\"],[24,0,\"btn glow dropdown-toggle\"],[4,[38,1],[[32,0],[30,[36,4],[\"open\",[32,0]],null]],null],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"caret\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"ul\"],[15,0,[31,[\"dropdown-menu align-\",[34,5]]]],[12],[2,\"\\n  \"],[11,\"li\"],[4,[38,1],[[32,0],\"send\"],null],[12],[1,[30,[36,0],[\"send_program\"],null]],[13],[2,\"\\n  \"],[11,\"li\"],[4,[38,1],[[32,0],\"export\"],null],[12],[1,[30,[36,0],[\"export_program\"],null]],[13],[2,\"\\n  \"],[11,\"li\"],[4,[38,1],[[32,0],\"download\"],null],[12],[1,[30,[36,0],[\"download\"],null]],[13],[2,\"\\n  \"],[11,\"li\"],[4,[38,1],[[32,0],\"delete\"],null],[12],[1,[30,[36,0],[\"delete\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"action\",\"isSupported\",\"if\",\"toggle\",\"position\"]}",
    "moduleName": "alliance-business-suite/templates/components/machines/programs/combo-button.hbs"
  });

  _exports.default = _default;
});