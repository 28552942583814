define("alliance-business-suite/helpers/diff-between-timestamps", ["exports", "alliance-business-suite/utils/traceability"], function (_exports, _traceability) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // given two unix timestamps, it calcs the difference and outputs the time in 00:00:00 format
  function diffBetweenTimestamps(_ref) {
    let [a, b] = _ref;
    const formattedTime = (0, _traceability.formatMinutesToTime)((b - a) / 60); // divide by 60 as it expected minutes in the arg

    return `${formattedTime.hrs}:${formattedTime.mins}:${formattedTime.secs}`;
  }

  var _default = Ember.Helper.helper(diffBetweenTimestamps);

  _exports.default = _default;
});