define("alliance-business-suite/components/machines/programs/cycles/steps-centurion", ["exports", "alliance-business-suite/mixins/machines/programs/cycles/step-global"], function (_exports, _stepGlobal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_stepGlobal.default, {
    defaultCompartment: true,
    heatValue: Ember.computed('object.{FillStepHeaterInAgitateStep,FillStepMaintainHeatInAgitateStep}', function () {
      let prop1 = this.object.FillStepHeaterInAgitateStep;
      let prop2 = this.object.FillStepMaintainHeatInAgitateStep;

      switch (true) {
        case prop1 === false && prop2 === false:
          return 1;

        case prop1 === true && prop2 === false:
          return 2;

        case prop1 === false && prop2 === true:
          return 3;
      }
    }),
    updateTimeValue: Ember.observer('model.{Name,FillStepOn,DrainStepOn,SupplyStepOn,AgitateStepOn,ExtractStepOn,SupplyStepMinutes,SupplyStepSeconds,AgitateStepMinutes,ExtractStepMinutes,ExtractStepSeconds,FillStepWaterLevelCustom,FillStepWaterLevelGlobal,FillStepTemperatureCustom,FillStepTemperatureGlobal}', function () {
      this.updateTime();
    }),
    optionsMotorDriveType: [// V and F speed seem to be the same
    {
      val: 'TWO_SPEED',
      label: '2 Speed'
    }, // use custom=true to set as default
    {
      val: 'V_SPEED',
      label: 'V Speed'
    }, {
      val: 'F_SPEED',
      label: 'F Speed'
    }],
    optionsSegmentStatus: [{
      val: 0,
      label: 'Off'
    }, {
      val: 2,
      label: 'Wash'
    }, {
      val: 3,
      label: 'Rinse'
    }],
    optionsSpeeds: Ember.computed('machineProgram.{settings._MotorDriveType,machineSize}', function () {
      // Speeds are determined by machine size and motor drive
      let motorDrive = Ember.get(this, "machineProgram.settings._MotorDriveType");
      let machineType = Ember.get(this, "machineProgram.machineType.id");

      if (machineType == 100 || motorDrive === 'TWO_SPEED') {
        return [{
          val: 1,
          label: 'very_low'
        }, {
          val: 2,
          label: 'low'
        }, {
          val: 3,
          label: 'medium'
        }, {
          val: 4,
          label: 'high'
        }, {
          val: 5,
          label: 'very_high'
        }, {
          val: 6,
          label: 'ultra_high'
        }];
      } else {
        let speedPresets = motorDrive === 'V_SPEED' ? this.machineProgram._machineSettings.c4.vSpeed : this.machineProgram._machineSettings.c4.fSpeed;
        return [{
          val: 1,
          label: 'very_low',
          presetValue: speedPresets[0]
        }, {
          val: 2,
          label: 'low',
          presetValue: speedPresets[1]
        }, {
          val: 3,
          label: 'medium',
          presetValue: speedPresets[2]
        }, {
          val: 4,
          label: 'high',
          presetValue: speedPresets[3]
        }, {
          val: 5,
          label: 'very_high',
          presetValue: speedPresets[4]
        }, {
          val: 6,
          label: 'ultra_high',
          presetValue: speedPresets[5]
        }];
      }
    }),
    optionsFillStepHeat: [{
      val: 1,
      label: 'no_heat',
      values: [false, false]
    }, {
      val: 2,
      label: 'heat_to_target_in_agitate_step',
      values: [true, false]
    }, {
      val: 3,
      label: 'maintain_heat_in_agitate_step',
      values: [false, true]
    }],
    optionsFillWaterLevel: Ember.computed('machineProgram._machineAttributes', function () {
      if (this.machineProgram._machineAttributes.includes('CENTURION_C4')) {
        return [{
          val: 2,
          label: 'high',
          presetValue: this.machineProgram.settings.Program.WaterLevelHigh
        }, {
          val: 1,
          label: 'medium',
          presetValue: this.machineProgram.settings.Program.WaterLevelMedium
        }, {
          val: 0,
          label: 'low',
          presetValue: this.machineProgram.settings.Program.WaterLevelLow
        }, {
          val: 128,
          label: 'custom',
          isCustom: true
        }];
      } else {
        return [{
          val: 2,
          label: 'high',
          presetValue: this.machineProgram.settings.Program.WaterLevelHigh
        }, {
          val: 1,
          label: 'medium',
          presetValue: this.machineProgram.settings.Program.WaterLevelMedium
        }, {
          val: 0,
          label: 'low',
          presetValue: this.machineProgram.settings.Program.WaterLevelLow
        }];
      }
    }),
    optionsFillGlobalTemperature: Ember.computed(function () {
      return [{
        val: 2,
        label: 'global_hot_temp',
        presetValue: this.machineProgram.settings.Program.WaterTemperatureHot
      }, {
        val: 1,
        label: 'global_warm_temp',
        presetValue: this.machineProgram.settings.Program.WaterTemperatureWarm
      }, {
        val: 0,
        label: 'global_cold_temp',
        presetValue: this.machineProgram.settings.Program.WaterTemperatureCold
      }];
    }),
    optionsRotatePauseAgitate: [{
      val: 0,
      label: '18 / 3 / 18'
    }, {
      val: 1,
      label: '3 / 12 / 3'
    }],
    actions: {
      updateHeat(params) {
        this.update(this.object, "FillStepHeaterInAgitateStep", params.values[0]);
        this.update(this.object, "FillStepMaintainHeatInAgitateStep", params.values[1]);
        return params.val;
      }

    }
  });

  _exports.default = _default;
});