define("alliance-business-suite/templates/components/inventory/item-list-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GZwDbebD",
    "block": "{\"symbols\":[\"@inventory\"],\"statements\":[[6,[37,2],[[30,[36,3],[[32,1,[\"length\"]],0],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"content-container container--placeholder\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[32,0,[\"selectedCategoryId\"]],0],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"h3\"],[12],[1,[30,[36,0],[\"no_items_in_category\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"h3\"],[12],[1,[30,[36,0],[\"no_organization_items\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"inventory-table\"],[12],[2,\"\\n    \"],[8,\"tables/table-interactive\",[],[[\"@isClickable\",\"@isLeftAlignLast\",\"@data\",\"@columns\",\"@displayDataChangedAction\"],[true,false,[32,1],[32,0,[\"columns\"]],[32,0,[\"transitionToItemDetail\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"gte\",\"if\",\"eq\"]}",
    "moduleName": "alliance-business-suite/templates/components/inventory/item-list-table.hbs"
  });

  _exports.default = _default;
});