define("alliance-business-suite/helpers/lookup-property-value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (_ref, namedArgs) {
    let [value, ...rest] = _ref;

    /*
     Returns the property value found in the collection.
     Properties of namedArgs:
        collection - array of objects
        value - the value we are looking for in the collection
        key - object property to match
        property - name of property whose value is returned
     */
    let key = namedArgs.key || 'id';
    let returnPropertyName = namedArgs.property || 'name';
    let found = namedArgs.collection.find(item => {
      return item[key] == namedArgs.value;
    });
    return found[returnPropertyName];
  });

  _exports.default = _default;
});