define("alliance-business-suite/components/products/categories-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { alias, reads } from '@ember/object/computed';
  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    classNames: ['inventory-section'],
    currentView: 'canView',
    showCreateItemModal: false,
    showCategoryModal: false,
    sortedCategories: Ember.computed('categories.{[],@each.isNew}', function () {
      let list = this.categories.sortBy('name');
      return list.filterBy('isNew', false);
    }),
    columns: Ember.computed(function () {
      return [{
        propertyName: 'name',
        title: this.intl.t('name'),
        component: 'table/table-cell-subtitle'
      }, {
        propertyName: 'description',
        title: this.intl.t('description'),
        component: 'table/table-cell-subtitle'
      }, {
        propertyName: 'upsellCategories',
        title: this.intl.t('upsell_categories'),
        component: 'table/retail/categories/table-cell-upsell-categories'
      }];
    }),

    showEditCategory(record, tableContext, self) {
      let recordId = record.selectedItems[0].get('id');
      Ember.set(self, 'editCategory', tableContext.store.peekRecord('item-category', recordId));
      Ember.set(self, 'showCategoryModal', true);
    },

    actions: {
      showCreateCategoryModal() {
        Ember.set(this, 'editCategory', this.store.createRecord('item-category'));
        Ember.set(this, 'showCategoryModal', true);
      },

      closeCategoryModal(item) {
        // If this is a new item, delete it from the store.
        if (item && item.isNew) {
          item.destroy();
          this.notifyPropertyChange('categories');
        } else if (item && item.hasDirtyAttributes) {
          item.rollbackAttributes();
        }

        Ember.set(this, 'showCategoryModal', false);
      },

      saveCategory(category) {
        let itemExists = Ember.get(this, "categories").any(item => {
          return item !== category && item.name.trim() === category.name.trim(); // TODO: Trim things
        });

        if (itemExists) {
          alert(`${category.name} already exists as a category.`); // TODO: i18n

          return false;
        } // Check lengths


        if (category.name.length < 2 || category.name.length > 12) {
          alert(this.intl.t('validation_category_name'));
          return false;
        }

        if (category.description.length < 6 || category.description.length > 60) {
          alert(this.intl.t('validation_category_description'));
          return false;
        }

        category.save().then(() => {
          this.send('closeCategoryModal');
        }); // TODO: Handle failures
      },

      deleteCategory(category) {
        // Show warning if other items have this category
        let itemExists = Ember.get(this, "items").any(item => {
          return Ember.get(item, 'itemCategory.name') == category.name; // TODO: Trim things
        });
        let itemsInCategory = Ember.get(this, 'items').reduce((preVal, item) => {
          return preVal += Ember.get(item, 'itemCategory.id') == category.id ? 1 : 0;
        }, 0);

        if (itemsInCategory > 0 && !confirm(`There are ${itemsInCategory} items in this category. Delete this category?`)) {
          return false;
        } else {
          category.destroyRecord().then(() => {
            this.send('closeCategoryModal');
          });
        }
      }

    }
  });

  _exports.default = _default;
});