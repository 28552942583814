define("alliance-business-suite/utils/machine-controls-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.processCommand = processCommand;
  _exports.processOutOfOrder = processOutOfOrder;
  _exports.sendMachineCommand = sendMachineCommand;

  async function processCommand(uri, machine, options) {
    let response, command;
    let self = this;
    let requestBody = {
      method: 'POST',
      data: {}
    };

    if (options && options.type == 'startReason') {
      requestBody.data.params = {
        remoteStartReason: {
          typeLookupKey: options.typeLookupKey,
          notes: options.notes
        }
      };
    }

    if (options && options.type == 'freeVend') {
      requestBody.data.params = {
        vendAmount: options.value
      };
    }

    this.isCommandProcessing = true;

    try {
      response = await this.ajax.request(uri, requestBody).then(() => {
        this.isCommandProcessing = false;
        this.notifications.info(this.intl.t('your_command_has_been_sent_to_the_machine'), {
          autoClear: true,
          clearDuration: 10000
        });

        if (options) {
          this.close();
        }
      });
    } catch (error) {
      this.isCommandProcessing = false;

      if (error.payload.message) {
        if (error.payload.message == "REMOTE_START_THRESHOLD_REACHED_NO_OVERRIDE") {
          this.notifications.error(this.intl.t('error_remote_start_threshold_reached'), {
            autoClear: true,
            clearDuration: 60000
          });
        }

        if (error.payload.message == "REMOTE_START_THRESHOLD_REACHED") {
          if (confirm(self.intl.t('confirm_force_start'))) {
            requestBody.data.params.forceRemoteStart = true;
            self.isCommandProcessing = true;
            self.ajax.request(uri, requestBody).then(() => {
              self.notifications.info(self.intl.t('your_command_has_been_sent_to_the_machine'), {
                autoClear: true,
                clearDuration: 10000
              });
            });
          }
        }

        this.close();
        return;
      }

      this.notifications.error(this.intl.t('please_try_again'), {
        autoClear: true,
        clearDuration: 60000
      });
      return; // fail silently
    }

    if (response) {
      try {
        this.isCommandProcessing = false;
        command = await this.store.findRecord('command', response.data.id); // get(machine, 'commands').addObject(command);

        machine.commands.addObject(command);
      } catch (error) {
        this.isCommandProcessing = false;
        console.error(error);
        return; // fail silently
      }
    }
  }

  async function processOutOfOrder(machineId, setToOurOfOrder) {
    let requestBody = {
      method: 'POST'
    };
    let response, command;
    let obj = {
      params: {
        enabled: setToOurOfOrder
      }
    };
    requestBody.data = obj;
    this.isCommandProcessing = true;

    try {
      response = await this.ajax.request(`/machines/${machineId}/programOutOfOrder`, requestBody).then(() => {
        this.isCommandProcessing = false;
        this.notifications.info(this.intl.t('your_command_has_been_sent_to_the_machine'), {
          autoClear: true,
          clearDuration: 60000
        });
      });
    } catch (error) {
      this.isCommandProcessing = false;
      this.notifications.error(this.intl.t('please_try_again'), {
        autoClear: true,
        clearDuration: 60000
      });
      return; // fail silently
    }

    if (response) {
      try {
        this.isCommandProcessing = false;
        command = await this.store.findRecord('command', response.data.id);
        machineId.commands.addObject(command);
      } catch (error) {
        this.isCommandProcessing = false;
        return; // fail silently
      }
    }
  }

  async function sendMachineCommand() {
    let {
      model
    } = this.args;
    let currentCommand = this.currentCommand || this.machineCommandOptions.get('firstObject.value');
    let id = model.get('id');
    this.startDryerType = null;
    console.log('currentCommand:', currentCommand); // confirmed

    if (currentCommand == 'fetchProgram') {
      this.toggleProgramModal();
      return;
    } // confirmed


    if (currentCommand == 'start') {
      this.toggleStartMachineModal();
      return;
    } // confirmed


    if (currentCommand == 'startDryerWithMinutes') {
      this.startDryerType = 'startDryer';
      this.toggleStartDryerModal();
      return;
    }

    if (currentCommand == 'freeVend') {
      this.toggleRemoteVendModal();
      return;
    }

    if (currentCommand == 'topOff') {
      this.startDryerType = 'topOff';
      this.toggleStartDryerModal();
      return;
    }

    if (currentCommand == 'advanceToCycle') {
      this.toggleAdvanceToCycle();
      return;
    } // confirmed


    if (currentCommand == 'programOutOfOrder') {
      await processOutOfOrder.call(this, id, false);
      return;
    }

    if (currentCommand == 'programBackInOrder') {
      await processOutOfOrder.call(this, id, true);
      return;
    }

    if (currentCommand == 'startTraceability') {
      this.toggleStartTrace();
      return;
    }

    if (currentCommand) {
      await processCommand.call(this, `/machines/${id}/${currentCommand}`, model);
    }
  }
});