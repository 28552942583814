define("alliance-business-suite/templates/components/machines/programs/pricing/midas-wx-modal-increase-spin-speed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FsmzuoB5",
    "block": "{\"symbols\":[\"ui\",\"@hideModal\"],\"statements\":[[8,\"form-ui\",[],[[\"@object\",\"@machineProgram\",\"@formSettings\",\"@selectOptions\",\"@machineSettings\",\"@formDisplay\"],[[32,0,[\"object\"]],[32,0,[\"machineProgram\"]],[32,0,[\"formSettings\"]],[32,0,[\"selectOptions\"]],[32,0,[\"machineSettings\"]],\"compact\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[8,[32,1,[\"columns\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"column\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,1,[\"section\"]],[],[[\"@title\"],[\"increase_spin_speed\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,1,[\"select\"]],[],[[\"@params\"],[[30,[36,0],null,[[\"propertyName\",\"options\",\"labelKey\",\"hasCustom\",\"sharedCustomProperty\",\"showPresets\",\"customType\",\"speedSettings\",\"machineSettings\"],[\"Program.Price.ModifierOptions.IncreasedSpinSpeed_WX\",[32,0,[\"optionList\"]],\"speed\",true,true,[32,0,[\"showPresets\"]],\"speed\",[32,0,[\"speedSettings\"]],[32,1,[\"machineSettings\"]]]]]]],null],[2,\"\\n\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[11,\"button\"],[24,0,\"button--ok\"],[24,4,\"button\"],[4,[38,1],[\"click\",[32,2]],null],[12],[1,[30,[36,2],[\"OK\"],null]],[13],[2,\"\\n\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"hash\",\"on\",\"t\"]}",
    "moduleName": "alliance-business-suite/templates/components/machines/programs/pricing/midas-wx-modal-increase-spin-speed.hbs"
  });

  _exports.default = _default;
});