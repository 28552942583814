define("alliance-business-suite/templates/components/locations/locations-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+IxIYd/D",
    "block": "{\"symbols\":[\"room\",\"@rooms\"],\"statements\":[[10,\"section\"],[14,0,\"content-container\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"filter-container filter-select\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"reports-controls\"],[12],[2,\"\\n      \"],[8,\"power-select-multiple\",[],[[\"@options\",\"@placeholder\",\"@selected\",\"@searchEnabled\",\"@searchField\",\"@onChange\"],[[32,2],[30,[36,0],[\"filter_location\"],null],[32,0,[\"activeLocation\"]],true,\"name\",[30,[36,2],[[30,[36,1],[[32,0,[\"activeLocation\"]]],null]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[1,[32,1,[\"formattedBreadcrumbDisplay\"]]],[2,\"\\n      \"]],\"parameters\":[1]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[8,\"maps/google-map\",[],[[\"@height\",\"@zoom\",\"@markers\",\"@markerTooltipOpen\"],[\"1000px\",15,[32,0,[\"markers\"]],\"true\"]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"mut\",\"fn\"]}",
    "moduleName": "alliance-business-suite/templates/components/locations/locations-map.hbs"
  });

  _exports.default = _default;
});