define("alliance-business-suite/components/side-navigation", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "alliance-business-suite/config/environment"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  let SideNavigationComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, (_class = class SideNavigationComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "session", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "showChat", _descriptor4, this);
    }

    get versionNumber() {
      return _environment.default.version;
    }

    get isDevelopment() {
      return _environment.default.configEnvironment == 'development';
    }

    closeSidebar() {
      this.args.burger.state.actions.close();
    }

    toggleChat() {
      this.showChat = !this.showChat; // Close the sidebar menu

      if (this.args.burger) {
        this.args.burger.state.actions.close();
      }
    }

    toggleOplMode() {
      this.access.toggleOplMode();
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "showChat", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "closeSidebar", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "closeSidebar"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "toggleChat", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "toggleChat"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "toggleOplMode", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "toggleOplMode"), _class.prototype)), _class));
  _exports.default = SideNavigationComponent;
});