define("alliance-business-suite/models/deposit-reward", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    access: Ember.inject.service(),
    depositThreshold: attr('number'),
    depositFlatAmount: attr('number'),
    depositMultiplier: attr('number', {
      defaultValue: 0
    }),
    runFromDate: attr('', {
      defaultValue: null
    }),
    runToDate: attr('', {
      defaultValue: null
    }),
    rewardsProgram: belongsTo('rewards-program'),
    // this computed needs to be based on the other computed instead of the model property to work for all deposit rewards
    depositFinalAmount: Ember.computed('rewardsProgram.depositRewards.@each.{depositThreshold,depositFlatAmount}', {
      get() {
        let amount = this.depositThreshold;
        let max = this.depositThreshold;
        let deposits = this.get('rewardsProgram.depositRewards');

        if (deposits) {
          deposits.forEach(function (deposit) {
            if (deposit.get('depositThreshold') <= max) {
              amount += deposit.get('depositFlatAmount');
            }
          });
        }

        return amount || 0;
      },

      set() {
        return this.depositFinalAmount; // do nothing because this is purely computed
      }

    }),
    // this computed needs to be based on the other computed instead of the model property to work for all deposit rewards
    depositPreviousAmount: Ember.computed('rewardsProgram.depositRewards.@each.{depositThreshold,depositFlatAmount}', {
      get() {
        let amount = 0;
        let max = this.depositThreshold;
        let deposits = this.get('rewardsProgram.depositRewards');

        if (deposits) {
          deposits.forEach(function (deposit) {
            if (deposit.get('depositThreshold') < max) {
              amount += deposit.get('depositFlatAmount');
            }
          });
        }

        return amount;
      },

      set() {
        return this.depositPreviousAmount; // do nothing because this is purely computed
      }

    })
  });

  _exports.default = _default;
});