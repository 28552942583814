define("alliance-business-suite/helpers/fixed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.fixed = fixed;

  function fixed(params) {
    return params[0].toFixed(params[1]);
  }

  var _default = Ember.Helper.helper(fixed);

  _exports.default = _default;
});