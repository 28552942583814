define("alliance-business-suite/models/account-transaction", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-data/model"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15;

  // NOTE: This is a virtual model. It exists in the Ember app and has a limited
  //       implementation in the API, but currently doesn't exist in the server
  //       database anywhere. [twl 5.May.17]
  let AccountTransactionModel = (_dec = (0, _model.attr)('number'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('number'), _dec4 = (0, _model.attr)('boolean'), _dec5 = (0, _model.attr)('date'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('date'), _dec8 = (0, _model.attr)('date'), _dec9 = (0, _model.attr)('number'), _dec10 = (0, _model.attr)(), _dec11 = (0, _model.belongsTo)('user'), _dec12 = (0, _model.belongsTo)('account'), _dec13 = (0, _model.belongsTo)('paymentMethod'), _dec14 = (0, _model.belongsTo)('user', {
    inverse: null
  }), _dec15 = (0, _model.belongsTo)('user', {
    inverse: null
  }), (_class = class AccountTransactionModel extends _model.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "amount", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "description", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "userAccountBalanceAmount", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "isCredit", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "transactionDate", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "transactionNonce", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "createdAt", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "updatedAt", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "depositRewardsEarned", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "userAccountTransactionType", _descriptor10, this);
      (0, _initializerDefineProperty2.default)(this, "user", _descriptor11, this);
      (0, _initializerDefineProperty2.default)(this, "account", _descriptor12, this);
      (0, _initializerDefineProperty2.default)(this, "paymentMethod", _descriptor13, this);
      (0, _initializerDefineProperty2.default)(this, "createdBy", _descriptor14, this);
      (0, _initializerDefineProperty2.default)(this, "updatedBy", _descriptor15, this);
    } // isRefund: computed('userAccountTransactionType', {
    //   get() {
    //     let transactionType = get(this, 'userAccountTransactionType.id');
    //
    //     return transactionType && transactionType.indexOf('REFUND') !== -1;
    //   }
    // }).readOnly(),
    //
    // isRefundable: computed('isCredit', 'isRefund', {
    //   get() {
    //     return !get(this, 'isCredit') && !get(this, 'isRefund');
    //   }
    // }).readOnly()


  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "amount", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "description", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "userAccountBalanceAmount", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isCredit", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "transactionDate", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "transactionNonce", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "createdAt", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "updatedAt", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "depositRewardsEarned", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "userAccountTransactionType", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "user", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "account", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "paymentMethod", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "createdBy", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "updatedBy", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = AccountTransactionModel;
});