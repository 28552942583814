define("alliance-business-suite/templates/components/ui/context-menu-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uFN+EnED",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[10,\"i\"],[14,0,\"fa fa-check menu-checked\"],[12],[13]],\"parameters\":[]}]]],[2,[34,2]]],\"hasEval\":false,\"upvars\":[\"checked\",\"if\",\"label\"]}",
    "moduleName": "alliance-business-suite/templates/components/ui/context-menu-item.hbs"
  });

  _exports.default = _default;
});