define("alliance-business-suite/components/ui/section-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const componentClassName = 'ui-section-panel';
  /**
   * Renders a panel with an optional title.
   *
   * # Named Parameters
   *
   * type
   * : The type of panel to render. Used to apply variant styles to this panel.
   *
   * # Yielded Variables
   *
   * header
   * : A component used to render the header. See `section-panel-header` for the
   *   properties to pass into this component.
   *
   * contents
   * : A component that yields its contents to render the main contents for this
   *   panel. Can be styled using the `__contents` element style in this
   *   component's style definition file.
   *
   * group
   * : A component that yields its contents to render a group within this panel.
   *   Can be styled using the `__group` element style in this component's style
   *   definition file.
   *
  * @public
   */

  var _default = Ember.Component.extend({
    /**
     * The "block" component class name for this component in the
     * Block-Element-Modifier (BEM) naming convention.
     *
     * @type {Array<String>}
     * @public
     */
    componentClassName,

    /**
     * The static class names for this component.
     *
     * @type {Array<String>}
     * @public
     */
    // TODO: Remove the `listing-container` once all styles migrated. [twl 6.Jul.17]
    classNames: ['listing-container', componentClassName],

    /**
     * The dynamic class names for this component that are derived from component
     * properties.
     *
     * @type {Array<String>}
     * @public
     */
    classNameBindings: ['typeClassName'],

    /**
     * The class name that specifies the horizontal alignment of this pill.
     *
     * @type {String}
     * @public
     */
    typeClassName: Ember.computed('type', {
      get() {
        let type = this.type;
        return type ? `${componentClassName}--${type}` : undefined;
      }

    }),

    /**
     * The tag name used for this component; an empty string prevents a DOM
     * element from being created, instead rendering the template for this
     * component directly underneath its parent.
     *
     * @protected
     */
    tagName: 'section'
  });

  _exports.default = _default;
});