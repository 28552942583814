define("alliance-business-suite/models/user-role", ["exports", "ember-data", "alliance-business-suite/models/mixins/timestamped"], function (_exports, _emberData, _timestamped) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend(_timestamped.default, {
    roleType: belongsTo('role-type'),
    relationshipType: attr('string'),
    // NOTE: If you add additional relationships here, you may need to override
    //       them in `access-user-role`, so we don't have priviledged data
    //       records being pointed to by unpriviledged records. [twl 16.May.17]
    user: belongsTo('user'),
    organization: belongsTo('organization')
  });

  _exports.default = _default;
});