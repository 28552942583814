define("alliance-business-suite/components/products/taxes-list-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    access: Ember.inject.service(),
    router: Ember.inject.service(),
    intl: Ember.inject.service(),
    classNames: ['taxes-list-table'],
    intlPrefix: 'components.crm',
    tagName: '',
    showComponentFooter: false,
    showColumnsDropdown: false,
    useFilteringByColumns: false,
    showGlobalFilter: false,
    useNumericPagination: false,
    doFilteringByHiddenColumns: false,
    filteringIgnoreCase: false,
    multipleColumnsSorting: false,
    showPageSize: false,
    collapseNumPaginationForPagesCount: 1,
    pageSize: 100,
    columns: Ember.computed(function () {
      return [{
        propertyName: 'description',
        title: this.intl.t('description')
      }, {
        propertyName: 'rate',
        title: this.intl.t('room_tax'),
        suffix: '%',
        component: 'table/table-cell-quantity-amount'
      }, {
        component: 'table/table-cell-button-options',
        action: this.toggleTaxModal,
        params: ['record'],
        buttonClass: 'button button--secondary button--small',
        buttonContent: ['<i class="fa fa-cog" aria-hidden="true"></i>', '<i class="fa fa-plus" aria-hidden="true"></i>'],
        disableSorting: true
      }];
    })
  });

  _exports.default = _default;
});