define("alliance-business-suite/helpers/format-number-custom", ["exports", "moment", "numeral", "alliance-business-suite/utils/locale"], function (_exports, _moment, _numeral, _locale) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),
    access: Ember.inject.service(),
    store: Ember.inject.service(),

    compute(_ref, hash) {
      let [...rest] = _ref;
      let {
        value,
        convertNumToBool,
        skipNumberFormatting,
        skipEmptyValues
      } = hash;
      let result = value;

      if (convertNumToBool && typeof value === "boolean") {
        return value;
      }

      if (skipEmptyValues && (!value || value.length === 0)) {
        return '';
      } // if a number, then conver to number formatting (1000 -> 1,000)


      if (!isNaN(value) && !skipNumberFormatting) {
        result = this.intl.formatNumber(value);
      }

      return result;
    }

  });

  _exports.default = _default;
});