define("alliance-business-suite/components/rooms/modal-financials", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "jquery"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28;

  // import { inject as service } from '@ember/service';
  // import Component from '@ember/component';
  // import { observer, computed, set, get } from '@ember/object';
  // import { A } from '@ember/array';
  const RENT_INTERVALS = [{
    text: 'monthly',
    id: 'MONTHLY'
  }, {
    text: 'quarterly',
    id: 'QUARTERLY'
  }, {
    text: 'yearly',
    id: 'YEARLY'
  }];
  let RoomsModalFinancialComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember._tracked, _dec18 = Ember._tracked, _dec19 = Ember._tracked, _dec20 = Ember._tracked, _dec21 = Ember._tracked, _dec22 = Ember._tracked, _dec23 = Ember._tracked, _dec24 = Ember._tracked, _dec25 = Ember._tracked, _dec26 = Ember._tracked, _dec27 = Ember._tracked, _dec28 = Ember._tracked, _dec29 = Ember._action, _dec30 = Ember._action, _dec31 = Ember._action, (_class = class RoomsModalFinancialComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "ajax", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "isLoading", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "incomeId", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "vendingAmount", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "otherAmountOne", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "otherAmountTwo", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "expenseId", _descriptor10, this);
      (0, _initializerDefineProperty2.default)(this, "rentTypeLookupKey", _descriptor11, this);
      (0, _initializerDefineProperty2.default)(this, "rentAmount", _descriptor12, this);
      (0, _initializerDefineProperty2.default)(this, "propertyTaxAmount", _descriptor13, this);
      (0, _initializerDefineProperty2.default)(this, "gasRatePerCuFt", _descriptor14, this);
      (0, _initializerDefineProperty2.default)(this, "waterUtilityRatePerGallon", _descriptor15, this);
      (0, _initializerDefineProperty2.default)(this, "electricExpense", _descriptor16, this);
      (0, _initializerDefineProperty2.default)(this, "sewerUtilityRatePerGallon", _descriptor17, this);
      (0, _initializerDefineProperty2.default)(this, "heatUtilityRatePerGallon", _descriptor18, this);
      (0, _initializerDefineProperty2.default)(this, "servicesExpense", _descriptor19, this);
      (0, _initializerDefineProperty2.default)(this, "laborExpense", _descriptor20, this);
      (0, _initializerDefineProperty2.default)(this, "debtOneExpense", _descriptor21, this);
      (0, _initializerDefineProperty2.default)(this, "debtTwoExpense", _descriptor22, this);
      (0, _initializerDefineProperty2.default)(this, "insuranceExpense", _descriptor23, this);
      (0, _initializerDefineProperty2.default)(this, "maintenanceExpense", _descriptor24, this);
      (0, _initializerDefineProperty2.default)(this, "marketingExpense", _descriptor25, this);
      (0, _initializerDefineProperty2.default)(this, "suppliesExpense", _descriptor26, this);
      (0, _initializerDefineProperty2.default)(this, "otherExpenseOne", _descriptor27, this);
      (0, _initializerDefineProperty2.default)(this, "otherExpenseTwo", _descriptor28, this);
    }

    get rentIntervals() {
      return RENT_INTERVALS;
    }

    fetchRoomInfo() {
      let roomId = this.args.room.id;
      let self = this;
      this.isLoading = true;
      this.ajax.request(`/rooms/${roomId}/income?raw=true`, {
        method: 'GET'
      }).then(response => {
        let props = response[0];
        this.incomeId = props.id;
        this.vendingAmount = props.vendingAmount || 0;
        this.otherAmountOne = props.otherIncomeAmountOne || 0;
        this.otherAmountTwo = props.otherIncomeAmountTwo || 0;
        this.ajax.request(`/rooms/${roomId}/expense?raw=true`, {
          method: 'GET'
        }).then(response => {
          let props = response[0];
          let rentTypeLookupKey;

          if (props.rentTypeLookupKey) {
            rentTypeLookupKey = {
              id: props.rentTypeLookupKey,
              text: props.rentTypeLookupKey.toLowerCase()
            };
          }

          self.isLoading = false;
          self.expenseId = props.id;
          self.rentTypeLookupKey = rentTypeLookupKey;
          self.rentAmount = props.rentAmount || 0;
          self.propertyTaxAmount = props.propertyTaxAmount || 0;
          self.gasRatePerCuFt = props.gasRatePerCuFt || 0;
          self.waterUtilityRatePerGallon = props.waterUtilityRatePerGallon || 0;
          self.electricExpense = props.electricExpense || 0;
          self.sewerUtilityRatePerGallon = props.sewerUtilityRatePerGallon || 0;
          self.heatUtilityRatePerGallon = props.heatUtilityRatePerGallon || 0;
          self.servicesExpense = props.servicesExpense || 0;
          self.laborExpense = props.laborExpense || 0;
          self.debtOneExpense = props.debtOneExpense || 0;
          self.debtTwoExpense = props.debtTwoExpense || 0;
          self.insuranceExpense = props.insuranceExpense || 0;
          self.maintenanceExpense = props.maintenanceExpense || 0;
          self.marketingExpense = props.marketingExpense || 0;
          self.suppliesExpense = props.suppliesExpense || 0;
          self.otherExpenseOne = props.otherExpenseOne || 0;
          self.otherExpenseTwo = props.otherExpenseTwo || 0;
        });
      });
    }

    get currencySymbol() {
      return this.access.currencyDenomination;
    }

    get currencyDecimals() {
      if (this.access.currencySubunit === 1) {
        return 0;
      } else {
        return 2;
      }
    }

    convertToSafe(value) {
      if (!value || value < 0) {
        return 0;
      }

      return value;
    }

    convertToCurrentUnit(value, type) {
      let currencyDecimals = this.currencyDecimals;
      let multiple = 100;

      if (currencyDecimals == 0) {
        multiple = 1;
      }

      if (type == 'output') {
        return parseInt(value * multiple);
      }

      if (type == 'input') {
        return value / multiple;
      }
    }

    close() {
      this.args.closeModal();
    }

    submit() {
      if (this.access.isDemo) {
        alert('Demo mode restricted.');
        return;
      }

      const roomId = this.args.room ? this.args.room.get('id') : null;

      if (!roomId) {
        alert("There was an error saving data, please refresh and try again.");
        return;
      }

      this.isLoading = true;
      this.ajax.request(`/rooms/${roomId}/income?raw=true`, {
        method: 'PATCH',
        data: {
          'vendingAmount': this.vendingAmount,
          'otherAmountOne': this.otherAmountOne,
          'otherAmountTwo': this.otherAmountTwo
        }
      }).then(result => {
        this.ajax.request(`/rooms/${roomId}/expense?raw=true`, {
          method: 'PATCH',
          data: {
            'rentTypeLookupKey': this.rentTypeLookupKey ? this.rentTypeLookupKey.id : null,
            'rentAmount': this.rentAmount,
            'propertyTaxAmount': this.propertyTaxAmount,
            'gasRatePerCuFt': this.convertToSafe(this.gasRatePerCuFt),
            'waterUtilityRatePerGallon': this.convertToSafe(this.waterUtilityRatePerGallon),
            'electricExpense': this.electricExpense,
            'sewerUtilityRatePerGallon': this.convertToSafe(this.sewerUtilityRatePerGallon),
            'heatUtilityRatePerGallon': this.convertToSafe(this.heatUtilityRatePerGallon),
            'servicesExpense': this.servicesExpense,
            'laborExpense': this.laborExpense,
            'debtOneExpense': this.debtOneExpense,
            'debtTwoExpense': this.debtTwoExpense,
            'insuranceExpense': this.insuranceExpense,
            'maintenanceExpense': this.maintenanceExpense,
            'marketingExpense': this.marketingExpense,
            'suppliesExpense': this.suppliesExpense,
            'otherExpenseOne': this.otherExpenseOne,
            'otherExpenseTwo': this.otherExpenseTwo
          }
        }).catch(result => {
          alert("There was an error saving data, please try again.");
        }).finally(result => {
          this.isLoading = false;
        });
      });
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isLoading", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "incomeId", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "vendingAmount", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "otherAmountOne", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "otherAmountTwo", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "expenseId", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "rentTypeLookupKey", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "rentAmount", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "propertyTaxAmount", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "gasRatePerCuFt", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "waterUtilityRatePerGallon", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "electricExpense", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "sewerUtilityRatePerGallon", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "heatUtilityRatePerGallon", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "servicesExpense", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "laborExpense", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "debtOneExpense", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "debtTwoExpense", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "insuranceExpense", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "maintenanceExpense", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "marketingExpense", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "suppliesExpense", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "otherExpenseOne", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "otherExpenseTwo", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "fetchRoomInfo", [_dec29], Object.getOwnPropertyDescriptor(_class.prototype, "fetchRoomInfo"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "close", [_dec30], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "submit", [_dec31], Object.getOwnPropertyDescriptor(_class.prototype, "submit"), _class.prototype)), _class));
  _exports.default = RoomsModalFinancialComponent;
});