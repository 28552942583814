define("alliance-business-suite/components/products/services-list-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    access: Ember.inject.service(),
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    classNames: ['services-list-table'],
    intlPrefix: 'components.crm',
    tagName: '',
    showComponentFooter: false,
    showColumnsDropdown: false,
    useFilteringByColumns: false,
    showGlobalFilter: false,
    useNumericPagination: false,
    doFilteringByHiddenColumns: false,
    filteringIgnoreCase: false,
    multipleColumnsSorting: false,
    showPageSize: false,
    pageSize: 100,
    collapseNumPaginationForPagesCount: 1,
    columns: Ember.computed(function () {
      return [{
        propertyName: 'description',
        title: `${this.intl.t('name')}`
      }, {
        propertyName: 'type',
        title: this.intl.t('type')
      }, {
        propertyName: 'price',
        title: this.intl.t('price'),
        component: 'table/table-cell-price'
      }, {
        propertyName: 'isFlatAmount',
        title: this.intl.t('flat_amount'),
        component: 'table/table-cell-flat-amount'
      }, {
        propertyName: 'taxRates',
        title: this.intl.t('room_tax'),
        component: 'table/table-cell-list',
        format: '<<description>> <<rate>>%'
      }, {
        component: 'table/table-cell-button-options',
        action: this.toggleEditModal,
        params: ['record'],
        buttonClass: 'button button--secondary button--small',
        buttonContent: '<i class="fa fa-cog" aria-hidden="true"></i>',
        disableSorting: true
      }];
    })
  });

  _exports.default = _default;
});