define("alliance-business-suite/templates/components/rewards/location-rewards", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e10DfjoR",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"section--container-v2 listing-container-alt margin-top-0\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"product-banner\"],[12],[2,\"\\n    \"],[10,\"h2\"],[12],[1,[30,[36,0],[\"rewards_programs_must_have_a_location\"],null]],[13],[2,\"\\n    \"],[10,\"p\"],[12],[1,[30,[36,0],[\"location_rewards_disclaimer\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"model-field form-field\"],[12],[2,\"\\n    \"],[10,\"label\"],[14,0,\"model-field__label\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"send_reward_program\"],null]],[2,\"\\n      \"],[10,\"span\"],[14,0,\"model-field__subtitle\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"select_where_you_wish_this_reward_program_to_apply\"],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[8,\"locations/rewards/r-edit-form\",[],[[\"@isInitLoading\",\"@model\",\"@rewardId\",\"@gbWithRewardsPrograms\",\"@selectedGeoboundaries\",\"@selectedTreeNodes\",\"@editDisabled\"],[[34,1],[34,2],[34,3,[\"accessRewardProgramId\"]],[34,4],[34,5],[34,6],[34,7]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"isInitLoading\",\"parentModel\",\"access\",\"gbWithRewardsPrograms\",\"selectedGeoboundaries\",\"selectedTreeNodes\",\"editDisabled\"]}",
    "moduleName": "alliance-business-suite/templates/components/rewards/location-rewards.hbs"
  });

  _exports.default = _default;
});