define("alliance-business-suite/components/machines/programs/cycles/cycle-info-mc10-opl", ["exports", "alliance-business-suite/mixins/machines/programs/mc10-options", "jquery"], function (_exports, _mc10Options, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_mc10Options.default, {
    didInsertElement() {
      this._super(...arguments);

      (0, _jquery.default)(this.element).focus();
      Mousetrap.bind(['esc', 'enter'], (e, combo) => {
        e.preventDefault();
        e.stopPropagation();
        Ember.get(this, 'close')();
      });
    },

    destroy() {
      this._super(...arguments); // Clean up keyboard shortcuts


      Mousetrap.unbind(['esc', 'enter']);
    },

    actions: {
      selectDefaultTemperature(value) {
        // Update this property and check the same item in allowed temperatures.
        Ember.set(this, 'model.DefaultTemp', value.val);
        Ember.set(this, `model.TempOptions.${value.key}`, true);
      }

    }
  });

  _exports.default = _default;
});