define("alliance-business-suite/mixins/can", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    access: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    roles: Ember.computed('session.data.securityRoles.[]', function () {
      return this.get('session.data.securityRoles') || [];
    }),

    userHasRole(whoCanAccess) {
      let currentUserSecurityRoleName = this.session.get('data.currentUserSecurityRoleName');
      return whoCanAccess.indexOf(currentUserSecurityRoleName) >= 0; // let userRoles = this.get('session').get('data.securityRoles') || [];
      //
      // if (userRoles.length === 0) {
      //   return false;
      // }
      //
      // // Check each userRole against the list of allowed roles
      // return userRoles.any((role) => {
      //   // console.log('1 role', role);
      //   // console.log('2 role', role.get('name'));
      //   // console.log('3 role', role.name);
      //
      //   let roleName;
      //
      //   if (role.name) {
      //     roleName = role.name;
      //   } else {
      //     roleName = role.get('name')
      //   }
      //   return (whoCanAccess.indexOf(roleName) >= 0);
      // });
    }

  });

  _exports.default = _default;
});