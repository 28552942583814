define("alliance-business-suite/templates/machines/traceability", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "W+VlsXVO",
    "block": "{\"symbols\":[],\"statements\":[[8,\"machines/traceability/traceability-section\",[],[[\"@queryLocId\",\"@queryTraceId\",\"@querySectionId\",\"@updateQueryParam\"],[[32,0,[\"locId\"]],[32,0,[\"traceId\"]],[32,0,[\"sectionId\"]],[32,0,[\"updateQueryParam\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "alliance-business-suite/templates/machines/traceability.hbs"
  });

  _exports.default = _default;
});