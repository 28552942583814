define("alliance-business-suite/adapters/guest", ["exports", "alliance-business-suite/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    buildURL(modelName, id, snapshot, requestType, query) {
      let url = this._super(modelName, id, snapshot, requestType, query);

      switch (requestType) {
        case 'deleteRecord':
          url = url.replace(`guests`, `pos/guests`);
          break;

        case 'updateRecord':
          url = url.replace('guests', 'pos/guests');
          break;
      }

      return url;
    }

  });

  _exports.default = _default;
});