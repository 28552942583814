define("alliance-business-suite/components/tree-table-date-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['format-date']
  });

  _exports.default = _default;
});