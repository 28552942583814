define("alliance-business-suite/adapters/account-refund", ["exports", "alliance-business-suite/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    /**
     * Builds the URL for a request.
     *
     * @returns {string} The URL to make the API request to
     * @protected
     */
    buildURL(modelName, id, snapshot, requestType, query) {
      let url = this._super(modelName, id, snapshot, requestType, query);

      switch (requestType) {
        case 'createRecord':
          url = url.replace(':accountTransactionId', snapshot.record.get('accountTransaction.id'));
          break;

        default:
          throw new Error('Refunds can only be created through the API');
      }

      return url;
    },

    /**
     * Returns the path for all requests to this adapter. If `sessionPath` is
     * defined on this adapter, pre-pends the path with the session path.
     *
     * @param {string} modelName The type of the model to use this adapter for
     *
     * @returns {string} The path to use when executing API requests for the model
     * @protected
     */
    pathForType() {
      return 'accountTransactions/:accountTransactionId/refund';
    }

  });

  _exports.default = _default;
});