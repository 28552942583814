define("alliance-business-suite/templates/summary/alerts/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hM53j+Kr",
    "block": "{\"symbols\":[],\"statements\":[[8,\"summary/alerts/s-edit-form\",[],[[\"@machineAuditErrorSubTemplates\",\"@machineErrorSubTemplates\",\"@rooms\",\"@reportQueryParams\",\"@report\"],[[32,0,[\"machineAuditErrorSubTemplates\"]],[32,0,[\"machineErrorSubTemplates\"]],[32,0,[\"model\",\"rooms\"]],[32,0,[\"report\"]],[32,0,[\"model\",\"reportSubscription\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "alliance-business-suite/templates/summary/alerts/create.hbs"
  });

  _exports.default = _default;
});