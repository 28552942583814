define("alliance-business-suite/components/summary/reports/s-new-user", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "alliance-business-suite/utils/currency-format-util", "alliance-business-suite/utils/report-tables-util"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _currencyFormatUtil, _reportTablesUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  let SummaryReportsSNewUserComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, (_class = class SummaryReportsSNewUserComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor3, this);
    }

    get translatedColumns() {
      return [{
        name: 'Organization',
        valuePath: 'name',
        width: 325,
        isFixed: 'left'
      }, {
        name: 'username',
        valuePath: 'userName',
        width: 350
      }, {
        name: 'first_name',
        valuePath: 'firstName',
        width: 170
      }, {
        name: 'last_name',
        valuePath: 'lastName',
        width: 170
      }, {
        name: 'date_created',
        valuePath: 'accountCreatedAt',
        width: 225
      }, {
        name: 'first_amount_added',
        valuePath: 'firstAmount',
        width: 225
      }, {
        name: 'dare_first_amount_Added',
        valuePath: 'firstAmountCreatedAt',
        width: 225
      }, {
        name: 'location_pin',
        valuePath: 'firstLocationPin',
        width: 225
      }];
    }

    get rowsWithChildren() {
      let {
        model
      } = this.args;
      let geoBoundaries = model.geoBoundaries;
      let rowData = [];
      geoBoundaries.forEach(geoBoundary => {
        let userDataArray = [];
        rowData.push({
          name: _reportTablesUtil.localizeString.call(this, geoBoundary.name, 'Location', 'location'),
          children: userDataArray
        });
        geoBoundary.users.forEach(user => {
          let firstAmountDate = "";
          let accountCreatedDate = "";

          if (user.firstAmountCreatedAt) {
            firstAmountDate = moment(user.firstAmountCreatedAt).format(`${_reportTablesUtil.formatDateTimeString.call(this)}`);
          }

          if (user.accountCreatedAt) {
            accountCreatedDate = moment(user.accountCreatedAt).format(`${_reportTablesUtil.formatDateTimeString.call(this)}`);
          }

          userDataArray.push({
            userName: user.userName || 'N/A',
            firstName: user.firstName || 'N/A',
            lastName: user.lastName || 'N/A',
            accountCreatedAt: accountCreatedDate,
            firstAmount: _currencyFormatUtil.formatCurrencyString.call(this, user.firstAmount || 0),
            firstAmountCreatedAt: firstAmountDate,
            firstLocationPin: user.firstLocationPin
          });
        });
      });
      return rowData;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SummaryReportsSNewUserComponent;
});