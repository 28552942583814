define("alliance-business-suite/components/form-ui/base-price-multi-vend", ["exports", "alliance-business-suite/utils/array-utils", "alliance-business-suite/utils/machine-programming-property-names"], function (_exports, _arrayUtils, _machineProgrammingPropertyNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // eslint-disable-line ember/no-classic-classes
    tagName: '',
    allCyclesEnabled: Ember.computed('params.cycleEnabled', function () {
      return Ember.isNone(this.params.cycleEnabled);
    }),
    vendSelected: {},
    isArrayCollection: Ember.computed('params.arrayNames', 'object', 'index', function () {
      if (this.params.arrayNames === undefined) {
        return false;
      } // Make sure all arrayNames are defined


      return this.params.arrayNames.every(arrayName => {
        return Ember.get(this.object, (0, _machineProgrammingPropertyNames.replaceIndexPlaceholder)(arrayName, this.index)) !== undefined;
      });
    }),
    arrayCollection: Ember.computed('isArrayCollection', 'params.{propertyName,arrayNames.[]}', 'index', function () {
      let arrayCollection = [];
      let paramIndex = this.index;

      if (this.isArrayCollection) {
        // Get the resolved names of each arrayName
        let array1Name = (0, _machineProgrammingPropertyNames.replaceIndexPlaceholder)(this.params.arrayNames[0], paramIndex);
        let array2Name = (0, _machineProgrammingPropertyNames.replaceIndexPlaceholder)(this.params.arrayNames[1], paramIndex); // Get the full array of each arrayName

        let array1 = Ember.get(this, `object.${array1Name}`);
        let array2 = Ember.get(this, `object.${array2Name}`); // Store information about the arrays in arrayCollection

        array1.forEach((item, index) => {
          arrayCollection.push(`${array1Name}.${index}`);
        });
        array2.forEach((item, index) => {
          arrayCollection.push(`${array2Name}.${index}`);
        });
      } else {
        let arrayName = (0, _machineProgrammingPropertyNames.replaceIndexPlaceholder)(this.params.propertyName, paramIndex);
        let array = Ember.get(this, `object.${arrayName}`);
        array.forEach((item, index) => {
          arrayCollection.push(`${arrayName}.${index}`);
        });
      }

      return arrayCollection;
    }),

    firstValue() {
      let val = Ember.get(this.object, this.arrayCollection[0]);
      return val;
    },

    vendOptions: [{
      val: 0,
      label: 'SingleLevelVend'
    }, {
      val: 1,
      label: 'MultiLevelVend'
    }],
    propertyName: Ember.computed('params.{propertyName,index}', 'index', function () {
      let propertyName = this.params.propertyName;
      let index = this.params.index;

      if (Ember.isEmpty(index)) {
        index = this.index;
      }

      if (!Ember.isNone(index) && !Ember.isNone(propertyName)) {
        propertyName = propertyName.replace('${index}', index); // Replace the index placeholder if this is an array
      }

      return propertyName;
    }),
    rawValue: Ember.computed('object', 'propertyName', 'isArrayCollection', 'index', 'params.arrayNames.[]', function () {
      if (this.isArrayCollection) {
        let array1 = Ember.get(this, `object.${(0, _machineProgrammingPropertyNames.replaceIndexPlaceholder)(this.params.arrayNames[0], this.index)}`);
        let array2 = Ember.get(this, `object.${(0, _machineProgrammingPropertyNames.replaceIndexPlaceholder)(this.params.arrayNames[1], this.index)}`);
        return (0, _arrayUtils.arrayCollection)(array1, array2);
      } else {
        // If all array elements are the same value, this is Single Level Vend
        return Ember.get(this.object, this.propertyName);
      }
    }),
    isSingleVend: Ember.computed('firstValue', 'rawValue', function () {
      return this.rawValue.every(item => {
        return item == this.firstValue();
      });
    }),

    didReceiveAttrs() {
      this._super(...arguments);

      Ember.set(this, 'vendSelected', this.vendOptions[this.isSingleVend ? 0 : 1]);
    },

    single: Ember.computed('rawValue.{@each,0,[]}', {
      get() {
        return this.rawValue[0];
      },

      set(key, value) {
        // need to set all array elements to same value, then update `array`
        // Handle combined arrays differently
        if (this.isArrayCollection) {
          this.rawValue.forEach((item, index) => {
            this.update(this.object, this.arrayCollection[index], value);
          });
        } else {
          this.rawValue.forEach((item, index) => {
            this.update(this.object, `${this.propertyName}.${index}`, value);
          });
        }

        return value;
      }

    }),
    actions: {
      // eslint-disable-line ember/no-actions-hash
      changeVendType(vendType) {
        // If we change to single, show warning then reset all to the value of the first one
        // value.val: 1 = multi; 0 = single
        let warning = "Switching to single-level vend will overwrite existing vend prices. Are you sure?";
        let allSame = this.rawValue.every(item => {
          return vendType.val == item;
        });

        if (vendType.val === 0 && allSame) {
          Ember.set(this, 'vendSelected', vendType);
        } else if (vendType.val === 0 && !allSame && confirm(warning)) {
          // Change to single level
          Ember.set(this, 'vendSelected', vendType); // Reset values

          this.rawValue.forEach((item, index) => {
            this.update(this.object, this.arrayCollection[index], this.firstValue());
          });
          this.notifyPropertyChange('single');
        } else if (vendType.val === 1) {
          // Change to multi-level
          Ember.set(this, 'vendSelected', vendType);
        }
      }

    }
  });

  _exports.default = _default;
});