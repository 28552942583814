define("alliance-business-suite/components/ui/section-block", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    intl: Ember.inject.service(),
    titleIntl: Ember.computed('title', 'titleTranslated', function () {
      if (!Ember.isNone(this.title)) {
        return this.intl.t(this.title);
      } else {
        return this.titleTranslated;
      }
    })
  });

  _exports.default = _default;
});