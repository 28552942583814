define("alliance-business-suite/components/products/tax-form", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'description': [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    'rate': [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })]
  });

  var _default = Ember.Component.extend(Validations, {
    store: Ember.inject.service(),
    description: Ember.computed(function () {
      return this.get('tax.description');
    }),
    rate: Ember.computed(function () {
      return this.get('tax.rate') || 0;
    }),
    actions: {
      close() {
        this.close();
      },

      deleteTax() {
        let tax = this.store.peekRecord('tax-rate', this.get('tax.id'));
        this.set('isDeleting', true);
        this.set('isProcessing', true);
        tax.destroyRecord().then(() => {
          this.set('isDeleting', false);
          this.set('isProcessing', false);
          this.send('close');
        }, () => {
          this.set('isDeleting', false);
          this.set('isProcessing', false);
        });
      },

      addTax() {
        let description = this.description;
        let rate = this.rate;
        let tax = this.store.createRecord('tax-rate', {
          description,
          rate
        });
        this.set('isSaving', true);
        this.set('isProcessing', true);
        tax.save().then(() => {
          this.set('isSaving', false);
          this.set('isProcessing', false);
          this.send('close');
        }, () => {
          this.set('isSaving', false);
          this.set('isProcessing', false);
        });
      },

      saveTax() {
        let tax = this.tax;
        let description = this.description;
        let rate = this.rate;
        this.set('tax.description', description);
        this.set('tax.rate', rate);
        this.set('isSaving', true);
        this.set('isProcessing', true);
        tax.save().then(() => {
          this.set('isSaving', false);
          this.set('isProcessing', false);
          this.send('close');
        }, () => {
          this.set('isSaving', false);
          this.set('isProcessing', false);
        });
      }

    }
  });

  _exports.default = _default;
});