define("alliance-business-suite/mixins/filterify", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEBOUNCE_MS = 500;

  var _default = Ember.Mixin.create({
    searchTask: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(DEBOUNCE_MS);
      this.refresh();
    }).restartable(),
    actions: {
      search(term) {
        Ember.set(this, 'isSearching', true);
        Ember.set(this, 'searchTerm', term);
        Ember.get(this, 'searchTask').perform();
      }

    }
  });

  _exports.default = _default;
});