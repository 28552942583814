define("alliance-business-suite/adapters/security-role", ["exports", "alliance-business-suite/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    access: Ember.inject.service(),
    namespace: Ember.computed(function () {
      let organizationId = Ember.get(this, 'access.accessOrganization.id');
      return `organizations/${organizationId}`;
    }),

    buildURL(modelName, id, snapshot, requestType, query) {
      // let url = this._super(modelName, id, snapshot, requestType, query);
      let host = Ember.get(this, 'host');
      let organizationId = Ember.get(this, 'access.accessOrganization.id'); // return `organizations/${organizationId}`;

      switch (requestType) {
        case 'deleteRecord':
          return `${host}/securityRoles/${id}`;

        case 'updateRecord':
          return `${host}/organizations/${organizationId}/securityRoles/${id}`;

        default:
          // return `organizations/${organizationId}`;
          return `${host}/organizations/${organizationId}/securityRoles`;
      }
    }

  });

  _exports.default = _default;
});