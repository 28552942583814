define("alliance-business-suite/components/machines/programs/cycles/steps-primus-mc10-wx", ["exports", "alliance-business-suite/mixins/machines/programs/mc10-options"], function (_exports, _mc10Options) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.inletConfiguration = _exports.default = void 0;

  let inletConfiguration = function (washType, inlets, hasDrain2) {
    let config = [{
      washTypeId: 6,
      washTypeName: "SOAK",
      inlets: 2,
      maxValvesOn: 4,
      hasDrain2: false,
      valves: [1, 1, 1, 0, 1, 1, 0, 1]
    }, {
      washTypeId: 6,
      washTypeName: "SOAK",
      inlets: 2,
      maxValvesOn: 4,
      hasDrain2: true,
      valves: [1, 1, 1, 0, 1, 1, 1, 1]
    }, {
      washTypeId: 6,
      washTypeName: "SOAK",
      inlets: 3,
      maxValvesOn: 4,
      hasDrain2: false,
      valves: [1, 1, 1, 1, 1, 1, 1, 1]
    }, {
      washTypeId: 6,
      washTypeName: "SOAK",
      inlets: 3,
      maxValvesOn: 4,
      hasDrain2: true,
      valves: [1, 1, 1, 1, 1, 1, 1, 1]
    }, {
      washTypeId: 7,
      washTypeName: "COOL_DOWN",
      inlets: 2,
      maxValvesOn: 0,
      hasDrain2: false,
      valves: [0, 0, 0, 0, 0, 0, 0, 0]
    }, {
      washTypeId: 7,
      washTypeName: "COOL_DOWN",
      inlets: 2,
      maxValvesOn: 0,
      hasDrain2: true,
      valves: [0, 0, 0, 0, 0, 0, 0, 0]
    }, {
      washTypeId: 7,
      washTypeName: "COOL_DOWN",
      inlets: 3,
      maxValvesOn: 0,
      hasDrain2: false,
      valves: [0, 0, 0, 0, 0, 0, 0, 0]
    }, {
      washTypeId: 7,
      washTypeName: "COOL_DOWN",
      inlets: 3,
      maxValvesOn: 0,
      hasDrain2: true,
      valves: [0, 0, 0, 0, 0, 0, 0, 0]
    }, {
      washTypeId: 13,
      washTypeName: "PRE_WASH",
      inlets: 2,
      maxValvesOn: 4,
      hasDrain2: false,
      valves: [1, 1, 1, 0, 1, 1, 0, 1]
    }, {
      washTypeId: 13,
      washTypeName: "PRE_WASH",
      inlets: 2,
      maxValvesOn: 4,
      hasDrain2: true,
      valves: [1, 1, 1, 0, 1, 1, 1, 1]
    }, {
      washTypeId: 13,
      washTypeName: "PRE_WASH",
      inlets: 3,
      maxValvesOn: 4,
      hasDrain2: false,
      valves: [1, 1, 1, 1, 1, 1, 1, 1]
    }, {
      washTypeId: 13,
      washTypeName: "PRE_WASH",
      inlets: 3,
      maxValvesOn: 4,
      hasDrain2: true,
      valves: [1, 1, 1, 1, 1, 1, 1, 1]
    }, {
      washTypeId: 14,
      washTypeName: "WASH",
      inlets: 2,
      maxValvesOn: 6,
      hasDrain2: false,
      valves: [1, 1, 1, 0, 1, 1, 0, 1]
    }, {
      washTypeId: 14,
      washTypeName: "WASH",
      inlets: 2,
      maxValvesOn: 6,
      hasDrain2: true,
      valves: [1, 1, 1, 0, 1, 1, 1, 1]
    }, {
      washTypeId: 14,
      washTypeName: "WASH",
      inlets: 3,
      maxValvesOn: 6,
      hasDrain2: false,
      valves: [1, 1, 1, 1, 1, 1, 1, 1]
    }, {
      washTypeId: 14,
      washTypeName: "WASH",
      inlets: 3,
      maxValvesOn: 6,
      hasDrain2: true,
      valves: [1, 1, 1, 1, 1, 1, 1, 1]
    }, {
      washTypeId: 15,
      washTypeName: "RINSE",
      inlets: 2,
      maxValvesOn: 3,
      hasDrain2: false,
      valves: [1, 1, 0, 1, 0, 0, 0, 0]
    }, {
      washTypeId: 15,
      washTypeName: "RINSE",
      inlets: 2,
      maxValvesOn: 3,
      hasDrain2: true,
      valves: [1, 1, 0, 1, 1, 0, 1, 0]
    }, {
      washTypeId: 15,
      washTypeName: "RINSE",
      inlets: 3,
      maxValvesOn: 3,
      hasDrain2: false,
      valves: [1, 1, 1, 1, 0, 0, 1, 0]
    }, {
      washTypeId: 15,
      washTypeName: "RINSE",
      inlets: 3,
      maxValvesOn: 3,
      hasDrain2: true,
      valves: [1, 1, 1, 1, 1, 0, 1, 0]
    }, {
      washTypeId: 16,
      washTypeName: "FINAL_RINSE",
      inlets: 2,
      maxValvesOn: 3,
      hasDrain2: false,
      valves: [1, 1, 0, 1, 0, 0, 0, 0]
    }, {
      washTypeId: 16,
      washTypeName: "FINAL_RINSE",
      inlets: 2,
      maxValvesOn: 3,
      hasDrain2: true,
      valves: [1, 1, 0, 1, 1, 0, 1, 0]
    }, {
      washTypeId: 16,
      washTypeName: "FINAL_RINSE",
      inlets: 3,
      maxValvesOn: 3,
      hasDrain2: false,
      valves: [1, 1, 1, 1, 0, 0, 1, 0]
    }, {
      washTypeId: 16,
      washTypeName: "FINAL_RINSE",
      inlets: 3,
      maxValvesOn: 3,
      hasDrain2: true,
      valves: [1, 1, 1, 1, 1, 0, 1, 0]
    }, {
      washTypeId: 17,
      washTypeName: "NO_WASH",
      inlets: 2,
      maxValvesOn: 0,
      hasDrain2: false,
      valves: [0, 0, 0, 0, 0, 0, 0, 0]
    }, {
      washTypeId: 17,
      washTypeName: "NO_WASH",
      inlets: 2,
      maxValvesOn: 0,
      hasDrain2: true,
      valves: [0, 0, 0, 0, 0, 0, 0, 0]
    }, {
      washTypeId: 17,
      washTypeName: "NO_WASH",
      inlets: 3,
      maxValvesOn: 0,
      hasDrain2: false,
      valves: [0, 0, 0, 0, 0, 0, 0, 0]
    }, {
      washTypeId: 17,
      washTypeName: "NO_WASH",
      inlets: 3,
      maxValvesOn: 0,
      hasDrain2: true,
      valves: [0, 0, 0, 0, 0, 0, 0, 0]
    }, {
      washTypeId: 18,
      washTypeName: "PROG_TUMBLE",
      inlets: 2,
      maxValvesOn: 0,
      hasDrain2: false,
      valves: [0, 0, 0, 0, 0, 0, 0, 0]
    }, {
      washTypeId: 18,
      washTypeName: "PROG_TUMBLE",
      inlets: 2,
      maxValvesOn: 0,
      hasDrain2: true,
      valves: [0, 0, 0, 0, 0, 0, 0, 0]
    }, {
      washTypeId: 18,
      washTypeName: "PROG_TUMBLE",
      inlets: 3,
      maxValvesOn: 0,
      hasDrain2: false,
      valves: [0, 0, 0, 0, 0, 0, 0, 0]
    }, {
      washTypeId: 18,
      washTypeName: "PROG_TUMBLE",
      inlets: 3,
      maxValvesOn: 0,
      hasDrain2: true,
      valves: [0, 0, 0, 0, 0, 0, 0, 0]
    }, {
      washTypeId: 19,
      washTypeName: "RINSE_2",
      inlets: 2,
      maxValvesOn: 3,
      hasDrain2: false,
      valves: [1, 1, 0, 1, 0, 0, 0, 0]
    }, {
      washTypeId: 19,
      washTypeName: "RINSE_2",
      inlets: 2,
      maxValvesOn: 3,
      hasDrain2: true,
      valves: [1, 1, 0, 1, 1, 0, 1, 0]
    }, {
      washTypeId: 19,
      washTypeName: "RINSE_2",
      inlets: 3,
      maxValvesOn: 3,
      hasDrain2: false,
      valves: [1, 1, 1, 1, 0, 0, 1, 0]
    }, {
      washTypeId: 19,
      washTypeName: "RINSE_2",
      inlets: 3,
      maxValvesOn: 3,
      hasDrain2: true,
      valves: [1, 1, 1, 1, 1, 0, 1, 0]
    }];
    return config.find(item => {
      return item.washTypeId == washType && item.inlets == inlets && item.hasDrain2 == hasDrain2;
    });
  };

  _exports.inletConfiguration = inletConfiguration;

  var _default = Ember.Component.extend(_mc10Options.default, {
    // eslint-disable-line ember/no-classic-classes
    tagName: '',
    // machineSettings.machineType: 242
    // machineSettings.spinRpmMax: 1165
    // machineSettings.spinRpmMin: 150
    // machineSettings.washRpmMax: 60
    // machineSettings.washRpmMin: 10
    // machineSettings.waterMax: 29
    // machineSettings.waterMin: 9

    /* TODO:
     We need to revalidate and keep values within ranges when there are changes to:
      - Machine type = water and speed changes, inlet changes; can change ALL cycles and steps.
      - Step type (Cycles) / Water valve count (Config) / Drain valve 2 enabled (Config) = inlets change
     There are a couple options we have...
      1) We do it all when the program is saved. This is how LMS does it.
      2) We do it when the change happens.
      3) We do it when the change happens, and notify the user of the change.
      4) When the change happens, we notify the user and ask if they want to make the changes (or not).
     */
    drainTypeSpin: 2,
    currentStepTypeName: Ember.computed('object.Type', function () {
      let currentTypeId = this.object.Type;
      return this.optionsStepType.findBy("val", currentTypeId).label.toUpperCase();
    }),
    detergentCount: Ember.computed('machineProgram', function () {
      if (this.machineProgram.hasMachineAttribute("MC10_COMBO")) {
        return 4;
      } else {
        return 8;
      }
    }),
    // TODO: For these, do we want to update the speed value if drain type is changes?
    drainSpeedMin: Ember.computed('object.DrainType', function () {
      return this.object.DrainType == this.drainTypeSpin ? this.machineSettings.spinRpmMin : this.machineSettings.washRpmMin;
    }),
    drainSpeedMax: Ember.computed('object.DrainType', function () {
      return this.object.DrainType == this.drainTypeSpin ? this.machineSettings.spinRpmMax : this.machineSettings.washRpmMax;
    }),
    decimals: 2,

    update(object, propertyName, value) {
      Ember.set(object, propertyName, value);
    },

    inletSettings: Ember.computed('machineProgram', 'object.Type', 'totalNumberOfInlets', 'drainValve2Enabled', function () {
      let washType = Ember.get(this, 'object.Type');
      let inlets = Ember.get(this, 'totalNumberOfInlets');
      let hasDrain2 = Ember.get(this, 'drainValve2Enabled'); // Combo Rinse step always has 1 inlet

      if (this.machineProgram.hasMachineAttribute("MC10_COMBO") && washType == 15) {
        inlets = 1;
      } // Combo always has 2 inlets


      if (this.machineProgram.hasMachineAttribute("MC10_COMBO")) {
        inlets = 2;
      }

      return inletConfiguration(washType, inlets, hasDrain2);
    }),
    isCombo: Ember.computed('machineProgram._machineAuditTypeFamily', function () {
      return this.machineProgram._machineAuditTypeFamily == "MC10_COMBO";
    }),
    enabledInlets: Ember.computed('inletSettings', function () {
      // Returns array of which inlets are available/enabled
      let enabledValves = this.inletSettings ? this.inletSettings.valves : [0, 0, 0, 0, 0, 0, 0, 0];
      let object = Ember.get(this, 'object'); // Both inlets are enabled by default for Combo

      if (this.isCombo) {
        return [1, 1];
      } // We need to uncheck any valves that are not enabled


      enabledValves.forEach((valve, index) => {
        if (valve == 0) {
          this.update(this.object, `Inlets.Inlet${index + 1}`, false);
        }
      });
      return enabledValves;
    }),
    maxValvesOn: Ember.computed('inletSettings', 'Type', function () {
      let count = this.inletSettings && this.inletSettings.maxValvesOn ? this.inletSettings.maxValvesOn : 0;
      return count;
    }),
    selectedInletCount: Ember.computed('object.Inlets.{Inlet1,Inlet2,Inlet3,Inlet4,Inlet5,Inlet6,Inlet7,Inlet8}', function () {
      // How many inlets are currently selected
      let count = 0;

      for (let i = 1; i <= 8; i++) {
        if (Ember.get(this, `object.Inlets.Inlet${i}`)) {
          count = count + 1;
        }
      }

      return count;
    }),
    valveTypeOptions: Ember.computed('drainValve2Enabled', function () {
      if (this.drainValve2Enabled) {
        return [{
          label: "Valve1",
          val: 1
        }, {
          label: "Valve2",
          val: 2
        }, {
          label: "Both",
          val: 3
        }];
      } else {
        return [{
          label: "Valve1",
          val: 1
        }];
      }
    }),
    actions: {
      // TODO: Why does this action need to be included?
      save() {
        alert('Save this form'); // Code smell: things break if this action is missing.
      },

      stepChanged(args) {
        // We need to force the value of DrainValveForCooldownOrTumble based on the step type.
        // Must not be 0 for Cooldown, but must be 0 for all others.
        let correctValue = args.val === 7 ? 1 : 0;
        this.update(this.object, 'DrainValveForCooldownOrTumble', correctValue);
      },

      changeDrainType(val) {
        let drainType = val.val;
        let drainTypeDisabled = 3;
        let drainValve = this.object.DrainValve; // If the drainType is changed to disabled (drainType==3), we need to set DrainValve to 0.
        // If the drainType is changing _from_ disabled, we'll set the value to 1.
        // If the drainType is changing but not to/from disabled, we check the previous of DrainValve and set to 1 if drain valve 2 is not enabled.

        if (drainType == drainTypeDisabled) {
          this.update(this.object, 'DrainValve', 0);
        } else {
          if (drainValve == 0) {
            this.update(this.object, 'DrainValve', 1);
          } else if (drainValve != 1 && !this.drainValve2Enabled) {
            // Reset if drain 2 is not available
            this.update(this.object, 'DrainValve', 1);
          }
        }
      }

    }
  });

  _exports.default = _default;
});