define("alliance-business-suite/utils/byte-utilities", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.base64ToBinaryString = base64ToBinaryString;
  _exports.base64ToDecimal = base64ToDecimal;
  _exports.bytesToBase64 = bytesToBase64;
  _exports.getByteArrayElement = getByteArrayElement;
  _exports.updateByteArrayElement = updateByteArrayElement;

  // TODO: Confirm that ember-cli/babel gives us these functions:
  // // https://github.com/uxitten/polyfill/blob/master/string.polyfill.js
  // // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/padStart
  // if (!String.prototype.padStart) {
  //   String.prototype.padStart = function padStart(targetLength, padString) {
  //     targetLength = targetLength >> 0; // truncate if number, or convert non-number to 0;
  //     padString = String(typeof padString !== 'undefined' ? padString : ' ');
  //     if (this.length >= targetLength) {
  //       return String(this);
  //     } else {
  //       targetLength = targetLength - this.length;
  //       if (targetLength > padString.length) {
  //         padString += padString.repeat(targetLength / padString.length); // append to original to ensure we are longer than needed
  //       }
  //       return padString.slice(0, targetLength) + String(this);
  //     }
  //   };
  // }
  // // https://github.com/uxitten/polyfill/blob/master/string.polyfill.js
  // // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/padEnd
  // if (!String.prototype.padEnd) {
  //   String.prototype.padEnd = function padEnd(targetLength, padString) {
  //     targetLength = targetLength >> 0; // floor if number or convert non-number to 0;
  //     padString = String((typeof padString !== 'undefined' ? padString : ' '));
  //     if (this.length > targetLength) {
  //       return String(this);
  //     } else {
  //       targetLength = targetLength - this.length;
  //       if (targetLength > padString.length) {
  //         padString += padString.repeat(targetLength / padString.length); // append to original to ensure we are longer than needed
  //       }
  //       return String(this) + padString.slice(0, targetLength);
  //     }
  //   };
  // }
  let toBase64 = function (u8) {
    return btoa(String.fromCharCode.apply(null, u8));
  };

  function base64ToBinaryString(value) {
    // Returns an array of binary strings from a base64 value
    let dec = base64ToDecimal(value); // decimal array

    let array = [];

    for (let i = 0; i < dec.length; i++) {
      array[i] = dec[i].toString(2).padStart(8, 0);
    }

    return array;
  }

  function base64ToDecimal(base64String) {
    // Return array of decimal values
    let raw = window.atob(base64String);
    let rawLength = raw.length;
    let array = new Uint8Array(new ArrayBuffer(rawLength));

    for (let i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i); // Get Unicode code from string
    }

    return array;
  }

  function bytesToBase64(bytes) {
    // Working with a string of hex like '8802'
    return btoa(bytes.match(/\w{2}/g).map(function (a) {
      return String.fromCharCode(parseInt(a, 16)); // Get decimal value of Unicode code
    }).join(''));
  }

  function getByteArrayElement(string, index) {
    let array = base64ToDecimal(string);
    return array[index];
  }

  function updateByteArrayElement(string, index, value) {
    // Updates the string
    let array = base64ToDecimal(string);
    array[index] = value;
    return toBase64(array);
  }
});