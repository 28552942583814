define("alliance-business-suite/components/chat-window", ["exports", "jquery", "alliance-business-suite/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    access: Ember.inject.service(),
    classNames: ['chat-window'],
    minimized: false,
    chatConfig: {
      'webchatAppUrl': 'https://apps.mypurecloud.com/webchat',
      'webchatServiceUrl': 'https://realtime.mypurecloud.com:443',
      'orgId': 4722,
      'orgName': 'alliancelaundrysystems',
      'queueName': 'Software_Chat',
      // 'Software_Chat',
      'locale': 'en',
      'logLevel': 'DEBUG',
      'priority': 0,
      'data': {
        'firstName': 'firstName',
        'lastName': 'lastName',
        // 'addressStreet': '64472 Brown Street',
        // 'addressCity': 'Lindgrenmouth',
        // 'addressPostalCode': '50163-2735',
        // 'addressState': 'FL',
        // 'phoneNumber': '1-916-892-2045 x293',
        // 'phoneType': 'Cell',
        'customerId': 0,
        'customField1Label': 'Client',
        'customField1': 'web',
        'customField2Label': 'Email',
        'customField2': 'unknown',
        'customField3Label': 'Alliance Brand',
        'customField3': 'brand'
      },
      'companyLogo': {
        'width': 600,
        'height': 149,
        'url': 'https://s3.amazonaws.com/alliancelabs-assets-production/common/chat-agent.svg'
      },
      // Logo used within the chat window
      'companyLogoSmall': {
        'width': 149,
        'height': 149,
        'url': 'https://s3.amazonaws.com/alliancelabs-assets-production/common/chat-agent.svg'
      },
      // Image used for agent
      'agentAvatar': {
        'width': 462,
        'height': 462,
        'url': 'https://s3.amazonaws.com/alliancelabs-assets-production/common/chat-agent.svg'
      },
      // Text displayed with chat window is displayed
      'welcomeMessage': 'Welcome to the support line, please wait for the next available agent.',
      'cssClass': 'webchat-frame',
      'css': {
        'width': '100%',
        'height': '100%'
      },
      'screenShareMediaSource': '',
      'standAloneApplication': false,
      'chromeWebstoreUrl': '' // 'https://chrome.google.com/webstore/detail/chpkaocjjopjgclkahflonfkhdhkdenh1'

    },
    isMinimized: false,

    didInsertElement() {
      this._super(...arguments);

      let _this = this;

      let user = this.get('access.accessUser');
      this.set('chatConfig.data.firstName', user.get('firstName'));
      this.set('chatConfig.data.lastName', user.get('lastName'));
      this.set('chatConfig.data.customerId', user.get('id'));
      this.set('chatConfig.data.customField1', `Web ${_environment.default.version}`);
      this.set('chatConfig.data.customField2', user.get('primaryEmail'));
      this.set('chatConfig.data.customField3', _environment.default.brand); // console.error(this.get('access.accessOrganization.brand'));
      // console.error('chatConfig', this.get('chatConfig'));

      ININ.webchat.create(this.chatConfig, function (err, webchat) {
        if (err) {
          if (err.name === 'UNSUPPORTED_BROWSER') {
            // Redirect to instructions for unsupported browser, or handle appropriately
            alert('Sorry, your browser is not supported!');
            return;
          }
        }

        webchat.renderFrame({
          containerEl: 'chatContainer'
        });

        webchat.chatEnded = function () {
          // Code to run when chat ends
          _this.toggleChat();
        };
      }); // ININ.screenshare.create(this.chatConfig, function(err, screenshare) {
      //   if (err) {
      //     // You should change this block to properly handle errors.
      //     console.error(err);
      //     if (err.name === 'UNSUPPORTED_BROWSER') {
      //       // Redirect to instructions for unsupported browser, or handle appropriately
      //       alert('Sorry, either your browser is not supported, or the page is not being served over TLS (HTTPS).');
      //       return;
      //     }
      //     alert('An error occurred launching the screen share widget. See console for details');
      //   }
      //
      //   screenshare.renderScreenShareForm({
      //     containerEl: 'chatContainer'
      //   });
      // });
    },

    actions: {
      toggleMinimize() {
        this.toggleProperty('minimized');
        (0, _jquery.default)('.chat-window').toggleClass('minimized');
      }

    }
  });

  _exports.default = _default;
});