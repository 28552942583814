define("alliance-business-suite/templates/components/table/table-cell-security-role", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "z1ZUG8nV",
    "block": "{\"symbols\":[],\"statements\":[[2,\"\\n\"],[6,[37,2],[[35,0,[\"primarySecurityRole\",\"isSystemDefault\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"i\"],[14,0,\"fa fa-lock\"],[14,\"aria-hidden\",\"true\"],[14,5,\"opacity: .35; font-size: 14px\"],[12],[13],[2,\"\\n  \"],[1,[30,[36,1],[[35,0,[\"primarySecurityRole\",\"name\"]]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[35,0,[\"primarySecurityRole\",\"name\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"record\",\"t\",\"if\"]}",
    "moduleName": "alliance-business-suite/templates/components/table/table-cell-security-role.hbs"
  });

  _exports.default = _default;
});