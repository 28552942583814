define("alliance-business-suite/helpers/machine-display-name", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (_ref, namedArgs) {
    let [value, ...rest] = _ref;
    let machineName = namedArgs.machineName;
    let machineNumber = `${namedArgs.machineNumber}`;
    let displayValue = machineNumber.replace(/^[0|\D]*/, '');
    let isDryer = namedArgs.isDryer;
    let displayMachineType = namedArgs.displayMachineType;
    let machineType = namedArgs.machineType;

    if (machineName) {
      if (machineName.length < 8) {
        displayValue = machineName;
      }
    }

    if (displayMachineType && machineType != 900) {
      if (isDryer === true || isDryer === "dryer") {
        displayValue = `D ${displayValue}`;
      } else {
        displayValue = `W ${displayValue}`;
      }
    } else {
      // machineType 900 is a generic combo
      displayValue = `M ${displayValue}`;
    }

    return displayValue;
  });

  _exports.default = _default;
});