define("alliance-business-suite/components/form-ui/tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /*  Parameters include:
          - tooltip (required)
          - icon: the FontAwesome style and name ("far fa-question-circle")
          - side: top, bottom, left, right (default)
          - event: hover (default), click
          - delay: ms delay before displaying tooltip (default 400)
    */
    tagName: "i",
    classNameBindings: ['icon', 'tooltipClass'],
    tooltipClass: "tooltip-machine-programming",
    icon: Ember.computed('tooltipIcon', function () {
      return Ember.get(this, 'tooltipIcon') || "fa fa-question-circle";
    })
  });

  _exports.default = _default;
});