define("alliance-business-suite/components/summary/reports/s-audit-cycle-usage", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "alliance-business-suite/utils/report-tables-util"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _reportTablesUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  let SummaryReportsSAuditCycleUsageComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, (_class = class SummaryReportsSAuditCycleUsageComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "brand", _descriptor4, this);
    }

    get translatedColumns() {
      return [{
        name: 'location_machine',
        valuePath: 'name',
        width: 379,
        isFixed: 'left'
      }, {
        name: 'date_range',
        valuePath: 'dateRange',
        width: 339
      }, {
        name: 'heatCount',
        valuePath: 'heatCount',
        width: 180
      }, {
        name: 'noHeatCount',
        valuePath: 'noHeatCount',
        width: 180
      }, {
        name: 'cycle1Price',
        valuePath: 'normalHotCount',
        width: 180
      }, {
        name: 'cycle2Price',
        valuePath: 'normalWarmCount',
        width: 222
      }, {
        name: 'cycle3Price',
        valuePath: 'normalColdCount',
        width: 180
      }, {
        name: 'cycle4Price',
        valuePath: 'permanentPressHotCount',
        width: 216
      }, {
        name: 'cycle5Price',
        valuePath: 'permanentPressWarmCount',
        width: 180
      }, {
        name: 'cycle6Price',
        valuePath: 'permanentPressColdCount',
        width: 180
      }, {
        name: 'cycle7Price',
        valuePath: 'delicateHotCount',
        width: 180
      }, {
        name: 'cycle8Price',
        valuePath: 'delicateWarmCount',
        width: 180
      }, {
        name: 'cycle9Price',
        valuePath: 'delicateColdCount',
        width: 180
      }, {
        name: 'cycle_count_10',
        valuePath: 'cycleCount10',
        width: 180
      }, {
        name: 'cycle_count_11',
        valuePath: 'cycleCount11',
        width: 180
      }, {
        name: 'cycle_count_12',
        valuePath: 'cycleCount12',
        width: 180
      }, {
        name: 'cycle_count_13',
        valuePath: 'cycleCount13',
        width: 180
      }, {
        name: 'cycle_count_14',
        valuePath: 'cycleCount14',
        width: 180
      }, {
        name: 'cycle_count_15',
        valuePath: 'cycleCount15',
        width: 180
      }];
    }

    get rowsWithChildren() {
      let brand = this.brand.name;
      let {
        model
      } = this.args;
      let geoBoundaries = model.geoBoundaries;
      let geoData = [];
      let grandheatCountSum = 0;
      let grandnoHeatCountSum = 0;
      let grandnormalHotSum = 0;
      let grandnormalWarmSum = 0;
      let grandnormalColdSum = 0;
      let grandpermPressHotSum = 0;
      let grandpermPressWarmSum = 0;
      let grandpermPressColdSum = 0;
      let granddelicateHotSum = 0;
      let granddelicateWarmSum = 0;
      let granddelicateColdSum = 0;
      let grandcycleCount10Sum = 0;
      let grandcycleCount11Sum = 0;
      let grandcycleCount12Sum = 0;
      let grandcycleCount13Sum = 0;
      let grandcycleCount14Sum = 0;
      let grandcycleCount15Sum = 0;
      let totalGrandRoomHeatCountCount = [];
      let totalGrandRoomNoHeatCountCount = [];
      let totalGrandRoomNormalHotCount = [];
      let totalGrandRoomPermPressHotCount = [];
      let totalGrandRoomDelicateHotCount = [];
      let totalGrandRoomNormalWarmCount = [];
      let totalGrandRoomPermPressWarmCount = [];
      let totalGrandRoomDelicateWarmCount = [];
      let totalGrandRoomNormalColdCount = [];
      let totalGrandRoomDelicateColdCount = [];
      let totalGrandRoomPermPressColdCount = [];
      let totalGrandRoomCycleCount10Count = [];
      let totalGrandRoomCycleCount11Count = [];
      let totalGrandRoomCycleCount12Count = [];
      let totalGrandRoomCycleCount13Count = [];
      let totalGrandRoomCycleCount14Count = [];
      let totalGrandRoomCycleCount15Count = [];
      let rowData = [{
        name: this.intl.t('grandTotal'),
        children: geoData,
        heatCount: totalGrandRoomHeatCountCount,
        noHeatCount: totalGrandRoomNoHeatCountCount,
        normalHotCount: totalGrandRoomNormalHotCount,
        normalWarmCount: totalGrandRoomNormalWarmCount,
        normalColdCount: totalGrandRoomNormalColdCount,
        permanentPressHotCount: totalGrandRoomPermPressHotCount,
        permanentPressWarmCount: totalGrandRoomPermPressWarmCount,
        permanentPressColdCount: totalGrandRoomPermPressColdCount,
        delicateHotCount: totalGrandRoomDelicateHotCount,
        delicateWarmCount: totalGrandRoomDelicateWarmCount,
        delicateColdCount: totalGrandRoomDelicateColdCount,
        cycleCount10: totalGrandRoomCycleCount10Count,
        cycleCount11: totalGrandRoomCycleCount11Count,
        cycleCount12: totalGrandRoomCycleCount12Count,
        cycleCount13: totalGrandRoomCycleCount13Count,
        cycleCount14: totalGrandRoomCycleCount14Count,
        cycleCount15: totalGrandRoomCycleCount15Count
      }];
      geoBoundaries.forEach(geoBoundary => {
        let rowArray = [];
        let nameArray = [];
        let heatCountSum = 0;
        let noHeatCountSum = 0;
        let normalHotSum = 0;
        let normalWarmSum = 0;
        let normalColdSum = 0;
        let permPressHotSum = 0;
        let permPressWarmSum = 0;
        let permPressColdSum = 0;
        let delicateHotSum = 0;
        let delicateWarmSum = 0;
        let delicateColdSum = 0;
        let cycleCount10Sum = 0;
        let cycleCount11Sum = 0;
        let cycleCount12Sum = 0;
        let cycleCount13Sum = 0;
        let cycleCount14Sum = 0;
        let cycleCount15Sum = 0;
        let totalRoomHeatCountCount = [];
        let totalRoomNoHeatCountCount = [];
        let totalRoomNormalHotCount = [];
        let totalRoomPermPressHotCount = [];
        let totalRoomDelicateHotCount = [];
        let totalRoomNormalWarmCount = [];
        let totalRoomPermPressWarmCount = [];
        let totalRoomDelicateWarmCount = [];
        let totalRoomNormalColdCount = [];
        let totalRoomDelicateColdCount = [];
        let totalRoomPermPressColdCount = [];
        let totalRoomCycleCount10Count = [];
        let totalRoomCycleCount11Count = [];
        let totalRoomCycleCount12Count = [];
        let totalRoomCycleCount13Count = [];
        let totalRoomCycleCount14Count = [];
        let totalRoomCycleCount15Count = [];
        geoData.push({
          name: _reportTablesUtil.localizeString.call(this, geoBoundary.name, 'Location', 'location'),
          children: nameArray,
          heatCount: totalRoomHeatCountCount,
          noHeatCount: totalRoomNoHeatCountCount,
          normalHotCount: totalRoomNormalHotCount,
          normalWarmCount: totalRoomNormalWarmCount,
          normalColdCount: totalRoomNormalColdCount,
          permanentPressHotCount: totalRoomPermPressHotCount,
          permanentPressWarmCount: totalRoomPermPressWarmCount,
          permanentPressColdCount: totalRoomPermPressColdCount,
          delicateHotCount: totalRoomDelicateHotCount,
          delicateWarmCount: totalRoomDelicateWarmCount,
          delicateColdCount: totalRoomDelicateColdCount,
          cycleCount10: totalRoomCycleCount10Count,
          cycleCount11: totalRoomCycleCount11Count,
          cycleCount12: totalRoomCycleCount12Count,
          cycleCount13: totalRoomCycleCount13Count,
          cycleCount14: totalRoomCycleCount14Count,
          cycleCount15: totalRoomCycleCount15Count
        });
        geoBoundary.rooms.forEach(room => {
          let heatCountSum1 = 0;
          let noHeatCountSum1 = 0;
          let normalHotSum1 = 0;
          let normalWarmSum1 = 0;
          let normalColdSum1 = 0;
          let permPressHotSum1 = 0;
          let permPressWarmSum1 = 0;
          let permPressColdSum1 = 0;
          let delicateHotSum1 = 0;
          let delicateWarmSum1 = 0;
          let delicateColdSum1 = 0;
          let cycleCount10Sum1 = 0;
          let cycleCount11Sum1 = 0;
          let cycleCount12Sum1 = 0;
          let cycleCount13Sum1 = 0;
          let cycleCount14Sum1 = 0;
          let cycleCount15Sum1 = 0;
          let totalRoomHeatCountCount1 = [];
          let totalRoomNoHeatCountCount1 = [];
          let totalRoomNormalHotCount1 = [];
          let totalRoomPermPressHotCount1 = [];
          let totalRoomDelicateHotCount1 = [];
          let totalRoomNormalWarmCount1 = [];
          let totalRoomPermPressWarmCount1 = [];
          let totalRoomDelicateWarmCount1 = [];
          let totalRoomNormalColdCount1 = [];
          let totalRoomPermPressColdCount1 = [];
          let totalRoomDelicateColdCount1 = [];
          let totalRoomCycleCount10Count1 = [];
          let totalRoomCycleCount11Count1 = [];
          let totalRoomCycleCount12Count1 = [];
          let totalRoomCycleCount13Count1 = [];
          let totalRoomCycleCount14Count1 = [];
          let totalRoomCycleCount15Count1 = [];
          nameArray.push({
            name: room.name,
            children: rowArray,
            heatCount: totalRoomHeatCountCount1,
            noHeatCount: totalRoomNoHeatCountCount1,
            normalHotCount: totalRoomNormalHotCount1,
            normalWarmCount: totalRoomNormalWarmCount1,
            normalColdCount: totalRoomNormalColdCount1,
            permanentPressHotCount: totalRoomPermPressHotCount1,
            permanentPressWarmCount: totalRoomPermPressWarmCount1,
            permanentPressColdCount: totalRoomPermPressColdCount1,
            delicateHotCount: totalRoomDelicateHotCount1,
            delicateWarmCount: totalRoomDelicateWarmCount1,
            delicateColdCount: totalRoomDelicateColdCount1,
            cycleCount10: totalRoomCycleCount10Count1,
            cycleCount11: totalRoomCycleCount11Count1,
            cycleCount12: totalRoomCycleCount12Count1,
            cycleCount13: totalRoomCycleCount13Count1,
            cycleCount14: totalRoomCycleCount14Count1,
            cycleCount15: totalRoomCycleCount15Count1
          });
          room.machineAuditGroupTypes.forEach(machineAuditType => {
            machineAuditType.machineTypes.forEach(machineType => {
              let isTouch = machineAuditType.name == 'Touch';
              let machineTypeName = `${machineAuditType.name} - ${machineType.name}`;

              if (isTouch) {
                if (brand == "PRIMUS") {
                  machineTypeName = `XControl Flex - ${machineType.name}`;
                }

                if (brand == "IPSO") {
                  machineTypeName = `Evolis - ${machineType.name}`;
                }

                if (brand == "SPEED_QUEEN") {
                  machineTypeName = `Quantum Touch - ${machineType.name}`;
                }
              }

              let machineArray = [];
              let heatCountSum2 = 0;
              let noHeatCountSum2 = 0;
              let normalHotSum2 = 0;
              let normalWarmSum2 = 0;
              let normalColdSum2 = 0;
              let permPressHotSum2 = 0;
              let permPressWarmSum2 = 0;
              let permPressColdSum2 = 0;
              let delicateHotSum2 = 0;
              let delicateWarmSum2 = 0;
              let delicateColdSum2 = 0;
              let cycleCount10Sum2 = 0;
              let cycleCount11Sum2 = 0;
              let cycleCount12Sum2 = 0;
              let cycleCount13Sum2 = 0;
              let cycleCount14Sum2 = 0;
              let cycleCount15Sum2 = 0;
              let totalRoomHeatCountCount2 = [];
              let totalRoomNoHeatCountCount2 = [];
              let totalRoomNormalHotCount2 = [];
              let totalRoomPermPressHotCount2 = [];
              let totalRoomDelicateHotCount2 = [];
              let totalRoomNormalWarmCount2 = [];
              let totalRoomPermPressWarmCount2 = [];
              let totalRoomDelicateWarmCount2 = [];
              let totalRoomNormalColdCount2 = [];
              let totalRoomPermPressColdCount2 = [];
              let totalRoomDelicateColdCount2 = [];
              let totalRoomCycleCount10Array2 = [];
              let totalRoomCycleCount11Array2 = [];
              let totalRoomCycleCount12Array2 = [];
              let totalRoomCycleCount13Array2 = [];
              let totalRoomCycleCount14Array2 = [];
              let totalRoomCycleCount15Array2 = [];
              rowArray.push({
                name: machineTypeName,
                machineTypeName: machineType.name,
                children: machineArray,
                heatCount: totalRoomHeatCountCount2,
                noHeatCount: totalRoomNoHeatCountCount2,
                normalHotCount: totalRoomNormalHotCount2,
                normalWarmCount: totalRoomNormalWarmCount2,
                normalColdCount: totalRoomNormalColdCount2,
                permanentPressHotCount: totalRoomPermPressHotCount2,
                permanentPressWarmCount: totalRoomPermPressWarmCount2,
                permanentPressColdCount: totalRoomPermPressColdCount2,
                delicateHotCount: totalRoomDelicateHotCount2,
                delicateWarmCount: totalRoomDelicateWarmCount2,
                delicateColdCount: totalRoomDelicateColdCount2,
                cycleCount10: totalRoomCycleCount10Array2,
                cycleCount11: totalRoomCycleCount11Array2,
                cycleCount12: totalRoomCycleCount12Array2,
                cycleCount13: totalRoomCycleCount13Array2,
                cycleCount14: totalRoomCycleCount14Array2,
                cycleCount15: totalRoomCycleCount15Array2
              });
              machineType.machines.forEach(machine => {
                let dateRange = "";
                let heatCountArray = [];
                let noHeatCountArray = [];
                let normalHotCountArray = [];
                let normalWarmCountArray = [];
                let normalColdCountArray = [];
                let permanentPressHotCountArray = [];
                let permanentPressWarmCountArray = [];
                let permanentPressColdCountArray = [];
                let delicateHotPercentArray = [];
                let delicateWarmPercentArray = [];
                let delicateColdPercentArray = [];
                let cycleCount10Array = [];
                let cycleCount11Array = [];
                let cycleCount12Array = [];
                let cycleCount13Array = [];
                let cycleCount14Array = [];
                let cycleCount15Array = [];
                let isDryer = machineType.name.toUpperCase().indexOf('DRYER') > -1 || machineType.name.toUpperCase().indexOf('TUMBLER') > -1;

                if (this.brand.slug == 'primus') {
                  // fix the branding issue alliance-tasks/issues/2291
                  rowArray[rowArray.length - 1].name = `${machine.machineAuditType.name} - ${rowArray[rowArray.length - 1].machineTypeName}`;
                }

                if (machine.heatCount !== 0) {
                  heatCountArray.push(machine.heatCount);
                  heatCountSum = (0, _reportTablesUtil.totalRows)(heatCountArray, heatCountSum);
                  heatCountSum1 = (0, _reportTablesUtil.totalRows)(heatCountArray, heatCountSum1);
                  heatCountSum2 = (0, _reportTablesUtil.totalRows)(heatCountArray, heatCountSum2);
                  grandheatCountSum = (0, _reportTablesUtil.totalRows)(heatCountArray, grandheatCountSum);
                }

                if (machine.noHeatCount !== 0) {
                  noHeatCountArray.push(machine.noHeatCount);
                  noHeatCountSum = (0, _reportTablesUtil.totalRows)(noHeatCountArray, noHeatCountSum);
                  grandnoHeatCountSum = (0, _reportTablesUtil.totalRows)(noHeatCountArray, grandnoHeatCountSum);
                  noHeatCountSum1 = (0, _reportTablesUtil.totalRows)(noHeatCountArray, noHeatCountSum1);
                  noHeatCountSum2 = (0, _reportTablesUtil.totalRows)(noHeatCountArray, noHeatCountSum2);
                }

                if (!isDryer) {
                  if (machine.normalHotCount !== 0) {
                    normalHotCountArray.push(machine.normalHotCount);
                    normalHotSum = (0, _reportTablesUtil.totalRows)(normalHotCountArray, normalHotSum);
                    grandnormalHotSum = (0, _reportTablesUtil.totalRows)(normalHotCountArray, grandnormalHotSum);
                    normalHotSum1 = (0, _reportTablesUtil.totalRows)(normalHotCountArray, normalHotSum1);
                    normalHotSum2 = (0, _reportTablesUtil.totalRows)(normalHotCountArray, normalHotSum2);
                  }

                  if (machine.normalWarmCount !== 0) {
                    normalWarmCountArray.push(machine.normalWarmCount);
                    normalWarmSum = (0, _reportTablesUtil.totalRows)(normalWarmCountArray, normalWarmSum);
                    grandnormalWarmSum = (0, _reportTablesUtil.totalRows)(normalWarmCountArray, grandnormalWarmSum);
                    normalWarmSum1 = (0, _reportTablesUtil.totalRows)(normalWarmCountArray, normalWarmSum1);
                    normalWarmSum2 = (0, _reportTablesUtil.totalRows)(normalWarmCountArray, normalWarmSum2);
                  }

                  if (machine.normalColdCount !== 0) {
                    normalColdCountArray.push(machine.normalColdCount);
                    normalColdSum = (0, _reportTablesUtil.totalRows)(normalColdCountArray, normalColdSum);
                    grandnormalColdSum = (0, _reportTablesUtil.totalRows)(normalColdCountArray, grandnormalColdSum);
                    normalColdSum1 = (0, _reportTablesUtil.totalRows)(normalColdCountArray, normalColdSum1);
                    normalColdSum2 = (0, _reportTablesUtil.totalRows)(normalColdCountArray, normalColdSum2);
                  }

                  if (machine.permanentPressHotCount !== 0) {
                    permanentPressHotCountArray.push(machine.permanentPressHotCount);
                    permPressHotSum = (0, _reportTablesUtil.totalRows)(permanentPressHotCountArray, permPressHotSum);
                    grandpermPressHotSum = (0, _reportTablesUtil.totalRows)(permanentPressHotCountArray, grandpermPressHotSum);
                    permPressHotSum1 = (0, _reportTablesUtil.totalRows)(permanentPressHotCountArray, permPressHotSum1);
                    permPressHotSum2 = (0, _reportTablesUtil.totalRows)(permanentPressHotCountArray, permPressHotSum2);
                  }

                  if (machine.permanentPressWarmCount !== 0) {
                    permanentPressWarmCountArray.push(machine.permanentPressWarmCount);
                    permPressWarmSum = (0, _reportTablesUtil.totalRows)(permanentPressWarmCountArray, permPressWarmSum);
                    grandpermPressWarmSum = (0, _reportTablesUtil.totalRows)(permanentPressWarmCountArray, grandpermPressWarmSum);
                    permPressWarmSum1 = (0, _reportTablesUtil.totalRows)(permanentPressWarmCountArray, permPressWarmSum1);
                    permPressWarmSum2 = (0, _reportTablesUtil.totalRows)(permanentPressWarmCountArray, permPressWarmSum2);
                  }

                  if (machine.permanentPressColdCount !== 0) {
                    permanentPressColdCountArray.push(machine.permanentPressColdCount);
                    permPressColdSum = (0, _reportTablesUtil.totalRows)(permanentPressColdCountArray, permPressColdSum);
                    grandpermPressColdSum = (0, _reportTablesUtil.totalRows)(permanentPressColdCountArray, grandpermPressColdSum);
                    permPressColdSum1 = (0, _reportTablesUtil.totalRows)(permanentPressColdCountArray, permPressColdSum1);
                    permPressColdSum2 = (0, _reportTablesUtil.totalRows)(permanentPressColdCountArray, permPressColdSum2);
                  }

                  if (machine.delicateHotCount !== 0) {
                    delicateHotPercentArray.push(machine.delicateHotCount);
                    delicateHotSum = (0, _reportTablesUtil.totalRows)(delicateHotPercentArray, delicateHotSum);
                    granddelicateHotSum = (0, _reportTablesUtil.totalRows)(delicateHotPercentArray, granddelicateHotSum);
                    delicateHotSum1 = (0, _reportTablesUtil.totalRows)(delicateHotPercentArray, delicateHotSum1);
                    delicateHotSum2 = (0, _reportTablesUtil.totalRows)(delicateHotPercentArray, delicateHotSum2);
                  }

                  if (machine.delicateWarmCount !== 0) {
                    delicateWarmPercentArray.push(machine.delicateWarmCount);
                    delicateWarmSum = (0, _reportTablesUtil.totalRows)(delicateWarmPercentArray, delicateWarmSum);
                    granddelicateWarmSum = (0, _reportTablesUtil.totalRows)(delicateWarmPercentArray, granddelicateWarmSum);
                    delicateWarmSum1 = (0, _reportTablesUtil.totalRows)(delicateWarmPercentArray, delicateWarmSum1);
                    delicateWarmSum2 = (0, _reportTablesUtil.totalRows)(delicateWarmPercentArray, delicateWarmSum2);
                  }

                  if (machine.delicateColdCount !== 0) {
                    delicateColdPercentArray.push(machine.delicateColdCount);
                    delicateColdSum = (0, _reportTablesUtil.totalRows)(delicateColdPercentArray, delicateColdSum);
                    granddelicateColdSum = (0, _reportTablesUtil.totalRows)(delicateColdPercentArray, granddelicateColdSum);
                    delicateColdSum1 = (0, _reportTablesUtil.totalRows)(delicateColdPercentArray, delicateColdSum1);
                    delicateColdSum2 = (0, _reportTablesUtil.totalRows)(delicateColdPercentArray, delicateColdSum2);
                  }

                  if (machine.cycleCount10 !== 0) {
                    cycleCount10Array.push(machine.cycleCount10);
                    cycleCount10Sum = (0, _reportTablesUtil.totalRows)(cycleCount10Array, cycleCount10Sum);
                    grandcycleCount10Sum = (0, _reportTablesUtil.totalRows)(cycleCount10Array, grandcycleCount10Sum);
                    cycleCount10Sum1 = (0, _reportTablesUtil.totalRows)(cycleCount10Array, cycleCount10Sum1);
                    cycleCount10Sum2 = (0, _reportTablesUtil.totalRows)(cycleCount10Array, cycleCount10Sum2);
                  }

                  if (machine.cycleCount11 !== 0) {
                    cycleCount11Array.push(machine.cycleCount11);
                    cycleCount11Sum = (0, _reportTablesUtil.totalRows)(cycleCount11Array, cycleCount11Sum);
                    grandcycleCount11Sum = (0, _reportTablesUtil.totalRows)(cycleCount11Array, grandcycleCount11Sum);
                    cycleCount11Sum1 = (0, _reportTablesUtil.totalRows)(cycleCount11Array, cycleCount11Sum1);
                    cycleCount11Sum2 = (0, _reportTablesUtil.totalRows)(cycleCount11Array, cycleCount11Sum2);
                  }

                  if (machine.cycleCount12 !== 0) {
                    cycleCount12Array.push(machine.cycleCount12);
                    cycleCount12Sum = (0, _reportTablesUtil.totalRows)(cycleCount12Array, cycleCount12Sum);
                    grandcycleCount12Sum = (0, _reportTablesUtil.totalRows)(cycleCount12Array, grandcycleCount12Sum);
                    cycleCount12Sum1 = (0, _reportTablesUtil.totalRows)(cycleCount12Array, cycleCount12Sum1);
                    cycleCount12Sum2 = (0, _reportTablesUtil.totalRows)(cycleCount12Array, cycleCount12Sum2);
                  }

                  if (machine.cycleCount13 !== 0) {
                    cycleCount13Array.push(machine.cycleCount13);
                    cycleCount13Sum = (0, _reportTablesUtil.totalRows)(cycleCount13Array, cycleCount13Sum);
                    grandcycleCount13Sum = (0, _reportTablesUtil.totalRows)(cycleCount13Array, grandcycleCount13Sum);
                    cycleCount13Sum1 = (0, _reportTablesUtil.totalRows)(cycleCount13Array, cycleCount13Sum1);
                    cycleCount13Sum2 = (0, _reportTablesUtil.totalRows)(cycleCount13Array, cycleCount13Sum2);
                  }

                  if (machine.cycleCount14 !== 0) {
                    cycleCount14Array.push(machine.cycleCount14);
                    cycleCount14Sum = (0, _reportTablesUtil.totalRows)(cycleCount14Array, cycleCount14Sum);
                    grandcycleCount14Sum = (0, _reportTablesUtil.totalRows)(cycleCount14Array, grandcycleCount14Sum);
                    cycleCount14Sum1 = (0, _reportTablesUtil.totalRows)(cycleCount14Array, cycleCount14Sum1);
                    cycleCount14Sum2 = (0, _reportTablesUtil.totalRows)(cycleCount14Array, cycleCount14Sum2);
                  }

                  if (machine.cycleCount15 !== 0) {
                    cycleCount15Array.push(machine.cycleCount15);
                    cycleCount15Sum = (0, _reportTablesUtil.totalRows)(cycleCount15Array, cycleCount15Sum);
                    grandcycleCount15Sum = (0, _reportTablesUtil.totalRows)(cycleCount15Array, grandcycleCount15Sum);
                    cycleCount15Sum1 = (0, _reportTablesUtil.totalRows)(cycleCount15Array, cycleCount15Sum1);
                    cycleCount15Sum2 = (0, _reportTablesUtil.totalRows)(cycleCount15Array, cycleCount15Sum2);
                  }
                }

                if (machine.minFirstReceivedAt && machine.maxFirstReceivedAt) {
                  dateRange = `${moment(machine.minFirstReceivedAt).format(_reportTablesUtil.formatDateTimeString.call(this))} - ${moment(machine.maxFirstReceivedAt).format(_reportTablesUtil.formatDateTimeString.call(this))}`;
                }

                let machineTotal = machine.normalHotCount + machine.normalWarmCount + machine.normalColdCount + machine.permanentPressHotCount + machine.permanentPressWarmCount + machine.permanentPressColdCount + machine.delicateHotCount + machine.delicateWarmCount + machine.delicateColdCount + machine.cycleCount10 + machine.cycleCount11 + machine.cycleCount12 + machine.cycleCount13 + machine.cycleCount14 + machine.cycleCount15;
                let dryerTotal = machine.heatCount + machine.noHeatCount;
                machineArray.push({
                  name: _reportTablesUtil.machineNameDisplay.call(this, machine.machineName, machine.machineNumber),
                  dateRange: dateRange,
                  heatCount: `${(machine.heatCount / dryerTotal * 100 || 0).toFixed(2)} %`,
                  noHeatCount: `${(machine.noHeatCount / dryerTotal * 100 || 0).toFixed(2)} %`,
                  normalHotCount: `${(machine.normalHotCount / machineTotal * 100 || 0).toFixed(2)} %`,
                  normalWarmCount: `${(machine.normalWarmCount / machineTotal * 100 || 0).toFixed(2)} %`,
                  normalColdCount: `${(machine.normalColdCount / machineTotal * 100 || 0).toFixed(2)} %`,
                  permanentPressHotCount: `${(machine.permanentPressHotCount / machineTotal * 100 || 0).toFixed(2)} %`,
                  permanentPressWarmCount: `${(machine.permanentPressWarmCount / machineTotal * 100 || 0).toFixed(2)} %`,
                  permanentPressColdCount: `${(machine.permanentPressColdCount / machineTotal * 100 || 0).toFixed(2)} %`,
                  delicateHotCount: `${(machine.delicateHotCount / machineTotal * 100 || 0).toFixed(2)} %`,
                  delicateWarmCount: `${(machine.delicateWarmCount / machineTotal * 100 || 0).toFixed(2)} %`,
                  delicateColdCount: `${(machine.delicateColdCount / machineTotal * 100 || 0).toFixed(2)} %`,
                  cycleCount10: `${(machine.cycleCount10 / machineTotal * 100 || 0).toFixed(2)} %`,
                  cycleCount11: `${(machine.cycleCount11 / machineTotal * 100 || 0).toFixed(2)} %`,
                  cycleCount12: `${(machine.cycleCount12 / machineTotal * 100 || 0).toFixed(2)} %`,
                  cycleCount13: `${(machine.cycleCount13 / machineTotal * 100 || 0).toFixed(2)} %`,
                  cycleCount14: `${(machine.cycleCount14 / machineTotal * 100 || 0).toFixed(2)} %`,
                  cycleCount15: `${(machine.cycleCount15 / machineTotal * 100 || 0).toFixed(2)} %`
                });
              });
              let machineTotal = normalHotSum2 + normalWarmSum2 + normalColdSum2 + permPressHotSum2 + permPressWarmSum2 + permPressColdSum2 + delicateHotSum2 + delicateWarmSum2 + delicateColdSum2 + cycleCount10Sum2 + cycleCount11Sum2 + cycleCount12Sum2 + cycleCount13Sum2 + cycleCount14Sum2;
              let dryerTotal = heatCountSum2 + noHeatCountSum2;
              totalRoomHeatCountCount2.push(`${(heatCountSum2 / dryerTotal * 100 || 0).toFixed(2)} %`);
              totalRoomNoHeatCountCount2.push(`${(noHeatCountSum2 / dryerTotal * 100 || 0).toFixed(2)} %`);
              totalRoomNormalHotCount2.push(`${(normalHotSum2 / machineTotal * 100 || 0).toFixed(2)} %`);
              totalRoomNormalWarmCount2.push(`${(normalWarmSum2 / machineTotal * 100 || 0).toFixed(2)} %`);
              totalRoomNormalColdCount2.push(`${(normalColdSum2 / machineTotal * 100 || 0).toFixed(2)} %`);
              totalRoomPermPressHotCount2.push(`${(permPressHotSum2 / machineTotal * 100 || 0).toFixed(2)} %`);
              totalRoomPermPressWarmCount2.push(`${(permPressWarmSum2 / machineTotal * 100 || 0).toFixed(2)} %`);
              totalRoomPermPressColdCount2.push(`${(permPressColdSum2 / machineTotal * 100 || 0).toFixed(2)} %`);
              totalRoomDelicateHotCount2.push(`${(delicateHotSum2 / machineTotal * 100 || 0).toFixed(2)} %`);
              totalRoomDelicateWarmCount2.push(`${(delicateWarmSum2 / machineTotal * 100 || 0).toFixed(2)} %`);
              totalRoomDelicateColdCount2.push(`${(delicateColdSum2 / machineTotal * 100 || 0).toFixed(2)} %`);
              totalRoomCycleCount10Array2.push(`${(cycleCount10Sum2 / machineTotal * 100 || 0).toFixed(2)} %`);
              totalRoomCycleCount11Array2.push(`${(cycleCount11Sum2 / machineTotal * 100 || 0).toFixed(2)} %`);
              totalRoomCycleCount12Array2.push(`${(cycleCount12Sum2 / machineTotal * 100 || 0).toFixed(2)} %`);
              totalRoomCycleCount13Array2.push(`${(cycleCount13Sum2 / machineTotal * 100 || 0).toFixed(2)} %`);
              totalRoomCycleCount14Array2.push(`${(cycleCount14Sum2 / machineTotal * 100 || 0).toFixed(2)} %`);
              totalRoomCycleCount15Array2.push(`${(cycleCount15Sum2 / machineTotal * 100 || 0).toFixed(2)} %`);
            });
          });
          let machineTotal = normalHotSum1 + normalWarmSum1 + normalColdSum1 + permPressHotSum1 + permPressWarmSum1 + permPressColdSum1 + delicateHotSum1 + delicateWarmSum1 + delicateColdSum1 + cycleCount10Sum1 + cycleCount11Sum1 + cycleCount12Sum1 + cycleCount13Sum1 + cycleCount14Sum1;
          let dryerTotal = heatCountSum1 + noHeatCountSum1;
          totalRoomHeatCountCount1.push(`${(heatCountSum1 / dryerTotal * 100 || 0).toFixed(2)} %`);
          totalRoomNoHeatCountCount1.push(`${(noHeatCountSum1 / dryerTotal * 100 || 0).toFixed(2)} %`);
          totalRoomNormalHotCount1.push(`${(normalHotSum1 / machineTotal * 100 || 0).toFixed(2)} %`);
          totalRoomNormalWarmCount1.push(`${(normalWarmSum1 / machineTotal * 100 || 0).toFixed(2)} %`);
          totalRoomNormalColdCount1.push(`${(normalColdSum1 / machineTotal * 100 || 0).toFixed(2)} %`);
          totalRoomPermPressHotCount1.push(`${(permPressHotSum1 / machineTotal * 100 || 0).toFixed(2)} %`);
          totalRoomPermPressWarmCount1.push(`${(permPressWarmSum1 / machineTotal * 100 || 0).toFixed(2)} %`);
          totalRoomPermPressColdCount1.push(`${(permPressColdSum1 / machineTotal * 100 || 0).toFixed(2)} %`);
          totalRoomDelicateHotCount1.push(`${(delicateHotSum1 / machineTotal * 100 || 0).toFixed(2)} %`);
          totalRoomDelicateWarmCount1.push(`${(delicateWarmSum1 / machineTotal * 100 || 0).toFixed(2)} %`);
          totalRoomDelicateColdCount1.push(`${(delicateColdSum1 / machineTotal * 100 || 0).toFixed(2)} %`);
          totalRoomCycleCount10Count1.push(`${(cycleCount10Sum1 / machineTotal * 100 || 0).toFixed(2)} %`);
          totalRoomCycleCount11Count1.push(`${(cycleCount11Sum1 / machineTotal * 100 || 0).toFixed(2)} %`);
          totalRoomCycleCount12Count1.push(`${(cycleCount12Sum1 / machineTotal * 100 || 0).toFixed(2)} %`);
          totalRoomCycleCount13Count1.push(`${(cycleCount13Sum1 / machineTotal * 100 || 0).toFixed(2)} %`);
          totalRoomCycleCount14Count1.push(`${(cycleCount14Sum1 / machineTotal * 100 || 0).toFixed(2)} %`);
          totalRoomCycleCount15Count1.push(`${(cycleCount15Sum1 / machineTotal * 100 || 0).toFixed(2)} %`);
        });
        let machineTotal = normalHotSum + normalWarmSum + normalColdSum + permPressHotSum + permPressWarmSum + permPressColdSum + delicateHotSum + delicateWarmSum + delicateColdSum + cycleCount10Sum + cycleCount11Sum + cycleCount12Sum + cycleCount13Sum + cycleCount14Sum;
        let dryerTotal = heatCountSum + noHeatCountSum;
        totalRoomHeatCountCount.push(`${(heatCountSum / dryerTotal * 100 || 0).toFixed(2)} %`);
        totalRoomNoHeatCountCount.push(`${(noHeatCountSum / dryerTotal * 100 || 0).toFixed(2)} %`);
        totalRoomNormalHotCount.push(`${(normalHotSum / machineTotal * 100 || 0).toFixed(2)} %`);
        totalRoomNormalWarmCount.push(`${(normalWarmSum / machineTotal * 100 || 0).toFixed(2)} %`);
        totalRoomNormalColdCount.push(`${(normalColdSum / machineTotal * 100 || 0).toFixed(2)} %`);
        totalRoomPermPressHotCount.push(`${(permPressHotSum / machineTotal * 100 || 0).toFixed(2)} %`);
        totalRoomPermPressWarmCount.push(`${(permPressWarmSum / machineTotal * 100 || 0).toFixed(2)} %`);
        totalRoomPermPressColdCount.push(`${(permPressColdSum / machineTotal * 100 || 0).toFixed(2)} %`);
        totalRoomDelicateHotCount.push(`${(delicateHotSum / machineTotal * 100 || 0).toFixed(2)} %`);
        totalRoomDelicateWarmCount.push(`${(delicateWarmSum / machineTotal * 100 || 0).toFixed(2)} %`);
        totalRoomDelicateColdCount.push(`${(delicateColdSum / machineTotal * 100 || 0).toFixed(2)} %`);
        totalRoomCycleCount10Count.push(`${(cycleCount10Sum / machineTotal * 100 || 0).toFixed(2)} %`);
        totalRoomCycleCount11Count.push(`${(cycleCount11Sum / machineTotal * 100 || 0).toFixed(2)} %`);
        totalRoomCycleCount12Count.push(`${(cycleCount12Sum / machineTotal * 100 || 0).toFixed(2)} %`);
        totalRoomCycleCount13Count.push(`${(cycleCount13Sum / machineTotal * 100 || 0).toFixed(2)} %`);
        totalRoomCycleCount14Count.push(`${(cycleCount14Sum / machineTotal * 100 || 0).toFixed(2)} %`);
        totalRoomCycleCount15Count.push(`${(cycleCount15Sum / machineTotal * 100 || 0).toFixed(2)} %`);
      });
      let machineTotal = grandnormalHotSum + grandnormalWarmSum + grandnormalColdSum + grandpermPressHotSum + grandpermPressWarmSum + grandpermPressColdSum + granddelicateHotSum + granddelicateWarmSum + granddelicateColdSum + grandcycleCount10Sum + grandcycleCount11Sum + grandcycleCount12Sum + grandcycleCount13Sum + grandcycleCount14Sum;
      let dryerTotal = grandheatCountSum + grandnoHeatCountSum;
      totalGrandRoomHeatCountCount.push(`${(grandheatCountSum / dryerTotal * 100 || 0).toFixed(2)} %`);
      totalGrandRoomNoHeatCountCount.push(`${(grandnoHeatCountSum / dryerTotal * 100 || 0).toFixed(2)} %`);
      totalGrandRoomNormalHotCount.push(`${(grandnormalHotSum / machineTotal * 100 || 0).toFixed(2)} %`);
      totalGrandRoomNormalWarmCount.push(`${(grandnormalWarmSum / machineTotal * 100 || 0).toFixed(2)} %`);
      totalGrandRoomNormalColdCount.push(`${(grandnormalColdSum / machineTotal * 100 || 0).toFixed(2)} %`);
      totalGrandRoomPermPressHotCount.push(`${(grandpermPressHotSum / machineTotal * 100 || 0).toFixed(2)} %`);
      totalGrandRoomPermPressWarmCount.push(`${(grandpermPressWarmSum / machineTotal * 100 || 0).toFixed(2)} %`);
      totalGrandRoomPermPressColdCount.push(`${(grandpermPressColdSum / machineTotal * 100 || 0).toFixed(2)} %`);
      totalGrandRoomDelicateHotCount.push(`${(granddelicateHotSum / machineTotal * 100 || 0).toFixed(2)} %`);
      totalGrandRoomDelicateWarmCount.push(`${(granddelicateWarmSum / machineTotal * 100 || 0).toFixed(2)} %`);
      totalGrandRoomDelicateColdCount.push(`${(granddelicateColdSum / machineTotal * 100 || 0).toFixed(2)} %`);
      totalGrandRoomCycleCount10Count.push(`${(grandcycleCount10Sum / machineTotal * 100 || 0).toFixed(2)} %`);
      totalGrandRoomCycleCount11Count.push(`${(grandcycleCount11Sum / machineTotal * 100 || 0).toFixed(2)} %`);
      totalGrandRoomCycleCount12Count.push(`${(grandcycleCount12Sum / machineTotal * 100 || 0).toFixed(2)} %`);
      totalGrandRoomCycleCount13Count.push(`${(grandcycleCount13Sum / machineTotal * 100 || 0).toFixed(2)} %`);
      totalGrandRoomCycleCount14Count.push(`${(grandcycleCount14Sum / machineTotal * 100 || 0).toFixed(2)} %`);
      totalGrandRoomCycleCount15Count.push(`${(grandcycleCount15Sum / machineTotal * 100 || 0).toFixed(2)} %`);
      return rowData;
    }

    get userTimeZone() {
      return moment.tz.guess();
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "brand", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SummaryReportsSAuditCycleUsageComponent;
});