define("alliance-business-suite/helpers/uncapitalize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.uncapitalize = uncapitalize;

  function uncapitalize(string) {
    return string.toLowerCase();
  }

  var _default = Ember.Helper.extend({
    compute(_ref
    /* , hash*/
    ) {
      let [string] = _ref;
      return uncapitalize(string);
    }

  });

  _exports.default = _default;
});