define("alliance-business-suite/components/form-ui/steps/delay-time", ["exports", "alliance-business-suite/mixins/init-property-names"], function (_exports, _initPropertyNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_initPropertyNames.default, {
    min: Ember.computed('validations.delayTime', function () {
      if (this?.params?.validations?.delayTime) {
        return this?.params?.validations?.delayTime[0];
      } else {
        return 0;
      }
    }),
    max: Ember.computed('validations.delayTime', function () {
      if (this?.params?.validations?.delayTime) {
        return this?.params?.validations?.delayTime[1];
      } else {
        return 255;
      }
    })
  });

  _exports.default = _default;
});