define("alliance-business-suite/templates/crm/campaigns/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CaJeIelb",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"section--container-v2\"],[12],[2,\"\\n  \"],[10,\"header\"],[14,0,\"ui-route-header\"],[12],[2,\"\\n    \"],[10,\"h2\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"campaigns\"],null]],[2,\"\\n      \"],[10,\"span\"],[14,0,\"ui-route-header__subtitle\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"routes.marketing.campaigns.index.subtitle\"],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"controls controls--right\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[32,0,[\"access\",\"isDemo\"]]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[\"CRM_CREATE_CAMPAIGN\"],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[8,\"link-to\",[[24,0,\"primary\"]],[[\"@route\",\"@tagName\",\"@aria-label\",\"@title\"],[\"crm.campaigns.create\",\"button\",[30,[36,0],[\"routes.summary.alerts.index.button.create.aria-label\"],null],[30,[36,0],[\"routes.summary.alerts.index.button.create.title\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[10,\"i\"],[14,0,\"fa fa-plus\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n          \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[8,\"marketing/campaign-list-table\",[],[[],[]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"can-access\",\"not\",\"if\"]}",
    "moduleName": "alliance-business-suite/templates/crm/campaigns/index.hbs"
  });

  _exports.default = _default;
});