define("alliance-business-suite/components/standard-currency-input-format", ["exports", "alliance-business-suite/mixins/currency-format"], function (_exports, _currencyFormat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const localeMap = {
    'ENUS': {
      locale: 'en-US',
      code: 'USD',
      currency: '$',
      group: ',',
      decimal: '.',
      position: 'left'
    },
    'EN': {
      locale: 'en-US',
      code: 'USD',
      currency: '$',
      group: ',',
      decimal: '.',
      position: 'left'
    },
    'CSCZ': {
      locale: 'cs-CZ',
      code: 'EUR',
      currency: '€',
      group: '',
      decimal: ',',
      position: 'right'
    },
    'DE': {
      locale: 'de-DE',
      code: 'EUR',
      currency: '€',
      group: ',',
      decimal: '.',
      position: 'right'
    },
    'ES': {
      locale: 'es-US',
      code: 'USD',
      currency: '$',
      group: ',',
      decimal: '.',
      position: 'left'
    },
    'ESCO': {
      locale: 'es-CO',
      code: 'COP',
      currency: '$',
      group: '.',
      decimal: ',',
      position: 'left'
    },
    'FR': {
      locale: 'fr-FR',
      code: 'EUR',
      currency: '€',
      group: '',
      decimal: ',',
      position: 'left'
    },
    'ID': {
      locale: 'id-ID',
      code: 'IDR',
      currency: 'Rp',
      group: '.',
      decimal: ',',
      position: 'left'
    },
    'ITA': {
      locale: 'it-IT',
      code: 'EUR',
      currency: '€',
      group: '.',
      decimal: ',',
      position: 'right'
    },
    'ITIT': {
      locale: 'it-IT',
      code: 'EUR',
      currency: '€',
      group: '.',
      decimal: ',',
      position: 'left'
    },
    'JPN': {
      locale: 'ja-JP',
      code: 'JPY',
      currency: '￥',
      group: ',',
      decimal: undefined,
      position: 'left'
    },
    'JA': {
      locale: 'ja-JP',
      code: 'JPY',
      currency: '￥',
      group: ',',
      decimal: undefined,
      position: 'left'
    },
    'KO': {
      locale: 'ko-KR',
      code: 'KRW',
      currency: '₩',
      group: ',',
      decimal: undefined,
      position: 'left'
    },
    'MS': {
      locale: 'ms-MY',
      code: 'MYR',
      currency: 'RM',
      group: ',',
      decimal: '.',
      position: 'left'
    },
    'NLNL': {
      locale: 'nl-NL',
      code: 'EUR',
      currency: '€',
      group: ',',
      decimal: '.',
      position: 'left'
    },
    'PT': {
      locale: 'pt-PT',
      code: 'EUR',
      currency: '€',
      group: '',
      decimal: ',',
      position: 'left'
    },
    'RU': {
      locale: 'ru-RU',
      code: 'RUB',
      currency: '₽',
      group: '',
      decimal: ',',
      position: 'right'
    },
    'TH': {
      locale: 'th-TH',
      code: 'THB',
      currency: '฿',
      group: ',',
      decimal: '.',
      position: 'left'
    },
    'ZHCN': {
      locale: 'zh-CN',
      code: 'CNY',
      currency: '¥',
      group: ',',
      decimal: '.',
      position: 'left'
    },
    'ZHTW': {
      locale: 'zh-TW',
      code: 'CNY',
      currency: 'CN¥',
      group: ',',
      decimal: '.',
      position: 'left'
    }
  };

  var _default = Ember.Component.extend(_currencyFormat.default, {
    access: Ember.inject.service(),

    didInsertElement() {
      this._super(...arguments);

      this.send('setUnmaskedCurrency');
    },

    numberHasChanged: Ember.observer('number', function () {
      this.send('maskCurrencyInput');
    }),

    // send events up to the parent
    handleKeyUp(val) {
      if (this.handleChange) {
        this.handleChange({
          val: val,
          inputId: this.inputId
        });
      }
    },

    actions: {
      // Sets Masked input value
      maskCurrencyInput() {
        let organizationId = this.access.accessOrganization.id;
        let thaiTemporaryWorkAround = false;
        let currencyAccessObject = this.access || {};
        let windowLocale = window.navigator.language || 'en_US';
        let currencyAccessUser = currencyAccessObject.accessUser || {
          locale: windowLocale
        };
        let userLocale = this.localeCode || currencyAccessUser.locale;
        let userLocaleFormatted = userLocale.replace(/[^0-9a-z]/gi, '');
        let mappedLocale = localeMap[userLocaleFormatted.toUpperCase()] || {
          locale: 'en-US',
          code: 'USD',
          currency: '$',
          group: ',',
          decimal: '.',
          position: 'right'
        }; // 5 overridable currency format options

        let currencyDenomination = this.currencySymbol || currencyAccessObject.currencyDenomination || mappedLocale.currency;
        let currencyThousandsSeparator = this.thousandsSeparator || currencyAccessObject.currencyThousandsSeparator || mappedLocale.group;
        let currencyDecimalSeparator = this.decimalSeparator || currencyAccessObject.currencyDecimalSeparator || mappedLocale.decimal;
        let currencyDecimalPlaces = this.decimalPlaces === true || currencyAccessObject.currencyDecimalPlaces !== 0;
        let currencyCode = currencyAccessObject.currencyCode || `${mappedLocale.code}` || "USD";
        let symbolPosition = this.symbolPosition || mappedLocale.position || "left";
        let inputId = this.inputId || "currency-field";
        let input = document.getElementById(inputId);
        let currentNumber = this.number;
        let input_val = `${currentNumber}` || input.value;

        if (input_val === "") {
          return;
        } // sets caret position


        let original_len = input_val.length;
        let caret_pos = input.selectionStart; // Temporary Workaround for BE bug causing certain thai organization to return currencyDecimalSeparator - undefined.
        // thai org 132579 is pricing their machines with no decimals and causing issues on top of the known THAI

        if (currencyCode === "THB" && organizationId != 132579) {
          thaiTemporaryWorkAround = true;
        }

        if (currencyDecimalPlaces === false || currencyDecimalSeparator === undefined || thaiTemporaryWorkAround) {
          //Formats Number without decimal places based on "currencyDecimalPlaces" property being set to 0...Example: ¥1,0000
          input_val = this.formatNumberWithoutDecimals(input_val, currencyThousandsSeparator);
        } else {
          //Formats Number with decimal places based on "currencyDecimalPlaces" property being set to 2... Example: $1,235.25
          input_val = this.formatNumberWithDecimals(input_val, currencyThousandsSeparator, currencyDecimalSeparator);
        } //Get formatted currency symbol position based on standard Intl NumberFormat


        if (symbolPosition === "right") {
          input_val = input_val + `${currencyDenomination}`;
        } else {
          input_val = `${currencyDenomination}` + input_val;
        } //Send final formatted currency value to the input


        input.value = input_val; // put caret back in the right position

        let updated_len = input_val.length;
        caret_pos = updated_len - original_len + caret_pos;
        input.setSelectionRange(caret_pos, caret_pos);
      },

      // Sets unmasked input value for API
      setUnmaskedCurrency() {
        let inputId = this.inputId;
        let input = document.getElementById(inputId);
        let currentNumber = this.number;
        let input_val = input.value || `${currentNumber}`;

        if (Number(input_val.replace(/\D/g, "").replace(/^0+/, "")) >= 0) {
          const num = Number(input_val.replace(/\D/g, "").replace(/^0+/, ""));
          this.handleKeyUp(num);
          this.set('number', num);
          this.send('maskCurrencyInput');
        }
      }

    }
  });

  _exports.default = _default;
});