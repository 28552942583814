define("alliance-business-suite/initializers/setup-pikaday-i18n", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const momentLocaleMap = {
    'cs-cz': 'cs',
    'de': 'de',
    'en-us': 'en',
    'es': 'es',
    'es-co': 'es',
    'fr': 'fr',
    'id': 'id',
    'ms': 'ms-my',
    'ita': 'it',
    'ko': 'ko',
    'jpn': 'ja',
    'nl-nl': 'nl',
    'pt': 'pt',
    'ru': 'ru',
    'th': 'th',
    'zh-cn': 'zh-cn'
  };
  var _default = {
    name: 'setup-pikaday-i18n',
    initialize: function (application) {
      let currentLocale = calculateLocale();

      let months = _moment.default.localeData(currentLocale)._months;

      let weekdays = _moment.default.localeData(currentLocale)._weekdays;

      let weekdaysShort = _moment.default.localeData(currentLocale)._weekdaysShort;

      if (currentLocale === 'ru') {
        months = _moment.default.localeData(currentLocale)._months.format;
      }

      let i18n = Ember.Object.extend({
        previousMonth: '',
        nextMonth: '',
        months: months,
        weekdays: weekdays,
        weekdaysShort: weekdaysShort
      });
      application.register('pikaday-i18n:main', i18n, {
        singleton: true
      });
      application.inject('component:pikaday-input', 'i18n', 'pikaday-i18n:main');
    }
  };
  _exports.default = _default;

  function calculateLocale() {
    let locale = (localStorage.getItem('language') || navigator.language || navigator.userLanguage || 'en-us').toLowerCase();

    if (locale.includes('en')) {
      return 'en-us';
    } else {
      let value = momentLocaleMap[locale];
      return value;
    }
  }
});