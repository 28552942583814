define("alliance-business-suite/templates/components/form-ui/steps/heater-options-rate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "n4Of87c1",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[8,\"form-ui\",[],[[\"@object\",\"@formSettings\",\"@selectOptions\"],[[32,0,[\"object\"]],[32,0,[\"formSettings\"]],[32,0,[\"selectOptions\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[10,\"h2\"],[12],[1,[30,[36,0],[\"heater_options\"],null]],[13],[2,\"\\n\\n \"],[8,[32,1,[\"select\"]],[],[[\"@params\"],[[30,[36,1],null,[[\"propertyName\",\"options\",\"label\"],[[32,0,[\"properties\",\"HeatOption\",\"key\"]],\"HeatOption\",\"heat\"]]]]],null],[2,\"\\n\\n  \"],[8,[32,1,[\"select\"]],[],[[\"@params\"],[[30,[36,1],null,[[\"propertyName\",\"label\",\"options\",\"hasCustom\",\"sharedCustomProperty\",\"disabled\",\"customType\",\"min\",\"max\"],[[32,0,[\"properties\",\"HeatRate\",\"key\"]],\"rate\",\"HeatRateOption\",true,true,[30,[36,2],[[32,0,[\"HeatOption\"]],0],null],\"temperature-rate\",0.5,6]]]]],null],[2,\"\\n\\n\\n\"],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"hash\",\"eq\"]}",
    "moduleName": "alliance-business-suite/templates/components/form-ui/steps/heater-options-rate.hbs"
  });

  _exports.default = _default;
});