define("alliance-business-suite/components/machines/programs/config/acas-wx-flw", ["exports", "alliance-business-suite/utils/machine-programming-find-options"], function (_exports, _machineProgrammingFindOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    machineAuditType: Ember.computed.alias('machineProgram.machineAuditType.id'),
    optionsManualMode: Ember.computed("machineProgram._machineGeneration", function () {
      if (this.machineProgram._machineGeneration === "TITAN") {
        return [{
          val: 2,
          label: 'Password protected',
          text: "Access is passcode only"
        }, {
          val: 1,
          label: 'Enabled',
          text: "Access is not limited"
        }, {
          val: 0,
          label: 'Disabled',
          text: "Access is not allowed"
        }];
      } else {
        return [{
          val: 0,
          label: 'Password protected',
          text: "Access is passcode only"
        }, {
          val: 1,
          label: 'Enabled',
          text: "Access is not limited"
        }, {
          val: 2,
          label: 'Disabled',
          text: "Access is not allowed"
        }];
      }
    }),
    optionsSpeedUnits: [{
      val: 0,
      label: 'RPM'
    }, {
      val: 1,
      label: 'G Force'
    }],
    optionsBreakInAlarmKeypad: (0, _machineProgrammingFindOptions.findOptions)([{
      machineAttributes: ["ACAS_A3"],
      options: [{
        val: 1,
        label: 'a_abbrev'
      }, {
        val: 2,
        label: 'b_abbrev'
      }, {
        val: 3,
        label: 'c_abbrev'
      }, {
        val: 5,
        label: 'normal_90'
      }, {
        val: 6,
        label: 'normal_60'
      }, {
        val: 7,
        label: 'normal_40'
      }, {
        val: 9,
        label: 'perm_press_60'
      }, {
        val: 10,
        label: 'delicate_30'
      }, {
        val: 11,
        label: 'delicate_no_heat'
      }, {
        val: 12,
        label: 'start'
      }]
    }, {
      machineAttributes: ["ACAS_A4", "TITAN_T4"],
      options: [{
        val: 1,
        label: 'light'
      }, {
        val: 2,
        label: 'medium'
      }, {
        val: 3,
        label: 'heavy'
      }, {
        val: 5,
        label: 'normal'
      }, {
        val: 6,
        label: 'perm_press'
      }, {
        val: 7,
        label: 'delicates_bulky'
      }, {
        val: 9,
        label: 'hot'
      }, {
        val: 10,
        label: 'warm'
      }, {
        val: 11,
        label: 'cold'
      }, {
        val: 12,
        label: 'start'
      }]
    }, {
      machineAttributes: ["ACAS_A44"],
      options: [{
        val: 1,
        label: 'regular'
      }, {
        val: 2,
        label: 'deluxe'
      }, {
        val: 3,
        label: 'ultra'
      }, {
        val: 5,
        label: 'normal'
      }, // no perm press button on this one
      {
        val: 7,
        label: 'delicate'
      }, {
        val: 9,
        label: 'hot'
      }, {
        val: 10,
        label: 'warm'
      }, {
        val: 11,
        label: 'cold'
      }, {
        val: 12,
        label: 'start'
      }]
    }])
  });

  _exports.default = _default;
});