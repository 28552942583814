define("alliance-business-suite/components/machines/m-type-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['machine-type-icon'],
    attributeBindings: ['title'],
    title: Ember.computed('recordId', function () {
      return `Id: ${this.recordId}`;
    })
  });

  _exports.default = _default;
});