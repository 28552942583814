define("alliance-business-suite/controllers/crm/campaigns/manage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    access: Ember.inject.service(),
    actions: {
      toggleScheduleModal() {
        if (this.access.isDemo) {
          alert('Demo mode restricted.');
          return;
        }

        this.toggleProperty('viewScheduleModal');
      }

    }
  });

  _exports.default = _default;
});