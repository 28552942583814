define("alliance-business-suite/mixins/machines/programs/mgd-options", ["exports", "alliance-business-suite/utils/speed-utilities", "alliance-business-suite/utils/convert-temp", "alliance-business-suite/utils/options-temperature-range", "alliance-business-suite/mixins/machines/programs/validate-options", "alliance-business-suite/utils/custom-hold-message"], function (_exports, _speedUtilities, _convertTemp, _optionsTemperatureRange, _validateOptions, _customHoldMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_validateOptions.default, {
    // eslint-disable-line
    // SOME OF THESE OPTIONS VALUES ALSO EXIST IN THE machine-program.js MODEL!
    //    - Currency symbol, decimal places, etc.

    /*
    -----------------
    I M P O R T A N T
    -----------------
    Options used to be kept in the `selectOptions` object. DON'T DO THIS GOING FORWARD!
    The new convention is to create individual properties with names beginning with
    'options' because some options may change or display values that come from the
    machineProgram model...which must be computed properties that observe changes
    in the model or elsewhere. That makes one big `selectOptions` computed property
    very slow and error-prone. Many smaller CPs perform much better and are easier
    to reason.
    
    */
    // ****************** New MGD stuff starts here

    /*
    MGD_M7 TUM machine types
      30 - Tumbler
      31 - Tumbler25
      32 - Tumbler30
      33 - TumblerT30
      34 - TumblerT30L
      35 - TumblerT30U
      36 - Tumbler35
      37 - TumblerT45
      38 - TumblerT45L
      39 - TumblerT45U
      40 - Tumbler50
      41 - Tumbler55
      42 - Tumbler75
      43 - Tumbler120
      44 - Tumbler170
      45 - Tumbler200
      46 - TumblerF75
    */
    // Added to get A3 FLW working
    useG: Ember.computed.alias('machineProgram._showG'),
    machineSettings: Ember.computed.alias('machineProgram._machineSettings'),
    // Requires model, object (model.settings)
    basketDiameter: Ember.computed(function () {
      return this.machineSettings?.basketDiameter;
    }),
    globalValues: Ember.computed(function () {
      // Gets the global water level for matching property names
      let returnValue = {
        highWaterLevel: this.machineProgram.settings.Program.Cycle.HighWaterLevel,
        mediumWaterLevel: this.machineProgram.settings.Program.Cycle.MediumWaterLevel,
        lowWaterLevel: this.machineProgram.settings.Program.Cycle.LowWaterLevel,
        hotWaterTemp: this.machineProgram.settings.Program.Cycle.HotWaterTemp,
        warmWaterTemp: this.machineProgram.settings.Program.Cycle.WarmWaterTemp,
        coldWaterTemp: this.machineProgram.settings.Program.Cycle.ColdWaterTemp,
        cooldownWaterTemp: this.machineProgram.settings.Program.Cycle.CoolDownWaterTemp
      };
      return returnValue;
    }),
    // THIS IS NEW STANDARDIZATION
    optionsConfig: Ember.computed(function () {
      // eslint-disable-line ember/require-computed-property-dependencies
      return [{
        name: "speedOptions",
        options: [{
          val: 1,
          label: 'Very Low (400RPM)'
        }, {
          val: 2,
          label: 'Low (500RPM)'
        }, {
          val: 3,
          label: 'Medium (650RPM)'
        }, {
          val: 4,
          label: 'High (800RPM)'
        }, {
          val: 5,
          label: 'Very High (1000RPM)'
        }, {
          val: 6,
          label: 'Ultra High (1200RPM)'
        }, {
          val: 7,
          label: 'Custom'
        }]
      }, {
        name: "rinseLevelOptions",
        options: [{
          val: 126,
          label: 'High (15)'
        }, {
          val: 125,
          label: 'Medium (10)'
        }, {
          val: 124,
          label: 'Low (6)'
        }, {
          val: 30,
          label: 'Custom',
          isCustom: true
        }]
      }, {
        name: "washLevelOptions",
        options: [{
          val: 126,
          label: 'High (15)'
        }, {
          val: 125,
          label: 'Medium (10)'
        }, {
          val: 124,
          label: 'Low (6)'
        }, {
          val: 30,
          label: 'Custom',
          isCustom: true
        }]
      }, {
        name: "ModifierNames",
        options: [{
          val: 0,
          label: 'LightMediumHeavy'
        }, {
          val: 1,
          label: 'RegularDeluxeUltra'
        }]
      }, {
        name: "DropType",
        options: [{
          label: "token",
          val: 0
        }, {
          label: "coin_value",
          isCustom: true
        }]
      }, {
        name: "DefaultCycles",
        options: [{
          val: 1,
          label: "High"
        }, {
          val: 2,
          label: "Low"
        }, {
          val: 3,
          label: "NoHeat"
        }, {
          val: 4,
          label: "Medium"
        }, {
          val: 5,
          label: "Delicate"
        }]
      }, {
        name: "NumericSeparator",
        options: [{
          label: "None",
          val: 0,
          decimalSymbol: '',
          decimalPlaces: 0,
          divideBy: 1
        }, {
          label: "Dot1",
          val: 1,
          decimalSymbol: '.',
          decimalPlaces: 1,
          divideBy: 1
        }, {
          label: "Dot2",
          val: 2,
          decimalSymbol: '.',
          decimalPlaces: 2,
          divideBy: 1
        }, {
          label: "Dot3",
          val: 3,
          decimalSymbol: '.',
          decimalPlaces: 3,
          divideBy: 1
        }, {
          label: "Comma1",
          val: 4,
          decimalSymbol: ',',
          decimalPlaces: 1,
          divideBy: 1
        }, {
          label: "Comma2",
          val: 5,
          decimalSymbol: ',',
          decimalPlaces: 2,
          divideBy: 1
        }, {
          label: "Comma3",
          val: 6,
          decimalSymbol: ',',
          decimalPlaces: 3,
          divideBy: 1
        }]
      }, {
        name: "CurrencyType",
        options: [{
          label: "None",
          val: 0,
          abbr: ""
        }, {
          label: "Dollar",
          val: 1,
          abbr: "$"
        }, {
          label: "Pound",
          val: 2,
          abbr: "£"
        }, {
          label: "Yen",
          val: 3,
          abbr: "¥"
        }, {
          label: "Won",
          val: 4,
          abbr: "₩"
        }, {
          label: "Euro",
          val: 5,
          abbr: "€"
        }, {
          label: "Rupee",
          val: 6,
          abbr: "₹"
        }, {
          label: "TurkishLira",
          val: 7,
          abbr: "₺"
        }, {
          label: "Ruble",
          val: 8,
          abbr: "₽"
        }, {
          label: "Forint",
          val: 9,
          abbr: "Ft"
        }, {
          label: "Koruna",
          val: 10,
          abbr: "Kč"
        }, {
          label: "Krona",
          val: 11,
          abbr: "kr"
        }, {
          label: "Kuna",
          val: 12,
          abbr: "kn"
        }, {
          label: "Rand",
          val: 13,
          abbr: "R"
        }, {
          label: "Real",
          val: 14,
          abbr: "R$"
        }, {
          label: "Token",
          val: 15,
          abbr: "Tk"
        }, {
          label: "Zloty",
          val: 16,
          abbr: "zł"
        }, {
          label: "Rupiah",
          val: 17
        }, {
          label: "Ringgit",
          val: 18
        }, {
          label: "Baht",
          val: 19
        }, {
          label: "Kip",
          val: 20
        }, {
          label: "Kyat",
          val: 21
        }, {
          label: "Dong",
          val: 22
        }]
      }, {
        name: "SymbolPlacement",
        options: [{
          label: "Left",
          val: false
        }, {
          label: "Right",
          val: true
        }]
      }, {
        name: "ActiveState",
        conditional: true,
        options: [{
          val: 0,
          label: 'Disabled'
        }, {
          val: 1,
          label: 'Mod1',
          notMachine: "OPL"
        }, {
          val: 2,
          label: 'Mod2',
          notMachine: "OPL"
        }, {
          val: 3,
          label: 'Mod3',
          notMachine: "OPL"
        }, {
          val: 4,
          label: 'Mod4',
          notMachine: "OPL"
        }, {
          val: 8,
          label: 'Enabled'
        }]
      }, {
        name: "StepLabel",
        options: [{
          val: 0,
          label: 'PreWash',
          group: 'Prewash'
        }, {
          val: 1,
          label: 'Wash',
          group: 'Wash'
        }, {
          val: 7,
          label: 'Bleach',
          group: 'Bleach'
        }, {
          val: 2,
          label: 'Rinse1',
          group: 'Rinse'
        }, {
          val: 3,
          label: 'Rinse2',
          group: 'Rinse'
        }, {
          val: 4,
          label: 'Rinse3',
          group: 'Rinse'
        }, {
          val: 5,
          label: 'Rinse4',
          group: 'Rinse'
        }, {
          val: 6,
          label: 'Spin',
          group: 'Spin'
        }]
      }, {
        name: "WaterLevel",
        options: [{
          val: 126,
          label: 'High',
          presetValue: this.globalWaterLevel.high
        }, {
          val: 125,
          label: 'Medium',
          presetValue: this.globalWaterLevel.medium
        }, {
          val: 124,
          label: 'Low',
          presetValue: this.globalWaterLevel.low
        }, {
          val: 0,
          label: 'Custom',
          isCustom: true
        }]
      }, {
        name: "WaterLevelOverflow",
        options: [{
          val: 127,
          label: 'Overflow'
        }, {
          val: 126,
          label: 'High',
          presetValue: this.globalWaterLevel.high
        }, {
          val: 125,
          label: 'Medium',
          presetValue: this.globalWaterLevel.medium
        }, {
          val: 124,
          label: 'Low',
          presetValue: this.globalWaterLevel.low
        }, {
          val: 0,
          label: 'Custom',
          isCustom: true
        }]
      }, {
        name: "WaterValvesControlled",
        options: [{
          label: "Cold",
          val: 0,
          hotFill: false,
          coldFill: true,
          controlledFill: false
        }, {
          label: "Warm",
          val: 1,
          hotFill: true,
          coldFill: true,
          controlledFill: false
        }, {
          label: "Hot",
          val: 2,
          hotFill: true,
          coldFill: false,
          controlledFill: false
        }, {
          label: "ControlledFill",
          val: 3,
          hotFill: false,
          coldFill: false,
          controlledFill: true
        }]
      }, {
        name: "WaterValves",
        options: [{
          label: "Cold",
          val: 0,
          hotFill: false,
          coldFill: true
        }, {
          label: "Warm",
          val: 1,
          hotFill: true,
          coldFill: true
        }, {
          label: "Hot",
          val: 2,
          hotFill: true,
          coldFill: false
        }]
      }, {
        name: "CooldownType",
        options: [{
          label: "plumbing_code",
          val: 0,
          plumbingCode: true,
          antiWrinkle: false
        }, {
          label: "anti_wrinkle",
          val: 1,
          plumbingCode: false,
          antiWrinkle: true
        }]
      }, {
        name: "HeaterOptionsTemperature",
        options: [{
          val: 2,
          label: 'global_hot_temp',
          presetValue: this.globalWaterTemperature.hot
        }, {
          val: 1,
          label: 'global_warm_temp',
          presetValue: this.globalWaterTemperature.warm
        }, {
          val: 0,
          label: 'global_cold_temp',
          presetValue: this.globalWaterTemperature.cold
        }, {
          val: 3,
          label: 'Custom',
          isCustom: true
        } // use custom value, stored in F
        ]
      }, {
        name: "SpinSpeedReuseFill",
        options: [{
          val: 1,
          label: 'Low',
          presetValue: this.machineSettings?.agitate?.presets[0],
          g: this.machineSettings?.agitate?.presets[0],
          rpm: (0, _speedUtilities.convertGtoRPM)(this.basketDiameter, this.machineSettings?.agitate?.presets[0], 10, 50)
        }, {
          val: 2,
          label: 'Regular',
          presetValue: this.machineSettings?.agitate?.presets[1],
          g: this.machineSettings?.agitate?.presets[1],
          rpm: (0, _speedUtilities.convertGtoRPM)(this.basketDiameter, this.machineSettings?.agitate?.presets[1], 10, 50)
        }, {
          val: 0,
          label: 'Disabled',
          g: 0,
          rpm: 0,
          presetValue: null
        }]
      }, {
        name: "SpinSpeedAgitate",
        options: [{
          val: 1,
          label: 'Low',
          presetValue: this.machineSettings?.agitate?.presets[0],
          g: this.machineSettings?.agitate?.presets[0],
          rpm: (0, _speedUtilities.convertGtoRPM)(this.basketDiameter, this.machineSettings?.agitate?.presets[0], 10, 50)
        }, {
          val: 2,
          label: 'Regular',
          presetValue: this.machineSettings?.agitate?.presets[1],
          g: this.machineSettings?.agitate?.presets[1],
          rpm: (0, _speedUtilities.convertGtoRPM)(this.basketDiameter, this.machineSettings?.agitate?.presets[1], 10, 50)
        }, {
          val: 255,
          label: 'Custom',
          isCustom: true
        }, {
          val: 0,
          label: 'Disabled',
          g: 0,
          rpm: 0,
          presetValue: null
        }]
      }, {
        name: "SpinSpeedSprayRinseExtract",
        options: [{
          val: 1,
          label: 'VeryLow',
          presetValue: this.machineSettings?.extract?.presets[0],
          g: this.machineSettings?.extract?.presets[0],
          rpm: (0, _speedUtilities.convertGtoRPM)(this.basketDiameter, this.machineSettings?.extract?.presets[0])
        }, {
          val: 2,
          label: 'Low',
          presetValue: this.machineSettings?.extract?.presets[1],
          g: this.machineSettings?.extract?.presets[1],
          rpm: (0, _speedUtilities.convertGtoRPM)(this.basketDiameter, this.machineSettings?.extract?.presets[1])
        }, {
          val: 7,
          label: 'Custom',
          isCustom: true
        }]
      }, {
        name: "SpinSpeedExtract",
        options: [{
          val: 1,
          label: 'VeryLow',
          presetValue: this.machineSettings?.extract?.presets[0],
          g: this.machineSettings?.extract?.presets[0],
          rpm: (0, _speedUtilities.convertGtoRPM)(this.basketDiameter, this.machineSettings?.extract?.presets[0])
        }, {
          val: 2,
          label: 'Low',
          presetValue: this.machineSettings?.extract?.presets[1],
          g: this.machineSettings?.extract?.presets[1],
          rpm: (0, _speedUtilities.convertGtoRPM)(this.basketDiameter, this.machineSettings?.extract?.presets[1])
        }, {
          val: 3,
          label: 'Medium',
          presetValue: this.machineSettings?.extract?.presets[2],
          g: this.machineSettings?.extract?.presets[2],
          rpm: (0, _speedUtilities.convertGtoRPM)(this.basketDiameter, this.machineSettings?.extract?.presets[2])
        }, {
          val: 4,
          label: 'High',
          presetValue: this.machineSettings?.extract?.presets[3],
          g: this.machineSettings?.extract?.presets[3],
          rpm: (0, _speedUtilities.convertGtoRPM)(this.basketDiameter, this.machineSettings?.extract?.presets[3])
        }, {
          val: 5,
          label: 'VeryHigh',
          presetValue: this.machineSettings?.extract?.presets[4],
          g: this.machineSettings?.extract?.presets[4],
          rpm: (0, _speedUtilities.convertGtoRPM)(this.basketDiameter, this.machineSettings?.extract?.presets[4])
        }, {
          val: 6,
          label: 'UltraHigh',
          presetValue: this.machineSettings?.extract?.presets[5],
          g: this.machineSettings?.extract?.presets[5],
          rpm: (0, _speedUtilities.convertGtoRPM)(this.basketDiameter, this.machineSettings?.extract?.presets[5])
        }, {
          val: 7,
          label: 'Custom',
          isCustom: true
        } // Yes, custom is NOT 255 for extract
        ]
      }, {
        name: "SpinSpeedDrain",
        options: [{
          val: 1,
          label: 'Low',
          presetValue: this.machineSettings?.drain?.presets[0],
          g: this.machineSettings?.drain?.presets[0],
          rpm: (0, _speedUtilities.convertGtoRPM)(this.basketDiameter, this.machineSettings?.drain?.presets[0], 10, 50)
        }, {
          val: 2,
          label: 'Regular',
          presetValue: this.machineSettings?.drain?.presets[1],
          g: this.machineSettings?.drain?.presets[1],
          rpm: (0, _speedUtilities.convertGtoRPM)(this.basketDiameter, this.machineSettings?.drain?.presets[1], 10, 50)
        }, {
          val: 3,
          label: 'Distribution',
          presetValue: this.machineSettings?.drain?.presets[2],
          g: this.machineSettings?.drain?.presets[2],
          rpm: (0, _speedUtilities.convertGtoRPM)(this.basketDiameter, this.machineSettings?.drain?.presets[2], 10, 50)
        }, {
          val: 255,
          label: 'Custom',
          isCustom: true
        }, {
          val: 0,
          label: 'Disabled',
          g: 0,
          rpm: 0
        }]
      }, {
        name: "StepType",
        options: [// { label: "Empty", val: 0, active: false },
        {
          label: "ReuseFill",
          val: 1,
          active: true
        }, {
          label: "Fill",
          val: 2,
          active: true
        }, {
          label: "Supply",
          val: 3,
          active: true
        }, {
          label: "Agitate",
          val: 4,
          active: true
        }, {
          label: "Soak",
          val: 5,
          active: true
        }, {
          label: "CoolDown",
          val: 6,
          active: true
        }, {
          label: "Drain",
          val: 7,
          active: true
        }, {
          label: "SprayRinseExtract",
          val: 8,
          active: false
        }, {
          label: "DrainExtract",
          val: 9,
          active: true
        }, {
          label: "AudioSignal",
          val: 10,
          active: true
        }, {
          label: "Hold",
          val: 11,
          active: true
        }, {
          label: "WashSpray",
          val: 12,
          active: false
        }]
      }, {
        name: "SupplyCondition",
        options: [{
          val: 0,
          label: 'until_supply_time_expires'
        }, {
          val: 1,
          label: 'until_target_water_level'
        } // { val: 2, label: 'EarlySmartWAVeShutoff' },
        ]
      }, {
        name: "HeatOption",
        options: [{
          val: 0,
          label: 'no_heat'
        }, {
          val: 1,
          label: 'heat_to_target'
        }, {
          val: 2,
          label: 'maintain_heat'
        }]
      }, {
        name: "HeatRateOption",
        options: [{
          val: 0,
          label: 'no_rate_restriction'
        }, // presetValue is 6.0...after dividing by 10
        {
          val: 5,
          label: 'increment_by_time',
          isCustom: true
        } // value becomes the custom setting...saved as 10x the displayed value
        ]
      }, {
        name: "CooldownTemperature",
        options: [{
          val: 0,
          label: 'global_cool_down',
          presetValue: this.globalValues.cooldownWaterTemp
        }, {
          val: this.globalValues.cooldownWaterTemp,
          label: 'Custom',
          isCustom: true
        }]
      }, {
        name: "CooldownRate",
        options: [{
          val: 0,
          label: 'no_rate_restriction'
        }, {
          val: 1,
          label: 'decrement_by_time',
          isCustom: true
        }]
      }, {
        name: "OptiSpray",
        options: [{
          val: 0,
          label: 'Custom'
        }, {
          val: 1,
          label: 'Towels'
        }, {
          val: 2,
          label: 'Sheets'
        }, {
          val: 3,
          label: 'General'
        }]
      }, {
        name: "AudioPattern",
        options: [{
          val: 0,
          label: 'NoTone'
        }, {
          val: 1,
          label: 'SolidHigh'
        }, {
          val: 2,
          label: 'SolidMedium'
        }, {
          val: 3,
          label: 'SolidLow'
        }, {
          val: 4,
          label: 'HighBeep'
        }, {
          val: 5,
          label: 'MediumBeep'
        }, {
          val: 6,
          label: 'LowBeep'
        }, {
          val: 7,
          label: 'HighDoubleQuickBeep'
        }, {
          val: 8,
          label: 'MediumDoubleQuickBeep'
        }, {
          val: 9,
          label: 'LowDoubleQuickBeep'
        }, {
          val: 10,
          label: 'HighSOSBeep'
        }, {
          val: 11,
          label: 'MediumSOSBeep'
        }, {
          val: 12,
          label: 'LowSOSBeep'
        }, {
          val: 13,
          label: 'IncreasingTone'
        }, {
          val: 14,
          label: 'SinusoidalTone'
        }, {
          val: 15,
          label: 'Melody1'
        }, {
          val: 16,
          label: 'Melody2'
        }, {
          val: 17,
          label: 'Melody3'
        }, {
          val: 18,
          label: 'Melody4'
        }, {
          val: 19,
          label: 'Melody5'
        }, {
          val: 20,
          label: 'Melody6'
        }]
      }, {
        name: "GlobalMessage",
        options: [{
          val: 0,
          label: 'Load'
        }, {
          val: 1,
          label: 'Chemical'
        }, {
          val: 2,
          label: 'Soap'
        }, {
          val: 3,
          label: 'Bleach'
        }, {
          val: 4,
          label: 'Softener'
        }, {
          val: 5,
          label: 'Sour'
        }, {
          val: 6,
          label: 'Starch'
        }, {
          val: 7,
          label: `${(0, _customHoldMessage.base64ToUnicode)(this.customMessage1)} (Custom 1)`
        }, {
          val: 8,
          label: `${(0, _customHoldMessage.base64ToUnicode)(this.customMessage2)} (Custom 2)`
        }]
      }];
    }),
    // TUM BELOW
    optionsStepTemperatureTum: [{
      val: 0,
      label: "very_low",
      global: "120F"
    }, {
      val: 1,
      label: "low",
      global: "140F"
    }, {
      val: 2,
      label: "medium",
      global: "160F"
    }, {
      val: 3,
      label: "high",
      global: "190F"
    }, {
      val: 100,
      label: "100"
    }, {
      val: 105,
      label: "105"
    }, {
      val: 110,
      label: "110"
    }, {
      val: 115,
      label: "115"
    }, {
      val: 120,
      label: "120"
    }, {
      val: 125,
      label: "125"
    }, {
      val: 130,
      label: "130"
    }, {
      val: 135,
      label: "135"
    }, {
      val: 140,
      label: "140"
    }, {
      val: 145,
      label: "145"
    }, {
      val: 150,
      label: "150"
    }, {
      val: 155,
      label: "155"
    }, {
      val: 160,
      label: "160"
    }, {
      val: 165,
      label: "165"
    }, {
      val: 170,
      label: "170"
    }, {
      val: 175,
      label: "175"
    }, {
      val: 180,
      label: "180"
    }, {
      val: 185,
      label: "185"
    }, {
      val: 190,
      label: "190"
    }],
    optionsStepMoistureTum: [{
      val: 0,
      label: "0%"
    }, {
      val: 1,
      label: "1%"
    }, {
      val: 3,
      label: "3%"
    }, {
      val: 5,
      label: "5%"
    }, {
      val: 7,
      label: "7%"
    }, {
      val: 10,
      label: "10%"
    }, {
      val: 15,
      label: "15%"
    }, {
      val: 20,
      label: "20%"
    }, {
      val: 25,
      label: "25%"
    }, {
      val: 30,
      label: "30%"
    }, {
      val: 35,
      label: "35%"
    }, {
      val: 40,
      label: "40%"
    }],
    optionsStepTypeTum: [{
      val: 0,
      label: "time"
    }, {
      val: 1,
      label: "auto"
    }, {
      val: 2,
      label: "moisture_dry"
    }],
    optionsMaterialTum: [{
      val: 0,
      label: "cotton"
    }, {
      val: 1,
      label: "blend"
    }, {
      val: 2,
      label: "bedding"
    }, {
      val: 3,
      label: "delicate"
    }, {
      val: 4,
      label: "synthetics"
    }, {
      val: 5,
      label: "wool"
    }],
    optionsRotateTimeTum: [// Seconds
    {
      val: 3,
      label: "30"
    }, {
      val: 4,
      label: "45"
    }, {
      val: 5,
      label: "60"
    }, {
      val: 6,
      label: "90"
    }, {
      val: 7,
      label: "120"
    }, {
      val: 8,
      label: "240"
    }, {
      val: 9,
      label: "540"
    }],
    optionsStartPulseMode: [{
      val: 0,
      label: 'Off',
      key: 'off'
    }, {
      val: 128,
      label: 'SinglePulse',
      key: 'single'
    }, {
      val: 192,
      label: 'MultiplePulses',
      key: 'multiple'
    }],
    optionsPauseTimeTum: Ember.computed('machineProgram.machineType.id', function () {
      let machineType = Number(Ember.get(this, 'machineProgram.machineType.id')); // eslint-disable-line ember/no-get

      if ([43, 44, 45].includes(machineType)) {
        // 120-200#
        return [{
          val: 0,
          label: "10"
        }, {
          val: 1,
          label: "11"
        }, {
          val: 2,
          label: "12"
        }, {
          val: 3,
          label: "13"
        }, {
          val: 4,
          label: "14"
        }];
      } else {
        // 25-75#
        return [{
          val: 0,
          label: "6"
        }, {
          val: 1,
          label: "7"
        }, {
          val: 2,
          label: "8"
        }, {
          val: 3,
          label: "9"
        }, {
          val: 4,
          label: "10"
        }];
      }
    }),
    optionsCooldownTempTum: [{
      val: 70,
      F: 70,
      C: 21,
      label: "70"
    }, {
      val: 75,
      F: 75,
      C: 24,
      label: "75"
    }, {
      val: 80,
      F: 80,
      C: 27,
      label: "80"
    }, {
      val: 85,
      F: 85,
      C: 29,
      label: "85"
    }, {
      val: 90,
      F: 90,
      C: 32,
      label: "90"
    }, {
      val: 95,
      F: 95,
      C: 35,
      label: "95"
    }, {
      val: 100,
      F: 100,
      C: 38,
      label: "100"
    }, {
      val: 105,
      F: 105,
      C: 41,
      label: "105"
    }, {
      val: 110,
      F: 110,
      C: 43,
      label: "110"
    }],
    optionsTemperatureHighTum: Ember.computed('machineProgram.{machineType.id,_machineTypeCode}', function () {
      let machineTypeCode = this.machineProgram._machineTypeCode;
      let machineType = Number(Ember.get(this, 'machineProgram.machineType.id')); // eslint-disable-line ember/no-get

      switch (true) {
        case machineTypeCode === "DRY":
          return (0, _optionsTemperatureRange.default)(100, 160, 5);

        case [40, 42, 46, 43, 44, 45].includes(machineType):
          return (0, _optionsTemperatureRange.default)(160, 190, 5);

        case [31, 32, 33, 34, 35].includes(machineType):
          return (0, _optionsTemperatureRange.default)(155, 190, 5);

        case [36, 37, 38, 39, 41].includes(machineType):
          return (0, _optionsTemperatureRange.default)(160, 190, 5);

        default:
          return (0, _optionsTemperatureRange.default)(160, 190, 5);
        // Most restrictive range if machine type not found
      }
    }),
    optionsTemperatureMediumTum: Ember.computed('machineProgram.{machineType.id,_machineTypeCode}', function () {
      let machineTypeCode = this.machineProgram._machineTypeCode;
      let machineType = Number(Ember.get(this, 'machineProgram.machineType.id')); // eslint-disable-line ember/no-get

      switch (true) {
        case machineTypeCode === "DRY":
          return (0, _optionsTemperatureRange.default)(100, 160, 5);

        case [40, 42, 46, 43, 44, 45].includes(machineType):
          return (0, _optionsTemperatureRange.default)(140, 160, 5);

        case [31, 32, 33, 34, 35].includes(machineType):
          return (0, _optionsTemperatureRange.default)(135, 160, 5);

        case [36, 37, 38, 39, 41].includes(machineType):
          return (0, _optionsTemperatureRange.default)(145, 165, 5);

        default:
          return (0, _optionsTemperatureRange.default)(145, 160, 5);
        // Most restrictive range if machine type not found
      }
    }),
    optionsTemperatureLowTum: Ember.computed('machineProgram.{machineType.id,_machineTypeCode}', function () {
      let machineTypeCode = this.machineProgram._machineTypeCode;
      let machineType = Number(Ember.get(this, 'machineProgram.machineType.id')); // eslint-disable-line ember/no-get

      switch (true) {
        case machineTypeCode === "DRY":
          return (0, _optionsTemperatureRange.default)(100, 160, 5);

        case machineTypeCode === "TUM" & [40, 42, 46, 43, 44, 45].includes(machineType):
          return (0, _optionsTemperatureRange.default)(120, 140, 5);

        case machineTypeCode === "TUM" & [31, 32, 33, 34, 35].includes(machineType):
          return (0, _optionsTemperatureRange.default)(105, 145, 5);

        case machineTypeCode === "TUM" & [36, 37, 38, 39, 41].includes(machineType):
          return (0, _optionsTemperatureRange.default)(125, 155, 5);

        default:
          return (0, _optionsTemperatureRange.default)(125, 140, 5);
        // Most restrictive range if machine type not found
      }
    }),
    optionsTemperatureVeryLowTum: Ember.computed('machineProgram._machineTypeCode', function () {
      let machineTypeCode = this.machineProgram._machineTypeCode;

      switch (true) {
        case machineTypeCode === "DRY":
          return (0, _optionsTemperatureRange.default)(100, 160, 5);

        default:
          return (0, _optionsTemperatureRange.default)(100, 120, 5);
        // Most restrictive range if machine type not found
      }
    }),
    // optionsTemperatureVeryLowTum: [
    //   { val: 100, F: 100, C: 38 },
    //   { val: 105, F: 105, C: 41 },
    //   { val: 110, F: 110, C: 43 },
    //   { val: 115, F: 115, C: 46 },
    //   { val: 120, F: 120, C: 49 }
    // ],
    optionsAvailableTypeOpl: [{
      label: "AvailableWithStart",
      val: 1
    }, {
      label: "AvailableWithEOC",
      val: 2
    }, {
      label: "AvailableWithStartAndEOC",
      val: 3
    }, {
      label: "InUse",
      val: 8
    }, {
      label: "CycleComplete",
      val: 9
    }, {
      label: "EndOfCycle",
      val: 12
    }, {
      label: "MotorOn",
      val: 13
    }],
    // optionsAvailableTypeTum: [
    //   { label: "AvailableWithStart", val: 1 },
    //   { label: "AvailableWithEOC", val: 2 },
    //   { label: "AvailableWithStartAndEOC", val: 3 },
    //   { label: "InUse", val: 8 },
    //   { label: "CycleComplete", val: 9 },
    //   { label: "EndOfCycle", val: 12 },
    //   { label: "MotorOn", val: 13 },
    // ],
    optionsAvailableTypeTum: Ember.computed('machineProgram._machineTypeCode', function () {
      switch (this.machineProgram._machineTypeCode) {
        case "TUM":
          return [{
            label: "AvailableWithStart",
            val: 1
          }, {
            label: "AvailableWithEOC",
            val: 2
          }, {
            label: "AvailableWithStartAndEOC",
            val: 3
          }, {
            label: "InUse",
            val: 8
          }, {
            label: "CycleComplete",
            val: 9
          }, {
            label: "EndOfCycle",
            val: 12
          }, {
            label: "MotorOn",
            val: 13
          }];

        default:
          // DRY
          return [{
            label: "Available",
            val: 0
          }, {
            label: "AvailableWithStart",
            val: 1
          }, {
            label: "AvailableWithEOC",
            val: 2
          }, {
            label: "AvailableWithStartAndEOC",
            val: 3
          }, {
            label: "VendNotSatisfied",
            val: 4
          }, {
            label: "VendNotSatisfiedAndEOC",
            val: 5
          }, {
            label: "AvaiableWithVend",
            val: 6
          }, {
            label: "AvailableWithVendAndEOC",
            val: 7
          }, {
            label: "InUse",
            val: 8
          }, {
            label: "CycleComplete",
            val: 9
          }, {
            label: "LuckyCycleTriggered",
            val: 10
          }, {
            label: "BreakinTriggered",
            val: 11
          }, {
            label: "EndOfCycle",
            val: 12
          }, {
            label: "MotorOn",
            val: 13
          }];
      }
    }),
    // WX BELOW
    optionsDefaultCycles: [{
      val: 1,
      label: 'normal_hot',
      index: 0
    }, {
      val: 2,
      label: 'normal_warm',
      index: 1
    }, {
      val: 3,
      label: 'normal_cold',
      index: 2
    }, {
      val: 4,
      label: 'perm_press_hot',
      index: 3
    }, {
      val: 5,
      label: 'perm_press_warm',
      index: 4
    }, {
      val: 6,
      label: 'perm_press_cold',
      index: 5
    }, {
      val: 7,
      label: 'delicate_hot',
      index: 6
    }, {
      val: 8,
      label: 'delicate_warm',
      index: 7
    }, {
      val: 9,
      label: 'delicate_cold',
      index: 8
    }],
    optionsSoil: Ember.computed('machineProgram.settings.Program.Price.ModifierName', function () {
      if (this.machineProgram.settings.Program.Price.ModifierName === 0) {
        return [{
          val: 0,
          label: 'light'
        }, {
          val: 1,
          label: 'medium'
        }, {
          val: 2,
          label: 'heavy'
        }];
      } else {
        return [{
          val: 0,
          label: 'Regular'
        }, // Lokalise has this twice: lowercase and title.
        {
          val: 1,
          label: 'deluxe'
        }, {
          val: 2,
          label: 'ultra'
        }];
      }
    }),
    cycleStepDefaults: {},
    optionsTemperatureUnits: [{
      val: true,
      label: 'Celsius'
    }, {
      val: false,
      label: 'Fahrenheit'
    }],
    optionsExternalDispenserPause: [{
      val: 0,
      label: "Disabled"
    }, {
      val: 1,
      label: "ExternalDispenserPause_WX"
    }, {
      val: 2,
      label: "EmptyDispenserBin"
    }],
    optionsCycleExternalSignalOutput: [{
      val: 0,
      label: '1 - A'
    }, {
      val: 1,
      label: '2 - B'
    }, {
      val: 2,
      label: '3 - C'
    }, {
      val: 3,
      label: '4 - D'
    }, {
      val: 4,
      label: '5 - E'
    }, {
      val: 5,
      label: '6 - F'
    }, {
      val: 6,
      label: '7 - G'
    }, {
      val: 7,
      label: '8 - H'
    }, {
      val: 8,
      label: 'I'
    }, {
      val: 9,
      label: 'J'
    }, {
      val: 10,
      label: 'K'
    }, {
      val: 11,
      label: 'L'
    }, {
      val: 12,
      label: 'M'
    }, {
      val: 13,
      label: 'N'
    }],
    optionsAvailability: [{
      val: 0,
      label: "availablity_available"
    }, {
      val: 1,
      label: "availablity_available_with_start"
    }, {
      val: 2,
      label: "availablity_available_with_end_of_cycle"
    }, {
      val: 3,
      label: "availablity_available_with_start_and_end_of_cycle"
    }, {
      val: 4,
      label: "availablity_vend_is_not_satisfied"
    }, {
      val: 5,
      label: "availablity_vend_is_not_satisfied_with_end_of_cycle"
    }, {
      val: 6,
      label: "availablity_available_with_vend"
    }, {
      val: 7,
      label: "availablity_available_with_vend_and_end_of_cycle"
    }, {
      val: 8,
      label: "availablity_machine_is_in_use"
    }, {
      val: 9,
      label: "availablity_cycle_is_complete"
    }, {
      val: 10,
      label: "availablity_lucky_cycle"
    }, {
      val: 11,
      label: "availablity_break_in_alarm"
    }, {
      val: 12,
      label: "availablity_end_of_cycle_is_entered"
    }, {
      val: 13,
      label: "availablity_motor_running"
    }],
    optionsAvailabilityOpl: [{
      val: 1,
      label: "availablity_available_with_start"
    }, {
      val: 2,
      label: "availablity_available_with_end_of_cycle"
    }, {
      val: 3,
      label: "availablity_available_with_start_and_end_of_cycle"
    }, {
      val: 8,
      label: "availablity_machine_is_in_use"
    }, {
      val: 9,
      label: "availablity_cycle_is_complete"
    }, {
      val: 12,
      label: "availablity_end_of_cycle_is_entered"
    }, {
      val: 13,
      label: "availablity_motor_running"
    }],
    optionsCellConfiguration: [{
      val: 0,
      label: "3_cell"
    }, {
      val: 1,
      label: "4_cell"
    }, {
      val: 2,
      label: "5_cell"
    }],
    optionsSpeedUnits: [{
      val: false,
      label: 'rpm'
    }, {
      val: true,
      label: 'g_force'
    }],
    optionsDefaultLanguage: [{
      val: 0,
      label: "NotConfigured"
    }, {
      val: 1,
      label: "English"
    }, {
      val: 2,
      label: "SpanishColumbian"
    }, {
      val: 3,
      label: "Spanish"
    }, {
      val: 4,
      label: "French"
    }, {
      val: 5,
      label: "ChineseSimplified"
    }, {
      val: 6,
      label: "ChineseTraditional"
    }, {
      val: 7,
      label: "Russian"
    }, {
      val: 8,
      label: "German"
    }, {
      val: 9,
      label: "Japanese"
    }, {
      val: 10,
      label: "Italian"
    }, {
      val: 11,
      label: "PortugueseEuropean"
    }, {
      val: 12,
      label: "PortugueseBrazilian"
    }, {
      val: 13,
      label: "Korean"
    }, {
      val: 14,
      label: "Dutch"
    }],
    optionsDateOrder: [{
      val: 0,
      label: 'Month_Day_Year'
    }, {
      val: 1,
      label: 'Day_Month_Year'
    }, {
      val: 2,
      label: 'Year_Month_Day'
    }],

    // ****************** New MGD stuff ends here. TODO: everything else should be deleted
    // TODO: Possible code smell. This breaks for pricing tab, but works for all others.
    getSpeedPreset(name, index) {
      let array = Ember.get(this, name);

      if (array && array.length > index) {
        return array[index];
      } else {
        console.error("Preset value not found for %s, index %s.", name, index); // eslint-disable-line no-console

        return 0;
      }
    },

    CoolDownWaterTemperature: Ember.computed.alias('machineProgram.settings.Program.Cycle.CoolDownWaterTemperature'),
    _CoolDownWaterTemperature: Ember.computed('CoolDownWaterTemperature', 'celsiusEnabled', {
      get() {
        let temp = this.CoolDownWaterTemperature; // Always F

        let isCelsius = this.celsiusEnabled;
        return isCelsius ? (0, _convertTemp.toC)(temp) : temp; // Converts to C if needed
      },

      set(key, value) {
        let isCelsius = this.celsiusEnabled;
        Ember.set(this, 'CoolDownWaterTemperature', isCelsius ? (0, _convertTemp.toF)(value) : value);
        return value;
      }

    }),
    globalWaterTemperature: Ember.computed('machineProgram.settings.Program.Cycle.{HotWaterTemp,WarmWaterTemp,ColdWaterTemp}', function () {
      return {
        hot: this.machineProgram.settings.Program.Cycle.HotWaterTemp,
        warm: this.machineProgram.settings.Program.Cycle.WarmWaterTemp,
        cold: this.machineProgram.settings.Program.Cycle.ColdWaterTemp
      };
    }),
    globalWaterLevel: Ember.computed('machineProgram.settings.Program.Cycle.{HighWaterLevel,MediumWaterLevel,LowWaterLevel}', function () {
      return {
        low: this.machineProgram.settings.Program.Cycle.LowWaterLevel,
        medium: this.machineProgram.settings.Program.Cycle.MediumWaterLevel,
        high: this.machineProgram.settings.Program.Cycle.HighWaterLevel
      };
    })
  });

  _exports.default = _default;
});