define("alliance-business-suite/components/services/room-services-create", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'selectedServiceType': [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    'selectedService': [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })]
  });
  const SERVICE_TYPES = [{
    id: 'order-setting',
    text: 'order_setting',
    subtitle: 'subtitle_order_setting'
  }, {
    id: 'line-item-type',
    text: 'line_item_type',
    subtitle: 'subtitle_line_item_type'
  }];

  var _default = Ember.Component.extend(Validations, {
    access: Ember.inject.service(),
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    isSaving: false,
    model: null,
    currentType: null,

    didInsertElement() {
      this._super(...arguments);

      this.set('isLoading', true);
      this.fetchOrderSettingTypes();
      this.fetchOrderLineItemTypes();
    },

    currencySymbol: Ember.computed(function () {
      return this.get('access.currencyDenomination');
    }),
    currencyDecimals: Ember.computed(function () {
      if (this.get('access.currencySubunit') === 1) {
        return 0;
      } else {
        return 2;
      }
    }),
    displayPrice: Ember.computed(function () {
      return this.get('model.price');
    }),
    taxOptions: Ember.computed('taxes', {
      get() {
        return this.taxes;
      }

    }),
    selectedTaxes: Ember.computed({
      get() {
        return this.get('model.taxRates');
      }

    }),
    adjustPriceObserver: Ember.observer('displayPrice', function () {
      let displayPrice = this.displayPrice;
      this.set('model.price', displayPrice);
    }),
    rooms: Ember.computed(function () {
      return this.store.peekAll('room').sortBy('formattedBreadcrumbDisplay');
    }),
    serviceTypes: Ember.computed(function () {
      return SERVICE_TYPES;
    }),
    servicesOptions: Ember.computed('selectedServiceType', function () {
      let type = this.selectedServiceType;
      let modelKey = type.id;
      let peekKey = modelKey == 'line-item-type' ? 'order-line-item-type' : 'order-setting-type';

      let _services = modelKey == 'line-item-type' ? '_lineItemTypes' : '_orderSettings';

      let result = []; // get all the line items types served in a room

      let servicesInRoom = this.get(_services);
      let array1 = []; // create an array of all the order line item types in those (things we dont want to show) Array1

      servicesInRoom.forEach(s => {
        if (modelKey == 'line-item-type') {
          array1.push(s.get('lineItemType.id'));
        } else {
          array1.push(s.get('orderSettingType.id'));
        }
      }); // get all the order line item types

      let _orgServices = null;
      _orgServices = this.store.peekAll(peekKey); // create an array of all order line item types id (base array) Array2

      let array3 = []; // if any id in Array2 is found in Array1, we dont care
      // if any id in Array2 is not found in Array2, we push to Array3 (diff)

      _orgServices.forEach(s => {
        let id = s.get('id');
        let indexOf = array1.indexOf(id);

        if (indexOf == -1) {
          array3.push(id);
        }
      });

      array3.forEach(id => {
        result.push(this.store.peekRecord(peekKey, id));
      });
      return result;
    }),
    selectedServiceObserver: Ember.observer('selectedService', function () {
      let type = this.selectedService;

      if (type && type.get('modelType') == 'order-line-item-type') {
        this.set('model.lineItemType', type);
      } else {
        this.set('model.orderSettingType', type);
      }
    }),

    async fetchOrderSettingTypes() {
      let self = this;
      await this.store.findAll('order-setting-type', {
        reload: true
      });
      self.notifyPropertyChange('_orderSettingTypes');
      this.set('isLoading', false);
    },

    async fetchOrderLineItemTypes() {
      let self = this;
      await this.store.findAll('order-line-item-type', {
        reload: true
      });
      self.notifyPropertyChange('_orderLineItemTypes');
      this.set('isLoading', false);
    },

    actions: {
      changeRoom(room) {
        let self = this;
        this.set('selectedRoom', room);
        this.set('model.room', room);
      },

      exit(model) {
        if (model && model.get('hasDirtyAttributes')) {
          model.rollbackAttributes();
        }

        this.close();
      },

      updatedServiceType(type) {
        let model = this.model;

        if (model && model.get('hasDirtyAttributes')) {
          model.deleteRecord();
        }

        let newItem = this.store.createRecord(type.id);
        this.set('model', newItem);
        this.set('model.room', this.room);
        this.set('selectedServiceType', type);
        this.set('currentType', type.id);
      },

      save() {
        let model = this.model;
        let roomId = this.get('room.id');
        let url = '';
        let data = {};
        let taxRates = [];
        let selectedTaxes = this.selectedTaxes;

        if (!this.selectedService) {
          Ember.get(this, 'notifications').error(this.intl.t('error_no_service_type'), {
            autoClear: true,
            clearDuration: 5000
          });
          return false;
        }

        if (selectedTaxes) {
          selectedTaxes.forEach(taxRate => {
            taxRates.push({
              id: taxRate.get('id')
            });
          });
        }

        this.set('isSaving', true);
        let price = model.get('price') ? model.get('price') : 0;

        if (model.get('lineItemType')) {
          url = `/rooms/${roomId}/lineItemTypes`;
          data = {
            price,
            isFlatAmount: model.get('isFlatAmount'),
            taxRates,
            lineItemType: {
              id: model.get('lineItemType.id')
            }
          };
        } else {
          url = `/rooms/${roomId}/orderSettings`;
          data = {
            price,
            taxRates,
            orderSettingType: {
              id: model.get('orderSettingType.id')
            }
          };
        }

        this.ajax.request(url, {
          method: 'PATCH',
          data
        }).then(response => {
          // remove the temporary item in DS
          let model = this.model;
          model.deleteRecord();
          this.refreshServices();
          this.close();
          this.notifications.info(`${this.get('room.formattedBreadcrumbDisplay')} ${this.intl.t('service_added')}`, {
            autoClear: true,
            clearDuration: 5000
          });
          return response;
        }).catch((error
        /* , jqXHR */
        ) => {
          this.notifications.error(this.intl.t('please_try_again'), {
            autoClear: true,
            clearDuration: 5000
          });
          this.set('isSaving', false);
          return error;
        });
      }

    }
  });

  _exports.default = _default;
});