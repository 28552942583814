define("alliance-business-suite/components/form-ui/duration-field/duration-field", ["exports", "jquery", "alliance-business-suite/components/form-ui/duration-field/utils/pad", "alliance-business-suite/components/form-ui/duration-field/utils/mod", "alliance-business-suite/components/form-ui/duration-field/states/manager", "alliance-business-suite/components/form-ui/duration-field/utils/codes"], function (_exports, _jquery, _pad, _mod, _manager, _codes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: 'duration-field',
    tagName: 'input',
    type: 'text',
    autocomplete: false,
    disabled: false,
    attributeBindings: ['type', 'placeholder', 'name', 'autocomplete', 'disabled'],
    hours: null,
    minutes: null,
    seconds: null,
    intl: Ember.inject.service(),
    showHours: true,
    // set in init()
    showMinutes: true,
    showSeconds: Ember.computed('display', function () {
      // Show seconds unless we get `display` without an `s` in it.
      if (this.display && !this.display.includes('s')) {
        return false;
      } else {
        return true;
      }
    }),
    RANGES: Ember.computed('showHours', function () {
      let ranges = {};

      if (this.showHours) {
        ranges = {
          HOUR: {
            START: 0,
            END: 3
          },
          MINUTE: {
            START: 4,
            END: 7
          },
          SECOND: {
            START: 8,
            END: 11
          }
        };
      } else {
        ranges = {
          MINUTE: {
            START: 0,
            END: 3
          },
          SECOND: {
            START: 4,
            END: 7
          }
        };
      }

      if (!this.showSeconds) {
        delete ranges.SECOND;
      }

      return ranges;
    }),

    //#region Lifecycle Events
    init() {
      this._super();

      this.set("stateManager", _manager.default.create({
        input: this
      })); // Based on the max value allowed, figure out if we display hours or not.

      let max = this.max;

      if (!Ember.isNone(max)) {
        this.set('showHours', max >= 3600); // We need to set in two places to make it work. Why?

        let stateManager = this.stateManager;
        stateManager.set('showHours', max >= 3600);
      }
    },

    didReceiveAttrs() {
      this._super();

      const value = this.value;
      let hours = null;
      let minutes = null;
      let seconds = null;

      if (value) {
        hours = Ember.get(value, "hours");
        minutes = Ember.get(value, "minutes");
        seconds = Ember.get(value, "seconds");
      }

      this.setProperties({
        hours,
        minutes,
        seconds
      });
    },

    didRender() {
      this.updateDOMValue();
      this.stateManager.send("refocus");
    },

    //#endregion
    _value: Ember.computed("hours", "minutes", "seconds", "showHours", {
      get() {
        const {
          hours,
          minutes,
          seconds
        } = this;
        const hoursValue = Ember.isNone(hours) ? '--' : (0, _pad.default)(hours);
        const minutesValue = Ember.isNone(minutes) ? '--' : (0, _pad.default)(minutes);
        const secondsValue = Ember.isNone(seconds) ? '--' : (0, _pad.default)(seconds);
        const intl = this.intl;
        let str = '';

        if (this.showHours) {
          str += `${hoursValue}${intl.t('duration_hours_single_letter')} `;
        }

        if (this.showMinutes) {
          str += `${minutesValue}${intl.t('duration_minutes_single_letter')} `;
        }

        if (this.showSeconds) {
          str += `${secondsValue}${intl.t('duration_seconds_single_letter')}`;
        }

        return str;
      }

    }),

    triggerStateEvent(name) {
      this.stateManager.send(name);
    },

    // for now just ignore tab
    shouldHandleKey(code) {
      return code !== _codes.KEY_CODES.TAB;
    },

    //#region DOM Events
    keyUp(e) {
      if (!this.shouldHandleKey(e.keyCode)) {
        return;
      }

      e.preventDefault();
    },

    keyDown(e) {
      if (!this.shouldHandleKey(e.keyCode)) {
        return;
      }

      e.preventDefault();

      switch (e.keyCode) {
        case _codes.KEY_CODES.LEFT:
          this.stateManager.send("left");
          break;

        case _codes.KEY_CODES.RIGHT:
          this.stateManager.send("right");
          break;

        case _codes.KEY_CODES.UP:
          this.stateManager.send("up");
          break;

        case _codes.KEY_CODES.DOWN:
          this.stateManager.send("down");
          break;

        default:
          this.stateManager.send("key", e.keyCode);
          break;
      }
    },

    focusIn() {
      // ignore if part of click otherwise we move focus too early
      if (!this._handlingClick) {
        this.stateManager.send("focusIn");
      }
    },

    focusOut() {
      this.stateManager.send("focusOut");
    },

    mouseDown() {
      this._handlingClick = true;
    },

    mouseUp() {
      this._handlingClick = false;
      const el = this.element;
      const cursor = el.selectionStart;
      const RANGES = this.RANGES;

      if (this.showHours) {
        if (cursor >= RANGES.HOUR.START && cursor <= RANGES.HOUR.END) {
          this.stateManager.transitionTo("focused.hours");
        }
      }

      if (this.showMinutes) {
        if (cursor >= RANGES.MINUTE.START && cursor <= RANGES.MINUTE.END) {
          this.stateManager.transitionTo("focused.minutes");
        }
      }

      if (this.showSeconds) {
        if (cursor >= RANGES.SECOND.START && cursor <= RANGES.SECOND.END) {
          this.stateManager.transitionTo("focused.seconds");
        }
      }
    },

    //#endregion
    // [--]:-- --
    selectHours() {
      const RANGES = this.RANGES;

      if (this.showHours) {
        this.element.setSelectionRange(RANGES.HOUR.START, RANGES.HOUR.END);
      }
    },

    // --:[--] --
    selectMinutes() {
      const RANGES = this.RANGES;
      this.element.setSelectionRange(RANGES.MINUTE.START, RANGES.MINUTE.END);
    },

    // --:-- [--]
    selectSeconds() {
      const RANGES = this.RANGES;

      if (this.showSeconds) {
        this.element.setSelectionRange(RANGES.SECOND.START, RANGES.SECOND.END);
      }
    },

    //#region Hours
    incrementHours() {
      let amnt = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
      const hours = this.hours;

      if (Ember.isNone(hours)) {
        this.set("hours", 0);
      } else {
        this.set("hours", (0, _mod.default)(hours + amnt, 24));
      }

      this.valueChanged();
    },

    decrementHours() {
      this.incrementHours(-1);
    },

    setHours(hours) {
      this.set("hours", hours);
      this.valueChanged();
    },

    setHoursDigit2(hours) {
      const current = this.hours;

      if (Ember.isNone(current)) {
        this.set("hours", hours);
      } else {
        this.set("hours", Math.min(current * 10 + hours, 24 - 1));
      }

      this.valueChanged();
    },

    //#endregion
    //#region Minutes
    incrementMinutes() {
      let amnt = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
      const minutes = this.minutes;

      if (Ember.isNone(minutes)) {
        this.set("minutes", 0);
      } else {
        this.set("minutes", (0, _mod.default)(minutes + amnt, 60));
      }

      this.valueChanged();
    },

    decrementMinutes() {
      this.incrementMinutes(-1);
    },

    setMinutes(minutes) {
      this.set("minutes", minutes);
      this.valueChanged();
    },

    setMinutesDigit2(minutes) {
      const current = this.minutes;

      if (Ember.isNone(current)) {
        this.set("minutes", minutes);
      } else {
        this.set("minutes", current * 10 + minutes);
      }

      this.valueChanged();
    },

    //#endregion
    //#region Seconds
    incrementSeconds() {
      let amnt = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
      const seconds = this.seconds;

      if (Ember.isNone(seconds)) {
        this.set("seconds", 0);
      } else {
        this.set("seconds", (0, _mod.default)(seconds + amnt, 60));
      }

      this.valueChanged();
    },

    decrementSeconds() {
      this.incrementSeconds(-1);
    },

    setSeconds(seconds) {
      this.set("seconds", seconds);
      this.valueChanged();
    },

    setSecondsDigit2(seconds) {
      const current = this.seconds;

      if (Ember.isNone(current)) {
        this.set("seconds", seconds);
      } else {
        this.set("seconds", current * 10 + seconds);
      }

      this.valueChanged();
    },

    //#endregion
    valueChanged() {
      this.updateDOMValue();
      this.stateManager.send("focusIn");
      let {
        hours,
        minutes,
        seconds
      } = this;
      this["on-change"]({
        hours,
        minutes,
        seconds
      });
    },

    updateDOMValue() {
      const value = this._value;
      this.element.value = value; // trigger standard events in-case anything else is listening

      (0, _jquery.default)().trigger("input");
      (0, _jquery.default)().trigger("change");
    }

  });

  _exports.default = _default;
});