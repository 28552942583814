define("alliance-business-suite/helpers/format-currency", ["exports", "alliance-business-suite/mixins/currency-format"], function (_exports, _currencyFormat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend(_currencyFormat.default, {
    compute(_ref, namedArgs) {
      let [value, ...rest] = _ref;
      return this.formatCurrencyString(value, namedArgs.useOverride, namedArgs.isDollars);
    }

  });

  _exports.default = _default;
});