define("alliance-business-suite/components/summary/reports/s-funds-deposit", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "alliance-business-suite/utils/currency-format-util", "alliance-business-suite/utils/report-tables-util"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _currencyFormatUtil, _reportTablesUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  let SummaryReportsSFundsDepositsComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, (_class = class SummaryReportsSFundsDepositsComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor3, this);
    }

    get orgAccountNumber() {
      const organizationId = this.access.accessOrganization.get('id');
      return this.store.peekRecord('organization', organizationId).get('accountNumber');
    }

    get translatedColumns() {
      let options = [{
        name: 'Name',
        valuePath: 'name',
        width: 275,
        isFixed: 'left'
      }, // { name: 'organization_account_number', valuePath: 'accountNumber' },
      {
        name: 'amount_deposited',
        valuePath: 'amountDeposited',
        width: 180
      }, {
        name: 'value_center_deposit',
        valuePath: 'valueStationDeposited',
        width: 180
      }, {
        name: 'PAYMENT_CENTER_DEPOSIT_CASH',
        valuePath: 'paymentCenterCashDeposited',
        width: 180
      }, {
        name: 'PAYMENT_CENTER_DEPOSIT_CREDIT_CARD',
        valuePath: 'paymentCenterCreditDeposited',
        width: 180
      }, {
        name: 'rewards_earned',
        valuePath: 'pointsRewardsCreditEarned',
        width: 180
      }, {
        name: 'promotions_earned',
        valuePath: 'depositRewardsEarned',
        width: 180
      }, {
        name: 'total_deposited',
        valuePath: 'totalDeposited',
        width: 180
      }]; // if has account number, then put it in index 2

      if (this.orgAccountNumber) {
        let a = options.slice(0, 1);
        let b = options.slice(1);
        a.push({
          name: 'organization_account_number',
          valuePath: 'orgAccountNumber'
        });
        options = [...a, ...b];
      }

      return options;
    }

    get rowsWithChildren() {
      let {
        model
      } = this.args;
      let geoBoundaries = model.geoBoundaries;
      let rowData = [];
      geoBoundaries.forEach(geoBoundary => {
        let monthArray = [];
        let totalCoinVendedSum = 0;
        let totalValueCenterDepositSum = 0;
        let totalPaymentCenterCashDepositSum = 0;
        let totalPaymentCenterCreditDepositSum = 0;
        let totalCardVendSum = 0;
        let totalRemoteVendSum = 0;
        let totalLuckyCycleSum = 0;
        let totalCoinVended = [];
        let totalValueCenterDeposit = [];
        let totalPaymentCenterCashDeposit = [];
        let totalPaymentCenterCreditDeposit = [];
        let totalCardVend = [];
        let totalRemoteVend = [];
        let totalLuckyCycles = [];
        rowData.push({
          name: _reportTablesUtil.localizeString.call(this, geoBoundary.name, 'Location', 'location'),
          children: monthArray,
          amountDeposited: totalCoinVended,
          valueStationDeposited: totalValueCenterDeposit,
          // paymentCenterDeposited: totalPaymentCenterDeposit,
          paymentCenterCashDeposited: totalPaymentCenterCashDeposit,
          paymentCenterCreditDeposited: totalPaymentCenterCreditDeposit,
          pointsRewardsCreditEarned: totalCardVend,
          depositRewardsEarned: totalRemoteVend,
          totalDeposited: totalLuckyCycles,
          orgAccountNumber: this.orgAccountNumber
        });
        geoBoundary.months.forEach(month => {
          let weekArray = [];
          let total1CoinVendedSum = 0;
          let total1ValueCenterDepositSum = 0;
          let total1PaymentCenterCashDepositSum = 0;
          let total1PaymentCenterCreditDepositSum = 0;
          let total1CardVendSum = 0;
          let total1RemoteVendSum = 0;
          let total1LuckyCycleSum = 0;
          let total1CoinVended = [];
          let total1ValueCenterDeposit = [];
          let total1PaymentCenterCashDeposit = [];
          let total1PaymentCenterCreditDeposit = [];
          let total1CardVend = [];
          let total1RemoteVend = [];
          let total1LuckyCycles = [];
          monthArray.push({
            name: month.name,
            children: weekArray,
            amountDeposited: total1CoinVended,
            valueStationDeposited: total1ValueCenterDeposit,
            // paymentCenterDeposited: total1PaymentCenterDeposit,
            paymentCenterCashDeposited: total1PaymentCenterCashDeposit,
            paymentCenterCreditDeposited: total1PaymentCenterCreditDeposit,
            pointsRewardsCreditEarned: total1CardVend,
            depositRewardsEarned: total1RemoteVend,
            totalDeposited: total1LuckyCycles,
            orgAccountNumber: this.orgAccountNumber
          });
          month.weeks.forEach(week => {
            let dayArray = [];
            let total2CoinVendedSum = 0;
            let total2ValueCenterDepositSum = 0;
            let total2PaymentCenterCashDepositSum = 0;
            let total2PaymentCenterCreditDepositSum = 0;
            let total2CardVendSum = 0;
            let total2RemoteVendSum = 0;
            let total2LuckyCycleSum = 0;
            let total2CoinVended = [];
            let total2ValueCenterDeposit = [];
            let total2PaymentCenterCashDeposit = [];
            let total2PaymentCenterCreditDeposit = [];
            let total2CardVend = [];
            let total2RemoteVend = [];
            let total2LuckyCycles = [];
            weekArray.push({
              name: week.name,
              children: dayArray,
              amountDeposited: total2CoinVended,
              // paymentCenterDeposited: total2PaymentCenterDeposit,
              paymentCenterCashDeposited: total2PaymentCenterCashDeposit,
              paymentCenterCreditDeposited: total2PaymentCenterCreditDeposit,
              valueStationDeposited: total2ValueCenterDeposit,
              pointsRewardsCreditEarned: total2CardVend,
              depositRewardsEarned: total2RemoteVend,
              totalDeposited: total2LuckyCycles,
              orgAccountNumber: this.orgAccountNumber
            });
            week.days.forEach(day => {
              let stationArray = [];
              let total3CoinVendedSum = 0;
              let total3ValueCenterDepositSum = 0;
              let total3PaymentCenterCashDepositSum = 0;
              let total3PaymentCenterCreditDepositSum = 0;
              let total3CardVendSum = 0;
              let total3RemoteVendSum = 0;
              let total3LuckyCycleSum = 0;
              let total3CoinVended = [];
              let total3ValueCenterDeposit = [];
              let total3PaymentCenterCashDeposit = [];
              let total3PaymentCenterCreditDeposit = [];
              let total3CardVend = [];
              let total3RemoteVend = [];
              let total3LuckyCycles = [];
              dayArray.push({
                name: day.name,
                children: stationArray,
                amountDeposited: total3CoinVended,
                valueStationDeposited: total3ValueCenterDeposit,
                // paymentCenterDeposited: total3PaymentCenterDeposit,
                paymentCenterCashDeposited: total3PaymentCenterCashDeposit,
                paymentCenterCreditDeposited: total3PaymentCenterCreditDeposit,
                pointsRewardsCreditEarned: total3CardVend,
                depositRewardsEarned: total3RemoteVend,
                totalDeposited: total3LuckyCycles,
                orgAccountNumber: this.orgAccountNumber
              });
              day.deposits.forEach(deposit => {
                let machineCycleArray = [];
                let machineVendArray = [];
                let paymentCenterCashArray = [];
                let paymentCenterCreditArray = [];
                let machineMediumArray = [];
                let machineBreakInArray = [];
                let machineVendDArray = [];

                if (deposit.amountDeposited !== 0) {
                  machineCycleArray.push(deposit.amountDeposited);
                  totalCoinVendedSum = (0, _reportTablesUtil.sumArray)(machineCycleArray, totalCoinVendedSum);
                  total1CoinVendedSum = (0, _reportTablesUtil.sumArray)(machineCycleArray, total1CoinVendedSum);
                  total2CoinVendedSum = (0, _reportTablesUtil.sumArray)(machineCycleArray, total2CoinVendedSum);
                  total3CoinVendedSum = (0, _reportTablesUtil.sumArray)(machineCycleArray, total3CoinVendedSum);
                }

                if (deposit.valueStationDeposited !== 0) {
                  machineVendArray.push(deposit.valueStationDeposited);
                  totalValueCenterDepositSum = (0, _reportTablesUtil.sumArray)(machineVendArray, totalValueCenterDepositSum);
                  total1ValueCenterDepositSum = (0, _reportTablesUtil.sumArray)(machineVendArray, total1ValueCenterDepositSum);
                  total2ValueCenterDepositSum = (0, _reportTablesUtil.sumArray)(machineVendArray, total2ValueCenterDepositSum);
                  total3ValueCenterDepositSum = (0, _reportTablesUtil.sumArray)(machineVendArray, total3ValueCenterDepositSum);
                } // if (deposit.paymentCenterDeposited !== 0) {


                if (deposit.paymentCenterCashDeposited !== 0) {
                  paymentCenterCashArray.push(deposit.paymentCenterCashDeposited);
                  totalPaymentCenterCashDepositSum = (0, _reportTablesUtil.sumArray)(paymentCenterCashArray, totalPaymentCenterCashDepositSum);
                  total1PaymentCenterCashDepositSum = (0, _reportTablesUtil.sumArray)(paymentCenterCashArray, total1PaymentCenterCashDepositSum);
                  total2PaymentCenterCashDepositSum = (0, _reportTablesUtil.sumArray)(paymentCenterCashArray, total2PaymentCenterCashDepositSum);
                  total3PaymentCenterCashDepositSum = (0, _reportTablesUtil.sumArray)(paymentCenterCashArray, total3PaymentCenterCashDepositSum);
                }

                if (deposit.paymentCenterCreditDeposited !== 0) {
                  paymentCenterCreditArray.push(deposit.paymentCenterCreditDeposited);
                  totalPaymentCenterCreditDepositSum = (0, _reportTablesUtil.sumArray)(paymentCenterCreditArray, totalPaymentCenterCreditDepositSum);
                  total1PaymentCenterCreditDepositSum = (0, _reportTablesUtil.sumArray)(paymentCenterCreditArray, total1PaymentCenterCreditDepositSum);
                  total2PaymentCenterCreditDepositSum = (0, _reportTablesUtil.sumArray)(paymentCenterCreditArray, total2PaymentCenterCreditDepositSum);
                  total3PaymentCenterCreditDepositSum = (0, _reportTablesUtil.sumArray)(paymentCenterCreditArray, total3PaymentCenterCreditDepositSum);
                }

                if (deposit.pointsRewardsCreditEarned !== 0) {
                  machineMediumArray.push(deposit.pointsRewardsCreditEarned);
                  totalCardVendSum = (0, _reportTablesUtil.sumArray)(machineMediumArray, totalCardVendSum);
                  total1CardVendSum = (0, _reportTablesUtil.sumArray)(machineMediumArray, total1CardVendSum);
                  total2CardVendSum = (0, _reportTablesUtil.sumArray)(machineMediumArray, total2CardVendSum);
                  total3CardVendSum = (0, _reportTablesUtil.sumArray)(machineMediumArray, total3CardVendSum);
                }

                if (deposit.depositRewardsEarned !== 0) {
                  machineBreakInArray.push(deposit.depositRewardsEarned);
                  totalRemoteVendSum = (0, _reportTablesUtil.sumArray)(machineBreakInArray, totalRemoteVendSum);
                  total1RemoteVendSum = (0, _reportTablesUtil.sumArray)(machineBreakInArray, total1RemoteVendSum);
                  total2RemoteVendSum = (0, _reportTablesUtil.sumArray)(machineBreakInArray, total2RemoteVendSum);
                  total3RemoteVendSum = (0, _reportTablesUtil.sumArray)(machineBreakInArray, total3RemoteVendSum);
                }

                if (deposit.totalDeposited !== 0) {
                  machineVendDArray.push(deposit.totalDeposited);
                  totalLuckyCycleSum = (0, _reportTablesUtil.sumArray)(machineVendDArray, totalLuckyCycleSum);
                  total1LuckyCycleSum = (0, _reportTablesUtil.sumArray)(machineVendDArray, total1LuckyCycleSum);
                  total2LuckyCycleSum = (0, _reportTablesUtil.sumArray)(machineVendDArray, total2LuckyCycleSum);
                  total3LuckyCycleSum = (0, _reportTablesUtil.sumArray)(machineVendDArray, total3LuckyCycleSum);
                }

                let amountDeposited = _currencyFormatUtil.formatCurrencyString.call(this, deposit.amountDeposited || 0);

                let valueStationDeposited = _currencyFormatUtil.formatCurrencyString.call(this, deposit.valueStationDeposited || 0); // let paymentCenterDeposited = formatCurrencyString.call(this, deposit.paymentCenterDeposited || 0);


                let paymentCenterCashDeposited = _currencyFormatUtil.formatCurrencyString.call(this, deposit.paymentCenterCashDeposited || 0);

                let paymentCenterCreditDeposited = _currencyFormatUtil.formatCurrencyString.call(this, deposit.paymentCenterCreditDeposited || 0);

                let pointsRewardsCreditEarned = _currencyFormatUtil.formatCurrencyString.call(this, deposit.pointsRewardsCreditEarned || 0);

                let depositRewardsEarned = _currencyFormatUtil.formatCurrencyString.call(this, deposit.depositRewardsEarned || 0);

                let totalDeposited = _currencyFormatUtil.formatCurrencyString.call(this, deposit.totalDeposited || 0);

                stationArray.push({
                  name: deposit.userName,
                  amountDeposited: amountDeposited,
                  valueStationDeposited: valueStationDeposited,
                  // paymentCenterDeposited: paymentCenterDeposited,
                  paymentCenterCashDeposited: paymentCenterCashDeposited,
                  paymentCenterCreditDeposited: paymentCenterCreditDeposited,
                  pointsRewardsCreditEarned: pointsRewardsCreditEarned,
                  depositRewardsEarned: depositRewardsEarned,
                  totalDeposited: totalDeposited,
                  orgAccountNumber: this.orgAccountNumber
                });
              });
              total3CoinVended.push(_currencyFormatUtil.formatCurrencyString.call(this, total3CoinVendedSum || 0));
              total3ValueCenterDeposit.push(_currencyFormatUtil.formatCurrencyString.call(this, total3ValueCenterDepositSum || 0));
              total3PaymentCenterCashDeposit.push(_currencyFormatUtil.formatCurrencyString.call(this, total3PaymentCenterCashDepositSum || 0));
              total3PaymentCenterCreditDeposit.push(_currencyFormatUtil.formatCurrencyString.call(this, total3PaymentCenterCreditDepositSum || 0));
              total3CardVend.push(_currencyFormatUtil.formatCurrencyString.call(this, total3CardVendSum || 0));
              total3RemoteVend.push(_currencyFormatUtil.formatCurrencyString.call(this, total3RemoteVendSum || 0));
              total3LuckyCycles.push(_currencyFormatUtil.formatCurrencyString.call(this, total3LuckyCycleSum || 0));
            });
            total2CoinVended.push(_currencyFormatUtil.formatCurrencyString.call(this, total2CoinVendedSum || 0));
            total2ValueCenterDeposit.push(_currencyFormatUtil.formatCurrencyString.call(this, total2ValueCenterDepositSum || 0));
            total2PaymentCenterCashDeposit.push(_currencyFormatUtil.formatCurrencyString.call(this, total2PaymentCenterCashDepositSum || 0));
            total2PaymentCenterCreditDeposit.push(_currencyFormatUtil.formatCurrencyString.call(this, total2PaymentCenterCreditDepositSum || 0));
            total2CardVend.push(_currencyFormatUtil.formatCurrencyString.call(this, total2CardVendSum || 0));
            total2RemoteVend.push(_currencyFormatUtil.formatCurrencyString.call(this, total2RemoteVendSum || 0));
            total2LuckyCycles.push(_currencyFormatUtil.formatCurrencyString.call(this, total2LuckyCycleSum || 0));
          });
          total1CoinVended.push(_currencyFormatUtil.formatCurrencyString.call(this, total1CoinVendedSum || 0));
          total1ValueCenterDeposit.push(_currencyFormatUtil.formatCurrencyString.call(this, total1ValueCenterDepositSum || 0));
          total1PaymentCenterCashDeposit.push(_currencyFormatUtil.formatCurrencyString.call(this, total1PaymentCenterCashDepositSum || 0));
          total1PaymentCenterCreditDeposit.push(_currencyFormatUtil.formatCurrencyString.call(this, total1PaymentCenterCreditDepositSum || 0));
          total1CardVend.push(_currencyFormatUtil.formatCurrencyString.call(this, total1CardVendSum || 0));
          total1RemoteVend.push(_currencyFormatUtil.formatCurrencyString.call(this, total1RemoteVendSum || 0));
          total1LuckyCycles.push(_currencyFormatUtil.formatCurrencyString.call(this, total1LuckyCycleSum || 0));
        });
        totalCoinVended.push(_currencyFormatUtil.formatCurrencyString.call(this, totalCoinVendedSum || 0));
        totalValueCenterDeposit.push(_currencyFormatUtil.formatCurrencyString.call(this, totalValueCenterDepositSum || 0));
        totalPaymentCenterCashDeposit.push(_currencyFormatUtil.formatCurrencyString.call(this, totalPaymentCenterCashDepositSum || 0));
        totalPaymentCenterCreditDeposit.push(_currencyFormatUtil.formatCurrencyString.call(this, totalPaymentCenterCreditDepositSum || 0));
        totalCardVend.push(_currencyFormatUtil.formatCurrencyString.call(this, totalCardVendSum || 0));
        totalRemoteVend.push(_currencyFormatUtil.formatCurrencyString.call(this, totalRemoteVendSum || 0));
        totalLuckyCycles.push(_currencyFormatUtil.formatCurrencyString.call(this, totalLuckyCycleSum || 0));
      });
      return rowData;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SummaryReportsSFundsDepositsComponent;
});