define("alliance-business-suite/templates/components/form-ui/dev-tools", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "chrBCDPB",
    "block": "{\"symbols\":[\"value\",\"key\"],\"statements\":[[6,[37,2],[[35,9]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"ul\"],[14,0,\"meta\"],[12],[2,\"\\n\"],[6,[37,2],[[35,6,[\"propertyName\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[[35,6,[\"labelAutoTranslated\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"li\"],[12],[10,\"i\"],[14,0,\"fa fa-tags on\"],[14,\"title\",\"Label added from translation\"],[12],[13],[2,\" \"],[1,[35,6,[\"propertyName\"]]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[10,\"li\"],[12],[10,\"i\"],[14,0,\"fa fa-tags off\"],[12],[13],[2,\" \"],[1,[35,6,[\"propertyName\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,8],[[30,[36,7],[[35,6]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[[30,[36,1],[[30,[36,4],[[32,2],[35,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[[30,[36,1],[[30,[36,0],[[32,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"li\"],[12],[1,[32,2]],[2,\": \"],[10,\"strong\"],[12],[1,[32,1]],[13],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,2],[[30,[36,5],[[32,2],\"componentName\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"li\"],[12],[10,\"strong\"],[12],[1,[32,1]],[13],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1,2]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"is-empty\",\"not\",\"if\",\"ignoreKeys\",\"contains\",\"eq\",\"outputs\",\"-each-in\",\"each\",\"showTools\"]}",
    "moduleName": "alliance-business-suite/templates/components/form-ui/dev-tools.hbs"
  });

  _exports.default = _default;
});