define("alliance-business-suite/routes/employees/roles", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "alliance-business-suite/routes/_secureRoute"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _secureRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3;

  let EmployeesRolesRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._action, (_class = class EmployeesRolesRoute extends _secureRoute.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "brush", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor3, this);
      (0, _defineProperty2.default)(this, "allowAccess", 'EMPLOYEES_SECURITY_ROLES_VIEW_PAGE');
      (0, _defineProperty2.default)(this, "disableBrush", true);
    }

    model() {
      return Ember.RSVP.hash({
        groups: this.store.findAll('securityFunctionalAreaGroup', {
          reload: true
        }),
        roles: this.store.findAll('securityRole', {
          reload: true
        })
      });
    }

    willTransition(transition) {
      let selectedRole = this.controller.get('selectedRole');

      if (selectedRole.get('isDirty')) {
        if (!confirm(this.intl.t('unsaved_changes_discard'))) {
          transition.abort();
        } else {
          selectedRole.rollback();
        }
      }

      return true; // Bubble the event
      // TODO: Selected navigation tab changes after aborting the transition.
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "brush", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "willTransition", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "willTransition"), _class.prototype)), _class));
  _exports.default = EmployeesRolesRoute;
});