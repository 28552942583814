define("alliance-business-suite/components/form-ui/duration-field/states/hours-focused", ["exports", "alliance-business-suite/components/form-ui/duration-field/utils/state", "alliance-business-suite/components/form-ui/duration-field/utils/codes"], function (_exports, _state, _codes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _state.default.extend({
    initialState: "digit1",
    digit1: (0, _state.state)(_state.default, {
      key(manager, code) {
        if (!(0, _codes.isNumberCode)(code)) {
          return; // no-op
        }

        const num = (0, _codes.keyCodeToNumber)(code);
        manager.get("input").setHours(num);

        if (num <= 2) {
          manager.transitionTo("digit2");
        } else {
          manager.transitionTo("minutes");
        }
      }

    }),
    digit2: (0, _state.state)(_state.default, {
      key(manager, code) {
        if (!(0, _codes.isNumberCode)(code)) {
          return; // no-op
        }

        const num = (0, _codes.keyCodeToNumber)(code);
        manager.get("input").setHoursDigit2(num);
        manager.transitionTo("minutes");
      }

    }),

    enter(manager) {
      this.focusIn(manager);
    },

    focusIn(manager) {
      manager.get("input").selectHours();
    },

    right(manager) {
      manager.transitionTo("minutes");
    },

    up(manager) {
      manager.get("input").incrementHours();
    },

    down(manager) {
      manager.get("input").decrementHours();
    },

    key(manager, code) {
      if (!(0, _codes.isNumberCode)(code)) {
        return; // no-op
      }

      const num = (0, _codes.keyCodeToNumber)(code);
      manager.get("input").setHours(num);

      if (num <= 2) {
        manager.transitionTo("digit2");
      } else {
        manager.transitionTo("minutes");
      }
    }

  });

  _exports.default = _default;
});