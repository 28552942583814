define("alliance-business-suite/components/form-ui/special-vend", ["exports", "alliance-business-suite/utils/number-to-hour", "alliance-business-suite/mixins/init-property-names", "alliance-business-suite/mixins/modals"], function (_exports, _numberToHour, _initPropertyNames, _modals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function formatTime(minutes, seconds) {
    return `${minutes}:${seconds.toString().padStart(2, 0)}`;
  }

  var _default = Ember.Component.extend(_initPropertyNames.default, _modals.default, {
    intl: Ember.inject.service(),
    summaryText: Ember.computed('Enabled', 'Saturday', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'StartMonth', 'StartDate', 'StartYear', 'EndMonth', 'EndDate', 'EndYear', 'StartHour', 'StartMinute', 'Duration', 'HeatMinutes', 'HeatSeconds', 'NoHeatMinutes', 'NoHeatSeconds', 'Coin1TopOffMinutes', 'Coin1TopOffSeconds', 'Coin2TopOffMinutes', 'Coin2TopOffSeconds', 'HeatPrice', 'NoHeatPrice', function () {
      let output = '';
      let intl = this.intl;

      if (!this.Enabled || this.Enabled === false) {
        return 'Off';
      } // What is the type


      if (this.LowPowerEnabled && this.AutoShutdownEnabled) {
        output += 'Low Power & Auto Shutdown - ';
      } else if (this.LowPowerEnabled) {
        output += 'Low Power - ';
      } else if (this.AutoShutdownEnabled) {
        output += 'Auto Shutdown - ';
      } else {
        output += 'On - ';
      } // When: Days (dates) (hours)
      // Every Day; Weekends; Weekdays; Mon-Wed, Fri


      let daysText = "";

      if (this.Saturday && this.Sunday && this.Monday && this.Tuesday && this.Wednesday && this.Thursday && this.Friday) {
        daysText = 'Every Day, ';
      } else {
        // Weekends
        if (this.Saturday && this.Sunday) {
          daysText += ' Weekends, ';
        } else if (this.Saturday) {
          daysText += 'Sat, ';
        } else if (this.Sunday) {
          daysText += 'Sun, ';
        } // Weekdays


        if (this.Monday && this.Tuesday && this.Wednesday && this.Thursday && this.Friday) {
          daysText += 'Weekdays, ';
        } else {
          if (this.Monday) {
            daysText += 'Mon, ';
          }

          if (this.Tuesday) {
            daysText += 'Tue, ';
          }

          if (this.Wednesday) {
            daysText += 'Wed, ';
          }

          if (this.Thursday) {
            daysText += 'Thu, ';
          }

          if (this.Friday) {
            daysText += 'Fri, ';
          }
        }

        if (daysText) {
          output += daysText;
        } else {
          output += `${intl.t("no_days_selected")}, `;
        } // Dates - this has some logic. If the day, month, or year has a value of 0, date limit is not enabled. If start = 1/1/2000 and end = 12/31/2099, also has no limit.


        if (this.StartMonth == 0 || this.StartDate == 0 || this.EndMonth == 0 || this.EndDate == 0 || this.StartMonth == 1 && this.StartDate == 1 && this.StartYear == 0 && this.EndMonth == 12 && this.EndDate == 31 && this.EndYear == 99) {// No date limits
        } else {
          output += `(${this.StartMonth + 1}/${this.StartDate}/${this.StartYear}&ndash;${this.EndMonth + 1}/${this.EndDate}/${this.EndYear})  `;
        } // Hours - this seems to have some logic. If duration is 0, not enabled.


        if (this.Duration > 0) {
          // We'll need to figure out the end time
          let startHour = (0, _numberToHour.default)(this.StartHour);
          let endHour = (0, _numberToHour.default)(Number(this.StartHour) + Number(this.Duration));
          output += `(${startHour}&ndash;${endHour}), `;
        }
      } // Show Actions
      // Change times


      let heatTime = formatTime(this.HeatMinutes, this.HeatSeconds);
      let noHeatTime = formatTime(this.NoHeatMinutes, this.NoHeatSeconds);
      let coin1Time = formatTime(this.Coin1TopOffMinutes, this.Coin1TopOffSeconds);
      let coin2Time = formatTime(this.Coin2TopOffMinutes, this.Coin2TopOffSeconds);
      output += `${intl.t('change_times')} (`;
      output += `${intl.t("Heat")}: ${heatTime}, `;
      output += `${intl.t("NoHeat")}: ${noHeatTime}, `;
      output += `${intl.t("Coin1TopOffTime")}: ${coin1Time}, `;
      output += `${intl.t("Coin2TopOffTime")}: ${coin2Time}`;
      output += `), `; // Change prices

      let heatPrice = this.HeatPrice;
      let noHeatPrice = this.NoHeatPrice;
      output += `${intl.t('change_prices')} (`;
      output += `${intl.t("Heat")}: ${heatPrice}, `;
      output += `${intl.t("NoHeat")}: ${noHeatPrice}`;
      output += `), `;
      return output.length > 2 ? Ember.String.htmlSafe(output.substr(0, output.length - 2)) : output;
    }),
    defaultModifier: Ember.computed('DefaultReversing', 'DefaultAntiWrinkle', function () {
      let reversing = this.DefaultReversing;
      let antiWrinkle = this.DefaultAntiWrinkle;
      if (!reversing && !antiWrinkle) return 1;
      if (!reversing && antiWrinkle) return 2;
      if (reversing && !antiWrinkle) return 3;
      if (reversing && antiWrinkle) return 4;
    }),
    actions: {
      updateValues(obj, prop, value) {
        let object = this.object;
        Ember.set(this, 'defaultModifier', value);

        switch (value) {
          case 1:
            // None
            // Do nothing
            this.update(object, Ember.get(this, 'properties.DefaultReversing.key'), false);
            this.update(object, Ember.get(this, 'properties.DefaultAntiWrinkle.key'), false);
            break;

          case 2:
            // Anti-Wrinkle
            this.update(object, Ember.get(this, 'properties.DefaultReversing.key'), false);
            this.update(object, Ember.get(this, 'properties.DefaultAntiWrinkle.key'), true);
            this.update(object, Ember.get(this, 'properties.AntiWrinkle.key'), true);
            break;

          case 3:
            // Reversing
            this.update(object, Ember.get(this, 'properties.DefaultReversing.key'), true);
            this.update(object, Ember.get(this, 'properties.DefaultAntiWrinkle.key'), false);
            this.update(object, Ember.get(this, 'properties.Reversing.key'), true);
            break;

          case 4:
            // Both
            this.update(object, Ember.get(this, 'properties.DefaultReversing.key'), true);
            this.update(object, Ember.get(this, 'properties.DefaultAntiWrinkle.key'), true);
            this.update(object, Ember.get(this, 'properties.AntiWrinkle.key'), true);
            this.update(object, Ember.get(this, 'properties.Reversing.key'), true);
            break;
        }
      }

    }
  });

  _exports.default = _default;
});