define("alliance-business-suite/templates/components/form-ui/limit-dates", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DboK91ni",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"checkbox-field form-field overflow-float\"],[12],[2,\"\\n  \"],[10,\"label\"],[14,0,\"form-field--label inline-checkbox form-ui-element\"],[12],[2,\"\\n    \"],[8,\"input\",[[24,0,\"form-field--control\"]],[[\"@type\",\"@checked\"],[\"checkbox\",[32,0,[\"limitEnabled\"]]]],null],[2,\"\\n    \"],[1,[30,[36,0],[\"limit_dates\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,3],[[32,0,[\"hasFullDates\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[[32,0,[\"limitEnabled\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"form-ui/date\",[],[[\"@object\",\"@update\",\"@formSettings\",\"@params\"],[[32,0,[\"object\"]],[32,0,[\"update\"]],[32,0,[\"formSettings\"]],[30,[36,1],null,[[\"label\",\"yearPropertyName\",\"monthPropertyName\",\"datePropertyName\"],[[30,[36,0],[\"start_date\"],null],[32,0,[\"properties\",\"startYear\",\"key\"]],[32,0,[\"properties\",\"startMonth\",\"key\"]],[32,0,[\"properties\",\"startDate\",\"key\"]]]]]]],null],[2,\"\\n    \"],[8,\"form-ui/date\",[],[[\"@object\",\"@update\",\"@formSettings\",\"@params\"],[[32,0,[\"object\"]],[32,0,[\"update\"]],[32,0,[\"formSettings\"]],[30,[36,1],null,[[\"label\",\"yearPropertyName\",\"monthPropertyName\",\"datePropertyName\"],[[30,[36,0],[\"end_date\"],null],[32,0,[\"properties\",\"endYear\",\"key\"]],[32,0,[\"properties\",\"endMonth\",\"key\"]],[32,0,[\"properties\",\"endDate\",\"key\"]]]]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"hash\",\"liquid-if\",\"if\"]}",
    "moduleName": "alliance-business-suite/templates/components/form-ui/limit-dates.hbs"
  });

  _exports.default = _default;
});