define("alliance-business-suite/helpers/charts/opl-productivity-shifts-transform", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function chartsOplProductivityShiftsTransform(params
  /*, hash*/
  ) {
    let data = params[0];
    return [{
      name: 'Rewash',
      data: [parseInt(data.rewash)],
      color: '#1eba54'
    }, {
      name: 'Total',
      data: [data.total] // }, {
      // name: 'other',
      // data: [data.other]

    }];
  });

  _exports.default = _default;
});