define("alliance-business-suite/models/guest", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;

  var _default = Model.extend({
    firstName: attr('string'),
    lastName: attr('string'),
    emailAddress: attr('string'),
    telecomInternationalCode: attr('string'),
    telecomAreaCode: attr('string'),
    telecomContactNumber: attr('string'),
    conflictGuestId: attr('string'),
    nonUpdateReason: attr('string'),
    fullName: Ember.computed('firstName', 'lastName', {
      get() {
        return `${Ember.get(this, 'firstName')} ${Ember.get(this, 'lastName')}`;
      }

    }),
    brushLabel: Ember.computed.alias('fullName').readOnly()
  });

  _exports.default = _default;
});