define("alliance-business-suite/components/summary-bar-percentage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'ol',
    classNames: 'measure-bar',
    percentage: Ember.computed('values', function () {
      let v = this.values;

      if (v) {
        return {
          selfService: v.selfService / v.total * 100,
          wdf: v.wdf / v.total * 100,
          goods: v.goods / v.total * 100
        };
      }
    })
  });

  _exports.default = _default;
});