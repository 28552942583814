define("alliance-business-suite/router", ["exports", "@babel/runtime/helpers/esm/defineProperty", "alliance-business-suite/config/environment"], function (_exports, _defineProperty2, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Router extends Ember.Router {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "location", _environment.default.locationType);
      (0, _defineProperty2.default)(this, "rootURL", _environment.default.rootURL);
    }

  }

  _exports.default = Router;
  Router.map(function () {
    this.route("login", function () {
      this.route("forgot-password");
    });
    this.route("forgot-password");
    this.route("start");
    this.route("not-allowed");
    this.route("route-not-found", {
      path: "/*path"
    }); // this.route('not-found', { path: '/*path' });

    this.route("not-found");
    this.route("welcome");
    this.route("logout");
    this.route("payment-form");
    this.route("summary", function () {
      this.route("charts");
      this.route("reports");
      this.route("subscriptions", function () {
        this.route("create");
        this.route("manage", {
          path: "/:subscription_id/manage"
        });
      });
      this.route("alerts", function () {
        this.route("create");
        this.route("manage", {
          path: "/:alert_id/manage"
        });
      });
    });
    this.route("employees", function () {
      this.route("list", {
        path: "/"
      }); // Added for security...was index

      this.route("schedules");
      this.route("create");
      this.route("manage", {
        path: "/:employee_id/manage"
      });
      this.route("roles");
      this.route("time-clock");
      this.route("sales-goals");
    });
    this.route("locations", function () {
      this.route("list", {
        path: "/"
      }); // Added for security...was index

      this.route("create");
      this.route("manage", {
        path: "/:location_id/manage"
      });
      this.route("map");
      this.route("rewards", function () {
        this.route("create");
        this.route("manage", {
          path: "/:rewards_program_id/manage"
        });
      });
      this.route("rooms", {
        path: "/:room_id"
      }, function () {
        this.route("manage");
      });
      this.route("wash-alert", function () {
        this.route("ads", {
          path: "/:room_id/ads"
        });
        this.route("manage", {
          path: "/:room_id"
        });
      });
      this.route("configure");
    });
    this.route("machines", function () {
      this.route("list", {
        path: "/"
      }); // Added for security...was index

      this.route("manage", {
        path: "/:machine_id/manage"
      });
      this.route("programs", function () {
        // this.route('create');
        this.route("manage", {
          path: "/:machine_program_id/manage"
        });
        this.route("send", {
          path: "/:machine_program_id/send"
        });
        this.route("test");
        this.route("export", {
          path: "/:machine_program_id/export"
        });
      });
      this.route("traceability");
    });
    this.route("customers", function () {
      this.route("list", {
        path: "/"
      }); // Added for security...was index

      this.route("orders");
      this.route("memberships");
    });
    this.route("onboarding");
    this.route("profile", function () {
      this.route("billing");
    });
    this.route("reset-password");
    this.route("crm", function () {
      this.route("list", {
        path: "/"
      }); // Added for security...was index

      this.route("analytics");
      this.route("campaigns", function () {
        this.route("create");
        this.route("manage", {
          path: "/:campaign_id"
        });
        this.route("edit", {
          path: "/edit/:campaign_id"
        });
      });
    });
    this.route("retail", function () {
      this.route("list", {
        path: "/"
      }, function () {
        this.route("sort");
      }); // Added for security...was index

      this.route("manage", {
        path: "/:item_id"
      });
      this.route("create");
      this.route("categories");
      this.route("inventory");
      this.route("services");
      this.route("services-list", function () {
        this.route("sort");
      });
      this.route("advanced");
      this.route("taxes");
    });
    this.route("organizations");
    this.route("renew");
    this.route("marketing", function () {
      this.route("list");
    });
    this.route("notifications");
    this.route("support", function () {
      this.route("index", {
        path: "/"
      });
    });
  });
});