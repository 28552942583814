define("alliance-business-suite/routes/machines/programs", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "alliance-business-suite/routes/_secureRoute"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _secureRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  let MachinesProgramsRoute = (_dec = Ember.inject.service, (_class = class MachinesProgramsRoute extends _secureRoute.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _defineProperty2.default)(this, "allowAccess", 'MACHINES_PROGRAMS_VIEW');
      (0, _defineProperty2.default)(this, "disableBrush", true);
    }

    model() {
      // Pre-loading the `organization` is needed for the program list, but we do it here
      // because Send Program might need it also (not sure).
      return this.store.findRecord('organization', this.access.accessOrganization.id, {
        include: 'machine-programs',
        reload: true
      });
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = MachinesProgramsRoute;
});