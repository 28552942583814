define("alliance-business-suite/adapters/shift-block", ["exports", "alliance-business-suite/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    access: Ember.inject.service(),

    buildURL(modelName, id, snapshot, requestType, query) {
      let host = this.host;
      let url;

      switch (requestType) {
        case 'findAll':
        case 'createRecord':
          url = `${host}/rooms/${snapshot.adapterOptions.roomId}/shiftBlocks`;
          break;

        case 'deleteRecord':
        case 'updateRecord':
          url = `${host}/shiftBlocks/${id}`;
          break;
      }

      return url;
    }

  });

  _exports.default = _default;
});