define("alliance-business-suite/components/brush-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A link which changes the brush rather than the route.
   *
   * # Positional Parameters
   *
   * target
   * : The scrub to switch to.
   *
   * data
   * : The data for the brush, or an object indicating how to load the data.
  
   * # Named Parameters
   *
   * tagName
   * : The tag to use for this component. Defaults to `a`, but any tag can be used
   *   to create clickable UI elements that change the brush.
   *
   * @public
   */
  const BrushLink = Ember.Component.extend({
    /**
     * The service used to manage the scrub brush.
     *
     * @protected
     */
    brush: Ember.inject.service(),
    store: Ember.inject.service(),

    /**
     * The static class names for this component.
     *
     * @type {Array<String>}
     * @public
     */
    classNames: ['brush-link'],
    classNameBindings: ['isRowActive:active'],

    /**
     * An array of properties which should be bound to the HTML element rendered
     * by this component.
     *
     * @type {Array<string>}
     * @protected
     */
    attributeBindings: ['href'],

    /**
     * The tag name used for this component; an empty string prevents a DOM
     * element from being created, instead rendering the template for this
     * component directly underneath its parent.
     *
     * @protected
     */
    tagName: 'a',

    /**
     * The `href` attribute for this component; only used when the `a` tag is
     * being used. This ensures that the element is handled as a link and not an
     * anchor by the browser (e.g. changing to the hand cursor when the mouse is
     * over it).
     *
     * @protected
     */
    href: Ember.computed('tagName', {
      get() {
        return Ember.get(this, 'tagName') === 'a' ? '#' : undefined;
      }

    }),

    /**
     * Handles the click on the link to transition the brush.
     *
     * @public
     */
    click() {
      let {
        target,
        lazyLoad,
        store,
        brush,
        data
      } = this;
      let modelName = Ember.get(this, 'data.constructor.modelName');

      if (target == modelName && !lazyLoad) {
        brush.transitionTo(target, data);
      } else {
        /*
          Ty and Curtis hacked this to make the customer brush work.
          The customer list was return errors when first clicking on customers.
          To fix this, we targeted only the 'customer' target, without changing any
          logic for other targets.
        */
        if (target === 'customer') {
          let id = Ember.get(this, 'data.id');
          brush.transitionTo(target, {
            id,
            type: target
          });
        } else {
          brush.transitionTo(target, store.find(target, Ember.get(this, 'data.id')));
        }
      }
    }

  });
  BrushLink.reopenClass({
    positionalParams: ['target', 'data']
  });
  var _default = BrushLink;
  _exports.default = _default;
});