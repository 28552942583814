define("alliance-business-suite/components/machines/programs/water-usage/acas-wx-flw", ["exports", "alliance-business-suite/mixins/machines/programs/cycles/water-usage", "alliance-business-suite/themes/default-theme", "alliance-business-suite/mixins/machines/programs/acas-options"], function (_exports, _waterUsage, _defaultTheme, _acasOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const toL = gallons => {
    let value = new Big(gallons).times(3.78541).toFixed(2);
    return Number(value);
  };

  var _default = Ember.Component.extend(_acasOptions.default, _waterUsage.default, {
    intl: Ember.inject.service(),
    machineAuditType: Ember.computed.alias('machineProgram.machineAuditType.name'),
    convertToLiters: false,

    // #region Settings and Configs
    i18n(key) {
      if (typeof key === "string") {
        return this.intl.t(key).toString();
      } else {
        // array
        return key.map(item => {
          return this.intl.t(item).toString();
        });
      }
    },

    temperatureNames: Ember.computed('machineProgram._machineAuditTypeFamily', function () {
      if (this.machineProgram._machineAuditTypeFamily === "ACAS_A3") {
        return this.i18n(['normal_90', 'normal_60', 'normal_40', 'perm_press_60', 'delicate_30', 'delicate_no_heat']);
      } else {
        return this.i18n(['hot', 'normal_warm', 'normal_cold', 'perm_press_hot', 'perm_press_warm', 'perm_press_cold', 'delicate_hot', 'delicate_warm', 'delicate_cold']);
      }
    }),
    // #region Charts
    // Return chart options for each of the 4 charts, with a unique title for each chart.
    lightChartOptions: Ember.computed('convertToLiters', function () {
      return this.chartOptions(this.intl.t('light_load').toString());
    }),
    mediumChartOptions: Ember.computed('convertToLiters', function () {
      return this.chartOptions(this.intl.t('medium_load').toString());
    }),
    heavyChartOptions: Ember.computed('convertToLiters', function () {
      return this.chartOptions(this.intl.t('heavy_load').toString());
    }),

    // Chart options only vary in title text. All other options are the same.
    chartOptions(titleText) {
      return {
        chart: {
          type: 'column',
          spacingTop: 50
        },
        title: {
          text: titleText
        },
        xAxis: {
          categories: this.temperatureNames
        },
        yAxis: {
          min: 0,
          title: {
            text: this.intl.t('water_used').toString()
          },
          stackLabels: {
            enabled: true,
            verticalAlign: 'top',
            allowOverlap: true,
            // required to prevent some total values from disappering on re-render
            style: {
              fontWeight: 'bold'
            }
          }
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            dataLabels: {
              enabled: true
            }
          }
        },
        colors: ['#efa4a4', '#cae3f8']
      };
    },

    // Data is array of 12 values for each cycle (Normal, Perm Press, Delicate) and it's temperature (hot, warm, cold).
    chartDataLight: Ember.computed('convertToLiters', function () {
      return this.getChartDataObject('light');
    }),
    chartDataMedium: Ember.computed('convertToLiters', function () {
      return this.getChartDataObject('medium');
    }),
    chartDataHeavy: Ember.computed('convertToLiters', function () {
      return this.getChartDataObject('heavy');
    }),

    getChartDataObject(loadSize) {
      // All charts get arrays of Hot and Cold data.
      return [{
        name: this.intl.t('hot').toString(),
        data: this.getChartDataArray(loadSize, 'hot')
      }, {
        name: this.intl.t('cold').toString(),
        data: this.getChartDataArray(loadSize, 'cold')
      }];
    },

    getChartDataArray(load, waterTemp) {
      // Water data is stored in the cycles array. We need to
      // extract that and convert hot or cold data to an array
      // let water = get(this, 'totalWater');
      let output = [];
      let mod = load.charAt(0); // Data is now store in this.waterUse

      this.waterUse.forEach(cycle => {
        if (waterTemp == 'hot') {
          let value = Number(cycle[mod][1]);
          output.push(this.convertToLiters ? toL(value) : value);
        } else {
          let total = new Big(cycle[mod][0]);
          let hot = Number(cycle[mod][1]);
          let cold = Number(total.minus(hot).toFixed(2));
          output.push(this.convertToLiters ? toL(cold) : cold);
        }
      });
      return output;
    },

    theme: _defaultTheme.default // #endregion

  });

  _exports.default = _default;
});