define("alliance-business-suite/templates/components/ui-page/page-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JyPnmloL",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,1],[[35,0]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"ui-loading-throb\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"isLoading\",\"if\"]}",
    "moduleName": "alliance-business-suite/templates/components/ui-page/page-content.hbs"
  });

  _exports.default = _default;
});