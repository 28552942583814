define("alliance-business-suite/components/machines/programs/program/acas-tlw", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    machineAuditType: Ember.computed.alias('machineProgram.machineAuditType.id'),
    machineCode: Ember.computed.alias('machineProgram.machineCode'),
    cycleCount: 5,
    optionsDefaultCycles: Ember.computed(function () {
      if (this.machineAuditType === "ACAS_A1") {
        return [{
          val: 1,
          label: 'normal_hot'
        }, {
          val: 2,
          label: 'normal_warm'
        }, {
          val: 3,
          label: 'normal_cold'
        }, {
          val: 4,
          label: 'perm_press_warm'
        }, {
          val: 5,
          label: 'delicate_cold'
        }];
      } else {
        return [{
          val: 1,
          label: 'normal_hot'
        }, {
          val: 2,
          label: 'normal_warm'
        }, {
          val: 3,
          label: 'normal_cold'
        }, {
          val: 4,
          label: 'perm_press_warm'
        }, {
          val: 6,
          label: 'delicate_cold'
        }];
      }
    }),
    audioSignalsOnValue: 29,
    // Default is 29
    audioSignals: Ember.computed('machineAuditType', 'machineProgram.settings.Program.{miscProgMessage.audio,Miscellaneous.AudioSignalCoinOrCardInsertionEnabled,Miscellaneous.AudioSignalKeyPressEnabled,Miscellaneous.AudioSignalNetworkCommandEnabled,Miscellaneous.AudioSignalStartModeEnabled}', {
      get() {
        if (this.machineAuditType === "ACAS_A1") {
          return this.machineProgram.settings.Program.Miscellaneous.AudioSignalCoinOrCardInsertionEnabled && this.machineProgram.settings.Program.Miscellaneous.AudioSignalKeyPressEnabled && this.machineProgram.settings.Program.Miscellaneous.AudioSignalNetworkCommandEnabled && this.machineProgram.settings.Program.Miscellaneous.AudioSignalStartModeEnabled;
        } else {
          return this.machineProgram.settings.Program.miscProgMessage.audio !== 0;
        }
      },

      set(key, value) {
        if (this.machineAuditType === "ACAS_A1") {
          this.setProperties({
            'machineProgram.settings.Program.Miscellaneous.AudioSignalCoinOrCardInsertionEnabled': value,
            'machineProgram.settings.Program.Miscellaneous.AudioSignalKeyPressEnabled': value,
            'machineProgram.settings.Program.Miscellaneous.AudioSignalNetworkCommandEnabled': value,
            'machineProgram.settings.Program.Miscellaneous.AudioSignalStartModeEnabled': value
          });
        } else {
          Ember.set(this.machineProgram.settings, "Program.miscProgMessage.audio", value === true ? this.audioSignalsOnValue : 0);
        }

        return value;
      }

    })
  });

  _exports.default = _default;
});