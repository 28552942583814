define("alliance-business-suite/helpers/str-replace", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute(args
    /* , hash*/
    ) {
      if (args[0]) {
        return args[0].replace(args[1], args[2]);
      }

      return args[0];
    }

  });

  _exports.default = _default;
});