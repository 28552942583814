define("alliance-business-suite/templates/components/ui/info-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "u0bxnApf",
    "block": "{\"symbols\":[],\"statements\":[[10,\"i\"],[14,0,\"fa fa-info-circle\"],[14,\"aria-hidden\",\"true\"],[12],[8,\"ember-tooltip\",[],[[\"@event\",\"@tooltipClass\"],[\"hover\",\"ember-tooltip tooltip-lg\"]],[[\"default\"],[{\"statements\":[[1,[34,0]]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"text\"]}",
    "moduleName": "alliance-business-suite/templates/components/ui/info-icon.hbs"
  });

  _exports.default = _default;
});