define("alliance-business-suite/templates/components/machines/programs/pricing/midas-wx-modal-cycle-order", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ru8ZUYlJ",
    "block": "{\"symbols\":[\"panel\",\"cycle\",\"@hideModal\"],\"statements\":[[8,\"form-ui\",[],[[\"@object\",\"@machineProgram\",\"@formSettings\",\"@selectOptions\",\"@formDisplay\"],[[32,0,[\"object\"]],[32,0,[\"machineProgram\"]],[32,0,[\"formSettings\"]],[32,0,[\"selectOptions\"]],\"compact\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[10,\"div\"],[12],[2,\"\\n    \"],[8,\"ui/section-panel\",[],[[\"@type\"],[\"dialog-section\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,1,[\"header\"]],[],[[\"@title\"],[[30,[36,2],[\"cycle_ordering\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n      \"],[8,[32,1,[\"contents\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],[[32,0,[\"cycleDisplayList\"]]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"table\"],[14,0,\"table-hover-row cycle__table\"],[12],[2,\"\\n            \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,0,[\"cycleDisplayList\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[10,\"tr\"],[14,0,\"no-select\"],[12],[2,\"\\n                  \"],[10,\"td\"],[14,\"role\",\"button\"],[12],[2,\"\\n                    \"],[1,[32,2]],[2,\"\\n                  \"],[13],[2,\"\\n                \"],[13],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"\\n            \"],[13],[2,\"\\n          \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[2,\"      \"],[11,\"button\"],[24,0,\"button--ok\"],[24,4,\"button\"],[4,[38,4],[\"click\",[32,3]],null],[12],[1,[30,[36,2],[\"OK\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\",\"t\",\"if\",\"on\"]}",
    "moduleName": "alliance-business-suite/templates/components/machines/programs/pricing/midas-wx-modal-cycle-order.hbs"
  });

  _exports.default = _default;
});