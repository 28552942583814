define("alliance-business-suite/components/summary/reports/s-manual-balance-change", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "alliance-business-suite/utils/currency-format-util", "alliance-business-suite/utils/report-tables-util"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _currencyFormatUtil, _reportTablesUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  let SummaryReportsSManualBalanceChangeComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, (_class = class SummaryReportsSManualBalanceChangeComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor3, this);
    }

    get translatedColumns() {
      return [{
        name: 'Name',
        valuePath: 'name',
        width: 414,
        isFixed: 'left'
      }, {
        name: 'Date',
        valuePath: 'transactionDate',
        width: 231
      }, {
        name: 'cash_balance_value_change',
        valuePath: 'cashBalanceValueChanged',
        width: 343
      }, {
        name: 'rewards_credit_value_changed',
        valuePath: 'rewardsCreditValueChanged',
        width: 304
      }, {
        name: 'rewards_points_value_changed',
        valuePath: 'rewardsPointsValueChanged',
        width: 262
      }, {
        name: 'by_user',
        valuePath: 'changedByUser',
        width: 125
      }];
    }

    get rowsWithChildren() {
      let {
        model
      } = this.args;
      let geoBoundaries = model.geoBoundaries;
      let rowData = [];
      geoBoundaries.forEach(geoBoundary => {
        let monthArray = [];
        let totalAmountDepositedSum = 0;
        let totalAmountDeposited = [];
        let totalCreditDepositedSum = 0;
        let totalCreditDeposited = [];
        let totalPointsDepositedSum = 0;
        let totalPointsDeposited = [];
        rowData.push({
          name: _reportTablesUtil.localizeString.call(this, geoBoundary.name, 'Location', 'location'),
          children: monthArray,
          cashBalanceValueChanged: totalAmountDeposited,
          rewardsCreditValueChanged: totalCreditDeposited,
          rewardsPointsValueChanged: totalPointsDeposited
        });
        geoBoundary.months.forEach(month => {
          let weekArray = [];
          let totalAmountDepositedSum1 = 0;
          let totalAmountDeposited1 = [];
          let totalCreditDepositedSum1 = 0;
          let totalCreditDeposited1 = [];
          let totalPointsDepositedSum1 = 0;
          let totalPointsDeposited1 = [];
          monthArray.push({
            name: month.name,
            children: weekArray,
            cashBalanceValueChanged: totalAmountDeposited1,
            rewardsCreditValueChanged: totalCreditDeposited1,
            rewardsPointsValueChanged: totalPointsDeposited1
          });
          month.weeks.forEach(week => {
            let dayArray = [];
            let totalAmountDepositedSum2 = 0;
            let totalAmountDeposited2 = [];
            let totalCreditDepositedSum2 = 0;
            let totalCreditDeposited2 = [];
            let totalPointsDepositedSum2 = 0;
            let totalPointsDeposited2 = [];
            weekArray.push({
              name: week.name,
              children: dayArray,
              cashBalanceValueChanged: totalAmountDeposited2,
              rewardsCreditValueChanged: totalCreditDeposited2,
              rewardsPointsValueChanged: totalPointsDeposited2
            });
            week.days.forEach(day => {
              let stationArray = [];
              let totalAmountDepositedSum3 = 0;
              let totalAmountDeposited3 = [];
              let totalCreditDepositedSum3 = 0;
              let totalCreditDeposited3 = [];
              let totalPointsDepositedSum3 = 0;
              let totalPointsDeposited3 = [];
              dayArray.push({
                name: day.name,
                children: stationArray,
                cashBalanceValueChanged: totalAmountDeposited3,
                rewardsCreditValueChanged: totalCreditDeposited3,
                rewardsPointsValueChanged: totalPointsDeposited3
              });
              day.manualBalanceChange.forEach(balance => {
                let machineVendDArray = [];

                if (balance.cashBalanceValueChanged !== 0) {
                  machineVendDArray.push(balance.cashBalanceValueChanged);
                  totalAmountDepositedSum = (0, _reportTablesUtil.sumArray)(machineVendDArray, totalAmountDepositedSum);
                  totalAmountDepositedSum1 = (0, _reportTablesUtil.sumArray)(machineVendDArray, totalAmountDepositedSum1);
                  totalAmountDepositedSum2 = (0, _reportTablesUtil.sumArray)(machineVendDArray, totalAmountDepositedSum2);
                  totalAmountDepositedSum3 = (0, _reportTablesUtil.sumArray)(machineVendDArray, totalAmountDepositedSum3);
                }

                if (balance.rewardsCreditValueChanged !== 0) {
                  machineVendDArray.push(balance.rewardsCreditValueChanged);
                  totalCreditDepositedSum = (0, _reportTablesUtil.sumArray)(machineVendDArray, totalCreditDepositedSum);
                  totalCreditDepositedSum1 = (0, _reportTablesUtil.sumArray)(machineVendDArray, totalCreditDepositedSum1);
                  totalCreditDepositedSum2 = (0, _reportTablesUtil.sumArray)(machineVendDArray, totalCreditDepositedSum2);
                  totalCreditDepositedSum3 = (0, _reportTablesUtil.sumArray)(machineVendDArray, totalCreditDepositedSum3);
                }

                if (balance.rewardsPointsValueChanged !== 0) {
                  machineVendDArray.push(balance.rewardsPointsValueChanged);
                  totalPointsDepositedSum = (0, _reportTablesUtil.sumArray)(machineVendDArray, totalPointsDepositedSum);
                  totalPointsDepositedSum1 = (0, _reportTablesUtil.sumArray)(machineVendDArray, totalPointsDepositedSum1);
                  totalPointsDepositedSum2 = (0, _reportTablesUtil.sumArray)(machineVendDArray, totalPointsDepositedSum2);
                  totalPointsDepositedSum3 = (0, _reportTablesUtil.sumArray)(machineVendDArray, totalPointsDepositedSum3);
                }

                let cashBalanceValueChanged = _currencyFormatUtil.formatCurrencyString.call(this, balance.cashBalanceValueChanged || 0);

                let rewardsCreditValueChanged = _currencyFormatUtil.formatCurrencyString.call(this, balance.rewardsCreditValueChanged || 0); // let rewardsCreditValueChanged = balance.rewardsCreditValueChanged  || 0;


                let rewardsPointsValueChanged = balance.rewardsPointsValueChanged || 0;
                let changedByUser = balance.changedByUser || 'N/A';
                let transactionDate = "";

                if (balance.transactionDate) {
                  transactionDate = moment(balance.transactionDate).format(`${_reportTablesUtil.formatDateTimeString.call(this)}`);
                }

                stationArray.push({
                  name: balance.userName,
                  transactionDate: transactionDate,
                  cashBalanceValueChanged: cashBalanceValueChanged,
                  rewardsCreditValueChanged: rewardsCreditValueChanged,
                  rewardsPointsValueChanged: rewardsPointsValueChanged,
                  changedByUser: changedByUser
                });
              });
              totalAmountDeposited3.push(_currencyFormatUtil.formatCurrencyString.call(this, totalAmountDepositedSum3 || 0));
              totalCreditDeposited3.push(totalCreditDepositedSum3 || 0);
              totalPointsDeposited3.push(totalPointsDepositedSum3 || 0);
            });
            totalAmountDeposited2.push(_currencyFormatUtil.formatCurrencyString.call(this, totalAmountDepositedSum2 || 0));
            totalCreditDeposited2.push(totalCreditDepositedSum2 || 0);
            totalPointsDeposited2.push(totalPointsDepositedSum2 || 0);
          });
          totalAmountDeposited1.push(_currencyFormatUtil.formatCurrencyString.call(this, totalAmountDepositedSum1 || 0));
          totalCreditDeposited1.push(totalCreditDepositedSum1 || 0);
          totalPointsDeposited1.push(totalPointsDepositedSum1 || 0);
        });
        totalAmountDeposited.push(_currencyFormatUtil.formatCurrencyString.call(this, totalAmountDepositedSum || 0));
        totalCreditDeposited.push(totalCreditDepositedSum || 0);
        totalPointsDeposited.push(totalPointsDepositedSum || 0);
      });
      return rowData;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SummaryReportsSManualBalanceChangeComponent;
});