define("alliance-business-suite/templates/components/form-ui/speed-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oywbas2O",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[8,\"form-ui\",[],[[\"@object\",\"@formSettings\",\"@selectOptions\",\"@machineProgram\"],[[32,0,[\"object\"]],[32,0,[\"formSettings\"]],[32,0,[\"selectOptions\"]],[32,0,[\"machineProgram\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n    \"],[8,[32,1,[\"select\"]],[],[[\"@params\"],[[30,[36,0],null,[[\"propertyName\",\"options\",\"labelKey\",\"hasCustom\",\"sharedCustomProperty\",\"showPresets\",\"customType\",\"speedSettings\",\"machineSettings\",\"machineProgram\"],[[32,0,[\"properties\",\"Speed\",\"key\"]],[32,0,[\"optionList\"]],\"speed\",true,true,[32,0,[\"showPresets\"]],\"speed\",[32,0,[\"speedSettings\"]],[32,0,[\"params\",\"machineSettings\"]],[32,0,[\"params\",\"machineProgram\"]]]]]]],null],[2,\"\\n\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"hash\"]}",
    "moduleName": "alliance-business-suite/templates/components/form-ui/speed-select.hbs"
  });

  _exports.default = _default;
});