define("alliance-business-suite/components/shopping-cart-upgrade", ["exports", "jquery", "moment", "alliance-business-suite/mixins/shopping-cart", "alliance-business-suite/mixins/account-payment"], function (_exports, _jquery, _moment, _shoppingCart, _accountPayment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_shoppingCart.default, _accountPayment.default, {
    access: Ember.inject.service(),
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    intl: Ember.inject.service(),
    notifications: Ember.inject.service(),
    classNameBindings: ['brandNameClass'],
    brandNameClass: Ember.computed('this.brand.slug', function () {
      return `cart--${this.brand.slug}`;
    }),
    activeSection: 'info',
    mode: 'default',
    stripeToken: null,
    isLoading: true,
    fetchServices: true,

    getAddonPrices() {
      let {
        selectedLocation
      } = this;
      let state = selectedLocation.state;
      let zipCode = selectedLocation.zipCode;
      this.set('isCalcPrices', true);
      this.ajax.request(`/organizations/${this.get('access.accessOrganization.id')}/balance/price?raw=true&zipCode=${zipCode}&state=${state}&roomId=${selectedLocation.id}`, {}).then(response => {
        this.setProperties({
          addons: response.addons,
          isCalcPrices: false,
          servicesError: false,
          isLoading: false
        });
        this.notifyPropertyChange('selectedService');
      }).catch(e => {
        this.setProperties({
          isCalcPrices: false,
          servicesError: true,
          activeSection: 'info'
        });
      });
    },

    rooms: Ember.computed(function () {
      return this.access.paymentLocations;
    }),
    upgradeOptions: Ember.computed('currentPlan', function () {
      let {
        rawLoc,
        currentPlan
      } = this;
      let result = [];

      if (currentPlan.genericProductLookupKey == "NETWORKING_PLUS_PAYMENT") {
        result.push('select_upgrade_to_pos');
      }

      if (rawLoc.availableAddons && rawLoc.availableAddons.length > 0) {
        result.push('select_add_ons');
      }

      return result;
    }).readOnly(),
    selectedLocation: Ember.computed('preselectedLocation', function () {
      if (this.preselectedLocation) {
        let roomId = this.preselectedLocation;
        let room = null;
        this.rooms.forEach(r => {
          if (r.id === roomId) {
            room = r;
          }
        });
        return room;
      }

      return this.access.paymentLocations[0];
    }),
    isInfoNotComplete: Ember.computed('selectedUpgradePath', function () {
      if (!this.selectedUpgradePath) {
        return true;
      }
    }),
    hasConfirmed: false,
    isAddonNotComplete: Ember.computed('selectedLocation', 'upgradeOption', 'hasConfirmed', function () {
      let {
        selectedLocation,
        upgradeOption,
        hasConfirmed
      } = this;
      let state = selectedLocation.state;
      let zipCode = selectedLocation.zipCode;

      if (upgradeOption == 'upgradeProductToPOS') {
        return !hasConfirmed;
      }

      if (!zipCode || !state) {
        return true;
      }
    }),
    generateAddOns: Ember.observer('selectedService', 'activeAddons', function () {
      let {
        availableAddons,
        activeAddons,
        selectedLocation
      } = this;
      let arr = [];
      let supportedAddons = selectedLocation.availableAddons;
      supportedAddons.forEach(addon => {
        let obj = {};
        obj.description = addon.description;
        obj.id = addon.id;
        obj.lookupKey = addon.lookupKey;
        obj.pricePerMonth = addon.pricePerMonth;
        obj.isSelected = false;
        arr.push(obj);
      });
      this.set('addonLineItems', arr);
    }),
    hasSelectedGatewayAddon: Ember.computed('addonLineItems.[].@each', function () {
      let {
        addonLineItems
      } = this;
      let result;

      if (addonLineItems) {
        addonLineItems.forEach(addon => {
          if (addon.lookupKey == 'ADDON_CELLULAR_GATEWAY' && addon.isSelected) {
            result = true;
          }
        });
      }

      return result;
    }),

    calcFinalPrice() {
      let {
        selectedLocation,
        selectedService,
        addonLineItems,
        hasConfirmed,
        currentPlan
      } = this;
      let roomId = selectedLocation.id;
      let addons = [];
      let state = selectedLocation.state;
      let zipCode = selectedLocation.zipCode;
      this.set('isLoadingPrice', true);

      if (addonLineItems && addonLineItems.length > 0) {
        addonLineItems.forEach(a => {
          if (a.isSelected) {
            addons.push(a.lookupKey);
          }
        });
      }

      let params = {
        roomId,
        zipCode: zipCode,
        state: state,
        addons
      };

      if (hasConfirmed) {
        params.productLookupKey = currentPlan.lookupKey;
        params.genericProductTypeLookupKey = "NETWORKING_PLUS_PAYMENT_PLUS_POS";
      }

      this.ajax.request(`/organizations/${this.get('access.accessOrganization.id')}/balance/calculate?raw=true`, {
        data: params,
        method: 'POST'
      }).then(response => {
        this.set('isLoadingPrice', false);
        this.set('finalPrice', response);
      }).catch(error => {
        this.set('activeSection', 'info');
        this.set('servicesError', true);
        this.set('fetchServices', true);
      });
    },

    actions: {
      close() {
        this.close();
      },

      togglePOSupgrade() {
        this.toggleProperty('hasConfirmed');

        if (this.hasConfirmed) {
          this.calcFinalPrice();
        }
      },

      setSection(section) {
        if (section == 'payment') {
          this.send('payment');
          return false;
        }

        this.set('activeSection', section);

        if (section == 'upgrade_addons') {
          if (this.fetchServices) {
            this.getAddonPrices();
          }
        }
      },

      selectUpgradePath(val) {
        let {
          selectedUpgradeType
        } = this;

        if (val == "select_add_ons") {
          this.setProperties({
            upgradeOption: 'upgradeAddons',
            hasConfirmed: false
          });
        } else {
          this.set('upgradeOption', 'upgradeProductToPOS');
        }

        this.setProperties({
          selectedUpgradePath: val,
          finalPrice: null
        });
      },

      updateAddon(val) {
        let {
          addonLineItems,
          activeAddons
        } = this;
        let newArr = [];
        addonLineItems.forEach(addon => {
          let obj = {};
          obj.description = addon.description;
          obj.id = addon.id;
          obj.lookupKey = addon.lookupKey;
          obj.pricePerMonth = addon.pricePerMonth;

          if (addon.lookupKey == val.lookupKey) {
            obj.isSelected = !val.isSelected;
          } else {
            obj.isSelected = addon.isSelected;
          }

          newArr.push(obj);
        });
        this.set('addonLineItems', newArr);
        this.calcFinalPrice();
      },

      payment() {
        let _this = this;

        let organizationId = this.get('session.data.orgId');
        let userEmail = this.access.accessUser.primaryEmail;
        let {
          addonLineItems,
          upgradeOption,
          hasConfirmed,
          finalPrice
        } = this;
        let selectedLocation = this.selectedLocation;
        let state = selectedLocation.state;
        let zipCode = selectedLocation.zipCode;
        let brandName = this.brand.name;
        let imageUrl = this.brand.lookupKey('payment-logo');
        this.set('mode', 'loading');
        let addons = [];

        if (addonLineItems && addonLineItems.length > 0) {
          addonLineItems.forEach(a => {
            if (a.isSelected) {
              addons.push(a.lookupKey);
            }
          });
        }

        if (upgradeOption == 'upgradeProductToPOS') {
          if (!hasConfirmed) {
            alert(this.intl.t('complete_form_to_continue'));
          }
        } else {
          if (!zipCode || !state || addons.length == 0) {
            alert(this.intl.t('complete_form_to_continue'));
            this.set('mode', 'default');
            return;
          }
        } // stripe stuff here
        // fake CC 4242424242424242


        let stripe = StripeCheckout.configure({
          // key: "pk_test_ZzfxZ5TKR8EAIRi7jNcbF2Ii00g8bLwRen", // TEST
          key: "pk_live_WPqd2s7WlRCyIR21goQQKtkx009OSbLGWq",
          // Prod
          image: imageUrl,
          locale: "auto",
          token: function (token) {
            _this.set('stripeToken', token);

            let params = {
              userEmail: userEmail,
              stripeToken: token.id,
              roomId: _this.selectedLocation.id,
              zipCode,
              state,
              addons
            };

            if (upgradeOption == 'upgradeProductToPOS') {
              params.productLookupKey = finalPrice.product.lookupKey;
            } // checkout call
            // need a spinner here


            _this.ajax.request(`/organizations/${organizationId}/balance/charge`, {
              method: 'POST',
              data: params
            }).then(() => {
              _this.set('mode', 'success');

              setTimeout(function () {
                location.href = '/';
              }, 10000);
            }, () => {
              alert(_this.intl.t('cart_payment_error'));

              _this.set('mode', 'default'); // alert('Error 2827, please contact support. You were not charged.');
              // _this.notifications.error(_this.intl.t('cart_payment_error'), {
              //   autoClear: true,
              //   clearDuration: 30000
              // });

            });
          }
        }); // pass extra meta data

        stripe.open({
          name: this.brand.b('order_care') // description: "Purchase 1 year",
          // zipCode: true,
          // amount: _this.total,

        }); // return to 'default' mode when closing the stripe iframe
        // which is only when there is no stripe token

        (0, _jquery.default)(document).on("DOMNodeRemoved", ".stripe_checkout_app", close);

        function close() {
          if (!_this.stripeToken) {
            _this.set('mode', 'default');
          }
        }
      }

    }
  });

  _exports.default = _default;
});