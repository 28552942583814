define("alliance-business-suite/components/form-ui/checkbox-number", ["exports", "alliance-business-suite/mixins/init-elements"], function (_exports, _initElements) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_initElements.default, {
    tagName: 'div',
    classNames: ['checkbox-number', 'form-field', 'overflow-float', 'number-field', 'text-field', 'display-compact'],
    actions: {
      focusIn() {
        Ember.set(this, 'showTooltip', true);
      },

      focusOut() {
        Ember.set(this, 'showTooltip', false);
      }

    }
  });

  _exports.default = _default;
});