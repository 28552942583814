define("alliance-business-suite/utils/is-expired", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isExpired;

  function isExpired() {
    let month = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
    let year = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    const now = new Date();
    const currentMonth = now.getMonth() + 1;
    const currentYear = now.getFullYear();
    month = Number(month);
    year = Number(year);

    if (year < 100) {
      year = year + 2000; // TODO: Fix this when we get near the year 2100
    }

    if (year < currentYear || year === currentYear && month < currentMonth) {
      return true;
    } else {
      return false;
    }
  }
});