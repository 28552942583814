define("alliance-business-suite/components/employees/sales-goals/roles", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <section class="content-container padding-0" {{did-insert this.initialLoad}}>
  
    {{#if this.isLoading}}
      <div class="content-container container--placeholder">
        <UiLoadingThrob />
      </div>
    {{else}}
      {{#if (eq this.items.length 0)}}
        <div class="content-container container--placeholder">
          <h3>{{t "not_found"}}</h3>
        </div>
      {{else}}
        <Tables::TableInteractive
          @isClickable={{true}}
          @data={{this.items}}
          @columns={{this.columns}}
          @displayDataChangedAction={{action "selectRow"}}
         />
      {{/if}}
    {{/if}}
  </section>
  
  {{#if this.showModal}}
    <Employees::SalesGoals::Modal::Role
      @save={{this.save}}
      @close={{this.close}}
      @delete={{this.delete}}
      @role={{this.selectedRow}} />
  {{/if}}
  
  */
  {
    "id": "GaAPcm3Z",
    "block": "{\"symbols\":[],\"statements\":[[11,\"section\"],[24,0,\"content-container padding-0\"],[4,[38,4],[[32,0,[\"initialLoad\"]]],null],[12],[2,\"\\n\\n\"],[6,[37,3],[[32,0,[\"isLoading\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"content-container container--placeholder\"],[12],[2,\"\\n      \"],[8,\"ui-loading-throb\",[],[[],[]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[30,[36,2],[[32,0,[\"items\",\"length\"]],0],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"content-container container--placeholder\"],[12],[2,\"\\n        \"],[10,\"h3\"],[12],[1,[30,[36,1],[\"not_found\"],null]],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[8,\"tables/table-interactive\",[],[[\"@isClickable\",\"@data\",\"@columns\",\"@displayDataChangedAction\"],[true,[32,0,[\"items\"]],[32,0,[\"columns\"]],[30,[36,0],[[32,0],\"selectRow\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\"],[6,[37,3],[[32,0,[\"showModal\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"employees/sales-goals/modal/role\",[],[[\"@save\",\"@close\",\"@delete\",\"@role\"],[[32,0,[\"save\"]],[32,0,[\"close\"]],[32,0,[\"delete\"]],[32,0,[\"selectedRow\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"t\",\"eq\",\"if\",\"did-insert\"]}",
    "moduleName": "alliance-business-suite/components/employees/sales-goals/roles.hbs"
  });

  let EmployeesSalesGoalsRolesComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, (_class = class EmployeesSalesGoalsRolesComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "ajax", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "canAccess", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "isLoading", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "showModal", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "selectedRow", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "items", _descriptor8, this);
    }

    // @computed
    // get items() {
    //   return this.args.data.filterBy('securityRole.internalRole', false).sortBy('securityRole.prettyName');
    // }
    initialLoad() {
      this.isLoading = true;
      this.fetchRolesSaleGoals();
    } // @computed('args.data.[]')
    // get items() {
    //   console.log('*** updating items');
    //   return this.args.data.sortBy('employee.fullName');
    // }


    fetchRolesSaleGoals(isCached) {
      if (isCached) {
        this.showModal = false;
        this.isSaving = false;
        this.isLoading = false;
        this.items = this.store.peekAll('salesGoal').filterBy('securityRole.internalRole', false).sortBy('securityRole.prettyName');
      } else {
        this.store.findAll('salesGoal', {
          reload: true
        }).then(results => {
          this.items = results.filterBy('securityRole.internalRole', false).sortBy('securityRole.prettyName');
          this.showModal = false;
          this.isSaving = false;
          this.isLoading = false;
        });
      }
    }

    selectRow(item, e) {
      // TODO: clicking a second time will unselect the row...which we don't want to happen.
      if (item.selectedItems.length > 0) {
        this.selectedRow = item.selectedItems.firstObject;
      }

      if (this.canAccess.canAccess('EMPLOYEE_GOALS_EDIT_ROLE_SALES_GOAL')) {
        this.showModal = true;
      }
    }

    close(item) {
      this.showModal = false;
      this.selectedRow.rollbackAttributes();
    }

    delete() {
      let id = this.selectedRow.id;
      let record = this.store.peekRecord('sales-goal', id);
      record.roleGoal = null;
      this.ajax.request(`/securityRoles/${id}/salesGoal`, {
        method: 'DELETE'
      }).then(() => {
        this.showModal = false;
        this.isSaving = false;
        this.isLoading = false;
      });
    }

    save() {
      this.isSaving = true; // SAVE

      this.selectedRow.save().then(result => {
        this.showModal = false;
        this.isSaving = false;
        this.isLoading = false;
      });
    }

    get columns() {
      return [{
        title: this.intl.t('role_name'),
        propertyName: 'securityRole.prettyName'
      }, {
        title: this.intl.t('goal_amount'),
        propertyName: 'goal',
        component: 'table/sales-goals/table-cell-role-goal'
      }];
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "canAccess", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isLoading", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "showModal", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedRow", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "items", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "initialLoad", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "initialLoad"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectRow", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "selectRow"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "close", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "delete", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "delete"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "save", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype)), _class));
  _exports.default = EmployeesSalesGoalsRolesComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, EmployeesSalesGoalsRolesComponent);
});