define("alliance-business-suite/models/shift-block", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    shiftName: attr(),
    name: Ember.computed('shiftName', function () {
      return this.shiftName || '<not named>';
    }),
    startTime: attr(),
    endTime: attr(),
    // startTime: computed(function() {
    //   return moment(get(this, 'startDatetime')).format('hh:mm');
    // }),
    // endTime: computed(function() {
    //   return moment(get(this, 'endDatetime')).format('hh:mm');
    // }),
    createdBy: attr(),
    createdAt: attr(),
    updatedBy: attr(),
    updatedAt: attr(),
    room: belongsTo('room')
  });

  _exports.default = _default;
});