define("alliance-business-suite/components/machines/programs/cycles/mc5-tum", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // eslint-disable-line
    notifications: Ember.inject.service(),
    intl: Ember.inject.service(),
    cycleList: Ember.computed.alias('model.settings.Cycles'),

    didInsertElement() {
      let self = this;

      this._super(...arguments);

      this.send('selectCycle');
    },

    actions: {
      // eslint-disable-line
      selectCycle(value, init) {
        console.time('selectCycle');

        if (event && event.target.tagName === "INPUT") {
          return true;
        } // Value can be the cycle object or the index of the cycle


        let index, cycle;

        if (value === undefined) {
          value = 0;
        }

        if (isNaN(value)) {
          // we have an object
          cycle = value;
          index = this.cycleList.indexOf(value);
        } else {
          // we have a number
          index = value;
          cycle = this.cycleList.objectAt(value);
        }

        Ember.set(this, 'selectedCycle', cycle);
        Ember.set(this, 'selectedCycleIndex', Number(index));
        localStorage.setItem('cycle', index || 0);
        console.timeEnd('selectCycle');
      }

    }
  });

  _exports.default = _default;
});