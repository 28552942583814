define("alliance-business-suite/components/machines/programs/cycles/steps-mc5-tum", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // eslint-disable-line ember/no-classic-classes
    tagName: '',
    isCelsius: Ember.computed.alias('machineProgram._isCelsius'),
    globalReversing: Ember.computed.alias('machineProgram.settings.Program.Cycle.ReversingEnable'),
    optionsDryingType: [{
      label: "time",
      val: 0
    }, {
      label: "moisture",
      val: 1
    }],
    optionsCooldownType: [{
      label: "time",
      val: 0
    }, {
      label: "temperature",
      val: 1
    }]
  });

  _exports.default = _default;
});