define("alliance-business-suite/components/system-header", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11;

  let SystemHeaderComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember.computed.alias("access.headerLinks"), _dec11 = Ember.computed.mapBy("headerLinks", "parentLink"), _dec12 = Ember.computed("router.currentRoute"), _dec13 = Ember.computed("router.currentRoute"), _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, _dec18 = Ember._action, (_class = class SystemHeaderComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "ajax", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "brand", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "brush", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "router", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "session", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "isMidasOrg", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "isLoadingMidasUrl", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "headerLinks", _descriptor10, this);
      (0, _initializerDefineProperty2.default)(this, "parentLinks", _descriptor11, this);
    }

    get isRoute() {
      return !this.access.isLaundromatOrg;
    } // computed('session.data.{authenticated.hasAcceptedTOS,hasAcceptedTOS}',


    get tosAccepted() {
      return this.session.get("data.authenticated.hasAcceptedTOS") || this.session.get("data.hasAcceptedTOS");
    }

    get currentPath() {
      if (this.parentLinks.includes(this.parentPath)) {
        return this.parentPath;
      }
    }

    set currentPath(value) {
      return value;
    }

    handleRouteChanged() {
      if (this.parentLinks.includes(this.parentPath)) {
        this.currentPath = this.parentPath;
      }
    }

    get parentPath() {
      return this.getParentPathFromPath(this.router.get("currentRoute.name"));
    }

    getParentPathFromPath(path) {
      if (!path) {
        return;
      }

      return path.split(".")[0];
    } // _syncCurrentPath: observer('router.currentPath', function() {
    //   let currentPath = this.router.get('currentPath')
    //   let parentLinks = get(this, 'parentLinks');
    //
    //   if (parentLinks.includes(this.getParentPathFromPath(currentPath))) {
    //     set(this, 'currentPath', currentPath);
    //   }
    // }),


    get currentUserRole() {
      const employee = this.store.peekRecord("employee", this.access.accessUser.get("id"));
      const securityRoles = employee.get("securityRoles");
      const securityRole = securityRoles.get("firstObject");
      return securityRole.get("name");
    }

    findIsMidasOrg() {
      if (this.currentUserRole && this.currentUserRole === "OWNER_OPERATOR") {
        try {
          const orgMidasMachines = this.store.peekAll("machine").filter(m => {
            return m.machineAuditGroupType.get("id") === "MIDAS";
          });
          return orgMidasMachines.length > 0;
        } catch (e) {
          console.log("e", e);
          return false;
        }
      } else {
        return null;
      }
    }

    initialLoad() {
      // show the VIP badge only for SQ orgs with Midas machines
      if (this.brand.get("slug") === "sq") {
        if (this.access.isTrade && !this.access.isOpl && !this.access.isInternational || this.access.isDemo) {
          setTimeout(() => {
            this.isMidasOrg = this.findIsMidasOrg();
          }, 1000);
        }
      }
    }

    sidebarToggle() {
      this.args.burger.state.actions.toggle();
    }

    toggleSidebar() {
      this.brush.handleSidebar(); // this.set(
      //   'brush.showSidebar', !this.brush.get('showSidebar')
      // );
    }

    selectLink(dropdownLink) {
      if (this.access.isDemo && dropdownLink === "employees") {
        alert("Demo mode restricted.");
        return; // employees will not do anything when clicked in demo mode
      }

      if (dropdownLink === "open-external-midas") {
        this.actions.openMidasExternalUrl();
        return;
      }

      this.currentPath = dropdownLink;
      this.router.transitionTo(dropdownLink);
    }

    openMidasExternalUrl() {
      if (this.isLoadingMidasUrl) {
        return;
      }

      this.isLoadingMidasUrl = true;
      this.ajax.request(`/marketing/docebo`, {
        method: "POST",
        dataType: "JSON"
      }).then(response => {
        this.isLoadingMidasUrl = false;

        if (response.url.length > 0) {
          window.open(response.url, "_blank");
        }
      });
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "brand", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "brush", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "router", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "session", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isMidasOrg", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isLoadingMidasUrl", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "headerLinks", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "parentLinks", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "currentPath", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "currentPath"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "parentPath", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "parentPath"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "initialLoad", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "initialLoad"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "sidebarToggle", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "sidebarToggle"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "toggleSidebar", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "toggleSidebar"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectLink", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "selectLink"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "openMidasExternalUrl", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "openMidasExternalUrl"), _class.prototype)), _class));
  _exports.default = SystemHeaderComponent;
});