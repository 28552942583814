define("alliance-business-suite/components/ui-loading-spinner", ["exports", "alliance-business-suite/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['route-loading']
  });

  _exports.default = _default;
});