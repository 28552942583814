define("alliance-business-suite/components/welcome/welcome-shim", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  let WelcomeWelcomeShimComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._action, (_class = class WelcomeWelcomeShimComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "router", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "brand", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "currentView", _descriptor4, this);
    }

    updateCurrentView(from) {
      const promoSupportedBrand = this.brand.slug === 'sq' || this.brand.slug === 'hu'; // if OPL, not brand supported skip all this

      if (this.access.isOpl || !promoSupportedBrand || from === 'owner-app-promo') {
        this.router.transitionTo('start');
      }

      if (from === 'terms-of-service') {
        this.currentView = 'owner-app-promo';
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "brand", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "currentView", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'terms-of-service';
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "updateCurrentView", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "updateCurrentView"), _class.prototype)), _class));
  _exports.default = WelcomeWelcomeShimComponent;
});