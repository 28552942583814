define("alliance-business-suite/templates/components/input/input-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jTe7MURp",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"input-number\"],[12],[2,\"\\n  \"],[8,\"input\",[],[[\"@id\",\"@type\",\"@value\"],[[30,[36,1],[[35,0],\"_hidden\"],null],\"hidden\",[34,2]]],null],[2,\"\\n  \"],[8,\"input\",[[24,0,\"input-number-text\"],[16,\"placeholder\",[34,4]]],[[\"@id\",\"@type\",\"@value\",\"@disabled\"],[[34,0],\"text\",[34,3],[34,5]]],null],[2,\"\\n  \"],[10,\"div\"],[14,0,\"input-number-half-height-up\"],[12],[2,\"\\n    \"],[10,\"button\"],[15,\"onclick\",[30,[36,6],[[32,0],\"increment\"],null]],[14,0,\"input-number-arrow-up\"],[15,\"disabled\",[34,5]],[14,4,\"button\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"input-number-half-height-down\"],[12],[2,\"\\n    \"],[10,\"button\"],[15,\"onclick\",[30,[36,6],[[32,0],\"decrement\"],null]],[14,0,\"input-number-arrow-down\"],[15,\"disabled\",[34,5]],[14,4,\"button\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"input-id\",\"concat\",\"value\",\"formattedValue\",\"placeholder\",\"disabled\",\"action\"]}",
    "moduleName": "alliance-business-suite/templates/components/input/input-number.hbs"
  });

  _exports.default = _default;
});