define("alliance-business-suite/components/dashboard/charts/drilldown/highcharts-drilldown", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "alliance-business-suite/utils/summary-util", "alliance-business-suite/utils/currency-util"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _summaryUtil, _currencyUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <HighCharts
    @content={{this.chartData}}
    @chartOptions={{this.chartOptions}}
    @theme={{@theme}} as |chart|>
  
      <Dashboard::Charts::Drilldown::DrilldownLegend
        @chart={{chart}}
        @isDrilldown={{this.isDrilldown}}
        @drillDownData={{this.drillDownData}}
        @defaultLegend={{this.defaultLegend}}
        @customLegend={{this.customLegend}} />
  </HighCharts>
  
  */
  {
    "id": "4qbY/xq+",
    "block": "{\"symbols\":[\"chart\",\"@theme\"],\"statements\":[[8,\"high-charts\",[],[[\"@content\",\"@chartOptions\",\"@theme\"],[[32,0,[\"chartData\"]],[32,0,[\"chartOptions\"]],[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n    \"],[8,\"dashboard/charts/drilldown/drilldown-legend\",[],[[\"@chart\",\"@isDrilldown\",\"@drillDownData\",\"@defaultLegend\",\"@customLegend\"],[[32,1],[32,0,[\"isDrilldown\"]],[32,0,[\"drillDownData\"]],[32,0,[\"defaultLegend\"]],[32,0,[\"customLegend\"]]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "alliance-business-suite/components/dashboard/charts/drilldown/highcharts-drilldown.hbs"
  });

  const COLORS = ['#6a71e5', '#0091ff', '#2cbae7', '#61d2fe', '#48dc6b', '#ff4966', '#ff5534', '#ffa500', '#ffd200', '#9fa0a4', '#97449c', '#0e5218', '#302d7c', '#c52961', '#e35604'];
  let DashboardChartsDrilldownHighchartsDrilldownComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, (_class = class DashboardChartsDrilldownHighchartsDrilldownComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "brush", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "isDrilldown", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "drillDownData", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "chartTotal", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "customLegend", _descriptor7, this);
      (0, _defineProperty2.default)(this, "highestValue", 0);
    }

    get chartData() {
      if (!this.args.results) {
        return;
      }

      return this.seriesData();
    }

    seriesData() {
      let tp = this.args.tp;
      let totals = this.args.results.roomTotals;
      let results = [];

      if (!totals) {
        return [];
      }

      let rooms = [];
      let ct = 0;
      totals.forEach(location => {
        let total = 0;
        location.rooms.forEach(room => {
          switch (tp) {
            case 'washer':
              total += room.washerRevenue;
              break;

            case 'dryer':
              total += room.dryerRevenue;
              break;

            case 'turns':
              total += room.turns;
              break;

            default:
              total += room.totalRevenue;
          }
        });

        if (total > this.highestValue) {
          this.highestValue = total;
        }

        rooms.push({
          name: location.name,
          y: total,
          color: COLORS[ct],
          drilldown: location.id.toString()
        });
        ct++;
      });
      results.push({
        name: this.intl.t('location'),
        data: rooms,
        axisLabel: 'Locations',
        subtitleText: this.intl.t('click_the_columns_for_room_breakdown')
      });
      return results;
    }

    drilldownDataRooms() {
      let tp = this.args.tp;
      let totals = this.args.results.machineTotals;
      let results = [];
      totals.forEach(location => {
        let ct = 0;
        let drilldown = {
          id: location.id.toString(),
          // dataLabels: {
          //   enabled: true,
          //   inside: false,
          //   format: tp == 'turns' ? '{point.y:,.0f}' : '${point.y:,.2f}'
          // },
          cursor: 'pointer',
          data: [],
          customLegend: this.drilldownLegendRooms,
          axisLabel: this.intl.t('machines')
        };
        location.rooms.forEach(room => {
          room.machines.forEach(machine => {
            let total = machine.totalRevenue;
            let label = "";

            if (machine.machineType.isWasher === true) {
              label = `Washer - ${(0, _summaryUtil.machineNameDisplay)(machine.machineName, machine.machineNumber)}`;
            }

            if (machine.machineType.isDryer === true) {
              label = `Dryer - ${(0, _summaryUtil.machineNameDisplay)(machine.machineName, machine.machineNumber)}`;
            }

            switch (tp) {
              case 'dryer':
                if (machine.machineType.isWasher === true) {
                  total = machine.revenue;
                }

                break;

              case 'washer':
                if (machine.machineType.isDryer === true) {
                  total = machine.revenue;
                }

                break;

              case 'turns':
                total = machine.turns;
            }

            if (total > 0 || total < 0 && total !== undefined) {
              drilldown.data.push({
                name: `${(0, _summaryUtil.machineNameDisplay)(machine.machineName, machine.machineNumber)}`,
                label: label,
                y: total,
                color: COLORS[ct],
                id: machine.id
              });
              ct++;
            }
          });
          results.push(drilldown);
        });
      });
      return results;
    }

    drilldownLegendRooms(drilldownData) {
      let results = [];
      drilldownData.forEach(data => {
        results.push({
          name: data.label,
          color: data.color
        });
      });
      return results;
    }

    get defaultLegend() {
      let totals = this.args.results.roomTotals;
      let results = [];
      let ct = 0;
      totals.forEach(location => {
        results.push({
          name: location.name,
          color: COLORS[ct]
        });
        ct++;
      });
      return results;
    }

    get chartOptions() {
      let subtitleText = ''; //this.intl.t('click_the_columns_for_room_breakdown').string;

      let yAxisLabel = this.intl.t('revenue');
      let tp = this.args.tp;
      let self = this;
      let format = '${value:,.0f}';
      let plotFormat = '${point.y:,.2f}';

      if (tp == 'turns') {
        format = '{value}';
        plotFormat = '{point.y:,.0f}';
        yAxisLabel = this.intl.t('turns');
      }

      return {
        chart: {
          marginTop: 30,
          type: 'column',
          backgroundColor: '#fafcfe',
          events: {
            load: e => {
              this.args.isLoaded();
            },
            render: e => {
              if (e.target.series && e.target.series[0]) {
                let total = 0;
                e.target.series[0].data.forEach(data => {
                  total += data.y;
                });
                this.chartTotal = total;
              }

              Ember.run.later(() => {
                if (!this.isDestroyed) {
                  e.target.reflow();
                }
              }, 220); // about how long it takes for the liquid animation to finish
            },
            drilldown: e => {
              this.isDrilldown = true;
              this.drillDownData = e.seriesOptions.data;
              this.customLegend = e.seriesOptions.customLegend;
              e.target.xAxis[0].update({
                title: {
                  text: e.seriesOptions.axisLabel
                }
              }, false, false);
              e.target.subtitle.update({
                text: e.seriesOptions.subtitleText || ' '
              }, false, false);
              Ember.run.later(() => {
                e.target.redraw();
              }, 550);
            },
            drillup: e => {
              this.isDrilldown = false;
              e.target.xAxis[0].update({
                title: {
                  text: e.seriesOptions.axisLabel
                }
              }, false, false);
              e.target.subtitle.update({
                text: e.seriesOptions.subtitleText || ' '
              }, false, false);
            }
          }
        },
        title: {
          text: ''
        },
        subtitle: {
          text: subtitleText
        },
        xAxis: {
          type: 'category',
          title: {
            text: 'Locations'
          },
          labels: {
            format: '{value}',

            formatter() {
              let str = this.value.toString();
              let strIndx = str.indexOf(':');

              if (strIndx != -1) {
                return str.substr(strIndx + 1);
              }

              return str;
            }

          }
        },
        yAxis: {
          max: this.highestValue,
          title: {
            text: yAxisLabel
          },
          labels: {
            formatter() {
              if (tp !== 'turns') {
                return _currencyUtil.formatCurrencyString.call(self, this.value);
              } else {
                return this.value;
              }
            }

          }
        },
        legend: {
          enabled: false
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              inside: false,

              formatter() {
                if (tp !== 'turns') {
                  return _currencyUtil.formatCurrencyString.call(self, this.y);
                } else {
                  return this.y;
                }
              }

            },
            point: {
              events: {
                click: e => {
                  if (e.point.id) {
                    this.brush.transitionTo('machine', {
                      id: e.point.id,
                      type: 'machine'
                    }, 'show');
                  }
                }
              }
            }
          }
        },
        tooltip: {
          headerFormat: '',
          pointFormat: `<span style="color:{point.color}">{point.facility}</span><b> ${plotFormat}</b>`,

          formatter() {
            if (tp !== 'turns') {
              return _currencyUtil.formatCurrencyString.call(self, this.y);
            } else {
              return this.y;
            }
          }

        },
        drilldown: {
          // For multiple drilldowns call multiple functions and then array merge them into 1 array
          series: this.drilldownDataRooms(),

          formatter() {
            if (tp !== 'turns') {
              return _currencyUtil.formatCurrencyString.call(self, this.y);
            } else {
              return this.y;
            }
          }

        }
      };
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "brush", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isDrilldown", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "drillDownData", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "chartTotal", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "customLegend", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = DashboardChartsDrilldownHighchartsDrilldownComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, DashboardChartsDrilldownHighchartsDrilldownComponent);
});