define("alliance-business-suite/models/machine-current-status", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    access: Ember.inject.service(),
    statusId: attr('string', {
      defaultValue: 'LOADING'
    }),
    linkQualityIndicator: attr('number'),
    remainingSeconds: attr('number'),
    remainingVend: attr('number'),
    createdAt: attr('date'),
    displayStatus: attr(),
    machine: belongsTo('machine'),
    displayStatusMessage: Ember.computed('displayStatus', function () {
      return Ember.get(this, 'displayStatus.values');
    }),
    displayStatusTime: Ember.computed('displayStatus', function () {
      return Ember.get(this, 'displayStatus.times');
    }),
    isAvailable: Ember.computed('statusId', {
      get() {
        return this.statusId === 'AVAILABLE';
      }

    }).readOnly(),
    ordinalPosition: Ember.computed('statusId', {
      get() {
        return ['AVAILABLE', 'READY_TO_START', 'IN_USE', 'LUCKY_CYCLE', 'COMPLETE', 'DOOR_OPEN', 'UNAVAILABLE', 'UNKNOWN', 'ERROR', 'NETWORK_ERROR'].indexOf(this.statusId);
      }

    })
  });

  _exports.default = _default;
});