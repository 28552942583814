define("alliance-business-suite/templates/not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "i0fFEEcV",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"content-container container--not-allowed\"],[12],[2,\"\\n  \"],[10,\"img\"],[14,0,\"scrubbrush-watermark\"],[14,\"src\",\"/assets/images/warning.svg\"],[12],[13],[2,\"\\n  \"],[10,\"h3\"],[14,0,\"scrubbrush-watermark\"],[12],[1,[30,[36,0],[\"page_not_found\"],null]],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,5,\"text-align: center; margin-top:3em;\"],[12],[2,\"\\n\"],[6,[37,2],[[35,1,[\"intent\",\"url\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"p\"],[12],[1,[30,[36,0],[\"not_found_page_custom_message\"],[[\"url\"],[[35,1,[\"intent\",\"url\"]]]]]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"p\"],[12],[1,[30,[36,0],[\"not_found_page_message\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[10,\"p\"],[12],[10,\"a\"],[14,6,\"/\"],[12],[1,[30,[36,0],[\"return_home\"],null]],[13],[2,\" or \"],[10,\"a\"],[14,6,\"/logout\"],[12],[1,[30,[36,0],[\"log_off\"],null]],[13],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"previousTransition\",\"if\"]}",
    "moduleName": "alliance-business-suite/templates/not-found.hbs"
  });

  _exports.default = _default;
});