define("alliance-business-suite/templates/components/ui/t-moment-duration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yuV1KG3z",
    "block": "{\"symbols\":[],\"statements\":[[1,[34,0]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"translationString\"]}",
    "moduleName": "alliance-business-suite/templates/components/ui/t-moment-duration.hbs"
  });

  _exports.default = _default;
});