define("alliance-business-suite/components/btn-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    direction: 'down',
    directionClass: Ember.computed('direction', {
      get() {
        if (this.direction == 'down') {
          return 'dropdown';
        } else {
          return 'dropup';
        }
      }

    })
  });

  _exports.default = _default;
});