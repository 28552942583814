define("alliance-business-suite/templates/components/form-ui/time-plus-minus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GaMagFJk",
    "block": "{\"symbols\":[\"ui\"],\"statements\":[[8,\"form-ui\",[],[[\"@object\",\"@formSettings\",\"@formDisplay\"],[[32,0],[32,0,[\"formSettings\"]],\"compact\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[8,[32,1,[\"number\"]],[],[[\"@object\",\"@update\",\"@params\"],[[32,0],[30,[36,0],[[32,0],\"updateValue\"],null],[30,[36,1],null,[[\"propertyName\",\"label\",\"min\",\"max\"],[\"plusMinusValue\",[32,0,[\"params\",\"label\"]],[32,0,[\"params\",\"min\"]],[32,0,[\"params\",\"max\"]]]]]]],null],[2,\"\\n\\n  \"],[8,\"form-ui/dev-tools\",[],[[\"@outputs\"],[[30,[36,1],null,[[\"labelAutoTranslated\",\"plusProp\",\"minusProp\",\"plusValue\",\"minusValue\",\"type\",\"min\",\"max\",\"rawValue\"],[[32,0,[\"labelAutoTranslated\"]],[32,0,[\"params\",\"properties\",\"plus\"]],[32,0,[\"params\",\"properties\",\"minus\"]],[32,0,[\"plus\"]],[32,0,[\"minus\"]],[32,0,[\"type\"]],[32,0,[\"params\",\"min\"]],[32,0,[\"params\",\"max\"]],[32,0,[\"rawValue\"]]]]]]],null],[2,\"\\n\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"hash\"]}",
    "moduleName": "alliance-business-suite/templates/components/form-ui/time-plus-minus.hbs"
  });

  _exports.default = _default;
});