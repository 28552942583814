define("alliance-business-suite/templates/components/form-ui/steps/debug-step", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "h0Vzw4JO",
    "block": "{\"symbols\":[\"value\",\"name\"],\"statements\":[[10,\"div\"],[14,0,\"dev-tools\"],[12],[2,\"\\n  \"],[10,\"p\"],[12],[2,\"Step Properties\"],[13],[2,\"\\n  \"],[10,\"ul\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[35,0]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"li\"],[12],[1,[32,2]],[2,\" = \"],[1,[32,1]],[13],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"stepModel\",\"-each-in\",\"each\"]}",
    "moduleName": "alliance-business-suite/templates/components/form-ui/steps/debug-step.hbs"
  });

  _exports.default = _default;
});