define("alliance-business-suite/components/machines/modal-advance-to-cycle-alt", ["exports", "alliance-business-suite/components/mixins/machine-controls"], function (_exports, _machineControls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const GOLD_PLUS_WX = {
    'start_cycle': [0, 0],
    'next_step': [0, 255],
    'next_fill': [0, 2],
    'next_agitate_soak': [0, 4],
    'next_cool_down_drain_extract': [0, 6],
    'next_hold': [0, 11],
    'last_cool_down': [0, 90],
    'end_of_cycle': [0, 10]
  };
  const MIDAS_WX = {
    'start_cycle': [0, 0],
    'next_step': [0, 255],
    'next_fill': [0, 2],
    'next_agitate_soak': [0, 4],
    'next_cool_down_drain_extract': [0, 6],
    'next_hold': [0, 11],
    'last_cool_down': [0, 90],
    'end_of_cycle': [0, 10]
  };
  const GOLD_PLUS_FLW = {
    'start_cycle': [0, 255],
    'next_step': [0, 255],
    'next_fill': [0, 2],
    'next_agitate_soak': [0, 4],
    'next_cool_down_drain_extract': [0, 6],
    'next_hold': [0, 11],
    'last_cool_down': [0, 90],
    'end_of_cycle': [0, 26]
  };
  const PLAT_FLW = {
    'start_cycle': [0, 255],
    'next_step': [0, 255],
    'next_fill': [0, 2],
    'next_agitate_soak': [0, 4],
    'next_cool_down_drain_extract': [0, 6],
    'next_hold': [0, 11],
    'last_cool_down': [0, 90],
    'end_of_cycle': [0, 10]
  };
  const GOLD_WX = {
    'segment_1_Fill': [1, 2],
    'segment_1_Supply': [1, 3],
    'segment_1_Agitate': [1, 5],
    'segment_1_Drain': [1, 7],
    'segment_2_Fill': [2, 2],
    'segment_2_Supply': [2, 3],
    'segment_2_Agitate': [2, 5],
    'segment_2_Drain': [2, 7],
    'segment_3_Fill': [3, 2],
    'segment_3_Supply': [3, 3],
    'segment_3_Agitate': [3, 5],
    'segment_3_Drain': [3, 7],
    'segment_4_Fill': [4, 2],
    'segment_4_Supply': [4, 3],
    'segment_4_Agitate': [4, 5],
    'segment_4_Drain': [4, 7],
    'segment_5_Fill': [5, 2],
    'segment_5_Supply': [5, 3],
    'segment_5_Agitate': [5, 5],
    'segment_5_Drain': [5, 7],
    'segment_6_Fill': [6, 2],
    'segment_6_Supply': [6, 3],
    'segment_6_Agitate': [6, 5],
    'segment_6_Drain': [6, 7],
    'segment_7_Fill': [7, 2],
    'segment_7_Supply': [7, 3],
    'segment_7_Agitate': [7, 5],
    'segment_7_Drain': [7, 7],
    'segment_8_Fill': [8, 2],
    'segment_8_Supply': [8, 3],
    'segment_8_Agitate': [8, 5],
    'segment_8_Drain': [8, 7],
    'end_of_cycle': [7, 11]
  };
  const GOLD_FLW = {
    'Wash_1_Fill_Agitate': [0, 0],
    'Wash_1_Drain': [0, 1],
    'Wash_1_Rinse_Spin': [0, 2],
    'Wash_2_Fill_Agitate': [0, 3],
    'Wash_2_Drain': [0, 4],
    'Wash_2_Rinse_Spin': [0, 5],
    'Wash_3_Fill_Agitate': [0, 6],
    'Wash_3_Drain': [0, 7],
    'Wash_3_Rinse_Spin': [0, 8],
    'Wash_4_Fill_Agitate': [0, 9],
    'Wash_4_Drain': [0, 10],
    'Wash_4_Rinse_Spin': [0, 11],
    'Rinse_1_Fill_Agitate': [0, 12],
    'Rinse_1_Drain': [0, 13],
    'Rinse_1_Rinse_Spin': [0, 14],
    'Rinse_2_Fill_Agitate': [0, 15],
    'Rinse_2_Drain': [0, 16],
    'Rinse_2_Rinse_Spin': [0, 17],
    'Rinse_3_Fill_Agitate': [0, 18],
    'Rinse_3_Drain': [0, 19],
    'Rinse_3_Rinse_Spin': [0, 20],
    'Rinse_4_Fill_Agitate': [0, 21],
    'Rinse_4_Drain': [0, 22],
    'Rinse_4_Rinse_Spin': [0, 23],
    'Final_High_spin': [0, 24],
    'End_of_cycle': [0, 26]
  };
  const GOLD_TLW = {
    'Wash_Fill_Agitate': [0, 0],
    'Wash_Pause': [0, 1],
    'Wash_Spin_1': [0, 2],
    'Wash_Spin_spray': [0, 3],
    'Wash_Spin_2': [0, 4],
    'Extra_rinse_Fill_Agitate': [0, 5],
    'Extra_rinse_Pause': [0, 6],
    'Extra_rinse_Spin_1': [0, 7],
    'Rinse': [0, 8],
    'Final_spin': [0, 11],
    'End_of_cycle': [0, 255]
  };
  const GOLD_PLUS_TLW = {
    'wash_fill': [1, 1],
    'wash_agitate': [1, 2],
    'wash_pause': [1, 3],
    'wash_next_spin': [1, 6],
    'wash_spin_spray': [1, 7],
    'rinse': [2, 0],
    'extra_rinse_fill': [3, 1],
    'extra_rinse_agitate': [3, 2],
    'extra_rinse_pause': [3, 3],
    'extra_rinse_next_spin_1': [3, 6],
    'final_spin': [4, 0],
    'end_of_cycle': [0, 0]
  };
  let ComponentClass = Ember.Component.extend(_machineControls.default, {
    classNames: ['machine-view'],
    ajax: Ember.inject.service(),
    access: Ember.inject.service(),
    intl: Ember.inject.service(),
    notifications: Ember.inject.service(),
    store: Ember.inject.service(),
    firebaseCommands: Ember.inject.service('firebase-commands'),
    lastUpdated: true,
    noCyclesFound: true,

    didInsertElement() {
      this._super(...arguments);
    },

    options: Ember.computed('machine', function () {
      let generation = this.get('machine.machineAuditGroupType.id');
      let grouping = this.get('machine.machineType.machineGrouping');
      let options = [];
      let key = null;

      if (grouping == 'washer-extractor') {
        if (generation == 'QUANTUM_GOLD+' || generation == 'GOLD+WITH_6_CYCLE') {
          key = GOLD_PLUS_WX;
        }

        if (generation == 'GALAXY_600+') {
          key = GOLD_PLUS_WX;
        }

        if (generation == 'QUANTUM_GOLD') {
          key = GOLD_WX;
        }

        if (generation == 'GALAXY_600') {
          key = GOLD_WX;
        }

        if (generation == 'MIDAS') {
          key = MIDAS_WX;
        }
      }

      if (grouping == 'front-load-washer') {
        if (generation == 'QUANTUM_GOLD+') {
          key = GOLD_PLUS_FLW;
        }

        if (generation == 'GALAXY_600+') {
          key = GOLD_PLUS_FLW;
        }

        if (generation == 'QUANTUM_GOLD') {
          // console.error('confirm cycle value for last two props!');
          key = GOLD_FLW;
        }

        if (generation == 'QUANTUM_PLATINUM') {
          key = PLAT_FLW;
        }
      }

      if (grouping == 'top-load-washer') {
        if (generation == 'QUANTUM_GOLD+') {
          key = GOLD_PLUS_TLW;
        }

        if (generation == 'QUANTUM_GOLD') {
          key = GOLD_TLW;
        }
      }

      for (let prop in key) {
        let obj = {};
        obj.text = prop;
        obj.id = prop;
        obj.value = key[prop];
        options.push(obj);
      }

      console.log('generation', generation);
      console.log('grouping', grouping);
      console.log('key', key);
      console.log('options', options);
      return options;
    }),
    actions: {
      close() {
        let machine = Ember.get(this, 'machine');

        if (!Ember.get(this, 'isLoading')) {
          this.close();

          if (machine) {
            machine.rollbackAttributes();
          }
        }
      },

      updatedSelected(selectedCycle) {
        if (selectedCycle) {
          this.set('noCyclesFound', false);
        }
      },

      apply(selectedCycle) {
        let cycleObject = selectedCycle ? selectedCycle : [];
        this.set('isLoading', true);
        this.ajax.request(`/machines/${Ember.get(this, 'machineId')}/advanceWasherToCycle`, {
          data: {
            params: {
              'step': cycleObject[1],
              'cycle': cycleObject[0]
            }
          },
          method: 'POST'
        }).then(response => {
          this.close();
          this.notifications.info(this.intl.t('your_command_has_been_sent_to_the_machine'), {
            autoClear: true,
            clearDuration: 60000
          });
        });
      }

    }
  });
  ComponentClass.reopenClass({
    positionalParams: ['model']
  });
  var _default = ComponentClass;
  _exports.default = _default;
});