define("alliance-business-suite/templates/components/form-ui/steps/aux-output", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kw4NQH8F",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[10,\"h2\"],[12],[1,[30,[36,0],[\"auxillary_output\"],null]],[13],[2,\"\\n\\n\"],[8,\"form-ui\",[],[[\"@object\",\"@machineProgram\",\"@formSettings\",\"@selectOptions\"],[[32,0,[\"object\"]],[32,0,[\"machineProgram\"]],[32,0,[\"formSettings\"]],[32,0,[\"selectOptions\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[8,[32,1,[\"checkbox\"]],[],[[\"@params\"],[[30,[36,1],null,[[\"propertyName\",\"labelKey\"],[[32,0,[\"properties\",\"AuxOutputsI\",\"key\"]],\"aux_output_i\"]]]]],null],[2,\"\\n\\n  \"],[8,[32,1,[\"checkbox\"]],[],[[\"@params\"],[[30,[36,1],null,[[\"propertyName\",\"labelKey\"],[[32,0,[\"properties\",\"AuxOutputsJ\",\"key\"]],\"aux_output_j\"]]]]],null],[2,\"\\n\\n  \"],[8,[32,1,[\"checkbox\"]],[],[[\"@params\"],[[30,[36,1],null,[[\"propertyName\",\"labelKey\"],[[32,0,[\"properties\",\"AuxOutputsK\",\"key\"]],\"aux_output_k\"]]]]],null],[2,\"\\n\\n  \"],[8,[32,1,[\"checkbox\"]],[],[[\"@params\"],[[30,[36,1],null,[[\"propertyName\",\"labelKey\"],[[32,0,[\"properties\",\"AuxOutputsL\",\"key\"]],\"aux_output_l\"]]]]],null],[2,\"\\n\\n  \"],[8,[32,1,[\"checkbox\"]],[],[[\"@params\"],[[30,[36,1],null,[[\"propertyName\",\"labelKey\"],[[32,0,[\"properties\",\"AuxOutputsM\",\"key\"]],\"aux_output_m\"]]]]],null],[2,\"\\n  \"],[8,[32,1,[\"checkbox\"]],[],[[\"@params\"],[[30,[36,1],null,[[\"propertyName\",\"labelKey\"],[[32,0,[\"properties\",\"AuxOutputsN\",\"key\"]],\"aux_output_n\"]]]]],null],[2,\"\\n\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"hash\"]}",
    "moduleName": "alliance-business-suite/templates/components/form-ui/steps/aux-output.hbs"
  });

  _exports.default = _default;
});