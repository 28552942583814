define("alliance-business-suite/utils/format-currency", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global Big */
  var _default = (value, options) => {
    let {
      currencySymbol,
      currencySymbolPosition,
      decimalSymbol,
      thousandsSymbol,
      divideBy,
      decimals
    } = options;

    if (value === undefined || value === null) {
      console.error("Value is undefined or null");
      return "---";
    }

    value = new Big(value).div(10 ** decimals);
    let displayValue = value.toFormat(thousandsSymbol, decimalSymbol, decimals);
    let output = '';

    if (currencySymbolPosition == "before") {
      output = `${currencySymbol}${displayValue}`;
    } else {
      output = `${displayValue}${currencySymbol}`;
    }

    return output;
  };

  _exports.default = _default;
});