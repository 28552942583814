define("alliance-business-suite/mixins/machines/programs/acas-options", ["exports", "alliance-business-suite/utils/speed-utilities", "alliance-business-suite/utils/machine-programming-property-names", "alliance-business-suite/utils/led", "alliance-business-suite/mixins/machines/programs/validate-options", "alliance-business-suite/utils/custom-hold-message", "alliance-business-suite/utils/machine-programming-find-options"], function (_exports, _speedUtilities, _machineProgrammingPropertyNames, _led, _validateOptions, _customHoldMessage, _machineProgrammingFindOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_validateOptions.default, {
    // eslint-disable-line
    // Added to get A3 FLW working
    useG: Ember.computed.alias('machineProgram._showG'),
    // Requires model, object (machineProgram.settings)
    machineSettings: Ember.computed.alias('machineProgram._machineSettings'),
    cycleCount: Ember.computed('machineAuditType', function () {
      // The A4 has 9 cycles. Others have 6.
      switch (this.machineAuditType) {
        case "CENTURION_C4":
        case "CENTURION_C4_ALS_DRIVE":
        case "ACAS_A4":
        case "TITAN_T4":
        case "MGD_M1":
          return 9;

        case "ACAS_A22":
          return 7;

        case "CENTURION_C3":
        case "CENTURION_C3_ALS_DRIVE":
        case "ACAS_A2":
        case "TITAN_T2":
          return 6;

        case "TITAN_T1":
          return 5;

        default:
          return 6;
        // This should probably be 9, once all the machines are listed above...or attempt to discover from Cycles array.
      }
    }),
    // Special Vend
    indexValues: Ember.computed("machineAuditType", function () {
      switch (this.machineAuditType) {
        case "ACAS_A44":
        case "ACAS_A2":
        case "TITAN_T2":
        case "TITAN_T1":
          return [0, 1];

        case "CENTURION_C4":
        case "CENTURION_C4_ALS_DRIVE":
          return ["A", "B", "C", "D"];

        case "CENTURION_C3":
        case "CENTURION_C3_ALS_DRIVE":
          return ["A", "B"];

        default:
          return [0, 1, 2, 3];
      }
    }),
    // HB machines have "Bonus Cycle" and not "Lucky Cycle"
    luckyCycleLabel: Ember.computed('machineAuditType', 'machineProgram._machineAttributes', function () {
      if (this.machineProgram._machineAttributes.includes("HUEBSCH")) {
        return "bonus_cycle";
      } else {
        return "lucky_cycle";
      }
    }),
    labelsModifierOptions: Ember.computed('machineAuditType', function () {
      switch (this.machineAuditType) {
        case "ACAS_A2":
        case "TITAN_T2":
          return {
            mod1: "mod_1_active_state",
            mod2: "mod_2_active_state"
          };

        case "ACAS_A22":
          return {
            mod1: "include_mod_1_steps",
            mod2: "include_mod_2_steps"
          };

        case "CENTURION_C4":
        case "CENTURION_C3":
        case "CENTURION_C4_ALS_DRIVE":
        case "CENTURION_C3_ALS_DRIVE":
          return {
            mod1: "prewash",
            mod2: "extra_rinse"
          };

        default:
          return {
            mod1: "include_mod_1_prewash",
            mod2: "include_mod_2_extra_rinse"
          };
      }
    }),
    optionsDefaultCycles: (0, _machineProgrammingFindOptions.findOptions)([{
      machineAttributes: ["MGD_M1"],
      options: [{
        val: 1,
        label: 'normal_hot',
        index: 0
      }, {
        val: 2,
        label: 'normal_warm',
        index: 1
      }, {
        val: 3,
        label: 'normal_cold',
        index: 2
      }, {
        val: 4,
        label: 'perm_press_hot',
        index: 3
      }, {
        val: 5,
        label: 'perm_press_warm',
        index: 4
      }, {
        val: 6,
        label: 'perm_press_cold',
        index: 5
      }, {
        val: 7,
        label: 'delicate_hot',
        index: 6
      }, {
        val: 8,
        label: 'delicate_warm',
        index: 7
      }, {
        val: 9,
        label: 'delicate_cold',
        index: 8
      }]
    }, {
      machineAttributes: ["ACAS_A44"],
      // 6 cycles
      options: [{
        val: 1,
        label: 'normal_hot'
      }, {
        val: 2,
        label: 'normal_warm'
      }, {
        val: 3,
        label: 'normal_cold'
      }, {
        val: 4,
        label: 'delicate_hot'
      }, {
        val: 5,
        label: 'delicate_warm'
      }, {
        val: 6,
        label: 'delicate_cold'
      }]
    }, {
      machineAttributes: ["ACAS_A2", "TITAN_T2", "CENTURION_C3"],
      // 6 cycles
      options: [{
        val: 1,
        label: 'normal_hot'
      }, {
        val: 2,
        label: 'normal_warm'
      }, {
        val: 3,
        label: 'normal_cold'
      }, {
        val: 4,
        label: 'blankets_cold'
      }, {
        val: 5,
        label: 'delicate_warm'
      }, {
        val: 6,
        label: 'delicate_cold'
      }]
    }, {
      machineAttributes: ["ACAS_A22"],
      // 7 cycles
      options: [{
        val: 1,
        label: 'normal_90'
      }, {
        val: 2,
        label: 'normal_60'
      }, {
        val: 3,
        label: 'normal_40'
      }, {
        val: 4,
        label: 'perm_press_90'
      }, {
        val: 5,
        label: 'perm_press_60'
      }, {
        val: 6,
        label: 'gentle_30'
      }, {
        val: 7,
        label: 'perm_press_50'
      }]
    }, {
      machineAttributes: ["ACAS_A3", "CENTURION_C3"],
      // International 6 cycles
      options: [{
        val: 1,
        label: 'normal_90'
      }, {
        val: 2,
        label: 'normal_60'
      }, {
        val: 3,
        label: 'normal_40'
      }, {
        val: 4,
        label: 'perm_press_60'
      }, {
        val: 5,
        label: 'delicate_30'
      }, {
        val: 6,
        label: 'delicate_no_heat'
      }]
    }, {
      machineAttributes: ["ACAS_A4", "MGD_M1", "CENTURION_C4"],
      // Domestic 9 cycles
      options: [{
        val: 1,
        label: 'normal_hot'
      }, {
        val: 2,
        label: 'normal_warm'
      }, {
        val: 3,
        label: 'normal_cold'
      }, {
        val: 4,
        label: 'perm_press_hot'
      }, {
        val: 5,
        label: 'perm_press_warm'
      }, {
        val: 6,
        label: 'perm_press_cold'
      }, {
        val: 7,
        label: 'delicate_hot'
      }, {
        val: 8,
        label: 'delicate_warm'
      }, {
        val: 9,
        label: 'delicate_cold'
      }]
    }, {
      machineAttributes: ["TITAN_T4"],
      // Domestic 9 cycles
      options: [{
        val: 1,
        label: 'normal_hot'
      }, {
        val: 2,
        label: 'normal_warm'
      }, {
        val: 3,
        label: 'normal_cold'
      }, {
        val: 4,
        label: 'synthetic_hot'
      }, {
        val: 5,
        label: 'synthetic_warm'
      }, {
        val: 6,
        label: 'synthetic_cold'
      }, {
        val: 7,
        label: 'delicate_hot'
      }, {
        val: 8,
        label: 'delicate_warm'
      }, {
        val: 9,
        label: 'delicate_cold'
      }]
    }, {
      machineAttributes: ["TITAN_T1"],
      // International 6 cycles
      options: [{
        val: 1,
        label: 'normal_hot'
      }, {
        val: 2,
        label: 'normal_warm'
      }, {
        val: 3,
        label: 'normal_cold'
      }, {
        val: 4,
        label: 'perm_press_warm'
      }, {
        val: 5,
        label: 'delicate_cold'
      }]
    }]),
    optionsDefaultModifiers: (0, _machineProgrammingFindOptions.findOptions)([{
      machineAttributes: ["TITAN_T1_FLW"],
      // Removed TITAN_T2
      options: [{
        val: 0,
        label: 'regular',
        abbrev: 'regular_abbrev'
      }, {
        val: 1,
        label: 'deluxe',
        abbrev: 'deluxe_abbrev'
      }, {
        val: 2,
        label: 'ultra',
        abbrev: 'ultra_abbrev'
      }]
    }, {
      machineAttributes: ["ACAS_A4_FLW", "TITAN_T4_FLW", "MGD_M1_FLW"],
      options: [{
        val: 0,
        label: 'light',
        abbrev: 'light_abbrev'
      }, {
        val: 1,
        label: 'medium',
        abbrev: 'medium_abbrev'
      }, {
        val: 2,
        label: 'heavy',
        abbrev: 'heavy_abbrev'
      }]
    }, {
      machineAttributes: ["ACAS_A2_FLW", "ACAS_A22_FLW", "TITAN_T2_FLW"],
      options: [{
        val: 0,
        label: 'none',
        abbrev: 'none_abbrev'
      }, {
        val: 1,
        label: 'extra_wash',
        abbrev: 'extra_wash_abbrev'
      }, {
        val: 2,
        label: 'extra_rinse',
        abbrev: 'extra_rinse_abbrev'
      }, {
        val: 3,
        label: 'extra_wash_extra_rinse',
        abbrev: 'extra_wash_extra_rinse_abbrev'
      }]
    }, {
      machineAttributes: ["ACAS_A3_FLW"],
      options: [{
        val: 0,
        label: 'A',
        abbrev: 'a_abbrev'
      }, {
        val: 1,
        label: 'B',
        abbrev: 'b_abbrev'
      }, {
        val: 2,
        label: 'C',
        abbrev: 'c_abbrev'
      }]
    }, {
      machineAttributes: ["ACAS_A4", "ACAS_A44", "TITAN_T4", "CENTURION_C4"],
      options: [{
        val: 0,
        label: 'regular',
        abbrev: 'regular_abbrev'
      }, {
        val: 1,
        label: 'deluxe',
        abbrev: 'deluxe_abbrev'
      }, {
        val: 2,
        label: 'ultra',
        abbrev: 'ultra_abbrev'
      }]
    }, {
      machineAttributes: ["ACAS_A2", "ACAS_A22", "TITAN_T2"],
      options: [{
        val: 0,
        label: 'none',
        abbrev: 'none_abbrev'
      }, {
        val: 1,
        label: 'extra_wash',
        abbrev: 'extra_wash_abbrev'
      }, {
        val: 2,
        label: 'extra_rinse',
        abbrev: 'extra_rinse_abbrev'
      }, {
        val: 3,
        label: 'extra_wash_extra_rinse',
        abbrev: 'extra_wash_extra_rinse_abbrev'
      }]
    }, {
      machineAttributes: ["ACAS_A3", "CENTURION_C3"],
      options: [{
        val: 0,
        label: 'A',
        abbrev: 'a_abbrev'
      }, {
        val: 1,
        label: 'B',
        abbrev: 'b_abbrev'
      }, {
        val: 2,
        label: 'C',
        abbrev: 'c_abbrev'
      }]
    }]),
    customMessage1: Ember.computed('machineProgram.{_machineAuditTypeFamily,settings.Program.Cycle.CustomHoldMessageDisplay1Digits,settings.Program.cycleRelatedMessage.customMessage1,settings.Program.Display.CustomHoldMessageBuffer1}', function () {
      switch (this.machineProgram._machineAuditTypeFamily) {
        case "ACAS_A4":
        case "ACAS_A3":
        case "ACAS_A2":
        case "ACAS_A22":
          return (0, _led.ledCodeToString)(this.machineProgram.settings.Program.Cycle.CustomHoldMessageDisplay1Digits);

        case "MGD":
          return (0, _customHoldMessage.base64ToUnicode)(this.machineProgram.settings.Program.Display.CustomHoldMessageBuffer1);

        case "TITAN_T4":
        case "TITAN_T2":
          return (0, _led.ledCodeToString)(this.machineProgram.settings.Program.cycleRelatedMessage.customMessage1);

        default:
          return null;
      }
    }),
    customMessage2: Ember.computed('machineProgram.{_machineAuditTypeFamily,settings.Program.Cycle.CustomHoldMessageDisplay2Digits,settings.Program.cycleRelatedMessage.customMessage2,settings.Program.Display.CustomHoldMessageBuffer2}', function () {
      switch (this.machineProgram._machineAuditTypeFamily) {
        case "ACAS_A4":
        case "ACAS_A3":
        case "ACAS_A2":
        case "ACAS_A22":
          return (0, _led.ledCodeToString)(this.machineProgram.settings.Program.Cycle.CustomHoldMessageDisplay2Digits);

        case "MGD":
          return (0, _customHoldMessage.base64ToUnicode)(this.machineProgram.settings.Program.Display.CustomHoldMessageBuffer2);

        case "TITAN_T4":
        case "TITAN_T2":
          return (0, _led.ledCodeToString)(this.machineProgram.settings.Program.cycleRelatedMessage.customMessage2);

        default:
          return null;
      }
    }),
    basketDiameter: Ember.computed.alias('machineSettings.basketDiameter'),

    // TODO: Possible code smell. This breaks for pricing tab, but works for all others.
    getSpeedPreset(name, index) {
      let array = Ember.get(this, name);

      if (array && array.length > index) {
        return array[index];
      } else {
        console.error("Preset value not found for %s, index %s.", name, index); // eslint-disable-line no-console

        return 0;
      }
    },

    globalValues: Ember.computed('machineProgram.settings', function () {
      // Gets the global water level for matching property names
      let returnValue = {
        highWaterLevel: (0, _machineProgrammingPropertyNames.definedPropertyValue)(this.machineProgram.settings, ["Program.Cycle.HighWaterLevel", "Program.cycleRelatedMessage.highWaterLevel", "Program.Cycle.HighWaterLevel"]),
        mediumWaterLevel: (0, _machineProgrammingPropertyNames.definedPropertyValue)(this.machineProgram.settings, ["Program.Cycle.MediumWaterLevel", "Program.cycleRelatedMessage.mediumWaterLevel", "Program.Cycle.MediumWaterLevel"]),
        lowWaterLevel: (0, _machineProgrammingPropertyNames.definedPropertyValue)(this.machineProgram.settings, ["Program.Cycle.LowWaterLevel", "Program.cycleRelatedMessage.lowWaterLevel", "Program.Cycle.LowWaterLevel"]),
        hotWaterTemp: (0, _machineProgrammingPropertyNames.definedPropertyValue)(this.machineProgram.settings, ["Program.Cycle.HotWaterTemperature", "Program.cycleRelatedMessage.hotWaterTemperature", "Program.Cycle.HotWaterTemp"]),
        warmWaterTemp: (0, _machineProgrammingPropertyNames.definedPropertyValue)(this.machineProgram.settings, ["Program.Cycle.WarmWaterTemperature", "Program.cycleRelatedMessage.warmWaterTemperature", "Program.Cycle.WarmWaterTemp"]),
        coldWaterTemp: (0, _machineProgrammingPropertyNames.definedPropertyValue)(this.machineProgram.settings, ["Program.Cycle.ColdWaterTemperature", "Program.cycleRelatedMessage.coldWaterTemperature", "Program.Cycle.ColdWaterTemp"]),
        cooldownWaterTemp: (0, _machineProgrammingPropertyNames.definedPropertyValue)(this.machineProgram.settings, ["Program.Cycle.CoolDownWaterTemperature", "Program.cycleRelatedMessage.coolDownWaterTemperature", "Program.Cycle.CoolDownWaterTemp"])
      };
      return returnValue;
    }),
    optionsConfig: Ember.computed(function () {
      // eslint-disable-line ember/require-computed-property-dependencies
      return [{
        name: "ActiveState",
        conditional: true,
        options: [{
          val: 0,
          label: 'Disabled'
        }, {
          val: 1,
          label: 'Mod1'
        }, {
          val: 2,
          label: 'Mod2'
        }, {
          val: 3,
          label: 'off_with_speed_cycle',
          ifMachine: "MGD_M1"
        }, {
          val: 8,
          label: 'Enabled'
        }]
      }, {
        name: "StepLabel",
        options: [{
          val: 0,
          label: "PreWash",
          group: "Prewash"
        }, {
          val: 1,
          label: "Wash",
          group: "Wash"
        }, {
          val: 7,
          label: "Bleach",
          group: "Bleach",
          ifMachine: "MGD_M1"
        }, {
          val: 2,
          label: "Rinse1",
          group: "Rinse"
        }, {
          val: 3,
          label: "Rinse2",
          group: "Rinse"
        }, {
          val: 4,
          label: "Rinse3",
          group: "Rinse"
        }, {
          val: 5,
          label: "Rinse4",
          group: "Rinse"
        }, {
          val: 6,
          label: "Spin",
          group: "Spin"
        }]
        /*
        Midas Enum:
          Prewash1 = 0,
          Wash1,
          Rinse1,
          Rinse2,
          Rinse3,
          Rinse4,
          Spin,
          Bleach,
          Prewash2,
          Prewash3,
          Wash2,
          Wash3,
          Wash4
        */

      }, {
        name: "WaterLevel",
        conditional: true,
        options: [// was FillWaterLevel
        {
          val: 126,
          label: 'High',
          presetValue: this.globalValues.highWaterLevel
        }, {
          val: 125,
          label: 'Medium',
          presetValue: this.globalValues.mediumWaterLevel
        }, {
          val: 124,
          label: 'Low',
          presetValue: this.globalValues.lowWaterLevel
        }, {
          val: 0,
          label: 'Custom',
          isCustom: true,
          notMachine: ["IPSO_ACAS_A2_WX", "ACAS_A22", "ACAS_A2", "TITAN_T2"]
        }]
      }, {
        name: "FillWaterLevel",
        options: [// was FillWaterLevel
        {
          val: 126,
          label: 'High',
          presetValue: this.globalValues.highWaterLevel
        }, {
          val: 125,
          label: 'Medium',
          presetValue: this.globalValues.mediumWaterLevel
        }, {
          val: 124,
          label: 'Low',
          presetValue: this.globalValues.lowWaterLevel
        }, {
          val: 0,
          label: 'Custom',
          isCustom: true
        }]
      }, {
        name: "WaterLevelOverflow",
        conditional: true,
        options: [{
          val: 127,
          label: 'Overflow',
          notMachine: ["IPSO_ACAS_A3_FLW", "MGD_M1_FLW", "ACAS_A2", "TITAN_T2"]
        }, {
          val: 126,
          label: 'High',
          presetValue: this.globalValues.highWaterLevel
        }, {
          val: 125,
          label: 'Medium',
          presetValue: this.globalValues.mediumWaterLevel
        }, {
          val: 124,
          label: 'Low',
          presetValue: this.globalValues.lowWaterLevel
        }, {
          val: 0,
          label: 'Custom',
          isCustom: true,
          notMachine: ["IPSO_ACAS_A2_WX", "ACAS_A22", "ACAS_A2", "TITAN_T2"]
        }]
      }, {
        name: "WaterValvesControlled",
        options: [{
          label: "Cold",
          val: 0,
          hotFill: false,
          coldFill: true,
          controlledFill: false
        }, {
          label: "Warm",
          val: 1,
          hotFill: true,
          coldFill: true,
          controlledFill: false
        }, {
          label: "Hot",
          val: 2,
          hotFill: true,
          coldFill: false,
          controlledFill: false
        }, {
          label: "ControlledFill",
          val: 3,
          hotFill: false,
          coldFill: false,
          controlledFill: true
        }]
      }, {
        name: "WaterValves",
        options: [{
          label: "Cold",
          val: 0,
          hotFill: false,
          coldFill: true
        }, {
          label: "Warm",
          val: 1,
          hotFill: true,
          coldFill: true
        }, {
          label: "Hot",
          val: 2,
          hotFill: true,
          coldFill: false
        }]
      }, {
        name: "HeaterOptionsTemperature",
        conditional: true,
        options: [{
          val: 2,
          label: 'global_hot_temp',
          presetValue: this.globalValues.hotWaterTemp
        }, {
          val: 1,
          label: 'global_warm_temp',
          presetValue: this.globalValues.warmWaterTemp
        }, {
          val: 0,
          label: 'global_cold_temp',
          presetValue: this.globalValues.coldWaterTemp
        }, {
          val: 3,
          label: 'Custom',
          isCustom: true,
          notMachine: ["ACAS_A2", "ACAS_A22", "IPSO_ACAS_A2_WX", "TITAN_T2"]
        } // use custom value, stored in F
        ]
      }, {
        name: "SpinSpeedReuseFill",
        conditional: true,
        options: [{
          val: 1,
          label: 'Low',
          presetValue: this.machineSettings.agitate.presets[0],
          g: this.machineSettings.agitate?.presets[0],
          rpm: (0, _speedUtilities.convertGtoRPM)(this.basketDiameter, this.machineSettings.agitate?.presets[0], 10, 50)
        }, {
          val: 2,
          label: 'Regular',
          presetValue: this.machineSettings.agitate.presets[1],
          g: this.machineSettings.agitate.presets[1],
          rpm: (0, _speedUtilities.convertGtoRPM)(this.basketDiameter, this.machineSettings.agitate.presets[1], 10, 50)
        }, {
          val: 255,
          label: 'Custom',
          isCustom: true,
          notMachine: ["ACAS_A2", "ACAS_A22", "TITAN_T2"]
        }, {
          val: 0,
          label: 'Disabled',
          g: 0,
          rpm: 0,
          presetValue: null
        }]
      }, {
        name: "SpinSpeedAgitate",
        conditional: true,
        options: [{
          val: 1,
          label: 'Low',
          presetValue: this.machineSettings.agitate.presets[0],
          g: this.machineSettings.agitate.presets[0],
          rpm: (0, _speedUtilities.convertGtoRPM)(this.basketDiameter, this.machineSettings.agitate.presets[0], 10, 50)
        }, {
          val: 2,
          label: 'Regular',
          presetValue: this.machineSettings.agitate.presets[1],
          g: this.machineSettings.agitate.presets[1],
          rpm: (0, _speedUtilities.convertGtoRPM)(this.basketDiameter, this.machineSettings.agitate.presets[1], 10, 50)
        }, {
          val: 255,
          label: 'Custom',
          isCustom: true,
          notMachine: ["ACAS_A2", "ACAS_A22", "TITAN_T2"]
        }, {
          val: 0,
          label: 'Disabled',
          g: 0,
          rpm: 0,
          presetValue: null
        }]
      }, {
        name: "SpinSpeedExtract",
        conditional: true,
        options: [{
          val: 1,
          label: 'VeryLow',
          presetValue: this.machineSettings.extract.presets[0],
          g: this.machineSettings.extract.presets[0],
          rpm: (0, _speedUtilities.convertGtoRPM)(this.basketDiameter, this.machineSettings.extract.presets[0])
        }, {
          val: 2,
          label: 'Low',
          presetValue: this.machineSettings.extract.presets[1],
          g: this.machineSettings.extract.presets[1],
          rpm: (0, _speedUtilities.convertGtoRPM)(this.basketDiameter, this.machineSettings.extract.presets[1])
        }, {
          val: 3,
          label: 'Medium',
          presetValue: this.machineSettings.extract.presets[2],
          g: this.machineSettings.extract.presets[2],
          rpm: (0, _speedUtilities.convertGtoRPM)(this.basketDiameter, this.machineSettings.extract.presets[2])
        }, {
          val: 4,
          label: 'High',
          presetValue: this.machineSettings.extract.presets[3],
          g: this.machineSettings.extract.presets[3],
          rpm: (0, _speedUtilities.convertGtoRPM)(this.basketDiameter, this.machineSettings.extract.presets[3])
        }, {
          val: 5,
          label: 'VeryHigh',
          presetValue: this.machineSettings.extract.presets[4],
          g: this.machineSettings.extract.presets[4],
          rpm: (0, _speedUtilities.convertGtoRPM)(this.basketDiameter, this.machineSettings.extract.presets[4])
        }, {
          val: 6,
          label: 'UltraHigh',
          presetValue: this.machineSettings.extract.presets[5],
          g: this.machineSettings.extract.presets[5],
          rpm: (0, _speedUtilities.convertGtoRPM)(this.basketDiameter, this.machineSettings.extract.presets[5])
        }, {
          val: 7,
          label: 'Custom',
          isCustom: true,
          notMachine: ["ACAS_A2", "ACAS_A22", "TITAN_T2", "TITAN_T1"]
        } // Yes, custom is NOT 255 for extract
        ]
      }, {
        name: "SpinSpeedDrain",
        conditional: true,
        options: [{
          val: 1,
          label: 'Low',
          presetValue: this.machineSettings.drain.presets[0],
          g: this.machineSettings.drain.presets[0],
          rpm: (0, _speedUtilities.convertGtoRPM)(this.basketDiameter, this.machineSettings.drain.presets[0], 10, 50)
        }, {
          val: 2,
          label: 'Regular',
          presetValue: this.machineSettings.drain.presets[1],
          g: this.machineSettings.drain.presets[1],
          rpm: (0, _speedUtilities.convertGtoRPM)(this.basketDiameter, this.machineSettings.drain.presets[1], 10, 50)
        }, {
          val: 3,
          label: 'Distribution',
          presetValue: this.machineSettings.drain.presets[2],
          g: this.machineSettings.drain.presets[2],
          rpm: (0, _speedUtilities.convertGtoRPM)(this.basketDiameter, this.machineSettings.drain.presets[2], 10, 50)
        }, {
          val: 255,
          label: 'Custom',
          isCustom: true,
          notMachine: ["ACAS_A2", "ACAS_A22", "TITAN_T2"]
        }, {
          val: 0,
          label: 'Disabled',
          g: 0,
          rpm: 0
        }]
      }, {
        name: "StepType",
        options: [// { label: "Empty", val: 0, active: false },
        {
          label: "ReuseFill",
          val: 1,
          active: true
        }, {
          label: "Fill",
          val: 2,
          active: true
        }, {
          label: "Supply",
          val: 3,
          active: true
        }, {
          label: "Agitate",
          val: 4,
          active: true
        }, {
          label: "Soak",
          val: 5,
          active: true
        }, {
          label: "CoolDown",
          val: 6,
          active: true
        }, {
          label: "Drain",
          val: 7,
          active: true
        }, {
          label: "DrainExtract",
          val: 9,
          active: true
        }, {
          label: "AudioSignal",
          val: 10,
          active: true
        }, {
          label: "Hold",
          val: 11,
          active: true
        }]
      }, {
        name: "SupplyCondition",
        options: [{
          val: 0,
          label: 'until_supply_time_expires'
        }, {
          val: 1,
          label: 'until_target_water_level'
        }]
      }, {
        name: "HeatOption",
        options: [{
          val: 0,
          label: 'no_heat'
        }, {
          val: 1,
          label: 'heat_to_target'
        }, {
          val: 2,
          label: 'maintain_heat'
        }]
      }, {
        name: "HeatRateOption",
        options: [{
          val: 0,
          label: 'no_rate_restriction'
        }, // presetValue is 6.0...after dividing by 10
        {
          val: 5,
          label: 'increment_by_minute',
          isCustom: true
        } // value becomes the custom setting...saved as 10x the displayed value
        ]
      }, {
        name: "CooldownTemperature",
        options: [{
          val: 0,
          label: 'global_cool_down',
          presetValue: this.globalValues.cooldownWaterTemp
        }, {
          val: this.globalValues.cooldownWaterTemp,
          label: 'Custom',
          isCustom: true
        }]
      }, {
        name: "CooldownRate",
        options: [{
          val: 0,
          label: 'no_rate_restriction'
        }, {
          val: 1,
          label: 'decrement_by_minute',
          isCustom: true
        }]
      }, {
        name: "AudioPattern",
        conditional: true,
        ifMachine: ["ACAS_A2", "ACAS_A22", "TITAN_T2"],
        options: [{
          val: 1,
          label: 'SolidHigh'
        }, {
          val: 2,
          label: 'SolidMedium'
        }, {
          val: 3,
          label: 'SolidLow'
        }]
      }, {
        name: "AudioPattern",
        conditional: true,
        ifMachine: ["MGD_M1"],
        options: [{
          val: 0,
          label: 'NoTone'
        }, {
          val: 1,
          label: 'SolidHigh'
        }, {
          val: 2,
          label: 'SolidMedium'
        }, {
          val: 3,
          label: 'SolidLow'
        }, {
          val: 4,
          label: 'HighBeep'
        }, {
          val: 5,
          label: 'MediumBeep'
        }, {
          val: 6,
          label: 'LowBeep'
        }, {
          val: 7,
          label: 'HighDoubleQuickBeep'
        }, {
          val: 8,
          label: 'MediumDoubleQuickBeep'
        }, {
          val: 9,
          label: 'LowDoubleQuickBeep'
        }, {
          val: 10,
          label: 'HighSOSBeep'
        }, {
          val: 11,
          label: 'MediumSOSBeep'
        }, {
          val: 12,
          label: 'LowSOSBeep'
        }, {
          val: 13,
          label: 'IncreasingTone'
        }, {
          val: 14,
          label: 'SinusoidalTone'
        }, {
          val: 15,
          label: 'Melody1'
        }, {
          val: 16,
          label: 'Melody2'
        }, {
          val: 17,
          label: 'Melody3'
        }, {
          val: 18,
          label: 'Melody4'
        }, {
          val: 19,
          label: 'Melody5'
        }, {
          val: 20,
          label: 'Melody6'
        }]
      }, {
        name: "AudioPattern",
        conditional: true,
        notMachine: ["ACAS_A2", "ACAS_A22", "MGD_M1", "TITAN_T2"],
        options: [{
          val: 1,
          label: 'SolidHigh'
        }, {
          val: 2,
          label: 'SolidMedium'
        }, {
          val: 3,
          label: 'SolidLow'
        }, {
          val: 4,
          label: 'HighBeep'
        }, {
          val: 5,
          label: 'MediumBeep'
        }, {
          val: 6,
          label: 'LowBeep'
        }, {
          val: 7,
          label: 'HighDoubleQuickBeep'
        }, {
          val: 8,
          label: 'MediumDoubleQuickBeep'
        }, {
          val: 9,
          label: 'LowDoubleQuickBeep'
        }, {
          val: 10,
          label: 'HighSOSBeep'
        }, {
          val: 11,
          label: 'MediumSOSBeep'
        }, {
          val: 12,
          label: 'LowSOSBeep'
        }, {
          val: 13,
          label: 'IncreasingTone'
        }, {
          val: 14,
          label: 'SinusoidalTone'
        }, {
          val: 15,
          label: 'Melody1'
        }, {
          val: 16,
          label: 'Melody2'
        }, {
          val: 17,
          label: 'Melody3'
        }, {
          val: 18,
          label: 'Melody4'
        }, {
          val: 19,
          label: 'Melody5'
        }, {
          val: 20,
          label: 'Melody6'
        }]
      }, {
        name: "GlobalMessage",
        conditional: true,
        ifMachine: ["ACAS", "TITAN"],
        options: [{
          val: 0,
          label: 'Load'
        }, {
          val: 1,
          label: 'Chemical'
        }, {
          val: 2,
          label: 'Soap'
        }, {
          val: 3,
          label: 'Bleach'
        }, {
          val: 4,
          label: 'Softener'
        }, {
          val: 5,
          label: 'Sour'
        }, {
          val: 6,
          label: 'Starch'
        }, {
          val: 7,
          label: `<span class="led7 led7-select">${this.customMessage1}</span> (Custom 1)`
        }, {
          val: 8,
          label: `<span class="led7 led7-select">${this.customMessage2}</span> (Custom 2)`
        }]
      }, {
        name: "GlobalMessage",
        conditional: true,
        ifMachine: ["MGD"],
        options: [{
          val: 0,
          label: 'Load'
        }, {
          val: 1,
          label: 'Chemical'
        }, {
          val: 2,
          label: 'Soap'
        }, {
          val: 3,
          label: 'Bleach'
        }, {
          val: 4,
          label: 'Softener'
        }, {
          val: 5,
          label: 'Sour'
        }, {
          val: 6,
          label: 'Starch'
        }, {
          val: 7,
          label: `${this.customMessage1} (Custom 1)`
        }, {
          val: 8,
          label: `${this.customMessage2} (Custom 2)`
        }]
      }];
    })
  });

  _exports.default = _default;
});