define("alliance-business-suite/helpers/temperature-option-label", ["exports", "alliance-business-suite/utils/convert-temperature"], function (_exports, _convertTemperature) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
    { val: 32 }
    { val: 32, F:32 C:0 }
  
    Based on the option and isCelsius, we return the value using this logic:
  
    1) If there's an F or C property, return that.
    2) If there's a label property, return that.
    3) Return the val property (converted to C, if required).
  
    {{temperature-option-label option=opt isCelsius=this.formSettings.isCelsius}}
  
  */
  // function temperatureOptionLabel([string], { option, isCelsius }) {
  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),

    compute(_ref, _ref2) {
      let [string] = _ref;
      let {
        option,
        isCelsius
      } = _ref2;

      switch (true) {
        case option.C === undefined && option.F === undefined && option.label !== undefined:
          return this.intl.t(option.label);

        case isCelsius && option.C !== undefined:
          return Ember.String.htmlSafe(`${option.C}&deg;C`);

        case !isCelsius && option.F !== undefined:
          return Ember.String.htmlSafe(`${option.F}&deg;F`);

        case isCelsius && option.val !== undefined:
          return Ember.String.htmlSafe(`${(0, _convertTemperature.toC)(option.val)}&deg;C`);

        case !isCelsius && option.val !== undefined:
          return Ember.String.htmlSafe(`${option.val}&deg;F`);
      }
    }

  }); // export default helper(temperatureOptionLabel);


  _exports.default = _default;
});