define("alliance-business-suite/components/machines/programs/config/acas-tum-dry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    machineAuditType: Ember.computed.alias('machineProgram.machineAuditType.name'),
    // TODO: INTL
    optionsManualMode: Ember.computed("_machineGeneration", function () {
      if (this._machineGeneration === "TITAN") {
        return [{
          val: 2,
          label: 'Password protected',
          text: "Access is passcode only"
        }, {
          val: 1,
          label: 'Enabled',
          text: "Access is not limited"
        }, {
          val: 0,
          label: 'Disabled',
          text: "Access is not allowed"
        }];
      } else {
        return [{
          val: 0,
          label: 'Password protected',
          text: "Access is passcode only"
        }, {
          val: 1,
          label: 'Enabled',
          text: "Access is not limited"
        }, {
          val: 2,
          label: 'Disabled',
          text: "Access is not allowed"
        }];
      }
    }),
    optionsCycleTimeDisplay: [{
      val: 0,
      label: 'minutes_and_seconds'
    }, {
      val: 1,
      label: 'minutes_only'
    }, {
      val: 2,
      label: 'colon_minutes'
    }],
    optionsPassKey: [{
      val: 4,
      label: 'high_temp'
    }, {
      val: 5,
      label: 'low_temp'
    }, {
      val: 6,
      label: 'no_heat'
    }, {
      val: 7,
      label: 'medium_temp'
    }, {
      val: 8,
      label: 'delicates'
    }, {
      val: 9,
      label: 'start'
    }]
  });

  _exports.default = _default;
});