define("alliance-business-suite/serializers/note", ["exports", "alliance-business-suite/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      let json = this._super(...arguments); // currently the POST endpoint returns ALL the notes in the order
      // we need to extract and show only the response for the note we created


      if (requestType == 'createRecord') {
        let payloadData = json.data;
        let lastId = payloadData[payloadData.length - 1].id;
        let extractedData = {};
        payloadData.forEach(data => {
          if (data.id == lastId) {
            extractedData = data;
          }
        });
        json.data = extractedData;
      }

      return json;
    }

  });

  _exports.default = _default;
});