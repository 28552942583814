define("alliance-business-suite/components/summary/reports/s-sales-tax", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "alliance-business-suite/utils/currency-format-util"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _currencyFormatUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  let SummaryReportsSSalesTaxComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, (_class = class SummaryReportsSSalesTaxComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor3, this);
    }

    get translatedColumns() {
      return [{
        name: 'Organization',
        valuePath: 'organizationName',
        width: 225,
        isFixed: 'left'
      }, {
        name: 'tax_rate_id',
        valuePath: 'id',
        width: 225
      }, {
        name: 'tax_rate_type',
        valuePath: 'taxRateDescription',
        width: 225
      }, {
        name: 'Tax',
        valuePath: 'taxAmount',
        width: 225
      }, {
        name: 'taxable_total',
        valuePath: 'taxableTotal',
        width: 225
      }];
    }

    get rowsWithChildren() {
      let {
        model
      } = this.args;
      let taxRateObjects = model.taxRates;
      let rowData = [];
      let taxDataArray = [];

      let orgTaxAmount = _currencyFormatUtil.formatCurrencyString.call(this, model.taxAmountTotal || 0);

      let orgTaxableAmount = _currencyFormatUtil.formatCurrencyString.call(this, model.taxableAmountTotal || 0);

      rowData.push({
        organizationName: model.organizationName,
        taxAmount: orgTaxAmount,
        taxableTotal: orgTaxableAmount,
        children: taxDataArray
      });
      taxRateObjects.forEach(taxRateObject => {
        taxDataArray.push({
          id: taxRateObject.id,
          taxRateDescription: taxRateObject.taxRateDescription,
          taxAmount: _currencyFormatUtil.formatCurrencyString.call(this, taxRateObject.taxAmount || 0),
          taxableTotal: _currencyFormatUtil.formatCurrencyString.call(this, taxRateObject.taxableTotal || 0)
        });
      });
      return rowData;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SummaryReportsSSalesTaxComponent;
});