define("alliance-business-suite/components/machines/programs/config/mc5-tum", ["exports", "alliance-business-suite/utils/machine-programming-find-options"], function (_exports, _machineProgrammingFindOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // eslint-disable-line ember/no-classic-classes
    tagName: '',
    machineAuditType: Ember.computed.alias('machineProgram.machineAuditType.id'),
    isCoinMode: Ember.computed.equal('machineProgram.settings.Program.Price.Misc.PaymentMethod', 1),
    isOplMode: Ember.computed.equal('machineProgram.settings.Program.Price.Misc.PaymentMethod', 0),
    optionsTemperatureUnits: [{
      val: false,
      label: 'Celsius'
    }, {
      val: true,
      label: 'Fahrenheit'
    }],
    optionsTemperatureUnitsInt: [{
      val: 0,
      label: 'Celsius'
    }, {
      val: 1,
      label: 'Fahrenheit'
    }],
    optionsDecimalPoint: [{
      val: 0,
      label: 'None'
    }, {
      val: 1,
      label: 'One'
    }, {
      val: 2,
      label: 'Two'
    }],
    optionsPriceMode: [{
      val: 0,
      label: 'One'
    }, {
      val: 1,
      label: 'Individual'
    }],
    optionsPaymentMethod: [{
      val: 0,
      label: 'OPL'
    }, {
      val: 1,
      label: 'Coins'
    }, {
      val: 2,
      label: 'Pulse'
    }],
    optionsLanguageRegion1: [{
      val: 0,
      label: 'Engels'
    }, {
      val: 1,
      label: 'Czech'
    }, {
      val: 2,
      label: 'Russian'
    }, {
      val: 3,
      label: 'Slovenian'
    }, {
      val: 4,
      label: 'Croatian'
    }, {
      val: 5,
      label: 'Hungarian'
    }, {
      val: 6,
      label: 'Polish'
    }, {
      val: 7,
      label: 'Bulgarian'
    }, {
      val: 8,
      label: 'Romanian'
    }, {
      val: 9,
      label: 'Turkish'
    }],
    optionsLanguageRegion0: [{
      val: 0,
      label: 'English'
    }, {
      val: 1,
      label: 'Czech'
    }, {
      val: 2,
      label: 'German'
    }, {
      val: 3,
      label: 'French'
    }, {
      val: 4,
      label: 'Dutch'
    }, {
      val: 5,
      label: 'Spanish'
    }, {
      val: 6,
      label: 'Italian'
    }, {
      val: 7,
      label: 'Danish'
    }, {
      val: 8,
      label: 'Norwegian'
    }],
    optionsDefaultLanguage: [{
      val: 0,
      label: 'English/Engels'
    }, {
      val: 1,
      label: 'Czech'
    }, {
      val: 2,
      label: 'Russian/German'
    }, {
      val: 3,
      label: 'Slovenian/French'
    }, {
      val: 4,
      label: 'Croatian/Dutch'
    }, {
      val: 5,
      label: 'Hungarian/Spanish'
    }, {
      val: 6,
      label: 'Polish/Italian'
    }, {
      val: 7,
      label: 'Bulgarian/Danish'
    }, {
      val: 8,
      label: 'Romanian/Norwegian'
    }, {
      val: 9,
      label: 'Turkish'
    }]
  });

  _exports.default = _default;
});