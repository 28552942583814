define("alliance-business-suite/templates/components/table/table-cell-boolean-checklist", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YexbkURc",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[30,[36,2],[[35,1],[35,0,[\"propertyName\"]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"i\"],[14,0,\"fa fa-check\"],[14,\"aria-hidden\",\"true\"],[14,5,\"opacity: .85\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"i\"],[14,\"aria-hidden\",\"true\"],[14,0,\"fa fa-ban\"],[14,5,\"opacity: .2\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"column\",\"record\",\"get\",\"if\"]}",
    "moduleName": "alliance-business-suite/templates/components/table/table-cell-boolean-checklist.hbs"
  });

  _exports.default = _default;
});