define("alliance-business-suite/components/ui/boolean-string-formatter", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{this.formattedString}}
  */
  {
    "id": "Zz0WXVTb",
    "block": "{\"symbols\":[],\"statements\":[[1,[32,0,[\"formattedString\"]]]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "alliance-business-suite/components/ui/boolean-string-formatter.hbs"
  });

  let UiBooleanStringFormatterComponent = (_dec = Ember.inject.service, (_class = class UiBooleanStringFormatterComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor, this);
    }

    get formattedString() {
      let {
        value,
        trueVal,
        falseVal
      } = this.args;
      return value ? this.intl.t(trueVal) : this.intl.t(falseVal);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = UiBooleanStringFormatterComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, UiBooleanStringFormatterComponent);
});