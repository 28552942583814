define("alliance-business-suite/models/customer", ["exports", "ember-data", "alliance-business-suite/models/user"], function (_exports, _emberData, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import emailAddress from '../adapters/email-address';
  const {
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;

  var _default = _user.default.extend({
    organization: belongsTo(),
    firstName: attr(),
    lastName: attr(),
    // , notes: hasMany('note')
    emailAddresses: hasMany('emailAddresses', {
      inverse: 'user'
    }),
    primaryEmail: Ember.computed('emailAddresses', 'emailAddresses.[].emailAddress', {
      get() {
        let emailAddresses = this.emailAddresses;

        if (emailAddresses.get('length')) {
          return emailAddresses.objectAt(0).get('emailAddress');
        }
      }

    })
  });

  _exports.default = _default;
});