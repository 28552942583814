define("alliance-business-suite/helpers/access", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Access helper
   *
   * Use like so: (access 'accessOrganization' 'id')
   * This should also work, haven't tested: (access 'accessOrganization.id')
   * @public
   */
  var _default = Ember.Helper.extend({
    access: Ember.inject.service(),

    compute(props
    /* , hash*/
    ) {
      let pathStr = 'access';
      props.forEach(prop => {
        pathStr += `.${prop}`;
      });
      return this.get(pathStr);
    }

  });

  _exports.default = _default;
});