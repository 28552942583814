define("alliance-business-suite/helpers/safe-string", ["exports", "numeral"], function (_exports, _numeral) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.safeString = safeString;

  function safeString(params
  /* , hash */
  ) {
    let [value, format, prependText, appendText] = params;

    if (format == 'append') {
      return Ember.String.htmlSafe(`${prependText}${value}${appendText}`);
    } // "simple" avoids numeral.js and does a straightfoward safe string


    if (format === 'simple') {
      return Ember.String.htmlSafe(`${prependText}${value}`);
    }

    return Ember.String.htmlSafe(`${prependText}${(0, _numeral.default)(value).format(format)}`);
  }

  var _default = Ember.Helper.helper(safeString);

  _exports.default = _default;
});