define("alliance-business-suite/models/line-item-type", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    hasMany,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    intl: Ember.inject.service(),
    isFlatAmount: attr('boolean', {
      defaultValue: false
    }),
    price: attr('number'),
    minWeight: attr('string'),
    room: belongsTo('room'),
    lineItemType: belongsTo('order-line-item-type'),
    taxRates: hasMany('tax-rate'),
    description: attr('string'),
    modelType: 'line-item-type',
    type: Ember.computed({
      get() {
        return this.intl.t('line_item_type');
      }

    }).readOnly()
  });

  _exports.default = _default;
});