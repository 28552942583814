define("alliance-business-suite/components/form-ui/steps/water-level", ["exports", "alliance-business-suite/mixins/init-property-names", "alliance-business-suite/utils/machine-programming-custom-properties"], function (_exports, _initPropertyNames, _machineProgrammingCustomProperties) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_initPropertyNames.default, {
    // eslint-disable-line ember/no-classic-classes
    tagName: '',

    didReceiveAttrs() {
      this._super(...arguments); // If there is a custom property, we need to do some extra work


      if (this.hasCustomProperty) {
        (0, _machineProgrammingCustomProperties.initCustomPropertyValues)({
          context: this,
          model: this.object,
          options: this.selectOptions.WaterLevel,
          propertyName: this.properties.WaterLevel.key,
          customPropertyName: this.properties.CustomWaterLevel.key,
          limits: this.params.validations.waterLevel
        });
      }
    },

    lastPresetValue: null,
    hasCustomProperty: (0, _machineProgrammingCustomProperties.hasCustomProperty)({
      model: 'object',
      options: 'selectOptions.WaterLevel',
      propertyName: 'properties.WaterLevel.key',
      customPropertyName: 'properties.CustomWaterLevel.key'
    }),
    actions: {
      // eslint-disable-line ember/no-actions-hash
      updateCustomProperty(selection) {
        (0, _machineProgrammingCustomProperties.updateCustomPropertyValues)({
          context: this,
          hasCustomProperty: _machineProgrammingCustomProperties.hasCustomProperty,
          model: this.object,
          selection: selection,
          lastPresetValue: this.lastPresetValue,
          propertyName: this.properties.WaterLevel.key,
          customPropertyName: this.properties.CustomWaterLevel.key
        });
      }

    }
  });

  _exports.default = _default;
});