define("alliance-business-suite/components/form-ui/checkbox-value-combo", ["exports", "alliance-business-suite/mixins/init-elements", "jquery", "alliance-business-suite/mixins/init-property-names", "alliance-business-suite/mixins/show-component"], function (_exports, _initElements, _jquery, _initPropertyNames, _showComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_showComponent.default, _initElements.default, _initPropertyNames.default, {
    initPropertyName(propertyName) {
      // TODO: This whole thing needs to be DRY and moved somewhere else.
      let index = Ember.get(this, 'params.index');

      if (Ember.isEmpty(index)) {
        index = this.index;
      }

      if (!Ember.isNone(index)) {
        propertyName = propertyName.replace('${index}', index); // Replace the index placeholder if this is an array
      }

      return propertyName;
    },

    classNames: ['checkbox-value-combo', 'form-field'],
    // el: alias('params.elements'), // save some keystrokes
    el: Ember.computed.alias('params.elements'),
    // save some keystrokes
    properties: Ember.computed.alias('params.elements'),
    // save some keystrokes
    index: '',
    // If checkbox2 is checked, we need to force checkbox1 to also be checked
    watchCheckbox2: Ember.observer('checkbox2', function () {
      let value = this.checkbox2;

      if (value === true) {
        let object = this.object;
        let propertyName = this.initPropertyName(Ember.get(this, 'properties.checkbox1.key'));
        this.update(object, propertyName, value);
      }
    }),
    // If checkbox2 is checked, we cannot uncheck checkbox1
    watchCheckbox1: Ember.observer('checkbox1', function () {
      let value1 = this.checkbox1;
      let value2 = this.checkbox2;

      if (value2 === true && value1 === false) {
        let object = this.object;
        let propertyName = this.initPropertyName(Ember.get(this, 'properties.checkbox2.key'));
        this.update(object, propertyName, value1);
      }
    }),
    // TODO: What is this stuff doing in here?
    cycleOptions: Ember.computed('el', 'object.Cycles', function () {
      let cyclesArray = Ember.get(this, 'object.Cycles');
      let newCycleArray = [];
      let filteredCycles = cyclesArray.filterBy('Enabled', true);
      filteredCycles.forEach(want => {
        newCycleArray.push({
          val: want.Number,
          label: want.Name
        });
      });
      return newCycleArray;
    }),
    actions: {
      doChange(val) {
        Ember.set(this, 'selectedOption', val);

        if (Ember.get(this, 'params.onChange')) {
          Ember.get(this, 'params.onChange')(val);
        } else {
          // If 'custom' is chosen and sharedCustomProperty is true, we need to set the value to the last preset value.
          let isCustom = val.isCustom;
          let sharedCustomProperty = this.sharedCustomProperty;
          let lastPresetValue = Ember.get(this, 'yieldHash.lastPresetValue') || Ember.get(this, 'yieldHash.presetValue');
          let value = val.val;

          if (isCustom && sharedCustomProperty) {
            // We need to update the value to be the last presetValue
            // If this is a sharedCustomProperty, the lastPresetValue might be undefined. If so, we'll use the current value.
            if (Ember.isNone(lastPresetValue)) {
              Ember.set(this, 'value', value);
            } else {
              Ember.set(this, 'value', lastPresetValue);
            }
          } else if (isCustom) {
            Ember.set(this, 'value', value);
          } else {
            // not custom
            Ember.set(this, 'value', value);
            Ember.set(this, 'lastPresetValue', val.presetValue);
          }
        } // If "custom" was just selected, try to focus on an input. setTimeout required to catch the element in the DOM.


        run.schedule('afterRender', () => {
          setTimeout(() => {
            (0, _jquery.default)(this.element).find('input').focus();
          }, 100);
        });
      },

      showModal() {
        Ember.set(this, 'showModal', true);
      },

      hideModal() {
        // We may need to call an action to refresh things.
        // This way we can update the formConfig computed property to show updated property values.
        if (this.properties.button1.notifyPropertyChange && this.refreshFormConfig) {
          this.refreshFormConfig();
        }

        Ember.set(this, 'showModal', false);
      }

    }
  });

  _exports.default = _default;
});