define("alliance-business-suite/templates/components/ui/context-menu-separator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CEUX1jMy",
    "block": "{\"symbols\":[],\"statements\":[],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "alliance-business-suite/templates/components/ui/context-menu-separator.hbs"
  });

  _exports.default = _default;
});