define("alliance-business-suite/components/table/table-cell-external-url", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@glimmer/component"], function (_exports, _applyDecoratedDescriptor2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  let TableTableCellExternalUrlComponent = (_dec = Ember._action, (_class = class TableTableCellExternalUrlComponent extends _component.default {
    openExternalUrl() {
      let {
        record,
        column
      } = this.args;
      let externalLink = record.get(column.propertyName);
      window.open(externalLink, '_blank');
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class.prototype, "openExternalUrl", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "openExternalUrl"), _class.prototype)), _class));
  _exports.default = TableTableCellExternalUrlComponent;
});