define("alliance-business-suite/helpers/find-by-dropdown-with-custom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.findByDropDown = findByDropDown;

  function findByDropDown(_ref) {
    let [collection, attrName, attrValue] = _ref;

    // If attrValue is an array, then we are binding to more than one property.
    // This happens with Galaxy FLW Rotate/Pause Times, and maybe other places, too.
    if (Array.isArray(attrValue)) {
      // If we get an array of primitives, we'll need to look up those numbers against the `values` array of the collection.
      let match = collection.find(item => {
        // We'll compare the joined strings
        let result = item.values.join() === attrValue.join();
        return result;
      });
      return match;
    } else {
      // First look for a matching attrValue in the collection object. If found, return that.
      let match = collection.find(el => Ember.get(el, attrName) === attrValue);

      if (match) {
        return match;
      } // If we didn't find anything, we'll return the item in the collection array with isCustom == true.


      return collection.findBy('isCustom');
    }
  }

  var _default = Ember.Helper.helper(findByDropDown);

  _exports.default = _default;
});