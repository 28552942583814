define("alliance-business-suite/templates/components/machines/machines-list-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "E+ucorKi",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[6,[37,2],[[30,[36,1],[[32,1,[\"machines\",\"length\"]],0],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"content-container container--placeholder\"],[12],[2,\"\\n    \"],[10,\"h3\"],[12],[1,[30,[36,0],[\"no_machines_found\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"machines-list-table\"],[12],[2,\"\\n    \"],[8,\"tables/table-interactive\",[],[[\"@isClickable\",\"@data\",\"@columns\",\"@brushModel\"],[true,[32,1],[32,0,[\"columns\"]],\"machine\"]],null],[2,\"\\n\"],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"eq\",\"if\"]}",
    "moduleName": "alliance-business-suite/templates/components/machines/machines-list-table.hbs"
  });

  _exports.default = _default;
});