define("alliance-business-suite/components/summary/reports/s-audit-additional-vending", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "alliance-business-suite/utils/currency-format-util", "alliance-business-suite/utils/report-tables-util"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _currencyFormatUtil, _reportTablesUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  let SummaryReportsSAuditAdditionalVendingComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, (_class = class SummaryReportsSAuditAdditionalVendingComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "brand", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor4, this);
    }

    get translatedColumns() {
      return [{
        name: 'location_machine',
        valuePath: 'name',
        width: 393,
        isFixed: 'left'
      }, {
        name: 'date_range',
        valuePath: 'dateRange',
        width: 388
      }, {
        name: 'standard_vended',
        valuePath: 'standardVended',
        width: 180
      }, {
        name: 'extra_wash_vended',
        valuePath: 'calcVendedMedium',
        width: 180
      }, {
        name: 'extra_rinse_vended',
        valuePath: 'calcVendedHigh',
        width: 180
      }, {
        name: 'total_vended',
        valuePath: 'calcTotalVend',
        width: 180
      }];
    }

    get rowsWithChildren() {
      let {
        model
      } = this.args;
      let geoBoundaries = model.geoBoundaries;
      let rowData = [];
      geoBoundaries.forEach(geoBoundary => {
        let roomArray = [];
        let cycleSum = 0;
        let medModSum = 0;
        let heavModSum = 0;
        let highModSum = 0;
        let totalRoomCycles = [];
        let totalMedMod = [];
        let totalheavMod = [];
        let totalMedModPer = [];
        rowData.push({
          name: _reportTablesUtil.localizeString.call(this, geoBoundary.name, 'Location', 'location'),
          children: roomArray,
          standardVended: totalRoomCycles,
          calcTotalVend: totalMedMod,
          calcVendedMedium: totalheavMod,
          calcVendedHigh: totalMedModPer
        });
        geoBoundary.rooms.forEach(room => {
          let machineTypeArray = [];
          let cycleSum1 = 0;
          let medModSum1 = 0;
          let heavModSum1 = 0;
          let highModSum1 = 0;
          let totalRoomCycles1 = [];
          let totalMedMod1 = [];
          let totalheavMod1 = [];
          let totalMedModPer1 = [];
          roomArray.push({
            name: room.name,
            children: machineTypeArray,
            standardVended: totalRoomCycles1,
            calcTotalVend: totalMedMod1,
            calcVendedMedium: totalheavMod1,
            calcVendedHigh: totalMedModPer1
          });
          room.machineAuditGroupTypes.forEach(machineAuditType => {
            machineAuditType.machineTypes.forEach(machineType => {
              let machineArray = [];
              let machineTypeName = `${machineAuditType.name} - ${machineType.name}`;
              let cycleSum2 = 0;
              let medModSum2 = 0;
              let heavModSum2 = 0;
              let highModSum2 = 0;
              let totalRoomCycles2 = [];
              let totalMedMod2 = [];
              let totalheavMod2 = [];
              let totalMedModPer2 = [];
              machineTypeArray.push({
                name: machineTypeName,
                machineTypeName: machineType.name,
                children: machineArray,
                standardVended: totalRoomCycles2,
                calcTotalVend: totalMedMod2,
                calcVendedMedium: totalheavMod2,
                calcVendedHigh: totalMedModPer2
              });
              machineType.machines.forEach(machine => {
                let machineCycleArray = [];
                let machineVendArray = [];
                let machineMediumArray = [];
                let machineHighArray = [];
                let dateRange = "";

                if (this.brand.slug == 'primus') {
                  // fix the branding issue alliance-tasks/issues/2291
                  machineTypeArray[machineTypeArray.length - 1].name = `${machine.machineAuditType.name} - ${machineTypeArray[machineTypeArray.length - 1].machineTypeName}`;
                }

                if (machine.minFirstReceivedAt && machine.maxFirstReceivedAt) {
                  dateRange = `${moment(machine.minFirstReceivedAt).format(_reportTablesUtil.formatDateTimeString.call(this))} - ${moment(machine.maxFirstReceivedAt).format(_reportTablesUtil.formatDateTimeString.call(this))}`;
                }

                if (machine.calcTotalVend !== 0) {
                  machineCycleArray.push(machine.calcTotalVend);
                  cycleSum = (0, _reportTablesUtil.sumArray)(machineCycleArray, cycleSum);
                  cycleSum1 = (0, _reportTablesUtil.sumArray)(machineCycleArray, cycleSum1);
                  cycleSum2 = (0, _reportTablesUtil.sumArray)(machineCycleArray, cycleSum2);
                }

                if (machine.standardVended !== 0) {
                  machineVendArray.push(machine.standardVended);
                  medModSum = (0, _reportTablesUtil.sumArray)(machineVendArray, medModSum);
                  medModSum1 = (0, _reportTablesUtil.sumArray)(machineVendArray, medModSum1);
                  medModSum2 = (0, _reportTablesUtil.sumArray)(machineVendArray, medModSum2);
                }

                if (machine.calcVendedMedium !== 0) {
                  machineMediumArray.push(machine.calcVendedMedium);
                  heavModSum = (0, _reportTablesUtil.sumArray)(machineMediumArray, heavModSum);
                  heavModSum1 = (0, _reportTablesUtil.sumArray)(machineMediumArray, heavModSum1);
                  heavModSum2 = (0, _reportTablesUtil.sumArray)(machineMediumArray, heavModSum2);
                }

                if (machine.calcVendedHigh !== 0) {
                  machineHighArray.push(machine.calcVendedHigh);
                  highModSum = (0, _reportTablesUtil.sumArray)(machineHighArray, highModSum);
                  highModSum1 = (0, _reportTablesUtil.sumArray)(machineHighArray, highModSum1);
                  highModSum2 = (0, _reportTablesUtil.sumArray)(machineHighArray, highModSum2);
                }

                machineArray.push({
                  name: _reportTablesUtil.machineNameDisplay.call(this, machine.machineName, machine.machineNumber),
                  dateRange: dateRange,
                  standardVended: _currencyFormatUtil.formatCurrencyString.call(this, machine.standardVended || 0),
                  calcVendedMedium: _currencyFormatUtil.formatCurrencyString.call(this, machine.calcVendedMedium || 0),
                  calcVendedHigh: _currencyFormatUtil.formatCurrencyString.call(this, machine.calcVendedHigh || 0),
                  calcTotalVend: _currencyFormatUtil.formatCurrencyString.call(this, machine.calcTotalVend || 0)
                });
              });
              totalRoomCycles2.push(_currencyFormatUtil.formatCurrencyString.call(this, medModSum2 || 0));
              totalMedMod2.push(_currencyFormatUtil.formatCurrencyString.call(this, cycleSum2 || 0));
              totalheavMod2.push(_currencyFormatUtil.formatCurrencyString.call(this, heavModSum2 || 0));
              totalMedModPer2.push(_currencyFormatUtil.formatCurrencyString.call(this, highModSum2 || 0));
            });
          });
          totalRoomCycles1.push(_currencyFormatUtil.formatCurrencyString.call(this, medModSum1 || 0));
          totalMedMod1.push(_currencyFormatUtil.formatCurrencyString.call(this, cycleSum1 || 0));
          totalheavMod1.push(_currencyFormatUtil.formatCurrencyString.call(this, heavModSum1 || 0));
          totalMedModPer1.push(_currencyFormatUtil.formatCurrencyString.call(this, highModSum1 || 0));
        });
        totalRoomCycles.push(_currencyFormatUtil.formatCurrencyString.call(this, medModSum || 0));
        totalMedMod.push(_currencyFormatUtil.formatCurrencyString.call(this, cycleSum || 0));
        totalheavMod.push(_currencyFormatUtil.formatCurrencyString.call(this, heavModSum || 0));
        totalMedModPer.push(_currencyFormatUtil.formatCurrencyString.call(this, highModSum || 0));
      });
      return rowData;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "brand", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SummaryReportsSAuditAdditionalVendingComponent;
});