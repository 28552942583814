define("alliance-business-suite/components/machines/programs/cycles/acas-tlw", ["exports", "alliance-business-suite/themes/default-theme"], function (_exports, _defaultTheme) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function addFixedTime(value, fixedTime) {
    return value > 0 ? value + fixedTime : 0;
  }

  const enums = {
    modifier: {
      smallLoad: 'small',
      light: 'light',
      medium: 'medium',
      heavy: 'heavy'
    }
  };

  function timeInSeconds(val) {
    let time = Number(val) || 0;
    return time * 60;
  }

  function getProgrammableSeconds(userTime, stepTime) {
    // If setTime is 0, we return 0. Otherwise, we return the user time plus the standard step time
    let time = Number(userTime) || 0;

    if (time > 0) {
      return time * 60 + stepTime;
    } else {
      return 0;
    }
  }

  function toMinutes(value) {
    return Math.ceil(value / 60);
  }

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    tubOptions: Ember.computed(function () {
      return [{
        val: true,
        label: this.intl.t('standard_tub').toString()
      }, {
        val: false,
        label: this.intl.t('tall_tub').toString()
      }];
    }),
    waterUnits: Ember.computed('model.settings._convertToLiters', function () {
      return this.model.settings._convertToLiters ? this.intl.t('g').toString() : this.intl.t('g').toString();
    }),
    waterUnitsLabel: Ember.computed('model.settings._convertToLiters', function () {
      return this.model.settings._convertToLiters ? this.intl.t('liters').toString() : this.intl.t('gallons').toString();
    }),

    /*
      Quick notes. This report has a top and bottom section. Top section adds "fixed" time for each cycle, which is
      always rounded up to the nearest minute. The values can change based on spray vs. full fill, and tub size.
       The bottom section adds up all the time, also factoring in extra time for MOD1 and MOD2, and subtracting
      a minute from the wash cycle for small loads. These values are rounded (up?) AFTER they've all been added up.
       Heavy extra rinse is always full tub.
       If spray enabled, normal and medium always gets spray time added, but user time is not added to the total.
       MODs only apply to Wash and Extra Rinse.
       Heavy rinse is always a full fill, and all Extra Rinse are full fill.
    */
    cycleTimes: Ember.computed('machineProgram', function () {
      // wash, rinseFill, rinseSpray, extraRinse
      return this.model.machineType.cycleTimes;
    }),
    cycle: Ember.computed.or("machineProgram.settings.Program.Cycle", "machineProgram.settings.Program.cycleRelatedMessage"),
    price: Ember.computed.or("machineProgram.settings.Program.Price", "machineProgram.settings.Program.priceRelatedMessage"),
    smallLoadEnabled: Ember.computed.or('machineProgram.settings.Program.Cycle.SmallLoadEnabled', 'machineProgram.settings.Program.cycleRelatedMessage.smallLoad'),
    sprayEnabled: Ember.computed.or('machineProgram.settings.Program.Cycle.SprayRinseEnabled', 'machineProgram.settings.Program.cycleRelatedMessage.sprayRinse'),
    standardTub: true,
    optionTubSizes: [{
      val: true,
      label: "standard"
    }, {
      val: false,
      label: "tall"
    }],
    tubSize: Ember.computed('standardTub', function () {
      return this.standardTub ? 'standard' : 'tall';
    }),

    didReceiveAttrs() {
      if (!this.model.settings._convertToLiters) {
        Ember.set(this, 'model.settings._convertToLiters', false);
      }
    },

    // #region Modifier notes for total cycle times table
    tallTub: Ember.computed.not("standardTub"),
    // TODO: All these aliases are for Titanium machines because cycle times were never calculated for the A4 TLW. Need to
    // change the `alias` to `or` and add in the A4 properties.
    // Medium
    CycleModifierKey1AddedExtraWashAgitateTime: Ember.computed.alias('machineProgram.settings.Program.vendRelatedMessage.addWashAgitationTimeMod1TLW'),
    CycleModifierKey1AddedExtraRinseAgitateTime: Ember.computed.alias('machineProgram.settings.Program.vendRelatedMessage.addRinseAgitationTimeMod1TLW'),
    Key1ModifierOptionsWarmFinalRinse: Ember.computed.alias('machineProgram.settings.Program.vendRelatedMessage.warmRinseMod1'),
    // Heavy
    CycleModifierKey2AddedExtraWashAgitateTime: Ember.computed.alias('machineProgram.settings.Program.vendRelatedMessage.addWashAgitationTimeMod2TLW'),
    CycleModifierKey2AddedExtraRinseAgitateTime: Ember.computed.alias('machineProgram.settings.Program.vendRelatedMessage.addRinseAgitationTimeMod2TLW'),
    Key2ModifierOptionsWarmFinalRinse: Ember.computed.alias('machineProgram.settings.Program.vendRelatedMessage.warmRinseMod2'),
    CycleModifierDefaultValue: Ember.computed.alias('machineProgram.settings.Program.vendRelatedMessage.defaultModifierTLW'),
    CycleModifierKey1MediumPriceAdder: Ember.computed.alias('machineProgram.settings.Program.vendRelatedMessage.mod1VendPrice'),
    CycleModifierKey2HeavyPriceAdder: Ember.computed.alias('machineProgram.settings.Program.vendRelatedMessage.mod2VendPrice'),
    normalWashAgitateSegmentTime: Ember.computed.alias("machineProgram.settings.Program.cycleRelatedMessage.normalWashAgitateSegmentTime"),
    normalHeavyRinseAgitateSegmentTime: Ember.computed.alias("machineProgram.settings.Program.cycleRelatedMessage.normalHeavyRinseAgitateSegmentTime"),
    normalExtraRinseAgitateSegmentTime: Ember.computed.alias("machineProgram.settings.Program.cycleRelatedMessage.normalExtraRinseAgitateSegmentTime"),
    normalFinalSpinSegmentTime: Ember.computed.alias("machineProgram.settings.Program.cycleRelatedMessage.normalFinalSpinSegmentTime"),
    permPressWashAgitateSegmentTime: Ember.computed.alias("machineProgram.settings.Program.cycleRelatedMessage.permPressWashAgitateSegmentTime"),
    permPressHeavyRinseAgitateSegmentTime: Ember.computed.alias("machineProgram.settings.Program.cycleRelatedMessage.permPressHeavyRinseAgitateSegmentTime"),
    permPressExtraRinseAgitateSegmentTime: Ember.computed.alias("machineProgram.settings.Program.cycleRelatedMessage.permPressExtraRinseAgitateSegmentTime"),
    permPressFinalSpinSegmentTime: Ember.computed.alias("machineProgram.settings.Program.cycleRelatedMessage.permPressFinalSpinSegmentTime"),
    delicateWashAgitateSegmentTime: Ember.computed.alias("machineProgram.settings.Program.cycleRelatedMessage.delicateWashAgitateSegmentTime"),
    delicateHeavyRinseAgitateSegmentTime: Ember.computed.alias("machineProgram.settings.Program.cycleRelatedMessage.delicateHeavyRinseAgitateSegmentTime"),
    delicateExtraRinseAgitateSegmentTime: Ember.computed.alias("machineProgram.settings.Program.cycleRelatedMessage.delicateExtraRinseAgitateSegmentTime"),
    delicateFinalSpinSegmentTime: Ember.computed.alias("machineProgram.settings.Program.cycleRelatedMessage.delicateFinalSpinSegmentTime"),

    washTime(programTime) {
      let isSmallLoad = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      let time = this.tallTub ? 525 : 495;

      if (isSmallLoad) {
        // Small load is different...if the program time is 0 or 1, the time is zero...but we need to return 60 seconds because later we subtract 60 for small load.
        if (Number(programTime) > 1) {
          return getProgrammableSeconds(programTime, time) - 60;
        } else {
          return 0;
        }
      } else {
        return getProgrammableSeconds(programTime, time);
      }
    },

    rinseTime(programTime) {
      let fullRinseTime = 430; // 7:10

      let sprayRinseTime = this.tallTub ? 205 : 185;

      if (this.sprayEnabled) {
        // Spray rinse is always a fixed time
        return sprayRinseTime;
      } else {
        // Full rinse is 0 if program time is 0, else programTime + fullRinseTime
        return getProgrammableSeconds(programTime, fullRinseTime);
      }
    },

    fullRinseTime(programTime) {
      return this.extraRinseTime(programTime);
    },

    extraRinseTime(programTime) {
      let time = 430;
      return getProgrammableSeconds(programTime, time);
    },

    spinTime(programTime) {
      return timeInSeconds(programTime);
    },

    allTimes: Ember.computed('tallTub', 'sprayEnabled', 'normalWashAgitateSegmentTime', 'normalHeavyRinseAgitateSegmentTime', 'normalExtraRinseAgitateSegmentTime', 'normalFinalSpinSegmentTime', 'permPressWashAgitateSegmentTime', 'permPressHeavyRinseAgitateSegmentTime', 'permPressExtraRinseAgitateSegmentTime', 'permPressFinalSpinSegmentTime', 'delicateWashAgitateSegmentTime', 'delicateHeavyRinseAgitateSegmentTime', 'delicateExtraRinseAgitateSegmentTime', 'delicateFinalSpinSegmentTime', 'CycleModifierKey1AddedExtraWashAgitateTime', 'CycleModifierKey1AddedExtraRinseAgitateTime', 'CycleModifierKey2AddedExtraWashAgitateTime', 'CycleModifierKey2AddedExtraRinseAgitateTime', function () {
      // Heavy never gets spray...always a full fill.
      // If spray is enabled (and not heavy), the rinse step is always 4 minutes--we don't add the time the user enters.
      // If any times are entered as 0, we do not count any time for that step--it equals zero.
      // Small loads have zero wash time if the programmed time is 0 or 1 (not just zero, like the other steps).
      // If a small load has a programmed wash time > 1, the wash time gets reduced by 1 minute.
      // If small load is not enabled, should we hide the times for that column?
      let baseTime = {
        // This uses a spray rinse, if enabled
        normal: this.washTime(this.normalWashAgitateSegmentTime) + this.rinseTime(this.normalHeavyRinseAgitateSegmentTime) + this.extraRinseTime(this.normalExtraRinseAgitateSegmentTime) + this.spinTime(this.normalFinalSpinSegmentTime),
        permPress: this.washTime(this.permPressWashAgitateSegmentTime) + this.rinseTime(this.permPressHeavyRinseAgitateSegmentTime) + this.extraRinseTime(this.permPressExtraRinseAgitateSegmentTime) + this.spinTime(this.permPressFinalSpinSegmentTime),
        delicate: this.washTime(this.delicateWashAgitateSegmentTime) + this.rinseTime(this.delicateHeavyRinseAgitateSegmentTime) + this.extraRinseTime(this.delicateExtraRinseAgitateSegmentTime) + this.spinTime(this.delicateFinalSpinSegmentTime)
      };
      let baseSmallLoadTime = {
        // Small loads have zero wash time if the programmed time is not > 1, otherwise the wash time gets lowered by 1 minute.
        normal: this.washTime(this.normalWashAgitateSegmentTime, true) + this.rinseTime(this.normalHeavyRinseAgitateSegmentTime) + this.extraRinseTime(this.normalExtraRinseAgitateSegmentTime) + this.spinTime(this.normalFinalSpinSegmentTime),
        permPress: this.washTime(this.permPressWashAgitateSegmentTime, true) + this.rinseTime(this.permPressHeavyRinseAgitateSegmentTime) + this.extraRinseTime(this.permPressExtraRinseAgitateSegmentTime) + this.spinTime(this.permPressFinalSpinSegmentTime),
        delicate: this.washTime(this.delicateWashAgitateSegmentTime, true) + this.rinseTime(this.delicateHeavyRinseAgitateSegmentTime) + this.extraRinseTime(this.delicateExtraRinseAgitateSegmentTime) + this.spinTime(this.delicateFinalSpinSegmentTime)
      };
      let baseHeavyTime = {
        // This always uses a full fill for rinse
        normal: this.washTime(this.normalWashAgitateSegmentTime) + this.fullRinseTime(this.normalHeavyRinseAgitateSegmentTime) + this.extraRinseTime(this.normalExtraRinseAgitateSegmentTime) + this.spinTime(this.normalFinalSpinSegmentTime),
        permPress: this.washTime(this.permPressWashAgitateSegmentTime) + this.fullRinseTime(this.permPressHeavyRinseAgitateSegmentTime) + this.extraRinseTime(this.permPressExtraRinseAgitateSegmentTime) + this.spinTime(this.permPressFinalSpinSegmentTime),
        delicate: this.washTime(this.delicateWashAgitateSegmentTime) + this.fullRinseTime(this.delicateHeavyRinseAgitateSegmentTime) + this.extraRinseTime(this.delicateExtraRinseAgitateSegmentTime) + this.spinTime(this.delicateFinalSpinSegmentTime)
      };
      let mod1 = timeInSeconds(this.CycleModifierKey1AddedExtraWashAgitateTime) + timeInSeconds(this.CycleModifierKey1AddedExtraRinseAgitateTime);
      let mod2 = timeInSeconds(this.CycleModifierKey2AddedExtraWashAgitateTime) + timeInSeconds(this.CycleModifierKey2AddedExtraRinseAgitateTime);
      return {
        light: [toMinutes(baseTime.normal), toMinutes(baseTime.permPress), toMinutes(baseTime.delicate)],
        medium: [toMinutes(baseTime.normal + mod1), toMinutes(baseTime.permPress + mod1), toMinutes(baseTime.delicate + mod1)],
        heavy: [toMinutes(baseHeavyTime.normal + mod2), toMinutes(baseHeavyTime.permPress + mod2), toMinutes(baseHeavyTime.delicate + mod2)],
        small: [toMinutes(baseSmallLoadTime.normal), toMinutes(baseSmallLoadTime.permPress), toMinutes(baseSmallLoadTime.delicate)]
      };
    }),
    mediumModifierNotes: Ember.computed('CycleModifierKey1AddedExtraWashAgitateTime', 'CycleModifierKey1AddedExtraRinseAgitateTime', function () {
      let extraWash = this.CycleModifierKey1AddedExtraWashAgitateTime;
      let extraRinse = this.CycleModifierKey1AddedExtraRinseAgitateTime;
      let output = '';

      if (extraWash > 0) {
        output += `<br>+${extraWash} ${this.intl.t('min').toString()} ${this.intl.t('wash').toString()}`;
      }

      if (extraRinse > 0) {
        output += `<br>+${extraRinse} ${this.intl.t('min').toString()} ${this.intl.t('extra_rinse').toString()}`;
      }

      return output;
    }),
    heavyModifierNotes: Ember.computed('CycleModifierKey2AddedExtraWashAgitateTime', 'CycleModifierKey2AddedExtraRinseAgitateTime', function () {
      let extraWash = this.CycleModifierKey2AddedExtraWashAgitateTime;
      let extraRinse = this.CycleModifierKey2AddedExtraRinseAgitateTime;
      let output = '';

      if (extraWash > 0) {
        output += `<br>+${extraWash} ${this.intl.t('min').toString()} ${this.intl.t('wash').toString()}`;
      }

      if (extraRinse > 0) {
        output += `<br>+${extraRinse} ${this.intl.t('min').toString()} ${this.intl.t('extra_rinse').toString()}`;
      }

      return output;
    }),
    smallNotes: Ember.computed('smallLoadEnabled', function () {
      let smallLoad = this.smallLoadEnabled;
      return smallLoad ? `<br>${this.intl.t('wash_reduced').toString()} 1 ${this.intl.t('min').toString()}` : '';
    }),
    // #endregion
    // #region Notes displayed beside agitation times
    fixedWashTimeNote: Ember.computed('fixedWashTime', 'fixedWashSeconds', function () {
      let fixedTime = Math.ceil(this.fixedWashSeconds / 60);
      return `+${fixedTime} ${this.intl.t('minutes_full_fill').toString()}`;
    }),
    fixedRinseTimeNote: Ember.computed('cycle.SprayRinseEnabled', 'tubSize', 'model.settings.Program.vendrelatedmessage.addrinseagitationtimemod2tlw', function () {
      let tubSize = Ember.get(this, 'tubSize');
      let fixedFillTime = Math.ceil(Ember.get(this, 'cycleTimes.rinseFill') / 60); // always fill

      let fixedSprayTime = Math.ceil(Ember.get(this, `cycleTimes.rinseSpray.${tubSize}`) / 60); // can be fill or spray

      let sprayEnabled = Ember.get(this, 'cycle.SprayRinseEnabled');

      if (sprayEnabled) {
        return `+${fixedSprayTime} minutes spray, <br>or ${fixedFillTime} minutes full fill`;
      } else {
        return `+${fixedFillTime} minutes full fill`;
      }
    }),
    fixedExtraRinseTimeNote: Ember.computed('fixedExtraRinseSeconds', function () {
      let fixedTime = Math.ceil(this.fixedExtraRinseSeconds / 60);
      return `+${fixedTime} minutes full fill`;
    }),

    // #endregion
    // #region Calculating cycle times
    getCycleTimeTotals(type) {
      // Calculate wash times, taken from Windows application code.
      // let userWash = 60 * (get(this, `model.settings.Program.Cycle.${type}WashAgitateMinutes`) || 0);
      let userWash = 60 * (Ember.get(this, `${type}WashAgitateMinutes`) || 0);
      let fixedWash = this.fixedWashSeconds || 0; // Based on tub size

      let mediumWashModifier = 60 * (this.CycleModifierKey1AddedExtraWashAgitateTime || 0);
      let heavyWashModifier = 60 * (this.CycleModifierKey2AddedExtraWashAgitateTime || 0);
      let lightWash = addFixedTime(userWash, fixedWash);
      let smallWash = lightWash ? lightWash - 60 : 0; // Small loads get 1 minute subtracted

      let mediumWash = addFixedTime(userWash + mediumWashModifier, fixedWash);
      let heavyWash = addFixedTime(userWash + heavyWashModifier, fixedWash); // Calculate rinse times
      // let userRinse = 60 * (get(this, `model.settings.Program.Cycle.${type}HeavyRinseAgitateMinutes`) || 0);

      let userRinse = 60 * (Ember.get(this, `${type}HeavyRinseAgitateMinutes`) || 0);
      let sprayEnabled = this.model.settings.Program.cyclerelatedmessage.sprayrinse;
      let fixedSprayRinseSeconds = this.fixedSprayRinseSeconds; // Based on tub size

      let fixedFillRinseSeconds = this.fixedFillRinseSeconds;
      let lightRinse, mediumRinse;

      if (sprayEnabled) {
        // Always add spray time and DO NOT add user time
        lightRinse = addFixedTime(fixedSprayRinseSeconds, 0);
        mediumRinse = addFixedTime(fixedSprayRinseSeconds, 0);
      } else {
        lightRinse = addFixedTime(userRinse, fixedFillRinseSeconds);
        mediumRinse = addFixedTime(userRinse, fixedFillRinseSeconds);
      }

      let heavyRinse = addFixedTime(userRinse, fixedFillRinseSeconds); // Calculate Extra Rinse
      // let userExtraRinse = 60 * (get(this, `model.settings.Program.Cycle.${type}ExtraRinseAgitateMinutes`) || 0);

      let userExtraRinse = 60 * (Ember.get(this, `${type}ExtraRinseAgitateMinutes`) || 0);
      let fixedExtraRinseSeconds = this.cycleTimes.extraRinse;
      let mediumRinseModifier = 60 * (this.CycleModifierKey1AddedExtraRinseAgitateTime || 0);
      let heavyRinseModifier = 60 * (this.CycleModifierKey2AddedExtraRinseAgitateTime || 0);
      let lightExtraRinse = addFixedTime(userExtraRinse, fixedFillRinseSeconds);
      let mediumExtraRinse = addFixedTime(userExtraRinse + mediumRinseModifier, fixedFillRinseSeconds);
      let heavyExtraRinse = addFixedTime(userExtraRinse + heavyRinseModifier, fixedFillRinseSeconds); // Calculate Spin

      let userSpin = 60 * (Ember.get(this, `model.settings.Program.Cycle.${type}FinalSpinMinutes`) || 0);
      return {
        light: Math.ceil((lightWash + lightRinse + lightExtraRinse + userSpin) / 60),
        medium: Math.ceil((mediumWash + mediumRinse + mediumExtraRinse + userSpin) / 60),
        heavy: Math.ceil((heavyWash + heavyRinse + heavyExtraRinse + userSpin) / 60),
        small: Math.ceil((smallWash + lightRinse + lightExtraRinse + userSpin) / 60)
      };
    },

    normalTimes: Ember.computed('sprayEnabled', 'tubSize', 'NormalWashAgitateMinutes', 'NormalHeavyRinseAgitateMinutes', 'NormalExtraRinseAgitateMinutes', 'NormalFinalSpinMinutes', 'CycleModifierKey1AddedExtraWashAgitateTime', 'CycleModifierKey1AddedExtraRinseAgitateTime', 'CycleModifierKey2AddedExtraWashAgitateTime', 'CycleModifierKey2AddedExtraRinseAgitateTime', function () {
      return this.getCycleTimeTotals('Normal');
    }),
    permPressTimes: Ember.computed('sprayEnabled', 'tubSize', 'PermPressWashAgitateMinutes', 'PermPressHeavyRinseAgitateMinutes', 'PermPressExtraRinseAgitateMinutes', 'PermPressFinalSpinMinutes', 'CycleModifierKey1AddedExtraWashAgitateTime', 'CycleModifierKey1AddedExtraRinseAgitateTime', 'CycleModifierKey2AddedExtraWashAgitateTime', 'CycleModifierKey2AddedExtraRinseAgitateTime', function () {
      return this.getCycleTimeTotals('PermPress');
    }),
    delicateTimes: Ember.computed('sprayEnabled', 'tubSize', 'DelicateWashAgitateMinutes', 'DelicateHeavyRinseAgitateMinutes', 'DelicateExtraRinseAgitateMinutes', 'DelicateFinalSpinMinutes', 'CycleModifierKey1AddedExtraWashAgitateTime', 'CycleModifierKey1AddedExtraRinseAgitateTime', 'CycleModifierKey2AddedExtraWashAgitateTime', 'CycleModifierKey2AddedExtraRinseAgitateTime', function () {
      return this.getCycleTimeTotals('Delicate');
    }),
    // #endregion
    // #region Cycle fixed times in seconds
    // The following provide fixed seconds, based on tub size and spray enabled
    fixedWashSeconds: Ember.computed('tubSize', function () {
      // Wash time depends on size of tub
      let tubSize = this.tubSize;
      return Ember.get(this, `cycleTimes.wash.${tubSize}`);
    }),
    fixedSprayRinseSeconds: Ember.computed('tubSize', function () {
      let tubSize = this.tubSize;
      return Ember.get(this, `cycleTimes.rinseSpray.${tubSize}`) || 0;
    }),
    fixedFillRinseSeconds: Ember.computed('cycleTimes.rinseFill', function () {
      return this.cycleTimes.rinseFill || 0;
    }),
    fixedExtraRinseSeconds: Ember.computed('cycleTimes.rinseFill', function () {
      // Extra rinse is alway fill
      return this.cycleTimes.rinseFill;
    }),
    // #endregion
    // #region Water Use

    /*
      We need to recalculate the water use when observed properties change.
       Water use is different for each stage/step: Wash, Rinse, and Extra Rinse. Different cycles may have different
      steps enabled, and different settings impact water use, such as Timed Warm Fill, Warm Rinse, Spray Rinse, and modifiers. The size
      of the machine also matters...tall tub vs. standard tub.
    */
    onWaterChanged: Ember.observer('normalTimes', 'permPressTimes', 'delicateTimes', 'model.settings._convertToLiters', function () {
      this.calculateWater();
    }),

    calculateWater() {
      try {
        let totalWater = {};
        ['Normal', 'PermPress', 'Delicate'].forEach(cycle => {
          totalWater[cycle] = {};
          ['cold', 'warm', 'hot'].forEach(temp => {
            totalWater[cycle][temp] = {};
            ['small', 'light', 'medium', 'heavy'].forEach(modifier => {
              totalWater[cycle][temp][modifier] = {};
              let water = {
                hot: 0,
                cold: 0
              };
              let washWater, rinseWater, extraWater;

              if (Ember.get(this, `${cycle}WashAgitateMinutes`) > 0) {
                washWater = this.calculateWashWaterUse(modifier, temp);
              }

              if (Ember.get(this, `${cycle}HeavyRinseAgitateMinutes`) > 0) {
                rinseWater = this.calculateRinseWaterUse(modifier, temp, false); // false for normal rinse
              }

              if (Ember.get(this, `${cycle}ExtraRinseAgitateMinutes`) > 0) {
                extraWater = this.calculateRinseWaterUse(modifier, temp, true); // true for extra rinse
              }

              water.hot += washWater && washWater.hot || 0;
              water.hot += rinseWater && rinseWater.hot || 0;
              water.hot += extraWater && extraWater.hot || 0;
              water.cold += washWater && washWater.cold || 0;
              water.cold += rinseWater && rinseWater.cold || 0;
              water.cold += extraWater && extraWater.cold || 0;

              if (this.model.settings._convertToLiters) {
                // Values must be numbers for HighCharts to re-render correctly
                totalWater[cycle][temp][modifier].cold = Number((water.cold *= 3.785412).toFixed(1));
                totalWater[cycle][temp][modifier].hot = Number((water.hot *= 3.785412).toFixed(1));
              } else {
                totalWater[cycle][temp][modifier].cold = water.cold;
                totalWater[cycle][temp][modifier].hot = water.hot;
              }
            });
          });
        });
        Ember.set(this, 'totalWater', totalWater);
      } catch (err) {
        console.log('Error while calculating water use data.'); // eslint-disable-line no-console
      }
    },

    // TITANIUM
    isSmallLoadEnabled: Ember.computed.alias('model.settings.Program.cycleRelatedMessage.smallLoad'),
    isSprayRinseEnabled: Ember.computed.alias('model.settings.Program.cycleRelatedMessage.sprayRinse'),
    isEnergySaver: Ember.computed.alias('model.settings.Program.cycleRelatedMessage.timedHotFill'),
    isWarmRinseEnabled: Ember.computed.alias('model.settings.Program.Cycle.warmRinseOption'),

    // isSmallLoadEnabled: alias('machineProgram.settings.Program.Cycle.SmallLoadEnabled'),
    // isSprayRinseEnabled: alias('machineProgram.settings.Program.Cycle.SprayRinseEnabled'),
    // isEnergySaver: alias('machineProgram.settings.Program.Cycle.TimedHotFillEnabled'),
    // isWarmRinseEnabled: alias('machineProgram.settings.Program.Cycle.isWarmRinseEnabled'),
    calculateWashWaterUse(modifier, cycleTemp) {
      let water = {
        cold: 0,
        hot: 0
      };
      let isEnergySaver = this.isEnergySaver;
      let isTall = this.tubSize === 'tall' ? true : false;

      if (modifier == enums.modifier.smallLoad) {
        // Small load uses less water
        if (isEnergySaver) {
          // Energy saver uses less hot water
          switch (cycleTemp) {
            case 'cold':
              water = isTall ? {
                cold: 13.2,
                hot: 0
              } : {
                cold: 8,
                hot: 0
              };
              break;

            case 'warm':
              water = isTall ? {
                cold: 11.6,
                hot: 1.6
              } : {
                cold: 6.6,
                hot: 1.4
              };
              break;

            case 'hot':
              water = isTall ? {
                cold: 9.4,
                hot: 3.8
              } : {
                cold: 4.5,
                hot: 3.5
              };
              break;
          }
        } else {
          // Not energy saver
          switch (cycleTemp) {
            case 'cold':
              water = isTall ? {
                cold: 13.2,
                hot: 0
              } : {
                cold: 8,
                hot: 0
              };
              break;

            case 'warm':
              water = isTall ? {
                cold: 6.6,
                hot: 6.6
              } : {
                cold: 4,
                hot: 4
              };
              break;

            case 'hot':
              water = isTall ? {
                cold: 0,
                hot: 13.2
              } : {
                cold: 0,
                hot: 8
              };
              break;
          }
        }
      } else {
        // Not a small load
        if (modifier == enums.modifier.light && isEnergySaver) {
          // Light load with energy saver
          switch (cycleTemp) {
            case 'cold':
              water = isTall ? {
                cold: 19,
                hot: 0
              } : {
                cold: 18,
                hot: 0
              };
              break;

            case 'warm':
              water = isTall ? {
                cold: 16.5,
                hot: 2.5
              } : {
                cold: 16,
                hot: 2
              };
              break;

            case 'hot':
              water = isTall ? {
                cold: 14,
                hot: 5
              } : {
                cold: 13,
                hot: 5
              };
              break;
          }
        } else {
          // Not light load && energy saver
          switch (cycleTemp) {
            case 'cold':
              water = isTall ? {
                cold: 19,
                hot: 0
              } : {
                cold: 18,
                hot: 0
              };
              break;

            case 'warm':
              water = isTall ? {
                cold: 9.5,
                hot: 9.5
              } : {
                cold: 9,
                hot: 9
              };
              break;

            case 'hot':
              water = isTall ? {
                cold: 0,
                hot: 19
              } : {
                cold: 0,
                hot: 18
              };
              break;
          }
        }
      }

      water.cold += isTall ? 3 : 1.4; // Account for spin/spray step in wash stage

      return water;
    },

    calculateRinseWaterUse(modifier, cycleTemp, isExtraRinse) {
      let isSprayRinseEnabled = this.isSprayRinseEnabled;
      let isWarmRinseEnabled = this.isWarmRinseEnabled;
      let mediumWarmRinseEnabled = this.model.settings.Program.Price.CycleModifierKey1WarmRinseEnabled;
      let heavyWarmRinseEnabled = this.model.settings.Program.Price.CycleModifierKey2WarmRinseEnabled;
      let isTall = this.tubSize === 'tall' ? true : false;
      let isUsingWarmRinse = false;
      let water = 0;
      let isEnergySaver = isSprayRinseEnabled && !isExtraRinse && (modifier == enums.modifier.smallLoad || modifier == enums.modifier.light || modifier == enums.modifier.medium); // Get total Water

      if (isEnergySaver) {
        water = isTall ? 3.8 : 2.6;
      } else {
        switch (modifier) {
          case enums.modifier.smallLoad:
            water = isTall ? 12.5 : 7.3;
            break;

          case enums.modifier.light:
          case enums.modifier.medium:
          case enums.modifier.heavy:
            water = isTall ? 18 : 17;
            break;
        } // Warm rinse not available in extra rinse stage


        if (!isExtraRinse) {
          if (cycleTemp.toLowerCase() !== 'cold') {
            if (isWarmRinseEnabled) {
              isUsingWarmRinse = true;
            } else {
              if (modifier == enums.modifier.medium) {
                isUsingWarmRinse = mediumWarmRinseEnabled;
              } else if (modifier == enums.modifier.heavy) {
                isUsingWarmRinse = heavyWarmRinseEnabled;
              }
            }
          }
        }
      }

      let hotWater = isUsingWarmRinse ? water / 2 : 0;
      return {
        hot: hotWater,
        cold: water - hotWater
      };
    },

    // #endregion
    // #region Charts
    // Return chart options for each of the 4 charts, with a unique title for each chart.
    smallChartOptions: Ember.computed(function () {
      return this.chartOptions(this.intl.t('small_load').toString());
    }),
    lightChartOptions: Ember.computed(function () {
      return this.chartOptions(this.intl.t('light_load').toString());
    }),
    mediumChartOptions: Ember.computed(function () {
      return this.chartOptions(this.intl.t('medium_load').toString());
    }),
    heavyChartOptions: Ember.computed(function () {
      return this.chartOptions(this.intl.t('heavy_load').toString());
    }),

    // Chart options only vary in title text. All other options are the same.
    chartOptions(titleText) {
      return {
        chart: {
          type: 'column',
          spacingTop: 50
        },
        title: {
          text: titleText.toString()
        },
        xAxis: {
          categories: [this.intl.t('normal_hot').toString(), this.intl.t('normal_warm').toString(), this.intl.t('normal_cold').toString(), this.intl.t('perm_press_hot').toString(), this.intl.t('perm_press_warm').toString(), this.intl.t('perm_press_cold').toString(), this.intl.t('delicate_hot').toString(), this.intl.t('delicate_warm').toString(), this.intl.t('delicate_cold').toString()]
        },
        yAxis: {
          min: 0,
          title: {
            text: this.intl.t('water_used').toString()
          },
          stackLabels: {
            enabled: true,
            verticalAlign: 'top',
            allowOverlap: true,
            // required to prevent some total values from disappering on re-render
            style: {
              fontWeight: 'bold'
            }
          }
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            dataLabels: {
              enabled: true
            }
          }
        },
        colors: ['#efa4a4', '#cae3f8']
      };
    },

    // Data is array of 12 values for each cycle (Normal, Perm Press, Delicate) and it's temperature (hot, warm, cold).
    chartDataSmall: Ember.computed('totalWater.@each', function () {
      return this.getChartDataObject('small');
    }),
    chartDataLight: Ember.computed('totalWater.@each', function () {
      return this.getChartDataObject('light');
    }),
    chartDataMedium: Ember.computed('totalWater.@each', function () {
      return this.getChartDataObject('medium');
    }),
    chartDataHeavy: Ember.computed('totalWater.@each', function () {
      return this.getChartDataObject('heavy');
    }),

    getChartDataObject(loadSize) {
      // All charts get arrays of Hot and Cold data.
      return [{
        name: this.intl.t('hot').toString(),
        data: this.getChartDataArray(loadSize, 'hot')
      }, {
        name: this.intl.t('cold').toString(),
        data: this.getChartDataArray(loadSize, 'cold')
      }];
    },

    getChartDataArray(loadSize, waterTemp) {
      // Convert hot or cold data to an array
      let output = [];
      ['Normal', 'PermPress', 'Delicate'].forEach(cycle => {
        ['hot', 'warm', 'cold'].forEach(temp => {
          output.push(this.totalWater[cycle][temp][loadSize][waterTemp]);
        });
      });
      return output;
    },

    theme: _defaultTheme.default,
    // #endregion
    // #region Actions
    actions: {
      showModel() {
        console.log('model', this.model); // eslint-disable-line no-console

        debugger; // eslint-disable-line no-debugger
      },

      setTubSize(value) {
        Ember.set(this, 'standardTub', value);
      }

    } // #endregion

  });

  _exports.default = _default;
});