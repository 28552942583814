define("alliance-business-suite/initializers/can-access-route", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    initialize() {
      Ember.Route.reopen({
        brush: Ember.inject.service(),

        afterModel(model, transition) {
          this._super(...arguments);

          if (this.hideBrush) {
            this.brush.hide();
          }

          if (this.showBrush) {
            this.brush.show();
          }

          if (this.disableBrush) {
            this.brush.disable();
          } else {
            this.brush.enable();
          }
        },

        beforeModel(transition) {
          this._super(...arguments);

          if (transition.targetName !== 'not-found') {
            let notFoundController = this.controllerFor('not-found');
            Ember.set(notFoundController, 'previousTransition', transition);
          }

          let securityDebugging = false;
          let access = Ember.get(this, 'can-access');
          let areas = Ember.get(this, 'allowAccess');
          let $body = (0, _jquery.default)('body'); // Redirect if landing on top-level tab

          let findTargetRoute = Ember.get(this, 'subnavTabs');
          let url = transition.intent.url || transition.intent.name;
          let len = 0;

          if (url) {
            len = url.split('/').reduce((val, item) => {
              return item.length !== 0 ? val + 1 : val;
            }, 0);
          }

          if (findTargetRoute && len == 1) {
            for (let x = 0; x < findTargetRoute.length; x++) {
              let p = findTargetRoute[x];

              if (access.canAccessAny(p.allowAccess)) {
                this.transitionTo(p.link);
                break;
              }
            }
          }

          if (areas) {
            // we have something to check
            if (!access.canAccessAny(areas)) {
              $body.removeClass('route-access-true');
              $body.addClass('route-access-false');

              if (securityDebugging === true) {
                console.log('DEBUG ON: BLOCKED ACCESS TO', transition.targetName); // eslint-disable-line no-console

                $body.addClass('route-blocked');
              } else {
                console.log('BLOCKED ACCESS TO', transition.targetName); // eslint-disable-line no-console

                return this.transitionTo('not-found');
              }
            } else {
              // can access route
              $body.addClass('route-access-true');
              $body.removeClass('route-blocked');
              $body.removeClass('route-access-false');
            }
          }
        }

      });
    }

  };
  _exports.default = _default;
});