define("alliance-business-suite/adapters/employee", ["exports", "alliance-business-suite/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    access: Ember.inject.service(),
    sessionPath: 'organizations/:organizationId/',

    buildURL(modelName, id, snapshot, requestType, query) {
      let host = this.host;

      let url = this._super(modelName, id, snapshot, requestType, query);

      let organizationId = Ember.get(this, 'session.data.orgId');

      switch (requestType) {
        case 'query':
          url = `${host}/organizations/${organizationId}/employees?`;
          break;

        case 'findAll':
          url = `${host}/organizations/${organizationId}/employees?include=all`;
          break;

        case 'findRecord':
          url += `?organizationId=${organizationId}&include=all`;
          break;
        // case 'queryRecord':
        //   console.log('query', query)
        //   url = `employees/${query.userId}/?organizationId=${query.organizationId}`
        //   break;
      }

      return url;
    }

  });

  _exports.default = _default;
});