define("alliance-business-suite/models/product", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    belongsTo,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    access: Ember.inject.service(),
    productDescription: attr('string'),
    imageUrl: attr('string'),
    unitPriceCents: attr('number'),
    quantity: attr('number'),
    createdAt: attr('date'),
    createdBy: belongsTo('user', {
      inverse: null
    }),
    updatedAt: attr('date'),
    updatedBy: belongsTo('user', {
      inverse: null
    }),
    isActive: attr('boolean'),
    productType: belongsTo('productType'),

    /* Computed Properties */
    amount: Ember.computed('unitPriceCents', {
      get() {
        return this.unitPriceCents;
      }

    })
  });

  _exports.default = _default;
});