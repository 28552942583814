define("alliance-business-suite/components/ui/action-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['ui-action-bar']
  });

  _exports.default = _default;
});