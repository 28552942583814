define("alliance-business-suite/services/local-ajax", ["exports", "ember-ajax/services/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ajax.default.extend({
    access: Ember.inject.service(),
    session: Ember.inject.service(),
    headers: Ember.computed('access.accessToken', 'session.data', {
      get() {
        let headers = {};
        headers['Content-Type'] = 'application/json';
        return headers;
      }

    })
  });

  _exports.default = _default;
});