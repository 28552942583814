define("alliance-business-suite/adapters/deposit-reward", ["exports", "alliance-business-suite/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    access: Ember.inject.service(),

    /**
     * Builds the URL for a request.
     *
     * @public
     */
    buildURL(modelName, id, snapshot, requestType, query) {
      let host = this.host;

      let url = this._super(modelName, id, snapshot, requestType, query);

      let tempRewardProgramId = Ember.get(this, 'access.accessRewardProgramId'); // /organizations/{organizationId}/rewardsPrograms

      if (requestType === 'createRecord') {
        url = `${host}/rewardsPrograms/${tempRewardProgramId}/depositRewards`;
      }

      if (requestType === 'destroyRecord') {
        url = `${host}/depositRewards/${id}`;
      }

      return url;
    }

  });

  _exports.default = _default;
});