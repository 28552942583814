define("alliance-business-suite/components/summary/reports/s-wdf-status-revenue", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "alliance-business-suite/utils/currency-format-util", "alliance-business-suite/utils/report-tables-util"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _currencyFormatUtil, _reportTablesUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  let SummaryReportsSWdfStatusRevenueComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, (_class = class SummaryReportsSWdfStatusRevenueComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor3, this);
    }

    get translatedColumns() {
      return [{
        name: 'Organization',
        valuePath: 'organizationName',
        width: 375,
        isFixed: 'left'
      }, {
        name: 'drop_off_pick_up',
        valuePath: 'pickupDropOffRange',
        width: 225
      }, {
        name: 'order_number',
        valuePath: 'orderNumber',
        width: 225
      }, {
        name: 'order_items',
        valuePath: 'description',
        width: 225
      }, {
        name: 'Weight',
        valuePath: 'weight',
        width: 225
      }, {
        name: 'MIN_WEIGHT_OVERRIDE',
        valuePath: 'minWeight',
        width: 225
      }, {
        name: 'unit_price',
        valuePath: 'price',
        width: 225
      }, {
        name: 'Total',
        valuePath: 'totalPrice',
        width: 225
      }, {
        name: 'tax',
        valuePath: 'tax',
        width: 225
      }, {
        name: 'Name',
        valuePath: 'guestName',
        width: 225
      }, {
        name: 'Email',
        valuePath: 'emailAddress',
        width: 250
      }, {
        name: 'Phone',
        valuePath: 'phoneNumber',
        width: 225
      }, {
        name: 'order_status',
        valuePath: 'orderStatus',
        width: 225
      }, {
        name: 'promo_code',
        valuePath: 'promotionCode',
        width: 225
      }, {
        name: 'order_tax',
        valuePath: 'taxTotal',
        width: 225
      }, {
        name: 'order_total',
        valuePath: 'orderTotal',
        width: 225
      }, {
        name: 'payment_type',
        valuePath: 'paymentType',
        width: 225
      }, {
        name: 'machines',
        valuePath: 'machinesUsedInOrder',
        width: 225
      }];
    }

    get rowsWithChildren() {
      let {
        model
      } = this.args;
      let geoBoundariesObjects = model.geoBoundaries;
      let rowData = [];
      let wdfStatusRevenueArray = [];

      let orgOrderTotal = _currencyFormatUtil.formatCurrencyString.call(this, model.orderTotal || 0);

      let orgOrderTax = _currencyFormatUtil.formatCurrencyString.call(this, model.taxTotal || 0);

      rowData.push({
        organizationName: model.organizationName,
        orderTotal: orgOrderTotal,
        taxTotal: orgOrderTax,
        children: wdfStatusRevenueArray
      });
      geoBoundariesObjects.forEach(geoBoundaryObject => {
        let geoBoundArray = [];

        let geoOrderTotal = _currencyFormatUtil.formatCurrencyString.call(this, geoBoundaryObject.orderTotal || 0);

        let geoOrderTax = _currencyFormatUtil.formatCurrencyString.call(this, geoBoundaryObject.taxTotal || 0);

        wdfStatusRevenueArray.push({
          organizationName: geoBoundaryObject.name,
          orderTotal: geoOrderTotal,
          taxTotal: geoOrderTax,
          children: geoBoundArray
        });
        let roomsArray = [];
        geoBoundaryObject.rooms.forEach(room => {
          let roomOrderTotal = _currencyFormatUtil.formatCurrencyString.call(this, room.orderTotal || 0);

          let roomOrderTax = _currencyFormatUtil.formatCurrencyString.call(this, room.taxTotal || 0);

          geoBoundArray.push({
            organizationName: room.name,
            orderTotal: roomOrderTotal,
            taxTotal: roomOrderTax,
            children: roomsArray
          });
          room.orders.forEach(order => {
            if (order.items.length > 0) {
              let itemsArray = [];
              const dropOffDate = order.dropOffDate ? moment(order.dropOffDate).format(`${_reportTablesUtil.formatDateTimeString.call(this)}`) : "";
              const pickedUpDate = order.pickedUpDate ? moment(order.pickedUpDate).format(`${_reportTablesUtil.formatDateTimeString.call(this)}`) : "";
              let paymentType = order.paymentType ? this.intl.t(order.paymentType) : null;
              roomsArray.push({
                organizationName: `Order Number: ${order.orderNumber}`,
                pickupDropOffRange: `${dropOffDate} - ${pickedUpDate.length > 0 ? pickedUpDate : 'N/A'}`,
                orderNumber: order.orderNumber,
                guestName: order.guestName,
                emailAddress: order.emailAddress,
                phoneNumber: order.phoneNumber,
                orderStatus: order.orderStatus,
                promotionCode: order.promotionCode,
                taxTotal: _currencyFormatUtil.formatCurrencyString.call(this, order.orderTax || 0),
                orderTotal: _currencyFormatUtil.formatCurrencyString.call(this, order.orderTotal || 0),
                paymentType: paymentType,
                isCollapsed: true,
                children: itemsArray
              });
              order.items.forEach(item => {
                itemsArray.push({
                  description: item.description,
                  weight: item.weight,
                  minWeight: item.weight > item.minWeight ? '' : item.minWeight,
                  price: _currencyFormatUtil.formatCurrencyString.call(this, item.price || 0),
                  totalPrice: _currencyFormatUtil.formatCurrencyString.call(this, item.totalPrice || 0),
                  tax: _currencyFormatUtil.formatCurrencyString.call(this, item.tax || 0)
                });
              });
            } else {
              roomsArray.push({
                pickupDropOffRange: `${order.dropOffDate} - ${order.pickedUpDate}`,
                orderNumber: order.orderNumber,
                guestName: order.guestName,
                emailAddress: order.emailAddress,
                phoneNumber: order.phoneNumber,
                orderStatus: order.orderStatus,
                promotionCode: order.promotionCode,
                taxTotal: _currencyFormatUtil.formatCurrencyString.call(this, order.orderTax || 0),
                orderTotal: _currencyFormatUtil.formatCurrencyString.call(this, order.orderTotal || 0),
                machinesUsedInOrder: machinesUsedInOrder
              });
            }

            let machinesUsedInOrder = order.machines.map(machine => {
              return _reportTablesUtil.machineNameDisplayWithPrefixSupport.call(this, machine.machineName, machine.machineNumber, machine.isWasher);
            });
            roomsArray[roomsArray.length - 1].machinesUsedInOrder = machinesUsedInOrder.join(', ');
          });
        });
      });
      return rowData;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SummaryReportsSWdfStatusRevenueComponent;
});