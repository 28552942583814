define("alliance-business-suite/templates/components/table/programs/send/table-cell-machine-name", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EUMTGbaE",
    "block": "{\"symbols\":[\"@record\"],\"statements\":[[10,\"div\"],[14,0,\"machine-type-icon\"],[12],[2,\"\\n  \"],[1,[30,[36,0],null,[[\"machineName\",\"machineNumber\",\"displayMachineType\",\"isDryer\",\"machineType\"],[[32,1,[\"machineName\"]],[32,1,[\"machineNumber\"]],true,[32,1,[\"isDryer\"]],[32,1,[\"machineTypeId\"]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"machine-display-name\"]}",
    "moduleName": "alliance-business-suite/templates/components/table/programs/send/table-cell-machine-name.hbs"
  });

  _exports.default = _default;
});