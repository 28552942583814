define("alliance-business-suite/components/summary/reports/s-peak-times", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SummaryReportsSPeakTimesComponent extends _component.default {
    get translatedColumns() {
      return [{
        name: 'Organization/Room/Day',
        valuePath: 'name',
        width: 500,
        isFixed: 'left'
      }, {
        name: '12 A.M',
        valuePath: 'hour0',
        width: 180
      }, {
        name: '1 A.M',
        valuePath: 'hour1',
        width: 180
      }, {
        name: '2 A.M',
        valuePath: 'hour2',
        width: 180
      }, {
        name: '3 A.M',
        valuePath: 'hour3',
        width: 180
      }, {
        name: '4 A.M',
        valuePath: 'hour4',
        width: 180
      }, {
        name: '5 A.M',
        valuePath: 'hour5',
        width: 180
      }, {
        name: '6 A.M',
        valuePath: 'hour6',
        width: 180
      }, {
        name: '7 A.M',
        valuePath: 'hour7',
        width: 180
      }, {
        name: '8 A.M',
        valuePath: 'hour8',
        width: 180
      }, {
        name: '9 A.M',
        valuePath: 'hour9',
        width: 180
      }, {
        name: '10 A.M',
        valuePath: 'hour10',
        width: 180
      }, {
        name: '11 A.M',
        valuePath: 'hour11',
        width: 180
      }, {
        name: '12 P.M',
        valuePath: 'hour12',
        width: 180
      }, {
        name: '1 P.M',
        valuePath: 'hour13',
        width: 180
      }, {
        name: '2 P.M',
        valuePath: 'hour14',
        width: 180
      }, {
        name: '3 P.M',
        valuePath: 'hour15',
        width: 180
      }, {
        name: '4 P.M',
        valuePath: 'hour16',
        width: 180
      }, {
        name: '5 P.M',
        valuePath: 'hour17',
        width: 180
      }, {
        name: '6 P.M',
        valuePath: 'hour18',
        width: 180
      }, {
        name: '7 P.M',
        valuePath: 'hour19',
        width: 180
      }, {
        name: '8 P.M',
        valuePath: 'hour20',
        width: 180
      }, {
        name: '9 P.M',
        valuePath: 'hour21',
        width: 180
      }, {
        name: '10 P.M',
        valuePath: 'hour22',
        width: 180
      }, {
        name: '11 P.M',
        valuePath: 'hour23',
        width: 180
      }];
    }

    get rowsWithChildren() {
      let {
        model
      } = this.args;
      let roomData = model.rooms;
      let rowData = [];
      let userDataArray = [];
      rowData.push({
        name: model.organizationName,
        children: userDataArray
      });
      roomData.forEach(room => {
        let peakTimeData = [];
        userDataArray.push({
          name: room.name,
          children: peakTimeData
        });
        room.days.forEach(day => {
          let results = {
            name: day.dayOfWeekName
          };

          for (var i = 0; i <= 23; i++) {
            results[`hour${i}`] = `${day.hours[i]}%`;
          }

          peakTimeData.push(results);
        });
      });
      return rowData;
    }

  }

  _exports.default = SummaryReportsSPeakTimesComponent;
});