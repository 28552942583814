define("alliance-business-suite/adapters/alert", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-data/adapter/rest", "alliance-business-suite/config/environment"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _rest, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  let AlertAdapter = (_dec = Ember.inject.service, (_class = class AlertAdapter extends _rest.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "session", _descriptor, this);
      (0, _defineProperty2.default)(this, "host", _environment.default.APP.INSIGHTS_API_URL);
    }

    get headers() {
      let {
        token
      } = this.session.data.authenticated;
      return {
        'content-type': 'application/json',
        'app': _environment.default.APP.APP_IDENTIFIER,
        'Authorization': `Bearer ${token}`
      };
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = AlertAdapter;
});