define("alliance-business-suite/helpers/show-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function showChart(params, hash) {
    let {
      chart,
      isLaundromat,
      hasAccess,
      isOpl
    } = hash;
    let canShow; // if no access, never show

    if (!hasAccess) {
      return false;
    }

    if (isLaundromat) {
      canShow = chart.visibility.indexOf('laundromat') != -1;
    } else {
      canShow = chart.visibility.indexOf('route') != -1;
    } // opl flag trumps all


    if (isOpl && chart.visibility.indexOf('opl') != -1) {
      canShow = true;
    }

    return canShow;
  });

  _exports.default = _default;
});