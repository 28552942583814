define("alliance-business-suite/models/price-adjustment", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    belongsTo,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    isDryer: attr('boolean'),
    amount: attr('number'),
    isPercentage: attr('boolean'),
    maxAmount: attr('number'),
    paymentTypeLookupKey: attr('string'),
    geoBoundary: belongsTo('geoBoundary'),
    paymentType: belongsTo('paymentType')
  });

  _exports.default = _default;
});