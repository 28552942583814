define("alliance-business-suite/components/wash-alert/wa-item-info", ["exports", "fabric", "bowser", "jquery"], function (_exports, _fabric, _bowser, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['wa-info'],
    zIndexItems: ["machine", "text", "image"],
    // These items can be moved to different layers/z-indexes
    zLockedItems: ["grid", "gridLayer", "layout"],
    isIE: Ember.computed(function () {
      const browser = _bowser.default.getParser(window.navigator.userAgent);

      return browser.getBrowser().name == 'Internet Explorer';
    }),
    hasGrid: Ember.computed('', function () {
      return Ember.get(this, 'model.grid') != null;
    }),
    hasBackgroundImage: true,
    isBackground: Ember.computed('item', 'editBackground', function () {
      // if (this.item !== null) return false;
      return this.editBackground ? true : false;
    }),
    isMachine: Ember.computed('item.machineNumber', 'isBackground', function () {
      if (this.isBackground) return false;
      return this.item?.machineNumber ? true : false;
    }),
    isImage: Ember.computed('item.id', 'isBackground', function () {
      if (this.isBackground) return false;
      return this.item?.id === "image" ? true : false;
    }),
    isText: Ember.computed('item.id', 'isBackground', function () {
      if (this.isBackground) return false;
      return this.item?.id === "text" ? true : false;
    }),
    actions: {
      showBgColor() {
        (0, _jquery.default)('#bgColor').click();
      },

      showBackgroundToolbar() {
        this.canvas.discardActiveObject().renderAll();
        Ember.set(this, 'editBackground', true);
      },

      hasChanged(changed) {
        this.hasChanged(changed);
        Ember.set(this, 'isModified', changed);
        this.notifyPropertyChange('isModified');
      },

      removeBgColor() {
        let canvas = this.canvas;
        Ember.set(this, 'bgColor', '');
        canvas.set('backgroundColor', null);
        canvas.renderAll();
        this.send('hasChanged', true);
      },

      toTop(item) {
        if (this.zIndexItems.includes(item.id)) {
          item.bringToFront();
          this.send('hasChanged', true);
        }
      },

      moveUp(item) {
        let sibling = this.canvas.getObjects()[this.canvas.getObjects().indexOf(item) + 1];

        if (sibling && !this.zLockedItems.includes(sibling.id)) {
          item.bringForward();
          this.send('hasChanged', true);
        }
      },

      moveDown(item) {
        let sibling = this.canvas.getObjects()[this.canvas.getObjects().indexOf(item) - 1];

        if (sibling && !this.zLockedItems.includes(sibling.id)) {
          item.sendBackwards();
          this.send('hasChanged', true);
        }
      },

      toBottom(item) {
        if (this.zIndexItems.includes(item.id)) {
          item.sendToBack();
          item.bringForward();
          if (this.hasGrid) item.bringForward();
          if (this.hasBackgroundImage) item.bringForward();
          this.send('hasChanged', true);
        }
      },

      move(direction, e) {
        if (e.shiftKey) {
          direction = "shift+" + direction;
        }

        this.move(direction);
      },

      resize(direction, e) {
        if (e.shiftKey) {
          direction = "shift+" + direction;
        }

        this.resize(direction);
      },

      removeItem() {
        this.removeItem();
      },

      toggleItalic() {
        this.toggleItalic();
      },

      toggleBold() {
        this.toggleBold();
      },

      toggleUnderline() {
        this.toggleUnderline();
      },

      changeFont(font) {
        this.changeFont(font);
      },

      changeFill(color) {
        this.changeFill(color);
      },

      changeBackground(color) {
        this.changeBackground(color);
      },

      toggleLockBackground() {
        this.toggleLockBackground();
      },

      removeBackground() {
        this.removeBackground();
      },

      chooseImageFile() {
        document.getElementById('chooseImageFile').click();
      },

      addText() {
        this.addText();
      },

      addImage(e) {
        this.addImage(e);
      },

      addBackground(e) {
        let self = this;
        let canvas = this.canvas;
        let [file] = e.target.files;
        let reader = new FileReader();
        canvas.forEachObject(obj => {
          if (obj && obj.id === 'layout') {
            canvas.remove(obj);
          }
        });
        canvas.forEachObject(obj => {
          if (obj && obj.id === 'gridLayer') {
            canvas.remove(obj);
          }
        });

        reader.onload = f => {
          let data = f.target.result;

          _fabric.fabric.Image.fromURL(data, function (img) {
            let oImg = img.set({
              id: 'layout',
              left: 0,
              top: 0,
              angle: 0,
              selectable: false
            }).scale(0.5);
            oImg.setControlsVisibility(self.backgroundControls);
            Ember.set(self, 'backgroundObj', oImg);
            canvas.add(oImg);
            canvas.sendToBack(img);
            let rect = new _fabric.fabric.Rect({
              id: 'gridLayer',
              left: 0,
              top: 0,
              width: oImg.width,
              height: oImg.height,
              fill: 'transparent',
              ignoreOnHover: true,
              angle: 0,
              borderColor: 'red',
              cornerColor: 'red'
            }).scale(0.5);
            canvas.add(rect);
            canvas.sendToBack(rect);
            rect.bringForward();

            if (self.get('hasGrid')) {
              rect.bringForward();
            }

            canvas.renderAll();
          });
        };

        reader.readAsDataURL(file);
        Ember.set(this, 'hasBackgroundImage', true);
        Ember.set(this, 'isBackgroundEditable', true);
        Ember.set(this, 'showBackgroundUpload', false);
        Ember.set(this, 'file', file);
        this.send('hasChanged', true);
      }

    }
  });

  _exports.default = _default;
});