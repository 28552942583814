define("alliance-business-suite/routes/locations/rewards/manage", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "alliance-business-suite/routes/_secureRoute"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _secureRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  let LocationsRewardsManageRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = class LocationsRewardsManageRoute extends _secureRoute.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor2, this);
      (0, _defineProperty2.default)(this, "allowAccess", 'LOCATIONS_REWARDS_VIEW_PAGE');
    }

    model(params) {
      return this.store.findRecord('rewardsProgram', params.rewards_program_id);
    } // this hasnt worked in a while, should be moved into the component itself
    // @action
    // willTransition(transition) {
    //   if (this.controller.get('unsavedChanges')) {
    //     // check if they want to abandon changes
    //     let abandonMessage = this.intl.t('unsaved_changes_abandon');
    //     if (window.confirm(abandonMessage)) {
    //       this.controller.rollbackAllChanges(); // resetting newDepositFlatAmount is just not that important...
    //       return true;
    //     } else {
    //       // Don't bubble the `willTransition` action so that
    //       // parent routes can decide whether or not to abort.
    //       transition.abort();
    //     }
    //   } else {
    //     return true;
    //   }
    // }


    setupController(controller, model) {
      super.setupController(controller, model);
      controller.parentModel = this.modelFor('locations.rewards');
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = LocationsRewardsManageRoute;
});