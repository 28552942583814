define("alliance-business-suite/components/machines/m-edit-form", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'model.machine.machineName': [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })]
  });

  var _default = Ember.Component.extend(Validations, {
    intl: Ember.inject.service(),
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    access: Ember.inject.service(),
    notifications: Ember.inject.service(),
    isSaving: false,

    didInsertElement() {
      this._super(...arguments);

      this.setCurrentMachineType();
    },

    // editingDisabled: computed(function() {
    //   return !this['can-access'].canAccess('LOCATIONS_EDIT_DETAIL_VIEW');
    // }).readOnly(),
    // set the initial machine type based for the dropdown
    setCurrentMachineType() {
      let {
        model,
        store
      } = this;
      let {
        machine,
        machineTypes
      } = model;
      let machinePeek = store.peekRecord('machine', machine.get('id'));
      let currentMachineType = machinePeek.get('machineType').get('id');
      machineTypes.forEach(type => {
        if (type.get('id') == currentMachineType) {
          this.set('selectedMachineType', type);
        }
      });
    },

    showCoinVaultEdit: Ember.computed('model.machine.isVended', 'access.isOpl', function () {
      if (this.access.isOpl) {
        return false;
      }

      return this.model.machine.isVended;
    }),
    safeguardEmptyMachineName: Ember.observer('model.machine.machineName', function () {
      let {
        model
      } = this;
      let {
        machine
      } = model;

      if (!machine.machineName) {
        this.set('isEmptyName', true);
      }
    }),
    actions: {
      updateMachineType(newMachineType) {
        let {
          machineTypes,
          store
        } = this;
        this.set('selectedMachineType', store.peekRecord('machine-type', newMachineType.get('id')));
        this.set('model.machine.machineType', store.peekRecord('machine-type', newMachineType.get('id')));
      },

      exit() {
        let {
          model
        } = this;
        let {
          machine
        } = model; // Revert changes

        if (machine.get('hasDirtyAttributes')) {
          machine.rollbackAttributes();
        }

        Ember.get(this, 'router').transitionTo('machines.list');
      },

      save() {
        if (this.access.isDemo) {
          alert('Demo mode restricted.');
          return;
        }

        let self = this;
        let {
          model
        } = this;
        let {
          machine
        } = model;
        let coinVaultSize = machine.get('coinVaultSize');
        this.set('isSaving', true); // confirm coin vault size is greater than zero

        if (isNaN(coinVaultSize) || coinVaultSize <= 0) {
          Ember.set(this, 'isSaving', false);
          Ember.get(this, 'notifications').error(this.intl.t('invalid_coin_vault_size'), {
            autoClear: true,
            clearDuration: 2000
          });
          return;
        }

        if (!confirm(`${this.intl.t('confirm_machine_edit')}`)) {
          this.set('isSaving', false);
          return;
        }

        machine.save().then(function () {
          self.set('isSaving', false);
          self.notifications.success(self.get('intl').t('success'), {
            autoClear: true,
            clearDuration: 2000
          });
          self.router.transitionTo('machines.list');
        }, function () {
          self.set('isSaving', false);
          self.notifications.error(self.get('intl').t('save_failed'));
        });
      }

    }
  });

  _exports.default = _default;
});