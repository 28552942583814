define("alliance-business-suite/components/summary/reports/s-what-sold", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "alliance-business-suite/utils/currency-format-util"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _currencyFormatUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  let SummaryReportsSWhatSoldComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, (_class = class SummaryReportsSWhatSoldComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor3, this);
    }

    get translatedColumns() {
      return [{
        name: 'location',
        valuePath: 'roomName',
        width: 180,
        isFixed: 'left'
      }, {
        name: 'items_name',
        valuePath: 'itemDescription',
        width: 180
      }, {
        name: 'unit_price',
        valuePath: 'unitPrice',
        width: 180
      }, {
        name: 'quantity_sold',
        valuePath: 'quantitySold',
        width: 180
      }, {
        name: 'total_revenue',
        valuePath: 'totalRevenue',
        width: 180
      }];
    }

    get rowsWithChildren() {
      let {
        model
      } = this.args;
      let orgRooms = model.orgRooms;
      let rowData = [];
      orgRooms.forEach(orgRoom => {
        let sectionDataArray = [];
        rowData.push({
          roomName: orgRoom.roomName,
          children: sectionDataArray
        });
        orgRoom.sections.forEach(section => {
          let monthDataArray = [];
          sectionDataArray.push({
            roomName: section.sectionName || 'N/A',
            children: monthDataArray
          });
          section.months.forEach(month => {
            let itemDataArray = [];

            if (!month.monthName) {
              month.items.forEach(item => {
                monthDataArray.push({
                  itemDescription: item.itemDescription,
                  unitPrice: _currencyFormatUtil.formatCurrencyString.call(this, item.unitPrice || 0),
                  quantitySold: item.quantitySold || 0,
                  totalRevenue: _currencyFormatUtil.formatCurrencyString.call(this, item.totalRevenue || 0)
                });
              });
            } else {
              monthDataArray.push({
                roomName: `${month.monthName} - ${month.year}`,
                children: itemDataArray
              });
              month.items.forEach(item => {
                itemDataArray.push({
                  itemDescription: item.itemDescription,
                  unitPrice: _currencyFormatUtil.formatCurrencyString.call(this, item.unitPrice || 0),
                  quantitySold: item.quantitySold || 0,
                  totalRevenue: _currencyFormatUtil.formatCurrencyString.call(this, item.totalRevenue || 0)
                });
              });
            }
          });
        });
      });
      return rowData;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SummaryReportsSWhatSoldComponent;
});