define("alliance-business-suite/components/summary/reports/s-audit-additional-cycle", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "alliance-business-suite/utils/report-tables-util"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _reportTablesUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  let SummaryReportsSAuditAdditionalCycleComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, (_class = class SummaryReportsSAuditAdditionalCycleComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "brand", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor4, this);
    }

    get translatedColumns() {
      return [{
        name: 'location_machine',
        valuePath: 'name',
        width: 379,
        isFixed: 'left'
      }, {
        name: 'date_range',
        valuePath: 'dateRange',
        width: 339
      }, {
        name: 'extra_wash_modifier',
        valuePath: 'mediumModifier',
        width: 180
      }, {
        name: 'extra_wash_percent',
        valuePath: 'mediumModifierPercent',
        width: 222
      }, {
        name: 'extra_rinse_modifier',
        valuePath: 'heavyModifier',
        width: 180
      }, {
        name: 'extra_rinse_percent',
        valuePath: 'heavyModifierPercent',
        width: 216
      }, {
        name: 'total_cycle',
        valuePath: 'totalNumberOfMachineCycles',
        width: 180
      }];
    }

    get rowsWithChildren() {
      let {
        model
      } = this.args;
      let geoBoundaries = model.geoBoundaries;
      let rowData = [];
      geoBoundaries.forEach(geoBoundary => {
        let rowArray = [];
        let nameArray = [];
        let cycleSum = 0;
        let medModSum = 0;
        let heavModSum = 0;
        let totalRoomCycles = [];
        let totalMedMod = [];
        let totalheavMod = [];
        let totalMedModPer = [];
        let totalheavModPer = [];
        rowData.push({
          name: _reportTablesUtil.localizeString.call(this, geoBoundary.name, 'Location', 'location'),
          children: nameArray,
          totalNumberOfMachineCycles: totalRoomCycles,
          mediumModifier: totalMedMod,
          heavyModifier: totalheavMod,
          mediumModifierPercent: totalMedModPer,
          heavyModifierPercent: totalheavModPer
        });
        geoBoundary.rooms.forEach(room => {
          let cycleSum1 = 0;
          let medModSum1 = 0;
          let heavModSum1 = 0;
          let totalRoomCycles1 = [];
          let totalMedMod1 = [];
          let totalheavMod1 = [];
          let totalMedModPer1 = [];
          let totalheavModPer1 = [];
          nameArray.push({
            name: room.name,
            children: rowArray,
            totalNumberOfMachineCycles: totalRoomCycles1,
            mediumModifier: totalMedMod1,
            heavyModifier: totalheavMod1,
            mediumModifierPercent: totalMedModPer1,
            heavyModifierPercent: totalheavModPer1
          });
          room.machineAuditGroupTypes.forEach(machineAuditType => {
            machineAuditType.machineTypes.forEach(machineType => {
              let machineArray = [];
              let machineTypeName = `${machineAuditType.name} - ${machineType.name}`;
              let cycleSum2 = 0;
              let medModSum2 = 0;
              let heavModSum2 = 0;
              let totalRoomCycles2 = [];
              let totalMedMod2 = [];
              let totalheavMod2 = [];
              let totalMedModPer2 = [];
              let totalheavModPer2 = [];
              rowArray.push({
                name: machineTypeName,
                machineTypeName: machineType.name,
                children: machineArray,
                totalNumberOfMachineCycles: totalRoomCycles2,
                mediumModifier: totalMedMod2,
                heavyModifier: totalheavMod2,
                mediumModifierPercent: totalMedModPer2,
                heavyModifierPercent: totalheavModPer2
              });
              machineType.machines.forEach(machine => {
                let machineCycleArray = [];
                let medModArray = [];
                let heavModArray = [];
                let dateRange = "";

                if (this.brand.slug == 'primus') {
                  // fix the branding issue alliance-tasks/issues/2291
                  rowArray[rowArray.length - 1].name = `${machine.machineAuditType.name} - ${rowArray[rowArray.length - 1].machineTypeName}`;
                }

                if (machine.totalNumberOfMachineCycles !== 0) {
                  machineCycleArray.push(machine.totalNumberOfMachineCycles);
                  cycleSum = (0, _reportTablesUtil.totalRows)(machineCycleArray, cycleSum);
                  cycleSum1 = (0, _reportTablesUtil.totalRows)(machineCycleArray, cycleSum1);
                  cycleSum2 = (0, _reportTablesUtil.totalRows)(machineCycleArray, cycleSum2);
                }

                if (machine.mediumModifier !== 0) {
                  medModArray.push(machine.mediumModifier);
                  medModSum = (0, _reportTablesUtil.totalRows)(medModArray, medModSum);
                  medModSum1 = (0, _reportTablesUtil.totalRows)(medModArray, medModSum1);
                  medModSum2 = (0, _reportTablesUtil.totalRows)(medModArray, medModSum2);
                }

                if (machine.heavyModifier !== 0) {
                  heavModArray.push(machine.heavyModifier);
                  heavModSum = (0, _reportTablesUtil.totalRows)(heavModArray, heavModSum);
                  heavModSum1 = (0, _reportTablesUtil.totalRows)(heavModArray, heavModSum1);
                  heavModSum2 = (0, _reportTablesUtil.totalRows)(heavModArray, heavModSum2);
                }

                if (machine.minFirstReceivedAt && machine.maxFirstReceivedAt) {
                  dateRange = `${moment(machine.minFirstReceivedAt).format(_reportTablesUtil.formatDateTimeString.call(this))} - ${moment(machine.maxFirstReceivedAt).format(_reportTablesUtil.formatDateTimeString.call(this))}`;
                }

                machineArray.push({
                  name: _reportTablesUtil.machineNameDisplay.call(this, machine.machineName, machine.machineNumber),
                  dateRange: dateRange,
                  mediumModifier: machine.mediumModifier || 0,
                  mediumModifierPercent: `${(machine.mediumModifier / machine.totalNumberOfMachineCycles * 100 || 0).toFixed(2)} %`,
                  heavyModifier: machine.heavyModifier || 0,
                  heavyModifierPercent: `${(machine.heavyModifier / machine.totalNumberOfMachineCycles * 100 || 0).toFixed(2)} %`,
                  totalNumberOfMachineCycles: machine.totalNumberOfMachineCycles || 0
                });
              });
              totalRoomCycles2.push(cycleSum2 || 0);
              totalMedMod2.push(medModSum2 || 0);
              totalheavMod2.push(heavModSum2 || 0);
              totalMedModPer2.push(`${(medModSum2 / cycleSum2 * 100 || 0).toFixed(2)} %`);
              totalheavModPer2.push(`${(heavModSum2 / cycleSum2 * 100 || 0).toFixed(2)} %`);
            });
          });
          totalRoomCycles1.push(cycleSum1 || 0);
          totalMedMod1.push(medModSum1 || 0);
          totalheavMod1.push(heavModSum1 || 0);
          totalMedModPer1.push(`${(medModSum1 / cycleSum1 * 100 || 0).toFixed(2)} %`);
          totalheavModPer1.push(`${(heavModSum1 / cycleSum1 * 100 || 0).toFixed(2)} %`);
        });
        totalRoomCycles.push(cycleSum || 0);
        totalMedMod.push(medModSum || 0);
        totalheavMod.push(heavModSum || 0);
        totalMedModPer.push(`${(medModSum / cycleSum * 100 || 0).toFixed(2)} %`);
        totalheavModPer.push(`${(heavModSum / cycleSum * 100 || 0).toFixed(2)} %`);
      });
      return rowData;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "brand", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SummaryReportsSAuditAdditionalCycleComponent;
});