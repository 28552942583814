define("alliance-business-suite/helpers/component-name", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (_ref) {
    let [value, ...rest] = _ref;
    let compName = value.toString().split(':');

    if (compName.length > 2) {
      return compName[1];
    } else {
      return 'unknown component name';
    }
  });

  _exports.default = _default;
});