define("alliance-business-suite/utils/computed-length", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = computedLength;

  // Returns the length of the array
  function computedLength(objectName) {
    return Ember.computed(`this.${objectName}.[]`, function () {
      return Object.keys(this[objectName]).length;
    });
  }
});