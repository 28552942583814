define("alliance-business-suite/models/report-subscription", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    hasMany
  } = _emberData.default;

  var _default = Model.extend({
    frequency: attr('string'),
    reportKey: attr('string'),
    timeframe: attr('string'),
    userId: attr('number'),
    roomIds: attr(),
    frequencyDisplay: attr('string'),
    reportTypeDisplay: attr('string'),
    timeframeDisplay: attr('string'),
    roomsDisplay: attr('string'),
    rooms: hasMany('room')
  });

  _exports.default = _default;
});