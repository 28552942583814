define("alliance-business-suite/modifiers/split-columns", ["exports", "ember-modifier", "split.js"], function (_exports, _emberModifier, _split) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _emberModifier.modifier)((element, _ref) => {
    let [columns = 3] = _ref;
    element.classList.add("split-column-wrapper");

    let resizeElements = () => {
      // Calculate height of split columns.
      let footerHeight = document.querySelector(".view-always-footer").offsetHeight;
      let screenHeight = window.innerHeight;
      let containerTop = document.querySelector('.machines-container').offsetTop;
      let splitTop = document.querySelector('.split').offsetTop;
      let devTrayHeight = document.getElementById('dev-tool-tray') ? document.getElementById('dev-tool-tray').clientHeight : 0;
      let frameHeight = screenHeight - (containerTop + splitTop) - footerHeight - devTrayHeight - 30; // 1290 - 132 - 99 - 42

      document.querySelectorAll('.split, .gutter').forEach(el => {
        el.style.height = frameHeight + "px";
      });
    };

    let sizes;

    if (columns == 3) {
      sizes = localStorage.getItem('split-sizes');

      if (sizes) {
        sizes = JSON.parse(sizes);
      } else {
        sizes = [40, 40, 19]; // default sizes
      }
    } else {
      if (sizes) {
        sizes = localStorage.getItem('split-sizes-2');
        sizes = JSON.parse(sizes);
      } else {
        sizes = [50, 49];
      }
    }

    let divIds = columns == 3 ? ['#split1', '#split2', '#split3'] : ['#split1', '#split2'];
    let split = new _split.default(divIds, {
      sizes,
      minSize: 200,

      onDragEnd() {
        if (columns == 3) {
          localStorage.setItem('split-sizes', JSON.stringify(split.getSizes()));
        } else {
          localStorage.setItem('split-sizes-2', JSON.stringify(split.getSizes()));
        }
      }

    });
    resizeElements();
    window.addEventListener('resize', resizeElements);
    return function () {
      split.destroy();
      window.removeEventListener('resize', resizeElements);
    };
  });

  _exports.default = _default;
});