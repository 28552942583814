define("alliance-business-suite/components/form-ui/duration", ["exports", "alliance-business-suite/mixins/form-ui", "alliance-business-suite/mixins/init-property-names"], function (_exports, _formUi, _initPropertyNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function sumSeconds(hours, minutes, seconds) {
    let totalSeconds = 0;
    totalSeconds += hours * 3600;
    totalSeconds += minutes * 60;
    totalSeconds += seconds;
    return totalSeconds;
  }

  function getDurationParts(totalSeconds) {
    let hours = 0,
        minutes = 0,
        seconds = 0;

    if (totalSeconds >= 3600) {
      hours = Math.floor(totalSeconds / 3600);
      totalSeconds = totalSeconds % 3600;
    }

    if (totalSeconds >= 60) {
      minutes = Math.floor(totalSeconds / 60);
      totalSeconds = totalSeconds % 60;
    }

    seconds = totalSeconds;
    return {
      hours,
      minutes,
      seconds
    };
  }

  var _default = Ember.Component.extend(_formUi.default, _initPropertyNames.default, {
    tagName: 'div',
    classNames: ['form-field', 'overflow-float'],
    intl: Ember.inject.service(),
    // TODO: We need to deal with max/min values.
    // TODO: Support minutes without seconds.
    display: Ember.computed('params.display', function () {
      return this.params.display || 'hms';
    }),
    displayFormat: Ember.computed('params.displayFormat', function () {
      return this.params.displayFormat || 'labels';
    }),
    stringLength: Ember.computed('display.length', function () {
      return this.display.length * 2;
    }),
    isUndefinedProperty: Ember.computed('hours', 'minutes', 'seconds', function () {
      // TODO: True if all properties are undefined. This can probably be made better.
      return ['hours', 'minutes', 'seconds'].every(item => {
        return Ember.get(this, item) === undefined;
      });
    }),
    value: Ember.computed('hours', 'minutes', 'seconds', {
      get(key) {
        // We can get back only a seconds property with a value > 60. We must add up everything and recalculate things.
        let hours = this.hours || 0;
        let minutes = this.minutes || 0;
        let seconds = this.seconds || 0;
        let totalSeconds = sumSeconds(hours, minutes, seconds);
        return getDurationParts(totalSeconds);
      },

      set(key, value) {
        // Value will be { hours, minutes, seconds }
        let object = this.object;
        let {
          min = 0,
          max = 24 * 60 * 60
        } = this.params; // We need to check min/max limits, which are provided as seconds.

        let {
          hours,
          minutes,
          seconds
        } = value;
        let totalSeconds = sumSeconds(hours, minutes, seconds); // Check that value is within limits

        if (totalSeconds < min) {
          totalSeconds = min;
        }

        if (totalSeconds > max) {
          totalSeconds = max;
        } // Update needs to be based on which properties we have connected to this


        let calcSeconds = totalSeconds;

        if (this.properties.hours) {
          this.update(object, this.properties.hours.key, Math.floor(calcSeconds / 3600));
          calcSeconds = calcSeconds % 3600;
        }

        if (this.properties.minutes) {
          this.update(object, this.properties.minutes.key, Math.floor(calcSeconds / 60));
          calcSeconds = calcSeconds % 60;
        }

        if (this.properties.seconds) {
          // We should always have seconds
          this.update(object, this.properties.seconds.key, calcSeconds);
        }

        return getDurationParts(totalSeconds);
      }

    })
  });

  _exports.default = _default;
});