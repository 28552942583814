define("alliance-business-suite/templates/employees/schedules", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "znddlWHM",
    "block": "{\"symbols\":[],\"statements\":[[8,\"employees/schedule-list\",[],[[\"@model\",\"@roomId\",\"@updateQueryRoomId\"],[[32,0,[\"model\"]],[32,0,[\"roomId\"]],[32,0,[\"updateQueryRoomId\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "alliance-business-suite/templates/employees/schedules.hbs"
  });

  _exports.default = _default;
});