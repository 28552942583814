define("alliance-business-suite/components/form-ui/steps/inlets", ["exports", "alliance-business-suite/mixins/init-property-names", "alliance-business-suite/utils/computed-length"], function (_exports, _initPropertyNames, _computedLength) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_initPropertyNames.default, {
    intl: Ember.inject.service(),
    inletCountLength: (0, _computedLength.default)('object'),
    inletCount: Ember.computed('inletCountLength', '_inletCount', {
      get() {
        if (this._inletCount) {
          return this._inletCount;
        }

        return this.inletCountLength;
      },

      set(key, value) {
        Ember.set(this, "_inletCount", value);
        return value;
      }

    }),
    headerTitle: Ember.computed('maxValvesOn', function () {
      if (this.isCombo) {
        return `${this.intl.t("inlets")}`;
      } else {
        return `${this.intl.t("inlets")} (${this.maxValvesOn} ${this.intl.t("max")})`;
      }
    }),
    actions: {
      checkUpdate(object, propertyName, value, event) {
        if (this.afterChange) {
          this.afterChange(object, propertyName, value, event, 'inlet');
        } else {
          // If we're past the inlet limit, always uncheck the last selected checkbox
          console.log('*** inlets', value, this.selectedInletCount, this.maxValvesOn);

          if (value == true && this.selectedInletCount >= this.maxValvesOn) {
            console.log("Too many selected inlets."); // Undo the checkbox

            event.target.checked = false;
            return;
          }

          this.update(object, propertyName, value);
        }
      }

    }
  });

  _exports.default = _default;
});