define("alliance-business-suite/utils/subscription-methods-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DAYS_OF_WEEK = void 0;
  const DAYS_OF_WEEK = [{
    id: 0,
    text: 'sunday'
  }, {
    id: 1,
    text: 'monday'
  }, {
    id: 2,
    text: 'tuesday'
  }, {
    id: 3,
    text: 'wednesday'
  }, {
    id: 4,
    text: 'thursday'
  }, {
    id: 5,
    text: 'friday'
  }, {
    id: 6,
    text: 'saturday'
  }];
  _exports.DAYS_OF_WEEK = DAYS_OF_WEEK;
});