define("alliance-business-suite/helpers/str-method", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute(_ref
    /* , hash*/
    ) {
      let [method, string] = _ref;
      return string[method]();
    }

  });

  _exports.default = _default;
});