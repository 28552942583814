define("alliance-business-suite/components/form-ui/steps/drain", ["exports", "alliance-business-suite/mixins/init-property-names"], function (_exports, _initPropertyNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_initPropertyNames.default, {
    intl: Ember.inject.service(),
    isHidden: Ember.computed('machineProgram.machineTypeCode', function () {
      // Hide the drain for the FLW
      return this.machineProgram.machineTypeCode == "FLW";
    }),
    actions: {
      updateDrain(object, propertyName, value, e) {
        // Check if drain is required and we have two drains
        if (this.params.validations.requireDrain && this.MainDrain2 !== undefined) {
          if (value == false) {
            if (propertyName == this.properties.MainDrain1.key && this.MainDrain2 == false || propertyName == this.properties.MainDrain2.key && this.MainDrain1 == false) {
              alert(this.intl.t("minimum_one_drain"));
              e.target.checked = true;
              return;
            }
          }
        }

        this.update(object, propertyName, value);
      }

    }
  });

  _exports.default = _default;
});