define("alliance-business-suite/templates/components/tree-table-grouping-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e9S1+kkB",
    "block": "{\"symbols\":[],\"statements\":[[1,[34,0]],[2,\" \"],[1,[35,1,[\"value\"]]]],\"hasEval\":false,\"upvars\":[\"handleSymbol\",\"cell\"]}",
    "moduleName": "alliance-business-suite/templates/components/tree-table-grouping-cell.hbs"
  });

  _exports.default = _default;
});