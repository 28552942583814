define("alliance-business-suite/templates/components/customers/orders/orders-list-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ce73+4wR",
    "block": "{\"symbols\":[\"order\"],\"statements\":[[6,[37,7],[[35,6]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"loading-container loading-container--padding\"],[12],[2,\"\\n    \"],[8,\"ui-loading-throb\",[],[[],[]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,4],[[35,5]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"content-container container--placeholder\"],[12],[2,\"\\n      \"],[10,\"img\"],[14,0,\"scrubbrush-watermark\"],[14,\"src\",\"/assets/images/basket-empty.svg\"],[12],[13],[2,\"\\n      \"],[10,\"h3\"],[12],[1,[30,[36,0],[\"no_orders_found\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,4],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"table\"],[14,0,\"table orders-table\"],[12],[2,\"\\n        \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,\"customers/orders/order-list-item\",[],[[\"@order\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"content-container container--placeholder\"],[12],[2,\"\\n        \"],[10,\"img\"],[14,0,\"scrubbrush-watermark\"],[14,\"src\",\"/assets/images/basket.svg\"],[12],[13],[2,\"\\n        \"],[10,\"h3\"],[12],[1,[30,[36,0],[\"no_orders\"],null]],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"filteredOrders\",\"-track-array\",\"each\",\"if\",\"noResultsFound\",\"isLoading\",\"liquid-if\"]}",
    "moduleName": "alliance-business-suite/templates/components/customers/orders/orders-list-table.hbs"
  });

  _exports.default = _default;
});