define("alliance-business-suite/routes/employees/create", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "alliance-business-suite/routes/_secureRoute"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _secureRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  let EmployeesCreateRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, (_class = class EmployeesCreateRoute extends _secureRoute.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "cache", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor3, this);
      (0, _defineProperty2.default)(this, "allowAccess", 'EMPLOYEES_CREATE_VIEW');
    }

    model() {
      const userId = this.access.get('accessUser.id');
      const orgId = this.access.get('accessOrganization.id');

      if (!this.cache.get('loadedGeoIncludedAll')) {
        return Ember.RSVP.hash({
          newEmployee: this.store.createRecord('employee', {
            securityRoles: [],
            geoBoundaries: [],
            roleId: 'PERSON_EMPLOYEE'
          }),
          user: this.store.peekRecord('user', userId),
          organization: this.store.peekRecord('organization', orgId),
          geoBoundaries: this.store.queryRecord('organization', {
            organization: orgId,
            geoBoundaries: true,
            include: 'all'
          })
        });
      } else {
        return Ember.RSVP.hash({
          newEmployee: this.store.createRecord('employee', {
            securityRoles: [],
            geoBoundaries: [],
            roleId: 'PERSON_EMPLOYEE'
          }),
          user: this.store.peekRecord('user', userId),
          organization: this.store.peekRecord('organization', orgId),
          geoBoundaries: this.store.peekRecord('organization', orgId)
        });
      }
    }

    setupController(controller, model) {
      super.setupController(controller, model);
      let newEmployee = model.newEmployee;
      newEmployee.createdBy = model.user;
      newEmployee.organization = model.organization;
      controller.parentModel = this.modelFor('employees');
      this.cache.set('loadedGeoIncludedAll', true);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "cache", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = EmployeesCreateRoute;
});