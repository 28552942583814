define("alliance-business-suite/components/marketing/campaigns/content/promo-modal", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const TYPE_OPTIONS = [{
    id: 'REWARD_POINTS',
    text: 'reward_points'
  }, {
    id: 'REWARD_CREDIT',
    text: 'rewards_credit'
  }, {
    id: 'DISCOUNT',
    text: 'discount'
  }];
  const DISCOUNT_TYPE_OPTIONS = [{
    id: 'PERCENTAGE',
    text: 'percent'
  }, {
    id: 'FLAT_AMOUNT',
    text: 'flat_amount'
  }];
  const DISCOUNT_ITEM_OPTIONS = [{
    id: 'WASH_DRY_FOLD',
    text: 'wash_dry_fold'
  }, {
    id: 'GOODS',
    text: 'over_the_counter_goods'
  }];
  const LENGTH_OPTIONS = [{
    id: 'days',
    text: 'Days'
  }, {
    id: 'weeks',
    text: 'Weeks'
  }, {
    id: 'mons',
    text: 'Months'
  }];

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    access: Ember.inject.service(),
    intl: Ember.inject.service(),
    validPromoCode: false,
    typeOptions: Ember.computed(function () {
      let typeOptions = TYPE_OPTIONS;

      if (this.get('access.isRetail') === false) {
        typeOptions = TYPE_OPTIONS.filter(item => item.id !== 'DISCOUNT');
      }

      return typeOptions;
    }),
    discountTypeOptions: Ember.computed(function () {
      return DISCOUNT_TYPE_OPTIONS;
    }),
    discountItemOptions: Ember.computed(function () {
      return DISCOUNT_ITEM_OPTIONS;
    }),
    lengthOptions: Ember.computed(function () {
      return LENGTH_OPTIONS;
    }),
    currencySymbol: Ember.computed(function () {
      return Ember.get(this, 'access.currencyDenomination');
    }),
    currencyDecimals: Ember.computed(function () {
      if (this.get('access.currencySubunit') === 1) {
        return 0;
      } else {
        return 2;
      }
    }),
    isValid: Ember.computed('promoCode', 'validPromoCode', function () {
      let _this = this;

      let valid = false;
      let promoCode = this.promoCode;
      let validPromoCode = this.validPromoCode;
      let regEx = new RegExp('^[A-Z0-9_][A-Z0-9_]*$');

      if (promoCode && validPromoCode && validPromoCode === promoCode) {
        valid = true;
      } else if (promoCode && !regEx.test(promoCode.toUpperCase())) {
        setTimeout(function () {
          _this.set('promoCode', promoCode.substring(0, promoCode.length - 1));
        }, 1);
      }

      return valid;
    }),
    promoCode: Ember.computed(function () {
      let promoCode = this.get('campaign.campaignPromotion.promotionCode');

      if (promoCode === this.get('campaign.campaignPromotion.promotionCode')) {
        this.set('validPromoCode', promoCode);
      }

      return promoCode;
    }),
    promoType: Ember.computed(function () {
      let promoType;

      for (let i = 0; i < TYPE_OPTIONS.length; i++) {
        if (TYPE_OPTIONS[i].id == this.get('campaign.campaignPromotion.promotionTypeLookupKey')) {
          promoType = TYPE_OPTIONS[i];
        }
      }

      return promoType;
    }),
    promoValue: Ember.computed('campaign.campaignPromotion.promotionDiscountTypeLookupKey', function () {
      let value = `${this.get('campaign.campaignPromotion.value') || 0}`;

      if ((this.get('campaign.campaignPromotion.promotionDiscountTypeLookupKey') === 'FLAT_AMOUNT' || this.get('campaign.campaignPromotion.promotionTypeLookupKey') === 'REWARD_CREDIT') && this.get('access.currencySubunit') !== 1) {
        value = `${this.get('campaign.campaignPromotion.value')}`;
      }

      value = value + `%`;
      return value;
    }),
    discountMax: Ember.computed(function () {
      let value;

      if (this.get('campaign.campaignPromotion.maxDiscountAmount')) {
        if (this.get('access.currencySubunit') === 1) {
          value = this.get('campaign.campaignPromotion.maxDiscountAmount');
        } else {
          value = this.get('campaign.campaignPromotion.maxDiscountAmount');
        }
      }

      return value;
    }),
    discountType: Ember.computed(function () {
      let type;

      for (let i = 0; i < DISCOUNT_TYPE_OPTIONS.length; i++) {
        if (DISCOUNT_TYPE_OPTIONS[i].id == this.get('campaign.campaignPromotion.promotionDiscountTypeLookupKey')) {
          type = DISCOUNT_TYPE_OPTIONS[i];
        }
      }

      return type;
    }),
    discountItem: Ember.computed(function () {
      let item;

      for (let i = 0; i < DISCOUNT_ITEM_OPTIONS.length; i++) {
        if (DISCOUNT_ITEM_OPTIONS[i].id == this.get('campaign.campaignPromotion.promotionDiscountObjectTypeLookupKey')) {
          item = DISCOUNT_ITEM_OPTIONS[i];
        }
      }

      return item;
    }),
    promoLength: Ember.computed(function () {
      let _this = this;

      let length = LENGTH_OPTIONS[0];
      let promoDuration = this.promoDuration;

      if (this.get('campaign.campaignPromotion.expirationInterval')) {
        for (let i = 0; i < LENGTH_OPTIONS.length; i++) {
          if (this.get('campaign.campaignPromotion.expirationInterval').indexOf(LENGTH_OPTIONS[i].id) !== -1) {
            if (LENGTH_OPTIONS[i].id == 'days' && promoDuration % 7 === 0) {
              length = LENGTH_OPTIONS[1]; // timeout because ember is crazy about modifying a property twice (idk)

              setTimeout(function () {
                _this.set('promoDuration', promoDuration / 7);
              }, 5);
            } else {
              length = LENGTH_OPTIONS[i];
            }
          }
        }
      }

      return length;
    }),
    promoDuration: Ember.computed(function () {
      let duration = 1;

      if (this.get('campaign.campaignPromotion.expirationInterval')) {
        let interval = this.get('campaign.campaignPromotion.expirationInterval').split(' ');

        if (interval[0] && !isNaN(interval[0])) {
          duration = interval[0];
        }
      }

      return duration;
    }),

    async validate() {
      let self = this;
      let promotionCode = this.promoCode;
      let rooms = this.get('campaign.rooms');
      let roomIds = [];
      rooms.forEach(function (room) {
        roomIds.push(room.get('id'));
      });

      if (!promotionCode) {
        alert('Please enter a promotion code');
        return;
      } else if (roomIds.length === 0) {
        alert('Please first select at least 1 location for this campaign!');
        return;
      }

      this.set('isValidating', true);
      return await this.ajax.request('/promotionCodes/validate', {
        method: 'GET',
        data: {
          promotionCode,
          roomIds: `{${roomIds}}`,
          // langCode: this.get('intl.locale'),
          raw: true
        }
      }).then(function (results) {
        self.set('isValidating', false);

        if (results.canBeUsed) {
          self.set('validPromoCode', promotionCode);
        } else if (results.conflictedRooms.length) {
          let conflictedRooms = [];
          results.conflictedRooms.forEach(function (conflictedRoom) {
            conflictedRooms.push(self.get('store').peekRecord('room', conflictedRoom));
          });
          self.set('conflictedRooms', conflictedRooms);
          self.set('showPromoConflictModal', true);
        } else if (results.localizedErrorMessage) {
          alert(results.localizedErrorMessage);
        } else {
          alert('Unable to validate promo code');
        }
      }, function () {
        self.set('isValidating', false); // error message needed
      });
    },

    apply() {
      let valid = this.isValid;
      let valueSting = `${this.promoValue}`;
      let campaign = this.campaign;
      let promotionCode = this.promoCode;
      let promotionTypeLookupKey = this.get('promoType.id');
      let promotionDiscountTypeLookupKey = this.get('discountType.id');
      let promotionDiscountObjectTypeLookupKey = this.get('discountItem.id');
      let value = Number(valueSting.replace(/[^0-9]*/g, ''));
      let maxDiscountAmount = this.discountMax;
      let promoDuration = this.promoDuration;
      let promoLength = this.get('promoLength.id');

      if (!promotionTypeLookupKey) {
        alert('Please enter a promotion type');
        return;
      } else if (!value) {
        alert('Please enter a promotion amount');
        return;
      } else if (!promoDuration || !promoLength) {
        alert('Please enter a promotion length');
        return;
      } else if (promotionTypeLookupKey === 'DISCOUNT' && !promotionDiscountTypeLookupKey) {
        alert('Please enter a discount type');
        return;
      } else if (promotionTypeLookupKey === 'DISCOUNT' && promotionDiscountTypeLookupKey === 'PERCENTAGE' && !maxDiscountAmount) {
        alert('Please enter a discount maximum');
        return;
      } else if (promotionTypeLookupKey === 'DISCOUNT' && !promotionDiscountObjectTypeLookupKey) {
        alert('Please enter a discount item');
        return;
      }

      maxDiscountAmount = Math.round(maxDiscountAmount);

      if ((promotionDiscountTypeLookupKey === 'FLAT_AMOUNT' || promotionTypeLookupKey === 'REWARD_CREDIT') && this.get('access.currencySubunit') !== 1) {
        value = Math.round(value);
      } else {
        value = Math.round(value);
      }

      if (!this.get('campaign.campaignPromotion.id')) {
        let promo = this.store.createRecord('campaignPromotion', {
          campaign,
          promotionCode,
          promotionTypeLookupKey,
          promotionDiscountTypeLookupKey,
          promotionDiscountObjectTypeLookupKey,
          expirationInterval: `${promoDuration} ${promoLength}`,
          value,
          maxDiscountAmount
        });
        this.set('promo', promo);
        this.set('viewPromoEditor', false);
      } else {
        let promo = this.store.peekRecord('campaignPromotion', this.get('campaign.campaignPromotion.id'));
        promo.setProperties({
          campaign,
          promotionCode,
          promotionTypeLookupKey,
          promotionDiscountTypeLookupKey,
          promotionDiscountObjectTypeLookupKey,
          expirationInterval: `${promoDuration} ${promoLength}`,
          value,
          maxDiscountAmount
        });
        this.set('promo', promo);
        this.set('viewPromoEditor', false);
      }
    },

    actions: {
      toggleModal() {
        this.toggleProperty('viewPromoEditor');
      },

      toggleShowPromoConflictModal() {
        this.toggleProperty('showPromoConflictModal');
      },

      updatePercentage(value) {
        let rawValue = `${value}` || "0";
        let newValue = `${Number(rawValue.replace(/[^0-9]*/g, ''))}` + `%`;
        this.set('promoValue', newValue);
      },

      validatePromo() {
        this.validate();
      },

      deletePromo() {
        let promo;

        if (this.get('campaign.campaignPromotion.id')) {
          promo = this.store.peekRecord('campaignPromotion', this.get('campaign.campaignPromotion.id'));
        } else {
          promo = this.promo;
        }

        promo.deleteRecord();
        this.set('promo', promo);
        this.set('viewPromoEditor', false);
      },

      applyPromo() {
        let _this = this;

        if (!this.isValid) {
          this.validate().then(function (result) {
            if (_this.get('isValid')) {
              _this.apply();
            }
          });
        } else {
          this.apply();
        }
      }

    }
  });

  _exports.default = _default;
});