define("alliance-business-suite/models/campaign-run", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    hasMany,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    runDate: attr('string'),
    campaign: belongsTo('campaign'),
    runDateTime: Ember.computed('runDate', {
      get() {
        return moment.utc(this.runDate).local().format('LLLL');
      }

    })
  });

  _exports.default = _default;
});