define("alliance-business-suite/components/table/table-cell-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    listItems: Ember.computed('column.propertyName', 'column.format', {
      get() {
        let listItems = [];
        let record = this.record;
        let records = record.get(this.get('column.propertyName'));
        let format = this.get('column.format');

        if (records && format) {
          records.forEach(data => {
            let parsedString = format.replace(/<<([a-z]+)?[a-z]+.[a-z]*>>/gi, (match, $1) => {
              // Return the replacement leveraging the parameters.
              match = match.replace('<<', '').replace('>>', '');
              return data.get(match);
            });
            listItems.push(parsedString);
          });
        } else if (records) {
          listItems = records;
        }

        return listItems;
      }

    })
  });

  _exports.default = _default;
});