define("alliance-business-suite/app", ["exports", "@babel/runtime/helpers/esm/defineProperty", "jquery", "ember-resolver", "ember-load-initializers", "alliance-business-suite/config/environment", "alliance-business-suite/models/custom-inflector-rules", "@sentry/ember"], function (_exports, _defineProperty2, _jquery, _emberResolver, _emberLoadInitializers, _environment, _customInflectorRules, Sentry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  if (_environment.default.environment === 'development') {
    // Increase number of stack frames in stack traces
    // https://stackoverflow.com/questions/9931444/how-to-increase-number-of-call-stack-entries-in-google-chrome-developer-tools-o
    Error.stackTraceLimit = 250;

    if (_environment.default.APP.THROTTLE_LIVE_RELOADING) {
      window.onbeforeunload = () => 'Are you sure you want to reload?';
    }
  } // $('body').addClass(`theme--${config.BRAND.slug}`); // Used for brand styling
  // $('body').addClass(`body-${config.BRAND.slug}`); // Used for brand styling


  Ember.Route.reopen({
    activate() {
      this._super();

      let cssClass = this.toCssClass(); // you probably don't need the application class
      // to be added to the body

      if (cssClass !== 'application') {
        (0, _jquery.default)('body').addClass(cssClass);
      }
    },

    deactivate() {
      this._super();

      (0, _jquery.default)('body').removeClass(this.toCssClass());
    },

    toCssClass() {
      return Ember.String.dasherize(this.routeName.replace(/\./g, '-'));
    }

  }); // ED -> Fix for ember JS tree...remove on upgrade to new ember 2.16+

  if (typeof Ember.Test === 'undefined') {
    Ember.Test = {
      registerWaiter() {}

    };
  }

  Sentry.InitSentryForEmber();

  class App extends Ember.Application {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "modulePrefix", _environment.default.modulePrefix);
      (0, _defineProperty2.default)(this, "podModulePrefix", _environment.default.podModulePrefix);
      (0, _defineProperty2.default)(this, "Resolver", _emberResolver.default);
    }

  }

  _exports.default = App;
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
});