define("alliance-business-suite/templates/components/form-ui/default-bundle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "K4eciajF",
    "block": "{\"symbols\":[\"ui\"],\"statements\":[[8,\"form-ui\",[],[[\"@object\",\"@formDisplay\",\"@formSettings\",\"@selectOptions\"],[[32,0,[\"object\"]],\"compact\",[32,0,[\"formSettings\"]],[32,0,[\"selectOptions\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n\\n  \"],[8,[32,1,[\"select\"]],[],[[\"@object\",\"@update\",\"@params\"],[[32,0],[30,[36,0],[[32,0],\"updateValues\"],null],[30,[36,1],null,[[\"propertyName\",\"options\"],[\"defaultBundle\",\"DefaultBundles\"]]]]],null],[2,\"\\n\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"hash\"]}",
    "moduleName": "alliance-business-suite/templates/components/form-ui/default-bundle.hbs"
  });

  _exports.default = _default;
});