define("alliance-business-suite/templates/components/summary-bar-percentage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c8a66J2i",
    "block": "{\"symbols\":[],\"statements\":[[10,\"li\"],[14,0,\"selfService li\"],[15,5,[31,[\"width:\",[34,0,[\"selfService\"]],\"%;\"]]],[12],[13],[2,\"\\n\"],[10,\"li\"],[14,0,\"wdf li\"],[15,5,[31,[\"width:\",[34,0,[\"wdf\"]],\"%;\"]]],[12],[13],[2,\"\\n\"],[10,\"li\"],[14,0,\"goods li\"],[15,5,[31,[\"width:\",[34,0,[\"goods\"]],\"%;\"]]],[12],[13]],\"hasEval\":false,\"upvars\":[\"percentage\"]}",
    "moduleName": "alliance-business-suite/templates/components/summary-bar-percentage.hbs"
  });

  _exports.default = _default;
});