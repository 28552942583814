define("alliance-business-suite/components/form-ui/steps/step-time", ["exports", "alliance-business-suite/mixins/init-property-names"], function (_exports, _initPropertyNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_initPropertyNames.default, {
    currentlyDisabled: Ember.computed('object.ShutoffCondition', function () {
      let shutoff = Ember.get(this, 'object.ShutoffCondition');
      return shutoff === 1;
    }),
    hasHours: Ember.computed('object.Hours', function () {
      return !Ember.isNone(Ember.get(this, 'Hours'));
    }),
    hasMinutes: Ember.computed('object.Minutes', function () {
      return !Ember.isNone(Ember.get(this, 'Minutes'));
    }),
    hasSeconds: Ember.computed('object.Seconds', function () {
      return !Ember.isNone(Ember.get(this, 'Seconds'));
    }),
    validations: Ember.computed.alias('params.validations'),
    hasDelayTime: Ember.computed('DelayTime', function () {
      return !Ember.isNone(this.DelayTime);
    }),
    hasMessage: Ember.computed('Message', function () {
      return !Ember.isNone(this.Message);
    }),
    hasAutoResumeMinutes: Ember.computed('AutoResumeMinutes', function () {
      return !Ember.isNone(this.AutoResumeMinutes);
    })
  });

  _exports.default = _default;
});