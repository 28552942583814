define("alliance-business-suite/helpers/can-access", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute(_ref) {
      let [permission] = _ref;
      return this['can-access'].canAccessAny(permission);
    }

  });

  _exports.default = _default;
});