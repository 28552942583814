define("alliance-business-suite/templates/components/rooms/r-view-overview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PnlWL9p7",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[10,\"div\"],[14,0,\"brush-room-map\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"g-map-container\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[32,1,[\"lat\"]],[32,1,[\"lng\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"maps/google-map\",[],[[\"@height\",\"@zoom\",\"@markers\",\"@lat\",\"@lng\",\"@mapTypeControl\",\"@draggable\"],[\"150px\",15,[32,0,[\"markers\"]],[32,1,[\"lat\"]],[32,1,[\"lng\"]],false,false]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,1],[[30,[36,2],[\"LOCATIONS_VIEW_PEAK_CHART\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"rooms/peak-chart-container\",[],[[\"@model\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"and\",\"if\",\"can-access\"]}",
    "moduleName": "alliance-business-suite/templates/components/rooms/r-view-overview.hbs"
  });

  _exports.default = _default;
});