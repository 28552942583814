define("alliance-business-suite/adapters/price-adjustment", ["exports", "alliance-business-suite/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    store: Ember.inject.service(),

    buildURL(modelName, id, snapshot, requestType, query) {
      let url = this._super('', null, snapshot, requestType, query);

      let geoBoundaryId = Ember.get(snapshot, 'adapterOptions.geoBoundaryId');
      let priceAdjustmentId = Ember.get(snapshot, 'adapterOptions.priceAdjustmentId');

      switch (requestType) {
        case 'find':
          url += `/geoBoundaries/${geoBoundaryId}/priceAdjustments?paymentTypeLookupKey=CUSTOMER_APP`;
          return url;

        case 'findAll':
          url += `/geoBoundaries/${geoBoundaryId}/priceAdjustments?paymentTypeLookupKey=CUSTOMER_APP`;
          return url;

        case 'createRecord':
          url += `/geoBoundaries/${geoBoundaryId}/priceAdjustments`;
          return url;

        case 'updateRecord':
          url += `/priceAdjustments/${priceAdjustmentId}`;
          return url;

        case 'deleteRecord':
          url += `/priceAdjustments/${priceAdjustmentId}`;
          return url;
      }

      return url;
    }

  });

  _exports.default = _default;
});