define("alliance-business-suite/templates/components/form-ui/coin-drop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "U6wO/dpm",
    "block": "{\"symbols\":[\"ui\"],\"statements\":[[8,\"form-ui\",[],[[\"@object\",\"@formSettings\",\"@formDisplay\"],[[32,0,[\"object\"]],[32,0,[\"formSettings\"]],\"compact\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n\"],[2,\"  \"],[8,[32,1,[\"checkbox\"]],[],[[\"@object\",\"@update\",\"@params\"],[[32,0],[30,[36,0],[[32,0],\"updateCoinDrop\"],null],[30,[36,1],null,[[\"property\",\"label\"],[[32,0,[\"coinDrop\"]],[32,0,[\"coinDropLabel\"]]]]]]],null],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"form-field overflow-float display-compact\"],[14,5,\"margin-left:2em; margin-top:-10px;\"],[12],[2,\"\\n    \"],[10,\"label\"],[12],[10,\"input\"],[14,3,\"drop\"],[15,\"checked\",[30,[36,3],[[35,2],1],null]],[15,\"onclick\",[30,[36,0],[[32,0],[30,[36,4],[[35,2]],null],1],null]],[15,\"disabled\",[30,[36,6],[[30,[36,5],[[35,2]],null],true,false],null]],[14,4,\"radio\"],[12],[13],[2,\" \"],[1,[30,[36,7],[\"single_drop\"],null]],[13],[2,\"\\n    \"],[10,\"label\"],[12],[10,\"input\"],[14,3,\"drop\"],[15,\"checked\",[30,[36,3],[[35,2],2],null]],[15,\"onclick\",[30,[36,0],[[32,0],[30,[36,4],[[35,2]],null],2],null]],[15,\"disabled\",[30,[36,6],[[30,[36,5],[[35,2]],null],true,false],null]],[14,4,\"radio\"],[12],[13],[2,\" \"],[1,[30,[36,7],[\"dual_drop\"],null]],[13],[2,\"\\n    \"],[10,\"label\"],[12],[10,\"input\"],[14,3,\"drop\"],[15,\"checked\",[30,[36,3],[[35,2],3],null]],[15,\"onclick\",[30,[36,0],[[32,0],[30,[36,4],[[35,2]],null],3],null]],[15,\"disabled\",[30,[36,6],[[30,[36,5],[[35,2]],null],true,false],null]],[14,4,\"radio\"],[12],[13],[2,\" \"],[1,[30,[36,7],[[32,0,[\"worldDropLabel\"]]],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"hash\",\"currentDrop\",\"eq\",\"mut\",\"not\",\"if\",\"t\"]}",
    "moduleName": "alliance-business-suite/templates/components/form-ui/coin-drop.hbs"
  });

  _exports.default = _default;
});