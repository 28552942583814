define("alliance-business-suite/utils/machine-programming-property-names", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.replaceIndexPlaceholder = _exports.getValidPropertyValue = _exports.findValidPropertyName = _exports.definedPropertyValue = _exports.defined = void 0;

  const findValidPropertyName = (object, propertyNames, index) => {
    /**
     * Checks an array of propertyNames and returns the first one that exists on the object.
     * Returns the name (string) of the first property that exists on the object with any index placeholders resolved.
     */
    if (Ember.typeOf(propertyNames) !== "array") {
      console.error("findValidPropertyName requires an array.");
    }

    for (const prop of propertyNames) {
      let propertyName = replaceIndexPlaceholder(prop, index);

      if (Ember.get(object, propertyName) !== undefined) {
        return propertyName;
      }
    }
  };

  _exports.findValidPropertyName = findValidPropertyName;

  const getValidPropertyValue = (object, propertyNames, index) => {
    // TODO: This doesn't appear to be used anywhere!

    /**
     * Accepts an object (model) and array of property names.
     * Returns the value of the first property that exists on the object.
     */
    if (Ember.typeOf(propertyNames) !== "array") {
      console.error("getValidPropertyValue requires an array.");
    }

    for (const prop of propertyNames) {
      let propertyName = replaceIndexPlaceholder(prop, index);
      let propertyValue = Ember.get(object, propertyName);

      if (propertyValue !== undefined) {
        return propertyValue;
      }
    }
  };

  _exports.getValidPropertyValue = getValidPropertyValue;

  const replaceIndexPlaceholder = (string, index) => {
    /**
     * If we have a string and an index value, make the replacement and return the result
     */
    if (!Ember.isNone(index) && !Ember.isNone(string)) {
      string = string.replace('${index}', index); // Replace the index placeholder if this is an array

      string = string.replace('${index+1}', index + 1); // Replace the index placeholder if this is an array
    }

    return string;
  };

  _exports.replaceIndexPlaceholder = replaceIndexPlaceholder;

  const defined = properties => {
    let expandedProperties = [];

    function extractProperty(entry) {
      expandedProperties.push(entry);
    }

    for (let i = 0; i < properties.length; i++) {
      let property = properties[i];
      Ember.expandProperties(property, extractProperty);
    }

    let dependentKeys = expandedProperties;
    let computedFunc = Ember.computed(...dependentKeys, function () {
      let lastIdx = dependentKeys.length - 1;

      for (let i = 0; i < lastIdx; i++) {
        let value = Ember.get(this, dependentKeys[i]);

        if (value !== undefined) {
          return value;
        }
      }

      return Ember.get(this, dependentKeys[lastIdx]);
    });
    return computedFunc;
  };

  _exports.defined = defined;

  const definedPropertyValue = (object, propertyNames, index) => {
    // Returns the value of the first element in the `propertyNames` array that exists
    // in the `object`.
    if (Ember.typeOf(object) !== "object") {
      console.error("definedPropertyValue requires an object.");
    }

    if (Ember.typeOf(propertyNames) !== "array") {
      console.error("definedPropertyValue requires an array.");
    }

    let returnValue;

    for (var i = 0; i < propertyNames.length; i += 1) {
      let propertyName = replaceIndexPlaceholder(propertyNames[i], index);
      let value = Ember.get(object, propertyName);

      if (value !== undefined) {
        returnValue = value;
        break;
      }
    }

    return returnValue;
  };

  _exports.definedPropertyValue = definedPropertyValue;
});