define("alliance-business-suite/components/form-ui/limit-dates-epoch", ["exports", "alliance-business-suite/mixins/init-property-names"], function (_exports, _initPropertyNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function rollHours(value) {
    // If we add hours to a time that rolls it over to the next day, reset the value
    return value >= 24 ? value - 24 : value;
  }

  var _default = Ember.Component.extend(_initPropertyNames.default, {
    tagName: 'div',
    startTime: Ember.computed('startDate', function () {
      let date = moment(this.startDate, "X");
      let hours = date.hour() || 0;
      let minutes = date.minute() || 0;
      return {
        hours,
        minutes
      };
    }),
    endTime: Ember.computed('endDate', function () {
      let date = moment(this.endDate, "X");
      let hours = date.hour() || 0;
      let minutes = date.minute() || 0;
      return {
        hours,
        minutes
      };
    }),
    limitEnabled: Ember.computed('startDate', 'endDate', 'limitDates', {
      // This is based on magic. If limit dates is NOT enabled, ALL the date parts will be 0. If limit dates IS enabled, ALL the date parts will NOT be zero.
      get() {
        return this.limitDates;
      },

      set(key, value) {
        if (value) {
          // Set default dates if enabled is checked on
          this.update(this.object, this.properties.startDate.key, moment().hour(this.startTime.hours).minute(this.startTime.minutes).seconds(0).format("X"));
          this.update(this.object, this.properties.endDate.key, moment("12/31/2099").hour(this.startTime.hours).minute(this.startTime.minutes).seconds(0).format("X"));
        } else {
          // If no limit selected, default to 1/1/2000 and 12/31/2099 for start/end dates
          this.update(this.object, this.properties.startDate.key, moment("1/1/2000").hour(this.endTime.hours).minute(this.endTime.minutes).seconds(0).format("X"));
          this.update(this.object, this.properties.endDate.key, moment("12/31/2099").hour(this.endTime.hours).minute(this.endTime.minutes).seconds(0).format("X"));
        }

        this.update(this.object, this.properties.limitDates.key, value);
        return value;
      }

    }),
    actions: {
      // Need to preserve the time values when updating start and end dates
      updateStartDate(object, propertyName, value) {
        this.update(object, propertyName, moment(value, "X").hours(this.startTime.hours).minutes(this.startTime.minutes).format("X"));
      },

      updateEndDate(object, propertyName, value) {
        this.update(object, propertyName, moment(value, "X").hours(this.endTime.hours).minutes(this.endTime.minutes).format("X"));
      }

    }
  });

  _exports.default = _default;
});