define("alliance-business-suite/components/marketing/campaigns/message-edit-form", ["exports", "alliance-business-suite/mixins/crm"], function (_exports, _crm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MESSAGE_TYPES = [{
    id: 'EMAIL_HTML',
    number: 2,
    text: 'email_html'
  }, {
    id: 'EMAIL_TEXT',
    number: 4,
    text: 'email_text'
  }, {
    id: 'IN_APP_NOTIFICATION',
    number: 3,
    text: 'IN_APP_NOTIFICATION'
  }, {
    id: 'SMS',
    number: 1,
    text: 'sms_message'
  }, {
    id: 'NONE',
    number: 5,
    text: 'delivery_type_none'
  }];

  var _default = Ember.Component.extend(_crm.default, {
    ajax: Ember.inject.service(),
    access: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    router: Ember.inject.service(),

    init() {
      this._super(...arguments);

      let _this = this;

      let deliveryMethodMessages;
      let deliveryMethodMessagesArray = [];
      let deliveryMethodMessageKeysArray = [];

      if (this.get('campaign.deliveryMethodMessages.length')) {
        deliveryMethodMessages = this.get('campaign.deliveryMethodMessages');
        deliveryMethodMessages.forEach(function (deliveryMethodMessage) {
          deliveryMethodMessagesArray.push(deliveryMethodMessage);
        });
        deliveryMethodMessagesArray.forEach(methodMessage => {
          if (!methodMessage.deliveryMethodTypeLookupKey) {
            deliveryMethodMessageKeysArray.push("NONE");
          } else {
            deliveryMethodMessageKeysArray.push(methodMessage.deliveryMethodTypeLookupKey);
          }

          if (methodMessage.deliveryMethodTypeLookupKey === "SMS") {
            _this.set('campaign.deliveryMethodBodyTextSMS', methodMessage.deliveryMethodBodyText);
          }

          if (methodMessage.deliveryMethodTypeLookupKey === "IN_APP_NOTIFICATION") {
            _this.set('campaign.deliveryMethodBodyTextInApp', methodMessage.deliveryMethodBodyText);
          }

          if (methodMessage.deliveryMethodTypeLookupKey === "EMAIL_HTML") {
            _this.set('campaign.deliveryMethodBodyTextHtml', methodMessage.deliveryMethodBodyText);

            _this.set('campaign.deliveryMethodFromNameHtml', methodMessage.deliveryMethodFromName);

            _this.set('campaign.deliveryMethodFromEmailHtml', methodMessage.deliveryMethodFromEmail);

            _this.set('campaign.deliveryMethodReplyEmailHtml', methodMessage.deliveryMethodReplyEmail);

            _this.set('campaign.deliveryMethodSubjectTextHtml', methodMessage.deliveryMethodSubjectText);
          }

          if (methodMessage.deliveryMethodTypeLookupKey === "EMAIL_TEXT") {
            _this.set('campaign.deliveryMethodBodyTextPlain', methodMessage.deliveryMethodBodyText);

            _this.set('campaign.deliveryMethodFromNameText', methodMessage.deliveryMethodFromName);

            _this.set('campaign.deliveryMethodFromEmailText', methodMessage.deliveryMethodFromEmail);

            _this.set('campaign.deliveryMethodReplyEmailText', methodMessage.deliveryMethodReplyEmail);

            _this.set('campaign.deliveryMethodSubjectTextPlain', methodMessage.deliveryMethodSubjectText);
          }
        });
        this.set('campaign.deliveryMethodLookupKeys', deliveryMethodMessageKeysArray);
        this.set('preSelectedMessages', deliveryMethodMessagesArray);
      } else {
        let deliveryKeys;
        let deliveryMethodKeys = [];

        if (this.get('campaign.deliveryMethodLookupKey.length')) {
          deliveryKeys = this.get('campaign.deliveryMethodLookupKey');
          deliveryKeys.forEach(function (deliveryKey) {
            deliveryMethodKeys.push(deliveryKey.get('lookupKey'));
          });
        }

        this.set('campaign.deliveryMethodLookupKeys', deliveryMethodKeys);
        this.set('preSelectedMessages', deliveryMethodMessagesArray);
      }
    },

    willDestroyElement() {
      let {
        cache
      } = this;

      this._super(...arguments); // when leaving the page need to reset both the mixin and the cache service


      cache.resetCrmCache();
    },

    htmlMessageBody: Ember.computed(function () {
      let campaign = this.campaign;
      let text = '';
      text = campaign.get('deliveryMethodBodyTextHtml').replace(/<br\/>/g, '\n');
      return text;
    }),
    textMessageBody: Ember.computed(function () {
      let campaign = this.campaign;
      let text = '';
      text = campaign.get('deliveryMethodBodyTextPlain').replace(/<br\/>/g, '\n');
      return text;
    }),
    htmlEmailBodyParser: Ember.observer('htmlMessageBody', function () {
      let message = this.htmlMessageBody;
      let campaign = this.campaign;

      if (message) {
        message = message.replace(/\n/g, '<br/>');

        if (message !== campaign.get('deliveryMethodBodyTextHtml')) {
          campaign.set('deliveryMethodBodyTextHtml', message);
        }
      }
    }),
    textEmailBodyParser: Ember.observer('textMessageBody', function () {
      let message = this.textMessageBody;
      let campaign = this.campaign;

      if (message) {
        message = message.replace(/\n/g, '<br/>');

        if (message !== campaign.get('deliveryMethodBodyTextPlain')) {
          campaign.set('deliveryMethodBodyTextPlain', message);
        }
      }
    }),
    messageTypeSelected: Ember.computed('preSelectedMessages', {
      get() {
        let deliveryMessages;
        let deliveryMethodMessages = [];

        if (this.get('campaign.deliveryMethodLookupKey.length')) {
          deliveryMessages = this.get('campaign.deliveryMethodLookupKey');
          deliveryMessages.forEach(function (deliveryMessage) {
            let messageTypes = MESSAGE_TYPES.find(element => element.id === deliveryMessage.get('lookupKey'));
            deliveryMethodMessages.push(messageTypes);
          });
        }

        return deliveryMethodMessages;
      },

      set(key, value) {
        let paramsDeliveryType = [];
        value.forEach(deliveryType => {
          paramsDeliveryType.push(deliveryType.id);
        });

        if (paramsDeliveryType) {
          this.setCrmPreviewProperties({
            type: 'deliveryType',
            content: paramsDeliveryType
          });
        }

        return value;
      }

    }),
    messageTypeOptions: Ember.computed(function () {
      let messageTypes = MESSAGE_TYPES;

      if (this.access.isInternational) {
        messageTypes = messageTypes.filter(function (el) {
          return el.id != "SMS";
        });
      }

      return messageTypes;
    }),
    hasChanged: Ember.observer('campaign.deliveryMethodLookupKeys', 'campaign.deliveryMethodSubjectTextHtml', 'campaign.deliveryMethodBodyTextPlain', 'campaign.deliveryMethodBodyTextSMS', 'campaign.deliveryMethodBodyTextInApp', function () {
      if (this.get('campaign.hasDirtyAttributes')) {
        this.hasUnsavedChanges(true);
      }
    }),
    actions: {
      changeMessageTypeSelected(value) {
        let sortedValue = value.sort((a, b) => a.number - b.number);
        let selectedDeliveryMethods = [];
        sortedValue.forEach(deliveryMethod => {
          selectedDeliveryMethods.push(deliveryMethod.id);
        });
        this.set('messageTypeSelected', value);
        this.set('campaign.deliveryMethodLookupKeys', selectedDeliveryMethods);
      }

    }
  });

  _exports.default = _default;
});