define("alliance-business-suite/templates/components/form-ui/radio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pyGml/3c",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[10,\"div\"],[14,0,\"form-field form-ui-element form-ui-radio\"],[12],[2,\"\\n    \"],[10,\"label\"],[14,0,\"group-label\"],[12],[1,[34,3]],[13],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[35,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"radio-button\",[],[[\"@value\",\"@groupValue\",\"@changed\"],[[32,1,[\"val\"]],[34,0],[30,[36,1],[[32,0],\"doChange\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,2],[[32,1,[\"label\"]]],null]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"selectedOption\",\"action\",\"t\",\"label\",\"options\",\"-track-array\",\"each\"]}",
    "moduleName": "alliance-business-suite/templates/components/form-ui/radio.hbs"
  });

  _exports.default = _default;
});