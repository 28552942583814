define("alliance-business-suite/components/charts/chart-with-kpi-yield", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    brush: Ember.inject.service(),
    updateOrgKPI: Ember.observer('kpi', 'chart', function () {
      let chart = Ember.get(this, 'chart');
      let kpi = Ember.get(this, 'kpi');

      if (chart.options.yAxis[0].plotLines && kpi) {
        chart.options.yAxis[0].plotLines[0].value = kpi;
        chart.yAxis[0].isDirty = true;
        chart.redraw();
      }
    }),
    groupKPI: Ember.observer('groupRoomKPI', 'chart', function () {
      let chart = Ember.get(this, 'chart');
      let groupKPI = Ember.get(this, 'groupRoomKPI');
      let plotLine = {
        color: 'green',
        width: 2,
        value: groupKPI,
        dashStyle: "dash",
        id: 'groupRoomKPI',
        zIndex: 10,
        label: {
          text: 'Group KPI'
        }
      };

      if (groupKPI) {
        if (chart.options.yAxis[0].plotLines.find(room => room.id === "groupRoomKPI")) {
          chart.yAxis[0].removePlotLine("groupRoomKPI");
          chart.yAxis[0].addPlotLine(plotLine);
        } else {
          chart.yAxis[0].addPlotLine(plotLine);
        }
      }
    }),
    addPlotLine: Ember.observer('kpi', 'chart', function () {
      if (!this.hasKPI) {
        return;
      }

      let chart = Ember.get(this, 'chart');
      let kpi = Ember.get(this, 'kpi');
      let activePlotLines = 0;
      let val = chart.options.yAxis[0].plotLines.find(room => room.value);

      if (val) {
        activePlotLines = chart.options.yAxis[0].plotLines.find(room => room.value).value;
      }

      if (isNaN(kpi)) {
        kpi.forEach(value => {
          let kpiValue = value.kpi;
          let roomName = value.roomName;
          let color = value.color;
          let plotLine = {
            color: color,
            width: 2,
            value: kpiValue,
            dashStyle: "dash",
            id: value.id,
            zIndex: 10,
            label: {
              useHTML: true,
              text: roomName,
              style: {
                backgroundColor: 'white'
              }
            },
            events: {
              mouseover: function () {
                let plotline = chart.yAxis[0].plotLinesAndBands.find(room => room.id === value.id);

                if (plotline) {
                  plotline.label.toFront();
                }
              }
            }
          };

          if (isNaN(activePlotLines)) {
            activePlotLines.forEach(activePlot => {
              if (value.id === activePlot.id) {
                chart.yAxis[0].removePlotLine(activePlot.id);
              }
            });
          }

          if (chart.options.yAxis[0].plotLines.find(room => room.id === "groupRoomKPI")) {
            chart.yAxis[0].removePlotLine("groupRoomKPI");
            chart.yAxis[0].addPlotLine(plotLine);
          } else {
            chart.yAxis[0].addPlotLine(plotLine);
          }
        });
      }
    })
  });

  _exports.default = _default;
});