define("alliance-business-suite/components/brushes/search-brush", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  let BrushesSearchBrushComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, (_class = class BrushesSearchBrushComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "brush", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "algolia", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "results", _descriptor4, this);
    }

    updateSearch() {
      this.searchAlgolia();
    }

    searchAlgolia(customIndex, hitsPerPage) {
      let query = this.args.query;
      let indices = this.access.accessOrganization.isRetail ? ['user', 'guest'] : ['user'];

      if (customIndex) {
        indices = customIndex;
      }

      if (!query) {
        this.results = null;
        return;
      }

      this.algolia.searchNew(indices, query, hitsPerPage).then(algoliaResult => {
        let final = {};
        let nbHits = 0;
        let nbPages = 0;
        let hits = [];
        algoliaResult.results.forEach(result => {
          hits = hits.concat(result.hits);
          nbHits += result.nbHits;
          nbPages += result.nbPages;
          final[result.index] = {
            hits: result.hits,
            total: result.nbHits,
            pages: result.nbPages,
            page: result.page
          };
        });
        this.results = final;
      });
    }

    showDetail(id, type) {
      let data = {
        id,
        type
      };
      this.brush.transitionTo(type, data);
    }

    loadAllResults(type, hitsPerPage) {
      this.searchAlgolia([type], hitsPerPage); // set(this.results, type, this.algolia.search(type, this.args.query, hitsPerPage));
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "brush", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "algolia", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "results", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "updateSearch", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "updateSearch"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "showDetail", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "showDetail"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "loadAllResults", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "loadAllResults"), _class.prototype)), _class));
  _exports.default = BrushesSearchBrushComponent;
});