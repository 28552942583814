define("alliance-business-suite/components/profile/select-timezone", ["exports", "alliance-business-suite/mixins/timezones"], function (_exports, _timezones) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    access: Ember.inject.service(),

    didInsertElement() {
      this._super(...arguments);

      Ember.set(this, 'timeZones', _timezones.timeZones);
      this.setCurrentTimeZone(this);
    },

    setCurrentTimeZone(context) {
      let userId = Ember.get(context, 'access.user.id');
      let user = Ember.get(context, 'store').peekRecord('employee', userId);
      context.send('updateTimezone', user.get('timeZone'));
    },

    findTimezoneByLocale(context, locale) {
      let tzObj = null;

      _timezones.timeZones.forEach(function (tz, index) {
        let utcArr = tz.utc;
        utcArr.forEach(function (utc) {
          if (utc == locale) {
            tzObj = _timezones.timeZones[index];
          }
        });
      });

      Ember.set(context, 'selectedTimeZone', tzObj);
    },

    actions: {
      toggleEdit() {
        let userId = Ember.get(this, 'access.user.id');
        let user = Ember.get(this, 'store').peekRecord('employee', userId);
        user.rollbackAttributes();
        this.toggleEdit();
      },

      updateTimezone(tz) {
        let userId = Ember.get(this, 'access.user.id');
        let user = Ember.get(this, 'store').peekRecord('employee', userId);

        if (tz.utc) {
          Ember.set(user, 'timeZone', tz.utc[0]);
          Ember.set(this, 'selectedTimeZone', tz);
        } else {
          Ember.set(user, 'timeZone', tz);
          this.findTimezoneByLocale(this, tz);
        }
      },

      saveTimezone() {
        let userId = Ember.get(this, 'access.user.id');
        let user = Ember.get(this, 'store').peekRecord('employee', userId);
        this.set('isSaving', true);
        user.save().then(response => {
          this.set('isSaving', false);
          this.send('toggleEdit');
        });
      }

    }
  });

  _exports.default = _default;
});