define("alliance-business-suite/components/rooms/room-tab-general", ["exports", "jquery", "alliance-business-suite/config/environment", "alliance-business-suite/mixins/timezones"], function (_exports, _jquery, _environment, _timezones) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    ajax: Ember.inject.service(),
    notifications: Ember.inject.service(),
    mapLatitude: 0,
    mapLongitude: 0,
    markers: null,
    isSaving: false,
    isGeoSearching: false,
    editingDisabled: Ember.computed('isGeoSearching', function () {
      let isGeoSearching = this.isGeoSearching;
      let hasAccess = this['can-access'].canAccess('LOCATIONS_EDIT_DETAIL_VIEW');

      if (isGeoSearching || !hasAccess) {
        return true;
      }
    }).readOnly(),

    didInsertElement() {
      this._super(...arguments);

      let _this = this;

      this.set('timeZones', _timezones.timeZones);
      this.send('updateTimezone', this.get('model.timeZoneName'));
      let {
        latitude,
        longitude
      } = Ember.get(this, 'model');
      this.set('mapLatitude', latitude);
      this.set('mapLongitude', longitude);
      let markers = [{
        id: 'model',
        lat: latitude,
        // Required
        lng: longitude,
        // Required
        clickable: true,
        draggable: true,
        crossOnDrag: false,

        dragend(event) {
          let lat = event.target.position.lat();
          let lng = event.target.position.lng();

          _this.set('model.latitude', lat.toFixed(6));

          _this.set('model.longitude', lng.toFixed(6));

          _this.set('manualCoordinates', true);
        }

      }];
      this.set('markers', markers);
    },

    findTimezoneByLocale(context, locale) {
      let tzObj = null;

      _timezones.timeZones.forEach(function (tz, index) {
        let utcArr = tz.utc;
        utcArr.forEach(function (utc) {
          if (utc == locale) {
            tzObj = _timezones.timeZones[index];
          }
        });
      });

      this.set('selectedTimeZone', tzObj);
    },

    actions: {
      updateTimezone(tz) {
        let model = this.model;

        if (tz.utc) {
          model.set('timeZoneName', tz.utc[0]);
          model.set('internationalTimeZoneName', tz.utc[0]);
          model.set('internationalTimeZoneAbbreviation', tz.abbr);
          this.set('selectedTimeZone', tz);
        } else {
          // model.set('timeZoneName', tz);
          this.findTimezoneByLocale(this, tz);
        }
      },

      findAddress(model) {
        let address = model.get('geomName');

        let _this = this;

        this.set('isGeoSearching', true);
        this.ajax.request(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${_environment.default['ember-google-maps'].key}`, {
          method: 'GET'
        }).then(result => {
          if (result && result.results.length) {
            let {
              lng,
              lat
            } = result.results[0].geometry.location; // Must be like this to make the map update markers every time

            let markers = [{
              id: 'model',
              lat: lat.toFixed(6),
              // Required
              lng: lng.toFixed(6),
              // Required
              clickable: true,
              draggable: true,
              crossOnDrag: false,

              dragend(event) {
                let lat = event.target.position.lat();
                let lng = event.target.position.lng();

                _this.set('model.latitude', lat.toFixed(6));

                _this.set('model.longitude', lng.toFixed(6));

                _this.set('manualCoordinates', true);
              }

            }];
            model.set('longitude', lng.toFixed(6));
            model.set('latitude', lat.toFixed(6));

            _this.set('mapLongitude', lng);

            _this.set('mapLatitude', lat);

            _this.set('markers', markers);

            _this.set('manualCoordinates', false);
          }

          _this.set('isGeoSearching', false);
        }, () => {
          Ember.get(_this, 'notifications').error(_this.get('intl').t('geo_lookup_failed'));

          _this.set('isGeoSearching', false);
        });
      }

    }
  });

  _exports.default = _default;
});