define("alliance-business-suite/utils/is-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isNumber = void 0;

  const isNumber = val => {
    return !isNaN(parseFloat(val)) && isFinite(val);
  };

  _exports.isNumber = isNumber;
});