define("alliance-business-suite/templates/components/form-ui/steps/drain", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3IFOZe7n",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[8,\"form-ui\",[],[[\"@object\",\"@formSettings\",\"@selectOptions\",\"@update\"],[[32,0,[\"object\"]],[32,0,[\"formSettings\"]],[32,0,[\"selectOptions\"]],[30,[36,5],[[32,0],\"updateDrain\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n\"],[6,[37,6],[[32,0,[\"isHidden\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"h2\"],[12],[1,[30,[36,1],[\"drain\"],null]],[13],[2,\"\\n\\n    \"],[8,[32,1,[\"checkbox\"]],[],[[\"@params\"],[[30,[36,0],null,[[\"propertyName\",\"label\"],[[32,0,[\"properties\",\"MainDrain1\",\"key\"]],\"MainDrain1\"]]]]],null],[2,\"\\n\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,2],[[32,0,[\"MainDrain2\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,[32,1,[\"checkbox\"]],[],[[\"@params\"],[[30,[36,0],null,[[\"propertyName\",\"label\"],[[32,0,[\"properties\",\"MainDrain2\",\"key\"]],\"MainDrain2\"]]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"hash\",\"t\",\"is-empty\",\"not\",\"if\",\"action\",\"unless\"]}",
    "moduleName": "alliance-business-suite/templates/components/form-ui/steps/drain.hbs"
  });

  _exports.default = _default;
});