define("alliance-business-suite/templates/components/machines/programs/not-supported/unsupported", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OH7O40lT",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,5,\"margin:100px;\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"machine_not_supported\"],null]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "moduleName": "alliance-business-suite/templates/components/machines/programs/not-supported/unsupported.hbs"
  });

  _exports.default = _default;
});