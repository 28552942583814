define("alliance-business-suite/components/ui/card-stack", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Renders a single card from a stack of cards.
   *
   * # Positional Parameters
   *
   * cards
   * : An array of card labels.
   *
   * # Named Parameters
   *
   * selected
   * : The label for the actively selected card.
   *
   * counts
   * : A hash mapping cards to counts to display in the selector for the card.
   *
   * onselected
   * : An action to call when a new card is selected. Passed the `card` as the
   *   first parameter.
   *
   *
   * @type {[type]}
   * @public
   */
  let ComponentClass = Ember.Component.extend({
    /**
     * The static class names for this component.
     *
     * @type {Array<String>}
     * @public
     */
    classNames: ['ui-card-stack'],

    /**
     * An array of cards to render.
     *
     * @type {Array<string>}
     * @public
     */
    cards: undefined,

    /**
     * The currently selected card.
     *
     * @type {string}
     * @public
     */
    selected: undefined,

    /**
     * The card that is currently active. Defaults to the first card if no other
     * card is selected.
     *
     * @protected
     */
    activeCard: Ember.computed('cards', 'selected', function () {
      let cards = Ember.get(this, 'cards');
      let selected = Ember.get(this, 'selected');
      return selected != null ? selected : cards && cards.length > 0 ? cards[0] : undefined; // TODO: Figure this out. Seems to only be used to select the initial tab.
      // The following was added by Ty to go to last selected tab.
      // let returnValue = null;
      // let previous = localStorage.getItem('machine-program-tab-selected');
      // if (selected != null) {
      //   returnValue =  selected;
      // } else if (cards && cards.length > 0) {
      //   if (previous) {
      //     returnValue =  previous;
      //   } else {
      //     returnValue =  cards[0];
      //   }
      // } else {
      //   returnValue =  undefined;
      // }
      // return returnValue;
    }),

    /**
     * The actions for this component.
     *
     * @public
     */
    actions: {
      /**
       * Sets the selected card and executes the `onselect` action passed into
       * this component.
       *
       * @public
       */
      select(card) {
        localStorage.setItem('machine-program-tab-selected', card);

        if (this.attrs.onselect) {
          Ember.set(this, 'selected', card);
          this.attrs.onselect(card);
        }
      }

    }
  });
  ComponentClass.reopenClass({
    positionalParams: ['cards']
  });
  var _default = ComponentClass;
  _exports.default = _default;
});