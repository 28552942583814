define("alliance-business-suite/models/user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;

  var _default = Model.extend({
    firstName: attr('string'),
    lastName: attr('string'),
    nickname: attr('string'),
    locale: attr('string'),
    gender: attr('string'),
    birthDate: attr('string'),
    isActive: attr('boolean'),
    loginIsActive: attr('boolean'),
    hasVerifiedPhoneNumber: attr('boolean'),
    employeeSalesGoal: belongsTo('employeeSalesGoal'),
    organizations: hasMany('organizations'),
    roles: hasMany('role'),
    accounts: hasMany('account', {
      inverse: 'user'
    }),
    securityRoles: hasMany('security-role', {
      inverse: null
    }),
    systemAlertSubscriptionTypes: hasMany('systemAlertSubscriptionType', {
      inverse: null
    }),
    // systemAlertSubscriptions: hasMany('system-alert-subscription', {
    //   inverse: null
    // }),
    emailAddresses: hasMany('emailAddresses', {
      inverse: 'user'
    }),
    primaryEmail: Ember.computed('emailAddresses', 'emailAddresses.[].emailAddress', {
      get() {
        let emailAddresses = this.emailAddresses;

        if (emailAddresses.get('length')) {
          return emailAddresses.objectAt(0).get('emailAddress');
        }
      }

    }),
    phoneNumbers: hasMany('phoneNumbers', {
      inverse: 'user'
    }),
    primaryPhoneNumber: Ember.computed('phoneNumbers', 'phoneNumbers.[].contactNumber', {
      get() {
        let phoneNumbers = this.phoneNumbers;

        if (phoneNumbers.get('length')) {
          return phoneNumbers.objectAt(0).get('formattedPhoneNumber');
        }
      }

    }),
    createdAt: attr('date'),
    createdBy: belongsTo('user', {
      inverse: null
    }),
    updatedAt: attr('date'),
    updatedBy: belongsTo('user', {
      inverse: null
    }),
    userType: belongsTo('userType', {
      inverse: 'users'
    }),
    userRoles: hasMany('userRole', {
      inverse: 'user'
    }),

    /* Computed Properties */
    fullName: Ember.computed('firstName', 'lastName', {
      get() {
        return `${this.firstName} ${this.lastName}`;
      }

    }),
    brushLabel: Ember.computed.alias('fullName').readOnly(),
    primaryUserRole: Ember.computed('userRoles', {
      get() {
        let userRoles = this.userRoles;
        return Ember.get(userRoles, 'length') ? userRoles.objectAt(0) : undefined;
      }

    }),
    primaryUserRoleType: Ember.computed('primaryUserRole', {
      get() {
        let role = this.primaryUserRole;
        return role ? Ember.get(role, 'roleType') : undefined;
      }

    })
  });

  _exports.default = _default;
});