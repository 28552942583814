define("alliance-business-suite/components/form-ui/user-languages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['form-field'],
    intl: Ember.inject.service(),
    sortedLanguages: Ember.computed.sort('params.languages', function (a, b) {
      let localLanguage1 = a.split('.').slice(-1)[0];
      let localLanguage2 = b.split('.').slice(-1)[0];

      if (this.intl.t(localLanguage1) > this.intl.t(localLanguage2)) {
        return 1;
      } else if (this.intl.t(localLanguage1) < this.intl.t(localLanguage2)) {
        return -1;
      } else {
        return 0;
      }
    })
  });

  _exports.default = _default;
});