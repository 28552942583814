define("alliance-business-suite/components/machines/programs/pricing/special-vend-primus-mc10-wx", ["exports", "alliance-business-suite/utils/number-to-hour", "alliance-business-suite/utils/format-currency", "alliance-business-suite/mixins/init-property-names", "alliance-business-suite/mixins/modals"], function (_exports, _numberToHour, _formatCurrency, _initPropertyNames, _modals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function formatTime(minutes, seconds) {
    return `${minutes}:${seconds.toString().padStart(2, 0)}`;
  }

  var _default = Ember.Component.extend(_initPropertyNames.default, _modals.default, {
    intl: Ember.inject.service(),
    summaryText: Ember.computed('Enabled', 'Saturday', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'StartMonth', 'StartDate', 'StartYear', 'EndMonth', 'EndDate', 'EndYear', 'StartHour', 'StartMinute', 'Duration', function () {
      let output = '';
      let intl = Ember.get(this, 'intl');

      if (!this.Enabled || this.Enabled === false) {
        return this.intl.t("off");
      } // When: Days (dates) (hours)
      // Every Day; Weekends; Weekdays; Mon-Wed, Fri


      let daysText = "";

      if (this.Saturday && this.Sunday && this.Monday && this.Tuesday && this.Wednesday && this.Thursday && this.Friday) {
        // daysText = 'Every Day, ';
        daysText = `${this.intl.t("every_day")}, `;
      } else {
        // Weekends
        if (this.Saturday && this.Sunday) {
          daysText += ` ${this.intl.t("weekends")}, `;
        } else if (this.Saturday) {
          daysText += `${this.intl.t("sat")}, `;
        } else if (this.Sunday) {
          daysText += `${this.intl.t("sun")}, `;
        } // Weekdays


        if (this.Monday && this.Tuesday && this.Wednesday && this.Thursday && this.Friday) {
          daysText += `${this.intl.t("weekdays")}, `;
        } else {
          if (this.Monday) {
            daysText += `${this.intl.t("mon")}, `;
          }

          if (this.Tuesday) {
            daysText += `${this.intl.t("tue")}, `;
          }

          if (this.Wednesday) {
            daysText += `${this.intl.t("wed")}, `;
          }

          if (this.Thursday) {
            daysText += `${this.intl.t("thu")}, `;
          }

          if (this.Friday) {
            daysText += `${this.intl.t("fri")}, `;
          }
        }

        if (daysText) {
          output += daysText;
        } else {
          output += `${this.intl.t("no_days_selected")}, `;
        } // Dates - this has some logic. If the day, month, or year has a value of 0, date limit is not enabled. If start = 1/1/2000 and end = 12/31/2099, also has no limit.


        if (this.StartMonth == 0 || this.StartDate == 0 || this.EndMonth == 0 || this.EndDate == 0 || this.StartMonth == 1 && this.StartDate == 1 && this.StartYear == 0 && this.EndMonth == 12 && this.EndDate == 31 && this.EndYear == 99) {// No date limits
        } else {
          output += `(${this.StartMonth}/${this.StartDate}/${this.StartYear}&ndash;${this.EndMonth}/${this.EndDate}/${this.EndYear})  `;
        } // Hours - this seems to have some logic. If duration is 0, not enabled.


        if (this.Duration > 0) {
          // We'll need to figure out the end time
          let startHour = (0, _numberToHour.default)(this.StartHour);
          let endHour = (0, _numberToHour.default)(Number(this.StartHour) + Number(this.Duration));
          output += `(${startHour}&ndash;${endHour}), `;
        }

        if (output.length > 0) {
          output = `<strong>${output}</strong>`;
        }
      } // Show Actions
      // Change prices (Normal $0.00, Synthetics $0.00, Delicates/Bulky $0.00, Colors $0.00, Whites $0.00, Mix $0.00, Woolens $0.00, Delicate $0.00, Bedding $0.00,)
      // Loop through all enabled cycles and show their price. Prices need to be localized.


      let cycles = this.selectOptions.CyclesWx;
      let enabledCycles = cycles.filter(item => {
        let cycle = Ember.get(this, 'object.Cycles').objectAt(item.index);
        return cycle ? Ember.get(cycle, "Enabled") : false;
      });
      let actionOutput = '';
      enabledCycles.forEach(item => {
        let name = `${this.intl.t("Cycle")} ${item.val}`;
        let price = (0, _formatCurrency.default)(this.CyclePrices[item.index], this.formSettings);
        actionOutput += `, ${name}: ${price}`;
      });
      actionOutput = actionOutput.substr(2);

      if (actionOutput) {
        output += `<br>Change prices (${actionOutput})`;
      }

      return Ember.String.htmlSafe(output);
    }),
    defaultModifier: Ember.computed('DefaultReversing', 'DefaultAntiWrinkle', function () {
      let reversing = Ember.get(this, 'DefaultReversing');
      let antiWrinkle = Ember.get(this, 'DefaultAntiWrinkle');
      if (!reversing && !antiWrinkle) return 1;
      if (!reversing && antiWrinkle) return 2;
      if (reversing && !antiWrinkle) return 3;
      if (reversing && antiWrinkle) return 4;
    }),
    actions: {
      updateValues(obj, prop, value) {
        let object = Ember.get(this, 'object');
        Ember.set(this, 'defaultModifier', value);

        switch (value) {
          case 1:
            // None
            // Do nothing
            Ember.get(this, 'update')(object, Ember.get(this, 'properties.DefaultReversing.key'), false);
            Ember.get(this, 'update')(object, Ember.get(this, 'properties.DefaultAntiWrinkle.key'), false);
            break;

          case 2:
            // Anti-Wrinkle
            Ember.get(this, 'update')(object, Ember.get(this, 'properties.DefaultReversing.key'), false);
            Ember.get(this, 'update')(object, Ember.get(this, 'properties.DefaultAntiWrinkle.key'), true);
            Ember.get(this, 'update')(object, Ember.get(this, 'properties.AntiWrinkle.key'), true);
            break;

          case 3:
            // Reversing
            Ember.get(this, 'update')(object, Ember.get(this, 'properties.DefaultReversing.key'), true);
            Ember.get(this, 'update')(object, Ember.get(this, 'properties.DefaultAntiWrinkle.key'), false);
            Ember.get(this, 'update')(object, Ember.get(this, 'properties.Reversing.key'), true);
            break;

          case 4:
            // Both
            Ember.get(this, 'update')(object, Ember.get(this, 'properties.DefaultReversing.key'), true);
            Ember.get(this, 'update')(object, Ember.get(this, 'properties.DefaultAntiWrinkle.key'), true);
            Ember.get(this, 'update')(object, Ember.get(this, 'properties.AntiWrinkle.key'), true);
            Ember.get(this, 'update')(object, Ember.get(this, 'properties.Reversing.key'), true);
            break;
        }
      }

    },
    // Need to observe any properties used for enabled, etc.
    formConfig: Ember.computed('indexValue', function () {
      return {
        columns: [{
          sections: [{
            title: "Enabled",
            controls: [{
              type: "checkbox",
              propertyName: "SpecialVend.Items.${index}.DOWEn.Enabled"
            }]
          }, {
            title: "Dates",
            controls: [{
              type: "limit-dates-repeat-annually",
              properties: {
                startYear: "SpecialVend.Items.${index}.StartYear",
                startMonth: "SpecialVend.Items.${index}.StartMonth",
                startDate: "SpecialVend.Items.${index}.StartDate",
                endYear: "SpecialVend.Items.${index}.EndYear",
                endMonth: "SpecialVend.Items.${index}.EndMonth",
                endDate: "SpecialVend.Items.${index}.EndDate"
              }
            }]
          }, {
            title: "Times",
            controls: [{
              type: "time-span",
              properties: {
                startHour: "SpecialVend.Items.${index}.StartHour",
                startMinute: "SpecialVend.Items.${index}.StartMinute",
                durationHours: "SpecialVend.Items.${index}.Duration"
              },
              hour12: true
            }]
          }, {
            title: "Days",
            controls: [{
              type: "checkbox",
              propertyName: "SpecialVend.Items.${index}.DOWEn.Monday"
            }, {
              type: "checkbox",
              propertyName: "SpecialVend.Items.${index}.DOWEn.Tuesday"
            }, {
              type: "checkbox",
              propertyName: "SpecialVend.Items.${index}.DOWEn.Wednesday"
            }, {
              type: "checkbox",
              propertyName: "SpecialVend.Items.${index}.DOWEn.Thursday"
            }, {
              type: "checkbox",
              propertyName: "SpecialVend.Items.${index}.DOWEn.Friday"
            }, {
              type: "checkbox",
              propertyName: "SpecialVend.Items.${index}.DOWEn.Saturday"
            }, {
              type: "checkbox",
              propertyName: "SpecialVend.Items.${index}.DOWEn.Sunday"
            }]
          }, {
            title: "cycle_prices",
            notMachine: "MC10_COMBO",
            controls: [{
              type: "cycle-prices",
              propertyName: "SpecialVend.Items.${index}.CyclePrices"
            }]
          }]
        }, {
          sections: [{
            title: "cycle_prices",
            ifMachine: "MC10_COMBO",
            controls: [{
              type: "cycle-prices",
              propertyName: "SpecialVend.Items.${index}.CyclePrices",
              cycleCount: 5
            }]
          }, {
            title: "modifier_prices",
            notMachine: "MC10_COMBO",
            controls: [{
              type: "currency",
              propertyName: "SpecialVend.Items.${index}.ModifierPrices.Mod1Adder",
              label: "mod_1",
              min: 0,
              max: 65535,
              limit: true,
              decimals: this.decimals
            }, {
              type: "currency",
              propertyName: "SpecialVend.Items.${index}.ModifierPrices.Mod2Adder",
              label: "mod_2",
              min: 0,
              max: 65535,
              limit: true,
              decimals: this.decimals
            }, {
              type: "currency",
              propertyName: "SpecialVend.Items.${index}.ModifierPrices.ExtraTimeAdder",
              label: "extra_time",
              min: 0,
              max: 65535,
              limit: true,
              decimals: this.decimals
            }, // {
            //   type: "currency",
            //   propertyName: "SpecialVend.Items.${index}.ModifierPrices.WarmRinseAdder",
            //   label: "warm_rinse",
            //   min: 0,
            //   max: 65535,
            //   limit: true,
            //   decimals: this.decimals
            // },
            {
              type: "currency",
              propertyName: "SpecialVend.Items.${index}.ModifierPrices.IncreasedWaterLevelAdder",
              label: "increased_water_level",
              min: 0,
              max: 65535,
              limit: true,
              decimals: this.decimals
            }, {
              type: "currency",
              propertyName: "SpecialVend.Items.${index}.ModifierPrices.IncreasedSpinSpeedAdder",
              label: "increased_spin_speed",
              min: 0,
              max: 65535,
              limit: true,
              decimals: this.decimals
            }, // {
            //   type: "currency",
            //   propertyName: "SpecialVend.Items.${index}.ModifierPrices.OzoneAdder",
            //   label: "ozone",
            //   min: 0,
            //   max: 65535,
            //   limit: true,
            //   decimals: this.decimals
            // },
            {
              type: "currency",
              propertyName: "SpecialVend.Items.${index}.ModifierPrices.SupplyInjectionAdder",
              label: "dispense_soap",
              min: 0,
              max: 65535,
              limit: true,
              decimals: this.decimals
            }, {
              type: "currency",
              propertyName: "SpecialVend.Items.${index}.ModifierPrices.SpeedCycleAdder",
              label: "speed_cycle",
              min: 0,
              max: 65535,
              limit: true,
              decimals: this.decimals
            }, {
              type: "currency",
              propertyName: "SpecialVend.Items.${index}.ModifierPrices.MediumBundleAdder",
              label: "medium_soil",
              min: 0,
              max: 65535,
              limit: true,
              decimals: this.decimals
            }, {
              type: "currency",
              propertyName: "SpecialVend.Items.${index}.ModifierPrices.HeavyBundleAdder",
              label: "heavy_soil",
              min: 0,
              max: 65535,
              limit: true,
              decimals: this.decimals
            }]
          }, // {
          //   title: "extract_speed",
          //   controls: [
          //     {
          //       type: "limit-extract-speed",
          //       propertyName: "SpecialVend.Items.${index}.ExtractSpeedLimit",
          //       label: "limit_extract_speed",
          //       offValue: 0,
          //       _onValue: 166,
          //       min: 150,
          //       max: 1075
          //     }
          //   ]
          // },
          {
            title: "temperature_prices",
            notMachine: "MC10_COMBO",
            controls: [{
              type: "currency",
              propertyName: "SpecialVend.Items.${index}.ModifierPrices.WarmAdder",
              label: "warm"
            }, {
              type: "currency",
              propertyName: "SpecialVend.Items.${index}.ModifierPrices.HotAdder",
              label: "hot"
            }, {
              type: "currency",
              propertyName: "SpecialVend.Items.${index}.ModifierPrices.Temp15CAdder",
              label: Ember.get(this, "isCelsius") ? "Temp15C" : "Temp59F"
            }, {
              type: "currency",
              propertyName: "SpecialVend.Items.${index}.ModifierPrices.Temp30CAdder",
              label: Ember.get(this, "isCelsius") ? "Temp30C" : "Temp86F"
            }, {
              type: "currency",
              propertyName: "SpecialVend.Items.${index}.ModifierPrices.Temp40CAdder",
              label: Ember.get(this, "isCelsius") ? "Temp40C" : "Temp104F"
            }, {
              type: "currency",
              propertyName: "SpecialVend.Items.${index}.ModifierPrices.Temp50CAdder",
              label: Ember.get(this, "isCelsius") ? "Temp50C" : "Temp122F"
            }, {
              type: "currency",
              propertyName: "SpecialVend.Items.${index}.ModifierPrices.Temp60CAdder",
              label: Ember.get(this, "isCelsius") ? "Temp60C" : "Temp140F"
            }, {
              type: "currency",
              propertyName: "SpecialVend.Items.${index}.ModifierPrices.Temp90CAdder",
              label: Ember.get(this, "isCelsius") ? "Temp90C" : "Temp194F"
            }]
          }, {
            title: "modifier_options",
            notMachine: "MC10_COMBO",
            controls: [{
              type: "modifier-options",
              properties: {
                Mod1: "SpecialVend.Items.${index}.DefaultModifiers.Mod1",
                Mod1On: "SpecialVend.Items.${index}.ModsAdditionalOptsEnabled.Mod1",
                Mod2: "SpecialVend.Items.${index}.DefaultModifiers.Mod2",
                Mod2On: "SpecialVend.Items.${index}.ModsAdditionalOptsEnabled.Mod2",
                ExtraTime: "SpecialVend.Items.${index}.DefaultModifiers.ExtraTime",
                ExtraTimeOn: "SpecialVend.Items.${index}.ModsAdditionalOptsEnabled.ExtraTime",
                // WarmFinalRinse: "SpecialVend.Items.${index}.DefaultModifiers.WarmFinalRinse",
                // WarmFinalRinseOn: "SpecialVend.Items.${index}.ModsAdditionalOptsEnabled.WarmFinalRinse",
                IncreasedWaterLevel: "SpecialVend.Items.${index}.DefaultModifiers.IncreasedWaterLevel",
                IncreasedWaterLevelOn: "SpecialVend.Items.${index}.ModsAdditionalOptsEnabled.IncreasedWaterLevel",
                IncreasedSpinSpeed: "SpecialVend.Items.${index}.DefaultModifiers.IncreasedSpinSpeed",
                IncreasedSpinSpeedOn: "SpecialVend.Items.${index}.ModsAdditionalOptsEnabled.IncreasedSpinSpeed",
                // Ozone: "SpecialVend.Items.${index}.DefaultModifiers.Ozone",
                // OzoneOn: "SpecialVend.Items.${index}.ModsAdditionalOptsEnabled.Ozone",
                DispenseSoap: "SpecialVend.Items.${index}.DefaultModifiers.DispenseSoap",
                DispenseSoapOn: "SpecialVend.Items.${index}.ModsAdditionalOptsEnabled.DispenseSoap",
                SpeedCycle: "SpecialVend.Items.${index}.DefaultModifiers.SpeedCycle",
                SpeedCycleOn: "SpecialVend.Items.${index}.ModsAdditionalOptsEnabled.SpeedCycle",
                MediumBundle: "SpecialVend.Items.${index}.DefaultModifiers.MediumBundle",
                MediumBundleOn: "SpecialVend.Items.${index}.ModsAdditionalOptsEnabled.MediumBundle",
                HeavyBundle: "SpecialVend.Items.${index}.DefaultModifiers.HeavyBundle",
                HeavyBundleOn: "SpecialVend.Items.${index}.ModsAdditionalOptsEnabled.HeavyBundle"
              }
            } // {
            //   type: "positive-negative-number",
            //   properties: {
            //     positive: "SpecialVend.Items.${index}.WashAgitateMinutesAdder",
            //     negative: "SpecialVend.Items.${index}.WashAgitateMinuteSubtracter"
            //   },
            //   label: "vend_agitate_time_mod",
            //   min: -10,
            //   max: 10,
            //   labelAfter: "minutes"
            // }
            ]
          }, {
            title: "bundle_options",
            notMachine: "MC10_COMBO",
            controls: [{
              type: "bundle-options",
              properties: {
                Mod1Medium: "SpecialVend.Items.${index}.MediumBundleOptions.Mod1",
                Mod1Heavy: "SpecialVend.Items.${index}.HeavyBundleOptions.Mod1",
                Mod2Medium: "SpecialVend.Items.${index}.MediumBundleOptions.Mod2",
                Mod2Heavy: "SpecialVend.Items.${index}.HeavyBundleOptions.Mod2",
                ExtraTimeMedium: "SpecialVend.Items.${index}.MediumBundleOptions.ExtraTime",
                ExtraTimeHeavy: "SpecialVend.Items.${index}.HeavyBundleOptions.ExtraTime",
                // WarmRinseMedium: "SpecialVend.Items.${index}.MediumBundleOptions.WarmRinse",
                // WarmRinseHeavy: "SpecialVend.Items.${index}.HeavyBundleOptions.WarmRinse",
                WaterLevelMedium: "SpecialVend.Items.${index}.MediumBundleOptions.WaterLevel",
                WaterLevelHeavy: "SpecialVend.Items.${index}.HeavyBundleOptions.WaterLevel",
                SpinSpeedMedium: "SpecialVend.Items.${index}.MediumBundleOptions.SpinSpeed",
                SpinSpeedHeavy: "SpecialVend.Items.${index}.HeavyBundleOptions.SpinSpeed"
              }
            }]
          }]
        }]
      };
    })
  });

  _exports.default = _default;
});