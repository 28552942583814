define("alliance-business-suite/components/form-ui/led-text", ["exports", "alliance-business-suite/utils/led"], function (_exports, _led) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /*
      This component displays the six characters displayed on the 7-segment LED displays on machines. The
      characters can be letters a through z, and numbers 0 through 9.
       There are two different implementations possible. A single property containing the base64-encoded
      hex values of all 6 characters, or an array of decimal values (6 total) representing the character
      to be displayed. For example:
       Water Guardian
        "Bjd4bT45" == "CUSTM1"
       Machine Configuration
        [57,119,80,94,0,0] == "CARD  "
    */
    isDecimalArray: false,
    displayValue: Ember.computed('property', 'value', {
      get(key) {
        if (Ember.get(this, 'property')) {
          let output;
          let val = Ember.get(this, 'property');

          if (Ember.get(this, 'isDecimalArray')) {
            // Some tabs will pass in an array of decimals instead of a base64 encoded string of Unicode, which does NOT get reversed.
            output = val;
          } else {
            output = (0, _led.base64ToDecimalArray)(val);
            output.reverse();
          }

          return output.reduce((previous, data) => {
            let hex = data.toString(16).padStart(2, '0'); // Convert to hex so we can lookup the value

            return previous += _led.LED_HEX2CHAR[`0x${hex.toUpperCase()}`]; // Find value and append to return string
          }, '');
        }
      },

      set(key, value) {
        if (value) {
          value = value.toLowerCase();
          let letters = value.split('');
          letters.reverse();
          let hexString = '';
          let dec = [];
          letters.forEach(function (letter, index) {
            let hex = _led.LED_CHAR2HEX[letter].toUpperCase();

            hexString += hex;
            dec[index] = parseInt(`0x${hex}`, 16) || 0;
          });

          if (Ember.get(this, 'isDecimalArray')) {
            // Return array of decimals
            Ember.set(this, 'property', dec.reverse());
          } else {
            // Return base64-encoded string of Unicode
            let base64 = (0, _led.hexToBase64)(hexString);
            Ember.set(this, 'property', base64);
          }
        } else {
          // No value, it's empty
          if (Ember.get(this, 'isDecimalArray')) {
            Ember.set(this, 'property', [0, 0, 0, 0, 0, 0]);
          } else {
            Ember.set(this, 'property', '');
          }
        }

        return value;
      }

    }),
    actions: {
      validateInput(value) {
        let letters = value.toLowerCase().split('');
        let validLetters = letters.filter(item => {
          return 'abcdefghijklmnopqrstuvwxyz0123456789'.indexOf(item) >= 0;
        });
        let response = validLetters.join('');
        Ember.set(this, 'displayValue', response.substr(0, 6)); // Only allow 6 characters
      }

    }
  });

  _exports.default = _default;
});