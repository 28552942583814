define("alliance-business-suite/models/campaign-promotion", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    promotionCode: attr('string'),
    promotionTypeLookupKey: attr('string'),
    promotionDiscountTypeLookupKey: attr('string'),
    promotionDiscountObjectTypeLookupKey: attr('string'),
    expirationInterval: attr('string'),
    value: attr('number'),
    maxDiscountAmount: attr('number'),
    campaign: belongsTo('campaign')
  });

  _exports.default = _default;
});