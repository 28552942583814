define("alliance-business-suite/templates/components/table/table-cell-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "M4+wAz3r",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[10,\"ul\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,0]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"li\"],[12],[1,[32,1]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"listItems\",\"-track-array\",\"each\"]}",
    "moduleName": "alliance-business-suite/templates/components/table/table-cell-list.hbs"
  });

  _exports.default = _default;
});