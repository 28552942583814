define("alliance-business-suite/components/inventory/category-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notifications: Ember.inject.service('notifications'),
    store: Ember.inject.service(),
    selectedCategories: null,

    didInsertElement() {
      this._super(...arguments);

      this.generateInitialCategories();
    },

    generateInitialCategories() {
      let selectedItemCategories = this.item.get('upsellCategories').forEach(cat => {
        return cat;
      });
      let categoriesExceptSelf = this.store.peekAll('item-category').filter(cat => {
        return cat.id != this.item.id ? cat : null;
      });
      this.set('selectedCategories', selectedItemCategories);
      this.set('allCategories', categoriesExceptSelf);
    },

    actions: {
      updateSelectedCategory(val) {
        let {
          item
        } = this;

        if (val.length == 0) {
          item.set('upsellCategories', []);
        } else {
          let valPeek = this.store.peekRecord('upsell-category', val[val.length - 1].id); // if there is none in the DS, then we push it to the store directly

          if (!valPeek) {
            this.store.push({
              data: [{
                id: val[val.length - 1].id,
                type: 'upsell-category',
                attributes: {
                  name: val[val.length - 1].name,
                  description: val[val.length - 1].description
                },
                relationships: {}
              }]
            });
            valPeek = this.store.peekRecord('upsell-category', val[val.length - 1].id);
          }

          val.splice(val.length - 1, 1, valPeek);
          item.set('upsellCategories', val);
        }
      },

      close() {
        this.close(this.item);
      },

      save() {
        this.set('isLoading', true);
        let item = this.item;
        item.save().then(response => {
          this.set('isLoading', false);
          this.close();
        }).catch(error => {
          this.set('isLoading', false);
          this.notifications.error(this.intl.t('unexpected_error'), {
            clearDuration: 5000,
            autoClear: true
          });
        });
      },

      delete(item) {
        this.set('isLoading', true);
        item.destroyRecord().then(response => {
          this.set('isLoading', false);
          this.close();
        }).catch(error => {
          this.set('isLoading', false);
          this.notifications.error(this.intl.t('unexpected_error'), {
            clearDuration: 5000,
            autoClear: true
          });
        });
      }

    }
  });

  _exports.default = _default;
});