define("alliance-business-suite/components/ui/input", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@glimmer/component"], function (_exports, _applyDecoratedDescriptor2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  let UiInput = (_dec = Ember._action, (_class = class UiInput extends _component.default {
    validateInput(event) {
      Ember.set(this.args.changeset, this.args.propertyName, event.target.value);
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class.prototype, "validateInput", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "validateInput"), _class.prototype)), _class));
  _exports.default = UiInput;
});