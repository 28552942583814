define("alliance-business-suite/templates/components/machines/programs/cycles/steps-acas-wx-flw", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lSiEblNQ",
    "block": "{\"symbols\":[\"control\"],\"statements\":[[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,0,[\"stepControls\",\"controls\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,2],[[30,[36,1],[\"form-ui/steps/\",[30,[36,0],[[32,1,[\"type\"]],[32,1]],null]],null]],[[\"object\",\"update\",\"formSettings\",\"selectOptions\",\"params\",\"machineSettings\",\"machineProgram\",\"formDisplay\"],[[32,0,[\"selectedStep\"]],[32,0,[\"update\"]],[32,0,[\"settings\"]],[32,0,[\"selectOptions\"]],[32,0,[\"stepControls\"]],[32,0,[\"machineProgram\",\"_machineSettings\"]],[32,0,[\"machineProgram\"]],\"compact\"]]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"or\",\"concat\",\"component\",\"-track-array\",\"each\"]}",
    "moduleName": "alliance-business-suite/templates/components/machines/programs/cycles/steps-acas-wx-flw.hbs"
  });

  _exports.default = _default;
});