define("alliance-business-suite/components/services/room-services-edit", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SERVICE_TYPES = [{
    id: 'order-setting',
    text: 'order_setting',
    subtitle: 'subtitle_order_setting'
  }, {
    id: 'line-item-type',
    text: 'line_item_type',
    subtitle: 'subtitle_line_item_type'
  }];

  var _default = Ember.Component.extend({
    access: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    editDisabled: Ember.computed('guideline.room', 'isSaving', function () {
      let canUpdate = this['can-access'].canAccess('SERVICE_LOCATION_UPDATE');

      if (!canUpdate || this.isSaving) {
        return true;
      }
    }).readOnly(),
    serviceTypes: Ember.computed(function () {
      return SERVICE_TYPES;
    }).readOnly(),
    selectedServiceType: Ember.computed('model', function () {
      let model = this.model;

      if (model.get('modelType') == 'order-setting') {
        return SERVICE_TYPES[0];
      } else {
        return SERVICE_TYPES[1];
      }
    }),
    currencySymbol: Ember.computed(function () {
      return this.get('access.currencyDenomination');
    }),
    currencyDecimals: Ember.computed(function () {
      if (this.get('access.currencySubunit') === 1) {
        return 0;
      } else {
        return 2;
      }
    }),
    displayPrice: Ember.computed(function () {
      return this.get('model.price');
    }),
    taxOptions: Ember.computed('tax', {
      get() {
        return this.taxes;
      }

    }),
    selectedTaxes: Ember.computed({
      get() {
        return this.get('model.taxRates');
      }

    }),
    adjustPriceObserver: Ember.observer('displayPrice', function () {
      let displayPrice = this.displayPrice;
      this.set('model.price', this.displayPrice);
    }),
    adjustTaxObserver: Ember.observer('selectedTaxes', function () {
      let selectedTaxes = this.selectedTaxes;
      this.set('model.taxRates', selectedTaxes);
    }),
    actions: {
      exit(model) {
        if (model && model.get('hasDirtyAttributes')) {
          model.rollbackAttributes();
        }

        this.close();
      },

      delete(model) {
        if (confirm(this.intl.t('room_service_delete_confirm'))) {
          this.set('isSaving', true);
          model.destroyRecord({
            adapterOptions: {
              roomId: this.get('room.id'),
              serviceId: model.get('id')
            }
          }).then(() => {
            this.refreshServices();
            this.close();
            this.notifications.info(this.intl.t('service_removed'), {
              autoClear: true,
              clearDuration: 5000
            });
          });
        }
      },

      save(model) {
        this.set('isSaving', true);
        model.save({
          adapterOptions: {
            roomId: this.get('room.id')
          }
        }).then(() => {
          this.set('isSaving', false);
          this.notifications.info(this.intl.t('successfully_updated'), {
            autoClear: true,
            clearDuration: 5000
          });
          this.close();
        }, () => {
          this.set('isSaving', false);
          this.notifications.error(this.intl.t('save_failed'));
        });
      }

    }
  });

  _exports.default = _default;
});