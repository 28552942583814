define("alliance-business-suite/components/products/taxes-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    showCreateTaxModal: false,
    actions: {
      toggleTaxModal(tax) {
        this.set('tax', tax);
        this.toggleProperty('showCreateTaxModal');
      }

    }
  });

  _exports.default = _default;
});