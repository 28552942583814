define("alliance-business-suite/components/welcome/welcome-owner-app-promo", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  let WelcomeWelcomeOwnerAppPromoComponent = (_dec = Ember.inject.service, _dec2 = Ember._action, (_class = class WelcomeWelcomeOwnerAppPromoComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "brand", _descriptor, this);
      (0, _defineProperty2.default)(this, "promoImg", this.brand.slug === 'sq' ? 'sq-owner-app-promo' : 'hu-owner-app-promo');
      (0, _defineProperty2.default)(this, "urls", [{
        apple: 'https://apps.apple.com/us/app/speed-queen-insights/id1476082966',
        android: 'https://play.google.com/store/apps/details?id=com.alliancelaundry.b2b'
      }, {
        apple: 'https://apps.apple.com/us/app/huebsch-command/id1477625102',
        android: 'https://play.google.com/store/apps/details?id=com.alliancelaundry.b2b.huebsch'
      }]);
      (0, _defineProperty2.default)(this, "badges", [{
        image: '/assets/images/download-apple-app-store.png',
        url: this.brand.slug === 'sq' ? this.urls[0].apple : this.urls[1].apple
      }, {
        image: '/assets/images/download-google-play-store.png',
        url: this.brand.slug === 'sq' ? this.urls[0].android : this.urls[1].android
      }]);
    }

    continue() {
      this.args.updateCurrentView();
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "brand", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "continue", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "continue"), _class.prototype)), _class));
  _exports.default = WelcomeWelcomeOwnerAppPromoComponent;
});