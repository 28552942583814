define("alliance-business-suite/templates/components/form-ui/date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "n6iePYAl",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[32,0,[\"show\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"label\"],[14,0,\"form-ui-element\"],[12],[1,[32,0,[\"label\"]]],[2,\"\\n  \"],[8,\"pikaday-input\",[],[[\"@format\",\"@value\",\"@onSelection\",\"@disabled\"],[[32,0,[\"format\"]],[32,0,[\"value\"]],[30,[36,0],[[32,0],\"doChange\"],null],[32,0,[\"params\",\"isDisabled\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"dev-tools\"],[12],[2,\"\\n    \"],[10,\"ul\"],[14,0,\"pills\"],[12],[2,\"\\n      \"],[10,\"li\"],[12],[1,[30,[36,1],[\"disabled\"],null]],[2,\": \"],[1,[32,0,[\"params\",\"isDisabled\"]]],[13],[2,\"\\n      \"],[10,\"li\"],[12],[1,[30,[36,1],[\"value\"],null]],[2,\": \"],[1,[32,0,[\"params\",\"value\"]]],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"t\",\"if\"]}",
    "moduleName": "alliance-business-suite/templates/components/form-ui/date.hbs"
  });

  _exports.default = _default;
});