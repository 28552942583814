define("alliance-business-suite/models/subscription", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    hasMany,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    email: attr('string'),
    phone: attr('string'),
    messageLocalizationKey: attr('string'),
    machineErrorTypeLookupKey: attr('string'),
    reportLookupKey: attr('string'),
    machineErrorLookupKey: attr('string'),
    sendAtTime: attr('string'),
    sendOnDay: attr('number'),
    frequency: belongsTo('frequency'),
    subscriptionConditions: hasMany('subscription-condition'),
    subscriptionRoomConditions: hasMany('subscription-room-condition'),
    subscriptionEventType: belongsTo('subscription-event-type'),
    // splits the emails into an array for display purposes
    formattedEmails: Ember.computed('email', function () {
      let {
        email
      } = this;
      return email.split(',');
    })
  });

  _exports.default = _default;
});