define("alliance-business-suite/templates/components/maps/google-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ev0joaQV",
    "block": "{\"symbols\":[\"gmap\",\"marker\",\"m\"],\"statements\":[[10,\"div\"],[15,5,[30,[36,4],[[35,3],\"simple\",\"height:\"],null]],[12],[2,\"\\n  \"],[8,\"g-map\",[],[[\"@lat\",\"@lng\",\"@zoom\",\"@scrollwheel\",\"@zoomControl\",\"@zoomControlOptions\",\"@mapTypeControl\",\"@draggable\",\"@draggableCursor\",\"@onLoad\"],[[34,5],[34,6],[34,7],[34,8],[34,9],[30,[36,11],null,[[\"position\"],[[35,10]]]],[34,12],[34,13],[34,14],[30,[36,1],[[32,0],\"loaded\"],null]]],[[\"default\"],[{\"statements\":[[8,[32,1,[\"canvas\"]],[[24,0,\"ember-google-map\"]],[[],[]],null],[2,\"\\n\"],[6,[37,17],[[30,[36,16],[[30,[36,16],[[35,15]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,[32,1,[\"marker\"]],[],[[\"@id\",\"@lat\",\"@lng\",\"@draggable\",\"@crossOnDrag\",\"@onDragend\",\"@onClick\"],[[32,2,[\"id\"]],[32,2,[\"lat\"]],[32,2,[\"lng\"]],[32,2,[\"draggable\"]],[32,2,[\"crossOnDrag\"]],[30,[36,2],[[32,2,[\"dragend\"]],[32,2,[\"dragend\"]],[30,[36,1],[[32,0],\"emptyAction\"],null]],null],[30,[36,2],[[32,2,[\"click\"]],[32,2,[\"click\"]],[30,[36,1],[[32,0],\"emptyAction\"],null]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[32,2,[\"infoWindow\"]]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[8,[32,3,[\"infoWindow\"]],[],[[\"@isOpen\",\"@content\"],[[34,0],[32,2,[\"infoWindow\",\"content\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"]],\"parameters\":[3]}]]],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"  \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"markerTooltipOpen\",\"action\",\"if\",\"height\",\"safe-string\",\"lat\",\"lng\",\"zoom\",\"scrollwheel\",\"zoomControl\",\"zoomControlPosition\",\"hash\",\"mapTypeControl\",\"draggable\",\"draggableCursor\",\"mapMarkers\",\"-track-array\",\"each\"]}",
    "moduleName": "alliance-business-suite/templates/components/maps/google-map.hbs"
  });

  _exports.default = _default;
});