define("alliance-business-suite/routes/locations/wash-alert/index", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "alliance-business-suite/routes/_secureRoute"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _secureRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  let LocationsWashAlertIndexRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = class LocationsWashAlertIndexRoute extends _secureRoute.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "cache", _descriptor2, this);
      (0, _defineProperty2.default)(this, "allowAccess", 'LOCATIONS_WASH_ALERT_VIEW');
      (0, _defineProperty2.default)(this, "disableBrush", true);
    }

    model() {
      const organizationId = this.access.get('accessOrganization.id');

      if (!this.cache.get('loadedGeoIncludedAll')) {
        return this.store.queryRecord('organization', {
          organization: organizationId,
          geoBoundaries: true,
          include: 'all'
        });
      } else {
        return this.store.peekRecord('organization', organizationId);
      }
    } // may not even work? (april 2021)
    // @action
    // willTransition(transition) {
    //   console.log('hi', this.controller, this.controller.get('hasChanged'))
    //   let abandonMessage = 'You have unsaved changes, continue and lose changes?';
    //   if (this.controller.get('hasChanged') && !window.confirm(abandonMessage)) {
    //     transition.abort();
    //   } else {
    //     this.controller.set('activeRoom', null);
    //     this.controller.set('hasChanged', false);
    //     return true;
    //   }
    // }


    setupController(controller, model) {
      super.setupController(controller, model);
      this.cache.set('loadedGeoIncludedAll', true);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "cache", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = LocationsWashAlertIndexRoute;
});