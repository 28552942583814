define("alliance-business-suite/components/dashboard/charts/revenue-by-machine-size/revenue-by-machine-size-chart", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "alliance-business-suite/utils/summary-util", "alliance-business-suite/utils/currency-format-util"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _summaryUtil, _currencyFormatUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <HighCharts
    @content={{this.chartData}}
    @chartOptions={{this.chartOptions}}
    @theme={{@theme}} as |chart|>
  
      <Dashboard::Charts::Drilldown::DrilldownLegend
        @chart={{chart}}
        @isDrilldown={{this.isDrilldown}}
        @drillDownData={{this.drillDownData}}
        @defaultLegend={{this.defaultLegend}}
        @customLegend={{this.customLegend}} />
  </HighCharts>
  
  */
  {
    "id": "aWxo/W4q",
    "block": "{\"symbols\":[\"chart\",\"@theme\"],\"statements\":[[8,\"high-charts\",[],[[\"@content\",\"@chartOptions\",\"@theme\"],[[32,0,[\"chartData\"]],[32,0,[\"chartOptions\"]],[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n    \"],[8,\"dashboard/charts/drilldown/drilldown-legend\",[],[[\"@chart\",\"@isDrilldown\",\"@drillDownData\",\"@defaultLegend\",\"@customLegend\"],[[32,1],[32,0,[\"isDrilldown\"]],[32,0,[\"drillDownData\"]],[32,0,[\"defaultLegend\"]],[32,0,[\"customLegend\"]]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "alliance-business-suite/components/dashboard/charts/revenue-by-machine-size/revenue-by-machine-size-chart.hbs"
  });

  const COLORS = ['#6a71e5', '#0091ff', '#2cbae7', '#61d2fe', '#48dc6b', '#ff4966', '#ff5534', '#ffa500', '#ffd200', '#9fa0a4', '#97449c', '#0e5218', '#302d7c', '#c52961', '#e35604'];
  let DashboardChartsRevenueByMachineSizeRevenueByMachineSizeChartComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, (_class = class DashboardChartsRevenueByMachineSizeRevenueByMachineSizeChartComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "brush", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "isDrilldown", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "drillDownData", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "chartTotal", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "customLegend", _descriptor7, this);
    }

    get chartData() {
      if (!this.args.results) {
        return;
      }

      return this.seriesData();
    }

    get defaultLegend() {
      return [{
        name: this.intl.t('washers'),
        color: COLORS[0]
      }, {
        name: this.intl.t('dryers'),
        color: COLORS[1]
      }];
    }

    seriesData() {
      let totals = this.args.results.machineSizeTotals;
      let results = [];

      if (!totals) {
        return [];
      }

      let washers = [];
      let dryers = [];
      totals.forEach(day => {
        if (day.isWasher) {
          washers.push(day);
        } else {
          dryers.push(day);
        }
      });
      let washerSeries = washers.map(type => {
        return {
          name: type.machineType,
          y: type.revenue,
          drilldown: type.id.toString(),
          color: COLORS[0]
        };
      });
      let dryerSeries = dryers.map(type => {
        return {
          name: type.machineType,
          y: type.revenue,
          drilldown: type.id.toString(),
          color: COLORS[1]
        };
      });
      let seriesData = washerSeries.concat(dryerSeries);
      results.push({
        name: this.intl.t('machine_types'),
        data: seriesData,
        axisLabel: 'Machine Types',
        subtitleText: this.intl.t('click_the_columns_for_machine_breakdown')
      });
      return results;
    }

    drilldownData() {
      let totals = this.args.results.machineSizeTotals;

      if (!totals) {
        return [];
      }

      let drilldownData = [];
      let checkedRoomIds = [];
      totals.forEach(type => {
        // sort the facilities so as to group them by color
        let sortedRooms = type.machines.sort((a, b) => {
          return a.room.id - b.room.id;
        });
        let mapped = sortedRooms.map(machine => {
          // group facilities by color
          let columnColor = '';

          if (checkedRoomIds.length == 0) {
            checkedRoomIds.push(machine.room.id);
            columnColor = COLORS[0];
          } else {
            let existingRoomIdx = checkedRoomIds.indexOf(machine.room.id);

            if (existingRoomIdx < 0) {
              checkedRoomIds.push(machine.room.id);
              columnColor = COLORS[checkedRoomIds.length - 1];
            } else {
              columnColor = COLORS[existingRoomIdx];
            }
          }

          return {
            facility: machine.room.name,
            facilityNumber: machine.room.id,
            machineNumber: machine.machineNumber,
            name: `${machine.room.id}:${_summaryUtil.machineNameDisplay.call(this, machine.machineName, machine.machineNumber)}`,
            y: machine.revenue,
            color: columnColor,
            id: machine.id
          };
        });
        drilldownData.push({
          id: type.id.toString(),
          // dataLabels: {
          //   enabled: true,
          //   inside: false,
          //   format: '${point.y:,.2f}'
          // },
          cursor: 'pointer',
          customLegend: this.drilldownLegend,
          axisLabel: this.intl.t('machines'),
          data: mapped
        });
      });
      return drilldownData;
    }

    drilldownLegend(drillDownData) {
      let facIds = [];
      let facObjs = [];
      drillDownData.forEach(function (data) {
        let idx = facIds.indexOf(data.facilityNumber);

        if (idx == -1) {
          facIds.push(data.facilityNumber);
          let obj = {
            name: data.facility,
            color: data.color
          };
          facObjs.push(obj);
        }
      });
      return facObjs;
    }

    get chartOptions() {
      let subtitleText = ''; //this.intl.t('click_the_columns_for_machine_breakdown').string;

      let self = this;
      Highcharts.setOptions({
        lang: {
          drillUpText: `${this.intl.t('back_to')} {series.name}`
        }
      }); // TODO: localization

      return {
        chart: {
          marginTop: 30,
          type: 'column',
          backgroundColor: '#fafcfe',
          events: {
            load: e => {
              this.args.isLoaded();
            },
            render: e => {
              if (e.target.series && e.target.series[0]) {
                let total = 0;
                e.target.series[0].data.forEach(data => {
                  total += data.y;
                });
                this.chartTotal = total;
              }

              Ember.run.later(() => {
                if (!this.isDestroyed) {
                  e.target.reflow();
                }
              }, 220); // about how long it takes for the liquid animation to finish
            },
            drilldown: e => {
              this.isDrilldown = true;
              this.drillDownData = e.seriesOptions.data;
              this.customLegend = e.seriesOptions.customLegend;
              e.target.xAxis[0].update({
                title: {
                  text: e.seriesOptions.axisLabel
                }
              }, false, false);
              e.target.subtitle.update({
                text: e.seriesOptions.subtitleText || ' '
              }, false, false);
              Ember.run.later(() => {
                if (e.target.legend) {
                  e.target.redraw();
                }
              }, 550);
            },
            drillup: e => {
              this.isDrilldown = false;
              e.target.xAxis[0].update({
                title: {
                  text: e.seriesOptions.axisLabel
                }
              }, false, false);
              e.target.subtitle.update({
                text: e.seriesOptions.subtitleText || ' '
              }, false, false);
            }
          }
        },
        title: {
          text: ''
        },
        subtitle: {
          text: subtitleText
        },
        xAxis: {
          type: 'category',
          title: {
            text: this.intl.t('machine_types')
          },
          labels: {
            // format: '${value:,.0f}',
            // HACK: highcharts will group automatically by name so we give each name a long unique string (facility + machine number)
            // then format the string to return just the machine number (non-unique)
            formatter() {
              let str = this.value.toString();
              let strIndx = str.indexOf(':');

              if (strIndx != -1) {
                return str.substr(strIndx + 1);
              }

              return str;
            }

          }
        },
        yAxis: {
          title: {
            text: this.intl.t('revenue')
          },
          labels: {
            formatter() {
              return _currencyFormatUtil.formatCurrencyString.call(self, this.value);
            }

          }
        },
        legend: {
          enabled: false
        },
        plotOptions: {
          column: {
            stacking: null
          },
          series: {
            stacking: null,
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              inside: false,

              formatter() {
                return _currencyFormatUtil.formatCurrencyString.call(self, this.y);
              }

            },
            point: {
              events: {
                click: e => {
                  if (e.point.id) {
                    this.brush.transitionTo('machine', {
                      id: e.point.id,
                      type: 'machine'
                    }, 'show');
                  }
                }
              }
            }
          }
        },
        tooltip: {
          headerFormat: '',
          pointFormat: '<span style="color:{point.color}">{point.facility}</span><b> ${point.y:,.2f}</b>',

          formatter() {
            return _currencyFormatUtil.formatCurrencyString.call(self, this.y);
          }

        },
        drilldown: {
          // For multiple drilldowns call multiple functions and then array merge them into 1 array
          series: this.drilldownData(),

          formatter() {
            return _currencyFormatUtil.formatCurrencyString.call(self, this.y);
          }

        }
      };
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "brush", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isDrilldown", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "drillDownData", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "chartTotal", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "customLegend", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = DashboardChartsRevenueByMachineSizeRevenueByMachineSizeChartComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, DashboardChartsRevenueByMachineSizeRevenueByMachineSizeChartComponent);
});