define("alliance-business-suite/components/ui-page/page-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['cssContentContainer:content-container'],
    classNames: ['container--placeholder'],
    // flag to disable 'content-container' class
    cssContentContainer: true
  });

  _exports.default = _default;
});