define("alliance-business-suite/serializers/customer", ["exports", "alliance-business-suite/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    attrs: {// phoneNumbers: { serialize: true }
    },

    /**
     * Hacks a JSON API data object returned within a JSON API document to
     * conform to what is required to make the endpoint work properly with Ember
     * Data and within this application.
     *
     * @param {object} data A data object from a JSON API document
     * @protected
     */
    normalizeDataHack(data) {
      this.toLinkRelationship(data, 'accounts'); // this.toLinkRelationship(data, 'facilities');

      this.toLinkRelationship(data, 'userRoles', 'roles'); // this.toLinkRelationship(data, 'notes', 'note');

      data.type = 'customers';
    }

  });

  _exports.default = _default;
});