define("alliance-business-suite/templates/components/video-player", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YxK4nQ7P",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"wistia-video\",[],[[\"@matcher\"],[[32,0,[\"videoId\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[8,\"form-ui/dev-tools\",[],[[\"@outputs\"],[[30,[36,6],null,[[\"hasVideo\",\"videoId\",\"sq\",\"hb\",\"pr\"],[[35,0],[35,5],[35,4],[35,3],[35,2]]]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"hasVideo\",\"if\",\"pr\",\"hb\",\"sq\",\"videoId\",\"hash\"]}",
    "moduleName": "alliance-business-suite/templates/components/video-player.hbs"
  });

  _exports.default = _default;
});