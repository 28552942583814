define("alliance-business-suite/components/ui/tab-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Renders a single tab, along with an optional count.
   *
   * # Positional Parameters
   *
   * name
   * : The unique name of the tab.
   *
   * # Named Parameters
   *
   * label
   * : The label for the tab. If none is provided, the label will be retrieved
   *   by appending the `name` to the `intlPrefix` to lookup the translated text.
   *   If `intlPrefix` is null, the `name` itself will be used as the label.
   *
   * intlPrefix
   * : The prefix used to lookup translation keys for this component.
   *
   * onclick
   * : An action to call when this tab is clicked. Passed the `name` as the first
   *   parameter.
   *
   *
   * @type {[type]}
   * @public
   */
  let ComponentClass = Ember.Component.extend({
    /**
     * The service used to do the translation.
     *
     * @protected
     */
    intl: Ember.inject.service(),

    /**
     * The static class names for this component.
     *
     * @type {Array<String>}
     * @public
     */
    classNames: ['ui-tab-item'],

    /**
     * The tag name used for this component; an empty string prevents a DOM
     * element from being created, instead rendering the template for this
     * component directly underneath its parent.
     *
     * @protected
     */
    tagName: 'li',

    /**
     * An array of tabs to render.
     *
     * @type {Array<string>}
     * @public
     */
    name: undefined,

    /**
     * The currently selected tab.
     *
     * @type {string}
     * @public
     */
    label: Ember.computed('name', 'intlPrefix', 'count', {
      get() {
        let name = this.name;
        let intlPrefix = this.intlPrefix;
        return intlPrefix ? this.intl.t(`${intlPrefix}.${name}`) : name;
      }

    }).readOnly(),

    /**
     * Handles clicks on this component.
     *
     * @public
     */
    click() {
      let onclick = this.onclick;

      if (onclick) {
        onclick(this.name);
      }
    }

  });
  ComponentClass.reopenClass({
    positionalParams: ['name']
  });
  var _default = ComponentClass;
  _exports.default = _default;
});