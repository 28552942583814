define("alliance-business-suite/helpers/show-machine", ["exports", "alliance-business-suite/utils/show-features"], function (_exports, _showFeatures) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    access: Ember.inject.service(),
    brand: Ember.inject.service(),

    compute(params, hash) {
      return (0, _showFeatures.showMachineFeature)({
        ifMachine: hash.ifMachine,
        notMachine: hash.notMachine,
        machineProgram: hash.machineProgram
      });
    }

  });

  _exports.default = _default;
});