define("alliance-business-suite/components/machines/programs/water-guardian/mgd-opl", ["exports", "alliance-business-suite/mixins/machines/programs/mgd-options"], function (_exports, _mgdOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_mgdOptions.default, {
    machineAuditType: Ember.computed.alias('machineProgram.machineAuditType.name')
  });

  _exports.default = _default;
});