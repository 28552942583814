define("alliance-business-suite/mixins/init-property-names-v1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    init() {
      this._super(...arguments); // This handles bindings for components that update multiple properties: low-power, lucky cycle, manual mode, power-fail-reset
      // It sets up CPs for the property name strings and CPs to the property values.


      let propertyNames = Ember.get(this, 'params.propertyNames');

      if (propertyNames !== undefined) {
        Ember.set(this, 'propertyNames', {});
        let index = Ember.get(this, 'index');

        if (Ember.isEmpty(index)) {
          index = Ember.get(this, 'params.index');
        } // Loop through each property to create new CPs


        Object.keys(propertyNames).forEach(key => {
          let propertyName = Ember.get(this, `params.propertyNames.${key}`);

          if (index !== undefined) {
            propertyName = propertyName.replace('${index}', index); // Replace the index placeholder
          }

          Ember.set(this, `propertyNames.${key}`, propertyName);
          Ember.defineProperty(this, key, Ember.computed.reads(`object.${propertyName}`));
        });
      }
    }

  });

  _exports.default = _default;
});