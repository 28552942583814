define("alliance-business-suite/components/models-table/global-filter", ["exports", "ember-models-table/components/models-table/global-filter"], function (_exports, _globalFilter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _globalFilter.default;
    }
  });
});