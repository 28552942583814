define("alliance-business-suite/models/phone-number", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    phoneNumberType: belongsTo('phoneNumberType'),
    internationalCode: attr('string'),
    areaCode: attr('string'),
    contactNumber: attr('string'),
    isVerified: attr('boolean'),
    isActive: attr('boolean'),
    createdAt: attr('date'),
    createdBy: belongsTo('user', {
      inverse: null
    }),
    updatedAt: attr('date'),
    updatedBy: belongsTo('user', {
      inverse: null
    }),
    user: belongsTo('user'),
    phoneNumber: attr('string'),
    telecomInternationalCode: attr('string'),
    telecomAreaCode: attr('string'),
    telecomContactNumber: attr('string') // formattedPhoneNumber: computed('internationalCode', 'areaCode', 'contactNumber', {
    //   get() {
    //
    //     let contactNumber = get(this, 'contactNumber').match(/\d{3}(?=\d{2,3})|\d+/g).join('-');
    //     return `+${get(this, 'internationalCode')} (${get(this, 'areaCode')}) ${contactNumber}`;
    //   }
    // })

  });

  _exports.default = _default;
});