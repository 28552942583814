define("alliance-business-suite/templates/components/summary/reports/s-store-revenue-rewards", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GAXbnltx",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[10,\"div\"],[14,0,\"content-container container--placeholder\"],[12],[2,\"\\n  \"],[10,\"img\"],[14,0,\"scrubbrush-watermark\"],[14,\"src\",\"/assets/images/quickbooks.svg\"],[12],[13],[2,\"\\n  \"],[10,\"h3\"],[12],[1,[30,[36,0],[\"quickbooks_only_report\"],null]],[13],[2,\"\\n  \"],[10,\"h4\"],[12],[1,[30,[36,0],[\"quickbooks_only_report_subtitle\"],null]],[13],[2,\"\\n  \"],[10,\"h2\"],[14,0,\"center\"],[12],[2,\"\\n    \"],[10,\"a\"],[14,0,\"btn btn-primary\"],[15,6,[32,1,[\"quickbooks\"]]],[12],[1,[30,[36,0],[\"download\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "moduleName": "alliance-business-suite/templates/components/summary/reports/s-store-revenue-rewards.hbs"
  });

  _exports.default = _default;
});