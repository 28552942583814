define("alliance-business-suite/components/one-way-tel", ["exports", "ember-one-way-controls/components/one-way-tel"], function (_exports, _oneWayTel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _oneWayTel.default;
    }
  });
});