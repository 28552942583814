define("alliance-business-suite/components/summary/reports/s-cycle-count", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SummaryReportsSCycleCountComponent extends _component.default {
    get translatedColumns() {
      return [{
        name: 'room',
        valuePath: 'name',
        width: 180,
        isFixed: 'left'
      }, {
        name: 'items_name',
        valuePath: 'itemName',
        width: 180
      }, {
        name: 'expected_amount',
        valuePath: 'expectedAmount',
        width: 180
      }, {
        name: 'counted_amount',
        valuePath: 'actualAmount',
        width: 180
      }, {
        name: 'difference',
        valuePath: 'variance',
        width: 180
      }, {
        name: 'notes',
        valuePath: 'cycleCountNotes',
        width: 180
      }];
    }

    get rowsWithChildren() {
      let cycleCounts = this.args.model.get('cycleCounts');
      let rowData = [];
      cycleCounts.forEach(cycleCount => {
        let userDataArray = [];
        rowData.push({
          name: cycleCount.name,
          children: userDataArray
        });
        cycleCount.dateUserCounts.forEach(dateUserCount => {
          let itemDataArray = [];
          let dateUserName = `${dateUserCount.dateFormatted} - ${dateUserCount.countedByFirstName} ${dateUserCount.countedByLastName}`;
          userDataArray.push({
            name: dateUserName,
            children: itemDataArray
          });
          dateUserCount.items.forEach(item => {
            itemDataArray.push({
              itemName: item.itemName || 'N/A',
              expectedAmount: item.expectedAmount || 0,
              actualAmount: item.actualAmount || 0,
              variance: item.variance || 0,
              cycleCountNotes: item.cycleCountNotes
            });
          });
        });
      });
      return rowData;
    }

  }

  _exports.default = SummaryReportsSCycleCountComponent;
});