define("alliance-business-suite/components/rewards/machine-type-rewards", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    access: Ember.inject.service(),
    notifications: Ember.inject.service(),

    didReceiveAttrs() {
      this._super(...arguments);

      this.fetchMachineTypes();
    },

    fetchMachineTypes() {
      this.set('isLoading', true);
      this.store.findAll('machineType').then(() => {
        setTimeout(() => {
          this.set('isLoading', false);
        }, 4000);
      });
    },

    actions: {
      toggleProgramMachine(programMachine) {
        if (this.access.isDemo) {
          alert('Demo mode restricted.');
          return;
        }

        if (programMachine) {
          this.set('selectedProgramMachine', programMachine);
        }

        this.toggleProperty('viewEditProgramMachine');
      },

      addMachine() {
        if (this.access.isDemo) {
          alert('Demo mode restricted.');
          return;
        }

        let newMachineType = this.store.createRecord('rewardsProgramMachineType', {
          rewardsProgram: this.model
        });
        this.set('selectedProgramMachine', newMachineType);
        this.toggleProperty('viewEditProgramMachine');
      }

    }
  });

  _exports.default = _default;
});