define("alliance-business-suite/components/ui/modal-balance-edit", ["exports", "alliance-business-suite/mixins/currency-format"], function (_exports, _currencyFormat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_currencyFormat.default, {
    ajax: Ember.inject.service(),
    access: Ember.inject.service(),
    intl: Ember.inject.service(),
    notifications: Ember.inject.service(),
    // newBalance: ${this.get('access.currencyFormatStringWithoutThousands',
    netBalanceClass: '',
    userAccountId: null,
    isCredit: true,
    newBalance: Ember.computed('currentBalance', 'currentRewardsBalance', function () {
      if (this.isRewardsEdit) {
        return Ember.get(this, 'currentRewardsBalance');
      } else {
        return Ember.get(this, 'currentBalance');
      }
    }),
    currencySubunit: Ember.computed(function () {
      return Ember.get(this, 'access.currencySubunit');
    }),
    currencySymbol: Ember.computed(function () {
      return Ember.get(this, 'access.currencyDenomination');
    }),
    balanceDifference: Ember.observer('newBalance', function () {
      let currentBalance = null;

      if (this.isRewardsEdit) {
        currentBalance = Ember.get(this, 'currentRewardsBalance');
      } else {
        currentBalance = Ember.get(this, 'currentBalance');
      }

      let newBalance = Ember.get(this, 'newBalance');
      let diff = (newBalance - currentBalance).toFixed(0);

      if (diff < 0) {
        Ember.set(this, 'isCredit', false);
      } else {
        Ember.set(this, 'isCredit', true);
      }

      Ember.set(this, 'proposedBalanceDifference', diff);
      return;
    }),
    actions: {
      close() {
        if (!Ember.get(this, 'isLoading')) {
          this.close();
        }
      },

      updateBalance() {
        let self = this;
        let isCredit = Ember.get(this, 'isCredit');
        let userAccountId = Ember.get(this, 'userAccountId');
        let fundsRootId = Ember.get(this, 'fundsRootId');
        let proposedBalanceDifference = Ember.get(this, 'proposedBalanceDifference');
        let confirmText = Ember.get(this, 'intl').t('confirm_balance_adjustment');
        let isPromotionalCredit = false;

        if (this.isRewardsEdit) {
          confirmText = Ember.get(this, 'intl').t('confirm_rewards_balance_adjustment');
          isPromotionalCredit = true;
        }

        if (proposedBalanceDifference < 0) {
          confirmText = `${confirmText} (${this.formatCurrencyString(Math.abs(proposedBalanceDifference))})`;
        } else {
          confirmText = `${confirmText} ${this.formatCurrencyString(proposedBalanceDifference)}`;
        }

        if (!confirm(confirmText)) {
          return;
        }

        Ember.set(this, 'isLoading', true);
        let data = {
          amount: parseInt(Math.abs(proposedBalanceDifference)),
          geoBoundary: {
            id: fundsRootId
          },
          isCredit,
          isPromotionalCredit
        };
        let uri = `/accounts/${userAccountId}/updateBalance`;
        this.ajax.request(uri, {
          method: 'POST',
          data: JSON.stringify(data)
        }).then(() => {
          Ember.set(this, 'isLoading', false);
          this.refresh();
          this.close();
          return;
        }).catch(error => {
          if (error.payload.message) {
            let errorMessage = error.payload.message;

            if (errorMessage == "USER_ACCOUNT_BALANCE_CREDIT_THRESHOLD_REACHED_NO_OVERRIDE" || errorMessage == "USER_ACCOUNT_REWARDS_CREDIT_THRESHOLD_REACHED_NO_OVERRIDE") {
              self.notifications.error(self.intl.t('error_balance_threshold_reached'), {
                autoClear: true,
                clearDuration: 8000
              });
            }

            let forceCommand = isPromotionalCredit ? "forceUserAccountRewardsCredit" : "forceUserAccountBalanceCredit";

            if (errorMessage == "USER_ACCOUNT_BALANCE_CREDIT_THRESHOLD_REACHED" || errorMessage == "USER_ACCOUNT_REWARDS_CREDIT_THRESHOLD_REACHED") {
              if (confirm(self.intl.t('confirm_balance_override'))) {
                data[forceCommand] = true;
                self.ajax.request(uri, {
                  method: "POST",
                  data: JSON.stringify(data)
                }).then(() => {
                  self.refresh();
                });
              }
            }

            self.close();
            return;
          }

          self.notifications.error(this.intl.t('please_try_again'), {
            autoClear: true,
            clearDuration: 60000
          }); // return; // fail silently
        });
      }

    }
  });

  _exports.default = _default;
});