define("alliance-business-suite/models/shift", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // const { Model, attr, belongsTo } = DS;
  var _default = _model.default.extend({
    // Timezone note: All times are saved in UTC but displayed in the timezone of the current room.
    // The user's timezone preference has no effect on the time values being displayed.
    startDatetime: (0, _model.attr)(),
    endDatetime: (0, _model.attr)(),
    ratePerHour: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    room: (0, _model.belongsTo)('room'),
    employee: (0, _model.belongsTo)('employee'),
    currentShiftState: Ember.computed('isNew', 'isDeleted', 'isError', 'hasDirtyAttributes', function () {
      let isNew = this.isNew;
      let isDeleted = this.isDeleted;
      let isShiftDirty = this.hasDirtyAttributes;
      let isError = this.isError;

      if (isError) {
        return 'error';
      }

      if (isDeleted) {
        return 'deleted';
      }

      if (isNew) {
        return 'new';
      }

      if (isShiftDirty) {
        return 'dirty';
      }

      return 'unchanged';
    }),
    roomTimezone: Ember.computed.alias('room.timeZoneName'),
    startTime: Ember.computed('localStartDatetime', 'startDatetime', 'roomTimezone', {
      get() {
        return moment(this.localStartDatetime).tz(this.roomTimezone).format('HH:mm');
      },

      set(key, value) {
        this.set('localStartDatetime', value.localDateTime);
        return value.val;
      }

    }),
    localStartDatetime: Ember.computed('roomTimezone', 'startDatetime', {
      get() {
        return moment.utc(this.startDatetime).tz(this.roomTimezone).format();
      },

      set(key, value) {
        let newtime = moment.tz(value, this.roomTimezone).utc().format();
        this.set('startDatetime', newtime);
        return newtime;
      }

    }),
    endTime: Ember.computed('endDatetime', 'localEndDatetime', 'roomTimezone', {
      get() {
        return moment(this.localEndDatetime).tz(this.roomTimezone).format('HH:mm');
      },

      set(key, value) {
        this.set('localEndDatetime', value.localDateTime);
        return value.val;
      }

    }),
    localEndDatetime: Ember.computed('endDatetime', 'roomTimezone', {
      get() {
        let result = moment.utc(this.endDatetime).tz(this.roomTimezone).format();
        return result;
      },

      set(key, value) {
        let newtime = moment.tz(value, this.roomTimezone).utc().format();
        this.set('endDatetime', newtime);
        return newtime;
      }

    })
  });

  _exports.default = _default;
});