define("alliance-business-suite/routes/start", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "alliance-business-suite/routes/_secureRoute", "alliance-business-suite/config/environment", "alliance-business-suite/utils/org-setup-util", "alliance-business-suite/mixins/account-payment"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _secureRoute, _environment, _orgSetupUtil, _accountPayment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  let StartRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, (_class = class StartRoute extends _secureRoute.default.extend(_accountPayment.default) {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "ajax", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "brush", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "canAccess", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "session", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor6, this);
      (0, _defineProperty2.default)(this, "disableBrush", true);
    }

    // @computed('session.data.hasAcceptedTOS')
    get hasAcceptedTOS() {
      return this.session.get('data.hasAcceptedTOS');
    }

    async redirect() {
      let {
        canAccess
      } = this;
      canAccess.listRoles;

      if (!this.session.get('data.userOrgs')) {
        this.session.invalidate();
      } // if there is only 1 organization to that user, automatically pass them through


      if (this.session.get('data.userOrgs').get('length') == 1 && !this.session.get('data.hasPassedOrgSelect')) {
        let onlyOrg = this.session.get('data.userOrgs').get('firstObject');

        _orgSetupUtil.findOrg.call(this, onlyOrg.id, onlyOrg.brandName);

        this.transitionTo('loading');
        return;
      }

      if (!this.session.get('data.hasAcceptedTOS')) {
        this.transitionTo('welcome');
        return;
      }

      if (!this.session.get('data.hasPassedOrgSelect')) {
        this.transitionTo('organizations');
        return;
      }

      if (this.hasAcceptedTOS) {
        let session = this.session;
        let orgId = this.session.get('data.orgId');
        let email = session.get('data.accessPrimaryEmail');
        let confirmPaymentDetails = true;

        if (_environment.default.configEnvironment == 'development' || _environment.default.configEnvironment == 'staging') {
          console.log('whitelisting all dev users');
          confirmPaymentDetails = false;
        }

        if (_environment.default.configEnvironment == 'production') {
          // WHITELIST ALL USERS
          if (this.access.get('whiteListPayment')) {
            console.log('whitelisting all prod users');
            confirmPaymentDetails = false;
          }
        }

        if (confirmPaymentDetails && orgId) {
          await this.retrieveAccountExpiration(session, orgId);

          if (!this.access.get('isAuthorizedPayment')) {
            return;
          }
        }

        let defaultRoute;
        let startMapping = [// In order of preference for redirects
        {
          route: 'summary',
          permission: 'SUMMARY_VIEW_PAGE'
        }, {
          route: 'locations',
          permission: 'LOCATIONS_VIEW_PAGE'
        }, {
          route: 'machines',
          permission: 'MACHINES_VIEW_PAGE'
        }, {
          route: 'customers',
          permission: 'CUSTOMERS_VIEW_PAGE'
        }, {
          route: 'employees',
          permission: 'EMPLOYEES_VIEW_PAGE'
        }, {
          route: 'crm',
          permission: 'MARKETING_VIEW_PAGE'
        } // { route: 'account', permission: 'MY_ACCOUNT_VIEW_PAGE' },
        // { route: 'roles', permission: 'SECURITY_ROLES_VIEW_PAGE' }
        ];
        let routeName = startMapping.find(item => {
          return canAccess.canAccess(item.permission);
        });
        defaultRoute = routeName.route; // console.log('Starting user at', defaultRoute);

        if (!defaultRoute) {
          this.logr.captureException(new Error('no assigned roles, defaultRoute is undefined'));
        }

        session.set('data.hasGoneThroughStartRoute', true);

        if (this.access.isDisconnected) {
          this.transitionTo('summary.reports');
        } else {
          this.transitionTo(defaultRoute);
        }
      } else {
        this.transitionTo('welcome');
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "brush", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "canAccess", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "session", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = StartRoute;
});