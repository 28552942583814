define("alliance-business-suite/models/role", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    hasMany
  } = _emberData.default;
  const NAMES = {
    // Roles retrieved from `/roles` in the API
    'CUSTOMER': 'Customer',
    'GUEST': 'Guest',
    'EMPLOYEE_ADMIN': 'Employee Administrator',
    'EMPLOYEE_DISTRICT_MANAGER': 'District Manager',
    'EMPLOYEE_FACILITY_MANAGER': 'Facility Manager',
    'EMPLOYEE_FACILITY_ASSOCIATE': 'Facility Associate',
    'EMPLOYEE_DELIVERY_PERSON': 'Delivery Person',
    'SUPER_ADMIN': 'Super Administrator',
    // Roles returned from other places in the API; names are improviesd
    'PERSON_ALLIANCE_ADMIN': 'Alliance Admin',
    'PERSON_CUSTOMER': 'Customer',
    'PERSON_FRANCHISE_FACILITY_MANAGER': 'Franchise Facility Manager'
  };

  var _default = Model.extend({
    // HACK: The `name` is not being returned as an attribute from
    //       `/users/:id/roles`, so I'm calculating it manually using a list from
    //       above. [twl 1.May.17]
    //
    //  name: attr('string')
    name: Ember.computed('id', {
      get() {
        return NAMES[this.id];
      }

    }),
    // TODO: To implement this, we need a `/roles/:roleId/users` endpoint.
    //       [twl 1.May.17]
    //
    users: hasMany('users', {
      inverse: 'roles'
    })
  });

  _exports.default = _default;
});