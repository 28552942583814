define("alliance-business-suite/templates/components/machines/programs/advanced-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xRo9emqn",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,7],[[30,[36,6],[\"machines/programs/advanced/\",[35,5]],null]],[[\"form\",\"machineProgram\",\"loadDefaults\",\"clickCount\",\"advanced\"],[[35,4],[35,3],[35,2],[35,1],[35,0]]]]]],\"hasEval\":false,\"upvars\":[\"advanced\",\"clickCount\",\"loadDefaults\",\"machineProgram\",\"form\",\"formName\",\"concat\",\"component\"]}",
    "moduleName": "alliance-business-suite/templates/components/machines/programs/advanced-section.hbs"
  });

  _exports.default = _default;
});