define("alliance-business-suite/components/brushes/ui/list-item-label", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <span class="model-field__label">
    {{#if hasBlock}}
      {{yield}}
    {{else}}
      {{@label}}
  
      {{#if @tooltip}}
        <i class="fa fa-info-circle" aria-hidden="true">
          <EmberTooltip @event="hover" @_tooltipVariantClass="ember-tooltip brush-tooltip" @side="bottom-start">
            {{@tooltip}}
          </EmberTooltip>
        </i>
      {{/if}}
    {{/if}}
  </span>
  
  */
  {
    "id": "xYAufw6M",
    "block": "{\"symbols\":[\"@tooltip\",\"@label\",\"&default\"],\"statements\":[[10,\"span\"],[14,0,\"model-field__label\"],[12],[2,\"\\n\"],[6,[37,0],[[27,[32,3]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,3,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[32,2]],[2,\"\\n\\n\"],[6,[37,0],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"i\"],[14,0,\"fa fa-info-circle\"],[14,\"aria-hidden\",\"true\"],[12],[2,\"\\n        \"],[8,\"ember-tooltip\",[],[[\"@event\",\"@_tooltipVariantClass\",\"@side\"],[\"hover\",\"ember-tooltip brush-tooltip\",\"bottom-start\"]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[1,[32,1]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "alliance-business-suite/components/brushes/ui/list-item-label.hbs"
  });

  class BrushesUiListItemLabelComponent extends _component.default {}

  _exports.default = BrushesUiListItemLabelComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, BrushesUiListItemLabelComponent);
});