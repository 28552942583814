define("alliance-business-suite/templates/components/ui/cell-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZeCXqvM0",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"label\"],[12],[2,\"\\n\"],[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"input\",[],[[\"@type\",\"@checked\"],[\"checkbox\",[34,0]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"span\"],[12],[1,[34,3]],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[18,1,null],[2,\"\\n\"],[10,\"span\"],[12],[1,[34,4]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"checked\",\"hasCheckbox\",\"if\",\"label\",\"errorMessage\"]}",
    "moduleName": "alliance-business-suite/templates/components/ui/cell-input.hbs"
  });

  _exports.default = _default;
});