define("alliance-business-suite/templates/components/chat-window", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Q1kbcOtJ",
    "block": "{\"symbols\":[],\"statements\":[[8,\"modal-dialog\",[],[[\"@onClose\",\"@animatable\",\"@tetherTarget\",\"@targetAttachment\",\"@targetOffset\",\"@attachment\",\"@hasOverlay\",\"@overlayClassNames\",\"@containerClassNames\"],[\"toggleModal\",true,\"body\",\"bottom right\",\"0 -120px\",\"bottom right\",false,\"chat__overlay\",[30,[36,1],[\"ui-modal-dialog\",[35,0]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,1,\"controls\"],[12],[2,\"\\n      \"],[10,\"p\"],[12],[1,[30,[36,2],[\"live_chat\"],null]],[13],[2,\"\\n      \"],[11,\"button\"],[24,0,\"secondary btn--small\"],[4,[38,3],[[32,0],\"toggleMinimize\"],null],[12],[2,\"\\n\"],[6,[37,5],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"          \"],[10,\"i\"],[14,0,\"fa fa-window-maximize\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"          \"],[10,\"i\"],[14,0,\"fa fa-window-minimize\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,1,\"chatContainer\"],[14,5,\"height: 380px; width: 380px;\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"classNames\",\"array\",\"t\",\"action\",\"minimized\",\"if\"]}",
    "moduleName": "alliance-business-suite/templates/components/chat-window.hbs"
  });

  _exports.default = _default;
});