define("alliance-business-suite/templates/components/form-ui/button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tC8GKma5",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[35,2,[\"isPending\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[34,5]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[35,2,[\"isFulfilled\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[34,4]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[35,2,[\"isRejected\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[34,1]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[34,0]],[2,\"\\n  \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"defaultLabel\",\"errorLabel\",\"activePromise\",\"if\",\"successLabel\",\"pendingLabel\"]}",
    "moduleName": "alliance-business-suite/templates/components/form-ui/button.hbs"
  });

  _exports.default = _default;
});