define("alliance-business-suite/components/rooms/room-tab-retail", ["exports", "jquery", "ember-concurrency", "alliance-business-suite/config/environment"], function (_exports, _jquery, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    uploadFile: null,
    isLoadingRetailInfo: true,
    acceptedFileTypes: ['png', 'jpeg'],

    didReceiveAttrs() {
      this._super(...arguments);

      if (!this.get('model.receiptMarkupJson.id')) {
        let receiptMarkupJson = this.store.createRecord('receiptMarkupJson');
        this.set('model.receiptMarkupJson', receiptMarkupJson);
      }
    },

    editingDisabled: Ember.computed(function () {
      return !this['can-access'].canAccess('LOCATIONS_EDIT_DETAIL_VIEW');
    }).readOnly(),

    async uploadImage(file) {
      let _this = this;

      return await this.ajax.request(`${_environment.default.APP.ALLIANCE_API_URL}/inventory/assetUrl?fileName=${file.get('blob.name')}`, {
        method: 'GET'
      }).then(function (response) {
        let url = response.signedUrl;
        url = url.substring(0, url.indexOf('?'));
        return file.uploadBinary(response.signedUrl, {
          method: 'PUT'
        }).then(() => {
          _this.set('model.receiptMarkupJson.imageUrl', url);

          _this.set('isLoading', false);
        });
      });
    },

    actions: {
      uploadPreview(file) {
        if (this.acceptedFileTypes.indexOf(file.extension) === -1) {
          alert(`${this.intl.t('only_file_types')} ${this.acceptedFileTypes.join(', ')}`);
          return false;
        }

        let blobUrl = window.URL.createObjectURL(file.blob);

        if (blobUrl) {
          this.set('uploadFile', file);
          this.set('previewUrl', blobUrl);
          this.set('model.receiptMarkupJson.imageUrl', blobUrl);
          this.set('isLoading', true);
          this.uploadImage(file).then(result => {// item.set('imageKey', self.get('imageKey'));
            // console.log('upload done', result);
            // console.log('upload result', result);
          });
        }
      }

    }
  });

  _exports.default = _default;
});