define("alliance-business-suite/components/billing/subscription-cart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // eslint-disable-line
    tagName: '',
    access: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    cart: Ember.computed.filterBy('access.billingLocations', 'inCart', true),
    sortBy: ['name'],
    sortedCart: Ember.computed.sort('cart', 'sortBy'),
    actions: {
      // eslint-disable-line
      selectPlan(planId, location) {
        if (location) {
          Ember.set(location, 'planId', planId);
        } else {
          this.cart.forEach(element => {
            Ember.set(element, 'planId', planId);
          });
        }
      },

      unselectPlan(planId, location) {
        if (location) {
          Ember.set(location, 'planId', null);
        } else {
          this.cart.forEach(element => {
            Ember.set(element, 'planId', null);
          });
        }
      }

    }
  });

  _exports.default = _default;
});