define("alliance-business-suite/templates/components/rewards/rewards-list-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "h15e5dia",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,15],[[30,[36,14],[[35,0,[\"length\"]],0],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"content-container container--placeholder\"],[12],[2,\"\\n    \"],[10,\"h3\"],[12],[1,[30,[36,13],[\"no_reward_programs\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"inventory-table\"],[12],[2,\"\\n    \"],[8,\"models-table\",[],[[\"@data\",\"@columns\",\"@pageSize\",\"@multipleColumnsSorting\",\"@showComponentFooter\",\"@showColumnsDropdown\",\"@useFilteringByColumns\",\"@showGlobalFilter\",\"@doFilteringByHiddenColumns\",\"@useNumericPagination\",\"@filteringIgnoreCase\",\"@collapseNumPaginationForPagesCount\",\"@showPageSize\"],[[34,0],[34,1],[34,2],[34,3],[34,4],[34,5],[34,6],[34,7],[34,8],[34,9],[34,10],[34,11],[34,12]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"rewards\",\"columns\",\"pageSize\",\"multipleColumnsSorting\",\"showComponentFooter\",\"showColumnsDropdown\",\"useFilteringByColumns\",\"showGlobalFilter\",\"doFilteringByHiddenColumns\",\"useNumericPagination\",\"filteringIgnoreCase\",\"collapseNumPaginationForPagesCount\",\"showPageSize\",\"t\",\"eq\",\"if\"]}",
    "moduleName": "alliance-business-suite/templates/components/rewards/rewards-list-table.hbs"
  });

  _exports.default = _default;
});