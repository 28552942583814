define("alliance-business-suite/components/form-ui", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   *  The form-ui component does most of the heavy-lifting for quickly building out forms.
   *  The following data is needed for form-ui to work:
   *
   *    - object (this is the model that gets updated with actions-up)
   *    - formSettings (used for customizing things like currency symbol, decimals, etc.)
   *    - machineSettings (used my machine programming)
   *    - selectOptions (used to popular option lists for selects, etc.)
   *    - update (optional. By default we update the object, but this can be overridden by passing in an update function)
   *
   *  On the initial use of form-ui, these parameters can be used to let form-ui create
   *  the formSettings object for you:
   *
   *    - formDisplay: defaults to 'normal'
   *    - currencySymbol: defaults to ''
   *    - currencySymbolPosition: defaults to 'after'
   *    - decimals: defaults to 0
   *    - selectOptions: defaults to null
   *    - currencyDivideBy: defaults to 1
   *    - divideBy: defaults to 1
   *    - thousandsSymbol
   *    - decimalSymbol
   *    - machineSettings
   *    - isCelsius
   *    - showG
   */
  var _default = Ember.Component.extend({
    // eslint-disable-line ember/no-classic-classes
    tagName: 'form',
    classNames: ['ui-container', 'ui-container-v3', 'form-ui-container'],
    // We want to eventually migrate to just form-ui-container.
    // TODO: Why was this added...what does it do?
    attributeBindings: ['setMaxWidth:style'],
    setMaxWidth: Ember.computed('limitWidth', function () {
      return this.limitWidth ? Ember.String.htmlSafe("max-width:1024px; margin:0 auto;") : Ember.String.htmlSafe("");
    }),

    /*
    This is temporary. machineSettings is getting moved to formSettings and will come from the machineProgram model.
    But for Midas (and maybe elsewhere), we pass machineSettings into FormUi without passing machineProgram.
     For backwards support, we'll look for machineSettings being passed in, and use that. Next, we'll look for machineProgram
    and use the _machineSettings property in the model. This is where we eventually want to get the setting.
     Once everything gets updated, we can update the FormUi template so that...
     This:
    checkbox=(component "form-ui/checkbox" index=this.index object=this.object params=this.params update=this.update formSettings=this.formSettings machineSettings=this.__machineSettings selectOptions=this.selectOptions)
     Gets changed to this:
    checkbox=(component "form-ui/checkbox" index=this.index object=this.object params=this.params update=this.update formSettings=this.formSettings machineSettings=this.machineProgram._machineSettings selectOptions=this.selectOptions)
     And eventually we'll want to remove the machineSettings param completely, and get that data from formSettings, instead.
    Or maybe even remove formSettings and get that data directly from machineProgram...once all components are receiving the
    machineProgram param.
     */
    __machineSettings: Ember.computed.or('machineSettings', 'machineProgram._machineSettings'),
    initialFormSettings: Ember.computed('machineProgram.{_currencySymbolPosition,_currencySymbol,_selectOptions,_machineSettings,_divideBy,_currencyDivideBy,_thousandsSymbol,_isCelsius,_showG,_decimals,_decimalSymbol}', 'showG', 'formDisplay', 'machineSettings', 'formSettings.@each', 'isCelsius', 'currencySymbolPosition', 'currencySymbol', 'display', 'decimals', 'selectOptions', 'currencyDivideBy', 'divideBy', 'thousandsSymbol', 'decimalSymbol', function () {
      // These settings are based on individual properties passed in at the top-most level of form-ui use. After that
      // we MUST pass this.formSettings into any children that use form-ui.
      if (this.machineProgram) {
        return {
          formDisplay: this.formDisplay || 'normal',
          currencySymbol: this.machineProgram._currencySymbol || this.currencySymbol || '',
          currencySymbolPosition: this.machineProgram._currencySymbolPosition || this.currencySymbolPosition || 'after',
          decimals: this.machineProgram._decimals || this.decimals || 0,
          selectOptions: this.machineProgram._selectOptions || this.selectOptions || null,
          currencyDivideBy: this.machineProgram._currencyDivideBy || this.currencyDivideBy || 1,
          divideBy: this.machineProgram._divideBy || this.divideBy || 1,
          thousandsSymbol: this.machineProgram._thousandsSymbol || this.thousandsSymbol,
          decimalSymbol: this.machineProgram._decimalSymbol || this.decimalSymbol,
          machineSettings: this.machineProgram._machineSettings || this.machineSettings,
          isCelsius: this.machineProgram._isCelsius || this.isCelsius,
          temperatureAbbrev: this.isCelsius ? 'C' : 'F',
          showG: this.machineProgram._showG || this.showG
        };
      } else {
        return {
          formDisplay: this.formDisplay || 'normal',
          currencySymbol: this.currencySymbol || '',
          currencySymbolPosition: this.currencySymbolPosition || 'after',
          decimals: this.decimals || 0,
          selectOptions: this.selectOptions || null,
          currencyDivideBy: this.currencyDivideBy || 1,
          divideBy: this.divideBy || 1,
          thousandsSymbol: this.thousandsSymbol,
          decimalSymbol: this.decimalSymbol,
          machineSettings: this.machineSettings,
          isCelsius: this.isCelsius,
          temperatureAbbrev: this.isCelsius ? 'C' : 'F',
          showG: this.showG
        };
      }
    }),
    formSettings: Ember.computed('initialFormSettings', 'initialFormSettings.[]', '_formSettings', {
      // Either use formSettings being passed in, or use initialFormSettings.
      get() {
        return this._formSettings ? this._formSettings : this.initialFormSettings;
      },

      set(key, value) {
        Ember.set(this, '_formSettings', value);
        return value;
      }

    }),
    formMeta: Ember.computed('stepTypeName', function () {
      return {
        stepTypeName: this.stepTypeName
      };
    }),

    update(object, propertyName, value) {
      if (object !== undefined && Ember.get(object, propertyName) !== undefined) {
        Ember.set(object, propertyName, value);
      } else {
        console.error('update() called with invalid arguments: object, propertyName, value');
      }
    },

    submit(event) {
      event.preventDefault();

      if (this.onSubmit) {
        this.onSubmit();
      }
    }

  });

  _exports.default = _default;
});