define("alliance-business-suite/components/summary/reports/s-store-stock", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "alliance-business-suite/utils/currency-format-util"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _currencyFormatUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  let SummaryReportsSStoreStockComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, (_class = class SummaryReportsSStoreStockComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor3, this);
    }

    get translatedColumns() {
      return [{
        name: 'room',
        valuePath: 'name',
        width: 180,
        isFixed: 'left'
      }, {
        name: 'items_name',
        valuePath: 'itemName',
        width: 180
      }, {
        name: 'quantity',
        valuePath: 'quantityOnHand',
        width: 180
      }, {
        name: 'models.product.amount',
        valuePath: 'price',
        width: 180
      }];
    }

    get rowsWithChildren() {
      let storeStocks = this.args.model.storeStock;
      let rowData = [];
      storeStocks.forEach(storeStock => {
        let itemDataArray = [];
        rowData.push({
          name: storeStock.name,
          children: itemDataArray
        });
        storeStock.items.forEach(item => {
          itemDataArray.push({
            itemName: item.itemName || 'N/A',
            quantityOnHand: item.quantityOnHand || 0,
            price: _currencyFormatUtil.formatCurrencyString.call(this, item.price || 0)
          });
        });
      });
      return rowData;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SummaryReportsSStoreStockComponent;
});