define("alliance-business-suite/models/image-type", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    hasMany
  } = _emberData.default;

  var _default = Model.extend({
    // id: attr('string'),
    images: hasMany('images')
  });

  _exports.default = _default;
});