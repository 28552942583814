define("alliance-business-suite/components/summary/reports/s-water-usage-detail", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "alliance-business-suite/utils/report-tables-util"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _reportTablesUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  let SummaryReportsSWaterUsageDetailComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, (_class = class SummaryReportsSWaterUsageDetailComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "brand", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor4, this);
    }

    get translatedColumns() {
      return [{
        name: 'location_machine',
        valuePath: 'name',
        width: 425,
        isFixed: 'left'
      }, {
        name: 'date_range',
        valuePath: 'dateRange',
        width: 325
      }, {
        name: 'models.machine-program.cycle_1',
        valuePath: 'normalHotCount',
        width: 180
      }, {
        name: 'models.machine-program.cycle_2',
        valuePath: 'normalWarmCount',
        width: 180
      }, {
        name: 'models.machine-program.cycle_3',
        valuePath: 'normalColdCount',
        width: 180
      }, {
        name: 'models.machine-program.cycle_4',
        valuePath: 'permanentPressHotCount',
        width: 180
      }, {
        name: 'models.machine-program.cycle_5',
        valuePath: 'permanentPressWarmCount',
        width: 180
      }, {
        name: 'models.machine-program.cycle_6',
        valuePath: 'permanentPressColdCount',
        width: 180
      }, {
        name: 'models.machine-program.cycle_7',
        valuePath: 'delicateHotCount',
        width: 180
      }, {
        name: 'models.machine-program.cycle_8',
        valuePath: 'delicateWarmCount',
        width: 180
      }, {
        name: 'models.machine-program.cycle_9',
        valuePath: 'delicateColdCount',
        width: 180
      }, {
        name: 'models.machine-program.cycle_10',
        valuePath: 'cycleCount10',
        width: 180
      }, {
        name: 'models.machine-program.cycle_11',
        valuePath: 'cycleCount11',
        width: 180
      }, {
        name: 'models.machine-program.cycle_12',
        valuePath: 'cycleCount12',
        width: 180
      }, {
        name: 'models.machine-program.cycle_13',
        valuePath: 'cycleCount13',
        width: 180
      }, {
        name: 'models.machine-program.cycle_14',
        valuePath: 'cycleCount14',
        width: 180
      }, {
        name: 'models.machine-program.cycle_15',
        valuePath: 'cycleCount15',
        width: 180
      }];
    }

    get rowsWithChildren() {
      let geoBoundaries = this.args.model.get('geoBoundaries');
      let rowData = [];
      geoBoundaries.forEach(geoBoundary => {
        let rowArray = [];
        let nameArray = [];
        rowData.push({
          name: _reportTablesUtil.localizeString.call(this, geoBoundary.name, 'Location', 'location'),
          children: nameArray
        });
        geoBoundary.rooms.forEach(room => {
          nameArray.push({
            name: room.name,
            children: rowArray
          });
          room.machineAuditGroupTypes.forEach(machineAuditType => {
            machineAuditType.machineTypes.forEach(machineType => {
              let machineArray = [];
              let machineTypeName = `${machineAuditType.name} - ${machineType.name}`;
              rowArray.push({
                name: machineTypeName,
                machineTypeName: machineType.name,
                children: machineArray
              });
              machineType.machines.forEach(machine => {
                let machineName = _reportTablesUtil.machineNameDisplay.call(this, machine.machineName, machine.machineNumber);

                let normalHot = machine.normalHotCount || 0;
                let normalWarm = machine.normalWarmCount || 0;
                let normalCold = machine.normalColdCount || 0;
                let permanentPressHot = machine.permanentPressHotCount || 0;
                let permanentPressWarm = machine.permanentPressWarmCount || 0;
                let permanentPressCold = machine.permanentPressColdCount || 0;
                let delicateHot = machine.delicateHotCount || 0;
                let delicateWarm = machine.delicateWarmCount || 0;
                let delicateCold = machine.delicateColdCount || 0;
                let cycle10 = machine.cycleCount10 || 0;
                let cycle11 = machine.cycleCount11 || 0;
                let cycle12 = machine.cycleCount12 || 0;
                let cycle13 = machine.cycleCount13 || 0;
                let cycle14 = machine.cycleCount14 || 0;
                let cycle15 = machine.cycleCount15 || 0;
                let dateRange = "";

                if (this.brand.slug == 'primus') {
                  // fix the branding issue alliance-tasks/issues/2291
                  rowArray[rowArray.length - 1].name = `${machine.machineAuditType.name} - ${rowArray[rowArray.length - 1].machineTypeName}`;
                }

                if (machine.minFirstReceivedAt && machine.maxFirstReceivedAt) {
                  dateRange = `${moment(machine.minFirstReceivedAt).format(`${_reportTablesUtil.formatDateTimeString.call(this)}`)} - ${moment(machine.maxFirstReceivedAt).format(`${_reportTablesUtil.formatDateTimeString.call(this)}`)}`;
                }

                machineArray.push({
                  name: machineName,
                  dateRange: dateRange,
                  normalHotCount: normalHot,
                  normalWarmCount: normalWarm,
                  normalColdCount: normalCold,
                  permanentPressHotCount: permanentPressHot,
                  permanentPressWarmCount: permanentPressWarm,
                  permanentPressColdCount: permanentPressCold,
                  delicateHotCount: delicateHot,
                  delicateWarmCount: delicateWarm,
                  delicateColdCount: delicateCold,
                  cycleCount10: cycle10,
                  cycleCount11: cycle11,
                  cycleCount12: cycle12,
                  cycleCount13: cycle13,
                  cycleCount14: cycle14,
                  cycleCount15: cycle15
                });
              });
            });
          });
        });
      });
      return rowData;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "brand", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SummaryReportsSWaterUsageDetailComponent;
});