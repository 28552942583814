define("alliance-business-suite/components/employees/schedule-item", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  let EmployeesScheduleItemComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, (_class = class EmployeesScheduleItemComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor2, this);
      (0, _defineProperty2.default)(this, "restrictionTimeBuffer", 30);
      (0, _initializerDefineProperty2.default)(this, "startTime", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "endTime", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "ratePerHour", _descriptor5, this);
    }

    // computed('selectedDate'
    get dateConfig() {
      let selectedDate = moment(this.args.selectedDate, 'M/D/YYYY');
      let weekdayNum = selectedDate.weekday();
      let type = weekdayNum == 6 || weekdayNum == 0 ? 'weekend' : 'weekday';
      return {
        weekday: selectedDate.format('dddd'),
        month: selectedDate.format('MMM'),
        monthNumber: selectedDate.format('MM'),
        day: selectedDate.format('D'),
        dayMoment: selectedDate.format('DD'),
        year: selectedDate.format('YYYY'),
        type,
        weekdayNum
      };
    } // @computed('this.args.items.[]')


    get filteredItems() {
      // This can probably be improved
      let result = null;

      if (this.args.items) {
        const selectedDate = moment(this.args.selectedDate, 'M/D/YYYY').format('M/D/YYYY');
        result = this.args.items.filter(item => {
          const startDate = moment.utc(item.localStartDatetime).format('M/D/YYYY');
          return startDate == selectedDate;
        });

        if (!this.args.hasDirtyItems) {
          result = result.sortBy('startTime');
        }
      }

      return result;
    }

    updateRatePerHour(item, val) {
      item.ratePerHour = val;
    } // TODO: Right now  we bump up against the opposite time. We can also slide the opposite time...perhaps better with the slider than with textbox entries.
    // @action
    // restrictTimes(position, context, e) {
    //   let start = get(context, 'startTime');
    //   let end = get(context, 'endTime');
    //   let buffer = get(this, 'restrictionTimeBuffer');
    //
    //   this.updateCreateTimestamp() // update dirty timestamp to bubble up
    //
    //   if (moment(start, 'HH:mm').isSameOrAfter(moment(end, 'HH:mm'))) {
    //     if (position == 'start') { // Flipping this to 'end' will slide the opposite time instead of bumping up against it.
    //       set(context, 'startTime', moment(end, 'HH:mm').subtract(buffer, 'm').format('HH:mm'));
    //     } else {
    //       set(context, 'endTime', moment(start, 'HH:mm').add(buffer, 'm').format('HH:mm'));
    //     }
    //   }
    // }


    changeEmployee(item, employee) {
      this.args.updateCreateTimestamp();
      item.employee = employee;
    }

    useShiftBlock(item) {
      this.startTime = item.startTime;
      this.endTime = item.endTime;
    }

    updateItem(item, key, val) {
      item[key] = val;
      this.args.updateCreateTimestamp();
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "startTime", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '09:00';
    }
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "endTime", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '17:00';
    }
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "ratePerHour", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 700;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "changeEmployee", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "changeEmployee"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "useShiftBlock", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "useShiftBlock"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "updateItem", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "updateItem"), _class.prototype)), _class));
  _exports.default = EmployeesScheduleItemComponent;
});