define("alliance-business-suite/helpers/cycles-step-mod-indicator", ["exports", "alliance-business-suite/utils/cycles-values"], function (_exports, _cyclesValues) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),

    compute(_ref, args) {
      let [value, array, ...rest] = _ref;
      let intl = this.intl;

      switch (value) {
        case _cyclesValues.ACTIVE_STATES.enabled:
          return `<span class="step-icon step-enabled" title="${intl.t("tooltip_Enabled")}">&bull;</span>`;

        case _cyclesValues.ACTIVE_STATES.mod1:
          return `<span class="step-icon step-mod step-mod1" title="${intl.t("tooltip_ActiveMod1")}">&bull;</span>`;

        case _cyclesValues.ACTIVE_STATES.mod2:
          return `<span class="step-icon step-mod step-mod2" title="${intl.t("tooltip_ActiveMod2")}">&bull;&bull;</span>`;

        case _cyclesValues.ACTIVE_STATES.mod3:
          return `<span class="step-icon step-mod step-mod3" title="${intl.t("tooltip_ActiveMod3")}">&bull;&bull;&bull;</span>`;

        case _cyclesValues.ACTIVE_STATES.disabled:
          return `<span class="step-icon step-disabled" title="${intl.t("tooltip_Disabled")}">&bull;</span>`;
      }
    }

  });

  _exports.default = _default;
});