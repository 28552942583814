define("alliance-business-suite/mixins/form-ui", ["exports", "alliance-business-suite/utils/is-number", "alliance-business-suite/utils/machine-programming-property-names", "alliance-business-suite/utils/show-machine", "alliance-business-suite/utils/show-features"], function (_exports, _isNumber, _machineProgrammingPropertyNames, _showMachine, _showFeatures) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // This mixin is used by the form-ui contexual components
  // TODO: Not all this code is being used. It needs some cleaning up!
  function cleanPropertyName(value) {
    // Remove any trailing _TUM or _WX from property names.
    if (value) {
      value = value.replace("_WX", "");
      value = value.replace("_TUM", "");
    }

    return value;
  }

  var _default = Ember.Mixin.create({
    // eslint-disable-line
    //#region Variables
    intl: Ember.inject.service(),
    access: Ember.inject.service(),
    devtools: Ember.inject.service(),
    classNameBindings: ['params.isInvalid', 'display', 'params.labelAfter:has-tooltip', 'isUndefinedProperty:undefined_property', 'form-ui-element'],
    showTooltip: false,
    advanced: true,
    zebra: true,
    autoTranslateLabel: false,
    showMinMax: Ember.computed('params.{min,max}', function () {
      return false; // This feature isn't ready for production yet.

      if (this.params.showMinMax === false) {
        return false;
      }

      if (this.params?.min !== undefined && this.params?.max !== undefined) {
        return true;
      } else {
        return false;
      }
    }),
    minValue: Ember.computed('params.min', function () {
      return this.params.min;
    }),
    maxValue: Ember.computed('params.max', function () {
      return this.params.max;
    }),
    propertyName: Ember.computed('object', 'index', 'params.{index,propertyName}', function () {
      // We can get 1) a string of the property name, 2) an array of property name strings, 3) the bound property.
      let propertyName = this.params.propertyName;
      let index = this.params.index || this.index;

      if (Ember.typeOf(propertyName) === 'array') {
        propertyName = (0, _machineProgrammingPropertyNames.findValidPropertyName)(this.object, propertyName, index);
      } else if (Ember.typeOf(propertyName) === 'string') {
        propertyName = (0, _machineProgrammingPropertyNames.replaceIndexPlaceholder)(propertyName, index);
      }

      return propertyName;
    }),
    displayFor: Ember.computed.alias('params.displayFor'),
    enableWith: Ember.computed.alias('params.enableWith'),
    showWith: Ember.computed.alias('params.showWith'),
    step: Ember.computed.alias('params.step'),
    labelAfter: Ember.computed.alias('params.labelAfter'),
    // label displayed after inputs
    placeholder: Ember.computed.alias('params.placeholder'),
    // placerholder text, optional if defined in machineSettings
    ifMachine: Ember.computed.alias('params.ifMachine'),
    notMachine: Ember.computed.alias('params.notMachine'),

    //#endregion
    //#region Event hooks
    initPropertyName(propertyName) {
      if (!this.showComponent) {
        console.log('THIS MACHINE IS NOT ACTIVE FOR THIS COMPONENT');
        return false;
      } // TODO: This whole thing needs to be DRY and moved somewhere else.


      let index = this.params.index;

      if (Ember.isEmpty(index)) {
        index = this.index;
      }

      if (!Ember.isNone(index)) {
        propertyName = propertyName.replace('${index}', index); // Replace the index placeholder if this is an array

        propertyName = propertyName.replace('${index+1}', index + 1); // Replace the index placeholder if this is an array
      }

      return propertyName;
    },

    didReceiveAttrs() {
      // was init
      this._super(...arguments);

      let showComponent = true;
      showComponent = (0, _showFeatures.showMachineFeature)({
        showComponent: showComponent,
        machineProgram: this.machineProgram,
        ifMachine: this.ifMachine,
        notMachine: this.notMachine
      });
      showComponent = (0, _showFeatures.showStepFeature)({
        showComponent: showComponent,
        stepType: this.formMeta?.stepTypeName,
        ifStep: this.params?.ifStep,
        notStep: this.params?.notStep
      });
      Ember.set(this, 'showComponent', showComponent); // If component should be hidden, remove any classNames so it will collapse cleanly in the DOM

      if (this.showComponent === false) {
        this.classNames = null;
        return; // Nothing else to do if this component doesn't get displayed
      }

      let propertyName = this.propertyName;
      let property = this.params.property; // Some property names are part of an array, and the index needs to be updated...like SpecialVend.Items.${index}

      let index = this.params.index;

      if (!Ember.isNone(index)) {
        propertyName = propertyName.replace('${index}', index); // Replace the index placeholder if this is an array

        propertyName = propertyName.replace('${index+1}', index + 1); // Replace the index placeholder if this is an array
      } // NEW! TODO: Test this stuff!
      // `propertyName` requires binding rawValue to the object.propertyName.
      // But if we get a `property` passed in (not a string) we need to bind to that CP/property.
      // A `property` will always override a `propertyName`


      if (!Ember.isNone(property)) {
        Ember.defineProperty(this, 'rawValue', Ember.computed.reads('params.property'));
      } else {
        Ember.defineProperty(this, 'rawValue', Ember.computed.reads(`object.${propertyName}`));
      } // If this property displays preset values, add an observer to watch for updates.
      // This should not happen if the (custom) select and number are bound to the same property!


      if (this.params.preset && !this.params.sharedCustomProperty) {
        Ember.addObserver(this, 'params.preset', this, 'observePresets');
      } // Bind to properties if enabled/disabled are strings


      let {
        enabled,
        disabled
      } = this.params;

      if (enabled && typeof enabled === 'string') {
        Ember.defineProperty(this, 'enabled', Ember.computed.reads(this.initPropertyName(`object.${enabled}`)));
      } else {
        Ember.defineProperty(this, 'enabled', Ember.computed.reads('params.enabled'));
      }

      if (disabled && typeof disabled === 'string') {
        Ember.defineProperty(this, 'disabled', Ember.computed.reads(this.initPropertyName(`object.${disabled}`)));
      } else {
        Ember.defineProperty(this, 'disabled', Ember.computed.reads('params.disabled'));
      }
    },

    didInsertElement() {
      this._super(...arguments); // Change formSetting.display, if we have an override coming in


      let displaySetting = this.params.formDisplay; // TODO: This causes problems...overrides all other comps on the page
      // if (displaySetting) {
      //   set(this, 'formSettings.formDisplay', displaySetting);
      // }

      if (this.displayValue === 'field-only') {
        Ember.set(this, 'classNames', null);
      }
    },

    willRender() {
      // TODO: merging this with didInsertElement broke display. Figure out how to fix it.
      this._super(...arguments);

      if (this.params.classNames === null) {
        Ember.set(this, 'classNames', null);
      }
    },

    willDestroyElement() {
      if (this.params.preset) {
        Ember.removeObserver(this, 'params.preset', this, 'observePresets');
      }
    },

    //#endregion
    //#region Computed properties
    // Display can be modified by setting display to 'compact'
    display: Ember.computed('formSettings.formDisplay', 'params.formDisplay', function () {
      let display = this.params.formDisplay || this.formSettings && this.formSettings.formDisplay || "compact";
      return display ? `display-${display}` : null;
    }),
    // Display can be modified by setting display to 'compact'
    displayValue: Ember.computed.or('params.formDisplay', 'formSettings.formDisplay'),
    presetValue: Ember.computed('params.{divideBy,preset.presetValue}', 'type', {
      // This gets displayed in the template as a disabled preset value.
      get() {
        let preset = this.params.preset;

        if (preset) {
          let value = Ember.get(preset, 'lastPresetValue') || preset.value;

          if (this.type === 'number' && (0, _isNumber.isNumber)(value)) {
            let divideBy = this.params.divideBy || 1;
            return value / divideBy;
          } else {
            return value;
          }
        }

        return null;
      },

      set(key, value) {
        // Cannot set values
        console.error('Number preset values cannot be set.'); // eslint-disable-line no-console

        return value;
      }

    }),
    showPreset: Ember.computed('params.preset.isCustom', function () {
      // Presets are displayed when Custom is not selected
      let hasPreset = this.params.preset || false;
      let isCustom = this.params.preset && this.params.preset.isCustom; // TODO: Does this work?

      return hasPreset && !isCustom;
    }),
    lastProperty: Ember.computed('propertyName', function () {
      let propertyName = this.propertyName;

      if (propertyName) {
        let parts = propertyName.split('.');
        return parts[parts.length - 1];
      }

      return propertyName;
    }),
    labelAutoTranslated: Ember.computed('params.label', 'propertyName', function () {
      let label = this.params.label;

      if (Ember.isNone(label)) {
        let intl = this.intl;
        let propertyName = this.propertyName;
        return intl.exists(propertyName);
      } else {
        return false;
      }
    }),
    // hasLabel: computed('params.label', function(){
    //   return (this.params.label === '');
    // }),
    hasLabel: Ember.computed.equal("params.label", ''),
    isCelsius: Ember.computed.alias('formSettings.isCelsius'),
    temperatureUnit: Ember.computed('isCelsius', function () {
      return this.isCelsius ? Ember.String.htmlSafe('&deg;C') : Ember.String.htmlSafe('&deg;F'); // TODO: Do we need intl here?
    }),
    label: Ember.computed('lastProperty', 'params.{label,labelKey,labelTranslated}', 'propertyName', function () {
      let label = this.params.label;
      let labelKey = this.params.labelKey;
      let intl = this.intl; // If we have a labelTranslated there's no need to do anything else. Just return that string

      if (!Ember.isNone(this.params.labelTranslated)) {
        return this.params.labelTranslated;
      } // If we have an empty or null label, skip everything


      if (label === '' || label === null) {
        return;
      } // All label values should now be keys.


      if (!Ember.isEmpty(label)) {
        // It's possible we receive an object with `key` and `placeholder` for dynamic label translations
        if (Ember.typeOf(label) === "object") {
          // TODO: Why did we start getting a SafeString here? Catch it here
          if (label && label.string) {
            return intl.t(label.string);
          } // Make sure the object has a key and placeholder, so we don't get errors


          if (label && label.key && label.placeholders) {
            return intl.t(label.key, label.placeholders);
          }
        }

        if (intl.exists(label)) {
          return intl.t(label);
        } else {
          console.warn('Translation key missing for label attribute:', label);
          this.devtools.addIntlKey(label);
          return label;
        }
      } // If we have a labelKey, we'll use that


      if (!Ember.isNone(labelKey)) {
        if (intl.exists(labelKey)) {
          return intl.t(labelKey);
        } else {
          console.warn('Translation key missing:', labelKey);
          this.devtools.addIntlKey(labelKey);
          return labelKey;
        }
      } // If we don't have a label or a labelKey, we'll try to match the propertyName


      if (Ember.isNone(label)) {
        // Auto translation - 1) Full propertyName with dot notation, 2) Last segment of propertyName
        let propertyName = this.propertyName;
        let lastPropertyName = this.lastProperty;

        if (intl.exists(propertyName)) {
          return intl.t(propertyName);
        } else if (intl.exists(lastPropertyName)) {
          return intl.t(lastPropertyName);
        } else if (intl.exists(cleanPropertyName(lastPropertyName))) {
          return intl.t(cleanPropertyName(lastPropertyName));
        } else {
          console.warn('Translation key missing:', lastPropertyName);
          return lastPropertyName;
        }
      }

      return null; // Fix 'always return value' lint message
    }),

    addPropertyPath(property) {
      // Concats any base propertyPath with the property, and replaces any index value along the way
      let propertyPath = this.params.propertyPath;

      if (!Ember.isEmpty(propertyPath)) {
        let index = this.params.index || "";
        let returnValue = `${propertyPath.replace('${index}', index)}.${property}`;
        return returnValue.replace('${index+1}', index + 1);
      } else {
        return property;
      }
    },

    value: Ember.computed('rawValue', 'preset', 'formSettings.[]', 'params.{decimals,divideBy,min,max}', {
      // We need to update the values if there is a change to divideBy decimal placement.
      get(key) {
        let rawValue = this.rawValue;
        let serializeValue = this.serializeValue(rawValue);
        return serializeValue;
      },

      set(key, value) {
        // Add deserialization here - convert value to what the API expects
        let object = this.object;
        let propertyName = this.propertyName;
        let deserializeValue = this.deserializeValue(value);
        this.update(object, propertyName, deserializeValue); // TODO: This doesn't work if we've changed the value because of min/max values

        return this.serializeValue(deserializeValue);
      }

    }),
    limit: Ember.computed('params.{min,max}', function () {
      return this.params.max != null && this.params.min != null;
    }),
    numberType: Ember.computed('params.numberType', function () {
      let value = this.params.numberType || '';
      return value.toUpperCase();
    }),
    type: Ember.computed('params.type', 'inputtype', function () {
      return this.params.type || this.inputtype || 'text';
    }),
    isInvalid: Ember.computed('params.isValidProperty', function () {
      return this.params.isValidProperty ? '' : 'ui__invalidPropertyBinding';
    }),
    show: Ember.computed('params.{advanced,displayFor,form}', function () {
      let displayList = this.params.displayFor;

      if (displayList) {
        // we have a list to display for
        let formName = this.params.form;

        if (!displayList.includes(formName)) {
          // array
          return false;
        }
      }

      if (this.params.advanced === false) {
        return false;
      } else {
        return true;
      }
    }),
    isDisabled: Ember.computed('params.preset', 'disabled', 'enabled', function () {
      // let { enabled, disabled, preset } = get(this, 'params');
      let {
        preset
      } = this.params;
      let disabled = this.disabled;
      let enabled = this.enabled; // Auto set if we're watching a preset and have no enabled or disabled

      if (preset && Ember.isNone(enabled) && Ember.isNone(disabled)) {
        return !preset.isCustom;
      }

      if (!Ember.isNone(enabled)) {
        return !enabled;
      }

      if (!Ember.isNone(disabled)) {
        return disabled;
      }

      return false;
    }),
    isUndefinedProperty: Ember.computed('properties', 'params.property', 'propertyName', function () {
      // Check for invalid properties. This will not flag `duration` components! TODO: Add duration component.
      // If we have a `properties` object then we need to ignore the `propertyName` because we're passing those
      // properties along to other components and those components will check `isUndefinedPropery` for themselves.
      if (this.properties !== undefined) {
        if (this.propertyName !== undefined && this.showComponent) {
          alert("FormUi received properties and propertyName. This shouldn't happen."); // TODO: Remove this line before going to production!

          console.error("Both `properties` and `propertyName` are defined in FormUi. This should not happen.");
        }

        return false;
      }

      if (this.showComponent && Ember.isNone(Ember.get(this, `object.${this.propertyName}`)) && Ember.isNone(this.params.property)) {
        console.error('Reference to property', this.propertyName, 'is undefined.'); // eslint-disable-line no-console

        return true;
      } else {
        return false;
      }
    }),
    isValidProperty: Ember.computed('property', 'propertyName', function () {
      return Ember.isNone(this.property) ? false : true;
    }),

    //#endregion
    //#region Methods
    serializeValue(rawValue) {
      // From API to the FE
      return rawValue; // Stub to override in component
    },

    deserializeValue(value) {
      // Going back to API from the FE
      return value; // Stub to override in component
    },

    observePresets() {
      let {
        presetValue,
        isCustom,
        lastPresetValue
      } = this.params.preset;
      let preset = this.params.preset;

      if (isCustom && lastPresetValue) {
        // We'll only set the value to the presetValue if the selected option isCustom.
        Ember.run.schedule('afterRender', () => {
          Ember.set(this, 'value', lastPresetValue);
        });
      }
    },

    updateProperty(value) {
      // Utility
      let propertyName = this.propertyName;
      let object = this.object;
      this.update(object, propertyName, value);
    },

    getPropertyValue() {
      // Utility
      let propertyName = this.propertyName;
      let object = this.object;
      return Ember.get(object, propertyName);
    },

    //#endregion
    //#region Actions
    actions: {
      focusIn() {
        Ember.set(this, 'showTooltip', true);
      },

      focusOut() {
        Ember.set(this, 'showTooltip', false);
      },

      processUpdate(object, propertyName, value) {
        let deserializeValue = this.deserializeValue(value);
        this.update(object, propertyName, deserializeValue);
      }

    } //#endregion

  });

  _exports.default = _default;
});