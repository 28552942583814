define("alliance-business-suite/components/summary/reports/s-maintenance-summary", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "alliance-business-suite/utils/report-tables-util"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _reportTablesUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Summary::Reports::DefaultReportShim
    @height={{@height}}
    @translatedColumns={{this.translatedColumns}}
    @rowsWithChildren={{this.rowsWithChildren}} />
  
  */
  {
    "id": "tM+h2GlR",
    "block": "{\"symbols\":[\"@height\"],\"statements\":[[8,\"summary/reports/default-report-shim\",[],[[\"@height\",\"@translatedColumns\",\"@rowsWithChildren\"],[[32,1],[32,0,[\"translatedColumns\"]],[32,0,[\"rowsWithChildren\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "alliance-business-suite/components/summary/reports/s-maintenance-summary.hbs"
  });

  let SummaryReportsSMaintenanceSummaryComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, (_class = class SummaryReportsSMaintenanceSummaryComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor3, this);
    }

    get translatedColumns() {
      return [{
        name: "",
        valuePath: "name",
        width: 200,
        isFixed: "left"
      }, // { name: 'models.revenue-details.machine-type', valuePath: 'machineType' },
      {
        name: "models.machine.machine-name",
        width: 180,
        valuePath: "machineName"
      }, {
        name: "description",
        width: 200,
        valuePath: "description"
      }, {
        name: "total_cycles",
        valuePath: "total_cycles"
      }, {
        name: "avg_cycles_per_day",
        valuePath: "avg_cycles_per_day"
      }, {
        name: "bearing_hours_since_greased",
        valuePath: "bearing_hours_since_greased"
      }, {
        name: "operator_stopped",
        valuePath: "operator_stopped"
      }, {
        name: "rapid_advance",
        valuePath: "rapid_advance"
      }, {
        name: "avg_fill_time",
        valuePath: "avg_fill_time",
        width: 110
      }, {
        name: "avg_drain_time",
        valuePath: "avg_drain_time"
      }, {
        name: "error_codes",
        valuePath: "error_codes",
        width: 350
      }, {
        name: "fatal_errors",
        valuePath: "fatal_errors"
      }, {
        name: "operation_hours",
        valuePath: "operation_hours"
      }];
    }

    get rowsWithChildren() {
      let rooms = this.args.model.get("rooms");
      let rowData = [];
      rooms.forEach(room => {
        let roomArray = [];
        let dryersChildren = [];
        let dryers = {
          name: this.intl.t("dryers"),
          children: dryersChildren
        };
        room.dryers.forEach(data => {
          let errorStr = "";

          if (data.errorCodes.length > 0) {
            data.errorCodes.forEach(e => {
              let str = "";

              for (let errorCodeName in e) {
                const formattedErrorCodeName = errorCodeName.replace("_", " ");
                str += `${formattedErrorCodeName}: ` + e[errorCodeName].count + ", ";
              }

              errorStr += `${str}`;
            });
          }

          dryersChildren.push({
            machineName: data.machineName,
            avg_cycles_per_day: data.avgCyclesPerDay,
            description: data.description,
            operation_hours: data.operationHours,
            operator_stopped: data.operatorStopped,
            rapid_advance: data.rapidAdvance,
            total_cycles: data.totalCycles,
            fatal_errors: data.fatalErrors,
            // error_codes: data.errorCodes,
            error_codes: errorStr,
            // these columns do not apply for dryers
            bearing_hours_since_greased: {
              cellMeta: {
                id: "no-data"
              }
            },
            avg_drain_time: {
              cellMeta: {
                id: "no-data"
              }
            },
            avg_fill_time: {
              cellMeta: {
                id: "no-data"
              }
            }
          });
        });

        if (dryersChildren.length > 0) {
          dryersChildren.push({
            fatal_errors: {
              cellValue: "Total Operational Hours",
              cellMeta: {
                type: "strong"
              }
            },
            operation_hours: {
              cellValue: parseFloat(room.totalOperationHoursDryers).toFixed(2),
              cellMeta: {
                type: "strong"
              }
            }
          });
        }

        let washersChildren = [];
        let washers = {
          name: this.intl.t("washers"),
          children: washersChildren
        };
        room.washers.forEach(data => {
          let errorStr = "";

          if (data.errorCodes.length > 0) {
            data.errorCodes.forEach(e => {
              let str = "";

              for (let errorCodeName in e) {
                const formattedErrorCodeName = errorCodeName.replace("_", " ");
                str += `${formattedErrorCodeName}: ` + e[errorCodeName].count + ", ";
              }

              errorStr += `${str}`;
            });
          } else {
            errorStr = "None";
          }

          washersChildren.push({
            machineName: data.machineName,
            avg_cycles_per_day: data.avgCyclesPerDay,
            avg_drain_time: data.avgDrainTime,
            avg_fill_time: data.avgFillTime,
            bearing_hours_since_greased: data.bearingHoursSinceGreased,
            description: data.description,
            operation_hours: data.operationHours,
            operator_stopped: data.operatorStopped,
            rapid_advance: data.rapidAdvance,
            total_cycles: data.totalCycles,
            fatal_errors: data.fatalErrors,
            // error_codes: data.errorCodes,
            error_codes: errorStr
          });
        });

        if (washersChildren.length > 0) {
          washersChildren.push({
            fatal_errors: {
              cellValue: "Total Operational Hours",
              cellMeta: {
                type: "strong"
              }
            },
            operation_hours: {
              cellValue: parseFloat(room.totalOperationHoursWashers).toFixed(2),
              cellMeta: {
                type: "strong"
              }
            }
          });
        }

        roomArray.push(dryers);
        roomArray.push(washers);
        rowData.push({
          name: _reportTablesUtil.localizeString.call(this, room.roomName, "Location", "location"),
          children: roomArray
        });
      });
      return rowData;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SummaryReportsSMaintenanceSummaryComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SummaryReportsSMaintenanceSummaryComponent);
});