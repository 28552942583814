define("alliance-business-suite/templates/components/form-ui/time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IK8orBW5",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,4],[[30,[36,7],[[35,6],\"compact\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"label\"],[15,0,[31,[\"form-field--label \",[30,[36,4],[[35,3],\"disabled\"],null],\" form-ui-element\"]]],[12],[2,\"\\n    \"],[10,\"span\"],[12],[1,[34,5]],[13],[2,\"\\n    \"],[10,\"span\"],[12],[2,\"\\n      \"],[8,\"time-field\",[],[[\"@on-change\",\"@value\",\"@hour12\"],[[30,[36,0],[[32,0],\"timeChanged\"],null],[34,1],[34,2,[\"hours12\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"text-field\"],[12],[2,\"\\n    \"],[10,\"label\"],[14,0,\"form-field--label form-ui-element\"],[12],[2,\"\\n      \"],[8,\"time-field\",[],[[\"@on-change\",\"@value\",\"@hour12\"],[[30,[36,0],[[32,0],\"timeChanged\"],null],[34,1],[34,2,[\"hours12\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[8,\"form-ui/dev-tools\",[],[[\"@outputs\"],[[30,[36,12],null,[[\"labelAutoTranslated\",\"propertyName\",\"hoursPropertyName\",\"minutesPropertyName\",\"hours\",\"minutes\",\"isDisabled\",\"hours12\",\"rawValue\",\"value\"],[[35,11],[35,2,[\"propertyName\"]],[35,2,[\"hoursPropertyName\"]],[35,2,[\"minutesPropertyName\"]],[35,10],[35,9],[35,3],[35,2,[\"hours12\"]],[35,8],[35,1]]]]]],null]],\"hasEval\":false,\"upvars\":[\"action\",\"value\",\"params\",\"isDisabled\",\"if\",\"label\",\"displayValue\",\"eq\",\"rawValue\",\"minutesValue\",\"hoursValue\",\"labelAutoTranslated\",\"hash\"]}",
    "moduleName": "alliance-business-suite/templates/components/form-ui/time.hbs"
  });

  _exports.default = _default;
});