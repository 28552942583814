define("alliance-business-suite/components/table/machines/table-cell-machine-name-pill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const TableMachinesTableCellMachineNamePill = Ember._templateOnlyComponent();

  var _default = TableMachinesTableCellMachineNamePill;
  _exports.default = _default;
});