define("alliance-business-suite/mixins/machines/programs/midas-opl-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    // Requires model, object (model.settings)
    agitateSpeeds: Ember.computed.alias('model.machineType.machineSettings.agitate.presets'),
    drainSpeeds: Ember.computed.alias('model.machineType.machineSettings.drain.presets'),
    extractSpeeds: Ember.computed.alias('model.machineType.machineSettings.extract.presets'),
    object: Ember.computed.alias('machineProgram'),

    // TODO: Possible code smell. This breaks for pricing tab, but works for all others.
    getSpeedPreset(name, index) {
      let array = Ember.get(this, name);

      if (array && array.length > index) {
        return array[index];
      } else {
        console.error("Preset value not found for %s, index %s.", name, index); // eslint-disable-line no-console

        return 0;
      }
    },

    selectOptions: Ember.computed('agitateSpeeds', 'drainSpeeds', 'extractSpeeds', 'formSettings.@each', 'machineProgram.settings.Program.Cycle.{CooldownWaterTemp_WX,ColdWaterTemp_WX,WarmWaterTemp_WX,HotWaterTemp_WX,LowWaterLevel_WX,MediumWaterLevel_WX,HighWaterLevel_WX}', function () {
      // These options are used for the "select" form controls. The values come from a collection of enumeration values in the Windows app DLL.
      // We're using a CP because some values will be calculated.
      // NOTE: Some `preset` values specify the unique property name used for a machine.
      // let object = get(this, 'machineProgram.settings');
      let object = Ember.get(this, 'object'); // let agitateSpeeds = get(this, 'agitateSpeeds');
      // let drainSpeeds = get(this, 'drainSpeeds');
      // let extractSpeeds = get(this, 'extractSpeeds');

      let optionOutput = {
        CellConfiguration: [{
          val: 0,
          label: "3_cell"
        }, {
          val: 1,
          label: "4_cell"
        }, {
          val: 2,
          label: "5_cell"
        }],
        Rotate: [{
          val: 30,
          label: "30"
        }, {
          val: 45,
          label: "45"
        }, {
          val: 60,
          label: "60"
        }, {
          val: 90,
          label: "90"
        }, {
          val: 120,
          label: "120"
        }, {
          val: 240,
          label: "240"
        }, {
          val: 540,
          label: "540"
        }],
        Pause: [{
          val: 6,
          label: "6"
        }, {
          val: 7,
          label: "7"
        }, {
          val: 8,
          label: "8"
        }, {
          val: 9,
          label: "9"
        }, {
          val: 10,
          label: "10"
        }],
        CooldownTemp: [{
          val: 70,
          F: 70,
          C: 21
        }, {
          val: 75,
          F: 75,
          C: 24
        }, {
          val: 80,
          F: 80,
          C: 27
        }, {
          val: 85,
          F: 85,
          C: 29
        }, {
          val: 90,
          F: 90,
          C: 32
        }, {
          val: 95,
          F: 95,
          C: 35
        }, {
          val: 100,
          F: 100,
          C: 38
        }, {
          val: 105,
          F: 105,
          C: 41
        }, {
          val: 110,
          F: 110,
          C: 43
        }],
        AvailableType: [// { label: "Available", val: 0 },
        {
          label: "AvailableWithStart",
          val: 1
        }, {
          label: "AvailableWithEOC",
          val: 2
        }, {
          label: "AvailableWithStartAndEOC",
          val: 3
        }, // { label: "VendNotSatisfied", val: 4 },
        // { label: "VendNotSatisfiedAndEOC", val: 5 },
        // { label: "AvaiableWithVend", val: 6 },
        // { label: "AvailableWithVendAndEOC", val: 7 },
        {
          label: "InUse",
          val: 8
        }, {
          label: "CycleComplete",
          val: 9
        }, // { label: "LuckyCycleTriggered", val: 10 },
        // { label: "BreakinTriggered", val: 11 },
        {
          label: "EndOfCycle",
          val: 12
        }, {
          label: "MotorOn",
          val: 13
        }],
        temperatureValuesHigh: [// 155 to 190
        {
          val: 155,
          F: 155,
          C: 68
        }, {
          val: 160,
          F: 160,
          C: 71
        }, {
          val: 165,
          F: 165,
          C: 74
        }, {
          val: 170,
          F: 170,
          C: 77
        }, {
          val: 175,
          F: 175,
          C: 79
        }, {
          val: 180,
          F: 180,
          C: 82
        }, {
          val: 185,
          F: 185,
          C: 85
        }, {
          val: 190,
          F: 190,
          C: 88
        }],
        temperatureValuesMedium: [{
          val: 135,
          F: 135,
          C: 57
        }, {
          val: 140,
          F: 140,
          C: 60
        }, {
          val: 145,
          F: 145,
          C: 63
        }, {
          val: 150,
          F: 150,
          C: 66
        }, {
          val: 155,
          F: 155,
          C: 68
        }, {
          val: 160,
          F: 160,
          C: 71
        }, {
          val: 165,
          F: 165,
          C: 74
        }],
        temperatureValuesLow: [{
          val: 105,
          F: 105,
          C: 41
        }, {
          val: 110,
          F: 110,
          C: 43
        }, {
          val: 115,
          F: 115,
          C: 46
        }, {
          val: 120,
          F: 120,
          C: 48
        }, {
          val: 125,
          F: 125,
          C: 52
        }, {
          val: 130,
          F: 130,
          C: 54
        }, {
          val: 135,
          F: 135,
          C: 57
        }, {
          val: 140,
          F: 140,
          C: 60
        }, {
          val: 145,
          F: 145,
          C: 63
        }, {
          val: 150,
          F: 150,
          C: 66
        }, {
          val: 155,
          F: 155,
          C: 68
        }],
        temperatureValuesVeryLow: [{
          val: 100,
          F: 100,
          C: 38
        }, {
          val: 105,
          F: 105,
          C: 41
        }, {
          val: 110,
          F: 110,
          C: 43
        }, {
          val: 115,
          F: 115,
          C: 46
        }, {
          val: 120,
          F: 120,
          C: 49
        }],
        temperatureUnits: [{
          val: true,
          label: 'Celsius'
        }, {
          val: false,
          label: 'Fahrenheit'
        }],
        CooldownTemperature: [{
          val: 0,
          label: "Global",
          presetValue: this.machineProgram.settings.Program.Cycle.CooldownWaterTemp_WX
        }, {
          label: "Custom",
          isCustom: true,
          val: this.machineProgram.settings.Program.Cycle.CooldownWaterTemp_WX
        }],
        CooldownRate: [{
          val: 0,
          label: "NoRateRestriction",
          presetValue: 5
        }, {
          label: "DecrementByTime",
          isCustom: true,
          val: 5
        }],
        CycleNames: [{
          val: 1,
          label: "MediumHeavy"
        }, {
          val: 0,
          label: "DeluxeUltra"
        }, {
          val: 2,
          label: "Custom"
        }],
        CycleBundle: [{
          val: 0,
          label: "None",
          key: "NoneLightRegular"
        }, {
          val: 1,
          label: "Medium",
          key: "OneMediumDeluxe"
        }, {
          val: 2,
          label: "Heavy",
          key: "TwoHeavyUltra"
        }],
        CyclesWx: [{
          val: 1,
          label: "Normal",
          index: 0
        }, {
          val: 2,
          label: "Synthetics",
          index: 1
        }, {
          val: 3,
          label: "Delicate/Bulky",
          index: 2
        }, {
          val: 4,
          label: "Colors",
          index: 3
        }, {
          val: 5,
          label: "Whites",
          index: 4
        }, {
          val: 6,
          label: "Mix",
          index: 5
        }, {
          val: 7,
          label: "Woolens",
          index: 6
        }, {
          val: 8,
          label: "Delicate",
          index: 7
        }, {
          val: 9,
          label: "Bedding",
          index: 8
        }, {
          val: 10,
          label: "Hot Wash",
          index: 9
        }, {
          val: 11,
          label: "Warm Wash",
          index: 10
        }, {
          val: 12,
          label: "Cotton",
          index: 11
        }, {
          val: 13,
          label: "High Speed Spin",
          index: 12
        }, {
          val: 14,
          label: "Pre-Clean",
          index: 13
        }, {
          val: 15,
          label: "Custom",
          index: 14
        }],
        Months: [{
          label: "January",
          val: 1
        }, {
          label: "February",
          val: 2
        }, {
          label: "March",
          val: 3
        }, {
          label: "April",
          val: 4
        }, {
          label: "May",
          val: 5
        }, {
          label: "June",
          val: 6
        }, {
          label: "July",
          val: 7
        }, {
          label: "August",
          val: 8
        }, {
          label: "September",
          val: 9
        }, {
          label: "October",
          val: 10
        }, {
          label: "November",
          val: 11
        }, {
          label: "December",
          val: 12
        }],
        Weeks: [{
          label: "Week1",
          val: 1
        }, {
          label: "Week2",
          val: 2
        }, {
          label: "Week3",
          val: 3
        }, {
          label: "LastWeek",
          val: 4
        } // { label: "Week5", val: 5 } per docs, only 1-4 are valid values.
        ],
        Weekdays: [{
          label: "Sunday",
          val: 1
        }, {
          label: "Monday",
          val: 2
        }, {
          label: "Tueday",
          val: 3
        }, {
          label: "Wednesday",
          val: 4
        }, {
          label: "Thursday",
          val: 5
        }, {
          label: "Friday",
          val: 6
        }, {
          label: "Saturday",
          val: 7
        }],
        DropType: [{
          label: "Token",
          val: -1
        }, {
          label: "Blank",
          val: 0
        }, {
          label: "Custom",
          val: 1,
          isCustom: true
        }],
        DropTypeToken: [{
          label: "Token",
          val: -1
        }],
        HeaterOptionsTemperature: [{
          label: "Cold",
          val: 0,
          presetValue: this.machineProgram.settings.Program.Cycle.ColdWaterTemp_WX
        }, {
          label: "Warm",
          val: 1,
          presetValue: this.machineProgram.settings.Program.Cycle.WarmWaterTemp_WX
        }, {
          label: "Hot",
          val: 2,
          presetValue: this.machineProgram.settings.Program.Cycle.HotWaterTemp_WX
        }, {
          label: "Custom",
          isCustom: true
        }],
        WaterValves: [{
          label: "Cold",
          val: 0,
          hotFill: false,
          coldFill: true
        }, {
          label: "Warm",
          val: 1,
          hotFill: true,
          coldFill: true
        }, {
          label: "Hot",
          val: 2,
          hotFill: true,
          coldFill: false
        }],
        WaterValvesControlled: [{
          label: "Cold",
          val: 0,
          hotFill: false,
          coldFill: true,
          controlledFill: false
        }, {
          label: "Warm",
          val: 1,
          hotFill: true,
          coldFill: true,
          controlledFill: false
        }, {
          label: "Hot",
          val: 2,
          hotFill: true,
          coldFill: false,
          controlledFill: false
        }, {
          label: "ControlledFill",
          val: 3,
          hotFill: false,
          coldFill: false,
          controlledFill: true
        }],
        // TODO: Check this one.
        SymbolPlacement: [{
          label: "Left",
          val: false
        }, {
          label: "Right",
          val: true
        }],
        StartPulseMode: [// Taken from MGD programming. Others use 0, 1, 2 or 1, 2, 3...so be careful!
        // `key` is used in coin-start-pulses component
        {
          val: 0,
          label: 'Off',
          key: 'off'
        }, {
          val: 128,
          label: 'SinglePulse',
          key: 'single'
        }, {
          val: 192,
          label: 'MultiplePulses',
          key: 'multiple'
        }],
        // temperatureValues: [
        //   { val: 100, F: 100, C: 37 },
        //   { val: 105, F: 105, C: 40 },
        //   { val: 110, F: 110, C: 43 },
        //   { val: 115, F: 115, C: 46 },
        //   { val: 120, F: 120, C: 48 },
        //   { val: 125, F: 125, C: 51 },
        //   { val: 130, F: 130, C: 54 },
        //   { val: 135, F: 135, C: 57 },
        //   { val: 140, F: 140, C: 60 },
        //   { val: 145, F: 145, C: 62 },
        //   { val: 150, F: 150, C: 65 },
        //   { val: 155, F: 155, C: 68 },
        //   { val: 160, F: 160, C: 71 },
        //   { val: 165, F: 165, C: 73 },
        //   { val: 170, F: 170, C: 76 },
        //   { val: 175, F: 175, C: 79 },
        //   { val: 180, F: 180, C: 82 },
        //   { val: 185, F: 185, C: 85 },
        //   { val: 190, F: 190, C: 87 },
        //   { val: 195, F: 195, C: 90 }
        // ],
        DefaultCycles: [{
          val: 1,
          label: "High"
        }, {
          val: 2,
          label: "Low"
        }, {
          val: 3,
          label: "NoHeat"
        }, {
          val: 4,
          label: "Medium"
        }, {
          val: 5,
          label: "Delicate"
        }],
        ExternalDispenserPause: [{
          val: 0,
          label: "Disabled"
        }, {
          val: 1,
          label: "External Dispenser Pause"
        }, {
          val: 2,
          label: "Empty Dispenser Bin"
        }],
        DefaultModifiers: [{
          val: 1,
          label: "None",
          Reversing: false,
          AntiWrinkle: false
        }, {
          val: 2,
          label: "AntiWrinkle",
          Reversing: false,
          AntiWrinkle: true
        }, {
          val: 3,
          label: "Reversing",
          Reversing: true,
          AntiWrinkle: false
        }, {
          val: 4,
          label: "AntiWrinkleReversing",
          Reversing: true,
          AntiWrinkle: true
        }],
        DefaultBundles: [{
          val: 1,
          label: "None",
          medium: false,
          heavy: false
        }, {
          val: 2,
          label: "Medium",
          medium: true,
          heavy: false
        }, {
          val: 3,
          label: "Heavy",
          medium: false,
          heavy: true
        }],
        WaterLevel: [{
          label: "GlobalHigh",
          val: 126,
          presetValue: this.machineProgram.settings.Program.Cycle.HighWaterLevel_WX
        }, {
          label: "GlobalMedium",
          val: 125,
          presetValue: this.machineProgram.settings.Program.Cycle.MediumWaterLevel_WX
        }, {
          label: "GlobalLow",
          val: 124,
          presetValue: this.machineProgram.settings.Program.Cycle.LowWaterLevel_WX
        }, {
          label: "Custom",
          isCustom: true
        }],
        TemperatureOverrides: [{
          label: "Cold",
          val: 0,
          presetValue: this.machineProgram.settings.Program.Cycle.ColdWaterTemp_WX
        }, {
          label: "Warm",
          val: 1,
          presetValue: this.machineProgram.settings.Program.Cycle.WarmWaterTemp_WX
        }, {
          label: "Hot",
          val: 2,
          presetValue: this.machineProgram.settings.Program.Cycle.HotWaterTemp_WX
        }, {
          label: "Custom",
          isCustom: true,
          val: this.machineProgram.settings.Program.Cycle.ColdWaterTemp
        } // Custom is stored as F
        ],
        // These were from Alan
        TopoffEnable: [{
          label: "Disabled",
          val: 0
        }, {
          label: "Enabled",
          val: 1
        }, {
          label: "FullCycle",
          val: 2
        }],
        SupplyCondition: [{
          label: "TimeExpires",
          val: 0,
          text: "On until supply time expires"
        }, {
          label: "WaterLevelReached",
          val: 1,
          text: "On until water level reached"
        } // { label: "SmartWAVe", val: 2, text: "Shutoff early from SmartWAVe" },
        ],
        StepType: [{
          label: "Empty",
          val: 0,
          active: false
        }, {
          label: "ReuseFill",
          val: 1,
          active: true
        }, {
          label: "Fill",
          val: 2,
          active: true
        }, {
          label: "Supply",
          val: 3,
          active: true
        }, {
          label: "Agitate",
          val: 4,
          active: true
        }, {
          label: "Soak",
          val: 5,
          active: true
        }, {
          label: "CoolDown",
          val: 6,
          active: true
        }, {
          label: "Drain",
          val: 7,
          active: true
        }, {
          label: "DrainExtract",
          val: 9,
          active: true
        }, {
          label: "AudioSignal",
          val: 10,
          active: true
        }, {
          label: "Hold",
          val: 11,
          active: true
        }],
        StepLabel: [{
          label: "Prewash1",
          val: 0,
          group: "Prewash"
        }, {
          label: "Wash1",
          val: 1,
          group: "Wash"
        }, {
          label: "Rinse1",
          val: 2,
          group: "Rinse"
        }, {
          label: "Rinse2",
          val: 3,
          group: "Rinse"
        }, {
          label: "Rinse3",
          val: 4,
          group: "Rinse"
        }, {
          label: "Rinse4",
          val: 5,
          group: "Rinse"
        }, {
          label: "Spin",
          val: 6,
          group: "Spin"
        }, {
          label: "Bleach",
          val: 7,
          group: "Bleach"
        }, {
          label: "Prewash2",
          val: 8,
          group: "Prewash"
        }, {
          label: "Prewash3",
          val: 9,
          group: "Prewash"
        }, {
          label: "Wash2",
          val: 10,
          group: "Wash"
        }, {
          label: "Wash3",
          val: 11,
          group: "Wash"
        }, {
          label: "Wash4",
          val: 12,
          group: "Wash"
        }],
        PocketIndicator: [{
          label: "Single",
          val: 0
        }, {
          label: "Upper",
          val: 1
        }, {
          label: "Lower",
          val: 2
        }],
        OptisprayTimeOption: [{
          label: "CustomTime",
          val: 0
        }, {
          label: "Towels",
          val: 1
        }, {
          label: "Sheets",
          val: 2
        }, {
          label: "General",
          val: 3
        }],
        NumericSeparator: [{
          label: "None",
          val: 0,
          decimalSymbol: '',
          decimalPlaces: 0,
          divideBy: 1
        }, {
          label: "Dot1",
          val: 1,
          decimalSymbol: '.',
          decimalPlaces: 1,
          divideBy: 1
        }, {
          label: "Dot2",
          val: 2,
          decimalSymbol: '.',
          decimalPlaces: 2,
          divideBy: 1
        }, {
          label: "Dot3",
          val: 3,
          decimalSymbol: '.',
          decimalPlaces: 3,
          divideBy: 1
        }, {
          label: "Comma1",
          val: 4,
          decimalSymbol: ',',
          decimalPlaces: 1,
          divideBy: 1
        }, {
          label: "Comma2",
          val: 5,
          decimalSymbol: ',',
          decimalPlaces: 2,
          divideBy: 1
        }, {
          label: "Comma3",
          val: 6,
          decimalSymbol: ',',
          decimalPlaces: 3,
          divideBy: 1
        }],
        ManualAccessType: [{
          label: "Disabled",
          val: 0,
          text: "Access is not allowed"
        }, {
          label: "Enabled",
          val: 1,
          text: "Access is not limited"
        }, {
          label: "Passcode",
          val: 2,
          text: "Access is passcode only"
        }],
        Language: [{
          label: "English",
          val: 1
        }, {
          label: "SpanishEuropean",
          val: 2
        }, {
          label: "SpanishColumbian",
          val: 3
        }, {
          label: "FrenchEuropean",
          val: 4
        }, {
          label: "ChineseSimplified",
          val: 5
        }, {
          label: "ChineseTraditional",
          val: 6
        }, {
          label: "Russian",
          val: 7
        }, {
          label: "German",
          val: 8
        }, {
          label: "JapaneseStandard",
          val: 9
        }, {
          label: "Italian",
          val: 10
        }, {
          label: "PortugueseEuropean",
          val: 11
        }, {
          label: "PortugueseBrazilian",
          val: 12
        }, {
          label: "Korean",
          val: 13
        }, {
          label: "Dutch",
          val: 14
        }, {
          label: "Czech",
          val: 15
        }, {
          label: "Finnish",
          val: 16
        }, {
          label: "Danish",
          val: 17
        }, {
          label: "Swedish",
          val: 18
        }, {
          label: "Norwegian",
          val: 19
        }, {
          label: "Polish",
          val: 20
        }, {
          label: "Slovene",
          val: 21
        }, {
          label: "Romanian",
          val: 22
        }, {
          label: "Croatian",
          val: 23
        }, {
          label: "Hungarian",
          val: 24
        }, {
          label: "Latvian",
          val: 25
        }, {
          label: "Lithuanian",
          val: 26
        }, {
          label: "Slovak",
          val: 27
        }, {
          label: "Greek",
          val: 28
        }, {
          label: "Bulgarian",
          val: 29
        }, {
          label: "Turkish",
          val: 30
        }, {
          label: "Indonesian",
          val: 31
        }, {
          label: "Malay",
          val: 32
        }, {
          label: "Thai",
          val: 33
        }, {
          label: "Vietnamese",
          val: 34
        }],
        HoldMessage: [{
          label: "Load",
          val: 0
        }, {
          label: "Chemical",
          val: 0
        }, {
          label: "Soap",
          val: 0
        }, {
          label: "Bleach",
          val: 0
        }, {
          label: "Softener",
          val: 0
        }, {
          label: "Sour",
          val: 0
        }, {
          label: "Starch",
          val: 0
        }, {
          label: "Custom1",
          val: 0
        }, {
          label: "Custom2",
          val: 0
        }],
        HeatOption: [{
          label: "NoRateRestriction",
          val: 0
        }, {
          label: "Target",
          val: 1,
          text: "Heat to target"
        }, {
          label: "Maintain",
          val: 2,
          text: "Heat to"
        }],
        HeatRateOption: [{
          label: "no_heat",
          val: 0,
          presetValue: 5
        }, {
          label: "increment_by_time",
          isCustom: true
        }],
        DrainSelection: [{
          label: "Drain1",
          val: 1
        }, {
          label: "Drain2",
          val: 2
        }, {
          label: "Drain1And2",
          val: 3
        }],
        DateSeperator: [{
          label: "Slash",
          val: 0
        }, {
          label: "Period",
          val: 1
        }, {
          label: "Dash",
          val: 2
        }],
        DateOrder: [{
          label: "Month_Day_Year",
          val: 0
        }, {
          label: "Day_Month_Year",
          val: 1
        }, {
          label: "Year_Month_Day",
          val: 2
        }],
        CycleTempStatus: [{
          label: "Invalid",
          val: 0
        }, {
          label: "Cold",
          val: 1
        }, {
          label: "Warm",
          val: 2
        }, {
          label: "Hot",
          val: 3
        }, {
          label: "NoHeat",
          val: 4
        }, {
          label: "_15C",
          val: 5
        }, {
          label: "_30C",
          val: 6
        }, {
          label: "_40C",
          val: 7
        }, {
          label: "_50C",
          val: 8
        }, {
          label: "_60C",
          val: 9
        }, {
          label: "_90C",
          val: 10
        }],
        CycleTemp: [{
          label: "Cold",
          val: 0
        }, {
          label: "Warm",
          val: 1
        }, {
          label: "Hot",
          val: 2
        }, {
          label: "NoHeat",
          val: 3
        }, {
          label: "_15C",
          val: 4
        }, {
          label: "_30C",
          val: 5
        }, {
          label: "_40C",
          val: 6
        }, {
          label: "_50C",
          val: 7
        }, {
          label: "_60C",
          val: 8
        }, {
          label: "_90C",
          val: 9
        }],
        CurrencyType: [{
          label: "None",
          val: 0,
          abbr: ""
        }, {
          label: "Dollar",
          val: 1,
          abbr: "$"
        }, {
          label: "Pound",
          val: 2,
          abbr: "£"
        }, {
          label: "Yen",
          val: 3,
          abbr: "¥"
        }, {
          label: "Won",
          val: 4,
          abbr: "₩"
        }, {
          label: "Euro",
          val: 5,
          abbr: "€"
        }, {
          label: "Rupee",
          val: 6,
          abbr: "₹"
        }, {
          label: "TurkishLira",
          val: 7,
          abbr: "₺"
        }, {
          label: "Ruble",
          val: 8,
          abbr: "₽"
        }, {
          label: "Forint",
          val: 9,
          abbr: "Ft"
        }, {
          label: "Koruna",
          val: 10,
          abbr: "Kč"
        }, {
          label: "Krona",
          val: 11,
          abbr: "kr"
        }, {
          label: "Kuna",
          val: 12,
          abbr: "kn"
        }, {
          label: "Rand",
          val: 13,
          abbr: "R"
        }, {
          label: "Real",
          val: 14,
          abbr: "R$"
        }, {
          label: "Token",
          val: 15,
          abbr: "Tk"
        }, {
          label: "Zloty",
          val: 16,
          abbr: "zł"
        }, // TODO: Need symbols for these
        {
          label: "Rupiah",
          val: 17
        }, {
          label: "Ringgit",
          val: 18
        }, {
          label: "Baht",
          val: 19
        }, {
          label: "Kip",
          val: 20
        }, {
          label: "Kyat",
          val: 21
        }, {
          label: "Dong",
          val: 22
        }],
        AudioPattern: [{
          label: "NoTone",
          val: 0
        }, {
          label: "SolidHigh",
          val: 1
        }, {
          label: "SolidMedium",
          val: 2
        }, {
          label: "SolidLow",
          val: 3
        }, {
          label: "BeepHigh",
          val: 4
        }, {
          label: "BeepMedium",
          val: 5
        }, {
          label: "BeepLow",
          val: 6
        }, {
          label: "DoubleBeepHigh",
          val: 7
        }, {
          label: "DoubleBeepMedium",
          val: 8
        }, {
          label: "DoubleBeepLow",
          val: 9
        }, {
          label: "SOSHigh",
          val: 10
        }, {
          label: "SOSMedium",
          val: 11
        }, {
          label: "SOSLow",
          val: 12
        }, {
          label: "IncreasingTone",
          val: 13
        }, {
          label: "SineTone",
          val: 14
        }, {
          label: "Melody1",
          val: 15
        }, {
          label: "Melody2",
          val: 16
        }, {
          label: "Melody3",
          val: 17
        }, {
          label: "Melody4",
          val: 18
        }, {
          label: "Melody5",
          val: 19
        }, {
          label: "Melody6",
          val: 20
        }],
        ActiveState: [{
          label: "Disabled",
          val: 0
        }, {
          label: "ActiveMod1",
          val: 1
        }, {
          label: "ActiveMod2",
          val: 2
        }, {
          label: "InactiveMod3",
          val: 3
        }, {
          label: "ActiveMod4",
          val: 4
        }, {
          label: "InactiveMod4",
          val: 5
        }, {
          label: "AlwaysEnabled",
          val: 8
        }],
        ReversingRotateTime: [{
          label: "30",
          val: 3
        }, {
          label: "45",
          val: 4
        }, {
          label: "60",
          val: 5
        }, {
          label: "90",
          val: 6
        }, {
          label: "120",
          val: 7
        }, {
          label: "240",
          val: 8
        }, {
          label: "540",
          val: 9
        }],
        ReversingStopTime: [{
          label: "6",
          val: 0
        }, {
          label: "7",
          val: 1
        }, {
          label: "8",
          val: 2
        }, {
          label: "9",
          val: 3
        }, {
          label: "10",
          val: 4
        }],
        SpinSpeedExtract: [// TODO: Confirm the values are correct!
        {
          label: "VeryLow",
          val: 1,
          presetValue: this.getSpeedPreset('extractSpeeds', 0)
        }, {
          label: "Low",
          val: 2,
          presetValue: this.getSpeedPreset('extractSpeeds', 1)
        }, {
          label: "Medium",
          val: 3,
          presetValue: this.getSpeedPreset('extractSpeeds', 2)
        }, {
          label: "High",
          val: 4,
          presetValue: this.getSpeedPreset('extractSpeeds', 3)
        }, {
          label: "VeryHigh",
          val: 5,
          presetValue: this.getSpeedPreset('extractSpeeds', 4)
        }, {
          label: "UltraHigh",
          val: 6,
          presetValue: this.getSpeedPreset('extractSpeeds', 5)
        }, {
          label: "Custom",
          isCustom: true
        }],
        SpinSpeedAgitate: [{
          label: "Low",
          val: 1,
          presetValue: this.getSpeedPreset('agitateSpeeds', 0)
        }, {
          label: "Regular",
          val: 2,
          presetValue: this.getSpeedPreset('agitateSpeeds', 1)
        }, {
          label: "Custom",
          isCustom: true
        }, {
          label: "Disable",
          val: 4
        }],
        SpinSpeedDrain: [{
          label: "Distribution",
          val: 3,
          presetValue: this.getSpeedPreset('drainSpeeds', 2)
        }, {
          label: "Low",
          val: 1,
          presetValue: this.getSpeedPreset('drainSpeeds', 0)
        }, {
          label: "Regular",
          val: 2,
          presetValue: this.getSpeedPreset('drainSpeeds', 1)
        }, {
          label: "Custom",
          isCustom: true
        }, {
          label: "Disable",
          val: 4
        }]
      };
      return optionOutput;
    })
  });

  _exports.default = _default;
});