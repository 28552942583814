define("alliance-business-suite/templates/components/input/input-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SYzHtLQS",
    "block": "{\"symbols\":[],\"statements\":[[8,\"input\",[[16,0,[34,2]],[16,\"placeholder\",[34,3]]],[[\"@type\",\"@value\"],[[34,0],[34,1]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"type\",\"value\",\"class\",\"placeholder\"]}",
    "moduleName": "alliance-business-suite/templates/components/input/input-text.hbs"
  });

  _exports.default = _default;
});