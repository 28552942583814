define("alliance-business-suite/helpers/step-label-abbrev", ["exports", "alliance-business-suite/utils/cycles-values"], function (_exports, _cyclesValues) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // TODO: Add translations
  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),

    compute(_ref, args) {
      let [value, ...rest] = _ref;
      let abbrev = _cyclesValues.STEP_LABELS_ABBREV[value];
      let stepLabel = _cyclesValues.STEP_LABELS[value];
      return Ember.String.htmlSafe(`<span class="step-label-abbrev" title="${stepLabel}">${abbrev}</span>`);
    }

  });

  _exports.default = _default;
});