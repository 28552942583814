define("alliance-business-suite/components/machines/programs/config/midas-ezopl-m3-tum", ["exports", "alliance-business-suite/mixins/machines/programs/midas-options", "alliance-business-suite/mixins/machines/programs/midas-opl-property-names"], function (_exports, _midasOptions, _midasOplPropertyNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_midasOptions.default, _midasOplPropertyNames.default, {
    formConfig: Ember.computed(function () {
      return {
        columns: [{
          sections: [{
            title: "machine_settings",
            controls: [{
              type: "daylight-saving",
              properties: this.propertyNames.daylightSaving,
              hours12: true
            }, {
              disable: true,
              type: "checkbox",
              propertyName: "Program.Display.VendPriceDisplayOverrideEnabled"
            }, {
              type: "checkbox",
              propertyName: "Program.Misc.Comms.IREnabled"
            }, {
              disable: true,
              type: "checkbox",
              propertyName: "Program.Misc.Machine.PaymentSystemDIP"
            }, {
              type: "checkbox",
              propertyName: "Program.Misc.Comms.USBEnabled"
            }, {
              type: "checkbox",
              propertyName: "Program.Misc.Comms.WifiEnabled"
            }, {
              type: "checkbox",
              propertyName: "Program.Misc.MiscConfig.FactoryTestCycleEnabled"
            }, {
              type: "checkbox-extended-tumble",
              propertyName: 'Program.Cycle.ExtendedTumbleEnabled_TUM'
            }, {
              type: "checkbox",
              propertyName: "Program.Misc.MiscConfig.ErrorClearingEnabled"
            }]
          }, {
            disable: true,
            title: "break_in_alarm",
            controls: [{
              type: "break-in-alarm",
              properties: this.propertyNames.breakinAlarm
            }]
          }, {
            title: "manual_mode",
            controls: [{
              type: "manual-mode",
              properties: this.propertyNames.manualMode
            }]
          }]
        }, {
          sections: [{
            title: "display_settings",
            controls: [{
              type: "checkbox",
              propertyName: "Program.Display.DisplayHeatFlameIndicator"
            }, {
              type: "checkbox",
              propertyName: "Program.Display.DisplayTimeSinceCyleComplete"
            }, {
              type: "checkbox",
              propertyName: "Program.Display.Backlight.ScreenSaverEnabled",
              inverted: true
            }, {
              type: "checkbox",
              propertyName: "Program.Display.DisplayTimeMinutesOnly_TUM",
              inverted: true
            }, {
              type: "checkbox",
              propertyName: "Program.Display.DisplayApproachScreen"
            }, {
              type: "checkbox",
              propertyName: "Program.Display.DisplayLimitCycles_TUM"
            }, {
              type: "select",
              propertyName: "Program.Misc.DaylightSavings.DateOrder",
              options: "DateOrder"
            }, {
              type: "select",
              propertyName: "Program.Misc.MiscConfig.DefaultLanguage",
              options: "Language"
            }, {
              type: "user-languages",
              languages: this.propertyNames.userLanguages
            }, // TODO: These three are missing
            {
              type: "number",
              propertyName: "Program.Display.Backlight.Active",
              labelKey: "BacklightActive",
              min: 0,
              max: 255
            }, {
              type: "number",
              propertyName: "Program.Display.Backlight.InactiveCriticalMode",
              min: 0,
              max: 255
            }, {
              type: "number",
              propertyName: "Program.Display.Backlight.InactiveNonCriticalMode",
              min: 0,
              max: 255
            }, {
              type: "text",
              propertyName: "Program.Misc.MiscConfig.HelpNumber",
              length: 20
            }, {
              disable: true,
              type: "number",
              propertyName: "Program.Price.Misc.KioskNumber",
              min: 0,
              max: 255
            }]
          }, {
            title: "programmable_errors",
            controls: [{
              disable: true,
              type: "checkbox",
              propertyName: "Program.Misc.Errors.CoinErrorEnabled"
            }, {
              disable: true,
              type: "checkbox",
              propertyName: "Program.Misc.Errors.CoinErrorPenaltyEnabled"
            }, {
              disable: true,
              type: "checkbox",
              propertyName: "Program.Misc.Errors.CoinDropVendHeaderErrorEnabled",
              inverted: true
            }, {
              type: "checkbox",
              propertyName: "Program.Display.DisplayFalseFlameError_TUM"
            }]
          }, {
            title: "audio_signals",
            controls: [{
              type: "checkbox",
              propertyName: "Program.Misc.MiscConfig.EndOfCycleAudioEnabled"
            }, {
              disable: true,
              type: "checkbox",
              propertyName: "Program.Misc.MiscConfig.CoinCardAudioEnabled"
            }, {
              disable: true,
              type: "checkbox",
              propertyName: "Program.Misc.MiscConfig.NetworkVendAudioEnabled",
              inverted: true
            }, {
              type: "checkbox",
              propertyName: "Program.Misc.MiscConfig.KeyPressAudioEnabled"
            }, {
              type: "checkbox",
              propertyName: "Program.Misc.MiscConfig.StartModeAudioEnabled"
            }, {
              type: "checkbox",
              propertyName: "Program.Cycle.AntiWrinkleAudioEnabled_TUM"
            }, {
              type: "checkbox",
              propertyName: "Program.Cycle.ExtendedTumbleAudioEnabled_TUM"
            }]
          }]
        }]
      };
    })
  });

  _exports.default = _default;
});