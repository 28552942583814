define("alliance-business-suite/components/validation/message", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#let (get @changeset.error @propertyName) as |errorItem|}}
  
    {{!-- Do we have any errors for this property? --}}
    {{#if errorItem}}
      <ul class="validation-message">
  
        {{#if errorItem.validation.message}}
          {{!-- Single validation --}}
          <li>{{t errorItem.validation.message description=(t errorItem.validation.context.label)}}</li>
        {{else}}
          {{!-- Multiple validations --}}
          {{#each errorItem.validation as |validation|}}
            <li>{{t validation.message description=(t validation.context.label)}}</li>
          {{/each}}
        {{/if}}
  
      </ul>
    {{/if}}
  
  {{/let}}
  */
  {
    "id": "wU8lDhx8",
    "block": "{\"symbols\":[\"errorItem\",\"validation\",\"@propertyName\",\"@changeset\"],\"statements\":[[6,[37,5],[[30,[36,4],[[32,4,[\"error\"]],[32,3]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"ul\"],[14,0,\"validation-message\"],[12],[2,\"\\n\\n\"],[6,[37,3],[[32,1,[\"validation\",\"message\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"li\"],[12],[1,[30,[36,0],[[32,1,[\"validation\",\"message\"]]],[[\"description\"],[[30,[36,0],[[32,1,[\"validation\",\"context\",\"label\"]]],null]]]]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,1,[\"validation\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"li\"],[12],[1,[30,[36,0],[[32,2,[\"message\"]]],[[\"description\"],[[30,[36,0],[[32,2,[\"context\",\"label\"]]],null]]]]],[13],[2,\"\\n\"]],\"parameters\":[2]}]]]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"-track-array\",\"each\",\"if\",\"get\",\"let\"]}",
    "moduleName": "alliance-business-suite/components/validation/message.hbs"
  });

  class ValidationMessageComponent extends _component.default {}

  _exports.default = ValidationMessageComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ValidationMessageComponent);
});