define("alliance-business-suite/components/machines/traceability/data/charts-water-level", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "alliance-business-suite/utils/traceability"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _traceability) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="traceability-chart">
    <div class="steps-container with-shadow">
      <header>
        <h3 class="stamp">{{t 'water_level'}}</h3>
      </header>
  
      <HighCharts
        @chartOptions={{this.chartOptions}}
        @content={{this.chartData}} as |chart|>
          <Machines::Traceability::Data::ChartsRedraw
            @chart={{chart}}
            @xAxisLabels={{this.xAxisLabels}} />
      </HighCharts>
    </div>
  </div>
  
  */
  {
    "id": "BDEXvyIH",
    "block": "{\"symbols\":[\"chart\"],\"statements\":[[10,\"div\"],[14,0,\"traceability-chart\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"steps-container with-shadow\"],[12],[2,\"\\n    \"],[10,\"header\"],[12],[2,\"\\n      \"],[10,\"h3\"],[14,0,\"stamp\"],[12],[1,[30,[36,0],[\"water_level\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[8,\"high-charts\",[],[[\"@chartOptions\",\"@content\"],[[32,0,[\"chartOptions\"]],[32,0,[\"chartData\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"machines/traceability/data/charts-redraw\",[],[[\"@chart\",\"@xAxisLabels\"],[[32,1],[32,0,[\"xAxisLabels\"]]]],null],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "moduleName": "alliance-business-suite/components/machines/traceability/data/charts-water-level.hbs"
  });

  let MachinesTraceabilityDataChartsWaterLevelComponent = (_dec = Ember.inject.service, (_class = class MachinesTraceabilityDataChartsWaterLevelComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor, this);
      (0, _defineProperty2.default)(this, "baseTime", this.args.rawTraceData.data[0].timestamp);
    }

    generateData(prop, datapointKey, divideBy) {
      let results = {
        [prop]: [],
        time: []
      };
      this.args.rawTraceData.data.forEach(datapoint => {
        // Some values may need to be converted to decimals because controls only accept integers.
        if (divideBy !== undefined) {
          results[prop].push(datapoint[datapointKey] / divideBy);
        } else {
          results[prop].push(datapoint[datapointKey]);
        }

        results.time.push(datapoint.timestamp - this.baseTime);
      });
      return results;
    }

    get chartResults() {
      return {
        waterLevelData: this.generateData('level', 'waterLevel', 10),
        targetWeterLevelData: this.generateData('targetWater', '_stepTargetLevel'),
        minWaterLevel: this.generateData('minLevel', 'minimumWaterLevel', 10)
      };
    }

    generateTimes() {
      return this.args.rawTraceData.data.map(datapoint => {
        const val = _traceability.formatMinutesToTime.call(this, (datapoint.timestamp - this.baseTime) / 60, true);

        if (val.hrs > 0) {
          return `${val.hrs}:${val.mins}:${val.secs}`;
        } else {
          return `${val.mins}:${val.secs}`;
        }
      });
    }

    get xAxisLabels() {
      return this.generateTimes();
    }

    get chartOptions() {
      return {
        chart: {
          zoomType: 'xy',
          events: {// load: (e) => {
            // this.args.isLoaded();
            // },
          }
        },
        title: {
          text: null // removes title

        },
        subtitle: {
          text: null // removes subtitle

        },
        xAxis: {
          categories: this.xAxisLabels,
          crosshair: true,
          // tickInterval: 5,
          // minorTickInterval: 1,
          // minorTickLength: 1,
          tickPositioner: function () {
            var positions = [],
                tick = Math.floor(this.dataMin),
                increment = Math.ceil((this.dataMax - this.dataMin) / 10);

            if (this.dataMax !== null && this.dataMin !== null) {
              for (tick; tick - increment <= this.dataMax; tick += increment) {
                positions.push(tick);
              }
            } // console.log('chart-water-level positions:', positions)


            return positions;
          },
          title: {
            text: 'Time (min)'
          } // type : 'second',
          // dateTimeLabelFormats : {
          //     week : '%Y/%m/%d %H%M'

        },
        yAxis: {
          // max: 100,
          tickInterval: 10,
          minorTickInterval: 5,
          minorTickLength: 1,
          labels: {
            format: '{value}',
            style: {
              color: Highcharts.getOptions().colors[1]
            }
          },
          title: {
            text: 'Water level (units)',
            style: {
              color: Highcharts.getOptions().colors[1]
            }
          }
        },
        tooltip: {
          shared: true
        },
        plotOptions: {
          line: {
            marker: {
              enabled: false
            }
          },
          area: {
            marker: {
              enabled: false
            },
            fillColor: {
              linearGradient: [0, 0, 0, 300],
              stops: [[0, "rgba(241,241,241,0.9)"], [1, Highcharts.color("#493185").setOpacity(.3).get('rgba')]]
            }
          }
        }
      };
    }

    get chartData() {
      return [{
        name: this.intl.t('water_level'),
        type: 'area',
        color: '#493185',
        lineWidth: 4,
        data: this.chartResults.waterLevelData.level,
        tooltip: {
          valueSuffix: ''
        }
      }, {
        name: this.intl.t('target_level'),
        type: 'line',
        color: '#478735',
        lineWidth: 4,
        data: this.chartResults.targetWeterLevelData.targetWater,
        tooltip: {
          valueSuffix: ''
        }
      }, {
        name: this.intl.t('minimum_level'),
        type: 'line',
        color: '#f9b654',
        lineWidth: 4,
        data: this.chartResults.minWaterLevel.minLevel,
        tooltip: {
          valueSuffix: ''
        }
      }];
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = MachinesTraceabilityDataChartsWaterLevelComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, MachinesTraceabilityDataChartsWaterLevelComponent);
});