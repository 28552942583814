define("alliance-business-suite/helpers/invalid-value-updated", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),

    compute(_ref, hash) {
      let [hasChanged, ...rest] = _ref;

      // Match param based on brand abbrev (NOT slug)
      if (hasChanged) {
        return Ember.String.htmlSafe(`<span class="value-changed-alert"><i class="fa fa-exclamation-triangle"></i> ${this.intl.t("invalid_value_changed")}</span>`);
      }
    }

  });

  _exports.default = _default;
});