define("alliance-business-suite/components/form-ui/slow-drain-detection", ["exports", "alliance-business-suite/mixins/init-property-names", "alliance-business-suite/mixins/show-component"], function (_exports, _initPropertyNames, _showComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_showComponent.default, _initPropertyNames.default, {});

  _exports.default = _default;
});