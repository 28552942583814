define("alliance-business-suite/components/machines/programs/cycles/mgd-opl-tum", ["exports", "ember-copy", "alliance-business-suite/mixins/machines/programs/mgd-options", "alliance-business-suite/helpers/find-by-dropdown-with-custom"], function (_exports, _emberCopy, _mgdOptions, _findByDropdownWithCustom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Removed CycleGlobal mixin!
  var _default = Ember.Component.extend(_mgdOptions.default, {
    optionsType: [{
      val: 0,
      label: "time_dry"
    }, {
      val: 1,
      label: "auto_dry"
    }, {
      val: 2,
      label: "moisture_dry"
    }],
    optionsMaterial: [{
      val: 0,
      label: "cotton"
    }, {
      val: 1,
      label: "blend"
    }, {
      val: 2,
      label: "bedding"
    }, {
      val: 3,
      label: "delicate"
    }, {
      val: 4,
      label: "synthetic"
    }, {
      val: 5,
      label: "wool"
    }],
    //#region Variables
    notifications: Ember.inject.service(),
    intl: Ember.inject.service(),
    clipboard: {
      type: null,
      contents: null,
      title: null
    },

    // showCycleInfo: false,
    //#endregion
    //#region Events
    didInsertElement() {
      let self = this;

      this._super(...arguments);

      this.send('selectCycle');
      Ember.set(this, 'defaultMachineType', Ember.get(this, 'cycleList.firstObject.MachineType'));
      Ember.set(this, 'hasCycleNumbers', Ember.get(this, 'cycleList.firstObject.Number') ? true : false);
    },

    //#endregion
    //#region Computed properties
    // TODO: Make sure the cycle array is sorted based on Number.
    cycleList: Ember.computed.alias('model.settings.Cycles'),
    isDefaultCycle: Ember.computed('model', function () {
      return Ember.get(this, 'model.settings.Program.Misc.CycleRibonConfig_WX.0');
    }),

    //#endregion
    //#region Methods
    resetCycleNumbers() {
      console.time('resetCycleNumbers');

      if (this.hasCycleNumbers) {
        this.cycleList.forEach((cycle, index) => {
          // TODO: Check if cycle.Number is zero-based
          Ember.set(cycle, 'Number', index + 1);
        });
      }

      console.timeEnd('resetCycleNumbers');
    },

    //#endregion
    //#region Actions
    actions: {
      selectCycle(value, init) {
        // debugger; // eslint-disable-line
        console.time('selectCycle');

        if (event && event.target.tagName === "INPUT") {
          return true;
        } // Value can be the cycle object or the index of the cycle


        let index, cycle;

        if (value === undefined) {
          value = 0;
        }

        if (isNaN(value)) {
          // we have an object
          cycle = value;
          index = this.cycleList.indexOf(value);
        } else {
          // we have a number
          index = value;
          cycle = this.cycleList.objectAt(value);
        }

        Ember.set(this, 'selectedCycle', cycle);
        Ember.set(this, 'selectedCycleIndex', Number(index));
        localStorage.setItem('cycle', index || 0);
        console.timeEnd('selectCycle');
      }

    } //#endregion

  });

  _exports.default = _default;
});