define("alliance-business-suite/helpers/to-celsius", ["exports", "alliance-business-suite/utils/convert-temperature"], function (_exports, _convertTemperature) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (params) {
    let f = params[0];

    if (Ember.isNone(f)) {
      return;
    } else {
      return (0, _convertTemperature.toC)(f);
    }
  });

  _exports.default = _default;
});