define("alliance-business-suite/components/summary/reports/s-audit-error-diagnostic", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "alliance-business-suite/utils/report-tables-util"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _reportTablesUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  let SummaryReportsSAuditErrorDiagnostic = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, (_class = class SummaryReportsSAuditErrorDiagnostic extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "brand", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor4, this);
    }

    get translatedColumns() {
      return [{
        name: 'location_machine',
        valuePath: 'name',
        width: 375,
        isFixed: 'left'
      }, {
        name: 'date_range',
        valuePath: 'dateRange',
        width: 360
      }, {
        name: 'door_open',
        valuePath: 'doorOpenErrorAuditCounter',
        width: 180
      }, {
        name: 'door_lock',
        valuePath: 'doorLockErrorAuditCounter',
        width: 180
      }, {
        name: 'motor_fail',
        valuePath: 'motorFail',
        width: 180
      }, {
        name: 'Fill',
        valuePath: 'fillErrorAuditCounter',
        width: 180
      }, {
        name: 'thermistor_open',
        valuePath: 'openThermistorErrorAuditCounter',
        width: 180
      }, {
        name: 'thermistor_short',
        valuePath: 'shortedThermistorErrorAuditCounter',
        width: 180
      }, {
        name: 'SPI',
        valuePath: 'spiErrorAuditCounter',
        width: 180
      }, {
        name: 'Unbalanced',
        valuePath: 'unbalanceErrorAuditCounter',
        width: 180
      }, {
        name: 'Heater',
        valuePath: 'heaterErrorAuditCounter',
        width: 180
      }];
    }

    get rowsWithChildren() {
      let geoBoundaries = this.args.model.get('geoBoundaries');
      let rowData = [];
      geoBoundaries.forEach(geoBoundary => {
        let rowArray = [];
        let nameArray = [];
        rowData.push({
          name: _reportTablesUtil.localizeString.call(this, geoBoundary.name, 'Location', 'location'),
          children: nameArray
        });
        geoBoundary.rooms.forEach(room => {
          let powerFailureSum = 0;
          let vendAuditTest1Sum = 0;
          let vendAuditTest2Sum = 0;
          let vendAuditError1Sum = 0;
          let vendAuditError2Sum = 0;
          let cardRelatedSum = 0;
          let badIRCommunicationsSum = 0;
          let badNetworkCommunicationsSum = 0;
          let heaterSum = 0;
          let totalPowerFailure = [];
          let totalAuditTest1 = [];
          let totalAuditTest2 = [];
          let totalAuditError1 = [];
          let totalAuditError2 = [];
          let cardRelatedTotal = [];
          let totalBadIR = [];
          let totalBadNetwork = [];
          let totalHeaterError = [];
          nameArray.push({
            name: room.name,
            children: rowArray,
            doorLockErrorAuditCounter: totalPowerFailure,
            doorOpenErrorAuditCounter: totalAuditTest1,
            motorFail: totalAuditTest2,
            fillErrorAuditCounter: totalAuditError1,
            openThermistorErrorAuditCounter: totalAuditError2,
            shortedThermistorErrorAuditCounter: cardRelatedTotal,
            spiErrorAuditCounter: totalBadIR,
            unbalanceErrorAuditCounter: totalBadNetwork,
            heaterErrorAuditCounter: totalHeaterError
          });
          room.machineAuditGroupTypes.forEach(machineAuditType => {
            machineAuditType.machineTypes.forEach(machineType => {
              let machineArray = [];
              let machineTypeName = `${machineAuditType.name} - ${machineType.name}`;
              rowArray.push({
                name: machineTypeName,
                machineTypeName: machineType.name,
                children: machineArray
              });
              machineType.machines.forEach(machine => {
                let machinePowerFailureArray = [];
                let machineCoin1TestArray = [];
                let machineCoin2TestArray = [];
                let machineCoin1ErrorsArray = [];
                let machineCoin2ErrorsArray = [];
                let machineCardReaderErrorsArray = [];
                let machineBadIRCommunicationsArray = [];
                let machineBadNetworkCommunicationsArray = [];
                let machineHeaterArray = [];

                if (this.brand.slug == 'primus') {
                  // fix the branding issue alliance-tasks/issues/2291
                  rowArray[rowArray.length - 1].name = `${machine.machineAuditType.name} - ${rowArray[rowArray.length - 1].machineTypeName}`;
                }

                if (machine.doorLockErrorAuditCounter !== 0) {
                  machinePowerFailureArray.push(machine.doorLockErrorAuditCounter);
                  powerFailureSum = (0, _reportTablesUtil.sumArray)(machinePowerFailureArray, powerFailureSum);
                }

                if (machine.doorOpenErrorAuditCounter !== 0) {
                  machineCoin1TestArray.push(machine.doorOpenErrorAuditCounter);
                  vendAuditTest1Sum = (0, _reportTablesUtil.sumArray)(machineCoin1TestArray, vendAuditTest1Sum);
                }

                if (machine.motorFail !== 0) {
                  machineCoin2TestArray.push(machine.motorFail);
                  vendAuditTest2Sum = (0, _reportTablesUtil.sumArray)(machineCoin2TestArray, vendAuditTest2Sum);
                }

                if (machine.fillErrorAuditCounter !== 0) {
                  machineCoin1ErrorsArray.push(machine.fillErrorAuditCounter);
                  vendAuditError1Sum = (0, _reportTablesUtil.sumArray)(machineCoin1ErrorsArray, vendAuditError1Sum);
                }

                if (machine.openThermistorErrorAuditCounter !== 0) {
                  machineCoin2ErrorsArray.push(machine.openThermistorErrorAuditCounter);
                  vendAuditError2Sum = (0, _reportTablesUtil.sumArray)(machineCoin2ErrorsArray, vendAuditError2Sum);
                }

                if (machine.shortedThermistorErrorAuditCounter !== 0) {
                  machineCardReaderErrorsArray.push(machine.shortedThermistorErrorAuditCounter);
                  cardRelatedSum = (0, _reportTablesUtil.sumArray)(machineCardReaderErrorsArray, cardRelatedSum);
                }

                if (machine.spiErrorAuditCounter !== 0) {
                  machineBadIRCommunicationsArray.push(machine.spiErrorAuditCounter);
                  badIRCommunicationsSum = (0, _reportTablesUtil.sumArray)(machineBadIRCommunicationsArray, badIRCommunicationsSum);
                }

                if (machine.unbalanceErrorAuditCounter !== 0) {
                  machineBadNetworkCommunicationsArray.push(machine.unbalanceErrorAuditCounter);
                  badNetworkCommunicationsSum = (0, _reportTablesUtil.sumArray)(machineBadNetworkCommunicationsArray, badNetworkCommunicationsSum);
                }

                if (machine.heaterErrorAuditCounter !== 0) {
                  machineHeaterArray.push(machine.heaterErrorAuditCounter);
                  heaterSum = (0, _reportTablesUtil.sumArray)(machineHeaterArray, heaterSum);
                }

                let machineName = _reportTablesUtil.machineNameDisplay.call(this, machine.machineName, machine.machineNumber);

                let doorLock = machine.doorLockErrorAuditCounter || 0;
                let doorOpen = machine.doorOpenErrorAuditCounter || 0;
                let motorError = machine.motorFail || 0;
                let fillError = machine.fillErrorAuditCounter || 0;
                let openThermistor = machine.openThermistorErrorAuditCounter || 0;
                let shortThermistor = machine.shortedThermistorErrorAuditCounter || 0;
                let spiError = machine.spiErrorAuditCounter || 0;
                let unbalanceError = machine.unbalanceErrorAuditCounter || 0;
                let heaterError = machine.heaterErrorAuditCounter || 0;
                let dateRange = "";

                if (machine.minFirstReceivedAt && machine.maxFirstReceivedAt) {
                  dateRange = `${moment(machine.minFirstReceivedAt).format(_reportTablesUtil.formatDateTimeString.call(this))} - ${moment(machine.maxFirstReceivedAt).format(_reportTablesUtil.formatDateTimeString.call(this))}`;
                }

                machineArray.push({
                  name: machineName,
                  dateRange: dateRange,
                  doorLockErrorAuditCounter: doorLock,
                  doorOpenErrorAuditCounter: doorOpen,
                  motorFail: motorError,
                  fillErrorAuditCounter: fillError,
                  openThermistorErrorAuditCounter: openThermistor,
                  shortedThermistorErrorAuditCounter: shortThermistor,
                  spiErrorAuditCounter: spiError,
                  unbalanceErrorAuditCounter: unbalanceError,
                  heaterErrorAuditCounter: heaterError
                });
              });
            });
          });
          totalPowerFailure.push(powerFailureSum);
          totalAuditTest1.push(vendAuditTest1Sum);
          totalAuditTest2.push(vendAuditTest2Sum);
          totalAuditError1.push(vendAuditError1Sum);
          totalAuditError2.push(vendAuditError2Sum);
          cardRelatedTotal.push(cardRelatedSum);
          totalBadIR.push(badIRCommunicationsSum);
          totalBadNetwork.push(badNetworkCommunicationsSum);
          totalHeaterError.push(heaterSum);
        });
      });
      return rowData;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "brand", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SummaryReportsSAuditErrorDiagnostic;
});