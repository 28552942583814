define("alliance-business-suite/templates/components/input-currency", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ySHlTflU",
    "block": "{\"symbols\":[\"@disabled\"],\"statements\":[[10,\"input\"],[14,\"data-type\",\"currency\"],[14,3,\"currency-field\"],[15,1,[32,0,[\"inputId\"]]],[15,\"disabled\",[32,1]],[15,0,[31,[\"mousetrap \",[34,0]]]],[15,2,[34,1]],[15,\"onkeyup\",[30,[36,2],[[32,0],\"maskCurrencyInput\"],null]],[15,\"onkeyup\",[30,[36,2],[[32,0],\"setUnmaskedCurrency\"],null]],[14,4,\"currency\"],[12],[13]],\"hasEval\":false,\"upvars\":[\"class\",\"currencyDisplay\",\"action\"]}",
    "moduleName": "alliance-business-suite/templates/components/input-currency.hbs"
  });

  _exports.default = _default;
});