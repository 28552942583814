define("alliance-business-suite/components/rewards/general-rewards", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    access: Ember.inject.service(),
    intl: Ember.inject.service(),
    notifications: Ember.inject.service(),
    router: Ember.inject.service(),
    currencySymbol: Ember.computed(function () {
      return Ember.get(this, 'access.currencyDenomination');
    }),
    currencyDecimals: Ember.computed(function () {
      if (this.get('access.currencySubunit') === 1) {
        return 0;
      } else {
        return 2;
      }
    }),
    actions: {
      updateRecord(model) {
        if (this.access.isDemo) {
          alert('Demo mode restricted.');
          return;
        }

        this.set('isSaving', true);
        model.save().then(() => {
          this.notifications.success(this.intl.t('reward_saved'), {
            autoClear: true,
            clearDuration: 4000
          });
          this.set('isSaving', false);
        });
      },

      rollbackRecord(model) {
        if (model.get('hasDirtyAttributes')) {
          model.rollbackAttributes();
          this.router.transitionTo('locations.rewards');
        } else {
          this.router.transitionTo('locations.rewards');
        }
      },

      destroyRecord(model) {
        if (this.access.isDemo) {
          alert('Demo mode restricted.');
          return;
        }

        if (confirm(this.intl.t('confirm_reward_program_deletion'))) {
          model.destroyRecord().then(() => {
            this.notifications.success(this.intl.t('reward_deleted'), {
              autoClear: true,
              clearDuration: 4000
            });
            this.set('isSaving', false);
            this.router.transitionTo('locations.rewards');
          });
        }
      }

    }
  });

  _exports.default = _default;
});