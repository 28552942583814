define("alliance-business-suite/components/employees/employees-list-table", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  let EmployeesEmployeesListTableComponent = (_dec = Ember.inject.service, (_class = class EmployeesEmployeesListTableComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor, this);
    }

    get columns() {
      return [{
        propertyName: 'fullName',
        title: this.intl.t('name')
      }, {
        propertyName: 'primaryEmail',
        title: this.intl.t('email')
      }, {
        propertyName: 'primarySecurityRole',
        title: this.intl.t('role'),
        component: 'table/table-cell-security-role'
      }];
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = EmployeesEmployeesListTableComponent;
});