define("alliance-business-suite/mixins/split-columns", ["exports", "jquery", "ember-copy"], function (_exports, _jquery, _emberCopy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* globals Split */
  var _default = Ember.Mixin.create({
    resizeElements() {
      // Calculate height of split columns.
      // TODO: This should probably be done with CSS flex or grid.
      let footerHeight = 0;
      (0, _jquery.default)('.view-always-footer').each(function () {
        let $this = (0, _jquery.default)(this);
        footerHeight += $this.outerHeight();
      });
      let top = (0, _jquery.default)('.split:first').offset().top;
      let screenHeight = (0, _jquery.default)(window).height(); // Remove the height of the dev tray, if displayed

      let devTrayHeight = document.getElementById('dev-tool-tray') ? document.getElementById('dev-tool-tray').clientHeight : 0;
      (0, _jquery.default)('.split, .gutter').height(screenHeight - top - footerHeight - devTrayHeight - 10); // Subtracting 10 to give a little padding at the bottom of the screen
    },

    didInsertElement() {
      this._super(...arguments);

      let sizes = localStorage.getItem('split-sizes');

      if (sizes) {
        sizes = JSON.parse(sizes);
      } else {
        sizes = [40, 40, 19]; // default sizes
      }

      let split = new Split(['#split1', '#split2', '#split3'], {
        sizes,
        minSize: 200,

        onDragEnd() {
          localStorage.setItem('split-sizes', JSON.stringify(split.getSizes()));
        }

      });
      Ember.set(this, 'panes', split);
      this.resizeElements(); // Hook up resize

      (0, _jquery.default)(window).on('resize', this.resizeElements);
    },

    didDestroyElement() {
      let panes = this.panes;

      if (panes) {
        panes.destroy();
      }

      (0, _jquery.default)(window).off('resize', this.resizeElements);
    }

  });

  _exports.default = _default;
});