define("alliance-business-suite/components/billing/subscription-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // eslint-disable-line
    tagName: '',
    access: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),

    didReceiveAttrs() {
      this._super(...arguments);

      this.set('renderComplete', false);
      this.timeOutRender(this);
    },

    didInsertElement() {
      this._super(...arguments);

      this.locations.forEach(location => {
        Ember.set(location, 'isActive', moment(location.expirationDate).isAfter(moment()));
      });
    },

    // locations: computed('access.billingLocations', function() {
    //   return this.access.billingLocations.sortBy('name');
    // }),
    locales: Ember.computed('intl.{locale,locales}', function () {
      return this.intl;
    }),
    displayLocations: Ember.computed('onlyShowInactive', function () {
      if (this.onlyShowInactive) {
        return this.locations.filter(location => {
          return moment(location.expirationDate).isBefore(moment());
        });
      } else {
        return this.locations;
      }
    }),
    onlyShowInactive: false,

    // HACK because we need to break out the render from happening instantly twice
    // or else we get an ember error of "You’ve modified xxx twice in a single render"
    timeOutRender(context) {
      setTimeout(() => {
        context.set('renderComplete', true);
      }, 500);
    },

    selectedCount: Ember.computed('locations.@each.planId', function () {
      return this.locations.filter(location => {
        return location.planId !== undefined && location.planId !== null;
      }).length;
    }),

    payNow() {
      alert("Clicking this button will take the user directly to the Stripe payment page so they can give us their money.");
    },

    actions: {
      selectPlan(planId, location) {
        if (location) {
          Ember.set(location, 'planId', planId);
        } else {
          // only do this for non-active
          this.locations.forEach(element => {
            if (planId === 0) {
              if (!element.isActive) {
                Ember.set(element, 'planId', planId);
              }
            } else {
              Ember.set(element, 'planId', planId);
            }
          });
        }
      },

      unselectPlan(planId, location) {
        if (location) {
          Ember.set(location, 'planId', null);
        } else {
          this.locations.forEach(element => {
            if (element.planId == planId) {
              Ember.set(element, 'planId', null);
            }
          });
        }
      },

      togglePaymentForm(loc) {
        if (loc) {
          this.set('selectedLoc', loc.id);
        }

        this.toggleProperty('showPaymentForm');
      },

      toggleUpgradeForm(loc) {
        if (loc) {
          this.setProperties({
            rawLoc: loc,
            currentPlan: loc.service,
            selectedLoc: loc.id
          });
        }

        this.toggleProperty('showUpgradeForm');
      }

    }
  });

  _exports.default = _default;
});