define("alliance-business-suite/routes/locations/wash-alert/ads", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "alliance-business-suite/routes/_secureRoute"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _secureRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  let LocationsWashAlertAdsRoute = (_dec = Ember.inject.service, (_class = class LocationsWashAlertAdsRoute extends _secureRoute.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "ajax", _descriptor, this);
      (0, _defineProperty2.default)(this, "allowAccess", 'LOCATIONS_WASH_ALERT_ADS_VIEW');
    }

    model(params) {
      return Ember.RSVP.hash({
        room: this.store.peekRecord('room', params.room_id),
        ads: this.ajax.request(`/rooms/${params.room_id}/ads?raw=true`)
      });
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = LocationsWashAlertAdsRoute;
});