define("alliance-business-suite/templates/components/machines/programs/pricing/midas-wx", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9SNIELgp",
    "block": "{\"symbols\":[\"ui\"],\"statements\":[[8,\"form-ui\",[],[[\"@object\",\"@model\",\"@formDisplay\",\"@machineSettings\",\"@selectOptions\",\"@currencySymbol\",\"@currencySymbolPosition\",\"@decimals\",\"@currencyDivideBy\",\"@thousandsSymbol\",\"@decimalSymbol\",\"@isCelsius\",\"@refreshFormConfig\",\"@coinValue\",\"@machineProgram\"],[[32,0,[\"machineProgram\",\"settings\"]],[32,0,[\"machineProgram\"]],\"compact\",[34,0,[\"machineType\",\"machineSettings\"]],[32,0,[\"selectOptions\"]],[32,0,[\"globalSettings\",\"currencySymbol\"]],[32,0,[\"globalSettings\",\"currencySymbolPosition\"]],[32,0,[\"globalSettings\",\"decimals\"]],[32,0,[\"globalSettings\",\"divideBy\"]],[32,0,[\"globalSettings\",\"thousandsSymbol\"]],[32,0,[\"globalSettings\",\"decimalSymbol\"]],[32,0,[\"globalSettings\",\"isCelsius\"]],[30,[36,1],[[32,0],\"refreshFormConfig\"],null],[32,0,[\"globalSettings\",\"coinValue\"]],[32,0,[\"machineProgram\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[8,[32,1,[\"render-form\"]],[],[[\"@formConfig\"],[[32,0,[\"formConfig\"]]]],null],[2,\"\\n\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"machineProgram\",\"action\"]}",
    "moduleName": "alliance-business-suite/templates/components/machines/programs/pricing/midas-wx.hbs"
  });

  _exports.default = _default;
});