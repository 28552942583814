define("alliance-business-suite/components/summary/reports/s-cycle-history", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "alliance-business-suite/utils/report-tables-util"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _reportTablesUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  let SummaryReportsSCycleHistoryComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, (_class = class SummaryReportsSCycleHistoryComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "brand", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor4, this);
    }

    get translatedColumns() {
      return [{
        name: "cycle_name",
        valuePath: "cycleName",
        width: 425,
        isFixed: "left"
      }, {
        name: "start_time",
        valuePath: "startTime",
        width: 325
      }, {
        name: "end_time",
        valuePath: "endTime",
        width: 325
      }, {
        name: "cycle_length_minutes",
        valuePath: "cycleLength"
      }, {
        name: "NEXT_CYCLE",
        valuePath: "nextCycle"
      }, {
        name: "cycle_end_status",
        valuePath: "cycleEnded"
      }, {
        name: "door_open",
        valuePath: "doorOpen"
      }, {
        name: "max_temp",
        valuePath: "maxTemp"
      }];
    }

    get rowsWithChildren() {
      let cycles = this.args.model.get("cycles") ? this.args.model.get("cycles") : [];
      let rowData = [];
      cycles.forEach(_ref => {
        let {
          cycleName,
          startTime,
          endTime,
          cycleLength,
          nextCycle,
          cycleEnded,
          doorOpen,
          maxTemp
        } = _ref;
        rowData.push({
          cycleName: cycleName,
          startTime: startTime ? moment(startTime).format(`${_reportTablesUtil.formatDateTimeString.call(this)}`) : "",
          endTime: endTime ? moment(endTime).format(`${_reportTablesUtil.formatDateTimeString.call(this)}`) : "",
          cycleLength: cycleLength,
          nextCycle,
          cycleEnded: this.formatCycleEndedString(cycleEnded),
          doorOpen,
          maxTemp
        });
      });
      return rowData;
    }

    formatCycleEndedString(status) {
      let str = status.replace("_", " ").toLowerCase();
      return str.charAt(0).toUpperCase() + str.slice(1);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "brand", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SummaryReportsSCycleHistoryComponent;
});