define("alliance-business-suite/controllers/summary/reports", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;

  let SummaryReportsController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._action, (_class = class SummaryReportsController extends Ember.Controller {
    // use this instead of @tracked due to ember slow down caused by moment
    // @tracked startDate =  moment().subtract(30, 'days').toDate()
    // @tracked endDate = moment().toDate();
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "session", _descriptor2, this);
      (0, _defineProperty2.default)(this, "queryParams", ['reportId', 'startDate', 'endDate', 'filterRoomIds', 'timeZone', 'machineId', 'source']);
      (0, _initializerDefineProperty2.default)(this, "filterRoomIds", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "defaultRooms", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "timeZone", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "reportId", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "machineId", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "source", _descriptor8, this);

      if (!this.startDate) {
        this.startDate = new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 30);
      }

      if (!this.endDate) {
        this.endDate = new Date();
      }
    } // this does not work either
    // the use of this over @tracked due to ember slow down caused by moment
    // get startDate() {
    //   return moment().subtract(30, 'days').toDate()
    // }
    // set startDate(value) {
    //   return value;
    // }
    //
    // get endDate() {
    //   return moment().toDate();
    // }
    // set endDate(value) {
    //   return value;
    // }


    updateQueryParam(queryParam, newValue) {
      // use this instead of @tracked due to ember slow down caused by moment
      // need to use the this.set because of the odd slowdown
      // it can't be a get() or a @tracked otherwise in ember 3.16
      if (queryParam == 'startDate' || queryParam == 'endDate') {
        this.set(queryParam, newValue);
      } else {
        this[queryParam] = newValue;
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "filterRoomIds", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "defaultRooms", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "timeZone", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return moment.tz.guess();
    }
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "reportId", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "machineId", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "source", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "updateQueryParam", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "updateQueryParam"), _class.prototype)), _class));
  _exports.default = SummaryReportsController;
});