define("alliance-business-suite/utils/show-features", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.showMachineFeature = void 0;
  _exports.showStepFeature = showStepFeature;

  // Check if this component should be displayed for this step
  function showStepFeature(_ref) {
    let {
      showComponent = true,
      stepType,
      ifStep,
      notStep
    } = _ref;
    if (showComponent === false) return false;

    if (showComponent && stepType) {
      const ifArray = typeof ifStep === "string" ? ifStep.split(" ") : ifStep;
      const notArray = typeof notStep === "string" ? notStep.split(" ") : notStep;

      if (ifStep !== undefined) {
        if (ifArray.includes(stepType)) {
          showComponent = true;
        } else {
          showComponent = false;
        }
      }

      if (notStep !== undefined) {
        if (notArray.includes(stepType)) {
          showComponent = false;
        } else {
          showComponent = true;
        }
      }
    }

    return showComponent;
  }

  const showMachineFeature = function (_ref2) {
    let {
      showComponent = true,
      machineProgram,
      ifMachine,
      notMachine
    } = _ref2;

    /*
    Use it like this:
     {{#if (show-machine ifMachine=this.ifMachine machineProgram=this.machineProgram)}}
     Show the item when an ifMachine attribute matches this machine. Otherwise, hide it.
    Hide the item when a notMachine attribute matches this machine. Otherwise, show it.
     We go with the most restrictive results of comparing ifMachine first, then notMachine. So if we're looking at TITAN_T2_FLW...
     ifMachine="FLW" returns true - matched, so show it
    ifMachine="WX" returns false - no match, so hide it
    ifMachine="" returns false - no match, so hide it
     notMachine="FLW" returns false - matched, so hide it
    notMachine="WX" returns true - no match, so show it
    notMachine="" returns true - no match, so show it
     */
    if (showComponent === false) return false; // We must have something to check

    if (ifMachine === undefined && notMachine === undefined) {
      return showComponent;
    } // We must have a machineProgram object to check against or we return `true`.


    if (machineProgram === undefined) {
      return showComponent;
    } // We always compare array elements to be sure we don't match partial strings


    let ifCheck = Ember.typeOf(ifMachine) === "string" ? ifMachine.split(" ") : ifMachine;
    let unlessCheck = Ember.typeOf(notMachine) === "string" ? notMachine.split(" ") : notMachine;
    let hasIf = Array.isArray(ifCheck) && !!ifCheck.length;
    let hasUnless = Array.isArray(unlessCheck) && !!unlessCheck.length; // Many components are wrapped with showMachine but don't have if/unless values to check for. In this case, we immediately return `true`.

    if (!hasIf && !hasUnless) {
      return true;
    } // Everything has been moved to an array of attributes


    let attributes = machineProgram._machineAttributes;
    let hasUnlessCondition = false,
        hasIfCondition = false; // Check each attribute against the "if" and "not" params that were provided.

    attributes.forEach(attribute => {
      if (hasUnless && unlessCheck.includes(attribute)) {
        hasUnlessCondition = true;
      }

      if (hasIf && ifCheck.includes(attribute)) {
        hasIfCondition = true;
      }
    }); // Check conditions and return the appropriate value

    if (hasUnlessCondition && hasIfCondition) {
      // Both conditions met. This shouldn't happen.
      console.warn("Show-machine matched if and not. Enforcing most restrictive setting.", ifCheck, unlessCheck);
      return false; // go with more restrictive setting
    }

    if (hasUnlessCondition) return false; // Always hide an explicit "unless" match first.

    if (hasIfCondition) return true; // Show an explicit "if" match.

    if (hasIf) return false; // Hide when we don't match any "if" criteria.

    if (hasUnless) return true; // Show when we don't match any "not" criteria.

    return false; // We should never get to this line.
  };

  _exports.showMachineFeature = showMachineFeature;
});