define("alliance-business-suite/components/form-ui/button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'button',
    // classNames: ['form-field', 'overflow-float'],
    classNames: ['button'],
    attributeBindings: ['disabled'],
    intl: Ember.inject.service(),
    classNameBindings: ['buttonTypeClass'],
    buttonTypeClass: Ember.computed('type', function () {
      switch (this.type) {
        case 'delete':
        case 'danger':
          return 'button--danger';

        case 'cancel':
        case 'secondary':
          return 'button--secondary';

        default:
          return 'primary';
      }
    }),
    notifications: Ember.inject.service(),

    getLabel(labelKey) {
      // Return localized labelKey or the defaultLabel
      let label = Ember.get(this, labelKey);

      if (!Ember.isEmpty(label)) {
        return this.intl.t(label);
      } else {
        return this.defaultLabel;
      }
    },

    pendingLabel: Ember.computed('pendingText', function () {
      return this.intl.t(this.pendingText);
    }),
    successLabel: Ember.computed('successText', function () {
      return this.getLabel('successText');
    }),
    errorLabel: Ember.computed('errorText', function () {
      return this.getLabel('errorText');
    }),
    defaultLabel: Ember.computed('defaultText', function () {
      return this.intl.t(this.defaultText);
    }),
    disabled: Ember.computed('activePromise.isPending', function () {
      return Ember.get(this, 'activePromise.isPending');
    }),
    notificationObserver: Ember.observer('activePromise.{isFulfilled,isRejected}', function () {
      if (!Ember.isNone(this.notifySuccess) && Ember.get(this, 'activePromise.isFulfilled')) {
        let message = this.intl.t(this.notifySuccess);
        this.notifications.success(message, {
          autoClear: true,
          clearDuration: 2000,
          cssClasses: 'quick-notification'
        });
      }

      if (this.fulfilled && Ember.get(this, 'activePromise.isFulfilled')) {
        this.fulfilled();
      }

      if (!Ember.isNone(this.notifyError) && Ember.get(this, 'activePromise.isRejected')) {
        let message = this.intl.t(this.notifyError);
        this.notifications.error(message, {
          autoClear: false,
          cssClasses: 'quick-notification'
        });
      }

      if (this.rejected && Ember.get(this, 'activePromise.isRejected')) {
        this.rejected();
      }
    }),

    click() {
      let activePromise = this.clickAction();
      Ember.set(this, 'activePromise', activePromise);
    }

  });

  _exports.default = _default;
});