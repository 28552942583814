define("alliance-business-suite/components/dashboard/charts/drilldown/drilldown-legend", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <ul
    {{did-insert this.initialLoad}}
    {{did-update this.updateLegend @chart @isDrilldown}}
    class="drilldown-legend--container">
      {{#each this.legendData as |data|}}
        <li>
          <span
            class="color"
            style="{{safe-string data.color "simple" "background-color:"}}">
          </span>
          {{data.name}}
        </li>
      {{/each}}
  </ul>
  
  */
  {
    "id": "T+Ro87nD",
    "block": "{\"symbols\":[\"data\",\"@isDrilldown\",\"@chart\"],\"statements\":[[11,\"ul\"],[24,0,\"drilldown-legend--container\"],[4,[38,1],[[32,0,[\"initialLoad\"]]],null],[4,[38,2],[[32,0,[\"updateLegend\"]],[32,3],[32,2]],null],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,0,[\"legendData\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"li\"],[12],[2,\"\\n        \"],[10,\"span\"],[14,0,\"color\"],[15,5,[30,[36,0],[[32,1,[\"color\"]],\"simple\",\"background-color:\"],null]],[12],[2,\"\\n        \"],[13],[2,\"\\n        \"],[1,[32,1,[\"name\"]]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"safe-string\",\"did-insert\",\"did-update\",\"-track-array\",\"each\"]}",
    "moduleName": "alliance-business-suite/components/dashboard/charts/drilldown/drilldown-legend.hbs"
  });

  let DashboardChartsDrilldownDrilldownLegendComponent = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, (_class = class DashboardChartsDrilldownDrilldownLegendComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "legendData", _descriptor, this);
    }

    initialLoad() {
      if (this.chart) {
        this.buildLegend();
      }
    }

    updateLegend() {
      this.buildLegend();
    } // @action
    // destroyChart() {
    //   console.log('calling destroy', this.args.chart)
    //
    //   if (this.args.chart) {
    //     this.args.chart.destroy();
    //   }
    // }


    buildLegend() {
      let isDrilldown = this.args.isDrilldown;
      let customLegend = this.args.customLegend;
      let drillDownData = this.args.drillDownData;

      if (!isDrilldown) {
        this.legendData = this.args.defaultLegend;
      } else if (customLegend && drillDownData) {
        this.legendData = customLegend(drillDownData);
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "legendData", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "initialLoad", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "initialLoad"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "updateLegend", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "updateLegend"), _class.prototype)), _class));
  _exports.default = DashboardChartsDrilldownDrilldownLegendComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, DashboardChartsDrilldownDrilldownLegendComponent);
});