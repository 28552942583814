define("alliance-business-suite/validators/login-section", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    // The label is the intl key that gets added to the default validation message
    // THIS IS FOR MULTIPLE VALIDATIONS:
    // identification: [
    //   validatePresence({ presence: true, ignoreBlank: true, label: 'email' }),
    //   validateFormat({ type: 'email', label: 'email' })
    // ],
    identification: (0, _validators.validateFormat)({
      type: 'email',
      label: 'email'
    }),
    password: (0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true,
      label: 'password'
    })
  };
  _exports.default = _default;
});