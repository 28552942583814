define("alliance-business-suite/utils/demo-traceability", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.roomMockData = _exports.chartsMockData = void 0;
  const roomMockData = [{
    id: 1,
    isDryer: false,
    machineName: '12',
    machineType: 'Washer Extractor 20lb',
    washProcess: [{
      id: 'timestamp-999',
      name: '02/01/2021 11:42',
      status: 'success'
    }, {
      id: 'timestamp-9dn',
      name: '01/30/2021 12:01',
      status: 'success'
    }, {
      id: 'timestamp-998',
      name: '01/26/2021 08:11',
      status: 'failed'
    }, {
      id: 'timestamp-997',
      name: '01/25/2021 15:42',
      status: 'canceled'
    }]
  }, {
    id: 2,
    machineName: '23',
    isDryer: false,
    machineType: 'Washer Extractor 20lb',
    washProcess: [{
      id: 'timestamp-avfx',
      name: '01/06/2021 11:42',
      status: 'success'
    }, {
      id: 'timestamp-fin',
      name: '01/06/2021 11:42',
      status: 'failed'
    }, {
      id: 'timestamp-mw99',
      name: '01/06/2021 11:42',
      status: 'canceled'
    }]
  }, {
    id: 3,
    machineName: '29',
    isDryer: false,
    machineType: 'Washer Extractor 20lb',
    washProcess: [{
      id: 'timestamp-sbs2',
      name: '01/06/2021 11:42'
    }]
  }];
  _exports.roomMockData = roomMockData;
  const chartsMockData = {
    general: {
      machineType: 'R16',
      idCode: '1_7',
      cyclesStart: 7,
      cyclesEnd: 7,
      timeStart: '13:06:58',
      timeEnd: '13:08:57',
      totalTime: '00:01:59',
      washProcessResult: 'Success'
    },
    "data": [{
      "ID": 38,
      "TimeRemaining": 2641,
      "Temperature": 90,
      "FanSpeed": 3503,
      "DriveSpeed": 63939
    }, {
      "ID": 39,
      "TimeRemaining": 2639,
      "Temperature": 91,
      "FanSpeed": 3497,
      "DriveSpeed": 63965
    }, {
      "ID": 40,
      "TimeRemaining": 2637,
      "Temperature": 92,
      "FanSpeed": 3499,
      "DriveSpeed": 63973
    }, {
      "ID": 41,
      "TimeRemaining": 2635,
      "Temperature": 93,
      "FanSpeed": 3502,
      "DriveSpeed": 63976
    }, {
      "ID": 42,
      "TimeRemaining": 2633,
      "Temperature": 93,
      "FanSpeed": 3498,
      "DriveSpeed": 63966
    }, {
      "ID": 43,
      "TimeRemaining": 2631,
      "Temperature": 95,
      "FanSpeed": 3501,
      "DriveSpeed": 0
    }, {
      "ID": 44,
      "TimeRemaining": 2629,
      "Temperature": 95,
      "FanSpeed": 3502,
      "DriveSpeed": 0
    }, {
      "ID": 45,
      "TimeRemaining": 2627,
      "Temperature": 96,
      "FanSpeed": 3501,
      "DriveSpeed": 0
    }, {
      "ID": 46,
      "TimeRemaining": 2625,
      "Temperature": 98,
      "FanSpeed": 3500,
      "DriveSpeed": 469
    }, {
      "ID": 47,
      "TimeRemaining": 2623,
      "Temperature": 100,
      "FanSpeed": 3495,
      "DriveSpeed": 1586
    }, {
      "ID": 48,
      "TimeRemaining": 2621,
      "Temperature": 102,
      "FanSpeed": 3495,
      "DriveSpeed": 1577
    }, {
      "ID": 49,
      "TimeRemaining": 2619,
      "Temperature": 102,
      "FanSpeed": 3497,
      "DriveSpeed": 1577
    }, {
      "ID": 50,
      "TimeRemaining": 2617,
      "Temperature": 103,
      "FanSpeed": 3502,
      "DriveSpeed": 1576
    }, {
      "ID": 51,
      "TimeRemaining": 2615,
      "Temperature": 103,
      "FanSpeed": 3497,
      "DriveSpeed": 1560
    }, {
      "ID": 52,
      "TimeRemaining": 2613,
      "Temperature": 105,
      "FanSpeed": 3503,
      "DriveSpeed": 1565
    }, {
      "ID": 53,
      "TimeRemaining": 2611,
      "Temperature": 106,
      "FanSpeed": 3502,
      "DriveSpeed": 1579
    }, {
      "ID": 54,
      "TimeRemaining": 2609,
      "Temperature": 106,
      "FanSpeed": 3499,
      "DriveSpeed": 1576
    }, {
      "ID": 55,
      "TimeRemaining": 2607,
      "Temperature": 107,
      "FanSpeed": 3498,
      "DriveSpeed": 1572
    }, {
      "ID": 56,
      "TimeRemaining": 2605,
      "Temperature": 107,
      "FanSpeed": 3498,
      "DriveSpeed": 1564
    }, {
      "ID": 57,
      "TimeRemaining": 2603,
      "Temperature": 107,
      "FanSpeed": 3498,
      "DriveSpeed": 1577
    }, {
      "ID": 58,
      "TimeRemaining": 2601,
      "Temperature": 107,
      "FanSpeed": 3499,
      "DriveSpeed": 1563
    }, {
      "ID": 59,
      "TimeRemaining": 2599,
      "Temperature": 108,
      "FanSpeed": 3495,
      "DriveSpeed": 1576
    }, {
      "ID": 60,
      "TimeRemaining": 2597,
      "Temperature": 108,
      "FanSpeed": 3496,
      "DriveSpeed": 1563
    }, {
      "ID": 61,
      "TimeRemaining": 2595,
      "Temperature": 108,
      "FanSpeed": 3502,
      "DriveSpeed": 0
    }, {
      "ID": 62,
      "TimeRemaining": 2593,
      "Temperature": 110,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 63,
      "TimeRemaining": 2591,
      "Temperature": 111,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 64,
      "TimeRemaining": 2589,
      "Temperature": 111,
      "FanSpeed": 3499,
      "DriveSpeed": 65083
    }, {
      "ID": 65,
      "TimeRemaining": 2587,
      "Temperature": 112,
      "FanSpeed": 3495,
      "DriveSpeed": 63965
    }, {
      "ID": 66,
      "TimeRemaining": 2585,
      "Temperature": 113,
      "FanSpeed": 3497,
      "DriveSpeed": 63965
    }, {
      "ID": 67,
      "TimeRemaining": 2583,
      "Temperature": 115,
      "FanSpeed": 3498,
      "DriveSpeed": 63958
    }, {
      "ID": 68,
      "TimeRemaining": 2581,
      "Temperature": 115,
      "FanSpeed": 3499,
      "DriveSpeed": 63966
    }, {
      "ID": 69,
      "TimeRemaining": 2579,
      "Temperature": 115,
      "FanSpeed": 3496,
      "DriveSpeed": 63972
    }, {
      "ID": 70,
      "TimeRemaining": 2577,
      "Temperature": 115,
      "FanSpeed": 3501,
      "DriveSpeed": 63955
    }, {
      "ID": 71,
      "TimeRemaining": 2575,
      "Temperature": 116,
      "FanSpeed": 3501,
      "DriveSpeed": 63961
    }, {
      "ID": 72,
      "TimeRemaining": 2573,
      "Temperature": 116,
      "FanSpeed": 3498,
      "DriveSpeed": 63970
    }, {
      "ID": 73,
      "TimeRemaining": 2571,
      "Temperature": 117,
      "FanSpeed": 3501,
      "DriveSpeed": 63965
    }, {
      "ID": 74,
      "TimeRemaining": 2569,
      "Temperature": 117,
      "FanSpeed": 3504,
      "DriveSpeed": 63971
    }, {
      "ID": 75,
      "TimeRemaining": 2567,
      "Temperature": 118,
      "FanSpeed": 3496,
      "DriveSpeed": 63964
    }, {
      "ID": 76,
      "TimeRemaining": 2565,
      "Temperature": 118,
      "FanSpeed": 3501,
      "DriveSpeed": 63970
    }, {
      "ID": 77,
      "TimeRemaining": 2563,
      "Temperature": 120,
      "FanSpeed": 3500,
      "DriveSpeed": 63982
    }, {
      "ID": 78,
      "TimeRemaining": 2561,
      "Temperature": 120,
      "FanSpeed": 3497,
      "DriveSpeed": 0
    }, {
      "ID": 79,
      "TimeRemaining": 2559,
      "Temperature": 120,
      "FanSpeed": 3502,
      "DriveSpeed": 0
    }, {
      "ID": 80,
      "TimeRemaining": 2557,
      "Temperature": 121,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 81,
      "TimeRemaining": 2555,
      "Temperature": 121,
      "FanSpeed": 3501,
      "DriveSpeed": 0
    }, {
      "ID": 82,
      "TimeRemaining": 2553,
      "Temperature": 123,
      "FanSpeed": 3500,
      "DriveSpeed": 910
    }, {
      "ID": 83,
      "TimeRemaining": 2551,
      "Temperature": 123,
      "FanSpeed": 3499,
      "DriveSpeed": 1567
    }, {
      "ID": 84,
      "TimeRemaining": 2549,
      "Temperature": 125,
      "FanSpeed": 3495,
      "DriveSpeed": 1572
    }, {
      "ID": 85,
      "TimeRemaining": 2547,
      "Temperature": 125,
      "FanSpeed": 3503,
      "DriveSpeed": 1580
    }, {
      "ID": 86,
      "TimeRemaining": 2545,
      "Temperature": 125,
      "FanSpeed": 3501,
      "DriveSpeed": 1567
    }, {
      "ID": 87,
      "TimeRemaining": 2543,
      "Temperature": 126,
      "FanSpeed": 3502,
      "DriveSpeed": 1586
    }, {
      "ID": 88,
      "TimeRemaining": 2541,
      "Temperature": 126,
      "FanSpeed": 3498,
      "DriveSpeed": 1570
    }, {
      "ID": 89,
      "TimeRemaining": 2539,
      "Temperature": 126,
      "FanSpeed": 3498,
      "DriveSpeed": 1562
    }, {
      "ID": 90,
      "TimeRemaining": 2537,
      "Temperature": 126,
      "FanSpeed": 3500,
      "DriveSpeed": 1577
    }, {
      "ID": 91,
      "TimeRemaining": 2535,
      "Temperature": 126,
      "FanSpeed": 3497,
      "DriveSpeed": 1569
    }, {
      "ID": 92,
      "TimeRemaining": 2533,
      "Temperature": 126,
      "FanSpeed": 3496,
      "DriveSpeed": 1564
    }, {
      "ID": 93,
      "TimeRemaining": 2531,
      "Temperature": 126,
      "FanSpeed": 3504,
      "DriveSpeed": 1562
    }, {
      "ID": 94,
      "TimeRemaining": 2529,
      "Temperature": 126,
      "FanSpeed": 3495,
      "DriveSpeed": 1561
    }, {
      "ID": 95,
      "TimeRemaining": 2527,
      "Temperature": 126,
      "FanSpeed": 3496,
      "DriveSpeed": 1568
    }, {
      "ID": 96,
      "TimeRemaining": 2525,
      "Temperature": 126,
      "FanSpeed": 3500,
      "DriveSpeed": 1559
    }, {
      "ID": 97,
      "TimeRemaining": 2523,
      "Temperature": 127,
      "FanSpeed": 3506,
      "DriveSpeed": 0
    }, {
      "ID": 98,
      "TimeRemaining": 2521,
      "Temperature": 127,
      "FanSpeed": 3503,
      "DriveSpeed": 0
    }, {
      "ID": 99,
      "TimeRemaining": 2519,
      "Temperature": 127,
      "FanSpeed": 3501,
      "DriveSpeed": 0
    }, {
      "ID": 100,
      "TimeRemaining": 2517,
      "Temperature": 127,
      "FanSpeed": 3498,
      "DriveSpeed": 64583
    }, {
      "ID": 101,
      "TimeRemaining": 2515,
      "Temperature": 128,
      "FanSpeed": 3495,
      "DriveSpeed": 63967
    }, {
      "ID": 102,
      "TimeRemaining": 2513,
      "Temperature": 128,
      "FanSpeed": 3495,
      "DriveSpeed": 63971
    }, {
      "ID": 103,
      "TimeRemaining": 2511,
      "Temperature": 128,
      "FanSpeed": 3499,
      "DriveSpeed": 63970
    }, {
      "ID": 104,
      "TimeRemaining": 2509,
      "Temperature": 128,
      "FanSpeed": 3500,
      "DriveSpeed": 63969
    }, {
      "ID": 105,
      "TimeRemaining": 2507,
      "Temperature": 128,
      "FanSpeed": 3498,
      "DriveSpeed": 63966
    }, {
      "ID": 106,
      "TimeRemaining": 2505,
      "Temperature": 128,
      "FanSpeed": 3501,
      "DriveSpeed": 63974
    }, {
      "ID": 107,
      "TimeRemaining": 2503,
      "Temperature": 128,
      "FanSpeed": 3502,
      "DriveSpeed": 63967
    }, {
      "ID": 108,
      "TimeRemaining": 2501,
      "Temperature": 128,
      "FanSpeed": 3498,
      "DriveSpeed": 63983
    }, {
      "ID": 109,
      "TimeRemaining": 2499,
      "Temperature": 128,
      "FanSpeed": 3502,
      "DriveSpeed": 63962
    }, {
      "ID": 110,
      "TimeRemaining": 2497,
      "Temperature": 128,
      "FanSpeed": 3504,
      "DriveSpeed": 63968
    }, {
      "ID": 111,
      "TimeRemaining": 2495,
      "Temperature": 128,
      "FanSpeed": 3498,
      "DriveSpeed": 63953
    }, {
      "ID": 112,
      "TimeRemaining": 2493,
      "Temperature": 128,
      "FanSpeed": 3498,
      "DriveSpeed": 63976
    }, {
      "ID": 113,
      "TimeRemaining": 2491,
      "Temperature": 128,
      "FanSpeed": 3498,
      "DriveSpeed": 63976
    }, {
      "ID": 114,
      "TimeRemaining": 2489,
      "Temperature": 128,
      "FanSpeed": 3501,
      "DriveSpeed": 63951
    }, {
      "ID": 115,
      "TimeRemaining": 2487,
      "Temperature": 128,
      "FanSpeed": 3502,
      "DriveSpeed": 0
    }, {
      "ID": 116,
      "TimeRemaining": 2485,
      "Temperature": 128,
      "FanSpeed": 3501,
      "DriveSpeed": 0
    }, {
      "ID": 117,
      "TimeRemaining": 2483,
      "Temperature": 128,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 118,
      "TimeRemaining": 2481,
      "Temperature": 128,
      "FanSpeed": 3499,
      "DriveSpeed": 439
    }, {
      "ID": 119,
      "TimeRemaining": 2479,
      "Temperature": 128,
      "FanSpeed": 3497,
      "DriveSpeed": 1577
    }, {
      "ID": 120,
      "TimeRemaining": 2477,
      "Temperature": 128,
      "FanSpeed": 3496,
      "DriveSpeed": 1560
    }, {
      "ID": 121,
      "TimeRemaining": 2475,
      "Temperature": 129,
      "FanSpeed": 3502,
      "DriveSpeed": 1573
    }, {
      "ID": 122,
      "TimeRemaining": 2473,
      "Temperature": 129,
      "FanSpeed": 3501,
      "DriveSpeed": 1565
    }, {
      "ID": 123,
      "TimeRemaining": 2471,
      "Temperature": 129,
      "FanSpeed": 3499,
      "DriveSpeed": 1575
    }, {
      "ID": 124,
      "TimeRemaining": 2469,
      "Temperature": 129,
      "FanSpeed": 3503,
      "DriveSpeed": 1570
    }, {
      "ID": 125,
      "TimeRemaining": 2467,
      "Temperature": 129,
      "FanSpeed": 3500,
      "DriveSpeed": 1546
    }, {
      "ID": 126,
      "TimeRemaining": 2465,
      "Temperature": 129,
      "FanSpeed": 3501,
      "DriveSpeed": 1571
    }, {
      "ID": 127,
      "TimeRemaining": 2463,
      "Temperature": 129,
      "FanSpeed": 3499,
      "DriveSpeed": 1574
    }, {
      "ID": 128,
      "TimeRemaining": 2461,
      "Temperature": 129,
      "FanSpeed": 3501,
      "DriveSpeed": 1563
    }, {
      "ID": 129,
      "TimeRemaining": 2459,
      "Temperature": 129,
      "FanSpeed": 3503,
      "DriveSpeed": 1559
    }, {
      "ID": 130,
      "TimeRemaining": 2457,
      "Temperature": 129,
      "FanSpeed": 3499,
      "DriveSpeed": 1572
    }, {
      "ID": 131,
      "TimeRemaining": 2455,
      "Temperature": 129,
      "FanSpeed": 3500,
      "DriveSpeed": 1578
    }, {
      "ID": 132,
      "TimeRemaining": 2453,
      "Temperature": 129,
      "FanSpeed": 3501,
      "DriveSpeed": 1557
    }, {
      "ID": 133,
      "TimeRemaining": 2451,
      "Temperature": 129,
      "FanSpeed": 3501,
      "DriveSpeed": 0
    }, {
      "ID": 134,
      "TimeRemaining": 2449,
      "Temperature": 129,
      "FanSpeed": 3501,
      "DriveSpeed": 0
    }, {
      "ID": 135,
      "TimeRemaining": 2447,
      "Temperature": 130,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 136,
      "TimeRemaining": 2445,
      "Temperature": 130,
      "FanSpeed": 3500,
      "DriveSpeed": 65149
    }, {
      "ID": 137,
      "TimeRemaining": 2443,
      "Temperature": 131,
      "FanSpeed": 3495,
      "DriveSpeed": 63958
    }, {
      "ID": 138,
      "TimeRemaining": 2441,
      "Temperature": 132,
      "FanSpeed": 3501,
      "DriveSpeed": 63960
    }, {
      "ID": 139,
      "TimeRemaining": 2439,
      "Temperature": 132,
      "FanSpeed": 3497,
      "DriveSpeed": 63971
    }, {
      "ID": 140,
      "TimeRemaining": 2437,
      "Temperature": 132,
      "FanSpeed": 3501,
      "DriveSpeed": 63959
    }, {
      "ID": 141,
      "TimeRemaining": 2435,
      "Temperature": 132,
      "FanSpeed": 3499,
      "DriveSpeed": 63966
    }, {
      "ID": 142,
      "TimeRemaining": 2433,
      "Temperature": 132,
      "FanSpeed": 3500,
      "DriveSpeed": 63971
    }, {
      "ID": 143,
      "TimeRemaining": 2431,
      "Temperature": 132,
      "FanSpeed": 3497,
      "DriveSpeed": 63962
    }, {
      "ID": 144,
      "TimeRemaining": 2429,
      "Temperature": 132,
      "FanSpeed": 3504,
      "DriveSpeed": 63954
    }, {
      "ID": 145,
      "TimeRemaining": 2427,
      "Temperature": 132,
      "FanSpeed": 3496,
      "DriveSpeed": 63970
    }, {
      "ID": 146,
      "TimeRemaining": 2425,
      "Temperature": 132,
      "FanSpeed": 3498,
      "DriveSpeed": 63969
    }, {
      "ID": 147,
      "TimeRemaining": 2423,
      "Temperature": 132,
      "FanSpeed": 3498,
      "DriveSpeed": 63958
    }, {
      "ID": 148,
      "TimeRemaining": 2421,
      "Temperature": 132,
      "FanSpeed": 3498,
      "DriveSpeed": 63965
    }, {
      "ID": 149,
      "TimeRemaining": 2419,
      "Temperature": 132,
      "FanSpeed": 3498,
      "DriveSpeed": 63951
    }, {
      "ID": 150,
      "TimeRemaining": 2417,
      "Temperature": 132,
      "FanSpeed": 3500,
      "DriveSpeed": 63974
    }, {
      "ID": 151,
      "TimeRemaining": 2415,
      "Temperature": 132,
      "FanSpeed": 3501,
      "DriveSpeed": 0
    }, {
      "ID": 152,
      "TimeRemaining": 2413,
      "Temperature": 132,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 153,
      "TimeRemaining": 2411,
      "Temperature": 132,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 154,
      "TimeRemaining": 2409,
      "Temperature": 133,
      "FanSpeed": 3500,
      "DriveSpeed": 875
    }, {
      "ID": 155,
      "TimeRemaining": 2407,
      "Temperature": 134,
      "FanSpeed": 3497,
      "DriveSpeed": 1572
    }, {
      "ID": 156,
      "TimeRemaining": 2405,
      "Temperature": 134,
      "FanSpeed": 3494,
      "DriveSpeed": 1569
    }, {
      "ID": 157,
      "TimeRemaining": 2403,
      "Temperature": 135,
      "FanSpeed": 3500,
      "DriveSpeed": 1565
    }, {
      "ID": 158,
      "TimeRemaining": 2401,
      "Temperature": 135,
      "FanSpeed": 3502,
      "DriveSpeed": 1567
    }, {
      "ID": 159,
      "TimeRemaining": 2399,
      "Temperature": 134,
      "FanSpeed": 3498,
      "DriveSpeed": 1560
    }, {
      "ID": 160,
      "TimeRemaining": 2397,
      "Temperature": 135,
      "FanSpeed": 3503,
      "DriveSpeed": 1580
    }, {
      "ID": 161,
      "TimeRemaining": 2395,
      "Temperature": 135,
      "FanSpeed": 3504,
      "DriveSpeed": 1574
    }, {
      "ID": 162,
      "TimeRemaining": 2393,
      "Temperature": 135,
      "FanSpeed": 3495,
      "DriveSpeed": 1581
    }, {
      "ID": 163,
      "TimeRemaining": 2391,
      "Temperature": 135,
      "FanSpeed": 3499,
      "DriveSpeed": 1567
    }, {
      "ID": 164,
      "TimeRemaining": 2389,
      "Temperature": 135,
      "FanSpeed": 3501,
      "DriveSpeed": 1559
    }, {
      "ID": 165,
      "TimeRemaining": 2387,
      "Temperature": 135,
      "FanSpeed": 3499,
      "DriveSpeed": 1562
    }, {
      "ID": 166,
      "TimeRemaining": 2385,
      "Temperature": 135,
      "FanSpeed": 3501,
      "DriveSpeed": 1551
    }, {
      "ID": 167,
      "TimeRemaining": 2383,
      "Temperature": 135,
      "FanSpeed": 3498,
      "DriveSpeed": 1550
    }, {
      "ID": 168,
      "TimeRemaining": 2381,
      "Temperature": 135,
      "FanSpeed": 3506,
      "DriveSpeed": 1580
    }, {
      "ID": 169,
      "TimeRemaining": 2379,
      "Temperature": 136,
      "FanSpeed": 3501,
      "DriveSpeed": 0
    }, {
      "ID": 170,
      "TimeRemaining": 2377,
      "Temperature": 136,
      "FanSpeed": 3501,
      "DriveSpeed": 0
    }, {
      "ID": 171,
      "TimeRemaining": 2375,
      "Temperature": 136,
      "FanSpeed": 3498,
      "DriveSpeed": 0
    }, {
      "ID": 172,
      "TimeRemaining": 2373,
      "Temperature": 136,
      "FanSpeed": 3502,
      "DriveSpeed": 64622
    }, {
      "ID": 173,
      "TimeRemaining": 2371,
      "Temperature": 137,
      "FanSpeed": 3497,
      "DriveSpeed": 63959
    }, {
      "ID": 174,
      "TimeRemaining": 2369,
      "Temperature": 137,
      "FanSpeed": 3501,
      "DriveSpeed": 63954
    }, {
      "ID": 175,
      "TimeRemaining": 2367,
      "Temperature": 138,
      "FanSpeed": 3495,
      "DriveSpeed": 63949
    }, {
      "ID": 176,
      "TimeRemaining": 2365,
      "Temperature": 138,
      "FanSpeed": 3497,
      "DriveSpeed": 63968
    }, {
      "ID": 177,
      "TimeRemaining": 2363,
      "Temperature": 138,
      "FanSpeed": 3500,
      "DriveSpeed": 63960
    }, {
      "ID": 178,
      "TimeRemaining": 2361,
      "Temperature": 138,
      "FanSpeed": 3495,
      "DriveSpeed": 63970
    }, {
      "ID": 179,
      "TimeRemaining": 2359,
      "Temperature": 138,
      "FanSpeed": 3495,
      "DriveSpeed": 63962
    }, {
      "ID": 180,
      "TimeRemaining": 2357,
      "Temperature": 138,
      "FanSpeed": 3502,
      "DriveSpeed": 63956
    }, {
      "ID": 181,
      "TimeRemaining": 2355,
      "Temperature": 138,
      "FanSpeed": 3501,
      "DriveSpeed": 63964
    }, {
      "ID": 182,
      "TimeRemaining": 2353,
      "Temperature": 138,
      "FanSpeed": 3499,
      "DriveSpeed": 63966
    }, {
      "ID": 183,
      "TimeRemaining": 2351,
      "Temperature": 138,
      "FanSpeed": 3499,
      "DriveSpeed": 63973
    }, {
      "ID": 184,
      "TimeRemaining": 2349,
      "Temperature": 138,
      "FanSpeed": 3504,
      "DriveSpeed": 63961
    }, {
      "ID": 185,
      "TimeRemaining": 2347,
      "Temperature": 137,
      "FanSpeed": 3502,
      "DriveSpeed": 63955
    }, {
      "ID": 186,
      "TimeRemaining": 2345,
      "Temperature": 137,
      "FanSpeed": 3497,
      "DriveSpeed": 63968
    }, {
      "ID": 187,
      "TimeRemaining": 2343,
      "Temperature": 137,
      "FanSpeed": 3502,
      "DriveSpeed": 0
    }, {
      "ID": 188,
      "TimeRemaining": 2341,
      "Temperature": 137,
      "FanSpeed": 3499,
      "DriveSpeed": 0
    }, {
      "ID": 189,
      "TimeRemaining": 2339,
      "Temperature": 138,
      "FanSpeed": 3501,
      "DriveSpeed": 0
    }, {
      "ID": 190,
      "TimeRemaining": 2337,
      "Temperature": 138,
      "FanSpeed": 3503,
      "DriveSpeed": 394
    }, {
      "ID": 191,
      "TimeRemaining": 2335,
      "Temperature": 139,
      "FanSpeed": 3500,
      "DriveSpeed": 1584
    }, {
      "ID": 192,
      "TimeRemaining": 2333,
      "Temperature": 140,
      "FanSpeed": 3496,
      "DriveSpeed": 1558
    }, {
      "ID": 193,
      "TimeRemaining": 2331,
      "Temperature": 140,
      "FanSpeed": 3501,
      "DriveSpeed": 1575
    }, {
      "ID": 194,
      "TimeRemaining": 2329,
      "Temperature": 140,
      "FanSpeed": 3503,
      "DriveSpeed": 1562
    }, {
      "ID": 195,
      "TimeRemaining": 2327,
      "Temperature": 140,
      "FanSpeed": 3497,
      "DriveSpeed": 1557
    }, {
      "ID": 196,
      "TimeRemaining": 2325,
      "Temperature": 140,
      "FanSpeed": 3502,
      "DriveSpeed": 1575
    }, {
      "ID": 197,
      "TimeRemaining": 2323,
      "Temperature": 140,
      "FanSpeed": 3501,
      "DriveSpeed": 1558
    }, {
      "ID": 198,
      "TimeRemaining": 2321,
      "Temperature": 140,
      "FanSpeed": 3500,
      "DriveSpeed": 1577
    }, {
      "ID": 199,
      "TimeRemaining": 2319,
      "Temperature": 140,
      "FanSpeed": 3500,
      "DriveSpeed": 1565
    }, {
      "ID": 200,
      "TimeRemaining": 2317,
      "Temperature": 140,
      "FanSpeed": 3503,
      "DriveSpeed": 1559
    }, {
      "ID": 201,
      "TimeRemaining": 2315,
      "Temperature": 140,
      "FanSpeed": 3504,
      "DriveSpeed": 1562
    }, {
      "ID": 202,
      "TimeRemaining": 2313,
      "Temperature": 140,
      "FanSpeed": 3501,
      "DriveSpeed": 1568
    }, {
      "ID": 203,
      "TimeRemaining": 2311,
      "Temperature": 140,
      "FanSpeed": 3501,
      "DriveSpeed": 1558
    }, {
      "ID": 204,
      "TimeRemaining": 2309,
      "Temperature": 140,
      "FanSpeed": 3500,
      "DriveSpeed": 1568
    }, {
      "ID": 205,
      "TimeRemaining": 2307,
      "Temperature": 140,
      "FanSpeed": 3501,
      "DriveSpeed": 0
    }, {
      "ID": 206,
      "TimeRemaining": 2305,
      "Temperature": 140,
      "FanSpeed": 3501,
      "DriveSpeed": 0
    }, {
      "ID": 207,
      "TimeRemaining": 2303,
      "Temperature": 140,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 208,
      "TimeRemaining": 2301,
      "Temperature": 140,
      "FanSpeed": 3500,
      "DriveSpeed": 65136
    }, {
      "ID": 209,
      "TimeRemaining": 2299,
      "Temperature": 140,
      "FanSpeed": 3500,
      "DriveSpeed": 63947
    }, {
      "ID": 210,
      "TimeRemaining": 2297,
      "Temperature": 140,
      "FanSpeed": 3493,
      "DriveSpeed": 63962
    }, {
      "ID": 211,
      "TimeRemaining": 2295,
      "Temperature": 140,
      "FanSpeed": 3501,
      "DriveSpeed": 63966
    }, {
      "ID": 212,
      "TimeRemaining": 2293,
      "Temperature": 141,
      "FanSpeed": 3499,
      "DriveSpeed": 63969
    }, {
      "ID": 213,
      "TimeRemaining": 2291,
      "Temperature": 141,
      "FanSpeed": 3501,
      "DriveSpeed": 63960
    }, {
      "ID": 214,
      "TimeRemaining": 2289,
      "Temperature": 141,
      "FanSpeed": 3501,
      "DriveSpeed": 63935
    }, {
      "ID": 215,
      "TimeRemaining": 2287,
      "Temperature": 141,
      "FanSpeed": 3499,
      "DriveSpeed": 63957
    }, {
      "ID": 216,
      "TimeRemaining": 2285,
      "Temperature": 141,
      "FanSpeed": 3501,
      "DriveSpeed": 63962
    }, {
      "ID": 217,
      "TimeRemaining": 2283,
      "Temperature": 141,
      "FanSpeed": 3499,
      "DriveSpeed": 63977
    }, {
      "ID": 218,
      "TimeRemaining": 2281,
      "Temperature": 140,
      "FanSpeed": 3497,
      "DriveSpeed": 63963
    }, {
      "ID": 219,
      "TimeRemaining": 2279,
      "Temperature": 140,
      "FanSpeed": 3499,
      "DriveSpeed": 63958
    }, {
      "ID": 220,
      "TimeRemaining": 2277,
      "Temperature": 140,
      "FanSpeed": 3496,
      "DriveSpeed": 63965
    }, {
      "ID": 221,
      "TimeRemaining": 2275,
      "Temperature": 140,
      "FanSpeed": 3497,
      "DriveSpeed": 63964
    }, {
      "ID": 222,
      "TimeRemaining": 2273,
      "Temperature": 140,
      "FanSpeed": 3501,
      "DriveSpeed": 63968
    }, {
      "ID": 223,
      "TimeRemaining": 2271,
      "Temperature": 140,
      "FanSpeed": 3503,
      "DriveSpeed": 0
    }, {
      "ID": 224,
      "TimeRemaining": 2269,
      "Temperature": 140,
      "FanSpeed": 3501,
      "DriveSpeed": 0
    }, {
      "ID": 225,
      "TimeRemaining": 2267,
      "Temperature": 140,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 226,
      "TimeRemaining": 2265,
      "Temperature": 140,
      "FanSpeed": 3500,
      "DriveSpeed": 880
    }, {
      "ID": 227,
      "TimeRemaining": 2263,
      "Temperature": 141,
      "FanSpeed": 3499,
      "DriveSpeed": 1577
    }, {
      "ID": 228,
      "TimeRemaining": 2261,
      "Temperature": 141,
      "FanSpeed": 3496,
      "DriveSpeed": 1559
    }, {
      "ID": 229,
      "TimeRemaining": 2259,
      "Temperature": 142,
      "FanSpeed": 3502,
      "DriveSpeed": 1564
    }, {
      "ID": 230,
      "TimeRemaining": 2257,
      "Temperature": 142,
      "FanSpeed": 3502,
      "DriveSpeed": 1590
    }, {
      "ID": 231,
      "TimeRemaining": 2255,
      "Temperature": 142,
      "FanSpeed": 3497,
      "DriveSpeed": 1564
    }, {
      "ID": 232,
      "TimeRemaining": 2253,
      "Temperature": 141,
      "FanSpeed": 3499,
      "DriveSpeed": 1558
    }, {
      "ID": 233,
      "TimeRemaining": 2251,
      "Temperature": 141,
      "FanSpeed": 3495,
      "DriveSpeed": 1572
    }, {
      "ID": 234,
      "TimeRemaining": 2249,
      "Temperature": 141,
      "FanSpeed": 3501,
      "DriveSpeed": 1574
    }, {
      "ID": 235,
      "TimeRemaining": 2247,
      "Temperature": 141,
      "FanSpeed": 3496,
      "DriveSpeed": 1563
    }, {
      "ID": 236,
      "TimeRemaining": 2245,
      "Temperature": 141,
      "FanSpeed": 3497,
      "DriveSpeed": 1573
    }, {
      "ID": 237,
      "TimeRemaining": 2243,
      "Temperature": 141,
      "FanSpeed": 3505,
      "DriveSpeed": 1574
    }, {
      "ID": 238,
      "TimeRemaining": 2241,
      "Temperature": 141,
      "FanSpeed": 3495,
      "DriveSpeed": 1569
    }, {
      "ID": 239,
      "TimeRemaining": 2239,
      "Temperature": 141,
      "FanSpeed": 3495,
      "DriveSpeed": 1570
    }, {
      "ID": 240,
      "TimeRemaining": 2237,
      "Temperature": 141,
      "FanSpeed": 3504,
      "DriveSpeed": 1582
    }, {
      "ID": 241,
      "TimeRemaining": 2235,
      "Temperature": 141,
      "FanSpeed": 3501,
      "DriveSpeed": 0
    }, {
      "ID": 242,
      "TimeRemaining": 2233,
      "Temperature": 141,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 243,
      "TimeRemaining": 2231,
      "Temperature": 142,
      "FanSpeed": 3498,
      "DriveSpeed": 0
    }, {
      "ID": 244,
      "TimeRemaining": 2229,
      "Temperature": 142,
      "FanSpeed": 3497,
      "DriveSpeed": 65108
    }, {
      "ID": 245,
      "TimeRemaining": 2227,
      "Temperature": 142,
      "FanSpeed": 3499,
      "DriveSpeed": 63963
    }, {
      "ID": 246,
      "TimeRemaining": 2225,
      "Temperature": 143,
      "FanSpeed": 3498,
      "DriveSpeed": 63969
    }, {
      "ID": 247,
      "TimeRemaining": 2223,
      "Temperature": 143,
      "FanSpeed": 3501,
      "DriveSpeed": 63967
    }, {
      "ID": 248,
      "TimeRemaining": 2221,
      "Temperature": 143,
      "FanSpeed": 3501,
      "DriveSpeed": 63964
    }, {
      "ID": 249,
      "TimeRemaining": 2219,
      "Temperature": 143,
      "FanSpeed": 3499,
      "DriveSpeed": 63968
    }, {
      "ID": 250,
      "TimeRemaining": 2217,
      "Temperature": 142,
      "FanSpeed": 3500,
      "DriveSpeed": 63970
    }, {
      "ID": 251,
      "TimeRemaining": 2215,
      "Temperature": 142,
      "FanSpeed": 3501,
      "DriveSpeed": 63969
    }, {
      "ID": 252,
      "TimeRemaining": 2213,
      "Temperature": 142,
      "FanSpeed": 3496,
      "DriveSpeed": 63964
    }, {
      "ID": 253,
      "TimeRemaining": 2211,
      "Temperature": 142,
      "FanSpeed": 3504,
      "DriveSpeed": 63958
    }, {
      "ID": 254,
      "TimeRemaining": 2209,
      "Temperature": 142,
      "FanSpeed": 3493,
      "DriveSpeed": 63955
    }, {
      "ID": 255,
      "TimeRemaining": 2207,
      "Temperature": 142,
      "FanSpeed": 3496,
      "DriveSpeed": 63979
    }, {
      "ID": 0,
      "TimeRemaining": 2205,
      "Temperature": 143,
      "FanSpeed": 3502,
      "DriveSpeed": 63975
    }, {
      "ID": 1,
      "TimeRemaining": 2203,
      "Temperature": 143,
      "FanSpeed": 3495,
      "DriveSpeed": 63952
    }, {
      "ID": 2,
      "TimeRemaining": 2201,
      "Temperature": 143,
      "FanSpeed": 3494,
      "DriveSpeed": 63964
    }, {
      "ID": 3,
      "TimeRemaining": 2199,
      "Temperature": 143,
      "FanSpeed": 3502,
      "DriveSpeed": 0
    }, {
      "ID": 4,
      "TimeRemaining": 2197,
      "Temperature": 143,
      "FanSpeed": 3502,
      "DriveSpeed": 0
    }, {
      "ID": 5,
      "TimeRemaining": 2195,
      "Temperature": 144,
      "FanSpeed": 3501,
      "DriveSpeed": 0
    }, {
      "ID": 6,
      "TimeRemaining": 2193,
      "Temperature": 144,
      "FanSpeed": 3499,
      "DriveSpeed": 404
    }, {
      "ID": 7,
      "TimeRemaining": 2191,
      "Temperature": 144,
      "FanSpeed": 3495,
      "DriveSpeed": 1582
    }, {
      "ID": 8,
      "TimeRemaining": 2189,
      "Temperature": 145,
      "FanSpeed": 3499,
      "DriveSpeed": 1570
    }, {
      "ID": 9,
      "TimeRemaining": 2187,
      "Temperature": 145,
      "FanSpeed": 3496,
      "DriveSpeed": 1571
    }, {
      "ID": 10,
      "TimeRemaining": 2185,
      "Temperature": 145,
      "FanSpeed": 3500,
      "DriveSpeed": 1573
    }, {
      "ID": 11,
      "TimeRemaining": 2183,
      "Temperature": 144,
      "FanSpeed": 3502,
      "DriveSpeed": 1567
    }, {
      "ID": 12,
      "TimeRemaining": 2181,
      "Temperature": 144,
      "FanSpeed": 3499,
      "DriveSpeed": 1576
    }, {
      "ID": 13,
      "TimeRemaining": 2179,
      "Temperature": 143,
      "FanSpeed": 3499,
      "DriveSpeed": 1567
    }, {
      "ID": 14,
      "TimeRemaining": 2177,
      "Temperature": 143,
      "FanSpeed": 3501,
      "DriveSpeed": 1567
    }, {
      "ID": 15,
      "TimeRemaining": 2175,
      "Temperature": 143,
      "FanSpeed": 3504,
      "DriveSpeed": 1569
    }, {
      "ID": 16,
      "TimeRemaining": 2173,
      "Temperature": 144,
      "FanSpeed": 3501,
      "DriveSpeed": 1568
    }, {
      "ID": 17,
      "TimeRemaining": 2171,
      "Temperature": 144,
      "FanSpeed": 3500,
      "DriveSpeed": 1563
    }, {
      "ID": 18,
      "TimeRemaining": 2169,
      "Temperature": 144,
      "FanSpeed": 3499,
      "DriveSpeed": 1568
    }, {
      "ID": 19,
      "TimeRemaining": 2167,
      "Temperature": 144,
      "FanSpeed": 3502,
      "DriveSpeed": 1563
    }, {
      "ID": 20,
      "TimeRemaining": 2165,
      "Temperature": 144,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 21,
      "TimeRemaining": 2163,
      "Temperature": 144,
      "FanSpeed": 3503,
      "DriveSpeed": 0
    }, {
      "ID": 22,
      "TimeRemaining": 2161,
      "Temperature": 144,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 23,
      "TimeRemaining": 2159,
      "Temperature": 144,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 24,
      "TimeRemaining": 2157,
      "Temperature": 145,
      "FanSpeed": 3500,
      "DriveSpeed": 64577
    }, {
      "ID": 25,
      "TimeRemaining": 2155,
      "Temperature": 145,
      "FanSpeed": 3498,
      "DriveSpeed": 63968
    }, {
      "ID": 26,
      "TimeRemaining": 2153,
      "Temperature": 146,
      "FanSpeed": 3498,
      "DriveSpeed": 63956
    }, {
      "ID": 27,
      "TimeRemaining": 2151,
      "Temperature": 146,
      "FanSpeed": 3497,
      "DriveSpeed": 63949
    }, {
      "ID": 28,
      "TimeRemaining": 2149,
      "Temperature": 146,
      "FanSpeed": 3497,
      "DriveSpeed": 63963
    }, {
      "ID": 29,
      "TimeRemaining": 2147,
      "Temperature": 146,
      "FanSpeed": 3500,
      "DriveSpeed": 63971
    }, {
      "ID": 30,
      "TimeRemaining": 2145,
      "Temperature": 145,
      "FanSpeed": 3497,
      "DriveSpeed": 63970
    }, {
      "ID": 31,
      "TimeRemaining": 2143,
      "Temperature": 145,
      "FanSpeed": 3496,
      "DriveSpeed": 63966
    }, {
      "ID": 32,
      "TimeRemaining": 2141,
      "Temperature": 145,
      "FanSpeed": 3496,
      "DriveSpeed": 63966
    }, {
      "ID": 33,
      "TimeRemaining": 2139,
      "Temperature": 145,
      "FanSpeed": 3497,
      "DriveSpeed": 63967
    }, {
      "ID": 34,
      "TimeRemaining": 2137,
      "Temperature": 146,
      "FanSpeed": 3501,
      "DriveSpeed": 63966
    }, {
      "ID": 35,
      "TimeRemaining": 2135,
      "Temperature": 146,
      "FanSpeed": 3495,
      "DriveSpeed": 63978
    }, {
      "ID": 36,
      "TimeRemaining": 2133,
      "Temperature": 146,
      "FanSpeed": 3503,
      "DriveSpeed": 63961
    }, {
      "ID": 37,
      "TimeRemaining": 2131,
      "Temperature": 146,
      "FanSpeed": 3502,
      "DriveSpeed": 63965
    }, {
      "ID": 38,
      "TimeRemaining": 2129,
      "Temperature": 146,
      "FanSpeed": 3499,
      "DriveSpeed": 63959
    }, {
      "ID": 39,
      "TimeRemaining": 2127,
      "Temperature": 145,
      "FanSpeed": 3501,
      "DriveSpeed": 0
    }, {
      "ID": 40,
      "TimeRemaining": 2125,
      "Temperature": 145,
      "FanSpeed": 3502,
      "DriveSpeed": 0
    }, {
      "ID": 41,
      "TimeRemaining": 2123,
      "Temperature": 146,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 42,
      "TimeRemaining": 2121,
      "Temperature": 147,
      "FanSpeed": 3501,
      "DriveSpeed": 839
    }, {
      "ID": 43,
      "TimeRemaining": 2119,
      "Temperature": 148,
      "FanSpeed": 3496,
      "DriveSpeed": 1585
    }, {
      "ID": 44,
      "TimeRemaining": 2117,
      "Temperature": 148,
      "FanSpeed": 3496,
      "DriveSpeed": 1569
    }, {
      "ID": 45,
      "TimeRemaining": 2115,
      "Temperature": 148,
      "FanSpeed": 3502,
      "DriveSpeed": 1582
    }, {
      "ID": 46,
      "TimeRemaining": 2113,
      "Temperature": 148,
      "FanSpeed": 3502,
      "DriveSpeed": 1568
    }, {
      "ID": 47,
      "TimeRemaining": 2111,
      "Temperature": 148,
      "FanSpeed": 3502,
      "DriveSpeed": 1577
    }, {
      "ID": 48,
      "TimeRemaining": 2109,
      "Temperature": 148,
      "FanSpeed": 3500,
      "DriveSpeed": 1571
    }, {
      "ID": 49,
      "TimeRemaining": 2107,
      "Temperature": 147,
      "FanSpeed": 3497,
      "DriveSpeed": 1568
    }, {
      "ID": 50,
      "TimeRemaining": 2105,
      "Temperature": 147,
      "FanSpeed": 3503,
      "DriveSpeed": 1587
    }, {
      "ID": 51,
      "TimeRemaining": 2103,
      "Temperature": 147,
      "FanSpeed": 3496,
      "DriveSpeed": 1569
    }, {
      "ID": 52,
      "TimeRemaining": 2101,
      "Temperature": 147,
      "FanSpeed": 3494,
      "DriveSpeed": 1572
    }, {
      "ID": 53,
      "TimeRemaining": 2099,
      "Temperature": 147,
      "FanSpeed": 3500,
      "DriveSpeed": 1566
    }, {
      "ID": 54,
      "TimeRemaining": 2097,
      "Temperature": 147,
      "FanSpeed": 3501,
      "DriveSpeed": 1570
    }, {
      "ID": 55,
      "TimeRemaining": 2095,
      "Temperature": 147,
      "FanSpeed": 3496,
      "DriveSpeed": 1571
    }, {
      "ID": 56,
      "TimeRemaining": 2093,
      "Temperature": 147,
      "FanSpeed": 3501,
      "DriveSpeed": 0
    }, {
      "ID": 57,
      "TimeRemaining": 2091,
      "Temperature": 147,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 58,
      "TimeRemaining": 2089,
      "Temperature": 147,
      "FanSpeed": 3501,
      "DriveSpeed": 0
    }, {
      "ID": 59,
      "TimeRemaining": 2087,
      "Temperature": 147,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 60,
      "TimeRemaining": 2085,
      "Temperature": 147,
      "FanSpeed": 3501,
      "DriveSpeed": 65048
    }, {
      "ID": 61,
      "TimeRemaining": 2083,
      "Temperature": 147,
      "FanSpeed": 3501,
      "DriveSpeed": 63958
    }, {
      "ID": 62,
      "TimeRemaining": 2081,
      "Temperature": 148,
      "FanSpeed": 3493,
      "DriveSpeed": 63962
    }, {
      "ID": 63,
      "TimeRemaining": 2079,
      "Temperature": 148,
      "FanSpeed": 3503,
      "DriveSpeed": 63964
    }, {
      "ID": 64,
      "TimeRemaining": 2077,
      "Temperature": 148,
      "FanSpeed": 3503,
      "DriveSpeed": 63963
    }, {
      "ID": 65,
      "TimeRemaining": 2075,
      "Temperature": 148,
      "FanSpeed": 3498,
      "DriveSpeed": 63973
    }, {
      "ID": 66,
      "TimeRemaining": 2073,
      "Temperature": 148,
      "FanSpeed": 3501,
      "DriveSpeed": 63972
    }, {
      "ID": 67,
      "TimeRemaining": 2071,
      "Temperature": 148,
      "FanSpeed": 3501,
      "DriveSpeed": 63964
    }, {
      "ID": 68,
      "TimeRemaining": 2069,
      "Temperature": 147,
      "FanSpeed": 3499,
      "DriveSpeed": 63961
    }, {
      "ID": 69,
      "TimeRemaining": 2067,
      "Temperature": 147,
      "FanSpeed": 3504,
      "DriveSpeed": 63963
    }, {
      "ID": 70,
      "TimeRemaining": 2065,
      "Temperature": 147,
      "FanSpeed": 3499,
      "DriveSpeed": 63963
    }, {
      "ID": 71,
      "TimeRemaining": 2063,
      "Temperature": 147,
      "FanSpeed": 3500,
      "DriveSpeed": 63961
    }, {
      "ID": 72,
      "TimeRemaining": 2061,
      "Temperature": 147,
      "FanSpeed": 3502,
      "DriveSpeed": 63948
    }, {
      "ID": 73,
      "TimeRemaining": 2059,
      "Temperature": 147,
      "FanSpeed": 3499,
      "DriveSpeed": 63974
    }, {
      "ID": 74,
      "TimeRemaining": 2057,
      "Temperature": 147,
      "FanSpeed": 3501,
      "DriveSpeed": 0
    }, {
      "ID": 75,
      "TimeRemaining": 2055,
      "Temperature": 147,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 76,
      "TimeRemaining": 2053,
      "Temperature": 147,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 77,
      "TimeRemaining": 2051,
      "Temperature": 147,
      "FanSpeed": 3502,
      "DriveSpeed": 0
    }, {
      "ID": 78,
      "TimeRemaining": 2049,
      "Temperature": 147,
      "FanSpeed": 3502,
      "DriveSpeed": 409
    }, {
      "ID": 79,
      "TimeRemaining": 2047,
      "Temperature": 148,
      "FanSpeed": 3498,
      "DriveSpeed": 1591
    }, {
      "ID": 80,
      "TimeRemaining": 2045,
      "Temperature": 149,
      "FanSpeed": 3498,
      "DriveSpeed": 1553
    }, {
      "ID": 81,
      "TimeRemaining": 2043,
      "Temperature": 149,
      "FanSpeed": 3498,
      "DriveSpeed": 1561
    }, {
      "ID": 82,
      "TimeRemaining": 2041,
      "Temperature": 149,
      "FanSpeed": 3495,
      "DriveSpeed": 1563
    }, {
      "ID": 83,
      "TimeRemaining": 2039,
      "Temperature": 149,
      "FanSpeed": 3502,
      "DriveSpeed": 1557
    }, {
      "ID": 84,
      "TimeRemaining": 2037,
      "Temperature": 149,
      "FanSpeed": 3496,
      "DriveSpeed": 1567
    }, {
      "ID": 85,
      "TimeRemaining": 2035,
      "Temperature": 149,
      "FanSpeed": 3496,
      "DriveSpeed": 1569
    }, {
      "ID": 86,
      "TimeRemaining": 2033,
      "Temperature": 149,
      "FanSpeed": 3503,
      "DriveSpeed": 1564
    }, {
      "ID": 87,
      "TimeRemaining": 2031,
      "Temperature": 149,
      "FanSpeed": 3501,
      "DriveSpeed": 1576
    }, {
      "ID": 88,
      "TimeRemaining": 2029,
      "Temperature": 149,
      "FanSpeed": 3496,
      "DriveSpeed": 1558
    }, {
      "ID": 89,
      "TimeRemaining": 2027,
      "Temperature": 149,
      "FanSpeed": 3502,
      "DriveSpeed": 1570
    }, {
      "ID": 90,
      "TimeRemaining": 2025,
      "Temperature": 149,
      "FanSpeed": 3501,
      "DriveSpeed": 1575
    }, {
      "ID": 91,
      "TimeRemaining": 2023,
      "Temperature": 149,
      "FanSpeed": 3502,
      "DriveSpeed": 1567
    }, {
      "ID": 92,
      "TimeRemaining": 2021,
      "Temperature": 149,
      "FanSpeed": 3498,
      "DriveSpeed": 1565
    }, {
      "ID": 93,
      "TimeRemaining": 2019,
      "Temperature": 149,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 94,
      "TimeRemaining": 2017,
      "Temperature": 149,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 95,
      "TimeRemaining": 2015,
      "Temperature": 149,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 96,
      "TimeRemaining": 2013,
      "Temperature": 150,
      "FanSpeed": 3500,
      "DriveSpeed": 64609
    }, {
      "ID": 97,
      "TimeRemaining": 2011,
      "Temperature": 150,
      "FanSpeed": 3495,
      "DriveSpeed": 63962
    }, {
      "ID": 98,
      "TimeRemaining": 2009,
      "Temperature": 151,
      "FanSpeed": 3499,
      "DriveSpeed": 63966
    }, {
      "ID": 99,
      "TimeRemaining": 2007,
      "Temperature": 151,
      "FanSpeed": 3498,
      "DriveSpeed": 63957
    }, {
      "ID": 100,
      "TimeRemaining": 2005,
      "Temperature": 151,
      "FanSpeed": 3497,
      "DriveSpeed": 63974
    }, {
      "ID": 101,
      "TimeRemaining": 2003,
      "Temperature": 150,
      "FanSpeed": 3501,
      "DriveSpeed": 63970
    }, {
      "ID": 102,
      "TimeRemaining": 2001,
      "Temperature": 150,
      "FanSpeed": 3502,
      "DriveSpeed": 63967
    }, {
      "ID": 103,
      "TimeRemaining": 1999,
      "Temperature": 150,
      "FanSpeed": 3498,
      "DriveSpeed": 63967
    }, {
      "ID": 104,
      "TimeRemaining": 1997,
      "Temperature": 150,
      "FanSpeed": 3497,
      "DriveSpeed": 63968
    }, {
      "ID": 105,
      "TimeRemaining": 1995,
      "Temperature": 149,
      "FanSpeed": 3504,
      "DriveSpeed": 63966
    }, {
      "ID": 106,
      "TimeRemaining": 1993,
      "Temperature": 149,
      "FanSpeed": 3506,
      "DriveSpeed": 63959
    }, {
      "ID": 107,
      "TimeRemaining": 1991,
      "Temperature": 149,
      "FanSpeed": 3497,
      "DriveSpeed": 63967
    }, {
      "ID": 108,
      "TimeRemaining": 1989,
      "Temperature": 149,
      "FanSpeed": 3505,
      "DriveSpeed": 63960
    }, {
      "ID": 109,
      "TimeRemaining": 1987,
      "Temperature": 149,
      "FanSpeed": 3499,
      "DriveSpeed": 63961
    }, {
      "ID": 110,
      "TimeRemaining": 1985,
      "Temperature": 150,
      "FanSpeed": 3503,
      "DriveSpeed": 63963
    }, {
      "ID": 111,
      "TimeRemaining": 1983,
      "Temperature": 150,
      "FanSpeed": 3503,
      "DriveSpeed": 0
    }, {
      "ID": 112,
      "TimeRemaining": 1981,
      "Temperature": 150,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 113,
      "TimeRemaining": 1979,
      "Temperature": 150,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 114,
      "TimeRemaining": 1977,
      "Temperature": 150,
      "FanSpeed": 3498,
      "DriveSpeed": 921
    }, {
      "ID": 115,
      "TimeRemaining": 1975,
      "Temperature": 151,
      "FanSpeed": 3495,
      "DriveSpeed": 1577
    }, {
      "ID": 116,
      "TimeRemaining": 1973,
      "Temperature": 151,
      "FanSpeed": 3496,
      "DriveSpeed": 1565
    }, {
      "ID": 117,
      "TimeRemaining": 1971,
      "Temperature": 151,
      "FanSpeed": 3498,
      "DriveSpeed": 1577
    }, {
      "ID": 118,
      "TimeRemaining": 1969,
      "Temperature": 151,
      "FanSpeed": 3498,
      "DriveSpeed": 1562
    }, {
      "ID": 119,
      "TimeRemaining": 1967,
      "Temperature": 150,
      "FanSpeed": 3497,
      "DriveSpeed": 1575
    }, {
      "ID": 120,
      "TimeRemaining": 1965,
      "Temperature": 149,
      "FanSpeed": 3500,
      "DriveSpeed": 1579
    }, {
      "ID": 121,
      "TimeRemaining": 1963,
      "Temperature": 149,
      "FanSpeed": 3500,
      "DriveSpeed": 1570
    }, {
      "ID": 122,
      "TimeRemaining": 1961,
      "Temperature": 149,
      "FanSpeed": 3499,
      "DriveSpeed": 1572
    }, {
      "ID": 123,
      "TimeRemaining": 1959,
      "Temperature": 148,
      "FanSpeed": 3501,
      "DriveSpeed": 1566
    }, {
      "ID": 124,
      "TimeRemaining": 1957,
      "Temperature": 148,
      "FanSpeed": 3497,
      "DriveSpeed": 1578
    }, {
      "ID": 125,
      "TimeRemaining": 1955,
      "Temperature": 148,
      "FanSpeed": 3499,
      "DriveSpeed": 1571
    }, {
      "ID": 126,
      "TimeRemaining": 1953,
      "Temperature": 148,
      "FanSpeed": 3499,
      "DriveSpeed": 1578
    }, {
      "ID": 127,
      "TimeRemaining": 1951,
      "Temperature": 148,
      "FanSpeed": 3501,
      "DriveSpeed": 1581
    }, {
      "ID": 128,
      "TimeRemaining": 1949,
      "Temperature": 148,
      "FanSpeed": 3495,
      "DriveSpeed": 1577
    }, {
      "ID": 129,
      "TimeRemaining": 1947,
      "Temperature": 148,
      "FanSpeed": 3503,
      "DriveSpeed": 0
    }, {
      "ID": 130,
      "TimeRemaining": 1945,
      "Temperature": 148,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 131,
      "TimeRemaining": 1943,
      "Temperature": 148,
      "FanSpeed": 3499,
      "DriveSpeed": 0
    }, {
      "ID": 132,
      "TimeRemaining": 1941,
      "Temperature": 149,
      "FanSpeed": 3498,
      "DriveSpeed": 65060
    }, {
      "ID": 133,
      "TimeRemaining": 1939,
      "Temperature": 149,
      "FanSpeed": 3496,
      "DriveSpeed": 63966
    }, {
      "ID": 134,
      "TimeRemaining": 1937,
      "Temperature": 149,
      "FanSpeed": 3496,
      "DriveSpeed": 63973
    }, {
      "ID": 135,
      "TimeRemaining": 1935,
      "Temperature": 150,
      "FanSpeed": 3498,
      "DriveSpeed": 63967
    }, {
      "ID": 136,
      "TimeRemaining": 1933,
      "Temperature": 149,
      "FanSpeed": 3500,
      "DriveSpeed": 63964
    }, {
      "ID": 137,
      "TimeRemaining": 1931,
      "Temperature": 149,
      "FanSpeed": 3498,
      "DriveSpeed": 63976
    }, {
      "ID": 138,
      "TimeRemaining": 1929,
      "Temperature": 149,
      "FanSpeed": 3498,
      "DriveSpeed": 63972
    }, {
      "ID": 139,
      "TimeRemaining": 1927,
      "Temperature": 149,
      "FanSpeed": 3500,
      "DriveSpeed": 63967
    }, {
      "ID": 140,
      "TimeRemaining": 1925,
      "Temperature": 149,
      "FanSpeed": 3498,
      "DriveSpeed": 63966
    }, {
      "ID": 141,
      "TimeRemaining": 1923,
      "Temperature": 149,
      "FanSpeed": 3498,
      "DriveSpeed": 63970
    }, {
      "ID": 142,
      "TimeRemaining": 1921,
      "Temperature": 148,
      "FanSpeed": 3500,
      "DriveSpeed": 63974
    }, {
      "ID": 143,
      "TimeRemaining": 1919,
      "Temperature": 148,
      "FanSpeed": 3505,
      "DriveSpeed": 63959
    }, {
      "ID": 144,
      "TimeRemaining": 1917,
      "Temperature": 148,
      "FanSpeed": 3500,
      "DriveSpeed": 63962
    }, {
      "ID": 145,
      "TimeRemaining": 1915,
      "Temperature": 149,
      "FanSpeed": 3502,
      "DriveSpeed": 63975
    }, {
      "ID": 146,
      "TimeRemaining": 1913,
      "Temperature": 148,
      "FanSpeed": 3501,
      "DriveSpeed": 63979
    }, {
      "ID": 147,
      "TimeRemaining": 1911,
      "Temperature": 148,
      "FanSpeed": 3504,
      "DriveSpeed": 0
    }, {
      "ID": 148,
      "TimeRemaining": 1909,
      "Temperature": 148,
      "FanSpeed": 3501,
      "DriveSpeed": 0
    }, {
      "ID": 149,
      "TimeRemaining": 1907,
      "Temperature": 149,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 150,
      "TimeRemaining": 1905,
      "Temperature": 149,
      "FanSpeed": 3498,
      "DriveSpeed": 399
    }, {
      "ID": 151,
      "TimeRemaining": 1903,
      "Temperature": 150,
      "FanSpeed": 3494,
      "DriveSpeed": 1589
    }, {
      "ID": 152,
      "TimeRemaining": 1901,
      "Temperature": 151,
      "FanSpeed": 3498,
      "DriveSpeed": 1571
    }, {
      "ID": 153,
      "TimeRemaining": 1899,
      "Temperature": 151,
      "FanSpeed": 3495,
      "DriveSpeed": 1574
    }, {
      "ID": 154,
      "TimeRemaining": 1897,
      "Temperature": 151,
      "FanSpeed": 3498,
      "DriveSpeed": 1577
    }, {
      "ID": 155,
      "TimeRemaining": 1895,
      "Temperature": 150,
      "FanSpeed": 3504,
      "DriveSpeed": 1570
    }, {
      "ID": 156,
      "TimeRemaining": 1893,
      "Temperature": 150,
      "FanSpeed": 3498,
      "DriveSpeed": 1579
    }, {
      "ID": 157,
      "TimeRemaining": 1891,
      "Temperature": 150,
      "FanSpeed": 3498,
      "DriveSpeed": 1567
    }, {
      "ID": 158,
      "TimeRemaining": 1889,
      "Temperature": 150,
      "FanSpeed": 3503,
      "DriveSpeed": 1580
    }, {
      "ID": 159,
      "TimeRemaining": 1887,
      "Temperature": 150,
      "FanSpeed": 3502,
      "DriveSpeed": 1573
    }, {
      "ID": 160,
      "TimeRemaining": 1885,
      "Temperature": 150,
      "FanSpeed": 3498,
      "DriveSpeed": 1568
    }, {
      "ID": 161,
      "TimeRemaining": 1883,
      "Temperature": 150,
      "FanSpeed": 3500,
      "DriveSpeed": 1573
    }, {
      "ID": 162,
      "TimeRemaining": 1881,
      "Temperature": 150,
      "FanSpeed": 3499,
      "DriveSpeed": 1562
    }, {
      "ID": 163,
      "TimeRemaining": 1879,
      "Temperature": 151,
      "FanSpeed": 3499,
      "DriveSpeed": 1571
    }, {
      "ID": 164,
      "TimeRemaining": 1877,
      "Temperature": 151,
      "FanSpeed": 3498,
      "DriveSpeed": 1581
    }, {
      "ID": 165,
      "TimeRemaining": 1875,
      "Temperature": 151,
      "FanSpeed": 3501,
      "DriveSpeed": 0
    }, {
      "ID": 166,
      "TimeRemaining": 1873,
      "Temperature": 151,
      "FanSpeed": 3499,
      "DriveSpeed": 0
    }, {
      "ID": 167,
      "TimeRemaining": 1871,
      "Temperature": 151,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 168,
      "TimeRemaining": 1869,
      "Temperature": 151,
      "FanSpeed": 3499,
      "DriveSpeed": 64641
    }, {
      "ID": 169,
      "TimeRemaining": 1867,
      "Temperature": 151,
      "FanSpeed": 3495,
      "DriveSpeed": 63964
    }, {
      "ID": 170,
      "TimeRemaining": 1865,
      "Temperature": 152,
      "FanSpeed": 3499,
      "DriveSpeed": 63966
    }, {
      "ID": 171,
      "TimeRemaining": 1863,
      "Temperature": 152,
      "FanSpeed": 3497,
      "DriveSpeed": 63965
    }, {
      "ID": 172,
      "TimeRemaining": 1861,
      "Temperature": 152,
      "FanSpeed": 3499,
      "DriveSpeed": 63966
    }, {
      "ID": 173,
      "TimeRemaining": 1859,
      "Temperature": 152,
      "FanSpeed": 3500,
      "DriveSpeed": 63967
    }, {
      "ID": 174,
      "TimeRemaining": 1857,
      "Temperature": 151,
      "FanSpeed": 3498,
      "DriveSpeed": 63973
    }, {
      "ID": 175,
      "TimeRemaining": 1855,
      "Temperature": 151,
      "FanSpeed": 3504,
      "DriveSpeed": 63972
    }, {
      "ID": 176,
      "TimeRemaining": 1853,
      "Temperature": 151,
      "FanSpeed": 3500,
      "DriveSpeed": 63968
    }, {
      "ID": 177,
      "TimeRemaining": 1851,
      "Temperature": 151,
      "FanSpeed": 3500,
      "DriveSpeed": 63984
    }, {
      "ID": 178,
      "TimeRemaining": 1849,
      "Temperature": 151,
      "FanSpeed": 3497,
      "DriveSpeed": 63976
    }, {
      "ID": 179,
      "TimeRemaining": 1847,
      "Temperature": 151,
      "FanSpeed": 3500,
      "DriveSpeed": 63971
    }, {
      "ID": 180,
      "TimeRemaining": 1845,
      "Temperature": 151,
      "FanSpeed": 3495,
      "DriveSpeed": 63976
    }, {
      "ID": 181,
      "TimeRemaining": 1843,
      "Temperature": 151,
      "FanSpeed": 3496,
      "DriveSpeed": 63963
    }, {
      "ID": 182,
      "TimeRemaining": 1841,
      "Temperature": 151,
      "FanSpeed": 3502,
      "DriveSpeed": 63960
    }, {
      "ID": 183,
      "TimeRemaining": 1839,
      "Temperature": 151,
      "FanSpeed": 3503,
      "DriveSpeed": 0
    }, {
      "ID": 184,
      "TimeRemaining": 1837,
      "Temperature": 151,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 185,
      "TimeRemaining": 1835,
      "Temperature": 152,
      "FanSpeed": 3501,
      "DriveSpeed": 0
    }, {
      "ID": 186,
      "TimeRemaining": 1833,
      "Temperature": 152,
      "FanSpeed": 3502,
      "DriveSpeed": 438
    }, {
      "ID": 187,
      "TimeRemaining": 1831,
      "Temperature": 153,
      "FanSpeed": 3496,
      "DriveSpeed": 1576
    }, {
      "ID": 188,
      "TimeRemaining": 1829,
      "Temperature": 154,
      "FanSpeed": 3500,
      "DriveSpeed": 1580
    }, {
      "ID": 189,
      "TimeRemaining": 1827,
      "Temperature": 154,
      "FanSpeed": 3499,
      "DriveSpeed": 1580
    }, {
      "ID": 190,
      "TimeRemaining": 1825,
      "Temperature": 154,
      "FanSpeed": 3502,
      "DriveSpeed": 1579
    }, {
      "ID": 191,
      "TimeRemaining": 1823,
      "Temperature": 154,
      "FanSpeed": 3498,
      "DriveSpeed": 1574
    }, {
      "ID": 192,
      "TimeRemaining": 1821,
      "Temperature": 153,
      "FanSpeed": 3500,
      "DriveSpeed": 1578
    }, {
      "ID": 193,
      "TimeRemaining": 1819,
      "Temperature": 153,
      "FanSpeed": 3503,
      "DriveSpeed": 1573
    }, {
      "ID": 194,
      "TimeRemaining": 1817,
      "Temperature": 153,
      "FanSpeed": 3499,
      "DriveSpeed": 1582
    }, {
      "ID": 195,
      "TimeRemaining": 1815,
      "Temperature": 153,
      "FanSpeed": 3502,
      "DriveSpeed": 1566
    }, {
      "ID": 196,
      "TimeRemaining": 1813,
      "Temperature": 152,
      "FanSpeed": 3500,
      "DriveSpeed": 1576
    }, {
      "ID": 197,
      "TimeRemaining": 1811,
      "Temperature": 152,
      "FanSpeed": 3498,
      "DriveSpeed": 1583
    }, {
      "ID": 198,
      "TimeRemaining": 1809,
      "Temperature": 152,
      "FanSpeed": 3501,
      "DriveSpeed": 1563
    }, {
      "ID": 199,
      "TimeRemaining": 1807,
      "Temperature": 152,
      "FanSpeed": 3501,
      "DriveSpeed": 1575
    }, {
      "ID": 200,
      "TimeRemaining": 1805,
      "Temperature": 152,
      "FanSpeed": 3499,
      "DriveSpeed": 1570
    }, {
      "ID": 201,
      "TimeRemaining": 1803,
      "Temperature": 152,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 202,
      "TimeRemaining": 1801,
      "Temperature": 152,
      "FanSpeed": 3499,
      "DriveSpeed": 0
    }, {
      "ID": 203,
      "TimeRemaining": 1799,
      "Temperature": 152,
      "FanSpeed": 3499,
      "DriveSpeed": 0
    }, {
      "ID": 204,
      "TimeRemaining": 1797,
      "Temperature": 152,
      "FanSpeed": 3496,
      "DriveSpeed": 65106
    }, {
      "ID": 205,
      "TimeRemaining": 1795,
      "Temperature": 152,
      "FanSpeed": 3495,
      "DriveSpeed": 63957
    }, {
      "ID": 206,
      "TimeRemaining": 1793,
      "Temperature": 153,
      "FanSpeed": 3497,
      "DriveSpeed": 63980
    }, {
      "ID": 207,
      "TimeRemaining": 1791,
      "Temperature": 153,
      "FanSpeed": 3500,
      "DriveSpeed": 63963
    }, {
      "ID": 208,
      "TimeRemaining": 1789,
      "Temperature": 153,
      "FanSpeed": 3497,
      "DriveSpeed": 63972
    }, {
      "ID": 209,
      "TimeRemaining": 1787,
      "Temperature": 153,
      "FanSpeed": 3501,
      "DriveSpeed": 63971
    }, {
      "ID": 210,
      "TimeRemaining": 1785,
      "Temperature": 153,
      "FanSpeed": 3498,
      "DriveSpeed": 63965
    }, {
      "ID": 211,
      "TimeRemaining": 1783,
      "Temperature": 153,
      "FanSpeed": 3499,
      "DriveSpeed": 63961
    }, {
      "ID": 212,
      "TimeRemaining": 1781,
      "Temperature": 153,
      "FanSpeed": 3499,
      "DriveSpeed": 63965
    }, {
      "ID": 213,
      "TimeRemaining": 1779,
      "Temperature": 153,
      "FanSpeed": 3501,
      "DriveSpeed": 63960
    }, {
      "ID": 214,
      "TimeRemaining": 1777,
      "Temperature": 154,
      "FanSpeed": 3502,
      "DriveSpeed": 63957
    }, {
      "ID": 215,
      "TimeRemaining": 1775,
      "Temperature": 154,
      "FanSpeed": 3499,
      "DriveSpeed": 63970
    }, {
      "ID": 216,
      "TimeRemaining": 1773,
      "Temperature": 154,
      "FanSpeed": 3502,
      "DriveSpeed": 63972
    }, {
      "ID": 217,
      "TimeRemaining": 1771,
      "Temperature": 154,
      "FanSpeed": 3496,
      "DriveSpeed": 63959
    }, {
      "ID": 218,
      "TimeRemaining": 1769,
      "Temperature": 154,
      "FanSpeed": 3497,
      "DriveSpeed": 63974
    }, {
      "ID": 219,
      "TimeRemaining": 1767,
      "Temperature": 154,
      "FanSpeed": 3502,
      "DriveSpeed": 0
    }, {
      "ID": 220,
      "TimeRemaining": 1765,
      "Temperature": 154,
      "FanSpeed": 3501,
      "DriveSpeed": 0
    }, {
      "ID": 221,
      "TimeRemaining": 1763,
      "Temperature": 154,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 222,
      "TimeRemaining": 1761,
      "Temperature": 154,
      "FanSpeed": 3500,
      "DriveSpeed": 878
    }, {
      "ID": 223,
      "TimeRemaining": 1759,
      "Temperature": 154,
      "FanSpeed": 3496,
      "DriveSpeed": 1579
    }, {
      "ID": 224,
      "TimeRemaining": 1757,
      "Temperature": 155,
      "FanSpeed": 3501,
      "DriveSpeed": 1573
    }, {
      "ID": 225,
      "TimeRemaining": 1755,
      "Temperature": 155,
      "FanSpeed": 3497,
      "DriveSpeed": 1561
    }, {
      "ID": 226,
      "TimeRemaining": 1753,
      "Temperature": 155,
      "FanSpeed": 3499,
      "DriveSpeed": 1572
    }, {
      "ID": 227,
      "TimeRemaining": 1751,
      "Temperature": 155,
      "FanSpeed": 3504,
      "DriveSpeed": 1572
    }, {
      "ID": 228,
      "TimeRemaining": 1749,
      "Temperature": 154,
      "FanSpeed": 3501,
      "DriveSpeed": 1573
    }, {
      "ID": 229,
      "TimeRemaining": 1747,
      "Temperature": 154,
      "FanSpeed": 3501,
      "DriveSpeed": 1564
    }, {
      "ID": 230,
      "TimeRemaining": 1745,
      "Temperature": 154,
      "FanSpeed": 3496,
      "DriveSpeed": 1560
    }, {
      "ID": 231,
      "TimeRemaining": 1743,
      "Temperature": 153,
      "FanSpeed": 3503,
      "DriveSpeed": 1572
    }, {
      "ID": 232,
      "TimeRemaining": 1741,
      "Temperature": 153,
      "FanSpeed": 3502,
      "DriveSpeed": 1566
    }, {
      "ID": 233,
      "TimeRemaining": 1739,
      "Temperature": 153,
      "FanSpeed": 3497,
      "DriveSpeed": 1562
    }, {
      "ID": 234,
      "TimeRemaining": 1737,
      "Temperature": 153,
      "FanSpeed": 3499,
      "DriveSpeed": 1571
    }, {
      "ID": 235,
      "TimeRemaining": 1735,
      "Temperature": 154,
      "FanSpeed": 3497,
      "DriveSpeed": 1554
    }, {
      "ID": 236,
      "TimeRemaining": 1733,
      "Temperature": 154,
      "FanSpeed": 3499,
      "DriveSpeed": 1571
    }, {
      "ID": 237,
      "TimeRemaining": 1731,
      "Temperature": 153,
      "FanSpeed": 3503,
      "DriveSpeed": 0
    }, {
      "ID": 238,
      "TimeRemaining": 1729,
      "Temperature": 154,
      "FanSpeed": 3502,
      "DriveSpeed": 0
    }, {
      "ID": 239,
      "TimeRemaining": 1727,
      "Temperature": 154,
      "FanSpeed": 3501,
      "DriveSpeed": 0
    }, {
      "ID": 240,
      "TimeRemaining": 1725,
      "Temperature": 155,
      "FanSpeed": 3501,
      "DriveSpeed": 64657
    }, {
      "ID": 241,
      "TimeRemaining": 1723,
      "Temperature": 157,
      "FanSpeed": 3497,
      "DriveSpeed": 63963
    }, {
      "ID": 242,
      "TimeRemaining": 1721,
      "Temperature": 160,
      "FanSpeed": 3496,
      "DriveSpeed": 63969
    }, {
      "ID": 243,
      "TimeRemaining": 1719,
      "Temperature": 160,
      "FanSpeed": 3500,
      "DriveSpeed": 63966
    }, {
      "ID": 244,
      "TimeRemaining": 1717,
      "Temperature": 157,
      "FanSpeed": 3500,
      "DriveSpeed": 63971
    }, {
      "ID": 245,
      "TimeRemaining": 1715,
      "Temperature": 157,
      "FanSpeed": 3498,
      "DriveSpeed": 63962
    }, {
      "ID": 246,
      "TimeRemaining": 1713,
      "Temperature": 157,
      "FanSpeed": 3501,
      "DriveSpeed": 63977
    }, {
      "ID": 247,
      "TimeRemaining": 1711,
      "Temperature": 157,
      "FanSpeed": 3498,
      "DriveSpeed": 63980
    }, {
      "ID": 248,
      "TimeRemaining": 1709,
      "Temperature": 157,
      "FanSpeed": 3500,
      "DriveSpeed": 63961
    }, {
      "ID": 249,
      "TimeRemaining": 1707,
      "Temperature": 157,
      "FanSpeed": 3498,
      "DriveSpeed": 63977
    }, {
      "ID": 250,
      "TimeRemaining": 1705,
      "Temperature": 157,
      "FanSpeed": 3497,
      "DriveSpeed": 63970
    }, {
      "ID": 251,
      "TimeRemaining": 1703,
      "Temperature": 157,
      "FanSpeed": 3501,
      "DriveSpeed": 63967
    }, {
      "ID": 252,
      "TimeRemaining": 1701,
      "Temperature": 155,
      "FanSpeed": 3497,
      "DriveSpeed": 63981
    }, {
      "ID": 253,
      "TimeRemaining": 1699,
      "Temperature": 155,
      "FanSpeed": 3499,
      "DriveSpeed": 63968
    }, {
      "ID": 254,
      "TimeRemaining": 1697,
      "Temperature": 155,
      "FanSpeed": 3498,
      "DriveSpeed": 0
    }, {
      "ID": 255,
      "TimeRemaining": 1695,
      "Temperature": 155,
      "FanSpeed": 3502,
      "DriveSpeed": 0
    }, {
      "ID": 0,
      "TimeRemaining": 1693,
      "Temperature": 154,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 1,
      "TimeRemaining": 1691,
      "Temperature": 155,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 2,
      "TimeRemaining": 1689,
      "Temperature": 155,
      "FanSpeed": 3499,
      "DriveSpeed": 438
    }, {
      "ID": 3,
      "TimeRemaining": 1687,
      "Temperature": 155,
      "FanSpeed": 3497,
      "DriveSpeed": 1577
    }, {
      "ID": 4,
      "TimeRemaining": 1685,
      "Temperature": 157,
      "FanSpeed": 3496,
      "DriveSpeed": 1566
    }, {
      "ID": 5,
      "TimeRemaining": 1683,
      "Temperature": 157,
      "FanSpeed": 3498,
      "DriveSpeed": 1573
    }, {
      "ID": 6,
      "TimeRemaining": 1681,
      "Temperature": 157,
      "FanSpeed": 3498,
      "DriveSpeed": 1566
    }, {
      "ID": 7,
      "TimeRemaining": 1679,
      "Temperature": 157,
      "FanSpeed": 3500,
      "DriveSpeed": 1563
    }, {
      "ID": 8,
      "TimeRemaining": 1677,
      "Temperature": 157,
      "FanSpeed": 3497,
      "DriveSpeed": 1567
    }, {
      "ID": 9,
      "TimeRemaining": 1675,
      "Temperature": 157,
      "FanSpeed": 3499,
      "DriveSpeed": 1566
    }, {
      "ID": 10,
      "TimeRemaining": 1673,
      "Temperature": 157,
      "FanSpeed": 3501,
      "DriveSpeed": 1566
    }, {
      "ID": 11,
      "TimeRemaining": 1671,
      "Temperature": 157,
      "FanSpeed": 3501,
      "DriveSpeed": 1558
    }, {
      "ID": 12,
      "TimeRemaining": 1669,
      "Temperature": 155,
      "FanSpeed": 3502,
      "DriveSpeed": 1572
    }, {
      "ID": 13,
      "TimeRemaining": 1667,
      "Temperature": 157,
      "FanSpeed": 3501,
      "DriveSpeed": 1568
    }, {
      "ID": 14,
      "TimeRemaining": 1665,
      "Temperature": 157,
      "FanSpeed": 3497,
      "DriveSpeed": 1558
    }, {
      "ID": 15,
      "TimeRemaining": 1663,
      "Temperature": 155,
      "FanSpeed": 3500,
      "DriveSpeed": 1567
    }, {
      "ID": 16,
      "TimeRemaining": 1661,
      "Temperature": 155,
      "FanSpeed": 3500,
      "DriveSpeed": 1570
    }, {
      "ID": 17,
      "TimeRemaining": 1659,
      "Temperature": 155,
      "FanSpeed": 3504,
      "DriveSpeed": 0
    }, {
      "ID": 18,
      "TimeRemaining": 1657,
      "Temperature": 154,
      "FanSpeed": 3501,
      "DriveSpeed": 0
    }, {
      "ID": 19,
      "TimeRemaining": 1655,
      "Temperature": 154,
      "FanSpeed": 3499,
      "DriveSpeed": 0
    }, {
      "ID": 20,
      "TimeRemaining": 1653,
      "Temperature": 155,
      "FanSpeed": 3498,
      "DriveSpeed": 65100
    }, {
      "ID": 21,
      "TimeRemaining": 1651,
      "Temperature": 155,
      "FanSpeed": 3496,
      "DriveSpeed": 63963
    }, {
      "ID": 22,
      "TimeRemaining": 1649,
      "Temperature": 157,
      "FanSpeed": 3498,
      "DriveSpeed": 63959
    }, {
      "ID": 23,
      "TimeRemaining": 1647,
      "Temperature": 157,
      "FanSpeed": 3497,
      "DriveSpeed": 63961
    }, {
      "ID": 24,
      "TimeRemaining": 1645,
      "Temperature": 157,
      "FanSpeed": 3502,
      "DriveSpeed": 63955
    }, {
      "ID": 25,
      "TimeRemaining": 1643,
      "Temperature": 157,
      "FanSpeed": 3498,
      "DriveSpeed": 63970
    }, {
      "ID": 26,
      "TimeRemaining": 1641,
      "Temperature": 157,
      "FanSpeed": 3503,
      "DriveSpeed": 63960
    }, {
      "ID": 27,
      "TimeRemaining": 1639,
      "Temperature": 157,
      "FanSpeed": 3502,
      "DriveSpeed": 63970
    }, {
      "ID": 28,
      "TimeRemaining": 1637,
      "Temperature": 157,
      "FanSpeed": 3499,
      "DriveSpeed": 63966
    }, {
      "ID": 29,
      "TimeRemaining": 1635,
      "Temperature": 157,
      "FanSpeed": 3503,
      "DriveSpeed": 63960
    }, {
      "ID": 30,
      "TimeRemaining": 1633,
      "Temperature": 157,
      "FanSpeed": 3502,
      "DriveSpeed": 63969
    }, {
      "ID": 31,
      "TimeRemaining": 1631,
      "Temperature": 157,
      "FanSpeed": 3499,
      "DriveSpeed": 63966
    }, {
      "ID": 32,
      "TimeRemaining": 1629,
      "Temperature": 157,
      "FanSpeed": 3499,
      "DriveSpeed": 63969
    }, {
      "ID": 33,
      "TimeRemaining": 1627,
      "Temperature": 157,
      "FanSpeed": 3500,
      "DriveSpeed": 63976
    }, {
      "ID": 34,
      "TimeRemaining": 1625,
      "Temperature": 155,
      "FanSpeed": 3499,
      "DriveSpeed": 63965
    }, {
      "ID": 35,
      "TimeRemaining": 1623,
      "Temperature": 155,
      "FanSpeed": 3501,
      "DriveSpeed": 0
    }, {
      "ID": 36,
      "TimeRemaining": 1621,
      "Temperature": 155,
      "FanSpeed": 3502,
      "DriveSpeed": 0
    }, {
      "ID": 37,
      "TimeRemaining": 1619,
      "Temperature": 155,
      "FanSpeed": 3499,
      "DriveSpeed": 0
    }, {
      "ID": 38,
      "TimeRemaining": 1617,
      "Temperature": 155,
      "FanSpeed": 3500,
      "DriveSpeed": 924
    }, {
      "ID": 39,
      "TimeRemaining": 1615,
      "Temperature": 157,
      "FanSpeed": 3500,
      "DriveSpeed": 1578
    }, {
      "ID": 40,
      "TimeRemaining": 1613,
      "Temperature": 157,
      "FanSpeed": 3499,
      "DriveSpeed": 1570
    }, {
      "ID": 41,
      "TimeRemaining": 1611,
      "Temperature": 157,
      "FanSpeed": 3499,
      "DriveSpeed": 1565
    }, {
      "ID": 42,
      "TimeRemaining": 1609,
      "Temperature": 157,
      "FanSpeed": 3499,
      "DriveSpeed": 1573
    }, {
      "ID": 43,
      "TimeRemaining": 1607,
      "Temperature": 157,
      "FanSpeed": 3504,
      "DriveSpeed": 1572
    }, {
      "ID": 44,
      "TimeRemaining": 1605,
      "Temperature": 157,
      "FanSpeed": 3496,
      "DriveSpeed": 1570
    }, {
      "ID": 45,
      "TimeRemaining": 1603,
      "Temperature": 157,
      "FanSpeed": 3498,
      "DriveSpeed": 1563
    }, {
      "ID": 46,
      "TimeRemaining": 1601,
      "Temperature": 157,
      "FanSpeed": 3498,
      "DriveSpeed": 1571
    }, {
      "ID": 47,
      "TimeRemaining": 1599,
      "Temperature": 157,
      "FanSpeed": 3501,
      "DriveSpeed": 1575
    }, {
      "ID": 48,
      "TimeRemaining": 1597,
      "Temperature": 157,
      "FanSpeed": 3501,
      "DriveSpeed": 1573
    }, {
      "ID": 49,
      "TimeRemaining": 1595,
      "Temperature": 157,
      "FanSpeed": 3497,
      "DriveSpeed": 1570
    }, {
      "ID": 50,
      "TimeRemaining": 1593,
      "Temperature": 157,
      "FanSpeed": 3501,
      "DriveSpeed": 1572
    }, {
      "ID": 51,
      "TimeRemaining": 1591,
      "Temperature": 157,
      "FanSpeed": 3496,
      "DriveSpeed": 1570
    }, {
      "ID": 52,
      "TimeRemaining": 1589,
      "Temperature": 157,
      "FanSpeed": 3500,
      "DriveSpeed": 1571
    }, {
      "ID": 53,
      "TimeRemaining": 1587,
      "Temperature": 157,
      "FanSpeed": 3503,
      "DriveSpeed": 0
    }, {
      "ID": 54,
      "TimeRemaining": 1585,
      "Temperature": 157,
      "FanSpeed": 3502,
      "DriveSpeed": 0
    }, {
      "ID": 55,
      "TimeRemaining": 1583,
      "Temperature": 160,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 56,
      "TimeRemaining": 1581,
      "Temperature": 160,
      "FanSpeed": 3500,
      "DriveSpeed": 64572
    }, {
      "ID": 57,
      "TimeRemaining": 1579,
      "Temperature": 165,
      "FanSpeed": 3498,
      "DriveSpeed": 63958
    }, {
      "ID": 58,
      "TimeRemaining": 1577,
      "Temperature": 165,
      "FanSpeed": 3496,
      "DriveSpeed": 63964
    }, {
      "ID": 59,
      "TimeRemaining": 1575,
      "Temperature": 170,
      "FanSpeed": 3502,
      "DriveSpeed": 63969
    }, {
      "ID": 60,
      "TimeRemaining": 1573,
      "Temperature": 165,
      "FanSpeed": 3498,
      "DriveSpeed": 63968
    }, {
      "ID": 61,
      "TimeRemaining": 1571,
      "Temperature": 165,
      "FanSpeed": 3502,
      "DriveSpeed": 63963
    }, {
      "ID": 62,
      "TimeRemaining": 1569,
      "Temperature": 165,
      "FanSpeed": 3499,
      "DriveSpeed": 63963
    }, {
      "ID": 63,
      "TimeRemaining": 1567,
      "Temperature": 165,
      "FanSpeed": 3498,
      "DriveSpeed": 63973
    }, {
      "ID": 64,
      "TimeRemaining": 1565,
      "Temperature": 165,
      "FanSpeed": 3499,
      "DriveSpeed": 63974
    }, {
      "ID": 65,
      "TimeRemaining": 1563,
      "Temperature": 165,
      "FanSpeed": 3499,
      "DriveSpeed": 63968
    }, {
      "ID": 66,
      "TimeRemaining": 1561,
      "Temperature": 165,
      "FanSpeed": 3501,
      "DriveSpeed": 63966
    }, {
      "ID": 67,
      "TimeRemaining": 1559,
      "Temperature": 165,
      "FanSpeed": 3500,
      "DriveSpeed": 63964
    }, {
      "ID": 68,
      "TimeRemaining": 1557,
      "Temperature": 160,
      "FanSpeed": 3500,
      "DriveSpeed": 63967
    }, {
      "ID": 69,
      "TimeRemaining": 1555,
      "Temperature": 160,
      "FanSpeed": 3500,
      "DriveSpeed": 63961
    }, {
      "ID": 70,
      "TimeRemaining": 1553,
      "Temperature": 160,
      "FanSpeed": 3502,
      "DriveSpeed": 0
    }, {
      "ID": 71,
      "TimeRemaining": 1551,
      "Temperature": 160,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 72,
      "TimeRemaining": 1549,
      "Temperature": 160,
      "FanSpeed": 3499,
      "DriveSpeed": 0
    }, {
      "ID": 73,
      "TimeRemaining": 1547,
      "Temperature": 165,
      "FanSpeed": 3499,
      "DriveSpeed": 0
    }, {
      "ID": 74,
      "TimeRemaining": 1545,
      "Temperature": 165,
      "FanSpeed": 3502,
      "DriveSpeed": 444
    }, {
      "ID": 75,
      "TimeRemaining": 1543,
      "Temperature": 170,
      "FanSpeed": 3494,
      "DriveSpeed": 1572
    }, {
      "ID": 76,
      "TimeRemaining": 1541,
      "Temperature": 171,
      "FanSpeed": 3499,
      "DriveSpeed": 1560
    }, {
      "ID": 77,
      "TimeRemaining": 1539,
      "Temperature": 171,
      "FanSpeed": 3496,
      "DriveSpeed": 1572
    }, {
      "ID": 78,
      "TimeRemaining": 1537,
      "Temperature": 171,
      "FanSpeed": 3497,
      "DriveSpeed": 1563
    }, {
      "ID": 79,
      "TimeRemaining": 1535,
      "Temperature": 171,
      "FanSpeed": 3502,
      "DriveSpeed": 1572
    }, {
      "ID": 80,
      "TimeRemaining": 1533,
      "Temperature": 171,
      "FanSpeed": 3498,
      "DriveSpeed": 1565
    }, {
      "ID": 81,
      "TimeRemaining": 1531,
      "Temperature": 171,
      "FanSpeed": 3499,
      "DriveSpeed": 1572
    }, {
      "ID": 82,
      "TimeRemaining": 1529,
      "Temperature": 171,
      "FanSpeed": 3501,
      "DriveSpeed": 1566
    }, {
      "ID": 83,
      "TimeRemaining": 1527,
      "Temperature": 171,
      "FanSpeed": 3500,
      "DriveSpeed": 1566
    }, {
      "ID": 84,
      "TimeRemaining": 1525,
      "Temperature": 171,
      "FanSpeed": 3503,
      "DriveSpeed": 1570
    }, {
      "ID": 85,
      "TimeRemaining": 1523,
      "Temperature": 171,
      "FanSpeed": 3498,
      "DriveSpeed": 1575
    }, {
      "ID": 86,
      "TimeRemaining": 1521,
      "Temperature": 171,
      "FanSpeed": 3498,
      "DriveSpeed": 1573
    }, {
      "ID": 87,
      "TimeRemaining": 1519,
      "Temperature": 170,
      "FanSpeed": 3496,
      "DriveSpeed": 1572
    }, {
      "ID": 88,
      "TimeRemaining": 1517,
      "Temperature": 170,
      "FanSpeed": 3495,
      "DriveSpeed": 1570
    }, {
      "ID": 89,
      "TimeRemaining": 1515,
      "Temperature": 170,
      "FanSpeed": 3503,
      "DriveSpeed": 0
    }, {
      "ID": 90,
      "TimeRemaining": 1513,
      "Temperature": 170,
      "FanSpeed": 3503,
      "DriveSpeed": 0
    }, {
      "ID": 91,
      "TimeRemaining": 1511,
      "Temperature": 170,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 92,
      "TimeRemaining": 1509,
      "Temperature": 171,
      "FanSpeed": 3499,
      "DriveSpeed": 65059
    }, {
      "ID": 93,
      "TimeRemaining": 1507,
      "Temperature": 172,
      "FanSpeed": 3496,
      "DriveSpeed": 63972
    }, {
      "ID": 94,
      "TimeRemaining": 1505,
      "Temperature": 173,
      "FanSpeed": 3499,
      "DriveSpeed": 63970
    }, {
      "ID": 95,
      "TimeRemaining": 1503,
      "Temperature": 173,
      "FanSpeed": 3500,
      "DriveSpeed": 63976
    }, {
      "ID": 96,
      "TimeRemaining": 1501,
      "Temperature": 173,
      "FanSpeed": 3505,
      "DriveSpeed": 63985
    }, {
      "ID": 97,
      "TimeRemaining": 1499,
      "Temperature": 173,
      "FanSpeed": 3499,
      "DriveSpeed": 63967
    }, {
      "ID": 98,
      "TimeRemaining": 1497,
      "Temperature": 173,
      "FanSpeed": 3497,
      "DriveSpeed": 63968
    }, {
      "ID": 99,
      "TimeRemaining": 1495,
      "Temperature": 173,
      "FanSpeed": 3501,
      "DriveSpeed": 63967
    }, {
      "ID": 100,
      "TimeRemaining": 1493,
      "Temperature": 173,
      "FanSpeed": 3500,
      "DriveSpeed": 63966
    }, {
      "ID": 101,
      "TimeRemaining": 1491,
      "Temperature": 173,
      "FanSpeed": 3503,
      "DriveSpeed": 63962
    }, {
      "ID": 102,
      "TimeRemaining": 1489,
      "Temperature": 173,
      "FanSpeed": 3495,
      "DriveSpeed": 63969
    }, {
      "ID": 103,
      "TimeRemaining": 1487,
      "Temperature": 173,
      "FanSpeed": 3500,
      "DriveSpeed": 63962
    }, {
      "ID": 104,
      "TimeRemaining": 1485,
      "Temperature": 173,
      "FanSpeed": 3501,
      "DriveSpeed": 63952
    }, {
      "ID": 105,
      "TimeRemaining": 1483,
      "Temperature": 173,
      "FanSpeed": 3500,
      "DriveSpeed": 63966
    }, {
      "ID": 106,
      "TimeRemaining": 1481,
      "Temperature": 173,
      "FanSpeed": 3501,
      "DriveSpeed": 63980
    }, {
      "ID": 107,
      "TimeRemaining": 1479,
      "Temperature": 173,
      "FanSpeed": 3502,
      "DriveSpeed": 0
    }, {
      "ID": 108,
      "TimeRemaining": 1477,
      "Temperature": 173,
      "FanSpeed": 3501,
      "DriveSpeed": 0
    }, {
      "ID": 109,
      "TimeRemaining": 1475,
      "Temperature": 173,
      "FanSpeed": 3502,
      "DriveSpeed": 0
    }, {
      "ID": 110,
      "TimeRemaining": 1473,
      "Temperature": 175,
      "FanSpeed": 3499,
      "DriveSpeed": 877
    }, {
      "ID": 111,
      "TimeRemaining": 1471,
      "Temperature": 180,
      "FanSpeed": 3495,
      "DriveSpeed": 1588
    }, {
      "ID": 112,
      "TimeRemaining": 1469,
      "Temperature": 180,
      "FanSpeed": 3498,
      "DriveSpeed": 1569
    }, {
      "ID": 113,
      "TimeRemaining": 1467,
      "Temperature": 180,
      "FanSpeed": 3494,
      "DriveSpeed": 1574
    }, {
      "ID": 114,
      "TimeRemaining": 1465,
      "Temperature": 180,
      "FanSpeed": 3496,
      "DriveSpeed": 1572
    }, {
      "ID": 115,
      "TimeRemaining": 1463,
      "Temperature": 180,
      "FanSpeed": 3502,
      "DriveSpeed": 1577
    }, {
      "ID": 116,
      "TimeRemaining": 1461,
      "Temperature": 175,
      "FanSpeed": 3501,
      "DriveSpeed": 1573
    }, {
      "ID": 117,
      "TimeRemaining": 1459,
      "Temperature": 175,
      "FanSpeed": 3498,
      "DriveSpeed": 1569
    }, {
      "ID": 118,
      "TimeRemaining": 1457,
      "Temperature": 175,
      "FanSpeed": 3497,
      "DriveSpeed": 1573
    }, {
      "ID": 119,
      "TimeRemaining": 1455,
      "Temperature": 175,
      "FanSpeed": 3499,
      "DriveSpeed": 1587
    }, {
      "ID": 120,
      "TimeRemaining": 1453,
      "Temperature": 175,
      "FanSpeed": 3500,
      "DriveSpeed": 1571
    }, {
      "ID": 121,
      "TimeRemaining": 1451,
      "Temperature": 175,
      "FanSpeed": 3495,
      "DriveSpeed": 1577
    }, {
      "ID": 122,
      "TimeRemaining": 1449,
      "Temperature": 175,
      "FanSpeed": 3503,
      "DriveSpeed": 1577
    }, {
      "ID": 123,
      "TimeRemaining": 1447,
      "Temperature": 175,
      "FanSpeed": 3498,
      "DriveSpeed": 1577
    }, {
      "ID": 124,
      "TimeRemaining": 1445,
      "Temperature": 173,
      "FanSpeed": 3505,
      "DriveSpeed": 1569
    }, {
      "ID": 125,
      "TimeRemaining": 1443,
      "Temperature": 173,
      "FanSpeed": 3504,
      "DriveSpeed": 0
    }, {
      "ID": 126,
      "TimeRemaining": 1441,
      "Temperature": 173,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 127,
      "TimeRemaining": 1439,
      "Temperature": 175,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 128,
      "TimeRemaining": 1437,
      "Temperature": 175,
      "FanSpeed": 3499,
      "DriveSpeed": 65126
    }, {
      "ID": 129,
      "TimeRemaining": 1435,
      "Temperature": 180,
      "FanSpeed": 3500,
      "DriveSpeed": 63955
    }, {
      "ID": 130,
      "TimeRemaining": 1433,
      "Temperature": 180,
      "FanSpeed": 3498,
      "DriveSpeed": 63978
    }, {
      "ID": 131,
      "TimeRemaining": 1431,
      "Temperature": 180,
      "FanSpeed": 3499,
      "DriveSpeed": 63965
    }, {
      "ID": 132,
      "TimeRemaining": 1429,
      "Temperature": 180,
      "FanSpeed": 3501,
      "DriveSpeed": 63957
    }, {
      "ID": 133,
      "TimeRemaining": 1427,
      "Temperature": 180,
      "FanSpeed": 3497,
      "DriveSpeed": 63966
    }, {
      "ID": 134,
      "TimeRemaining": 1425,
      "Temperature": 180,
      "FanSpeed": 3500,
      "DriveSpeed": 63964
    }, {
      "ID": 135,
      "TimeRemaining": 1423,
      "Temperature": 175,
      "FanSpeed": 3498,
      "DriveSpeed": 63972
    }, {
      "ID": 136,
      "TimeRemaining": 1421,
      "Temperature": 175,
      "FanSpeed": 3499,
      "DriveSpeed": 63955
    }, {
      "ID": 137,
      "TimeRemaining": 1419,
      "Temperature": 175,
      "FanSpeed": 3500,
      "DriveSpeed": 63967
    }, {
      "ID": 138,
      "TimeRemaining": 1417,
      "Temperature": 180,
      "FanSpeed": 3498,
      "DriveSpeed": 63972
    }, {
      "ID": 139,
      "TimeRemaining": 1415,
      "Temperature": 180,
      "FanSpeed": 3498,
      "DriveSpeed": 63970
    }, {
      "ID": 140,
      "TimeRemaining": 1413,
      "Temperature": 180,
      "FanSpeed": 3500,
      "DriveSpeed": 63969
    }, {
      "ID": 141,
      "TimeRemaining": 1411,
      "Temperature": 175,
      "FanSpeed": 3497,
      "DriveSpeed": 63972
    }, {
      "ID": 142,
      "TimeRemaining": 1409,
      "Temperature": 175,
      "FanSpeed": 3496,
      "DriveSpeed": 63962
    }, {
      "ID": 143,
      "TimeRemaining": 1407,
      "Temperature": 175,
      "FanSpeed": 3502,
      "DriveSpeed": 0
    }, {
      "ID": 144,
      "TimeRemaining": 1405,
      "Temperature": 175,
      "FanSpeed": 3502,
      "DriveSpeed": 0
    }, {
      "ID": 145,
      "TimeRemaining": 1403,
      "Temperature": 175,
      "FanSpeed": 3499,
      "DriveSpeed": 0
    }, {
      "ID": 146,
      "TimeRemaining": 1401,
      "Temperature": 180,
      "FanSpeed": 3498,
      "DriveSpeed": 419
    }, {
      "ID": 147,
      "TimeRemaining": 1399,
      "Temperature": 180,
      "FanSpeed": 3501,
      "DriveSpeed": 1577
    }, {
      "ID": 148,
      "TimeRemaining": 1397,
      "Temperature": 182,
      "FanSpeed": 3493,
      "DriveSpeed": 1579
    }, {
      "ID": 149,
      "TimeRemaining": 1395,
      "Temperature": 182,
      "FanSpeed": 3503,
      "DriveSpeed": 1566
    }, {
      "ID": 150,
      "TimeRemaining": 1393,
      "Temperature": 182,
      "FanSpeed": 3504,
      "DriveSpeed": 1575
    }, {
      "ID": 151,
      "TimeRemaining": 1391,
      "Temperature": 182,
      "FanSpeed": 3495,
      "DriveSpeed": 1581
    }, {
      "ID": 152,
      "TimeRemaining": 1389,
      "Temperature": 185,
      "FanSpeed": 3503,
      "DriveSpeed": 1574
    }, {
      "ID": 153,
      "TimeRemaining": 1387,
      "Temperature": 185,
      "FanSpeed": 3502,
      "DriveSpeed": 1581
    }, {
      "ID": 154,
      "TimeRemaining": 1385,
      "Temperature": 185,
      "FanSpeed": 3497,
      "DriveSpeed": 1563
    }, {
      "ID": 155,
      "TimeRemaining": 1383,
      "Temperature": 182,
      "FanSpeed": 3503,
      "DriveSpeed": 1571
    }, {
      "ID": 156,
      "TimeRemaining": 1381,
      "Temperature": 185,
      "FanSpeed": 3502,
      "DriveSpeed": 1570
    }, {
      "ID": 157,
      "TimeRemaining": 1379,
      "Temperature": 185,
      "FanSpeed": 3499,
      "DriveSpeed": 1569
    }, {
      "ID": 158,
      "TimeRemaining": 1377,
      "Temperature": 185,
      "FanSpeed": 3500,
      "DriveSpeed": 1574
    }, {
      "ID": 159,
      "TimeRemaining": 1375,
      "Temperature": 185,
      "FanSpeed": 3500,
      "DriveSpeed": 1573
    }, {
      "ID": 160,
      "TimeRemaining": 1373,
      "Temperature": 185,
      "FanSpeed": 3505,
      "DriveSpeed": 1576
    }, {
      "ID": 161,
      "TimeRemaining": 1371,
      "Temperature": 185,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 162,
      "TimeRemaining": 1369,
      "Temperature": 185,
      "FanSpeed": 3502,
      "DriveSpeed": 0
    }, {
      "ID": 163,
      "TimeRemaining": 1367,
      "Temperature": 185,
      "FanSpeed": 3499,
      "DriveSpeed": 0
    }, {
      "ID": 164,
      "TimeRemaining": 1365,
      "Temperature": 187,
      "FanSpeed": 3501,
      "DriveSpeed": 64577
    }, {
      "ID": 165,
      "TimeRemaining": 1363,
      "Temperature": 190,
      "FanSpeed": 3497,
      "DriveSpeed": 63977
    }, {
      "ID": 166,
      "TimeRemaining": 1361,
      "Temperature": 190,
      "FanSpeed": 3497,
      "DriveSpeed": 63963
    }, {
      "ID": 167,
      "TimeRemaining": 1359,
      "Temperature": 190,
      "FanSpeed": 3499,
      "DriveSpeed": 63958
    }, {
      "ID": 168,
      "TimeRemaining": 1357,
      "Temperature": 190,
      "FanSpeed": 3499,
      "DriveSpeed": 63972
    }, {
      "ID": 169,
      "TimeRemaining": 1355,
      "Temperature": 187,
      "FanSpeed": 3498,
      "DriveSpeed": 63959
    }, {
      "ID": 170,
      "TimeRemaining": 1353,
      "Temperature": 187,
      "FanSpeed": 3497,
      "DriveSpeed": 63956
    }, {
      "ID": 171,
      "TimeRemaining": 1351,
      "Temperature": 182,
      "FanSpeed": 3498,
      "DriveSpeed": 63961
    }, {
      "ID": 172,
      "TimeRemaining": 1349,
      "Temperature": 180,
      "FanSpeed": 3496,
      "DriveSpeed": 63961
    }, {
      "ID": 173,
      "TimeRemaining": 1347,
      "Temperature": 175,
      "FanSpeed": 3494,
      "DriveSpeed": 63959
    }, {
      "ID": 174,
      "TimeRemaining": 1345,
      "Temperature": 172,
      "FanSpeed": 3502,
      "DriveSpeed": 63962
    }, {
      "ID": 175,
      "TimeRemaining": 1343,
      "Temperature": 171,
      "FanSpeed": 3498,
      "DriveSpeed": 63967
    }, {
      "ID": 176,
      "TimeRemaining": 1341,
      "Temperature": 170,
      "FanSpeed": 3496,
      "DriveSpeed": 63961
    }, {
      "ID": 177,
      "TimeRemaining": 1339,
      "Temperature": 165,
      "FanSpeed": 3501,
      "DriveSpeed": 63963
    }, {
      "ID": 178,
      "TimeRemaining": 1337,
      "Temperature": 160,
      "FanSpeed": 3505,
      "DriveSpeed": 63969
    }, {
      "ID": 179,
      "TimeRemaining": 1335,
      "Temperature": 160,
      "FanSpeed": 3502,
      "DriveSpeed": 0
    }, {
      "ID": 180,
      "TimeRemaining": 1333,
      "Temperature": 157,
      "FanSpeed": 3502,
      "DriveSpeed": 0
    }, {
      "ID": 181,
      "TimeRemaining": 1331,
      "Temperature": 160,
      "FanSpeed": 3499,
      "DriveSpeed": 0
    }, {
      "ID": 182,
      "TimeRemaining": 1329,
      "Temperature": 160,
      "FanSpeed": 3500,
      "DriveSpeed": 960
    }, {
      "ID": 183,
      "TimeRemaining": 1327,
      "Temperature": 165,
      "FanSpeed": 3498,
      "DriveSpeed": 1556
    }, {
      "ID": 184,
      "TimeRemaining": 1325,
      "Temperature": 165,
      "FanSpeed": 3497,
      "DriveSpeed": 1568
    }, {
      "ID": 185,
      "TimeRemaining": 1323,
      "Temperature": 170,
      "FanSpeed": 3497,
      "DriveSpeed": 1571
    }, {
      "ID": 186,
      "TimeRemaining": 1321,
      "Temperature": 170,
      "FanSpeed": 3499,
      "DriveSpeed": 1572
    }, {
      "ID": 187,
      "TimeRemaining": 1319,
      "Temperature": 170,
      "FanSpeed": 3500,
      "DriveSpeed": 1565
    }, {
      "ID": 188,
      "TimeRemaining": 1317,
      "Temperature": 170,
      "FanSpeed": 3499,
      "DriveSpeed": 1575
    }, {
      "ID": 189,
      "TimeRemaining": 1315,
      "Temperature": 170,
      "FanSpeed": 3501,
      "DriveSpeed": 1578
    }, {
      "ID": 190,
      "TimeRemaining": 1313,
      "Temperature": 170,
      "FanSpeed": 3496,
      "DriveSpeed": 1566
    }, {
      "ID": 191,
      "TimeRemaining": 1311,
      "Temperature": 170,
      "FanSpeed": 3501,
      "DriveSpeed": 1586
    }, {
      "ID": 192,
      "TimeRemaining": 1309,
      "Temperature": 170,
      "FanSpeed": 3503,
      "DriveSpeed": 1575
    }, {
      "ID": 193,
      "TimeRemaining": 1307,
      "Temperature": 171,
      "FanSpeed": 3499,
      "DriveSpeed": 1569
    }, {
      "ID": 194,
      "TimeRemaining": 1305,
      "Temperature": 171,
      "FanSpeed": 3498,
      "DriveSpeed": 1564
    }, {
      "ID": 195,
      "TimeRemaining": 1303,
      "Temperature": 171,
      "FanSpeed": 3499,
      "DriveSpeed": 1570
    }, {
      "ID": 196,
      "TimeRemaining": 1301,
      "Temperature": 171,
      "FanSpeed": 3499,
      "DriveSpeed": 0
    }, {
      "ID": 197,
      "TimeRemaining": 1299,
      "Temperature": 171,
      "FanSpeed": 3503,
      "DriveSpeed": 0
    }, {
      "ID": 198,
      "TimeRemaining": 1297,
      "Temperature": 171,
      "FanSpeed": 3501,
      "DriveSpeed": 0
    }, {
      "ID": 199,
      "TimeRemaining": 1295,
      "Temperature": 171,
      "FanSpeed": 3502,
      "DriveSpeed": 0
    }, {
      "ID": 200,
      "TimeRemaining": 1293,
      "Temperature": 172,
      "FanSpeed": 3499,
      "DriveSpeed": 65044
    }, {
      "ID": 201,
      "TimeRemaining": 1291,
      "Temperature": 172,
      "FanSpeed": 3500,
      "DriveSpeed": 63961
    }, {
      "ID": 202,
      "TimeRemaining": 1289,
      "Temperature": 173,
      "FanSpeed": 3495,
      "DriveSpeed": 63975
    }, {
      "ID": 203,
      "TimeRemaining": 1287,
      "Temperature": 173,
      "FanSpeed": 3500,
      "DriveSpeed": 63968
    }, {
      "ID": 204,
      "TimeRemaining": 1285,
      "Temperature": 173,
      "FanSpeed": 3501,
      "DriveSpeed": 63965
    }, {
      "ID": 205,
      "TimeRemaining": 1283,
      "Temperature": 173,
      "FanSpeed": 3497,
      "DriveSpeed": 63969
    }, {
      "ID": 206,
      "TimeRemaining": 1281,
      "Temperature": 173,
      "FanSpeed": 3503,
      "DriveSpeed": 63963
    }, {
      "ID": 207,
      "TimeRemaining": 1279,
      "Temperature": 173,
      "FanSpeed": 3499,
      "DriveSpeed": 63969
    }, {
      "ID": 208,
      "TimeRemaining": 1277,
      "Temperature": 173,
      "FanSpeed": 3499,
      "DriveSpeed": 63971
    }, {
      "ID": 209,
      "TimeRemaining": 1275,
      "Temperature": 173,
      "FanSpeed": 3498,
      "DriveSpeed": 63961
    }, {
      "ID": 210,
      "TimeRemaining": 1273,
      "Temperature": 173,
      "FanSpeed": 3497,
      "DriveSpeed": 63976
    }, {
      "ID": 211,
      "TimeRemaining": 1271,
      "Temperature": 175,
      "FanSpeed": 3505,
      "DriveSpeed": 63963
    }, {
      "ID": 212,
      "TimeRemaining": 1269,
      "Temperature": 175,
      "FanSpeed": 3502,
      "DriveSpeed": 63959
    }, {
      "ID": 213,
      "TimeRemaining": 1267,
      "Temperature": 175,
      "FanSpeed": 3502,
      "DriveSpeed": 63967
    }, {
      "ID": 214,
      "TimeRemaining": 1265,
      "Temperature": 175,
      "FanSpeed": 3503,
      "DriveSpeed": 63966
    }, {
      "ID": 215,
      "TimeRemaining": 1263,
      "Temperature": 175,
      "FanSpeed": 3503,
      "DriveSpeed": 0
    }, {
      "ID": 216,
      "TimeRemaining": 1261,
      "Temperature": 175,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 217,
      "TimeRemaining": 1259,
      "Temperature": 175,
      "FanSpeed": 3499,
      "DriveSpeed": 0
    }, {
      "ID": 218,
      "TimeRemaining": 1257,
      "Temperature": 180,
      "FanSpeed": 3500,
      "DriveSpeed": 453
    }, {
      "ID": 219,
      "TimeRemaining": 1255,
      "Temperature": 180,
      "FanSpeed": 3500,
      "DriveSpeed": 1573
    }, {
      "ID": 220,
      "TimeRemaining": 1253,
      "Temperature": 182,
      "FanSpeed": 3499,
      "DriveSpeed": 1575
    }, {
      "ID": 221,
      "TimeRemaining": 1251,
      "Temperature": 182,
      "FanSpeed": 3498,
      "DriveSpeed": 1570
    }, {
      "ID": 222,
      "TimeRemaining": 1249,
      "Temperature": 182,
      "FanSpeed": 3499,
      "DriveSpeed": 1580
    }, {
      "ID": 223,
      "TimeRemaining": 1247,
      "Temperature": 182,
      "FanSpeed": 3499,
      "DriveSpeed": 1566
    }, {
      "ID": 224,
      "TimeRemaining": 1245,
      "Temperature": 182,
      "FanSpeed": 3499,
      "DriveSpeed": 1574
    }, {
      "ID": 225,
      "TimeRemaining": 1243,
      "Temperature": 182,
      "FanSpeed": 3500,
      "DriveSpeed": 1570
    }, {
      "ID": 226,
      "TimeRemaining": 1241,
      "Temperature": 182,
      "FanSpeed": 3500,
      "DriveSpeed": 1565
    }, {
      "ID": 227,
      "TimeRemaining": 1239,
      "Temperature": 182,
      "FanSpeed": 3500,
      "DriveSpeed": 1582
    }, {
      "ID": 228,
      "TimeRemaining": 1237,
      "Temperature": 182,
      "FanSpeed": 3497,
      "DriveSpeed": 1573
    }, {
      "ID": 229,
      "TimeRemaining": 1235,
      "Temperature": 185,
      "FanSpeed": 3498,
      "DriveSpeed": 1566
    }, {
      "ID": 230,
      "TimeRemaining": 1233,
      "Temperature": 185,
      "FanSpeed": 3504,
      "DriveSpeed": 1570
    }, {
      "ID": 231,
      "TimeRemaining": 1231,
      "Temperature": 185,
      "FanSpeed": 3497,
      "DriveSpeed": 1568
    }, {
      "ID": 232,
      "TimeRemaining": 1229,
      "Temperature": 185,
      "FanSpeed": 3494,
      "DriveSpeed": 1569
    }, {
      "ID": 233,
      "TimeRemaining": 1227,
      "Temperature": 185,
      "FanSpeed": 3502,
      "DriveSpeed": 0
    }, {
      "ID": 234,
      "TimeRemaining": 1225,
      "Temperature": 185,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 235,
      "TimeRemaining": 1223,
      "Temperature": 187,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 236,
      "TimeRemaining": 1221,
      "Temperature": 187,
      "FanSpeed": 3499,
      "DriveSpeed": 64611
    }, {
      "ID": 237,
      "TimeRemaining": 1219,
      "Temperature": 187,
      "FanSpeed": 3497,
      "DriveSpeed": 63957
    }, {
      "ID": 238,
      "TimeRemaining": 1217,
      "Temperature": 190,
      "FanSpeed": 3495,
      "DriveSpeed": 63965
    }, {
      "ID": 239,
      "TimeRemaining": 1215,
      "Temperature": 190,
      "FanSpeed": 3502,
      "DriveSpeed": 63971
    }, {
      "ID": 240,
      "TimeRemaining": 1213,
      "Temperature": 190,
      "FanSpeed": 3495,
      "DriveSpeed": 63953
    }, {
      "ID": 241,
      "TimeRemaining": 1211,
      "Temperature": 190,
      "FanSpeed": 3495,
      "DriveSpeed": 63965
    }, {
      "ID": 242,
      "TimeRemaining": 1209,
      "Temperature": 190,
      "FanSpeed": 3496,
      "DriveSpeed": 63961
    }, {
      "ID": 243,
      "TimeRemaining": 1207,
      "Temperature": 187,
      "FanSpeed": 3494,
      "DriveSpeed": 63967
    }, {
      "ID": 244,
      "TimeRemaining": 1205,
      "Temperature": 185,
      "FanSpeed": 3497,
      "DriveSpeed": 63960
    }, {
      "ID": 245,
      "TimeRemaining": 1203,
      "Temperature": 182,
      "FanSpeed": 3496,
      "DriveSpeed": 63955
    }, {
      "ID": 246,
      "TimeRemaining": 1201,
      "Temperature": 175,
      "FanSpeed": 3503,
      "DriveSpeed": 63972
    }, {
      "ID": 247,
      "TimeRemaining": 1199,
      "Temperature": 173,
      "FanSpeed": 3499,
      "DriveSpeed": 63969
    }, {
      "ID": 248,
      "TimeRemaining": 1197,
      "Temperature": 172,
      "FanSpeed": 3501,
      "DriveSpeed": 63966
    }, {
      "ID": 249,
      "TimeRemaining": 1195,
      "Temperature": 171,
      "FanSpeed": 3503,
      "DriveSpeed": 63962
    }, {
      "ID": 250,
      "TimeRemaining": 1193,
      "Temperature": 170,
      "FanSpeed": 3508,
      "DriveSpeed": 63960
    }, {
      "ID": 251,
      "TimeRemaining": 1191,
      "Temperature": 165,
      "FanSpeed": 3503,
      "DriveSpeed": 0
    }, {
      "ID": 252,
      "TimeRemaining": 1189,
      "Temperature": 165,
      "FanSpeed": 3502,
      "DriveSpeed": 0
    }, {
      "ID": 253,
      "TimeRemaining": 1187,
      "Temperature": 165,
      "FanSpeed": 3501,
      "DriveSpeed": 0
    }, {
      "ID": 254,
      "TimeRemaining": 1185,
      "Temperature": 170,
      "FanSpeed": 3501,
      "DriveSpeed": 886
    }, {
      "ID": 255,
      "TimeRemaining": 1183,
      "Temperature": 171,
      "FanSpeed": 3495,
      "DriveSpeed": 1567
    }, {
      "ID": 0,
      "TimeRemaining": 1181,
      "Temperature": 171,
      "FanSpeed": 3498,
      "DriveSpeed": 1566
    }, {
      "ID": 1,
      "TimeRemaining": 1179,
      "Temperature": 171,
      "FanSpeed": 3498,
      "DriveSpeed": 1570
    }, {
      "ID": 2,
      "TimeRemaining": 1177,
      "Temperature": 171,
      "FanSpeed": 3498,
      "DriveSpeed": 1567
    }, {
      "ID": 3,
      "TimeRemaining": 1175,
      "Temperature": 170,
      "FanSpeed": 3498,
      "DriveSpeed": 1564
    }, {
      "ID": 4,
      "TimeRemaining": 1173,
      "Temperature": 170,
      "FanSpeed": 3502,
      "DriveSpeed": 1570
    }, {
      "ID": 5,
      "TimeRemaining": 1171,
      "Temperature": 170,
      "FanSpeed": 3502,
      "DriveSpeed": 1566
    }, {
      "ID": 6,
      "TimeRemaining": 1169,
      "Temperature": 170,
      "FanSpeed": 3496,
      "DriveSpeed": 1563
    }, {
      "ID": 7,
      "TimeRemaining": 1167,
      "Temperature": 171,
      "FanSpeed": 3499,
      "DriveSpeed": 1559
    }, {
      "ID": 8,
      "TimeRemaining": 1165,
      "Temperature": 171,
      "FanSpeed": 3496,
      "DriveSpeed": 1557
    }, {
      "ID": 9,
      "TimeRemaining": 1163,
      "Temperature": 171,
      "FanSpeed": 3502,
      "DriveSpeed": 1574
    }, {
      "ID": 10,
      "TimeRemaining": 1161,
      "Temperature": 171,
      "FanSpeed": 3497,
      "DriveSpeed": 1564
    }, {
      "ID": 11,
      "TimeRemaining": 1159,
      "Temperature": 171,
      "FanSpeed": 3498,
      "DriveSpeed": 1577
    }, {
      "ID": 12,
      "TimeRemaining": 1157,
      "Temperature": 171,
      "FanSpeed": 3500,
      "DriveSpeed": 1565
    }, {
      "ID": 13,
      "TimeRemaining": 1155,
      "Temperature": 171,
      "FanSpeed": 3502,
      "DriveSpeed": 0
    }, {
      "ID": 14,
      "TimeRemaining": 1153,
      "Temperature": 171,
      "FanSpeed": 3502,
      "DriveSpeed": 0
    }, {
      "ID": 15,
      "TimeRemaining": 1151,
      "Temperature": 172,
      "FanSpeed": 3501,
      "DriveSpeed": 0
    }, {
      "ID": 16,
      "TimeRemaining": 1149,
      "Temperature": 172,
      "FanSpeed": 3499,
      "DriveSpeed": 65133
    }, {
      "ID": 17,
      "TimeRemaining": 1147,
      "Temperature": 172,
      "FanSpeed": 3497,
      "DriveSpeed": 63961
    }, {
      "ID": 18,
      "TimeRemaining": 1145,
      "Temperature": 173,
      "FanSpeed": 3498,
      "DriveSpeed": 63965
    }, {
      "ID": 19,
      "TimeRemaining": 1143,
      "Temperature": 173,
      "FanSpeed": 3499,
      "DriveSpeed": 63967
    }, {
      "ID": 20,
      "TimeRemaining": 1141,
      "Temperature": 173,
      "FanSpeed": 3499,
      "DriveSpeed": 63974
    }, {
      "ID": 21,
      "TimeRemaining": 1139,
      "Temperature": 173,
      "FanSpeed": 3499,
      "DriveSpeed": 63960
    }, {
      "ID": 22,
      "TimeRemaining": 1137,
      "Temperature": 173,
      "FanSpeed": 3501,
      "DriveSpeed": 63966
    }, {
      "ID": 23,
      "TimeRemaining": 1135,
      "Temperature": 173,
      "FanSpeed": 3500,
      "DriveSpeed": 63963
    }, {
      "ID": 24,
      "TimeRemaining": 1133,
      "Temperature": 173,
      "FanSpeed": 3499,
      "DriveSpeed": 63982
    }, {
      "ID": 25,
      "TimeRemaining": 1131,
      "Temperature": 173,
      "FanSpeed": 3498,
      "DriveSpeed": 63969
    }, {
      "ID": 26,
      "TimeRemaining": 1129,
      "Temperature": 173,
      "FanSpeed": 3501,
      "DriveSpeed": 63968
    }, {
      "ID": 27,
      "TimeRemaining": 1127,
      "Temperature": 175,
      "FanSpeed": 3499,
      "DriveSpeed": 63971
    }, {
      "ID": 28,
      "TimeRemaining": 1125,
      "Temperature": 175,
      "FanSpeed": 3500,
      "DriveSpeed": 63967
    }, {
      "ID": 29,
      "TimeRemaining": 1123,
      "Temperature": 175,
      "FanSpeed": 3498,
      "DriveSpeed": 63964
    }, {
      "ID": 30,
      "TimeRemaining": 1121,
      "Temperature": 175,
      "FanSpeed": 3502,
      "DriveSpeed": 0
    }, {
      "ID": 31,
      "TimeRemaining": 1119,
      "Temperature": 175,
      "FanSpeed": 3502,
      "DriveSpeed": 0
    }, {
      "ID": 32,
      "TimeRemaining": 1117,
      "Temperature": 175,
      "FanSpeed": 3501,
      "DriveSpeed": 0
    }, {
      "ID": 33,
      "TimeRemaining": 1115,
      "Temperature": 175,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 34,
      "TimeRemaining": 1113,
      "Temperature": 180,
      "FanSpeed": 3500,
      "DriveSpeed": 442
    }, {
      "ID": 35,
      "TimeRemaining": 1111,
      "Temperature": 180,
      "FanSpeed": 3493,
      "DriveSpeed": 1579
    }, {
      "ID": 36,
      "TimeRemaining": 1109,
      "Temperature": 182,
      "FanSpeed": 3497,
      "DriveSpeed": 1573
    }, {
      "ID": 37,
      "TimeRemaining": 1107,
      "Temperature": 185,
      "FanSpeed": 3499,
      "DriveSpeed": 1573
    }, {
      "ID": 38,
      "TimeRemaining": 1105,
      "Temperature": 185,
      "FanSpeed": 3501,
      "DriveSpeed": 1568
    }, {
      "ID": 39,
      "TimeRemaining": 1103,
      "Temperature": 185,
      "FanSpeed": 3499,
      "DriveSpeed": 1566
    }, {
      "ID": 40,
      "TimeRemaining": 1101,
      "Temperature": 185,
      "FanSpeed": 3501,
      "DriveSpeed": 1563
    }, {
      "ID": 41,
      "TimeRemaining": 1099,
      "Temperature": 185,
      "FanSpeed": 3501,
      "DriveSpeed": 1567
    }, {
      "ID": 42,
      "TimeRemaining": 1097,
      "Temperature": 185,
      "FanSpeed": 3497,
      "DriveSpeed": 1570
    }, {
      "ID": 43,
      "TimeRemaining": 1095,
      "Temperature": 185,
      "FanSpeed": 3500,
      "DriveSpeed": 1568
    }, {
      "ID": 44,
      "TimeRemaining": 1093,
      "Temperature": 185,
      "FanSpeed": 3503,
      "DriveSpeed": 1564
    }, {
      "ID": 45,
      "TimeRemaining": 1091,
      "Temperature": 185,
      "FanSpeed": 3500,
      "DriveSpeed": 1568
    }, {
      "ID": 46,
      "TimeRemaining": 1089,
      "Temperature": 187,
      "FanSpeed": 3500,
      "DriveSpeed": 1570
    }, {
      "ID": 47,
      "TimeRemaining": 1087,
      "Temperature": 187,
      "FanSpeed": 3501,
      "DriveSpeed": 1562
    }, {
      "ID": 48,
      "TimeRemaining": 1085,
      "Temperature": 187,
      "FanSpeed": 3502,
      "DriveSpeed": 1573
    }, {
      "ID": 49,
      "TimeRemaining": 1083,
      "Temperature": 187,
      "FanSpeed": 3501,
      "DriveSpeed": 0
    }, {
      "ID": 50,
      "TimeRemaining": 1081,
      "Temperature": 187,
      "FanSpeed": 3499,
      "DriveSpeed": 0
    }, {
      "ID": 51,
      "TimeRemaining": 1079,
      "Temperature": 190,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 52,
      "TimeRemaining": 1077,
      "Temperature": 190,
      "FanSpeed": 3500,
      "DriveSpeed": 64653
    }, {
      "ID": 53,
      "TimeRemaining": 1075,
      "Temperature": 190,
      "FanSpeed": 3500,
      "DriveSpeed": 63956
    }, {
      "ID": 54,
      "TimeRemaining": 1073,
      "Temperature": 190,
      "FanSpeed": 3492,
      "DriveSpeed": 63970
    }, {
      "ID": 55,
      "TimeRemaining": 1071,
      "Temperature": 191,
      "FanSpeed": 3494,
      "DriveSpeed": 63959
    }, {
      "ID": 56,
      "TimeRemaining": 1069,
      "Temperature": 190,
      "FanSpeed": 3499,
      "DriveSpeed": 63969
    }, {
      "ID": 57,
      "TimeRemaining": 1067,
      "Temperature": 190,
      "FanSpeed": 3496,
      "DriveSpeed": 63960
    }, {
      "ID": 58,
      "TimeRemaining": 1065,
      "Temperature": 187,
      "FanSpeed": 3502,
      "DriveSpeed": 63965
    }, {
      "ID": 59,
      "TimeRemaining": 1063,
      "Temperature": 185,
      "FanSpeed": 3502,
      "DriveSpeed": 63968
    }, {
      "ID": 60,
      "TimeRemaining": 1061,
      "Temperature": 180,
      "FanSpeed": 3497,
      "DriveSpeed": 63966
    }, {
      "ID": 61,
      "TimeRemaining": 1059,
      "Temperature": 175,
      "FanSpeed": 3494,
      "DriveSpeed": 63963
    }, {
      "ID": 62,
      "TimeRemaining": 1057,
      "Temperature": 173,
      "FanSpeed": 3505,
      "DriveSpeed": 63977
    }, {
      "ID": 63,
      "TimeRemaining": 1055,
      "Temperature": 172,
      "FanSpeed": 3502,
      "DriveSpeed": 63962
    }, {
      "ID": 64,
      "TimeRemaining": 1053,
      "Temperature": 172,
      "FanSpeed": 3501,
      "DriveSpeed": 63968
    }, {
      "ID": 65,
      "TimeRemaining": 1051,
      "Temperature": 171,
      "FanSpeed": 3502,
      "DriveSpeed": 63969
    }, {
      "ID": 66,
      "TimeRemaining": 1049,
      "Temperature": 171,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 67,
      "TimeRemaining": 1047,
      "Temperature": 172,
      "FanSpeed": 3502,
      "DriveSpeed": 0
    }, {
      "ID": 68,
      "TimeRemaining": 1045,
      "Temperature": 172,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 69,
      "TimeRemaining": 1043,
      "Temperature": 172,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 70,
      "TimeRemaining": 1041,
      "Temperature": 173,
      "FanSpeed": 3499,
      "DriveSpeed": 926
    }, {
      "ID": 71,
      "TimeRemaining": 1039,
      "Temperature": 175,
      "FanSpeed": 3500,
      "DriveSpeed": 1577
    }, {
      "ID": 72,
      "TimeRemaining": 1037,
      "Temperature": 180,
      "FanSpeed": 3499,
      "DriveSpeed": 1571
    }, {
      "ID": 73,
      "TimeRemaining": 1035,
      "Temperature": 180,
      "FanSpeed": 3498,
      "DriveSpeed": 1567
    }, {
      "ID": 74,
      "TimeRemaining": 1033,
      "Temperature": 180,
      "FanSpeed": 3499,
      "DriveSpeed": 1565
    }, {
      "ID": 75,
      "TimeRemaining": 1031,
      "Temperature": 180,
      "FanSpeed": 3501,
      "DriveSpeed": 1564
    }, {
      "ID": 76,
      "TimeRemaining": 1029,
      "Temperature": 180,
      "FanSpeed": 3495,
      "DriveSpeed": 1570
    }, {
      "ID": 77,
      "TimeRemaining": 1027,
      "Temperature": 180,
      "FanSpeed": 3497,
      "DriveSpeed": 1570
    }, {
      "ID": 78,
      "TimeRemaining": 1025,
      "Temperature": 180,
      "FanSpeed": 3505,
      "DriveSpeed": 1571
    }, {
      "ID": 79,
      "TimeRemaining": 1023,
      "Temperature": 180,
      "FanSpeed": 3497,
      "DriveSpeed": 1573
    }, {
      "ID": 80,
      "TimeRemaining": 1021,
      "Temperature": 180,
      "FanSpeed": 3498,
      "DriveSpeed": 1576
    }, {
      "ID": 81,
      "TimeRemaining": 1019,
      "Temperature": 180,
      "FanSpeed": 3497,
      "DriveSpeed": 1564
    }, {
      "ID": 82,
      "TimeRemaining": 1017,
      "Temperature": 182,
      "FanSpeed": 3501,
      "DriveSpeed": 1571
    }, {
      "ID": 83,
      "TimeRemaining": 1015,
      "Temperature": 182,
      "FanSpeed": 3502,
      "DriveSpeed": 1567
    }, {
      "ID": 84,
      "TimeRemaining": 1013,
      "Temperature": 182,
      "FanSpeed": 3500,
      "DriveSpeed": 1563
    }, {
      "ID": 85,
      "TimeRemaining": 1011,
      "Temperature": 182,
      "FanSpeed": 3502,
      "DriveSpeed": 0
    }, {
      "ID": 86,
      "TimeRemaining": 1009,
      "Temperature": 182,
      "FanSpeed": 3501,
      "DriveSpeed": 0
    }, {
      "ID": 87,
      "TimeRemaining": 1007,
      "Temperature": 182,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 88,
      "TimeRemaining": 1005,
      "Temperature": 185,
      "FanSpeed": 3500,
      "DriveSpeed": 65092
    }, {
      "ID": 89,
      "TimeRemaining": 1003,
      "Temperature": 185,
      "FanSpeed": 3500,
      "DriveSpeed": 63964
    }, {
      "ID": 90,
      "TimeRemaining": 1001,
      "Temperature": 187,
      "FanSpeed": 3497,
      "DriveSpeed": 63972
    }, {
      "ID": 91,
      "TimeRemaining": 999,
      "Temperature": 187,
      "FanSpeed": 3501,
      "DriveSpeed": 63965
    }, {
      "ID": 92,
      "TimeRemaining": 997,
      "Temperature": 187,
      "FanSpeed": 3499,
      "DriveSpeed": 63956
    }, {
      "ID": 93,
      "TimeRemaining": 995,
      "Temperature": 190,
      "FanSpeed": 3501,
      "DriveSpeed": 63972
    }, {
      "ID": 94,
      "TimeRemaining": 993,
      "Temperature": 190,
      "FanSpeed": 3499,
      "DriveSpeed": 63965
    }, {
      "ID": 95,
      "TimeRemaining": 991,
      "Temperature": 190,
      "FanSpeed": 3495,
      "DriveSpeed": 63964
    }, {
      "ID": 96,
      "TimeRemaining": 989,
      "Temperature": 190,
      "FanSpeed": 3503,
      "DriveSpeed": 63956
    }, {
      "ID": 97,
      "TimeRemaining": 987,
      "Temperature": 190,
      "FanSpeed": 3497,
      "DriveSpeed": 63969
    }, {
      "ID": 98,
      "TimeRemaining": 985,
      "Temperature": 190,
      "FanSpeed": 3501,
      "DriveSpeed": 63970
    }, {
      "ID": 99,
      "TimeRemaining": 983,
      "Temperature": 187,
      "FanSpeed": 3496,
      "DriveSpeed": 63957
    }, {
      "ID": 100,
      "TimeRemaining": 981,
      "Temperature": 185,
      "FanSpeed": 3500,
      "DriveSpeed": 63966
    }, {
      "ID": 101,
      "TimeRemaining": 979,
      "Temperature": 182,
      "FanSpeed": 3498,
      "DriveSpeed": 63962
    }, {
      "ID": 102,
      "TimeRemaining": 977,
      "Temperature": 175,
      "FanSpeed": 3501,
      "DriveSpeed": 63971
    }, {
      "ID": 103,
      "TimeRemaining": 975,
      "Temperature": 173,
      "FanSpeed": 3501,
      "DriveSpeed": 0
    }, {
      "ID": 104,
      "TimeRemaining": 973,
      "Temperature": 172,
      "FanSpeed": 3502,
      "DriveSpeed": 0
    }, {
      "ID": 105,
      "TimeRemaining": 971,
      "Temperature": 171,
      "FanSpeed": 3503,
      "DriveSpeed": 0
    }, {
      "ID": 106,
      "TimeRemaining": 969,
      "Temperature": 170,
      "FanSpeed": 3502,
      "DriveSpeed": 888
    }, {
      "ID": 107,
      "TimeRemaining": 967,
      "Temperature": 170,
      "FanSpeed": 3503,
      "DriveSpeed": 1590
    }, {
      "ID": 108,
      "TimeRemaining": 965,
      "Temperature": 171,
      "FanSpeed": 3500,
      "DriveSpeed": 1575
    }, {
      "ID": 109,
      "TimeRemaining": 963,
      "Temperature": 171,
      "FanSpeed": 3500,
      "DriveSpeed": 1560
    }, {
      "ID": 110,
      "TimeRemaining": 961,
      "Temperature": 171,
      "FanSpeed": 3502,
      "DriveSpeed": 1565
    }, {
      "ID": 111,
      "TimeRemaining": 959,
      "Temperature": 171,
      "FanSpeed": 3498,
      "DriveSpeed": 1577
    }, {
      "ID": 112,
      "TimeRemaining": 957,
      "Temperature": 171,
      "FanSpeed": 3500,
      "DriveSpeed": 1577
    }, {
      "ID": 113,
      "TimeRemaining": 955,
      "Temperature": 171,
      "FanSpeed": 3504,
      "DriveSpeed": 1570
    }, {
      "ID": 114,
      "TimeRemaining": 953,
      "Temperature": 172,
      "FanSpeed": 3497,
      "DriveSpeed": 1575
    }, {
      "ID": 115,
      "TimeRemaining": 951,
      "Temperature": 172,
      "FanSpeed": 3502,
      "DriveSpeed": 1577
    }, {
      "ID": 116,
      "TimeRemaining": 949,
      "Temperature": 172,
      "FanSpeed": 3501,
      "DriveSpeed": 1573
    }, {
      "ID": 117,
      "TimeRemaining": 947,
      "Temperature": 173,
      "FanSpeed": 3497,
      "DriveSpeed": 1567
    }, {
      "ID": 118,
      "TimeRemaining": 945,
      "Temperature": 173,
      "FanSpeed": 3503,
      "DriveSpeed": 1573
    }, {
      "ID": 119,
      "TimeRemaining": 943,
      "Temperature": 173,
      "FanSpeed": 3502,
      "DriveSpeed": 1562
    }, {
      "ID": 120,
      "TimeRemaining": 941,
      "Temperature": 173,
      "FanSpeed": 3495,
      "DriveSpeed": 1569
    }, {
      "ID": 121,
      "TimeRemaining": 939,
      "Temperature": 173,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 122,
      "TimeRemaining": 937,
      "Temperature": 173,
      "FanSpeed": 3501,
      "DriveSpeed": 0
    }, {
      "ID": 123,
      "TimeRemaining": 935,
      "Temperature": 175,
      "FanSpeed": 3501,
      "DriveSpeed": 0
    }, {
      "ID": 124,
      "TimeRemaining": 933,
      "Temperature": 175,
      "FanSpeed": 3500,
      "DriveSpeed": 64576
    }, {
      "ID": 125,
      "TimeRemaining": 931,
      "Temperature": 180,
      "FanSpeed": 3500,
      "DriveSpeed": 63970
    }, {
      "ID": 126,
      "TimeRemaining": 929,
      "Temperature": 180,
      "FanSpeed": 3497,
      "DriveSpeed": 63958
    }, {
      "ID": 127,
      "TimeRemaining": 927,
      "Temperature": 182,
      "FanSpeed": 3501,
      "DriveSpeed": 63959
    }, {
      "ID": 128,
      "TimeRemaining": 925,
      "Temperature": 182,
      "FanSpeed": 3499,
      "DriveSpeed": 63972
    }, {
      "ID": 129,
      "TimeRemaining": 923,
      "Temperature": 182,
      "FanSpeed": 3499,
      "DriveSpeed": 63965
    }, {
      "ID": 130,
      "TimeRemaining": 921,
      "Temperature": 182,
      "FanSpeed": 3497,
      "DriveSpeed": 63962
    }, {
      "ID": 131,
      "TimeRemaining": 919,
      "Temperature": 182,
      "FanSpeed": 3500,
      "DriveSpeed": 63966
    }, {
      "ID": 132,
      "TimeRemaining": 917,
      "Temperature": 185,
      "FanSpeed": 3501,
      "DriveSpeed": 63964
    }, {
      "ID": 133,
      "TimeRemaining": 915,
      "Temperature": 185,
      "FanSpeed": 3501,
      "DriveSpeed": 63965
    }, {
      "ID": 134,
      "TimeRemaining": 913,
      "Temperature": 185,
      "FanSpeed": 3503,
      "DriveSpeed": 63970
    }, {
      "ID": 135,
      "TimeRemaining": 911,
      "Temperature": 185,
      "FanSpeed": 3496,
      "DriveSpeed": 63966
    }, {
      "ID": 136,
      "TimeRemaining": 909,
      "Temperature": 185,
      "FanSpeed": 3504,
      "DriveSpeed": 63973
    }, {
      "ID": 137,
      "TimeRemaining": 907,
      "Temperature": 187,
      "FanSpeed": 3500,
      "DriveSpeed": 63972
    }, {
      "ID": 138,
      "TimeRemaining": 905,
      "Temperature": 187,
      "FanSpeed": 3503,
      "DriveSpeed": 0
    }, {
      "ID": 139,
      "TimeRemaining": 903,
      "Temperature": 187,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 140,
      "TimeRemaining": 901,
      "Temperature": 187,
      "FanSpeed": 3501,
      "DriveSpeed": 0
    }, {
      "ID": 141,
      "TimeRemaining": 899,
      "Temperature": 187,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 142,
      "TimeRemaining": 897,
      "Temperature": 190,
      "FanSpeed": 3500,
      "DriveSpeed": 489
    }, {
      "ID": 143,
      "TimeRemaining": 895,
      "Temperature": 190,
      "FanSpeed": 3499,
      "DriveSpeed": 1564
    }, {
      "ID": 144,
      "TimeRemaining": 893,
      "Temperature": 190,
      "FanSpeed": 3496,
      "DriveSpeed": 1571
    }, {
      "ID": 145,
      "TimeRemaining": 891,
      "Temperature": 190,
      "FanSpeed": 3502,
      "DriveSpeed": 1569
    }, {
      "ID": 146,
      "TimeRemaining": 889,
      "Temperature": 190,
      "FanSpeed": 3497,
      "DriveSpeed": 1563
    }, {
      "ID": 147,
      "TimeRemaining": 887,
      "Temperature": 190,
      "FanSpeed": 3495,
      "DriveSpeed": 1576
    }, {
      "ID": 148,
      "TimeRemaining": 885,
      "Temperature": 190,
      "FanSpeed": 3499,
      "DriveSpeed": 1575
    }, {
      "ID": 149,
      "TimeRemaining": 883,
      "Temperature": 187,
      "FanSpeed": 3500,
      "DriveSpeed": 1569
    }, {
      "ID": 150,
      "TimeRemaining": 881,
      "Temperature": 185,
      "FanSpeed": 3499,
      "DriveSpeed": 1570
    }, {
      "ID": 151,
      "TimeRemaining": 879,
      "Temperature": 180,
      "FanSpeed": 3498,
      "DriveSpeed": 1566
    }, {
      "ID": 152,
      "TimeRemaining": 877,
      "Temperature": 175,
      "FanSpeed": 3497,
      "DriveSpeed": 1571
    }, {
      "ID": 153,
      "TimeRemaining": 875,
      "Temperature": 173,
      "FanSpeed": 3501,
      "DriveSpeed": 1570
    }, {
      "ID": 154,
      "TimeRemaining": 873,
      "Temperature": 172,
      "FanSpeed": 3504,
      "DriveSpeed": 1567
    }, {
      "ID": 155,
      "TimeRemaining": 871,
      "Temperature": 171,
      "FanSpeed": 3501,
      "DriveSpeed": 1579
    }, {
      "ID": 156,
      "TimeRemaining": 869,
      "Temperature": 171,
      "FanSpeed": 3501,
      "DriveSpeed": 0
    }, {
      "ID": 157,
      "TimeRemaining": 867,
      "Temperature": 171,
      "FanSpeed": 3503,
      "DriveSpeed": 0
    }, {
      "ID": 158,
      "TimeRemaining": 865,
      "Temperature": 172,
      "FanSpeed": 3502,
      "DriveSpeed": 0
    }, {
      "ID": 159,
      "TimeRemaining": 863,
      "Temperature": 172,
      "FanSpeed": 3501,
      "DriveSpeed": 0
    }, {
      "ID": 160,
      "TimeRemaining": 861,
      "Temperature": 173,
      "FanSpeed": 3499,
      "DriveSpeed": 65050
    }, {
      "ID": 161,
      "TimeRemaining": 859,
      "Temperature": 173,
      "FanSpeed": 3497,
      "DriveSpeed": 63975
    }, {
      "ID": 162,
      "TimeRemaining": 857,
      "Temperature": 175,
      "FanSpeed": 3496,
      "DriveSpeed": 63967
    }, {
      "ID": 163,
      "TimeRemaining": 855,
      "Temperature": 175,
      "FanSpeed": 3501,
      "DriveSpeed": 63971
    }, {
      "ID": 164,
      "TimeRemaining": 853,
      "Temperature": 180,
      "FanSpeed": 3499,
      "DriveSpeed": 63974
    }, {
      "ID": 165,
      "TimeRemaining": 851,
      "Temperature": 180,
      "FanSpeed": 3501,
      "DriveSpeed": 63971
    }, {
      "ID": 166,
      "TimeRemaining": 849,
      "Temperature": 180,
      "FanSpeed": 3500,
      "DriveSpeed": 63967
    }, {
      "ID": 167,
      "TimeRemaining": 847,
      "Temperature": 180,
      "FanSpeed": 3500,
      "DriveSpeed": 63971
    }, {
      "ID": 168,
      "TimeRemaining": 845,
      "Temperature": 180,
      "FanSpeed": 3498,
      "DriveSpeed": 63965
    }, {
      "ID": 169,
      "TimeRemaining": 843,
      "Temperature": 180,
      "FanSpeed": 3501,
      "DriveSpeed": 63972
    }, {
      "ID": 170,
      "TimeRemaining": 841,
      "Temperature": 182,
      "FanSpeed": 3500,
      "DriveSpeed": 63969
    }, {
      "ID": 171,
      "TimeRemaining": 839,
      "Temperature": 182,
      "FanSpeed": 3500,
      "DriveSpeed": 63972
    }, {
      "ID": 172,
      "TimeRemaining": 837,
      "Temperature": 182,
      "FanSpeed": 3500,
      "DriveSpeed": 63969
    }, {
      "ID": 173,
      "TimeRemaining": 835,
      "Temperature": 182,
      "FanSpeed": 3499,
      "DriveSpeed": 63966
    }, {
      "ID": 174,
      "TimeRemaining": 833,
      "Temperature": 182,
      "FanSpeed": 3498,
      "DriveSpeed": 63964
    }, {
      "ID": 175,
      "TimeRemaining": 831,
      "Temperature": 182,
      "FanSpeed": 3499,
      "DriveSpeed": 0
    }, {
      "ID": 176,
      "TimeRemaining": 829,
      "Temperature": 182,
      "FanSpeed": 3501,
      "DriveSpeed": 0
    }, {
      "ID": 177,
      "TimeRemaining": 827,
      "Temperature": 185,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 178,
      "TimeRemaining": 825,
      "Temperature": 185,
      "FanSpeed": 3501,
      "DriveSpeed": 929
    }, {
      "ID": 179,
      "TimeRemaining": 823,
      "Temperature": 185,
      "FanSpeed": 3500,
      "DriveSpeed": 1559
    }, {
      "ID": 180,
      "TimeRemaining": 821,
      "Temperature": 187,
      "FanSpeed": 3499,
      "DriveSpeed": 1570
    }, {
      "ID": 181,
      "TimeRemaining": 819,
      "Temperature": 187,
      "FanSpeed": 3498,
      "DriveSpeed": 1567
    }, {
      "ID": 182,
      "TimeRemaining": 817,
      "Temperature": 190,
      "FanSpeed": 3500,
      "DriveSpeed": 1569
    }, {
      "ID": 183,
      "TimeRemaining": 815,
      "Temperature": 190,
      "FanSpeed": 3501,
      "DriveSpeed": 1578
    }, {
      "ID": 184,
      "TimeRemaining": 813,
      "Temperature": 190,
      "FanSpeed": 3502,
      "DriveSpeed": 1563
    }, {
      "ID": 185,
      "TimeRemaining": 811,
      "Temperature": 190,
      "FanSpeed": 3500,
      "DriveSpeed": 1572
    }, {
      "ID": 186,
      "TimeRemaining": 809,
      "Temperature": 190,
      "FanSpeed": 3495,
      "DriveSpeed": 1580
    }, {
      "ID": 187,
      "TimeRemaining": 807,
      "Temperature": 190,
      "FanSpeed": 3498,
      "DriveSpeed": 1579
    }, {
      "ID": 188,
      "TimeRemaining": 805,
      "Temperature": 187,
      "FanSpeed": 3498,
      "DriveSpeed": 1575
    }, {
      "ID": 189,
      "TimeRemaining": 803,
      "Temperature": 185,
      "FanSpeed": 3499,
      "DriveSpeed": 1574
    }, {
      "ID": 190,
      "TimeRemaining": 801,
      "Temperature": 182,
      "FanSpeed": 3498,
      "DriveSpeed": 1570
    }, {
      "ID": 191,
      "TimeRemaining": 799,
      "Temperature": 180,
      "FanSpeed": 3496,
      "DriveSpeed": 1573
    }, {
      "ID": 192,
      "TimeRemaining": 797,
      "Temperature": 173,
      "FanSpeed": 3500,
      "DriveSpeed": 1568
    }, {
      "ID": 193,
      "TimeRemaining": 795,
      "Temperature": 172,
      "FanSpeed": 3498,
      "DriveSpeed": 0
    }, {
      "ID": 194,
      "TimeRemaining": 793,
      "Temperature": 171,
      "FanSpeed": 3503,
      "DriveSpeed": 0
    }, {
      "ID": 195,
      "TimeRemaining": 791,
      "Temperature": 170,
      "FanSpeed": 3502,
      "DriveSpeed": 0
    }, {
      "ID": 196,
      "TimeRemaining": 789,
      "Temperature": 170,
      "FanSpeed": 3500,
      "DriveSpeed": 64569
    }, {
      "ID": 197,
      "TimeRemaining": 787,
      "Temperature": 171,
      "FanSpeed": 3498,
      "DriveSpeed": 63968
    }, {
      "ID": 198,
      "TimeRemaining": 785,
      "Temperature": 171,
      "FanSpeed": 3503,
      "DriveSpeed": 63972
    }, {
      "ID": 199,
      "TimeRemaining": 783,
      "Temperature": 172,
      "FanSpeed": 3497,
      "DriveSpeed": 63967
    }, {
      "ID": 200,
      "TimeRemaining": 781,
      "Temperature": 172,
      "FanSpeed": 3497,
      "DriveSpeed": 63969
    }, {
      "ID": 201,
      "TimeRemaining": 779,
      "Temperature": 172,
      "FanSpeed": 3501,
      "DriveSpeed": 63966
    }, {
      "ID": 202,
      "TimeRemaining": 777,
      "Temperature": 173,
      "FanSpeed": 3497,
      "DriveSpeed": 63964
    }, {
      "ID": 203,
      "TimeRemaining": 775,
      "Temperature": 173,
      "FanSpeed": 3497,
      "DriveSpeed": 63963
    }, {
      "ID": 204,
      "TimeRemaining": 773,
      "Temperature": 173,
      "FanSpeed": 3500,
      "DriveSpeed": 63968
    }, {
      "ID": 205,
      "TimeRemaining": 771,
      "Temperature": 173,
      "FanSpeed": 3499,
      "DriveSpeed": 63967
    }, {
      "ID": 206,
      "TimeRemaining": 769,
      "Temperature": 173,
      "FanSpeed": 3499,
      "DriveSpeed": 63966
    }, {
      "ID": 207,
      "TimeRemaining": 767,
      "Temperature": 173,
      "FanSpeed": 3498,
      "DriveSpeed": 63962
    }, {
      "ID": 208,
      "TimeRemaining": 765,
      "Temperature": 175,
      "FanSpeed": 3500,
      "DriveSpeed": 63967
    }, {
      "ID": 209,
      "TimeRemaining": 763,
      "Temperature": 175,
      "FanSpeed": 3499,
      "DriveSpeed": 63972
    }, {
      "ID": 210,
      "TimeRemaining": 761,
      "Temperature": 175,
      "FanSpeed": 3497,
      "DriveSpeed": 63963
    }, {
      "ID": 211,
      "TimeRemaining": 759,
      "Temperature": 175,
      "FanSpeed": 3501,
      "DriveSpeed": 0
    }, {
      "ID": 212,
      "TimeRemaining": 757,
      "Temperature": 175,
      "FanSpeed": 3501,
      "DriveSpeed": 0
    }, {
      "ID": 213,
      "TimeRemaining": 755,
      "Temperature": 175,
      "FanSpeed": 3499,
      "DriveSpeed": 0
    }, {
      "ID": 214,
      "TimeRemaining": 753,
      "Temperature": 180,
      "FanSpeed": 3499,
      "DriveSpeed": 442
    }, {
      "ID": 215,
      "TimeRemaining": 751,
      "Temperature": 180,
      "FanSpeed": 3497,
      "DriveSpeed": 1581
    }, {
      "ID": 216,
      "TimeRemaining": 749,
      "Temperature": 182,
      "FanSpeed": 3497,
      "DriveSpeed": 1574
    }, {
      "ID": 217,
      "TimeRemaining": 747,
      "Temperature": 182,
      "FanSpeed": 3499,
      "DriveSpeed": 1571
    }, {
      "ID": 218,
      "TimeRemaining": 745,
      "Temperature": 182,
      "FanSpeed": 3500,
      "DriveSpeed": 1563
    }, {
      "ID": 219,
      "TimeRemaining": 743,
      "Temperature": 182,
      "FanSpeed": 3503,
      "DriveSpeed": 1571
    }, {
      "ID": 220,
      "TimeRemaining": 741,
      "Temperature": 185,
      "FanSpeed": 3499,
      "DriveSpeed": 1568
    }, {
      "ID": 221,
      "TimeRemaining": 739,
      "Temperature": 185,
      "FanSpeed": 3497,
      "DriveSpeed": 1565
    }, {
      "ID": 222,
      "TimeRemaining": 737,
      "Temperature": 185,
      "FanSpeed": 3506,
      "DriveSpeed": 1580
    }, {
      "ID": 223,
      "TimeRemaining": 735,
      "Temperature": 185,
      "FanSpeed": 3495,
      "DriveSpeed": 1569
    }, {
      "ID": 224,
      "TimeRemaining": 733,
      "Temperature": 187,
      "FanSpeed": 3498,
      "DriveSpeed": 1568
    }, {
      "ID": 225,
      "TimeRemaining": 731,
      "Temperature": 187,
      "FanSpeed": 3505,
      "DriveSpeed": 1579
    }, {
      "ID": 226,
      "TimeRemaining": 729,
      "Temperature": 187,
      "FanSpeed": 3494,
      "DriveSpeed": 1566
    }, {
      "ID": 227,
      "TimeRemaining": 727,
      "Temperature": 190,
      "FanSpeed": 3502,
      "DriveSpeed": 1574
    }, {
      "ID": 228,
      "TimeRemaining": 725,
      "Temperature": 190,
      "FanSpeed": 3500,
      "DriveSpeed": 1576
    }, {
      "ID": 229,
      "TimeRemaining": 723,
      "Temperature": 190,
      "FanSpeed": 3501,
      "DriveSpeed": 0
    }, {
      "ID": 230,
      "TimeRemaining": 721,
      "Temperature": 190,
      "FanSpeed": 3495,
      "DriveSpeed": 0
    }, {
      "ID": 231,
      "TimeRemaining": 719,
      "Temperature": 190,
      "FanSpeed": 3496,
      "DriveSpeed": 0
    }, {
      "ID": 232,
      "TimeRemaining": 717,
      "Temperature": 190,
      "FanSpeed": 3498,
      "DriveSpeed": 64657
    }, {
      "ID": 233,
      "TimeRemaining": 715,
      "Temperature": 187,
      "FanSpeed": 3499,
      "DriveSpeed": 63945
    }, {
      "ID": 234,
      "TimeRemaining": 713,
      "Temperature": 185,
      "FanSpeed": 3498,
      "DriveSpeed": 63967
    }, {
      "ID": 235,
      "TimeRemaining": 711,
      "Temperature": 182,
      "FanSpeed": 3499,
      "DriveSpeed": 63967
    }, {
      "ID": 236,
      "TimeRemaining": 709,
      "Temperature": 180,
      "FanSpeed": 3500,
      "DriveSpeed": 63969
    }, {
      "ID": 237,
      "TimeRemaining": 707,
      "Temperature": 175,
      "FanSpeed": 3499,
      "DriveSpeed": 63966
    }, {
      "ID": 238,
      "TimeRemaining": 705,
      "Temperature": 173,
      "FanSpeed": 3501,
      "DriveSpeed": 63961
    }, {
      "ID": 239,
      "TimeRemaining": 703,
      "Temperature": 172,
      "FanSpeed": 3503,
      "DriveSpeed": 63962
    }, {
      "ID": 240,
      "TimeRemaining": 701,
      "Temperature": 172,
      "FanSpeed": 3502,
      "DriveSpeed": 63965
    }, {
      "ID": 241,
      "TimeRemaining": 699,
      "Temperature": 172,
      "FanSpeed": 3499,
      "DriveSpeed": 63960
    }, {
      "ID": 242,
      "TimeRemaining": 697,
      "Temperature": 172,
      "FanSpeed": 3501,
      "DriveSpeed": 63969
    }, {
      "ID": 243,
      "TimeRemaining": 695,
      "Temperature": 172,
      "FanSpeed": 3497,
      "DriveSpeed": 63971
    }, {
      "ID": 244,
      "TimeRemaining": 693,
      "Temperature": 173,
      "FanSpeed": 3497,
      "DriveSpeed": 63964
    }, {
      "ID": 245,
      "TimeRemaining": 691,
      "Temperature": 173,
      "FanSpeed": 3499,
      "DriveSpeed": 63958
    }, {
      "ID": 246,
      "TimeRemaining": 689,
      "Temperature": 173,
      "FanSpeed": 3498,
      "DriveSpeed": 63970
    }, {
      "ID": 247,
      "TimeRemaining": 687,
      "Temperature": 173,
      "FanSpeed": 3502,
      "DriveSpeed": 0
    }, {
      "ID": 248,
      "TimeRemaining": 685,
      "Temperature": 173,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 249,
      "TimeRemaining": 683,
      "Temperature": 173,
      "FanSpeed": 3499,
      "DriveSpeed": 0
    }, {
      "ID": 250,
      "TimeRemaining": 681,
      "Temperature": 175,
      "FanSpeed": 3502,
      "DriveSpeed": 882
    }, {
      "ID": 251,
      "TimeRemaining": 679,
      "Temperature": 175,
      "FanSpeed": 3500,
      "DriveSpeed": 1575
    }, {
      "ID": 252,
      "TimeRemaining": 677,
      "Temperature": 180,
      "FanSpeed": 3499,
      "DriveSpeed": 1572
    }, {
      "ID": 253,
      "TimeRemaining": 675,
      "Temperature": 180,
      "FanSpeed": 3499,
      "DriveSpeed": 1571
    }, {
      "ID": 254,
      "TimeRemaining": 673,
      "Temperature": 180,
      "FanSpeed": 3501,
      "DriveSpeed": 1565
    }, {
      "ID": 255,
      "TimeRemaining": 671,
      "Temperature": 182,
      "FanSpeed": 3501,
      "DriveSpeed": 1569
    }, {
      "ID": 0,
      "TimeRemaining": 669,
      "Temperature": 182,
      "FanSpeed": 3498,
      "DriveSpeed": 1573
    }, {
      "ID": 1,
      "TimeRemaining": 667,
      "Temperature": 182,
      "FanSpeed": 3498,
      "DriveSpeed": 1563
    }, {
      "ID": 2,
      "TimeRemaining": 665,
      "Temperature": 182,
      "FanSpeed": 3503,
      "DriveSpeed": 1562
    }, {
      "ID": 3,
      "TimeRemaining": 663,
      "Temperature": 185,
      "FanSpeed": 3496,
      "DriveSpeed": 1570
    }, {
      "ID": 4,
      "TimeRemaining": 661,
      "Temperature": 185,
      "FanSpeed": 3496,
      "DriveSpeed": 1569
    }, {
      "ID": 5,
      "TimeRemaining": 659,
      "Temperature": 187,
      "FanSpeed": 3504,
      "DriveSpeed": 1566
    }, {
      "ID": 6,
      "TimeRemaining": 657,
      "Temperature": 187,
      "FanSpeed": 3497,
      "DriveSpeed": 1575
    }, {
      "ID": 7,
      "TimeRemaining": 655,
      "Temperature": 190,
      "FanSpeed": 3498,
      "DriveSpeed": 1564
    }, {
      "ID": 8,
      "TimeRemaining": 653,
      "Temperature": 190,
      "FanSpeed": 3501,
      "DriveSpeed": 1573
    }, {
      "ID": 9,
      "TimeRemaining": 651,
      "Temperature": 190,
      "FanSpeed": 3501,
      "DriveSpeed": 0
    }, {
      "ID": 10,
      "TimeRemaining": 649,
      "Temperature": 190,
      "FanSpeed": 3497,
      "DriveSpeed": 0
    }, {
      "ID": 11,
      "TimeRemaining": 647,
      "Temperature": 190,
      "FanSpeed": 3496,
      "DriveSpeed": 0
    }, {
      "ID": 12,
      "TimeRemaining": 645,
      "Temperature": 190,
      "FanSpeed": 3497,
      "DriveSpeed": 64572
    }, {
      "ID": 13,
      "TimeRemaining": 643,
      "Temperature": 187,
      "FanSpeed": 3500,
      "DriveSpeed": 63971
    }, {
      "ID": 14,
      "TimeRemaining": 641,
      "Temperature": 185,
      "FanSpeed": 3499,
      "DriveSpeed": 63971
    }, {
      "ID": 15,
      "TimeRemaining": 639,
      "Temperature": 182,
      "FanSpeed": 3499,
      "DriveSpeed": 63965
    }, {
      "ID": 16,
      "TimeRemaining": 637,
      "Temperature": 180,
      "FanSpeed": 3498,
      "DriveSpeed": 63963
    }, {
      "ID": 17,
      "TimeRemaining": 635,
      "Temperature": 175,
      "FanSpeed": 3499,
      "DriveSpeed": 63964
    }, {
      "ID": 18,
      "TimeRemaining": 633,
      "Temperature": 173,
      "FanSpeed": 3503,
      "DriveSpeed": 63964
    }, {
      "ID": 19,
      "TimeRemaining": 631,
      "Temperature": 173,
      "FanSpeed": 3502,
      "DriveSpeed": 63977
    }, {
      "ID": 20,
      "TimeRemaining": 629,
      "Temperature": 172,
      "FanSpeed": 3505,
      "DriveSpeed": 63963
    }, {
      "ID": 21,
      "TimeRemaining": 627,
      "Temperature": 173,
      "FanSpeed": 3495,
      "DriveSpeed": 63973
    }, {
      "ID": 22,
      "TimeRemaining": 625,
      "Temperature": 173,
      "FanSpeed": 3504,
      "DriveSpeed": 63973
    }, {
      "ID": 23,
      "TimeRemaining": 623,
      "Temperature": 173,
      "FanSpeed": 3502,
      "DriveSpeed": 63967
    }, {
      "ID": 24,
      "TimeRemaining": 621,
      "Temperature": 175,
      "FanSpeed": 3498,
      "DriveSpeed": 63969
    }, {
      "ID": 25,
      "TimeRemaining": 619,
      "Temperature": 175,
      "FanSpeed": 3501,
      "DriveSpeed": 63976
    }, {
      "ID": 26,
      "TimeRemaining": 617,
      "Temperature": 180,
      "FanSpeed": 3501,
      "DriveSpeed": 63968
    }, {
      "ID": 27,
      "TimeRemaining": 615,
      "Temperature": 180,
      "FanSpeed": 3502,
      "DriveSpeed": 0
    }, {
      "ID": 28,
      "TimeRemaining": 613,
      "Temperature": 180,
      "FanSpeed": 3501,
      "DriveSpeed": 0
    }, {
      "ID": 29,
      "TimeRemaining": 611,
      "Temperature": 180,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 30,
      "TimeRemaining": 609,
      "Temperature": 182,
      "FanSpeed": 3498,
      "DriveSpeed": 403
    }, {
      "ID": 31,
      "TimeRemaining": 607,
      "Temperature": 182,
      "FanSpeed": 3496,
      "DriveSpeed": 1575
    }, {
      "ID": 32,
      "TimeRemaining": 605,
      "Temperature": 185,
      "FanSpeed": 3501,
      "DriveSpeed": 1564
    }, {
      "ID": 33,
      "TimeRemaining": 603,
      "Temperature": 185,
      "FanSpeed": 3497,
      "DriveSpeed": 1565
    }, {
      "ID": 34,
      "TimeRemaining": 601,
      "Temperature": 187,
      "FanSpeed": 3499,
      "DriveSpeed": 1568
    }, {
      "ID": 35,
      "TimeRemaining": 599,
      "Temperature": 187,
      "FanSpeed": 3501,
      "DriveSpeed": 1575
    }, {
      "ID": 36,
      "TimeRemaining": 597,
      "Temperature": 187,
      "FanSpeed": 3500,
      "DriveSpeed": 1568
    }, {
      "ID": 37,
      "TimeRemaining": 595,
      "Temperature": 190,
      "FanSpeed": 3501,
      "DriveSpeed": 1570
    }, {
      "ID": 38,
      "TimeRemaining": 593,
      "Temperature": 190,
      "FanSpeed": 3497,
      "DriveSpeed": 1570
    }, {
      "ID": 39,
      "TimeRemaining": 591,
      "Temperature": 190,
      "FanSpeed": 3502,
      "DriveSpeed": 1564
    }, {
      "ID": 40,
      "TimeRemaining": 589,
      "Temperature": 190,
      "FanSpeed": 3496,
      "DriveSpeed": 1575
    }, {
      "ID": 41,
      "TimeRemaining": 587,
      "Temperature": 190,
      "FanSpeed": 3495,
      "DriveSpeed": 1569
    }, {
      "ID": 42,
      "TimeRemaining": 585,
      "Temperature": 187,
      "FanSpeed": 3499,
      "DriveSpeed": 1571
    }, {
      "ID": 43,
      "TimeRemaining": 583,
      "Temperature": 187,
      "FanSpeed": 3499,
      "DriveSpeed": 1572
    }, {
      "ID": 44,
      "TimeRemaining": 581,
      "Temperature": 185,
      "FanSpeed": 3498,
      "DriveSpeed": 1575
    }, {
      "ID": 45,
      "TimeRemaining": 579,
      "Temperature": 180,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 46,
      "TimeRemaining": 577,
      "Temperature": 180,
      "FanSpeed": 3497,
      "DriveSpeed": 0
    }, {
      "ID": 47,
      "TimeRemaining": 575,
      "Temperature": 173,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 48,
      "TimeRemaining": 573,
      "Temperature": 172,
      "FanSpeed": 3506,
      "DriveSpeed": 64578
    }, {
      "ID": 49,
      "TimeRemaining": 571,
      "Temperature": 171,
      "FanSpeed": 3502,
      "DriveSpeed": 63966
    }, {
      "ID": 50,
      "TimeRemaining": 569,
      "Temperature": 171,
      "FanSpeed": 3503,
      "DriveSpeed": 63969
    }, {
      "ID": 51,
      "TimeRemaining": 567,
      "Temperature": 171,
      "FanSpeed": 3501,
      "DriveSpeed": 63964
    }, {
      "ID": 52,
      "TimeRemaining": 565,
      "Temperature": 171,
      "FanSpeed": 3500,
      "DriveSpeed": 63967
    }, {
      "ID": 53,
      "TimeRemaining": 563,
      "Temperature": 172,
      "FanSpeed": 3498,
      "DriveSpeed": 63969
    }, {
      "ID": 54,
      "TimeRemaining": 561,
      "Temperature": 172,
      "FanSpeed": 3499,
      "DriveSpeed": 63969
    }, {
      "ID": 55,
      "TimeRemaining": 559,
      "Temperature": 172,
      "FanSpeed": 3498,
      "DriveSpeed": 63974
    }, {
      "ID": 56,
      "TimeRemaining": 557,
      "Temperature": 173,
      "FanSpeed": 3501,
      "DriveSpeed": 63971
    }, {
      "ID": 57,
      "TimeRemaining": 555,
      "Temperature": 173,
      "FanSpeed": 3497,
      "DriveSpeed": 63964
    }, {
      "ID": 58,
      "TimeRemaining": 553,
      "Temperature": 173,
      "FanSpeed": 3497,
      "DriveSpeed": 63971
    }, {
      "ID": 59,
      "TimeRemaining": 551,
      "Temperature": 173,
      "FanSpeed": 3500,
      "DriveSpeed": 63969
    }, {
      "ID": 60,
      "TimeRemaining": 549,
      "Temperature": 175,
      "FanSpeed": 3498,
      "DriveSpeed": 63964
    }, {
      "ID": 61,
      "TimeRemaining": 547,
      "Temperature": 175,
      "FanSpeed": 3497,
      "DriveSpeed": 63971
    }, {
      "ID": 62,
      "TimeRemaining": 545,
      "Temperature": 180,
      "FanSpeed": 3503,
      "DriveSpeed": 63966
    }, {
      "ID": 63,
      "TimeRemaining": 543,
      "Temperature": 180,
      "FanSpeed": 3505,
      "DriveSpeed": 0
    }, {
      "ID": 64,
      "TimeRemaining": 541,
      "Temperature": 180,
      "FanSpeed": 3502,
      "DriveSpeed": 0
    }, {
      "ID": 65,
      "TimeRemaining": 539,
      "Temperature": 182,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 66,
      "TimeRemaining": 537,
      "Temperature": 185,
      "FanSpeed": 3498,
      "DriveSpeed": 923
    }, {
      "ID": 67,
      "TimeRemaining": 535,
      "Temperature": 187,
      "FanSpeed": 3498,
      "DriveSpeed": 1580
    }, {
      "ID": 68,
      "TimeRemaining": 533,
      "Temperature": 190,
      "FanSpeed": 3498,
      "DriveSpeed": 1562
    }, {
      "ID": 69,
      "TimeRemaining": 531,
      "Temperature": 190,
      "FanSpeed": 3497,
      "DriveSpeed": 1571
    }, {
      "ID": 70,
      "TimeRemaining": 529,
      "Temperature": 190,
      "FanSpeed": 3497,
      "DriveSpeed": 1572
    }, {
      "ID": 71,
      "TimeRemaining": 527,
      "Temperature": 190,
      "FanSpeed": 3503,
      "DriveSpeed": 1565
    }, {
      "ID": 72,
      "TimeRemaining": 525,
      "Temperature": 190,
      "FanSpeed": 3495,
      "DriveSpeed": 1570
    }, {
      "ID": 73,
      "TimeRemaining": 523,
      "Temperature": 190,
      "FanSpeed": 3497,
      "DriveSpeed": 1572
    }, {
      "ID": 74,
      "TimeRemaining": 521,
      "Temperature": 190,
      "FanSpeed": 3500,
      "DriveSpeed": 1566
    }, {
      "ID": 75,
      "TimeRemaining": 519,
      "Temperature": 187,
      "FanSpeed": 3500,
      "DriveSpeed": 1574
    }, {
      "ID": 76,
      "TimeRemaining": 517,
      "Temperature": 182,
      "FanSpeed": 3496,
      "DriveSpeed": 1578
    }, {
      "ID": 77,
      "TimeRemaining": 515,
      "Temperature": 182,
      "FanSpeed": 3500,
      "DriveSpeed": 1565
    }, {
      "ID": 78,
      "TimeRemaining": 513,
      "Temperature": 180,
      "FanSpeed": 3500,
      "DriveSpeed": 1564
    }, {
      "ID": 79,
      "TimeRemaining": 511,
      "Temperature": 173,
      "FanSpeed": 3501,
      "DriveSpeed": 1587
    }, {
      "ID": 80,
      "TimeRemaining": 509,
      "Temperature": 172,
      "FanSpeed": 3500,
      "DriveSpeed": 1567
    }, {
      "ID": 81,
      "TimeRemaining": 507,
      "Temperature": 172,
      "FanSpeed": 3505,
      "DriveSpeed": 0
    }, {
      "ID": 82,
      "TimeRemaining": 505,
      "Temperature": 172,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 83,
      "TimeRemaining": 503,
      "Temperature": 172,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 84,
      "TimeRemaining": 501,
      "Temperature": 173,
      "FanSpeed": 3502,
      "DriveSpeed": 65088
    }, {
      "ID": 85,
      "TimeRemaining": 499,
      "Temperature": 175,
      "FanSpeed": 3499,
      "DriveSpeed": 63965
    }, {
      "ID": 86,
      "TimeRemaining": 497,
      "Temperature": 180,
      "FanSpeed": 3499,
      "DriveSpeed": 63965
    }, {
      "ID": 87,
      "TimeRemaining": 495,
      "Temperature": 180,
      "FanSpeed": 3498,
      "DriveSpeed": 63974
    }, {
      "ID": 88,
      "TimeRemaining": 493,
      "Temperature": 180,
      "FanSpeed": 3498,
      "DriveSpeed": 63975
    }, {
      "ID": 89,
      "TimeRemaining": 491,
      "Temperature": 180,
      "FanSpeed": 3503,
      "DriveSpeed": 63972
    }, {
      "ID": 90,
      "TimeRemaining": 489,
      "Temperature": 182,
      "FanSpeed": 3496,
      "DriveSpeed": 63967
    }, {
      "ID": 91,
      "TimeRemaining": 487,
      "Temperature": 182,
      "FanSpeed": 3500,
      "DriveSpeed": 63964
    }, {
      "ID": 92,
      "TimeRemaining": 485,
      "Temperature": 182,
      "FanSpeed": 3498,
      "DriveSpeed": 63966
    }, {
      "ID": 93,
      "TimeRemaining": 483,
      "Temperature": 185,
      "FanSpeed": 3499,
      "DriveSpeed": 63972
    }, {
      "ID": 94,
      "TimeRemaining": 481,
      "Temperature": 185,
      "FanSpeed": 3501,
      "DriveSpeed": 63964
    }, {
      "ID": 95,
      "TimeRemaining": 479,
      "Temperature": 185,
      "FanSpeed": 3499,
      "DriveSpeed": 63970
    }, {
      "ID": 96,
      "TimeRemaining": 477,
      "Temperature": 187,
      "FanSpeed": 3498,
      "DriveSpeed": 63963
    }, {
      "ID": 97,
      "TimeRemaining": 475,
      "Temperature": 187,
      "FanSpeed": 3497,
      "DriveSpeed": 63970
    }, {
      "ID": 98,
      "TimeRemaining": 473,
      "Temperature": 187,
      "FanSpeed": 3501,
      "DriveSpeed": 63958
    }, {
      "ID": 99,
      "TimeRemaining": 471,
      "Temperature": 187,
      "FanSpeed": 3503,
      "DriveSpeed": 0
    }, {
      "ID": 100,
      "TimeRemaining": 469,
      "Temperature": 190,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 101,
      "TimeRemaining": 467,
      "Temperature": 190,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 102,
      "TimeRemaining": 465,
      "Temperature": 190,
      "FanSpeed": 3499,
      "DriveSpeed": 917
    }, {
      "ID": 103,
      "TimeRemaining": 463,
      "Temperature": 190,
      "FanSpeed": 3492,
      "DriveSpeed": 1579
    }, {
      "ID": 104,
      "TimeRemaining": 461,
      "Temperature": 190,
      "FanSpeed": 3493,
      "DriveSpeed": 1569
    }, {
      "ID": 105,
      "TimeRemaining": 459,
      "Temperature": 190,
      "FanSpeed": 3498,
      "DriveSpeed": 1571
    }, {
      "ID": 106,
      "TimeRemaining": 457,
      "Temperature": 190,
      "FanSpeed": 3497,
      "DriveSpeed": 1570
    }, {
      "ID": 107,
      "TimeRemaining": 455,
      "Temperature": 187,
      "FanSpeed": 3496,
      "DriveSpeed": 1572
    }, {
      "ID": 108,
      "TimeRemaining": 453,
      "Temperature": 185,
      "FanSpeed": 3497,
      "DriveSpeed": 1575
    }, {
      "ID": 109,
      "TimeRemaining": 451,
      "Temperature": 182,
      "FanSpeed": 3496,
      "DriveSpeed": 1580
    }, {
      "ID": 110,
      "TimeRemaining": 449,
      "Temperature": 180,
      "FanSpeed": 3498,
      "DriveSpeed": 1572
    }, {
      "ID": 111,
      "TimeRemaining": 447,
      "Temperature": 175,
      "FanSpeed": 3503,
      "DriveSpeed": 1576
    }, {
      "ID": 112,
      "TimeRemaining": 445,
      "Temperature": 173,
      "FanSpeed": 3503,
      "DriveSpeed": 1568
    }, {
      "ID": 113,
      "TimeRemaining": 443,
      "Temperature": 173,
      "FanSpeed": 3505,
      "DriveSpeed": 1566
    }, {
      "ID": 114,
      "TimeRemaining": 441,
      "Temperature": 173,
      "FanSpeed": 3496,
      "DriveSpeed": 1574
    }, {
      "ID": 115,
      "TimeRemaining": 439,
      "Temperature": 175,
      "FanSpeed": 3499,
      "DriveSpeed": 1576
    }, {
      "ID": 116,
      "TimeRemaining": 437,
      "Temperature": 175,
      "FanSpeed": 3498,
      "DriveSpeed": 1571
    }, {
      "ID": 117,
      "TimeRemaining": 435,
      "Temperature": 175,
      "FanSpeed": 3502,
      "DriveSpeed": 0
    }, {
      "ID": 118,
      "TimeRemaining": 433,
      "Temperature": 180,
      "FanSpeed": 3501,
      "DriveSpeed": 0
    }, {
      "ID": 119,
      "TimeRemaining": 431,
      "Temperature": 180,
      "FanSpeed": 3501,
      "DriveSpeed": 0
    }, {
      "ID": 120,
      "TimeRemaining": 429,
      "Temperature": 180,
      "FanSpeed": 3496,
      "DriveSpeed": 64610
    }, {
      "ID": 121,
      "TimeRemaining": 427,
      "Temperature": 182,
      "FanSpeed": 3501,
      "DriveSpeed": 63952
    }, {
      "ID": 122,
      "TimeRemaining": 425,
      "Temperature": 182,
      "FanSpeed": 3499,
      "DriveSpeed": 63965
    }, {
      "ID": 123,
      "TimeRemaining": 423,
      "Temperature": 185,
      "FanSpeed": 3498,
      "DriveSpeed": 63961
    }, {
      "ID": 124,
      "TimeRemaining": 421,
      "Temperature": 185,
      "FanSpeed": 3499,
      "DriveSpeed": 63967
    }, {
      "ID": 125,
      "TimeRemaining": 419,
      "Temperature": 187,
      "FanSpeed": 3501,
      "DriveSpeed": 63960
    }, {
      "ID": 126,
      "TimeRemaining": 417,
      "Temperature": 187,
      "FanSpeed": 3500,
      "DriveSpeed": 63968
    }, {
      "ID": 127,
      "TimeRemaining": 415,
      "Temperature": 190,
      "FanSpeed": 3500,
      "DriveSpeed": 63962
    }, {
      "ID": 128,
      "TimeRemaining": 413,
      "Temperature": 190,
      "FanSpeed": 3501,
      "DriveSpeed": 63971
    }, {
      "ID": 129,
      "TimeRemaining": 411,
      "Temperature": 190,
      "FanSpeed": 3500,
      "DriveSpeed": 63957
    }, {
      "ID": 130,
      "TimeRemaining": 409,
      "Temperature": 190,
      "FanSpeed": 3500,
      "DriveSpeed": 63963
    }, {
      "ID": 131,
      "TimeRemaining": 407,
      "Temperature": 190,
      "FanSpeed": 3498,
      "DriveSpeed": 63962
    }, {
      "ID": 132,
      "TimeRemaining": 405,
      "Temperature": 190,
      "FanSpeed": 3501,
      "DriveSpeed": 63971
    }, {
      "ID": 133,
      "TimeRemaining": 403,
      "Temperature": 190,
      "FanSpeed": 3501,
      "DriveSpeed": 63961
    }, {
      "ID": 134,
      "TimeRemaining": 401,
      "Temperature": 187,
      "FanSpeed": 3500,
      "DriveSpeed": 63960
    }, {
      "ID": 135,
      "TimeRemaining": 399,
      "Temperature": 185,
      "FanSpeed": 3498,
      "DriveSpeed": 0
    }, {
      "ID": 136,
      "TimeRemaining": 397,
      "Temperature": 182,
      "FanSpeed": 3499,
      "DriveSpeed": 0
    }, {
      "ID": 137,
      "TimeRemaining": 395,
      "Temperature": 180,
      "FanSpeed": 3499,
      "DriveSpeed": 0
    }, {
      "ID": 138,
      "TimeRemaining": 393,
      "Temperature": 175,
      "FanSpeed": 3498,
      "DriveSpeed": 882
    }, {
      "ID": 139,
      "TimeRemaining": 391,
      "Temperature": 172,
      "FanSpeed": 3502,
      "DriveSpeed": 1583
    }, {
      "ID": 140,
      "TimeRemaining": 389,
      "Temperature": 172,
      "FanSpeed": 3500,
      "DriveSpeed": 1571
    }, {
      "ID": 141,
      "TimeRemaining": 387,
      "Temperature": 172,
      "FanSpeed": 3501,
      "DriveSpeed": 1577
    }, {
      "ID": 142,
      "TimeRemaining": 385,
      "Temperature": 172,
      "FanSpeed": 3502,
      "DriveSpeed": 1565
    }, {
      "ID": 143,
      "TimeRemaining": 383,
      "Temperature": 173,
      "FanSpeed": 3498,
      "DriveSpeed": 1575
    }, {
      "ID": 144,
      "TimeRemaining": 381,
      "Temperature": 173,
      "FanSpeed": 3500,
      "DriveSpeed": 1566
    }, {
      "ID": 145,
      "TimeRemaining": 379,
      "Temperature": 175,
      "FanSpeed": 3498,
      "DriveSpeed": 1567
    }, {
      "ID": 146,
      "TimeRemaining": 377,
      "Temperature": 175,
      "FanSpeed": 3499,
      "DriveSpeed": 1561
    }, {
      "ID": 147,
      "TimeRemaining": 375,
      "Temperature": 180,
      "FanSpeed": 3502,
      "DriveSpeed": 1577
    }, {
      "ID": 148,
      "TimeRemaining": 373,
      "Temperature": 180,
      "FanSpeed": 3500,
      "DriveSpeed": 1560
    }, {
      "ID": 149,
      "TimeRemaining": 371,
      "Temperature": 180,
      "FanSpeed": 3499,
      "DriveSpeed": 1557
    }, {
      "ID": 150,
      "TimeRemaining": 369,
      "Temperature": 182,
      "FanSpeed": 3500,
      "DriveSpeed": 1569
    }, {
      "ID": 151,
      "TimeRemaining": 367,
      "Temperature": 182,
      "FanSpeed": 3500,
      "DriveSpeed": 1562
    }, {
      "ID": 152,
      "TimeRemaining": 365,
      "Temperature": 185,
      "FanSpeed": 3496,
      "DriveSpeed": 1565
    }, {
      "ID": 153,
      "TimeRemaining": 363,
      "Temperature": 185,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 154,
      "TimeRemaining": 361,
      "Temperature": 187,
      "FanSpeed": 3498,
      "DriveSpeed": 0
    }, {
      "ID": 155,
      "TimeRemaining": 359,
      "Temperature": 187,
      "FanSpeed": 3499,
      "DriveSpeed": 0
    }, {
      "ID": 156,
      "TimeRemaining": 357,
      "Temperature": 190,
      "FanSpeed": 3503,
      "DriveSpeed": 65090
    }, {
      "ID": 157,
      "TimeRemaining": 355,
      "Temperature": 190,
      "FanSpeed": 3501,
      "DriveSpeed": 63957
    }, {
      "ID": 158,
      "TimeRemaining": 353,
      "Temperature": 190,
      "FanSpeed": 3499,
      "DriveSpeed": 63963
    }, {
      "ID": 159,
      "TimeRemaining": 351,
      "Temperature": 191,
      "FanSpeed": 3497,
      "DriveSpeed": 63967
    }, {
      "ID": 160,
      "TimeRemaining": 349,
      "Temperature": 191,
      "FanSpeed": 3498,
      "DriveSpeed": 63971
    }, {
      "ID": 161,
      "TimeRemaining": 347,
      "Temperature": 190,
      "FanSpeed": 3498,
      "DriveSpeed": 63962
    }, {
      "ID": 162,
      "TimeRemaining": 345,
      "Temperature": 190,
      "FanSpeed": 3498,
      "DriveSpeed": 63965
    }, {
      "ID": 163,
      "TimeRemaining": 343,
      "Temperature": 187,
      "FanSpeed": 3500,
      "DriveSpeed": 63956
    }, {
      "ID": 164,
      "TimeRemaining": 341,
      "Temperature": 185,
      "FanSpeed": 3503,
      "DriveSpeed": 63968
    }, {
      "ID": 165,
      "TimeRemaining": 339,
      "Temperature": 182,
      "FanSpeed": 3498,
      "DriveSpeed": 63964
    }, {
      "ID": 166,
      "TimeRemaining": 337,
      "Temperature": 180,
      "FanSpeed": 3498,
      "DriveSpeed": 63960
    }, {
      "ID": 167,
      "TimeRemaining": 335,
      "Temperature": 175,
      "FanSpeed": 3502,
      "DriveSpeed": 63964
    }, {
      "ID": 168,
      "TimeRemaining": 333,
      "Temperature": 173,
      "FanSpeed": 3504,
      "DriveSpeed": 63967
    }, {
      "ID": 169,
      "TimeRemaining": 331,
      "Temperature": 173,
      "FanSpeed": 3501,
      "DriveSpeed": 63965
    }, {
      "ID": 170,
      "TimeRemaining": 329,
      "Temperature": 173,
      "FanSpeed": 3501,
      "DriveSpeed": 0
    }, {
      "ID": 171,
      "TimeRemaining": 327,
      "Temperature": 173,
      "FanSpeed": 3501,
      "DriveSpeed": 0
    }, {
      "ID": 172,
      "TimeRemaining": 325,
      "Temperature": 175,
      "FanSpeed": 3499,
      "DriveSpeed": 0
    }, {
      "ID": 173,
      "TimeRemaining": 323,
      "Temperature": 175,
      "FanSpeed": 3499,
      "DriveSpeed": 0
    }, {
      "ID": 174,
      "TimeRemaining": 321,
      "Temperature": 175,
      "FanSpeed": 3499,
      "DriveSpeed": 412
    }, {
      "ID": 175,
      "TimeRemaining": 319,
      "Temperature": 180,
      "FanSpeed": 3498,
      "DriveSpeed": 1589
    }, {
      "ID": 176,
      "TimeRemaining": 317,
      "Temperature": 180,
      "FanSpeed": 3499,
      "DriveSpeed": 1568
    }, {
      "ID": 177,
      "TimeRemaining": 315,
      "Temperature": 182,
      "FanSpeed": 3501,
      "DriveSpeed": 1571
    }, {
      "ID": 178,
      "TimeRemaining": 313,
      "Temperature": 182,
      "FanSpeed": 3499,
      "DriveSpeed": 1574
    }, {
      "ID": 179,
      "TimeRemaining": 311,
      "Temperature": 182,
      "FanSpeed": 3501,
      "DriveSpeed": 1571
    }, {
      "ID": 180,
      "TimeRemaining": 309,
      "Temperature": 182,
      "FanSpeed": 3498,
      "DriveSpeed": 1563
    }, {
      "ID": 181,
      "TimeRemaining": 307,
      "Temperature": 185,
      "FanSpeed": 3497,
      "DriveSpeed": 1561
    }, {
      "ID": 182,
      "TimeRemaining": 305,
      "Temperature": 185,
      "FanSpeed": 3501,
      "DriveSpeed": 1574
    }, {
      "ID": 183,
      "TimeRemaining": 303,
      "Temperature": 187,
      "FanSpeed": 3497,
      "DriveSpeed": 1569
    }, {
      "ID": 184,
      "TimeRemaining": 301,
      "Temperature": 187,
      "FanSpeed": 3497,
      "DriveSpeed": 1568
    }, {
      "ID": 185,
      "TimeRemaining": 299,
      "Temperature": 190,
      "FanSpeed": 3503,
      "DriveSpeed": 1569
    }, {
      "ID": 186,
      "TimeRemaining": 297,
      "Temperature": 190,
      "FanSpeed": 3502,
      "DriveSpeed": 1565
    }, {
      "ID": 187,
      "TimeRemaining": 295,
      "Temperature": 190,
      "FanSpeed": 3500,
      "DriveSpeed": 1577
    }, {
      "ID": 188,
      "TimeRemaining": 293,
      "Temperature": 190,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 189,
      "TimeRemaining": 291,
      "Temperature": 190,
      "FanSpeed": 3496,
      "DriveSpeed": 0
    }, {
      "ID": 190,
      "TimeRemaining": 289,
      "Temperature": 190,
      "FanSpeed": 3498,
      "DriveSpeed": 0
    }, {
      "ID": 191,
      "TimeRemaining": 287,
      "Temperature": 190,
      "FanSpeed": 3498,
      "DriveSpeed": 0
    }, {
      "ID": 192,
      "TimeRemaining": 285,
      "Temperature": 187,
      "FanSpeed": 3499,
      "DriveSpeed": 64605
    }, {
      "ID": 193,
      "TimeRemaining": 283,
      "Temperature": 185,
      "FanSpeed": 3503,
      "DriveSpeed": 63950
    }, {
      "ID": 194,
      "TimeRemaining": 281,
      "Temperature": 182,
      "FanSpeed": 3497,
      "DriveSpeed": 63960
    }, {
      "ID": 195,
      "TimeRemaining": 279,
      "Temperature": 180,
      "FanSpeed": 3503,
      "DriveSpeed": 63975
    }, {
      "ID": 196,
      "TimeRemaining": 277,
      "Temperature": 175,
      "FanSpeed": 3502,
      "DriveSpeed": 63969
    }, {
      "ID": 197,
      "TimeRemaining": 275,
      "Temperature": 173,
      "FanSpeed": 3501,
      "DriveSpeed": 63957
    }, {
      "ID": 198,
      "TimeRemaining": 273,
      "Temperature": 173,
      "FanSpeed": 3502,
      "DriveSpeed": 63961
    }, {
      "ID": 199,
      "TimeRemaining": 271,
      "Temperature": 173,
      "FanSpeed": 3500,
      "DriveSpeed": 63954
    }, {
      "ID": 200,
      "TimeRemaining": 269,
      "Temperature": 173,
      "FanSpeed": 3501,
      "DriveSpeed": 63964
    }, {
      "ID": 201,
      "TimeRemaining": 267,
      "Temperature": 173,
      "FanSpeed": 3501,
      "DriveSpeed": 63961
    }, {
      "ID": 202,
      "TimeRemaining": 265,
      "Temperature": 175,
      "FanSpeed": 3500,
      "DriveSpeed": 63967
    }, {
      "ID": 203,
      "TimeRemaining": 263,
      "Temperature": 175,
      "FanSpeed": 3500,
      "DriveSpeed": 63958
    }, {
      "ID": 204,
      "TimeRemaining": 261,
      "Temperature": 180,
      "FanSpeed": 3502,
      "DriveSpeed": 63964
    }, {
      "ID": 205,
      "TimeRemaining": 259,
      "Temperature": 180,
      "FanSpeed": 3500,
      "DriveSpeed": 63966
    }, {
      "ID": 206,
      "TimeRemaining": 257,
      "Temperature": 182,
      "FanSpeed": 3500,
      "DriveSpeed": 63960
    }, {
      "ID": 207,
      "TimeRemaining": 255,
      "Temperature": 182,
      "FanSpeed": 3501,
      "DriveSpeed": 0
    }, {
      "ID": 208,
      "TimeRemaining": 253,
      "Temperature": 182,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 209,
      "TimeRemaining": 251,
      "Temperature": 185,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 210,
      "TimeRemaining": 249,
      "Temperature": 185,
      "FanSpeed": 3501,
      "DriveSpeed": 411
    }, {
      "ID": 211,
      "TimeRemaining": 247,
      "Temperature": 187,
      "FanSpeed": 3500,
      "DriveSpeed": 1578
    }, {
      "ID": 212,
      "TimeRemaining": 245,
      "Temperature": 187,
      "FanSpeed": 3496,
      "DriveSpeed": 1571
    }, {
      "ID": 213,
      "TimeRemaining": 243,
      "Temperature": 190,
      "FanSpeed": 3503,
      "DriveSpeed": 1566
    }, {
      "ID": 214,
      "TimeRemaining": 241,
      "Temperature": 190,
      "FanSpeed": 3503,
      "DriveSpeed": 1561
    }, {
      "ID": 215,
      "TimeRemaining": 239,
      "Temperature": 190,
      "FanSpeed": 3497,
      "DriveSpeed": 1569
    }, {
      "ID": 216,
      "TimeRemaining": 237,
      "Temperature": 191,
      "FanSpeed": 3497,
      "DriveSpeed": 1565
    }, {
      "ID": 217,
      "TimeRemaining": 235,
      "Temperature": 191,
      "FanSpeed": 3497,
      "DriveSpeed": 1564
    }, {
      "ID": 218,
      "TimeRemaining": 233,
      "Temperature": 190,
      "FanSpeed": 3497,
      "DriveSpeed": 1564
    }, {
      "ID": 219,
      "TimeRemaining": 231,
      "Temperature": 190,
      "FanSpeed": 3500,
      "DriveSpeed": 1565
    }, {
      "ID": 220,
      "TimeRemaining": 229,
      "Temperature": 190,
      "FanSpeed": 3500,
      "DriveSpeed": 1567
    }, {
      "ID": 221,
      "TimeRemaining": 227,
      "Temperature": 187,
      "FanSpeed": 3500,
      "DriveSpeed": 1572
    }, {
      "ID": 222,
      "TimeRemaining": 225,
      "Temperature": 185,
      "FanSpeed": 3499,
      "DriveSpeed": 1561
    }, {
      "ID": 223,
      "TimeRemaining": 223,
      "Temperature": 182,
      "FanSpeed": 3503,
      "DriveSpeed": 1567
    }, {
      "ID": 224,
      "TimeRemaining": 221,
      "Temperature": 180,
      "FanSpeed": 3504,
      "DriveSpeed": 1575
    }, {
      "ID": 225,
      "TimeRemaining": 219,
      "Temperature": 175,
      "FanSpeed": 3504,
      "DriveSpeed": 0
    }, {
      "ID": 226,
      "TimeRemaining": 217,
      "Temperature": 175,
      "FanSpeed": 3504,
      "DriveSpeed": 0
    }, {
      "ID": 227,
      "TimeRemaining": 215,
      "Temperature": 175,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 228,
      "TimeRemaining": 213,
      "Temperature": 180,
      "FanSpeed": 3499,
      "DriveSpeed": 65130
    }, {
      "ID": 229,
      "TimeRemaining": 211,
      "Temperature": 180,
      "FanSpeed": 3499,
      "DriveSpeed": 63950
    }, {
      "ID": 230,
      "TimeRemaining": 209,
      "Temperature": 182,
      "FanSpeed": 3497,
      "DriveSpeed": 63964
    }, {
      "ID": 231,
      "TimeRemaining": 207,
      "Temperature": 182,
      "FanSpeed": 3499,
      "DriveSpeed": 63973
    }, {
      "ID": 232,
      "TimeRemaining": 205,
      "Temperature": 185,
      "FanSpeed": 3501,
      "DriveSpeed": 63972
    }, {
      "ID": 233,
      "TimeRemaining": 203,
      "Temperature": 185,
      "FanSpeed": 3499,
      "DriveSpeed": 63966
    }, {
      "ID": 234,
      "TimeRemaining": 201,
      "Temperature": 185,
      "FanSpeed": 3499,
      "DriveSpeed": 63972
    }, {
      "ID": 235,
      "TimeRemaining": 199,
      "Temperature": 187,
      "FanSpeed": 3497,
      "DriveSpeed": 63959
    }, {
      "ID": 236,
      "TimeRemaining": 197,
      "Temperature": 187,
      "FanSpeed": 3498,
      "DriveSpeed": 63965
    }, {
      "ID": 237,
      "TimeRemaining": 195,
      "Temperature": 190,
      "FanSpeed": 3497,
      "DriveSpeed": 63955
    }, {
      "ID": 238,
      "TimeRemaining": 193,
      "Temperature": 190,
      "FanSpeed": 3498,
      "DriveSpeed": 63966
    }, {
      "ID": 239,
      "TimeRemaining": 191,
      "Temperature": 190,
      "FanSpeed": 3503,
      "DriveSpeed": 63961
    }, {
      "ID": 240,
      "TimeRemaining": 189,
      "Temperature": 190,
      "FanSpeed": 3499,
      "DriveSpeed": 63964
    }, {
      "ID": 241,
      "TimeRemaining": 187,
      "Temperature": 190,
      "FanSpeed": 3492,
      "DriveSpeed": 63954
    }, {
      "ID": 242,
      "TimeRemaining": 185,
      "Temperature": 190,
      "FanSpeed": 3498,
      "DriveSpeed": 63971
    }, {
      "ID": 243,
      "TimeRemaining": 183,
      "Temperature": 190,
      "FanSpeed": 3501,
      "DriveSpeed": 0
    }, {
      "ID": 244,
      "TimeRemaining": 181,
      "Temperature": 190,
      "FanSpeed": 3498,
      "DriveSpeed": 0
    }, {
      "ID": 245,
      "TimeRemaining": 179,
      "Temperature": 185,
      "FanSpeed": 3499,
      "DriveSpeed": 0
    }, {
      "ID": 246,
      "TimeRemaining": 177,
      "Temperature": 182,
      "FanSpeed": 3499,
      "DriveSpeed": 966
    }, {
      "ID": 247,
      "TimeRemaining": 175,
      "Temperature": 180,
      "FanSpeed": 3502,
      "DriveSpeed": 1562
    }, {
      "ID": 248,
      "TimeRemaining": 173,
      "Temperature": 175,
      "FanSpeed": 3498,
      "DriveSpeed": 1570
    }, {
      "ID": 249,
      "TimeRemaining": 171,
      "Temperature": 173,
      "FanSpeed": 3503,
      "DriveSpeed": 1565
    }, {
      "ID": 250,
      "TimeRemaining": 169,
      "Temperature": 172,
      "FanSpeed": 3502,
      "DriveSpeed": 1558
    }, {
      "ID": 251,
      "TimeRemaining": 167,
      "Temperature": 172,
      "FanSpeed": 3499,
      "DriveSpeed": 1573
    }, {
      "ID": 252,
      "TimeRemaining": 165,
      "Temperature": 173,
      "FanSpeed": 3501,
      "DriveSpeed": 1580
    }, {
      "ID": 253,
      "TimeRemaining": 163,
      "Temperature": 173,
      "FanSpeed": 3499,
      "DriveSpeed": 1565
    }, {
      "ID": 254,
      "TimeRemaining": 161,
      "Temperature": 175,
      "FanSpeed": 3504,
      "DriveSpeed": 1566
    }, {
      "ID": 255,
      "TimeRemaining": 159,
      "Temperature": 175,
      "FanSpeed": 3501,
      "DriveSpeed": 1581
    }, {
      "ID": 0,
      "TimeRemaining": 157,
      "Temperature": 180,
      "FanSpeed": 3498,
      "DriveSpeed": 1566
    }, {
      "ID": 1,
      "TimeRemaining": 155,
      "Temperature": 182,
      "FanSpeed": 3501,
      "DriveSpeed": 1572
    }, {
      "ID": 2,
      "TimeRemaining": 153,
      "Temperature": 182,
      "FanSpeed": 3501,
      "DriveSpeed": 1578
    }, {
      "ID": 3,
      "TimeRemaining": 151,
      "Temperature": 185,
      "FanSpeed": 3499,
      "DriveSpeed": 1576
    }, {
      "ID": 4,
      "TimeRemaining": 149,
      "Temperature": 185,
      "FanSpeed": 3500,
      "DriveSpeed": 1571
    }, {
      "ID": 5,
      "TimeRemaining": 147,
      "Temperature": 185,
      "FanSpeed": 3502,
      "DriveSpeed": 0
    }, {
      "ID": 6,
      "TimeRemaining": 145,
      "Temperature": 185,
      "FanSpeed": 3502,
      "DriveSpeed": 0
    }, {
      "ID": 7,
      "TimeRemaining": 143,
      "Temperature": 187,
      "FanSpeed": 3499,
      "DriveSpeed": 0
    }, {
      "ID": 8,
      "TimeRemaining": 141,
      "Temperature": 187,
      "FanSpeed": 3500,
      "DriveSpeed": 64650
    }, {
      "ID": 9,
      "TimeRemaining": 139,
      "Temperature": 190,
      "FanSpeed": 3499,
      "DriveSpeed": 63961
    }, {
      "ID": 10,
      "TimeRemaining": 137,
      "Temperature": 190,
      "FanSpeed": 3497,
      "DriveSpeed": 63972
    }, {
      "ID": 11,
      "TimeRemaining": 135,
      "Temperature": 190,
      "FanSpeed": 3502,
      "DriveSpeed": 63976
    }, {
      "ID": 12,
      "TimeRemaining": 133,
      "Temperature": 190,
      "FanSpeed": 3496,
      "DriveSpeed": 63971
    }, {
      "ID": 13,
      "TimeRemaining": 131,
      "Temperature": 191,
      "FanSpeed": 3497,
      "DriveSpeed": 63966
    }, {
      "ID": 14,
      "TimeRemaining": 129,
      "Temperature": 190,
      "FanSpeed": 3495,
      "DriveSpeed": 63963
    }, {
      "ID": 15,
      "TimeRemaining": 127,
      "Temperature": 190,
      "FanSpeed": 3498,
      "DriveSpeed": 63971
    }, {
      "ID": 16,
      "TimeRemaining": 125,
      "Temperature": 190,
      "FanSpeed": 3499,
      "DriveSpeed": 63969
    }, {
      "ID": 17,
      "TimeRemaining": 123,
      "Temperature": 187,
      "FanSpeed": 3501,
      "DriveSpeed": 63968
    }, {
      "ID": 18,
      "TimeRemaining": 121,
      "Temperature": 185,
      "FanSpeed": 3500,
      "DriveSpeed": 63965
    }, {
      "ID": 19,
      "TimeRemaining": 119,
      "Temperature": 182,
      "FanSpeed": 3498,
      "DriveSpeed": 63974
    }, {
      "ID": 20,
      "TimeRemaining": 117,
      "Temperature": 180,
      "FanSpeed": 3496,
      "DriveSpeed": 63970
    }, {
      "ID": 21,
      "TimeRemaining": 115,
      "Temperature": 175,
      "FanSpeed": 3502,
      "DriveSpeed": 63966
    }, {
      "ID": 22,
      "TimeRemaining": 113,
      "Temperature": 175,
      "FanSpeed": 3504,
      "DriveSpeed": 0
    }, {
      "ID": 23,
      "TimeRemaining": 111,
      "Temperature": 175,
      "FanSpeed": 3502,
      "DriveSpeed": 0
    }, {
      "ID": 24,
      "TimeRemaining": 109,
      "Temperature": 175,
      "FanSpeed": 3501,
      "DriveSpeed": 0
    }, {
      "ID": 25,
      "TimeRemaining": 107,
      "Temperature": 180,
      "FanSpeed": 3501,
      "DriveSpeed": 0
    }, {
      "ID": 26,
      "TimeRemaining": 105,
      "Temperature": 180,
      "FanSpeed": 3500,
      "DriveSpeed": 397
    }, {
      "ID": 27,
      "TimeRemaining": 103,
      "Temperature": 182,
      "FanSpeed": 3496,
      "DriveSpeed": 1582
    }, {
      "ID": 28,
      "TimeRemaining": 101,
      "Temperature": 185,
      "FanSpeed": 3497,
      "DriveSpeed": 1579
    }, {
      "ID": 29,
      "TimeRemaining": 99,
      "Temperature": 185,
      "FanSpeed": 3499,
      "DriveSpeed": 1566
    }, {
      "ID": 30,
      "TimeRemaining": 97,
      "Temperature": 185,
      "FanSpeed": 3501,
      "DriveSpeed": 1572
    }, {
      "ID": 31,
      "TimeRemaining": 95,
      "Temperature": 187,
      "FanSpeed": 3497,
      "DriveSpeed": 1571
    }, {
      "ID": 32,
      "TimeRemaining": 93,
      "Temperature": 187,
      "FanSpeed": 3499,
      "DriveSpeed": 1568
    }, {
      "ID": 33,
      "TimeRemaining": 91,
      "Temperature": 187,
      "FanSpeed": 3500,
      "DriveSpeed": 1574
    }, {
      "ID": 34,
      "TimeRemaining": 89,
      "Temperature": 190,
      "FanSpeed": 3500,
      "DriveSpeed": 1573
    }, {
      "ID": 35,
      "TimeRemaining": 87,
      "Temperature": 190,
      "FanSpeed": 3502,
      "DriveSpeed": 1571
    }, {
      "ID": 36,
      "TimeRemaining": 85,
      "Temperature": 190,
      "FanSpeed": 3501,
      "DriveSpeed": 1567
    }, {
      "ID": 37,
      "TimeRemaining": 83,
      "Temperature": 190,
      "FanSpeed": 3493,
      "DriveSpeed": 1568
    }, {
      "ID": 38,
      "TimeRemaining": 81,
      "Temperature": 190,
      "FanSpeed": 3499,
      "DriveSpeed": 1570
    }, {
      "ID": 39,
      "TimeRemaining": 79,
      "Temperature": 190,
      "FanSpeed": 3498,
      "DriveSpeed": 1565
    }, {
      "ID": 40,
      "TimeRemaining": 77,
      "Temperature": 187,
      "FanSpeed": 3497,
      "DriveSpeed": 1565
    }, {
      "ID": 41,
      "TimeRemaining": 75,
      "Temperature": 185,
      "FanSpeed": 3499,
      "DriveSpeed": 0
    }, {
      "ID": 42,
      "TimeRemaining": 73,
      "Temperature": 185,
      "FanSpeed": 3498,
      "DriveSpeed": 0
    }, {
      "ID": 43,
      "TimeRemaining": 71,
      "Temperature": 180,
      "FanSpeed": 3498,
      "DriveSpeed": 0
    }, {
      "ID": 44,
      "TimeRemaining": 69,
      "Temperature": 175,
      "FanSpeed": 3493,
      "DriveSpeed": 64609
    }, {
      "ID": 45,
      "TimeRemaining": 67,
      "Temperature": 173,
      "FanSpeed": 3501,
      "DriveSpeed": 63964
    }, {
      "ID": 46,
      "TimeRemaining": 65,
      "Temperature": 172,
      "FanSpeed": 3500,
      "DriveSpeed": 63968
    }, {
      "ID": 47,
      "TimeRemaining": 63,
      "Temperature": 172,
      "FanSpeed": 3503,
      "DriveSpeed": 63969
    }, {
      "ID": 48,
      "TimeRemaining": 61,
      "Temperature": 172,
      "FanSpeed": 3503,
      "DriveSpeed": 63968
    }, {
      "ID": 49,
      "TimeRemaining": 59,
      "Temperature": 173,
      "FanSpeed": 3499,
      "DriveSpeed": 63963
    }, {
      "ID": 50,
      "TimeRemaining": 57,
      "Temperature": 173,
      "FanSpeed": 3499,
      "DriveSpeed": 63973
    }, {
      "ID": 51,
      "TimeRemaining": 55,
      "Temperature": 173,
      "FanSpeed": 3498,
      "DriveSpeed": 63975
    }, {
      "ID": 52,
      "TimeRemaining": 53,
      "Temperature": 173,
      "FanSpeed": 3495,
      "DriveSpeed": 63971
    }, {
      "ID": 53,
      "TimeRemaining": 51,
      "Temperature": 172,
      "FanSpeed": 3501,
      "DriveSpeed": 63970
    }, {
      "ID": 54,
      "TimeRemaining": 49,
      "Temperature": 171,
      "FanSpeed": 3498,
      "DriveSpeed": 63973
    }, {
      "ID": 55,
      "TimeRemaining": 47,
      "Temperature": 170,
      "FanSpeed": 3501,
      "DriveSpeed": 63962
    }, {
      "ID": 56,
      "TimeRemaining": 45,
      "Temperature": 170,
      "FanSpeed": 3498,
      "DriveSpeed": 63965
    }, {
      "ID": 57,
      "TimeRemaining": 43,
      "Temperature": 165,
      "FanSpeed": 3499,
      "DriveSpeed": 63973
    }, {
      "ID": 58,
      "TimeRemaining": 41,
      "Temperature": 160,
      "FanSpeed": 3502,
      "DriveSpeed": 63969
    }, {
      "ID": 59,
      "TimeRemaining": 39,
      "Temperature": 157,
      "FanSpeed": 3499,
      "DriveSpeed": 0
    }, {
      "ID": 60,
      "TimeRemaining": 37,
      "Temperature": 155,
      "FanSpeed": 3500,
      "DriveSpeed": 0
    }, {
      "ID": 61,
      "TimeRemaining": 35,
      "Temperature": 154,
      "FanSpeed": 3499,
      "DriveSpeed": 0
    }, {
      "ID": 62,
      "TimeRemaining": 33,
      "Temperature": 153,
      "FanSpeed": 3498,
      "DriveSpeed": 885
    }, {
      "ID": 63,
      "TimeRemaining": 31,
      "Temperature": 152,
      "FanSpeed": 3489,
      "DriveSpeed": 1583
    }, {
      "ID": 64,
      "TimeRemaining": 29,
      "Temperature": 151,
      "FanSpeed": 3495,
      "DriveSpeed": 1573
    }, {
      "ID": 65,
      "TimeRemaining": 27,
      "Temperature": 150,
      "FanSpeed": 3496,
      "DriveSpeed": 1571
    }, {
      "ID": 66,
      "TimeRemaining": 25,
      "Temperature": 149,
      "FanSpeed": 3496,
      "DriveSpeed": 1577
    }, {
      "ID": 67,
      "TimeRemaining": 23,
      "Temperature": 148,
      "FanSpeed": 3495,
      "DriveSpeed": 1577
    }, {
      "ID": 68,
      "TimeRemaining": 21,
      "Temperature": 147,
      "FanSpeed": 3499,
      "DriveSpeed": 1566
    }, {
      "ID": 69,
      "TimeRemaining": 19,
      "Temperature": 146,
      "FanSpeed": 3489,
      "DriveSpeed": 1575
    }, {
      "ID": 70,
      "TimeRemaining": 17,
      "Temperature": 145,
      "FanSpeed": 3485,
      "DriveSpeed": 1570
    }, {
      "ID": 71,
      "TimeRemaining": 15,
      "Temperature": 144,
      "FanSpeed": 3489,
      "DriveSpeed": 1570
    }, {
      "ID": 72,
      "TimeRemaining": 13,
      "Temperature": 144,
      "FanSpeed": 3489,
      "DriveSpeed": 1574
    }, {
      "ID": 73,
      "TimeRemaining": 11,
      "Temperature": 143,
      "FanSpeed": 3478,
      "DriveSpeed": 1568
    }, {
      "ID": 74,
      "TimeRemaining": 9,
      "Temperature": 142,
      "FanSpeed": 3480,
      "DriveSpeed": 1581
    }, {
      "ID": 75,
      "TimeRemaining": 7,
      "Temperature": 141,
      "FanSpeed": 3478,
      "DriveSpeed": 1580
    }, {
      "ID": 76,
      "TimeRemaining": 5,
      "Temperature": 140,
      "FanSpeed": 3474,
      "DriveSpeed": 1560
    }, {
      "ID": 77,
      "TimeRemaining": 3,
      "Temperature": 140,
      "FanSpeed": 3488,
      "DriveSpeed": 0
    }, {
      "ID": 78,
      "TimeRemaining": 1,
      "Temperature": 140,
      "FanSpeed": 3491,
      "DriveSpeed": 0
    }],
    "steps": [{
      "Step": 0,
      "Time": 2641
    }, {
      "Step": 0,
      "Time": 2615
    }, {
      "Step": 0,
      "Time": 2581
    }, {
      "Step": 0,
      "Time": 2555
    }, {
      "Step": 0,
      "Time": 2521
    }, {
      "Step": 0,
      "Time": 2495
    }, {
      "Step": 0,
      "Time": 2461
    }, {
      "Step": 0,
      "Time": 2435
    }, {
      "Step": 0,
      "Time": 2401
    }, {
      "Step": 0,
      "Time": 2375
    }, {
      "Step": 0,
      "Time": 2341
    }, {
      "Step": 0,
      "Time": 2315
    }, {
      "Step": 0,
      "Time": 2281
    }, {
      "Step": 0,
      "Time": 2255
    }, {
      "Step": 0,
      "Time": 2221
    }, {
      "Step": 0,
      "Time": 2195
    }, {
      "Step": 0,
      "Time": 2161
    }, {
      "Step": 0,
      "Time": 2135
    }, {
      "Step": 0,
      "Time": 2101
    }, {
      "Step": 0,
      "Time": 2075
    }, {
      "Step": 0,
      "Time": 2041
    }, {
      "Step": 0,
      "Time": 2015
    }, {
      "Step": 0,
      "Time": 1981
    }, {
      "Step": 0,
      "Time": 1955
    }, {
      "Step": 0,
      "Time": 1921
    }, {
      "Step": 0,
      "Time": 1906
    }, {
      "Step": 0,
      "Time": 1895
    }, {
      "Step": 0,
      "Time": 1861
    }, {
      "Step": 0,
      "Time": 1835
    }, {
      "Step": 0,
      "Time": 1801
    }, {
      "Step": 0,
      "Time": 1775
    }, {
      "Step": 0,
      "Time": 1741
    }, {
      "Step": 0,
      "Time": 1715
    }, {
      "Step": 0,
      "Time": 1681
    }, {
      "Step": 0,
      "Time": 1655
    }, {
      "Step": 0,
      "Time": 1621
    }, {
      "Step": 0,
      "Time": 1595
    }, {
      "Step": 0,
      "Time": 1561
    }, {
      "Step": 0,
      "Time": 1535
    }, {
      "Step": 0,
      "Time": 1501
    }, {
      "Step": 0,
      "Time": 1475
    }, {
      "Step": 0,
      "Time": 1441
    }, {
      "Step": 0,
      "Time": 1415
    }, {
      "Step": 0,
      "Time": 1381
    }, {
      "Step": 0,
      "Time": 1355
    }, {
      "Step": 0,
      "Time": 1321
    }, {
      "Step": 0,
      "Time": 1295
    }, {
      "Step": 0,
      "Time": 1261
    }, {
      "Step": 0,
      "Time": 1235
    }, {
      "Step": 0,
      "Time": 1201
    }, {
      "Step": 0,
      "Time": 1175
    }, {
      "Step": 0,
      "Time": 1141
    }, {
      "Step": 0,
      "Time": 1115
    }, {
      "Step": 0,
      "Time": 1081
    }, {
      "Step": 0,
      "Time": 1055
    }, {
      "Step": 0,
      "Time": 1021
    }, {
      "Step": 0,
      "Time": 1015
    }, {
      "Step": 0,
      "Time": 995
    }, {
      "Step": 0,
      "Time": 961
    }, {
      "Step": 0,
      "Time": 935
    }, {
      "Step": 0,
      "Time": 901
    }, {
      "Step": 0,
      "Time": 875
    }, {
      "Step": 0,
      "Time": 841
    }, {
      "Step": 0,
      "Time": 815
    }, {
      "Step": 0,
      "Time": 781
    }, {
      "Step": 0,
      "Time": 755
    }, {
      "Step": 0,
      "Time": 721
    }, {
      "Step": 0,
      "Time": 695
    }, {
      "Step": 0,
      "Time": 661
    }, {
      "Step": 0,
      "Time": 635
    }, {
      "Step": 0,
      "Time": 601
    }, {
      "Step": 0,
      "Time": 575
    }, {
      "Step": 0,
      "Time": 541
    }, {
      "Step": 0,
      "Time": 515
    }, {
      "Step": 0,
      "Time": 481
    }, {
      "Step": 0,
      "Time": 455
    }, {
      "Step": 0,
      "Time": 421
    }, {
      "Step": 0,
      "Time": 395
    }, {
      "Step": 0,
      "Time": 361
    }, {
      "Step": 0,
      "Time": 335
    }, {
      "Step": 0,
      "Time": 301
    }, {
      "Step": 0,
      "Time": 275
    }, {
      "Step": 0,
      "Time": 241
    }, {
      "Step": 0,
      "Time": 215
    }, {
      "Step": 0,
      "Time": 181
    }, {
      "Step": 0,
      "Time": 155
    }, {
      "Step": 0,
      "Time": 121
    }, {
      "Step": 0,
      "Time": 113
    }, {
      "Step": 0,
      "Time": 95
    }, {
      "Step": 0,
      "Time": 61
    }, {
      "Step": 0,
      "Time": 35
    }, {
      "Step": 0,
      "Time": 1
    }],
    "errors": ['1', '2', '3'] // "CycleId": 5777

  };
  _exports.chartsMockData = chartsMockData;
});