define("alliance-business-suite/services/logr", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@sentry/ember"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, Sentry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  let LogrService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, (_class = class LogrService extends Ember.Service {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "brand", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "unhandledPromiseErrorMessage", _descriptor4, this);
    }

    addContext() {
      if (this == null) {
        // eslint-disable-next-line
        // console.warn('logr cannot addContext because this is null/undefined, continuing without');
        return;
      }

      try {
        Sentry.setUser({
          id: this.access.get('user.id') || this.access.get('accessUser.id') || '-',
          firstName: this.access.get('user.firstName') || this.access.get('accessUser.firstName') || '-',
          lastName: this.access.get('user.lastName') || this.access.get('accessUser.lastName') || '-',
          email: this.access.get('user.primaryEmail') || this.access.get('accessUser.primaryEmail') || '-'
        });
      } catch {// do not delete
        // something failed above, but we still need a catch
      }

      try {
        Sentry.setContext("tags", {
          locale: this.intl.get('locale') || '-',
          orgId: this.access.accessOrganization.id || '-',
          organizationName: this.access.accessOrganization.organizationName || '-',
          brandName: this.brand.get('name')
        });
      } catch {// do not delete
        // something failed above, but we still need a catch
      }
    }

    captureException(error) {
      this.addContext();
      Sentry.captureException(error);
      return true;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "brand", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "unhandledPromiseErrorMessage", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  })), _class));
  _exports.default = LogrService;
});