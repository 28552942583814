define("alliance-business-suite/components/machines/programs/modifiers/primus-mc10-wx", ["exports", "alliance-business-suite/mixins/machines/programs/mc10-options"], function (_exports, _mc10Options) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_mc10Options.default, {
    // eslint-disable-line ember/no-classic-classes
    tagName: '',
    formConfig: Ember.computed(function () {
      return {
        columns: [{
          sections: [{
            title: "modifiers",
            controls: [{
              type: "modifier-options",
              properties: {
                Mod1: "Program.Price.ModifierOptions.DefaultModifiers.Mod1",
                Mod1On: "Program.Price.ModifierOptions.ModsAdditionalOptsEnabled.Mod1",
                Mod2: "Program.Price.ModifierOptions.DefaultModifiers.Mod2",
                Mod2On: "Program.Price.ModifierOptions.ModsAdditionalOptsEnabled.Mod2",
                ExtraTime: "Program.Price.ModifierOptions.DefaultModifiers.ExtraTime",
                ExtraTimeOn: "Program.Price.ModifierOptions.ModsAdditionalOptsEnabled.ExtraTime",
                // WarmFinalRinse: "Program.Price.ModifierOptions.DefaultModifiers.WarmFinalRinse",
                // WarmFinalRinseOn: "Program.Price.ModifierOptions.ModsAdditionalOptsEnabled.WarmFinalRinse",
                IncreasedWaterLevel: "Program.Price.ModifierOptions.DefaultModifiers.IncreasedWaterLevel",
                IncreasedWaterLevelOn: "Program.Price.ModifierOptions.ModsAdditionalOptsEnabled.IncreasedWaterLevel",
                IncreasedSpinSpeed: "Program.Price.ModifierOptions.DefaultModifiers.IncreasedSpinSpeed",
                IncreasedSpinSpeedOn: "Program.Price.ModifierOptions.ModsAdditionalOptsEnabled.IncreasedSpinSpeed",
                // Ozone: "Program.Price.ModifierOptions.DefaultModifiers.Ozone",
                // OzoneOn: "Program.Price.ModifierOptions.ModsAdditionalOptsEnabled.Ozone",
                DispenseSoap: "Program.Price.ModifierOptions.DefaultModifiers.DispenseSoap",
                DispenseSoapOn: "Program.Price.ModifierOptions.ModsAdditionalOptsEnabled.DispenseSoap",
                SpeedCycle: "Program.Price.ModifierOptions.DefaultModifiers.SpeedCycle",
                SpeedCycleOn: "Program.Price.ModifierOptions.ModsAdditionalOptsEnabled.SpeedCycle",
                MediumBundle: "Program.Price.ModifierOptions.DefaultModifiers.MediumBundle",
                MediumBundleOn: "Program.Price.ModifierOptions.ModsAdditionalOptsEnabled.MediumBundle",
                HeavyBundle: "Program.Price.ModifierOptions.DefaultModifiers.HeavyBundle",
                HeavyBundleOn: "Program.Price.ModifierOptions.ModsAdditionalOptsEnabled.HeavyBundle"
              }
            }]
          }, {
            title: "bundles",
            controls: [{
              type: "bundle-options",
              properties: {
                Mod1Medium: "Program.Price.ModifierOptions.MediumBundleMods.Options.Mod1",
                Mod1Heavy: "Program.Price.ModifierOptions.HeavyBundleMods.Options.Mod1",
                Mod2Medium: "Program.Price.ModifierOptions.MediumBundleMods.Options.Mod2",
                Mod2Heavy: "Program.Price.ModifierOptions.HeavyBundleMods.Options.Mod2",
                ExtraTimeMedium: "Program.Price.ModifierOptions.MediumBundleMods.Options.ExtraTime",
                ExtraTimeHeavy: "Program.Price.ModifierOptions.HeavyBundleMods.Options.ExtraTime",
                // WarmRinseMedium: "Program.Price.ModifierOptions.MediumBundleMods.Options.WarmRinse",
                // WarmRinseHeavy: "Program.Price.ModifierOptions.HeavyBundleMods.Options.WarmRinse",
                WaterLevelMedium: "Program.Price.ModifierOptions.MediumBundleMods.Options.WaterLevel",
                WaterLevelHeavy: "Program.Price.ModifierOptions.HeavyBundleMods.Options.WaterLevel",
                SpinSpeedMedium: "Program.Price.ModifierOptions.MediumBundleMods.Options.SpinSpeed",
                SpinSpeedHeavy: "Program.Price.ModifierOptions.HeavyBundleMods.Options.SpinSpeed",
                ExtraWashAgitateTimeMedium: "Program.Price.ModifierOptions.MediumBundleMods.ExtraWashAgitateTime",
                ExtraWashAgitateTimeHeavy: "Program.Price.ModifierOptions.HeavyBundleMods.ExtraWashAgitateTime",
                ExtraRinseAgitateTimeMedium: "Program.Price.ModifierOptions.MediumBundleMods.ExtraRinseAgitateTime",
                ExtraRinseAgitateTimeHeavy: "Program.Price.ModifierOptions.HeavyBundleMods.ExtraRinseAgitateTime"
              }
            }]
          }]
        }, {
          sections: [{
            title: "modifier_options",
            notMachine: "MC10_COMBO",
            controls: [{
              type: "select",
              propertyName: "Program.Price.ModifierOptions.CycleModifierName",
              options: "ModifierName"
            }, {
              type: "number",
              propertyName: "Program.Price.ModifierOptions.IncreasedSpinSpeed",
              min: 150,
              max: 1075
            }, {
              type: "select",
              propertyName: "Program.Price.ModifierOptions.IncreasedWaterLevelWash",
              options: "WaterLevelType",
              hasCustom: true,
              min: 9,
              max: 29
            }, {
              type: "select",
              propertyName: "Program.Price.ModifierOptions.IncreasedWaterLevelRinse",
              options: "WaterLevelType",
              hasCustom: true,
              min: 9,
              max: 29
            }, {
              type: "number",
              propertyName: "Program.Price.ModifierOptions.ExtraWashAgitateTime",
              min: 0,
              max: 15,
              labelAfter: "Minutes"
            }, {
              type: "number",
              propertyName: "Program.Price.ModifierOptions.ExtraRinseAgitateTime",
              min: 0,
              max: 15,
              labelAfter: "Minutes"
            }]
          }]
        }]
      };
    })
  });

  _exports.default = _default;
});