define("alliance-business-suite/components/support-section", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;

  const SECTIONS = [{
    name: 'charts_and_reports',
    isCollapsed: true,
    children: [{
      name: 'charts',
      section: 'charts.charts'
    }, {
      hideOpl: true,
      name: 'reports',
      section: 'charts.reports'
    }, {
      hideOpl: true,
      name: 'income_statement',
      section: 'charts.income'
    }, {
      hideOpl: true,
      name: 'supplemental_information',
      section: 'charts.supplement'
    }]
  }, {
    name: 'subscriptions',
    isCollapsed: true,
    children: [{
      name: 'create/edit',
      section: 'subscriptions.create'
    }]
  }, {
    name: 'alerts',
    isCollapsed: true,
    children: [{
      name: 'create/edit',
      section: 'alerts.create'
    }, {
      name: 'error_codes',
      section: 'alerts.error'
    }, {
      name: 'supplemental_information',
      section: 'alerts.supplement'
    }]
  }, {
    name: 'locations',
    isCollapsed: true,
    children: [{
      name: 'list',
      section: 'locations.list'
    }, {
      name: 'location_details',
      section: 'locations.details'
    }, {
      hideOpl: true,
      name: 'organization_fraud_control',
      section: 'locations.fraud'
    }, {
      hideOpl: true,
      name: 'SIGN_UP_BONUS',
      section: 'locations.signUpBonus'
    }, {
      hideOpl: true,
      name: 'download_qr_codes',
      section: 'locations.qrCode'
    }, {
      hideOpl: true,
      name: 'rewards',
      section: 'locations.rewards'
    }, {
      name: 'wash_alert',
      section: 'locations.wash_alert',
      hideOpl: true
    }]
  }, {
    name: 'machines',
    isCollapsed: true,
    children: [{
      name: 'list',
      section: 'machines.list'
    }, {
      name: 'programs',
      section: 'machines.programs'
    }, {
      name: 'create_edit_programs',
      section: 'machines.createPrograms'
    }, {
      name: 'machine_information',
      section: 'machines.machineInfo'
    }]
  }, {
    hideOpl: true,
    name: 'customers',
    isCollapsed: true,
    children: [{
      name: 'list',
      section: 'customers.list'
    }]
  }, {
    name: 'employees',
    isCollapsed: true,
    children: [{
      name: 'list',
      section: 'employees.list'
    }, {
      name: 'security',
      section: 'employees.security'
    }]
  }, {
    name: 'crm',
    isCollapsed: true,
    isCrm: true,
    hideOpl: true,
    children: [{
      name: 'segment_customers_and_create_campaign',
      section: 'crm.filter'
    }, {
      name: 'create_acquisition',
      section: 'crm.acquisition'
    }, {
      name: 'supplemental_information',
      section: 'crm.supplement'
    }]
  }, {
    name: 'retail',
    isRetail: true,
    isCollapsed: true,
    children: [{
      name: 'create_item_or_add_inventory',
      section: 'retail.create'
    }, {
      name: 'service_and_service_types',
      section: 'retail.serviceType'
    }, {
      name: 'taxes',
      section: 'retail.taxes'
    }]
  }, {
    name: 'POS',
    isCollapsed: true,
    isRetail: true,
    hideOpl: true,
    children: [{
      name: 'pos_create_an_order',
      section: 'pos.pos'
    }, {
      name: 'pos_search_for_order',
      section: 'pos.posSearch'
    }, {
      name: 'pos_edit_order_status',
      section: 'pos.posOrder'
    }, {
      name: 'pos_connect_scale_wifi',
      section: 'pos.scale-wifi'
    }, {
      name: 'pos_connect_scale_ipad',
      section: 'pos.scale-ipad'
    }, {
      name: 'supplemental_information',
      section: 'pos.supplement'
    }]
  }, {
    hideOpl: true,
    name: 'promoting_the_app',
    isCollapsed: true,
    children: [{
      name: 'supplemental_information',
      section: 'app-promo.supplement'
    }]
  }, {
    name: 'contact_us',
    isCollapsed: true,
    children: [{
      name: 'support',
      section: 'contact.support'
    }]
  }];
  const COLUMNS = [{
    name: '',
    valuePath: 'name',
    width: 200,
    isFixed: 'left'
  }];
  let SupportSectionComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, (_class = class SupportSectionComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "ajax", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "brand", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "cachedErrorCodes", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "sectionContent", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "selectedSection", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "isCollapsed", _descriptor8, this);
      (0, _defineProperty2.default)(this, "columns", COLUMNS);
    }

    initialLoad() {
      if (this.selectedSection == 'alerts.error') {
        this.fetchErrorCodes();
      } // Filtering


      let sectionArray = SECTIONS;
      let {
        isRetail,
        isCrm,
        isOpl
      } = this.access;

      if (isRetail === false) {
        sectionArray = sectionArray.filter(item => item.isRetail !== true);
      }

      if (isCrm === false) {
        sectionArray = sectionArray.filter(item => item.isCrm !== true);
      }

      let final = [];
      sectionArray.forEach(section => {
        if (section.hideOpl && isOpl) {
          return;
        }

        let newSection = {
          isCollapsed: section.isCollapsed,
          name: section.name
        };

        if (section.children) {
          newSection.children = [];
          section.children.forEach((child, i) => {
            let shouldInclude = true; // Only show Wash Alert for Speed Queen brand

            if (!this.brand.isBrand('sq') && child.section === 'locations.wash_alert') {
              shouldInclude = false;
            }

            if (child.hideOpl && isOpl) {
              shouldInclude = false;
            }

            if (shouldInclude) {
              newSection.children.push(child);
            }
          });
        }

        final.push(newSection);
      });
      this.sectionContent = final;
    }

    get sections() {
      return this.setSection();
    }

    get hasHeroImage() {
      return this.brand.name != 'PRIMUS' && this.brand.name != 'IPSO';
    } // retrieve the error codes json files from S3


    fetchErrorCodes() {
      this.ajax.request('https://alliancelabs-assets-production.s3.amazonaws.com/common/support-error-codes-arr-min.json', {
        method: 'GET'
      }).then(response => {
        this.cachedErrorCodes = response;
      });
    }

    setSection() {
      let newSections = [];
      this.sectionContent.forEach(section => {
        let newSection = Object.assign({}, section);

        if (newSection.children) {
          newSection.children.forEach((child, i) => {
            let newChild = Object.assign({}, child);

            if (newChild.section === this.selectedSection) {
              newSection.isCollapsed = false;
            }
          });
        }

        newSections.push(newSection);
      });
      return newSections;
    }

    reRunSection() {
      this.section = this.setSection();
    }

    get selectedTopLevel() {
      return this.selectedSection.substr(0, this.selectedSection.indexOf('.'));
    }

    selectSection(selected, context) {
      if (selected.rowValue.section) {
        this.selectedSection = selected.rowValue.section;

        if (selected.rowValue.section == "alerts.error" && !this.cachedErrorCodes) {
          this.fetchErrorCodes();
        }
      } else {
        this.reRunSection();
      }
    }

    goToSection(section) {
      this.selectedSection = 'contact.support';
      this.reRunSection();
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "brand", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "cachedErrorCodes", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "sectionContent", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedSection", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isCollapsed", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "initialLoad", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "initialLoad"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectSection", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "selectSection"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "goToSection", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "goToSection"), _class.prototype)), _class));
  _exports.default = SupportSectionComponent;
});