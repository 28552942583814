define("alliance-business-suite/templates/components/form-ui/dropoff", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ChboHmra",
    "block": "{\"symbols\":[\"ui\",\"number\"],\"statements\":[[6,[37,6],[[32,0,[\"showComponent\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"form-ui\",[],[[\"@object\",\"@formDisplay\",\"@formSettings\",\"@selectOptions\"],[[32,0,[\"object\"]],\"compact\",[32,0,[\"formSettings\"]],[32,0,[\"selectOptions\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n    \"],[8,[32,1,[\"checkbox\"]],[],[[\"@params\"],[[30,[36,1],null,[[\"propertyName\",\"label\"],[[32,0,[\"properties\",\"Enabled\",\"key\"]],\"enabled\"]]]]],null],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"form-field\"],[12],[2,\"\\n      \"],[10,\"label\"],[14,0,\"form-ui-element\"],[12],[1,[30,[36,2],[\"passkeys\"],null]],[2,\":\"],[13],[2,\"\\n      \"],[10,\"ul\"],[14,0,\"horizontal-inputs\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[30,[36,3],[0,3,true],null]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"li\"],[12],[2,\"\\n              \"],[8,[32,1,[\"number\"]],[],[[\"@params\"],[[30,[36,1],null,[[\"propertyName\",\"label\",\"min\",\"max\",\"limit\",\"formDisplay\",\"enabled\"],[[30,[36,0],[[32,0,[\"properties\",\"Passkey\",\"key\"]],\".\",[32,2]],null],\"\",0,9,true,\"field-only\",[32,0,[\"Enabled\"]]]]]]],null],[2,\"\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"concat\",\"hash\",\"t\",\"range\",\"-track-array\",\"each\",\"if\"]}",
    "moduleName": "alliance-business-suite/templates/components/form-ui/dropoff.hbs"
  });

  _exports.default = _default;
});