define("alliance-business-suite/helpers/exists-in", ["exports", "ember-models-table/helpers/exists-in"], function (_exports, _existsIn) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _existsIn.default;
    }
  });
  Object.defineProperty(_exports, "existsIn", {
    enumerable: true,
    get: function () {
      return _existsIn.existsIn;
    }
  });
});