define("alliance-business-suite/components/ui/modal-organization-deposits", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21;

  function compareNumbers(a, b) {
    return a - b;
  }

  let UiModalOrganizationDepositsComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember._tracked, _dec18 = Ember._tracked, _dec19 = Ember._tracked, _dec20 = Ember._tracked, _dec21 = Ember._tracked, _dec22 = Ember._action, _dec23 = Ember._action, _dec24 = Ember._action, _dec25 = Ember._action, _dec26 = Ember._action, _dec27 = Ember._action, (_class = class UiModalOrganizationDepositsComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "ajax", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "brand", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "notifications", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "canAccess", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor7, this);
      (0, _defineProperty2.default)(this, "toggleOptions", ['enabled', 'disabled']);
      (0, _initializerDefineProperty2.default)(this, "isLoading", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "maxCcAmountPerDay", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "maxCcTransPerDay", _descriptor10, this);
      (0, _initializerDefineProperty2.default)(this, "limitCardsPerUser", _descriptor11, this);
      (0, _initializerDefineProperty2.default)(this, "isSaving", _descriptor12, this);
      (0, _initializerDefineProperty2.default)(this, "newAmount", _descriptor13, this);
      (0, _initializerDefineProperty2.default)(this, "amount", _descriptor14, this);
      (0, _initializerDefineProperty2.default)(this, "currentOrg", _descriptor15, this);
      (0, _initializerDefineProperty2.default)(this, "_walletAmounts", _descriptor16, this);
      (0, _initializerDefineProperty2.default)(this, "showCvvExample", _descriptor17, this);
      (0, _initializerDefineProperty2.default)(this, "accountNumber", _descriptor18, this);
      (0, _initializerDefineProperty2.default)(this, "selectedCvvOption", _descriptor19, this);
      (0, _initializerDefineProperty2.default)(this, "selectedAddressOption", _descriptor20, this);
      (0, _initializerDefineProperty2.default)(this, "selectedCard", _descriptor21, this);
    }

    get hasAdvancedFPFraudControls() {
      const orgId = this.access.get('accessOrganization.id');
      return {
        canEdit: this.currentOrg.get('paymentGateway.lookupKey') == 'FIRSTPAY',
        cvvEnabled: this.currentOrg.get('hasCvvVerification'),
        addressEnabled: this.currentOrg.get('hasAddressVerification')
      };
    }

    get cards() {
      let cards = [];

      if (this.canAccess.canAccess('LOCATIONS_MANAGE_FUND_DEPOSIT_LIMITS')) {
        cards.push('fraud_limits');
      }

      if (this.canAccess.canAccess('LOCATIONS_MANAGE_ACCOUNT_CREDIT_VALUES')) {
        cards.push('reload_amounts');
      }

      if (this.canAccess.canAccess('LOCATIONS_MANAGE_ORGANIZATION_ACCOUNT_INFO')) {
        cards.push('account_info');
      }

      return cards;
    }

    initialLoad() {
      const organizationId = this.access.accessOrganization.get('id');
      this.currentOrg = this.store.peekRecord('organization', organizationId);
      this.accountNumber = this.currentOrg.get('accountNumber');
      this.fetchFundsDepositLimits();
      this.fetchWalletTiers();
      this.fetchFraudLimits();
    }

    fetchFraudLimits() {
      this.ajax.request(`/organizations/${this.access.get('accessOrganization.id')}/fraud`).then(response => {
        this.limitCardsPerUser = response.limitCardsPerUser;
      });
    }

    fetchFundsDepositLimits() {
      this.ajax.request(`/organizations/${this.access.get('accessOrganization.id')}/fundsDepositLimits`).then(response => {
        this.maxCcAmountPerDay = response.maxCcAmountPerDay;
        this.maxCcTransPerDay = response.maxCcTransPerDay;
        this.isLoading = false;
      });
    } // computed('_walletAmounts.[]', 'isLoading',


    get walletAmounts() {
      if (!this.isLoading) {
        return this._walletAmounts.sort(compareNumbers);
      }

      return [];
    }

    fetchWalletTiers() {
      this.ajax.request(`/accountCreditValues?raw=true&organizationId=${this.access.get('accessOrganization.id')}`).then(response => {
        this._walletAmounts = response.data.mapBy('attributes.amount');
      });
    }

    saveAdvancedFPFraud() {
      if (this.hasAdvancedFPFraudControls) {
        const hasCvvVerification = this.selectedCvvOption == 'enabled';
        const hasAddressVerification = this.selectedAddressOption == 'enabled';
        const limitCardsPerUser = this.limitCardsPerUser ? this.limitCardsPerUser : null;
        return new Promise((resolve, reject) => {
          this.ajax.request(`/organizations/${this.access.get('accessOrganization.id')}/fraud`, {
            method: 'POST',
            data: {
              hasCvvVerification,
              hasAddressVerification,
              limitCardsPerUser // this is not First Pay only

            }
          }).then(response => {
            resolve(response); // update local store

            this.currentOrg.hasCvvVerification = hasCvvVerification;
            this.currentOrg.hasAddressVerification = hasAddressVerification;
          }, function (reason) {
            reject(new Error(`Failed with reason: ${reason}`));
          });
        });
      }
    }

    saveFraud() {
      let maxCcTransactionsPerDay = parseInt(this.maxCcTransPerDay);
      let maxCcAmountPerDay = this.maxCcAmountPerDay;

      if (maxCcTransactionsPerDay <= 0 || isNaN(maxCcTransactionsPerDay)) {
        maxCcTransactionsPerDay = null;
      }

      if (maxCcAmountPerDay <= 0) {
        maxCcAmountPerDay = null;
      }

      return this.ajaxPatchRequest(`/organizations/${this.access.get('accessOrganization.id')}/fundsDepositLimits`, {
        maxCcTransactionsPerDay,
        maxCcAmountPerDay
      });
    }

    saveWallet() {
      let amounts = this.walletAmounts;

      if (amounts.length === 0) {
        this.notifications.error(this.intl.t('empty_wallet_amounts'), {
          autoClear: true,
          clearDuration: 5000
        });
        return;
      }

      return this.ajaxPatchRequest(`/organizations/${this.access.get('accessOrganization.id')}/accountCreditValues`, {
        amounts: amounts
      });
    }

    saveOrgInfo() {
      const orgId = this.access.get('accessOrganization.id'); // no need for PATCH if the values did not change

      if (this.accountNumber == this.currentOrg.get('accountNumber')) {
        return;
      }

      function onSuccessUpdateOrg() {
        this.currentOrg.accountNumber = this.accountNumber;
      }

      return this.ajaxPatchRequest(`/organizations/${orgId}/settings`, {
        accountNumber: this.accountNumber,
        brandLookupKey: this.brand.name
      }, onSuccessUpdateOrg.bind(this));
    }

    ajaxPatchRequest(url, data, cb) {
      return new Promise((resolve, reject) => {
        this.ajax.request(url, {
          method: 'PATCH',
          data
        }).then(response => {
          if (cb) {
            cb();
          }

          resolve(response);
        }, function (reason) {
          reject(new Error(`Failed with reason: ${reason}`));
        });
      });
    }

    close() {
      if (!this.isSaving) {
        this.args.close();
      }
    }

    addAmount() {
      let amount = this.newAmount;

      if (amount > 0) {
        this._walletAmounts.pushObject(amount);

        this.newAmount = '';
      }
    }

    removeAmount(amount) {
      this._walletAmounts.removeObject(amount);
    }

    save() {
      if (this.access.isDemo) {
        alert('Demo mode restricted.');
        return;
      }

      this.isSaving = true;
      Promise.all([this.canAccess.canAccess('LOCATIONS_MANAGE_FUND_DEPOSIT_LIMITS') ? this.saveFraud() : null, this.canAccess.canAccess('LOCATIONS_MANAGE_ACCOUNT_CREDIT_VALUES') ? this.saveWallet() : null, this.canAccess.canAccess('LOCATIONS_MANAGE_FUND_DEPOSIT_LIMITS') ? this.saveAdvancedFPFraud() : null, this.canAccess.canAccess('LOCATIONS_MANAGE_ORGANIZATION_ACCOUNT_INFO') ? this.saveOrgInfo() : null]).then(responses => {
        this.notifications.success(this.intl.t('success'), {
          clearDuration: 5000,
          autoClear: true
        });
      }).catch(() => {
        this.notifications.error(this.intl.t('error'), {
          autoClear: false
        });
      }).finally(() => {
        this.isSaving = false;
      });
    }

    toggleCvvExample() {
      this.showCvvExample = !this.showCvvExample;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "brand", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "notifications", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "canAccess", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isLoading", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "maxCcAmountPerDay", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "maxCcTransPerDay", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "limitCardsPerUser", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isSaving", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "newAmount", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "amount", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "currentOrg", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "_walletAmounts", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "showCvvExample", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "accountNumber", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedCvvOption", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.hasAdvancedFPFraudControls.cvvEnabled ? this.toggleOptions[0] : this.toggleOptions[1];
    }
  }), _descriptor20 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedAddressOption", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.hasAdvancedFPFraudControls.addressEnabled ? this.toggleOptions[0] : this.toggleOptions[1];
    }
  }), _descriptor21 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedCard", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.canAccess.canAccess('LOCATIONS_MANAGE_FUND_DEPOSIT_LIMITS') ? 'fraud_limits' : 'reload_amounts';
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "initialLoad", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "initialLoad"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "close", [_dec23], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "addAmount", [_dec24], Object.getOwnPropertyDescriptor(_class.prototype, "addAmount"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "removeAmount", [_dec25], Object.getOwnPropertyDescriptor(_class.prototype, "removeAmount"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "save", [_dec26], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "toggleCvvExample", [_dec27], Object.getOwnPropertyDescriptor(_class.prototype, "toggleCvvExample"), _class.prototype)), _class));
  _exports.default = UiModalOrganizationDepositsComponent;
});