define("alliance-business-suite/transforms/intl", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Transform
  } = _emberData.default;

  var _default = Transform.extend({
    intl: Ember.inject.service(),

    deserialize(value) {
      let t = this.intl;
      return t.t(value);
    },

    serialize(value) {
      return value;
    }

  });

  _exports.default = _default;
});