define("alliance-business-suite/components/search-results", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@glimmer/component"], function (_exports, _applyDecoratedDescriptor2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  let SearchResultsComponent = (_dec = Ember._action, (_class = class SearchResultsComponent extends _component.default {
    loadAll() {
      this.args.loadAll();
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class.prototype, "loadAll", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "loadAll"), _class.prototype)), _class));
  _exports.default = SearchResultsComponent;
});