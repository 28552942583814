define("alliance-business-suite/components/summary/reports/s-laundromat-overview", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "alliance-business-suite/utils/currency-format-util", "alliance-business-suite/utils/report-tables-util"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _currencyFormatUtil, _reportTablesUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  let SummaryReportsSLaundromatOverviewComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, (_class = class SummaryReportsSLaundromatOverviewComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor3, this);
    }

    get translatedColumns() {
      return [{
        name: 'location',
        valuePath: 'location',
        width: 250,
        isFixed: 'left'
      }, {
        name: 'goods_revenue',
        valuePath: 'goodsRevenue',
        width: 180
      }, {
        name: 'wdf_revenue',
        valuePath: 'wdfRevenue',
        width: 180
      }, {
        name: 'self_service_revenue',
        valuePath: 'selfServiceRevenue',
        width: 180
      }, {
        name: 'total_revenue',
        valuePath: 'totalRevenue',
        width: 180
      }, {
        name: 'turns_per_day',
        valuePath: 'turnsPerDay',
        width: 180
      }];
    }

    get rowsWithChildren() {
      let {
        model
      } = this.args;
      let laundromatPerDayData = [];
      let laundromatMachineData = [];
      let totalGoodsRevenueSum = 0;
      let totalWdfSum = 0;
      let totalSelfServiceSum = 0;
      let totalDailyRevSum = 0;
      let totalMachineRevenueSum = 0;
      let totalMachineTurnsSum = 0;
      model.machineSizeTotals.forEach(machine => {
        if (machine.revenue !== 0) {
          totalMachineRevenueSum += machine.revenue;
        }

        if (machine.averageTurns !== 0) {
          totalMachineTurnsSum += machine.averageTurns;
        }

        let totalObj = {
          location: machine.machineType,
          totalRevenue: _currencyFormatUtil.formatCurrencyString.call(this, machine.revenue || 0),
          turnsPerDay: machine.averageTurns
        };
        laundromatMachineData.push(totalObj);
      });
      model.totals.forEach(total => {
        if (total.goodsRevenue !== 0) {
          totalGoodsRevenueSum += total.goodsRevenue;
        }

        if (total.washDryFoldRevenue !== 0) {
          totalWdfSum += total.washDryFoldRevenue;
        }

        if (total.revenue !== 0) {
          totalSelfServiceSum += total.revenue;
        }

        totalDailyRevSum += total.revenue + total.washDryFoldRevenue + total.goodsRevenue;
        let totalObj = {
          location: moment(total.reportDate).format(`${_reportTablesUtil.formatDateTimeString.call(this)}`),
          goodsRevenue: _currencyFormatUtil.formatCurrencyString.call(this, total.goodsRevenue || 0),
          wdfRevenue: _currencyFormatUtil.formatCurrencyString.call(this, total.washDryFoldRevenue || 0),
          selfServiceRevenue: _currencyFormatUtil.formatCurrencyString.call(this, total.revenue || 0),
          totalRevenue: _currencyFormatUtil.formatCurrencyString.call(this, total.revenue + total.washDryFoldRevenue + total.goodsRevenue || 0),
          turnsPerDay: total.averageTurns
        };
        laundromatPerDayData.push(totalObj);
      });
      return [{
        location: model.organizationName,
        children: [{
          location: `${this.intl.t('revenue_by_day')} (${model.totals.length || 0})`,
          goodsRevenue: _currencyFormatUtil.formatCurrencyString.call(this, totalGoodsRevenueSum),
          wdfRevenue: _currencyFormatUtil.formatCurrencyString.call(this, totalWdfSum),
          selfServiceRevenue: _currencyFormatUtil.formatCurrencyString.call(this, totalSelfServiceSum),
          totalRevenue: _currencyFormatUtil.formatCurrencyString.call(this, totalDailyRevSum),
          // isCollapsed: true,
          children: laundromatPerDayData
        }, {
          location: `${this.intl.t('revenue_by_machineType')} (${model.machineSizeTotals.length || 0})`,
          totalRevenue: _currencyFormatUtil.formatCurrencyString.call(this, totalMachineRevenueSum),
          turnsPerDay: totalMachineTurnsSum.toFixed(2),
          // isCollapsed: true,
          children: laundromatMachineData
        }]
      }];
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SummaryReportsSLaundromatOverviewComponent;
});