define("alliance-business-suite/helpers/traceability/format-cycle-name", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // formats a string (ex "FINAL_RINSE" -> "Final Rinse")
  function traceabilityFormatCycleName(_ref) {
    let [cycleName] = _ref;
    let finalString = '';

    if (!cycleName || cycleName.length === 0) {
      return 'N/A';
    }

    for (var i = 0; i < cycleName.length; i++) {
      if (i === 0 || cycleName[i - 1] === '_') {
        finalString += cycleName[i].toUpperCase();
      } else if (cycleName[i] === '_') {
        finalString += ' ';
      } else {
        finalString += cycleName[i].toLowerCase();
      }
    }

    return finalString;
  }

  var _default = Ember.Helper.helper(traceabilityFormatCycleName);

  _exports.default = _default;
});