define("alliance-business-suite/models/receipt-markup-json", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    room: belongsTo('room'),
    imageUrl: attr('string', {
      defaultValue: ''
    }),
    header1: attr('string'),
    header2: attr('string'),
    header3: attr('string'),
    header4: attr('string'),
    footer1: attr('string'),
    footer2: attr('string'),
    footer3: attr('string'),
    footer4: attr('string')
  });

  _exports.default = _default;
});