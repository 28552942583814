define("alliance-business-suite/routes/summary/reports", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "alliance-business-suite/routes/_secureRoute"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _secureRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  let SummaryReportsRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = class SummaryReportsRoute extends _secureRoute.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "cache", _descriptor2, this);
      (0, _defineProperty2.default)(this, "allowAccess", 'SUMMARY_REPORTS_VIEW_PAGE');
      (0, _defineProperty2.default)(this, "disableBrush", true);
    }

    async model() {
      let organizationId = this.access.get('accessOrganization.id');

      if (!this.cache.get('loadedGeoIncludedAll')) {
        return Ember.RSVP.hash({
          reports: this.store.findAll('report-type'),
          geoBoundaries: this.store.queryRecord('organization', {
            organization: organizationId,
            geoBoundaries: true,
            include: 'all'
          })
        });
      } else {
        return Ember.RSVP.hash({
          reports: this.store.findAll('report-type'),
          geoBoundaries: this.store.peekRecord('organization', organizationId)
        });
      }
    }

    setupController(controller, model) {
      super.setupController(controller, model);
      controller.rooms = model.rooms;
      controller.reports = model.reports;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "cache", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SummaryReportsRoute;
});