define("alliance-business-suite/components/summary/reports/s-audit-productivity", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "alliance-business-suite/utils/report-tables-util"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _reportTablesUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  let SummaryReportsSAuditProductivityComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, (_class = class SummaryReportsSAuditProductivityComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor3, this);
    }

    get translatedColumns() {
      return [{
        name: "location_machine",
        valuePath: "name",
        width: 225,
        isFixed: "left"
      }, {
        name: "machine",
        valuePath: "machineControlName",
        width: 200
      }, {
        name: "date_range",
        valuePath: "dateRange",
        width: 200
      }, {
        name: "total_hours",
        valuePath: "totalOperationHours",
        width: 125
      }, // { name: "target_hours", valuePath: "targetHours", width: 125 },
      {
        name: "productivity_lb",
        valuePath: "productivityPounds",
        width: 125
      }, // { name: "productivity", valuePath: "productivity", width: 125 },
      {
        name: "labor_hours",
        valuePath: "laborHours",
        width: 125
      }];
    }

    get rowsWithChildren() {
      let geoBoundaries = this.args.model.get("geoBoundaries");
      let rowData = [];
      geoBoundaries.forEach(geoBoundary => {
        let rowArray = [];
        let nameArray = [];
        let totalTargetHoursSum = 0;
        let totalOperationHoursSum = 0;
        let topTotalTargetHoursArray = [];
        let topTotalOperationHoursArray = []; // let totalProductitivyPoundsSum = 0;
        // let totalProductitivyPounds = [];

        rowData.push({
          name: _reportTablesUtil.localizeString.call(this, geoBoundary.name, "Location", "location"),
          children: nameArray
        });
        geoBoundary.rooms.forEach(room => {
          let productivityPercentage = [];
          let roomTargetHoursSum = 0;
          let roomTargetHoursArray = [];
          let roomTotalOperationHoursSum = 0;
          let roomTotalOperationHoursArray = [];
          nameArray.push({
            name: room.name,
            children: rowArray // targetHours: roomTargetHoursArray,
            // totalOperationHours: roomTotalOperationHoursArray,
            // productivity: productivityPercentage,

          });
          room.machineAuditGroupTypes.forEach(machineAuditType => {
            machineAuditType.machineTypes.forEach(machineType => {
              let machineArray = [];
              let machineTypeName = `${machineAuditType.name} - ${machineType.name}`;
              let machineTargetHoursSum = 0;
              let machineTotalOperationHoursSum = 0;
              let _machineTargetHoursArray = [];
              let _machineTotalOperationHoursArray = [];
              rowArray.push({
                name: machineTypeName,
                children: machineArray // totalOperationHours: _machineTotalOperationHoursArray,

              });
              machineType.machines.forEach(machine => {
                let machineTargetHoursArray = [];
                let machineTotalOperationHoursArray = [];
                let targetHours = machine.targetHours;
                let totalOperationHours = machine.totalOperationHours;

                let machineName = _reportTablesUtil.machineNameDisplay.call(this, machine.machineName, machine.machineNumber);

                let operationHours = machine.totalOperationHours || 0;
                let dateRange = "";

                if (targetHours !== 0) {
                  machineTargetHoursArray.push(targetHours);
                  totalTargetHoursSum = (0, _reportTablesUtil.sumArray)(machineTargetHoursArray, totalTargetHoursSum);
                  roomTargetHoursSum = targetHours;
                  machineTargetHoursSum = (0, _reportTablesUtil.sumArray)(machineTargetHoursArray, machineTargetHoursSum);
                }

                if (totalOperationHours !== 0) {
                  machineTotalOperationHoursArray.push(totalOperationHours);
                  totalOperationHoursSum = (0, _reportTablesUtil.sumArray)(machineTotalOperationHoursArray, totalOperationHoursSum);
                  roomTotalOperationHoursSum = (0, _reportTablesUtil.sumArray)(machineTotalOperationHoursArray, roomTotalOperationHoursSum);
                  machineTotalOperationHoursSum = (0, _reportTablesUtil.sumArray)(machineTotalOperationHoursArray, machineTotalOperationHoursSum);
                }

                if (machine.minFirstReceivedAt && machine.maxFirstReceivedAt) {
                  dateRange = `${moment(machine.minFirstReceivedAt).format(_reportTablesUtil.formatDateTimeString.call(this))} - ${moment(machine.maxFirstReceivedAt).format(_reportTablesUtil.formatDateTimeString.call(this))}`;
                } // totalProductitivyPoundsSum += machine.productivity;


                machineArray.push({
                  name: machineName,
                  dateRange: dateRange,
                  machineControlName: machine.machineControlName,
                  productivityPounds: machine.productivity,
                  laborHours: machine.laborHours,
                  totalOperationHours: operationHours
                });
              });

              _machineTargetHoursArray.push(machineTargetHoursSum || 0);

              _machineTotalOperationHoursArray.push(machineTotalOperationHoursSum || 0);
            });
          });
          roomTargetHoursArray.push(roomTargetHoursSum || 0);
          roomTotalOperationHoursArray.push(roomTotalOperationHoursSum || 0);
          let productivityPercent = roomTotalOperationHoursSum > 0 && roomTargetHoursSum > 0 ? roomTotalOperationHoursSum / roomTargetHoursSum : 0;
          productivityPercentage.push(`${(productivityPercent * 100).toFixed(2)}%`);
        });
        topTotalTargetHoursArray.push(totalTargetHoursSum || 0);
        topTotalOperationHoursArray.push(totalOperationHoursSum || 0);
      });
      return rowData;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SummaryReportsSAuditProductivityComponent;
});