define("alliance-business-suite/templates/components/ui/led-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eB+ha7dZ",
    "block": "{\"symbols\":[],\"statements\":[[8,\"input\",[[24,0,\"led7\"],[24,\"spellcheck\",\"false\"]],[[\"@type\",\"@value\",\"@key-up\",\"@disabled\"],[\"text\",[32,0,[\"displayValue\"]],[30,[36,0],[[32,0],\"validateInput\"],null],[32,0,[\"isDisabled\"]]]],null],[2,\"\\n\\n\"],[8,\"form-ui/dev-tools\",[],[[\"@outputs\"],[[30,[36,1],null,[[\"property\",\"displayValue\",\"isDisabled\",\"isAscii\"],[[32,0,[\"property\"]],[32,0,[\"displayValue\"]],[32,0,[\"isDisabled\"]],[32,0,[\"isAscii\"]]]]]]],null]],\"hasEval\":false,\"upvars\":[\"action\",\"hash\"]}",
    "moduleName": "alliance-business-suite/templates/components/ui/led-field.hbs"
  });

  _exports.default = _default;
});