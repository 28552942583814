define("alliance-business-suite/components/table/retail/categories/table-cell-upsell-categories", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @record.upsellCategories.length}}
    <ul>
      {{#each @record.upsellCategories as |cat|}}
        <li>{{cat.name}}</li>
      {{/each}}
    </ul>
  {{else}}
    <i aria-hidden="true" class="fa fa-ban" style="opacity: .25"></i>
  {{/if}}
  
  */
  {
    "id": "8eKOfDSd",
    "block": "{\"symbols\":[\"cat\",\"@record\"],\"statements\":[[6,[37,2],[[32,2,[\"upsellCategories\",\"length\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"ul\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,2,[\"upsellCategories\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"li\"],[12],[1,[32,1,[\"name\"]]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"i\"],[14,\"aria-hidden\",\"true\"],[14,0,\"fa fa-ban\"],[14,5,\"opacity: .25\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\",\"if\"]}",
    "moduleName": "alliance-business-suite/components/table/retail/categories/table-cell-upsell-categories.hbs"
  });

  class TableRetailCategoriesTableCellUpsellCategoriesComponent extends _component.default {}

  _exports.default = TableRetailCategoriesTableCellUpsellCategoriesComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TableRetailCategoriesTableCellUpsellCategoriesComponent);
});