define("alliance-business-suite/components/customers/modal-download-customers-list", ["exports", "jquery", "moment", "alliance-business-suite/config/environment", "ember-cp-validations"], function (_exports, _jquery, _moment, _environment, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'startDate': [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    'endDate': [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    'dateRange': [(0, _emberCpValidations.validator)('dependent', {
      dependentKeys: ['startDate', 'endDate'],
      on: ['startDate', 'endDate'],
      message: 'Must have a valid start and end date'
    })] // 'userEmail': [
    //   validator('presence', {
    //     presence: true
    //   }),
    //
    //   validator('format', {
    //     type: 'email',
    //     allowNonTld: true
    //   })
    // ]

  });

  var _default = Ember.Component.extend(Validations, {
    access: Ember.inject.service(),
    ajax: Ember.inject.service(),
    canAccess: Ember.inject.service(),
    intl: Ember.inject.service(),
    notifications: Ember.inject.service(),
    store: Ember.inject.service(),
    downloadOptions: Ember.computed(function () {
      let canExportTransactions = this.canAccess.canAccess('CUSTOMERS_LIST_DOWNLOAD');
      let canExportCustomers = this.canAccess.canAccess('CUSTOMERS_REPORTS_VIEW_CUSTOMER_OVERVIEW');
      let result = [];
      console.log('can export?', this.canAccess.canAccess('CUSTOMERS_REPORTS_VIEW_CUSTOMER_OVERVIEW'));

      if (this.canAccess.canAccess('CUSTOMERS_REPORTS_VIEW_CUSTOMER_OVERVIEW')) {
        result.push({
          id: 'CUSTOMER_DOWNLOAD',
          text: 'export_customer_list'
        });
      }

      if (this.canAccess.canAccess('CUSTOMERS_LIST_DOWNLOAD')) {
        result.push({
          id: 'CUSTOMER_TRANSACTION_DOWNLOAD',
          text: 'export_transaction_list'
        });
      }

      return result;
    }),
    minStartDate: Ember.computed(function () {// return moment().toDate();
    }),
    maxStartDate: Ember.computed(function () {
      return (0, _moment.default)().subtract(1, 'days').toDate();
    }),
    minEndDate: Ember.computed('startDate', function () {
      let startDate = this.startDate || (0, _moment.default)();
      return (0, _moment.default)(startDate).add(1, 'days').toDate();
    }),
    maxEndDate: Ember.computed('startDate', function () {
      let startDate = this.startDate || (0, _moment.default)();

      if (startDate && (0, _moment.default)(startDate).add(30, 'days').isBefore((0, _moment.default)())) {
        return (0, _moment.default)(startDate).add(30, 'days').toDate();
      }

      return (0, _moment.default)().toDate();
    }),
    editingDisabled: Ember.computed('isLoading', function () {
      let isLoading = this.isLoading;

      if (isLoading) {
        return true;
      }
    }).readOnly(),

    exportCustomerTransactions() {
      let userId = this.get('access.accessUser.id');
      let user = this.store.peekRecord('employee', userId);
      let organizationId = this.get('access.accessOrganization.id');
      let timeZone = user.get('timeZone');
      let email = this.access.user.get('primaryEmail');
      let startDate = this.startDate;
      let endDate = this.endDate;
      let brandLookupKey = _environment.default.brand;
      let notifications = this.notifications;
      this.set('isLoading', true);

      if (confirm(`${this.intl.t('download_customers_list_confirmation')} ${email}?`)) {
        this.ajax.request('/reports/CUSTOMER_PAYMENT_TRXN_HISTORY/email', {
          method: 'POST',
          data: {
            email,
            timeZone,
            organizationId,
            startDate,
            endDate,
            brandLookupKey
          }
        }).then(result => {
          notifications.success(this.intl.t('download_customers_list_success'), {
            clearDuration: 5000,
            autoClear: true
          });
          this.set('isLoading', true);
          this.close();
        }, () => {
          notifications.error(this.intl.t('download_customers_list_error'), {
            clearDuration: 5000,
            autoClear: true
          });
          this.set('isLoading', true);
          this.close();
        });
      }
    },

    exportCustomersList() {
      if (confirm(this.intl.t('export_all_customers_confirmation'))) {
        this.set('isLoading', true);
        this.ajax.request('/reports/CUSTOMER_OVERVIEW/email', {
          method: 'POST',
          data: {
            organizationId: this.get('access.accessOrganization.id'),
            brandLookupKey: _environment.default.brand
          }
        }).then(result => {
          this.notifications.info(this.intl.t('export_all_customers_success'), {
            clearDuration: 15000,
            autoClear: true
          });
          this.set('isLoading', true);
          this.close();
        }).catch(() => {
          this.notifications.success(this.intl.t('export_all_customers_error'), {
            clearDuration: 5000,
            autoClear: true
          });
          this.set('isLoading', true);
        });
      }
    },

    actions: {
      close() {
        this.close();
      },

      toggleDownloadOptions() {
        this.toggleProperty('viewDownloadOptions');
      },

      setDate(property, value) {
        let date = value;

        if ((0, _moment.default)(date).isValid()) {
          date = (0, _moment.default)(value).format('YYYY-MM-DDTHH:mm:ss');
        }

        this.set(property, date);
      },

      submitExport() {
        let {
          selectedDownloadOption
        } = this;

        if (this.access.isDemo) {
          alert('Demo mode restricted.');
          return;
        }

        if (selectedDownloadOption.id === 'CUSTOMER_DOWNLOAD') {
          this.exportCustomersList();
        }

        if (selectedDownloadOption.id === 'CUSTOMER_TRANSACTION_DOWNLOAD') {
          // startDate and endDate can't be empty
          if (this.startDate && this.endDate) {
            this.exportCustomerTransactions();
          }
        }
      }

    }
  });

  _exports.default = _default;
});