define("alliance-business-suite/components/dashboard/summary/details/pos-cash-section", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="summary-details-tray" {{did-insert this.initialLoad}} {{did-update this.updateCall @data}}>
  
    <label>
      {{t "today_pos_cash_on_hand"}}
    </label>
  
      {{#if this.isLoading}}
        <Dashboard::Summary::SummaryTotal
          @title={{t "loading"}}
          @color="pos_cash"/>        
      {{else}}
        {{#each-in this.roomStat as |room value|}}
          <Dashboard::Summary::SummaryTotal
            @title={{value.name}}
            @total={{value.cash}}
            @color="pos_cash"/>
        {{/each-in}}
      {{/if}}
  </div>
  
  */
  {
    "id": "2Asok2pc",
    "block": "{\"symbols\":[\"value\",\"room\",\"@data\"],\"statements\":[[11,\"div\"],[24,0,\"summary-details-tray\"],[4,[38,3],[[32,0,[\"initialLoad\"]]],null],[4,[38,4],[[32,0,[\"updateCall\"]],[32,3]],null],[12],[2,\"\\n\\n  \"],[10,\"label\"],[12],[2,\"\\n    \"],[1,[30,[36,2],[\"today_pos_cash_on_hand\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[6,[37,5],[[32,0,[\"isLoading\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[8,\"dashboard/summary/summary-total\",[],[[\"@title\",\"@color\"],[[30,[36,2],[\"loading\"],null],\"pos_cash\"]],null],[2,\"        \\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[30,[36,0],[[32,0,[\"roomStat\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"dashboard/summary/summary-total\",[],[[\"@title\",\"@total\",\"@color\"],[[32,1,[\"name\"]],[32,1,[\"cash\"]],\"pos_cash\"]],null],[2,\"\\n\"]],\"parameters\":[1,2]}]]]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-each-in\",\"each\",\"t\",\"did-insert\",\"did-update\",\"if\"]}",
    "moduleName": "alliance-business-suite/components/dashboard/summary/details/pos-cash-section.hbs"
  });

  let DashboardSummaryDetailsPosCashSectionComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._action, _dec6 = Ember._action, (_class = class DashboardSummaryDetailsPosCashSectionComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "ajax", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "roomStat", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "isLoading", _descriptor4, this);
    }

    // we need the update so the {{each-in}} updates
    initialLoad() {
      this.fetchPosCash();
    }

    updateCall() {
      this.fetchPosCash();
    }

    fetchPosCash() {
      this.isLoading = true;
      this.ajax.request(`/pos/cash?raw=true`, {
        method: 'GET'
      }).then(response => {
        this.calculateCategories(response);
      });
    }

    calculateCategories(data) {
      this.isLoading = true;
      this.roomStat = {};
      data.forEach(room => {
        let id = room.roomId;
        this.roomStat[id] = {
          id: id,
          name: this.store.peekRecord('room', id).get('formattedBreadcrumbDisplay'),
          cash: room.cash ? room.cash : 0
        };
      });
      this.isLoading = false;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "roomStat", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isLoading", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "initialLoad", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "initialLoad"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "updateCall", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "updateCall"), _class.prototype)), _class));
  _exports.default = DashboardSummaryDetailsPosCashSectionComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, DashboardSummaryDetailsPosCashSectionComponent);
});