define("alliance-business-suite/serializers/account-transaction", ["exports", "lodash", "alliance-business-suite/serializers/application"], function (_exports, _lodash, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    /**
     * Whether `includes` from the JSON API response should be pushed to the
     * store.
     *
     * @type {boolean}
     * @protected
     */
    allowIncludes: false,

    /**
     * Hacks a JSON API data object returned within a JSON API document to
     * conform to what is required to make the endpoint work properly with Ember
     * Data and within this application.
     *
     * @param {object} data A data object from a JSON API document
     * @protected
     */
    normalizeDataHack(data) {
      // Until we have an endpoint for `account-transaction-types`, convert this
      // into an attribute. [twl 4.May.17]
      if (data.relationships && data.relationships.userAccountTransactionType) {
        data.attributes.userAccountTransactionType = {
          id: data.relationships.userAccountTransactionType.data.id
        };
        delete data.relationships.userAccountTransactionType;
      } // Convert the paymentMethod from an attribute to a relationship


      if (_lodash.default.get(data, 'attributes.paymentMethod.id') != null) {
        _lodash.default.set(data, 'relationships.paymentMethod.data.type', 'payment-methods');

        _lodash.default.set(data, 'relationships.paymentMethod.data.id', data.attributes.paymentMethod.id);
      }
    }

  });

  _exports.default = _default;
});