define("alliance-business-suite/templates/components/application-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IXjeuVFM",
    "block": "{\"symbols\":[\"burger\",\"menu\",\"&default\"],\"statements\":[[6,[37,1],[[32,0,[\"session\",\"isAuthenticated\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,1],[[30,[36,0],[[32,0,[\"session\",\"data\",\"hasPassedOrgSelect\"]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"liquid-outlet\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"div\"],[14,1,\"ember-wormhole-destination\"],[12],[13],[2,\"\\n    \"],[8,\"ui/system-alerts\",[],[[],[]],null],[2,\"\\n\\n    \"],[8,\"burger-menu\",[],[[\"@animation\",\"@dismissOnItemClick\",\"@width\"],[\"push\",true,275]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,1,[\"menu\"]],[],[[\"@itemTagName\",\"@dismissOnItemClick\"],[\"li\",true]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"side-navigation\",[],[[\"@menu\",\"@burger\",\"@tagName\"],[[32,2],[32,1],\"aside\"]],null],[2,\"\\n      \"]],\"parameters\":[2]}]]],[2,\"\\n\\n      \"],[8,[32,1,[\"outlet\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"div\"],[14,0,\"main\"],[12],[2,\"\\n          \"],[8,\"ui/system-warning\",[],[[],[]],null],[2,\"\\n          \"],[8,\"ui/machine-program-send-container\",[],[[],[]],null],[2,\"\\n          \"],[8,\"system-header\",[],[[\"@tagName\",\"@burger\"],[\"header\",[32,1]]],null],[2,\"\\n          \"],[18,3,null],[2,\"\\n        \"],[13],[2,\"\\n        \"],[8,\"sidebar-panel\",[],[[],[]],null],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[18,3,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"not\",\"if\"]}",
    "moduleName": "alliance-business-suite/templates/components/application-section.hbs"
  });

  _exports.default = _default;
});