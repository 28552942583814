define("alliance-business-suite/components/machines/programs/advanced/midas-wx", ["exports", "alliance-business-suite/mixins/machines/programs/midas-options", "alliance-business-suite/mixins/machines/programs/midas-property-names"], function (_exports, _midasOptions, _midasPropertyNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_midasOptions.default, _midasPropertyNames.default, {
    object: Ember.computed.alias('machineProgram.settings'),
    // This is required for selectOptions to properly find preset values
    formConfig: Ember.computed(function () {
      return {
        columns: [{
          sections: [{
            title: "power_fail_reset",
            controls: [{
              type: "power-fail-reset",
              properties: this.propertyNames.powerFailReset
            }]
          }, {
            title: "temperature_override",
            controls: [{
              type: "temperature-override",
              properties: {
                WashCold: 'Program.Cycle.WashColdTempOverride_WX',
                WashWarm: 'Program.Cycle.WashWarmTempOverride_WX',
                WashHot: 'Program.Cycle.WashHotTempOverride_WX',
                WashNoHeat: 'Program.Cycle.WashNoHeatTempOverride_WX',
                Wash15C: 'Program.Cycle.Wash15CTempOverride_WX',
                Wash30C: 'Program.Cycle.Wash30CTempOverride_WX',
                Wash40C: 'Program.Cycle.Wash40CTempOverride_WX',
                Wash50C: 'Program.Cycle.Wash50CTempOverride_WX',
                Wash60C: 'Program.Cycle.Wash60CTempOverride_WX',
                Wash90C: 'Program.Cycle.Wash90CTempOverride_WX',
                PrewashCold: 'Program.Cycle.PrewashColdTempOverride_WX',
                PrewashWarm: 'Program.Cycle.PrewashWarmTempOverride_WX',
                PrewashHot: 'Program.Cycle.PrewashHotTempOverride_WX',
                PrewashNoHeat: 'Program.Cycle.PrewashNoHeatTempOverride_WX',
                Prewash15C: 'Program.Cycle.Prewash15CTempOverride_WX',
                Prewash30C: 'Program.Cycle.Prewash30CTempOverride_WX',
                Prewash40C: 'Program.Cycle.Prewash40CTempOverride_WX',
                Prewash50C: 'Program.Cycle.Prewash50CTempOverride_WX',
                Prewash60C: 'Program.Cycle.Prewash60CTempOverride_WX',
                Prewash90C: 'Program.Cycle.Prewash90CTempOverride_WX'
              }
            }]
          }]
        }, {
          sections: [{
            title: "coin_start_pulses",
            controls: [{
              type: "coin-start-pulses",
              properties: this.propertyNames.coinStartPulses,
              options: this.selectOptions.StartPulseMode
            }]
          }, {
            title: "availability",
            controls: [{
              type: "select",
              propertyName: "Program.Misc.MiscConfig.AvailableType1",
              options: "AvailableType"
            }, {
              type: "select",
              propertyName: "Program.Misc.MiscConfig.AvailableType2",
              options: "AvailableType"
            }, {
              type: "select",
              propertyName: "Program.Misc.MiscConfig.AvailableType3",
              options: "AvailableType"
            }]
          }, {
            title: "low_power",
            controls: [{
              type: "low-power",
              properties: this.propertyNames.lowPower,
              index: 0,
              number: 1
            }, {
              type: "low-power",
              properties: this.propertyNames.lowPower,
              index: 1,
              number: 2
            }, {
              type: "low-power",
              properties: this.propertyNames.lowPower,
              index: 2,
              number: 3
            }, {
              type: "low-power",
              properties: this.propertyNames.lowPower,
              index: 3,
              number: 4
            }]
          }]
        }]
      };
    })
  });

  _exports.default = _default;
});