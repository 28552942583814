define("alliance-business-suite/components/machines/programs/pricing/primus-mc10-wx", ["exports", "alliance-business-suite/helpers/find-by-dropdown-with-custom", "alliance-business-suite/mixins/machines/programs/mc10-options", "alliance-business-suite/utils/bits"], function (_exports, _findByDropdownWithCustom, _mc10Options, _bits) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_mc10Options.default, {
    // eslint-disable-line ember/no-classic-classes
    tagName: '',
    intl: Ember.inject.service(),
    machines: Ember.inject.service(),
    machineSettings: Ember.computed(function () {
      return this.machines.getMachineSettings('primus-mc10-wx');
    }),

    //#region Computed properties
    didReceiveAttrs() {
      // Check if Coin1PriceSeparator property exists
      let newPropertyExists = Ember.get(this, 'machineProgram.settings.Program.Display.Coin1PriceSeparator') !== undefined; // Check if NumericSeparator property exists

      let oldPropertyExists = Ember.get(this, 'machineProgram.settings.Program.Display.NumericSeparator') !== undefined;

      if (!newPropertyExists && oldPropertyExists) {
        // Convert the old property to the new properties, then remove the old property
        Ember.set(this, 'machineProgram.settings.Program.Display.Coin1PriceSeparator', _bits.default.getLowNibble(this.machineProgram.settings.Program.Display.NumericSeparator));
        Ember.set(this, 'machineProgram.settings.Program.Display.Coin2PriceSeparator', _bits.default.getHighNibble(this.machineProgram.settings.Program.Display.NumericSeparator));
        Ember.set(this, 'machineProgram.settings.Program.Display.NumericSeparator', undefined);
      }

      this._super(...arguments);
    },

    //#endregion
    // Need to observe any properties used for enabled, etc.
    formConfig: Ember.computed(function () {
      return {
        columns: [{
          sections: [{
            title: "payment_options",
            controls: [{
              type: "checkbox",
              propertyName: "Program.CoinPulse.SerialPaymentSystemEnabled",
              label: "serial_payment_system",
              notMachine: "MC10_COMBO"
            }, {
              type: "currency",
              propertyName: "Program.CoinPulse.Coin1Value",
              min: 0,
              max: 65535,
              limit: true
            }, {
              type: "currency",
              propertyName: "Program.CoinPulse.Coin2Value",
              min: 0,
              max: 65535,
              limit: true
            }, {
              type: "number",
              propertyName: "Program.CoinPulse.Coin1TopoffMinutes",
              label: "Coin1TopOffMinutes",
              min: 0,
              max: 255,
              limit: true,
              ifMachine: "MC10_COMBO"
            }, {
              type: "number",
              propertyName: "Program.Price.Misc.KioskNumber",
              min: 1,
              max: 255,
              limit: true,
              notMachine: "MC10_COMBO"
            }, {
              type: "select",
              propertyName: "Program.Price.Misc.Currency1.Symbol",
              label: "money_1_symbol",
              options: "CurrencyType"
            }, {
              type: "select",
              propertyName: "Program.Price.Misc.Currency1.PlaceAfter",
              label: "money_1_symbol_placement"
            }, {
              type: "select",
              propertyName: "Program.Display.Coin1PriceSeparator",
              label: "money_1_format",
              options: "NumericSeparator"
            }, {
              type: "select",
              propertyName: "Program.Price.Misc.Currency2.Symbol",
              label: "money_2_symbol",
              options: "CurrencyType",
              notMachine: "MC10_COMBO"
            }, {
              type: "select",
              propertyName: "Program.Price.Misc.Currency2.PlaceAfter",
              label: "money_2_symbol_placement",
              notMachine: "MC10_COMBO"
            }, {
              type: "select",
              propertyName: "Program.Display.Coin2PriceSeparator",
              label: "money_2_format",
              options: "NumericSeparator",
              notMachine: "MC10_COMBO"
            }]
          }, {
            title: "payment_methods",
            controls: [{
              type: "coin-drop",
              properties: {
                Enabled: "Program.CoinPulse.CoinDropEnabled",
                SingleDrop: "Program.Price.Misc.PaymentMethods.SingleDrop",
                DualDrop: "Program.Price.Misc.PaymentMethods.DualDrop",
                WorldDrop: "Program.Price.Misc.PaymentMethods.WorldDrop"
              }
            }, {
              type: "checkbox",
              propertyName: "Program.Price.Misc.PaymentMethods.Card",
              label: "show_card",
              notMachine: "MC10_COMBO"
            }, {
              type: "checkbox",
              propertyName: "Program.Price.Misc.PaymentMethods.Bill",
              label: "show_bill",
              notMachine: "MC10_COMBO"
            }, {
              type: "checkbox",
              propertyName: "Program.Price.Misc.PaymentMethods.Mobile",
              label: "show_mobile_device"
            }, {
              type: "checkbox",
              propertyName: "Program.Price.Misc.PaymentMethods.Kiosk",
              label: "show_kiosk",
              notMachine: "MC10_COMBO"
            }]
          }]
        }, {
          sections: [{
            title: "cycles",
            controls: [{
              type: "currency",
              propertyName: "Program.Price.CyclePrices.CyclePrices.0",
              label: "cycle_wash_dry_normal",
              min: 0,
              max: 65535,
              limit: true,
              decimals: this.decimals,
              ifMachine: "MC10_COMBO"
            }, {
              type: "currency",
              propertyName: "Program.Price.CyclePrices.CyclePrices.1",
              label: "cycle_wash_dry_small",
              min: 0,
              max: 65535,
              limit: true,
              decimals: this.decimals,
              ifMachine: "MC10_COMBO"
            }, {
              type: "currency",
              propertyName: "Program.Price.CyclePrices.CyclePrices.2",
              label: "cycle_wash_only",
              min: 0,
              max: 65535,
              limit: true,
              decimals: this.decimals,
              ifMachine: "MC10_COMBO"
            }, {
              type: "currency",
              propertyName: "Program.Price.CyclePrices.CyclePrices.3",
              label: "cycle_dry_only",
              min: 0,
              max: 65535,
              limit: true,
              decimals: this.decimals,
              ifMachine: "MC10_COMBO"
            }, {
              type: "currency",
              propertyName: "Program.Price.CyclePrices.CyclePrices.4",
              label: "cycle_rinse_machine",
              min: 0,
              max: 65535,
              limit: true,
              decimals: this.decimals,
              ifMachine: "MC10_COMBO"
            }, {
              type: "currency",
              propertyName: "Program.Price.CyclePrices.CyclePrices.0",
              labelTranslated: `${this.intl.t("cycle")} 1`,
              min: 0,
              max: 65535,
              limit: true,
              decimals: this.decimals,
              notMachine: "MC10_COMBO"
            }, {
              type: "currency",
              propertyName: "Program.Price.CyclePrices.CyclePrices.1",
              labelTranslated: `${this.intl.t("cycle")} 2`,
              min: 0,
              max: 65535,
              limit: true,
              decimals: this.decimals,
              notMachine: "MC10_COMBO"
            }, {
              type: "currency",
              propertyName: "Program.Price.CyclePrices.CyclePrices.2",
              labelTranslated: `${this.intl.t("cycle")} 3`,
              min: 0,
              max: 65535,
              limit: true,
              decimals: this.decimals,
              notMachine: "MC10_COMBO"
            }, {
              type: "currency",
              propertyName: "Program.Price.CyclePrices.CyclePrices.3",
              labelTranslated: `${this.intl.t("cycle")} 4`,
              min: 0,
              max: 65535,
              limit: true,
              decimals: this.decimals,
              notMachine: "MC10_COMBO"
            }, {
              type: "currency",
              propertyName: "Program.Price.CyclePrices.CyclePrices.4",
              labelTranslated: `${this.intl.t("cycle")} 5`,
              min: 0,
              max: 65535,
              limit: true,
              decimals: this.decimals,
              notMachine: "MC10_COMBO"
            }, {
              type: "currency",
              propertyName: "Program.Price.CyclePrices.CyclePrices.5",
              labelTranslated: `${this.intl.t("cycle")} 6`,
              min: 0,
              max: 65535,
              limit: true,
              decimals: this.decimals,
              notMachine: "MC10_COMBO"
            }, {
              type: "currency",
              propertyName: "Program.Price.CyclePrices.CyclePrices.6",
              labelTranslated: `${this.intl.t("cycle")} 7`,
              min: 0,
              max: 65535,
              limit: true,
              decimals: this.decimals,
              notMachine: "MC10_COMBO"
            }, {
              type: "currency",
              propertyName: "Program.Price.CyclePrices.CyclePrices.7",
              labelTranslated: `${this.intl.t("cycle")} 8`,
              min: 0,
              max: 65535,
              limit: true,
              decimals: this.decimals,
              notMachine: "MC10_COMBO"
            }, {
              type: "currency",
              propertyName: "Program.Price.CyclePrices.CyclePrices.8",
              labelTranslated: `${this.intl.t("cycle")} 9`,
              min: 0,
              max: 65535,
              limit: true,
              decimals: this.decimals,
              notMachine: "MC10_COMBO"
            }, {
              type: "currency",
              propertyName: "Program.Price.CyclePrices.CyclePrices.9",
              labelTranslated: `${this.intl.t("cycle")} 10`,
              min: 0,
              max: 65535,
              limit: true,
              decimals: this.decimals,
              notMachine: "MC10_COMBO"
            }, {
              type: "currency",
              propertyName: "Program.Price.CyclePrices.CyclePrices.10",
              labelTranslated: `${this.intl.t("cycle")} 11`,
              min: 0,
              max: 65535,
              limit: true,
              decimals: this.decimals,
              notMachine: "MC10_COMBO"
            }, {
              type: "currency",
              propertyName: "Program.Price.CyclePrices.CyclePrices.11",
              labelTranslated: `${this.intl.t("cycle")} 12`,
              min: 0,
              max: 65535,
              limit: true,
              decimals: this.decimals,
              notMachine: "MC10_COMBO"
            }, {
              type: "currency",
              propertyName: "Program.Price.CyclePrices.CyclePrices.12",
              labelTranslated: `${this.intl.t("cycle")} 13`,
              min: 0,
              max: 65535,
              limit: true,
              decimals: this.decimals,
              notMachine: "MC10_COMBO"
            }, {
              type: "currency",
              propertyName: "Program.Price.CyclePrices.CyclePrices.13",
              labelTranslated: `${this.intl.t("cycle")} 14`,
              min: 0,
              max: 65535,
              limit: true,
              decimals: this.decimals,
              notMachine: "MC10_COMBO"
            }, {
              type: "currency",
              propertyName: "Program.Price.CyclePrices.CyclePrices.14",
              labelTranslated: `${this.intl.t("cycle")} 15`,
              min: 0,
              max: 65535,
              limit: true,
              decimals: this.decimals,
              notMachine: "MC10_COMBO"
            }]
          }, {
            title: "modifiers",
            notMachine: "MC10_COMBO",
            controls: [{
              type: "currency",
              propertyName: "Program.Price.ModifierPrices.Mod1Adder",
              min: 0,
              max: 65535,
              limit: true,
              decimals: this.decimals
            }, {
              type: "currency",
              propertyName: "Program.Price.ModifierPrices.Mod2Adder",
              min: 0,
              max: 65535,
              limit: true,
              decimals: this.decimals
            }, {
              type: "currency",
              propertyName: "Program.Price.ModifierPrices.ExtraTimeAdder",
              min: 0,
              max: 65535,
              limit: true,
              decimals: this.decimals
            }, // {
            //   type: "currency",
            //   propertyName: "Program.Price.ModifierPrices.WarmRinseAdder",
            //   min: 0,
            //   max: 65535,
            //   limit: true,
            //   decimals: this.decimals
            // },
            {
              type: "currency",
              propertyName: "Program.Price.ModifierPrices.IncreasedWaterLevelAdder",
              min: 0,
              max: 65535,
              limit: true,
              decimals: this.decimals
            }, {
              type: "currency",
              propertyName: "Program.Price.ModifierPrices.IncreasedSpinSpeedAdder",
              min: 0,
              max: 65535,
              limit: true,
              decimals: this.decimals
            }, // {
            //   type: "currency",
            //   propertyName: "Program.Price.ModifierPrices.OzoneAdder",
            //   min: 0,
            //   max: 65535,
            //   limit: true,
            //   decimals: this.decimals
            // },
            {
              type: "currency",
              propertyName: "Program.Price.ModifierPrices.SupplyInjectionAdder",
              min: 0,
              max: 65535,
              limit: true,
              decimals: this.decimals
            }, {
              type: "currency",
              propertyName: "Program.Price.ModifierPrices.MediumBundleAdder",
              label: "medium_soil",
              min: 0,
              max: 65535,
              limit: true,
              decimals: this.decimals
            }, {
              type: "currency",
              propertyName: "Program.Price.ModifierPrices.HeavyBundleAdder",
              label: "heavy_soil",
              min: 0,
              max: 65535,
              limit: true,
              decimals: this.decimals
            }, {
              type: "currency",
              propertyName: "Program.Price.ModifierPrices.WarmAdder",
              min: 0,
              max: 65535,
              limit: true,
              decimals: this.decimals
            }, {
              type: "currency",
              propertyName: "Program.Price.ModifierPrices.HotAdder",
              min: 0,
              max: 65535,
              limit: true,
              decimals: this.decimals
            }, {
              type: "currency",
              propertyName: "Program.Price.ModifierPrices.Temp15CAdder",
              label: this.isCelsius ? "Temp15C" : "Temp59F",
              min: 0,
              max: 65535,
              limit: true,
              decimals: this.decimals
            }, {
              type: "currency",
              propertyName: "Program.Price.ModifierPrices.Temp30CAdder",
              label: this.isCelsius ? "Temp30C" : "Temp86F",
              min: 0,
              max: 65535,
              limit: true,
              decimals: this.decimals
            }, {
              type: "currency",
              propertyName: "Program.Price.ModifierPrices.Temp40CAdder",
              label: this.isCelsius ? "Temp40C" : "Temp104F",
              min: 0,
              max: 65535,
              limit: true,
              decimals: this.decimals
            }, {
              type: "currency",
              propertyName: "Program.Price.ModifierPrices.Temp50CAdder",
              label: this.isCelsius ? "Temp50C" : "Temp122F",
              min: 0,
              max: 65535,
              limit: true,
              decimals: this.decimals
            }, {
              type: "currency",
              propertyName: "Program.Price.ModifierPrices.Temp60CAdder",
              label: this.isCelsius ? "Temp60C" : "Temp140F",
              min: 0,
              max: 65535,
              limit: true,
              decimals: this.decimals
            }, {
              type: "currency",
              propertyName: "Program.Price.ModifierPrices.Temp90CAdder",
              label: this.isCelsius ? "Temp90C" : "Temp194F",
              min: 0,
              max: 65535,
              limit: true,
              decimals: this.decimals
            }, {
              type: "currency",
              propertyName: "Program.Price.ModifierPrices.SpeedCycleAdder",
              min: -32768,
              max: 32767,
              limit: true,
              decimals: this.decimals
            }]
          }, {
            title: "dropoff",
            controls: [{
              type: "dropoff",
              properties: {
                Enabled: "Program.Misc.Dropoff.Enabled",
                Passkey: "Program.Misc.Dropoff.Passkey"
              }
            }]
          } // {
          //   title: "special_vend",
          //   ifMachine: "MC10_COMBO",
          //   controls: [
          //     {
          //       type: "special-vend-container",
          //       componentName: "machines/programs/pricing/special-vend-primus-mc10-wx",
          //       uniqueIndex: [0,1,2,3,4,5,6,7],
          //       properties: {
          //         Enabled: "SpecialVend.Items.${index}.DOWEn.Enabled",
          //         Friday: "SpecialVend.Items.${index}.DOWEn.Friday",
          //         Monday: "SpecialVend.Items.${index}.DOWEn.Monday",
          //         Sunday: "SpecialVend.Items.${index}.DOWEn.Sunday",
          //         Tuesday: "SpecialVend.Items.${index}.DOWEn.Tuesday",
          //         Saturday: "SpecialVend.Items.${index}.DOWEn.Saturday",
          //         Thursday: "SpecialVend.Items.${index}.DOWEn.Thursday",
          //         Wednesday: "SpecialVend.Items.${index}.DOWEn.Wednesday",
          //         EndDate: "SpecialVend.Items.${index}.EndDate",
          //         EndYear: "SpecialVend.Items.${index}.EndYear",
          //         Duration: "SpecialVend.Items.${index}.Duration",
          //         EndMonth: "SpecialVend.Items.${index}.EndMonth",
          //         StartDate: "SpecialVend.Items.${index}.StartDate",
          //         StartHour: "SpecialVend.Items.${index}.StartHour",
          //         StartYear: "SpecialVend.Items.${index}.StartYear",
          //         StartMonth: "SpecialVend.Items.${index}.StartMonth",
          //         CyclePrices: "SpecialVend.Items.${index}.CyclePrices",
          //         StartMinute: "SpecialVend.Items.${index}.StartMinute",
          //         HotAdder: "SpecialVend.Items.${index}.ModifierPrices.HotAdder",
          //         Mod1Adder: "SpecialVend.Items.${index}.ModifierPrices.Mod1Adder",
          //         Mod2Adder: "SpecialVend.Items.${index}.ModifierPrices.Mod2Adder",
          //         WarmAdder: "SpecialVend.Items.${index}.ModifierPrices.WarmAdder",
          //         OzoneAdder: "SpecialVend.Items.${index}.ModifierPrices.OzoneAdder",
          //         Temp15CAdder: "SpecialVend.Items.${index}.ModifierPrices.Temp15CAdder",
          //         Temp30CAdder: "SpecialVend.Items.${index}.ModifierPrices.Temp30CAdder",
          //         Temp40CAdder: "SpecialVend.Items.${index}.ModifierPrices.Temp40CAdder",
          //         Temp50CAdder: "SpecialVend.Items.${index}.ModifierPrices.Temp50CAdder",
          //         Temp60CAdder: "SpecialVend.Items.${index}.ModifierPrices.Temp60CAdder",
          //         Temp90CAdder: "SpecialVend.Items.${index}.ModifierPrices.Temp90CAdder",
          //         ExtraTimeAdder: "SpecialVend.Items.${index}.ModifierPrices.ExtraTimeAdder",
          //         WarmRinseAdder: "SpecialVend.Items.${index}.ModifierPrices.WarmRinseAdder",
          //         SpeedCycleAdder: "SpecialVend.Items.${index}.ModifierPrices.SpeedCycleAdder",
          //         HeavyBundleAdder: "SpecialVend.Items.${index}.ModifierPrices.HeavyBundleAdder",
          //         MediumBundleAdder: "SpecialVend.Items.${index}.ModifierPrices.MediumBundleAdder",
          //         SupplyInjectionAdder: "SpecialVend.Items.${index}.ModifierPrices.SupplyInjectionAdder",
          //         IncreasedSpinSpeedAdder: "SpecialVend.Items.${index}.ModifierPrices.IncreasedSpinSpeedAdder",
          //         IncreasedWaterLevelAdder: "SpecialVend.Items.${index}.ModifierPrices.IncreasedWaterLevelAdder",
          //         Mod1: "SpecialVend.Items.${index}.DefaultModifiers.Mod1",
          //         Mod2: "SpecialVend.Items.${index}.DefaultModifiers.Mod2",
          //         Ozone: "SpecialVend.Items.${index}.DefaultModifiers.Ozone",
          //         ExtraTime: "SpecialVend.Items.${index}.DefaultModifiers.ExtraTime",
          //         SpeedCycle: "SpecialVend.Items.${index}.DefaultModifiers.SpeedCycle",
          //         HeavyBundle: "SpecialVend.Items.${index}.DefaultModifiers.HeavyBundle",
          //         DispenseSoap: "SpecialVend.Items.${index}.DefaultModifiers.DispenseSoap",
          //         MediumBundle: "SpecialVend.Items.${index}.DefaultModifiers.MediumBundle",
          //         WarmFinalRinse: "SpecialVend.Items.${index}.DefaultModifiers.WarmFinalRinse",
          //         IncreasedSpinSpeed: "SpecialVend.Items.${index}.DefaultModifiers.IncreasedSpinSpeed",
          //         IncreasedWaterLevel: "SpecialVend.Items.${index}.DefaultModifiers.IncreasedWaterLevel",
          //         ExtractSpeedLimit: "SpecialVend.Items.${index}.ExtractSpeedLimit",
          //         HeavyMod1: "SpecialVend.Items.${index}.HeavyBundleOptions.Mod1",
          //         HeavyMod2: "SpecialVend.Items.${index}.HeavyBundleOptions.Mod2",
          //         HeavyExtraTime: "SpecialVend.Items.${index}.HeavyBundleOptions.ExtraTime",
          //         HeavySpinSpeed: "SpecialVend.Items.${index}.HeavyBundleOptions.SpinSpeed",
          //         HeavyWarmRinse: "SpecialVend.Items.${index}.HeavyBundleOptions.WarmRinse",
          //         HeavyWaterLevel: "SpecialVend.Items.${index}.HeavyBundleOptions.WaterLevel",
          //         MediumMod1: "SpecialVend.Items.${index}.MediumBundleOptions.Mod1",
          //         MediumMod2: "SpecialVend.Items.${index}.MediumBundleOptions.Mod2",
          //         MediumExtraTime: "SpecialVend.Items.${index}.MediumBundleOptions.ExtraTime",
          //         MediumSpinSpeed: "SpecialVend.Items.${index}.MediumBundleOptions.SpinSpeed",
          //         MediumWarmRinse: "SpecialVend.Items.${index}.MediumBundleOptions.WarmRinse",
          //         MediumWaterLevel: "SpecialVend.Items.${index}.MediumBundleOptions.WaterLevel",
          //         OptionsMod1: "SpecialVend.Items.${index}.ModsAdditionalOptsEnabled.Mod1",
          //         OptionsMod2: "SpecialVend.Items.${index}.ModsAdditionalOptsEnabled.Mod2",
          //         OptionsOzone: "SpecialVend.Items.${index}.ModsAdditionalOptsEnabled.Ozone",
          //         OptionsExtraTime: "SpecialVend.Items.${index}.ModsAdditionalOptsEnabled.ExtraTime",
          //         OptionsSpeedCycle: "SpecialVend.Items.${index}.ModsAdditionalOptsEnabled.SpeedCycle",
          //         OptionsHeavyBundle: "SpecialVend.Items.${index}.ModsAdditionalOptsEnabled.HeavyBundle",
          //         OptionsDispenseSoap: "SpecialVend.Items.${index}.ModsAdditionalOptsEnabled.DispenseSoap",
          //         OptionsMediumBundle: "SpecialVend.Items.${index}.ModsAdditionalOptsEnabled.MediumBundle",
          //         OptionsWarmFinalRinse: "SpecialVend.Items.${index}.ModsAdditionalOptsEnabled.WarmFinalRinse",
          //         OptionsIncreasedSpinSpeed: "SpecialVend.Items.${index}.ModsAdditionalOptsEnabled.IncreasedSpinSpeed",
          //         OptionsIncreasedWaterLevel: "SpecialVend.Items.${index}.ModsAdditionalOptsEnabled.IncreasedWaterLevel",
          //         WashAgitateMinutesAdder: "SpecialVend.Items.${index}.WashAgitateMinutesAdder",
          //         WashAgitateMinuteSubtracter: "SpecialVend.Items.${index}.WashAgitateMinuteSubtracter",
          //       },
          //     }
          //   ]
          // }
          ]
        }]
      };
    })
  });

  _exports.default = _default;
});