define("alliance-business-suite/controllers/crm/campaigns/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    access: Ember.inject.service(),
    ajax: Ember.inject.service(),

    init() {
      this._super(...arguments);
    },

    actions: {
      toggleDeleteConfirmModal() {
        this.toggleProperty('showDeleteConfirmModal');
      },

      deleteCampaign() {
        if (this.access.isDemo) {
          alert('Demo mode restricted.');
          return;
        }

        let campaign = this.model;
        this.set('isDeleting', true);
        campaign.destroyRecord().then(() => {
          this.router.transitionTo('crm.campaigns');
          this.set('isDeleting', false);
          this.set('showDeleteConfirmModal', false);
        }, () => {
          this.set('isDeleting', false);
          this.set('showDeleteConfirmModal', false);
        });
      },

      saveCampaign() {
        if (this.access.isDemo) {
          alert('Demo mode restricted.');
          return;
        }

        let campaign = this.model;
        let campaignId = this.get('model.id');
        let promo = this.promo;
        let deliveryMessages;
        let rooms = [];
        let filter = campaign.get('filter');
        let deliveryMethodLookupKey = campaign.get('deliveryMethodLookupKeys');
        let name = campaign.get('name');
        let campaignTypeLookupKey = campaign.get('campaignTypeLookupKey');
        let organizationId = this.get('access.accessOrganization.id');
        let deliveryMethodMessages = [];
        let shouldAlert = false;

        if (!campaign.get('name')) {
          alert('Please enter a name for this campaign');
          return;
        } else if (!campaign.get('rooms.length')) {
          alert('Please select a location for this campaign');
          return;
        } else if (campaign.get('campaignTypeLookupKey') == 'REENGAGEMENT' && (!campaign.get('filter') || !JSON.parse(campaign.get('filter')).Params.length)) {
          alert('Please select a filter for this campaign');
          return;
        } else if (campaign.get('campaignTypeLookupKey') == 'REENGAGEMENT' && deliveryMethodLookupKey.length === 0) {
          alert('Please select a message for this campaign');
          return;
        } else if (JSON.parse(filter).Params.length) {
          let filterParams = JSON.parse(filter).Params;
          filterParams.forEach(function (filterParam) {
            if (filterParam.Name === "LAST_VISIT_END_DATE") {
              if (filterParam.Value === "Invalid date") {
                shouldAlert = true;
              }
            }

            if (filterParam.Name === "LAST_VISIT_START_DATE") {
              if (filterParam.Value === "Invalid date") {
                shouldAlert = true;
              }
            }

            if (filterParam.Name === "LAST_VISIT_INTERVAL") {
              if (filterParam.Value === "custom") {
                shouldAlert = true;
              }
            }
          });

          if (shouldAlert) {
            alert('Please select a valid date');
            return;
          }
        } else if (campaign.get('campaignTypeLookupKey') == 'REENGAGEMENT' && deliveryMethodLookupKey.length > 0) {
          this.set('isEmpty', false);
          deliveryMethodLookupKey.forEach(MethodLookupKey => {
            if (MethodLookupKey === 'SMS' && campaign.get('deliveryMethodBodyTextSMS') === undefined) {
              this.set('isEmpty', true);
            }

            if (MethodLookupKey === 'EMAIL_HTML' && campaign.get('deliveryMethodBodyTextHtml') === undefined) {
              this.set('isEmpty', true);
            }

            if (MethodLookupKey === 'IN_APP_NOTIFICATION' && campaign.get('deliveryMethodBodyTextInApp') === undefined) {
              this.set('isEmpty', true);
            }

            if (MethodLookupKey === 'EMAIL_TEXT' && campaign.get('deliveryMethodBodyTextPlain') === undefined) {
              this.set('isEmpty', true);
            }
          });

          if (this.isEmpty === true) {
            alert('Please fill out a message for this campaign');
            return;
          }
        }

        this.set('isWorking', true);

        if (campaign.get('rooms.length')) {
          deliveryMessages = campaign.get('rooms');
          deliveryMessages.forEach(function (deliveryMessage) {
            rooms.push({
              'id': deliveryMessage.get('id')
            });
          });
        }

        if (campaignTypeLookupKey === 'REENGAGEMENT') {
          deliveryMethodLookupKey.forEach((MethodLookupKey, index) => {
            if (MethodLookupKey === "SMS") {
              deliveryMethodMessages.push({
                "id": 1,
                "deliveryMethodTypeLookupKey": MethodLookupKey,
                "deliveryMethodBodyText": `${campaign.get('deliveryMethodBodyTextSMS')}`
              });
            }

            if (MethodLookupKey === "EMAIL_HTML") {
              deliveryMethodMessages.push({
                "id": 2,
                "deliveryMethodTypeLookupKey": MethodLookupKey,
                "deliveryMethodBodyText": `${campaign.get('deliveryMethodBodyTextHtml')}`,
                "deliveryMethodFromName": `${campaign.get('deliveryMethodFromNameHtml')}`,
                "deliveryMethodFromEmail": `${campaign.get('deliveryMethodFromEmailHtml')}`,
                "deliveryMethodReplyEmail": `${campaign.get('deliveryMethodReplyEmailHtml')}`,
                "deliveryMethodSubjectText": `${campaign.get('deliveryMethodSubjectTextHtml')}`
              });
            }

            if (MethodLookupKey === "IN_APP_NOTIFICATION") {
              deliveryMethodMessages.push({
                "id": 3,
                "deliveryMethodTypeLookupKey": MethodLookupKey,
                "deliveryMethodBodyText": `${campaign.get('deliveryMethodBodyTextInApp')}`
              });
            }

            if (MethodLookupKey === "EMAIL_TEXT") {
              deliveryMethodMessages.push({
                "id": 4,
                "deliveryMethodTypeLookupKey": MethodLookupKey,
                "deliveryMethodBodyText": `${campaign.get('deliveryMethodBodyTextPlain')}`,
                "deliveryMethodFromName": `${campaign.get('deliveryMethodFromNameText')}`,
                "deliveryMethodFromEmail": `${campaign.get('deliveryMethodFromEmailText')}`,
                "deliveryMethodReplyEmail": `${campaign.get('deliveryMethodReplyEmailText')}`,
                "deliveryMethodSubjectText": `${campaign.get('deliveryMethodSubjectTextPlain')}`
              });
            }

            if (MethodLookupKey === "NONE") {
              deliveryMethodMessages.push({
                "id": 5,
                "deliveryMethodTypeLookupKey": MethodLookupKey
              });
            }
          });
        } else {
          deliveryMethodLookupKey = ["NONE"];
          deliveryMethodMessages = {
            "id": 5,
            "deliveryMethodTypeLookupKey": "NONE"
          };
        }

        if (promo && promo.get('hasDirtyAttributes')) {
          promo.save({
            adapterOptions: {
              campaignId
            }
          }).then(() => {
            this.ajax.request(`/organizations/${organizationId}/campaigns?campaignId=${campaignId}`, {
              data: {
                name,
                rooms,
                filter,
                deliveryMethodLookupKey,
                deliveryMethodMessages,
                campaignTypeLookupKey,
                raw: true
              },
              method: 'PATCH'
            }).then(response => {
              this.set('hasUnsavedChanges', false);
              this.router.transitionTo('crm.campaigns');
              this.set('isWorking', false);
            }, () => {
              alert('Failed to save campaign');
              this.set('isWorking', false);
            });
          }, () => {
            alert('Failed to save promotion');
            this.set('isWorking', false);
          });
        } else {
          this.ajax.request(`/organizations/${organizationId}/campaigns?campaignId=${campaignId}`, {
            data: {
              name,
              rooms,
              filter,
              deliveryMethodLookupKey,
              deliveryMethodMessages,
              campaignTypeLookupKey,
              raw: true
            },
            method: 'PATCH'
          }).then(response => {
            this.set('hasUnsavedChanges', false);
            this.router.transitionTo('crm.campaigns');
            this.set('isWorking', false);
          }, function () {});
        }
      },

      hasUnsavedChanges(value) {
        this.set('hasUnsavedChanges', value);
      }

    }
  });

  _exports.default = _default;
});