define("alliance-business-suite/utils/y2k", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fixYear = _exports.fixMonth = _exports.fixDate = void 0;

  const fixYear = function (year) {
    if (year == 0) {
      year = 1900;
    }

    if (year == 2000) {
      year = 1900; // This unfortunate hack is required because there are places where the year gets set to 2000 instead of 1900.
    }

    if (year < 100) {
      year += 2000;
    }

    return year;
  };

  _exports.fixYear = fixYear;

  const fixMonth = function (month) {
    if (month > 0) {
      month--; // We are working with the month property, which is Jan == 1. Needs to be JavaScript Jan == 0.
    }

    return month;
  };

  _exports.fixMonth = fixMonth;

  const fixDate = function (day) {
    if (day == 0) {
      day = 1;
    }

    return day;
  };

  _exports.fixDate = fixDate;
});