define("alliance-business-suite/components/form-ui/duration-field/utils/state-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    initialState: null,
    showHours: true,
    currentState: Ember.computed({
      get() {
        let name = this.initialState;
        return this.get(name);
      },

      set(_key, val) {
        return val;
      }

    }),

    transitionTo(path) {
      let current = this.currentState;

      while (current) {
        let nextState = current.get(path);

        if (nextState) {
          allBetween(current, nextState).forEach(state => state.enter && state.enter(this));
          this.set("currentState", nextState);
          return;
        }

        current = current.get('parent');
      }

      throw new Error(`Can't transition to ${path}`);
    },

    send(name) {
      let state = this.currentState;

      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key2 = 1; _key2 < _len; _key2++) {
        args[_key2 - 1] = arguments[_key2];
      }

      return state.send(name, ...args);
    }

  });

  _exports.default = _default;

  function allBetween(start, end) {
    let parent = end.get('parent');
    let intermediate = [end];

    while (parent) {
      intermediate.push(parent);
      parent = parent.get('parent');

      if (parent === start) {
        break;
      }
    }

    return intermediate;
  }
});