define("alliance-business-suite/components/machines/programs/pricing/special-vend-acas", ["exports", "alliance-business-suite/utils/number-to-hour", "alliance-business-suite/mixins/init-property-names", "alliance-business-suite/mixins/modals", "alliance-business-suite/mixins/machines/programs/acas-options"], function (_exports, _numberToHour, _initPropertyNames, _modals, _acasOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // This is used in other files. Needs to be DRY.
  function formatTime(minutes, seconds) {
    if (!Ember.isNone(minutes) && !Ember.isNone(seconds)) {
      return `${minutes}:${seconds.toString().padStart(2, 0)}`;
    } else {
      return '';
    }
  }

  var _default = Ember.Component.extend(_initPropertyNames.default, _modals.default, _acasOptions.default, {
    // Start: Added for MGD_M1
    modifier1Name: Ember.computed("machineProgram.settings.Program.Price.ModifierName", function () {
      return this.machineProgram.settings.Program.Price.ModifierName === 0 ? "medium" : "deluxe";
    }),
    modifier2Name: Ember.computed("machineProgram.settings.Program.Price.ModifierName", function () {
      return this.machineProgram.settings.Program.Price.ModifierName === 0 ? "heavy" : "ultra";
    }),
    optionsModifiers: Ember.computed('machineProgram.settings.Program.Price.ModifierName', function () {
      if (this.machineProgram.settings.Program.Price.ModifierName === 0) {
        return [{
          val: 0,
          label: 'light'
        }, {
          val: 1,
          label: 'medium'
        }, {
          val: 2,
          label: 'heavy'
        }];
      } else {
        return [{
          val: 0,
          label: 'Regular'
        }, // Lokalise has this twice: lowercase and title.
        {
          val: 1,
          label: 'deluxe'
        }, {
          val: 2,
          label: 'ultra'
        }];
      }
    }),
    // End: Added for MGD_M1
    intl: Ember.inject.service(),
    machineAuditType: Ember.computed.alias('machineProgram._machineAuditType'),
    machineSpeeds: Ember.computed.alias('machineProgram.machineType.machineSpeeds'),
    useG: Ember.computed('machineProgram._showG', 'basketDiameter', function () {
      if (this.basketDiameter === 0) {
        return true; // Always show g-force when no basket size
      }

      return this.machineProgram._showG;
    }),
    weekdays: Ember.computed('DayOfWeek', 'Saturday', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', function () {
      // Convert bitfield to object
      if (this.DayOfWeek !== undefined) {
        return {
          sun: (this.DayOfWeek & 2) === 2,
          mon: (this.DayOfWeek & 4) === 4,
          tue: (this.DayOfWeek & 8) === 8,
          wed: (this.DayOfWeek & 16) === 16,
          thu: (this.DayOfWeek & 32) === 32,
          fri: (this.DayOfWeek & 64) === 64,
          sat: (this.DayOfWeek & 128) === 128
        };
      } else {
        return {
          sun: this.Sunday,
          mon: this.Monday,
          tue: this.Tuesday,
          wed: this.Wednesday,
          thu: this.Thursday,
          fri: this.Friday,
          sat: this.Saturday
        };
      }
    }),
    summaryText: Ember.computed('Enabled', 'DayOfWeek', 'Saturday', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'StartMonth', 'StartDate', 'StartYear', 'EndMonth', 'EndDate', 'EndYear', 'StartHour', 'StartMinute', 'Duration', 'HeatMinutes', 'HeatSeconds', 'NoHeatMinutes', 'NoHeatSeconds', 'Coin1TopOffMinutes', 'Coin1TopOffSeconds', 'Coin2TopOffMinutes', 'Coin2TopOffSeconds', 'HeatPrice', 'NoHeatPrice', function () {
      let output = '';
      let intl = this.intl;

      if (!this.Enabled || this.Enabled === false) {
        return 'Off';
      } // What is the type


      if (this.LowPowerEnabled && this.AutoShutdownEnabled) {
        output += 'Low Power & Auto Shutdown - ';
      } else if (this.LowPowerEnabled) {
        output += 'Low Power - ';
      } else if (this.AutoShutdownEnabled) {
        output += 'Auto Shutdown - ';
      } else {
        output += 'On - ';
      } // When: Days (dates) (hours)
      // Every Day; Weekends; Weekdays; Mon-Wed, Fri


      let daysText = "";
      let weekdays = this.weekdays;

      if (weekdays.sat && weekdays.sun && weekdays.mon && weekdays.tue && weekdays.wed && weekdays.thu && weekdays.fri) {
        daysText = 'Every Day, ';
        console.log('EVERY DAY');
      } else {
        // Weekends
        if (weekdays.sat && weekdays.sun) {
          daysText += ' Weekends, ';
        } else if (weekdays.sat) {
          daysText += 'Sat, ';
        } else if (weekdays.sun) {
          daysText += 'Sun, ';
        } // Weekdays


        if (weekdays.mon && weekdays.tue && weekdays.wed && weekdays.thu && weekdays.fri) {
          daysText += 'Weekdays, ';
        } else {
          if (weekdays.mon) {
            daysText += 'Mon, ';
          }

          if (weekdays.tue) {
            daysText += 'Tue, ';
          }

          if (weekdays.wed) {
            daysText += 'Wed, ';
          }

          if (weekdays.thu) {
            daysText += 'Thu, ';
          }

          if (weekdays.fri) {
            daysText += 'Fri, ';
          }
        }
      }

      if (daysText) {
        output += daysText;
      } else {
        output += `${intl.t("no_days_selected")}, `;
      } // Dates - this has some logic. If the day, month, or year has a value of 0, date limit is not enabled. If start = 1/1/2000 and end = 12/31/2099, also has no limit.


      if (this.StartMonth == 0 || this.StartDate == 0 || this.EndMonth == 0 || this.EndDate == 0 || this.StartMonth == 1 && this.StartDate == 1 && this.StartYear == 0 && this.EndMonth == 12 && this.EndDate == 31 && this.EndYear == 99) {// No date limits
      } else {
        output += `(${this.StartMonth}/${this.StartDate}/${this.StartYear}&ndash;${this.EndMonth}/${this.EndDate}/${this.EndYear})  `;
      } // Hours - this seems to have some logic. If duration is 0, not enabled.


      if (this.Duration > 0) {
        // We'll need to figure out the end time
        let startHour = (0, _numberToHour.default)(this.StartHour);
        let endHour = (0, _numberToHour.default)(Number(this.StartHour) + Number(this.Duration));
        output += `(${startHour}&ndash;${endHour}), `;
      }

      return output.length > 2 ? Ember.String.htmlSafe(output.substr(0, output.length - 2)) : output;
    })
  });

  _exports.default = _default;
});