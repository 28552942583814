define("alliance-business-suite/components/products/sortable-products", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    access: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    setInterval: null,

    didInsertElement() {
      this._super(...arguments);

      let self = this;
      (0, _jquery.default)("#sortable-inventory").sortable({
        containment: "parent"
      });
      (0, _jquery.default)("#sortable-inventory").disableSelection();
      let interval = setInterval(() => {
        this.toggleProperty('openHand');
      }, 700);
      this.set('setInterval', interval);
    },

    willDestroyElement() {
      clearInterval(this.setInterval);

      this._super(...arguments);
    },

    sortedList: Ember.computed('model.items', function () {
      let og = this.get('model.items');
      let list = [];
      og.forEach(i => {
        list.push(i);
      });
      let sortedList = list.sort((a, b) => {
        let ordinalA = a.get('ordinal') ? a.get('ordinal') : 0;
        let ordinalB = b.get('ordinal') ? b.get('ordinal') : 0;

        if (ordinalA > ordinalB) {
          return 1;
        } else if (ordinalA < ordinalB) {
          return -1;
        }

        return 0;
      });
      return sortedList;
    }),
    actions: {
      save() {
        let self = this;
        let notifications = this.notifications;
        let oridinalArray = (0, _jquery.default)("#sortable-inventory").sortable("toArray");
        this.set('isLoading', true);
        this.ajax.request('/items/batchUpdate', {
          method: 'PATCH',
          data: {
            'itemList': oridinalArray
          }
        }).then(response => {
          notifications.success(this.intl.t('sort_save_success'), {
            clearDuration: 5000,
            autoClear: true
          }); // because the PATCH doesnt return anything but a 200, the ember data is out of date
          // we fake it in the data store until the user refreshes the endpoints

          oridinalArray.forEach((s, index) => {
            let service = self.get('store').peekRecord('item', s);
            service.set('ordinal', index + 1);
          });
          this.set('isLoading', false);
          this.router.transitionTo('retail.list');
        }).catch(() => {
          alert('Error saving');
          this.set('isLoading', false);
        });
      }

    }
  });

  _exports.default = _default;
});