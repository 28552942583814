define("alliance-business-suite/components/login-section", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "alliance-business-suite/validators/login-section"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _loginSection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  let LoginSectionComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._action, (_class = class LoginSectionComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "validations", _loginSection.default);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "notifications", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "session", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "isLoading", _descriptor4, this);
      (0, _defineProperty2.default)(this, "user", {
        identification: null,
        password: null
      });
    }

    async authenticate(changeset, event) {
      event.preventDefault(); // To get the property values, we must use the changeset's get method

      let identification = changeset.get('identification');
      let password = changeset.get('password');
      this.isLoading = true;

      try {
        await this.session.authenticate('authenticator:token', {
          identification,
          password
        });
      } catch (reason) {
        this.isLoading = false;
        this.notifications.error(this.intl.t('error_sign_in'), {
          autoClear: true,
          clearDuration: 10000,
          cssClasses: 'error-white-notification'
        });
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "notifications", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "session", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isLoading", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "authenticate", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "authenticate"), _class.prototype)), _class));
  _exports.default = LoginSectionComponent;
});