define("alliance-business-suite/routes/login", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  let LoginRoute = (_dec = Ember.inject.service, (_class = class LoginRoute extends Ember.Route {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "session", _descriptor, this);
    }

    beforeModel(transition) {
      // fix for loading issue that takes user to white page on auth
      // when coming from /login instead of / when unauthenticated
      if (!transition.from) {
        this.session.requireAuthentication(transition, 'start');
      }

      this.session.prohibitAuthentication('start');
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = LoginRoute;
});