define("alliance-business-suite/transitions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    // BEGIN-SNIPPET animated-transitions
    this.transition(this.hasClass('liquid-dialog-container'), this.use('fade'));
    this.transition(this.hasClass('modal-liquid-grow'), // this makes our rule apply when the liquid-if transitions to the
    // true state.
    this.toValue(true), this.use('toRight'), // which means we can also apply a reverse rule for transitions to
    // the false state.
    this.reverse('toLeft'));
    this.transition(this.childOf('#liquid-bind-s-title'), this.use('toDown'));
    this.transition(this.childOf('#liquid-bind-wa-item-info'), this.use('toDown'));
    this.transition(this.fromRoute('login.index'), this.toRoute('login.forgot-password'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('login.index'), this.toRoute('login.reset-password'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('login'), this.toRoute('organizations'), this.use('toLeft'), this.reverse('toRight'));
  }
});