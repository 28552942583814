define("alliance-business-suite/adapters/shift", ["exports", "alliance-business-suite/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    access: Ember.inject.service(),

    buildURL(modelName, id, snapshot, requestType, query) {
      let host = this.host;

      let url = this._super(modelName, id, snapshot, requestType, query);

      let roomId = null;

      if (snapshot && snapshot.adapterOptions && snapshot.adapterOptions.roomId) {
        roomId = snapshot.adapterOptions.roomId;
      } else if (query && query.adapterOptions && query.adapterOptions.roomId) {
        roomId = query.adapterOptions.roomId;
      }

      switch (requestType) {
        case 'findAll':
          url = `${host}/rooms/${roomId}/shifts?startDate=${snapshot.adapterOptions.startDate}&endDate=${snapshot.adapterOptions.endDate}`;
          break;

        case 'createRecord':
          url = `${host}/rooms/${roomId}/shifts`;
          break;

        case 'updateRecord':
        case 'deleteRecord':
          url = `${host}/shifts/${id}`;
          break;

        case 'query':
          url = `${host}/rooms/${roomId}/shifts?startDate=${query.filter.startDate}&endDate=${query.filter.endDate}`;
          break;
      }

      return url;
    }

  });

  _exports.default = _default;
});