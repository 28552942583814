define("alliance-business-suite/templates/components/ui/context-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "V25cVHgh",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"ui-dropdown ui-hover-only\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"dropdown-container\"],[12],[2,\"\\n\"],[6,[37,3],[[35,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"button\"],[15,\"title\",[31,[[34,1]]]],[14,0,\"primary dropdown-trigger\"],[12],[2,\"\\n        \"],[10,\"i\"],[15,0,[31,[\"fa \",[34,0]]]],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"i\"],[15,0,[31,[\"fa \",[34,0],\" btn-more-actions dropdown-trigger\"]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[10,\"ul\"],[14,0,\"dropdown-menu dropdown-menu-left _with-arrow _dropdown-select\"],[12],[2,\"\\n\\n      \"],[18,1,[[30,[36,9],null,[[\"separator\",\"item\"],[[30,[36,8],[\"ui/context-menu-separator\"],[[\"label\"],[[35,7]]]],[30,[36,8],[\"ui/context-menu-item\"],[[\"label\",\"action\",\"disabled\",\"checked\"],[[35,7],[35,6],[35,5],[35,4]]]]]]]]],[2,\"\\n\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"iconName\",\"title\",\"showButton\",\"if\",\"checked\",\"disabled\",\"action\",\"label\",\"component\",\"hash\"]}",
    "moduleName": "alliance-business-suite/templates/components/ui/context-menu.hbs"
  });

  _exports.default = _default;
});