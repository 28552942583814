define("alliance-business-suite/components/customers/orders/orders-list-table", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    brush: Ember.inject.service(),
    classNames: ['alert-list-table'],
    tagName: '',
    filteredOrders: Ember.computed('orders', function () {
      let orders = this.orders;
      return orders;
    }),
    actions: {}
  });

  _exports.default = _default;
});