define("alliance-business-suite/models/geo-boundary", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    hasMany,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    fundsRestriction: attr('boolean'),
    description: attr('string'),
    lookupKey: attr('string'),
    depth: attr('number'),
    geoBoundaryType: belongsTo('geoBoundaryType'),
    organization: belongsTo('organization'),
    rooms: hasMany('room'),
    priceAdjustments: hasMany('priceAdjustment'),
    geoBoundaries: hasMany('geoBoundary', {
      inverse: null
    })
  });

  _exports.default = _default;
});