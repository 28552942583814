define("alliance-business-suite/templates/components/ui/cell-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qn4UzSTn",
    "block": "{\"symbols\":[\"@topLeft\",\"@topRight\",\"@topRightAction\",\"@bottomLeft\",\"@bottomRight\",\"&default\"],\"statements\":[[11,\"div\"],[24,0,\"cell-data clickable\"],[4,[38,0],[\"click\",[32,0,[\"onClick\"]]],null],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"top\"],[12],[2,\"\\n    \"],[10,\"span\"],[15,0,[31,[[32,0,[\"topLeftClass\"]]]]],[12],[1,[32,1]],[13],[2,\"\\n    \"],[10,\"span\"],[15,0,[31,[[32,0,[\"topRightClass\"]]]]],[12],[2,\"\\n      \"],[1,[32,2]],[2,\"\\n      \"],[10,\"span\"],[14,0,\"secondary-link\"],[12],[1,[32,3]],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"bottom\"],[12],[2,\"\\n    \"],[10,\"span\"],[15,0,[31,[[32,0,[\"bottomLeftClass\"]]]]],[12],[1,[32,4]],[13],[2,\"\\n    \"],[10,\"span\"],[15,0,[31,[[32,0,[\"bottomRightClass\"]]]]],[12],[1,[32,5]],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[18,6,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"on\"]}",
    "moduleName": "alliance-business-suite/templates/components/ui/cell-data.hbs"
  });

  _exports.default = _default;
});