define("alliance-business-suite/utils/summary-util", ["exports", "alliance-business-suite/utils/demo-mode"], function (_exports, _demoMode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.calcChartsAvailable = calcChartsAvailable;
  _exports.controlsScroll = controlsScroll;
  _exports.fetchAuditTurnsKPI = fetchAuditTurnsKPI;
  _exports.fetchStoreRevKPI = fetchStoreRevKPI;
  _exports.getRandomColor = getRandomColor;
  _exports.groupRoomKPI = groupRoomKPI;
  _exports.kpiObserverGenerator = kpiObserverGenerator;
  _exports.machineNameDisplay = machineNameDisplay;
  _exports.momentLocaleMap = void 0;
  _exports.numberWithCommas = numberWithCommas;
  _exports.resetFixedControls = resetFixedControls;
  _exports.saveRevKPI = saveRevKPI;
  _exports.saveTurnsKPI = saveTurnsKPI;
  _exports.scrollEvent = scrollEvent;
  _exports.updateRoomKPI = updateRoomKPI;
  const momentLocaleMap = {
    'cs-cz': 'cs',
    'de': 'de',
    'en-us': 'en',
    'es': 'es',
    'es-co': 'es-co',
    'fr': 'fr',
    'id': 'id',
    'ita': 'it',
    'jpn': 'ja',
    'ko': 'ko',
    'ms': 'ms-my',
    'nl-nl': 'nl',
    'pt': 'pt',
    'ru': 'ru',
    'th': 'th',
    'zh-cn': 'zh-cn'
  }; // when scrolling, fix the controls to the top of the page

  _exports.momentLocaleMap = momentLocaleMap;

  function controlsScroll() {
    let distanceForFixed = 112;
    let scrollableArea = document.querySelector('.summary .main .overflow-container');
    let statsElem = document.querySelector('.summary .main .overflow-container .summary-stats');
    let reportsControl = document.getElementById('summary-controls');
    let reportsHeight;

    if (reportsControl) {
      reportsHeight = reportsControl.offsetHeight;
    }

    if (scrollableArea) {
      this.scrollEventRef = scrollEvent;
      scrollableArea.addEventListener("scroll", scrollEvent.bind({
        distanceForFixed,
        scrollableArea,
        statsElem,
        reportsControl,
        reportsHeight
      }), {
        passive: true
      });
    }
  }

  function scrollEvent() {
    if (this.scrollableArea.scrollTop > this.distanceForFixed) {
      this.scrollableArea.classList.add('fix-search');
      this.reportsControl.style.top = `${this.scrollableArea.scrollTop}px`;
      this.statsElem.style.marginTop = `${this.reportsHeight}px`;
    } else {
      this.scrollableArea.classList.remove('fix-search');
      this.reportsControl.style.top = 0;
      this.statsElem.style.marginTop = 0;
    }
  }

  function resetFixedControls() {
    let scrollableArea = document.querySelector('.summary .main .overflow-container');
    let reportsControl = document.querySelector('#summary-controls');
    let statsElem = document.querySelector('.summary .main .overflow-container .summary-stats');

    if (scrollableArea) {
      scrollableArea.classList.remove('fix-search');
      scrollableArea.scrollTop = 0;
    }

    if (reportsControl) {
      reportsControl.style.top = 0;
    }

    if (statsElem) {
      statsElem.style.marginTop = 0;
    }
  } // determines how many charts should be shown
  // updates this.chartCount


  function calcChartsAvailable(charts) {
    let count = 0;
    charts.forEach(chart => {
      if (this.canAccess.canAccessAny(chart)) {
        count++;
      }
    });

    if (count == 0) {
      this.chartsLoaded = true;
    }

    this.chartCount = count;
  }

  function fetchAuditTurnsKPI(orgId, rooms) {
    return this.ajax.request(`/reports/AUDIT_TURNS/kpi?organizationId=${orgId}&raw=true`).then(response => {
      if (response[0] && !this.isDestroyed) {
        let roomIdArray = rooms.map(room => room);

        if (!response[0].organization) {
          response[0] = {
            organization: {
              kpi: 0
            }
          };
        }

        if (roomIdArray.length <= 0) {
          this.lastSavedKpis.revKPI = response[0].organization.kpi;
          this.turnsKPI = response[0].organization.kpi;
          this.groupTurnsKPI = Number(0);
        }

        if (roomIdArray.length >= 1) {
          if (this.access.isDemo) {
            console.log('DEMO MANIPULATE');
            response = (0, _demoMode.fudgeReports)(response);
          }

          let kpiArray = [];
          roomIdArray.forEach(room => {
            let color = getRandomColor();
            let data = response[0].rooms;

            if (data) {
              data = data.find(data => data.id === room.id);
            }

            if (data) {
              if (room.id === data.id) {
                let pushData = {
                  id: data.id,
                  roomName: data.roomName,
                  kpi: data.kpi,
                  color
                };
                kpiArray.push(pushData);
              }
            } else {
              let pushData = {
                id: room.id,
                roomName: room.roomName,
                kpi: 0,
                color
              };
              kpiArray.push(pushData);
            }
          });
          this.turnsKPI = kpiArray;
        }
      }
    });
  }

  function fetchStoreRevKPI(orgId, rooms) {
    return this.ajax.request(`/reports/LOCATION_AND_REVENUE/kpi?organizationId=${orgId}&raw=true`).then(response => {
      if (response[0] && !this.isDestroyed) {
        let roomIdArray = rooms.map(room => room);

        if (!response[0].organization) {
          response[0] = {
            organization: {
              kpi: 0
            }
          };
        }

        if (roomIdArray.length <= 0) {
          this.lastSavedKpis.revKPI = response[0].organization.kpi;
          this.revKPI = response[0].organization.kpi;
          this.groupRevKPI = Number(0);
        }

        if (roomIdArray.length >= 1) {
          if (this.access.isDemo) {
            console.log('DEMO MANIPULATE');
            response = (0, _demoMode.fudgeReports)(response);
          }

          let kpiArray = [];
          roomIdArray.forEach(room => {
            let color = getRandomColor();
            let data = response[0].rooms;

            if (data) {
              data = data.find(data => data.id === room.id);
            }

            if (data) {
              if (room.id === data.id) {
                let pushData = {
                  id: data.id,
                  roomName: data.roomName,
                  kpi: data.kpi,
                  color
                };
                kpiArray.push(pushData);
              }
            } else {
              let pushData = {
                id: room.id,
                roomName: room.roomName,
                kpi: 0,
                color
              };
              kpiArray.push(pushData);
            }
          });
          this.revKPI = kpiArray;
        }
      }
    });
  }

  function getRandomColor() {
    let letters = '0123456789ABCDEF';
    let color = '#';

    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }

    return color;
  }

  function kpiObserverGenerator(kpiType) {
    let kpi = this[kpiType];
    let lastSavedKpiVal = this.lastSavedKpis[kpiType];

    if (lastSavedKpiVal === 'init' && kpi === null) {
      // stuff has not even initialized, should still be disabled but just in case:
      this[`${kpiType}BtnDisabled`] = true;
    } else {
      if (kpi) {
        kpi = parseFloat(kpi);
      } else {
        kpi = undefined;
      }

      if (lastSavedKpiVal) {
        lastSavedKpiVal = parseFloat(lastSavedKpiVal);
      } else {
        lastSavedKpiVal = undefined;
      }

      this[`${kpiType}BtnDisabled`] = false; // console.log('BtnDisabled?', `${kpiType}BtnDisabled`, kpi, lastSavedKpiVal, kpi != lastSavedKpiVal)
      // this[`${kpiType}BtnDisabled`] = kpi === lastSavedKpiVal;
      // console.log(`kpi:${kpi} type:${typeof kpi}`, `lastSavedKpis.${kpiType}: ${lastSavedKpis[kpiType]}  type:${typeof lastSavedKpis[kpiType]}`, `they totally equal${kpi === lastSavedKpiVal}`);
    }
  }

  function updateRoomKPI(kpiType) {
    let kpi = this[kpiType];
    let updateArray = [];

    if (isNaN(kpi)) {
      kpi.forEach(value => {
        let data = {
          id: value.id,
          roomName: value.roomName,
          kpi: Number(value.kpi),
          color: value.color
        };

        if (data) {
          updateArray.push(data);
        }
      });

      if (kpiType === 'revKPI') {
        this.revKPI = updateArray;
      }

      if (kpiType === 'turnsKPI') {
        this.turnsKPI = updateArray;
      }
    }
  }

  function saveRevKPI(kpiType, index) {
    let orgId = this.access.accessOrganization.id;
    let kpi = this[kpiType];
    let url = '/reports/LOCATION_AND_REVENUE/kpi';
    let dataKpi = String(kpi || 0);

    if (index >= 0) {
      kpi = kpi.get(index);
      url = `/reports/LOCATION_AND_REVENUE/kpi?roomId=${kpi.id}`;
      dataKpi = String(kpi.kpi || 0);
    }

    this.ajax.request(url, {
      type: 'PATCH',
      data: {
        kpi: dataKpi,
        organizationId: orgId
      }
    }).then(resp => {
      this.lastSavedKpis[kpiType] = kpi;
      this.notifications.success(this.intl.t('kpi_save_success'), {
        autoClear: true,
        clearDuration: 2000
      });
    }).catch(() => {
      this.notifications.error(this.intl.t('kpi_save_error'), {
        autoClear: true,
        clearDuration: 5000
      });
    });
  }

  function saveTurnsKPI(kpiType, index) {
    let orgId = this.access.accessOrganization.id;
    let kpi = this[kpiType];
    let url = '/reports/AUDIT_TURNS/kpi';
    let data = {
      kpi: String(kpi || 0),
      organizationId: orgId
    };

    if (index >= 0) {
      kpi = kpi.get(index);
      url = `/reports/AUDIT_TURNS/kpi?roomId=${kpi.id}`;
      data = {
        kpi: String(kpi.kpi || 0)
      };
    }

    this.ajax.request(url, {
      type: 'PATCH',
      data
    }).then(resp => {
      this.lastSavedKpis[kpiType] = kpi;
      this.notifications.success(this.intl.t('kpi_save_success'), {
        autoClear: true,
        clearDuration: 2000
      });
    }).catch(() => {
      this.notifications.error(this.intl.t('kpi_save_error'), {
        autoClear: true,
        clearDuration: 5000
      });
    });
  }

  function groupRoomKPI(kpiType) {
    let kpi = this.args[kpiType];
    let kpiSum = 0;
    kpi.forEach(value => {
      kpiSum += Number(value.kpi);
    });

    if (kpiType === 'revKPI') {
      this.hasGroupRevKPI = !this.hasGroupRevKPI;

      if (this.hasGroupRevKPI) {
        this.groupRevKPI = kpiSum;
      } else {
        this.groupRevKPI = null;
        this.args.updateRoomKPI(kpiType);
      }
    } else if (kpiType === 'turnsKPI') {
      this.hasGroupTurnsKPI = !this.hasGroupTurnsKPI;

      if (this.hasGroupTurnsKPI) {
        this.groupTurnsKPI = kpiSum;
      } else {
        this.groupTurnsKPI = null;
        this.args.updateRoomKPI(kpiType);
      }
    }
  } // copied from https://stackoverflow.com/a/2901298/565877


  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  function machineNameDisplay(name, number) {
    let machineName = name;
    let machineNumber = `${number}`;
    let displayValue = machineNumber.replace(/^[0|\D]*/, '');

    if (machineName) {
      if (machineName.length < 8) {
        displayValue = machineName;
      }
    }

    return displayValue;
  }
});