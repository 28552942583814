define("alliance-business-suite/templates/components/table/table-cell-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3agQrGZE",
    "block": "{\"symbols\":[],\"statements\":[[10,\"img\"],[14,0,\"table-img\"],[15,\"src\",[30,[36,2],[[35,1],[35,0,[\"propertyName\"]]],null]],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"column\",\"record\",\"get\"]}",
    "moduleName": "alliance-business-suite/templates/components/table/table-cell-image.hbs"
  });

  _exports.default = _default;
});