define("alliance-business-suite/components/machines/traceability/data/charts-redraw", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@glimmer/component"], function (_exports, _applyDecoratedDescriptor2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{!-- DO NOT DELETE --}}
  {{!-- This hidden component is used only to capture the chart instance and update event --}}
  <div
    class="hide-all"
    {{did-update this.redrawChart @xAxisLabels}}>
  </div>
  
  */
  {
    "id": "+15iyF2n",
    "block": "{\"symbols\":[\"@xAxisLabels\"],\"statements\":[[11,\"div\"],[24,0,\"hide-all\"],[4,[38,0],[[32,0,[\"redrawChart\"]],[32,1]],null],[12],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"did-update\"]}",
    "moduleName": "alliance-business-suite/components/machines/traceability/data/charts-redraw.hbs"
  });

  let MachinesTraceabilityDataChartsRedrawComponent = (_dec = Ember._action, (_class = class MachinesTraceabilityDataChartsRedrawComponent extends _component.default {
    // update the xAxis labels when new data is pushed by redrawing the "chart" context
    redrawChart() {
      this.args.chart.xAxis[0].setCategories(this.args.xAxisLabels);
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class.prototype, "redrawChart", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "redrawChart"), _class.prototype)), _class));
  _exports.default = MachinesTraceabilityDataChartsRedrawComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, MachinesTraceabilityDataChartsRedrawComponent);
});