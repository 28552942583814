define("alliance-business-suite/mixins/machines/programs/cycles/water-usage", ["exports", "alliance-business-suite/utils/bits", "alliance-business-suite/utils/cycles-values"], function (_exports, _bits, _cyclesValues) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Requires:
   * - options.FillWaterLevel
   */
  var _default = Ember.Mixin.create({
    // eslint-disable-line
    intl: Ember.inject.service(),

    didReceiveAttrs() {
      this._super(...arguments);

      if (!this.machineProgram.settings._hasHeater) {
        Ember.set(this, 'model.settings._hasHeater', 'false');
      }

      if (!this.useLiters) {
        Ember.set(this, 'useLiters', false);
      }

      if (this.machineProgram._machineAuditType === "ACAS_A4") {
        this.mediumMod1Enabled = this.machineProgram.settings.Program.Price.Key1ModifierOptionsPrewash;
        this.mediumMod2Enabled = this.machineProgram.settings.Program.Price.Key1ModifierOptionsExtraRinse;
        this.heavyMod1Enabled = this.machineProgram.settings.Program.Price.Key2ModifierOptionsPrewash;
        this.heavyMod2Enabled = this.machineProgram.settings.Program.Price.Key2ModifierOptionsExtraRinse;
        this.mediumWarmRinseEnabled = this.machineProgram.settings.Program.Price.Key1ModifierOptionsWarmFinalRinse;
        this.heavyWarmRinseEnabled = this.machineProgram.settings.Program.Price.Key2ModifierOptionsWarmFinalRinse;
      }

      if (this.machineProgram._machineAuditTypeFamily === "MGD") {
        this.mediumMod1Enabled = this.machineProgram.settings.Program.Price.ModifierKey1Options.PrewashEnabled;
        this.mediumMod2Enabled = this.machineProgram.settings.Program.Price.ModifierKey1Options.ExtraRinseEnabled;
        this.heavyMod1Enabled = this.machineProgram.settings.Program.Price.ModifierKey2Options.PrewashEnabled;
        this.heavyMod2Enabled = this.machineProgram.settings.Program.Price.ModifierKey2Options.ExtraRinseEnabled;
        this.mediumWarmRinseEnabled = this.machineProgram.settings.Program.Price.ModifierKey1Options.WarmRinseEnabled;
        this.heavyWarmRinseEnabled = this.machineProgram.settings.Program.Price.ModifierKey2Options.WarmRinseEnabled;
      }

      if (this.machineProgram._machineAuditType === "ACAS_A3") {
        this.mediumMod1Enabled = this.machineProgram.settings.Program.Price.Key1ModifierOptionsPrewash;
        this.mediumMod2Enabled = this.machineProgram.settings.Program.Price.Key1ModifierOptionsExtraRinse;
        this.heavyMod1Enabled = this.machineProgram.settings.Program.Price.Key2ModifierOptionsPrewash;
        this.heavyMod2Enabled = this.machineProgram.settings.Program.Price.Key2ModifierOptionsExtraRinse;
        this.mediumWarmRinseEnabled = this.machineProgram.settings.Program.Price.Key1ModifierOptionsWarmFinalRinse;
        this.heavyWarmRinseEnabled = this.machineProgram.settings.Program.Price.Key2ModifierOptionsWarmFinalRinse;
      }

      if (["TITAN_T4", "TITAN_T2"].includes(this.machineProgram._machineAuditType)) {
        this.mediumMod1Enabled = _bits.default.get('this.machineProgram.settings.Program.lpas1To4Message.vendRelatedMessage.mod1Options', 0);
        this.mediumMod2Enabled = _bits.default.get('this.machineProgram.settings.Program.lpas1To4Message.vendRelatedMessage.mod1Options', 1);
        this.heavyMod1Enabled = _bits.default.get('this.machineProgram.settings.Program.lpas1To4Message.vendRelatedMessage.mod2Options', 0);
        this.heavyMod2Enabled = _bits.default.get('this.machineProgram.settings.Program.lpas1To4Message.vendRelatedMessage.mod2Options', 1);
        this.mediumWarmRinseEnabled = this.machineProgram.settings.Program.vendRelatedMessage.warmRinseMod1;
        this.heavyWarmRinseEnabled = this.machineProgram.settings.Program.vendRelatedMessage.warmRinseMod2;
      }

      this.calculateWaterUse();
    },

    // #region Calculate Water Use
    calculateWaterUse() {
      const logTime = false;
      if (logTime) console.time('Calculate water use'); // eslint-disable-line no-console
      // Move calculations to new object instead of creating new properties in our model

      let cycleWaterUse = [];
      let stepWaterUse = [];
      let cycles = this.machineProgram.settings.Cycles.CycleSteps || this.machineProgram.settings.Cycles;
      let hasHeaterValue = this.machineProgram.settings._hasHeater;
      let hasHeater = hasHeaterValue === true || hasHeaterValue == 'true'; // TODO: False in wx
      // Loop through each cycle

      cycles.forEach((cycle, cycleIndex) => {
        // Stub out our object for storing calcs
        cycleWaterUse.push({
          l: null,
          m: null,
          h: null,
          s: null,
          step: []
        }); // Loop through each load type

        ['light', 'medium', 'heavy', 'speed_cycle'].forEach((load, modIndex) => {
          let cycleHot = 0;
          let cycleCold = 0;
          let isFirstFill = true; // The cycle can be an array of steps, or the steps can be contained in a CycleSteps array.

          let steps = cycle.CycleSteps || cycle; // Loop through the steps for this load type

          steps.forEach((step, stepIndex) => {
            // We only do work for fill step
            let stepType = Ember.get(step, 'Type');
            let activeState = Number(Ember.get(step, 'ActiveState'));
            let cold = 0;
            let hot = 0;

            if (modIndex === 0) {
              // Stub out this step's data as we forEach
              stepWaterUse.push({
                l: null,
                m: null,
                h: null,
                s: null
              });
            } // First we only deal with Fill steps that have time. Then, only active based on the current modifier.


            if (stepType === 2 && Ember.get(step, 'Minutes') + Ember.get(step, 'Seconds') > 0) {
              // if (stepType === 2) {
              // For medium and heavy, we need to know which mod1/2 should be considered active.
              if (this.isModifierInLoad(load, activeState)) {
                // Get the water level
                let stepWaterLevel = step.WaterLevel ?? step.CustomWaterLevel; // The fillWaterLevel comes from different places for Cycles vs. Water Usage

                let fillWaterLevel;
                let optionsFillWaterLevel = this.selectOptions.FillWaterLevel ?? this.optionsFillWaterLevel;

                if (optionsFillWaterLevel) {
                  // Water Usage tab
                  fillWaterLevel = optionsFillWaterLevel.findBy('val', stepWaterLevel); // Get "Custom" if val is not found

                  if (fillWaterLevel == undefined) {
                    fillWaterLevel = optionsFillWaterLevel.findBy('isCustom', true);
                  }
                } else {
                  // Cycles tab
                  fillWaterLevel = this.cycleSettings ? this.cycleSettings.options.FillWaterLevel.findBy('val', stepWaterLevel) : this.selectOptions.FillWaterLevel.findBy('val', stepWaterLevel);
                }

                let waterLevel = Ember.get(fillWaterLevel, 'isCustom') ? Ember.get(step, 'CustomWaterLevel') : Ember.get(fillWaterLevel, 'presetValue');
                let settings = this.machineProgram.settings;

                if (isFirstFill) {
                  if (hasHeater) {
                    if (this.isHotFill(settings, cycle, step, load)) {
                      cold = 0;
                      hot = this.waterCalc(waterLevel, 0.0002, 0.0964, 7.5384);
                    } else if (this.isWarmFill(settings, cycle, step, load)) {
                      cold = this.waterCalc(waterLevel, -0.0024, 0.1462, 4.3442);
                      hot = this.waterCalc(waterLevel, -0.0014, 0.0859, 2.9913);
                    } else if (this.isColdFill(settings, cycle, step, load)) {
                      cold = this.waterCalc(waterLevel, -0.0038, 0.2347, 7.2997);
                      hot = 0;
                    }
                  } else {
                    if (this.isHotFill(settings, cycle, step, load)) {
                      cold = 0;
                      hot = this.waterCalc(waterLevel, -0.0023, 0.1707, 6.3192);
                    } else if (this.isWarmFill(settings, cycle, step, load)) {
                      cold = this.waterCalc(waterLevel, -0.0015, 0.1198, 3.9034);
                      hot = this.waterCalc(waterLevel, -0.0005, 0.0727, 2.3014);
                    } else if (this.isColdFill(settings, cycle, step, load)) {
                      cold = this.waterCalc(waterLevel, -0.0037, 0.2232, 6.096);
                      hot = 0;
                    }
                  }
                } else {
                  // not first fill
                  if (hasHeater) {
                    if (this.isHotFill(settings, cycle, step, load)) {
                      cold = 0;
                      hot = this.waterCalc(waterLevel, -0.0011, 0.1781, 5.347);
                    } else if (this.isWarmFill(settings, cycle, step, load)) {
                      cold = this.waterCalc(waterLevel, -0.0013, 0.131, 3.1146);
                      hot = this.waterCalc(waterLevel, -0.0008, 0.0834, 2.1433);
                    } else if (this.isColdFill(settings, cycle, step, load)) {
                      cold = this.waterCalc(waterLevel, -0.0009, 0.1884, 5.2573);
                      hot = 0;
                    }
                  } else {
                    if (this.isHotFill(settings, cycle, step, load)) {
                      cold = 0;
                      hot = this.waterCalc(waterLevel, -.0015, 0.1994, 4.0756);
                    } else if (this.isWarmFill(settings, cycle, step, load)) {
                      cold = this.waterCalc(waterLevel, -0.0003, 0.1056, 2.672);
                      hot = this.waterCalc(waterLevel, -0.0004, 0.0737, 1.539);
                    } else if (this.isColdFill(settings, cycle, step, load)) {
                      cold = this.waterCalc(waterLevel, -0.0011, 0.1853, 4.2276);
                      hot = 0;
                    }
                  }
                }

                isFirstFill = false;
              } // if activeState

            } // if Fill step
            // Save values for the current step.


            let totalWater = new Big(hot).plus(cold).toFixed(2); // Save this mod's calcs for this step

            stepWaterUse[stepIndex][load.charAt(0)] = [totalWater === '0.00' ? 0 : totalWater, hot]; // Add up to display total for the cycle

            cycleHot += Number(hot);
            cycleCold += Number(cold);
          }); // done with steps
          // Save the total for this cycle

          let cycleTotalWater = new Big(cycleHot).plus(cycleCold).toFixed(2);
          cycleWaterUse[cycleIndex][load.charAt(0)] = [cycleTotalWater === '0.00' ? 0 : cycleTotalWater, cycleHot.toFixed(2)];
        }); // done with load
        // Save all the steps for this cycle

        cycleWaterUse[cycleIndex].step = stepWaterUse;
        stepWaterUse = [];
      }); // done with cycles

      Ember.set(this, 'waterUse', cycleWaterUse);
      if (logTime) console.timeEnd('Calculate water use'); // eslint-disable-line no-console
    },

    // TODO: Is this deprecated?
    showCycleWater: Ember.computed({
      get(key) {
        return localStorage.getItem('showCycleWater') === 'ON';
      },

      set(key, value) {
        localStorage.setItem('showCycleWater', value ? 'ON' : 'OFF');
        return value;
      }

    }),
    // TODO: Is this deprecated?
    showStepWater: Ember.computed({
      get(key) {
        return localStorage.getItem('showStepWater') === 'ON';
      },

      set(key, value) {
        localStorage.setItem('showStepWater', value ? 'ON' : 'OFF');
        return value;
      }

    }),
    hasHeater: Ember.computed.alias('model.settings._hasHeater'),

    isWarmFill(settings, cycle, step, load) {
      if (this.isUsingFinalWarmRinse(settings, cycle, step, load)) {
        return true;
      } else {
        let hotFill = step.HotFillOn || step.HotFillEnabled;
        let coldFill = step.ColdFillOn || step.ColdFillEnabled;
        let controlledFill = step.TemperatureControlledFill || step.TempControlledFillEnabled;
        return coldFill && hotFill || this.hasHeater && controlledFill;
      }
    },

    isColdFill(settings, cycle, step, load) {
      if (this.isUsingFinalWarmRinse(settings, cycle, step, load)) {
        return false;
      } else {
        let hotFill = step.HotFillOn || step.HotFillEnabled;
        let coldFill = step.ColdFillOn || step.ColdFillEnabled;
        let controlledFill = step.TemperatureControlledFill || step.TempControlledFillEnabled; // From Windows app: To make life easier non-heater machine will use cold water for temperature controlled fill

        return coldFill && !hotFill || !this.hasHeater && controlledFill;
      }
    },

    isHotFill(settings, cycle, step, load) {
      if (this.isUsingFinalWarmRinse(settings, cycle, step, load)) {
        return false;
      } else {
        let hotFill = step.HotFillOn || step.HotFillEnabled;
        let coldFill = step.ColdFillOn || step.ColdFillEnabled;
        return !coldFill && hotFill;
      }
    },

    isUsingFinalWarmRinse(settings, steps, step, load) {
      /*
        Applies to Medium and Heavy loads only!
         If this is a medium or heavy load, we need to see if it has Warm Rinse enabled in the
        Pricing tab. If it does, the last Fill is always going to be Warm for that load.
      */
      if (load === 'light') {
        return false;
      }

      if (load === 'speed_cycle') {
        return false;
      }

      let usingWarmRinse = false; // We need to see if this Load has a Warm Rinse.

      if (load == 'medium') {
        usingWarmRinse = this.mediumWarmRinseEnabled;
      } else if (load == 'heavy') {
        usingWarmRinse = this.heavyWarmRinseEnabled;
      } // If warm rinse is enabled for this mod, we need to check if this is the final Fill step for this cycle.


      if (usingWarmRinse) {
        // Find the last fill step in the cycle for this load type by going backwards in the array of steps.
        // The ActiveState for this step must be either Enabled or an active Mod for this Load
        for (let index = steps.length - 1; index >= 0; index--) {
          let checkingStep = steps[index]; // Is this a fill step?

          if (checkingStep.Type == 2) {
            // Fill step is 2
            // Is this step a valid ActiveState?
            if (this.isModifierInLoad(load, Ember.get(checkingStep, 'ActiveState'))) {
              return checkingStep == step; // returns true if the step we're checking is the step we're on
            }
          }
        }
      }

      return false;
    },

    waterCalc(waterLevel, num1, num2, num3) {
      if (waterLevel !== undefined) {
        let wl = new Big(waterLevel);
        return wl.pow(2).times(num1).plus(wl.times(num2).plus(num3)).toFixed(2);
      } else {
        return 0;
      }
    },

    mediumMods: Ember.computed(function () {
      // Return array of integers representing the mods that are active in Medium loads.
      let activeMods = [];

      if (this.mediumMod1Enabled) {
        activeMods.push(1);
      }

      if (this.mediumMod2Enabled) {
        activeMods.push(2);
      }

      return activeMods;
    }),
    heavyMods: Ember.computed(function () {
      // Return array of integers representing the mods that are active in Heavy loads.
      let activeMods = [];

      if (this.heavyMod1Enabled) {
        activeMods.push(1);
      }

      if (this.heavyMod2Enabled) {
        activeMods.push(2);
      }

      return activeMods;
    }),

    isModifierInLoad(load, activeState) {
      // Skip if disabled
      if (activeState === _cyclesValues.ACTIVE_STATES.disabled) {
        return false;
      } // ActiveState of enabled is always active for all loads


      if (activeState === _cyclesValues.ACTIVE_STATES.enabled) {
        return true;
      }

      if (activeState === _cyclesValues.ACTIVE_STATES.speedCycle && load === "speed_cycle") {
        return false; // Behaves like ActiveState is Enabled
      }

      if (activeState === _cyclesValues.ACTIVE_STATES.speedCycle && load !== "speed_cycle") {
        return true;
      }

      switch (load) {
        case 'light':
          // this WAS commented out...not sure why
          return activeState == _cyclesValues.ACTIVE_STATES.enabled;

        case 'medium':
          return this.mediumMods.includes(activeState);

        case 'heavy':
          return this.heavyMods.includes(activeState);

        case 'speed_cycle':
          if (activeState == _cyclesValues.ACTIVE_STATES.enabled) {
            return true;
          } else {
            return false;
          }

        default:
          return false;
      }
    },

    // #endregion
    // Calc water when fill steps are changed
    // updateWaterUse: observer('model.settings._hasHeater', 'cycleList.[]', 'stepList.[]', 'selectedStep.{Minutes,Seconds,HotFillOn,ColdFillOn,WaterLevel,ActiveState,Temperature,FillValveSet,CustomWaterLevel,CustomTemperature,TemperatureControlledFill}', function() { // eslint-disable-line
    updateWaterUse: Ember.observer('model.settings._hasHeater', 'selectedStep.{Minutes,Seconds,HotFillOn,ColdFillOn,HotFillEnabled,ColdFillEnabled,WaterLevel,ActiveState,Temperature,FillValveSet,CustomWaterLevel,CustomTemperature,TemperatureControlledFill}', function () {
      // eslint-disable-line
      // Only Fill steps deal with water
      if (this?.selectedStep?.Type == 2) {
        Ember.run.once(this, 'calculateWaterUse');
      }
    }),
    useLiters: Ember.computed({
      get(key) {
        return localStorage.getItem('useLiters') == 'true';
      },

      set(key, value) {
        localStorage.setItem('useLiters', value);
        return value;
      }

    }),
    waterUnits: Ember.computed('useLiters', function () {
      let t = this.intl;
      return this.useLiters ? t.t('l') : t.t('g');
    }),
    waterUnitsLabel: Ember.computed('useLiters', function () {
      let t = this.intl;
      return this.useLiters ? t.t('liters') : t.t('gallons');
    })
  });

  _exports.default = _default;
});