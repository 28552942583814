define("alliance-business-suite/templates/components/table/table-cell-quantity-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9jqKPHO3",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,7],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[11,\"div\"],[16,0,[31,[\"signal-bars mt1 sizing-box \",[34,3]]]],[4,[38,0],[[32,0],\"go\"],null],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"first-bar bar\"],[12],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"second-bar bar\"],[12],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"third-bar bar\"],[12],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"fourth-bar bar\"],[12],[13],[2,\"\\n\\n    \"],[8,\"ember-tooltip\",[],[[\"@event\",\"@class\"],[\"hover\",[30,[36,2],[\"\",[35,1]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n      Inventory alert set at \"],[1,[34,4]],[2,\" \"],[1,[30,[36,7],[[35,6,[\"isLocation\"]],[30,[36,5],[\"for_this_location\"],null],[30,[36,5],[\"across_your__organization\"],null]],null]],[2,\". Current inventory at \"],[1,[30,[36,9],[[35,8],[35,6,[\"propertyName\"]]],null]],[2,\".\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[11,\"i\"],[24,0,\"fa fa-bell\"],[24,\"aria-hidden\",\"true\"],[24,5,\"opacity: .3;\"],[4,[38,0],[[32,0],\"go\"],null],[12],[2,\"\\n    \"],[8,\"ember-tooltip\",[],[[\"@event\",\"@class\"],[\"hover\",[30,[36,2],[\"\",[35,1]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n      Create an inventory alert notification to know when inventory levels fall below a threshold.\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"cssColor\",\"concat\",\"cssClass\",\"alertThreshold\",\"t\",\"column\",\"if\",\"record\",\"get\"]}",
    "moduleName": "alliance-business-suite/templates/components/table/table-cell-quantity-alert.hbs"
  });

  _exports.default = _default;
});