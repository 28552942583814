define("alliance-business-suite/templates/summary/charts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6PrNbZY9",
    "block": "{\"symbols\":[],\"statements\":[[8,\"dashboard/dashboard-charts-section\",[],[[\"@startDate\",\"@endDate\",\"@rooms\",\"@initRoom\",\"@updateDate\",\"@showParentModal\"],[[32,0,[\"startDate\"]],[32,0,[\"endDate\"]],[32,0,[\"roomsSorted\"]],[32,0,[\"initRoom\"]],[32,0,[\"updateDate\"]],[32,0,[\"showWelcomeModal\"]]]],null],[2,\"\\n\\n\"],[6,[37,0],[[32,0,[\"showWelcome\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"ui/modal-welcome\",[],[[\"@close\"],[[32,0,[\"showWelcomeModal\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "alliance-business-suite/templates/summary/charts.hbs"
  });

  _exports.default = _default;
});