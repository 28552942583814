define("alliance-business-suite/components/summary/reports/s-error-history", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "alliance-business-suite/utils/report-tables-util"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _reportTablesUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  let SummaryReportsSDailyOverviewComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, (_class = class SummaryReportsSDailyOverviewComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "brand", _descriptor4, this);
    }

    get translatedColumns() {
      return [{
        name: 'location_machine',
        valuePath: 'name',
        width: 200,
        isFixed: 'left'
      }, {
        name: 'models.machine.error',
        valuePath: 'error',
        width: 125
      }, {
        name: 'cycle',
        valuePath: 'cycle',
        width: 100
      }, {
        name: 'models.machine.segment',
        valuePath: 'segment',
        width: 100
      }, {
        name: 'step',
        valuePath: 'step',
        width: 100
      }, {
        name: 'models.machine.error-date',
        valuePath: 'errorDate',
        width: 100
      }];
    }

    get rowsWithChildren() {
      let geoBoundaries = this.args.model.get('geoBoundaries');
      let rowData = [];
      geoBoundaries.forEach(geoBoundary => {
        let rowArray = [];
        let nameArray = [];
        rowData.push({
          name: _reportTablesUtil.localizeString.call(this, geoBoundary.name, 'Location', 'location'),
          children: nameArray
        });
        geoBoundary.rooms.forEach(room => {
          nameArray.push({
            name: room.name,
            children: rowArray
          });
          room.machineAuditGroupTypes.forEach(machineAuditType => {
            machineAuditType.machineTypes.forEach(machineType => {
              let machineArray = [];
              let machineTypeName = `${machineAuditType.name} - ${machineType.name}`;
              rowArray.push({
                name: machineTypeName,
                machineTypeName: machineType.name,
                children: machineArray
              });
              machineType.machines.forEach(machine => {
                let machineName = _reportTablesUtil.machineNameDisplay.call(this, machine.machineName, machine.machineNumber);

                let error = machine.error || 'N/A';
                let cycle = machine.cycle || 'N/A';
                let segment = machine.segment || 'N/A';
                let step = machine.step || 'N/A';
                let errorDate = "";

                if (this.brand.slug == 'primus') {
                  // fix the branding issue alliance-tasks/issues/2291
                  rowArray[rowArray.length - 1].name = `${machine.machineAuditType.name} - ${rowArray[rowArray.length - 1].machineTypeName}`;
                }

                if (machine.errorDate) {
                  errorDate = moment(machine.errorDate, "MM/DD/YYYY hh:mm A").format(`${_reportTablesUtil.formatDateTimeString.call(this)}`);
                }

                machineArray.push({
                  name: machineName,
                  error: error,
                  cycle: cycle,
                  segment: segment,
                  step: step,
                  errorDate: errorDate
                });
              });
            });
          });
        });
      });
      return rowData;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "brand", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SummaryReportsSDailyOverviewComponent;
});