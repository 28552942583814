define("alliance-business-suite/adapters/item", ["exports", "alliance-business-suite/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    buildURL(modelName, id, snapshot, requestType, query) {
      let url = this._super('', null, snapshot, requestType, query);

      let organizationId = this.get('session.data.orgId');

      switch (requestType) {
        case 'findAll':
          url += `/organizations/${organizationId}/items?include=all`;
          return url;

        case 'createRecord':
          url += '/items';
          return url;

        case 'updateRecord':
          url += `/items/${id}`;
          return url;

        case 'deleteRecord':
          url += `/items/${id}`;
          return url;
      }

      return url;
    }

  });

  _exports.default = _default;
});