define("alliance-business-suite/utils/convert-temperature", ["exports", "big.js"], function (_exports, _big) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.toFRate = _exports.toF = _exports.toCRate = _exports.toC = _exports.FtoC = _exports.CtoF = void 0;

  /* global Big */
  // This converts temperatures using the same arrays that the Windows application uses.
  //
  // public static byte[] FtoC = new byte[] { 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 2, 2, 3, 3, 4, 4, 5, 6, 6, 7, 7, 8, 8, 9, 9, 10, 11, 11, 12, 12, 13, 13, 14, 14, 15, 16, 16, 17, 17, 18, 18, 19, 19, 20, 21, 21, 22, 22, 23, 23, 24, 24, 25, 26, 26, 27, 27, 28, 28, 29, 29, 30, 31, 31, 32, 32, 33, 33, 34, 34, 35, 36, 36, 37, 37, 37 /* [Tweaked from 38] */, 38, 39, 39, 40, 41, 41, 42, 42, 43, 43, 44, 44, 45, 46, 46, 47, 47, 48, 48, 49, 49, 50, 51, 51, 52, 52, 53, 53, 54, 54, 55, 56, 56, 57, 57, 58, 58, 59, 59, 60, 61, 61, 62, 62, 63, 63, 64, 64, 65, 66, 66, 67, 67, 68, 68, 69, 69, 70, 71, 71, 72, 72, 73, 73, 74, 74, 75, 76, 76, 77, 77, 78, 78, 79, 79, 80, 81, 81, 82, 82, 83, 83, 84, 84, 85, 86, 86, 87, 87, 88, 88, 89, 89, 90, 91, 91, 92, 92, 93, 93, 94, 94, 95, 96, 96, 97, 97, 98, 98, 99, 99, 100 };
  // public static byte[] CtoF = new byte[] { 32, 34, 35 /* [Tweaked from 36] */, 37, 39, 41, 43, 45, 46, 48, 50, 52, 54, 55, 57, 59, 61, 63, 64, 66, 68, 70, 72, 73, 75, 77, 79, 81, 82, 84, 86, 88, 90, 91, 93, 95, 97, 100 /* [Tweaked from 99] */, 100, 102, 104, 106, 108, 110 /* [Tweaked from 109] */, 111, 113, 115, 117, 118, 120, 122, 124, 126, 127, 129, 131, 133, 135, 136, 138, 140, 142, 144, 145, 147, 149, 151, 153, 154, 156, 158, 160, 162, 163, 165, 167, 169, 171, 172, 174, 176, 178, 180, 181, 183, 185, 187, 189, 190, 192, 194, 196, 198, 199, 201, 203, 205, 207, 208, 210, 212 };`
  //
  //
  const FtoC = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 2, 2, 3, 3, 4, 4, 5, 6, 6, 7, 7, 8, 8, 9, 9, 10, 11, 11, 12, 12, 13, 13, 14, 14, 15, 16, 16, 17, 17, 18, 18, 19, 19, 20, 21, 21, 22, 22, 23, 23, 24, 24, 25, 26, 26, 27, 27, 28, 28, 29, 29, 30, 31, 31, 32, 32, 33, 33, 34, 34, 35, 36, 36, 37, 37, 37
  /* [Tweaked from 38] */
  , 38, 39, 39, 40, 41, 41, 42, 42, 43, 43, 44, 44, 45, 46, 46, 47, 47, 48, 48, 49, 49, 50, 51, 51, 52, 52, 53, 53, 54, 54, 55, 56, 56, 57, 57, 58, 58, 59, 59, 60, 61, 61, 62, 62, 63, 63, 64, 64, 65, 66, 66, 67, 67, 68, 68, 69, 69, 70, 71, 71, 72, 72, 73, 73, 74, 74, 75, 76, 76, 77, 77, 78, 78, 79, 79, 80, 81, 81, 82, 82, 83, 83, 84, 84, 85, 86, 86, 87, 87, 88, 88, 89, 89, 90, 91, 91, 92, 92, 93, 93, 94, 94, 95, 96, 96, 97, 97, 98, 98, 99, 99, 100];
  _exports.FtoC = FtoC;
  const CtoF = [32, 34, 35
  /* [Tweaked from 36] */
  , 37, 39, 41, 43, 45, 46, 48, 50, 52, 54, 55, 57, 59, 61, 63, 64, 66, 68, 70, 72, 73, 75, 77, 79, 81, 82, 84, 86, 88, 90, 91, 93, 95, 97, 100
  /* [Tweaked from 99] */
  , 100, 102, 104, 106, 108, 110
  /* [Tweaked from 109] */
  , 111, 113, 115, 117, 118, 120, 122, 124, 126, 127, 129, 131, 133, 135, 136, 138, 140, 142, 144, 145, 147, 149, 151, 153, 154, 156, 158, 160, 162, 163, 165, 167, 169, 171, 172, 174, 176, 178, 180, 181, 183, 185, 187, 189, 190, 192, 194, 196, 198, 199, 201, 203, 205, 207, 208, 210, 212];
  _exports.CtoF = CtoF;

  const toC = F => {
    if (F > 212) {
      return (0, _big.default)(F).minus(32).times(5).div(9);
    } else {
      return FtoC[F];
    }
  };

  _exports.toC = toC;

  const toF = C => {
    if (C > 100) {
      return (0, _big.default)(C).times(9).div(5).plus(32);
    } else {
      return CtoF[C];
    }
  };

  _exports.toF = toF;

  const toFRate = value => {
    let roundup = (0, _big.default)(value).times(10).times(9).mod(5) >= 3 ? 1 : 0;
    return (0, _big.default)(value).times(10).times(9).div(5).round(0, 0).plus(roundup).div(10);
  };

  _exports.toFRate = toFRate;

  const toCRate = value => {
    let roundup = (0, _big.default)(value).times(10).times(5).mod(9) >= 5 ? 1 : 0;
    return (0, _big.default)(value).times(10).times(5).div(9).round(0, 0).plus(roundup).div(10);
  };

  _exports.toCRate = toCRate;
});