define("alliance-business-suite/components/form-ui/steps/heater-options-rate", ["exports", "alliance-business-suite/mixins/init-property-names", "alliance-business-suite/helpers/find-by-dropdown-with-custom"], function (_exports, _initPropertyNames, _findByDropdownWithCustom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_initPropertyNames.default, {
    isCelsius: Ember.computed.alias('formSettings.isCelsius'),
    stepLabelName: Ember.computed('Label', 'formSettings.selectOptions', function () {
      return (0, _findByDropdownWithCustom.findByDropDown)([this.formSettings.selectOptions['StepLabel'], "val", this.Label]).group.toLowerCase();
    }),
    isDisabled: Ember.computed.equal('HeatOption', 0)
  });

  _exports.default = _default;
});