define("alliance-business-suite/controllers/locations/list", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "jquery"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  let LocationsListController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, (_class = class LocationsListController extends Ember.Controller {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "brush", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "isEditOrgOpen", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "isDialogOpen", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "selectedFundsRoot", _descriptor6, this);
    }

    get canViewOrgDepositModal() {
      return this['can-access'].canAccess('LOCATIONS_MANAGE_FUND_DEPOSIT_LIMITS') || this['can-access'].canAccess('LOCATIONS_MANAGE_ACCOUNT_CREDIT_VALUES');
    }

    appendText() {
      if (!this.access.get('accessOrganization.hasCvvVerification')) {
        (0, _jquery.default)('*[data-edit-text="true"]').append(`<a class="link">- ${this.intl.t('edit')}</a>`);
      }
    }

    toggleOrgEdit() {
      this.isEditOrgOpen = !this.isEditOrgOpen;
    }

    toggleModal(e) {
      if (e) {
        this.selectedFundsRoot = e;
      }

      this.isDialogOpen = !this.isDialogOpen;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "brush", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isEditOrgOpen", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isDialogOpen", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedFundsRoot", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "appendText", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "appendText"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "toggleOrgEdit", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "toggleOrgEdit"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "toggleModal", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "toggleModal"), _class.prototype)), _class));
  _exports.default = LocationsListController;
});