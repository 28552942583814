define("alliance-business-suite/templates/components/table/table-cell-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mrcjvuha",
    "block": "{\"symbols\":[],\"statements\":[[8,\"link-to\",[],[[\"@route\",\"@model\"],[[34,0,[\"customLinkTo\"]],[34,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"span\"],[14,5,\"text-transform: capitalize\"],[12],[2,\"\\n    \"],[1,[35,0,[\"customLinkText\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"column\",\"record\"]}",
    "moduleName": "alliance-business-suite/templates/components/table/table-cell-link.hbs"
  });

  _exports.default = _default;
});