define("alliance-business-suite/routes/crm/campaigns/manage", ["exports", "@babel/runtime/helpers/esm/defineProperty", "alliance-business-suite/routes/_secureRoute"], function (_exports, _defineProperty2, _secureRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CrmCampaignsManageRoute extends _secureRoute.default {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "allowAccess", 'CRM_CAMPAIGNS_VIEW_PAGE');
    }

    model(params) {
      return this.store.findRecord('campaign', params.campaign_id);
    }

  }

  _exports.default = CrmCampaignsManageRoute;
});