define("alliance-business-suite/services/algolia", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "alliance-business-suite/config/environment", "algoliasearch"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _environment, _algoliasearch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  let AlgoliaService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, (_class = class AlgoliaService extends Ember.Service {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "session", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "client", _descriptor3, this);
      this.client = (0, _algoliasearch.default)(_environment.default.algolia.appId, this.session.data.algoliaKey);
    }

    async searchNew(index, query) {
      let hitsPerPage = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 3;
      let facets = arguments.length > 3 ? arguments[3] : undefined;
      let queries = [];
      let facetFilters = [];

      if (facets) {
        facets.forEach(facet => {
          facets.push(facet.name);
          facetFilters.push(`${facet.name}:${facet.value}`);
        });
      }

      index.forEach(idx => {
        queries.push({
          'indexName': idx,
          'query': query,
          'params': {
            'hitsPerPage': hitsPerPage,
            facets,
            facetFilters
          }
        });
      });
      let results = await this.client.multipleQueries(queries);
      return results;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "client", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = AlgoliaService;
});