define("alliance-business-suite/templates/components/payment/modal-payment-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+h3cbWhK",
    "block": "{\"symbols\":[],\"statements\":[[8,\"shopping-cart\",[],[[\"@preselectedLocation\",\"@close\"],[[34,0],[30,[36,1],[[32,0],\"close\"],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"selectedLocation\",\"action\"]}",
    "moduleName": "alliance-business-suite/templates/components/payment/modal-payment-form.hbs"
  });

  _exports.default = _default;
});