define("alliance-business-suite/models/report-type", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.GROUPS = void 0;
  const {
    Model,
    attr
  } = _emberData.default; // Reports will be grouped as defined below. Groups will be listed in the same order as listed below, and reports will be sorted by translated name.

  const GROUPS = [{
    id: "TRANSACTIONS_GROUP",
    reports: ["CAMPUS_CARD_RECON", "FLOAT_STATUS", "FUNDS_DEPOSIT", "INCOME_STATEMENT", "LOCATION_AND_REVENUE", "MONTHLY_OVERVIEW", "MONTHLY_RECON", "STORE_REVENUE_AND_REWARDS_QB_READY", "REFUNDS", "REWARDS_EARNED", "REWARDS_SPENT", "VALUE_STATION", "VALUE_CENTER_COLLECTIONS", "PEAK_TIMES", "PEAK_TIMES_BY_HOUR", "NEW_USERS", "STORE_OVERVIEW", "DAILY_OVERVIEW"]
  }, {
    id: "EMPLOYEES_GROUP",
    reports: ["EMPLOYEE_VEND", "MACHINE_REMOTE_START_TRACKING", "EMPLOYEE_TIME_CLOCK", "EMPLOYEE_LOGIN", "MANUAL_BALANCE_CHANGE", "EMPLOYEE_SOLD", "EMPLOYEE_SALES_GOAL", "EMPLOYEE_SCHEDULE_STATS"]
  }, {
    id: "MACHINES_GROUP",
    reports: ["AUDIT_ADDITIONAL_CYCLE", "AUDIT_ADDITIONAL_VENDING", "COIN_VAULT", "AUDIT_AVERAGE_VEND", "AUDIT_COMMON_DIAGNOSTIC", "AUDIT_CYCLE_USAGE", "AUDIT_DETAILED_VENDING", "AUDIT_FREE_VENDING", "AUDIT_PRODUCTIVITY", "AUDIT_TURNS", "AUDIT_TOTAL_VENDING", "AUDIT_SPECIAL_VEND", "AUDIT_SECURITY", "AUDIT_OPERATION", "AUDIT_ERROR_DIAGNOSTIC", "PRICING_OVERVIEW", "ERROR_HISTORY", "LIFETIME_OPERATION", "MACHINE_ACTIVITY", "MACHINE_DRAIN_TIME", "MACHINE_OUTAGE", "MACHINE_FILL_TIME", "MACHINE_SETUP", "MACHINE_SECURITY_DATA", "WATER_USAGE_DETAIL"]
  }, {
    id: "POS_GROUP",
    reports: ["CYCLE_COUNT", "DAILY_RECON", "STORE_STOCK", "RETAIL", "SALES_TAX", "WDF_STATUS_REVENUE", "WHAT_SOLD", "POS_STATUS_REVENUE"]
  }, {
    id: "CRM_GROUP",
    reports: ["CRM"]
  }, {
    id: "OTHER_GROUP",
    // This group MUST exist because it's the "default"
    reports: ["FIRELINC"]
  }];
  _exports.GROUPS = GROUPS;

  var _default = Model.extend({
    intl: Ember.inject.service(),
    name: attr("string"),
    isActive: attr("boolean"),
    filterStartDate: attr("boolean"),
    filterEndDate: attr("boolean"),
    filterStartTime: attr("boolean"),
    filterEndTime: attr("boolean"),
    filterRoomId: attr("boolean"),
    filterSingleRoomId: attr("boolean"),
    filterOrganizationId: attr("boolean"),
    filterTimeZone: attr("boolean"),
    filterYear: attr("boolean"),
    filterMonth: attr("boolean"),
    filterMachineId: attr("boolean"),
    filterFundsRootId: attr("boolean"),
    isFavorite: attr("boolean"),
    disabled: attr("boolean"),
    // not used by API but by the reports dropdown to disable emeber-select-dropdown
    filterOperator: attr("boolean"),
    filterGroupId: attr("boolean"),
    translatedName: Ember.computed("name", function () {
      return this.intl.t(`models.report-type.name.${this.name.replace(/\s+/g, "-").toLowerCase()}`);
    }),
    groupName: Ember.computed("id", function () {
      // Find the group this report is listed in, or default to "OTHER_GROUP".
      let reportId = this.id;
      let group = GROUPS.find(groupItem => {
        return groupItem.reports.includes(reportId);
      });
      return !Ember.isNone(group) ? group.id : "OTHER_GROUP";
    })
  });

  _exports.default = _default;
});