define("alliance-business-suite/components/employees/roles/role-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notifications: Ember.inject.service(),
    access: Ember.inject.service(),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    showCreateForm: false,
    securityFunctionalAreas: Ember.computed('selectedRole', 'selectedRole.id', 'selectedGroup', 'selectedGroup.securityFunctionalAreas', 'access.isLaundromatOrg', function () {
      this.send('climbTree'); // Do not return route charts for laundromats

      if (Ember.get(this, 'access.isLaundromatOrg') && Ember.get(this, 'selectedGroup.securityFunctionalAreas')) {
        return Ember.get(this, 'selectedGroup.securityFunctionalAreas').filter(item => {
          return Ember.get(item, 'isRouteChart') ? false : true;
        });
      } else {
        return Ember.get(this, 'selectedGroup.securityFunctionalAreas');
      }
    }),
    groups: Ember.computed(function () {
      // List of functional areas in center column
      let groups = this.model.groups; // remove groups if OPL

      if (this.access.isOpl) {
        let removeGroups = ['BILLING', 'RETAIL', 'CUSTOMERS', 'CRM', 'MARKETING'];
        return groups.filter(g => {
          if (removeGroups.indexOf(g.lookupKey) == -1) {
            return g;
          }
        });
      } else {
        return groups;
      }
    }),
    filteredRoles: Ember.computed('model.roles.[]', function () {
      let roles = this.store.peekAll('securityRole');
      let excludedRoleNames = ['SUSPEND_EMPLOYEE', 'KIOSK_GUEST'];
      return roles.filter(role => {
        return !excludedRoleNames.includes(role.name);
      });
    }),

    didInsertElement() {
      this._super(...arguments);

      if (this.sRole) {
        let queryRole = this.store.peekRecord('security-role', this.sRole);

        if (queryRole) {
          this.send('selectRole', queryRole);
        } else {
          this.send('selectRole', Ember.get(this, 'model.roles.firstObject'));
        }
      } else {
        this.send('selectRole', Ember.get(this, 'model.roles.firstObject'));
      }
    },

    isSavingRole: false,
    disabledKeys: [],
    dirtyRole: Ember.computed('selectedRole.{hasDirtyRelations,hasDirtyAttributes}', function () {
      let selectedRole = this.selectedRole || null;

      if (selectedRole && selectedRole.isSystemDefault) {
        return false; // System roles cannot be edited
      }

      if (selectedRole) {
        let {
          hasDirtyRelations,
          hasDirtyAttributes
        } = selectedRole;
        return hasDirtyAttributes == true || hasDirtyRelations == true;
      }

      return false;
    }),
    actions: {
      selectRole(role) {
        if (role) {
          // If leaving a dirty role we need to rollback changes or abort
          if (Ember.get(this, 'dirtyRole')) {
            if (!confirm(this.intl.t('unsaved_changes_discard'))) {
              return;
            } else {
              Ember.get(this, 'selectedRole').rollback();
            }
          }

          this.set('sRole', role.get('id'));
          this.setProperties({
            selectedRole: role,
            updateRoleName: Ember.get(role, 'name') // isEditingRole: false

          });

          if (!Ember.get(this, 'selectedGroup')) {
            this.send('selectGroup', Ember.get(this, 'groups.firstObject'));
          }
        }
      },

      selectGroup(group) {
        Ember.set(this, 'selectedGroup', group);
      },

      climbTree(item) {
        let selectedRoleSecurityFunctionalAreas = Ember.get(this, 'selectedRole.securityFunctionalAreas');
        let allSecurityFunctionalAreas = Ember.get(this, 'selectedGroup.securityFunctionalAreas'); // This is the list of all permissions for the selected functional area

        let selectedRole = Ember.get(this, 'selectedRole');

        function climbTree(item, parentEnabled) {
          // We know the parent "item", so find any children and set their values
          if (parentEnabled) {
            Ember.set(item, 'isDisabled', false);
            parentEnabled = selectedRoleSecurityFunctionalAreas.findBy('id', Ember.get(item, 'id')) ? true : false;
          } else {
            Ember.set(item, 'isDisabled', true);

            if (!selectedRole.isSystemDefault) {
              selectedRoleSecurityFunctionalAreas.removeObject(item);
            }

            parentEnabled = false;
          } // Loop through descendents based on parentId


          let children = allSecurityFunctionalAreas.filterBy('parentId', Ember.get(item, 'id'));
          children.forEach(item => {
            climbTree(item, parentEnabled);
          });
        }

        if (allSecurityFunctionalAreas && selectedRoleSecurityFunctionalAreas) {
          // We never change the display of the current item.
          // If current item is CHECKED, we will ENABLE CHILDREN
          //   Children's children are set based on children's settings
          // If current item is UNCHECKED, we will DISABLE and UNCHECK CHILDREN and all THEIR CHILDREN
          if (!item) {
            allSecurityFunctionalAreas.filterBy('parentId', undefined).forEach(i => {
              climbTree(i, true); // We always start enabled
            });
          } else {
            climbTree(item, true);
          }
        }
      },

      save() {
        if (Ember.get(this, 'can-access').canAccess('EMPLOYEES_MANAGE_SECURITY_ROLES')) {
          Ember.set(this, 'isSavingRole', true);
          let notifications = Ember.get(this, 'notifications');
          let role = Ember.get(this, 'selectedRole');
          role.set('name', Ember.get(this, 'updateRoleName'));
          role.save().then(response => {
            Ember.set(this, 'isSavingRole', false);
            notifications.success(this.intl.t('role_successfully_saved'), {
              clearDuration: 5000,
              autoClear: true
            });
          }).catch(err => {
            console.log('Error saving:', err); // eslint-disable-line no-console

            Ember.set(this, 'isSavingRole', false);
            notifications.error(this.intl.t('role_error_saving'), {
              clearDuration: 5000,
              autoClear: true
            });
          });
        }
      },

      closeModal() {
        Ember.set(this, 'showCreateForm', false);
      },

      openModal() {
        Ember.set(this, 'showCreateForm', true);
      },

      checkboxChanged(securityFunctionalArea, e) {
        // Checked gets added to the current role's securityFunctionalAreas. Unchecked gets removed.
        let self = this;
        let sfa = Ember.get(this, 'selectedRole.securityFunctionalAreas');
        let allGroupPermissions = Ember.get(this, 'selectedGroup.securityFunctionalAreas');

        if (e.target.checked) {
          sfa.pushObject(securityFunctionalArea);
        } else {
          sfa.removeObject(securityFunctionalArea);
        }

        this.send('climbTree', securityFunctionalArea);
      }

    }
  });

  _exports.default = _default;
});