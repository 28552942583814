define("alliance-business-suite/helpers/talert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.translatedAlert = translatedAlert;

  /**
   * Displays an alert box with a localized message based on the data.
   *
   * @param  {object} owner An object containing an 'intl' property whose value is
   *                        the service used to do the translation
   * @param  {string} key   The translation key
   * @param  {object} data  An object whose properties are the variables to use
   *                        for replacement in the message defined by `key`
   * @param  {string} style The style of the alert: `alert`, `confirm`, `prompt`.
   *                        Defaults to `alert`.
   *
   * @private
   */
  function translatedAlert(owner, key, data, style) {
    let message;

    try {
      message = Ember.get(owner, 'intl').t(key, data).toString();
    } catch (e) {
      console.error(e);
      message = `An error occurred while translating the error message: ${key}`;
    }

    return style === 'confirm' ? confirm(message) : style === 'prompt' ? prompt(message) : alert(message);
  }
  /**
   * Displays an alert box with a translated message.
   *
   * NOTE: This will display an alert box every time this is rendered. This may
   *       not be a useful helper, but I wanted to experiment with helpers that
   *       had side-effects (like the `log` helper), since I needed the
   *       `translatedAlert` function. Ignore or delete this if isn't not useful
   *       and move `translatedAlert` into a `utils` folder. [twl 20.Apr.17]
   *
   * @public
   */


  var _default = Ember.Helper.extend({
    /**
     * The service used to do the translation.
     *
     * @protected
     */
    intl: Ember.inject.service(),

    /**
     * Displays an alert box with the translated message.
     *
     * @param {string} key   The translation key
     * @param {object} data  An object whose properties are the variables to use
     *                       for replacement in the message defined by `key`
     * @param {string} style The style of the alert: `alert`, `confirm`.
     *                       Defaults to `alert`.
     *
     * @public
     */
    compute(_ref, _ref2) {
      let [key, data] = _ref;
      let {
        style
      } = _ref2;
      return translatedAlert(this, key, data, style);
    },

    /**
     * Recompute whenever the locale changes.
     *
     * @private
     */
    _recomputeOnLocaleChange: Ember.observer('intl.locale', function () {
      this.recompute();
    })
  });

  _exports.default = _default;
});