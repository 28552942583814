define("alliance-business-suite/components/marketing/campaigns/schedule-date", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "moment", "alliance-business-suite/utils/crm-schedule-utils", "alliance-business-suite/utils/timezones"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _moment, _crmScheduleUtils, _timezones) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18;

  let MarketingCampaignsScheduleDateComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember._tracked, _dec18 = Ember._tracked, _dec19 = Ember._action, _dec20 = Ember._action, _dec21 = Ember._action, _dec22 = Ember._action, _dec23 = Ember._action, _dec24 = Ember._action, _dec25 = Ember._action, _dec26 = Ember._action, _dec27 = Ember._action, _dec28 = Ember._action, (_class = class MarketingCampaignsScheduleDateComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "ajax", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor3, this);
      (0, _defineProperty2.default)(this, "scheduleOptions", _crmScheduleUtils.SCHEDULE_TYPE);
      (0, _defineProperty2.default)(this, "frequencyOptions", _crmScheduleUtils.FREQUENCY_OPTIONS);
      (0, _defineProperty2.default)(this, "endOptions", _crmScheduleUtils.END_OPTIONS);
      (0, _defineProperty2.default)(this, "onOptions", _crmScheduleUtils.ON_OPTIONS);
      (0, _defineProperty2.default)(this, "dayOptions", _crmScheduleUtils.DAY_OPTIONS);
      (0, _defineProperty2.default)(this, "timeZones", _timezones.TIME_ZONES);
      (0, _defineProperty2.default)(this, "minStartDate", (0, _moment.default)().toDate());
      (0, _defineProperty2.default)(this, "minEndDate", (0, _moment.default)().add(1, 'days').toDate());
      (0, _initializerDefineProperty2.default)(this, "isDeleting", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "isSaving", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "selectedSchedule", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "selectedTimeZone", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "selectedFrequency", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "selectedEndDate", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "dayOfWeek", _descriptor10, this);
      (0, _initializerDefineProperty2.default)(this, "dayOfMonth", _descriptor11, this);
      (0, _initializerDefineProperty2.default)(this, "selectedOn", _descriptor12, this);
      (0, _initializerDefineProperty2.default)(this, "selectedDay", _descriptor13, this);
      (0, _initializerDefineProperty2.default)(this, "startDate", _descriptor14, this);
      (0, _initializerDefineProperty2.default)(this, "startTime", _descriptor15, this);
      (0, _initializerDefineProperty2.default)(this, "endDate", _descriptor16, this);
      (0, _initializerDefineProperty2.default)(this, "iteration", _descriptor17, this);
      (0, _initializerDefineProperty2.default)(this, "hasFrequencyError", _descriptor18, this);
    }

    initialLoad() {
      this.buildIteration();
      this.buildSelectedTimeZone();
      this.buildSelectedFrequency();
    }

    guessUserTimezone(context) {
      let browserTZ = _moment.default.tz.guess();

      let selectedUTC = _timezones.TIME_ZONES[5];

      _timezones.TIME_ZONES.forEach(function (tz, index) {
        let utcArr = tz.utc;
        utcArr.forEach(function (utc) {
          if (utc == browserTZ) {
            selectedUTC = _timezones.TIME_ZONES[index];
          }
        });
      });

      return selectedUTC;
    }

    buildSelectedTimeZone() {
      let timeZone;

      if (this.args.activeCampaign.get('timeZone')) {
        for (let i = 0; i < _timezones.TIME_ZONES.length; i++) {
          if (_timezones.TIME_ZONES[i].utc.indexOf(this.args.activeCampaign.get('timeZone')) !== -1) {
            timeZone = _timezones.TIME_ZONES[i];
          }
        }
      }

      if (!timeZone) {
        timeZone = this.guessUserTimezone();
      }

      this.selectedTimeZone = timeZone;
    }

    buildSelectedFrequency() {
      let frequency = _crmScheduleUtils.FREQUENCY_OPTIONS[0];
      let iteration = this.iteration;
      let campaignInterval = this.args.activeCampaign.get('interval');

      if (campaignInterval && campaignInterval.includes('mons')) {
        campaignInterval = campaignInterval.replace('mons', 'months');
      }

      if (campaignInterval) {
        for (let i = 0; i < _crmScheduleUtils.FREQUENCY_OPTIONS.length; i++) {
          if (campaignInterval.indexOf(_crmScheduleUtils.FREQUENCY_OPTIONS[i].value) !== -1) {
            if (_crmScheduleUtils.FREQUENCY_OPTIONS[i].value == 'days' && iteration % 7 === 0) {
              frequency = _crmScheduleUtils.FREQUENCY_OPTIONS[1]; // console.log('ERROR HERE?', iteration)

              this.iteration = iteration / 7;
            } else {
              frequency = _crmScheduleUtils.FREQUENCY_OPTIONS[i];
            }
          }
        }
      }

      this.selectedFrequency = frequency;
    }

    buildIteration() {
      let iteration = 1;

      if (this.args.activeCampaign.get('interval')) {
        let split = this.args.activeCampaign.get('interval').split(' ');

        if (split[0] && !isNaN(split[0])) {
          iteration = split[0];
        }
      }

      this.iteration = iteration;
    }

    close() {
      this.args.close();
    }

    setStartDate(value) {
      this.startDate = value;
    }

    setEndDate(value) {
      this.endDate = value;
    }

    setSelectedEndDate(value) {
      if (value.id == 'never') {
        this.endDate = null;
      }

      this.selectedEndDate = value;
    }

    updateSelectedFrequency(value) {
      let projectedEnd = (0, _moment.default)(this.startDate).add(2, value.value);
      let invalidEnd = projectedEnd.isAfter((0, _moment.default)(this.endDate));

      if (this.selectedEndDate.id != 'never' && invalidEnd) {
        this.hasFrequencyError = true;
      } else {
        this.hasFrequencyError = false;
        this.selectedFrequency = value;
      }
    }

    updateSelectedSchedule(value) {
      this.hasFrequencyError = false;
      this.selectedSchedule = value;
    }

    startDayDisabled(value) {
      let date = (0, _moment.default)(value);
      let dayOfWeek = this.dayOfWeek;
      let selectedFrequency = this.selectedFrequency.id; // Only allow dayOfWeek with weekly recurring schedules

      if (selectedFrequency == 'weekly' && date.weekday() !== dayOfWeek) {
        return true;
      }
    }

    deleteSchedule() {
      let campaignId = this.args.activeCampaign.get('id');
      let payload = {
        startDate: null
      };
      this.isDeleting = true;
      this.ajax.request(`/campaigns/${campaignId}/schedule`, {
        method: 'PATCH',
        data: payload
      }).then(results => {
        if (results && results.data && results.data.attributes && !results.data.attributes.nextRunDate) {
          results.data.attributes.nextRunDate = null;
        }

        this.store.pushPayload(results);
        this.isDeleting = false;
        this.close();
      }).finally(() => {
        this.isDeleting = false;
      });
    }

    createSchedule() {
      let campaignId = this.args.activeCampaign.get('id');
      let startTime = this.startTime || '00:00:00';

      if (typeof startTime !== 'string') {
        startTime = (0, _moment.default)(`${startTime.hours}:${startTime.minutes}`, "HH:mm:ss").format('HH:mm');
      }

      let payload = {
        isOneTimeOnly: this.selectedSchedule.value,
        interval: `${this.iteration} ${this.selectedFrequency.value}`,
        startDate: (0, _moment.default)(this.startDate).format('YYYY-MM-DD'),
        startTime: startTime,
        timeZone: this.selectedTimeZone.utc[0],
        endDate: this.endDate ? (0, _moment.default)(this.endDate).format('YYYY-MM-DD') : null
      };
      this.isSaving = true;
      this.ajax.request(`/campaigns/${campaignId}/schedule`, {
        method: 'PATCH',
        data: payload
      }).then(results => {
        this.store.pushPayload(results);
        this.isSaving = false;
        this.args.close();
      }, function () {
        this.isSaving = false;
      });
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isDeleting", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isSaving", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedSchedule", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.activeCampaign.get('isOneTimeOnly') ? _crmScheduleUtils.SCHEDULE_TYPE[0] : _crmScheduleUtils.SCHEDULE_TYPE[1];
    }
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedTimeZone", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedFrequency", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedEndDate", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.activeCampaign.get('endDate') ? _crmScheduleUtils.END_OPTIONS[1] : _crmScheduleUtils.END_OPTIONS[0];
    }
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dayOfWeek", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return (0, _moment.default)(this.startDate).weekday();
    }
  }), _descriptor11 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dayOfMonth", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor12 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedOn", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return _crmScheduleUtils.ON_OPTIONS[0];
    }
  }), _descriptor13 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedDay", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return _crmScheduleUtils.DAY_OPTIONS[0];
    }
  }), _descriptor14 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "startDate", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return (0, _moment.default)(this.args.activeCampaign.get('startDate')).format('llll');
    }
  }), _descriptor15 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "startTime", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.activeCampaign.get('startTime');
    }
  }), _descriptor16 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "endDate", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return (0, _moment.default)(this.args.activeCampaign.get('endDate')).format('llll');
    }
  }), _descriptor17 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "iteration", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "hasFrequencyError", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "initialLoad", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "initialLoad"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "close", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setStartDate", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "setStartDate"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setEndDate", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "setEndDate"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setSelectedEndDate", [_dec23], Object.getOwnPropertyDescriptor(_class.prototype, "setSelectedEndDate"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "updateSelectedFrequency", [_dec24], Object.getOwnPropertyDescriptor(_class.prototype, "updateSelectedFrequency"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "updateSelectedSchedule", [_dec25], Object.getOwnPropertyDescriptor(_class.prototype, "updateSelectedSchedule"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "startDayDisabled", [_dec26], Object.getOwnPropertyDescriptor(_class.prototype, "startDayDisabled"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "deleteSchedule", [_dec27], Object.getOwnPropertyDescriptor(_class.prototype, "deleteSchedule"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "createSchedule", [_dec28], Object.getOwnPropertyDescriptor(_class.prototype, "createSchedule"), _class.prototype)), _class));
  _exports.default = MarketingCampaignsScheduleDateComponent;
});