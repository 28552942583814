define("alliance-business-suite/components/form-ui/time-span", ["exports", "alliance-business-suite/mixins/init-property-names"], function (_exports, _initPropertyNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function rollHours(value) {
    // If we add hours to a time that rolls it over to the next day, reset the value
    return value >= 24 ? value - 24 : value;
  }

  var _default = Ember.Component.extend(_initPropertyNames.default, {
    tagName: 'div',
    startTime: Ember.computed('startHour', 'startMinute', {
      get(key) {
        let hours = Ember.get(this, 'startHour') || 0;
        let minutes = Ember.get(this, 'startMinute') || 0;
        return {
          hours,
          minutes
        };
      }

    }),
    overnight: Ember.computed('startTime.@each', 'durationHours', function () {
      let startHours = Ember.get(this, 'startTime.hours');
      let startMinutes = Ember.get(this, 'startTime.minutes');
      let duration = Ember.get(this, 'durationHours');
      let totalTime = startHours + startMinutes / 60 + duration;

      if (totalTime >= 24) {
        return true;
      } else {
        return false;
      }
    }),
    updateValues: Ember.observer('startTime.@each', 'durationHours', function () {
      let {
        hours,
        minutes
      } = Ember.get(this, 'startTime');
      let duration = Number(Ember.get(this, 'durationHours')); // Update action, if provided

      if (Ember.get(this, 'params.changeTime')) {
        Ember.get(this, 'params.changeTime')({
          startHour: hours,
          startMinute: minutes,
          durationHours: duration
        });
      } // Update each property


      let object = Ember.get(this, 'object');
      Ember.get(this, 'update')(object, this.properties.startHour.key, hours);
      Ember.get(this, 'update')(object, this.properties.startMinute.key, minutes);
      Ember.get(this, 'update')(object, this.properties.durationHours.key, duration);
    }),
    endTime: Ember.computed('startTime', 'startMinute', 'durationHours', {
      get(key) {
        // Get the start time
        let hours = Ember.get(this, 'startTime.hours');
        let minutes = Ember.get(this, 'startTime.minutes');
        let durationHours = Number(Ember.get(this, 'durationHours')) || 0; // Add the duration

        let endHour = rollHours(hours + durationHours);
        let ampm = "AM";

        if (Ember.get(this, 'params.hour12')) {
          // Change the hours
          if (endHour > 24) {
            endHour -= 24;
          }

          if (endHour >= 12) {
            ampm = "PM";
          }

          if (endHour > 12) {
            endHour -= 12;
          }

          if (endHour == 0) {
            endHour = 12;
          }

          return `${endHour}:${minutes.toString().padStart(2, "0")} ${ampm}`; // TODO: Use moment for this, as used elsewhere?
        }

        return `${endHour.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
      }

    })
  });

  _exports.default = _default;
});