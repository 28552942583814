define("alliance-business-suite/templates/components/form-ui/special-vend-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "I3y7bB2u",
    "block": "{\"symbols\":[\"ui\",\"i\",\"index\"],\"statements\":[[6,[37,5],[[32,0,[\"showComponent\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"form-ui\",[],[[\"@object\",\"@model\",\"@update\",\"@formSettings\",\"@display\",\"@selectOptions\",\"@machineProgram\"],[[32,0,[\"object\"]],[32,0,[\"object\"]],[32,0,[\"update\"]],[32,0,[\"formSettings\"]],\"normal\",[32,0,[\"selectOptions\"]],[32,0,[\"machineProgram\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,0,[\"params\",\"uniqueIndex\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,1],[[32,0,[\"params\",\"componentName\"]]],[[\"object\",\"params\",\"update\",\"formSettings\",\"machineProgram\",\"selectOptions\",\"indexValue\",\"index\",\"number\"],[[32,0,[\"object\"]],[32,0,[\"params\"]],[32,0,[\"update\"]],[32,0,[\"formSettings\"]],[32,0,[\"machineProgram\"]],[32,0,[\"selectOptions\"]],[32,2],[32,2],[30,[36,0],[[32,3],1],null]]]]],[2,\"\\n\"]],\"parameters\":[2,3]}]]],[2,\"  \"]],\"parameters\":[1]}]]],[2,\"\\n\\n  \"],[8,\"form-ui/dev-tools\",[],[[\"@outputs\"],[[30,[36,4],null,[[\"display\",\"currencySymbol\",\"currencySymbolPosition\",\"decimals\",\"currencyDivideBy\",\"divideBy\",\"thousandsSymbol\",\"decimalSymbol\",\"isCelsius\"],[[32,0,[\"formSettings\",\"display\"]],[32,0,[\"formSettings\",\"currencySymbol\"]],[32,0,[\"formSettings\",\"currencySymbolPosition\"]],[32,0,[\"formSettings\",\"decimals\"]],[32,0,[\"formSettings\",\"currencyDivideBy\"]],[32,0,[\"formSettings\",\"divideBy\"]],[32,0,[\"formSettings\",\"thousandsSymbol\"]],[32,0,[\"formSettings\",\"decimalSymbol\"]],[32,0,[\"formSettings\",\"isCelsius\"]]]]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"add\",\"component\",\"-track-array\",\"each\",\"hash\",\"if\"]}",
    "moduleName": "alliance-business-suite/templates/components/form-ui/special-vend-container.hbs"
  });

  _exports.default = _default;
});