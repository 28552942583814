define("alliance-business-suite/templates/components/billing/billing-history", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "R2JvVSVb",
    "block": "{\"symbols\":[\"@transactions\"],\"statements\":[[10,\"section\"],[12],[2,\"\\n\"],[6,[37,1],[[35,0]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"ui-loading-throb\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"models-table\",[],[[\"@data\",\"@columns\",\"@pageSize\",\"@multipleColumnsSorting\",\"@showComponentFooter\",\"@showColumnsDropdown\",\"@useFilteringByColumns\",\"@showGlobalFilter\",\"@doFilteringByHiddenColumns\",\"@useNumericPagination\",\"@filteringIgnoreCase\",\"@collapseNumPaginationForPagesCount\",\"@showPageSize\"],[[32,1],[32,0,[\"columns\"]],[32,0,[\"pageSize\"]],[32,0,[\"multipleColumnsSorting\"]],[32,0,[\"showComponentFooter\"]],[32,0,[\"showColumnsDropdown\"]],[32,0,[\"useFilteringByColumns\"]],[32,0,[\"showGlobalFilter\"]],[32,0,[\"doFilteringByHiddenColumns\"]],[32,0,[\"useNumericPagination\"]],[32,0,[\"filteringIgnoreCase\"]],[32,0,[\"collapseNumPaginationForPagesCount\"]],[32,0,[\"showPageSize\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"isLoading\",\"if\"]}",
    "moduleName": "alliance-business-suite/templates/components/billing/billing-history.hbs"
  });

  _exports.default = _default;
});