define("alliance-business-suite/routes/machines", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "alliance-business-suite/routes/_secureRoute"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _secureRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  let MachinesRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = class MachinesRoute extends _secureRoute.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "session", _descriptor2, this);
      (0, _defineProperty2.default)(this, "allowAccess", 'MACHINES_VIEW_PAGE');
    }

    get subnavTabs() {
      return this.access.subnavTabs(this.routeName);
    } // do not delete, there is a bug with linkTo for machines.programs.manage
    // that requires this beforeModel hook invokes
    // even though it invokes nothing


    beforeModel(transition) {
      // do not delete: see note above
      super.beforeModel(...arguments); // We need to pre-load these models before we get to machine programming

      return Ember.RSVP.hash({
        machineAuditType: this.store.findAll('machine-audit-type'),
        machineType: this.store.findAll('machine-type')
      });
    }

    setupController(controller, model) {
      super.setupController(controller, model);
      controller.subnavTabs = this.subnavTabs;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = MachinesRoute;
});