define("alliance-business-suite/components/form-ui/default-modifier", ["exports", "alliance-business-suite/mixins/init-property-names"], function (_exports, _initPropertyNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_initPropertyNames.default, {
    defaultModifier: Ember.computed('reversing', 'antiWrinkle', function () {
      let reversing = this.reversing;
      let antiWrinkle = this.antiWrinkle;
      if (!reversing && !antiWrinkle) return 1;
      if (!reversing && antiWrinkle) return 2;
      if (reversing && !antiWrinkle) return 3;
      if (reversing && antiWrinkle) return 4;
    }),
    actions: {
      updateValues(obj, prop, value) {
        let object = this.object;

        switch (value) {
          case 1:
            // None
            // Do nothing
            this.update(object, Ember.get(this, 'properties.reversing.key'), false);
            this.update(object, Ember.get(this, 'properties.antiWrinkle.key'), false);
            break;

          case 2:
            // Anti-Wrinkle
            this.update(object, Ember.get(this, 'properties.reversing.key'), false);
            this.update(object, Ember.get(this, 'properties.antiWrinkle.key'), true);
            break;

          case 3:
            // Reversing
            this.update(object, Ember.get(this, 'properties.reversing.key'), true);
            this.update(object, Ember.get(this, 'properties.antiWrinkle.key'), false);
            break;

          case 4:
            // Both
            this.update(object, Ember.get(this, 'properties.reversing.key'), true);
            this.update(object, Ember.get(this, 'properties.antiWrinkle.key'), true);
            break;
        }
      }

    }
  });

  _exports.default = _default;
});