define("alliance-business-suite/components/form-ui/steps/internal-external-supply", ["exports", "alliance-business-suite/mixins/init-property-names"], function (_exports, _initPropertyNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_initPropertyNames.default, {
    init() {
      this._super(...arguments);

      if (["MGD_M7"].includes(this.machineProgram._machineAuditType)) {
        this.compartmentLabel1 = "compartment_1";
        this.compartmentLabel2 = "compartment_2";
        this.compartmentLabel3 = "compartment_3";
        this.compartmentLabel4 = "compartment_4";
      } else {
        this.compartmentLabel1 = "compartment_1_prewash";
        this.compartmentLabel2 = "compartment_2_wash";
        this.compartmentLabel3 = "compartment_3_detergent_bleach";
        this.compartmentLabel4 = "compartment_4_fabric_softener";
      }
    }

  });

  _exports.default = _default;
});