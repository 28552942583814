define("alliance-business-suite/components/forgot-password-section", ["exports", "alliance-business-suite/config/environment", "ember-cp-validations"], function (_exports, _environment, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    accountEmail: [// validator('presence', {
    // presence: true
    // message: 'You need an email bro'
    // }),
    (0, _emberCpValidations.validator)('format', {
      type: 'email',
      message: 'Please enter a valid email'
    })]
  });

  var _default = Ember.Component.extend(Validations, {
    access: Ember.inject.service(),
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    notifications: Ember.inject.service(),
    email: null,
    actions: {
      async resetPassword() {
        Ember.set(this, 'isLoading', true);
        let email = this.accountEmail;
        await this.ajax.request('/auth/resetPassword', {
          method: 'POST',
          data: JSON.stringify({
            email,
            brandLookupKey: _environment.default.brand
          })
        }).then(response => {
          this.notifications.info(this.intl.t('forgot_password_instructions'), {
            autoClear: true,
            clearDuration: 60000
          });
          return response;
        }).catch((error
        /* , jqXHR */
        ) => {
          Ember.set(this, 'hasError', true);
          this.notifications.error(this.intl.t('please_try_again'), {
            autoClear: true,
            clearDuration: 6000
          });
          Ember.run.later(() => {
            Ember.set(this, 'hasError', false);
          }, 1200);
          return error;
        });
        Ember.set(this, 'isLoading', false);
      }

    }
  });

  _exports.default = _default;
});