define("alliance-business-suite/components/wash-alert/wa-listing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const STATUS_LEVELS = {
    'AVAILABLE': 'available',
    'COMPLETE': 'complete',
    'DOOR_OPEN': 'unknown',
    'ERROR': 'error',
    'IN_USE': 'in-use',
    'LUCKY_CYCLE': 'in-use',
    'READY_TO_START': 'available',
    'RESERVED': 'in-use',
    'UNAVAILABLE': 'error',
    'UNKNOWN': 'unknown'
  };

  var _default = Ember.Component.extend({
    tagName: '',
    // a machine status has changed, bubble that up to the board
    statusUpdate: Ember.observer('status', function () {
      let machine = Ember.get(this, 'model');
      let status = Ember.get(this, 'status');
      this.machineStatusChange(machine, status);
    }),
    machineStatusClass: Ember.computed('status', function () {
      let status = Ember.get(this, 'status');
      return STATUS_LEVELS[status || 'LOADING'];
    }),
    statusColor: Ember.computed('status', function () {
      let status = STATUS_LEVELS[Ember.get(this, 'status') || 'LOADING']; // green

      if (status == 'available') {
        return ['#61c26d', '#359742'];
      } // yellow


      if (status == 'in-use') {
        return ['#f5b255', '#c98729'];
      } // red


      if (status == 'unavailable' || status == 'unknown' || status == 'error') {
        return ['#D0011B', '#a20014'];
      } // blue


      if (status == 'complete') {
        return ['#4a9cdf', '#2671b4'];
      } // gray


      return ['#c6cccf', '#999d9e'];
    }),
    machineNumber: Ember.computed('model', function () {
      let machineNumber = Ember.get(this, 'model.machineNumber');
      return `#${machineNumber.replace(/^0+/, '')}`;
    }),
    isActive: Ember.computed('machinesOnBoard.[]', function () {
      let machinesOnBoard = Ember.get(this, 'machinesOnBoard');
      let machineId = Ember.get(this, 'model.id');

      if (machinesOnBoard.length > 0 && machinesOnBoard.indexOf(machineId) != -1) {
        return 'active';
      }
    }),
    // during combo, we filter out certain machines during selection
    showItem: Ember.computed('filterType', function () {
      let filterType = Ember.get(this, 'filterType');
      let model = Ember.get(this, 'model');
      let machineType = model.get('machineType.id');

      if (!filterType) {
        return true;
      } // if (filterType == 'washer') {
      //   console.log('show only washers!');
      // }


      if (filterType == 'dryer') {
        if (machineType == 33 || machineType == 37 || machineType == 42) {
          return true;
        }
      }
    }),
    machineType: Ember.computed('model', function () {
      let model = Ember.get(this, 'model');
      let machineType = model.get('machineType.id'); // tumbler 30 lbs stock lower: 34;
      // tumbler 30 lbs stock upper: 35;

      if (machineType == 34) {
        return 'lower';
      }

      if (machineType == 35) {
        return 'upper';
      }
    }),
    actions: {
      addMachine(machine, status) {
        this.addMachine(machine, status);
      },

      removeMachine(machine) {
        this.removeMachine(machine);
      }

    }
  });

  _exports.default = _default;
});