define("alliance-business-suite/services/firebase-commands", ["exports", "firebase/app", "firebase/database", "alliance-business-suite/config/environment"], function (_exports, _app, _database, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    notifications: Ember.inject.service('notifications'),
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    firebaseCommands: Ember.inject.service('firebase-commands'),
    programsInProgress: Ember.A(),
    access: Ember.inject.service(),
    logr: Ember.inject.service(),
    machines: Ember.inject.service(),
    machinePrograms: Ember.inject.service(),
    devtools: Ember.inject.service(),
    fetchProgramList: [],
    sendProgramList: [],

    init() {
      this._super(...arguments);

      if (!_app.default.apps.length) {
        _app.default.initializeApp(_environment.default.firebase); //eslint-disable-next-line


        console.log('firebase app not init\'d, going to init in fb-cmds');
      }
    },

    // old version
    sendProgramCommand(context, machineId, commandId, notifications, intl, statusId, testFunction) {
      let fbBinding = _app.default.database().ref(`commands/${commandId}`); // let statusId = context.machines.addStatus("send", {
      //   machineId,
      //   commandId,
      //   status: "sending"
      // });


      fbBinding.on('value', function (snapshot) {
        // eslint-disable-next-line no-console
        console.log(`Sending program: ${commandId}`);
        let val = snapshot.val(); // console.log('fbBinding.on(value) -> snapshot.val() is:', val);

        if (val) {
          if (val.status == 'QUEUED') {// do nothing
          } else if (val.status == 'COMPLETED') {
            let successText = intl.t('program_send_response_success');
            context.machines.updateStatus(statusId, "complete");
            notifications.success(successText, {
              clearDuration: 5000,
              autoClear: true
            });

            if (testFunction) {
              testFunction();
            }

            fbBinding.off();
          } else {
            console.error('A: Sending program failed.', val); // eslint-disable-line no-console

            context.machines.updateStatus(statusId, "error"); // context.logr.captureException(new Error('Sending program failed'));

            let errorText = intl.t('program_send_response_error');
            notifications.error(errorText, {
              autoClear: false
            });
            context.devtools.speak("failed.mp3");
            fbBinding.off();
          }
        } else {
          console.error('B: Sending program failed.', val); // eslint-disable-line no-console

          if (context.logr) {
            context.logr.captureException(new Error('Sending program failed'));
          }

          let errorText = intl.t('program_send_response_error'); // notifications.error(this.get('intl').t('error_send_program'), {

          notifications.error(errorText, {
            autoClear: false
          });
          context.devtools.speak("failed.mp3");
          fbBinding.off();
        }
      });
    },

    // new
    // 010cf968-10d8-4776-9597-8a509ae20511
    sendProgramCommandToEvent(e, eventIdx, commandId, addToEvent) {
      console.log('sendProgramCommand:', commandId);
      let self = this;

      let fbBinding = _app.default.database().ref(`commands/${commandId}`);

      let machinePrograms = addToEvent ? self.machinePrograms : self;

      if (addToEvent) {
        this.machinePrograms.addPendingFirebaseCommandsToEvent(e, eventIdx, commandId);
      }

      fbBinding.on('value', function (snapshot) {
        let val = snapshot.val();
        console.log('fbBinding val', val); // console.log('fbBinding val.status', val.status)

        if (val) {
          if (val.status == 'QUEUED') {// do nothing
          } else if (val.status == 'COMPLETED' || val.status == 'FAILED') {
            machinePrograms.parseFirebaseStatusToUpdateQueuedMachines(e, eventIdx, val); // machinePrograms.removeEventPendingFirebaseCommand(e, eventIdx, commandId)

            fbBinding.off();
          } else {
            console.error('A: Sending program failed.', val); // eslint-disable-line no-console

            machinePrograms.removeEventPendingFirebaseCommand(e, eventIdx, commandId);
            fbBinding.off();
          }
        } else {
          console.log('>>>> going to disconnect!', val);

          if (addToEvent) {
            machinePrograms.removeEventPendingFirebaseCommand(e, eventIdx, commandId);
            machinePrograms.failAllEventMachinesInQueue(e, eventIdx, 'network_error');
            console.error('B: Sending program failed.', val); // eslint-disable-line no-console

            console.log('B2', e, eventIdx);

            if (self.logr) {
              self.logr.captureException(new Error('Sending program failed'));
            }

            fbBinding.off();
          } else {
            console.log('FIREBASE COMMAND NOT FOUND: EXECUTE ORDER 66');
            console.log('e', e);
            console.log('eventIdx', eventIdx);
            console.log('commandId', commandId);
            machinePrograms.noFirebaseCommandFound(e, eventIdx, commandId);
          }
        }
      });
    },

    fetchProgramCommand(context, machineId, commandId, notifications, intl, meta) {
      // eslint-disable-next-line no-console
      console.log(`Fetching ${commandId}`);
      let routing = Ember.get(context, 'router');
      let programsInProgress = Ember.get(context, 'programsInProgress'); // if program is already in progress, don't re-run

      if (programsInProgress.indexOf(machineId) == -1) {
        programsInProgress.pushObject(machineId);
      } else {
        return;
      }

      let statusId = meta.statusId;
      context.machines.updateStatus(statusId, "fetching");

      let fbBinding = _app.default.database().ref(`commands/${commandId}`);

      let successText = intl.t('fetch_program_success');
      let failedText = intl.t('fetch_program_failed');
      fbBinding.on('value', function (snapshot) {
        let val = snapshot.val();
        /* We might want to tell the user the machine and room of the failure. This is what val looks like:
           val = {
            machines[0] = {
              id = 4226,
              success = false,
              room = {
                id = 304,
                roomName = "Excelion - Mohr Point"
              }
            },
            success = false,
            status = "FAILED"
          }
         */

        if (["COMPLETED", "FAILED"].includes(val.status)) {
          switch (val.status) {
            case "COMPLETED":
              notifications.success(`${successText}`, {
                clearDuration: 5000,
                autoClear: true,
                onClick: () => {
                  routing.transitionTo('machines.programs');
                }
              });
              context.devtools.speak("fetch-success.mp3");
              context.machines.updateStatus(statusId, "complete");
              break;

            case "FAILED":
              notifications.error(`${failedText}`, {
                onClick: () => {
                  routing.transitionTo('machines');
                }
              });
              context.devtools.speak("fetch-failed.mp3");
              context.machines.updateStatus(statusId, "failed");
              break;
          }

          programsInProgress.removeObject(machineId);
          fbBinding.off();

          if (meta && meta.testMode) {
            routing.transitionTo('machines.programs.test');
          }
        }
      });
    },

    fetchDetachedProgramCommand(context, machineId, commandId, intl) {
      let programsInProgress = Ember.get(context, 'programsInProgress'); // if program is already in progress, don't re-run

      if (programsInProgress.indexOf(machineId) == -1) {
        programsInProgress.pushObject(machineId);
      } else {
        return;
      }

      let fbBinding = _app.default.database().ref(`commands/${commandId}`);

      fbBinding.on('value', function (snapshot) {
        let val = snapshot.val();

        if (val.status == 'COMPLETED') {
          programsInProgress.removeObject(machineId);
          fbBinding.off();
        }

        if (val.status == 'FAILED') {
          programsInProgress.removeObject(machineId);
          fbBinding.off();
        }
      });
    }

  });

  _exports.default = _default;
});