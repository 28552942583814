define("alliance-business-suite/components/standard-time-format", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    is24HourDisplay: false,
    access: Ember.inject.service(),
    store: Ember.inject.service(),
    userDateTimeFormat: Ember.computed(function () {
      let userId = Ember.get(this, 'access.accessUser.id');
      let user = Ember.get(this, 'store').peekRecord('employee', userId);
      let is12Hour = true;

      if (user.get('timeFormat') === 'HH:mm') {
        is12Hour = false;
      }

      return is12Hour;
    }),
    timeString: Ember.computed('value', function () {
      let value = this.value || '00:00:00';

      if (typeof value === 'string') {
        let timeArray = value.split(" ");
        let hours = parseInt(timeArray[0].split(":")[0]);
        let minutes = parseInt(timeArray[0].split(":")[1]);
        value = {
          hours,
          minutes
        };
      }

      return value;
    })
  });

  _exports.default = _default;
});