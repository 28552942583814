define("alliance-business-suite/templates/components/form-ui/positive-negative-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "m3Gc0xPh",
    "block": "{\"symbols\":[\"ui\"],\"statements\":[[8,\"form-ui\",[],[[\"@object\",\"@formSettings\",\"@formDisplay\"],[[34,0],[32,0,[\"formSettings\"]],\"compact\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[8,[32,1,[\"number\"]],[],[[\"@object\",\"@params\"],[[32,0],[30,[36,3],null,[[\"propertyName\",\"disabled\",\"labelAfter\",\"min\",\"max\"],[\"numberValue\",[35,2],[35,1,[\"labelAfter\"]],[35,1,[\"min\"]],[35,1,[\"max\"]]]]]]],null],[2,\"\\n\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\\n\"],[8,\"form-ui/dev-tools\",[],[[\"@outputs\"],[[30,[36,3],null,[[\"labelAutoTranslated\",\"positive\",\"negative\",\"positiveProperty\",\"negativeProperty\",\"min\",\"max\",\"limit\",\"isDisabled\"],[[35,8],[35,7],[35,6],[35,5,[\"positive\",\"key\"]],[35,5,[\"negative\",\"key\"]],[35,1,[\"min\"]],[35,1,[\"max\"]],[35,4],[35,2]]]]]],null]],\"hasEval\":false,\"upvars\":[\"object\",\"params\",\"isDisabled\",\"hash\",\"limit\",\"properties\",\"negative\",\"positive\",\"labelAutoTranslated\"]}",
    "moduleName": "alliance-business-suite/templates/components/form-ui/positive-negative-number.hbs"
  });

  _exports.default = _default;
});