define("alliance-business-suite/templates/retail/services", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MwestCGu",
    "block": "{\"symbols\":[],\"statements\":[[8,\"products/services-container\",[],[[\"@model\"],[[34,0]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "moduleName": "alliance-business-suite/templates/retail/services.hbs"
  });

  _exports.default = _default;
});