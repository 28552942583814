define("alliance-business-suite/components/billing/subscription-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service(),
    access: Ember.inject.service(),
    showExpirationDate: Ember.computed('item.expirationDate', function () {
      let exp = this.item.expirationDate;

      if (exp === '2100-01-01T00:00:00') {
        return false;
      }

      return exp;
    }),
    expiringSoon: Ember.computed('item', 'access.locationsExpiringSoonIds', function () {
      let {
        item,
        access
      } = this;
      let locationsExpiringSoonIds = access.locationsExpiringSoonIds;
      let roomId = item.id;

      if (locationsExpiringSoonIds.indexOf(roomId) != -1) {
        return true;
      }
    }).readOnly(),
    dateFormat: Ember.computed(function () {
      let userId = Ember.get(this, 'access.accessUser.id');
      let user = Ember.get(this, 'store').peekRecord('employee', userId);
      return user.get('dateFormat');
    }),
    isActiveAgreement: Ember.computed('item', function () {
      let item = this.item;
      return moment(item.expirationDate).isAfter(moment());
    }),
    canUpgrade: Ember.computed('item', function () {
      let {
        item
      } = this;

      if (item.service && item.service.genericProductLookupKey == 'NETWORKING_PLUS_PAYMENT') {
        return true;
      }

      if (item.availableAddons && item.availableAddons.length > 0) {
        return true;
      }
    }),
    itemStatusClass: Ember.computed('item.planId', 'isActiveAgreement', function () {
      if (this.item.planId !== undefined && this.item.planId !== null) {
        return 'newSubscription';
      }

      if (this.isActiveAgreement) {
        return 'activeSubscription';
      }

      return 'subscription';
    }),
    actions: {
      showModal() {
        this.togglePaymentForm();
      },

      showUpgradeModal() {
        this.toggleUpgradeForm();
      },

      addSubscription(location, e) {
        Ember.set(location, 'inCart', true);
      },

      removeSubscription(location, e) {
        Ember.set(location, 'inCart', false);
      },

      selectPlan(planId, location) {
        if (location) {
          Ember.set(location, 'planId', planId);
        } else {
          this.cart.forEach(element => {
            Ember.set(element, 'planId', planId);
          });
        }
      },

      unselectPlan(location) {
        if (location) {
          Ember.set(location, 'planId', null);
        } else {
          this.cart.forEach(element => {
            Ember.set(element, 'planId', null);
          });
        }
      }

    }
  });

  _exports.default = _default;
});