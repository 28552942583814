define("alliance-business-suite/mixins/show-component", ["exports", "alliance-business-suite/utils/show-features"], function (_exports, _showFeatures) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    // eslint-disable-line
    didReceiveAttrs() {
      this._super(...arguments);

      let showComponent = true;
      showComponent = (0, _showFeatures.showMachineFeature)({
        showComponent: showComponent,
        machineProgram: this.machineProgram,
        ifMachine: this.ifMachine,
        notMachine: this.notMachine
      });
      showComponent = (0, _showFeatures.showStepFeature)({
        showComponent: showComponent,
        stepType: this.formMeta?.stepTypeName,
        ifStep: this.params?.ifStep,
        notStep: this.params?.notStep
      });
      Ember.set(this, 'showComponent', showComponent);
    }

  });

  _exports.default = _default;
});