define("alliance-business-suite/components/summary/reports/s-employee-vend", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "alliance-business-suite/utils/report-tables-util"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _reportTablesUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  let SummaryReportsSEmployeeVendComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, (_class = class SummaryReportsSEmployeeVendComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor3, this);
    }

    get translatedColumns() {
      return [{
        name: 'user_login',
        valuePath: 'userLoginName',
        width: 225,
        isFixed: 'left'
      }, {
        name: 'first_name',
        valuePath: 'firstName',
        width: 180
      }, {
        name: 'last_name',
        valuePath: 'lastName',
        width: 180
      }, {
        name: 'machine_start_threshold',
        valuePath: 'threshold',
        width: 180
      }, {
        name: 'machine_start_total',
        valuePath: 'total',
        width: 180
      }, {
        name: 'machine_start_exceeded_count',
        valuePath: 'exceeded',
        width: 180
      }, {
        name: 'date_time',
        valuePath: 'threshholdBreachDate',
        width: 180
      }];
    }

    get rowsWithChildren() {
      let userRecords = this.args.model.get('records');
      let rowData = [];
      userRecords.forEach(userRecord => {
        let breachDate = "";

        if (userRecord.threshholdBreachDate) {
          breachDate = moment(userRecord.threshholdBreachDate).format(`${_reportTablesUtil.formatDateTimeString.call(this)}`);
        }

        rowData.push({
          userLoginName: userRecord.userLoginName,
          firstName: userRecord.firstName,
          lastName: userRecord.lastName,
          threshold: userRecord.threshold,
          total: userRecord.total,
          exceeded: userRecord.exceeded,
          threshholdBreachDate: breachDate
        });
      });
      return rowData;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SummaryReportsSEmployeeVendComponent;
});