define("alliance-business-suite/templates/not-allowed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YOhllE7v",
    "block": "{\"symbols\":[],\"statements\":[[2,\"\\n\\n\\n\"],[10,\"div\"],[14,0,\"content-container container--not-allowed\"],[12],[2,\"\\n  \"],[10,\"img\"],[14,0,\"scrubbrush-watermark\"],[14,\"src\",\"/assets/images/warning.svg\"],[12],[13],[2,\"\\n  \"],[10,\"h3\"],[14,0,\"scrubbrush-watermark\"],[12],[1,[30,[36,0],[\"permission_not_allowed\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "moduleName": "alliance-business-suite/templates/not-allowed.hbs"
  });

  _exports.default = _default;
});