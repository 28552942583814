define("alliance-business-suite/controllers/application", ["exports", "jquery", "alliance-business-suite/config/environment", "alliance-business-suite/services/brush"], function (_exports, _jquery, _environment, _brush) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  Big.prototype.toFormat = function (ts, ds, dp) {
    var arr = this.toFixed(dp).split(".");
    arr[0] = arr[0].replace(/\B(?=(\d{3})+(?!\d))/g, ts == null ? "," : ts + "");
    return arr.join(ds == null ? "." : ds + "");
  };

  var _default = Ember.Controller.extend({
    // The query parameters are defined in the `brush` service; if other params
    // are needed, just concatenate them with this array
    queryParams: _brush.queryParams,
    session: Ember.inject.service(),
    access: Ember.inject.service(),
    devtools: Ember.inject.service(),
    machines: Ember.inject.service(),
    // used in brush.js in _routeController
    router: Ember.inject.service(),

    // // TODO: This is wrong when the "renew subscription" message is displayed
    // heightOfMainView: screen.height - 102 /* browser/os chrome */
    //                                 - 71  /* topmost header */
    //                                 - 60  /* second level header */,
    init() {
      this._super(...arguments); // forceOrgType can persist across reloads for localhost


      const forceOrgType = localStorage.getItem('forceOrgType');

      if (forceOrgType && _environment.default.configEnvironment == 'development') {
        Ember.set(this, 'access.forceOrgType', forceOrgType);
      } // Vertical display of elements


      (0, _jquery.default)(document).on("click", ".vd-toggle-bar", function () {
        document.body.classList.toggle("vd-toggle-hide");
        window.dispatchEvent(new Event("resize"));
      }); // for mac users

      Mousetrap.bind(["alt+s alt+e alt+t"], () => {
        this.devtools.toggleDevToolTray();
      }); // for windows users

      Mousetrap.bind(["alt+1 alt+9 alt+0"], () => {
        this.devtools.toggleDevToolTray();
      });
      Mousetrap.bind(["alt+s alt+s alt+t"], () => {
        this.devtools.toggleSecurityTools();
      }); // TODO: The following stuff needs to fit into an overall strategy instead of being a one-off.
      // Event handler for context menus. TODO: Should this be done the "Ember Way"?

      (0, _jquery.default)("body").on("click", ".dropdown-button, .dropdown-trigger", function (e) {
        e.stopPropagation(); // Close anything already displayed

        if ((0, _jquery.default)(this).parents(".ui-dropdown").hasClass("show-menu")) {
          (0, _jquery.default)(".show-menu").removeClass("show-menu");
          return;
        }

        (0, _jquery.default)(".show-menu").removeClass("show-menu");
        let $button, $menu, $parent;
        $button = (0, _jquery.default)(this);
        $menu = $button.siblings(".dropdown-menu");
        $parent = $button.parents(".ui-dropdown");
        $menu.toggleClass("show-menu");
        $parent.toggleClass("show-menu"); // TODO: Is this needed, since clicking the document will close the menu?

        $menu.children("li").click(function () {
          (0, _jquery.default)(".show-menu").removeClass("show-menu");
        });
      });
      (0, _jquery.default)(document).click(function () {
        (0, _jquery.default)(".show-menu").removeClass("show-menu");
      });
      Mousetrap.bind(["esc"], function () {
        (0, _jquery.default)(".show-menu").removeClass("show-menu");
      });
    },

    destroy() {
      this._super(...arguments); // Clean up keyboard shortcuts


      Mousetrap.unbind(['alt+s alt+e alt+t']);
      Mousetrap.unbind(['alt+s alt+s alt+t']);
      Mousetrap.unbind(['alt+o alt+p alt+l']);
      Mousetrap.unbind(['esc']);
    },

    actions: {
      toggleDevToolTray(e) {
        this.devtools.toggleDevToolTray();
      },

      forceOrgTypeOff() {
        Ember.set(this, 'access.forceOrgType', null);
        localStorage.setItem('forceOrgType', null);
      }

    }
  });

  _exports.default = _default;
});