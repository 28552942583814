define("alliance-business-suite/components/machines/programs/cycles/steps-mc10-opl", ["exports", "alliance-business-suite/mixins/machines/programs/mc10-options"], function (_exports, _mc10Options) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.inletConfiguration = _exports.default = void 0;

  let inletConfiguration = function (washType, inlets, hasDrain2) {
    let config = [{
      washTypeId: 6,
      washTypeName: "SOAK",
      inlets: 2,
      maxValvesOn: 4,
      hasDrain2: false,
      valves: [1, 1, 1, 0, 1, 1, 0, 1]
    }, {
      washTypeId: 6,
      washTypeName: "SOAK",
      inlets: 2,
      maxValvesOn: 4,
      hasDrain2: true,
      valves: [1, 1, 1, 0, 1, 1, 1, 1]
    }, {
      washTypeId: 6,
      washTypeName: "SOAK",
      inlets: 3,
      maxValvesOn: 4,
      hasDrain2: false,
      valves: [1, 1, 1, 1, 1, 1, 1, 1]
    }, {
      washTypeId: 6,
      washTypeName: "SOAK",
      inlets: 3,
      maxValvesOn: 4,
      hasDrain2: true,
      valves: [1, 1, 1, 1, 1, 1, 1, 1]
    }, {
      washTypeId: 7,
      washTypeName: "COOL_DOWN",
      inlets: 2,
      maxValvesOn: 0,
      hasDrain2: false,
      valves: [0, 0, 0, 0, 0, 0, 0, 0]
    }, {
      washTypeId: 7,
      washTypeName: "COOL_DOWN",
      inlets: 2,
      maxValvesOn: 0,
      hasDrain2: true,
      valves: [0, 0, 0, 0, 0, 0, 0, 0]
    }, {
      washTypeId: 7,
      washTypeName: "COOL_DOWN",
      inlets: 3,
      maxValvesOn: 0,
      hasDrain2: false,
      valves: [0, 0, 0, 0, 0, 0, 0, 0]
    }, {
      washTypeId: 7,
      washTypeName: "COOL_DOWN",
      inlets: 3,
      maxValvesOn: 0,
      hasDrain2: true,
      valves: [0, 0, 0, 0, 0, 0, 0, 0]
    }, {
      washTypeId: 13,
      washTypeName: "PRE_WASH",
      inlets: 2,
      maxValvesOn: 4,
      hasDrain2: false,
      valves: [1, 1, 1, 0, 1, 1, 0, 1]
    }, {
      washTypeId: 13,
      washTypeName: "PRE_WASH",
      inlets: 2,
      maxValvesOn: 4,
      hasDrain2: true,
      valves: [1, 1, 1, 0, 1, 1, 1, 1]
    }, {
      washTypeId: 13,
      washTypeName: "PRE_WASH",
      inlets: 3,
      maxValvesOn: 4,
      hasDrain2: false,
      valves: [1, 1, 1, 1, 1, 1, 1, 1]
    }, {
      washTypeId: 13,
      washTypeName: "PRE_WASH",
      inlets: 3,
      maxValvesOn: 4,
      hasDrain2: true,
      valves: [1, 1, 1, 1, 1, 1, 1, 1]
    }, {
      washTypeId: 14,
      washTypeName: "WASH",
      inlets: 2,
      maxValvesOn: 6,
      hasDrain2: false,
      valves: [1, 1, 1, 0, 1, 1, 0, 1]
    }, {
      washTypeId: 14,
      washTypeName: "WASH",
      inlets: 2,
      maxValvesOn: 6,
      hasDrain2: true,
      valves: [1, 1, 1, 0, 1, 1, 1, 1]
    }, {
      washTypeId: 14,
      washTypeName: "WASH",
      inlets: 3,
      maxValvesOn: 6,
      hasDrain2: false,
      valves: [1, 1, 1, 1, 1, 1, 1, 1]
    }, {
      washTypeId: 14,
      washTypeName: "WASH",
      inlets: 3,
      maxValvesOn: 6,
      hasDrain2: true,
      valves: [1, 1, 1, 1, 1, 1, 1, 1]
    }, {
      washTypeId: 15,
      washTypeName: "RINSE",
      inlets: 2,
      maxValvesOn: 3,
      hasDrain2: false,
      valves: [1, 1, 0, 1, 0, 0, 0, 0]
    }, {
      washTypeId: 15,
      washTypeName: "RINSE",
      inlets: 2,
      maxValvesOn: 3,
      hasDrain2: true,
      valves: [1, 1, 0, 1, 1, 0, 1, 0]
    }, {
      washTypeId: 15,
      washTypeName: "RINSE",
      inlets: 3,
      maxValvesOn: 3,
      hasDrain2: false,
      valves: [1, 1, 1, 1, 0, 0, 1, 0]
    }, {
      washTypeId: 15,
      washTypeName: "RINSE",
      inlets: 3,
      maxValvesOn: 3,
      hasDrain2: true,
      valves: [1, 1, 1, 1, 1, 0, 1, 0]
    }, {
      washTypeId: 16,
      washTypeName: "FINAL_RINSE",
      inlets: 2,
      maxValvesOn: 3,
      hasDrain2: false,
      valves: [1, 1, 0, 1, 0, 0, 0, 0]
    }, {
      washTypeId: 16,
      washTypeName: "FINAL_RINSE",
      inlets: 2,
      maxValvesOn: 3,
      hasDrain2: true,
      valves: [1, 1, 0, 1, 1, 0, 1, 0]
    }, {
      washTypeId: 16,
      washTypeName: "FINAL_RINSE",
      inlets: 3,
      maxValvesOn: 3,
      hasDrain2: false,
      valves: [1, 1, 1, 1, 0, 0, 1, 0]
    }, {
      washTypeId: 16,
      washTypeName: "FINAL_RINSE",
      inlets: 3,
      maxValvesOn: 3,
      hasDrain2: true,
      valves: [1, 1, 1, 1, 1, 0, 1, 0]
    }, {
      washTypeId: 17,
      washTypeName: "NO_WASH",
      inlets: 2,
      maxValvesOn: 0,
      hasDrain2: false,
      valves: [0, 0, 0, 0, 0, 0, 0, 0]
    }, {
      washTypeId: 17,
      washTypeName: "NO_WASH",
      inlets: 2,
      maxValvesOn: 0,
      hasDrain2: true,
      valves: [0, 0, 0, 0, 0, 0, 0, 0]
    }, {
      washTypeId: 17,
      washTypeName: "NO_WASH",
      inlets: 3,
      maxValvesOn: 0,
      hasDrain2: false,
      valves: [0, 0, 0, 0, 0, 0, 0, 0]
    }, {
      washTypeId: 17,
      washTypeName: "NO_WASH",
      inlets: 3,
      maxValvesOn: 0,
      hasDrain2: true,
      valves: [0, 0, 0, 0, 0, 0, 0, 0]
    }, {
      washTypeId: 18,
      washTypeName: "PROG_TUMBLE",
      inlets: 2,
      maxValvesOn: 0,
      hasDrain2: false,
      valves: [0, 0, 0, 0, 0, 0, 0, 0]
    }, {
      washTypeId: 18,
      washTypeName: "PROG_TUMBLE",
      inlets: 2,
      maxValvesOn: 0,
      hasDrain2: true,
      valves: [0, 0, 0, 0, 0, 0, 0, 0]
    }, {
      washTypeId: 18,
      washTypeName: "PROG_TUMBLE",
      inlets: 3,
      maxValvesOn: 0,
      hasDrain2: false,
      valves: [0, 0, 0, 0, 0, 0, 0, 0]
    }, {
      washTypeId: 18,
      washTypeName: "PROG_TUMBLE",
      inlets: 3,
      maxValvesOn: 0,
      hasDrain2: true,
      valves: [0, 0, 0, 0, 0, 0, 0, 0]
    }, {
      washTypeId: 19,
      washTypeName: "RINSE_2",
      inlets: 2,
      maxValvesOn: 3,
      hasDrain2: false,
      valves: [1, 1, 0, 1, 0, 0, 0, 0]
    }, {
      washTypeId: 19,
      washTypeName: "RINSE_2",
      inlets: 2,
      maxValvesOn: 3,
      hasDrain2: true,
      valves: [1, 1, 0, 1, 1, 0, 1, 0]
    }, {
      washTypeId: 19,
      washTypeName: "RINSE_2",
      inlets: 3,
      maxValvesOn: 3,
      hasDrain2: false,
      valves: [1, 1, 1, 1, 0, 0, 1, 0]
    }, {
      washTypeId: 19,
      washTypeName: "RINSE_2",
      inlets: 3,
      maxValvesOn: 3,
      hasDrain2: true,
      valves: [1, 1, 1, 1, 1, 0, 1, 0]
    }];
    return config.find(item => {
      return item.washTypeId == washType && item.inlets == inlets && item.hasDrain2 == hasDrain2;
    });
  };

  _exports.inletConfiguration = inletConfiguration;

  var _default = Ember.Component.extend(_mc10Options.default, {
    // eslint-disable-line ember/no-classic-classes
    tagName: '',
    intl: Ember.inject.service(),

    /*
       Some validations are based on the machineType, and those settings are stored in `machineSettings` in the machineType model.
       We need to revalidate and keep values within ranges when there are changes to:
        - Machine type = water and speed changes, inlet changes; can change ALL cycles and steps.
        - Step type (Cycles) / Water valve count (Config) / Drain valve 2 enabled (Config) = inlets change
       We will do this validation when the program is saved, which is how LMS does it.
     */
    //#region Enumerated Types
    drainType: {
      Drain: 1,
      Spin: 2,
      NoDrain: 3,
      Static: 4,
      Reversing: 5
    },
    stepType: {
      Soak: 6,
      Prewash: 13,
      PreWash: 13,
      Wash: 14,
      NoWash: 17,
      Rinse: 15,
      CoolDown: 7,
      FinalRinse: 16,
      Tumble: 18
    },
    //#endregion
    currentDrainType: Ember.computed.alias('object.DrainType'),
    currentStep: Ember.computed.alias('object.Type'),
    drainSpeedMin: Ember.computed('object.DrainType', 'drainType.Spin', 'machineSettings.{spinRpmMin,washRpmMin}', function () {
      return this.object.DrainType == this.drainType.Spin ? this.machineSettings.spinRpmMin : this.machineSettings.washRpmMin;
    }),
    drainSpeedMax: Ember.computed('object.DrainType', 'drainType.Spin', 'machineSettings.{spinRpmMax,washRpmMax}', function () {
      return this.object.DrainType == this.drainType.Spin ? this.machineSettings.spinRpmMax : this.machineSettings.washRpmMax;
    }),

    // decimals: 2,
    update(object, propertyName, value) {
      Ember.set(object, propertyName, value);
    },

    inletSettings: Ember.computed('object.Type', 'totalNumberOfInlets', 'drainValve2Enabled', function () {
      let washType = this.object.Type;
      let inlets = this.totalNumberOfInlets;
      let hasDrain2 = this.drainValve2Enabled;
      return inletConfiguration(washType, inlets, hasDrain2);
    }),
    enabledInlets: Ember.computed('inletSettings', 'inletSettings.valves', 'object', function () {
      // Returns array of which inlets are available/enabled
      let enabledValves = this.inletSettings ? this.inletSettings.valves : [0, 0, 0, 0, 0, 0, 0, 0];
      let object = this.object; // We need to uncheck any valves that are not enabled

      enabledValves.forEach((valve, index) => {
        if (valve == 0) {
          this.update(this.object, `Inlets.Inlet${index + 1}`, false);
        }
      });
      return enabledValves;
    }),
    maxValvesOn: Ember.computed('inletSettings', 'inletSettings.maxValvesOn', 'isMidLargeMachine', function () {
      // June 2022 update: Mid/Large machines have a limit of 3, softmount has a limit of 4
      if (this.isMidLargeMachine) {
        return 3;
      } else {
        return 4;
      } // // Old code: This never runs...might be for vended MC10 only?
      // let count = (this.inletSettings && this.inletSettings.maxValvesOn) ? this.inletSettings.maxValvesOn : 0;
      // return count;

    }),
    observeMaxValves: Ember.observer('maxValvesOn', function () {
      // eslint-disable-line ember/no-observers
      // Need to uncheck some if the machine size changes and fewer inlets are allowed
      if (this.selectedInletCount > this.maxValvesOn) {
        let inletCount = 0;

        for (let i = 1; i <= 8; i++) {
          if (Ember.get(this, `object.Inlets.Inlet${i}`)) {
            inletCount = inletCount + 1;

            if (inletCount > this.maxValvesOn) {
              this.update(this.object, `Inlets.Inlet${i}`, false);
            }
          }
        }
      }

      ;
    }),
    selectedInletCount: Ember.computed('object.Inlets.{Inlet1,Inlet2,Inlet3,Inlet4,Inlet5,Inlet6,Inlet7,Inlet8}', function () {
      // How many inlets are currently selected
      let count = 0;

      for (let i = 1; i <= 8; i++) {
        if (Ember.get(this, `object.Inlets.Inlet${i}`)) {
          count = count + 1;
        }
      }

      return count;
    }),
    selectedSoapBoxCount: Ember.computed('object.SoapBoxes.{SB1,SB2,SB3,SB4,SB5}', function () {
      // How many inlets are currently selected
      let count = 0;

      for (let i = 1; i <= 5; i++) {
        if (Ember.get(this.object, `SoapBoxes.SB${i}`)) {
          count = count + 1;
        }
      }

      return count;
    }),
    valveTypeOptions: Ember.computed('drainValve2Enabled', function () {
      if (this.drainValve2Enabled) {
        return [{
          label: "Valve1",
          val: 1
        }, {
          label: "Valve2",
          val: 2
        }, {
          label: "Both",
          val: 3
        }];
      } else {
        return [{
          label: "Valve1",
          val: 1
        }];
      }
    }),
    ////#region Actions
    actions: {
      // eslint-disable-line ember/no-actions-hash
      // TODO: Why does this action need to be included?
      save() {
        alert('Save this form'); // Code smell: things break if this action is missing.
      },

      changeStepType(selection) {
        if (selection.val === this.stepType.CoolDown && this.object.DrainValveForCooldown === 0) {
          Ember.set(this.object, "DrainValveForCooldown", 1);
        } else {
          Ember.set(this.object, "DrainValveForCooldown", 0);
        }
      },

      changeDrainType(val) {
        let drainType = val.val;
        let drainTypeDisabled = 3;
        let drainValve = this.object.DrainValve; // If the drainType is changed to disabled (drainType==3), we need to set DrainValve to 0.
        // If the drainType is changing _from_ disabled, we'll set the value to 1.
        // If the drainType is changing but not to/from disabled, we check the previous of DrainValve and set to 1 if drain valve 2 is not enabled.

        if (drainType == drainTypeDisabled) {
          this.update(this.object, 'DrainValve', 0);
        } else {
          if (drainValve == 0) {
            this.update(this.object, 'DrainValve', 1);
          } else if (drainValve != 1 && !this.drainValve2Enabled) {
            // Reset if drain 2 is not available
            this.update(this.object, 'DrainValve', 1);
          }
        }
      },

      afterInletChange(object, propertyName, value, event) {
        let type = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : "soapbox";

        if (this.isMidLargeMachine) {
          const maxInlets = 3;
          const maxInletSoapBoxes = 4; // Mid/Large machines have a limit of 3 inlets...

          if (value == true && type == 'inlet' && this.selectedInletCount + 1 > maxInlets) {
            event.target.checked = false; // alert(this.intl.t("machine_inlet_limit", maxInlets));

            return;
          } // Mid/Large machines can only have 4 inlet + soap box selections


          if (value == true && this.selectedInletCount + this.selectedSoapBoxCount + 1 > maxInletSoapBoxes) {
            event.target.checked = false;
            alert(this.intl.t("machine_inlet_soapbox_number", maxInletSoapBoxes));
            return;
          }
        } else {
          // If we're at the limit, always uncheck the selected checkbox
          if (value == true && this.selectedInletCount >= this.maxValvesOn) {
            // Undo the checkbox
            event.target.checked = false;
            return;
          }
        }

        this.update(object, propertyName, value);
      }

    } //#endregion

  });

  _exports.default = _default;
});