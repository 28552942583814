define("alliance-business-suite/components/employees/employees-section", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3;

  let EmployeesEmployeesSectionComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._action, (_class = class EmployeesEmployeesSectionComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "selectedRole", _descriptor3, this);
    }

    // computed('model.securityRoles', function() {
    get roleOptions() {
      let arr = [{
        id: '0',
        name: 'all_roles'
      }];
      this.args.model.securityRoles.forEach(function (role) {
        if (role.name != 'SUSPENDED_EMPLOYEE') {
          arr.push(role);
        }
      });
      return arr;
    } // computed('model.employees', 'selectedRole', function()


    get filteredEmployees() {
      let model = this.args.model.employees;
      let selectedRole = this.selectedRole;

      if (!selectedRole || selectedRole.id == 0) {
        return model;
      } else {
        let filteredModel = [];
        model.filter(employee => {
          let sr = employee.get('securityRoles');
          sr.forEach(function (r) {
            if (r.get('id') == selectedRole.id) {
              filteredModel.pushObject(employee);
              return true;
            }
          });
        });
        return filteredModel;
      }
    }

    updateSelectedRole(role) {
      this.selectedRole = role;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedRole", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        id: '0',
        name: 'all_roles'
      };
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "updateSelectedRole", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "updateSelectedRole"), _class.prototype)), _class));
  _exports.default = EmployeesEmployeesSectionComponent;
});