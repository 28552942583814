define("alliance-business-suite/components/rooms/peak-chart-container", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17;

  // import Component from '@ember/component';
  // import { inject as service } from '@ember/service';
  // import { observer, set, get, computed } from '@ember/object';
  // import moment from "moment";
  // import defaultTheme from '../../themes/default-theme';
  let RoomsPeakChartComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember._tracked, _dec18 = Ember._action, _dec19 = Ember._action, (_class = class RoomsPeakChartComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "session", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "ajax", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "userAgent", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "browserName", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "dayOfWeekIdx", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "selectedDay", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "nextDayIdx", _descriptor10, this);
      (0, _initializerDefineProperty2.default)(this, "previousDayIdx", _descriptor11, this);
      (0, _initializerDefineProperty2.default)(this, "isLoading", _descriptor12, this);
      (0, _initializerDefineProperty2.default)(this, "dayRequested", _descriptor13, this);
      (0, _initializerDefineProperty2.default)(this, "noData", _descriptor14, this);
      (0, _initializerDefineProperty2.default)(this, "chartData", _descriptor15, this);
      (0, _initializerDefineProperty2.default)(this, "showChart", _descriptor16, this);
      (0, _initializerDefineProperty2.default)(this, "chartOptions", _descriptor17, this);
      (0, _defineProperty2.default)(this, "days", ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']);
    }

    get userDateTimeFormat() {
      let userId = this.access.accessUser.id;
      let user = this.store.peekRecord('employee', userId);
      let userTimeFormat = user.get('timeFormat');

      if (userTimeFormat == 'HH:mm') {
        return 'H:mm';
      } else {
        return 'ha';
      }
    }

    get options() {
      let {
        browserName
      } = this;
      let height = 110;
      return {
        chart: {
          height,
          type: 'column'
        },
        title: {
          text: ''
        },
        tooltip: {
          formatter: function () {
            return this.x;
          }
        },
        xAxis: {
          categories: [],
          endOnTick: true,
          showLastLabel: true,
          startOnTick: true,
          labels: {
            step: 4 // enabled: false

          },
          title: {
            enabled: false
          }
        },
        yAxis: {
          gridLineWidth: 0,
          max: 1,
          labels: {
            enabled: false
          },
          title: {
            enabled: false //   text: ''

          }
        },
        legend: {
          enabled: false
        },
        plotOptions: {
          column: {
            colorByPoint: true
          },
          series: {
            groupPadding: 0,
            pointPadding: 0,
            borderWidth: 1 // color: ['#FF0000']

          } // colors: ['#3B97B2', '#67BC42', '#FF56DE', '#E6D605', '#BC36FE']

        }
      };
    }

    calcSelectedDay(day) {
      let {
        days
      } = this;
      let daysLength = days.length - 1;
      let dayIdx = null;

      if (day) {
        dayIdx = days.indexOf(day);
        this.dayOfWeekIdx = dayIdx;
        this.selectedDay = day;
      } else {
        let today = moment();
        dayIdx = today.day();
        this.dayOfWeekIdx = dayIdx;
        this.selectedDay = days[dayIdx];
      }

      if (dayIdx == daysLength) {
        this.nextDayIdx = 0;
      } else {
        this.nextDayIdx = dayIdx + 1;
      }

      if (dayIdx == 0) {
        this.previousDayIdx = daysLength;
      } else {
        this.previousDayIdx = dayIdx - 1;
      }

      this.fetchPeakData();
    }

    fetchPeakData(dayIdx) {
      let {
        dayOfWeekIdx
      } = this;
      let model = this.args.model; // if we do not pass in a day, grab the current day of the week

      let dayRequested = dayIdx ? dayIdx : dayOfWeekIdx;
      this.isLoading = true, this.dayRequested = dayRequested, this.noData = false;
      this.ajax.request(`/rooms/${model.id}/peakUsage?dayOfWeek=${dayRequested}`, {
        method: 'GET'
      }).then(response => {
        this.createChart(response);
      }).catch(e => {
        this.setEmptyChart();
      });
    }

    setEmptyChart() {
      this.isLoading = false;
      this.noData = true;
      this.chartData = [{
        data: []
      }];
    }

    createChart(data) {
      let hours = data.hours; // while data is being populated, we need to check if there are too many zeroes

      let emptyCollect = 0;
      hours.forEach(h => {
        if (h <= 0) {
          emptyCollect++;
        }
      });

      if (emptyCollect > 18) {
        this.setEmptyChart();
        return;
      }

      this.createHourCategories(hours, this.userDateTimeFormat);
      this.chartData = [{
        data: hours
      }];
      this.isLoading = false;
      setTimeout(() => {
        this.showChart = true;
      }, 750);
    }

    createHourCategories(array, format) {
      let categories = array.map((hour, idx) => {
        let start = moment().startOf('day');
        start.add(idx, 'hours');
        return start.format(format);
      });
      let options = this.options;
      options.xAxis.categories = categories;
      this.chartOptions = options;
      this.setColorToLocalTime(array);
    } // set the color of columns, if current local time give special color


    setColorToLocalTime(array) {
      let {
        store
      } = this;
      let model = this.args.model;
      let defaultColor = '#7BAAF7';
      let currentColor = '#ff80ab';
      let room = store.peekRecord('room', model.id);
      let timeZoneName = room.get('internationalTimeZoneName');
      let localTimeIdx = moment().tz(timeZoneName).format('HH');
      let colorArray = array.map((hour, idx) => {
        if (idx == localTimeIdx) {
          return currentColor;
        }

        return defaultColor;
      });
      Highcharts.setOptions({
        colors: colorArray
      });
    }

    initialSetup() {
      this.browserName = this.userAgent.browser.info.name;
      this.calcSelectedDay();
    }

    fetchDay(idx) {
      let {
        days
      } = this;
      let day = null; // can be passed in as a string via dropdown select

      if (typeof idx === 'string') {
        idx = days.indexOf(idx);
      } // can be programatically passed in as index, sunday has an index of 0


      if (!isNaN(idx)) {
        day = days[idx];
      }

      this.calcSelectedDay(day);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "ajax", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "userAgent", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "browserName", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dayOfWeekIdx", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedDay", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "nextDayIdx", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "previousDayIdx", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isLoading", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dayRequested", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "noData", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "chartData", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "showChart", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "chartOptions", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "initialSetup", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "initialSetup"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "fetchDay", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "fetchDay"), _class.prototype)), _class));
  _exports.default = RoomsPeakChartComponent;
});