define("alliance-business-suite/templates/machines", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jrd/xr79",
    "block": "{\"symbols\":[],\"statements\":[[8,\"header-sub-nav\",[],[[\"@subnavTabs\"],[[34,0]]],null],[2,\"\\n\"],[10,\"div\"],[14,0,\"overflow-container machines-container\"],[12],[2,\"\\n  \"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"subnavTabs\",\"-outlet\",\"component\"]}",
    "moduleName": "alliance-business-suite/templates/machines.hbs"
  });

  _exports.default = _default;
});