define("alliance-business-suite/components/form-ui/time", ["exports", "alliance-business-suite/utils/is-number", "alliance-business-suite/mixins/form-ui"], function (_exports, _isNumber, _formUi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // value is a Time
  // hour12 (default false) whether or not to display in 12 hour format with am/pm field.
  // on-change action is triggered with a Time whenever the input value changes.
  // Time is simply a POJO with hours and minutes properties and is always 24 hours.
  var _default = Ember.Component.extend(_formUi.default, {
    tagName: 'div',
    classNames: ['form-field', 'overflow-float'],

    init() {
      this._super(...arguments);

      let hoursPropertyName = Ember.get(this, 'params.hoursPropertyName');
      let minutesPropertyName = Ember.get(this, 'params.minutesPropertyName');
      Ember.defineProperty(this, 'hoursValue', Ember.computed.reads(`object.${hoursPropertyName}`));
      Ember.defineProperty(this, 'minutesValue', Ember.computed.reads(`object.${minutesPropertyName}`));
    },

    isUndefinedProperty: Ember.computed('params.{propertyName,hoursPropertyName,minutesPropertyName}', function () {
      // Check for invalid properties.
      let hasProperty = ["propertyName", "hoursPropertyName", "minutesPropertyName"].any(propertyName => {
        let property = Ember.get(this, `params.${propertyName}`);
        return Ember.get(this, `object.${property}`) !== undefined;
      });

      if (hasProperty) {
        return false;
      } else {
        console.error('Reference to property in time component is undefined.'); // eslint-disable-line no-console

        return true;
      }
    }),
    value: Ember.computed('rawValue', 'hoursValue', 'minutesValue', {
      get() {
        if (Ember.get(this, 'propertyName')) {
          let time = Ember.get(this, 'rawValue');
          let timeValue = time.padStart(4, '0');
          let output = moment.utc(timeValue, ['h:m a', 'H:m']); // Supports strings like 9:45AM, 9:45 pm, 0945, 945

          return {
            hours: output.hours(),
            minutes: output.minutes()
          };
        } else {
          let hours = Ember.get(this, 'hoursValue') || 0;
          let minutes = Ember.get(this, 'minutesValue') || 0;
          return {
            hours,
            minutes
          };
        }
      }

    }),
    actions: {
      timeChanged(time) {
        let {
          hours,
          minutes
        } = time;
        let object = Ember.get(this, 'object');
        let propertyName = Ember.get(this, 'params.propertyName');
        let hoursPropertyName = Ember.get(this, 'params.hoursPropertyName');
        let minutesPropertyName = Ember.get(this, 'params.minutesPropertyName');

        if (hoursPropertyName) {
          Ember.get(this, 'update')(object, hoursPropertyName, hours);
        }

        if (minutesPropertyName !== undefined) {
          Ember.get(this, 'update')(object, minutesPropertyName, minutes);
        }

        if (propertyName) {
          // Update with hh:mm. TODO: Is there a need to save in 12-hour format?
          Ember.get(this, 'update')(object, propertyName, `${hours}:${minutes}`);
        }

        this.notifyPropertyChange('value');
      }

    }
  });

  _exports.default = _default;
});