define("alliance-business-suite/components/summary/reports/s-machine-remote-start-tracking", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "alliance-business-suite/utils/currency-format-util"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _currencyFormatUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  let SummaryReportsSMachineRemoteStartTrackingComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, (_class = class SummaryReportsSMachineRemoteStartTrackingComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor3, this);
    }

    get translatedColumns() {
      let headers = [{
        name: "location_name",
        valuePath: "organizationName",
        width: 150,
        isFixed: "left"
      }, {
        name: "date_started",
        valuePath: "startedDate",
        width: 125
      }, {
        name: "started_by",
        valuePath: "startedBy",
        width: 125
      }, {
        name: "price",
        valuePath: "price",
        width: 125
      }, {
        name: "machine_number",
        valuePath: "machineNumber",
        width: 125
      }, {
        name: "reason_for_machine_start",
        valuePath: "startReason",
        width: 125
      }];

      if (this.access.isRetail) {
        headers.push({
          name: "order",
          valuePath: "orderNumber",
          width: 125
        });
      }

      return headers;
    }

    get rowsWithChildren() {
      let {
        model
      } = this.args;
      let geoBoundariesObjects = model.geoBoundaries;
      let geoArray = [];
      let rowData = [];
      rowData.push({
        organizationName: model.organizationName,
        children: geoArray
      });
      geoBoundariesObjects.forEach(geoBoundaryObject => {
        let roomArray = [];
        geoArray.push({
          organizationName: geoBoundaryObject.name,
          children: roomArray
        });
        geoBoundaryObject.rooms.forEach(room => {
          let machineStartArray = [];
          roomArray.push({
            organizationName: room.name,
            children: machineStartArray
          });
          room.machineStarts.forEach(machineStart => {
            let startReason = machineStart.startReason;

            if (startReason == "Other") {
              startReason = `Other: ${machineStart.note}` || "Other";
            }

            machineStartArray.push({
              startedDate: moment(machineStart.startedDate).format("MM/DD/YYYY hh:mm A"),
              startedBy: machineStart.startedBy,
              price: _currencyFormatUtil.formatCurrencyString.call(this, machineStart.price || 0),
              machineNumber: machineStart.machineNumber,
              startReason: startReason,
              orderNumber: machineStart.orderNumber
            });
          });
        });
      });
      return rowData;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SummaryReportsSMachineRemoteStartTrackingComponent;
});