define("alliance-business-suite/components/customers/c-view", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  let RoomsCViewComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember.computed('brush.state'), _dec6 = Ember._action, (_class = class RoomsCViewComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "brush", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "canAccess", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "showCustomerModal", _descriptor4, this);
    }

    get cards() {
      let cards = [];

      if (this.canAccess.canAccess('CUSTOMERS_TRANSACTIONS_VIEW')) {
        cards.push('transactions');
      }

      if (this.canAccess.canAccess('CUSTOMERS_PAYMENT_METHODS_VIEW')) {
        cards.push('payment-methods');
      } // These only get displayed for retail (RETAIL_FEATURES)


      if (Ember.get(this, 'access.isCrm')) {
        if (this.canAccess.canAccess('CRM_ANALYTICS_VIEW_PAGE')) {
          cards.push('stats');
        }
      }

      cards.push('notes');
      return cards;
    }

    get selectedCard() {
      let savedId = this.brush.state;
      let savedCard;

      if (savedId != null) {
        savedCard = this.cards.find(card => Ember.String.dasherize(card) === savedId);
      }

      return savedCard;
    }

    set selectedCard(value) {
      this.brush.state = Ember.String.dasherize(value);
      return value;
    }

    toggleCustomerModal() {
      if (this.access.isDemo) {
        alert('Demo mode restricted');
        return;
      }

      this.showCustomerModal = !this.showCustomerModal;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "brush", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "canAccess", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "showCustomerModal", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedCard", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "selectedCard"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "toggleCustomerModal", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "toggleCustomerModal"), _class.prototype)), _class));
  _exports.default = RoomsCViewComponent;
});