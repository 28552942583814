define("alliance-business-suite/models/revenue-detail", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import Ember from 'ember';
  // const { computed, get } = Ember;
  const {
    Model
  } = _emberData.default;

  var _default = Model.extend({});

  _exports.default = _default;
});