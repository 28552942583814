define("alliance-business-suite/components/employees/time-clock-section", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    access: Ember.inject.service(),
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    filterByDate: false,
    rooms: Ember.computed(function () {
      let allRooms = this.store.peekAll('room').sortBy('formattedBreadcrumbDisplay');
      return allRooms.filter(room => {
        if (room.get('isRetail')) {
          return true;
        }
      });
    }),
    selectedRoom: Ember.computed('rooms', function () {
      if (this.rooms.length === 1) {
        // this.send('changeRoom', this.rooms.firstObject);
        return this.rooms.firstObject;
      }
    }),
    startDate: Ember.computed(function () {
      let date = (0, _moment.default)().subtract(30, 'days').toDate();
      return (0, _moment.default)(date).format('YYYY-MM-DD');
    }),
    endDate: Ember.computed(function () {
      let date = (0, _moment.default)().toDate();
      return (0, _moment.default)(date).format('YYYY-MM-DD');
    }),
    employees: Ember.computed('selectedRoom', 'model', function () {
      let {
        model,
        selectedRoom
      } = this;
      return model;
    }),
    minStartDate: Ember.computed('endDate', function () {
      let endDate = this.endDate; // return moment().subtract(100, 'days').toDate(); No limit
    }),
    maxStartDate: Ember.computed('endDate', function () {
      let endDate = this.endDate;
      return (0, _moment.default)().toDate();
    }),
    minEndDate: Ember.computed('startDate', function () {
      let startDate = this.startDate;
      return (0, _moment.default)(startDate).toDate();
    }),
    maxEndDate: Ember.computed('startDate', function () {
      let startDate = this.startDate; // if (startDate && moment(startDate).add(30, 'days').isBefore(moment())) {
      //   return moment(startDate).add(30, 'days').toDate();
      // }

      return (0, _moment.default)().toDate();
    }),
    cannotFetchPunches: Ember.computed('endDate', 'startDate', 'selectedEmployee', 'selectedRoom', function () {
      let {
        endDate,
        startDate,
        selectedEmployee,
        selectedRoom
      } = this;

      if (!endDate || !startDate || !selectedEmployee || !selectedRoom || !(0, _moment.default)(startDate).isValid() || !(0, _moment.default)(endDate).isValid()) {
        return true;
      }
    }),
    userDateTimeFormat: Ember.computed(function () {
      let userId = Ember.get(this, 'access.accessUser.id');
      let user = Ember.get(this, 'store').peekRecord('employee', userId);
      let userTimeFormat = `${user.get('timeFormat')} A`;
      let userDateFormat = user.get('dateFormat');

      if (user.get('timeFormat') === 'HH:mm') {
        userTimeFormat = `${user.get('timeFormat')}`;
      }

      return {
        userDateFormat,
        userTimeFormat
      };
    }),

    fetchPunches(isOldRefresh) {
      let {
        selectedEmployee,
        selectedRoom,
        startDate,
        endDate,
        filterByDate,
        searchedEmployee,
        searchedLocation
      } = this;

      if (isOldRefresh === undefined) {
        this.set('searchedLocation', selectedRoom);
        this.set('searchedEmployee', selectedEmployee);
      } else if (isOldRefresh === true) {
        this.set('selectedEmployee', searchedEmployee);
        this.set('selectedRoom', searchedLocation);
      }

      this.setProperties({
        isLoading: true,
        isEmpty: false
      });
      let room = selectedRoom;
      let employee = selectedEmployee;
      room = isOldRefresh ? searchedLocation : selectedRoom;
      employee = isOldRefresh ? searchedEmployee : selectedEmployee;
      this.ajax.request(`/rooms/${room.get('id')}/employees/${employee.get('id')}/punches`, {
        method: 'GET',
        data: {
          startDate,
          endDate,
          raw: true
        }
      }).then(response => {
        let final = [];

        if (response.length == 0) {
          this.setProperties({
            isLoading: false,
            isEmpty: true,
            timeClocks: null
          });
          return;
        }

        response.forEach(punch => {
          let punchInDate = punch.punchInDate;
          let punchOutDate = punch.punchOutDate;

          if (filterByDate) {
            if ((0, _moment.default)(punchInDate).format('YYYY DD MM') !== (0, _moment.default)(punchOutDate).format('YYYY DD MM')) {
              let arr = punch;
              arr.punchInDate = punchInDate.substring(0, punchInDate.indexOf('.'));

              if (punch.punchOutDate !== null) {
                arr.punchOutDate = punchOutDate.substring(0, punchOutDate.indexOf('.'));
              }

              final.push(arr);
            }
          } else {
            let arr = punch;
            arr.punchInDate = punchInDate.substring(0, punchInDate.indexOf('.'));

            if (punchOutDate !== null) {
              arr.punchOutDate = punchOutDate.substring(0, punchOutDate.indexOf('.'));
            }

            final.push(arr);
          }
        });
        this.setProperties({
          timeClocks: final,
          isLoading: false
        });
      });
    },

    actions: {
      refreshData(isOldRefresh) {
        this.fetchPunches(isOldRefresh);
      },

      changeEmployee(employee) {
        this.set('selectedEmployee', employee); // this.fetchPunches();
      },

      changeRoom(room) {
        this.set('selectedRoom', room);
      },

      showEditModal() {
        this.toggleProperty('showEditModal');
      },

      setStartDate(date) {
        this.set('startDate', _moment.default.utc(date).format('YYYY-MM-DD'));
      },

      setEndDate(date) {
        this.set('endDate', _moment.default.utc(date).format('YYYY-MM-DD'));
      }

    }
  });

  _exports.default = _default;
});