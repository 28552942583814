define("alliance-business-suite/templates/components/form-ui/steps/external-supply", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uZIM2jbT",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[8,\"form-ui\",[],[[\"@object\",\"@formSettings\",\"@selectOptions\"],[[32,0,[\"object\"]],[32,0,[\"formSettings\"]],[32,0,[\"selectOptions\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[10,\"h2\"],[12],[1,[30,[36,0],[\"external_supply\"],null]],[13],[2,\"\\n\\n  \"],[8,[32,1,[\"select\"]],[],[[\"@params\"],[[30,[36,1],null,[[\"propertyName\",\"options\"],[[32,0,[\"properties\",\"ShutoffCondition\",\"key\"]],\"SupplyCondition\"]]]]],null],[2,\"\\n\\n  \"],[8,[32,1,[\"checkbox\"]],[],[[\"@params\"],[[30,[36,1],null,[[\"propertyName\",\"labelKey\"],[[32,0,[\"properties\",\"ExtSupply1\",\"key\"]],\"external_supply_1\"]]]]],null],[2,\"\\n  \"],[8,[32,1,[\"checkbox\"]],[],[[\"@params\"],[[30,[36,1],null,[[\"propertyName\",\"labelKey\"],[[32,0,[\"properties\",\"ExtSupply2\",\"key\"]],\"external_supply_2\"]]]]],null],[2,\"\\n  \"],[8,[32,1,[\"checkbox\"]],[],[[\"@params\"],[[30,[36,1],null,[[\"propertyName\",\"labelKey\"],[[32,0,[\"properties\",\"ExtSupply3\",\"key\"]],\"external_supply_3\"]]]]],null],[2,\"\\n  \"],[8,[32,1,[\"checkbox\"]],[],[[\"@params\"],[[30,[36,1],null,[[\"propertyName\",\"labelKey\"],[[32,0,[\"properties\",\"ExtSupply4\",\"key\"]],\"external_supply_4\"]]]]],null],[2,\"\\n\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"hash\"]}",
    "moduleName": "alliance-business-suite/templates/components/form-ui/steps/external-supply.hbs"
  });

  _exports.default = _default;
});