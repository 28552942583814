define("alliance-business-suite/routes/summary/subscriptions/manage", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "alliance-business-suite/routes/_secureRoute", "alliance-business-suite/utils/subscription-methods-utils"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _secureRoute, _subscriptionMethodsUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  let SummarySubscriptionsManageRoute = (_dec = Ember.inject.service, (_class = class SummarySubscriptionsManageRoute extends _secureRoute.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _defineProperty2.default)(this, "allowAccess", 'SUMMARY_SUBSCRIPTION_VIEW_PAGE');
    }

    get dayOfWeeks() {
      return _subscriptionMethodsUtils.DAYS_OF_WEEK;
    }

    async model(params) {
      let reportSubscription = this.store.peekRecord('subscription', params.subscription_id);
      let selectedRooms = [];
      reportSubscription.get('subscriptionRoomConditions').forEach(function (roomSubscription) {
        selectedRooms.push(roomSubscription.get('room'));
      });
      let selectedSendAtTime = {
        display: moment(reportSubscription.get('sendAtTime'), 'HH:mm:ss').format('h:mm A'),
        value: moment(reportSubscription.get('sendAtTime'), 'HH:mm:ss').format('HH:mm')
      };
      let selectedSendOnDay = null;

      if (reportSubscription.get('sendOnDay')) {
        selectedSendOnDay = {
          id: reportSubscription.get('sendOnDay'),
          text: this.dayOfWeeks.get(reportSubscription.get('sendOnDay')).text
        };
      }

      return {
        reportSubscription,
        subscriptionTemplates: this.store.peekAll('subscriptionTemplate'),
        selectedRooms,
        selectedFrequency: reportSubscription.get('frequency'),
        selectedSendAtTime,
        selectedSendOnDay,
        reportTypes: this.store.findAll('reportType'),
        timeFrames: this.store.findAll('timeFrame'),
        frequencies: this.store.findAll('frequency'),
        rooms: this.store.peekAll('room')
      };
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SummarySubscriptionsManageRoute;
});