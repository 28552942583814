define("alliance-business-suite/utils/object", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.diffPaths = diffPaths;

  /**
   * Compare two objects deeply for properties which differ and return the paths
   * to those properties as an array.
   *
   * @param {object} object1 The first object to compare
   * @param {object} object2 The second object to compare
   *
   * @return {array} An array of paths to the properties which differ, or
   *                 undefined if no paths differ
   * @public
   */
  function diffPaths(object1, object2) {
    let pathPrefix = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';

    if (object1 == null && object2 == null) {
      return undefined;
    }

    object1 = object1 || {};
    object2 = object2 || {};

    let keys = _lodash.default.union(Object.keys(object1), Object.keys(object2));

    let result = [];

    for (let key of keys) {
      if (!_lodash.default.has(object1, key) || !_lodash.default.has(object2, key)) {
        result.push(pathPrefix + key);
      } else {
        let value1 = object1[key];
        let value2 = object2[key];

        if (value1 !== value2) {
          if (typeof value1 === 'object' && typeof value2 === 'object') {
            let childPaths = diffPaths(value1, value2, `${key}.`);

            if (childPaths) {
              result = result.concat(childPaths);
            }
          } else {
            result.push(pathPrefix + key);
          }
        }
      }
    }

    return result.length > 0 ? result : undefined;
  }
});