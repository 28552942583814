define("alliance-business-suite/components/form-ui/manual-mode", ["exports", "alliance-business-suite/mixins/init-property-names", "alliance-business-suite/utils/byte-utilities"], function (_exports, _initPropertyNames, _byteUtilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_initPropertyNames.default, {
    updatePasskey(index, value) {
      this.update(this.object, this.properties.Passkey.key, (0, _byteUtilities.updateByteArrayElement)(this.Passkey, index, value));
    },

    passkey0: Ember.computed({
      get() {
        return (0, _byteUtilities.getByteArrayElement)(this.Passkey, 0);
      },

      set(key, value) {
        this.updatePasskey(0, value);
        return value;
      }

    }),
    passkey1: Ember.computed({
      get() {
        return (0, _byteUtilities.getByteArrayElement)(this.Passkey, 1);
      },

      set(key, value) {
        this.updatePasskey(1, value);
        return value;
      }

    }),
    passkey2: Ember.computed({
      get() {
        return (0, _byteUtilities.getByteArrayElement)(this.Passkey, 2);
      },

      set(key, value) {
        this.updatePasskey(2, value);
        return value;
      }

    }),
    passkey3: Ember.computed({
      get() {
        return (0, _byteUtilities.getByteArrayElement)(this.Passkey, 3);
      },

      set(key, value) {
        this.updatePasskey(3, value);
        return value;
      }

    }),
    machineAuditType: Ember.computed.alias('machineProgram.machineAuditType.name'),
    optionCollection: Ember.computed('params.options', 'machineAuditType', function () {
      // Options should always be passed in as an array. Fall back to Midas settings if we don't get options passed in.
      if (this.params.options) {
        // If options are passed in, then use them
        return this.params.options;
      } else if (this.machineAuditType) {
        // If we have an audit type, use that
        switch (this.machineAuditType) {
          case "TITAN_T4":
          case "TITAN_T2":
          case "TITAN_T1":
            return [{
              val: 2,
              label: 'Password protected',
              text: "Access is passcode only"
            }, {
              val: 1,
              label: 'Enabled',
              text: "Access is not limited"
            }, {
              val: 0,
              label: 'Disabled',
              text: "Access is not allowed"
            }];

          case "MGD_M7":
            return [{
              label: "Passcode",
              val: 0,
              text: "Access is passcode only"
            }, {
              label: "Enabled",
              val: 1,
              text: "Access is not limited"
            }, {
              label: "Disabled",
              val: 2,
              text: "Access is not allowed"
            }];

          default:
            return [{
              label: "Disabled",
              val: 0,
              text: "Access is not allowed"
            }, {
              label: "Enabled",
              val: 1,
              text: "Access is not limited"
            }, {
              label: "Passcode",
              val: 2,
              text: "Access is passcode only"
            }];
        }
      } else {
        // Fall back to Midas defaults so we don't break existing code
        console.warn("manual-mode component options fall back");
        return [{
          label: "Disabled",
          val: 0,
          text: "Access is not allowed"
        }, {
          label: "Enabled",
          val: 1,
          text: "Access is not limited"
        }, {
          label: "Passcode",
          val: 2,
          text: "Access is passcode only"
        }];
      }
    })
  });

  _exports.default = _default;
});