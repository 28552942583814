define("alliance-business-suite/helpers/water-labels", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
  * @public
  * Presentation of water usage values.
  * @param {Object} value
  * @param {number} value.total - Total water used.
  * @param {number} value.hot - Total hot water used.
  *
  * @param {Object} args
  * @param {string} args.show - Which value to display, either total or hot.
  * @param {string} [args.units=Gallons] - String to display as water units. Should be 'L' or 'Gallons'.
  * @param {string} [args.convertToLiters] - Boolean to convert value to liters'.
  *
  * @return {string} - The water usage value.
  */
  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),

    compute(_ref, args) {
      let [value, ...rest] = _ref;
      let intl = this.intl;

      if (value != null) {
        let total = value.total || value || 0;
        let hot = value.hot || value || 0;
        let units = args.units || 'G'; // Handle conversion to Liters if required

        if (args.convertToLiters) {
          total = value.total * 3.78541 || 0;
        }

        if (args.show == 'total') {
          return Ember.String.htmlSafe(`<span class="label-water-total">${total}&nbsp;${units}</span>`);
        }

        if (args.show == 'hot') {
          return Ember.String.htmlSafe(`<span class="label-water-hot">${hot}&nbsp;${intl.t('hot')}</span>`);
        }
      }
    }

  });

  _exports.default = _default;
});