define("alliance-business-suite/components/rooms/r-view-resets", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  let RoomsRViewResetsComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._action, _dec8 = Ember._action, (_class = class RoomsRViewResetsComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "ajax", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "notifications", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "isProcessing", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "isLoading", _descriptor6, this);
    }

    getVCMs() {
      this.isLoading = true;
      this.store.findAll('vcm', {
        adapterOptions: {
          roomId: this.args.room.id
        }
      }).then(() => {
        this.isLoading = false;
      }, () => {
        this.isLoading = false;
      });
    }

    resetVCM(vcm) {
      this.isProcessing = vcm.id;
      this.ajax.request(`/vcms/${vcm.id}/resetCount`, {
        method: 'PATCH'
      }).then(() => {
        this.notifications.success(this.intl.t('success'), {
          clearDuration: 5000,
          autoClear: true
        });
        this.isProcessing = false;
      }, () => {
        this.notifications.error(this.intl.t('error'), {
          clearDuration: 5000,
          autoClear: true
        });
        this.isProcessing = false;
      });
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "notifications", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isProcessing", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isLoading", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "getVCMs", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "getVCMs"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "resetVCM", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "resetVCM"), _class.prototype)), _class));
  _exports.default = RoomsRViewResetsComponent;
});