define("alliance-business-suite/models/system-alert", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    smsNumber: attr('string'),
    systemAlertTypeKey: attr('string'),
    emailAddress: attr('string'),
    userId: attr('number'),
    systemAlertTypeDisplay: attr('string')
  });

  _exports.default = _default;
});