define("alliance-business-suite/components/form-ui/steps/internal-supply", ["exports", "alliance-business-suite/mixins/init-property-names", "alliance-business-suite/utils/includesAny"], function (_exports, _initPropertyNames, _includesAny) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_initPropertyNames.default, {
    showCompartment3: Ember.computed('machineProgram._machineTypeCode', function () {
      if (this.machineProgram._machineAttributes.includes("MGD_M1_FLW")) {
        return true;
      }

      if (this.machineProgram._machineTypeCode == "FLW") {
        return false;
      } else {
        return true;
      }
    }),
    compartment1Disabled: Ember.computed('machineProgram._machineTypeCode', function () {
      if (this.machineProgram._machineAttributes.includes("MGD_M1_FLW")) {
        return false;
      }

      if (this.machineProgram._machineTypeCode == "FLW") {
        return true;
      } else {
        return false;
      }
    }),
    compartmentLabels: Ember.computed('machineProgram.{_machineAuditType,_machineAttributes}', function () {
      switch (true) {
        case this.machineProgram._machineAttributes.includes("MGD_M1_FLW"):
          return ['compartment_1', 'compartment_2', 'compartment_3', 'compartment_4'];

        case this.machineProgram._machineAttributes.includes("FLW"):
          // Frontload Washers
          return ['compartment_prewash_on', 'compartment_detergent_bleach', null, 'compartment_softener'];

        case this.machineProgram._machineAttributes.includes("IPSO_ACAS_A2"): // ISPO

        case this.machineProgram._machineAttributes.includes("ACAS_A22"):
          // ISPO
          return ['compartment_1_prewash', 'compartment_2_main_wash', 'compartment_3_bleach', 'compartment_4_softener'];

        case (0, _includesAny.default)(this.machineProgram._machineAttributes, ["ACAS_A44", "ACAS_A3"]):
          // Specific audit types
          return ['compartment1_prewash', 'compartment2_main_wash', 'compartment3_bleach', 'compartment4_softener'];

        default:
          // Everything else
          return ['compartment_1', 'compartment_2', 'compartment_3', 'compartment_4'];
      }
    })
  });

  _exports.default = _default;
});