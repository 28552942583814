define("alliance-business-suite/routes/machines/programs/export", ["exports", "@babel/runtime/helpers/esm/defineProperty", "alliance-business-suite/routes/_secureRoute"], function (_exports, _defineProperty2, _secureRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class MachinesProgramsManageRoute extends _secureRoute.default {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "allowAccess", 'MACHINES_PROGRAMS_VIEW');
      (0, _defineProperty2.default)(this, "disableBrush", true);
    }

    model(params) {
      return this.store.findRecord('machine-program', params.machine_program_id, {
        reload: true
      });
    }

  }

  _exports.default = MachinesProgramsManageRoute;
});