define("alliance-business-suite/components/machines/m-current-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    access: Ember.inject.service(),
    currentUser: null,
    isLoading: false,
    fetchCurrentUser: Ember.observer('status', 'model', function () {
      if (this.status == 'READY_TO_START' || this.status == 'IN_USE') {
        this.set('isLoading', true);
        this.ajax.request(`/machines/${this.model.get('id')}/user`, {
          method: 'GET'
        }).then(response => {
          if (!this.isDestroyed) {
            if (response.coinUser) {
              this.set('currentUser', null);
            } else {
              this.set('currentUser', `${response.firstName} ${response.lastName}`);
            }
          }
        }).finally(() => {
          if (!this.isDestroyed) {
            this.set('isLoading', false);
          }
        });
      } else {
        this.setProperties({
          currentUser: null,
          isLoading: false
        });
      }
    })
  });

  _exports.default = _default;
});