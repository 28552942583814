define("alliance-business-suite/templates/locations/rooms/manage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zVA1Sc6x",
    "block": "{\"symbols\":[],\"statements\":[[8,\"rooms/r-edit-form\",[],[[\"@model\"],[[34,0]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "moduleName": "alliance-business-suite/templates/locations/rooms/manage.hbs"
  });

  _exports.default = _default;
});