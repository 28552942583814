define("alliance-business-suite/components/wash-alert/wa-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    access: Ember.inject.service(),
    intl: Ember.inject.service(),
    hasChanged: false,
    activeRoom: Ember.computed('roomId', {
      get() {
        if (this.roomId) {
          return this.store.findRecord('room', this.roomId);
        } else if (this.rooms && this.rooms.length === 1) {// this.set('roomId', this.rooms.firstObject.id);
          // return this.rooms.firstObject;
        }
      },

      set(key, value) {
        Ember.set(this, 'roomId', value != null ? value.id : undefined);
        return value;
      }

    }),
    rooms: Ember.computed({
      get() {
        return Ember.get(this, 'store').peekAll('room');
      }

    }),
    machines: Ember.computed('activeRoom', {
      get() {
        return Ember.get(this, 'store').peekAll('machine').filter(m => {
          if (m.get('room.id') == this.get('activeRoom.id') && m.get('machineNumber')) {
            return m;
          }
        });
      }

    }),
    organizationId: Ember.computed({
      get() {
        return Ember.get(this, 'access.accessOrganization.id');
      }

    }),
    actions: {
      hasChanged(changed) {
        this.set('hasChanged', changed);
      },

      changeRoom(room) {
        if (this.hasChanged) {
          let abandonMessage = this.intl.t('wa_unsaved_changes_confirm');

          if (!window.confirm(abandonMessage)) {
            return false;
          } else {
            this.set('roomId', room.get('id'));
            this.set('hasChanged', false);
          }
        } else {
          this.set('roomId', room.get('id'));
        }
      }

    }
  });

  _exports.default = _default;
});