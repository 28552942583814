define("alliance-business-suite/controllers/not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({// This controller is required for storing transition data. Do not remove!
  });

  _exports.default = _default;
});