define("alliance-business-suite/templates/components/form-ui/textarea", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wNJhxGMc",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"text-field\"],[12],[2,\"\\n  \"],[10,\"label\"],[14,0,\"form-field--label form-ui-element\"],[12],[2,\"\\n    \"],[1,[34,0]],[2,\"\\n    \"],[8,\"textarea\",[[16,\"placeholder\",[34,2]],[24,0,\"form-field--control\"]],[[\"@value\",\"@disabled\",\"@rows\",\"@limit\",\"@maxlength\"],[[34,1],[34,3],[34,4,[\"rows\"]],[34,5],[34,4,[\"maxlength\"]]]],null],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[8,\"form-ui/dev-tools\",[],[[\"@outputs\"],[[30,[36,8],null,[[\"labelAutoTranslated\",\"propertyName\",\"limit\",\"rows\",\"maxlength\",\"rawValue\",\"value\"],[[35,7],[35,4,[\"propertyName\"]],[35,5],[35,4,[\"rows\"]],[35,4,[\"maxlength\"]],[35,6],[35,1]]]]]],null]],\"hasEval\":false,\"upvars\":[\"label\",\"value\",\"placeholder\",\"isDisabled\",\"params\",\"limit\",\"rawValue\",\"labelAutoTranslated\",\"hash\"]}",
    "moduleName": "alliance-business-suite/templates/components/form-ui/textarea.hbs"
  });

  _exports.default = _default;
});