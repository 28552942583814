define("alliance-business-suite/templates/components/rooms/r-view-gateways", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gPvSZCuN",
    "block": "{\"symbols\":[\"@gatewayUrl\"],\"statements\":[[10,\"div\"],[14,0,\"model-view\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"model-field\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"model-field__label\"],[12],[1,[30,[36,0],[\"gateway_url\"],null]],[13],[2,\"\\n    \"],[10,\"span\"],[14,0,\"model-field__value\"],[12],[2,\"\\n      \"],[10,\"a\"],[15,6,[32,1]],[14,\"target\",\"_blank\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"gateway\"],null]],[2,\"\\n        \"],[10,\"i\"],[14,0,\"fa fa-external-link\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "moduleName": "alliance-business-suite/templates/components/rooms/r-view-gateways.hbs"
  });

  _exports.default = _default;
});