define("alliance-business-suite/components/form-ui/steps/heater-options-temperature", ["exports", "alliance-business-suite/mixins/init-property-names", "alliance-business-suite/helpers/find-by-dropdown-with-custom", "alliance-business-suite/utils/machine-programming-custom-properties"], function (_exports, _initPropertyNames, _findByDropdownWithCustom, _machineProgrammingCustomProperties) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_initPropertyNames.default, {
    tagName: '',

    didReceiveAttrs() {
      this._super(...arguments); // If there is a custom property, we need to do some extra work


      if (this.hasCustomProperty && !this.isSharedProperty) {
        (0, _machineProgrammingCustomProperties.initCustomPropertyValues)({
          context: this,
          model: this.object,
          options: this.selectOptions.HeaterOptionsTemperature,
          propertyName: this.properties.Temperature.key,
          customPropertyName: this.properties.CustomTemperature.key,
          limits: this.params.validations.heaterOptionsTemperature
        });
      }
    },

    options: Ember.computed.alias('selectOptions.HeaterOptionsTemperature'),
    propertyName: Ember.computed.alias('properties.Temperature.key'),
    customPropertyName: Ember.computed.alias('properties.CustomTemperature.key'),
    model: Ember.computed.alias('object'),
    lastPresetValue: null,
    hasCustomProperty: (0, _machineProgrammingCustomProperties.hasCustomProperty)({
      model: 'object',
      options: 'selectOptions.HeaterOptionsTemperature',
      propertyName: 'properties.Temperature.key',
      customPropertyName: 'properties.CustomTemperature.key'
    }),
    stepLabelName: Ember.computed('Label', function () {
      let labelValue = Ember.get(this, 'Label');
      return (0, _findByDropdownWithCustom.findByDropDown)([Ember.get(this, 'formSettings.selectOptions')['StepLabel'], "val", labelValue]).group.toLowerCase();
    }),
    isDisabled: Ember.computed('AllowTempOverride', 'stepLabelName', 'TempControlledFill', 'machineProgram._machineAuditType', function () {
      // List any audit types that are always enabled (TODO: Should this be extracted to some config somewhere?)
      let enabledMachines = ['ACAS_A4', 'ACAS_A2', 'ACAS_A22', 'ACAS_A44', 'ACAS_A3', 'MGD_M7'];

      if (enabledMachines.includes(this.machineProgram._machineAuditType)) {
        return false;
      } // Disabled if this is a Prewash or Wash AND AllowTempOverride is true. Is this just for Midas machine?


      let hasOverride = ["prewash", "wash"].includes(this.stepLabelName); // Disabled if this is a Prewash or Wash AND AllowTempOverride is true.

      return hasOverride && this.AllowTempOverride;
    }),
    isSharedProperty: Ember.computed('machineProgram._machineAuditType', 'properties.CustomTemperature', function () {
      // Some audit types have a shared property regardless of CustomTemperature being present
      const sharedAuditTypes = ['MGD_M1', 'MGD_M2', 'MGD_M3', 'MGD_M4', 'MGD_M5', 'MGD_M6', 'MGD_M7'];

      if (sharedAuditTypes.includes(this.machineProgram._machineAuditType)) {
        return true;
      } // If there's a CustomTemperature property, then this is NOT a shared property


      return this.properties.CustomTemperature === undefined;
    }),
    findOptions: Ember.computed('properties.TempControlledFill', function () {
      // Has "Controlled" option if TempControlledFill property is present
      if (Ember.isNone(this.properties.TempControlledFill)) {
        return 'HeaterOptionsTemperature';
      } else {
        return 'HeaterOptionsTemperature';
      }
    }),
    actions: {
      // eslint-disable-line ember/no-actions-hash
      updateCustomProperty(selection) {
        (0, _machineProgrammingCustomProperties.updateCustomPropertyValues)({
          context: this,
          hasCustomProperty: _machineProgrammingCustomProperties.hasCustomProperty,
          model: this.object,
          selection: selection,
          lastPresetValue: this.lastPresetValue,
          propertyName: this.properties.Temperature.key,
          customPropertyName: this.properties.CustomTemperature.key
        });
      }

    }
  });

  _exports.default = _default;
});