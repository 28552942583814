define("alliance-business-suite/templates/components/employees/time-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c2eqpPcd",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[\"bar \",[34,0]]]],[15,5,[34,1]],[12],[2,\"\\n  \"],[10,\"span\"],[14,0,\"bar-start\"],[12],[1,[34,2]],[13],[2,\"\\n  \"],[10,\"span\"],[14,0,\"bar-end\"],[12],[1,[34,3]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"barType\",\"barWidth\",\"sTime\",\"eTime\"]}",
    "moduleName": "alliance-business-suite/templates/components/employees/time-bar.hbs"
  });

  _exports.default = _default;
});