define("alliance-business-suite/components/ui/modal-create-note", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // classNames: ['machine-view'],
    ajax: Ember.inject.service(),
    access: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    // classNames: ['order-brush--container'],
    note: null,

    didInsertElement() {
      this._super(...arguments);

      let store = this.store;
      let model = this.model;
      let note = store.createRecord('note', {
        order: model
      });
      this.set('note', note);
    },

    canSubmit: Ember.computed('note.noteText', function () {
      let note = this.note;

      if (note) {
        let noteText = note.get('noteText') || 0;

        if (noteText.length || noteText.length > 0) {
          return true;
        } else {
          return false;
        }
      }
    }),
    cards: Ember.computed('model', function () {
      let cards = ['general', 'history', 'line-items', 'notes'];
      return cards;
    }),
    actions: {
      close() {
        this.note.rollbackAttributes();

        if (!Ember.get(this, 'isLoading')) {
          this.close();
        }
      },

      apply() {
        Ember.set(this, 'isLoading', true);
        this.note.save({
          adapterOptions: {
            orderId: this.get('model.id')
          }
        }).then(() => {
          Ember.set(this, 'isLoading', false);
          this.close();
        });
      }

    }
  });

  _exports.default = _default;
});