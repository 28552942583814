define("alliance-business-suite/templates/components/ui/tab-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Uxp7Z4Ro",
    "block": "{\"symbols\":[\"tab\"],\"statements\":[[6,[37,11],[[30,[36,10],[[30,[36,10],[[35,9]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,8],[[32,1]],[[\"class\",\"intlPrefix\",\"count\",\"onclick\"],[[30,[36,7],[\"ui-tab-list__tab\",[30,[36,6],[[30,[36,5],[[35,4],[32,1]],null],\" ui-tab-list__tab--active\"],null]],null],[35,3],[30,[36,2],[[35,1],[32,1]],null],[30,[36,0],[[32,0],\"select\",[32,1]],null]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"counts\",\"get\",\"intlPrefix\",\"activeTab\",\"eq\",\"if\",\"concat\",\"ui/tab-item\",\"tabs\",\"-track-array\",\"each\"]}",
    "moduleName": "alliance-business-suite/templates/components/ui/tab-list.hbs"
  });

  _exports.default = _default;
});