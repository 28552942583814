define("alliance-business-suite/components/customers/orders/orders-container", ["exports", "jquery", "moment"], function (_exports, _jquery, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ORDER_STATUS = ['CUSTOMER_DROP_OFF', 'SERVICING', 'READY_FOR_PICKUP', 'CUSTOMER_PICKUP'];

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    startDate: Ember.computed(function () {
      return (0, _moment.default)().subtract(7, 'days').toDate();
    }),
    endDate: Ember.computed(function () {
      return (0, _moment.default)().toDate();
    }),
    rooms: Ember.computed(function () {
      return this.store.peekAll('room');
    }),
    statusOptions: Ember.computed(function () {
      return ORDER_STATUS;
    }).readOnly(),
    selectedStatus: Ember.computed(function () {
      return [ORDER_STATUS[0], ORDER_STATUS[1], ORDER_STATUS[2]];
    }),
    // locationOptions: computed(function() {
    //   return LOCATIONS;
    // }),
    //
    canSubmit: Ember.computed('selectedStatus.[]', function () {
      if (this.get('selectedStatus.length') > 0) {
        return true;
      }
    }),
    selectedLocation: Ember.computed('rooms.[]', function () {
      let rooms = this.rooms;
      return rooms.get('firstObject');
    }),

    getOrdersForRoom() {
      let selectedRoom = this.get('selectedLocation.id');
      let startDate = (0, _moment.default)(this.startDate).format('YYYY-MM-DD');
      let endDate = (0, _moment.default)(this.endDate).format('YYYY-MM-DD');
      let selectedStatus = this.selectedStatus;
      let statusQueryParam = '';
      selectedStatus.map(status => {
        statusQueryParam += `&statuses[]=${status}`;
      });
      this.set('isLoading', true);
      this.set('noResultsFound', false);
      this.ajax.request(`/rooms/${selectedRoom}/orders?raw=true&startDate=${startDate}&endDate=${endDate}${statusQueryParam}`, {
        method: 'GET'
      }).then(results => {
        if (results.length == 0) {
          this.set('noResultsFound', true);
        }

        this.set('isLoading', false);
        this.set('orders', results);
      }).catch(() => {
        this.set('isLoading', false);
        console.error('There was a problem retrieving orders');
        this.set('noResultsFound', true);
      });
    },

    actions: {
      submit() {
        this.getOrdersForRoom();
      },

      toggleAdvancedFilters() {
        this.toggleProperty('viewAdvanceFilters');
      }

    }
  });

  _exports.default = _default;
});