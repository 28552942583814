define("alliance-business-suite/components/summary/reports/s-daily-recon", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "alliance-business-suite/utils/currency-format-util", "alliance-business-suite/utils/report-tables-util"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _currencyFormatUtil, _reportTablesUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  let SummaryReportsSDailyReconComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, (_class = class SummaryReportsSDailyReconComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor3, this);
    }

    get translatedColumns() {
      return [{
        name: 'location',
        valuePath: 'name',
        width: 379,
        isFixed: 'left'
      }, {
        name: 'transaction_date',
        valuePath: 'transactionDate',
        width: 180
      }, {
        name: 'daily_total_amount',
        valuePath: 'dailyTotalAmount',
        width: 180
      }, {
        name: 'daily_cash_amount',
        valuePath: 'dailyCashAmount',
        width: 180
      }, {
        name: 'daily_tax_amount',
        valuePath: 'dailyTaxAmount',
        width: 180
      }, {
        name: 'goodsSubTotalAmount',
        valuePath: 'goodsSubTotalAmount',
        width: 180
      }, {
        name: 'goods_tax_total',
        valuePath: 'goodsTaxTotal',
        width: 180
      }, {
        name: 'goods_tax_cash',
        valuePath: 'goodsTaxCash',
        width: 180
      }, {
        name: 'goodsCashSubTotalAmount',
        valuePath: 'goodsCashSubTotalAmount',
        width: 180
      }, {
        name: 'goodsCreditSubTotalAmount',
        valuePath: 'goodsCreditSubTotalAmount',
        width: 180
      }, {
        name: 'goodsMobileSubTotalAmount',
        valuePath: 'goodsMobileSubTotalAmount',
        width: 180
      }, {
        name: 'goodsMobileRewardsSubTotalAmount',
        valuePath: 'goodsMobileRewardsSubTotalAmount',
        width: 180
      }, {
        name: 'goodsDiscountAmount',
        valuePath: 'goodsDiscountAmount',
        width: 180
      }, {
        name: 'machine_start_subtotal_amount',
        valuePath: 'machineStartSubTotalAmount',
        width: 223
      }, {
        name: 'machine_start_cash_subtotal_amount',
        valuePath: 'machineStartCashSubTotalAmount',
        width: 180
      }, {
        name: 'machine_start_credit_subtotal_amount',
        valuePath: 'machineStartCreditSubTotalAmount',
        width: 225
      }, {
        name: 'machine_start_mobile_subtotal_amount',
        valuePath: 'machineStartMobileSubTotalAmount',
        width: 275
      }, {
        name: 'machineStartMobileRewardsSubTotalAmount',
        valuePath: 'machineStartMobileRewardsSubTotalAmount',
        width: 275
      }, {
        name: 'machineStartWashersSubTotalAmount',
        valuePath: 'machineStartWashersSubTotalAmount',
        width: 275
      }, {
        name: 'machineStartDryersSubTotalAmount',
        valuePath: 'machineStartDryersSubTotalAmount',
        width: 275
      }, {
        name: 'serviceSubTotalAmount',
        valuePath: 'serviceSubTotalAmount',
        width: 180
      }, {
        name: 'service_tax_total',
        valuePath: 'serviceTaxTotal',
        width: 180
      }, {
        name: 'service_tax_cash',
        valuePath: 'serviceTaxCash',
        width: 180
      }, {
        name: 'serviceCashSubTotalAmount',
        valuePath: 'serviceCashSubTotalAmount',
        width: 180
      }, {
        name: 'serviceCreditSubTotalAmount',
        valuePath: 'serviceCreditSubTotalAmount',
        width: 180
      }, {
        name: 'serviceMobileSubTotalAmount',
        valuePath: 'serviceMobileSubTotalAmount',
        width: 180
      }, {
        name: 'serviceMobileRewardsSubTotalAmount',
        valuePath: 'serviceMobileRewardsSubTotalAmount',
        width: 180
      }, {
        name: 'serviceDiscountAmount',
        valuePath: 'serviceDiscountAmount',
        width: 180
      }, {
        name: 'averageTransactionAmount',
        valuePath: 'averageTransactionAmount',
        width: 180
      }, {
        name: 'machine_start_count',
        valuePath: 'machineStartCount',
        width: 180
      }, {
        name: 'total_trxn_count',
        valuePath: 'totalTrxnCount',
        width: 180
      }];
    }

    get rowsWithChildren() {
      let {
        model
      } = this.args;
      let geoBoundaries = model.geoBoundaries;
      let rowData = [];
      let geoData = [];
      let totalOrgGoodsSubTotalSum = 0;
      let totalOrgGoodsSubTotal = [];
      let totalOrgGoodsTaxSum = 0;
      let totalOrgGoodsTax = [];
      let totalOrgGoodsCashSum = 0;
      let totalOrgGoodsCash = [];
      let totalOrgGoodsCashSubTotalSum = 0;
      let totalOrgGoodsCashSubTotal = [];
      let totalOrgGoodsMachineStartCashSubTotalSum = 0;
      let totalOrgGoodsMachineStartCashSubTotal = [];
      let totalOrgGoodsCreditSubTotalSum = 0;
      let totalOrgGoodsCreditSubTotal = [];
      let totalOrgGoodsMachineStartCreditSubTotalSum = 0;
      let totalOrgGoodsMachineStartCreditSubTotal = [];
      let totalOrgGoodsMobileSubTotalSum = 0;
      let totalOrgGoodsMobileSubTotal = [];
      let totalOrgGoodsMobileRewardsSubTotalSum = 0;
      let totalOrgGoodsMobileRewardsSubTotal = [];
      let totalOrgGoodsMachineStartMobileSubTotalSum = 0;
      let totalOrgGoodsMachineStartMobileSubTotal = [];
      let totalOrgGoodsMachineStartMobileRewardsSubTotalSum = 0;
      let totalOrgGoodsMachineStartMobileRewardsSubTotal = [];
      let totalOrgGoodsMachineStartWashersSubTotalSum = 0;
      let totalOrgGoodsMachineStartWashersSubTotal = [];
      let totalOrgGoodsMachineStartDryersSubTotalSum = 0;
      let totalOrgGoodsMachineStartDryersSubTotal = [];
      let totalOrgGoodsMachineStartSubTotalSum = 0;
      let totalOrgGoodsMachineStartSubTotal = [];
      let totalOrgGoodsDiscountSum = 0;
      let totalOrgGoodsDiscount = [];
      let totalOrgServiceSubTotalSum = 0;
      let totalOrgServiceSubTotal = [];
      let totalOrgServiceTaxSum = 0;
      let totalOrgServiceTax = [];
      let totalOrgServiceTaxCashSum = 0;
      let totalOrgServiceTaxCash = [];
      let totalOrgServiceCashSubTotalSum = 0;
      let totalOrgServiceCashSubTotal = [];
      let totalOrgServiceCreditSubTotalSum = 0;
      let totalOrgServiceCreditSubTotal = [];
      let totalOrgServiceMobileSubTotalSum = 0;
      let totalOrgServiceMobileSubTotal = [];
      let totalOrgServiceMobileRewardsSubTotalSum = 0;
      let totalOrgServiceMobileRewardsSubTotal = [];
      let totalOrgServiceDiscountSum = 0;
      let totalOrgServiceDiscount = [];
      let totalOrgPosTrxnCountSum = 0;
      let totalOrgPosTrxnCount = [];
      let totalOrgAverageTransactionAmountSum = 0;
      let totalOrgAverageTransactionAmount = [];
      let totalOrgDailyTotalAmountSum = 0;
      let totalOrgDailyTaxAmount = [];
      let totalOrgDailyTaxAmountSum = 0;
      let totalOrgDailyCashAmount = [];
      let totalOrgDailyCashAmountSum = 0;
      let totalOrgDailyTotalAmount = [];
      let totalOrgMachineStartCountSum = 0;
      let totalOrgMachineStartCount = [];
      rowData.push({
        name: model.organizationName,
        goodsSubTotalAmount: totalOrgGoodsSubTotal,
        goodsTaxTotal: totalOrgGoodsTax,
        goodsTaxCash: totalOrgGoodsCash,
        goodsCashSubTotalAmount: totalOrgGoodsCashSubTotal,
        goodsCreditSubTotalAmount: totalOrgGoodsCreditSubTotal,
        goodsMobileSubTotalAmount: totalOrgGoodsMobileSubTotal,
        goodsMobileRewardsSubTotalAmount: totalOrgGoodsMobileRewardsSubTotal,
        machineStartCashSubTotalAmount: totalOrgGoodsMachineStartCashSubTotal,
        machineStartCreditSubTotalAmount: totalOrgGoodsMachineStartCreditSubTotal,
        machineStartMobileSubTotalAmount: totalOrgGoodsMachineStartMobileSubTotal,
        machineStartMobileRewardsSubTotalAmount: totalOrgGoodsMachineStartMobileRewardsSubTotal,
        machineStartWashersSubTotalAmount: totalOrgGoodsMachineStartWashersSubTotal,
        machineStartDryersSubTotalAmount: totalOrgGoodsMachineStartDryersSubTotal,
        machineStartSubTotalAmount: totalOrgGoodsMachineStartSubTotal,
        machineStartCount: totalOrgMachineStartCount,
        goodsDiscountAmount: totalOrgGoodsDiscount,
        serviceMobileRewardsSubTotalAmount: totalOrgServiceMobileRewardsSubTotal,
        serviceSubTotalAmount: totalOrgServiceSubTotal,
        serviceTaxCash: totalOrgServiceTaxCash,
        serviceTaxTotal: totalOrgServiceTax,
        serviceCashSubTotalAmount: totalOrgServiceCashSubTotal,
        serviceCreditSubTotalAmount: totalOrgServiceCreditSubTotal,
        serviceMobileSubTotalAmount: totalOrgServiceMobileSubTotal,
        serviceDiscountAmount: totalOrgServiceDiscount,
        totalTrxnCount: totalOrgPosTrxnCount,
        averageTransactionAmount: totalOrgAverageTransactionAmount,
        dailyCashAmount: totalOrgDailyCashAmount,
        dailyTaxAmount: totalOrgDailyTaxAmount,
        dailyTotalAmount: totalOrgDailyTotalAmount,
        children: geoData
      });
      geoBoundaries.forEach(geoBoundary => {
        let nameArray = [];
        let totalGoodsSubTotalSum = 0;
        let totalGoodsSubTotal = [];
        let totalGoodsTaxSum = 0;
        let totalGoodsTax = [];
        let totalGoodsCashSum = 0;
        let totalGoodsCash = [];
        let totalGoodsCashSubTotalSum = 0;
        let totalGoodsCashSubTotal = [];
        let totalGoodsMachineStartCashSubTotalSum = 0;
        let totalGoodsMachineStartCashSubTotal = [];
        let totalGoodsCreditSubTotalSum = 0;
        let totalGoodsCreditSubTotal = [];
        let totalGoodsMachineStartCreditSubTotalSum = 0;
        let totalGoodsMachineStartCreditSubTotal = [];
        let totalGoodsMobileSubTotalSum = 0;
        let totalGoodsMobileSubTotal = [];
        let totalGoodsMobileRewardsSubTotalSum = 0;
        let totalGoodsMobileRewardsSubTotal = [];
        let totalGoodsMachineStartMobileSubTotalSum = 0;
        let totalGoodsMachineStartMobileSubTotal = [];
        let totalGoodsMachineStartMobileRewardsSubTotalSum = 0;
        let totalGoodsMachineStartMobileRewardsSubTotal = [];
        let totalGoodsMachineStartWashersSubTotalSum = 0;
        let totalGoodsMachineStartWashersSubTotal = [];
        let totalGoodsMachineStartDryersSubTotalSum = 0;
        let totalGoodsMachineStartDryersSubTotal = [];
        let totalGoodsMachineStartSubTotalSum = 0;
        let totalGoodsMachineStartSubTotal = [];
        let totalMachineStartCountSum = 0;
        let totalMachineStartCount = [];
        let totalGoodsDiscountSum = 0;
        let totalGoodsDiscount = [];
        let totalServiceSubTotalSum = 0;
        let totalServiceSubTotal = [];
        let totalServiceTaxSum = 0;
        let totalServiceTax = [];
        let totalServiceTaxCashSum = 0;
        let totalServiceTaxCash = [];
        let totalServiceCashSubTotalSum = 0;
        let totalServiceCashSubTotal = [];
        let totalServiceCreditSubTotalSum = 0;
        let totalServiceCreditSubTotal = [];
        let totalServiceMobileSubTotalSum = 0;
        let totalServiceMobileSubTotal = [];
        let totalServiceMobileRewardsSubTotalSum = 0;
        let totalServiceMobileRewardsSubTotal = [];
        let totalServiceDiscountSum = 0;
        let totalServiceDiscount = [];
        let totalPosTrxnCountSum = 0;
        let totalPosTrxnCount = [];
        let totalAverageTransactionAmountSum = 0;
        let totalAverageTransactionAmount = [];
        let totalDailyTotalAmountSum = 0;
        let totalDailyTaxAmountSum = 0;
        let totalDailyTaxAmount = [];
        let totalDailyCashAmountSum = 0;
        let totalDailyCashAmount = [];
        let totalDailyTotalAmount = [];
        geoData.push({
          name: _reportTablesUtil.localizeString.call(this, geoBoundary.name, 'Location', 'location'),
          goodsSubTotalAmount: totalGoodsSubTotal,
          goodsTaxTotal: totalGoodsTax,
          goodsTaxCash: totalGoodsCash,
          goodsCashSubTotalAmount: totalGoodsCashSubTotal,
          goodsCreditSubTotalAmount: totalGoodsCreditSubTotal,
          goodsMobileSubTotalAmount: totalGoodsMobileSubTotal,
          // goodsMobileRewardsSubTotalAmount
          goodsMobileRewardsSubTotalAmount: totalGoodsMobileRewardsSubTotal,
          machineStartCashSubTotalAmount: totalGoodsMachineStartCashSubTotal,
          machineStartCreditSubTotalAmount: totalGoodsMachineStartCreditSubTotal,
          machineStartMobileSubTotalAmount: totalGoodsMachineStartMobileSubTotal,
          machineStartMobileRewardsSubTotalAmount: totalGoodsMachineStartMobileRewardsSubTotal,
          machineStartWashersSubTotalAmount: totalGoodsMachineStartWashersSubTotal,
          machineStartDryersSubTotalAmount: totalGoodsMachineStartDryersSubTotal,
          machineStartSubTotalAmount: totalGoodsMachineStartSubTotal,
          machineStartCount: totalMachineStartCount,
          goodsDiscountAmount: totalGoodsDiscount,
          serviceMobileRewardsSubTotalAmount: totalServiceMobileRewardsSubTotal,
          serviceSubTotalAmount: totalServiceSubTotal,
          serviceTaxCash: totalServiceTaxCash,
          serviceTaxTotal: totalServiceTax,
          serviceCashSubTotalAmount: totalServiceCashSubTotal,
          serviceCreditSubTotalAmount: totalServiceCreditSubTotal,
          serviceMobileSubTotalAmount: totalServiceMobileSubTotal,
          serviceDiscountAmount: totalServiceDiscount,
          totalTrxnCount: totalPosTrxnCount,
          averageTransactionAmount: totalAverageTransactionAmount,
          dailyTaxAmount: totalDailyTaxAmount,
          dailyCashAmount: totalDailyCashAmount,
          dailyTotalAmount: totalDailyTotalAmount,
          children: nameArray
        });
        geoBoundary.rooms.forEach(room => {
          let transactionArray = [];
          let totalRoomGoodsSubTotalSum = 0;
          let totalRoomGoodsSubTotal = [];
          let totalRoomGoodsTaxSum = 0;
          let totalRoomGoodsTax = [];
          let totalRoomGoodsCashSum = 0;
          let totalRoomGoodsCash = [];
          let totalRoomGoodsCashSubTotalSum = 0;
          let totalRoomGoodsCashSubTotal = [];
          let totalRoomGoodsMachineStartCashSubTotalSum = 0;
          let totalRoomGoodsMachineStartCashSubTotal = [];
          let totalRoomGoodsCreditSubTotalSum = 0;
          let totalRoomGoodsCreditSubTotal = [];
          let totalRoomGoodsMachineStartCreditSubTotalSum = 0;
          let totalRoomGoodsMachineStartCreditSubTotal = [];
          let totalRoomGoodsMobileSubTotalSum = 0;
          let totalRoomGoodsMobileSubTotal = [];
          let totalRoomGoodsMobileRewardsSubTotalSum = 0;
          let totalRoomGoodsMobileRewardsSubTotal = [];
          let totalRoomGoodsMachineStartMobileSubTotalSum = 0;
          let totalRoomGoodsMachineStartMobileSubTotal = [];
          let totalRoomGoodsMachineStartMobileRewardsSubTotalSum = 0;
          let totalRoomGoodsMachineStartMobileRewardsSubTotal = [];
          let totalRoomGoodsMachineStartWashersSubTotalSum = 0;
          let totalRoomGoodsMachineStartWashersSubTotal = [];
          let totalRoomGoodsMachineStartDryersSubTotalSum = 0;
          let totalRoomGoodsMachineStartDryersSubTotal = [];
          let totalRoomGoodsMachineStartSubTotalSum = 0;
          let totalRoomGoodsMachineStartSubTotal = [];
          let totalRoomMachineStartCountSum = 0;
          let totalRoomMachineStartCount = [];
          let totalRoomGoodsDiscountSum = 0;
          let totalRoomGoodsDiscount = [];
          let totalRoomServiceSubTotalSum = 0;
          let totalRoomServiceSubTotal = [];
          let totalRoomServiceTaxSum = 0;
          let totalRoomServiceTax = [];
          let totalRoomServiceTaxCashSum = 0;
          let totalRoomServiceTaxCash = [];
          let totalRoomServiceCashSubTotalSum = 0;
          let totalRoomServiceCashSubTotal = [];
          let totalRoomServiceCreditSubTotalSum = 0;
          let totalRoomServiceCreditSubTotal = [];
          let totalRoomServiceMobileSubTotalSum = 0;
          let totalRoomServiceMobileSubTotal = [];
          let totalRoomServiceMobileRewardsSubTotalSum = 0;
          let totalRoomServiceMobileRewardsSubTotal = [];
          let totalRoomServiceDiscountSum = 0;
          let totalRoomServiceDiscount = [];
          let totalRoomPosTrxnCountSum = 0;
          let totalRoomPosTrxnCount = [];
          let totalRoomAverageTransactionAmountSum = 0;
          let totalRoomAverageTransactionAmount = [];
          let totalRoomDailyTaxAmountSum = 0;
          let totalRoomDailyTotalAmountSum = 0;
          let totalRoomDailyTaxAmount = [];
          let totalRoomDailyCashAmountSum = 0;
          let totalRoomDailyCashAmount = [];
          let totalRoomDailyTotalAmount = [];
          nameArray.push({
            name: room.name,
            goodsSubTotalAmount: totalRoomGoodsSubTotal,
            goodsTaxTotal: totalRoomGoodsTax,
            goodsTaxCash: totalRoomGoodsCash,
            goodsCashSubTotalAmount: totalRoomGoodsCashSubTotal,
            goodsCreditSubTotalAmount: totalRoomGoodsCreditSubTotal,
            goodsMobileSubTotalAmount: totalRoomGoodsMobileSubTotal,
            goodsMobileRewardsSubTotalAmount: totalRoomGoodsMobileRewardsSubTotal,
            machineStartCashSubTotalAmount: totalRoomGoodsMachineStartCashSubTotal,
            machineStartCreditSubTotalAmount: totalRoomGoodsMachineStartCreditSubTotal,
            machineStartMobileSubTotalAmount: totalRoomGoodsMachineStartMobileSubTotal,
            machineStartMobileRewardsSubTotalAmount: totalRoomGoodsMachineStartMobileRewardsSubTotal,
            machineStartWashersSubTotalAmount: totalRoomGoodsMachineStartWashersSubTotal,
            machineStartDryersSubTotalAmount: totalRoomGoodsMachineStartDryersSubTotal,
            machineStartSubTotalAmount: totalRoomGoodsMachineStartSubTotal,
            machineStartCount: totalRoomMachineStartCount,
            goodsDiscountAmount: totalRoomGoodsDiscount,
            serviceMobileRewardsSubTotalAmount: totalRoomServiceMobileRewardsSubTotal,
            serviceSubTotalAmount: totalRoomServiceSubTotal,
            serviceTaxCash: totalRoomServiceTaxCash,
            serviceTaxTotal: totalRoomServiceTax,
            serviceCashSubTotalAmount: totalRoomServiceCashSubTotal,
            serviceCreditSubTotalAmount: totalRoomServiceCreditSubTotal,
            serviceMobileSubTotalAmount: totalRoomServiceMobileSubTotal,
            serviceDiscountAmount: totalRoomServiceDiscount,
            totalTrxnCount: totalRoomPosTrxnCount,
            averageTransactionAmount: totalRoomAverageTransactionAmount,
            dailyCashAmount: totalRoomDailyCashAmount,
            dailyTaxAmount: totalRoomDailyTaxAmount,
            dailyTotalAmount: totalRoomDailyTotalAmount,
            children: transactionArray
          });
          room.data.forEach(transaction => {
            let roomGoodsSubTotalAmountArray = [];
            let roomGoodsTaxAmountArray = [];
            let roomGoodsCashAmountArray = [];
            let roomGoodsCashSubTotalAmountArray = [];
            let roomGoodsCreditSubTotalAmountArray = [];
            let roomGoodsMobileSubTotalAmountArray = [];
            let roomGoodsMobileRewardsSubTotalAmountArray = [];
            let roomGoodsMachineStartCashSubTotalAmountArray = [];
            let roomGoodsMachineStartCreditSubTotalAmountArray = [];
            let roomGoodsMachineStartMobileSubTotalAmountArray = [];
            let roomGoodsMachineStartMobileRewardsSubTotalAmountArray = [];
            let roomGoodsMachineStartWashersSubTotalAmountArray = [];
            let roomGoodsMachineStartDryersSubTotalAmountArray = [];
            let roomGoodsMachineStartSubTotalAmountArray = [];
            let roomMachineStartCountArray = [];
            let roomGoodsDiscountAmountArray = [];
            let roomServiceSubTotalAmountArray = [];
            let roomServiceTaxAmountArray = [];
            let roomServiceTaxCashAmountArray = [];
            let roomServiceCashSubTotalAmountArray = [];
            let roomServiceCreditSubTotalAmountArray = [];
            let roomServiceMobileSubTotalAmountArray = [];
            let roomServiceMobileRewardsSubTotalAmountArray = [];
            let roomServiceDiscountAmountArray = [];
            let roomPosTrxnCountArray = [];
            let roomAverageTransactionAmountArray = [];
            let roomDailyTotalAmountArray = [];
            let roomDailyTaxAmountArray = [];
            let roomDailyCashAmountArray = [];
            let averageTransaction = transaction.dailyTotalAmount || 0;
            let posTransactionCount = transaction.totalTrxnCount || 0;
            let averageTransactionAmount = 0;

            if (posTransactionCount > 0) {
              averageTransactionAmount = averageTransaction / posTransactionCount;
            }

            if (transaction.goodsSubTotalAmount !== 0) {
              roomGoodsSubTotalAmountArray.push(transaction.goodsSubTotalAmount);
              totalOrgGoodsSubTotalSum = (0, _reportTablesUtil.sumArray)(roomGoodsSubTotalAmountArray, totalOrgGoodsSubTotalSum);
              totalGoodsSubTotalSum = (0, _reportTablesUtil.sumArray)(roomGoodsSubTotalAmountArray, totalGoodsSubTotalSum);
              totalRoomGoodsSubTotalSum = (0, _reportTablesUtil.sumArray)(roomGoodsSubTotalAmountArray, totalRoomGoodsSubTotalSum);
            }

            if (transaction.goodsTaxTotal !== 0) {
              roomGoodsTaxAmountArray.push(transaction.goodsTaxTotal);
              totalOrgGoodsTaxSum = (0, _reportTablesUtil.sumArray)(roomGoodsTaxAmountArray, totalOrgGoodsTaxSum);
              totalGoodsTaxSum = (0, _reportTablesUtil.sumArray)(roomGoodsTaxAmountArray, totalGoodsTaxSum);
              totalRoomGoodsTaxSum = (0, _reportTablesUtil.sumArray)(roomGoodsTaxAmountArray, totalRoomGoodsTaxSum);
            }

            if (transaction.goodsTaxCash !== 0) {
              roomGoodsCashAmountArray.push(transaction.goodsTaxCash);
              totalOrgGoodsCashSum = (0, _reportTablesUtil.sumArray)(roomGoodsCashAmountArray, totalOrgGoodsCashSum);
              totalGoodsCashSum = (0, _reportTablesUtil.sumArray)(roomGoodsCashAmountArray, totalGoodsCashSum);
              totalRoomGoodsCashSum = (0, _reportTablesUtil.sumArray)(roomGoodsCashAmountArray, totalRoomGoodsCashSum);
            }

            if (transaction.goodsCashSubTotalAmount !== 0) {
              roomGoodsCashSubTotalAmountArray.push(transaction.goodsCashSubTotalAmount);
              totalGoodsCashSubTotalSum = (0, _reportTablesUtil.sumArray)(roomGoodsCashSubTotalAmountArray, totalGoodsCashSubTotalSum);
              totalOrgGoodsCashSubTotalSum = (0, _reportTablesUtil.sumArray)(roomGoodsCashSubTotalAmountArray, totalOrgGoodsCashSubTotalSum);
              totalRoomGoodsCashSubTotalSum = (0, _reportTablesUtil.sumArray)(roomGoodsCashSubTotalAmountArray, totalRoomGoodsCashSubTotalSum);
            }

            if (transaction.goodsCreditSubTotalAmount !== 0) {
              roomGoodsCreditSubTotalAmountArray.push(transaction.goodsCreditSubTotalAmount);
              totalOrgGoodsCreditSubTotalSum = (0, _reportTablesUtil.sumArray)(roomGoodsCreditSubTotalAmountArray, totalOrgGoodsCreditSubTotalSum);
              totalGoodsCreditSubTotalSum = (0, _reportTablesUtil.sumArray)(roomGoodsCreditSubTotalAmountArray, totalGoodsCreditSubTotalSum);
              totalRoomGoodsCreditSubTotalSum = (0, _reportTablesUtil.sumArray)(roomGoodsCreditSubTotalAmountArray, totalRoomGoodsCreditSubTotalSum);
            }

            if (transaction.goodsMobileSubTotalAmount !== 0) {
              roomGoodsMobileSubTotalAmountArray.push(transaction.goodsMobileSubTotalAmount);
              totalOrgGoodsMobileSubTotalSum = (0, _reportTablesUtil.sumArray)(roomGoodsMobileSubTotalAmountArray, totalOrgGoodsMobileSubTotalSum);
              totalGoodsMobileSubTotalSum = (0, _reportTablesUtil.sumArray)(roomGoodsMobileSubTotalAmountArray, totalGoodsMobileSubTotalSum);
              totalRoomGoodsMobileSubTotalSum = (0, _reportTablesUtil.sumArray)(roomGoodsMobileSubTotalAmountArray, totalRoomGoodsMobileSubTotalSum);
            }

            if (transaction.goodsMobileRewardsSubTotalAmount !== 0) {
              roomGoodsMobileRewardsSubTotalAmountArray.push(transaction.goodsMobileRewardsSubTotalAmount);
              totalOrgGoodsMobileRewardsSubTotalSum = (0, _reportTablesUtil.sumArray)(roomGoodsMobileRewardsSubTotalAmountArray, totalOrgGoodsMobileRewardsSubTotalSum);
              totalGoodsMobileRewardsSubTotalSum = (0, _reportTablesUtil.sumArray)(roomGoodsMobileRewardsSubTotalAmountArray, totalGoodsMobileRewardsSubTotalSum);
              totalRoomGoodsMobileRewardsSubTotalSum = (0, _reportTablesUtil.sumArray)(roomGoodsMobileRewardsSubTotalAmountArray, totalRoomGoodsMobileRewardsSubTotalSum);
            }

            if (transaction.machineStartCashSubTotalAmount !== 0) {
              roomGoodsMachineStartCashSubTotalAmountArray.push(transaction.machineStartCashSubTotalAmount);
              totalOrgGoodsMachineStartCashSubTotalSum = (0, _reportTablesUtil.sumArray)(roomGoodsMachineStartCashSubTotalAmountArray, totalOrgGoodsMachineStartCashSubTotalSum);
              totalGoodsMachineStartCashSubTotalSum = (0, _reportTablesUtil.sumArray)(roomGoodsMachineStartCashSubTotalAmountArray, totalGoodsMachineStartCashSubTotalSum);
              totalRoomGoodsMachineStartCashSubTotalSum = (0, _reportTablesUtil.sumArray)(roomGoodsMachineStartCashSubTotalAmountArray, totalRoomGoodsMachineStartCashSubTotalSum);
            }

            if (transaction.machineStartCreditSubTotalAmount !== 0) {
              roomGoodsMachineStartCreditSubTotalAmountArray.push(transaction.machineStartCreditSubTotalAmount);
              totalOrgGoodsMachineStartCreditSubTotalSum = (0, _reportTablesUtil.sumArray)(roomGoodsMachineStartCreditSubTotalAmountArray, totalOrgGoodsMachineStartCreditSubTotalSum);
              totalGoodsMachineStartCreditSubTotalSum = (0, _reportTablesUtil.sumArray)(roomGoodsMachineStartCreditSubTotalAmountArray, totalGoodsMachineStartCreditSubTotalSum);
              totalRoomGoodsMachineStartCreditSubTotalSum = (0, _reportTablesUtil.sumArray)(roomGoodsMachineStartCreditSubTotalAmountArray, totalRoomGoodsMachineStartCreditSubTotalSum);
            }

            if (transaction.machineStartMobileSubTotalAmount !== 0) {
              roomGoodsMachineStartMobileSubTotalAmountArray.push(transaction.machineStartMobileSubTotalAmount);
              totalOrgGoodsMachineStartMobileSubTotalSum = (0, _reportTablesUtil.sumArray)(roomGoodsMachineStartMobileSubTotalAmountArray, totalOrgGoodsMachineStartMobileSubTotalSum);
              totalGoodsMachineStartMobileSubTotalSum = (0, _reportTablesUtil.sumArray)(roomGoodsMachineStartMobileSubTotalAmountArray, totalGoodsMachineStartMobileSubTotalSum);
              totalRoomGoodsMachineStartMobileSubTotalSum = (0, _reportTablesUtil.sumArray)(roomGoodsMachineStartMobileSubTotalAmountArray, totalRoomGoodsMachineStartMobileSubTotalSum);
            }

            if (transaction.machineStartMobileRewardsSubTotalAmount !== 0) {
              roomGoodsMachineStartMobileRewardsSubTotalAmountArray.push(transaction.machineStartMobileRewardsSubTotalAmount);
              totalOrgGoodsMachineStartMobileRewardsSubTotalSum = (0, _reportTablesUtil.sumArray)(roomGoodsMachineStartMobileRewardsSubTotalAmountArray, totalOrgGoodsMachineStartMobileRewardsSubTotalSum);
              totalGoodsMachineStartMobileRewardsSubTotalSum = (0, _reportTablesUtil.sumArray)(roomGoodsMachineStartMobileRewardsSubTotalAmountArray, totalGoodsMachineStartMobileRewardsSubTotalSum);
              totalRoomGoodsMachineStartMobileRewardsSubTotalSum = (0, _reportTablesUtil.sumArray)(roomGoodsMachineStartMobileRewardsSubTotalAmountArray, totalRoomGoodsMachineStartMobileRewardsSubTotalSum);
            } //


            if (transaction.machineStartWashersSubTotalAmount !== 0) {
              roomGoodsMachineStartWashersSubTotalAmountArray.push(transaction.machineStartWashersSubTotalAmount);
              totalOrgGoodsMachineStartWashersSubTotalSum = (0, _reportTablesUtil.sumArray)(roomGoodsMachineStartWashersSubTotalAmountArray, totalOrgGoodsMachineStartWashersSubTotalSum);
              totalGoodsMachineStartWashersSubTotalSum = (0, _reportTablesUtil.sumArray)(roomGoodsMachineStartWashersSubTotalAmountArray, totalGoodsMachineStartWashersSubTotalSum);
              totalRoomGoodsMachineStartWashersSubTotalSum = (0, _reportTablesUtil.sumArray)(roomGoodsMachineStartWashersSubTotalAmountArray, totalRoomGoodsMachineStartWashersSubTotalSum);
            } //


            if (transaction.machineStartDryersSubTotalAmount !== 0) {
              roomGoodsMachineStartDryersSubTotalAmountArray.push(transaction.machineStartDryersSubTotalAmount);
              totalOrgGoodsMachineStartDryersSubTotalSum = (0, _reportTablesUtil.sumArray)(roomGoodsMachineStartDryersSubTotalAmountArray, totalOrgGoodsMachineStartDryersSubTotalSum);
              totalGoodsMachineStartDryersSubTotalSum = (0, _reportTablesUtil.sumArray)(roomGoodsMachineStartDryersSubTotalAmountArray, totalGoodsMachineStartDryersSubTotalSum);
              totalRoomGoodsMachineStartDryersSubTotalSum = (0, _reportTablesUtil.sumArray)(roomGoodsMachineStartDryersSubTotalAmountArray, totalRoomGoodsMachineStartDryersSubTotalSum);
            }

            if (transaction.machineStartSubTotalAmount !== 0) {
              roomGoodsMachineStartSubTotalAmountArray.push(transaction.machineStartSubTotalAmount);
              totalOrgGoodsMachineStartSubTotalSum = (0, _reportTablesUtil.sumArray)(roomGoodsMachineStartSubTotalAmountArray, totalOrgGoodsMachineStartSubTotalSum);
              totalGoodsMachineStartSubTotalSum = (0, _reportTablesUtil.sumArray)(roomGoodsMachineStartSubTotalAmountArray, totalGoodsMachineStartSubTotalSum);
              totalRoomGoodsMachineStartSubTotalSum = (0, _reportTablesUtil.sumArray)(roomGoodsMachineStartSubTotalAmountArray, totalRoomGoodsMachineStartSubTotalSum);
            }

            if (transaction.machineStartCount !== 0) {
              roomMachineStartCountArray.push(transaction.machineStartCount);
              totalOrgMachineStartCountSum = (0, _reportTablesUtil.sumArray)(roomMachineStartCountArray, totalOrgMachineStartCountSum);
              totalMachineStartCountSum = (0, _reportTablesUtil.sumArray)(roomMachineStartCountArray, totalMachineStartCountSum);
              totalRoomMachineStartCountSum = (0, _reportTablesUtil.sumArray)(roomMachineStartCountArray, totalRoomMachineStartCountSum);
            }

            if (transaction.goodsDiscountAmount !== 0) {
              roomGoodsDiscountAmountArray.push(transaction.goodsDiscountAmount);
              totalOrgGoodsDiscountSum = (0, _reportTablesUtil.sumArray)(roomGoodsDiscountAmountArray, totalOrgGoodsDiscountSum);
              totalGoodsDiscountSum = (0, _reportTablesUtil.sumArray)(roomGoodsDiscountAmountArray, totalGoodsDiscountSum);
              totalRoomGoodsDiscountSum = (0, _reportTablesUtil.sumArray)(roomGoodsDiscountAmountArray, totalRoomGoodsDiscountSum);
            }

            if (transaction.serviceMobileRewardsSubTotalAmount !== 0) {
              roomServiceMobileRewardsSubTotalAmountArray.push(transaction.serviceMobileRewardsSubTotalAmount);
              totalOrgServiceMobileRewardsSubTotalSum = (0, _reportTablesUtil.sumArray)(roomServiceMobileRewardsSubTotalAmountArray, totalOrgServiceMobileRewardsSubTotalSum);
              totalServiceMobileRewardsSubTotalSum = (0, _reportTablesUtil.sumArray)(roomServiceMobileRewardsSubTotalAmountArray, totalServiceMobileRewardsSubTotalSum);
              totalRoomServiceMobileSubTotalSum = (0, _reportTablesUtil.sumArray)(roomServiceMobileRewardsSubTotalAmountArray, totalRoomServiceMobileSubTotalSum);
            }

            if (transaction.serviceSubTotalAmount !== 0) {
              roomServiceSubTotalAmountArray.push(transaction.serviceSubTotalAmount);
              totalOrgServiceSubTotalSum = (0, _reportTablesUtil.sumArray)(roomServiceSubTotalAmountArray, totalOrgServiceSubTotalSum);
              totalServiceSubTotalSum = (0, _reportTablesUtil.sumArray)(roomServiceSubTotalAmountArray, totalServiceSubTotalSum);
              totalRoomServiceSubTotalSum = (0, _reportTablesUtil.sumArray)(roomServiceSubTotalAmountArray, totalRoomServiceSubTotalSum);
            }

            if (transaction.serviceTaxTotal !== 0) {
              roomServiceTaxAmountArray.push(transaction.serviceTaxTotal);
              totalOrgServiceTaxSum = (0, _reportTablesUtil.sumArray)(roomServiceTaxAmountArray, totalOrgServiceTaxSum);
              totalServiceTaxSum = (0, _reportTablesUtil.sumArray)(roomServiceTaxAmountArray, totalServiceTaxSum);
              totalRoomServiceTaxSum = (0, _reportTablesUtil.sumArray)(roomServiceTaxAmountArray, totalRoomServiceTaxSum);
            }

            if (transaction.serviceTaxCash !== 0) {
              roomServiceTaxCashAmountArray.push(transaction.serviceTaxCash);
              totalOrgServiceTaxCashSum = (0, _reportTablesUtil.sumArray)(roomServiceTaxCashAmountArray, totalOrgServiceTaxCashSum);
              totalServiceTaxCashSum = (0, _reportTablesUtil.sumArray)(roomServiceTaxCashAmountArray, totalServiceTaxCashSum);
              totalRoomServiceTaxCashSum = (0, _reportTablesUtil.sumArray)(roomServiceTaxCashAmountArray, totalRoomServiceTaxCashSum);
            }

            if (transaction.serviceCashSubTotalAmount !== 0) {
              roomServiceCashSubTotalAmountArray.push(transaction.serviceCashSubTotalAmount);
              totalOrgServiceCashSubTotalSum = (0, _reportTablesUtil.sumArray)(roomServiceCashSubTotalAmountArray, totalOrgServiceCashSubTotalSum);
              totalServiceCashSubTotalSum = (0, _reportTablesUtil.sumArray)(roomServiceCashSubTotalAmountArray, totalServiceCashSubTotalSum);
              totalRoomServiceCashSubTotalSum = (0, _reportTablesUtil.sumArray)(roomServiceCashSubTotalAmountArray, totalRoomServiceCashSubTotalSum);
            }

            if (transaction.serviceCreditSubTotalAmount !== 0) {
              roomServiceCreditSubTotalAmountArray.push(transaction.serviceCreditSubTotalAmount);
              totalOrgServiceCreditSubTotalSum = (0, _reportTablesUtil.sumArray)(roomServiceCreditSubTotalAmountArray, totalOrgServiceCreditSubTotalSum);
              totalServiceCreditSubTotalSum = (0, _reportTablesUtil.sumArray)(roomServiceCreditSubTotalAmountArray, totalServiceCreditSubTotalSum);
              totalRoomServiceCreditSubTotalSum = (0, _reportTablesUtil.sumArray)(roomServiceCreditSubTotalAmountArray, totalRoomServiceCreditSubTotalSum);
            }

            if (transaction.serviceMobileSubTotalAmount !== 0) {
              roomServiceMobileSubTotalAmountArray.push(transaction.serviceMobileSubTotalAmount);
              totalOrgServiceMobileSubTotalSum = (0, _reportTablesUtil.sumArray)(roomServiceMobileSubTotalAmountArray, totalOrgServiceMobileSubTotalSum);
              totalServiceMobileSubTotalSum = (0, _reportTablesUtil.sumArray)(roomServiceMobileSubTotalAmountArray, totalServiceMobileSubTotalSum);
              totalRoomServiceMobileSubTotalSum = (0, _reportTablesUtil.sumArray)(roomServiceMobileSubTotalAmountArray, totalRoomServiceMobileSubTotalSum);
            }

            if (transaction.serviceDiscountAmount !== 0) {
              roomServiceDiscountAmountArray.push(transaction.serviceDiscountAmount);
              totalOrgServiceDiscountSum = (0, _reportTablesUtil.sumArray)(roomServiceDiscountAmountArray, totalOrgServiceDiscountSum);
              totalServiceDiscountSum = (0, _reportTablesUtil.sumArray)(roomServiceDiscountAmountArray, totalServiceDiscountSum);
              totalRoomServiceDiscountSum = (0, _reportTablesUtil.sumArray)(roomServiceDiscountAmountArray, totalRoomServiceDiscountSum);
            }

            if (transaction.totalTrxnCount !== 0) {
              roomPosTrxnCountArray.push(transaction.totalTrxnCount);
              totalOrgPosTrxnCountSum = (0, _reportTablesUtil.sumArray)(roomPosTrxnCountArray, totalOrgPosTrxnCountSum);
              totalPosTrxnCountSum = (0, _reportTablesUtil.sumArray)(roomPosTrxnCountArray, totalPosTrxnCountSum);
              totalRoomPosTrxnCountSum = (0, _reportTablesUtil.sumArray)(roomPosTrxnCountArray, totalRoomPosTrxnCountSum);
            }

            if (averageTransactionAmount !== 0) {
              roomAverageTransactionAmountArray.push(averageTransactionAmount);
              totalOrgAverageTransactionAmountSum = (0, _reportTablesUtil.sumArray)(roomAverageTransactionAmountArray, totalOrgAverageTransactionAmountSum);
              totalAverageTransactionAmountSum = (0, _reportTablesUtil.sumArray)(roomAverageTransactionAmountArray, totalAverageTransactionAmountSum);
              totalRoomAverageTransactionAmountSum = (0, _reportTablesUtil.sumArray)(roomAverageTransactionAmountArray, totalRoomAverageTransactionAmountSum);
            }

            if (transaction.dailyTotalAmount !== 0) {
              roomDailyTotalAmountArray.push(transaction.dailyTotalAmount);
              totalOrgDailyTotalAmountSum = (0, _reportTablesUtil.sumArray)(roomDailyTotalAmountArray, totalOrgDailyTotalAmountSum);
              totalDailyTotalAmountSum = (0, _reportTablesUtil.sumArray)(roomDailyTotalAmountArray, totalDailyTotalAmountSum);
              totalRoomDailyTotalAmountSum = (0, _reportTablesUtil.sumArray)(roomDailyTotalAmountArray, totalRoomDailyTotalAmountSum);
            }

            if (transaction.dailyTaxAmount !== 0) {
              roomDailyTaxAmountArray.push(transaction.dailyTaxAmount);
              totalOrgDailyTaxAmountSum = (0, _reportTablesUtil.sumArray)(roomDailyTaxAmountArray, totalOrgDailyTaxAmountSum);
              totalDailyTaxAmountSum = (0, _reportTablesUtil.sumArray)(roomDailyTaxAmountArray, totalDailyTaxAmountSum);
              totalRoomDailyTaxAmountSum = (0, _reportTablesUtil.sumArray)(roomDailyTaxAmountArray, totalRoomDailyTaxAmountSum);
            }

            if (transaction.dailyCashAmount !== 0) {
              roomDailyCashAmountArray.push(transaction.dailyCashAmount);
              totalOrgDailyCashAmountSum = (0, _reportTablesUtil.sumArray)(roomDailyCashAmountArray, totalOrgDailyCashAmountSum);
              totalDailyCashAmountSum = (0, _reportTablesUtil.sumArray)(roomDailyCashAmountArray, totalDailyCashAmountSum);
              totalRoomDailyCashAmountSum = (0, _reportTablesUtil.sumArray)(roomDailyCashAmountArray, totalRoomDailyCashAmountSum);
            }

            transactionArray.push({
              transactionDate: transaction.transactionDate,
              goodsSubTotalAmount: _currencyFormatUtil.formatCurrencyString.call(this, transaction.goodsSubTotalAmount || 0),
              goodsTaxTotal: _currencyFormatUtil.formatCurrencyString.call(this, transaction.goodsTaxTotal || 0),
              goodsTaxCash: _currencyFormatUtil.formatCurrencyString.call(this, transaction.goodsTaxCash || 0),
              goodsCashSubTotalAmount: _currencyFormatUtil.formatCurrencyString.call(this, transaction.goodsCashSubTotalAmount || 0),
              goodsCreditSubTotalAmount: _currencyFormatUtil.formatCurrencyString.call(this, transaction.goodsCreditSubTotalAmount || 0),
              goodsMobileSubTotalAmount: _currencyFormatUtil.formatCurrencyString.call(this, transaction.goodsMobileSubTotalAmount || 0),
              goodsMobileRewardsSubTotalAmount: _currencyFormatUtil.formatCurrencyString.call(this, transaction.goodsMobileRewardsSubTotalAmount || 0),
              machineStartCashSubTotalAmount: _currencyFormatUtil.formatCurrencyString.call(this, transaction.machineStartCashSubTotalAmount || 0),
              machineStartCreditSubTotalAmount: _currencyFormatUtil.formatCurrencyString.call(this, transaction.machineStartCreditSubTotalAmount || 0),
              machineStartMobileSubTotalAmount: _currencyFormatUtil.formatCurrencyString.call(this, transaction.machineStartMobileSubTotalAmount || 0),
              machineStartMobileRewardsSubTotalAmount: _currencyFormatUtil.formatCurrencyString.call(this, transaction.machineStartMobileRewardsSubTotalAmount || 0),
              machineStartWashersSubTotalAmount: _currencyFormatUtil.formatCurrencyString.call(this, transaction.machineStartWashersSubTotalAmount || 0),
              machineStartDryersSubTotalAmount: _currencyFormatUtil.formatCurrencyString.call(this, transaction.machineStartDryersSubTotalAmount || 0),
              machineStartSubTotalAmount: _currencyFormatUtil.formatCurrencyString.call(this, transaction.machineStartSubTotalAmount || 0),
              machineStartCount: transaction.machineStartCount || 0,
              goodsDiscountAmount: _currencyFormatUtil.formatCurrencyString.call(this, transaction.goodsDiscountAmount || 0),
              serviceMobileRewardsSubTotalAmount: _currencyFormatUtil.formatCurrencyString.call(this, transaction.serviceMobileRewardsSubTotalAmount || 0),
              serviceSubTotalAmount: _currencyFormatUtil.formatCurrencyString.call(this, transaction.serviceSubTotalAmount || 0),
              serviceTaxCash: _currencyFormatUtil.formatCurrencyString.call(this, transaction.serviceTaxCash || 0),
              serviceTaxTotal: _currencyFormatUtil.formatCurrencyString.call(this, transaction.serviceTaxTotal || 0),
              serviceCashSubTotalAmount: _currencyFormatUtil.formatCurrencyString.call(this, transaction.serviceCashSubTotalAmount || 0),
              serviceCreditSubTotalAmount: _currencyFormatUtil.formatCurrencyString.call(this, transaction.serviceCreditSubTotalAmount || 0),
              serviceMobileSubTotalAmount: _currencyFormatUtil.formatCurrencyString.call(this, transaction.serviceMobileSubTotalAmount || 0),
              serviceDiscountAmount: _currencyFormatUtil.formatCurrencyString.call(this, transaction.serviceDiscountAmount || 0),
              totalTrxnCount: transaction.totalTrxnCount || 0,
              averageTransactionAmount: _currencyFormatUtil.formatCurrencyString.call(this, averageTransactionAmount || 0),
              dailyTotalAmount: _currencyFormatUtil.formatCurrencyString.call(this, transaction.dailyTotalAmount || 0),
              dailyTaxAmount: _currencyFormatUtil.formatCurrencyString.call(this, transaction.dailyTaxAmount || 0),
              dailyCashAmount: _currencyFormatUtil.formatCurrencyString.call(this, transaction.dailyCashAmount || 0)
            });
          });
          totalRoomGoodsSubTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalRoomGoodsSubTotalSum || 0));
          totalRoomGoodsTax.push(_currencyFormatUtil.formatCurrencyString.call(this, totalRoomGoodsTaxSum || 0));
          totalRoomGoodsCash.push(_currencyFormatUtil.formatCurrencyString.call(this, totalRoomGoodsCashSum || 0));
          totalRoomGoodsCashSubTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalRoomGoodsCashSubTotalSum || 0));
          totalRoomGoodsCreditSubTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalRoomGoodsCreditSubTotalSum || 0));
          totalRoomGoodsMobileSubTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalRoomGoodsMobileSubTotalSum || 0));
          totalRoomGoodsMobileRewardsSubTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalRoomGoodsMobileRewardsSubTotalSum || 0));
          totalRoomGoodsMachineStartCashSubTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalRoomGoodsMachineStartCashSubTotalSum || 0));
          totalRoomGoodsMachineStartCreditSubTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalRoomGoodsMachineStartCreditSubTotalSum || 0));
          totalRoomGoodsMachineStartMobileSubTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalRoomGoodsMachineStartMobileSubTotalSum || 0));
          totalRoomGoodsMachineStartMobileRewardsSubTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalRoomGoodsMachineStartMobileRewardsSubTotalSum || 0));
          totalRoomGoodsMachineStartSubTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalRoomGoodsMachineStartSubTotalSum || 0));
          totalRoomMachineStartCount.push(totalRoomMachineStartCountSum || 0);
          totalRoomGoodsDiscount.push(_currencyFormatUtil.formatCurrencyString.call(this, totalRoomGoodsDiscountSum || 0));
          totalRoomServiceSubTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalRoomServiceSubTotalSum || 0));
          totalRoomServiceTaxCash.push(_currencyFormatUtil.formatCurrencyString.call(this, totalRoomServiceTaxCashSum || 0));
          totalRoomServiceTax.push(_currencyFormatUtil.formatCurrencyString.call(this, totalRoomServiceTaxSum || 0));
          totalRoomServiceCashSubTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalRoomServiceCashSubTotalSum || 0));
          totalRoomServiceCreditSubTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalRoomServiceCreditSubTotalSum || 0));
          totalRoomServiceMobileSubTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalRoomServiceMobileSubTotalSum || 0));
          totalRoomServiceMobileRewardsSubTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalRoomServiceMobileRewardsSubTotalSum || 0));
          totalRoomServiceDiscount.push(_currencyFormatUtil.formatCurrencyString.call(this, totalRoomServiceDiscountSum || 0));
          totalRoomPosTrxnCount.push(totalRoomPosTrxnCountSum || 0);
          let roomAverageTransaction = totalRoomDailyTotalAmountSum / totalRoomPosTrxnCountSum;
          totalRoomAverageTransactionAmount.push(_currencyFormatUtil.formatCurrencyString.call(this, roomAverageTransaction || 0));
          totalRoomDailyTaxAmount.push(_currencyFormatUtil.formatCurrencyString.call(this, totalRoomDailyTaxAmountSum || 0));
          totalRoomDailyCashAmount.push(_currencyFormatUtil.formatCurrencyString.call(this, totalRoomDailyCashAmountSum || 0));
          totalRoomDailyTotalAmount.push(_currencyFormatUtil.formatCurrencyString.call(this, totalRoomDailyTotalAmountSum || 0));
        });
        totalGoodsSubTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalGoodsSubTotalSum || 0));
        totalGoodsTax.push(_currencyFormatUtil.formatCurrencyString.call(this, totalGoodsTaxSum || 0));
        totalGoodsCash.push(_currencyFormatUtil.formatCurrencyString.call(this, totalGoodsCashSum || 0));
        totalGoodsCashSubTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalGoodsCashSubTotalSum || 0));
        totalGoodsCreditSubTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalGoodsCreditSubTotalSum || 0));
        totalGoodsMobileSubTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalGoodsMobileSubTotalSum || 0));
        totalGoodsMobileRewardsSubTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalGoodsMobileRewardsSubTotalSum || 0));
        totalGoodsMachineStartCashSubTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalGoodsMachineStartCashSubTotalSum || 0));
        totalGoodsMachineStartCreditSubTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalGoodsMachineStartCreditSubTotalSum || 0));
        totalGoodsMachineStartMobileSubTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalGoodsMachineStartMobileSubTotalSum || 0));
        totalGoodsMachineStartMobileRewardsSubTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalGoodsMachineStartMobileRewardsSubTotalSum || 0));
        totalGoodsMachineStartWashersSubTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalGoodsMachineStartWashersSubTotalSum || 0));
        totalGoodsMachineStartDryersSubTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalGoodsMachineStartDryersSubTotalSum || 0));
        totalGoodsMachineStartSubTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalGoodsMachineStartSubTotalSum || 0));
        totalMachineStartCount.push(totalMachineStartCountSum || 0);
        totalGoodsDiscount.push(_currencyFormatUtil.formatCurrencyString.call(this, totalGoodsDiscountSum || 0));
        totalServiceSubTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalServiceSubTotalSum || 0));
        totalServiceTaxCash.push(_currencyFormatUtil.formatCurrencyString.call(this, totalServiceTaxCashSum || 0));
        totalServiceTax.push(_currencyFormatUtil.formatCurrencyString.call(this, totalServiceTaxSum || 0));
        totalServiceCashSubTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalServiceCashSubTotalSum || 0));
        totalServiceCreditSubTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalServiceCreditSubTotalSum || 0));
        totalServiceMobileSubTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalServiceMobileSubTotalSum || 0));
        totalServiceMobileRewardsSubTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalServiceMobileRewardsSubTotalSum || 0));
        totalServiceDiscount.push(_currencyFormatUtil.formatCurrencyString.call(this, totalServiceDiscountSum || 0));
        totalPosTrxnCount.push(totalPosTrxnCountSum || 0);
        let geoAverageTransaction = totalDailyTotalAmountSum / totalPosTrxnCountSum;
        totalAverageTransactionAmount.push(_currencyFormatUtil.formatCurrencyString.call(this, geoAverageTransaction || 0));
        totalDailyTaxAmount.push(_currencyFormatUtil.formatCurrencyString.call(this, totalDailyTaxAmountSum || 0));
        totalDailyCashAmount.push(_currencyFormatUtil.formatCurrencyString.call(this, totalDailyCashAmountSum || 0));
        totalDailyTotalAmount.push(_currencyFormatUtil.formatCurrencyString.call(this, totalDailyTotalAmountSum || 0));
      });
      totalOrgGoodsSubTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalOrgGoodsSubTotalSum || 0));
      totalOrgGoodsTax.push(_currencyFormatUtil.formatCurrencyString.call(this, totalOrgGoodsTaxSum || 0));
      totalOrgGoodsCash.push(_currencyFormatUtil.formatCurrencyString.call(this, totalOrgGoodsCashSum || 0));
      totalOrgGoodsCashSubTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalOrgGoodsCashSubTotalSum || 0));
      totalOrgGoodsCreditSubTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalOrgGoodsCreditSubTotalSum || 0));
      totalOrgGoodsMobileSubTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalOrgGoodsMobileSubTotalSum || 0));
      totalOrgGoodsMobileRewardsSubTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalOrgGoodsMobileRewardsSubTotalSum || 0));
      totalOrgGoodsMachineStartCashSubTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalOrgGoodsMachineStartCashSubTotalSum || 0));
      totalOrgGoodsMachineStartCreditSubTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalOrgGoodsMachineStartCreditSubTotalSum || 0));
      totalOrgGoodsMachineStartMobileSubTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalOrgGoodsMachineStartMobileSubTotalSum || 0));
      totalOrgGoodsMachineStartMobileRewardsSubTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalOrgGoodsMachineStartMobileRewardsSubTotalSum || 0));
      totalOrgGoodsMachineStartWashersSubTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalOrgGoodsMachineStartWashersSubTotalSum || 0));
      totalOrgGoodsMachineStartDryersSubTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalOrgGoodsMachineStartDryersSubTotalSum || 0));
      totalOrgGoodsMachineStartSubTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalOrgGoodsMachineStartSubTotalSum || 0));
      totalOrgMachineStartCount.push(totalOrgMachineStartCountSum || 0);
      totalOrgGoodsDiscount.push(_currencyFormatUtil.formatCurrencyString.call(this, totalOrgGoodsDiscountSum || 0));
      totalOrgServiceSubTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalOrgServiceSubTotalSum || 0));
      totalOrgServiceTaxCash.push(_currencyFormatUtil.formatCurrencyString.call(this, totalOrgServiceTaxCashSum || 0));
      totalOrgServiceTax.push(_currencyFormatUtil.formatCurrencyString.call(this, totalOrgServiceTaxSum || 0));
      totalOrgServiceCashSubTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalOrgServiceCashSubTotalSum || 0));
      totalOrgServiceCreditSubTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalOrgServiceCreditSubTotalSum || 0));
      totalOrgServiceMobileSubTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalOrgServiceMobileSubTotalSum || 0));
      totalOrgServiceMobileRewardsSubTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalOrgServiceMobileRewardsSubTotalSum || 0));
      totalOrgServiceDiscount.push(_currencyFormatUtil.formatCurrencyString.call(this, totalOrgServiceDiscountSum || 0));
      totalOrgPosTrxnCount.push(totalOrgPosTrxnCountSum || 0);
      let orgAverageTransaction = totalOrgDailyTotalAmountSum / totalOrgPosTrxnCountSum;
      totalOrgAverageTransactionAmount.push(_currencyFormatUtil.formatCurrencyString.call(this, orgAverageTransaction || 0));
      totalOrgDailyTaxAmount.push(_currencyFormatUtil.formatCurrencyString.call(this, totalOrgDailyTaxAmountSum || 0));
      totalOrgDailyCashAmount.push(_currencyFormatUtil.formatCurrencyString.call(this, totalOrgDailyCashAmountSum || 0));
      totalOrgDailyTotalAmount.push(_currencyFormatUtil.formatCurrencyString.call(this, totalOrgDailyTotalAmountSum || 0));
      return rowData;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SummaryReportsSDailyReconComponent;
});