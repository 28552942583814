define("alliance-business-suite/helpers/find-option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),

    compute(_ref, hash) {
      let [key, ...rest] = _ref;

      /*
        Returns the label of the first option found with `val` matching the value
         The provided array should look like this:
           optionsDefaultModifiers: [
            { val: 0, label: 'regular' },
            { val: 1, label: 'deluxe' },
            { val: 2, label: 'ultra' }
          ]
      */
      let {
        options,
        val,
        propertyName = "label"
      } = hash;

      if (!options) {
        console.log("find-options: no options found");
        return undefined;
      }

      let foundItem = options.find(item => {
        return item.val == val;
      });
      let found = foundItem[propertyName];

      if (found !== undefined) {
        return this.intl.t(found);
      } else {
        return undefined;
      }
    }

  });

  _exports.default = _default;
});