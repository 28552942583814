define("alliance-business-suite/templates/components/form-ui/custom-bundle-names", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sNMuwg+P",
    "block": "{\"symbols\":[\"ui\"],\"statements\":[[8,\"form-ui\",[],[[\"@object\",\"@formDisplay\",\"@formSettings\",\"@selectOptions\"],[[32,0,[\"object\"]],\"compact\",[32,0,[\"formSettings\"]],[32,0,[\"selectOptions\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[8,[32,1,[\"select\"]],[],[[\"@params\"],[[30,[36,0],null,[[\"propertyName\",\"options\"],[[32,0,[\"properties\",\"CycleModifierName\",\"key\"]],\"CycleNames\"]]]]],null],[2,\"\\n\\n\"],[6,[37,2],[[30,[36,1],[[32,0,[\"CycleModifierName\"]],2],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,[32,1,[\"text\"]],[],[[\"@params\"],[[30,[36,0],null,[[\"propertyName\",\"label\"],[[32,0,[\"properties\",\"bundle1Name\",\"key\"]],\"custom_bundle_name_1\"]]]]],null],[2,\"\\n\\n    \"],[8,[32,1,[\"textarea\"]],[],[[\"@params\"],[[30,[36,0],null,[[\"propertyName\",\"label\"],[[32,0,[\"properties\",\"bundle1Description\",\"key\"]],\"custom_bundle_description_1\"]]]]],null],[2,\"\\n\\n    \"],[8,[32,1,[\"text\"]],[],[[\"@params\"],[[30,[36,0],null,[[\"propertyName\",\"label\"],[[32,0,[\"properties\",\"bundle2Name\",\"key\"]],\"custom_bundle_name_2\"]]]]],null],[2,\"\\n\\n    \"],[8,[32,1,[\"textarea\"]],[],[[\"@params\"],[[30,[36,0],null,[[\"propertyName\",\"label\"],[[32,0,[\"properties\",\"bundle2Description\",\"key\"]],\"custom_bundle_description_2\"]]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"hash\",\"eq\",\"liquid-if\"]}",
    "moduleName": "alliance-business-suite/templates/components/form-ui/custom-bundle-names.hbs"
  });

  _exports.default = _default;
});