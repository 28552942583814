define("alliance-business-suite/templates/components/locations/rewards/r-create-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cjbOL3pH",
    "block": "{\"symbols\":[],\"statements\":[[10,\"article\"],[12],[2,\"\\n\"],[2,\"  \"],[8,\"ui/cell-input\",[],[[\"@label\"],[[30,[36,0],[\"name\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,3],[[30,[36,2],[[35,1,[\"text-field\"]],\"expected `form.text-field` to be a contextual component but found a string. Did you mean `(component form.text-field)`? ('alliance-business-suite/templates/components/locations/rewards/r-create-form.hbs' @ L4:C6) \"],null],\"name\"],[[\"required\",\"labelClasses\",\"placeholder\"],[true,\"form-field__label--hidden\",[30,[36,0],[\"your_program_name\"],null]]]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"form\",\"-assert-implicit-component-helper-argument\",\"component\"]}",
    "moduleName": "alliance-business-suite/templates/components/locations/rewards/r-create-form.hbs"
  });

  _exports.default = _default;
});