define("alliance-business-suite/components/services/org-services-edit", ["exports", "jquery", "alliance-business-suite/config/environment", "ember-cp-validations"], function (_exports, _jquery, _environment, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'model.description': [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })]
  });

  var _default = Ember.Component.extend(Validations, {
    intl: Ember.inject.service(),
    notifications: Ember.inject.service(),
    isSaving: false,
    actions: {
      delete(model) {
        let self = this;

        if (confirm(this.intl.t('org_service_delete_confirm'))) {
          this.set('isSaving', true);
          model.destroyRecord({
            adapterOptions: {
              roomId: this.get('room.id'),
              serviceId: model.get('id')
            }
          }).then(() => {
            this.refreshServices();
            this.close();
            self.get('notifications').info(self.get('intl').t('org_service_removed'), {
              autoClear: true,
              clearDuration: 5000
            });
          });
        }
      },

      exit(model) {
        if (model.get('hasDirtyAttributes')) {
          model.rollbackAttributes();
        }

        this.close();
      },

      save(model) {
        let self = this;
        this.set('isSaving', true);
        model.save().then(function () {
          self.set('isSaving', false);
          Ember.get(self, 'notifications').info(self.get('intl').t('successfully_updated'), {
            autoClear: true,
            clearDuration: 5000
          });
          self.close();
        }, function () {
          self.set('isSaving', false);
          Ember.get(self, 'notifications').error(self.get('intl').t('save_failed'));
        });
      }

    }
  });

  _exports.default = _default;
});