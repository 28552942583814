define("alliance-business-suite/components/form-ui/limit-dates", ["exports", "alliance-business-suite/mixins/init-property-names"], function (_exports, _initPropertyNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function rollHours(value) {
    // If we add hours to a time that rolls it over to the next day, reset the value
    return value >= 24 ? value - 24 : value;
  }

  var _default = Ember.Component.extend(_initPropertyNames.default, {
    tagName: 'div',
    // classNames: ['form-field', 'overflow-float'],
    hasFullDates: true,
    limitEnabled: Ember.computed('startYear', 'startMonth', 'startDate', 'endYear', 'endMonth', 'endDate', {
      // This is based on magic. If limit dates is NOT enabled, ALL the date parts will be 0. If limit dates IS enabled, ALL the date parts will NOT be zero.
      get() {
        let props = ['startYear', 'startMonth', 'startDate', 'endYear', 'endMonth', 'endDate'];
        let allZero = props.every(propName => {
          return Ember.get(this, propName) === 0 || Ember.get(this, propName) === 2000; // TODO: Remove 2000 hack after we fix the adapter/serializer.
        });
        return !allZero;
      },

      set(key, value) {
        let object = this.object;

        if (value) {
          // Set default dates if enabled is checked on
          let now = moment();
          this.update(object, this.properties.startYear.key, now.format('YY')); // Changed to always set a 2-digit year

          this.update(object, this.properties.startMonth.key, now.month() + 1);
          this.update(object, this.properties.startDate.key, now.date());
          this.update(object, this.properties.endYear.key, 99);
          this.update(object, this.properties.endMonth.key, 12);
          this.update(object, this.properties.endDate.key, 31);
        } else {
          this.update(object, this.properties.startYear.key, 0);
          this.update(object, this.properties.startMonth.key, 0);
          this.update(object, this.properties.startDate.key, 0);
          this.update(object, this.properties.endYear.key, 0);
          this.update(object, this.properties.endMonth.key, 0);
          this.update(object, this.properties.endDate.key, 0);
        }

        return value;
      }

    })
  });

  _exports.default = _default;
});