define("alliance-business-suite/templates/components/table/table-cell-button-lock-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "APDELLJ1",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[35,2,[\"systemDefault\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"button\"],[15,0,[31,[[34,0,[\"buttonClass\"]]]]],[14,\"disabled\",\"\"],[14,5,\"width: 30px\"],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-lock\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[11,\"button\"],[16,0,[31,[[34,0,[\"buttonClass\"]]]]],[24,5,\"width: 30px\"],[4,[38,1],[[32,0],\"go\"],null],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-cog\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"column\",\"action\",\"record\",\"if\"]}",
    "moduleName": "alliance-business-suite/templates/components/table/table-cell-button-lock-edit.hbs"
  });

  _exports.default = _default;
});