define("alliance-business-suite/components/rewards/modal-schedule-day", ["exports", "ember-cp-validations", "moment"], function (_exports, _emberCpValidations, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'programSchedule.dayLookupKey': [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    'isSaving': [(0, _emberCpValidations.validator)('presence', {
      presence: false,
      disabled: Ember.computed('model.isSaving', function () {
        return this.get('isSaving') === true ? true : false;
      })
    })],
    'programSchedule.runFromDate': [(0, _emberCpValidations.validator)('presence', {
      dependentKeys: ['model.programSchedule.dateRange'],
      presence: true,
      disabled: Ember.computed('model.programSchedule.dateRange', function () {
        return this.model.get('programSchedule.dateRange') === 'FOREVER' ? true : false;
      })
    })],
    'programSchedule.dayStartTime': [(0, _emberCpValidations.validator)('presence', {
      dependentKeys: ['model.programSchedule.timeRange'],
      presence: true,
      disabled: Ember.computed('model.programSchedule.timeRange', function () {
        return this.model.get('programSchedule.timeRange') === 'FOREVER' ? true : false;
      })
    })],
    'programSchedule.dayEndTime': [(0, _emberCpValidations.validator)('presence', {
      dependentKeys: ['model.programSchedule.timeRange'],
      presence: true,
      disabled: Ember.computed('model.programSchedule.timeRange', function () {
        return this.model.get('programSchedule.timeRange') === 'FOREVER' ? true : false;
      })
    })],
    'programSchedule.timeRange': [(0, _emberCpValidations.validator)('dependent', {
      dependentKeys: ['model.programSchedule.timeRange', 'model.programSchedule.dayStartTime', 'model.programSchedule.dayEndTime'],
      on: ['programSchedule.dayStartTime', 'programSchedule.dayEndTime'],
      // message: 'Both required!',
      disabled: Ember.computed('model.programSchedule.timeRange', function () {
        return this.model.get('programSchedule.timeRange') === 'FOREVER' ? true : false;
      })
    })],
    'programSchedule.pointsMultiplier': [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      // must allow string, cannot get it to work otherwise (use input type="number" to prevent strings instead)
      gt: 0,
      lte: 100
    }), (0, _emberCpValidations.validator)('format', {
      regex: /^[0-9]+(\.[0-9]{1,2})?$/,

      value(model, attribute) {
        let value = this.model.get(`${attribute}`);
        return `${value}`; // convert to string because regex doesn't like a javascript number, weird
      },

      message: Ember.computed(function () {
        return this.model.get('intl').t('only_2_decimals');
      })
    })]
  });
  const SCHEDULE_DAYS = [{
    id: 'MONDAY'
  }, {
    id: 'TUESDAY'
  }, {
    id: 'WEDNESDAY'
  }, {
    id: 'THURSDAY'
  }, {
    id: 'FRIDAY'
  }, {
    id: 'SATURDAY'
  }, {
    id: 'SUNDAY'
  } // { id: 'WEEKDAYS'},
  // { id: 'WEEKENDS'}
  ];
  const SCHEDULE_DATE_OPTIONS = ['FOREVER', 'CUSTOM'];
  const SCHEDULE_TIME_OPTIONS = ['ALL_DAY', 'CUSTOM'];
  const WEEKDAYS = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY'];
  const WEEKENDS = ['SATURDAY', 'SUNDAY'];

  var _default = Ember.Component.extend(Validations, {
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    intl: Ember.inject.service(),
    scheduleDaysOptions: Ember.computed('programSchedule.dayLookupKey', function () {
      let programSchedules = this.get('programSchedule.rewardsProgram.rewardsProgramScheduleDays');
      SCHEDULE_DAYS.forEach(function (scheduleDay) {
        Ember.set(scheduleDay, 'disabled', false);
      });

      if (programSchedules) {
        programSchedules.forEach(function (programSchedule) {
          SCHEDULE_DAYS.forEach(function (scheduleDay) {
            const selectedDay = programSchedule.get('dayLookupKey');

            if (selectedDay === scheduleDay.id) {
              Ember.set(scheduleDay, 'disabled', true);
            }

            if (selectedDay === 'WEEKDAYS' && WEEKDAYS.indexOf(scheduleDay.id) != -1) {
              Ember.set(scheduleDay, 'disabled', true);
            }

            if (selectedDay === 'WEEKENDS' && WEEKENDS.indexOf(scheduleDay.id) != -1) {
              Ember.set(scheduleDay, 'disabled', true);
            }
          });
        });
      }

      return SCHEDULE_DAYS;
    }),
    scheduleDateOptions: Ember.computed(function () {
      return SCHEDULE_DATE_OPTIONS;
    }),
    scheduleTimeOptions: Ember.computed(function () {
      return SCHEDULE_TIME_OPTIONS;
    }),
    minFromDate: Ember.computed(function () {
      return (0, _moment.default)().toDate();
    }),
    minToDate: Ember.computed('programSchedule.runFromDate', function () {
      let runFromDate = this.get('programSchedule.runFromDate') || (0, _moment.default)();
      return (0, _moment.default)(runFromDate).add(1, 'days').toDate();
    }),
    selectedScheduleDay: Ember.computed('programSchedule.dayLookupKey', function () {
      let selection;
      let dayLookupKey = this.get('programSchedule.dayLookupKey');
      SCHEDULE_DAYS.forEach(function (option) {
        if (option.id === dayLookupKey) {
          selection = option;
        }
      });
      return selection;
    }),
    actions: {
      exit() {
        if (this.isSaving) {
          return;
        }

        this.programSchedule.rollbackAttributes();
        this.close();
      },

      // clearSelectedScheduleDay() {
      //   this.set('selectedScheduleDay',)
      // },
      toggleProgramSchedule(programSchedule) {
        if (programSchedule) {
          this.set('selectedProgramSchedule', programSchedule);
        }

        this.toggleProperty('viewEditProgramSchedule');
      },

      setDay(value) {
        this.set('programSchedule.dayLookupKey', value.id);
      },

      setDate(model, property, value) {
        let date = value;

        if ((0, _moment.default)(date).isValid()) {
          date = (0, _moment.default)(value).format('YYYY-MM-DDTHH:mm:ss');
        }

        model.set(property, date);
      },

      saveSchedule(programSchedule) {
        this.set('isSaving', true);

        if (typeof programSchedule.dayStartTime !== 'string') {
          this.set('programSchedule.dayStartTime', (0, _moment.default)(`${programSchedule.dayStartTime.hours}:${programSchedule.dayStartTime.minutes}`, "HH:mm:ss").format('HH:mm:ss'));
        }

        if (typeof programSchedule.dayEndTime !== 'string') {
          this.set('programSchedule.dayEndTime', (0, _moment.default)(`${programSchedule.dayEndTime.hours}:${programSchedule.dayEndTime.minutes}`, "HH:mm:ss").format('HH:mm:ss'));
        }

        programSchedule.save({
          adapterOptions: {
            programId: programSchedule.get('rewardsProgram.id')
          }
        }).then(() => {
          this.close();
          this.set('isSaving', false);
          this.notifications.info(this.intl.t('reward_schedule_saved'), {
            autoClear: true,
            clearDuration: 4000
          });
        });
      },

      deleteSchedule(programSchedule) {
        this.set('isSaving', true);
        programSchedule.deleteRecord();
        programSchedule.save({
          adapterOptions: {
            programId: programSchedule.get('rewardsProgram.id')
          }
        }).then(() => {
          this.close();
          this.set('isSaving', false);
          this.notifications.error(this.intl.t('reward_schedule_deleted'), {
            autoClear: true,
            clearDuration: 4000
          });
        });
      }

    }
  });

  _exports.default = _default;
});