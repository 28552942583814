define("alliance-business-suite/components/inventory/inventory-locations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    access: Ember.inject.service(),
    activeTab: 'all-rooms',

    progressPercent(threshold, currentAmount) {
      return parseFloat(threshold / currentAmount * 100).toFixed(2);
    },

    progressBar(threshold, currentAmount) {
      let percent = this.progressPercent(threshold, currentAmount);

      if (percent > 100) {
        return 1;
      } else if (percent < 0) {
        return 0;
      }

      return percent / 100;
    },

    cssColor(threshold, currentAmount) {
      let percent = this.progressPercent(threshold, currentAmount);
      let color = 'green';

      if (percent >= 75) {
        color = 'red';
      } else if (percent >= 50) {
        color = 'orange';
      }

      return color;
    },

    totalInventory: Ember.computed('activeItem', 'activeItem.inventory.@each.quantity', function () {
      let inventories = this.get('activeItem.inventory');
      let total = 0;
      inventories.forEach(function (inventory) {
        total += parseInt(inventory.get('quantity'));
      });
      return total;
    }),
    rooms: Ember.computed(function () {
      let allRooms = this.store.peekAll('room').sortBy('formattedBreadcrumbDisplay');
      return allRooms.filter(room => {
        if (room.get('isRetail')) {
          return true;
        }
      });
    }),
    reorderGuidelines: Ember.computed(function () {
      return this.store.peekAll('reorderGuidelines');
    }),
    // eslint-disable-next-line ember/use-brace-expansion
    itemRooms: Ember.computed('activeItem.{inventory.[],reorderGuidelines.[]}', 'rooms.reorderGuidelines.[]', function () {
      let rooms = this.store.peekAll('room');
      let activeItem = this.activeItem;
      let itemRooms = [];
      rooms.forEach(function (room) {
        if (room.get('inventory')) {
          let matchedInventory = false;
          room.get('inventory').forEach(function (inventory) {
            if (inventory.get('item.id') === activeItem.get('id')) {
              matchedInventory = true;
              room.set('activeInventory', inventory);
              itemRooms.push(room);
            }
          });

          if (!matchedInventory) {
            room.set('activeInventory', null);
            itemRooms.push(room);
          }
        } else {
          room.set('activeInventory', null);
          itemRooms.push(room);
        }
      });
      return itemRooms;
    }),

    // itemRooms: computed('activeItem.inventory.[]', 'activeItem.reorderGuidelines.[]', 'rooms.reorderGuidelines.[]', function() {
    //   let rooms = this.get('store').peekAll('room');
    //   let activeItem = this.get('activeItem');
    //   let itemRooms = [];
    //
    //   rooms.forEach(function(room) {
    //     if (room.get('inventory')) {
    //       let matchedInventory = false;
    //       room.get('inventory').forEach(function(inventory) {
    //         if (inventory.get('item.id') === activeItem.get('id')) {
    //           matchedInventory = true;
    //           itemRooms.push({
    //             room,
    //             inventory
    //           });
    //         }
    //       });
    //
    //       if (!matchedInventory) {
    //         itemRooms.push({
    //           room
    //         });
    //       }
    //     } else {
    //       itemRooms.push({
    //         room
    //       });
    //     }
    //   });
    //
    //   return itemRooms;
    // }),
    itemInventory(room) {
      let inventory;
      let activeItem = this.activeItem;

      if (room.get('inventory')) {
        room.get('inventory').forEach(function (roomInventory) {
          if (roomInventory.get('item') == activeItem) {
            inventory = roomInventory;
          }
        });
      }

      return inventory;
    },

    actions: {
      switchTab(tab) {
        this.set('activeTab', tab);
      },

      toggleEditInventory(inventory) {
        this.set('activeInventory', inventory);
        this.set('activeRooms', null);
        this.send('toggleViewInventoryForm');
      },

      toggleAddInventory(room) {
        this.set('activeInventory', null);
        this.set('activeRooms', [room]);
        this.send('toggleViewInventoryForm');
      },

      toggleInventory(inventory, room) {
        this.set('activeInventory', inventory);
        this.set('activeRooms', [room]);
        this.send('toggleViewInventoryForm');
      },

      toggleEditItem(item) {
        this.send('toggleViewItemForm');
      },

      toggleViewInventoryForm() {
        this.toggleProperty('viewInventoryForm');
      },

      toggleViewItemForm() {
        this.toggleProperty('viewItemForm');
      },

      toggleItemReorderGuideline(guideline) {
        this.set('activeGuideline', guideline);
        this.set('activeInventory', null);
        this.send('toggleViewReorderGuidelineForm');
      },

      toggleInventoryReorderGuideline(guideline, inventory) {
        this.set('activeGuideline', guideline);
        this.set('activeInventory', inventory);
        this.send('toggleViewReorderGuidelineForm');
      },

      toggleViewReorderGuidelineForm() {
        this.toggleProperty('viewReorderGuidelineForm');
      }

    }
  });

  _exports.default = _default;
});