define("alliance-business-suite/components/ui/placeholder-message", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class UiPlaceholderMessageComponent extends _component.default {}

  _exports.default = UiPlaceholderMessageComponent;
});