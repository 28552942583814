define("alliance-business-suite/templates/components/machines/programs/input-currency-int", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "G3iuYPSw",
    "block": "{\"symbols\":[],\"statements\":[[10,\"label\"],[15,0,[31,[[34,4]]]],[12],[2,\"\\n  \"],[1,[34,5]],[2,\"\\n\"],[6,[37,8],[[30,[36,7],[[35,6,[\"val\"]],false],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"input-money\",[],[[\"@number\",\"@precision\",\"@class\",\"@disabled\",\"@affixesStay\",\"@prefix\"],[[34,0],[34,1],\"form-field--control\",[34,2],true,[34,3]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"input-money\",[],[[\"@number\",\"@precision\",\"@class\",\"@disabled\",\"@affixesStay\",\"@suffix\"],[[34,0],[34,1],\"form-field--control\",[34,2],true,[34,3]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"dev-tools\"],[12],[2,\"\\n  \"],[10,\"ul\"],[14,0,\"pills\"],[12],[2,\"\\n    \"],[10,\"li\"],[12],[2,\"decimalPlaces: \"],[1,[34,1]],[13],[2,\"\\n    \"],[10,\"li\"],[12],[2,\"property: \"],[1,[34,9]],[13],[2,\"\\n    \"],[10,\"li\"],[12],[2,\"displayValue: \"],[1,[34,0]],[13],[2,\"\\n    \"],[10,\"li\"],[12],[2,\"allowDecimal: \"],[1,[34,10]],[13],[2,\"\\n    \"],[10,\"li\"],[12],[2,\"limit: \"],[1,[34,11]],[13],[2,\"\\n    \"],[10,\"li\"],[12],[2,\"Currency: \"],[1,[35,12,[\"label\"]]],[13],[2,\"\\n    \"],[10,\"li\"],[12],[2,\"currency Placement: \"],[1,[35,6,[\"val\"]]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"displayValue\",\"decimalPlaces\",\"disableIf\",\"currencySymbol\",\"labelClass\",\"label\",\"currencyPlacement\",\"eq\",\"if\",\"property\",\"allowDecimal\",\"limit\",\"currency\"]}",
    "moduleName": "alliance-business-suite/templates/components/machines/programs/input-currency-int.hbs"
  });

  _exports.default = _default;
});