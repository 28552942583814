define("alliance-business-suite/components/machines/m-brush-history-preview", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['account-transaction-list-block'],
    ajax: Ember.inject.service(),
    access: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),

    // isLoading: true,
    // transactions: null,
    // didInsertElement() {
    //   this._super(...arguments);
    //   this.fetchMachineHistoryPreview(this.get('model.id'));
    // },
    didUpdateAttrs() {
      this._super(...arguments); // set(this, 'isLoading', true); // Clear any previous results


      this.fetchMachineHistoryPreview(this.get('model.id'));
    },

    momentFormat: Ember.computed(function () {
      let userId = Ember.get(this, 'access.accessUser.id');
      let user = Ember.get(this, 'store').peekRecord('employee', userId);
      let date = user.get('dateFormat');
      let time = user.get('timeFormat') + ' A';

      if (user.get('timeFormat') === 'HH:mm') {
        time = `${user.get('timeFormat')}`;
      }

      return `${date} ${time}`;
    }),
    transactions: Ember.computed('model.id', (0, _emberConcurrency.task)(function* () {
      // let ajax = this.ajax;
      // ajax.request(`/machines/${machineId}/transactionsPreview`, {
      //   method: 'GET'
      // }).then((response) => {
      //   if (!this.isDestroyed) {
      //     this.setProperties({
      //       isLoading: false,
      //       transactions: response
      //     });
      //   }
      // }).catch((e) => {
      //   if (!this.isDestroyed) {
      //     this.set('isLoading', true);
      //     this.setProperties({
      //       isLoading: false,
      //       transactions: null
      //     });
      //   }
      // });
      let machineId = this.model.id;
      let response = yield this.ajax.request(`/machines/${machineId}/transactionsPreview`, {
        method: 'GET'
      }); // set(this, 'transactions', response);
    })) // fetchMachineHistoryPreview(machineId) {
    //   let ajax = this.ajax;
    //   ajax.request(`/machines/${machineId}/transactionsPreview`, {
    //     method: 'GET'
    //   }).then((response) => {
    //     if (!this.isDestroyed) {
    //       this.setProperties({
    //         isLoading: false,
    //         transactions: response
    //       });
    //     }
    //   }).catch((e) => {
    //     if (!this.isDestroyed) {
    //       this.set('isLoading', true);
    //       this.setProperties({
    //         isLoading: false,
    //         transactions: null
    //       });
    //     }
    //   });
    // }

  });

  _exports.default = _default;
});