define("alliance-business-suite/utils/org-setup-util", ["exports", "alliance-business-suite/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.buildUserInfo = buildUserInfo;
  _exports.buildUserOrgs = buildUserOrgs;
  _exports.findOrg = findOrg;
  _exports.findUserInOrg = findUserInOrg;
  _exports.loopIncluded = loopIncluded;

  function buildUserInfo(credentials, payload) {
    this.session.set('data.accessPrimaryEmail', credentials.identification);
    this.session.set('data.userId', payload.data.id);
  }

  function buildUserOrgs(includes, isDemoUser) {
    let orgs = [];
    includes.forEach(included => {
      // Look for list of organizations that match the current brand
      if (included.type == 'organizations') {
        if (_environment.default.brand == included.attributes.brandName) {
          let obj = {
            id: included.id,
            name: included.attributes.organizationName,
            brandName: included.attributes.brandName
          };

          if (isDemoUser && obj.id === '1084') {
            console.log('Demo build');
            obj.name = 'ALS Demo Mode Org ★';
          }

          orgs.push(obj);
        }
      }
    });

    if (orgs.length == 0) {
      this.session.invalidate();
    }

    this.session.set('data.userOrgs', orgs);
  }

  function loopIncluded(credentials, includes) {
    let isLaundromatOrg = true;
    includes.forEach(function (included) {
      if (included.type == 'user-roles') {
        if (included.attributes.lookupKey == 'ORGANIZATION_ROUTE_UNIVERSITY') {
          isLaundromatOrg = false;
        }
      }
    });
    this.session.set('data.isLaundromatOrg', isLaundromatOrg);
  }

  function findOrg(orgId, orgBrand) {
    let {
      access,
      store,
      session
    } = this;
    let isHuebschDevelopment = _environment.default.environment === 'development' && _environment.default.brand === 'HUEBSCH'; // If wrong brand invalidate the user
    // HUEBSCH HACK: Do not restrict brand for Huebsch development.

    if (!isHuebschDevelopment && orgBrand != _environment.default.brand) {
      session.invalidate();
    }

    session.set('data.orgId', orgId);
    store.queryRecord('organization', {
      organization: orgId,
      geoBoundaries: true,
      include: 'all'
    }).then(org => {
      access.set('accessOrganization', org);
      session.set('data.algoliaKey', org.get('organizationAlgoliaSecuredApiKey'));
      session.set('data.currencySymbol', org.get('currency.symbol'));
      session.set('data.currencyDivisor', org.get('currency.divisor'));
      session.set('data.currencyThousandsSeparator', org.get('currency.thousandsSeparator'));
      session.set('data.currencyDecimalSeparator', org.get('currency.decimalSeparator'));
      session.set('data.currencyDecimalOverride', org.get('currency.overrideDecimalPlaces'));
      session.set('data.currencyDecimalPlaces', org.get('currency.decimalPlaces'));
      session.set('data.iso4217Code', org.get('currency.iso4217Code')); // if an existing org, then we are switching between orgs so we need to reload
      // if previously set, we need to clear the ember store
      // reloading the page is the best method

      if (session.get('data.orgId')) {
        findUserInOrg(this, orgId, true);
      } else {
        findUserInOrg(this, orgId);
      }
    }).catch(adapterError => {
      // eslint-disable no-console
      alert(this.intl.t(`geoboundary_login_error`));
      session.invalidate();
    });
  }

  function findUserInOrg(context, orgId, shouldReload) {
    let {
      router,
      store,
      session,
      access
    } = context;
    let userId = context.session.data.userId;
    let securityRoles = [];
    store.findRecord('employee', userId, {
      reload: true
    }).then(user => {
      let srs = user.get('securityRoles');
      srs.forEach(sc => {
        securityRoles.push(sc);
      });
      let currentUserSecurityRoleName = securityRoles[0];
      context.session.set('data.currentUserSecurityRoleName', currentUserSecurityRoleName.get('name'));
      context.session.set('data.securityRoles', securityRoles);
      context.session.set('data.hasPassedOrgSelect', true);
      context.isLoading = false;
      access.accessUser = user;

      if (shouldReload) {
        location.href = '/';
      } else {
        router.transitionTo('start');
      }
    }).catch(e => {
      // eslint-disable no-console
      console.error('Error 3923', e);
      session.invalidate();
    });
  }
});