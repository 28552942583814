define("alliance-business-suite/components/video-player", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    hasVideo: Ember.computed('videoId', function () {
      return !Ember.isNone(Ember.get(this, 'videoId'));
    }),
    videoId: Ember.computed('brand.abbrev', function () {
      let brandAbbrev = this.brand.abbrev.toLowerCase();
      return Ember.get(this, brandAbbrev);
    })
  });

  _exports.default = _default;
});