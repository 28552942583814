define("alliance-business-suite/components/tables/table-interactive", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="standard-table {{if @isClickable "table-clickable-row"}} {{if @isLeftAlignLast "td-last-left-align"}} {{@classNames}}">
    <ModelsTable
      @data={{@data}}
      @columns={{@columns}}
      @pageSize={{this.pageSize}}
      @multipleColumnsSorting={{this.multipleColumnsSorting}}
      @showComponentFooter={{this.showComponentFooter}}
      @showColumnsDropdown={{this.showColumnsDropdown}}
      @useFilteringByColumns={{this.useFilteringByColumns}}
      @showGlobalFilter={{this.showGlobalFilter}}
      @doFilteringByHiddenColumns={{this.doFilteringByHiddenColumns}}
      @useNumericPagination={{this.useNumericPagination}}
      @filteringIgnoreCase={{this.filteringIgnoreCase}}
      @collapseNumPaginationForPagesCount={{this.collapseNumPaginationForPagesCount}}
      @showPageSize={{this.showPageSize}}
      @displayDataChangedAction={{this.rowClick}}
      @selectedItems={{this.selectedItems}}
      @brush={{this.brush}}
      @store={{this.store}}
      @router={{this.router}}
    />
  </div>
  
  */
  {
    "id": "mk40EQsx",
    "block": "{\"symbols\":[\"@classNames\",\"@isLeftAlignLast\",\"@isClickable\",\"@data\",\"@columns\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"standard-table \",[30,[36,0],[[32,3],\"table-clickable-row\"],null],\" \",[30,[36,0],[[32,2],\"td-last-left-align\"],null],\" \",[32,1]]]],[12],[2,\"\\n  \"],[8,\"models-table\",[],[[\"@data\",\"@columns\",\"@pageSize\",\"@multipleColumnsSorting\",\"@showComponentFooter\",\"@showColumnsDropdown\",\"@useFilteringByColumns\",\"@showGlobalFilter\",\"@doFilteringByHiddenColumns\",\"@useNumericPagination\",\"@filteringIgnoreCase\",\"@collapseNumPaginationForPagesCount\",\"@showPageSize\",\"@displayDataChangedAction\",\"@selectedItems\",\"@brush\",\"@store\",\"@router\"],[[32,4],[32,5],[32,0,[\"pageSize\"]],[32,0,[\"multipleColumnsSorting\"]],[32,0,[\"showComponentFooter\"]],[32,0,[\"showColumnsDropdown\"]],[32,0,[\"useFilteringByColumns\"]],[32,0,[\"showGlobalFilter\"]],[32,0,[\"doFilteringByHiddenColumns\"]],[32,0,[\"useNumericPagination\"]],[32,0,[\"filteringIgnoreCase\"]],[32,0,[\"collapseNumPaginationForPagesCount\"]],[32,0,[\"showPageSize\"]],[32,0,[\"rowClick\"]],[32,0,[\"selectedItems\"]],[32,0,[\"brush\"]],[32,0,[\"store\"]],[32,0,[\"router\"]]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "alliance-business-suite/components/tables/table-interactive.hbs"
  });

  // we extended ember-models-table in the components/models-table.js file
  // we did this to make sure we can tell the difference between user clicks on rows
  // and other event types (such as table initial load)
  // ember-models-table v3.0.1
  let TablesTableInteractiveComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._action, (_class = class TablesTableInteractiveComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "brush", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "router", _descriptor3, this);
      (0, _defineProperty2.default)(this, "showComponentFooter", true);
      (0, _defineProperty2.default)(this, "showColumnsDropdown", false);
      (0, _defineProperty2.default)(this, "useFilteringByColumns", false);
      (0, _defineProperty2.default)(this, "showGlobalFilter", false);
      (0, _defineProperty2.default)(this, "useNumericPagination", true);
      (0, _defineProperty2.default)(this, "doFilteringByHiddenColumns", false);
      (0, _defineProperty2.default)(this, "filteringIgnoreCase", false);
      (0, _defineProperty2.default)(this, "multipleColumnsSorting", false);
      (0, _defineProperty2.default)(this, "showPageSize", true);
      (0, _defineProperty2.default)(this, "collapseNumPaginationForPagesCount", 1);
      (0, _defineProperty2.default)(this, "pageSize", 150);
      (0, _defineProperty2.default)(this, "columnsAreUpdateable", true);
    }

    openBrush(e, modelType) {
      let recordId = e.selectedItems.length > 0 ? e.selectedItems[0].get('id') : null;

      if (recordId) {
        this.store.findRecord(modelType, recordId).then(result => {
          this.brush.transitionTo(modelType, result, 'show');
        });
      } else {
        this.brush.transitionTo('default', null);
      }
    }

    rowClick(e) {
      // TODO: Second click unselects the row
      if (e.eventType == 'userClick') {
        if (this.args.brushModel) {
          this.openBrush(e, this.args.brushModel);
        } else {
          // the @self is so that the callback action can preserve the "this"
          // see categories-section.js (aka this.set('prop') -> self.set)
          if (this.args.displayDataChangedAction && this.args.self) {
            this.args.displayDataChangedAction(e, this, this.args.self);
          } else {
            this.args.displayDataChangedAction(e, this);
          }
        }
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "brush", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "rowClick", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "rowClick"), _class.prototype)), _class));
  _exports.default = TablesTableInteractiveComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TablesTableInteractiveComponent);
});