define("alliance-business-suite/components/employees/shift-rate", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{!-- <ul>
    <li>employee: {{@employee.ratePerHour}}</li>
    <li>shift: {{@shift.ratePerHour}}</li>
    <li>this: {{this.ratePerHour}}</li>
  </ul> --}}
  
  <div class="input-group"
    {{did-insert this.initialLoad}}
    {{did-update this.didUpdateEmployee @employee}}
    >
    {{#if this.showRatePerHour}}
      <Ui::AlsCurrencyInput
        @value={{this.ratePerHour}}
        @onUpdate={{fn this.internalUpdateRatePerHour @shift}} />
    {{else}}
      {{!-- due to a render bug need to duplicate this when switching employees --}}
      <span class="input-append loading">Loading...</span>
    {{/if}}
    <div class="input-append">$/hour</div>
  </div>
  
  */
  {
    "id": "P6RgxH5j",
    "block": "{\"symbols\":[\"@shift\",\"@employee\"],\"statements\":[[2,\"\\n\"],[11,\"div\"],[24,0,\"input-group\"],[4,[38,1],[[32,0,[\"initialLoad\"]]],null],[4,[38,2],[[32,0,[\"didUpdateEmployee\"]],[32,2]],null],[12],[2,\"\\n\"],[6,[37,3],[[32,0,[\"showRatePerHour\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"ui/als-currency-input\",[],[[\"@value\",\"@onUpdate\"],[[32,0,[\"ratePerHour\"]],[30,[36,0],[[32,0,[\"internalUpdateRatePerHour\"]],[32,1]],null]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"input-append loading\"],[12],[2,\"Loading...\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"div\"],[14,0,\"input-append\"],[12],[2,\"$/hour\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"did-insert\",\"did-update\",\"if\"]}",
    "moduleName": "alliance-business-suite/components/employees/shift-rate.hbs"
  });

  let EmployeesShiftRateComponent = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, (_class = class EmployeesShiftRateComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "showRatePerHour", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "ratePerHour", _descriptor2, this);
    }

    initialLoad() {
      this.ratePerHour = this.setInitialRatePerHour();
      setTimeout(() => {
        this.showRatePerHour = true; // see above
      }, 200);
    }

    didUpdateEmployee() {
      this.showRatePerHour = false; // due to a render bug need to duplicate this when switching employees

      this.ratePerHour = this.setInitialRatePerHour();
      this.internalUpdateRatePerHour(this.args.shift, this.ratePerHour);
      setTimeout(() => {
        this.showRatePerHour = true; // see above
      }, 200);
    }

    internalUpdateRatePerHour(item, val) {
      this.args.updateRatePerHour(item, val);
    }

    setInitialRatePerHour() {
      const shiftRate = this.args.shift.get('ratePerHour');
      const employeeRate = this.args.employee.get('ratePerHour');
      let final = employeeRate;

      if (this.args.shift.get('id') && shiftRate >= 0) {
        final = shiftRate;
      }

      return final;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "showRatePerHour", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "ratePerHour", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "initialLoad", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "initialLoad"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "didUpdateEmployee", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "didUpdateEmployee"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "internalUpdateRatePerHour", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "internalUpdateRatePerHour"), _class.prototype)), _class));
  _exports.default = EmployeesShiftRateComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, EmployeesShiftRateComponent);
});