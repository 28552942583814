define("alliance-business-suite/components/employees/e-view", ["exports", "jquery", "alliance-business-suite/mixins/card-brush-state"], function (_exports, _jquery, _cardBrushState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let ComponentClass = Ember.Component.extend(_cardBrushState.default, {
    classNames: ['employee-view'],
    cards: ['main'],
    access: Ember.inject.service(),
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    downloadUrl: null,
    processingSalesDownload: null,
    // when the employee changes on the brush, reset the initial conditions
    employeeChange: Ember.observer('model.id', function () {
      this.setProperties({
        pinInteraction: false,
        isExistingPIN: false,
        noExistingPin: false
      });
    }),
    isSelf: Ember.computed('access.accessUser.id', 'model.id', function () {
      let accessId = Ember.get(this, 'access.accessUser.id');
      let modelId = Ember.get(this, 'model.id');
      return accessId === modelId;
    }),
    actions: {
      getEmployeePIN(employeeId) {
        let self = this;
        this.setProperties({
          isLoadingPin: true,
          pinInteraction: true
        });
        this.ajax.request(`employees/${employeeId}/pin?raw=true`, {
          method: 'GET'
        }).then(function (response) {
          self.set('isLoadingPin', false);

          if (!response.pin) {
            self.setProperties({
              noExistingPin: true,
              pinMessage: self.get('intl').t('no_employee_pin_found')
            });
          } else {
            self.setProperties({
              employeePIN: response.pin,
              isExistingPIN: true
            });
          }
        }).catch(err => {
          self.setProperties({
            isLoadingPin: false,
            pinInteraction: false
          });
        });
      },

      generateEmployeePIN(employeeId) {
        let self = this;
        this.setProperties({
          isLoadingPin: true,
          isExistingPIN: false,
          noExistingPin: false
        });
        this.ajax.request(`employees/${employeeId}/pin?raw=true`, {
          method: 'PATCH'
        }).then(response => {
          this.set('isLoadingPin', false);

          if (!response.pin) {
            self.setProperties({
              noExistingPin: true,
              pinMessage: self.get('intl').t('no_employee_pin_found')
            });
          } else {
            self.setProperties({
              employeePIN: response.pin,
              isExistingPIN: true
            });
          }
        }).catch(err => {
          self.setProperties({
            isLoadingPin: false
          });
        });
      },

      downloadSalesHistory(employeeId) {
        this.set('processingSalesDownload', true);
        this.set('downloadUrl', null);
        this.ajax.request(`/employees/${employeeId}/sales/download?raw=true`).then(result => {
          this.set('downloadUrl', result);
          setTimeout(() => {
            this.set('processingSalesDownload', false);
            document.getElementById('downloadSalesLink').click();
          }, 300);
        });
      }

    }
  });
  ComponentClass.reopenClass({
    positionalParams: ['model']
  });
  var _default = ComponentClass;
  _exports.default = _default;
});