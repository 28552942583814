define("alliance-business-suite/components/form-ui/low-power-container", ["exports", "alliance-business-suite/mixins/show-component"], function (_exports, _showComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_showComponent.default, {
    indexValues: Ember.computed('params.startIndex', function () {
      /*
      *  Index values used in property names can start with 0, 1, or A.
      *  For example:
      *    Program.LowPowerShutdown_A.On
      *    Program.Miscellaneous.LowPowerShutDown1.On
      *    Program.lpas1To4Message.lpasParametersMessage.${index}.lowPowerAutoShutdownEnable (stored in an array)
      */
      switch (this.params.startIndex) {
        case 0:
          return [0, 1, 2, 3].slice(0, this.count);

        case 1:
          return [1, 2, 3, 4].slice(0, this.count);

        case "A":
          return ["A", "B", "C", "D"].slice(0, this.count);

        default:
          return [0, 1, 2, 3].slice(0, this.count);
      }
    }),
    count: Ember.computed('params.count', function () {
      return this.params.count || 4;
    })
  });

  _exports.default = _default;
});