define("alliance-business-suite/components/machines/programs/cycles/cycle-info-midas-wx", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement() {
      this._super(...arguments);

      (0, _jquery.default)(this.element).focus();
      Mousetrap.bind(['esc', 'enter'], (e, combo) => {
        e.preventDefault();
        e.stopPropagation();
        Ember.get(this, 'close')();
      });
    },

    destroy() {
      this._super(...arguments); // Clean up keyboard shortcuts


      Mousetrap.unbind(['esc', 'enter']);
    },

    cycDescPropertyName: Ember.computed('object.CycDesc', function () {
      // Machines use two different property names, so we need to detect and use the correct one
      if (this.object.CycDesc) {
        switch (this.cycle.Number) {
          case 15:
            return "CycDesc.Text";

          default:
            // New cycles (16-19) store the description in different property naming convention
            return `CycDesc.TextCycle${this.cycle.Number}`;
        }
      } else {
        return "CycleDescription.Text";
      }
    }),
    actions: {
      selectDefaultTemperature(value) {
        // Update this property and check the same item in allowed temperatures.
        Ember.set(this, 'cycle.DefaultTemp', value.val);
        Ember.set(this, `cycle.TempOptions.${value.key}`, true);
      }

    }
  });

  _exports.default = _default;
});