define("alliance-business-suite/helpers/brand-value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    onBrandChange: Ember.observer('brand.name', function () {
      this.recompute();
    }),

    compute(_ref, hash) {
      let [key, ...rest] = _ref;
      // Match param based on brand abbrev (NOT slug)
      let abbrev = this.brand.abbrev;
      return hash[abbrev];
    }

  });

  _exports.default = _default;
});