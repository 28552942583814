define("alliance-business-suite/components/ui/modal-progress-bar", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "jquery"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor;

  let UiModalProgressBarComponent = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, (_class = class UiModalProgressBarComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "staticInitialAmount", _descriptor, this);
    }

    get savingProgress() {
      if (this.staticInitialAmount == 0) {
        return 0;
      }

      return parseInt((this.staticInitialAmount - this.args.currentAmount) / this.staticInitialAmount * 100);
    }

    initialLoad() {
      this.staticInitialAmount = this.args.initialAmount;
    }

    updateProgressBar() {
      const $ppc = document.getElementsByClassName('progress-pie-chart')[0];
      const percent = parseInt(this.savingProgress);
      const deg = 360 * percent / 100;

      if (percent > 50) {
        $ppc.classList.add('gt-50');
      }

      document.getElementsByClassName('ppc-progress-fill')[0].style.transform = `rotate(${deg}deg)`;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "staticInitialAmount", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "initialLoad", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "initialLoad"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "updateProgressBar", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "updateProgressBar"), _class.prototype)), _class));
  _exports.default = UiModalProgressBarComponent;
});