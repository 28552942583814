define("alliance-business-suite/mixins/init-property-names", ["exports", "alliance-business-suite/utils/machine-programming-property-names"], function (_exports, _machineProgrammingPropertyNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    // eslint-disable-line

    /**
     * Many components take a `propertyName` param that is the string used for DDAU and/or for binding to the model.
     *
     * Some components need more than a single `propertyName`. In this case, we pass a hash of properties using a
     * `properties` param. Each item of the hash can be one of three things...
     *
     * 1) A string that references the property name on the model.
     *
     *      properties=(hash
     *        minutes=this.properties.RemoteTopOffMinutes.key   // this one is referencing a string
     *        seconds="Program.Price.Misc.RemoteTopoffSeconds"  // this one is a literal string
     *      )
     *
     * 2) An object (another hash) with a `key` property that contains a string that references the property name
     *    on the model.
     *
     *      properties=(hash
     *        checkbox1=(hash
     *          key="Program.Miscellaneous.FillErrorEnabled"
     *          label="enable_fill_error_message"
     *        )
     *      )
     *
     * 3) An array of several possible property names, in which case the first one that is not `undefined`
     *    gets used as the property name.
     *
     *      properties=(hash
     *        timeMod=(array "Program.vendRelatedMessage.addWashAgitationTimeMod1TLW" "Program.vendRelatedMessage.addWashAgitationTimeMod1FLWWX")
     *      )
     *
     * There are three use-cases for these...
     *
     * string: The component simply needs access to multiple properties.
     *
     * object: The component needs access to multiple properties, but each of those properties might have different settings,
     * such as min/max values, distinct labels, etc.
     *
     * array: Like "string" (above), the component needs to access multiple properties, but there is more than one possibility
     * for the name. This can happen because some machines have properties named differently for TLW vs. FLW/WX...or it can happen
     * because we're supporting different machines that have completely different property names (Titanium machines are a drop-in
     * replacement for ACA machines, but most/all of the property names were changed).
     *
     * What this mixin does
     * --------------------
     * Within the component receiving all this stuff, we create a new `properties` object that has a `key` property that maps to
     * the string value of the `propertyName`. So `properties.minutes.key` and `properties.checkbox1.key` reference the name of
     * that property.
     *
     * We also bind directly to the property on the model so we can check it's value, so `this.minutes` and `this.checkbox1` will
     * give us the actual values of those properties.
     *
     */
    ifMachine: Ember.computed.alias('params.ifMachine'),
    notMachine: Ember.computed.alias('params.notMachine'),
    compName: Ember.computed('_debugContainerKey', function () {
      return this._debugContainerKey.replace('component:form-ui', '');
    }),

    didReceiveAttrs() {
      // was init
      // This handles bindings for components that update multiple properties: low-power, lucky cycle, manual mode, power-fail-reset
      // It sets up CPs for the property name strings and CPs to the property values.
      this._super(...arguments);

      let index = this.index;

      if (Ember.isEmpty(index)) {
        index = this.params?.index;
      }

      let propertiesObject = this.params?.properties;
      let properties = {};

      if (Ember.isNone(propertiesObject)) {
        console.error("No property names found for init-property-names."); // eslint-disable-line no-console

        Ember.set(this, 'properties', null);
        return;
      } // Properties can be one of three things:
      // 1: an object with properties that are strings - a list of properties and the string defining the property on the model
      // 2: an object with properties that are objects


      if (Ember.typeOf(propertiesObject) == "object") {
        // Are the keys strings or objects?
        Object.keys(propertiesObject).forEach(name => {
          if (Ember.isEmpty(propertiesObject[name])) {
            // HACK: We need to clear out any properties that should be empty now. I think this is due to singletons.
            // MGD_OPL_WX cycles has Temp vs. Temperature and ChemFlush vs. no ChemFlush. This clears out the
            // old values...think SINGLETONS!
            if (Ember.get(this, name) !== undefined) {
              Ember.set(this, name, null);
            }

            return; // We don't do anything with null or undefined property names
          } else if (Ember.typeOf(propertiesObject[name]) == "string") {
            properties[name] = {
              key: (0, _machineProgrammingPropertyNames.replaceIndexPlaceholder)(propertiesObject[name], index)
            };
          } else if (Ember.typeOf(propertiesObject[name]) == "array") {
            // Find which element is the correct property name, and use that
            let correctPropertyName = (0, _machineProgrammingPropertyNames.findValidPropertyName)(this.object, propertiesObject[name], index);
            properties[name] = {
              key: correctPropertyName
            };
          } else {
            // object
            properties[name] = propertiesObject[name];
            Ember.set(properties[name], "key", (0, _machineProgrammingPropertyNames.replaceIndexPlaceholder)(properties[name].key, index)); // Update any keys with ${index} in them
          } // let key = propertiesObject[name].key || propertiesObject[name];


          let key = properties[name].key || properties[name];
          Ember.defineProperty(this, name, Ember.computed.reads(`object.${key}`));
          this.notifyPropertyChange(name);
        });
      } // 3: an array of objects


      if (Ember.typeOf(propertiesObject) == "array") {
        propertiesObject.forEach(prop => {
          let name = prop.name;
          let key = (0, _machineProgrammingPropertyNames.replaceIndexPlaceholder)(prop.key, index);
          properties[name] = prop;
          Ember.defineProperty(this, name, Ember.computed.reads(`object.${key}`));
          this.notifyPropertyChange(name);
        });
      }

      Ember.set(this, 'properties', properties);
    } // didReceiveAttrs


  });

  _exports.default = _default;
});