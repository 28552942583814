define("alliance-business-suite/components/summary/reports/s-touch-modifiers", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "alliance-business-suite/utils/report-tables-util"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _reportTablesUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  let SummaryReportsSTouchModifiersComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, (_class = class SummaryReportsSTouchModifiersComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "brand", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor4, this);
    }

    get translatedColumns() {
      return [{
        name: 'location_machine',
        valuePath: 'name',
        width: 379,
        isFixed: 'left'
      }, {
        name: 'Temp15C',
        valuePath: 'temp_15c',
        width: 180
      }, {
        name: 'Temp30C',
        valuePath: 'temp_30c',
        width: 180
      }, {
        name: 'Temp40C',
        valuePath: 'temp_40c',
        width: 180
      }, {
        name: 'Temp50C',
        valuePath: 'temp_50c',
        width: 222
      }, {
        name: 'Temp60C',
        valuePath: 'temp_60c',
        width: 180
      }, {
        name: 'Temp90C',
        valuePath: 'temp_90c',
        width: 216
      }, {
        name: 'cold',
        valuePath: 'cold',
        width: 180
      }, {
        name: 'warm',
        valuePath: 'warm',
        width: 180
      }, {
        name: 'hot',
        valuePath: 'hot',
        width: 180
      }, {
        name: 'no_heat',
        valuePath: 'no_heat',
        width: 180
      }, {
        name: 'IncreasedSpinSpeed',
        valuePath: 'increasedSpinSpeed',
        width: 180
      }, {
        name: 'increased_water_level',
        valuePath: 'increasedWaterLevel',
        width: 180
      }, // { name: 'anti_wrinkle', valuePath: 'antiWrinkleTum', width: 180},
      {
        name: 'dispense_soap',
        valuePath: 'dispenseSoap',
        width: 180
      }, // { name: 'extra_soap', valuePath: 'extraSoap', width: 180},
      {
        name: 'extra_time',
        valuePath: 'extraTime',
        width: 180
      }, {
        name: 'medium_bundle',
        valuePath: 'mediumBundle',
        width: 180
      }, {
        name: 'heavy_bundle',
        valuePath: 'heavyBundle',
        width: 180
      }, {
        name: 'ozone',
        valuePath: 'ozone_wx',
        width: 180
      }, // { name: 'reversing', valuePath: 'reversing_tum', width: 180},
      {
        name: 'speed',
        valuePath: 'speed',
        width: 180
      }, {
        name: 'warm_final_rinse',
        valuePath: 'warm_final_rinse',
        width: 180
      }, {
        name: 'mod_1',
        valuePath: 'mod1',
        width: 180
      }, {
        name: 'mod_2',
        valuePath: 'mod2',
        width: 180
      }];
    }

    get rowsWithChildren() {
      let brand = this.brand;
      let {
        model
      } = this.args;
      let rooms = model.rooms;
      let roomData = [];
      let grandMediumBundleSum = 0;
      let grandTemp_15cSum = 0;
      let grandTemp_40cSum = 0;
      let grandTemp_50cSum = 0;
      let grandTemp_30cSum = 0;
      let grandtemp_90cSum = 0;
      let grandWarmSum = 0;
      let grandTemp_60cSum = 0;
      let grandincreasedSpinSpeedSum = 0;
      let grandIncreasedWaterLevelSum = 0;
      let grandHotSum = 0;
      let grandAntiWrinkleTumSum = 0;
      let grandColdSum = 0;
      let grandDispenseSoapSum = 0;
      let grandExtraSoapSum = 0;
      let grandExtraTimeSum = 0;
      let grandHeavyBundleSum = 0;
      let grandMod1BundleSum = 0;
      let grandMod2BundleSum = 0;
      let grandNoHeatSum = 0;
      let grandOzoneWxSum = 0;
      let grandReversingTumSum = 0;
      let grandSpeedSum = 0;
      let grandWarmFinalRinseSum = 0;
      let totalGrandRoomMediumBundleCount = [];
      let totalGrandRoomTemp_15cCount = [];
      let totalGrandRoomTemp_40cCount = [];
      let totalGrandRoomTemp_90cCount = [];
      let totalGrandRoomIncreasedSpinSpeed = [];
      let totalGrandRoomTemp_50c = [];
      let totalGrandRoomwarmCount = [];
      let totalGrandRoomIncreasedWaterLevel = [];
      let totalGrandRoomTemp_30cCount = [];
      let totalGrandRoomHot = [];
      let totalGrandRoomTemp_60cCount = [];
      let totalGrandRoomAntiWrinkleTumCount = [];
      let totalGrandRoomColdCount = [];
      let totalGrandRoomCycleCount12Count = [];
      let totalGrandRoomExtraSoapCount = [];
      let totalGrandRoomExtraTimeCount = [];
      let totalGrandRoomHeavyBundleCount = [];
      let totalGrandRoomMod1BundleCount = [];
      let totalGrandRoomMod2BundleCount = [];
      let totalGrandRoomNoHeatCount = [];
      let totalGrandRoomOzoneWxCount = [];
      let totalGrandRoomReversingTumCount = [];
      let totalGrandRoomSpeedCount = [];
      let totalGrandRoomWarmFinalRinseCount = [];
      let rowData = [{
        name: this.intl.t('grandTotal'),
        children: roomData,
        mediumBundle: totalGrandRoomMediumBundleCount,
        temp_15c: totalGrandRoomTemp_15cCount,
        temp_40c: totalGrandRoomTemp_40cCount,
        temp_50c: totalGrandRoomTemp_50c,
        temp_30c: totalGrandRoomTemp_30cCount,
        temp_90c: totalGrandRoomTemp_90cCount,
        warm: totalGrandRoomwarmCount,
        temp_60c: totalGrandRoomTemp_60cCount,
        increasedSpinSpeed: totalGrandRoomIncreasedSpinSpeed,
        increasedWaterLevel: totalGrandRoomIncreasedWaterLevel,
        hot: totalGrandRoomHot,
        antiWrinkleTum: totalGrandRoomAntiWrinkleTumCount,
        cold: totalGrandRoomColdCount,
        dispenseSoap: totalGrandRoomCycleCount12Count,
        extraSoap: totalGrandRoomExtraSoapCount,
        extraTime: totalGrandRoomExtraTimeCount,
        heavyBundle: totalGrandRoomHeavyBundleCount,
        mod1: totalGrandRoomMod1BundleCount,
        mod2: totalGrandRoomMod2BundleCount,
        no_heat: totalGrandRoomNoHeatCount,
        ozone_wx: totalGrandRoomOzoneWxCount,
        reversing_tum: totalGrandRoomReversingTumCount,
        speed: totalGrandRoomSpeedCount,
        warm_final_rinse: totalGrandRoomWarmFinalRinseCount
      }]; // geoBoundaries.forEach((geoBoundary) => {

      let rowArray = [];
      let nameArray = [];
      let mediumBundleSum = 0;
      let temp_15cSum = 0;
      let temp_40cSum = 0;
      let temp_50cSum = 0;
      let temp_30cSum = 0;
      let temp_90cSum = 0;
      let warmSum = 0;
      let temp_60cSum = 0;
      let increasedSpinSpeedSum = 0;
      let increasedWaterLevelSum = 0;
      let hotSum = 0;
      let antiWrinkleTumSum = 0;
      let coldSum = 0;
      let dispenseSoapSum = 0;
      let extraSoapSum = 0;
      let extraTimeSum = 0;
      let heavyBundleSum = 0;
      let mod1BundleSum = 0;
      let mod2BundleSum = 0;
      let noHeatSum = 0;
      let ozoneWxSum = 0;
      let reversingTumSum = 0;
      let speedSum = 0;
      let warmFinalRinseSum = 0; // let totalRoomMediumBundleCount = [];
      // let totalRoomTemp_15cCount = [];
      // let totalRoomTemp_40cCount = [];
      // let totalRoomTemp_90cCount = [];
      // let totalRoomIncreasedSpinSpeed = [];
      // let totalRoomTemp_50c = [];
      // let totalRoomwarmCount = [];
      // let totalRoomIncreasedWaterLevel = [];
      // let totalRoomTemp_30cCount = [];
      // let totalRoomHot = [];
      // let totalRoomTemp_60cCount = [];
      // let totalRoomAntiWrinkleTumCount = [];
      // let totalRoomColdCount = [];
      // let totalRoomCycleCount12Count = [];
      // let totalRoomExtraSoapCount = [];
      // let totalRoomExtraTimeCount = [];
      // let totalRoomHeavyBundleCount = [];
      // let totalRoomMod1BundleCount = [];
      // let totalRoomMod2BundleCount = [];
      // let totalRoomNoHeatCount = [];
      // let totalRoomOzoneWxCount = [];
      // let totalRoomReversingTumCount = [];
      // let totalRoomSpeedCount = [];
      // let totalRoomWarmFinalRinseCount = [];
      //
      //   roomData.push({
      //     name: localizeString.call(this, geoBoundary.name, 'Location', 'location'),
      //     children: nameArray,
      //     mediumBundle: totalRoomMediumBundleCount,
      //     temp_15c: totalRoomTemp_15cCount,
      //     temp_40c: totalRoomTemp_40cCount,
      //     temp_50c: totalRoomTemp_50c,
      //     temp_30c: totalRoomTemp_30cCount,
      //     temp_90c: totalRoomTemp_90cCount,
      //     warm: totalRoomwarmCount,
      //     temp_60c: totalRoomTemp_60cCount,
      //     increasedSpinSpeed: totalRoomIncreasedSpinSpeed,
      //     increasedWaterLevel: totalRoomIncreasedWaterLevel,
      //     hot: totalRoomHot,
      //     antiWrinkleTum: totalRoomAntiWrinkleTumCount,
      //     cold: totalRoomColdCount,
      //     dispenseSoap: totalRoomCycleCount12Count,
      //     extraSoap: totalRoomExtraSoapCount,
      //     extraTime: totalRoomExtraTimeCount,
      //     heavyBundle: totalRoomHeavyBundleCount,
      //     mod1: totalRoomMod1BundleCount,
      //     mod2: totalRoomMod2BundleCount,
      //     no_heat: totalRoomNoHeatCount,
      //     ozone_wx: totalRoomOzoneWxCount,
      //     reversing_tum: totalRoomReversingTumCount,
      //     speed: totalRoomSpeedCount,
      //     warm_final_rinse: totalRoomWarmFinalRinseCount
      //   });

      rooms.forEach(room => {
        let mediumBundleSum1 = 0;
        let temp_15cSum1 = 0;
        let temp_40cSum1 = 0;
        let temp_50cSum1 = 0;
        let temp_30cSum1 = 0;
        let temp_90cSum1 = 0;
        let warmSum1 = 0;
        let temp_60cSum1 = 0;
        let increasedSpinSpeedSum1 = 0;
        let increasedWaterLevelSum1 = 0;
        let hotSum1 = 0;
        let antiWrinkleTumSum1 = 0;
        let coldSum1 = 0;
        let dispenseSoapSum1 = 0;
        let extraSoapSum1 = 0;
        let extraTimeSum1 = 0;
        let heavyBundleSum1 = 0;
        let mod1BundleSum1 = 0;
        let mod2BundleSum1 = 0;
        let noHeatSum1 = 0;
        let ozoneWxSum1 = 0;
        let reversingTumSum1 = 0;
        let speedSum1 = 0;
        let warmFinalRinseSum1 = 0;
        let totalRoomMediumBundleCount1 = [];
        let totalRoomTemp_15cCount1 = [];
        let totalRoomTemp_40cCount1 = [];
        let totalRoomTemp_90cCount1 = [];
        let totalRoomIncreasedSpinSpeed1 = [];
        let totalRoomTemp_50c1 = [];
        let totalRoomwarmCount1 = [];
        let totalRoomIncreasedWaterLevel1 = [];
        let totalRoomTemp_30cCount1 = [];
        let totalRoomTemp_60cCount1 = [];
        let totalRoomHot1 = [];
        let totalRoomAntiWrinkleTumCount1 = [];
        let totalRoomColdCount1 = [];
        let totalRoomCycleCount12Count1 = [];
        let totalRoomExtraSoapCount1 = [];
        let totalRoomExtraTimeCount1 = [];
        let totalRoomHeavyBundleCount1 = [];
        let totalRoomMod1BundleCount1 = [];
        let totalRoomMod2BundleCount1 = [];
        let totalRoomNoHeatCount1 = [];
        let totalRoomOzoneWxCount1 = [];
        let totalRoomReversingTumCount1 = [];
        let totalRoomSpeedCount1 = [];
        let totalRoomWarmFinalRinseCount1 = [];
        roomData.push({
          name: room.name,
          children: rowArray,
          mediumBundle: totalRoomMediumBundleCount1,
          temp_15c: totalRoomTemp_15cCount1,
          temp_40c: totalRoomTemp_40cCount1,
          temp_50c: totalRoomTemp_50c1,
          temp_30c: totalRoomTemp_30cCount1,
          temp_90c: totalRoomTemp_90cCount1,
          warm: totalRoomwarmCount1,
          temp_60c: totalRoomTemp_60cCount1,
          increasedSpinSpeed: totalRoomIncreasedSpinSpeed1,
          increasedWaterLevel: totalRoomIncreasedWaterLevel1,
          hot: totalRoomHot1,
          antiWrinkleTum: totalRoomAntiWrinkleTumCount1,
          cold: totalRoomColdCount1,
          dispenseSoap: totalRoomCycleCount12Count1,
          extraSoap: totalRoomExtraSoapCount1,
          extraTime: totalRoomExtraTimeCount1,
          heavyBundle: totalRoomHeavyBundleCount1,
          mod1: totalRoomMod1BundleCount1,
          mod2: totalRoomMod2BundleCount1,
          no_heat: totalRoomNoHeatCount1,
          ozone_wx: totalRoomOzoneWxCount1,
          reversing_tum: totalRoomReversingTumCount1,
          speed: totalRoomSpeedCount1,
          warm_final_rinse: totalRoomWarmFinalRinseCount1
        });
        room.machineAuditGroupTypes.forEach(machineAuditType => {
          machineAuditType.machineTypes.forEach(machineType => {
            let isTouch = machineAuditType.name == 'Touch';
            let machineTypeName = `${machineAuditType.name} - ${machineType.name}`;

            if (isTouch) {
              if (brand == "PRIMUS") {
                machineTypeName = `XControl Flex - ${machineType.name}`;
              }

              if (brand == "IPSO") {
                machineTypeName = `Evolis - ${machineType.name}`;
              }

              if (brand == "SPEED_QUEEN") {
                machineTypeName = `Quantum Touch - ${machineType.name}`;
              }
            }

            let machineArray = [];
            let mediumBundleSum2 = 0;
            let temp_15cSum2 = 0;
            let temp_40cSum2 = 0;
            let temp_50cSum2 = 0;
            let temp_30cSum2 = 0;
            let temp_90cSum2 = 0;
            let warmSum2 = 0;
            let temp_60cSum2 = 0;
            let increasedSpinSpeedSum2 = 0;
            let increasedWaterLevelSum2 = 0;
            let hotSum2 = 0;
            let antiWrinkleTumSum2 = 0;
            let coldSum2 = 0;
            let dispenseSoapSum2 = 0;
            let extraSoapSum2 = 0;
            let extraTimeSum2 = 0;
            let heavyBundleSum2 = 0;
            let mod1BundleSum2 = 0;
            let mod2BundleSum2 = 0;
            let noHeatSum2 = 0;
            let ozoneWxSum2 = 0;
            let reversingTumSum2 = 0;
            let speedSum2 = 0;
            let warmFinalRinseSum2 = 0;
            let totalRoomMediumBundleCount2 = [];
            let totalRoomTemp_15cCount2 = [];
            let totalRoomTemp_40cCount2 = [];
            let totalRoomTemp_90cCount2 = [];
            let totalRoomIncreasedSpinSpeed2 = [];
            let totalRoomTemp_50c2 = [];
            let totalRoomwarmCount2 = [];
            let totalRoomIncreasedWaterLevel2 = [];
            let totalRoomTemp_30cCount2 = [];
            let totalRoomTemp_60cCount2 = [];
            let totalRoomHot2 = [];
            let totalRoomAntiWrinkleTumArray2 = [];
            let totalRoomColdArray2 = [];
            let totalRoomCycleCount12Array2 = [];
            let totalRoomExtraSoapArray2 = [];
            let totalRoomExtraTimeArray2 = [];
            let totalRoomHeavyBundleArray2 = [];
            let totalRoomMod1BundleArray2 = [];
            let totalRoomMod2BundleArray2 = [];
            let totalRoomNoHeatArray2 = [];
            let totalRoomOzoneWxArray2 = [];
            let totalRoomReversingTumArray2 = [];
            let totalRoomSpeedArray2 = [];
            let totalRoomWarmFinalRinseArray2 = [];
            rowArray.push({
              name: machineTypeName,
              machineTypeName: machineType.name,
              children: machineArray,
              mediumBundle: totalRoomMediumBundleCount2,
              temp_15c: totalRoomTemp_15cCount2,
              temp_40c: totalRoomTemp_40cCount2,
              temp_50c: totalRoomTemp_50c2,
              temp_30c: totalRoomTemp_30cCount2,
              temp_90c: totalRoomTemp_90cCount2,
              warm: totalRoomwarmCount2,
              temp_60c: totalRoomTemp_60cCount2,
              increasedSpinSpeed: totalRoomIncreasedSpinSpeed2,
              increasedWaterLevel: totalRoomIncreasedWaterLevel2,
              hot: totalRoomHot2,
              antiWrinkleTum: totalRoomAntiWrinkleTumArray2,
              cold: totalRoomColdArray2,
              dispenseSoap: totalRoomCycleCount12Array2,
              extraSoap: totalRoomExtraSoapArray2,
              extraTime: totalRoomExtraTimeArray2,
              heavyBundle: totalRoomHeavyBundleArray2,
              mod1: totalRoomMod1BundleArray2,
              mod2: totalRoomMod2BundleArray2,
              no_heat: totalRoomNoHeatArray2,
              ozone_wx: totalRoomOzoneWxArray2,
              reversing_tum: totalRoomReversingTumArray2,
              speed: totalRoomSpeedArray2,
              warm_final_rinse: totalRoomWarmFinalRinseArray2
            });
            machineType.machines.forEach(machine => {
              let dateRange = "";
              let mediumBundleArray = [];
              let temp_15cArray = [];
              let temp_40cArray = [];
              let temp_50cArray = [];
              let temp_30cArray = [];
              let temp_90cArray = [];
              let warmArray = [];
              let temp_60cArray = [];
              let increasedSpinSpeedPercentArray = [];
              let increasedWaterLevelPercentArray = [];
              let hotPercentArray = [];
              let antiWrinkleTumArray = [];
              let coldArray = [];
              let dispenseSoapArray = [];
              let extraSoapArray = [];
              let extraTimeArray = [];
              let heavyBundleArray = [];
              let mod1BundleArray = [];
              let mod2BundleArray = [];
              let noHeatArray = [];
              let ozoneWxArray = [];
              let reversingTumArray = [];
              let speedArray = [];
              let warmFinalRinseArray = [];
              let isDryer = machineType.name.toUpperCase().indexOf('DRYER') > -1 || machineType.name.toUpperCase().indexOf('TUMBLER') > -1;

              if (this.brand.slug == 'primus') {
                // fix the branding issue alliance-tasks/issues/2291
                rowArray[rowArray.length - 1].name = `${machine.machineAuditType ? machine.machineAuditType.name : ''} - ${rowArray[rowArray.length - 1].machineTypeName}`;
              }

              if (machine.modifierCycleCounts.medium_bundle !== 0) {
                mediumBundleArray.push(machine.modifierCycleCounts.medium_bundle);
                mediumBundleSum = (0, _reportTablesUtil.totalRows)(mediumBundleArray, mediumBundleSum);
                mediumBundleSum1 = (0, _reportTablesUtil.totalRows)(mediumBundleArray, mediumBundleSum1);
                mediumBundleSum2 = (0, _reportTablesUtil.totalRows)(mediumBundleArray, mediumBundleSum2);
                grandMediumBundleSum = (0, _reportTablesUtil.totalRows)(mediumBundleArray, grandMediumBundleSum);
              }

              if (machine.modifierCycleCounts.temp_15c !== 0) {
                temp_15cArray.push(machine.modifierCycleCounts.temp_15c);
                temp_15cSum = (0, _reportTablesUtil.totalRows)(temp_15cArray, temp_15cSum);
                grandTemp_15cSum = (0, _reportTablesUtil.totalRows)(temp_15cArray, grandTemp_15cSum);
                temp_15cSum1 = (0, _reportTablesUtil.totalRows)(temp_15cArray, temp_15cSum1);
                temp_15cSum2 = (0, _reportTablesUtil.totalRows)(temp_15cArray, temp_15cSum2);
              }

              if (!isDryer) {
                if (machine.modifierCycleCounts.temp_40c !== 0) {
                  temp_40cArray.push(machine.modifierCycleCounts.temp_40c);
                  temp_40cSum = (0, _reportTablesUtil.totalRows)(temp_40cArray, temp_40cSum);
                  grandTemp_40cSum = (0, _reportTablesUtil.totalRows)(temp_40cArray, grandTemp_40cSum);
                  temp_40cSum1 = (0, _reportTablesUtil.totalRows)(temp_40cArray, temp_40cSum1);
                  temp_40cSum2 = (0, _reportTablesUtil.totalRows)(temp_40cArray, temp_40cSum2);
                }

                if (machine.modifierCycleCounts.temp_50c !== 0) {
                  temp_50cArray.push(machine.modifierCycleCounts.temp_50c);
                  temp_50cSum = (0, _reportTablesUtil.totalRows)(temp_50cArray, temp_50cSum);
                  grandTemp_50cSum = (0, _reportTablesUtil.totalRows)(temp_50cArray, grandTemp_50cSum);
                  temp_50cSum1 = (0, _reportTablesUtil.totalRows)(temp_50cArray, temp_50cSum1);
                  temp_50cSum2 = (0, _reportTablesUtil.totalRows)(temp_50cArray, temp_50cSum2);
                }

                if (machine.modifierCycleCounts.temp_30c !== 0) {
                  temp_30cArray.push(machine.modifierCycleCounts.temp_30c);
                  temp_30cSum = (0, _reportTablesUtil.totalRows)(temp_30cArray, temp_30cSum);
                  grandTemp_30cSum = (0, _reportTablesUtil.totalRows)(temp_30cArray, grandTemp_30cSum);
                  temp_30cSum1 = (0, _reportTablesUtil.totalRows)(temp_30cArray, temp_30cSum1);
                  temp_30cSum2 = (0, _reportTablesUtil.totalRows)(temp_30cArray, temp_30cSum2);
                }

                if (machine.modifierCycleCounts.temp_90c !== 0) {
                  temp_90cArray.push(machine.modifierCycleCounts.temp_90c);
                  temp_90cSum = (0, _reportTablesUtil.totalRows)(temp_90cArray, temp_90cSum);
                  grandtemp_90cSum = (0, _reportTablesUtil.totalRows)(temp_90cArray, grandtemp_90cSum);
                  temp_90cSum1 = (0, _reportTablesUtil.totalRows)(temp_90cArray, temp_90cSum1);
                  temp_90cSum2 = (0, _reportTablesUtil.totalRows)(temp_90cArray, temp_90cSum2);
                }

                if (machine.modifierCycleCounts.warm !== 0) {
                  warmArray.push(machine.modifierCycleCounts.warm);
                  warmSum = (0, _reportTablesUtil.totalRows)(warmArray, warmSum);
                  grandWarmSum = (0, _reportTablesUtil.totalRows)(warmArray, grandWarmSum);
                  warmSum1 = (0, _reportTablesUtil.totalRows)(warmArray, warmSum1);
                  warmSum2 = (0, _reportTablesUtil.totalRows)(warmArray, warmSum2);
                }

                if (machine.modifierCycleCounts.temp_60c !== 0) {
                  temp_60cArray.push(machine.modifierCycleCounts.temp_60c);
                  temp_60cSum = (0, _reportTablesUtil.totalRows)(temp_60cArray, temp_60cSum);
                  grandTemp_60cSum = (0, _reportTablesUtil.totalRows)(temp_60cArray, grandTemp_60cSum);
                  temp_60cSum1 = (0, _reportTablesUtil.totalRows)(temp_60cArray, temp_60cSum1);
                  temp_60cSum2 = (0, _reportTablesUtil.totalRows)(temp_60cArray, temp_60cSum2);
                }

                if (machine.modifierCycleCounts.increased_spin_speed !== 0) {
                  increasedSpinSpeedPercentArray.push(machine.modifierCycleCounts.increased_spin_speed);
                  increasedSpinSpeedSum = (0, _reportTablesUtil.totalRows)(increasedSpinSpeedPercentArray, increasedSpinSpeedSum);
                  grandincreasedSpinSpeedSum = (0, _reportTablesUtil.totalRows)(increasedSpinSpeedPercentArray, grandincreasedSpinSpeedSum);
                  increasedSpinSpeedSum1 = (0, _reportTablesUtil.totalRows)(increasedSpinSpeedPercentArray, increasedSpinSpeedSum1);
                  increasedSpinSpeedSum2 = (0, _reportTablesUtil.totalRows)(increasedSpinSpeedPercentArray, increasedSpinSpeedSum2);
                }

                if (machine.modifierCycleCounts.increased_water_level !== 0) {
                  increasedWaterLevelPercentArray.push(machine.modifierCycleCounts.increased_water_level);
                  increasedWaterLevelSum = (0, _reportTablesUtil.totalRows)(increasedWaterLevelPercentArray, increasedWaterLevelSum);
                  grandIncreasedWaterLevelSum = (0, _reportTablesUtil.totalRows)(increasedWaterLevelPercentArray, grandIncreasedWaterLevelSum);
                  increasedWaterLevelSum1 = (0, _reportTablesUtil.totalRows)(increasedWaterLevelPercentArray, increasedWaterLevelSum1);
                  increasedWaterLevelSum2 = (0, _reportTablesUtil.totalRows)(increasedWaterLevelPercentArray, increasedWaterLevelSum2);
                }

                if (machine.modifierCycleCounts.hot !== 0) {
                  hotPercentArray.push(machine.modifierCycleCounts.hot);
                  hotSum = (0, _reportTablesUtil.totalRows)(hotPercentArray, hotSum);
                  grandHotSum = (0, _reportTablesUtil.totalRows)(hotPercentArray, grandHotSum);
                  hotSum1 = (0, _reportTablesUtil.totalRows)(hotPercentArray, hotSum1);
                  hotSum2 = (0, _reportTablesUtil.totalRows)(hotPercentArray, hotSum2);
                }

                if (machine.modifierCycleCounts.antiwrinkle_tum !== 0) {
                  antiWrinkleTumArray.push(machine.modifierCycleCounts.antiwrinkle_tum);
                  antiWrinkleTumSum = (0, _reportTablesUtil.totalRows)(antiWrinkleTumArray, antiWrinkleTumSum);
                  grandAntiWrinkleTumSum = (0, _reportTablesUtil.totalRows)(antiWrinkleTumArray, grandAntiWrinkleTumSum);
                  antiWrinkleTumSum1 = (0, _reportTablesUtil.totalRows)(antiWrinkleTumArray, antiWrinkleTumSum1);
                  antiWrinkleTumSum2 = (0, _reportTablesUtil.totalRows)(antiWrinkleTumArray, antiWrinkleTumSum2);
                }

                if (machine.modifierCycleCounts.cold !== 0) {
                  coldArray.push(machine.modifierCycleCounts.cold);
                  coldSum = (0, _reportTablesUtil.totalRows)(coldArray, coldSum);
                  grandColdSum = (0, _reportTablesUtil.totalRows)(coldArray, grandColdSum);
                  coldSum1 = (0, _reportTablesUtil.totalRows)(coldArray, coldSum1);
                  coldSum2 = (0, _reportTablesUtil.totalRows)(coldArray, coldSum2);
                }

                if (machine.modifierCycleCounts.dispense_soap !== 0) {
                  dispenseSoapArray.push(machine.modifierCycleCounts.dispense_soap);
                  dispenseSoapSum = (0, _reportTablesUtil.totalRows)(dispenseSoapArray, dispenseSoapSum);
                  grandDispenseSoapSum = (0, _reportTablesUtil.totalRows)(dispenseSoapArray, grandDispenseSoapSum);
                  dispenseSoapSum1 = (0, _reportTablesUtil.totalRows)(dispenseSoapArray, dispenseSoapSum1);
                  dispenseSoapSum2 = (0, _reportTablesUtil.totalRows)(dispenseSoapArray, dispenseSoapSum2);
                }

                if (machine.modifierCycleCounts.extra_soap !== 0) {
                  extraSoapArray.push(machine.modifierCycleCounts.extra_soap);
                  extraSoapSum = (0, _reportTablesUtil.totalRows)(extraSoapArray, extraSoapSum);
                  grandExtraSoapSum = (0, _reportTablesUtil.totalRows)(extraSoapArray, grandExtraSoapSum);
                  extraSoapSum1 = (0, _reportTablesUtil.totalRows)(extraSoapArray, extraSoapSum1);
                  extraSoapSum2 = (0, _reportTablesUtil.totalRows)(extraSoapArray, extraSoapSum2);
                }

                if (machine.modifierCycleCounts.extra_time !== 0) {
                  extraTimeArray.push(machine.modifierCycleCounts.extra_time);
                  extraTimeSum = (0, _reportTablesUtil.totalRows)(extraTimeArray, extraTimeSum);
                  grandExtraTimeSum = (0, _reportTablesUtil.totalRows)(extraTimeArray, grandExtraTimeSum);
                  extraTimeSum1 = (0, _reportTablesUtil.totalRows)(extraTimeArray, extraTimeSum1);
                  extraTimeSum2 = (0, _reportTablesUtil.totalRows)(extraTimeArray, extraTimeSum2);
                }

                if (machine.modifierCycleCounts.heavy_bundle !== 0) {
                  heavyBundleArray.push(machine.modifierCycleCounts.heavy_bundle);
                  heavyBundleSum = (0, _reportTablesUtil.totalRows)(heavyBundleArray, heavyBundleSum);
                  grandHeavyBundleSum = (0, _reportTablesUtil.totalRows)(heavyBundleArray, grandHeavyBundleSum);
                  heavyBundleSum1 = (0, _reportTablesUtil.totalRows)(heavyBundleArray, heavyBundleSum1);
                  heavyBundleSum2 = (0, _reportTablesUtil.totalRows)(heavyBundleArray, heavyBundleSum2);
                }

                if (machine.modifierCycleCounts.mod_1 !== 0) {
                  mod1BundleArray.push(machine.modifierCycleCounts.mod_1);
                  mod1BundleSum = (0, _reportTablesUtil.totalRows)(mod1BundleArray, mod1BundleSum);
                  grandMod1BundleSum = (0, _reportTablesUtil.totalRows)(mod1BundleArray, grandMod1BundleSum);
                  mod1BundleSum1 = (0, _reportTablesUtil.totalRows)(mod1BundleArray, mod1BundleSum1);
                  mod1BundleSum2 = (0, _reportTablesUtil.totalRows)(mod1BundleArray, mod1BundleSum2);
                }

                if (machine.modifierCycleCounts.mod_2 !== 0) {
                  mod2BundleArray.push(machine.modifierCycleCounts.mod_2);
                  mod2BundleSum = (0, _reportTablesUtil.totalRows)(mod2BundleArray, mod2BundleSum);
                  grandMod2BundleSum = (0, _reportTablesUtil.totalRows)(mod2BundleArray, grandMod2BundleSum);
                  mod2BundleSum1 = (0, _reportTablesUtil.totalRows)(mod2BundleArray, mod2BundleSum1);
                  mod2BundleSum2 = (0, _reportTablesUtil.totalRows)(mod2BundleArray, mod2BundleSum2);
                }

                if (machine.modifierCycleCounts.no_heat !== 0) {
                  noHeatArray.push(machine.modifierCycleCounts.no_heat);
                  noHeatSum = (0, _reportTablesUtil.totalRows)(noHeatArray, noHeatSum);
                  grandNoHeatSum = (0, _reportTablesUtil.totalRows)(noHeatArray, grandNoHeatSum);
                  noHeatSum1 = (0, _reportTablesUtil.totalRows)(noHeatArray, noHeatSum1);
                  noHeatSum2 = (0, _reportTablesUtil.totalRows)(noHeatArray, noHeatSum2);
                }

                if (machine.modifierCycleCounts.ozone_wx !== 0) {
                  ozoneWxArray.push(machine.modifierCycleCounts.ozone_wx);
                  ozoneWxSum = (0, _reportTablesUtil.totalRows)(ozoneWxArray, ozoneWxSum);
                  grandOzoneWxSum = (0, _reportTablesUtil.totalRows)(ozoneWxArray, grandOzoneWxSum);
                  ozoneWxSum1 = (0, _reportTablesUtil.totalRows)(ozoneWxArray, ozoneWxSum1);
                  ozoneWxSum2 = (0, _reportTablesUtil.totalRows)(ozoneWxArray, ozoneWxSum2);
                }

                if (machine.modifierCycleCounts.reversing_tum !== 0) {
                  reversingTumArray.push(machine.modifierCycleCounts.reversing_tum);
                  reversingTumSum = (0, _reportTablesUtil.totalRows)(reversingTumArray, reversingTumSum);
                  grandReversingTumSum = (0, _reportTablesUtil.totalRows)(reversingTumArray, grandReversingTumSum);
                  reversingTumSum1 = (0, _reportTablesUtil.totalRows)(reversingTumArray, reversingTumSum1);
                  reversingTumSum2 = (0, _reportTablesUtil.totalRows)(reversingTumArray, reversingTumSum2);
                }

                if (machine.modifierCycleCounts.speed !== 0) {
                  speedArray.push(machine.modifierCycleCounts.speed);
                  speedSum = (0, _reportTablesUtil.totalRows)(speedArray, speedSum);
                  grandSpeedSum = (0, _reportTablesUtil.totalRows)(speedArray, grandSpeedSum);
                  speedSum1 = (0, _reportTablesUtil.totalRows)(speedArray, speedSum1);
                  speedSum2 = (0, _reportTablesUtil.totalRows)(speedArray, speedSum2);
                }

                if (machine.modifierCycleCounts.warm_final_rinse !== 0) {
                  warmFinalRinseArray.push(machine.modifierCycleCounts.warm_final_rinse);
                  warmFinalRinseSum = (0, _reportTablesUtil.totalRows)(warmFinalRinseArray, warmFinalRinseSum);
                  grandWarmFinalRinseSum = (0, _reportTablesUtil.totalRows)(warmFinalRinseArray, grandWarmFinalRinseSum);
                  warmFinalRinseSum1 = (0, _reportTablesUtil.totalRows)(warmFinalRinseArray, warmFinalRinseSum1);
                  warmFinalRinseSum2 = (0, _reportTablesUtil.totalRows)(warmFinalRinseArray, warmFinalRinseSum2);
                }
              }

              if (machine.minFirstReceivedAt && machine.maxFirstReceivedAt) {
                dateRange = `${moment(machine.minFirstReceivedAt).format(_reportTablesUtil.formatDateTimeString.call(this))} - ${moment(machine.maxFirstReceivedAt).format(_reportTablesUtil.formatDateTimeString.call(this))}`;
              }

              machineArray.push({
                name: _reportTablesUtil.machineNameDisplay.call(this, machine.machineName, machine.machineNumber),
                dateRange: dateRange,
                mediumBundle: machine.modifierCycleCounts.medium_bundle,
                temp_15c: machine.modifierCycleCounts.temp_15c,
                temp_40c: machine.modifierCycleCounts.temp_40c,
                temp_50c: machine.modifierCycleCounts.temp_50c,
                temp_30c: machine.modifierCycleCounts.temp_30c,
                temp_90c: machine.modifierCycleCounts.temp_90c,
                warm: machine.modifierCycleCounts.warm,
                temp_60c: machine.modifierCycleCounts.temp_60c,
                increasedSpinSpeed: machine.modifierCycleCounts.increased_spin_speed,
                increasedWaterLevel: machine.modifierCycleCounts.increased_water_level,
                hot: machine.modifierCycleCounts.hot,
                antiWrinkleTum: machine.modifierCycleCounts.antiwrinkle_tum,
                cold: machine.modifierCycleCounts.cold,
                dispenseSoap: machine.modifierCycleCounts.dispense_soap,
                extraSoap: machine.modifierCycleCounts.extra_soap,
                extraTime: machine.modifierCycleCounts.extra_time,
                heavyBundle: machine.modifierCycleCounts.heavy_bundle,
                mod1: machine.modifierCycleCounts.mod_1,
                mod2: machine.modifierCycleCounts.mod_2,
                no_heat: machine.modifierCycleCounts.no_heat,
                ozone_wx: machine.modifierCycleCounts.ozone_wx,
                reversing_tum: machine.modifierCycleCounts.reversing_tum,
                speed: machine.modifierCycleCounts.speed,
                warm_final_rinse: machine.modifierCycleCounts.warm_final_rinse
              });
            });
            totalRoomMediumBundleCount2.push(mediumBundleSum2);
            totalRoomTemp_15cCount2.push(temp_15cSum2);
            totalRoomTemp_40cCount2.push(temp_40cSum2);
            totalRoomTemp_50c2.push(temp_50cSum2);
            totalRoomTemp_30cCount2.push(temp_30cSum2);
            totalRoomTemp_90cCount2.push(temp_90cSum2);
            totalRoomwarmCount2.push(warmSum2);
            totalRoomTemp_60cCount2.push(temp_60cSum2);
            totalRoomIncreasedSpinSpeed2.push(increasedSpinSpeedSum2);
            totalRoomIncreasedWaterLevel2.push(increasedWaterLevelSum2);
            totalRoomHot2.push(hotSum2);
            totalRoomAntiWrinkleTumArray2.push(antiWrinkleTumSum2);
            totalRoomColdArray2.push(coldSum2);
            totalRoomCycleCount12Array2.push(dispenseSoapSum2);
            totalRoomExtraSoapArray2.push(extraSoapSum2);
            totalRoomExtraTimeArray2.push(extraTimeSum2);
            totalRoomHeavyBundleArray2.push(heavyBundleSum2);
            totalRoomMod1BundleArray2.push(mod1BundleSum2);
            totalRoomMod2BundleArray2.push(mod2BundleSum2);
            totalRoomNoHeatArray2.push(noHeatSum2);
            totalRoomOzoneWxArray2.push(ozoneWxSum2);
            totalRoomReversingTumArray2.push(reversingTumSum2);
            totalRoomSpeedArray2.push(speedSum2);
            totalRoomWarmFinalRinseArray2.push(warmFinalRinseSum2);
          });
        });
        totalRoomMediumBundleCount1.push(mediumBundleSum1);
        totalRoomTemp_15cCount1.push(temp_15cSum1);
        totalRoomTemp_40cCount1.push(temp_40cSum1);
        totalRoomTemp_50c1.push(temp_50cSum1);
        totalRoomTemp_30cCount1.push(temp_30cSum1);
        totalRoomTemp_90cCount1.push(temp_90cSum1);
        totalRoomwarmCount1.push(warmSum1);
        totalRoomTemp_60cCount1.push(temp_60cSum1);
        totalRoomIncreasedSpinSpeed1.push(increasedSpinSpeedSum1);
        totalRoomIncreasedWaterLevel1.push(increasedWaterLevelSum1);
        totalRoomHot1.push(hotSum1);
        totalRoomAntiWrinkleTumCount1.push(antiWrinkleTumSum1);
        totalRoomColdCount1.push(coldSum1);
        totalRoomCycleCount12Count1.push(dispenseSoapSum1);
        totalRoomExtraSoapCount1.push(extraSoapSum1);
        totalRoomExtraTimeCount1.push(extraTimeSum1);
        totalRoomHeavyBundleCount1.push(heavyBundleSum1);
        totalRoomMod1BundleCount1.push(mod1BundleSum1);
        totalRoomMod2BundleCount1.push(mod2BundleSum1);
        totalRoomNoHeatCount1.push(noHeatSum1);
        totalRoomOzoneWxCount1.push(ozoneWxSum1);
        totalRoomReversingTumCount1.push(reversingTumSum1);
        totalRoomSpeedCount1.push(speedSum1);
        totalRoomWarmFinalRinseCount1.push(warmFinalRinseSum1);
      }); // totalRoomMediumBundleCount.push(mediumBundleSum);
      // totalRoomTemp_15cCount.push(temp_15cSum);
      // totalRoomTemp_40cCount.push(temp_40cSum);
      // totalRoomTemp_50c.push(temp_50cSum);
      // totalRoomTemp_30cCount.push(temp_30cSum);
      // totalRoomTemp_90cCount.push(temp_90cSum);
      // totalRoomwarmCount.push(warmSum);
      // totalRoomTemp_60cCount.push(temp_60cSum);
      // totalRoomIncreasedSpinSpeed.push(increasedSpinSpeedSum);
      // totalRoomIncreasedWaterLevel.push(increasedWaterLevelSum);
      // totalRoomHot.push(hotSum);
      // totalRoomAntiWrinkleTumCount.push(antiWrinkleTumSum);
      // totalRoomColdCount.push(coldSum);
      // totalRoomCycleCount12Count.push(dispenseSoapSum);
      // totalRoomExtraSoapCount.push(extraSoapSum);
      // totalRoomExtraTimeCount.push(extraTimeSum);
      // totalRoomHeavyBundleCount.push(heavyBundleSum);
      // totalRoomMod1BundleCount.push(mod1BundleSum);
      // totalRoomMod2BundleCount.push(mod2BundleSum);
      // totalRoomNoHeatCount.push(noHeatSum);
      // totalRoomOzoneWxCount.push(ozoneWxSum);
      // totalRoomReversingTumCount.push(reversingTumSum);
      // totalRoomSpeedCount.push(speedSum);
      // totalRoomWarmFinalRinseCount.push(warmFinalRinseSum);
      // });

      totalGrandRoomMediumBundleCount.push(grandMediumBundleSum);
      totalGrandRoomTemp_15cCount.push(grandTemp_15cSum);
      totalGrandRoomTemp_40cCount.push(grandTemp_40cSum);
      totalGrandRoomTemp_50c.push(grandTemp_50cSum);
      totalGrandRoomTemp_30cCount.push(grandTemp_30cSum);
      totalGrandRoomTemp_90cCount.push(grandtemp_90cSum);
      totalGrandRoomwarmCount.push(grandWarmSum);
      totalGrandRoomTemp_60cCount.push(grandTemp_60cSum);
      totalGrandRoomIncreasedSpinSpeed.push(grandincreasedSpinSpeedSum);
      totalGrandRoomIncreasedWaterLevel.push(grandIncreasedWaterLevelSum);
      totalGrandRoomHot.push(grandHotSum);
      totalGrandRoomAntiWrinkleTumCount.push(grandAntiWrinkleTumSum);
      totalGrandRoomColdCount.push(grandColdSum);
      totalGrandRoomCycleCount12Count.push(grandDispenseSoapSum);
      totalGrandRoomExtraSoapCount.push(grandExtraSoapSum);
      totalGrandRoomExtraTimeCount.push(grandExtraTimeSum);
      totalGrandRoomHeavyBundleCount.push(grandHeavyBundleSum);
      totalGrandRoomMod1BundleCount.push(grandMod1BundleSum);
      totalGrandRoomMod2BundleCount.push(grandMod2BundleSum);
      totalGrandRoomNoHeatCount.push(grandNoHeatSum);
      totalGrandRoomOzoneWxCount.push(grandOzoneWxSum);
      totalGrandRoomReversingTumCount.push(grandReversingTumSum);
      totalGrandRoomSpeedCount.push(grandSpeedSum);
      totalGrandRoomWarmFinalRinseCount.push(grandWarmFinalRinseSum);
      return rowData;
    }

    get userTimeZone() {
      return moment.tz.guess();
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "brand", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SummaryReportsSTouchModifiersComponent;
});