define("alliance-business-suite/components/form-ui/select-temp", ["exports", "alliance-business-suite/mixins/form-ui", "alliance-business-suite/utils/fix-range"], function (_exports, _formUi, _fixRange) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_formUi.default, {
    tagName: 'div',
    intl: Ember.inject.service(),
    customValue: null,

    //#region Events
    didReceiveAttrs() {
      this._super(...arguments);

      let val = this.value;
      let selected; // We must have options to continue.

      try {
        selected = this.options.findBy('val', val) || this.options.findBy('isCustom') || null;
      } catch (err) {// We didn't have an Ember object to work with
      }

      if (selected) {
        Ember.set(this, 'selectedOption', selected);
      }
    },

    valueChanged: false,
    //#endregion
    //#region Computed properties
    options: Ember.computed('propertyName', 'params.options', 'formSettings.selectOptions', function () {
      // eslint-disable-line ember/require-computed-property-dependencies
      let options = this.params.options; // What options we're going to display

      let returnOptions;

      if (options && typeof options !== "string") {
        // An object was passed in
        returnOptions = options || {
          val: null,
          label: 'Invalid options'
        };
      } else {
        // Find options and return them.
        if (options) {
          // A string was passed into 'options'
          returnOptions = Ember.get(this, `formSettings.selectOptions.${options}`) || {
            val: null,
            label: 'Data not found.'
          };
        } else {
          // Nothing passed in, so fall back to propertyName
          let propertyName = this.propertyName;
          let key = propertyName.split('.').slice(-1)[0];
          let so = this.formSettings.selectOptions;
          let output = Ember.get(so, key) || {
            val: null,
            label: 'Data not found.'
          };
          returnOptions = output;
        }
      } // Reset the value to fit within the options values, if necessary


      let values = returnOptions.map(option => {
        return option.val;
      });

      if (values.length > 1) {
        let validValue = (0, _fixRange.fixValueToOptions)(returnOptions, this.value);

        if (validValue != this.value) {
          Ember.set(this, 'valueChanged', true);
          this.update(this.object, this.propertyName, validValue);
        }
      }

      return returnOptions;
    }),
    //#endregion
    //#region Actions
    actions: {
      // eslint-disable-line ember/no-actions-hash
      blockBubbles(select, event) {
        if (event.target && event.target.tagName === 'INPUT') {
          event.stopPropagation();
          return false; // This is required to stop the bubbles
        }
      },

      doChange(val) {
        Ember.set(this, 'selectedOption', val);

        if (Ember.get(this, 'params.onChange')) {
          Ember.get(this, 'params.onChange')(val);
        } else {
          // If 'custom' is chosen and sharedCustomProperty is true, we need to set the value to the last preset value.
          let isCustom = val.isCustom;
          let sharedCustomProperty = Ember.get(this, 'sharedCustomProperty');
          let lastPresetValue = Ember.get(this, 'lastPresetValue');
          let value = val.val;

          if (isCustom && sharedCustomProperty) {
            // We need to update the value to be the last presetValue
            Ember.set(this, 'value', lastPresetValue);
          } else if (isCustom) {
            Ember.set(this, 'value', value);
          } else {
            // not custom
            Ember.set(this, 'value', value);
            Ember.set(this, 'lastPresetValue', val.presetValue);
          }
        }
      }

    } //#endregion

  });

  _exports.default = _default;
});