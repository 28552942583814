define("alliance-business-suite/mixins/machines/programs/cycles/cycle-times", ["exports", "alliance-business-suite/utils/cycles-values"], function (_exports, _cyclesValues) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    // eslint-disable-line
    init() {
      this._super();

      this.send('calculateTime');
    },

    defaultMods: [// This must be returned for time calculations. OPL has no mods, so these defaults get used instead.
    {
      name: "Default",
      mod1: false,
      mod2: false,
      additionalWashMinutes: 0,
      additionalRinseMinutes: 0,
      activeStates: ["Enabled", "AlwaysEnabled"] // These should match all possible values for enabled steps

    }],
    machineDrainTime: Ember.computed('machineProgram._machineAuditType', function () {
      switch (true) {
        case this.machineProgram._machineAuditType === "MGD_M7":
          return 10;

        default:
          return 30;
      }
    }),
    actions: {
      calculateTime() {
        /*
         We need to show the time for each Step, with some special conditions to consider. Those
        times get added up to show the total time for each Cycle. We will use an action that gets
        called whenever dependent properties mutate.
         For this to work, a `mod` array is required, which defines additional time that gets added
        for each mod, such as additional wash minutes for heavy loads, etc.
         Loop through each cycle, then loop through each step, then loop through each soil level.
        The times will change depending on which mods are enabled for that soil level. Save the
        total time.
         Example mods we find:
         Medium, Heavy
        Light, Medium, Heavy, SpeedCycle
        NoMod, Mod1, Mod2
         */
        console.time('Calculate times'); // eslint-disable-line no-console

        let mods = this.mods || this.defaultMods; // mods should be defined for each type of machine.

        let previousStepType = -1;
        let cycles = this.machineProgram.settings.Cycles; // TODO: Get the results out of the model

        let times = []; // Loop through each cycle

        cycles.forEach((cycle, cycleIndex) => {
          // Working on a single cycle...loop through Steps now
          let isFirstFill = true; // for (let soilLevel = 0; soilLevel < 3; soilLevel++) {

          for (let soilLevel = 0; soilLevel < mods.length; soilLevel++) {
            // Changed for combined soilLevels for Galaxy+ WX
            // Get the steps, which _may_ be a named array of "Steps", "CycleSteps", or just an unnamed array
            let steps = cycle.Steps || cycle.CycleSteps || cycle; // let totalCycleSeconds = cycle.reduce((cycleSeconds, item) => {

            let totalCycleSeconds = steps.reduce((cycleSeconds, item, stepIndex) => {
              let activeState = Ember.get(item, 'ActiveState');
              let stepType = Ember.get(item, 'Type');
              let label = Ember.get(item, 'Label');
              let minutes = 0; // Skip Supply if it's preceded by a Fill step

              if (stepType === _cyclesValues.STEP_TYPES_ENUM.Supply && previousStepType === _cyclesValues.STEP_TYPES_ENUM.Fill) {
                previousStepType = stepType;
                times.push({
                  cycleIndex,
                  stepIndex,
                  soilLevel,
                  seconds: 0
                });
                return cycleSeconds;
              }

              previousStepType = stepType; // Now we'll want to do this for every Soil Level, 0, 1, 2...
              // First, reset the totalSeconds array

              if (activeState === _cyclesValues.ACTIVE_STATES.enabled || activeState === _cyclesValues.ACTIVE_STATES.mod3 || // TODO: Next line is the problem
              mods[soilLevel].mod1 === true && activeState === _cyclesValues.ACTIVE_STATES.mod1 || mods[soilLevel].mod2 === true && activeState === _cyclesValues.ACTIVE_STATES.mod2) {
                // Check for extra wash time to add
                if (stepType === _cyclesValues.STEP_TYPES_ENUM.Agitate && label === _cyclesValues.STEP_LABELS_ENUM.Wash) {
                  minutes += parseInt(mods[soilLevel].additionalWashMinutes);
                } // Check for extra rinse time to add


                if (stepType === _cyclesValues.STEP_TYPES_ENUM.Agitate && label === _cyclesValues.STEP_LABELS_ENUM.Rinse1) {
                  minutes += parseInt(mods[soilLevel].additionalRinseMinutes);
                } // Now check the time for this step


                let seconds = parseInt(Ember.get(item, 'Seconds')) || 0;
                minutes += parseInt(Ember.get(item, 'Minutes')) || 0; // The soak step and hours and minutes (not mintues and seconds)

                if (Ember.get(item, 'Hours')) {
                  minutes += parseInt(Ember.get(item, 'Hours')) * 60;
                } // Drain takes :30 (was left out in first pass?)


                if (stepType === _cyclesValues.STEP_TYPES_ENUM.Drain) {
                  seconds += this.machineDrainTime;
                }

                let totalSeconds = seconds + minutes * 60; // If Speed Cycle with Disable for Speed Cycle, reset time to 0

                if (activeState === _cyclesValues.ACTIVE_STATES.mod3 && soilLevel == 3) {
                  totalSeconds = 0;
                } // Save the time for this step


                times.push({
                  cycleIndex,
                  stepIndex,
                  soilLevel,
                  seconds: totalSeconds
                });
                return cycleSeconds += totalSeconds;
              } else {
                times.push({
                  cycleIndex,
                  stepIndex,
                  soilLevel,
                  seconds: 0
                });
                return cycleSeconds;
              }
            }, 0); // Save totel seconds for this Cycle

            times.push({
              cycleIndex,
              stepIndex: null,
              soilLevel,
              seconds: totalCycleSeconds
            });
          }
        }); // end of this cycle

        Ember.set(this, "cycleTimes", times);
        console.timeEnd('Calculate times'); // eslint-disable-line no-console
      }

    }
  });

  _exports.default = _default;
});