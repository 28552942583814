define("alliance-business-suite/components/form-ui/coin-drop", ["exports", "alliance-business-suite/mixins/init-property-names"], function (_exports, _initPropertyNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_initPropertyNames.default, {
    // TODO: Should FormFields be in here?!
    coinDropLabel: Ember.computed("machineProgram._machineAuditTypeFamily", function () {
      return this.machineProgram._machineAuditTypeFamily === "MC10_COMBO" ? "coin_options" : "coin_drop";
    }),
    worldDropLabel: Ember.computed("machineProgram._machineAuditTypeFamily", function () {
      return this.machineProgram._machineAuditTypeFamily === "MC10_COMBO" ? "electronic" : "world_drop";
    }),
    coinDrop: Ember.computed('SingleDrop', 'DualDrop', 'WorldDrop', {
      get() {
        let SingleDrop = Ember.get(this, 'SingleDrop');
        let DualDrop = Ember.get(this, 'DualDrop');
        let WorldDrop = Ember.get(this, 'WorldDrop');
        let returnVal = SingleDrop || DualDrop || WorldDrop;
        return returnVal;
      },

      set(key, value) {
        let object = Ember.get(this, 'object');

        if (value === false) {
          Ember.set(this, 'currentDrop', 0);
        } else {
          Ember.set(this, 'currentDrop', 1);
          return true;
        }
      }

    }),
    currentDrop: Ember.computed('coinDrop', {
      get() {
        let single = Ember.get(this, 'SingleDrop');
        let dual = Ember.get(this, 'DualDrop');
        let world = Ember.get(this, 'WorldDrop');
        if (Ember.get(this, 'SingleDrop')) return 1;
        if (Ember.get(this, 'DualDrop')) return 2;
        if (Ember.get(this, 'WorldDrop')) return 3;
        return 0;
      },

      set(key, value) {
        let object = Ember.get(this, 'object');
        Ember.get(this, 'update')(object, Ember.get(this, 'properties.SingleDrop.key'), value === 1);
        Ember.get(this, 'update')(object, Ember.get(this, 'properties.DualDrop.key'), value === 2);
        Ember.get(this, 'update')(object, Ember.get(this, 'properties.WorldDrop.key'), value === 3);

        if (!Ember.isEmpty(this.Enabled)) {
          Ember.get(this, 'update')(object, Ember.get(this, 'properties.Enabled.key'), Boolean(value));
        }

        return value;
      }

    }),
    actions: {
      updateCoinDrop(obj, prop, value) {
        Ember.set(this, 'coinDrop', value);
        this.notifyPropertyChange('coinDrop');
      }

    }
  });

  _exports.default = _default;
});