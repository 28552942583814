define("alliance-business-suite/routes/machines/programs/test", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "alliance-business-suite/routes/_secureRoute"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _secureRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  let MachinesProgramsTestRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = class MachinesProgramsTestRoute extends _secureRoute.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "machines", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor2, this);
      (0, _defineProperty2.default)(this, "allowAccess", 'MACHINES_PROGRAM_MANAGE_PROGRAM');
    }

    // 'view programs in machines',
    beforeModel(transition) {
      super.beforeModel(...arguments); // call the SecureRoutes default beforeModel
      // We have to do a clean fetch because the model is out-of-date.

      return this.store.findRecord('organization', this.access.get('accessOrganization.id'), {
        include: 'machine-programs',
        reload: true
      });
    }

    model() {
      return this.store.peekAll('machineProgram');
    }

    afterModel() {
      super.afterModel(...arguments); // call the SecureRoutes default afterModel

      this.machines.clearFetchCount();
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "machines", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = MachinesProgramsTestRoute;
});