define("alliance-business-suite/mixins/init-elements", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    ifMachine: Ember.computed.alias('params.ifMachine'),
    notMachine: Ember.computed.alias('params.notMachine'),
    el: Ember.computed.alias('params.elements'),

    initPropertyName(propertyName) {
      // TODO: This whole thing needs to be DRY and moved somewhere else.
      let index = Ember.get(this, 'params.index');

      if (Ember.isEmpty(index)) {
        index = Ember.get(this, 'index');
      }

      if (!Ember.isNone(index)) {
        propertyName = propertyName.replace('${index}', index); // Replace the index placeholder if this is an array
      }

      if (propertyName.indexOf('${index}')) {
        debugger; // eslint-disable-line
      }

      return propertyName;
    },

    init() {
      this._super(...arguments); // Create computed properties from each item in the element object that contains a `property`


      let elements = Ember.get(this, 'params.elements');

      if (!Ember.isEmpty(elements)) {
        Ember.set(this, 'propertyNames', {});
        let index = Ember.get(this, 'params.index');

        if (Ember.isEmpty(index)) {
          index = Ember.get(this, 'index');
        } // Loop through each property to create new CPs


        Object.keys(elements).forEach(key => {
          let property = Ember.get(this, `params.elements.${key}.key`); // property is a string pointing to the key
          // TODO: Fix this. We use propertyName elsewhere

          if (Ember.isEmpty(property)) {
            property = Ember.get(this, `params.elements.${key}.propertyName`);
          }

          if (!Ember.isEmpty(property)) {
            if (index !== undefined) {
              property = property.replace('${index}', index); // Replace the index placeholder if this is an array
            }

            Ember.set(this, `propertyNames.${key}`, property);
            Ember.defineProperty(this, key, Ember.computed.reads(`object.${property}`));
          }
        });
      }
    }

  });

  _exports.default = _default;
});