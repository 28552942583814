define("alliance-business-suite/utils/total-step-time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.totalStepTime = _exports.default = void 0;

  const totalStepTime = (step, returnAsObject) => {
    let totalMinutes = 0;
    let totalSeconds = 0; // instead of repeating this whole thing 3 times:
    // if (step.AgitateStepOn) {
    //   if (step.AgitateStepMinutes) {
    //     totalMinutes += step.AgitateStepMinutes;
    //   }
    //   if (step.AgitateStepSeconds) {
    //     totalSeconds += step.AgitateStepSeconds;
    //   }
    // }
    // We will just do this:

    let handleSubStep = subStep => {
      if (step[`${subStep}StepOn`]) {
        if (step[`${subStep}StepMinutes`]) {
          totalMinutes += step[`${subStep}StepMinutes`];
        }

        if (step[`${subStep}StepSeconds`]) {
          totalSeconds += step[`${subStep}StepSeconds`];
        }
      }
    };

    if (step.FillStepOn) {
      totalMinutes += 1;
    }

    ['Supply', 'Agitate'].forEach(handleSubStep);

    if (step.DrainStepOn) {
      // Figure out... drain time.. or something.. code copied over from native app..

      /*
      Missing these variables:
      bool usesSupplyTime
      ConstantsEnumsMethods.MotorDriveType paraMotorDriveType
      ALS.CommonDatastream.Common.Product_Generation paraGeneration
      ConstantsEnumsMethods.MotorDriveType.MotorDriveType0
      ConstantsEnumsMethods.MotorDriveType.MotorDriveType2
      ALS.Centurion.MachineControls.Centurion_WxSegment.ExtractSpeed.Medium
      ALS.CommonDatastream.Common.Product_Generation.Centurion_UW_Redesign
      
      /// <param name="usesSupplyTime">True for cabinet 125 and all pocket machines.</param>
      /// <param name="paraGeneration">Product byte 1</param>
      /// <param name="paraMotorDriveType">Motor Drive Type. from Machine Config #2</param>
      
      //Determine motor drive type
      if (CommonDatastream.Common.IsCenturion_106UWRedesign((CommonDatastream.Common.AuditType)_bizPreset.AuditTypeId)) {
        return ConstantsEnumsMethods.MotorDriveType.MotorDriveType0;
      } else if (CommonDatastream.Common.IsCenturion_103ALSDrive((CommonDatastream.Common.AuditType)_bizPreset.AuditTypeId)) {
        return ConstantsEnumsMethods.MotorDriveType.MotorDriveType2;
      } else if (CommonDatastream.Common.IsCenturion_101((CommonDatastream.Common.AuditType)_bizPreset.AuditTypeId)) {
        return ConstantsEnumsMethods.MotorDriveType.MotorDriveType0;
      } else {
        return ConstantsEnumsMethods.MotorDriveType.MotorDriveType1;
      }
      */
      // if (step.ExtractStepOn
      //     && (paraMotorDriveType === ConstantsEnumsMethods.MotorDriveType.MotorDriveType0)) {
      //   if (step.ExtractStepSpeed < ALS.Centurion.MachineControls.Centurion_WxSegment.ExtractSpeed.Medium
      //       && paraGeneration === ALS.CommonDatastream.Common.Product_Generation.Centurion_UW_Redesign) {
      //     totalMinutes += 1;        // redesign
      //   } else {
      //     totalMinutes += 1;
      //     totalSeconds += 45;
      //   }
      // } else if (step.ExtractStepOn
      //   && (paraMotorDriveType === ConstantsEnumsMethods.MotorDriveType.MotorDriveType2)) {
      //   if (step.ExtractStepSpeed < ALS.Centurion.MachineControls.Centurion_WxSegment.ExtractSpeed.Medium) {
      //     totalMinutes += 1;
      //   } else {
      //     totalMinutes += 1;
      //     totalSeconds += 10;
      //   }
      // } else {
      //   totalMinutes += 1;    // 2 speed.
      // }
      // no matter what we COULD add at least 1 min from above if-branch..
      // but we are sort of covering up the issue of not really knowing, so not going to do this now.
      // totalMinutes += 1;
      handleSubStep('Extract');
    }

    totalMinutes += Math.floor(totalSeconds / 60);
    totalSeconds = totalSeconds % 60;

    if (returnAsObject === 'asObject') {
      return {
        minutes: totalMinutes,
        seconds: totalSeconds
      };
    } else {
      totalSeconds = String(totalSeconds);

      if (totalSeconds.length === 1) {
        totalSeconds = `0${totalSeconds}`;
      }

      return `${totalMinutes}:${totalSeconds}`;
    }
  };

  _exports.totalStepTime = totalStepTime;
  var _default = totalStepTime;
  _exports.default = _default;
});