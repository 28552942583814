define("alliance-business-suite/components/ui/modal-wa-advanced", ["exports", "alliance-business-suite/config/environment", "ember-cp-validations"], function (_exports, _environment, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // List of themes to display in the dropdown. Can restrict to specific orgs by adding an array of `orgId`.
  const THEME_OPTIONS = [{
    name: 'theme_1',
    location: 'theme1'
  }, {
    name: 'theme_2',
    location: 'theme2'
  }, {
    name: 'theme_3',
    location: 'theme3'
  }, {
    name: 'Automatic',
    location: 'automatic',
    orgId: [4052, 623]
  }];
  const Validations = (0, _emberCpValidations.buildValidations)({
    'room.serviceEmail': [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('format', {
      type: 'email'
    })]
  });

  var _default = Ember.Component.extend(Validations, {
    ajax: Ember.inject.service(),
    access: Ember.inject.service(),
    brand: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    uploadFile: null,
    acceptedFileTypes: ['png', 'jpeg'],

    didInsertElement() {
      this._super(...arguments);
    },

    themes: Ember.computed(function () {
      return THEME_OPTIONS.filter(theme => {
        if (theme.orgId) {
          const orgId = Number(Ember.get(this, 'access.accessOrganization.id'));
          return theme.orgId.includes(orgId);
        } else {
          return true;
        }
      });
    }),
    selectedTheme: Ember.computed('room.washAlertTheme', function () {
      let theme = this.get('room.washAlertTheme');
      let selectedTheme = THEME_OPTIONS[0];

      if (theme) {
        THEME_OPTIONS.forEach(themeOption => {
          if (themeOption.location === theme) {
            selectedTheme = themeOption;
          }
        });
      }

      return selectedTheme;
    }),
    orgHasMultipleRooms: Ember.computed(function () {
      let {
        store
      } = this;
      let rooms = store.peekAll('room');

      if (rooms.get('length') > 1) {
        return true;
      }
    }).readOnly(),
    previewUrl: Ember.computed('logoUrl', function () {
      return this.logoUrl;
    }),
    publicSiteUrl: Ember.computed(function () {
      return `https://wa.${this.brand.topLevelDomain}.com/${this.organizationId}?room=${this.room.get('id')}`;
    }),
    directoryUrl: Ember.computed(function () {
      return `https://wa.${this.brand.topLevelDomain}.com/directory/${this.room.get('id')}`;
    }),
    editDisabled: Ember.computed('model', function () {
      let canUpdate = this['can-access'].canAccess('LOCATIONS_WASH_ALERT_ADS_VIEW');

      if (this.access.isDemo) {
        return true;
      }

      if (!canUpdate) {
        return true;
      }
    }).readOnly(),

    async uploadImage(file) {
      let _this = this;

      return await this.ajax.request(`${_environment.default.APP.ALLIANCE_API_URL}/rooms/assetUrl?fileName=${file.get('blob.name')}`, {
        method: 'GET'
      }).then(function (response) {
        _this.set('customLogoKey', `rooms/${response.fileName}`); // needs to use reponse url


        return file.uploadBinary(response.signedUrl, {
          method: 'PUT'
        });
      });
    },

    async updateSettings() {
      let _this = this;

      let roomId = this.room.get('id');
      let canSms = this.room.get('canSms');
      let hasWashAlert = this.room.get('hasWashAlert');
      let serviceEmail = this.room.get('serviceEmail');
      let file = this.file;
      Ember.set(this, 'isLoading', true);

      if (file) {
        await this.uploadImage(file);
      }

      this.ajax.request(`/rooms/${roomId}`, {
        method: 'PATCH',
        data: {
          id: roomId,
          canSms,
          serviceEmail,
          hasWashAlert,
          customLogoKey: this.customLogoKey,
          raw: true
        }
      }).then(function (results) {
        Ember.set(_this, 'isLoading', false);

        _this.get('store').peekRecord('room', _this.get('room.id')).save();

        Ember.set(_this, 'logoUrl', results.logoUrl);

        _this.changeTheme(_this.get('room.washAlertTheme'));

        _this.reloadMachines();

        _this.close();
      }, function () {
        Ember.set(_this, 'isLoading', false);
        Ember.get(_this, 'notifications').error(_this.get('intl').t('save_failed'));
      });
    },

    actions: {
      close() {
        if (!this.isLoading) {
          // Revert changes
          let room = this.store.peekRecord('room', this.get('room.id'));
          room.rollbackAttributes();
          this.close();
        }
      },

      changeTheme(theme) {
        if (this.access.isDemo) {
          alert('Demo mode restricted.');
          return;
        }

        if (confirm(this.intl.t('wa_advanced_theme_confirm'))) {
          this.set('room.washAlertTheme', theme.location);
        }
      },

      uploadPreview(file) {
        if (this.access.isDemo) {
          alert('Demo mode restricted.');
          return;
        }

        if (this.acceptedFileTypes.indexOf(file.extension) === -1) {
          alert(`${this.intl.t('only_file_types')} ${this.acceptedFileTypes.join(', ')}`);
          return false;
        }

        let blobUrl = window.URL.createObjectURL(file.blob);

        if (blobUrl) {
          this.set('file', file);
          this.set('previewUrl', blobUrl);
        }
      },

      deleteLogo() {
        if (this.access.isDemo) {
          alert('Demo mode restricted.');
          return;
        }

        this.set('file', null);
        this.set('previewUrl', null); // this.set('logoUrl', null);

        this.set('customLogoKey', '');
      },

      toggleHasWashAlert() {
        if (this.access.isDemo) {
          alert('Demo mode restricted.');
          return;
        }

        this.toggleProperty('room.hasWashAlert');
      },

      toggleCanSms() {
        if (this.access.isDemo) {
          alert('Demo mode restricted.');
          return;
        }

        this.toggleProperty('room.canSms');
      },

      toggleShowRoomName() {
        if (this.access.isDemo) {
          alert('Demo mode restricted.');
          return;
        }

        this.toggleProperty('room.hasWashAlertRoomName');
      },

      toggleShowClock() {
        if (this.access.isDemo) {
          alert('Demo mode restricted.');
          return;
        }

        this.toggleProperty('room.hasWashAlertClock');
      },

      toggleShowLegend() {
        if (this.access.isDemo) {
          alert('Demo mode restricted.');
          return;
        }

        this.toggleProperty('room.hasWashAlertLegend');
      },

      toggleAutoResize() {
        if (this.access.isDemo) {
          alert('Demo mode restricted.');
          return;
        }

        this.toggleProperty('room.washAlertCanvasResize');
      },

      transitionToAdRoll() {
        if (this.isModified) {
          if (confirm(this.intl.t('unsaved_changes_wash_alert_confirm_exit'))) {
            this.router.transitionTo('locations.wash-alert.ads', this.get('room.id'));
          }
        } else {
          this.router.transitionTo('locations.wash-alert.ads', this.get('room.id'));
        }
      },

      updateSettings() {
        if (this.access.isDemo) {
          alert('Demo mode restricted.');
          return;
        }

        this.updateSettings();
      }

    }
  });

  _exports.default = _default;
});