define("alliance-business-suite/components/summary/reports/s-refunds", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "alliance-business-suite/utils/currency-format-util", "alliance-business-suite/utils/report-tables-util"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _currencyFormatUtil, _reportTablesUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  let SummaryReportsSRefundsComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, (_class = class SummaryReportsSRefundsComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor3, this);
    }

    get translatedColumns() {
      return [{
        name: 'Name',
        valuePath: 'name',
        width: 180,
        isFixed: 'left'
      }, {
        name: 'refund_amount',
        valuePath: 'refundAmount',
        width: 100
      }, {
        name: 'refunded_transaction',
        valuePath: 'refundedTransaction',
        width: 180
      }, {
        name: 'refund_reason',
        valuePath: 'refundReasonLookupKey',
        width: 180
      }, {
        name: 'refund_transaction_date',
        valuePath: 'refundTransactionDate',
        width: 180
      }, {
        name: 'refunded_by_user',
        valuePath: 'refundedByUser',
        width: 180
      }];
    }

    get rowsWithChildren() {
      let {
        model
      } = this.args;
      let geoBoundaries = model.geoBoundaries;
      let rowData = [];
      geoBoundaries.forEach(geoBoundary => {
        let monthArray = [];
        let nameArray = [];
        rowData.push({
          name: _reportTablesUtil.localizeString.call(this, geoBoundary.name, 'Location', 'location'),
          children: nameArray
        });
        geoBoundary.rooms.forEach(room => {
          nameArray.push({
            name: room.name,
            children: monthArray
          });
          room.months.forEach(month => {
            let weekArray = [];
            monthArray.push({
              name: month.name,
              children: weekArray
            });
            month.weeks.forEach(week => {
              let dayArray = [];
              weekArray.push({
                name: week.name,
                children: dayArray
              });
              week.days.forEach(day => {
                let stationArray = [];
                dayArray.push({
                  name: day.name,
                  children: stationArray
                });
                day.refunds.forEach(refund => {
                  let refundValue = _currencyFormatUtil.formatCurrencyString.call(this, refund.refundAmount || 0);

                  let refundedUser = refund.refundedByUser || 'N/A';
                  let refundReasonLookupKey = this.intl.t(refund.refundReasonLookupKey) || 'N/A';
                  let refundTransaction = JSON.parse(refund.refundedTransaction).map(obj => obj.isLookup ? this.intl.t(obj.text.toLowerCase()) : obj.text).reduce((cur, prev) => `${cur} ${prev}`, '');
                  let refundTransactionDate = "";

                  if (refund.refundTransactionDate) {
                    refundTransactionDate = moment(refund.refundTransactionDate).format(`${_reportTablesUtil.formatDateTimeString.call(this)}`);
                  }

                  stationArray.push({
                    name: refund.userName,
                    refundAmount: refundValue,
                    refundedTransaction: refundTransaction,
                    refundReasonLookupKey: refundReasonLookupKey,
                    refundTransactionDate: refundTransactionDate,
                    refundedByUser: refundedUser
                  });
                });
              });
            });
          });
        });
      });
      return rowData;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SummaryReportsSRefundsComponent;
});