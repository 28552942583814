define("alliance-business-suite/components/customers/orders/order-list-item", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'tr',
    access: Ember.inject.service(),
    brush: Ember.inject.service(),
    progressPercent: Ember.computed('order', function () {
      let status = Ember.get(this, 'order.status.lookupKey');

      switch (status) {
        case 'CUSTOMER_DROP_OFF':
          return '33%';

        case 'SERVICING':
          return '50%';

        case 'READY_FOR_PICKUP':
          return '75%';

        case 'CUSTOMER_PICKUP':
          return '100%';

        default:
          return '0%';
      }
    }),
    actions: {
      viewOrderDetails() {
        this.brush.transitionTo('order', {
          type: 'order',
          id: `${this.get('order.orderGuid')}`
        }, 'show');
      }

    }
  });

  _exports.default = _default;
});