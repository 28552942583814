define("alliance-business-suite/serializers/application", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    JSONAPISerializer
  } = _emberData.default;

  var _default = JSONAPISerializer.extend({
    /**
     * Whether `includes` from the JSON API response should be pushed to the
     * store. Defaults to true.
     *
     * @type {boolean}
     * @protected
     */
    allowIncludes: true,

    /**
     * Defines how to convert an attribute name in a model to a key the JSON API.
     *
     * @param {string} attr The name of the attribute in the model
     *
     * @return {string} The name of the attribute in the JSON API
     * @protected
     */
    keyForAttribute(attr) {
      return Ember.String.camelize(attr);
    },

    /**
     * Defines how to convert a relationship name in a model to a key the JSON
     * API.
     *
     * @param {string} attr The name of the relationship in the model
     *
     * @return {string} The name of the relationship in the JSON API
     * @protected
     */
    keyForRelationship(attr) {
      return Ember.String.camelize(attr);
    },

    /**
     * Normalizes a response payload from the API before pushing it to the store.
     *
     * @param {DS.Store} store             The store this response is for
     * @param {DS.Model} primaryModelClass The root model being retrieved
     * @param {object}   payload           The JSON API payload to normalize
     *
     * @returns {object} A JSON API document
     * @protected
     */
    normalizeResponse(store, primaryModelClass, payload
    /* , id, requestType */
    ) {
      // console.log('-------')
      // console.log('store', store);
      // console.log('payload', payload);
      if (payload == null) {
        // eslint-disable-next-line
        console.warn('payload is null or undefined, setting to empty object..');
        payload = {};
      } // HACK: `included` must be an array according to the JSON API spec, so the
      //       second half of this should be redundant; but sometimes we're
      //       getting `""` back, which generates errors. This fixes the issue.
      //       [twl 4.May.17]


      if (payload.included) {
        if (!Ember.get(this, 'allowIncludes' || !Array.isArray(payload.included))) {
          delete payload.included;
        } else if (this.normalizeInclude) {
          payload.included = payload.included.map(e => this.normalizeInclude(e)).compact();
        }
      } // HACK: Fix if the API returns the wrong format for `links`.
      //       [twl 19.Jun.17]


      if (payload.links === '') {
        delete payload.links;
      }

      if (this.normalizeDataHack) {
        if (Array.isArray(payload.data)) {
          payload.data.forEach(e => {
            this.normalizeDataHack(e);
          });
        } else if (payload.data != null) {
          this.normalizeDataHack(payload.data);
        }
      }

      return this._super(...arguments);
    },

    /**
     * Converts the data for the `type` relationship to a link relationship
     * instead of including the data in the response payload. This causes Ember
     * Data to load the data from the `related` endpoint.
     *
     * @param {object} data             A JSON API data object that includes `id`
     *                                  and `type` properties
     * @param {string} relationship     The name of the relationship
     * @param {string} relationshipPath The path underneath the data path used to
     *                                  retrieve the relationship records.
     *                                  Defaults to the `relationship` parameter.
     *
     * @public
     */
    toLinkRelationship(data, relationship, relationshipPath) {
      data.relationships = data.relationships || {};
      delete data.relationships[relationship];
      data.relationships[relationship] = {
        links: {
          related: `/${data.type}/${data.id}/${relationshipPath || relationship}`
        }
      };
    },

    /**
     * Converts an attribute that contains relationship data into an actual
     * relationship data definition. This causes Ember to load the data from the
     * store using the `id` of the relationship; if the data does not exist in
     * the store, it will load from the default endpoint for that `type`.
     *
     * NOTE: This currently does not handle array attributes.
     *
     * @param {object} data         A JSON API data object that includes `id` and
     *                              `type` properties
     * @param {string} relationship The name of the relationship
     * @param {string} type         The model type used for this relationship.
     *                              Defaults to the `relationship` parameter.
     * @public
     */
    convertAttributeToRelationship(data, relationship, type) {
      let id = Ember.get(data, `attributes.${relationship}.id`);

      if (id != null) {
        data.relationships = data.relationships || {};
        data.relationships[relationship] = {
          data: {
            id,
            type: type || relationship
          }
        };
      }

      if (data.attributes) {
        delete data.attributes[relationship];
      }
    }

  });

  _exports.default = _default;
});