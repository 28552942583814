define("alliance-business-suite/services/brand-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.lookupTable = _exports.brandConfig = void 0;
  // Note: There are some images stored in AWS that need to be added: https://s3.amazonaws.com/alliancelabs-assets-production/web-payment/stripe-${this.brand.slug}.png

  /*
    {
      id: "PRIMUS", - Used to determine the brand used by the application
      name: This should match the allianceBrand in the config.json file.
      slug: "primus", - the slug is used in translation keys, image paths, CSS class names, etc.
      abbrev: "pr", - used in templates and components for setting values for specific brands
      isBrandKeys - keys used to determine the current brand with `if` statements
    }
  */
  const brandConfig = [{
    id: "SPEED_QUEEN",
    name: "SPEED_QUEEN",
    slug: "sq",
    abbrev: "sq",
    isBrandKeys: ['sq', 'speed_queen', 'speed-queen', 'speed queen', 'speedqueen']
  }, {
    id: "HUEBSCH",
    name: "HUEBSCH",
    slug: "huebsch",
    abbrev: "hb",
    isBrandKeys: ['hb', 'huebsch', 'hu']
  }, {
    id: "PRIMUS",
    name: "PRIMUS",
    slug: "primus",
    abbrev: "pr",
    isBrandKeys: ['pr', 'primus']
  }, {
    id: "IPSO",
    name: "IPSO",
    slug: "ipso",
    abbrev: "ip",
    isBrandKeys: ['ip', 'ipso']
  }, {
    id: "UNIMAC",
    name: "UNIMAC",
    slug: "unimac",
    abbrev: "un",
    isBrandKeys: ['un', 'unimac']
  }];
  _exports.brandConfig = brandConfig;
  const lookupTable = {
    'brand-image-path': {
      sq: "/assets/images/branding/sq/REPLACE_STRING",
      hb: "/assets/images/branding/huebsch/REPLACE_STRING",
      pr: "/assets/images/branding/primus/REPLACE_STRING",
      ip: "/assets/images/branding/ipso/REPLACE_STRING",
      un: "/assets/images/branding/unimac/REPLACE_STRING"
    },
    'brand-active-color': {
      sq: "#6fff82",
      hb: "#cbff46",
      pr: "#00aeef",
      ip: "#F15D22",
      un: "#ee1c24"
    },
    'brand-loader-color': {
      // Not the same as the active color.
      sq: "#F24822",
      hb: "#6FB315",
      pr: "#00aeef",
      ip: "#F15D22",
      un: "#ee1c24"
    },
    'favicon': {
      sq: "/speed_queen.ico",
      hb: "/huebsch.ico",
      pr: "/primus.ico",
      ip: "/ipso.ico",
      un: "/unimac.png"
    },
    'help-request-href': {
      sq: "https://sqinsights.zendesk.com/hc/en-us/requests/new",
      hb: "https://huebschcommand.zendesk.com/hc/en-us/requests/new",
      pr: "",
      ip: "",
      un: ""
    },
    'terms-of-service-href': {
      sq: "https://alliancelabs-assets-production.s3.us-east-1.amazonaws.com/portal_tos/sq/SQ_ServiceAgreement_04012021.html",
      hb: "https://alliancelabs-assets-production.s3.us-east-1.amazonaws.com/portal_tos/hu/Hue_ServiceAgreement_04012021.html",
      pr: "https://alliancelabs-assets-production.s3.us-east-1.amazonaws.com/portal_tos/pr/Primus_ServiceAgreement_04012021.html",
      ip: "https://alliancelabs-assets-production.s3.us-east-1.amazonaws.com/portal_tos/ip/IPSO_ServiceAgreement_04012021.html",
      un: "https://alliancelabs-assets-production.s3.us-east-1.amazonaws.com/portal_tos/un/UN_ServiceAgreement_04012021.html"
    },
    'privacy-policy-href': {
      sq: "https://alliancelabs-assets-production.s3.us-east-1.amazonaws.com/portal_tos/sq/SQ_ServiceAgreement_04012021.html#privacy",
      hb: "https://alliancelabs-assets-production.s3.us-east-1.amazonaws.com/portal_tos/hu/Hue_ServiceAgreement_04012021.html#privacy",
      pr: "https://alliancelabs-assets-production.s3.us-east-1.amazonaws.com/portal_tos/pr/Primus_ServiceAgreement_04012021.html#privacy",
      ip: "https://alliancelabs-assets-production.s3.us-east-1.amazonaws.com/portal_tos/ip/IPSO_ServiceAgreement_04012021.html#privacy",
      un: "https://alliancelabs-assets-production.s3.us-east-1.amazonaws.com/portal_tos/un/UN_ServiceAgreement_04012021.html#privacy"
    },
    'brand-slug': {
      // used in urls, etc.
      sq: "sq",
      hb: "huebsch",
      pr: "primus",
      ip: "ipso",
      un: "unimac"
    },
    'domain': {
      sq: "huebschcommand.com",
      hb: "sqinsights.com",
      pr: "primusitrace.com",
      ip: "ipsoconnect.com",
      un: "unimaccore.com"
    },
    'billing-domain': {
      sq: "sqinsights",
      hb: "huebschcommand",
      pr: "",
      ip: "",
      un: ""
    },
    'payment-logo': {
      sq: "https://s3.amazonaws.com/alliancelabs-assets-production/web-payment/stripe-sq.png",
      hb: "https://s3.amazonaws.com/alliancelabs-assets-production/web-payment/stripe-huebsch.png",
      pr: "https://s3.amazonaws.com/alliancelabs-assets-production/web-payment/stripe-primus.png",
      ip: "https://s3.amazonaws.com/alliancelabs-assets-production/web-payment/stripe-ipso.png",
      un: "https://s3.amazonaws.com/alliancelabs-assets-production/web-payment/stripe-unimac.png"
    },
    'burger-menu-icon': {
      sq: "/assets/images/branding/sq/menu-logo.svg",
      hb: "/assets/images/branding/huebsch/menu-logo.svg",
      pr: "/assets/images/branding/primus/menu-logo.svg",
      ip: "/assets/images/branding/ipso/menu-logo.svg",
      un: "/assets/images/branding/unimac/menu-logo.svg"
    }
  };
  _exports.lookupTable = lookupTable;
});