define("alliance-business-suite/routes/summary/alerts/create", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "alliance-business-suite/routes/_secureRoute"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _secureRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  let SummaryAlertsCreateRoute = (_dec = Ember.inject.service, (_class = class SummaryAlertsCreateRoute extends _secureRoute.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _defineProperty2.default)(this, "allowAccess", 'SUMMARY_ALERTS_CREATE_NEW_ALERT');
    }

    async model() {
      return {
        rooms: this.store.peekAll('room'),
        subscriptionTemplates: this.store.peekAll('subscriptionTemplate')
      };
    }

    setupController(controller, model) {
      super.setupController(controller, model);
      controller.machineErrorSubTemplates = model.subscriptionTemplates; // is these used?

      controller.machineAuditErrorSubTemplates = model.machineAuditErrorSubTemplates;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SummaryAlertsCreateRoute;
});