define("alliance-business-suite/helpers/multiply", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.multiply = multiply;

  function multiply(params) {
    return params.reduce((total, amount) => total * amount);
  }

  var _default = Ember.Helper.helper(multiply);

  _exports.default = _default;
});