define("alliance-business-suite/routes/crm/campaigns", ["exports", "@babel/runtime/helpers/esm/defineProperty", "alliance-business-suite/routes/_secureRoute"], function (_exports, _defineProperty2, _secureRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CrmCampaignsRoute extends _secureRoute.default {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "allowAccess", 'CRM_CAMPAIGNS_VIEW_PAGE');
      (0, _defineProperty2.default)(this, "disableBrush", true);
      (0, _defineProperty2.default)(this, "hideBrush", true);
    }

  }

  _exports.default = CrmCampaignsRoute;
});