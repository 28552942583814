define("alliance-business-suite/components/customers/c-list-table", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17;

  let CustomersCListTableComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember._tracked, _dec18 = Ember._action, _dec19 = Ember._action, _dec20 = Ember._action, _dec21 = Ember._action, _dec22 = Ember._action, _dec23 = Ember._action, (_class = class CustomersCListTableComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "ajax", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "algolia", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "brush", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "cache", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "selectedFundsRoot", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "selectedSearchType", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "query", _descriptor10, this);
      (0, _initializerDefineProperty2.default)(this, "results", _descriptor11, this);
      (0, _initializerDefineProperty2.default)(this, "bindInstance", _descriptor12, this);
      (0, _initializerDefineProperty2.default)(this, "height", _descriptor13, this);
      (0, _initializerDefineProperty2.default)(this, "isLoading", _descriptor14, this);
      (0, _initializerDefineProperty2.default)(this, "isLoadingSmall", _descriptor15, this);
      (0, _initializerDefineProperty2.default)(this, "includeRemovedCCN", _descriptor16, this);
      (0, _initializerDefineProperty2.default)(this, "tableHeader", _descriptor17, this);
      (0, _defineProperty2.default)(this, "customerTableHeader", [{
        name: "firstName",
        valuePath: "firstName",
        width: 180
      }, {
        name: "lastName",
        valuePath: "lastName",
        width: 180
      }, {
        name: "email",
        valuePath: "emailAddresses.firstObject.emailAddress",
        width: 275
      }]);
      (0, _defineProperty2.default)(this, "ccHeader", [{
        name: "firstName",
        valuePath: "firstName",
        width: 140
      }, {
        name: "lastName",
        valuePath: "lastName",
        width: 140
      }, {
        name: "email",
        valuePath: "email",
        width: 200
      }, {
        name: "type",
        valuePath: "type"
      }, {
        name: "expMonth",
        valuePath: "expMonth",
        width: 50
      }, {
        name: "expYear",
        valuePath: "expYear",
        width: 50
      }]);
    }

    get fundsRoot() {
      return this.store.peekAll("geo-boundary").filter(gb => {
        return gb.fundsRestriction;
      });
    }

    get searchOptions() {
      let options = [];
      let _fundsRoot = [...this.fundsRoot];

      _fundsRoot.sort((a, b) => {
        let nameA = a.description.toLowerCase();
        let nameB = b.description.toLowerCase();

        if (nameA < nameB) {
          return -1;
        }

        if (nameA > nameB) {
          return 1;
        }

        return 0;
      });

      _fundsRoot.forEach(fr => {
        options.push({
          type: "funds-root-search",
          data: fr,
          text: fr.description
        });
      });

      options.push({
        type: "cc-search",
        text: Ember.String.capitalize(this.intl.t("search")) + " last 4 digits of card"
      });
      return options;
    }

    updateSelectedSearchType(val) {
      this.isLoadingSmall = false;
      this.selectedSearchType = val;

      if (val.type == "funds-root-search") {
        this.tableHeader = "customerTableHeader";
        this.changeSelectedFundsRoot(val.data);
        setTimeout(() => {
          document.getElementById("customerAlgoliaSearch").focus();
        }, 300);
      } else {
        this.tableHeader = "ccHeader";
        this.results = {
          type: "raw",
          data: []
        };
        setTimeout(() => {
          document.getElementById("customerLastFourSearch").focus();
        }, 300);
      }
    } // management doesn't like our names appearing, so we are removing them from results on the front end


    get resultsFiltered() {
      let results = this.results;
      let final = []; // let removeEmployees = ['paluzzi', 'bibo', 'rautine', '+apps', 'phoenius', 'prats', 'patrogizmo', 'patrick.teague', 'blaey', 'sbfujii', 'Devinrhode2'];

      if (!results) {
        return [];
      }

      if (this.results.type == "algolia") {
        // unoptimized loop
        results.data.hits.forEach(r => {
          if (!r.emailAddresses) {
            return;
          }

          let passesEmployeeFilter = true; // let email = r.emailAddresses[0].emailAddress.toLowerCase();
          // removeEmployees.forEach((e) => {
          //   let strIdx = email.indexOf(e);
          //   if (strIdx != -1) {
          //     passesEmployeeFilter = false;
          //   }
          // });

          if (passesEmployeeFilter) {
            final.push(r);
          }
        });
      } else {
        return this.results.data;
      }

      return final;
    }

    searchCustomer(query) {
      const hitsLimit = query.hitsLimit ? query.hitsLimit : 20;
      let fundsRoot = this.selectedFundsRoot.get("description");

      if (this.access.isDemo) {
        console.log("demo mode");
        fundsRoot = "Speed Queen Little York";
      }

      if (!this.isDestroyed) {
        this.isLoadingSmall = true;
        this.algolia.searchNew(["user"], query.query, hitsLimit, [{
          name: "organization.geoBoundaries.geoBoundaryName",
          value: fundsRoot
        }]).then(algoliaResult => {
          let final = {};
          let nbHits = 0;
          let nbPages = 0;
          let hits = [];
          algoliaResult.results.forEach(result => {
            hits = hits.concat(result.hits);
            nbHits += result.nbHits;
            nbPages += result.nbPages;
            final[result.index] = {
              hits: result.hits,
              total: result.nbHits,
              pages: result.nbPages,
              page: result.page
            };
          });
          this.results = {
            type: "algolia",
            data: final.user
          };
          this.isLoading = false;
          this.isLoadingSmall = false;
        });
      }
    }

    initialLoad() {
      const blankQuery = "";
      this.selectedFundsRoot = this.fundsRoot[0]; // set the first option in the dropdown

      Ember.run.debounce(this, "searchCustomer", {
        blankQuery,
        hitsLimit: 20
      }, 200); // get initial search results

      this.createDynamicHeightContainer(); // we want a dynamic height so we can leverage occlusion for performance as large results can slow down DOM

      this.selectedSearchType = this.searchOptions[0];
      setTimeout(() => {
        document.getElementById("customerAlgoliaSearch").focus();
      }, 300);
    }

    willDestroyCustomerTable() {
      window.removeEventListener("resize", this.bindInstance);
    }

    sendToBrush(data) {
      let id = data.id ? data.id : data.get("firstObject.id"); // first type is for "raw", second for "algolia"

      this.brush.transitionTo("customer", {
        id,
        type: "customer"
      });
    }

    changeSelectedFundsRoot(option) {
      this.selectedFundsRoot = option;
      this.cache.customerFundsRootOption = option;
      const query = this.query ? this.query : "";
      Ember.run.debounce(this, "searchCustomer", {
        query,
        hitsLimit: 20
      }, 200);
    }

    search(query) {
      Ember.run.debounce(this, "searchCustomer", {
        query,
        hitsLimit: 20
      }, 200);
    }

    submitForm(e) {
      e.preventDefault();

      if (this.selectedSearchType.type == "funds-root-search") {
        this.search(this.query);
      } else {
        this.searchLastFour(this.ccQuery);
      }
    }

    searchLastFour(query) {
      // Test using 0000 or 4021 in dev
      if (isNaN(query) || query.length != 4) {
        alert(this.intl.t("last_four_digits_error"));
      } else {
        this.isLoading = true;
        this.isLoadingSmall = true;
        let lastFourUrl = `/paymentMethods/search?lastFour=${query}&includeRemoved=${this.includeRemovedCCN ? 'true' : 'false'}`;
        this.ajax.request(lastFourUrl, {
          method: "GET"
        }).then(response => {
          response = response.map(item => {
            if (item.isRemoved) {
              // Removed cards
              item.type = Ember.String.htmlSafe(`${item.type}<br>(${this.intl.t("removed")})`);
            }

            return item;
          });
          this.results = {
            type: "raw",
            data: response
          };
        }).finally(() => {
          this.isLoading = false;
          this.isLoadingSmall = false;
        });
      }
    }

    showAll() {
      let {
        query
      } = this;
      Ember.run.debounce(this, "searchCustomer", {
        query,
        hitsLimit: 1000
      }, 200);
      this.createScrollEvent();
    } // due to the occlusion for the table, need to trigger a window resize event on scroll


    createScrollEvent(e) {
      const el = document.querySelector("#customer-list-size-container .ember-table-overflow");

      if (el) {
        el.addEventListener("scroll", e => {
          window.dispatchEvent(new Event("resize"));
        });
      }
    } // calculates the dynamic height


    setHeight() {
      let bottomOffset = 25;
      let windowHeight = window.innerHeight;
      let headerHeight = document.querySelector(".system-header").offsetHeight;
      let subMenuHeight = document.querySelector(".sub-menu") ? document.querySelector(".sub-menu").offsetHeight : 0;
      let sectionPaddingTop = 20;
      let sectionHeader = document.querySelector(".ui-route-header").offsetHeight;
      let topControls = document.querySelector(".c-list--top-controls").offsetHeight;
      let secondControls = document.querySelector(".c-list--total-results").offsetHeight;
      let showAllControls = 68; // document.querySelector('.controls--sub-list').offsetHeight;

      let locationsExpiringSoon = this.access.locationsExpiringSoon;
      let subscriptionWarningBanner = 0;

      if (locationsExpiringSoon) {
        if (locationsExpiringSoon.length > 0) {
          subscriptionWarningBanner = 43;
        }
      }

      let final = windowHeight - headerHeight - subMenuHeight - sectionPaddingTop - sectionHeader - topControls - secondControls - showAllControls - subscriptionWarningBanner - bottomOffset;
      this.height = final;
    }

    createDynamicHeightContainer() {
      this.setHeight(); // To remove event listener after willDestroyElement which where added using bind() you just have to store listener.bind(this) in a variable.
      // Because bind() is creating a new reference each time its called.

      this.bindInstance = this.setHeight.bind(this);
      window.addEventListener("resize", this.bindInstance, {
        passive: true
      });
      setTimeout(() => {
        this.createScrollEvent();
      }, 2000);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "algolia", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "brush", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "cache", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedFundsRoot", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedSearchType", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "query", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _descriptor11 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "results", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "bindInstance", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "height", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isLoading", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isLoadingSmall", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "includeRemovedCCN", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor17 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "tableHeader", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "customerTableHeader";
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "updateSelectedSearchType", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "updateSelectedSearchType"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "initialLoad", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "initialLoad"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "willDestroyCustomerTable", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "willDestroyCustomerTable"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "sendToBrush", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "sendToBrush"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "submitForm", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "submitForm"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "showAll", [_dec23], Object.getOwnPropertyDescriptor(_class.prototype, "showAll"), _class.prototype)), _class));
  _exports.default = CustomersCListTableComponent;
});