define("alliance-business-suite/components/badge-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * An icon that displays as a badge with an optional number over it.
   *
   * In inline form, the icon can be specified from the `name` parameter, which
   * specifies a Font Awesome icon. In block form, the content from the block is
   * rendered as the icon.
   *
   * # Positional Parameters
   *
   * name
   * : The name of the icon to display.
   *
   * number
   * : A number displayed as an overlay on top of the icon.
   *
   * @public
   */
  let ComponentClass = Ember.Component.extend({
    /**
     * The static class names for this component.
     *
     * @type {Array<String>}
     * @public
     */
    classNames: ['badge-icon'],

    /**
     * The dynamic class names for this component that are derived from component
     * properties.
     *
     * @type {Array<String>}
     * @public
     */
    classNameBindings: ['isActive:badge-icon--active'],

    /**
     * The name of the icon to display.
     *
     * @type {string}
     * @public
     */
    name: undefined,

    /**
     * The number on the badge.
     *
     * @type {Number}
     * @public
     */
    number: undefined,

    /**
     * Whether this badge is active. The badge is active if it's number is greater
     * than 0.
     *
     * @type {boolean}
     * @public
     */
    isActive: Ember.computed('number', function () {
      return this.number > 0;
    })
  });
  ComponentClass.reopenClass({
    positionalParams: ['name', 'number']
  });
  var _default = ComponentClass;
  _exports.default = _default;
});