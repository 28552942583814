define("alliance-business-suite/components/summary/reports/s-monthly-recon", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "alliance-business-suite/utils/currency-format-util", "alliance-business-suite/utils/report-tables-util"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _currencyFormatUtil, _reportTablesUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  let SummaryReportsSMonthlyReconComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, (_class = class SummaryReportsSMonthlyReconComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor3, this);
    }

    get translatedColumns() {
      return [{
        name: 'empty_string',
        width: 300,
        isFixed: 'left',
        subcolumns: [{
          name: 'Organization',
          valuePath: 'name',
          width: 300,
          isFixed: 'left'
        }]
      }, {
        name: 'deposits',
        textAlign: 'center',
        subcolumns: [{
          name: 'cash',
          valuePath: 'cashDeposits',
          textAlign: 'center',
          width: 180
        }, {
          name: 'credit',
          valuePath: 'creditDeposits',
          textAlign: 'center',
          width: 180
        }]
      }, {
        name: 'spending',
        textAlign: 'center',
        subcolumns: [{
          name: 'washers',
          valuePath: 'washerRevenue',
          textAlign: 'center',
          width: 180
        }, {
          name: 'dryers',
          valuePath: 'dryerRevenue',
          textAlign: 'center',
          width: 180
        }, {
          name: 'WDF',
          valuePath: 'serviceRevenue',
          textAlign: 'center',
          width: 180
        }, {
          name: 'retail',
          valuePath: 'goodsRevenue',
          textAlign: 'center',
          width: 180
        }, {
          name: 'tax',
          valuePath: 'tax',
          textAlign: 'center',
          width: 180
        }]
      }, {
        name: 'unearned_revenue',
        textAlign: 'center',
        width: 250,
        subcolumns: [{
          name: 'deposit_minus_spending',
          textAlign: 'center',
          valuePath: 'unearnedRevenue',
          width: 250
        }]
      }];
    }

    get rowsWithChildren() {
      let {
        model
      } = this.args;
      let geoBoundaries = model.geoBoundaries;
      let rowData = [];
      let totalGoodsSubTotalSum = 0;
      let totalGoodsSubTotal = [];
      let totalGoodsTaxSum = 0;
      let totalGoodsTax = [];
      let totalGoodsCashSubTotalSum = 0;
      let totalGoodsCashSubTotal = [];
      let totalGoodsCreditSubTotalSum = 0;
      let totalGoodsCreditSubTotal = [];
      let totalGoodsMobileSubTotalSum = 0;
      let totalGoodsMobileSubTotal = [];
      let totalGoodsDiscountSum = 0;
      let totalGoodsDiscount = [];
      let totalServiceSubTotalSum = 0;
      let totalServiceSubTotal = [];
      let totalServiceTaxSum = 0;
      let totalServiceTax = [];
      geoBoundaries.forEach(geo => {
        let roomGoodsSubTotalAmountArray = [];
        let roomGoodsTaxAmountArray = [];
        let roomGoodsCashSubTotalAmountArray = [];
        let roomGoodsCreditSubTotalAmountArray = [];
        let roomGoodsMobileSubTotalAmountArray = [];
        let roomGoodsDiscountAmountArray = [];
        let roomServiceSubTotalAmountArray = [];
        let roomServiceTaxAmountArray = [];

        if (geo.cashDeposits !== 0) {
          roomGoodsSubTotalAmountArray.push(geo.cashDeposits);
          totalGoodsSubTotalSum = (0, _reportTablesUtil.sumArray)(roomGoodsSubTotalAmountArray, totalGoodsSubTotalSum);
        }

        if (geo.creditDeposits !== 0) {
          roomGoodsTaxAmountArray.push(geo.creditDeposits);
          totalGoodsTaxSum = (0, _reportTablesUtil.sumArray)(roomGoodsTaxAmountArray, totalGoodsTaxSum);
        }

        if (geo.washerRevenue !== 0) {
          roomGoodsCashSubTotalAmountArray.push(geo.washerRevenue);
          totalGoodsCashSubTotalSum = (0, _reportTablesUtil.sumArray)(roomGoodsCashSubTotalAmountArray, totalGoodsCashSubTotalSum);
        }

        if (geo.dryerRevenue !== 0) {
          roomGoodsCreditSubTotalAmountArray.push(geo.dryerRevenue);
          totalGoodsCreditSubTotalSum = (0, _reportTablesUtil.sumArray)(roomGoodsCreditSubTotalAmountArray, totalGoodsCreditSubTotalSum);
        }

        if (geo.serviceRevenue !== 0) {
          roomGoodsMobileSubTotalAmountArray.push(geo.serviceRevenue);
          totalGoodsMobileSubTotalSum = (0, _reportTablesUtil.sumArray)(roomGoodsMobileSubTotalAmountArray, totalGoodsMobileSubTotalSum);
        }

        if (geo.goodsRevenue !== 0) {
          roomGoodsDiscountAmountArray.push(geo.goodsRevenue);
          totalGoodsDiscountSum = (0, _reportTablesUtil.sumArray)(roomGoodsDiscountAmountArray, totalGoodsDiscountSum);
        }

        if (geo.tax !== 0) {
          roomServiceSubTotalAmountArray.push(geo.tax);
          totalServiceSubTotalSum = (0, _reportTablesUtil.sumArray)(roomServiceSubTotalAmountArray, totalServiceSubTotalSum);
        }

        if (geo.unearnedRevenue !== 0) {
          roomServiceTaxAmountArray.push(geo.unearnedRevenue);
          totalServiceTaxSum = (0, _reportTablesUtil.sumArray)(roomServiceTaxAmountArray, totalServiceTaxSum);
        }

        rowData.push({
          name: geo.name,
          cashDeposits: totalGoodsSubTotal,
          creditDeposits: totalGoodsTax,
          washerRevenue: totalGoodsCashSubTotal,
          dryerRevenue: totalGoodsCreditSubTotal,
          serviceRevenue: totalGoodsMobileSubTotal,
          goodsRevenue: totalGoodsDiscount,
          tax: totalServiceSubTotal,
          unearnedRevenue: totalServiceTax,
          children: [{
            cashDeposits: _currencyFormatUtil.formatCurrencyString.call(this, geo.cashDeposits || 0),
            creditDeposits: _currencyFormatUtil.formatCurrencyString.call(this, geo.creditDeposits || 0),
            washerRevenue: _currencyFormatUtil.formatCurrencyString.call(this, geo.washerRevenue || 0),
            dryerRevenue: _currencyFormatUtil.formatCurrencyString.call(this, geo.dryerRevenue || 0),
            serviceRevenue: _currencyFormatUtil.formatCurrencyString.call(this, geo.serviceRevenue || 0),
            goodsRevenue: _currencyFormatUtil.formatCurrencyString.call(this, geo.goodsRevenue || 0),
            tax: _currencyFormatUtil.formatCurrencyString.call(this, geo.tax || 0),
            unearnedRevenue: _currencyFormatUtil.formatCurrencyString.call(this, geo.unearnedRevenue || 0)
          }]
        });
        totalGoodsSubTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalGoodsSubTotalSum || 0));
        totalGoodsTax.push(_currencyFormatUtil.formatCurrencyString.call(this, totalGoodsTaxSum || 0));
        totalGoodsCashSubTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalGoodsCashSubTotalSum || 0));
        totalGoodsCreditSubTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalGoodsCreditSubTotalSum || 0));
        totalGoodsMobileSubTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalGoodsMobileSubTotalSum || 0));
        totalGoodsDiscount.push(_currencyFormatUtil.formatCurrencyString.call(this, totalGoodsDiscountSum || 0));
        totalServiceSubTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalServiceSubTotalSum || 0));
        totalServiceTax.push(_currencyFormatUtil.formatCurrencyString.call(this, totalServiceTaxSum || 0));
      });
      return rowData;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SummaryReportsSMonthlyReconComponent;
});