define("alliance-business-suite/templates/components/locations/locations-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "a7E8i9hX",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,6],[[35,5]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"modal-dialog\",[],[[\"@animatable\",\"@targetAttachment\",\"@translucentOverlay\",\"@overlayClassNames\",\"@containerClassNames\"],[true,\"none\",true,\"ui-modal-dialog__overlay\",[30,[36,0],[\"ui-modal-dialog\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"header\"],[12],[2,\"\\n      \"],[10,\"span\"],[14,0,\"ui-modal-dialog__title\"],[12],[2,\"Select Locations\"],[13],[2,\"\\n      \"],[11,\"img\"],[24,\"src\",\"/assets/images/icons/icon-close-modal.svg\"],[4,[38,1],[[32,0],\"toggleLocationModal\"],null],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"ui-modal-dialog__content\"],[12],[2,\"\\n      \"],[8,\"ui/location-tree\",[],[[\"@model\",\"@hasCheckboxSupport\",\"@fundsRestrictionOnlyCheckbox\",\"@disableRooms\",\"@selectedTreeNodes\",\"@compactView\",\"@disableTransitionLinks\",\"@isExpanded\"],[[34,2],true,false,true,[34,3],true,true,false]],null],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"footer\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"ui-modal-dialog__footer-right\"],[12],[2,\"\\n        \"],[11,\"button\"],[16,\"label\",[30,[36,4],[\"OK\"],null]],[24,0,\"button button--secondary form-button--reset\"],[24,4,\"reset\"],[4,[38,1],[[32,0],\"toggleLocationModal\"],null],[12],[2,\"\\n          \"],[1,[30,[36,4],[\"OK\"],null]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"array\",\"action\",\"organization\",\"selectedLocations\",\"t\",\"showLocationModal\",\"if\"]}",
    "moduleName": "alliance-business-suite/templates/components/locations/locations-modal.hbs"
  });

  _exports.default = _default;
});