define("alliance-business-suite/components/table/table-cell-quantity-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    alertThreshold: Ember.computed('column.alertThreshold', function () {
      let threshold = 100;
      let record = this.record;

      if (this.get('column.alertThreshold')) {
        threshold = record.get(this.get('column.alertThreshold'));
      }

      return threshold;
    }),
    progressPercent: Ember.computed('column.propertyName', function () {
      let record = this.record;
      let inverted = this.get('column.inverted');
      let currentAmount = record.get(this.get('column.propertyName'));
      let threshold = this.alertThreshold;

      if (inverted) {
        return parseFloat(threshold / currentAmount * 100).toFixed(2);
      } else {
        return parseFloat(currentAmount / threshold * 100).toFixed(2);
      }
    }),
    progressBar: Ember.computed('progressPercent', function () {
      let percent = this.progressPercent;

      if (percent > 100) {
        return 1;
      } else if (percent < 0) {
        return 0;
      }

      return percent / 100;
    }),
    cssColor: Ember.computed('progressPercent', function () {
      let progressPercent = this.progressPercent;
      let inverted = this.get('column.inverted');
      let color = 'green';

      if (inverted) {
        if (progressPercent >= 75) {
          color = 'red';
        } else if (progressPercent >= 50) {
          color = 'orange';
        }
      } else {
        if (progressPercent <= 25) {
          color = 'red';
        } else if (progressPercent <= 50) {
          color = 'orange';
        }
      }

      return color;
    })
  });

  _exports.default = _default;
});