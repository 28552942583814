define("alliance-business-suite/templates/components/scrub/brush-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uB8eMI92",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,3],null,[[\"title\",\"subtitle\",\"scrub\",\"data\",\"state\",\"model\"],[[35,2],[35,1],[35,0,[\"scrub\"]],[35,0,[\"data\"]],[35,0,[\"state\"]],[35,0,[\"model\"]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"item\",\"subtitle\",\"title\",\"hash\"]}",
    "moduleName": "alliance-business-suite/templates/components/scrub/brush-item.hbs"
  });

  _exports.default = _default;
});