define("alliance-business-suite/components/table/programs/send/table-cell-program-progress", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class TableProgramsSendTableCelProgramProgressComponent extends _component.default {}

  _exports.default = TableProgramsSendTableCelProgramProgressComponent;
});