define("alliance-business-suite/templates/components/table/table-cell-machine-program-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DhaMzEJH",
    "block": "{\"symbols\":[\"@record\"],\"statements\":[[8,\"machines/programs/combo-button\",[],[[\"@manage\",\"@sendProgram\",\"@download\",\"@delete\",\"@export\",\"@isSupported\"],[[32,0,[\"manageItem\"]],[32,0,[\"sendItem\"]],[32,0,[\"downloadItem\"]],[32,0,[\"deleteItem\"]],[32,0,[\"exportItem\"]],[32,1,[\"isSupported\"]]]],null],[2,\"\\n\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,1],[\"MACHINES_PROGRAM_MANAGE_PROGRAM\"],null],[32,0,[\"devtools\",\"devToolsEnabled\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"button\"],[24,0,\"button button--secondary button--small\"],[16,\"disabled\",[32,0,[\"isCommandProcessing\"]]],[24,5,\"margin-left: 0; padding: 7px; float:none;\"],[24,\"title\",\"Delete program\"],[4,[38,0],[\"click\",[32,0,[\"deleteItem\"]]],null],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-trash\"],[14,\"aria-hidden\",\"true\"],[14,5,\"font-size:0.875rem;\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"on\",\"can-access\",\"and\",\"if\"]}",
    "moduleName": "alliance-business-suite/templates/components/table/table-cell-machine-program-options.hbs"
  });

  _exports.default = _default;
});