define("alliance-business-suite/helpers/spinner-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.spinnerIcon = spinnerIcon;

  function spinnerIcon(params
  /*, hash*/
  ) {
    return Ember.String.htmlSafe('<i class="fa fa-refresh fa-spin fa-fw"></i>');
  }

  var _default = Ember.Helper.helper(spinnerIcon);

  _exports.default = _default;
});