define("alliance-business-suite/components/products/moka-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    ajax: Ember.inject.service(),
    notifications: Ember.inject.service(),
    uploadOptions: ['MOKA_ITEM_SALES', 'MOKA_TRANSACTION_LIST'],
    acceptedFileTypes: ['csv'],
    rooms: Ember.computed(function () {
      return this.store.peekAll('room').sortBy('formattedBreadcrumbDisplay');
    }),

    async uploadFile(file, roomId) {
      return await this.ajax.request(`/rooms/${roomId}/posUpload?fileName=${file.get('blob.name')}`, {
        method: 'GET'
      }).then(response => {
        this.set('fileName', `${response.fileName}`); // needs to use reponse url

        return file.uploadBinary(response.signedUrl, {
          method: 'PUT'
        });
      });
    },

    actions: {
      async uploadFile(file) {
        if (this.acceptedFileTypes.indexOf(file.extension) === -1) {
          alert(`${this.intl.t('only_file_types')} ${this.acceptedFileTypes.join(', ')}`);
          return false;
        }

        let roomId = this.get('selectedRoom.id');
        let selectedUploadOption = this.selectedUploadOption;
        let notifications = this.notifications;
        this.set('isLoading', true);
        await this.uploadFile(file, roomId);
        this.ajax.request(`/rooms/${roomId}/posUpload`, {
          method: 'POST',
          data: {
            reportUploadType: selectedUploadOption,
            fileName: this.fileName
          }
        }).then(response => {
          this.set('isLoading', false);
          notifications.success(this.intl.t('moka_successful'), {
            clearDuration: 5000,
            autoClear: true
          });
        }, () => {
          this.set('isLoading', false);
          notifications.error(this.intl.t('error'), {
            clearDuration: 5000,
            autoClear: true
          });
        });
      }

    }
  });

  _exports.default = _default;
});