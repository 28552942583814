define("alliance-business-suite/components/form-ui/steps/speed", ["exports", "alliance-business-suite/helpers/find-by-dropdown-with-custom", "alliance-business-suite/utils/speed-utilities", "alliance-business-suite/mixins/init-property-names", "alliance-business-suite/utils/machine-programming-custom-properties"], function (_exports, _findByDropdownWithCustom, _speedUtilities, _initPropertyNames, _machineProgrammingCustomProperties) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_initPropertyNames.default, {
    didReceiveAttrs() {
      this._super(...arguments); // If there is a custom property, we need to do some extra work


      if (this.hasCustomProperty) {
        (0, _machineProgrammingCustomProperties.initCustomPropertyValues)({
          context: this,
          model: this.object,
          options: this.optionsSpeed,
          propertyName: this.properties.Speed.key,
          customPropertyName: this.properties.CustomSpeed.key,
          limits: this.params.validations.temperature
        });
      }
    },

    lastPresetValue: null,
    stepTypeName: Ember.computed('Type', function () {
      // eslint-disable-line ember/require-computed-property-dependencies
      return (0, _findByDropdownWithCustom.findByDropDown)([this.formSettings.selectOptions['StepType'], "val", this.Type]).label.toLowerCase();
    }),
    speedSettings: Ember.computed('stepTypeName', 'showG', 'basketDiameter', function () {
      // eslint-disable-line ember/require-computed-property-dependencies
      // Returns the min/max/presets for the current step
      let stepTypeName = this.stepTypeName;
      let settings = {};

      switch (stepTypeName) {
        case 'reusefill':
        case 'fill':
        case 'agitate':
        case 'soak':
        case 'cooldown':
        case 'washspray':
          settings = this.machineSettings.agitate;
          break;

        case 'sprayrinseextract':
          settings = this.machineSettings.sprayRinseExtract;
          break;

        case 'drain':
          settings = this.machineSettings.drain;
          break;

        case 'drainextract':
          // Use drainExtract if found in machine-type model, otherwise fall back to extract
          if (this?.machineSettings?.drainExtract !== undefined) {
            settings = this.machineSettings.drainExtract;
          } else {
            settings = this.machineSettings.extract;
          }

          break;

        case 'extract':
          settings = this.machineSettings.extract;
          break;
      }

      Ember.set(settings, 'basketDiameter', this.basketDiameter);
      Ember.set(settings, 'showG', this.showG);
      Ember.set(settings, 'stepName', stepTypeName);
      return settings;
    }),
    hasDistribution: Ember.computed.equal('params.display', 'distribution'),
    hasRotationDelay: Ember.computed('properties.AlwaysRotate', function () {
      return this.properties.AlwaysRotate !== undefined;
    }),
    hasCustomProperty: (0, _machineProgrammingCustomProperties.hasCustomProperty)({
      model: 'object',
      options: 'optionsSpeed',
      // TODO: This is hardcoded. :-( SpinSpeedAgitate
      propertyName: 'properties.Speed.key',
      customPropertyName: 'properties.CustomSpeed.key'
    }),
    optionsSpeed: Ember.computed('params.display', 'stepTypeName', function () {
      switch (this.stepTypeName) {
        case 'reusefill':
          return this.selectOptions.SpinSpeedReuseFill;

        case 'fill':
        case 'agitate':
        case 'soak':
        case 'cooldown':
        case 'washspray':
          return this.selectOptions.SpinSpeedAgitate;

        case 'sprayrinseextract':
          return this.selectOptions.SpinSpeedSprayRinseExtract;

        case 'drain':
          return this.selectOptions.SpinSpeedDrain;

        case 'drainextract':
        case 'extract':
          return this.selectOptions.SpinSpeedExtract;

        default:
          return [];
      }
    }),
    displayType: Ember.computed.alias('params.display'),
    basketDiameter: Ember.computed.alias('machineSettings.basketDiameter'),
    selectedSpeed: Ember.computed.alias('object.Speed'),
    // TODO: object or properties?
    showG: Ember.computed('basketDiameter', 'formSettings.showG', function () {
      // If basketDiameter is 0, only show g-force.
      // Without basket size, show G
      if (this.basketDiameter == 0) {
        return true;
      }

      return this.formSettings.showG;
    }),
    showPresets: Ember.computed('basketDiameter', function () {
      // If basketDiameter is 0, no presets
      return this.basketDiameter !== 0;
    }),
    pauseRotateValues: [{
      val: 0,
      label: 'normal',
      presetValue: '18/4/18',
      values: [18, 4],
      steps: [1, 2, 4, 6, 7]
    }, {
      val: 1,
      label: 'delicate',
      presetValue: '6/11/6',
      values: [6, 11],
      steps: [1, 2, 4, 7]
    }, {
      val: 2,
      label: 'wetting',
      presetValue: '5/15/5',
      values: [5, 15],
      steps: [1, 2, 4]
    }, {
      val: 3,
      label: 'shake_out',
      presetValue: '4/3/4',
      values: [4, 3],
      steps: [1, 2, 4]
    }, {
      val: 4,
      label: 'normal',
      presetValue: '4/56/4',
      values: [4, 56],
      steps: [5]
    }, {
      val: 5,
      label: 'delicate',
      presetValue: '4/176/4',
      values: [4, 176],
      steps: [5]
    }, {
      val: 6,
      label: 'cooldown',
      presetValue: '6/18/6',
      values: [6, 18],
      steps: [6]
    }],
    rotatePauseValue: Ember.computed('object.{RotateTime,PauseTime,Type}', function () {
      let prop1 = this.object.RotateTime;
      let prop2 = this.object.PauseTime;
      let match = this.pauseRotateValues.find(function (item) {
        return item.values[0] == prop1 && item.values[1] == prop2;
      });
      return match.val;
    }),
    optionsRotatePause: Ember.computed('object.Step', function () {
      let currentStep = this.object.Type;
      let options = this.pauseRotateValues;
      return options.filter(item => {
        return item.steps.includes(currentStep);
      });
    }),
    actions: {
      updateRotatePause(params) {
        this.update(this.object, "RotateTime", params.values[0]);
        this.update(this.object, "PauseTime", params.values[1]);
        return params.val;
      },

      updateCustomProperty(selection) {
        console.log('*** lastPresetValue', this.lastPresetValue);
        (0, _machineProgrammingCustomProperties.updateCustomPropertyValues)({
          context: this,
          hasCustomProperty: _machineProgrammingCustomProperties.hasCustomProperty,
          model: this.object,
          selection: selection,
          lastPresetValue: this.lastPresetValue,
          propertyName: this.properties.Speed.key,
          customPropertyName: this.properties.CustomSpeed.key
        });
      }

    }
  });

  _exports.default = _default;
});