define("alliance-business-suite/adapters/system-alert-subscription", ["exports", "alliance-business-suite/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    access: Ember.inject.service(),
    namespace: Ember.computed(function () {
      let userId = Ember.get(this, 'access.accessUser.id');
      return `users/${userId}`;
    }),

    buildURL(modelName, id, snapshot, requestType, query) {
      let url = this._super(modelName, id, snapshot, requestType, query);

      let host;

      switch (requestType) {
        case 'deleteRecord':
          host = Ember.get(this, 'host');
          url = `${host}/systemAlertSubscriptions/${snapshot.id}`;
          return url;
      }

      return url;
    }

  });

  _exports.default = _default;
});