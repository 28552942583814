define("alliance-business-suite/models/machine-status", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    statusId: attr('string'),
    selectedCycle: attr(),
    // {id: number,name: string}
    selectedModifier: attr(),
    // {id: number,name: string}
    uuid: attr('string'),
    remainingSeconds: attr('number'),
    remainingVend: attr('number'),
    receivedAt: attr('date'),
    createdAt: attr('date'),
    updatedAt: attr('date')
  });

  _exports.default = _default;
});