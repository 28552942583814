define("alliance-business-suite/adapters/campaign-promotion", ["exports", "alliance-business-suite/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    /**
     * Builds the URL for a request.
     *
     * @public
     */
    // TODO - Work with team to use proper end point
    buildURL(modelName, id, snapshot, requestType, query) {
      let url = this._super(modelName, id, snapshot, requestType, query);

      let campaignId = Ember.get(snapshot, 'adapterOptions.campaignId');

      switch (requestType) {
        case 'createRecord':
          url = url.replace('campaignPromotions', `campaigns/${campaignId}/promotionCodes`);
          break;

        case 'updateRecord':
          url = url.replace(`campaignPromotions/${id}`, `promotionCodes/${id}`);
          break;

        case 'deleteRecord':
          url = url.replace(`campaignPromotions/${id}`, `promotionCodes/${id}`);
          break;
      }

      return url;
    }

  });

  _exports.default = _default;
});