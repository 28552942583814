define("alliance-business-suite/models/rewards-program", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    hasMany
  } = _emberData.default;

  var _default = Model.extend({
    name: attr(''),
    access: Ember.inject.service(),
    pointsExpirationInterval: attr(''),
    pointsPerDry: attr('rewards-points'),
    pointsPerWash: attr('rewards-points'),
    pointsThreshold: attr('rewards-points'),
    promotionalCreditEarned: attr('number'),
    promotionalCreditEarnedDollars: Ember.computed('promotionalCreditEarned', {
      get() {
        return this.promotionalCreditEarned || 0;
      },

      set(key, value) {
        value || (value = 0);
        this.set('promotionalCreditEarned', value);
        return value;
      }

    }),
    depositRewards: hasMany('deposit-reward'),
    rewardsProgramScheduleDays: hasMany('rewards-program-schedule-day'),
    rewardsProgramMachineTypes: hasMany('rewards-program-machine-type'),
    hasDepositRewards: Ember.computed('depositRewards', function () {
      if (this.depositRewards.get('length') > 0) {
        return true;
      }
    }),
    hasScheduleDays: Ember.computed('rewardsProgramScheduleDays', function () {
      if (this.rewardsProgramScheduleDays.get('length') > 0) {
        return true;
      }
    }),
    hasProgramMachineTypes: Ember.computed('rewardsProgramMachineTypes', function () {
      if (this.rewardsProgramMachineTypes.get('length') > 0) {
        return true;
      }
    })
  });

  _exports.default = _default;
});