define("alliance-business-suite/components/ui/led-field", ["exports", "alliance-business-suite/utils/led"], function (_exports, _led) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /*
      This component displays the six characters displayed on the 7-segment LED displays on machines. The
      characters can be letters a through z, and numbers 0 through 9.
       There are two different implementations possible. A single property containing the base64-encoded
      hex values of all 6 characters, or an array of decimal values (6 total) representing the character
      to be displayed. For example:
       Water Guardian
        "Bjd4bT45" == "CUSTM1"
       Machine Configuration
        [57,119,80,94,0,0] == "CARD  "
       2021-01-21:
      Now we can get ASCII text for values, such as "CArd"
    */
    isDecimalArray: false,
    validAscii: "0123456789 AbCdEFHiJKLMnoPqrtUvWy",
    // special: gsxz
    mustCapitalize: "acefhjklmpuw",
    displayValue: Ember.computed('property', 'value', 'isDecimalArray', 'isAscii', {
      get() {
        if (this.property) {
          let output;
          let val = this.property;

          if (this.isAscii) {
            // We always need lowercase for the font to work
            return this.property.toLowerCase();
          }

          if (this.isDecimalArray) {
            // Some tabs will pass in an array of decimals instead of a base64 encoded string of Unicode, which does NOT get reversed.
            output = val;
          } else {
            output = (0, _led.base64ToDecimalArray)(val);
            output.reverse();
          }

          return output.reduce((previous, data) => {
            let hex = data.toString(16).padStart(2, '0'); // Convert to hex so we can lookup the value

            return previous += _led.LED_HEX2CHAR[`0x${hex.toUpperCase()}`]; // Find value and append to return string
          }, '');
        }

        return null; // Fix lint warning
      },

      set(key, value) {
        if (value) {
          if (this.isAscii) {
            Ember.set(this, 'property', value);
            return value.toLowerCase();
          }

          value = value.toLowerCase();
          let letters = value.split('');
          letters.reverse();
          let hexString = '';
          let dec = [];
          letters.forEach(function (letter, index) {
            // Only use valid characters
            let hex = _led.LED_CHAR2HEX[letter];

            if (hex) {
              hex = hex.toUpperCase();
              hexString += hex;
              dec[index] = parseInt(`0x${hex}`, 16) || 0;
            }
          });

          if (this.isDecimalArray) {
            // Return array of decimals
            Ember.set(this, 'property', dec.reverse());
          } else {
            // Return base64-encoded string of Unicode
            let base64 = (0, _led.hexToBase64)(hexString);
            Ember.set(this, 'property', base64);
          }
        } else {
          // No value, it's empty
          if (this.isDecimalArray) {
            Ember.set(this, 'property', [0, 0, 0, 0, 0, 0]);
          } else {
            Ember.set(this, 'property', '');
          }
        }

        return value;
      }

    }),
    actions: {
      validateInput(value) {
        // If we working with ASCII, we just check the case and update the value
        if (this.isAscii) {
          let validAscii = this.validAscii.toLowerCase();
          let validOutput = '';
          value.toLowerCase().split('').forEach((letter, index) => {
            if ("gsxz".includes(letter)) {
              if (letter === 'g') validOutput += "9";
              if (letter === 's') validOutput += "5";
              if (letter === 'x') validOutput += "H";
              if (letter === 'z') validOutput += "2";
            } else {
              if (validAscii.includes(letter)) {
                if (this.mustCapitalize.includes(letter)) {
                  validOutput += letter.toUpperCase();
                } else {
                  validOutput += letter;
                }
              }
            }
          });
          Ember.set(this, 'displayValue', validOutput);
          return;
        }

        let letters = value.toLowerCase().split('');
        let validLetters = letters.filter(item => {
          return 'abcdefghijklmnopqrstuvwxyz0123456789'.indexOf(item) >= 0;
        });
        let response = validLetters.join('');
        Ember.set(this, 'displayValue', response.substr(0, 6)); // Only allow 6 characters
      }

    }
  });

  _exports.default = _default;
});