define("alliance-business-suite/mixins/machines/programs/v3/advanced-low-power", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    advanced: true,
    displayFor: null,
    enableWith: null,
    showWith: null,
    classNameBindings: ['isInvalid'],
    isInvalid: Ember.computed('isValidProperty', function () {
      return Ember.get(this, 'isValidProperty') ? '' : 'ui__invalidPropertyBinding';
    }),
    show: Ember.computed('advanced', function () {
      let displayList = Ember.get(this, 'displayFor');

      if (displayList) {
        // we have a list to display for
        let formName = Ember.get(this, 'form');

        if (!displayList.includes(formName)) {
          // array
          return false;
        }
      }

      if (Ember.get(this, 'advanced') === false) {
        return false;
      } else {
        return true;
      }
    }),
    isDisabled: Ember.computed('enableWith', function () {
      if (Ember.get(this, 'enableWith') == null) {
        return false;
      }

      let val = Ember.get(this, 'enableWith') || false;
      return !val;
    }),
    isValidProperty: Ember.computed('property', function () {
      return Ember.get(this, 'property') === undefined ? false : true;
    })
  });

  _exports.default = _default;
});