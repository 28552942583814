define("alliance-business-suite/routes/summary/charts", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "alliance-business-suite/routes/_secureRoute"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _secureRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  let SummaryChartsRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, (_class = class SummaryChartsRoute extends _secureRoute.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "brush", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "cache", _descriptor3, this);
      (0, _defineProperty2.default)(this, "allowAccess", 'SUMMARY_CHARTS_VIEW_PAGE');
      (0, _defineProperty2.default)(this, "hideBrush", true);
    }

    async model() {
      let {
        access,
        store
      } = this;
      let organizationId = access.get('accessOrganization.id');
      let organization = store.peekRecord('organization', organizationId);
      let rooms = store.peekAll('room');
      let geoBoundariesInDS = store.peekAll('geo-boundary').get('length'); // if no cached geoBoundaries, then GET

      if (geoBoundariesInDS == 0) {
        await store.queryRecord('organization', {
          organization: organizationId,
          geoBoundaries: true,
          include: 'all'
        });
        rooms = await store.peekAll('room');
      }

      return {
        organization,
        rooms
      };
    }

    setupController(controller, model) {
      super.setupController(controller, model);
      controller.rooms = model.rooms; // controller.initRoom = [];

      this.cache.set('loadedGeoIncludedAll', true);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "brush", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "cache", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SummaryChartsRoute;
});