define("alliance-business-suite/components/summary/reports/s-machine-outage", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "alliance-business-suite/utils/report-tables-util"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _reportTablesUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  let SummaryReportsSMachineOutageComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, (_class = class SummaryReportsSMachineOutageComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "brand", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor4, this);
    }

    get translatedColumns() {
      return [{
        name: 'location_machine',
        valuePath: 'name',
        width: 225,
        isFixed: 'left'
      }, {
        name: 'audit_last_received_at',
        valuePath: 'auditLastReceivedAt',
        width: 150
      }, {
        name: 'machine_id',
        valuePath: 'id',
        width: 150
      }, {
        name: 'machine_serial_number',
        valuePath: 'serialNumber',
        width: 150
      }, {
        name: 'machine_model_number',
        valuePath: 'modelNumber',
        width: 150
      }];
    }

    get rowsWithChildren() {
      let geoBoundaries = this.args.model.get('geoBoundaries');
      let rowData = [];
      geoBoundaries.forEach(geoBoundary => {
        let rowArray = [];
        let nameArray = [];
        rowData.push({
          name: _reportTablesUtil.localizeString.call(this, geoBoundary.name, 'Location', 'location'),
          children: nameArray
        });
        geoBoundary.rooms.forEach(room => {
          nameArray.push({
            name: room.name,
            children: rowArray
          });
          room.machineAuditGroupTypes.forEach(machineAuditType => {
            machineAuditType.machineTypes.forEach(machineType => {
              let machineArray = [];
              let machineTypeName = `${machineAuditType.name} - ${machineType.name}`;
              rowArray.push({
                name: machineTypeName,
                machineTypeName: machineType.name,
                children: machineArray
              });
              machineType.machines.forEach(machine => {
                let machineName = _reportTablesUtil.machineNameDisplay.call(this, machine.machineName, machine.machineNumber);

                let machineAuditLastReceivedAt = "";

                if (machine.auditLastReceivedAt) {
                  machineAuditLastReceivedAt = moment(machine.auditLastReceivedAt).format(`${_reportTablesUtil.formatDateTimeString.call(this)}`); // no longer needed, BE does conversion
                  // machineAuditLastReceivedAt = moment(machine.auditLastReceivedAt).tz(`${room.timeZone}`).format(`${formatDateTimeString.call(this)}`)
                }

                let machineId = machine.id;
                let machineSerial = machine.serialNumber;
                let machineModelNumber = machine.modelNumber;

                if (this.brand.slug == 'primus') {
                  // fix the branding issue alliance-tasks/issues/2291
                  rowArray[rowArray.length - 1].name = `${machine.machineAuditType.name} - ${rowArray[rowArray.length - 1].machineTypeName}`;
                }

                machineArray.push({
                  name: machineName,
                  auditLastReceivedAt: machineAuditLastReceivedAt,
                  id: machineId,
                  serialNumber: machineSerial,
                  modelNumber: machineModelNumber
                });
              });
            });
          });
        });
      });
      return rowData;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "brand", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SummaryReportsSMachineOutageComponent;
});