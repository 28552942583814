define("alliance-business-suite/components/summary/reports/s-employee-login", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "alliance-business-suite/utils/report-tables-util"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _reportTablesUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  const SYSTEM_ROLES = ['OWNER_OPERATOR', 'EMPLOYEE', 'MANAGER'];
  let SummaryReportsSEmployeeLoginComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, (_class = class SummaryReportsSEmployeeLoginComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor3, this);
    }

    get translatedColumns() {
      return [{
        name: 'email',
        valuePath: 'email',
        width: 180,
        isFixed: 'left'
      }, {
        name: 'role',
        valuePath: 'securityRole',
        width: 180
      }, {
        name: 'login_timestamp',
        valuePath: 'loginAt',
        width: 180
      }, {
        name: 'app',
        valuePath: 'appName',
        width: 180
      }];
    }

    get rowsWithChildren() {
      let userRecords = this.args.model.get('employeeLogins') ? this.args.model.get('employeeLogins') : [];
      let rowData = [];
      userRecords.forEach(userRecord => {
        rowData.push({
          email: userRecord.email ? userRecord.email : "",
          securityRole: this.translateRoleName(userRecord.securityRole),
          loginAt: userRecord.loginAt ? moment(userRecord.loginAt).format(`${_reportTablesUtil.formatDateTimeString.call(this)}`) : "",
          appName: userRecord.appName ? userRecord.appName : ""
        });
      });
      return rowData;
    } // we should not try to translate custom name


    translateRoleName(role) {
      return SYSTEM_ROLES.indexOf(role) === -1 ? role : this.intl.t(role);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SummaryReportsSEmployeeLoginComponent;
});