define("alliance-business-suite/initializers/brand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    initialize(app) {
      app.inject('controller', 'brand', 'service:brand');
      app.inject('component', 'brand', 'service:brand');
      app.inject('helper', 'brand', 'service:brand');
      app.inject('route', 'brand', 'service:brand');
      app.inject('template', 'brand', 'service:brand');
    }

  };
  _exports.default = _default;
});