define("alliance-business-suite/templates/support", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mRWalaDA",
    "block": "{\"symbols\":[],\"statements\":[[8,\"support-section\",[],[[\"@selectedSection\"],[[34,0]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"section\"]}",
    "moduleName": "alliance-business-suite/templates/support.hbs"
  });

  _exports.default = _default;
});