define("alliance-business-suite/templates/components/brushes/device-brush", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FJHZ4e25",
    "block": "{\"symbols\":[],\"statements\":[[1,[34,0]],[2,\"\\n\\n\"],[10,\"header\"],[14,0,\"listing-container listing-header\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"inner--left\"],[12],[2,\"\\n    \"],[10,\"img\"],[14,\"src\",\"/assets/images/scrubbrush/icon-avatar-empty.svg\"],[14,0,\"avatar\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"inner--right\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"title\"],[12],[2,\"S/N 1602051428 (N032)\"],[13],[2,\"\\n    \"],[10,\"span\"],[14,0,\"byline status status--active\"],[12],[1,[30,[36,1],[\"connected\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"section\"],[14,0,\"listing-table\"],[12],[2,\"\\n  \"],[10,\"article\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"inner inner--left\"],[12],[2,\"\\n      \"],[10,\"span\"],[12],[1,[30,[36,1],[\"location\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"inner inner--right\"],[12],[2,\"\\n      \"],[10,\"span\"],[12],[2,\"Houston - Foundren St.\"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"article\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"inner inner--left\"],[12],[2,\"\\n      \"],[10,\"span\"],[12],[1,[30,[36,1],[\"network_controller\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"inner inner--right\"],[12],[2,\"\\n      \"],[10,\"a\"],[12],[10,\"span\"],[12],[2,\"Houston - Foundren St.\"],[13],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"article\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"inner inner--left\"],[12],[2,\"\\n      \"],[10,\"span\"],[12],[1,[30,[36,1],[\"last_processed\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"inner inner--right\"],[12],[2,\"\\n      \"],[10,\"span\"],[12],[2,\"Aug 30, 2016 12:40 AM\"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"data\",\"t\"]}",
    "moduleName": "alliance-business-suite/templates/components/brushes/device-brush.hbs"
  });

  _exports.default = _default;
});