define("alliance-business-suite/templates/components/machines/programs/special-vend-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ckESUd74",
    "block": "{\"symbols\":[],\"statements\":[[2,\"\\n\"],[6,[37,2],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,0],[\"enabled\"],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[30,[36,0],[\"off\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"enabled\",\"if\"]}",
    "moduleName": "alliance-business-suite/templates/components/machines/programs/special-vend-details.hbs"
  });

  _exports.default = _default;
});