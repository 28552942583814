define("alliance-business-suite/templates/components/page-ui/columns", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5hVUnWOs",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,1],null,[[\"column\"],[[30,[36,0],[\"page-ui/column\"],null]]]]]]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "moduleName": "alliance-business-suite/templates/components/page-ui/columns.hbs"
  });

  _exports.default = _default;
});