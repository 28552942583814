define("alliance-business-suite/components/guests/g-merge-modal", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    brush: Ember.inject.service(),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    conflictGuest: Ember.computed(function () {
      return this.store.findRecord('guest', this.guest.conflictGuestId);
    }),
    phone: Ember.computed('conflictGuest.telecomInternationalCode', function () {
      let telecomInternationalCode = this.get('conflictGuest.telecomInternationalCode') || '';
      let telecomAreaCode = this.get('conflictGuest.telecomAreaCode') || '';
      let telecomContactNumber = this.get('conflictGuest.telecomContactNumber') || '';

      if (telecomInternationalCode && telecomContactNumber) {
        return `+${telecomInternationalCode}${telecomAreaCode}${telecomContactNumber}`;
      }

      return;
    }),
    actions: {
      close() {
        this.close();
      },

      merge() {
        if (confirm(this.intl.t('guest_merge_warning'))) {
          this.set('isMerging', true);
          this.ajax.request(`/pos/guests/${this.guest.conflictGuestId}/merge/${this.guest.id}`, {
            method: 'PATCH'
          }).then(result => {
            this.store.pushPayload(result);

            for (let change in this.changes) {
              this.guest.set(change, this.changes[change][1]);
            }

            this.guest.set('conflictGuestId', null);
            this.guest.set('nonUpdateReason', null);
            this.guest.save();
            this.store.peekRecord('guest', this.get('conflictGuest.id')).unloadRecord();
            this.set('isMerging', false);
            this.brush.transitionTo('guest', {
              type: 'guest',
              id: this.guest.id
            });
          }, () => {
            this.set('isMerging', false);
          });
        }
      }

    }
  });

  _exports.default = _default;
});