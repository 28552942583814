define("alliance-business-suite/components/form-ui/top-off", ["exports", "alliance-business-suite/mixins/init-property-names"], function (_exports, _initPropertyNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_initPropertyNames.default, {
    init() {
      this._super(...arguments);

      Ember.defineProperty(this, 'TopoffEnableType', Ember.computed.reads(`object.${Ember.get(this, 'properties.TopoffEnableType.key')}`));
    },

    isEnabledBoolean: Ember.computed(function () {
      return Ember.typeOf(this.TopoffEnableType) === "boolean";
    }),
    isAdjustBoolean: Ember.computed(function () {
      return Ember.typeOf(this.TopoffTimeAdjustEnabled) === "boolean";
    }),
    isEnabled: Ember.computed('params.topoffEnabledValue', 'TopoffEnableType', function () {
      let enabledValue = this.params.topoffEnabledValue !== undefined ? this.params.topoffEnabledValue : true;
      return this.TopoffEnableType == enabledValue;
    })
  });

  _exports.default = _default;
});