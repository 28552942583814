define("alliance-business-suite/templates/components/welcome/welcome-owner-app-promo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aZo6Vcjg",
    "block": "{\"symbols\":[\"badge\"],\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"ui-modal-dialog__content padding-0 overflow-hidden\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"owner-app-promo\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"download-badges\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,0,[\"badges\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"a\"],[15,6,[32,1,[\"url\"]]],[14,\"target\",\"_blank\"],[12],[2,\"\\n            \"],[10,\"img\"],[15,\"src\",[32,1,[\"image\"]]],[14,\"alt\",\"\"],[12],[13],[2,\"\\n          \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"      \"],[13],[2,\"\\n\\n      \"],[10,\"img\"],[15,\"src\",[31,[\"/assets/images/\",[32,0,[\"promoImg\"]],\".png\"]]],[14,\"alt\",\"\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"footer\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"ui-modal-dialog__footer-right\"],[12],[2,\"\\n      \"],[11,\"button\"],[16,\"label\",[30,[36,2],[\"buttons.cancel\"],null]],[24,0,\"button primary\"],[4,[38,3],[\"click\",[32,0,[\"continue\"]]],null],[12],[2,\"\\n          \"],[1,[30,[36,2],[\"continue\"],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\",\"t\",\"on\"]}",
    "moduleName": "alliance-business-suite/templates/components/welcome/welcome-owner-app-promo.hbs"
  });

  _exports.default = _default;
});