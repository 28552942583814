define("alliance-business-suite/helpers/currency", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    currencyFormat: Ember.inject.service('currency-format'),

    compute(_ref, namedArgs) {
      let [value, ...rest] = _ref;
      // Format currency using currency-formatter service
      return this.currencyFormat.serializeCurrency(value, namedArgs.useOverride, namedArgs.isDollars);
    }

  });

  _exports.default = _default;
});