define("alliance-business-suite/components/retail/advanced-section", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20;

  let RetailAdvancedSectionComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember._tracked, _dec18 = Ember._tracked, _dec19 = Ember._tracked, _dec20 = Ember._tracked, _dec21 = Ember._action, _dec22 = Ember._action, _dec23 = Ember._action, (_class = class RetailAdvancedSectionComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "ajax", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "canAccess", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "notifications", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "isLoading", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "isError", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "isSaving", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "PAY_LATER_ORDER", _descriptor10, this);
      (0, _initializerDefineProperty2.default)(this, "CASH_ACCEPTED", _descriptor11, this);
      (0, _initializerDefineProperty2.default)(this, "posConfigNotifications", _descriptor12, this);
      (0, _initializerDefineProperty2.default)(this, "notification_CUSTOMER_DROP_OFF", _descriptor13, this);
      (0, _initializerDefineProperty2.default)(this, "notification_SERVICING", _descriptor14, this);
      (0, _initializerDefineProperty2.default)(this, "notification_READY_FOR_PICKUP", _descriptor15, this);
      (0, _initializerDefineProperty2.default)(this, "notification_CUSTOMER_PICKUP", _descriptor16, this);
      (0, _initializerDefineProperty2.default)(this, "notification_CANCELED", _descriptor17, this);
      (0, _initializerDefineProperty2.default)(this, "roomId", _descriptor18, this);
      (0, _initializerDefineProperty2.default)(this, "posOptions", _descriptor19, this);
      (0, _initializerDefineProperty2.default)(this, "selectedRoom", _descriptor20, this);
    }

    // isTrade orgs cannot view, even if they have the SFAs
    get canViewOrderNotifications() {
      let {
        canAccess,
        access
      } = this;
      let isTrade = access.accessOrganization.get('isTrade');
      let canViewSFA = this.canAccess.canAccess('RETAIL_VIEW_ORDER_STATUS_NOTIFICATIONS');
      return !isTrade && canViewSFA;
    }

    get disableEditOrderNotifications() {
      return !this.canAccess.canAccess('RETAIL_MANAGE_ORDER_STATUS_NOTIFICATIONS');
    }

    async getPosOptions(roomId) {
      this.roomId = roomId;
      return this.ajax.request(`rooms/${roomId}/posOptions`, {}).then(response => {
        this.buildPosOptions(response);
      }).catch(() => {
        this.isError = true;
        this.posOptions = null;
      });
    }

    async getPosConfig(roomId) {
      return this.ajax.request(`rooms/${roomId}/pos/config?raw=true`, {}).then(response => {
        this.buildPosConfig(response.notifications);
      }).catch(error => {
        this.isError = true;
        this.posConfigNotifications = null;
      });
    }

    get rooms() {
      let allRooms = this.store.peekAll('room').sortBy('formattedBreadcrumbDisplay');
      return allRooms.filter(room => {
        if (room.isRetail) {
          return true;
        }
      });
    } // because of the tracking/checkbox component
    // they need to become properties on the object this.POS_etc


    buildPosOptions(options) {
      this.posOptions = options;
      options.forEach(o => {
        this[o.posOptionTypeLookupKey] = o.isEnabled;
      });
    } // because of the tracking/checkbox component
    // they need to become properties on the object this.POS_etc


    buildPosConfig(notifications) {
      // sorted by ordinal value
      notifications.sort((a, b) => {
        return a.ordinal - b.ordinal;
      });
      this.posConfigNotifications = notifications;
      notifications.forEach(n => {
        this[`notification_${n.lookupKey}`] = n.notify;
      });
    }

    async roomSelected(val) {
      this.selectedRoom = val;
      this.isLoading = true;
      this.posConfigNotifications = null;
      this.posOptions = null;

      if (this.canAccess.canAccess('RETAIL_POS_MANAGE_OPTIONS')) {
        await this.getPosOptions(val.id);
      }

      if (this.canViewOrderNotifications) {
        await this.getPosConfig(val.id);
      }

      this.isLoading = false;
    }

    toggleProperty(key) {
      this[key] = !this[key];
    }

    async save() {
      this.isSaving = true;

      if (this.canAccess.canAccess('RETAIL_POS_MANAGE_OPTIONS')) {
        await this.savePosOptions();
      }

      if (this.canViewOrderNotifications && this.canAccess.canAccess('RETAIL_MANAGE_ORDER_STATUS_NOTIFICATIONS')) {
        await this.saveConfigNotifications();
      }

      this.isSaving = false;
      this.notifications.success(this.intl.t('success'), {
        clearDuration: 5000,
        autoClear: true
      });
    }

    async savePosOptions() {
      let options = this.posOptions.map(option => {
        return {
          id: option.id,
          isEditable: option.isEditable,
          posOptionTypeLookupKey: option.posOptionTypeLookupKey,
          isEnabled: this[option.posOptionTypeLookupKey]
        };
      });
      return this.ajax.request(`rooms/${this.roomId}/posOptions`, {
        method: 'PATCH',
        data: {
          options
        }
      });
    }

    async saveConfigNotifications() {
      let notifications = this.posConfigNotifications.map(option => {
        return {
          id: option.id,
          lookupKey: option.lookupKey,
          notify: this[`notification_${option.lookupKey}`]
        };
      });
      return this.ajax.request(`rooms/${this.roomId}/pos/config`, {
        method: 'PATCH',
        data: {
          notifications
        }
      });
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "canAccess", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "notifications", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isLoading", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isError", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isSaving", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "PAY_LATER_ORDER", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "CASH_ACCEPTED", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "posConfigNotifications", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "notification_CUSTOMER_DROP_OFF", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "notification_SERVICING", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "notification_READY_FOR_PICKUP", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "notification_CUSTOMER_PICKUP", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "notification_CANCELED", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "roomId", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "posOptions", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedRoom", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "roomSelected", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "roomSelected"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "toggleProperty", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "toggleProperty"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "save", [_dec23], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype)), _class));
  _exports.default = RetailAdvancedSectionComponent;
});