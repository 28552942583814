define("alliance-business-suite/components/billing/subscription-statuses", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // eslint-disable-line
    tagName: '',
    access: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),

    didReceiveAttrs() {
      this._super(...arguments);

      this.set('renderComplete', false);
      this.timeOutRender(this);
    },

    locations: Ember.computed('access.billingLocations', function () {
      return this.access.billingLocations.sortBy('name');
    }),
    locales: Ember.computed('intl.{locale,locales}', function () {
      return this.intl;
    }),

    // HACK because we need to break out the render from happening instantly twice
    // or else we get an ember error of "You’ve modified xxx twice in a single render"
    timeOutRender(context) {
      setTimeout(() => {
        context.set('renderComplete', true);
      }, 500);
    },

    actions: {
      togglePaymentForm(loc) {
        if (loc) {
          this.set('selectedLoc', loc.id);
        }

        this.toggleProperty('showPaymentForm');
      },

      toggleUpgradeForm(loc) {
        if (loc) {
          this.setProperties({
            rawLoc: loc,
            currentPlan: loc.service,
            selectedLoc: loc.id
          });
        }

        this.toggleProperty('showUpgradeForm');
      }

    }
  });

  _exports.default = _default;
});