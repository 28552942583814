define("alliance-business-suite/models/custom-inflector-rules", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import Inflector from 'ember-inflector';
  // const { inflector } = Inflector;
  // inflector.irregular('person', 'persons');
  // Meet Ember Inspector's expectation of an export
  var _default = {};
  _exports.default = _default;
});