define("alliance-business-suite/components/ui/date-format", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    access: Ember.inject.service(),
    formatDate: Ember.computed('intl', function () {
      let userId = this.access.accessUser.id;
      let user = this.store.peekRecord('employee', userId);
      let dateFormat = user.get('dateFormat');

      if (dateFormat === undefined) {
        dateFormat = 'MM/DD/YYYY';
      }

      return dateFormat;
    }),
    firstDay: Ember.computed('access.calendarStartDay', 'overrideFirstDay', function () {
      if (this.overrideFirstDay) {
        return this.overrideFirstDay;
      }

      return this.access.calendarStartDay();
    }),
    actions: {
      doChange(val) {
        if (this.onchange) {
          this.onchange(val);
        }

        if (this.onChange) {
          this.onChange(val);
        }

        if (this.onSelection) {
          this.onSelection(val);
        }

        if (this.onChangeObject) {
          this.onChangeObject(val);
        }

        if (this.onChangeValue) {
          this.onChangeValue(val.val);
        }
      }

    }
  });

  _exports.default = _default;
});