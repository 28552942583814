define("alliance-business-suite/utils/includesAny", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = includesAny;

  function includesAny(array, items) {
    let isFound = false;
    items.forEach(item => {
      if (array.includes(item)) {
        isFound = true;
      }
    });
    return isFound;
  }
});