define("alliance-business-suite/controllers/locations/rewards/manage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    access: Ember.inject.service(),
    modelIdObserver: Ember.observer('model.id', function () {
      Ember.set(Ember.get(this, 'access'), 'accessRewardProgramId', Ember.get(this, 'model.id'));
    }),
    actions: {}
  });

  _exports.default = _default;
});