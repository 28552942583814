define("alliance-business-suite/routes/application", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "alliance-business-suite/utils/locale", "alliance-business-suite/mixins/account-payment", "moment-timezone", "firebase/app", "alliance-business-suite/config/environment", "alliance-business-suite/utils/fetch-localizations", "alliance-business-suite/utils/demo-mode"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _locale, _accountPayment, _momentTimezone, _app, _environment, _fetchLocalizations, _demoMode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;

  let ApplicationRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember.inject.service, _dec9 = Ember._tracked, _dec10 = Ember._action, _dec11 = Ember._action, (_class = class ApplicationRoute extends Ember.Route.extend(_accountPayment.default) {
    // needed so util fn's can window.appThis.get('logr')
    // An array of arguments from calls to `queryParamsDidChange` that were
    // deferred until the access service is loaded.
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "ajax", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "brush", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "moment", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "session", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "logr", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "devtools", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "_deferredQueryParamChanges", _descriptor9, this);
      window.appThis = this;
    }

    redirect(model, transition) {
      if (this.session.isAuthenticated && !this.session.data.userOrgs) {
        this.transitionTo('logout');
        return;
      }

      if (this.session.isAuthenticated && transition.targetName === 'logout') {
        this.transitionTo('logout');
        return;
      }

      if (this.session.isAuthenticated && !this.session.get('data.hasAcceptedTOS')) {
        this.transitionTo('welcome');
        return;
      }

      if (transition.targetName === 'reset-password') {
        // The start route figures out where to send the user
        // this.transitionTo('reset-password');
        return;
      }

      if (this.access.isDemo) {
        console.error('DEMO MODE');

        if (this.access.get('accessOrganization')) {
          _demoMode.updateOrganizationName.call(this);

          _demoMode.updateRoomNames.call(this);

          _demoMode.updateGeoNames.call(this);
        }
      }

      if (!this.session.get('data.hasGoneThroughStartRoute') && this.session.get('data.isAuthenticated')) {
        this.transitionTo('start');
        return;
      }

      if (transition.targetName === 'index') {
        this.transitionTo('start');
        return;
      }
    }

    async beforeModel(transition) {
      if (this.session.data.orgId) {
        await this._loadAccess(transition);
      } // fetch localizations from S3 bucket


      await _fetchLocalizations.fetchLocalizations.call(this, localStorage.getItem('language'));

      this._processDeferredQueryParams();

      this._setTimezone();

      this._setDateTimeLocale();
    }

    async model() {
      const orgId = this.session.data.orgId;

      if (orgId) {
        !_app.default.apps.length ? _app.default.initializeApp(_environment.default.firebase) : _app.default.app();
        return this.retrieveAccountExpiration(this.session, orgId);
      }
    } // /**
    //  * A hook for loading resources after the user logs in, but before loading the
    //  * route. This is only called when the session is authenticated (e.g. after
    //  * a user login) and not for every page load or route transition.
    //  *
    //  * @protected
    //  */
    // async sessionAuthenticated() {
    //   // NOTE: For async methods, Ember loses the reference to `_super` after an
    //   //       `await`. Saving a reference here so we can use it later.
    //   //       [twl 26.May.17]
    //   let { _super } = this;
    //
    //   this.transitionTo('loading');
    //
    //   if (this.session.data.orgId) {
    //     await this._loadAccess();
    //   }
    //
    //   this._processDeferredQueryParams();
    //
    //   // Call `sessionAuthenticated` in ApplicationRouteMixin, which retries any
    //   // pending transitions, or transitions to `routeAfterAuthentication`
    //   _super.call(this, ...arguments);
    // }
    // Loads the authentication and authorization for the current user in their
    // active role; if the user has not authenticated yet, does nothing.


    async _loadAccess(transition) {
      if (!this.session.isAuthenticated) {
        return;
      }

      try {
        await this.access.load(transition);
      } catch (e) {
        console.log('Invalidating user (100)'); // eslint-disable-line no-console

        this.session.invalidate();
        throw e;
      }
    } // Set local timezone for moment dates


    _setTimezone() {
      // Safari on ios9 does not support 'Intl', so we must have a fallback
      let timezone = 'America/Los_Angeles';

      try {
        timezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // eslint-disable-line new-cap

        this.moment.setTimeZone(timezone);
      } catch (e) {
        this.moment.setTimeZone(timezone);
      }
    } // Set locale for moment dates


    _setDateTimeLocale() {
      const locale = this.intl.get('locale');
      const l = _locale.momentLocaleMap[locale] ?? 'en';
      this.moment.setLocale(l);
    } // Processes any query parameter changes that were deferred before the session
    // was authenticated.


    _processDeferredQueryParams() {
      if (!this.session.get('isAuthenticated')) {
        return;
      }

      let queryParamChanges = this._deferredQueryParamChanges;

      while (queryParamChanges.length > 0) {
        let changeArgs = queryParamChanges.shiftObject();
        this.brush.queryParamsDidChange(...changeArgs);
      }
    } // A hook that is called whenever the query parameters for this route change.


    queryParamsDidChange() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      // When initially loading the page, this hook is called before the
      // `beforeModel` hook. Since the brush will immediately start loading
      // resources that require authentication, we defer the update here until
      // we've been authenticated.
      if (!this.session.get('isAuthenticated')) {
        this._deferredQueryParamChanges.pushObject(args);
      } else {
        this.brush.queryParamsDidChange(...args);
      }
    } // A hook for handling errors that occur in the application.


    error(error) {
      console.error('error!', error, this.session.get('isAuthenticated')); // Trap unhandled failures during routing:
      // Should maybe be handled by the sentry addon, but is not as of now:
      // https://github.com/damiencaselli/ember-cli-sentry/issues/105

      this.logr.captureException(error);

      if (error && !this.session.get('isAuthenticated')) {
        this.session.invalidate();
      }

      if (error && error.errors && error.errors.mapBy('status').includes('401')) {
        // console.error(error);
        let session = this.session;
        session.invalidate();
      } else {
        // Let the route above this handle the error.
        return true;
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "brush", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "moment", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "session", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "logr", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "devtools", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "_deferredQueryParamChanges", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "queryParamsDidChange", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "queryParamsDidChange"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "error", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "error"), _class.prototype)), _class));
  _exports.default = ApplicationRoute;
});