define("alliance-business-suite/templates/components/products/taxes-list-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ybq5lVh+",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"tax-table\"],[12],[2,\"\\n  \"],[8,\"models-table\",[],[[\"@data\",\"@columns\",\"@pageSize\",\"@multipleColumnsSorting\",\"@showComponentFooter\",\"@showColumnsDropdown\",\"@useFilteringByColumns\",\"@showGlobalFilter\",\"@doFilteringByHiddenColumns\",\"@useNumericPagination\",\"@filteringIgnoreCase\",\"@collapseNumPaginationForPagesCount\",\"@showPageSize\"],[[34,0],[34,1],[34,2],[34,3],[34,4],[34,5],[34,6],[34,7],[34,8],[34,9],[34,10],[34,11],[34,12]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"taxes\",\"columns\",\"pageSize\",\"multipleColumnsSorting\",\"showComponentFooter\",\"showColumnsDropdown\",\"useFilteringByColumns\",\"showGlobalFilter\",\"doFilteringByHiddenColumns\",\"useNumericPagination\",\"filteringIgnoreCase\",\"collapseNumPaginationForPagesCount\",\"showPageSize\"]}",
    "moduleName": "alliance-business-suite/templates/components/products/taxes-list-table.hbs"
  });

  _exports.default = _default;
});