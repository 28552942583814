define("alliance-business-suite/adapters/billing-history", ["exports", "alliance-business-suite/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    access: Ember.inject.service(),
    sessionPath: 'organizations/:organizationId/',

    pathForType(type) {
      return 'billing/history';
    },

    buildURL(modelName, id, snapshot, requestType, query) {
      let url = this._super(modelName, id, snapshot, requestType, query);

      let host = this.host;
      let organizationId = Ember.get(this, 'session.data.orgId');

      if (requestType === 'findAll') {
        url = `${host}/organizations/${organizationId}/billing/history`;
      }

      return url;
    }

  });

  _exports.default = _default;
});