define("alliance-business-suite/components/table/machines/table-cell-machine-current-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const TableMachinesTableCellMachineCurrentUser = Ember._templateOnlyComponent();

  var _default = TableMachinesTableCellMachineCurrentUser;
  _exports.default = _default;
});