define("alliance-business-suite/components/summary/reports/s-audit-common-diagnostic", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "alliance-business-suite/utils/report-tables-util"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _reportTablesUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  let SummaryReportsSAuditCommonDiagnosticComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, (_class = class SummaryReportsSAuditCommonDiagnosticComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "brand", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor4, this);
    }

    get translatedColumns() {
      if (this.access.isOpl) {
        return [{
          name: 'location_machine',
          valuePath: 'name',
          width: 400,
          isFixed: 'left'
        }, {
          name: 'date_range',
          valuePath: 'dateRange',
          width: 350
        }, {
          name: 'power_failures',
          valuePath: 'powerFailureAuditDataTotalNumberOfPowerFails',
          width: 180
        }, {
          name: 'infrared_errors',
          valuePath: 'irNetworkTotalNumberOfBadIRCommunications',
          width: 180
        }, {
          name: 'network_errors',
          valuePath: 'irNetworkTotalNumberOfBadNetworkCommunications',
          width: 180
        }];
      } else {
        return [{
          name: 'location_machine',
          valuePath: 'name',
          width: 400,
          isFixed: 'left'
        }, {
          name: 'date_range',
          valuePath: 'dateRange',
          width: 350
        }, {
          name: 'power_failures',
          valuePath: 'powerFailureAuditDataTotalNumberOfPowerFails',
          width: 180
        }, {
          name: 'coin_1_test',
          valuePath: 'vendAuditDataTotalNumberOfCoin1Test',
          width: 180
        }, {
          name: 'coin_2_test',
          valuePath: 'vendAuditDataTotalNumberOfCoin2Test',
          width: 180
        }, {
          name: 'coin_1_errors',
          valuePath: 'vendAuditDataTotalNumberOfCoin1Errors',
          width: 180
        }, {
          name: 'coin_2_errors',
          valuePath: 'vendAuditDataTotalNumberOfCoin2Errors',
          width: 180
        }, {
          name: 'card_errors',
          valuePath: 'cardRelatedTotalNumberOfFecGeneratedCardReaderErrors',
          width: 180
        }, {
          name: 'infrared_errors',
          valuePath: 'irNetworkTotalNumberOfBadIRCommunications',
          width: 180
        }, {
          name: 'network_errors',
          valuePath: 'irNetworkTotalNumberOfBadNetworkCommunications',
          width: 180
        }];
      }
    }

    get rowsWithChildren() {
      let {
        model
      } = this.args;
      let geoBoundaries = model.geoBoundaries;
      let rowData = [];
      geoBoundaries.forEach(geoBoundary => {
        let rowArray = [];
        let nameArray = [];
        let powerFailureSum = 0;
        let vendAuditTest1Sum = 0;
        let vendAuditTest2Sum = 0;
        let vendAuditError1Sum = 0;
        let vendAuditError2Sum = 0;
        let cardRelatedSum = 0;
        let badIRCommunicationsSum = 0;
        let badNetworkCommunicationsSum = 0;
        let totalPowerFailure = [];
        let totalAuditTest1 = [];
        let totalAuditTest2 = [];
        let totalAuditError1 = [];
        let totalAuditError2 = [];
        let cardRelatedTotal = [];
        let totalBadIR = [];
        let totalBadNetwork = [];
        rowData.push({
          name: _reportTablesUtil.localizeString.call(this, geoBoundary.name, 'Location', 'location'),
          children: nameArray,
          powerFailureAuditDataTotalNumberOfPowerFails: totalPowerFailure,
          vendAuditDataTotalNumberOfCoin1Test: totalAuditTest1,
          vendAuditDataTotalNumberOfCoin2Test: totalAuditTest2,
          vendAuditDataTotalNumberOfCoin1Errors: totalAuditError1,
          vendAuditDataTotalNumberOfCoin2Errors: totalAuditError2,
          cardRelatedTotalNumberOfFecGeneratedCardReaderErrors: cardRelatedTotal,
          irNetworkTotalNumberOfBadIRCommunications: totalBadIR,
          irNetworkTotalNumberOfBadNetworkCommunications: totalBadNetwork
        });
        geoBoundary.rooms.forEach(room => {
          let po1werFailureSum = 0;
          let ve1ndAuditTest1Sum = 0;
          let ve1ndAuditTest2Sum = 0;
          let ve1ndAuditError1Sum = 0;
          let ve1ndAuditError2Sum = 0;
          let ca1rdRelatedSum = 0;
          let ba1dIRCommunicationsSum = 0;
          let ba1dNetworkCommunicationsSum = 0;
          let to1talPowerFailure = [];
          let to1talAuditTest1 = [];
          let to1talAuditTest2 = [];
          let to1talAuditError1 = [];
          let to1talAuditError2 = [];
          let ca1rdRelatedTotal = [];
          let to1talBadIR = [];
          let to1talBadNetwork = [];
          nameArray.push({
            name: room.name,
            children: rowArray,
            powerFailureAuditDataTotalNumberOfPowerFails: to1talPowerFailure,
            vendAuditDataTotalNumberOfCoin1Test: to1talAuditTest1,
            vendAuditDataTotalNumberOfCoin2Test: to1talAuditTest2,
            vendAuditDataTotalNumberOfCoin1Errors: to1talAuditError1,
            vendAuditDataTotalNumberOfCoin2Errors: to1talAuditError2,
            cardRelatedTotalNumberOfFecGeneratedCardReaderErrors: ca1rdRelatedTotal,
            irNetworkTotalNumberOfBadIRCommunications: to1talBadIR,
            irNetworkTotalNumberOfBadNetworkCommunications: to1talBadNetwork
          });
          room.machineAuditGroupTypes.forEach(machineAuditType => {
            machineAuditType.machineTypes.forEach(machineType => {
              let machineArray = [];
              let machineTypeName = `${machineAuditType.name} - ${machineType.name}`;
              let po2werFailureSum = 0;
              let ve2ndAuditTest1Sum = 0;
              let ve2ndAuditTest2Sum = 0;
              let ve2ndAuditError1Sum = 0;
              let ve2ndAuditError2Sum = 0;
              let ca2rdRelatedSum = 0;
              let ba2dIRCommunicationsSum = 0;
              let ba2dNetworkCommunicationsSum = 0;
              let to2talPowerFailure = [];
              let to2talAuditTest1 = [];
              let to2talAuditTest2 = [];
              let to2talAuditError1 = [];
              let to2talAuditError2 = [];
              let ca2rdRelatedTotal = [];
              let to2talBadIR = [];
              let to2talBadNetwork = [];
              rowArray.push({
                name: machineTypeName,
                machineTypeName: machineType.name,
                children: machineArray,
                powerFailureAuditDataTotalNumberOfPowerFails: to2talPowerFailure,
                vendAuditDataTotalNumberOfCoin1Test: to2talAuditTest1,
                vendAuditDataTotalNumberOfCoin2Test: to2talAuditTest2,
                vendAuditDataTotalNumberOfCoin1Errors: to2talAuditError1,
                vendAuditDataTotalNumberOfCoin2Errors: to2talAuditError2,
                cardRelatedTotalNumberOfFecGeneratedCardReaderErrors: ca2rdRelatedTotal,
                irNetworkTotalNumberOfBadIRCommunications: to2talBadIR,
                irNetworkTotalNumberOfBadNetworkCommunications: to2talBadNetwork
              });
              machineType.machines.forEach(machine => {
                let machinePowerFailureArray = [];
                let machineCoin1TestArray = [];
                let machineCoin2TestArray = [];
                let machineCoin1ErrorsArray = [];
                let machineCoin2ErrorsArray = [];
                let machineCardReaderErrorsArray = [];
                let machineBadIRCommunicationsArray = [];
                let machineBadNetworkCommunicationsArray = [];
                let dateRange = "";

                if (this.brand.slug == 'primus') {
                  // fix the branding issue alliance-tasks/issues/2291
                  rowArray[rowArray.length - 1].name = `${machine.machineAuditType.name} - ${rowArray[rowArray.length - 1].machineTypeName}`;
                }

                if (machine.minFirstReceivedAt && machine.maxFirstReceivedAt) {
                  dateRange = `${moment(machine.minFirstReceivedAt).format(_reportTablesUtil.formatDateTimeString.call(this))} - ${moment(machine.maxFirstReceivedAt).format(_reportTablesUtil.formatDateTimeString.call(this))}`;
                }

                if (machine.powerFailureAuditDataTotalNumberOfPowerFails !== 0) {
                  machinePowerFailureArray.push(machine.powerFailureAuditDataTotalNumberOfPowerFails);
                  powerFailureSum = (0, _reportTablesUtil.sumArray)(machinePowerFailureArray, powerFailureSum);
                  po1werFailureSum = (0, _reportTablesUtil.sumArray)(machinePowerFailureArray, po1werFailureSum);
                  po2werFailureSum = (0, _reportTablesUtil.sumArray)(machinePowerFailureArray, po2werFailureSum);
                }

                if (machine.vendAuditDataTotalNumberOfCoin1Test !== 0) {
                  machineCoin1TestArray.push(machine.vendAuditDataTotalNumberOfCoin1Test);
                  vendAuditTest1Sum = (0, _reportTablesUtil.sumArray)(machineCoin1TestArray, vendAuditTest1Sum);
                  ve1ndAuditTest1Sum = (0, _reportTablesUtil.sumArray)(machineCoin1TestArray, ve1ndAuditTest1Sum);
                  ve2ndAuditTest1Sum = (0, _reportTablesUtil.sumArray)(machineCoin1TestArray, ve2ndAuditTest1Sum);
                }

                if (machine.vendAuditDataTotalNumberOfCoin2Test !== 0) {
                  machineCoin2TestArray.push(machine.vendAuditDataTotalNumberOfCoin2Test);
                  vendAuditTest2Sum = (0, _reportTablesUtil.sumArray)(machineCoin2TestArray, vendAuditTest2Sum);
                  ve1ndAuditTest2Sum = (0, _reportTablesUtil.sumArray)(machineCoin2TestArray, ve1ndAuditTest2Sum);
                  ve2ndAuditTest2Sum = (0, _reportTablesUtil.sumArray)(machineCoin2TestArray, ve2ndAuditTest2Sum);
                }

                if (machine.vendAuditDataTotalNumberOfCoin1Errors !== 0) {
                  machineCoin1ErrorsArray.push(machine.vendAuditDataTotalNumberOfCoin1Errors);
                  vendAuditError1Sum = (0, _reportTablesUtil.sumArray)(machineCoin1ErrorsArray, vendAuditError1Sum);
                  ve1ndAuditError1Sum = (0, _reportTablesUtil.sumArray)(machineCoin1ErrorsArray, ve1ndAuditError1Sum);
                  ve2ndAuditError1Sum = (0, _reportTablesUtil.sumArray)(machineCoin1ErrorsArray, ve2ndAuditError1Sum);
                }

                if (machine.vendAuditDataTotalNumberOfCoin2Errors !== 0) {
                  machineCoin2ErrorsArray.push(machine.vendAuditDataTotalNumberOfCoin2Errors);
                  vendAuditError2Sum = (0, _reportTablesUtil.sumArray)(machineCoin2ErrorsArray, vendAuditError2Sum);
                  ve1ndAuditError2Sum = (0, _reportTablesUtil.sumArray)(machineCoin2ErrorsArray, ve1ndAuditError2Sum);
                  ve2ndAuditError2Sum = (0, _reportTablesUtil.sumArray)(machineCoin2ErrorsArray, ve2ndAuditError2Sum);
                }

                if (machine.cardRelatedTotalNumberOfFecGeneratedCardReaderErrors !== 0) {
                  machineCardReaderErrorsArray.push(machine.cardRelatedTotalNumberOfFecGeneratedCardReaderErrors);
                  cardRelatedSum = (0, _reportTablesUtil.sumArray)(machineCardReaderErrorsArray, cardRelatedSum);
                  ca1rdRelatedSum = (0, _reportTablesUtil.sumArray)(machineCardReaderErrorsArray, ca1rdRelatedSum);
                  ca2rdRelatedSum = (0, _reportTablesUtil.sumArray)(machineCardReaderErrorsArray, ca2rdRelatedSum);
                }

                if (machine.irNetworkTotalNumberOfBadIRCommunications !== 0) {
                  machineBadIRCommunicationsArray.push(machine.irNetworkTotalNumberOfBadIRCommunications);
                  badIRCommunicationsSum = (0, _reportTablesUtil.sumArray)(machineBadIRCommunicationsArray, badIRCommunicationsSum);
                  ba1dIRCommunicationsSum = (0, _reportTablesUtil.sumArray)(machineBadIRCommunicationsArray, ba1dIRCommunicationsSum);
                  ba2dIRCommunicationsSum = (0, _reportTablesUtil.sumArray)(machineBadIRCommunicationsArray, ba2dIRCommunicationsSum);
                }

                if (machine.irNetworkTotalNumberOfBadNetworkCommunications !== 0) {
                  machineBadNetworkCommunicationsArray.push(machine.irNetworkTotalNumberOfBadNetworkCommunications);
                  badNetworkCommunicationsSum = (0, _reportTablesUtil.sumArray)(machineBadNetworkCommunicationsArray, badNetworkCommunicationsSum);
                  ba1dNetworkCommunicationsSum = (0, _reportTablesUtil.sumArray)(machineBadNetworkCommunicationsArray, ba1dNetworkCommunicationsSum);
                  ba2dNetworkCommunicationsSum = (0, _reportTablesUtil.sumArray)(machineBadNetworkCommunicationsArray, ba2dNetworkCommunicationsSum);
                }

                machineArray.push({
                  name: _reportTablesUtil.machineNameDisplay.call(this, machine.machineName, machine.machineNumber),
                  dateRange: dateRange,
                  powerFailureAuditDataTotalNumberOfPowerFails: machine.powerFailureAuditDataTotalNumberOfPowerFails || 0,
                  vendAuditDataTotalNumberOfCoin1Test: machine.vendAuditDataTotalNumberOfCoin1Test || 0,
                  vendAuditDataTotalNumberOfCoin2Test: machine.vendAuditDataTotalNumberOfCoin2Test || 0,
                  vendAuditDataTotalNumberOfCoin1Errors: machine.vendAuditDataTotalNumberOfCoin1Errors || 0,
                  vendAuditDataTotalNumberOfCoin2Errors: machine.vendAuditDataTotalNumberOfCoin2Errors || 0,
                  cardRelatedTotalNumberOfFecGeneratedCardReaderErrors: machine.cardRelatedTotalNumberOfFecGeneratedCardReaderErrors || 0,
                  irNetworkTotalNumberOfBadIRCommunications: machine.irNetworkTotalNumberOfBadIRCommunications || 0,
                  irNetworkTotalNumberOfBadNetworkCommunications: machine.irNetworkTotalNumberOfBadNetworkCommunications || 0
                });
              });
              to2talPowerFailure.push(po2werFailureSum);
              to2talAuditTest1.push(ve2ndAuditTest1Sum);
              to2talAuditTest2.push(ve2ndAuditTest2Sum);
              to2talAuditError1.push(ve2ndAuditError1Sum);
              to2talAuditError2.push(ve2ndAuditError2Sum);
              ca2rdRelatedTotal.push(ca2rdRelatedSum);
              to2talBadIR.push(ba2dIRCommunicationsSum);
              to2talBadNetwork.push(ba2dNetworkCommunicationsSum);
            });
          });
          to1talPowerFailure.push(po1werFailureSum);
          to1talAuditTest1.push(ve1ndAuditTest1Sum);
          to1talAuditTest2.push(ve1ndAuditTest2Sum);
          to1talAuditError1.push(ve1ndAuditError1Sum);
          to1talAuditError2.push(ve1ndAuditError2Sum);
          ca1rdRelatedTotal.push(ca1rdRelatedSum);
          to1talBadIR.push(ba1dIRCommunicationsSum);
          to1talBadNetwork.push(ba1dNetworkCommunicationsSum);
        });
        totalPowerFailure.push(powerFailureSum);
        totalAuditTest1.push(vendAuditTest1Sum);
        totalAuditTest2.push(vendAuditTest2Sum);
        totalAuditError1.push(vendAuditError1Sum);
        totalAuditError2.push(vendAuditError2Sum);
        cardRelatedTotal.push(cardRelatedSum);
        totalBadIR.push(badIRCommunicationsSum);
        totalBadNetwork.push(badNetworkCommunicationsSum);
      });
      return rowData;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "brand", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SummaryReportsSAuditCommonDiagnosticComponent;
});