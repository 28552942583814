define("alliance-business-suite/mixins/machines/programs/heater-temperature", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    //#region Properties
    temperatureValues: [{
      val: 13,
      F: 100,
      C: 37,
      Cfloor: 37
    }, {
      val: 14,
      F: 105,
      C: 41,
      Cfloor: 40
    }, {
      val: 15,
      F: 110,
      C: 43,
      Cfloor: 43
    }, {
      val: 16,
      F: 115,
      C: 46,
      Cfloor: 46
    }, {
      val: 17,
      F: 120,
      C: 49,
      Cfloor: 48
    }, {
      val: 18,
      F: 125,
      C: 52,
      Cfloor: 51
    }, {
      val: 19,
      F: 130,
      C: 54,
      Cfloor: 54
    }, {
      val: 20,
      F: 135,
      C: 57,
      Cfloor: 57
    }, {
      val: 21,
      F: 140,
      C: 60,
      Cfloor: 60
    }, {
      val: 22,
      F: 145,
      C: 63,
      Cfloor: 62
    }, {
      val: 23,
      F: 150,
      C: 66,
      Cfloor: 65
    }, {
      val: 24,
      F: 155,
      C: 68,
      Cfloor: 68
    }, {
      val: 25,
      F: 160,
      C: 71,
      Cfloor: 71
    }, {
      val: 26,
      F: 165,
      C: 74,
      Cfloor: 73
    }, {
      val: 27,
      F: 170,
      C: 77,
      Cfloor: 76
    }, {
      val: 28,
      F: 175,
      C: 79,
      Cfloor: 79
    }, {
      val: 29,
      F: 180,
      C: 82,
      Cfloor: 82
    }, {
      val: 30,
      F: 185,
      C: 85,
      Cfloor: 85
    }, {
      val: 31,
      F: 190,
      C: 88,
      Cfloor: 87
    }, {
      val: 32,
      F: 195,
      C: 91,
      Cfloor: 90
    }],
    temperatureUnits: [{
      val: true,
      label: 'Celsius'
    }, {
      val: false,
      label: 'Fahrenheit'
    }],
    temperatureAbbrev: Ember.computed('machineProgram.settings.Program.TemperatureScaleCelsius', function () {
      let isCelsius = Ember.get(this, 'machineProgram.settings.Program.TemperatureScaleCelsius');
      return isCelsius ? "C" : "F";
    }),
    // TODO: Confirm all machines use the same key
    isCelsius: Ember.computed.alias('machineProgram.settings.Program.TemperatureScaleCelsius') //#endregion

  });

  _exports.default = _default;
});