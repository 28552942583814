define("alliance-business-suite/helpers/find-label-by-index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Returns the property value of the element at `index` in the `array`.
   * @function
   * @name findOptions
   * @param {array} array - The array of data to search.
   * @param {number} index - The index of the array item to use.
   * @param {string} propertyName - The property returned from the element of the array. Default to `label`.
   * @return {string} - The value of the specified property in the array.
   *
      The provided array should look like this:
  
      optionsDefaultModifiers: [
        {
          machineAttributes: ["ACAS_A4", "ACAS_A44"], // the machine audit type
          options: [
            { val: 0, label: 'regular' },
            { val: 1, label: 'deluxe' },
            { val: 2, label: 'ultra' }
          ]
        }
      ]
  
      findOptions(optionsDefaultModifiers, 2) // returns 'ultra'
   */
  var _default = Ember.Helper.helper(function findOptions(params, hash) {
    let foundItems = hash.array;
    let propertyName = hash.propertyName || "label";

    if (hash.index === undefined) {
      // Return the array
      return foundItems.items;
    } else {
      // Return an item of the array based on index. In the future we may want to find based on `val` instead of index.
      if (foundItems === undefined) {
        console.error('Nothing found for find-label-by-index.');
      } else {
        return foundItems[hash.index][propertyName];
      }
    }
  });

  _exports.default = _default;
});