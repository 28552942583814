define("alliance-business-suite/serializers/system-alert", ["exports", "alliance-business-suite/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    access: Ember.inject.service(),

    serializeIntoHash(data, type, snapshot
    /* , options */
    ) {
      let userId = Ember.get(this, 'access.accessUser.id');
      Object.assign(data, snapshot.record.getProperties('systemAlertTypeKey', 'smsNumber', 'emailAddress'));
      data.user = {
        id: userId
      };
    }

  });

  _exports.default = _default;
});