define("alliance-business-suite/components/products/org-services-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    access: Ember.inject.service(),
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    classNames: ['services-section'],
    selectedService: null,

    didInsertElement() {
      this._super(...arguments);

      this.set('isInitialLoad', false);
      this.set('isLoading', true);
      this.fetchOrderSettingTypes();
      this.fetchOrderLineItemTypes();
    },

    _orderLineItemTypes: Ember.computed(function () {
      return this.store.peekAll('order-line-item-type');
    }),
    _orderSettingTypes: Ember.computed(function () {
      let selectedRoom = this.selectedRoom;
      return this.store.peekAll('order-setting-type');
    }),
    orderSettingsFiltered: Ember.computed('_orderSettingTypes.[]', 'selectedRoom', '_orderLineItemTypes.[]', function () {
      let orderSettings = this._orderSettingTypes; // Need to leave this to initialize the computed property above

      let orderLineItemTypes = this._orderLineItemTypes; // Need to leave this to initialize the computed property above

      let result = [];

      if (orderLineItemTypes) {
        orderLineItemTypes.forEach(l => {
          result.push(l);
        });
      }

      if (orderSettings) {
        orderSettings.forEach(o => {
          result.push(o);
        });
      }

      return result;
    }),

    async fetchOrderSettingTypes() {
      let self = this;
      await this.store.findAll('order-setting-type', {
        reload: true
      });
      self.notifyPropertyChange('_orderSettingTypes');

      if (!self.isDestroyed) {
        this.set('isLoading', false);
      }
    },

    async fetchOrderLineItemTypes() {
      let self = this;
      await this.store.findAll('order-line-item-type', {
        reload: true
      });
      self.notifyPropertyChange('_orderLineItemTypes');

      if (!self.isDestroyed) {
        this.set('isLoading', false);
      }
    },

    actions: {
      refresh() {// this.set('isLoading', true);
        // this.fetchOrderSettingTypes();
        // this.fetchOrderLineItemTypes();
      },

      toggleCreateModal() {
        this.toggleProperty('viewCreateModal');
      },

      toggleEditModal(service) {
        if (service) {
          this.set('selectedService', service);
        }

        this.toggleProperty('viewEditModal');
      }

    }
  });

  _exports.default = _default;
});