define("alliance-business-suite/components/form-ui/lucky-cycle", ["exports", "alliance-business-suite/mixins/init-property-names", "alliance-business-suite/mixins/show-component"], function (_exports, _initPropertyNames, _showComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_showComponent.default, _initPropertyNames.default, {
    luckyCycleLabel: Ember.computed('machineAuditType', function () {
      // TODO: Change this to be based on brand instead of audit type
      if (this?.machineProgram?._machineAttributes.includes("HUEBSCH")) {
        return "bonus_cycle";
      } else {
        return "lucky_cycle";
      }
    }),
    actions: {
      showModal() {
        Ember.set(this, 'showModal', true);
      },

      hideModal() {
        Ember.set(this, 'showModal', false);
      }

    }
  });

  _exports.default = _default;
});