define("alliance-business-suite/components/rewards/deposit-rewards-container", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'activeDepositReward.depositThreshold': [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gt: 0
    })],
    'activeDepositReward.depositFlatAmount': [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gt: 0
    })]
  });

  var _default = Ember.Component.extend(Validations, {
    intl: Ember.inject.service(),
    access: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    currencySymbol: Ember.computed(function () {
      return Ember.get(this, 'access.currencyDenomination');
    }),
    currencyDecimals: Ember.computed(function () {
      if (this.get('access.currencySubunit') === 1) {
        return 0;
      } else {
        return 2;
      }
    }),
    sortedDepositRewards: Ember.computed('depositRewards.[]', 'depositRewards.@each.depositThreshold', function () {
      return this.depositRewards.sortBy('depositThreshold');
    }),
    actions: {
      toggleModal(depositReward) {
        if (this.access.isDemo) {
          alert('Demo mode restricted.');
          return;
        }

        if (this.activeDepositReward) {
          this.activeDepositReward.rollbackAttributes();
        }

        this.set('activeDepositReward', depositReward);
        this.toggleProperty('showModal');
      },

      addDepositReward() {
        let depositReward = this.store.createRecord('deposit-reward', {
          rewardsProgram: this.rewardsProgram
        });
        this.send('toggleModal', depositReward);
      },

      saveDepositReward(depositReward) {
        if (this.access.isDemo) {
          alert('Demo mode restricted.');
          return;
        }

        let self = this;
        this.set('isLoading', true);
        depositReward.save().then(function () {
          self.send('toggleModal');
          self.set('isLoading', false);
          self.get('notifications').info(self.get('intl').t('deposit_reward_saved'), {
            autoClear: true,
            clearDuration: 2000
          });
        }).catch((error
        /* , jqXHR */
        ) => {
          self.send('toggleModal');
          self.set('isLoading', false);
          self.get('notifications').info(self.get('intl').t('deposit_reward_saved'), {
            autoClear: true,
            clearDuration: 2000
          });
          return error;
        });
      },

      deleteDepositReward(depositReward) {
        if (this.access.isDemo) {
          alert('Demo mode restricted.');
          return;
        }

        let self = this;
        this.set('isLoading', true);
        depositReward.deleteRecord();
        depositReward.save().then(function () {
          self.send('toggleModal');
          self.set('isLoading', false);
          self.get('notifications').error(self.get('intl').t('deposit_reward_deleted'), {
            autoClear: true,
            clearDuration: 2000
          }); // avoid error from ember of duplicate records

          self.store.unloadRecord(depositReward);
        });
      }

    }
  });

  _exports.default = _default;
});