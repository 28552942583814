define("alliance-business-suite/templates/components/machines/programs/cycles/ui/step-water", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9Bvp7XgK",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[32,0,[\"showStepWater\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,3],[[30,[36,4],[[32,0,[\"stepType\"]],2],null]],null,[[\"default\"],[{\"statements\":[[6,[37,3],[[30,[36,2],[[32,0,[\"activeState\"]],[30,[36,1],[[32,0,[\"modStates\"]],8],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"span\"],[14,0,\"label-water-hot\"],[12],[1,[32,0,[\"totalWater\"]]],[2,\" \"],[1,[32,0,[\"units\"]]],[2,\" / \"],[1,[32,0,[\"hotWater\"]]],[2,\" \"],[1,[30,[36,0],[\"hot\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"append\",\"contains\",\"if\",\"eq\"]}",
    "moduleName": "alliance-business-suite/templates/components/machines/programs/cycles/ui/step-water.hbs"
  });

  _exports.default = _default;
});