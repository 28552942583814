define("alliance-business-suite/adapters/campaign", ["exports", "alliance-business-suite/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    /**
     * The path to use when restricting the data returned from this adapter based
     * on a session property.
     *
     * @type {string}
     * @public
     */
    sessionPath: 'organizations/:organizationId/',

    /**
     * Builds the URL for a request.
     *
     * @public
     */
    // TODO - Work with team to use proper end point
    buildURL(modelName, id, snapshot, requestType, query) {
      let url = this._super(modelName, id, snapshot, requestType, query);

      let organizationId = this.get('access.accessOrganization.id');

      switch (requestType) {
        case 'createRecord':
          url = url.replace('campaigns', `organizations/${organizationId}/campaigns`);
          break;

        case 'findAll':
          url = url.replace('campaigns', `organizations/${organizationId}/campaigns?include=runs`);
          break;

        case 'findRecord':
          url = url.replace(`campaigns/${id}`, `organizations/${organizationId}/campaigns?id=${id}&include=runs`);
          break;

        case 'updateRecord':
          url = url.replace(`campaigns/${id}`, `organizations/${organizationId}/campaigns?campaignId=${id}`);
          break;

        case 'deleteRecord':
          url = url.replace(`campaigns/${id}`, `organizations/${organizationId}/campaigns?campaignId=${id}`);
          break;
      }

      return url;
    }

  });

  _exports.default = _default;
});