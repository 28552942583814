define("alliance-business-suite/templates/components/form-ui/checkbox-number-combo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "s46uzCyT",
    "block": "{\"symbols\":[\"ui\"],\"statements\":[[8,\"form-ui\",[],[[\"@object\",\"@formDisplay\",\"@formSettings\"],[[32,0,[\"object\"]],\"compact\",[32,0,[\"formSettings\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[8,[32,1,[\"checkbox\"]],[],[[\"@params\"],[[30,[36,1],null,[[\"propertyName\",\"label\"],[[32,0,[\"properties\",\"checkbox\",\"key\"]],[32,0,[\"properties\",\"checkbox\",\"label\"]]]]]]],null],[2,\"\\n\\n\"],[6,[37,3],[[30,[36,2],[[32,0,[\"params\",\"numberType\"]],\"currency\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,[32,1,[\"currency\"]],[],[[\"@params\"],[[30,[36,1],null,[[\"propertyName\",\"label\",\"enabled\"],[[32,0,[\"properties\",\"number\",\"key\"]],[32,0,[\"properties\",\"number\",\"label\"]],[30,[36,0],[[32,0,[\"object\"]],[32,0,[\"properties\",\"checkbox\",\"key\"]]],null]]]]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,[32,1,[\"number\"]],[],[[\"@params\"],[[30,[36,1],null,[[\"propertyName\",\"label\",\"enabled\"],[[32,0,[\"properties\",\"number\",\"key\"]],[32,0,[\"properties\",\"number\",\"label\"]],[30,[36,0],[[32,0,[\"object\"]],[32,0,[\"properties\",\"checkbox\",\"key\"]]],null]]]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"get\",\"hash\",\"eq\",\"if\"]}",
    "moduleName": "alliance-business-suite/templates/components/form-ui/checkbox-number-combo.hbs"
  });

  _exports.default = _default;
});