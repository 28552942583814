define("alliance-business-suite/components/form-ui/dev-tools-step-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    devtools: Ember.inject.service(),
    showTools: Ember.computed('devtools.devToolsEnabled', function () {
      return Ember.get(this, 'devtools.devToolsEnabled');
    })
  });

  _exports.default = _default;
});