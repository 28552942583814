define("alliance-business-suite/components/form-ui/duration-field/states/minutes-focused", ["exports", "alliance-business-suite/components/form-ui/duration-field/utils/state", "alliance-business-suite/components/form-ui/duration-field/utils/codes"], function (_exports, _state, _codes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _state.default.extend({
    initialState: "digit1",
    digit1: (0, _state.state)(_state.default, {
      key(manager, code) {
        if (!(0, _codes.isNumberCode)(code)) {
          return; // no-op
        }

        const num = (0, _codes.keyCodeToNumber)(code);
        manager.get("input").setMinutes(num);

        if (num <= 5) {
          manager.transitionTo("digit2");
        } else if (manager.get("input.hour12")) {
          manager.transitionTo("hours");
        }
      }

    }),
    digit2: (0, _state.state)(_state.default, {
      key(manager, code) {
        if (!(0, _codes.isNumberCode)(code)) {
          return; // no-op
        }

        const num = (0, _codes.keyCodeToNumber)(code);
        manager.get("input").setMinutesDigit2(num);
        manager.transitionTo("digit1");
      }

    }),

    enter(manager) {
      this.focusIn(manager);
    },

    focusIn(manager) {
      manager.get("input").selectMinutes();
    },

    left(manager) {
      if (this.get('manager.showHours')) {
        manager.transitionTo("hours");
      }
    },

    right(manager) {
      manager.transitionTo("seconds");
    },

    up(manager) {
      manager.get("input").incrementMinutes();
    },

    down(manager) {
      manager.get("input").decrementMinutes();
    },

    key(manager, code) {
      if (!(0, _codes.isNumberCode)(code)) {
        return; // no-op
      }

      const num = (0, _codes.keyCodeToNumber)(code);
      manager.get("input").setMinutes(num);

      if (num <= 5) {
        manager.transitionTo("digit2");
      } else if (manager.get("input.hour12")) {
        manager.transitionTo("seconds");
      }
    }

  });

  _exports.default = _default;
});