define("alliance-business-suite/routes/retail/services-list", ["exports", "@babel/runtime/helpers/esm/defineProperty", "alliance-business-suite/routes/_secureRoute"], function (_exports, _defineProperty2, _secureRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class RetailServicesListRoute extends _secureRoute.default {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "allowAccess", 'SERVICES_VIEW_PAGE');
    }

  }

  _exports.default = RetailServicesListRoute;
});