define("alliance-business-suite/components/employees/roles/group-permissions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    notifications: Ember.inject.service(),
    intl: Ember.inject.service(),
    itemId: null,
    currentIndent: Ember.computed('indent', function () {
      return parseInt(this.indent) + 20;
    }),
    currentIndentPx: Ember.computed('indent', function () {
      return `${this.currentIndent}px`;
    }),
    items: Ember.computed('securityFunctionalAreas', function () {
      if (!this.securityFunctionalAreas) {
        return null;
      }

      return this.securityFunctionalAreas.filter(item => {
        return Ember.get(item, 'parentId') == this.itemId;
      });
    }),
    rolesList: Ember.computed('selectedRole', 'roles.[]', function () {
      // Array of all roles EXCEPT THE CURRENT ROLE
      let test = this.get('selectedRole.securityRoles.lastObject');
      return this.roles.without(this.selectedRole);
    })
  });

  _exports.default = _default;
});