define("alliance-business-suite/components/machines/programs/pricing/midas-wx-modal-cycle-order", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
    TODO: This entire thing should be reviewed...it was initially hard-coded and fragile.
    Things were updated 2021-08-21 to fix ticket 2824, where the custom cycle couldn't be
    set as the default.
  */
  var _default = Ember.Component.extend({
    didInsertElement() {
      let self = this;

      this._super(...arguments);

      (0, _jquery.default)("tbody").sortable({
        axis: "y",

        stop(event, ui) {
          self.updateCycleArray(event, ui);
        }

      });
    },

    updateCycleArray(event) {
      let currentCycleOrder = event.target.children;
      let newCycleOrder = [];
      let disabledCycles = this.object.Cycles.filterBy('Enabled', false);
      let cycles = this.object.Cycles; // Get all the sorted, active cycles

      for (let item of currentCycleOrder) {
        newCycleOrder.push(cycles.findBy('Name', item.innerText).Number);
      } // Get all the inactive cycles


      disabledCycles.forEach(item => {
        newCycleOrder.push(item.Number);
      }); // Update the property

      Ember.set(this, 'object.Program.Misc.CycleRibonConfig_WX', newCycleOrder);
      this.notifyPropertyChange("object.Program.Misc.CycleRibonConfig_WX");
    },

    cycleDisplayList: Ember.computed('object.{Cycles,Program.Misc.CycleRibonConfig_WX}', function () {
      // The CycleRibon property is the sorted list of all cycles
      let sortedCycleIds = this.object.Program.Misc.CycleRibonConfig_WX;
      let cycles = this.object.Cycles;
      let initialDisplay = []; // Loop through to get the Cycle.Name to be displayed

      sortedCycleIds.forEach(id => {
        let cycle = cycles.findBy('Number', id);

        if (cycle && cycle.Enabled) {
          initialDisplay.push(cycle.Name);
        }
      });
      return initialDisplay;
    })
  });

  _exports.default = _default;
});