define("alliance-business-suite/components/services/org-services-create", ["exports", "jquery", "alliance-business-suite/config/environment", "ember-cp-validations"], function (_exports, _jquery, _environment, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'model.description': [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })]
  });
  const SERVICE_TYPES = [{
    id: 'order-setting-type',
    text: 'order_setting_type',
    subtitle: 'subtitle_order_setting_type'
  }, {
    id: 'order-line-item-type',
    text: 'order_line_item_type',
    subtitle: 'subtitle_order_line_item_type'
  }];

  var _default = Ember.Component.extend(Validations, {
    access: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    isSaving: false,
    model: null,
    currentType: null,
    serviceTypes: Ember.computed(function () {
      return SERVICE_TYPES;
    }).readOnly(),
    actions: {
      updatedServiceType(type) {
        let model = this.model;

        if (model && model.get('hasDirtyAttributes')) {
          model.deleteRecord();
        }

        let newItem = this.store.createRecord(type.id);
        this.set('model', newItem);
        this.set('selectedServiceType', type);
        this.set('currentType', type.id);
      },

      exit(model) {
        if (model && model.get('hasDirtyAttributes')) {
          model.rollbackAttributes();
        }

        this.close();
      },

      save(model) {
        let self = this;
        this.set('isSaving', true);
        model.save().then(function () {
          self.set('isSaving', false);
          Ember.get(self, 'notifications').info(self.get('intl').t('successfully_created'), {
            autoClear: true,
            clearDuration: 5000
          });
          self.close();
        }, function () {
          self.set('isSaving', false);
          Ember.get(self, 'notifications').error(self.get('intl').t('save_failed'));
        });
      }

    }
  });

  _exports.default = _default;
});