define("alliance-business-suite/helpers/has-access", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Returns whether the session has access to a feature.
   *
   * @public
   */
  var _default = Ember.Helper.extend({
    /**
     * The service that manages the access control for this application.
     *
     * @protected
     */
    access: Ember.inject.service(),

    /**
     * Initialize this helper.
     *
     * @protected
     */
    init() {
      this._super(...arguments);

      this.access.on('accessChanged', this.accessChanged.bind(this));
    },

    /**
     * Handle when the access has changed within the access service.
     *
     * @protected
     */
    accessChanged() {
      this.recompute();
    },

    /**
     * Computes whether the current session (e.g. user and role) has access to a
     * feature.
     *
     * @param {string} feature The feature to test
     *
     * @return {boolean} true if the session has access to this feature; false
     *                   otherwise
     * @public
     */
    compute(_ref) {
      let [feature] = _ref;
      return this.access.hasAccess(feature);
    }

  });

  _exports.default = _default;
});