define("alliance-business-suite/adapters/organization", ["exports", "alliance-business-suite/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    session: Ember.inject.service(),

    buildURL(modelName, id, snapshot, requestType, query) {
      let url = this._super(modelName, id, snapshot, requestType, query);

      let organizationId = Ember.get(this, 'session.data.orgId');

      switch (requestType) {
        case 'queryRecord':
          url += `/${organizationId}/geoBoundaries/`;
          return url;
      }

      return url;
    }

  });

  _exports.default = _default;
});