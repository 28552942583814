define("alliance-business-suite/components/ui/als-currency-display", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "alliance-business-suite/utils/currency-format-util"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _currencyFormatUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{this.formattedValue}}
  
  */
  {
    "id": "bDie1msY",
    "block": "{\"symbols\":[],\"statements\":[[1,[32,0,[\"formattedValue\"]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "alliance-business-suite/components/ui/als-currency-display.hbs"
  });

  let UiAlsCurrencyDisplayComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = class UiAlsCurrencyDisplayComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "session", _descriptor2, this);
    }

    get formattedValue() {
      return _currencyFormatUtil.formatCurrencyString.call(this, this.args.value || 0);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = UiAlsCurrencyDisplayComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, UiAlsCurrencyDisplayComponent);
});