define("alliance-business-suite/utils/number-to-hour", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const AM_DISPLAY = 'AM';
  const PM_DISPLAY = 'PM';

  var _default = hour => {
    let output = '';
    let startHour = hour;
    let ampm = AM_DISPLAY;

    if (startHour >= 24) {
      startHour -= 24;
    }

    if (startHour >= 12) {
      ampm = PM_DISPLAY;
    }

    if (startHour > 12) {
      startHour -= 12;
    }

    if (startHour == 0) {
      output += 'Midnight';
    } else if (startHour == 12) {
      output += 'Noon';
    } else {
      output += `${startHour}${ampm}`;
    }

    return output;
  };

  _exports.default = _default;
});