define("alliance-business-suite/adapters/sales-goal", ["exports", "alliance-business-suite/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    buildURL(modelName, id, snapshot, requestType, query) {
      let url = this._super(modelName, id, snapshot, requestType, query);

      switch (requestType) {
        case 'findAll':
          url = `${this.host}/securityRoles/salesGoal`;
          break;

        case 'updateRecord':
          url = `${this.host}/securityRoles/${id}/salesGoal`;
          break;

        case 'destroyRecord':
        case 'deleteRecord':
          url = `${this.host}/securityRoles/${id}/salesGoal`;
          break;
      }

      return url;
    }

  });

  _exports.default = _default;
});