define("alliance-business-suite/routes/retail/inventory", ["exports", "@babel/runtime/helpers/esm/defineProperty", "alliance-business-suite/routes/_secureRoute"], function (_exports, _defineProperty2, _secureRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class RetailInventoryRoute extends _secureRoute.default {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "allowAccess", 'PRODUCTS_LIST_VIEW_PAGE');
    }

    model(params) {
      return Ember.RSVP.hash({
        items: this.store.findAll('item'),
        inventory: this.store.peekAll('inventory'),
        taxes: this.store.findAll('tax-rate')
      });
    }

  }

  _exports.default = RetailInventoryRoute;
});