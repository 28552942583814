define("alliance-business-suite/templates/components/ui/section-block", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "03nmckP+",
    "block": "{\"symbols\":[\"panel\",\"&default\"],\"statements\":[[6,[37,1],[[30,[36,0],null,[[\"ifMachine\",\"notMachine\",\"machineProgram\"],[[32,0,[\"ifMachine\"]],[32,0,[\"notMachine\"]],[32,0,[\"machineProgram\"]]]]]],null,[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"ui/section-panel\",[],[[\"@type\"],[\"dialog-section\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"header\"]],[],[[\"@title\",\"@checkbox\",\"@showCheckbox\"],[[32,0,[\"titleIntl\"]],[32,0,[\"checkbox\"]],[32,0,[\"showCheckbox\"]]]],null],[2,\"\\n    \"],[8,[32,1,[\"contents\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[18,2,null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"show-machine\",\"if\"]}",
    "moduleName": "alliance-business-suite/templates/components/ui/section-block.hbs"
  });

  _exports.default = _default;
});