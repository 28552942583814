define("alliance-business-suite/components/brushes/room-brush", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * The tag name used for this component. Since this just proxies through to
     * other components, this is an empty string. This prevents a DOM element
     * from being created, instead rendering the template for this component
     * directly underneath its parent.
     *
     * @type {string}
     * @protected
     */
    tagName: ''
  });

  _exports.default = _default;
});