define("alliance-business-suite/templates/components/table/machines/table-cell-machine-name-pill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KBO/25ct",
    "block": "{\"symbols\":[\"@record\"],\"statements\":[[10,\"div\"],[14,0,\"machine-type-icon\"],[12],[2,\"\\n  \"],[1,[32,1,[\"formattedMachineDisplayName\"]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "alliance-business-suite/templates/components/table/machines/table-cell-machine-name-pill.hbs"
  });

  _exports.default = _default;
});