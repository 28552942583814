define("alliance-business-suite/components/billing/billing-history", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  let BillingBillingHistoryComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, (_class = class BillingBillingHistoryComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "session", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "ajax", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor4, this);
      (0, _defineProperty2.default)(this, "showComponentFooter", false);
      (0, _defineProperty2.default)(this, "showColumnsDropdown", false);
      (0, _defineProperty2.default)(this, "useFilteringByColumns", false);
      (0, _defineProperty2.default)(this, "showGlobalFilter", false);
      (0, _defineProperty2.default)(this, "useNumericPagination", false);
      (0, _defineProperty2.default)(this, "doFilteringByHiddenColumns", false);
      (0, _defineProperty2.default)(this, "filteringIgnoreCase", false);
      (0, _defineProperty2.default)(this, "multipleColumnsSorting", false);
      (0, _defineProperty2.default)(this, "showPageSize", false);
      (0, _defineProperty2.default)(this, "collapseNumPaginationForPagesCount", 1);
      (0, _defineProperty2.default)(this, "pageSize", 100);
    }

    get columns() {
      return [{
        propertyName: 'roomName',
        title: this.intl.t('location')
      }, {
        propertyName: 'alsProductTypeLookupKey',
        title: this.intl.t('subscription_plan'),
        prependBrand: true,
        component: 'table/table-cell-localize-text'
      }, {
        propertyName: 'createdAt',
        title: this.intl.t('purchased'),
        customDateFormat: 'MM/DD/YYYY',
        component: 'table/table-cell-date',
        sortDirection: 'desc',
        sortPrecedence: 1
      }, {
        propertyName: 'totalAmount',
        component: 'table/table-cell-price',
        title: this.intl.t('amount')
      }, {
        propertyName: 'receiptUrl',
        component: 'table/table-cell-external-url',
        title: ''
      }];
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "ajax", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = BillingBillingHistoryComponent;
});