define("alliance-business-suite/adapters/item-category", ["exports", "alliance-business-suite/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    buildURL(modelName, id, snapshot, requestType, query) {
      let url = this._super('', null, snapshot, requestType, query);

      switch (requestType) {
        case 'findAll':
          url += `/items/categories`;
          break;

        case 'createRecord':
          url += `/items/categories`;
          break;

        case 'deleteRecord':
          url += `/items/categories/${id}`;
          break;

        case 'updateRecord':
          url += `/items/categories/${id}`;
          break;

        default:
          url += `/items/categories`;
      }

      return url;
    }

  });

  _exports.default = _default;
});