define("alliance-business-suite/templates/components/header-sub-nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CxL2h3Uf",
    "block": "{\"symbols\":[\"tab\",\"@subnavTabs\"],\"statements\":[[10,\"div\"],[14,0,\"sub-menu vd-toggle\"],[12],[2,\"\\n  \"],[10,\"ul\"],[14,0,\"nav nav--secondary\"],[12],[2,\"\\n\"],[6,[37,11],[[30,[36,10],[[30,[36,10],[[32,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[30,[36,5],[[32,1,[\"override\"]],[30,[36,9],[[30,[36,7],[[32,1,[\"hideExperimetal\"]]],null],[30,[36,5],[[30,[36,8],[[32,0,[\"brand\",\"slug\"]],[32,1,[\"allowBrandSlug\"]]],null],[30,[36,7],[[30,[36,6],[[32,1,[\"allowBrandSlug\"]]],null]],null]],null],[30,[36,5],[[30,[36,2],[[32,1,[\"allowAccess\"]]],null],[32,0,[\"securityDebugging\"]]],null]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"link-to\",[[16,0,[30,[36,3],[\"sub-tab can-access-\",[30,[36,2],[[32,1,[\"allowAccess\"]]],null],\" \",[30,[36,1],[[30,[36,0],[[32,1,[\"link\"]],[32,0,[\"currentRouteName\"]]],null],\"active\",\"\"],null]],null]]],[[\"@route\",\"@tagName\"],[[32,1,[\"link\"]],\"li\"]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[30,[36,4],[[32,1,[\"title\"]]],null]],[2,\"\\n          \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\",\"can-access\",\"concat\",\"t\",\"or\",\"is-array\",\"not\",\"contains\",\"and\",\"-track-array\",\"each\"]}",
    "moduleName": "alliance-business-suite/templates/components/header-sub-nav.hbs"
  });

  _exports.default = _default;
});