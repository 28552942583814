define("alliance-business-suite/models/item", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;

  var _default = Model.extend({
    organization: belongsTo(),
    description: attr('string'),
    catalogName: attr('string'),
    controlNumber: attr('string'),
    itemTypeLookupKey: attr('string'),
    imageKey: attr('string'),
    imageUrl: attr('string'),
    totalInventory: attr('number'),
    ordinal: attr('number'),
    inventory: hasMany('inventory'),
    reorderGuidelines: hasMany('reorder-guideline'),
    itemCategory: belongsTo('item-category'),
    createdBy: belongsTo('user', {
      inverse: null
    }),
    itemGuideline: Ember.computed('reorderGuidelines.[]', function () {
      let guidelines = this.reorderGuidelines;
      let itemGuideline;
      guidelines.forEach(function (guideline) {
        if (!guideline.get('room.id')) {
          itemGuideline = guideline;
        }
      });
      return itemGuideline;
    })
  });

  _exports.default = _default;
});