define("alliance-business-suite/mixins/modals", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    showModal: false,
    actions: {
      showModal() {
        Ember.set(this, 'showModal', true);
      },

      hideModal() {
        Ember.set(this, 'showModal', false);
      }

    }
  });

  _exports.default = _default;
});