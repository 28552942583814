define("alliance-business-suite/helpers/moment-is-same", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (params) {
    let punchOutDay = moment(params[0].punchOutDate).format('DD');
    let punchOutYear = moment(params[0].punchOutDate).format('YYYY');
    let punchInDay = moment(params[0].punchInDate).format('DD');
    let punchInYear = moment(params[0].punchInDate).format('YYYY');
    let a = moment([Number(punchOutYear), 0, Number(punchOutDay)]);
    let b = moment([Number(punchInYear), 0, Number(punchInDay)]);
    let difference = a.diff(b, 'days');
    let sameDayCheck = moment(params[0].punchInDate).isSame(`${params[0].punchOutDate}`, 'day');

    if (sameDayCheck === true) {
      difference = 0;
    } else {
      difference = `+${difference}`;
    }

    return difference;
  });

  _exports.default = _default;
});