define("alliance-business-suite/components/rewards/location-rewards", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    access: Ember.inject.service()
  });

  _exports.default = _default;
});