define("alliance-business-suite/models/campaign", ["exports", "ember-data", "alliance-business-suite/mixins/timezones"], function (_exports, _emberData, _timezones) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    hasMany,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    name: attr('string'),
    filter: attr('string'),
    campaignTypeLookupKey: attr('string'),
    deliveryMethodLookupKeys: attr(),
    deliveryMethodMessage: attr(),
    deliveryMethodBodyText: attr('string'),
    deliveryMethodBodyTextSMS: attr('string'),
    deliveryMethodBodyTextInApp: attr('string'),
    deliveryMethodBodyTextHtml: attr('string'),
    deliveryMethodBodyTextPlain: attr('string'),
    deliveryMethodSubjectTextPlain: attr('string'),
    deliveryMethodSubjectTextHtml: attr('string'),
    deliveryMethodReplyEmailText: attr('string'),
    deliveryMethodReplyEmailHtml: attr('string'),
    deliveryMethodFromNameText: attr('string'),
    deliveryMethodFromNameHtml: attr('string'),
    deliveryMethodFromEmailText: attr('string'),
    deliveryMethodFromEmailHtml: attr('string'),
    deliveryMethodFromName: attr('string'),
    deliveryMethodFromEmail: attr('string'),
    deliveryMethodReplyEmail: attr('string'),
    deliveryMethodSubjectText: attr('string'),
    isOneTimeOnly: attr('boolean'),
    isApproved: attr('boolean'),
    interval: attr('string'),
    createdAt: attr('string'),
    startDate: attr('string'),
    startTime: attr('string'),
    endDate: attr('string'),
    timeZone: attr('string'),
    nextRunDate: attr('string'),
    lastSentDate: attr('string'),
    rooms: hasMany('room'),
    campaignRuns: hasMany('campaign-run'),
    campaignPromotion: belongsTo('campaign-promotion'),
    deliveryMethodLookupKey: hasMany('delivery-method-lookup-key'),
    deliveryMethodMessages: hasMany('delivery-method-message'),
    hasPromotion: Ember.computed('campaignPromotion', {
      get() {
        return this.get('campaignPromotion.promotionCode') ? true : false;
      }

    }),
    nextRunFormatted: Ember.computed('nextRunDate', {
      get() {
        return moment.utc(this.nextRunDate).tz(this.timeZone).format('LLLL');
      }

    }),
    timeZoneObject: Ember.computed('timeZone', {
      get() {
        let timeZone;

        for (let i = 0; i < _timezones.timeZones.length; i++) {
          if (_timezones.timeZones[i].utc.indexOf(this.timeZone) !== -1) {
            timeZone = _timezones.timeZones[i];
          }
        }

        return timeZone;
      }

    })
  });

  _exports.default = _default;
});