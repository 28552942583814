define("alliance-business-suite/templates/components/form-ui/steps/heater-options-temperature", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ai2fAaj3",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[8,\"form-ui\",[],[[\"@object\",\"@formSettings\",\"@selectOptions\"],[[32,0,[\"object\"]],[32,0,[\"formSettings\"]],[32,0,[\"selectOptions\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[10,\"h2\"],[12],[1,[30,[36,2],[\"heater_options\"],null]],[13],[2,\"\\n\\n    \"],[8,\"form-ui/dev-tools\",[],[[\"@outputs\"],[[30,[36,1],null,[[\"Temperature\",\"CustomTemperature\",\"hasCustom\",\"isSharedProperty\",\"hasCustomProperty\"],[[32,0,[\"Temperature\"]],[32,0,[\"CustomTemperature\"]],[32,0,[\"hasCustom\"]],[32,0,[\"isSharedProperty\"]],[32,0,[\"hasCustomProperty\"]]]]]]],null],[2,\"\\n\\n\"],[6,[37,3],[[32,0,[\"isSharedProperty\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[8,[32,1,[\"select\"]],[],[[\"@params\"],[[30,[36,1],null,[[\"propertyName\",\"label\",\"options\",\"hasCustom\",\"sharedCustomProperty\",\"customType\",\"showPresets\",\"disabled\",\"min\",\"max\"],[[32,0,[\"properties\",\"Temperature\",\"key\"]],\"temperature\",\"HeaterOptionsTemperature\",true,true,\"temperature\",true,[32,0,[\"isDisabled\"]],35,194]]]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[8,[32,1,[\"select\"]],[],[[\"@params\"],[[30,[36,1],null,[[\"propertyName\",\"label\",\"options\",\"hasCustom\",\"sharedCustomProperty\",\"customPropertyName\",\"customType\",\"showPresets\",\"disabled\",\"min\",\"max\",\"afterChange\"],[[32,0,[\"properties\",\"Temperature\",\"key\"]],\"temperature\",\"HeaterOptionsTemperature\",true,[32,0,[\"isSharedProperty\"]],[32,0,[\"properties\",\"CustomTemperature\",\"key\"]],\"temperature\",true,[32,0,[\"isDisabled\"]],35,194,[30,[36,0],[[32,0],\"updateCustomProperty\"],null]]]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"hash\",\"t\",\"if\"]}",
    "moduleName": "alliance-business-suite/templates/components/form-ui/steps/heater-options-temperature.hbs"
  });

  _exports.default = _default;
});