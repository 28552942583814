define("alliance-business-suite/templates/components/table/machines/table-cell-machine-price", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3FwGy5x1",
    "block": "{\"symbols\":[\"@record\"],\"statements\":[[6,[37,1],[[30,[36,0],[[32,1,[\"machineCurrentStatus\",\"remainingVend\"]],[29]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[14,0,\"currency-space-adjust\"],[12],[2,\"\\n    \"],[8,\"currency-options\",[],[[\"@value\",\"@useOverride\"],[[32,1,[\"machineCurrentStatus\",\"remainingVend\"]],true]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"not-eq\",\"if\"]}",
    "moduleName": "alliance-business-suite/templates/components/table/machines/table-cell-machine-price.hbs"
  });

  _exports.default = _default;
});