define("alliance-business-suite/templates/components/ui/forms/checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BghfqdJE",
    "block": "{\"symbols\":[\"@subtitle\",\"@key\",\"@value\",\"@disabled\",\"@title\"],\"statements\":[[11,\"div\"],[24,0,\"checkbox-component\"],[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"toggle\"]],[32,2]],null]],null],[12],[2,\"\\n  \"],[10,\"input\"],[15,\"checked\",[32,3]],[15,\"disabled\",[32,4]],[14,4,\"checkbox\"],[12],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"notice-text\"],[12],[2,\"\\n    \"],[10,\"label\"],[12],[1,[32,5]],[13],[2,\"\\n\\n\"],[6,[37,2],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"p\"],[12],[1,[32,1]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"if\"]}",
    "moduleName": "alliance-business-suite/templates/components/ui/forms/checkbox.hbs"
  });

  _exports.default = _default;
});