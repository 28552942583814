define("alliance-business-suite/templates/components/machines/programs/pricing/midas-wx-modal-extra-time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KHzmrbPs",
    "block": "{\"symbols\":[\"ui\",\"@hideModal\"],\"statements\":[[8,\"form-ui\",[],[[\"@object\",\"@formSettings\",\"@selectOptions\",\"@machineProgram\",\"@formDisplay\"],[[32,0,[\"object\"]],[32,0,[\"formSettings\"]],[32,0,[\"selectOptions\"]],[32,0,[\"machineProgram\"]],\"compact\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[8,[32,1,[\"columns\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"column\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,1,[\"section\"]],[],[[\"@title\"],[[30,[36,0],[\"extra_time\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n        \"],[8,[32,1,[\"number\"]],[],[[\"@params\"],[[30,[36,1],null,[[\"propertyName\",\"label\",\"labelAfter\"],[\"Program.Price.ModifierOptions.ExtraWashAgitateTime_WX\",\"additional_wash_time\",\"minutes\"]]]]],null],[2,\"\\n        \"],[8,[32,1,[\"number\"]],[],[[\"@params\"],[[30,[36,1],null,[[\"propertyName\",\"label\",\"labelAfter\"],[\"Program.Price.ModifierOptions.ExtraRinseAgitateTime_WX\",\"additional_rinse_time\",\"minutes\"]]]]],null],[2,\"\\n\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[11,\"button\"],[24,0,\"button--ok\"],[24,4,\"button\"],[4,[38,2],[\"click\",[32,2]],null],[12],[1,[30,[36,0],[\"OK\"],null]],[13],[2,\"\\n\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"hash\",\"on\"]}",
    "moduleName": "alliance-business-suite/templates/components/machines/programs/pricing/midas-wx-modal-extra-time.hbs"
  });

  _exports.default = _default;
});