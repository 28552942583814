define("alliance-business-suite/templates/components/tables/table-body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+2YPjzKz",
    "block": "{\"symbols\":[\"m\",\"option\",\"&default\"],\"statements\":[[6,[37,6],[[27,[32,3]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,3,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"tbody\"],[15,0,[30,[36,6],[[35,5],\"table-clickable-row\"],null]],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,7]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[11,\"tr\"],[4,[38,1],[[32,0],\"sendToBrush\",[32,1]],null],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"td\"],[12],[2,\"\\n            \"],[1,[30,[36,0],[[32,1],[32,2,[\"value\"]]],null]],[2,\"\\n          \"],[13],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"      \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"get\",\"action\",\"headerOptions\",\"-track-array\",\"each\",\"clickable\",\"if\",\"model\"]}",
    "moduleName": "alliance-business-suite/templates/components/tables/table-body.hbs"
  });

  _exports.default = _default;
});