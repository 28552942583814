define("alliance-business-suite/components/machines/modal-start-dryer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let ComponentClass = Ember.Component.extend({
    classNames: ['machine-view'],
    ajax: Ember.inject.service(),
    access: Ember.inject.service(),
    intl: Ember.inject.service(),
    notifications: Ember.inject.service(),
    firebaseCommands: Ember.inject.service('firebase-commands'),
    canSubmit: Ember.computed('minutes', function () {
      let minutes = Ember.get(this, 'minutes');

      if (minutes > 0) {
        return true;
      }
    }),
    actions: {
      close() {
        if (!Ember.get(this, 'isLoading')) {
          this.close();
        }
      },

      apply() {
        let minutes = this.minutes;
        let machineId = Ember.get(this, 'machineId');
        Ember.set(this, 'isLoading', true);
        this.ajax.request(`/machines/${machineId}/startDryerWithTime`, {
          data: {
            'params': {
              minutes
            }
          },
          method: 'POST'
        }).then(response => {
          Ember.set(this, 'isLoading', false);
          this.close();
        });
      }

    }
  });
  ComponentClass.reopenClass({
    positionalParams: ['model']
  });
  var _default = ComponentClass;
  _exports.default = _default;
});