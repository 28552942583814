define("alliance-business-suite/components/ui/shopping-cart/addon-listing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    access: Ember.inject.service(),
    tagName: '',
    actions: {
      checkboxUpdate(addon) {
        this.update(addon); // this.toggleProperty('isSelected');
      }

    }
  });

  _exports.default = _default;
});