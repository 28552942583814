define("alliance-business-suite/helpers/options-label-lookup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Translates option text/label if found in translations.js file.
   */
  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),

    compute(_ref, args) {
      let [value, array, ...rest] = _ref;

      // array is the list of options
      if (value && array) {
        let option = array.findBy('val', value);
        let label = option ? Ember.get(option, 'label') : 'unknown'; // TODO: We can probably use the options-intl helper for this part

        let intl = this.intl;
        let key = `option_${label}`;

        if (intl.exists(key)) {
          return intl.t(key);
        } else {
          return Ember.String.htmlSafe(label);
        }
      } else {
        return Ember.String.htmlSafe("&mdash;");
      }
    }

  });

  _exports.default = _default;
});