define("alliance-business-suite/templates/components/accounts/a-refund-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Od2BE7oC",
    "block": "{\"symbols\":[\"@refresh\",\"@refundReasons\",\"@item\"],\"statements\":[[10,\"div\"],[14,0,\"account-refund-button\"],[12],[2,\"\\n\"],[6,[37,3],[[32,0,[\"isRefundable\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,3],[[30,[36,2],[\"CUSTOMERS_REFUND_MACHINE_PAYMENT\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[11,\"a\"],[24,6,\"#\"],[4,[38,1],[\"click\",[32,0,[\"toggleRefundModal\"]]],null],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"components.accounts.a-refund-button.action\"],null]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[30,[36,0],[\"components.accounts.a-refund-button.action\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\"],[6,[37,3],[[32,0,[\"isDialogOpen\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"accounts/modal-refund-reason\",[],[[\"@refresh\",\"@refundAmount\",\"@isRefundable\",\"@refundReasons\",\"@item\",\"@close\"],[[32,1],[32,0,[\"refundAmount\"]],[32,0,[\"isRefundable\"]],[32,2],[32,3],[32,0,[\"toggleRefundModal\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"on\",\"can-access\",\"if\"]}",
    "moduleName": "alliance-business-suite/templates/components/accounts/a-refund-button.hbs"
  });

  _exports.default = _default;
});