define("alliance-business-suite/components/products/products-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    classNames: ['inventory-section'],
    currentView: 'canView',
    showCreateItemModal: false,

    init() {
      this._super(...arguments);

      Ember.set(this, 'selectedItemCategory', this.sortedCategories.firstObject);
    },

    selectedCategoryId: Ember.computed('selectedItemCategory.id', function () {
      return Number(this.selectedItemCategory.id);
    }),
    sortedCategories: Ember.computed('categories.[]', function () {
      let showAll = {
        id: -1,
        name: this.intl.t('all_categories'),
        description: this.intl.t('display_all_products')
      };
      let list = this.categories.sortBy('name');
      list.insertAt(0, showAll);
      list.pushObject({
        id: "add",
        name: this.intl.t('add_category'),
        description: ""
      });
      return list;
    }),
    itemList: Ember.computed('items.[]', 'selectedItemCategory', function () {
      let categoryId = this.selectedItemCategory.id;

      if (categoryId == -1) {
        return this.items;
      } else {
        return this.items.filterBy('itemCategory.id', categoryId);
      }
    }),
    newItemCategory: {},
    actions: {
      showCreateItemModal() {
        this.toggleProperty('showCreateItemModal');
      },

      showSortOrdinalsModal() {
        this.toggleProperty('showSortOrdinalsModal');
      }

    }
  });

  _exports.default = _default;
});