define("alliance-business-suite/routes/summary/alerts", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "alliance-business-suite/routes/_secureRoute", "alliance-business-suite/config/environment"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _secureRoute, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  let SummaryAlertsRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, (_class = class SummaryAlertsRoute extends _secureRoute.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "ajax", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "cache", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "notifications", _descriptor5, this);
      (0, _defineProperty2.default)(this, "allowAccess", 'SUMMARY_ALERTS_VIEW_PAGE');
    }

    model() {
      const userId = this.access.get('accessUser.id');
      const organizationId = this.access.get('accessOrganization.id');
      return Ember.RSVP.hash({
        reportSubscriptions: this.store.query('subscription', {
          userId,
          eventTypeLookupKey: 'MACHINE_ERROR',
          brandLookupKey: _environment.default.brand
        }),
        machineAuditErrorSubscriptions: this.store.query('subscription', {
          userId,
          eventTypeLookupKey: 'MACHINE_AUDIT_ERROR',
          brandLookupKey: _environment.default.brand
        }),
        machineAnomalySubscriptions: this.store.query('subscription', {
          userId,
          eventTypeLookupKey: 'MACHINE_STATUS_ANOMALY',
          brandLookupKey: _environment.default.brand
        }),
        newUserSubscriptions: this.store.query('subscription', {
          userId,
          eventTypeLookupKey: 'NEW_USER',
          brandLookupKey: _environment.default.brand
        }),
        gatewayOutageSubscriptions: this.store.query('subscription', {
          userId,
          eventTypeLookupKey: 'GATEWAY_OUTAGE',
          brandLookupKey: _environment.default.brand
        }),
        reports: this.store.peekAll('subscription'),
        organization: this.cache.get('loadedGeoIncludedAll') ? this.store.peekRecord('organization', organizationId) : this.store.queryRecord('organization', {
          organization: organizationId,
          geoBoundaries: true,
          include: 'all'
        })
      });
    }

    setupController(controller, model) {
      controller.access = this.access;
      super.setupController(controller, model);
      this.cache.set('loadedGeoIncludedAll', true);
      let subscriptionTemplates = this.store.peekAll('subscriptionTemplate').filterBy('subscriptionEventType.lookupKey', 'MACHINE_ERROR');
      let machineAuditErrorSubTemplates = this.store.peekAll('subscriptionTemplate').filterBy('subscriptionEventType.lookupKey', 'MACHINE_AUDIT_ERROR');
      let machineAnomalySubTemplates = this.store.peekAll('subscriptionTemplate').filterBy('subscriptionEventType.lookupKey', 'MACHINE_STATUS_ANOMALY');
      let newUserSubTemplates = this.store.peekAll('subscriptionTemplate').filterBy('subscriptionEventType.lookupKey', 'NEW_USER');
      let gatewayOutageSubTemplates = this.store.peekAll('subscriptionTemplate').filterBy('subscriptionEventType.lookupKey', 'GATEWAY_OUTAGE');

      if (subscriptionTemplates.get('length') == 0 || machineAuditErrorSubTemplates.get('length') == 0 || machineAnomalySubTemplates.get('length') == 0 || newUserSubTemplates.get('length') == 0) {
        this.store.query('subscriptionTemplate', {
          eventTypeLookupKey: 'MACHINE_ERROR'
        }).then(response => {
          model.subscriptionTemplates = response;
        });
        this.store.query('subscriptionTemplate', {
          eventTypeLookupKey: 'MACHINE_AUDIT_ERROR'
        }).then(response => {
          model.machineAuditErrorSubTemplates = response;
        });
        this.store.query('subscriptionTemplate', {
          eventTypeLookupKey: 'MACHINE_STATUS_ANOMALY'
        }).then(response => {
          model.machineAnomalySubTemplates = response;
        });
        this.store.query('subscriptionTemplate', {
          eventTypeLookupKey: 'NEW_USER'
        }).then(response => {
          model.newUserSubTemplates = response;
        });
        this.store.query('subscriptionTemplate', {
          eventTypeLookupKey: 'GATEWAY_OUTAGE'
        }).then(response => {
          model.gatewayOutageSubTemplates = response;
        });
      } else {
        model.subscriptionTemplates = subscriptionTemplates;
        model.machineAuditErrorSubTemplates = machineAuditErrorSubTemplates;
        model.machineAnomalySubTemplates = machineAnomalySubTemplates;
        model.newUserSubTemplates = newUserSubTemplates;
        model.gatewayOutageSubTemplates = gatewayOutageSubTemplates;
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "cache", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "notifications", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SummaryAlertsRoute;
});