define("alliance-business-suite/adapters/system-alert", ["exports", "alliance-business-suite/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    access: Ember.inject.service(),

    /**
     * Builds the URL for a request.
     *
     * @public
     */
    // TODO - Work with team to use proper end point
    buildURL(modelName, id, snapshot, requestType, query) {
      let url = this._super(modelName, id, snapshot, requestType, query);

      let userId = Ember.get(this, 'access.accessUser.id');
      url = url.replace('systemAlerts', 'alerts/system');

      if (requestType === 'findAll') {
        url = `${url}?userId=${userId}`;
      }

      return url;
    }

  });

  _exports.default = _default;
});