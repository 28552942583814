define("alliance-business-suite/utils/machine-programming-tabs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hasTabs = hasTabs;
  _exports.tabs = void 0;
  const tabs = [// CENTURION MACHINES
  {
    id: 'centurion-c3-als-drive-wx',
    active: true,
    tabs: [{
      name: 'pricing'
    }, {
      name: 'cycles',
      filename: 'centurion'
    }, {
      name: 'water-guardian'
    }, {
      name: 'config'
    }, {
      name: 'advanced'
    }, {
      name: 'scheduled-programs'
    }],
    filename: 'acas-wx-flw'
  }, {
    id: 'centurion-c3-wx',
    active: true,
    tabs: [{
      name: 'pricing'
    }, {
      name: 'cycles',
      filename: 'centurion'
    }, {
      name: 'water-guardian'
    }, {
      name: 'config'
    }, {
      name: 'advanced'
    }, {
      name: 'scheduled-programs'
    }],
    filename: 'acas-wx-flw'
  }, {
    id: 'centurion-c3-tum',
    active: true,
    tabs: ['pricing', 'config', 'advanced', 'scheduled-programs'],
    filename: 'acas-tum-dry'
  }, {
    id: 'centurion-c4-wx',
    active: true,
    tabs: [{
      name: 'pricing'
    }, {
      name: 'cycles',
      filename: 'centurion'
    }, {
      name: 'water-guardian'
    }, {
      name: 'config'
    }, {
      name: 'advanced'
    }, {
      name: 'scheduled-programs'
    }],
    filename: 'acas-wx-flw'
  }, {
    id: 'centurion-c4-als-drive-wx',
    active: true,
    tabs: [{
      name: 'pricing'
    }, {
      name: 'cycles',
      filename: 'centurion'
    }, {
      name: 'water-guardian'
    }, {
      name: 'config'
    }, {
      name: 'advanced'
    }, {
      name: 'scheduled-programs'
    }],
    filename: 'acas-wx-flw'
  }, {
    id: 'centurion-c4-tum',
    active: true,
    tabs: ['pricing', 'config', 'advanced', 'scheduled-programs'],
    filename: 'acas-tum-dry'
  }, {
    id: 'centurion-c4-tlw',
    active: true,
    tabs: ['not-supported'],
    filename: 'unsupported'
  }, {
    id: 'centurion-c4-flw',
    active: true,
    tabs: ['not-supported'],
    filename: 'unsupported'
  }, // HUEBSCH ACA MACHINES
  {
    id: 'acas-a2-wx',
    active: true,
    tabs: ['pricing', 'cycles', 'water-guardian', 'config', 'advanced', 'scheduled-programs'],
    filename: 'acas-wx-flw' // same as a4-wx

  }, {
    id: 'acas-a22-wx',
    active: true,
    tabs: ['pricing', 'cycles', 'water-guardian', 'config', 'advanced', 'scheduled-programs'],
    filename: 'acas-wx-flw'
  }, {
    id: 'acas-a2-flw',
    active: true,
    tabs: ['pricing', 'cycles', 'water-usage', 'water-guardian', 'config', 'advanced', 'scheduled-programs'],
    filename: 'acas-wx-flw'
  }, {
    id: 'acas-a1-tlw',
    active: true,
    tabs: ['program', 'scheduled-programs'],
    filename: 'acas-tlw'
  }, {
    id: 'acas-a2-tum',
    // same as a4-tum
    active: true,
    tabs: ['pricing', 'config', 'advanced', 'scheduled-programs'],
    filename: 'acas-tum-dry'
  }, // SPEED QUEEN ACA MACHINES
  {
    id: 'acas-a4-wx',
    active: true,
    tabs: ['pricing', 'cycles', 'water-guardian', 'config', 'advanced', 'scheduled-programs'],
    filename: 'acas-wx-flw'
  }, {
    id: 'acas-a4-flw',
    active: true,
    tabs: ['pricing', 'cycles',
    /* 'water-usage', */
    'water-guardian', 'config', 'advanced', 'scheduled-programs'],
    filename: 'acas-wx-flw' // was 'acas-a4-flw'

  }, {
    id: 'acas-a3-flw',
    // 6-cycle intl
    active: true,
    tabs: ['pricing', 'cycles', 'water-usage', 'water-guardian', 'config', 'advanced', 'scheduled-programs'],
    filename: 'acas-wx-flw'
  }, {
    id: 'acas-a4-tlw',
    active: true,
    tabs: ['pricing', 'cycles', 'config', 'advanced', 'scheduled-programs'],
    filename: 'acas-tlw'
  }, {
    id: 'acas-a4-tum',
    active: true,
    tabs: ['pricing', 'config', 'advanced', 'scheduled-programs'],
    filename: 'acas-tum-dry'
  }, {
    id: 'acas-a4-dry',
    active: true,
    tabs: ['pricing', 'config', 'advanced', 'scheduled-programs'],
    filename: 'acas-tum-dry'
  }, {
    id: 'acas-a44-wx',
    active: true,
    tabs: ['pricing', 'cycles', 'water-guardian', 'config', 'advanced', 'scheduled-programs'],
    filename: 'acas-wx-flw'
  }, {
    id: 'acas-a3-wx',
    active: true,
    tabs: ['pricing', 'cycles', 'water-guardian', 'config', 'advanced', 'scheduled-programs'],
    filename: 'acas-wx-flw'
  }, // TITAN MACHINES
  // SQ
  {
    id: 'titan-t4-flw',
    active: true,
    tabs: ['pricing', 'cycles', 'water-usage', 'water-guardian', 'config', 'advanced', 'scheduled-programs'],
    filename: 'acas-wx-flw'
  }, {
    id: 'titan-t4-tlw',
    active: true,
    tabs: ['pricing', 'cycles', 'config', 'advanced', 'scheduled-programs'],
    filename: 'acas-tlw'
  }, {
    id: 'titan-t4-dry',
    active: true,
    tabs: ['pricing', 'config', 'advanced', 'scheduled-programs'],
    filename: 'acas-tum-dry'
  }, // HB
  {
    id: 'titan-t2-flw',
    active: true,
    tabs: ['pricing', 'cycles', 'water-usage', 'water-guardian', 'config', 'advanced', 'scheduled-programs'],
    filename: 'acas-wx-flw'
  }, {
    id: 'titan-t1-tlw',
    active: true,
    tabs: ['program', 'scheduled-programs'],
    filename: 'acas-tlw'
  }, // MGD MACHINES
  {
    id: 'mgd-m1-dry',
    active: true,
    // tabs: [ 'pricing', 'cycles', 'config', 'special-vend', 'advanced', 'scheduled-programs' ],
    tabs: [{
      name: 'pricing',
      filename: 'mgd'
    }, {
      name: 'cycles',
      filename: 'midas-tum'
    }, {
      name: 'config',
      filename: 'mgd-opl-tum'
    }, {
      name: 'advanced',
      filename: 'mgd-opl-tum'
    }, {
      name: 'scheduled-programs'
    }]
  }, {
    id: 'mgd-m1-flw',
    active: true,
    tabs: [// Using existing files
    {
      name: 'pricing',
      filename: 'mgd'
    }, {
      name: 'cycles',
      filename: 'acas-wx-flw'
    }, {
      name: 'water-guardian',
      filename: 'mgd-opl'
    }, {
      name: 'config',
      filename: 'mgd-opl'
    }, {
      name: 'advanced',
      filename: 'mgd-opl-tum'
    }, {
      name: 'scheduled-programs'
    }]
  }, // MGD OPL
  {
    id: 'mgd-m7-wx',
    active: true,
    tabs: ['cycles', 'water-guardian', 'config', 'scheduled-programs'],
    filename: 'mgd-opl'
  }, {
    id: 'mgd-m7-tum',
    active: true,
    tabs: ['cycles', 'config', 'advanced', 'scheduled-programs'],
    filename: 'mgd-opl-tum'
  }, // MC5 MACHINES
  {
    id: 'mc5-ec-primus-tum',
    active: true,
    tabs: ['pricing', 'config', 'scheduled-programs'],
    filename: 'mc5-tum'
  }, {
    id: 'mc5-ec-sq-tum',
    active: true,
    tabs: ['pricing', 'config', 'scheduled-programs'],
    filename: 'mc5-tum'
  }, {
    id: 'mc5-fc-sq-tum',
    active: true,
    tabs: ['cycles', 'config', 'scheduled-programs'],
    filename: 'mc5-tum'
  }, // MC10 MACHINES
  {
    id: 'mc10-wx',
    active: true,
    tabs: ['pricing', 'cycles', 'display', 'modifiers', 'config', 'special-vend', 'scheduled-programs'],
    filename: 'primus-mc10-wx'
  }, {
    id: 'primus-mc10-wx',
    active: true,
    tabs: ['pricing', 'cycles', 'display', 'modifiers', 'config', 'special-vend', 'scheduled-programs'],
    filename: 'primus-mc10-wx'
  }, {
    id: 'mc10-combo-m1-cbo',
    active: true,
    tabs: ['pricing', 'cycles', 'display', 'config', 'scheduled-programs'],
    filename: 'primus-mc10-wx'
  }, {
    id: 'primus-ezopl-mc10-wx',
    // TODO: Deprecated? Changed to mc10-ezopl-m5
    active: true,
    tabs: ['cycles', 'display', 'config', 'scheduled-programs'],
    // no pricing, modifiers, or special vend
    filename: 'primus-mc10-wx',
    isOpl: true
  }, {
    id: 'mc10-ezopl-m5-wx',
    active: true,
    tabs: ['cycles', 'display', 'config', 'scheduled-programs'],
    // no pricing, modifiers, or special vend
    filename: 'primus-mc10-wx',
    isOpl: true
  }, {
    id: 'mc10-opl-1-wx',
    active: true,
    tabs: ['cycles', 'display', 'config', 'misc', 'scheduled-programs'],
    filename: 'mc10-opl',
    isOpl: true
  }, {
    id: 'mc10-opl-5-wx',
    active: true,
    tabs: ['cycles', 'display', 'config', 'misc', 'scheduled-programs'],
    filename: 'mc10-opl',
    isOpl: true
  }, // MIDAS MACHINES
  {
    id: 'midas-vend-m1-wx',
    active: true,
    tabs: ['pricing', 'special-vend', 'config', 'cycles', 'advanced', 'water-guardian', 'scheduled-programs'],
    filename: 'midas-wx'
  }, {
    id: 'midas-vend-m2-wx',
    active: true,
    tabs: ['pricing', 'special-vend', 'config', 'cycles', 'advanced', 'water-guardian', 'scheduled-programs'],
    filename: 'midas-wx'
  }, {
    id: 'midas-vend-m1-tum',
    // Speed Queen
    active: true,
    tabs: ['pricing', 'special-vend', 'cycles', 'config', 'advanced', 'scheduled-programs'],
    filename: 'midas-tum'
  }, {
    id: 'midas-vend-m2-tum',
    // Huebsch
    active: true,
    tabs: ['pricing', 'special-vend', 'cycles', 'config', 'advanced', 'scheduled-programs'],
    filename: 'midas-tum'
  }, {
    id: 'midas-vend-m3-tum',
    // IPSO
    active: true,
    tabs: ['pricing', 'special-vend', 'cycles', 'config', 'advanced', 'scheduled-programs'],
    filename: 'midas-tum'
  }, {
    id: 'midas-vend-m5-tum',
    // Primus
    active: true,
    tabs: ['pricing', 'special-vend', 'cycles', 'config', 'advanced', 'scheduled-programs'],
    filename: 'midas-tum'
  }, {
    id: 'midas-wx',
    active: true,
    tabs: ['pricing', 'special-vend', 'config', 'cycles', 'advanced', 'water-guardian', 'scheduled-programs'],
    filename: 'midas-wx'
  }, {
    id: 'midas-tum',
    active: true,
    tabs: ['pricing', 'special-vend', 'cycles', 'config', 'advanced', 'scheduled-programs'],
    filename: 'midas-tum'
  }, {
    id: 'midas-ezopl-m3-tum',
    active: true,
    tabs: ['cycles', 'config', 'advanced', 'scheduled-programs'],
    filename: 'midas-ezopl-m3-tum',
    isOpl: true
  }, {
    id: 'midas-ezopl-m5-tum',
    active: true,
    tabs: ['cycles', 'config', 'advanced', 'scheduled-programs'],
    filename: 'midas-ezopl-m3-tum',
    isOpl: true
  }, {
    id: 'midas-opl-1-tum',
    active: true,
    tabs: ['banners', 'cycles', 'config', 'advanced', 'scheduled-programs'],
    filename: 'midas-opl-tum',
    isOpl: true
  }, // OPL MACHINES - TODO: DRY up these machineAuditTypes
  {
    id: 'mc10-opl-1-wx',
    // Speed Queen
    active: true,
    tabs: ['cycles', 'display', 'config', 'misc', 'scheduled-programs'],
    filename: 'mc10-opl',
    isOpl: true
  }, {
    id: 'mc10-opl-2-wx',
    // Huebsch
    active: true,
    tabs: ['cycles', 'display', 'config', 'misc', 'scheduled-programs'],
    filename: 'mc10-opl',
    isOpl: true
  }, {
    id: 'mc10-opl-3-wx',
    // IPSO Evolis Elite
    active: true,
    tabs: ['cycles', 'display', 'config', 'misc', 'scheduled-programs'],
    filename: 'mc10-opl',
    isOpl: true
  }, {
    id: 'mc10-opl-4-wx',
    // UniMac
    active: true,
    tabs: ['cycles', 'display', 'config', 'misc', 'scheduled-programs'],
    filename: 'mc10-opl',
    isOpl: true
  }, {
    id: 'mc10-opl-5-wx',
    //Primus Xcontrol Flex+
    active: true,
    tabs: ['cycles', 'display', 'config', 'misc', 'scheduled-programs'],
    filename: 'mc10-opl',
    isOpl: true
  }, // { id: 'midas-opl-2-tum',
  //   active: true,
  //   tabs: ['banners','cycles', 'config', 'advanced', 'scheduled-programs' ],
  //   filename: 'midas-opl-tum', // Assuming all midas_opl_# audit types will use the same files
  //   isOpl: true
  // },
  {
    id: 'midas-opl-5-tum',
    active: false,
    tabs: ['banners', 'config', 'advanced', 'scheduled-programs'],
    filename: 'midas-opl-tum',
    isOpl: true
  }, {
    id: 'midas-opl-2-tum',
    active: false,
    tabs: ['banners', 'config', 'advanced', 'scheduled-programs'],
    filename: 'midas-opl-tum',
    isOpl: true
  }, {
    id: 'midas-opl-1-tum',
    active: false,
    tabs: ['banners', 'cycles', 'config', 'advanced', 'scheduled-programs'],
    filename: 'midas-opl-tum',
    isOpl: true
  }, {
    id: 'unsupported',
    active: false,
    tabs: ['not-supported'],
    filename: 'unsupported'
  }];
  _exports.tabs = tabs;

  function hasTabs(machineCode) {
    return tabs.any(item => {
      return machineCode === item.id && item.active === true; // Only support active machines
    });
  }
});