define("alliance-business-suite/helpers/f", ["exports", "moment", "numeral", "alliance-business-suite/utils/locale"], function (_exports, _moment, _numeral, _locale) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Returns a formatted value.
   *
   * @public
   */
  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),
    access: Ember.inject.service(),
    store: Ember.inject.service(),

    /**
     * Computes the formatted value.
     *
     * @param {any}    value  The value to format
     * @param {string} format The format to use to format the value
     * @param {string} style  The style to use to format the value
     *
     * @return {string} A formatted value
     *
     * @public
     */
    compute(_ref, _ref2) {
      let [value, format] = _ref;
      let {
        style,
        missing,
        type,
        useCommas,
        useLocalTimezone,
        includeDate,
        useSubDivisor,
        ifLoadingIgnoreNull,
        timeFormat,
        showMissingPlaceholder
      } = _ref2;
      // TODO: Pass options in hash so we can set preferences for a large section of our template
      // using `with` instead of passing the same options several times.
      let currencyDenomination = Ember.get(this, 'access.currencyDenomination');
      let currencySubunit = useSubDivisor;
      let locale = Ember.get(this, 'intl.locale'); // If string, trim it. Some values are just a bunch of spaces.

      if (typeof value === 'string') {
        value = value.trim();
      }

      if ((Ember.isEmpty(value) || Ember.isBlank(value)) && showMissingPlaceholder) {
        return Ember.String.htmlSafe("&ndash;");
      }

      if (value == null && missing != null) {
        return missing;
      }

      if (type === 'autocurrency') {
        // Ty is too lazy to remember all the parameters to pass in.
        let originalValue = value; // Show a dash if there's no value

        if (!value) {
          return '-';
        }

        currencySubunit = this.access.currencySubunit;

        if (currencySubunit) {
          value = value / currencySubunit;
        } // we want numbers to have the comma (eg 1000 -> 1,000)


        format = this.access.currencyFormatStringWithoutThousands;
        format = `0,${format}`;

        if (typeof value === 'string') {
          value = parseFloat(value);
        }

        return currencyDenomination + (0, _numeral.default)(value).format(format);
      } // All dates are expected to be UTC so convert from that.
      // Application route sets default (guessed) timezone and locale for momentjs


      if (type === 'currency') {
        // avoids showing a NaN
        if (ifLoadingIgnoreNull && !value) {
          value = '0';
        }

        if (useSubDivisor) {
          value = value / useSubDivisor;
        } // we want numbers to have the comma (eg 1000 -> 1,000)
        // we prepend to keep the currency subdivisor correct


        if (useCommas) {
          format = `0,${format}`;
        }

        if (typeof value === 'string') {
          value = parseFloat(value);
        }

        return currencyDenomination + (0, _numeral.default)(value).format(format);
      } else if (useLocalTimezone) {
        let userId = Ember.get(this, 'access.accessUser.id');
        let user = Ember.get(this, 'store').peekRecord('employee', userId);
        let userTimeFormat = `${user.get('timeFormat')} A`;
        let userDateFormat = user.get('dateFormat');

        if (user.get('timeFormat') === 'HH:mm') {
          userTimeFormat = `${user.get('timeFormat')}`;
        }

        let customTimeFormat = timeFormat ? timeFormat : `${userTimeFormat}`;

        if (includeDate) {
          let userDateFormat = `${user.get('dateFormat')}`;
          customTimeFormat = userDateFormat + ' ' + customTimeFormat;
        } // convert the utc timestamp to local browser time


        return _moment.default.utc(value).local().format(customTimeFormat);
      } else if (_moment.default.isDate(value) || type === 'date' && (0, _moment.default)(value).isValid()) {
        let userId = Ember.get(this, 'access.accessUser.id');
        let user = Ember.get(this, 'store').peekRecord('employee', userId);
        let userTimeFormat = `${user.get('timeFormat')} A z`;

        if (user.get('timeFormat') === 'HH:mm') {
          userTimeFormat = `${user.get('timeFormat')} z`;
        }

        let dateTime = _moment.default.tz(value, _moment.default.tz.guess());

        switch (style) {
          case 'fromNow':
            return _moment.default.utc(value.toString()).local().fromNow();

          case 'toNow':
            return dateTime.toNow();

          default:
            return dateTime.format(`${_locale.momentLocaleFormatMap[locale[0]]} ${userTimeFormat}`);
        }
      } else {
        if (typeof value === 'number' && !Ember.isNone(format)) {
          let currencySymbol = this.get('access.currencyDenomination');
          let currencyValue = this.get('access.currencyFormatString');

          switch (format) {
            case 'm:s':
              return `${parseInt(value / 60)}:${('0' + value % 60).substr(-2)}`;
            // eslint-disable-line prefer-template

            case `${currencySymbol}${currencyValue}`:
              return `${currencySymbol}${(0, _numeral.default)(value).format(`${this.get('access.currencyFormatString')}`)}`;

            default:
              return (0, _numeral.default)(value).format(format);
          }
        } else if (typeof value === 'string' && !Ember.isNone(format)) {
          switch (format) {
            case 'lower':
              return value.toLowerCase();

            case 'upper':
              return value.toUpperCase();

            case 'capital':
              return Ember.String.capitalize(value);

            case 'title':
              return Ember.String.capitalize(value.toLowerCase());

            case 'class':
              return Ember.String.classify(value);

            case 'camel':
              return Ember.String.camelize(value);

            case 'decamel':
              return Ember.String.decamelize(value);

            case 'dash':
              return Ember.String.dasherize(value);

            case 'underscore':
              return Ember.String.underscore(value);
          }
        }
      }

      return value;
    },

    /**
     * Recompute whenever the locale changes.
     *
     * @private
     */
    _recomputeOnLocaleChange: Ember.observer('intl.locale', function () {
      this.recompute();
    })
  });

  _exports.default = _default;
});