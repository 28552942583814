define("alliance-business-suite/components/form-ui/steps/water-valves", ["exports", "alliance-business-suite/helpers/find-by-dropdown-with-custom", "alliance-business-suite/mixins/init-property-names"], function (_exports, _findByDropdownWithCustom, _initPropertyNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_initPropertyNames.default, {
    stepLabelName: Ember.computed('Label', 'formSettings.selectOptions', function () {
      return (0, _findByDropdownWithCustom.findByDropDown)([this.formSettings.selectOptions['StepLabel'], "val", this.Label]).group.toLowerCase();
    }),
    stepTypeName: Ember.computed('Type', 'formSettings.selectOptions', function () {
      return (0, _findByDropdownWithCustom.findByDropDown)([this.formSettings.selectOptions['StepType'], "val", this.Type]).label.toLowerCase();
    }),
    hasOverride: Ember.computed('AllowTempOverride', 'stepLabelName', function () {
      if (["MGD_M7"].includes(this.machineProgram._machineAuditType)) {
        return false;
      } // Only available for Prewash and Wash


      if (["prewash", "wash"].includes(this.stepLabelName)) {
        let value = this.AllowTempOverride;
        return !Ember.isEmpty(this.AllowTempOverride);
      } else {
        return false;
      }
    }),
    hasControlledFill: Ember.computed('Type', 'properties.TempControlledFill', function () {
      if (this.machineProgram._machineAuditType == "ACAS_A4") {
        let hasProperty = this.properties.TempControlledFill !== undefined;
        return hasProperty;
      } else {
        // "Controlled" is only an option for reuse fill and fill steps
        switch (this.Type) {
          case 1: // Reuse Fill

          case 2:
            // Fill
            return true;

          default:
            return false;
        }
      }
    }),
    optionsKey: Ember.computed('hasControlledFill', function () {
      if (this.hasControlledFill === true) {
        return "WaterValvesControlled";
      } else {
        return "WaterValves";
      }
    }),
    options: Ember.computed('optionsKey', 'selectOptions', 'formSettings.selectOptions', function () {
      let optionsList = this.selectOptions || this.formSettings.selectOptions;
      return optionsList[this.optionsKey];
    }),
    showOptions: Ember.computed('machineProgram.machineTypeCode', function () {
      // Options are not shown for FLW
      if (this.machineProgram.machineTypeCode == "FLW") {
        return false;
      } else {
        return true;
      }
    }),
    waterValves: Ember.computed('HotFill', 'ColdFill', 'TempControlledFill', 'hasControlledFill', 'options', {
      get() {
        // Get current values
        let hot = this.HotFill;
        let cold = this.ColdFill;
        let controlled = this.TempControlledFill || false; // Set to false if undefined?

        let hasControlledFill = this.hasControlledFill; // Get options

        let options = this.options; // SUPRISE: If all temp values are false, we set Cold to `true`.

        if (hasControlledFill) {
          if (!hot && !cold && !controlled) {
            cold = true;
          }
        } else {
          if (!hot && !cold) {
            cold = true;
          }
        } // Find a match...


        let selected = options.find(item => {
          if (hasControlledFill) {
            return Ember.get(item, 'hotFill') == hot && Ember.get(item, 'coldFill') == cold && Ember.get(item, 'controlledFill') == controlled;
          } else {
            return Ember.get(item, 'hotFill') == hot && Ember.get(item, 'coldFill') == cold;
          }
        });
        return Ember.get(selected, "val");
      },

      set(key, value) {
        // We do nothing.
        return value;
      }

    }),
    actions: {
      updateWaterValves(object, propertyName, value) {
        // Find the correct values
        let {
          coldFill,
          hotFill,
          controlledFill
        } = (0, _findByDropdownWithCustom.findByDropDown)([this.options, "val", value]);
        this.update(this.object, this.properties.HotFill.key, hotFill);
        this.update(this.object, this.properties.ColdFill.key, coldFill);

        if (this.hasControlledFill) {
          if (!Ember.isEmpty(this.TempControlledFill)) {
            // added this if statement to catch when TempControlledFill does not exist
            this.update(this.object, this.properties.TempControlledFill.key, controlledFill);
          }
        }
      }

    }
  });

  _exports.default = _default;
});