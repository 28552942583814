define("alliance-business-suite/services/cache", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    // used in CRM campaigns (create) to preview matching users
    // showCrmPreviewBanner: false,
    // loadingCrmPreviewBanner: false,
    // crmPreviewTotal: null,
    // crmPreviewMatching: null,
    // paramFilters: null,
    // paramSelectedRoomIds: null,
    // paramDeliveryMethod: 'NONE',
    matchingCustomerPercent: Ember.computed('crmPreviewTotal', 'crmPreviewMatching', function () {
      let {
        crmPreviewMatching,
        crmPreviewTotal
      } = this;

      if (crmPreviewMatching > 0) {
        return (crmPreviewMatching / crmPreviewTotal * 100).toFixed(2);
      }

      return 0;
    }),

    resetCrmCache() {
      this.setProperties({
        showCrmPreviewBanner: false,
        paramFilters: null,
        paramSelectedRoomIds: null,
        paramDeliveryMethod: null
      });
    }

  });

  _exports.default = _default;
});