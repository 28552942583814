define("alliance-business-suite/components/rooms/r-view", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12;

  let RoomsRViewComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember.computed('brush.state'), _dec14 = Ember.computed('model', 'model.gateways.content.{firstObject,firstObject.url}'), _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, _dec18 = Ember._action, _dec19 = Ember._action, (_class = class RoomsRViewComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "ajax", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "brush", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "canAccess", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "downloadReady", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "targetHours", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "loadingTargetHours", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "processingDownload", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "downloadUrl", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "viewFinancialsModal", _descriptor10, this);
      (0, _initializerDefineProperty2.default)(this, "viewRoomBonus", _descriptor11, this);
      (0, _initializerDefineProperty2.default)(this, "viewTargetHoursModal", _descriptor12, this);
    }

    get selectedCard() {
      let savedId = this.brush.state;
      let savedCard;

      if (savedId != null) {
        savedCard = this.cards.find(card => Ember.String.dasherize(card) === savedId);
      }

      return savedCard;
    }

    set selectedCard(value) {
      this.brush.state = Ember.String.dasherize(value);
      return value;
    }

    get cards() {
      let model = this.args.model;
      let cards = ['overview'];

      if (model.gateways && model.gateways.get('content.firstObject.url')) {
        cards.push('gateways');
      }

      if (this.canAccess.canAccess('LOCATIONS_EXTERNAL_FUND_SYSTEM_COUNT_RESET')) {
        cards.push('resets');
      }

      return cards;
    }

    fetchTargetHours() {
      if (this.access.get('isOpl')) {
        this.targetHours = null;
        this.loadingTargetHours = true;
        this.ajax.request(`rooms/${this.args.model.id}/targetHours?raw=true`).then(response => {
          this.loadingTargetHours = false;
          this.targetHours = response.targetHours;
        });
      }
    }

    toggleFinancialsModal() {
      this.viewFinancialsModal = !this.viewFinancialsModal;
    }

    downloadQrCodes() {
      if (this.processingDownload) {
        return;
      }

      this.processingDownload = true;
      this.downloadUrl = null;
      this.ajax.request(`rooms/${this.args.model.id}/qrCodeDownloads`, {
        dataType: 'text'
      }).then(result => {
        this.downloadUrl = result;
        this.downloadReady = true;
        setTimeout(() => {
          this.processingDownload = false;
          document.getElementById('downloadLink').click(); // reset the download link by setting to false

          this.downloadReady = false;
        }, 200);
      }, result => {
        this.downloadReady = false;
        this.processingDownload = false;
      });
    }

    toggleRoomBonus() {
      this.viewRoomBonus = !this.viewRoomBonus;
    }

    toggleTargetHoursModal() {
      this.viewTargetHoursModal = !this.viewTargetHoursModal;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "brush", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "canAccess", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "downloadReady", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "targetHours", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "loadingTargetHours", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "processingDownload", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "downloadUrl", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "viewFinancialsModal", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor11 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "viewRoomBonus", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor12 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "viewTargetHoursModal", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedCard", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "selectedCard"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "cards", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "cards"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "fetchTargetHours", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "fetchTargetHours"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "toggleFinancialsModal", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "toggleFinancialsModal"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "downloadQrCodes", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "downloadQrCodes"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "toggleRoomBonus", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "toggleRoomBonus"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "toggleTargetHoursModal", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "toggleTargetHoursModal"), _class.prototype)), _class));
  _exports.default = RoomsRViewComponent;
});