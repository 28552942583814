define("alliance-business-suite/templates/components/table/table-cell-cycle-count", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Q38SdlA6",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[35,7,[\"cycleCount\",\"createdAt\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,3],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"i\"],[14,\"aria-hidden\",\"true\"],[15,0,[31,[\"fa fa-exclamation-triangle \",[34,1]]]],[12],[2,\"\\n      \"],[8,\"ember-tooltip\",[],[[\"@delay\",\"@side\"],[300,\"left\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,0],[\"cycle_count_over_month_old\"],null]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[10,\"span\"],[12],[2,\"\\n    \"],[1,[30,[36,6],[[35,5]],[[\"useLocalTimezone\",\"timeFormat\"],[true,[35,4]]]]],[2,\"\\n\\n    \"],[8,\"ember-tooltip\",[],[[\"@delay\",\"@side\"],[300,\"left\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,6],[[35,5]],[[\"useLocalTimezone\"],[true]]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"i\"],[14,\"aria-hidden\",\"true\"],[14,0,\"fa fa-ban\"],[14,5,\"opacity: .35\"],[12],[2,\"\\n    \"],[8,\"ember-tooltip\",[],[[\"@delay\",\"@side\"],[300,\"left\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,0],[\"no_previous_cycle_counts\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"warningClass\",\"hasWarning\",\"if\",\"userDateFormat\",\"lastCycleCount\",\"f\",\"record\"]}",
    "moduleName": "alliance-business-suite/templates/components/table/table-cell-cycle-count.hbs"
  });

  _exports.default = _default;
});