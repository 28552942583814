define("alliance-business-suite/components/marketing/analytics-container", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "jquery"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15;

  const FILTERS = [{
    id: 'DEADBEAT_USERS',
    text: 'deadbeat_users',
    description: 'deadbeat_users.description'
  }, {
    id: 'LAST_ACTIVE',
    text: 'last_visit',
    description: 'last_visit.description'
  }, {
    id: 'SERVICE_TYPE',
    text: 'usage_type',
    description: 'usage_type.description'
  }, {
    id: 'PREFERRED_DAY',
    text: 'preferred_day_of_week',
    description: 'preferred_day_of_week.description'
  }, {
    id: 'MACHINE_TYPE',
    text: 'machine_type',
    description: 'machine_type.description'
  }, {
    id: 'WASH_DRY_FOLD',
    text: 'avg_visit_spend_wash_dry_fold',
    description: 'avg_visit_spend_wash_dry_fold.description'
  }, {
    id: 'GOODS',
    text: 'avg_visit_spend_goods',
    description: 'avg_visit_spend_goods.description'
  }, {
    id: 'MACHINE',
    text: 'avg_visit_spend_self_service',
    description: 'avg_visit_spend_self_service.description'
  }, {
    id: 'LIFETIME_SPEND',
    text: 'lifetime_spend',
    description: 'lifetime_spend.description'
  }];
  let MarketingAnaltyicsContainerComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._action, _dec16 = Ember.computed('selectedLocations.[]'), _dec17 = Ember._action, _dec18 = Ember._tracked, _dec19 = Ember._action, _dec20 = Ember._action, _dec21 = Ember._action, _dec22 = Ember._action, _dec23 = Ember._action, _dec24 = Ember._action, _dec25 = Ember._action, (_class = class MarketingAnaltyicsContainerComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "ajax", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "router", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "filteredResults", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "selectedFilters", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "filterButtonDisabled", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "filterComponents", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "checkboxOptions", _descriptor10, this);
      (0, _initializerDefineProperty2.default)(this, "showLocationModal", _descriptor11, this);
      (0, _initializerDefineProperty2.default)(this, "isProccessingDownload", _descriptor12, this);
      (0, _initializerDefineProperty2.default)(this, "downloadUrl", _descriptor13, this);
      (0, _initializerDefineProperty2.default)(this, "isLoading", _descriptor14, this);
      (0, _initializerDefineProperty2.default)(this, "viewFilters", _descriptor15, this);
    }

    initialLoad() {
      // Reset starting vars
      this.filteredResults = null;
      this.selectedFilters = [];
      this.filterButtonDisabled = false;
      this.filterComponents = [{
        component: 'marketing/campaigns/campaign-filter'
      }];
    }

    get resultsStatus() {
      const count = this.filteredResults.length;

      if (count === 0) {
        return `${this.intl.t('no_results')}.`;
      }

      if (this.selectedFilters.length > 0) {
        // Filtered
        return count >= 300 ? this.intl.t('showing_the_first_X_filtered_results', {
          count: count
        }) : this.intl.t('found_X_filtered_results', {
          count: count
        });
      } else {
        // Not filtered
        return count >= 300 ? this.intl.t('showing_the_first_X_results', {
          count: count
        }) : this.intl.t('found_X_results', {
          count: count
        });
      }
    }

    get filters() {
      let filterOptions = FILTERS;

      if (this.access.get('isRetail') === false) {
        filterOptions = filterOptions.filter(item => item.id !== 'WASH_DRY_FOLD' && item.id !== 'GOODS');
      }

      return JSON.parse(JSON.stringify(filterOptions));
    }

    set filters(value) {
      return value;
    }

    get organization() {
      const organizationId = this.access.get('accessOrganization.id');
      return this.store.peekRecord('organization', organizationId);
    }

    get selectedRoomIds() {
      let roomIds = [];
      let selectedLocations = this.selectedLocations;

      if (selectedLocations) {
        selectedLocations.forEach(function (location) {
          if (location.original && location.original.roomId) {
            roomIds.push(location.original.roomId);
          }
        });
      } else if (this.store.peekAll('room').length === 1) {
        let rooms = this.store.peekAll('room');
        let onlyRoom = rooms.get('firstObject');
        roomIds.push(onlyRoom.get('id'));
      }

      return roomIds;
    }

    get columns() {
      let columnsArray = [{
        propertyName: 'firstName',
        title: `${this.intl.t('first_name')}`
      }, {
        propertyName: 'lastName',
        title: `${this.intl.t('last_name')}`
      }, {
        propertyName: 'lastVisit',
        title: `${this.intl.t('last_visit')}`,
        component: 'table/table-cell-date',
        customDateFormat: 'MMM DD, YYYY'
      }, {
        propertyName: 'servicesUsed',
        title: `${this.intl.t('usage_type')}`,
        component: 'table/table-cell-intl-loop',
        disableSorting: true
      }, {
        propertyName: 'dayOfWeek',
        title: `${this.intl.t('day_preference')}`,
        component: 'table/table-cell-date',
        customDateType: 'dayOfWeek'
      }, {
        propertyName: 'washerTypeDescription',
        title: `${this.intl.t('preferred_washer')}`,
        component: 'table/table-cell-machine-type',
        customIfNullUseIcon: true
      }, {
        propertyName: 'dryerTypeDescription',
        title: `${this.intl.t('preferred_dryer')}`,
        component: 'table/table-cell-machine-type',
        customIfNullUseIcon: true
      }, {
        propertyName: 'avgWdfSpend',
        title: `${this.intl.t('avg_wdf_spend')}`,
        component: 'table/table-cell-price',
        customIfNullUseIcon: true
      }, {
        propertyName: 'avgGoodsSpend',
        title: `${this.intl.t('avg_goods_spend')}`,
        component: 'table/table-cell-price',
        customIfNullUseIcon: true
      }, {
        propertyName: 'avgMachineSpend',
        title: `${this.intl.t('avg_self_service_spend')}`,
        component: 'table/table-cell-price',
        customIfNullUseIcon: true
      }, {
        propertyName: 'lifetimeSpend',
        title: `${this.intl.t('lifetime_spend')}`,
        component: 'table/table-cell-price',
        customIfNullUseIcon: true
      }];

      if (this.access.get('isRetail') === false) {
        columnsArray = columnsArray.filter(item => item.propertyName !== 'avgWdfSpend' && item.propertyName !== 'avgGoodsSpend');
      }

      if (this.access.isDemo) {
        columnsArray.splice(7, 2);
      }

      return columnsArray;
    }

    toggleLocationModal() {
      this.showLocationModal = !this.showLocationModal;
    }

    reset() {
      this.filteredResults = null;
      this.selectedFilters = [];
      this.filterButtonDisabled = false;
      this.filterComponents = [{
        component: 'marketing/campaigns/campaign-filter'
      }];
      this.filters = JSON.parse(JSON.stringify(FILTERS));
      this.viewFilters = false;
      setTimeout(() => {
        this.viewFilters = true;
      }, 250);
    }

    downloadCSV() {
      if (this.access.isDemo) {
        alert('Demo mode restricted.');
        return;
      }

      let self = this;
      let organizationId = this.access.get('accessOrganization.id');
      let roomIds = this.selectedRoomIds;
      let filters = this.selectedFilters;
      let payload = {
        'Params': []
      };
      filters.forEach(function (filter) {
        filter.options.forEach(function (option) {
          payload.Params.push(option);
        });
      });
      this.isProccessingDownload = true;
      this.ajax.request(`/organizations/${organizationId}/trackingDownload`, {
        method: 'GET',
        data: {
          params: JSON.stringify(payload),
          roomIds: `{${roomIds}}`,
          raw: true
        },
        dataType: 'text'
      }).then(function (results) {
        self.downloadUrl = results;
        self.isProccessingDownload = false;
        setTimeout(() => {
          document.getElementById('downloadLink').click();
        }, 200);
      }, function (results) {
        self.isProccessingDownload = false; // error message needed
      });
    }

    applyFilters() {
      let self = this;
      let organizationId = this.access.get('accessOrganization.id');
      let roomIds = this.selectedRoomIds;
      let filters = this.selectedFilters;
      let payload = {
        'Params': []
      };
      filters.forEach(function (filter) {
        filter.options.forEach(function (option) {
          payload.Params.push(option);
        });
      });
      this.isLoading = true;
      this.downloadUrl = null;
      this.ajax.request(`/organizations/${organizationId}/trackingPreview`, {
        method: 'GET',
        data: {
          params: JSON.stringify(payload),
          roomIds: `{${roomIds}}`,
          raw: true
        }
      }).then(function (results) {
        self.filteredResults = results.users;
        self.isLoading = false;
      }, function () {
        self.isLoading = false; // error message needed
      });
    }

    createCampaign() {
      let selectedFilters = this.selectedFilters;
      let roomIds = this.selectedRoomIds;
      localStorage.setItem('newCampaignLocations', JSON.stringify(roomIds));
      let payload = {
        'Params': []
      };
      selectedFilters.forEach(function (filter) {
        if (filter.options) {
          filter.options.forEach(function (option) {
            payload.Params.push(option);
          });
        }
      });
      localStorage.setItem('newCampaignFilters', JSON.stringify(payload));
      this.router.transitionTo('crm.campaigns.create');
    }

    filterChange() {
      let filters = this.selectedFilters;
      let self = this;
      let payload = {
        'Params': []
      };
      let part1 = false;
      let part2 = false;
      filters.forEach(function (filter) {
        if (filter.options) {
          // Needed a way to disable analytics button.
          filter.options.forEach(function (option) {
            if (option.Name === "LAST_VISIT_INTERVAL") {
              if (option.Value === "custom") {
                part1 = true;
                part2 = true;
                self.filterButtonDisabled = true;
              } else {
                self.filterButtonDisabled = false;
              }
            }

            if (option.Name === "LAST_VISIT_START_DATE") {
              if (!moment(option.Value).isValid()) {
                part1 = true;
              } else {
                part1 = false;
              }
            }

            if (option.Name === "LAST_VISIT_END_DATE") {
              if (!moment(option.Value).isValid()) {
                part2 = true;
              } else {
                part2 = false;
              }
            }

            if (part1 === false && part2 === false) {
              self.filterButtonDisabled = false;
            } else {
              self.filterButtonDisabled = true;
            }

            payload.Params.push(option);
          });
        }
      });

      if (this.filter !== JSON.stringify(payload)) {
        this.filter = JSON.stringify(payload);
      }
    }

    addFilter() {
      let filterComponents = this.filterComponents;
      filterComponents.addObject({
        component: 'marketing/campaigns/campaign-filter'
      });
      this.actions.filterChange;
    }

    removeFilter(index) {
      let filterComponents = this.filterComponents;
      filterComponents.removeObject(filterComponents[index]);
      this.actions.filterChange;
    }

    updateSelectedFilters(update) {
      // using classic "set" to update
      this.set('selectedFilters', update); // using classic "set" to update

      if (update.length === 1 && update[0].filter.id === 'DEADBEAT_USERS') {
        this.set('filterComponents', this.filterComponents.filter((filter, idx) => {
          return idx == update[0].index;
        }));
        this.filterChange(update);
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "ajax", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "router", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "filteredResults", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedFilters", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "filterButtonDisabled", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "filterComponents", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [{
        component: 'marketing/campaigns/campaign-filter'
      }];
    }
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "checkboxOptions", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        'three_state': false,
        'cascade': 'down+undetermined',
        'load_checked_fix': true
      };
    }
  }), _descriptor11 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "showLocationModal", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor12 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isProccessingDownload", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor13 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "downloadUrl", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor14 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isLoading", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "initialLoad", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "initialLoad"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedRoomIds", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "selectedRoomIds"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "toggleLocationModal", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "toggleLocationModal"), _class.prototype), _descriptor15 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "viewFilters", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "reset", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "reset"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "downloadCSV", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "downloadCSV"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "applyFilters", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "applyFilters"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "createCampaign", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "createCampaign"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "filterChange", [_dec23], Object.getOwnPropertyDescriptor(_class.prototype, "filterChange"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "addFilter", [_dec24], Object.getOwnPropertyDescriptor(_class.prototype, "addFilter"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "removeFilter", [_dec25], Object.getOwnPropertyDescriptor(_class.prototype, "removeFilter"), _class.prototype)), _class));
  _exports.default = MarketingAnaltyicsContainerComponent;
});