define("alliance-business-suite/components/reset-password-section", ["exports", "jquery", "alliance-business-suite/config/environment", "ember-cp-validations"], function (_exports, _jquery, _environment, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    password: [(0, _emberCpValidations.validator)('format', {
      type: 'password'
    }), // validator('length', {
    //   min: 8,
    //   max: 24
    // }),
    (0, _emberCpValidations.validator)('format', {
      // regex: /^(?!.* )(?=.*\d)(?=.*[A-Z]).{8,24}$/,
      regex: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=\S*$).{8,24}$/,
      message: 'Password must include at least one upper case letter, one lower case letter, and a number'
    })],
    confirmPassword: [(0, _emberCpValidations.validator)('format', {
      type: 'password'
    }), (0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('confirmation', {
      on: 'password',
      message: 'Email addresses do not match'
    })]
  });

  var _default = Ember.Component.extend(Validations, {
    access: Ember.inject.service(),
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    intl: Ember.inject.service(),
    notifications: Ember.inject.service(),
    email: null,
    isMobileSuccess: false,

    didReceiveAttrs() {
      this._super(...arguments);

      Ember.set(this, 'access.resetPasswordToken', Ember.get(this, 'token'));
    },

    actions: {
      async changePassword() {
        Ember.set(this, 'isLoading', true);
        let self = this;
        let password = Ember.get(this, 'password');
        let token = Ember.get(this, 'token');
        let isMobile = Ember.get(this, 'isMobile');
        await this.ajax.request('/auth/setPassword', {
          method: 'POST',
          data: JSON.stringify({
            password,
            token
          })
        }).then(response => {
          if (isMobile) {
            Ember.set(this, 'isMobileSuccess', true);
          } else {
            self.get('router').transitionTo('login');
            Ember.get(this, 'notifications').info(this.intl.t('new_password_ready_please_log_in'), {
              autoClear: true,
              clearDuration: 20000
            });
          }

          return response;
        }).catch((error
        /* , jqXHR */
        ) => {
          Ember.get(this, 'notifications').error(this.intl.t('please_try_again'), {
            autoClear: true,
            clearDuration: 6000
          });
          Ember.set(this, 'hasError', true);
          Ember.set(this, 'isLoading', false);
          Ember.run.later(() => {
            Ember.set(this, 'hasError', false);
          }, 1200);
          return error;
        });
      }

    }
  });

  _exports.default = _default;
});