define("alliance-business-suite/components/mixins/machine-controls", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    intl: Ember.inject.service(),
    isCommandProcessing: false,
    currentCommand: null,

    async processCommand(uri, machine, startReason) {
      let requestBody = {
        method: 'POST'
      };
      let self = this;

      if (startReason) {
        let obj = {
          params: {
            remoteStartReason: startReason
          }
        };
        requestBody.data = obj;
      }

      let response, command;
      Ember.set(this, 'isCommandProcessing', true);

      try {
        response = await this.ajax.request(uri, requestBody).then(() => {
          Ember.set(this, 'isCommandProcessing', false);
          Ember.get(this, 'notifications').info(this.intl.t('your_command_has_been_sent_to_the_machine'), {
            autoClear: true,
            clearDuration: 10000
          });

          if (startReason) {
            this.close();
          }
        });
      } catch (error) {
        Ember.set(this, 'isCommandProcessing', false);

        if (error.payload.message) {
          if (error.payload.message == "REMOTE_START_THRESHOLD_REACHED_NO_OVERRIDE") {
            Ember.get(this, 'notifications').error(this.intl.t('error_remote_start_threshold_reached'), {
              autoClear: true,
              clearDuration: 60000
            });
          }

          if (error.payload.message == "REMOTE_START_THRESHOLD_REACHED") {
            if (confirm(self.intl.t('confirm_force_start'))) {
              requestBody.data.params.forceRemoteStart = true;
              Ember.set(self, 'isCommandProcessing', true);
              self.ajax.request(uri, requestBody).then(() => {
                Ember.get(self, 'notifications').info(self.intl.t('your_command_has_been_sent_to_the_machine'), {
                  autoClear: true,
                  clearDuration: 10000
                });
              });
            }
          }

          this.close();
          return;
        }

        Ember.get(this, 'notifications').error(this.intl.t('please_try_again'), {
          autoClear: true,
          clearDuration: 60000
        });
        return; // fail silently
      }

      if (response) {
        try {
          Ember.set(this, 'isCommandProcessing', false);
          command = await Ember.get(this, 'store').findRecord('command', response.data.id);
          Ember.get(machine, 'commands').addObject(command);
        } catch (error) {
          Ember.set(this, 'isCommandProcessing', false);
          console.error(error);
          return; // fail silently
        }
      }
    },

    async processOutOfOrder(machineId, setToOurOfOrder) {
      let requestBody = {
        method: 'POST'
      };
      let response, command;
      let obj = {
        params: {
          enabled: setToOurOfOrder
        }
      };
      requestBody.data = obj;
      Ember.set(this, 'isCommandProcessing', true);

      try {
        response = await this.ajax.request(`/machines/${machineId}/programOutOfOrder`, requestBody).then(() => {
          Ember.set(this, 'isCommandProcessing', false);
          Ember.get(this, 'notifications').info(this.intl.t('your_command_has_been_sent_to_the_machine'), {
            autoClear: true,
            clearDuration: 60000
          });
        });
      } catch (error) {
        Ember.set(this, 'isCommandProcessing', false);
        Ember.get(this, 'notifications').error(this.intl.t('please_try_again'), {
          autoClear: true,
          clearDuration: 60000
        });
        return; // fail silently
      }

      if (response) {
        try {
          Ember.set(this, 'isCommandProcessing', false);
          command = await Ember.get(this, 'store').findRecord('command', response.data.id);
          Ember.get(machineId, 'commands').addObject(command);
        } catch (error) {
          Ember.set(this, 'isCommandProcessing', false);
          return; // fail silently
        }
      }
    },

    actions: {
      setMachineCommand(value) {
        Ember.set(this, 'currentCommand', value);
      },

      async sendMachineCommand() {
        let model = Ember.get(this, 'model');
        let currentCommand = Ember.get(this, 'currentCommand') || Ember.get(this, 'machineCommandOptions.firstObject.value');
        let id = Ember.get(model, 'id');
        let uri = `/machines/${id}/${currentCommand}`;

        if (currentCommand == 'start') {
          this.send('toggleStartMachineModal');
          return;
        }

        if (currentCommand == 'fetchProgram') {
          this.send('toggleProgramModal');
          return;
        }

        if (currentCommand == 'topOff') {
          this.set('startDryerType', 'topOff');
          this.send('toggleStartDryerModal');
          return;
        }

        if (currentCommand == 'advanceToCycle') {
          this.send('toggleAdvanceToCycle');
          return;
        }

        if (currentCommand == 'startDryerWithMinutes') {
          this.set('startDryerType', 'startDryer');
          this.send('toggleStartDryerModal');
          return;
        }

        if (currentCommand == 'programOutOfOrder') {
          await this.processOutOfOrder(id, false);
          return;
        }

        if (currentCommand == 'programBackInOrder') {
          await this.processOutOfOrder(id, true);
          return;
        }

        if (currentCommand) {
          await this.processCommand(uri, model);
        }
      },

      async requestAudit(machine) {
        let id = Ember.get(machine, 'id');
        let uri = `/machines/${id}/requestAudit`;
        await this.processCommand(uri, machine);
      },

      async resetToReady(machine) {
        let id = Ember.get(machine, 'id');
        let uri = `/machines/${id}/resetToReady`;
        await this.processCommand(uri, machine);
      },

      async requestPrice(machine) {
        let id = Ember.get(machine, 'id');
        let uri = `/machines/${id}/requestPrice`;
        await this.processCommand(uri, machine);
      },

      async machineCommand(command, machine) {
        let id = Ember.get(machine, 'id');
        let uri = `/machines/${id}/${command}`;
        await this.processCommand(uri, machine);
      },

      async remoteStart(command, machine, reason) {
        let id = Ember.get(machine, 'id');
        let uri = `/machines/${id}/${command}`;
      },

      async remoteVend() {
        Ember.set(this, 'isLoading', false);
      }

    },
    auditCommandObserver: Ember.observer('model', 'model.auditCommand', function () {
      this._processCommandChange('auditCommandActive', 'model.auditCommand');
    }),
    auditCommandActive: false,
    resetCommandObserver: Ember.observer('model', 'model.resetCommand', function () {
      this._processCommandChange('resetCommandActive', 'model.resetCommand');
    }),
    resetCommandActive: false,
    priceCommandObserver: Ember.observer('model', 'model.priceCommand', function () {
      this._processCommandChange('priceCommandActive', 'model.priceCommand');
    }),
    priceCommandActive: false,
    vendCommandObserver: Ember.observer('model', 'model.vendCommand', function () {
      this._processCommandChange('vendCommandActive', 'model.vendCommand');
    }),
    vendCommandActive: false,

    _processCommandChange(activeFlag, commandProp) {
      let command = Ember.get(this, commandProp);
      Ember.set(this, activeFlag, !Ember.isEmpty(command));
      this.notifyPropertyChange(activeFlag);
    }

  });

  _exports.default = _default;
});