define("alliance-business-suite/components/machines/programs/pricing/acas-tlw", ["exports", "alliance-business-suite/mixins/machines/programs/acas-options", "alliance-business-suite/utils/machine-programming-find-options"], function (_exports, _acasOptions, _machineProgrammingFindOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_acasOptions.default, {
    machineAuditType: Ember.computed.alias('machineProgram.machineAuditType.id'),
    machineCode: Ember.computed.alias('machineProgram.machineCode'),
    // HB machines have "Bonus Cycle" and not "Lucky Cycle"
    luckyCycleLabel: Ember.computed('machineAuditType', function () {
      if (["TITAN_T1", "TITAN_2"].includes(this.machineAuditType)) {
        return "bonus_cycle";
      } else {
        return "lucky_cycle";
      }
    }),
    cycleCount: Ember.computed('machineAuditType', function () {
      // The A4 has 9 cycles. Others have 6.
      if (["ACAS_A4", "TITAN_T4"].includes(this.machineAuditType)) {
        return 9;
      } else {
        return 6;
      }
    }),
    indexValues: Ember.computed("machineAuditType", function () {
      return [0, 1, 2, 3];
    }),
    optionsDefaultCycles: (0, _machineProgrammingFindOptions.findOptions)([{
      machineAttributes: ["ACAS_A44"],
      // 6 cycles
      options: [{
        val: 1,
        label: 'normal_hot'
      }, {
        val: 2,
        label: 'normal_warm'
      }, {
        val: 3,
        label: 'normal_cold'
      }, {
        val: 4,
        label: 'delicate_hot'
      }, {
        val: 5,
        label: 'delicate_warm'
      }, {
        val: 6,
        label: 'delicate_cold'
      }]
    }, {
      machineAttributes: ["ACAS_A3"],
      // International 6 cycles
      options: [{
        val: 1,
        label: 'normal_90'
      }, {
        val: 2,
        label: 'normal_60'
      }, {
        val: 3,
        label: 'normal_40'
      }, {
        val: 4,
        label: 'perm_press_60'
      }, {
        val: 5,
        label: 'delicate_30'
      }, {
        val: 6,
        label: 'delicate_no_heat'
      }]
    }, {
      machineAttributes: ["ACAS_A4", "TITAN_T4", "TITAN_T1"],
      // Domestic 9 cycles
      options: [{
        val: 1,
        label: 'normal_hot'
      }, {
        val: 2,
        label: 'normal_warm'
      }, {
        val: 3,
        label: 'normal_cold'
      }, {
        val: 4,
        label: 'perm_press_hot'
      }, {
        val: 5,
        label: 'perm_press_warm'
      }, {
        val: 6,
        label: 'perm_press_cold'
      }, {
        val: 7,
        label: 'delicate_hot'
      }, {
        val: 8,
        label: 'delicate_warm'
      }, {
        val: 9,
        label: 'delicate_cold'
      }]
    }]),
    optionsDefaultModifiers: (0, _machineProgrammingFindOptions.findOptions)([{
      machineAttributes: ["ACAS_A4_FLW", "ACAS_A3_FLW", "ACAS_A4_TLW", "TITAN_T4_TLW"],
      options: [{
        val: 0,
        label: 'light',
        abbrev: 'light_abbrev'
      }, {
        val: 1,
        label: 'medium',
        abbrev: 'medium_abbrev'
      }, {
        val: 2,
        label: 'heavy',
        abbrev: 'heavy_abbrev'
      }]
    }, {
      machineAttributes: ["ACAS_A4", "ACAS_A44", "TITAN_T1", "TITAN_T4"],
      options: [{
        val: 0,
        label: 'regular',
        abbrev: 'regular_abbrev'
      }, {
        val: 1,
        label: 'deluxe',
        abbrev: 'deluxe_abbrev'
      }, {
        val: 2,
        label: 'ultra',
        abbrev: 'ultra_abbrev'
      }]
    }, {
      machineAttributes: ["ACAS_A3"],
      options: [{
        val: 0,
        label: 'A',
        abbrev: 'a_abbrev'
      }, {
        val: 1,
        label: 'B',
        abbrev: 'b_abbrev'
      }, {
        val: 2,
        label: 'C',
        abbrev: 'c_abbrev'
      }]
    }]),
    optionsDisplaySettings: (0, _machineProgrammingFindOptions.findOptions)([{
      machineAttributes: ["ACAS_A4", "ACAS_A3", "ACAS_A44"],
      options: [{
        val: 0,
        label: '0',
        precision: 0
      }, {
        val: 2,
        label: '0.00',
        precision: 2
      }, {
        val: 1,
        label: '0.000',
        precision: 3
      }]
    }, {
      machineAttributes: ["TITAN_T1", "TITAN_T2", "TITAN_T4"],
      options: [{
        val: 0,
        label: '0',
        precision: 0
      }, {
        val: 2,
        label: '0.00',
        precision: 2
      }, {
        val: 1,
        label: '0.000',
        precision: 3
      }]
    }]),
    basketDiameter: Ember.computed.alias('machineProgram.machineType.machineSpeeds.basketDiameter'),
    machineSpeeds: Ember.computed.alias('machineProgram.machineType.machineSpeeds'),
    summaryText: Ember.computed('AutoShutdownEnabled', 'LowPowerEnabled', 'Enabled', 'Saturday', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'StartMonth', 'StartDate', 'StartYear', 'EndMonth', 'EndDate', 'EndYear', 'StartHour', 'StartMinute', 'Duration', 'HeatMinutes', 'HeatSeconds', 'NoHeatMinutes', 'NoHeatSeconds', 'Coin1TopOffMinutes', 'Coin1TopOffSeconds', 'Coin2TopOffMinutes', 'Coin2TopOffSeconds', 'HeatPrice', 'NoHeatPrice', function () {
      let output = '';
      let intl = this.intl;

      if (!this.Enabled || this.Enabled === false) {
        return 'Off';
      } // What is the type


      if (this.LowPowerEnabled && this.AutoShutdownEnabled) {
        output += 'Low Power & Auto Shutdown - ';
      } else if (this.LowPowerEnabled) {
        output += 'Low Power - ';
      } else if (this.AutoShutdownEnabled) {
        output += 'Auto Shutdown - ';
      } else {
        output += 'On - ';
      } // When: Days (dates) (hours)
      // Every Day; Weekends; Weekdays; Mon-Wed, Fri


      let daysText = "";

      if (this.Saturday && this.Sunday && this.Monday && this.Tuesday && this.Wednesday && this.Thursday && this.Friday) {
        daysText = 'Every Day, ';
      } else {
        // Weekends
        if (this.Saturday && this.Sunday) {
          daysText += ' Weekends, ';
        } else if (this.Saturday) {
          daysText += 'Sat, ';
        } else if (this.Sunday) {
          daysText += 'Sun, ';
        } // Weekdays


        if (this.Monday && this.Tuesday && this.Wednesday && this.Thursday && this.Friday) {
          daysText += 'Weekdays, ';
        } else {
          if (this.Monday) {
            daysText += 'Mon, ';
          }

          if (this.Tuesday) {
            daysText += 'Tue, ';
          }

          if (this.Wednesday) {
            daysText += 'Wed, ';
          }

          if (this.Thursday) {
            daysText += 'Thu, ';
          }

          if (this.Friday) {
            daysText += 'Fri, ';
          }
        }

        if (daysText) {
          output += daysText;
        } else {
          output += `${intl.t("no_days_selected")}, `;
        } // Dates - this has some logic. If the day, month, or year has a value of 0, date limit is not enabled. If start = 1/1/2000 and end = 12/31/2099, also has no limit.


        if (this.StartMonth == 0 || this.StartDate == 0 || this.EndMonth == 0 || this.EndDate == 0 || this.StartMonth == 1 && this.StartDate == 1 && this.StartYear == 0 && this.EndMonth == 12 && this.EndDate == 31 && this.EndYear == 99) {// No date limits
        } else {
          output += `(${this.StartMonth}/${this.StartDate}/${this.StartYear}&ndash;${this.EndMonth}/${this.EndDate}/${this.EndYear})  `;
        } // Hours - this seems to have some logic. If duration is 0, not enabled.


        if (this.Duration > 0) {
          // We'll need to figure out the end time
          let startHour = numberToHour(this.StartHour);
          let endHour = numberToHour(Number(this.StartHour) + Number(this.Duration));
          output += `(${startHour}&ndash;${endHour}), `;
        }
      }

      return output.length > 2 ? htmlSafe(output.substr(0, output.length - 2)) : output;
    })
  });

  _exports.default = _default;
});