define("alliance-business-suite/models/machine-audit-group-type", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    typeName: attr('string'),
    name: attr('string'),
    // fix for inconsistent name prop from it
    groupName: Ember.computed('typeName', 'name', function () {
      return this.typeName || this.name;
    })
  });

  _exports.default = _default;
});