define("alliance-business-suite/templates/components/employees/schedule-block-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "heJG5Ddp",
    "block": "{\"symbols\":[\"@currentShiftState\",\"@toggleEditBar\",\"@employee\",\"@startTime\",\"@endTime\"],\"statements\":[[10,\"div\"],[14,0,\"employee-block\"],[12],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[\"block-bar-item bar-\",[32,1]]]],[12],[2,\"\\n    \"],[11,\"div\"],[24,0,\"block-bar-info\"],[4,[38,0],[\"click\",[32,2]],null],[12],[2,\"\\n      \"],[1,[32,3]],[2,\" \\n    \"],[13],[2,\"\\n\\n    \"],[8,\"employees/time-bar\",[],[[\"@startTime\",\"@endTime\",\"@updateTime\"],[[32,4],[32,5],[32,0,[\"updateTime\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"on\"]}",
    "moduleName": "alliance-business-suite/templates/components/employees/schedule-block-bar.hbs"
  });

  _exports.default = _default;
});