define("alliance-business-suite/initializers/intl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'intl',

    initialize(app) {
      app.inject('route', 'intl', 'service:intl');
    }

  };
  _exports.default = _default;
});