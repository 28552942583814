define("alliance-business-suite/templates/components/table/machines/table-cell-machine-current-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "B7AMmDMK",
    "block": "{\"symbols\":[\"@record\"],\"statements\":[[8,\"machines/m-current-user\",[],[[\"@model\",\"@status\"],[[32,1],[32,1,[\"machineCurrentStatus\",\"statusId\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "alliance-business-suite/templates/components/table/machines/table-cell-machine-current-user.hbs"
  });

  _exports.default = _default;
});