define("alliance-business-suite/models/machine-program", ["exports", "@ember-data/model", "alliance-business-suite/utils/iso-date", "alliance-business-suite/components/machines/programs/cycles/steps-primus-mc10-wx", "alliance-business-suite/utils/fix-range", "alliance-business-suite/utils/y2k", "alliance-business-suite/utils/machine-programming-tabs", "alliance-business-suite/utils/machine-programming-presets", "alliance-business-suite/components/machines/programs/variables"], function (_exports, _model, _isoDate, _stepsPrimusMc10Wx, _fixRange, _y2k, _machineProgrammingTabs, _machineProgrammingPresets, _variables) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // TODO: These constants suck and should only be used temporarily. We need a global place to keep all this stuff. Right now they also exist in the mgd-options.js file.
  const MGD_NUMERIC_SEPARATOR = [{
    label: "None",
    val: 0,
    decimalSymbol: '',
    decimalPlaces: 0,
    divideBy: 1,
    thousandsSymbol: ''
  }, {
    label: "Dot1",
    val: 1,
    decimalSymbol: '.',
    decimalPlaces: 1,
    divideBy: 1,
    thousandsSymbol: ','
  }, {
    label: "Dot2",
    val: 2,
    decimalSymbol: '.',
    decimalPlaces: 2,
    divideBy: 1,
    thousandsSymbol: ','
  }, {
    label: "Dot3",
    val: 3,
    decimalSymbol: '.',
    decimalPlaces: 3,
    divideBy: 1,
    thousandsSymbol: ','
  }, {
    label: "Comma1",
    val: 4,
    decimalSymbol: ',',
    decimalPlaces: 1,
    divideBy: 1,
    thousandsSymbol: '.'
  }, {
    label: "Comma2",
    val: 5,
    decimalSymbol: ',',
    decimalPlaces: 2,
    divideBy: 1,
    thousandsSymbol: '.'
  }, {
    label: "Comma3",
    val: 6,
    decimalSymbol: ',',
    decimalPlaces: 3,
    divideBy: 1,
    thousandsSymbol: '.'
  }];
  const MC10_NUMERIC_SEPARATOR = [{
    label: "None",
    val: 0,
    decimalSymbol: '',
    decimalPlaces: 0,
    divideBy: 1,
    thousandsSymbol: ''
  }, {
    label: "Dot1",
    val: 1,
    decimalSymbol: '.',
    decimalPlaces: 1,
    divideBy: 10,
    thousandsSymbol: ','
  }, {
    label: "Dot2",
    val: 2,
    decimalSymbol: '.',
    decimalPlaces: 2,
    divideBy: 100,
    thousandsSymbol: ','
  }, {
    label: "Dot3",
    val: 3,
    decimalSymbol: '.',
    decimalPlaces: 3,
    divideBy: 1000,
    thousandsSymbol: ','
  }, {
    label: "Comma1",
    val: 4,
    decimalSymbol: ',',
    decimalPlaces: 1,
    divideBy: 10,
    thousandsSymbol: '.'
  }, {
    label: "Comma2",
    val: 5,
    decimalSymbol: ',',
    decimalPlaces: 2,
    divideBy: 100,
    thousandsSymbol: '.'
  }, {
    label: "Comma3",
    val: 6,
    decimalSymbol: ',',
    decimalPlaces: 3,
    divideBy: 1000,
    thousandsSymbol: '.'
  }];
  const MGD_CURRENCY_TYPE = [{
    label: "None",
    val: 0,
    abbr: ""
  }, {
    label: "Dollar",
    val: 1,
    abbr: "$"
  }, {
    label: "Pound",
    val: 2,
    abbr: "£"
  }, {
    label: "Yen",
    val: 3,
    abbr: "¥"
  }, {
    label: "Won",
    val: 4,
    abbr: "₩"
  }, {
    label: "Euro",
    val: 5,
    abbr: "€"
  }, {
    label: "Rupee",
    val: 6,
    abbr: "₹"
  }, {
    label: "TurkishLira",
    val: 7,
    abbr: "₺"
  }, {
    label: "Ruble",
    val: 8,
    abbr: "₽"
  }, {
    label: "Forint",
    val: 9,
    abbr: "Ft"
  }, {
    label: "Koruna",
    val: 10,
    abbr: "Kč"
  }, {
    label: "Krona",
    val: 11,
    abbr: "kr"
  }, {
    label: "Kuna",
    val: 12,
    abbr: "kn"
  }, {
    label: "Rand",
    val: 13,
    abbr: "R"
  }, {
    label: "Real",
    val: 14,
    abbr: "R$"
  }, {
    label: "Token",
    val: 15,
    abbr: "Tk"
  }, {
    label: "Zloty",
    val: 16,
    abbr: "zł"
  }, {
    label: "Rupiah",
    val: 17,
    abbr: ""
  }, {
    label: "Ringgit",
    val: 18,
    abbr: ""
  }, {
    label: "Baht",
    val: 19,
    abbr: ""
  }, {
    label: "Kip",
    val: 20,
    abbr: ""
  }, {
    label: "Kyat",
    val: 21,
    abbr: ""
  }, {
    label: "Dong",
    val: 22,
    abbr: ""
  }];
  const MC10_CURRENCY_TYPE = [{
    label: "None",
    val: 0,
    abbr: ""
  }, {
    label: "Dollar",
    val: 1,
    abbr: "$"
  }, {
    label: "Pound",
    val: 2,
    abbr: "£"
  }, {
    label: "Yen",
    val: 3,
    abbr: "¥"
  }, {
    label: "Won",
    val: 4,
    abbr: "₩"
  }, {
    label: "Euro",
    val: 5,
    abbr: "€"
  }, {
    label: "Rupee",
    val: 6,
    abbr: "₹"
  }, {
    label: "TurkishLira",
    val: 7,
    abbr: "₺"
  }, {
    label: "Ruble",
    val: 8,
    abbr: "₽"
  }, {
    label: "Forint",
    val: 9,
    abbr: "Ft"
  }, {
    label: "Koruna",
    val: 10,
    abbr: "Kč"
  }, {
    label: "Krona",
    val: 11,
    abbr: "kr"
  }, {
    label: "Kuna",
    val: 12,
    abbr: "kn"
  }, {
    label: "Rand",
    val: 13,
    abbr: "R"
  }, {
    label: "Real",
    val: 14,
    abbr: "R$"
  }, {
    label: "Token",
    val: 15,
    abbr: "Tk"
  }, {
    label: "Zloty",
    val: 16,
    abbr: "zł"
  }, {
    label: "Rupiah",
    val: 17,
    abbr: ""
  }, {
    label: "Ringgit",
    val: 18,
    abbr: ""
  }, {
    label: "Baht",
    val: 19,
    abbr: ""
  }, {
    label: "Kip",
    val: 20,
    abbr: ""
  }, {
    label: "Kyat",
    val: 21,
    abbr: ""
  }, {
    label: "Dong",
    val: 22,
    abbr: ""
  }]; // TODO: Some of the computed properties below may not be needed.

  var _default = _model.default.extend({
    access: Ember.inject.service(),
    brand: Ember.inject.service(),
    intl: Ember.inject.service(),
    machines: Ember.inject.service(),
    settings: (0, _model.attr)(),
    settingBytes: (0, _model.attr)(),
    transcoderError: Ember.computed.alias('settingBytes.Message'),
    description: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    advanced: (0, _model.attr)('boolean'),
    isActive: (0, _model.attr)('boolean'),
    createdAt: (0, _model.attr)(),
    updatedBy: (0, _model.attr)(),
    updatedAt: (0, _model.attr)(),
    //#region Global Settings
    // These three properties are used to identify machines.
    _machineTypeCode: Ember.computed.alias('machineType.generalMachineType.code'),
    // WX
    _machineAuditType: Ember.computed.alias('machineAuditType.id'),
    // ACAS_A4 ... was machineAuditType.name, which was wrong for some audit types
    _machineCode: Ember.computed('machineCode', function () {
      // ACAS_A4_WX
      return Ember.String.underscore(this.machineCode).toUpperCase();
    }),
    _machineGeneration: Ember.computed('machineAuditType.id', function () {
      // ACAS, MIDAS, etc.
      // Several audit types all belong to the same "family", but are distinct because of different brands.
      // This CP will group them into a new category so we don't have to check against each brand with logical operators.
      switch (Ember.get(this, 'machineAuditType.id')) {
        case "CENTURION_C4":
        case "CENTURION_C3":
        case "CENTURION_C4_ALS_DRIVE":
        case "CENTURION_C3_ALS_DRIVE":
          return "CENTURION";

        case "ACAS_A2":
        case "ACAS_A3":
        case "ACAS_A4":
        case "ACAS_A11":
        case "ACAS_A22":
        case "ACAS_A1":
        case "ACAS_A44":
          return "ACAS";

        case "ACA_OPL_A9":
        case "ACA_OPL_A30":
        case "ACA_OPL_A14":
        case "ACA_OPL_A13":
          return "ACA_OPL";

        case "MGD_M1":
        case "MGD_M7":
          return "MGD";

        case "MC10_OPL_1":
        case "MC10_OPL_2":
        case "MC10_OPL_3":
        case "MC10_OPL_4":
        case "MC10_OPL_5":
        case "MC10_EZOPL_M2":
        case "MC10_EZOPL_M3":
        case "MC10_EZOPL_M4":
        case "MC10_EZOPL_M5":
          return "MC10_OPL";

        case "MC10_M2":
        case "MC10_M3":
        case "MC10_M4":
        case "MC10_M5": // case "MC10_COMBO_M1": // Ignore the combos.

        case "PRIMUS_MC10":
          return "MC10";
        // TODO: This is a problem, since the brand is PRIMUS, but the machine type is MC10.
        // case "PRIMUS_MC10":
        //   return "PRIMUS";
        // case "PRIMUS_EZOPL_MC10":
        //   return "PRIMUS_OPL";

        case "PRIMUS_EZOPL_MC10":
          return "MC10_EZOPL";

        case "MIDAS_VEND_M1":
        case "MIDAS_VEND_M2":
        case "MIDAS_VEND_M3":
        case "MIDAS_VEND_M4":
        case "MIDAS_VEND_M5":
          return "MIDAS";

        case "MIDAS_EZOPL_M1":
        case "MIDAS_EZOPL_M2":
        case "MIDAS_EZOPL_M3":
        case "MIDAS_EZOPL_M4":
        case "MIDAS_EZOPL_M5":
        case "MIDAS_OPL_2":
        case "MIDAS_OPL_1":
        case "MIDAS_OPL_3":
        case "MIDAS_OPL_4":
        case "MIDAS_OPL_5":
          return "MIDAS_OPL";

        case "TITAN_T1":
        case "TITAN_T2":
        case "TITAN_T3":
        case "TITAN_T4":
        case "TITAN_T11":
        case "TITAN_T20":
        case "TITAN_T21":
        case "TITAN_T22":
        case "TITAN_T31":
        case "TITAN_T44":
          return "TITAN";

        case "TITAN_OPL_T9":
        case "TITAN_OPL_T13":
        case "TITAN_OPL_T14":
        case "TITAN_OPL_T30":
          return "TITAN_OPL";

        case "MC5_EC_Primus":
        case "MC5_EC_IPSO":
        case "MC5_EC_SQ":
        case "MC5_EC_UniMac":
        case "MC5_EC_Generic":
        case "MC5_FC_Primus":
        case "MC5_FC_IPSO":
        case "MC5_FC_SQ":
        case "MC5_FC_UniMac":
        case "MC5_FC_Generic":
          return "MC5";

        default:
          return "GENERIC";
      }
    }),
    _machineAuditTypeFamily: Ember.computed('machineAuditType.id', function () {
      // Several audit types all belong to the same "family", but are distinct because of different brands.
      // This CP will group them into a new category so we don't have to check against each brand with logical operators.
      switch (Ember.get(this, 'machineAuditType.id')) {
        // eslint-disable-line ember/no-get
        case "CENTURION_C4":
        case "CENTURION_C4_ALS_DRIVE":
          return "CENTURION_C4";

        case "CENTURION_C3":
        case "CENTURION_C3_ALS_DRIVE":
          return "CENTURION_C3";
        // case "ACAS_A4":
        // case "ACAS_A44":
        // case "ACAS_A3":
        // case "ACAS_A2":
        // case "ACAS_A22":
        //   return "ACAS";
        // case "MIDAS":
        //   return "MIDAS";
        // case "TITAN_T4":
        //   return "TITAN"

        case "MC10_COMBO_M1":
        case "MC10_COMBO_M2":
        case "MC10_COMBO_M3":
        case "MC10_COMBO_M4":
        case "MC10_COMBO_M5":
          return "MC10_COMBO";

        case "MC10_OPL_1":
        case "MC10_OPL_2":
        case "MC10_OPL_3":
        case "MC10_OPL_4":
        case "MC10_OPL_5":
          return "MC10_OPL";

        case "MGD_M1":
        case "MGD_M2":
        case "MGD_M3":
        case "MGD_M4":
        case "MGD_M5":
        case "MGD_M6":
        case "MGD_M7":
          return "MGD";

        case "MC5_EC_Primus":
        case "MC5_EC_IPSO":
        case "MC5_EC_SQ":
        case "MC5_EC_UniMac":
        case "MC5_EC_Generic":
          return "MC5_EC";

        case "MC5_FC_Primus":
        case "MC5_FC_IPSO":
        case "MC5_FC_SQ":
        case "MC5_FC_UniMac":
        case "MC5_FC_Generic":
          return "MC5_FC";

        default:
          return Ember.get(this, 'machineAuditType.id');
        // eslint-disable-line ember/no-get
      }
    }),

    hasMachineAttribute(attributeNames) {
      // This helper function returns a boolean indicating if the provided `attributeName`
      // is found in the list of `_machineAttributes`. It lives in this model for simplicity
      // because it's used so often.
      if (Array.isArray(attributeNames)) {
        let found = false;
        attributeNames.forEach(attribute => {
          if (this._machineAttributes.includes(attribute)) {
            found = true;
          }
        });
        return found;
      } else {
        return this._machineAttributes.includes(attributeNames);
      }
    },

    _machineSize: Ember.computed('machineType.{id,name}', function () {
      const machineSize = Ember.get(this, 'machineType.name'); // Uppercase to normalize the machine size name.

      return machineSize ? machineSize.toUpperCase() : "SIZE_UNKNOWN";
    }),
    _machineCodeFamily: Ember.computed('_machineAuditTypeFamily', '_machineTypeCode', function () {
      // MC10_OPL_WX
      // Similar to _machineAuditTypeFamily, this attempts to remove the brand-specific audit types
      return `${this._machineAuditTypeFamily}_${this._machineTypeCode}`.toUpperCase();
    }),
    _machineAttributes: Ember.computed('access.isVended', 'machineAuditType.machineBrand.name', '_machineCode', '_machineCodeFamily', '_machineAuditType', '_machineAuditTypeFamily', '_machineGeneration', '_machineTypeCode', 'machineType.id', 'machineAuditType', '_machineSize', function () {
      // Returns a list of attributes used for determining if form elements should be displayed
      let brandName = Ember.get(this, "machineAuditType.machineBrand.name");
      let attributes = [this._machineCode, // MC10_OPL_WX, but could be something like MC10_OPL_1_WX
      this._machineCodeFamily, // MC10_OPL_WX (NEW FOR MATCHING)
      this._machineAuditType, // MC10_OPL, but could be something like MC10_OPL_1
      this._machineAuditTypeFamily, // MC10_OPL (NEW FOR MATCHING)
      this._machineGeneration, // MC10
      this._machineTypeCode, // WX
      brandName, // IPSO
      `${brandName}_${this._machineCode}`, `${brandName}_${this._machineCodeFamily}`, `${brandName}_${this._machineAuditType}`, `${brandName}_${this._machineAuditTypeFamily}`, `${brandName}_${this._machineGeneration}`, `${brandName}_${this._machineTypeCode}`, // (this.access.isVended === true) ? "VENDED" : "OPL" // VENDED | OPL
      Ember.get(this, "machineAuditType.isOpl") === true ? "OPL" : "VENDED", `SIZE_${this._machineSize}` // Return the named machine type with SIZE_ prefix
      ]; // Special sizing attributes for the MC10_OPL_WX

      if (this._machineCodeFamily === "MC10_OPL_WX") {
        let machineTypeId = Number(Ember.get(this, "machineType.id"));

        if (_variables.default.MC10_OPL.smallMachineSize.includes(machineTypeId)) {
          attributes.push("MC10_OPL_WX_SMALL_MACHINE");
        }

        if (_variables.default.MC10_OPL.midLargeMachineSize.includes(machineTypeId)) {
          attributes.push("MC10_OPL_WX_MID_LARGE_MACHINE");
        }
      }

      return attributes.uniq();
    }),
    _machineSettings: Ember.computed.reads("machineType.machineSettings"),
    _isCelsius: Ember.computed('_machineAuditType', 'settings.Program.{Misc.MiscConfig.TempUnits,TemperatureScaleCelsius,Misc.CelsiusEnabled,cycleRelatedMessage.temperatureUnitsToDisplay,Miscellaneous.CelsiusEnabled,Misc.MiscConfig.CelsiusEnabled}', function () {
      // If adding new properties, make sure they are listed above ^ to be observed.
      switch (this._machineAuditType) {
        case "MC5_EC_Primus":
        case "MC5_EC_IPSO":
        case "MC5_EC_SQ":
        case "MC5_EC_UniMac":
        case "MC5_EC_Generic":
        case "MC5_FC_Primus":
        case "MC5_FC_IPSO":
        case "MC5_FC_SQ":
        case "MC5_FC_UniMac":
        case "MC5_FC_Generic":
          return !this.settings.Program.Misc.MiscConfig.TempUnits;
        // false = Celsius

        case "CENTURION_C4":
        case "CENTURION_C3":
        case "CENTURION_C4_ALS_DRIVE":
        case "CENTURION_C3_ALS_DRIVE":
          return this.settings.Program.TemperatureScaleCelsius;

        case "ACAS_A4":
        case "ACAS_A44":
        case "ACAS_A3":
        case "ACAS_A2":
        case "ACAS_A22":
          return this.settings.Program.Miscellaneous.CelsiusEnabled;

        case "MIDAS":
          return this.settings.Program.Misc.MiscConfig.CelsiusEnabled;

        case "TITAN_T1":
        case "TITAN_T2":
        case "TITAN_T4":
          return this.settings.Program.cycleRelatedMessage.temperatureUnitsToDisplay;
        // TITAN stores as int, not boolean

        case "PRIMUS_MC10":
        case "MC10_OPL_1":
        case "MC10_OPL_2":
        case "MC10_OPL_3":
        case "MC10_OPL_4":
        case "MC10_OPL_5":
        case "MC10_COMBO_M1":
        case "MC10_COMBO_M2":
        case "MC10_COMBO_M3":
        case "MC10_COMBO_M4":
        case "MC10_COMBO_M5":
          return this.settings.Program.Misc.MiscConfig.CelsiusEnabled;

        case "MGD_M1":
        case "MGD_M7":
          return this.settings.Program.Misc.CelsiusEnabled;

        default:
          return undefined;
      }
    }),
    _decimals: Ember.computed('_machineAuditType', 'settings.Program.{Display.Coin1PriceSeparator,CoinPulse.DecimalPointCoinvendRelatedMessage.vendPriceDecimalPoint,CoinAndPulse.DecimalPointPosition,CoinPulse.DecimalCommaOptions}', function () {
      switch (this._machineAuditType) {
        case "MC5_EC_Primus":
        case "MC5_EC_IPSO":
        case "MC5_EC_SQ":
        case "MC5_EC_UniMac":
        case "MC5_EC_Generic":
          return this.settings.Program.CoinPulse.DecimalPointCoin;

        case "ACAS_A4":
        case "ACAS_A44":
        case "ACAS_A3":
        case "ACAS_A2":
        case "ACAS_A22":
          return this.settings.Program.CoinAndPulse.DecimalPointPosition;

        case "TITAN_T1":
        case "TITAN_T2":
        case "TITAN_T4":
          return this.settings.Program.vendRelatedMessage.vendPriceDecimalPoint;

        case "MGD_M1":
          let value = this.settings.Program.CoinPulse.DecimalCommaOptions;
          return MGD_NUMERIC_SEPARATOR.findBy("val", value)?.decimalPlaces || 0;

        case "MC10":
        case "PRIMUS_MC10":
        case "MC10_COMBO_M1":
        case "PRIMUS_EZOPL_MC10":
        case "MC10_EZOPL_M5":
        case "MC10_COMBO_M1":
        case "MC10_COMBO_M2":
        case "MC10_COMBO_M3":
        case "MC10_COMBO_M4":
        case "MC10_COMBO_M5":
          let mc10Value = this.settings.Program.Display.Coin1PriceSeparator;
          return MC10_NUMERIC_SEPARATOR.findBy("val", mc10Value)?.decimalPlaces || 0;

        default:
          return 0;
      }
    }),
    _showG: Ember.computed('_machineAuditType', 'settings.Program.{Display.DisplayExtractSpeedInG,Cycle.DisplaySpeedUnits}', function () {
      switch (this._machineAuditType) {
        case "ACAS_A4":
        case "ACAS_A44":
        case "ACAS_A3":
        case "ACAS_A2":
        case "ACAS_A22":
          return !!this.settings.Program.Cycle.DisplaySpeedUnits;

        case "MC10_COMBO_M1":
        case "MC10_COMBO_M2":
        case "MC10_COMBO_M3":
        case "MC10_COMBO_M4":
        case "MC10_COMBO_M5":
          return !!this.settings.Program.Display.DisplayExtractSpeedInGForce;

        case "TITAN_T1":
        case "TITAN_T2":
        case "TITAN_T4":
          return !!this.settings.Program.cycleRelatedMessage.speedUnit;

        case "MGD_M1":
        case "MGD_M7":
          return this.settings.Program.Display.DisplayExtractSpeedInG;

        case "MIDAS_VEND_M1":
        case "MIDAS_VEND_M2":
        case "MIDAS_VEND_M3":
        case "MIDAS_VEND_M4":
        case "MIDAS_VEND_M5":
          return this.settings.Program.Display.DisplayExtractSpeedInGForce_WX;

        default:
          return undefined;
      }
    }),
    _coinValue: Ember.computed('_machineAuditType', 'settings.Program.{vendRelatedMessage.coin1Vaue,CoinAndPulse.Coin1Value,CoinPulse.Coin1Value,CoinPulse.Coin1Config.Value}', function () {
      switch (this._machineAuditType) {
        case "CENTURION_C4":
        case "CENTURION_C3":
        case "CENTURION_C4_ALS_DRIVE":
        case "CENTURION_C3_ALS_DRIVE":
          return this.settings.Program.Coin1Value;

        case "ACAS_A4":
        case "ACAS_A44":
        case "ACAS_A3":
        case "ACAS_A2":
        case "ACAS_A22":
          return this.settings.Program.CoinAndPulse.Coin1Value;

        case "TITAN_T1":
        case "TITAN_T2":
        case "TITAN_T4":
          return this.settings.Program.vendRelatedMessage.coin1Vaue;

        case "MIDAS":
          return this.settings.Program.CoinPulse.Coin1Config.Value;

        case "MGD_M1":
        case "MC10_COMBO_M1":
        case "MC10_COMBO_M2":
        case "MC10_COMBO_M3":
        case "MC10_COMBO_M4":
        case "MC10_COMBO_M5":
          return this.settings.Program.CoinPulse.Coin1Value;

        default:
          return undefined;
      }
    }),
    _divideBy: Ember.computed('_machineAuditType', 'settings.Program.Display.Coin1PriceSeparator', function () {
      switch (this._machineAuditType) {
        case "ACAS_A4":
        case "ACAS_A44":
        case "ACAS_A3":
        case "ACAS_A2":
        case "ACAS_A22":
        case "TITAN_T1":
        case "TITAN_T2":
        case "TITAN_T4":
        case "MGD_M1":
          return 1;

        case "MC10":
        case "PRIMUS_MC10":
        case "MC10_COMBO_M1":
        case "PRIMUS_EZOPL_MC10":
        case "MC10_EZOPL_M5":
        case "MC10_COMBO_M1":
        case "MC10_COMBO_M2":
        case "MC10_COMBO_M3":
        case "MC10_COMBO_M4":
        case "MC10_COMBO_M5":
          let mc10Value = this.settings.Program.Display.Coin1PriceSeparator;
          return MC10_NUMERIC_SEPARATOR.findBy("val", mc10Value)?.divideBy || 1;

        default:
          return undefined;
      }
    }),
    _currencyDivideBy: Ember.computed('_machineAuditType', function () {
      switch (this._machineAuditType) {
        case "ACAS_A4":
        case "ACAS_A44":
        case "ACAS_A3":
        case "ACAS_A2":
        case "ACAS_A22":
        case "TITAN_T1":
        case "TITAN_T2":
        case "TITAN_T4":
        case "MGD_M1":
          return 100;

        default:
          return undefined;
      }
    }),
    _decimalSymbol: Ember.computed('_machineAuditType', 'settings.Program.{Display.Coin1PriceSeparator,CoinPulse.DecimalCommaOptions}', function () {
      switch (this._machineAuditType) {
        case "ACAS_A4":
        case "ACAS_A44":
        case "ACAS_A3":
        case "ACAS_A2":
        case "ACAS_A22":
        case "TITAN_T1":
        case "TITAN_T2":
        case "TITAN_T4":
          return '.';

        case "MGD_M1":
          let value = this.settings.Program.CoinPulse.DecimalCommaOptions;
          return MGD_NUMERIC_SEPARATOR.findBy("val", value)?.decimalSymbol || ".";

        case "MC10":
        case "PRIMUS_MC10":
        case "MC10_COMBO_M1":
        case "PRIMUS_EZOPL_MC10":
        case "MC10_EZOPL_M5":
        case "MC10_COMBO_M1":
        case "MC10_COMBO_M2":
        case "MC10_COMBO_M3":
        case "MC10_COMBO_M4":
        case "MC10_COMBO_M5":
          let mc10Value = this.settings.Program.Display.Coin1PriceSeparator;
          return MC10_NUMERIC_SEPARATOR.findBy("val", mc10Value)?.decimalSymbol || null;

        default:
          return undefined;
      }
    }),
    _thousandsSymbol: Ember.computed('_machineAuditType', 'settings.Program.{CoinPulse.DecimalCommaOptions,Display.Coin1PriceSeparator}', function () {
      switch (this._machineAuditType) {
        case "ACAS_A4":
        case "ACAS_A44":
        case "ACAS_A3":
        case "ACAS_A2":
        case "ACAS_A22":
        case "TITAN_T1":
        case "TITAN_T2":
        case "TITAN_T4":
          return ',';

        case "MGD_M1":
          let value = this.settings.Program.CoinPulse.DecimalCommaOptions;
          return MGD_NUMERIC_SEPARATOR.findBy("val", value)?.thousandsSymbol || ",";

        case "MC10":
        case "PRIMUS_MC10":
        case "MC10_COMBO_M1":
        case "PRIMUS_EZOPL_MC10":
        case "MC10_EZOPL_M5":
        case "MC10_COMBO_M1":
        case "MC10_COMBO_M2":
        case "MC10_COMBO_M3":
        case "MC10_COMBO_M4":
        case "MC10_COMBO_M5":
          let mc10Value = this.settings.Program.Display.Coin1PriceSeparator;
          return MC10_NUMERIC_SEPARATOR.findBy("val", mc10Value)?.thousandsSymbol || null;

        case "COMBO":
          return;

        default:
          return undefined;
      }
    }),
    _currencySymbol: Ember.computed('_machineAuditType', 'settings.Program.Price.{MonetaryUnit,Misc.Currency1.Symbol}', function () {
      switch (this._machineAuditType) {
        case "ACAS_A4":
        case "ACAS_A44":
        case "ACAS_A3":
        case "ACAS_A2":
        case "ACAS_A22":
        case "TITAN_T1":
        case "TITAN_T2":
        case "TITAN_T4":
          return "";

        case "MGD_M1":
          let value = this.settings.Program.Price.MonetaryUnit;
          return MGD_CURRENCY_TYPE.findBy("val", value)?.abbr || "";

        case "MC10":
        case "PRIMUS_MC10":
        case "MC10_COMBO_M1":
        case "PRIMUS_EZOPL_MC10":
        case "MC10_EZOPL_M5":
        case "MC10_COMBO_M1":
        case "MC10_COMBO_M2":
        case "MC10_COMBO_M3":
        case "MC10_COMBO_M4":
        case "MC10_COMBO_M5":
          let mc10Value = this.settings.Program.Price.Misc.Currency1.Symbol;
          return MC10_CURRENCY_TYPE.findBy("val", mc10Value)?.abbr || "";

        default:
          return undefined;
      }
    }),
    _currencySymbolPosition: Ember.computed('_machineAuditType', 'settings.Program.Price.{Misc.Currency1.PlaceAfter,IsMonetaryUnitAfterCurrency}', function () {
      switch (this._machineAuditType) {
        case "ACAS_A4":
        case "ACAS_A44":
        case "ACAS_A3":
        case "ACAS_A2":
        case "ACAS_A22":
        case "TITAN_T1":
        case "TITAN_T2":
        case "TITAN_T4":
          return "before";

        case "MGD_M1":
          return this.settings.Program.Price.IsMonetaryUnitAfterCurrency ? "after" : "before";

        case "MC10":
        case "PRIMUS_MC10":
        case "MC10_COMBO_M1":
        case "PRIMUS_EZOPL_MC10":
        case "MC10_EZOPL_M5":
        case "MC10_COMBO_M1":
        case "MC10_COMBO_M2":
        case "MC10_COMBO_M3":
        case "MC10_COMBO_M4":
        case "MC10_COMBO_M5":
          return this.settings.Program.Price.Misc.Currency1.PlaceAfter ? "after" : "before";

        default:
          return undefined;
      }
    }),
    _cycles: Ember.computed.alias('settings.Cycles'),
    // normalized array of cycles data
    _cycleTime: Ember.computed('_machineCodeFamily', 'settings.Cycles.CycleSteps.@each.{Time,Type,Enabled}', function () {
      // Calculates the cycle time
      let cycleTimes = [];

      switch (true) {
        case this._machineCodeFamily === "MGD_OPL_TUM":
          this._cycles.forEach((cycle, cycleIndex) => {
            let stepTime = 0;
            cycle.CycleSteps.forEach((step, stepIndex) => {
              if (step.Enabled) {
                stepTime = stepTime + step.Time;
              }
            });
            cycleTimes.push(stepTime);
          });

          console.log('*** cycletimes', cycleTimes);
          return cycleTimes;

        default:
          return undefined;
      }
    }),

    //#endregion
    init() {
      this._super(...arguments);

      this.initObservers();
    },

    validateDataOnSave() {
      /*
        We need to revalidate machine settings before we save them. This is a big
        problem for MC10, but may also be a concern for existing machine programs.
      */
      console.groupCollapsed("Machine program validation changes");
      this.machines.clearValidationLog();
      let logChanges = [];
      let machineSettings = Ember.get(this, 'machineType.machineSettings'); // eslint-disable-line ember/no-get

      const STEP_TYPE = {
        Soak: 6,
        Prewash: 13,
        PreWash: 13,
        Wash: 14,
        NoWash: 17,
        Rinse: 15,
        CoolDown: 7,
        FinalRinse: 16,
        Tumble: 18
      };

      if (['MC10_COMBO_WX'].includes(this._machineCodeFamily)) {// TODO: Add validation for Combo machine, which doesn't have all the properties the MC10 has.
        // set(this, 'settings.Program.Misc.MiscConfig.CelsiusEnabled', true);
      } // MC5 should not have Cycles


      if (['MC5_EC_TUM'].includes(this._machineCodeFamily)) {
        this.settings.Cycles = null;
      } // if (this.machineCode == 'primus-mc10-wx') {


      if (['PRIMUS_MC10_WX', 'MC10_OPL_WX'].includes(this._machineCodeFamily)) {
        let drainTypeSpin = 2; // Loop through every Cycle, and every Step

        this.settings.Cycles.forEach((cycle, cycleIndex) => {
          // MC10_OPL validation requirements
          if (['MC10_OPL_WX'].includes(this._machineCodeFamily)) {
            // Keep cycle.Number values sequential
            Ember.set(cycle, "Number", cycleIndex + 1); // Make sure these properties have their minimum values

            if (cycle.DisinfectTemperature === 0) {
              Ember.set(cycle, "DisinfectTemperature", 35);
            }

            if (cycle.DisinfectTimeMinutes === 0) {
              Ember.set(cycle, "DisinfectTimeMinutes", 1);
            }

            if (cycle.ProgrammableCycleTime === 0) {
              Ember.set(cycle, "ProgrammableCycleTime", 1);
            }
          }

          cycle.Steps.forEach((step, stepIndex) => {
            let logChange = function (message) {
              logChanges.push(`Cycle ${cycle.CycleName}, Step ${stepIndex + 1}: ${message}`);
            }; // INLETS


            let washType = step.Type;
            let inlets = this.settings.Program.Misc.TotalNumberOfInlets;
            let hasDrain2 = this.settings.Program.Misc.DrainValve2Enabled;
            let settings = (0, _stepsPrimusMc10Wx.inletConfiguration)(washType, inlets, hasDrain2);
            let maxValves = settings.maxValvesOn;
            let valves = settings.valves;
            let inletCount = 0; // Check each inlet...

            for (let i = 1; i <= 8; i++) {
              let inletChecked = Ember.get(step, `Inlets.Inlet${i}`);
              let inletEnabled = valves[i - 1]; // Turn off inlets that are not available

              if (inletChecked && !inletEnabled) {
                Ember.set(step, `Inlets.Inlet${i}`, false);
                inletChecked = false;
                logChange(`Inlet ${i} has been been changed to false because the inlet is not available.`);
              }

              if (inletChecked) {
                inletCount = inletCount + 1;
              } // Turn off inlets that exceed the max number


              if (inletChecked && inletCount > maxValves) {
                Ember.set(step, `Inlets.Inlet${i}`, false);
                logChange(`Inlet ${i} has been been changed to false because it exceeded the maximum number of inlets.`);
              }
            } // WASH SPEED


            if (step.WashSpeed < machineSettings.washRpmMin) {
              logChange(`WashSpeed of ${step.WashSpeed} exceeds min of ${machineSettings.washRpmMin}`);
              Ember.set(step, 'WashSpeed', machineSettings.washRpmMin);
            }

            if (step.WashSpeed > machineSettings.washRpmMax) {
              logChange(`WashSpeed of ${step.WashSpeed} exceeds max of ${machineSettings.washRpmMax}`);
              Ember.set(step, 'WashSpeed', machineSettings.washRpmMax);
            } // DRAIN SPEED


            if (step.DrainType == 3 || step.DrainType == 4) {
              // NoDrain and StaticDrain
              if (step.DrainSpeed != 0) {
                logChange(`DrainSpeed for DrainType of NoDrain and StaticDrain must be 0 (found ${step.DrainSpeed})`);
                Ember.set(step, 'DrainSpeed', 0);
              }
            } else {
              // Everything besides NoDrain and StaticDrain
              if (step.DrainType == drainTypeSpin) {
                if (step.DrainSpeed < machineSettings.spinRpmMin) {
                  logChange(`DrainSpeed of ${step.DrainSpeed} exceeds spin min of ${machineSettings.spinRpmMin}`);
                  Ember.set(step, 'DrainSpeed', machineSettings.spinRpmMin);
                }

                if (step.DrainSpeed > machineSettings.spinRpmMax) {
                  logChange(`DrainSpeed of ${step.DrainSpeed} exceeds spin max of ${machineSettings.spinRpmMax}`);
                  Ember.set(step, 'DrainSpeed', machineSettings.spinRpmMax);
                }
              } else {
                // wash
                if (step.DrainSpeed < machineSettings.washRpmMin) {
                  logChange(`DrainSpeed of ${step.DrainSpeed} exceeds wash min of ${machineSettings.spinRpmMin}`);
                  Ember.set(step, 'DrainSpeed', machineSettings.washRpmMin);
                }

                if (step.DrainSpeed > machineSettings.washRpmMax) {
                  logChange(`DrainSpeed of ${step.DrainSpeed} exceeds wash max of ${machineSettings.spinRpmMax}`);
                  Ember.set(step, 'DrainSpeed', machineSettings.washRpmMax);
                }
              }
            } // WATER LEVEL


            if (step.WaterLevelType == 4) {
              if (step.WaterLevel < machineSettings.waterMin) {
                Ember.set(step, 'WaterLevel', machineSettings.waterMin);
              }

              if (step.WaterLevel > machineSettings.waterMax) {
                Ember.set(step, 'WaterLevel', machineSettings.waterMax);
              }
            } else {
              if (step.WaterLevel !== 0) {
                Ember.set(step, 'WaterLevel', 0); // If global level, then WaterLevel is 0
              }
            } // DRAIN TYPE AND DRAIN VALVE


            let drainTypeDisabled = 3;

            if (step.DrainType == drainTypeDisabled) {
              logChange(`DrainValve is 0 since DrainType is disabled`);
              Ember.set(step, 'DrainValve', 0);
            } else if (!hasDrain2 && step.DrainValve != 1) {
              logChange(`DrainValve is 1 since machine has no drain 2`);
              Ember.set(step, 'DrainValve', 1);
            } // TEMPERATURE


            if (['MC10_OPL_WX'].includes(this._machineCodeFamily)) {
              // ONLY OPL MACHINES
              // Max values change based on step type.
              let initTemp = step.Temperature || 0;

              switch (true) {
                case step.Type === STEP_TYPE.Wash:
                  Ember.set(step, 'Temperature', (0, _fixRange.fixRange)(initTemp, 33, 197));
                  break;

                case step.Type === STEP_TYPE.FinalRinse:
                case step.Type === STEP_TYPE.NoWash:
                case step.Type === STEP_TYPE.Rinse:
                case step.Type === STEP_TYPE.Tumble:
                  Ember.set(step, 'Temperature', (0, _fixRange.fixRange)(initTemp, 33, 194));
                  break;

                case step.Type === STEP_TYPE.CoolDown:
                  Ember.set(step, 'Temperature', (0, _fixRange.fixRange)(initTemp, 33, 140));
                  break;

                case step.Type === STEP_TYPE.PreWash:
                case step.Type === STEP_TYPE.Soak:
                  Ember.set(step, 'Temperature', (0, _fixRange.fixRange)(initTemp, 33, 113));
                  break;
              }

              if (initTemp != step.Temperature) {
                logChanges.push(`Temperature was changed from ${initTemp} to ${step.Temperature}`);
              }
            }
          });
        }); // Check global values for water level (this is new with OPL machines)

        logChanges.push(`Checking that LowWaterLevel value of ${this.settings.Program.Cycle.LowWaterLevel} is between ${machineSettings.waterMin} and ${machineSettings.waterMax}.`);
        logChanges.push(`Checking that MediumWaterLevel value of ${this.settings.Program.Cycle.MediumWaterLevel} is between ${machineSettings.waterMin} and ${machineSettings.waterMax}.`);
        logChanges.push(`Checking that HighWaterLevel value of ${this.settings.Program.Cycle.HighWaterLevel} is between ${machineSettings.waterMin} and ${machineSettings.waterMax}.`);
        Ember.set(this, 'settings.Program.Cycle.LowWaterLevel', (0, _fixRange.fixRange)(this.settings.Program.Cycle.LowWaterLevel, machineSettings.waterMin, machineSettings.waterMax));
        Ember.set(this, 'settings.Program.Cycle.MediumWaterLevel', (0, _fixRange.fixRange)(this.settings.Program.Cycle.MediumWaterLevel, machineSettings.waterMin, machineSettings.waterMax));
        Ember.set(this, 'settings.Program.Cycle.HighWaterLevel', (0, _fixRange.fixRange)(this.settings.Program.Cycle.HighWaterLevel, machineSettings.waterMin, machineSettings.waterMax));
        console.log('Machine program validation changes', logChanges); // eslint-disable-line no-console

        this.machines.saveValidationLog(logChanges);
      }

      if (['TITAN_T4_FLW', 'TITAN_T1_FLW', 'TITAN_T2_FLW'].includes(this._machineCodeFamily)) {
        // // Loop through every Cycle, and every Step
        // this.settings.Cycles.forEach((cycle, cycleIndex) => {
        //   // Cycle stuff here
        //   cycle.Steps.forEach((step, stepIndex) => {
        //     // Step stuff here
        //   })
        // })
        // Loop through Special Vend
        this.settings.SpecialVend.forEach(sv => {
          if (sv.extractSpeedLimit === 0) {
            Ember.set(sv, "extractSpeedLimit", 6);
          }
        });
        console.log('Machine program validation changes', logChanges); // eslint-disable-line no-console

        this.machines.saveValidationLog(logChanges);
      }

      if (['MGD_OPL_WX', 'MGD_FLW'].includes(this._machineCodeFamily)) {
        this.settings.Cycles.forEach((cycle, cycleIndex) => {
          // Keep cycle.CycleNumber values sequential
          Ember.set(cycle, "CycleNumber", cycleIndex + 1); // Set cycle.ValidStepCount to the total number of steps

          Ember.set(cycle, "ValidStepCount", cycle.CycleSteps.length);
          console.log('Machine program validation changes', logChanges); // eslint-disable-line no-console

          this.machines.saveValidationLog(logChanges);
        });
      }

      if (['MGD_OPL_TUM'].includes(this._machineCodeFamily)) {
        let TIME_DRY = 0;
        let NO_HEAT = 3;
        let HEAT_TIME_LIMIT = 99;
        let NO_HEAT_TIME_LIMIT = 240; // For each cycle with type TIME_DRY, the sum of active step times cannot exceed 99 if heat is selected on any active step, or 240 if all active steps have No Heat.

        this.settings.Cycles.forEach((cycle, cycleIndex) => {
          if (cycle.Enabled && cycle.Type === TIME_DRY) {
            // Add up total time
            let totalTime = 0;
            let hasHeat = false; // Find total time and if heat or not

            cycle.CycleSteps.forEach((step, stepIndex) => {
              if (step.Enabled === true) {
                totalTime = totalTime + Number(step.Time);
              }

              if (step.CustomTemp !== NO_HEAT) {
                hasHeat = true;
              }
            });
            let timeLimit = hasHeat ? HEAT_TIME_LIMIT : NO_HEAT_TIME_LIMIT;

            if (totalTime > timeLimit) {
              // If time is exceeded, we need to reduce all times equally to bring the total within the limits
              let divisor = timeLimit / totalTime;
              logChanges.push(`Cycle ${cycleIndex} time is ${totalTime}, which exceeds the limit of ${timeLimit}. Reducing step times by ${divisor}.`);
              cycle.CycleSteps.forEach((step, stepIndex) => {
                if (step.Enabled === true) {
                  let originalTime = step.Time;
                  let newTime = Math.floor(step.Time * divisor);
                  Ember.set(step, 'Time', newTime);
                  logChanges.push(`Cycle ${cycleIndex} Step ${stepIndex}. Time changed from ${originalTime} to ${newTime}.`);
                }
              });
            }
          }
        });
        console.log('Machine program validation changes', logChanges); // eslint-disable-line no-console

        this.machines.saveValidationLog(logChanges);
      }

      console.groupEnd();
    },

    compatibleMachineTypes: Ember.computed('machineCode', 'machineType', 'machineTypeCode', function () {
      // TODO: This should be moved to the machine-types model

      /*
        Some machine programs are only compatible with specific machine types.
        For example, Midas WX has a stack machine, but other WX machines do
        not have stack, and should not display those in the Sizes drop-down.
      */
      // let defaults = {
      //   DRY: [20, 21, 22, 23, 24],
      //   TUM: [30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51],
      //   WX: [100, 101, 102, 103, 104, 105, 106, 107, 108, 120, 121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 151, 152]
      // }
      let defaults = {
        DRY: [20, 21, 22],
        TUM: [30, 32, 33, 37, 42, 51, 52, 53],
        WX: [100, 101, 103, 104, 105, 106, 107]
      };
      let list = [{
        machineCodes: ['midas-tum', 'midas-vend-m1-tum', 'midas-vend-m2-tum', 'midas-ezopl-m3-tum', 'midas-ezopl-m5-tum'],
        compatible: [30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 53, 41, 42]
      }, {
        machineCodes: ['midas-vend-m1-wx', 'midas-vend-m2-wx', 'midas-wx'],
        compatible: [100, 101, 102, 103, 104, 105, 106, 107, 108, 120, 121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 151, 152]
      }, {
        machineCodes: ['mgd-m7-wx'],
        compatible: [120, 128, 129, 130, 131, 132, 133, 134]
      }, {
        machineCodes: ['mgd-m7-tum'],
        compatible: [30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 46, 43, 44, 45]
      }, {
        machineCodes: ['primus-mc10-wx', 'mc10-wx'],
        compatible: [242, 243, 244, 245, 246, 247, 248]
      }, // MC10 OPL machines have different sizes based on brands
      {
        machineCodes: ['mc10-opl-1-wx', 'mc10-opl-2-wx', 'mc10-opl-4-wx', 'mc10-opl-5-wx'],
        compatible: [242, 243, 244, 245, 246, 247, 248, 255, 256, 257, 258, 260, 262]
      }, {
        machineCodes: ['mc10-opl-3-wx'],
        compatible: [242, 243, 244, 245, 246, 247, 248, 255, 256, 257, 259, 261, 263]
      }, {
        // MC5
        machineCodes: ['mc5-ec-primus-tum', 'mc5-ec-ipso-tum', 'mc5-ec-sq-tum', 'mc5-ec-unimac-tum', 'mc5-fc-primus-tum', 'mc5-fc-ipso-tum', 'mc5-fc-sq-tum', 'mc5-fc-unimac-tum'],
        compatible: [281, 270, 271, 272, 273, 274, 275, 276, 277, 278, 279, 280] // TODO: These came from machine-type model...DRY!

      }, {
        // MC10_COMBO
        machineCodes: ['mc10-combo-m1-cbo', 'mc10-combo-m2-cbo', 'mc10-combo-m3-cbo', 'mc10-combo-m4-cbo', 'mc10-combo-m5-cbo'],
        compatible: [345, 347]
      }]; // First see if this machineCode is in the list ^

      let foundCode = list.find(item => {
        return item.machineCodes.includes(this.machineCode);
      });

      if (foundCode) {
        return foundCode.compatible;
      } else {
        return defaults[this.machineTypeCode];
      }
    }),
    machineTypeCode: Ember.computed.alias('machineType.generalMachineType.code'),
    // WX
    machineDescription: Ember.computed('machineAuditType.machineBrand.abbrev', 'machineAuditType.id', 'machineTypeCode', function () {
      let type = this.intl.t(this.machineTypeCode);
      let auditType = Ember.get(this, 'machineAuditType.id');
      let brand = Ember.get(this, "machineAuditType.machineBrand.abbrev");
      let machineName = this.intl.t(`${brand}.${auditType}`.toUpperCase());
      return `${machineName} ${type}`;
    }),

    willDestroy() {
      // Clean up the observers we created
      let observerList = Ember.get(this, 'observerList');

      if (!Ember.isNone(Ember.get(this, 'observerList'))) {
        observerList.forEach(keyName => {
          this.removeObserver(keyName, this, 'dateTimeDidChange');
        });
      }

      this._super(...arguments);
    },

    watchSettings: Ember.observer('settings', function () {
      if (Ember.isNone(Ember.get(this, 'observerList'))) {
        this.initObservers();
      }
    }),

    initObservers() {
      let objectNames = ['settings.SpecialVend', 'settings.SpecialVend.Items', 'settings.Program.LowPowerShutdown_A', 'settings.Program.LowPowerShutdown_B', 'settings.Program.LowPowerShutdown_C', 'settings.Program.LowPowerShutdown_D', 'settings.Program.SpecialVend_A', 'settings.Program.SpecialVend_B', 'settings.Program.SpecialVend_C', 'settings.Program.SpecialVend_D', 'settings.Program.Miscellaneous.LowPowerShutDown1', 'settings.Program.Miscellaneous.LowPowerShutDown2', 'settings.Program.Miscellaneous.LowPowerShutDown3', 'settings.Program.Miscellaneous.LowPowerShutDown4', 'settings.Program.Miscellaneous.LuckyCycleInfo', 'settings.Program.Misc.LowPowerShutdown'];
      let propertyNames = ['EndDateTime', 'StartDateTime'];
      let keysToObserve = ['Duration', 'EndDate', 'EndMonth', 'EndYear', 'LengthInHours', 'StartDate', 'StartHour', 'StartMinute', 'StartMonth', 'StartYear'];
      objectNames.forEach(objName => {
        // Check for the presence of StartDateTime in each of the objectNames
        let obj = Ember.get(this, objName);

        if (!Ember.isNone(obj)) {
          // Check if this is an array of objects, in which case we need to watch each object separately
          let isArray = Ember.typeOf(obj) == 'array'; // Object exists, now look for the properties...

          let checkArray = [];

          if (isArray) {
            obj.forEach((item, index) => {
              checkArray.push(`${objName}.${index}`);
            });
          } else {
            checkArray.push(objName);
          }

          checkArray.forEach(objName => {
            // New hack added 2020-03-13 to fix 4-digit years for StartYear and EndYear.
            if (this.machineCode == 'acas-a4-tum') {
              ['EndYear', 'StartYear'].forEach(prop => {
                let value = Ember.get(this, `${objName}.${prop}`);

                if (value && Number(value) > 99) {
                  console.log(`Fixing 4-digit year value in ${objName}.${prop}`); // eslint-disable-line no-console

                  Ember.set(this, `${objName}.${prop}`, Number(value.toString().slice(-2)));
                }
              });
            } // Check if the property name exists


            let hasProperty = propertyNames.any(prop => {
              return !Ember.isNone(Ember.get(this, `${objName}.${prop}`));
            }); // Add observers if this property exists

            if (hasProperty) {
              // We found the prop in this obj and now need to watch for changes on the different keys
              keysToObserve.forEach(key => {
                // If the key exists, add an observer
                let keyName = `${objName}.${key}`;

                if (!Ember.isNone(Ember.get(this, keyName))) {
                  this.addObserver(keyName, this, 'dateTimeDidChange'); // Keep track of observers added

                  let observerList = Ember.get(this, 'observerList') || [];
                  observerList.pushObject(keyName);
                  Ember.set(this, 'observerList', observerList);
                }
              });
            }
          });
        }
      });
    },

    dateTimeDidChange(sender, key, value, rev) {
      /*
       *  When dealing with dates, the month value starts at 0 for January. This is important!
       */
      let obj = key.split('.');
      obj.pop(); // Need to see if we have EndDateTime or StartDateTime

      let propertyName = obj.join('.');
      let propertyObject = Ember.get(this, propertyName);
      let {
        EndDate,
        EndMonth,
        EndYear,
        StartDate,
        StartHour,
        StartMinute,
        StartMonth,
        StartYear,
        Duration,
        LengthInHours
      } = Ember.get(this, propertyName); // if (StartMonth > 0) {
      //   StartMonth--; // We are working with the month property, which is Jan == 1. Needs to be JavaScript Jan == 0.
      // }
      // if (EndMonth > 0) {
      //   EndMonth--; // We are working with the month property, which is Jan == 1. Needs to be JavaScript Jan == 0.
      // }

      StartMonth = (0, _y2k.fixMonth)(StartMonth);
      EndMonth = (0, _y2k.fixMonth)(EndMonth);
      StartYear = (0, _y2k.fixYear)(StartYear);
      EndYear = (0, _y2k.fixYear)(EndYear);
      StartDate = (0, _y2k.fixDate)(StartDate);
      EndDate = (0, _y2k.fixDate)(EndDate); // StartDateTime has time of 00:00:00 when Limit Dates is enabled (and StartYear is 1900)

      if (!Ember.isNone(Ember.get(propertyObject, 'StartDateTime'))) {
        let date = (0, _isoDate.isoDate)({
          year: StartYear,
          month: StartMonth,
          date: StartDate,
          // hours: (StartYear == 1900) ? 0 :StartHour,
          // minutes: (StartYear == 1900) ? 0 : StartMinute
          // If not using Limit Dates, we set year to 1900 but need correct hour and minute.
          hours: StartHour || 0,
          minutes: StartMinute || 0
        });
        Ember.set(propertyObject, 'StartDateTime', date);
        console.log('StartDateTime set to', date); // eslint-disable-line no-console
      } // The EndDateTime _always_ has a time of 00:00:00.


      if (!Ember.isNone(Ember.get(propertyObject, 'EndDateTime'))) {
        let date = (0, _isoDate.isoDate)({
          year: EndYear,
          month: EndMonth,
          date: EndDate
        });
        Ember.set(propertyObject, 'EndDateTime', date); // console.log('EndDateTime set to', date); // eslint-disable-line no-console
      }
    },

    auditTypeCode: Ember.computed('machineAuditType.id', function () {
      // Temporarily change MIDAS to midas-ezopl-m3-tum
      // if (get(this, 'machineAuditType.id') == 'MIDAS') {
      //   return 'MIDAS-EZOPL-M3';
      // }
      return Ember.get(this, 'machineAuditType.id').replace(/_/g, '-');
    }),
    isVended: Ember.computed('machineAuditType', function () {
      return Ember.get(this, 'machineAuditType.isVended');
    }),
    isOpl: Ember.computed('machineAuditType', function () {
      return Ember.get(this, 'machineAuditType.isOpl');
    }),
    machineCode: Ember.computed('machineTypeCode', 'auditTypeCode', function () {
      return `${this.auditTypeCode}-${this.machineTypeCode}`.toLowerCase();
    }),
    isSupported: Ember.computed('_machineAuditType', 'machineCode', 'machineAuditType.machineBrand.name', function () {
      /*
        For a machine program to be supported:
        1) Tabs must be configured and active
        2) Machine presets must be configured and active for the machine brand
      */
      let machineBrand = Ember.get(this, 'machineAuditType.machineBrand.name');
      return (0, _machineProgrammingTabs.hasTabs)(this.machineCode) && (0, _machineProgrammingPresets.hasPreset)(this._machineAuditType, machineBrand);
    }),
    tabList: Ember.computed('machineCode', function () {
      let item = _machineProgrammingTabs.tabs.findBy('id', this.machineCode);

      if (!item) {
        item = _machineProgrammingTabs.tabs.findBy('id', 'unsupported');
      }

      return item.tabs;
    }),
    machineName: Ember.computed.alias('machineCode'),
    // For backwards-compatibility
    // For backwards-compatibility. Did alias machineCode but broke when mapping A3/A44 to A4 components.
    formName: Ember.computed.alias('filename'),
    filename: Ember.computed('machineCode', function () {
      let item = _machineProgrammingTabs.tabs.findBy('id', this.machineCode); // It's possible to 'get' a machine program for a machine we do not support.


      if (!item) {
        item = _machineProgrammingTabs.tabs.findBy('id', 'unsupported');
      }

      return item.filename;
    }),
    organization: (0, _model.belongsTo)('organization', {
      // inverse: null
      inverse: 'machinePrograms'
    }),
    machineAuditGroupType: (0, _model.belongsTo)('machineAuditGroupType', {
      inverse: null
    }),
    machineControlName: Ember.computed('machineAuditType.{id,machineBrand.abbrev}', function () {
      let brand = Ember.get(this, 'machineAuditType.machineBrand.abbrev');
      let auditType = Ember.get(this, "machineAuditType.id");
      let key = `${brand}.${auditType}`.toUpperCase();
      return this.intl.t(key, {
        htmlSafe: true,
        default: "&mdash;"
      });
    }),
    machineAuditType: (0, _model.belongsTo)('machineAuditType', {
      inverse: null
    }),
    auditType: Ember.computed.alias('machineAuditType'),
    machineType: (0, _model.belongsTo)('machineType', {
      inverse: null
    }),
    createdBy: (0, _model.belongsTo)('user', {
      inverse: null
    }),
    brushLabel: Ember.computed.alias('name').readOnly(),
    brushSublabel: Ember.computed('machineAuditGroupType', {
      get() {
        let machineAuditGroupType = this.machineAuditGroupType;
        return `${Ember.get(machineAuditGroupType, 'groupName')} Program`;
      }

    }).readOnly()
  });

  _exports.default = _default;
});