define("alliance-business-suite/mixins/machines/programs/cycles/cycle-global-mgd-opl", ["exports", "jquery", "ember-copy", "alliance-business-suite/utils/cycles-values", "alliance-business-suite/utils/convert-temp", "alliance-business-suite/utils/led"], function (_exports, _jquery, _emberCopy, _cyclesValues, _convertTemp, _led) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    // eslint-disable-line ember/no-new-mixins
    intl: Ember.inject.service(),
    notifications: Ember.inject.service(),
    selectedCycle: null,
    customMessage1: Ember.computed('model.settings.Program.Cycle.CustomHoldMessageDisplay1Digits', function () {
      return (0, _led.ledCodeToString)(Ember.get(this, 'model.settings.Program.Cycle.CustomHoldMessageDisplay1Digits'));
    }),
    customMessage2: Ember.computed('model.settings.Program.Cycle.CustomHoldMessageDisplay2Digits', function () {
      return (0, _led.ledCodeToString)(Ember.get(this, 'model.settings.Program.Cycle.CustomHoldMessageDisplay2Digits'));
    }),
    basketDiameter: Ember.computed('model.machineType.id', function () {
      // This may be deprecated;
      return Ember.get(this, 'model.machineType.machineSpeeds.basketDiameter');
    }),
    availableSteps: Ember.computed('model', function () {
      return Ember.get(this, 'cycleSettings.stepMetaData').filterBy('enabled');
    }),
    machineSpeeds: Ember.computed('model.machineType.id', function () {
      return Ember.get(this, 'model.machineType.machineSpeeds');
    }),
    cycleList: Ember.computed('model', function () {
      return Ember.get(this, 'model.settings.Cycles');
    }),
    cycleNames: Ember.computed('model.settings.Cycles', function () {
      return Ember.get(this, 'cycleSettings.cycles');
    }),
    stepList: Ember.computed('selectedCycle', 'selectedCycle.[]', function () {
      return Ember.get(this, 'selectedCycle.CycleSteps');
    }),
    stepTypeName: Ember.computed(function (type) {
      return Ember.get(this, 'cycleSettings.options.Type').findBy('Type', Ember.get(type, 'Type')).get('TypeName');
    }),
    // This is the default mapping. Specific machines may need to override this.
    flushoutTime: Ember.computed('model.settings.Program.Cycle.GlobalFlushoutTimeForFillSteps', function () {
      return Ember.get(this, 'model.settings.Program.Cycle.GlobalFlushoutTimeForFillSteps') || 'UNKNOWN';
    }),

    closeControls() {
      // Hides any "row-controls" currently open for adding steps
      this.$('.row-controls').removeClass('show');
    },

    _CoolDownWaterTemperature: Ember.computed('CoolDownWaterTemperature', 'celsiusEnabled', {
      get() {
        let temp = this.CoolDownWaterTemperature; // Always F

        let isCelsius = this.celsiusEnabled;
        return isCelsius ? (0, _convertTemp.toC)(temp) : temp; // Converts to C if needed
      },

      set(key, value) {
        let isCelsius = this.celsiusEnabled;
        Ember.set(this, 'CoolDownWaterTemperature', isCelsius ? (0, _convertTemp.toF)(value) : value);
        return value;
      }

    }),
    _HotWaterTemperature: Ember.computed('HotWaterTemperature', 'celsiusEnabled', {
      get() {
        let temp = this.HotWaterTemperature; // Always F

        let isCelsius = this.celsiusEnabled;
        return isCelsius ? (0, _convertTemp.toC)(temp) : temp; // Converts to C if needed
      },

      set(key, value) {
        let isCelsius = this.celsiusEnabled;
        Ember.set(this, 'HotWaterTemperature', isCelsius ? (0, _convertTemp.toF)(value) : value);
        return value;
      }

    }),
    _WarmWaterTemperature: Ember.computed('WarmWaterTemperature', 'celsiusEnabled', {
      get() {
        let temp = this.WarmWaterTemperature; // Always F

        let isCelsius = this.celsiusEnabled;
        return isCelsius ? (0, _convertTemp.toC)(temp) : temp; // Converts to C if needed
      },

      set(key, value) {
        let isCelsius = this.celsiusEnabled;
        Ember.set(this, 'WarmWaterTemperature', isCelsius ? (0, _convertTemp.toF)(value) : value);
        return value;
      }

    }),
    _ColdWaterTemperature: Ember.computed('ColdWaterTemperature', 'celsiusEnabled', {
      get() {
        let temp = this.ColdWaterTemperature; // Always F

        let isCelsius = this.celsiusEnabled;
        return isCelsius ? (0, _convertTemp.toC)(temp) : temp; // Converts to C if needed
      },

      set(key, value) {
        let isCelsius = this.celsiusEnabled;
        Ember.set(this, 'ColdWaterTemperature', isCelsius ? (0, _convertTemp.toF)(value) : value);
        return value;
      }

    }),

    // #region Component Lifecycle Hooks
    didInsertElement() {
      this._super(...arguments);

      let self = this; // Check for past cycle and step

      let cycleIndex = 0;
      let stepIndex = 0;
      let stepList = Ember.get(self, 'stepList'); // TODO: Make sure we don't go out of bounds

      if (cycleIndex != null && stepIndex != null) {
        this.send('selectCycle', cycleIndex, true);
        stepList = Ember.get(self, 'stepList'); // this.send('selectCycle', get(this, 'cycleList').objectAt(cycle), cycle, true);

        if (stepList) {
          this.send('selectStep', stepList.objectAt(stepIndex)); // this.send('selectStep', stepList.objectAt(stepIndex), stepIndex);
        }
      } else {
        this.send('selectStep', Ember.get(this, 'stepList.firstObject'));
        Ember.set(this, 'selectedCycle', Ember.get(this, 'cycleList.firstObject'));
        Ember.set(this, 'selectedCycleIndex', 0);
        Ember.set(this, 'selectedStep', Ember.get(this, 'stepList.firstObject'));
        Ember.set(this, 'selectedStepIndex', 0);
      }
    },

    // #endregion
    // #region Aliases for global values...temperatures, water levels, etc. Override when property names are different.
    useG: Ember.computed('model.settings.Program.Cycle.DisplaySpeedUnits', function () {
      // Generic machines with no size will always use G. They have a basketSize of 0
      return Ember.get(this, 'model.machineType.machineSettings.basketDiameter') == 0 ? true : !!Ember.get(this, 'model.settings.Program.Cycle.DisplaySpeedUnits'); // 0 = RPM, 1 = G
    }),
    highWaterLevel: Ember.computed.alias('model.settings.Program.Cycle.HighWaterLevel'),
    mediumWaterLevel: Ember.computed.alias('model.settings.Program.Cycle.MediumWaterLevel'),
    lowWaterLevel: Ember.computed.alias('model.settings.Program.Cycle.LowWaterLevel'),
    isCelsius: Ember.computed.alias('model.settings.Program.Miscellaneous.CelsiusEnabled'),
    temperatureScale: Ember.computed('isCelsius', function () {
      return this.isCelsius ? 'C' : 'F';
    }),
    CoolDownWaterTemperature: Ember.computed.alias('model.settings.Program.Cycle.CoolDownWaterTemperature'),
    HotWaterTemperature: Ember.computed.alias('model.settings.Program.Cycle.HotWaterTemperature'),
    WarmWaterTemperature: Ember.computed.alias('model.settings.Program.Cycle.WarmWaterTemperature'),
    ColdWaterTemperature: Ember.computed.alias('model.settings.Program.Cycle.ColdWaterTemperature'),
    // #endregion
    // #region MOD object for calculating step and cycle time. Override when property names are different.
    // Get mods for this machine. TODO: See if we can DRY this up a little.
    mods: Ember.computed('model.settings.Program.Price.{Key1ModifierOptionsPrewash,Key1ModifierOptionsExtraRinse,Key2ModifierOptionsPrewash,Key2ModifierOptionsExtraRinse,Key1ModifierOptionsExtraWashStepsAgitateTime,CycleModifierKey1AddedExtraWashAgitateTime,Key1ModifierOptionsExtraRinseStepsAgitateTime,CycleModifierKey1AddedExtraRinseAgitateTime,Key2ModifierOptionsExtraWashStepsAgitateTime,CycleModifierKey2AddedExtraWashAgitateTime,Key2ModifierOptionsExtraRinseStepsAgitateTime,CycleModifierKey2AddedExtraRinseAgitateTime}', function () {
      // THIS GETS KINDA CRAZY!
      // let mod11 = get(this, 'model.settings.Program.Price.Key1ModifierOptionsPrewash');
      // let mod12 = get(this, 'model.settings.Program.Price.Key1ModifierOptionsExtraRinse')
      // let mod21 = get(this, 'model.settings.Program.Price.Key2ModifierOptionsPrewash');
      // let mod22 = get(this, 'model.settings.Program.Price.Key2ModifierOptionsExtraRinse')
      // Key1ModifierOptionsExtraWashStepsAgitateTime: false,
      // Key1ModifierOptionsExtraRinseStepsAgitateTime: false,
      // Key2ModifierOptionsExtraWashStepsAgitateTime: false,
      // Key2ModifierOptionsExtraRinseStepsAgitateTime: false,
      // CycleModifierKey1AddedExtraWashAgitateTime: 2,
      // CycleModifierKey1AddedExtraRinseAgitateTime: 2,
      // CycleModifierKey2AddedExtraWashAgitateTime: 4,
      // CycleModifierKey2AddedExtraRinseAgitateTime: 4,

      /*
      Soil levels can have additional time added to their Wash and Rinse steps in the Pricing tab. We need to
      see if these extras are enabled, and if so, add that time using the following rules.
       Additional Wash - Time is added to all Agitate steps with a Label of Wash.
      Additional Rinse - Time is added to all Agitate steps with a Label of Rinse 1.
      */
      let model = Ember.get(this, 'model.settings.Program.Price');
      let allMods = [{
        // No Modifiers
        mod1: false,
        mod2: false,
        additionalWashMinutes: 0,
        additionalRinseMinutes: 0
      }];
      return allMods;
    }),
    // #endregion
    clipboard: {
      type: null,
      contents: null,
      title: null
    },
    // #region Actions
    actions: {
      // TODO: Remove for production
      showModel() {
        console.log('model', this.model); // eslint-disable-line no-console
      },

      showCycles() {
        console.log('cycles', this.get('model.settings.Cycles.CycleSteps')); // eslint-disable-line no-console
      },

      selectCycle(value, init) {
        // Value can be the cycle object or the index of the cycle
        let index, cycle;

        if (isNaN(value)) {
          // we have an object
          cycle = value;
          index = this.cycleList.indexOf(value);
        } else {
          // we have a number
          index = value;
          cycle = this.cycleList.objectAt(value);
        }

        Ember.set(this, 'selectedCycle', cycle);
        Ember.set(this, 'selectedCycleIndex', Number(index));
        localStorage.setItem('cycle', index || 0);

        if (!init) {
          // Select first step
          this.send('selectStep');
        }
      },

      selectStep(step) {
        if (this.stepList.length === 0) {
          Ember.set(this, 'selectedStep', null);
          return;
        }

        if (!step) {
          step = Ember.get(this, 'stepList.firstObject');
        } else if (!isNaN(step)) {
          step = this.stepList.objectAt(Number(step));
        }

        let index = this.stepList.indexOf(step) || 0;
        Ember.set(this, 'selectedStep', step);
        (0, _jquery.default)(step).focus();
        let stepOptions;

        if (this?.cycleSettings?.stepMetaData) {
          stepOptions = Ember.get(this, 'cycleSettings.stepMetaData').findBy('Type', step.Type);
        } else {
          stepOptions = '';
        } // Find step name to display in options panel


        let stepName = stepOptions?.name || 'Unknown';
        Ember.set(this, 'stepName', `${stepName} Step`);
        this.closeControls();
        localStorage.setItem('step', index || 0);
        this.send('calculateTime');
      },

      insertStep(stepType, index) {
        // Get the properties for the step we're inserting
        let props = Ember.get(this, 'cycleSettings.steps').findBy('Type', stepType); // Now we need to follow some rules. The label needs to match the label of the next step in the array, unless we're at the end...when we'll use the last element.
        // And we need to do the same with the ActiveState.
        // TODO: Find out if we should use the previous or next step to copy these values.

        let steps = this.stepList;
        let totalSteps = Ember.get(steps, 'length');
        let copyObject, label, activeState, newStep; // Copy the Label and ActiveState

        if (index === 'last') {
          copyObject = steps.objectAt(totalSteps - 1);
        } else {
          copyObject = steps.objectAt(index);
        }

        if (copyObject != null) {
          label = Ember.get(copyObject, 'Label');
          activeState = Ember.get(copyObject, 'ActiveState');
        } else {
          label = 1;
          activeState = 8;
        }

        Ember.set(props, 'Label', label);
        Ember.set(props, 'ActiveState', activeState); // Insert the new step.

        if (index === 'last') {
          steps.insertAt(totalSteps, props);
          newStep = steps.objectAt(totalSteps);
          index = totalSteps;
        } else {
          steps.insertAt(index, props);
          newStep = steps.objectAt(index);
        } // this.send('selectStep', newStep, index);


        this.send('selectStep', newStep); // Go grab a coffee.
      },

      editCycleInfo(cycle) {
        Ember.set(this, 'showCycleInfo', true);
      }

    } // #endregion

  });

  _exports.default = _default;
});