define("alliance-business-suite/components/form-ui/radio", ["exports", "alliance-business-suite/mixins/form-ui", "alliance-business-suite/helpers/find-by-dropdown-with-custom", "alliance-business-suite/utils/convert-temperature"], function (_exports, _formUi, _findByDropdownWithCustom, _convertTemperature) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_formUi.default, {
    tagName: 'div',
    intl: Ember.inject.service(),
    customValue: null,

    // classNameBindings: ['form-field'],
    //#region Events
    init() {
      this._super(...arguments);

      let val = this.value;
      let selected; // We must have options to continue.

      try {
        selected = this.options.findBy('val', val) || this.options.findBy('isCustom') || null;
      } catch (err) {// We didn't have an Ember object to work with
      }

      if (selected) {
        Ember.set(this, 'selectedOption', selected.val);
      }
    },

    //#endregion
    //#region Computed properties
    customPropertyName: Ember.computed('params.{propertyName,customPropertyName}', function () {
      // Use the propertyName if no customPropertyName is provided
      return Ember.get(this, 'params.customPropertyName') || Ember.get(this, 'params.propertyName');
    }),
    isTemperature: Ember.computed.equal('params.customType', 'temperature'),
    isTemperatureRate: Ember.computed.equal('params.customType', 'temperature-rate'),
    isSpeed: Ember.computed.equal('params.customType', 'speed'),
    speedSettings: Ember.computed.alias('params.speedSettings'),

    serializePresetValue(value) {
      // Serialize to display value
      if (Ember.get(this, 'isTemperature') && Ember.get(this, 'formSettings.isCelsius')) {
        return (0, _convertTemperature.toC)(value);
      }

      return value;
    },

    deserializePresetValue(value) {// Not used
    },

    // yieldHash: computed('value', 'options', function() {
    //   /*
    //   Always yield the current option that is selected, BUT...
    //   The presetValue is the last valid preset that was selected. isCustom has no presetValue.
    //   */
    //   let options = get(this, 'options');
    //   let value = get(this, 'value');
    //   let selectedOption = findByDropDown([options, "val", value]); // This is the actual option selected. Do not mutate!
    //   // If custom is selected, send presetValue of last preset.
    //   let myreturn = {
    //     val: selectedOption.val,
    //     label: selectedOption.label,
    //     isCustom: selectedOption.isCustom,
    //     presetValue: selectedOption.presetValue,
    //     lastPresetValue: get(this, 'lastPresetValue')
    //   };
    //   return myreturn;
    // }),
    isCustomSelected: Ember.computed('value', 'options', function () {
      let options = Ember.get(this, 'options');
      let value = Ember.get(this, 'value');
      let selectedOption = (0, _findByDropdownWithCustom.findByDropDown)([options, "val", value]);
      return selectedOption.isCustom;
    }),
    options: Ember.computed('params.options', 'formSettings.selectOptions', function () {
      // overrides options CP in mixin
      let options = Ember.get(this, 'params.options'); // What options we're going to display

      if (options && typeof options !== "string") {
        // An object was passed in
        return options || {
          val: null,
          label: 'Invalid options'
        };
      } else {
        // Find options and return them.
        if (options) {
          // A string was passed into 'options'
          return Ember.get(this, `formSettings.selectOptions.${options}`) || {
            val: null,
            label: 'Data not found.'
          };
        } else {
          // Nothing passed in, so fall back to propertyName
          let propertyName = Ember.get(this, 'propertyName');
          let key = propertyName.split('.').slice(-1)[0];
          let so = Ember.get(this, 'formSettings.selectOptions');
          let output = Ember.get(so, key) || {
            val: null,
            label: 'Data not found.'
          };
          return output;
        }
      }
    }),
    sharedCustomProperty: Ember.computed('params.{hasCustom,customPropertyName}', function () {
      // If hasCustom is true but we have no customPropertyName, then one property is used
      // for both and sharedCustomProperty is true.
      // If sharedCustomProperty is passed in, that value will replace this function.
      let hasCustom = Ember.get(this, 'params.hasCustom');
      let customPropertyName = Ember.get(this, 'params.customPropertyName');
      return hasCustom && customPropertyName === undefined;
    }),
    //#endregion
    //#region Actions
    actions: {
      blockBubbles(select, event) {
        if (event.target && event.target.tagName === 'INPUT') {
          event.stopPropagation();
          return false; // This is required to stop the bubbles
        }
      },

      doChange(val) {
        Ember.set(this, 'selectedOption', val);
        Ember.set(this, 'value', val); // if (get(this, 'params.onChange')) {
        //   get(this, 'params.onChange')(val);
        // } else {
        //   // If 'custom' is chosen and sharedCustomProperty is true, we need to set the value to the last preset value.
        //   let isCustom = val.isCustom;
        //   let sharedCustomProperty = get(this, 'sharedCustomProperty');
        //   let lastPresetValue = get(this, 'yieldHash.lastPresetValue') || get(this, 'yieldHash.presetValue');
        //   let value = val;
        //   if (isCustom && sharedCustomProperty) { // We need to update the value to be the last presetValue
        //     // If this is a sharedCustomProperty, the lastPresetValue might be undefined. If so, we'll use the current value.
        //     let customValue = lastPresetValue || value || get(this, 'params.min') || 0;
        //     // Oh gosh, this is ugly, but needs to be here for backwards-compatibility. :-( G-force presets need to be multipled by 100.
        //     if (get(this, 'isSpeed')) {
        //       customValue*= 100;
        //     }
        //     set(this, 'value', customValue);
        //   } else if (isCustom) {
        //     set(this, 'value', value);
        //   } else { // not custom
        //     set(this, 'value', value);
        //     // Only update if we have a presetValue to use
        //     if (!isNone(val.presetValue)) {
        //       set(this, 'lastPresetValue', val.presetValue);
        //     }
        //   }
        // }
        // If "custom" was just selected, try to focus on an input. setTimeout required to catch the element in the DOM.
        // run.schedule('afterRender', () => {
        //   setTimeout(() => {
        //     this.$(this.element).find('input').focus();
        //   }, 100);
        // });
      }

    } //#endregion

  });

  _exports.default = _default;
});