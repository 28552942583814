define("alliance-business-suite/components/customers/customers-section", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3;

  let CustomersCustomersSectionComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._action, (_class = class CustomersCustomersSectionComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "canAccess", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "viewDownloadOptions", _descriptor3, this);
    }

    get canExport() {
      let {
        canAccess
      } = this;
      let canExportTransactions = this.canAccess.canAccess('CUSTOMERS_LIST_DOWNLOAD');
      let canExportCustomers = this.canAccess.canAccess('CUSTOMERS_REPORTS_VIEW_CUSTOMER_OVERVIEW');
      return canExportCustomers || canExportTransactions;
    }

    toggleDownloadModal() {
      this.viewDownloadOptions = !this.viewDownloadOptions;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "canAccess", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "viewDownloadOptions", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "toggleDownloadModal", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "toggleDownloadModal"), _class.prototype)), _class));
  _exports.default = CustomersCustomersSectionComponent;
});