define("alliance-business-suite/components/rewards/schedule-day-rewards", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    access: Ember.inject.service(),
    store: Ember.inject.service(),
    actions: {
      toggleProgramSchedule(programSchedule) {
        if (this.access.isDemo) {
          alert('Demo mode restricted.');
          return;
        }

        if (programSchedule) {
          this.set('selectedProgramSchedule', programSchedule);
        }

        this.toggleProperty('viewEditProgramSchedule');
      },

      addSchedule() {
        if (this.access.isDemo) {
          alert('Demo mode restricted.');
          return;
        }

        let newSchedule = this.store.createRecord('rewardsProgramScheduleDay', {
          rewardsProgram: this.model
        });
        this.set('selectedProgramSchedule', newSchedule);
        this.toggleProperty('viewEditProgramSchedule');
      }

    }
  });

  _exports.default = _default;
});