define("alliance-business-suite/components/machines/traceability/errors/errors-overview", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@glimmer/component"], function (_exports, _defineProperty2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="details-section">
  
    <div class="steps-container with-shadow">
      <header>
        <h3 class="stamp">Errors</h3>
      </header>
  
      <table class="table">
        <tbody>
          {{#each @trace.errors as |error|}}
            <tr>
              <td>{{diff-between-timestamps this.earliestTimestamp error.timestamp}}</td>
              <td>Error</td>
              <td>{{error.errorStatus.error}} - {{error.errorStatus.errorValue}}</td>
            </tr>
          {{else}}
            <tr>
              <td>No errors</td>
            </tr>
          {{/each}}
        </tbody>
      </table>
    </div>
  </div>
  
  */
  {
    "id": "5l550PuJ",
    "block": "{\"symbols\":[\"error\",\"@trace\"],\"statements\":[[10,\"div\"],[14,0,\"details-section\"],[12],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"steps-container with-shadow\"],[12],[2,\"\\n    \"],[10,\"header\"],[12],[2,\"\\n      \"],[10,\"h3\"],[14,0,\"stamp\"],[12],[2,\"Errors\"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"table\"],[14,0,\"table\"],[12],[2,\"\\n      \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,2,[\"errors\"]]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"          \"],[10,\"tr\"],[12],[2,\"\\n            \"],[10,\"td\"],[12],[1,[30,[36,0],[[32,0,[\"earliestTimestamp\"]],[32,1,[\"timestamp\"]]],null]],[13],[2,\"\\n            \"],[10,\"td\"],[12],[2,\"Error\"],[13],[2,\"\\n            \"],[10,\"td\"],[12],[1,[32,1,[\"errorStatus\",\"error\"]]],[2,\" - \"],[1,[32,1,[\"errorStatus\",\"errorValue\"]]],[13],[2,\"\\n          \"],[13],[2,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[2,\"          \"],[10,\"tr\"],[12],[2,\"\\n            \"],[10,\"td\"],[12],[2,\"No errors\"],[13],[2,\"\\n          \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"diff-between-timestamps\",\"-track-array\",\"each\"]}",
    "moduleName": "alliance-business-suite/components/machines/traceability/errors/errors-overview.hbs"
  });

  class MachinesTraceabilityErrorsErrorsOverviewComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "earliestTimestamp", this.args.trace.data[0].timestamp);
    }

  }

  _exports.default = MachinesTraceabilityErrorsErrorsOverviewComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, MachinesTraceabilityErrorsErrorsOverviewComponent);
});