define("alliance-business-suite/routes/employees/manage", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "alliance-business-suite/routes/_secureRoute"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _secureRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  let EmployeesManageRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, (_class = class EmployeesManageRoute extends _secureRoute.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "cache", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor3, this);
      (0, _defineProperty2.default)(this, "allowAccess", ['EMPLOYEES_LIST_VIEW_PAGE']);
    }

    model(params) {
      const organizationId = this.access.get('accessOrganization.id');
      return Ember.RSVP.hash({
        employee: this.store.findRecord('employee', params.employee_id, {
          reload: true
        }),
        geoBoundaries: this.store.queryRecord('organization', {
          geoBoundaries: true,
          include: 'all'
        })
      });
    }

    setupController(controller, model) {
      super.setupController(controller, model);
      this.cache.set('loadedGeoIncludedAll', true);
      controller.parentModel = this.modelFor('employees');
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "cache", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = EmployeesManageRoute;
});