define("alliance-business-suite/components/machines/programs/cycles/ui/galaxy-600-wx-segment-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    output: Ember.computed('index', 'modifiers.@each', function () {
      // We will output the label for the current segment/stage index based on provided modifiers
      let modifiers = this.modifiers;
      let index = this.index;

      switch (index) {
        case 0:
          return 'Extra Wash';

        case 6:
          return 'Extra Rinse';
      }
    })
  });

  _exports.default = _default;
});