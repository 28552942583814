define("alliance-business-suite/templates/components/form-ui/steps/delay-time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "v3RkESPe",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[8,\"form-ui\",[],[[\"@object\",\"@formSettings\",\"@selectOptions\"],[[32,0,[\"object\"]],[32,0,[\"formSettings\"]],[32,0,[\"selectOptions\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[8,[32,1,[\"number\"]],[],[[\"@params\"],[[30,[36,0],null,[[\"propertyName\",\"label\",\"min\",\"max\"],[[32,0,[\"properties\",\"SupplyDelay\",\"key\"]],\"SupplyDelay\",[32,0,[\"min\"]],[32,0,[\"max\"]]]]]]],null],[2,\"\\n\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"hash\"]}",
    "moduleName": "alliance-business-suite/templates/components/form-ui/steps/delay-time.hbs"
  });

  _exports.default = _default;
});