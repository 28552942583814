define("alliance-business-suite/helpers/to-rpm", ["exports", "alliance-business-suite/utils/speed-utilities"], function (_exports, _speedUtilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (params) {
    let diameter = params[0] || 0;
    let gForce = params[1];
    let converted = (0, _speedUtilities.toRPM)(diameter, gForce);
    return converted;
  });

  _exports.default = _default;
});