define("alliance-business-suite/components/form-ui/currency", ["exports", "alliance-business-suite/utils/is-number", "alliance-business-suite/mixins/form-ui"], function (_exports, _isNumber, _formUi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_formUi.default, {
    // eslint-disable-line ember/no-classic-classes
    //#region Variables
    tagName: 'div',
    classNames: ['text-field', 'form-field', 'currency-field'],
    limit: false,
    affixesStay: true,

    // display the prefix/suffix after leaving the field
    // precision: "2", // how many decimals are displayed by default
    //#endregion
    //#region Computed properties
    findFirstValue(values) {
      // Returns the first value that is defined. This will eventually go away when everything uses the `machineProgram` properties instead of passing in params.
      let returnValue = null;

      for (const value of values) {
        if (value !== undefined) {
          returnValue = value;
          break;
        }
      }

      return returnValue;
    },

    // The following 6 properties are used to format currency values. They should all move towards using the `machineProgram` properties instead of params and formSettings, which are still included for backwards compatibility.
    decimals: Ember.computed('params.{machineProgram._decimals,decimals}', 'formSettings.decimals', function () {
      return this.findFirstValue([this.params.machineProgram?._decimals, this.params?.decimals, this.formSettings.decimals]) || 0;
    }),
    thousands: Ember.computed('formSettings.thousandsSymbol', 'params.{machineProgram._thousandsSymbol,thousandsSymbol}', function () {
      return this.findFirstValue([this.params.machineProgram?._thousandsSymbol, this.params?.thousandsSymbol, this.formSettings.thousandsSymbol]) || '';
    }),
    decimalSymbol: Ember.computed('formSettings.decimalSymbol', 'params.{machineProgram._decimalSymbol,decimalSymbol}', function () {
      return this.findFirstValue([this.params.machineProgram?._decimalSymbol, this.params?.decimalSymbol, this.formSettings.decimalSymbol]) || '.';
    }),
    divideBy: Ember.computed('formSettings.currencyDivideBy', 'params.{machineProgram._currencyDivideBy,divideBy}', function () {
      return this.findFirstValue([this.params.machineProgram?._currencyDivideBy, this.params?.divideBy, this.formSettings.currencyDivideBy]) || 1;
    }),
    symbolPosition: Ember.computed('formSettings.currencySymbolPosition', 'params.{machineProgram._currencySymbolPosition,symbolPosition}', function () {
      return this.findFirstValue([this.params.machineProgram?._currencySymbolPosition, this.params?.symbolPosition, this.formSettings.currencySymbolPosition]) || 'after';
    }),
    currencySymbol: Ember.computed('formSettings.currencySymbol', 'params.{machineProgram._currencySymbol,currencySymbol}', function () {
      return this.findFirstValue([this.params.machineProgram?._currencySymbol, this.params?.currencySymbol, this.formSettings.currencySymbol]) || '';
    }),
    prefix: Ember.computed('symbolPosition', 'currencySymbol', function () {
      let symbolPosition = Ember.get(this, 'symbolPosition');

      if (symbolPosition && symbolPosition.toLowerCase() === 'before') {
        return Ember.get(this, 'currencySymbol');
      } else {
        return "";
      }
    }),
    suffix: Ember.computed('symbolPosition', 'currencySymbol', function () {
      let symbolPosition = Ember.get(this, 'symbolPosition');

      if (symbolPosition && symbolPosition.toLowerCase() === 'after') {
        return Ember.get(this, 'currencySymbol');
      } else {
        return "";
      }
    }),
    allowDecimal: Ember.computed('decimals', function () {
      return this.decimals > 0;
    }),

    //#endregion
    //#region Methods
    serializeValue(rawValue) {
      // Convert raw value to something the UI can display
      let decimals = Ember.get(this, 'decimals') || 0;
      return rawValue / 10 ** decimals;
    },

    deserializeValue(value) {
      // Convert values to what the back-end API wants to receive
      if ((0, _isNumber.isNumber)(value)) {
        let decimals = Ember.get(this, 'decimals') || 0;
        let rawValue = decimals !== undefined ? parseFloat(new Big(value).times(new Big(10).pow(decimals))) : value; // Now that we've normalized the values for the back-end, we need to check any min/max limits

        let {
          min,
          max
        } = Ember.get(this, 'params');

        if (Ember.isPresent(min) && rawValue < min) {
          rawValue = min;
          this.notifyPropertyChange('value');
        }

        if (Ember.isPresent(max) && rawValue > max) {
          rawValue = max;
          this.notifyPropertyChange('value');
        }

        return Number(rawValue);
      }
    } //#endregion


  });

  _exports.default = _default;
});