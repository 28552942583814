define("alliance-business-suite/helpers/prefix", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.prefix = prefix;

  /**
   * Returns the path to the property by prefixing the property with the first
   * path which is not null or undefined.
   *
   * @param {string}     property The property or sub-path to prefix
   * @param {...[string} paths    The paths to examine to prefix
   *
   * @returns {string} A dotted path to the property using the first defined path
   * @public
   */
  function prefix(property) {
    for (var _len = arguments.length, paths = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      paths[_key - 1] = arguments[_key];
    }

    let activePath = paths != null ? paths.find(e => e != null) : undefined;
    return activePath != null ? `${activePath}.${property}` : property;
  }

  var _default = Ember.Helper.helper(function (positionalParams) {
    return prefix(...positionalParams);
  });

  _exports.default = _default;
});