define("alliance-business-suite/serializers/employee", ["exports", "alliance-business-suite/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    attrs: {// phoneNumbers: { serialize: true }
    },

    /**
     * The service used to manage access control.
     *
     * @protected
     */
    access: Ember.inject.service(),

    /**
     * Hacks a JSON API data object returned within a JSON API document to
     * conform to what is required to make the endpoint work properly with Ember
     * Data and within this application.
     *
     * @param {object} data A data object from a JSON API document
     * @protected
     */
    normalizeDataHack(data) {// this.toLinkRelationship(data, 'facilities');
      // this.toLinkRelationship(data, 'userRoles', 'roles');
      // this.toLinkRelationship(data, 'notes', 'note');
      // data.type = 'employees';
    } // serializeIntoHash(data, type, snapshot, /* options */) {
    //   if (snapshot.record.get('isNew')) {
    //     Object.assign(data, snapshot.record.getProperties('firstName', 'lastName', 'email'));
    //     data.role = {
    //       id: 'PERSON_FRANCHISE_FACILITY_MANAGER'
    //     };
    //     data.organization = {
    //       id: get(this, 'access.accessOrganization.id')
    //     };
    //     data.createdBy = {
    //       id: get(this, 'access.accessUser.id')
    //     };
    //   } else if (snapshot.record.get('isSaving')) {
    //     Object.assign(data, snapshot.record.getProperties('firstName', 'lastName'));
    //   }
    // }


  });

  _exports.default = _default;
});