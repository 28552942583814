define("alliance-business-suite/models/machine", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;
  const STATUS_LEVELS = {
    'AVAILABLE': 'available',
    'COMPLETE': 'complete',
    'DOOR_OPEN': 'unknown',
    'ERROR': 'error',
    'IN_USE': 'in-use',
    'LUCKY_CYCLE': 'in-use',
    'READY_TO_START': 'available',
    'RESERVED': 'in-use',
    'UNAVAILABLE': 'error',
    'UNKNOWN': 'unknown',
    'NETWORK_ERROR': 'error',
    'OUT_OF_ORDER': 'out-of-order'
  };

  var _default = Model.extend({
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    machineNumber: attr('string'),
    modelNumber: attr('string'),
    serialNumber: attr('string'),
    networkController: attr('string'),
    networkNode: attr('number'),
    controlId: attr('string'),
    generation: attr('string'),
    machineGeneration: attr('string'),
    lastCollectionDate: attr('date'),
    lastProcessedDate: attr('date'),
    installedDate: attr('date'),
    isActive: attr('boolean'),
    isDryer: attr('boolean'),
    isWasher: attr('boolean'),
    isCombo: Ember.computed('machineAuditType.id', 'machineType.id', 'isWasher', 'isDryer', 'isCombo', function () {
      // A few specific machine types are combo machines...
      if ([902, 903, 904, 905].includes(this.machineType.get("id"))) {
        return true;
      } // If isWasher and isDryer are both true, it's a Combo machine.


      if (this.isWasher && this.isDryer) {
        return true;
      } // If the machine audit type has the "_COMBO" string in it, it's a Combo machine.


      if (this.machineAuditType.get('id').includes('_COMBO')) {
        return true;
      }

      return false;
    }),
    isTraceable: Ember.computed.reads('machineAuditType.isTraceable'),
    coinVaultSize: attr('number'),
    machineName: attr('string'),
    isExternal: attr('boolean'),
    lastKnownProgram: belongsTo('last-known-program', {
      inverse: null
    }),
    lastKnownProgramUpdatedAt: attr('date'),
    stats: attr(''),
    isVended: Ember.computed('machineAuditType.isVended', function () {
      return Ember.get(this, 'machineAuditType.isVended');
    }),
    number: attr('string'),
    machineTime: attr('date'),
    totalCycles: attr('number'),
    rapidAdvanceCycles: attr('number'),
    operationMinutes: attr('number'),
    machineType: belongsTo('machineType'),
    machineAuditGroupType: belongsTo('machineAuditGroupType'),
    machineAuditType: belongsTo('machineAuditType'),
    machineStatus: belongsTo('machineStatus'),
    machineCurrentStatus: belongsTo('machineCurrentStatus'),
    machineCurrentStatusId: Ember.computed.readOnly('machineCurrentStatus.statusId'),
    room: belongsTo('room'),
    notes: hasMany('note'),
    createdAt: attr('date'),
    createdBy: belongsTo('user', {
      inverse: null
    }),
    updatedAt: attr('date'),
    updatedBy: belongsTo('user', {
      inverse: null
    }),
    machineAudit: belongsTo('machineAudit'),
    statusLevel: Ember.computed('machineCurrentStatus.statusId', {
      get() {
        return STATUS_LEVELS[Ember.get(this, 'machineCurrentStatus.statusId') || 'LOADING'] || 'loading';
      }

    }).readOnly(),
    brushLabel: Ember.computed('machineType', {
      get() {
        let machineNumber = this.machineNumber;
        let returnValue = `${Ember.get(this, 'machineType.equipmentTypeAbbreviation')} ${machineNumber}`;

        if (!machineNumber) {
          // eslint-disable-next-line no-console
          console.log(`machineNumber is undefined, returning "${returnValue}"`); // currently returns "D undefined" or "W undefined"
        }

        return returnValue;
      }

    }).readOnly(),
    displayName: Ember.computed('isDryer', 'isWasher', 'isCombo', 'machineNumber', 'machineName', function () {
      let {
        isDryer,
        isWasher,
        machineNumber,
        machineName
      } = this;

      if (machineName) {
        return machineName;
      }

      if (this.isCombo) {
        return `${this.intl.t("Combo")} ${machineNumber}`;
      }

      if (this.isDryer) {
        return `${this.intl.t("Dryer")} ${machineNumber}`;
      }

      if (this.isWasher) {
        return `${this.intl.t("Washer")} ${machineNumber}`;
      }
    }),
    machineControlName: Ember.computed('machineAuditGroupType', function () {
      // This really needs to be sorted out. machineAuditGroupType spans brands, which causes problems.
      // We return intl version because the model-table component just outputs what it gets
      return this.intl.t(Ember.get(this, "machineAuditType.id").toUpperCase());
    }),
    // based on the "machine-display-name" helper
    formattedMachineDisplayName: Ember.computed('machineName', 'machineNumber', 'isDryer', 'machineType', function () {
      let {
        machineName,
        machineNumber,
        isDryer
      } = this;
      let displayValue = machineNumber ? machineNumber.replace(/^[0|\D]*/, '') : '?';

      if (machineName) {
        if (machineName.length < 8) {
          displayValue = machineName;
        }
      }

      if (this.machineType.get('id') != 900) {
        if (this.isCombo) {
          return `${this.intl.t("abbrev_combo")} ${displayValue}`;
        }

        if (this.isDryer) {
          return `${this.intl.t("abbrev_dryer")} ${displayValue}`;
        }

        if (this.isWasher) {
          return `${this.intl.t("abbrev_washer")} ${displayValue}`;
        }
      } else {
        displayValue = `${this.intl.t("abbrev_machine")} ${displayValue}`;
      }

      return displayValue;
    }),
    // for sorting, we need to be able to compare by numbers and not strings
    sortableMachineName: Ember.computed('machineName', function () {
      let machineName = this.machineName;

      if (machineName.includes('Machine Node -')) {
        machineName = machineName.slice(14);
      }

      machineName = parseInt(machineName);
      console.log(this.id, machineName, isNaN(machineName) ? 0 : this.networkNode);
      return isNaN(machineName) ? 0 : machineName;
    })
  });

  _exports.default = _default;
});