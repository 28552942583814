define("alliance-business-suite/components/summary/reports/s-retail", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "alliance-business-suite/utils/currency-format-util"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _currencyFormatUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  let SummaryReportsSRetailComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, (_class = class SummaryReportsSRetailComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor3, this);
    }

    get translatedColumns() {
      return [{
        name: 'Store',
        valuePath: 'roomName',
        width: 300,
        isFixed: 'left'
      }, {
        name: 'Washer ',
        valuePath: 'washerRevenue',
        width: 225
      }, {
        name: 'Dryer',
        valuePath: 'dryerRevenue',
        width: 225
      }, {
        name: 'WDF',
        valuePath: 'washDryFoldRevenue',
        width: 225
      }, {
        name: 'wdf_tax',
        valuePath: 'washDryFoldTax',
        width: 225
      }, {
        name: 'wdf_subtotal',
        valuePath: 'washDryFoldSubtotal',
        width: 225
      }, {
        name: 'Retail',
        valuePath: 'retailRevenue',
        width: 225
      }, {
        name: 'retail_tax',
        valuePath: 'retailTax',
        width: 225
      }, {
        name: 'retail_subtotal',
        valuePath: 'retailSubtotal',
        width: 225
      }, {
        name: 'Total',
        valuePath: 'totalRevenue',
        width: 225
      }, {
        name: 'total_tax',
        valuePath: 'totalTax',
        width: 225
      }, {
        name: 'total_revenue_and_tax',
        valuePath: 'totalRevenueAndTax',
        width: 225
      }, {
        name: 'wdf_percent',
        valuePath: 'washDryFoldPercentTotal',
        width: 225
      }, {
        name: 'retail_percent',
        valuePath: 'retailPercent',
        width: 225
      }, {
        name: 'dry_wash_percent',
        valuePath: 'dryerPercentOfWash',
        width: 225
      }, {
        name: 'site_size',
        valuePath: 'siteSizeSqFt',
        width: 225
      }, {
        name: 'revenue_sqft',
        valuePath: 'revenueSqFtDay',
        width: 225
      }, {
        name: 'annual_rent',
        valuePath: 'annualRent',
        width: 225
      }, {
        name: 'rent_sqft',
        valuePath: 'rentSqFt',
        width: 225
      }, {
        name: 'sales_rent',
        valuePath: 'salesPerRentMonth',
        width: 225
      }, {
        name: 'washer_pockets',
        valuePath: 'washerPockets',
        width: 225
      }, {
        name: 'washer_pockets_day',
        valuePath: 'washerSalesPerPocketPerDay',
        width: 225
      }, {
        name: 'washer_turns_day',
        valuePath: 'washerTurnsPerDay',
        width: 225
      }, {
        name: 'washer_sales_day',
        valuePath: 'washerSalesPerTurnPerDay',
        width: 225
      }, {
        name: 'average_wash',
        valuePath: 'averageWashPrice',
        width: 225
      }, {
        name: 'dry_pockets',
        valuePath: 'dryerPockets',
        width: 225
      }, {
        name: 'total_dry_pockets',
        valuePath: 'dryerSalesPerPocketPerDay',
        width: 225
      }, {
        name: 'dryer_turns',
        valuePath: 'dryerTurnsPerDay',
        width: 225
      }, {
        name: 'dryer_sales',
        valuePath: 'dryerSalesPerTurnPerDay',
        width: 225
      }, {
        name: 'average_dry',
        valuePath: 'averageDryPrice',
        width: 225
      }, {
        name: 'dry_wash_turns',
        valuePath: 'dryerTurnsPerWashTurn',
        width: 225
      }, {
        name: 'total_sales_pockets',
        valuePath: 'totalSalesPerPocketPerDay',
        width: 225
      }, {
        name: 'total_sales_turns',
        valuePath: 'totalSalesPerTurnPerDay',
        width: 225
      }, {
        name: 'labour_hour',
        valuePath: 'laborHours',
        width: 225
      }, {
        name: 'hour_rate',
        valuePath: 'ratePerHour',
        width: 225
      }, {
        name: 'staff_total',
        valuePath: 'hourlyStaffTotal',
        width: 225
      }, {
        name: 'salaried_staff',
        valuePath: 'salariedStaff',
        width: 225
      }, {
        name: 'total_labour',
        valuePath: 'totalLaborCost',
        width: 225
      }, {
        name: 'revenue_labour',
        valuePath: 'revenuePerLabour',
        width: 225
      }, {
        name: 'revenue_labour_hour',
        valuePath: 'revenuePerLaborHour',
        width: 225
      }, {
        name: 'labour_sqft',
        valuePath: 'laborPerSqFt',
        width: 225
      }, {
        name: 'labour_per_pocket',
        valuePath: 'laborHoursPerPocket',
        width: 225
      }];
    }

    get rowsWithChildren() {
      let {
        model
      } = this.args;
      let regions = model.regions;
      let rowData = [];
      regions.forEach(region => {
        rowData.push({
          roomName: region.roomName,
          washerRevenue: _currencyFormatUtil.formatCurrencyString.call(this, region.washerRevenue || 0),
          dryerRevenue: _currencyFormatUtil.formatCurrencyString.call(this, region.dryerRevenue || 0),
          washDryFoldRevenue: _currencyFormatUtil.formatCurrencyString.call(this, region.washDryFoldRevenue || 0),
          washDryFoldTax: _currencyFormatUtil.formatCurrencyString.call(this, region.washDryFoldTax || 0),
          washDryFoldSubtotal: _currencyFormatUtil.formatCurrencyString.call(this, region.washDryFoldSubtotal || 0),
          retailRevenue: _currencyFormatUtil.formatCurrencyString.call(this, region.retailRevenue || 0),
          retailTax: _currencyFormatUtil.formatCurrencyString.call(this, region.retailTax || 0),
          retailSubtotal: _currencyFormatUtil.formatCurrencyString.call(this, region.retailSubtotal || 0),
          totalRevenue: _currencyFormatUtil.formatCurrencyString.call(this, region.totalRevenue || 0),
          totalTax: _currencyFormatUtil.formatCurrencyString.call(this, region.totalTax || 0),
          totalRevenueAndTax: _currencyFormatUtil.formatCurrencyString.call(this, region.totalRevenueAndTax || 0),
          washDryFoldPercentTotal: (region.washDryFoldPercentTotal * 100 || 0).toFixed(1),
          retailPercent: (region.retailPercent * 100 || 0).toFixed(1),
          dryerPercentOfWash: (region.dryerPercentOfWash * 100 || 0).toFixed(1),
          siteSizeSqFt: region.siteSizeSqFt || 0,
          revenueSqFtDay: _currencyFormatUtil.formatCurrencyString.call(this, region.revenueSqFtDay || 0),
          annualRent: _currencyFormatUtil.formatCurrencyString.call(this, region.annualRent || 0),
          rentSqFt: _currencyFormatUtil.formatCurrencyString.call(this, region.rentSqFt || 0),
          salesPerRentMonth: _currencyFormatUtil.formatCurrencyString.call(this, region.salesPerRentMonth || 0),
          washerPockets: region.washerPockets || 0,
          washerSalesPerPocketPerDay: _currencyFormatUtil.formatCurrencyString.call(this, region.washerSalesPerPocketPerDay || 0),
          washerTurnsPerDay: (region.washerTurnsPerDay || 0).toFixed(1),
          washerSalesPerTurnPerDay: region.washerSalesPerTurnPerDay || 0,
          averageWashPrice: _currencyFormatUtil.formatCurrencyString.call(this, region.averageWashPrice || 0),
          dryerPockets: region.dryerPockets || 0,
          dryerSalesPerPocketPerDay: _currencyFormatUtil.formatCurrencyString.call(this, region.dryerSalesPerPocketPerDay || 0),
          dryerTurnsPerDay: (region.dryerTurnsPerDay || 0).toFixed(1),
          dryerSalesPerTurnPerDay: region.dryerSalesPerTurnPerDay || 0,
          averageDryPrice: _currencyFormatUtil.formatCurrencyString.call(this, region.averageDryPrice || 0),
          dryerTurnsPerWashTurn: (region.dryerTurnsPerWashTurn || 0).toFixed(1),
          totalSalesPerPocketPerDay: _currencyFormatUtil.formatCurrencyString.call(this, region.totalSalesPerPocketPerDay || 0),
          totalSalesPerTurnPerDay: region.totalSalesPerTurnPerDay,
          laborHours: region.laborHours || 0,
          ratePerHour: _currencyFormatUtil.formatCurrencyString.call(this, region.ratePerHour || 0),
          hourlyStaffTotal: _currencyFormatUtil.formatCurrencyString.call(this, region.hourlyStaffTotal || 0),
          salariedStaff: _currencyFormatUtil.formatCurrencyString.call(this, region.salariedStaff || 0),
          totalLaborCost: _currencyFormatUtil.formatCurrencyString.call(this, region.totalLaborCost || 0),
          revenuePerLabour: _currencyFormatUtil.formatCurrencyString.call(this, region.revenuePerLabour || 0),
          revenuePerLaborHour: _currencyFormatUtil.formatCurrencyString.call(this, region.revenuePerLaborHour || 0),
          laborPerSqFt: _currencyFormatUtil.formatCurrencyString.call(this, region.laborPerSqFt || 0),
          laborHoursPerPocket: (region.laborHoursPerPocket || 0).toFixed(1)
        });
      });
      return rowData;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SummaryReportsSRetailComponent;
});