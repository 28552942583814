define("alliance-business-suite/templates/components/form-ui/cycle-prices", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zUSVdhyf",
    "block": "{\"symbols\":[\"ui\",\"num\"],\"statements\":[[8,\"form-ui\",[],[[\"@object\",\"@formSettings\",\"@selectOptions\",\"@formDisplay\"],[[34,6],[34,7],[34,8],\"compact\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n\"],[2,\"\\n\"],[6,[37,11],[[30,[36,10],[[30,[36,10],[[30,[36,9],[0,[32,0,[\"maxCycleIndex\"]],true],null]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,[32,1,[\"currency\"]],[],[[\"@params\"],[[30,[36,5],null,[[\"propertyName\",\"label\",\"min\",\"max\",\"limit\",\"decimals\"],[[30,[36,3],[[35,4],\".\",[32,2]],null],[30,[36,3],[[30,[36,2],[\"cycle\"],null],\" \",[30,[36,1],[[32,2],1],null]],null],0,65535,true,[35,0]]]]]],null],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"decimals\",\"add\",\"t\",\"concat\",\"propertyName\",\"hash\",\"object\",\"formSettings\",\"selectOptions\",\"range\",\"-track-array\",\"each\"]}",
    "moduleName": "alliance-business-suite/templates/components/form-ui/cycle-prices.hbs"
  });

  _exports.default = _default;
});