define("alliance-business-suite/mixins/report-tables", ["exports", "numeral", "alliance-business-suite/mixins/currency-format"], function (_exports, _numeral, _currencyFormat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_currencyFormat.default, {
    access: Ember.inject.service(),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    download: null,

    // search within a string and replace it with a localization
    localizeString(phrase, keyToReplace, localizationKeyToSubsituteWith) {
      let regex = new RegExp(keyToReplace, "g");
      return phrase.replace(regex, this.intl.t(localizationKeyToSubsituteWith));
    },

    formatAsCurrency(value) {
      return this.formatCurrencyString(value);
    },

    formatDenominations(value) {
      return (0, _numeral.default)(value).format(`${this.get('access.currencyFormatString')}`);
    },

    totalRows(array, sum) {
      for (let i = 0; i < array.length; i++) {
        sum += parseInt(array[i]);
      }

      return sum;
    },

    convertSeconds(secondsValue) {
      let hours = Math.floor(secondsValue / 3600);
      let minutes = secondsValue % 3600 / 60;

      if (minutes <= 9) {
        minutes = '0' + minutes;
      }

      return `${hours}:${minutes}` || 0;
    },

    averageArray(arr, sum) {
      for (let i = 0; i < arr.length; i++) {
        sum += parseFloat(arr[i]);
      }

      return sum;
    },

    sumArray(arr, sum) {
      for (let i = 0; i < arr.length; i++) {
        sum += parseInt(arr[i]);
      }

      return sum;
    },

    formatDateTimeString() {
      let userId = Ember.get(this, 'access.accessUser.id');
      let user = this.store.peekRecord('employee', userId);
      let dateTimeString = `${user.get('dateFormat')} ${user.get('timeFormat')} A`;

      if (user.get('timeFormat') === 'HH:mm') {
        dateTimeString = `${user.get('dateFormat')} ${user.get('timeFormat')}`;
      }

      return dateTimeString;
    },

    machineNameDisplay(name, number) {
      let machineName = name;
      let machineNumber = `${number}`;
      let displayValue = `${this.intl.t('machine')} - ${machineNumber.replace(/^[0|\D]*/, '')}`;

      if (machineName) {
        if (machineName.length < 8) {
          displayValue = `${this.intl.t('machine')} - ${machineName}`;
        }
      }

      return displayValue;
    },

    // outputs "Dryer ABC" or "Washeer 1"
    machineNameDisplayWithPrefixSupport(name, number, isWasher) {
      let machineName = name;
      let machineNumber = `${number}`;
      let prefix = isWasher ? this.intl.t('washer') : this.intl.t('dryer');
      let displayValue = `${prefix} ${machineNumber.replace(/^[0|\D]*/, '')}`;

      if (machineName) {
        if (machineName.length < 8) {
          displayValue = `${prefix} - ${machineName}`;
        }
      }

      return displayValue;
    }

  });

  _exports.default = _default;
});