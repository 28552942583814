define("alliance-business-suite/utils/array-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.arrayCollection = arrayCollection;
  _exports.updateArrayCollection = updateArrayCollection;

  // Returns a single array containing the elements from all the provided arrays.
  function arrayCollection() {
    return [].concat(...arguments);
  } // Update the index spanning all arrays in the order provided.


  function updateArrayCollection(index, value) {
    for (var _len = arguments.length, arrays = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
      arrays[_key - 2] = arguments[_key];
    }

    for (const array of arrays) {
      let len = array.length;

      if (len > index) {
        array[index] = value;
        break;
      }

      index -= len;
    }
  }
});