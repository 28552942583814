define("alliance-business-suite/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7Hgm+OpQ",
    "block": "{\"symbols\":[],\"statements\":[[2,\"\\n\"],[6,[37,1],[[32,0,[\"devtools\",\"trayEnabled\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"dev/dev-tools-tray\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,1],[[30,[36,2],[[32,0,[\"access\",\"forceOrgType\"]],\"OPL\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"div\"],[24,5,\"position:fixed; background: red; color:white; top: 10px; right: 10px; z-index: 999999; padding: 3px 12px;\"],[4,[38,0],[[32,0],\"forceOrgTypeOff\"],null],[12],[2,\"\\n    OPL OVERRIDE\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,1],[[30,[36,2],[[32,0,[\"access\",\"forceOrgType\"]],\"VENDED\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"div\"],[24,5,\"position:fixed; background: red; color:white; top: 10px; right: 10px; z-index: 999999; padding: 3px 12px;\"],[4,[38,0],[[32,0],\"forceOrgTypeOff\"],null],[12],[2,\"\\n    VENDED OVERRIDE\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[8,\"notification-container\",[],[[\"@position\",\"@zindex\"],[\"top\",\"99999\"]],null],[2,\"\\n\\n\"],[8,\"application-section\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[1,[30,[36,4],[[30,[36,3],null,null]],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"if\",\"eq\",\"-outlet\",\"component\"]}",
    "moduleName": "alliance-business-suite/templates/application.hbs"
  });

  _exports.default = _default;
});