define("alliance-business-suite/components/summary/reports/s-machine-activity", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "alliance-business-suite/utils/currency-format-util", "alliance-business-suite/utils/report-tables-util"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _currencyFormatUtil, _reportTablesUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  let SummaryReportsSMachineActivityComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, (_class = class SummaryReportsSMachineActivityComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "brand", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor4, this);
    }

    get translatedColumns() {
      return [{
        name: 'date',
        valuePath: 'date',
        width: 225,
        isFixed: 'left'
      }, {
        name: 'full_name',
        valuePath: 'user',
        width: 180
      }, {
        name: 'email',
        valuePath: 'email',
        width: 180
      }, {
        name: 'type',
        valuePath: 'type',
        width: 180
      }, {
        name: 'amount',
        valuePath: 'amount',
        width: 180
      }, {
        name: 'notes',
        valuePath: 'info',
        width: 180
      }];
    }

    get rowsWithChildren() {
      let userRecords = this.args.model.get('events');
      let rowData = [];
      userRecords.forEach(userRecord => {
        // convert "MACHINE PAYMENT -> MACHINE_PAYMENT"
        let typeWithUnderscore = userRecord.type.replace(/ /g, "_");

        let amount = _currencyFormatUtil.formatCurrencyString.call(this, userRecord.amount || 0);

        let info = '';

        if (userRecord.type != "MACHINE_ACTIVITY_CUSTOMER_PAYMENT") {
          amount = '';
        }

        if (userRecord.type == "MACHINE_ACTIVITY_EMPLOYEE_START") {
          info = `${this.intl.t('reason_for_machine_start')}: ${this.intl.t(userRecord.info)}`;
        }

        if (userRecord.type == "MACHINE_ACTIVITY_MACHINE_PROGRAMMED") {
          info = `${this.intl.t('program')}: ${userRecord.programName}`;
        }

        rowData.push({
          date: moment(userRecord.date).format(`${_reportTablesUtil.formatDateTimeString.call(this)}`),
          user: {
            cellValue: `${userRecord.firstName} ${userRecord.lastName}`,
            cellMeta: {
              type: userRecord.type,
              id: userRecord.user
            }
          },
          email: userRecord.email,
          // firstName: {
          //   cellValue: userRecord.firstName,
          //   cellMeta: 'bibo'
          // },
          type: this.intl.t(userRecord.type),
          amount: amount,
          info: info
        });
      });
      return rowData;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "brand", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SummaryReportsSMachineActivityComponent;
});