define("alliance-business-suite/utils/force-unload-all", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (store, modelName) => {
    // There is a (possible) bug in store.unloadAll() that
    // prevents the data from _really_ unloading. Maybe because
    // .unloadAll() happens in the next runloop? Regardless,
    // This will brute-force remove each record.
    const all = store.peekAll(modelName);
    all.forEach(shiftItem => shiftItem.unloadRecord());
  };

  _exports.default = _default;
});