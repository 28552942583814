define("alliance-business-suite/models/report", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    hasMany
  } = _emberData.default;

  var _default = Model.extend({
    title: attr("string"),
    name: attr("string"),
    icon: attr("string"),
    reportType: attr("string"),
    revenueDetails: hasMany("revenueDetails"),
    // Retail report
    regions: attr(),
    // employee vend report
    records: attr(),
    // machine activity report
    events: attr(),
    // Charts
    geoBoundaries: attr(),
    // Charts
    promotionGeoBoundaries: attr(),
    // Charts
    currency: attr(),
    // CRM report
    campaigns: attr(),
    // Utility Consumption
    orgNetIncome: attr(),
    orgTotalExpenses: attr(),
    orgTotalIncome: attr(),
    orgRooms: attr(),
    // Store Stock Report
    storeStock: attr(),
    // Cycle Counts Report
    cycleCounts: attr(),
    // Capacity Utilization Report
    cycleTotals: attr(),
    // Cycle History
    cycles: attr(),
    // Rewards and Revenue Report
    quickbooks: attr(),
    // Employee Login Report
    employeeLogins: attr(),
    // Sales Tax Report
    taxRates: attr(),
    organizationName: attr(),
    taxAmountTotal: attr(),
    taxableAmountTotal: attr(),
    currencySymbol: attr(),
    // vend dashboard
    machineTotals: attr(),
    roomTotals: attr(),
    // Lifetime Operation report
    todaysTotals: attr(),
    totals: attr(),
    machineSizeTotals: attr(),
    // Value Center Collections
    collections: attr(),
    billCountTotal: attr(),
    currencyAmountTotal: attr(),
    currencyDivisor: attr(),
    vcmRooms: attr(),
    vcms: attr(),
    uniqueDenominations: attr(),
    // machine data report
    machineDataReport: attr(),
    // Store & Revenue report
    details: attr(),
    overview: attr(),
    // float report
    orgName: attr(),
    totalFloat: attr(),
    // WDF Status report
    orderTotal: attr(),
    taxTotal: attr(),
    // Peak Times report
    days: attr(),
    rooms: attr(),
    items: attr(),
    configValues: attr(),
    // community membership group report
    deposits: attr(),
    // subscriptionTemplates
    lookupKey: attr("string")
  });

  _exports.default = _default;
});