define("alliance-business-suite/helpers/cycle-times", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (_ref, namedArgs) {
    let [value, ...rest] = _ref;
    let {
      times,
      cycleIndex,
      stepIndex,
      soil,
      minutesOnly
    } = namedArgs; // Find the time

    let time = times.find(item => {
      let first = item.cycleIndex == cycleIndex;
      return item.cycleIndex == cycleIndex && item.stepIndex == stepIndex && item.soilLevel == soil;
    });

    if (time === undefined) {
      return 0;
    }

    let totalSeconds = time.seconds || 0;

    if (totalSeconds && totalSeconds > 0) {
      let totalMinutes = parseInt(totalSeconds / 60);
      let hours = parseInt(totalSeconds / 60 / 60);
      let minutes = totalMinutes % 60;
      let seconds = totalSeconds % 60;

      if (minutesOnly) {
        return seconds > 0 ? totalMinutes + 1 : totalMinutes;
      } else if (hours > 0) {
        return `${hours}:${`0${minutes}`.substr(-2)}:${`0${seconds}`.substr(-2)}`;
      } else {
        return `${minutes}:${`0${seconds}`.substr(-2)}`;
      }
    } else {
      return minutesOnly ? '0' : '0:00';
    }
  });

  _exports.default = _default;
});