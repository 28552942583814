define("alliance-business-suite/serializers/geo-boundary", ["exports", "alliance-business-suite/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    /**
     * Hacks a JSON API include object returned within a JSON API document to
     * confirm to what is required to make the endpoint work property with Ember
     * Data and within this application.
     *
     * @param  {object} include An included data object from a JSON API document
     *
     * @return {object} The data object to use for this include when passing it
     *                  to the store; or undefined if this include should be
     *                  ignored.
     *
     * @protected
     */
    normalizeInclude(include) {
      ['coordinates'].includes(include.type) ? undefined : include;
    },

    /**
     * Hacks a JSON API data object returned within a JSON API document to
     * conform to what is required to make the endpoint work properly with Ember
     * Data and within this application. This hack specifically moves the coordinates
     * out of the relation.
     *
     * @param {object} data A data object from a JSON API document
     * @protected
     */
    normalizeDataHack(data) {
      let coordinates = Ember.get(data, 'relationships.coordinates.data');

      if (coordinates) {
        data.attributes.path = coordinates.map(function (e) {
          return e.id.split(' ').reverse().map(function (e) {
            return parseFloat(e);
          });
        });
      }
    }

  });

  _exports.default = _default;
});