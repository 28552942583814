define("alliance-business-suite/components/employees/roles/limit-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    unlimitedValue: -1,
    watchParentSfa: Ember.observer('sfaEnabled', function () {
      // eslint-disable-line ember/no-observers
      // If the parent is unchecked, clear everything
      if (!this.sfaEnabled) {
        Ember.set(this, 'threshold', this.unlimitedValue);
        Ember.set(this, 'canForce', false);
        this.clearNotifyRoles();
      }
    }),
    // Whether elements are enabled or not
    checkboxLimitEnabled: Ember.computed('selectedRole.isSystemDefault', 'sfaEnabled', function () {
      let isSystemDefault = Ember.get(this, "selectedRole.isSystemDefault");
      return !isSystemDefault && this.sfaEnabled; // Only enable is not system default and active
    }),
    checkboxNotifyEnabled: Ember.computed('isLimited', function () {
      return this.isLimited == true;
    }),
    inputThresholdEnabled: Ember.computed('isLimited', function () {
      return this.isLimited;
    }),
    selectRolesEnabled: Ember.computed('canForce', function () {
      return this.canForce;
    }),
    // Element values
    isLimited: Ember.computed('threshold', function () {
      return this.threshold !== this.unlimitedValue;
    }),
    limitLabels: Ember.computed('limitLabel', function () {
      let limitLabel = this.limitLabel;

      if (Ember.typeOf(limitLabel) === 'string') {
        return [limitLabel];
      } else {
        return limitLabel;
      }
    }),
    disableLimitSection: Ember.computed.not("checkboxLimitEnabled"),
    disableNotifySection: Ember.computed.not("checkboxNotifyEnabled"),
    notSystemDefault: Ember.computed.not("selectedRole.isSystemDefault"),
    hasLimit: Ember.computed('threshold', 'sfaEnabled', function () {
      return this.sfaEnabled && this.threshold != this.unlimitedValue;
    }),

    clearNotifyRoles() {
      let notifyRoles = this.notifyRoles;
      notifyRoles.removeObjects(notifyRoles.compact());
    },

    actions: {
      toggleCheckboxLimit(isChecked) {
        if (isChecked) {
          // set threshold to min
          Ember.set(this, 'threshold', this.min); // enable threshold because value is min
        } else {
          // set threshold to unlimited
          Ember.set(this, 'threshold', this.unlimitedValue); // disable threshold because value is unlimited
          // disable checkbox Notify

          Ember.set(this, 'canForce', false); // disable select Notify
          // clear notify roles

          this.clearNotifyRoles();
        }
      },

      toggleCheckboxNotify(isChecked) {
        if (isChecked) {
          // enable select Notifyy
          Ember.set(this, 'canForce', true);
        } else {
          // disable select Notify
          Ember.set(this, 'canForce', false); // clear notify roles

          this.clearNotifyRoles();
        }
      },

      updateDecimal(value) {
        let rawValue = `${value}` || "0";
        Ember.set(this, 'threshold', Number(rawValue.replace(/[^0-9]*/g, '')));
      }

    }
  });

  _exports.default = _default;
});