define("alliance-business-suite/components/machines/programs/config/primus-mc10-wx", ["exports", "alliance-business-suite/mixins/machines/programs/mc10-options"], function (_exports, _mc10Options) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_mc10Options.default, {
    // eslint-disable-line ember/no-classic-classes
    intl: Ember.inject.service(),
    tagName: '',
    decimalPlaces: Ember.computed.alias('machineProgram.settings.Program.CoinAndPulse.DecimalPointPosition'),
    // Need to observe any properties used for enabled, etc.
    formConfig: Ember.computed('machineProgram.settings.Program.Misc.HasLiquidSoapSupply', 'isCelsius', function () {
      return {
        columns: [{
          sections: [{
            title: "misc_programming",
            controls: [{
              type: "daylight-saving",
              hours12: true,
              properties: {
                enabled: "Program.Misc.DaylightSavings.Enabled",
                startMonth: "Program.Misc.DaylightSavings.StartMonth",
                startWeek: "Program.Misc.DaylightSavings.StartWOM",
                startDay: "Program.Misc.DaylightSavings.StartDOW",
                startHour: "Program.Misc.DaylightSavings.StartHour",
                endMonth: "Program.Misc.DaylightSavings.EndMonth",
                endWeek: "Program.Misc.DaylightSavings.EndWOM",
                endDay: "Program.Misc.DaylightSavings.EndDOW",
                endHour: "Program.Misc.DaylightSavings.EndHour"
              }
            }, {
              type: "select",
              propertyName: "Program.Misc.MiscConfig.DefaultLanguage",
              labelKey: "Default_Language",
              options: "Language",
              sortLocalized: true
            }, {
              type: "checkbox",
              propertyName: "Program.Misc.MiscConfig.OutOfOrderModeEnabled",
              label: "Programming.Misc.MiscConfig.OutOfOrderModeEnabled",
              removeOpl: true
            }, {
              type: "text",
              propertyName: "Program.Misc.MiscConfig.HelpNumber",
              label: "HelpNumber",
              length: 20,
              notMachine: "MC10_COMBO"
            }, {
              type: "number",
              propertyName: "Program.Misc.SystemMenu.ManualAccessPasskey.0",
              label: "manual_access_passkey_1",
              min: 0,
              max: 9
            }, {
              type: "number",
              propertyName: "Program.Misc.SystemMenu.ManualAccessPasskey.1",
              label: "manual_access_passkey_2",
              min: 0,
              max: 9
            }, {
              type: "number",
              propertyName: "Program.Misc.SystemMenu.ManualAccessPasskey.2",
              label: "manual_access_passkey_3",
              min: 0,
              max: 9
            }, {
              type: "number",
              propertyName: "Program.Misc.SystemMenu.ManualAccessPasskey.3",
              label: "manual_access_passkey_4",
              min: 0,
              max: 9
            }, {
              type: "number",
              propertyName: "Program.Misc.FullHeating",
              label: "full_heating_percent",
              min: 1,
              max: 100,
              notMachine: "MC10_COMBO"
            }, {
              type: "number",
              propertyName: "Program.Misc.ServiceInterval",
              label: "service_interval",
              min: 1,
              max: 9999
            }, {
              type: "number",
              propertyName: "Program.Misc.BuzzerTime",
              label: "buzzer_time",
              min: 1,
              max: 99,
              labelAfter: "min."
            }, {
              type: "temperature",
              propertyName: "Program.Misc.HotWaterHeaterTemperature",
              label: "water_heater_temperature",
              min: 122,
              max: 176
            }, {
              type: "number",
              propertyName: "Program.Misc.TemperatureOvershootProtection",
              label: "temp_overshoot_protection_percent",
              min: 0,
              max: 30,
              notMachine: "MC10_COMBO"
            }, {
              type: "number",
              propertyName: "Program.Misc.MaximumHeatingTime",
              label: "maximum_heating_time",
              min: 10,
              max: 90,
              labelAfter: "min.",
              noLimitValue: 0,
              notMachine: "MC10_COMBO"
            }, {
              type: "number",
              propertyName: "Program.Misc.MaximumWaterFillTime",
              label: "maximum_water_fill_time",
              min: 5,
              max: 99,
              labelAfter: "min."
            }, {
              type: "number",
              propertyName: "Program.Misc.OverfillDetection",
              label: "overfill_detection",
              min: 0,
              max: 25
            }, {
              type: "select",
              propertyName: "Program.Misc.ExternalWaitControl",
              label: "external_wait_control",
              options: "WaitControl",
              notMachine: "MC10_COMBO"
            }, {
              type: "select",
              propertyName: "Program.Misc.WeighingOptions",
              label: "weighing_options",
              options: "WeighingOptions",
              ifMachine: "IPSO"
            }, {
              type: "select",
              propertyName: "Program.Misc.DaylightSavings.DateOrder",
              label: "date_orientation",
              options: "DateOrder"
            }, {
              type: "select",
              propertyName: "Program.Misc.DaylightSavings.DateSeparator",
              label: "date_separator",
              options: "DateSeperator"
            }]
          }, {
            title: "low_power",
            notMachine: "MC10_COMBO",
            controls: [{
              type: "low-power-container",
              properties: {
                Enabled: "Program.Misc.LowPowerShutdown.${index}.DOWEn.Enabled",
                Monday: "Program.Misc.LowPowerShutdown.${index}.DOWEn.Monday",
                Tuesday: "Program.Misc.LowPowerShutdown.${index}.DOWEn.Tuesday",
                Wednesday: "Program.Misc.LowPowerShutdown.${index}.DOWEn.Wednesday",
                Thursday: "Program.Misc.LowPowerShutdown.${index}.DOWEn.Thursday",
                Friday: "Program.Misc.LowPowerShutdown.${index}.DOWEn.Friday",
                Saturday: "Program.Misc.LowPowerShutdown.${index}.DOWEn.Saturday",
                Sunday: "Program.Misc.LowPowerShutdown.${index}.DOWEn.Sunday",
                EndYear: "Program.Misc.LowPowerShutdown.${index}.EndYear",
                EndMonth: "Program.Misc.LowPowerShutdown.${index}.EndMonth",
                EndDate: "Program.Misc.LowPowerShutdown.${index}.EndDate",
                StartYear: "Program.Misc.LowPowerShutdown.${index}.StartYear",
                StartMonth: "Program.Misc.LowPowerShutdown.${index}.StartMonth",
                StartDate: "Program.Misc.LowPowerShutdown.${index}.StartDate",
                StartHour: "Program.Misc.LowPowerShutdown.${index}.StartHour",
                StartMinute: "Program.Misc.LowPowerShutdown.${index}.StartMinute",
                Duration: "Program.Misc.LowPowerShutdown.${index}.Duration",
                LowPowerEnabled: "Program.Misc.LowPowerShutdown.${index}.LowPowerEnabled",
                AutoShutdownEnabled: "Program.Misc.LowPowerShutdown.${index}.AutoShutdownEnabled",
                LowPowerShutdownOptions: null
              },
              startIndex: 0,
              count: 4
            }]
          }]
        }, {
          sections: [{
            title: "machine_configuration",
            notMachine: "MC10_COMBO",
            controls: [{
              type: "number",
              propertyName: "Program.Misc.TotalNumberOfInlets",
              label: "total_number_of_inlets",
              min: 2,
              max: 3
            }, {
              type: "select",
              propertyName: "Program.Misc.Machine.VoltageSetting",
              label: "voltage_configuration",
              options: "VoltageType",
              disabled: true
            }, {
              type: "checkbox",
              propertyName: "Program.Misc.DrainValve1Inverted",
              label: "drain_valve_1_inverted"
            }, {
              type: "checkbox",
              propertyName: "Program.Misc.DrainValve2Enabled",
              label: "drain_valve_2"
            }, {
              type: "checkbox",
              propertyName: "Program.Misc.AutomaticCooldownEnabled",
              label: "automatic_cooldown"
            }, {
              type: "checkbox",
              propertyName: "Program.Misc.WaitForTemperatureEnabled",
              label: "wait_for_temperature"
            }, {
              type: "checkbox",
              propertyName: "Program.Misc.TemperatureBalanceEnabled",
              label: "temperature_balance"
            }, {
              type: "checkbox",
              propertyName: "Program.Misc.HasLiquidSoapSupply",
              label: "liquid_soap_supply"
            }, {
              type: "number",
              propertyName: "Program.Misc.MinimumSupplyLevel",
              label: "minimum_water_level_to_start_supply",
              min: 0,
              max: 9,
              enabled: this.machineProgram.settings.Program.Misc.HasLiquidSoapSupply
            }]
          }, {
            title: "audio_signals",
            notMachine: "MC10_COMBO",
            controls: [{
              type: "checkbox",
              propertyName: "Program.Misc.MiscConfig.TouchAcknowledgeAudioEnabled",
              label: "touch_acknowledge"
            }, {
              type: "checkbox",
              propertyName: "Program.Misc.MiscConfig.EndOfCycleAudioEnabled",
              label: "end_of_cycle"
            }, {
              type: "checkbox",
              propertyName: "Program.Misc.MiscConfig.CoinCardAudioEnabled",
              label: "coin_/_card",
              removeOpl: true
            }, {
              type: "checkbox",
              propertyName: "Program.Misc.MiscConfig.NetworkVendAudioEnabled",
              label: "serial_/_network_vend",
              removeOpl: true
            }, {
              type: "checkbox",
              propertyName: "Program.Misc.MiscConfig.StartModeAudioEnabled",
              label: "start_mode"
            }]
          }, {
            title: "cycle_related_settings",
            controls: [{
              type: "number",
              propertyName: "Program.Cycle.DefaultCycle",
              label: "default_cycle",
              min: 1,
              max: 15,
              notMachine: "MC10_COMBO"
            }, {
              type: "number",
              propertyName: "Program.Cycle.LowWaterLevel",
              label: "low_water_level",
              min: 9,
              max: 30
            }, {
              type: "number",
              propertyName: "Program.Cycle.MediumWaterLevel",
              label: "medium_water_level",
              min: 9,
              max: 30
            }, {
              type: "number",
              propertyName: "Program.Cycle.HighWaterLevel",
              label: "high_water_level",
              min: 9,
              max: 30
            }, {
              type: "temperature",
              propertyName: "Program.Cycle.HotWaterTemp",
              label: "hot_water_temperature",
              min: 35,
              max: 194
            }, {
              type: "temperature",
              propertyName: "Program.Cycle.WarmWaterTemp",
              label: "warm_water_temperature",
              min: 35,
              max: 194
            }, {
              type: "temperature",
              propertyName: "Program.Cycle.ColdWaterTemp",
              label: "cold_water_temperature",
              min: 35,
              max: 194
            }, {
              type: "number",
              propertyName: "Program.Cycle.AutoFlushCyclesBetween",
              label: "rinse_the_machine_cycle_interval",
              min: 1,
              max: 127,
              noLimitValue: 0,
              notMachine: "MC10_COMBO"
            }, {
              type: "number",
              propertyName: "Program.Misc.MinLevelToStartSupply",
              label: "minimum_water_level_to_start_supply",
              min: 0,
              max: 9,
              ifMachine: "MC10_COMBO"
            }, {
              type: "number",
              propertyName: "Program.Cycle.PrecleanCycleNumber",
              label: "pre_clean_cycle",
              min: 1,
              max: 15,
              notMachine: "MC10_COMBO"
            }, {
              type: "number",
              propertyName: "Program.Cycle.PrecleanCycleLimit",
              label: "pre_clean_cycle_time",
              labelAfter: "min",
              min: 1,
              max: 127,
              ifMachine: "MC10_COMBO"
            }]
          }, {
            title: "temperature_overrides",
            notMachine: "MC10_COMBO",
            controls: [{
              type: "select",
              propertyName: "Program.Cycle.PrewashColdTempOverride",
              label: "prewash_cold",
              options: "TemperatureType",
              customType: "temperature",
              hasCustom: true,
              showPresets: true,
              min: 35,
              max: 194
            }, {
              type: "select",
              propertyName: "Program.Cycle.PrewashWarmTempOverride",
              label: "prewash_warm",
              options: "TemperatureType",
              customType: "temperature",
              hasCustom: true,
              showPresets: true,
              min: 35,
              max: 194
            }, {
              type: "select",
              propertyName: "Program.Cycle.PrewashHotTempOverride",
              label: "prewash_hot",
              options: "TemperatureType",
              customType: "temperature",
              hasCustom: true,
              showPresets: true,
              min: 35,
              max: 194
            }, {
              type: "select",
              propertyName: "Program.Cycle.PrewashNoHeatTempOverride",
              label: "prewash_no_heat",
              options: "TemperatureType",
              customType: "temperature",
              hasCustom: true,
              showPresets: true,
              min: 35,
              max: 194
            }, {
              type: "select",
              propertyName: "Program.Cycle.Prewash15CTempOverride",
              labelTranslated: `${this.intl.t("prewash")} ${this.isCelsius ? this.intl.t("Temp15C") : this.intl.t("Temp59F")}`,
              options: "TemperatureType",
              customType: "temperature",
              hasCustom: true,
              showPresets: true,
              min: 35,
              max: 194
            }, {
              type: "select",
              propertyName: "Program.Cycle.Prewash30CTempOverride",
              labelTranslated: `${this.intl.t("prewash")} ${this.isCelsius ? this.intl.t("Temp30C") : this.intl.t("Temp86F")}`,
              options: "TemperatureType",
              customType: "temperature",
              hasCustom: true,
              showPresets: true,
              min: 35,
              max: 194
            }, {
              type: "select",
              propertyName: "Program.Cycle.Prewash40CTempOverride",
              labelTranslated: `${this.intl.t("prewash")} ${this.isCelsius ? this.intl.t("Temp40C") : this.intl.t("Temp104F")}`,
              options: "TemperatureType",
              customType: "temperature",
              hasCustom: true,
              showPresets: true,
              min: 35,
              max: 194
            }, {
              type: "select",
              propertyName: "Program.Cycle.Prewash50CTempOverride",
              labelTranslated: `${this.intl.t("prewash")} ${this.isCelsius ? this.intl.t("Temp50C") : this.intl.t("Temp122F")}`,
              options: "TemperatureType",
              customType: "temperature",
              hasCustom: true,
              showPresets: true,
              min: 35,
              max: 194
            }, {
              type: "select",
              propertyName: "Program.Cycle.Prewash60CTempOverride",
              labelTranslated: `${this.intl.t("prewash")} ${this.isCelsius ? this.intl.t("Temp60C") : this.intl.t("Temp140F")}`,
              options: "TemperatureType",
              customType: "temperature",
              hasCustom: true,
              showPresets: true,
              min: 35,
              max: 194
            }, {
              type: "select",
              propertyName: "Program.Cycle.Prewash90CTempOverride",
              labelTranslated: `${this.intl.t("prewash")} ${this.isCelsius ? this.intl.t("Temp90C") : this.intl.t("Temp194F")}`,
              options: "TemperatureType",
              customType: "temperature",
              hasCustom: true,
              showPresets: true,
              min: 35,
              max: 194
            }, {
              type: "select",
              propertyName: "Program.Cycle.WashColdTempOverride",
              label: "wash_cold",
              options: "TemperatureType",
              customType: "temperature",
              hasCustom: true,
              showPresets: true,
              min: 35,
              max: 194
            }, {
              type: "select",
              propertyName: "Program.Cycle.WashWarmTempOverride",
              label: "wash_warm",
              options: "TemperatureType",
              customType: "temperature",
              hasCustom: true,
              showPresets: true,
              min: 35,
              max: 194
            }, {
              type: "select",
              propertyName: "Program.Cycle.WashHotTempOverride",
              label: "wash_hot",
              options: "TemperatureType",
              customType: "temperature",
              hasCustom: true,
              showPresets: true,
              min: 35,
              max: 194
            }, {
              type: "select",
              propertyName: "Program.Cycle.WashNoHeatTempOverride",
              label: "wash_no_heat",
              options: "TemperatureType",
              customType: "temperature",
              hasCustom: true,
              showPresets: true,
              min: 35,
              max: 194
            }, {
              type: "select",
              propertyName: "Program.Cycle.Wash15CTempOverride",
              labelTranslated: `${this.intl.t("wash")} ${this.isCelsius ? this.intl.t("Temp15C") : this.intl.t("Temp59F")}`,
              options: "TemperatureType",
              customType: "temperature",
              hasCustom: true,
              showPresets: true,
              min: 35,
              max: 194
            }, {
              type: "select",
              propertyName: "Program.Cycle.Wash30CTempOverride",
              labelTranslated: `${this.intl.t("wash")} ${this.isCelsius ? this.intl.t("Temp30C") : this.intl.t("Temp86F")}`,
              options: "TemperatureType",
              customType: "temperature",
              hasCustom: true,
              showPresets: true,
              min: 35,
              max: 194
            }, {
              type: "select",
              propertyName: "Program.Cycle.Wash40CTempOverride",
              labelTranslated: `${this.intl.t("wash")} ${this.isCelsius ? this.intl.t("Temp40C") : this.intl.t("Temp104F")}`,
              options: "TemperatureType",
              customType: "temperature",
              hasCustom: true,
              showPresets: true,
              min: 35,
              max: 194
            }, {
              type: "select",
              propertyName: "Program.Cycle.Wash50CTempOverride",
              labelTranslated: `${this.intl.t("wash")} ${this.isCelsius ? this.intl.t("Temp50C") : this.intl.t("Temp122F")}`,
              options: "TemperatureType",
              customType: "temperature",
              hasCustom: true,
              showPresets: true,
              min: 35,
              max: 194
            }, {
              type: "select",
              propertyName: "Program.Cycle.Wash60CTempOverride",
              labelTranslated: `${this.intl.t("wash")} ${this.isCelsius ? this.intl.t("Temp60C") : this.intl.t("Temp140F")}`,
              options: "TemperatureType",
              customType: "temperature",
              hasCustom: true,
              showPresets: true,
              min: 35,
              max: 194
            }, {
              type: "select",
              propertyName: "Program.Cycle.Wash90CTempOverride",
              labelTranslated: `${this.intl.t("wash")} ${this.isCelsius ? this.intl.t("Temp90C") : this.intl.t("Temp194F")}`,
              options: "TemperatureType",
              customType: "temperature",
              hasCustom: true,
              showPresets: true,
              min: 35,
              max: 194
            }]
          }, {
            title: "dryer_temperatures",
            ifMachine: "MC10_COMBO",
            controls: [{
              type: "temperature",
              propertyName: "Program.Cycle.HighTemperature",
              label: "high_temperature",
              min: 35,
              max: 176
            }, {
              type: "temperature",
              propertyName: "Program.Cycle.MediumTemperature",
              label: "medium_temperature",
              min: 35,
              max: 176
            }, {
              type: "temperature",
              propertyName: "Program.Cycle.LowTemperature",
              label: "low_temperature",
              min: 35,
              max: 176
            }]
          }, {
            title: "anti_wrinkle",
            ifMachine: "MC10_COMBO",
            controls: [{
              type: "checkbox",
              propertyName: "Program.Cycle.AntiWrinkleEnabled",
              label: "anti_wrinkle_enabled"
            }, {
              type: "number",
              propertyName: "Program.Cycle.AntiWrinkleDuration",
              label: "duration",
              labelAfter: "minutes",
              min: 5,
              max: 60
            }, {
              type: "number",
              propertyName: "Program.Cycle.AntiWrinkleRestTime",
              label: "anti_wrinkle_rest_time",
              labelAfter: "minutes",
              min: 5,
              max: 60
            }, {
              type: "number",
              propertyName: "Program.Cycle.AntiWrinkleActionTime",
              label: "anti_wrinkle_action_time",
              labelAfter: "minutes",
              min: 5,
              max: 60
            }, {
              type: "checkbox",
              propertyName: "Program.Cycle.AntiWrinkleAudioEnabled",
              label: "audio_enabled"
            }]
          }]
        }]
      };
    }),
    actions: {
      save() {
        alert('Save this form');
      }

    }
  });

  _exports.default = _default;
});