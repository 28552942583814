define("alliance-business-suite/templates/locations/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t57Cuc46",
    "block": "{\"symbols\":[],\"statements\":[[8,\"ui-loading-spinner\",[],[[],[]],null]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "alliance-business-suite/templates/locations/loading.hbs"
  });

  _exports.default = _default;
});