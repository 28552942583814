define("alliance-business-suite/components/form-ui/limit-dates-repeat-annually", ["exports", "alliance-business-suite/mixins/init-property-names"], function (_exports, _initPropertyNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function rollHours(value) {
    // If we add hours to a time that rolls it over to the next day, reset the value
    return value >= 24 ? value - 24 : value;
  }

  var _default = Ember.Component.extend(_initPropertyNames.default, {
    // eslint-disable-line ember/no-classic-classes
    tagName: 'div',
    yearOffset: 2000,
    defaultEndDate: "12/31/2099",
    repeatAnnually: Ember.computed(function () {
      // eslint-disable-line ember/require-computed-property-dependencies
      // We do not repeat annually if years are zero and month/day are > 0
      return this.startYear === 0 && this.startMonth > 0 && this.startDay > 0 ? false : true;
    }),
    StartDate: Ember.computed(function () {
      // eslint-disable-line ember/require-computed-property-dependencies
      if (this.startYear == 0 && this.startMonth == 0 && this.startDate == 0) {
        return moment().format("MM/DD/YYYY");
      } else {
        return moment({
          year: this.yearOffset + Number(this.startYear),
          month: this.startMonth - 1,
          date: this.startDate
        }).format("MM/DD/YYYY");
      }
    }),
    EndDate: Ember.computed(function () {
      // eslint-disable-line ember/require-computed-property-dependencies
      if (this.endYear == 0 && this.endMonth == 0 && this.endDate == 0) {
        return moment(this.defaultEndDate, "MM/DD/YYYY").format("MM/DD/YYYY");
      } else {
        return moment({
          year: this.yearOffset + Number(this.endYear),
          month: this.endMonth - 1,
          date: this.endDate
        }).format("MM/DD/YYYY");
      }
    }),
    limitEnabled: Ember.computed('startYear', 'startMonth', 'startDate', 'endYear', 'endMonth', 'endDate', {
      // This is based on magic. If limit dates is NOT enabled, ALL the date parts will be 0. If limit dates IS enabled, ALL the date parts will NOT be zero.
      get() {
        let props = ['startYear', 'startMonth', 'startDate', 'endYear', 'endMonth', 'endDate'];
        let allZero = props.every(propName => {
          return Ember.get(this, propName) === 0 || Ember.get(this, propName) === this.yearOffset; // TODO: Remove 2000 hack after we fix the adapter/serializer.
        });
        return !allZero;
      },

      set(key, value) {
        let object = this.object;

        if (value) {
          // Set default dates if enabled is checked on
          let now = moment();
          this.update(object, this.properties.startMonth.key, now.month() + 1);
          this.update(object, this.properties.startDate.key, now.date());
          this.update(object, this.properties.endMonth.key, 12);
          this.update(object, this.properties.endDate.key, 31);

          if (this.repeatAnnually) {
            this.update(object, this.properties.startYear.key, now.format('YY')); // Changed to always set a 2-digit year

            this.update(object, this.properties.endYear.key, 99);
          } else {
            this.update(object, this.properties.startYear.key, 0); // Changed to always set a 2-digit year

            this.update(object, this.properties.endYear.key, 0);
          }
        } else {
          this.update(object, this.properties.startYear.key, 0);
          this.update(object, this.properties.startMonth.key, 0);
          this.update(object, this.properties.startDate.key, 0);
          this.update(object, this.properties.endYear.key, 0);
          this.update(object, this.properties.endMonth.key, 0);
          this.update(object, this.properties.endDate.key, 0);
        }

        return value;
      }

    }),
    actions: {
      updateRepeatAnnually(e) {
        if (!e.target.checked) {
          this.update(this.object, this.properties.startYear.key, 0);
          this.update(this.object, this.properties.endYear.key, 0);
        } else {
          this.update(this.object, this.properties.startYear.key, moment(this.StartDate).year() - this.yearOffset);
          this.update(this.object, this.properties.endYear.key, moment(this.EndDate).year() - this.yearOffset);
        }
      },

      updateStartDate(value) {
        if (this.repeatAnnually) {
          this.update(this.object, this.properties.startYear.key, value.getYear() - this.yearOffset);
        } else {
          this.update(this.object, this.properties.startYear.key, 0);
        }

        this.update(this.object, this.properties.startMonth.key, value.getMonth() + 1);
        this.update(this.object, this.properties.startDate.key, value.getDate());
        Ember.set(this, 'StartDate', moment(value).format("MM/DD/YYYY"));
      },

      updateEndDate(value) {
        if (this.repeatAnnually) {
          this.update(this.object, this.properties.endYear.key, value.getYear() - this.yearOffset);
        } else {
          this.update(this.object, this.properties.endYear.key, 0);
        }

        this.update(this.object, this.properties.endMonth.key, value.getMonth() + 1);
        this.update(this.object, this.properties.endDate.key, value.getDate());
        Ember.set(this, 'EndDate', moment(value).format("MM/DD/YYYY"));
      }

    }
  });

  _exports.default = _default;
});