define("alliance-business-suite/helpers/filter-by-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (_ref) {
    let [collection, attrName, attrValue] = _ref;
    return collection.filter(el => attrValue.includes(Ember.get(el, attrName)));
  });

  _exports.default = _default;
});