define("alliance-business-suite/models/order", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    hasMany,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    intl: Ember.inject.service(),
    orderDate: attr('date'),
    isPaid: attr('boolean'),
    totalCost: attr('number'),
    guestName: attr('string'),
    guestEmailAddress: attr('string'),
    guestPhoneNumber: attr('string'),
    laundryCost: attr('number'),
    additionalSettingCost: attr('number'),
    tax: attr('number'),
    subTotalAmount: attr('number'),
    taxAmount: attr('number'),
    totalAmount: attr('number'),
    serviceType: belongsTo('service-type', {
      inverse: null
    }),
    status: belongsTo('status'),
    receipt: belongsTo('receipt'),
    salesReceipt: belongsTo('sales-receipt'),
    payment: belongsTo('payment'),
    settings: hasMany('settings'),
    lineItems: hasMany('line-items'),
    notes: hasMany('notes'),
    history: belongsTo('history'),
    brushLabel: Ember.computed.alias('guestName').readOnly(),
    brushSublabel: Ember.computed('serviceType', {
      get() {
        let serviceType = Ember.get(this, 'serviceType.lookupKey');
        return this.intl.t(serviceType);
      }

    }).readOnly()
  });

  _exports.default = _default;
});