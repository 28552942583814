define("alliance-business-suite/components/machines/programs/p-send", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "firebase/database", "moment"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _database, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18;

  let MachinesProgramsPSendComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember.inject.service, _dec9 = Ember.inject.service, _dec10 = Ember.inject.service, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember._tracked, _dec18 = Ember._tracked, _dec19 = Ember._action, _dec20 = Ember._action, _dec21 = Ember._action, _dec22 = Ember._action, _dec23 = Ember._action, (_class = class MachinesProgramsPSendComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "ajax", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "notifications", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "firebaseCommands", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "router", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "machines", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "machinePrograms", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "devtools", _descriptor10, this);
      (0, _initializerDefineProperty2.default)(this, "viewLater", _descriptor11, this);
      (0, _initializerDefineProperty2.default)(this, "minSendAtDate", _descriptor12, this);
      (0, _initializerDefineProperty2.default)(this, "selectedSendAtDate", _descriptor13, this);
      (0, _initializerDefineProperty2.default)(this, "selectedSendAtTime", _descriptor14, this);
      (0, _initializerDefineProperty2.default)(this, "selectedFrequency", _descriptor15, this);
      (0, _initializerDefineProperty2.default)(this, "saving", _descriptor16, this);
      (0, _initializerDefineProperty2.default)(this, "selectedTreeNodes", _descriptor17, this);
      (0, _initializerDefineProperty2.default)(this, "model", _descriptor18, this);
      let userId = this.access.accessUser.id;
      let user = this.store.peekRecord('employee', userId);
      let userTimeFormat = {
        display: '12:00 AM',
        value: '00:00'
      };

      if (user.timeFormat === 'HH:mm') {
        userTimeFormat = {
          display: '00:00',
          value: '00:00'
        };
      } // return userTimeFormat;


      this.selectedSendAtTime = userTimeFormat;

      if (this.devtools.trayEnabled && this.devtools.autoSendTest && this.devtools.testMachineId) {
        // this.send("sendProgramTest");
        this.sendProgramTest;
      }
    }

    get selectedMachines() {
      let data = []; // get(this, 'selectedTreeNodes').forEach(function(node) {

      this.selectedTreeNodes.forEach(function (node) {
        let {
          isMachine,
          machineId
        } = node.original;

        if (isMachine) {
          data.push(machineId);
        }
      });
      return data;
    }

    get frequencies() {
      let scheduleSubscriptionTemplate = this.model.scheduleSubscriptionTemplate;
      let firstObj = scheduleSubscriptionTemplate.firstObject;
      let freq = firstObj.frequencies;
      return freq;
    }

    get timeOptions() {
      let locale = 'en'; // or whatever you want...

      let hours = [];
      let userId = this.access.accessUser.id;
      let user = this.store.peekRecord('employee', userId);
      let userTimeFormat = `${user.get('timeFormat')} A`;

      if (user.get('timeFormat') === 'HH:mm') {
        userTimeFormat = `${user.get('timeFormat')}`;
      }

      _moment.default.locale(locale); // optional - can remove if you are only dealing with one locale


      for (let hour = 0; hour < 24; hour++) {
        let hourObj = {};
        let hour30Obj = {};
        hourObj.display = (0, _moment.default)({
          hour
        }).format(`${userTimeFormat}`);
        hourObj.value = (0, _moment.default)({
          hour
        }).format('HH:mm');
        hours.push(hourObj);
        hour30Obj.display = (0, _moment.default)({
          hour,
          minute: 30
        }).format(`${userTimeFormat}`);
        hour30Obj.value = (0, _moment.default)({
          hour,
          minute: 30
        }).format('HH:mm');
        hours.push(hour30Obj);
      }

      return hours;
    }

    get notReadyToSubscribe() {
      return (// all the following are required:
        !this.model.program.id || !this.access.accessUser.id || !this.selectedSendAtDate || !this.selectedSendAtTime.value || !this.selectedFrequency.id || !this.selectedMachines || this.selectedMachines.length <= 0
      );
    }

    async sendSaveSubscription() {
      // TODO: Make sure this works.
      let self = this;
      let templateId = this.model.scheduleSubscriptionTemplate.get('firstObject.id');
      let programId = this.model.program.id;
      let userId = this.access.accessUser.id;
      let sendAtDate = (0, _moment.default)(this.selectedSendAtDate).format('MM/DD/YY');
      let sendAtTime = this.selectedSendAtTime.value;
      let selectedFrequency = this.selectedFrequency.id;
      let machines = this.selectedMachines; // Convert the selected date/time to UTC

      let dateEntered = _moment.default.tz(`${sendAtDate} ${sendAtTime}`, 'M/DD/YY HH:mm', this.access.userTimeZone).utc();

      let payload = {
        'user': {
          'id': userId
        },
        'program': {
          'id': programId
        },
        sendAtDate: dateEntered.format('MM/DD/YY'),
        sendAtTime: dateEntered.format('HH:mm'),
        'frequency': {
          'id': selectedFrequency
        },
        'subscriptionConditions': []
      }; // Add in the machines

      machines.forEach(function (machine) {
        payload.subscriptionConditions.push({
          'subscriptionConditionTypeId': '3',
          'intValue': machine
        });
      });
      await this.ajax.request(`/subscriptionTemplates/${templateId}/subscriptions?raw=true`, {
        method: 'POST',
        data: payload,
        dataType: 'JSON'
      }).then(response => {
        if (response && response.errorMessage) {
          this.notifications.error(this.intl.t('error_send_program'), {
            autoClear: true,
            clearDuration: 6000
          });
          this.saving = false;
        } else {
          self.router.transitionTo('machines.programs');
          this.notifications.success(this.intl.t('program_send_response_success'), {
            autoClear: true,
            clearDuration: 6000
          });
        }

        return response;
      }).catch(error => {
        this.notifications.error(this.intl.t('error_send_program'), {
          autoClear: true,
          clearDuration: 6000
        });
        this.saving = false;
        return error;
      });
    }

    initFirebase() {
      this.fetchFirebaseBindings();
      let compatibleMachines = Ember.get(this, 'model.program.machineType.compatibleMachines'); // eslint-disable-line ember/no-get

      if (compatibleMachines && compatibleMachines.length > 0) {
        this.store.peekAll('machine').filter(m => {
          if (compatibleMachines.indexOf(parseInt(m.machineType.get('id')) != -1)) {
            m.machineCurrentStatus.get('statusId');
          }
        });
      }
    } // init the firebase bindings


    fetchFirebaseBindings() {
      let compatibleMachines = Ember.get(this, 'model.program.machineType.compatibleMachines'); // eslint-disable-line ember/no-get

      if (compatibleMachines && compatibleMachines.length > 0) {
        this.store.peekAll('machine').filter(m => {
          if (compatibleMachines.indexOf(parseInt(m.machineType.get('id')) != -1)) {
            m.machineCurrentStatus.get('statusId');
          }
        });
      }
    }

    sendProgramTest() {
      let machineIds = this.selectedMachines;

      if (this.devtools.trayEnabled && machineIds.length === 0 && this.devtools.testMachineId) {
        machineIds.push(this.devtools.testMachineId);
      }

      let machineProgramId = this.model.program.id;
      let programName = this.model.program.name;
      this.machines.sendProgramTest(machineIds, machineProgramId, programName);
    } // show the modal for program send progress


    sendProgram() {
      if (this.selectedMachines.length > 0) {
        this.machinePrograms.createNewProgramSend(this.selectedMachines, this.model.program);
      } else {
        this.notifications.error(this.intl.t('select_machine'), {
          autoClear: true,
          clearDuration: 6000
        });
      }
    }

    saveSubscription() {
      // Disable save button
      this.saving = true;
      this.sendSaveSubscription();
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "notifications", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "firebaseCommands", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "router", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "machines", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "machinePrograms", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "devtools", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "viewLater", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor12 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "minSendAtDate", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return (0, _moment.default)().add(1, 'days').toDate();
    }
  }), _descriptor13 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedSendAtDate", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return (0, _moment.default)(new Date()).add(1, 'days').format('MM/DD/YYYY');
    }
  }), _descriptor14 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedSendAtTime", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedFrequency", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        value: 'P0Y0M0DT0H0M0S',
        id: '7'
      };
    }
  }), _descriptor16 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "saving", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor17 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedTreeNodes", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A([]);
    }
  }), _descriptor18 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "model", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.model;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "initFirebase", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "initFirebase"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "fetchFirebaseBindings", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "fetchFirebaseBindings"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "sendProgramTest", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "sendProgramTest"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "sendProgram", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "sendProgram"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "saveSubscription", [_dec23], Object.getOwnPropertyDescriptor(_class.prototype, "saveSubscription"), _class.prototype)), _class));
  _exports.default = MachinesProgramsPSendComponent;
});