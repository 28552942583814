define("alliance-business-suite/templates/components/ui/validated-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fCuaY/Op",
    "block": "{\"symbols\":[],\"statements\":[[8,\"input\",[[16,\"placeholder\",[34,6]],[24,0,\"form-control\"]],[[\"@type\",\"@value\",\"@name\",\"@disabled\"],[[34,4],[34,5],[34,0],[34,7]]],null],[2,\"\\n\\n\"],[2,\"\\n\"],[10,\"div\"],[14,0,\"input-error\"],[12],[2,\"\\n\"],[6,[37,9],[[35,8]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"error\"],[12],[2,\"\\n\"],[2,\"      \"],[1,[34,3]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,9],[[35,10]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"warning\"],[12],[2,\"\\n      \"],[1,[30,[36,2],[[30,[36,2],[[30,[36,2],[[30,[36,2],[[35,1],\"validations\"],null],\"attrs\"],null],[35,0]],null],\"warningMessage\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"valuePath\",\"model\",\"get\",\"errorMessage\",\"type\",\"value\",\"placeholder\",\"disabled\",\"showErrorMessage\",\"liquid-if\",\"showWarningMessage\"]}",
    "moduleName": "alliance-business-suite/templates/components/ui/validated-input.hbs"
  });

  _exports.default = _default;
});