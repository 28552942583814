define("alliance-business-suite/services/machine-programs", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12;

  let MachineProgramsService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, (_class = class MachineProgramsService extends Ember.Service {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "notifications", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "firebaseCommands", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "showMachineProgramsSend", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "isProgressModalPinned", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "isCreate", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "programEvents", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "programQueueMachines", _descriptor10, this);
      (0, _initializerDefineProperty2.default)(this, "eventsProgressBarStats", _descriptor11, this);
      (0, _initializerDefineProperty2.default)(this, "eventsProgramsProcessingStatus", _descriptor12, this);
    }

    createNewProgramSend(selectedMachines, program) {
      let user = this.store.peekRecord('employee', this.access.accessUser.get('id'));
      let userTimeFormat = `${user.get('timeFormat')} A`;
      let userDateFormat = user.get('dateFormat');
      this.programEvents.pushObject({
        id: Math.random().toString(36).substring(2, 8) + Math.random().toString(36).substring(2, 8),
        selectedMachines: selectedMachines,
        machineProgramId: program.id,
        machineProgramName: program.name,
        timestamp: new Date(),
        fomattedTimestamp: moment().format(userDateFormat + ' ' + userTimeFormat),
        machineIdsProgrammingQueue: [],
        pendingFirebaseCommands: [],
        failedMachineIds: []
      });
      this.eventsProgressBarStats.pushObject({
        pending: 0,
        completed: 0,
        rejected: 0,
        failed: 0,
        unknown: 0
      });
      this.eventsProgramsProcessingStatus.pushObject({
        isPrelim: true
      });
      this.isCreate = true;
      this.showProgressComponent();
    }

    hasCreatedEvent() {
      this.isCreate = false;
    }

    toggleMachineProgramProgresssModal() {
      this.showProgressComponent();
    }

    addMachineToQueue(machine, eventIdx) {
      let {
        programEvents
      } = this;
      let e = programEvents[eventIdx];
      this.programQueueMachines.push(machine);
      this.updateEventProgressBarStats(machine.programStatus, eventIdx);
    }

    updateEventProgressBarStats(status, eventIdx) {
      let progressBarStats = this.eventsProgressBarStats[eventIdx];
      let newProgressBarStats = { ...progressBarStats
      };

      if (status == 'pending') {
        newProgressBarStats.pending += 1;
      }

      if (status == 'rejected') {
        newProgressBarStats.rejected += 1;
      }

      if (status == 'completed') {
        newProgressBarStats.pending -= 1;
        newProgressBarStats.completed += 1;
      }

      if (status == 'failed') {
        newProgressBarStats.pending -= 1;
        newProgressBarStats.failed += 1;
      }

      if (status == 'unknown') {
        newProgressBarStats.pending -= 1;
        newProgressBarStats.unknown += 1;
      }

      this.customNotifyPropertyChange('eventsProgressBarStats', eventIdx, newProgressBarStats);
    }

    parseFirebaseStatusToUpdateQueuedMachines(e, eventIdx, firebaseResponse) {
      let {
        failedMachineIds
      } = e;
      let fbMachineIdSuccess = [];
      let fbMachineIdFailed = [];
      firebaseResponse.machines.forEach(m => {
        if (m.success) {
          fbMachineIdSuccess.push(m.id);
        } else {
          fbMachineIdFailed.push(m.id);
        }
      });
      this.programQueueMachines.forEach((machine, idx) => {
        // only those machines from the specific event
        if (machine.eventId == e.id) {
          let updatedMachine = { ...machine
          }; // if that machine was in the response and success: true

          if (fbMachineIdSuccess.indexOf(machine.id) != -1) {
            if (machine.programStatus == 'pending') {
              updatedMachine.programStatus = 'completed';
              updatedMachine.icon = "fa-check-circle";
              updatedMachine.statusOrdinal = 1;
              this.updateEventProgressBarStats('completed', eventIdx);
            }
          } // if that machine was in the response and success: false


          if (fbMachineIdFailed.indexOf(machine.id) != -1) {
            if (machine.programStatus == 'pending') {
              updatedMachine.programStatus = 'failed';
              updatedMachine.icon = "fa-exclamation-triangle";
              updatedMachine.statusOrdinal = 2;
              failedMachineIds.push(machine.id);
              this.updateEventProgressBarStats('failed', eventIdx);
            }
          }

          this.customNotifyPropertyChange('programQueueMachines', idx, updatedMachine);
        }
      });
      this.removeEventPendingFirebaseCommand(e, eventIdx, firebaseResponse.id);
    }

    addPendingFirebaseCommandsToEvent(e, eventIdx, commandId) {
      let eventId = e.id;
      e.pendingFirebaseCommands.push(commandId);
      this.customNotifyPropertyChange('programEvents', eventIdx, e);
    }

    removeEventPendingFirebaseCommand(e, eventIdx, commandId) {
      let {
        pendingFirebaseCommands
      } = this.programEvents[eventIdx];
      let updated = pendingFirebaseCommands.filter(id => {
        if (id != commandId) {
          return id;
        }
      });
      let updatedEvent = { ...this.programEvents[eventIdx]
      };
      updatedEvent.pendingFirebaseCommands = updated;
      this.customNotifyPropertyChange('programEvents', eventIdx, updatedEvent);
      this.checkOverallStatus(updatedEvent, eventIdx);
    }

    checkOverallStatus(e, eventIdx) {
      // if no more firebase commands left, then the programming is complete
      if (e.pendingFirebaseCommands.length == 0) {
        let {
          eventsProgressBarStats
        } = this;
        let status = 'completed';

        if (eventsProgressBarStats[eventIdx].failed > 0) {
          status = 'completed_with_failures';
        }

        if (eventsProgressBarStats[eventIdx].completed == 0) {
          status = 'completed_all_failures';
        }

        this.updateEventOverallSendStatus(e, eventIdx, status);
      }
    }

    noFirebaseCommandFound(e, eventIdx, commandId) {
      this.programQueueMachines.forEach((machine, idx) => {
        // only those machines from the specific event
        if (machine.eventId == e.id) {
          let updatedMachine = { ...machine
          };

          if (machine.programStatus == 'pending') {
            updatedMachine.programStatus = 'unknown';
            updatedMachine.icon = "fa-question-circle";
            updatedMachine.statusOrdinal = 0;
            this.updateEventProgressBarStats('unknown', eventIdx);
          }

          this.customNotifyPropertyChange('programQueueMachines', idx, updatedMachine);
        }
      });
      this.removeEventPendingFirebaseCommand(e, eventIdx, commandId);
    }

    failEventMachine(eventIdx, machineId) {
      let e = this.programEvents[eventIdx];
      e.failedMachineIds.push(machineId);
    }

    failAllEventMachinesInQueue(e, eventIdx, statusType) {
      let {
        failedMachineIds
      } = e;
      let updated = [];
      this.programQueueMachines.forEach((machine, idx) => {
        if (machine.eventId == e.id) {
          let updatedMachine = { ...machine
          };

          if (machine.programStatus == 'pending') {
            updatedMachine.programStatus = 'failed';
            updatedMachine.icon = "fa-exclamation-triangle";
            updatedMachine.statusOrdinal = 2;
            this.updateEventProgressBarStats('failed', eventIdx);
            failedMachineIds.push(machine.id);
          }

          this.customNotifyPropertyChange('programQueueMachines', idx, updatedMachine);
        }
      });
      this.updateEventOverallSendStatus(e, eventIdx, statusType);
    }

    failCommandQueuedTooLong(e, eventIdx) {
      // clear out all the firebase commands
      let updatedEvent = { ...this.programEvents[eventIdx]
      };
      updatedEvent.pendingFirebaseCommands = [];
      this.customNotifyPropertyChange('programEvents', eventIdx, updatedEvent); // fail all pending machines

      this.failAllEventMachinesInQueue(e, eventIdx, "command_queued_too_long");
    }

    updateEventOverallSendStatus(e, eventIdx, status) {
      let statusDictionary = {
        'unknown': {
          bannerClass: 'unknown',
          bannerIcon: "/assets/images/inline-banner/unknown.svg",
          isDone: true,
          canResend: true
        },
        'command_queued_too_long': {
          bannerClass: 'danger',
          bannerIcon: "/assets/images/inline-banner/timeout.svg",
          isDone: true,
          canResend: true
        },
        'network_error': {
          bannerClass: 'danger',
          bannerIcon: "/assets/images/inline-banner/error.svg",
          isDone: true,
          canResend: true
        },
        'all_rejected': {
          bannerClass: 'danger',
          bannerIcon: "/assets/images/inline-banner/error.svg",
          isDone: true,
          canResend: true
        },
        'completed': {
          bannerClass: 'success',
          bannerIcon: "/assets/images/inline-banner/success.svg",
          isDone: true,
          canResend: false
        },
        'completed_with_failures': {
          bannerClass: 'warning',
          bannerIcon: "/assets/images/inline-banner/warning.svg",
          isDone: true,
          canResend: true
        },
        'completed_all_failures': {
          bannerClass: 'danger',
          bannerIcon: "/assets/images/inline-banner/error.svg",
          isDone: true,
          canResend: true
        }
      };
      let newStatus = {
        id: status,
        bannerClass: statusDictionary[status].bannerClass,
        bannerIcon: statusDictionary[status].bannerIcon,
        status: `program_send_status_${status}`,
        text: `program_send_status_${status}_subtitle`,
        isDone: statusDictionary[status].isDone
      };
      this.customNotifyPropertyChange('eventsProgramsProcessingStatus', eventIdx, newStatus);
    } // notify of property change to this value composed of an array with objects
    // due to @tracked changes in octane ember 3.16


    customNotifyPropertyChange(key, idx, newValue) {
      this[key].splice(idx, 1, newValue);
      this[key] = this[key]; // this assignment may look redundant but is needed to trigger the ember update
      // if (key == 'programEvents') {
      //   setTimeout(() => {
      //     this.checkOverallStatus(newValue, idx)
      //   }, 300)
      // }
    } // toggles the visibility in application-section for site wide modal


    showProgressComponent() {
      this.showMachineProgramsSend = !this.showMachineProgramsSend;
      this.isProgressModalPinned = false;
    }

    toggleProgressModalToDock() {
      this.isProgressModalPinned = !this.isProgressModalPinned;
    }

    deleteTestPrograms() {
      // Delete all auto-fetched test programs
      let testNameStartsWith = "__";
      let machineProgramList = this.store.peekAll('machineProgram');
      let testPrograms = machineProgramList.filter(program => {
        return program.name.startsWith(testNameStartsWith);
      });

      if (confirm(`This will delete ${testPrograms.length} programs. Continue?`)) {
        testPrograms.forEach(program => {
          program.destroyRecord();
        });
      }
    }

    deleteUnnamedPrograms() {
      // Delete all unnamed programs
      let testNameStartsWith = "New program created on ";
      let machineProgramList = this.store.peekAll('machineProgram');
      let unnamedPrograms = machineProgramList.filter(program => {
        return program.name.startsWith(testNameStartsWith);
      });

      if (confirm(`This will delete ${unnamedPrograms.length} programs. Continue?`)) {
        unnamedPrograms.forEach(program => {
          program.destroyRecord();
        });
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "notifications", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "firebaseCommands", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "showMachineProgramsSend", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isProgressModalPinned", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isCreate", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "programEvents", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "programQueueMachines", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor11 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "eventsProgressBarStats", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor12 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "eventsProgramsProcessingStatus", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  })), _class));
  _exports.default = MachineProgramsService;
});