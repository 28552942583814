define("alliance-business-suite/mixins/machines/programs/galaxy-lucky-cycles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    /*
      Galaxy machines do not have ability to set year for limit dates--only month and date.
      The year defaults to 1900 when limit dates is turned off, and 2000 when enabled.
     */
    defaultLimitDates: '1900-01-01T00:00:00',
    LuckyCycleInfo: Ember.computed.alias('machineProgram.settings.Program.Miscellaneous.LuckyCycleInfo'),
    actions: {
      updateTimeSpan(values) {
        Ember.set(this, 'LuckyCycleInfo.StartHour', values.startHour);
        Ember.set(this, 'LuckyCycleInfo.StartMinute', values.startMinute);
        Ember.set(this, 'LuckyCycleInfo.LengthInHours', values.durationHours);
      }

    },
    updateStartDateTime: Ember.observer('LuckyCycleInfo.{StartMonth,StartDate,StartHour,StartMinute}', function () {
      let month = Ember.get(this, 'LuckyCycleInfo.StartMonth');
      let date = Ember.get(this, 'LuckyCycleInfo.StartDate');
      let hour = Ember.get(this, 'LuckyCycleInfo.StartHour');
      let minute = Ember.get(this, 'LuckyCycleInfo.StartMinute');
      let dateTime = moment({
        year: 2000,
        month: month - 1,
        date,
        hour,
        minute
      });

      if (!dateTime.isValid()) {
        dateTime = Ember.get(this, 'defaultLimitDates');
      } else {
        dateTime = dateTime.format('YYYY-MM-DDTHH:mm:00');
      }

      Ember.set(this, 'LuckyCycleInfo.StartDateTime', dateTime);
    }),
    updateEndDateTime: Ember.observer('LuckyCycleInfo.{EndMonth,EndDate,EndHour,EndMinute}', function () {
      let month = Ember.get(this, 'LuckyCycleInfo.EndMonth');
      let date = Ember.get(this, 'LuckyCycleInfo.EndDate');
      let dateTime = moment({
        year: 2000,
        month: month - 1,
        date,
        hour: 0,
        minute: 0
      });

      if (!dateTime.isValid()) {
        dateTime = Ember.get(this, 'defaultLimitDates');
      } else {
        dateTime = dateTime.format('YYYY-MM-DDTHH:mm:00');
      }

      Ember.set(this, 'LuckyCycleInfo.EndDateTime', dateTime);
    }),
    startDate: Ember.computed('LuckyCycleInfo.{StartDate,StartMonth}', {
      get() {
        let year = 2000;
        let month = Ember.get(this, 'LuckyCycleInfo.StartMonth');
        let date = Ember.get(this, 'LuckyCycleInfo.StartDate');

        if (month === 0 && date === 0) {
          return null;
        } else {
          return moment({
            year,
            month: month - 1,
            date
          }).format(); // Moment months are 0-based values
        }
      },

      set(key, value) {
        // Gets set from pikaday component
        let dateValue = moment(value);
        Ember.set(this, 'LuckyCycleInfo.StartYear', 2000);
        Ember.set(this, 'LuckyCycleInfo.StartMonth', dateValue.month() + 1);
        Ember.set(this, 'LuckyCycleInfo.StartDate', dateValue.date());
        return value;
      }

    }),
    endDate: Ember.computed('LuckyCycleInfo.{EndDate,EndMonth}', {
      get() {
        let year = 2000;
        let month = Ember.get(this, 'LuckyCycleInfo.EndMonth');
        let date = Ember.get(this, 'LuckyCycleInfo.EndDate');

        if (month === 0 && date === 0) {
          return null;
        } else {
          return moment({
            year,
            month: month - 1,
            date
          }).format();
        }
      },

      set(key, value) {
        // Gets set from pikaday component
        let dateValue = moment(value);
        Ember.set(this, 'LuckyCycleInfo.EndYear', 2000);
        Ember.set(this, 'LuckyCycleInfo.EndMonth', dateValue.month() + 1);
        Ember.set(this, 'LuckyCycleInfo.EndDate', dateValue.date());
        return value;
      }

    }),
    limitLuckyDates: Ember.computed('LuckyCycleInfo.{StartDate,StartMonth,EndDate,EndMonth}', {
      // TODO: Confirm the following logic...
      get() {
        return !(Ember.get(this, 'LuckyCycleInfo.StartDate') === 0 && Ember.get(this, 'LuckyCycleInfo.StartMonth') === 0 && Ember.get(this, 'LuckyCycleInfo.EndDate') === 0 && Ember.get(this, 'LuckyCycleInfo.EndMonth') === 0);
      },

      set(key, value) {
        if (value === true) {
          Ember.set(this, 'LuckyCycleInfo.StartDate', 1);
          Ember.set(this, 'LuckyCycleInfo.StartMonth', 1);
          Ember.set(this, 'LuckyCycleInfo.StartYear', 2000);
          Ember.set(this, 'LuckyCycleInfo.EndDate', 31);
          Ember.set(this, 'LuckyCycleInfo.EndMonth', 12);
          Ember.set(this, 'LuckyCycleInfo.EndYear', 2000);
        } else {
          Ember.set(this, 'LuckyCycleInfo.StartDate', 0);
          Ember.set(this, 'LuckyCycleInfo.StartMonth', 0);
          Ember.set(this, 'LuckyCycleInfo.StartYear', 0);
          Ember.set(this, 'LuckyCycleInfo.EndDate', 0);
          Ember.set(this, 'LuckyCycleInfo.EndMonth', 0);
          Ember.set(this, 'LuckyCycleInfo.EndYear', 0);
        }

        return value;
      }

    })
  });

  _exports.default = _default;
});