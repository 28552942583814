define("alliance-business-suite/utils/traceability", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.STATUSES = void 0;
  _exports.fahrenheitToCelsius = fahrenheitToCelsius;
  _exports.formatMinutesToTime = formatMinutesToTime;
  _exports.padNumbers = padNumbers;
  _exports.setHeight = setHeight;
  // yes, there are 2 canceled states, 3 and 4 -- via alan
  const STATUSES = ['started', 'in-progress', 'completed', 'canceled', 'canceled', 'failed']; // calculates the dynamic height

  _exports.STATUSES = STATUSES;

  function setHeight() {
    let bottomOffset = 25;
    let windowHeight = window.innerHeight;
    let headerHeight = document.querySelector('.system-header').offsetHeight;
    let subMenuHeight = document.querySelector('.sub-menu').offsetHeight;
    let sectionPaddingTop = 20;
    let sectionHeader = document.querySelector('.ui-route-header').offsetHeight;
    let controlsHeight = document.querySelector('.controls-bar').offsetHeight;
    let locationsExpiringSoon = this.access.locationsExpiringSoon;
    let subscriptionWarningBanner = 0;

    if (locationsExpiringSoon) {
      if (locationsExpiringSoon.length > 0) {
        subscriptionWarningBanner = 43;
      }
    }

    let final = windowHeight - headerHeight - subMenuHeight - sectionPaddingTop - sectionHeader - controlsHeight - subscriptionWarningBanner - bottomOffset;
    this.height = final;
  }

  function formatMinutesToTime(minutes, roundNumber) {
    let hrs = 0,
        mins = 0,
        secs = 0,
        divisor;

    if (minutes >= 60) {
      hrs = (minutes / 60).toString().split(".")[0];
    }

    mins = parseFloat((minutes % 60).toFixed(2));
    mins = mins.toString().split(".")[0];
    secs = parseFloat((minutes % 60).toFixed(2));
    let tempSecs = secs.toString().split(".")[1];
    divisor = tempSecs <= 9 && tempSecs.length != 2 ? 10 : 100;

    if (roundNumber) {
      secs = Math.round(tempSecs / divisor * 60);
    } else {
      secs = parseInt(tempSecs / divisor * 60);
    }

    return {
      hrs: padNumbers(hrs),
      mins: padNumbers(mins, true),
      secs: padNumbers(secs, true)
    };
  }

  function padNumbers(num, maxTwoDigits) {
    if (!num) {
      num = 0;
    }

    if (parseInt(num) < 10) {
      num = '0' + num;
    }

    if (maxTwoDigits && parseInt(num) >= 100) {
      num = num.toString().slice(0, 2);
    }

    return num;
  }

  function fahrenheitToCelsius(fahrenheit) {
    if (!fahrenheit) {
      return null;
    }

    return parseFloat(((fahrenheit - 32) * 5 / 9).toFixed(2));
  }
});