define("alliance-business-suite/components/machines/programs/utils", ["exports", "alliance-business-suite/utils/is-number", "alliance-business-suite/config/environment"], function (_exports, _isNumber, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  let createFixedDecimalProp = path => {
    return Ember.computed('decimalPlaces', path, {
      /* The API stores all values as integers, so we must convert values with decimals
       * to be integers in our model...but displayed as decimals in our front-end.
       * There is a property on the model that tells us how many decimals we need, and
       * we use that to handle converting values.
       */
      get() {
        let value = this.get(path);
        let decimalPlaces = this.decimalPlaces || 0;
        return value / 10 ** decimalPlaces;
      },

      set(key, value) {
        let decimalPlaces = this.decimalPlaces || 0;

        if ((0, _isNumber.isNumber)(value)) {
          let apiValue = decimalPlaces !== undefined ? new Big(value).times(new Big(10).pow(decimalPlaces)) : value;
          this.set(path, apiValue);
        }

        return value;
      }

    });
  };

  var _default = {
    decimalPlaces(path) {
      let displaySettingsOptions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [{
        val: 0,
        label: '0',
        precision: 0
      }, {
        val: 2,
        label: '0.00',
        precision: 2
      }, {
        val: 3,
        label: '0.000',
        precision: 3
      }];
      return Ember.computed(path, function () {
        let decimalIndex = Ember.get(this, path);

        try {
          return displaySettingsOptions.find(function (setting) {
            return setting.val === decimalIndex;
          }).precision;
        } catch (e) {
          let err = new Error(`decimalIndex:${decimalIndex} - Stringified error:${e}\n - e.message:${e.message} - e.stack:${e.stack}`);
          this.logr.captureException(err);
          console.error(err); // eslint-disable-line no-console

          Ember.set(this, path, 0);
          return 0;
        }
      });
    },

    createFixedDecimalProp,

    addDecicmalFixedProp(comp, propName, path) {
      comp[`Fixed${propName}`] = createFixedDecimalProp(path);
    },

    addStartEndDateFullComputeds(comp, prefix, path) {
      comp[`${prefix}StartDateTimeObserver`] = Ember.observer(`${path}.{StartDate,StartMonth,StartYear,StartHour,StartMinute}`, function () {
        /*
          This observer watches for changes to the start date/time and
          updates the StartDateTime property to match. A2 machines seem
          to use the StartDateTime property instead of individual datepart
          properties.
           Watch out! There's a serializer that can change the date values
          on us. This will makes years like 2000 become 0, which will mess
          up things.
        */
        if (Ember.get(this, `${path}.StartDateTime`)) {
          let startDate = Ember.get(this, `${path}.StartDate`);
          let startMonth = Ember.get(this, `${path}.StartMonth`);
          let startYear = Ember.get(this, `${path}.StartYear`); // If day, month, and year are zero, the we should return: '1900-01-01T00:00:00'

          if (startDate == 0 && startMonth == 0 && startYear == 0) {
            let noDate = _environment.default.machineProgram.nullDateString;
            Ember.set(this, `${path}.StartDateTime`, noDate);
          } else {
            // This feels icky, but fixes our serializer problem
            if (startMonth != 0 && startDate != 0 && startYear == 0) {
              startYear = 2000;
            } // To programmers in the future: you'll need to fix this when the year 2100 approaches.


            if (startYear > 0 && startYear < 100) {
              startYear = startYear += 2000;
            }

            startMonth = startMonth > 0 ? startMonth - 1 : 0;
            let startHour = Ember.get(this, `${path}.StartHour`);
            let startMinute = Ember.get(this, `${path}.StartMinute`);
            let startDateTime = moment({
              y: startYear,
              M: startMonth,
              d: startDate,
              h: startHour,
              m: startMinute
            });
            let startDateTimeFormatted = startDateTime.format('YYYY-MM-DDTHH:mm:ss');
            Ember.set(this, `${path}.StartDateTime`, startDateTimeFormatted);
          }
        }
      });
      comp[`${prefix}StartDateFull`] = Ember.computed(`${path}.StartDate`, `${path}.StartMonth`, `${path}.StartYear`, {
        get() {
          let startDate = Ember.get(this, `${path}.StartDate`);
          let startMonth = Ember.get(this, `${path}.StartMonth`);
          let startYear = Ember.get(this, `${path}.StartYear`);
          let dateString = `${startMonth}/${startDate}/${startYear}`;
          let dateObj = moment(dateString, 'MM/DD/YYYY');
          return dateObj.toDate();
        },

        set(key, value) {
          let startDate = moment(value).format('DD');
          let startMonth = moment(value).format('MM');
          let startYear = moment(value).format('YYYY');
          Ember.set(this, `${path}.StartDate`, startDate);
          Ember.set(this, `${path}.StartMonth`, startMonth);
          Ember.set(this, `${path}.StartYear`, startYear);
        }

      });
      comp[`${prefix}EndDateFull`] = Ember.computed(`${path}.EndDate`, `${path}.EndMonth`, `${path}.EndYear`, {
        get() {
          let endDate = Ember.get(this, `${path}.EndDate`);
          let endMonth = Ember.get(this, `${path}.EndMonth`);
          let endYear = Ember.get(this, `${path}.EndYear`);
          let dateString = `${endMonth}/${endDate}/${endYear}`;
          let dateObj = moment(dateString, 'MM/DD/YYYY');
          return dateObj.toDate();
        },

        set(key, value) {
          let endDate = moment(value).format('DD');
          let endMonth = moment(value).format('MM');
          let endYear = moment(value).format('YYYY');
          Ember.set(this, `${path}.EndDate`, endDate);
          Ember.set(this, `${path}.EndMonth`, endMonth);
          Ember.set(this, `${path}.EndYear`, endYear);
        }

      });
    },

    addStartEndTimeFullComputeds(comp, prefix, path) {
      comp[`${prefix}StartTimeFull`] = Ember.computed(`${path}.StartHour`, `${path}.StartMinute`, {
        get() {
          let startHour = Ember.get(this, `${path}.StartHour`);
          let startMinute = Ember.get(this, `${path}.StartMinute`);
          let time = `${startHour}:${startMinute}`;
          let dateObj = moment(time, 'HH:mm');
          return dateObj.format('HH:mm');
        },

        set(key, value) {
          let endValue = Ember.get(this, `${prefix}EndTimeFull`);
          let [startHour, startMinute] = value.split(':');
          Ember.set(this, `${path}.StartHour`, startHour);
          Ember.set(this, `${path}.StartMinute`, startMinute);
          let [endHour] = endValue.split(':');
          let startTime = `${startHour}:${startMinute}`;
          let startDateObj = moment(startTime, 'HH:mm');
          let endTime = `${endHour}:${startMinute}`;
          let endDateObj = moment(endTime, 'HH:mm');

          if (startDateObj.isSameOrAfter(endDateObj)) {
            endDateObj.add(1, 'days');
          }

          if (`${path}.LengthInHours`) {
            let lengthInHours = moment.duration(endDateObj.diff(startDateObj)).asHours();
            Ember.set(this, `${path}.LengthInHours`, lengthInHours);
          }

          if (`${path}.Duration`) {
            let Duration = moment.duration(endDateObj.diff(startDateObj)).asHours();
            Ember.set(this, `${path}.Duration`, Duration);
          }

          return value;
        }

      });
      comp[`${prefix}EndTimeFull`] = Ember.computed(`${path}.StartHour`, `${path}.StartMinute`, `${path}.LengthInHours`, `${path}.Duration`, {
        get() {
          let startHour = Ember.get(this, `${path}.StartHour`);
          let startMinute = Ember.get(this, `${path}.StartMinute`);
          let time = `${startHour}:${startMinute}`;
          let startDateObj = moment(time, 'HH:mm');

          if (`${path}.LengthInHours`) {
            let lengthInHours = Ember.get(this, `${path}.LengthInHours`);
            let endDateObj = startDateObj.add(lengthInHours, 'hours');
            return endDateObj.format('HH:mm');
          }

          if (`${path}.Duration`) {
            let Duration = Ember.get(this, `${path}.Duration`);
            let endDateObj = startDateObj.add(Duration, 'hours');
            return endDateObj.format('HH:mm');
          }
        },

        set(key, value) {
          let startValue = Ember.get(this, `${prefix}StartTimeFull`);
          let [startHour, startMinute] = startValue.split(':');
          let [endHour, endMinute] = value.split(':');

          if (endMinute !== startMinute) {
            startMinute = endMinute;
            Ember.set(this, `${path}.StartMinute`, startMinute);
          }

          let startTime = `${startHour}:${startMinute}`;
          let startDateObj = moment(startTime, 'HH:mm');
          let endTime = `${endHour}:${startMinute}`;
          let endDateObj = moment(endTime, 'HH:mm');

          if (startDateObj.isSameOrAfter(endDateObj)) {
            endDateObj.add(1, 'days');
          }

          if (`${path}.LengthInHours`) {
            let lengthInHours = moment.duration(endDateObj.diff(startDateObj)).asHours();
            Ember.set(this, `${path}.LengthInHours`, lengthInHours);
          }

          if (`${path}.Duration`) {
            let lengthInHours = moment.duration(endDateObj.diff(startDateObj)).asHours();
            Ember.set(this, `${path}.Duration`, lengthInHours);
          }

          return value;
        }

      });
    },

    generateLimitDatesComputed(path) {
      return Ember.computed(`${path}.StartDate`, `${path}.StartMonth`, `${path}.StartYear`, `${path}.EndDate`, `${path}.EndMonth`, `${path}.EndYear`, {
        get() {
          return !(Ember.get(this, `${path}.StartDate`) == 0 || Ember.get(this, `${path}.StartMonth`) == 0 || Ember.get(this, `${path}.StartYear`) == 0 || Ember.get(this, `${path}.EndDate`) == 0 || Ember.get(this, `${path}.EndMonth`) == 0 || Ember.get(this, `${path}.EndYear`) == 0);
        },

        set(key, value) {
          if (value) {
            Ember.set(this, `${path}.StartDate`, new Date().getDate());
            Ember.set(this, `${path}.StartMonth`, new Date().getMonth() + 1);
            Ember.set(this, `${path}.StartYear`, new Date().getFullYear());
            Ember.set(this, `${path}.EndDate`, new Date().getDate());
            Ember.set(this, `${path}.EndMonth`, new Date().getMonth() + 1);
            Ember.set(this, `${path}.EndYear`, new Date().getFullYear() + 1);
          } else {
            Ember.set(this, `${path}.StartDate`, 0);
            Ember.set(this, `${path}.StartMonth`, 0);
            Ember.set(this, `${path}.StartYear`, 0);
            Ember.set(this, `${path}.EndDate`, 0);
            Ember.set(this, `${path}.EndMonth`, 0);
            Ember.set(this, `${path}.EndYear`, 0);
          }

          return value;
        }

      });
    },

    // original util functions, before abstracting out computeds form pricing+special vend
    addVendPriceArrayComputeds(comp, useDecimalPlaces) {
      for (let i = 0; i < 9; ++i) {
        (function (i) {
          comp[`vendPriceArray${i}`] = Ember.computed('machineProgram.settings.Program.Price.VendPriceArray', 'decimalPlaces', {
            get() {
              let vendPriceArray = Ember.get(this, 'machineProgram.settings.Program.Price.VendPriceArray');

              if (!vendPriceArray) {
                // eslint-disable-next-line
                console.warn('settings.Program.Price.VendPriceArray is falsey, defaulting to empty array');
                vendPriceArray = [];
              }

              let decimalPlaces = this.decimalPlaces;
              let retVal = useDecimalPlaces && decimalPlaces ? vendPriceArray[i] / 10 ** decimalPlaces : vendPriceArray[i];
              return retVal;
            },

            set(key, value) {
              let vendPriceArray = Ember.get(this, 'machineProgram.settings.Program.Price.VendPriceArray');

              if (!vendPriceArray) {
                // eslint-disable-next-line
                console.warn('settings.Program.Price.VendPriceArray is falsey, defaulting to empty array');
                vendPriceArray = [];
              }

              let decimalPlaces = this.decimalPlaces;
              let setVal = useDecimalPlaces && decimalPlaces ? value * 10 ** decimalPlaces : value;
              vendPriceArray[i] = setVal;
              Ember.set(this, 'machineProgram.settings.Program.Price.VendPriceArray', vendPriceArray);
              return value;
            }

          });
        })(i);
      }
    },

    addDisplayedTempProps: (comp, options) => {
      ['Hot', 'Warm', 'Cold', 'CoolDown'].forEach(prop => {
        let {
          celciusPath
        } = options;
        let fullPropName = options.fullPropName(prop);
        let fullPath = options.fullPath(fullPropName);
        comp[`Displayed${fullPropName}`] = Ember.computed(celciusPath, {
          get() {
            let isCelsiusEnabled = Ember.get(this, celciusPath);
            let temp = Ember.get(this, fullPath); // console.log(
            //   `${fullPropName} GET`,
            //   'c enabled:', isCelsiusEnabled,
            //   'temp', temp
            // );

            if (isCelsiusEnabled) {
              // convert F to C
              // T(°C) = (T(°F) - 32) / 1.8
              // temp is always stored as F
              temp = (temp - 32) / 1.8;
            }

            temp = Math.round(temp); // console.log('new temp', temp);

            return temp;
          },

          set(key, value) {
            let isCelsiusEnabled = Ember.get(this, celciusPath);
            value = parseInt(value); // console.log(
            //   `${fullPropName} SET to`, value,
            //   'c enabled:', isCelsiusEnabled
            // );

            let temp = value;

            if (isCelsiusEnabled) {
              // convert C to F
              // T(°F) = T(°C) × 1.8 + 32
              temp = temp * 1.8 + 32;
            }

            temp = Math.round(temp); // console.log('new temp', temp);

            Ember.set(this, fullPath, temp);
            return value;
          }

        });
      });
    }
  };
  _exports.default = _default;
});