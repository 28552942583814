define("alliance-business-suite/templates/components/machines/programs/pricing/midas-wx-modal-increase-water-level", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xRoXpxIN",
    "block": "{\"symbols\":[\"ui\",\"@hideModal\"],\"statements\":[[8,\"form-ui\",[],[[\"@object\",\"@machineProgram\",\"@formSettings\",\"@selectOptions\",\"@formDisplay\"],[[32,0,[\"object\"]],[32,0,[\"machineProgram\"]],[32,0,[\"formSettings\"]],[32,0,[\"selectOptions\"]],\"compact\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[8,[32,1,[\"columns\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"column\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,1,[\"section\"]],[],[[\"@title\"],[[30,[36,0],[\"increase_water_level\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n        \"],[8,[32,1,[\"select\"]],[],[[\"@params\"],[[30,[36,1],null,[[\"propertyName\",\"label\",\"options\",\"hasCustom\",\"sharedCustomProperty\",\"showPresets\",\"min\",\"max\"],[\"Program.Price.ModifierOptions.IncreasedWaterLevelWash_WX\",\"prewash_wash\",\"WaterLevel\",true,true,true,1,30]]]]],null],[2,\"\\n\\n        \"],[8,[32,1,[\"select\"]],[],[[\"@params\"],[[30,[36,1],null,[[\"propertyName\",\"label\",\"options\",\"hasCustom\",\"sharedCustomProperty\",\"showPresets\",\"min\",\"max\"],[\"Program.Price.ModifierOptions.IncreasedWaterLevelRinse_WX\",\"rinse\",\"WaterLevel\",true,true,true,1,30]]]]],null],[2,\"\\n\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[11,\"button\"],[24,0,\"button--ok\"],[24,4,\"button\"],[4,[38,2],[\"click\",[32,2]],null],[12],[1,[30,[36,0],[\"OK\"],null]],[13],[2,\"\\n\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"hash\",\"on\"]}",
    "moduleName": "alliance-business-suite/templates/components/machines/programs/pricing/midas-wx-modal-increase-water-level.hbs"
  });

  _exports.default = _default;
});