define("alliance-business-suite/components/modal/modal-dialog", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#ember-wormhole to="ember-wormhole-destination"}}
    <div id="als-modal-container"
      {{did-insert this.fadeInModal}}
      >
  
      <div class="als-modal {{@containerClassNames}}">
        <header class="als-modal-header">
          <span class="title">
            {{@title}}
          </span>
  
          <div class="controls">
            {{#if this.machinePrograms.isProgressModalPinned}}
              <button
                {{on "click" this.undockModal}}>
                  <i class="fa fa-chevron-up" aria-hidden="true"></i>
              </button>
            {{else}}
              <button
                {{on "click" this.dockToBottom}}>
                  <i class="fa fa-chevron-down" aria-hidden="true"></i>
              </button>
            {{/if}}
  
            <button
              class="close-btn"
              {{on "click" this.close}}>
                <i class="fa fa-times" aria-hidden="true"></i>
                {{!-- <img src="/assets/images/icons/icon-close-modal.svg"> --}}
            </button>
          </div>
        </header>
  
        {{yield}}
  
        {{!-- <footer class="als-modal-footer">
          <div class="footer-left">
            <button class="button button--secondary">
              Extra
            </button>
  
            <button class="button button--secondary">
              Extra
            </button>
          </div>
  
  
          <div class="footer-right">
            <button class="button button--secondary">
              Cancel
            </button>
  
            <button class="button primary">
              Export
            </button>
          </div>
        </footer> --}}
      </div>
    </div>
  
    <div id="als-modal-bg-shield"></div>
  {{/ember-wormhole}}
  
  */
  {
    "id": "JiXSMYC2",
    "block": "{\"symbols\":[\"@containerClassNames\",\"@title\",\"&default\"],\"statements\":[[6,[37,3],null,[[\"to\"],[\"ember-wormhole-destination\"]],[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"div\"],[24,1,\"als-modal-container\"],[4,[38,1],[[32,0,[\"fadeInModal\"]]],null],[12],[2,\"\\n\\n    \"],[10,\"div\"],[15,0,[31,[\"als-modal \",[32,1]]]],[12],[2,\"\\n      \"],[10,\"header\"],[14,0,\"als-modal-header\"],[12],[2,\"\\n        \"],[10,\"span\"],[14,0,\"title\"],[12],[2,\"\\n          \"],[1,[32,2]],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"div\"],[14,0,\"controls\"],[12],[2,\"\\n\"],[6,[37,2],[[32,0,[\"machinePrograms\",\"isProgressModalPinned\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"            \"],[11,\"button\"],[4,[38,0],[\"click\",[32,0,[\"undockModal\"]]],null],[12],[2,\"\\n                \"],[10,\"i\"],[14,0,\"fa fa-chevron-up\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"            \"],[11,\"button\"],[4,[38,0],[\"click\",[32,0,[\"dockToBottom\"]]],null],[12],[2,\"\\n                \"],[10,\"i\"],[14,0,\"fa fa-chevron-down\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n          \"],[11,\"button\"],[24,0,\"close-btn\"],[4,[38,0],[\"click\",[32,0,[\"close\"]]],null],[12],[2,\"\\n              \"],[10,\"i\"],[14,0,\"fa fa-times\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n\"],[2,\"          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[18,3,null],[2,\"\\n\\n\"],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,1,\"als-modal-bg-shield\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"on\",\"did-insert\",\"if\",\"ember-wormhole\"]}",
    "moduleName": "alliance-business-suite/components/modal/modal-dialog.hbs"
  });

  let ModalModalDialogComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, (_class = class ModalModalDialogComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "machinePrograms", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor2, this);
    }

    fadeInModal() {
      const modalElem = document.getElementById("als-modal-container");
      const shieldElem = document.getElementById("als-modal-bg-shield"); // make visible, then delay for opacity transition

      modalElem.classList.add('is-visible');
      shieldElem.classList.add('is-visible');
      setTimeout(() => {
        shieldElem.classList.add('opacity-1');
        modalElem.classList.add('opacity-1');
      }, 100);
    } // @action


    fadeOutModal() {
      const modalElem = document.getElementById("als-modal-container");
      const shieldElem = document.getElementById("als-modal-bg-shield"); // remove opacity, then delay for opacity transition

      modalElem.classList.remove('opacity-1');
      shieldElem.classList.remove('opacity-1');
      setTimeout(() => {
        modalElem.classList.remove('is-visible');
        shieldElem.classList.remove('is-visible');
      }, 400);
    }

    dockToBottom() {
      this.machinePrograms.toggleProgressModalToDock(); // hide the background shield

      const shieldElem = document.getElementById("als-modal-bg-shield");
      shieldElem.classList.remove('opacity-1'); // animate and dock to the bottom of the screen

      const modalContent = document.querySelector("#als-modal-container .als-modal");
      const modalContentHeight = modalContent.offsetHeight;
      const headerHeight = document.querySelector("#als-modal-container .als-modal-header").offsetHeight; // set modals bottom prop first so that CSS does the transition

      const newBottomProp = (window.innerHeight - modalContentHeight) / 2;
      modalContent.style.bottom = `${newBottomProp}px`;
      setTimeout(() => {
        shieldElem.classList.remove('is-visible');
        modalContent.style.bottom = `-${modalContentHeight - headerHeight}px`;
      }, 100);
    }

    undockModal() {
      this.machinePrograms.toggleProgressModalToDock(); // show the background shield

      const shieldElem = document.getElementById("als-modal-bg-shield");
      shieldElem.classList.add('is-visible'); // animate and dock to the bottom of the screen

      const modalContent = document.querySelector("#als-modal-container .als-modal");
      const modalContentHeight = modalContent.offsetHeight;
      const headerHeight = document.querySelector("#als-modal-container .als-modal-header").offsetHeight; // set modals bottom prop first so that CSS does the transition

      const newBottomProp = (window.innerHeight - modalContentHeight) / 2;
      modalContent.style.bottom = `${newBottomProp}px`;
      setTimeout(() => {
        shieldElem.classList.add('opacity-1');
        modalContent.style.removeProperty('bottom');
      }, 400);
    }

    close() {
      if (confirm(this.intl.t("confirm_exit_machine_program_send_progress"))) {
        this.fadeOutModal();
        setTimeout(() => {
          this.args.close();
        }, 500);
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "machinePrograms", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "fadeInModal", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "fadeInModal"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "dockToBottom", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "dockToBottom"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "undockModal", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "undockModal"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "close", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype)), _class));
  _exports.default = ModalModalDialogComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ModalModalDialogComponent);
});