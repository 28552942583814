define("alliance-business-suite/templates/components/table/table-cell-quantity-amount", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JBufn0dU",
    "block": "{\"symbols\":[],\"statements\":[[1,[35,0,[\"prefix\"]]],[1,[30,[36,2],[[35,1],[35,0,[\"propertyName\"]]],null]],[1,[35,0,[\"suffix\"]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"column\",\"record\",\"get\"]}",
    "moduleName": "alliance-business-suite/templates/components/table/table-cell-quantity-amount.hbs"
  });

  _exports.default = _default;
});