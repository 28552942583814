define("alliance-business-suite/models/rewards-program-machine-type", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    machineTypeLookupKey: attr('number'),
    pointsPerUnitUsage: attr('number'),
    runFromDate: attr('string', {
      defaultValue: null
    }),
    runToDate: attr('string', {
      defaultValue: null
    }),
    rewardsProgram: belongsTo('rewards-program'),
    machineType: belongsTo('machine-type'),
    dateRange: Ember.computed('runFromDate', {
      get() {
        let runFromDate = this.runFromDate;
        return runFromDate == null ? 'FOREVER' : 'CUSTOM';
      },

      set(key, value) {
        if (value === 'FOREVER') {
          this.set('runFromDate', null);
          this.set('runToDate', null);
        }

        return value;
      }

    }),
    machineTypeName: Ember.computed('machineTypeLookupKey', function () {
      if (this.machineTypeLookupKey || this.machineTypeLookupKey == 0) {
        return this.store.peekRecord('machineType', this.machineTypeLookupKey).get('name');
      }
    })
  });

  _exports.default = _default;
});