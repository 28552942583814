define("alliance-business-suite/templates/components/table/table-cell-quantity-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "O11aw6Kl",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,7],[[30,[36,6],[[35,4],0],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"order-progress\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"progress-bar\"],[12],[2,\"\\n      \"],[10,\"span\"],[15,0,[31,[[34,0]]]],[15,5,[30,[36,2],[[35,1],\"0.0%\",\"width:\"],null]],[12],[13],[2,\"\\n      \"],[8,\"ember-tooltip\",[],[[\"@event\",\"@class\"],[\"hover\",[30,[36,3],[\"\",[35,0]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        Inventory at \"],[1,[34,4]],[2,\"%, alert trigger set at \"],[1,[34,5]],[2,\" threshold\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"cssColor\",\"progressBar\",\"safe-string\",\"concat\",\"progressPercent\",\"alertThreshold\",\"gte\",\"if\"]}",
    "moduleName": "alliance-business-suite/templates/components/table/table-cell-quantity-bar.hbs"
  });

  _exports.default = _default;
});