define("alliance-business-suite/components/profile/select-datetime-format", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    access: Ember.inject.service(),
    intl: Ember.inject.service(),
    dateFormats: [// TODO: This needs to be localized
    {
      value: 'MM/DD/YYYY',
      label: 'MM/DD/YYYY'
    }, {
      value: 'DD/MM/YYYY',
      label: 'DD/MM/YYYY'
    }, {
      value: 'YYYY/MM/DD',
      label: 'YYYY/MM/DD'
    }],
    timeFormats: Ember.computed(function () {
      return [{
        value: 'hh:mm',
        label: `${this.intl.t('standard_time')}`
      }, {
        value: 'HH:mm',
        label: `${this.intl.t('military_time')}`
      }];
    }),
    user: Ember.computed('access.user.id', function () {
      let userId = Ember.get(this, 'access.user.id');
      return Ember.get(this, 'store').peekRecord('employee', userId);
    }),
    calendarStartDays: [{
      value: 0,
      label: 'sunday'
    }, {
      value: 1,
      label: 'monday'
    }, {
      value: 2,
      label: 'tuesday'
    }, {
      value: 3,
      label: 'wednesday'
    }, {
      value: 4,
      label: 'thursday'
    }, {
      value: 5,
      label: 'friday'
    }, {
      value: 6,
      label: 'saturday'
    }],
    calendarStartDay: Ember.computed('user.calendarStartDay', {
      get() {
        let value = this.user.calendarStartDay || 'sunday';
        return this.calendarStartDays.findBy('label', value);
      },

      set(key, value) {
        Ember.set(this.user, 'calendarStartDay', value.label);
        return value;
      }

    }),
    dateFormat: Ember.computed('user.dateFormat', {
      get() {
        let date = this.user.get('dateFormat');
        return {
          value: date,
          label: date
        };
      },

      set(key, value) {
        Ember.set(this.user, 'dateFormat', value.label);
        return value;
      }

    }),
    timeFormat: Ember.computed('user.timeFormat', {
      get() {
        let time = this.user.get('timeFormat');
        return this.timeFormats.findBy('value', time);
      },

      set(key, value) {
        Ember.set(this.user, 'timeFormat', value.value);
        return value;
      }

    }),
    actions: {
      toggleEdit() {
        this.user.rollbackAttributes();
        this.toggleEdit();
      },

      save() {
        this.set('isSaving', true);
        this.user.save().then(response => {
          this.set('isSaving', false);
          this.send('toggleEdit');
        });
      }

    }
  });

  _exports.default = _default;
});