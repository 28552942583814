define("alliance-business-suite/templates/components/form-ui/world-drop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Gy8cSlnL",
    "block": "{\"symbols\":[\"ui\",\"number\"],\"statements\":[[8,\"form-ui\",[],[[\"@object\",\"@machineProgram\",\"@formSettings\",\"@selectOptions\"],[[32,0,[\"object\"]],[32,0,[\"machineProgram\"]],[32,0,[\"formSettings\"]],[32,0,[\"selectOptions\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n\"],[2,\"    \"],[8,[32,1,[\"number\"]],[],[[\"@params\"],[[30,[36,3],null,[[\"formDisplay\",\"propertyName\",\"label\",\"max\",\"min\"],[\"compact\",[32,0,[\"properties\",\"WorldDrop1CoinsAccepted\",\"key\"]],\"number_of_coins\",5,1]]]]],null],[2,\"\\n\\n\\n\"],[6,[37,7],[[30,[36,6],[[30,[36,6],[[30,[36,5],[0,3,true],null]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,[32,1,[\"select\"]],[],[[\"@params\"],[[30,[36,3],null,[[\"propertyName\",\"label\",\"options\",\"hasCustom\",\"sharedCustomProperty\",\"customType\",\"disabled\",\"min\",\"max\"],[[30,[36,4],[[32,0,[\"properties\",\"WorldDropValues\",\"key\"]],\".\",[32,2]],null],[30,[36,3],null,[[\"key\",\"placeholders\"],[\"coin_X\",[30,[36,3],null,[[\"number\"],[[30,[36,2],[[32,2],1],null]]]]]]],\"DropType\",true,true,\"currency\",[30,[36,1],[[30,[36,0],[[32,2],[32,0,[\"WorldDrop1CoinsAccepted\"]]],null],true,false],null],1,32767]]]]],null],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"\\n\"],[2,\"    \"],[8,[32,1,[\"select\"]],[],[[\"@object\",\"@params\"],[[32,0],[30,[36,3],null,[[\"propertyName\",\"label\",\"options\",\"disabled\"],[\"drop5\",[30,[36,3],null,[[\"key\",\"placeholders\"],[\"coin_X\",[30,[36,3],null,[[\"number\"],[5]]]]]],[32,0,[\"DropTypeToken\"]],[30,[36,1],[[30,[36,8],[[32,0,[\"WorldDrop1CoinsAccepted\"]],\"5\"],null],false,true],null]]]]]],null],[2,\"\\n\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"gte\",\"if\",\"add\",\"hash\",\"concat\",\"range\",\"-track-array\",\"each\",\"eq\"]}",
    "moduleName": "alliance-business-suite/templates/components/form-ui/world-drop.hbs"
  });

  _exports.default = _default;
});