define("alliance-business-suite/templates/components/ui/interactive-progress-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pHuY8SHP",
    "block": "{\"symbols\":[\"category\",\"category\",\"@stopPulse\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"interactive-progress-bar-container \",[30,[36,3],[[32,3],\"stopPulse\"],null]]]],[12],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"program-progress-bar\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,0,[\"total\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"span\"],[15,0,[31,[[32,2,[\"className\"]]]]],[15,5,[30,[36,2],[[32,2,[\"percent\"]],\"append\",\"width:\",\"%\"],null]],[12],[13],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"  \"],[13],[2,\"\\n\\n  \"],[10,\"ul\"],[14,0,\"program-progress-bar-listing\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,0,[\"total\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"li\"],[12],[2,\"\\n        \"],[10,\"span\"],[15,0,[31,[\"progress-category-circle \",[32,1,[\"className\"]]]]],[12],[13],[2,\"\\n        \"],[10,\"span\"],[12],[1,[30,[36,0],[[32,1,[\"localeKey\"]]],null]],[13],[2,\"\\n        \"],[10,\"small\"],[12],[1,[32,1,[\"percent\"]]],[2,\"%\"],[13],[2,\"\\n\"],[2,\"\\n        \"],[8,\"ember-tooltip\",[],[[\"@event\",\"@side\",\"@_tooltipVariantClass\"],[\"hover\",\"bottom\",\"ember-tooltip\"]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[30,[36,0],[[30,[36,1],[\"machine_send_progress_\",[32,1,[\"id\"]]],null]],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"concat\",\"safe-string\",\"if\",\"-track-array\",\"each\"]}",
    "moduleName": "alliance-business-suite/templates/components/ui/interactive-progress-bar.hbs"
  });

  _exports.default = _default;
});