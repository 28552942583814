define("alliance-business-suite/controllers/locations/wash-alert/ads", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    access: Ember.inject.service(),
    hasChanged: false,
    isSaving: false,
    deletedAds: [],

    async uploadImage(ad) {
      if (this.access.isDemo) {
        alert('Demo mode restricted.');
        return;
      }

      let _this = this;

      return await this.ajax.request(`/rooms/assetUrl?fileName=${ad.file.get('blob.name')}`, {
        method: 'GET'
      }).then(function (response) {
        ad.imageKey = `rooms/${response.fileName}`;
        return ad.file.uploadBinary(response.signedUrl, {
          method: 'PUT'
        });
      });
    },

    actions: {
      async save() {
        if (this.access.isDemo) {
          alert('Demo mode restricted.');
          return;
        }

        let self = this;
        let roomId = this.get('model.room.id');
        let ads = this.get('model.ads');
        let deletedAds = this.deletedAds; // validate slides

        for (let i = 0; i < ads.length; i++) {
          if (ads[i].advertisementTypeLookupKey == 'ADVERTISEMENT' && !ads[i].file && !ads[i].imageUrl) {
            alert(`Ads must have an image! Ad ${i + 1} is missing an image`);
            return false;
          } else if (ads[i].duration <= 0) {
            alert('All ads must have a duration in seconds!');
            return false;
          }
        }

        this.set('isSaving', true);

        for (let i = 0; i < deletedAds.length; i++) {
          await this.ajax.request(`/ads/${deletedAds[i].id}?raw=true`, {
            method: 'DELETE'
          }).then(function () {
            deletedAds.removeObject(deletedAds[i]);
          });
        } // loop over ads and if file exists, call uploadImage


        for (let i = 0; i < ads.length; i++) {
          if (ads[i].file) {
            await this.uploadImage(ads[i]);
            delete ads[i].file;
          }

          if (ads[i].id) {
            await this.ajax.request(`/ads/${ads[i].id}?raw=true`, {
              method: 'PATCH',
              data: ads[i]
            });
          } else {
            await this.ajax.request(`/rooms/${roomId}/ads?raw=true`, {
              method: 'POST',
              data: ads[i]
            }).then(response => {
              ads[i].id = response.id;
            });
          }
        }

        this.set('hasChanged', false);
        this.set('isSaving', false);
      }

    }
  });

  _exports.default = _default;
});