define("alliance-business-suite/components/ui/machine-program-send-container", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.machinePrograms.showMachineProgramsSend}}
    <Machines::Programs::ModalProgramSendProgress
      @self={{this}}
      @close={{this.toggleProgressModal}}
      @programEvents={{this.machinePrograms.programEvents}}
      @selectedMachines={{this.machinePrograms.selectedMachines}}
      @machineProgramId={{this.machinePrograms.machineProgramId}}
    />
  {{/if}}
  
  */
  {
    "id": "E1zwnUHY",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,0],[[32,0,[\"machinePrograms\",\"showMachineProgramsSend\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"machines/programs/modal-program-send-progress\",[],[[\"@self\",\"@close\",\"@programEvents\",\"@selectedMachines\",\"@machineProgramId\"],[[32,0],[32,0,[\"toggleProgressModal\"]],[32,0,[\"machinePrograms\",\"programEvents\"]],[32,0,[\"machinePrograms\",\"selectedMachines\"]],[32,0,[\"machinePrograms\",\"machineProgramId\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "alliance-business-suite/components/ui/machine-program-send-container.hbs"
  });

  let UiMachineProgramSendContainerComponent = (_dec = Ember.inject.service, _dec2 = Ember._action, (_class = class UiMachineProgramSendContainerComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "machinePrograms", _descriptor, this);
    }

    toggleProgressModal() {
      this.machinePrograms.showProgressComponent();
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "machinePrograms", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "toggleProgressModal", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "toggleProgressModal"), _class.prototype)), _class));
  _exports.default = UiMachineProgramSendContainerComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, UiMachineProgramSendContainerComponent);
});