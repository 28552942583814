define("alliance-business-suite/adapters/customer", ["exports", "alliance-business-suite/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import Ember from 'ember';
  // const { inject: { service }, get } = Ember;
  var _default = _application.default.extend({
    /**
     * The path to use when restricting the data returned from this adapter based
     * on a session property.
     *
     * @type {string}
     * @public
     */
    sessionPath: 'organizations/:organizationId/',

    // access: service(),

    /**
     * Builds the URL for a request.
     *
     * @public
     */
    // TODO - Work with team to use proper end point
    buildURL(modelName, id, snapshot, requestType, query) {
      let url = this._super(modelName, id, snapshot, requestType, query); // let organizationId = get(this, 'access.accessOrganization.id');


      switch (requestType) {
        case 'createRecord':
          // TODO - Work with team to use standard URL
          url = url.replace('customers', 'auth/register');
          break;

        case 'findRecord':
          url = url.replace('customer', 'user');
          break;

        case 'updateRecord':
          // TODO - Work with team to use standard URL
          url = url.replace('customers', 'users');
          break;

        case 'deleteRecord':
          // TODO - Work with team to use standard URL
          url = url.replace('customers', 'users');
          break;
      }

      return url;
    }

  });

  _exports.default = _default;
});