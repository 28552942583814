define("alliance-business-suite/templates/components/brushes/products-brush", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "G2XfHd2/",
    "block": "{\"symbols\":[],\"statements\":[[2,\"I manage products\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "alliance-business-suite/templates/components/brushes/products-brush.hbs"
  });

  _exports.default = _default;
});