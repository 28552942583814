define("alliance-business-suite/mixins/subscription-methods", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DAYS_OF_WEEK = [{
    id: 0,
    text: 'sunday'
  }, {
    id: 1,
    text: 'monday'
  }, {
    id: 2,
    text: 'tuesday'
  }, {
    id: 3,
    text: 'wednesday'
  }, {
    id: 4,
    text: 'thursday'
  }, {
    id: 5,
    text: 'friday'
  }, {
    id: 6,
    text: 'saturday'
  }];

  var _default = Ember.Mixin.create({
    dayOfWeeks: Ember.computed({
      get() {
        return DAYS_OF_WEEK;
      }

    })
  });

  _exports.default = _default;
});