define("alliance-business-suite/templates/components/table/programs/send/table-cell-program-progress", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dwai8UL0",
    "block": "{\"symbols\":[\"@record\"],\"statements\":[[10,\"div\"],[14,0,\"relative-container\"],[12],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[\"program-status-icon \",[32,1,[\"programStatus\"]]]]],[12],[2,\"\\n\"],[2,\"\\n    \"],[8,\"ember-tooltip\",[],[[\"@event\",\"@side\",\"@_tooltipVariantClass\"],[\"hover\",\"right\",\"ember-tooltip\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,1],[[30,[36,0],[\"machine_send_progress_\",[32,1,[\"programStatus\"]]],null]],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"concat\",\"t\"]}",
    "moduleName": "alliance-business-suite/templates/components/table/programs/send/table-cell-program-progress.hbs"
  });

  _exports.default = _default;
});