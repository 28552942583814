define("alliance-business-suite/templates/components/ui/shopping-cart/addon-listing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e4udS4Mv",
    "block": "{\"symbols\":[],\"statements\":[[11,\"label\"],[24,0,\"service-listing\"],[4,[38,3],[[32,0],\"checkboxUpdate\",[35,0]],null],[12],[2,\"\\n\\n\"],[6,[37,4],[[35,0,[\"isSelected\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"input\"],[14,\"checked\",\"\"],[14,4,\"checkbox\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"input\"],[14,4,\"checkbox\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[10,\"span\"],[14,0,\"service-title\"],[12],[2,\"\\n    \"],[10,\"strong\"],[12],[2,\"\\n      \"],[1,[30,[36,2],[[35,0,[\"lookupKey\"]]],null]],[2,\" \"],[1,[30,[36,2],[\"addon\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"ul\"],[14,0,\"service-info text-normal-casing\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,5],[[35,0,[\"lookupKey\"]],\"ADDON_CRM\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"li\"],[12],[1,[30,[36,2],[\"cart_upsell_CRM_BRANDLESS\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[10,\"li\"],[12],[1,[30,[36,2],[[30,[36,1],[\"cart_upsell_\",[35,0,[\"lookupKey\"]]],null]],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"span\"],[14,0,\"service-price text-normal-casing\"],[12],[2,\"\\n\"],[6,[37,4],[[35,0,[\"pricePerMonth\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"currency-options\",[],[[\"@value\",\"@isInput\"],[[34,0,[\"pricePerMonth\"]],false]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\" \"],[1,[30,[36,2],[\"a_month_billed_annually\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"addon\",\"concat\",\"t\",\"action\",\"if\",\"eq\"]}",
    "moduleName": "alliance-business-suite/templates/components/ui/shopping-cart/addon-listing.hbs"
  });

  _exports.default = _default;
});