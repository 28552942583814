define("alliance-business-suite/components/dashboard/summary/details/details-section", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="summary-details-tray" {{did-insert this.initialLoad}} {{did-update this.updateCall @data}}>
  
    <label>
      {{t "goods_breakdown_by_category"}}
    </label>
  
      {{#if (not this.isLoading)}}
        {{#each-in this.categories as |cat value|}}
          <Dashboard::Summary::SummaryTotal
            @title={{value.name}}
            @total={{value.revenue}}
            @color="goods"/>
        {{else}}
          {{!-- nothing? --}}
        {{/each-in}}
      {{/if}}
  </div>
  
  */
  {
    "id": "qNBhuIYy",
    "block": "{\"symbols\":[\"value\",\"cat\",\"@data\"],\"statements\":[[11,\"div\"],[24,0,\"summary-details-tray\"],[4,[38,2],[[32,0,[\"initialLoad\"]]],null],[4,[38,3],[[32,0,[\"updateCall\"]],[32,3]],null],[12],[2,\"\\n\\n  \"],[10,\"label\"],[12],[2,\"\\n    \"],[1,[30,[36,4],[\"goods_breakdown_by_category\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[6,[37,6],[[30,[36,5],[[32,0,[\"isLoading\"]]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[30,[36,0],[[32,0,[\"categories\"]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[8,\"dashboard/summary/summary-total\",[],[[\"@title\",\"@total\",\"@color\"],[[32,1,[\"name\"]],[32,1,[\"revenue\"]],\"goods\"]],null],[2,\"\\n\"]],\"parameters\":[1,2]},{\"statements\":[],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-each-in\",\"each\",\"did-insert\",\"did-update\",\"t\",\"not\",\"if\"]}",
    "moduleName": "alliance-business-suite/components/dashboard/summary/details/details-section.hbs"
  });

  let DashboardSummaryDetailsDetailsSectionComponent = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = Ember._action, (_class = class DashboardSummaryDetailsDetailsSectionComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "categories", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "isLoading", _descriptor2, this);
    }

    // we need the update so the {{each-in}} updates
    initialLoad() {
      this.calculateCategories();
    }

    updateCall() {
      this.calculateCategories();
    }

    calculateCategories() {
      this.isLoading = true;
      this.categories = {};
      this.args.data.forEach(day => {
        if (day.goodsRevenueByCategory.length > 0) {
          day.goodsRevenueByCategory.forEach(c => {
            let id = c.category.id;

            if (!this.categories[id]) {
              this.categories[id] = {
                id: id,
                name: c.categoryName,
                revenue: c.goodsRevenue
              };
            } else {
              this.categories[id].revenue += c.goodsRevenue;
            }
          });
        }
      });
      this.isLoading = false;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "categories", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isLoading", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "initialLoad", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "initialLoad"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "updateCall", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "updateCall"), _class.prototype)), _class));
  _exports.default = DashboardSummaryDetailsDetailsSectionComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, DashboardSummaryDetailsDetailsSectionComponent);
});