define("alliance-business-suite/templates/components/machines/programs/cycles/steps-mgd-opl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "12ibC0qQ",
    "block": "{\"symbols\":[\"control\"],\"statements\":[[6,[37,6],[[32,0,[\"selectedStep\",\"Type\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,0,[\"stepControls\",\"controls\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,3],[[30,[36,2],[\"form-ui/steps/\",[30,[36,1],[[32,1,[\"type\"]],[32,1]],null]],null]],[[\"object\",\"update\",\"formSettings\",\"selectOptions\",\"params\",\"machineSettings\",\"machineProgram\"],[[32,0,[\"selectedStep\"]],[32,0,[\"update\"]],[32,0,[\"formSettings\"]],[32,0,[\"selectOptions\"]],[32,0,[\"stepControls\"]],[32,0,[\"machineSettings\"]],[32,0,[\"machineProgram\"]]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[30,[36,0],[\"select_step\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[8,\"form-ui/steps/debug-step\",[],[[\"@stepModel\"],[[32,0,[\"object\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"or\",\"concat\",\"component\",\"-track-array\",\"each\",\"if\"]}",
    "moduleName": "alliance-business-suite/templates/components/machines/programs/cycles/steps-mgd-opl.hbs"
  });

  _exports.default = _default;
});