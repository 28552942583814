define("alliance-business-suite/helpers/t-advanced", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // have to use a class-based helper because we want to inject the intl service
  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),

    compute(_ref, _ref2) {
      let [localeKeys] = _ref;
      let {
        capitalize
      } = _ref2;
      if (!localeKeys || localeKeys.lenght === 0) return;
      const keysToTranslate = localeKeys.split(",");
      return keysToTranslate.map(l => {
        if (capitalize === 'every_first' || capitalize === 'default') {
          return this.capitalizeLetter(this.intl.t(l), capitalize === 'every_first');
        } else {
          return this.intl.t(l);
        }
      }).join(" ");
    },

    capitalizeLetter(localized, allFirstLetters) {
      return localized.split("").map((str, idx) => {
        if (allFirstLetters && (idx === 0 || localized[idx - 1] === " ")) {
          return str.toUpperCase();
        } else if (idx === 0) {
          return str.toUpperCase();
        } else {
          return str;
        }
      }).join("");
    }

  });

  _exports.default = _default;
});