define("alliance-business-suite/components/ui/transition-animation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const componentClassName = 'ui-transition-animation';
  const animationClass = `${componentClassName}--animating`;
  /**
   * A component which activates an animation whenever an observed property
   * changes.
   *
   * @public
   */

  let ComponentClass = Ember.Component.extend({
    /**
     * The static class names for this component.
     *
     * @type {Array<String>}
     * @public
     */
    classNames: [componentClassName],

    /**
     * The dynamic class names for this component that are derived from component
     * properties.
     *
     * @type {Array<String>}
     * @public
     */
    classNameBindings: ['animationClass'],

    /**
     * The class name used to activate the animation.
     *
     * @protected
     */
    animationClass,

    /**
     * Called after the component has been added to the DOM.
     *
     * @protected
     */
    didInsertElement() {
      this._super(...arguments);

      this.element.addEventListener('animationend', () => {
        Ember.set(this, 'animationClass', undefined);
      });
    },

    /**
     * Listens for changes to the observable and activates the animation when a
     * change occurs.
     *
     * @private
     */
    _observableChanged: Ember.observer('observable', function () {
      Ember.set(this, 'animationClass', animationClass);
    })
  });
  ComponentClass.reopenClass({
    positionalParams: ['observable']
  });
  var _default = ComponentClass;
  _exports.default = _default;
});