define("alliance-business-suite/controllers/reset-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['token', 'mobile', 'onboarding'],
    token: null,
    mobile: false,
    onboarding: false
  });

  _exports.default = _default;
});