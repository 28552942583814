define("alliance-business-suite/components/form-ui/duration-field/utils/pad", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = pad;

  function pad(val) {
    const str = String(val);

    if (str.length === 1) {
      return `0${str}`;
    } else {
      return str;
    }
  }
});