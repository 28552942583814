define("alliance-business-suite/components/machines/programs/water-guardian/acas-wx-flw", ["exports", "alliance-business-suite/mixins/machines/programs/acas-options", "alliance-business-suite/mixins/machines/programs/acas-property-names"], function (_exports, _acasOptions, _acasPropertyNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_acasOptions.default, _acasPropertyNames.default, {
    machineAuditType: Ember.computed.alias('machineProgram.machineAuditType.name'),
    options: {
      celsiusEnabled: [{
        val: true,
        label: 'Celsius'
      }, {
        val: false,
        label: 'Fahrenheit'
      }],
      temperatureUnits: [{
        val: 1,
        label: 'Celsius'
      }, {
        val: 0,
        label: 'Fahrenheit'
      }],
      globalFlushOut: [{
        val: 1,
        label: 'Drain 1'
      }, {
        val: 2,
        label: 'Drain 2'
      }, {
        val: 3,
        label: 'Both'
      }]
    }
  });

  _exports.default = _default;
});