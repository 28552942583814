define("alliance-business-suite/components/machines/programs/pricing/acas-tum-dry", ["exports", "alliance-business-suite/utils/machine-programming-find-options"], function (_exports, _machineProgrammingFindOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    machineAuditType: Ember.computed.alias('machineProgram.machineAuditType.id'),
    // Special Vend
    indexValues: Ember.computed("machineAuditType", function () {
      switch (this.machineAuditType) {
        case "ACAS_A44":
        case "ACAS_A2":
        case "TITAN_T2":
        case "TITAN_T1":
          return [0, 1];

        case "CENTURION_C4":
          return ["A", "B", "C", "D"];

        case "CENTURION_C3":
          return ["A", "B"];

        default:
          return [0, 1, 2, 3];
      }
    }),
    luckyCycleLabel: Ember.computed('machineAuditType', function () {
      if (["TITAN_T2", "TITAN_T1"].includes(this.machineAuditType)) {
        return "bonus_cycle";
      } else {
        return "lucky_cycle";
      }
    }),
    optionsDefaultCycles: (0, _machineProgrammingFindOptions.findOptions)([{
      machineAttributes: ['CENTURION_C4', 'ACAS_A4', 'TITAN_T4'],
      options: [{
        val: 1,
        label: 'high'
      }, {
        val: 2,
        label: 'low'
      }, {
        val: 3,
        label: 'no_heat'
      }, {
        val: 4,
        label: 'medium'
      }, {
        val: 5,
        label: 'delicate'
      }]
    }, {
      machineAttributes: ['CENTURION_C3', 'ACAS_A2'],
      options: [{
        val: 1,
        label: 'high'
      }, {
        val: 2,
        label: 'low'
      }, {
        val: 4,
        label: 'medium'
      }, {
        val: 5,
        label: 'delicate'
      }]
    }]),
    optionsDisplaySettings: [{
      val: 0,
      label: '0',
      precision: 0
    }, {
      val: 2,
      label: '0.00',
      precision: 2
    }, {
      val: 3,
      label: '0.000',
      precision: 3
    }],
    optionsTopoffEnable: [{
      label: "Enabled",
      val: 1
    }, {
      label: "Disabled",
      val: 0
    }, {
      label: "FullCycle",
      val: 2
    }]
  });

  _exports.default = _default;
});