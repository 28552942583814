define("alliance-business-suite/helpers/end-time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Used for machine programming
  var _default = Ember.Helper.helper(function (_ref, namedArgs) {
    let [value, ...rest] = _ref;
    let totalHour = namedArgs.startHour + namedArgs.duration;
    let duration = namedArgs.duration;
    let ampm = 'am';
    let warning = '';

    if (totalHour > 23) {
      warning = '<strong>End time setting allows option to continue into next day regardless of Day of Week settings.</strong>';
      totalHour -= 24;
    }

    if (totalHour > 12) {
      ampm = 'pm';
      totalHour -= 12;
    }

    if (totalHour > 11) {
      ampm = 'pm';
    }

    if (totalHour == 0) {
      totalHour = 12;
    }

    let output = `${totalHour}:${namedArgs.startMinute} ${ampm}`;

    if (warning) {
      output += `<br>${warning}`;
    }

    return Ember.String.htmlSafe(output);
  });

  _exports.default = _default;
});