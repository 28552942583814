define("alliance-business-suite/components/machines/programs/advanced/midas-opl-tum", ["exports", "alliance-business-suite/mixins/machines/programs/midas-opl-options", "alliance-business-suite/mixins/machines/programs/midas-opl-property-names"], function (_exports, _midasOplOptions, _midasOplPropertyNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_midasOplOptions.default, _midasOplPropertyNames.default, {
    formConfig: Ember.computed(function () {
      return {
        columns: [{
          sections: [//     {
          //       title: "power_fail_reset",
          //       controls: [
          //         {
          //           type: "power-fail-reset",
          //           properties: this.propertyNames.powerFailReset
          //         }
          //       ]
          //     },
          {
            title: "auto_ignite",
            controls: [{
              type: "number",
              propertyName: "Program.Cycle.AutoIgniteRetries_TUM"
            }]
          }, {
            title: "heater_temperatures",
            controls: [{
              type: "select",
              propertyName: "Program.Misc.MiscConfig.CelsiusEnabled",
              label: "CelsiusEnabled",
              options: 'temperatureUnits'
            }, {
              type: "select-temp",
              propertyName: "Program.Cycle.HighTemp_TUM",
              label: "high",
              options: 'temperatureValuesHigh'
            }, {
              type: "select-temp",
              propertyName: "Program.Cycle.MediumTemp_TUM",
              label: "medium",
              options: 'temperatureValuesMedium'
            }, {
              type: "select-temp",
              propertyName: "Program.Cycle.LowTemp_TUM",
              label: "low",
              options: 'temperatureValuesLow'
            }, {
              type: "select-temp",
              propertyName: "Program.Cycle.VeryLowTemp_TUM",
              label: "very_low",
              options: 'temperatureValuesVeryLow'
            }]
          }, {
            title: "reversing",
            controls: [{
              type: "select",
              propertyName: "Program.Cycle.ReversingRotateTime_TUM",
              label: "rotate",
              options: 'Rotate'
            }, {
              type: "select",
              propertyName: "Program.Cycle.ReversingStopTime_TUM",
              label: "pause",
              options: 'Pause'
            }]
          }, {
            title: "cooldown",
            controls: [{
              type: "select",
              propertyName: "Program.Cycle.CooldownTemp_TUM",
              label: "temperature",
              options: 'CooldownTemp'
            }, {
              type: "number",
              propertyName: "Program.Cycle.CooldownTime_TUM",
              label: "time",
              labelAfter: "min",
              min: 1,
              max: 15
            }]
          }]
        }, {
          sections: [{
            title: "miscellaneous",
            controls: [{
              type: "checkbox",
              propertyName: "Program.Cycle.MultiStepCycles_TUM",
              label: "multi-step"
            }, {
              type: "checkbox",
              propertyName: "Program.Cycle.AdvancedMoistureDryOptions_TUM",
              label: "advanced_moisture_dry_options"
            }, {
              type: "checkbox",
              propertyName: "Program.Display.LintScreenReminder_TUM",
              label: "lint_screen_reminder"
            }, {
              type: "number",
              propertyName: "Program.Display.CyclesBetweenLintReminders_TUM",
              label: "cycles_between_clear_lint_screen_reminder",
              min: 1,
              max: 255,
              enabled: this.object.Display.CyclesBetweenLintReminders_TUM
            }, // {
            //   type: "checkbox",
            //   propertyName: "",
            //   label: "door_open_indicator"
            // },
            {
              type: "checkbox",
              propertyName: "Program.Cycle.AdvancedReversing_TUM",
              label: "advanced_reversing"
            }]
          }, // {
          //   disable: true,
          //   title: "coin_start_pulses",
          //   controls: [
          //     {
          //       type: "coin-start-pulses",
          //       properties: this.propertyNames.coinStartPulses
          //     }
          //   ]
          // },
          {
            title: "availability",
            controls: [{
              type: "select",
              propertyName: "Program.Misc.MiscConfig.AvailableType1",
              options: "AvailableType"
            }, {
              type: "select",
              propertyName: "Program.Misc.MiscConfig.AvailableType2",
              options: "AvailableType"
            }, {
              type: "select",
              propertyName: "Program.Misc.MiscConfig.AvailableType3",
              options: "AvailableType"
            }]
          } // {
          //   title: "low_power",
          //   controls: [
          //     {
          //       type: "low-power",
          //       properties: this.propertyNames.lowPower,
          //       index: 0,
          //       number: 1
          //     },
          //     {
          //       type: "low-power",
          //       properties: this.propertyNames.lowPower,
          //       index: 1,
          //       number: 2
          //     },
          //     {
          //       type: "low-power",
          //       properties: this.propertyNames.lowPower,
          //       index: 2,
          //       number: 3
          //     },
          //     {
          //       type: "low-power",
          //       properties: this.propertyNames.lowPower,
          //       index: 3,
          //       number: 4
          //     }
          //   ]
          // },
          ]
        }]
      };
    })
  });

  _exports.default = _default;
});