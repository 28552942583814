define("alliance-business-suite/components/form-ui/text", ["exports", "alliance-business-suite/mixins/form-ui", "alliance-business-suite/utils/custom-hold-message"], function (_exports, _formUi, _customHoldMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_formUi.default, {
    tagName: 'div',
    classNames: ['form-field', 'overflow-float'],

    // maxlength: 524288
    serializeValue(rawValue) {
      // From API to the FE
      if (this.params.encoding && this.params.encoding.toLowerCase() === 'utf32-le') {
        return (0, _customHoldMessage.base64ToUnicode)(rawValue);
      } else {
        return rawValue; // Stub to override in component
      }
    },

    deserializeValue(value) {
      // Going back to API from the FE
      if (this.params.encoding && this.params.encoding.toLowerCase() === 'utf32-le') {
        return (0, _customHoldMessage.unicodeToBase64)(value, this.params.requiredLength);
      } else {
        return value; // Stub to override in component
      }
    }

  });

  _exports.default = _default;
});