define("alliance-business-suite/models/employee", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'firstName': [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    'lastName': [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    'email': [(0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('format', {
      type: 'email'
    })]
  });

  var _default = Model.extend(Validations, {
    firstName: attr('string'),
    lastName: attr('string'),
    timeZone: attr('string'),
    dateFormat: attr('string'),
    timeFormat: attr('string'),
    calendarStartDay: attr('string'),
    dateTimeFormat: attr('string'),
    locale: attr('string'),
    isActive: attr('boolean'),
    roleId: attr('string'),
    email: attr('string'),
    organization: belongsTo(),
    addSecurityRoles: attr(),
    removeSecurityRoles: attr(),
    addRooms: attr(),
    removeRooms: attr(),
    employeeNumber: attr('string'),
    ratePerHour: attr(),
    // securityRoles: hasMany('securityRole'), // THIS WAS A DUPLICATE KEY
    // roles: hasMany('role', {
    //   async: false
    // }),
    geoBoundaries: hasMany('geoBoundaries', {
      async: false
    }),
    rooms: hasMany('room', {
      async: false
    }),
    securityRoles: hasMany('securityRoles', {
      async: false
    }),
    accounts: hasMany('account', {
      inverse: 'user'
    }),
    // no longer `inverse: user` as that was causing odd sidebar behavior
    emailAddresses: hasMany('emailAddresses'),
    primaryEmail: Ember.computed('emailAddresses', 'emailAddresses.[].emailAddress', {
      get() {
        let emailAddresses = this.emailAddresses;

        if (emailAddresses.get('length')) {
          return emailAddresses.objectAt(0).get('emailAddress');
        }
      }

    }),
    phoneNumbers: hasMany('phoneNumbers', {
      inverse: 'user'
    }),
    primaryPhoneNumber: Ember.computed('phoneNumbers', 'phoneNumbers.[].contactNumber', {
      get() {
        let phoneNumbers = this.phoneNumbers;

        if (phoneNumbers.get('length')) {
          return phoneNumbers.objectAt(0).get('formattedPhoneNumber');
        }
      }

    }),
    createdAt: attr('date'),
    createdBy: belongsTo('user', {
      inverse: null
    }),
    updatedAt: attr('date'),
    updatedBy: belongsTo('user', {
      inverse: null
    }),
    userType: belongsTo('userType', {
      inverse: 'users'
    }),
    userRoles: hasMany('userRole', {
      inverse: 'user'
    }),

    /* Computed Properties */
    fullName: Ember.computed('firstName', 'lastName', {
      get() {
        return `${this.firstName} ${this.lastName}`;
      }

    }),
    brushLabel: Ember.computed.alias('fullName').readOnly(),
    primaryUserRole: Ember.computed('userRoles', {
      get() {
        let userRoles = this.userRoles;
        return Ember.get(userRoles, 'length') ? userRoles.objectAt(0) : undefined;
      }

    }),
    primaryUserRoleType: Ember.computed('primaryUserRole', {
      get() {
        let role = this.primaryUserRole;
        return role ? Ember.get(role, 'roleType') : undefined;
      }

    }),
    primarySecurityRole: Ember.computed('securityRoles', function () {
      let securityRoles = this.securityRoles;
      return securityRoles.get('firstObject');
    }),
    nameInitials: Ember.computed('firstName', 'lastName', function () {
      let firstInitial = this.firstName.length > 0 ? this.firstName.charAt(0) : '';
      let lastInitial = this.lastName.length > 0 ? this.lastName.charAt(0) : '';
      return `${firstInitial}${lastInitial}`;
    })
  });

  _exports.default = _default;
});