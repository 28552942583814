define("alliance-business-suite/components/summary/reports/s-capacity-utilization-breakdown", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#each this.formattedData.machineSeries as |data idx|}}
    <Summary::Reports::Chart::CapacityUtilizationBreakdown
      @yAxisCategories={{this.generatedChartData.dayDataFormatted}}
      @machine={{data}}
      @employees={{this.formattedData.employeeSeries}}
    />
  {{/each}}
  
  */
  {
    "id": "Ovo+9ClQ",
    "block": "{\"symbols\":[\"data\",\"idx\"],\"statements\":[[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,0,[\"formattedData\",\"machineSeries\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"summary/reports/chart/capacity-utilization-breakdown\",[],[[\"@yAxisCategories\",\"@machine\",\"@employees\"],[[32,0,[\"generatedChartData\",\"dayDataFormatted\"]],[32,1],[32,0,[\"formattedData\",\"employeeSeries\"]]]],null],[2,\"\\n\"]],\"parameters\":[1,2]}]]]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\"]}",
    "moduleName": "alliance-business-suite/components/summary/reports/s-capacity-utilization-breakdown.hbs"
  });

  // import {
  //   reportCapacityUtilizationBreakdown,
  //   edMachinesTest,
  //   edEmployeesTest
  // } from 'alliance-business-suite/utils/demo-opl'
  class SummaryReportsSCapacityUtilizationBreakdownComponent extends _component.default {
    // used to generate the days, which we use later to associate the cycles
    get generatedChartData() {
      let {
        startDate,
        endDate
      } = this.args;
      let dayData = [];
      let dayDataFormatted = [];
      let dayDifference = (new Date(endDate) - new Date(startDate)) / 1000 / 60 / 60 / 24;
      let count = 0;
      let daysDictionary = {}; // initial

      if (dayDifference <= 0) {
        dayData.push(moment(new Date(startDate).setDate(new Date(startDate).getDate() + 1)).format('MM-DD-YYYY'));
      } else {
        for (let i = 0; i <= dayDifference; i++) {
          let day = new Date(startDate).setDate(new Date(startDate).getDate() + i);
          dayData.push({
            date: day,
            formattedDay: moment(day).format('MM-DD-YYYY')
          });
          dayDataFormatted.push(moment(day).format('MM-DD-YYYY'));
          daysDictionary[moment(day).format('YYYY-MM-DD')] = count;
          count++;
        }
      }

      return {
        // dayDifference,
        // dayData,
        dayDataFormatted,
        daysDictionary
      };
    }

    get generateData() {
      let {
        rooms
      } = this.args.model;
      let machineSeries = [];
      let employeeSeries = [];
      rooms.forEach(room => {
        room.machines.forEach(machine => {
          let machineInfo = {
            roomId: room.id,
            roomName: room.name,
            machineId: machine.machineId,
            machineName: machine.machineName,
            machineDescription: machine.machineDescription,
            isDryer: machine.isDryer,
            cycles: []
          };
          machine.cycles.forEach(cycle => {
            let formattedDates = this.parseCycle(cycle, 'cycle');
            machineInfo.cycles.push({ ...cycle,
              ...formattedDates
            });
          });
          machineSeries.push(machineInfo);
        });
        room.employees.forEach(employee => {
          let employeeInfo = { ...employee,
            shifts: []
          };
          employee.shifts.forEach(shift => {
            let formattedDates = this.parseCycle(shift, 'shift');
            employeeInfo.shifts.push({ ...shift,
              ...formattedDates
            });
          });
          employeeSeries.push(employeeInfo);
        });
      });
      return {
        machineSeries,
        employeeSeries
      };
    }

    get formattedData() {
      let machineSeries = this.generateData.machineSeries.map(machineData => {
        return { ...machineData,
          name: `${machineData.machineId} - ${machineData.machineName}`,
          borderRadius: 5,
          data: machineData.cycles.map(cycle => {
            return {
              x: cycle._chartDayIdxYAxis,
              low: this.convertToUTC("2010-00-01", cycle.cycleStartTimePart).utcDate,
              high: this.convertToUTC("2010-00-01", cycle._cycleEndTimePart).utcDate,
              color: cycle._color,
              tooltipText: `${machineData.machineName}<br/> ${cycle.tooltipText}`
            };
          })
        };
      });
      let employeeSeries = this.generateData.employeeSeries.map(employeeData => {
        return {
          name: `${employeeData.firstName} - ${employeeData.lastName}`,
          data: employeeData.shifts.map(shift => {
            return {
              x: shift._chartDayIdxYAxis,
              low: this.convertToUTC("2010-00-01", shift.shiftStartTimePart).utcDate,
              high: this.convertToUTC("2010-00-01", shift.shiftEndTimePart).utcDate,
              // color: shift_color,
              tooltipText: `${employeeData.firstName} ${employeeData.lastName} <br/> ${shift.tooltipText}`
            };
          }),
          // color: 'url(#highcharts-default-pattern-0)'
          color: '#88e' // fillColor: {
          //     pattern: {
          //         color: '#11d'
          //     }
          // }
          // color: '#b5d6f5',
          // opacity: .85

        };
      });
      return {
        machineSeries,
        employeeSeries
      };
    }

    parseCycle(data, type) {
      let _startDatesFormatted = this.convertToUTC(data[`${type}StartDatePart`], data[`${type}StartTimePart`]); // using "shiftStartDatePart" below because BE isnt returning end property
      // so we will assume that it ends the same day it started


      const cycleEndDatePart = type === 'cycle' ? data.cycleEndDatePart : data.shiftStartDatePart;
      const cycleEndTimePart = type === 'cycle' ? data.cycleEndTimePart : data.shiftEndTimePart;

      const _endDatesFormatted = this.convertToUTC(cycleEndDatePart, cycleEndTimePart);

      return {
        _startDatesFormatted,
        _endDatesFormatted,
        _cycleEndTimePart: cycleEndTimePart,
        _chartDayIdxYAxis: this.generatedChartData.daysDictionary[cycleEndDatePart],
        _color: this.convertCycleColor(type === 'cycle' ? data.cycleEndDescription : 'red'),
        tooltipText: `${this.formatTooltip(_startDatesFormatted)} - ${this.formatTooltip(_endDatesFormatted)}`
      };
    }

    formatTooltip(data) {
      return `${data.hours.toString().length < 2 ? '0' + data.hours : data.hours}:${data.minutes.toString().length < 2 ? '0' + data.minutes : data.minutes}`;
    }

    convertToUTC(date, time) {
      const year = parseInt(date.slice(0, 4));
      const month = parseInt(date.slice(5, 7));
      const day = parseInt(date.slice(8, 11));
      const hours = parseInt(time.slice(0, 2));
      const minutes = parseInt(time.slice(3, 5));
      const seconds = parseInt(time.slice(6, 8));
      return {
        year,
        month,
        day,
        hours,
        minutes,
        seconds,
        utcDate: Date.UTC(year, month, day, hours, minutes, seconds)
      };
    }

    convertCycleColor(description) {
      let color = "#008200"; // green;
      // normal -> green (#008200)
      // rapid advance -> blue (#0800ff)
      // stop -> red (#ff0000)
      // error -> orange (#ffa000)
      // power fail -> dark red (#b41722)
      // unknown -> gray (#a9a9a9)
      // max heat -> purple (#530087)

      if (description === 'RAPID_ADVANCE') {
        color = "#0800ff";
      } // blue


      if (description === 'STOP') {
        color = "#ff0000";
      } // red


      if (description === 'ERROR') {
        color = "#ffa000";
      } // orange


      if (description === 'POWERFAIL') {
        color = "#b41722";
      } // dark red


      if (description === 'UNKNOWN') {
        color = "#a9a9a9";
      } // gray


      if (description === 'MAX_HEAT') {
        color = "#530087";
      } // purple


      if (description === 'MAXHEAT') {
        color = "#530087";
      } // purple (^ repeated cause we dont know actual key)


      return color;
    }

  }

  _exports.default = SummaryReportsSCapacityUtilizationBreakdownComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SummaryReportsSCapacityUtilizationBreakdownComponent);
});