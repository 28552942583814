define("alliance-business-suite/components/summary/reports/s-employee-time-clock", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "alliance-business-suite/utils/report-tables-util"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _reportTablesUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  let SummaryReportsSEmployeeTimeClockComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, (_class = class SummaryReportsSEmployeeTimeClockComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor3, this);
    }

    get translatedColumns() {
      return [{
        name: 'location_name',
        valuePath: 'roomName',
        width: 180,
        isFixed: 'left'
      }, {
        name: 'employee_number',
        valuePath: 'employeeNumber',
        width: 180
      }, {
        name: 'Date',
        valuePath: 'startDate',
        width: 180
      }, {
        name: 'In',
        valuePath: 'clockInDate',
        width: 180
      }, {
        name: 'Out',
        valuePath: 'clockOutDate',
        width: 180
      }, {
        name: 'total_time',
        valuePath: 'timeWorkedSecs',
        width: 180
      }, {
        name: 'total_hours',
        valuePath: 'timeWorkedHours',
        width: 180
      }];
    }

    get rowsWithChildren() {
      let orgRooms = this.args.model.get('orgRooms') ? this.args.model.get('orgRooms') : [];
      let rowData = [];
      orgRooms.forEach(orgRoom => {
        let orgRoomData = [];
        let roomSum = 0;
        let totalRoomTime = [];
        rowData.push({
          roomName: orgRoom.roomName,
          timeWorkedSecs: totalRoomTime,
          children: orgRoomData
        });
        orgRoom.employees.forEach(employee => {
          let employeeData = [];
          let employeeSum = 0;
          let totalEmployeeTime = [];
          orgRoomData.push({
            roomName: `${employee.firstName} ${employee.lastName}` || 'N/A',
            timeWorkedSecs: totalEmployeeTime,
            employeeNumber: employee.employeeNumber ? employee.employeeNumber : '—',
            children: employeeData
          });
          employee.timeEntries.forEach(timeEntry => {
            let timeEntryArray = [];
            let timeWorked = (0, _reportTablesUtil.convertSeconds)(timeEntry.timeWorkedSecs);

            if (timeEntry.timeWorkedSecs !== 0) {
              timeEntryArray.push(timeEntry.timeWorkedSecs);
              roomSum = (0, _reportTablesUtil.sumArray)(timeEntryArray, roomSum);
              employeeSum = (0, _reportTablesUtil.sumArray)(timeEntryArray, employeeSum);
            }

            employeeData.push({
              startDate: moment(timeEntry.clockInDate).format(`${_reportTablesUtil.formatDateTimeString.call(this)}`),
              clockInDate: moment(timeEntry.clockInDate).format(`${_reportTablesUtil.formatDateTimeString.call(this)}`),
              clockOutDate: moment(timeEntry.clockOutDate).format(`${_reportTablesUtil.formatDateTimeString.call(this)}`),
              timeWorkedHours: timeEntry.timeWorkedHours,
              timeWorkedSecs: timeWorked
            });
          });
          let timeWorked = (0, _reportTablesUtil.convertSeconds)(employeeSum);
          totalEmployeeTime.push(timeWorked);
        });
        let timeWorked = (0, _reportTablesUtil.convertSeconds)(roomSum);
        totalRoomTime.push(timeWorked);
      });
      return rowData;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SummaryReportsSEmployeeTimeClockComponent;
});