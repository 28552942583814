define("alliance-business-suite/components/ui/form-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    checked: null,
    controlId: null,
    labelText: '',
    elementName: Ember.computed(function () {
      return `checkbox_${Ember.get(this, 'element.id')}`;
    })
  });

  _exports.default = _default;
});