define("alliance-business-suite/components/machines/programs/config/midas-wx", ["exports", "alliance-business-suite/mixins/machines/programs/midas-options", "alliance-business-suite/mixins/machines/programs/midas-property-names"], function (_exports, _midasOptions, _midasPropertyNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_midasOptions.default, _midasPropertyNames.default, {
    formConfig: Ember.computed('object.settings.Messages', 'propertyNames.{breakinAlarm,daylightSaving,manualMode,userLanguages}', function () {
      return {
        columns: [{
          sections: [{
            title: "machine_settings",
            controls: [{
              type: "daylight-saving",
              hours12: true,
              properties: this.propertyNames.daylightSaving
            }, {
              type: "checkbox",
              propertyName: "Program.Display.VendPriceDisplayOverrideEnabled"
            }, {
              type: "checkbox",
              propertyName: "Program.Display.DisplayCurrentTemp_WX"
            }, {
              type: "checkbox",
              propertyName: "Program.Misc.Comms.IREnabled"
            }, {
              type: "checkbox",
              propertyName: "Program.Misc.Comms.USBEnabled"
            }, {
              type: "checkbox",
              propertyName: "Program.Misc.Comms.WifiEnabled"
            }, {
              type: "checkbox",
              propertyName: "Program.Misc.MiscConfig.FactoryTestCycleEnabled"
            }, {
              type: "checkbox",
              propertyName: "Program.Misc.MiscConfig.ErrorClearingEnabled"
            }, {
              type: "checkbox",
              propertyName: "Program.Misc.MiscConfig.PauseResumeModeEnabled_WX"
            }, {
              type: "number",
              propertyName: "Program.Cycle.BalanceAttemptLimit_WX",
              min: 1,
              max: 7
            }]
          }, {
            title: "break_in_alarm",
            controls: [{
              type: "break-in-alarm",
              properties: this.propertyNames.breakinAlarm
            }]
          }, {
            title: "manual_mode",
            controls: [{
              type: "manual-mode",
              properties: this.propertyNames.manualMode
            }]
          }, {
            title: "programmable_errors",
            controls: [{
              // Coin error
              type: "checkbox",
              propertyName: "Program.Misc.Errors.CoinErrorEnabled"
            }, {
              // Coin error penalty
              type: "checkbox",
              propertyName: "Program.Misc.Errors.CoinErrorPenaltyEnabled"
            }, {
              // Coin drop header
              type: "checkbox",
              propertyName: "Program.Misc.Errors.CoinDropVendHeaderErrorEnabled"
            }, {
              // Too hot to drain
              type: "checkbox",
              propertyName: "Program.Misc.Errors.DrainTempErrorEnabled_WX"
            }, {
              // Unbalance error
              type: "checkbox",
              propertyName: "Program.Display.DisplayNonFatalUnbalanceError_WX"
            }, {
              // Open themistor
              type: "checkbox",
              propertyName: "Program.Display.DisplayOpenThermistorError_WX"
            }, {
              // Shorted Thermister
              type: "checkbox",
              propertyName: "Program.Display.DisplayShortedThermistorError_WX"
            } // { // Broken belt - THIS SHOULD NOT BE DISPLAYED TO CUSOMTERS...IT'S AN INTERNAL SETTING
            //   type: "checkbox",
            //   propertyName: "Program.FactoryOnly.BrokenBeltErrorEnabled_WX"
            // }
            ]
          }, {
            title: "audio_signals",
            controls: [{
              type: "checkbox",
              propertyName: "Program.Misc.MiscConfig.EndOfCycleAudioEnabled"
            }, {
              type: "checkbox",
              propertyName: "Program.Misc.MiscConfig.CoinCardAudioEnabled"
            }, {
              type: "checkbox",
              propertyName: "Program.Misc.MiscConfig.NetworkVendAudioEnabled",
              inverted: true
            }, {
              type: "checkbox",
              propertyName: "Program.Misc.MiscConfig.KeyPressAudioEnabled"
            }, {
              type: "checkbox",
              propertyName: "Program.Misc.MiscConfig.StartModeAudioEnabled"
            }]
          }]
        }, {
          sections: [{
            title: "display_settings",
            controls: [{
              type: "checkbox",
              propertyName: "Program.Display.DisplayTimeSinceCyleComplete"
            }, {
              // Run
              type: "checkbox",
              propertyName: "Program.Display.HideTimeOnRunPage_WX"
            }, {
              // Ready mode
              type: "checkbox",
              propertyName: "Program.Display.HideTimeOnUpgrade_WX"
            }, {
              // Upgrade page
              type: "checkbox",
              propertyName: "Program.Display.HideTimeOnPresetUpgradePay_WX"
            }, {
              // Cycle display time
              type: "checkbox",
              propertyName: "Program.Display.DisplayProgrammableCycleTime_WX"
            }, {
              // Heat indicator
              type: "checkbox",
              propertyName: "Program.Display.DisplayHeatFlameIndicator"
            }, {
              type: "checkbox",
              propertyName: "Program.Display.DisplayApproachScreen"
            }, {
              type: "text",
              propertyName: "Messages.ApproachMessage",
              label: "approach_message",
              maxlength: 52,
              enabled: "Program.Display.DisplayApproachScreen",
              hide: this.object.settings.Messages === null
            }, {
              type: "checkbox",
              propertyName: "Program.Display.Backlight.ScreenSaverEnabled"
            }, {
              type: "checkbox",
              propertyName: "Program.Display.DisplayExtractSpeedInGForce_WX"
            }, {
              type: "select",
              propertyName: "Program.Misc.DaylightSavings.DateOrder",
              options: "DateOrder"
            }, {
              type: "select",
              propertyName: "Program.Misc.MiscConfig.DefaultLanguage",
              options: "Language",
              sortLocalized: true
            }, {
              type: "user-languages",
              languages: this.propertyNames.userLanguages
            }, {
              type: "number",
              propertyName: "Program.Display.Backlight.Active",
              labelKey: "BacklightActive",
              min: 0,
              max: 255
            }, {
              type: "number",
              propertyName: "Program.Display.Backlight.InactiveCriticalMode",
              min: 0,
              max: 255
            }, {
              type: "number",
              propertyName: "Program.Display.Backlight.InactiveNonCriticalMode",
              min: 0,
              max: 255
            }, {
              type: "text",
              propertyName: "Program.Misc.MiscConfig.HelpNumber",
              length: 20
            }]
          }, {
            title: "cycle_modifiers",
            controls: [{
              type: "custom-bundle-names",
              options: "CycleNames",
              properties: {
                CycleModifierName: "Program.Price.ModifierOptions.CycleModifierName_WX",
                bundle1Name: "Messages.Bundle1Name",
                bundle1Description: "Messages.Bundle1Description",
                bundle2Name: "Messages.Bundle2Name",
                bundle2Description: "Messages.Bundle2Description"
              }
            }]
          }, {
            title: "alarm_times",
            controls: [{
              // Fill error
              type: "checkbox-value-combo",
              properties: {
                checkbox1: {
                  key: "Program.Misc.Errors.FillErrorEnabled_WX",
                  labelKey: "FillErrorEnabled"
                },
                input1: {
                  key: "Program.Cycle.FillAlarmTimeMinutes_WX",
                  type: "number",
                  label: null,
                  min: 1,
                  max: 59,
                  labelAfter: "min"
                }
              }
            }, {
              // Drain error
              type: "checkbox-value-combo",
              properties: {
                checkbox1: {
                  key: "Program.Misc.Errors.DrainErrorEnabled_WX",
                  labelKey: "DrainErrorEnabled"
                },
                input1: {
                  key: "Program.Cycle.DrainAlarmTimeMinutes_WX",
                  type: "number",
                  label: null,
                  min: 1,
                  max: 59,
                  labelAfter: "min"
                }
              }
            }, {
              // No water flow
              type: "checkbox-value-combo",
              properties: {
                checkbox1: {
                  key: "Program.Misc.Errors.NoFlowErrorEnabled_WX",
                  labelKey: "NoFlowErrorEnabled"
                },
                input1: {
                  key: "Program.Cycle.NoWaterFlowAlarmTimeMinutes_WX",
                  type: "number",
                  label: null,
                  min: 1,
                  max: 59,
                  labelAfter: "min"
                }
              }
            }, {
              // Heater error
              type: "checkbox-value-combo",
              properties: {
                checkbox1: {
                  key: "Program.Display.DisplayHeaterError_WX",
                  labelKey: "DisplayHeaterError"
                },
                input1: {
                  key: "Program.Cycle.HeaterAlarmTimeMinutes_WX",
                  type: "number",
                  label: null,
                  min: 1,
                  max: 255,
                  labelAfter: "min"
                }
              }
            }, {
              type: "number",
              propertyName: "Program.Cycle.SlowToHeatAlarmTimeMintues_WX",
              min: 0,
              max: 255,
              labelAfter: "min"
            }]
          }]
        }]
      };
    })
  });

  _exports.default = _default;
});