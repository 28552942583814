define("alliance-business-suite/helpers/cycles-time-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (_ref) {
    let [value, ...rest] = _ref;

    /*
      We are only working with minutes, provided as integers.
      We only return something if the value is greater than zero.
    */
    if (value) {
      return `${value} min.`;
    }
  });

  _exports.default = _default;
});