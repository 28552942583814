define("alliance-business-suite/components/machines/status-display", ["exports", "alliance-business-suite/utils/led"], function (_exports, _led) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // #region LED display status
    access: Ember.inject.service(),
    ledMessage: '',
    ledMessageIndex: 0,
    hideEmptyStatusMessage: true,

    init() {
      this._super(...arguments);

      Ember.get(this, 'messageArray');
    },

    willDestroyElement() {
      this._super(...arguments);

      clearTimeout(this.cycleTime);
    },

    messageArray: Ember.computed('model.{id,machineCurrentStatus.displayStatus.values.@each}', function () {
      // Returns an array of decoded machine status messages
      let values = Ember.get(this, 'model.machineCurrentStatus.displayStatus.values');
      let machineAuditType = Ember.get(this, 'model.machineAuditType.id');

      if (Ember.isNone(values)) {
        return null;
      } else {
        return values.map(item => {
          switch (machineAuditType) {
            case "TITAN_T4":
            case "TITAN_T2":
            case "TITAN_T1":
              return item.toLowerCase();

            default:
              return (0, _led.ledCodeToString)(item);
          }
        });
      }
    }),
    hasMessage: Ember.computed('model.{id,machineCurrentStatus.displayStatus}', function () {
      return !Ember.isNone(Ember.get(this, 'model.machineCurrentStatus.displayStatus'));
    }),
    showMessage: Ember.computed('hasMessage', function () {
      return !(this.hideEmptyStatusMessage && !this.hasMessage);
    }),
    messageTiming: Ember.computed.alias('model.machineCurrentStatus.displayStatus.times'),
    processStatusMessage: Ember.observer('messageArray.@each', function () {
      // Clear any existing messages
      clearTimeout(Ember.get(this, 'cycleTime'));
      Ember.set(this, 'ledMessage', ''); // Show the message and start cycling messages if there are more than one

      let messageArray = Ember.get(this, 'messageArray');
      if (Ember.isNone(messageArray)) return;

      if (messageArray.length == 1) {
        Ember.set(this, 'ledMessage', messageArray[0]);
      } else {
        this.cycleMessages();
      }
    }),

    cycleMessages() {
      // Cycle messages when messageArray contains more than one message
      if (!this.isDestroyed) {
        let index = Ember.get(this, 'ledMessageIndex');
        let message = Ember.get(this, 'messageArray');
        let nextUpdate = Ember.get(this, 'messageTiming') ? Ember.get(this, 'messageTiming')[index] : 1000;

        if (message) {
          message = Ember.get(this, 'messageArray')[index];
        }

        let nextIndex = index + 1;
        Ember.set(this, 'ledMessage', message);

        if (Number(this.messageArray.length) > Number(nextIndex)) {
          Ember.set(this, 'ledMessageIndex', nextIndex);
        } else {
          Ember.set(this, 'ledMessageIndex', 0);
        }

        Ember.set(this, 'cycleTime', setTimeout(this.cycleMessages.bind(this), nextUpdate));
      }
    },

    ledMessageBackground: Ember.computed('messageArray.[]', 'ledMessage', function () {
      // Most machines are 6-character displays, except ACAS_A1 machines (and maybe others?)
      let shortDisplayAuditType = ['ACAS_A1'];
      let len = shortDisplayAuditType.includes(Ember.get(this, 'model.machineAuditType.id')) ? 4 : 6; // If there's a `:` before the last two characters, add it to the background

      if (this?.ledMessage && this.ledMessage.lastIndexOf(":") === this.ledMessage.length - 3) {
        return ''.padEnd(len - 2, '8') + ':88';
      } else {
        return ''.padEnd(len, '8');
      }
    }),
    // #endregion
    inUse: Ember.computed('model.machineCurrentStatus.statusId', function () {
      let status = Ember.get(this, 'model.machineCurrentStatus.statusId');
      return status == "IN_USE" ? "in-use" : "";
    }),
    statusType: Ember.computed('model.machineCurrentStatus.{statusId,displayStatus.error}', function () {
      let type = Ember.get(this, 'model.machineCurrentStatus.statusId');

      switch (type) {
        case "AVAILABLE":
        case "PARTIAL_VEND":
          return "vend";

        case "COMPLETE":
          return "complete";

        case "IN_USE":
        case "READY_TO_START":
          return "time";

        default:
          if (!Ember.isEmpty(Ember.get(this, 'model.machineCurrentStatus.displayStatus.error'))) {
            return "error";
          } else {
            return null;
          }

      }
    }),
    remainingTime: Ember.computed('model.machineCurrentStatus.remainingSeconds', function () {
      return parseInt(Ember.get(this, 'model.machineCurrentStatus.remainingSeconds') / 60); // TODO: Fix rounding
    }),
    machineType: Ember.computed('model.isWasher', function () {
      return Ember.get(this, 'model.isWasher') ? 'is-washer' : 'is-dryer';
    }),
    errorTitle: Ember.computed('model.machineCurrentStatus.displayStatus.error', function () {
      return Ember.get(this, 'model.machineCurrentStatus.displayStatus.error') || "";
    }),
    errorText: '',
    vend: Ember.computed('model.machineCurrentStatus.remainingVend', function () {
      let vend = Ember.get(this, 'model.machineCurrentStatus.remainingVend') || 0;
      return vend;
    }),
    // MIDAS and MC10 are advanced displays
    hasAdvancedDisplay: Ember.computed('model.machineAuditGroupType.id', function () {
      let type = this.model.get('machineAuditGroupType.id');

      if (type == 'MC10' || type == 'MIDAS') {
        return true;
      }
    })
  });

  _exports.default = _default;
});