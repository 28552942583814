define("alliance-business-suite/components/form-ui/weekday-list", ["exports", "alliance-business-suite/mixins/form-ui", "alliance-business-suite/mixins/init-property-names"], function (_exports, _formUi, _initPropertyNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_initPropertyNames.default, _formUi.default, {
    isBitfield: Ember.computed('DayOfWeek', function () {
      // We are dealing with a bitfield if `DayOfWeek` is defined
      return this.DayOfWeek !== undefined;
    })
  });

  _exports.default = _default;
});