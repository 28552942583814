define("alliance-business-suite/templates/components/form-ui/steps/cooldown-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fRU0h5oH",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[8,\"form-ui\",[],[[\"@object\",\"@formSettings\",\"@selectOptions\",\"@machineProgram\"],[[32,0,[\"object\"]],[32,0,[\"formSettings\"]],[32,0,[\"selectOptions\"]],[32,0,[\"machineProgram\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[10,\"h2\"],[12],[1,[30,[36,0],[\"type\"],null]],[13],[2,\"\\n\\n  \"],[8,[32,1,[\"select\"]],[],[[\"@object\",\"@update\",\"@params\"],[[32,0],[30,[36,1],[[32,0],\"updateValue\"],null],[30,[36,2],null,[[\"propertyName\",\"options\",\"label\"],[\"cooldownType\",\"CooldownType\",\"type\"]]]]],null],[2,\"\\n\\n    \"],[8,\"form-ui/dev-tools\",[],[[\"@outputs\"],[[30,[36,2],null,[[\"AntiWrinkle\",\"PlumbingCode\"],[[32,0,[\"AntiWrinkle\"]],[32,0,[\"PlumbingCode\"]]]]]]],null],[2,\"\\n\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"action\",\"hash\"]}",
    "moduleName": "alliance-business-suite/templates/components/form-ui/steps/cooldown-type.hbs"
  });

  _exports.default = _default;
});