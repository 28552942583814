define("alliance-business-suite/components/locations/locations-map", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  let LocationsLocationsMapComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, (_class = class LocationsLocationsMapComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "brush", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "activeLocation", _descriptor3, this);
    }

    get markers() {
      return this.createMarkers(this, this.args.rooms, this.activeLocation);
    }

    createMarkers(context, rooms, filterBy) {
      let content = [];
      let filterIds = [];

      if (filterBy) {
        filterBy.forEach(function (selectedRooms) {
          filterIds.push(parseInt(selectedRooms.id));
        });
      }

      rooms.forEach(function (room) {
        if (filterIds.length > 0) {
          let roomId = room.id;

          if (filterIds.indexOf(parseInt(roomId)) < 0) {
            return;
          }
        }

        let roomObj = {
          id: room.id,
          lat: room.lat,
          lng: room.lng,
          infoWindow: {
            content: `<p>${room.roomName}</p>`,
            visible: true
          },

          click(event) {
            context.brush.transitionTo('room', {
              type: 'room',
              id: event.target.id
            });
          }

        };
        content.pushObject(roomObj);
      });
      return content;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "brush", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "activeLocation", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = LocationsLocationsMapComponent;
});