define("alliance-business-suite/templates/components/table/table-cell-price", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+EJ8gJ+8",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,4],[[30,[36,2],[[35,1],[35,0,[\"propertyName\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"currency-options\",[],[[\"@value\"],[[30,[36,2],[[35,1],[35,0,[\"propertyName\"]]],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,4],[[35,0,[\"customIfNullUseIcon\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,3],[[30,[36,2],[[35,1],[35,0,[\"propertyName\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"i\"],[14,\"aria-hidden\",\"true\"],[14,0,\"fa fa-ban\"],[14,5,\"opacity: .25\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"column\",\"record\",\"get\",\"unless\",\"if\"]}",
    "moduleName": "alliance-business-suite/templates/components/table/table-cell-price.hbs"
  });

  _exports.default = _default;
});