define("alliance-business-suite/templates/components/ui/date-format", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gmn56jhz",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,12],[[35,15]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,12],[[35,14]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"div\"],[4,[38,13],[[32,0,[\"toggleInputless\"]]],null],[12],[2,\"\\n      \"],[8,\"pikaday-inputless\",[],[[\"@useUTC\",\"@format\",\"@value\",\"@onSelection\",\"@minDate\",\"@maxDate\",\"@firstDay\",\"@disabled\",\"@options\",\"@placeholder\"],[[34,0],[32,0,[\"formatDate\"]],[34,1],[30,[36,2],[[32,0],\"doChange\"],null],[34,3],[34,4],[34,5],[34,6],[34,7],[34,8]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"\\n\"],[6,[37,12],[[30,[36,11],[[35,10],true],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"pikaday-input\",[],[[\"@useUTC\",\"@format\",\"@value\",\"@onSelection\",\"@minDate\",\"@maxDate\",\"@firstDay\",\"@disabled\",\"@options\",\"@placeholder\"],[[34,0],[32,0,[\"formatDate\"]],[34,1],[30,[36,2],[[32,0],[30,[36,9],[[35,1]],null]],null],[34,3],[34,4],[34,5],[34,6],[34,7],[34,8]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"pikaday-input\",[],[[\"@useUTC\",\"@format\",\"@value\",\"@onSelection\",\"@minDate\",\"@maxDate\",\"@firstDay\",\"@disabled\",\"@options\",\"@placeholder\"],[[34,0],[32,0,[\"formatDate\"]],[34,1],[30,[36,2],[[32,0],\"doChange\"],null],[34,3],[34,4],[34,5],[34,6],[34,7],[34,8]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"useUTC\",\"value\",\"action\",\"minDate\",\"maxDate\",\"firstDay\",\"disabled\",\"options\",\"placeholder\",\"mut\",\"isMutant\",\"eq\",\"if\",\"on-click-outside\",\"showPika\",\"inputless\"]}",
    "moduleName": "alliance-business-suite/templates/components/ui/date-format.hbs"
  });

  _exports.default = _default;
});