define("alliance-business-suite/components/profile/e-password", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    currentPassword: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    newPassword: [(0, _emberCpValidations.validator)('format', {
      type: 'password'
    }), (0, _emberCpValidations.validator)('format', {
      // regex: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,24}$/,
      // regex: /^(?!.* )(?=.*\d)(?=.*[A-Z]).{8,24}$/,
      regex: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=\S*$).{8,24}$/,
      message: 'Password must include at least one upper case letter, one lower case letter, and a number'
    }) // validator('length', {
    //   min: 6,
    //   max: 30
    // })
    ],
    confirmPassword: [(0, _emberCpValidations.validator)('format', {
      type: 'password'
    }), (0, _emberCpValidations.validator)('presence', {
      presence: true
    }), (0, _emberCpValidations.validator)('confirmation', {
      on: 'newPassword',
      message: 'Passwords do not match'
    })]
  });

  var _default = Ember.Component.extend(Validations, {
    ajax: Ember.inject.service(),
    access: Ember.inject.service(),
    session: Ember.inject.service(),
    notifications: Ember.inject.service('notifications'),
    intl: Ember.inject.service(),
    passwordTypeTimeout: 'password',

    didInsertElement() {
      this._super(...arguments); // weird chrome bug, password must be rendered later


      Ember.run.later(() => {
        Ember.set(this, 'showForms', true);
        Ember.set(this, 'passwordTypeTimeout', 'password');
      }, 1000);
    },

    actions: {
      toggleEdit() {
        if (Ember.get(this, 'isLoadingPassword')) {
          return;
        }

        Ember.set(this, 'showForms', false);
        Ember.set(this, 'passwordTypeTimeout', 'text');
        Ember.run.later(() => {
          this.toggleEdit();
        }, 200);
      },

      async updatePassword() {
        Ember.set(this, 'isLoadingPassword', true);
        let userId = Ember.get(this, 'access.user.id');
        let currentPassword = Ember.get(this, 'currentPassword');
        let newPassword = Ember.get(this, 'newPassword');
        await this.ajax.request('/auth/changePassword', {
          method: 'POST',
          data: JSON.stringify({
            userId,
            currentPassword,
            newPassword
          })
        }).then(response => {
          Ember.get(this, 'notifications').info(this.intl.t('password_succesfully_updated'), {
            autoClear: true,
            clearDuration: 2000
          }); // console.info('response');
          // console.info(response);

          Ember.set(this, 'isLoadingPassword', false);
          this.send('toggleEdit');
          return response;
        }).catch((error
        /* , jqXHR*/
        ) => {
          // console.info('error');
          // console.info(response);
          // console.info(jqXHR.status);
          //
          Ember.get(this, 'notifications').error(this.intl.t('password_please_try_again'), {
            autoClear: true,
            clearDuration: 6000
          });
          Ember.set(this, 'isLoadingPassword', false);
          return error;
        });
      }

    }
  });

  _exports.default = _default;
});