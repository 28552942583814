define("alliance-business-suite/models/stat", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model
  } = _emberData.default; // TODO - This was used for stats on facilities which is now embedded. Machines has a stat model as
  // well, but if it becomes embedded then this model file can go away.

  var _default = Model.extend();

  _exports.default = _default;
});