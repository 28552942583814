define("alliance-business-suite/mixins/machines/programs/cycles/cycle-global-gold", ["exports", "jquery", "ember-copy", "alliance-business-suite/utils/convert-temp"], function (_exports, _jquery, _emberCopy, _convertTemp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    intl: Ember.inject.service(),
    notifications: Ember.inject.service(),
    selectedCycle: null,
    machineSpeeds: Ember.computed('model.machineType.id', function () {
      return Ember.get(this, 'model.machineType.machineSpeeds');
    }),
    machineSettings: Ember.computed('model.machineType.id', function () {
      return Ember.get(this, 'model.machineType.machineSettings');
    }),
    cycleList: Ember.computed('model', function () {
      return Ember.get(this, 'model.settings.Cycles');
    }),
    cycleNames: Ember.computed('model.settings.Cycles', function () {
      return Ember.get(this, 'cycleSettings.cycles');
    }),
    stepList: Ember.computed('selectedCycle', 'selectedCycle.[]', function () {
      let selectedCycle = this.selectedCycle;
      return selectedCycle ? selectedCycle.stages : null;
    }),
    stepTypeName: Ember.computed(function (type) {
      return Ember.get(this, 'cycleSettings.options.Type').findBy('Type', Ember.get(type, 'Type')).get('TypeName');
    }),

    init() {
      this._super();

      this.send('calculateTime');
    },

    didReceiveAttrs() {
      this._super();

      if (!Ember.get(this, 'model.settings._MotorDriveType')) {
        Ember.set(this, 'model.settings._MotorDriveType', 'V_SPEED');
      }

      if (!Ember.get(this, 'model.settings._convertToLiters')) {
        Ember.set(this, 'model.settings._convertToLiters', false);
      }
    },

    didInsertElement() {
      this._super(...arguments);

      let self = this; // Check for past cycle and step

      let cycleIndex = 0;
      let stepIndex = 0;
      let stepList = Ember.get(self, 'stepList'); // TODO: Make sure we don't go out of bounds

      if (cycleIndex != null && stepIndex != null) {
        this.send('selectCycle', cycleIndex, true);
        stepList = Ember.get(self, 'stepList');

        if (stepList) {
          this.send('selectStep', stepList.objectAt(stepIndex));
        }
      } else {
        this.send('selectStep', Ember.get(this, 'stepList.firstObject'));
        Ember.set(this, 'selectedCycle', Ember.get(this, 'cycleList.firstObject'));
        Ember.set(this, 'selectedCycleIndex', 0);
        Ember.set(this, 'selectedStep', Ember.get(this, 'stepList.firstObject'));
        Ember.set(this, 'selectedStepIndex', 0);
      }
    },

    getWaterUsage(firstFill, waterLevelGlobal, waterLevelCustom, waterTemp) {
      let waterUse = Ember.get(this, 'machineSettings.waterUse');
      let machineVolume = waterLevelCustom;

      if (Number(waterLevelGlobal) === 2) {
        machineVolume = Ember.get(this, 'model.settings.Program.WaterLevelHigh');
      }

      if (Number(waterLevelGlobal) === 1) {
        machineVolume = Ember.get(this, 'model.settings.Program.WaterLevelMedium');
      }

      if (Number(waterLevelGlobal) === 0) {
        machineVolume = Ember.get(this, 'model.settings.Program.WaterLevelLow');
      }

      let waterVolume = 0;

      if (firstFill) {
        let slope = Number(Ember.get(this, 'machineSettings.waterUse.slope1'));
        let intercept = Number(Ember.get(this, 'machineSettings.waterUse.intercept1'));
        waterVolume += slope * machineVolume + intercept;
      } else {
        waterVolume += Number(Ember.get(this, 'machineSettings.waterUse.slope2') * Number(machineVolume)) + Ember.get(this, 'machineSettings.waterUse.intercept2');
      }

      if (this.showWaterUsageWithHeatSump) {
        waterVolume += Ember.get(this, 'machineSettings.waterUse.heat');
      }

      if (Ember.get(this, 'model.settings._convertToLiters')) {
        waterVolume *= 3.785412;
      }

      switch (waterTemp) {
        case 0:
          // cold
          return {
            total: waterVolume,
            hot: 0
          };

        case 2:
          // hot
          return {
            total: waterVolume,
            hot: waterVolume
          };

        default:
          return {
            total: waterVolume,
            hot: waterVolume / 2
          };
      }
    },

    // #endregion
    isCelsius: Ember.computed.alias('model.settings.Program.Miscellaneous.CelsiusEnabled'),
    temperatureScale: Ember.computed('isCelsius', function () {
      return this.isCelsius ? 'C' : 'F';
    }),
    clipboard: {
      type: null,
      contents: null,
      title: null
    },
    convertToLiters: false,
    waterUnits: Ember.computed('model.settings._convertToLiters', function () {
      return Ember.get(this, 'model.settings._convertToLiters') ? 'L' : 'G';
    }),
    showWaterUsageWithHeatSump: false,
    showWater: Ember.computed('model.machineType', function () {
      return Ember.get(this, 'model.machineType.id') != 100;
    }),
    // #region Actions
    actions: {
      // TODO: Remove for production
      showModel() {
        console.log('model', this.model); // eslint-disable-line no-console
      },

      showCycles() {
        console.log('cycles', this.get('model.settings.Cycles')); // eslint-disable-line no-console
      },

      selectCycle(value, init) {
        // Value can be the cycle object or the index of the cycle
        let index, cycle;

        if (isNaN(value)) {
          // we have an object
          cycle = value;
          index = this.cycleList.indexOf(value);
        } else {
          // we have a number
          index = value;
          cycle = this.cycleList.objectAt(value);
        }

        Ember.set(this, 'selectedCycle', cycle);
        Ember.set(this, 'selectedCycleIndex', Number(index));
        localStorage.setItem('cycle', index || 0);

        if (!init) {
          // Select first step
          this.send('selectStep');
        }
      },

      selectStep(step) {
        if (!step) {
          step = Ember.get(this, 'stepList.firstObject');
        } else if (!isNaN(step)) {
          step = this.stepList.objectAt(Number(step));
        }

        let index = this.stepList.indexOf(step) || 0;
        Ember.set(this, 'selectedStep', step);
        (0, _jquery.default)(step).focus();
        localStorage.setItem('step', index || 0);
        Ember.set(this, 'selectedStepIndex', index); // used for Gold WX because last stage/step has different extract max time
      },

      copyCycle(cycle) {
        // We copy the stages array
        Ember.set(this, 'clipboard.type', 'cycle');
        Ember.set(this, 'clipboard.contents', cycle.stages.toArray());
        Ember.set(this, 'clipboard.title', 'Name will be here');
        let notifications = this.notifications;
        this.notifications.success('Cycle copied', {
          autoClear: true,
          clearDuration: 1000,
          cssClasses: 'quick-notification'
        });
      },

      copyStep(step) {
        Ember.set(this, 'clipboard.type', 'step');
        Ember.set(this, 'clipboard.contents', (0, _emberCopy.copy)(step)); // step is an object

        let notifications = this.notifications;
        this.notifications.success('Segment copied', {
          autoClear: true,
          clearDuration: 1000,
          cssClasses: 'quick-notification'
        });
      },

      pasteCycle(cycle) {
        let type = Ember.get(this, 'clipboard.type');

        if (type !== 'cycle') {
          alert('No cycle is currently in your clipboard.');
          return false;
        }

        let clipboard = Ember.get(this, 'clipboard.contents');
        let cycles = this.cycleList;
        let index = cycles.indexOf(cycle);
        cycle.stages = clipboard;
        this.send('calculateTime');
        this.send('selectCycle', index);
        let notifications = this.notifications;
        this.notifications.success('Cycle pasted', {
          autoClear: true,
          clearDuration: 1000,
          cssClasses: 'quick-notification'
        });
      },

      pasteStep(step) {
        // We're NOT going to paste the new step above the selected on...
        // We ARE going to replace the current step.
        let type = Ember.get(this, 'clipboard.type');

        if (type !== 'step') {
          alert('No step is currently in your clipboard.');
          return false;
        }

        let clipboard = (0, _emberCopy.copy)(Ember.get(this, 'clipboard.contents'));
        let steps = this.stepList;
        let index = steps.indexOf(step); // Copy the Label and ActiveState

        let label, activeState;

        if (step) {
          label = Ember.get(step, 'Label');
          activeState = Ember.get(step, 'ActiveState');
        } else {
          // Defaults
          label = 1;
          activeState = 8;
        }

        Ember.set(clipboard, 'Label', label);
        Ember.set(clipboard, 'ActiveState', activeState);
        steps.removeAt(index); // Added for replacing the step

        steps.insertAt(index, clipboard);
        this.send('calculateTime');
        this.send('selectStep', index);
        let notifications = this.notifications;
        this.notifications.success('Step pasted', {
          autoClear: true,
          clearDuration: 1000,
          cssClasses: 'quick-notification'
        });
      },

      deleteStep(step) {
        let stepList = this.stepList;

        if (stepList.length === 0) {
          return;
        }

        let description = Ember.get(name, 'Name') || 'Current step';

        if (confirm(`Delete selected ${description}?`)) {
          let index = stepList.indexOf(step);
          stepList.removeAt(index);
          let selected;
          let newIndex = stepList.length > index ? index : index - 1;

          if (newIndex >= 0) {
            selected = stepList.objectAt(newIndex);
            Ember.set(this, 'selectedStep', selected);
            Ember.set(this, 'selectedStepIndex', newIndex);
            (0, _jquery.default)(selected).focus();
          }
        }
      },

      calculateTime() {
        Ember.run.schedule('afterRender', () => {
          let cycles = Ember.get(this, 'model.settings.Cycles');
          let motorDriveType = this.motorDriveType;
          cycles.forEach(cycle => {
            let cycleSeconds = 0;
            let cycleWaterUsed = {
              total: 0,
              hot: 0
            };
            let firstFill = true; // Used to track first fill in this cycle

            cycle.stages.forEach(step => {
              // Add up the time for this stage. This is copied from Centurion codebase
              let seconds = 0;
              let stepWaterUsed = {
                total: 0,
                hot: 0
              };

              if (step.Name !== 0) {
                // Determine if this step has firstFill
                // if fill, add 1 minute
                if (step.FillStepOn) {
                  seconds += 60;
                  step._firstFill = firstFill;
                  let waterLevelGlobal = Ember.get(step, 'FillStepWaterLevelGlobal');
                  let waterLevelCustom = Ember.get(step, 'FillStepWaterLevelCustom');
                  let waterFillTemp = Ember.get(step, 'FillStepTemperatureGlobal'); //  cold=0, warm=1, hot=2

                  let stepWater = this.getWaterUsage(firstFill, waterLevelGlobal, waterLevelCustom, waterFillTemp);
                  stepWaterUsed.total += stepWater.total;
                  stepWaterUsed.hot += stepWater.hot;
                  firstFill = false;
                } // add supply if we add supply time


                if (Ember.get(step, 'SupplyStepOn') && Ember.get(step, 'addSupplyTime')) {
                  seconds += step.SupplyStepMinutes * 60;
                  seconds += step.SupplyStepSeconds;
                } // add agitate


                if (step.AgitateStepOn) {
                  seconds += step.AgitateStepMinutes * 60;
                } // v and f have longer cycles


                if (step.DrainStepOn) {
                  if (step.ExtractStepOn && motorDriveType == 'TYPE_0') {
                    seconds += 105; // add 1:45
                  } else if (step.ExtractStepOn && motorDriveType == 'TYPE_2') {
                    if (step.ExtractStepSpeed < 3) {
                      // medium speed = 3
                      seconds -= 60;
                    } else {
                      seconds += 70;
                    }
                  } else {
                    seconds += 60;
                  } // if extract ... yes, only added if drain step on, per Windows app code.


                  if (step.ExtractStepOn) {
                    seconds += step.ExtractStepMinutes * 60;
                    seconds += step.ExtractStepSeconds;
                  }
                }
              } // Minutes are returned, rounded up.


              let minutes = Math.ceil(seconds / 60);
              Ember.set(step, '_stepSeconds', seconds);
              Ember.set(step, '_stepMinutes', minutes);
              Ember.set(step, '_waterUsed', {
                total: stepWaterUsed.total.toFixed(2),
                hot: stepWaterUsed.hot.toFixed(2)
              });
              cycleSeconds += seconds;
              cycleWaterUsed.total += stepWaterUsed.total;
              cycleWaterUsed.hot += stepWaterUsed.hot;
            });

            if (Ember.get(this, 'model.machineAuditType.name') === 'CenturionC4_ALSDrive') {
              cycleSeconds += 32;
            } else {
              cycleSeconds += 20;
            }

            Ember.set(cycle, '_cycleSeconds', cycleSeconds);
            let minutes = Math.ceil(cycleSeconds / 60);
            Ember.set(cycle, '_cycleMinutes', minutes);
            Ember.set(cycle, '_waterUsed', {
              total: cycleWaterUsed.total.toFixed(2),
              hot: cycleWaterUsed.hot.toFixed(2)
            });
          });
        });
      }

    },
    // #endregion
    observerMotorDriveType: Ember.observer('settings._MotorDriveType', 'model.settings._convertToLiters', 'model.machineType', function () {
      this.send('calculateTime');
    }),
    motorDriveType: Ember.computed('model.{machineAuditType.name,machineType.id,settings._MotorDriveType}', function () {
      let machineAuditType = Ember.get(this, 'model.machineAuditType.name'); // CenturionC4_ALSDrive

      let machineType = Ember.get(this, 'model.machineType.id'); // 101

      let motorDrive = Ember.get(this, 'model.settings._MotorDriveType');

      if (machineType == 100) {
        // generic machine
        if (machineAuditType == 'CenturionC4_ALSDrive') {
          return 'TYPE_2';
        } else if (machineAuditType == 'CenturionC4') {
          return 'TYPE_0';
        } else {
          return 'TYPE_1';
        }
      } else {
        // specific size
        if (motorDrive == 'TWO_SPEED') {
          return 'TYPE_1';
        } else {
          return 'TYPE_0'; // default
        }
      }
    })
  });

  _exports.default = _default;
});