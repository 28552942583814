define("alliance-business-suite/templates/components/machines/m-type-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ed49anDW",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,3],null,[[\"machineName\",\"machineNumber\",\"displayMachineType\",\"isDryer\"],[[35,2],[35,1],true,[35,0]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"type\",\"number\",\"name\",\"machine-display-name\"]}",
    "moduleName": "alliance-business-suite/templates/components/machines/m-type-icon.hbs"
  });

  _exports.default = _default;
});