define("alliance-business-suite/components/guests/g-view", ["exports", "alliance-business-suite/mixins/card-brush-state"], function (_exports, _cardBrushState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let ComponentClass = Ember.Component.extend(_cardBrushState.default, {
    classNames: ['guest-view'],
    access: Ember.inject.service(),
    cards: Ember.computed(function () {
      let cards = ['transactions'];
      return cards;
    }),
    actions: {
      toggleGuestModal() {
        this.toggleProperty('showGuestModal');
      }

    }
  });
  ComponentClass.reopenClass({
    positionalParams: ['model']
  });
  var _default = ComponentClass;
  _exports.default = _default;
});