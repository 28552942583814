define("alliance-business-suite/utils/json-to-csv", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.jsonToCsv = _exports.downloadJsonToCsv = _exports.downloadCsv = void 0;

  const downloadAsDataLink = function (csv) {
    let filename = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "download.csv";
    // Create link and download
    var link = document.createElement('a');
    link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(csv));
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadAsBlobLink = function (csv) {
    let filename = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "download.csv";
    //Download the file as CSV
    var downloadLink = document.createElement("a");
    var blob = new Blob(["\ufeff", csv]);
    var url = URL.createObjectURL(blob);
    downloadLink.href = url;
    downloadLink.download = filename; //Name the file here

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const jsonToCsv = (json, keyArray) => {
    const replacer = (key, value) => value === null ? '' : value; // nulls get returned as empty strings


    const header = keyArray || Object.keys(json[0]); // use provided keys or extracts column names from the first object in JSON

    const escapeCsv = value => value.replace(/\\"/g, '""'); // property escape double quotes in CSV


    const csv = [header.join(','), // header row first
    ...json.map(row => header.map(fieldName => escapeCsv(JSON.stringify(row[fieldName], replacer))).join(','))].join('\r\n');
    return csv;
  };

  _exports.jsonToCsv = jsonToCsv;

  const downloadCsv = function (data) {
    let filename = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "download.csv";
    downloadAsBlobLink(data, filename);
  };

  _exports.downloadCsv = downloadCsv;

  const downloadJsonToCsv = function (json, keyArray) {
    let filename = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "download.csv";
    let csv = jsonToCsv(json, keyArray);
    downloadAsBlobLink(csv, filename);
  };

  _exports.downloadJsonToCsv = downloadJsonToCsv;
});