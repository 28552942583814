define("alliance-business-suite/components/employees/time-bar", ["exports", "jquery", "moment"], function (_exports, _jquery, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function convertToMoment(leftPos, width, roundingValue) {
    let pos = leftPos / width;
    let decimalTime = pos * 24; // Where we are in the day

    let momentObj = (0, _moment.default)().startOf('day').add(decimalTime, 'hours');

    if (roundingValue) {
      momentObj = roundMomentValue(decimalTime, roundingValue, 'round');
    }

    return momentObj;
  }

  function roundMomentValue(decimalTime, duration, method) {
    let minutes = _moment.default.duration(decimalTime, 'hours').asMinutes();

    let newMinutes = Math[method](minutes / duration) * duration;
    return (0, _moment.default)().startOf('day').add(newMinutes, 'minutes');
  }

  var _default = Ember.Component.extend({
    classNames: ['block-bar-grid', 'no-select'],
    roundTime: 30,
    brush: Ember.inject.service(),
    store: Ember.inject.service(),
    access: Ember.inject.service(),
    barType: Ember.computed('isError', 'hasDirtyAttributes', function () {
      if (Ember.get(this, 'isError')) {
        return 'bar-error';
      } else if (Ember.get(this, 'hasDirtyAttributes')) {
        return 'bar-dirty';
      } else {
        return 'bar-clean';
      }
    }),

    didInsertElement() {
      let self = this;
      let userId = Ember.get(this, 'access.accessUser.id');
      let user = Ember.get(this, 'store').peekRecord('employee', userId);
      let userTimeFormat = `${user.get('timeFormat')} A`;
      let startTimeValue = Ember.get(this, 'startTime');
      let endTimeValue = Ember.get(this, 'endTime');

      this._super(...arguments);

      if (user.get('timeFormat') === 'HH:mm') {
        userTimeFormat = `${user.get('timeFormat')}`;
      }

      this.setProperties({
        sTime: (0, _moment.default)(`${startTimeValue}`, "HH:mm:ss").format(`${userTimeFormat}`),
        eTime: (0, _moment.default)(`${endTimeValue}`, "HH:mm:ss").format(`${userTimeFormat}`)
      });
      let roundTime = Ember.get(this, 'roundTime');
      let containerWidth = (0, _jquery.default)(this.element).width();
      let gridSize = roundTime / 1440 * containerWidth;
      this.$('.bar').draggable({
        axis: 'x',
        containment: self.get('element'),

        stop(event, ui) {
          self.translateWidthToTime(ui, self.$().width(), true, true);
        }

      });
      this.$('.bar').resizable({
        handles: 'e, w',
        containment: self.get('element'),
        grid: [gridSize, null],

        // Looks like we can just update on resize. Might want to debouce/throttle this
        // stop(event, ui) {
        //   self.translateWidthToTime(ui, self.$().width(), true);
        // }
        resize(event, ui) {
          self.translateWidthToTime(ui, self.$().width(), true);
        }

      });

      this.resizeHandler = function (event) {
        let roundTime = Ember.get(self, 'roundTime');
        let containerWidth = (0, _jquery.default)(self.get('element')).width();
        let gridSize = roundTime / 1440 * containerWidth;
        self.$('.bar').resizable('option', 'grid', gridSize);
      };

      window.addEventListener('resize', this.resizeHandler);
    },

    willDestroyElement() {
      this._super(...arguments);

      window.removeEventListener('resize', this.resizeHandler);
    },

    observeTimes: Ember.observer('startTime', 'endTime', function () {
      let startTimeValue = Ember.get(this, 'startTime');
      let endTimeValue = Ember.get(this, 'endTime');
      let userId = Ember.get(this, 'access.accessUser.id');
      let user = Ember.get(this, 'store').peekRecord('employee', userId);
      let userTimeFormat = `${user.get('timeFormat')} A`;

      if (user.get('timeFormat') === 'HH:mm') {
        userTimeFormat = `${user.get('timeFormat')}`;
      }

      if (typeof startTimeValue !== 'string') {
        startTimeValue = (0, _moment.default)(`${startTimeValue.hours}:${startTimeValue.minutes}`, "HH:mm:ss").format(`${userTimeFormat}`);
      } else {
        startTimeValue = (0, _moment.default)(`${startTimeValue}`, "HH:mm:ss").format(`${userTimeFormat}`);
      }

      if (typeof endTimeValue !== 'string') {
        endTimeValue = (0, _moment.default)(`${endTimeValue.hours}:${endTimeValue.minutes}`, "HH:mm:ss").format(`${userTimeFormat}`);
      } else {
        endTimeValue = (0, _moment.default)(`${endTimeValue}`, "HH:mm:ss").format(`${userTimeFormat}`);
      } // If the startTime/endTime values change, update the sTime and eTime variables.


      this.setProperties({
        sTime: startTimeValue,
        eTime: endTimeValue
      });
    }),

    translateWidthToTime(ui, gridWidth, snapTime, isDraggable) {
      if (isDraggable) {
        ui.size = {
          width: this.$('.bar').width()
        };
      }

      let whichHandle = ui.originalPosition.left == ui.position.left ? 'right' : 'left';
      let leftPoint = ui.position.left;
      let rightPoint = ui.position.left + ui.size.width;
      let roundTo = snapTime ? Ember.get(this, 'roundTime') : null;
      let userId = Ember.get(this, 'access.accessUser.id');
      let user = Ember.get(this, 'store').peekRecord('employee', userId);
      let userTimeFormat = `${user.get('timeFormat')} A`;

      if (user.get('timeFormat') === 'HH:mm') {
        userTimeFormat = `${user.get('timeFormat')}`;
      }

      let newStartTime = convertToMoment(leftPoint, gridWidth, roundTo);
      let newEndTime = convertToMoment(rightPoint, gridWidth, roundTo); // Keep 60 minutes between handles

      let timeDiff = newEndTime.diff(newStartTime, 'minutes');

      if (timeDiff < 60) {
        let offset = 60 - timeDiff;

        if (whichHandle == 'left') {
          newStartTime.subtract(offset, 'minutes');
        } else {
          newEndTime.add(offset, 'minutes');
        }
      }

      if (newEndTime.format('HH:mm') == '00:00') {
        newEndTime.subtract(1, 'minute');
      }

      if (isDraggable) {
        this.updateTime({
          newStartTime: newStartTime.format('HH:mm'),
          newEndTime: newEndTime.format('HH:mm')
        });
      } else {
        if (whichHandle == 'left') {
          this.updateTime({
            newStartTime: newStartTime.format('HH:mm')
          });
        } else {
          this.updateTime({
            newEndTime: newEndTime.format('HH:mm')
          });
        }
      } // Set the labels on the slider and update the bar width


      Ember.set(this, 'sTime', newStartTime.format(`${userTimeFormat}`));
      Ember.set(this, 'eTime', newEndTime.format(`${userTimeFormat}`));
      this.notifyPropertyChange('barWidth');
    },

    barWidth: Ember.computed('startTime', 'endTime', 'barUpdateCounter', function () {
      let start = this.startTime;
      let end = this.endTime;
      let percentage = this.calcBarWidth(start, end);
      let marginLeft = this.calcBarOffset(start);
      return Ember.String.htmlSafe(`width: ${percentage}%; left: ${marginLeft}`);
    }),

    calcBarWidth(start, end) {
      let startSpan = _moment.default.duration(start).asHours();

      let endSpan = _moment.default.duration(end).asHours();

      let total = endSpan - startSpan;
      return total / 24 * 100;
    },

    calcBarOffset(start) {
      if (start) {
        let startSpan = _moment.default.duration(start).asHours();

        let value = startSpan / 24 * 100;
        return `${value}%`;
      }
    }

  });

  _exports.default = _default;
});