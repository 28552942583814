define("alliance-business-suite/mixins/machines/programs/cycles/step-global", ["exports", "alliance-business-suite/utils/cycles-values"], function (_exports, _cyclesValues) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
    Required arguments:
      - update
      - selectedStep
      - machineProgram
      - updateTime
  */
  var _default = Ember.Mixin.create({
    types: _cyclesValues.STEP_TYPES_ENUM,
    updateTimeObserver: Ember.observer('selectedStep.{Minutes,Seconds,ActiveState,Label}', function () {
      Ember.run.once(this, 'updateTime');
    }),
    temperatureScale: Ember.computed('isCelsius', 'machineProgram', function () {
      if (this.machineProgram) {
        return Ember.get(this, 'machineProgram._isCelsius');
      } else {
        return Ember.get(this, 'isCelsius') ? 'C' : 'F'; // TODO: This needs to go away
      }
    }),
    modelObserver: Ember.observer('model', function () {
      Ember.set(this, 'proxy', {});
      Ember.set(this, 'preset', {});
    }),
    cooldownWaterValvesTemperature: Ember.computed('HotFillValveOn', 'ColdFillValveOn', {
      // Cooldown uses 'Valve' in the property name, while Suppy does not.
      get() {
        let hotFill = Ember.get(this, 'HotFillValveOn');
        let coldFill = Ember.get(this, 'ColdFillValveOn');

        if (hotFill && coldFill) {
          return 1; // Warm
        } else if (hotFill) {
          return 0; // Hot
        } else if (coldFill) {
          return 2; // Cold
        } else {
          return null;
        }
      },

      set(key, value) {
        switch (value) {
          case 0:
            Ember.set(this, 'HotFillValveOn', true);
            Ember.set(this, 'ColdFillValveOn', false);
            break;

          case 1:
            Ember.set(this, 'HotFillValveOn', true);
            Ember.set(this, 'ColdFillValveOn', true);
            break;

          case 2:
            Ember.set(this, 'HotFillValveOn', false);
            Ember.set(this, 'ColdFillValveOn', true);
            break;
        }

        return value;
      }

    }),
    waterValvesTemperature: Ember.computed('selectedStep.{HotFillOn,ColdFillOn}', {
      get() {
        let hotFill = Ember.get(this, 'HotFillOn');
        let coldFill = Ember.get(this, 'ColdFillOn');

        if (hotFill && coldFill) {
          return 1; // Warm
        } else if (hotFill) {
          return 0; // Hot
        } else if (coldFill) {
          return 2; // Cold
        } else {
          return 2; // If both are false, we will set to cold
        }
      },

      set(key, value) {
        switch (value) {
          case 0:
            // Hot
            Ember.set(this, 'HotFillOn', true);
            Ember.set(this, 'ColdFillOn', false);
            break;

          case 1:
            // Warm
            Ember.set(this, 'HotFillOn', true);
            Ember.set(this, 'ColdFillOn', true);
            break;

          case 2:
            // Cold
            Ember.set(this, 'HotFillOn', false);
            Ember.set(this, 'ColdFillOn', true);
            break;
        }

        return value;
      }

    }),
    waterValvesTemperatureWithControlled: Ember.computed('model', 'HotFillOn', 'ColdFillOn', 'TemperatureControlledFill', {
      get() {
        // First check controlled fill
        if (Ember.get(this, 'TemperatureControlledFill') === true) {
          return 3;
        }

        let hotFill = Ember.get(this, 'HotFillOn');
        let coldFill = Ember.get(this, 'ColdFillOn');

        if (hotFill && coldFill) {
          return 1; // Warm
        } else if (hotFill) {
          return 0; // Hot
        } else if (coldFill) {
          return 2; // Cold
        }
      },

      set(key, value) {
        switch (value) {
          case 0:
            Ember.set(this, 'HotFillOn', true);
            Ember.set(this, 'ColdFillOn', false);
            Ember.set(this, 'TemperatureControlledFill', false);
            break;

          case 1:
            Ember.set(this, 'HotFillOn', true);
            Ember.set(this, 'ColdFillOn', true);
            Ember.set(this, 'TemperatureControlledFill', false);
            break;

          case 2:
            Ember.set(this, 'HotFillOn', false);
            Ember.set(this, 'ColdFillOn', true);
            Ember.set(this, 'TemperatureControlledFill', false);
            break;

          case 3:
            Ember.set(this, 'HotFillOn', false);
            Ember.set(this, 'ColdFillOn', false);
            Ember.set(this, 'TemperatureControlledFill', true);
            break;
        }

        return value;
      }

    }),

    init() {
      this._super(...arguments);

      Ember.set(this, 'preset', {});
    },

    actions: {
      // TODO: Remove this and the button in the template
      showModel() {
        console.log('cycleSettings', this.cycleSettings); // eslint-disable-line no-console

        console.log('model', this.model); // eslint-disable-line no-console
      },

      setProperties(props, value) {
        // Sometimes we need to update several properties with the same value.
        // Keep in mind that our model is limited to the current step.
        props.forEach(prop => {
          this.set(prop, value);
        });
      },

      oneDrainRequired(property, value) {
        /*
          Both drains cannot be disabled. If the user unchecks both drains, then
          alert them and re-check the last one they turned off.
        */
        let drain1 = Ember.get(this, 'selectedStep.MainDrain1On');
        let drain2 = Ember.get(this, 'selectedStep.MainDrain2On');

        if (!drain1 && !drain2) {
          alert('At least one drain must be enabled.');
          Ember.set(this, property, true);
        }
      },

      updateMultipleProperties(params, value) {
        // value.values is an array of values to be saved
        // params.props is an array of property names for storing the above value.values
        let propertyArray = params.props;
        let valueArray = value.values;
        propertyArray.forEach((propertyName, index) => {
          let thisValue = valueArray[index];
          Ember.set(this, propertyName, thisValue);
        });
      },

      initCustomSpeed(property, selection) {
        // If this is a preset, we need to update the CustomSpeed control
        if (!selection.isCustom) {
          Ember.set(this, `selectedStep.${property}`, selection.g * 100);
        }
      },

      initCustom(customPropertyName, value) {
        if (value.isCustom) {
          Ember.set(this, `preset.${customPropertyName}`, null);
        } else {
          Ember.set(this, `preset.${customPropertyName}`, value.presetValue);
        }
      },

      updateSelectField(hash, value) {
        // Every time a select is updated, we'll check for an isCustom and do the
        // property update and the custom update together
        let currentProperty = hash.propertyName;
        let customProperty = hash.customPropertyName; // Set the current property

        Ember.set(this, `selectedStep.${hash.propertyName}`, value.val); // Deal with presets and custom

        if (!value.isCustom) {
          // We haven't selected custom, so the custom property matches the non-custom, and we update the customValue.
          Ember.set(this, `selectedStep.${hash.customPropertyName}`, value.val);
          Ember.set(this, `preset.${hash.customPropertyName}`, value.presetValue);
        } else {
          // Set the custom property to the last preset value, then set preset to null
          Ember.set(this, `selectedStep.${hash.customPropertyName}`, Ember.get(this, `preset.${hash.customPropertyName}`));
          Ember.set(this, `preset.${hash.customPropertyName}`, null);
        }
      },

      updateCustom(property, selection) {
        // Now only used for customSpeed
        // Second parameter can be blank
        if (!selection) {
          selection = status;
        }

        if (property === 'CustomSpeed' && !selection.isCustom) {
          Ember.set(this, `selectedStep.${property}`, selection.g * 100);
          return;
        }
      }

    }
  });

  _exports.default = _default;
});