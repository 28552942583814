define("alliance-business-suite/templates/components/table/table-head-tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gH+KWmn3",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"span\"],[12],[2,\"\\n  eddie \"],[18,1,null],[2,\"\\n\\n  \"],[8,\"ember-tooltip\",[],[[\"@delay\"],[150]],[[\"default\"],[{\"statements\":[[2,\"\\n    Services of \\\"Order Settings\\\" are applied to the whole order. Weight of the order is not taken into account.\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "alliance-business-suite/templates/components/table/table-head-tooltip.hbs"
  });

  _exports.default = _default;
});