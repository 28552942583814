define("alliance-business-suite/components/products/services-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    access: Ember.inject.service(),
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    classNames: ['services-section'],
    isLoading: false,
    rooms: Ember.computed(function () {
      let allRooms = this.store.peekAll('room').sortBy('formattedBreadcrumbDisplay');
      return allRooms.filter(room => {
        if (room.get('isRetail')) {
          return true;
        }
      });
    }),
    selectedRoom: Ember.computed('rooms', function () {
      if (this.rooms.length === 1) {
        this.send('changeRoom', this.rooms.firstObject);
        return this.rooms.firstObject;
      }
    }),

    didInsertElement() {
      this._super(...arguments); // this.notifyPropertyChange('orderSettingsFiltered');


      this.set('isInitialLoad', false);
    },

    // isInitialLoad: computed('_lineItemTypes.[]', '_orderSettings.[]', function() {
    //   let _lineItemTypes = this.get('_lineItemTypes') || [];
    //   let _orderSettings = this.get('_orderSettings') || [];
    //
    //   console.log('_lineItemTypes', _lineItemTypes);
    //
    //   if ((_lineItemTypes.get('length') <= 0) && (_orderSettings.get('length') <= 0)) {
    //     console.log('true??')
    //     return true;
    //   }
    //
    //   this.set('isLoading', false);
    //
    //   return false;
    // }),
    _lineItemTypes: Ember.computed(function () {
      let selectedRoom = this.selectedRoom;

      if (selectedRoom) {
        return this.store.peekAll('line-item-type').filterBy('room.id', selectedRoom.get('id'));
      }
    }),
    _orderSettings: Ember.computed(function () {
      let selectedRoom = this.selectedRoom;

      if (selectedRoom) {
        return this.store.peekAll('order-setting').filterBy('room.id', selectedRoom.get('id'));
      }
    }),
    orderSettingsFiltered: Ember.computed('_orderSettings.[]', 'selectedRoom', '_lineItemTypes.[]', function () {
      let orderSettings = this._orderSettings; // Need to leave this to initialize the computed property above

      let lineItemTypes = this._lineItemTypes; // Need to leave this to initialize the computed property above

      let result = [];

      if (orderSettings) {
        orderSettings.forEach(o => {
          result.push(o);
        });
      }

      if (lineItemTypes) {
        lineItemTypes.forEach(l => {
          result.push(l);
        });
      } // if (this.get('selectedRoom')) {
      //   this.set('isLoading', false);
      // }


      return result;
    }),

    async fetchOrderSettings(room) {
      let self = this;
      await this.store.findAll('order-setting', {
        reload: true,
        adapterOptions: {
          roomId: room.get('id')
        }
      }).then(orderSettings => {// self.set('selectedRoom', room);
        // self.notifyPropertyChange('_orderSettings');
        // console.log('fetchOrderSettings: done!');
      }, function () {// self.set('selectedRoom', room);
        // self.notifyPropertyChange('_orderSettings');
        // console.log('fetchOrderSettings: failed')
      }); // self.set('selectedRoom', room);

      self.notifyPropertyChange('_orderSettings'); // this.set('isLoading', false);
    },

    async fetchLineItemTypes(room) {
      let self = this;
      await this.store.findAll('line-item-type', {
        reload: true,
        adapterOptions: {
          roomId: room.get('id')
        }
      }).then(lineItemTypes => {// self.set('selectedRoom', room);
        // self.notifyPropertyChange('_lineItemTypes');
      }, function () {// self.set('selectedRoom', room);
        // self.notifyPropertyChange('_lineItemTypes');
      }); // self.set('selectedRoom', room);

      self.notifyPropertyChange('_lineItemTypes'); // this.set('isLoading', false);
    },

    actions: {
      async refresh() {
        let room = this.selectedRoom;
        this.set('isLoading', true);
        await this.fetchOrderSettings(room);
        await this.fetchLineItemTypes(room);
        this.set('isLoading', false);
      },

      toggleCreateModal() {
        this.toggleProperty('viewCreateModal');
      },

      toggleEditModal(service) {
        if (service) {
          this.set('selectedService', service);
        }

        this.toggleProperty('viewEditModal');
      },

      async changeRoom(room) {
        let self = this;
        this.set('isLoading', true);
        await this.fetchOrderSettings(room);
        await this.fetchLineItemTypes(room);

        if (!this.isDestroyed) {
          this.set('selectedRoom', room);
        }

        this.set('isLoading', false);
      }

    }
  });

  _exports.default = _default;
});