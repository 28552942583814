define("alliance-business-suite/components/validation/summary", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{!-- Validation Summary --}}
    {{#if @changeset.errors}}
      <div class="validation-summary">
  
      <h1>{{t 'problems_found'}}:</h1>
      <ul>
        {{#each @changeset.errors as |errorSet|}}
  
          {{#if errorSet.validation.message}}
            {{!-- Single validation --}}
            <li>{{t errorSet.validation.message description=(t errorSet.validation.context.label)}}</li>
          {{else}}
            {{!-- Multiple validations --}}
            {{#each errorSet.validation as |validation|}}
              <li>{{t validation.message description=(t validation.context.label)}}</li>
            {{/each}}
          {{/if}}
  
        {{/each}}
      </ul>
      </div>
    {{/if}}
  */
  {
    "id": "1A0LEcv0",
    "block": "{\"symbols\":[\"errorSet\",\"validation\",\"@changeset\"],\"statements\":[[6,[37,3],[[32,3,[\"errors\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"validation-summary\"],[12],[2,\"\\n\\n    \"],[10,\"h1\"],[12],[1,[30,[36,0],[\"problems_found\"],null]],[2,\":\"],[13],[2,\"\\n    \"],[10,\"ul\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,3,[\"errors\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],[[32,1,[\"validation\",\"message\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"          \"],[10,\"li\"],[12],[1,[30,[36,0],[[32,1,[\"validation\",\"message\"]]],[[\"description\"],[[30,[36,0],[[32,1,[\"validation\",\"context\",\"label\"]]],null]]]]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,1,[\"validation\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"li\"],[12],[1,[30,[36,0],[[32,2,[\"message\"]]],[[\"description\"],[[30,[36,0],[[32,2,[\"context\",\"label\"]]],null]]]]],[13],[2,\"\\n\"]],\"parameters\":[2]}]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"-track-array\",\"each\",\"if\"]}",
    "moduleName": "alliance-business-suite/components/validation/summary.hbs"
  });

  class ValidationSummaryComponent extends _component.default {}

  _exports.default = ValidationSummaryComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ValidationSummaryComponent);
});