define("alliance-business-suite/components/form-ui/time-plus-minus", ["exports", "alliance-business-suite/mixins/form-ui", "alliance-business-suite/mixins/init-property-names"], function (_exports, _formUi, _initPropertyNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_initPropertyNames.default, _formUi.default, {
    tagName: 'div',
    intl: Ember.inject.service(),

    didReceiveAttrs() {
      this._super(...arguments);

      this.plusMinusValue = this.minus > 0 ? this.minus * -1 : this.plus;
    },

    isUndefinedProperty: Ember.computed('minus', 'plus', function () {
      if (Ember.isNone(this.minus) && Ember.isNone(this.plus)) {
        console.error('Reference to minus/plus properties are undefined.'); // eslint-disable-line no-console

        return true;
      } else {
        return false;
      }
    }),
    actions: {
      updateValue(obj, propertyName, value) {
        let val = Number(value) || 0;

        if (val >= 0) {
          if (val > this.params.max) {
            val = this.params.max;
          }

          this.update(this.object, this.properties.minus.key, 0);
          this.update(this.object, this.properties.plus.key, val);
        } else {
          if (val < this.params.min) {
            val = this.params.min;
          }

          this.update(this.object, this.properties.minus.key, Math.abs(val));
          this.update(this.object, this.properties.plus.key, 0);
        }

        Ember.set(this, "plusMinusValue", val);
      }

    }
  });

  _exports.default = _default;
});