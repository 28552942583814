define("alliance-business-suite/templates/components/support/support-error-codes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "m9WhZE1I",
    "block": "{\"symbols\":[\"section\",\"article\",\"errorCode\"],\"statements\":[[6,[37,1],[[30,[36,0],[[30,[36,0],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"table\"],[14,\"cellspacing\",\"0\"],[12],[2,\"\\n      \"],[10,\"tbody\"],[12],[2,\"\\n        \"],[10,\"tr\"],[12],[2,\"\\n          \"],[10,\"td\"],[14,\"colspan\",\"3\"],[12],[2,\"\\n            \"],[1,[32,2,[\"title\"]]],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,2,[\"errorCodes\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"tr\"],[12],[2,\"\\n            \"],[10,\"td\"],[12],[2,\"\\n              \"],[1,[32,3,[\"display\"]]],[2,\"\\n            \"],[13],[2,\"\\n            \"],[10,\"td\"],[12],[2,\"\\n              \"],[1,[32,3,[\"description\"]]],[2,\"\\n            \"],[13],[2,\"\\n            \"],[10,\"td\"],[12],[2,\"\\n              \"],[1,[32,3,[\"cause\"]]],[2,\"\\n            \"],[13],[2,\"\\n          \"],[13],[2,\"\\n\"]],\"parameters\":[3]}]]],[2,\"      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[2]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\",\"data\"]}",
    "moduleName": "alliance-business-suite/templates/components/support/support-error-codes.hbs"
  });

  _exports.default = _default;
});