define("alliance-business-suite/templates/components/form-ui/dev-tools-step-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nQhglFEq",
    "block": "{\"symbols\":[\"value\",\"name\"],\"statements\":[[6,[37,3],[[32,0,[\"showTools\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"ul\"],[14,0,\"dev-tools-step-data\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[32,0,[\"object\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"li\"],[12],[1,[32,2]],[2,\" = \"],[10,\"span\"],[15,0,[31,[[30,[36,0],[[32,1]],null]]]],[12],[1,[32,1]],[13],[13],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"  \"],[13],[2,\"\\n\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"data-type\",\"-each-in\",\"each\",\"if\"]}",
    "moduleName": "alliance-business-suite/templates/components/form-ui/dev-tools-step-data.hbs"
  });

  _exports.default = _default;
});