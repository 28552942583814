define("alliance-business-suite/utils/computed-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = search;

  function search(dependentKey, propertyKey, searchQueryKey, returnEmptyArray) {
    returnEmptyArray = typeof returnEmptyArray === 'undefined' ? false : returnEmptyArray;
    let propertyKeyObserver;
    let propertyKeyArray = false;

    if (Array.isArray(propertyKey)) {
      propertyKeyObserver = `{${propertyKey.join(',')}}`;
      propertyKeyArray = true;
    } else {
      propertyKeyObserver = propertyKey;
    }

    return Ember.computed(`${dependentKey}.@each.${propertyKeyObserver}`, searchQueryKey, function () {
      let items, query;

      if (returnEmptyArray && !this.get(searchQueryKey)) {
        return Ember.A([]);
      }

      query = this.get(searchQueryKey) || '';
      query = query.toLowerCase();
      items = this.get(dependentKey) || Ember.A([]);
      return items.filter(function (item) {
        let source = null;

        if (propertyKeyArray) {
          source = propertyKey.reduce(function (prev, curr) {
            return `${prev} ${Ember.get(item, curr)}`;
          }, '').substr(1);
        } else {
          source = Ember.get(item, propertyKey);
        }

        if (source) {
          return source.toLowerCase().indexOf(query) !== -1;
        }
      });
    });
  }
});