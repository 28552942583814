define("alliance-business-suite/validators/employee-validators", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    email: (0, _validators.validateFormat)({
      type: 'email',
      label: 'email'
    }),
    firstName: (0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true,
      label: 'first_name'
    }),
    lastName: (0, _validators.validatePresence)({
      presence: true,
      ignoreBlank: true,
      label: 'last_name'
    })
  };
  _exports.default = _default;
});