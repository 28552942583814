define("alliance-business-suite/templates/components/page-ui/card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5PpmCw/2",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,4],null,[[\"header\",\"contents\",\"group\"],[[30,[36,2],[\"ui/section-panel-header\"],[[\"type\"],[[35,3]]]],[30,[36,2],[\"ui/section-panel-element\"],[[\"componentClassName\"],[[30,[36,1],[[35,0],\"__contents\"],null]]]],[30,[36,2],[\"ui/section-panel-element\"],[[\"componentClassName\"],[[30,[36,1],[[35,0],\"__group\"],null]]]]]]]]]],\"hasEval\":false,\"upvars\":[\"componentClassName\",\"concat\",\"component\",\"type\",\"hash\"]}",
    "moduleName": "alliance-business-suite/templates/components/page-ui/card.hbs"
  });

  _exports.default = _default;
});