define("alliance-business-suite/components/machines/programs/display/primus-mc10-wx", ["exports", "alliance-business-suite/mixins/machines/programs/mc10-options"], function (_exports, _mc10Options) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_mc10Options.default, {
    // eslint-disable-line ember/no-classic-classes
    tagName: '',
    // Need to observe any properties used for enabled, etc.
    formConfig: Ember.computed(function () {
      return {
        columns: [{
          sections: [{
            title: "display_settings",
            controls: [{
              type: "select",
              propertyName: "Program.Misc.MiscConfig.CelsiusEnabled",
              label: "CelsiusEnabled",
              options: "TemperatureUnits"
            }, {
              type: "number",
              propertyName: "Program.Display.Backlight.Active",
              label: "BacklightActive",
              min: 1,
              max: 255
            }, {
              type: "number",
              propertyName: "Program.Display.Backlight.InactiveCriticalMode",
              label: "BacklightInactiveCriticalMode",
              min: 1,
              max: 255
            }, {
              type: "number",
              propertyName: "Program.Display.Backlight.InactiveNonCriticalMode",
              label: "BacklightInactiveNonCriticalMode",
              min: 1,
              max: 255
            }, {
              type: "checkbox",
              propertyName: "Program.Display.Backlight.ScreenSaverEnabled",
              label: "ScreenSaverEnabled",
              inverted: true,
              ifMachine: "MC10_COMBO"
            }, {
              type: "checkbox",
              propertyName: "Program.Display.DisplayApproachScreen",
              notMachine: "MC10_COMBO"
            }, {
              type: "checkbox",
              propertyName: "Program.Display.DisplayTimeSinceCyleComplete"
            }, {
              type: "checkbox",
              propertyName: "Program.Display.HideTimeOnRunPage",
              notMachine: "MC10_COMBO"
            }, {
              type: "checkbox",
              propertyName: "Program.Display.HideTimeOnPresetUpgradePay",
              notMachine: "MC10_COMBO"
            }, {
              type: "checkbox",
              propertyName: "Program.Display.HideTimeOnUpgrade",
              notMachine: "MC10_COMBO"
            }, {
              type: "checkbox",
              propertyName: "Program.Display.DisplayProgrammableCycleTime",
              notMachine: "MC10_COMBO"
            }, {
              type: "checkbox",
              propertyName: "Program.Display.DisplayHeatFlameIndicator",
              label: "DisplayHeatFlameIndicator",
              ifMachine: "MC10_COMBO"
            }]
          }]
        }, {
          sections: [{
            title: "displayed_languages",
            controls: [{
              type: "user-languages",
              languages: ["Program.Display.EnabledLanguages.Bulgarian", "Program.Display.EnabledLanguages.ChineseSimplified", "Program.Display.EnabledLanguages.ChineseTraditional", "Program.Display.EnabledLanguages.Croatian", "Program.Display.EnabledLanguages.Czech", "Program.Display.EnabledLanguages.Danish", "Program.Display.EnabledLanguages.Dutch", "Program.Display.EnabledLanguages.English", "Program.Display.EnabledLanguages.Finnish", "Program.Display.EnabledLanguages.French", "Program.Display.EnabledLanguages.German", "Program.Display.EnabledLanguages.Greek", "Program.Display.EnabledLanguages.Hungarian", "Program.Display.EnabledLanguages.Italian", "Program.Display.EnabledLanguages.Japanese", "Program.Display.EnabledLanguages.Korean", "Program.Display.EnabledLanguages.Latvian", "Program.Display.EnabledLanguages.Lithuanian", "Program.Display.EnabledLanguages.Norwegian", "Program.Display.EnabledLanguages.Polish", "Program.Display.EnabledLanguages.PortugueseBrazilian", "Program.Display.EnabledLanguages.PortugueseEuropean", "Program.Display.EnabledLanguages.Romanian", "Program.Display.EnabledLanguages.Russian", "Program.Display.EnabledLanguages.Slovak", "Program.Display.EnabledLanguages.Slovene", "Program.Display.EnabledLanguages.SpanishColumbian", "Program.Display.EnabledLanguages.SpanishEuropean", "Program.Display.EnabledLanguages.Swedish", "Program.Display.EnabledLanguages.Turkish", "Program.Display.AdditionalEnabledLanguages.Indonesian", "Program.Display.AdditionalEnabledLanguages.Malay", "Program.Display.AdditionalEnabledLanguages.Thai", "Program.Display.AdditionalEnabledLanguages.Vietnamese"]
            }]
          }]
        }]
      };
    }),
    actions: {
      // eslint-disable-line ember/no-actions-hash
      save() {
        alert('Save this form');
      }

    }
  });

  _exports.default = _default;
});