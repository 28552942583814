define("alliance-business-suite/helpers/show-step-feature", ["exports", "alliance-business-suite/utils/show-features"], function (_exports, _showFeatures) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  '@ember/service';

  var _default = Ember.Helper.extend({
    compute(params, hash) {
      return (0, _showFeatures.showStepFeature)({
        ifStep: hash.ifStep,
        notStep: hash.notStep,
        stepType: hash.stepTypeName
      });
    }

  });

  _exports.default = _default;
});