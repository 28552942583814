define("alliance-business-suite/adapters/tax-rate", ["exports", "alliance-business-suite/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    session: Ember.inject.service(),
    sessionPath: 'organizations/:organizationId/',

    buildURL(modelName, id, snapshot, requestType, query) {
      let url = this._super('', null, snapshot, requestType, query);

      let organizationId = this.get('session.data.orgId');

      switch (requestType) {
        case 'findAll':
          url += `/organizations/${organizationId}/taxRates`;
          return url;

        case 'createRecord':
          url += `/organizations/${organizationId}/taxRates`;
          return url;

        case 'updateRecord':
          url += `/organizations/${organizationId}/taxRates/${id}`;
          return url;

        case 'deleteRecord':
          url += `/organizations/${organizationId}/taxRates/${id}`;
          return url;
      }

      return url;
    }

  });

  _exports.default = _default;
});