define("alliance-business-suite/templates/components/table/table-cell-subtitle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "A7SH2JDT",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,2],[[35,1],[35,0,[\"propertyName\"]]],null]],[2,\"\\n\"],[10,\"div\"],[14,0,\"subtitle\"],[12],[1,[30,[36,2],[[35,1],[35,0,[\"subtitle\"]]],null]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"column\",\"record\",\"get\"]}",
    "moduleName": "alliance-business-suite/templates/components/table/table-cell-subtitle.hbs"
  });

  _exports.default = _default;
});