define("alliance-business-suite/templates/components/ui/inline-banner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QFVsoP6k",
    "block": "{\"symbols\":[\"@cssClass\",\"&default\",\"@text\",\"@title\",\"@img\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"inline-notice \",[32,1]]]],[12],[2,\"\\n\"],[6,[37,0],[[32,5]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"img\"],[15,\"src\",[32,5]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"notice-text\"],[12],[2,\"\\n\"],[6,[37,0],[[32,4]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"h2\"],[12],[1,[32,4]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,0],[[32,3]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"p\"],[12],[1,[32,3]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,0],[[27,[32,2]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[31,[\"inline-notice \",[32,1],\" no-margin-top\"]]],[12],[2,\"\\n    \"],[18,2,null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "alliance-business-suite/templates/components/ui/inline-banner.hbs"
  });

  _exports.default = _default;
});