define("alliance-business-suite/components/ui/modal-funds-root-edit", ["exports", "alliance-business-suite/mixins/currency-format"], function (_exports, _currencyFormat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_currencyFormat.default, {
    ajax: Ember.inject.service(),
    access: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    intl: Ember.inject.service(),
    isLoading: true,
    wasAutoReloadPreviouslyDisabled: false,

    didInsertElement() {
      this._super(...arguments);

      this.getAmounts();
      this.getGeoAmounts();
    },

    editDisabled: Ember.computed('model', function () {
      let canUpdate = this['can-access'].canAccess('LOCATIONS_EDIT_REPLENISHMENT');

      if (!canUpdate) {
        return true;
      }
    }).readOnly(),
    currencySubunit: Ember.computed(function () {
      return this.get('access.currencySubunit');
    }),
    currencySymbol: Ember.computed(function () {
      return this.get('access.currencyDenomination');
    }),
    hasLoadedInit: Ember.observer('selectedTrigger.[]', 'selectedAmountToBuy.[]', function () {
      let selectedAmountToBuy = Ember.get(this, 'selectedAmountToBuy');
      let selectedTrigger = Ember.get(this, 'selectedTrigger');

      if (selectedAmountToBuy && selectedTrigger) {
        this.set('isLoading', false);
      }
    }),
    isAutoReloadDisabled: Ember.computed('selectedTrigger', function () {
      let selectedTrigger = Ember.get(this, 'selectedTrigger');

      if (selectedTrigger && selectedTrigger.value == 0) {
        Ember.set(this, 'selectedAmountToBuy', {
          value: 0,
          formattedValue: Ember.get(this, 'intl').t('disabled')
        });
        Ember.set(this, 'wasAutoReloadPreviouslyDisabled', true);
        return true;
      }
    }),
    // if wasAutoReloadPreviouslyDisabled and it is changed to a value other than zero
    // then we need to change the value of the amount to something other than zero as well
    afterDisablingSelectAmount: Ember.observer('selectedTrigger', function () {
      let selectedTrigger = Ember.get(this, 'selectedTrigger');
      let wasAutoReloadPreviouslyDisabled = Ember.get(this, 'wasAutoReloadPreviouslyDisabled');
      let replenishmentDefaultValueAmounts = Ember.get(this, 'replenishmentDefaultValueAmounts');

      if (wasAutoReloadPreviouslyDisabled && selectedTrigger.value != 0) {
        Ember.set(this, 'wasAutoReloadPreviouslyDisabled', false);
        Ember.set(this, 'selectedAmountToBuy', replenishmentDefaultValueAmounts[1]);
      }
    }),

    getGeoAmounts() {
      let selectedFundsRoot = Ember.get(this, 'selectedFundsRoot');
      let self = this;
      this.ajax.request(`/geoBoundaries/${selectedFundsRoot.original.geoBoundaryId}/replenishmentDefaults?raw=true`, {
        method: 'GET'
      }).then(response => {
        if (response) {
          let triggerObj = {};
          triggerObj.value = response.replenishmentDefaultMinimumValueAmount;

          if (!response.replenishmentDefaultMinimumValueAmount) {
            Ember.set(this, 'selectedTrigger', {
              formattedValue: this.intl.t('disable_auto_reload'),
              value: this.get('access.currencyFormatStringWithoutThousands')
            });
            Ember.set(this, 'selectedAmountToBuy', {
              formattedValue: `${this.get('access.currencyDenomination')}${this.get('access.currencyFormatStringWithoutThousands')}`,
              value: this.get('access.currencyFormatStringWithoutThousands')
            });
            Ember.set(self, 'wasAutoReloadPreviouslyDisabled', true);
            return;
          }

          triggerObj.formattedValue = `${self.formatCurrencyString(response.replenishmentDefaultMinimumValueAmount)}`;

          if (triggerObj.value == 0) {
            triggerObj.formattedValue = this.intl.t('disable_auto_reload');
            Ember.set(self, 'wasAutoReloadPreviouslyDisabled', true);
          }

          let amountToBuyObj = {};
          amountToBuyObj.value = response.replenishmentDefaultValueAmount;
          amountToBuyObj.formattedValue = `${self.formatCurrencyString(response.replenishmentDefaultValueAmount)}`;
          Ember.set(this, 'selectedTrigger', triggerObj);
          Ember.set(this, 'selectedAmountToBuy', amountToBuyObj);
        }
      });
    },

    getAmounts() {
      let self = this;

      function sortByValue(a, b) {
        if (a.value < b.value) {
          return -1;
        }

        if (a.value > b.value) {
          return 1;
        }

        return 0;
      } // the default amount that will trigger an auto reload


      this.ajax.request('/replenishmentDefaultValueAmounts?raw=true', {
        method: 'GET'
      }).then(response => {
        let data = [];
        response.forEach(function (val) {
          let obj = {};
          obj.value = parseInt(val.value);
          obj.formattedValue = `${self.formatCurrencyString(val.value / 1)}`;
          data.push(obj);
        });
        Ember.set(this, 'replenishmentDefaultValueAmounts', data.sort(sortByValue)); // the default amount that will be purchased by a user on the minimum trigger

        this.ajax.request('/replenishmentDefaultMinimumValueAmounts?raw=true', {
          method: 'GET'
        }).then(response2 => {
          let data = [];
          response2.forEach(function (val) {
            let obj = {};
            obj.value = parseInt(val.value);
            obj.formattedValue = `${self.formatCurrencyString(val.value / 1)}`;

            if (obj.value == 0) {
              Ember.set(self, 'wasAutoReloadPreviouslyDisabled', true);
              obj.formattedValue = self.get('intl').t('disable_auto_reload');
            }

            data.push(obj);
          });
          Ember.set(this, 'replenishmentDefaultMinimumValueAmounts', data.sort(sortByValue));
        });
      });
    },

    actions: {
      close() {
        if (!Ember.get(this, 'isLoading')) {
          this.close();
        }
      },

      saveAutoReplenishment() {
        if (this.access.isDemo) {
          alert('Demo mode restricted.');
          return;
        }

        let self = this;
        Ember.set(this, 'isLoading', true);
        let selectedFundsRoot = Ember.get(this, 'selectedFundsRoot');
        let selectedAmountToBuy = Ember.get(this, 'selectedAmountToBuy');
        let selectedTrigger = Ember.get(this, 'selectedTrigger');
        this.ajax.request(`/geoBoundaries/${selectedFundsRoot.original.geoBoundaryId}/replenishmentDefaults?raw=true`, {
          method: 'PATCH',
          data: JSON.stringify({
            replenishmentDefaultValueAmount: selectedAmountToBuy.value,
            replenishmentDefaultMinimumValueAmount: selectedTrigger.value
          })
        }).then(() => {
          Ember.set(this, 'isLoading', false);
          Ember.get(this, 'notifications').success(this.intl.t('auto_charge_settings_saved'), {
            autoClear: true,
            clearDuration: 5000
          });
          this.close();
        }, function () {
          self.set('isLoading', false);
          self.get('notifications').error(self.get('intl').t('unknown_error'), {
            autoClear: true,
            clearDuration: 5000
          });
        });
      }

    }
  });

  _exports.default = _default;
});