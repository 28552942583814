define("alliance-business-suite/templates/machines/programs/send", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "79Hz343N",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[8,\"machines/programs/p-send\",[],[[\"@model\"],[[32,1]]],null]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "alliance-business-suite/templates/machines/programs/send.hbs"
  });

  _exports.default = _default;
});