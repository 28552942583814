define("alliance-business-suite/components/wash-alert/wa-ad-preview", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    acceptedFileTypes: ['png', 'jpeg'],
    previewUrl: Ember.computed('ad.imageUrl', function () {
      return this.get('ad.imageUrl');
    }),
    actions: {
      showColorPicker() {
        (0, _jquery.default)('#colorPicker').click();
      },

      changeColor(e) {
        let color = e.target.value;
        this.set('ad.background', color);
        this.set('hasChanged', true);
      },

      uploadPreview(file) {
        if (this.acceptedFileTypes.indexOf(file.extension) === -1) {
          alert(`${this.intl.t('only_file_types')} ${this.acceptedFileTypes.join(', ')}`);
          return false;
        }

        let blobUrl = window.URL.createObjectURL(file.blob);

        if (blobUrl) {
          this.set('ad.file', file);
          this.set('ad.imageUrl', blobUrl);
          this.set('hasChanged', true);
        }
      }

    }
  });

  _exports.default = _default;
});