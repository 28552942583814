define("alliance-business-suite/serializers/organization", ["exports", "alliance-business-suite/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    /**
     * Hacks a JSON API data object returned within a JSON API document to
     * conform to what is required to make the endpoint work properly with Ember
     * Data and within this application.
     *
     * @param {object} data A data object from a JSON API document
     * @protected
     */
    normalizeDataHack(data) {
      this.toLinkRelationship(data, 'employees');
      this.toLinkRelationship(data, 'customers');
      this.toLinkRelationship(data, 'facilities'); // TODO - Remove this once role types are not returned in the API.

      if (data && data.relationships && data.relationships.roles) {
        delete data.relationships.roles;
      }
    },

    /**
     * Hacks a JSON API include object returned within a JSON API document to
     * confirm to what is required to make the endpoint work property with Ember
     * Data and within this application.
     *
     * @param  {object} include An included data object from a JSON API document
     *
     * @return {object} The data object to use for this include when passing it
     *                  to the store; or undefined if this include should be
     *                  ignored.
     *
     * @protected
     */
    // normalizeInclude(include) {
    //   return include.type !== 'role-types'
    //     && include.type !== 'roles'
    //     && include.type !==  'user-role-types'
    //     && include.type !==  'user-roles'
    //       ? include : undefined;
    // },
    // extract the meta
    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});