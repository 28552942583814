define("alliance-business-suite/components/ui/lcd-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    displayValue: Ember.computed('property', {
      get(key) {
        if (Ember.get(this, 'property')) {
          let val = Ember.get(this, 'property');
          return decodeURIComponent(escape(window.atob(val)));
        }
      },

      set(key, value) {
        if (value) {
          let padValue = value.padEnd(40, " ");
          Ember.set(this, 'property', window.btoa(unescape(encodeURIComponent(padValue))));
        }

        return value;
      }

    }),
    actions: {
      validateInput(value) {
        let letters = value.split('');
        let validLetters = letters.filter(item => {
          return 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789# '.indexOf(item) >= 0;
        });
        let response = validLetters.join('');
        Ember.set(this, 'displayValue', response);
      }

    }
  });

  _exports.default = _default;
});