define("alliance-business-suite/components/table/sales-goals/table-cell-role-name", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{this.formattedRoleName}}
  
  */
  {
    "id": "gSfGTtR5",
    "block": "{\"symbols\":[],\"statements\":[[1,[32,0,[\"formattedRoleName\"]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "alliance-business-suite/components/table/sales-goals/table-cell-role-name.hbs"
  });

  let TableSalesGoalsTableCellRoleNameComponent = (_dec = Ember.inject.service, (_class = class TableSalesGoalsTableCellRoleNameComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor, this);
    }

    get formattedRoleName() {
      let {
        securityRoleIsSystemDefault,
        securityRoleName
      } = this.args.record;

      if (securityRoleIsSystemDefault) {
        return this.intl.t(securityRoleName);
      }

      return securityRoleName;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = TableSalesGoalsTableCellRoleNameComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TableSalesGoalsTableCellRoleNameComponent);
});