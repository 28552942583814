define("alliance-business-suite/mixins/account-balance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    access: Ember.inject.service(),
    notifications: Ember.inject.service(),
    currencySubunit: Ember.computed(function () {
      return Ember.get(this, 'access.currencySubunit');
    }),
    currencySymbol: Ember.computed(function () {
      return Ember.get(this, 'access.currencyDenomination');
    }),
    currentBalance: Ember.computed('transactions.[]', function () {
      let {
        transactions
      } = this;
      let isMetered = Ember.get(this, 'access.accessOrganization.isMeteredLaundry');

      if (transactions.length > 0) {
        let currentTransaction = transactions.get(0);

        if (isMetered) {
          this.setBalances(null, currentTransaction.promotionalCredit, null);
        } else {
          this.setBalances(currentTransaction.userAccountBalanceAmount, currentTransaction.promotionalCredit, currentTransaction.rewardsPoints);
        }

        return currentTransaction.userAccountBalanceAmount;
      } else {
        this.setBalances(null, 0, 0);
      }

      return 0;
    }),

    setBalances(newBalance, currentRewardsBalance, currentRewardsPoints) {
      this.setProperties({
        newBalance,
        currentRewardsBalance,
        currentRewardsPoints
      });
    },

    fetchFundsRoots() {
      let {
        store
      } = this;
      let selectedFundsRoot = null;
      let fundsRoot = store.peekAll('geo-boundary').filter(gb => {
        if (Ember.get(gb, 'fundsRestriction') && !Ember.get(this, 'selectedFundsRoot')) {
          if (!selectedFundsRoot) {
            selectedFundsRoot = gb;
          }
        }

        return Ember.get(gb, 'fundsRestriction');
      });
      this.setProperties({
        selectedFundsRoot,
        fundsRoot
      });
      this.getTransactions();
    },

    getTransactions() {
      let {
        userId,
        selectedFundsRoot
      } = this;
      let organizationId = Ember.get(this, 'access.accessOrganization.id');
      this.set('isLoading', true);
      this.ajax.request(`/users/${userId}/accounts?organizationId=${organizationId}&geoBoundaryId=${selectedFundsRoot.get('id')}&include=account-transactions&raw=true`, {
        method: 'GET'
      }).then(response => {
        if (!this.isDestroyed) {
          Ember.set(this, 'isLoading', false);
        }

        if (response[0]) {
          if (!this.isDestroyed) {
            Ember.set(this, 'canEditBalance', true);
            Ember.set(this, 'userAccountId', response[0].id);
            Ember.set(this, 'geoBoundaryId', response[0].geoBoundary.id);
          }
        }

        let noTransactions = !response || !response[0] || !response[0].accountTransactions;

        if (noTransactions) {
          Ember.set(this, 'transactions', []);
          return;
        }

        this.set('accountId', response[0].id);
        let transactions = response[0].accountTransactions; // console.log('transactions', transactions);
        // divide dollar amounts by 100
        // In the future, the backend could send us the amounts in cents
        // and this code will still do the right thing.
        // let stopDividing = false;
        // let finalLog = '';

        if (!transactions.map) {
          this.set('transactions', []);
          return response;
        }

        transactions = transactions.map(transaction => {
          ['pointsRewardsCreditEarned'].forEach(divideBy100Key => {
            if (transaction.transactionTypeLookupKey != 'REWARDS_POINTS_PROMO_REDEEMED') {
              // Divide by 100!
              let newDollarAmount = String(+transaction[divideBy100Key] / this.get('access.currencySubunit'));

              if (this.get('access.currencySubunit') === 100) {
                // always fill in decimal places ("7.4" -> "7.40", "8" -> "8.00"
                if (!newDollarAmount.includes('.')) {
                  newDollarAmount += '.00';
                } else if (newDollarAmount.split('.')[1].length === 1) {
                  newDollarAmount += '0'; // if the length is 2 then leave it alone!
                } else {// console.log(
                  //   'his else - this should be true:',
                  //   newDollarAmount.split('.')[1].length === 2
                  // ); // (it's always true for seiya010@als.com)
                }
              }

              transaction[divideBy100Key] = newDollarAmount;
            }
          });
          return transaction;
        }); // intl for Description

        transactions.forEach(element => {
          element.transactionDescriptionLocalized = '';
          element.transactionDescription.forEach(transaction => {
            if (transaction.isLookup === true) {
              let translated = this.intl.t(transaction.text.toLowerCase());
              element.transactionDescriptionLocalized += `${translated}`;
            } else {
              element.transactionDescriptionLocalized += `${transaction.text}`;
            }
          });
        });

        if (!this.isDestroyed) {
          Ember.set(this, 'transactions', transactions);
        }

        return response;
      }); // .catch((error/* , jqXHR */) => {
      //   // console.error('get transactions xhr .catch error:', error);
      //   return error;
      // });
    },

    actions: {
      changeSelectedFundsRoot(val) {
        this.set('selectedFundsRoot', val);
        this.getTransactions();
      }

    }
  });

  _exports.default = _default;
});