define("alliance-business-suite/components/machines/programs/advanced/midas-tum", ["exports", "alliance-business-suite/mixins/machines/programs/midas-options", "alliance-business-suite/mixins/machines/programs/midas-property-names"], function (_exports, _midasOptions, _midasPropertyNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_midasOptions.default, _midasPropertyNames.default, {
    formConfig: Ember.computed(function () {
      return {
        columns: [{
          sections: [{
            title: "power_fail_reset",
            controls: [{
              type: "power-fail-reset",
              properties: this.propertyNames.powerFailReset
            }]
          }, {
            title: "auto_ignite",
            controls: [{
              type: "number",
              propertyName: "Program.Cycle.AutoIgniteRetries_TUM"
            }]
          }, {
            title: "heater_temperatures",
            controls: [{
              type: "select",
              propertyName: "Program.Misc.MiscConfig.CelsiusEnabled",
              options: 'temperatureUnits'
            }, {
              type: "select-temp",
              propertyName: "Program.Cycle.HighTemp_TUM",
              options: 'temperatureValues'
            }, {
              type: "select-temp",
              propertyName: "Program.Cycle.MediumTemp_TUM",
              options: 'temperatureValues'
            }, {
              type: "select-temp",
              propertyName: "Program.Cycle.LowTemp_TUM",
              options: 'temperatureValues'
            }, {
              type: "select-temp",
              propertyName: "Program.Cycle.DelicatesTemp_TUM",
              options: 'temperatureValues'
            }]
          }]
        }, {
          sections: [{
            title: "coin_start_pulses",
            controls: [{
              type: "coin-start-pulses",
              properties: this.propertyNames.coinStartPulses,
              options: this.selectOptions.StartPulseMode
            }]
          }, {
            title: "availability",
            controls: [{
              type: "select",
              propertyName: "Program.Misc.MiscConfig.AvailableType1",
              options: "AvailableType"
            }, {
              type: "select",
              propertyName: "Program.Misc.MiscConfig.AvailableType2",
              options: "AvailableType"
            }, {
              type: "select",
              propertyName: "Program.Misc.MiscConfig.AvailableType3",
              options: "AvailableType"
            }]
          }, {
            title: "low_power",
            controls: [{
              type: "low-power",
              properties: this.propertyNames.lowPower,
              index: 0,
              number: 1
            }, {
              type: "low-power",
              properties: this.propertyNames.lowPower,
              index: 1,
              number: 2
            }, {
              type: "low-power",
              properties: this.propertyNames.lowPower,
              index: 2,
              number: 3
            }, {
              type: "low-power",
              properties: this.propertyNames.lowPower,
              index: 3,
              number: 4
            }]
          }]
        }]
      };
    })
  });

  _exports.default = _default;
});