define("alliance-business-suite/transforms/rewards-points", ["exports", "jquery", "ember-data"], function (_exports, _jquery, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Transform
  } = _emberData.default;

  var _default = Transform.extend({
    deserialize(value) {
      return value / 100;
    },

    serialize(value) {
      return Number(value);
    }

  });

  _exports.default = _default;
});