define("alliance-business-suite/templates/components/form-ui/time-span", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Gl1IEitl",
    "block": "{\"symbols\":[],\"statements\":[[2,\"\\n\"],[10,\"div\"],[14,0,\"text-field form-field overflow-float\"],[12],[2,\"\\n  \"],[10,\"label\"],[14,0,\"form-ui-element\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"start_time\"],null]],[2,\"\\n    \"],[8,\"time-field\",[],[[\"@on-change\",\"@value\",\"@hour12\"],[[30,[36,3],[[32,0],[30,[36,2],[[35,1]],null]],null],[34,1],[34,4,[\"hour12\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\\n\"],[8,\"form-ui/number\",[],[[\"@object\",\"@update\",\"@formSettings\",\"@params\"],[[34,5],[34,6],[34,7],[30,[36,9],null,[[\"propertyName\",\"label\",\"display\",\"min\",\"max\",\"limit\"],[[35,8,[\"durationHours\",\"key\"]],\"duration_hours\",\"compact\",0,24,true]]]]],null],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"text-field form-field overflow-float\"],[12],[2,\"\\n  \"],[10,\"label\"],[14,\"for\",\"\"],[14,0,\"form-ui-element\"],[12],[1,[30,[36,0],[\"end_time\"],null]],[2,\":\"],[13],[2,\"\\n  \"],[8,\"input\",[],[[\"@value\",\"@disabled\"],[[34,10],true]],null],[2,\"\\n\"],[6,[37,12],[[35,11]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,5,\"font-size:smaller\"],[12],[1,[30,[36,0],[\"nextday_message\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"startTime\",\"mut\",\"action\",\"params\",\"object\",\"update\",\"formSettings\",\"properties\",\"hash\",\"endTime\",\"overnight\",\"if\"]}",
    "moduleName": "alliance-business-suite/templates/components/form-ui/time-span.hbs"
  });

  _exports.default = _default;
});