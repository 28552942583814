define("alliance-business-suite/components/form-ui/language-selection", ["exports", "alliance-business-suite/utils/byte-utilities", "alliance-business-suite/mixins/form-ui"], function (_exports, _byteUtilities, _formUi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_formUi.default, {
    languageOptions: Ember.computed(function () {
      return [{
        val: 8,
        label: "German",
        fullLabel: "Deutsch (German)"
      }, {
        val: 1,
        label: "English",
        fullLabel: "English"
      }, {
        val: 3,
        label: "SpanishColumbian",
        fullLabel: "Español (Columbian Spanish)"
      }, {
        val: 2,
        label: "SpanishEuropean",
        fullLabel: "Español (European Spanish)"
      }, {
        val: 4,
        label: "French",
        fullLabel: "Français (French)"
      }, {
        val: 10,
        label: "Italian",
        fullLabel: "Italiano (Italian)"
      }, {
        val: 14,
        label: "Dutch",
        fullLabel: "Nederlands (Dutch)"
      }, {
        val: 12,
        label: "PortugueseBrazilian",
        fullLabel: "Português (Brazilian Portuguese)"
      }, {
        val: 11,
        label: "PortugueseEuropean",
        fullLabel: "Português (European Portuguese)"
      }, {
        val: 7,
        label: "Russian",
        fullLabel: "Pусский (Russian)"
      }, {
        val: 13,
        label: "Korean",
        fullLabel: "한국어 (Korean)"
      }, {
        val: 9,
        label: "Japanese",
        fullLabel: "日本人 (Japanese)"
      }, {
        val: 5,
        label: "ChineseSimplified",
        fullLabel: "中文 (Simplified Chinese)"
      }, {
        val: 6,
        label: "ChineseTraditional",
        fullLabel: "中文 (Tradition Chinese)"
      }];
    }),
    languages: Ember.computed('rawValue', function () {
      // Get the list of languages
      let list = Ember.get(this, 'languageOptions'); // Get the base64 stuff and break it into an array

      let array = (0, _byteUtilities.base64ToBinaryString)(this.value); // Reverse items in this array

      array.reverse(); // Combine the values and then reverse the bits

      let all = array.join('').split('').reverse(); // Loop through the languages array and set "selected" to the base64 stuff

      list.forEach(function (item) {
        let bitPosition = item.val - 1; // Make position zero-based

        item.selected = all[bitPosition] == 1;
      });
      let returnValue = list.sortBy('label');
      return returnValue;
    }),
    actions: {
      checkboxClicked(e) {
        this.languageOptions.findBy('val', e.val).selected = !e.selected; // Get the list, sorted by val

        let list = Ember.get(this, 'languageOptions').sortBy('val'); // Loop through and create bytes

        let bits = list.reduce((prevValue, item) => {
          return `${prevValue}${item.selected ? 1 : 0}`;
        }, '');
        bits = bits.padEnd(16, 0);
        let bitsString = bits.split('').reverse().join('');
        let bytesArray = bitsString.match(/.{1,8}/g); // Gives us 2 bytes

        bytesArray.reverse();
        let hexString = bytesArray.reduce((prev, item) => {
          let dec = parseInt(item, 2);
          return prev + dec.toString(16).padStart(2, 0);
        }, ''); // Update the property

        let updatedValue = (0, _byteUtilities.bytesToBase64)(hexString);
        this.update(this.object, this.propertyName, updatedValue);
      }

    }
  });

  _exports.default = _default;
});