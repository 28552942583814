define("alliance-business-suite/components/form-ui/duration-field/states/focused", ["exports", "alliance-business-suite/components/form-ui/duration-field/utils/state", "alliance-business-suite/components/form-ui/duration-field/states/hours-focused", "alliance-business-suite/components/form-ui/duration-field/states/minutes-focused", "alliance-business-suite/components/form-ui/duration-field/states/seconds-focused"], function (_exports, _state, _hoursFocused, _minutesFocused, _secondsFocused) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _state.default.extend({
    hours: (0, _state.state)(_hoursFocused.default),
    minutes: (0, _state.state)(_minutesFocused.default),
    seconds: (0, _state.state)(_secondsFocused.default),

    focusOut(manager) {
      manager.transitionTo("unfocused");
    },

    refocus(manager) {
      manager.send("focusIn");
    },

    left() {// no-op if not handled by child
    },

    right() {// no-op if not handled by child
    }

  });

  _exports.default = _default;
});