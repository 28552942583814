define("alliance-business-suite/components/form-ui/temperature-override", ["exports", "alliance-business-suite/mixins/init-property-names"], function (_exports, _initPropertyNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_initPropertyNames.default, {
    // TODO: Change based on C/F...check intl keys
    nameArray: Ember.computed('formSettings.isCelsius', function () {
      if (this.formSettings.isCelsius) {
        return ['Cold', 'Warm', 'Hot', 'NoHeat', '15C', '30C', '40C', '50C', '60C', '90C'];
      } else {
        return ['Cold', 'Warm', 'Hot', 'NoHeat', '15C', '30C', '40C', '50C', '60C', '90C'];
      }
    }) // washPropertyNameArray: ['WashCold', 'WashWarm', 'WashHot', 'WashNoHeat', 'Wash15C', 'Wash30C', 'Wash40C', 'Wash50C', 'Wash60C', 'Wash90C'],
    // prewashPropertyNameArray: ['PrewashCold', 'PrewashWarm', 'PrewashHot', 'PrewashNoHeat', 'Prewash15C', 'Prewash30C', 'Prewash40C', 'Prewash50C', 'Prewash60C', 'Prewash90C']

  });

  _exports.default = _default;
});