define("alliance-business-suite/components/services/sortable-services", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    access: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    note: null,
    setInterval: null,

    didInsertElement() {
      this._super(...arguments);

      let self = this;
      (0, _jquery.default)("#sortable-services").sortable({
        containment: "parent"
      });
      (0, _jquery.default)("#sortable-services").disableSelection();
      let interval = setInterval(() => {
        this.toggleProperty('openHand');
      }, 700);
      this.set('setInterval', interval);
    },

    willDestroyElement() {
      clearInterval(this.setInterval);

      this._super(...arguments);
    },

    sortedList: Ember.computed(function () {
      let og = this.store.peekAll('order-line-item-type');
      let list = [];
      og.forEach(i => {
        list.push(i);
      });
      let sortedList = list.sort((a, b) => {
        let ordinalA = a.get('ordinalValue') ? a.get('ordinalValue') : 0;
        let ordinalB = b.get('ordinalValue') ? b.get('ordinalValue') : 0;

        if (ordinalA > ordinalB) {
          return 1;
        } else if (ordinalA < ordinalB) {
          return -1;
        }

        return 0;
      });
      return sortedList;
    }),
    actions: {
      save() {
        let self = this;
        let notifications = this.notifications;
        let orgId = Ember.get(this, 'access.accessOrganization.id');
        let oridinalArray = (0, _jquery.default)("#sortable-services").sortable("toArray");
        this.set('isLoading', true);
        this.ajax.request(`organizations/${orgId}/orderLineItemTypes/ordinalUpdate`, {
          method: 'PATCH',
          data: {
            'itemList': oridinalArray
          }
        }).then(response => {
          notifications.success(this.intl.t('sort_save_success'), {
            clearDuration: 5000,
            autoClear: true
          }); // because the PATCH doesnt return anything but a 200, the ember data is out of date
          // we fake it in the data store until the user refreshes the endpoints

          oridinalArray.forEach((s, index) => {
            let service = self.get('store').peekRecord('order-line-item-type', s);
            service.set('ordinalValue', index + 1);
          });
          this.set('isLoading', false);
          this.router.transitionTo('retail.services-list');
        }).catch(() => {
          alert('Error saving');
          this.set('isLoading', false);
        });
      }

    }
  });

  _exports.default = _default;
});