define("alliance-business-suite/components/machines/programs/config/acas-tlw", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    machineAuditType: Ember.computed.alias('machineProgram.machineAuditType.name'),
    machineCode: Ember.computed.alias('machineProgram.machineCode'),
    optionsManualMode: Ember.computed("_machineGeneration", function () {
      if (this._machineGeneration === "TITAN") {
        return [{
          val: 2,
          label: 'Password protected',
          text: "Access is passcode only"
        }, {
          val: 1,
          label: 'Enabled',
          text: "Access is not limited"
        }, {
          val: 0,
          label: 'Disabled',
          text: "Access is not allowed"
        }];
      } else {
        return [{
          val: 0,
          label: 'Password protected',
          text: "Access is passcode only"
        }, {
          val: 1,
          label: 'Enabled',
          text: "Access is not limited"
        }, {
          val: 2,
          label: 'Disabled',
          text: "Access is not allowed"
        }];
      }
    }),
    optionsSpeedUnits: [{
      val: 0,
      label: 'RPM'
    }, {
      val: 1,
      label: 'G Force'
    }],
    optionsPassKey: [{
      val: 1,
      label: 'Light Soil'
    }, {
      val: 2,
      label: 'Medium Soil'
    }, {
      val: 3,
      label: 'Heavy Soil'
    }, {
      val: 4,
      label: 'Small Load'
    }, {
      val: 5,
      label: 'Normal'
    }, {
      val: 6,
      label: 'Perm Press'
    }, {
      val: 7,
      label: 'Delicates'
    }, {
      val: 8,
      label: 'Hot Temp'
    }, {
      val: 9,
      label: 'Warm Temp'
    }, {
      val: 10,
      label: 'Cold Temp'
    }, {
      val: 12,
      label: 'Start'
    }] // days: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

  });

  _exports.default = _default;
});