define("alliance-business-suite/components/rooms/r-edit-form", ["exports", "jquery", "alliance-business-suite/config/environment", "alliance-business-suite/mixins/timezones", "ember-cp-validations"], function (_exports, _jquery, _environment, _timezones, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'model.roomName': [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    'model.geomName': [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    // 'model.taxRate': [
    //   validator('presence', {
    //     presence: true
    //   })
    // ],
    'model.latitude': [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    'model.longitude': [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    'model.timeZoneName': [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })]
  });

  var _default = Ember.Component.extend(Validations, {
    intl: Ember.inject.service(),
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    access: Ember.inject.service(),
    notifications: Ember.inject.service(),
    isSaving: false,
    cards: Ember.computed('model', function () {
      let cards = ['general', 'social-media'];

      if (this.get('access.isRetail')) {
        cards = ['general', 'social-media', 'retail'];
      }

      return cards;
    }),
    editingDisabled: Ember.computed(function () {
      return !this['can-access'].canAccess('LOCATIONS_EDIT_DETAIL_VIEW');
    }).readOnly(),

    saveMarkup(room, receiptMarkupJson) {
      let data = {
        markupJson: {}
      };

      let _this = this;

      data.markupJson.imageUrl = receiptMarkupJson.get('imageUrl');
      data.markupJson.header1 = receiptMarkupJson.get('header1') || '';
      data.markupJson.header2 = receiptMarkupJson.get('header2') || '';
      data.markupJson.header3 = receiptMarkupJson.get('header3') || '';
      data.markupJson.header4 = receiptMarkupJson.get('header4') || '';
      data.markupJson.footer1 = receiptMarkupJson.get('footer1') || '';
      data.markupJson.footer2 = receiptMarkupJson.get('footer2') || '';
      data.markupJson.footer3 = receiptMarkupJson.get('footer3') || '';
      data.markupJson.footer4 = receiptMarkupJson.get('footer4') || '';
      this.ajax.request(`/rooms/${room.get('id')}/receipt`, {
        data,
        method: 'PATCH'
      }).then(response => {}).catch(() => {
        Ember.get(_this, 'notifications').error(_this.get('intl').t('receipt_save_failed'));
      });
    },

    actions: {
      exit(model) {
        // Revert changes
        if (model.get('hasDirtyAttributes')) {
          model.rollbackAttributes();
        }

        let receiptMarkupJson = model.get('receiptMarkupJson');

        if (receiptMarkupJson.get('id') && receiptMarkupJson.get('hasDirtyAttributes')) {
          this.store.peekRecord('receipt-markup-json', receiptMarkupJson.get('id')).rollbackAttributes();
        }

        Ember.get(this, 'router').transitionTo('locations');
      },

      save(model) {
        let _this = this;

        this.set('isSaving', true);
        let receiptMarkupJson = model.get('receiptMarkupJson');

        if (receiptMarkupJson && receiptMarkupJson.get('hasDirtyAttributes')) {
          this.saveMarkup(model, receiptMarkupJson); // this.get('store').peekRecord('receipt-markup-json', receiptMarkupJson.get('id')).save({ adapterOptions: { roomId: model.get('id') } });
        }

        model.save().then(function () {
          _this.set('isSaving', false);

          Ember.get(_this, 'notifications').info(_this.get('intl').t('room_successfully_updated'), {
            autoClear: true,
            clearDuration: 2000
          });
          Ember.get(_this, 'router').transitionTo('locations');
        }, function () {
          _this.set('isSaving', false);

          Ember.get(_this, 'notifications').error(_this.get('intl').t('save_failed'));
        });
      }

    }
  });

  _exports.default = _default;
});