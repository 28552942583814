define("alliance-business-suite/components/form-ui/weekday-bitfield", ["exports", "alliance-business-suite/mixins/form-ui"], function (_exports, _formUi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_formUi.default, {
    // Sunday = bits[1]
    // Monday = bits[2]
    // Tuesday = bits[3]
    // Wednesday = bits[4]
    // Thursday = bits[5]
    // Friday = bits[6]
    // Saturday = bits[7]
    weekdays: Ember.computed('value', function () {
      // Convert bitfield to object
      return {
        sun: (this.value & 2) === 2,
        mon: (this.value & 4) === 4,
        tue: (this.value & 8) === 8,
        wed: (this.value & 16) === 16,
        thu: (this.value & 32) === 32,
        fri: (this.value & 64) === 64,
        sat: (this.value & 128) === 128
      };
    }),

    updateValue() {
      let weekdays = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
      let returnValue = weekdays.reduce((previousValue, item, index) => {
        if (this.weekdays[item]) {
          previousValue |= 1 << index + 1;
        }

        return previousValue;
      }, 0);
      this.update(this.object, this.propertyName, returnValue);
    },

    // We need to observe any changes to the
    weekdayUpdates: Ember.observer('weekdays.{sun,mon,tue,wed,thu,fri,sat}', function () {
      // eslint-disable-line
      Ember.run.scheduleOnce('afterRender', this, this.updateValue); // TODO: This runs twice.
    })
  });

  _exports.default = _default;
});