define("alliance-business-suite/components/machines/programs/mgd-opl-tum-cycle-description", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{this.description}}
  */
  {
    "id": "BJSHhLb3",
    "block": "{\"symbols\":[],\"statements\":[[1,[32,0,[\"description\"]]]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "alliance-business-suite/components/machines/programs/mgd-opl-tum-cycle-description.hbs"
  });

  const TIME_DRY = 0;
  const AUTO_DRY = 1;
  const MOISTURE_DRY = 2;

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    tagName: '',
    intl: Ember.inject.service(),
    steps: Ember.computed.alias('cycle.CycleSteps'),
    step1: Ember.computed.alias('cycle.CycleSteps.0'),
    type: Ember.computed.alias('cycle.Type'),
    time: Ember.computed.alias('step1.Time'),
    temperature: Ember.computed('step1.CustomTemp', 'tempScale', function () {
      let temperature = this.step1.CustomTemp;

      switch (temperature) {
        case 1:
          return this.intl.t("high");

        case 2:
          return this.intl.t("low");

        case 3:
          return this.intl.t("no_heat");

        case 4:
          return this.intl.t("medium");

        case 5:
          return this.intl.t("very_low");

        default:
          return `${temperature}°${this.tempScale}`;
      }
    }),
    tempScale: Ember.computed('isCelsius', function () {
      return this.isCelsius ? "C" : "F";
    }),
    material: Ember.computed('cycle.Material', function () {
      switch (Number(this.cycle.Material)) {
        case 0:
          return this.intl.t("cotton");

        case 1:
          return this.intl.t("blend");

        case 2:
          return this.intl.t("bedding");

        case 3:
          return this.intl.t("delicate");

        case 4:
          return this.intl.t("synthetic");

        case 5:
          return this.intl.t("wool");

        default:
          return this.intl.t("unknown");
      }
    }),
    dryness: Ember.computed('step1.DrynessLevel', function () {
      let dryness = Number(this.step1.DrynessLevel);
      let options = [{
        val: 251,
        label: "-5"
      }, {
        val: 252,
        label: "-4"
      }, {
        val: 253,
        label: "-3"
      }, {
        val: 254,
        label: "-2"
      }, {
        val: 255,
        label: "-1"
      }, {
        val: 0,
        label: "0"
      }, {
        val: 1,
        label: "1"
      }, {
        val: 2,
        label: "2"
      }, {
        val: 3,
        label: "3"
      }, {
        val: 4,
        label: "4"
      }, {
        val: 5,
        label: "5"
      }];
      return options.findBy('val', dryness).label;
    }),
    moisture: Ember.computed.alias('step1.TargetMoisture'),
    isMultistep: Ember.computed('steps.@each.Enabled', 'multiStep', function () {
      if (this.multiStep) {
        // Check index 1 to 4 for Enabled==true
        for (let i = 1; i < this.steps.length; i++) {
          if (this.steps[i].Enabled == true) {
            return true;
          }
        }
      }

      return false;
    }),
    description: Ember.computed('isMultistep', 'moisture', 'dryness', 'time', 'temperature', 'material', 'type', function () {
      switch (true) {
        case this.type === TIME_DRY:
          // temp + time: "Very low 30 mins" or "115°F 5 mins" or "No heat 5 mins" or "Multi-step"
          return this.isMultistep ? this.intl.t("multi-step") : `${this.temperature} ${this.time} ${this.intl.t("min")}`;

        case this.type === AUTO_DRY:
          // temp + dryness level: "Very low 3"
          return `${this.temperature} ${this.dryness}`;

        case this.type === MOISTURE_DRY:
          // material + temperature + target moisture: "Cotton 115°F 1%" or "Cotton Multi-step"
          return this.isMultistep ? `${this.material} ${this.intl.t("multi-step")}` : `${this.material} ${this.temperature} ${this.moisture}%`;

        default:
          return this.intl.t("unknown");
      }
    })
  }));

  _exports.default = _default;
});