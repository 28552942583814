define("alliance-business-suite/mixins/machines/programs/v3/form-fields", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    advanced: true,
    displayFor: Ember.computed.alias('params.displayFor'),
    enableWith: Ember.computed.alias('params.enableWith'),
    showWith: Ember.computed.alias('params.showWith'),
    step: Ember.computed.alias('params.step'),
    limit: Ember.computed('params.{min,max}', function () {
      return Ember.get(this, 'params.max') != null && Ember.get(this, 'params.min') != null;
    }),
    classNameBindings: ['params.isInvalid'],
    labelAfter: Ember.computed.alias('params.labelAfter'),
    // label displayed after inputs
    // property: computed.alias('params.property'), // the generic property name
    property: null,
    value: null,
    numberType: Ember.computed('params.numberType', function () {
      let value = Ember.get(this, 'params.numberType') || '';
      return value.toUpperCase();
    }),
    placeholder: Ember.computed.alias('params.placeholder'),
    // placerholder text, optional if defined in machineSettings
    // type: computed.alias('params.type'), // type of input element, optional if defined in machineSettings
    type: Ember.computed('params.type', 'inputtype', function () {
      return Ember.get(this, 'params.type') || Ember.get(this, 'inputtype') || 'text';
    }),
    label: Ember.computed.alias('params.label'),
    // label text, optional if defined in machineSettings
    options: Ember.computed.alias('params.options'),
    // select options
    isInvalid: Ember.computed('params.isValidProperty', function () {
      return Ember.get(this, 'params.isValidProperty') ? '' : 'ui__invalidPropertyBinding';
    }),
    show: Ember.computed('params.advanced', function () {
      let displayList = Ember.get(this, 'params.displayFor');

      if (displayList) {
        // we have a list to display for
        let formName = Ember.get(this, 'params.form');

        if (!displayList.includes(formName)) {
          // array
          return false;
        }
      }

      if (Ember.get(this, 'params.advanced') === false) {
        return false;
      } else {
        return true;
      }
    }),
    isDisabled: Ember.computed('params.{enableWith,disableUnless,disableIf}', function () {
      // Add support for disableUnless hash value
      if (Ember.get(this, 'params.disableUnless') !== undefined) {
        return !Ember.get(this, 'params.disableUnless');
      }

      if (Ember.get(this, 'params.disableIf') !== undefined) {
        return Ember.get(this, 'params.disableIf');
      } // Older non-hash stuff


      if (Ember.get(this, 'params.enableWith') == null) {
        return false;
      }

      let val = Ember.get(this, 'params.enableWith') || false;
      return !val;
    }),
    isValidProperty: Ember.computed('property', function () {
      return Ember.get(this, 'property') === undefined ? false : true;
    }),

    change() {
      let el = arguments[0].target;

      if (Ember.get(this, 'params.onChange')) {
        if (el.type === 'checkbox') {
          Ember.get(this, 'params.onChange')(el.checked);
        } else {
          Ember.get(this, 'params.onChange')(el.value);
        }
      }
    }

  });

  _exports.default = _default;
});