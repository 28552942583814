define("alliance-business-suite/components/machines/programs/step-context-menu", ["exports", "ember-copy"], function (_exports, _emberCopy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Ui::ContextMenu as |menu|>
    {{#if this.canCopyPaste}}
      <menu.item @label="Copy" @click={{action "copyStep" this.step}} />
      <menu.item @label="Paste" @click={{action "pasteStep" this.step}} @disabled={{not-eq clipboard.type "step"}} />
      <menu.separator />
    {{/if}}
    {{#if this.canMove}}
      <menu.item @label="Move up" @click={{action "moveStep" this.step "up"}} @disabled={{this.isFirstItem}} />
      <menu.item @label="Move down" @click={{action "moveStep" this.step "down"}} @disabled={{this.isLastItem}} />
      <menu.separator />
    {{/if}}
    {{#if this.canDelete}}
      <menu.item @label="Delete" @click={{action "deleteStep" this.step}} />
      <menu.separator />
    {{/if}}
  </Ui::ContextMenu>
  */
  {
    "id": "2p2QD1XW",
    "block": "{\"symbols\":[\"menu\"],\"statements\":[[8,\"ui/context-menu\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],[[32,0,[\"canCopyPaste\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,[32,1,[\"item\"]],[],[[\"@label\",\"@click\"],[\"Copy\",[30,[36,0],[[32,0],\"copyStep\",[32,0,[\"step\"]]],null]]],null],[2,\"\\n    \"],[8,[32,1,[\"item\"]],[],[[\"@label\",\"@click\",\"@disabled\"],[\"Paste\",[30,[36,0],[[32,0],\"pasteStep\",[32,0,[\"step\"]]],null],[30,[36,2],[[35,1,[\"type\"]],\"step\"],null]]],null],[2,\"\\n    \"],[8,[32,1,[\"separator\"]],[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,3],[[32,0,[\"canMove\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,[32,1,[\"item\"]],[],[[\"@label\",\"@click\",\"@disabled\"],[\"Move up\",[30,[36,0],[[32,0],\"moveStep\",[32,0,[\"step\"]],\"up\"],null],[32,0,[\"isFirstItem\"]]]],null],[2,\"\\n    \"],[8,[32,1,[\"item\"]],[],[[\"@label\",\"@click\",\"@disabled\"],[\"Move down\",[30,[36,0],[[32,0],\"moveStep\",[32,0,[\"step\"]],\"down\"],null],[32,0,[\"isLastItem\"]]]],null],[2,\"\\n    \"],[8,[32,1,[\"separator\"]],[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,3],[[32,0,[\"canDelete\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,[32,1,[\"item\"]],[],[[\"@label\",\"@click\"],[\"Delete\",[30,[36,0],[[32,0],\"deleteStep\",[32,0,[\"step\"]]],null]]],null],[2,\"\\n    \"],[8,[32,1,[\"separator\"]],[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"clipboard\",\"not-eq\",\"if\"]}",
    "moduleName": "alliance-business-suite/components/machines/programs/step-context-menu.hbs"
  }); // import Component from '@glimmer/component';


  // export default class MachinesProgramsStepContextMenuComponent extends Component {
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    clipboard: {
      type: null,
      contents: null,
      title: null
    },
    notifications: Ember.inject.service(),
    isFirstItem: Ember.computed('index', 'steps.[]', function () {
      return this.index === 0;
    }),
    isLastItem: Ember.computed('index', 'steps.[]', function () {
      return this.index === this.stepList.length - 1;
    }),
    intl: Ember.inject.service(),

    swapArrayItems(array, index, direction) {
      // Used to re-order array items
      let secondIndex = direction === 'up' ? index - 1 : index + 1;
      let firstItem = [array[index]];
      let secondItem = [array[secondIndex]];

      if (direction == "up" && index > 0 || direction == 'down' && index < array.get('length') - 1) {
        array.replace(index, 1, secondItem).replace(secondIndex, 1, firstItem);
      }

      return array;
    },

    canMove: true,
    canDelete: true,
    canCopyPaste: true,
    canAdd: true,
    // if false, we replace during paste instead of adding
    actions: {
      moveStep(step, direction) {
        let array = this.stepList;
        let index = array.indexOf(step);
        this.swapArrayItems(array, index, direction); // this.applyTime(); // Doesn't work
      },

      selectStep(step) {
        if (!step) {
          step = Ember.get(this, 'stepList.firstObject');
        } else if (!isNaN(step)) {
          step = this.stepList.objectAt(Number(step));
        }

        let index = this.stepList.indexOf(step) || 0;
        Ember.set(this, 'selectedStep', step);
        $(step).focus();
        let stepOptions = Ember.get(this, 'cycleSettings.stepMetaData').findBy('Type', step.Type); // Find step name to display in options panel

        let stepName = stepOptions.name || 'Unknown';
        Ember.set(this, 'stepName', `${stepName} Step`);
        this.closeControls();
        localStorage.setItem('step', index || 0);
        this.send('calculateTime');
      },

      deleteStep(step) {
        let stepList = this.stepList;

        if (stepList.length === 0) {
          return;
        }

        let id = Ember.get(step, 'Type');

        if (confirm(this.intl.t('confirm_delete_selected_step'))) {
          let index = stepList.indexOf(step);
          stepList.removeAt(index); // Now we need to set the next step that is active.

          let selected;
          let newIndex = stepList.length > index ? index : index - 1;

          if (newIndex >= 0) {
            selected = stepList.objectAt(newIndex);
            Ember.set(this, 'selectedStep', selected);
            Ember.set(this, 'selectedStepIndex', newIndex);
            $(selected).focus();
          } else {
            // No steps to display
            Ember.set(this, 'selectedStep', null);
            Ember.set(this, 'selectedStepIndex', null);
          }
        }
      },

      insertStep(stepType, index) {
        // Get the properties for the step we're inserting
        let props = Ember.get(this, 'cycleSettings.steps').findBy('Type', stepType); // Now we need to follow some rules. The label needs to match the label of the next step in the array, unless we're at the end...when we'll use the last element.
        // And we need to do the same with the ActiveState.
        // TODO: Find out if we should use the previous or next step to copy these values.

        let steps = this.stepList;
        let totalSteps = Ember.get(steps, 'length');
        let copyObject, label, activeState, newStep; // Copy the Label and ActiveState

        if (index === 'last') {
          copyObject = steps.objectAt(totalSteps - 1);
        } else {
          copyObject = steps.objectAt(index);
        }

        if (copyObject != null) {
          label = Ember.get(copyObject, 'Label');
          activeState = Ember.get(copyObject, 'ActiveState');
        } else {
          label = 1;
          activeState = 8;
        }

        Ember.set(props, 'Label', label);
        Ember.set(props, 'ActiveState', activeState); // Insert the new step.

        if (index === 'last') {
          steps.insertAt(totalSteps, props);
          newStep = steps.objectAt(totalSteps);
          index = totalSteps;
        } else {
          steps.insertAt(index, props);
          newStep = steps.objectAt(index);
        } // this.send('selectStep', newStep, index);


        this.send('selectStep', newStep); // Go grab a coffee.
      },

      copyStep(step) {
        Ember.set(this, 'clipboard.type', 'step');
        Ember.set(this, 'clipboard.contents', (0, _emberCopy.copy)(step, true)); // step is an object

        let notifications = this.notifications;
        this.notifications.success(this.intl.t("step_copied"), {
          autoClear: true,
          clearDuration: 1000,
          cssClasses: 'quick-notification'
        });
        return false;
      },

      pasteStep(step) {
        // We're going to paste the new step above the selected on
        let type = Ember.get(this, 'clipboard.type');

        if (type !== 'step') {
          alert(this.intl.t("no_step_in_clipboard"));
          return false;
        }

        let clipboard = (0, _emberCopy.copy)(Ember.get(this, 'clipboard.contents'));
        let steps = this.stepList;
        let index = steps.indexOf(step); // Copy the Label and ActiveState

        let label, activeState;

        if (step) {
          label = Ember.get(step, 'Label');
          activeState = Ember.get(step, 'ActiveState');
        } else {
          // Defaults
          label = 1;
          activeState = 8;
        }

        Ember.set(clipboard, 'Label', label);
        Ember.set(clipboard, 'ActiveState', activeState);

        if (this.canAdd) {
          steps.insertAt(index, clipboard);
        } else {
          steps.replace(index, 1, [clipboard]);
        }

        this.send('calculateTime');
        this.send('selectStep', index);
        let notifications = this.notifications;
        this.notifications.success(this.intl.t('step_pasted'), {
          autoClear: true,
          clearDuration: 1000,
          cssClasses: 'quick-notification'
        });
        return false;
      }

    }
  }));

  _exports.default = _default;
});