define("alliance-business-suite/templates/summary/subscriptions/manage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YpbDaqU7",
    "block": "{\"symbols\":[],\"statements\":[[8,\"summary/subscriptions/s-edit-form\",[],[[\"@rooms\",\"@report\",\"@activeEmail\",\"@selectedFrequency\",\"@selectedSendAtTime\",\"@selectedSendOnDay\",\"@selectedRooms\",\"@subscriptionTemplatesModel\",\"@isEditing\"],[[32,0,[\"model\",\"rooms\"]],[32,0,[\"model\",\"reportSubscription\"]],[32,0,[\"model\",\"reportSubscription\",\"email\"]],[32,0,[\"model\",\"selectedFrequency\"]],[32,0,[\"model\",\"selectedSendAtTime\"]],[32,0,[\"model\",\"selectedSendOnDay\"]],[32,0,[\"model\",\"selectedRooms\"]],[32,0,[\"model\",\"subscriptionTemplates\"]],true]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "alliance-business-suite/templates/summary/subscriptions/manage.hbs"
  });

  _exports.default = _default;
});