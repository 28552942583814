define("alliance-business-suite/templates/summary/alerts/manage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wcToNACB",
    "block": "{\"symbols\":[],\"statements\":[[8,\"summary/alerts/s-edit-form\",[],[[\"@machineAuditErrorSubTemplates\",\"@machineErrorSubTemplates\",\"@rooms\",\"@report\",\"@activeEmail\",\"@phone\",\"@selectedRooms\",\"@errorCount\",\"@isEditing\"],[[32,0,[\"machineAuditErrorSubTemplates\"]],[32,0,[\"machineErrorSubTemplates\"]],[32,0,[\"model\",\"rooms\"]],[32,0,[\"model\",\"reportSubscription\"]],[32,0,[\"model\",\"reportSubscription\",\"email\"]],[32,0,[\"model\",\"reportSubscription\",\"phone\"]],[32,0,[\"model\",\"selectedRooms\"]],[32,0,[\"model\",\"errorCount\"]],true]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "alliance-business-suite/templates/summary/alerts/manage.hbs"
  });

  _exports.default = _default;
});