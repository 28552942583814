define("alliance-business-suite/components/models-table", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "ember-models-table/components/models-table"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _modelsTable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class2, _descriptor;

  // extending functionality of ember-models-table
  let _class = (_dec = Ember._tracked, _dec2 = Ember._action, (_class2 = class _class2 extends _modelsTable.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "eventType", _descriptor, this);
    }

    // overrride default functionality to add a new "eventType" property
    // this gets bubbled up to userInteractionObserverOnce which then bubbles up to our action
    // this is the only way to tell if the action was because of a click on a row vs a click on header sorting
    // this is useful for special brush open behavior
    clickOnRow(index, dataItem) {
      // only custom code
      this.eventType = 'userClick'; // everything here on our is boiler plate from addon/components/models-table.js (3.x)

      if (this.selectRowOnClick) {
        const {
          multipleSelect,
          selectedItems
        } = this;

        if (selectedItems.includes(dataItem)) {
          selectedItems.removeObject(dataItem);
        } else {
          if (!multipleSelect && Ember.get(selectedItems, 'length') === 1) {
            selectedItems.clear();
          }

          selectedItems.pushObject(dataItem);
        }
      }

      this.userInteractionObserver();
    } // only custom code is adding an eventType property we can bubble up
    // once we set it and call the action, we reset to null the eventType
    // everything else is boiler plate from addon/components/models-table.js (3.x)


    userInteractionObserverOnce(event) {
      let actionIsFunction = typeof this.displayDataChangedAction === 'function';

      if (actionIsFunction) {
        let settings = Ember.Object.create({
          // eventType is custom prop
          eventType: this.eventType,
          sort: this.sortProperties,
          currentPageNumber: this.currentPageNumber,
          pageSize: this.pageSize,
          filterString: this.filterString,
          filteredContent: this.filteredContent,
          selectedItems: this.selectedItems,
          expandedItems: this.expandedItems,
          columns: this.processedColumns.map(c => Ember.getProperties(c, ['filterString', 'filterField', 'sortField', 'sorting', 'propertyName'])),
          columnFilters: {}
        });
        this.processedColumns.forEach(column => {
          if (!Ember.isBlank(Ember.get(column, 'filterString'))) {
            settings.columnFilters[Ember.get(column, 'propertyName')] = Ember.get(column, 'filterString');
          }
        });
        this.displayDataChangedAction(settings); // clear out the eventType for the next action

        this.eventType = null;
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "eventType", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "clickOnRow", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "clickOnRow"), _class2.prototype)), _class2));

  _exports.default = _class;
});