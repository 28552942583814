define("alliance-business-suite/controllers/summary/subscriptions/create", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  let SummarySubscriptionsCreateController = (_dec = Ember._tracked, _dec2 = Ember._action, (_class = class SummarySubscriptionsCreateController extends Ember.Controller {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "queryParams", ['reportId']);
      (0, _initializerDefineProperty2.default)(this, "reportId", _descriptor, this);
    }

    clearQueryParam() {
      this.reportId = null;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "reportId", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "clearQueryParam", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "clearQueryParam"), _class.prototype)), _class));
  _exports.default = SummarySubscriptionsCreateController;
});