define("alliance-business-suite/utils/show-machine", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.showMachine = void 0;

  // hash = { ifMachine, notMachine, machineProgram}
  const showMachine = function (hash) {
    /*
    Use it like this:
     {{#if (show-machine ifMachine=this.ifMachine machineProgram=this.machineProgram)}}
     Show the item when an ifMachine attribute matches this machine. Otherwise, hide it.
    Hide the item when a notMachine attribute matches this machine. Otherwise, show it.
     We go with the most restrictive results of comparing ifMachine first, then notMachine. So if we're looking at TITAN_T2_FLW...
     ifMachine="FLW" returns true - matched, so show it
    ifMachine="WX" returns false - no match, so hide it
    ifMachine="" returns false - no match, so hide it
     notMachine="FLW" returns false - matched, so hide it
    notMachine="WX" returns true - no match, so show it
    notMachine="" returns true - no match, so show it
     */
    // We must have a machineProgram object to check against or we return `true`.
    let machineProgram = hash.machineProgram;

    if (machineProgram === undefined) {
      // console.warn('Show-machine returned `true` because no machineProgram was found.');
      return true;
    } // We always compare array elements to be sure we don't match partial strings


    let ifCheck = Ember.typeOf(hash.ifMachine) === "string" ? hash.ifMachine.split(" ") : hash.ifMachine;
    let notMachine = hash.notMachine || hash.notMachine;
    let unlessCheck = Ember.typeOf(notMachine) === "string" ? notMachine.split(" ") : notMachine;
    let hasIf = Array.isArray(ifCheck) && !!ifCheck.length;
    let hasUnless = Array.isArray(unlessCheck) && !!unlessCheck.length; // Many components are wrapped with showMachine but don't have if/unless values to check for. In this case, we immediately return `true`.

    if (!hasIf && !hasUnless) {
      return true;
    } // Everything has been moved to an array of attributes


    let attributes = hash.machineProgram._machineAttributes;
    let hasUnlessCondition = false,
        hasIfCondition = false; // Check each attribute against the "if" and "not" params that were provided.

    attributes.forEach(attribute => {
      if (hasUnless && unlessCheck.includes(attribute)) {
        hasUnlessCondition = true;
      }

      if (hasIf && ifCheck.includes(attribute)) {
        hasIfCondition = true;
      }
    }); // Check conditions and return the appropriate value

    if (hasUnlessCondition && hasIfCondition) {
      // Both conditions met. This shouldn't happen.
      console.warn("Show-machine matched if and not. Enforcing most restrictive setting.", ifCheck, unlessCheck);
      return false; // go with more restrictive setting
    }

    if (hasUnlessCondition) return false; // Always hide an explicit "unless" match first.

    if (hasIfCondition) return true; // Show an explicit "if" match.

    if (hasIf) return false; // Hide when we don't match any "if" criteria.

    if (hasUnless) return true; // Show when we don't match any "not" criteria.

    return false; // We should never get to this line.
  };

  _exports.showMachine = showMachine;
});