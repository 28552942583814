define("alliance-business-suite/components/summary/reports/s-location-config", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "alliance-business-suite/utils/report-tables-util", "alliance-business-suite/utils/currency-format-util"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _reportTablesUtil, _currencyFormatUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Summary::Reports::DefaultReportShim
    @translatedColumns={{this.translatedColumns1}}
    @rowsWithChildren={{this.rowsWithChildren1}} />
  
  <div class="padding-20 border-bottom-report"></div>
  
  <Summary::Reports::DefaultReportShim
    @translatedColumns={{this.translatedColumns2}}
    @rowsWithChildren={{this.rowsWithChildren1}} />
  
  <div class="padding-20 border-bottom-report"></div>
  
  <Summary::Reports::DefaultReportShim
    @translatedColumns={{this.translatedColumns3}}
    @rowsWithChildren={{this.rowsWithChildren1}} />
  
  */
  {
    "id": "Md1M9Kng",
    "block": "{\"symbols\":[],\"statements\":[[8,\"summary/reports/default-report-shim\",[],[[\"@translatedColumns\",\"@rowsWithChildren\"],[[32,0,[\"translatedColumns1\"]],[32,0,[\"rowsWithChildren1\"]]]],null],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"padding-20 border-bottom-report\"],[12],[13],[2,\"\\n\\n\"],[8,\"summary/reports/default-report-shim\",[],[[\"@translatedColumns\",\"@rowsWithChildren\"],[[32,0,[\"translatedColumns2\"]],[32,0,[\"rowsWithChildren1\"]]]],null],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"padding-20 border-bottom-report\"],[12],[13],[2,\"\\n\\n\"],[8,\"summary/reports/default-report-shim\",[],[[\"@translatedColumns\",\"@rowsWithChildren\"],[[32,0,[\"translatedColumns3\"]],[32,0,[\"rowsWithChildren1\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "alliance-business-suite/components/summary/reports/s-location-config.hbs"
  });

  let SummaryReportsSLocationConfigComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, (_class = class SummaryReportsSLocationConfigComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor3, this);
    }

    get translatedColumns1() {
      return [{
        name: 'Market',
        valuePath: 'organization_name',
        width: 200,
        isFixed: 'left'
      }, {
        name: 'Branch',
        width: 180,
        valuePath: 'geom_name'
      }, {
        name: 'Location',
        width: 200,
        valuePath: 'room_name'
      }, {
        name: 'Capacity Utilization Goal',
        valuePath: 'CAPACITY_UTILIZATION_GOAL'
      }, {
        name: 'Productivity Goal',
        valuePath: 'PRODUCTIVITY_GOAL'
      }, {
        name: 'Daily Op Expense Goal',
        valuePath: 'dailyOpExpenseGoal'
      }, {
        name: 'Efficiency Goal',
        valuePath: 'EFFICIENCY_GOAL'
      }, {
        name: 'Target Hours Per Week',
        valuePath: 'TARGET_HOURS_PER_WEEK'
      }, {
        name: 'Washer Productivity',
        valuePath: 'washerProductivity'
      }, {
        name: 'Dryer Productivity',
        valuePath: 'dryerProductivity'
      }, {
        name: 'Stop/Rapid Adv. % Max',
        valuePath: 'STOP_RAPID_ADV_PCT_GOAL'
      }, {
        name: 'Aborted Cycles % Goal',
        valuePath: 'ABORTED_CYCLES_PCT_GOAL'
      }, {
        name: 'Aborted Cycles % Max',
        valuePath: 'STOP_RAPID_ADV_PCT_MAX'
      }, {
        name: 'Unbalanced Cycles % Goal',
        valuePath: 'UNBALANCED_CYCLES_GOAL'
      }, {
        name: 'Unbalanced Cycles % Max',
        valuePath: 'UNBALANCED_CYCLES_MAX'
      }];
    }

    get rowsWithChildren1() {
      let rooms = this.args.model.get('rooms');
      let rowData = [];
      rooms.forEach(room => {
        let roomInfo = { ...room.configValues
        };
        roomInfo.organization_name = this.args.model.get('organizationName');
        roomInfo.room_name = room.roomName;
        roomInfo.geom_name = room.geomName;
        rowData.push(roomInfo);
        let {
          WASHER_PRODUCTIVITY_GOAL_SHIFT_1,
          WASHER_PRODUCTIVITY_GOAL_SHIFT_2,
          WASHER_PRODUCTIVITY_GOAL_SHIFT_3,
          DRYER_PRODUCTIVITY_GOAL_SHIFT_1,
          DRYER_PRODUCTIVITY_GOAL_SHIFT_2,
          DRYER_PRODUCTIVITY_GOAL_SHIFT_3,
          OPERATION_EXPENSE_GOAL_SHIFT_1,
          OPERATION_EXPENSE_GOAL_SHIFT_2,
          OPERATION_EXPENSE_GOAL_SHIFT_3
        } = room.configValues;
        roomInfo.dailyOpExpenseGoal = this.calcSum(OPERATION_EXPENSE_GOAL_SHIFT_1, OPERATION_EXPENSE_GOAL_SHIFT_2, OPERATION_EXPENSE_GOAL_SHIFT_3);
        roomInfo.washerProductivity = this.calcSum(WASHER_PRODUCTIVITY_GOAL_SHIFT_1, WASHER_PRODUCTIVITY_GOAL_SHIFT_2, WASHER_PRODUCTIVITY_GOAL_SHIFT_3);
        roomInfo.dryerProductivity = this.calcSum(DRYER_PRODUCTIVITY_GOAL_SHIFT_1, DRYER_PRODUCTIVITY_GOAL_SHIFT_2, DRYER_PRODUCTIVITY_GOAL_SHIFT_3);
        roomInfo.WATER_COST = _currencyFormatUtil.formatCurrencyString.call(this, roomInfo.WATER_COST || 0);
        roomInfo.GAS_COST_PER_THERM = _currencyFormatUtil.formatCurrencyString.call(this, roomInfo.GAS_COST_PER_THERM || 0);
        roomInfo.SEWER_COST = _currencyFormatUtil.formatCurrencyString.call(this, roomInfo.SEWER_COST || 0);
        roomInfo.ELECTRIC_COST_PER_KWH = _currencyFormatUtil.formatCurrencyString.call(this, roomInfo.ELECTRIC_COST_PER_KWH || 0);
      });
      return rowData;
    }

    get translatedColumns2() {
      return [{
        name: 'Time Between Cycles Goal',
        valuePath: 'TIME_BETWEEN_CYCLES_GOAL'
      }, {
        name: 'Time Between Cycles Max',
        width: 180,
        valuePath: 'TIME_BETWEEN_CYCLES_MAX'
      }, {
        name: 'Max Temp Goal',
        width: 200,
        valuePath: 'MAX_TEMP_GOAL'
      }, {
        name: 'Max Temp Min',
        valuePath: 'MAX_TEMP_MIN'
      }, {
        name: 'Fill Time Goal',
        valuePath: 'FILL_TIME_GOAL'
      }, {
        name: 'Fill Time Max',
        valuePath: 'FILL_TIME_MAX'
      }, {
        name: 'Drain Time Goal',
        valuePath: 'DRAIN_TIME_GOAL'
      }, {
        name: 'Drain Time Max',
        valuePath: 'DRAIN_TIME_MAX'
      }, {
        name: 'Door Open Goal',
        valuePath: 'DOOR_OPEN_GOAL'
      }, {
        name: 'Door Open Max',
        valuePath: 'DOOR_OPEN_MAX'
      }, {
        name: 'Airflow Open Goal',
        valuePath: 'AIRFLOW_OPEN_GOAL'
      }, {
        name: 'Airflow Open Max',
        valuePath: 'AIRFLOW_OPEN_MAX'
      }, {
        name: 'Airflow Closed Goal',
        valuePath: 'AIRFLOW_CLOSED_GOAL'
      }, {
        name: 'Airflow Closed Max',
        valuePath: 'AIRFLOW_CLOSED_MAX'
      }, {
        name: 'Heat Errors Goal',
        valuePath: 'HEAT_ERRORS_GOAL'
      }, {
        name: 'Heat Errors Max',
        valuePath: 'HEAT_ERRORS_MAX'
      }];
    }

    get translatedColumns3() {
      return [{
        name: 'ICM Lockout Goal',
        valuePath: 'ICOM_LOCKOUT_GOAL'
      }, {
        name: 'ICM Lockout Max',
        width: 180,
        valuePath: 'ICM_LOCKOUT_MAX'
      }, {
        name: 'Bearings Max Hours Goal',
        width: 200,
        valuePath: 'BEARINGS_MAX_HOURS_GOAL'
      }, {
        name: 'Gas Cost/Therm',
        valuePath: 'GAS_COST_PER_THERM'
      }, {
        name: 'Sewer Cost / 1,000 Gal',
        valuePath: 'SEWER_COST'
      }, {
        name: 'Ambient Water Temp',
        valuePath: 'AMBIENT_WATER_TEMPERATURE'
      }, {
        name: 'Water Cost / 1,000 Gal',
        valuePath: 'WATER_COST'
      }, {
        name: 'Electric Cost / KWh',
        valuePath: 'ELECTRIC_COST_PER_KWH'
      }, {
        name: 'Shifts Configured',
        valuePath: 'SHIFTS_CONFIGURED'
      }, // ??
      {
        name: 'Report Date',
        valuePath: 'REPORT_DATE'
      } // ??
      ];
    }

    calcSum() {
      let sum = 0;

      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      [...args].forEach(val => {
        if (val) {
          sum += parseInt(val);
        }
      });
      return sum;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SummaryReportsSLocationConfigComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SummaryReportsSLocationConfigComponent);
});