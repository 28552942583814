define("alliance-business-suite/components/customers/c-notes", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3;

  let CustomersCNotesComponent = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember.computed('notes.[]', 'customer'), _dec6 = Ember._action, (_class = class CustomersCNotesComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "viewCreateNoteModal", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "notes", _descriptor3, this);
    }

    fetchCustomerNotes() {
      this.store.findAll('user-note', {
        reload: true,
        adapterOptions: {
          customerId: this.args.customer.id
        }
      }).then(response => {
        this.notes = response;
      });
    }

    get filteredNotes() {
      let customer = this.args.customer;
      let notes = this.notes; // Need to leave this to initialize the computed property above

      if (customer) {
        return this.store.peekAll('user-note', {
          reload: true,
          adapterOptions: {
            customerId: customer.get('id')
          }
        }).filterBy('user.id', customer.id).sortBy('noteDate').reverse();
      } else {
        return [];
      }
    }

    toggleCreateNote() {
      this.viewCreateNoteModal = !this.viewCreateNoteModal;
      Ember.notifyPropertyChange(this, 'notes'); // hack because we are not using a route with a model for this component
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "viewCreateNoteModal", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "notes", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "fetchCustomerNotes", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "fetchCustomerNotes"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "filteredNotes", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "filteredNotes"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "toggleCreateNote", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "toggleCreateNote"), _class.prototype)), _class));
  _exports.default = CustomersCNotesComponent;
});