define("alliance-business-suite/serializers/payment-method", ["exports", "alliance-business-suite/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    /**
     * Hacks a JSON API data object returned within a JSON API document to
     * conform to what is required to make the endpoint work properly with Ember
     * Data and within this application.
     *
     * @param {object} data A data object from a JSON API document
     * @protected
     */
    normalizeDataHack(data) {
      if (data.relationships) {
        delete data.relationships.paymentMethodType;
        delete data.relationships.paymentType;
        delete data.relationships.userAccount;
      }
    },

    /**
     * Hacks a JSON API include object returned within a JSON API document to
     * confirm to what is required to make the endpoint work property with Ember
     * Data and within this application.
     *
     * @param  {object} include An included data object from a JSON API document
     *
     * @return {object} The data object to use for this include when passing it
     *                  to the store; or undefined if this include should be
     *                  ignored.
     *
     * @protected
     */
    normalizeInclude(include) {
      return ['payment-types', 'user-roles', 'payment-gateways', 'payment-method-types', 'user-accounts', 'user-account'].includes(include.type) ? undefined : include;
    }

  });

  _exports.default = _default;
});