define("alliance-business-suite/components/dashboard/charts/turns-per-day/turns-per-day-container", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "alliance-business-suite/utils/summary-util"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _summaryUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <section class="content-container charts">
    <header>
      <h2>
        {{t "turns_per_day"}}
      </h2>
  
      {{#can-access "SUMMARY_CHARTS_VIEW_TURNS_PER_DAY_KPI"}}
        <div class="controls controls--right">
          <button
            type="button"
            {{on "click" @toggleTurnsPerDayOptions}}
            class="button button--secondary button--small {{if @viewTurnsPerDayOptions "btn-pressed"}}">
              <i class="fa fa-cog" aria-hidden="true"></i>
          </button>
        </div>
      {{/can-access}}
    </header>
  
    {{#liquid-if @viewTurnsPerDayOptions}}
      <Dashboard::Charts::TurnsPerDay::TurnsPerDayDetailsControl
        @groupRoomKPI={{this.groupRoomKPI}}
        @groupTurnsKPI={{this.groupTurnsKPI}}
        @hasGroupTurnsKPI={{this.hasGroupTurnsKPI}}
        @initRoom={{@initRoom}}
        @saveTurnsKPI={{@saveTurnsKPI}}
        @toggleTurnsPerDayKPI={{@toggleTurnsPerDayKPI}}
        @turnsKPI={{@turnsKPI}}
        @turnsKPIBtnDisabled={{@turnsKPIBtnDisabled}}
        @updateRoomKPI={{@updateRoomKPI}}
      />
    {{/liquid-if}}
  
    <Dashboard::Charts::TurnsPerDay::TurnsPerDayChart
      @results={{@results}}
      @groupTurnsKPI={{this.groupTurnsKPI}}
      @theme={{@theme}}
      @toggleTurnsPerDayKPI={{@toggleTurnsPerDayKPI}}
      @turnsKPI={{@turnsKPI}}
      @isLoaded={{@isLoaded}}
      @chartTotal={{@dailyTurnsTotal}}
    />
  </section>
  
  */
  {
    "id": "rn3CUI2G",
    "block": "{\"symbols\":[\"@initRoom\",\"@saveTurnsKPI\",\"@toggleTurnsPerDayKPI\",\"@turnsKPI\",\"@turnsKPIBtnDisabled\",\"@updateRoomKPI\",\"@viewTurnsPerDayOptions\",\"@toggleTurnsPerDayOptions\",\"@results\",\"@theme\",\"@isLoaded\",\"@dailyTurnsTotal\"],\"statements\":[[10,\"section\"],[14,0,\"content-container charts\"],[12],[2,\"\\n  \"],[10,\"header\"],[12],[2,\"\\n    \"],[10,\"h2\"],[12],[2,\"\\n      \"],[1,[30,[36,2],[\"turns_per_day\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\\n\"],[6,[37,3],[\"SUMMARY_CHARTS_VIEW_TURNS_PER_DAY_KPI\"],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"controls controls--right\"],[12],[2,\"\\n        \"],[11,\"button\"],[16,0,[31,[\"button button--secondary button--small \",[30,[36,0],[[32,7],\"btn-pressed\"],null]]]],[24,4,\"button\"],[4,[38,1],[\"click\",[32,8]],null],[12],[2,\"\\n            \"],[10,\"i\"],[14,0,\"fa fa-cog\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\\n\"],[6,[37,4],[[32,7]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"dashboard/charts/turns-per-day/turns-per-day-details-control\",[],[[\"@groupRoomKPI\",\"@groupTurnsKPI\",\"@hasGroupTurnsKPI\",\"@initRoom\",\"@saveTurnsKPI\",\"@toggleTurnsPerDayKPI\",\"@turnsKPI\",\"@turnsKPIBtnDisabled\",\"@updateRoomKPI\"],[[32,0,[\"groupRoomKPI\"]],[32,0,[\"groupTurnsKPI\"]],[32,0,[\"hasGroupTurnsKPI\"]],[32,1],[32,2],[32,3],[32,4],[32,5],[32,6]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,\"dashboard/charts/turns-per-day/turns-per-day-chart\",[],[[\"@results\",\"@groupTurnsKPI\",\"@theme\",\"@toggleTurnsPerDayKPI\",\"@turnsKPI\",\"@isLoaded\",\"@chartTotal\"],[[32,9],[32,0,[\"groupTurnsKPI\"]],[32,10],[32,3],[32,4],[32,11],[32,12]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"on\",\"t\",\"can-access\",\"liquid-if\"]}",
    "moduleName": "alliance-business-suite/components/dashboard/charts/turns-per-day/turns-per-day-container.hbs"
  });

  let DashboardChartsTurnsPerDayTurnsPerDayContainerComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._action, (_class = class DashboardChartsTurnsPerDayTurnsPerDayContainerComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "canAccess", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "groupTurnsKPI", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "hasGroupTurnsKPI", _descriptor4, this);
    }

    groupRoomKPI(kpiType) {
      _summaryUtil.groupRoomKPI.call(this, kpiType);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "canAccess", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "groupTurnsKPI", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "hasGroupTurnsKPI", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "groupRoomKPI", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "groupRoomKPI"), _class.prototype)), _class));
  _exports.default = DashboardChartsTurnsPerDayTurnsPerDayContainerComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, DashboardChartsTurnsPerDayTurnsPerDayContainerComponent);
});