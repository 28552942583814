define("alliance-business-suite/components/machines/programs/advanced/acas-wx-flw", ["exports", "alliance-business-suite/utils/machine-programming-find-options"], function (_exports, _machineProgrammingFindOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    machineAuditType: Ember.computed.alias('machineProgram.machineAuditType.id'),
    lowPowerStartIndex: Ember.computed('machineAuditType', function () {
      switch (this.machineAuditType) {
        case "CENTURION_C4":
        case "CENTURION_C4_ALS_DRIVE":
          return "A";

        default:
          return 0;
      }
    }),
    lowPowerCount: Ember.computed('machineAuditType', function () {
      switch (this.machineAuditType) {
        case "ACAS_A44":
          return 1;

        case "ACAS_A2":
        case "ACAS_A22":
        case "TITAN_T1":
        case "TITAN_T2":
          return 2;

        default:
          return 4;
      }
    }),
    optionsAvailability: (0, _machineProgrammingFindOptions.findOptions)([{
      machineAttributes: ["CENTURION_C3"],
      options: [{
        val: 0,
        label: 'in_ready_mode'
      }, {
        val: 1,
        label: 'until_start_mode'
      }, {
        val: 2,
        label: 'in_ready_mode_EOC'
      }, {
        val: 3,
        label: 'until_start_mode_EOC'
      }, {
        val: 4,
        label: 'inready_start_mode'
      }, {
        val: 5,
        label: 'until_run_mode'
      }, {
        val: 6,
        label: 'in_ready_start_mode_EOC'
      }, {
        val: 7,
        label: 'until_run_mode_EOC'
      }]
    }, {
      default: true,
      options: [{
        val: 0,
        label: "availablity_available"
      }, {
        val: 1,
        label: "availablity_available_with_start"
      }, {
        val: 2,
        label: "availablity_available_with_end_of_cycle"
      }, {
        val: 3,
        label: "availablity_available_with_start_and_end_of_cycle"
      }, {
        val: 4,
        label: "availablity_vend_is_not_satisfied"
      }, {
        val: 5,
        label: "availablity_vend_is_not_satisfied_with_end_of_cycle"
      }, {
        val: 6,
        label: "availablity_available_with_vend"
      }, {
        val: 7,
        label: "availablity_available_with_vend_and_end_of_cycle"
      }, {
        val: 8,
        label: "availablity_machine_is_in_use"
      }, {
        val: 9,
        label: "availablity_cycle_is_complete"
      }, {
        val: 10,
        label: "availablity_lucky_cycle"
      }, {
        val: 11,
        label: "availablity_break_in_alarm"
      }, {
        val: 12,
        label: "availablity_end_of_cycle_is_entered"
      }, {
        val: 13,
        label: "availablity_motor_running"
      }]
    }]),
    options: {
      startPulseMode: [{
        val: 0,
        label: 'Off',
        key: 'off'
      }, {
        val: 1,
        label: 'SinglePulse',
        key: 'single'
      }, {
        val: 2,
        label: 'MultiplePulses',
        key: 'multiple'
      }]
    }
  });

  _exports.default = _default;
});