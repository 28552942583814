define("alliance-business-suite/mixins/machines/programs/mc10-options", ["exports", "alliance-business-suite/components/machines/programs/variables", "alliance-business-suite/utils/computed-find-options"], function (_exports, _variables, _computedFindOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    // eslint-disable-line ember/no-new-mixins
    machineTypeId: Ember.computed('machineProgram.machineType.id', function () {
      return Number(Ember.get(this, "machineProgram.machineType.id"));
    }),
    isSmallMachine: Ember.computed('machineTypeId', function () {
      return _variables.default.MC10_OPL.smallMachineSize.includes(this.machineTypeId);
    }),
    isMidLargeMachine: Ember.computed('machineTypeId', function () {
      return _variables.default.MC10_OPL.midLargeMachineSize.includes(this.machineTypeId);
    }),
    isCelsius: Ember.computed.alias('machineProgram._isCelsius'),
    optionsStepType: (0, _computedFindOptions.findOptions)([{
      label: "Empty",
      hex: "0x00",
      val: 0,
      ifMachine: "MC10_COMBO",
      notMachine: "OPL"
    }, {
      label: "Prewash",
      hex: "0x0D",
      val: 13,
      notMachine: "MC10_OPL_WX_MID_LARGE_MACHINE"
    }, {
      label: "Wash",
      hex: "0x0E",
      val: 14
    }, {
      label: "CoolDown",
      hex: "0x07",
      val: 7
    }, {
      label: "Rinse",
      hex: "0x0F",
      val: 15
    }, {
      label: "FinalRinse",
      hex: "0x10",
      val: 16,
      notMachine: "MC10_OPL_WX_MID_LARGE_MACHINE"
    }, {
      label: "Soak",
      hex: "0x06",
      val: 6
    }, {
      label: "NoWash",
      hex: "0x11",
      val: 17
    }, {
      label: "Tumble",
      hex: "0x12",
      val: 18
    }, {
      label: "Rinse2",
      hex: "0x13",
      val: 19,
      ifMachine: "MC10_COMBO"
    }, {
      label: "TumbleDry",
      hex: "0x14",
      val: 20,
      ifMachine: "MC10_COMBO"
    }]),
    optionValveType: Ember.computed('machineProgram.settings.Program.Misc.DrainValve2Enabled', function () {
      let drainValve2Enabled = this.machineProgram.settings.Program.Misc?.DrainValve2Enabled;

      if (drainValve2Enabled) {
        return [{
          label: "Valve1",
          val: 1
        }, {
          label: "Valve2",
          val: 2
        }, {
          label: "Both",
          val: 3
        }];
      } else {
        return [{
          label: "Valve1",
          val: 1
        }];
      }
    }),
    // Door lock settings change based on the machine size.
    optionsDoorLockOpl: Ember.computed('isMidLargeMachine', function () {
      if (this.isSmallMachine) {
        return [{
          label: "Candrion",
          val: 1
        }, {
          label: "USType",
          val: 2
        }];
      } else if (this.isMidLargeMachine) {
        return [{
          label: "MidSizeFX",
          val: 3
        }];
      } else {
        return [];
      }
    }),
    optionsWeighingOptions: Ember.computed("isMidLargeMachine", function () {
      let options = [{
        label: "No",
        val: 0
      }, {
        label: "Manual",
        val: 2
      }];

      if (this.isMidLargeMachine) {
        options.push({
          label: "Auto",
          val: 1,
          ifMachine: "MC10_OPL_WX_MID_LARGE_MACHINE"
        });
      } else {
        options.push({
          label: "UltraBalance",
          val: 3,
          notMachine: "MC10_OPL_WX_MID_LARGE_MACHINE"
        });
      }

      if (this.machineProgram._machineAttributes.includes("ISPO")) {
        options.push({
          label: "SmartWAVe",
          val: 4,
          ifMachine: "IPSO"
        });
      }

      return options;
    }),
    optionsDryTemperature: Ember.computed('machineProgram.settings.Program.Cycle.{LowTemperature,MediumTemperature,HotTemperature}', function () {
      return [{
        label: "NoHeat",
        val: 0
      }, {
        label: "Low",
        val: 1,
        presetValue: this.machineProgram.settings.Program.Cycle.LowTemperature
      }, {
        label: "Medium",
        val: 2,
        presetValue: this.machineProgram.settings.Program.Cycle.MediumTemperature
      }, {
        label: "High",
        val: 3,
        presetValue: this.machineProgram.settings.Program.Cycle.HighTemperature
      }];
    }),
    selectOptions: Ember.computed('machineProgram.settings.Program.Cycle.{ColdWaterTemp,WarmWaterTemp,HotWaterTemp,LowWaterLevel,MediumWaterLevel,HighWaterLevel,LowTemperature,MediumTemperature,HighTemperature}', function () {
      // These options are used for the "select" form controls. The values come from a collection of enumeration values in the Windows app DLL.
      // We're using a CP because some values will be calculated.
      return {
        WeighingOptions: [{
          label: "No",
          val: 0
        }, {
          label: "Auto",
          val: 1,
          ifMachine: "MC10_OPL_WX_MID_LARGE_MACHINE"
        }, {
          label: "Manual",
          val: 2
        }, {
          label: "UltraBalance",
          val: 3,
          notMachine: "MC10_OPL_WX_MID_LARGE_MACHINE"
        }, {
          label: "SmartWAVe",
          val: 4,
          ifMachine: "IPSO"
        }],
        // SmartWAVe shows up for brands IPSO or JLA. I don't think we have either, so this one is effectively always No/0
        WeightEntry: [{
          label: "Empty",
          val: 0
        }, {
          label: "LastUsed",
          val: 1
        }, {
          label: "Predefined",
          val: 2
        }],
        WeightUnits: [{
          label: "Kilograms",
          val: 0
        }, {
          label: "Pounds",
          val: 1
        }],
        WeighingWaterLevelUnits: [{
          label: "units",
          val: 0
        }, {
          label: "liters",
          val: 1
        }, {
          label: "liters_per_kg",
          val: 2
        }],
        DefaultWeightEntry: [],
        BatchEntry: [{
          label: "Empty",
          val: 0
        }, {
          label: "LastUsed",
          val: 1
        }, {
          label: "Predefined",
          val: 2
        }],
        WaterPressure: [{
          label: "low",
          val: 0
        }, {
          label: "medium",
          val: 1
        }, {
          label: "high",
          val: 2
        }],
        DoorLock: [{
          label: "Candrion",
          val: 1
        }, {
          label: "USType",
          val: 2
        }, {
          label: "MidSizeFX",
          val: 3
        }, {
          label: "MC70_180",
          val: 4
        }, {
          label: "MXB",
          val: 5
        }],
        HeatSource: [{
          label: "None",
          val: 0
        }, {
          label: "Electrical",
          val: 1
        }, {
          label: "Steam",
          val: 2
        }, {
          label: "Gas",
          val: 3
        }],
        HeatSourceOPL: [// { label: "None", val: 0 },
        {
          label: "Electrical",
          val: 1
        }, {
          label: "Steam",
          val: 2
        }, {
          label: "Gas",
          val: 4
        }],
        SupplySignal: [{
          label: "box",
          val: 0
        }, {
          label: "liquid",
          val: 1
        }],
        SupplySignalBoolean: [{
          label: "box",
          val: false
        }, {
          label: "liquid",
          val: true
        }],
        TiltingSystem: [{
          label: "Off",
          val: 0
        }, {
          label: "One",
          val: 1
        }, {
          label: "Two",
          val: 2
        }],
        TemperatureUnits: [{
          val: true,
          label: 'Celsius'
        }, {
          val: false,
          label: 'Fahrenheit'
        }],
        StepTypeOpl: [// StepType TODO: Values are NOT correct
        // { label: "Empty", hex: "0x00", val: 0 },
        {
          label: "Soak",
          hex: "0x06",
          val: 6
        }, {
          label: "Prewash",
          hex: "0x0D",
          val: 13,
          notMachine: "MC10_OPL_WX_MID_LARGE_MACHINE"
        }, {
          label: "Wash",
          hex: "0x0E",
          val: 14
        }, {
          label: "NoWash",
          hex: "0x11",
          val: 17
        }, {
          label: "Rinse",
          hex: "0x0F",
          val: 15
        }, {
          label: "CoolDown",
          hex: "0x07",
          val: 7
        }, {
          label: "FinalRinse",
          hex: "0x10",
          val: 16,
          notMachine: "MC10_OPL_WX_MID_LARGE_MACHINE"
        }, {
          label: "Tumble",
          hex: "0x12",
          val: 18
        } // { label: "Rinse2", hex: "0x12", val: 19 }, // This one is in the SQLite db, but not in enum.
        ],
        ActiveState: [{
          label: "Disabled",
          val: 0
        }, {
          label: "ActiveMod1",
          val: 1
        }, {
          label: "ActiveMod2",
          val: 2
        }, {
          label: "InactiveSpeedCycle",
          val: 3
        }, {
          label: "ActiveOzone",
          val: 4
        }, {
          label: "InactiveOzone",
          val: 5
        }, {
          label: "AlwaysEnabled",
          val: 6
        }],
        AudioPattern: [{
          label: "NoTone",
          val: 0
        }, {
          label: "SolidHigh",
          val: 1
        }, {
          label: "SolidMedium",
          val: 2
        }, {
          label: "SolidLow",
          val: 3
        }, {
          label: "BeepHigh",
          val: 4
        }, {
          label: "BeepMedium",
          val: 5
        }, {
          label: "BeepLow",
          val: 6
        }, {
          label: "DoubleBeepHigh",
          val: 7
        }, {
          label: "DoubleBeepMedium",
          val: 8
        }, {
          label: "DoubleBeepLow",
          val: 9
        }, {
          label: "SOSHigh",
          val: 10
        }, {
          label: "SOSMedium",
          val: 11
        }, {
          label: "SOSLow",
          val: 12
        }, {
          label: "IncreasingTone",
          val: 13
        }, {
          label: "SineTone",
          val: 14
        }, {
          label: "Melody1",
          val: 15
        }, {
          label: "Melody2",
          val: 16
        }, {
          label: "Melody3",
          val: 17
        }, {
          label: "Melody4",
          val: 18
        }, {
          label: "Melody5",
          val: 19
        }, {
          label: "Melody6",
          val: 20
        }],
        AvailableType: [{
          label: "Available",
          val: 0
        }, {
          label: "AvailableWithStart",
          val: 1
        }, {
          label: "AvailableWithEOC",
          val: 2
        }, {
          label: "AvailableWithStartAndEOC",
          val: 3
        }, {
          label: "VendNotSatisfied",
          val: 4
        }, {
          label: "VendNotSatisfiedAndEOC",
          val: 5
        }, {
          label: "AvaiableWithVend",
          val: 6
        }, {
          label: "AvailableWithVendAndEOC",
          val: 7
        }, {
          label: "InUse",
          val: 8
        }, {
          label: "CycleComplete",
          val: 9
        }, {
          label: "LuckyCycleTriggered",
          val: 10
        }, {
          label: "BreakinTriggered",
          val: 11
        }, {
          label: "EndOfCycle",
          val: 12
        }, {
          label: "MotorOn",
          val: 13
        }],
        CurrencyType: [{
          label: "None",
          val: 0,
          abbr: ""
        }, {
          label: "Dollar",
          val: 1,
          abbr: "$"
        }, {
          label: "Pound",
          val: 2,
          abbr: "£"
        }, {
          label: "Yen",
          val: 3,
          abbr: "¥"
        }, {
          label: "Won",
          val: 4,
          abbr: "₩"
        }, {
          label: "Euro",
          val: 5,
          abbr: "€"
        }, {
          label: "Rupee",
          val: 6,
          abbr: "₹"
        }, {
          label: "TurkishLira",
          val: 7,
          abbr: "₺"
        }, {
          label: "Ruble",
          val: 8,
          abbr: "₽"
        }, {
          label: "Forint",
          val: 9,
          abbr: "Ft"
        }, {
          label: "Koruna",
          val: 10,
          abbr: "Kč"
        }, {
          label: "Krona",
          val: 11,
          abbr: "kr"
        }, {
          label: "Kuna",
          val: 12,
          abbr: "kn"
        }, {
          label: "Rand",
          val: 13,
          abbr: "R"
        }, {
          label: "Real",
          val: 14,
          abbr: "R$"
        }, {
          label: "Token",
          val: 15,
          abbr: "Tk"
        }, {
          label: "Zloty",
          val: 16,
          abbr: "zł"
        }, {
          label: "Rupiah",
          val: 17,
          abbr: "Rp"
        }, {
          label: "Ringgit",
          val: 18,
          abbr: "RM"
        }, {
          label: "Baht",
          val: 19,
          abbr: "฿"
        }, {
          label: "Kip",
          val: 20,
          abbr: "₭"
        }, {
          label: "Kyat",
          val: 21,
          abbr: "K"
        }, {
          label: "Dong",
          val: 22,
          abbr: "₫"
        }],
        CycleBundle: [{
          label: "NoneLightRegular",
          val: 0
        }, {
          label: "OneMediumDeluxe",
          val: 1
        }, {
          label: "TwoHeavyUltra",
          val: 2
        }],
        CycleEndType: [{
          label: "Normal",
          val: 1
        }, {
          label: "Stopped",
          val: 2
        }, {
          label: "RapidAdvance",
          val: 3
        }, {
          label: "Error",
          val: 4
        }, {
          label: "Powerfail",
          val: 5
        }],
        CycleModifiedType: [{
          label: "Time",
          val: 1
        }, {
          label: "Temp",
          val: 2
        }, {
          label: "DrynessLevel",
          val: 3
        }, {
          label: "MoistureLevel",
          val: 4
        }, {
          label: "CustomSave6",
          val: 5
        }],
        // `key` is used for updating `TempOptions`
        CycleTemp: [{
          label: "Cold",
          val: 0,
          key: "Cold"
        }, {
          label: "Warm",
          val: 1,
          key: "Warm"
        }, {
          label: "Hot",
          val: 2,
          key: "Hot"
        }, {
          label: "NoHeat",
          val: 3,
          key: "NoHeat"
        }, {
          label: "15C",
          val: 4,
          key: "t15C"
        }, {
          label: "30C",
          val: 5,
          key: "t30C"
        }, {
          label: "40C",
          val: 6,
          key: "t40C"
        }, {
          label: "50C",
          val: 7,
          key: "t50C"
        }, {
          label: "60C",
          val: 8,
          key: "t60C"
        }, {
          label: "90C",
          val: 9,
          key: "t90C"
        }],
        DateOrder: [{
          label: "Month_Day_Year",
          val: 0
        }, {
          label: "Day_Month_Year",
          val: 1
        }, {
          label: "Year_Month_Day",
          val: 2
        }],
        DateSeperator: [{
          label: "Slash",
          val: 0
        }, {
          label: "Period",
          val: 1
        }, {
          label: "Dash",
          val: 2
        }],
        DrainSelection: [{
          label: "Drain1",
          val: 1
        }, {
          label: "Drain2",
          val: 2
        }, {
          label: "Drain1And2",
          val: 3
        }],
        DrainType: [{
          label: "Drain",
          val: 1
        }, {
          label: "Spin",
          val: 2
        }, {
          label: "NoDrain",
          val: 3
        }, {
          label: "StaticDrain",
          val: 4
        }, {
          label: "ReversingDrain",
          val: 5
        }],
        HeatOption: [{
          label: "NoHeat",
          val: 0
        }, {
          label: "Target",
          val: 1
        }, {
          label: "Maintain",
          val: 2
        }],
        HoldMessage: [{
          label: "Load",
          val: 0
        }, {
          label: "Chemical",
          val: 1
        }, {
          label: "Soap",
          val: 2
        }, {
          label: "Bleach",
          val: 3
        }, {
          label: "Softener",
          val: 4
        }, {
          label: "Sour",
          val: 5
        }, {
          label: "Starch",
          val: 6
        }, {
          label: "Custom1",
          val: 7
        }, {
          label: "Custom2",
          val: 8
        }],
        Language: [{
          label: "English",
          val: 1
        }, {
          label: "SpanishEuropean",
          val: 2
        }, {
          label: "SpanishColumbian",
          val: 3
        }, {
          label: "FrenchEuropean",
          val: 4
        }, {
          label: "ChineseSimplified",
          val: 5
        }, {
          label: "ChineseTraditional",
          val: 6
        }, {
          label: "Russian",
          val: 7
        }, {
          label: "German",
          val: 8
        }, {
          label: "JapaneseStandard",
          val: 9
        }, {
          label: "Italian",
          val: 10
        }, {
          label: "PortugueseEuropean",
          val: 11
        }, {
          label: "PortugueseBrazilian",
          val: 12
        }, {
          label: "Korean",
          val: 13
        }, {
          label: "Dutch",
          val: 14
        }, {
          label: "Czech",
          val: 15
        }, {
          label: "Finnish",
          val: 16
        }, {
          label: "Danish",
          val: 17
        }, {
          label: "Swedish",
          val: 18
        }, {
          label: "Norwegian",
          val: 19
        }, {
          label: "Polish",
          val: 20
        }, {
          label: "Slovene",
          val: 21
        }, {
          label: "Romanian",
          val: 22
        }, {
          label: "Croatian",
          val: 23
        }, {
          label: "Hungarian",
          val: 24
        }, {
          label: "Latvian",
          val: 25
        }, {
          label: "Lithuanian",
          val: 26
        }, {
          label: "Slovak",
          val: 27
        }, {
          label: "Greek",
          val: 28
        }, {
          label: "Bulgarian",
          val: 29
        }, {
          label: "Turkish",
          val: 30
        }, {
          label: "Indonesian",
          val: 31
        }, {
          label: "Malay",
          val: 32
        }, {
          label: "Thai",
          val: 33
        }, {
          label: "Vietnamese",
          val: 34
        }],
        ManualAccessType: [{
          label: "Disabled",
          val: 0
        }, {
          label: "Enabled",
          val: 1
        }, {
          label: "Passcode",
          val: 2
        }],
        ManualAccessTypeOPL: [// TODO: Stop using this property once the OPL machine is fixed.
        {
          label: "Disabled",
          val: 0
        }, {
          label: "Enabled",
          val: 1
        } //   { label: "Passcode", val: 2 }
        ],
        ModifierName: [{
          label: "LightMediumHeavy",
          val: 1
        }, {
          label: "RegularDeluxeUltra",
          val: 2
        }, {
          label: "Custom",
          val: 3,
          isCustom: true
        }],
        MutexMode: [{
          label: "ReadyMode",
          hex: "0x02",
          val: 2
        }, {
          label: "PartialVendMode",
          hex: "0x03",
          val: 3
        }, {
          label: "StartMode",
          hex: "0x04",
          val: 4
        }, {
          label: "RunMode",
          hex: "0x0A",
          val: 10
        }, {
          label: "DoorLockMode",
          hex: "0x20",
          val: 32
        }, {
          label: "DoorUnlockMode",
          hex: "0x1A",
          val: 26
        }, {
          label: "PauseMode",
          hex: "0x21",
          val: 33
        }, {
          label: "EndOfCycle",
          hex: "0x0B",
          val: 11
        }, {
          label: "ErrorMode",
          hex: "0x0C",
          val: 12
        }],
        //   Program.Display.NumericSeparator
        NumericSeparator: [// { label: "None", val: 255, decimalPlaces: 0, decimalSymbol: '', divideBy: 1 }, // TODO: Remove this once we know why this value showed up in defaults
        {
          label: "None",
          val: 0,
          decimalPlaces: 0,
          decimalSymbol: '',
          divideBy: 1
        }, {
          label: "Dot1",
          val: 1,
          decimalPlaces: 1,
          decimalSymbol: '.',
          divideBy: 10
        }, {
          label: "Dot2",
          val: 2,
          decimalPlaces: 2,
          decimalSymbol: '.',
          divideBy: 100
        }, {
          label: "Dot3",
          val: 3,
          decimalPlaces: 3,
          decimalSymbol: '.',
          divideBy: 1000
        }, {
          label: "Comma1",
          val: 4,
          decimalPlaces: 1,
          decimalSymbol: ',',
          divideBy: 10
        }, {
          label: "Comma2",
          val: 5,
          decimalPlaces: 2,
          decimalSymbol: ',',
          divideBy: 100
        }, {
          label: "Comma3",
          val: 6,
          decimalPlaces: 3,
          decimalSymbol: ',',
          divideBy: 1000
        }],
        OptisprayTimeOption: [{
          label: "CustomTime",
          val: 0
        }, {
          label: "Towels",
          val: 1
        }, {
          label: "Sheets",
          val: 2
        }, {
          label: "General",
          val: 3
        }],
        PocketIndicator: [{
          label: "Single",
          val: 0
        }, {
          label: "Upper",
          val: 1
        }, {
          label: "Lower",
          val: 2
        }],
        StepLabel: [{
          label: "Prewash1",
          val: 0
        }, {
          label: "Wash1",
          val: 1
        }, {
          label: "Rinse1",
          val: 2
        }, {
          label: "Rinse2",
          val: 3
        }, {
          label: "Rinse3",
          val: 4
        }, {
          label: "Rinse4",
          val: 5
        }, {
          label: "Spin",
          val: 6
        }, {
          label: "Bleach",
          val: 7
        }, {
          label: "Prewash2",
          val: 8
        }, {
          label: "Prewash3",
          val: 9
        }, {
          label: "Wash2",
          val: 10
        }, {
          label: "Wash3",
          val: 11
        }, {
          label: "Wash4",
          val: 12
        }],
        StepType: [// StepType TODO: Values are NOT correct
        {
          label: "Prewash",
          hex: "0x0D",
          val: 13
        }, {
          label: "Wash",
          hex: "0x0E",
          val: 14
        }, {
          label: "CoolDown",
          hex: "0x07",
          val: 7
        }, {
          label: "Rinse",
          hex: "0x0F",
          val: 15
        }, {
          label: "FinalRinse",
          hex: "0x10",
          val: 16
        }, {
          label: "Soak",
          hex: "0x06",
          val: 6
        }, {
          label: "NoWash",
          hex: "0x11",
          val: 17
        }, {
          label: "Tumble",
          hex: "0x12",
          val: 18
        }],
        TemperatureType: [{
          label: "NoHeat",
          hex: "0x00",
          val: 0
        }, {
          label: "GlobalLow",
          hex: "0x01",
          val: 1,
          presetValue: Ember.get(this, 'machineProgram.settings.Program.Cycle.LowTemperature')
        }, {
          label: "GlobalMedium",
          hex: "0x02",
          val: 2,
          presetValue: Ember.get(this, 'machineProgram.settings.Program.Cycle.MediumTemperature')
        }, {
          label: "GlobalHigh",
          hex: "0x03",
          val: 3,
          presetValue: Ember.get(this, 'machineProgram.settings.Program.Cycle.HotTemperature')
        } // { label: "Custom", hex: "0x04", val: 4 }, // only option for wash steps, only
        ],
        ComboCycleNames: [{
          label: "ComboNormalLoad",
          val: 0
        }, {
          label: "ComboSmallLoad",
          val: 1
        }, {
          label: "ComboWashOnly",
          val: 2
        }, {
          label: "ComboDryOnly",
          val: 3
        }, {
          label: "ComboRinse",
          val: 4
        }],
        SupplyCondition: [{
          label: "TimeExpires",
          val: 0
        }, {
          label: "WaterLevelReached",
          val: 1
        }, {
          label: "SmartWAVe",
          val: 2
        }],
        TemperatureType: [{
          label: "GlobalLow",
          val: 1,
          presetValue: Ember.get(this, 'machineProgram.settings.Program.Cycle.ColdWaterTemp')
        }, {
          label: "GlobalWarm",
          val: 2,
          presetValue: Ember.get(this, 'machineProgram.settings.Program.Cycle.WarmWaterTemp')
        }, {
          label: "GlobalHot",
          val: 3,
          presetValue: Ember.get(this, 'machineProgram.settings.Program.Cycle.HotWaterTemp')
        }, {
          label: "Custom",
          val: 4,
          isCustom: true
        }],
        TopoffEnable: [{
          label: "Disabled",
          val: 0
        }, {
          label: "Enabled",
          val: 1
        }, {
          label: "FullCycle",
          val: 2
        }],
        ValveType: [{
          label: "Valve1",
          val: 1
        }, {
          label: "Valve2",
          val: 2
        }, {
          label: "Both",
          val: 3
        }],
        VoltageType: [{
          label: "V3x380_415V_N",
          val: 1
        }, {
          label: "V1x220_240V",
          val: 2
        }, {
          label: "V3x220_240V",
          val: 3
        }, {
          label: "V3x380_480V_N",
          val: 4
        }, {
          label: "V1x208_240V",
          val: 5
        }, {
          label: "V3x208_240V",
          val: 6
        }, {
          label: "V3x380_480V",
          val: 7
        }, {
          label: "VTTL",
          val: 8
        }],
        WaitControl: [{
          label: "No",
          val: 0
        }, {
          label: "Soap",
          val: 1
        }, {
          label: "Heating",
          val: 2
        }, {
          label: "WaitTime",
          val: 3
        }],
        WaterLevelType: [{
          label: "GlobalLow",
          val: 1,
          presetValue: Ember.get(this, 'machineProgram.settings.Program.Cycle.LowWaterLevel')
        }, {
          label: "GlobalMedium",
          val: 2,
          presetValue: Ember.get(this, 'machineProgram.settings.Program.Cycle.MediumWaterLevel')
        }, {
          label: "GlobalHigh",
          val: 3,
          presetValue: Ember.get(this, 'machineProgram.settings.Program.Cycle.HighWaterLevel')
        }, {
          label: "Custom",
          val: 4,
          isCustom: true
        }],
        PlaceAfter: [{
          val: true,
          label: 'Symbol after currency'
        }, {
          val: false,
          label: 'Symbol before currency'
        }],
        // These are for daylight saving. Can probably move these into the comp and provide override...
        Months: [{
          label: "January",
          val: 1
        }, {
          label: "February",
          val: 2
        }, {
          label: "March",
          val: 3
        }, {
          label: "April",
          val: 4
        }, {
          label: "May",
          val: 5
        }, {
          label: "June",
          val: 6
        }, {
          label: "July",
          val: 7
        }, {
          label: "August",
          val: 8
        }, {
          label: "September",
          val: 9
        }, {
          label: "October",
          val: 10
        }, {
          label: "November",
          val: 11
        }, {
          label: "December",
          val: 12
        }],
        Weeks: [{
          label: "Week1",
          val: 0
        }, {
          label: "Week2",
          val: 1
        }, {
          label: "Week3",
          val: 2
        }, {
          label: "Week4",
          val: 3
        }, {
          label: "Week5",
          val: 4
        }],
        Weekdays: [{
          label: "Sunday",
          val: 1
        }, {
          label: "Monday",
          val: 2
        }, {
          label: "Tueday",
          val: 3
        }, {
          label: "Wednesday",
          val: 4
        }, {
          label: "Thursday",
          val: 5
        }, {
          label: "Friday",
          val: 6
        }, {
          label: "Saturday",
          val: 7
        }],
        // These are for cycle names in Special Vend. Can probably get moved to Special Vend.
        // TODO: What happens if the user deletes a cycle?
        CyclesWx: [{
          val: 1,
          label: "Cycle price 1",
          index: 0
        }, {
          val: 2,
          label: "Cycle price 2",
          index: 1
        }, {
          val: 3,
          label: "Cycle price 3",
          index: 2
        }, {
          val: 4,
          label: "Cycle price 4",
          index: 3
        }, {
          val: 5,
          label: "Cycle price 5",
          index: 4
        }, {
          val: 6,
          label: "Cycle price 6",
          index: 5
        }, {
          val: 7,
          label: "Cycle price 7",
          index: 6
        }, {
          val: 8,
          label: "Cycle price 8",
          index: 7
        }, {
          val: 9,
          label: "Cycle price 9",
          index: 8
        }, {
          val: 10,
          label: "Cycle price 10",
          index: 9
        }, {
          val: 11,
          label: "Cycle price 11",
          index: 10
        }, {
          val: 12,
          label: "Cycle price 12",
          index: 11
        }, {
          val: 13,
          label: "Cycle price 13",
          index: 12
        }, {
          val: 14,
          label: "Cycle price 14",
          index: 13
        }, {
          val: 15,
          label: "Cycle price 15",
          index: 14
        }]
      };
    })
  });

  _exports.default = _default;
});