define("alliance-business-suite/utils/led", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.LED_HEX2CHAR = _exports.LED_CHAR2HEX = void 0;
  _exports.base64ToDecimalArray = base64ToDecimalArray;
  _exports.hexToBase64 = hexToBase64;
  _exports.hexToBinary = hexToBinary;
  _exports.ledCodeToString = ledCodeToString;
  _exports.stringToLedCode = stringToLedCode;

  function base64ToDecimalArray(base64String) {
    let raw;

    try {
      raw = window.atob(base64String);
    } catch (e) {
      // Sometimes we get text instead of base64String. TODO: Detect this better than try/catch
      return null;
    } // let raw = window.atob(base64String);


    let rawLength = raw.length;
    let array = new Uint8Array(new ArrayBuffer(rawLength));

    for (let i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i); // Get Unicode code from string
    }

    return array;
  }

  function ignoreHighOrderBit(binaryValue) {
    // Returns the binary value with the high order bit set to 0
    return binaryValue.padStart(8, '0').substring(1).padStart(8, '0');
  }

  function hasDecimalPoint(binaryValue) {
    // Returns true if the high order bit is 1.
    return binaryValue.padStart(8, '0').substring(0, 1) == 1 ? true : false;
  }

  function hexToBase64(hexString) {
    return btoa(hexString.match(/\w{2}/g).map(function (a) {
      return String.fromCharCode(parseInt(a, 16)); // Get decimal value of Unicode code
    }).join(''));
  }

  function ledCodeToString(text) {
    let output;
    output = base64ToDecimalArray(text); // Get the decimal bytes

    if (output === null) return ''; // Added to fix error when not receiving base64 values

    output.reverse();
    return output.reduce((previous, data) => {
      let bits7 = ignoreHighOrderBit(data.toString(2));
      let hex = parseInt(bits7, 2).toString(16).padStart(2, '0'); // Convert to hex so we can lookup the value

      let dp = hasDecimalPoint(data.toString(2)) ? "." : '';
      let displayChar = LED_HEX2CHAR[`0x${hex.toUpperCase()}`] || '';
      return previous += `${displayChar}${dp}`;
    }, '');
  }

  function stringToLedCode(text) {
    let output;
    if (!text || text.length < 1) return;
    output = text.split('');
    output.reverse();
    let hexString = output.reduce((previous, char) => {
      let letter = LED_CHAR2HEX[char];

      if (letter) {
        return previous += LED_CHAR2HEX[char].toString(16);
      } else {
        return previous;
      }
    }, '');
    return hexToBase64(hexString);
  }

  function hexToBinary(hex) {
    return parseInt(hex, 16).toString(2).padStart(8, '0');
  }

  const LED_HEX2CHAR = {
    '0x00': ' ',
    '0x3F': '0',
    '0x06': '1',
    '0x5B': '2',
    '0x4F': '3',
    '0x66': '4',
    '0x6D': '5',
    '0x7D': '6',
    '0x07': '7',
    '0x7F': '8',
    '0x6F': '9',
    '0x77': 'a',
    '0x7C': 'b',
    '0x39': 'c',
    '0x5E': 'd',
    '0x79': 'e',
    '0x71': 'f',
    // '0x6F': 'g',    9
    '0x76': 'h',
    '0x04': 'i',
    '0x1E': 'j',
    '0x75': 'k',
    '0x38': 'l',
    '0x37': 'm',
    '0x54': 'n',
    '0x5C': 'o',
    '0x73': 'p',
    '0x67': 'q',
    '0x50': 'r',
    // '0x6D': 's',    5
    '0x78': 't',
    '0x3E': 'u',
    '0x1C': 'v',
    '0x2A': 'w',
    // '0x76': 'x',    h
    '0x6E': 'y' // '0x5B': 'z'     2

  };
  _exports.LED_HEX2CHAR = LED_HEX2CHAR;
  const LED_CHAR2HEX = {
    ' ': '00',
    '0': '3F',
    '1': '06',
    '2': '5B',
    '3': '4F',
    '4': '66',
    '5': '6D',
    '6': '7D',
    '7': '07',
    '8': '7F',
    '9': '6F',
    'a': '77',
    'b': '7C',
    'c': '39',
    'd': '5E',
    'e': '79',
    'f': '71',
    'g': '6F',
    'h': '76',
    'i': '04',
    'j': '1E',
    'k': '75',
    'l': '38',
    'm': '37',
    'n': '54',
    'o': '5C',
    'p': '73',
    'q': '67',
    'r': '50',
    's': '6D',
    't': '78',
    'u': '3E',
    'v': '1C',
    'w': '2A',
    'x': '76',
    'y': '6E',
    'z': '5B'
  };
  _exports.LED_CHAR2HEX = LED_CHAR2HEX;
});