define("alliance-business-suite/templates/components/btn-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eHSOFosi",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[10,\"div\"],[15,0,[34,0]],[12],[2,\"\\n  \"],[10,\"button\"],[14,0,\"btn btn-default dropdown-toggle\"],[14,\"data-toggle\",\"dropdown\"],[14,4,\"button\"],[12],[2,\"\\n    \"],[1,[34,1]],[2,\"\\n    \"],[10,\"span\"],[14,0,\"caret\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"ul\"],[14,0,\"dropdown-menu\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"li\"],[12],[10,\"a\"],[14,6,\"#\"],[12],[1,[32,1,[\"name\"]]],[13],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"directionClass\",\"title\",\"options\",\"-track-array\",\"each\"]}",
    "moduleName": "alliance-business-suite/templates/components/btn-dropdown.hbs"
  });

  _exports.default = _default;
});