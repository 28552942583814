define("alliance-business-suite/components/machines/traceability/data/charts-temperature", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "alliance-business-suite/utils/traceability"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _traceability) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="traceability-chart">
  
    <div class="steps-container with-shadow">
      <header>
        <h3 class="stamp">{{t 'temperature'}}</h3>
  
        <div class="controls-right">
  
          <div class="btn-group">
            <div class="btn-group__item">
              <button
                {{on "click" @toggleTempUnits}}
                class="btn">
                  {{if @useCelsius (t 'celsius') (t 'fahrenheit')}}
              </button>
            </div>
          </div>
        </div>
      </header>
  
  
      <HighCharts
        @chartOptions={{this.chartOptions}}
        @content={{this.chartData}} as |chart|>
          <Machines::Traceability::Data::ChartsRedraw
            @chart={{chart}}
            @xAxisLabels={{this.xAxisLabels}} />
      </HighCharts>
    </div>
  </div>
  
  */
  {
    "id": "IWB3Z+2D",
    "block": "{\"symbols\":[\"chart\",\"@toggleTempUnits\",\"@useCelsius\"],\"statements\":[[10,\"div\"],[14,0,\"traceability-chart\"],[12],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"steps-container with-shadow\"],[12],[2,\"\\n    \"],[10,\"header\"],[12],[2,\"\\n      \"],[10,\"h3\"],[14,0,\"stamp\"],[12],[1,[30,[36,0],[\"temperature\"],null]],[13],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"controls-right\"],[12],[2,\"\\n\\n        \"],[10,\"div\"],[14,0,\"btn-group\"],[12],[2,\"\\n          \"],[10,\"div\"],[14,0,\"btn-group__item\"],[12],[2,\"\\n            \"],[11,\"button\"],[24,0,\"btn\"],[4,[38,1],[\"click\",[32,2]],null],[12],[2,\"\\n                \"],[1,[30,[36,2],[[32,3],[30,[36,0],[\"celsius\"],null],[30,[36,0],[\"fahrenheit\"],null]],null]],[2,\"\\n            \"],[13],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n\\n    \"],[8,\"high-charts\",[],[[\"@chartOptions\",\"@content\"],[[32,0,[\"chartOptions\"]],[32,0,[\"chartData\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"machines/traceability/data/charts-redraw\",[],[[\"@chart\",\"@xAxisLabels\"],[[32,1],[32,0,[\"xAxisLabels\"]]]],null],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"on\",\"if\"]}",
    "moduleName": "alliance-business-suite/components/machines/traceability/data/charts-temperature.hbs"
  });

  let MachinesTraceabilityDataChartsTemperatureComponent = (_dec = Ember.inject.service, (_class = class MachinesTraceabilityDataChartsTemperatureComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor, this);
      (0, _defineProperty2.default)(this, "baseTime", this.args.rawTraceData.data[0].timestamp);
    }

    generateMinTempData() {
      let results = {
        minTemp: [],
        time: []
      };
      return results;
    }

    generateData(prop, datapointKey, useCelsius) {
      let results = {
        [prop]: [],
        time: []
      };
      this.args.rawTraceData.data.forEach(datapoint => {
        const val = datapoint[datapointKey] ? datapoint[datapointKey] : null;
        results[prop].push(useCelsius ? (0, _traceability.fahrenheitToCelsius)(val) : val);
        results.time.push(datapoint.timestamp - this.baseTime);
      });
      return results;
    }

    generateTimes() {
      return this.args.rawTraceData.data.map(datapoint => {
        const val = _traceability.formatMinutesToTime.call(this, (datapoint.timestamp - this.baseTime) / 60, true);

        if (val.hrs > 0) {
          return `${val.hrs}:${val.mins}:${val.secs}`;
        } else {
          return `${val.mins}:${val.secs}`;
        }
      });
    }

    get xAxisLabels() {
      return this.generateTimes();
    }

    get chartResults() {
      return {
        tempData: this.generateData('temperature', 'temperature', this.args.useCelsius),
        targetTempData: this.generateData('targetTemp', '_stepTargetTemperature', this.args.useCelsius),
        minTempData: this.generateData('minTemp', 'minimumTemp', this.args.useCelsius)
      };
    }

    get chartOptions() {
      return {
        chart: {
          zoomType: 'xy',
          events: {// load: (e) => {
            // this.args.isLoaded();
            // },
          }
        },
        title: {
          text: null // removes title

        },
        subtitle: {
          text: null // removes subtitle

        },
        xAxis: {
          categories: this.xAxisLabels,
          crosshair: true,
          // tickInterval: 5,
          // minorTickInterval: 1,
          // minorTickLength: 1,
          // showLastLabel: true,
          tickPositioner: function () {
            var positions = [],
                tick = Math.floor(this.dataMin),
                increment = Math.ceil((this.dataMax - this.dataMin) / 10);

            if (this.dataMax !== null && this.dataMin !== null) {
              for (tick; tick - increment <= this.dataMax; tick += increment) {
                positions.push(tick);
              }
            }

            return positions;
          },
          title: {
            text: 'Time (minutes)'
          }
        },
        yAxis: {
          // max: 100,
          tickInterval: 10,
          minorTickInterval: 5,
          minorTickLength: 1,
          labels: {
            format: '{value}°',
            style: {
              color: Highcharts.getOptions().colors[1]
            }
          },
          title: {
            // text:' hi',
            text: 'Temperature',
            style: {
              color: Highcharts.getOptions().colors[1]
            }
          }
        },
        tooltip: {
          shared: true
        },
        plotOptions: {
          line: {
            marker: {
              enabled: false
            }
          },
          area: {
            marker: {
              enabled: false
            },
            fillColor: {
              linearGradient: [0, 0, 0, 300],
              stops: [[0, "rgba(241,241,241,0.25)"], [1, Highcharts.color("#f13343").setOpacity(.05).get('rgba')]]
            }
          }
        }
      };
    }

    get chartData() {
      return [{
        name: this.intl.t('temperature'),
        type: 'area',
        color: '#e63c24',
        lineWidth: 4,
        data: this.chartResults.tempData.temperature,
        tooltip: {
          valueSuffix: `${this.args.useCelsius ? '°C' : '°F'}`
        }
      }, {
        name: this.intl.t('target_temperature'),
        type: 'line',
        color: '#478735',
        lineWidth: 4,
        data: this.chartResults.targetTempData.targetTemp,
        tooltip: {
          valueSuffix: `${this.args.useCelsius ? '°C' : '°F'}`
        }
      }, {
        name: this.intl.t('minimum_temperature'),
        type: 'line',
        color: '#f9b654',
        lineWidth: 4,
        data: this.chartResults.minTempData.minTemp,
        tooltip: {
          valueSuffix: `${this.args.useCelsius ? '°C' : '°F'}`
        }
      }];
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = MachinesTraceabilityDataChartsTemperatureComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, MachinesTraceabilityDataChartsTemperatureComponent);
});