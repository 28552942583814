define("alliance-business-suite/utils/bits", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //@ts-check

  /** Class representing a bit field */
  class Bits {
    /**
     * Get the value at a specific index in the bit field
     * @param {number} field Bit field value
     * @param {number} index Bit index
     * @returns {number} The number value at index
     */
    static get(field, index) {
      return field >> index & 1;
    }
    /**
     * Set the value at index of the bit field
     * @param {number} field The bit field value
     * @param {number} index The index to get
     * @returns {number} The new bit field value
     */


    static set(field, index) {
      let value = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;
      return field & ~(1 << index) | value << index;
    }

    static getLowNibble(decimal) {
      return decimal & 0x0F;
    }

    static getHighNibble(decimal) {
      return decimal >> 4 & 0x0F;
    }

  }

  _exports.default = Bits;
});