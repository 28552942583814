define("alliance-business-suite/helpers/utc-to-user", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  let UtcToUserHelper = (_dec = Ember.inject.service, (_class = class UtcToUserHelper extends Ember.Helper {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
    }

    compute(_ref) {
      let [utcDate, format = false] = _ref;
      console.log('*** date received: ', utcDate);

      if (utcDate) {
        const convertedDate = this.access.toUserDateTime(utcDate, {
          type: "moment"
        });
        const hasCustomFormat = typeof format !== 'boolean';
        const defaultFormat = this.access.userDateTimeFormat;
        let formattedDate = convertedDate.format(hasCustomFormat ? format : defaultFormat);

        if (format === true) {
          formattedDate += ` ${this.access.userTimeZoneAbbr}`;
        }

        return formattedDate;
      } else {
        return utcDate;
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = UtcToUserHelper;
});