define("alliance-business-suite/components/marketing/campaigns/campaign-details", ["exports", "jquery", "alliance-business-suite/mixins/campaign-mixin"], function (_exports, _jquery, _campaignMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const FILTERS = [{
    id: 'LAST_ACTIVE',
    text: 'last_visit'
  }, {
    id: 'SERVICE_TYPE',
    text: 'usage_type'
  }, {
    id: 'PREFERRED_DAY',
    text: 'preferred_day_of_week'
  }, {
    id: 'MACHINE_TYPE',
    text: 'machine_type'
  }, {
    id: 'WASH_DRY_FOLD',
    text: 'avg_visit_spend_wash_dry_fold'
  }, {
    id: 'GOODS',
    text: 'avg_visit_spend_goods'
  }, {
    id: 'MACHINE',
    text: 'avg_visit_spend_self_service'
  }];
  const PROMO_TYPE_OPTIONS = [{
    id: 'REWARD_POINTS',
    text: 'reward_points'
  }, {
    id: 'REWARD_CREDIT',
    text: 'rewards_credit'
  }, {
    id: 'DISCOUNT',
    text: 'discount'
  }];
  const PROMO_DISCOUNT_TYPE_OPTIONS = [{
    id: 'PERCENTAGE',
    text: 'percent'
  }, {
    id: 'FLAT_AMOUNT',
    text: 'flat_amount'
  }];
  const PROMO_DISCOUNT_ITEM_OPTIONS = [{
    id: 'WASH_DRY_FOLD',
    text: 'wash_dry_fold'
  }, {
    id: 'GOODS',
    text: 'over_the_counter_goods'
  }];
  const PROMO_LENGTH_OPTIONS = [{
    id: 'days',
    text: 'Days'
  }, {
    id: 'weeks',
    text: 'Weeks'
  }, {
    id: 'months',
    text: 'Months'
  }];

  var _default = Ember.Component.extend(_campaignMixin.default, {
    // eslint-disable-line
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    access: Ember.inject.service(),
    classNames: ['relative-container'],
    totalRecipients: 0,
    redemptionPercent: 0,
    deliveryRate: 0,
    activeTab: 'GRAPH',
    selectedFilters: [],
    numberOfFilters: 1,
    blockLoading: true,

    init() {
      this._super(...arguments);

      this.getChartData();
    },

    parseFilters() {
      let self = this;

      if (!this.get('campaignRun.filter')) {
        return false;
      }

      let selectedFilters = JSON.parse(this.get('campaignRun.filter'));

      if (selectedFilters && selectedFilters.Params && selectedFilters.Params.length) {
        let filters = {
          size: 0
        };
        let filterCount = 0;
        selectedFilters = selectedFilters.Params;
        selectedFilters.forEach(function (filter) {
          if (filter.Name == 'LAST_VISIT_INTERVAL' || filter.Name == 'LAST_VISIT_START_DATE' || filter.Name == 'LAST_VISIT_END_DATE' || filter.Name == 'LAST_VISIT_QUALIFIER') {
            if (filters.LAST_ACTIVE) {
              filters.LAST_ACTIVE.options.push({
                name: filter.Name,
                value: filter.Value
              });
            } else {
              filters.LAST_ACTIVE = {
                'filter': FILTERS[0],
                'options': [{
                  name: filter.Name,
                  value: filter.Value
                }]
              };
              filters.size++;
              filterCount++;
            }
          } else if (filter.Name == 'SERVICE_TYPE') {
            if (filters[filter.Name]) {
              if (filters.SERVICE_TYPE.options.indexOf(filter.Value) === -1) {
                filters.SERVICE_TYPE.options.push(filter.Value);
              }
            } else {
              filters.SERVICE_TYPE = {
                'filter': FILTERS[1],
                'options': [filter.Value]
              };
              filters.size++;
              filterCount++;
            }
          } else if (filter.Name == 'DAY_OF_WEEK') {
            if (filters[filter.Name]) {
              if (filters.DAY_OF_WEEK.options.indexOf(filter.Value) === -1) {
                filters.DAY_OF_WEEK.options.push(filter.Value);
              }
            } else {
              filters.DAY_OF_WEEK = {
                'filter': FILTERS[2],
                'options': [filter.Value]
              };
              filters.size++;
              filterCount++;
            }
          } else if (filter.Name == 'AVG_VEND_TYPE') {
            let min;
            let max;
            selectedFilters.forEach(selectedFilter => {
              if (selectedFilter.Name == filter.Name && selectedFilter.Value == filter.Value) {
                min = false;
                max = false;
              } else if (selectedFilter.Name == 'AVG_VEND_MIN' && !min) {
                min = selectedFilter.Value;
              } else if (selectedFilter.Name == 'AVG_VEND_MAX' && !max) {
                max = selectedFilter.Value;
              }
            });
            FILTERS.forEach(FILTER => {
              if (FILTER.id == filter.Value) {
                filters[filter.Value] = {
                  'filter': FILTER,
                  'type': 'AVG_VEND_TYPE',
                  'options': {
                    'AVG_VEND_MIN': min,
                    'AVG_VEND_MAX': max
                  }
                };
              }
            });
            filters.size++;
            filterCount++;
          }
        });
        this.set('numberOfFilters', filterCount);
        this.set('applySelectedFilters', filters);
      } else {
        this.set('numberOfFilters', 0);
      }
    },

    filters: Ember.computed(function () {
      let filterOptions = FILTERS;

      if (this.get('access.isRetail') === false) {
        filterOptions = filterOptions.filter(item => item.id !== 'WASH_DRY_FOLD' && item.id !== 'GOODS');
      }

      return JSON.parse(JSON.stringify(filterOptions));
    }),
    filterComponents: Ember.computed('numberOfFilters', function () {
      let numberOfFilters = this.numberOfFilters;
      let finalArr = [];

      for (let i = 0; i < numberOfFilters; i++) {
        finalArr.push('marketing/campaigns/campaign-filter');
      }

      return finalArr;
    }),
    campaignRunLocations: Ember.computed('campaignRun', function () {
      let _this = this;

      let locations = [];
      let roomIds = this.get('campaignRun.roomIds');

      if (roomIds && roomIds) {
        roomIds.forEach(function (roomId) {
          let room = _this.get('store').peekRecord('room', roomId);

          if (room) {
            locations.push(room);
          }
        });
      }

      return locations;
    }),
    typeOptions: Ember.computed(function () {
      return PROMO_TYPE_OPTIONS;
    }),
    discountTypeOptions: Ember.computed(function () {
      return PROMO_DISCOUNT_TYPE_OPTIONS;
    }),
    discountItemOptions: Ember.computed(function () {
      return PROMO_DISCOUNT_ITEM_OPTIONS;
    }),
    lengthOptions: Ember.computed(function () {
      return PROMO_LENGTH_OPTIONS;
    }),
    currencySymbol: Ember.computed(function () {
      return Ember.get(this, 'access.currencyDenomination');
    }),
    promoType: Ember.computed(function () {
      let promoType;

      for (let i = 0; i < PROMO_TYPE_OPTIONS.length; i++) {
        if (PROMO_TYPE_OPTIONS[i].id == this.get('campaignRun.promotion.promotionTypeLookupKey')) {
          promoType = PROMO_TYPE_OPTIONS[i];
        }
      }

      return promoType;
    }),
    promoValue: Ember.computed(function () {
      return this.get('campaignRun.promotion.value');
    }),
    discountMax: Ember.computed(function () {
      let value;

      if (this.get('campaignRun.promotion.maxDiscountAmount')) {
        value = this.get('campaignRun.promotion.maxDiscountAmount');
      }

      return value;
    }),
    discountType: Ember.computed(function () {
      let type;

      for (let i = 0; i < PROMO_DISCOUNT_TYPE_OPTIONS.length; i++) {
        if (PROMO_DISCOUNT_TYPE_OPTIONS[i].id == this.get('campaignRun.promotion.promotionDiscountTypeLookupKey')) {
          type = PROMO_DISCOUNT_TYPE_OPTIONS[i];
        }
      }

      return type;
    }),
    discountItem: Ember.computed(function () {
      let item;

      for (let i = 0; i < PROMO_DISCOUNT_ITEM_OPTIONS.length; i++) {
        if (PROMO_DISCOUNT_ITEM_OPTIONS[i].id == this.get('campaignRun.promotion.promotionDiscountObjectTypeLookupKey')) {
          item = PROMO_DISCOUNT_ITEM_OPTIONS[i];
        }
      }

      return item;
    }),
    promoLength: Ember.computed(function () {
      let _this = this;

      let length = PROMO_LENGTH_OPTIONS[0];
      let promoDuration = this.promoDuration;
      return this.get('campaignRun.promotion.expirationInterval'); // console.log('1 ...promoDuration', promoDuration)
      // console.log('2 ...init length', length)
      // console.log('3 campaignRun.promotion', this.get('campaignRun.promotion'))
      //
      // if (this.get('campaignRun.promotion.expirationInterval')) {
      //   console.log('4 interval')
      //   for (let i = 0; i < PROMO_LENGTH_OPTIONS.length; i++) {
      //     console.log('4b', i, PROMO_LENGTH_OPTIONS[i], PROMO_LENGTH_OPTIONS[i].id)
      //     if (this.get('campaignRun.promotion.expirationInterval').indexOf(PROMO_LENGTH_OPTIONS[i].id) !== -1) {
      //       console.log('>> 4c')
      //       if (PROMO_LENGTH_OPTIONS[i].id == 'days' && promoDuration % 7 === 0) {
      //         console.log('if', 1, PROMO_LENGTH_OPTIONS[1])
      //         length = PROMO_LENGTH_OPTIONS[1];
      //         // timeout because ember is crazy about modifying a property twice (idk)
      //         setTimeout(function() {
      //           console.log('timeout....')
      //           _this.set('promoDuration', promoDuration / 7);
      //         }, 5);
      //       } else {
      //         console.log('else', i, PROMO_LENGTH_OPTIONS[i])
      //         length = PROMO_LENGTH_OPTIONS[i];
      //       }
      //     }
      //   }
      // }
      //
      // console.log('final length', length)
      //
      // return length;
    }),

    // promoDuration: computed(function() {
    //   let duration = 1;
    //
    //   if (this.get('campaignRun.promotion.expirationInterval')) {
    //     let interval = this.get('campaignRun.promotion.expirationInterval').split(' ');
    //     if (interval[0] && !isNaN(interval[0])) {
    //       duration = interval[0];
    //     }
    //   }
    //
    //   return duration;
    // }),
    getChartData() {
      let self = this;
      let campaign = this.campaign;
      let runId = this.runId;
      let chartData = {
        labels: [],
        datasets: [{
          label: 'Promo Redemptions',
          fill: false,
          backgroundColor: '#76bcd3',
          borderColor: '#76bcd3',
          data: []
        }]
      };

      if (this.dataIndex === 0) {
        this.set('blockLoading', false);
      }

      if (this.blockLoading) {
        this.set('isLoading', false);
        return;
      }

      this.set('isLoading', true);
      this.ajax.request(`/campaigns/${campaign.get('id')}/stats`, {
        method: 'GET',
        data: {
          runId,
          raw: true
        }
      }).then(function (results) {
        let deliveryMethodsData = results.campaign.deliveryMethods;
        deliveryMethodsData.forEach(method => {
          if (method.deliveryMethodLookupKey === "IN_APP_NOTIFICATION" && method.openPercent >= 0) {
            self.set('inAppOpenRate', method.openPercent);
          }

          if (method.deliveryMethodLookupKey === "EMAIL_HTML" && method.openPercent >= 0) {
            self.set('emailOpenRate', method.openPercent);
          }
        });
        chartData.labels = results.redemptionX;
        chartData.datasets[0].data = results.redemptionY;

        if (results.redemptionY.length <= 0) {
          self.set('hasChartData', false);
        } else {
          self.set('hasChartData', true);
        }

        self.set('chartData', chartData);
        self.set('totalRecipients', results.receipentCount);
        self.set('redemptionCount', results.redemptionCount);
        self.set('redemptionPercent', results.redemptionPercent);
        self.set('deliveryPercent', Number(results.deliveryPercent).toFixed(2));
        self.set('campaignRun', results.campaign);
        self.set('run', results);
        self.set('isLoading', false);
        let deliveryMethods = self.get('campaignRun.deliveryMethods');

        if (deliveryMethods) {
          deliveryMethods.forEach(method => {
            let emailContent = method.deliveryMethodBodyText;

            if (emailContent && method.deliveryMethodLookupKey === 'EMAIL_TEXT') {
              emailContent = emailContent.replace(/&/g, '&amp;');
              emailContent = emailContent.replace(/</g, '&lt;');
              emailContent = emailContent.replace(/>/g, '&gt;');
              emailContent = emailContent.replace(/\n/g, '<br/>');
            }

            setTimeout(function () {
              (0, _jquery.default)(`#email-preview-iframe_${results.id}`).contents().find('html').html(emailContent);
            }, 100);
          });
        }

        if (results.campaign && results.campaign.filter) {
          self.parseFilters();
        }
      }, function () {
        self.set('chartData', chartData);
        self.set('noData', true);
        self.set('isLoading', false);
      });
    },

    chartOptions: Ember.computed(function () {
      return {
        responsive: true,
        // width: 555
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              min: 1,
              precision: 0
            }
          }]
        }
      };
    }),
    actions: {
      getChartData() {
        this.set('blockLoading', false);
        this.getChartData();
      },

      openEmailPreviewWindow() {
        let deliveryMethods = this.get('run.campaign.deliveryMethods');
        deliveryMethods.forEach(method => {
          let emailContent = method.deliveryMethodBodyText;

          if (emailContent && method.deliveryMethodLookupKey === 'EMAIL_TEXT') {
            emailContent = emailContent.replace(/&/g, '&amp;');
            emailContent = emailContent.replace(/</g, '&lt;');
            emailContent = emailContent.replace(/>/g, '&gt;');
            emailContent = emailContent.replace(/\n/g, '<br/>');
          }

          let previewWindow = window.open(null, 'Email Preview', 'titlebar=yes,toolbar=no,status=no,location=no');
          previewWindow.document.write(emailContent);
        });
      },

      switchTab(tab) {
        this.parseFilters();
        this.set('activeTab', tab);
        let runId = this.get('run.id');
        let deliveryMethods = this.get('run.campaign.deliveryMethods');
        deliveryMethods.forEach(method => {
          let deliveryMethodBodyText = method.deliveryMethodBodyText;

          if (deliveryMethodBodyText && method.deliveryMethodLookupKey === 'EMAIL_TEXT') {
            deliveryMethodBodyText = deliveryMethodBodyText.replace(/&/g, '&amp;');
            deliveryMethodBodyText = deliveryMethodBodyText.replace(/</g, '&lt;');
            deliveryMethodBodyText = deliveryMethodBodyText.replace(/>/g, '&gt;');
            deliveryMethodBodyText = deliveryMethodBodyText.replace(/\n/g, '<br/>');
          }

          setTimeout(function () {
            (0, _jquery.default)(`#email-preview-iframe_${runId}`).contents().find('html').html(deliveryMethodBodyText);
          }, 100);
        });
      },

      downloadCSV() {
        if (this.access.isDemo) {
          alert('Demo mode restricted.');
          return;
        }

        let self = this;
        let runId = this.runId;
        this.set('isProccessingDownload', true);
        this.ajax.request(`/runs/${runId}/csv`, {
          method: 'GET',
          dataType: 'text'
        }).then(function (results) {
          self.set('downloadUrl', results);
          self.set('isProccessingDownload', false);
          setTimeout(() => {
            document.getElementById('downloadLink').click();
          }, 200);
        }, function (results) {
          self.set('isProccessingDownload', false); // error message needed
        });
      }

    }
  });

  _exports.default = _default;
});