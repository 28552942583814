define("alliance-business-suite/components/table/table-cell-button-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    buttonContent: Ember.computed('column.params.[]', function () {
      let buttonContent = this.get('column.buttonContent');

      if (Array.isArray(buttonContent)) {
        for (let x = 0; x <= buttonContent.length; x++) {
          if (this.get('column.params') && this.get('column.params')[x]) {
            if (this.get(this.get('column.params')[x])) {
              return buttonContent[x];
            } else if (buttonContent[x + 1]) {
              return buttonContent[x + 1];
            }
          }
        }
      } else {
        return buttonContent;
      }
    }),
    actions: {
      go() {
        let params = [];
        let fnCall = this.get('column.action');

        if (typeof fnCall === 'function') {
          if (this.get('column.params')) {
            this.get('column.params').forEach(param => {
              if (this.get(param)) {
                params.push(this.get(param));
              } else {
                params.push(param);
              }
            });
          }

          fnCall.call(this, ...params);
        }
      }

    }
  });

  _exports.default = _default;
});