define("alliance-business-suite/routes/welcome", ["exports", "alliance-business-suite/routes/_secureRoute"], function (_exports, _secureRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class WelcomeRoute extends _secureRoute.default {}

  _exports.default = WelcomeRoute;
});