define("alliance-business-suite/helpers/temperature-display", ["exports", "alliance-business-suite/utils/traceability"], function (_exports, _traceability) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // by default shows Fahrenheit unless passed in otherwise
  function temperatureDisplay(_ref) {
    let [useCelsius = false, temperature] = _ref;
    return useCelsius ? `${(0, _traceability.fahrenheitToCelsius)(temperature)} °C` : `${temperature} °F`;
  }

  var _default = Ember.Helper.helper(temperatureDisplay);

  _exports.default = _default;
});