define("alliance-business-suite/templates/components/form-ui/steps/internal-supply", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sysoAthS",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[2,\" \"],[8,\"form-ui\",[],[[\"@object\",\"@formSettings\",\"@selectOptions\"],[[32,0,[\"object\"]],[32,0,[\"formSettings\"]],[32,0,[\"selectOptions\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[10,\"h2\"],[12],[1,[30,[36,2],[\"internal_supply\"],null]],[13],[2,\"\\n\\n\"],[2,\"  \"],[8,[32,1,[\"checkbox\"]],[],[[\"@params\"],[[30,[36,1],null,[[\"propertyName\",\"labelKey\",\"disabled\"],[[32,0,[\"properties\",\"Compartment1\",\"key\"]],[30,[36,0],[0,[32,0,[\"compartmentLabels\"]]],null],[32,0,[\"compartment1Disabled\"]]]]]]],null],[2,\"\\n  \"],[8,[32,1,[\"checkbox\"]],[],[[\"@params\"],[[30,[36,1],null,[[\"propertyName\",\"labelKey\"],[[32,0,[\"properties\",\"Compartment2\",\"key\"]],[30,[36,0],[1,[32,0,[\"compartmentLabels\"]]],null]]]]]],null],[2,\"\\n\"],[6,[37,3],[[32,0,[\"showCompartment3\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,[32,1,[\"checkbox\"]],[],[[\"@params\"],[[30,[36,1],null,[[\"propertyName\",\"labelKey\"],[[32,0,[\"properties\",\"Compartment3\",\"key\"]],[30,[36,0],[2,[32,0,[\"compartmentLabels\"]]],null]]]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[8,[32,1,[\"checkbox\"]],[],[[\"@params\"],[[30,[36,1],null,[[\"propertyName\",\"labelKey\"],[[32,0,[\"properties\",\"Compartment4\",\"key\"]],[30,[36,0],[3,[32,0,[\"compartmentLabels\"]]],null]]]]]],null],[2,\"\\n\\n  \"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"object-at\",\"hash\",\"t\",\"if\"]}",
    "moduleName": "alliance-business-suite/templates/components/form-ui/steps/internal-supply.hbs"
  });

  _exports.default = _default;
});