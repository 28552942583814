define("alliance-business-suite/routes/crm/campaigns/edit", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "alliance-business-suite/routes/_secureRoute"], function (_exports, _defineProperty2, _applyDecoratedDescriptor2, _secureRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  let CrmCampaignsEditRoute = (_dec = Ember._action, (_class = class CrmCampaignsEditRoute extends _secureRoute.default {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "allowAccess", 'CRM_EDIT_CAMPAIGN');
    }

    willTransition(transition) {
      let promo = this.controller.get('promo');
      let abandonMessage = 'You have unsaved changes, continue and lose changes?';

      if ((this.controller.get('hasUnsavedChanges') || promo && promo.get('hasDirtyAttributes')) && !window.confirm(abandonMessage)) {
        transition.abort();
      } else {
        this.controller.set('hasUnsavedChanges', false);

        if (this.controller.get('model')) {
          this.controller.get('model').rollbackAttributes();
        }

        localStorage.removeItem('newCampaignFilters');
        localStorage.removeItem('newCampaignLocations');

        if (promo) {
          promo.rollbackAttributes();
        }

        return true;
      }
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class.prototype, "willTransition", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "willTransition"), _class.prototype)), _class));
  _exports.default = CrmCampaignsEditRoute;
});