define("alliance-business-suite/templates/components/form-ui/steps/drain-refill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vt1TJ1HP",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[2,\"\\n\\n\"],[8,\"form-ui\",[],[[\"@object\",\"@formSettings\",\"@selectOptions\"],[[32,0,[\"object\"]],[32,0,[\"formSettings\"]],[32,0,[\"selectOptions\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n\"],[10,\"h2\"],[12],[1,[30,[36,1],[\"drain\"],null]],[13],[2,\"\\n\\n  \"],[8,[32,1,[\"checkbox\"]],[],[[\"@params\"],[[30,[36,0],null,[[\"propertyName\",\"label\"],[[32,0,[\"properties\",\"MainDrain1\",\"key\"]],\"MainDrain1\"]]]]],null],[2,\"\\n\\n\"],[6,[37,2],[[32,0,[\"showDrain2\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,[32,1,[\"checkbox\"]],[],[[\"@params\"],[[30,[36,0],null,[[\"propertyName\",\"label\"],[[32,0,[\"properties\",\"MainDrain2\",\"key\"]],\"MainDrain2\"]]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,2],[[30,[36,4],[[30,[36,3],[[32,0,[\"Refill\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,[32,1,[\"checkbox\"]],[],[[\"@params\"],[[30,[36,0],null,[[\"propertyName\",\"labelKey\"],[[32,0,[\"properties\",\"Refill\",\"key\"]],\"allow_refill\"]]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,2],[[30,[36,4],[[30,[36,3],[[32,0,[\"ChemFlush\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,[32,1,[\"checkbox\"]],[],[[\"@params\"],[[30,[36,0],null,[[\"propertyName\",\"labelKey\"],[[32,0,[\"properties\",\"ChemFlush\",\"key\"]],\"chemical_flush\"]]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"hash\",\"t\",\"if\",\"is-empty\",\"not\"]}",
    "moduleName": "alliance-business-suite/templates/components/form-ui/steps/drain-refill.hbs"
  });

  _exports.default = _default;
});