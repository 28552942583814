define("alliance-business-suite/utils/convert-temp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.toF = _exports.toC = _exports.rateToF = _exports.rateToC = void 0;

  /* globals Big */
  const toC = F => {
    return new Big(F).minus(32).times(5).div(9).toFixed(0);
  };

  _exports.toC = toC;

  const toF = C => {
    return new Big(C).times(9).div(5).plus(32).toFixed(0);
  }; // These rate calculations work on numbers that are integers...before the division by 10
  // (this is because of the Floor in there)


  _exports.toF = toF;

  const rateToC = F => {
    let result = new Big(F).times(5);
    let roundup = result.mod(9) >= 5 ? 1 : 0;
    return Math.floor(result.div(9)) + roundup;
  };

  _exports.rateToC = rateToC;

  const rateToF = C => {
    let result = new Big(C).times(9);
    let roundup = result.mod(5) >= 3 ? 1 : 0;
    return Math.floor(result.div(5)) + roundup;
  }; // THIS IS FROM THE WINDOWS APPLICATION SOURCE CODE:
  //
  // public static decimal ConvertCelsiusToFahrenheit_FLW_RATES(decimal paraTemperature)
  // {
  //     decimal result = 0;
  //     decimal roundup = 0;
  //     result = paraTemperature * 9;
  //     roundup = result % 5 >= 3 ? 1 : 0;
  //     result /= 5;
  //     result = Math.Floor(result);
  //     return result + roundup; //roundup = result % 5 >= 3 ? 1 : 0;	//Determine if value should be rounded up <-- Ben Patri's Method of rounding up
  // }
  // public static decimal ConvertFahrenheitToCelsius_FLW_RATES(decimal paraTemperature)
  //   {
  //       decimal result = 0;
  //       decimal roundup = 0;
  //       result = paraTemperature * 5;
  //       roundup = result % 9 >= 5 ? 1 : 0;
  //       result /= 9;
  //       result = Math.Floor(result);
  //       return result + roundup; //roundup = result % 9 >= 5 ? 1 : 0;	//Determine if value should be rounded up <-- Ben Patri's Method of rounding up
  //   }


  _exports.rateToF = rateToF;
});