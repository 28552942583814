define("alliance-business-suite/components/machines/programs/modal-program-send-progress", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "firebase/app", "firebase/database"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _app, _database) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21;

  let MachinesProgramsModalProgramSendProgressComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember.inject.service, _dec9 = Ember.inject.service, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember._tracked, _dec18 = Ember._tracked, _dec19 = Ember._tracked, _dec20 = Ember._tracked, _dec21 = Ember._tracked, _dec22 = Ember._action, _dec23 = Ember._action, _dec24 = Ember._action, _dec25 = Ember._action, _dec26 = Ember._action, _dec27 = Ember._action, _dec28 = Ember._action, (_class = class MachinesProgramsModalProgramSendProgressComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "ajax", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "notifications", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "firebaseCommands", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "router", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "devtools", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "machinePrograms", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "hasNoProgramsSent", _descriptor10, this);
      (0, _initializerDefineProperty2.default)(this, "isProcessing", _descriptor11, this);
      (0, _initializerDefineProperty2.default)(this, "selectedRoom", _descriptor12, this);
      (0, _initializerDefineProperty2.default)(this, "isLoading", _descriptor13, this);
      (0, _initializerDefineProperty2.default)(this, "sendBannerMessage", _descriptor14, this);
      (0, _initializerDefineProperty2.default)(this, "hasUsedResendButton", _descriptor15, this);
      (0, _defineProperty2.default)(this, "elapsedTimeWarningThreshold", 300);
      (0, _initializerDefineProperty2.default)(this, "showTimeWarning", _descriptor16, this);
      (0, _initializerDefineProperty2.default)(this, "setInterval", _descriptor17, this);
      (0, _initializerDefineProperty2.default)(this, "currentIndex", _descriptor18, this);
      (0, _initializerDefineProperty2.default)(this, "selectedProgramEvent", _descriptor19, this);
      (0, _initializerDefineProperty2.default)(this, "selectedProgressBarStats", _descriptor20, this);
      (0, _initializerDefineProperty2.default)(this, "selectedProgramsProcessingStatus", _descriptor21, this);
    }

    get eventOptions() {
      let {
        programEvents,
        eventsProgramsProcessingStatus,
        eventsProgressBarStats
      } = this.machinePrograms;
      let {
        selectedProgressBarStats
      } = this;
      let options = [];
      programEvents.forEach((e, idx) => {
        let progressBarStats = eventsProgressBarStats[idx];
        let status = eventsProgramsProcessingStatus[idx] ? eventsProgramsProcessingStatus[idx] : {};
        options.push({
          id: e.id,
          machineProgramId: e.machineProgramId,
          machineProgramName: e.machineProgramName,
          timestamp: e.timestamp,
          fomattedTimestamp: e.fomattedTimestamp,
          progressBarStats: {
            pending: progressBarStats.pending,
            completed: progressBarStats.completed,
            rejected: progressBarStats.rejected,
            failed: progressBarStats.failed
          },
          overallStatus: {
            status: status.status,
            text: status.text
          }
        });
      });
      return options.reverse();
    }

    get eventMachines() {
      if (this.selectedProgramEvent) {
        let selectedEventId = this.selectedProgramEvent.id;
        return this.machinePrograms.programQueueMachines.filter(machine => {
          if (machine.eventId == selectedEventId) {
            return machine;
          }
        });
      } else {
        return [];
      }
    }

    initialLoad() {
      this.isProcessing = true;
      this.currentIndex = this.args.programEvents.length - 1;

      if (this.currentIndex == -1) {
        this.hasNoProgramsSent = true;
      } else {
        if (this.machinePrograms.isCreate) {
          this.parseProgramEvent();
          this.machinePrograms.hasCreatedEvent();
        } else {
          this.isProcessing = false;
          this.setSecondaryInfo();
        }
      }
    }

    argsUpdate() {
      if (this.selectedProgramEvent) {
        this.setSecondaryInfo();
      }
    }

    selectProgramEvent(val) {
      this.currentIndex = this.machinePrograms.programEvents.findIndex(x => x.id === val.id);
      this.setSecondaryInfo();
    }

    close() {
      this.args.close();
    } // vanilla js implemtnation of getting seconds between dates


    diffBetweenDates(date) {
      let now = Date.now();
      let then = new Date(date).getTime();
      return Math.floor((now - then) / 1000);
    }

    calcTimeElapsed() {
      // Called every 10 seconds while sending programs
      let resultWarning = null;
      let e = this.selectedProgramEvent;

      if (e.pendingFirebaseCommands.length > 0) {
        let diff = this.diffBetweenDates(e.timestamp);
        this.setTimer(this.selectedProgramEvent.id); // after the threshold, put up a warning on machines taking a long time

        if (diff >= this.elapsedTimeWarningThreshold) {
          resultWarning = {
            type: 'warning',
            text: 'program_ellapsed_time_warning'
          };
        } // after 45 minutes, kill the program and any machines pending


        if (diff >= 2700) {
          this.machinePrograms.failCommandQueuedTooLong(e, this.currentIndex);
          this.clearTimer();
        }
      } else {
        // kill any timers if there are no pending firebase events
        this.clearTimer();
      }

      this.showTimeWarning = resultWarning;
    }

    setSecondaryInfo(programEvent) {
      // this.selectedProgramEvent = this.args.programEvents[this.currentIndex] // reset it to make sure it's current  // TODO: commented this to fix errors, but need a better fix
      this.selectedProgressBarStats = this.machinePrograms.eventsProgressBarStats[this.currentIndex];
      this.selectedProgramsProcessingStatus = this.machinePrograms.eventsProgramsProcessingStatus[this.currentIndex];
      this.calcTimeElapsed();
    }

    parseProgramEvent() {
      let index = this.args.programEvents.length - 1;
      let e = this.args.programEvents[index];
      this.findAvailableMachines(e.selectedMachines, e.id, index);
    } // given all the machines the user selected
    // go through firebase and find those they can send to and those they cannot


    async findAvailableMachines(machines, eventId, eventIdx) {
      await Promise.all(machines.map(async machineId => {
        let ref = await this.checkFirebaseStatus(machineId);
        let statusId = ref.val() ? ref.val().statusId : 'UNKNOWN';
        this.sortMachines(machineId, statusId, eventId, eventIdx);
      }));
      this.selectedProgramEvent = this.args.programEvents[this.currentIndex]; // reset it to make sure it's current

      this.setSecondaryInfo(this.selectedProgramEvent);
      this.sendProgram(eventIdx);
    } // creates a firebase promise


    async checkFirebaseStatus(id) {
      let machineCurrentStatusRef = _app.default.database().ref('machineCurrentStatuses/' + id);

      return await machineCurrentStatusRef.once('value');
    } // depending on the status, either rejects or approves it


    sortMachines(machineId, statusId, eventId, eventIdx) {
      let allowList = ['AVAILABLE', 'IN_USE', 'READY_TO_START', 'COMPLETE', 'UNAVAILABLE'];
      let machine = this.store.peekRecord('machine', machineId);
      let roomId = machine.room.get('id');
      let machineObject = {
        id: machine.id,
        eventId: eventId,
        isDryer: machine.isDryer,
        machineName: machine.machineName,
        machineNumber: machine.machineNumber,
        machineTypeId: machine.machineType.get('id'),
        roomId: roomId,
        roomName: machine.room.get('formattedBreadcrumbDisplay'),
        typeName: machine.machineType.get('typeName')
      };

      if (statusId && allowList.indexOf(statusId) != -1) {
        machineObject.programStatus = 'pending';
        machineObject.icon = 'fa-refresh fa-spin';
        machineObject.statusOrdinal = 3;
      } else {
        machineObject.programStatus = 'rejected';
        machineObject.icon = 'fa-ban';
        machineObject.statusOrdinal = 4;
        this.machinePrograms.failEventMachine(eventIdx, machine.id);
      }

      this.machinePrograms.addMachineToQueue(machineObject, eventIdx);
    } // need something to supress and error


    displayDataChangedAction() {
      return;
    }

    generateMachineIdsToSend(eventId) {
      let machinesToSend = this.machinePrograms.programQueueMachines.map(m => {
        if (m.eventId == eventId) {
          if (m.programStatus == 'pending') {
            return m.id;
          }
        }
      });
      return machinesToSend.compact();
    }

    get columns() {
      return [{
        sortPrecedence: 1,
        sortDirection: 'asc',
        // title: this.intl.t('status'),
        title: '',
        propertyName: 'statusOrdinal',
        component: 'table/programs/send/table-cell-program-progress'
      }, {
        sortPrecedence: 3,
        sortDirection: 'asc',
        // sortPrecedence: 1,
        // sortDirection: 'asc',
        propertyName: 'id',
        title: this.intl.t('machine'),
        component: 'table/programs/send/table-cell-machine-name'
      }, {
        sortPrecedence: 4,
        sortDirection: 'asc',
        title: this.intl.t('type'),
        propertyName: 'typeName',
        component: 'table/programs/send/table-cell-machine-info'
      }, {
        sortPrecedence: 2,
        sortDirection: 'asc',
        title: this.intl.t('room'),
        propertyName: 'roomName',
        component: 'table/programs/send/table-cell-machine-room'
      }];
    }

    resendProgramToFailedMachines() {
      this.hasUsedResendButton = true;
      this.isProcessing = true;
      this.findAvailableMachines(this.machinePrograms.failedMachineIds);
    }

    async sendProgram(eventIdx) {
      let e = this.machinePrograms.programEvents[eventIdx];
      let machineProgramId = e.machineProgramId;
      let notifications = this.notifications;
      let userId = this.access.accessUser.get('id');
      let machineIds = this.generateMachineIdsToSend(e.id);

      if (machineIds.length == 0) {
        this.isProcessing = false;
        this.machinePrograms.customNotifyPropertyChange('programEvents', eventIdx, e);
        this.machinePrograms.updateEventOverallSendStatus(e, eventIdx, 'all_rejected');
        return;
      }

      this.isLoading = true;
      await this.ajax.request(`/machinePrograms/${machineProgramId}/send?raw=true`, {
        method: 'POST',
        data: JSON.stringify({
          machineProgramId,
          machineIds,
          createdBy: {
            id: userId
          }
        })
      }).then(response => {
        // Show Send In Progress
        this.notifications.info(this.intl.t('program_send_in_progress'), {
          autoClear: true,
          clearDuration: 2000
        });
        return response;
      }).then(response => {
        let {
          firebaseCommands,
          notification,
          intl
        } = this;
        this.isLoading = false;
        this.isProcessing = false; // A response can have several ids

        response.forEach(room => {
          firebaseCommands.sendProgramCommandToEvent.call(this, e, eventIdx, room.id, true);
        });
        return response;
      }).catch(error => {
        // eslint-disable-next-line
        console.error('stack', error.stack); // eslint-disable-next-line

        console.error('ajax error type 123', e, eventIdx); // mark all "pending" machines as failed;

        this.machinePrograms.failAllEventMachinesInQueue(e, eventIdx, 'network_error');
        this.notifications.error(this.intl.t('error_send_program'), {
          autoClear: true,
          clearDuration: 6000
        });
        return error;
      });
      this.isLoading = false;
      this.isProcessing = false;
    } // only set timer if a different event


    setTimer(eventId) {
      if (!this.setInterval || this.setInterval.eventId != eventId) {
        let interval = setInterval(() => {
          this.calcTimeElapsed();
        }, 10000);

        const onlyOnce = () => {
          this.setInterval = {
            eventId,
            interval
          };
        };

        Ember.run.once(this, onlyOnce);
      }
    }

    clearTimer() {
      if (this.setInterval) {
        clearInterval(this.setInterval.interval);
      } // this.setInterval = null; // TODO: commented this to fix errors, but need a better fix

    }

    willDestroySendProgress() {
      this.clearTimer();
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "notifications", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "firebaseCommands", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "router", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "devtools", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "machinePrograms", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "hasNoProgramsSent", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isProcessing", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedRoom", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isLoading", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "sendBannerMessage", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "hasUsedResendButton", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor16 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "showTimeWarning", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor17 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setInterval", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor18 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "currentIndex", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedProgramEvent", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedProgressBarStats", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedProgramsProcessingStatus", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "initialLoad", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "initialLoad"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "argsUpdate", [_dec23], Object.getOwnPropertyDescriptor(_class.prototype, "argsUpdate"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectProgramEvent", [_dec24], Object.getOwnPropertyDescriptor(_class.prototype, "selectProgramEvent"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "close", [_dec25], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "displayDataChangedAction", [_dec26], Object.getOwnPropertyDescriptor(_class.prototype, "displayDataChangedAction"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "resendProgramToFailedMachines", [_dec27], Object.getOwnPropertyDescriptor(_class.prototype, "resendProgramToFailedMachines"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "willDestroySendProgress", [_dec28], Object.getOwnPropertyDescriptor(_class.prototype, "willDestroySendProgress"), _class.prototype)), _class));
  _exports.default = MachinesProgramsModalProgramSendProgressComponent;
});