define("alliance-business-suite/utils/json-api", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.toPlainDataObject = toPlainDataObject;

  /**
   * Converts a JSON API data object into a plain data object where the `id` and
   * `type` are normal properties, along with all `attributes` and
   * `relationships`. Relationships are converted to properties whose values are
   * plain data objects.
   *
   * @param {object} data A JSON APi data object
   *
   * @return {object} A plain data object
   * @public
   */
  function toPlainDataObject(data) {
    if (data == null) {
      return data;
    }

    let result = Object.assign({}, data.attributes);

    for (let name in data.relationships) {
      if (data.relationships[name].hasOwnProperty('data')) {
        result[name] = toPlainDataObject(data.relationships[name].data);
      } else {
        result[name] = data.relationships[name];
      }

      if (result[name] && !result[name].type) {
        result[name].type = (0, _emberInflector.pluralize)(name);
      }
    }

    return Object.assign(result, {
      id: data.id,
      type: data.type
    });
  }

  var _default = {
    toPlainDataObject
  };
  _exports.default = _default;
});