define("alliance-business-suite/components/locations/rewards/rewards-edit-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // validations,
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    brush: Ember.inject.service(),
    store: Ember.inject.service(),
    access: Ember.inject.service(),
    notifications: Ember.inject.service(),
    classNames: ['edit-loyalty'],
    activeTab: 'GENERAL',
    // location stuff
    selectedTreeNodes: Ember.A(),
    gbWithRewardsPrograms: [],
    isInitLoading: true,
    showSaveConfirmModal: false,

    didInsertElement() {
      this._super(...arguments);

      this.findProgramsGBs();
    },

    findProgramsGBs() {
      let rewardId = this.get('model.id');
      this.ajax.request(`/rewardsPrograms/${rewardId}/geoBoundaries?raw=true`, {
        method: 'GET'
      }).then(response => {
        this.set('isInitLoading', false);

        if (response == null) {
          console.warn(`response from /rewardsPrograms/${rewardId}/geoBoundaries?raw=true is null or undefined:${response}`);
          return;
        } // reset, before repopulating


        Ember.set(this, 'gbWithRewardsPrograms', []);

        if (response.length) {
          let gbWithRewardsPrograms = Ember.get(this, 'gbWithRewardsPrograms');
          response.forEach(gb => {
            gbWithRewardsPrograms.pushObject(gb.geoBoundary.id);
          }); // console.log('init\'d gbWithRewardsPrograms:', gbWithRewardsPrograms);

          Ember.set(this, 'selectedGeoboundaries', gbWithRewardsPrograms.map(id => parseInt(id)));
        }
      });
    },

    editDisabled: Ember.computed('model', function () {
      let canUpdate = this['can-access'].canAccess('LOCATIONS_MANAGE_REWARDS_PROGRAM');

      if (!canUpdate) {
        return true;
      }
    }).readOnly(),
    selectedGeoboundaries: Ember.computed('selectedTreeNodes', {
      get() {
        let data = [];
        let selectedTreeNodes = Ember.get(this, 'selectedTreeNodes');

        if (selectedTreeNodes) {
          selectedTreeNodes.forEach(node => {
            let {
              hasFundsRestriction,
              geoBoundaryId
            } = node.original;

            if (hasFundsRestriction) {
              data.push(geoBoundaryId);
            }
          });
        }

        return data.map(id => parseInt(id));
      },

      set(propName, setValue) {
        // console.log('force set selectedGeoboundaries hack', setValue);
        return setValue;
      }

    }),
    modelIdObserver: Ember.observer('model.id', function () {
      Ember.set(Ember.get(this, 'access'), 'accessRewardProgramId', Ember.get(this, 'model.id'));
    }),
    // rollbackAllChanges() {
    //   this.get('model').rollbackAttributes();
    //   this.set('newDepositFlatAmount', undefined);
    //   this.get('sortedDepositRewards').forEach((reward) => {
    //     if (reward.get('hasDirtyAttributes')) {
    //       reward.rollbackAttributes();
    //       // reward.set('hasChanges', true); // for styling boxes that have unsaved changes
    //       // can just use reward.hasDirtyChanges in template.... riiight?
    //     }
    //   });
    // },
    actions: {
      setTab(type) {
        this.set('activeTab', type);
      }

    }
  });

  _exports.default = _default;
});