define("alliance-business-suite/helpers/moment-manual", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.momentManual = momentManual;

  function momentManual(params
  /* , hash */
  ) {
    let [date, format] = params;

    if (date && format) {
      return _moment.default.tz(date).format(format);
    }

    return '';
  }

  var _default = Ember.Helper.helper(momentManual);

  _exports.default = _default;
});