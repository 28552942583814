define("alliance-business-suite/components/ui/cell-data", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@glimmer/component"], function (_exports, _defineProperty2, _applyDecoratedDescriptor2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  let UiCellDataComponent = (_dec = Ember._action, (_class = class UiCellDataComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "topLeftClass", 'secondary');
      (0, _defineProperty2.default)(this, "topRightClass", 'secondary');
      (0, _defineProperty2.default)(this, "bottomLeftClass", this.args.bottomLeftClass ? this.args.bottomLeftClass : 'primary');
      (0, _defineProperty2.default)(this, "bottomRightClass", 'primary');
    }

    onClick() {
      this.args.click();
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class.prototype, "onClick", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "onClick"), _class.prototype)), _class));
  _exports.default = UiCellDataComponent;
});