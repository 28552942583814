define("alliance-business-suite/components/machines/programs/water-guardian/midas-wx", ["exports", "alliance-business-suite/mixins/machines/programs/midas-options", "alliance-business-suite/helpers/find-by-dropdown-with-custom", "alliance-business-suite/mixins/machines/programs/midas-property-names"], function (_exports, _midasOptions, _findByDropdownWithCustom, _midasPropertyNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_midasOptions.default, _midasPropertyNames.default, {
    // decimalPlaces: alias('machineProgram.settings.Program.CoinAndPulse.DecimalPointPosition'),
    numberFormatting: Ember.computed('machineProgram.settings.Program.Display.NumericSeparator', function () {
      let value = Ember.get(this, 'machineProgram.settings.Program.Display.NumericSeparator');
      let option = Ember.get(this, 'selectOptions.NumericSeparator').findBy('val', value);
      let thousandsSymbol;
      if (option.decimalSymbol == "") thousandsSymbol = "";
      if (option.decimalSymbol == ".") thousandsSymbol = ",";
      if (option.decimalSymbol == ",") thousandsSymbol = ".";
      let output = {
        decimals: option.decimalPlaces,
        divideBy: option.divideBy,
        decimalSymbol: option.decimalSymbol,
        thousandsSymbol
      };
      return output;
    }),
    currencySymbol: Ember.computed('machineProgram.settings.Program.Price.Misc.Currency1.Symbol', function () {
      let selectedSymbol = Ember.get(this, 'machineProgram.settings.Program.Price.Misc.Currency1.Symbol');
      let options = Ember.get(this, 'selectOptions.CurrencyType');
      let option = (0, _findByDropdownWithCustom.findByDropDown)([options, "val", selectedSymbol]);
      return option.abbr;
    }),
    currencySymbolPosition: Ember.computed('machineProgram.settings.Program.Price.Misc.Currency1.PlaceAfter', function () {
      return Ember.get(this, 'machineProgram.settings.Program.Price.Misc.Currency1.PlaceAfter') === true ? "after" : "before";
    }),
    // Need to observe any properties used for enabled, etc.
    formConfig: Ember.computed(function () {
      return {
        columns: [{
          sections: [{
            title: "temperature",
            controls: [{
              type: "select",
              propertyName: "Program.Misc.MiscConfig.CelsiusEnabled",
              options: 'temperatureUnits'
            }, {
              type: "temperature",
              propertyName: "Program.Cycle.HotWaterTemp_WX",
              min: 35,
              max: 194
            }, {
              type: "temperature",
              propertyName: "Program.Cycle.WarmWaterTemp_WX",
              min: 35,
              max: 194
            }, {
              type: "temperature",
              propertyName: "Program.Cycle.ColdWaterTemp_WX",
              min: 35,
              max: 194
            }, {
              type: "temperature",
              propertyName: "Program.Cycle.CooldownWaterTemp_WX",
              min: 50,
              max: 194
            }, {
              type: "temperature",
              propertyName: "Program.Cycle.ColdValveTempMax_WX",
              min: 50,
              max: 194
            }, {
              type: "temperature",
              propertyName: "Program.Cycle.HotValveTempMax_WX",
              min: 50,
              max: 194
            }]
          }, {
            title: "water_level",
            controls: [{
              type: "number",
              propertyName: "Program.Cycle.HighWaterLevel_WX",
              min: 1,
              max: 30
            }, {
              type: "number",
              propertyName: "Program.Cycle.MediumWaterLevel_WX",
              min: 1,
              max: 30
            }, {
              type: "number",
              propertyName: "Program.Cycle.LowWaterLevel_WX",
              min: 1,
              max: 30
            }]
          }, {
            title: "global_times",
            controls: [{
              type: "number",
              propertyName: "Program.Cycle.RefillWithinTimeMinutes_WX",
              min: 0,
              max: 255,
              labelAfter: "minutes"
            }, {
              type: "number",
              propertyName: "Program.Cycle.FlushoutForFillSeconds_WX",
              min: 0,
              max: 255,
              labelAfter: "seconds"
            }, {
              type: "select",
              propertyName: "Program.Cycle.FlushoutDrainSelection_WX",
              options: 'DrainSelection'
            }]
          }, {
            title: "pulse_drain",
            controls: [{
              type: "number",
              propertyName: "Program.Cycle.PulseDrainOffSeconds_WX",
              min: 1,
              max: 7,
              labelAfter: "seconds"
            }, {
              type: "number",
              propertyName: "Program.Cycle.PulseDrainOnSeconds_WX",
              min: 4,
              max: 31,
              labelAfter: "seconds"
            }]
          }]
        }, {
          sections: [{
            title: "miscellaneous",
            controls: [{
              type: "checkbox",
              propertyName: "Program.Cycle.SlowDrainDetectEnabled_WX"
            }, {
              type: "number",
              propertyName: "Program.Cycle.SlowDrainDetectAdjust_WX",
              min: 0,
              max: 255,
              labelAfter: "seconds" // TODO: Enabled based on control above

            }, {
              type: "number",
              propertyName: "Program.Cycle.TubFillDelaySeconds_WX",
              min: 0,
              max: 60,
              labelAfter: "seconds"
            }, {
              type: "number",
              propertyName: "Program.Cycle.PrecleanCycleLimit_WX",
              min: 0,
              max: 127
            }]
          }, {
            title: "auto_flush_settings",
            controls: [{
              type: "auto-flush",
              properties: this.propertyNames.autoFlush
            }]
          }, {
            title: "water_leak_detection",
            controls: [{
              type: "water-leak-detection",
              properties: this.propertyNames.leakDetection
            }]
          }, {
            title: "suds_removal_settings",
            controls: [{
              type: "checkbox",
              propertyName: "Program.Display.DisplaySudsRemovalRoutine_WX"
            }, {
              type: "checkbox",
              propertyName: "Program.Display.DisplaySudsLockError_WX"
            }, {
              type: "checkbox",
              propertyName: "Program.Cycle.SudsRemovalExtraTime_WX"
            }, {
              type: "number",
              propertyName: "Program.Cycle.SudsRemovalLimit_WX",
              min: 0,
              max: 10
            }]
          }, {
            title: "display_settings",
            controls: [{
              type: "checkbox",
              propertyName: "Program.Display.DisplayOverflowModeEnabled_WX"
            }, {
              type: "text",
              propertyName: "Program.Display.CustomHoldMessage1_WX"
            }, {
              type: "text",
              propertyName: "Program.Display.CustomHoldMessage2_WX"
            }]
          }, {
            title: "external_dispenser",
            controls: [// TODO: Find these properties
            {
              type: "select",
              propertyName: "Program.Cycle.AuxInputA",
              options: 'ExternalDispenserPause'
            }, {
              type: "select",
              propertyName: "Program.Cycle.AuxInputB",
              options: 'ExternalDispenserPause'
            }, {
              type: "select",
              propertyName: "Program.Cycle.AuxInputC",
              options: 'ExternalDispenserPause'
            }, {
              type: "select",
              propertyName: "Program.Cycle.AuxInputD",
              options: 'ExternalDispenserPause'
            }]
          }]
        }]
      };
    })
  });

  _exports.default = _default;
});