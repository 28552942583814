define("alliance-business-suite/components/machines/traceability/data/charts-container", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @data.data}}
    <Machines::Traceability::Data::ChartsTemperature
      @rawTraceData={{@data}}
      @useCelsius={{@useCelsius}}
      @toggleTempUnits={{@toggleTempUnits}}
    />
  
    <Machines::Traceability::Data::ChartsWaterLevel
      @rawTraceData={{@data}}
    />
  {{else}}
    <Ui::PlaceholderMessage
      @classNames="border-top trace-pending"
      @image="/assets/images/basket.svg"
      @title={{"Trace initiated, please wait..."}} />
  {{/if}}
  
  */
  {
    "id": "HvGjrfLn",
    "block": "{\"symbols\":[\"@data\",\"@useCelsius\",\"@toggleTempUnits\"],\"statements\":[[6,[37,0],[[32,1,[\"data\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"machines/traceability/data/charts-temperature\",[],[[\"@rawTraceData\",\"@useCelsius\",\"@toggleTempUnits\"],[[32,1],[32,2],[32,3]]],null],[2,\"\\n\\n  \"],[8,\"machines/traceability/data/charts-water-level\",[],[[\"@rawTraceData\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"ui/placeholder-message\",[],[[\"@classNames\",\"@image\",\"@title\"],[\"border-top trace-pending\",\"/assets/images/basket.svg\",\"Trace initiated, please wait...\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "alliance-business-suite/components/machines/traceability/data/charts-container.hbs"
  });

  class MachinesTraceabilityDataChartsContainerComponent extends _component.default {
    get formatData() {
      let rawData = this.args.data;
      console.log('rawData?', rawData);
      console.log('converting F to C');
      let temperature = [];
      let timeRemaining = [];
      rawData.data.forEach((d, idx) => {
        temperature.push(5 / 9 * (d.Temperature - 32));
        timeRemaining.push(d.TimeRemaining);
      });
      return {
        minutesOrigin: rawData.data[0].TimeRemaining,
        temperature: temperature,
        timeRemaining: timeRemaining
      };
    }

  }

  _exports.default = MachinesTraceabilityDataChartsContainerComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, MachinesTraceabilityDataChartsContainerComponent);
});