define("alliance-business-suite/templates/components/form-ui/checkbox-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "V3aKSwww",
    "block": "{\"symbols\":[],\"statements\":[[2,\"    \"],[10,\"span\"],[12],[2,\"\\n      \"],[10,\"label\"],[14,0,\"form-field--label form-ui-element\"],[12],[2,\"\\n      \"],[8,\"input\",[],[[\"@type\",\"@checked\"],[\"checkbox\",[34,2]]],null],[2,\"\\n      \"],[1,[30,[36,1],[[35,0,[\"checkbox1\",\"label\"]]],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"span\"],[12],[2,\"\\n      \"],[8,\"input\",[[16,\"placeholder\",[34,5]],[24,0,\"form-field--control ember-text-field\"]],[[\"@type\",\"@value\",\"@disabled\",\"@step\",\"@min\",\"@max\",\"@limit\"],[[30,[36,3],[[35,0,[\"number1\",\"type\"]],\"number\"],null],[34,4],[30,[36,6],[[35,2]],null],[34,7],[34,0,[\"number1\",\"min\"]],[34,0,[\"number1\",\"max\"]],[34,0,[\"number1\",\"limit\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n\\n\"],[6,[37,10],[[30,[36,9],[[35,0,[\"number1\",\"labelAfter\"]],[35,8]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"tooltip\"],[12],[2,\"\\n        \"],[1,[30,[36,1],[[35,0,[\"number1\",\"labelAfter\"]]],null]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"el\",\"t\",\"checkbox1\",\"or\",\"number1\",\"placeholder\",\"not\",\"step\",\"showTooltip\",\"and\",\"if\"]}",
    "moduleName": "alliance-business-suite/templates/components/form-ui/checkbox-number.hbs"
  });

  _exports.default = _default;
});