define("alliance-business-suite/adapters/inventory", ["exports", "alliance-business-suite/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    buildURL(modelName, id, snapshot, requestType, query) {
      let url = this._super('', null, snapshot, requestType, query);

      let roomId = Ember.get(snapshot, 'adapterOptions.roomId');

      switch (requestType) {
        case 'findAll':
          url += `/rooms/${roomId}/inventory`;
          return url;

        case 'createRecord':
          url += `/rooms/${roomId}/inventory`;
          return url;

        case 'updateRecord':
          url += `/rooms/${roomId}/inventory`;
          return url;
      }

      return url;
    }

  });

  _exports.default = _default;
});