define("alliance-business-suite/components/form-ui/duration-field/states/manager", ["exports", "alliance-business-suite/components/form-ui/duration-field/utils/state-manager", "alliance-business-suite/components/form-ui/duration-field/utils/state", "alliance-business-suite/components/form-ui/duration-field/states/unfocused", "alliance-business-suite/components/form-ui/duration-field/states/focused"], function (_exports, _stateManager, _state, _unfocused, _focused) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _stateManager.default.extend({
    initialState: 'unfocused',
    unfocused: (0, _state.state)(_unfocused.default),
    focused: (0, _state.state)(_focused.default)
  });

  _exports.default = _default;
});