define("alliance-business-suite/components/organizations/org-select", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "alliance-business-suite/utils/org-setup-util"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _orgSetupUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;

  let OrganizationsOrgSelectComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, (_class = class OrganizationsOrgSelectComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "router", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "session", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "isLoading", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "searchResultsOrg", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "isSearching", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "searchOrgName", _descriptor9, this);
    }

    initialLoad() {
      document.getElementById("input-search-org").focus();

      if (!this.session.data.hasPassedOrgSelect) {
        document.querySelector('body').classList.add('brand-theme');
        return;
      }
    }

    willDestroyOrgSelect() {
      document.querySelector('body').classList.remove('brand-theme');
    }

    get filteredOrgs() {
      let orgs = this.session.data.userOrgs;

      try {
        orgs.sort((a, b) => {
          a.name = String(a.name);
          b.name = String(b.name);

          if (!a.name) {
            throw new Error('missing text property, cant call toLowerCase on undefined');
          }

          a = a.name.toLowerCase();
          b = b.name.toLowerCase();
          return a === b ? 0 : a > b ? 1 : -1;
        });
        return orgs;
      } catch (err) {
        return orgs;
      }
    }

    searchOrgs(key) {
      let {
        filteredOrgs
      } = this;

      if (key) {
        let results = [];
        this.isSearching = true;
        filteredOrgs.forEach(org => {
          let str = org.name.toLowerCase();

          if (str.indexOf(key.toLowerCase()) >= 0) {
            results.push(org);
          }
        });
        this.searchResultsOrg = results;
      } else {
        this.isSearching = false;
      }
    }

    onChange() {
      this.searchOrgs(this.searchOrgName);
    }

    clearSearch() {
      this.searchOrgName = null;
      this.isSearching = false;
    }

    setOrganization(orgId, orgBrand) {
      this.isLoading = true;

      _orgSetupUtil.findOrg.call(this, orgId, orgBrand);
    }

    logout() {
      this.session.invalidate();
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "session", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isLoading", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "searchResultsOrg", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isSearching", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "searchOrgName", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "initialLoad", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "initialLoad"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "willDestroyOrgSelect", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "willDestroyOrgSelect"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onChange", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "onChange"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "clearSearch", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "clearSearch"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setOrganization", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "setOrganization"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "logout", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "logout"), _class.prototype)), _class));
  _exports.default = OrganizationsOrgSelectComponent;
});