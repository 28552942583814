define("alliance-business-suite/components/ui/countdown-timer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Format a moment duration.
   *
   * NOTE: This implementation may not work for durations greater than 24 hours.
   *       [twl 28.Apr.17]
   *
   * TODO: Move this to a common location, since it's likely we'll want to use
   *       this in the future; maybe use it in the `f` helper. [twl 28.Apr.17]
   *
   * @param {moment.duration} duration A moment duration
   * @param {string}          format   The format to use
   *
   * @returns {string} A formatted duration.
   * @public
   */
  function formatDuration(duration, format) {
    return moment('2000-01-01 00:00:00').add(duration).format(format);
  }

  var _default = Ember.Component.extend({
    classNames: ['countdown-timer'],
    activeSetTimeout: null,
    liveTimer: '00:00',

    didInsertElement() {
      this.buildCountdownTimer(this);
    },

    didUpdateAttrs() {
      this._super(...arguments);

      this.buildCountdownTimer(this);
    },

    willDestroyElement() {
      this._super(...arguments);

      clearTimeout(Ember.get(this, 'activeSetTimeout'));
    },

    buildCountdownTimer(context) {
      clearTimeout(Ember.get(this, 'activeSetTimeout'));
      let counter = 0;
      let timeRemaining = Ember.get(this, 'timeRemaining');
      let lastUpdated = Ember.get(this, 'lastUpdated') || moment();
      let diff = moment().diff(moment(lastUpdated), 'seconds');
      let timer = setInterval(() => {
        if (timeRemaining <= diff || timeRemaining <= 0) {
          clearInterval(timer);
          counter = 0;
        } else {
          counter = timeRemaining - diff;
          timeRemaining--;
        }

        Ember.set(context, 'liveTimer', formatDuration(moment.duration(counter * 1000), counter >= 3600 ? 'hh:mm:ss' : 'mm:ss'));
      }, 1000);
      Ember.set(this, 'activeSetTimeout', timer);
    }

  });

  _exports.default = _default;
});