define("alliance-business-suite/components/form-ui/daylight-saving", ["exports", "alliance-business-suite/mixins/modals", "alliance-business-suite/mixins/init-property-names", "alliance-business-suite/mixins/show-component"], function (_exports, _modals, _initPropertyNames, _showComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_showComponent.default, _modals.default, _initPropertyNames.default, {
    optionsMonths: [{
      label: "January",
      val: 1
    }, {
      label: "February",
      val: 2
    }, {
      label: "March",
      val: 3
    }, {
      label: "April",
      val: 4
    }, {
      label: "May",
      val: 5
    }, {
      label: "June",
      val: 6
    }, {
      label: "July",
      val: 7
    }, {
      label: "August",
      val: 8
    }, {
      label: "September",
      val: 9
    }, {
      label: "October",
      val: 10
    }, {
      label: "November",
      val: 11
    }, {
      label: "December",
      val: 12
    }],
    optionsWeeks: [{
      label: "Week1",
      val: 1
    }, {
      label: "Week2",
      val: 2
    }, {
      label: "Week3",
      val: 3
    }, {
      label: "LastWeek",
      val: 4
    } // { label: "Week5", val: 5 } per docs, only 1-4 are valid values.
    ],
    optionsWeekdays: Ember.computed('params.weekdayIndex', function () {
      // We know that Midas and Titan use a 1-based index
      if (this.machineProgram && ["TITAN", "MIDAS"].includes(this.machineProgram._machineGeneration)) {
        return [{
          label: "Sunday",
          val: 1
        }, {
          label: "Monday",
          val: 2
        }, {
          label: "Tueday",
          val: 3
        }, {
          label: "Wednesday",
          val: 4
        }, {
          label: "Thursday",
          val: 5
        }, {
          label: "Friday",
          val: 6
        }, {
          label: "Saturday",
          val: 7
        }];
      } else {
        // TODO: Remove this section after confirming it's not used for non-TITAN/MIDAS machines
        if (this.params.weekdayIndex === 0) {
          // ACAS-A4 has Sunday=0
          return [{
            label: "Sunday",
            val: 0
          }, {
            label: "Monday",
            val: 1
          }, {
            label: "Tueday",
            val: 2
          }, {
            label: "Wednesday",
            val: 3
          }, {
            label: "Thursday",
            val: 4
          }, {
            label: "Friday",
            val: 5
          }, {
            label: "Saturday",
            val: 6
          }];
        } else {
          // Midas has Sunday=1
          return [{
            label: "Sunday",
            val: 1
          }, {
            label: "Monday",
            val: 2
          }, {
            label: "Tueday",
            val: 3
          }, {
            label: "Wednesday",
            val: 4
          }, {
            label: "Thursday",
            val: 5
          }, {
            label: "Friday",
            val: 6
          }, {
            label: "Saturday",
            val: 7
          }];
        }
      }
    })
  });

  _exports.default = _default;
});