define("alliance-business-suite/components/marketing/campaign-list-table", ["exports", "alliance-business-suite/mixins/crm"], function (_exports, _crm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crm.default, {
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    intl: Ember.inject.service(),
    access: Ember.inject.service(),
    intlPrefix: 'components.crm',
    classNames: ['sortable-table'],

    didInsertElement() {
      this._super(...arguments);

      this.campaignTypes();
    },

    campaignTypes() {
      let _this = this;

      this.set('isLoading', true);
      this.store.findAll('campaign', {
        reload: true
      }).then(results => {
        setTimeout(() => {
          if (!_this.isDestroyed) {
            let sortedResults = results.sortBy('name');
            let result = [];
            sortedResults.forEach(campaign => {
              if (campaign.campaignTypeLookupKey === "REENGAGEMENT") {
                if (campaign.deliveryMethodLookupKey.length) {
                  let deliveryKeys;
                  let deliveryMethodLookupKeys = [];
                  deliveryKeys = campaign.deliveryMethodLookupKey;
                  deliveryKeys.forEach(function (deliveryMessage) {
                    let messageTypes = deliveryMessage.get('lookupKey');
                    deliveryMethodLookupKeys.push(messageTypes);
                  });

                  if (campaign.deliveryMethodLookupKeys === undefined) {
                    campaign.set('deliveryMethodLookupKeys', deliveryMethodLookupKeys);
                  }
                } else {
                  if (campaign.deliveryMethodLookupKeys === undefined) {
                    if (campaign.deliveryMethodMessages.length) {
                      let deliveryKeys;
                      let deliveryMethodLookupKeys = [];
                      deliveryKeys = campaign.deliveryMethodMessages;
                      deliveryKeys.forEach(function (deliveryMessage) {
                        let messageTypes = deliveryMessage.get('deliveryMethodTypeLookupKey');
                        deliveryMethodLookupKeys.push(messageTypes);
                      });
                      campaign.set('deliveryMethodLookupKeys', deliveryMethodLookupKeys);
                    }
                  }
                }
              }

              result.push(campaign);
            });

            if (result.length) {
              _this.set('isLoading', false);

              _this.set('campaigns', result);
            } else {
              _this.set('isLoading', false);
            }
          }
        }, function () {
          if (!_this.isDestroyed) {
            _this.set('isLoading', false);
          }
        }, 4000);
      });
    },

    transitionToCampaignManage(record, context) {
      let recordId = record.selectedItems[0].get('id');
      context.router.transitionTo('crm.campaigns.manage', recordId);
    },

    columns: Ember.computed(function () {
      return [{
        propertyName: 'name',
        title: this.intl.t('campaign_name') // }, {
        //   propertyName: 'deliveryMethodLookupKeys',
        //   title: '',
        //   component: 'table/table-cell-icon',

      }, {
        propertyName: 'campaignTypeLookupKey',
        title: this.intl.t('type'),
        component: 'table/table-cell-pill'
      }, {
        propertyName: 'hasPromotion',
        title: '',
        component: 'table/table-cell-pill',
        customIsPromotional: true
      }, {
        propertyName: 'createdAt',
        title: this.intl.t('created_at'),
        component: 'table/table-cell-date',
        customDateType: 'useLocalTimezone',
        sortDirection: 'desc',
        sortPrecedence: 1
      }, {
        propertyName: 'lastSentDate',
        title: this.intl.t('last_run'),
        customDateType: 'useLocalTimezone',
        nullValue: ' ',
        component: 'table/table-cell-date'
      }, {
        propertyName: 'isApproved',
        title: this.intl.t('campaign_approved'),
        component: 'table/table-cell-boolean-checklist'
      }];
    })
  });

  _exports.default = _default;
});