define("alliance-business-suite/components/form-ui/textarea", ["exports", "alliance-business-suite/mixins/form-ui"], function (_exports, _formUi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_formUi.default, {
    tagName: 'div',
    classNames: ['form-field', 'overflow-float'] // maxlength: 524288

  });

  _exports.default = _default;
});