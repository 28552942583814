define("alliance-business-suite/components/input/input-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    formattedValue: Ember.computed('value', 'digits', function () {
      let value = Ember.get(this, 'value');
      let decimal = Ember.get(this, 'decimal');
      let digits = Ember.get(this, 'digits');
      let valueFloat = parseFloat(value);
      let decimalBool = false;
      let digitsInt = 2;

      if (decimal !== undefined) {
        decimalBool = decimal;
      }

      if (decimalBool && digits !== undefined) {
        digitsInt = parseInt(digits);
      }

      let formattedValue = valueFloat.toString();

      if (decimalBool) {
        formattedValue = valueFloat.toFixed(digitsInt);
      }

      return formattedValue;
    }),
    actions: {
      increment() {
        let value = Ember.get(this, 'value');
        let step = Ember.get(this, 'step');
        let minimum = Ember.get(this, 'min');
        let maximum = Ember.get(this, 'max');
        let valueFloat = parseFloat(value);
        let stepFloat = 1;

        if (step !== undefined && step !== 'any') {
          stepFloat = parseFloat(step);
        }

        let minimumEnabled = false;
        let minimumFloat = 0;
        let maximumEnabled = false;
        let maximumFloat = 0;

        if (minimum !== undefined) {
          minimumEnabled = true;
          minimumFloat = parseFloat(minimum);
        }

        if (maximum !== undefined) {
          maximumEnabled = true;
          maximumFloat = parseFloat(maximum);
        }

        let newValueFloat = valueFloat + stepFloat;
        let allowChange = true;

        if (minimumEnabled && newValueFloat < minimumFloat) {
          allowChange = false;
        }

        if (maximumEnabled && newValueFloat > maximumFloat) {
          allowChange = false;
        }

        if (allowChange) {
          Ember.set(this, 'value', newValueFloat);
        }
      },

      decrement() {
        let value = Ember.get(this, 'value');
        let step = Ember.get(this, 'step');
        let minimum = Ember.get(this, 'min');
        let maximum = Ember.get(this, 'max');
        let valueFloat = parseFloat(value);
        let stepFloat = 1;

        if (step !== undefined && step !== 'any') {
          stepFloat = parseFloat(step);
        }

        let minimumEnabled = false;
        let minimumFloat = 0;
        let maximumEnabled = false;
        let maximumFloat = 0;

        if (minimum !== undefined) {
          minimumEnabled = true;
          minimumFloat = parseFloat(minimum);
        }

        if (maximum !== undefined) {
          maximumEnabled = true;
          maximumFloat = parseFloat(maximum);
        }

        let newValueFloat = valueFloat - stepFloat;
        let allowChange = true;

        if (minimumEnabled && newValueFloat < minimumFloat) {
          allowChange = false;
        }

        if (maximumEnabled && newValueFloat > maximumFloat) {
          allowChange = false;
        }

        if (allowChange) {
          Ember.set(this, 'value', newValueFloat);
        }
      }

    }
  });

  _exports.default = _default;
});