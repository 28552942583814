define("alliance-business-suite/helpers/first-valid-property-value", ["exports", "alliance-business-suite/utils/machine-programming-property-names"], function (_exports, _machineProgrammingPropertyNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (_ref, hash) {
    let [obj, ...properties] = _ref;

    if (Ember.typeOf(obj) !== 'object') {
      console.error("First param of first-valid-property-value must be an object representing the model to use.");
      return;
    }

    if (properties === undefined) {
      console.error("Second param of first-valid-property-value must be a string of the property name to use.");
      return;
    }

    let propertyName = (0, _machineProgrammingPropertyNames.findValidPropertyName)(obj, properties);

    if (propertyName === undefined) {
      console.error("No valid property name found for the provided object and properties.");
      return;
    }

    return Ember.get(obj, propertyName);
  });

  _exports.default = _default;
});