define("alliance-business-suite/components/machines/programs/cycles/mc10-opl", ["exports", "ember-copy", "jquery", "alliance-business-suite/mixins/machines/programs/mc10-options", "alliance-business-suite/utils/is-number"], function (_exports, _emberCopy, _jquery, _mc10Options, _isNumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Removed CycleGlobal mixin!
  var _default = Ember.Component.extend(_mc10Options.default, {
    //#region Variables
    notifications: Ember.inject.service(),
    intl: Ember.inject.service(),
    clipboard: {
      type: null,
      contents: null,
      title: null
    },
    maxCycles: 99,
    showCycleInfo: false,
    hasCycleNumbers: true,

    //#endregion
    //#region Events
    didInsertElement() {
      this._super(...arguments);

      this.send('selectCycle');
      Ember.set(this, 'defaultMachineType', Ember.get(this, 'cycleList.firstObject.MachineType')); // set(this, 'hasCycleNumbers', get(this, 'cycleList.firstObject.Number') ? true : false);
      // TODO: How do we know if this is for cycles or steps?
      // Catch Ctrl+Up and Ctrl+Down keyboard events to move the selected step position in the list.
      // Mousetrap.bind(['mod+up', 'mod+down'], function(e, combo) {
      //   e.preventDefault();
      //   e.stopPropagation();
      //   let cycle = self.get('selectedCycle');
      //   let stepIndex = self.get('selectedStepIndex');
      //   let totalSteps = cycle.get('length');
      //   if (combo === 'mod+up') {
      //     if (stepIndex > 0) {
      //       self.swapArrayItems(cycle, stepIndex, stepIndex - 1);
      //       self.set('selectedStepIndex', stepIndex - 1);
      //     }
      //   } else {
      //     if (stepIndex < totalSteps - 1) {
      //       self.swapArrayItems(cycle, stepIndex, stepIndex + 1);
      //       self.set('selectedStepIndex', stepIndex + 1);
      //     }
      //   }
      // });
    },

    //#endregion
    //#region Default values
    // TODO: Need to set the MachineType value
    defaultCycle: Ember.computed(function () {
      return {
        Steps: [],
        Number: 0,
        Enabled: false,
        CycleName: "",
        DisinfectTemperature: 0,
        DisinfectTimeMinutes: 0,
        ProgrammableCycleTime: 60,
        TempTimeVerificationEnable: false,
        ProgrammableCycleTimeEnable: false
      };
    }),
    defaultStep: {
      Type: 14,
      Inlets: {
        Inlet1: false,
        Inlet2: false,
        Inlet3: false,
        Inlet4: false,
        Inlet5: false,
        Inlet6: false,
        Inlet7: false,
        Inlet8: false
      },
      DrainType: 2,
      WashSpeed: 45,
      // database
      Detergents: {
        D1: false,
        D2: false,
        D3: false,
        D4: false,
        D5: false,
        D6: false,
        D7: false,
        D8: false,
        D9: false,
        D10: false,
        D11: false,
        D12: false,
        D13: false,
        D14: false,
        D15: false,
        D16: false
      },
      DrainSpeed: 250,
      // database
      DrainValve: 1,
      WaterLevel: 15,
      // database
      Temperature: 86,
      StepDisabled: true,
      StepEndPause: false,
      WashDuration: 300,
      DrainDuration: 60,
      WashPauseTime: 3,
      DetergentTimes: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      DrainPauseTime: 3,
      WashRotateTime: 12,
      WaterLevelType: 4,
      DrainRotateTime: 0,
      DrainValveForCooldown: 0,
      Enabled: true
    },
    //#endregion
    //#region Computed properties
    // TODO: Make sure the cycle array is sorted based on Number.
    cycleList: Ember.computed.alias('model.settings.Cycles'),
    stepList: Ember.computed('selectedCycle', 'selectedCycle.[]', function () {
      // Steps can be in an unnamed array, or an array named `Steps`
      if (Ember.get(this, 'selectedCycle.Steps')) {
        return Ember.get(this, 'selectedCycle.Steps');
      } else {
        return this.selectedCycle || [];
      }
    }),

    //#endregion
    //#region Methods
    resetCycleNumbers() {
      console.log("*** resetCycleNumbers");

      if (this.hasCycleNumbers) {
        this.cycleList.forEach((cycle, index) => {
          // TODO: Check if cycle.Number is zero-based
          Ember.set(cycle, 'Number', index + 1);
        });
      }
    },

    swapArrayItems(array, index, direction) {
      // Used to re-order array items
      let secondIndex = direction === 'up' ? index - 1 : index + 1;
      let firstItem = [array[index]];
      let secondItem = [array[secondIndex]];

      if (direction == "up" && index > 0 || direction == 'down' && index < array.get('length') - 1) {
        array.replace(index, 1, secondItem).replace(secondIndex, 1, firstItem);
      }

      return array;
    },

    //#endregion
    //#region Actions
    actions: {
      closeModal() {
        Ember.set(this, 'showCycleInfo', false);
      },

      editCycleInfo(cycle) {
        Ember.set(this, 'showCycleInfo', true);
      },

      moveCycle(cycle, direction) {
        let array = this.cycleList;
        let index = array.indexOf(cycle);
        this.swapArrayItems(array, index, direction);
      },

      moveStep(step, direction) {
        let array = this.stepList;
        let index = array.indexOf(step);
        this.swapArrayItems(array, index, direction);
      },

      addCycle() {// NOT SUPPORTED FOR OPL MACHINES
        // // New for MC10
        // let maxCycles = this.maxCycles;
        // let list = this.cycleList;
        // if (list.length < maxCycles) {
        //   let newItem = copy(this.defaultCycle);
        //   let index = list.length;
        //   list.insertAt(index, newItem);
        //   this.send('selectCycle', index);
        //   this.resetCycleNumbers();
        // } else {
        //   this.notifications.error('Unable to add a new cycle because this machine only supports 15 cycles. Please delete a cycle and try again.', {
        //     autoClear: false,
        //     clearDuration: 1000,
        //     cssClasses: 'quick-notification'
        //   });
        // }
      },

      deleteCycle(cycle) {
        let cycleList = this.cycleList;

        if (cycleList.length === 0) {
          return;
        }

        let description = name || 'Cycle';

        if (confirm(`Delete selected ${description}?`)) {
          let index = cycleList.indexOf(cycle); // We don't actually delete anything...we just set the steps to an empty array
          // cycleList[index] = [];
          // this.notifyPropertyChange('model');
          // We used to change this to an empty array, but that doesn't work because MC10 has objects, so we set things to defaults and clear steps.
          // Clear steps

          cycleList[index] = this.defaultCycle;
          this.notifyPropertyChange('model');
          this.send("selectCycle", index); // this.notifyPropertyChange(`model.settings.Cycles.${index}`);
          // this.notifyPropertyChange(`model.settings.Cycles.${index}.CycleName`);
        }
      },

      selectCycle(value, init) {
        // Value can be the cycle object or the index of the cycle
        let index, cycle;

        if (value === undefined) {
          value = 0;
        }

        if (isNaN(value)) {
          // we have an object
          cycle = value;
          index = this.cycleList.indexOf(value);
        } else {
          // we have a number
          index = value;
          cycle = this.cycleList.objectAt(value);
        }

        Ember.set(this, 'selectedCycle', cycle);
        Ember.set(this, 'selectedCycleIndex', Number(index));
        localStorage.setItem('cycle', index || 0);

        if (!init) {
          // Select first step
          this.send('selectStep');
        }
      },

      selectStep(step) {
        if (!step) {
          step = Ember.get(this, 'stepList.firstObject');
        } else if ((0, _isNumber.isNumber)(step)) {
          step = this.stepList.objectAt(Number(step));
        }

        let index = this.stepList.length > 0 ? this.stepList.indexOf(step) : 0;
        Ember.set(this, 'selectedStep', step);
        (0, _jquery.default)(step).focus();
        localStorage.setItem('step', index || 0);
      },

      // These are from the mixin
      deleteStep(step) {
        let stepList = this.stepList;

        if (stepList.length === 0) {
          return;
        }

        let id = Ember.get(step, 'Type');
        let selectOptions = this.selectOptions;
        let description = name || 'Current step';

        if (confirm(`Delete selected ${description}?`)) {
          let index = stepList.indexOf(step);
          stepList.removeAt(index); // Now we need to set the next step that is active.

          let selected;
          let newIndex = stepList.length > index ? index : index - 1;

          if (newIndex >= 0) {
            selected = stepList.objectAt(newIndex);
            Ember.set(this, 'selectedStep', selected);
            Ember.set(this, 'selectedStepIndex', newIndex);
            (0, _jquery.default)(selected).focus();
          }
        }
      },

      addStep(index) {
        // TODO: Be able to add steps within the list of steps, instead of always at the bottom.
        let newItem = (0, _emberCopy.copy)(this.defaultStep);
        let list = this.stepList; // Click action can pass in proxy object as index

        index = (0, _isNumber.isNumber)(index) ? index : list.length || 0;
        list.insertAt(index, newItem);
        this.send('selectStep', index);
      },

      copyCycle(cycle) {
        Ember.set(this, 'clipboard.type', 'cycle');
        Ember.set(this, 'clipboard.contents', (0, _emberCopy.copy)(cycle));
        Ember.set(this, 'clipboard.title', 'Name will be here');
        this.notifications.success('Cycle copied', {
          autoClear: true,
          clearDuration: 1000,
          cssClasses: 'quick-notification'
        });
        return false;
      },

      copyStep(step) {
        Ember.set(this, 'clipboard.type', 'step');
        Ember.set(this, 'clipboard.contents', (0, _emberCopy.copy)(step)); // step is an object

        this.notifications.success('Step copied', {
          autoClear: true,
          clearDuration: 1000,
          cssClasses: 'quick-notification'
        });
        return false;
      },

      pasteCycle(cycle) {
        // Copied from cycle-context-menu
        let type = Ember.get(this, 'clipboard.type');

        if (type !== 'cycle') {
          alert(this.intl.t("no_cycle_in_clipboard"));
          return false;
        }

        let clipboard = (0, _emberCopy.copy)(Ember.get(this, 'clipboard.contents'), true);
        let cycles = this.cycleList;
        let index = cycles.indexOf(cycle); // We may need to preserve the Name and Number of the existing cycle

        let cycleName = cycles.objectAt(index).Name || cycles.objectAt(index).CycleNameText;
        let cycleNumber = cycles.objectAt(index).Number || cycles.objectAt(index).CycleNumber; // Remove current cycle and insert copied cycle

        cycles.removeAt(index);
        cycles.insertAt(index, clipboard);
        let pastedCycle = cycles.objectAt(index);
        let keepOldName = false; // Might be used for some other machines

        if (keepOldName) {
          Ember.set(pastedCycle, 'Name', cycleName);
        } // CHANGED: This was moved from the above IF statement


        Ember.set(pastedCycle, 'Number', cycleNumber);
        this.selectCycle(index);
        this.notifications.success(this.intl.t('cycle_pasted'), {
          autoClear: true,
          clearDuration: 1000,
          cssClasses: 'quick-notification'
        }); // CHANGED: This was not part of the copied code...but also doesn't seem to work.

        this.resetCycleNumbers();
        return false;
      },

      pasteStep(step) {
        // We're going to paste the new step above the selected on
        let type = Ember.get(this, 'clipboard.type');

        if (type !== 'step') {
          alert('No step is currently in your clipboard.');
          return false;
        }

        let clipboard = (0, _emberCopy.copy)(Ember.get(this, 'clipboard.contents'));
        let steps = this.stepList;
        let index = steps.indexOf(step); // Copy the Label and ActiveState

        let label, activeState;

        if (step) {
          label = Ember.get(step, 'Label');
          activeState = Ember.get(step, 'ActiveState');
        } else {
          // Defaults
          label = 1;
          activeState = 8;
        }

        Ember.set(clipboard, 'Label', label);
        Ember.set(clipboard, 'ActiveState', activeState);
        steps.insertAt(index, clipboard);
        this.send('selectStep', index);
        let notifications = this.notifications;
        this.notifications.success('Step pasted', {
          autoClear: true,
          clearDuration: 1000,
          cssClasses: 'quick-notification'
        });
        return false;
      }

    } //#endregion

  });

  _exports.default = _default;
});