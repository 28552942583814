define("alliance-business-suite/utils/intl/missing-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = missingMessage;

  /* global appThis */
  function missingMessage(key, locales) {
    let devtools = window.appThis.get('devtools');
    console.error(`Missing english translation: ${key}`);
    devtools.addIntlKey(key);
    return key;
  }
});