define("alliance-business-suite/components/can-access", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    canAccess: Ember.computed('params.[]', function () {
      let area = this.params[0];
      let access = this['can-access'];
      return access.canAccessAny(area);
    }),
    sfa: Ember.computed('params.[]', function () {
      return this.params[0];
    }),
    isDebug: Ember.computed('can-access.securityDebugging', function () {
      // TODO: Debugging should be restricted to admin/owner-operator users!
      return Ember.get(this, 'can-access.securityDebugging');
    })
  }).reopenClass({
    positionalParams: 'params'
  });

  _exports.default = _default;
});