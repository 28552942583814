define("alliance-business-suite/helpers/minutes-seconds", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (_ref, namedArgs) {
    let [value, ...rest] = _ref;
    let totalSeconds = value;

    if (totalSeconds && totalSeconds > 0) {
      let totalMinutes = parseInt(totalSeconds / 60);
      let hours = parseInt(totalSeconds / 60 / 60);
      let minutes = totalMinutes % 60;
      let seconds = totalSeconds % 60;

      if (namedArgs.minutesOnly) {
        return seconds > 0 ? totalMinutes + 1 : totalMinutes;
      } else if (hours > 0) {
        return `${hours}:${`0${minutes}`.substr(-2)}:${`0${seconds}`.substr(-2)}`;
      } else {
        return `${minutes}:${`0${seconds}`.substr(-2)}`;
      }
    } else {
      return namedArgs.minutesOnly ? '0' : '0:00';
    }
  });

  _exports.default = _default;
});