define("alliance-business-suite/components/summary/reports/s-monthly-overview", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "alliance-business-suite/utils/currency-format-util"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _currencyFormatUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  let SummaryReportsSMonthlyOverviewComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, (_class = class SummaryReportsSMonthlyOverviewComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor3, this);
    }

    get translatedColumns() {
      return [{
        name: 'empty_string',
        width: 300,
        isFixed: 'left',
        subcolumns: [{
          name: 'Organization',
          valuePath: 'name',
          width: 300
        }]
      }, {
        name: 'deposits',
        textAlign: 'center',
        subcolumns: [{
          name: 'cash',
          valuePath: 'cashDeposits',
          textAlign: 'center',
          width: 180
        }, {
          name: 'credit',
          valuePath: 'creditDeposits',
          textAlign: 'center',
          width: 180
        }]
      }, {
        name: 'spending',
        textAlign: 'center',
        subcolumns: [{
          name: 'washers',
          valuePath: 'washerRevenue',
          textAlign: 'center',
          width: 180
        }, {
          name: 'dryers',
          valuePath: 'dryerRevenue',
          textAlign: 'center',
          width: 180
        }, {
          name: 'WDF',
          valuePath: 'serviceRevenue',
          textAlign: 'center',
          width: 180
        }, {
          name: 'retail',
          valuePath: 'goodsRevenue',
          textAlign: 'center',
          width: 180
        }, {
          name: 'tax',
          valuePath: 'tax',
          textAlign: 'center',
          width: 180
        }]
      }, {
        name: 'rewards',
        textAlign: 'center',
        width: 250,
        subcolumns: [{
          name: 'rewards_earned',
          textAlign: 'center',
          valuePath: 'rewardsEarned',
          width: 250
        }, {
          name: 'rewards_spent',
          textAlign: 'center',
          valuePath: 'rewardsSpent',
          width: 250
        }]
      }, {
        name: 'empty_string',
        textAlign: 'center',
        width: 250,
        subcolumns: [{
          name: 'float_amount_at_closing_date',
          textAlign: 'center',
          valuePath: 'floatAmountClosing',
          width: 250
        }, {
          name: 'number_of_manual_starts',
          textAlign: 'center',
          valuePath: 'manualStarts',
          width: 250
        }]
      }];
    }

    get rowsWithChildren() {
      let {
        model
      } = this.args;
      let geoBoundaries = model.geoBoundaries;
      let rowData = [];
      geoBoundaries.forEach(geo => {
        rowData.push({
          name: geo.name,
          children: [{
            cashDeposits: _currencyFormatUtil.formatCurrencyString.call(this, geo.cashDeposits || 0),
            creditDeposits: _currencyFormatUtil.formatCurrencyString.call(this, geo.creditDeposits || 0),
            washerRevenue: _currencyFormatUtil.formatCurrencyString.call(this, geo.washerRevenue || 0),
            dryerRevenue: _currencyFormatUtil.formatCurrencyString.call(this, geo.dryerRevenue || 0),
            serviceRevenue: _currencyFormatUtil.formatCurrencyString.call(this, geo.serviceRevenue || 0),
            goodsRevenue: _currencyFormatUtil.formatCurrencyString.call(this, geo.goodsRevenue || 0),
            tax: _currencyFormatUtil.formatCurrencyString.call(this, geo.tax || 0),
            rewardsEarned: _currencyFormatUtil.formatCurrencyString.call(this, geo.rewardsEarned || 0),
            rewardsSpent: _currencyFormatUtil.formatCurrencyString.call(this, geo.rewardsSpent || 0),
            floatAmountClosing: _currencyFormatUtil.formatCurrencyString.call(this, geo.floatAmountClosing || 0),
            manualStarts: geo.manualStarts || 0
          }]
        });
      });
      return rowData;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SummaryReportsSMonthlyOverviewComponent;
});