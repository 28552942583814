define("alliance-business-suite/components/billing/subscription-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'tr',
    store: Ember.inject.service(),
    access: Ember.inject.service(),
    showExpirationDate: Ember.computed('item.expirationDate', function () {
      let exp = this.item.expirationDate;

      if (exp === '2100-01-01T00:00:00') {
        return false;
      }

      return exp;
    }),
    expiringSoon: Ember.computed('item', 'access.locationsExpiringSoonIds', function () {
      let {
        item,
        access
      } = this;
      let locationsExpiringSoonIds = access.locationsExpiringSoonIds;
      let roomId = item.id;

      if (locationsExpiringSoonIds.indexOf(roomId) != -1) {
        return true;
      }
    }).readOnly(),
    dateFormat: Ember.computed(function () {
      let userId = Ember.get(this, 'access.accessUser.id');
      let user = Ember.get(this, 'store').peekRecord('employee', userId);
      return user.get('dateFormat');
    }),
    isActiveAgreement: Ember.computed('item', function () {
      let item = this.item;
      return moment(item.expirationDate).isAfter(moment());
    }),
    canUpgrade: Ember.computed('item', function () {
      let {
        item
      } = this;

      if (item.service && item.service.genericProductLookupKey == 'NETWORKING_PLUS_PAYMENT') {
        return true;
      }

      if (item.availableAddons && item.availableAddons.length > 0) {
        return true;
      }
    }),
    actions: {
      showModal() {
        this.togglePaymentForm();
      },

      showUpgradeModal() {
        this.toggleUpgradeForm();
      }

    }
  });

  _exports.default = _default;
});