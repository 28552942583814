define("alliance-business-suite/components/form-ui/tyui-select", ["exports", "alliance-business-suite/mixins/form-ui", "alliance-business-suite/helpers/find-by-dropdown-with-custom", "alliance-business-suite/utils/convert-temperature"], function (_exports, _formUi, _findByDropdownWithCustom, _convertTemperature) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_formUi.default, {
    tagName: 'div',
    intl: Ember.inject.service(),
    customValue: null,

    //#region Events
    init() {
      this._super(...arguments);

      let val = this.value;
      let selected; // We must have options to continue.

      try {
        selected = this.options.findBy('val', val) || this.options.findBy('isCustom') || null;
      } catch (err) {// We didn't have an Ember object to work with
      }

      if (selected) {
        Ember.set(this, 'selectedOption', selected);
      }
    },

    //#endregion
    //#region Computed properties
    customPropertyName: Ember.computed('params.{propertyName,customPropertyName}', function () {
      // Use the propertyName if no customPropertyName is provided
      return Ember.get(this, 'params.customPropertyName') || Ember.get(this, 'params.propertyName');
    }),
    // isTemperature: computed('params.customType', function() {
    //   // return get(this, 'params.customType') == 'temperature';
    //   switch (get(this, 'params.customType')) {
    //     case "temperature":
    //     case "temperature-rate":
    //       return true;
    //     default:
    //       return false;
    //   }
    // }),
    isTemperature: Ember.computed.equal('params.customType', 'temperature'),
    isTemperatureRate: Ember.computed.equal('params.customType', 'temperature-rate'),
    isSpeed: Ember.computed.equal('params.customType', 'speed'),
    // isSpeed: computed('params.customType', function() {
    //   return get(this, 'params.customType') == 'speed';
    // }),
    speedSettings: Ember.computed.alias('params.speedSettings'),

    // isCelsius: alias('formSettings.isCelsius'),
    serializePresetValue(value) {
      // Serialize to display value
      if (Ember.get(this, 'isTemperature') && Ember.get(this, 'formSettings.isCelsius')) {
        return (0, _convertTemperature.toC)(value);
      }

      return value;
    },

    deserializePresetValue(value) {// Not used
    },

    yieldHash: Ember.computed('value', 'options', function () {
      /*
      Always yield the current option that is selected, BUT...
      The presetValue is the last valid preset that was selected. isCustom has no presetValue.
      */
      let options = Ember.get(this, 'options');
      let value = Ember.get(this, 'value');
      let selectedOption = (0, _findByDropdownWithCustom.findByDropDown)([options, "val", value]); // This is the actual option selected. Do not mutate!
      // If custom is selected, send presetValue of last preset.

      let myreturn = {
        val: selectedOption.val,
        label: selectedOption.label,
        isCustom: selectedOption.isCustom,
        presetValue: selectedOption.presetValue,
        lastPresetValue: Ember.get(this, 'lastPresetValue')
      };
      return myreturn;
    }),
    isCustomSelected: Ember.computed('value', 'options', function () {
      let options = Ember.get(this, 'options');
      let value = Ember.get(this, 'value');
      let selectedOption = (0, _findByDropdownWithCustom.findByDropDown)([options, "val", value]);
      return selectedOption.isCustom;
    }),
    options: Ember.computed('params.options', 'formSettings.selectOptions', function () {
      // overrides options CP in mixin
      let options = Ember.get(this, 'params.options'); // What options we're going to display
      // We don't get options for checkboxes

      if (Ember.isNone(options)) {
        return [{
          val: true,
          label: this.propertyName
        }];
      }

      if (options && typeof options !== "string") {
        // An object was passed in
        return options || {
          val: null,
          label: 'Invalid options'
        };
      } else {
        // Find options and return them.
        if (options) {
          // A string was passed into 'options'
          return Ember.get(this, `formSettings.selectOptions.${options}`) || {
            val: null,
            label: 'Data not found.'
          };
        } else {
          // Nothing passed in, so fall back to propertyName
          let propertyName = Ember.get(this, 'propertyName');
          let key = propertyName.split('.').slice(-1)[0];
          let so = Ember.get(this, 'formSettings.selectOptions');
          let output = Ember.get(so, key) || {
            val: null,
            label: 'Data not found.'
          };
          return output;
        }
      }
    }),
    sharedCustomProperty: Ember.computed('params.{hasCustom,customPropertyName}', function () {
      // If hasCustom is true but we have no customPropertyName, then one property is used
      // for both and sharedCustomProperty is true.
      // If sharedCustomProperty is passed in, that value will replace this function.
      let hasCustom = Ember.get(this, 'params.hasCustom');
      let customPropertyName = Ember.get(this, 'params.customPropertyName');
      return hasCustom && customPropertyName === undefined;
    }),
    //#endregion
    //#region Actions
    actions: {
      clickItem() {
        alert('clicked');
      },

      blockBubbles(select, event) {
        if (event.target && event.target.tagName === 'INPUT') {
          event.stopPropagation();
          return false; // This is required to stop the bubbles
        }
      },

      isSingleOption: Ember.computed('options', function () {
        return Ember.isNone(this.options);
      }),

      doChange(val) {
        Ember.set(this, 'selectedOption', val);

        if (Ember.get(this, 'params.onChange')) {
          Ember.get(this, 'params.onChange')(val);
        } else {
          // If 'custom' is chosen and sharedCustomProperty is true, we need to set the value to the last preset value.
          let isCustom = val.isCustom;
          let sharedCustomProperty = Ember.get(this, 'sharedCustomProperty');
          let lastPresetValue = Ember.get(this, 'yieldHash.lastPresetValue') || Ember.get(this, 'yieldHash.presetValue');
          let value = val.val;

          if (isCustom && sharedCustomProperty) {
            // We need to update the value to be the last presetValue
            // If this is a sharedCustomProperty, the lastPresetValue might be undefined. If so, we'll use the current value.
            let customValue = lastPresetValue || value || Ember.get(this, 'params.min') || 0; // if (isNone(lastPresetValue)) {
            //   if (isNone(value)) {
            //     set(this, 'value', get(this, 'params.min'));
            //   } else {
            //     set(this, 'value', value);
            //   }
            // } else {
            //   set(this, 'value', lastPresetValue);
            // }
            // Oh gosh, this is ugly, but needs to be here for backwards-compatibility. :-( G-force presets need to be multipled by 100.

            if (Ember.get(this, 'isSpeed')) {
              customValue *= 100;
            }

            Ember.set(this, 'value', customValue);
          } else if (isCustom) {
            Ember.set(this, 'value', value);
          } else {
            // not custom
            Ember.set(this, 'value', value); // Only update if we have a presetValue to use

            if (!Ember.isNone(val.presetValue)) {
              Ember.set(this, 'lastPresetValue', val.presetValue);
            }
          }
        } // If "custom" was just selected, try to focus on an input. setTimeout required to catch the element in the DOM.


        Ember.run.schedule('afterRender', () => {
          setTimeout(() => {
            this.$(this.element).find('input').focus();
          }, 100);
        });
      }

    } //#endregion

  });

  _exports.default = _default;
});