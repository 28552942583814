define("alliance-business-suite/components/table/machines/table-cell-machine-status", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  let TableMachinesTableCellMachineStatusComponent = (_dec = Ember.inject.service, (_class = class TableMachinesTableCellMachineStatusComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor, this);
    }

    get showLastStatus() {
      const whitelist = ['UNAVAILABLE', 'NETWORK_ERROR'];
      return whitelist.indexOf(this.args.record.get('machineCurrentStatus.statusId')) !== -1;
    } // get showLastUpdated() {
    //   return this.store.peekRecord('machineCurrentStatus', this.args.record.get('machineCurrentStatus.id')).get('createdAt')
    // }


  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = TableMachinesTableCellMachineStatusComponent;
});