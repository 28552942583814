define("alliance-business-suite/utils/demo-opl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reportCapacityUtilizationBreakdown = _exports.oplReportMockData = _exports.edMachinesTest = _exports.edEmployeesTest = void 0;
  const oplReportMockData = [{
    "type": "capacity_utilization",
    "data": [{
      "id": "shift_1",
      "washerPercent": 13,
      "dryerPercent": 14,
      "totalPercent": 13
    }, {
      "id": "shift_2",
      "washerPercent": 12,
      "dryerPercent": 15,
      "totalPercent": 14
    }, {
      "id": "shift_3",
      "washerPercent": 4,
      "dryerPercent": 5,
      "totalPercent": 4
    }, {
      "id": "avg_shift",
      "washerPercent": 10,
      "dryerPercent": 11,
      "totalPercent": 10
    }]
  }, {
    "type": "utilization_breakdown",
    "data": [{
      "date": "01-12-2020",
      "washerUtilization": 9,
      "dryerUtilization": 11,
      "totalUtilization": 10
    }, {
      "date": "02-12-2020",
      "washerUtilization": 8,
      "dryerUtilization": 9,
      "totalUtilization": 8
    }, {
      "date": "03-12-2020",
      "washerUtilization": 10,
      "dryerUtilization": 11,
      "totalUtilization": 10
    }, {
      "date": "04-12-2020",
      "washerUtilization": 8,
      "dryerUtilization": 11,
      "totalUtilization": 10
    }, {
      "date": "05-12-2020",
      "washerUtilization": 10,
      "dryerUtilization": 11,
      "totalUtilization": 10
    }, {
      "date": "06-12-2020",
      "washerUtilization": 17,
      "dryerUtilization": 21,
      "totalUtilization": 19
    }, {
      "date": "07-12-2020",
      "washerUtilization": 9,
      "dryerUtilization": 11,
      "totalUtilization": 10
    }, {
      "date": "08-12-2020",
      "washerUtilization": 10,
      "dryerUtilization": 11,
      "totalUtilization": 11
    }, {
      "date": "09-12-2020",
      "washerUtilization": 7,
      "dryerUtilization": 8,
      "totalUtilization": 8
    }, {
      "date": "10-12-2020",
      "washerUtilization": 7,
      "dryerUtilization": 8,
      "totalUtilization": 8
    }, {
      "date": "11-12-2020",
      "washerUtilization": 7,
      "dryerUtilization": 8,
      "totalUtilization": 7
    }, {
      "date": "12-12-2020",
      "washerUtilization": 12,
      "dryerUtilization": 14,
      "totalUtilization": 13
    }, {
      "date": "13-12-2020",
      "washerUtilization": 15,
      "dryerUtilization": 17,
      "totalUtilization": 16
    }, {
      "date": "14-12-2020",
      "washerUtilization": 10,
      "dryerUtilization": 11,
      "totalUtilization": 11
    }, {
      "date": "15-12-2020",
      "washerUtilization": 7,
      "dryerUtilization": 9,
      "totalUtilization": 8
    }, {
      "date": "16-12-2020",
      "washerUtilization": 8,
      "dryerUtilization": 10,
      "totalUtilization": 9
    }, {
      "date": "17-12-2020",
      "washerUtilization": 7,
      "dryerUtilization": 9,
      "totalUtilization": 8
    }, {
      "date": "18-12-2020",
      "washerUtilization": 7,
      "dryerUtilization": 8,
      "totalUtilization": 8
    }, {
      "date": "19-12-2020",
      "washerUtilization": 10,
      "dryerUtilization": 11,
      "totalUtilization": 10
    }, {
      "date": "20-12-2020",
      "washerUtilization": 19,
      "dryerUtilization": 22,
      "totalUtilization": 20
    }, {
      "date": "21-12-2020",
      "washerUtilization": 11,
      "dryerUtilization": 14,
      "totalUtilization": 13
    }, {
      "date": "22-12-2020",
      "washerUtilization": 10,
      "dryerUtilization": 11,
      "totalUtilization": 11
    }, {
      "date": "23-12-2020",
      "washerUtilization": 10,
      "dryerUtilization": 12,
      "totalUtilization": 11
    }, {
      "date": "24-12-2020",
      "washerUtilization": 5,
      "dryerUtilization": 7,
      "totalUtilization": 6
    }, {
      "date": "25-12-2020",
      "washerUtilization": 0,
      "dryerUtilization": 0,
      "totalUtilization": 0
    }, {
      "date": "26-12-2020",
      "washerUtilization": 10,
      "dryerUtilization": 10,
      "totalUtilization": 10
    }, {
      "date": "27-12-2020",
      "washerUtilization": 14,
      "dryerUtilization": 16,
      "totalUtilization": 15
    }, {
      "date": "28-12-2020",
      "washerUtilization": 10,
      "dryerUtilization": 13,
      "totalUtilization": 11
    }, {
      "date": "29-12-2020",
      "washerUtilization": 10,
      "dryerUtilization": 11,
      "totalUtilization": 11
    }, {
      "date": "30-12-2020",
      "washerUtilization": 11,
      "dryerUtilization": 14,
      "totalUtilization": 13
    }, {
      "date": "31-12-2020",
      "washerUtilization": 9,
      "dryerUtilization": 11,
      "totalUtilization": 10
    }]
  }, {
    "type": "productivity",
    "data": {
      "dryers_goal_shift_1": "147774",
      "dryers_goal_shift_2": "144933",
      "dryers_goal_shift_3": "74943",
      "dryers_real_shift_1": "101964",
      "dryers_real_shift_2": "117396",
      "dryers_real_shift_3": "39720",
      "washers_goal_shift_1": "128541",
      "washers_goal_shift_2": "100954",
      "washers_goal_shift_3": "46633",
      "washers_real_shift_1": "91264",
      "washers_real_shift_2": "83792",
      "washers_real_shift_3": "25648"
    }
  }, {
    "type": "productivity_daily",
    "data": [{
      "date": "01-12-2020",
      "washersTotal": "8140",
      "dryersTotal": "10665",
      "allTotal": "18805",
      "rewash": "1700"
    }, {
      "date": "02-12-2020",
      "washersTotal": "6180",
      "dryersTotal": "8175",
      "allTotal": "14355",
      "rewash": "1680"
    }, {
      "date": "03-12-2020",
      "washersTotal": "8620",
      "dryersTotal": "9360",
      "allTotal": "17980",
      "rewash": "2540"
    }, {
      "date": "04-12-2020",
      "washersTotal": "6960",
      "dryersTotal": "9195",
      "allTotal": "16155",
      "rewash": "1680"
    }, {
      "date": "05-12-2020",
      "washersTotal": "6900",
      "dryersTotal": "9540",
      "allTotal": "16440",
      "rewash": "1740"
    }, {
      "date": "06-12-2020",
      "washersTotal": "15040",
      "dryersTotal": "19275",
      "allTotal": "34315",
      "rewash": "3760"
    }, {
      "date": "07-12-2020",
      "washersTotal": "7860",
      "dryersTotal": "10170",
      "allTotal": "18030",
      "rewash": "2380"
    }, {
      "date": "08-12-2020",
      "washersTotal": "8520",
      "dryersTotal": "10560",
      "allTotal": "19080",
      "rewash": "2280"
    }, {
      "date": "09-12-2020",
      "washersTotal": "5180",
      "dryersTotal": "7215",
      "allTotal": "12395",
      "rewash": "2040"
    }, {
      "date": "10-12-2020",
      "washersTotal": "6380",
      "dryersTotal": "7815",
      "allTotal": "14195",
      "rewash": "840"
    }, {
      "date": "11-12-2020",
      "washersTotal": "5880",
      "dryersTotal": "7980",
      "allTotal": "13860",
      "rewash": "1240"
    }, {
      "date": "12-12-2020",
      "washersTotal": "9540",
      "dryersTotal": "12375",
      "allTotal": "21915",
      "rewash": "3360"
    }, {
      "date": "13-12-2020",
      "washersTotal": "13240",
      "dryersTotal": "16800",
      "allTotal": "30040",
      "rewash": "3020"
    }, {
      "date": "14-12-2020",
      "washersTotal": "8100",
      "dryersTotal": "10785",
      "allTotal": "18885",
      "rewash": "2960"
    }, {
      "date": "15-12-2020",
      "washersTotal": "5800",
      "dryersTotal": "9015",
      "allTotal": "14815",
      "rewash": "2140"
    }, {
      "date": "16-12-2020",
      "washersTotal": "6720",
      "dryersTotal": "9285",
      "allTotal": "16005",
      "rewash": "2660"
    }, {
      "date": "17-12-2020",
      "washersTotal": "6500",
      "dryersTotal": "8565",
      "allTotal": "15065",
      "rewash": "2060"
    }, {
      "date": "18-12-2020",
      "washersTotal": "5660",
      "dryersTotal": "7140",
      "allTotal": "12800",
      "rewash": "1480"
    }, {
      "date": "19-12-2020",
      "washersTotal": "8180",
      "dryersTotal": "9135",
      "allTotal": "17315",
      "rewash": "1800"
    }, {
      "date": "20-12-2020",
      "washersTotal": "16200",
      "dryersTotal": "19590",
      "allTotal": "35790",
      "rewash": "3720"
    }, {
      "date": "21-12-2020",
      "washersTotal": "9260",
      "dryersTotal": "12870",
      "allTotal": "22130",
      "rewash": "2260"
    }, {
      "date": "22-12-2020",
      "washersTotal": "7440",
      "dryersTotal": "10125",
      "allTotal": "17565",
      "rewash": "1940"
    }, {
      "date": "23-12-2020",
      "washersTotal": "8840",
      "dryersTotal": "12030",
      "allTotal": "20870",
      "rewash": "3160"
    }, {
      "date": "24-12-2020",
      "washersTotal": "4460",
      "dryersTotal": "6150",
      "allTotal": "10610",
      "rewash": "1060"
    }, {
      "date": "25-12-2020",
      "washersTotal": "0",
      "dryersTotal": "0",
      "allTotal": "0",
      "rewash": "0"
    }, {
      "date": "26-12-2020",
      "washersTotal": "7780",
      "dryersTotal": "9105",
      "allTotal": "16885",
      "rewash": "1640"
    }, {
      "date": "27-12-2020",
      "washersTotal": "11980",
      "dryersTotal": "14985",
      "allTotal": "26965",
      "rewash": "3600"
    }, {
      "date": "28-12-2020",
      "washersTotal": "9560",
      "dryersTotal": "12585",
      "allTotal": "22145",
      "rewash": "2360"
    }, {
      "date": "29-12-2020",
      "washersTotal": "8760",
      "dryersTotal": "10695",
      "allTotal": "19455",
      "rewash": "2260"
    }, {
      "date": "30-12-2020",
      "washersTotal": "9280",
      "dryersTotal": "12135",
      "allTotal": "21415",
      "rewash": "2040"
    }, {
      "date": "31-12-2020",
      "washersTotal": "7920",
      "dryersTotal": "10530",
      "allTotal": "18450",
      "rewash": "2980"
    }]
  }, {
    "type": "productivity_shift_bars",
    "data": [{
      "id": "shift_1",
      "stats": {
        "washersTotal": "114080",
        "dryersTotal": "127455",
        "allTotal": "241535",
        "rewash": "33100"
      }
    }, {
      "id": "shift_2",
      "stats": {
        "washersTotal": "104740",
        "dryersTotal": "146745",
        "allTotal": "251485",
        "rewash": "26000"
      }
    }, {
      "id": "shift_3",
      "stats": {
        "washersTotal": "32060",
        "dryersTotal": "49650",
        "allTotal": "81710",
        "rewash": "9280"
      }
    }, {
      "id": "avg_shift",
      "stats": {
        "washersTotal": "83626.666666666667",
        "dryersTotal": "107950.000000000000",
        "allTotal": "191576.666666666667",
        "rewash": "22793.333333333333"
      }
    }, {
      "id": "total",
      "stats": {
        "washersTotal": "250880",
        "dryersTotal": "323850",
        "allTotal": "574730",
        "rewash": "68380"
      }
    }]
  }, {
    "type": 'shift_efficiency_breakdown',
    "data": [{
      "id": "shift_1",
      "stats": {
        "washersTotal": ".33",
        "dryersTotal": ".32",
        "allTotal": ".33"
      }
    }, {
      "id": "shift_2",
      "stats": {
        "washersTotal": ".71",
        "dryersTotal": ".71",
        "allTotal": ".71"
      }
    }, {
      "id": "shift_3",
      "stats": {
        "washersTotal": ".90",
        "dryersTotal": ".91",
        "allTotal": ".90"
      }
    }, {
      "id": "avg_shift",
      "stats": {
        "washersTotal": ".65",
        "dryersTotal": ".66",
        "allTotal": ".65"
      }
    }]
  }, {
    "type": "efficiency_daily",
    "data": [{
      "date": "01-12-2020",
      "shift_1": .36,
      "shift_2": .60,
      "shift_3": .44
    }, {
      "date": "02-12-2020",
      "shift_1": .37,
      "shift_2": .60,
      "shift_3": .44
    }, {
      "date": "03-12-2020",
      "shift_1": .36,
      "shift_2": .60,
      "shift_3": .44
    }, {
      "date": "04-12-2020",
      "shift_1": .36,
      "shift_2": .60,
      "shift_3": .44
    }, {
      "date": "05-12-2020",
      "shift_1": .36,
      "shift_2": .60,
      "shift_3": .44
    }, {
      "date": "06-12-2020",
      "shift_1": .36,
      "shift_2": .60,
      "shift_3": .44
    }, {
      "date": "07-12-2020",
      "shift_1": .36,
      "shift_2": .60,
      "shift_3": .44
    }, {
      "date": "08-12-2020",
      "shift_1": .36,
      "shift_2": .60,
      "shift_3": .44
    }, {
      "date": "09-12-2020",
      "shift_1": .36,
      "shift_2": .60,
      "shift_3": .44
    }, {
      "date": "10-12-2020",
      "shift_1": .36,
      "shift_2": .60,
      "shift_3": .44
    }, {
      "date": "11-12-2020",
      "shift_1": .36,
      "shift_2": .60,
      "shift_3": .44
    }, {
      "date": "12-12-2020",
      "shift_1": .36,
      "shift_2": .60,
      "shift_3": .44
    }, {
      "date": "13-12-2020",
      "shift_1": .36,
      "shift_2": .60,
      "shift_3": .44
    }, {
      "date": "14-12-2020",
      "shift_1": .36,
      "shift_2": .60,
      "shift_3": .44
    }, {
      "date": "15-12-2020",
      "shift_1": .36,
      "shift_2": .60,
      "shift_3": .44
    }, {
      "date": "16-12-2020",
      "shift_1": .36,
      "shift_2": .60,
      "shift_3": .44
    }, {
      "date": "17-12-2020",
      "shift_1": .36,
      "shift_2": .60,
      "shift_3": .44
    }, {
      "date": "18-12-2020",
      "shift_1": .36,
      "shift_2": .60,
      "shift_3": .44
    }, {
      "date": "19-12-2020",
      "shift_1": .36,
      "shift_2": .60,
      "shift_3": .44
    }, {
      "date": "20-12-2020",
      "shift_1": .36,
      "shift_2": .60,
      "shift_3": .44
    }, {
      "date": "21-12-2020",
      "shift_1": .36,
      "shift_2": .60,
      "shift_3": .44
    }, {
      "date": "22-12-2020",
      "shift_1": .36,
      "shift_2": .60,
      "shift_3": .44
    }, {
      "date": "23-12-2020",
      "shift_1": .36,
      "shift_2": .60,
      "shift_3": .44
    }, {
      "date": "24-12-2020",
      "shift_1": .36,
      "shift_2": .60,
      "shift_3": .44
    }, {
      "date": "25-12-2020",
      "shift_1": .36,
      "shift_2": .60,
      "shift_3": .44
    }, {
      "date": "26-12-2020",
      "shift_1": .36,
      "shift_2": .60,
      "shift_3": .44
    }, {
      "date": "27-12-2020",
      "shift_1": .36,
      "shift_2": .60,
      "shift_3": .44
    }, {
      "date": "28-12-2020",
      "shift_1": .36,
      "shift_2": .60,
      "shift_3": .44
    }, {
      "date": "29-12-2020",
      "shift_1": .36,
      "shift_2": .60,
      "shift_3": .44
    }, {
      "date": "30-12-2020",
      "shift_1": .36,
      "shift_2": .60,
      "shift_3": .44
    }, {
      "date": "31-12-2020",
      "shift_1": .36,
      "shift_2": .60,
      "shift_3": .44
    }]
  }, {
    "type": 'operational_expense_breakdown',
    "data": [{
      type: 'shift_1',
      water: '20',
      electricity: '68',
      gas: '31',
      employees: '640',
      total: '759'
    }, {
      type: 'shift_2',
      water: '9',
      electricity: '28',
      gas: '12',
      employees: '640',
      total: '688'
    }, {
      type: 'shift_3',
      water: '11',
      electricity: '62',
      gas: '30',
      employees: '640',
      total: '743'
    }, {
      type: 'avg_shift',
      water: '13',
      electricity: '52',
      gas: '24',
      employees: '640',
      total: '730'
    }, {
      type: 'total',
      water: '40',
      electricity: '158',
      gas: '73',
      employees: '1920',
      total: '2191'
    }]
  }];
  _exports.oplReportMockData = oplReportMockData;
  const edEmployeesTest = [{
    employeeId: "1",
    firstName: "Ed",
    lastName: "Prats",
    shifts: [{
      "shiftStartDatePart": "2021-07-02",
      "shiftEndDatePart": "2021-07-02",
      "shiftStartTimePart": "02:00:00",
      "shiftEndTimePart": "02:54:00"
    }, {
      "shiftStartDatePart": "2021-07-02",
      "shiftEndDatePart": "2021-07-02",
      "shiftStartTimePart": "11:00:00",
      "shiftEndTimePart": "13:15:00"
    }]
  }];
  _exports.edEmployeesTest = edEmployeesTest;
  const edMachinesTest = [{
    "machineId": "4295",
    "machineName": "Machine Node - 2",
    "machineDescription": "SY65H",
    "isDryer": false,
    "cycles": [{
      "machineId": "4295",
      "machineName": "Machine Node - 250",
      "machineDescription": "SY65H",
      "isDryer": false,
      "cycleId": "160",
      "name": 180,
      "cycleStart": "2021-07-02T00:02:00+00:00",
      "cycleStartDatePart": "2021-07-02",
      "cycleStartTimePart": "02:00:00",
      "cycleLengthMinutes": 54,
      "cycleEndDescription": "NORMAL"
    }, {
      "machineId": "4295",
      "machineName": "Machine Node - 250",
      "machineDescription": "SY65H",
      "isDryer": false,
      "cycleId": "161",
      "name": 180,
      "cycleStart": "2021-07-02T00:02:00+00:00",
      "cycleStartDatePart": "2021-07-02",
      "cycleStartTimePart": "05:05:00",
      "cycleLengthMinutes": 75,
      "cycleEndDescription": "NORMAL"
    }]
  }, {
    "machineId": "4296",
    "machineName": "Machine Node - 1",
    "machineDescription": "SY65H",
    "isDryer": false,
    "cycles": [{
      "machineId": "4296",
      "machineName": "Machine Node - 250",
      "machineDescription": "SY65H",
      "isDryer": false,
      "cycleId": "160",
      "name": 180,
      "cycleStart": "2021-07-02T00:02:00+00:00",
      "cycleStartDatePart": "2021-07-02",
      "cycleStartTimePart": "08:00:00",
      "cycleLengthMinutes": 75,
      "cycleEndDescription": "NORMAL"
    }, {
      "machineId": "4296",
      "machineName": "Machine Node - 250",
      "machineDescription": "SY65H",
      "isDryer": false,
      "cycleId": "161",
      "name": 180,
      "cycleStart": "2021-07-02T00:02:00+00:00",
      "cycleStartDatePart": "2021-07-03",
      "cycleStartTimePart": "11:05:00",
      "cycleLengthMinutes": 75,
      "cycleEndDescription": "POWERFAIL"
    }, {
      "machineId": "4296",
      "machineName": "Machine Node - 250",
      "machineDescription": "SY65H",
      "isDryer": false,
      "cycleId": "160",
      "name": 180,
      "cycleStart": "2021-07-02T00:02:00+00:00",
      "cycleStartDatePart": "2021-07-05",
      "cycleStartTimePart": "14:05:00",
      "cycleLengthMinutes": 75,
      "cycleEndDescription": "POWERFAIL"
    }]
  }];
  _exports.edMachinesTest = edMachinesTest;
  const reportCapacityUtilizationBreakdown = {
    "geoBoundaries": [{
      "id": "452",
      "name": "221 Shepard St. Ripon, WI 54971",
      "rooms": [{
        "id": "351",
        "name": "ALS - Laundromat",
        "machines": [{
          "machineId": "4295",
          "machineName": "Machine Node - 250",
          "machineDescription": "SY65H",
          "isDryer": false,
          "totalCycles": 8,
          "totalCycleMinutes": 242,
          "cycles": [{
            "id": "160",
            "name": 180,
            "cycleStart": "2021-07-02T00:02:00+00:00",
            "cycleStartDatePart": "2021-07-02",
            "cycleStartTimePart": "00:02:00",
            "cycleLengthMinutes": 54,
            "cycleEndDescription": "NORMAL"
          }, {
            "id": "164",
            "name": 184,
            "cycleStart": "2021-07-02T20:04:00+00:00",
            "cycleStartDatePart": "2021-07-02",
            "cycleStartTimePart": "20:04:00",
            "cycleLengthMinutes": 21,
            "cycleEndDescription": "POWERFAIL"
          }, {
            "id": "171",
            "name": 191,
            "cycleStart": "2021-07-04T02:37:00+00:00",
            "cycleStartDatePart": "2021-07-04",
            "cycleStartTimePart": "02:37:00",
            "cycleLengthMinutes": 11,
            "cycleEndDescription": "UNKNOWN"
          }, {
            "id": "172",
            "name": 191,
            "cycleStart": "2021-07-04T05:22:00+00:00",
            "cycleStartDatePart": "2021-07-04",
            "cycleStartTimePart": "05:22:00",
            "cycleLengthMinutes": 22,
            "cycleEndDescription": "NORMAL"
          }, {
            "id": "169",
            "name": 189,
            "cycleStart": "2021-07-04T15:29:00+00:00",
            "cycleStartDatePart": "2021-07-04",
            "cycleStartTimePart": "15:29:00",
            "cycleLengthMinutes": 24,
            "cycleEndDescription": "ERROR"
          }, {
            "id": "173",
            "name": 192,
            "cycleStart": "2021-07-04T21:39:00+00:00",
            "cycleStartDatePart": "2021-07-04",
            "cycleStartTimePart": "21:39:00",
            "cycleLengthMinutes": 42,
            "cycleEndDescription": "STOP"
          }, {
            "id": "174",
            "name": 192,
            "cycleStart": "2021-07-06T21:59:00+00:00",
            "cycleStartDatePart": "2021-07-06",
            "cycleStartTimePart": "21:59:00",
            "cycleLengthMinutes": 13,
            "cycleEndDescription": "RAPID_ADVANCE"
          }, {
            "id": "175",
            "name": 193,
            "cycleStart": "2021-07-07T01:05:00+00:00",
            "cycleStartDatePart": "2021-07-07",
            "cycleStartTimePart": "01:05:00",
            "cycleLengthMinutes": 55,
            "cycleEndDescription": "UNKNOWN"
          }],
          "cycleNameCount": [{
            "cycleName": "180",
            "cycleCount": 1
          }, {
            "cycleName": "184",
            "cycleCount": 1
          }, {
            "cycleName": "189",
            "cycleCount": 1
          }, {
            "cycleName": "191",
            "cycleCount": 2
          }, {
            "cycleName": "192",
            "cycleCount": 2
          }, {
            "cycleName": "193",
            "cycleCount": 1
          }]
        }]
      }]
    }]
  };
  _exports.reportCapacityUtilizationBreakdown = reportCapacityUtilizationBreakdown;
});