define("alliance-business-suite/components/employees/roles/permission-tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    intl: Ember.inject.service(),

    click() {
      return false; // Don't let clicks toggle the checkbox
    },

    hasTooltip: Ember.computed('lookupKey', function () {
      let lookupKey = Ember.get(this, 'lookupKey');
      let tooltipKey = `${lookupKey}_TOOLTIP`;
      return Ember.get(this, 'intl').exists(tooltipKey);
    }),
    tooltipText: Ember.computed('lookupKey', function () {
      let lookupKey = Ember.get(this, 'lookupKey');
      return Ember.get(this, 'intl').t(`${lookupKey}_TOOLTIP`);
    })
  });

  _exports.default = _default;
});