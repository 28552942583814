define("alliance-business-suite/templates/components/form-ui/temp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fgQ9K4tP",
    "block": "{\"symbols\":[],\"statements\":[[2,\"  \"],[10,\"label\"],[15,0,[31,[\"form-field--label \",[30,[36,1],[[35,0],\"disabled\"],null]]]],[12],[2,\"\\n    \"],[10,\"span\"],[12],[2,\"\\n      \"],[1,[34,2]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"span\"],[12],[2,\"\\n      \"],[8,\"input\",[[16,\"placeholder\",[34,5]],[24,0,\"form-field--control\"]],[[\"@type\",\"@value\",\"@disabled\",\"@min\",\"@max\",\"@step\",\"@limit\",\"@autofocus\",\"@maxlength\"],[[34,3],[34,4],[34,0],[34,6,[\"min\"]],[34,6,[\"max\"]],[34,6,[\"step\"]],[34,7],[34,6,[\"autofocus\"]],[34,6,[\"maxlength\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\\n\"],[8,\"form-ui/dev-tools\",[],[[\"@outputs\"],[[30,[36,11],null,[[\"labelAutoTranslated\",\"propertyName\",\"min\",\"max\",\"step\",\"limit\",\"isDisabled\",\"preset\",\"rawValue\",\"value\"],[[35,10],[35,6,[\"propertyName\"]],[35,6,[\"min\"]],[35,6,[\"max\"]],[35,6,[\"step\"]],[35,7],[35,0],[35,9],[35,8],[35,4]]]]]],null]],\"hasEval\":false,\"upvars\":[\"isDisabled\",\"if\",\"label\",\"type\",\"value\",\"placeholder\",\"params\",\"limit\",\"rawValue\",\"preset\",\"labelAutoTranslated\",\"hash\"]}",
    "moduleName": "alliance-business-suite/templates/components/form-ui/temp.hbs"
  });

  _exports.default = _default;
});