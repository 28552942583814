define("alliance-business-suite/serializers/phone-number", ["exports", "alliance-business-suite/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    access: Ember.inject.service(),

    /**
     * Hacks a JSON API include object returned within a JSON API document to
     * confirm to what is required to make the endpoint work property with Ember
     * Data and within this application.
     *
     * @param  {object} include An included data object from a JSON API document
     *
     * @return {object} The data object to use for this include when passing it
     *                  to the store; or undefined if this include should be
     *                  ignored.
     *
     * @protected
     */
    normalizeInclude(include) {
      return ['role-types', 'roles', 'user-role-types', 'user-roles'].includes(include.type) ? undefined : include;
    },

    serializeIntoHash(data, type, snapshot
    /* , options */
    ) {
      // this._super(...arguments);
      Object.assign(data, snapshot.record.getProperties('internationalCode', 'areaCode', 'contactNumber'));
      data.phoneNumberType = {
        id: Ember.get(snapshot, 'record.phoneNumberType.id')
      };
      data.createdBy = {
        id: Ember.get(this, 'access.accessUser.id')
      };
      data.user = {
        id: snapshot.record.get('user.id')
      };
      let facilityId = snapshot.record.get('facility.id');

      if (facilityId) {
        data.facility = {
          id: facilityId
        };
      }
    }

  });

  _exports.default = _default;
});