define("alliance-business-suite/components/summary/reports/s-pricing-overview", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "alliance-business-suite/utils/currency-format-util", "alliance-business-suite/utils/report-tables-util"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _currencyFormatUtil, _reportTablesUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  let SummaryReportsSPricingOverviewComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, (_class = class SummaryReportsSPricingOverviewComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "brand", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor4, this);
    }

    get translatedColumns() {
      return [{
        name: 'location_machine',
        valuePath: 'name',
        width: 379,
        isFixed: 'left'
      }, {
        name: 'machineValue',
        valuePath: 'machineValue',
        width: 180
      }, {
        name: 'machinePrice',
        valuePath: 'machinePrice',
        width: 222
      }];
    }

    get rowsWithChildren() {
      let geoBoundaries = this.args.model.get('geoBoundaries');
      let currencyOptions = this.args.model.get('currency');
      let rowData = [];
      geoBoundaries.forEach(geoBoundary => {
        let rowArray = [];
        let nameArray = [];
        rowData.push({
          name: _reportTablesUtil.localizeString.call(this, geoBoundary.name, 'Location', 'location'),
          children: nameArray
        });
        geoBoundary.rooms.forEach(room => {
          nameArray.push({
            name: room.name,
            children: rowArray
          });
          room.machineAuditGroupTypes.forEach(machineAuditType => {
            machineAuditType.machineTypes.forEach(machineType => {
              let isDryer = machineType.name.toUpperCase().indexOf('DRYER') > -1 || machineType.name.toUpperCase().indexOf('TUMBLER') > -1;
              let machineTypeName = `${machineAuditType.name} - ${machineType.name}`;
              let machineTypeValues = [{
                machineValue: this.intl.t('heatPrice'),
                machinePrice: _currencyFormatUtil.formatCurrencyString.call(this, machineType.heatPrice || 0)
              }, {
                machineValue: this.intl.t('noHeatPrice'),
                machinePrice: _currencyFormatUtil.formatCurrencyString.call(this, machineType.noHeatPrice || 0)
              }, {
                machineValue: this.intl.t('topoff1Price'),
                machinePrice: _currencyFormatUtil.formatCurrencyString.call(this, machineType.topoff1Price || 0)
              }, {
                machineValue: this.intl.t('topoff2Price'),
                machinePrice: _currencyFormatUtil.formatCurrencyString.call(this, machineType.topoff2Price || 0)
              }, {
                machineValue: this.intl.t('heatTime'),
                machinePrice: machineType.heatTime || '0m 0s'
              }, {
                machineValue: this.intl.t('noHeatTime'),
                machinePrice: machineType.noHeatTime || '0m 0s'
              }, {
                machineValue: this.intl.t('topoff1Time'),
                machinePrice: machineType.topoff1Time || '0m 0s'
              }, {
                machineValue: this.intl.t('topoff2Time'),
                machinePrice: machineType.topoff2Time || '0m 0s'
              }, {
                machineValue: this.intl.t('paymentSystemTopOff'),
                machinePrice: machineType.paymentSystemTopoffTime || '0m 0s'
              }];

              if (!isDryer) {
                machineTypeValues = [{
                  machineValue: this.intl.t('cycle1Price'),
                  machinePrice: _currencyFormatUtil.formatCurrencyString.call(this, machineType.cycle1Price || 0)
                }, {
                  machineValue: this.intl.t('cycle2Price'),
                  machinePrice: _currencyFormatUtil.formatCurrencyString.call(this, machineType.cycle2Price || 0)
                }, {
                  machineValue: this.intl.t('cycle3Price'),
                  machinePrice: _currencyFormatUtil.formatCurrencyString.call(this, machineType.cycle3Price || 0)
                }, {
                  machineValue: this.intl.t('cycle4Price'),
                  machinePrice: _currencyFormatUtil.formatCurrencyString.call(this, machineType.cycle4Price || 0)
                }, {
                  machineValue: this.intl.t('cycle5Price'),
                  machinePrice: _currencyFormatUtil.formatCurrencyString.call(this, machineType.cycle5Price || 0)
                }, {
                  machineValue: this.intl.t('cycle6Price'),
                  machinePrice: _currencyFormatUtil.formatCurrencyString.call(this, machineType.cycle6Price || 0)
                }, {
                  machineValue: this.intl.t('cycle7Price'),
                  machinePrice: _currencyFormatUtil.formatCurrencyString.call(this, machineType.cycle7Price || 0)
                }, {
                  machineValue: this.intl.t('cycle8Price'),
                  machinePrice: _currencyFormatUtil.formatCurrencyString.call(this, machineType.cycle8Price || 0)
                }, {
                  machineValue: this.intl.t('cycle9Price'),
                  machinePrice: _currencyFormatUtil.formatCurrencyString.call(this, machineType.cycle9Price || 0)
                }, {
                  machineValue: this.intl.t('mediumPrice'),
                  machinePrice: _currencyFormatUtil.formatCurrencyString.call(this, machineType.mediumPrice || 0)
                }, {
                  machineValue: this.intl.t('heavyPrice'),
                  machinePrice: _currencyFormatUtil.formatCurrencyString.call(this, machineType.heavyPrice || 0)
                }, {
                  machineValue: this.intl.t('smallLoadPrice'),
                  machinePrice: _currencyFormatUtil.formatCurrencyString.call(this, machineType.smallLoadPrice || 0)
                }, {
                  machineValue: this.intl.t('cycle10Price'),
                  machinePrice: _currencyFormatUtil.formatCurrencyString.call(this, machineType.cycle10Price || 0)
                }, {
                  machineValue: this.intl.t('cycle11Price'),
                  machinePrice: _currencyFormatUtil.formatCurrencyString.call(this, machineType.cycle11Price || 0)
                }, {
                  machineValue: this.intl.t('cycle12Price'),
                  machinePrice: _currencyFormatUtil.formatCurrencyString.call(this, machineType.cycle12Price || 0)
                }, {
                  machineValue: this.intl.t('cycle13Price'),
                  machinePrice: _currencyFormatUtil.formatCurrencyString.call(this, machineType.cycle13Price || 0)
                }, {
                  machineValue: this.intl.t('cycle14Price'),
                  machinePrice: _currencyFormatUtil.formatCurrencyString.call(this, machineType.cycle14Price || 0)
                }, {
                  machineValue: this.intl.t('cycle15Price'),
                  machinePrice: _currencyFormatUtil.formatCurrencyString.call(this, machineType.cycle15Price || 0)
                }];
              }

              if (this.brand.slug == 'primus') {
                // fix the branding issue alliance-tasks/issues/2291
                machineTypeName = machineTypeName.replace("Touch Softmount", "XControl Flex Softmount");
              }

              rowArray.push({
                name: machineTypeName,
                children: machineTypeValues
              });
            });
          });
        });
      });
      return rowData;
    }

    get userTimeZone() {
      return moment.tz.guess();
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "brand", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SummaryReportsSPricingOverviewComponent;
});