define("alliance-business-suite/components/customers/c-transactions", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _dec32, _dec33, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24;

  // import accountBalance from 'alliance-business-suite/mixins/account-balance';
  let CustomersCTransactionsComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember._tracked, _dec18 = Ember._tracked, _dec19 = Ember._tracked, _dec20 = Ember._tracked, _dec21 = Ember._tracked, _dec22 = Ember._tracked, _dec23 = Ember._tracked, _dec24 = Ember._tracked, _dec25 = Ember._action, _dec26 = Ember.computed('transactions.[]', 'filterTransactionType'), _dec27 = Ember.computed('transactions.[]'), _dec28 = Ember._action, _dec29 = Ember.computed('cache.loadedGeoIncludedAll'), _dec30 = Ember._action, _dec31 = Ember._action, _dec32 = Ember._action, _dec33 = Ember._action, (_class = class CustomersCTransactionsComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "ajax", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "devtools", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "cache", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "notifications", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "transactions", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "selectedFundsRoot", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "isLoading", _descriptor10, this);
      (0, _initializerDefineProperty2.default)(this, "fundsRoot", _descriptor11, this);
      (0, _initializerDefineProperty2.default)(this, "refundReasons", _descriptor12, this);
      (0, _initializerDefineProperty2.default)(this, "canEditBalance", _descriptor13, this);
      (0, _initializerDefineProperty2.default)(this, "userAccountId", _descriptor14, this);
      (0, _initializerDefineProperty2.default)(this, "geoBoundaryId", _descriptor15, this);
      (0, _initializerDefineProperty2.default)(this, "accountId", _descriptor16, this);
      (0, _initializerDefineProperty2.default)(this, "newBalance", _descriptor17, this);
      (0, _initializerDefineProperty2.default)(this, "currentRewardsBalance", _descriptor18, this);
      (0, _initializerDefineProperty2.default)(this, "currentRewardsPoints", _descriptor19, this);
      (0, _initializerDefineProperty2.default)(this, "isDialogOpen", _descriptor20, this);
      (0, _initializerDefineProperty2.default)(this, "isRewardsDialogOpen", _descriptor21, this);
      (0, _initializerDefineProperty2.default)(this, "isRewardsPointsDialogOpen", _descriptor22, this);
      (0, _initializerDefineProperty2.default)(this, "downloadUrl", _descriptor23, this);
      (0, _initializerDefineProperty2.default)(this, "processingHistoryDownload", _descriptor24, this);
    }

    initialSetup() {
      if (this.cache.loadedGeoIncludedAll) {
        this.fetchFundsRoots();
      } else if (this.cache.customerFundsRootOption) {
        this.selectedFundsRoot = this.cache.customerFundsRootOption;
        this.getTransactions();
      } else {
        setTimeout(() => {
          this.fetchFundsRoots();
        }, 1250);
      } // TODO fetch only once


      this.getRefundReasons(this);
    }

    get filteredTransactions() {
      let {
        transactions,
        filterTransactionType
      } = this;

      if (filterTransactionType && filterTransactionType != 'view-all') {
        let filteredResults = transactions.filter(t => {
          return t.userAccountTransactionTypeLookupKey == filterTransactionType;
        });
        return filteredResults;
      } else {
        return transactions;
      }
    }

    get transactionTypes() {
      let {
        transactions
      } = this;
      let types = ['view-all'];
      transactions.forEach(function (t) {
        if (types.indexOf(t.userAccountTransactionTypeLookupKey) == -1) {
          types.push(t.userAccountTransactionTypeLookupKey);
        }
      });
      return types;
    }

    getRefundReasons(context) {
      let request = this.ajax.request('/refundReasons?userAccountTransactionType=MACHINE_PAYMENT&raw=true', {
        method: 'GET'
      }).then(response => {
        if (!context.isDestroyed) {
          context.refundReasons = response;
        }

        return response;
      });
      this.ajaxRequest = request;
    }

    get geosLoaded() {
      return this.cache.get('loadedGeoIncludedAll');
    } // does this work/
    // https://ember-decorators.github.io/ember-decorators/docs/api/modules/@ember-decorators/object ??
    // @observer('cache.loadedGeoIncludedAll')
    // get fundsRootObserver() {
    //   if (get(this, 'cache.loadedGeoIncludedAll')) {
    //     this.fetchFundsRoots();
    //   }
    // }


    refresh() {
      this.getTransactions();
    }

    toggleModal(key) {
      if (this.access.isDemo) {
        alert('Demo mode restricted');
        return;
      }

      this[key] = !this[key];
    }

    downloadHistory() {
      if (this.access.isDemo) {
        alert('Demo mode restricted');
        return;
      }

      this.processingHistoryDownload = true;
      this.downloadUrl = null;
      this.ajax.request(`/customers/${this.args.userId}/transactionHistoryDownload?timeZone=${moment.tz.guess()}&geoBoundaryId=${this.selectedFundsRoot.get('id')}&raw=true`, {
        contentType: 'text/html; charset=UTF-8'
      }).then(result => {
        this.downloadUrl = result;
        setTimeout(() => {
          this.processingHistoryDownload = false;
          document.getElementById('downloadCustomerHistoryLink').click();
        }, 300);
      }).catch(error => {
        console.log('error', error);
        this.processingHistoryDownload = false;
        alert("Error downloading");
      });
    }

    get currentBalance() {
      let isMetered = this.access.accessOrganization.get('isMeteredLaundry');

      if (this.transactions.length > 0) {
        let currentTransaction = this.transactions.get(0);

        if (isMetered) {
          this.setBalances(null, currentTransaction.promotionalCredit, null);
        } else {
          this.setBalances(currentTransaction.userAccountBalanceAmount, currentTransaction.promotionalCredit, currentTransaction.rewardsPoints);
        }

        return currentTransaction.userAccountBalanceAmount;
      } else {
        this.setBalances(null, 0, 0);
      }

      return 0;
    }

    setBalances(newBalance, currentRewardsBalance, currentRewardsPoints) {
      this.newBalance = newBalance;
      this.currentRewardsBalance = currentRewardsBalance;
      this.currentRewardsPoints = currentRewardsPoints;
    }

    fetchFundsRoots() {
      let {
        store
      } = this;
      let selectedFundsRoot = null;
      let fundsRoot = store.peekAll('geo-boundary').filter(gb => {
        if (gb.fundsRestriction) {
          if (!selectedFundsRoot) {
            selectedFundsRoot = gb;
          }
        }

        return gb.fundsRestriction;
      });
      this.selectedFundsRoot = selectedFundsRoot;
      this.fundsRoot = fundsRoot.sort((a, b) => {
        let nameA = a.description.toLowerCase();
        let nameB = b.description.toLowerCase();

        if (nameA < nameB) {
          return -1;
        }

        if (nameA > nameB) {
          return 1;
        }

        return 0;
      });

      if (this.selectedFundsRoot) {
        this.cache.customerFundsRootOption = this.selectedFundsRoot;
      }

      this.getTransactions();
    }

    getTransactions() {
      let {
        selectedFundsRoot
      } = this;
      let organizationId = this.access.accessOrganization.id;
      this.isLoading = true;

      if (!this.args.userId) {
        return;
      }

      this.ajax.request(`/users/${this.args.userId}/accounts?organizationId=${organizationId}&geoBoundaryId=${selectedFundsRoot.get('id')}&include=account-transactions&raw=true`, {
        method: 'GET'
      }).then(response => {
        if (!this.isDestroyed) {
          this.isLoading = false;
        }

        if (response[0]) {
          if (!this.isDestroyed) {
            this.canEditBalance = true;
            this.userAccountId = response[0].id;
            this.geoBoundaryId = response[0].geoBoundary.id;
          }
        }

        let noTransactions = !response || !response[0] || !response[0].accountTransactions;

        if (noTransactions) {
          this.transactions = [];
          return;
        }

        this.accountId = response[0].id;
        let transactions = response[0].accountTransactions; // console.log('transactions', transactions);
        // divide dollar amounts by 100
        // In the future, the backend could send us the amounts in cents
        // and this code will still do the right thing.
        // let stopDividing = false;
        // let finalLog = '';

        if (!transactions.map) {
          this.transactions = [];
          return response;
        }

        transactions = transactions.map(transaction => {
          ['pointsRewardsCreditEarned'].forEach(divideBy100Key => {
            if (transaction.transactionTypeLookupKey != 'REWARDS_POINTS_PROMO_REDEEMED') {
              // Divide by 100!
              let newDollarAmount = String(+transaction[divideBy100Key] / this.access.currencySubunit);

              if (this.access.currencySubunit === 100) {
                // always fill in decimal places ("7.4" -> "7.40", "8" -> "8.00"
                if (!newDollarAmount.includes('.')) {
                  newDollarAmount += '.00';
                } else if (newDollarAmount.split('.')[1].length === 1) {
                  newDollarAmount += '0'; // if the length is 2 then leave it alone!
                } else {// console.log(
                  //   'his else - this should be true:',
                  //   newDollarAmount.split('.')[1].length === 2
                  // ); // (it's always true for seiya010@als.com)
                }
              }

              transaction[divideBy100Key] = newDollarAmount;
            }
          });
          return transaction;
        }); // intl for Description

        transactions.forEach(element => {
          element.transactionDescriptionLocalized = '';
          element.transactionDescription.forEach(transaction => {
            if (transaction.isLookup === true) {
              let translated = this.intl.t(transaction.text.toLowerCase());
              element.transactionDescriptionLocalized += `${translated}`;
            } else {
              element.transactionDescriptionLocalized += `${transaction.text}`;
            }
          });
        });

        if (!this.isDestroyed) {
          this.transactions = transactions;
        }

        return response;
      }); // .catch((error/* , jqXHR */) => {
      //   // console.error('get transactions xhr .catch error:', error);
      //   return error;
      // });
    }

    changeSelectedFundsRoot(val) {
      this.selectedFundsRoot = val;
      this.getTransactions();
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "devtools", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "cache", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "notifications", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "transactions", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedFundsRoot", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isLoading", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "fundsRoot", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "refundReasons", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "canEditBalance", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "userAccountId", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "geoBoundaryId", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "accountId", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "newBalance", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "currentRewardsBalance", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "currentRewardsPoints", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isDialogOpen", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isRewardsDialogOpen", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isRewardsPointsDialogOpen", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "downloadUrl", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "processingHistoryDownload", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "initialSetup", [_dec25], Object.getOwnPropertyDescriptor(_class.prototype, "initialSetup"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "filteredTransactions", [_dec26], Object.getOwnPropertyDescriptor(_class.prototype, "filteredTransactions"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "transactionTypes", [_dec27], Object.getOwnPropertyDescriptor(_class.prototype, "transactionTypes"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "getRefundReasons", [_dec28], Object.getOwnPropertyDescriptor(_class.prototype, "getRefundReasons"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "geosLoaded", [_dec29], Object.getOwnPropertyDescriptor(_class.prototype, "geosLoaded"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "refresh", [_dec30], Object.getOwnPropertyDescriptor(_class.prototype, "refresh"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "toggleModal", [_dec31], Object.getOwnPropertyDescriptor(_class.prototype, "toggleModal"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "downloadHistory", [_dec32], Object.getOwnPropertyDescriptor(_class.prototype, "downloadHistory"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "changeSelectedFundsRoot", [_dec33], Object.getOwnPropertyDescriptor(_class.prototype, "changeSelectedFundsRoot"), _class.prototype)), _class));
  _exports.default = CustomersCTransactionsComponent;
});