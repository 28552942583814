define("alliance-business-suite/components/demo/brand-switcher", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="model-field form-field">
    <label class="model-field__label">
      Demo Brand
    </label>
  
    <PowerSelect
      @options={{this.brandOptions}}
      @selected={{this.selectedBrand}}
      @onChange={{this.changeSelectedBrand}} as |b|>
        {{b.text}}
    </PowerSelect>
  </div>
  
  {{#if this.showDisableSwitcher}}
    <div class="model-field form-field">
      <label class="model-field__label">
        Demo Mode
      </label>
  
      <PowerSelect
        @options={{this.demoMode}}
        @selected={{this.selectedDemoMode}}
        @onChange={{this.changeDemoMode}} as |b|>
          {{b.text}}
      </PowerSelect>
    </div>
  {{/if}}
  
  */
  {
    "id": "NrTkxQva",
    "block": "{\"symbols\":[\"b\",\"b\"],\"statements\":[[10,\"div\"],[14,0,\"model-field form-field\"],[12],[2,\"\\n  \"],[10,\"label\"],[14,0,\"model-field__label\"],[12],[2,\"\\n    Demo Brand\\n  \"],[13],[2,\"\\n\\n  \"],[8,\"power-select\",[],[[\"@options\",\"@selected\",\"@onChange\"],[[32,0,[\"brandOptions\"]],[32,0,[\"selectedBrand\"]],[32,0,[\"changeSelectedBrand\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[32,2,[\"text\"]]],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,0],[[32,0,[\"showDisableSwitcher\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"model-field form-field\"],[12],[2,\"\\n    \"],[10,\"label\"],[14,0,\"model-field__label\"],[12],[2,\"\\n      Demo Mode\\n    \"],[13],[2,\"\\n\\n    \"],[8,\"power-select\",[],[[\"@options\",\"@selected\",\"@onChange\"],[[32,0,[\"demoMode\"]],[32,0,[\"selectedDemoMode\"]],[32,0,[\"changeDemoMode\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[32,1,[\"text\"]]],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "alliance-business-suite/components/demo/brand-switcher.hbs"
  });

  let DemoBrandSwitcherComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._action, _dec6 = Ember._action, (_class = class DemoBrandSwitcherComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "brand", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "session", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "selectedDemoMode", _descriptor4, this);
      (0, _defineProperty2.default)(this, "brandOptions", [{
        text: "Speed Queen",
        id: "SPEED_QUEEN"
      }, {
        text: "Huebsch",
        id: "HUEBSCH"
      }, {
        text: "UniMac",
        id: "UNIMAC"
      }, {
        text: "IPSO",
        id: "IPSO"
      }, {
        text: "Primus",
        id: "PRIMUS"
      }]);
      (0, _defineProperty2.default)(this, "demoMode", [{
        id: "enable",
        text: "Demo mode enabled"
      }, {
        id: "disable",
        text: "Disable demo mode"
      }]);
    }

    get showDisableSwitcher() {
      let userId = this.access.accessUser.id;
      const users = ["dbe22fb5e52b", "9884c4071bf9", "701ed6496876", "3cb2e0ac2e85", "47a60a70ada6"];
      return users.some(val => {
        return userId.indexOf(val) != -1;
      });
    }

    get selectedBrand() {
      let brand;

      switch (this.brand.slug) {
        case "huebsch":
          brand = this.brandOptions[1];
          break;

        case "unimac":
          brand = this.brandOptions[2];
          break;

        case "ipso":
          brand = this.brandOptions[3];
          break;

        case "primus":
          brand = this.brandOptions[4];
          break;

        default:
          brand = this.brandOptions[0];
      }

      return brand;
    }

    set selectedBrand(val) {
      return val;
    }

    changeSelectedBrand(option) {
      this.brand.loadBrand(option.id);
      this.selectedBrand = option;
    }

    changeDemoMode(option) {
      if (option.id === "disable") {
        if (confirm("Once disabled, you will need to log out and back in to re-enable demo mode.")) {
          this.session.set("data.isDemoUser", false);
          setTimeout(() => {
            location.reload();
          }, 250);
        }
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "brand", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "session", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedDemoMode", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.demoMode[0];
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "changeSelectedBrand", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "changeSelectedBrand"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "changeDemoMode", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "changeDemoMode"), _class.prototype)), _class));
  _exports.default = DemoBrandSwitcherComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, DemoBrandSwitcherComponent);
});