define("alliance-business-suite/templates/components/table/programs/send/table-cell-machine-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hnTLY5bE",
    "block": "{\"symbols\":[\"@record\"],\"statements\":[[1,[32,1,[\"typeName\"]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "alliance-business-suite/templates/components/table/programs/send/table-cell-machine-info.hbs"
  });

  _exports.default = _default;
});