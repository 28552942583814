define("alliance-business-suite/components/summary/reports/s-daily-overview", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "alliance-business-suite/utils/currency-format-util", "alliance-business-suite/utils/report-tables-util"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _currencyFormatUtil, _reportTablesUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  let SummaryReportsSDailyOverviewComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, (_class = class SummaryReportsSDailyOverviewComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor3, this);
    }

    get translatedColumns() {
      return [{
        name: 'location',
        valuePath: 'name',
        width: 379,
        isFixed: 'left'
      }, {
        name: 'date',
        valuePath: 'date',
        width: 180
      }, {
        name: 'SELF_SERVICE_COIN_REVENUE',
        valuePath: 'selfServiceCoinRevenue',
        width: 180
      }, {
        name: 'SELF_SERVICE_CARD_REVENUE',
        valuePath: 'selfServiceCardRevenue',
        width: 180
      }, {
        name: 'SELF_SERVICE_APP_REVENUE',
        valuePath: 'selfServiceAppRevenue',
        width: 180
      }, {
        name: 'SELF_SERVICE_PULSE_REVENUE',
        valuePath: 'selfServicePulseRevenue',
        width: 180
      }, {
        name: 'machine_starts_revenue',
        valuePath: 'machineStartsRevenue',
        width: 180
      }, {
        name: 'wdf_revenue',
        valuePath: 'wdfRevenue',
        width: 180
      }, {
        name: 'goods_revenue',
        valuePath: 'goodsRevenue',
        width: 180
      }, {
        name: 'float',
        valuePath: 'float',
        width: 180
      }, {
        name: 'total_credit_app',
        valuePath: 'totalCreditApp',
        width: 180
      }, {
        name: 'total_cash_pos',
        valuePath: 'totalCashPos',
        width: 180
      }, {
        name: 'total_credit_pos',
        valuePath: 'totalCreditPos',
        width: 180
      }, {
        name: 'total_cash_vcm',
        valuePath: 'totalCashVcm',
        width: 180
      }, {
        name: 'total_cash_payment_center',
        valuePath: 'totalCashPaymentCenter',
        width: 180
      }, {
        name: 'total_credit_payment_center',
        valuePath: 'totalCreditPaymentCenter',
        width: 180
      }];
    }

    get rowsWithChildren() {
      let {
        model
      } = this.args;
      let geoBoundaries = model.geoBoundaries;
      let rowData = [];
      let grandTotal = {
        selfServiceRevenue: 0,
        selfServiceCoinRev: 0,
        selfServiceCardRev: 0,
        selfServiceAppRev: 0,
        selfServicePulseRev: 0,
        machineStartRev: 0,
        wdfRev: 0,
        goodsRev: 0,
        float: 0,
        totalCreditAppRev: 0,
        totalCashPosRev: 0,
        totalCreditPosRev: 0,
        totalCashVcmRev: 0,
        totalPaymentCenterDepositCashRev: 0,
        totalPaymentCenterDepositCreditRev: 0
      };
      geoBoundaries.forEach(geoBoundary => {
        let nameArray = [];
        let totalOrgSelfServiceCoinRevenueSum = 0;
        let totalOrgSelfServiceCoinRevenueTotal = [];
        let totalOrgSelfServiceCardRevenueSum = 0;
        let totalOrgSelfServiceCardRevenueTotal = [];
        let totalOrgSelfServiceAppRevenueSum = 0;
        let totalOrgSelfServiceAppRevenueTotal = [];
        let totalOrgSelfServicePulseRevenueSum = 0;
        let totalOrgSelfServicePulseRevenueTotal = [];
        let totalOrgWdfRevenueSum = 0;
        let totalOrgWdfRevenueTotal = [];
        let totalOrgGoodsRevenueSum = 0;
        let totalOrgGoodsRevenueTotal = [];
        let totalOrgFloatSum = 0;
        let totalOrgFloatTotal = [];
        let totalOrgMachineStartsRevenueSum = 0;
        let totalOrgMachineStartsRevenueTotal = [];
        let totalOrgTotalCashPosSum = 0;
        let totalOrgTotalCashPosTotal = [];
        let totalOrgTotalCashVcmSum = 0;
        let totalOrgTotalCashVcmTotal = []; // payment center
        // let totalOrgTotalPaymentCenterSum = 0;

        let totalOrgTotalCashPaymentCenterSum = 0;
        let totalOrgTotalCreditPaymentCenterSum = 0;
        let totalOrgTotalCashPaymentCenterTotal = [];
        let totalOrgTotalCreditPaymentCenterTotal = [];
        let totalOrgTotalCreditPosSum = 0;
        let totalOrgTotalCreditPosTotal = [];
        let totalOrgTotalCreditAppSum = 0;
        let totalOrgTotalCreditAppTotal = [];
        rowData.push({
          name: _reportTablesUtil.localizeString.call(this, geoBoundary.name, 'Location', 'location'),
          selfServiceCoinRevenue: totalOrgSelfServiceCoinRevenueTotal,
          selfServiceCardRevenue: totalOrgSelfServiceCardRevenueTotal,
          selfServiceAppRevenue: totalOrgSelfServiceAppRevenueTotal,
          selfServicePulseRevenue: totalOrgSelfServicePulseRevenueTotal,
          wdfRevenue: totalOrgWdfRevenueTotal,
          goodsRevenue: totalOrgGoodsRevenueTotal,
          float: totalOrgFloatTotal,
          machineStartsRevenue: totalOrgMachineStartsRevenueTotal,
          totalCashPos: totalOrgTotalCashPosTotal,
          totalCashVcm: totalOrgTotalCashVcmTotal,
          totalCashPaymentCenter: totalOrgTotalCashPaymentCenterTotal,
          totalCreditPaymentCenter: totalOrgTotalCreditPaymentCenterTotal,
          totalCreditPos: totalOrgTotalCreditPosTotal,
          totalCreditApp: totalOrgTotalCreditAppTotal,
          children: nameArray
        });
        geoBoundary.months.forEach(month => {
          let transactionArray = [];
          let totalMonthSelfServiceCoinRevenueSum = 0;
          let totalMonthSelfServiceCoinRevenueTotal = [];
          let totalMonthSelfServiceCardRevenueSum = 0;
          let totalMonthSelfServiceCardRevenueTotal = [];
          let totalMonthSelfServiceAppRevenueSum = 0;
          let totalMonthSelfServiceAppRevenueTotal = [];
          let totalMonthSelfServicePulseRevenueSum = 0;
          let totalMonthSelfServicePulseRevenueTotal = [];
          let totalMonthWdfRevenueSum = 0;
          let totalMonthWdfRevenueTotal = [];
          let totalMonthGoodsRevenueSum = 0;
          let totalMonthGoodsRevenueTotal = [];
          let totalMonthFloatSum = 0;
          let totalMonthFloatTotal = [];
          let totalMonthMachineStartsRevenueSum = 0;
          let totalMonthMachineStartsRevenueTotal = [];
          let totalMonthTotalCashPosSum = 0;
          let totalMonthTotalCashPosTotal = [];
          let totalMonthTotalCashVcmSum = 0;
          let totalMonthTotalCashVcmTotal = []; // payment center

          let totalMonthTotalCashPaymentCenterSum = 0;
          let totalMonthTotalCreditPaymentCenterSum = 0;
          let totalMonthTotalCashPaymentCenterTotal = [];
          let totalMonthTotalCreditPaymentCenterTotal = [];
          let totalMonthTotalCreditPosSum = 0;
          let totalMonthTotalCreditPosTotal = [];
          let totalMonthTotalCreditAppSum = 0;
          let totalMonthTotalCreditAppTotal = [];
          nameArray.push({
            name: month.name,
            selfServiceCoinRevenue: totalMonthSelfServiceCoinRevenueTotal,
            selfServiceCardRevenue: totalMonthSelfServiceCardRevenueTotal,
            selfServiceAppRevenue: totalMonthSelfServiceAppRevenueTotal,
            selfServicePulseRevenue: totalMonthSelfServicePulseRevenueTotal,
            wdfRevenue: totalMonthWdfRevenueTotal,
            goodsRevenue: totalMonthGoodsRevenueTotal,
            float: totalMonthFloatTotal,
            machineStartsRevenue: totalMonthMachineStartsRevenueTotal,
            totalCashPos: totalMonthTotalCashPosTotal,
            totalCashVcm: totalMonthTotalCashVcmTotal,
            totalCashPaymentCenter: totalMonthTotalCashPaymentCenterTotal,
            totalCreditPaymentCenter: totalMonthTotalCreditPaymentCenterTotal,
            totalCreditPos: totalMonthTotalCreditPosTotal,
            totalCreditApp: totalMonthTotalCreditAppTotal,
            children: transactionArray
          });
          month.weeks.forEach(week => {
            let daysArray = [];
            let totalWeekSelfServiceCoinRevenueSum = 0;
            let totalWeekSelfServiceCoinRevenueTotal = [];
            let totalWeekSelfServiceCardRevenueSum = 0;
            let totalWeekSelfServiceCardRevenueTotal = [];
            let totalWeekSelfServiceAppRevenueSum = 0;
            let totalWeekSelfServiceAppRevenueTotal = [];
            let totalWeekSelfServicePulseRevenueSum = 0;
            let totalWeekSelfServicePulseRevenueTotal = [];
            let totalWeekWdfRevenueSum = 0;
            let totalWeekWdfRevenueTotal = [];
            let totalWeekGoodsRevenueSum = 0;
            let totalWeekGoodsRevenueTotal = [];
            let totalWeekFloatSum = 0;
            let totalWeekFloatTotal = [];
            let totalWeekMachineStartsRevenueSum = 0;
            let totalWeekMachineStartsRevenueTotal = [];
            let totalWeekTotalCashPosSum = 0;
            let totalWeekTotalCashPosTotal = [];
            let totalWeekTotalCashVcmSum = 0;
            let totalWeekTotalCashVcmTotal = []; // payment center

            let totalWeekTotalPaymentCenterSum = 0;
            let totalWeekTotalCashPaymentCenterSum = 0;
            let totalWeekTotalCreditPaymentCenterSum = 0;
            let totalWeekTotalCashPaymentCenterTotal = [];
            let totalWeekTotalCreditPaymentCenterTotal = [];
            let totalWeekTotalCreditPosSum = 0;
            let totalWeekTotalCreditPosTotal = [];
            let totalWeekTotalCreditAppSum = 0;
            let totalWeekTotalCreditAppTotal = [];
            transactionArray.push({
              name: week.name,
              selfServiceCoinRevenue: totalWeekSelfServiceCoinRevenueTotal,
              selfServiceCardRevenue: totalWeekSelfServiceCardRevenueTotal,
              selfServiceAppRevenue: totalWeekSelfServiceAppRevenueTotal,
              selfServicePulseRevenue: totalWeekSelfServicePulseRevenueTotal,
              wdfRevenue: totalWeekWdfRevenueTotal,
              goodsRevenue: totalWeekGoodsRevenueTotal,
              float: totalWeekFloatTotal,
              machineStartsRevenue: totalWeekMachineStartsRevenueTotal,
              totalCashPos: totalWeekTotalCashPosTotal,
              totalCashVcm: totalWeekTotalCashVcmTotal,
              totalCashPaymentCenter: totalWeekTotalCashPaymentCenterTotal,
              totalCreditPaymentCenter: totalWeekTotalCreditPaymentCenterTotal,
              totalCreditPos: totalWeekTotalCreditPosTotal,
              totalCreditApp: totalWeekTotalCreditAppTotal,
              children: daysArray
            });
            week.days.forEach(day => {
              let daySelfServiceCoinRevenueAmountArray = [];
              let daySelfServiceCardRevenueAmountArray = [];
              let daySelfServiceAppRevenueAmountArray = [];
              let daySelfServicePulseRevenueAmountArray = [];
              let dayWdfRevenueAmountArray = [];
              let dayFloatAmountArray = [];
              let dayGoodsRevenueAmountArray = [];
              let dayMachineStartsRevenueAmountArray = [];
              let dayTotalCashPosAmountArray = [];
              let dayTotalCashVcmAmountArray = []; // payment center

              let dayTotalCashPaymentCenterAmountArray = [];
              let dayTotalCreditPaymentCenterAmountArray = [];
              let dayTotalCreditPosAmountArray = [];
              let dayTotalCreditAppAmountArray = [];

              if (day.selfServiceCoin !== 0) {
                daySelfServiceCoinRevenueAmountArray.push(day.selfServiceCoin);
                totalOrgSelfServiceCoinRevenueSum = (0, _reportTablesUtil.sumArray)(daySelfServiceCoinRevenueAmountArray, totalOrgSelfServiceCoinRevenueSum);
                totalMonthSelfServiceCoinRevenueSum = (0, _reportTablesUtil.sumArray)(daySelfServiceCoinRevenueAmountArray, totalMonthSelfServiceCoinRevenueSum);
                totalWeekSelfServiceCoinRevenueSum = (0, _reportTablesUtil.sumArray)(daySelfServiceCoinRevenueAmountArray, totalWeekSelfServiceCoinRevenueSum);
              }

              if (day.selfServiceCard !== 0) {
                daySelfServiceCardRevenueAmountArray.push(day.selfServiceCard);
                totalOrgSelfServiceCardRevenueSum = (0, _reportTablesUtil.sumArray)(daySelfServiceCardRevenueAmountArray, totalOrgSelfServiceCardRevenueSum);
                totalMonthSelfServiceCardRevenueSum = (0, _reportTablesUtil.sumArray)(daySelfServiceCardRevenueAmountArray, totalMonthSelfServiceCardRevenueSum);
                totalWeekSelfServiceCardRevenueSum = (0, _reportTablesUtil.sumArray)(daySelfServiceCardRevenueAmountArray, totalWeekSelfServiceCardRevenueSum);
              }

              if (day.selfServiceApp !== 0) {
                daySelfServiceAppRevenueAmountArray.push(day.selfServiceApp);
                totalOrgSelfServiceAppRevenueSum = (0, _reportTablesUtil.sumArray)(daySelfServiceAppRevenueAmountArray, totalOrgSelfServiceAppRevenueSum);
                totalMonthSelfServiceAppRevenueSum = (0, _reportTablesUtil.sumArray)(daySelfServiceAppRevenueAmountArray, totalMonthSelfServiceAppRevenueSum);
                totalWeekSelfServiceAppRevenueSum = (0, _reportTablesUtil.sumArray)(daySelfServiceAppRevenueAmountArray, totalWeekSelfServiceAppRevenueSum);
              }

              if (day.selfServicePulse !== 0) {
                daySelfServicePulseRevenueAmountArray.push(day.selfServicePulse);
                totalOrgSelfServicePulseRevenueSum = (0, _reportTablesUtil.sumArray)(daySelfServicePulseRevenueAmountArray, totalOrgSelfServicePulseRevenueSum);
                totalMonthSelfServicePulseRevenueSum = (0, _reportTablesUtil.sumArray)(daySelfServicePulseRevenueAmountArray, totalMonthSelfServicePulseRevenueSum);
                totalWeekSelfServicePulseRevenueSum = (0, _reportTablesUtil.sumArray)(daySelfServicePulseRevenueAmountArray, totalWeekSelfServicePulseRevenueSum);
              }

              if (day.wdf !== 0) {
                dayWdfRevenueAmountArray.push(day.wdf);
                totalOrgWdfRevenueSum = (0, _reportTablesUtil.sumArray)(dayWdfRevenueAmountArray, totalOrgWdfRevenueSum);
                totalMonthWdfRevenueSum = (0, _reportTablesUtil.sumArray)(dayWdfRevenueAmountArray, totalMonthWdfRevenueSum);
                totalWeekWdfRevenueSum = (0, _reportTablesUtil.sumArray)(dayWdfRevenueAmountArray, totalWeekWdfRevenueSum);
              }

              if (day.float !== 0) {
                dayFloatAmountArray.push(day.float);
                totalOrgFloatSum = (0, _reportTablesUtil.sumArray)(dayFloatAmountArray, totalOrgFloatSum);
                totalMonthFloatSum = (0, _reportTablesUtil.sumArray)(dayFloatAmountArray, totalMonthFloatSum);
                totalWeekFloatSum = (0, _reportTablesUtil.sumArray)(dayFloatAmountArray, totalWeekFloatSum);
              }

              if (day.goods !== 0) {
                dayGoodsRevenueAmountArray.push(day.goods);
                totalOrgGoodsRevenueSum = (0, _reportTablesUtil.sumArray)(dayGoodsRevenueAmountArray, totalOrgGoodsRevenueSum);
                totalMonthGoodsRevenueSum = (0, _reportTablesUtil.sumArray)(dayGoodsRevenueAmountArray, totalMonthGoodsRevenueSum);
                totalWeekGoodsRevenueSum = (0, _reportTablesUtil.sumArray)(dayGoodsRevenueAmountArray, totalWeekGoodsRevenueSum);
              }

              if (day.machineStarts !== 0) {
                dayMachineStartsRevenueAmountArray.push(day.machineStarts);
                totalOrgMachineStartsRevenueSum = (0, _reportTablesUtil.sumArray)(dayMachineStartsRevenueAmountArray, totalOrgMachineStartsRevenueSum);
                totalMonthMachineStartsRevenueSum = (0, _reportTablesUtil.sumArray)(dayMachineStartsRevenueAmountArray, totalMonthMachineStartsRevenueSum);
                totalWeekMachineStartsRevenueSum = (0, _reportTablesUtil.sumArray)(dayMachineStartsRevenueAmountArray, totalWeekMachineStartsRevenueSum);
              }

              if (day.totalCashPos !== 0) {
                dayTotalCashPosAmountArray.push(day.totalCashPos);
                totalOrgTotalCashPosSum = (0, _reportTablesUtil.sumArray)(dayTotalCashPosAmountArray, totalOrgTotalCashPosSum);
                totalMonthTotalCashPosSum = (0, _reportTablesUtil.sumArray)(dayTotalCashPosAmountArray, totalMonthTotalCashPosSum);
                totalWeekTotalCashPosSum = (0, _reportTablesUtil.sumArray)(dayTotalCashPosAmountArray, totalWeekTotalCashPosSum);
              }

              if (day.totalCashVcm !== 0) {
                dayTotalCashVcmAmountArray.push(day.totalCashVcm);
                totalOrgTotalCashVcmSum = (0, _reportTablesUtil.sumArray)(dayTotalCashVcmAmountArray, totalOrgTotalCashVcmSum);
                totalMonthTotalCashVcmSum = (0, _reportTablesUtil.sumArray)(dayTotalCashVcmAmountArray, totalMonthTotalCashVcmSum);
                totalWeekTotalCashVcmSum = (0, _reportTablesUtil.sumArray)(dayTotalCashVcmAmountArray, totalWeekTotalCashVcmSum);
              } // payment center
              // if (day.totalPaymentCenter !== 0) {
              //   dayTotalPaymentCenterAmountArray.push(day.totalPaymentCenter);
              //   totalOrgTotalPaymentCenterSum = sumArray(dayTotalPaymentCenterAmountArray, totalOrgTotalPaymentCenterSum);
              //   totalMonthTotalPaymentCenterSum = sumArray(dayTotalPaymentCenterAmountArray, totalMonthTotalPaymentCenterSum);
              //   totalWeekTotalPaymentCenterSum = sumArray(dayTotalPaymentCenterAmountArray, totalWeekTotalPaymentCenterSum);
              // }
              // payment center


              if (day.totalCashPaymentCenter !== 0) {
                dayTotalCashPaymentCenterAmountArray.push(day.totalCashPaymentCenter);
                totalOrgTotalCashPaymentCenterSum = (0, _reportTablesUtil.sumArray)(dayTotalCashPaymentCenterAmountArray, totalOrgTotalCashPaymentCenterSum);
                totalMonthTotalCashPaymentCenterSum = (0, _reportTablesUtil.sumArray)(dayTotalCashPaymentCenterAmountArray, totalMonthTotalCashPaymentCenterSum);
                totalWeekTotalCashPaymentCenterSum = (0, _reportTablesUtil.sumArray)(dayTotalCashPaymentCenterAmountArray, totalWeekTotalCashPaymentCenterSum);
              }

              if (day.totalCreditPaymentCenter !== 0) {
                dayTotalCreditPaymentCenterAmountArray.push(day.totalCreditPaymentCenter);
                totalOrgTotalCreditPaymentCenterSum = (0, _reportTablesUtil.sumArray)(dayTotalCreditPaymentCenterAmountArray, totalOrgTotalCreditPaymentCenterSum);
                totalMonthTotalCreditPaymentCenterSum = (0, _reportTablesUtil.sumArray)(dayTotalCreditPaymentCenterAmountArray, totalMonthTotalCreditPaymentCenterSum);
                totalWeekTotalCreditPaymentCenterSum = (0, _reportTablesUtil.sumArray)(dayTotalCreditPaymentCenterAmountArray, totalWeekTotalCreditPaymentCenterSum);
              }

              if (day.totalCreditPos !== 0) {
                dayTotalCreditPosAmountArray.push(day.totalCreditPos);
                totalOrgTotalCreditPosSum = (0, _reportTablesUtil.sumArray)(dayTotalCreditPosAmountArray, totalOrgTotalCreditPosSum);
                totalMonthTotalCreditPosSum = (0, _reportTablesUtil.sumArray)(dayTotalCreditPosAmountArray, totalMonthTotalCreditPosSum);
                totalWeekTotalCreditPosSum = (0, _reportTablesUtil.sumArray)(dayTotalCreditPosAmountArray, totalWeekTotalCreditPosSum);
              }

              if (day.totalCreditApp !== 0) {
                dayTotalCreditAppAmountArray.push(day.totalCreditApp);
                totalOrgTotalCreditAppSum = (0, _reportTablesUtil.sumArray)(dayTotalCreditAppAmountArray, totalOrgTotalCreditAppSum);
                totalMonthTotalCreditAppSum = (0, _reportTablesUtil.sumArray)(dayTotalCreditAppAmountArray, totalMonthTotalCreditAppSum);
                totalWeekTotalCreditAppSum = (0, _reportTablesUtil.sumArray)(dayTotalCreditAppAmountArray, totalWeekTotalCreditAppSum);
              }

              daysArray.push({
                name: day.name,
                date: day.date,
                selfServiceCoinRevenue: _currencyFormatUtil.formatCurrencyString.call(this, day.selfServiceCoin || 0),
                selfServiceCardRevenue: _currencyFormatUtil.formatCurrencyString.call(this, day.selfServiceCard || 0),
                selfServiceAppRevenue: _currencyFormatUtil.formatCurrencyString.call(this, day.selfServiceApp || 0),
                selfServicePulseRevenue: _currencyFormatUtil.formatCurrencyString.call(this, day.selfServicePulse || 0),
                wdfRevenue: _currencyFormatUtil.formatCurrencyString.call(this, day.wdf || 0),
                float: _currencyFormatUtil.formatCurrencyString.call(this, day.float || 0),
                goodsRevenue: _currencyFormatUtil.formatCurrencyString.call(this, day.goods || 0),
                machineStartsRevenue: _currencyFormatUtil.formatCurrencyString.call(this, day.machineStarts || 0),
                totalCashPos: _currencyFormatUtil.formatCurrencyString.call(this, day.totalCashPos || 0),
                totalCashVcm: _currencyFormatUtil.formatCurrencyString.call(this, day.totalCashVcm || 0),
                // totalPaymentCenter: formatCurrencyString.call(this, day.totalPaymentCenter || 0), // payment center
                totalCashPaymentCenter: _currencyFormatUtil.formatCurrencyString.call(this, day.totalCashPaymentCenter || 0),
                // payment center
                totalCreditPaymentCenter: _currencyFormatUtil.formatCurrencyString.call(this, day.totalCreditPaymentCenter || 0),
                // payment center
                totalCreditPos: _currencyFormatUtil.formatCurrencyString.call(this, day.totalCreditPos || 0),
                totalCreditApp: _currencyFormatUtil.formatCurrencyString.call(this, day.totalCreditApp || 0)
              });
            });
            totalWeekSelfServiceCardRevenueTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalWeekSelfServiceCardRevenueSum || 0));
            totalWeekSelfServiceCoinRevenueTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalWeekSelfServiceCoinRevenueSum || 0));
            totalWeekSelfServiceAppRevenueTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalWeekSelfServiceAppRevenueSum || 0));
            totalWeekSelfServicePulseRevenueTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalWeekSelfServicePulseRevenueSum || 0));
            totalWeekWdfRevenueTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalWeekWdfRevenueSum || 0));
            totalWeekFloatTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalWeekFloatSum || 0));
            totalWeekGoodsRevenueTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalWeekGoodsRevenueSum || 0));
            totalWeekMachineStartsRevenueTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalWeekMachineStartsRevenueSum || 0));
            totalWeekTotalCashPosTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalWeekTotalCashPosSum || 0));
            totalWeekTotalCashVcmTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalWeekTotalCashVcmSum || 0));
            totalWeekTotalCashPaymentCenterTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalWeekTotalCashPaymentCenterSum || 0)); // payment center

            totalWeekTotalCreditPaymentCenterTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalWeekTotalCreditPaymentCenterSum || 0)); // payment center

            totalWeekTotalCreditPosTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalWeekTotalCreditPosSum || 0));
            totalWeekTotalCreditAppTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalWeekTotalCreditAppSum || 0));
          });
          totalMonthSelfServiceCoinRevenueTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalMonthSelfServiceCoinRevenueSum || 0));
          totalMonthSelfServiceCardRevenueTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalMonthSelfServiceCardRevenueSum || 0));
          totalMonthSelfServiceAppRevenueTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalMonthSelfServiceAppRevenueSum || 0));
          totalMonthSelfServicePulseRevenueTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalMonthSelfServicePulseRevenueSum || 0));
          totalMonthWdfRevenueTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalMonthWdfRevenueSum || 0));
          totalMonthFloatTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalMonthFloatSum || 0));
          totalMonthGoodsRevenueTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalMonthGoodsRevenueSum || 0));
          totalMonthMachineStartsRevenueTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalMonthMachineStartsRevenueSum || 0));
          totalMonthTotalCashPosTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalMonthTotalCashPosSum || 0));
          totalMonthTotalCashVcmTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalMonthTotalCashVcmSum || 0));
          totalMonthTotalCashPaymentCenterTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalMonthTotalCashPaymentCenterTotal || 0)); // payment center

          totalMonthTotalCreditPaymentCenterTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalMonthTotalCreditPaymentCenterTotal || 0)); // payment center

          totalMonthTotalCreditPosTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalMonthTotalCreditPosSum || 0));
          totalMonthTotalCreditAppTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalMonthTotalCreditAppSum || 0));
        });
        totalOrgSelfServiceCoinRevenueTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalOrgSelfServiceCoinRevenueSum || 0));
        totalOrgSelfServiceCardRevenueTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalOrgSelfServiceCardRevenueSum || 0));
        totalOrgSelfServiceAppRevenueTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalOrgSelfServiceAppRevenueSum || 0));
        totalOrgSelfServicePulseRevenueTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalOrgSelfServicePulseRevenueSum || 0));
        totalOrgWdfRevenueTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalOrgWdfRevenueSum || 0));
        totalOrgFloatTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalOrgFloatSum || 0));
        totalOrgGoodsRevenueTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalOrgGoodsRevenueSum || 0));
        totalOrgMachineStartsRevenueTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalOrgMachineStartsRevenueSum || 0));
        totalOrgTotalCashPosTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalOrgTotalCashPosSum || 0));
        totalOrgTotalCashVcmTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalOrgTotalCashVcmSum || 0));
        totalOrgTotalCashPaymentCenterTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalOrgTotalCashPaymentCenterSum || 0)); // payment center

        totalOrgTotalCreditPaymentCenterTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalOrgTotalCreditPaymentCenterSum || 0)); // payment center

        totalOrgTotalCreditPosTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalOrgTotalCreditPosSum || 0));
        totalOrgTotalCreditAppTotal.push(_currencyFormatUtil.formatCurrencyString.call(this, totalOrgTotalCreditAppSum || 0));
        grandTotal = {
          selfServiceCoinRev: grandTotal.selfServiceCoinRev += totalOrgSelfServiceCoinRevenueSum,
          selfServiceCardRev: grandTotal.selfServiceCardRev += totalOrgSelfServiceCardRevenueSum,
          selfServiceAppRev: grandTotal.selfServiceAppRev += totalOrgSelfServiceAppRevenueSum,
          selfServicePulseRev: grandTotal.selfServicePulseRev += totalOrgSelfServicePulseRevenueSum,
          // selfServiceRevenue: grandTotal.selfServiceRevenue += totalOrgSelfServiceRevenueSum,
          machineStartRev: grandTotal.machineStartRev += totalOrgMachineStartsRevenueSum,
          wdfRev: grandTotal.wdfRev += totalOrgWdfRevenueSum,
          goodsRev: grandTotal.goodsRev += totalOrgGoodsRevenueSum,
          float: grandTotal.float += totalOrgFloatSum,
          totalCreditAppRev: grandTotal.totalCreditAppRev += totalOrgTotalCreditAppSum,
          totalCashPosRev: grandTotal.totalCashPosRev += totalOrgTotalCashPosSum,
          totalCreditPosRev: grandTotal.totalCreditPosRev += totalOrgTotalCreditPosSum,
          totalCashVcmRev: grandTotal.totalCashVcmRev += totalOrgTotalCashVcmSum,
          totalPaymentCenterDepositCashRev: grandTotal.totalPaymentCenterDepositCashRev += totalOrgTotalCashPaymentCenterSum,
          totalPaymentCenterDepositCreditRev: grandTotal.totalPaymentCenterDepositCreditRev += totalOrgTotalCreditPaymentCenterSum
        };
      }); // grand total row

      rowData.push({
        name: this.intl.t('total'),
        selfServiceRevenue: _currencyFormatUtil.formatCurrencyString.call(this, grandTotal.selfServiceRevenue || 0),
        selfServiceCoinRevenue: _currencyFormatUtil.formatCurrencyString.call(this, grandTotal.selfServiceCoinRev || 0),
        selfServiceCardRevenue: _currencyFormatUtil.formatCurrencyString.call(this, grandTotal.selfServiceCardRev || 0),
        selfServiceAppRevenue: _currencyFormatUtil.formatCurrencyString.call(this, grandTotal.selfServiceAppRev || 0),
        selfServicePulseRevenue: _currencyFormatUtil.formatCurrencyString.call(this, grandTotal.selfServicePulseRev || 0),
        wdfRevenue: _currencyFormatUtil.formatCurrencyString.call(this, grandTotal.wdfRev || 0),
        goodsRevenue: _currencyFormatUtil.formatCurrencyString.call(this, grandTotal.goodsRev || 0),
        float: _currencyFormatUtil.formatCurrencyString.call(this, grandTotal.float || 0),
        machineStartsRevenue: _currencyFormatUtil.formatCurrencyString.call(this, grandTotal.machineStartRev || 0),
        totalCashPos: _currencyFormatUtil.formatCurrencyString.call(this, grandTotal.totalCashPosRev || 0),
        totalCashVcm: _currencyFormatUtil.formatCurrencyString.call(this, grandTotal.totalCashVcmRev || 0),
        totalCashPaymentCenter: _currencyFormatUtil.formatCurrencyString.call(this, grandTotal.totalPaymentCenterDepositCashRev || 0),
        totalCreditPaymentCenter: _currencyFormatUtil.formatCurrencyString.call(this, grandTotal.totalPaymentCenterDepositCreditRev || 0),
        totalCreditPos: _currencyFormatUtil.formatCurrencyString.call(this, grandTotal.totalCreditPosRev || 0),
        totalCreditApp: _currencyFormatUtil.formatCurrencyString.call(this, grandTotal.totalCreditAppRev || 0)
      });
      return rowData;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SummaryReportsSDailyOverviewComponent;
});