define("alliance-business-suite/templates/components/ui/card-stack", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hKMGKmsY",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,7],null,[[\"card\",\"selector\"],[[30,[36,6],[\"ui/card-item\"],[[\"selected\"],[[35,1]]]],[30,[36,6],[\"ui/tab-list\",[35,5]],[[\"intlPrefix\",\"counts\",\"selected\",\"onselect\"],[[35,4],[35,3],[30,[36,2],[[35,1]],null],[30,[36,0],[[32,0],\"select\"],null]]]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"activeCard\",\"readonly\",\"counts\",\"intlPrefix\",\"cards\",\"component\",\"hash\"]}",
    "moduleName": "alliance-business-suite/templates/components/ui/card-stack.hbs"
  });

  _exports.default = _default;
});