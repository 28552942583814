define("alliance-business-suite/components/profile/billing-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    activeSection: 'locations',
    access: Ember.inject.service(),
    locations: Ember.computed.alias('access.billingLocations'),
    sortBy: ['name'],
    sortedLocations: Ember.computed.sort('locations', 'sortBy'),
    cartCount: Ember.computed('locations.@each.inCart', function () {
      return this.locations.reduce((prev, item) => {
        if (item.inCart) {
          prev++;
        }

        return prev;
      }, 0);
    }),
    actions: {
      setSection(tab) {
        this.set('activeSection', tab);
      },

      addAll() {
        this.locations.forEach(location => {
          Ember.set(location, 'inCart', true);
        });
      },

      removeAll() {
        this.locations.forEach(location => {
          Ember.set(location, 'inCart', false);
        });
      },

      addSubscription(location, e) {
        Ember.set(location, 'inCart', true);
      },

      removeSubscription(location, e) {
        Ember.set(location, 'inCart', false);
      }

    }
  });

  _exports.default = _default;
});