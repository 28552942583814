define("alliance-business-suite/components/maps/google-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // googleMapsApi: service(),
    // google: reads('googleMapsApi.google'),
    zoom: 10,
    scrollwheel: true,
    zoomControl: true,
    zoomControlPosition: 1,
    // google.maps.ControlPosition.TOP_LEFT
    mapTypeControl: true,
    draggable: true,
    markerTooltipOpen: false,
    // Where the map is centered by default if no location is set
    defaultLatitude: 43.84591670,
    defaultLongitude: -88.85121520,
    draggableCursor: Ember.computed('draggable', function () {
      return this.draggable ? '' : 'default';
    }),
    mapMarkers: Ember.computed('markers', function () {
      let map = this.map;
      let markers = this.markers;

      if (markers && map && (!this.lat || !this.lng)) {
        if (markers.length > 1) {
          this.fitBounds();
        } else {
          map.setCenter({
            lat: parseFloat(markers[0].lat),
            lng: parseFloat(markers[0].lng)
          });
          map.setZoom(this.zoom);
        }
      } // required because of an optimization on marker tooltip rendering


      if (this.markerTooltipOpen) {
        setTimeout(() => {
          // eslint-disable-next-line
          this.set('markerTooltipOpen', true);
        }, 100);
      }

      return this.markers;
    }),

    fitBounds() {
      let map = this.map;

      if (!map) {
        return false;
      }

      let markers = this.markers.filter(marker => {
        return Ember.isPresent(marker.lat) && Ember.isPresent(marker.lng);
      });

      if (markers.length > 0) {
        let bounds = new google.maps.LatLngBounds();
        let points = markers.map(marker => {
          return new google.maps.LatLng(marker.lat, marker.lng);
        });
        points.forEach(point => bounds.extend(point));
        map.fitBounds(bounds);
      }
    },

    actions: {
      loaded(_ref) {
        let {
          map,
          publicAPI
        } = _ref;
        this.set('map', map);

        if (!this.lat || !this.lng) {
          if (this.markers) {
            this.fitBounds();
          } else {
            this.set('lat', this.defaultLatitude);
            this.set('lng', this.defaultLongitude);
          }
        } // required because of an optimization on marker tooltip rendering


        if (this.markerTooltipOpen) {
          setTimeout(() => {
            this.set('markerTooltipOpen', true);
          }, 100);
        }
      },

      // used to avoid ember throwing errors about calling null functions
      emptyAction() {}

    }
  });

  _exports.default = _default;
});