define("alliance-business-suite/components/machines/machine-list-container", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;

  let MachinesMachineListContainerComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, (_class = class MachinesMachineListContainerComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "brush", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "_roomId", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "selectedRoom", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "selectedMachineFilters", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "showAdvancedOptions", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "isGrid", _descriptor8, this);
      (0, _defineProperty2.default)(this, "rooms", this.store.peekAll('room').sortBy('formattedBreadcrumbDisplay'));
      (0, _defineProperty2.default)(this, "filtersMachines", ['washers', 'dryers', 'combos']);
    }

    // computed('selectedRoom', 'selectedMachineFilters.[]',
    get filteredMachines() {
      let room = this.store.peekRecord('room', this._roomId);
      let roomMachines = room.machines;
      let selectedMachineFilters = this.selectedMachineFilters;
      return roomMachines.filter(machine => {
        if (!selectedMachineFilters) {
          return;
        } // return washers


        if (selectedMachineFilters.includes('washers') && machine.isWasher) {
          return machine;
        } // return dryers


        if (selectedMachineFilters.includes('dryers') && machine.isDryer) {
          return machine;
        } // return combos - right now combo machines have isWasher=true and isCombo is a computed property based on machine audit type.


        if (selectedMachineFilters.includes('combos') && machine.isCombo) {
          return machine;
        }
      });
    }

    initialLoad() {
      if (this._roomId) {
        this.selectedRoom = this.store.peekRecord('room', this._roomId);
      } // if there is only one room, default to that room


      if (this.rooms.length == 1) {
        const defaultRoomId = this.rooms.firstObject.get('id');
        this._roomId = defaultRoomId;
        this.selectedRoom = this.store.peekRecord('room', defaultRoomId);
      }

      this.selectedMachineFilters = this.filtersMachines;
    }

    updateSelectedRoom(room) {
      this._roomId = room.id;
      this.selectedRoom = room;
    }

    toggleAdvanced() {
      this.showAdvancedOptions = !this.showAdvancedOptions;
    }

    toggleGrid() {
      this.isGrid = !this.isGrid;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "brush", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "_roomId", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.roomId;
    }
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedRoom", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedMachineFilters", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "showAdvancedOptions", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isGrid", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "initialLoad", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "initialLoad"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "updateSelectedRoom", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "updateSelectedRoom"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "toggleAdvanced", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "toggleAdvanced"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "toggleGrid", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "toggleGrid"), _class.prototype)), _class));
  _exports.default = MachinesMachineListContainerComponent;
});