define("alliance-business-suite/components/summary/reports/s-crm", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "alliance-business-suite/utils/report-tables-util"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _reportTablesUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  let SummaryReportsSCrmComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, (_class = class SummaryReportsSCrmComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor3, this);
    }

    get translatedColumns() {
      let smsNumberOfMessagesDeliveredSum = 0;
      let emailHtmlNumberOfMessagesDeliveredSum = 0;
      let emailTextNumberOfMessagesDeliveredSum = 0;
      let inAppNumberOfMessagesDeliveredSum = 0;
      let campaignTypeData = [{
        name: 'empty_string',
        width: 300,
        isFixed: 'left',
        subcolumns: [{
          name: 'campaign_name',
          valuePath: 'name',
          width: 360,
          isFixed: 'left'
        }]
      }, {
        name: 'campaign',
        textAlign: 'center',
        subcolumns: [{
          name: 'date_time',
          valuePath: 'runDate',
          width: 225
        }, {
          name: 'multi_locations',
          valuePath: 'locations',
          width: 275
        }, {
          name: 'campaign_type',
          valuePath: 'campaignTypeLookupKey',
          width: 225
        }, {
          name: 'campaign_schedule',
          valuePath: 'campaignOneTime',
          width: 225
        }, {
          name: 'recipients',
          valuePath: 'numberOfRecipients',
          width: 225
        }]
      }];
      let campaigns = this.args.model.get('campaigns');
      campaigns.forEach(campaign => {
        campaign.runs.forEach(run => {
          let smsNumberOfMessagesDeliveredArray = [];
          let emailHtmlNumberOfMessagesDeliveredArray = [];
          let emailTextNumberOfMessagesDeliveredArray = [];
          let inAppNumberOfMessagesDeliveredArray = [];

          if (run.smsNumberOfMessagesDelivered) {
            smsNumberOfMessagesDeliveredArray.push(run.smsNumberOfMessagesDelivered);
            smsNumberOfMessagesDeliveredSum = (0, _reportTablesUtil.sumArray)(smsNumberOfMessagesDeliveredArray, smsNumberOfMessagesDeliveredSum);
          }

          if (run.emailHtmlNumberOfMessagesDelivered) {
            emailHtmlNumberOfMessagesDeliveredArray.push(run.emailHtmlNumberOfMessagesDelivered);
            emailHtmlNumberOfMessagesDeliveredSum = (0, _reportTablesUtil.sumArray)(emailHtmlNumberOfMessagesDeliveredArray, emailHtmlNumberOfMessagesDeliveredSum);
          }

          if (run.emailTextNumberOfMessagesDelivered) {
            emailTextNumberOfMessagesDeliveredArray.push(run.emailTextNumberOfMessagesDelivered);
            emailTextNumberOfMessagesDeliveredSum = (0, _reportTablesUtil.sumArray)(emailTextNumberOfMessagesDeliveredArray, emailTextNumberOfMessagesDeliveredSum);
          }

          if (run.inAppNumberOfMessagesDelivered) {
            inAppNumberOfMessagesDeliveredArray.push(run.inAppNumberOfMessagesDelivered);
            inAppNumberOfMessagesDeliveredSum = (0, _reportTablesUtil.sumArray)(inAppNumberOfMessagesDeliveredArray, inAppNumberOfMessagesDeliveredSum);
          }
        });
      });

      if (smsNumberOfMessagesDeliveredSum > 0) {
        campaignTypeData.push({
          name: 'SMS',
          textAlign: 'center',
          subcolumns: [{
            name: 'sms_number_of_messages_delivered',
            valuePath: 'smsNumberOfMessagesDelivered',
            textAlign: 'center',
            width: 300
          }, {
            name: 'sms_delivery_rate',
            valuePath: 'smsDeliveryRate',
            textAlign: 'center',
            width: 180
          }]
        });
      }

      if (emailHtmlNumberOfMessagesDeliveredSum > 0) {
        campaignTypeData.push({
          name: 'email_html',
          textAlign: 'center',
          subcolumns: [{
            name: 'email_html_number_of_messages_delivered',
            valuePath: 'emailHtmlNumberOfMessagesDelivered',
            textAlign: 'center',
            width: 300
          }, {
            name: 'email_html_delivery_rate',
            valuePath: 'emailHtmlDeliveryRate',
            textAlign: 'center',
            width: 225
          }, {
            name: 'email_html_number_of_opens',
            valuePath: 'emailHtmlNumberOfOpens',
            textAlign: 'center',
            width: 225
          }, {
            name: 'email_html_open_rate',
            valuePath: 'emailHtmlOpenRate',
            textAlign: 'center',
            width: 180
          }]
        });
      }

      if (emailTextNumberOfMessagesDeliveredSum > 0) {
        campaignTypeData.push({
          name: 'email_text',
          textAlign: 'center',
          subcolumns: [{
            name: 'email_text_number_of_messages_delivered',
            valuePath: 'emailTextNumberOfMessagesDelivered',
            textAlign: 'center',
            width: 300
          }, {
            name: 'email_text_delivery_rate',
            valuePath: 'emailTextDeliveryRate',
            textAlign: 'center',
            width: 225
          }]
        });
      }

      if (inAppNumberOfMessagesDeliveredSum > 0) {
        campaignTypeData.push({
          name: 'in_app',
          textAlign: 'center',
          subcolumns: [{
            name: 'in_app_number_of_messages_delivered',
            valuePath: 'inAppNumberOfMessagesDelivered',
            textAlign: 'center',
            width: 300
          }, {
            name: 'in_app_delivery_rate',
            valuePath: 'inAppDeliveryRate',
            textAlign: 'center',
            width: 225
          }, {
            name: 'in_app_number_of_opens',
            valuePath: 'inAppNumberOfOpens',
            textAlign: 'center',
            width: 225
          }, {
            name: 'in_app_open_rate',
            valuePath: 'inAppOpenRate',
            textAlign: 'center',
            width: 180
          }]
        });
      }

      campaignTypeData.push({
        name: 'redemptions',
        textAlign: 'center',
        subcolumns: [{
          name: 'promo_redemptions',
          valuePath: 'numberOfRedemptions',
          textAlign: 'center',
          width: 180
        }, {
          name: 'redemption_rate',
          valuePath: 'redemptionRate',
          textAlign: 'center',
          width: 180
        }]
      });
      return campaignTypeData;
    }

    get rowsWithChildren() {
      let self = this;
      let campaigns = this.args.model.get('campaigns');
      let rowData = [];
      campaigns.forEach(campaign => {
        let runDataArray = [];
        rowData.push({
          name: campaign.name,
          children: runDataArray
        });
        campaign.runs.forEach(run => {
          let runDate = "";
          let redemptionRate = run.redemptionRate * 100;

          if (run.runDate) {
            runDate = moment(run.runDate).format(`${_reportTablesUtil.formatDateTimeString.call(this)}`);
          }

          runDataArray.push({
            runDate: runDate,
            locations: run.locations || 'N/A',
            campaignTypeLookupKey: self.intl.t(run.campaignTypeLookupKey) || 'N/A',
            campaignOneTime: run.campaignOneTime || 'N/A',
            numberOfRecipients: run.numberOfRecipients || 0,
            numberOfRedemptions: run.numberOfRedemptions || 0,
            redemptionRate: `${Number(redemptionRate || 0).toFixed(2)} %`,
            smsNumberOfMessagesDelivered: run.smsNumberOfMessagesDelivered || 0,
            smsDeliveryRate: `${run.smsDeliveryRate || 0} %`,
            emailHtmlNumberOfOpens: run.emailHtmlNumberOfOpens || 0,
            emailHtmlNumberOfMessagesDelivered: run.emailHtmlNumberOfMessagesDelivered || 0,
            emailHtmlOpenRate: `${run.emailHtmlOpenRate || 0} %`,
            emailHtmlDeliveryRate: `${run.emailHtmlDeliveryRate || 0} %`,
            emailTextNumberOfMessagesDelivered: run.emailTextNumberOfMessagesDelivered || 0,
            emailTextDeliveryRate: `${run.emailTextDeliveryRate || 0} %`,
            inAppNumberOfMessagesDelivered: run.inAppNumberOfMessagesDelivered || 0,
            inAppNumberOfOpens: run.inAppNumberOfOpens || 0,
            inAppOpenRate: `${run.inAppOpenRate || 0} %`,
            inAppDeliveryRate: `${run.inAppDeliveryRate || 0} %`
          });
        });
      });
      return rowData;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SummaryReportsSCrmComponent;
});