define("alliance-business-suite/services/brush", ["exports", "jquery", "lodash", "ember-data"], function (_exports, _jquery, _lodash, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.queryParams = _exports.default = void 0;
  const {
    Model,
    RecordArray
  } = _emberData.default; // Decide if we should show the sidebar on boot or not.

  let initialScreenWidth = (0, _jquery.default)(document.documentElement).width();
  let showSidebar = initialScreenWidth > 960;

  if (showSidebar) {
    (0, _jquery.default)('body').removeClass('sidebar-hide'); // shouldn't be there initially, but we will make sure it isn't regardless.
  } else {
    (0, _jquery.default)('body').addClass('sidebar-hide');
  }
  /**
   * Returns the model type from the data object. The data object can be an
   * instance of `DS.Model` or a plain object with a `type` property.
   *
   * @param {DS.Model|object} data The data whose type should be returned
   *
   * @return {string} The type of the data parameter
   *
   * @private
   */


  function getDataType(data) {
    return data instanceof Model ? data.constructor.modelName : data.type;
  }
  /**
   * Returns true if `data1` is equivalent to `data2`. Data objects are equivalent
   * if they are both null or undefined or both share the same ID and type. Null
   * and undefined IDs are considered to be equivalent. Data objects can be either
   * instances of `DS.Model` or plain objects with `id` and `type` properties.
   *
   * @param {DS.Model|object} data1 The first data object to compare
   * @param {DS.Model|object} data2 The second data object to compare
   *
   * @return {boolean} Whether the two data objects are equivalent.
   *
   * @private
   */


  function isEquivalentData(data1, data2) {
    if (data1 == null && data2 == null) {
      return true;
    } else if (data1 == null && data2 != null || data1 != null && data2 == null) {
      return false;
    } else {
      let data2Id = null;

      try {
        data2Id = data2.get('id');
      } catch {
        data2Id = data2.id;
      }

      return data1.id == data2Id && getDataType(data1) === getDataType(data2);
    }
  }
  /**
   * The query parameters used by this service. All scrub parameters, other than
   * `scrub` itself, should be prefixed with `s` to indicate they belong to this
   * service.
   *
   * @public
   */


  const queryParams = ['scrub', 'sid', 'stype', 'sstate'];
  /**
   * A service to manage the state of the scrub brush.
   *
   * # Design
   *
   * The state of the scrub brush is managed in two places:
   *
   *  - URL Parameters
   *  - Brush Service
   *
   * Parameters are added to the URL for any non-transient state. This allows
   * URLs to be shared, with the same brush appearing.
   *
   * URL parameter management in Ember is done through the controller and the
   * route. Since brushes apply across the application architecture, the
   * application controller and route are used.
   *
   * The controller binds to the URL parameters, which are exported from this
   * service as `queryParams`. The route binds to the undocumented
   * `queryParamsDidChange`, which is called when the page is first loaded and
   * thereafter when the query parameters change.
   *
   * The properties of this service are initialized from the URL via the first
   * call to `queryParamsDidChange`. Thereafter, the properties of this service
   * are kept in sync with the URL.
   *
   * ## State
   *
   * Besides managing which brush is active and the model for that brush, this
   * service can track the state of that brush. Because the state is internal to
   * each brush, it is cleared when moving to a new brush.
   *
   * ## History
   *
   * History is managed as a queue of property changes for this service. This
   * allows the history of the brush to be independent from the browser history,
   * since the browser can add new history entries when the brush remains the
   * same (e.g. if a user is on the Facilities tab and click to the Employees tab
   * without changing the brush).
   *
   * Thus, a new history entry is only pushed when the state of this service is
   * changed (i.e. the URL parameters change). Going back in history only changes
   * the brush URL parameters itself, not the full URL.
   *
   * @public
   */

  _exports.queryParams = queryParams;

  var _default = Ember.Service.extend({
    // ---------------------------------------------------------------------------
    // PUBLIC PROPERTIES
    // ---------------------------------------------------------------------------

    /**
     * The scrub to use if no scrub is otherwise set.
     *
     * @public
     */
    default: Ember.computed({
      get() {
        return Ember.get(this, '_routeController.defaultBrush') || 'default';
      }

    }),

    /**
     * The scrub to render for the brush.
     *
     * @public
     */
    scrub: undefined,

    /**
     * The raw data used by the scrub.
     *
     * @public
     */
    data: undefined,

    /**
     * Show user's history in scrub.
     *
     * @public
     */
    showHistory: false,

    /**
     * Whether or not to show this sidebar
     *
     * @public
     */
    showSidebar,

    /**
     * Observes showSidebar and toggles the sidebar.
     *
     * @public
     */
    toggleSidebar: Ember.observer('showSidebar', function () {
      this.handleSidebar();
    }),

    handleSidebar() {
      let isHidden = (0, _jquery.default)('body').hasClass('sidebar-hide');
      let canToggle = !(0, _jquery.default)('body').hasClass('route-sidebar-hide');

      if (!canToggle) {
        return;
      }

      if (isHidden) {
        (0, _jquery.default)('body').removeClass('sidebar-hide'); // trigger resize event so listeners like highcharts can react

        setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
        }, 150);
      } else {
        (0, _jquery.default)('body').addClass('sidebar-hide'); // trigger resize event so listeners like highcharts can react

        setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
        }, 150);
      }
    },

    disable() {
      (0, _jquery.default)('body').addClass('route-sidebar-hide');
    },

    enable() {
      (0, _jquery.default)('body').removeClass('route-sidebar-hide');
    },

    show() {
      (0, _jquery.default)('body').removeClass('sidebar-hide'); // Trigger resize event

      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 150);
    },

    hide() {
      (0, _jquery.default)('body').addClass('sidebar-hide');
      setTimeout(() => {
        // Trigger resize event
        window.dispatchEvent(new Event('resize'));
      }, 150);
    },

    /**
     * The computed model instance used by the scrub.
     *
     * @public
     */
    model: Ember.computed('data', 'scrub', {
      get() {
        return this._buildModel(Ember.get(this, 'data'));
      }

    }),

    /**
     * The current state of this service, separate from the scrub and data.
     *
     * @public
     */
    state: Ember.computed('_state', {
      get() {
        return this._state;
      },

      set(key, value) {
        let entry = this._peekHistory(Ember.get(this, 'historyIndex')); // Update the state in the current history item; state changes don't
        // trigger transitions


        entry.state = value;
        this.set('_state', value); // Update the controller, which updates the URL

        Ember.setProperties(Ember.get(this, '_appController'), this._serializeState(value));
        return value;
      }

    }),

    /**
     * The template used to render the brush.
     *
     * @public
     */
    template: Ember.computed('default', 'scrub', {
      get() {
        let scrub = Ember.get(this, 'scrub');

        if (scrub === 'machine-program') {
          alert('Machine program sidebar is no longer supported, but you have an old url attempting to load it. Please click ok to reload.');
          location.href = `${location.origin}/machines/programs`; // location.href.split('?')[0]; would bring you to /loading with nothing really loading lol..
          // this wouldn't work very easily, since this.transitionTo is custom for the brush, and transitionToRoute is not defined.
          // let noQueryParams = location.pathname.split('/');
          // noQueryParams.shift();
          // this.transitionToRoute(
          //   noQueryParams.join('.'),
          //   { queryParams: {} }
          // );
        }

        return `brushes/${Ember.get(this, 'scrub') || Ember.get(this, 'default')}-brush`;
      }

    }),

    /**
     * The history of the properties of this service.
     *
     * @public
     */
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    history: [{
      scrub: undefined,
      // Create a default brush at the start of the history
      data: undefined,
      state: undefined,
      model: undefined
    }],

    /**
     * The current index of the history that is loaded into the service.
     *
     * @public
     */
    historyIndex: 0,

    /**
     * Returns whether this service has items in its history or not, not counting
     * the default brush at the beginning of the history stack.
     *
     * @public
     */
    hasHistory: Ember.computed.gt('history.length', 1),

    /**
     * Returns whether the `back` method on this service will change the state of
     * this service.
     *
     * @public
     */
    backActive: Ember.computed.gt('historyIndex', 0),

    /**
     * Returns whether the `forward` method on this service will change the state
     * of this service.
     *
     * @public
     */
    forwardActive: Ember.computed('historyIndex', 'history.length', {
      get() {
        return Ember.get(this, 'historyIndex') < Ember.get(this, 'history.length') - 1;
      }

    }),
    // ---------------------------------------------------------------------------
    // PRIVATE PROPERTIES
    // ---------------------------------------------------------------------------

    /**
     * The application controller for the application this service is used in.
     *
     * @private
     */
    _appController: Ember.computed({
      get() {
        return Ember.getOwner(this).lookup('controller:application');
      }

    }),

    /**
     * The route controller for the active route for the current application.
     *
     * @private
     */
    _routeController: Ember.computed({
      get() {
        return Ember.getOwner(this).lookup(`controller:${this._appController.router.currentRouteName}`);
      }

    }),

    /**
     * The raw state of this service.
     *
     * @private
     */
    _state: undefined,

    // ---------------------------------------------------------------------------
    // PUBLIC METHODS
    // ---------------------------------------------------------------------------

    /**
     * Moves the scrub back by one in history.
     *
     * @public
     */
    back() {
      if (Ember.get(this, 'backActive')) {
        this._loadHistory(Ember.get(this, 'historyIndex') - 1);
      }
    },

    /**
     * Moves the scrub forward by one in history.
     *
     * @public
     */
    forward() {
      if (Ember.get(this, 'forwardActive')) {
        this._loadHistory(Ember.get(this, 'historyIndex') + 1);
      }
    },

    /**
     * Moves the scrub to the specified index in its history.
     *
     * @public
     */
    goto(index) {
      if (index < 0 || index >= Ember.get(this, 'history.length')) {
        throw new Error('The index to go to must be valid in the history');
      }

      this._loadHistory(index);
    },

    /**
     * Clears the history.
     *
     * @public
     */
    clear() {
      let oldHistory = Ember.get(this, 'history');
      Ember.set(this, 'history', [oldHistory[0]]);

      this._loadHistory(0);
    },

    /**
     * Transitions the scrub to display the brush defined by `scrub` using the
     * `data`, replacing the current item in the history.
     *
     * @param {string} scrub The scrub to display
     * @param {object} data An Ember Data instance, or an object that can used
     *                      to create one (e.g. containing `id` and `type`
     *                      properties)
     * @param {any} state The state to transition to
     *
     * @public
     */
    async replaceWith(scrub, data, state) {
      let index = Ember.get(this, 'historyIndex');
      let history = Ember.get(this, 'history'); // The first history item should never be replaced

      if (history.length > 1 && index > 0) {
        history = history.slice(0, index);
        Ember.setProperties(this, {
          history,
          historyIndex: history.length - 1
        });
      }

      await this.transitionTo(scrub, data, state);
    },

    /**
     * Transitions the scrub to display the brush defined by `scrub` using the
     * `data`.
     *
     * @param {string} scrub The scrub to display
     * @param {object} data An Ember Data instance, or an object that can used
     *                      to create one (e.g. containing `id` and `type`
     *                      properties)
     * @param {any} state The state to transition to
     *
     * @public
     */
    async transitionTo(scrub, data, state) {
      // Since state is scrub-specific, only reuse the current state if the
      // scrub is the same and no state was passed in
      if (state == null && Ember.get(this, 'scrub') === scrub) {
        state = Ember.get(this, 'state');
      } // Use _state so we don't trigger a query param change until the end


      Ember.setProperties(this, {
        scrub,
        data,
        _state: state
      }); // Wait for any promises to fulfill before updating the URL, since we won't
      // know the `id` or `type` until the promise fulfills

      data = await data; // Update the controller, which updates the URL

      Ember.setProperties(Ember.get(this, '_appController'), Object.assign({
        scrub,
        sstate: state
      }, this._serializeData(data)));
      (0, _jquery.default)('.sidebar-panel').show(0, function () {
        (0, _jquery.default)('body').removeClass('sidebar-hide'); // this callback is required for IE11/IE10

        (0, _jquery.default)('.sidebar-panel').attr('style', ''); // trigger resize event so listeners like highcharts can react

        setTimeout(() => {
          if (typeof Event === 'function') {
            // modern browsers
            window.dispatchEvent(new Event('resize'));
          } else {
            // for IE and other old browsers
            // causes deprecation warning on modern browsers
            let event = window.document.createEvent('UIEvents');
            event.initUIEvent('resize', true, false, window, 0);
            window.dispatchEvent(event);
          }
        }, 100);
      });
    },

    /**
     * Updates this service when query parameters change.
     *
     * @public
     */
    queryParamsDidChange(changed, totalPresent
    /* , removed */
    ) {
      let data = this._deserializeData(totalPresent);

      let oldData = Ember.get(this, 'data');

      if (!data) {
        return;
      } // NOTE: Use the saved data object if the query parameters specify an
      // equivalent object. This allows a model instance with dirty values to be
      // passed in as `data` to `transitionTo` and have the same instance retained
      // when the query parameters sync. [twl 4.Apr.17]


      if (isEquivalentData(data, oldData)) {
        data = oldData;
      } // the ajax call for an employee needs to be user /users/{id}


      if (data.type == 'employee') {
        data.type = 'user';
      }

      Ember.setProperties(this, Object.assign({
        scrub: totalPresent.scrub,
        data,
        _state: this._deserializeState(totalPresent)
      }));

      this._pushHistory();

      return true;
    },

    // ---------------------------------------------------------------------------
    // PRIVATE METHODS
    // ---------------------------------------------------------------------------

    /**
     * Builds the `model` from the `data` for this service.
     *
     * The `data` parameter must currently be either an instance of an Ember Data
     * model, an object with `id` and `type` properties that can be used to load
     * an instance of an Ember Data model, null or undefined.
     *
     * This is an extension point for the future if we want to support additional
     * model data, such as arrays or instances which are not Ember Data models.
     *
     * @private
     */
    _buildModel(data) {
      let model;

      if (data instanceof RecordArray || Array.isArray(data)) {
        throw new Error('The brush service does not support array data yet');
      }

      let definedVia = undefined;

      if (data == null) {
        model = data;
      } else if (data instanceof Model) {
        model = data;
        definedVia = 'data was already an ember data model';
      } else if (data.isFulfilled && data.content instanceof Model) {
        model = data.content;
        definedVia = 'data.content';
      } else if (data.id != null) {
        model = Ember.get(this, '_appController.store').find(data.type, data.id);
        definedVia = 'store.find';
      } else if (data.type != null) {
        model = Ember.get(this, '_appController.store').createRecord(data.type);
        definedVia = 'store.createRecord';
      } else {
        model = undefined;
        let dataStringified = JSON.stringify(data);
        let expectedProps = [['data stringified:', dataStringified, '{}'], ['data.isFulfilled:', data.isFulfilled, false], ['data.isPending:', data.isPending.isDescriptor, true], ['data.content:', data.content, null], ['data instanceof Model:', data instanceof Model, false], ['data.content instanceof Model:', data.content instanceof Model, false], ['data.then && data.then.call:', !!(data.then && data.then.call), true], ['data.isDestroyed:', data.isDestroyed, false], ['data.isDestroying:', data.isDestroying, false], ['data._debugContainerKey:', data._debugContainerKey, undefined], ['data.isRejected:', data.isRejected, false], ['data.isSettled:', data.isSettled.isDescriptor, true], ['data.promise:', data.promise.isDescriptor, true], ['data.reason:', data.reason, null], ['data.isLoading:', data.get('isLoading'), undefined]];
        let anyUnexpected = false;
        let unexpectedIndicies = [];
        expectedProps.forEach((msgArray, index) => {
          if (msgArray[1] !== msgArray[2]) {
            unexpectedIndicies.push(index);
            anyUnexpected = true;
          }
        });

        if (anyUnexpected) {
          console.groupCollapsed('brush can\'t create model but this.data IS defined as something unexpected');
          console.log('For brush.js service to create model, one of the following conditions needs to be true regarding this.data');
          console.log('1. data can instead simply be null or undefined');
          console.log('2. data can be an instance of an Ember Data model (data instanceof Model === true or data.isFulfilled && data.content instanceof Model === true)');
          console.log('3. data can be an object with `id` and `type` properties that can be used to load an instance of an Ember Data model (via store.find(data.type, data.id))');
          console.log('4. data can be an object with just a type property that can be used to create a new record (via store.createRecord(data.type)');
          console.log('data', JSON.stringify(data));
          console.log('data.isFulfilled?', data.isFulfilled);
          console.log('data.content:', data.content);

          if (data.content != null) {
            console.log('data.content instanceof Model?', data.content instanceof Model);
          }

          unexpectedIndicies.forEach(index => {
            let msgArray = expectedProps[index];
            console.log(msgArray[0], msgArray[1], 'but this was expected:', msgArray[2]);
          });
          console.groupEnd();
        } else {
          console.log('brush model: this.data should load soon');
        }
      }

      if (model != null) {// console.log('brush model: defined via', definedVia);
      }

      return model;
    },

    /**
     * Serializes the data into URL parameters.
     *
     * @private
     */
    _serializeData(data) {
      return {
        sid: data != null && data.id != null ? data.id : undefined,
        stype: data != null ? getDataType(data) : undefined
      };
    },

    /**
     * Deserializes the data from URL parameters.
     *
     * @private
     */
    _deserializeData(params) {
      return params.stype == null ? undefined : {
        id: params.sid,
        type: params.stype
      };
    },

    /**
     * Serializes the state into URL parameters.
     *
     * @private
     */
    _serializeState(state) {
      return {
        sstate: state
      };
    },

    /**
     * Deserializes the state from URL parameters.
     *
     * @private
     */
    _deserializeState(params) {
      return params.sstate;
    },

    /**
     * Push a new item onto the history stack for this service.
     *
     * @private
     */
    _pushHistory() {
      let history = Ember.get(this, 'history');
      let index = Ember.get(this, 'historyIndex');

      let currentEntry = _lodash.default.omit(this._peekHistory(index), 'model');

      let newEntry = {
        scrub: Ember.get(this, 'scrub'),
        data: Ember.get(this, 'data'),
        state: Ember.get(this, '_state')
      };

      if (!_lodash.default.isEqual(newEntry, currentEntry)) {
        // Remove all the history past the scrub entry
        history = history.slice(0, index + 1); // Push a new history item; load the model explicitly since the `model`
        // may not be set correct on `this` at this point (and I ran into issues
        // with different model instances being returned when I set it expliclty)

        history.pushObject(Object.assign(newEntry, {
          model: this._buildModel(newEntry.data)
        }));
        Ember.setProperties(this, {
          history,
          historyIndex: history.length - 1
        });
      }
    },

    /**
     * Loads an item from the history stack for this service.
     *
     * @private
     */
    _loadHistory(index) {
      let entry = Ember.get(this, 'history').objectAt(index); // Always calculate the `model`; the instance in the history is not used by
      // the brush, it is only used for others who need a model instance to get
      // properties (like the `scrub-brush-history` component)

      Ember.setProperties(this, {
        scrub: entry.scrub,
        data: entry.data,
        _state: entry.state
      }); // Update the controller, which updates the URL

      Ember.setProperties(Ember.get(this, '_appController'), Object.assign({
        scrub: entry.scrub
      }, this._serializeData(entry.data), this._serializeState(entry.state)));
      Ember.set(this, 'historyIndex', index);
    },

    /**
     * Returns the element at the index in the history, without modifying the
     * history.
     *
     * @private
     */
    _peekHistory(index) {
      let history = Ember.get(this, 'history');
      return index >= 0 ? history.objectAt(index) : undefined;
    }

  });

  _exports.default = _default;
});