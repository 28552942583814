define("alliance-business-suite/components/summary/reports/s-audit-detailed-vend", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "alliance-business-suite/utils/currency-format-util", "alliance-business-suite/utils/report-tables-util"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _currencyFormatUtil, _reportTablesUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  let SummaryReportsSAuditDetailedVendComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, (_class = class SummaryReportsSAuditDetailedVendComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "brand", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor4, this);
    }

    get translatedColumns() {
      return [{
        name: 'location_machine',
        valuePath: 'name',
        width: 380,
        isFixed: 'left'
      }, {
        name: 'date_range',
        valuePath: 'dateRange',
        width: 380
      }, {
        name: 'coin_vended',
        valuePath: 'totalCoinVended',
        width: 180
      }, {
        name: 'pulse_vended',
        valuePath: 'calcVendedPulse',
        width: 180
      }, {
        name: 'card_vended',
        valuePath: 'calcVendedCard',
        width: 180
      }, {
        name: 'mobile_vended',
        valuePath: 'calcVendedMobile',
        width: 180
      }];
    }

    get rowsWithChildren() {
      let {
        model
      } = this.args;
      let geoBoundaries = model.geoBoundaries;
      let rowData = [];
      geoBoundaries.forEach(geoBoundary => {
        let roomArray = [];
        let totalCoinVendedSum = 0;
        let totalPulseVendSum = 0;
        let totalCardVendSum = 0;
        let totalRemoteVendSum = 0;
        let totalCoinVended = [];
        let totalPulseVend = [];
        let totalCardVend = [];
        let totalRemoteVend = [];
        rowData.push({
          name: _reportTablesUtil.localizeString.call(this, geoBoundary.name, 'Location', 'location'),
          children: roomArray,
          totalCoinVended: totalCoinVended,
          calcVendedPulse: totalPulseVend,
          calcVendedCard: totalCardVend,
          calcVendedMobile: totalRemoteVend
        });
        geoBoundary.rooms.forEach(room => {
          let rowArray = [];
          let to1talCoinVendedSum = 0;
          let to1talPulseVendSum = 0;
          let to1talCardVendSum = 0;
          let to1talRemoteVendSum = 0;
          let to1talCoinVended = [];
          let to1talPulseVend = [];
          let to1talCardVend = [];
          let to1talRemoteVend = [];
          roomArray.push({
            name: room.name,
            children: rowArray,
            totalCoinVended: to1talCoinVended,
            calcVendedPulse: to1talPulseVend,
            calcVendedCard: to1talCardVend,
            calcVendedMobile: to1talRemoteVend
          });
          room.machineAuditGroupTypes.forEach(machineAuditType => {
            machineAuditType.machineTypes.forEach(machineType => {
              let machineArray = [];
              let machineTypeName = `${machineAuditType.name} - ${machineType.name}`;
              let to2talCoinVendedSum = 0;
              let to2talPulseVendSum = 0;
              let to2talCardVendSum = 0;
              let to2talRemoteVendSum = 0;
              let to2talCoinVended = [];
              let to2talPulseVend = [];
              let to2talCardVend = [];
              let to2talRemoteVend = [];
              rowArray.push({
                name: machineTypeName,
                machineTypeName: machineType.name,
                isCollapsed: true,
                children: machineArray,
                totalCoinVended: to2talCoinVended,
                calcVendedPulse: to2talPulseVend,
                calcVendedCard: to2talCardVend,
                calcVendedMobile: to2talRemoteVend
              });
              machineType.machines.forEach(machine => {
                let machineCoinVendedArray = [];
                let machinePulseVendArray = [];
                let machineCardVendArray = [];
                let machineRemoteVendArray = [];

                if (this.brand.slug == 'primus') {
                  // fix the branding issue alliance-tasks/issues/2291
                  rowArray[rowArray.length - 1].name = `${machine.machineAuditType.name} - ${rowArray[rowArray.length - 1].machineTypeName}`;
                }

                if (machine.totalCoinVended !== 0) {
                  machineCoinVendedArray.push(machine.totalCoinVended);
                  totalCoinVendedSum = (0, _reportTablesUtil.sumArray)(machineCoinVendedArray, totalCoinVendedSum);
                  to1talCoinVendedSum = (0, _reportTablesUtil.sumArray)(machineCoinVendedArray, to1talCoinVendedSum);
                  to2talCoinVendedSum = (0, _reportTablesUtil.sumArray)(machineCoinVendedArray, to2talCoinVendedSum);
                }

                if (machine.calcVendedPulse !== 0) {
                  machinePulseVendArray.push(machine.calcVendedPulse);
                  totalPulseVendSum = (0, _reportTablesUtil.sumArray)(machinePulseVendArray, totalPulseVendSum);
                  to1talPulseVendSum = (0, _reportTablesUtil.sumArray)(machinePulseVendArray, to1talPulseVendSum);
                  to2talPulseVendSum = (0, _reportTablesUtil.sumArray)(machinePulseVendArray, to2talPulseVendSum);
                }

                if (machine.calcVendedCard !== 0) {
                  machineCardVendArray.push(machine.calcVendedCard);
                  totalCardVendSum = (0, _reportTablesUtil.sumArray)(machineCardVendArray, totalCardVendSum);
                  to1talCardVendSum = (0, _reportTablesUtil.sumArray)(machineCardVendArray, to1talCardVendSum);
                  to2talCardVendSum = (0, _reportTablesUtil.sumArray)(machineCardVendArray, to2talCardVendSum);
                }

                if (machine.calcVendedMobile !== 0) {
                  machineRemoteVendArray.push(machine.calcVendedMobile);
                  totalRemoteVendSum = (0, _reportTablesUtil.sumArray)(machineRemoteVendArray, totalRemoteVendSum);
                  to1talRemoteVendSum = (0, _reportTablesUtil.sumArray)(machineRemoteVendArray, to1talRemoteVendSum);
                  to2talRemoteVendSum = (0, _reportTablesUtil.sumArray)(machineRemoteVendArray, to2talRemoteVendSum);
                }

                let machineName = _reportTablesUtil.machineNameDisplay.call(this, machine.machineName, machine.machineNumber);

                let coinVended = _currencyFormatUtil.formatCurrencyString.call(this, machine.totalCoinVended || 0);

                let pulseVend = _currencyFormatUtil.formatCurrencyString.call(this, machine.calcVendedPulse || 0);

                let cardVend = _currencyFormatUtil.formatCurrencyString.call(this, machine.calcVendedCard || 0);

                let remoteVend = _currencyFormatUtil.formatCurrencyString.call(this, machine.calcVendedMobile || 0);

                let dateRange = "";

                if (machine.minFirstReceivedAt && machine.maxFirstReceivedAt) {
                  dateRange = `${moment(machine.minFirstReceivedAt).format(_reportTablesUtil.formatDateTimeString.call(this))} - ${moment(machine.maxFirstReceivedAt).format(_reportTablesUtil.formatDateTimeString.call(this))}`;
                }

                machineArray.push({
                  name: machineName,
                  dateRange: dateRange,
                  totalCoinVended: coinVended,
                  calcVendedPulse: pulseVend,
                  calcVendedCard: cardVend,
                  calcVendedMobile: remoteVend
                });
              });
              to2talCoinVended.push(_currencyFormatUtil.formatCurrencyString.call(this, to2talCoinVendedSum || 0));
              to2talPulseVend.push(_currencyFormatUtil.formatCurrencyString.call(this, to2talPulseVendSum || 0));
              to2talCardVend.push(_currencyFormatUtil.formatCurrencyString.call(this, to2talCardVendSum || 0));
              to2talRemoteVend.push(_currencyFormatUtil.formatCurrencyString.call(this, to2talRemoteVendSum || 0));
            });
          });
          to1talCoinVended.push(_currencyFormatUtil.formatCurrencyString.call(this, to1talCoinVendedSum || 0));
          to1talPulseVend.push(_currencyFormatUtil.formatCurrencyString.call(this, to1talPulseVendSum || 0));
          to1talCardVend.push(_currencyFormatUtil.formatCurrencyString.call(this, to1talCardVendSum || 0));
          to1talRemoteVend.push(_currencyFormatUtil.formatCurrencyString.call(this, to1talRemoteVendSum || 0));
        });
        totalCoinVended.push(_currencyFormatUtil.formatCurrencyString.call(this, totalCoinVendedSum || 0));
        totalPulseVend.push(_currencyFormatUtil.formatCurrencyString.call(this, totalPulseVendSum || 0));
        totalCardVend.push(_currencyFormatUtil.formatCurrencyString.call(this, totalCardVendSum || 0));
        totalRemoteVend.push(_currencyFormatUtil.formatCurrencyString.call(this, totalRemoteVendSum || 0));
      });
      return rowData;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "brand", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SummaryReportsSAuditDetailedVendComponent;
});