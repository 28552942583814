define("alliance-business-suite/models/employee-sales-goal", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-data/model"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;

  let EmployeeSalesGoalModel = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('boolean'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('number'), _dec7 = (0, _model.attr)('number'), _dec8 = (0, _model.attr)('number'), (_class = class EmployeeSalesGoalModel extends _model.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "firstName", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "lastName", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "email", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "securityRoleIsSystemDefault", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "securityRoleName", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "totalSales", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "roleGoal", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "individualGoal", _descriptor8, this);
    }

    // null is no employee value set...use role goal
    // 0 means the goal is zero
    // null means we take the role goal instead of an employee goal
    // patch "-1" for individualAmount means delete this employee-specific goal.
    // need to return the full record to let ember update the store
    get rolePercent() {
      let sales = this.totalSales || 0;
      let goal = this.roleGoal || 0;

      if (goal == 0) {
        return 0;
      } else {
        return sales / goal * 100;
      }
    }

    get individualPercent() {
      let sales = this.totalSales || 0;
      let goal = this.individualGoal || 0;

      if (goal == 0) {
        return 0;
      } else {
        return sales / goal * 100;
      }
    }

    get fullName() {
      return `${this.firstName} ${this.lastName}`;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "firstName", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "lastName", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "email", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "securityRoleIsSystemDefault", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "securityRoleName", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "totalSales", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "roleGoal", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "individualGoal", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = EmployeeSalesGoalModel;
});