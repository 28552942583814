define("alliance-business-suite/routes/employees/schedules", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "alliance-business-suite/routes/_secureRoute"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _secureRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  let EmployeesSchedulesRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, (_class = class EmployeesSchedulesRoute extends _secureRoute.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "brush", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "canAccess", _descriptor3, this);
      (0, _defineProperty2.default)(this, "allowAccess", 'EMPLOYEES_SCHEDULE_VIEW_PAGE');
      (0, _defineProperty2.default)(this, "disableBrush", true);
    }

    model() {
      return Ember.RSVP.hash({
        rooms: this.store.peekAll('room')
      });
    } // does not work, should be moved maybe to controller or component
    // @action
    // willTransition(transition) {
    //   if (get(this, 'can-access').canAccess('EMPLOYEES_EDIT_SCHEDULE')) {
    //
    //     // TODO: We are storing things in the controller (like results) that need to be dealt with.
    //     let abandonMessage = this.intl.t('unsaved_changes_discard');
    //
    //     if (this.controller.get('hasDirtyItems') && !window.confirm(abandonMessage)) {
    //       transition.abort();
    //     }
    //   }
    //
    //   return true;
    // }


  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "brush", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "canAccess", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = EmployeesSchedulesRoute;
});