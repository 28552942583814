define("alliance-business-suite/models/machine-audit-type", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    brand: Ember.inject.service(),
    name: attr('string'),
    typeName: attr('string'),
    isOpl: Ember.computed('id', function () {
      return [// List all OPL machine audit types below so they don't appear in vended accounts
      'MIDAS_EZOPL_M1', 'MIDAS_EZOPL_M2', 'MIDAS_EZOPL_M3', 'MIDAS_EZOPL_M4', 'MIDAS_EZOPL_M5', 'PRIMUS_EZOPL_MC10', 'MIDAS_OPL_1', 'MIDAS_OPL_2', 'MIDAS_OPL_5', 'MIDAS_OPL_3', 'MIDAS_OPL_4', 'MC10_OPL_1', 'MC10_OPL_2', 'MC10_OPL_3', 'MC10_OPL_4', 'MC10_OPL_5', 'MC10_EZOPL_M1', 'MC10_EZOPL_M2', 'MC10_EZOPL_M3', 'MC10_EZOPL_M4', 'MC10_EZOPL_M5', 'MGD_M7', 'MC5_FC_Primus', 'MC5_FC_IPSO', 'MC5_FC_SQ', 'MC5_FC_UniMac', 'MC5_FC_Generic'].includes(this.id.toUpperCase());
    }),
    isTraceable: Ember.computed('id', function () {
      return ['MC10_OPL_1', 'MC10_OPL_2', 'MC10_OPL_3', 'MC10_OPL_4', 'MC10_OPL_5'].includes(this.id.toUpperCase());
    }),
    isVended: Ember.computed.not('isOpl'),
    machineBrand: Ember.computed('id', 'brand.abbrev', function () {
      /**
       * User have been restricted to machines within their own brand, but Texprofin is a
       * Huebsch customer that requires access to Speed Queen machine programming.
       *
       * This property is used for dynamic localization of machine control names (which are
       * based on brand + audit type) and will be used for matchine `machineAttribute` values
       * in show-machine.
       */
      let speedQueenAuditTypes = ["ACAS_A4", "ACAS_A3", "ACAS_A44"];

      if (this.brand.abbrev.toUpperCase() === "HB" && speedQueenAuditTypes.includes(this.id)) {
        console.log('*** Override machine programming brand for', this.id);
        return {
          abbrev: "SQ",
          name: "SPEED_QUEEN"
        };
      } else {
        return {
          abbrev: this.brand.abbrev,
          name: this.brand.name
        };
      }
    }),
    generation: Ember.computed('id', function () {
      // ACAS, MIDAS, etc.
      // Several audit types all belong to the same "family", but are distinct because of different brands.
      // This CP will group them into a new category so we don't have to check against each brand with logical operators.
      switch (this.id) {
        case "CENTURION_C4_ALS_DRIVE":
        case "CENTURION_C4":
        case "CENTURION_C3":
        case "CENTURION_C3_ALS_DRIVE":
          return "CENTURION";

        case "ACAS_A2":
        case "ACAS_A3":
        case "ACAS_A4":
        case "ACAS_A11":
        case "ACAS_A22":
        case "ACAS_A1":
        case "ACAS_A44":
          return "ACAS";

        case "ACA_OPL_A9":
        case "ACA_OPL_A30":
        case "ACA_OPL_A14":
        case "ACA_OPL_A13":
          return "ACA_OPL";

        case "MGD_M1":
          return "MGD";

        case "MGD_M7":
          return "MGD_OPL";

        case "MC10_M2":
        case "MC10_M3":
        case "MC10_M4":
        case "MC10_M5":
          return "MC10";

        case "MC10_OPL_1":
        case "MC10_OPL_2":
        case "MC10_OPL_3":
        case "MC10_OPL_4":
        case "MC10_OPL_5":
          return "MC10_OPL";

        case "MC10_EZOPL_M2":
        case "MC10_EZOPL_M3":
        case "MC10_EZOPL_M4":
        case "MC10_EZOPL_M5":
          return "MC10_EZOPL";

        case "PRIMUS_MC10":
          return "PRIMUS";

        case "PRIMUS_EZOPL_MC10":
          return "PRIMUS_OPL";

        case "MIDAS_VEND_M1":
        case "MIDAS_VEND_M2":
        case "MIDAS_VEND_M3":
        case "MIDAS_VEND_M4":
        case "MIDAS_VEND_M5":
          return "MIDAS";

        case "MIDAS_EZOPL_M1":
        case "MIDAS_EZOPL_M2":
        case "MIDAS_EZOPL_M3":
        case "MIDAS_EZOPL_M4":
        case "MIDAS_EZOPL_M5":
          return "MIDAS_EZOPL";

        case "MIDAS_OPL_2":
        case "MIDAS_OPL_1":
        case "MIDAS_OPL_3":
        case "MIDAS_OPL_4":
        case "MIDAS_OPL_5":
          return "MIDAS_OPL";

        case "TITAN_T1":
        case "TITAN_T2":
        case "TITAN_T3":
        case "TITAN_T4":
        case "TITAN_T11":
        case "TITAN_T20":
        case "TITAN_T21":
        case "TITAN_T22":
        case "TITAN_T31":
        case "TITAN_T44":
          return "TITAN";

        case "TITAN_OPL_T9":
        case "TITAN_OPL_T13":
        case "TITAN_OPL_T14":
        case "TITAN_OPL_T30":
          return "TITAN_OPL";

        default:
          return "GENERIC";
      }
    })
  });

  _exports.default = _default;
});