define("alliance-business-suite/templates/components/machines/programs/banners/midas-opl-tum", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4BTNetYg",
    "block": "{\"symbols\":[\"ui\"],\"statements\":[[8,\"form-ui\",[],[[\"@formDisplay\",\"@object\",\"@machineProgram\",\"@selectOptions\"],[\"compact\",[32,0,[\"machineProgram\",\"settings\"]],[32,0,[\"machineProgram\"]],[32,0,[\"selectOptions\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[8,[32,1,[\"columns\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n\"],[2,\"    \"],[8,[32,1,[\"column\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n      \"],[8,[32,1,[\"section\"]],[],[[\"@title\"],[\"banners\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n          \"],[8,[32,1,[\"text\"]],[],[[\"@params\"],[[30,[36,0],null,[[\"propertyName\",\"label\"],[\"Program.Display.Banners.Banner1\",null]]]]],null],[2,\"\\n          \"],[8,[32,1,[\"text\"]],[],[[\"@params\"],[[30,[36,0],null,[[\"propertyName\",\"label\"],[\"Program.Display.Banners.Banner2\",null]]]]],null],[2,\"\\n          \"],[8,[32,1,[\"text\"]],[],[[\"@params\"],[[30,[36,0],null,[[\"propertyName\",\"label\"],[\"Program.Display.Banners.Banner3\",null]]]]],null],[2,\"\\n\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"hash\"]}",
    "moduleName": "alliance-business-suite/templates/components/machines/programs/banners/midas-opl-tum.hbs"
  });

  _exports.default = _default;
});