define("alliance-business-suite/components/form-ui/led", ["exports", "alliance-business-suite/mixins/form-ui"], function (_exports, _formUi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_formUi.default, {
    // eslint-disable-line
    tagName: 'div',
    classNames: ['form-field', 'overflow-float'],
    value: Ember.computed('isDecimalArray', 'params.propertyName', 'rawValue', {
      // The value can be a string or an array of integers stored as 6 different values, so we need to update each one
      get(key) {
        if (!this.isDecimalArray) {
          return this.rawValue;
        }

        let dec = [];
        [1, 2, 3, 4, 5, 6].forEach(index => {
          dec.push(Ember.get(this, `object.${this.params.propertyName}${index}`) || 0);
        });
        return dec;
      },

      set(key, value) {
        if (this.params.isAscii) {
          if (this.params.length !== undefined) {
            value = value.substring(0, this.params.length);
          }

          Ember.set(this, `object.${this.params.propertyName}`, value);
        }

        if (!this.isDecimalArray) {
          Ember.set(this, `object.${this.params.propertyName}`, value);
        }

        [1, 2, 3, 4, 5, 6].forEach((item, index) => {
          // Make sure this property exists before trying to update
          if (Ember.get(this.object, `${this.params.propertyName}${item}`) !== undefined) {
            Ember.set(this, `object.${this.params.propertyName}${item}`, value[index]);
          }
        });
        return value;
      }

    }),
    isDecimalArray: Ember.computed('rawValue', function () {
      return this.rawValue === undefined ? true : false;
    }),
    // Override property check because this component updates 6 properties. `propertyName` contains the fragment to use, NOT the full property name.
    isUndefinedProperty: false,
    isValidProperty: true
  });

  _exports.default = _default;
});