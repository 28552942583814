define("alliance-business-suite/templates/components/ui/context-menu-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KOW96zJV",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[2,\"\\n\"],[10,\"div\"],[14,0,\"ui-dropdown ui-hover-only\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"dropdown-container\"],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-ellipsis-h btn-more-actions dropdown-trigger\"],[12],[13],[2,\"\\n\\n\"],[6,[37,7],[[35,6]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"ember-wormhole\",[],[[\"@to\"],[\"context-menu-wormhole\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n        \"],[10,\"ul\"],[14,0,\"dropdown-menu dropdown-menu-left _with-arrow _dropdown-select show-menu\"],[12],[2,\"\\n          \"],[18,1,[[30,[36,5],null,[[\"separator\",\"item\"],[[30,[36,4],[\"ui/context-menu-separator\"],[[\"label\"],[[35,3]]]],[30,[36,4],[\"ui/context-menu-item\"],[[\"label\",\"action\",\"disabled\",\"checked\"],[[35,3],[35,2],[35,1],[35,0]]]]]]]]],[2,\"\\n        \"],[13],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"style\"],[12],[2,\"\\n  #context-menu-wormhole {\\n    position: absolute;\\n    z-index:55555;\\n    border:1px solid red;\\n  }\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"checked\",\"disabled\",\"action\",\"label\",\"component\",\"hash\",\"showMenu\",\"if\"]}",
    "moduleName": "alliance-business-suite/templates/components/ui/context-menu-2.hbs"
  });

  _exports.default = _default;
});