define("alliance-business-suite/components/machines/programs/cycles/steps-acas-wx-flw", ["exports", "alliance-business-suite/mixins/machines/programs/cycles/step-global", "alliance-business-suite/mixins/machines/programs/acas-options"], function (_exports, _stepGlobal, _acasOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_stepGlobal.default, _acasOptions.default, {
    machineAuditType: Ember.computed.alias("machineProgram._machineAuditType"),
    // Alias properties for cooldownWaterValvesTemperature CP
    HotFillValveOn: Ember.computed.alias('selectedStep.HotFillValveOn'),
    ColdFillValveOn: Ember.computed.alias('selectedStep.ColdFillValveOn'),
    // Alias properties for waterValvesTempeature CP
    HotFillOn: Ember.computed.alias('selectedStep.HotFillOn'),
    ColdFillOn: Ember.computed.alias('selectedStep.ColdFillOn'),
    TemperatureControlledFill: Ember.computed.alias('selectedStep.TemperatureControlledFill'),
    showCycleWater: false,
    // TODO: Check this for FLW
    settings: Ember.computed(function () {
      return {
        machineProgram: Ember.get(this, "machineProgram"),
        selectOptions: this.selectOptions,
        machineAuditType: this.machineAuditType,
        machineSettings: Ember.get(this, "machineProgram._machineSettings"),
        showG: Ember.get(this, "machineProgram._showG"),
        isCelsius: Ember.get(this, "machineProgram._isCelsius")
      };
    }),
    stepControls: Ember.computed('selectedStep.Type', 'selectedStep', function () {
      let stepId = Ember.get(this, 'selectedStep.Type');
      let allStepSettings = Ember.get(this, 'allStepSettings');
      let thisStepSettings = allStepSettings.findBy("id", stepId); // return thisStep.controls;

      return thisStepSettings;
    }),
    // stepChange: observer('selectedStep', function() {
    //   // Scroll to top when step changes
    //   // TODO: Make then event-based so it's not fragile.
    //   document.getElementById('split3').scrollTop = 0;
    // }),
    allStepSettings: [{
      id: 1,
      stepName: "ReuseFill",
      controls: ["active-label", "step-time", "flush-out", "water-level-overflow", "water-valves", "heater-options-temperature", "speed"],
      validations: {
        stepMinutes: [0, 255],
        stepSeconds: [0, 59],
        heaterOptionsTemperature: [35, 194]
      },
      properties: {
        Type: "Type",
        Label: "Label",
        Speed: ["Speed", "CustomSpeed"],
        CustomSpeed: "CustomSpeed",
        HotFill: ["HotFillOn", "HotFillEnabled"],
        ColdFill: ["ColdFillOn", "ColdFillEnabled"],
        FillValveSet: "FillValveSetOn",
        FillTubValveSet: "FillTubValveSetOn",
        ColdHardFillInlet: "ColdHardFillOn",
        AuxFill: "AuxiliaryFill",
        SprayValveSet: "SprayValveSetOn",
        Minutes: "Minutes",
        Seconds: "Seconds",
        PauseSec: "PauseTime",
        RotateSec: "RotateTime",
        AuxOutputsI: "AuxOutputs.I",
        AuxOutputsJ: "AuxOutputs.J",
        AuxOutputsK: "AuxOutputs.K",
        AuxOutputsL: "AuxOutputs.L",
        AuxOutputsM: "AuxOutputs.M",
        AuxOutputsN: "AuxOutputs.N",
        WaterLevel: ["WaterLevel", "CustomWaterLevel"],
        CustomWaterLevel: "CustomWaterLevel",
        ActiveState: "ActiveState",
        Temperature: ["Temperature", "Temp"],
        CustomTemperature: "CustomTemperature",
        FlushoutEnabled: ["Flushout", "FlushOut"],
        ReversingDisabled: ["NoReversing", "NonReversing"],
        TempControlledFill: "TemperatureControlledFill",
        AlwaysRotate: "RotateEntireStepEnabled"
      }
    }, {
      id: 2,
      stepName: "Fill",
      controls: ["active-label", "step-time", "flush-out", "water-level", "water-valves", "heater-options-temperature", "speed"],
      validations: {
        stepHours: [0, 255],
        stepMinutes: [0, 255],
        stepSeconds: [0, 59],
        waterLevel: [1, 30]
      },
      properties: {
        HeaterOptionsTemperatureEnabled: "",
        Type: "Type",
        Label: "Label",
        Speed: ["Speed", "CustomSpeed"],
        CustomSpeed: "CustomSpeed",
        HotFill: ["HotFillOn", "HotFillEnabled"],
        ColdFill: ["ColdFillOn", "ColdFillEnabled"],
        // water-valves
        FillValveSet: "FillValveSet",
        FillTubValveSet: "FillTubValveSetOn",
        ColdHardFillInlet: "ColdHardFillOn",
        AuxFill: "AuxFillOn",
        SprayValveSet: "SprayValveSetOn",
        TempControlledFill: "TemperatureControlledFill",
        Minutes: "Minutes",
        Seconds: "Seconds",
        PauseSec: "PauseTime",
        RotateSec: "RotateTime",
        WaterLevel: ["WaterLevel", "CustomWaterLevel"],
        CustomWaterLevel: "CustomWaterLevel",
        ActiveState: "ActiveState",
        Temperature: ["Temperature", "Temp"],
        CustomTemperature: "CustomTemperature",
        FlushoutEnabled: ["Flushout", "FlushOut"],
        ReversingDisabled: ["NoReversing", "NonReversing"],
        AlwaysRotate: "RotateEntireStepEnabled"
      }
    }, {
      id: 3,
      stepName: "Supply",
      controls: ["active-label", "step-time", "water-valves", "internal-supply", "external-supply"],
      validations: {
        stepMinutes: [0, 255],
        stepSeconds: [0, 59],
        delayTime: [0, 255]
      },
      properties: {
        Type: "Type",
        Label: "Label",
        Speed: "Speed",
        CustomSpeed: "CustomSpeed",
        // water-valves
        HotFill: ["HotFillOn", "HotFillEnabled"],
        ColdFill: ["ColdFillOn", "ColdFillEnabled"],
        FillValveSet: "FillValveSetOn",
        FillTubValveSet: "FillTubValveSetOn",
        ColdHardFillInlet: "ColdHardFillOn",
        AuxFill: "AuxFillOn",
        DelayTime: "SupplyDelaySeconds",
        SprayValveSet: "SprayValveSet",
        Minutes: "Minutes",
        Seconds: "Seconds",
        WaterLevel: "WaterLevel",
        CustomWaterLevel: "CustomWaterLevel",
        ActiveState: "ActiveState",
        Temperature: "Temperature",
        CustomTemperature: "CustomTemperature",
        FlushoutEnabled: ["Flushout", "FlushOut"],
        ExtSupply1: ["JrAuxOutput1", "JrAuxOutput1Enabled"],
        ExtSupply2: ["JrAuxOutput2", "JrAuxOutput2Enabled"],
        ExtSupply3: ["JrAuxOutput3", "JrAuxOutput3Enabled"],
        ExtSupply4: ["JrAuxOutput4", "JrAuxOutput4Enabled"],
        SupplyDelay: "SupplyDelaySeconds",
        Compartment1: ["Compartment1On", "Compartment1Enabled"],
        Compartment2: ["Compartment2On", "Compartment2Enabled"],
        Compartment3: ["Compartment3On", "Compartment3Enabled"],
        Compartment4: ["Compartment4On", "Compartment4Enabled"],
        ShutoffCondition: "ShutoffCondition"
      }
    }, {
      id: 4,
      stepName: "Agitate",
      controls: ["active-label", "step-time", "heater-options-rate", "speed", "drain-refill", "aux-supply"],
      validations: {
        stepMinutes: [0, 255],
        stepSeconds: [0, 59]
      },
      properties: {
        Type: "Type",
        Label: "Label",
        Speed: "Speed",
        CustomSpeed: "CustomSpeed",
        Minutes: "Minutes",
        Seconds: "Seconds",
        HeatRate: "HeatRate",
        HeatOption: "HeatOption",
        PauseSec: "PauseTime",
        RotateSec: "RotateTime",
        ActiveState: "ActiveState",
        AuxSupplyA: ["JrAuxOutput1On", "JrAuxOutput1Enabled"],
        AuxSupplyB: ["JrAuxOutput2On", "JrAuxOutput2Enabled"],
        AuxSupplyC: ["JrAuxOutput3On", "JrAuxOutput3Enabled"],
        AuxSupplyD: ["JrAuxOutput4On", "JrAuxOutput4Enabled"],
        Refill: ["RefillOn", "RefillEnabled"],
        MainDrain1: ["MainDrain1On", "MainDrain1"],
        MainDrain2: ["MainDrain2On", "MainDrain2"],
        PulseDrain: "PulseDrainOn",
        ReversingDisabled: ["NoReversing", "NonReversing"]
      }
    }, {
      id: 5,
      stepName: "Soak",
      controls: ["active-label", "step-time", "heater-options-rate", "speed", "drain-refill", "aux-supply"],
      validations: {
        stepHours: [0, 255],
        stepMinutes: [0, 59]
      },
      properties: {
        Type: "Type",
        Label: "Label",
        Speed: "Speed",
        CustomSpeed: "CustomSpeed",
        // water-valves
        HotFill: ["HotFillOn", "HotFillEnabled"],
        ColdFill: ["ColdFillOn", "ColdFillEnabled"],
        FillValveSet: "FillValveSetOn",
        FillTubValveSet: "FillTubValveSetOn",
        ColdHardFillInlet: "ColdHardFillOn",
        AuxFill: "AuxFillOn",
        DelayTime: "SupplyDelaySeconds",
        SprayValveSet: "SprayValveSet",
        Minutes: "Minutes",
        Hours: "Hours",
        HeatRate: "HeatRate",
        HeatOption: "HeatOption",
        PauseSec: "PauseTime",
        RotateSec: "RotateTime",
        WaterLevel: "WaterLevel",
        CustomWaterLevel: "CustomWaterLevel",
        ActiveState: "ActiveState",
        Temperature: "Temperature",
        CustomTemperature: "CustomTemperature",
        FlushoutEnabled: ["Flushout", "FlushOut"],
        AuxSupplyA: ["JrAuxOutput1On", "JrAuxOutput1Enabled"],
        AuxSupplyB: ["JrAuxOutput2On", "JrAuxOutput2Enabled"],
        AuxSupplyC: ["JrAuxOutput3On", "JrAuxOutput3Enabled"],
        AuxSupplyD: ["JrAuxOutput4On", "JrAuxOutput4Enabled"],
        SupplyDelay: "SupplyDelaySeconds",
        Compartment1: ["Compartment1On", "Compartment1Enabled"],
        Compartment2: ["Compartment2On", "Compartment2Enabled"],
        Compartment3: ["Compartment3On", "Compartment3Enabled"],
        Compartment4: ["Compartment4On", "Compartment4Enabled"],
        ShutoffCondition: "ShutoffCondition",
        ReuseFillA: "ReuseFillA",
        ReuseFillB: "ReuseFillB",
        Refill: ["RefillOn", "MainDrain1"],
        MainDrain1: ["MainDrain1On", "MainDrain1"],
        MainDrain2: ["MainDrain2On", "MainDrain2"],
        PulseDrain: "PulseDrainOn",
        ExternalDrain: "ExternalDrain",
        ReversingDisabled: ["NoReversing", "NonReversing"]
      }
    }, {
      id: 6,
      stepName: "Cooldown",
      controls: ["active-label", "cooldown-options", "water-valves", "speed", "aux-supply"],
      validations: {},
      properties: {
        Type: "Type",
        Label: "Label",
        Speed: ["Speed", "CustomSpeed"],
        CustomSpeed: "CustomSpeed",
        // cool-down
        CoolRate: ["CooldownRate", "CoolDownRate"],
        CoolDownType: "CoolDownType",
        // water-valves
        HotFill: ["HotFillValveOn", "HotFillEnabled"],
        ColdFill: ["ColdFillValveOn", "ColdFillEnabled"],
        FillValveSet: "FillValveSetOn",
        FillTubValveSet: ["FillTubValveSet", "FillTubValveEnabled"],
        ColdHardFillInlet: ["ColdHardFill", "ColdHardFillEnabled"],
        AuxFill: "AuxFillOn",
        DelayTime: "SupplyDelaySeconds",
        SprayValveSet: ["SprayValveSet", "SprayValveEnabled"],
        Minutes: "Minutes",
        Seconds: "Seconds",
        HeatRate: "HeatRate",
        HeatOption: "HeatOption",
        PauseSec: "PauseTime",
        RotateSec: "RotateTime",
        WaterLevel: "WaterLevel",
        CustomWaterLevel: "CustomWaterLevel",
        ActiveState: "ActiveState",
        Temperature: ["Temperature", "Temp"],
        CustomTemperature: "CustomTemperature",
        FlushoutEnabled: ["Flushout", "FlushOut"],
        AuxSupplyA: ["JrAuxOutput1On", "JrAuxOutput1Enabled"],
        AuxSupplyB: ["JrAuxOutput2On", "JrAuxOutput2Enabled"],
        AuxSupplyC: ["JrAuxOutput3On", "JrAuxOutput3Enabled"],
        AuxSupplyD: ["JrAuxOutput4On", "JrAuxOutput4Enabled"],
        SupplyDelay: "SupplyDelaySeconds",
        Compartment1: ["Compartment1On", "Compartment1Enabled"],
        Compartment2: ["Compartment2On", "Compartment2Enabled"],
        Compartment3: ["Compartment3On", "Compartment3Enabled"],
        Compartment4: ["Compartment4On", "Compartment4Enabled"],
        ShutoffCondition: "ShutoffCondition",
        ReuseFillA: "ReuseFillA",
        ReuseFillB: "ReuseFillB",
        Refill: "RefillOn",
        MainDrain1: ["MainDrain1On", "MainDrain1"],
        MainDrain2: ["MainDrain2On", "MainDrain2"],
        PulseDrain: "PulseDrainOn",
        ExternalDrain: "ExternalDrain",
        ReversingDisabled: ["NoReversing", "NonReversing"]
      }
    }, {
      id: 7,
      stepName: "Drain",
      controls: ["active-label", "speed", "drain", "aux-supply"],
      validations: {
        rotateTime: [3, 255],
        pauseTime: [3, 255],
        requireDrain: true
      },
      properties: {
        Type: "Type",
        Label: "Label",
        Speed: "Speed",
        CustomSpeed: "CustomSpeed",
        PauseSec: "PauseTime",
        RotateSec: "RotateTime",
        WaterLevel: "WaterLevel",
        CustomWaterLevel: "CustomWaterLevel",
        ActiveState: "ActiveState",
        AuxSupplyA: ["JrAuxOutput1On", "JrAuxOutput1Enabled"],
        AuxSupplyB: ["JrAuxOutput2On", "JrAuxOutput2Enabled"],
        AuxSupplyC: ["JrAuxOutput3On", "JrAuxOutput3Enabled"],
        AuxSupplyD: ["JrAuxOutput4On", "JrAuxOutput4Enabled"],
        MainDrain1: "MainDrain1On",
        MainDrain2: "MainDrain2On",
        PulseDrain: "PulseDrainEnabled",
        ReversingDisabled: ["NoReversing", "NoReversingEnabled"],
        AlwaysRotate: "RotateEntireStepEnabled"
      }
    }, {
      id: 9,
      stepName: "DrainExtract",
      controls: ["active-label", "step-time", "speed", "drain", "aux-supply"],
      validations: {
        stepMinutes: [0, 9],
        stepSeconds: [0, 59],
        requireDrain: true
      },
      properties: {
        Type: "Type",
        Label: "Label",
        Speed: ["Speed", "CustomSpeed"],
        Minutes: "Minutes",
        Seconds: "Seconds",
        CustomSpeed: "CustomSpeed",
        PauseSec: "PauseTime",
        RotateSec: "RotateTime",
        WaterLevel: ["WaterLevel", "CustomWaterLevel"],
        CustomWaterLevel: "CustomWaterLevel",
        ActiveState: "ActiveState",
        AuxSupplyA: ["JrAuxOutput1On", "JrAuxOutput1Enabled"],
        AuxSupplyB: ["JrAuxOutput2On", "JrAuxOutput2Enabled"],
        AuxSupplyC: ["JrAuxOutput3On", "JrAuxOutput3Enabled"],
        AuxSupplyD: ["JrAuxOutput4On", "JrAuxOutput4Enabled"],
        MainDrain1: "MainDrain1On",
        MainDrain2: "MainDrain2On",
        PulseDrain: "PulseDrainEnabled",
        ReversingDisabled: "NoReversing",
        AlwaysRotate: "RotateEntireStepEnabled"
      }
    }, {
      id: 10,
      stepName: "AudioSignal",
      controls: ["active-label", "audio-signal", "step-time", "aux-supply"],
      validations: {
        stepMinutes: [0, 2],
        stepSeconds: [0, 59]
      },
      properties: {
        Type: "Type",
        Label: "Label",
        Minutes: "Minutes",
        Pattern: ["AudioPattern", "AudioPatternType"],
        Seconds: "Seconds",
        AuxSupplyA: ["JrAuxOutput1On", "JrAuxOutput1Enabled"],
        AuxSupplyB: ["JrAuxOutput2On", "JrAuxOutput2Enabled"],
        AuxSupplyC: ["JrAuxOutput3On", "JrAuxOutput3Enabled"],
        AuxSupplyD: ["JrAuxOutput4On", "JrAuxOutput4Enabled"],
        ActiveState: "ActiveState"
      }
    }, {
      id: 11,
      stepName: "Hold",
      controls: ["active-label", "audio-signal", "step-time", "drain-refill", "aux-supply", "door"],
      validations: {
        stepMinutes: [0, 2],
        stepSeconds: [0, 59]
      },
      properties: {
        Type: "Type",
        Label: "Label",
        Message: "GlobalMessage",
        Minutes: ["Minutes", "AudioMinutes"],
        Seconds: ["Seconds", "AudioSeconds"],
        Pattern: ["AudioPattern", "AudioPatternType"],
        AudioMinutes: "AudioMinutes",
        AudioSeconds: "AudioSeconds",
        AuxSupplyA: ["JrAuxOutput1On", "JrAuxOutput1Enabled"],
        AuxSupplyB: ["JrAuxOutput2On", "JrAuxOutput2Enabled"],
        AuxSupplyC: ["JrAuxOutput3On", "JrAuxOutput3Enabled"],
        AuxSupplyD: ["JrAuxOutput4On", "JrAuxOutput4Enabled"],
        UnlockDoor: "DrainAndUnlock",
        ActiveState: "ActiveState",
        Refill: "DrainOptions.Refill",
        MainDrain1: "MainDrain1",
        MainDrain2: "MainDrain2",
        PulseDrain: "PulseDrainEnabled",
        ExternalDrain: "ExternalDrain",
        AutoResumeMinutes: "AutoResumeMinutes"
      }
    }]
  });

  _exports.default = _default;
});