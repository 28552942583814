define("alliance-business-suite/templates/components/rooms/room-tab-social-media", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FPHoijxC",
    "block": "{\"symbols\":[],\"statements\":[[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"select-field form-field\"],[12],[2,\"\\n    \"],[10,\"label\"],[14,0,\"form-field--label\"],[12],[2,\"Facebook\"],[13],[2,\"\\n    \"],[8,\"input\",[],[[\"@value\"],[[34,0,[\"socialMediaFacebook\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"select-field form-field\"],[12],[2,\"\\n    \"],[10,\"label\"],[14,0,\"form-field--label\"],[12],[2,\"Instagram\"],[13],[2,\"\\n    \"],[8,\"input\",[],[[\"@value\"],[[34,0,[\"socialMediaInstagram\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"select-field form-field\"],[12],[2,\"\\n    \"],[10,\"label\"],[14,0,\"form-field--label\"],[12],[2,\"Yelp\"],[13],[2,\"\\n    \"],[8,\"input\",[],[[\"@value\"],[[34,0,[\"socialMediaYelp\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"select-field form-field\"],[12],[2,\"\\n    \"],[10,\"label\"],[14,0,\"form-field--label\"],[12],[2,\"Google\"],[13],[2,\"\\n    \"],[8,\"input\",[],[[\"@value\"],[[34,0,[\"socialMediaGoogle\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "moduleName": "alliance-business-suite/templates/components/rooms/room-tab-social-media.hbs"
  });

  _exports.default = _default;
});