define("alliance-business-suite/components/summary-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    access: Ember.inject.service(),
    brush: Ember.inject.service(),

    didInsertElement() {
      this._super(...arguments); // fix for blank chart on login


      if (this.organization && this.machines) {
        this.buildCharts();
      }
    },

    didUpdateAttrs() {
      this._super(...arguments); // this.buildCharts();

    },

    buildCharts() {
      this.buildLocationRevenueChart();
      this.buildWasherRevenueChart(this);
      this.buildDryerRevenueChart(this);
      this.buildTotalTurns(this);
    },

    buildLocationRevenueChart() {// let ctx = this.$('#stat-chart--bar-1').get(0);
      // let labels = ['Total', 'Washer', 'Dryer'];
      // let statData = [
      //   get(this, 'organization').totalAmount,
      //   get(this, 'organization').washerAmount,
      //   get(this, 'organization').dryerAmount
      // ];
      // new Chart(ctx, {
      //   type: 'bar',
      //   data: {
      //     labels,
      //     datasets: [{
      //       label: 'Location Revenue',
      //       data: statData,
      //       backgroundColor: ['#78dfc3', '#5eb2b0', '#5cbed7']
      //     }]
      //   },
      //   options: {
      //     legend: {
      //       display: false
      //     },
      //     tooltips: {
      //       callbacks: {
      //         title(/* tooltipItem */) {
      //           return 'Revenue';
      //         },
      //         label(tooltipItem) {
      //           let formattedRevenue = numeral(tooltipItem.yLabel).format(`${this.get('access.currencyDenomination')}${this.get('access.currencyFormatString')}`);
      //           return `${formattedRevenue}`;
      //         }
      //       }
      //     },
      //     scales: {
      //       yAxes: [{
      //         stacked: true,
      //         ticks: {
      //           beginAtZero: true
      //         }
      //       }],
      //       XAxes: [{
      //         stacked: true,
      //         ticks: {
      //           beginAtZero: true
      //         }
      //       }]
      //     }
      //   }
      // });
    },

    buildWasherRevenueChart() {// let ctx = this.$('#stat-chart--bar-2').get(0);
      // let washers = get(this, 'machines').filter(function(machine) {
      //   return !machine.get('isDryer');
      // });
      // let labels = washers.map(function(item) {
      //   return item.get('machineNumber');
      // });
      // let statData = washers.map(function(item) {
      //   return item.get('stats.totalAmount');
      // });
      // function loadInScrubbrush(event, array) {
      //   if (array.length > 0) {
      //     let index = array[0]._index;
      //     let selectedMachine = washers[index];
      //     get(context, 'brush').transitionTo('machine', selectedMachine, 'show');
      //   }
      // }
      // new Chart(ctx, {
      //   type: 'bar',
      //   data: {
      //     labels,
      //     datasets: [{
      //       label: 'Location Revenue',
      //       data: statData,
      //       backgroundColor: '#70b6e8'
      //     }]
      //   },
      //   options: {
      //     onClick: loadInScrubbrush,
      //     legend: {
      //       display: false
      //     },
      //     tooltips: {
      //       callbacks: {
      //         title(tooltipItem) {
      //           let currentMachine = washers[tooltipItem[0].index];
      //           return currentMachine.get('facility.name');
      //         },
      //         label(tooltipItem) {
      //           let currentMachine = washers[tooltipItem.index];
      //           let machineType = currentMachine.get('isDryer') ? 'Dryer' : 'Washer';
      //           let formattedRevenue = numeral(currentMachine.get('stats.totalAmount')).format(`${this.get('access.currencyDenomination')}${this.get('access.currencyFormatString')}`);
      //           return `${machineType} #${currentMachine.get('machineNumber')}: ${formattedRevenue}`;
      //         }
      //       }
      //     },
      //     scales: {
      //       yAxes: [{
      //         stacked: true,
      //         ticks: {
      //           beginAtZero: true
      //         }
      //       }],
      //       XAxes: [{
      //         stacked: true,
      //         ticks: {
      //           beginAtZero: true
      //         }
      //       }]
      //     }
      //   }
      // });
    },

    buildDryerRevenueChart() {// let ctx = this.$('#stat-chart--bar-3').get(0);
      // let dryers = get(this, 'machines').filter(function(machine) {
      //   return machine.get('isDryer');
      // });
      // let labels = dryers.map(function(item) {
      //   return item.get('machineNumber');
      // });
      // let statData = dryers.map(function(item) {
      //   return item.get('stats.totalAmount');
      // });
      // function loadInScrubbrush(event, array) {
      //   if (array.length > 0) {
      //     let index = array[0]._index;
      //     let selectedMachine = dryers[index];
      //     get(context, 'brush').transitionTo('machine', selectedMachine, 'show');
      //   }
      // }
      // new Chart(ctx, {
      //   type: 'bar',
      //   data: {
      //     labels,
      //     datasets: [{
      //       label: 'Dryer Revenue',
      //       data: statData,
      //       backgroundColor: '#70b6e8'
      //     }]
      //   },
      //   options: {
      //     onClick: loadInScrubbrush,
      //     legend: {
      //       display: false
      //     },
      //     tooltips: {
      //       callbacks: {
      //         title(tooltipItem) {
      //           let currentMachine = dryers[tooltipItem[0].index];
      //           return currentMachine.get('facility.name');
      //         },
      //         label(tooltipItem) {
      //           let currentMachine = dryers[tooltipItem.index];
      //           let machineType = currentMachine.get('isDryer') ? 'Dryer' : 'Washer';
      //           let formattedRevenue = numeral(currentMachine.get('stats.totalAmount')).format(`${this.get('access.currencyDenomination')}${this.get('access.currencyFormatString')}`);
      //           return `${machineType} #${currentMachine.get('machineNumber')}: ${formattedRevenue}`;
      //         }
      //       }
      //     },
      //     scales: {
      //       yAxes: [{
      //         stacked: true,
      //         ticks: {
      //           beginAtZero: true
      //         }
      //       }],
      //       XAxes: [{
      //         stacked: true,
      //         ticks: {
      //           beginAtZero: true
      //         }
      //       }]
      //     }
      //   }
      // });
    },

    buildTotalTurns() {// let ctx = this.$('#stat-chart--bar-4').get(0);
      // let machinesArr = get(this, 'machines').map(function(item) {
      //   return item;
      // });
      // // sort machines alphabetically
      // machinesArr.sort(function(a, b) {
      //   if (a.get('facility.name') < b.get('facility.name')) {
      //     return -1;
      //   }
      //   if (a.get('facility.name') > b.get('facility.name')) {
      //     return 1;
      //   }
      //   return 0;
      // });
      // let labels = machinesArr.map(function(item) {
      //   return item.get('machineNumber');
      // });
      // let statData = machinesArr.map(function(item) {
      //   return item.get('stats.totalTurns');
      // });
      // function buildBackgroundColors() {
      //   let colorArr = [];
      //   let colorsAvailable = ['#70b6e8', '#6182e7'];
      //   let facilityIds = [];
      //   machinesArr.forEach(function(machine, i) {
      //     let facilityId = machine.get('facility.id');
      //     if (i == 0) {
      //       facilityIds.push(facilityId);
      //       colorArr.push(colorsAvailable[0]);
      //       return;
      //     }
      //     if (facilityIds[i - 1] == facilityId) {
      //       colorArr.push(colorArr[colorArr.length - 1]);
      //     } else {
      //       if (colorArr[colorArr.length - 1] == colorsAvailable[0]) {
      //         colorArr.push(colorsAvailable[1]);
      //       } else {
      //         colorArr.push(colorsAvailable[0]);
      //       }
      //     }
      //     facilityIds.push(facilityId);
      //   });
      //   return colorArr;
      // }
      // let backgroundColor = buildBackgroundColors();
      // function loadInScrubbrush(event, array) {
      //   if (array.length > 0) {
      //     let index = array[0]._index;
      //     let selectedMachine = machinesArr[index];
      //     get(context, 'brush').transitionTo('machine', selectedMachine, 'show');
      //   }
      // }
      // new Chart(ctx, {
      //   type: 'bar',
      //   data: {
      //     labels,
      //     datasets: [{
      //       label: 'Total Turns',
      //       data: statData,
      //       backgroundColor
      //     }]
      //   },
      //   options: {
      //     onClick: loadInScrubbrush,
      //     legend: {
      //       display: false
      //     },
      //     tooltips: {
      //       callbacks: {
      //         title(tooltipItem) {
      //           let currentMachine = machinesArr[tooltipItem[0].index];
      //           return currentMachine.get('facility.name');
      //         },
      //         label(tooltipItem) {
      //           let currentMachine = machinesArr[tooltipItem.index];
      //           let machineType = currentMachine.get('isDryer') ? 'Dryer' : 'Washer';
      //           let formattedTurns = numeral(currentMachine.get('stats.totalTurns')).format('0,0');
      //           return `${machineType} #${currentMachine.get('machineNumber')}: ${formattedTurns} turns`;
      //         }
      //       }
      //     },
      //     scales: {
      //       yAxes: [{
      //         stacked: true,
      //         ticks: {
      //           beginAtZero: true
      //         }
      //       }],
      //       XAxes: [{
      //         stacked: true,
      //         ticks: {
      //           beginAtZero: true
      //         }
      //       }]
      //     }
      //   }
      // });
    }

  });

  _exports.default = _default;
});