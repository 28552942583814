define("alliance-business-suite/models/system-alert-subscription", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    email: attr('string'),
    phone: attr('string'),
    // user: belongsTo('user'),
    organization: belongsTo('organization'),
    systemAlertType: belongsTo('system-alert-type')
  });

  _exports.default = _default;
});