define("alliance-business-suite/templates/components/ui/system-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "X9xQk9rQ",
    "block": "{\"symbols\":[],\"statements\":[[11,\"div\"],[4,[38,4],[[32,0,[\"initialLoad\"]]],null],[12],[2,\"\\n\"],[6,[37,5],[[32,0,[\"showBanner\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"system-warning\"],[12],[2,\"\\n      \"],[10,\"p\"],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"fa fa-exclamation-triangle\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n\\n        \"],[1,[30,[36,0],[\"brand_care\"],[[\"htmlSafe\"],[true]]]],[2,\"\\n\\n        \"],[10,\"span\"],[14,0,\"text-lowercase\"],[12],[2,\"\\n          \"],[1,[30,[36,1],[\"subscriptions_expiring_soon_for\"],null]],[2,\" \"],[1,[32,0,[\"access\",\"locationsExpiringSoon\",\"length\"]]],[2,\" \"],[1,[30,[36,3],[[30,[36,2],[[32,0,[\"access\",\"locationsExpiringSoon\",\"length\"]],1],null],[30,[36,1],[\"locations\"],null],[30,[36,1],[\"location\"],null]],null]],[2,\".\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"a\"],[14,6,\"/profile/billing\"],[12],[2,\"\\n          \"],[1,[30,[36,1],[\"renew_today\"],null]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"b\",\"t\",\"gte\",\"if\",\"did-insert\",\"liquid-if\"]}",
    "moduleName": "alliance-business-suite/templates/components/ui/system-warning.hbs"
  });

  _exports.default = _default;
});