define("alliance-business-suite/templates/components/form-ui/steps/reuse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4pxHw+EA",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[8,\"form-ui\",[],[[\"@object\",\"@formSettings\",\"@selectOptions\"],[[32,0,[\"object\"]],[32,0,[\"formSettings\"]],[32,0,[\"selectOptions\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[10,\"h2\"],[12],[1,[30,[36,1],[\"reuse\"],null]],[13],[2,\"\\n\\n  \"],[8,[32,1,[\"checkbox\"]],[],[[\"@params\"],[[30,[36,0],null,[[\"propertyName\",\"label\"],[[32,0,[\"properties\",\"ReuseFillA\",\"key\"]],\"ReuseFillA\"]]]]],null],[2,\"\\n  \"],[8,[32,1,[\"checkbox\"]],[],[[\"@params\"],[[30,[36,0],null,[[\"propertyName\",\"label\"],[[32,0,[\"properties\",\"ReuseFillB\",\"key\"]],\"ReuseFillB\"]]]]],null],[2,\"\\n\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,2],[[32,0,[\"ExternalDrain\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,[32,1,[\"checkbox\"]],[],[[\"@params\"],[[30,[36,0],null,[[\"propertyName\",\"label\"],[[32,0,[\"properties\",\"ExternalDrain\",\"key\"]],\"ExternalDrain\"]]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"hash\",\"t\",\"is-empty\",\"not\",\"if\"]}",
    "moduleName": "alliance-business-suite/templates/components/form-ui/steps/reuse.hbs"
  });

  _exports.default = _default;
});