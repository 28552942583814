define("alliance-business-suite/templates/components/billing/subscription-statuses", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Xuq04MFy",
    "block": "{\"symbols\":[\"loc\",\"@addSubscription\",\"@removeSubscription\"],\"statements\":[[10,\"table\"],[14,0,\"table billing-receipts\"],[12],[2,\"\\n  \"],[10,\"thead\"],[12],[2,\"\\n    \"],[10,\"td\"],[12],[2,\"Location\"],[13],[2,\"\\n    \"],[10,\"td\"],[12],[2,\"Expiration Date\"],[13],[2,\"\\n    \"],[10,\"td\"],[12],[2,\"Next Bill\"],[13],[2,\"\\n    \"],[10,\"td\"],[12],[2,\"Auto Renew\"],[13],[2,\"\\n    \"],[10,\"td\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[6,[37,9],[[30,[36,8],[[35,5,[\"length\"]],0],null]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,7],[[30,[36,6],[[30,[36,6],[[35,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"billing/subscription-status\",[],[[\"@addSubscription\",\"@removeSubscription\",\"@item\",\"@togglePaymentForm\",\"@toggleUpgradeForm\"],[[32,2],[32,3],[32,1],[30,[36,0],[[32,0],\"togglePaymentForm\",[32,1]],null],[30,[36,0],[[32,0],\"toggleUpgradeForm\",[32,1]],null]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"tr\"],[12],[2,\"\\n      \"],[10,\"td\"],[12],[1,[30,[36,4],[\"no_subscriptions_data\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\"],[6,[37,9],[[35,10]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"payment/modal-payment-form\",[],[[\"@close\",\"@selectedLocation\"],[[30,[36,0],[[32,0],\"togglePaymentForm\"],null],[34,1]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,9],[[35,11]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"payment/modal-upgrade-form\",[],[[\"@close\",\"@selectedLocation\",\"@currentPlan\",\"@rawLoc\"],[[30,[36,0],[[32,0],\"toggleUpgradeForm\"],null],[34,1],[34,2],[34,3]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"selectedLoc\",\"currentPlan\",\"rawLoc\",\"t\",\"locations\",\"-track-array\",\"each\",\"gte\",\"if\",\"showPaymentForm\",\"showUpgradeForm\"]}",
    "moduleName": "alliance-business-suite/templates/components/billing/subscription-statuses.hbs"
  });

  _exports.default = _default;
});