define("alliance-business-suite/modifiers/split-column", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _emberModifier.modifier)((element, _ref) => {
    let [id, classNames] = _ref;
    element.id = `split${id}`;
    element.classList.add("split");
    if (classNames) element.classList.add(classNames);
  });

  _exports.default = _default;
});