define("alliance-business-suite/components/shopping-cart", ["exports", "jquery", "moment", "alliance-business-suite/mixins/shopping-cart", "alliance-business-suite/mixins/account-payment"], function (_exports, _jquery, _moment, _shoppingCart, _accountPayment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_shoppingCart.default, _accountPayment.default, {
    access: Ember.inject.service(),
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    intl: Ember.inject.service(),
    notifications: Ember.inject.service(),
    brand: Ember.inject.service(),
    classNameBindings: ['brandNameClass'],
    brandNameClass: Ember.computed('this.brand.slug', function () {
      return `cart--${this.brand.slug}`;
    }),
    selectedRenew: false,
    serviceTerms: false,
    activeSection: 'info',
    hasDiscount: false,
    mode: 'default',
    stripeToken: null,
    services: [],
    activeAddons: [],
    fetchServices: true,
    state: Ember.computed('selectedState', function () {
      let selectedState = this.selectedState;
      return this.selectedState.code;
    }),

    setOrgPrices() {
      this.set('isCalcPrices', true);
      this.set('servicesError', false);
      this.set('hasError', false);
      let selectedLocation = this.selectedLocation;
      this.ajax.request(`/organizations/${this.get('access.accessOrganization.id')}/balance/price?raw=true&zipCode=${this.zipCode}&state=${this.state}&roomId=${selectedLocation.id}`, {}).then(response => {
        // descending order by cost
        let products = response.products;
        let addons = response.addons;
        let sortedProducts = products.sort((a, b) => {
          if (a.cost > b.cost) {
            return 1;
          } else if (a.cost < b.cost) {
            return -1;
          }

          return 0;
        });
        this.setProperties({
          services: sortedProducts,
          addons: addons,
          isCalcPrices: false,
          servicesError: false
        });
      }).catch(e => {
        this.set('activeSection', 'info');
        this.setProperties({
          isCalcPrices: false,
          servicesError: true
        });
      });
    },

    calcFinalPrice() {
      let {
        selectedLocation,
        selectedService,
        addonLineItems
      } = this;

      if (!selectedLocation || !selectedService) {
        return;
      }

      let roomId = selectedLocation.id;
      let productLookupKey = selectedService.lookupKey;
      let addons = [];
      this.set('isLoadingPrice', true);

      if (addonLineItems && addonLineItems.length > 0) {
        addonLineItems.forEach(a => {
          if (a.isSelected) {
            addons.push(a.lookupKey);
          }
        });
      }

      this.ajax.request(`/organizations/${this.get('access.accessOrganization.id')}/balance/calculate?raw=true`, {
        data: {
          roomId,
          zipCode: this.zipCode,
          state: this.state,
          productLookupKey,
          addons
        },
        method: 'POST'
      }).then(response => {
        this.set('isLoadingPrice', false);
        this.set('finalPrice', response);
      }).catch(error => {
        this.set('activeSection', 'info');
        this.set('servicesError', true);
        this.set('isLoadingPrice', false);
        this.set('fetchServices', true);
        this.set('selectedService', null);
      });
    },

    selectedServiceObserver: Ember.observer('selectedService', function () {
      this.calcFinalPrice();
    }),
    rooms: Ember.computed(function () {
      return this.access.paymentLocations;
    }),
    // clear out add-ons if the state or zip code are changed
    infoChanges: Ember.observer('zipCode', 'selectedState', function () {
      this.set('activeAddons', []);
    }),
    selectedLocation: Ember.computed('preselectedLocation', function () {
      if (this.preselectedLocation) {
        let roomId = this.preselectedLocation;
        let room = null;
        this.rooms.forEach(r => {
          if (r.id === roomId) {
            room = r;
          }
        });
        return room;
      }

      return this.access.paymentLocations[0];
    }),
    expires: Ember.computed('selectedLocation', function () {
      let loc = this.selectedLocation;
      let isAfter = (0, _moment.default)(loc.expirationDate).isAfter((0, _moment.default)()); // if the date is expired, then its from today plus a year

      if (!isAfter) {
        return (0, _moment.default)().add(1, 'years').format('MM/DD/YYYY');
      } // if the exp is current, then its a year plus


      let currentLocation = null;
      let locationsWithPaymentInfo = this.access.paymentLocations;
      locationsWithPaymentInfo.forEach(l => {
        if (l.id == this.selectedLocation.id) {
          currentLocation = l;
        }
      });
      let currentExpiration = currentLocation.expirationDate;
      return (0, _moment.default)(currentExpiration).add(1, 'years').format('MM/DD/YYYY');
    }),
    isInfoNotComplete: Ember.computed('zipCode', 'selectedState', function () {
      let zipCode = this.zipCode;
      var isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zipCode);

      if (isValidZip && this.selectedState) {
        return false;
      } else {
        return true;
      }
    }),
    isServiceNotComplete: Ember.computed('selectedService', 'serviceTerms', function () {
      let selectedService = this.selectedService;
      let serviceTerms = this.serviceTerms;

      if (selectedService && serviceTerms) {
        return false;
      } else {
        return true;
      }
    }),
    isSignNotCompete: Ember.computed('selectedSign', function () {
      let selectedSign = this.selectedSign;

      if (selectedSign) {
        return false;
      } else {
        return true;
      }
    }),
    hasSelectedGatewayAddon: Ember.computed('addonLineItems.[].@each', function () {
      let {
        addonLineItems
      } = this;
      let result;
      addonLineItems.forEach(addon => {
        if (addon.lookupKey == 'ADDON_CELLULAR_GATEWAY' && addon.isSelected) {
          result = true;
        }
      });
      return result;
    }),
    generateAddOns: Ember.observer('selectedService', 'activeAddons', function () {
      let {
        selectedService,
        activeAddons
      } = this;
      let arr = [];

      if (selectedService) {
        // the user has chosen an option so we lock down the "services", to not make more API calls
        this.set('fetchServices', false);
      }

      if (selectedService && selectedService.supportedAddons) {
        let supportedAddons = selectedService.supportedAddons;
        supportedAddons.forEach(addon => {
          let obj = {};
          obj.description = addon.description;
          obj.id = addon.id;
          obj.lookupKey = addon.lookupKey;
          obj.pricePerMonth = addon.pricePerMonth;
          obj.isSelected = false;
          arr.push(obj);
        });
        this.set('addonLineItems', arr);
      }
    }),

    charge(context, organizationId, userEmail, addons, stripeToken) {
      // checkout call
      // need a spinner here
      context.ajax.request(`/organizations/${organizationId}/balance/charge`, {
        method: 'POST',
        data: {
          userEmail: userEmail,
          stripeToken,
          roomId: context.selectedLocation.id,
          shouldAutoRenew: context.selectedRenew,
          zipCode: context.zipCode,
          state: context.state,
          productLookupKey: context.selectedService.lookupKey,
          signageTypeLookupKey: context.selectedSign ? context.selectedSign.lookupKey : null,
          addons
        }
      }).then(() => {
        context.set('mode', 'success');
        setTimeout(function () {
          location.href = '/';
        }, 10000);
      }, () => {
        alert(context.intl.t('cart_payment_error'));
        context.set('mode', 'default'); // alert('Error 2827, please contact support. You were not charged.');
        // context.notifications.error(context.intl.t('cart_payment_error'), {
        //   autoClear: true,
        //   clearDuration: 30000
        // });
      });
    },

    actions: {
      updateAddon(val) {
        let {
          addonLineItems,
          activeAddons
        } = this;
        let newArr = [];
        addonLineItems.forEach(addon => {
          let obj = {};
          obj.description = addon.description;
          obj.id = addon.id;
          obj.lookupKey = addon.lookupKey;
          obj.pricePerMonth = addon.pricePerMonth;

          if (addon.lookupKey == val.lookupKey) {
            obj.isSelected = !val.isSelected;
          } else {
            obj.isSelected = addon.isSelected;
          }

          newArr.push(obj);
        });
        this.set('addonLineItems', newArr);
        this.calcFinalPrice();
      },

      close() {
        this.close();
      },

      logout() {
        this.session.invalidate();
      },

      setSection(section) {
        if (section == 'payment') {
          this.send('payment');
          return false;
        }

        this.set('activeSection', section);

        if (section == 'services') {
          if (this.fetchServices) {
            this.setOrgPrices();
          }
        }
      },

      payment() {
        let _this = this;

        let organizationId = this.get('session.data.orgId');
        let userEmail = this.access.accessUser.primaryEmail;
        let {
          addonLineItems,
          finalPrice
        } = this;
        let brandName = this.intl.t(this.brand.name);
        let imageUrl = this.brand.lookupKey('payment-logo'); // console.log('_this.selectedService', _this.selectedService);
        // console.log('_this.zipCode', _this.zipCode);
        // console.log('_this.state', _this.state);

        if (!_this.zipCode || !_this.state || !_this.selectedService.id) {
          alert(this.intl.t('complete_form_to_continue'));
          return;
        }

        let addons = [];

        if (addonLineItems && addonLineItems.length > 0) {
          addonLineItems.forEach(a => {
            if (a.isSelected) {
              addons.push(a.lookupKey);
            }
          });
        }

        this.set('mode', 'loading');

        if (finalPrice.total == '0') {
          _this.charge(_this, organizationId, userEmail, addons);
        } else {
          // stripe stuff here
          // fake CC 4242424242424242
          let stripe = StripeCheckout.configure({
            // key: "pk_test_ZzfxZ5TKR8EAIRi7jNcbF2Ii00g8bLwRen", // TEST
            key: "pk_live_WPqd2s7WlRCyIR21goQQKtkx009OSbLGWq",
            // Prod
            image: imageUrl,
            locale: "auto",
            token: function (token) {
              _this.set('stripeToken', token);

              _this.charge(_this, organizationId, userEmail, addons, token.id);
            }
          }); // pass extra meta data

          stripe.open({
            // name: `Order ${brandName} Care`,
            name: this.brand.b('order_care'),
            // description: "Purchase 1 year",
            // zipCode: true,
            amount: _this.total
          }); // return to 'default' mode when closing the stripe iframe
          // which is only when there is no stripe token

          (0, _jquery.default)(document).on("DOMNodeRemoved", ".stripe_checkout_app", close);
        }

        function close() {
          if (!_this.stripeToken) {
            _this.set('mode', 'default');
          }
        }
      }

    }
  });

  _exports.default = _default;
});