define("alliance-business-suite/helpers/true-false-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (_ref) {
    let [value, ...rest] = _ref;

    if (value) {
      return Ember.String.htmlSafe('<i class="fa fa-check is-true"></i>');
    } else {
      return Ember.String.htmlSafe('<i class="fa fa-times is-false"></i>');
    }
  });

  _exports.default = _default;
});