define("alliance-business-suite/templates/summary/reports", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DA51T0Pr",
    "block": "{\"symbols\":[],\"statements\":[[8,\"summary/s-reports\",[],[[\"@source\",\"@reportId\",\"@startDate\",\"@endDate\",\"@timeZone\",\"@reports\",\"@machineId\",\"@updateQueryParam\"],[[32,0,[\"source\"]],[32,0,[\"reportId\"]],[32,0,[\"startDate\"]],[32,0,[\"endDate\"]],[32,0,[\"timeZone\"]],[32,0,[\"reports\"]],[32,0,[\"machineId\"]],[32,0,[\"updateQueryParam\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "alliance-business-suite/templates/summary/reports.hbs"
  });

  _exports.default = _default;
});