define("alliance-business-suite/components/employees/e-edit-form", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "jquery", "@glimmer/component", "alliance-business-suite/utils/timezones", "alliance-business-suite/validators/employee-validators"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _jquery, _component, _timezones, _employeeValidators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _dec32, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21;

  // export default class EmployeesEEditFormComponent extends Component.extend(Validations) {
  let EmployeesEEditFormComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember.inject.service, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember._tracked, _dec18 = Ember._tracked, _dec19 = Ember._tracked, _dec20 = Ember._tracked, _dec21 = Ember._tracked, _dec22 = Ember._action, _dec23 = Ember._action, _dec24 = Ember._action, _dec25 = Ember._action, _dec26 = Ember._action, _dec27 = Ember._action, _dec28 = Ember._action, _dec29 = Ember._action, _dec30 = Ember._action, _dec31 = Ember._action, _dec32 = Ember._action, (_class = class EmployeesEEditFormComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "ajax", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "canAccess", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "brush", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "router", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "notifications", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor8, this);
      (0, _defineProperty2.default)(this, "validations", _employeeValidators.default);
      (0, _defineProperty2.default)(this, "organization", this.store.peekRecord('organization', this.access.accessOrganization.get('id')));
      (0, _initializerDefineProperty2.default)(this, "currentSecurityRoles", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "newSecurityRoles", _descriptor10, this);
      (0, _initializerDefineProperty2.default)(this, "currentRooms", _descriptor11, this);
      (0, _initializerDefineProperty2.default)(this, "newRooms", _descriptor12, this);
      (0, _initializerDefineProperty2.default)(this, "preSelectedNodes", _descriptor13, this);
      (0, _initializerDefineProperty2.default)(this, "selectedRole", _descriptor14, this);
      (0, _initializerDefineProperty2.default)(this, "lastKnownRole", _descriptor15, this);
      (0, _initializerDefineProperty2.default)(this, "selectedTimeZone", _descriptor16, this);
      (0, _initializerDefineProperty2.default)(this, "isRefreshingTree", _descriptor17, this);
      (0, _initializerDefineProperty2.default)(this, "isOwnerOperator", _descriptor18, this);
      (0, _initializerDefineProperty2.default)(this, "selectedLocations", _descriptor19, this);
      (0, _initializerDefineProperty2.default)(this, "hasRanInitialDataGeneration", _descriptor20, this);
      (0, _initializerDefineProperty2.default)(this, "isLoading", _descriptor21, this);
    }

    // @action
    // receivedNewAttrs() {
    //   console.log('ALERT:::: received attrs...')
    //   if (this.args.isEditing) {
    //     this.findAllTheRooms(this.args.model);
    //   }
    // }
    initialLoad() {
      // when editing use the employee's existing data
      if (this.args.isEditing) {
        this.setPrimaryEmail();
        this.setCurrentSecurityRoles();
        this.setCurrentTimeZone();
        this.findAllTheRooms(this.args.model);
      } else {
        this.guessUserTimezone();
        this.setDefaultRole();
        this.hasRanInitialDataGeneration = true; // no data to configure
      }

      if (this.lastKnownRole && this.lastKnownRole.get('id')) {
        setTimeout(() => {
          this.isNewRoleOwnerOperator(this.lastKnownRole);
        }, 400);
      }
    } // computed('isEditing', 'viewerRole', 'model', function() {


    get accessDisabled() {
      let {
        isEditing,
        model
      } = this.args;
      let {
        viewerRole
      } = this;
      const accessCreate = this.canAccess.canAccess('EMPLOYEES_CREATE_VIEW');
      const accessEdit = this.canAccess.canAccess('EMPLOYEES_EDIT_VIEW');
      const isCreating = model.securityRoles.get('length') == 0;

      if (!isCreating && viewerRole.get('id') && model.securityRoles) {
        let viewerIsOwner = viewerRole.get('name') == 'OWNER_OPERATOR';
        let vieweeisSuspended = model.get('securityRoles').get('firstObject').get('name') == 'SUSPEND_EMPLOYEE';
        let vieweeIsOwner = model.get('securityRoles').get('firstObject').get('name') == 'OWNER_OPERATOR';

        if (vieweeisSuspended || !viewerIsOwner && vieweeIsOwner) {
          return true;
        }
      }

      if (!isEditing) {
        if (!accessCreate) {
          return true;
        }
      } else {
        return !accessEdit;
      }

      return false;
    }

    get headerText() {
      let intlKey = 'view_employee';

      if (this.args.isEditing) {
        intlKey = 'edit_employee';
      } else {
        if (this.canAccess.canAccess('EMPLOYEES_EDIT_VIEW')) {
          intlKey = 'new_employee';
        } else {
          intlKey = 'view_employee';
        }
      }

      return this.intl.t(intlKey);
    } // computed('isEditing', 'viewerRole', 'model', function() {


    get editingDisabled() {
      const {
        isEditing,
        isCreate
      } = this.args;
      const canAccessEdit = this.canAccess.canAccess('EMPLOYEES_EDIT_VIEW');
      const canAccessCreate = this.canAccess.canAccess('EMPLOYEES_CREATE_VIEW');

      if (isCreate && canAccessCreate) {
        return false;
      }

      if (isEditing || !canAccessEdit) {
        return true;
      }
    }

    get viewerRole() {
      const viewerId = this.access.accessUser.get('id');
      const employee = this.store.peekRecord('employee', viewerId);
      const securityRoles = employee.get('securityRoles');
      const securityRole = securityRoles.get('firstObject');
      return securityRole;
    } // only OWNER_OPERATORs can add that role
    // omputed('viewerRole', 'securityRoles.[]', function() {


    get filteredSecurityRoles() {
      return this.args.securityRoles.filter((role, idx) => {
        let canReturn = true;

        if (role.get('name') == 'OWNER_OPERATOR') {
          if (this.viewerRole.get('name') != 'OWNER_OPERATOR') {
            canReturn = false;
          }
        }

        if (role.name == 'SUSPEND_EMPLOYEE') {
          canReturn = false;
        }

        if (canReturn) {
          return role;
        }
      });
    }

    get isSuspended() {
      let {
        model
      } = this.args;
      return model.securityRoles.get('firstObject').get('name') == 'SUSPEND_EMPLOYEE';
    }

    findTimezoneByLocale(locale) {
      let tzObj = null;
      let self = this;

      _timezones.TIME_ZONES.forEach((tz, index) => {
        let utcArr = tz.utc;
        utcArr.forEach(utc => {
          if (utc == locale) {
            tzObj = _timezones.TIME_ZONES[index];
          }
        });
      });

      this.selectedTimeZone = tzObj;
    }

    guessUserTimezone() {
      const browserTZ = moment.tz.guess();
      let selectedUTC = _timezones.TIME_ZONES[5];
      let self = this;

      _timezones.TIME_ZONES.forEach((tz, index) => {
        let utcArr = tz.utc;
        utcArr.forEach(utc => {
          if (utc == browserTZ) {
            selectedUTC = _timezones.TIME_ZONES[index];
          }
        });
      });

      this.selectedTimeZone = selectedUTC;
      this.updateTimezone(selectedUTC);
    }

    setCurrentTimeZone() {
      const model = this.args.model;
      const userTz = model.get('timeZone');
      this.selectedTimeZone = userTz;
      this.updateTimezone(userTz);
    }

    setCurrentSecurityRoles() {
      const model = this.args.model;
      const securityRoles = model.securityRoles;
      let currentRolesArr = []; // for now we assume only one security role

      this.selectedRole = securityRoles.get('firstObject');
      this.lastKnownRole = securityRoles.get('firstObject');
      let firstRole = securityRoles.get('firstObject');

      if (firstRole) {
        currentRolesArr.push(firstRole.get('id'));
        this.currentSecurityRoles = currentRolesArr;
      } else {
        this.setDefaultRole();
        const role = this.selectedRole;
        this.newSecurityRoles = [role.get('id')];
      }
    }

    findAllTheRooms(model) {
      const employee = this.store.peekRecord('employee', model.get('id'));
      const eRooms = employee.rooms;
      let employeeRoomIds = eRooms.map(r => {
        return r.get('id');
      });
      this.preSelectedNodes = employeeRoomIds;
      this.currentRooms = employeeRoomIds;
      this.hasRanInitialDataGeneration = true;
    } // observeSelectedTreeNodes: observer('selectedLocations.[]', function() {
    //   console.log('old: OBSERVED KICKED!')
    //   let selectedNodes = get(this, 'selectedLocations');
    //   let store = get(this, 'store');
    //   let model = get(this, 'model');
    //   let rooms = [];
    //   let newRooms = [];
    //
    //   if (selectedNodes) {
    //     selectedNodes.forEach((node) => {
    //       if (node.original.roomId) {
    //         let { roomId } = node.original;
    //         let room = store.peekRecord('room', roomId);
    //         rooms.push(room);
    //         newRooms.push(room.id);
    //       }
    //     });
    //   }
    //
    //   set(model, 'rooms', rooms);
    //   set(this, 'newRooms', newRooms);
    //   this.notifyPropertyChange('model'); // Make sure updates happen
    // }),


    locationTreeDidLoad() {
      if (this.args.isEditing) {
        this.findAllTheRooms(this.args.model);
      }
    }

    locationTreeDidChange(event) {
      if (event && event.action != 'ready') {
        const selectedNodes = this.selectedLocations;
        const model = this.args.model;
        let rooms = [];
        let newRooms = [];

        if (selectedNodes) {
          selectedNodes.forEach(node => {
            if (node.original.roomId) {
              const {
                roomId
              } = node.original;
              const room = this.store.peekRecord('room', roomId);
              rooms.push(room);
              newRooms.push(room.id);
            }
          });
        }

        model.rooms = rooms;
        this.newRooms = newRooms; // this.notifyPropertyChange('model'); // Make sure updates happen
      }
    }

    setDefaultRole() {
      const model = this.args.model;
      let employeeRole = null;
      this.store.peekAll('security-role').filter(r => {
        if (r.name == 'EMPLOYEE') {
          employeeRole = r;
        }
      });

      if (!employeeRole) {
        this.store.peekAll('security-role').filter(r => {
          if (r.name == 'MANAGER') {
            employeeRole = r;
          }
        });
      }

      this.lastKnownRole = employeeRole;
      this.selectedRole = employeeRole;
      model.securityRoles = employeeRole ? [employeeRole] : [];
    }

    setPrimaryEmail() {
      const model = this.args.model;
      const emails = model.get('emailAddresses');

      if (emails.get('firstObject')) {
        model.email = emails.get('firstObject').get('emailAddress');
      }
    } // given two arrays to compare, it spits out two new arrays of those items added and removed


    getAddedRemoved(oldList, newList) {
      let addList = [];
      let removeList = [];
      newList.forEach(role => {
        if (!oldList || !oldList.includes(role)) {
          addList.push(parseInt(role));
        }
      });

      if (oldList.length > 0 && newList.length > 0) {
        oldList.forEach(role => {
          if (!newList.includes(role)) {
            removeList.push(parseInt(role));
          }
        });
      }

      return {
        addList,
        removeList
      };
    } // the tree is disabled only when 'OWNER_OPERATOR' and reset back to its original values


    isNewRoleOwnerOperator(role) {
      let {
        lastKnownRole,
        store
      } = this;
      let roleType = role ? role.name : '';

      if (lastKnownRole != 'OWNER_OPERATOR') {
        if (roleType != 'OWNER_OPERATOR') {
          return;
        }

        if (roleType == 'OWNER_OPERATOR') {
          this.isRefreshingTree = true;
          this.isOwnerOperator = true;
        }
      }

      if (lastKnownRole == 'OWNER_OPERATOR') {
        if (roleType != 'OWNER_OPERATOR') {
          this.isRefreshingTree = true;
          this.isOwnerOperator = false;
        }
      }

      this.lastKnownRole = roleType;
      setTimeout(() => {
        this.isRefreshingTree = false;
      }, 1000);
    } // computed('isOwnerOperator', 'accessDisabled', function() {


    get disableTree() {
      let {
        isOwnerOperator,
        accessDisabled
      } = this;
      return isOwnerOperator || accessDisabled;
    }

    updateRole(role) {
      let {
        model,
        isEditing
      } = this.args;
      this.isNewRoleOwnerOperator(role);

      if (isEditing) {
        const roleId = role.get('id');
        this.newSecurityRoles = [roleId];
      }

      model.securityRoles = [role];
      this.selectedRole = role;
    }

    saveEmployee(model) {
      this.isLoading = true;
      const {
        isEditing
      } = this.args; // API expects security role changes to be sent in two diff arrays

      if (isEditing) {
        const roleArrays = this.getAddedRemoved(this.currentSecurityRoles, this.newSecurityRoles);
        model.setProperties({
          addSecurityRoles: roleArrays.addList,
          removeSecurityRoles: roleArrays.removeList
        });
        let roomArrays = this.getAddedRemoved(this.currentRooms, this.newRooms);
        model.setProperties({
          addRooms: roomArrays.addList,
          removeRooms: roomArrays.removeList
        }); // if no rooms selected in the tree, then we remove all rooms

        if (this.selectedLocations && this.selectedLocations.length == 0) {
          model.setProperties({
            removeRooms: this.currentRooms
          });
        }
      }

      return model.save().then(() => {
        this.isLoading = false;
        this.router.transitionTo('employees');

        if (isEditing) {
          this.notifications.info(this.intl.t('employee_successfully_updated'), {
            autoClear: true,
            clearDuration: 2000
          });
        } else {
          this.notifications.info(this.intl.t('employee_successfully_created'), {
            autoClear: true,
            clearDuration: 2000
          });
        }
      }).catch(error => {
        this.isLoading = false;
        this.notifications.error(this.intl.t('unexpected_error'), {
          autoClear: true,
          clearDuration: 8000
        });
      });
    }

    updateTimezoneShim(tz) {
      const model = this.args.model;

      if (tz.utc) {
        model.timeZone = tz.utc[0];
        this.selectedTimeZone = tz;
      } else {
        model.timeZone = tz;
        this.findTimezoneByLocale(tz);
      }
    }

    updateSelectedRole(role) {
      this.updateRole(role);
    }

    updateTimezone(tz) {
      this.updateTimezoneShim(tz);
    } // WEIRD EMBER BUG: if inspector is open during this, it will freeze chrome
    // https://github.com/emberjs/ember-inspector/issues/725


    deleteEmployee(model) {
      // if (this.accessDisabled) { return }
      this.isLoading = true;
      this.ajax.request(`/employees/${model.get('id')}`, {
        method: 'DELETE',
        data: {
          id: model.get('id'),
          organization: {
            id: this.access.accessOrganization.get('id')
          },
          updatedBy: {
            id: this.access.accessUser.get('id')
          }
        }
      }).then(() => {
        model.deleteRecord();
        model.unloadRecord();
        this.router.transitionTo('employees');
        this.isLoading = false; // clear the brush

        let searchInput = (0, _jquery.default)('#scrub_search');
        searchInput.focus().val('').trigger({
          type: 'keydown',
          which: 20
        });
        this.brush.transitionTo('default', null);
        this.notifications.info(this.intl.t('employee_successfully_deleted'), {
          autoClear: true,
          clearDuration: 2000
        });
      }).catch((error
      /* , jqXHR */
      ) => {
        alert(error);
      });
    }

    exit(model) {
      if (this.args.isEditing) {
        model.rollbackAttributes();
      }

      this.router.transitionTo('employees');
    }

    save(model, changeset) {
      if (this.accessDisabled) {
        return;
      }

      changeset.execute(); // apply the changeset to the @model

      this.saveEmployee(model);
    }

    reactivateEmployee(model) {
      let role = this.store.peekAll('securityRole').filter(role => {
        if (role.name === "EMPLOYEE") {
          return role;
        }
      });
      this.updateRole(role.get('firstObject'));
      this.saveEmployee(model).then(() => {
        alert(this.intl.t("reactivated_employee_completed"));
      });
    }

    suspendEmployee(model) {
      if (this.accessDisabled) {
        return;
      }

      let role = this.store.peekAll('securityRole').filter(role => {
        if (role.name === "SUSPEND_EMPLOYEE") {
          return role;
        }
      });
      this.updateRole(role.get('firstObject'));
      this.selectedLocations = [];
      this.saveEmployee(model);
    }

    updateRatePerHour(changeset, val) {
      changeset.set('ratePerHour', val);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "canAccess", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "brush", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "router", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "notifications", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "currentSecurityRoles", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "newSecurityRoles", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor11 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "currentRooms", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor12 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "newRooms", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor13 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "preSelectedNodes", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor14 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedRole", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "lastKnownRole", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedTimeZone", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isRefreshingTree", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isOwnerOperator", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedLocations", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "hasRanInitialDataGeneration", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor21 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isLoading", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "initialLoad", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "initialLoad"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "locationTreeDidLoad", [_dec23], Object.getOwnPropertyDescriptor(_class.prototype, "locationTreeDidLoad"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "locationTreeDidChange", [_dec24], Object.getOwnPropertyDescriptor(_class.prototype, "locationTreeDidChange"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "updateSelectedRole", [_dec25], Object.getOwnPropertyDescriptor(_class.prototype, "updateSelectedRole"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "updateTimezone", [_dec26], Object.getOwnPropertyDescriptor(_class.prototype, "updateTimezone"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "deleteEmployee", [_dec27], Object.getOwnPropertyDescriptor(_class.prototype, "deleteEmployee"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "exit", [_dec28], Object.getOwnPropertyDescriptor(_class.prototype, "exit"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "save", [_dec29], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "reactivateEmployee", [_dec30], Object.getOwnPropertyDescriptor(_class.prototype, "reactivateEmployee"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "suspendEmployee", [_dec31], Object.getOwnPropertyDescriptor(_class.prototype, "suspendEmployee"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "updateRatePerHour", [_dec32], Object.getOwnPropertyDescriptor(_class.prototype, "updateRatePerHour"), _class.prototype)), _class));
  _exports.default = EmployeesEEditFormComponent;
});