define("alliance-business-suite/components/employees/roles/create-role", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notifications: Ember.inject.service(),
    intl: Ember.inject.service(),

    didInsertElement() {
      this._super(...arguments); // The focus doesn't work quite right...I think it's because of how the modal works.


      Ember.run.schedule('afterRender', () => {
        let element = this.$().find('[autofocus]:first').focus();
        setTimeout(function () {
          element.focus();
        }, 500);
      });
    },

    copyFromOptions: Ember.computed('securityRoles.[]', function () {
      let roles = Ember.get(this, 'securityRoles');
      return roles.map(role => {
        let intl = Ember.get(this, 'intl');
        let val = Ember.get(role, 'id');
        let name = Ember.get(role, 'name');
        let label = Ember.get(role, 'isSystemDefault') ? intl.t(name) : name;
        return {
          val,
          label
        };
      });
    }),
    isValidName: Ember.computed('roleName', function () {
      let usedNames = Ember.get(this, 'securityRoles').map(item => {
        if (!item.get('isSystemDefault')) {
          return Ember.get(item, 'name').toLowerCase();
        }
      });
      let roleName = Ember.get(this, 'roleName').toLowerCase();
      return !usedNames.includes(roleName);
    }),
    isValid: Ember.computed('isValidName', 'roleName', 'copyRole', function () {
      return Ember.get(this, 'roleName') && Ember.get(this, 'isValidName') && Ember.get(this, 'copyRole');
    }),
    actions: {
      createRole() {
        Ember.get(this, 'createRole')({
          name: Ember.get(this, 'roleName'),
          copyRoleId: Ember.get(this, 'copyRole')
        });
        Ember.get(this, 'close')();
      },

      updateSelectedRole(val) {
        Ember.set(this, "copyRole", val.val);
      }

    }
  });

  _exports.default = _default;
});