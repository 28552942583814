define("alliance-business-suite/routes/machines/programs/index", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "alliance-business-suite/routes/_secureRoute"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _secureRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2;

  let MachinesProgramsIndexRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._action, _dec4 = Ember._action, (_class = class MachinesProgramsIndexRoute extends _secureRoute.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "machines", _descriptor2, this);
      (0, _defineProperty2.default)(this, "allowAccess", 'MACHINES_PROGRAMS_VIEW');
      (0, _defineProperty2.default)(this, "disableBrush", true);
    }

    model() {
      this.machines.clearFetchCount();
      return Ember.RSVP.hash({
        machineAuditType: this.store.peekAll('machine-audit-type'),
        machineAuditGroupType: this.store.peekAll('machine-audit-group-type'),
        // within machine-audit-type
        machineType: this.store.peekAll('machine-type'),
        machineProgram: this.store.peekAll('machineProgram')
      });
    }

    edit(route, id) {
      this.transitionTo(route, id);
    }

    reloadModel() {
      this.store.findRecord('organization', this.access.get('accessOrganization.id'), {
        include: 'machine-programs',
        reload: true
      }).then(() => {
        this.machines.clearFetchCount();
      });
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "machines", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "edit", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "edit"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "reloadModel", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "reloadModel"), _class.prototype)), _class));
  _exports.default = MachinesProgramsIndexRoute;
});