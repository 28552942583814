define("alliance-business-suite/templates/components/form-ui/checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cCCY191V",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,0],[[32,0,[\"showComponent\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[10,\"div\"],[15,0,[31,[\"checkbox-field \",[30,[36,0],[[32,0,[\"noClass\"]],\"\",\"form-field overflow-float\"],null]]]],[12],[2,\"\\n\\n  \"],[10,\"label\"],[15,0,[31,[\"form-field--label inline-checkbox \",[30,[36,0],[[32,0,[\"isDisabled\"]],\"disabled\"],null],\" form-ui-element\"]]],[12],[2,\"\\n\\n    \"],[10,\"input\"],[14,0,\"form-field--control\"],[15,\"disabled\",[32,0,[\"isDisabled\"]]],[15,\"checked\",[32,0,[\"value\"]]],[15,\"onclick\",[30,[36,1],[[32,0],\"checkboxClicked\"],null]],[14,4,\"checkbox\"],[12],[13],[2,\"\\n    \"],[1,[32,0,[\"label\"]]],[2,\"\\n\\n\"],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[6,[37,0],[[32,0,[\"params\",\"tooltip\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"form-ui/tooltip\",[],[[\"@tooltip\",\"@tooltipIcon\",\"@side\",\"@event\"],[[32,0,[\"params\",\"tooltip\"]],[32,0,[\"params\",\"tooltipIcon\"]],[32,0,[\"params\",\"tooltipSide\"]],[32,0,[\"params\",\"tooltipEvent\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"dev-tools hidden-formui-component\"],[12],[2,\"CHECKBOX component hidden\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[8,\"form-ui/dev-tools\",[],[[\"@outputs\"],[[30,[36,2],null,[[\"componentName\",\"labelAutoTranslated\",\"propertyName\",\"isDisabled\",\"invert\",\"rawValue\",\"value\",\"bitIndex\",\"isBitField\",\"dataTypeDetected\"],[\"checkbox\",[32,0,[\"labelAutoTranslated\"]],[32,0,[\"propertyName\"]],[32,0,[\"isDisabled\"]],[32,0,[\"params\",\"invert\"]],[32,0,[\"rawValue\"]],[32,0,[\"value\"]],[32,0,[\"params\",\"bitIndex\"]],[32,0,[\"isBitField\"]],[32,0,[\"dataTypeDetected\"]]]]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"action\",\"hash\"]}",
    "moduleName": "alliance-business-suite/templates/components/form-ui/checkbox.hbs"
  });

  _exports.default = _default;
});