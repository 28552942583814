define("alliance-business-suite/services/can-access", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    // New stuff for custom security roles
    // TODO: Make sure the right security role data is being used (employee vs. user, etc.)
    store: Ember.inject.service(),
    securityDebugging: false,

    listRoles() {// console.log('Security Role', get(this, 'userSecurityRoleName'));
      // console.log('Functional Areas', get(this, 'userSecurityFunctionalAreas'));
    },

    userSecurityRoleName: Ember.computed('employee.firstObject.securityRoles.firstObject.name', function () {
      let me = Ember.get(this, 'store').peekAll('employee');
      let roleName = Ember.get(me, 'firstObject.securityRoles.firstObject.name');
      return roleName;
    }),
    // Returns array of lookupKey strings for the current user
    userSecurityFunctionalAreas: Ember.computed('employee.firstObject.securityRoles.firstObject.securityFunctionalAreas.[]', function () {
      let me = Ember.get(this, 'store').peekAll('employee');
      let sfa = '';

      if (me) {
        let meFirstObject = me.get('firstObject');

        if (meFirstObject) {
          let securityRoles = meFirstObject.get('securityRoles');

          if (securityRoles) {
            let securityFirstObject = securityRoles.get('firstObject');

            if (securityFirstObject) {
              sfa = securityFirstObject.get('securityFunctionalAreas').mapBy('lookupKey');
            }
          }
        }
      }

      return sfa;
    }),

    canAccess(lookupKey) {
      let userSecurityFunctionalAreas = Ember.get(this, 'userSecurityFunctionalAreas');
      return userSecurityFunctionalAreas.includes(lookupKey.toUpperCase());
    },

    canAccessAny(lookupKeys) {
      if (!lookupKeys) {
        console.log('No lookup keys found'); // eslint-disable-line no-console

        return false;
      }

      let lookupKeysArray = typeof lookupKeys === 'string' ? lookupKeys.split(',') : lookupKeys;
      let userSecurityFunctionalAreas = Ember.get(this, 'userSecurityFunctionalAreas');
      let canAccess = false;

      if (lookupKeysArray) {
        lookupKeysArray.forEach(lookupKey => {
          if (userSecurityFunctionalAreas.includes(lookupKey.toUpperCase())) {
            canAccess = true;
          }
        });
      }

      return canAccess;
    },

    canAccessAll(lookupKeys) {
      let lookupKeysArray = lookupKeys.split(',');
      let userSecurityFunctionalAreas = Ember.get(this, 'userSecurityFunctionalAreas');
      let checkAccess = true; // We must have lookupKeys to work with

      if (lookupKeys.length == 0) {
        return false;
      }

      lookupKeysArray.forEach(lookupKey => {
        if (!userSecurityFunctionalAreas.includes(lookupKey.toUpperCase())) {
          checkAccess = false;
        }
      });
      return checkAccess;
    },

    checkIfAllowed(lookupKeys) {
      return this.canAccessAll(lookupKeys);
    }

  });

  _exports.default = _default;
});