define("alliance-business-suite/templates/components/table/table-th-with-subtitle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6gfWxoOH",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[2,\"bibo\\n\\n\"],[18,1,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "alliance-business-suite/templates/components/table/table-th-with-subtitle.hbs"
  });

  _exports.default = _default;
});