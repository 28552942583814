define("alliance-business-suite/services/brand", ["exports", "alliance-business-suite/config/environment", "alliance-business-suite/services/brand-settings"], function (_exports, _environment, _brandSettings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const changeFavicon = link => {
    // Update <link rel="icon"> element or create a new one.
    let $favicon = document.querySelector('link[rel="icon"]');

    if ($favicon !== null) {
      $favicon.href = link;
    } else {
      $favicon = document.createElement("link");
      $favicon.rel = "icon";
      $favicon.href = link;
      document.head.appendChild($favicon);
    }
  };

  const momentLocaleMap = {
    'cs-cz': 'cs',
    'de': 'de-DE',
    'en-us': 'en-US',
    'es': 'es-MX',
    'es-co': 'es-MX',
    'fr': 'fr-FR',
    'id': 'en-US',
    'ita': 'it-IT',
    'jpn': 'jpn',
    'ko': 'en-US',
    'ms': 'en-US',
    'nl-nl': 'en-US',
    'pt': 'en-US',
    'ru': 'en-US',
    'th': 'th',
    'zh-cn': 'zh-CN'
  };
  const appName = {
    'sq': 'Speed Queen Insights',
    'huebsch': 'Huebsch Command',
    'primus': 'Primus iTrace',
    'ipso': 'IPSO Connect',
    'unimac': 'UniMac Core'
  };
  const appUrl = {
    'sq': 'sqinsights',
    'huebsch': 'huebschcommand',
    'primus': 'primusitrace',
    'ipso': 'ipsoconnect',
    'unimac': 'unimaccore'
  };

  var _default = Ember.Service.extend({
    intl: Ember.inject.service(),

    // Get config.brand and lookup in brandConfig to load the right settings. This allows us to
    // switch the branding while while the app is running...but other brand-specific settings
    // like keys, firebase, etc., DO NOT CHANGE
    init() {
      this._super(...arguments);

      this.loadBrand(_environment.default.brand);
    },

    initBrand: Ember.computed(function () {
      // Returns the brand that Ember was started with.
      return _environment.default.brand;
    }),
    name: Ember.computed.alias('currentBrand.name'),
    abbrev: Ember.computed.alias('currentBrand.abbrev'),
    slug: Ember.computed.alias('currentBrand.slug'),
    topLevelDomain: Ember.computed('slug', function () {
      return appUrl[this.slug];
    }),

    lookupKey(key) {
      // Returns the brand-specific value in the brand-settings.js file
      let keyValues = _brandSettings.lookupTable[key];

      if (keyValues) {
        let abbrev = this.abbrev.toLowerCase();
        let value = keyValues[abbrev];

        if (Ember.isNone(value)) {
          console.warn("Missing brand lookup:", key); // eslint-disable-line no-console
        }

        return value;
      }
    },

    b(key, replaceText, makeHtmlSafe) {
      let userLocale = this.get('intl.locale')[0]; // Returns the brand-specific value in the translation file or brand-settings.js file

      let lookupKey = `BRAND.${this.slug}.${key}`; // the document.title needs to be set before remote localization have been fetched

      if (key == "app_name") {
        return appName[this.slug];
      }

      if (this.intl.exists(lookupKey)) {
        let output = this.intl.t(lookupKey);
        return makeHtmlSafe ? Ember.String.htmlSafe(output) : output;
      } else {
        // Return the brand-specific value of the key provided
        let output = this.lookupKey(key); // Replace any string placeholders

        if (output) {
          if (replaceText) {
            output = output.replace("REPLACE_STRING", replaceText);
          }

          if (output.includes("REPLACE_LOCALE")) {
            output = output.replace("REPLACE_LOCALE", `${momentLocaleMap[userLocale]}`);
          }
        }

        if (!output) {
          let defaultLocaleFallback = this.intl.lookup(lookupKey, 'en-us', {
            resilient: true
          });
          output = defaultLocaleFallback;
          return makeHtmlSafe ? Ember.String.htmlSafe(output) : output;
        }

        return output;
      }
    },

    isBrand(id) {
      // Check if the id passed in is the current brand
      return Ember.get(this, 'currentBrand.isBrandKeys').includes(id.toLowerCase());
    },

    loadBrand(id) {
      let settings = _brandSettings.brandConfig.findBy("id", id.toUpperCase()); // TODO: Deal with error condition if not found.


      Ember.set(this, 'currentBrand', settings);
      this.notifyPropertyChange('currentBrand'); // Add brand class to body tag

      _brandSettings.brandConfig.mapBy('slug').forEach(slug => {
        let pattern = new RegExp("(^|\\s)theme--" + slug + "\\b", 'g');
        document.body.className = document.body.className.replace(pattern, '');
      });

      document.body.className += ` theme--${this.slug}`; // Document title and favicon

      document.title = this.b('app_name');
      changeFavicon(this.lookupKey('favicon'));
    }

  });

  _exports.default = _default;
});