define("alliance-business-suite/templates/components/rewards/rewards-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VnqhPs8i",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"section--container-v2\"],[12],[2,\"\\n  \"],[10,\"header\"],[14,0,\"ui-route-header\"],[12],[2,\"\\n    \"],[10,\"h2\"],[12],[2,\"\\n      \"],[1,[30,[36,2],[\"reward_programs\"],null]],[2,\"\\n      \"],[10,\"span\"],[14,0,\"ui-route-header__subtitle\"],[12],[2,\"\\n        \"],[1,[30,[36,2],[\"rewards_programs_subtitle\"],null]],[2,\".\\n\"],[6,[37,1],[[35,3,[\"isEnglish\"]]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"a\"],[14,6,\"https://alliancelabs-assets-production.s3.us-east-1.amazonaws.com/common/resources/setting-up-rewards-in-the-owner-portal/story.html\"],[14,\"target\",\"_blank\"],[12],[2,\"View tutorial\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n\"],[6,[37,1],[[30,[36,4],[[32,0,[\"access\",\"isDemo\"]]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[30,[36,0],[\"LOCATIONS_MANAGE_REWARDS_PROGRAM\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"controls controls--right\"],[12],[2,\"\\n          \"],[8,\"link-to\",[[24,0,\"primary\"]],[[\"@route\",\"@tagName\"],[\"locations.rewards.create\",\"button\"]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[10,\"i\"],[14,0,\"fa fa-plus\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n          \"]],\"parameters\":[]}]]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\\n  \"],[8,\"rewards/rewards-list-table\",[],[[\"@rewards\"],[[34,5]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"can-access\",\"if\",\"t\",\"access\",\"not\",\"model\"]}",
    "moduleName": "alliance-business-suite/templates/components/rewards/rewards-section.hbs"
  });

  _exports.default = _default;
});