define("alliance-business-suite/components/ui/als-currency-input", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "autonumeric", "alliance-business-suite/utils/fix-range"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _autonumeric, _fixRange) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
    {{did-insert this.initialLoad}}
    {{did-update this.didUpdateParent @currencySymbol @decimalPlaces @divisor @currencySymbolPlacement @decimalCharacter}}
    class={{if @suffix "input-group"}}>
    <Input
      id={{this.inputId}}
      type="text"
      @value={{this.formattedValue}}
      disabled={{@disabled}}
      {{on "input" this.updateVal}}
    />
    {{#if @suffix}}
      <div class="input-append">{{@suffix}}</div>
    {{/if}}
  </div>
  
  */
  {
    "id": "1eLgiD9V",
    "block": "{\"symbols\":[\"@suffix\",\"@decimalCharacter\",\"@currencySymbolPlacement\",\"@divisor\",\"@decimalPlaces\",\"@currencySymbol\",\"@disabled\"],\"statements\":[[11,\"div\"],[16,0,[30,[36,0],[[32,1],\"input-group\"],null]],[4,[38,1],[[32,0,[\"initialLoad\"]]],null],[4,[38,2],[[32,0,[\"didUpdateParent\"]],[32,6],[32,5],[32,4],[32,3],[32,2]],null],[12],[2,\"\\n  \"],[8,\"input\",[[16,1,[32,0,[\"inputId\"]]],[16,\"disabled\",[32,7]],[24,4,\"text\"],[4,[38,3],[\"input\",[32,0,[\"updateVal\"]]],null]],[[\"@value\"],[[32,0,[\"formattedValue\"]]]],null],[2,\"\\n\"],[6,[37,0],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"input-append\"],[12],[1,[32,1]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"did-insert\",\"did-update\",\"on\"]}",
    "moduleName": "alliance-business-suite/components/ui/als-currency-input.hbs"
  });
  /* globals Big */


  // @allowNull: false (default)
  //    in some cases we want to differentiate between 0 and null
  //    by default, we will make return 0 always
  // @min: 0 (default)
  // @disabled: false
  // @emptyInput available options from AutoNumeric: [focus, press, always, min, max, zero]
  // @currencySymbol example "$", "£"
  let UiAlsCurrencyInputComponent = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, (_class = class UiAlsCurrencyInputComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _defineProperty2.default)(this, "inputId", 'textInput-' + Ember.guidFor(this));
      (0, _initializerDefineProperty2.default)(this, "formattedValue", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "inputElem", _descriptor3, this);
    }

    get apiValue() {
      let newValue = this.inputElem.getNumericString();

      if (newValue !== null) {
        newValue = parseInt(parseFloat(new Big(newValue).times(new Big(this.divisor))));
      } // when allowNull, will make value "null" instead of "0"


      if (newValue === '0' || newValue === 0 || newValue.length == 0) {
        newValue = this.args.allowNull ? null : 0;
      }

      return newValue;
    }

    get divisor() {
      if (this.args.overrideValue === 'machine_program') {
        return parseFloat(new Big(10).pow(this.decimalPlaces));
      } else {
        return this.args.divisor ?? this.access.currencySubunit;
      }
    }

    get min() {
      return this.args.min ? this.args.min / this.divisor : "0";
    }

    get max() {
      return this.args.max ? this.args.max / this.divisor : "9999999";
    }

    get emptyInput() {
      return this.args.emptyInput ? this.args.emptyInput : "zero";
    }

    get currencySymbol() {
      return this.args.currencySymbol ?? this.access.currencyDenomination;
    }

    get decimalPlaces() {
      return this.args.decimalPlaces ?? this.access.currencyDecimalPlaces ?? 2; // ?? enforces 0 isn't false
    }

    get decimalCharacter() {
      return this.args.decimalCharacter ?? ".";
    }

    get digitGroupSeparator() {
      return this.args.digitGroupSeparator ?? ",";
    }

    get currencySymbolPlacement() {
      if (this.args.currencySymbolPlacement === "before") {
        return "p";
      }

      if (this.args.currencySymbolPlacement === "after") {
        return "s";
      }

      return this.args.currencySymbolPlacement ?? "p";
    }

    setInitialValue() {
      if (this.args.overrideValue === 'machine_program') {
        this.formattedValue = parseInt(this.args.value) / 10 ** this.decimalPlaces;
      } else {
        // enforce the minimum value
        if (this.args.value !== 0 && (!this.args.value || this.args.value < this.min)) {
          this.formattedValue = this.args.allowNull ? null : this.min / this.divisor;
          this.args.onUpdate(this.formattedValue);
        } else {
          this.formattedValue = this.args.value ? this.args.value / this.divisor : 0;
        }
      }

      setTimeout(() => {
        this.initAutoNumeric();
      }, 200);
    }

    initAutoNumeric() {
      try {
        this.inputElem = new _autonumeric.default(`#${this.inputId}`, {
          caretPositionOnFocus: "start",
          currencySymbol: this.currencySymbol,
          currencySymbolPlacement: this.currencySymbolPlacement,
          decimalCharacter: this.decimalCharacter,
          digitGroupSeparator: this.digitGroupSeparator,
          historySize: 5,
          maximumValue: this.max,
          minimumValue: this.min,
          decimalPlaces: this.decimalPlaces,
          emptyInputBehavior: this.emptyInput
        });
      } catch (e) {
        console.info(e);
      }
    }

    initialLoad() {
      this.setInitialValue();
    }

    didUpdateParent() {
      const result = (0, _fixRange.fixRangeWithDetails)(this.apiValue, this.min, this.max); // in machine programming it is possible to change decimal places
      // in the event of an error because of this, we just set it 0

      if (!result.withinRange) {
        // console.error('will error', result.withinRange, this.inputElem)
        this.inputElem.set('0');
      }

      this.inputElem.update({
        caretPositionOnFocus: "start",
        currencySymbol: this.currencySymbol,
        currencySymbolPlacement: this.currencySymbolPlacement,
        decimalCharacter: this.decimalCharacter,
        digitGroupSeparator: this.digitGroupSeparator,
        historySize: 5,
        maximumValue: this.max,
        minimumValue: this.min,
        decimalPlaces: this.decimalPlaces,
        emptyInputBehavior: this.emptyInput
      });
      this.updateVal();
    }

    updateVal() {
      this.args.onUpdate(this.apiValue);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "formattedValue", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "inputElem", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "initialLoad", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "initialLoad"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "didUpdateParent", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "didUpdateParent"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "updateVal", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "updateVal"), _class.prototype)), _class));
  _exports.default = UiAlsCurrencyInputComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, UiAlsCurrencyInputComponent);
});