define("alliance-business-suite/helpers/utc-format", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const momentLocaleFormatMap = {
    'cs-cz': 'LL',
    'de': 'LL',
    'en-us': 'MMMM D, YYYY',
    'es': 'LL',
    'es-co': 'LL',
    'fr': 'LL',
    'id': 'LL',
    'ita': 'LL',
    'jpn': 'LL',
    'ko': 'LL',
    'ms': 'LL',
    'nl-nl': 'LL',
    'pt': 'LL',
    'ru': 'LL',
    'th': 'LL',
    'zh-cn': 'LL'
  };

  var _default = Ember.Helper.extend({
    access: Ember.inject.service(),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),

    compute(params, namedArgs) {
      let date = params[0];

      if ((Ember.isEmpty(date) || Ember.isBlank(date)) && namedArgs.showMissingPlaceholder) {
        return Ember.String.htmlSafe("&ndash;");
      }

      let showTimeZone = namedArgs.displayTimeZone;
      let userId = Ember.get(this, 'access.accessUser.id');
      let locale = Ember.get(this, 'intl.locale');
      let user = Ember.get(this, 'store').peekRecord('employee', userId);
      let userTimeFormat = showTimeZone ? `${user.get('timeFormat')} A z` : `${user.get('timeFormat')} A`;
      let localMomentFormat = momentLocaleFormatMap[locale[0]];

      if (user.get('timeFormat') === 'HH:mm') {
        userTimeFormat = showTimeZone ? `${user.get('timeFormat')} z` : `${user.get('timeFormat')}`;
      }

      return _moment.default.utc(date).tz(_moment.default.tz.guess()).format(`${localMomentFormat} ${userTimeFormat}`);
    }

  });

  _exports.default = _default;
});