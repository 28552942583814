define("alliance-business-suite/components/sidebar-panel", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3;

  let SidebarPanelComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.computed('brush.{scrub,state}'), _dec5 = Ember._action, (_class = class SidebarPanelComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "brush", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "session", _descriptor3, this);
    }

    /**
     * The search query, which is synced to the brush state to participate in the
     * browser history.
     *
     * @type {string}
     * @public
     */
    get query() {
      return this.brush.scrub === 'search' ? this.brush.state : undefined;
    }

    set query(value) {
      let {
        brush
      } = this;
      let {
        scrub
      } = brush;

      if (!Ember.isBlank(value)) {
        if (scrub === 'search') {
          brush.replaceWith('search', undefined, value);
        } else {
          brush.transitionTo('search', undefined, value);
        }
      } else {
        brush.back();
      }

      return value;
    }

    clearSearch() {
      this.brush.transitionTo('default', null);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "brush", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "session", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "query", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "query"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "clearSearch", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "clearSearch"), _class.prototype)), _class));
  _exports.default = SidebarPanelComponent;
});