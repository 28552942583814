define("alliance-business-suite/components/ui/section-panel-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const componentClassName = 'ui-section-panel-header';

  var _default = Ember.Component.extend({
    /**
     * The "block" component class name for this component in the
     * Block-Element-Modifier (BEM) naming convention.
     *
     * @type {Array<String>}
     * @public
     */
    componentClassName,

    /**
     * The static class names for this component.
     *
     * @type {Array<String>}
     * @public
     */
    classNames: [componentClassName],

    /**
     * The dynamic class names for this component that are derived from component
     * properties.
     *
     * @type {Array<String>}
     * @public
     */
    classNameBindings: ['typeClassName'],

    /**
     * The class name that specifies the horizontal alignment of this pill.
     *
     * @type {String}
     * @public
     */
    typeClassName: Ember.computed('type', {
      get() {
        let type = this.type;
        return type ? `${componentClassName}--${type}` : undefined;
      }

    }),

    /**
     * The tag name used for this component; an empty string prevents a DOM
     * element from being created, instead rendering the template for this
     * component directly underneath its parent.
     *
     * @protected
     */
    tagName: 'header',
    displayCheckbox: Ember.computed('showCheckbox', 'checkbox', function () {
      // Show checkbox by default unless `false` is passed in.
      if (this.showCheckbox === false || this.checkbox === undefined) {
        return false;
      } else {
        return true;
      }
    })
  });

  _exports.default = _default;
});