define("alliance-business-suite/components/table/table-cell-guideline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    hasInventory: Ember.computed('record', 'record.{activeInventory,quantity}', function () {
      let record = this.record;

      if (record.get(this.get('column.propertyName'))) {
        return true;
      }

      return false;
    }),
    alertThreshold: Ember.computed('column.alertThreshold', 'record.{activeInventory.inventoryGuideline.reorderBelowLevel,inventoryGuideline.reorderBelowLevel}', function () {
      let threshold = 100;
      let record = this.record;

      if (this.get('column.alertThreshold')) {
        threshold = record.get(this.get('column.alertThreshold'));
      }

      return threshold;
    }),
    progressPercent: Ember.computed('column.propertyName', 'record.{quantity,activeInventory.quantity}', 'alertThreshold', function () {
      let record = this.record;
      let inverted = this.get('column.inverted');
      let currentAmount = record.get(this.get('column.propertyName'));
      let threshold = this.alertThreshold;

      if (inverted) {
        return parseFloat(threshold / currentAmount * 100).toFixed(2);
      } else {
        return parseFloat(currentAmount / threshold * 100).toFixed(2);
      }
    }),
    progressBar: Ember.computed('progressPercent', function () {
      let percent = this.progressPercent;

      if (percent > 100) {
        return 1;
      } else if (percent < 0) {
        return 0;
      }

      return percent / 100;
    }),
    cssColor: Ember.computed('progressPercent', function () {
      let progressPercent = this.progressPercent;
      let inverted = this.get('column.inverted');
      let color = 'green';

      if (inverted) {
        if (progressPercent >= 75) {
          color = 'red';
        } else if (progressPercent >= 50) {
          color = 'orange';
        }
      } else {
        if (progressPercent <= 25) {
          color = 'red';
        } else if (progressPercent <= 50) {
          color = 'orange';
        }
      }

      return color;
    }),
    actions: {
      go() {
        let params = [];
        let fnCall = this.get('column.action');

        if (typeof fnCall === 'function') {
          if (this.get('column.params')) {
            this.get('column.params').forEach(param => {
              if (this.get(param)) {
                params.push(this.get(param));
              } else {
                params.push(param);
              }
            });
          }

          fnCall.call(this, ...params);
        }
      }

    }
  });

  _exports.default = _default;
});