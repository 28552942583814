define("alliance-business-suite/adapters/rewards-program-schedule-day", ["exports", "alliance-business-suite/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    buildURL(modelName, id, snapshot, requestType, query) {
      let url = this._super('', null, snapshot, requestType, query);

      let programId = Ember.get(snapshot, 'adapterOptions.programId');

      switch (requestType) {
        case 'createRecord':
          url += `/rewardsPrograms/${programId}/rewardsProgramScheduleDays`;
          return url;

        case 'deleteRecord':
          url += `/rewardsPrograms/${programId}/rewardsProgramScheduleDays/${id}`;
          return url;

        case 'updateRecord':
          url += `/rewardsPrograms/${programId}/rewardsProgramScheduleDays/${id}`;
          return url;
      }

      return url;
    }

  });

  _exports.default = _default;
});