define("alliance-business-suite/controllers/machines/programs/manage", ["exports", "alliance-business-suite/components/machines/programs/variables", "blueimp-md5"], function (_exports, _variables, _blueimpMd) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    machineProgram: Ember.computed.alias('model'),
    access: Ember.inject.service(),
    intl: Ember.inject.service(),
    brush: Ember.inject.service(),
    machines: Ember.inject.service(),

    didInsertElement() {
      this._super(...arguments);
    },

    foundErrors: Ember.computed('model.settingBytes.Message', function () {
      return this.model.transcoderError !== undefined;
    }),
    showErrorDetails: Ember.computed('model.transcoderError', function () {
      if (window.location.hostname === 'localhost') {
        return `<br>${this.model.transcoderError}`;
      } else {
        return null;
      }
    }),

    allowGroupChange(groups, value1, value2) {
      // Warn the user if changing the size of the machine will change other values in the program and
      // confirm the change.
      let oldGroup, newGroup;
      groups.forEach((group, index) => {
        if (group.includes(Number(value1))) {
          oldGroup = index;
        }

        if (group.includes(Number(value2))) {
          newGroup = index;
        }
      });

      if (oldGroup !== newGroup) {
        if (!confirm(this.intl.t("confirm_machine_type_change"))) {
          return false; // Do not change the current machineType
        }
      }

      return true;
    },

    machineTypes: Ember.computed('model.machineType.generalMachineType.code', function () {
      let collection = this.store.peekAll('machine-type');
      let compatible = this.model.compatibleMachineTypes;
      let output = collection.filter(item => {
        return compatible.includes(Number(item.id));
      });
      return output.sortBy('ordinal');
    }),
    showSize: Ember.computed('model.machineType.generalMachineType.code', function () {
      let code = Ember.get(this, 'model.machineType.generalMachineType.code').toUpperCase();
      return ['WX', 'TUM', 'CBO'].includes(code);
    }),
    selectActiveTab: Ember.on('init', Ember.observer('model.settings', function () {
      // eslint-disable-line
      if (Ember.get(this, "model.settings")) {
        let lastTab = localStorage.getItem('activeSection') || Ember.get(this, 'model.tabList.firstObject.name') || Ember.get(this, 'model.tabList.firstObject') || 'pricing'; // Check that this machine has the tab we found

        let programSections = this.model.tabList;
        let activeFilename; // This can be an array of strings or objects

        if (Ember.typeOf(this.model.tabList[0]) === "string") {
          if (!programSections.includes(lastTab)) {
            lastTab = programSections[0] || null;
          }

          Ember.set(this, 'activeTabName', lastTab);
          Ember.set(this, 'activeFilename', this.model.filename);
        } else {
          if (!programSections.findBy('name', lastTab)) {
            lastTab = programSections[0] || null;
          } else {
            lastTab = programSections.findBy('name', lastTab);
          }

          Ember.set(this, 'activeTabName', lastTab.name);
          Ember.set(this, 'activeFilename', lastTab.filename || this.model.filename);
        }
      }
    })),
    selectNavigationType: Ember.on('init', Ember.observer('model.settings', function () {
      if (Ember.get(this, 'model.settings')) {
        let tabView = localStorage.getItem('tabView');

        if (tabView === null) {
          Ember.set(this, 'navigationType', 0);
          localStorage.setItem('tabView', '0');
        }

        if ('01'.indexOf(tabView) >= 0) {
          Ember.set(this, 'navigationType', Number(tabView));
        }
      }
    })),
    actions: {
      defaultBrush() {
        this.brush.transitionTo('default', null);
      },

      saved() {
        if (this.model.transcoderError) {
          console.log('TRANSCODER ERROR:', this.model.transcoderError);
          alert(t.intl("problem_saving_machine_program"));
        }

        Ember.set(this.machines, "lastProgramAfterSave", this.model);
        Ember.set(this.machines, "lastProgramSavedAt", moment());
        this.transitionToRoute('machines.programs');
      },

      deleted() {
        this.transitionToRoute('machines.programs');
      },

      save() {
        if (this.access.isDemo) {
          alert('Demo mode restricted.');
          return;
        }
        /*
          We need to revalidate machine settings before we save them. This is a big
          problem for MC10, but may also be a concern for existing machine programs.
        */


        this.model.validateDataOnSave();
        Ember.set(this.machines, "lastProgramBeforeSave", this.model);
        return this.model.save();
      },

      gotoSend() {
        Ember.set(this.machines, "lastProgramAfterSave", this.model);
        Ember.set(this.machines, "lastProgramSavedAt", moment());
        this.transitionToRoute('machines.programs.send', this.model.id);
      },

      cancel() {
        this.model.rollbackAttributes();
        this.transitionToRoute('machines.programs');
      },

      delete() {
        if (this.access.isDemo) {
          alert('Demo mode restricted.');
          return;
        }

        let message = `${this.intl.t('confirm_delete_program')} "${this.model.name}"?`;

        if (confirm(message)) {
          return this.model.destroyRecord();
        }
      },

      changeSection(section) {
        // section can be a string or an object with name and filename properties
        if (Ember.typeOf(section) === 'string') {
          localStorage.setItem('activeSection', section);
          Ember.set(this, 'activeTabName', section);
          Ember.set(this, 'activeFilename', this.model.filename);
        } else {
          localStorage.setItem('activeSection', section.name);
          Ember.set(this, 'activeTabName', section.name);
          Ember.set(this, 'activeFilename', section.filename || this.model.filename);
        }
      },

      toggleNavigation() {
        let navigationType = Ember.get(this, 'navigationType') === 0 ? 1 : 0;
        Ember.set(this, 'navigationType', navigationType);
        localStorage.setItem('tabView', `${navigationType}`);
      },

      changeMachineType(newType) {
        // We'll notify the user if changing the size of the machine will force changes in other properties,
        // like water temp or rotation speeds, and confirm they want to make the change.
        let currentId = Ember.get(this.machineProgram, "machineType.id");
        let allowChange = true;

        switch (true) {
          case this.machineProgram._machineCodeFamily === "MGD_OPL_TUM":
            allowChange = this.allowGroupChange(_variables.default.MGD_OPL.sizeGroups, currentId, newType.id);
            break;

          case this.machineProgram._machineCodeFamily === "MC10_OPL_WX":
            allowChange = this.allowGroupChange(_variables.default.MC10_OPL.sizeGroups, currentId, newType.id);
            break;

          case this.machineProgram._machineCodeFamily === "MC5_EC_TUM":
            allowChange = this.allowGroupChange(_variables.default.MC5_EC.sizeGroups, currentId, newType.id);
            break;
        }

        if (allowChange) {
          Ember.set(this.machineProgram, "machineType", newType);
        }
      }

    }
  });

  _exports.default = _default;
});