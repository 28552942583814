define("alliance-business-suite/components/brushes/customer-brush", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * The tag name used for this component. Since this just proxies through to
     * other components, this is an empty string. This prevents a DOM element
     * from being created, instead rendering the template for this component
     * directly underneath its parent.
     *
     * @type {string}
     * @protected
     */
    tagName: '',

    /**
     * The service used to manage the brush.
     *
     * @protected
     */
    brush: Ember.inject.service(),

    /**
     * The default mode for this brush.
     *
     * @type {string}
     *
     * @public
     */
    defaultMode: 'show',

    /**
     * Indicates the mode this brush is in:
     *
     * `edit`
     * : A single model instance is being created or edited.
     *
     * `show`
     * : A single model instance is being showed.
     *
     * If the value is set to null or undefined, the mode will be set to the
     * `defaultMode`.
     *
     * TODO: Convert this into an enum to ensure only valid modes can be set.
     *       It can also be 'manage'.
     *      [twl 30.Mar.17]
     *
     * @type {string}
     *
     * @public
     */
    mode: Ember.computed('brush.state', {
      get() {
        return Ember.get(this, 'brush.state') || Ember.get(this, 'defaultMode');
      },

      set(key, value) {
        let defaultMode = Ember.get(this, 'defaultMode'); // Clear the brush state if we're in the default mode

        this.set('brush.state', value !== defaultMode ? value : undefined);
        return value || defaultMode;
      }

    }),

    /**
     * The actions for this component.
     *
     * @public
     */
    actions: {
      /**
       * Handles when a managing is done for a model.
       *
       * @public
       */
      doneManaging() {
        Ember.set(this, 'mode', 'show');
      }

    }
  });

  _exports.default = _default;
});