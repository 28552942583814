define("alliance-business-suite/components/summary/reports/s-utility-consumption", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "alliance-business-suite/utils/currency-format-util"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _currencyFormatUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  let SummaryReportsSUtilityConsumptionComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, (_class = class SummaryReportsSUtilityConsumptionComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor3, this);
    }

    get totalsData() {
      let totals = {};
      totals.orgTotalIncome = _currencyFormatUtil.formatCurrencyString.call(this, this.args.model.orgTotalIncome || 0);
      totals.orgTotalExpenses = _currencyFormatUtil.formatCurrencyString.call(this, this.args.model.orgTotalExpenses || 0);
      totals.orgNetIncome = _currencyFormatUtil.formatCurrencyString.call(this, this.args.model.orgNetIncome || 0);
      return totals;
    }

    get utilityConsumptionRoomData() {
      let orgRooms = this.args.model.orgRooms;
      return orgRooms.map(rooms => {
        rooms.dryerTumblerIncome = _currencyFormatUtil.formatCurrencyString.call(this, rooms.dryerTumblerIncome || 0);
        rooms.frontloadWasherIncome = _currencyFormatUtil.formatCurrencyString.call(this, rooms.frontloadWasherIncome || 0);
        rooms.toploadWasherIncome = _currencyFormatUtil.formatCurrencyString.call(this, rooms.toploadWasherIncome || 0);
        rooms.washerExtractorIncome = _currencyFormatUtil.formatCurrencyString.call(this, rooms.washerExtractorIncome || 0);
        rooms.washDryFoldIncome = _currencyFormatUtil.formatCurrencyString.call(this, rooms.washDryFoldIncome || 0);
        rooms.vendingIncome = _currencyFormatUtil.formatCurrencyString.call(this, rooms.vendingIncome || 0);
        rooms.otherOneIncome = _currencyFormatUtil.formatCurrencyString.call(this, rooms.otherOneIncome || 0);
        rooms.otherTwoIncome = _currencyFormatUtil.formatCurrencyString.call(this, rooms.otherTwoIncome || 0);
        rooms.totalIncome = _currencyFormatUtil.formatCurrencyString.call(this, rooms.totalIncome || 0);
        rooms.rent = _currencyFormatUtil.formatCurrencyString.call(this, rooms.rent || 0);
        rooms.taxes = _currencyFormatUtil.formatCurrencyString.call(this, rooms.taxes || 0);
        rooms.gas = _currencyFormatUtil.formatCurrencyString.call(this, rooms.gas || 0);
        rooms.water = _currencyFormatUtil.formatCurrencyString.call(this, rooms.water || 0);
        rooms.electric = _currencyFormatUtil.formatCurrencyString.call(this, rooms.electric || 0);
        rooms.sewer = _currencyFormatUtil.formatCurrencyString.call(this, rooms.sewer || 0);
        rooms.services = _currencyFormatUtil.formatCurrencyString.call(this, rooms.services || 0);
        rooms.labor = _currencyFormatUtil.formatCurrencyString.call(this, rooms.labor || 0);
        rooms.rewardsSpent = _currencyFormatUtil.formatCurrencyString.call(this, rooms.rewardsSpent || 0);
        rooms.debtOne = _currencyFormatUtil.formatCurrencyString.call(this, rooms.debtOne || 0);
        rooms.debtTwo = _currencyFormatUtil.formatCurrencyString.call(this, rooms.debtTwo || 0);
        rooms.insurance = _currencyFormatUtil.formatCurrencyString.call(this, rooms.insurance || 0);
        rooms.maintenance = _currencyFormatUtil.formatCurrencyString.call(this, rooms.maintenance || 0);
        rooms.marketing = _currencyFormatUtil.formatCurrencyString.call(this, rooms.marketing || 0);
        rooms.supplies = _currencyFormatUtil.formatCurrencyString.call(this, rooms.supplies || 0);
        rooms.otherOne = _currencyFormatUtil.formatCurrencyString.call(this, rooms.otherOne || 0);
        rooms.otherTwo = _currencyFormatUtil.formatCurrencyString.call(this, rooms.otherTwo || 0);
        rooms.totalExpenses = _currencyFormatUtil.formatCurrencyString.call(this, rooms.totalExpenses || 0);
        return rooms;
      });
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SummaryReportsSUtilityConsumptionComponent;
});