define("alliance-business-suite/components/scrub/brush-history", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    gotoItem(index) {
      this.brush.goto(index);
    },

    actions: {
      clearItems() {
        this.brush.clear();
      }

    }
  });

  _exports.default = _default;
});