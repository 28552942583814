define("alliance-business-suite/components/header-sub-nav", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3;

  let HeaderSubNavComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.computed('router.currentRoute'), _dec5 = Ember.computed('canAccess.securityDebugging'), (_class = class HeaderSubNavComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "brand", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "canAccess", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "router", _descriptor3, this);
      (0, _defineProperty2.default)(this, "accessRules", {
        'facilities.wash-alert': {
          allow: ['PERSON_ALLIANCE_ADMIN']
        }
      });
    }

    get currentRouteName() {
      return this.router.currentRoute.name;
    }

    get securityDebugging() {
      return this.canAccess.securityDebugging;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "brand", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "canAccess", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "currentRouteName", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "currentRouteName"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "securityDebugging", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "securityDebugging"), _class.prototype)), _class));
  _exports.default = HeaderSubNavComponent;
});