define("alliance-business-suite/components/ui/inventory-quantity-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    tagName: '',
    alertThreshold: Ember.computed('activeItem.itemGuideline.reorderBelowLevel', function () {
      return this.get('activeItem.itemGuideline.reorderBelowLevel');
    }),
    progressPercent: Ember.computed('currentAmount', 'alertThreshold', function () {
      let currentAmount = this.currentAmount;
      let threshold = this.alertThreshold;
      return parseFloat(currentAmount / threshold * 100).toFixed(2);
    }),
    invertedProgress: Ember.computed('currentAmount', 'alertThreshold', function () {
      let currentAmount = this.currentAmount;
      let threshold = this.alertThreshold;
      return parseFloat(threshold / currentAmount * 100).toFixed(2);
    }),
    cssClass: Ember.computed('invertedProgress', 'progressPercent', function () {
      let progressPercent = this.progressPercent;
      let invertedProgress = this.invertedProgress;
      let color = '';

      if (progressPercent >= 200) {
        color = 'good four-bars';
      } else if (progressPercent >= 175) {
        color = 'good three-bars';
      } else if (progressPercent >= 150) {
        color = 'ok three-bars';
      } else if (progressPercent >= 125) {
        color = 'ok two-bars';
      } else if (progressPercent > 100) {
        color = 'bad one-bar';
      } else {
        color = 'bad four-bars blinker';
      }

      return color;
    }),
    cssColor: Ember.computed('invertedProgress', 'progressPercent', function () {
      let progressPercent = this.progressPercent;
      let invertedProgress = this.invertedProgress;
      let color = 'green';

      if (progressPercent >= 200) {
        color = 'green';
      } else if (progressPercent >= 175) {
        color = 'green';
      } else if (progressPercent >= 150) {
        color = 'orange';
      } else if (progressPercent >= 125) {
        color = 'orange';
      } else if (progressPercent > 100) {
        color = 'red';
      } else {
        color = 'red';
      }

      return color;
    }),
    actions: {
      toggleItemReorderGuideline(guideline) {
        this.toggleItemReorderGuideline(guideline);
      }

    }
  });

  _exports.default = _default;
});