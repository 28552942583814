define("alliance-business-suite/components/guests/merge-card", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    classNames: ['contact-card-container'],
    cards: Ember.computed('model', function () {
      let cards = ['general', 'orders'];
      return cards;
    })
  });

  _exports.default = _default;
});