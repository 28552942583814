define("alliance-business-suite/components/form-ui/lucky-cycle-epoch", ["exports", "alliance-business-suite/mixins/init-property-names", "alliance-business-suite/mixins/show-component"], function (_exports, _initPropertyNames, _showComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_showComponent.default, _initPropertyNames.default, {
    classNames: ["lucky-cycle-epoch"],

    init() {
      this._super(...arguments);

      this._displayEnabledType = Ember.typeOf(this.DisplayEnabled); // If the startDate or endDate is 0, we're going to set it to a "not limited date" value of 1/1/2000 and 12/31/2099

      if (this.StartDate === 0) {
        this.update(this.object, this.properties.StartDate.key, moment("1/1/2000").hour(0).minute(0).seconds(0).format("X"));
      }

      if (this.EndDate === 0) {
        this.update(this.object, this.properties.EndDate.key, moment("12/31/2099").hour(0).minute(0).seconds(0).format("X"));
      }
    },

    machineAuditType: Ember.computed.alias('machineProgram.machineAuditType.name'),
    luckyCycleLabel: Ember.computed('machineAuditType', function () {
      // TODO: Change this to be based on brand instead of audit type
      if (["TITAN_T2", "TITAN_T1"].includes(this.machineAuditType)) {
        return "bonus_cycle";
      } else {
        return "lucky_cycle";
      }
    }),

    displayAfterVendValue() {
      if (this._displayEnabledType === "boolean") {
        return this.DisplayEnabled;
      } else {
        return this.DisplayEnabled === 1 ? false : true;
      }
    },

    displayAfterVend: Ember.computed('DisplayEnabled', {
      // Most machines store this as a boolean, but Titanium machines use an enum
      get() {
        return this.displayAfterVendValue();
      },

      set(key, value) {
        this.update(this.object, this.properties.DisplayEnabled.key, value);
        return this.displayAfterVendValue();
      }

    }),
    actions: {
      updateDisplayEnabled(object, name, value) {
        if (this._displayEnabledType === "boolean") {
          Ember.set(this, "displayAfterVend", value);
        } else {
          Ember.set(this, "displayAfterVend", value === false ? 1 : 0);
        }
      },

      showModal() {
        Ember.set(this, 'showModal', true);
      },

      hideModal() {
        Ember.set(this, 'showModal', false);
      }

    }
  });

  _exports.default = _default;
});