define("alliance-business-suite/components/machines/programs/cycles/ui/quantum-gold-wx-segment-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    output: Ember.computed('index', 'modifiers.@each', 'machineProgram._machineAttributes', function () {
      // We will output the label for the current segment/stage index based on provided modifiers
      let modifiers = this.modifiers;
      let index = this.index;
      let machineProgram = this.machineProgram; // These properties are named weird. Medium mods have "heavy" in the name, and heavy mods have "super" in the name.

      let preWash = Ember.get(modifiers, 'CycleModifierHeavyOptionsPrewashEnabled') || Ember.get(modifiers, 'CycleModifierSuperOptionsPrewashEnabled');
      let extraWash = Ember.get(modifiers, 'CycleModifierHeavyOptionsExtraWashEnabled') || Ember.get(modifiers, 'CycleModifierSuperOptionsExtraWashEnabled');
      let exraRinse = Ember.get(modifiers, 'CycleModifierHeavyOptionsExtraRinseEnabled') || Ember.get(modifiers, 'CycleModifierSuperOptionsExtraRinseEnabled');
      let warmRinse = Ember.get(modifiers, 'CycleModifierHeavyOptionsWarmRinseEnabled') || Ember.get(modifiers, 'CycleModifierSuperOptionsWarmRinseEnabled');
      let mediumAgigateTime = Ember.get(modifiers, 'CycleModifierMediumExtraWashAgitateTime');
      let heavyAgigateTime = Ember.get(modifiers, 'CycleModifierHeavyExtraWashAgitateTime');

      switch (index) {
        case 0:
          if (preWash) {
            if (machineProgram._machineAttributes.includes("CENTURION_C4")) {
              return this.intl.t('prewash'); // SQ
            } else {
              return this.intl.t('extra_wash'); // Huebsch
            }
          }

          break;

        case 1:
          if (extraWash) {
            return `${this.intl.t('extra_wash')} ${mediumAgigateTime}/${heavyAgigateTime} ${this.intl.t('min')}`;
          }

          break;

        case 6:
          if (exraRinse) {
            return this.intl.t('extra_rinse');
          }

          break;

        case 7:
          if (warmRinse) {
            return this.intl.t('warm_rinse');
          }

          break;
      }
    })
  });

  _exports.default = _default;
});