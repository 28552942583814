define("alliance-business-suite/mixins/account-payment", ["exports", "alliance-business-suite/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    access: Ember.inject.service(),
    notifications: Ember.inject.service(),

    async retrieveAccountExpiration(session, orgId, routeToIndex) {
      this.set('access.isAccountPaymentLoading', true);
      let email = session.get('data.accessPrimaryEmail');
      let domain = _environment.default.billingDomain;
      let billingUrl = `${_environment.default.APP.ALLIANCE_API_URL}/organizations/${orgId}/rooms?raw=true`;
      let authToken = session.get('data.authToken');
      await this.ajax.request(billingUrl, {
        method: 'GET'
      }).then(response => {
        let isAuthorized = this.isAuthorized(response.locations);
        this.set('access.isAccountPaymentLoading', false);
        this.set('access.paymentLocations', response.locations);

        if (routeToIndex) {
          this.router.transitionTo('index');
          location.reload();
          return;
        }

        this.set('access.isAuthorizedPayment', isAuthorized);

        if (!isAuthorized) {
          this.set('access.isAccountPaymentLoading', true);
          this.transitionTo('payment-form');
        } // determine if any locations are expiring soon
        // let locationsExpiringSoon = this.expiringSoon(response.locations);


        this.set('access.locationsExpiringSoon', this.expiringSoon(response.locations));
      }).catch(() => {
        this.set('access.isAuthorizedPayment', false);
        console.error('There was a problem retrieving billing info');
        session.invalidate();
        return;
      });
    },

    // Determine whether their account is expired
    isAuthorized(locations) {
      if (!locations || locations.length < 1) {
        this.set('access.billingLocations', locations);
        return false;
      }

      this.set('access.billingLocations', locations);

      if (_environment.default.bypassPaywall || _environment.default.brand == "IPSO" || _environment.default.brand == "PRIMUS") {
        return true;
      } else {
        // use and .every so we can break out on the first instance of a "false" condition
        // there could be dozens of rooms, so we want to optimize the loop
        return locations.every(loc => {
          return this.isExpired(loc);
        });
      }
    },

    // determines if any location is expiring in the next 30 days
    expiringSoon(locations) {
      let expiringSoon = [];
      locations.forEach(loc => {
        let diff = moment(loc.expirationDate).diff(moment(), 'days');

        if (diff <= 30) {
          expiringSoon.push(loc);
        }
      });
      return expiringSoon;
    },

    isExpired(loc) {
      // if no expiration date, then an automatic fail
      if (!loc.expirationDate) {
        // Need to enable bypassPaywall in config.js to get past this
        console.log('Location "%s" has no expiration date.', loc.name); // eslint-disable-line no-console

        return false;
      }

      let hasNotExpired = moment(loc.expirationDate).isAfter(moment());
      return hasNotExpired;
    }

  });

  _exports.default = _default;
});