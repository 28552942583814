define("alliance-business-suite/components/ui-loading-throb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // classNames: ['spinner'],
    display: true
  });

  _exports.default = _default;
});