define("alliance-business-suite/mixins/machines/programs/cycles/water-usage-flw", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    // eslint-disable-line
    intl: Ember.inject.service(),

    // #region Calculate Water Use
    calculateWaterUse() {
      // TODO: pass in cycle to be calculated, since we don't need to calculate any that aren't being displayed.
      const logTime = false;
      if (logTime) console.time('Calculate water use'); // eslint-disable-line no-console

      let previousStepType = -1;
      let cycles = this.model.settings.Cycles.CycleSteps || this.model.settings.Cycles;
      let hasHeaterValue = this.model.settings._hasHeater;
      let hasHeater = hasHeaterValue || hasHeaterValue == 'true'; // Loop through each cycle

      cycles.forEach((cycle, index) => {
        // Loop through each load type
        ['light', 'medium', 'heavy'].forEach(load => {
          let cycleHot = 0;
          let cycleCold = 0;
          let isFirstFill = true; // The cycle can be an array of steps, or the steps can be contained in a CycleSteps array.

          let steps = cycle.CycleSteps || cycle; // Loop through the steps for this load type

          steps.forEach(step => {
            // We only do work for fill step
            let stepType = Ember.get(step, 'Type');
            let activeState = Ember.get(step, 'ActiveState');
            let cold = 0;
            let hot = 0; // First we only deal with Fill steps that have time. Then, only active based on the current modifier.

            if (stepType === 2 && Ember.get(step, 'Minutes') + Ember.get(step, 'Seconds') > 0) {
              // For medium and heavy, we need to know which mod1/2 should be considered active.
              if (this.isModifierInLoad(load, activeState)) {
                // Get the water level
                let stepWaterLevel = Ember.get(step, 'WaterLevel') || Ember.get(step, 'CustomWaterLevel'); // MGD now uses CustomWaterLevel instead of WaterLevel

                let fillWaterLevel = this.cycleSettings.options.FillWaterLevel.findBy('val', stepWaterLevel);
                let waterLevel = Ember.get(fillWaterLevel, 'isCustom') ? Ember.get(step, 'CustomWaterLevel') : Ember.get(fillWaterLevel, 'presetValue');
                let settings = this.model.settings;

                if (isFirstFill) {
                  if (hasHeater) {
                    if (this.isHotFill(settings, cycle, step, load)) {
                      cold = 0;
                      hot = this.waterCalc(waterLevel, 0.0002, 0.0964, 7.5384);
                    } else if (this.isWarmFill(settings, cycle, step, load)) {
                      cold = this.waterCalc(waterLevel, -0.0024, 0.1462, 4.3442);
                      hot = this.waterCalc(waterLevel, -0.0014, 0.0859, 2.9913);
                    } else if (this.isColdFill(settings, cycle, step, load)) {
                      cold = this.waterCalc(waterLevel, -0.0038, 0.2347, 7.2997);
                      hot = 0;
                    }
                  } else {
                    if (this.isHotFill(settings, cycle, step, load)) {
                      cold = 0;
                      hot = this.waterCalc(waterLevel, -0.0023, 0.1707, 6.3192);
                    } else if (this.isWarmFill(settings, cycle, step, load)) {
                      cold = this.waterCalc(waterLevel, -0.0015, 0.1198, 3.9034);
                      hot = this.waterCalc(waterLevel, -0.0005, 0.0727, 2.3014);
                    } else if (this.isColdFill(settings, cycle, step, load)) {
                      cold = this.waterCalc(waterLevel, -0.0037, 0.2232, 6.096);
                      hot = 0;
                    }
                  }
                } else {
                  // not first fill
                  if (hasHeater) {
                    if (this.isHotFill(settings, cycle, step, load)) {
                      cold = 0;
                      hot = this.waterCalc(waterLevel, -0.0011, 0.1781, 5.347);
                    } else if (this.isWarmFill(settings, cycle, step, load)) {
                      cold = this.waterCalc(waterLevel, -0.0013, 0.131, 3.1146);
                      hot = this.waterCalc(waterLevel, -0.0008, 0.0834, 2.1433);
                    } else if (this.isColdFill(settings, cycle, step, load)) {
                      cold = this.waterCalc(waterLevel, -0.0009, 0.1884, 5.2573);
                      hot = 0;
                    }
                  } else {
                    if (this.isHotFill(settings, cycle, step, load)) {
                      cold = 0;
                      hot = this.waterCalc(waterLevel, -.0015, 0.1994, 4.0756);
                    } else if (this.isWarmFill(settings, cycle, step, load)) {
                      cold = this.waterCalc(waterLevel, -0.0003, 0.1056, 2.672);
                      hot = this.waterCalc(waterLevel, -0.0004, 0.0737, 1.539);
                    } else if (this.isColdFill(settings, cycle, step, load)) {
                      cold = this.waterCalc(waterLevel, -0.0011, 0.1853, 4.2276);
                      hot = 0;
                    }
                  }
                }

                isFirstFill = false;
              } // if activeState

            } // if Fill step
            // Save values for the current step.
            // Convert to liters, if required. TODO: This may be better placed elsewhere.


            if (this.useLiters) {
              hot = (Number(hot) * 3.78541).toFixed(2);
              cold = (Number(cold) * 3.78541).toFixed(2);
            }

            Ember.set(step, `__water_${load}_hot`, hot);
            Ember.set(step, `__water_${load}_cold`, cold);
            let totalWater = new Big(hot).plus(cold).toFixed(2);
            Ember.set(step, `__water_${load}_total`, totalWater === '0.00' ? 0 : totalWater); // Add up to display total for the cycle

            cycleHot += Number(hot);
            cycleCold += Number(cold);
          }); // done with steps
          // Save the total for this cycle

          Ember.set(cycle, `__water_${load}_hot`, cycleHot.toFixed(2));
          Ember.set(cycle, `__water_${load}_cold`, cycleCold.toFixed(2));
          let cycleTotalWater = new Big(cycleHot).plus(cycleCold).toFixed(2);
          Ember.set(cycle, `__water_${load}_total`, cycleTotalWater === '0.00' ? 0 : cycleTotalWater);
        }); // done with load
      }); // done with cycles

      let waterDataUpdated = this.waterDataUpdated || 0;
      Ember.set(this, 'waterDataUpdated', waterDataUpdated++);
      if (logTime) console.timeEnd('Calculate water use'); // eslint-disable-line no-console
    },

    showCycleWater: Ember.computed({
      get(key) {
        return localStorage.getItem('showCycleWater') == 'true';
      },

      set(key, value) {
        localStorage.setItem('showCycleWater', value);
        return value;
      }

    }),
    showStepWater: Ember.computed({
      get(key) {
        return localStorage.getItem('showStepWater') == 'true';
      },

      set(key, value) {
        localStorage.setItem('showStepWater', value);
        return value;
      }

    }),

    didReceiveAttrs() {
      this._super();

      if (!this.model.settings._hasHeater) {
        Ember.set(this, 'model.settings._hasHeater', 'false');
      }

      if (!this.useLiters) {
        Ember.set(this, 'useLiters', false);
      }

      this.calculateWaterUse();
    },

    hasHeater: Ember.computed.alias('model.settings._hasHeater'),

    isWarmFill(settings, cycle, step, load) {
      if (this.isUsingFinalWarmRinse(settings, cycle, step, load)) {
        return true;
      } else {
        return step.ColdFillOn && step.HotFillOn || this.hasHeater && step.TemperatureControlledFill;
      }
    },

    isColdFill(settings, cycle, step, load) {
      if (this.isUsingFinalWarmRinse(settings, cycle, step, load)) {
        return false;
      } else {
        // From Windows app: To make life easier non-heater machine will use cold water for temperature controlled fill
        return step.ColdFillOn && !step.HotFillOn || !this.hasHeater && step.TemperatureControlledFill;
      }
    },

    isHotFill(settings, cycle, step, load) {
      if (this.isUsingFinalWarmRinse(settings, cycle, step, load)) {
        return false;
      } else {
        return !step.ColdFillOn && step.HotFillOn;
      }
    },

    isUsingFinalWarmRinse(settings, steps, step, load) {
      /*
        Applies to Medium and Heavy loads only!
         If this is a medium or heavy load, we need to see if it has Warm Rinse enabled in the
        Pricing tab. If it does, the last Fill is always going to be Warm for that load.
      */
      if (load === 'light') {
        return false;
      }

      let usingWarmRinse = false; // We need to see if this Load has a Warm Rinse.

      if (load == 'medium') {
        usingWarmRinse = Ember.get(settings, 'Program.Price.Key1ModifierOptionsWarmFinalRinse');
      } else if (load == 'heavy') {
        usingWarmRinse = Ember.get(settings, 'Program.Price.Key2ModifierOptionsWarmFinalRinse');
      } // If warm rinse is enabled for this mod, we need to check if this is the final Fill step for this cycle.


      if (usingWarmRinse) {
        // Find the last fill step in the cycle for this load type by going backwards in the array of steps.
        // The ActiveState for this step must be either Enabled or an active Mod for this Load
        for (let index = steps.length - 1; index >= 0; index--) {
          let checkingStep = steps[index]; // Is this a fill step?

          if (checkingStep.Type == 2) {
            // Fill step is 2
            // Is this step a valid ActiveState?
            if (this.isModifierInLoad(load, Ember.get(checkingStep, 'ActiveState'))) {
              return checkingStep == step; // returns true if the step we're checking is the step we're on
            }
          }
        }
      }

      return false;
    },

    waterCalc(waterLevel, num1, num2, num3) {
      let wl = new Big(waterLevel);
      return wl.pow(2).times(num1).plus(wl.times(num2).plus(num3)).toFixed(2);
    },

    mediumMods: Ember.computed('model.settings.Program.Price.{Key1ModifierOptionsPrewash,Key1ModifierOptionsExtraRinse,Key2ModifierOptionsPrewash,Key2ModifierOptionsExtraRinse}', function () {
      // Return array of integers representing the mods that are active in Medium loads.
      let activeMods = [];
      let hasMod1 = this.model.settings.Program.Price.Key1ModifierOptionsPrewash;
      let hasMod2 = this.model.settings.Program.Price.Key1ModifierOptionsExtraRinse;

      if (hasMod1) {
        activeMods.push(1);
      }

      if (hasMod2) {
        activeMods.push(2);
      }

      return activeMods;
    }),
    heavyMods: Ember.computed('model.settings.Program.Price.{Key2ModifierOptionsPrewash,Key2ModifierOptionsExtraRinse}', function () {
      // Return array of integers representing the mods that are active in Heavy loads.
      let activeMods = [];
      let hasMod1 = this.model.settings.Program.Price.Key2ModifierOptionsPrewash;
      let hasMod2 = this.model.settings.Program.Price.Key2ModifierOptionsExtraRinse;

      if (hasMod1) {
        activeMods.push(1);
      }

      if (hasMod2) {
        activeMods.push(2);
      }

      return activeMods;
    }),

    isModifierInLoad(load, mod) {
      // Skip if disabled
      if (mod == 0) {
        return false;
      } // ActiveState of enabled is always active for all loads


      if (mod == 8) {
        // enabled == 8
        return true;
      } // A medium or heavy load can include mod1, mod2, or both.


      switch (load) {
        case 'light':
          // this WAS commented out...not sure why
          return mod == 8;

        case 'medium':
          return this.mediumMods.includes(mod);

        case 'heavy':
          return this.heavyMods.includes(mod);

        default:
          return false;
      }
    },

    // #endregion
    useLiters: Ember.computed({
      get(key) {
        return localStorage.getItem('useLiters') == 'true';
      },

      set(key, value) {
        localStorage.setItem('useLiters', value);
        return value;
      }

    }),
    waterUnits: Ember.computed('useLiters', function () {
      let t = this.intl;
      return this.useLiters ? t.t('l') : t.t('g');
    }),
    waterUnitsLabel: Ember.computed('useLiters', function () {
      let t = this.intl;
      return this.useLiters ? t.t('liters') : t.t('gallons');
    })
  });

  _exports.default = _default;
});