define("alliance-business-suite/mixins/currency-format", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const localeMap = {
    ENUS: {
      locale: "en-US",
      code: "USD",
      currency: "$",
      group: ",",
      decimal: ".",
      position: "left"
    },
    EN: {
      locale: "en-US",
      code: "USD",
      currency: "$",
      group: ",",
      decimal: ".",
      position: "left"
    },
    CSCZ: {
      locale: "cs-CZ",
      code: "EUR",
      currency: "€",
      group: "",
      decimal: ",",
      position: "right"
    },
    DE: {
      locale: "de-DE",
      code: "EUR",
      currency: "€",
      group: ",",
      decimal: ".",
      position: "right"
    },
    ES: {
      locale: "es-US",
      code: "USD",
      currency: "$",
      group: ",",
      decimal: ".",
      position: "left"
    },
    ESCO: {
      locale: "es-CO",
      code: "COP",
      currency: "$",
      group: ".",
      decimal: ",",
      position: "left"
    },
    FR: {
      locale: "fr-FR",
      code: "EUR",
      currency: "€",
      group: "",
      decimal: ",",
      position: "left"
    },
    ID: {
      locale: "id-ID",
      code: "IDR",
      currency: "Rp",
      group: ".",
      decimal: ",",
      position: "left"
    },
    ITA: {
      locale: "it-IT",
      code: "EUR",
      currency: "€",
      group: ".",
      decimal: ",",
      position: "right"
    },
    ITIT: {
      locale: "it-IT",
      code: "EUR",
      currency: "€",
      group: ".",
      decimal: ",",
      position: "left"
    },
    JPN: {
      locale: "ja-JP",
      code: "JPY",
      currency: "￥",
      group: ",",
      decimal: undefined,
      position: "left"
    },
    JA: {
      locale: "ja-JP",
      code: "JPY",
      currency: "￥",
      group: ",",
      decimal: undefined,
      position: "left"
    },
    KO: {
      locale: "ko-KR",
      code: "KRW",
      currency: "₩",
      group: ",",
      decimal: undefined,
      position: "left"
    },
    MS: {
      locale: "ms-MY",
      code: "MYR",
      currency: "RM",
      group: ",",
      decimal: ".",
      position: "left"
    },
    NLNL: {
      locale: "nl-NL",
      code: "EUR",
      currency: "€",
      group: ",",
      decimal: ".",
      position: "left"
    },
    PT: {
      locale: "pt-PT",
      code: "EUR",
      currency: "€",
      group: "",
      decimal: ",",
      position: "left"
    },
    RU: {
      locale: "ru-RU",
      code: "RUB",
      currency: "₽",
      group: "",
      decimal: ",",
      position: "right"
    },
    TH: {
      locale: "th-TH",
      code: "THB",
      currency: "฿",
      group: ",",
      decimal: ".",
      position: "left"
    },
    ZHCN: {
      locale: "zh-CN",
      code: "CNY",
      currency: "¥",
      group: ",",
      decimal: ".",
      position: "left"
    },
    ZHTW: {
      locale: "zh-TW",
      code: "CNY",
      currency: "CN¥",
      group: ",",
      decimal: ".",
      position: "left"
    }
  };

  var _default = Ember.Mixin.create({
    access: Ember.inject.service(),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),

    // Regex number formatting without decimals
    formatNumberWithoutDecimals(n, t) {
      return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, `${t}`);
    },

    // Regex number formatting with decimals
    formatNumberWithDecimals(n, t, d) {
      let num = n.replace(/\D/g, "").replace(/^0+/, "");

      if (!num.length) {
        return `0${d}00`;
      }

      if (num.length === 1) {
        num = `0${d}0` + num;
      } else if (num.length === 2) {
        num = `0${d}` + num;
      } else {
        let left_side = num.substring(0, num.length - 2);
        let right_side = num.substring(num.length - 2, num.length);
        num = left_side + `${d}` + right_side;
      }

      return num.replace(/\B(?=(\d{3})+(?!\d))/g, `${t}`);
    },

    //Returns formatted currency string based on organization currency settings.
    formatCurrencyString(value, useOverride, isDollars) {
      let organizationId = this.access.accessOrganization.id;
      let thaiTemporaryWorkAround = false;
      let currencyAccessObject = this.access || {};
      let windowLocale = window.navigator.language || "en_US";
      let currencyAccessUser = currencyAccessObject.accessUser || {
        locale: windowLocale
      };
      let userLocale = currencyAccessUser.locale;
      let userLocaleFormatted = userLocale.replace(/[^0-9a-z]/gi, "");
      let mappedLocale = localeMap[userLocaleFormatted.toUpperCase()] || {
        locale: "en-US",
        code: "USD",
        currency: "$",
        group: ",",
        decimal: ".",
        position: "right"
      };
      let currencyCode = currencyAccessObject.currencyCode || `${mappedLocale.code}` || "USD";
      let currencyDenomination = currencyAccessObject.currencyDenomination || mappedLocale.currency;
      let currencyThousandsSeparator = currencyAccessObject.currencyThousandsSeparator || mappedLocale.group;
      let currencyDecimalSeparator = currencyAccessObject.currencyDecimalSeparator || mappedLocale.decimal;
      let currencyDecimalPlaces = currencyAccessObject.currencyDecimalPlaces;
      let currencyDecimalOverride = currencyAccessObject.currencyDecimalOverride;
      let currencySubunit = currencyAccessObject.currencySubunit;
      let hungarianOrgs = ["323299", "662856", "464868", "342447", "620310", "450318", "860418", "1177975"]; // these are strings
      // Temporary Workaround for BE bug causing certain thai organization to return currencyDecimalSeparator - undefined.
      // thai org 132579 is pricing their machines with no decimals and causing issues on top of the known THAI

      if (currencyCode === "THB" && organizationId != 132579) {
        thaiTemporaryWorkAround = true;
      }

      let currencyIntl = Number(value) || 0;

      if (useOverride && currencyDecimalOverride === 0) {
        currencyIntl = currencyIntl * 100;
      }

      if (isDollars) {
        if (currencySubunit === 100) {
          currencyIntl = currencyIntl * 100;
        }
      } //Formats Number based on decimal places


      if (currencyDecimalPlaces === 0 || currencyDecimalSeparator === undefined || thaiTemporaryWorkAround) {
        currencyIntl = this.formatNumberWithoutDecimals(`${currencyIntl.toFixed(0)}`, currencyThousandsSeparator);
      } // hungarian forint currency, cut off the last 2 zeroes (no decimals)
      else if (hungarianOrgs.indexOf(organizationId) != -1) {
        currencyIntl = currencyIntl.toString().slice(0, -2);
      } else {
        currencyIntl = this.formatNumberWithDecimals(`${currencyIntl.toFixed(0)}`, currencyThousandsSeparator, currencyDecimalSeparator);
      } //Get currency symbol position based on Intl NumberFormat


      if (mappedLocale.position == "right") {
        currencyIntl = currencyIntl + `${currencyDenomination}`;
      } else {
        currencyIntl = `${currencyDenomination}` + currencyIntl;
      }

      if (value < 0) {
        currencyIntl = `-${currencyIntl}`;
      }

      return `${currencyIntl}`;
    }

  });

  _exports.default = _default;
});