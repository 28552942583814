define("alliance-business-suite/templates/components/machines/m-status-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "evwCLVXl",
    "block": "{\"symbols\":[],\"statements\":[[2,\"\\n\"],[1,[34,0]],[2,\"\\n\"],[10,\"div\"],[14,0,\"model-field\"],[12],[2,\"\\n  \"],[10,\"span\"],[14,0,\"model-field__label\"],[12],[1,[30,[36,1],[[35,0],\"machineStatusId\"],null]],[13],[2,\"\\n  \"],[1,[35,0,[\"machineStatusId\"]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"status\",\"tm\"]}",
    "moduleName": "alliance-business-suite/templates/components/machines/m-status-view.hbs"
  });

  _exports.default = _default;
});