define("alliance-business-suite/templates/components/inventory/inventory-list-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JvXuNFqB",
    "block": "{\"symbols\":[\"@inventory\"],\"statements\":[[6,[37,3],[[30,[36,2],[[35,1,[\"length\"]],0],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"content-container container--placeholder\"],[12],[2,\"\\n    \"],[10,\"h3\"],[12],[1,[30,[36,0],[\"no_location_inventory\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"inventory-table\"],[12],[2,\"\\n    \"],[8,\"tables/table-interactive\",[],[[\"@isClickable\",\"@isLeftAlignLast\",\"@data\",\"@columns\",\"@displayDataChangedAction\"],[true,false,[32,1],[32,0,[\"columns\"]],[32,0,[\"transitionToItemDetail\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"inventory\",\"eq\",\"if\"]}",
    "moduleName": "alliance-business-suite/templates/components/inventory/inventory-list-table.hbs"
  });

  _exports.default = _default;
});