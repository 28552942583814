define("alliance-business-suite/templates/components/machines/programs/cycles/ui/galaxy-600-wx-segment-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6z++2oJO",
    "block": "{\"symbols\":[],\"statements\":[[1,[34,0]]],\"hasEval\":false,\"upvars\":[\"output\"]}",
    "moduleName": "alliance-business-suite/templates/components/machines/programs/cycles/ui/galaxy-600-wx-segment-label.hbs"
  });

  _exports.default = _default;
});