define("alliance-business-suite/controllers/machines/traceability", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3;

  let MachinesTraceabilityController = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, (_class = class MachinesTraceabilityController extends Ember.Controller {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "queryParams", ['locId', 'traceId', 'sectionId']);
      (0, _initializerDefineProperty2.default)(this, "locId", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "traceId", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "sectionId", _descriptor3, this);
    }

    updateQueryParam(prop, val) {
      this[prop] = val;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "locId", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "traceId", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "sectionId", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "updateQueryParam", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "updateQueryParam"), _class.prototype)), _class));
  _exports.default = MachinesTraceabilityController;
});