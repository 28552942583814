define("alliance-business-suite/templates/components/ui/section-collapse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RrMo1wxC",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[11,\"a\"],[4,[38,0],[[32,0],\"toggleCollapse\"],null],[12],[10,\"i\"],[14,0,\"fa fa-caret-right\"],[14,\"aria-hidden\",\"true\"],[12],[13],[13],[2,\"\\n\"],[18,1,null]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "moduleName": "alliance-business-suite/templates/components/ui/section-collapse.hbs"
  });

  _exports.default = _default;
});