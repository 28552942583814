define("alliance-business-suite/templates/start", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4hd+OLqZ",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[35,1,[\"isAccountPaymentLoading\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"content-container container--not-allowed\"],[12],[2,\"\\n    \"],[10,\"img\"],[14,0,\"scrubbrush-watermark\"],[14,\"src\",\"/assets/images/warning.svg\"],[12],[13],[2,\"\\n    \"],[10,\"h3\"],[14,0,\"scrubbrush-watermark\"],[12],[1,[30,[36,0],[\"no_roles_assigned\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"access\",\"unless\"]}",
    "moduleName": "alliance-business-suite/templates/start.hbs"
  });

  _exports.default = _default;
});