define("alliance-business-suite/components/table/table-cell-quantity-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    alertThreshold: Ember.computed('column.alertThreshold', function () {
      let threshold = 100;
      let record = this.record;

      if (this.get('column.alertThreshold')) {
        threshold = record.get(this.get('column.alertThreshold'));
      }

      return threshold;
    }),
    progressBar: Ember.computed('progressPercent', function () {
      let percent = this.progressPercent;

      if (percent > 100) {
        return 1;
      } else if (percent < 0) {
        return 0;
      }

      return percent / 100;
    }),
    progressPercent: Ember.computed('column.propertyName', function () {
      let record = this.record;
      let currentAmount = record.get(this.get('column.propertyName'));
      let threshold = this.alertThreshold;
      return parseFloat(threshold / currentAmount * 100).toFixed(2);
    }),
    invertedProgress: Ember.computed('column.propertyName', function () {
      let progressPercent = this.progressPercent;
      let record = this.record;
      let currentAmount = record.get(this.get('column.propertyName'));
      let threshold = this.alertThreshold;
      let amount = parseFloat(currentAmount / threshold * 100).toFixed(2);
      return amount;
    }),
    cssClass: Ember.computed('invertedProgress', function () {
      let invertedProgress = this.invertedProgress;
      let color = '';

      if (invertedProgress >= 100) {
        color = 'good four-bars';
      } else if (invertedProgress >= 75) {
        color = 'ok three-bars';
      } else if (invertedProgress >= 50) {
        color = 'bad two-bars';
      } else if (invertedProgress >= 25) {
        color = 'bad one-bar blinker';
      } else if (invertedProgress >= 0) {
        color = 'bad four-bars';
      }

      return color;
    }),
    cssColor: Ember.computed('progressPercent', function () {
      let progressPercent = this.progressPercent;
      let color = 'green';

      if (progressPercent >= 75) {
        color = 'red';
      } else if (progressPercent >= 50) {
        color = 'orange';
      }

      return color;
    }),
    actions: {
      go() {
        let params = [];
        let fnCall = this.get('column.action');

        if (typeof fnCall === 'function') {
          if (this.get('column.params')) {
            this.get('column.params').forEach(param => {
              if (this.get(param)) {
                params.push(this.get(param));
              } else {
                params.push(param);
              }
            });
          }

          fnCall.call(this, ...params);
        }
      }

    }
  });

  _exports.default = _default;
});