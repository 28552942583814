define("alliance-business-suite/serializers/machine", ["exports", "alliance-business-suite/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import Ember from 'ember';
  var _default = _application.default.extend({
    // HACK: Not sure why this has to be here. When I modify the response to
    //       exclude the `machine-status` from includes, this is called, but only
    //       when I'm retrieving a specific machine (e.g. `/machines/:id`). This
    //       does not exist in the default serializer; instead it exists in
    //       `DS.EmbeddedRecordsMixin`. If I try to add that as a mixin to this
    //       class, I get a warning from the JSON API serializer saying it doesn't
    //       support that mixin. Adding this here stops the errors and makes
    //       everything work. The return value seems to be irrelevant (both
    //       `false` and `true` seem to provide the same behavior).
    //       [twl 26.Apr.17]
    //
    // Machine resource has a relation to a command which is from Firebase
    // A relation to a type using a Firebase adapter needs to utilize EmbeddedRecordsMixin method
    // See https://github.com/emberjs/data/blob/v2.14.9/addon/serializers/embedded-records-mixin.js#L518-L525
    hasDeserializeRecordsOption() {
      return false;
    },

    /**
     * Hacks a JSON API include object returned within a JSON API document to
     * confirm to what is required to make the endpoint work property with Ember
     * Data and within this application.
     *
     * @param  {object} include An included data object from a JSON API document
     *
     * @return {object} The data object to use for this include when passing it
     *                  to the store; or undefined if this include should be
     *                  ignored.
     *
     * @protected
     */
    // normalizeInclude(include) {
    //   // return include.type !== 'machine-statuses'
    //   //   && include.type !== 'facilities'
    //   // ? include : undefined;
    //   return include.type !== 'facilities' ? include : undefined;
    // },

    /**
     * Hacks a JSON API data object returned within a JSON API document to
     * conform to what is required to make the endpoint work properly with Ember
     * Data and within this application.
     *
     * @param {object} data A data object from a JSON API document
     * @protected
     */
    normalizeDataHack(data) {
      this.toLinkRelationship(data, 'machineAudit', 'audits'); // this.toLinkRelationship(data, 'notes', 'note');
      // let machineStatus = get(data, 'relationships.machineStatus.data');
      // if (machineStatus && !Number.isFinite(parseInt(machineStatus.id))) {
      //   console.warn(`Deleting invalid machine status ID: ${machineStatus.id}`);
      //   data.relationships.machineStatus.data = null;
      // }
    }

  });

  _exports.default = _default;
});