define("alliance-business-suite/components/form-ui/cycle-prices", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    defaultCycleCount: 15,
    maxCycleIndex: Ember.computed('params.cycleCount', function () {
      return this.params.cycleCount !== undefined ? this.params.cycleCount - 1 : this.defaultCycleCount - 1;
    }),
    // We get `propertyName` and `index` passed in.
    propertyName: Ember.computed('params.propertyName', function () {
      let propertyName = Ember.get(this, 'params.propertyName');
      let index = Ember.get(this, 'params.index');

      if (Ember.isEmpty(index)) {
        index = Ember.get(this, 'index');
      }

      if (!Ember.isNone(index)) {
        propertyName = propertyName.replace('${index}', index); // Replace the index placeholder if this is an array
      }

      return propertyName;
    }),
    cyclePrices: Ember.computed(function () {
      return Ember.get(this.object, this.propertyName);
    }),

    init() {
      this._super(...arguments); // If all array elements are the same value, this is Single Level Vend


      let propertyName = Ember.get(this, 'params.propertyName');
      Ember.defineProperty(this, 'rawValue', Ember.computed.reads(`object.${propertyName}`));
    }

  });

  _exports.default = _default;
});