define("alliance-business-suite/components/inventory/item-inventory-list-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    access: Ember.inject.service(),
    intl: Ember.inject.service(),
    classNames: ['item-inventory-list-table'],
    intlPrefix: 'components.crm',
    tagName: '',
    showComponentFooter: false,
    showColumnsDropdown: false,
    useFilteringByColumns: false,
    showGlobalFilter: false,
    useNumericPagination: false,
    doFilteringByHiddenColumns: false,
    filteringIgnoreCase: false,
    multipleColumnsSorting: false,
    showPageSize: false,
    collapseNumPaginationForPagesCount: 1,
    pageSize: 100,
    columns: Ember.computed(function () {
      let allRooms = this.allRooms;

      if (allRooms) {
        return [{
          propertyName: 'roomName',
          title: this.intl.t('location'),
          sortDirection: 'asc',
          sortPrecedence: 1
        }, {
          propertyName: 'activeInventory.price',
          title: this.intl.t('price'),
          component: 'table/table-cell-price'
        }, {
          propertyName: 'activeInventory.quantity',
          title: this.intl.t('quantity'),
          component: 'table/table-cell-quantity-amount'
        }, {
          propertyName: 'activeInventory.taxRates',
          title: this.intl.t('taxes'),
          component: 'table/table-cell-list',
          format: '<<description>> <<rate>>%'
        }, {
          propertyName: 'activeInventory.quantity',
          alertThreshold: 'activeInventory.inventoryGuideline.reorderBelowLevel',
          title: this.intl.t('inventory_alert'),
          component: 'table/table-cell-guideline-alert',
          canCreate: true,
          isLocation: this.isLocation,
          action: this.toggleInventoryReorderGuideline,
          params: ['record.activeInventory.inventoryGuideline', 'record.activeInventory']
        }, {
          component: 'table/table-cell-button-options',
          action: this.toggleInventory,
          params: ['record.activeInventory', 'record'],
          buttonClass: 'button button--secondary button--small',
          buttonContent: ['<i class="fa fa-cog" aria-hidden="true"></i>', '<i class="fa fa-plus" aria-hidden="true"></i>'],
          disableSorting: true
        }];
      } else {
        return [{
          propertyName: 'room.roomName',
          title: this.intl.t('location'),
          sortDirection: 'asc',
          sortPrecedence: 1
        }, {
          propertyName: 'price',
          title: this.intl.t('price'),
          component: 'table/table-cell-price'
        }, {
          propertyName: 'quantity',
          title: this.intl.t('quantity'),
          component: 'table/table-cell-quantity-amount'
        }, {
          propertyName: 'taxRates',
          title: this.intl.t('taxes'),
          component: 'table/table-cell-list',
          format: '<<description>> <<rate>>%'
        }, {
          propertyName: 'quantity',
          alertThreshold: 'inventoryGuideline.reorderBelowLevel',
          title: this.intl.t('inventory_alert'),
          component: 'table/table-cell-guideline-alert',
          canCreate: true,
          isLocation: this.isLocation,
          action: this.toggleInventoryReorderGuideline,
          params: ['record.inventoryGuideline', 'record']
        }, {
          component: 'table/table-cell-button-options',
          action: this.toggleInventory,
          params: ['record'],
          buttonClass: 'button button--secondary button--small',
          buttonContent: ['<i class="fa fa-cog" aria-hidden="true"></i>', '<i class="fa fa-plus" aria-hidden="true"></i>'],
          disableSorting: true
        }];
      }
    })
  });

  _exports.default = _default;
});