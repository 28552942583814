define("alliance-business-suite/services/machines", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  JSON.flatten = function (data) {
    var result = {};

    function recurse(cur, prop) {
      if (Object(cur) !== cur) {
        result[prop] = cur;
      } else if (Array.isArray(cur)) {
        for (var i = 0, l = cur.length; i < l; i++) recurse(cur[i], prop + "[" + i + "]");

        if (l == 0) result[prop] = [];
      } else {
        var isEmpty = true;

        for (var p in cur) {
          isEmpty = false;
          recurse(cur[p], prop ? prop + "." + p : p);
        }

        if (isEmpty && prop) result[prop] = {};
      }
    }

    recurse(data, "");
    return result;
  };

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    access: Ember.inject.service(),
    intl: Ember.inject.service(),
    notifications: Ember.inject.service('notifications'),
    firebaseCommands: Ember.inject.service('firebase-commands'),
    devtools: Ember.inject.service(),
    _counter: 0,
    testProgramNames: [],
    _lastProgramValidationLog: [],
    lastProgramBeforeSave: {},
    lastProgramAfterSave: {},
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    testFetchDelay: 5000,

    // Wait 5 seconds before fetching back a program, so the machine has time to update things
    clearValidationLog() {
      Ember.set(this, '_lastProgramValidationLog', []);
    },

    saveValidationLog(logArray) {
      Ember.set(this, '_lastProgramValidationLog', logArray);
    },

    validationLog: Ember.computed.reads('_lastProgramValidationLog'),

    sendProgramTest(machineIds, machineProgramId, programName) {
      let machineId = machineIds[0];

      if (machineIds.length > 1) {
        alert("Only select one machine to test this program against.");
        return;
      }

      let continueTest = () => {
        console.log('continueTest');
        this.notifications.info("Fetching program from machine to continue testing...", {
          autoClear: true,
          clearDuration: 3000
        }); // Fetch program from the machine

        let testProgramName = `__[TEST ${moment().format("HH:mm")}] ${programName}`;
        Ember.set(this, "testProgramNames", [programName, testProgramName]);
        setTimeout(() => {
          this.fetchProgram(machineId, testProgramName, true);
        }, this.testFetchDelay);
      }; // Send the program


      this.sendProgram(machineIds, machineProgramId, continueTest);
    },

    sendProgram(machineIds, machineProgramId, testFunction) {
      let notifications = this.notifications;
      let userId = this.access.accessUser.id;
      this.toggleProperty('isSending');
      this.devtools.speak("sending-program.mp3");
      let statusId = this.addStatus("send", {
        machineIds,
        programId: machineProgramId,
        status: "init"
      });
      this.ajax.request(`/machinePrograms/${machineProgramId}/send?raw=true`, {
        method: 'POST',
        data: JSON.stringify({
          machineProgramId,
          machineIds,
          createdBy: {
            id: userId
          }
        })
      }).then(response => {
        // Show Send In Progress
        this.notifications.info(this.intl.t('program_send_in_progress'), {
          autoClear: true,
          clearDuration: 2000
        });
        this.updateStatus(statusId, "sending");
        return response;
      }).then(response => {
        let firebaseCommands = this.firebaseCommands;
        let notifications = this.notifications;
        let machineId = '';
        let intl = this.intl; // Watch firebaseCommands for status updates {"1":{"id":"700b8718-cbe1-4512-a349-d4091923c17a","room":{"id":"115"},"machines":[{"id":4212,"controlId":"31527414281171200"}],"type":"PROGRAM_MACHINE","createdAt":"2018-04-13 20:12:03.341000","createdBy":{"id":"3376"}}}
        // A response can have several ids, one for each room

        response.forEach(room => {
          firebaseCommands.get('sendProgramCommand')(firebaseCommands, machineId, room.id, notifications, intl, statusId, testFunction);
        });
        return response;
      }).catch(error => {
        console.log('error', error);
        console.error('stack', error.stack);
        this.updateStatus(statusId, "error");
        this.notifications.error(this.intl.t('error_send_program'), {
          autoClear: true,
          clearDuration: 6000
        });
        this.devtools.speak("failed.mp3");
        return error;
      });
    },

    getId() {
      let counter = this._counter + 1;
      Ember.set(this, "_counter", counter);
      return counter;
    },

    statusList: [],
    fetchCount: 0,

    clearFetchCount() {
      Ember.set(this, "fetchCount", 0);
    },

    addFetchCount() {
      let count = this.fetchCount + 1;
      Ember.set(this, 'fetchCount', count);
    },

    fetchInProgress: Ember.computed('statusList.{@each.status,[]}', function () {
      let fetchList = this.statusList.filterBy('type', 'fetch');
      return fetchList.filterBy('status', 'fetching').length;
    }),
    fetchInProgressMachineList: Ember.computed('statusList.{@each.status,[]}', function () {
      let fetchList = this.statusList.filterBy('type', 'fetch').filterBy('status', 'fetching');
      return fetchList.mapBy('machineId');
    }),
    sendInProgressMachineList: Ember.computed('statusList.{@each.status,[]}', function () {
      let fetchList = this.statusList.filterBy('type', 'send').filterBy('status', 'sending');
      let machineIds = fetchList.mapBy('machineIds'); // array of arrays

      let ids = machineIds.reduce((preVal, item) => {
        return preVal.concat(item);
      }, []);
      return ids;
    }),

    addStatus(type, meta) {
      let {
        name,
        description = "",
        machineId,
        machineIds,
        programId,
        organization,
        commandId
      } = meta;
      let id = this.getId();
      let statusList = this.statusList;
      statusList.pushObject({
        id,
        type,
        timestamp: moment(),
        machineId,
        machineIds,
        programId,
        name,
        organization,
        status
      });
      return id;
    },

    updateStatus(id, status) {
      console.log(`Receive status update of ${status} for id ${id}.`);
      let statusItem = this.statusList.findBy('id', id);

      if (!statusItem) {
        return;
      }

      Ember.set(statusItem, "status", status);

      if (statusItem.type == 'fetch' && status == 'complete') {
        this.addFetchCount();
      }
    },

    fetchProgram(selectedMachineId, programName) {
      let testMode = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      console.log('machines.fetchProgram');
      this.devtools.speak("fetch.mp3");
      let firebaseCommands = this.firebaseCommands;
      let notifications = this.notifications;
      let organization = this.access.accessOrganization.id;
      let intl = this.intl;
      let name = programName || this.name;
      let description = this.description || "";
      let machineId = selectedMachineId || this.machineId; // Here we update things in the machines service

      let statusId = this.addStatus("fetch", {
        name,
        description,
        machineId,
        organization,
        status: "init"
      }); // set(this, 'isLoading', true);

      this.ajax.request(`/machines/${machineId}/fetchProgram?raw=true`, {
        data: {
          params: {
            name,
            description,
            organization
          }
        },
        method: 'POST'
      }).then(response => {
        let fetchInProgress = intl.t('fetch_program_in_progress');
        firebaseCommands.get('fetchProgramCommand')(firebaseCommands, machineId, response.id, notifications, intl, {
          programName: name,
          statusId,
          testMode
        });
        notifications.info(fetchInProgress, {
          autoClear: true,
          clearDuration: 5000
        }); // System notification

        if ("Notification" in window && Notification.permission === "granted") {
          var notification = new Notification('Test Complete', {
            body: 'Program test send/receive is complete.',
            requireInteraction: true
          });
        }
      });
    },

    compareSaved: Ember.computed('lastProgramBeforeSave.{name,settings}', 'lastProgramAfterSave.{name,settings}', function () {
      let before = this.lastProgramBeforeSave.settings;
      let after = this.lastProgramAfterSave.settings;
      let name = this.lastProgramAfterSave.name;
      let comparison = this.compareObjects(before, after);

      if (before && after) {
        return {
          name,
          comparison,
          length: comparison.items.length,
          diffCount: comparison.diffs.length
        };
      } else {
        return {
          name: "-",
          comparison: [],
          length: 0,
          diffCount: 0
        };
      }
    }),

    compareObjects(obj1, obj2) {
      let comparison = []; // Start by flattening each object

      let a = JSON.flatten(obj1);
      let b = JSON.flatten(obj2);
      let keysA = Object.keys(a),
          keysB = Object.keys(b); // flatA has all named keys, like flatA["Cycles[0].Steps[0].DetergentTimes[8]"]
      // We'll do a quick loop through the object to see if it matches the other
      // { name: "name.of.key", valueA: "", value:B: "", match: true }

      for (let key of keysA) {
        // if (!keysB.includes(key) || !deepEqual(a[key], b[key])) return false;
        if (key == "") {// comparison.push({
          //   name: key,
          //   valueA: keysA[key],
          //   valueB: keysB[key],
          //   match: false,
          //   meta: "Key in objA is missing from objB",
          //   type: "zero-length",
          //   ordinal: 3
          // })
        } else {
          if (!keysB.includes(key)) {
            // debugger
            comparison.push({
              name: key,
              valueA: keysA[key],
              valueB: keysB[key],
              match: false,
              meta: "Key in objA is missing from objB",
              type: "missing",
              ordinal: 2
            });
            continue;
          }
        }

        if (a[key] == b[key]) {
          comparison.push({
            name: key,
            valueA: a[key],
            valueB: b[key],
            match: true,
            type: "match"
          });
        } else {
          comparison.push({
            name: key,
            valueA: a[key],
            valueB: b[key],
            match: false,
            type: "diff",
            ordinal: 1
          });
        }
      }

      for (let key of keysB) {
        // TODO: Make sure this is correct. If the key is "" we can just skip it?
        if (key == "") {// comparison.push({
          //   name: key,
          //   valueA: keysA[key],
          //   valueB: keysB[key],
          //   match: false,
          //   meta: "Key in objB is missing from objA",
          //   type: "zero-length",
          //   ordinal: 3
          // })
        } else {
          if (!keysA.includes(key)) {
            comparison.push({
              name: key,
              valueA: keysA[key],
              valueB: keysB[key],
              match: false,
              meta: "Key in objB is missing from objA",
              type: "missing",
              ordinal: 2
            });
          }
        }
      }

      return {
        items: comparison,
        diffs: comparison.filterBy('match', false),
        diffsOnly: comparison.filterBy('type', 'diff'),
        missing: comparison.filterBy('type', 'missing')
      };
    }

  });

  _exports.default = _default;
});