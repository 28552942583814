define("alliance-business-suite/utils/report-tables-util", ["exports", "numeral"], function (_exports, _numeral) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.averageArray = averageArray;
  _exports.convertSeconds = convertSeconds;
  _exports.formatDateTimeString = formatDateTimeString;
  _exports.formatDenominations = formatDenominations;
  _exports.localizeString = localizeString;
  _exports.machineNameDisplay = machineNameDisplay;
  _exports.machineNameDisplayWithPrefixSupport = machineNameDisplayWithPrefixSupport;
  _exports.sumArray = sumArray;
  _exports.totalRows = totalRows;

  function localizeString(phrase, keyToReplace, localizationKeyToSubsituteWith) {
    let regex = new RegExp(keyToReplace, "g");
    return phrase.replace(regex, this.intl.t(localizationKeyToSubsituteWith));
  }

  function totalRows(array, sum) {
    for (let i = 0; i < array.length; i++) {
      sum += parseInt(array[i]);
    }

    return sum;
  }

  function sumArray(arr, sum, useLastIdx) {
    if (useLastIdx) {
      sum += parseInt(arr[arr.length - 1]);
    } else {
      for (let i = 0; i < arr.length; i++) {
        sum += parseInt(arr[i]);
      }
    }

    return sum;
  }

  function averageArray(arr, sum) {
    for (let i = 0; i < arr.length; i++) {
      sum += parseFloat(arr[i]);
    }

    return sum;
  }

  function machineNameDisplay(name, number) {
    let machineName = name;
    let machineNumber = `${number}`;
    let displayValue = `${this.intl.t('machine')} - ${machineNumber.replace(/^[0|\D]*/, '')}`;

    if (machineName) {
      if (machineName.length < 8) {
        displayValue = `${this.intl.t('machine')} - ${machineName}`;
      }
    }

    return displayValue;
  }

  function formatDateTimeString() {
    let userId = this.access.accessUser.get('id');
    let user = this.store.peekRecord('employee', userId);
    let dateTimeString = `${user.dateFormat} ${user.timeFormat} A`;

    if (user.timeFormat === 'HH:mm') {
      dateTimeString = `${user.dateFormat} ${user.timeFormat}`;
    }

    return dateTimeString;
  }

  function convertSeconds(secondsValue) {
    let hours = Math.floor(secondsValue / 3600);
    let minutes = parseInt(secondsValue % 3600 / 60);

    if (minutes <= 9) {
      minutes = '0' + minutes;
    }

    return `${hours}:${minutes}` || 0;
  }

  function formatDenominations(value) {
    return (0, _numeral.default)(value).format(`${this.access.get('currencyFormatString')}`);
  } // outputs "Dryer ABC" or "Washeer 1"


  function machineNameDisplayWithPrefixSupport(name, number, isWasher) {
    let machineName = name;
    let machineNumber = `${number}`;
    let prefix = isWasher ? this.intl.t('washer') : this.intl.t('dryer');
    let displayValue = `${prefix} ${machineNumber.replace(/^[0|\D]*/, '')}`;

    if (machineName) {
      if (machineName.length < 8) {
        displayValue = `${prefix} - ${machineName}`;
      }
    }

    return displayValue;
  }
});