define("alliance-business-suite/routes/retail", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "alliance-business-suite/routes/_secureRoute"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _secureRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  let RetailRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, (_class = class RetailRoute extends _secureRoute.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "cache", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor3, this);
      (0, _defineProperty2.default)(this, "allowAccess", 'RETAIL_CONTROL_VIEW_PAGE');
    }

    get subnavTabs() {
      return this.access.subnavTabs(this.routeName);
    }

    model() {
      const organizationId = this.access.get('accessOrganization.id');

      if (!this.cache.get('loadedGeoIncludedAll')) {
        return Ember.RSVP.hash({
          organization: this.store.queryRecord('organization', {
            organization: organizationId,
            geoBoundaries: true,
            include: 'all'
          })
        });
      } else {
        return Ember.RSVP.hash({
          organization: this.store.peekRecord('organization', organizationId)
        });
      }
    }

    setupController(controller, model) {
      super.setupController(controller, model);
      controller.subnavTabs = this.subnavTabs;
      this.cache.set('loadedGeoIncludedAll', true);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "cache", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = RetailRoute;
});