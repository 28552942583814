define("alliance-business-suite/components/ui/system-warning", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  let UiSystemWarningComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._action, _dec6 = Ember._action, (_class = class UiSystemWarningComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "showBanner", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "showFallbackTranslation", _descriptor4, this);
    }

    initialLoad() {
      let locationsExpiringSoon = this.access.locationsExpiringSoon;

      if (locationsExpiringSoon) {
        if (locationsExpiringSoon.length > 0) {
          setTimeout(() => {
            this.showBanner = true;
          }, 750);
        }
      }
    }

    closeSurveyBanner() {
      this.showSurveyBanner = false;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "showBanner", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "showFallbackTranslation", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "initialLoad", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "initialLoad"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "closeSurveyBanner", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "closeSurveyBanner"), _class.prototype)), _class));
  _exports.default = UiSystemWarningComponent;
});