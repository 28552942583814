define("alliance-business-suite/components/machines/programs/cycles/steps-mgd-opl-tum", ["exports", "alliance-business-suite/mixins/machines/programs/mgd-options", "alliance-business-suite/utils/fix-range"], function (_exports, _mgdOptions, _fixRange) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const NO_HEAT = 3;
  const SMALL_MACHINES = [30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 46];
  const LARGE_MACHINES = [43, 44, 45];
  const NOHEAT_STOP_OPTIONS = [4, 5, 6, 7, 8, 9, 10, 11];
  const HEAT_STOP_OPTIONS = [0, 1, 2, 3, 4];
  const NOHEAT_ROTATE_OPTIONS = [0, 1, 2, 3, 4, 5, 6, 7];
  const HEAT_ROTATE_OPTIONS = [3, 4, 5, 6, 7, 8, 9];
  const TIME_DRY = 0;
  const AUTO_DRY = 1;
  const MOISTURE_DRY = 2;
  /*
    Notes on properties to use:
  
    "Enabled": true,
    "CycleNumber": 40, - do not touch
    "CycleNameText": "5 Minute Low",
    "Type": 0,
    "Material": 0, - shown for Moisture dry only
    "TimePastTarget": 0, - minutes, only displayed when Moisture Dry and global Advanced Moisture Dry Options is enabled (0-15)
  
  
  
    ## Reversing
    "CycleReversingEnabled": false, - enable reversing step
    "CycleSpecificParametersReversing": false - reversion "use global settings" checkbox
    "RotateTime": 3,
    "StopTime": 0,
  
    ## Steps 1-5
    "Step1ReversingEnabled": true, - enable reversing for step 1, shown when global Advanced Reversing is enabled
    "Step2ReversingEnabled": true, - enable reversing for step 2, shown when global Advanced Reversing is enabled
    "Step3ReversingEnabled": true, - enable reversing for step 3, shown when global Advanced Reversing is enabled
    "Step4ReversingEnabled": true, - enable reversing for step 4, shown when global Advanced Reversing is enabled
    "Step5ReversingEnabled": true, - enable reversing for step 5, shown when global Advanced Reversing is enabled
    "CycleSteps": [
      {
        "StepNumber": 1, - do not touch
        "Enabled": true,
        "CustomTemp": 2,
        "Time": 5, - shown for Time Dry only
        "DrynessLevel": 0, - shown for Auto Dry only
        "TargetMoisture": 5 - shown for Moisture Dry only
      }
    ],
  
    ## Cooldown
    "CycleSpecificParametersCooldown": false, - cooldown "use global settings" checkbox
    "Temperature": 100, - cooldown temperature
    "MaxCoolDownTime": 2,
    "ReversingCoolDownEnabled": true, - enable reversing for coolodown, shown when global Advanced Reversing is enabled AND reversing step is active
  
    ## Not used
    "GlobalParametersReversing": 7, - not used
    "GlobalParametersCooldown": 6, - not used
  
  */

  var _default = Ember.Component.extend({
    tagName: '',
    hasNoHeat: Ember.computed('object.CycleSteps.@each.{Enabled,CustomTemp}', function () {
      // If all active Steps 1-5 have No Heat set for temperature, this is a No Heat cycle.
      let noHeat = true;
      this.object.CycleSteps.forEach(step => {
        if (step.Enabled && step.CustomTemp !== NO_HEAT) {
          noHeat = false;
        }
      });
      return noHeat;
    }),
    hasHeat: Ember.computed.not('hasNoHeat'),
    cycleTime: Ember.computed('object.CycleSteps.@each.{Enabled,Time}', function () {
      // Add up all step times to get total cycle time
      let time = 0;
      this.object.CycleSteps.forEach(step => {
        if (step.Enabled) {
          time += Number(step.Time);
        }
      });
      return time;
    }),
    cycleTimeLimit: Ember.computed('hasHeat', function () {
      if (this.hasHeat) {
        return 99;
      } else {
        return 240;
      }
    }),
    exceedsTimeLimit: Ember.computed('cycleTime', 'isTimeDry', 'hasHeat', function () {
      // If any step has heat, limit is 99. Else 240.
      if (this.hasHeat) {
        return this.cycleTime > 99;
      } else {
        return this.cycleTime > 240;
      }
    }),
    multistepEnabled: Ember.computed.alias('machineProgram.settings.Program.Misc.MultiStepCyclesEnabled'),
    globalAdvancedReversing: Ember.computed.alias('machineProgram.settings.Program.Misc.AdvancedReversing'),
    globalAdvancedMoisureDry: Ember.computed.alias('machineProgram.settings.Program.Misc.AdvMoistureDryEnabled'),
    reversingStepEnabled: Ember.computed.alias('object.CycleReversingEnabled'),
    showReversingOption: Ember.computed.and('reversingStepEnabled', 'globalAdvancedReversing'),
    enableReversingForm: Ember.computed('showReversingOption', 'object.CycleSpecificParametersReversing', function () {
      return this.showReversingOption && this.object.CycleSpecificParametersReversing === false;
    }),
    enableReversingTime: Ember.computed("reversingStepEnabled", "globalAdvancedReversing", "object.CycleSpecificParametersReversing", function () {
      return this.reversingStepEnabled && this.globalAdvancedReversing && this.object.CycleSpecificParametersReversing === false;
    }),
    isTimeDry: Ember.computed('object.Type', function () {
      return this.object.Type === TIME_DRY;
    }),
    isAutoDry: Ember.computed('object.Type', function () {
      return this.object.Type === AUTO_DRY;
    }),
    isMoistureDry: Ember.computed('object.Type', function () {
      return this.object.Type === MOISTURE_DRY;
    }),
    showTimePastTarget: Ember.computed('globalAdvancedMoisureDry', 'object.Type', function () {
      return this.globalAdvancedMoisureDry && this.object.Type === MOISTURE_DRY;
    }),
    stepsToDisplay: Ember.computed('multistepEnabled', 'object.Type', function () {
      if (this.multistepEnabled && this.object.Type !== AUTO_DRY) {
        return [0, 1, 2, 3, 4];
      } else {
        return [0];
      }
    }),
    isSingleStep: Ember.computed('multistepEnabled', 'isAutoDry', function () {
      // It's a single step if multi-step not enabled OR auto dry.
      return this.isAutoDry || !this.multistepEnabled;
    }),
    stepTypeKey: Ember.computed('object.Type', function () {
      switch (this.object.Type) {
        case TIME_DRY:
          return "time_dry_step";

        case AUTO_DRY:
          return "auto_dry_step";

        case MOISTURE_DRY:
          return "moisture_dry_step";

        default:
          return null;
      }
    }),
    optionsStepType: [{
      val: 0,
      label: "time_dry"
    }, {
      val: 1,
      label: "auto_dry"
    }, {
      val: 2,
      label: "moisture_dry"
    }],
    optionsMaterial: [{
      val: 0,
      label: "cotton"
    }, {
      val: 1,
      label: "blend"
    }, {
      val: 2,
      label: "bedding"
    }, {
      val: 3,
      label: "delicate"
    }, {
      val: 4,
      label: "synthetic"
    }, {
      val: 5,
      label: "wool"
    }],
    optionsStepTemperature: Ember.computed('object.Type', function () {
      switch (this.object.Type) {
        case TIME_DRY:
          return [{
            val: 3,
            label: "no_heat"
          }, {
            val: 5,
            label: "very_low"
          }, {
            val: 2,
            label: "low"
          }, {
            val: 4,
            label: "medium"
          }, {
            val: 1,
            label: "high"
          }, {
            val: 100
          }, {
            val: 105
          }, {
            val: 110
          }, {
            val: 115
          }, {
            val: 120
          }, {
            val: 125
          }, {
            val: 130
          }, {
            val: 135
          }, {
            val: 140
          }, {
            val: 145
          }, {
            val: 150
          }, {
            val: 155
          }, {
            val: 160
          }, {
            val: 165
          }, {
            val: 170
          }, {
            val: 175
          }, {
            val: 180
          }, {
            val: 185
          }, {
            val: 190
          }];

        case AUTO_DRY:
          return [// { val: 3, label: "no_heat" },
          {
            val: 5,
            label: "very_low"
          }, {
            val: 2,
            label: "low"
          }, {
            val: 4,
            label: "medium"
          }, {
            val: 1,
            label: "high"
          }];

        case MOISTURE_DRY:
          return [// { val: 3, label: "no_heat" },
          {
            val: 5,
            label: "very_low"
          }, {
            val: 2,
            label: "low"
          }, {
            val: 4,
            label: "medium"
          }, {
            val: 1,
            label: "high"
          }, {
            val: 100
          }, {
            val: 105
          }, {
            val: 110
          }, {
            val: 115
          }, {
            val: 120
          }, {
            val: 125
          }, {
            val: 130
          }, {
            val: 135
          }, {
            val: 140
          }, {
            val: 145
          }, {
            val: 150
          }, {
            val: 155
          }, {
            val: 160
          }, {
            val: 165
          }, {
            val: 170
          }, {
            val: 175
          }, {
            val: 180
          }, {
            val: 185
          }, {
            val: 190
          }];

        default:
          return [];
      }
    }),
    optionsCooldownTemp: [{
      val: 70
    }, {
      val: 75
    }, {
      val: 80
    }, {
      val: 85
    }, {
      val: 90
    }, {
      val: 95
    }, {
      val: 100
    }, {
      val: 105
    }, {
      val: 110
    }],
    optionsStepMoisture: [{
      val: 0,
      label: "0"
    }, {
      val: 1,
      label: "1"
    }, {
      val: 3,
      label: "3"
    }, {
      val: 5,
      label: "5"
    }, {
      val: 7,
      label: "7"
    }, {
      val: 10,
      label: "10"
    }, {
      val: 15,
      label: "15"
    }, {
      val: 20,
      label: "20"
    }, {
      val: 25,
      label: "25"
    }, {
      val: 30,
      label: "30"
    }, {
      val: 35,
      label: "35"
    }, {
      val: 40,
      label: "40"
    }],
    optionsDrynessLevel: [{
      val: 251,
      label: "-5"
    }, {
      val: 252,
      label: "-4"
    }, {
      val: 253,
      label: "-3"
    }, {
      val: 254,
      label: "-2"
    }, {
      val: 255,
      label: "-1"
    }, {
      val: 0,
      label: "0"
    }, {
      val: 1,
      label: "1"
    }, {
      val: 2,
      label: "2"
    }, {
      val: 3,
      label: "3"
    }, {
      val: 4,
      label: "4"
    }, {
      val: 5,
      label: "5"
    }],
    optionsRotateTime: Ember.computed('hasNoHeat', function () {
      // No heat cycles can do enums 0-7
      // Heat cycles can only do enums 3-9
      let optionItems = [{
        val: 0,
        label: "5"
      }, {
        val: 1,
        label: "10"
      }, {
        val: 2,
        label: "20"
      }, {
        val: 3,
        label: "30"
      }, {
        val: 4,
        label: "45"
      }, {
        val: 5,
        label: "60"
      }, {
        val: 6,
        label: "90"
      }, {
        val: 7,
        label: "120"
      }, {
        val: 8,
        label: "240"
      }];
      return optionItems.filter(item => {
        return this.hasNoHeat ? NOHEAT_ROTATE_OPTIONS.includes(item.val) : HEAT_ROTATE_OPTIONS.includes(item.val);
      });
    }),
    optionsStopTime: Ember.computed('machineProgram.machineType', 'hasNoHeat', function () {
      // No heat cycles can do enums 4-11
      // Heat cycles can only do 0-4
      // MGD_M7_TUM is limited to these machine types:
      // 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 46, 43, 44, 45
      let optionItems; // Labels are different based on machine size

      if (SMALL_MACHINES.includes(Ember.get(this, 'machineProgram.machineType'))) {
        // eslint-disable-line ember/no-get
        // 25-75# machines
        optionItems = [{
          val: 0,
          label: "6"
        }, {
          val: 1,
          label: "7"
        }, {
          val: 2,
          label: "8"
        }, {
          val: 3,
          label: "9"
        }, {
          val: 4,
          label: "10"
        }, {
          val: 5,
          label: "15"
        }, {
          val: 6,
          label: "30"
        }, {
          val: 7,
          label: "45"
        }, {
          val: 8,
          label: "60"
        }, {
          val: 9,
          label: "90"
        }, {
          val: 10,
          label: "180"
        }, {
          val: 11,
          label: "360"
        }];
      } else {
        // 120-200# machines
        optionItems = [{
          val: 0,
          label: "10"
        }, {
          val: 1,
          label: "11"
        }, {
          val: 2,
          label: "12"
        }, {
          val: 3,
          label: "13"
        }, {
          val: 4,
          label: "14"
        }, {
          val: 5,
          label: "15"
        }, {
          val: 6,
          label: "30"
        }, {
          val: 7,
          label: "45"
        }, {
          val: 8,
          label: "60"
        }, {
          val: 9,
          label: "90"
        }, {
          val: 10,
          label: "180"
        }, {
          val: 11,
          label: "360"
        }];
      } // Allowed values change based on Heat/No Heat


      return optionItems.filter(item => {
        return this.hasNoHeat ? NOHEAT_STOP_OPTIONS.includes(item.val) : HEAT_STOP_OPTIONS.includes(item.val);
      });
    }),
    actions: {
      onTypeChange() {
        // If Type is changed to `1` we need to uncheck and disable steps 2-5
        let cycle = this.object;

        if (cycle.Type == AUTO_DRY) {
          cycle.CycleSteps.forEach((step, index) => {
            if (index > 0) {
              Ember.set(step, "Enabled", false);
            }
          });
        }
      },

      validateCycle() {
        // Rotate Time:
        // No heat cycles can do enums 0-7
        // Heat cycles can only do enums 3-9
        // Stop Time:
        //  No heat cycles can do enums 4-11
        //  Heat cycles can only do 0-4
        let cycle = this.object;

        if (this.hasNoHeat) {
          Ember.set(cycle, 'RotateTime', (0, _fixRange.fixRange)(cycle.RotateTime, 0, 7));
          Ember.set(cycle, 'StopTime', (0, _fixRange.fixRange)(cycle.StopTime, 4, 11));
        } else {
          // heat
          Ember.set(cycle, 'RotateTime', (0, _fixRange.fixRange)(cycle.RotateTime, 3, 9));
          Ember.set(cycle, 'StopTime', (0, _fixRange.fixRange)(cycle.StopTime, 0, 4));
        }
      }

    }
  });

  _exports.default = _default;
});