define("alliance-business-suite/components/employees/modal-punch-edit-form", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    access: Ember.inject.service(),
    intl: Ember.inject.service(),
    notifications: Ember.inject.service(),

    didInsertElement() {
      this._super(...arguments);

      this.setInitialValues(this.data);
    },

    setInitialValues(data) {
      console.log('data', data);

      if (data) {
        let {
          punchInId,
          punchInDate,
          punchOutId,
          punchOutDate
        } = data;
        let punchInDayFormatted = punchInDate;
        let punchInTimeFormatted = (0, _moment.default)(punchInDate).format("HH:mm");
        let punchOutDayFormatted = punchOutDate;
        let punchOutTimeFormatted = (0, _moment.default)(punchOutDate).format("HH:mm");
        this.setProperties({
          punchInId,
          punchInDayFormatted,
          punchInTimeFormatted,
          punchOutId,
          punchOutDayFormatted,
          punchOutTimeFormatted
        });
      } else {
        let punchInTimeFormatted = '09:00';
        let punchInDayFormatted = (0, _moment.default)().format();
        let punchOutTimeFormatted = '17:00';
        let punchOutDayFormatted = (0, _moment.default)().format();
        this.setProperties({
          isNew: true,
          punchInDayFormatted,
          punchInTimeFormatted,
          punchOutDayFormatted,
          punchOutTimeFormatted
        });
      }
    },

    disableModalButton: Ember.computed('punchInDayFormatted', function () {
      let {
        punchInDayFormatted
      } = this;

      if (!(0, _moment.default)(punchInDayFormatted).isValid()) {
        return true;
      }
    }),
    actions: {
      updateInTime(value) {
        this.set('punchInDayFormatted', value);
      },

      updateOutTime(value) {
        this.set('punchOutDayFormatted', value);
      },

      close() {
        if (!this.isSaving) {
          this.close();
        }
      },

      refreshData() {
        this.refreshData();
      },

      update() {
        let {
          notifications,
          ajax,
          punchInId,
          punchOutId,
          punchInDayFormatted,
          punchOutDayFormatted,
          punchInTimeFormatted,
          punchOutTimeFormatted
        } = this;
        let roomId = this.room.get('id');
        let employeeId = this.employee.get('id');
        let finalPunchIn = "";
        let finalPunchOut = ""; // have to convert to punchTime: '2019-08-15 17:07'

        punchInDayFormatted = (0, _moment.default)(punchInDayFormatted).format('YYYY-MM-DD');

        if (typeof punchInTimeFormatted !== 'string') {
          punchInTimeFormatted = (0, _moment.default)(`${punchInTimeFormatted.hours}:${punchInTimeFormatted.minutes}`, "HH:mm").format('HH:mm');
          finalPunchIn = `${punchInDayFormatted} ${punchInTimeFormatted}`;
        } else {
          punchInTimeFormatted = (0, _moment.default)(`${punchInTimeFormatted}`, "HH:mm").format('HH:mm');
          finalPunchIn = `${punchInDayFormatted} ${punchInTimeFormatted}`;
        } // have to convert to punchTime: '2019-08-15 17:07'


        punchOutDayFormatted = (0, _moment.default)(punchOutDayFormatted).format('YYYY-MM-DD');

        if (typeof punchOutTimeFormatted !== 'string') {
          punchOutTimeFormatted = (0, _moment.default)(`${punchOutTimeFormatted.hours}:${punchOutTimeFormatted.minutes}`, "HH:mm").format('HH:mm');
          finalPunchOut = `${punchOutDayFormatted} ${punchOutTimeFormatted}`;
        } else {
          punchOutTimeFormatted = (0, _moment.default)(`${punchOutTimeFormatted}`, "HH:mm").format('HH:mm');
          finalPunchOut = `${punchOutDayFormatted} ${punchOutTimeFormatted}`;
        }

        let punchId = {
          id: punchInId,
          punchTime: finalPunchIn
        };
        let punchOut = {
          id: punchOutId,
          punchTime: finalPunchOut
        };

        if (punchOutId === null) {
          punchOut = {
            id: null,
            punchTime: finalPunchOut,
            createPunchOutFor: punchInId
          };
        }

        let punches = [punchId, punchOut];

        if (!(0, _moment.default)(punchOutDayFormatted).isValid()) {
          punches = [punchId];
        }

        this.set('isSaving', true);
        ajax.request(`/rooms/${roomId}/employees/${employeeId}/punches`, {
          method: 'PATCH',
          data: {
            punches
          }
        }).then(response => {
          this.set('isSaving', false);
          this.refreshData();
          this.close();
        }).catch(error => {
          this.set('isSaving', false);
          notifications.error(this.intl.t(`${error.payload.message}`), {
            clearDuration: 5000,
            autoClear: true
          });
        });
      },

      create() {
        let {
          notifications,
          ajax,
          punchInDayFormatted,
          punchOutDayFormatted,
          punchInTimeFormatted,
          punchOutTimeFormatted
        } = this;
        let roomId = this.room.get('id');
        let employeeId = this.employee.get('id');
        let finalPunchIn = '';
        let finalPunchOut = '';
        let self = this; // have to convert to punchTime: '2019-08-15 17:07'

        punchInDayFormatted = (0, _moment.default)(punchInDayFormatted).format('YYYY-MM-DD');

        if (typeof punchInTimeFormatted !== 'string') {
          punchInTimeFormatted = (0, _moment.default)(`${punchInTimeFormatted.hours}:${punchInTimeFormatted.minutes}`, "HH:mm").format('HH:mm');
          finalPunchIn = `${punchInDayFormatted} ${punchInTimeFormatted}`;
        } else {
          punchInTimeFormatted = (0, _moment.default)(`${punchInTimeFormatted}`, "HH:mm").format('HH:mm');
          finalPunchIn = `${punchInDayFormatted} ${punchInTimeFormatted}`;
        } // have to convert to punchTime: '2019-08-15 17:07'


        punchOutDayFormatted = (0, _moment.default)(punchOutDayFormatted).format('YYYY-MM-DD');

        if (typeof punchOutTimeFormatted !== 'string') {
          punchOutTimeFormatted = (0, _moment.default)(`${punchOutTimeFormatted.hours}:${punchOutTimeFormatted.minutes}`, "HH:mm").format('HH:mm');
          finalPunchOut = `${punchOutDayFormatted} ${punchOutTimeFormatted}`;
        } else {
          punchOutTimeFormatted = (0, _moment.default)(`${punchOutTimeFormatted}`, "HH:mm").format('HH:mm');
          finalPunchOut = `${punchOutDayFormatted} ${punchOutTimeFormatted}`;
        }

        let punches = [{
          in: {
            timestamp: finalPunchIn
          },
          out: {
            timestamp: finalPunchOut
          }
        }];

        if (!(0, _moment.default)(punchOutDayFormatted).isValid()) {
          punches = [{
            in: {
              timestamp: finalPunchIn
            }
          }];
        }

        this.set('isSaving', true);
        ajax.request(`/rooms/${roomId}/employees/${employeeId}/punches`, {
          method: 'POST',
          data: {
            punches
          }
        }).then(response => {
          this.set('isSaving', false);
          let isOldRefresh = true;
          this.refreshData(isOldRefresh);
          this.close();
        }).catch(error => {
          this.set('isSaving', false);
          notifications.error(this.intl.t(`${error.payload.message}`), {
            clearDuration: 5000,
            autoClear: true
          });
        });
      },

      deletePunch() {
        let {
          notifications,
          ajax,
          punchInId
        } = this;
        let roomId = this.room.get('id');
        let employeeId = this.employee.get('id');
        this.set('isSaving', true);
        ajax.request(`/rooms/${roomId}/employees/${employeeId}/punches/${punchInId}`, {
          method: 'DELETE'
        }).then(response => {
          this.notifications.error(this.intl.t('punch_deleted'), {
            autoClear: true,
            clearDuration: 2000
          });
          this.set('isSaving', false);
          this.refreshData();
          this.close();
        }).catch(error => {
          this.set('isSaving', false);
          notifications.error(this.intl.t('error'), {
            clearDuration: 5000,
            autoClear: true
          });
        });
      }

    }
  });

  _exports.default = _default;
});