define("alliance-business-suite/components/machines/programs/pricing/mgd", ["exports", "alliance-business-suite/mixins/machines/programs/mgd-options"], function (_exports, _mgdOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_mgdOptions.default, {
    machineAuditType: Ember.computed.alias('machineProgram._machineAuditType'),
    indexValues: [0, 1, 2, 3, 4, 5, 6, 7],
    // Need to observe any properties used for enabled, etc.
    formConfig: Ember.computed(function () {
      return {
        columns: [{
          sections: [{
            title: "base_price",
            ifMachine: "MGD_M1_DRY",
            controls: [{
              type: "currency",
              propertyName: "Program.Price.HeatVendPrice",
              label: "vend-price-heat",
              min: 0,
              max: 65535,
              ifMachine: "DRY"
            }, {
              type: "currency",
              propertyName: "Program.Price.NoHeatVendPrice",
              label: "vend-price-no-heat",
              min: 0,
              max: 65535
            }]
          }, {
            title: "base_price",
            ifMachine: "FLW",
            controls: [{
              type: "base-price-multi-vend",
              propertyName: "Program.Price.VendPrices",
              names: this.optionsDefaultCycles.map(item => item.label)
            }]
          }, {
            title: "defaults",
            controls: [{
              ifMachine: "DRY",
              type: "select",
              propertyName: "Program.Cycle.DefaultCycle",
              label: "default_cycle",
              options: "DefaultCycles"
            }, {
              ifMachine: "FLW",
              type: "select",
              propertyName: "Program.Cycle.DefaultCycle",
              label: "default_cycle",
              options: this.optionsDefaultCycles
            }, {
              ifMachine: "FLW",
              // Soil level
              type: "select",
              propertyName: "Program.Cycle.FlushoutForFillStepsDrainValveSelection",
              label: "soil",
              options: this.optionsSoil
            }, {
              type: "select",
              propertyName: "Program.CoinPulse.DecimalCommaOptions",
              label: "decimal_position",
              options: "NumericSeparator"
            }, {
              type: "select",
              propertyName: "Program.Price.MonetaryUnit",
              label: "currency",
              options: "CurrencyType"
            }, {
              type: "select",
              propertyName: "Program.Price.IsMonetaryUnitAfterCurrency",
              label: "currency_placement",
              options: "SymbolPlacement"
            }, {
              type: "checkbox",
              propertyName: "Program.Price.CycleModifierPrompts.UpsellEnabled",
              label: "upsell_soil_level",
              ifMachine: "FLW"
            }, {
              type: "checkbox",
              propertyName: "Program.Price.CycleModifierPrompts.SupplyInjectionEnabled",
              label: "supply_injection",
              ifMachine: "FLW"
            }]
          }, {
            title: "coin_drops",
            controls: [// Enable coin 1
            {
              type: "checkbox-number-combo",
              numberType: "currency",
              properties: {
                checkbox: {
                  key: "Program.CoinPulse.CoinDrop1Enabled",
                  label: "Coin1Enabled"
                },
                number: {
                  key: "Program.CoinPulse.Coin1Value",
                  label: "value"
                }
              },
              min: 0,
              max: 65535
            }, // Enable coin 2
            {
              type: "checkbox-number-combo",
              numberType: "currency",
              properties: {
                checkbox: {
                  key: "Program.CoinPulse.CoinDrop2Enabled",
                  label: "Coin2Enabled"
                },
                number: {
                  key: "Program.CoinPulse.Coin2Value",
                  label: "value"
                }
              },
              min: 0,
              max: 65535
            }]
          }, {
            title: "world-drop",
            controls: [{
              type: "world-drop",
              properties: {
                WorldDropEnabled: "Program.Price.PaymentWorldDropEnabled",
                // Future use
                WorldDrop1CoinsAccepted: "Program.Price.DenominationsAcceptedCount",
                WorldDropValues: "Program.Price.DenominationValues"
              }
            }]
          }, {
            title: "payment_method",
            controls: [// {
            //   type: "coin-drop",
            //   properties: {
            //     SingleDrop: 'Program.Price.PaymentSingleCoinDropEnabled',
            //     DualDrop: 'Program.Price.PaymentDualCoinDropEnabled',
            //     WorldDrop: 'Program.Price.PaymentWorldDropEnabled'
            //   }
            // },
            {
              type: "checkbox",
              propertyName: "Program.Price.PaymentSingleCoinDropEnabled",
              label: "single_drop"
            }, {
              type: "checkbox",
              propertyName: "Program.Price.PaymentDualCoinDropEnabled",
              label: "dual_drop"
            }, {
              type: "checkbox",
              propertyName: "Program.Price.PaymentWorldDropEnabled",
              label: "world_drop"
            }, // Card
            {
              type: "checkbox",
              propertyName: "Program.Price.PaymentCardEnabled",
              label: "card"
            }, // Bill
            {
              type: "checkbox",
              propertyName: "Program.Price.PaymentBillEnabled",
              label: "bill"
            }, // Phone
            {
              type: "checkbox",
              propertyName: "Program.Price.PaymentPhoneEnabled",
              label: "phone"
            }]
          }]
        }, {
          sections: [{
            title: "lucky_cycle",
            controls: [{
              type: "lucky-cycle",
              properties: {
                Enabled: "Program.Misc.LuckyCycle.Enabled",
                Monday: "Program.Misc.LuckyCycle.Monday",
                Tuesday: "Program.Misc.LuckyCycle.Tuesday",
                Wednesday: "Program.Misc.LuckyCycle.Wednesday",
                Thursday: "Program.Misc.LuckyCycle.Thursday",
                Friday: "Program.Misc.LuckyCycle.Friday",
                Saturday: "Program.Misc.LuckyCycle.Saturday",
                Sunday: "Program.Misc.LuckyCycle.Sunday",
                EndYear: "Program.Misc.LuckyCycle.EndYear",
                EndMonth: "Program.Misc.LuckyCycle.EndMonth",
                EndDate: "Program.Misc.LuckyCycle.EndDate",
                StartYear: "Program.Misc.LuckyCycle.StartYear",
                StartMonth: "Program.Misc.LuckyCycle.StartMonth",
                StartDate: "Program.Misc.LuckyCycle.StartDate",
                StartHour: "Program.Misc.LuckyCycle.StartHour",
                StartMinute: "Program.Misc.LuckyCycle.StartMinute",
                Duration: "Program.Misc.LuckyCycle.Duration",
                CyclesBetween: "Program.Misc.LuckyCycle.CyclesBetween",
                DisplayEnabled: "Program.Misc.LuckyCycle.DisplayEnabled"
              }
            }]
          }, {
            title: "top_off",
            ifMachine: "DRY",
            controls: [{
              type: "top-off",
              properties: {
                TopoffEnableType: "Program.Misc.TopoffEnabled",
                PSTopoffPrice: "Program.CoinPulse.PaymentSystemTopoffPrice",
                TopoffTimeAdjustEnabled: "Program.Misc.TopoffTimeAdjustmentEnabled"
              },
              topoffEnabledValue: true
            }]
          }, {
            title: "anti_wrinkle",
            ifMachine: "DRY",
            controls: [// Enable
            {
              type: "checkbox",
              propertyName: "Program.Misc.AntiWrinkleEnabled",
              label: "AntiWrinkleEnabled_TUM"
            }, // Prompt user
            {
              type: "checkbox",
              propertyName: "Program.Price.CycleModifierPrompts.AntiWrinkleEnabled",
              label: "prompt_user"
            }, // Additional price
            {
              type: "currency",
              propertyName: "Program.Price.ModifierKey1VendAdder",
              label: "AntiWrinkleAdder_TUM",
              min: 0,
              max: 65535
            }, // Time min.
            {
              type: "number",
              propertyName: "Program.Misc.AntiWrinkleDuration",
              label: "AntiWrinkleDuration_TUM",
              labelAfter: "minutes",
              min: 5,
              max: 60
            }]
          }, {
            title: this.machineProgram.settings.Program.Price.ModifierName === 0 ? "medium_pricing_modifications" : "deluxe_pricing_modifications",
            ifMachine: "FLW",
            controls: [// Additional price
            {
              type: "currency",
              propertyName: "Program.Price.ModifierKey1VendAdder",
              label: "price",
              min: 0,
              max: 65535
            }, {
              type: "checkbox",
              propertyName: "Program.Price.ModifierKey1Options.PrewashEnabled",
              label: "prewash_mod_1_active_state",
              tooltip: "tooltip_enable_mod1"
            }, {
              type: "checkbox",
              propertyName: "Program.Price.ModifierKey1Options.ExtraRinseEnabled",
              label: "prewash_mod_2_active_state",
              tooltip: "tooltip_enable_mod2"
            }, // Wash time
            {
              type: "checkbox",
              propertyName: "Program.Price.ModifierKey1Options.ExtraWashAgitateEnabled",
              label: "additional_wash_time",
              tooltip: "tooltip_additional_wash_time"
            }, {
              type: "number",
              propertyName: "Program.Price.ModifierKey1AddedWashTime",
              label: "additional_wash_time",
              labelAfter: "minutes",
              min: 1,
              max: 5
            }, // Rinse time
            {
              type: "checkbox",
              propertyName: "Program.Price.ModifierKey1Options.ExtraRinse1AgitateEnabled",
              label: "additional_extra_rinse_time",
              tooltip: "tooltip_additional_rinse_time"
            }, {
              type: "number",
              propertyName: "Program.Price.ModifierKey1AddedRinseTime",
              label: "additional_extra_rinse_time",
              labelAfter: "minutes",
              min: 1,
              max: 5
            }, {
              type: "checkbox",
              propertyName: "Program.Price.ModifierKey1Options.IncreasedWaterLevelEnabled",
              label: "increase_water_level",
              tooltip: "tooltip_enable_increase_water_level"
            }, {
              type: "checkbox",
              propertyName: "Program.Price.ModifierKey1Options.IncreasedSpinSpeed",
              label: "increase_spin_speed",
              tooltip: "tooltip_enable_increased_spin_speed"
            }, {
              type: "checkbox",
              propertyName: "Program.Price.ModifierKey1Options.WarmFinalRinseEnabled",
              label: "warm_final_rinse",
              tooltip: "tooltip_enable_warm_final_rinse"
            }]
          }, {
            title: this.machineProgram.settings.Program.Price.ModifierName === 0 ? "heavy_pricing_modifications" : "ultra_pricing_modifications",
            ifMachine: "FLW",
            controls: [// Additional price
            {
              type: "currency",
              propertyName: "Program.Price.ModifierKey2VendAdder",
              label: "price",
              min: 0,
              max: 65535
            }, {
              type: "checkbox",
              propertyName: "Program.Price.ModifierKey2Options.PrewashEnabled",
              label: "prewash_mod_1_active_state",
              tooltip: "tooltip_enable_mod1"
            }, {
              type: "checkbox",
              propertyName: "Program.Price.ModifierKey2Options.ExtraRinseEnabled",
              label: "prewash_mod_2_active_state",
              tooltip: "tooltip_enable_mod2"
            }, // Wash time
            {
              type: "checkbox",
              propertyName: "Program.Price.ModifierKey2Options.ExtraWashAgitateEnabled",
              label: "additional_wash_time",
              tooltip: "tooltip_additional_wash_time"
            }, {
              type: "number",
              propertyName: "Program.Price.ModifierKey2AddedWashTime",
              label: "additional_wash_time",
              labelAfter: "minutes",
              min: 1,
              max: 5
            }, // Rinse time
            {
              type: "checkbox",
              propertyName: "Program.Price.ModifierKey2Options.ExtraRinse1AgitateEnabled",
              label: "additional_extra_rinse_time",
              tooltip: "tooltip_additional_rinse_time"
            }, {
              type: "number",
              propertyName: "Program.Price.ModifierKey2AddedRinseTime",
              label: "additional_extra_rinse_time",
              labelAfter: "minutes",
              min: 1,
              max: 5
            }, {
              type: "checkbox",
              propertyName: "Program.Price.ModifierKey2Options.IncreasedWaterLevelEnabled",
              label: "increase_water_level",
              tooltip: "tooltip_enable_increase_water_level"
            }, {
              type: "checkbox",
              propertyName: "Program.Price.ModifierKey2Options.IncreasedSpinSpeed",
              label: "increase_spin_speed",
              tooltip: "tooltip_enable_increased_spin_speed"
            }, {
              type: "checkbox",
              propertyName: "Program.Price.ModifierKey2Options.WarmFinalRinseEnabled",
              label: "warm_final_rinse",
              tooltip: "tooltip_enable_warm_final_rinse"
            }]
          }, {
            title: "pricing_modifications",
            ifMachine: "FLW",
            controls: [{
              type: "checkbox",
              propertyName: "Program.Price.ModifierKey3Options.SpeedCycleEnabled",
              label: "enable_speed_cycle",
              tooltip: "tooltip_enable_speed_cycle"
            }, {
              type: "currency",
              propertyName: "Program.Price.ModifierKey3VendAdder",
              label: "price",
              min: 0,
              max: 65535
            }, {
              type: "currency",
              propertyName: "Program.Price.SupplyInjectionVendPrice",
              label: "supply_injection",
              min: 0,
              max: 65535
            }, {
              type: "select",
              propertyName: "Program.Cycle.PreWashWaterLevelIncrease",
              label: "increase_water_level_for_prewash",
              options: "washLevelOptions"
            }, {
              type: "select",
              propertyName: "Program.Cycle.RinseWaterLevelIncrease",
              label: "increase_water_level_for_rinse",
              options: "rinseLevelOptions"
            }, {
              type: "select",
              propertyName: "Program.Cycle.SpinSpeedIncrease",
              label: "increase_spin_speed",
              options: "speedOptions"
            }]
          }, {
            title: "special_vend",
            ifMachine: "DRY",
            controls: [{
              type: "special-vend-container",
              componentName: "machines/programs/pricing/special-vend-acas",
              properties: {
                Enabled: "SpecialVend.${index}.DOW.Enabled",
                Friday: "SpecialVend.${index}.DOW.Friday",
                Monday: "SpecialVend.${index}.DOW.Monday",
                Sunday: "SpecialVend.${index}.DOW.Sunday",
                Tuesday: "SpecialVend.${index}.DOW.Tuesday",
                Saturday: "SpecialVend.${index}.DOW.Saturday",
                Thursday: "SpecialVend.${index}.DOW.Thursday",
                Wednesday: "SpecialVend.${index}.DOW.Wednesday",
                EndYear: "SpecialVend.${index}.EndYear",
                EndMonth: "SpecialVend.${index}.EndMonth",
                EndDate: "SpecialVend.${index}.EndDate",
                StartYear: "SpecialVend.${index}.StartYear",
                StartMonth: "SpecialVend.${index}.StartMonth",
                StartDate: "SpecialVend.${index}.StartDate",
                StartHour: "SpecialVend.${index}.StartHour",
                StartMinute: "SpecialVend.${index}.StartMinute",
                Duration: "SpecialVend.${index}.Duration",
                Coin1TopOffMinutes: "SpecialVend.${index}.Coin1TopOffMinute",
                Coin1TopOffSeconds: "SpecialVend.${index}.Coin1TopOffSecond",
                Coin2TopOffMinutes: "SpecialVend.${index}.Coin2TopOffMinute",
                Coin2TopOffSeconds: "SpecialVend.${index}.Coin2TopOffSecond",
                HeatPrice: "SpecialVend.${index}.HeatPrice",
                NoHeatPrice: "SpecialVend.${index}.NoHeatPrice",
                HeatMinutes: "SpecialVend.${index}.HeatMinute",
                HeatSeconds: "SpecialVend.${index}.HeatSecond",
                NoHeatMinutes: "SpecialVend.${index}.NoHeatMinute",
                NoHeatSeconds: "SpecialVend.${index}.NoHeatSecond",
                CycleModifierDefaultValue: "SpecialVend.${index}.CycleModifierDefaultValue",
                PaymentSystemTopOffPrice: "SpecialVend.${index}.NVVTopOffPrice",
                PaymentSystemTopOffMinutes: "SpecialVend.${index}.NVVTopOffMinute",
                PaymentSystemTopOffSeconds: "SpecialVend.${index}.NVVTopOffSecond",
                AntiwrinkleTopOffPrice: "SpecialVend.${index}.ModifierKey1VendAdder"
              },
              uniqueIndex: [0, 1, 2, 3, 4, 5, 6, 7]
            }]
          }, {
            title: "special_vend",
            ifMachine: "FLW",
            controls: [{
              type: "special-vend-container",
              componentName: "machines/programs/pricing/special-vend-acas",
              properties: {
                Enabled: "SpecialVend.${index}.DOW.Enabled",
                Friday: "SpecialVend.${index}.DOW.Friday",
                Monday: "SpecialVend.${index}.DOW.Monday",
                Sunday: "SpecialVend.${index}.DOW.Sunday",
                Tuesday: "SpecialVend.${index}.DOW.Tuesday",
                Saturday: "SpecialVend.${index}.DOW.Saturday",
                Thursday: "SpecialVend.${index}.DOW.Thursday",
                Wednesday: "SpecialVend.${index}.DOW.Wednesday",
                EndYear: "SpecialVend.${index}.EndYear",
                EndMonth: "SpecialVend.${index}.EndMonth",
                EndDate: "SpecialVend.${index}.EndDate",
                StartYear: "SpecialVend.${index}.StartYear",
                StartMonth: "SpecialVend.${index}.StartMonth",
                StartDate: "SpecialVend.${index}.StartDate",
                StartHour: "SpecialVend.${index}.StartHour",
                StartMinute: "SpecialVend.${index}.StartMinute",
                Duration: "SpecialVend.${index}.Duration",
                CyclePrices: 'SpecialVend.${index}.VendPrices',
                CycleModifierDefaultValue: "SpecialVend.${index}.CycleModifierDefaultValue",
                CycleModifierKey1MediumPriceAdder: "SpecialVend.${index}.CycleModifierKey1MediumPriceAdder",
                CycleModifierKey2HeavyPriceAdder: "SpecialVend.${index}.CycleModifierKey2HeavyPriceAdder",
                ExtractSpinLimit: "SpecialVend.${index}.ExtractSpinLimit",
                WashAgitateMinutesAdder: "SpecialVend.${index}.WashAgitateMinutesAdder",
                WashAgitateMinuteSubtracter: "SpecialVend.${index}.WashAgitateMinuteSubtracter",
                // NEW, trying to standardize things
                Modifier1_Price: "SpecialVend.${index}.CycleModifierKey1MediumPriceAdder",
                // NEW
                Modifier1_Mod1Active: null,
                Modifier1_Mod2Active: null,
                Modifier1_ExtraRinse: "SpecialVend.${index}.Key1ModifierOptionsExtraRinse",
                Modifier1_ExtraWashTime: "SpecialVend.${index}.Key1ModifierOptionsExtraWashStepsAgitateTime",
                Modifier1_ExtraRinseTime: "SpecialVend.${index}.Key1ModifierOptionsExtraRinseStepsAgitateTime",
                Modifier1_WaterLevel: null,
                Modifier1_SpinSpeed: null,
                Modifier1_Prewash: "SpecialVend.${index}.Key1ModifierOptionsPrewash",
                Modifier1_WarmFinalRinse: "SpecialVend.${index}.Key1ModifierOptionsWarmFinalRinse",
                Modifier2_Price: "SpecialVend.${index}.CycleModifierKey2HeavyPriceAdder",
                // NEW
                Modifier2_Mod1Active: null,
                Modifier2_Mod2Active: null,
                Modifier2_ExtraRinse: "SpecialVend.${index}.Key2ModifierOptionsExtraRinse",
                Modifier2_ExtraWashTime: "SpecialVend.${index}.Key2ModifierOptionsExtraWashStepsAgitateTime",
                Modifier2_ExtraRinseTime: "SpecialVend.${index}.Key2ModifierOptionsExtraRinseStepsAgitateTime",
                Modifier2_WaterLevel: null,
                Modifier2_SpinSpeed: null,
                Modifier2_Prewash: "SpecialVend.${index}.Key2ModifierOptionsPrewash",
                Modifier2_WarmFinalRinse: "SpecialVend.${index}.Key2ModifierOptionsWarmFinalRinse",
                Modifier3_Price: null,
                Modifier3_Enabled: null
              },
              uniqueIndex: [0, 1, 2, 3, 4, 5, 6, 7]
            }]
          }]
        }]
      };
    })
  });

  _exports.default = _default;
});