define("alliance-business-suite/templates/components/brushes/search-result", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wBw0jC6h",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[6,[37,1],[[32,0,[\"access\",\"isDemo\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"ui/cell-data\",[],[[\"@topLeft\",\"@bottomLeft\",\"@bottomLeftClass\",\"@click\"],[[30,[36,0],[[32,1,[\"firstName\"]],\" ****\"],null],[32,1,[\"emailAddresses\",\"firstObject\",\"emailAddress\"]],[31,[\"search--secondary \",[30,[36,1],[[32,0,[\"access\",\"isDemo\"]],\"demo-mode-search-result\",\"\"],null]]],[32,0,[\"showDetail\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"ui/cell-data\",[],[[\"@topLeft\",\"@bottomLeft\",\"@bottomLeftClass\",\"@click\"],[[30,[36,0],[[32,1,[\"firstName\"]],\" \",[32,1,[\"lastName\"]]],null],[32,1,[\"emailAddresses\",\"firstObject\",\"emailAddress\"]],[31,[\"search--secondary \",[30,[36,1],[[32,0,[\"access\",\"isDemo\"]],\"demo-mode-search-result\",\"\"],null]]],[32,0,[\"showDetail\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"concat\",\"if\"]}",
    "moduleName": "alliance-business-suite/templates/components/brushes/search-result.hbs"
  });

  _exports.default = _default;
});