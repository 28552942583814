define("alliance-business-suite/mixins/card-brush-state", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Synchronizes the selected card with the brush state.
   *
   * @public
   */
  var _default = Ember.Mixin.create({
    /**
     * The service used to manage the brush.
     *
     * @protected
     */
    brush: Ember.inject.service(),

    /**
     * The card that is currently selected.
     *
     * @type {string}
     * @public
     */
    selectedCard: Ember.computed('brush.state', {
      get() {
        let savedId = Ember.get(this, 'brush.state');
        let savedCard;

        if (savedId != null) {
          savedCard = Ember.get(this, 'cards').find(card => Ember.String.dasherize(card) === savedId);
        }

        return savedCard;
      },

      set(key, value) {
        this.set('brush.state', Ember.String.dasherize(value));
        return value;
      }

    })
  });

  _exports.default = _default;
});