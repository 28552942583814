define("alliance-business-suite/components/table/sales-goals/table-cell-sales-amount", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    
  <span>
    {{currency @record.totalSales}}
    <br>
    {{!-- <EmberTooltip @event="hover" @_tooltipVariantClass="ember-tooltip">
      useCase: {{this.useCase}}
    </EmberTooltip> --}}
  </span>
  
  
  {{#if this.hasProgressBar}}
    <div class="goal-progress-bar-container">
      <div class="program-progress-bar">
        <span class={{this.progressBarColorCss}} style="{{safe-string this.progressPercent "append" "width:" "%"}}"></span>
  
        {{!-- <EmberTooltip
          @event="hover"
          @_tooltipVariantClass="ember-tooltip tooltip-lg">
            {{this.progressPercent}}%
            <hr>
  
            {{#if (eq this.useCase 2)}}
              Using goal set for role <CurrencyOptions @value={{@record.roleGoal}} @isInput={{false}} />.
            {{/if}}
  
            {{#if (eq this.useCase 3)}}
              Using a custom goal of <CurrencyOptions @value={{@record.individualGoal}} @isInput={{false}} />, instead of the goal defined for their role.
            {{/if}}
  
            {{#if (eq this.useCase 4)}}
              Using a custom goal of <CurrencyOptions @value={{@record.individualGoal}} @isInput={{false}} />.
            {{/if}}
        </EmberTooltip> --}}
      </div>
  
      <span>
        {{currency this.goalDenominator}}
      </span>
    </div>
  {{else}}
    <small class="muted">No sales goal</small>
  {{/if}}
  
  */
  {
    "id": "o/MEtyHG",
    "block": "{\"symbols\":[\"@record\"],\"statements\":[[2,\"\\n\"],[10,\"span\"],[12],[2,\"\\n  \"],[1,[30,[36,1],[[32,1,[\"totalSales\"]]],null]],[2,\"\\n  \"],[10,\"br\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\\n\"],[6,[37,2],[[32,0,[\"hasProgressBar\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"goal-progress-bar-container\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"program-progress-bar\"],[12],[2,\"\\n      \"],[10,\"span\"],[15,0,[32,0,[\"progressBarColorCss\"]]],[15,5,[30,[36,0],[[32,0,[\"progressPercent\"]],\"append\",\"width:\",\"%\"],null]],[12],[13],[2,\"\\n\\n\"],[2,\"    \"],[13],[2,\"\\n\\n    \"],[10,\"span\"],[12],[2,\"\\n      \"],[1,[30,[36,1],[[32,0,[\"goalDenominator\"]]],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"small\"],[14,0,\"muted\"],[12],[2,\"No sales goal\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"safe-string\",\"currency\",\"if\"]}",
    "moduleName": "alliance-business-suite/components/table/sales-goals/table-cell-sales-amount.hbs"
  });

  class TableSalesGoalsTableCellSalesAmountComponent extends _component.default {
    get progressBarColorCss() {
      if (!this.progressPercent || this.progressPercent < 25) {
        return 'failed';
      }

      if (this.progressPercent < 100) {
        return 'rejected';
      }

      return 'completed';
    }

    get hasProgressBar() {
      return this.useCase != 1;
    }

    get goalDenominator() {
      let {
        roleGoal,
        individualGoal,
        rolePercent,
        individualPercent
      } = this.args.record;

      if (this.useCase == 2) {
        return roleGoal;
      }

      return individualGoal;
    }

    get progressPercent() {
      let {
        roleGoal,
        individualGoal,
        rolePercent,
        individualPercent
      } = this.args.record;

      if (this.useCase == 2) {
        return rolePercent;
      }

      return individualPercent;
    } // useCase 1: No employee goal. no role goal -> no progress bar
    // useCase 2: No employee goal, has role goal -> use rolePercent, roleGoal
    // useCase 3: Has employee goal, has role goal -> use individualPercent, individualGoal
    // useCase 4: Has employee goal, no role goal -> use individualPercent, individualGoal


    get useCase() {
      let {
        roleGoal,
        individualGoal
      } = this.args.record;

      if (roleGoal === null && individualGoal === null) {
        return 1;
      }

      if (roleGoal !== null && individualGoal === null) {
        return 2;
      }

      if (roleGoal !== null && individualGoal !== null) {
        return 3;
      }

      if (roleGoal === null && individualGoal !== null) {
        return 4;
      }
    }

  }

  _exports.default = TableSalesGoalsTableCellSalesAmountComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TableSalesGoalsTableCellSalesAmountComponent);
});