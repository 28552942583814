define("alliance-business-suite/controllers/profile/index", ["exports", "alliance-business-suite/mixins/timezones"], function (_exports, _timezones) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    access: Ember.inject.service(),
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service('notifications'),

    findTimezoneByLocale(locale) {
      let tzObj = null;

      _timezones.timeZones.forEach(function (tz, index) {
        let utcArr = tz.utc;
        utcArr.forEach(function (utc) {
          if (utc === locale) {
            tzObj = _timezones.timeZones[index];
          }
        });
      });

      return tzObj;
    },

    showDebug: Ember.computed('session', function () {
      let email = this.get('session.data.accessPrimaryEmail');

      if (email == 'adam.dill_sq@alliancels.com') {
        return true;
      }

      return false;
    }),
    locales: Ember.computed('intl.{locale,locales}', function () {
      return this.intl;
    }),
    locations: Ember.computed('access.billingLocations', function () {
      return this.get('access.billingLocations');
    }),
    user: Ember.computed('access.user.id', function () {
      let userId = Ember.get(this, 'access.user.id');
      return Ember.get(this, 'store').peekRecord('employee', userId);
    }),
    currentTimezone: Ember.computed('showTimezoneModal', function () {
      let timeZone = this.findTimezoneByLocale(Ember.get(this.user, 'timeZone'));
      return timeZone;
    }),
    currentDateFormat: Ember.computed('showDateTimeFormatModal', function () {
      if (this.user.timeFormat === 'hh:mm') {
        return `${this.user.dateFormat} - ${this.intl.t('standard_time')}`;
      } else {
        return `${this.user.dateFormat} - ${this.intl.t('military_time')}`;
      }
    }),
    canUpdate: Ember.computed('newPassword', 'confirmPassword', function () {
      let newPassword = Ember.get(this, 'newPassword');
      let confirmPassword = Ember.get(this, 'confirmPassword');

      if (newPassword && confirmPassword) {
        if (newPassword.length >= 8) {
          if (newPassword == confirmPassword) {
            return;
          }
        }
      }

      return true;
    }),
    confirmError: Ember.computed('newPassword', 'confirmPassword', function () {
      let newPassword = Ember.get(this, 'newPassword');
      let confirmPassword = Ember.get(this, 'confirmPassword');

      if (newPassword && confirmPassword) {
        if (newPassword != confirmPassword) {
          return 'Your passwords do not match!';
        }
      }

      return '';
    }),
    showPasswordHint: Ember.computed('newPassword', function () {
      let newPassword = Ember.get(this, 'newPassword');

      if (newPassword && newPassword.length >= 8) {
        return false;
      }

      return true;
    }),

    logout() {
      Ember.get(this, 'notifications').info(`${Ember.get(this, 'intl').t('see_you_soon')} ${Ember.get(this, 'access.accessUser.firstName')}.`, {
        autoClear: true,
        clearDuration: 2000
      });
      let session = Ember.get(this, 'session');
      session.invalidate();
    },

    actions: {
      callLocEndpoint(orgId) {
        let billingUrl = `https://order.sqinsights.com/api/BrandAgreement/GetLocOrderExpirationStatus?org=${orgId}`;
        this.ajax.request(billingUrl, {
          method: 'GET',
          xhrFields: {
            withCredentials: true
          }
        });
      },

      confirmLogout() {
        let r = confirm('Sign out of your session?');

        if (r == true) {
          this.logout();
        }
      },

      togglePasswordModal() {
        this.toggleProperty('showPasswordModal');
      },

      toggleTimezoneModal() {
        this.toggleProperty('showTimezoneModal');
      },

      toggleDateTimeFormatModal() {
        this.toggleProperty('showDateTimeFormatModal');
      },

      togglePaymentForm() {
        this.toggleProperty('showPaymentForm');
      },

      async updatePassword(id, email, newPassword) {
        await this.ajax.request('/auth/changePassword', {
          method: 'POST',
          data: JSON.stringify({
            id,
            email,
            password: newPassword
          })
        }).then(response => {
          Ember.get(this, 'notifications').info(this.intl.t('password_succesfully_updated'), {
            autoClear: true,
            clearDuration: 2000
          });
          this.toggleProperty('showPasswordModal');
          return response;
        }).catch((error, jqXHR) => {
          Ember.get(this, 'notifications').error(this.intl.t('please_try_again'), {
            autoClear: true,
            clearDuration: 6000
          });
          return error;
        });
      }

    }
  });

  _exports.default = _default;
});