define("alliance-business-suite/templates/components/machines/programs/pricing/primus-mc10-wx", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "X2jH6EoF",
    "block": "{\"symbols\":[\"ui\"],\"statements\":[[8,\"form-ui\",[],[[\"@object\",\"@model\",\"@machineProgram\",\"@formDisplay\",\"@selectOptions\"],[[32,0,[\"machineProgram\",\"settings\"]],[32,0,[\"machineProgram\"]],[32,0,[\"machineProgram\"]],\"compact\",[32,0,[\"selectOptions\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n  \"],[8,[32,1,[\"render-form\"]],[],[[\"@formConfig\"],[[32,0,[\"formConfig\"]]]],null],[2,\"\\n\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "alliance-business-suite/templates/components/machines/programs/pricing/primus-mc10-wx.hbs"
  });

  _exports.default = _default;
});