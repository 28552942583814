define("alliance-business-suite/components/machines/programs/schedule/schedule-list", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  let MachinesProgramsScheduleScheduleListComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._action, _dec9 = Ember._action, (_class = class MachinesProgramsScheduleScheduleListComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "session", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "ajax", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "notifications", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "isLoading", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "programSubs", _descriptor7, this);
    }

    get sortedProgramSubs() {
      return this.programSubs.sort((a, b) => {
        if (a.nextActivityTime > b.nextActivityTime) {
          return 1;
        } else if (a.nextActivityTime < b.nextActivityTime) {
          return -1;
        }

        return 0;
      });
    }

    initialLoad() {
      this.fetchProgramSubscriptions();
    }

    async fetchProgramSubscriptions() {
      this.isLoading = true;
      let programId = this.args.model.get('id');
      await this.ajax.request(`/subscriptions?programId=${programId}&raw=true`, {
        method: 'GET'
      }).then(response => {
        this.programSubs = response;
        return response;
      }).finally(() => {
        this.isLoading = false;
      });
    }

    async deleteSubscription(programId) {
      await this.ajax.request(`/subscriptions/${programId}?raw=true`, {
        method: 'DELETE'
      }).then(response => {
        if (response && response.errorMessage) {
          this.notifications.error(this.intl.t('error_deleting_schedule'), {
            autoClear: true,
            clearDuration: 6000
          });
        } else {
          this.fetchProgramSubscriptions();
          this.notifications.success(this.intl.t('program_delete_response_success'), {
            autoClear: true,
            clearDuration: 6000
          });
        }

        return response;
      }).catch(error => {
        this.notifications.error(this.intl.t('error_deleting_schedule'), {
          autoClear: true,
          clearDuration: 6000
        });
        let item = this.programSubs.findBy('id', programId);
        Ember.set(item, 'isDeleting', false);
        return error;
      });
    }

    deleteSub(id) {
      // Disable delete button for this schedule
      let item = this.programSubs.findBy('id', id);
      Ember.set(item, 'isDeleting', true);
      this.deleteSubscription(id);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "notifications", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isLoading", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "programSubs", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "initialLoad", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "initialLoad"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "deleteSub", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "deleteSub"), _class.prototype)), _class));
  _exports.default = MachinesProgramsScheduleScheduleListComponent;
});