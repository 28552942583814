define("alliance-business-suite/helpers/cycles-total-seconds", ["exports", "alliance-business-suite/utils/cycles-values"], function (_exports, _cyclesValues) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function (_ref, namedArgs) {
    let [input, ...rest] = _ref;

    /*
    This helper adds up the time from all steps in a cycle, with a few special rules. To start, there can be multiple Soil Levels,
    such as Normal, Medium, Heavy...or Light, Medium, Heavy.
     Machines can include Mod1 and Mod2 settings that are mapped to the additional Soil Levels in the Pricing tab. For example,
    the Medium soil level might enable all cycle steps that are defined with an ActiveState of Mod1. Times for this soil level
    will include all steps that 1) have ActiveState set to enabled, plus 2) all steps with ActiveState set to Mod1.
     So the additional Soil Levels can have Mod1, Mod2, or both Mod1 & Mod2 cycles steps inluded in their cycle times.
     In addition to that, the additional Soil Levels can have extra Wash and Rinse time added. (They can also have the last rinse
    be Warm water).
     If additional Wash - Time is added to all Agitate steps with a Label of Wash.
    If additional Rinse - Time is added to all Agitate steps with a Label of Rinse 1.
     These modifications are passed in with the named argument 'mods'.
     And one more thing...if a Supply step follows a Fill step, we don't include the time in the Supply step settings. :-)
    */
    if (input) {
      let {
        soilLevel,
        mods
      } = namedArgs;
      let previousStepType = -1;
      let items = [];

      if (!input.length) {
        items.push(input);
      } else {
        items = input;
      }

      return items.reduce((cycleTime, item) => {
        let activeState = Ember.get(item, 'ActiveState');
        let stepType = Ember.get(item, 'Type');
        let label = Ember.get(item, 'Label');
        let minutes = 0; // Skip Supply if it's preceded by a Fill step

        if (stepType === _cyclesValues.STEP_TYPES_ENUM.Supply && previousStepType === _cyclesValues.STEP_TYPES_ENUM.Fill) {
          previousStepType = stepType;
          return cycleTime;
        }

        previousStepType = stepType;

        if (activeState === _cyclesValues.ACTIVE_STATES.enabled || mods[soilLevel].mod1 === true && activeState === _cyclesValues.ACTIVE_STATES.mod1 || mods[soilLevel].mod2 === true && activeState === _cyclesValues.ACTIVE_STATES.mod2) {
          // Check for extra wash time to add
          if (stepType === _cyclesValues.STEP_TYPES_ENUM.Agitate && label === _cyclesValues.STEP_LABELS_ENUM.Wash) {
            minutes += parseInt(mods[soilLevel].additionalWashMinutes);
          } // Check for extra rinse time to add


          if (stepType === _cyclesValues.STEP_TYPES_ENUM.Agitate && label === _cyclesValues.STEP_LABELS_ENUM.Rinse1) {
            minutes += parseInt(mods[soilLevel].additionalRinseMinutes);
          } // Now check the time for this step


          let seconds = parseInt(Ember.get(item, 'Seconds')) || 0;
          minutes += parseInt(Ember.get(item, 'Minutes')) || 0;
          return cycleTime += seconds + minutes * 60;
        } else {
          return cycleTime;
        }
      }, 0);
    }
  });

  _exports.default = _default;
});