define("alliance-business-suite/components/summary/reports/s-community-group-member-spend", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "alliance-business-suite/utils/currency-format-util"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _currencyFormatUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Summary::Reports::DefaultReportShim
    @skipNumberFormatting={{true}}
    @height={{@height}}
    @translatedColumns={{this.translatedColumns}}
    @rowsWithChildren={{this.rowsWithChildren}} />
  
  */
  {
    "id": "7J3kQjl9",
    "block": "{\"symbols\":[\"@height\"],\"statements\":[[8,\"summary/reports/default-report-shim\",[],[[\"@skipNumberFormatting\",\"@height\",\"@translatedColumns\",\"@rowsWithChildren\"],[true,[32,1],[32,0,[\"translatedColumns\"]],[32,0,[\"rowsWithChildren\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "alliance-business-suite/components/summary/reports/s-community-group-member-spend.hbs"
  });

  let SummaryReportsSCommunityGroupMemberSpendComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, (_class = class SummaryReportsSCommunityGroupMemberSpendComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "access", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "store", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor3, this);
    }

    get translatedColumns() {
      let headers = [// {
      //   name: "location_name",
      //   valuePath: "organizationName",
      //   width: 150,
      //   isFixed: "left",
      // },
      // {
      //   name: "group",
      //   valuePath: "groupd",
      //   width: 150,
      //   isFixed: "left",
      // },
      {
        name: "date",
        valuePath: "transactionDate",
        width: 75
      }, {
        name: "name",
        valuePath: "startedBy",
        width: 75
      }, {
        name: "email",
        valuePath: "email",
        width: 75
      }, // { name: "machineNumber", valuePath: "machineNumber", width: 75 },
      {
        name: "amount",
        valuePath: "amount",
        width: 125
      }];
      return headers;
    }

    get rowsWithChildren() {
      let rowData = [];
      this.args.model.deposits.forEach(deposit => {
        rowData.push({
          transactionDate: moment(deposit.transactionDate).format("MM/DD/YYYY hh:mm A"),
          startedBy: `${deposit.firstName} ${deposit.lastName}`,
          email: deposit.userEmail,
          amount: _currencyFormatUtil.formatCurrencyString.call(this, deposit.amount || 0)
        });
      });
      return rowData;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "access", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SummaryReportsSCommunityGroupMemberSpendComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SummaryReportsSCommunityGroupMemberSpendComponent);
});