define("alliance-business-suite/components/ui/interactive-progress-bar", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class UiInteractiveProgressBarComponent extends _component.default {
    get total() {
      let {
        data
      } = this.args;
      let hideIfZero = ['pending', 'failed', 'rejected', 'unknown'];

      if (data) {
        let keys = Object.keys(data);
        let totalSum = 0;
        let result = [];
        keys.forEach(k => {
          totalSum += data[k];
        });
        keys.forEach(key => {
          let percent = (data[key] / totalSum * 100).toFixed(2);

          if (!hideIfZero.includes(key) || hideIfZero.includes(key) && data[key] > 0) {
            result.push({
              id: key,
              localeKey: 'program_send_progress_' + key,
              className: key,
              count: data[key],
              percent: percent != '0.00' ? percent : '0',
              hideWhenZero: hideIfZero.includes(key)
            });
          }
        });
        return result;
      }
    }

  }

  _exports.default = UiInteractiveProgressBarComponent;
});