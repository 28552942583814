define("alliance-business-suite/templates/components/table/table-cell-pill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "21GX+qE+",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,4],[[35,0,[\"customIsPromotional\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,4],[[30,[36,2],[[35,1],[35,0,[\"propertyName\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"pill\"],[12],[2,\"\\n      \"],[1,[30,[36,3],[\"promotional\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[30,[36,3],[[30,[36,2],[[35,1],[35,0,[\"propertyName\"]]],null]],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"column\",\"record\",\"get\",\"t\",\"if\"]}",
    "moduleName": "alliance-business-suite/templates/components/table/table-cell-pill.hbs"
  });

  _exports.default = _default;
});