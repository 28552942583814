define("alliance-business-suite/components/ui/grid-lines", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['grid-container'],
    access: Ember.inject.service(),
    store: Ember.inject.service(),
    selectedSendAtTime: Ember.computed(function () {
      let userId = Ember.get(this, 'access.accessUser.id');
      let user = this.store.peekRecord('employee', userId);
      return user.get('timeFormat');
    })
  });

  _exports.default = _default;
});