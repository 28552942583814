define("alliance-business-suite/components/machines/programs/input-money-int", ["exports", "alliance-business-suite/utils/is-number"], function (_exports, _isNumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['text-field'],
    labelClass: 'form-field--label',
    decimalPlaces: 0,
    allowDecimal: false,
    limit: false,
    step: 1,
    displayValue: Ember.computed('property', 'decimalPlaces', {
      get(key) {
        let value = Ember.get(this, 'property');
        let decimalPlaces = Ember.get(this, 'decimalPlaces') || 0;
        return value / 10 ** decimalPlaces;
      },

      set(key, value) {
        if ((0, _isNumber.isNumber)(value)) {
          let decimalPlaces = Ember.get(this, 'decimalPlaces') || 0;
          let apiValue = decimalPlaces !== undefined ? new Big(value).times(new Big(10).pow(decimalPlaces)) : value;

          if ((0, _isNumber.isNumber)(apiValue)) {
            Ember.set(this, 'property', Number(apiValue));
          } else {
            // This should never get run
            Ember.set(this, 'property', apiValue);
          }
        } else {
          console.log('Invalid use of non-numeric data. (input-money-int.js)'); // eslint-disable-line no-console
        }

        return value;
      }

    })
  });

  _exports.default = _default;
});