define("alliance-business-suite/transforms/object", ["exports", "jquery", "ember-data"], function (_exports, _jquery, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Transform
  } = _emberData.default;

  var _default = Transform.extend({
    deserialize(value) {
      return !_jquery.default.isPlainObject(value) ? {} : value;
    },

    serialize(value) {
      return !_jquery.default.isPlainObject(value) ? {} : value;
    }

  });

  _exports.default = _default;
});