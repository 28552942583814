define("alliance-business-suite/templates/components/table/table-cell-flat-amount", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OHusnI3+",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[30,[36,4],[[35,1,[\"modelType\"]],\"line-item-type\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,3],[[30,[36,2],[[35,1],[35,0,[\"propertyName\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"i\"],[14,\"aria-hidden\",\"true\"],[14,0,\"fa fa-check\"],[12],[2,\"\\n\"],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"i\"],[14,\"aria-hidden\",\"true\"],[14,0,\"fa fa-ban\"],[14,5,\"opacity: .35\"],[12],[2,\"\\n\"],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"column\",\"record\",\"get\",\"if\",\"eq\"]}",
    "moduleName": "alliance-business-suite/templates/components/table/table-cell-flat-amount.hbs"
  });

  _exports.default = _default;
});