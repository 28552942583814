define("alliance-business-suite/templates/components/ui/placeholder-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "h4OiBM8/",
    "block": "{\"symbols\":[\"@classNames\",\"@image\",\"@title\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"content-container container--placeholder \",[32,1]]]],[12],[2,\"\\n  \"],[10,\"img\"],[14,0,\"scrubbrush-watermark\"],[15,\"src\",[32,2]],[12],[13],[2,\"\\n  \"],[10,\"h3\"],[14,0,\"scrubbrush-watermark\"],[12],[2,\"\\n    \"],[1,[32,3]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "alliance-business-suite/templates/components/ui/placeholder-message.hbs"
  });

  _exports.default = _default;
});