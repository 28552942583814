define("alliance-business-suite/models/security-role", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    hasMany,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    intl: Ember.inject.service(),
    changeTracker: {
      enableIsDirty: true,
      auto: true,
      trackHasMany: true,
      only: ['securityFunctionalAreas', 'forceRemoteStartAlertRoles', 'forceOrderRemoteStartAlertRoles', 'forceEmployeeUserAccountBalanceCreditAlertRoles', 'forceEmployeeUserAccountRewardsCreditAlertRoles']
    },
    name: attr(),
    prettyName: Ember.computed('name', 'isSystemDefault', function () {
      if (this.isSystemDefault || this.internalRole) {
        return this.intl.t(this.name);
      } else {
        return this.name;
      }
    }),
    securityFunctionalAreas: hasMany('securityFunctionalArea'),
    isSystemDefault: attr(),
    // Remote start
    remoteStartThreshold: attr(),
    canForceRemoteStart: attr(),
    forceRemoteStartAlertRoles: hasMany('securityRoles', {
      inverse: null
    }),
    internalRole: Ember.computed('name', function () {
      // Flag internal roles that should not be displayed to users
      let internalRoles = ['SUSPEND_EMPLOYEE', 'KIOSK_GUEST'];
      return internalRoles.includes(this.name);
    }),
    // Remote start limits
    orderRemoteStartPctThreshold: attr(),
    canForceOrderRemoteStart: attr(),
    forceOrderRemoteStartAlertRoles: hasMany('securityRoles', {
      inverse: null
    }),
    // User credit limits
    employeeUserAccountBalanceCreditThreshold: attr(),
    canForceEmployeeUserAccountBalanceCredit: attr(),
    forceEmployeeUserAccountBalanceCreditAlertRoles: hasMany('securityRoles', {
      inverse: null
    }),
    // User rewards credit limits
    employeeUserAccountRewardsCreditThreshold: attr(),
    canForceEmployeeUserAccountRewardsCredit: attr(),
    forceEmployeeUserAccountRewardsCreditAlertRoles: hasMany('securityRoles', {
      inverse: null
    }),
    formattedName: Ember.computed('name', function () {
      return this.intl.t(this.name);
    })
  });

  _exports.default = _default;
});