define("alliance-business-suite/components/form-ui/limit-extract-speed", ["exports", "alliance-business-suite/mixins/form-ui"], function (_exports, _formUi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_formUi.default, {
    classNames: ['form-field', 'overflow-float', 'checkbox-field', 'display-compact'],
    isDisabled: false,
    // for future use
    offValue: Ember.computed('params.offValue', function () {
      // We either get an offValue (optional) or use 0
      return this.params.offValue || 0;
    }),
    onValue: Ember.computed('params.onValue', function () {
      return this.params.onValue || this.params.min;
    }),
    checkboxValue: Ember.computed('value', 'offValue', {
      // The checkbox should be checked if the value matches the offValue
      get() {
        return this.value != this.offValue;
      },

      set(key, value) {
        if (value) {
          // Checked
          Ember.get(this, 'update')(this.object, this.propertyName, this.offValue);
        } else {
          // Unchecked
          Ember.get(this, 'update')(this.object, this.propertyName, this.onValue);
        }

        return value;
      }

    }),
    actions: {
      checkboxClicked(e) {
        let newCheckboxValue = e.target.checked;

        if (newCheckboxValue == true) {
          // If checked, set the value to the "on value"
          Ember.get(this, 'update')(this.object, this.propertyName, this.onValue, e);
        } else {
          // Unchecked, set the value to the "off value"
          Ember.get(this, 'update')(this.object, this.propertyName, this.offValue, e);
        }
      }

    }
  });

  _exports.default = _default;
});