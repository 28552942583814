define("alliance-business-suite/components/ui/button-element", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Not being used. advanced/tab-complete uses the v2 version, nothing else uses the dateparts component.
  var _default = Ember.Component.extend({
    tagName: 'button',
    classNames: ['button'],
    classNameBindings: ['buttonType'],
    buttonType: Ember.computed('params.type', function () {
      let type = Ember.get(this, 'params.type');

      switch (type) {
        case 'primary':
        case 'save':
        case 'ok':
        case 'close':
          return 'primary';

        case 'cancel':
        case 'secondary':
          return 'secondary';

        default:
          return null;
      }
    }),
    attributeBindings: ['disabled'],
    type: Ember.computed.alias('params.type'),
    disabled: Ember.computed('params.isDisabled', function () {
      let isDisabled = Ember.get(this, 'params.isDisabled') == true;
      let isActive = Ember.get(this, 'params.isActive') == true;
      let disableWhenActive = Ember.get(this, 'params.disableWhenActive') == true; // return (get(this, 'params.isDisabled') == true) ? 'disabled' : null;

      return isDisabled || disableWhenActive && isActive ? 'disabled' : null;
    }),
    buttonText: Ember.computed('params.isActive', function () {
      if (Ember.get(this, 'params.isActive') == true) {
        return Ember.get(this, 'params.activeLabel');
      } else {
        return Ember.get(this, 'params.label');
      }
    }),

    click(event) {
      if (Ember.get(this, 'params.onClick')) {
        Ember.get(this, 'params.onClick')();
      }
    }

  });

  _exports.default = _default;
});