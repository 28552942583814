define("alliance-business-suite/components/brushes/order-brush", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    access: Ember.inject.service(),
    classNames: ['order-brush--container'],
    // tagName: '',
    cards: Ember.computed('model', function () {
      let cards = ['general', 'history', 'line-items', 'notes'];
      return cards;
    }),
    // combines the "machine-events" and the "order-status" and then sorts them by date (descending)
    historyFormatted: Ember.computed('model.history.{orderStatuses,machineEvents}', function () {
      let orderStatuses = this.get('model.history.orderStatuses');
      let machineEvents = this.get('model.history.machineEvents');
      let events = [];

      if (orderStatuses && orderStatuses.get('length') > 0) {
        orderStatuses.forEach(status => {
          events.push(status);
        });
      }

      if (machineEvents && machineEvents.get('length') > 0) {
        machineEvents.forEach(event => {
          events.push(event);
        });
      }

      let sortedHistory = events.sort((a, b) => {
        let momentA = (0, _moment.default)(a.get('date'));
        let momentB = (0, _moment.default)(b.get('date'));

        if (momentA.isBefore(momentB)) {
          return 1;
        } else if (momentB.isBefore(momentA)) {
          return -1;
        }

        return 0;
      });
      return sortedHistory;
    }),
    orderSubtotal: Ember.computed('model.{additionalSettingCost,laundryCost}', function () {
      let additionalSettingCost = parseFloat(this.get('model.additionalSettingCost'));
      let laundryCost = parseFloat(this.get('model.laundryCost'));
      return additionalSettingCost + laundryCost;
    }),
    actions: {
      toggleCreateNote() {
        this.toggleProperty('viewCreateNoteModal');
      }

    }
  });

  _exports.default = _default;
});